import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import HospitalizationSearch from "$hospitalization/hospitalizations/view/search/HospitalizationSearch";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/hospitalization/hospitalizations/search",
  name: "hospitalization.hospitalizations.search",
  components: {
    default: HospitalizationSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Internações", link: OpenSubModule("hospitalization") },
      { label: "Lista de Internações" },
    ],
  },
};
