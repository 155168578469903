<template>
  <Modulebox
    ref="modulebox"
    :title="'Registrar Ações em BPA-C'"
    class="consolidated-billing"
  >
    <div class="top-button">
      <LargeButton
        id="select-competence"
        label="Definir Competência"
        :backgroundColor="'#1E88A9'"
        class="btn-import-competence"
        :disabled="disableByModal"
        @click="selectCompetence"
      >
        <IconCalendar slot="icon" class="icon-import-calendar" color="#fff" />
      </LargeButton>
    </div>
    <FormBase :title="'Busca de Registros'" class="register-form">
      <div class="consolidated-billing-body">
        <RgValidatorForm ref="validator">
          <section class="consolidated-billing-form">
            <div class="selectinput">
              <RgInput
                id="cnes"
                v-model="form.cnes"
                type="text"
                placeholder="0000000"
                label="CNES"
                disabled
                :class="{ disable: true }"
                :rules="{ required: true }"
                class="inputitem"
              />
            </div>
            <div class="selectinput">
              <RgSelectCompetence
                v-model="form.competence"
                :rules="{ required: true }"
                :disabled="disableByModal"
                :class="{ disable: disableByModal }"
              />
            </div>
            <div class="selectinput">
              <RgSelectSheet
                id="leaf"
                ref="leaf"
                v-model="form.leaf"
                :period="form.competence"
                :rules="{ required: true }"
                :disabled="disableByModal"
                :class="{ disable: disableByModal }"
                @lastSheet="getLastSheet"
              />
            </div>
            <div class="procedure">
              <RgSuggestProcedure
                id="procedure"
                ref="Procedure"
                v-model="form.procedure"
                type="('01')"
                :period="form.competence"
                :occupationCode="form.occupationCode || null"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                :isBilling="true"
                fieldCaption="CO_PROCEDIMENTO - NO_PROCEDIMENTO"
                label="Procedimento"
                placeholder="Digite o cód. ou nome do procedimento"
                @selected="selectProcedure"
              />
            </div>
            <div class="selectinput">
              <RgSuggestCboProcedure
                id="cbo"
                ref="cbo"
                v-model="form.pdf_cod_cbo"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                :sigtap="true"
                :isBilling="true"
                :procedureCode="form.procedureCode || null"
                :competence="form.competence"
                label="CBO/Ocupação"
                class="occupation -margin-right-small"
                placeholder="Digite o CBO ou a ocupação"
                @selected="selectOccupation"
              />
            </div>
            <div class="selectinput">
              <RgInput
                id="age-admission"
                v-model="form.age"
                type="text"
                :disabled="!form.procedure || disabledByModal"
                :class="{ disable: !form.procedure || disabledByModal }"
                :rules="{ number: true }"
                placeholder="000"
                :maxlength="3"
                label="Idade"
                class="inputitem"
              />
            </div>
            <RgInput
              v-model="form.amount"
              label="Quantidade"
              placeholder="000000"
              :maxlength="6"
              :rules="{ number: true }"
              :disabled="!form.procedure || disabledByModal"
              :class="{ disable: !form.procedure || disabledByModal }"
            />
            <div class="actions">
              <RgCleanButton
                :disabled="disabledByModal"
                small
                @click="cleanForm"
              />
              <RgSearchButton
                ref="searchBtn"
                :disabled="isEdition || disabledByModal"
                @submit="searchBillingBpac(true)"
              />
            </div>
          </section>
        </RgValidatorForm>
        <hr class="hr" />
        <section class="consolidated-billing-search">
          <div class="actions">
            <RgAddButton
              id="add-date-hour"
              small
              data-id="adicionar-procedimento"
              :disabled="disabledByModal || disableCreate || disableCompetence"
              title="Adicionar Registro"
              @click="openModalCreateBpac"
            />
            <RgEditButton
              :disabled="disableActionsTable || disabledByModal"
              title="Editar Registro"
              @click="openModalEditBpac"
            />
            <RgLessButton
              :disabled="disableActionsTable || disabledByModal"
              title="Excluir Registro"
              @click="openModalDeleteRegister"
            />
            <SmallButton
              id="duplicate-button"
              class="btns"
              :disabled="
                disableActionsTable || disabledByModal || disableCreate
              "
              :backgroundColor="'#1E88A9'"
              title="Duplicar Registro"
              @click="openModalCreateBpac(true)"
            >
              <IconDuplicateSheet slot="icon" />
            </SmallButton>
          </div>
        </section>
        <section
          class="consolidated-billing-table"
          :disabled="isEdition || disabledByModal"
          :class="{ disable: isEdition || disabledByModal }"
        >
          <div
            v-if="mutableConsolidatedBillingList.length === 0"
            ref="listContent"
            class="no-result"
          >
            <IconEmpty />
            <span v-if="notResultSearch" class="info">
              Não foram encontrados resultados para essa busca
            </span>
            <span v-else class="info">
              Faça uma busca para exibir os resultados aqui
            </span>
          </div>
          <div v-else>
            <SmartTable
              ref="smartTable"
              name="ConsolidatedBillingTable"
              :columns="COLUMNS"
              :body="mutableConsolidatedBillingList"
              :initial-columns="7"
              :total="total"
              :disabled="isEdition || disabledByModal"
              :max-register="20"
              :item-per-page="20"
              index-column="mtp_id"
              toggleSelected
              minHeight
              removeBtnColumns
              :showPagination="false"
              @getLine="selectLine"
            >
              <div slot="bottom-buttons" class="register-page">
                <span>
                  Registros encontrados:
                  {{ mutableConsolidatedBillingList.length }}
                </span>
                <span>
                  Registros por folha:
                  {{ quantityPerSheet }}/20
                </span>
              </div>
            </SmartTable>
          </div>
        </section>
      </div>
    </FormBase>

    <ModalCreateBpaC
      id="modal-create-bpa-c"
      :show="modalCreateBpaC"
      :edition="editBpac"
      :duplicate="duplicate"
      :selectedData="selectedRow"
      @close="closeModalCreateBpac"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      hasSearch
      @close="closeModalDeleteRegister"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgValidatorForm,
  RgInput,
  RgCleanButton,
  RgSearchButton,
  SmallButton,
  IconDuplicateSheet,
  RgEditButton,
  RgAddButton,
  RgSuggestProcedure,
  RgLessButton,
  ModalConfirmDeletion,
  IconEmpty,
  LargeButton,
  IconCalendar,
} from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";
import {
  ModalCreateBpaC,
  RgSelectSheet,
} from "$billing/submodules/bpa/bpa-c/components";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgSelectCompetence from "$billing/common/components/select/rg-select-competence/RgSelectCompetence";
import RgSuggestCboProcedure from "$hospitalization/common/component/suggest/rg-suggest-cbo-procedure/RgSuggestCboProcedure";
import moment from "moment";

const FORM = {
  competence: null,
  leaf: null,
  procedure: null,
  cbo: null,
  age: null,
  amount: null,
  procedureCode: null,
  pdf_cod_cbo: null,
};

export default {
  name: "SearchBpaC",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectCompetence,
    RgSuggestProcedure,
    RgInput,
    RgSuggestCboProcedure,
    SmallButton,
    IconDuplicateSheet,
    RgCleanButton,
    RgSearchButton,
    RgEditButton,
    RgAddButton,
    RgLessButton,
    SmartTable,
    ModalCreateBpaC,
    RgSelectSheet,
    ModalConfirmDeletion,
    IconEmpty,
    LargeButton,
    IconCalendar,
  },

  data() {
    return {
      hasSelectedConsolidatedBilling: false,
      mutableConsolidatedBillingList: [],
      form: this.$utils.obj.DeepCopy(FORM),
      verifyCBO: false,
      total: 0,
      search: {
        consolidatedBilling: null,
      },
      isEdition: false,
      selectedRow: null,
      disableCreate: true,
      lastSheet: null,
      modalDeleteRegister: false,
      modalCreateBpaC: false,
      editBpac: false,
      disableCompetence: false,
      notResultSearch: false,
      currentLeaf: null,
      duplicate: false,
    };
  },

  computed: {
    ...mapGetters({
      clientId: "Login/GET_USER_ID_CLIENT",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
      competence: "Billing/BDSia/GET_PERIOD_DATE",
    }),
    disableByModal() {
      return this.modalDeleteRegister || this.modalCreateBpaC;
    },
    disableActionsTable() {
      return (
        this.isEdition ||
        this.disableCompetence ||
        !this.hasSelectedConsolidatedBilling
      );
    },

    propsModalConfirmDeletion() {
      const show = this.modalDeleteRegister;
      const confirm = () =>
        this.removeConsolidatedBilling(this.selectedRow.fco_id);
      const noReason = true;

      const title = "Excluir Sequência de Registro BPA-C";
      const message =
        "O registro selecionado será removido da remessa <br/> contida na Folha da produção atual";
      const btnRemoveTitle = "Excluir";

      const bodyText = `Folha: ${this.selectedRow?.fco_folha} <br />
      Sequência: ${this.selectedRow?.fco_sequencia} <br />
      Procedimento: ${this.selectedRow?.procedureName} <br />
      CBO: ${this.selectedRow?.fco_cbo} <br />
      Idade: ${
        this.selectedRow?.fco_idade ? this.selectedRow?.fco_idade : "-"
      } <br />
      Quantidade: ${this.selectedRow?.fco_quantidade}`;

      const hasSearch = true;
      const justifyContent = false;
      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
        hasSearch,
        justifyContent,
      };
    },
    disabledByModal() {
      return this.modalDeleteRegister || this.modalCreateBpaC;
    },
  },

  watch: {
    async "form.competence"(pValue) {
      if (pValue) {
        try {
          this.$loader.start();
          await this.disabledCompetence();
          this.mutableConsolidatedBillingList = [];
          this.cleanSelectRow();
        } finally {
          this.$loader.finish();
        }
      }
    },
    "form.procedure"(pValue, pPrev) {
      if (pValue !== pPrev) {
        this.form.age = null;
        this.form.amount = null;
      }
    },
    "form.age"(pValue) {
      if (pValue) {
        this.form.age = this.form.age?.replace(/^0+/, "");
      }
    },
    "form.amount"(pValue) {
      if (pValue) {
        this.form.amount = this.form.amount?.replace(/^0+/, "");
      }
    },
    async "form.leaf"(pValue, pPrev) {
      if (pValue) {
        if (
          (Number(this.form.leaf) === Number(this.lastSheet) ||
            this.lastSheet === null) &&
          (await this.disabledCompetence())
        ) {
          this.disableCreate = false;
        } else {
          this.disableCreate = true;
        }
      }
    },
  },

  async mounted() {
    this.notResultSearch = false;
    this.form.competence = this.competence;
    this.form.cnes = this.currentUnitHealth.uns_cnes;
    this.verifyCBO = true;
  },

  created() {
    this.COLUMNS = [
      { name: "Competência", key: "fco_competencia", align: "right" },
      { name: "Folha", key: "fco_folha", align: "right" },
      { name: "Sequência", key: "fco_sequencia", align: "right" },
      { name: "Procedimento", key: "procedureName", align: "left" },
      { name: "CBO/Ocupação", key: "fco_cbo_nome", align: "left" },
      { name: "Idade", key: "fco_idade", align: "right" },
      { name: "Quantidade", key: "fco_quantidade", align: "right" },
    ];
  },

  methods: {
    selectCompetence() {
      this.$router.push({
        name: "billing.bpa.define-period",
      });
    },
    openModalCreateBpac(isDuplicate = false) {
      if (!isDuplicate) {
        this.selectedRow.competence = this.form.competence;
        this.modalCreateBpaC = true;
      } else {
        this.selectedRow.competence = this.$store.getters[
          "Billing/BDSia/GET_PERIOD_DATE"
        ];

        this.selectedRow.fco_idade = null;
        this.selectedRow.fco_quantidade = null;

        this.duplicate = isDuplicate;

        this.modalCreateBpaC = true;
      }
    },
    getLastSheet(lastSheet) {
      this.lastSheet = lastSheet;
    },
    openModalEditBpac() {
      this.editBpac = true;
      this.currentLeaf = this.form.leaf;
      this.modalCreateBpaC = true;
    },
    openModalDeleteRegister() {
      this.modalDeleteRegister = true;
      this.currentLeaf = this.form.leaf;
    },
    async closeModalCreateBpac() {
      this.modalCreateBpaC = false;
      this.cleanSelectRow();
      this.$loader.start();
      await this.$refs.leaf.getSheets();
      if (this.editBpac) {
        this.form.leaf = this.currentLeaf;
      }
      this.editBpac = false;
      this.duplicate = false;

      await this.searchBillingBpac(false);
    },
    closeModalDeleteRegister() {
      this.modalDeleteRegister = false;
      this.cleanSelectRow();
    },
    async removeConsolidatedBilling(pId) {
      try {
        this.modalDeleteRegister = false;

        await this.$store.dispatch(
          "Billing/BpacBilling/REMOVE_CONSOLIDATED_BILLING",
          {
            variables: { consolidatedBilling: pId },
          },
        );

        await this.$refs.leaf.getSheets();
        await this.searchBillingBpac(false);

        if (this.mutableConsolidatedBillingList.length === 0) {
          this.form.leaf = "1";
          this.currentLeaf = "1";
        }
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar registros");
      }
    },
    selectProcedure(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { CO_PROCEDIMENTO } = source;
        this.form.procedureCode = CO_PROCEDIMENTO;
        this.verifyCBO = true;
      } else {
        if (pValue.source === "") {
          if (this.verifyCBO) {
            this.form.pdf_cod_cbo = null;
            this.form.occupationCode = null;
            this.form.procedureCode = null;
            this.verifyCBO = false;
          }
        }
      }
    },
    selectOccupation(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { ocp_codigo } = source;

        this.form.occupationCode = ocp_codigo;
        this.verifyCBO = true;
      } else {
        if (pValue.source === "") {
          if (this.verifyCBO) {
            this.form.procedure = null;
            this.form.occupationCode = null;
            this.form.procedureCode = null;
            this.verifyCBO = false;
          }
        }
      }
    },
    async searchBillingBpac(hasLoader = true) {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        if (hasLoader) this.$loader.start();
        let occupationCode = null;
        const regexLength = /^\d{6}$/;
        const regexNumber = /\d/;

        regexLength.test(this.form.pdf_cod_cbo) &&
        regexNumber.test(this.form.pdf_cod_cbo)
          ? (occupationCode = this.form.pdf_cod_cbo)
          : (occupationCode = this.form.occupationCode);

        const variables = {
          cboCode: occupationCode,
          period: this.form.competence?.replaceAll("-", ""),
          fco_id_unidades_saude: this.currentUnitHealth.uns_id,
          procedureCode: this.form.procedureCode,
          sheet: this.form.leaf,
          age: this.form.age,
          amount: this.form.amount,
        };

        const result = await this.$store.dispatch(
          "Billing/BpacBilling/LIST_CONSOLIDATED_BILLING",
          variables,
        );

        this.notResultSearch = true;

        const quantityPerSheet = await this.$store.dispatch(
          "Billing/BpacBilling/GET_QUANTITY_SHEET_CONSOLIDATED_BILLING",
          variables,
        );

        this.quantityPerSheet = quantityPerSheet;

        const aux = result?.consolidatedBillings?.rows;
        this.mutableConsolidatedBillingList = [];
        this.mutableConsolidatedBillingList = aux;
        this.mutableConsolidatedBillingList.map((item, index) => {
          item.procedureName =
            item.procedure.CO_PROCEDIMENTO +
            " - " +
            item.procedure.NO_PROCEDIMENTO;

          item.fco_cbo_nome = item.occupation
            ? item.fco_cbo + " - " + item.occupation.NO_OCUPACAO
            : "";
          item.fco_cbo_code = item.fco_cbo;
          if (!item.fco_competencia.includes("/")) {
            item.fco_competencia =
              item.fco_competencia.substring(4, 6) +
              "/" +
              item.fco_competencia.substring(0, 4);
          }
          item.fco_sequencia = (index + 1).toString();
          return item;
        });

        this.total = Number(result.total);

        if (
          (this.mutableConsolidatedBillingList.length < 20 ||
            Number(this.form.leaf) === Number(this.lastSheet)) &&
          (await this.disabledCompetence())
        ) {
          this.disableCreate = false;
        } else {
          this.disableCreate = true;
        }
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar registros");
      } finally {
        this.cleanSelectRow();
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionDone();
        this.$loader.finish();
      }
    },
    async disabledCompetence() {
      const result = await this.$store.dispatch(
        "Billing/BPAI/LAST_COMPETENCE_BPAI",
      );

      const lastCompetence = result[0].DT_COMPETENCIA;
      const limitRetroactive = moment(lastCompetence, "YYYY-MM")
        .subtract(3, "months")
        .format("YYYYMM");

      if (Number(limitRetroactive) <= Number(this.form.competence)) {
        this.disableCompetence = false;
        this.disableCreate = false;
        return true;
      } else {
        this.disableCompetence = true;
        this.disableCreate = true;
        return false;
      }
    },
    chooseCompetence(pValue) {
      this.form.iap_codigo_procedimentos = null;
      this.form.competence = null;
      this.form.competence = pValue.value;
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    cleanSelectRow() {
      this.selectedRow = {};
      this.hasSelectedConsolidatedBilling = false;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    cleanForm() {
      this.notResultSearch = false;
      this.form.procedure = null;
      this.form.cbo = null;
      this.form.pdf_cod_cbo = null;
      this.form.age = null;
      this.form.amount = null;
      this.selectedRow = {};
      this.hasSelectedConsolidatedBilling = false;
      this.isEdition = false;
      this.mutableConsolidatedBillingList = [];
      if (this.$refs.leaf) this.$refs.leaf.cleanValidate();
      this.cleanSelectRow();
    },
    async selectLine(pValue) {
      if (pValue) {
        this.hasSelectedConsolidatedBilling = true;
        this.selectedRow = pValue;
      } else {
        this.hasSelectedConsolidatedBilling = false;
        this.selectedRow = {};
      }
    },
  },
};
</script>
