<template lang="html">
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.69645 14.9672C4.95807 14.7129 4.95812 14.3004 4.69645 14.0459L2.28791 11.7043L9.43544 11.7043C9.80547 11.7043 10.1055 11.4126 10.1055 11.0528C10.1055 10.693 9.80547 10.4014 9.43544 10.4014L2.28778 10.4014L4.69636 8.05959C4.95803 7.80523 4.95803 7.39273 4.69636 7.13832C4.43469 6.88391 4.01047 6.88391 3.7488 7.13832L0.19648 10.5922C0.0707815 10.7144 0.000204868 10.88 0.000204861 11.0528C0.000204853 11.2256 0.0708263 11.3913 0.19648 11.5135L3.74889 14.9673C4.01056 15.2216 4.43478 15.2216 4.69645 14.9672Z"
      fill="white"
    />
    <path
      d="M7.30355 0.190981C7.04193 0.445349 7.04188 0.857845 7.30355 1.11226L9.71209 3.4539L2.56456 3.45394C2.19453 3.45394 1.89453 3.74562 1.89453 4.10539C1.89453 4.46516 2.19453 4.75684 2.56456 4.75684L9.71222 4.75679L7.30364 7.09861C7.04197 7.35298 7.04197 7.76547 7.30364 8.01988C7.56531 8.2743 7.98953 8.2743 8.2512 8.01988L11.8035 4.566C11.9292 4.44384 11.9998 4.27815 11.9998 4.10539C11.9998 3.93263 11.9292 3.7669 11.8035 3.64473L8.25111 0.190938C7.98944 -0.0634301 7.56522 -0.063431 7.30355 0.190981Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15C11 17.7614 13.2386 20 16 20ZM15.0811 17.5737C15.1623 17.5737 15.2404 17.5392 15.2977 17.4774L18.5002 14.0737C18.6797 13.8814 18.6797 13.5691 18.5002 13.3764L18.2836 13.1439C18.1044 12.9513 17.8134 12.9513 17.6338 13.1439L15.0811 15.85L14.0012 14.6909C13.8217 14.4983 13.531 14.4983 13.3514 14.6909L13.1348 14.9234C12.9553 15.1157 12.9553 15.4281 13.1348 15.6208L14.8645 17.4774C14.9218 17.5392 14.9999 17.5737 15.0811 17.5737Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "AttendTransfer",
};
</script>
