<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchPlaces from "./action/SearchPlaces";

export default {
  name: "RgSelectPlacesExam",
  extends: RgSelect,
  props: {
    sectorId: {
      required: true,
    },
    label: {
      type: String,
      default: "Local de Atendimento",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    sectorId() {
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (this.sectorId) {
        const data = await SearchPlaces({ intIdSetor: this.sectorId });

        this.valuesData = data.map((item) => {
          return { value: item.data, label: item.lca_nome };
        });

        if (this.valuesData?.length === 1) {
          this.$emit("selectOnlyItem", this.valuesData[0].value);
        }
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
