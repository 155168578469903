import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyCentralTransferReport(
    $unitHealth: Int
    $product: Int
    $far_id_origem: Int
    $mtp_id: Int
    $far_id_destino: Int
    $trs_lote: String
    $unitHealthName: String
    $pharmacyName: String
    $productName: String
    $mtp_nome: String
    $far_nome_origem: String
    $far_nome_destino: String
    $fel_validade_lote: String
    $initialDate: String
    $finalDate: String
    $loggedUserId: Int!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyCentralTransferReport(
      uns_id: $unitHealth
      mpd_id: $product
      mtp_id: $mtp_id
      far_id_destino: $far_id_destino
      far_id_origem: $far_id_origem
      trs_lote: $trs_lote
      uns_nome: $unitHealthName
      far_nome: $pharmacyName
      mpd_nome: $productName
      mtp_nome: $mtp_nome
      far_nome_origem: $far_nome_origem
      far_nome_destino: $far_nome_destino
      fel_validade_lote: $fel_validade_lote
      initialDate: $initialDate
      finalDate: $finalDate
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        PRODUTO
        NOME_COMERCIAL
        DATA
        FARMACIA_ORIGEM
        FARMACIA_DESTINO
        QUANTIDADE
        VALOR_TOTAL
        VALOR_UNITARIO
        TIPO
        LOTE_VALIDADE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyCentralTransferReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
