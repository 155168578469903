<template lang="html">
  <div v-if="show" class="modal-change-professional-scale">
    <RgValidatorForm ref="validator">
      <RgBaseModal
        ref="Modal"
        v-shortkey="['esc']"
        :show="show"
        with-close-button
        class="rg-base-modal"
        @close="close"
        @shortkey.native="close"
      >
        <div slot="header" class="header">
          <div class="title">Editar Escala de Atendimento</div>
        </div>

        <div slot="body" class="body">
          <FormBase class="form-base" title="Escala do Dia">
            <div class="grid informations">
              <div>
                Unidade de Saúde:
                <strong>
                  {{ mData.uns_nome ? mData.uns_nome : this.UNITHEALTHNAME }}
                </strong>
              </div>

              <div>
                Setor:
                <strong> {{ mData.set_nome }} </strong>
              </div>

              <div>
                Profissional:
                <strong>
                  {{ mData.pes_nome ? mData.pes_nome : mData.fun_nome }}
                </strong>
              </div>

              <div>
                Data:
                <strong>
                  {{
                    mData.data_consulta
                      ? mData.data_consulta
                      : mData.DataConsulta
                  }}
                </strong>
              </div>
            </div>

            <div class="inputs">
              <div class="input">
                <RgInput
                  id="inicio-atendimentos"
                  v-model="mData.eag_inicio_atendimentos"
                  v-mask="'##:##'"
                  class="field"
                  label="Horário inicial de atendimento"
                  :rules="{ required: true }"
                />
              </div>

              <div class="input">
                <RgInput
                  id="quantidade-consultas"
                  v-model="mData.eag_quantidade_consultas"
                  v-mask="'###'"
                  class="field"
                  label="Quantidade de consultas"
                  :rules="{ required: true, fn: validateNumberAppointments }"
                />
              </div>

              <div class="input">
                <RgInput
                  id="intervalo-consultas"
                  v-model="mData.eag_intervalo_consultas"
                  v-mask="'###'"
                  class="field"
                  label="Intervalo entre consultas"
                  :rules="{ required: true, fn: validateMinute }"
                />
              </div>

              <div class="input">
                <RgInput
                  id="final-atendimentos"
                  v-model="mData.eag_final_atendimentos"
                  v-mask="'##:##'"
                  class="field"
                  :class="{ disable: true }"
                  disabled
                  label="Último horário de atendimento"
                />
              </div>

              <div class="input">
                <RgInput
                  id="last-schedule"
                  v-model="lastSchedule"
                  v-mask="'##:##'"
                  disabled
                  :class="{ disable: true }"
                  class="field"
                  label="Horário final de atendimento"
                />
              </div>
            </div>
          </FormBase>
        </div>

        <div slot="footer" class="footer">
          <div class="footer-buttons">
            <RgCancelButton
              id="cancel-btn"
              class="cancel"
              medium
              @click="close"
            />

            <RgConfirmButton
              id="confirm-btn"
              ref="confirmButton"
              v-shortkey="['enter']"
              large
              class="confirm"
              :disabled="disableButton"
              @shortkey.native="save"
              @click="save"
            />
          </div>
        </div>
      </RgBaseModal>
    </RgValidatorForm>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  RgInput,
  RgBaseModal,
  RgConfirmButton,
  RgCancelButton,
  RgValidatorForm,
} from "~tokio/primitive";
import moment from "moment";

import { IsValid } from "~common/utils/date";

export default {
  name: "ModalChangeProfessionalScale",
  components: {
    RgInput,
    RgBaseModal,
    RgConfirmButton,
    RgCancelButton,
    FormBase,
    RgValidatorForm,
  },
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    normalScalePermission: {
      type: Number,
      default: 1,
    },
    technicalScalePermission: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      lastSchedule: null,
      mData: {},
      payload: {},
      disableButton: false,
    };
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
    data: {
      handler() {
        this.updateLocalData();
      },
      deep: true,
    },
    "mData.eag_inicio_atendimentos"(pValue) {
      this.calculateLastSchedule();
    },

    "mData.eag_intervalo_consultas"(pValue) {
      this.calculateLastSchedule();
    },

    "mData.eag_quantidade_consultas"(pValue) {
      this.calculateLastSchedule();
    },
  },
  created() {
    this.UNITHEALTHNAME = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },
  mounted() {
    this.updateLocalData();
    this.calculateLastSchedule();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateLocalData() {
      this.mData = this.$utils.obj.DeepCopy(this.data);
    },
    calculateLastSchedule() {
      this.mData.eag_inicio_atendimentos =
        this.mData.eag_inicio_atendimentos.length > 4
          ? this.mData.eag_inicio_atendimentos.substr(0, 5)
          : this.mData.eag_inicio_atendimentos;
      const durationInMinutes =
        this.mData.eag_intervalo_consultas *
        (this.mData.eag_quantidade_consultas - 1);
      const endTime = moment(this.mData.eag_inicio_atendimentos, "HH:mm")
        .add(durationInMinutes, "minutes")
        .format("HH:mm");
      this.mData.eag_final_atendimentos = endTime;
      this.lastSchedule = moment(endTime, "HH:mm")
        .add(this.mData.eag_intervalo_consultas, "minutes")
        .format("HH:mm");
    },

    calculateFinalTime() {
      const sumMinOfScale =
        this.mData.eag_quantidade_consultas *
        this.mData.eag_intervalo_consultas;

      const finalTime = moment(this.mData.eag_inicio_atendimentos, "HH:mm").add(
        sumMinOfScale,
        "m",
      );

      if (sumMinOfScale > 0) {
        const minutesTo24Hour = moment("23:59:59", "HH:mm").diff(
          finalTime,
          "minutes",
        );
        if (minutesTo24Hour < 0) {
          return true;
        }
      }

      return false;
    },
    async save() {
      try {
        const isInvalid = !(await this.isFormValid());
        if (isInvalid) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.confirmButton.actionDone();

          return false;
        }

        const invalidHours = !this.validHours();

        if (invalidHours) {
          return false;
        }
        if (this.calculateFinalTime()) {
          this.$toaster.warning(
            "A escala ultrapassou 23:59. Caso seja necessário o plantão duplo, faça duas escalas",
          );
          this.$refs.confirmButton.actionDone();
          return false;
        }

        this.makePayload();

        this.disableButton = true;
        this.$refs.confirmButton.actionSubmit();

        await this.$store.dispatch(
          "Appointment/Schedule/SAVE_PROFESSIONAL_SCALE",
          this.payload,
        );

        this.$toaster.success("Salvo com sucesso");
        this.$emit("reSearch", true);
        this.close();
      } catch (error) {
        const { esus_response } = error;
        if (esus_response?.hasError) {
          return this.$emit("error", error);
        } else {
          this.$toaster.warning(
            `Erro ao alterar dados do dia ${this.$utils.sanitize.formatError(
              error,
            )}`,
          );
        }
        this.$refs.confirmButton.fail();
      } finally {
        this.disableButton = false;
        this.$refs.confirmButton.actionDone();
      }
    },

    makePayload() {
      this.payload = {
        hag_data_consulta: this.mData.data_consulta || this.mData.DataConsulta,
        hag_hora_inicio_consulta_funcionario: this.mData
          .eag_inicio_atendimentos,
        hag_id: this.mData.hag_id,
        hag_id_dias_semana: this.mData.eag_id_dias_semana,
        hag_id_escalas_agendamentos: this.mData.eag_id,
        hag_intervalo_consultas: this.mData.eag_intervalo_consultas,
        hag_quantidade_consultas: this.mData.eag_quantidade_consultas,
        hag_ultimo_horario: this.mData.eag_final_atendimentos,
        horarioFinal: this.lastSchedule,
        permissaoEscalaNormal: this.normalScalePermission,
        permissaoReservaTecnica: this.technicalScalePermission,
      };
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    validHours() {
      if (
        !IsValid(
          `1900-01-01 ${this.mData.eag_inicio_atendimentos}`,
          "YYYY-MM-DD LT",
          true,
        )
      ) {
        this.$toaster.warning("Horário inicial de atendimento inválido");
        this.$refs.confirmButton.actionDone();
        return false;
      }
      return true;
    },

    validateMinute(pValue, pErrors) {
      const isInvalidRange = parseInt(pValue) < 1 || parseInt(pValue) > 60;

      if (isInvalidRange) {
        pErrors.push(`Intervalo deve ser entre 1 e 60 minutos`);
        return false;
      }

      return true;
    },
    validateNumberAppointments(pValue, pErrors) {
      const isInvalidNumber =
        pValue && (pValue === "0" || pValue === "00" || pValue === "000");

      if (isInvalidNumber) {
        pErrors.push("O valor deve ser maior que zero");
        return false;
      }

      return true;
    },
  },
};
</script>
