import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import DefineHospitalizationPeriod from "$billing/submodules/aih/define-hospitalization-period/view/DefinePeriod.vue";

export default {
  path: "/billing/aih/define-hospitalization-period",
  name: "billing.aih.defineHospitalizationPeriod",
  components: {
    default: DefineHospitalizationPeriod,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      { label: "AIH", link: OpenSubModule("billing") },
      { label: "Competência", link: "/define-period" },
    ],
  },
};
