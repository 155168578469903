import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentFormSchedule from "$appointment/submodules/schedule/views/appointment-form-schedule/AppointmentFormSchedule.vue";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/per-day/form",
  name: "appointment.schedule.form",
  components: {
    default: AppointmentFormSchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Agendamento", link: OpenSubModule("appointment") },
      { label: "Agendamentos por Dia", link: "/appointment/schedule/per-day" },
      { label: "Agendar Consulta", link: "/appointment/schedule/per-day/form" },
    ],
  },
};
