<style src="./RgSuggestCity.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-city">
    <RgSuggest
      :id="id"
      ref="rgSuggest"
      v-model="inputValue"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :search-on-focus="searchOnFocus"
      :disabled="disabled"
      :state="state"
      :tab-index="tabIndex"
      :rules="rules"
      min="1"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataId"
        :idx="idx"
        :item="item"
        class="city-item"
      >
        <div class="city-row-top">
          <span class="mun-name">{{ item.mun_nome }}</span>
          <span class="mun-state"
            >{{ item.state.est_nome }} - {{ item.state.est_sigla }}</span
          >
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>

<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchCity } from "./action";
import { isNumber } from "lodash";

export default {
  name: "RgSuggestCity",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "Cidade",
    },
    title: {
      type: String,
      default: "Cidade",
    },
    placeholder: {
      type: String,
      default: "Digite o nome da cidade",
    },
    state: {
      type: Number,
      default: 0,
    },
    filterByState: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      captionRaw: "mun_nome",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCity(this.inputValue);
    });
  },
  methods: {
    doSearch(pSearchTerm, pLimit = null) {
      if (this.filterByState) {
        if (this.state > 0) {
          return isNumber(pSearchTerm)
            ? SearchCity({
                munId: pSearchTerm,
                mun_id_estados: this.state,
                mun_ativo: this.active,
                limit: pLimit,
              })
            : SearchCity({
                munNome: pSearchTerm,
                mun_id_estados: this.state,
                mun_ativo: this.active,
                limit: pLimit,
              });
        }
      } else {
        return isNumber(pSearchTerm)
          ? SearchCity({
              munId: pSearchTerm,
              mun_ativo: this.active,
              limit: pLimit,
            })
          : SearchCity({
              munNome: pSearchTerm,
              mun_ativo: this.active,
              limit: pLimit,
            });
      }
    },
    async loadCity(pValue) {
      if (pValue < 1) return;

      if (pValue !== this.inputValue) this.inputValue = pValue;

      const result = await this.doSearch(pValue);
      if (result && Array.isArray(result) && result.length > 0) {
        if (result.length === 1) this.selectingItemFromSuggestList(result[0]);
        else {
          const selectedItem = result.find((item) => item.mun_id === pValue);
          this.selectingItemFromSuggestList(selectedItem);
        }
      }
    },

    validate() {
      this.$refs.rgSuggest.validate();
    },

    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },
    clearData() {
      this.suggestionList = null;
    },
    forceSelection(pValue) {
      if (pValue && pValue.mun_nome) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            mun_nome: pValue.mun_nome,
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
