<style src="./RgSuggestEthnicityAb.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-ethnicity-ab">
    <RgSuggest
      :id="id"
      ref="rgSuggest"
      v-model="inputValue"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :search-on-focus="searchOnFocus"
      :disabled="disabled"
      :tab-index="tabIndex"
      :rules="rules"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataId"
        :idx="idx"
        :item="item"
        class="ethnicity-item"
      >
        <div v-if="concatenateCodeName" class="ethnicity-row-top">
          <span class="ethnicity-description">
            {{ item.ens_codigo_ab + " - " + item.ens_descricao }}
          </span>
        </div>
        <div v-else class="ethnicity-row-top">
          <span class="ethnicity-description"> {{ item.ens_descricao }} </span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>

<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import SearchEthnicityAb from "./action/SearchEthnicityAb";

export default {
  name: "RgSuggestBasicAttentionEthnicity",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Etnia",
    },
    title: {
      type: String,
      default: "Etnia",
    },
    placeholder: {
      type: String,
      default: "Digite a etnia",
    },
    concatenateCodeName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "ens_descricao",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  mounted() {
    if (this.concatenateCodeName) {
      this.captionRaw = "ens_codigo_ab - ens_descricao";
    }
  },
  methods: {
    doSearch(pSearchTerm, pLimit = null) {
      return SearchEthnicityAb({
        ens_descricao: pSearchTerm,
        limit: pLimit,
      });
    },
    forceSelection(pValue) {
      if (pValue && pValue.ens_descricao) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            ens_id: pValue.ens_id,
            ens_codigo_ab: pValue.ens_codigo_ab,
            ens_descricao: pValue.ens_descricao,
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    validate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.validate();
    },
    cleanValidate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.cleanValidate();
    },
    clear() {
      this.$emit("clear");
    },
  },
};
</script>
