<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.42857 9.99986C4.42857 8.92843 4.71429 7.92843 5.21429 7.07129H1.28571C0.571429 7.07129 0 7.64272 0 8.357V11.5713C0 12.2856 0.571429 12.857 1.28571 12.857H5.21429C4.71429 12.0713 4.42857 11.0713 4.42857 9.99986Z"
      fill="white"
    />
    <path
      d="M18.7137 7.07129H14.7852C15.2852 7.92843 15.5709 8.92843 15.5709 9.99986C15.5709 11.0713 15.2852 12.0713 14.7852 12.9284H18.7137C19.428 12.9284 19.9994 12.357 19.9994 11.6427V8.42843C19.9994 7.71415 19.428 7.07129 18.7137 7.07129Z"
      fill="white"
    />
    <path
      d="M18.7143 0H1.28571C0.571429 0 0 0.571429 0 1.28571V4.5C0 5.21429 0.571429 5.78571 1.28571 5.78571H6.28571C7.28571 4.92857 8.57143 4.35714 10 4.35714C11.4286 4.35714 12.7143 4.85714 13.7143 5.78571H18.7143C19.4286 5.78571 20 5.21429 20 4.5V1.28571C20 0.571429 19.4286 0 18.7143 0Z"
      fill="white"
    />
    <path
      d="M18.7143 14.2144H13.7143C12.7143 15.0715 11.4286 15.6429 10 15.6429C8.57143 15.6429 7.28571 15.1429 6.28571 14.2144H1.28571C0.571429 14.2144 0 14.7858 0 15.5001V18.7144C0 19.4286 0.571429 20.0001 1.28571 20.0001H18.7143C19.4286 20.0001 20 19.4286 20 18.7144V15.5001C20 14.7858 19.4286 14.2144 18.7143 14.2144Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "StickRow",
};
</script>
