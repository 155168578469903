<template>
  <div class="complexity">
    <RgSearch
      ref="rgsearch"
      v-model="mutableComplexity"
      :result-title="'Complexidade de Procedimentos'"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :searchAreaHeight="tableHeight"
      :item-height="34"
      :max-register="30"
      :disabled="disabledByModal"
      :class="{ disable: disabledByModal }"
      @count="getCountValue"
      @submitFromButton="cleanSelectRow"
      @viewClickPagination="cleanSelectRow"
      @afterPerformSearch="afterSearchFilter"
    >
      <div slot="filters" class="complexity-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-health"
              ref="unitHealth"
              v-model="form.unitHealth"
              :class="{ disable: true }"
              :disabled="true"
              class="inputitem"
              label="Unidade de Saúde"
              permission="faturamento.faturar"
            />
          </div>
          <div class="selectinput">
            <RgInput
              id="procedure"
              ref="procedures"
              v-model="form.procedure"
              label="Procedimento"
              class="inputitem"
              placeholder="Digite o código ou o nome do procedimento"
              :rules="{ required: true, fn: validMinChar }"
            />
          </div>
          <div class="selectinput">
            <RgSuggestCid
              id="cid"
              v-model="form.cid"
              :class="{ disable: disabledByModal }"
              :disabled="disabledByModal"
              :competence="form.competence"
              @selected="selectCid"
            />
          </div>
          <div class="selectinput">
            <RgComboboxInstrument id="instrument" v-model="form.instrument" />
          </div>
          <div class="selectinput">
            <RgSuggestCboProcedure
              id="cbo"
              ref="cbo"
              v-model="form.occupation"
              sigtap
              lastCompetence
              label="Ocupação"
              class="occupation -margin-right-small"
              @selected="selectOccupation"
            />
          </div>
          <Tooltip class="tooltip" clickable startOpen>
            <IconExclamationHelper slot="icon" hollow />
            <div slot="content" class="content">
              <div class="title">
                <span>
                  O preenchimento do campo “Procedimento” é obrigatório:
                </span>

                <ul class="list">
                  <li class="item">
                    A busca por nome pode exibir um ou mais resultados,
                    permitindo a alteração de complexidade por lote.
                  </li>
                  <li class="item">
                    Para realizar essa alteração em lote basta selecionar os
                    registros desejados e adicionar nova complexidade (botão
                    verde).
                  </li>
                </ul>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>

      <div ref="radios" slot="search-top" class="complexity-top-buttons">
        <div slot="legends" class="radios">
          <div class="tooltips">
            <Tooltip class="tooltip">
              <IconInfoHelper
                slot="icon"
                style="width: 28.5px; height: 28.5px"
              />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    A complexidade de procedimento é determinada pelo DATASUS. A
                    busca é divida pelas classificações da tabela SIGTAP e
                    customizada pela Unidade.
                  </span>
                </div>
              </div>
            </Tooltip>
            <Tooltip class="tooltip">
              <IconLightBulbHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Selecione mais de um filtro, escolha entre os filtros
                    “SIGTAP” e “Customizada”. Depois selecione a complexidade
                    que deseja buscar.
                  </span>
                </div>
              </div>
            </Tooltip>
          </div>
          <br />
          <div class="radio-build-complexity">
            <RgRadioCustom
              id="status"
              v-bind="propsRadioCustom"
              :disabledByModal="disabledByModal"
              :canUnselect="false"
              class="radio-status"
              @input="onInputRadioCustom"
            />
            <div class="vertical-row"></div>
            <RgRadioCustom
              id="status"
              v-bind="propsRadioCustomComplexity"
              :disabledByModal="disabledByModal"
              :canUnselect="false"
              @input="onInputRadioCustomComplexity"
            />
          </div>
        </div>
      </div>

      <div class="complexity-search-list">
        <SmartTable
          ref="smartTable"
          name="Complexity"
          :columns="COLUMNS"
          :body="mutableComplexity"
          :dynamic-height="getSpaceTable"
          :total="Number(total)"
          :initial-columns="7"
          index-column="frd_id"
          toggle-selected
          :showPagination="false"
          class="smart-table"
          separatorSeeMore=";"
          :multSelect="true"
          :hasCheck="true"
          @getMultLines="selectMultLine"
        >
          <div slot="top-buttons" class="actions">
            <div class="right-buttons">
              <RgAddButton
                id="cadastrar-complexidade"
                small
                class="btns"
                title="Adicionar complexidade ao procedimento SIGTAP"
                :disabled="
                  !hasSelectedLine || disabledByModal || form.custom === 0
                "
                @click="openModalBuildComplexity"
              />

              <RgEditButton
                id="editar-complexidade"
                small
                title="Editar procedimento de complexidade customizada"
                :disabled="
                  !hasSelectedLine || disabledByModal || form.custom === 1
                "
                @click="openModalBuildComplexity(true)"
              />

              <RgLessButton
                id="excluir-complexidade"
                small
                title="Excluir a complexidade do procedimento"
                :disabled="
                  !hasSelectedLine ||
                  disabledByModal ||
                  this.selectedRow.length > 1 ||
                  form.custom === 1
                "
                @click="openModalDeleteComplexity"
              />
              <RgShowButton
                id="visualizar-complexidade"
                small
                title="Visualizar Complexidade"
                :disabled="
                  !hasSelectedLine ||
                  disabledByModal ||
                  this.selectedRow.length > 1
                "
                @click="openModalShowComplexity"
              />
            </div>
          </div>
        </SmartTable>
      </div>
    </RgSearch>
    <ModalNewComplexity
      :show="modalBuildComplexity"
      :isEdition="edit"
      :selectedLine="selectedRow"
      :reLoader="reSearch"
      @close="closeModalBuildComplexity"
    />
    <ModalSeeComplexity
      :show="modalShowComplexity"
      :selectedLine="selectedRow"
      @close="closeModalShowComplexity"
    />
    <ModalConfirmDeletion
      v-bind="propsModalConfirmDeletion"
      class="modal-confirm-deletion"
      :justify-content="false"
      @close="closeModalDeleteComplexity"
    />
  </div>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import {
  RgEditButton,
  RgAddButton,
  RgLessButton,
  RgShowButton,
  ModalConfirmDeletion,
  Tooltip,
  IconExclamationHelper,
  RgRadioCustom,
  RgSelectUnithealth,
  RgSuggestCid,
  RgInput,
  IconLightBulbHelper,
  IconInfoHelper,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgComboboxInstrument from "$billing/submodules/complexity/components/combobox/rg-combobox-instrument/RgComboboxInstrument";
import RgSuggestCboProcedure from "$hospitalization/common/component/suggest/rg-suggest-cbo-procedure/RgSuggestCboProcedure";

import ModalNewComplexity from "$billing/submodules/complexity/components/modal/modal-new-complexity/ModalNewComplexity.vue";
import ModalSeeComplexity from "$billing/submodules/complexity/components/modal/modal-see-complexity/ModalSeeComplexity.vue";
import { mapGetters } from "vuex";

const FORM = {
  unitHealth: null,
  complexity: 4,
  procedure: null,
  cid: null,
  codeCid: null,
  instrument: null,
  occupation: null,
  codeOccupation: null,
  custom: 1,
  competence: null,
};

export default {
  name: "buildComplexity",
  components: {
    RgSearch,
    SmartTable,
    RgEditButton,
    RgAddButton,
    RgLessButton,
    RgShowButton,
    ModalNewComplexity,
    ModalSeeComplexity,
    ModalConfirmDeletion,
    Tooltip,
    IconExclamationHelper,
    RgRadioCustom,
    RgSelectUnithealth,
    RgComboboxInstrument,
    RgInput,
    RgSuggestCid,
    RgSuggestCboProcedure,
    IconLightBulbHelper,
    IconInfoHelper,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableComplexity: [],
      total: 0,
      tableHeight: null,
      selectedRow: null,
      modalBuildComplexity: false,
      modalDeleteComplexity: false,
      modalShowComplexity: false,
      edit: false,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),
    getSpaceTable() {
      return this.tableHeight;
    },
    hasMutableComplexity() {
      return !(this.mutableComplexity && this.mutableComplexity.length === 0);
    },
    hasSelectedLine() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },

    disabledByModal() {
      return (
        this.modalBuildComplexity ||
        this.modalDeleteComplexity ||
        this.modalShowComplexity
      );
    },
    propsModalConfirmDeletion() {
      const title = "Excluir a Complexidade do Procedimento";
      const message =
        "Após a exclusão da Complexidade, o procedimento será reclassificado pelo padrão SIGTAP.";
      const confirm = () => this.deleteProcedureComplexity();
      const msgSuccess = "A complexidade do procedimento foi removida";
      const show = this.modalDeleteComplexity;
      const noReason = true;
      const bodyText =
        this.selectedRow?.length > 0
          ? `Código: ${this.selectedRow[0].procedureCode} <br/> Procedimento: ${this.selectedRow[0].procedureName} <br/> Complexidade: ${this.selectedRow[0].complexityName}`
          : ` - `;

      const hasSearch = true;
      const hasTooltip = true;
      const messageTooltip =
        "Verifique a possibilidade do procedimento ser classificado como “Não se Aplica”";
      const alignCenter = false;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
        hasTooltip,
        messageTooltip,
        alignCenter,
      };
    },
    propsRadioCustom() {
      const list = [
        { title: "Customizada", id: 0 },
        { title: "SIGTAP", id: 1 },
      ];

      const value = this.form.custom;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomComplexity() {
      const list = [
        { title: "Não se Aplica", id: 0, color: "#3BC0F2" },
        { title: "Atenção Básica", id: 1, color: "#3BC0F2" },
        { title: "Média", id: 2, color: "#3BC0F2" },
        { title: "Alta", id: 3, color: "#3BC0F2" },
        { title: "Todos", id: 4, color: "#3BC0F2" },
      ];

      const value = this.form.complexity;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },

  watch: {
    "form.procedure"(pValue, pPrev) {
      if (pValue === "") {
        if (this.$refs.smartTable) {
          this.$refs.smartTable.cleanSelectRow();
          this.$refs.rgsearch.clearPagination();
        }
        this.mutableComplexity = [];
        this.selectedRow = null;
        this.form.cid = null;
        this.form.codeCid = null;
        this.form.instrument = null;
        this.form.occupation = null;
      }
    },
  },

  mounted() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.form.competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
  },

  created() {
    this.COLUMNS = [
      { name: "Complexidade", key: "complexityName", align: "left" },
      { name: "Instrumento", key: "instrument", align: "left" },
      { name: "Código", key: "procedureCode" },
      { name: "Procedimento", key: "procedureName", align: "left" },
      {
        name: "Quantidade",
        key: "quantity",
        align: "right",
      },
      {
        name: "CID-10",
        key: "cid",
        align: "left",
        seeMore: "true",
      },
      {
        name: "Ocupação",
        key: "occupation",
        align: "left",
        seeMore: "true",
      },
    ];
    this.ALL_COMPLEXITY = 4;
  },

  methods: {
    onInputRadioCustomComplexity(item) {
      if (item) {
        this.form.complexity = item.id;
        this.$refs.rgsearch.submitForm(false, true);
      }
    },
    onInputRadioCustom(item) {
      if (item) {
        this.form.custom = item.id;
        this.$refs.rgsearch.submitForm(false, true);
      }
    },
    openModalBuildComplexity(isEdition = false) {
      this.modalBuildComplexity = true;
      this.edit = isEdition;
    },
    openModalDeleteComplexity() {
      this.modalDeleteComplexity = true;
    },
    openModalShowComplexity() {
      this.modalShowComplexity = true;
    },
    closeModalBuildComplexity() {
      this.modalBuildComplexity = false;
      this.edit = false;
    },
    closeModalDeleteComplexity() {
      this.modalDeleteComplexity = false;
    },
    closeModalShowComplexity() {
      this.modalShowComplexity = false;
    },
    afterSearchFilter() {
      this.getDynamicHeight();
      this.cleanSelectRow();
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgsearch?.$refs.listContent) {
          const headerHeight = 80;
          this.tableHeight =
            this.$refs.rgsearch.$refs.listContent.offsetHeight - headerHeight;
        }
      });
    },

    selectMultLine(pValue) {
      if (pValue !== null) {
        this.selectedRow = pValue;
      } else {
        this.selectedRow = null;
      }
    },

    generateFilter() {
      const variables = {
        complexity:
          this.form.complexity !== this.ALL_COMPLEXITY &&
          this.form.complexity !== null
            ? this.form.complexity.toString()
            : null,
        procedure: this.form.procedure,
        cid: this.form.codeCid,
        register: this.form.instrument,
        occupation: this.form.codeOccupation,
      };
      return variables;
    },

    async searchFilter(pData) {
      try {
        this.$loader.start();

        const variables = {
          ...pData,
          procedureCode: !isNaN(parseFloat(pData.procedure))
            ? pData.procedure
            : undefined,
          procedureName: isNaN(parseFloat(pData.procedure))
            ? pData.procedure
            : undefined,
          isCustom: Number(this.form.custom),
        };

        const result = await this.$store.dispatch(
          "Billing/Complexity/SEARCH_COMPLEXITY",
          variables,
        );

        return result;
      } catch (e) {
        return this.$toaster.error("Erro ao buscar complexidade");
      } finally {
        this.$loader.finish();
      }
    },
    selectCid(pValue) {
      if (pValue.source) {
        this.form.codeCid = pValue.source.cid_codigo;
      } else {
        this.form.codeCid = null;
      }
    },
    selectOccupation(pValue) {
      if (pValue.source) {
        this.form.codeOccupation = pValue.source.ocp_codigo;
      } else {
        this.form.codeOccupation = null;
      }
    },
    reSearch() {
      this.$refs.rgsearch.performSearch();
    },
    async deleteProcedureComplexity() {
      try {
        this.$loader.start();

        const variables = {
          procedureComplexity: {
            complexity: [{ com_id: Number(this.selectedRow[0].idCustom) }],
            type: "REMOVER",
          },
        };
        await this.$store.dispatch("Billing/Complexity/SAVE_COMPLEXITY", {
          variables,
        });

        this.$toaster.info(
          "O procedimento foi reclassificado pela complexidade da tabela SIGTAP.",
          "A complexidade do procedimento foi removida",
        );
      } catch (error) {
        this.$toaster.error(error, "Erro ao remover complexidade");
      } finally {
        this.$loader.finish();
        this.reSearch();
      }
    },
    validMinChar(pValue, pErrors) {
      const valid = pValue && pValue.length < 5;

      if (valid) {
        pErrors.push("Digite no mínimo 5 caracteres");
        return false;
      }
    },
    cleanSelectRow() {
      this.selectedRow = null;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
    },
    cleanForm() {
      this.mutableComplexity = [];
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.selectedRow = null;
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },
  },
};
</script>
