import { DateNow } from "~utils/date";

const formatDddPhoneNumber = (pPhone) => {
  if (pPhone?.length === 0) return "";
  const phoneDdd = pPhone.substr(0, 2);
  const phonePrefix = pPhone.substr(2, pPhone.length - 6);
  const phoneSufix = pPhone.substr(pPhone.length - 4);

  return `(${phoneDdd}) ${phonePrefix}-${phoneSufix}`;
};

const ConvertIndividualRegistrationToPatientAbEsus = (
  pData,
  pPatientBasicAttentionId,
  pPatientId,
  pVerifyOriginalRegistrationId = false,
) => {
  const visitHome = pData.visita_domiciliar;
  const teamRegistration = pData?.equipe;
  const teamHomeVisit = visitHome?.equipe;

  const CADASTRO_INDIVIDUAL = 1;
  const VISITA_DOMICILIAR = 2;

  const teamEmployeeAbRegistration = teamRegistration?.map((obj) => {
    return {
      fea_ficha: CADASTRO_INDIVIDUAL,
      fea_funcionario_nome: obj.funcionario_nome,
      fea_ocupacao_nome: obj.ocupacao_nome,
      fea_ocupacao_codigo: obj.ocupacao_codigo,
    };
  });

  const teamEmployeeAbHomeVisit = teamHomeVisit?.map((obj) => {
    return {
      fea_ficha: VISITA_DOMICILIAR,
      fea_funcionario_nome: obj.funcionario_nome,
      fea_ocupacao_nome: obj.ocupacao_nome,
      fea_ocupacao_codigo: obj.ocupacao_codigo,
    };
  });

  const teamEmployeeAb = (teamEmployeeAbRegistration || []).concat(
    teamEmployeeAbHomeVisit || [],
  );

  const patientBasicAttention = {
    pae_id: Number(pPatientBasicAttentionId),
    ...(pVerifyOriginalRegistrationId &&
      pData.pab_id !== pData.pab_id_payload && {
        pae_ficha: Number(pData.pab_id),
      }),
    pae_ficha_base: Number(pData.pab_id),
    pae_id_pacientes: Number(pPatientId),
    pae_nome_paciente: pData.pab_nome,
    pae_nome_mae: pData.pab_nome_mae,
    pae_nascimento: pData.pab_nascimento,
    pae_cpf: pData.pab_cpf || null,
    pae_cartao_sus: pData.pab_cartao_sus || null,
    pae_sexo: pData.sexo,
    pae_data_atendimento: pData.pab_data_atendimento,
    pae_unidade_saude: pData.unidade_nome,
    pae_area: pData.area_nome,
    pae_microarea: pData.microarea_nome,
    pae_equipe: pData.equipe_nome,
    pae_responsavel: pData.pessoa_funcionario_nome,
    pae_ocupacao_responsavel: pData.ocupacao_nome,
    pae_codigo_ocupacao_responsavel: pData.ocupacao_codigo,
    pae_data_visita_domiciliar: visitHome?.data_atendimento,
    pae_responsavel_visita_domiciliar: visitHome?.pessoa_funcionario_nome,
    pae_unidade_saude_visita_domiciliar: visitHome?.unidade_nome,
    pae_area_visita_domiciliar: visitHome?.area_nome,
    pae_equipe_visita_domiciliar: visitHome?.equipe_nome,
    pae_ocupacao_responsavel_visita_domiciliar: visitHome?.ocupacao_nome,
    pae_codigo_ocupacao_responsavel_visita_domiciliar:
      visitHome?.ocupacao_codigo,
    pae_microarea_visita_domiciliar: visitHome?.microarea_nome,
    equipe: teamEmployeeAb,
    pae_nome_social: pData.pab_nome_social || null,
    pae_nome_pai: pData.pab_nome_pai || null,
    pae_raca_cor: pData.raca_cor || null,
    pae_etnia: pData.etnia_indigina || null,
    pae_telefone: pData.pab_telefone_residencial
      ? formatDddPhoneNumber(pData.pab_telefone_residencial)
      : null,
    pae_celular: pData.pab_celular
      ? formatDddPhoneNumber(pData.pab_celular)
      : null,
    pae_email: pData.pab_email || null,
    pae_endereco: pData.endereco_domicilio || null,
    pae_naturalidade: pData.municipio_nascimento || null,
    pae_nacionalidade: pData.pais_nascimento || null,
    pae_data_naturalizacao: pData.pab_data_naturalizacao || null,
    pae_portaria_naturalizacao: pData.pab_portaria_naturalizacao || null,
    pae_data_entrada_brasil: pData.pab_data_entrada_brasil || null,
    pae_motivo_saida_cadastro_cidadao: pData.motivo_saida_cadastro || null,
    pae_numero_obito: pData.scc_numero_obito || null,
    pae_data_obito: pData.scc_data_obito || null,
    pae_ocupacao_paciente: pData.ocupacao_paciente || null,
    pae_codigo_ocupacao_paciente: pData.ocupacao_codigo_paciente || null,
    pae_situacao_saude: JSON.stringify(pData.situacao_saude) || null,
    pae_codigo_ine_cabecalho_ficha: pData.codigo_ine_cabecalho_ficha || null,
    pae_ativo: 1,
    pae_ultima_alteracao: DateNow(),
  };

  return patientBasicAttention;
};

export { ConvertIndividualRegistrationToPatientAbEsus };
