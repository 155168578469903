<style src="./RgSuggestProceduresExam.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="rgSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :search-on-focus="false"
    :rules="rules"
    min="2"
    class="suggest-procedure"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="procedure-item"
    >
      <div class="procedure-row-top">
        <b class="procedure-name">{{ item.stp_novo_nome_procedimento }}</b>
      </div>
      <div class="procedure-row-info">
        {{ item.stp_novo_codigo_procedimento }}
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchProceduresExame from "./action/SearchProceduresExam";
export default {
  name: "RgSuggestProceduresExam",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Procedimento",
    },
    dataItem: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Digite o procedimento",
    },
  },
  data() {
    return {
      captionRaw: "stp_novo_codigo_procedimento",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  watch: {},
  mounted() {
    this.captionRaw =
      "stp_novo_codigo_procedimento - stp_novo_nome_procedimento";
  },
  methods: {
    validate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.validate();
    },
    async doSearch(pSearchTerm, pLimit = null) {
      const variables = this.defineQuery(pSearchTerm, pLimit);
      return await SearchProceduresExame({ variables });
    },

    defineQuery(pSearchTerm, pLimit = null, offset = 0) {
      const variables = {
        procedureName: null,
        procedureCode: null,
        limit: pLimit,
        offset: offset,
      };

      const query = pSearchTerm.toString().trim();

      if (query) {
        if (query !== null && query.includes("-")) {
          const term = query.split(" - ");
          !isNaN(term[0])
            ? (variables.procedureCode = term[0])
            : (variables.procedureName = query);
        } else {
          !isNaN(query)
            ? (variables.procedureCode = query)
            : (variables.procedureName = query);
        }
      }
      return variables;
    },
  },
};
</script>
