<template lang="html">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 493.356 493.355"
    style="enable-background: new 0 0 493.356 493.355"
    xml:space="preserve"
  >
    <g>
      <path
        d="M355.457,370.873c-1.523-3.614-4.288-5.428-8.285-5.428h-63.954V9.135c0-2.666-0.858-4.856-2.569-6.567
    C278.944,0.855,276.753,0,274.084,0h-54.818c-2.667,0-4.854,0.855-6.567,2.568c-1.711,1.711-2.57,3.901-2.57,6.567v356.314h-63.953
    c-3.806,0-6.567,1.81-8.28,5.428c-1.521,3.613-1.043,6.943,1.431,9.996L240.68,490.505c1.903,1.902,4.187,2.851,6.854,2.851
    c2.478,0,4.665-0.948,6.567-2.851l99.927-109.632C356.503,377.82,356.983,374.49,355.457,370.873z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "ArrowDown",
};
</script>
