<style src="./RgSuggestNationality.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-nationality">
    <RgSuggest
      :id="id"
      ref="rgSuggest"
      v-model="inputValue"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :rules="rules"
      min="1"
      :search-on-focus="searchOnFocus"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataId"
        :idx="idx"
        :item="item"
        class="nat-item"
      >
        <div class="nat-row-top">
          <span class="nat-name">{{ item.nac_nome }}</span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchNationality } from "./action";

export default {
  name: "RgSuggestNationality",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
    hasCodeBasicAttention: Boolean,
  },
  data() {
    return {
      captionRaw: "nac_nome",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    doSearch(pSearchTerm, pLimit = null) {
      return SearchNationality({
        nacNome: pSearchTerm,
        nac_ativo: this.active,
        has_nac_codigo_ab: this.hasCodeBasicAttention,
        limit: pLimit,
      });
    },
    validate() {
      this.$refs.rgSuggest.validate();
    },
    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },
    forceSelection(pValue) {
      if (pValue && pValue.nac_nome) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            nac_nome: pValue.nac_nome,
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
