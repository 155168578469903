<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchProcedures from "./action/SearchProcedures";

export default {
  name: "RgSelectProceduresByHealthplan",
  extends: RgSelect,
  props: {
    variable: Object,
    label: {
      type: String,
      default: "Procedimentos",
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userId: this.$store.getters["Login/GET_USER_ID"],
      valuesData: [],
    };
  },
  watch: {
    variable(value) {
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (!this.variable.intIdVinculoSetor) {
        this.mValue = "";
        return Promise.resolve([]);
      }
      if (!this.variable.intIdPlanoSaude) {
        this.mValue = "";
        return Promise.resolve([]);
      }

      const data = await SearchProcedures(this.variable);
      this.valuesData = data.map((el) => {
        if (parseInt(el.sip_id) > 0) {
          return {
            value: el.sip_id,
            label: `${el.stp_novo_nome_procedimento}`,
            item: el,
          };
        } else {
          return {
            value: el.label,
            label: `${el.stp_novo_nome_procedimento}`,
            item: el,
          };
        }
      });

      this.$emit("valuesData", this.valuesData);
      return this.valuesData;
    },
    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
