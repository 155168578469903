<template lang="html">
  <section class="report-centralizer">
    <RgSearch
      ref="SearchRefs"
      v-model="mutableReport"
      :build-filter="buildFilter"
      :search-function="searchFunction"
      :print-report-css="printReportCss"
      result-title="Relatórios"
      :clear-function="clearFilter"
      :item-height="29"
      :max-register="50"
      :disableButtonWhenNoFilterSelected="!this.selectedModule"
      filter-title="Filtros"
      showEmptyMessage
      is-report
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
      @clear="clearSearch"
    >
      <div slot="extra" class="modules">
        <RgSelect
          v-show="!isFromSpecificModule"
          id="busca-modulo"
          v-model="selectedModule"
          :value="selectedModule"
          :placeholder="`Selecione um módulo`"
          :options="MODULES"
          data-id="busca-modulo"
          data-item="resultado-modulo"
          class="modulesItem"
          label="Módulo"
          openOnlyBottom
          :rules="{ required: true }"
        />

        <RgSelect
          id="busca-relatorio"
          v-model="selectedReport"
          :value="selectedReport"
          :placeholder="`Selecione um relatório`"
          :options="reports"
          data-id="busca-relatorio"
          data-item="resultado-relatorio"
          class="modulesItem"
          maxHeight="400px"
          label="Dados para Exibição"
          openOnlyBottom
          :rules="{ required: true }"
        />
      </div>

      <div slot="filters" class="filters">
        <component
          :is="component"
          ref="Report"
          class="component"
          :columns-to-print="columnsToPrint"
          :id-sub-report="idSubReport"
          :group-by-name="groupByName"
          @openModalGroupBy="openModalGroupBy"
          @cleanGroupBy="selectedCleanGroupBy"
        />
      </div>

      <RgCarousel
        v-if="showCarousel"
        slot="search-top"
        :mutationItems="idSubReport"
      >
        <div slot="carousel" ref="radios" class="report-centralizer-top">
          <div slot="legends" class="radios">
            <RgRadioCustom
              id="situation"
              v-bind="propsTagCarousel"
              :refreshValue="form.activeCarousel"
              :carousel="true"
              @input="onInputRadioCustom"
            />
          </div>
        </div>
      </RgCarousel>
      <div
        v-if="!showCarousel"
        ref="radios"
        slot="search-top"
        class="report-centralizer-top"
      >
        <div slot="legends" class="radios">
          <RgTagCustom
            v-if="resultTitle && selectedReport"
            id="situation"
            v-bind="propsTagCustom"
          />
        </div>
      </div>

      <div class="report-centralizer-search">
        <SmartTable
          ref="smartTable"
          :name="getNameSmartTable"
          :columns="columnReportTable"
          :body="mutableReport"
          :total="Number(total)"
          :initialColumns="getInitialColumns"
          :showPagination="false"
          :dynamic-height="getSpaceTable"
          :show-select-all-page="false"
          :index-column="indexColumn"
          :isSeeMore="isSeeMore"
          :columnsToPrint="columnsToPrint"
          :useIndexAsKey="useIndexAsKey"
          :isReport="true"
          toggle-selected
          :dontSelect="!isDontSelect"
          class="smart-table"
          @columnToSeeMore="columnToSeeMore"
          @getLine="selectRowData"
          @columnsToPrint="getColumnsToPrint"
        >
          <div slot="legends" class="legends">{{ getLegend }}</div>
          <div slot="top-buttons" class="top-buttons-centralizer">
            <RgShowButton
              v-if="isSeeMore"
              class="title-space"
              :disabled="!allowedShowButton"
              @click="openSeeMore"
            />

            <RgDropdown
              v-if="true"
              id="excel"
              ref="btnExcel"
              :disabled="getCountValue < 1"
              :action-options="itemActionExport()"
              large
              label="Exportar"
              title="Exportar Relatório"
              class="dropdown title-space"
            >
              <IconArrowDownload slot="icon" />
            </RgDropdown>

            <RgPrinterButton
              v-if="true"
              id="imprimir"
              :disabled="getCountValue < 1"
              large
              data-id="imprimir"
              title="Imprimir Relatório"
              class="print title-space"
              @click="printReport"
            />

            <ModalSeeMore
              id="modal-see-more"
              :show="modalSeeMore"
              :title="title"
              :selectedData="selectedRow"
              :columnsToSee="columnsToPrint"
              :columnToSeeMore="columnDataToSee"
              @close="closeSeeMore"
            />
          </div>
        </SmartTable>
      </div>
    </RgSearch>
    <ModalGroupBy
      id="modal-confirm"
      ref="confirmClearing"
      :report="reportGroupBy"
      :show="showModalGroupBy"
      :clean-group-by="cleanGroupBy"
      @select="groupByNameSelect"
      @close="closeModalGroupBy"
    />
  </section>
</template>

<script>
import { AlertError } from "$exam/common/services";
import { PrintReport, DownloadFile } from "~tokio/foundation/rg-search/helpers";
import ModalSeeMore from "~tokio/primitive/modal/modal-see-more/ModalSeeMore";
import ModalGroupBy from "~tokio/primitive/modal/modal-group-by/ModalGroupBy";
import {
  RgSelect,
  RgPrinterButton,
  RgDropdown,
  RgTagCustom,
  RgRadioCustom,
  RgCarousel,
} from "~tokio/primitive";
import { RgShowButton } from "~tokio/primitive/button";
import { RgSearch, SmartTable } from "~tokio/foundation";
import { IconArrowUp, IconArrowDownload } from "~tokio/primitive/icon/symbols";
import MODULES_LIST from "./ListModules";
import {
  LIST_REPORT_PHARMACY_CENTRAL,
  LIST_REPORT_PHARMACY_SATELLITE,
} from "./ListReportsPharmacy";

const FORM_REPORT = {
  statusProduct: -1,
  activeCarousel: true,
};

export default {
  name: "ReportCentralizer",
  components: {
    IconArrowUp,
    RgSearch,
    RgSelect,
    SmartTable,
    RgPrinterButton,
    RgShowButton,
    ModalSeeMore,
    IconArrowDownload,
    RgDropdown,
    RgTagCustom,
    RgRadioCustom,
    RgCarousel,
    ModalGroupBy,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_REPORT),
      useIndex: false,
      mutableReport: [],
      selectedModule: null,
      selectedReport: null,
      selectSubReport: null,
      filterComponent: null,
      reversed: false,
      modalSeeMore: false,
      isFromSpecificModule: false,
      total: 0,
      dynamicHeight: null,
      indexColumn: "",
      selectedRow: null,
      activeRow: null,
      showCarousel: false,
      columnDataToSee: null,
      columnsToPrint: [],
      idSubReport: null,
      groupByName: null,
      showModalGroupBy: false,
      cleanGroupBy: false,
      report: {
        resultTitle: "",
        printReportCss: "",
        doSearch: () => {},
        buildFilter: () => {},
        clearFilter: () => {},
      },
      subReport: {
        doSearchSubReport: () => {},
      },
      canCleanSelectedModule: true,
      prefixTable: "",
    };
  },
  computed: {
    reportGroupBy() {
      return this.component?.name;
    },
    propsTagCustom() {
      const list = [{ title: this.resultTitle, id: 1 }];
      const values = [1];

      return { list, values };
    },
    propsTagCarousel() {
      if (this.selectedReport !== null) {
        const list =
          this.selectedReport.name.toString() === "PharmacyCentralReport"
            ? this.REPORTS_PHARMACY_CENTRAL
            : this.REPORTS_PHARMACY_SATELLITE;

        const value = this.form.statusProduct;
        const uniqueKey = "id";

        return { list, value, uniqueKey };
      } else {
        return null;
      }
    },
    allowedShowButton() {
      if (this.selectedRow !== null && this.selectedRow !== undefined) {
        return Object.keys(this.selectedRow).length > 0;
      }

      return false;
    },
    isSeeMore() {
      return this.report
        ? this.report.seeMore
          ? this.report.seeMore
          : false
        : false;
    },
    canCleanSelectedReport() {
      return this.report
        ? this.report.cleanSelectedReport
          ? this.report.cleanSelectedReport
          : false
        : false;
    },
    title() {
      return this.report
        ? this.report.titleSeeMore
          ? this.report.titleSeeMore
          : ""
        : "";
    },
    isDontSelect() {
      return this.report
        ? this.report.dontSelect !== undefined
          ? true
          : this.report.dontSelect
        : true;
    },
    getSpaceTable() {
      return this.dynamicHeight;
    },
    reports() {
      return this.selectedModule ? this.selectedModule : [];
    },
    component() {
      return this.selectedReport ? this.selectedReport : null;
    },
    resultTitle() {
      return this.report ? this.report.resultTitle : "";
    },

    searchFunction() {
      if (this.showCarousel) {
        return this.subReport && this.idSubReport !== null
          ? this.subReport.doSearchSubReport
          : () => {
              this.$toaster.warning(
                "Os filtros estão localizados abaixo do título 'Relatórios'.",
                "Selecione um dos Filtros para exibir os resultados",
              );
            };
      } else {
        return this.report ? this.report.doSearch : () => {};
      }
    },
    printReportCss() {
      return this.report ? this.report.printReportCss : "";
    },
    columnReportTable() {
      return this.report ? this.report.columnTable : "";
    },
    buildFilter() {
      return this.report ? this.report.buildFilter : () => {};
    },
    clearFilter() {
      return this.report ? this.report.clearFilter : () => {};
    },
    getLegend() {
      return this.report ? this.report.getLegend : "";
    },
    getNameSmartTable() {
      return this.report
        ? this.prefixTable + this.report.getNameSmartTable
        : null;
    },
    getInitialColumns() {
      return this.report && this.report.initialColumns
        ? this.report.initialColumns
        : 6;
    },
    useIndexAsKey() {
      return this.report ? this.report.useIndexAsKey : false;
    },
  },

  watch: {
    selectedModule(pValue) {
      this.$nextTick(() => {
        this.selectedReport = null;
        this.selectedRow = null;
        this.columnsToPrint = [];
      });
    },
    "form.statusProduct"(pValue, pPrev) {
      if (pValue !== pPrev) {
        this.mutableReport = [];
        this.total = 0;
        this.$refs.SearchRefs.clearForm();
      }
    },
    selectedReport(pValue) {
      const showOptionsReport = [
        "PharmacyCentralReport",
        "PharmacySatelliteReport",
      ];
      this.form.activeCarousel = !this.form.activeCarousel;
      if (pValue === null) {
        this.showCarousel = false;
        this.mutableReport = [];
        this.total = 0;
        this.selectedRow = null;
        return null;
      }
      this.canCleanSelectedModule = false;
      this.$refs.SearchRefs.clearForm();
      this.idSubReport = null;
      this.showCarousel = showOptionsReport.includes(pValue.name.toString());
      this.loadFilterReport(pValue);
    },

    mutableReport(pValue) {
      if (pValue && pValue.length > 0) {
        this.setIndex(pValue);
      }
    },
  },

  created() {
    this.MODULES = MODULES_LIST;
    this.REPORTS_PHARMACY_CENTRAL = LIST_REPORT_PHARMACY_CENTRAL;
    this.REPORTS_PHARMACY_SATELLITE = LIST_REPORT_PHARMACY_SATELLITE;
  },

  mounted() {
    const isRouteCentralizer = this.$route.name === "report.centralizer";
    if (isRouteCentralizer) {
      this.MODULES = MODULES_LIST.filter((item) => {
        return item.active !== false && this.hasPermission(item.permission);
      });
      this.prefixTable = "Centralizer";
    } else {
      this.MODULES = MODULES_LIST;
      this.prefixTable = "Module";
    }
    this.preparePage();
    window.addEventListener("resize", this.getDynamicHeight);
  },
  methods: {
    selectedCleanGroupBy(pValue) {
      this.cleanGroupBy = pValue;
    },
    loadFilterReport(pValue) {
      try {
        if (pValue) this.$loader.start("Carregando Relatório...");
        this.mutableReport = [];
        this.total = 0;
        this.form.statusProduct = null;

        this.$nextTick(() => {
          this.report = this.$refs.Report;
          this.subReport = { doSearchSubReport: () => {} };
          this.selectedRow = null;
          this.cleanGroupBy = true;
        });
      } finally {
        if (pValue) this.$loader.finish(1000);
      }
    },
    onInputRadioCustom(item) {
      if (item) {
        this.idSubReport = item.id;
        this.form.statusProduct = item.id;
        this.selectSubReport = item.value;
        this.$nextTick(() => {
          this.subReport = this.$refs.Report.$refs.SubReport;
        });
      } else {
        this.$nextTick(() => {
          this.$refs.Report.component = null;
        });
        this.idSubReport = null;
        this.form.statusProduct = null;
      }
    },
    hasPermission(pPermission) {
      return this.$Permissions.global.has(pPermission);
    },
    clearSearch(pValue) {
      if (this.canCleanSelectedReport && this.canCleanSelectedModule) {
        this.selectedReport = null;
      }
      if (
        !(this.report && this.selectedReport) &&
        !this.isFromSpecificModule &&
        this.canCleanSelectedModule
      ) {
        this.selectedModule = null;
      }
      if (this.selectedRow !== null && this.isSeeMore) {
        this.selectedRow = null;
      }
      this.canCleanSelectedModule = true;
    },
    openSeeMore() {
      this.modalSeeMore = true;
    },
    closeSeeMore() {
      this.modalSeeMore = false;
    },
    async selectRowData(pItem, pIndex) {
      this.selectedRow = pItem;
      this.activeRow = pIndex;
    },
    columnToSeeMore(item) {
      this.columnDataToSee = item;
    },
    preparePage() {
      try {
        this.$loader.start("Carregando Relatórios...");
        this.isFromSpecificModule = false;
        this.getDynamicHeight();

        const moduleName = this.$route.meta.breadcrumb[0].label;
        const specificModule = this.MODULES.find(
          (item) => item.label === moduleName,
        );

        if (specificModule) {
          this.isFromSpecificModule = true;
          this.selectedModule = specificModule.value;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$loader.finish(1000);
      }
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    getDynamicHeight() {
      if (this.$refs.SearchRefs && this.$refs.SearchRefs.$refs.listContent) {
        const radios = this.$refs.radios.offsetHeight;
        const listContent = this.$refs.SearchRefs.$refs.listContent
          .offsetHeight;
        const height = listContent - radios - 50;

        this.dynamicHeight = height;
      }
    },

    afterSearchFilter() {
      this.getDynamicHeight();
      if (this.$refs.smartTable) {
        this.$refs.smartTable.setScrollTopZero();
      }
    },

    setIndex(pValue) {
      const nameObjects = Object.keys(pValue[0]);
      nameObjects.forEach((item) => {
        const hasId = item.indexOf("_id");
        if (hasId !== -1) this.indexColumn = item;
      });
    },

    getColumnsToPrint(pColumns) {
      this.columnsToPrint = pColumns;
    },
    prepareData(pToPrint = false, pToCsv = false, pToExcel = false) {
      const filterData = this.buildFilter();

      const data = {
        ...filterData,
        toPrint: pToPrint,
        toCsv: pToCsv,
        toExcel: pToExcel,
      };

      if (this.infiniteLimit) {
        this.pagination.limit = 99999;
      }

      if (data.arrFormData) {
        data.arrFormData.limiteInicio = this.pagination.offset;
        data.arrFormData.limiteFim = this.pagination.limit;
        return data;
      }

      if (data.arrFiltros) {
        data.arrFiltros.limiteInicio = this.pagination.offset;
        data.arrFiltros.limiteFim = this.pagination.limit;
        return data;
      }

      if (data.arrFiltro) {
        data.arrFiltro.limiteInicio = this.pagination.offset;
        data.arrFiltro.limiteFim = this.pagination.limit;
        return data;
      }

      return pToPrint || pToCsv || pToExcel
        ? data
        : {
            ...data,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          };
    },
    async downloadCsvReport() {
      try {
        this.$refs.btnExcel.setWaiting();
        if (this.isWaitingResult) {
          this.$refs.btnExcel.setStartOrFinish();
          return;
        }
        this.isWaitingResult = true;
        this.$loader.start();
        const data = this.prepareData(false, true);
        const result = await this.searchFunction(data);
        DownloadFile(result.csv, "csv", result.fileName);
      } catch (error) {
        AlertError(error.message);
      } finally {
        this.isWaitingResult = false;
        this.$loader.finish();
        this.$refs.btnExcel.setStartOrFinish();
      }
    },
    async downloadExcelReport() {
      try {
        this.$refs.btnExcel.setWaiting();
        if (this.isWaitingResult) {
          this.$refs.btnExcel.setStartOrFinish();
          return;
        }
        this.isWaitingResult = true;
        this.$loader.start();
        if (this.$refs.SearchRefs.pagination.total === 0)
          throw new Error("Nenhum registro a ser gerado.");
        const data = this.prepareData(false, false, true);
        const result = await this.searchFunction(data);

        DownloadFile(result.excel, "xlsx", result.fileName);
      } catch (error) {
        AlertError(error.message);
      } finally {
        this.isWaitingResult = false;
        this.$loader.finish();
        this.$refs.btnExcel.setStartOrFinish();
      }
    },
    itemActionExport() {
      return [
        {
          label: 'Arquivo no Formato ".XLSX"',
          action: this.downloadExcelReport,
        },
        {
          label: 'Arquivo no Formato ".CSV"',
          action: this.downloadCsvReport,
        },
      ];
    },
    async printReport() {
      try {
        if (this.isWaitingResult) {
          return;
        }
        this.isWaitingResult = true;

        this.$loader.start();
        const data = this.prepareData(true);
        const result = await this.searchFunction(data);

        PrintReport(result, this.printReportCss);
      } catch (error) {
        AlertError("Não foi possível gerar a impressão.");
      } finally {
        this.isWaitingResult = false;
        this.$loader.finish();
      }
    },

    openModalGroupBy() {
      this.showModalGroupBy = true;
    },
    groupByNameSelect(pValue) {
      this.groupByName = pValue;
    },
    closeModalGroupBy() {
      this.showModalGroupBy = false;
      this.cleanGroupBy = false;
    },
  },
};
</script>
