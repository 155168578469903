<template>
  <section class="person-contacts">
    <FormBase title="Contatos" class="form-base-contacts">
      <div class="grid-contacts">
        <div class="row-1">
          <div class="contact">
            <RgInput
              id="contato"
              ref="contacts"
              v-model="contacts"
              :class="{ disable: cantEdit }"
              :disabled="cantEdit"
              :rules="{ required: isRequiredPhone, fn: validateContact }"
              :change="disableOrEnabledRequired"
              :maxlength="20"
              label="Contato"
              placeholder="Digite o nome do contato"
            />
          </div>

          <RgSelectDddInputPhone
            id="numero-telefone"
            ref="phone"
            v-model="tel"
            :class="{ disable: cantEdit }"
            :rules="{
              required: isRequiredPhone,
              fn: validatePhone,
            }"
            :disabled="cantEdit"
            :change="disableOrEnabledRequired"
            @getDdd="getDddData"
          />

          <RgToggleButton
            id="receber-notificacao"
            class="receiveNotification"
            description="Receber notificações por SMS?"
            :class="{ disable: !isPhoneNumber }"
            :disabled="!isPhoneNumber"
            :value="receiveNotification"
            :valueSync="true"
            @change="handleReceiveNotification"
          />

          <RgSaveButton
            v-if="isEdit"
            id="salvar-telefone"
            ref="save"
            medium
            title="Salvar"
            class="btnSave"
            @click="savePhone"
          />

          <RgAddButton
            v-else
            id="adiciona-telefone"
            :class="{ disable: existPhoneData }"
            :disabled="existPhoneData"
            :backgroundColor="'#1E88A9'"
            class="btnPlus"
            title="Adicionar"
            @click="savePhone"
          />

          <RgInputEmail
            id="email-paciente"
            ref="email"
            v-model="email"
            class="mail"
            label="E-mail"
            :placeholder="emailPlaceHolder"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            :rules="{ required: isPrivateSector }"
            :maxlength="75"
          />
        </div>
        <div class="areatable" :class="{ widthArea: phones.length > 0 }">
          <SmartTable
            v-if="phones.length > 0"
            ref="smartTable"
            name="PersonContatcs"
            :columns="COLUMNS"
            :body="phones"
            :total="phones.length"
            :show-pagination="false"
            :initial-columns="3"
            toggleSelected
            remove-btn-columns
            @getLine="selectLine"
          >
            <div slot="top-buttons" class="top-buttons">
              <RgEditButton
                id="editar-contato"
                class="mg-right"
                :disabled="!hasSelectedContact"
                @click="contactListUpdateItem"
              />

              <RgLessButton
                id="deletar-contato"
                :disabled="!hasSelectedContact"
                @click="documentListRemoveItem"
              />
            </div>
          </SmartTable>
          <div v-else class="empty">
            <IconEmpty class="svg" />
            <span>Não há contatos cadastrados</span>
          </div>
        </div>
      </div>

      <div v-if="hasPatientAbEsus" class="contact-ab">
        <span class="contact-ab-subtitle">Dados da Atenção Básica</span>

        <hr class="divider" />

        <div class="basic-attention-data">
          <span>
            <Tooltip
              class="tooltip"
              startOpen
              :message="`Atualizada em: ${formatDate(
                patientInfo.pae_ultima_alteracao,
              )}`"
            >
              <IconInfoHelper slot="icon" />
            </Tooltip>
          </span>
          <span class="telephone-ab">
            Telefone AB:
            <strong> {{ patientInfo.pae_telefone }} </strong>
          </span>
          <span class="cell-phone-ab">
            Celular AB:
            <strong> {{ patientInfo.pae_celular }} </strong>
          </span>
        </div>
      </div>
    </FormBase>
  </section>
</template>

<script>
import { toast, AlertError } from "~tokio/primitive/notification";
import { mapGetters } from "vuex";
import {
  RgInput,
  RgInputEmail,
  RgToggleButton,
  RgAddButton,
  RgSaveButton,
  RgEditButton,
  RgLessButton,
  IconEmpty,
  IconInfoHelper,
  Tooltip,
} from "~tokio/primitive";
import { SmartTable } from "~tokio/foundation";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgSelectDddInputPhone from "$person/common/components/input/rg-select-ddd-input-phone/RgSelectDddInputPhone";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "PersonContacts",
  components: {
    FormBase,
    RgInput,
    RgInputEmail,
    RgToggleButton,
    RgSelectDddInputPhone,
    RgAddButton,
    RgSaveButton,
    RgEditButton,
    RgLessButton,
    SmartTable,
    IconEmpty,
    IconInfoHelper,
    Tooltip,
  },
  props: {
    cantEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contacts: "",
      notificationId: null,
      tel: null,
      ddd: null,
      phones: [],
      existIndexPhone: null,
      notifications: [],
      receiveNotification: false,
      email: null,
      hasSms: false,
      selectedContact: null,
      selectedContactIndex: null,
      editContact: false,
      isRequiredPhone: false,
    };
  },
  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      contactData: "Person/Patient/GET_CONTACT",
      isPrivateSector: "Login/GET_PRIVATE_SECTOR",
      validatePersonPhone: "Login/GET_PHONE_REQUIRED",
      isNewBorn: "Person/Patient/IS_NEWBORN",
      patientInfo: "Person/Patient/GET_PATIENT_INFO",
    }),

    existPhoneData() {
      const existContact = this.contacts && this.contacts.length > 2;
      const existTelNumber = this.tel && this.tel.length > 8;

      return !(
        existContact &&
        this.ddd &&
        existTelNumber &&
        this.contactIsValid
      );
    },

    contactIsValid() {
      if (!this.contacts) return false;
      const format = /[\d!0-9@#$%^&*()_+\-={};':"\\|,.|]+/gi;
      return !format.test(this.contacts);
    },

    isPhoneNumber() {
      const number = this.removeDashesAndDots(this.tel);
      const hasNineDigits = number && number.length === 9;

      const firstNumberIsNine = hasNineDigits
        ? Number(number.substring(0, 1)) === 9
        : false;

      return hasNineDigits && firstNumberIsNine;
    },

    hasSelectedContact() {
      return this.selectedContact;
    },

    isEdit() {
      return this.editContact;
    },

    emailPlaceHolder() {
      return this.isPrivateSector
        ? "Digite um e-mail"
        : "exemplo@exemplo.com.br";
    },

    fromBioslab() {
      const routeExist = ValidateIfRouteExistInBreadscrumb(
        "/exam/laboratory/details",
        this.$route.meta.breadcrumb,
      );

      return routeExist;
    },

    hasPatientAbEsus() {
      return this.patientInfo?.pae_ativo;
    },
  },

  watch: {
    contactData(pValue) {
      if (pValue) {
        this.mountData(pValue);
      }
    },
    tel(pValue, pPreviously) {
      const isDifferent = pValue !== pPreviously;
      const isToDisableNotification = isDifferent && !this.isPhoneNumber;

      if (isToDisableNotification) {
        this.receiveNotification = false;
      }
    },
    phones(pValue) {
      const validateTelephone =
        this.validatePersonPhone || this.isPrivateSector || this.fromBioslab;

      if (validateTelephone) {
        pValue && pValue.length >= 1
          ? (this.isRequiredPhone = false)
          : (this.isRequiredPhone = true);
      }
    },
  },

  mounted() {
    this.requiredPhone();
  },

  created() {
    this.COLUMNS = [
      { name: "Contato", key: "contact" },
      { name: "Telefone", key: "telDdd" },
      { name: "", key: "sms" },
    ];
  },

  methods: {
    mountData(pValue) {
      this.phones = [];
      const telephones = pValue.telephones ? pValue.telephones.rows : [];
      const notifications = pValue.notification ? pValue.notification.rows : [];

      this.setTelephoneAndNotification(telephones, notifications);
      this.email = pValue.emails;
      this.VerifiySmsToSetRequired();
    },

    setTelephoneAndNotification(telephones, notifications) {
      if (
        telephones &&
        telephones.length > 0 &&
        (!notifications || (notifications && notifications.length === 0))
      ) {
        telephones.forEach((item) => {
          const number = item.tel_numero.replace(/-/g, "");
          const formatNumber = number.replace(/^(\d{4,5})(\d{4}$)/gi, "$1-$2");

          this.phones.push({
            id: item.tel_id,
            contact: this.$utils.sanitize.removeExtraSpaces(item.tel_recado),
            tel: formatNumber,
            ddd: item.ddd,
            telDdd: `(${item.ddd.ddd_codigo}) ${formatNumber}`,
            sms: " ",
          });
        });
      }

      if (notifications && notifications.length > 0 && !telephones) {
        notifications.forEach((item) => {
          if (item.nop_celular) {
            const number = item.nop_celular.replace(/-/g, "");
            const formatNumber = number.replace(
              /^(\d{4,5})(\d{4}$)/gi,
              "$1-$2",
            );

            this.phones.push({
              id: item.tel_id,
              contact: "",
              tel: formatNumber,
              ddd: item.ddd,
              telDdd: `(${item.ddd.ddd_codigo}) ${formatNumber}`,
              sms: " ",
            });
          }
        });
      }

      if (
        telephones &&
        telephones.length > 0 &&
        notifications &&
        notifications.length > 0 &&
        !this.isNewBorn
      ) {
        telephones.forEach((pTelephone) => {
          const number = pTelephone.tel_numero.replace(/-/g, "");
          const formatNumber = number.replace(/^(\d{4,5})(\d{4}$)/gi, "$1-$2");

          const phone = {
            id: pTelephone.tel_id,
            contact: this.$utils.sanitize.removeExtraSpaces(
              pTelephone.tel_recado,
            ),
            tel: formatNumber,
            ddd: pTelephone.ddd,
            notification: false,
            telDdd: `(${pTelephone.ddd.ddd_codigo}) ${formatNumber}`,
            sms: " ",
          };

          notifications.forEach((pNotfications) => {
            if (
              pTelephone.tel_numero === pNotfications.nop_celular &&
              pTelephone.ddd.ddd_codigo === pNotfications.ddd.ddd_codigo
            ) {
              phone.notification = true;
              phone.nop_id = pNotfications.nop_id;
              phone.sms = "SMS";
            }
          });
          this.phones.push(phone);
        });
      }

      if (
        telephones &&
        telephones.length > 0 &&
        notifications &&
        notifications.length > 0 &&
        this.isNewBorn
      ) {
        telephones.forEach((pTelephone) => {
          const number = pTelephone.tel_numero.replace(/-/g, "");
          const formatNumber = number.replace(/^(\d{4,5})(\d{4}$)/gi, "$1-$2");

          const phone = {
            id: pTelephone.tel_id,
            contact: this.$utils.sanitize.removeExtraSpaces(
              pTelephone.tel_recado,
            ),
            tel: formatNumber,
            ddd: pTelephone.ddd,
            notification: false,
            telDdd: `(${pTelephone.ddd.ddd_codigo}) ${formatNumber}`,
            sms: " ",
          };

          this.phones.push(phone);
        });
      }
    },

    validateContact(pValue, pErrors) {
      if (!pValue) return true;

      const format = /[\d!0-9@#$%^&*()_+\-={};':"\\|,.|]+/gi;

      if (format.test(pValue)) {
        pErrors.push(
          "Campo inválido. Remova os números ou caracteres especiais.",
        );
        return false;
      }

      if (pValue.length < 3) {
        pErrors.push("Campo deve possuir no mínimo 3 caracteres");
        return false;
      }

      return true;
    },

    validatePhone(pValue, pErrors) {
      if (!pValue) {
        this.$refs.phone.cleanPhone();
        return true;
      }
      if (pValue && pValue.length < 9) {
        pErrors.push("Digite todos os números do telefone");
        return false;
      } else {
        if (!this.ddd) {
          pErrors.push("Selecione um DDD");
          return false;
        }
      }
      return true;
    },

    validateRequiredPhone() {
      if (!this.hasPhoneToReceiveSms()) {
        return AlertError(
          "Obrigatório fornecer um celular com recebimento de SMS",
        );
      }

      return true;
    },

    requiredPhone() {
      this.validatePersonPhone || this.isPrivateSector || this.fromBioslab
        ? (this.isRequiredPhone = true)
        : (this.isRequiredPhone = false);
    },

    hasPhoneToReceiveSms() {
      if (!this.isPrivateSector) return true;

      const hasPhoneToReceiveSms = this.phones.some(
        (phone) => phone.notification,
      );

      return hasPhoneToReceiveSms;
    },

    getDddData(pValue) {
      this.ddd = pValue;
    },

    removeDashesAndDots(pText) {
      return pText ? pText.toString().replace(/[^\d]/g, "") : null;
    },

    getContactForm() {
      let telephones = [];

      telephones = this.phones.map((phone) => {
        return {
          tel_id: phone.id,
          tel_recado: phone.contact,
          tel_numero: this.removeDashesAndDots(phone.tel),
          tel_id_ddds: Number(phone.ddd.ddd_id),
          tel_ddd: Number(phone.ddd.ddd_codigo),
          tel_notification: phone.notification,
          nop_id: phone.nop_id,
        };
      });

      this.email = this.$utils.sanitize.removeExtraSpaces(this.email);

      return {
        email: this.email ? this.email.toLowerCase() : null,
        phones: telephones,
      };
    },

    savePhone() {
      const existNumber = this.phones.some((phone, index) => {
        const equalTel = phone.tel === this.tel;
        const equalDdd = this.ddd.ddd_codigo === phone.ddd.ddd_codigo;
        const isAnotherPhone = index !== this.existIndexPhone;

        return equalTel && equalDdd && isAnotherPhone;
      });

      const hasToReceiveSMS = this.phones.some((phone, index) => {
        const isAnotherPhone = index !== this.existIndexPhone;
        return phone.notification && isAnotherPhone && this.receiveNotification;
      });

      if (existNumber) {
        toast.warning("Esse número de telefone já foi cadastrado");
        return;
      }

      if (hasToReceiveSMS) {
        toast.warning("Já existe um celular cadastrado para receber SMS");
        return;
      }

      const isAddContact = this.existIndexPhone === null;

      const telephone = {
        contact: this.contacts,
        tel: this.tel,
        ddd: this.ddd,
        notification: this.receiveNotification,
        nop_id: this.notificationId,
        telDdd: `(${this.ddd.ddd_codigo}) ${this.tel}`,
        sms: this.receiveNotification ? "SMS" : " ",
      };

      if (isAddContact) {
        this.phones.push(telephone);
      } else {
        telephone.notification = this.receiveNotification;
        this.phones[this.existIndexPhone] = telephone;
      }

      this.cleanContact();
      this.cleanSelectedPhone();
    },

    cleanContact() {
      this.cleanPhoneForm();
      this.cleanReceivedNotification();
      this.cleanValidate();
      this.VerifiySmsToSetRequired();
    },

    selectLine(pValue, pIndex) {
      if (pValue) {
        this.selectedContact = pValue;
        this.selectedContactIndex = pIndex;
      } else {
        this.selectedContact = null;
        this.selectedContactIndex = null;
      }
      this.editContact = false;
      this.cleanContact();
    },

    contactListUpdateItem() {
      this.editContact = true;
      this.existIndexPhone = this.selectedContactIndex;

      this.contacts = this.selectedContact.contact;
      this.tel = this.selectedContact.tel;
      this.ddd = this.selectedContact.ddd;
      this.$refs.phone.fillValue(
        this.selectedContact.tel,
        this.selectedContact.ddd,
      );
      this.receiveNotification = this.selectedContact.notification;
      this.notificationId = this.phones.nop_id;
    },

    documentListRemoveItem() {
      this.phones.splice(this.selectedContactIndex, 1);
      this.VerifiySmsToSetRequired();
      this.cleanContact();
      this.cleanSelectedPhone();
    },

    handleReceiveNotification(pValue) {
      this.receiveNotification = this.isPhoneNumber ? pValue : false;
    },

    cleanPhoneForm() {
      this.contacts = "";
      this.tel = null;
      this.ddd = null;
      this.existIndexPhone = null;
      this.notificationId = null;
      this.$refs.phone.cleanPhone();
    },

    cleanReceivedNotification() {
      this.receiveNotification = false;
      this.$refs.phone.cleanPhone();
    },

    cleanEmailForm() {
      this.email = "";
      this.$refs.email.cleanValidate();
    },

    cleanForm() {
      this.cleanEmailForm();
      this.cleanPhoneForm();
      this.cleanReceivedNotification();
      this.cleanSelectedPhone();
      this.cleanValidate();
      this.phones = [];
      this.email = "";
      this.disableOrEnabledRequired();
      this.$emit("hasSms", false);
    },

    cleanSelectedPhone() {
      this.selectedContact = null;
      this.selectedContactIndex = null;
      this.editContact = false;
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
    },

    disableOrEnabledRequired() {
      this.isRequiredPhone =
        (this.isPrivateSector && !this.hasSms) || !!this.contacts || !!this.tel;
      if (!this.isRequiredPhone) {
        this.cleanValidate();
      }
    },

    VerifiySmsToSetRequired() {
      this.hasSms = this.hasPhoneToReceiveSms();
      this.isRequiredPhone = !this.hasSms;
    },

    loadFromCadsus(pData) {
      if (!pData) return;
      this.cleanForm();
      if (pData.Emails) {
        if (
          !Array.isArray(pData.Emails) &&
          pData.Emails.Email &&
          pData.Emails.Email.validado
        ) {
          this.email = pData.Emails.Email.descricaoEmail.toLowerCase();
        } else {
          for (const email of pData.Emails) {
            if (email.tipoEmail === "P" && email.validado) {
              this.email = email.descricaoEmail.toLowerCase();
              break;
            }
          }
        }
      }

      if (pData.Telefones) {
        const tellphones = pData.Telefones?.Telefone;
        const hasOnlyPhone = !Array.isArray(tellphones);

        if (hasOnlyPhone) {
          const phone = {
            contact: "IMPORTADO CADSUS",
            tel: tellphones?.numeroTelefone,
            ddd: tellphones?.ID,
            telDdd: `(${tellphones?.ID?.ddd_codigo}) ${tellphones?.numeroTelefone}`,
            sms: " ",
          };

          this.phones.push(phone);
        } else {
          for (const telefone of tellphones) {
            const phone = {
              contact: "IMPORTADO CADSUS",
              tel: telefone.numeroTelefone,
              ddd: telefone.ID,
              telDdd: `(${telefone.ID?.ddd_codigo}) ${telefone.numeroTelefone}`,
              sms: " ",
            };

            this.phones.push(phone);
            break;
          }
        }
      }
    },

    formatDate(pValue) {
      if (!pValue) return "";
      return this.$utils.date.BrazilianDateShortTimeFormat(pValue);
    },

    cleanValidate() {
      this.$refs.contacts.cleanValidate();
      this.$refs.phone.cleanValidate();
      this.isRequiredPhone = false;
    },
  },
};
</script>
