import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentSearchPatientExclude from "$appointment/submodules/schedule/views/search-patient-exclude/AppointmentSearchPatientExclude.vue";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/patient-exclude",
  name: "appointment.schedule.patient-exclude",
  components: {
    default: AppointmentSearchPatientExclude,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Regulação", link: OpenSubModule("appointment") },
      {
        label: "Pacientes Excluídos pela Fila",
        link: "/appointment/schedule/patient-exclude",
      },
    ],
  },
};
