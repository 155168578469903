<template slot="subFilters">
  <div class="pharmacy-central-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealthPharmacy
          id="unit-health"
          ref="unitHealth"
          v-model="form.unitHealthDestiny"
          openOnlyBottom
          class="input"
          label="Unidade de Saúde Destino"
          :typePharmacy="1"
          @change="selectedUnitHealth"
        />
        <RgSelectPharmacy
          v-model="form.pharmacyDestiny"
          :unitHealthId="Number(form.unitHealthDestiny)"
          :typePharmacy="2"
          label="Farmácia de Destino"
          default-text="Selecione"
          openOnlyBottom
          byUser
          class="input"
          :disabled="!form.unitHealthDestiny"
          :class="{ disable: !form.unitHealthDestiny }"
          @change="selectedPharmacy"
        />
        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />
        <RgSelectLotExpiration
          ref="lotExpiration"
          v-model="form.lot"
          :pharmacyId="Number(pharmacyId)"
          :productId="Number(productId)"
          :isEntry="true"
          :disabled="!productId || !pharmacyId"
          :class="{
            disable: !productId || !pharmacyId,
          }"
          label="Lote"
          default-text="Todos"
          class="inputitem"
          @change="selectedLotExpiration"
        />
        <RgInput
          v-model="form.deliveryNote"
          label="Guia de Remessa"
          placeholder="Número da Guia"
          :maxlength="20"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import { RgInput } from "~tokio/primitive";
import { mapGetters } from "vuex";

const FORM_FILTER = {
  unitHealthDestiny: null,
  unitHealthDestinyName: null,
  pharmacyDestiny: null,
  pharmacyDestinyName: null,
  productTypeId: null,
  productTypeName: null,
  lot: null,
  lotAndExpiration: null,
  deliveryNote: null,
};

export default {
  name: "PharmacyCentralOutputReport",
  components: {
    RgSelectProductType,
    RgSelectLotExpiration,
    RgInput,
    RgSelectPharmacy,
    RgSelectUnithealthPharmacy,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      pharmacyId: null,
      productId: null,
    };
  },
  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
    }),
  },
  watch: {
    globalFilters: {
      handler(pValue) {
        this.pharmacyId = pValue.pharmacy;
        this.productId = pValue.product;
        if (!pValue.pharmacy) {
          this.clearSubFilter("pharmacy");
        }
        if (!pValue.product) {
          this.clearSubFilter("product");
        }
      },
      deep: true,
    },
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Farmácia", key: "FARMACIA", align: "left" },
      { name: "Valor Unitário", key: "VALOR_UNITARIO", align: "right" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Saída", key: "SAIDA", align: "right" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Custo", key: "CUSTO", align: "right" },
      { name: "Destino", key: "DESTINO", align: "left" },
      { name: "Guia de Remessa", key: "GUIA_REMESSA", align: "right" },
    ]);
  },

  methods: {
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          unitHealthId: Number(this.globalFilters.unitHealth),
          unitHealthName: this.globalFilters.unitHealthName,
          pharmacyId: Number(this.globalFilters.pharmacy),
          pharmacyName: this.globalFilters.pharmacyName,
          productId: Number(this.globalFilters.product),
          productName: this.globalFilters.productName,
          unitHealthDestiny: Number(this.form.unitHealthDestiny),
          unitHealthDestinyName: this.form.unitHealthDestinyName,
          pharmacyDestiny: Number(this.form.pharmacyDestiny),
          pharmacyDestinyName: this.form.pharmacyDestinyName,
          productTypeId: Number(this.form.productTypeId),
          productTypeName: this.form.productTypeName,
          lot: this.form.lot,
          lotAndExpiration: this.form.lotAndExpiration,
          deliveryNote: this.form.deliveryNote,
          initialDate: this.globalFilters.initialDate,
          finalDate:
            this.globalFilters.finalDate || moment().format("DD/MM/YYYY"),
          loggedUserId: this.userId,
          columnsToPrint: this.columnsToPrint,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CENTRAL_OUTPUT",
          variables,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    selectedUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.unitHealthDestinyName = pValue.label;
      } else {
        this.form.unitHealthDestinyName = "";
      }
    },
    selectedPharmacy(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.pharmacyDestinyName = pValue.label;
      } else {
        this.form.pharmacyDestinyName = "";
      }
    },
    selectedProductType(pValue) {
      if (pValue) {
        this.form.productTypeName = pValue.mtp_nome;
      } else {
        this.form.productTypeName = null;
      }
    },
    selectedLotExpiration(pValue) {
      if (pValue) {
        this.form.lotAndExpiration = pValue.label;
      } else {
        this.form.lotAndExpiration = null;
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
    clearSubFilter(option) {
      switch (option) {
        case "pharmacy":
        case "product":
          this.form.lotAndExpiration = null;
          this.form.lot = null;
          break;
        default:
          break;
      }
    },
  },
};
</script>
