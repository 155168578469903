<style src="./RgRadioCustom.scss" lang="scss" scoped></style>

<template lang="html">
  <div class="rg-radio-custom" :class="{ inline }">
    <RgFormBase
      :label="label"
      :required="isRequired"
      @click.stop.prevent="toggleStatus"
    >
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <section
        :style="{ gridTemplateColumns: `repeat(${listSize}, ${colWidth}fr)` }"
        :class="{
          reorganize,
          'radio-custom-carousel': carousel,
          'radio-custom': !carousel,
          margin: label !== null,
        }"
        class="grid-radio"
      >
        <div
          v-for="(el, idx) in list"
          :id="replaceString(el.title)"
          :key="`rc-${idx}`"
          class="radio-container unselect"
          :class="{
            active:
              el.active || selectedList.includes(el.id) || el.id === value,
            mg: reorganize,
            'disabled-by-modal':
              disabledByModal ||
              (disableByComponent && disableByComponent.includes(el.id)),
            blockfield: disabled,
            'inline-size': inline,
          }"
          :style="` ${
            el.active || selectedList.includes(el.id) || el.id === value
              ? 'background-color:' + el.color
              : ''
          };
           ${el.color ? 'border-color:' + el.color : ''};
           ${el.color ? 'color:' + el.color : ''};
           ${
             el.gridStart && el.gridEnd
               ? 'grid-column:' + el.gridStart + '/' + el.gridEnd
               : ''
           };
           `"
          @click="onClickContainer(el, idx)"
        >
          <CentralizerIcons :iconId="el.icon" :color="el.color" />
          {{ el.title }}
        </div>
      </section>
    </RgFormBase>
  </div>
</template>

<script>
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";
import { RgFormBase } from "~tokio/foundation/container";
import { replaceSpecial } from "@/common/utils/sanitize.js";
import CentralizerIcons from "~tokio/primitive/icon/font-awesome/icons/CentralizerIcons";

export default {
  name: "RgRadioCustom",
  components: {
    RgValidationAlert,
    RgFormBase,
    CentralizerIcons,
  },
  mixins: [RgValidatorMixin],

  props: {
    list: {
      type: Array,
      required: true,
    },
    uniqueKey: {
      type: String,
      required: true,
    },
    value: {},
    refreshValue: {},
    disableByComponent: {},
    reorganize: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    canUnselect: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledByModal: {
      type: Boolean,
      default: false,
    },
    multSelect: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    carousel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listSize: 0,
      colWidth: 0,
      validItem: null,
      selectedList: [],
    };
  },

  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
    validValue() {
      return this.validItem;
    },
  },

  watch: {
    value(val) {
      this.validItem = val;
      this.loadData();
    },
    refreshValue: {
      handler(pValue) {
        this.loadData();
      },
    },
  },

  mounted() {
    this.listSize = this.list.length;
    this.colWidth = parseInt(100 / this.listSize);

    let count = 0;
    this.list.map((el) => {
      if (!el.title) {
        console.error(
          `RadioCustom - list na posição ${count} não contêm chave title`,
        );
      }
      count++;
    });

    this.loadData();
  },

  methods: {
    loadData() {
      if (!this.multSelect) {
        if (this.value !== null) {
          this.list.forEach((el) => {
            el.active = false;
            if (el.id === this.value) {
              el.active = true;
            }
          });
        } else {
          this.list.forEach((el) => {
            el.active = false;
          });
        }
      } else {
        if (Array.isArray(this.value)) {
          this.selectedList = this.value;
        } else if (this.value !== undefined && this.value !== null) {
          this.selectedList.push(this.value);
        }
      }
      this.$forceUpdate();
    },

    onClickContainer(item, idx) {
      if (this.disabled) return;
      if (this.multSelect) {
        if (this.selectedList.includes(item.id)) {
          this.selectedList.splice(this.selectedList.indexOf(item.id), 1);
        } else {
          this.selectedList.push(item.id);
        }
        this.$emit("input", this.selectedList);
      } else {
        this.list.forEach((el) => {
          if (el.active && el[this.uniqueKey] !== item[this.uniqueKey]) {
            el.active = false;
          }
        });
        if (this.canUnselect) {
          item.active = !item.active;
        } else {
          item.active = true;
        }

        const hasActiveItem = this.list.filter((x) => x.active);
        if (hasActiveItem && hasActiveItem.length > 0) {
          this.validItem = item.id;
        } else {
          this.validItem = null;
        }
      }

      if (!this.multSelect) {
        if (item.active) {
          this.$emit("input", item);
        } else if (this.canUnselect) {
          this.$emit("input", null);
        }
      }
      this.validate();
      this.$forceUpdate();
    },

    forceUpdate() {
      this.$forceUpdate();
    },
    replaceString(params) {
      return replaceSpecial(params).replaceAll(" ", "-").toLowerCase();
    },
    cleanValidate() {
      this.error = [];
    },
    clearRadio() {
      if (this.canUnselect) {
        if (this.multSelect) {
          this.selectedList = [];
        } else {
          this.list.forEach((el) => {
            el.active = false;
          });
        }
      }
    },
  },
};
</script>
