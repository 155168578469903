import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import SearchPatientSchedule from "$hospitalization/hospitalizations/queue/views/search-patient-schedule/SearchPatientSchedule";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/hospitalization/schedule/patient-schedule",
  name: "hospitalization.schedule.patient-schedule",
  components: {
    default: SearchPatientSchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Regulação", link: OpenSubModule("hospitalization") },
      {
        label: "Agendados pela Fila",
      },
    ],
  },
};
