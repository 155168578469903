const ERRORS = {
  TIMEOUT: {
    ORIGINAL_MESSAGE: "timeout",
    NEW_MESSAGE:
      "A comunicação com o servidor do Bioslab excedeu o tempo limite. Tente novamente e se o problema persistir, acione o suporte.",
  },

  WEBSERVICE: {
    ORIGINAL_MESSAGE: "Erro ao enviar solicitação ao Integra",
    NEW_MESSAGE:
      "Erro na comunicação com o serviço de integração do Bioslab. Favor entrar em contato com o suporte do sistema ESUS",
  },

  SYSTEM: {
    ORIGINAL_MESSAGE: "SYSTEM ERROR CODE 8",
    NEW_MESSAGE:
      "Erro no recebimento do pedido de exames. Favor entrar em contato com o suporte do sistema Bioslab",
  },

  SERVER: {
    ORIGINAL_MESSAGE: "Request failed with status code 50", // pegar errors internos
    NEW_MESSAGE:
      "Erro no servidor. Favor entrar em contato com o suporte do sistema ESUS",
  },
};

export { ERRORS };
