import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query(
    $nacNome: String
    $limit: Int
    $nac_ativo: Boolean
    $has_nac_codigo_ab: Boolean
  ) {
    nationalities(
      nac_nome: $nacNome
      limit: $limit
      nac_ativo: $nac_ativo
      has_nac_codigo_ab: $has_nac_codigo_ab
    ) {
      count
      rows {
        nac_id
        nac_nome
        nac_codigo_ab
        nac_codigo
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.nationalities;
};
