import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

export default {
  path: "/emergency/administrative-check-out/search/patient-care-history",
  name: "emergency.administrative-check-out.search.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      {
        label: "Alta Administrativa",
        link: "/emergency/administrative-check-out/search",
      },
      {
        label: "Histórico de Atendimentos",
      },
    ],
  },
};
