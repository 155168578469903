<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchEthnicity from "./action/SearchEthnicity";

export default {
  name: "RgComboboxEthnicity",
  mixins: [RgComboboxMixin],
  props: {
    ethnicityId: {
      type: Number,
      default: 0,
    },
    callbackResponse: {
      type: Function,
      default: () => {},
    },
    initValue: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {};
  },
  watch: {
    ethnicityId: {
      handler(pValue) {
        this.callbackResponse(
          this.mData.filter((element) => element.value === pValue),
        );
      },
    },
    initValue: {
      handler(pValue) {
        this.forceSelect(pValue);
      },
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const data = await SearchEthnicity();

      const NAO_INFORMADO = 6;

      const filterRace = data.filter((item) => {
        return item.etn_id !== NAO_INFORMADO;
      });

      filterRace.forEach((item) => {
        this.mData.push({
          value: item.etn_id,
          text: item.etn_raca_ab,
          ...item,
        });
      });

      return this.mData;
    },
    forceSelect(pValue) {
      if (pValue) {
        this.selectedItem = this.mData.filter(
          (element) => element.etn_codigo_sisaih === pValue,
        )[0].value;
      }
    },
    getMData() {
      return this.mData;
    },
  },
};
</script>
