<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListUnitHealthMappedPep from "./action/ListUnitHealthMappedPep";

export default {
  name: "RgSelectUnithealthMappedPep",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    defaultText: {
      type: String,
      default: "Digite o nome da Unidade de Saúde",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
    placeId: {
      default: null,
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },

  watch: {
    placeId(pValue) {
      this.mValue = null;
      const isValidId = pValue && pValue > 0;

      if (isValidId) {
        this.doSearch();
      }
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      const data = await ListUnitHealthMappedPep({ placeId: this.placeId });

      this.valuesData = data.map((item) => {
        return {
          value: item.uns_id,
          label: `${item.uns_nome}`,
        };
      });
    },
  },
};
</script>
