<style src="./ModalInformativeBedsUnavailable.scss" lang="scss" scoped></style>
<template lang="html">
  <div v-if="show" class="modal-confirm-field-clearing">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">Nenhum leito disponível na unidade</div>

      <div slot="body" class="body">
        <div class="text">
          <span>É necessário um leito vazio para excluir a saída</span>
        </div>
      </div>

      <div slot="footer" class="footer">
        <button class="yes" @click="getOut">OK</button>

        <button class="out" @click="getOut">Lista de leitos</button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalRemoveHospitalizationFromBed",
  components: {
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getYes() {
      this.$emit("getYes", 1);
      this.close();
    },
    getOut() {
      this.$emit("getOut", 2);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
