export default {
  SET_FORM_SATELLITE: (state, data) => {
    state.form_satellite = data;
  },
  UNSET_FORM_SATELLITE: (state, data) => {
    state.form_satellite = null;
  },
  SET_TABLE_SATELLITE: (state, data) => {
    state.table_satellite = data;
  },
  UNSET_TABLE_SATELLITE: (state, data) => {
    state.table_satellite = [];
  },
};
