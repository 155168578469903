<template>
  <Modulebox :title="individualOrLot" class="exam-register-schedule-queue">
    <form @submit.stop.prevent="saveForm(false)">
      <section class="body">
        <RgValidatorForm ref="validator">
          <FormBase :title="patientOrPatients" class="form-base-patient">
            <div class="patient-table-actions">
              <RgPersonCardButton
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                @click="editPatient"
              />
              <RgPrinterButton
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                title="Imprimir carteirinha"
                @click="patientPrintCard"
              />
              <!-- <RgHistoryButton
                :disabled="!patientSelect"
                :class="{disable: !patientSelect}"
                title="Histórico do paciente"
                @click="historyPatient"
              /> -->
              <RgLessButton
                v-show="mutableListPatient && mutableListPatient.length > 1"
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                title="Remover paciente da tabela"
                @click="removePatientToList"
              />
            </div>

            <table class="patient-table">
              <div class="thead">
                <tr class="header">
                  <th class=""></th>
                  <th class="patient">Pacientes</th>
                  <th class="medical-record">Cartão SUS</th>
                  <th class="medical-record">CPF</th>
                  <th class="medical-record">Prontuário Único</th>
                </tr>
              </div>

              <div class="tbody">
                <tr
                  v-for="(item, index) in mutableListPatient"
                  :key="index"
                  :class="{ selected: index === activeRow }"
                  class="body"
                  @click="selectPatient(item, index)"
                >
                  <td>
                    <div v-if="index === activeRow" class="check">
                      <IconCheck />
                    </div>
                  </td>
                  <td>{{ item.pes_nome }}</td>
                  <td>{{ item.pep_cartaosus }}</td>
                  <td>{{ item.pep_cpf_numero }}</td>
                  <td>{{ item.pac_prontuario_unico }}</td>
                </tr>
              </div>
            </table>
          </FormBase>

          <FormBase title="Procedimento" class="form-base-procedure">
            <span class="name-procedure">
              Procedimento:
              <strong> {{ procedureName }} </strong>
            </span>

            <div class="procedure-form grid-1">
              <RgSelectUnitHealthProcedure
                id="select-unit-health-procedure"
                ref="unit"
                v-model="form.unitHealth"
                :rules="{ required: true }"
                :permission="'exame2.filaExame.agendar'"
                :procedureName="procedureNameToSearch"
                label="Unidade de Saúde"
                @change="selectUnitHealth"
              />

              <RgSelectSectorProcedureProfessional
                id="select-sector-procedure-professional"
                ref="sector"
                v-model="form.sector"
                :rules="{ required: true }"
                :disabled="!form.unitHealth"
                :class="{ disable: !form.unitHealth }"
                :unithHealthId="Number(form.unitHealth)"
                :procedureName="procedureNameToSearch"
                :submoduleId="submoduleId"
                default-text="Selecione"
                label="Setor"
                @change="selectItemSector"
              />

              <RgSelectorPlacebySectorExam
                id="selector-place-by-sector-exam"
                ref="locale"
                v-model="form.locale"
                :disabled="!form.sector"
                :class="{ disable: !form.sector }"
                :rules="{ required: true }"
                :sectorId="Number(form.sector)"
                label="Local de Atendimento"
              />
            </div>

            <div class="procedure-form grid-2">
              <RgSelectProfessionalSectorProcedure
                id="select-professional-sector-procedure"
                ref="employee"
                v-model="form.responsibleProfessional"
                :disabled="!form.sector"
                :class="{ disable: !form.sector }"
                :rules="{ required: true }"
                :sectorId="Number(form.sector)"
                :procedureName="procedureNameToSearch"
                label="Profissional"
                @change="selectItemProfessional"
              />

              <RgInputDate
                id="input-date"
                ref="date"
                v-model="form.date"
                :rules="{ required: true }"
                :disabled="!form.responsibleProfessional"
                :class="{ disable: !form.responsibleProfessional }"
                :dates-available="datesEnable"
                :disableNoAvailable="datesEnable.length === 0"
                label="Data"
              />

              <RgSelectScalesWithProcedure
                id="select-scales-with-procedure"
                ref="scale"
                v-model="form.scale"
                :disabled="!form.date"
                :class="{ disable: !form.date }"
                :rules="{ required: true }"
                :sectorId="Number(form.sector)"
                :bondId="Number(form.vus_id)"
                :procedureName="procedureNameToSearch"
                :date="form.date"
                label="Escala"
                @change="selectItemScale"
              />

              <RgSelectVacanciesAvailableScale
                v-if="!hasLot"
                id="select-vacancies-available-scale"
                ref="vacancie"
                v-model="form.vacancie"
                :disabled="!form.scale"
                :class="{ disable: !form.scale }"
                :rules="{ required: true }"
                :scale="Number(form.scale)"
                :date="form.date"
                label="Vagas"
              />

              <RgComboboxHour
                v-if="showComboHour"
                id="combobox-hour"
                ref="comboHour"
                v-model="form.hour"
                :rules="{ required: true }"
                :disabled="disableHour"
                :class="{ disable: disableHour }"
                :scaleSchedule="Number(form.scale)"
                :historySchedule="Number(selectedScale.eha_id)"
                :dateExam="form.date ? form.date : ''"
                :occupiedPlaces="Number(form.vacancie) || 1"
                :eapId="null"
                hasVacancies
                label="Horário"
                @selectOnlyItem="selectItemHour"
              />

              <RgSelectProceduresProfessionalAgreements
                id="select-procedures-professional-agreements"
                ref="healthPlan"
                v-model="form.healthPlan"
                :disabled="!form.sector"
                :class="{ disable: !form.sector }"
                :rules="{ required: true }"
                :sectorId="Number(form.sector)"
                :bondId="Number(form.vus_id)"
                :procedureName="procedureNameToSearch"
                label="Convênios"
              />
            </div>
          </FormBase>
        </RgValidatorForm>

        <FormBase title="Subprocedimentos" class="form-base-subprocedure">
          <div v-if="!form.unitHealth" class="empty">
            <IconEmpty />
            <span class="text">Nenhuma unidade de saúde selecionada</span>
          </div>
          <div v-else-if="!hasSubprocedureList" class="empty">
            <IconEmpty />
            <span class="text">Nenhum subprocedimento encontrado</span>
          </div>
          <div v-else class="content-grid">
            <div v-if="!hasLot" class="top-btn">
              <RgButton
                v-if="!equalListSize"
                class="select-btn"
                @click="selectAllList"
              >
                <IconSquare />
              </RgButton>

              <RgButton
                v-if="equalListSize"
                class="select-btn"
                @click="unselectAllList"
              >
                <IconSquareChecked />
              </RgButton>

              <RgButton
                class="select-btn reverse-select"
                title="Inverter seleção"
                @click="reverseListSelection"
              >
                <IconArrowSquare />
              </RgButton>

              <p class="text">
                Linhas Selecionadas:
                {{ subprocedureListSelected.length }}
              </p>
            </div>

            <table class="table unselect">
              <tr class="header">
                <th class="title check"></th>
                <th class="title">Subprocedimento</th>
                <th class="title">Realiza na Unidade</th>
              </tr>

              <tr
                v-for="(item, index) in subprocedureList"
                :key="index"
                class="body"
                :class="{ selected: item.selected }"
                @click="selectSubprocedure(item)"
              >
                <td class="icon-check">
                  <div v-if="item.selected" class="check">
                    <IconCheck />
                  </div>
                </td>

                <td class="data">
                  {{ item.subProcedimento }}
                </td>

                <td class="data">
                  {{ item.heldUnit ? item.heldUnit : "" }}
                </td>
              </tr>
            </table>
          </div>
        </FormBase>
      </section>
    </form>

    <div slot="footer" class="footer">
      <RgCleanButton small class="clean" @click="showConfirmFieldClearing" />

      <RgCancelButton
        id="cancel-button"
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton ref="save" medium class="save" @click="saveForm(false)" />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />

    <ModalConfirmSchedule
      ref="modalConfirmSchedule"
      :patientScheduleId="patientHistorySchedule"
      :scheduleDate="form.date"
      :show="showModalHistorySchedule"
      @save="saveFormByModalConfirmSchedule"
      @cancel="closeModalHistorySchedule"
      @close="closeModalHistorySchedule"
    />

    <ModalLinkSolicitation
      :show="showModalLinkSolicitation"
      :requested-data="requested"
      show-patient-name
      @not-linked="saveWithSolicitation"
      @linked="saveWithSolicitation"
      @close="closeModalLinkSolicitation"
    />
  </Modulebox>
</template>

<script>
import {
  RgSaveButton,
  RgCleanButton,
  RgValidatorForm,
  RgInputDate,
  RgCancelButton,
  RgPersonCardButton,
  RgPrinterButton,
  // RgHistoryButton,
  RgLessButton,
  IconEmpty,
  IconCheck,
  IconSquareChecked,
  IconSquare,
  IconArrowSquare,
  RgButton,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import ModalConfirmSchedule from "$exam/submodules/schedule/component/modal/modal-confirm-schedule/ModalConfirmSchedule";
import RgSelectUnitHealthProcedure from "$exam/submodules/schedule/component/select/rg-select-unit-health-procedure/RgSelectUnitHealthProcedure";
import RgSelectSectorProcedureProfessional from "$exam/submodules/schedule/component/select/rg-select-sector-procedure-professional/RgSelectSectorProcedureProfessional";
import RgSelectorPlacebySectorExam from "$exam/submodules/schedule/component/select/rg-select-place-by-sector-exam/RgSelectorPlacebySectorExam";
import RgSelectProfessionalSectorProcedure from "$exam/submodules/schedule/component/select/rg-select-professional-sector-procedure/RgSelectProfessionalSectorProcedure";
import RgSelectProceduresProfessionalAgreements from "$exam/submodules/schedule/component/select/rg-select-procedures-professional-agreements/RgSelectProceduresProfessionalAgreements";
import RgSelectScalesWithProcedure from "$exam/submodules/schedule/component/select/rg-select-scales-with-procedure/RgSelectScalesWithProcedure";
import RgSelectVacanciesAvailableScale from "$exam/submodules/schedule/component/select/rg-select-vacancies-available-scale/RgSelectVacanciesAvailableScale";
import RgComboboxHour from "$exam/common/component/rg-combobox-hour/RgComboboxHour";
import SearchPersonById from "$exam/submodules/schedule/store/actions/SearchPersonById";
import ModalLinkSolicitation from "$exam/submodules/schedule/component/modal/modal-link-solicitation/ModalLinkSolicitation";
import { mapGetters } from "vuex";

const FORM = {
  unitHealth: null,
  sector: null,
  locale: null,
  responsibleProfessional: null,
  date: null,
  scale: null,
  hour: null,
  vacancie: null,
  healthPlan: null,
  vus_id: null,
  fun_id: null,
};

export default {
  name: "ExamRegisterScheduleQueue",
  components: {
    RgSelectUnitHealthProcedure,
    RgSaveButton,
    Modulebox,
    FormBase,
    RgCleanButton,
    RgSelectorPlacebySectorExam,
    RgValidatorForm,
    RgSelectSectorProcedureProfessional,
    RgInputDate,
    ModalConfirmFieldClearing,
    ModalConfirmSchedule,
    RgSelectProfessionalSectorProcedure,
    RgSelectProceduresProfessionalAgreements,
    RgSelectScalesWithProcedure,
    RgSelectVacanciesAvailableScale,
    RgComboboxHour,
    IconEmpty,
    RgCancelButton,
    RgPersonCardButton,
    RgPrinterButton,
    // RgHistoryButton,
    RgLessButton,
    IconCheck,
    ModalLinkSolicitation,
    IconSquareChecked,
    IconSquare,
    IconArrowSquare,
    RgButton,
  },

  data() {
    return {
      selectedRows: [],
      listProcedureLinkedPep: [],
      mutableListPatient: [],
      listAllSubprocedures: [],
      datesEnable: [],
      periodsDate: [],
      heldUnitList: [],
      subprocedureListSelected: [],
      procedureCode: [],
      subprocedureList: null,
      indexListAllSubprocedures: null,
      showComboHour: false,
      heldUnit: "",
      queueExamId: null,
      procedureName: null,
      procedureNameToSearch: null,
      getDateSchedule: null,
      requestProfessional: null,
      activeRow: null,
      personId: null,
      modalConfirmFieldClearing: false,
      showModalHistorySchedule: false,
      showModalLinkSolicitation: false,
      buttonEditPatient: false,
      indexPatient: null,
      pesId: null,
      nameUnit: null,
      form: this.$utils.obj.DeepCopy(FORM),
      selectedScale: null,
      requested: null,
      saveFromModal: false,
      reasonToAnticipate: null,
      isLoading: false,
      dataSearchDates: null,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    hasSubprocedureList() {
      return this.subprocedureList && this.subprocedureList.length > 0;
    },

    individualOrLot() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Agendamento em Lote"
        : "Agendar Individual";
    },

    patientOrPatients() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Pacientes"
        : "Paciente";
    },

    patientHistorySchedule() {
      return this.mutableListPatient && this.mutableListPatient.length === 1
        ? Number(this.mutableListPatient[0].pxe_id)
        : null;
    },

    patientSelect() {
      return this.activeRow !== null;
    },

    hasLot() {
      return this.mutableListPatient && this.mutableListPatient.length > 1;
    },

    submoduleId() {
      return 123;
    },

    equalListSize() {
      return (
        this.subprocedureListSelected.length === this.subprocedureList.length
      );
    },

    disableHour() {
      return !this.hasLot
        ? !this.form.date || !this.form.scale || !this.form.vacancie
        : !this.form.date || !this.form.scale;
    },

    periodDatePreference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewExame2.eap_quantidade_busca_escalas_agendamento"
      ];
    },
  },

  watch: {
    "form.responsibleProfessional"(pValue, pPrev) {
      if (pValue && this.requestProfessional) {
        this.form.vus_id = this.requestProfessional.vus_id;
        this.form.fun_id = this.requestProfessional.fun_id;
        this.setDaysPossible();
      }

      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.hour = false;
        this.form.healthPlan = null;
        this.resetDataForDate();
        this.cleanFieldsValidation();
      }
    },

    "form.date"(pDate) {
      if (pDate && pDate.length === 10) {
        this.dateIsValid = true;
        this.getDateSchedule = this.form.date ? pDate.replaceAll("-", "/") : "";
      }

      if (!pDate) {
        this.form.hour = false;
        this.form.healthPlan = null;
        this.resetDataForDate();
        this.cleanFieldsValidation();
      }

      if (this.periodsDate) {
        this.periodsDate.eea_id = Number(
          this.getAttributeDayByDate(pDate, "eea_id"),
        );
      }
    },
  },

  created() {
    this.NO_REQUEST = 0;
    this.NOT_LINK_REQUEST = 1;
    this.LINK_REQUEST = 2;
  },

  async mounted() {
    try {
      this.isLoading = true;
      this.$loader.start();

      this.form.unitHealth = String(
        this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      );

      this.nameUnit = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

      const existData = await this.$store.getters[
        "Exam/Schedule/GET_SELECTED_PATIENT_LIST_QUEUE"
      ];

      if (existData) {
        this.reasonToAnticipate = existData.reason;

        if (this.lastPersonIdSaved) {
          const patientData = await SearchPersonById({
            pesId: this.lastPersonIdSaved,
          });
          if (patientData) {
            const index = existData.findIndex(
              (row) => Number(row.pes_id) === Number(this.lastPersonIdSaved),
            );

            existData[index].pes_nome = patientData.pes_nome;
            existData[index].pep_cartaosus = patientData.cns?.crs_numero;
            existData[index].pep_cpf_numero = patientData.cpf?.cpf_numero;
            existData[index].pac_prontuario_unico =
              patientData.patient?.pac_prontuario_unico;
          }
          this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
        }

        this.mutableListPatient = existData;

        this.addListProcedureLinkedPep(
          this.mutableListPatient[0].fle_id,
          this.mutableListPatient[0].pac_id,
          this.mutableListPatient[0].codigo_procedimento,
        );

        this.procedureName =
          this.mutableListPatient[0].nome ||
          this.mutableListPatient[0].fle_nome_procedimento;

        this.procedureNameToSearch = this.mutableListPatient[0].fle_nome_procedimento;

        await this.chargeList();

        if (this.mutableListPatient.length === 1) {
          await this.selectPatient(this.mutableListPatient[0], 0);
        }
      } else {
        this.cancel();
        this.$toaster.info(
          "Será necessário realizar uma nova pesquisa.",
          "Você foi redirecionado para a busca",
        );
      }
    } catch (pErr) {
      this.$toaster.error("Erro ao carregar dados do agendamento:", pErr);
    } finally {
      this.isLoading = false;
      this.$loader.finish();
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/queue/schedule",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_LIST_QUEUE_DATA");
    }
  },

  methods: {
    selectAllList() {
      this.subprocedureList.forEach((item) => {
        if (!item.selected) {
          item.selected = true;
          this.subprocedureListSelected.push(item);
        }
      });
    },

    reverseListSelection() {
      this.subprocedureList.forEach((item) => {
        if (item.selected) {
          item.selected = false;
          this.subprocedureListSelected.splice(
            this.subprocedureListSelected.indexOf(item),
            1,
          );
        } else {
          if (!item.selected) {
            item.selected = true;
            this.subprocedureListSelected.push(item);
          }
        }
      });
    },

    unselectAllList() {
      this.subprocedureList.forEach((item) => {
        if (item.selected) {
          item.selected = false;
          this.subprocedureListSelected.splice(
            this.subprocedureListSelected.indexOf(item),
            1,
          );
        }
      });
    },

    addListProcedureLinkedPep(queueId, patientId, code) {
      const found = this.listProcedureLinkedPep.some(
        (item) => item.fle_id === queueId,
      );

      if (!found) {
        this.listProcedureLinkedPep.push({
          fle_id: queueId,
          patientId: patientId,
          procedureCodes: [code],
          subProcedureCodes: [],
        });
      }
    },

    removeListSubProcedureLinkedPep(queueId, code) {
      const index = this.listProcedureLinkedPep.findIndex(
        (item) => item.fle_id === queueId,
      );

      const hasIndex = index !== -1;

      if (hasIndex) {
        const subProcedureIndex = this.listProcedureLinkedPep[
          index
        ].subProcedureCodes.findIndex((item) => item === code);

        const hasSubProcedureIndex = subProcedureIndex !== -1;

        if (hasSubProcedureIndex) {
          this.listProcedureLinkedPep[index].subProcedureCodes.splice(
            subProcedureIndex,
            1,
          );
        }
      }
    },

    addSubProcedureOnListProcedureLinkedPep(queueId, code) {
      const index = this.listProcedureLinkedPep.findIndex(
        (item) => item.fle_id === queueId,
      );

      const hasIndex = index !== -1;

      if (hasIndex) {
        const found = this.listProcedureLinkedPep[
          index
        ]?.subProcedureCodes.some((item) => item === code);

        if (!found) {
          this.listProcedureLinkedPep[index].subProcedureCodes.push(code);
        }
      }
    },

    closeModalLinkSolicitation() {
      this.showModalLinkSolicitation = false;

      const hasSubprocedures =
        this.listProcedureLinkedPep[0].subProcedureCodes?.length > 0;

      if (hasSubprocedures) {
        this.listProcedureLinkedPep[0].subProcedureCodes = [];
      }
    },

    saveWithSolicitation(solicitation, link) {
      if (link === this.NOT_LINK_REQUEST) {
        this.saveForm(this.saveFromModal, this.NOT_LINK_REQUEST);
      } else if (link === this.LINK_REQUEST) {
        this.saveForm(this.saveFromModal, this.LINK_REQUEST, solicitation);
      }
      this.saveFromModal = false;
    },

    async selectUnitHealth(pUnit) {
      if (!pUnit) {
        this.form.sector = null;
      } else {
        this.nameUnit = pUnit.label;
        try {
          if (!this.isLoading) this.$loader.start();
          await this.checkPerform(this.subprocedureList);
        } finally {
          if (!this.isLoading) this.$loader.finish();
        }
      }
    },

    async chargeList() {
      for (const item of this.mutableListPatient) {
        const subprocedureList = await this.$store.dispatch(
          "Exam/Schedule/SUBPROCEDURES_REGULATION_EXTERNAL",
          item.fle_id,
        );

        this.addListProcedureLinkedPep(
          item.fle_id,
          item.pac_id,
          item.codigo_procedimento,
        );

        const obj = {
          subprocedureList: subprocedureList,
          fle_id: item.fle_id,
        };

        const hasPatient =
          this.mutableListPatient && this.mutableListPatient.length > 1;

        if (hasPatient && subprocedureList?.length > 0) {
          subprocedureList.forEach((item) => {
            this.addSubProcedureOnListProcedureLinkedPep(
              item.fle_id,
              item.stp_novo_codigo_procedimento,
            );
          });
        }

        await this.checkPerform(obj.subprocedureList);

        this.listAllSubprocedures.push(obj);
      }
    },

    async checkPerform(subprocedureList) {
      try {
        const hasSubprocedureList = subprocedureList?.length > 0;

        if (hasSubprocedureList) {
          for (const subprocedure of subprocedureList) {
            const heldUnitList = await this.$store.dispatch(
              "Exam/Schedule/GET_UNITS_PERFORM_SUB_PROCEDURE",
              subprocedure.id,
            );

            const sameUnitHealth = heldUnitList.filter(
              (item) => item.uns_nome === this.nameUnit,
            );

            subprocedure.heldUnit = sameUnitHealth.length > 0 ? "SIM" : "NÃO";
          }

          return subprocedureList;
        }
      } catch (pErr) {
        this.$toaster.error(
          "Erro ao carregar subprocedimentos feitos na Unidade de Saúde",
        );
      }
    },

    async searchSubProceduresByFleId(fleId) {
      try {
        if (!this.isLoading) this.$loader.start();
        this.indexListAllSubprocedures = this.listAllSubprocedures.findIndex(
          (el) => {
            return el.fle_id === fleId;
          },
        );
        this.subprocedureList = this.listAllSubprocedures[
          this.indexListAllSubprocedures
        ].subprocedureList;
      } catch (pErr) {
        this.$toaster.error(
          "Erro ao carregar subprocedimentos feitos na Unidade de Saúde",
        );
      } finally {
        if (!this.isLoading) this.$loader.finish();
      }
    },

    formatData() {
      const patients = [];

      this.mutableListPatient.forEach((item) => {
        const objectSubprocedure = this.listAllSubprocedures.find((el) => {
          return el.fle_id === item.fle_id;
        });

        const subprocedure = objectSubprocedure
          ? objectSubprocedure.subprocedureList
          : null;
        if (this.listAllSubprocedures[0].subprocedureList !== 0) {
          subprocedure.forEach((el) => {
            delete el.heldUnit;
          });
        }

        if (!this.hasLot) {
          this.subprocedureListSelected.forEach((el) => {
            delete el.heldUnit;
            delete el.selected;
          });
        }

        patients.push({
          eap_hora: this.form.hour
            ? this.form.hour
            : this.selectedScale.eea_inicio_atendimentos,
          exa_id_pacientes_exames: Number(item.pxe_id),
          vus_id: Number(this.form.vus_id),
          eap_data_pedido: item.fil_data_pedido,
          eap_id_funcionarios: Number(this.form.fun_id),
          eha_id: Number(this.selectedScale.eha_id),
          eha_id_exames_tipo_escalas: this.selectedScale
            .eea_id_exames_tipo_escalas,
          eha_data_exame: this.form.date,
          eap_custo_procedimento: null,
          eap_id: 0,
          eap_id_filas_exames: Number(item.fle_id),
          eap_id_locais_atendimento: Number(this.form.locale),
          pls_id: Number(this.form.healthPlan),
          eea_id: Number(this.form.scale),
          eap_vagas_ocupadas: Number(this.form.vacancie)
            ? Number(this.form.vacancie)
            : 1,
          eap_id_exames_procedimentos_profissionais: 0,
          eap_solicitante_externo: null,
          stp_novo_nome_procedimento: item.fle_nome_procedimento,
          stp_subProcedimentos: this.hasLot
            ? subprocedure
            : this.subprocedureListSelected,
          set_id: Number(this.form.sector),
          eap_id_sinonimos_procedimentos: item.sip_id
            ? Number(item.sip_id)
            : null,
          eap_quantidade: 1,
          pac_id: Number(item.pac_id),
        });
      });

      return patients;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async validateSubprocedurePerformed() {
      let validate = true;

      if (this.listAllSubprocedures?.length > 0) {
        this.listAllSubprocedures.map((el) => {
          if (el.subprocedureList?.length > 0) {
            el.subprocedureList.map((item) => {
              if (item.heldUnit === "NÃO") {
                validate = false;
              }
            });
          }
        });
      }
      return validate;
    },

    async validateSelectedSubprocedurePerformed() {
      let validate = true;
      this.subprocedureListSelected.map((el) => {
        if (el.heldUnit === "NÃO") {
          validate = false;
        }
      });
      return validate;
    },

    async saveForm(
      isSaveByModal = false,
      link = this.NO_REQUEST,
      solicitation = null,
    ) {
      try {
        this.$loader.start();
        this.isLoading = true;

        const isValidForm = await this.isFormValid();
        const isValidAllSubprocedurePerformed = await this.validateSubprocedurePerformed();
        const isValidSelectedSubprocedurePerformed = await this.validateSelectedSubprocedurePerformed();

        const isValidSubprocedureSelected =
          this.subprocedureList?.length > 0 &&
          this.subprocedureListSelected?.length < 1;

        if (!isValidForm) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        if (!this.hasLot && isValidSubprocedureSelected) {
          this.$toaster.warning(
            "É obrigatório a seleção de um subprocedimento",
          );
          return false;
        }

        if (this.hasLot && !isValidAllSubprocedurePerformed) {
          this.$toaster.warning(
            "A unidade de saúde selecionada não realiza todos os subprocedimentos dos pacientes listados",
          );
          return false;
        }

        if (!this.hasLot && !isValidSelectedSubprocedurePerformed) {
          this.$toaster.warning(
            "A unidade de saúde selecionada não realiza todos os subprocedimentos selecionados para o paciente",
          );
          return false;
        }

        const variables = this.formatData();

        if (isSaveByModal) {
          this.saveFromModal = true;
          variables.blnExibirAvisos = false;
        } else {
          this.saveFromModal = false;
          variables.blnExibirAvisos = true;
        }

        if (link === this.NO_REQUEST) {
          const list = this.listProcedureLinkedPep.map(
            ({ patientId, procedureCodes, subProcedureCodes }) => {
              return {
                patientId: Number(patientId),
                procedureCodes: [...procedureCodes, ...subProcedureCodes],
              };
            },
          );

          const requestedExams = await this.$store.dispatch(
            "Exam/Schedule/SEARCH_REQUESTED_QUEUE_EXAMS",
            {
              list,
            },
          );

          const hasResquestedExams = requestedExams && requestedExams.count > 0;

          if (hasResquestedExams) {
            this.requested = requestedExams;
            this.showModalLinkSolicitation = true;
          } else {
            return await this.saveAction(variables);
          }
        } else if (link === this.NOT_LINK_REQUEST) {
          return await this.saveAction(variables);
        } else if (link === this.LINK_REQUEST && solicitation) {
          solicitation.forEach((item) => {
            const index = variables.findIndex(
              (key) => key.pac_id === item.exe_id_pacientes,
            );

            if (index !== -1) {
              if (!variables[index].stp_procedimentos_solicitados) {
                variables[index].stp_procedimentos_solicitados = [];
              }

              variables[index].stp_procedimentos_solicitados.push({
                id: item.id,
                codigo_procedimento: item.codigo_procedimento,
              });
            }
          });

          return await this.saveAction(variables);
        }
      } finally {
        this.$loader.finish();
        this.isLoading = false;
      }
    },

    async saveAction(variables) {
      try {
        const result = await this.$store.dispatch(
          "Exam/Schedule/SAVE_EXAM_SCHEDULE_QUEUE",
          variables,
        );

        const queueId = this.mutableListPatient.map(({ fil_id }) =>
          Number(fil_id),
        );
        const reason = this.mutableListPatient[0]
          .fil_motivo_agendamento_fora_topo;
        const reasonVariables = {
          queueId,
          reason,
        };

        if (reason) {
          await this.$store.dispatch(
            "Exam/Schedule/SAVE_EXAM_SCHEDULE_QUEUE_REASON",
            reasonVariables,
          );
        }

        this.$toaster.success("Agendamento realizado com sucesso");

        const params = {
          isExamSchedule: true,
          examId: result.dados,
        };

        this.$router.push({
          name: "exam.schedule.queue",
          params,
        });

        return true;
      } catch (pErr) {
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          if (esus_response.dados === "PacienteAgendadoHojeException") {
            this.showModalHistorySchedule = true;
            return false;
          } else {
            this.$toaster.error(
              this.$utils.sanitize.formatError(pErr),
              "Falha ao salvar agendamento",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Falha ao salvar agendamento",
          );
        }
      } finally {
        this.$refs.save.actionDone();
      }
    },

    async setDaysPossible() {
      try {
        if (!Number(this.form.responsibleProfessional)) {
          return;
        }

        const DEFAULT_QUANTITY_MONTHS = 12;

        const periodDatePreference =
          this.periodDatePreference || DEFAULT_QUANTITY_MONTHS;

        const pData = {
          set_id: Number(this.form.sector),
          vus_id: Number(this.form.vus_id),
          dataInicial: moment().format("DD/MM/YYYY"),
          dataFinal: moment()
            .add(periodDatePreference, "months")
            .format("DD/MM/YYYY"),
          procedimento_nome: this.procedureName,
        };

        if (
          pData.set_id !== this.dataSearchDates?.set_id &&
          pData.vus_id === this.dataSearchDates?.vus_id
        ) {
          return;
        }

        this.$loader.start("Carregando datas...");

        this.dataSearchDates = this.$utils.obj.DeepCopy(pData);

        this.periodsDate = await this.$store.dispatch(
          "Exam/Schedule/GET_DAYS_ON_WEEK_OF_PERIODS",
          pData,
        );

        if (this.periodsDate === 0) {
          this.resetDataForDate();
          this.$toaster.warning("O profissional selecionado não possui escala");
        } else {
          this.periodsDate = this.periodsDate.map((item) => {
            return {
              eea_id: item.eea_id,
              data: item.data,
            };
          });
          this.datesEnable = this.periodsDate.map((item) =>
            moment(item.data, "DD-MM-YYYY").format("YYYY-MM-DD"),
          );
        }
      } catch (Err) {
        this.periodsDate = [];
        this.datesEnable = [];
        this.$toaster.error(Err, "Erro na busca pelas datas");
      } finally {
        this.$loader.finish();
      }
    },

    getAttributeDayByDate(pDate, pAtrr) {
      if (this.periodsDate.length <= 0) return;
      return this.periodsDate.find((ele) => ele.data === pDate)[pAtrr];
    },

    editPatient() {
      this.$store.commit("Person/Patient/SET_PERSON_ID", parseInt(this.pesId));
      this.$router.push({
        name: "exam.schedule.queue.schedule.edit-patient",
      });
      this.activeRow = null;
    },

    async patientPrintCard() {
      try {
        if (this.pesId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: parseInt(this.pesId) },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
    },

    removePatientToList() {
      if (this.mutableListPatient && this.mutableListPatient.length === 2) {
        this.activeRow = null;
        this.$toaster.warning(
          "É necessário ao menos 2 pacientes para realizar o agendamento em lote",
        );
        return;
      }

      if (this.hasLot) {
        this.mutableListPatient.splice(this.indexPatient, 1);
        this.listAllSubprocedures.splice(this.indexListAllSubprocedures, 1);
        this.activeRow = null;
        if (this.mutableListPatient.length === 1) {
          this.selectPatient(this.mutableListPatient[0], 0);
        }
      }
    },

    async selectPatient(item, index) {
      if (this.activeRow === index) return;
      if (!this.isLoading) this.$loader.start();
      this.activeRow = index;
      this.pesId = item.pes_id;
      this.indexPatient = index;
      await this.searchSubProceduresByFleId(item.fle_id);
      await this.checkPerform(this.subprocedureList);
      if (this.isLoading) this.$loader.finish();
    },

    selectSubprocedure(item) {
      if (!this.hasLot) {
        const hasOnePatient =
          this.mutableListPatient && this.mutableListPatient.length === 1;

        if (item.selected) {
          item.selected = false;
          this.subprocedureListSelected.splice(
            this.subprocedureListSelected.indexOf(item),
            1,
          );

          if (hasOnePatient) {
            this.removeListSubProcedureLinkedPep(
              item.fle_id,
              item.stp_novo_codigo_procedimento,
            );
          }
        } else {
          this.subprocedureListSelected.push(item);

          if (hasOnePatient) {
            this.addSubProcedureOnListProcedureLinkedPep(
              item.fle_id,
              item.stp_novo_codigo_procedimento,
            );
          }

          item.selected = true;
        }
        this.$forceUpdate();
      }
    },

    selectItemSector(pValue) {
      if (!pValue) {
        this.form.locale = null;
        this.form.responsibleProfessional = null;
      }
    },

    selectItemProfessional(pValue) {
      if (pValue) {
        this.requestProfessional = pValue;
      } else {
        this.requestProfessional = null;
        this.heldUnit = "";
        this.form.healthPlan = null;
        this.resetDataForDate();
        this.cleanFieldsValidation();
      }
    },

    selectItemScale(pValue) {
      if (pValue) {
        this.form.scale = pValue.value;
        this.selectedScale = pValue;
        if (this.selectedScale.eea_id_exames_tipo_escalas === "2") {
          this.showComboHour = true;
        } else {
          this.showComboHour = false;
        }
      } else {
        this.form.scale = null;
        this.form.vacancie = null;
        this.selectedScale = null;
        this.showComboHour = false;
      }
    },

    selectItemHour(pValue) {
      this.form.hour = pValue;
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    confirmCleanForm() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    closeModalHistorySchedule() {
      this.showModalHistorySchedule = false;
    },

    async saveFormByModalConfirmSchedule() {
      this.saveFromModal = true;
      this.showModalHistorySchedule = false;
      await this.saveForm(true);
    },

    cancel() {
      this.$router.push({
        name: "exam.schedule.queue",
      });
    },

    resetDataForDate() {
      this.form.date = null;
      this.form.scale = null;
      this.form.vacancie = null;
      this.form.hour = null;
      this.periodsDate = [];
      this.datesEnable = [];
      this.showComboHour = false;
    },

    clearForm() {
      this.datesEnable = [];
      this.periodsDate = [];
      this.heldUnitList = [];
      this.heldUnit = "";
      this.getDateSchedule = null;
      this.requestProfessional = null;
      this.modalConfirmFieldClearing = false;
      this.saveFromModal = false;
      this.buttonEditPatient = false;
      this.periodsDate = null;
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.form.unitHealth = String(
        this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      );
      this.nameUnit = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

      if (!this.hasLot) {
        this.activeRow = 0;

        this.subprocedureListSelected = [];
        this.subprocedureList.forEach((el) => {
          delete el.selected;
        });
      }

      this.cleanFieldsValidation();
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "unit",
        "sector",
        "locale",
        "employee",
        "date",
        "scale",
        "vacancie",
        "comboHour",
        "healthPlan",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
