<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.749 1.25135C18.4139 0.916215 17.8722 0.916215 17.537 1.25135L11.286 7.50243L9.32055 5.53702C9.07455 5.29188 8.70684 5.21645 8.38627 5.35102C8.06656 5.48302 7.85742 5.79673 7.85742 6.14301V11.2858C7.85742 11.7598 8.24142 12.1429 8.71455 12.1429H13.8574C14.2036 12.1429 14.5165 11.9347 14.6493 11.6141C14.7822 11.2935 14.7085 10.925 14.4633 10.6798L12.4979 8.71441L18.749 2.46334C19.0842 2.1282 19.0842 1.58649 18.749 1.25135Z"
      fill="white"
    />
    <path
      d="M15.5713 19.0001H3.5714C2.1537 19.0001 1 17.8464 1 16.4287V4.42882C1 3.01112 2.1537 1.85742 3.5714 1.85742H10.4285C10.9016 1.85742 11.2856 2.24056 11.2856 2.71455C11.2856 3.18855 10.9016 3.57169 10.4285 3.57169H3.5714C3.09912 3.57169 2.71427 3.95568 2.71427 4.42882V16.4287C2.71427 16.9018 3.09912 17.2858 3.5714 17.2858H15.5713C16.0435 17.2858 16.4284 16.9018 16.4284 16.4287V9.57162C16.4284 9.09763 16.8124 8.71449 17.2855 8.71449C17.7587 8.71449 18.1427 9.09763 18.1427 9.57162V16.4287C18.1427 17.8464 16.989 19.0001 15.5713 19.0001Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Entry",
};
</script>
