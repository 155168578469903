<style src="./RgInputPhone.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model="inputValue"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :class="{ 'on-error': error.length > 0 }"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :data-id="dataId"
            autocomplete="off"
            type="text"
            @blur="validateBlur"
            @focus="checkFocus"
            @change="change"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import Phone from "./RgInputPhoneHelper";
import RgInputMixin from "../mixin/RgInputMixin";
import { mask } from "vue-the-mask";

export default {
  name: "RgInputPhone",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "Telefone",
    },
    change: {
      type: Function,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "telefone",
    },
    maxlength: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      anotherRules: {
        checkPhone(pPhone, pError) {
          if (pPhone !== null && pPhone !== undefined && pPhone !== "") {
            return Phone.checkPhone(pPhone, pError);
          }
          return true;
        },
      },
    };
  },
  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
    // TODO: Adicionar validação do telefone presente em "src/app/billing/hospitalization-billing/common/service/ValidatePhone.js"
  },
};
</script>
