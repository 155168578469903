import SEARCH_BULLETINS from "./SearchBulletins";
import REMOVE_BULLETIN from "./RemoveBulletin";
import REGISTER_BULLETIN from "./RegisterBulletin";
import SEARCH_REGISTER_BULLETIN from "./SearchRegisterBulletin";
import BASIC_PATIENT_INFO from "./BasicPatientInfo";
import EDIT_BULLETIN from "./EditBulletin";
import GET_FATHER from "./GetFather";
import GET_MOTHER from "./GetMother";
import GET_SUMMARY_BULLETIN from "./GetSummaryBulletin";
import REGISTER_EXIT from "./RegisterExit";
import DELETE_REGISTER_EXIT from "./DeleteRegisterExit";
import SAVE_HOSPITALIZATION_SOLICITATION from "./SaveHospitalizationSolicitation";
import GET_PATIENT_NO_WAY_OUT_BULLETIN from "./GetPatientNoWayOutBulletin";
import SEND_BULLETIN_PEP from "./SendBulletinPep";
import CANCEL_SEND_BULLETIN_PEP from "./CancelSendBulletinPep";
import RESEND_BULLETIN_PEP from "./ResendBulletinPep";
import IDENTIFY_PATIENT_PEP from "./IdentifyPatientPep";
import GET_PRINT_EMIT_BULLETIN_DATA from "./GetPrintEmitBulletinData";

export default {
  SEARCH_BULLETINS,
  REMOVE_BULLETIN,
  REGISTER_BULLETIN,
  SEARCH_REGISTER_BULLETIN,
  BASIC_PATIENT_INFO,
  EDIT_BULLETIN,
  GET_FATHER,
  GET_MOTHER,
  GET_SUMMARY_BULLETIN,
  REGISTER_EXIT,
  DELETE_REGISTER_EXIT,
  SAVE_HOSPITALIZATION_SOLICITATION,
  GET_PATIENT_NO_WAY_OUT_BULLETIN,
  SEND_BULLETIN_PEP,
  IDENTIFY_PATIENT_PEP,
  CANCEL_SEND_BULLETIN_PEP,
  GET_PRINT_EMIT_BULLETIN_DATA,
  RESEND_BULLETIN_PEP,
};
