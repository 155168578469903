<template lang="html">
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.07469 1.45656L1.07473 1.45657L1.0784 1.44877C1.20828 1.17329 1.48135 1 1.78553 1H21.0718C21.376 1 21.6491 1.17329 21.779 1.44877C21.9098 1.72626 21.8699 2.05079 21.6768 2.2872L13.512 12.2644L13.2859 12.5407V12.8977V18.572C13.2859 18.736 13.1943 18.8821 13.0518 18.9527L13.4957 19.8488L13.0518 18.9527C12.8976 19.0291 12.7269 19.0103 12.6014 18.9159L12.6001 18.9149L9.7429 16.772L9.74291 16.7719L9.73813 16.7684C9.63604 16.6928 9.57145 16.5693 9.57145 16.4291V12.8977V12.5407L9.34532 12.2644L1.17668 2.28345C1.17651 2.28324 1.17635 2.28304 1.17618 2.28284C0.984719 2.04746 0.946991 1.72149 1.07469 1.45656Z"
      :stroke="color"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "Filtering",
  props: {
    color: {
      type: String,
      default: "#1E88A9",
    },
  },
};
</script>
