<style src="./RgTextArea.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :optional="optional" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>

      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <textarea
            :id="getId"
            ref="inputFieldId"
            v-model.trim="inputValue"
            :data-id="dataId"
            :type="type"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :disabled="disabled"
            class="textarea small-scroll"
            @blur="validateBlur"
          />

          <span v-show="viewCharacters" class="remaining-character">
            Caracteres restantes: {{ remainCharacters }}
          </span>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgTextArea",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    change: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewCharacters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      remainCharacters: this.maxlength,
    };
  },
  computed: {
    getId() {
      return !isEmpty(this.id) ? this.id : this.dataId;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    inputValue(pValue) {
      this.characterCount();
    },
  },
  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
    characterCount(event) {
      if (this.inputValue && this.inputValue.length <= this.maxlength) {
        this.remainCharacters = this.maxlength - this.inputValue.length;
      } else {
        this.remainCharacters = this.maxlength;
      }
    },
    resetCharacter() {
      this.remainCharacters = this.maxlength;
    },
  },
};
</script>
