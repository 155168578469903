<style src="./RgSuggestSmartPersonDocument.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient">
    <RgSuggest
      :id="id"
      ref="innerSuggest"
      v-model="inputValue"
      :rules="{ ...rules, fn: validateField }"
      :disabled="disabled"
      :label="labelSuggest"
      :placeholder="placeholderSuggest"
      :mask="mask"
      min="0"
      @clean="setMask"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="patient-item"
      >
        <span title="Nome">
          <IconUser />
          <span class="mg-l-5"
            >Nome: <strong>{{ item.pes_nome }}</strong></span
          >
        </span>

        <div title="Data de Nascimento">
          <IconCalendar />
          <span class="mg-l-5"
            >Data de Nascimento :
            <strong>{{ item.pes_nascimento | dateBR }}</strong></span
          >
        </div>

        <span title="Nome da Mãe">
          <IconGenderFemale />
          <span class="mg-l-5"
            >Nome da mãe: <strong>{{ item.pes_mae }}</strong></span
          >
        </span>

        <div title="Prontuário Único">
          <IconCard />
          <span class="mg-l-5"
            >Prontuário Único:
            <strong>{{
              item.patient ? item.patient.pac_prontuario_unico : ""
            }}</strong></span
          >
        </div>

        <div class="group">
          <div :class="{ 'field-search': colFilter.col === 'cns' }">
            <IconCard />
            <span class="mg-l-5"
              >CNS: <strong>{{ item.cns.crs_numero | Cns }}</strong></span
            >
          </div>

          <div :class="{ 'field-search': colFilter.col === 'cpf' }">
            <IconCard />
            <span class="mg-l-5"
              >CPF: <strong>{{ item.cpf.cpf_numero | Cpf }}</strong></span
            >
          </div>
        </div>
      </RgSuggestItem>
    </RgSuggest>

    <div class="filter-suggest-patient">
      <div v-for="(item, idx) in validFilters" :key="idx" class="input">
        <input
          :id="item.col"
          v-model="colFilter"
          :value="item"
          :disabled="disabled"
          type="radio"
          name="personNameFilter"
        />
        <span class="align-span">
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import {
  IconCalendar,
  IconGenderFemale,
  IconUser,
  IconCard,
} from "~tokio/primitive/icon";
import { SearchPerson } from "./action";
import moment from "moment";
import { mask } from "vue-the-mask";

export default {
  name: "RgSuggestSmartPersonDocument",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconCalendar,
    IconGenderFemale,
    IconCard,
    IconUser,
  },
  directives: { mask },
  filters: {
    Cns(pValue) {
      if (!pValue) return "CNS NÃO CADASTRADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
    Cpf(pValue) {
      if (!pValue) return "CPF NÃO CADASTRADO";
      return pValue;
    },
    dateBR(pValue) {
      const isValid = moment(pValue).isValid();
      return isValid
        ? moment(pValue).format("DD/MM/YYYY")
        : "Data não informada";
    },
  },
  mixins: [RgSuggestMixin],
  props: {
    withPatientModule: {
      type: Array,
      default: function () {
        return [];
      },
    },
    extraData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    enabledPatient: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      colFilter: { label: "RG", col: "rg" },
      filters: [
        { label: "RG", col: "rg" },
        { label: "CPF", col: "cpf" },
        { label: "CNS", col: "cns" },
      ],
      validfilters: [],
      captionRaw: "",
      mask: "",
    };
  },
  computed: {
    labelSuggest() {
      this.reset();
      return "Documentação";
    },
    placeholderSuggest() {
      return "Digite o nº do documento";
    },
  },

  watch: {
    "colFilter.col"(pValue, pPrev) {
      this.setMask();

      if (pValue && pValue !== pPrev) {
        this.inputValue = "";
      }
    },
    inputValue(pValue) {
      if (!pValue) {
        this.cleanValidate();
      }
    },
  },

  created() {
    this.setDefaultFilter();
  },

  mounted() {
    this.setMask();
  },

  methods: {
    mountFilter(pSearchTerm) {
      const term = pSearchTerm ? pSearchTerm.toString().trim() : "";

      switch (this.colFilter.col) {
        case "rg":
          return { rg: term.replace(/\.|-/gm, "") };
        case "cpf":
          return { cpf: term.replace(/\.|-/gm, "") };
        case "cns":
          return { cns: term.trim() };
      }
    },

    doSearch(pSearchTerm, pLimit = null) {
      const variables = this.mountFilter(pSearchTerm);
      variables.limit = pLimit;

      if (pSearchTerm && this.mask && pSearchTerm.length === this.mask.length) {
        return SearchPerson({ variables });
      }
    },

    forceSelection(pValue) {
      if (pValue && pValue.pes_id > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_id: pValue.pes_id,
            pes_nome: pValue.pes_nome,
            pes_nascimento: this.$utils.date.BrazilianDateFormat(
              pValue.pes_nascimento,
            ),
            cpf: {
              cpf_numero: pValue.cpf ? pValue.cpf.cpf_numero : null,
            },
            cns: {
              cns_numero: pValue.cns ? pValue.cns.cns_numero : null,
            },
            patient: {
              pac_prontuario_unico: pValue.patient
                ? pValue.patient.pac_prontuario_unico
                : null,
            },
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },

    validateField(pValue, pErrors) {
      if (!pValue) {
        return true;
      }

      if (
        this.colFilter.col === "cpf" &&
        !this.$utils.app.Document.verifyCpfNumber(pValue)
      ) {
        pErrors.push(" CPF inválido.");
        return false;
      }

      if (
        this.colFilter.col === "cns" &&
        !this.$utils.app.Document.verifyCnsNumber(pValue)
      ) {
        pErrors.push(" CNS inválido.");
        return false;
      }

      return true;
    },

    setDefaultFilter() {
      this.validFilters = this.filters;
    },

    setMask() {
      this.mask = null;

      switch (this.colFilter.col) {
        case "rg":
          this.mask = "#########";
          this.captionRaw = "rg.rgs_numero";
          break;
        case "cpf":
          this.mask = "###########";
          this.captionRaw = "cpf.cpf_numero";
          break;
        case "cns":
          this.mask = "###############";
          this.captionRaw = "cns.crs_numero";
          break;
        default:
          this.mask = null;
      }
    },

    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    clearMask() {
      this.mask = null;
    },

    reset() {
      this.inputValue = null;
      if (this.$refs.innerSuggest) {
        this.$refs.innerSuggest.resetSuggestionList();
        this.$refs.innerSuggest.$refs.input.focus();
      }
    },
  },
};
</script>
