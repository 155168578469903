import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacySatelliteRevenue from "$pharmacy/submodules/satellite/view/revenue/PharmacySatelliteRevenue";

export default {
  path: "/pharmacy/satellite/revenue",
  name: "pharmacy.satellite.revenue",
  components: {
    default: PharmacySatelliteRevenue,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Satélite",
        link: "/pharmacy/satellite",
      },
      {
        label: "Receita",
      },
    ],
  },
};
