<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import {
  SearchClassifications,
  ValidateClassification,
} from "../../select/rg-select-classification/action";

export default {
  name: "RgSelectClassification",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Classificação",
    },
    defaultText: {
      type: String,
      default: "Digite o nome da classificação",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    serviceCode: {
      type: String,
      default: "",
    },
    procedureCode: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    serviceCode(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        if (pValue.length > 0) this.fillData();
      }
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async validateClassification(pData) {
      if (
        this.unitHealthId &&
        this.unitHealthId > 0 &&
        this.procedureCode?.length > 0 &&
        this.serviceCode?.length > 0
      ) {
        let validate;
        let hasData;

        try {
          this.$loader.start();

          validate = await ValidateClassification({
            strCodigoProcedimento: this.procedureCode,
          });

          hasData = pData && pData.length > 0;

          if (!hasData && validate) {
            this.$toaster.info(
              'Verifique em "Faturamento/BPA/Serviços e Classificações" as opções disponíveis.',
              "A Classificação não foi importada para a Unidade",
            );
          }
        } catch (pErr) {
          this.$toaster.error(
            "Erro ao buscar validação para a classificação",
            pErr,
          );
        } finally {
          if (hasData || validate) {
            this.$emit("required", true);
          } else {
            this.$emit("required", false);
          }

          if (!hasData || hasData.length < 1) {
            this.$emit("disable", true);
          } else {
            this.$emit("disable", false);
          }

          this.$loader.finish();
        }
      }
    },
    async fillData() {
      let data;

      try {
        this.$loader.start();
        this.valuesData = [];

        if (
          this.unitHealthId > 0 &&
          this.serviceCode?.length > 0 &&
          this.procedureCode?.length > 0
        ) {
          data = await SearchClassifications({
            unitHealthId: this.unitHealthId,
            serviceCode: this.serviceCode,
            procedureCode: this.procedureCode,
          });

          this.valuesData = data.map((item) => {
            return {
              value: item.CO_CLASSIFICACAO,
              label: item.CO_CLASSIFICACAO + " - " + item.NO_CLASSIFICACAO,
            };
          });
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao buscar classificação", pErr);
      } finally {
        this.$loader.finish();
        this.validateClassification(data);
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
