import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/laboratory/edit-details/edit-patient",
  name: "exam.laboratory.edit-details.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      { label: "Exames Laboratoriais", link: "/exam/laboratory" },
      {
        label: "Visualizar Pedido",
        link: "/exam/laboratory/edit-details",
      },
      { label: "Cadastro do Paciente" },
    ],
  },
};
