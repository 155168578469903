import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, pPlaceId) => {
  return AuthLegacyRequest.post(
    "integracoes/controller-integracao-rg-pep/excluir-mapeamento-local-atendimento",
    { lca_id: pPlaceId },
  )
    .then(({ data }) => {
      const dados = HandleResponse(data);
      return dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
