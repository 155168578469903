<template lang="html">
  <section class="brand">
    <img :src="logoImg" class="logo" alt="Logo" @click="redirectToHome" />
  </section>
</template>

<script>
export default {
  name: "Brand",
  data() {
    return {
      logoImg: "/static/images/logo-esus+.png",
    };
  },
  methods: {
    redirectToHome() {
      if (this.$route.fullPath !== "/") {
        this.$router.push("/");
        localStorage.setItem(
          "exec-refresh-page-refreshLogin",
          JSON.stringify({ exec: "active", refresh: "active" }),
        );
      }
    },
  },
};
</script>
