import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBasicAttentionEmployees($teamId: Int!, $unsId: Int!) {
    SearchBasicAttentionEmployees(epr_id_equipes: $teamId, uns_id: $unsId) {
      rows {
        fab_id
        fun_id
        pes_nome
        ctf_codigo_cbo
        fun_crs_numero_ab
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.SearchBasicAttentionEmployees.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
