import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamUserProductivityReport(
    $uns_id: Int
    $set_id: Int
    $ocp_id: Int
    $pes_id: Int
    $usu_id: Int
    $healthInsurance: Int
    $stp_id: String
    $subprocedureId: String
    $exam_id_locais_atendimento: Int
    $employeeRequest: Int
    $groupById: Int
    $uns_nome: String
    $set_nome: String
    $ocp_nome: String
    $usu_nome: String
    $healthInsuranceName: String
    $stp_novo_nome_procedimento: String
    $subprocedureName: String
    $exam_id_locais_atendimento_name: String
    $employeeRequestName: String
    $groupByName: String
    $initialDate: String
    $finalDate: String
    $initialDateExam: String
    $finalDateExam: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchExamUserProductivityReport(
      uns_id: $uns_id
      set_id: $set_id
      ocp_id: $ocp_id
      pes_id: $pes_id
      usu_id: $usu_id
      stp_id: $stp_id
      healthInsurance: $healthInsurance
      subprocedureId: $subprocedureId
      exam_id_locais_atendimento: $exam_id_locais_atendimento
      employeeRequest: $employeeRequest
      groupById: $groupById
      uns_nome: $uns_nome
      set_nome: $set_nome
      ocp_nome: $ocp_nome
      usu_nome: $usu_nome
      healthInsuranceName: $healthInsuranceName
      subprocedureName: $subprocedureName
      exam_id_locais_atendimento_name: $exam_id_locais_atendimento_name
      employeeRequestName: $employeeRequestName
      groupByName: $groupByName
      stp_novo_nome_procedimento: $stp_novo_nome_procedimento
      initialDate: $initialDate
      finalDate: $finalDate
      initialDateExam: $initialDateExam
      finalDateExam: $finalDateExam
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        QUANTIDADE
        DATA_EXAME
        OCUPACAO
        PROFISSIONAL
        PROCEDIMENTO
        SUBPROCEDIMENTOS
        UNS_NOME
        SETOR
        USUARIO
        LOCAL_ATENDIMENTO
        CONVENIO
        DATA_CADASTRO
        PERCENT
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamUserProductivityReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
