import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import DefineEmergencyPeriod from "$billing/submodules/bpa/define-period/view/DefinePeriod.vue";

export default {
  path: "/billing/bpa/define-period",
  name: "billing.bpa.define-period",
  components: {
    default: DefineEmergencyPeriod,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      { label: "BPA", link: OpenSubModule("billing") },
      { label: "Competência BPA", link: "/define-period" },
    ],
  },
};
