import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

export default {
  path: "/person/patient/search/editpatient/patient-care-history",
  name: "person.patient.search.editpatient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastro", link: OpenMenuModule("register") },
      { label: "Paciente", link: OpenSubModule("register") },
      { label: "Cadastros de Pacientes", link: "/person/patient/search" },
      {
        label: "Cadastro do Paciente",
        link: "/person/patient/search/editpatient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
