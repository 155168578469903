import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "faturamento/controller-paciente-procedimento-enviado-pep/combo-box-procedimento",
    variables,
  )
    .then(({ data }) => {
      const result = [];

      data.dados.forEach((item) => {
        if (item.data.length > 0) {
          result.push(item);
        }
      });

      return result;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
