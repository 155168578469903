import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($bol_id: Int!, $bol_alta_administrativa_data: String!) {
    SaveAdministrativeCheckout(
      bol_id: $bol_id
      bol_alta_administrativa_data: $bol_alta_administrativa_data
    ) {
      success
      message
    }
  }
`;

export default async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });

    return data.SaveAdministrativeCheckout;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
