import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyCentralDiscardReport(
    $unitHealth: Int
    $pharmacy: Int
    $product: Int
    $mdc_id: Int
    $mtp_id: Int
    $usu_id: Int
    $unitHealthName: String
    $pharmacyName: String
    $productName: String
    $usu_nome: String
    $mtp_nome: String
    $mdc_nome: String
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyCentralDiscardReport(
      uns_id: $unitHealth
      far_id: $pharmacy
      mpd_id: $product
      mdc_id: $mdc_id
      mtp_id: $mtp_id
      usu_id: $usu_id
      uns_nome: $unitHealthName
      far_nome: $pharmacyName
      mpd_nome: $productName
      usu_nome: $usu_nome
      mtp_nome: $mtp_nome
      mdc_nome: $mdc_nome
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        PRODUTO
        NOME_COMERCIAL
        UNIDADE_SAUDE
        FARMACIA
        TIPO
        APRESENTACAO
        LOTE_VALIDADE
        QUANTIDADE
        DATA
        MOTIVO
        OBSERVACAO
        USUARIO
        VALOR_UNITARIO
        VALOR_TOTAL
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyCentralDiscardReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
