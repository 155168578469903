<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchTypePharmacy from "./action/SearchTypePharmacy";

export default {
  name: "RgSelectPharmacy",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Tipo",
    },
  },
  data: () => {
    return {
      type: [],
    };
  },
  watch: {
    type: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.type;
    },
    async doSearch() {
      this.type = [];

      const data = await SearchTypePharmacy();

      this.type = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
