import LocalStorage from "~common/local-storage/LocalStorage";

const clientNameTitle = (cli_nome) => {
  return cli_nome ? `<h2>${cli_nome}</h2>` : "";
};

const effectivationPassword = (eap_senha_efetivacao) => {
  return eap_senha_efetivacao
    ? `<label>Senha Efetivação: </label><span>${eap_senha_efetivacao}</span>`
    : "";
};

const drawPreparationSubprocedure = (data, show) => {
  const validData = data?.length > 0;

  let html = "";

  if (show && validData) {
    data.forEach((item) => {
      html += `
      <div class="exibir-info">
        <label>Nome:</label>
        <span>${item.nome_suprocedimento}</span>
        <span>${item.preparo}</span>
      </div>`;
    });
  }

  return html;
};

const drawPreparationProcedure = (recomendation) => {
  let html = "";
  if (recomendation?.length > 0) {
    html = `<div class="exibir-info"><label>Modo de Preparo:</label><span>${recomendation}</span></div>`;
  }
  return html;
};
const getHalfSheet = (title, data, printPreparationSub) => {
  const logo = LocalStorage.getObject("client-logo");

  const unitAddress = `<p>
    ${data.tlg_nome} ${data.end_logradouro} ${data.end_numero} ${data.end_complemento} ${data.bai_nome} - ${data.mun_nome}
  </p>`;

  const html = `
    <div class="meia-folha">
      <div>
        <h1>${title}</h1>
        <img class="logo-cliente" src="${logo}">
        ${clientNameTitle(data.cli_nome)}
      </div>

      <div class="info-unidade">
        <h3>${data.uns_nome}</h3>
        ${unitAddress}
      </div>

      <hr>

      <div class="info-agendamento">
        <p class="referencia">${data.end_referencia}</p>
        <div>
          <label>Data:</label>
          <span>${data.eha_data_exame} às ${data.eap_hora}</span>
        </div>
        <div>
          <label>Paciente:</label>
          <span>${data.pes_nome}</span>
        </div>
        <div>
          <label>Nome Social:</label>
          <span>${data.pes_nome_social}</span>
        </div>
        <div>
          <label>Prontuário:</label>
          <span>${data.ppr_numero}</span>
        </div>
        <div>
          <label>Prontuário Único:</label>
          <span>${data.pac_prontuario_unico}</span>
        </div>
        <div>
          <label>Cartão SUS:</label>
          <span>${data.crs_numero}</span>
        </div>
        <div>
          <label>Setor:</label>
          <span>${data.set_nome}</span>
        </div>
        <div>
          <label>Local:</label>
          <span>${data.lca_nome}</span>
        </div>
        <div class="exibir-info">
          <label>Procedimento:</label>
          <span>${data.stp_novo_nome_procedimento}</span>
        </div>

        ${drawPreparationProcedure(data.stp_recomendacao)}

        <div class="exibir-info">
          <label>Subprocedimento(s):</label>
          <span>${data.subprocedimentos}</span>
        </div>

        ${drawPreparationSubprocedure(
          data.recomendacaoPreparoSub,
          printPreparationSub,
        )}

        <div>
          <label>Profissional:</label>
          <span>${data.atendente}</span>
        </div>
        <div>
          <label>Ocupação:</label>
          <span>${data.ocp_nome}</span>
        </div>

        ${effectivationPassword(data.eap_senha_efetivacao)}

        <div>
          <label style="width: 200px;">${data.agendadoPelaFila}</label>
        </div>
        <div>
          <label>${data.lblNomeRegulador}</label>
          <span>${data.nomeRegulador}</span>
        </div>
        <div>
          <label>Observações:</label>
          <span>${data.observacoes ? data.observacoes : "-"}</span>
        </div>
      </div>
    </div>
  `;

  return html;
};

const ScheduleVoucherHTML = (pListExams, printPreparationSub) => {
  const style = (element) => {
    const css = `
      <style type="text/css">
        body {
          font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
        }

        page {
          background: white;
          display: block;
          margin: 0 auto;
          margin-bottom: 0.5cm;
        }

        page[size="A4"] {
          width: 21cm;
          height: 30.5cm;
        }

        h1 {
          text-align: center;
          text-transform: uppercase;
          font-size: 14pt;
        }

        h2 {
          margin: 33px 0 0 0;
          font-size: 12pt;
        }

        h3 {
          text-transform: uppercase;
          font-size: 11pt;
        }

        hr {
          width: 70%;
          display: block;
          height: 1px;
          border: 0;
          border-top: 1px solid #666;
        }

        p,
        label,
        span {
          font-size: 11pt;
        }

        .meia-folha {
          width: 47%;
          float: left;
          border: 0;
        }

        .meia-folha:first-child {
          padding: 0 45px 0 0;
        }

        .logo-cliente {
          width: 65px;
          margin: 0 20px 0 0;
          float: left;
        }

        .info-unidade {
          margin: 40px 0 22px 0;
        }

        .info-agendamento {
          margin: 20px 0 0 0;
        }

        .info-agendamento div {
          margin: 0 0 6px 0;
        }

        .info-agendamento label {
          width: 145px;
          display: inline-block;
          font-weight: 700;
        }

        .referencia {
          text-transform: uppercase;
          text-align: center;
        }

        .exibir-info {
          display: ${element.exibir_info};
        }

        @media print {
          body,
          page {
              margin: 0;
              box-shadow: 0;
          }
        }
      </style>
    `;

    return css;
  };

  let html = ``;

  pListExams.forEach((element) => {
    html += `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <title>Nova Impressão (Normal) de Agendamento</title>
          ${style(element)}
        </head>

      <body>
        <page size="A4">
          ${getHalfSheet("Via do paciente", element, printPreparationSub)}
          ${getHalfSheet("Via do ambulatório", element, printPreparationSub)}
        </page>
      </body>
    </html>`;
  });

  return html;
};

export default ScheduleVoucherHTML;
