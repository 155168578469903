<template>
  <Modulebox
    :granted="hasPermission"
    title="Pendência"
    class="exam-queue-pendencies"
  >
    <div class="body">
      <form class="form-container" @submit.stop.prevent="registerPendency">
        <FormBase title="Pendência" class="content-form">
          <RgValidatorForm ref="validator">
            <span class="data">
              Paciente:
              <strong>
                {{ patientInfo.pes_nome }}
              </strong>
            </span>

            <RgTextArea
              id="pendency"
              ref="textArea"
              v-model="form.pendency"
              :maxlength="500"
              :disabled="disabledByModal"
              :rules="{ required: !disabledByModal }"
              class="text-area"
              label="Adicionar Pendência"
            />

            <div class="content-buttons">
              <div class="actions">
                <RgCleanButton
                  id="clear-pendency"
                  title="Limpar"
                  class="clean"
                  :disabled="disabledByModal"
                  @click="cleanPendency"
                />

                <RgAddButton
                  id="add-pendency"
                  :disabled="!hasPendingText || disabledByModal"
                  :class="{ disable: !hasPendingText }"
                  large
                  title="Adicionar pendência"
                  type="button"
                  class="add"
                  @click="registerPendency"
                />
              </div>
            </div>

            <div v-if="hasPendencies" class="content-table">
              <div class="order-buttons">
                <RgButton
                  v-if="!equalListSize"
                  id="select-all"
                  class="title check"
                  type="button"
                  :disabled="disabledByModal"
                  @click="selectAllList"
                >
                  <IconSquare />
                </RgButton>
                <RgButton
                  v-if="equalListSize"
                  id="unselect-all"
                  class="title check"
                  type="button"
                  :disabled="disabledByModal"
                  @click="unselectAllList"
                >
                  <IconSquareChecked />
                </RgButton>
                <RgButton
                  id="reverse-list"
                  class="title"
                  type="button"
                  :disabled="disabledByModal"
                  @click="reverseListSelection"
                >
                  <IconArrowSquare />
                </RgButton>
              </div>
              <table class="table-data unselect">
                <tr class="header">
                  <th class="title check"></th>
                  <th class="title">Pendência</th>
                  <th class="title">Data da pendência</th>
                  <th class="title">Cadastrado por</th>
                  <th class="title">Resolução</th>
                  <th class="title">Data da resolução</th>
                  <th class="title">Resolvido por</th>
                </tr>

                <tr
                  v-for="(item, index) in mutableListPendenciesQueue"
                  :key="index"
                  :class="{ 'active-row': item.selected }"
                  class="data-table"
                  @click="selectLine(item, index)"
                >
                  <td class="icon-check">
                    <div v-if="item.selected" class="check">
                      <IconCheck />
                    </div>
                  </td>

                  <td class="data">
                    {{ item.fip_motivo_pendencia }}
                  </td>

                  <td class="data">
                    {{ item.fip_data_pendencia }}
                  </td>

                  <td class="data">
                    {{ item.usuario_pendencia }}
                  </td>

                  <td class="data">
                    {{ item.fip_resolucao }}
                  </td>

                  <td class="data">
                    {{ item.fip_data_resolucao }}
                  </td>

                  <td class="data">
                    {{ item.usuario_resolucao }}
                  </td>
                </tr>
              </table>
            </div>

            <div v-else class="no-result">
              <IconEmpty />
              <span class="message"> Nenhuma pendência encontrada </span>
            </div>

            <div class="actions">
              <SmallButton
                id="printer-pendency"
                :disabled="!enableButtons || disabledByModal"
                :backgroundColor="'#1E88A9'"
                class="printer-pending"
                title="Imprimir pendência"
                type="button"
                @click="printerPendency"
              >
                <IconPrinter slot="icon" />
              </SmallButton>

              <SmallButton
                id="delete-pendency"
                :disabled="hasOnePendencySelected || disabledByModal"
                :backgroundColor="'#f96b70'"
                type="button"
                class="delete-pending"
                title="Excluir pendência"
                @click="openModalRemovePendency"
              >
                <IconDeletePending slot="icon" />
              </SmallButton>

              <SmallButton
                id="resolve-pendency"
                :disabled="!enableButtons || !notResolution || disabledByModal"
                :backgroundColor="'#1E88A9'"
                class="resolve-pending"
                type="button"
                title="Resolver pendência"
                @click="openModalRegisterResolution"
              >
                <IconResolvePending slot="icon" />
              </SmallButton>

              <SmallButton
                id="delete-resolve-pendency"
                :disabled="
                  hasOnePendencySelected || !hasResolution || disabledByModal
                "
                :backgroundColor="'#f96b70'"
                class="delete-pending-resolution"
                title="Excluir resolução da pendência"
                type="button"
                @click="openModalRemoveResolution"
              >
                <IconDeletePendingResolution slot="icon" />
              </SmallButton>

              <SmallButton
                id="show-pendency"
                :disabled="hasOnePendencySelected || disabledByModal"
                :backgroundColor="'#1E88A9'"
                class="view-pending"
                type="button"
                title="Visualizar pendência"
                @click="openModalViewPending(patientInfo, infoRow)"
              >
                <IconShow slot="icon" />
              </SmallButton>
            </div>
          </RgValidatorForm>
        </FormBase>
      </form>
    </div>

    <div slot="footer" class="footer">
      <RgBackButton
        id="back-btn"
        medium
        :disabled="disabledByModal"
        title="Voltar"
        @click="comeBackPage"
      />
    </div>

    <ModalConfirmDefault
      id="modal-confirm-default"
      :show="modalRemovePendency"
      :message="'Deseja mesmo excluir a pendência?'"
      title="Remover pendência"
      yes-label="Sim"
      no-label="Não"
      @getYes="removePendency(infoRow)"
      @getOut="closeModalRemovePendency"
    />

    <ModalRegisterSingleInformation
      id="modal-register-single-information"
      ref="registerResolution"
      :show="modalRegisterResolution"
      title="Resolver pendência"
      @save="registerResolution(infoRow)"
      @close="closeModalRegisterResolution"
    />

    <ModalConfirmDefault
      id="modal-confirm-default"
      :show="modalRemoveResolution"
      :message="'Deseja mesmo excluir a resolução da pendência?'"
      title="Remover resolução"
      yes-label="Sim"
      no-label="Não"
      @getYes="removeResolution(infoRow)"
      @getOut="closeModalRemoveResolution"
    />

    <ModalViewPending
      id="modal-view-pending"
      ref="modalViewPending"
      :show="modalViewPending"
      :patient="patientInfo"
      :info="listPendencies[0]"
      @close="closeModalViewPending"
    />
  </Modulebox>
</template>

<script>
import {
  RgCleanButton,
  RgAddButton,
  RgBackButton,
  IconEmpty,
  RgTextArea,
  IconDeletePending,
  IconResolvePending,
  IconShow,
  IconSquare,
  IconCheck,
  IconSquareChecked,
  IconArrowSquare,
  IconPrinter,
  IconDeletePendingResolution,
  ModalConfirmDefault,
  RgValidatorForm,
  SmallButton,
  RgButton,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";

import { AlertError } from "~tokio/primitive/notification";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ModalViewPending from "$exam/submodules/schedule/component/modal/modal-view-pending/ModalViewPending";
import ModalRegisterSingleInformation from "~tokio/primitive/modal/modal-register-single-information/ModalRegisterSingleInformation";

export default {
  name: "ExamQueuePendencies",
  components: {
    FormBase,
    ModalViewPending,
    ModalConfirmDefault,
    ModalRegisterSingleInformation,
    Modulebox,
    RgValidatorForm,
    RgAddButton,
    RgBackButton,
    RgTextArea,
    RgCleanButton,
    IconEmpty,
    IconDeletePending,
    IconResolvePending,
    IconShow,
    IconSquare,
    IconCheck,
    IconSquareChecked,
    IconArrowSquare,
    IconPrinter,
    IconDeletePendingResolution,
    SmallButton,
    RgButton,
  },

  data() {
    return {
      patientInfo: {},
      infoRow: {},
      mutableListPendenciesQueue: [],
      listPendencies: [],
      modalRemovePendency: false,
      modalRegisterResolution: false,
      modalRemoveResolution: false,
      modalViewPending: false,
      index: null,
      activeRow: false,
      form: {
        pendency: null,
      },
    };
  },

  computed: {
    disabledByModal() {
      return (
        this.modalRemovePendency ||
        this.modalRegisterResolution ||
        this.modalRemoveResolution ||
        this.modalViewPending
      );
    },

    hasPermission() {
      return this.$Permissions.global.has("exame2.filaExame");
    },

    getPermissionRegisterPendency() {
      return this.$Permissions.global.has(
        "exame2.filaExame.cadastrarPendencia",
      );
    },

    getPermissionRemovePendency() {
      return this.$Permissions.global.has("exame2.filaExame.excluirPendencia");
    },

    getPermissionRegisterResolution() {
      return this.$Permissions.global.has("exame2.filaExame.resolverPendencia");
    },

    hasPendencies() {
      return (
        this.mutableListPendenciesQueue &&
        this.mutableListPendenciesQueue.length >= 1
      );
    },

    enableButtons() {
      return this.listPendencies && this.listPendencies.length > 0;
    },

    hasOnePendencySelected() {
      return this.listPendencies.length !== 1;
    },

    notResolution() {
      const resolution = this.listPendencies.every((item) => {
        return item.fip_resolucao.length < 1;
      });
      return resolution;
    },

    hasResolution() {
      const resolution = this.listPendencies.every((item) => {
        return item.fip_resolucao.length > 0;
      });
      return this.listPendencies.length > 0 && resolution;
    },

    hasPendingText() {
      return this.form.pendency && this.form.pendency.length >= 5;
    },

    equalListSize() {
      return (
        this.listPendencies.length === this.mutableListPendenciesQueue.length
      );
    },
  },

  created() {
    this.CLIENT = this.$store.getters["Login/GET_USER_ID_CLIENT"];
  },

  destroyed() {
    this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_LIST_QUEUE_DATA");
  },

  async mounted() {
    const patient = await this.$store.getters[
      "Exam/Schedule/GET_SELECTED_PATIENT_LIST_QUEUE"
    ];
    this.patientInfo = patient[0];
    this.searchPendency(this.patientInfo);
  },

  methods: {
    async searchPendency(pData) {
      this.mutableListPendenciesQueue = await this.$store.dispatch(
        "Exam/Schedule/SEARCH_PENDENCY_QUEUE",
        { intIdFila: pData.fil_id },
      );
    },

    selectLine(pItem) {
      if (pItem.selected) {
        pItem.selected = false;

        this.listPendencies = this.listPendencies.filter((item) => {
          return item.fip_id !== pItem.fip_id;
        });
      } else {
        this.listPendencies.push(pItem);
        pItem.selected = true;
      }
      this.$forceUpdate();
    },

    selectAllList() {
      this.mutableListPendenciesQueue.forEach((item) => {
        if (!item.selected) {
          item.selected = true;
          this.listPendencies.push(item);
        }
      });
    },

    unselectAllList() {
      this.mutableListPendenciesQueue.forEach((item) => {
        if (item.selected === true) {
          this.listPendencies.splice(this.listPendencies.indexOf(item), 1);
          item.selected = false;
        }
      });
    },

    reverseListSelection() {
      this.mutableListPendenciesQueue.forEach((item, index) => {
        if (item.selected === true) {
          this.listPendencies.splice(this.listPendencies.indexOf(item), 1);
          item.selected = false;
        } else {
          item.selected = true;
          this.listPendencies.push(item);
        }
      });
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async registerPendency() {
      if (!(await this.isFormValid())) {
        return AlertError("Verifique os Campos");
      } else {
        if (this.form.pendency && this.form.pendency.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo pendência",
          );
          return;
        }

        const variables = {
          fip_id_filas: this.patientInfo.fil_id,
          fip_motivo_pendencia: this.form.pendency,
          objModulo: 33,
        };

        try {
          if (this.getPermissionRegisterPendency) {
            this.$loader.start();

            await this.$store.dispatch(
              "Exam/Schedule/SAVE_PENDENCY_QUEUE",
              variables,
            );

            this.$toaster.success("Pendência cadastrada com sucesso");
            this.cleanPendency();
            this.searchPendency(this.patientInfo);
          }
        } catch (error) {
          this.$toaster.error("Erro ao salvar pendência");
        } finally {
          this.$loader.finish();
        }
      }
    },

    async printerPendency() {
      try {
        if (this.hasPendencies) {
          const ids = this.listPendencies.map((pendecy) =>
            Number(pendecy.fip_id),
          );

          const html = await this.$store.dispatch(
            "Exam/Schedule/GET_PRINT_PENDENCY_QUEUE",
            { fipId: ids },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error("Erro ao gerar a impressão das pendências");
      }
    },
    async removePendency() {
      try {
        if (this.getPermissionRemovePendency) {
          this.$loader.start();

          await this.$store.dispatch("Exam/Schedule/REMOVE_PENDENCY_QUEUE", {
            intIdFilaPendencia: this.listPendencies[0].fip_id,
            intIdModulo: 33,
          });
          this.$toaster.success("Pendência removida com sucesso");
          this.setLine();
          this.mutableListPendenciesQueue = [];
          this.searchPendency(this.patientInfo);
        }
      } catch (error) {
        this.$toaster.error("Erro ao excluir pendência");
      } finally {
        this.modalRemovePendency = false;
        this.$loader.finish();
      }
    },

    async registerResolution() {
      const textResolution = this.$refs.registerResolution.text;

      if (!(await this.isFormValid())) {
        return AlertError("Verifique os Campos");
      } else {
        if (textResolution.length < 5) {
          this.$toaster.warning("Preencha no mínimo 5 caracteres");
          return;
        }

        const pendenciesId = [];

        this.listPendencies.forEach((item) => {
          pendenciesId.push(item.fip_id);
        });

        try {
          if (this.getPermissionRegisterResolution) {
            this.$loader.start();

            await this.$store.dispatch("Exam/Schedule/SAVE_RESOLUTION_QUEUE", {
              arrPendencias: pendenciesId,
              strResolution: textResolution,
              pIdModulo: 33,
            });

            this.$toaster.success("Resolução cadastrada com sucesso");
            this.cleanResolution();
            this.setLine();
            this.modalRegisterResolution = false;
            this.searchPendency(this.patientInfo);
          }
        } catch (error) {
          this.$toaster.error(error);
        } finally {
          this.modalRegisterResolution = false;
          this.$loader.finish();
        }
      }
    },

    async removeResolution() {
      try {
        this.$loader.start();

        await this.$store.dispatch("Exam/Schedule/REMOVE_RESOLUTION_QUEUE", {
          intIdFilaPendencia: this.listPendencies[0].fip_id,
          intIdModulo: 33,
        });

        this.$toaster.success("Resolução removida com sucesso");
        this.setLine();

        this.searchPendency(this.patientInfo);
      } catch (error) {
        this.$toaster.error("Erro ao excluir resolução de pendência");
      } finally {
        this.modalRemoveResolution = false;
        this.$loader.finish();
      }
    },

    openModalRemovePendency() {
      this.modalRemovePendency = true;
    },

    closeModalRemovePendency() {
      this.modalRemovePendency = false;
    },

    openModalRegisterResolution() {
      this.modalRegisterResolution = true;
      this.$refs.textArea.cleanValidate();
    },

    closeModalRegisterResolution() {
      this.modalRegisterResolution = false;
    },

    openModalRemoveResolution() {
      this.modalRemoveResolution = true;
    },

    closeModalRemoveResolution() {
      this.modalRemoveResolution = false;
    },

    openModalViewPending(pPatient, pInfo) {
      this.modalViewPending = true;
      this.patient = pPatient;
      this.info = pInfo;
    },

    closeModalViewPending() {
      this.modalViewPending = false;
    },

    cleanPendency() {
      this.form.pendency = null;
      this.$refs.textArea.resetCharacter();
    },

    cleanResolution() {
      this.$refs.registerResolution.text = null;
      this.$refs.textArea.resetCharacter();
    },

    setLine() {
      this.listPendencies = [];
    },

    comeBackPage() {
      this.$router.go(-1);
    },
  },
};
</script>
