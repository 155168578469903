<template>
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="38"
      cy="38"
      r="36.5"
      fill="#53B1D8"
      stroke="#53B1D8"
      stroke-width="3"
    />
    <path
      d="M52.5813 23.4189C52.0228 22.8604 51.1199 22.8604 50.5613 23.4189L40.1429 33.8374L36.8672 30.5617C36.4573 30.1531 35.8444 30.0274 35.3101 30.2517C34.7773 30.4717 34.4287 30.9945 34.4287 31.5717V40.143C34.4287 40.933 35.0687 41.5715 35.8573 41.5715H44.4286C45.0057 41.5715 45.5271 41.2244 45.7486 40.6901C45.97 40.1558 45.8471 39.5416 45.4386 39.133L42.1629 35.8573L52.5813 25.4389C53.1399 24.8803 53.1399 23.9775 52.5813 23.4189Z"
      fill="white"
    />
    <path
      d="M47.2854 53H27.2857C24.9228 53 23 51.0772 23 48.7143V28.7146C23 26.3518 24.9228 24.429 27.2857 24.429H38.7141C39.5026 24.429 40.1426 25.0675 40.1426 25.8575C40.1426 26.6475 39.5026 27.2861 38.7141 27.2861H27.2857C26.4985 27.2861 25.8571 27.9261 25.8571 28.7146V48.7143C25.8571 49.5029 26.4985 50.1429 27.2857 50.1429H47.2854C48.0725 50.1429 48.7139 49.5029 48.7139 48.7143V37.2859C48.7139 36.4959 49.3539 35.8574 50.1425 35.8574C50.9311 35.8574 51.571 36.4959 51.571 37.2859V48.7143C51.571 51.0772 49.6482 53 47.2854 53Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Regulation",
};
</script>
