<template>
  <Modulebox
    :granted="hasPermission"
    title="Pendência"
    class="hospitalization-queue-pendencies"
  >
    <div class="body">
      <form class="form-container" @submit.stop.prevent="registerPendency">
        <FormBase title="Pendência" class="content-form">
          <RgValidatorForm ref="validator">
            <span class="data">
              Paciente:
              <strong>
                {{ patientInfo.pes_nome }}
              </strong>
            </span>

            <RgTextArea
              id="pendency"
              ref="textArea"
              v-model="form.pendency"
              :maxlength="500"
              :disabled="disabledByModal"
              :rules="{ required: !disabledByModal }"
              class="text-area"
              label="Adicionar Pendência"
            />

            <div class="actions">
              <RgCleanButton
                id="clean-pendency"
                title="Limpar"
                class="clean"
                :disabled="disabledByModal"
                @click="cleanPendency"
              />

              <RgAddButton
                id="new-pendency"
                :disabled="!hasPendingText || disabledByModal"
                :class="{ disable: !hasPendingText }"
                large
                title="Adicionar pendência"
                class="add"
                @click="registerPendency"
              />
            </div>

            <div v-if="hasPendencies" class="content-table">
              <div class="order-buttons">
                <button
                  v-if="!equalListSize"
                  id="select-all-list"
                  :disabled="disabledByModal"
                  class="title check"
                  type="button"
                  @click="selectAllList"
                >
                  <IconSquare />
                </button>
                <button
                  v-if="equalListSize"
                  id="unselect-all-list"
                  :disabled="disabledByModal"
                  class="title check"
                  type="button"
                  @click="unselectAllList"
                >
                  <IconSquareChecked />
                </button>
                <button
                  id="reverse-list"
                  class="title"
                  :disabled="disabledByModal"
                  type="button"
                  @click="reverseListSelection"
                >
                  <IconArrowSquare />
                </button>
              </div>

              <table class="table-data unselect">
                <tr class="header">
                  <th class="title check" />
                  <th class="title">Pendência</th>
                  <th class="title">Data da pendência</th>
                  <th class="title">Cadastrado por</th>
                  <th class="title">Resolução</th>
                  <th class="title">Data da resolução</th>
                  <th class="title">Resolvido por</th>
                </tr>

                <tr
                  v-for="(item, index) in mutableListPendenciesQueue"
                  :key="index"
                  :class="{ 'active-row': item.selected }"
                  class="data-table"
                  @click="selectLine(item, index)"
                >
                  <td class="icon-check">
                    <div v-if="item.selected" class="check">
                      <IconCheck />
                    </div>
                  </td>

                  <td class="data">
                    {{ item.fip_motivo_pendencia }}
                  </td>

                  <td class="data">
                    {{ item.fip_data_pendencia }}
                  </td>

                  <td class="data">
                    {{ item.usuario_pendencia }}
                  </td>

                  <td class="data">
                    {{ item.fip_resolucao }}
                  </td>

                  <td class="data">
                    {{ item.fip_data_resolucao }}
                  </td>

                  <td class="data">
                    {{ item.usuario_resolucao }}
                  </td>
                </tr>
              </table>
            </div>

            <div v-else class="no-result">
              <IconEmpty />
              <span class="message"> Nenhuma pendência encontrada </span>
            </div>

            <div class="actions">
              <SmallButton
                id="print-pending"
                :disabled="!enableButtons || disabledByModal"
                :backgroundColor="'#1E88A9'"
                class="printer-pending"
                title="Imprimir pendência"
                type="button"
                @click="printerPendency"
              >
                <IconPrinter slot="icon" />
              </SmallButton>

              <SmallButton
                id="delete-pending"
                :disabled="hasOnePendencySelected"
                :backgroundColor="'#f96b70'"
                class="delete-pending"
                title="Excluir pendência"
                type="button"
                @click="openModalRemovePendency"
              >
                <IconDeletePending slot="icon" />
              </SmallButton>

              <SmallButton
                id="resolve-pending"
                :disabled="!enableButtons || hasResolution"
                :backgroundColor="'#1E88A9'"
                class="resolve-pending"
                title="Resolver pendência"
                type="button"
                @click="openModalRegisterResolution"
              >
                <IconResolvePending slot="icon" />
              </SmallButton>

              <SmallButton
                id="delete-resolve-pending"
                :disabled="hasOnePendencySelected || !hasResolution"
                :backgroundColor="'#f96b70'"
                class="delete-pending-resolution"
                title="Excluir resolução da pendência"
                type="button"
                @click="openModalRemoveResolution"
              >
                <IconDeletePendingResolution slot="icon" />
              </SmallButton>

              <SmallButton
                id="show-pending"
                :disabled="hasOnePendencySelected"
                :backgroundColor="'#1E88A9'"
                class="view-pending"
                title="Visualizar pendência"
                type="button"
                @click="openModalViewPending(patientInfo, infoRow)"
              >
                <IconShow slot="icon" />
              </SmallButton>
            </div>
          </RgValidatorForm>
        </FormBase>
      </form>
    </div>

    <div slot="footer" class="footer">
      <RgBackButton
        id="go-back"
        medium
        title="Voltar"
        :disabled="disabledByModal"
        @click="comeBackPage"
      />
    </div>

    <ModalConfirmDefault
      id="modal-confirm-default-remove-pendency"
      :show="modalRemovePendency"
      :message="'Deseja mesmo excluir a pendência?'"
      title="Remover pendência"
      yes-label="Sim"
      no-label="Não"
      @getYes="removePendency(infoRow)"
      @getOut="closeModalRemovePendency"
    />

    <ModalRegisterSingleInformation
      id="modal-register-single-information"
      ref="registerResolution"
      :show="modalRegisterResolution"
      showCancelButton
      subtitle="Observações"
      title="Resolver pendência"
      @save="registerResolution(infoRow)"
      @close="closeModalRegisterResolution"
    />

    <ModalConfirmDefault
      id="modal-confirm-default-remove-resolution"
      :show="modalRemoveResolution"
      yes-label="Salvar"
      no-label="Não"
      v-bind="propsModalConfirmDefault"
      @getYes="removeResolution(infoRow)"
      @getOut="closeModalRemoveResolution"
      @close="closeModalRemoveResolution"
    />

    <ModalViewPending
      id="modal-view-pending"
      ref="modalViewPending"
      :show="modalViewPending"
      :patient="patientInfo"
      :info="
        listPendencies && listPendencies.length === 1 ? listPendencies[0] : null
      "
      @close="closeModalViewPending"
    />
  </Modulebox>
</template>

<script>
import {
  RgCleanButton,
  RgAddButton,
  IconEmpty,
  RgTextArea,
  RgBackButton,
  IconDeletePending,
  IconResolvePending,
  IconShow,
  IconCheck,
  IconSquare,
  IconSquareChecked,
  IconArrowSquare,
  IconPrinter,
  IconDeletePendingResolution,
  ModalConfirmDefault,
  RgValidatorForm,
  SmallButton,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";

import { AlertError } from "~tokio/primitive/notification";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ModalViewPending from "$hospitalization/hospitalizations/queue/component/modal/modal-view-pending/ModalViewPending";
import ModalRegisterSingleInformation from "~tokio/primitive/modal/modal-register-single-information/ModalRegisterSingleInformation";
import ScheduleQueuePendenciesHTML from "$hospitalization/hospitalizations/queue/html/ScheduleQueuePendenciesHTML";
import moment from "moment";

export default {
  name: "HospitalizationQueuePendencies",
  components: {
    FormBase,
    ModalViewPending,
    ModalConfirmDefault,
    ModalRegisterSingleInformation,
    Modulebox,
    RgValidatorForm,
    RgBackButton,
    RgAddButton,
    RgTextArea,
    RgCleanButton,
    IconEmpty,
    IconDeletePending,
    IconResolvePending,
    IconShow,
    IconCheck,
    IconSquare,
    IconSquareChecked,
    IconArrowSquare,
    IconPrinter,
    IconDeletePendingResolution,
    SmallButton,
  },

  data() {
    return {
      patientInfo: {},
      infoRow: {},
      mutableListPendenciesQueue: [],
      listPendencies: [],
      modalRemovePendency: false,
      modalRegisterResolution: false,
      modalRemoveResolution: false,
      modalViewPending: false,
      index: null,
      form: {
        pendency: null,
      },
    };
  },

  computed: {
    disabledByModal() {
      return (
        this.modalRemovePendency ||
        this.modalRegisterResolution ||
        this.modalRemoveResolution ||
        this.modalViewPending
      );
    },

    propsModalConfirmDefault() {
      const justifyStart = true;
      const message =
        (this.listPendencies.length > 1
          ? ""
          : this.listPendencies[0]?.fip_motivo_pendencia) +
        "</br>" +
        "Data da Resolução: " +
        moment().format("DD/MM/YYYY");
      const title = "Remover resolução de pendência";
      const subtitle = "Deseja remover a resolução da Pendência selecionada?";

      return { message, title, subtitle, justifyStart };
    },

    hasPermission() {
      return this.$Permissions.global.has("internacao.filaInternacao");
    },

    getPermissionRegisterPendency() {
      return this.$Permissions.global.has(
        "internacao.filaInternacao.cadastrarPendencia",
      );
    },

    getPermissionRemovePendency() {
      return this.$Permissions.global.has(
        "internacao.filaInternacao.excluirPendencia",
      );
    },

    getPermissionRegisterResolution() {
      return this.$Permissions.global.has(
        "internacao.filaInternacao.resolverPendencia",
      );
    },

    hasPendencies() {
      return (
        this.mutableListPendenciesQueue &&
        this.mutableListPendenciesQueue.length >= 1
      );
    },

    hasResolution() {
      const resolution = this.listPendencies.every((item) => {
        return item.fip_resolucao.length > 0;
      });
      return this.listPendencies.length > 0 && resolution;
    },

    hasPendingText() {
      return this.form.pendency && this.form.pendency.length >= 5;
    },

    enableButtons() {
      return this.listPendencies && this.listPendencies.length > 0;
    },

    hasOnePendencySelected() {
      return this.listPendencies.length !== 1;
    },

    equalListSize() {
      return (
        this.listPendencies.length === this.mutableListPendenciesQueue.length
      );
    },
  },

  created() {
    this.CLIENT = this.$store.getters["Login/GET_USER_ID_CLIENT"];
  },

  destroyed() {
    this.$store.commit(
      "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
    );
  },

  async mounted() {
    const patient = await this.$store.getters[
      "Hospitalization/Queue/GET_SELECTED_EXTERNAL_HOSPITALIZATION_RESULT"
    ];
    this.patientInfo = patient[0];
    this.searchFilter(this.patientInfo);
  },

  methods: {
    async searchFilter(pData) {
      this.mutableListPendenciesQueue = await this.$store.dispatch(
        "Hospitalization/Queue/SEARCH_PENDENCY_QUEUE",
        { intIdFila: pData.fil_id },
      );
    },

    async SearchPendencyQueue(pData) {
      this.mutableListPendenciesQueue = await this.$store.dispatch(
        "Hospitalization/Queue/SEARCH_PENDENCY_QUEUE",
        { intIdFila: pData.fil_id },
      );
    },

    selectLine(pItem) {
      if (pItem.selected) {
        pItem.selected = false;
        this.listPendencies = this.listPendencies.filter((pendency) => {
          return pendency.fip_id !== pItem.fip_id;
        });
      } else {
        this.listPendencies.push(pItem);
        pItem.selected = true;
      }
      this.$forceUpdate();
    },

    selectAllList() {
      this.mutableListPendenciesQueue.forEach((item) => {
        if (!item.selected) {
          item.selected = true;
          this.listPendencies.push(item);
        }
      });
    },

    unselectAllList() {
      this.mutableListPendenciesQueue.forEach((item) => {
        if (item.selected === true) {
          this.listPendencies.splice(this.listPendencies.indexOf(item), 1);
          item.selected = false;
        }
      });
    },

    reverseListSelection() {
      this.mutableListPendenciesQueue.forEach((item, index) => {
        if (item.selected === true) {
          this.listPendencies.splice(this.listPendencies.indexOf(item), 1);
          item.selected = false;
        } else {
          item.selected = true;
          this.listPendencies.push(item);
        }
      });
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async registerPendency() {
      if (!(await this.isFormValid())) {
        return AlertError("Verifique os Campos");
      } else {
        if (this.form.pendency && this.form.pendency.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo pendência",
          );
          return;
        }

        const variables = {
          fip_id_filas: this.patientInfo.fil_id,
          fip_motivo_pendencia: this.form.pendency,
          objModulo: this.$store.state.Login.route_module_map.hospitalization,
        };

        try {
          if (this.getPermissionRegisterPendency) {
            this.$loader.start();
            await this.$store.dispatch(
              "Hospitalization/Queue/SAVE_PENDENCY_QUEUE",
              variables,
            );

            this.$toaster.success("Pendência cadastrada com sucesso");
            this.cleanPendency();
            this.searchFilter(this.patientInfo);
          }
        } catch (error) {
          this.$toaster.error("Erro ao salvar pendência");
        } finally {
          this.$loader.finish();
        }
      }
    },

    async printerPendency() {
      try {
        if (this.hasPendencies) {
          const aux1 = this.patientInfo;
          const aux2 = this.listPendencies;
          const html = ScheduleQueuePendenciesHTML(aux1, aux2);
          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error("Erro ao gerar a impressão das pendências");
      }
    },

    async removePendency(pInfo) {
      try {
        if (this.getPermissionRemovePendency) {
          this.$loader.start();
          await this.$store.dispatch(
            "Hospitalization/Queue/REMOVE_PENDENCY_QUEUE",
            {
              intIdFilaPendencia: this.listPendencies[0].fip_id,
              intIdModulo: this.$store.state.Login.route_module_map
                .hospitalization,
            },
          );
          this.$toaster.success("Pendência removida com sucesso");
          this.setLine();
          this.mutableListPendenciesQueue = [];
          this.searchFilter(this.patientInfo);
        }
      } catch (error) {
        this.$toaster.error("Erro ao excluir pendência");
      } finally {
        this.modalRemovePendency = false;
        this.$loader.finish();
      }
    },

    async registerResolution(pInfo) {
      const textResolution = this.$refs.registerResolution.text;

      if (!(await this.isFormValid())) {
        return AlertError("Verifique os Campos");
      } else {
        if (textResolution.length < 5) {
          this.$toaster.warning("Preencha no mínimo 5 caracteres");
          return;
        }

        const pendenciesId = [];

        this.listPendencies.forEach((item) => {
          pendenciesId.push(item.fip_id);
        });

        try {
          if (this.getPermissionRegisterResolution) {
            this.$loader.start();

            await this.$store.dispatch(
              "Hospitalization/Queue/SAVE_RESOLUTION_QUEUE",
              {
                arrPendencias: pendenciesId,
                strResolution: textResolution,
                pIdModulo: this.$store.state.Login.route_module_map
                  .hospitalization,
              },
            );

            this.$toaster.success("Resolução cadastrada com sucesso");
            this.cleanResolution();
            this.setLine();
            this.modalRegisterResolution = false;
            this.searchFilter(this.patientInfo);
          }
        } catch (error) {
          this.$toaster.error(error);
        } finally {
          this.modalRegisterResolution = false;
          this.$loader.finish();
        }
      }
    },

    async removeResolution(pInfo) {
      try {
        this.$loader.start();

        await this.$store.dispatch(
          "Hospitalization/Queue/REMOVE_RESOLUTION_QUEUE",
          {
            intIdFilaPendencia: this.listPendencies[0].fip_id,
            intIdModulo: this.$store.state.Login.route_module_map
              .hospitalization,
          },
        );

        this.$toaster.success("Resolução removida com sucesso");
        this.setLine();
        this.searchFilter(this.patientInfo);
      } catch (error) {
        this.$toaster.error("Erro ao excluir resolução de pendência");
      } finally {
        this.modalRemoveResolution = false;
        this.$loader.finish();
      }
    },

    openModalRemovePendency() {
      this.modalRemovePendency = true;
    },

    closeModalRemovePendency() {
      this.modalRemovePendency = false;
    },

    openModalRegisterResolution() {
      this.modalRegisterResolution = true;
      this.$refs.textArea.cleanValidate();
    },

    closeModalRegisterResolution() {
      this.modalRegisterResolution = false;
    },

    openModalRemoveResolution() {
      this.modalRemoveResolution = true;
    },

    closeModalRemoveResolution() {
      this.modalRemoveResolution = false;
    },

    openModalViewPending(pPatient, pInfo) {
      this.modalViewPending = true;
      this.patient = pPatient;
      this.info = pInfo;
    },

    closeModalViewPending() {
      this.modalViewPending = false;
    },

    cleanPendency() {
      this.form.pendency = null;
      this.$refs.textArea.resetCharacter();
    },

    cleanResolution() {
      this.$refs.registerResolution.text = null;
      this.$refs.textArea.resetCharacter();
    },

    setLine() {
      this.listPendencies = [];
    },

    comeBackPage() {
      this.$router.go(-1);
    },
  },
};
</script>
