<template>
  <Modulebox
    :granted="hasPermission"
    title="Agendar Externamente"
    class="hospitalization-regulation-form-external-schedule"
  >
    <QuickHospitalizationExternalScheduleRegulationForm
      ref="form"
      class="hospitalization-regulation-form-external-schedule-form"
    />

    <div class="footer">
      <RgCancelButton ref="cancelButton" medium class="save" @click="cancel" />

      <RgSaveButton
        ref="saveButton"
        large
        class="save"
        type="button"
        @click="saveForm"
      />
    </div>
  </Modulebox>
</template>

<script>
import QuickHospitalizationExternalScheduleRegulationForm from "$hospitalization/hospitalizations/component/quick-hospitalization-external-schedule-regulation-form/QuickHospitalizationExternalScheduleRegulationForm";
import { RgSaveButton, RgCancelButton } from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";

export default {
  name: "HospitalizationRegulationFormExternalSchedule",
  components: {
    RgSaveButton,
    Modulebox,
    QuickHospitalizationExternalScheduleRegulationForm,
    RgCancelButton,
  },

  data() {
    return {};
  },

  computed: {
    hasPermission() {
      return this.$Permissions.global.has(
        "internacao.filaInternacao.agendarExternamente",
      );
    },
  },

  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async saveForm() {
      try {
        this.$refs.saveButton.actionSubmit();
        await this.$refs.form.saveForm();
        this.$refs.saveButton.actionDone();
      } catch (Err) {
        this.$refs.saveButton.fail();
      }
    },
  },
};
</script>
