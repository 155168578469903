import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPatientsAppointmentSentPepByEffectedReport(
    $unitHealth: Int
    $unitHealthName: String
    $employee: Int
    $employeeName: String
    $sector: Int
    $sectorName: String
    $patient: Int
    $patientName: String
    $schedulingPeriodDateStart: String
    $schedulingPeriodDateEnd: String
    $occupation: Int
    $occupationName: String
    $sentPep: Boolean
    $effected: Boolean
    $smd_id: Int
    $columnsToPrint: [ColumnsToPrintInputType]
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchPatientsAppointmentSentPepByEffectedReport(
      unitHealth: $unitHealth
      unitHealthName: $unitHealthName
      employee: $employee
      employeeName: $employeeName
      sector: $sector
      sectorName: $sectorName
      patient: $patient
      patientName: $patientName
      schedulingPeriodDateStart: $schedulingPeriodDateStart
      schedulingPeriodDateEnd: $schedulingPeriodDateEnd
      occupation: $occupation
      occupationName: $occupationName
      sentPep: $sentPep
      effected: $effected
      smd_id: $smd_id
      columnsToPrint: $columnsToPrint
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        UNIDADE_SAUDE
        SETOR
        OCUPACAO
        PROFISSIONAL_DE_SAUDE
        PACIENTE
        DATA_AGENDAMENTO
        DATA_EFETIVACAO
        SITUACAO_PEP
        SITUACAO_EFETIVACAO
        DATA_ENVIO_PEP
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPatientsAppointmentSentPepByEffectedReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
