<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListPlaces from "./action/ListPlaces";
import { isNumber } from "lodash";

export default {
  name: "RgSelectPlaces",
  extends: RgSelect,
  props: {
    label: {
      default: "Local de Atendimento",
    },
    sector: {
      default: false,
      required: true,
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      places: [],
    };
  },
  computed: {
    filter() {
      if (!isNumber(this.sector)) {
        this.resetCombobox();
        return null;
      } else {
        return {
          set_id: this.sector,
        };
      }
    },
  },

  watch: {
    places: function (val) {
      this.updateOptions();
    },

    sector() {
      this.mValue = null;
      this.doSearch();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.places;
    },

    async doSearch() {
      if (this.sector) {
        const sectorId = this.sector;
        const data = await ListPlaces({ sectorId });
        this.places = data.map((pData) => {
          return {
            value: pData.lca_id,
            label: pData.lca_nome,
          };
        });
      }
    },

    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
