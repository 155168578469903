<style src="./RgInputContact.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase>
      <div class="rg-input--base">
        <form @submit.prevent="addNewContact">
          <div
            :class="{ 'on-error': error.length > 0 }"
            class="rg-input--textbox-container"
          >
            <div class="rg-input--contact-value">
              <input
                v-model="contactValue"
                v-mask="['(##) ####-####', '(##) #####-####']"
                type="text"
                class="rg-input--textbox__no-border textbox__border-right"
                placeholder="(99) 28888-9999"
              />
              <input
                v-model="contactMessage"
                type="text"
                class="rg-input--textbox__no-border"
                placeholder="Recado"
              />
            </div>

            <div class="rg-input--contact-command">
              <button v-if="!showWait" class="rg-input--button-add">
                <IconCircleAdd />
              </button>

              <div v-show="showWait" class="rg-input--wait">
                <div class="rg-input--wait-icon" />
              </div>

              <div
                v-show="error.length > 0"
                class="rg-input--alert-over-positioning"
              >
                <RgValidationAlert :alert="error" class="rg-input--icon" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
// import {RgValidatorMixin, RgValidationAlert} from '~tokio/component/validation'
import {
  RgInputMixin,
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive";
import { IconCircleAdd } from "~tokio/primitive/icon";
// import ContactTypeModel from 'app/person/model/ContactTypeModel'
// import ContactModel from 'app/person/model/ContactModel'
import { mask } from "vue-the-mask";

export default {
  name: "RgInputContact",
  components: {
    RgValidationAlert,
    RgFormBase,
    IconCircleAdd,
  },
  directives: { mask },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    pesId: {
      default: "",
    },
  },
  data() {
    return {
      showWait: false,
      contactValue: "",
      contactMessage: "",
      contactList: [],
      anotherRules: {
        checkRule: (pValue, pError) => {
          const isOk =
            this.contactValue.length > 13 && this.contactValue.length < 16;
          if (!isOk) {
            this.error.push("Contato inválido");
            pError.push("Contato inválido");
          }
          this.showWait = false;
          return isOk;
        },
      },
    };
  },
  watch: {
    contactValue(pVal) {
      this.error = [];
    },
  },
  methods: {
    addNewContact() {
      this.showWait = true;
      try {
        if (this.contactValue.substring(1, 3) === "00") {
          throw new Error("DDD 00 Inválido");
        }
        this.validate();
        if (this.error.length > 0) return false;
        const phone = {
          contactValue: this.contactValue,
          contactMessage: this.contactMessage,
        };
        this.$store
          .dispatch("Patient/ADD_PERSON_PHONE", phone)
          .then(() => {
            this.$toaster.success("Telefone Cadastrado com Sucesso");
          })
          .catch((Err) => {
            const pErr = Err.toString();
            if (pErr.includes("ddd_id")) {
              this.$toaster.error('O "DDD" informado não existe');
            } else {
              this.$toaster.error("Erro ao Cadastrar o Telefone");
            }
          });
      } catch (Err) {
        this.$toaster.error(Err);
      }
      this.showWait = false;
      this.contactValue = "";
      this.contactMessage = "";
    },
    hideError() {
      this.error = [];
    },
  },
};
</script>
