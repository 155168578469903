<template>
  <div v-show="show" class="modal-create-bpac">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <section slot="header" class="modal-create-bpac-header">
        <div class="title">{{ modalTitle }}</div>
      </section>

      <section slot="body" class="modal-create-bpac-body">
        <span class="information"> Preencha os dados necessários </span>

        <FormBase title="Dados do Registro" class="billing-form">
          <div class="top-button">
            <div class="tooltip-info">
              <Tooltip class="tooltip" :message="mouseoverMsg" startOpen>
                <IconLightBulbHelper slot="icon" hollow />
              </Tooltip>
            </div>
          </div>

          <div class="billing-fields">
            <RgValidatorForm ref="validator" @submit.stop.prevent="newLine">
              <section class="fields">
                <RgSelectCompetence
                  id="competence"
                  ref="competence"
                  v-model="form.competence"
                  hideWarning
                  :rules="{ required: true }"
                  :disabled="
                    edition ||
                    isEdition ||
                    duplicateBpaList ||
                    duplicateBpaSearch
                  "
                  :class="{
                    disable:
                      edition ||
                      isEdition ||
                      duplicateBpaList ||
                      duplicateBpaSearch,
                  }"
                  isRegister
                />
                <RgSelectSheet
                  id="newSheet"
                  ref="newSheet"
                  v-model="form.sheet"
                  :onlyAvailable="!isEdition || !edition"
                  :editModal="isEdition || edition"
                  :editValue="mValue"
                  :period="form.competence"
                  :disabled="form.sheet ? true : false"
                  :class="{ disable: true }"
                  :rules="{ required: true }"
                  @newSheet="newSheet"
                />

                <RgSuggestProcedure
                  id="procedure"
                  ref="procedure"
                  v-model="form.procedure"
                  :period="competence"
                  label="Procedimento"
                  :rules="{ required: true, forceSelection: true }"
                  :disabled="!form.sheet"
                  :type="TYPE_PROCEDURE"
                  :isBilling="true"
                  :class="{ disable: !form.sheet }"
                  fieldCaption="CO_PROCEDIMENTO - NO_PROCEDIMENTO"
                  placeholder="Digite o cód. ou nome do procedimento"
                  @selected="selectedProcedure"
                />

                <RgSuggestCboProcedure
                  id="cbo"
                  ref="cbo"
                  v-model="form.cbo"
                  :rules="{ required: true, forceSelection: true }"
                  :procedureCode="form.procedureCode"
                  :sigtap="true"
                  :isBilling="true"
                  :disabled="!form.procedure"
                  :class="{ disable: !form.procedure }"
                  label="CBO/Ocupação"
                  placeholder="Digite o CBO ou a ocupação"
                  @selected="selectedCbo"
                />

                <RgInputMasked
                  id="age"
                  v-model="form.age"
                  :disabled="!form.procedure || !hasAge"
                  :class="{ disable: !form.procedure || !hasAge }"
                  :rules="{
                    required: hasAge,
                    fn: minValueAge,
                  }"
                  placeholder="000"
                  :maxlength="3"
                  label="Idade"
                  mask="###"
                  class="inputitem"
                />

                <RgInputMasked
                  id="amount"
                  v-model="form.amount"
                  :disabled="!form.procedure"
                  :class="{ disable: !form.procedure }"
                  :rules="{
                    required: true,
                    fn: minValueAmount,
                    number: true,
                  }"
                  label="Quantidade"
                  placeholder="000000"
                  mask="######"
                  :maxlength="6"
                />

                <div class="buttons">
                  <RgCleanButton
                    id="clean-button"
                    class="btns"
                    @click="cleanForm"
                  />
                  <RgSaveButton
                    v-show="isEdition"
                    id="save-edit"
                    ref="save"
                    class="btns"
                    small
                    :disabled="!form.procedureCode && !form.cboCode"
                    @click="saveEditLine"
                  />
                  <RgAddButton
                    v-show="!isEdition"
                    id="new-button"
                    class="btns"
                    :disabled="!form.procedureCode && !form.cboCode"
                    @click="newLine"
                  />
                </div>
              </section>
            </RgValidatorForm>
          </div>

          <section class="billing-list">
            <hr class="hr" />
            <SmartTable
              ref="smartTable"
              name="ModalPatientCareHistory"
              :columns="COLUMN"
              :body="mutableBillingList"
              :initial-columns="4"
              :total="mutableBillingList.length"
              toggle-selected
              class="smart-table-create-bpac"
              :show-pagination="false"
              removeBtnColumns
              @getLine="selectLine"
            >
              <div slot="top-buttons" class="top-buttons">
                <RgEditButton
                  id="edit-button"
                  class="btns"
                  :class="{ disable: !hasSelectedLine }"
                  :disabled="!hasSelectedLine"
                  title="Editar Registro"
                  @click="editLine"
                />
                <RgLessButton
                  id="remove-button"
                  class="btns"
                  :class="{ disable: !hasSelectedLine || isEdition }"
                  :disabled="!hasSelectedLine || isEdition"
                  title="Excluir Registro"
                  @click="removeLine"
                />
                <SmallButton
                  id="duplicate-button"
                  class="btns"
                  :class="{ disable: !hasSelectedLine || isEdition }"
                  :disabled="!hasSelectedLine || isEdition"
                  title="Duplicar Registro"
                  :backgroundColor="'#1E88A9'"
                  @click="duplicateLine"
                >
                  <IconDuplicateSheet slot="icon" />
                </SmallButton>
              </div>
            </SmartTable>
          </section>
        </FormBase>
      </section>

      <section slot="footer" class="footer">
        <MediumButton id="cancel-btn" label="Concluir" medium @click="close" />
      </section>
    </RgBaseModal>
  </div>
</template>

<script>
import { FormBase, SmartTable } from "~tokio/foundation";
import { mapGetters } from "vuex";
import {
  RgBaseModal,
  IconLightBulbHelper,
  RgLessButton,
  RgEditButton,
  RgAddButton,
  SmallButton,
  IconDuplicateSheet,
  RgCleanButton,
  RgValidatorForm,
  MediumButton,
  RgSuggestProcedure,
  RgSaveButton,
  RgInputMasked,
} from "~tokio/primitive";
import RgSelectSheet from "$billing/submodules/bpa/bpa-c/components/select/rg-select-sheet/RgSelectSheet";
import Tooltip from "~tokio/primitive/tooltip/tooltip/Tooltip";

import RgSelectCompetence from "$billing/common/components/select/rg-select-competence/RgSelectCompetence";
import RgSuggestCboProcedure from "$hospitalization/common/component/suggest/rg-suggest-cbo-procedure/RgSuggestCboProcedure";

const FORM = {
  competence: null,
  sheet: "1",
  procedure: null,
  cbo: null,
  age: null,
  amount: null,
  procedureName: null,
  procedureCode: null,
  cboCode: null,
  cnes: null,
  uns_id: null,
  faturado: true,
};

export default {
  name: "ModalCreateBpaC",
  components: {
    RgBaseModal,
    FormBase,
    SmartTable,
    Tooltip,
    IconLightBulbHelper,
    RgLessButton,
    RgEditButton,
    RgAddButton,
    SmallButton,
    IconDuplicateSheet,
    RgCleanButton,
    RgValidatorForm,
    MediumButton,
    RgSelectSheet,
    RgSuggestProcedure,
    RgSuggestCboProcedure,
    RgSelectCompetence,
    RgSaveButton,
    RgInputMasked,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    edition: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    selectedData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      mutableBillingList: [],
      form: this.$utils.obj.DeepCopy(FORM),
      competence: null,
      selectedIndex: null,
      selectedLine: [],
      maxAge: null,
      minAge: null,
      ageRequired: null,
      editByList: false,
      editByModal: false,
      duplicateBpaSearch: false,
      duplicateBpaList: false,
      mValue: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),
    mouseoverMsg() {
      return this.isEdition
        ? "Para alterar os dados de um registro, basta selecionar o campo correspondente e clicar em salvar"
        : "Para alterar ou excluir os dados de um registro existente, basta selecionar a linha correspondente na lista abaixo e clicar em Editar ou Excluir";
    },
    modalTitle() {
      return this.isEdition
        ? "Editar Registro BPA-C"
        : this.duplicateBpaList || this.duplicateBpaSearch
        ? "Duplicar Registro BPA-C"
        : "Adicionar Novo Registro BPA-C";
    },
    hasSelectedLine() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },
    hasAge() {
      return this.ageRequired === 1;
    },
    isEdition() {
      return this.editByList || this.editByModal;
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.editByList = this.edition;

        if (this.edition || this.duplicate) {
          this.editSheetData(this.selectedData);
        } else {
          this.createSheetData();
        }
      }
    },
    "form.competence"(pValue) {
      if (!pValue) {
        this.form = this.$utils.obj.DeepCopy(FORM);
      }
    },
    "form.procedure"(pValue) {
      if (!pValue) {
        this.form.cbo = null;
        this.form.age = null;
        this.form.amount = null;
      }
    },
    "form.amount"(pValue) {
      if (pValue) {
        this.form.amount = this.form.amount?.replace(/^0+/, "");
      }
    },
  },
  created() {
    this.COLUMN = [
      {
        name: "Competência",
        key: "competence",
        align: "right",
        active: "true",
      },
      { name: "Folha", key: "sheet", align: "right", active: "true" },

      {
        name: "Procedimento",
        key: "procedureName",
        align: "left",
        active: "true",
      },
      { name: "CBO/Ocupação", key: "cbo", align: "left", active: "true" },
      { name: "Idade", key: "age", align: "right", active: "true" },
      { name: "Quantidade", key: "amount", align: "right", active: "true" },
    ];

    this.TYPE_PROCEDURE = "('01')";
  },
  methods: {
    newSheet(pValue) {
      this.$toaster.info(
        "Uma nova folha será criada com a adição do próximo registro.",
        "O Limite de Registros por Folha foi Atingido",
      );

      this.form.sheet = pValue.fco_folha ? pValue.fco_folha.toString() : null;
      this.mValue = pValue.fco_folha ? pValue.fco_folha.toString() : null;
    },
    async editSheetData(pValue) {
      try {
        this.$loader.start();
        if (pValue.fco_competencia) {
          if (pValue.fco_competencia.length === 6) {
            this.form.competence = pValue.fco_competencia;
          }

          if (pValue.competence) {
            this.form.competence = pValue.competence;
          } else {
            if (pValue.fco_competencia.length === 7) {
              const periodMonth = pValue.fco_competencia.substring(0, 2);
              const periodYear = pValue.fco_competencia.substring(3, 7);
              this.form.competence = `${periodYear}${periodMonth}`;
            }
          }
        } else {
          this.form.competence = this.$store.getters[
            "Billing/BDSia/GET_PERIOD_DATE"
          ];
        }

        this.form.cnes = this.currentUnitHealth.uns_cnes;
        this.form.uns_id = this.currentUnitHealth.uns_id;

        await this.$refs.newSheet.getSheets();

        this.form.sheet = pValue.fco_folha;
        this.mValue = pValue.fco_folha;

        this.form.procedure = pValue.procedure.CO_PROCEDIMENTO;

        this.$refs.procedure.forceSelection({
          CO_PROCEDIMENTO: pValue.procedure.CO_PROCEDIMENTO,
          NO_PROCEDIMENTO: pValue.procedure.NO_PROCEDIMENTO,
          QT_MAXIMA_EXECUCAO: pValue.procedure.QT_MAXIMA_EXECUCAO,
          VL_IDADE_MAXIMA: pValue.procedure.VL_IDADE_MAXIMA,
          VL_IDADE_MINIMA: pValue.procedure.VL_IDADE_MINIMA,
        });

        this.ageRequired = Number(pValue.fco_idade_requerida);
        this.minAge = pValue.procedure.VL_IDADE_MINIMA;
        this.maxAge = pValue.procedure.VL_IDADE_MAXIMA;

        this.$refs.cbo.forceSelection({
          ocp_codigo: pValue.occupation.CO_OCUPACAO,
          ocp_nome: pValue.occupation.NO_OCUPACAO,
        });

        this.form.age = pValue.fco_idade;
        this.form.amount = pValue.fco_quantidade;

        if (pValue.fco_idade === null && pValue.fco_quantidade === null)
          this.duplicateBpaSearch = true;
      } catch (e) {
        this.$toaster.error("Erro ao carregar dados");
      } finally {
        this.$loader.finish();
      }
    },
    async createSheetData() {
      this.form.cnes = this.currentUnitHealth.uns_cnes;
      this.form.uns_id = this.currentUnitHealth.uns_id;
      this.form.competence = this.selectedData.competence;
      this.form.faturado = true;
      await this.$refs.newSheet.getSheets();
    },

    minValueAge(pValue, pErrors) {
      const regexFormatAge = /^(?!0\d)\d{1,3}$/;
      const testFormatAge = regexFormatAge.test(pValue);

      if (pValue && pValue.length > 0 && !testFormatAge) {
        return false;
      }

      if (this.hasAge) {
        const minAge = Math.floor(
          Number(this.minAge || this.selectedLine.minAge) / 12,
        );
        const maxAge = Math.floor(
          Number(this.maxAge || this.selectedLine.maxAge) / 12,
        );
        if (
          (Number(pValue) < minAge && Number(minAge) > 0) ||
          Number(pValue) > Number(maxAge) ||
          !pValue
        ) {
          pErrors.push(`Digite uma idade entre ${minAge} e ${maxAge}`);
          return false;
        }
      }
      return true;
    },
    minValueAmount(pValue, pErrors) {
      if (Number(pValue) <= 0) {
        pErrors.push(`Digite uma quantidade maior que zero`);
        return false;
      }
      return true;
    },
    selectedProcedure(pValue) {
      if (pValue) {
        this.form.procedureName = pValue.source.NO_PROCEDIMENTO;
        this.form.procedureCode = pValue.source.CO_PROCEDIMENTO;
        this.maxAge = pValue.source.VL_IDADE_MAXIMA;
        this.minAge = pValue.source.VL_IDADE_MINIMA;
        this.ageRequired = pValue.source.age_required;
      } else {
        this.form.procedureName = null;
        this.form.procedureCode = null;
        this.maxAge = null;
        this.minAge = null;
      }
    },
    selectedCbo(pValue) {
      if (pValue) {
        this.form.cboCode = pValue.source.ocp_codigo;
      } else {
        this.form.cboCode = null;
      }
    },
    async mutableBillingListTable(newLine = false, data) {
      this.competenceMonth = this.form.competence.substring(4);
      this.competenceYear = this.form.competence.substring(0, 4);
      const competence = `${this.competenceMonth}/${this.competenceYear}`;
      if (this.editByList || newLine) {
        this.mutableBillingList.push({
          fco_id: data.SaveConsolidatedBilling.fco_id,
          competence: competence,
          sheet: this.form.sheet,
          procedure: data.SaveConsolidatedBilling.fco_codigo_procedimento,
          procedureName: data.SaveConsolidatedBilling.procedure.NO_PROCEDIMENTO,
          cbo: this.form.cbo,
          amount: this.form.amount,
          age: data.SaveConsolidatedBilling.fco_idade,
          age_required: this.ageRequired,
          maxAge: this.maxAge,
          minAge: this.minAge,
        });
      } else {
        this.mutableBillingList[this.selectedIndex] = {
          fco_id: data.SaveConsolidatedBilling.fco_id,
          competence: competence,
          sheet: this.form.sheet,
          procedure: data.SaveConsolidatedBilling.fco_codigo_procedimento,
          procedureName: data.SaveConsolidatedBilling.procedure.NO_PROCEDIMENTO,
          cbo: this.form.cbo,
          amount: this.form.amount,
          age: data.SaveConsolidatedBilling.fco_idade,
          age_required: this.ageRequired,
          maxAge: this.maxAge,
          minAge: this.minAge,
        };
      }
      await this.cleanForm();

      this.selectedLine = [];
      this.selectedIndex = null;
      this.$refs.smartTable.cleanSelectRow();
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async newLine() {
      const valid = await this.isFormValid();

      if (!valid) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();
        let procedureCode = this.form.procedureCode;
        if (this.form.procedureCode.includes("-")) {
          const term = this.form.procedureCode.split(" - ");
          procedureCode = term[0];
        }

        const formatAge = this.form.age
          ? this.form.age
          : this.form.age === "0"
          ? (this.form.age = "0")
          : null;

        const variables = {
          consolidatedBilling: {
            fco_competencia: this.form.competence,
            fco_codigo_procedimento: procedureCode,
            fco_id_unidades_saude: this.form.uns_id,
            fco_cnes: this.form.cnes,
            fco_quantidade: this.form.amount,
            fco_cbo: this.form.cboCode,
            fco_faturado: true,
            fco_idade: formatAge,
            fco_folha: this.form.sheet,
          },
        };
        const data = await this.$store.dispatch(
          "Billing/BpacBilling/SAVE_CONSOLIDATED_BILLING",
          { variables },
        );

        if (!this.duplicateBpaSearch) {
          this.mutableBillingListTable(!this.duplicateBpaSearch, data);
          await this.$refs.newSheet.getSheets();
        }

        if (this.duplicateBpaList) {
          this.duplicateBpaList = false;
        }
      } catch (pErr) {
        if (pErr.message.includes("Procedimento já faturado na remessa")) {
          this.$toaster.info(
            `Verifique a duplicidade dos dados inseridos: procedimento, CBO e idade.`,
            "O registro já faz parte dessa remessa",
          );
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Não foi possível criar registro BPA-C",
          );
        }
      } finally {
        if (!this.duplicateBpaSearch) {
          this.$loader.finish();
        }
        if (this.duplicateBpaSearch) this.close();
      }
    },
    async saveEditLine() {
      const valid = await this.isFormValid();

      if (!valid) {
        this.$toaster.warning("Verifique os campos");
        this.$refs.save.actionDone();
        return false;
      }
      try {
        this.$loader.start();

        let cboCode = this.form.cboCode;
        if (this.form.cboCode.includes("-")) {
          const term = this.form.cboCode.split(" - ");
          cboCode = term[0];
        }
        let procedureCode = this.form.procedureCode;
        if (this.form.procedureCode.includes("-")) {
          const term = this.form.procedureCode.split(" - ");
          procedureCode = term[0];
        }

        const formatAge = this.form.age
          ? this.form.age
          : this.form.age === "0"
          ? (this.form.age = "0")
          : null;

        const variables = {
          consolidatedBilling: {
            fco_id: this.selectedLine.fco_id || this.selectedData.fco_id,
            fco_competencia: this.form.competence,
            fco_codigo_procedimento: procedureCode,
            fco_id_unidades_saude: this.form.uns_id,
            fco_cnes: this.form.cnes,
            fco_quantidade: this.form.amount,
            fco_cbo: cboCode,
            fco_faturado: true,
            fco_idade: formatAge,
            fco_folha: this.form.sheet,
          },
        };
        const data = await this.$store.dispatch(
          "Billing/BpacBilling/SAVE_CONSOLIDATED_BILLING",
          { variables },
        );

        if (this.editByModal) {
          this.editByList = false;
          this.editByModal = false;
        }

        if (!this.edition) {
          this.mutableBillingListTable(false, data);
          await this.$refs.newSheet.getSheets();
        }
      } catch (pErr) {
        if (pErr.message.includes("Procedimento já faturado na remessa")) {
          this.$toaster.info(
            `Verifique a duplicidade dos dados inseridos: procedimento, CBO e idade.`,
            "O registro já faz parte dessa remessa",
          );
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Não foi possível editar registro BPA-C",
          );
        }
      } finally {
        this.$loader.finish();
        this.$refs.save.actionDone();
        if (this.edition) this.close();
        if (!this.edition) this.$loader.finish();
        this.$refs.save.actionDone();
      }
    },
    async editLine() {
      this.editByModal = true;
      const periodMonth = this.selectedLine.competence.substring(0, 2);
      const periodYear = this.selectedLine.competence.substring(3, 7);
      this.form.competence = `${periodYear}${periodMonth}`;

      await this.$refs.newSheet.getSheets();
      this.form.sheet = this.selectedLine.sheet;

      const procedure = Array.from([this.selectedLine.procedure])[0];
      const procedureName = Array.from([this.selectedLine.procedureName])[0];
      await this.$refs.procedure.forceSelection({
        CO_PROCEDIMENTO: procedure,
        NO_PROCEDIMENTO: procedureName,
      });
      const cbo = Array.from(this.selectedLine.cbo.split("-"));
      await this.$refs.cbo.forceSelection({
        ocp_nome: cbo[1].trim(),
        ocp_codigo: cbo[0].trim(),
      });
      this.form.age = this.selectedLine.age;
      this.form.amount = this.selectedLine.amount;
      this.ageRequired = this.selectedLine.age_required;
    },
    async removeLine() {
      try {
        this.$loader.start();
        await this.$store.dispatch(
          "Billing/BpacBilling/REMOVE_CONSOLIDATED_BILLING",
          { variables: { consolidatedBilling: this.selectedLine.fco_id } },
        );
        this.mutableBillingList.splice(this.selectedIndex, 1);
        this.selectedLine = [];
        this.selectedIndex = null;
        this.$refs.smartTable.cleanSelectRow();
        await this.$refs.newSheet.getSheets();
      } catch (err) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(err),
          "Não foi possível remover registro BPA-C",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async duplicateLine() {
      const periodMonth = this.selectedLine.competence.substring(0, 2);
      const periodYear = this.selectedLine.competence.substring(3, 7);
      this.form.competence = `${periodYear}${periodMonth}`;
      await this.$refs.procedure.forceSelection({
        CO_PROCEDIMENTO: this.selectedLine.procedure.split("-")[0],
        NO_PROCEDIMENTO: this.selectedLine.procedureName,
      });
      const cbo = this.selectedLine.cbo.split("-");
      this.ageRequired = this.selectedLine.age_required;

      await this.$refs.cbo.forceSelection({
        ocp_nome: cbo[1].trim(),
        ocp_codigo: cbo[0].trim(),
      });
      this.duplicateBpaList = true;
    },
    selectLine(pValue, pIndex) {
      if (!pValue) {
        this.cleanForm();
      }
      this.selectedLine = pValue;
      this.selectedIndex = pIndex;
    },
    cleanForm() {
      this.form.competence = this.$store.getters[
        "Billing/BDSia/GET_PERIOD_DATE"
      ];
      this.form.procedure = null;
      this.form.cbo = null;
      this.form.age = null;
      this.form.amount = null;
      this.form.procedureName = null;
      this.form.cboCode = null;
      this.selectedLine = [];
      this.selectedIndex = null;
      this.duplicateBpaList = false;
      this.editByList = false;
      this.editByModal = false;
      this.$refs.smartTable.cleanSelectRow();
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "competence",
        "newSheet",
        "procedure",
        "cbo",
        "age",
        "amount",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    close() {
      this.$emit("close");
      this.cleanForm();
      this.editByList = false;
      this.editByModal = false;
      this.mutableBillingList = [];
      this.selectedLine = [];
      this.duplicateBpaSearch = false;
      this.duplicateBpaList = false;
      this.selectedIndex = null;
      this.$refs.smartTable.cleanSelectRow();
      this.cleanFieldsValidation();
    },
  },
};
</script>
