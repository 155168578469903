import { isEmpty } from "lodash";
import moment from "moment";
import { AlertError } from "~tokio/primitive/notification";
import EXAM_STATUS_LABORATORY from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status-laboratory/GetExamStatusLaboratory";
import { mapGetters } from "vuex";

const ValidationLaboratoryDetailsMixin = {
  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    requiredObjectAnalisis() {
      return this.suggestProcedure !== "" && this.objectExam.length > 0;
    },

    requiredMaterial() {
      return this.suggestProcedure !== "";
    },

    isBrazillian() {
      return (
        (this.examPatientData.nationality === "BRASIL" ||
          this.examPatientData.nationality === "NÃO INFORMADO" ||
          this.examPatientData.nationality === "NAO INFORMADO" ||
          !this.examPatientData.nationality) &&
        this.isSelectedNationality
      );
    },

    disabledRemove() {
      return (
        this.protocolNumber &&
        this.protocolNumber.length > 0 &&
        this.procedureList.length === 1
      );
    },

    isSolicitation() {
      return this.currentStatus === "Solicitado";
    },

    blockScreen() {
      return (
        this.currentStatus === "Agendado" ||
        this.currentStatus === "Parcialmente Atendido" ||
        this.currentStatus === "Atendido"
      );
    },

    canPrint() {
      return this.currentStatus !== "Atendido";
    },

    hasPersonId() {
      return this.form.pes_id && this.form.pes_id > 0;
    },

    hasPermission() {
      return true;
    },

    validateExam() {
      return !!(this.form.pes_id && this.employeeRequest.id);
    },

    showObjectExam() {
      return this.objectExam.length === 0 || this.objectExam.length === 157;
    },

    hasProcedureList() {
      return this.procedureList.length >= 1;
    },

    isMenstruationRadioTrue() {
      return this.patientInfo.menstruada === "S";
    },

    isWoman() {
      return this.examPatientData.gender === "F";
    },

    hasUrl() {
      return !isEmpty(this.urlExam);
    },

    getUrl() {
      const URL_BASE_REPORT = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_REPORT_URL}`;
      return URL_BASE_REPORT + this.urlExam;
    },

    titleModalEditPerson() {
      return this.form.pes_id ? "Editar Paciente" : "Cadastrar Paciente";
    },

    disabledRadioGender() {
      return !this.isWoman || this.disabledInfoMenstruation;
    },

    disableProcedureSuggest() {
      return !this.validateExam;
    },

    disableTextAreaMenstruation() {
      return (
        !this.form.pes_id ||
        !this.isWoman ||
        this.disabledInfoMenstruation ||
        this.isMenstruationRadioTrue
      );
    },

    disableAddProcedure() {
      return !this.hasProcedure || this.buttons.disableButtons;
    },

    disableButtonSolicitation() {
      return !this.hasProcedureList || !this.isEmployeeSelected;
    },

    disableCleanButton() {
      return !this.hasProcedure;
    },

    showButtonAgendation() {
      return this.disableDateSchedule || this.form.leb_hora_agendamento;
    },

    disableButtonPending() {
      return (
        !this.isEmployeeSelected ||
        !this.hasProcedureList ||
        !this.disableDateSchedule ||
        !this.form.leb_hora_agendamento
      );
    },

    showAddButton() {
      return (
        (this.buttons.solicitation || this.buttons.agendation) &&
        this.setTitle !== "Novo Pedido" &&
        !this.buttons.attended &&
        !this.buttons.partiallyAttended
      );
    },

    hasResult() {
      return (
        this.examData &&
        (this.examData.leb_id_laboratoriais_status_pedido ===
          EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO ||
          this.examData.leb_id_laboratoriais_status_pedido ===
            EXAM_STATUS_LABORATORY.ATENDIDO)
      );
    },
  },

  watch: {
    protocolNumber(pValue) {
      this.disableInputsInRequestData = pValue && pValue.length > 0;
    },

    "form.leb_data_agendamento"(pValue) {
      this.disableDateSchedule = pValue && pValue.length === 10;
    },

    "form.leb_hora_agendamento"(pValue) {
      this.disableHourSchedule = !!(
        pValue &&
        pValue.length === 5 &&
        parseInt(pValue.substring(0, 2)) < 25 &&
        parseInt(pValue.substring(3)) < 60
      );
    },
  },

  methods: {
    sanitizeNumber(pValue) {
      return !pValue
        ? ""
        : pValue.replace(",", ".").replace(/[ a-zA-Z á]/g, "");
    },

    canAddMultiplo() {
      const auxObject = {
        nome: this.suggestProcedure,
        codigo: this.form.exe_codigo_procedimento,
        codigoExameMne: this.exams.codigoExameMne,
        material: this.exams.collectMaterial,
        lob_nome_objetos: !isEmpty(this.exams.objectAnalisis)
          ? this.exams.objectAnalisis.lob_nome_objetos
          : null,
      };

      const getProcedureObjectAnalisys = (pProcedure) => {
        return !isEmpty(pProcedure.objectAnalisis)
          ? pProcedure.objectAnalisis.lob_nome_objetos
          : "";
      };

      const multipleCheck = (pProcedure) => {
        return (
          pProcedure.nome === auxObject.nome &&
          pProcedure.collectMaterial === auxObject.material &&
          getProcedureObjectAnalisys(pProcedure) === auxObject.lob_nome_objetos
        );
      };

      const singleCheck = (pProcedure) => {
        return (
          pProcedure.nome === auxObject.nome &&
          getProcedureObjectAnalisys(pProcedure) === auxObject.lob_nome_objetos
        );
      };

      const existsEqual = this.procedureList.some(
        this.obligatoriness.multiplo === "S" ? multipleCheck : singleCheck,
      );
      return !existsEqual;
    },

    hasMultiplo(examRules = "N", procedure) {
      let count = 0;

      this.procedureList.forEach((item) => {
        const singleCheck =
          item.nome === procedure.nome &&
          (item.objectAnalisis === procedure.objectAnalisis ||
            item.objectAnalisis.lob_nome_objetos ===
              procedure.objectAnalisis.lob_nome_objetos);

        const multipleCheck =
          singleCheck && item.collectMaterial === procedure.material;

        if (examRules === "N") {
          if (singleCheck) {
            count++;
          }
        } else {
          if (multipleCheck) {
            count++;
          }
        }
      });

      return count <= 1;
    },

    verifyRetroactiveDate(pValue, pErrors) {
      const convertSolicitation = this.$utils.date.BrazilianDateToDatabase(
        this.form.leb_data_solicitacao,
      );
      const convertDate = this.$utils.date.BrazilianDateToDatabase(pValue);

      return !pValue || moment(convertDate).isSameOrAfter(convertSolicitation)
        ? true
        : AlertError(
            "A data de agendamento não pode ser retroativa à data de solicitação.",
            pErrors,
          );
    },

    validateAge() {
      const minAge = (this.procedure.vl_idade_minima / 12).toFixed(1);
      const maxAge = (this.procedure.vl_idade_maxima / 12).toFixed(1);

      const patienteBirth = moment(
        this.examPatientData.birthday,
        "DD/MM/YYYY",
      ).format("MM-DD-YYYY");

      const ageDifference = moment().diff(patienteBirth);
      const diffDuration = moment.duration(ageDifference);
      const age = diffDuration.years() + "." + diffDuration.months();

      return (
        parseFloat(age) >= parseFloat(minAge) &&
        parseFloat(age) <= parseFloat(maxAge)
      );
    },

    validateColorRace(examRules = "N") {
      const race = this.examPatientData?.colorAndRace;
      if (
        (this.obligatoriness.cor === "S" || examRules === "S") &&
        (!race ||
          race === "" ||
          race === "NÃO INFORMADO" ||
          race === "SEM INFORMAÇÃO")
      ) {
        this.validate.colorAndRace = false;
        return false;
      }
      this.validate.colorAndRace = true;
      return true;
    },

    validateHeightAndWeight(examRulesWeight = "N", examRulesHeight = "N") {
      const weight = this.patientWeight
        ? this.patientWeight.replace(",", ".")
        : null;
      const height = this.patientHeight
        ? this.patientHeight.replace(",", ".")
        : null;

      const invalidWeight = !weight || weight < 0.5 || weight > 500;
      const invalidHeight = !height || height < 0.2 || height > 2.5;

      if (
        (this.obligatoriness.peso === "S" ||
          examRulesWeight === "S" ||
          this.obligatoriness.altura === "S" ||
          examRulesHeight === "S") &&
        (invalidWeight || invalidHeight)
      ) {
        this.validate.heightAndWeight = false;
        return false;
      }

      this.validate.heightAndWeight = true;
      return true;
    },

    weightIsValid(pValue, pErrors) {
      pValue = pValue ? pValue.replace(",", ".") : "";

      if (!pValue || (pValue >= 0.5 && pValue <= 500)) {
        return true;
      } else {
        const errorMessage =
          pValue > 500
            ? "O valor máximo para o peso é 500"
            : "O valor mínimo para o peso é 0,5";
        return AlertError(errorMessage, pErrors);
      }
    },

    heightIsValid(pValue, pErrors) {
      pValue = pValue ? pValue.replace(",", ".") : "";

      if (!pValue || (pValue >= 0.09 && pValue < 2.6)) {
        return true;
      } else {
        const errorMessage =
          pValue > 2.5
            ? "O valor máximo para a altura é 2,5"
            : "O valor mínimo para a altura é 0,09";
        return AlertError(errorMessage, pErrors);
      }
    },

    validateNationality(examRules = "N") {
      if (
        (this.obligatoriness.nacionalidade === "S" || examRules === "S") &&
        !this.examPatientData.nationality
      ) {
        this.validate.nationality = false;
        return false;
      }
      this.validate.nationality = true;
      return true;
    },

    validateCity(examRules = "N") {
      if (
        (this.obligatoriness.naturalidade === "S" || examRules === "S") &&
        !this.examPatientData.city
      ) {
        this.validate.city = false;
        return false;
      }
      this.validate.city = true;
      return true;
    },

    validateDateMenstruation(pValue, pErrors) {
      if (this.isMenstruationRadioTrue && pValue) {
        const convertData = this.$utils.date.BrazilianDateToDatabase(pValue);
        if (!pValue || pValue.length < 10) {
          return AlertError(
            "A data da última menstruação é obrigatória.",
            pErrors,
          );
        }

        if (moment(convertData).isSameOrAfter(moment())) {
          return AlertError("A data não pode ser futura.", pErrors);
        }
      }
      return true;
    },

    checkSexIsValidFemale() {
      if (this.procedure.tp_sexo === "I" || this.procedure.tp_sexo === "N") {
        return true;
      } else {
        return this.procedure.tp_sexo === "F";
      }
    },

    checkSexIsValidMale() {
      if (this.procedure.tp_sexo === "I" || this.procedure.tp_sexo === "N") {
        return true;
      } else {
        return this.procedure.tp_sexo === "M";
      }
    },

    validadeMenstruation(examRules = "N") {
      let result = { valid: true, errorMessage: null };

      if (this.isWoman) {
        const dateLastMentrualDate = this.patientInfo.data_menstruacao;
        const convertData = this.$utils.date.BrazilianDateToDatabase(
          dateLastMentrualDate,
        );
        const haveLastMenstrualDate =
          !dateLastMentrualDate || dateLastMentrualDate.length < 10;
        const futureDate = moment(convertData).isSameOrAfter(moment());

        if (this.obligatoriness.dum === "S" || examRules === "S") {
          if (
            !this.isMenstruationRadioTrue &&
            !this.patientInfo.info_menstruacao
          ) {
            result = {
              valid: false,
              errorMessage: "O motivo da ausência de menstruação é obrigatório",
            };
          } else if (
            this.isMenstruationRadioTrue &&
            (haveLastMenstrualDate || futureDate)
          ) {
            result = {
              valid: false,
              errorMessage: "A data da última mentruação é obrigatória",
            };
          }
        }
      }
      return result;
    },

    validateProfessionalData(pValue, pErrors) {
      if (
        pValue &&
        this.isEmployeeSelected &&
        (!this.employeeRequest.cpf ||
          !this.employeeRequest.name ||
          !this.employeeRequest.uf ||
          !this.employeeRequest.numberCons ||
          !this.employeeRequest.conselho)
      ) {
        // this.isEmployeeSelected = false;

        /* :TODO: Comentei a linha acima pois estava desabilitando o botão "Atualizar"
        ao tentar agendar um paciente do qual o cadastro do profissional selecionado
        estivesse com dados obrigatórios faltando (Ex.: o CPF do profissional) */

        this.employeeRequest.id = 0;

        if (!this.employeeRequest.cpf) {
          return AlertError(
            "Profissional não possui CPF no cadastro.",
            pErrors,
          );
        }
        if (!this.employeeRequest.name) {
          return AlertError(
            "Profissional não possui Nome no cadastro.",
            pErrors,
          );
        }
        if (!this.employeeRequest.uf) {
          return AlertError("Profissional não possui UF no cadastro.", pErrors);
        }
        if (!this.employeeRequest.numberCons) {
          return AlertError(
            "Profissional não possui Número do Conselho no cadastro.",
            pErrors,
          );
        }
        if (!this.employeeRequest.conselho) {
          return AlertError(
            "Profissional não possui Tipo do Conselho no cadastro.",
            pErrors,
          );
        }
      }
      return true;
    },

    validateObjectAnalisis() {
      if (
        this.exams.objectAnalisis.lob_nome_objetos === "" &&
        this.objectExam.length > 0
      ) {
        return false;
      }
      return true;
    },

    validateMaterial() {
      if (this.exams.collectMaterial === "") {
        return false;
      }
      return true;
    },

    validateAddProcedures() {
      if (!this.validateExam) {
        return AlertError("Verifique os campos");
      }

      if (!this.canAddMultiplo()) {
        return AlertError("Esse procedimento não pode ser duplicado");
      }

      if (this.isWoman) {
        if (!this.checkSexIsValidFemale()) {
          return AlertError(
            "Esse procedimento não pode ser adicionado para o sexo feminino",
          );
        }
      } else {
        if (!this.checkSexIsValidMale()) {
          return AlertError(
            "Esse procedimento não pode ser adicionado para o sexo masculino",
          );
        }
      }

      if (!this.validateAge()) {
        return AlertError(
          "A idade do paciente não corresponde ao tipo de procedimento",
        );
      }

      if (!this.validateColorRace()) {
        return AlertError("A cor/raça do paciente é obrigatória");
      }

      if (!this.validateHeightAndWeight()) {
        return AlertError(
          "Os campos peso e altura são obrigatórios para o procedimento",
        );
      }

      if (!this.validateNationality()) {
        return AlertError("O campo nacionalidade é obrigatório");
      }

      if (!this.validateCity()) {
        return AlertError("O campo naturalidade é obrigatório");
      }

      if (!this.validateMaterial() || !this.validateObjectAnalisis()) {
        this.$refs.validatorExam.validate();
        return AlertError("Verifique os campos");
      }

      const menstruationValid = this.validadeMenstruation();
      if (!menstruationValid.valid) {
        return AlertError(menstruationValid.errorMessage);
      }

      if (!this.validateExamStatusPending()) {
        return AlertError(
          "Os campos de data e hora de agendamento são obrigatórios",
        );
      }

      return true;
    },

    validateProcedureList() {
      const procedure = this.procedureList.some((pProcedure) => {
        if (this.isWoman) {
          if (
            pProcedure.obligatoriness &&
            pProcedure.obligatoriness.dum === "S"
          ) {
            return (
              (this.isMenstruationRadioTrue &&
                !this.patientInfo.data_menstruacao) ||
              (!this.isMenstruationRadioTrue &&
                !this.patientInfo.info_menstruacao)
            );
          }
        }
      });

      return !procedure;
    },

    validateExamStatusPending() {
      return this.currentStatusId === EXAM_STATUS_LABORATORY.PENDENTE
        ? this.form.leb_data_agendamento && this.form.leb_hora_agendamento
        : true;
    },

    async isProceduresValid() {
      if (!this.procedureList || this.procedureList.length === 0) {
        throw new Error("Nenhum procedimento solicitado");
      }

      this.procedureList.forEach((pProcedure) => {
        const {
          altura,
          cor,
          dum,
          multiplo,
          nacionalidade,
          naturalidade,
          peso,
        } = pProcedure.obligatoriness;

        this.procedure = pProcedure.procedure;
        this.obligatoriness = pProcedure.obligatoriness;

        this.examObjects(
          pProcedure.codigo,
          pProcedure.codigoExameMne.trim() || "",
          !!pProcedure.objectAnalisis.lob_nome_objetos,
        );

        const procedureName = pProcedure.nome;

        if (!this.validateHeightAndWeight(peso, altura)) {
          throw new Error(
            `Os campos peso e altura são obrigatórios: ${procedureName}`,
          );
        }

        if (!this.validateColorRace(cor)) {
          throw new Error(
            `A cor/raça do paciente é obrigatória: ${procedureName}`,
          );
        }

        const menstruationValid = this.validadeMenstruation(dum);
        if (!menstruationValid.valid) {
          throw new Error(
            menstruationValid.errorMessage + `: ${procedureName}`,
          );
        }

        if (!this.hasMultiplo(multiplo, pProcedure)) {
          throw new Error(`Procedimentos duplicados: ${procedureName}`);
        }

        if (!this.validateNationality(nacionalidade)) {
          throw new Error(
            `O campo nacionalidade é obrigatório: ${procedureName}`,
          );
        }

        if (!this.validateCity(naturalidade)) {
          throw new Error(
            `O campo naturalidade é obrigatório: ${procedureName}`,
          );
        }

        if (this.isWoman) {
          if (!this.checkSexIsValidFemale()) {
            throw new Error(
              `Procedimento não pode ser adicionado para o sexo feminino: ${procedureName}`,
            );
          }
        } else {
          if (!this.checkSexIsValidMale()) {
            throw new Error(
              `Procedimento não pode ser adicionado para o sexo masculino: ${procedureName}`,
            );
          }
        }

        if (!this.validateAge()) {
          throw new Error(
            `A idade do paciente não corresponde ao tipo de procedimento: ${procedureName}`,
          );
        }

        if (!pProcedure.collectMaterial.length > 0) {
          throw new Error(
            `O campo material de coleta é obrigatório: ${procedureName}`,
          );
        }

        if (this.objectExam.length > 0 && !pProcedure.objectAnalisis) {
          throw new Error(
            `O campo objeto de análise é obrigatório: ${procedureName}`,
          );
        }
      });

      this.procedure = {
        no_procecimento: "",
        tp_sexo: "",
        vl_idade_maxima: "",
        vl_idade_minima: "",
      };

      return true;
    },
  },
};

export default ValidationLaboratoryDetailsMixin;
