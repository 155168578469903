var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modulebox',{staticClass:"exam-registration-queue",attrs:{"title":_vm.isEdition ? `Editar Fila` : `Inserir na Fila`}},[_c('div',{staticClass:"exam-registration-queue-body"},[_c('form',{staticClass:"form-container",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveForm.apply(null, arguments)}}},[_c('RgValidatorForm',{ref:"validator"},[_c('FormBase',{staticClass:"module-patient",attrs:{"title":"Paciente"}},[_c('div',{staticClass:"content-inputs"},[_c('RgSuggestPatientInformation',{ref:"patientInfoSuggest",staticClass:"patient-input",attrs:{"id":"patient","rules":{ forceSelection: true, required: true },"disabledOnlySuggest":_vm.isEdition,"with-patient-module":['exam2'],"pac_prontuario_unico":_vm.patientInfo && _vm.patientInfo.prontuario_unico,"disabled":_vm.disabledByModal,"enabled-patient":false,"is-patient":false,"extra-data":['withoutAddress'],"withPatientExam":true},on:{"disabledByModalComponents":_vm.disabledByModalComponents,"patientInfo":_vm.getPatientData},model:{value:(_vm.suggestPatient),callback:function ($$v) {_vm.suggestPatient=$$v},expression:"suggestPatient"}})],1)]),_c('FormBase',{staticClass:"module-solicitation",attrs:{"title":"Dados da Solicitação"}},[_c('div',{staticClass:"content-grid"},[_c('div',{staticClass:"content-grid row-1"},[_c('RgInputDate',{class:{ disable: true },attrs:{"id":"entry-date","disabled":true,"label":"Data de Entrada"},model:{value:(_vm.form.entryDate),callback:function ($$v) {_vm.$set(_vm.form, "entryDate", $$v)},expression:"form.entryDate"}}),_c('RgInputHour',{class:{ disable: true },attrs:{"id":"entry-hour","disabled":true,"label":"Hora de Entrada"},model:{value:(_vm.form.entryHour),callback:function ($$v) {_vm.$set(_vm.form, "entryHour", $$v)},expression:"form.entryHour"}}),_c('RgSuggestEmployee',{ref:"employee",class:{
                  disable: !_vm.hasIdPatient || _vm.isEdition,
                },attrs:{"id":"employee","label":"Profissional Solicitante","rules":{
                  required: true,
                  forceSelection: _vm.enableFreeRequesterEmployee,
                },"disabled":!_vm.hasIdPatient ||
                  _vm.isEdition ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent,"maxlength":100,"autoSelectIfHasOne":_vm.enableFreeRequesterEmployee,"placeholder":"Digite o nome do profissional","notNecessaryBond":"","filterWithoutBond":""},on:{"selected":_vm.selectingProfessional,"clear":function($event){_vm.form.classCouncil = null}},model:{value:(_vm.requestingProfessional),callback:function ($$v) {_vm.requestingProfessional=$$v},expression:"requestingProfessional"}}),_c('RgInput',{ref:"classCouncil",class:{
                  disable: _vm.disableClassCouncil || _vm.isEdition || !_vm.hasIdPatient,
                },attrs:{"id":"council","label":"Conselho de Classe","rules":{ required: !_vm.form.requestingProfessionalId },"disabled":_vm.disableClassCouncil ||
                  _vm.isEdition ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent ||
                  !_vm.hasIdPatient,"maxlength":20,"placeholder":"Digite o conselho de classe"},model:{value:(_vm.form.classCouncil),callback:function ($$v) {_vm.$set(_vm.form, "classCouncil", $$v)},expression:"form.classCouncil"}})],1),_c('div',{staticClass:"content-grid row-2"},[_c('RgSelectUnithealthQueue',{ref:"unitSolicitation",class:{
                  disable: !_vm.hasIdPatient || !_vm.canChangeProcedure,
                },attrs:{"id":"unit-health","rules":{ required: true },"disabled":!_vm.hasIdPatient ||
                  !_vm.canChangeProcedure ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent,"module-id":_vm.examModuleId,"hasProcedure":true,"label":"Unidade de Saúde Solicitante"},model:{value:(_vm.form.requesterUnit),callback:function ($$v) {_vm.$set(_vm.form, "requesterUnit", $$v)},expression:"form.requesterUnit"}}),_c('RgSelectPriority',{ref:"priority",staticClass:"inputitem",class:{
                  disable: !_vm.hasIdPatient,
                },attrs:{"id":"priority","rules":{ required: true },"module-id":33,"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"label":"Prioridade"},model:{value:(_vm.form.priority),callback:function ($$v) {_vm.$set(_vm.form, "priority", $$v)},expression:"form.priority"}}),_c('RgInputDate',{ref:"date",class:{ disable: _vm.isEdition || !_vm.hasIdPatient },attrs:{"id":"request-date","rules":{ maxTodayDate: true, required: true },"disabled":_vm.isEdition ||
                  !_vm.hasIdPatient ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent,"max-date":new Date(),"label":"Data do Pedido"},model:{value:(_vm.form.requestDate),callback:function ($$v) {_vm.$set(_vm.form, "requestDate", $$v)},expression:"form.requestDate"}}),(_vm.isEdition)?_c('RgInput',{class:{ disable: !_vm.hasIdPatient || _vm.isEdition },attrs:{"id":"regulator","label":"Autorizado por","disabled":_vm.isEdition || _vm.disabledByModal || _vm.disabledByModalComponent},model:{value:(_vm.dataLoadAlter.nomeRegulador),callback:function ($$v) {_vm.$set(_vm.dataLoadAlter, "nomeRegulador", $$v)},expression:"dataLoadAlter.nomeRegulador"}}):_vm._e()],1)]),_c('div',{staticClass:"area",class:{
              disable:
                !_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,
            }},[_c('div',{staticClass:"procedure-area"},[_c('p',{staticClass:"label"},[_vm._v("Procedimentos na Fila")]),(_vm.queuesPerPerson && _vm.queuesPerPerson.length > 0)?_c('div',{staticClass:"table-area"},_vm._l((_vm.queuesPerPerson),function(queuePerPerson,id){return _c('div',{key:id,staticClass:"item"},[_vm._v(" "+_vm._s(queuePerPerson.nome)+" ")])}),0):_c('div',{staticClass:"empty-subprocedures"},[_c('IconEmpty',{staticClass:"empty-icon"}),_c('span',[_vm._v("Nenhum procedimento encontrado")])],1)]),_c('div',{staticClass:"text-area"},[(!_vm.isEdition)?_c('RgTextArea',{ref:"textArea",staticClass:"inputbox",class:{
                  disable: !_vm.hasIdPatient,
                },attrs:{"id":"observations","maxlength":500,"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"label":"Observações","is-optional":""},model:{value:(_vm.form.observations),callback:function ($$v) {_vm.$set(_vm.form, "observations", $$v)},expression:"form.observations"}}):_vm._e(),(!_vm.isEdition)?_c('RgTextArea',{ref:"textArea",staticClass:"inputbox",class:{
                  disable: !_vm.hasIdPatient,
                },attrs:{"id":"motivations","maxlength":500,"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"label":"Motivo","is-optional":""},model:{value:(_vm.form.motivations),callback:function ($$v) {_vm.$set(_vm.form, "motivations", $$v)},expression:"form.motivations"}}):_vm._e()],1)])]),_c('FormBase',{staticClass:"module-procedure",attrs:{"title":"Procedimento"}},[_c('div',{staticClass:"inputs"},[_c('RgSelectProceduresQueueByUnit',{ref:"proceduresQueueByUnit",staticClass:"inputitem",class:{ disable: !_vm.hasIdPatient },attrs:{"id":"procedures-queue-unit","unit-health-id":Number(_vm.form.requesterUnit),"alter-procedure":_vm.alterProcedure,"disabled":!_vm.hasIdPatient ||
                !_vm.canChangeProcedure ||
                _vm.disabledByModal ||
                _vm.disabledByModalComponent,"permission":_vm.canChangeProcedure,"rules":{ required: true },"placeholder":"Digite o nome do procedimento"},on:{"change":_vm.selectingProcedure},model:{value:(_vm.procedure),callback:function ($$v) {_vm.procedure=$$v},expression:"procedure"}}),_c('RgInputQuantity',{ref:"quantity",class:{
                disable: !_vm.hasIdPatient,
              },attrs:{"id":"quantity","label":"Quantidade","min":1,"max":999,"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"rules":{ required: true }},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('RgSuggestCid',{ref:"cid10",class:{
                disable: !_vm.hasIdPatient,
              },attrs:{"id":"cid","label":"CID","disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent},on:{"selected":_vm.selectingCidCode},model:{value:(_vm.cid10),callback:function ($$v) {_vm.cid10=$$v},expression:"cid10"}})],1),(_vm.hasSubprocedure)?_c('div',{staticClass:"filter-subprocedure"},[_c('RgInput',{attrs:{"id":"subprocedure","disabled":!_vm.mutableSubprocedureList ||
                !_vm.canChangeSubProcedure ||
                _vm.disabledByModal ||
                _vm.disabledByModalComponent,"label":"","placeholder":"Busca por subprocedimento"},model:{value:(_vm.subprocedure),callback:function ($$v) {_vm.subprocedure=$$v},expression:"subprocedure"}}),_c('RgSearchButton',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['enter']),expression:"['enter']"}],ref:"subprocedures",attrs:{"id":"subprocedure-search","disabled":!_vm.mutableSubprocedureList ||
                !_vm.canChangeSubProcedure ||
                _vm.disabledByModal ||
                _vm.disabledByModalComponent},on:{"submit":_vm.searchSubprocedure},nativeOn:{"shortkey":function($event){return _vm.searchSubprocedure.apply(null, arguments)}}})],1):_vm._e(),(_vm.hasSubprocedure)?_c('div',{staticClass:"subprocedures"},[(
                _vm.mutableSubprocedureList && _vm.mutableSubprocedureList.length > 0
              )?_c('div',{staticClass:"list-subprocedures"},[_c('SmartTable',{ref:"smartTable",attrs:{"name":"ExamInsertQueue","columns":_vm.COLUMN,"body":_vm.mutableSubprocedureList,"total":Number(_vm.mutableSubprocedureList.length),"initial-columns":1,"show-pagination":false,"disabled":!_vm.canChangeSubProcedure ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent,"index-column":"id","remove-btn-columns":""},on:{"getLine":_vm.selectLine}})],1):_c('div',{staticClass:"empty-subprocedures"},[_c('span',{staticClass:"title"},[_vm._v("Subprocedimentos")]),_c('div',{staticClass:"empty-subprocedures"},[_c('IconEmpty'),_c('span',[_vm._v(" Nenhum subprocedimento encontrado")])],1)]),_c('div',{staticClass:"selected-subprocedures",class:{
                disable:
                  !_vm.canChangeSubProcedure ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent,
              }},[_c('span',{staticClass:"title"},[_vm._v("Subprocedimentos Selecionados")]),(_vm.selectedSubprocedure.length > 0)?_c('div',{staticClass:"list-subprocedures"},_vm._l((_vm.selectedSubprocedure),function(item,index){return _c('div',{key:index,staticClass:"subprocedure"},[_c('span',{staticClass:"item"},[_vm._v(" "+_vm._s(item.esu_nome_exames_procedimentos_filho || item.subProcedimento)+" ")]),_c('button',{staticClass:"remove",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeSubprocedureList(index)}}},[_vm._v(" x ")])])}),0):_c('div',{staticClass:"empty-subprocedures"},[_c('IconEmpty'),_c('span',[_vm._v(" Nenhum subprocedimento selecionado")])],1)])]):_vm._e()])],1)],1)]),_c('div',{staticClass:"footer"},[_c('RgCleanButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEdition),expression:"!isEdition"}],staticClass:"clean",attrs:{"id":"clear-btn","small":"","title":"Limpar","disabled":_vm.disabledByModal || _vm.disabledByModalComponent},on:{"click":_vm.showConfirmFieldClearing}}),(_vm.showBackButton)?_c('RgCancelButton',{ref:"cancelButton",staticClass:"cancel",attrs:{"id":"cancel-btn","medium":"","disabled":_vm.disabledByModal || _vm.disabledByModalComponent},on:{"click":_vm.cancel}}):_vm._e(),_c('RgSaveButton',{staticClass:"save",attrs:{"id":"save-btn","medium":"","disabled":!_vm.hasIdPatient ||
        _vm.disabledSave ||
        _vm.disabledByModal ||
        _vm.disabledByModalComponent},on:{"click":function($event){return _vm.saveForm(false)}}}),(!_vm.isEdition)?_c('MediumButton',{staticClass:"save",attrs:{"id":"save-insert-btn","medium":"","label":"Salvar e inserir arquivo","disabled":!_vm.hasIdPatient ||
        _vm.disabledSave ||
        _vm.disabledByModal ||
        _vm.disabledByModalComponent,"title":"Salvar e Inserir Arquivo"},on:{"click":function($event){return _vm.saveForm(true)}}}):_vm._e()],1),_c('ModalConfirmFieldClearing',{ref:"confirmClearing",attrs:{"id":"modal-confirm-clearing","show":_vm.modalConfirmFieldClearing},on:{"close":_vm.closeConfirmFieldClearing,"getYes":_vm.confirmCleanForm,"getOut":_vm.closeConfirmFieldClearing}}),_c('ModalTypesPrintInsertQueue',{attrs:{"id":"modal-print","show":_vm.modalPrint,"filId":Number(_vm.fil_id)},on:{"close":_vm.closeModalPrint}}),_c('ModalPatientImageInRow',{ref:"modalPatientImageInRow",attrs:{"id":"modal-patient-image-in-row","show":_vm.modalPatientImageInRow,"fill-id":Number(_vm.fil_id),"modId":_vm.examModuleId},on:{"close":_vm.closeModalPatientImageInRow}}),_c('ModalPatientDuplicationQueue',{attrs:{"id":"modal-patient-duplication-queue","show":_vm.modalPatientDuplicationQueue,"module-id":_vm.examModuleId,"patient-name":_vm.patientDuplicationName,"queue-info":_vm.queueDuplicationInfo,"block-duplication":_vm.blockPatientDuplication},on:{"save":_vm.saveDuplication,"close":_vm.closeModalPatientDuplicationQueue,"cancel":_vm.closeModalPatientDuplicationQueue}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }