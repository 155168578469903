<template>
  <div v-if="show" class="modal-exam-details">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Dados da Efetivação de Exame</div>
      </div>

      <div slot="body" class="body">
        <FormBase title="Dados do Paciente" class="area">
          <section v-if="examData" class="patient-info">
            <span>
              Nome:
              <b>{{
                patientData && patientData.pes_nome
                  ? patientData.pes_nome
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              Nome da mãe:
              <b>{{
                patientData && patientData.pes_mae
                  ? patientData.pes_mae
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              Sexo:
              <b>
                {{
                  patientData &&
                  patientData.gender &&
                  patientData.gender.sex_sigla
                    ? formatGender(patientData.gender.sex_sigla)
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
            <span>
              Data de Nascimento:
              <b>
                {{
                  patientData && patientData.pes_nascimento
                    ? formatBirthDate(patientData.pes_nascimento)
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
            <span>
              CPF:
              <b>
                {{
                  patientData && patientData.cpf && patientData.cpf.cpf_numero
                    ? formatCpf(patientData.cpf.cpf_numero)
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
            <span>
              CNS:
              <b>
                {{
                  patientData && patientData.cns && patientData.cns.crs_numero
                    ? formatCns(patientData.cns.crs_numero)
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
            <span>
              Prontuário:
              <b>
                {{
                  patientData &&
                  patientData.record_numbers &&
                  patientData.record_numbers.rows[0]
                    ? patientData.patientData.record_numbers.rows[0]
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
            <span>
              Prontuário Único:
              <b>
                {{
                  patientData && patientData.patient.pac_prontuario_unico
                    ? patientData.patient.pac_prontuario_unico
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
          </section>

          <div class="sub-title">
            <span class="sub-title">Exame</span>
            <hr class="line" />
          </div>

          <section class="solicitation">
            <span>
              Data da Solicitação:
              <b>{{
                examData
                  ? formatRequestDate(examData.exe_data_solicitacao)
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              Profissional Solicitante:
              <b>{{
                examData ? examData.request_employee : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              CID:
              <b>{{
                examData && examData.cid10
                  ? examData.cid10.cid_nome
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              Setor:
              <b>{{
                examData && examData.sector
                  ? examData.sector.set_nome
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              {{
                examData &&
                examData.exe_id_exames_agendamentos_sub_procedimentos
                  ? "Subprocedimento"
                  : "Procedimento"
              }}
              Solicitado:
              <b>{{
                examData && examData.procedimento
                  ? examData.procedimento
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span v-if="examData.exe_id_exames_agendamentos_sub_procedimentos">
              Procedimento Principal:
              <b>{{
                examData && examData.procedimento_principal
                  ? examData.procedimento_principal
                  : "NÃO INFORMADO"
              }}</b>
            </span>
          </section>

          <div v-if="effective" class="sub-title">
            <span class="sub-title">Efetivação</span>
            <hr class="line" />
          </div>

          <section v-if="effective" class="effectiveness">
            <span>
              Data da Efetivação:
              <b>{{
                effective && examData && examData.exe_data_efetivacao
                  ? formatEffectivenessDate(examData.exe_data_efetivacao)
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              Profissional Executante:
              <b>{{
                effective &&
                examData &&
                examData.employeeRelationship &&
                examData.employeeRelationship.employee &&
                examData.employeeRelationship.employee.person &&
                examData.employeeRelationship.employee.person.pes_nome
                  ? examData.employeeRelationship.employee.person.pes_nome
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span>
              Setor:
              <b>{{
                effective && examData && examData.sector
                  ? examData.sector.set_nome
                  : "NÃO INFORMADO"
              }}</b>
            </span>
            <span v-if="examData.exe_observacao">
              Observação:
              <b>
                {{
                  effective && examData && examData.exe_observacao
                    ? examData.exe_observacao
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
          </section>

          <div v-if="notEffective" class="sub-title">
            <span class="sub-title">Não Efetivado</span>
            <hr class="line" />
          </div>

          <section v-if="notEffective" class="not-effectiveness">
            <span>
              Motivo:
              <b>
                {{
                  notEffective && examData && examData.motivo_nao_efetivacao
                    ? examData.motivo_nao_efetivacao.toUpperCase()
                    : "NÃO INFORMADO"
                }}
              </b>
            </span>
          </section>
        </FormBase>
      </div>

      <div slot="footer" class="exam-situation">
        <span>Situação:</span>
        <div v-if="effective" class="situation effective">Efetivado</div>
        <div v-if="notEffective" class="situation not-effective">
          Não Efetivado
        </div>
        <div v-if="opened" class="situation opened">Em Aberto</div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalViewEffectivationDetails",
  components: {
    RgBaseModal,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      patientData: null,
      examData: null,
    };
  },

  computed: {
    opened() {
      return this.examData && this.examData.exe_id_exames_efetivacao === 1;
    },
    effective() {
      return this.examData && this.examData.exe_id_exames_efetivacao === 2;
    },
    notEffective() {
      return this.examData && this.examData.exe_id_exames_efetivacao === 3;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.mountedExam();
      }
    },
  },

  methods: {
    async mountedExam() {
      this.$loader.start();
      const pData = this.$store.getters[
        "Exam/IndividualEffectivation/GET_SELECTED_DATA"
      ];

      if (pData && pData.patient && pData.patient.person) {
        this.loadPatientData(pData.patient.person.pes_id);
      }

      this.examData = pData;
      this.$loader.finish();
    },
    async loadPatientData(pIdPerson) {
      try {
        this.$loader.start();

        this.patientData = await this.$store.dispatch(
          "Appointment/Schedule/GET_PATIENT_BY_ID",
          {
            pesId: pIdPerson,
          },
        );
      } catch (pErr) {
        this.$toaster.error("Erro ao carregar dados do paciente", pErr);
      } finally {
        this.$loader.finish();
      }
    },
    formatGender(pData) {
      return pData === "M"
        ? "MASCULINO"
        : pData === "F"
        ? "FEMININO"
        : "NÃO INFORMADO";
    },
    formatBirthDate(pDate) {
      return this.$utils.date.BrazilianDateFormat(pDate);
    },
    formatRequestDate(pDate) {
      const requestDateData = pDate ? pDate.split(" ") : null;
      const requestDate = requestDateData[0];

      return this.$utils.date.BrazilianDateFormat(requestDate);
    },
    formatEffectivenessDate(pDate) {
      return this.$utils.date.BrazilianDateTimeFormat(pDate);
    },
    formatCpf(pValue) {
      return pValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    formatCns(pValue) {
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
    },
    close() {
      this.patientData = null;
      this.examData = null;

      this.$store.commit("Exam/IndividualEffectivation/CLEAN_SELECTED_DATA");

      this.$emit("close");
    },
  },
};
</script>
