<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchPlacePerSectorPep from "./action/SearchPlacePerSectorPep";

export default {
  name: "RgComboboxPlacePerSectorPep",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Local de Atendimento",
    },
    sector: {
      type: Number,
    },
    active: Boolean,
  },
  data: () => {
    return {};
  },
  watch: {
    sector() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      if (this.sector) {
        const data = await SearchPlacePerSectorPep({
          set_id: this.sector,
          blnSomenteAtivos: this.active,
        });

        data.forEach((item) => {
          this.mData.push({
            value: item.data,
            text: item.label,
          });
        });
      }
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
