<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchPharmacyUnit from "./action/SearchPharmacyUnit";
import { UNIT_TYPE } from "$pharmacy/common/enums";

export default {
  name: "RgSelectPharmacyUnit",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Farmácia Central",
    },
    unitType: {
      type: Number,
      default: UNIT_TYPE.CENTRAL,
    },
    unitHealth: {
      type: Number,
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
    unitHealth(pValue) {
      this.mValue = null;
      this.doSearch();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      if (!this.unitHealth) return Promise.resolve([]);
      const variables = {
        far_id_tipos_farmacia: this.unitType,
        far_id_unidades_saude: this.unitHealth,
      };
      const data = await SearchPharmacyUnit(variables);
      this.valuesData = data.map((item) => {
        return { value: item.far_id, label: item.far_nome };
      });
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
