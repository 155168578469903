<style src="./RgSuggestCid.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="suggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :min="min"
    :search-on-focus="searchOnFocus"
    title="Classificação Internacional de Doenças"
    class="rg-suggest-cid"
    @item-selected="itemSelected"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="cid-item"
    >
      <div class="cid-row-info">
        <span class="cid">{{ item.cid_codigo }} - {{ item.cid_nome }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchCid from "$billing/common/component/rg-combobox-cid/action/SearchCid";
import {
  GetCidCode,
  IsCidPattern,
  StartWithLetter,
  IsCidExternalViolence,
} from "./helpers";

export default {
  name: "RgTypeaheadHospitalizationAih",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "CID:",
    },
    min: {
      type: Number,
      default: -1,
    },
    procedure: {
      type: String,
      default: null,
    },
    mainCid: {
      type: String,
      default: null,
    },
    loadAllExceptExternalViolence: {
      type: Boolean,
      default: false,
    },
    dataItem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loaded: false,
      captionRaw: "cid_codigo - cid_nome",
      limit: 20,
      specialProcedures: [
        // Procedimentos múltiplos
        "0415010012", // TRATAMENTO C/ CIRURGIAS MULTIPLAS
        "0415030013", // TRATAMENTO CIRURGICO EM POLITRAUMATIZADO
        "0415020077", // PROCEDIMENTOS SEQUENCIAIS EM NEUROCIRURGIA
        // Procedimentos de diagnóstico de 1º atendimento
        "0301060010", // DIAGNOSTICO E/OU ATENDIMENTO DE URGENCIA EM CLINICA PEDIATRICA
        "0301060070", // DIAGNOSTICO E/OU ATENDIMENTO DE URGENCIA EM CLINICA CIRURGICA
        "0301060088", //  DIAGNOSTICO E/OU ATENDIMENTO DE URGENCIA EM CLINICA MEDICA
      ],
    };
  },
  computed: {
    isSpecialProcedure() {
      return this.specialProcedures.includes(this.procedure);
    },
    searchOnFocus() {
      return (
        !isEmpty(this.procedure) &&
        !this.isSpecialProcedure &&
        (!this.loadAllExceptExternalViolence ||
          IsCidExternalViolence(this.mainCid))
      );
    },
  },
  watch: {
    inputValue(pValue) {
      this.$emit("input", pValue); // Default
      const cidCode = GetCidCode(pValue);
      this.$emit("cid-code", cidCode);
    },
    mainCid(pValue) {
      this.$refs.suggest.resetSuggestionList();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCid(this.inputValue);
    });
  },
  methods: {
    itemSelected(pValue) {
      this.$emit("cid-selected", pValue);
    },
    reset() {
      this.inputValue = null;
      this.$refs.suggest.resetSuggestionList();
    },
    async loadCid(pValue) {
      if (isEmpty(pValue)) return;

      if (pValue !== this.inputValue) this.inputValue = pValue;

      const result = await this.doSearch();
      if (result && Array.isArray(result) && result.length > 0) {
        if (result.length === 1) this.selectingItemFromSuggestList(result[0]);
        else {
          const selectedItem = result.find(
            (item) => item.cid_codigo === pValue,
          );
          this.selectingItemFromSuggestList(selectedItem);
        }
      }
    },
    async doSearch() {
      const params = this.handleParams();
      const result = await SearchCid(params);
      return this.handleSearchResult(result);
    },
    handleParams() {
      const params = { groupBy: "cid_codigo" };

      if (!isEmpty(this.inputValue)) {
        params.limit = this.limit;

        if (
          StartWithLetter(this.inputValue) && // Ex.: AXXX
          (this.inputValue.length === 1 || IsCidPattern(this.inputValue)) // Ex.: A009 ou A01
        ) {
          params.cid_codigo = this.inputValue;
        } else {
          params.cid_nome = this.inputValue;
        }
      }

      if (this.searchOnFocus) params.codProcedure = this.procedure;

      if (this.mainCid) params.mainCid = this.mainCid;

      return params;
    },
    handleSearchResult(result) {
      if (!Array.isArray(result)) return [];
      return !this.mainCid
        ? result
        : result.filter((item) => item.cid_codigo !== this.mainCid);
    },
  },
};
</script>
