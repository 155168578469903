<template>
  <div class="exam-laboratory-detail">
    <RgSearch
      ref="RgSearch"
      v-model="mutableExamList"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :item-height="73"
      result-title="Pedidos de Exames Laboratoriais"
      @viewClickPagination="actionCleanSelection"
      @afterPerformSearch="actionCleanSelection"
    >
      <div slot="filters" class="exam-laboratory-detail-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-health"
              v-model="form.selectedUnity"
              default-text="Todas"
              class="inputitem"
              label="Unidade Solicitante"
              :filterAll="true"
            />
          </div>

          <div class="selectinput">
            <RgSelectSector
              id="sector"
              ref="RgSelectSector"
              v-model="form.selectedSector"
              :disabled="disableSector"
              :unit-health="form.selectedUnity"
              class="inputitem"
              default-text="Todos"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              id="employee"
              ref="requestedEmployee"
              v-model="form.suggestRequestedEmployee"
              :rules="{ forceSelection: startToTypeSuggestRequestedEmployee }"
              data-id="profissional-solicitante"
              data-item="profissional-solicitante"
              label="Profissional Solicitante"
              class="inputitem"
              notNecessaryBond
              filterWithoutBond
              @selected="selectingRequestedEmployee"
            />
          </div>

          <div class="selectinput">
            <RgInput
              id="solicitation-number"
              v-model="form.solicitationNumber"
              placeholder="Informe o protocolo"
              data-id="numero-solicitacao"
              label="Protocolo"
              class="inputitem"
            />
          </div>

          <div class="filter-title">
            <span class="title"> Dados do Paciente </span>
          </div>

          <div class="selectinput">
            <RgInput
              id="patient"
              v-model="form.patient"
              data-id="paciente"
              data-item="paciente"
              label="Paciente"
              class="inputitem"
              placeholder="Digite o nome do paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              id="document"
              ref="smartPerson"
              v-model="form.suggestDocument"
              :with-patient-module="['examLaboratory']"
              :rules="{ forceSelection: true }"
              :enabled-patient="false"
              :radioCurrent="form.radiostatus"
              :removeFilter="FILTER_REMOVE"
              class="inputitem document"
              @selected="selectingPatient"
            />
          </div>

          <div class="filter-subtitle filter-title">
            <span class="period-subtitle"> Período da Solicitação </span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="request-initial-date"
                v-model="form.requestInitialDate"
                data-id="data-inicio-solicitacao"
                :rules="{ fn: verifyInclusionInitialDate }"
                label="Data inicial"
                class="inputitem"
              />
              <RgInputDate
                id="request-final-date"
                v-model="form.requestFinalDate"
                data-id="data-final-solicitacao"
                label="Data final"
                class="inputitem"
              />
            </div>
          </div>

          <div
            :class="{ 'agendation-disable': !canSelectEffectivatedPeriod }"
            class="filter-subtitle filter-title"
          >
            <span class="period-subtitle"> Período do Agendamento </span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="scheduling-initial-date"
                v-model="form.schedulingInitialDate"
                :class="{ disable: !canSelectEffectivatedPeriod }"
                :disabled="!canSelectEffectivatedPeriod"
                data-id="data-inicio-agendamento"
                label="Data inicial"
                class="inputitem"
              />

              <RgInputDate
                id="scheduling-final-date"
                v-model="form.schedulingFinalDate"
                :class="{ disable: !canSelectEffectivatedPeriod }"
                :disabled="!canSelectEffectivatedPeriod"
                data-id="data-final-agendamento"
                label="Data final"
                class="inputitem"
              />
            </div>
          </div>
        </div>
      </div>

      <div slot="search-top" class="result-search-top-button">
        <Tooltip
          class="tootlip"
          start-open
          message='Selecione um pedido na listagem e para visualizar ou editar clique no botão "visualizar" no canto superior direito da tabela'
        >
          <IconLightBulbHelper slot="icon" class="icon" hollow />
        </Tooltip>

        <div class="actions-buttons">
          <RgRadioCustom
            id="status"
            ref="status"
            v-bind="propsRadioCustom"
            multSelect
            @input="onInputRadioCustom"
          />

          <div class="right">
            <RgAddButton id="new-exam" @click="newExamRequest" />
            <RgShowButton
              id="show-exam"
              :disabled="!examSelected"
              title="Visualizar Pedido"
              @click="viewExam(selectedItem)"
            />
            <RgPersonCardButton
              id="edit-patient"
              :disabled="!examSelected"
              class="card"
              small
              @click="editPatient"
            />
            <RgHistoryButton
              id="history-patient"
              :disabled="!examSelected"
              class="card"
              title="Histórico de Atendimento"
              small
              @click="patientCareHistory"
            />
          </div>
        </div>
      </div>

      <div class="result-search-list">
        <div ref="examList" class="exam-list">
          <div
            v-for="(item, index) in mutableExamList"
            :key="index"
            :class="{ active: index === activeRow }"
            class="exam"
            @click="selectLine(item, index)"
          >
            <div class="item">
              <div class="calendar">
                <IconCalendarLaboratoryExam
                  :color="
                    getColorCalendar(
                      item.leb_id_laboratoriais_status_pedido,
                      item.biosLabIntegrationsError,
                    )
                  "
                  class="icon-calendar"
                />
              </div>

              <div class="info-column">
                <div class="patient" :title="item.patient.person.pes_nome">
                  <strong>{{ item.patient.person.pes_nome }} </strong>
                </div>

                <div>
                  Agendamento:
                  <strong>
                    {{
                      formatDate(
                        item.leb_data_agendamento,
                        item.leb_id_laboratoriais_status_pedido,
                      )
                    }}
                  </strong>
                  <strong>
                    {{
                      formatHour(
                        item.leb_hora_agendamento,
                        item.leb_id_laboratoriais_status_pedido,
                      )
                    }}
                  </strong>
                </div>
              </div>

              <div class="info-column">
                <div>
                  Prontuário:
                  <strong>
                    {{ item.patient.pac_prontuario_unico || "-" }}
                  </strong>
                </div>

                <div>
                  Solicitação:
                  <strong>{{ formatDate(item.leb_data_solicitacao) }} </strong>
                </div>
              </div>

              <div class="info-column">
                <div
                  class="employee"
                  :title="item.leb_profissional_solicitante"
                >
                  Profissional solicitante:
                  <strong>{{ item.leb_profissional_solicitante }} </strong>
                </div>
                <div>
                  Protocolo:
                  <strong>{{ item.leb_codigo_solicitacao || "-" }} </strong>
                </div>
              </div>

              <div class="extra-column">
                <div class="butons">
                  <RgNewScheduleButton
                    id="schedule-exam"
                    :disabled="disableScheduleExam(item)"
                    class="item-button schedule"
                    title="Agendar Exame"
                    @click="scheduleExam(item)"
                  />

                  <SmallButton
                    id="error"
                    :disabled="!disableError(item)"
                    class="item-button error"
                    title="Erros"
                    :backgroundColor="'#ffa56a'"
                    @click="showModalError(item)"
                  >
                    <IconDanger slot="icon" />
                  </SmallButton>

                  <SmallButton
                    id="download-exam"
                    :disabled="!hasExamResult(item.biosLabInfo)"
                    title="Baixar resultado"
                    class="item-button download"
                    :open-url="hasExamResult(item.biosLabInfo)"
                    :send-url="getUrl(item.biosLabInfo)"
                    :backgroundColor="'#26789f'"
                    @click="download(item)"
                  >
                    <IconArrowDownload slot="icon" />
                  </SmallButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RgSearch>

    <MedicalLoader
      :to-close="loading"
      :status="status.loader"
      :title-loader="'Aguarde, enviando para o Bioslab...'"
      :title-success="'Enviado com sucesso!'"
      :title-failed="'Erro no envio para o Bioslab... '"
      @close="closeLoader"
    />

    <ModalShowError
      id="modal-show-error"
      :show="showError"
      :errors="errors"
      @close="closeErrorModal"
    />

    <ModalShowTags
      id="modal-show-tags"
      :can-print="canPrint"
      :bioslab-id="examBioslabId"
      :show="showTags"
      @close="closeTagModal"
    />
  </div>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch.vue";
import moment from "moment";
import { isEmpty } from "lodash";
import {
  IconCalendarLaboratoryExam,
  IconLightBulbHelper,
  RgInputDate,
  RgInput,
  RgSuggestEmployee,
  RgSuggestSmartPerson,
  RgSelectUnithealth,
  RgSelectSector,
  // RgEditButton,
  // RgPrinterButton,
  IconArrowDownload,
  SmallButton,
  RgAddButton,
  RgShowButton,
  RgPersonCardButton,
  RgHistoryButton,
  Tooltip,
  RgNewScheduleButton,
  IconDanger,
  RgRadioCustom,
} from "~tokio/primitive";
import {
  ModalShowError,
  ModalShowTags,
} from "$exam/submodules/view/individual-effectivation/component/modal";
import MedicalLoader from "~tokio/primitive/loader/medical-loader/MedicalLoader";
// import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import EXAM_STATUS_LABORATORY from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status-laboratory/GetExamStatusLaboratory";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";

const REQUESTED = 1;
const SCHEDULED = 2;
const PENDING = 3;
const PARTIALLY_ATTENDED = 4;
const ATTENDED = 5;

export default {
  name: "ExamLaboratory",
  components: {
    IconCalendarLaboratoryExam,
    IconLightBulbHelper,
    MedicalLoader,
    ModalShowError,
    ModalShowTags,
    RgSearch,
    RgInput,
    RgInputDate,
    RgSuggestEmployee,
    RgSelectUnithealth,
    RgSuggestSmartPerson,
    RgSelectSector,
    // RgEditButton,
    // RgPrinterButton,
    RgAddButton,
    RgShowButton,
    IconArrowDownload,
    // RgInputCns,
    SmallButton,
    RgPersonCardButton,
    RgHistoryButton,
    Tooltip,
    RgNewScheduleButton,
    IconDanger,
    RgRadioCustom,
  },
  filters: {
    existsItem: (pValue) =>
      pValue && pValue.length > 0 ? pValue : "NÃO EXISTE",
  },
  data() {
    return {
      loading: false,
      errors: [],
      status: {
        loader: 0,
      },
      suggestEmployee: "",
      examBioslabId: 0,
      form: {
        suggestRequestedEmployee: "",
        suggestDocument: "",
        examStatus: null,
        sector: null,
        radiostatus: "cpf",
        cns: null,
        cpf: null,
        nascimento: null,
        solicitationNumber: null,
        leb_id_profissional_solicitante: null,
        vin_id: null,
        patient: null,
        patientId: null,
        recordNumber: null,
        leb_nascimento_paciente: null,
        requestInitialDate: null,
        requestFinalDate: null,
        schedulingInitialDate: null,
        schedulingFinalDate: null,
        selectedUnity: null,
        selectedSector: null,
      },
      selectedItem: {},
      activeRow: null,
      showError: false,
      showTags: false,
      mutableExamList: [],
      patientInfo: {},
    };
  },

  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Solicitado", id: 1, color: "#56b0c9" },
        { title: "Agendado", id: 2, color: "#b46cff" },
        { title: "Pendente", id: 3, color: "#edc76a" },
        { title: "Atendido", id: 4, color: "#ffa56a" },
        { title: "Parcialmente Atendido", id: 5, color: "#66d48f" },
      ];

      const value = this.form.examStatus;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    examSelected() {
      return this.activeRow !== null;
    },

    canPrint() {
      return this.selectedItem.leb_id_laboratoriais_status_pedido !== ATTENDED;
    },

    canSelectEffectivatedPeriod() {
      return !this.form.examStatus
        ? false
        : this.form.examStatus.includes(EXAM_STATUS_LABORATORY.AGENDADO) ||
            this.form.examStatus.includes(EXAM_STATUS_LABORATORY.PENDENTE) ||
            this.form.examStatus.includes(EXAM_STATUS_LABORATORY.ATENDIDO) ||
            this.form.examStatus.includes(
              EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO,
            );
    },

    startToTypeSuggestEmployee() {
      return !isEmpty(this.suggestEmployee);
    },

    startToTypeSuggestRequestedEmployee() {
      return !isEmpty(this.form.suggestRequestedEmployee);
    },

    isThereExams() {
      return this.mutableExamList && this.mutableExamList.length > 0;
    },

    disableSector() {
      return !this.form.selectedUnity;
    },
  },

  watch: {
    canSelectEffectivatedPeriod(pValue) {
      if (!pValue) {
        this.form.schedulingInitialDate = "";
        this.form.schedulingFinalDate = "";
      }
    },
  },
  created() {
    this.FILTER_REMOVE = [
      { label: "Nome", col: "peopleName" },
      // { label: "CNS", col: "cns" },
    ];
  },

  destroyed() {
    const existThisRoute = ValidateIfRouteExistInBreadscrumb(
      "/exam/laboratory",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRoute) {
      this.$store.commit("Exam/RESET_FILTER_BIOSLAB");
    }
  },

  mounted() {
    this.$store.commit("Exam/Laboratory/RESET_EXAMINFO");
    this.form.requestInitialDate = moment()
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    this.form.requestFinalDate = moment().format("DD/MM/YYYY");

    const hasFilter = this.$store.getters["Exam/GET_FILTER_BIOSLAB"];
    if (hasFilter) {
      this.reSearch(hasFilter);
    }
  },

  methods: {
    verifyInclusionInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.requestInitialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.requestFinalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    selectLine(pItem, pIndex) {
      const isSameLine = this.selectedItem.leb_id === pItem.leb_id;

      if (isSameLine) {
        this.activeRow = null;
        this.selectedItem = {};
        this.patientInfo = {};
      } else {
        this.activeRow = pIndex;
        this.selectedItem = pItem;
        this.patientInfo = pItem.patient.person;
      }
    },

    newExamRequest() {
      this.$router.push({
        name: "exam.laboratory.details",
      });
    },

    onInputRadioCustom(pItem) {
      this.form.examStatus = pItem;
      this.$loader.start("Carregando pedidos...");
      this.selectedItem = {};
      this.$refs.RgSearch.submitForm(true);
      this.$loader.finish();
    },

    async reSearch(pFilter) {
      this.form = pFilter;
      this.form.examStatus = !pFilter.examStatus ? [] : pFilter.examStatus;

      if (this.form.vin_id) {
        this.$refs.requestedEmployee.forceSelection({
          pes_nome: this.form.suggestRequestedEmployee,
          vin_id: this.form.vin_id,
        });
      }

      if (this.form.patientId) {
        this.$refs.smartPerson.fillPatientById(this.form.patientId);
      }

      this.$nextTick(async () => {
        await this.$refs.RgSearch.performSearch();
      });
    },

    searchFilter(pData) {
      try {
        if (this.$refs.examList) {
          this.$refs.examList.scrollTop = 0;
        }

        return this.$store.dispatch(
          "Exam/Laboratory/SEARCH_BIOSLAB_LABORATORY_EXAM",
          { ...pData },
        );
      } catch (pErr) {
        this.$toaster.error("pErr", pErr);
      }
    },

    generateFilter() {
      const variable = {
        unitHealth: this.form.selectedUnity ? this.form.selectedUnity : null,
        sectorId: this.form.selectedSector ? this.form.selectedSector : null,
        examStatus:
          this.form.examStatus && this.form.examStatus.length > 0
            ? this.form.examStatus
            : null,
        cns: this.form.cns ? this.form.cns : null,
        patient:
          this.form.patient && this.form.patient.length > 0
            ? this.form.patient
            : null,
        patientId: this.form.patientId ? this.form.patientId : null,
        requestEmployeeId: this.form.leb_id_profissional_solicitante,
        solicitationNumber:
          this.form.solicitationNumber &&
          this.form.solicitationNumber.length > 0
            ? this.form.solicitationNumber
            : null,
        recordNumber:
          this.form.recordNumber && this.form.recordNumber.length > 0
            ? Number(this.form.recordNumber)
            : null,
        leb_nascimento_paciente:
          this.form.leb_nascimento_paciente &&
          this.form.leb_nascimento_paciente.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.leb_nascimento_paciente,
              )
            : null,
        requestInitialDate:
          this.form.requestInitialDate &&
          this.form.requestInitialDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.requestInitialDate,
              )
            : null,
        requestFinalDate:
          this.form.requestFinalDate && this.form.requestFinalDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.requestFinalDate,
              )
            : null,
        schedulingInitialDate:
          this.form.schedulingInitialDate &&
          this.form.schedulingInitialDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.schedulingInitialDate,
              )
            : null,
        schedulingFinalDate:
          this.form.schedulingFinalDate &&
          this.form.schedulingFinalDate.length > 0
            ? this.$utils.date.BrazilianDateToDatabase(
                this.form.schedulingFinalDate,
              )
            : null,
      };

      this.$store.commit("Exam/SET_FILTER_BIOSLAB", this.form);
      return variable;
    },

    viewExam(pItem) {
      this.$router.push({
        name: "exam.laboratory.edit-details",
        params: { examData: pItem },
      });
    },

    editPatient() {
      const patientId = this.patientInfo.pes_id;

      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.edit-patient`,
      });
    },

    patientCareHistory() {
      const patientInfo = this.patientInfo;
      this.$router.push({
        name: "exam.laboratory.patient-care-history",
        params: patientInfo,
      });
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source) {
        this.form.patientId = pValue.source.pes_id;
        this.form.cpf = pValue.source.cpf ? pValue.source.cpf.cpf_numero : null;
        this.form.nascimento = this.$utils.date.BrazilianDateFormat(
          pValue.source.pes_nascimento,
        );
        this.form.prontuario =
          pValue.source.patient && pValue.source.patient.pac_prontuario_unico
            ? pValue.source.patient.pac_prontuario_unico
            : null;
      } else {
        this.form.patientId = null;
        this.form.cpf = null;
        this.form.prontuario = null;
      }
    },

    selectingRequestedEmployee(pEmployee) {
      const employeeData = pEmployee.source;
      if (
        employeeData &&
        employeeData.employeeRelationship &&
        employeeData.employeeRelationship.employee
      ) {
        this.form.leb_id_profissional_solicitante =
          employeeData.employeeRelationship.employee.fun_id;
        this.form.vin_id = employeeData.vin_id;
      } else {
        this.form.leb_id_profissional_solicitante = null;
      }
    },

    formatDate(pValue, pStatus) {
      let date = "";

      if (pStatus === 1) {
        date = "Pedido não agendado";
      } else {
        date = pValue ? moment(pValue).format("DD/MM/YYYY") : "";
      }

      return date;
    },

    formatHour(pValue, pStatus) {
      const hour = pValue && pStatus !== 1 ? pValue.substring(5, 0) : "";

      return hour;
    },

    getColorCalendar(pStatus, pError) {
      const hasIntegrationError = pError?.length > 0;

      const isPendentByIntegrationError =
        pStatus === REQUESTED && hasIntegrationError;

      if (isPendentByIntegrationError) {
        return "#EDC76A";
      }

      switch (pStatus) {
        case REQUESTED:
          return "#56B0C9";
        case SCHEDULED:
          return "#B46CFF";
        case PENDING:
          return "#EDC76A";
        case PARTIALLY_ATTENDED:
          return "#FFA56A";
        case ATTENDED:
          return "#66D48F";
        default:
          return "#fff";
      }
    },

    download(pItem) {
      this.hasExamResult(pItem.biosLabInfo);
      this.getUrl(pItem.biosLabInfo);
    },

    getUrl(pItem) {
      var url = "";
      if (pItem) {
        if (pItem.length > 0) {
          const result = pItem.find((item) => {
            return item.lpe_url_resultado !== null;
          });

          url = result ? result.lpe_url_resultado : "";
        } else {
          url = pItem.lpe_url_resultado || "";
        }
      }
      const URL_BASE_REPORT = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_REPORT_URL}`;
      return URL_BASE_REPORT + url;
    },

    disablePrint(status) {
      return status === SCHEDULED;
    },

    disableError(pItem) {
      const status = pItem.leb_id_laboratoriais_status_pedido;

      const hasIntegrationError = pItem.biosLabIntegrationsError.some(
        (item) => item.inb_mensagem_erro,
      );

      const hasLaboratoryExamError = pItem.leb_mensagem_erro;

      const hasPatientExamError = pItem.biosLabInfo.some(
        (item) => item.lpe_mensagem_erro,
      );

      return (
        (status === PENDING || status === REQUESTED) &&
        (hasIntegrationError || hasLaboratoryExamError || hasPatientExamError)
      );
    },

    hasExamResult(pItem) {
      var hasResult = false;
      if (pItem.length > 0) {
        hasResult = pItem.some((item) => {
          return item.lpe_url_resultado && item.lpe_url_resultado.length > 0;
        });
      } else {
        hasResult =
          pItem.lpe_url_resultado && pItem.lpe_url_resultado.length > 0;
      }
      return hasResult;
    },

    scheduleExam(pItem) {
      this.$router.push({
        name: "exam.laboratory.schedule-exam-request",
        params: { examData: pItem },
      });
    },

    disableScheduleExam(pItem) {
      const status = pItem.leb_id_laboratoriais_status_pedido;
      return status !== REQUESTED;
    },

    // showModalTags(pItem) {
    //   this.showTags = true;
    //   this.selectedItem = pItem;
    //   this.examBioslabId = pItem.leb_id;
    // },

    showModalError(pItem) {
      this.showError = true;
      this.errors = [];

      const hasIntegrationError =
        pItem.biosLabIntegrationsError?.length > 0 &&
        pItem.biosLabIntegrationsError[0].inb_mensagem_erro;

      if (hasIntegrationError) {
        this.errors.push({
          error: hasIntegrationError,
        });
      } else {
        if (pItem.leb_mensagem_erro) {
          this.errors.push({ error: pItem.leb_mensagem_erro });
        }

        const hasPatientExamError = pItem.biosLabInfo.some(
          (item) => item.lpe_mensagem_erro,
        );

        if (hasPatientExamError) {
          pItem.biosLabInfo.forEach(async (exam) => {
            const hasError = exam.lpe_mensagem_erro?.length > 0;

            if (hasError) {
              this.errors.push({
                error: exam.lpe_mensagem_erro,
              });
            }
          });
        }
      }
    },

    closeTagModal() {
      this.showTags = false;
    },

    closeErrorModal() {
      this.showError = false;
    },

    closeLoader() {
      this.loading = false;
    },

    actionCleanSelection() {
      this.selectedItem = {};
      this.activeRow = null;
    },

    cleanForm() {
      this.loading = false;
      this.form.suggestRequestedEmployee = "";
      this.form.suggestDocument = "";
      this.form.radiostatus = "cpf";
      this.form = {
        examStatus: null,
        cns: null,
        cpf: null,
        nascimento: null,
        prontuario: null,
        leb_id_profissional_solicitante: null,
        patient: null,
        recordNumber: null,
        leb_nascimento_paciente: null,
        requestInitialDate: moment().subtract(1, "days").format("DD/MM/YYYY"),
        requestFinalDate: moment().format("DD/MM/YYYY"),
        schedulingInitialDate: null,
        schedulingFinalDate: null,
        selectedUnity: null,
        selectedSector: null,
        patientId: null,
      };
      this.mutableExamList = [];
      if (this.$refs.status) {
        this.$refs.status.clearRadio();
      }
      this.$store.commit("Exam/RESET_FILTER_BIOSLAB");
      this.$emit("clear-search", true);
    },
  },
};
</script>
