<template lang="html">
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6113 7C21.2682 7 22.6113 5.65685 22.6113 4C22.6113 2.34315 21.2682 1 19.6113 1C17.9545 1 16.6113 2.34315 16.6113 4C16.6113 5.65685 17.9545 7 19.6113 7ZM19.6113 8C21.8205 8 23.6113 6.20914 23.6113 4C23.6113 1.79086 21.8205 0 19.6113 0C17.4022 0 15.6113 1.79086 15.6113 4C15.6113 6.20914 17.4022 8 19.6113 8Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6113 19C21.2682 19 22.6113 17.6569 22.6113 16C22.6113 14.3431 21.2682 13 19.6113 13C17.9545 13 16.6113 14.3431 16.6113 16C16.6113 17.6569 17.9545 19 19.6113 19ZM19.6113 20C21.8205 20 23.6113 18.2091 23.6113 16C23.6113 13.7909 21.8205 12 19.6113 12C17.4022 12 15.6113 13.7909 15.6113 16C15.6113 18.2091 17.4022 20 19.6113 20Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.36626 12.4727C4.18077 12.2872 3.88002 12.2872 3.69452 12.4727C3.50903 12.6582 3.50903 12.959 3.69453 13.1444L6.71739 16.1673L3.6946 19.1901C3.5091 19.3756 3.5091 19.6763 3.6946 19.8618C3.88009 20.0473 4.18084 20.0473 4.36633 19.8618L7.38912 16.839L10.4119 19.8618C10.5974 20.0473 10.8981 20.0473 11.0836 19.8618C11.2691 19.6763 11.2691 19.3756 11.0836 19.1901L8.06086 16.1673L11.0837 13.1445C11.2692 12.959 11.2692 12.6582 11.0837 12.4727C10.8982 12.2872 10.5975 12.2872 10.412 12.4727L7.38912 15.4956L4.36626 12.4727Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.61133 7C9.26818 7 10.6113 5.65685 10.6113 4C10.6113 2.34315 9.26818 1 7.61133 1C5.95447 1 4.61133 2.34315 4.61133 4C4.61133 5.65685 5.95447 7 7.61133 7ZM7.61133 8C9.82047 8 11.6113 6.20914 11.6113 4C11.6113 1.79086 9.82047 0 7.61133 0C5.40219 0 3.61133 1.79086 3.61133 4C3.61133 6.20914 5.40219 8 7.61133 8Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Consistency",
};
</script>
