var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rg-new-schedule-button"},[_c('RgButton',{staticClass:"button",class:{
      'rg-new-schedule-button -small': _vm.small,
      'rg-new-schedule-button -medium': _vm.medium,
      'rg-new-schedule-button -large': _vm.large,
    },style:({
      background: _vm.backgroundColor,
    }),attrs:{"id":_vm.id,"disabled":_vm.disabled,"permission":_vm.permission,"data-id":_vm.dataId,"title":_vm.text},on:{"click":_vm.clicked}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.medium || _vm.large),expression:"medium || large"}],staticClass:"text"},[_c('span',[_vm._v(_vm._s(_vm.label))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.large),expression:"large"}],staticClass:"separator"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.medium),expression:"!medium"}],staticClass:"icon"},[_c('IconNewSchedule')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }