import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const itens_request = await AuthLegacyRequest.post(
    "farmacia/controller-solicitacao-transferencia/buscar-itens-por-solicitacao",
    variables,
  );

  return {
    itens_request: HandleResponse(itens_request.data).dados,
  };
};
