import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import IndividualEffectivation from "$exam/submodules/view/individual-effectivation/IndividualEffectivation";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/effectivation",
  name: "exam.effectivation",
  components: {
    default: IndividualEffectivation,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      {
        label: "Efetivação",
        link: "/exam/effectivation",
      },
    ],
  },
};
