export default {
  SELECT_REGISTER_BILLING_PROCEDURE: (state, data) => {
    state.selected_register_billing_procedure = data;
  },
  CLEAN_REGISTER_BILLING_PROCEDURE: (state) => {
    state.selected_register_billing_procedure = null;
  },
  SET_FILTER_BILLING_PROCEDURE: (state, data) => {
    state.filter_billing_procedure = data;
  },
  RESET_FILTER_BILLING_PROCEDURE: (state) => {
    state.filter_billing_procedure = null;
  },
  CLEAN_SUB_MODULE_BILLING_PROCEDURE: (state) => {
    state.selected_register_billing_procedure = null;
    state.filter_billing_procedure = null;
  },
};
