<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.6665 5H7.3335C3.28809 5 0 8.68266 0 13.2135V50.5465C0 55.0773 3.28809 58.76 7.3335 58.76H56.6665C60.7119 58.76 64 55.0773 64 50.5465V13.2135C64 8.68266 60.7119 5 56.6665 5ZM20 16.9465C23.6748 16.9465 26.6665 20.2977 26.6665 24.4135C26.6665 28.5288 23.6748 31.88 20 31.88C16.3252 31.88 13.3335 28.5288 13.3335 24.4135C13.3335 20.2977 16.3252 16.9465 20 16.9465ZM32 44.5735C32 45.81 31.104 46.8135 30 46.8135H10C8.896 46.8135 8 45.81 8 44.5735V43.08C8 38.5491 11.2881 34.8665 15.3335 34.8665H24.6665C28.7119 34.8665 32 38.5491 32 43.08V44.5735ZM54 46.8135H39.3335C38.2295 46.8135 37.3335 45.81 37.3335 44.5735C37.3335 43.337 38.2295 42.3335 39.3335 42.3335H54C55.104 42.3335 56 43.337 56 44.5735C56 45.81 55.104 46.8135 54 46.8135ZM54 34.8665H39.3335C38.2295 34.8665 37.3335 33.863 37.3335 32.6265C37.3335 31.39 38.2295 30.3865 39.3335 30.3865H54C55.104 30.3865 56 31.39 56 32.6265C56 33.863 55.104 34.8665 54 34.8665ZM54 22.92H39.3335C38.2295 22.92 37.3335 21.9165 37.3335 20.68C37.3335 19.4435 38.2295 18.44 39.3335 18.44H54C55.104 18.44 56 19.4435 56 20.68C56 21.9165 55.104 22.92 54 22.92Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DocumentPerson",
};
</script>
