<style src="./RgSuggest.scss" lang="scss" scoped></style>
<template>
  <fieldset
    ref="rgSuggest"
    :class="{ activated: hasFocus }"
    class="rg-input--component"
  >
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <div
          v-show="error.length > 0"
          :data-error="`${dataId}-error`"
          class="rg-input--alert-over-positioning"
        >
          <RgValidationAlert :alert="error" class="rg-input--icon" />
        </div>
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <div class="rg-input--search-icon">
            <IconSearch v-if="showIcon" class="icon-search" />
          </div>

          <div class="rg-input--side-action">
            <div v-show="false" class="rg-input--btn-calendar">
              <div class="rg-input-wait" />
            </div>
          </div>

          <div
            v-if="showCleanData"
            class="rg-input--remove-icon"
            title="Limpar"
            @click="cleanDataAct(true)"
          >
            <IconClose class="icon-remove" />
          </div>

          <input
            v-if="!mask"
            :id="id"
            ref="input"
            key="inputWithoutMask"
            v-model.trim="inputValue"
            v-shortkey="{
              enter: hasFocus ? ['enter'] : [''],
              esc: hasFocus ? ['esc'] : [''],
            }"
            v-debounce-directive="debounce"
            :data-id="dataId"
            :disabled="disableInput"
            :readonly="disableInput"
            :placeholder="placeholder"
            :tabindex="disableInput ? '-1' : tabIndex"
            :style="styles"
            :maxlength="maxlength"
            type="text"
            class="rg-input--typeahead-input"
            autocomplete="off"
            :class="{ 'on-error': hasError }"
            @shortkey="action"
            @focus="focusMe"
            @blur="validateBlur"
            @keydown.up="navigateUp"
            @keydown.down="navigateDown"
            @keydown.13="navigateChoose"
            @keydown.esc="closeSuggestion"
            @debounced="inputChanged"
          />

          <input
            v-else
            :id="id"
            ref="input"
            key="inputMask"
            v-model.trim="inputValue"
            v-shortkey="{
              enter: hasFocus ? ['enter'] : [''],
              esc: hasFocus ? ['esc'] : [''],
            }"
            v-mask="{ mask, tokens }"
            v-debounce-directive="debounce"
            :data-id="dataId"
            :disabled="disableInput"
            :readonly="disableInput"
            :placeholder="placeholder"
            :tabindex="disableInput ? '-1' : tabIndex"
            :style="styles"
            :maxlength="maxlength"
            type="text"
            class="rg-input--typeahead-input"
            :class="{ 'on-error': hasError }"
            autocomplete="off"
            @shortkey="action"
            @focus="focusMe"
            @blur="validateBlur"
            @keydown.up="navigateUp"
            @keydown.down="navigateDown"
            @keydown.13="navigateChoose"
            @keydown.esc="closeSuggestion"
            @debounced="inputChanged"
          />

          <div
            ref="box"
            class="rg-input--searchbox-result box-scroll"
            :style="{ 'max-height': `${setResponsiveSize()}px` }"
            :class="{ 'show-top': showBottom, 'show-bottom': !showBottom }"
            tabindex="1"
          >
            <ul
              v-show="(!escapeSearch && !disabled) || showLoader"
              ref="List"
              :style="{ 'max-height': `${setResponsiveSize()}px` }"
              @scroll.passive="handleScroll"
            >
              <slot />

              <li v-show="hasMoreValues || showLoader" class="line">
                <div class="loader">
                  <div class="rg-input-wait" />
                  <div class="text-loader">Buscando...</div>
                </div>
              </li>

              <li v-show="total === 0" class="line">
                <div class="empty">
                  <IconEmpty class="empty-svg" />
                  <div class="empty-text">
                    Não foram encontrados resultados.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </RgFormBase>

    <ModalConfirmFieldClearing
      :show="showConfirmation"
      :message="message"
      @getYes="cleanSuggest"
      @getOut="closeConfirmation"
      @close="closeConfirmation"
    />
  </fieldset>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgFormBase } from "~tokio/foundation/container";
import {
  IconSearch,
  DebounceDirective,
  IconClose,
  IconEmpty,
} from "~tokio/primitive/";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";

export default {
  name: "RgSuggests",
  components: {
    RgFormBase,
    IconSearch,
    RgValidationAlert,
    IconClose,
    ModalConfirmFieldClearing,
    IconEmpty,
  },
  directives: {
    debounceDirective: DebounceDirective,
    mask,
  },
  mixins: [RgValidatorMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
    tabIndex: {
      type: String,
      default: "0",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    min: {
      default: 0,
    },
    diffForLargeList: {
      type: Number,
      default: 0,
    },
    showIcon: {
      default: true,
    },
    mask: {
      default: null,
    },
    tokens: {
      type: Object,
      default: () => {
        return {
          "#": { pattern: /\d/ },
          X: { pattern: /[0-9a-zA-Z]/ },
          S: { pattern: /[a-zA-Z]/ },
          A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
          a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
          "!": { escape: true },
        };
      },
    },
    styles: {
      default: null,
    },
    debounce: {
      type: Number,
      default: 500,
    },
    searchOnFocus: {
      type: Boolean,
      default: false,
    },
    confirmToRemove: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Deseja mesmo limpar esse campo?",
    },
    maxCharBeforeResize: {
      type: Number,
      default: 60,
    },
    maxlength: {
      type: Number,
    },
    openOnlyTop: {
      type: Boolean,
      default: false,
    },
    autoSelectIfHasOne: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      inputValue: "",
      isSearching: false,
      escapeSearch: false,
      itemSelected: false,
      hasFocus: false,
      showConfirmation: false,
      showBottom: false,
      disableInput: this.disabled,
      total: null,
      timeout: null,
      limit: 20,
      showLoader: false,
      anotherRules: {
        forceSelection: (pData, pError) => {
          if (this.rules && this.rules.forceSelection === true) {
            return this.checkSelection(pData, pError);
          }
        },
      },
    };
  },

  computed: {
    validValue() {
      return this.inputValue;
    },

    hasMoreValues() {
      return this.total !== null && this.total > this.limit;
    },

    listTotalValue() {
      return this.total !== null && this.total === this.limit;
    },

    isRequired() {
      return this.rules && this.rules.required;
    },

    showCleanData() {
      const ret = this.itemSelected && !this.disableInput && this.inputValue;
      return ret;
    },
    hasError() {
      return this.error.length > 0;
    },
  },

  watch: {
    value(pValue) {
      const valueString = pValue ? pValue.toString() : pValue;

      if (!valueString) {
        this.cleanDataAct();
        this.$emit("clean");
      }

      this.inputValue = this.$utils.sanitize.removeEmoji(valueString);
      this.$parent.selectedItemIdx = 0;
      this.limit = 20;

      const validValue = valueString && valueString.length > 0;

      if (validValue && this.itemSelected) {
        this.validate();
      }

      this.setScrollTopZero();
      if (this.itemSelected) {
        this.escapeSearch = true;
      }
    },
    disabled(pValue) {
      this.disableInput = pValue;

      if (!pValue) {
        this.autoSelectWhenHasOne();
      }
    },
  },

  mounted() {
    // Props disabled is reactive, but when the first render start isnt working.
    // this way, is force to change the value when the first loader start
    if (this.disabled) {
      this.disableInput = false;
      this.disableInput = true;
    } else {
      this.autoSelectWhenHasOne();
    }
  },

  methods: {
    async autoSelectWhenHasOne() {
      this.limit = 20;
      this.resetSuggestionList();
      this.$parent.selectedItemIdx = 0;
      const data = await this.submitSearch(this.inputValue || "");
      const hasOneRow = data?.rows && data?.count === 1;
      if (hasOneRow) {
        this.forceSelection(data.rows[0], 0);
        this.escapeSearch = false;
      }
    },

    blockResponsive() {
      return window.innerHeight <= 632;
    },

    setResponsiveSize() {
      if (window.innerHeight <= 670) {
        return 180;
      } else if (window.innerHeight <= 865) {
        return 250;
      } else {
        return 285;
      }
    },

    openBottomOrTop() {
      if (this.openOnlyTop) {
        this.showBottom = true;
        return;
      }

      if (this.blockResponsive()) {
        this.showBottom = false;
        return;
      }

      this.$nextTick(() => {
        const hasModulebox = this.getComponent("Modulebox");
        if (hasModulebox) {
          const body = hasModulebox.$refs.myself;
          const bodyMapPosition = body.getBoundingClientRect().bottom;

          const size = this.setResponsiveSize();

          const elementMapPosition = this.$refs.rgSuggest.getBoundingClientRect();
          const elementPositionFull =
            elementMapPosition.bottom +
            this.$refs.rgSuggest.offsetHeight +
            size;

          this.showBottom = elementPositionFull >= bodyMapPosition;
        }
      });
    },

    getComponent(componentName) {
      let component = null;
      let parent = this.$parent;
      while (parent && !component) {
        if (parent.$options.name === componentName) {
          component = parent;
        }
        parent = parent.$parent;
      }
      return component;
    },

    cleanDataAct(isClicked = false) {
      if (isClicked && this.confirmToRemove) {
        this.showConfirmation = true;
      } else {
        this.cleanSuggest();
      }
    },

    closeModalConfirmation() {
      this.showConfirmation = false;
    },

    cleanSuggest() {
      this.inputValue = "";

      this.itemSelected = false;
      this.$parent.emitSelection({ source: "" });
      this.$emit("input", this.inputValue);
      this.$emit("clear");
      this.$parent.cleanValues();
    },

    handleScroll() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        if (this.listTotalValue || !this.total) return;

        const boxHeight = this.$parent.$el.querySelectorAll("ul")[0]
          .clientHeight;
        const boxScrollHeight = this.$parent.$el.querySelectorAll("ul")[0]
          .scrollHeight;
        const scrollPosition = this.$refs.List.scrollTop;
        const isInEnd =
          Math.ceil(boxScrollHeight - scrollPosition) - 1 <= boxHeight;
        try {
          if (isInEnd) {
            this.limit += 20;

            if (!this.hasMoreValues) {
              this.limit = this.total;
            }

            await this.submitSearch(this.inputValue, this.limit, true);
          }
        } catch (err) {
          console.error("Erro ao trazer mais dados", err);
        }
      }, this.debounce);
    },

    closeConfirmation() {
      this.showConfirmation = false;
    },

    navigateUp() {
      this.escapeSearch = false;
      if (this.$parent.selectedItemIdx === 0) return;
      const prevItem = this.$parent.selectedItemIdx - 1;
      if (prevItem > -1) this.$parent.selectedItemIdx = prevItem;
      this.scrollPositionAdjust();
    },

    navigateDown() {
      this.escapeSearch = false;
      const nextItem = this.$parent.selectedItemIdx + 1;

      if (this.$parent.suggestionList.length > nextItem) {
        this.$parent.selectedItemIdx = nextItem;
      }

      this.scrollPositionAdjust();
    },

    navigateChoose(e) {
      if (!this.$parent.suggestionList) return;

      const itemSelected = this.$parent.suggestionList[
        this.$parent.selectedItemIdx
      ];

      if (!itemSelected) return false;

      this.itemSelected = true;
      e.preventDefault();
      e.stopPropagation();
      this.$parent.selectingItemFromSuggestList(itemSelected);
      this.escapeSearch = true;
    },

    closeSuggestion() {
      this.escapeSearch = true;
    },

    async focusMe() {
      this.escapeSearch = false;
      this.hasFocus = true;

      if (
        this.searchOnFocus ||
        (this.inputValue && this.inputValue.length > 0)
      ) {
        this.openBottomOrTop();
        this.setScrollTopZero();
        this.limit = 20;
        this.resetSuggestionList();
        this.$parent.selectedItemIdx = 0;
        await this.submitSearch(this.inputValue || "", null, true);
      } else {
        this.escapeSearch = true;
      }
    },

    resetSuggestionList() {
      this.$parent.suggestionList = [];
    },

    async inputChanged(pValue) {
      if (this.inputValue === this.$parent.inputValue) return false;
      this.total = null;
      this.itemSelected = false;
      // existe um bug que faz com que o evento selected seja emitido sempre que o input muda
      // a chamada para emitSelection resolve o problema de o input ser obrigatório
      this.$parent.emitSelection({ source: {} });
      this.$emit("input", this.inputValue);
      this.resetSuggestionList();
      if (this.inputValue && this.inputValue.length > this.min) {
        await this.submitSearch(this.inputValue || "", null, true);
        this.escapeSearch = false;
      }
    },

    getElementsToAdjustPosition() {
      const elementItem = this.$parent.$el.querySelectorAll("li")[
        this.$parent.selectedItemIdx
      ];
      const elementContainer = this.$parent.$el.querySelectorAll("ul")[0];
      if (!elementItem || !elementContainer) return { elementItem: false };

      return { elementItem, elementContainer };
    },

    scrollPositionAdjust() {
      const {
        elementItem,
        elementContainer,
      } = this.getElementsToAdjustPosition();

      if (!elementItem) return false;

      const elementTop = elementItem.offsetTop;
      const marginTop =
        elementTop - elementItem.offsetHeight + this.diffForLargeList;
      elementContainer.scrollTop = marginTop;
    },

    setScrollTopZero() {
      const elementContainer = this.$parent.$el.querySelectorAll("ul")[0];
      elementContainer.scrollTop = 0;
    },

    async validateBlur(e) {
      this.hasFocus = false;
      this.limit = 20;
      this.total = null;

      this.$nextTick(() => {
        this.$emit("input", this.inputValue);
        this.validate();
        this.showWait = false;
      });

      await this.forceSelection(this.inputValue);
    },

    setFocus() {
      if ("input" in this.$refs) {
        this.hasFocus = true;
        this.$refs.input.focus();
      }
    },

    forceSelection(pData) {
      const hasData = pData && !this.itemSelected;

      if (hasData && this.autoSelectIfHasOne) {
        if (
          this.$parent.suggestionList &&
          this.$parent.suggestionList.length === 1
        ) {
          const itemSelected = this.$parent.suggestionList[
            this.$parent.selectedItemIdx
          ];
          this.$emit("itemSelected");
          if (!itemSelected) return false;
          this.itemSelected = true;
          this.$parent.selectingItemFromSuggestList(itemSelected);
          this.escapeSearch = true;
        }
      }
    },

    checkSelection(pData, pError) {
      if (pData && !this.itemSelected) {
        pError.push("A seleção de um item é obrigatório");
        return false;
      }
      return true;
    },

    action(event) {
      switch (event.srcKey) {
        case "enter":
          this.navigateChoose(event);
          break;
        case "esc":
          this.closeSuggestion();
          break;
      }
    },

    async submitSearch(pSearchWord, pLimit = null, loader = false) {
      try {
        if (loader) {
          this.showLoader = true;
        }

        const hasEmoji = this.$utils.sanitize.hasEmoji(pSearchWord);

        const data = hasEmoji
          ? { rows: [], count: 0 }
          : await this.$parent.search(pSearchWord, pLimit || 20);

        this.total = data?.count;
        if (data?.length === 0) {
          this.total = 0;
        }

        return data;
      } catch (pErr) {
        console.log(pErr.message);
      } finally {
        if (loader) {
          this.showLoader = false;
        }
      }
    },
  },
};
</script>
