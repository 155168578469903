import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "pacientes/controller-paciente/buscar-historico-geral-paciente",
    variables,
  )
    .then(({ data }) => {
      const total = data.dados.total;
      data = HandleResponse(data);
      data.dados = data.dados.recordSet;

      data.dados.map((item) => {
        item.dataHora = item.data + " " + item.hora;
      });

      return {
        total,
        data: data.dados,
      };
    })
    .catch((pErr) => {
      throw pErr;
    });
};
