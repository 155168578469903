<template lang="html">
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.35273 6.58736C4.71971 6.95434 5.31482 6.9544 5.68186 6.58736L9.06018 3.20892L9.06024 13.2347C9.06024 13.7538 9.48104 14.1746 10.0001 14.1746C10.5191 14.1746 10.9399 13.7538 10.9399 13.2347L10.9399 3.20873L14.3184 6.58724C14.6854 6.95428 15.2805 6.95428 15.6476 6.58723C16.0146 6.22019 16.0146 5.62514 15.6476 5.2581L10.6646 0.275283C10.4884 0.098968 10.2493 -3.00216e-05 10.0001 -2.99998e-05C9.75084 -2.9978e-05 9.51174 0.0990301 9.33549 0.275283L4.35267 5.25823C3.98569 5.62527 3.98569 6.22032 4.35273 6.58736Z"
      fill="white"
    />
    <path
      d="M19.0601 18.1204H0.939847C0.420801 18.1204 0 18.5412 0 19.0602C0 19.5793 0.420801 20.0001 0.939847 20.0001H19.0601C19.5791 20.0001 20 19.5793 20 19.0602C20 18.5411 19.5791 18.1204 19.0601 18.1204Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowUpload",
};
</script>
