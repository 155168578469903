import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  SemPermissaoEscalasAgendamentoException: `Usuário não tem permissão para visualizar nenhum tipo de escala.`,
};

export default async (variables) => {
  return AuthLegacyRequest.post(
    "funcionarios/controller-atendente/combobox-atendentes-ativos-inativos-por-unidade-setor-ocupacao",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
