import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import UnifyPatientRegister from "$person/patient/view/unify-patient-register/UnifyPatientRegister";

export default {
  path: "/person/patient/unify/register",
  name: "person.patient.unify.register",
  components: {
    default: UnifyPatientRegister,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastro", link: OpenMenuModule("register") },
      { label: "Paciente", link: OpenSubModule("register") },
      { label: "Unificação de Pacientes", link: "/person/patient/unify" },
      { label: "Unificar Cadastros", link: "/person/patient/unify/register" },
    ],
  },
};
