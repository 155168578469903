<template>
  <section class="foundation-externally-schedule">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :build-filter="generateFilter"
      :clearFunction="actionCleanForm"
      :max-register="40"
      :item-height="29.5"
      :search-function="actionSearchFilter"
      class="search-container-area"
      resultTitle="Pacientes Agendados Externamente"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="foundation-externally-schedule-filter">
        <div class="grid">
          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              id="patient-name"
              v-model="form.patientName"
              :disabled="!!form.pac_id"
              :class="{ disable: !!form.pac_id }"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              id="specific-patient"
              ref="patient"
              v-model="form.specificPatient"
              :disabled="form.patientName.length > 0"
              :with-patient-module="permissionSuggest"
              :enabled-patient="false"
              filterOnlyDocument
              class="inputitem"
              @selected="selectingPatient"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados da Regulação</span>
          </div>

          <div class="selectinput">
            <RgSelectUnithealth
              id="requesting-unit"
              v-model="form.requestingUnit"
              :module-id="moduleId"
              :externally="true"
              label="Unidade de Saúde Solicitante"
              class="inputitem"
              :default-text="'Todas'"
            />
          </div>

          <div class="selectinput">
            <RgSelectExternalSchedulingProcedures
              v-if="moduleType === this.TYPE_MODULE.EXAM"
              id="procedure"
              v-model="form.procedure"
              class="inputitem"
              default-text="Todos"
            />

            <RgSelectOccupationScheduleExternally
              v-if="moduleType === this.TYPE_MODULE.APPOINTMENT"
              id="procedure-externally"
              v-model="form.soc_id"
              default-text="Todas"
            />

            <RgSuggestTypeBed
              v-if="moduleType === this.TYPE_MODULE.HOSPITALIZATION"
              id="bed-type"
              v-model="form.bedType"
              :rules="{ forceSelection: true }"
              class="inputitem"
              placeholder="Todos"
              @selected="selectingBedType"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Agendamento</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-date"
                v-model="form.initialDate"
                label="Data Inicial"
                class="date"
                :rules="{ fn: validateDate }"
              />

              <RgInputDate
                id="final-date"
                v-model="form.finalDate"
                label="Data Final"
                class="date"
                :rules="{ fn: validateDate }"
              />
            </div>

            <div class="selectinput">
              <RgSelectExternalLocation
                id="destiny"
                v-model="form.aex_id"
                label="Destino"
                class="inputitem"
                default-text="Todos"
                :id-modulo="moduleId"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="foundation-externally-schedule-search">
        <SmartTable
          ref="smartTable"
          :name="getModuleType().smartTableName"
          :columns="getModuleType().columnSmartTable"
          :dynamic-height="getSpaceTable"
          :body="mutableList"
          :total="Number(totalList)"
          :initial-columns="5"
          :show-pagination="false"
          toggle-selected
          class="smart-table"
          @getLine="selectRowData"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgEditButton
              id="edit-schedule"
              :disabled="!hasSelectedLine"
              title="Editar Agendamento Externo"
              @click="openModalEditExternallySchedule"
            />
            <RgLessButton
              id="delete-schedule"
              :disabled="!hasSelectedLine"
              title="Excluir Agendamento Externo"
              @click="openModalDeleteExternalSchedule"
            />
            <RgDropdown
              id="patient-options"
              :disabled="!hasSelectedLine"
              :item="selectedRow || {}"
              :action-options="itemActionOptionsPerson(selectedRow)"
              small
            >
              <IconCard slot="icon" />
            </RgDropdown>
            <RgPrinterButton
              id="print-patients"
              title="Lista de Pacientes Agendados"
              @click="printPatientsList"
            />
          </div>
        </SmartTable>
      </div>
    </RgSearch>

    <ModalEditExternallySchedule
      id="modal-externally-schedule"
      :show="modalEditExternallySchedule"
      :externallyScheduleData="selectedRow"
      :moduleId="moduleId"
      :moduleType="moduleType"
      @reSearch="reSearchTable"
      @close="closeModalEditExternallySchedule"
    />

    <ModalConfirmDeletion
      id="modal-deletion"
      v-bind="propsModalDeleteExternalSchedule"
      @close="closeModalDeleteExternalSchedule"
    >
      <div slot="more-info" class="delete-schedule">
        <hr class="divisor" />
        <div>
          <span class="info-title">Tipo de exclusão do agendamento</span>
        </div>
        <div class="delete-options">
          <RgRadioCustom
            id="delete-schedule"
            ref="RgRadioCustomDelete"
            :list="RADIO_DATA_DELETE"
            :value="deleteSchedule"
            uniqueKey="id"
            canUnselect
            @input="onInputRadioCustomDelete"
          />
        </div>
      </div>
    </ModalConfirmDeletion>

    <ModalPatientImageInRow
      id="modal-patient-image-row"
      :show="modalPatientImageInRow"
      :modId="moduleId"
      :fill-id="selectedRow && Number(selectedRow.fil_id)"
      hide-buttons
      view-only
      @close="closeModalPatientImageInRow"
    />

    <ModalObservationQueue
      id="modal-observation-queue"
      :show="modalObservationQueue"
      :patientData="selectedRow"
      :modId="moduleId"
      @close="closeModalObservationQueue"
    />
    <ModalObservationSchedule
      id="modal-observation-schedule"
      :show="modalObservationSchedule"
      :externallyScheduleData="selectedRow"
      @close="closeModalObservationSchedule"
    />
    <ModalInformationPrint
      id="modal-information-print"
      :show="modalInformationPrint"
      :list-size="totalList"
      @close="closeModalInformationPrint"
    />
    <ModalPatientDuplicationQueue
      id="modal-patient-duplication-queue"
      :show="modalPatientDuplicationQueue"
      :module-id="moduleId"
      :patient-name="patientDuplicationName"
      :queue-info="queueDuplicationInfo"
      :block-duplication="blockPatientDuplication"
      @save="saveDuplication"
      @close="closeModalPatientDuplicationQueue"
      @cancel="closeModalPatientDuplicationQueue"
    />
  </section>
</template>

<script>
import ModalObservationQueue from "~tokio/primitive/modal/modal-observation-queue/ModalObservationQueue";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import {
  RgInputDate,
  RgInput,
  RgEditButton,
  RgLessButton,
  RgDropdown,
  IconCard,
  RgPrinterButton,
  RgSelectUnithealth,
  RgSelectExternalLocation,
  RgRadioCustom,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson.vue";
import RgSelectExternalSchedulingProcedures from "$exam/common/component/rg-select-external-scheduling-procedures/RgSelectExternalSchedulingProcedures";
import RgSelectOccupationScheduleExternally from "$appointment/common/component/rg-select-occupation-schedule-externally/RgSelectOccupationScheduleExternally";
import ModalEditExternallySchedule from "~tokio/foundation/pages/patient/foundation-externally-schedule/components/modal/modal-edit-externally-schedule/ModalEditExternallySchedule";
import ModalObservationSchedule from "~tokio/foundation/pages/patient/foundation-externally-schedule/components/modal/modal-observation-schedule/ModalObservationSchedule";
import {
  ModalConfirmDeletion,
  ModalPatientImageInRow,
  ModalInformationPrint,
  ModalPatientDuplicationQueue,
} from "~tokio/primitive/modal";
import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import moment from "moment";

const FORM_BASE = {
  patientName: "",
  specificPatient: null,
  specificPatientName: "",
  pes_id: null,
  pac_id: null,
  uns_id: null,
  soc_id: null,
  procedure: "",
  initialDate: "",
  finalDate: "",
  aex_id: null,
  requestingUnit: null,
  bedTypeId: null,
  bedType: null,
};

export default {
  name: "FoundationExternallySchedule",

  components: {
    RgSearch,
    RgInputDate,
    RgInput,
    RgSuggestSmartPerson,
    RgSelectUnithealth,
    RgSelectExternalLocation,
    RgSelectOccupationScheduleExternally,
    RgSelectExternalSchedulingProcedures,
    ModalEditExternallySchedule,
    ModalObservationSchedule,
    ModalConfirmDeletion,
    ModalPatientImageInRow,
    ModalInformationPrint,
    SmartTable,
    RgEditButton,
    RgLessButton,
    RgDropdown,
    IconCard,
    RgPrinterButton,
    ModalObservationQueue,
    ModalPatientDuplicationQueue,
    RgSuggestTypeBed,
    RgRadioCustom,
  },

  props: {
    moduleType: {
      type: Number,
      default: 2,
    },
    printHtml: Object,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      mutableList: [],
      tableHeight: null,
      totalList: 0,
      selectedRow: null,
      modalDeleteExternalSchedule: false,
      modalPatientImageInRow: false,
      modalEditExternallySchedule: false,
      modalInformationPrint: false,
      modalObservationQueue: false,
      deleteSchedule: 1,
      modalPatientDuplicationQueue: false,
      patientDuplicationName: "",
      queueDuplicationInfo: "",
      blockPatientDuplication: false,
      reasonReturn: "",
      validateDuplication: true,
      modalObservationSchedule: false,
    };
  },

  computed: {
    hasSelectedLine() {
      return this.selectedRow;
    },

    permissionSuggest() {
      return this.getModuleType().permissionSuggest;
    },

    propsModalDeleteExternalSchedule() {
      const title = "Excluir o agendamento";
      const subtitle =
        "Escolha entre as opções “Excluir somente o agendamento” ou “Excluir o agendamento e <br> retornar o paciente para a fila”, selecionando um dos filtros abaixo do nome do paciente:";
      const bodyText = this.selectedRow && this.selectedRow.pes_nome;
      const show = this.modalDeleteExternalSchedule;
      const maxlength = 255;
      const confirm = (reason) => this.deleteExternalSchedule(reason);

      return {
        show,
        confirm,
        title,
        subtitle,
        bodyText,
        maxlength,
      };
    },

    getSpaceTable() {
      return this.tableHeight;
    },

    moduleId() {
      return this.getModuleType().moduleId;
    },

    getUnitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },

    getUnitHealthName() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
    },
  },

  watch: {
    "form.pac_id"() {
      this.form.patientName = "";
    },

    "form.patientName"(pValue) {
      this.form.pac_id = 0;
      this.form.specificPatientName = "";
      this.form.specificPatient = null;
    },
  },

  created() {
    this.RADIO_DATA_DELETE = [
      { title: "Somente excluir", blue: true, id: 1 },
      { title: "Excluir e retornar para a fila", blue: true, id: 2 },
    ];
    this.COLUMN_EXAM = [
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Telefone", key: "tel_num", align: "left" },
      { name: "Data", key: "aex_data" },
      { name: "Procedimento", key: "fle_nome_procedimento", align: "left" },
      { name: "Subprocedimento", key: "subProcedimento", align: "left" },
      { name: "Destino", key: "lex_nome", align: "left" },
      {
        name: "Unidade Solicitante",
        key: "unidade_solicitante",
        align: "left",
      },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
    this.COLUMN_APPOINTMENT = [
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Telefone", key: "tel_num", align: "left" },
      { name: "Data", key: "aex_data" },
      { name: "Ocupação", key: "flc_nome_ocupacao", align: "left" },
      { name: "Destino", key: "lex_nome", align: "left" },
      {
        name: "Unidade Solicitante",
        key: "unidade_solicitante",
        align: "left",
      },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
    this.COLUMN_HOSPITALIZATION = [
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Telefone", key: "tel_num", align: "left" },
      { name: "Tipo de Leito", key: "itl_nome", align: "left" },
      { name: "Local de Destino", key: "lex_nome", align: "left" },
      { name: "Usuário", key: "usu_nome", align: "left" },
      { name: "Data do Agendamento", key: "aex_data" },
    ];

    this.TYPE_MODULE = {
      APPOINTMENT: 1,
      EXAM: 2,
      HOSPITALIZATION: 3,
    };
  },

  beforeDestroy() {
    const pathName = this.getModuleType().beforeDestroyPathName;
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      `/${pathName}/schedule/externally-schedule`,
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(this.getModuleType().beforeDestroyRoute);
    }
  },

  mounted() {
    this.fillFilter();
    this.handleAfterSearchFilter();
  },

  methods: {
    onInputRadioCustomDelete(item) {
      if (item) {
        this.deleteSchedule = item.id;
      } else {
        this.deleteSchedule = 1;
      }
    },
    actionAfterSearchFilter() {
      this.handleAfterSearchFilter();
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          this.getModuleType().fillFilterRoute
        ];

        if (existFilterData) {
          this.$loader.start("Carregando dados da última busca...");

          this.form = {
            ...this.form,
            ...existFilterData,
          };

          if (this.$refs.patient && existFilterData.pes_id) {
            this.$refs.patient.fillPatientById(existFilterData.pes_id);
          } else {
            this.$nextTick(() => {
              this.form.patientName = existFilterData.patientName;
            });
          }

          if (this.$refs.SmartTable) {
            this.$refs.SmartTable.cleanSelectRow();
          }
          await this.$refs.RgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    generateFilter() {
      const variables = {
        arrFiltro: {
          itl_id_internacoes_tipos_leito: this.form.bedTypeId,
          aex_data: 0,
          aex_id: 0,
          itl_id: 0,
          lex_id: this.form.aex_id,
          lex_nome: null,
          pac_nome: this.form.specificPatientName || this.form.patientName,
          periodoFinal: this.form.finalDate,
          periodoInicial: this.form.initialDate,
          soc_id: this.form.soc_id,
          stp_nome: this.form.procedure,
          usu_nome: null,
          mod_id: this.moduleId,
          id_unidade_solicitante: this.form.requestingUnit,
        },
      };

      this.$store.commit(this.getModuleType().generateFilterRoute, this.form);

      return variables;
    },

    getCountValue(pValue) {
      this.totalList = Number(pValue) || 0;
    },

    selectRowData(data) {
      this.selectedRow = data;
    },

    closeModalPatientDuplicationQueue() {
      this.modalPatientDuplicationQueue = false;
      this.deleteSchedule = 1;
    },
    saveDuplication() {
      this.modalPatientDuplicationQueue = false;
      this.deleteExternalSchedule(this.reasonReturn, false);
    },
    openModalEditExternallySchedule() {
      this.modalEditExternallySchedule = true;
    },

    closeModalEditExternallySchedule() {
      this.modalEditExternallySchedule = false;
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    reSearchTable() {
      this.$refs.RgSearch.submitForm(false, true);
    },

    actionSearchFilter(pData) {
      try {
        const path = this.getModuleType().actionSearchFilterRoute;

        return this.$store.dispatch(path, pData);
      } catch (e) {
        this.$toaster.warning(e.message);
      }
    },

    async printPatientsList() {
      try {
        this.$loader.start("Preparando impressão...");

        if (this.totalList > 1000) {
          this.modalInformationPrint = true;
          return;
        }

        const clientName = this.$store.getters["Login/GET_CLIENT_NAME"];

        const html = await this.printHtml.patientsList(
          this.generateFilter(),
          clientName,
        );
        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      } finally {
        this.$loader.finish();
      }
    },

    getPayloadFilter(filter) {
      return {
        aex_data: 0,
        aex_id: 0,
        itl_id: 0,
        lex_id: this.form.aex_id,
        lex_nome: null,
        pac_nome: this.form.specificPatientName || this.form.patientName,
        periodoFinal: this.form.finalDate,
        periodoInicial: this.form.initialDate,
        soc_id: this.form.soc_id,
        stp_nome: this.form.procedure,
        usu_nome: null,
        mod_id: this.moduleId,
      };
    },

    actionsModalDuplicity(data) {
      const { exception } = data;

      const EXCEPTION_BLOCK_EXAME =
        "BloquearDuplicidadeAoInserirFilaExameException";
      const EXCEPTION_INFORM_EXAME =
        "InformarDuplicidadeAoInserirFilaExameException";
      const EXCEPTION_BLOCK_CONSULTA =
        "BloquearDuplicidadeAoInserirFilaConsultaException";
      const EXCEPTION_INFORM_CONSULTA =
        "InformarDuplicidadeAoInserirFilaConsultaException";
      const EXCEPTION_BLOCK_HOSPITALIZATION =
        "BloquearDuplicidadeAoInserirFilaInternacaoException";
      const EXCEPTION_INFORM_HOSPITALIZATION =
        "InformarDuplicidadeAoInserirFilaInternacaoException";

      const isExceptionDuplicityExam =
        exception === EXCEPTION_BLOCK_EXAME ||
        exception === EXCEPTION_INFORM_EXAME;
      const isExceptionDuplicityAppointment =
        exception === EXCEPTION_BLOCK_CONSULTA ||
        exception === EXCEPTION_INFORM_CONSULTA;
      const isExceptionDuplicityHospitalization =
        exception === EXCEPTION_BLOCK_HOSPITALIZATION ||
        exception === EXCEPTION_INFORM_HOSPITALIZATION;

      if (isExceptionDuplicityExam) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.pes_nome;
        this.queueDuplicationInfo = this.selectedRow.fle_nome_procedimento;

        if (exception === EXCEPTION_INFORM_EXAME) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK_EXAME) {
          this.blockPatientDuplication = true;
        }
      }
      if (isExceptionDuplicityAppointment) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.pes_nome;
        this.queueDuplicationInfo = this.selectedRow.flc_nome_ocupacao;

        if (exception === EXCEPTION_BLOCK_CONSULTA) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_INFORM_CONSULTA) {
          this.blockPatientDuplication = true;
        }
      }
      if (isExceptionDuplicityHospitalization) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.pes_nome;
        this.queueDuplicationInfo = this.selectedRow.itl_nome;

        if (exception === EXCEPTION_INFORM_HOSPITALIZATION) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK_HOSPITALIZATION) {
          this.blockPatientDuplication = true;
        }
      }
    },

    async deleteExternalSchedule(pReason, validarDuplicidade = true) {
      try {
        this.$loader.start();
        this.reasonReturn = pReason;
        this.validateDuplication = validarDuplicidade;
        const path = this.getModuleType().deleteExternalScheduleRoute;

        const variables = {
          intIdAgendamento: Number(this.selectedRow.aex_id),
          idModulo: this.moduleId,
          motivo: pReason,
          voltarPraFila: this.deleteSchedule !== 1,
          validarDuplicidade: validarDuplicidade,
        };

        await this.$store.dispatch(path, variables);

        this.$toaster.success("Operação realizada com sucesso");
        this.reasonReturn = "";
        this.deleteSchedule = 1;
        this.reSearchTable();
      } catch (pErr) {
        const { esus_response } = pErr;

        if (esus_response?.hasError) {
          if (this.validateDuplication) {
            this.actionsModalDuplicity(esus_response);
            if (this.modalPatientDuplicationQueue) return;
          }

          this.$toaster.error(
            "Não foi possível realizar operação: ",
            esus_response.message,
          );

          return;
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Não foi possível realizar operação: ",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },

    itemActionOptionsPerson(pItem) {
      return [
        {
          label: "Arquivos do paciente na fila",
          action: this.openModalPatientImageInRow,
        },
        {
          label: "Observações do paciente na fila",
          action: this.openModalObservationQueue,
        },
        {
          label: "Observações do Agendamento",
          action: this.openModalObservationSchedule,
        },
      ];
    },

    openModalObservationSchedule() {
      this.modalObservationSchedule = true;
    },
    closeModalObservationSchedule() {
      this.modalObservationSchedule = false;
    },

    openModalObservationQueue() {
      this.selectedRow.uns_nome = this.getUnitHealthName;
      this.modalObservationQueue = true;
    },

    closeModalObservationQueue() {
      this.modalObservationQueue = false;
    },

    openModalDeleteExternalSchedule() {
      this.modalDeleteExternalSchedule = true;
    },

    closeModalDeleteExternalSchedule() {
      if (!this.modalPatientDuplicationQueue) {
        this.deleteSchedule = 1;
      }
      this.modalDeleteExternalSchedule = false;
    },

    closeModalInformationPrint() {
      this.modalInformationPrint = false;
    },

    actionCleanForm() {
      this.mutableList = [];
      this.totalList = 0;
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);
      if (this.$refs.SmartTable) {
        this.$refs.SmartTable.cleanSelectRow();
      }
      this.selectedRow = null;
      this.$store.commit(this.getModuleType().actionCleanFormRoute);
    },

    validateDate(value, errors) {
      if (this.form.initialDate && this.form.finalDate) {
        const initial = moment(this.form.initialDate, "DD/MM/YYYY");
        const final = moment(this.form.finalDate, "DD/MM/YYYY");

        const isValidInitialValidity = moment(initial).isSameOrBefore(final);
        if (!isValidInitialValidity) {
          errors.push("A data inicial não pode ser maior que a data final.");
          return false;
        }
      }
      return true;
    },

    handleAfterSearchFilter() {
      // GET DYNAMIC HEIGHST
      if (this.$refs.RgSearch?.$refs.listContent) {
        const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;
        this.tableHeight = searchArea - 40;

        if (this.$refs.smartTable) {
          this.$refs.smartTable.setScrollTopZero();
          this.$refs.smartTable.cleanSelectRow();
        }
        this.selectedLine = null;
        this.selectedRow = null;
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source && pValue.source.patient) {
        const { pes_id, pes_nome } = pValue.source;
        const { pac_id } = pValue.source.patient;
        this.form.pac_id = pac_id;
        this.form.pes_id = pes_id;
        this.form.specificPatientName = pes_nome;
      } else {
        this.form.pes_id = 0;
        this.form.pac_id = 0;
        this.form.specificPatientName = "";
      }
    },

    selectingBedType(pBedType) {
      const source = pBedType && pBedType.source;
      if (source) {
        this.form.bedTypeId = source.itl_id;
      } else {
        this.form.bedTypeId = null;
      }
    },

    getModuleType() {
      switch (this.moduleType) {
        case this.TYPE_MODULE.APPOINTMENT:
          return {
            smartTableName: "AppointmentFoundationExternallySchedule",
            columnSmartTable: this.COLUMN_APPOINTMENT,
            permissionSuggest: ["appointment"],
            moduleId: this.$store.state.Login.route_module_map.appointment,
            beforeDestroyPathName: "appointment",
            beforeDestroyRoute:
              "Appointment/Schedule/RESET_FILTER_EXTERNALLY_SCHEDULE",
            fillFilterRoute:
              "Appointment/Schedule/GET_FILTER_EXTERNALLY_SCHEDULE",
            generateFilterRoute:
              "Appointment/Schedule/SET_FILTER_EXTERNALLY_SCHEDULE",
            actionSearchFilterRoute:
              "Appointment/Schedule/SEARCH_EXTERNALLY_SCHEDULE",
            deleteExternalScheduleRoute:
              "Appointment/Schedule/REMOVE_EXTERNALLY_SCHEDULE",
            actionCleanFormRoute:
              "Appointment/Schedule/RESET_FILTER_EXTERNALLY_SCHEDULE",
          };
        case this.TYPE_MODULE.EXAM:
          return {
            smartTableName: "ExamFoundationExternallySchedule",
            columnSmartTable: this.COLUMN_EXAM,
            permissionSuggest: ["exame2"],
            moduleId: this.$store.state.Login.route_module_map.exam,
            beforeDestroyPathName: "exam",
            beforeDestroyRoute:
              "Exam/Schedule/RESET_FILTER_EXTERNALLY_SCHEDULE",
            fillFilterRoute: "Exam/Schedule/GET_FILTER_EXTERNALLY_SCHEDULE",
            generateFilterRoute: "Exam/Schedule/SET_FILTER_EXTERNALLY_SCHEDULE",
            actionSearchFilterRoute: "Exam/Schedule/SEARCH_EXTERNALLY_SCHEDULE",
            deleteExternalScheduleRoute:
              "Exam/Schedule/REMOVE_EXTERNALLY_SCHEDULE",
            actionCleanFormRoute:
              "Exam/Schedule/RESET_FILTER_EXTERNALLY_SCHEDULE",
          };
        case this.TYPE_MODULE.HOSPITALIZATION:
          return {
            smartTableName: "HospitalizationFoundationExternallySchedule",
            columnSmartTable: this.COLUMN_HOSPITALIZATION,
            permissionSuggest: ["hospitalization"],
            moduleId: this.$store.state.Login.route_module_map.hospitalization,
            beforeDestroyPathName: "hospitalization",
            beforeDestroyRoute:
              "Hospitalization/Queue/RESET_FILTER_EXTERNALLY_SCHEDULE",
            fillFilterRoute:
              "Hospitalization/Queue/GET_FILTER_EXTERNALLY_SCHEDULE",
            generateFilterRoute:
              "Hospitalization/Queue/SET_FILTER_EXTERNALLY_SCHEDULE",
            actionSearchFilterRoute:
              "Hospitalization/Queue/SEARCH_EXTERNALLY_SCHEDULE",
            deleteExternalScheduleRoute:
              "Hospitalization/Queue/REMOVE_EXTERNALLY_SCHEDULE",
            actionCleanFormRoute:
              "Hospitalization/Queue/RESET_FILTER_EXTERNALLY_SCHEDULE",
          };
      }
    },
  },
};
</script>
