var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modulebox',{ref:"modulebox",staticClass:"export-billing",attrs:{"title":"Exportar Faturamento por Competência"}},[_c('RgValidatorForm',{ref:"validator",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.exportBilling.apply(null, arguments)}}},[_c('FormBase',{staticClass:"form-shipping-export",attrs:{"title":"Dados da Remessa"}},[_c('section',{staticClass:"group-form"},[_c('RgInput',{ref:"cnes",class:{ disable: true },attrs:{"label":"CNES","rules":{ required: true },"disabled":true},model:{value:(_vm.form.cnes),callback:function ($$v) {_vm.$set(_vm.form, "cnes", $$v)},expression:"form.cnes"}}),_c('RgInput',{ref:"unitHealth",class:{ disable: true },attrs:{"label":"Unidade de Saúde","rules":{ required: true },"disabled":true},model:{value:(_vm.form.unitHealth),callback:function ($$v) {_vm.$set(_vm.form, "unitHealth", $$v)},expression:"form.unitHealth"}}),_c('RgSelectCompetence',{ref:"competence",attrs:{"rules":{ required: true },"isFutureCompetence":false,"isRegister":""},model:{value:(_vm.form.competence),callback:function ($$v) {_vm.$set(_vm.form, "competence", $$v)},expression:"form.competence"}})],1),_c('hr',{staticClass:"separator-1"}),_c('span',{staticClass:"subtitle-export"},[_vm._v("Conteúdo")]),_c('Tooltip',{staticClass:"tooltip",attrs:{"message":"Selecione uma das opções de faturamento (BPA-C, BPA-I ou Todos) para ter acesso aos respectivos registros de procedimento","startOpen":""}},[_c('IconLightBulbHelper',{attrs:{"slot":"icon"},slot:"icon"})],1),_c('div',{staticClass:"subgroup-form"},[_c('div',{staticClass:"group-quantity-sheet-export-radio"},[_c('RgRadioCustom',_vm._b({attrs:{"id":"status","canUnselect":false},on:{"input":_vm.onInputRadioCustom}},'RgRadioCustom',_vm.propsRadio,false))],1),_c('div',{staticClass:"group-quantity-sheet-export"},[_c('RgInputNumber',{ref:"initBpai",class:{
              disable: _vm.LIST_GROUP_TYPES.bpac === _vm.selectTypeExport,
            },attrs:{"label":"Inicio da contagem BPA-I","rules":{ fn: _vm.minValueAmountBpai },"disabled":_vm.LIST_GROUP_TYPES.bpac === _vm.selectTypeExport,"maxlength":3,"placeholder":"000"},model:{value:(_vm.form.initBpai),callback:function ($$v) {_vm.$set(_vm.form, "initBpai", $$v)},expression:"form.initBpai"}}),_c('RgInputNumber',{ref:"initBpac",class:{
              disable: _vm.LIST_GROUP_TYPES.bpai === _vm.selectTypeExport,
            },attrs:{"label":"Inicio da contagem BPA-C","rules":{ fn: _vm.minValueAmountBpac },"disabled":_vm.LIST_GROUP_TYPES.bpai === _vm.selectTypeExport,"maxlength":3,"placeholder":"000"},model:{value:(_vm.form.initBpac),callback:function ($$v) {_vm.$set(_vm.form, "initBpac", $$v)},expression:"form.initBpac"}}),_c('RgSelectTypeModuleExport',{attrs:{"id":"type-module-export","rules":{ required: true }},model:{value:(_vm.form.moduleExport),callback:function ($$v) {_vm.$set(_vm.form, "moduleExport", $$v)},expression:"form.moduleExport"}}),_c('LargeButton',{attrs:{"id":"import","label":"Exportar Arquivo","disabled":_vm.inProgress,"backgroundColor":'#1E88A9'},on:{"click":_vm.exportBilling}},[_c('IconArrowUpload',{attrs:{"slot":"icon"},slot:"icon"})],1),_c('div',{staticClass:"export-loader",class:{
              'export-loader-active': _vm.inProgress,
              'export-loader-desactive': !_vm.inProgress,
            }},[_c('font-awesome-icon',{staticClass:"icon-loader",class:{
                'icon-loader-active': _vm.inProgress,
                'icon-loader-desactive': !_vm.inProgress,
              },attrs:{"icon":"fa-solid fa-rotate fa-spin","spin":_vm.inProgress}}),(_vm.inProgress)?_c('span',{staticClass:"text-in-progress"},[_vm._v(" Aguarde. Criando o arquivo de remessa... ")]):_vm._e()],1),(!_vm.inProgress)?_c('div'):_vm._e()],1)])],1)],1),_c('FormBase',{staticClass:"history-file-export",attrs:{"title":"Histórico de Arquivos de Remessa"}},[_c('div',{staticClass:"button-option"},[_c('LargeButton',{attrs:{"id":"import","label":"Baixar Arquivo Exportado","backgroundColor":'#1E88A9',"disabled":!_vm.fileOldDownload},on:{"click":_vm.oldDownload}},[_c('IconArrowDownload',{attrs:{"slot":"icon"},slot:"icon"})],1)],1),_c('SmartTable',{ref:"smartTable",staticClass:"smart-table",attrs:{"name":"BpaI","columns":_vm.COLUMNS,"body":_vm.listExports,"initial-columns":10,"total":_vm.listExports.length,"showPagination":false,"toggleSelected":"","removeBtnColumns":""},on:{"getLine":_vm.selectLine}})],1),_c('div',{staticClass:"export-billing-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.showBackButton)?_c('RgBackButton',{attrs:{"medium":""},on:{"click":_vm.goBack}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }