export default {
  SET_MENU_OPENED: (state, pData) => {
    state.menuOpened = pData;
  },
  SET_MODULE: (state, pModule) => {
    state.selectedModule = pModule;
  },
  SET_SUBMODULE: (state, pSubmodule) => {
    state.selectedSubModule = pSubmodule;
  },
  SET_OPERATION: (state, pOperation) => {
    state.selectedOperation = pOperation;
  },
  SET_FUSE_SEARCH: (state, pFuseSearch) => {
    state.fuseSearch = pFuseSearch;
  },
  SET_SUBMODULE_ID: (state, pSubModule) => {
    state.subModuleId = pSubModule;
  },
  CLEAN_SUBMODULE_ID: (state) => {
    state.subModuleId = null;
  },
  CLEAN_MENU: (state) => {
    state.selectedModule = null;
    state.selectedSubModule = null;
    state.selectedOperation = null;
    state.fuseSearch = false;
  },
};
