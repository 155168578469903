import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchCidProcedureBilling(
    $cid_nome: String
    $cid_sexo: String
    $cid_codigo: String
    $co_procedimento: String!
    $competence: String
    $limit: Int
  ) {
    SearchCidProcedureBilling(
      cid_nome: $cid_nome
      cid_sexo: $cid_sexo
      cid_codigo: $cid_codigo
      co_procedimento: $co_procedimento
      competence: $competence
      limit: $limit
    ) {
      count
      rows {
        cid_nome
        cid_codigo
        cid_sexo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchCidProcedureBilling;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
