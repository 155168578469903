import { BrazilianDateFormat } from "~utils/date";
import { DeepCopy } from "~utils/object";
import DocumentTypes from "./DocumentTypes";
import moment from "moment";

const mountPatientInfo = (pData) => {
  const hasPatientData = pData.pac_id > 0;
  const patientGender = pData.pes_id_sexos === 1 ? "M" : "F";
  let patient = null;

  if (hasPatientData) {
    patient = {
      pes_nome: pData.paciente,
      pes_mae: pData.pes_mae,
      pes_pai: pData.pes_pai,
      pes_id: pData.pes_id,
      pes_nascimento: pData.pes_nascimento,
      patient: {
        pac_id: pData.pac_id,
        pac_prontuario_unico: pData.pac_prontuario_unico,
      },
      cpf: {
        cpf_numero: pData.cpf_numero,
      },
      cns: {
        crs_numero: pData.crs_numero,
      },
      gender: {
        sex_sigla: patientGender,
      },
    };
  }

  return patient;
};

const mountProcedureInfo = (pData) => {
  const procedureId = pData.int_id_internacoes_procedimentos || pData.inp_id;

  if (procedureId) {
    const procedure = {
      inp_id: procedureId,
      inp_novo_codigo_procedimento: pData.inp_novo_codigo_procedimento,
      inp_novo_nome_procedimento: pData.inp_novo_nome_procedimento,
      inp_sexo: pData.inp_sexo,
      total_procedimento_cid: pData.total_procedimento_cid,
    };

    return procedure;
  }

  return null;
};

const mountEmployeeInfo = (pData, pEmployeeInfo, pType = 1) => {
  const relationshipId =
    pType === 1
      ? pData.int_id_funcionarios
      : pData.int_id_funcionarios_responsavel;

  const employeeName =
    pType === 1 ? pData.fun_solicitante_nome : pData.fun_responsavel_nome;

  const hasEmployeeInfo =
    pEmployeeInfo && pEmployeeInfo.employeeRelationshipType;
  if (relationshipId && hasEmployeeInfo) {
    const data = {
      employeeRelationship: {
        vin_id_funcionarios: relationshipId,
      },
      vin_id: pEmployeeInfo.vin_id,
      pes_nome: employeeName,
    };
    return data;
  }
};

const mountCompanionInfo = (pData) => {
  let documents = [];
  if (pData.companionDocuments && pData.companionDocuments.rows.length > 0) {
    const data = DeepCopy(pData.companionDocuments.rows);
    documents = data.map((item) => {
      delete item.__typename;

      return {
        dva_id_documentos: item.dva_id_documentos,
        dva_nome: DocumentTypes[item.dva_id_documentos],
        dva_numero_documentos: item.dva_numero_documentos,
      };
    });
  }

  return {
    via_id: pData.via_id,
    via_nome: pData.via_nome,
    documents,
  };
};

const mountHospitalization = (pData) => {
  return {
    int_id: pData.int_id,
    int_id_internacoes_tipos_responsavel:
      pData.int_id_internacoes_tipos_responsavel,
    int_id_internacoes_tipos_internacao:
      pData.int_id_internacoes_tipos_internacao,
    int_id_internacoes_caracteres: pData.int_id_internacoes_caracteres,
    int_hora: pData.int_hora ? pData.int_hora : moment().format("HH:mm"),
    int_data: pData.int_data ? pData.int_data : moment().format("DD/MM/YYYY"),
    int_previsao_alta: pData.int_previsao_alta
      ? BrazilianDateFormat(pData.int_previsao_alta)
      : null,
    int_ultima_situacao_internacao: pData.isl_id,
    int_id_internacoes_modalidade: pData.int_id_internacoes_modalidade,
    int_id_planos_saude: pData.int_id_planos_saude,
    ihs_id: pData.ihs_id,
    int_obs: pData.int_obs,
    int_id_ocupacoes_cbo: pData.int_id_ocupacoes_cbo,
    int_id_pacientes_internacao: pData.int_id_pacientes_internacao,
    int_id_filas_internacao: pData.int_id_filas_internacao,
    inl_id: pData.inl_id,
    itl_id: pData.itl_id,
    ids_tipos_do_leito: pData.ids_tipos_do_leito,
  };
};

const mountIndigent = (pData) => {
  const hasIndigentInfo = pData.ini_id > 0;
  let sex = null;
  let birthdate = null;
  let age = null;

  if (hasIndigentInfo) {
    sex = pData.ini_id_sexos === 1 ? "M" : "F";
    birthdate = BrazilianDateFormat(pData.ini_data_aproximada);

    const actualYear = moment().format("YYYY");
    const bornYear = moment(pData.ini_data_aproximada).format("YYYY");
    age = actualYear - bornYear;
  }

  return {
    info: {
      ini_id: pData.ini_id,
      ini_descricao: pData.ini_descricao,
      ini_id_sexos: sex,
      ini_data_aproximada: birthdate,
    },
    age,
  };
};

export {
  mountIndigent,
  mountHospitalization,
  mountPatientInfo,
  mountProcedureInfo,
  mountEmployeeInfo,
  mountCompanionInfo,
};
