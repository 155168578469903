<template>
  <form class="form" @submit.prevent="submitForm">
    <RgValidatorForm ref="validator" class="rg-validator">
      <FormBase title="Cadastro" class="form-base">
        <RgInput
          v-model="form.noh_nome"
          data-id="nome"
          label="Nome"
          placeholder="Digite o nome"
        />
        <RgInputCpf
          v-model="form.noh_cpf"
          data-id="cpf"
          label="CPF"
          placeholder="Digite o CPF"
        />
        <RgInputPhone
          v-model="form.noh_celular"
          data-id="celular"
          label="Celular"
          placeholder="Digite o número do celular"
        />
      </FormBase>

      <div class="actions">
        <RgSaveButton
          medium
          class="buttons"
          type="button"
          @click="submitForm"
        />
      </div>
    </RgValidatorForm>
  </form>
</template>
<script>
import {
  RgInput,
  RgInputCpf,
  RgInputPhone,
  RgValidatorForm,
  RgSaveButton,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "NotificationOnHomologationForm",
  components: {
    RgValidatorForm,
    RgInput,
    RgInputCpf,
    RgInputPhone,
    FormBase,
    RgSaveButton,
  },
  data: () => {
    return {
      form: {
        noh_cpf: "",
        noh_celular: "",
        noh_nome: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.validator.validate().then((valid) => {
        if (valid) {
          this.$emit("submit", { ...this.form });
        }
      });
    },
  },
};
</script>
