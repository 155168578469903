<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchScaleType from "./action/SearchScaleType";

export default {
  name: "RgSelectScaleType",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Tipos de Escala",
    },
    unitHealthId: {
      default: null,
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      userId: this.$store.getters["Login/GET_USER_ID"],
      valuesData: [],
    };
  },
  watch: {
    unitHealthId: async function (pValue, pPrev) {
      this.mValue = null;
      if (pValue && pValue !== pPrev) {
        this.doSearch();
      }
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (this.unitHealthId === null) return Promise.resolve([]);
      if (this.userId === null) return Promise.resolve([]);

      const data = await SearchScaleType({
        uns_id: this.unitHealthId,
        usu_id: this.userId,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: `${item.ees_nome}`,
        };
      });
    },
    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
