<style lang="scss" src="./RgInputDateMixin.scss" scoped></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase
      :label="label"
      :required="isRequired"
      :in-line="inLine"
      :has-label="hasLabel"
    >
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--icon" />
      </div>
      <div class="rg-input--base" :class="{ 'in-line': inLine }">
        <div
          :style="{ 'margin-top': marginTop }"
          class="rg-input--textbox-container"
        >
          <v-date-picker
            v-if="useDatepicker"
            v-model="date"
            :masks="masks"
            :model-config="returnConfig"
            :available-dates="formattedAvailabelDate"
            :min-date="getMinDate"
            :max-date="getMaxDate"
            :attributes="attributes"
            :firstDayOfWeek="1"
            @shortkey.native="cleanDatePicker"
          >
            <template v-slot="{ inputValue, togglePopover, inputEvents }">
              <label class="label-area">
                <div
                  class="rg-input--side-action"
                  @click="togglePopover({ placement: popUp })"
                >
                  <IconCalendar class="rg-input--icon" />
                </div>
                <input
                  :id="id"
                  v-mask="'##/##/####'"
                  :value="inputValue"
                  :disabled="disabled"
                  :readonly="disabled"
                  :data-id="dataId"
                  :class="{ 'on-error': hasError }"
                  :style="styles"
                  :placeholder="placeholder"
                  :tabindex="disabled ? -1 : tabIndex"
                  type="text"
                  class="inputbox"
                  @blur="validateBlur"
                  @change="change"
                  @click="
                    () => {
                      togglePopover({ placement: popUp });
                      if (!formattedAvailabelDate) loadSetMaxAndMinDate();
                    }
                  "
                  @focus="
                    (e) => {
                      moveCursorEnd(e);
                    }
                  "
                  v-on="inputEvents"
                />
              </label>
            </template>
          </v-date-picker>

          <div v-else>
            <input
              :id="id"
              v-model="inputValue"
              v-mask="'##/##/####'"
              :disabled="disabled"
              :readonly="disabled"
              :data-id="dataId"
              :class="{ 'on-error': hasError }"
              :style="styles"
              :placeholder="placeholder"
              :tabindex="disabled ? -1 : tabIndex"
              type="text"
              class="inputbox"
              @blur="validateBlur"
              @click="
                () => {
                  togglePopover({ placement: popUp });
                  loadSetMaxAndMinDate();
                }
              "
              @focus="
                (e) => {
                  moveCursorEnd(e);
                }
              "
              @change="change"
            />

            <div class="rg-input--side-action">
              <IconCalendar class="rg-input--icon" />
            </div>
          </div>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import { mask } from "vue-the-mask";
import { IconCalendar } from "~tokio/primitive/icon";
import moment from "moment";

const MIN_DATE = new Date("1900-01-01 00:00:00").getTime();
const MAX_DATE = new Date("2100-12-12 23:59:59").getTime();

export default {
  name: "RgInputDateMixin",
  components: {
    RgFormBase,
    RgValidationAlert,
    IconCalendar,
  },
  directives: { mask },
  mixins: [RgValidatorMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
    placeholder: {
      default: "dd/mm/aaaa",
    },
    tabIndex: {
      type: String,
      default: "0",
    },
    datesAvailable: {
      type: Array,
      default: () => [],
    },
    rules: {},
    label: {
      type: String,
      default: "Data",
    },
    change: {
      type: Function,
      default: () => {},
    },
    styles: {
      default: null,
    },
    marginTop: {
      type: String,
      default: "0px",
    },
    dataId: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inLine: Boolean,
    minDate: {
      type: [Date, Number],
      default: null,
    },
    maxDate: {
      type: [Date, Number],
      default: null,
    },
    useDatepicker: {
      type: Boolean,
      default: true,
    },
    disableNoAvailable: Boolean,
    hasLabel: {
      type: Boolean,
      default: true,
    },
    popUp: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      dirt: false,
      inputValue: "",
      date: "",
      formattedAvailabelDate: null,
      returnConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      masks: { input: "DD/MM/YYYY" },
      anotherRules: {
        validDate: (pValue, pError) => {
          if (!pValue) {
            return true;
          }
          const isDate = moment(pValue, "DD/MM/YYYY", true).isValid();
          if (!isDate) {
            pError.push(`${pValue} não é uma data válida`);
          }

          return isDate;
        },
      },
      error: [],
      disableAllDate: null,
      getMinDate: null,
      getMaxDate: null,
    };
  },
  computed: {
    attributes() {
      return [
        {
          key: "today",
          dot: this.showDot(),
          dates: new Date(),
        },
      ];
    },
    validValue() {
      if (this.useDatepicker) {
        return this.date;
      }
      return this.inputValue;
    },
    hasError() {
      return this.error.length > 0;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    value(pValue) {
      this.inputValue = pValue;
    },

    date(pDate) {
      this.inputValue = pDate;
      if (pDate) {
        this.validate();
      }
    },

    maxDate(pValue) {
      this.loadSetMaxAndMinDate();
    },

    inputValue(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.loadSetMaxAndMinDate();
      }
      this.date = pValue;
      this.$emit("input", this.inputValue);
      if (this.datesAvailable && this.datesAvailable.length > 0) {
        this.getMinDate = null;
        this.getMaxDate = null;
        this.formatForLimit();
      }
    },

    datesAvailable(pValue, pPrev) {
      if (pValue) {
        this.getMinDate = null;
        this.getMaxDate = null;
        this.formatForLimit();
      }
      if (!pValue) {
        this.getMinDate = this.minDate ? this.minDate : MIN_DATE;
        this.getMaxDate = this.maxDate ? this.maxDate : MAX_DATE;
      }
      if (pValue && pValue !== pPrev) {
        this.formatForLimit();
      }
    },
  },
  mounted() {
    this.getMinDate = this.minDate ? this.minDate : MIN_DATE;
    this.getMaxDate = this.maxDate ? this.maxDate : MAX_DATE;
    this.inputValue = this.value;
    this.formatForLimit();
  },
  methods: {
    validateBlur() {
      if (this.date || this.inputValue || this.isRequired) {
        this.validate();
        this.$emit("blur");
      }
    },

    formatForLimit() {
      if (this.datesAvailable?.length > 0) {
        this.formattedAvailabelDate = this.datesAvailable.map((item) => {
          return new Date(item.replaceAll("-", "/"));
        });
      } else if (this.disableNoAvailable) {
        /* OBSERVAÇÃO: motivo que foi feito desta forma
         * 'available-dates' tem prioridade acima da props 'disabled-dates'.
         * Sendo assim, mesmo desabilitando todas as datas, as datas habilitadas
         * estarão habilitadas.
         * Para contornar o problema, coloquei uma data improvável (descobrimento do Brasil)
         * desta forma somente esta data estará habilitada.
         */
        this.formattedAvailabelDate = ["1500-4-22"];
      } else {
        /*
         * Habilita todas as datas
         */
        this.formattedAvailabelDate = [];
      }
    },

    showDot() {
      const currentDate = moment().format("DD/MM/YYYY");

      if (!this.date) {
        return true;
      }

      if (this.date === currentDate) {
        return false;
      }

      return true;
    },

    setMinDate(pDate) {
      this.getMinDate = pDate || MIN_DATE;
    },

    setMaxDate(pDate) {
      this.getMaxDate = pDate || MAX_DATE;
    },

    loadSetMaxAndMinDate() {
      this.getMinDate = this.minDate ? this.minDate : MIN_DATE;
      this.getMaxDate = this.maxDate ? this.maxDate : MAX_DATE;
    },

    cleanDatePicker() {
      this.date = null;
    },

    cleanValidate() {
      this.error = [];
      this.isValid = true;
      if (this.dirt) {
        this.dirt = false;
      }
    },

    moveCursorEnd(e) {
      if (typeof e.target.selectionStart === "number") {
        e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
      } else if (typeof e.target.createTextRange !== "undefined") {
        const range = e.target.createTextRange();
        range.collapse(false);
        range.select();
      }
    },
  },
};
</script>
