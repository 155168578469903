<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchTypePresentation from "./action/SearchTypePresentation";

export default {
  name: "RgSelectTypePresentation",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Tipo de Apresentação",
    },
    produto: {
      type: Number,
      default: null,
    },
  },
  data: () => {
    return {
      typePresentation: [],
    };
  },
  watch: {
    produto(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    typePresentation: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.typePresentation;
    },
    async doSearch() {
      this.typePresentation = [];

      const data = await SearchTypePresentation({
        intIdProduto: this.produto,
      });

      this.typePresentation = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
          mtu_novo_nome: item.mtu_novo_nome,
        };
      });
    },
  },
};
</script>
