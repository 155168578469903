<style src="./RgDropdown.scss" lang="scss" scoped></style>
<template>
  <div
    v-clickOutside="clickOutside"
    class="dropdown-button"
    :disabled="disabled"
    :class="{
      disable: disabled && !notBackgroundWhenDisabled,
      disableNoColor: disabled && notBackgroundWhenDisabled,
      '-small': small,
      '-large': large,
    }"
    :style="{ 'background-color': backgroundColor }"
    @click="activeButton"
  >
    <div v-show="!small" :title="title" class="label">
      {{ label }}
    </div>

    <div v-show="!small" :title="title" class="separator" />

    <div class="icon" :title="title" :class="{ 'rotate-icon': rotateIcon }">
      <slot name="icon" class="icon" />
    </div>

    <div
      :class="{
        'show-up': showUp,
        'show-left': showLeft,
        'dropdown-open': isActive,
      }"
      class="dropdown"
    >
      <span
        v-for="option in actionOptions"
        :key="generateIdentifier(option)"
        :data-id="generateIdentifier(option)"
        :class="{ 'disable unselect': option.disable }"
        :disabled="option.disable"
        class="item"
        @click="handleClick(item, option)"
        v-html="option.label"
      >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RgDropdown",
  props: {
    /**
     * @type {{label: string, action: Function}[]} actionOptions
     */
    actionOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
    item: {
      type: Object,
      default: () => {},
    },
    itemId: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "#1e88a9",
    },
    notBackgroundWhenDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Para o componente funcionar corretamente,
     * é necessário escolher o tamanho (large ou small).
     * Quando o tamanho for large, é obrigatório o uso da prop label
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Para o componente funcionar corretamente,
     * é necessário escolher o tamanho (large ou small)
     */
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showUp: {
      type: Boolean,
      default: false,
    },
    showLeft: {
      type: Boolean,
      default: false,
    },
    rotateIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusWaiting: false,
      isActive: false,
    };
  },
  methods: {
    generateIdentifier(pOption) {
      return !this.itemId || this.itemId === 0
        ? pOption.label
        : `${pOption.label}-${this.itemId}`;
    },
    /**
     * @param {any} pItem
     * @param {{label: string, action: Function}[]} pOption
     */
    handleClick(pItem, pOption) {
      this.$emit(`on-${pOption.label}`, pItem);
      pOption.action(pItem);
    },
    setWaiting() {
      this.statusWaiting = true;
    },
    setStartOrFinish() {
      this.statusWaiting = false;
    },
    activeButton() {
      this.isActive = !this.isActive;
    },
    clickOutside() {
      this.isActive = false;
    },
  },
};
</script>
