<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchEmployeeByUnitSectorOccupation from "./action/SearchEmployeeByUnitSectorOccupation";

export default {
  name: "RgSelectEmployeeByUnitSectorOccupation",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Profissional",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    sectorId: {
      type: Number,
      default: null,
    },
    occupationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      employee: [],
    };
  },
  watch: {
    occupationId(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.doSearch();
      }
    },
    employee: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.employee;
    },
    async doSearch() {
      try {
        this.employee = [];

        const hasValuesSetted =
          this.unitHealthId > 0 && this.sectorId > 0 && this.occupationId > 0;

        if (hasValuesSetted) {
          const result = await SearchEmployeeByUnitSectorOccupation({
            intIdUnidadeSaude: this.unitHealthId,
            intIdSetor: this.sectorId,
            intIdOcupacao: this.occupationId,
          });

          const hasResult = result?.dados?.length > 0;
          if (!hasResult) {
            this.$toaster.warning("Não foi encontrado profissional.");
            return;
          }

          this.employee = result.dados.map((item) => {
            return {
              value: item.data,
              label: item.label,
              vin_id: item.vin_id,
              vus_id: item.vus_id,
            };
          });
        }
      } catch (error) {
        const { esus_response } = error;
        if (esus_response?.hasError) {
          this.$toaster.warning(esus_response.message);
        } else {
          this.$toaster.error(this.$utils.sanitize.formatError(error));
        }
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
