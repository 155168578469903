<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchProductType from "./action/SearchProductType";

export default {
  name: "RgSelectProductType",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Tipo de Produto/Medicamento",
    },
  },
  data: () => {
    return {
      productType: [],
    };
  },
  watch: {
    productType: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.productType;
    },
    async doSearch() {
      this.productType = [];

      const data = await SearchProductType();

      this.productType = data
        .map((item) => {
          return {
            value: item.data,
            label: item.label,
            mtp_nome: item.mtp_nome,
          };
        })
        .sort((element, element2) => {
          if (element.mtp_nome > element2.mtp_nome) {
            return 1;
          }
          if (element.mtp_nome < element2.mtp_nome) {
            return -1;
          }
          return 0;
        });
    },
  },
};
</script>
