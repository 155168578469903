<template>
  <Modulebox
    ref="modulebox"
    :title="
      isEdition
        ? 'Editar Entrada de Produtos e Medicamentos'
        : 'Entrada de Produtos e Medicamentos'
    "
    class="pharmacy-central-entry"
  >
    <div class="pharmacy-central-entry-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Dados da Compra/Aquisição" class="register-form">
          <section class="pharmacy-central-entry-purchase-data-form">
            <RgSelectProvider
              ref="provider"
              v-model="form.provider"
              label="Fornecedor"
              :disabled="disabledByModal"
              :rules="{ required: true }"
              @change="selectedProvider"
            />
            <RgComboboxPharmacyTypeTransport
              ref="type"
              v-model="form.type"
              label="Tipo de Entrada"
              :disabled="disabledByModal"
              :rules="{ required: true }"
            />
            <RgInput
              v-model="form.commitment"
              label="Empenho"
              placeholder="Digite os dados do empenho"
              :maxlength="50"
              :disabled="disabledByModal"
              :rules="{ fn: validateEspecialCharacter }"
              removeSpace
              :needRemoveSpecialStrings="false"
            />
            <RgInput
              v-model="form.invoice"
              label="Nota Fiscal"
              placeholder="Digite o número da nota"
              :maxlength="50"
              :disabled="disabledByModal"
              removeSpace
              :rules="{ fn: validateEspecialCharacter }"
              :needRemoveSpecialStrings="false"
            />
            <RgInputDate
              ref="purchaseData"
              v-model="form.purchaseData"
              label="Data da Compra"
              :rules="{ required: true }"
              :max-date="new Date()"
              :disabled="disabledByModal"
            />
          </section>
        </FormBase>

        <FormBase title="Farmácia Destino">
          <section class="pharmacy-central-entry-destination-pharmacy-form">
            <RgSelectUnithealthPharmacy
              ref="unitHealth"
              v-model="form.unitHealth"
              :disabled="isEdition || disabledByModal"
              :class="{ disable: isEdition }"
              :rules="{ required: true }"
              @change="selectedDestinyUnitHealth"
            />
            <RgSelectPharmacy
              ref="pharmacy"
              v-model="form.pharmacy"
              byUser
              :unitHealthId="form.unitHealth"
              :userId="userLoginId"
              :typePharmacy="CENTRAL_PHARMACY_TYPE"
              :disabled="!form.unitHealth || isEdition || disabledByModal"
              :class="{
                disable: !form.unitHealth || isEdition,
              }"
              :rules="{ required: true }"
              @change="selectedDestinyPharmacy"
            />
            <RgSuggestUser
              ref="user"
              v-model="form.user"
              label="Usuário"
              :disabled="true"
              :class="{ disable: true }"
              :rules="{ required: true }"
            />
            <RgInputDate
              ref="entryDate"
              v-model="form.entryDate"
              label="Data da Entrada"
              :disabled="
                blockedDateEntry || disabledByModal || !form.purchaseData
              "
              :class="{ disable: blockedDateEntry || !form.purchaseData }"
              :rules="{ required: true }"
              :min-date="getMinDateInitial"
              :max-date="new Date()"
            />

            <label class="checkbox">
              <input
                v-model="immediateExit"
                :disabled="isEdition || disabledByModal"
                :class="{ disable: isEdition }"
                type="checkbox"
              />
              <span> Saída Imediata para outra Farmácia </span>
            </label>

            <RgSelectUnithealthPharmacy
              ref="immediateUnitHealth"
              v-model="form.immediateUnitHealth"
              :class="{
                disable: !immediateExit || isEdition || disabledByModal,
              }"
              :disabled="!immediateExit || isEdition"
              :rules="{ required: immediateExit }"
              @change="selectedImmediateUnitHealth"
            />
            <RgSelectPharmacy
              ref="immediatePharmacy"
              v-model="form.immediatePharmacy"
              :class="{
                disable:
                  !form.immediateUnitHealth || isEdition || disabledByModal,
              }"
              :disabled="!form.immediateUnitHealth || isEdition"
              :unitHealthId="form.immediateUnitHealth"
              :rules="{ required: immediateExit }"
              viewPharmacyType
              @change="selectedImmediatePharmacy"
            />
          </section>

          <span class="subtitle">Medicamentos e ou Produtos</span>
          <hr class="hr" />

          <section class="pharmacy-central-entry-products-form">
            <RgSelectProduct
              ref="product"
              v-model="form.product"
              label="Produto/Medicamento"
              :rules="{ required: true }"
              :disabled="isEdition || disabledByModal"
              :class="{ disable: isEdition }"
              perClient
              @change="selectedProduct"
            />
            <RgInput
              ref="batch"
              v-model="form.batch"
              label="Lote"
              placeholder="Digite o número do lote"
              :rules="{ required: true, fn: validateEspecialCharacter }"
              :maxlength="30"
              removeSpace
              :disabled="isEdition || disabledByModal"
              :class="{ disable: isEdition }"
              :needRemoveSpecialStrings="false"
              @blur="getExpirationDate"
            />
            <RgInputDate
              ref="expirationDate"
              v-model="form.expirationDate"
              label="Validade"
              :disabled="isEdition || disabledByModal"
              :class="{ disable: isEdition }"
              :rules="{ required: true }"
            />
            <RgInputNumber
              ref="amountReceived"
              v-model="form.amountReceived"
              :class="{ disable: isEdition || disabledByModal }"
              :rules="{ required: true, fn: validateAmountReceived }"
              label="Quantidade Recebida"
              :maxlength="9"
              :disabled="isEdition || disabledByModal"
            />
            <RgInputMoney
              ref="amount"
              v-model="form.amount"
              :class="{ disable: isEdition || disabledByModal }"
              :rules="{ required: true, fn: validateAmount }"
              label="Valor Total"
              placeholder="00,00"
              :maxlength="16"
              :disabled="isEdition || disabledByModal"
            />

            <div class="actions">
              <RgCleanButton
                v-if="!isEdition"
                :disabled="disabledByModal"
                @click="openModalConfirmField"
              />
              <RgAddButton
                v-if="!isEdition"
                id="add-btn"
                title="Adicionar Produto/Medicamento"
                :disabled="disabledByModal"
                @click="openModalConfirm"
              />
            </div>
          </section>

          <hr v-if="!isEdition" class="hr" />

          <div v-if="!isEdition" class="pharmacy-central-entry-table">
            <SmartTable
              ref="smartTable"
              name="PharmacyProductType"
              :columns="COLUMNS"
              :body="mutableProductList"
              :total="mutableProductList.length"
              :showPagination="false"
              :initial-columns="7"
              toggleSelected
              :disabled="disabledByModal"
              @getLine="selectLine"
            >
            </SmartTable>
          </div>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="pharmacy-central-entry-footer">
      <RgCancelButton
        v-if="isEdition"
        :disabled="disabledByModal"
        medium
        @click="goBack"
      />
      <RgSaveButton
        v-if="isEdition"
        :disabled="disabledByModal"
        medium
        @click="saveEditEntry"
      />
      <MediumButton
        v-if="!isEdition"
        title="Voltar"
        label="Voltar"
        :disabled="disabledByModal"
        @click="goBack"
      />
    </div>

    <ModalConfirmDefault
      id="modal-confirm"
      :show="modalConfirmSaveEntry"
      title="Registrar Entrada de Produtos"
      subtitle="A operação não poderá ser desfeita. Deseja realizar o registro de Entrada?"
      :message="
        this.product +
        ' - ' +
        this.form.batch +
        ' - ' +
        this.form.expirationDate +
        ' - ' +
        this.form.amountReceived
      "
      yesLabel="Salvar"
      noLabel="Cancelar"
      buttonsRight
      @getYes="saveEntry()"
      @getOut="closeModalConfirm"
      @close="closeModalConfirm"
    />

    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmField"
      @getYes="cleanForm"
      @getOut="closeModalConfirmField"
      @close="closeModalConfirmField"
    />
    <ModalPrintMovement
      id="modal-print-movement"
      :show="modalPrintMovement"
      :filters="filterPrint"
      @close="closeModalPrintMovement"
    />
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInput,
  RgInputDate,
  RgSuggestUser,
  RgCleanButton,
  RgAddButton,
  RgSaveButton,
  RgCancelButton,
  MediumButton,
  RgInputNumber,
  RgInputMoney,
  ModalConfirmDefault,
  ModalConfirmFieldClearing,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";
import moment from "moment";

import { mapGetters } from "vuex";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ModalPrintMovement from "$pharmacy/common/modal/modal-print-movement/ModalPrintMovement";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectProvider from "$pharmacy/common/select/rg-select-provider/RgSelectProvider";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgComboboxPharmacyTypeTransport from "$pharmacy/common/combobox/rg-combobox-pharmacy-type-transport/RgComboboxPharmacyTypeTransport";

const FORM = {
  entryId: null,
  provider: null,
  type: null,
  commitment: "",
  invoice: null,
  purchaseData: moment().format("DD/MM/YYYY"),
  unitHealth: null,
  pharmacy: null,
  user: null,
  entryDate: moment().format("DD/MM/YYYY"),
  immediateUnitHealth: null,
  immediatePharmacy: null,
  product: null,
  batch: null,
  expirationDate: null,
  amountReceived: null,
  amount: null,
};

export default {
  name: "PharmacyCentralEntry",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectProvider,
    RgSelectPharmacy,
    RgSelectProduct,
    RgComboboxPharmacyTypeTransport,
    RgSelectUnithealthPharmacy,
    RgInput,
    RgInputDate,
    RgInputNumber,
    RgInputMoney,
    RgSuggestUser,
    RgCleanButton,
    RgCancelButton,
    RgAddButton,
    RgSaveButton,
    MediumButton,
    SmartTable,
    ModalConfirmDefault,
    ModalConfirmFieldClearing,
    ModalPrintMovement,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableProductList: [],
      selectedProductEntry: {},
      code: null,
      product: null,
      presentationType: null,
      immediateExit: false,
      immediatePharmacy: null,
      immediateUnitHealth: null,
      destinyUnitHealth: null,
      destinyPharmacy: null,
      provider: null,
      remittance: null,
      hasSelectedProductTable: false,
      isEdition: false,
      routeEditing: false,
      modalConfirmSaveEntry: false,
      modalConfirmField: false,
      lotExpirationDate: null,
      modalPrintMovement: false,
      filterPrint: null,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      userLoginName: "Login/GET_USER_NAME",
      getEntryId: "Pharmacy/Central/GET_SELECTED_CENTRAL",
    }),
    disableActionsTable() {
      return this.isEdition || !this.hasSelectedProductTable;
    },
    preferenceDateEntry() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.trs_bloquear_data_entrada"
      ];
    },
    blockedDateEntry() {
      return this.preferenceDateEntry === "1";
    },
    disabledByModal() {
      return this.modalConfirmField || this.modalConfirmSaveEntry;
    },
    getMinDateInitial() {
      return this.form.purchaseData
        ? new Date(moment(this.form.purchaseData, "DD/MM/YYYY").format())
        : null;
    },
    hasImmediateExit() {
      return (
        this.immediateExit &&
        this.form.immediateUnitHealth > 0 &&
        this.form.immediatePharmacy > 0
      );
    },
  },

  watch: {
    immediateExit(pValue) {
      if (!pValue) {
        this.form.immediateUnitHealth = null;
        this.form.immediatePharmacy = null;
      }
    },
    "form.purchaseData"(pValue) {
      this.form.entryDate = pValue;
    },
    "form.unitHealth"(pValue, pPrev) {
      if ((!pValue || pValue !== pPrev) && !this.isEdition) {
        this.form.pharmacy = null;
      }
    },
    "form.product"(pValue, pPrev) {
      if ((!pValue || pValue !== pPrev) && !this.isEdition) {
        this.form.batch = null;
        this.form.expirationDate = null;
        this.form.amountReceived = null;
        this.form.amount = null;
      }
    },
    "form.immediateUnitHealth"(pValue, pPrev) {
      if ((!pValue || pValue !== pPrev) && !this.isEdition) {
        this.form.immediatePharmacy = null;
      }
    },
  },

  mounted() {
    this.$refs.user.forceSelection({
      usu_id: this.userLoginId,
      usu_nome: this.userLoginName,
    });

    const routeName = this.$route.name;
    const editRoute = routeName === "pharmacy.central.edit-entry";

    if (editRoute) {
      const entryId = this.getEntryId.trs_id;
      this.isEdition = true;
      this.routeEditing = true;
      this.mountEdition(entryId);
    }
  },

  created() {
    this.COLUMNS = [
      { name: "Código", key: "code", align: "left", active: "true" },
      { name: "Nome", key: "name", align: "left", active: "true" },
      {
        name: "Apresentação",
        key: "presentation",
        align: "left",
        active: "true",
      },
      {
        name: "Quantidade",
        key: "amountReceived",
        align: "left",
        active: "true",
      },
      {
        name: "Data da Entrada",
        key: "entryDate",
        align: "left",
        active: "true",
      },
      { name: "Lote-Validade", key: "batch", align: "left", active: "true" },
      {
        name: "Farmácia Saída",
        key: "immediatePharmacy",
        align: "left",
        active: "true",
      },
      {
        name: "Unid. de Saúde Saída",
        key: "immediateUnitHealth",
        align: "left",
      },
      { name: "Nota Fiscal", key: "invoice", align: "left" },
      { name: "Empenho", key: "commitment", align: "left" },
      { name: "Data da Compra", key: "purchaseData", align: "left" },
      { name: "Valor Total", key: "amount", align: "left" },
      { name: "Unid. de Saúde Entrada", key: "unitHealth", align: "left" },
      { name: "Farmácia Entrada", key: "pharmacy", align: "left" },
      { name: "Fornecedor", key: "provider", align: "left" },
    ];

    this.CENTRAL_PHARMACY_TYPE = 1;
  },

  destroyed() {
    this.$store.commit("Pharmacy/Central/UNSELECT_PHARMACY_CENTRAL");
  },

  methods: {
    validateEspecialCharacter(pValue, pErrors) {
      const hasSpecial = /[!$%^&*()@_^#¨|´~=`{}[\]:";'~<>?,+\\]/.test(pValue);

      if (hasSpecial) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: barra, ponto, traço",
        );
        return false;
      }

      return true;
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    parseDataSave() {
      const variables = {
        trs_id: this.isEdition ? this.form.entryId : null,
        far_id_saida_imediata: this.form.immediatePharmacy,
        frd_id: this.form.provider,
        tec_compra: this.form.purchaseData,
        tec_nota_fiscal: this.form.invoice,
        tec_numero_empenho: this.form.commitment,
        trs_data: this.form.entryDate,
        trs_guia_remessa: this.remittance,
        trs_id_farmacias_destino: this.form.pharmacy,
        trs_id_municipios_produtos: this.form.product,
        trs_id_tipos_transportes: this.form.type,
        trs_id_usuarios: this.userLoginId,
        trs_lote: this.form.batch,
        trs_quantidade: this.$refs.amountReceived.formatNumber(
          this.form.amountReceived,
        ),
        trs_validade_lote: this.form.expirationDate,
        trs_valor: this.$refs.amount.formatNumber(this.form.amount),
      };
      return variables;
    },
    async saveEntry() {
      try {
        this.$loader.start();

        if (this.hasImmediateExit) {
          await this.getRemittanceGuide();
        }

        const variables = this.parseDataSave();

        const result = await this.$store.dispatch(
          "Pharmacy/Central/REGISTER_ENTRY",
          variables,
        );

        if (this.isEdition && this.routeEditing) {
          this.goBack();
        }

        this.insertProductTable(variables, result.dados);

        this.$toaster.success("Entrada registrada com sucesso");

        const info = {
          intIdMunicipioProduto: this.form.product,
          intIdFarmacia: this.form.pharmacy,
        };
        const data = await this.$store.dispatch(
          "Pharmacy/MAX_LIMIT_EXCEEDED",
          info,
        );

        if (data !== 0) {
          this.$toaster.info(
            "Faça um tipo de saída para liberar o estoque excedente do produto.",
            "O estoque máximo foi alcançado",
          );
        }
        if (this.hasImmediateExit) {
          this.filterPrint = this.remittance;
          this.modalPrintMovement = true;
        }
        this.cleanFormWithProduct();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao registrar entrada",
        );
      } finally {
        this.closeModalConfirm();
        this.$loader.finish();
      }
    },
    insertProductTable(pData, pId) {
      const data = {
        id: pId,
        code: this.code,
        name: this.product,
        presentation: this.presentationType,
        batch: pData.trs_lote + " - " + pData.trs_validade_lote,
        amountReceived: pData.trs_quantidade,
        amount: pData.trs_valor,
        productId: this.form.product,
        immediatePharmacy: this.immediatePharmacy,
        unitHealth: this.destinyUnitHealth,
        pharmacy: this.destinyPharmacy,
        immediateUnitHealth: this.immediateUnitHealth,
        provider: this.provider,
        commitment: pData.tec_numero_empenho,
        invoice: pData.tec_nota_fiscal,
        purchaseData: pData.tec_compra,
        entryDate: pData.trs_data,
      };

      this.mutableProductList.push(data);
    },
    editEntry() {
      this.isEdition = true;
      this.mountEdition();
    },
    async saveEditEntry() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();

        const variables = this.parseDataSave();

        await this.$store.dispatch("Pharmacy/Central/EDIT_ENTRY", variables);

        this.$toaster.success("Entrada alterada com sucesso");

        this.isEdition = false;

        this.cleanForm();

        this.goBack();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao alterar entrada",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async mountEdition(pId) {
      this.$loader.start();

      const entryId = this.selectedProductEntry.id
        ? this.selectedProductEntry.id
        : pId;

      const entry = await this.$store.dispatch(
        "Pharmacy/Central/GET_PRODUCT_ENTRY",
        entryId,
      );

      this.form.entryId = entry.trs_id;
      this.form.provider = entry.frd_id;
      this.form.type = entry.trs_id_tipos_transportes;
      this.form.commitment = entry.tec_numero_empenho;
      this.form.invoice = entry.tec_nota_fiscal;
      this.form.purchaseData = entry.tec_compra;
      this.form.unitHealth = Number(entry.unidadeSaudeEntrada);
      this.form.pharmacy = entry.trs_id_farmacias_destino;

      this.$refs.user.forceSelection({
        usu_id: Number(entry.trs_id_usuarios),
        usu_nome: entry.usu_nome,
      });

      this.form.entryDate = entry.trs_data;

      entry.unidadeSaudeSaidaImediata && entry.far_id_saidas_imediatas
        ? (this.immediateExit = true)
        : (this.immediateExit = false);

      this.form.immediateUnitHealth = Number(entry.unidadeSaudeSaidaImediata);
      this.form.immediatePharmacy = entry.far_id_saidas_imediatas;
      this.form.product = entry.trs_id_municipios_produtos;
      this.form.batch = entry.trs_lote;
      this.form.expirationDate = entry.trs_validade_lote;
      this.form.amountReceived = entry.trs_quantidade;
      this.form.amount = entry.trs_valor;

      this.$loader.finish();
    },
    selectLine(pValue) {
      if (pValue) {
        this.selectedProductEntry = pValue;
        this.hasSelectedProductTable = true;
      } else {
        this.selectedProductEntry = {};
        this.hasSelectedProductTable = false;
      }
    },
    async getRemittanceGuide() {
      try {
        const result = await this.$store.dispatch(
          "Pharmacy/Central/GENERATE_REMITTANCE_GUIDE",
        );
        if (result && result.length > 0) {
          this.remittance = result.toUpperCase();
        } else {
          this.remittance = null;
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao gerar remessa");
      }
    },
    async getExpirationDate() {
      if (
        this.form.pharmacy &&
        this.form.product &&
        this.form.batch?.length > 0
      ) {
        try {
          this.$loader.start();

          const variables = {
            intIdMunicipioProduto: this.form.product,
            intIdFarmacia: this.form.pharmacy,
            strLote: this.form.batch,
          };

          const result = await this.$store.dispatch(
            "Pharmacy/Central/GET_EXPIRATION_DATE",
            variables,
          );

          if (result && result.length > 0) {
            this.form.expirationDate = result;
          }
        } catch (pErr) {
          this.$toaster.error("Erro ao obter validade");
        } finally {
          this.$loader.finish();
        }
      }
    },
    selectedProduct(pProduct) {
      if (pProduct) {
        this.code = pProduct.mpd_codigo;
        this.product = pProduct.mpd_novo_principio_ativo;
        this.presentationType = pProduct.mtu_novo_nome;
      } else {
        this.code = null;
        this.product = null;
        this.presentationType = null;
      }
    },
    selectedProvider(pValue) {
      if (pValue) {
        this.provider = pValue.label;
      } else {
        this.provider = null;
      }
    },
    selectedImmediatePharmacy(pPharmacy) {
      if (pPharmacy) {
        this.immediatePharmacy = pPharmacy.label;
      } else {
        this.immediatePharmacy = null;
      }
    },
    selectedDestinyUnitHealth(pValue) {
      if (pValue) {
        this.destinyUnitHealth = pValue.label;
      } else {
        this.destinyUnitHealth = null;
      }
    },
    selectedDestinyPharmacy(pValue) {
      if (pValue) {
        this.destinyPharmacy = pValue.label;
      } else {
        this.destinyPharmacy = null;
      }
    },
    selectedImmediateUnitHealth(pValue) {
      if (pValue) {
        this.immediateUnitHealth = pValue.label;
      } else {
        this.immediateUnitHealth = null;
      }
    },
    validateAmountReceived(pValue, pErrors) {
      if (pValue && Number(pValue) < 1) {
        pErrors.push("Informe um valor maior que zero.");
        return false;
      }

      return true;
    },
    validateAmount(pValue, pErrors) {
      if (pValue && pValue === "R$ 0,00") {
        pErrors.push("Informe um valor maior que zero.");
        return false;
      }

      return true;
    },
    verifyDuplicate() {
      const batch = this.form.batch + " - " + this.form.expirationDate;
      const filterDuplicate = this.mutableProductList.filter((pData) => {
        if (pData.productId === this.form.product && pData.batch === batch)
          return true;
      });

      return filterDuplicate;
    },
    async openModalConfirm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      if (this.form.pharmacy === this.form.immediatePharmacy) {
        this.$toaster.error(
          "A Farmácia Destino da saída imediata deve ser diferente da Farmácia Destino.",
          "Não foi possível registrar a entrada",
        );
        return false;
      }

      const existsDuplicate = this.verifyDuplicate();

      if (existsDuplicate.length > 0) {
        this.$toaster.info(
          "Escolha o mesmo produto com outro Lote - Validade para registrar a entrada.",
          "O produto já faz parte desse registro de Entrada",
        );
        return false;
      }
      this.modalConfirmSaveEntry = true;
    },
    closeModalConfirm() {
      this.modalConfirmSaveEntry = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    closeModalConfirmField() {
      this.modalConfirmField = false;
    },

    openModalConfirmField() {
      this.modalConfirmField = true;
    },
    closeModalPrintMovement() {
      this.modalPrintMovement = false;
    },

    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM);

      this.cleanFieldsValidation();

      this.$nextTick(() => {
        this.$refs.user.forceSelection({
          usu_id: this.userLoginId,
          usu_nome: this.userLoginName,
        });
      });
    },
    cleanFormWithProduct() {
      this.form.entryId = null;
      this.form.product = null;
      this.form.batch = null;
      this.form.expirationDate = null;
      this.form.amountReceived = null;
      this.form.amount = null;
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "provider",
        "type",
        "purchaseData",
        "unitHealth",
        "pharmacy",
        "user",
        "entryDate",
        "immediateUnitHealth",
        "immediatePharmacy",
        "product",
        "batch",
        "expirationDate",
        "amountReceived",
        "amount",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
