import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variable) => {
  return AuthLegacyRequest.post(
    "farmacia/controller-farmacia/carregar",
    variable,
  )
    .then(({ data }) => {
      const result = HandleResponse(data);
      return result.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
