<style src="./RgUnregulateButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-unregulate-button">
    <RgButton
      :id="id"
      :disabled="disabled"
      :permission="permission"
      :data-id="dataId"
      :class="{
        'rg-unregulate-button -small': small,
        'rg-unregulate-button -medium': medium,
        'rg-unregulate-button -large': large,
      }"
      :title="title"
      class="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>{{ text }}</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-show="!medium" class="icon">
        <IconUnregulate />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconUnregulate } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "RgUnregulateButton",
  components: {
    IconUnregulate,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    text: {
      type: String,
      default: "Desregular",
    },
    title: {
      type: String,
      default: "Desregular",
    },
  },
};
</script>
