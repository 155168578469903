import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People(
    $rg: String
    $cpf: String
    $cns: String
    $limit: Int
    $offset: Int
  ) {
    people(cpf: $cpf, cns: $cns, rg: $rg, limit: $limit, offset: $offset) {
      count
      rows {
        pes_id
        pes_nome
        pes_mae
        pes_pai
        pes_nascimento
        pes_id_locais_atendimento_pleni
        gender {
          sex_id
          sex_sigla
        }
        patient {
          pac_id
          pac_prontuario_unico
          pac_ativo
          pac_id_motivo_inabilitacao
          record_numbers {
            rows {
              ppr_numero
              ppr_id_unidades_saude
            }
          }
        }
        cns {
          crs_numero
        }
        cpf {
          cpf_numero
        }
        rg {
          rgs_numero
        }
        address {
          end_logradouro
          end_cep
          end_numero
          end_complemento
          neighborhood {
            bai_id
            bai_nome
            city {
              mun_nome
            }
          }
          publicPlaceType {
            tlg_id
            tlg_nome
          }
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.people;
};
