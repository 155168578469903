<template lang="html">
  <div v-if="show" class="modal-discard-movement">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <!-- TODO: Verificar depois as telas que utilizam essa modal -->
      <div slot="header" class="modal-discard-movement-header">
        <span class="title"> Dados do Descarte de Produtos </span>
      </div>

      <div class="modal-discard-movement-type">
        <div slot="body" class="modal-discard-movement-body">
          <FormBase title="Dados Produto Selecionado" class="form-base">
            <section class="grid">
              <span class="text-info">
                Nome:
                <strong :title="product.mpd_novo_principio_ativo">
                  {{ product.mpd_novo_principio_ativo || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Código:
                <strong :title="product.mpd_codigo">
                  {{ product.mpd_codigo || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Apresentação:
                <strong :title="product.mtu_novo_nome">
                  {{ product.mtu_novo_nome || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Tipo:
                <strong :title="product.mtp_nome">
                  {{ product.mtp_nome || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Estoque:
                <strong :title="product.estoque">
                  {{ product.estoque || "-" }}</strong
                >
              </span>
              <span v-if="stockLotExpiration" class="text-info">
                Lote e Validade:
                <strong :title="product.dataLote">{{
                  product.dataLote || "-"
                }}</strong>
              </span>
            </section>
            <span class="subtitle">Informações do Descarte</span>
            <hr class="hr" />
            <section class="grid">
              <span class="text-info">
                Unidade de Saúde:
                <strong :title="unitHealthName">
                  {{ unitHealthName || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Farmácia:
                <strong :title="pharmacyName">
                  {{ pharmacyName || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Data:
                <strong :title="product.dsc_data">
                  {{ product.dsc_data || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Motivo:
                <strong :title="product.mdc_nome">
                  {{ product.mdc_nome || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Quantidade:
                <strong :title="product.dsc_quantidade">
                  {{ product.dsc_quantidade || "-" }}</strong
                >
              </span>
            </section>
            <section class="grid-observations">
              <RgTextArea
                id="observation"
                ref="observation"
                v-model="product.dsc_obs"
                class="inputitem"
                label="Observações"
                :viewCharacters="false"
                readonly
                :maxlength="255"
              />
            </section>
          </FormBase>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgTextArea } from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";

export default {
  name: "ModalSeeDiscard",
  components: {
    RgBaseModal,
    RgTextArea,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
    idUnithHealth: {
      type: Number,
      default: 0,
    },
    idPharmacy: {
      type: String,
      default: "0",
    },
    unitHealthName: {
      type: String,
      default: "",
    },
    pharmacyName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      product: {},
    };
  },
  computed: {
    preferenceStockType() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.frp_tipo_estoque"
      ];
    },
    stockLotExpiration() {
      return this.preferenceStockType === "2";
    },
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.mountedFormDisposal(this.selectedRow);
      }
    },

    // selectedRow(pValue) {
    //   if (pValue && pValue !== null && pValue.situacao === "DESCARTE") {
    //   }
    // },
  },
  created() {},
  mounted() {},

  methods: {
    mountedFormDisposal(pValue) {
      if (pValue && pValue !== null && pValue.situacao === "DESCARTE") {
        this.product = {
          mpd_id: pValue.mpd_id,
          mpd_codigo: pValue.mpd_codigo,
          mpd_novo_principio_ativo: pValue.mpd_novo_principio_ativo,
          mtu_novo_nome: pValue.mtu_novo_nome,
          mtp_nome: pValue.mtp_nome,
          estoque: pValue.estoque,
          mdc_nome: pValue.mdc_nome,
          dsc_data: pValue.dsc_data,
          dsc_quantidade: pValue.dsc_quantidade,
          dsc_obs: pValue.dsc_obs,
          lote: pValue.dsc_lote,
        };
        if (this.stockLotExpiration) this.product.dataLote = pValue.lote;
        this.getInventory();
      }
    },

    async getInventory() {
      try {
        this.$loader.start();
        const variables = {
          intIdProduto: this.product.mpd_id,
          intIdFarmacia: this.idPharmacy,
          ...(this.stockLotExpiration && { strLote: this.product.lote }),
        };
        const data = await this.$store.dispatch(
          "Pharmacy/Central/GET_QUANTITY_INVENTORY",
          variables,
        );
        this.product.estoque = data[0].estocado;
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e),
          "Erro ao carregar o estoque",
        );
      } finally {
        this.$loader.finish();
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
