<script>
import RgRemoteTypeaheadMixin from "$billing/common/component/selection/mixin/RgRemoteTypeaheadMixin";
import { SearchEthnicity, FindEthnicityByCodAb } from "./action";

export default {
  name: "RgTypeheadEthnicity",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      default: "Raça / Cor",
    },
    min: {
      default: "0",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: () => {
        return {
          ens_descricao: this.inputValue,
        };
      },
    };
  },
  methods: {
    doSearch() {
      const searchQuery = this.inputValue;

      if (Number.isInteger(+this.inputValue)) {
        return SearchEthnicity({ ens_codigo_ab: searchQuery });
      } else {
        return SearchEthnicity({ ens_descricao: searchQuery });
      }
    },
    doFind(pValue) {
      return FindEthnicityByCodAb(pValue);
    },
    formatItem(item) {
      return {
        value: item.ens_codigo_ab,
        text: item.ens_descricao,
      };
    },
  },
};
</script>
