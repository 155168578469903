import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path:
    "/exam/laboratory/schedule-exam-request/edit-patient/patient-care-history",
  name:
    "exam.laboratory.schedule-exam-request.edit-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      { label: "Exames Laboratoriais", link: "/exam/laboratory" },
      {
        label: "Agendar Pedido",
        link: "/exam/laboratory/schedule-exam-request",
      },
      {
        label: "Cadastro do Paciente",
        link: "/exam/laboratory/schedule-exam-request/edit-patient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
