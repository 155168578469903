<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListSectorsLegacy from "./action/ListSectorsLegacy";

export default {
  name: "RgSelectSectorLegacy",
  extends: RgSelect,
  props: {
    unitHealthId: {
      required: true,
      default: 0,
    },
    submoduleId: {
      required: true,
      type: Number,
      default: 0,
    },
    operationId: {
      type: Number,
      default: 0,
    },
    sectorsActive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Setor",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
  },
  data() {
    return {
      sector: [],
    };
  },
  watch: {
    sector(val) {
      this.updateOptions();
    },

    unitHealthId(pValue, pPreviously) {
      const isDiferentValue = pValue !== pPreviously;

      this.mValue = null;

      if (isDiferentValue) {
        this.doSearch();
      }
    },
  },
  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.sector;
    },

    async doSearch() {
      const hasUnitHealthId = this.unitHealthId > 0;

      if (!hasUnitHealthId) return Promise.resolve([]);

      const onlyActive = this.sectorsActive ? 1 : 0;

      const data = await ListSectorsLegacy({
        uns_id: this.unitHealthId,
        intIdSubModulo: this.submoduleId,
        blnSomenteAtivo: onlyActive,
        intIdOperacao: this.operationId,
      });

      this.sector = data.map((item) => {
        return {
          value: item.data,
          label: `${item.label}`,
        };
      });
    },

    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
