<style src="./RgInputEmail.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model="inputValue"
            type="text"
            :placeholder="placeholder"
            :maxlength="maxlength"
            autocomplete="off"
            :disabled="disabled"
            @blur="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import Email from "./RgInputEmailHelper";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";
export default {
  name: "RgInputEmail",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      default: "Email",
    },
    placeholder: {
      default: "",
    },
    maxlength: {
      default: 0,
    },
  },
  data() {
    return {
      anotherRules: {
        checkEmail(pEmail, pError) {
          return Email.checkEmail(pEmail, pError);
        },
      },
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    inputValue(pValue) {
      this.inputValue = pValue ? pValue.replace(/[\s]\w+/g, "").trim() : "";
    },
  },
  methods: {
    validateBlur() {
      this.validate();
    },
  },
};
</script>
