<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import TYPEBED from "$hospitalization/common/component/util/GetTypeBedValues.js";
export default {
  name: "RgComboboxBedType",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Situação do Leito",
    },
    defaultText: {
      type: String,
      default: "Todos",
    },
  },
  data() {
    return {
      valuesData: [
        { value: TYPEBED.VAZIO, label: "Vazio" },
        { value: TYPEBED.RESERVA, label: "Reserva" },
        { value: TYPEBED.OCUPADO, label: "Ocupado" },
        { value: TYPEBED.DESINFECCAO, label: "Desinfecção" },
        { value: TYPEBED.MANUTENCAO, label: "Manutenção" },
      ],
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData(pData) {
      const data = this.valuesData;
      data.forEach((item) => {
        this.mData.push({ value: item.value, text: item.label });
      });
      return data;
    },
  },
};
</script>
