import { isEmpty } from "lodash";
import { DateNowToDatabase, DateTimeNowToDatabase } from "~utils/date";

const PrepareExamItem = (
  exam,
  exe_id_exames_efetivacao,
  exeIdVinculos = null,
  exe_id_exames_motivos_nao_efetivacao = null,
  loggedUserId,
) => {
  return !exam
    ? {}
    : {
        examId: exam.exe_id,
        exam: {
          exe_id: exam.exe_id,
          exe_id_exames_agendamentos_procedimentos:
            exam.exe_id_exames_agendamentos_procedimentos,
          exe_id_exames_agendamentos_sub_procedimentos:
            exam.exe_id_exames_agendamentos_sub_procedimentos,
          exe_id_unidades_saude: exam.exe_id_unidades_saude,
          exe_id_pacientes: exam.exe_id_pacientes,
          exe_id_vinculos_solicitante: exam.exe_id_vinculos_solicitante,
          exe_id_boletins: exam.exe_id_boletins,
          exe_id_internacoes: exam.exe_id_internacoes
            ? exam.exe_id_internacoes
            : null,
          exe_id_setores: exam.exe_id_setores > 0 ? exam.exe_id_setores : null,
          exe_id_vinculos: exam.exe_id_vinculos || exeIdVinculos,
          exe_observacao: !isEmpty(exam.exe_observacao)
            ? exam.exe_observacao
            : null,
          exe_codigo_procedimento: !isEmpty(exam.exe_codigo_procedimento)
            ? exam.exe_codigo_procedimento
            : !isEmpty(exam.cod_procedimento)
            ? exam.cod_procedimento
            : exam.procedure
            ? exam.procedure.CO_PROCEDIMENTO
            : null,
          exe_codigo_cid:
            exam.exe_codigo_cid && exam.exe_codigo_cid.length > 0
              ? exam.exe_codigo_cid
              : null,
          exe_data_solicitacao: exam.exe_data_solicitacao
            ? exam.exe_data_solicitacao
            : DateTimeNowToDatabase(),
          exe_data_efetivacao: exam.exe_data_efetivacao
            ? exam.exe_data_efetivacao
            : null,
          exe_data_exame: exam.exe_data_exame
            ? exam.exe_data_exame
            : DateNowToDatabase(),
          exe_id_sinonimos_procedimentos: exam.exe_id_sinonimos_procedimentos,
          exe_id_exames_motivos_nao_efetivacao:
            exe_id_exames_motivos_nao_efetivacao ||
            exam.exe_id_exames_motivos_nao_efetivacao,
          exe_id_exames_efetivacao,
          loggedUserId: loggedUserId,
        },
      };
};

/**
 * @param {*} exam
 * @param {number} exe_id_exames_efetivacao Tipo da efetivação (EFETIVADO = 2, NAO_EFETIVADO = 3)
 * @param {number} exeIdVinculos ID do vínculo do profissional que executou
 * @param {number} exe_id_exames_motivos_nao_efetivacao ID do mótivo de não efetivação
 * @param {boolean} inBatch Indica se é uma ação em lote
 *
 * @returns Object | Array
 */
const PrepareExamToSave = (
  exam,
  exe_id_exames_efetivacao,
  exeIdVinculos = null,
  exe_id_exames_motivos_nao_efetivacao = null,
  inBatch = false,
  loggedUserId,
) => {
  let preparedData = null;
  if (!inBatch)
    preparedData = PrepareExamItem(
      exam,
      exe_id_exames_efetivacao,
      exeIdVinculos,
      exe_id_exames_motivos_nao_efetivacao,
      loggedUserId,
    );
  else {
    let exams = Object.values(exam);
    exams = !Array.isArray(exams) ? [exams] : [...exams];
    preparedData = exams.map((item) =>
      PrepareExamItem(
        item,
        exe_id_exames_efetivacao,
        exeIdVinculos,
        exe_id_exames_motivos_nao_efetivacao,
        loggedUserId,
      ),
    );
  }
  return preparedData;
};

export default PrepareExamToSave;
