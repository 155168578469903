import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import ServiceScale from "$exam/submodules/register/view/service-scale/ServiceScale";

export default {
  path: "/exam/register/service-scale",
  name: "exam.register.service-scale",
  components: {
    default: ServiceScale,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Cadastro", link: OpenSubModule("exam") },
      {
        label: "Escalas de Atendimento",
        link: "/exam/register/list-service-scale",
      },
      { label: "Cadastro de Escala" },
    ],
  },
};
