<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchSubproceduresPatientsQueue from "./action/SearchSubproceduresPatientsQueue";

export default {
  name: "RgSelectPatientsQueueSubprocedures",
  extends: RgSelect,
  props: {
    procedureName: {
      type: String,
      require: true,
    },
    label: {
      type: String,
      default: "Subprocedimento",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    procedureName() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (!this.procedureName || this.procedureName.length <= 0) {
        return;
      }
      const subprocedures = await SearchSubproceduresPatientsQueue(
        this.procedureName,
      );

      this.valuesData = subprocedures.map((item) => {
        return {
          value: item.data,
          label: item.label,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
