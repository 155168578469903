<template lang="html">
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8047 15.8047C13.8047 17.4615 15.1478 18.8047 16.8047 18.8047C18.4615 18.8047 19.8047 17.4615 19.8047 15.8047C19.8047 14.1478 18.4615 12.8047 16.8047 12.8047C15.1478 12.8047 13.8047 14.1478 13.8047 15.8047ZM12.8047 15.8047C12.8047 18.0138 14.5955 19.8047 16.8047 19.8047C19.0138 19.8047 20.8047 18.0138 20.8047 15.8047C20.8047 13.5955 19.0138 11.8047 16.8047 11.8047C14.5955 11.8047 12.8047 13.5955 12.8047 15.8047Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.80469 15.8047C1.80469 17.4615 3.14783 18.8047 4.80469 18.8047C6.46154 18.8047 7.80469 17.4615 7.80469 15.8047C7.80469 14.1478 6.46154 12.8047 4.80469 12.8047C3.14783 12.8047 1.80469 14.1478 1.80469 15.8047ZM0.804687 15.8047C0.804687 18.0138 2.59555 19.8047 4.80469 19.8047C7.01383 19.8047 8.80469 18.0138 8.80469 15.8047C8.80469 13.5955 7.01383 11.8047 4.80469 11.8047C2.59555 11.8047 0.804687 13.5955 0.804687 15.8047Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8047 4.19531C13.8047 5.85217 15.1478 7.19531 16.8047 7.19531C18.4615 7.19531 19.8047 5.85217 19.8047 4.19531C19.8047 2.53846 18.4615 1.19531 16.8047 1.19531C15.1478 1.19531 13.8047 2.53846 13.8047 4.19531ZM12.8047 4.19531C12.8047 6.40445 14.5955 8.19531 16.8047 8.19531C19.0138 8.19531 20.8047 6.40445 20.8047 4.19531C20.8047 1.98617 19.0138 0.195312 16.8047 0.195312C14.5955 0.195312 12.8047 1.98617 12.8047 4.19531Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.80469 6.19531L7.80469 2.19531C7.80469 1.64303 7.35697 1.19531 6.80469 1.19531L2.80469 1.19531C2.2524 1.19531 1.80469 1.64303 1.80469 2.19531L1.80469 6.19531C1.80469 6.7476 2.2524 7.19531 2.80469 7.19531L6.80469 7.19531C7.35697 7.19531 7.80469 6.7476 7.80469 6.19531ZM8.80469 2.19531C8.80469 1.09074 7.90926 0.195312 6.80469 0.195312L2.80469 0.195312C1.70012 0.195312 0.804687 1.09074 0.804687 2.19531L0.804687 6.19531C0.804687 7.29988 1.70012 8.19531 2.80469 8.19531L6.80469 8.19531C7.90926 8.19531 8.80469 7.29988 8.80469 6.19531L8.80469 2.19531Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ConfigureOptions",
};
</script>
