<style src="./ExamDayReport.scss" lang="scss" scoped></style>

<template>
  <div class="exam-day-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-edr"
          v-model="form.uns_id"
          :rules="{ required: true }"
          permission="exame2.relatoriosESUSng.Exibir"
          class="inputitem"
          @change="selectedUnitHealth"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-edr"
          v-model="form.set_id"
          :unit-health="form.uns_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          default-text="Todos"
          empty-default-text="Todos"
          class="inputitem"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgSelectProceduresFilter
          id="exe-codigo-procedimento-edr"
          ref="comboProcedures"
          v-model="form.exe_codigo_procedimento"
          class="inputitem"
          label="Procedimento"
          :sector-id="Number(form.set_id)"
          :disabled="!form.set_id"
          default-text="Todos"
          @change="getProcedureValue"
        />
      </div>
      <!-- <div class="selectinput">
        <RgSelectSubprocedures
          id="subprocedures-edr"
          v-model="subprocedures"
          class="inputitem"
          label="Subprocedimento"
          default-text="Todos"
          @change="getSubprocedureValue"
        />
      </div> -->
      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-edr"
          ref="requestedEmployee"
          v-model="form.pes_nome"
          label="Profissional"
          title="Profissional"
          placeholder="Todos"
          class="inputitem"
          @selected="selectingProfessional"
        />
      </div>

      <div class="filter-title">
        <span class="title">Período</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-edr"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="final-date-edr"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          label="Data Final"
          class="inputitem mg"
        />
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="from-queue-adr"
          :value="form.fromQueue"
          :list="ITEMSRADIOFILTER"
          uniqueKey="id"
          class="inputitem"
          label="Agendado pela Fila:"
          @input="onInputRadioQueue"
        />
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="situation-edr"
          :value="form.situation"
          :list="SITUATION"
          uniqueKey="id"
          class="inputitem"
          :disabledByModal="form.schedule"
          reorganize
          label="Situação do Exame:"
          @input="onInputRadioCustom"
        />
      </div>

      <div class="selectinput">
        <ComboboxReasonsNotEffective
          id="reason-not-effective-id-edr"
          v-model="form.reasonNotEffectiveId"
          :class="{ disable: !isNotEffectivated }"
          :disabled="!isNotEffectivated"
          label="Motivo Não Comparecimento"
          class="inputitem"
          default-text="Todos"
        />
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="schedule"
          ref="schedule"
          v-bind="propsRadioCustomSchedule"
          label="Agrupar por:"
          @input="onInputRadioCustomSchedule"
        />
      </div>

      <div class="tooltips">
        <Tooltip class="tooltip" startOpen>
          <IconInfoHelper slot="icon" hollow />
          <div slot="content" class="content">
            <div class="title">
              <span>
                Ao agrupar os resultados “Por agendamento”, o filtro “situação
                do exame” será desabilitado:
              </span>
              <br />
              <br />
              <span class="normal">
                Resultados agrupados não permitem visualizar a situação de cada
                procedimento/subprocedimento.
              </span>
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgInputDate,
  RgSelectSector,
  RgSuggestEmployee,
  RgRadioCustom,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";

import ComboboxReasonsNotEffective from "$appointment/submodules/schedule/component/combobox/combobox-reasons-not-effective/ComboboxReasonsNotEffective";
import RgSelectProceduresFilter from "$exam/submodules/schedule/component/select/rg-select-procedures-filter/RgSelectProceduresFilter";

// import RgSelectSubprocedures from "$exam/common/component/rg-select-subprocedures/RgSelectSubprocedures";
import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  uns_nome: null,
  set_id: null,
  set_nome: null,
  stp_novo_nome_procedimento: null,
  eas_nome_sub_procedimentos: null,
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  exe_codigo_procedimento: null,
  pes_id: null,
  pes_nome: null,
  fromQueue: null,
  situation: null,
  reasonNotEffectiveId: null,
  schedule: null,
  orderByLabel: "Data do Exame",
};

export default {
  name: "ExamDayReport",
  components: {
    RgSelectUnithealth,
    RgInputDate,
    RgSelectSector,
    RgSelectProceduresFilter,
    RgSuggestEmployee,
    RgRadioCustom,
    ComboboxReasonsNotEffective,
    // RgSelectSubprocedures
    Tooltip,
    IconInfoHelper,
  },

  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Dia do Exame",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      subprocedures: null,
      itemsRadioFilter: [
        {
          value: 1,
          text: "Sim",
        },
        {
          value: 2,
          text: "Não",
        },
      ],
    };
  },

  computed: {
    getNameSmartTable() {
      return "ExamDayReport";
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important;}" +
        ".report-column-2 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important }" +
        ".report-column-5 { white-space: normal !important;}" +
        ".report-column-6 { white-space: normal !important; text-align: left; }" +
        ".report-column-7 { white-space: normal !important; text-align: left; }" +
        ".report-column-10 { white-space: normal !important; text-align: left; }" +
        ".report-column-11 { white-space: normal !important }"
      );
    },
    columnTable() {
      return this.COLUMN;
    },
    isNotEffectivated() {
      return this.form.situation && this.form.situation === 3;
    },
    variableComboProcedures() {
      return {
        intIdSetor: this.form.set_id,
      };
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },

    propsRadioCustomSchedule() {
      const list = [{ title: "Por Agendamento", id: 1 }];
      const value = this.form.schedule;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
        this.form.set_nome = null;
      }
    },
    "form.set_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.exe_codigo_procedimento = null;
        this.form.stp_novo_nome_procedimento = null;
        this.form.pes_id = null;
        this.form.pes_nome = null;
      }
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },

    "form.schedule"(pValue, pPrev) {
      this.form.situation = null;
    },
  },

  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  created() {
    this.COLUMN = [
      { name: "Exame", key: "DATA_MARCACAO" },
      { name: "Prontuário Único", key: "PRONTUARIO_UNICO" },
      { name: "Prontuário da Unidade", key: "PRONTUARIO_UNIDADE" },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Sexo", key: "SEXO" },
      { name: "Idade", key: "IDADE" },
      { name: "CNS ou CPF", key: "CNS_OU_CPF" },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      {
        name: "Subprocedimento",
        key: "SUB_PROCEDIMENTO",
        align: "left",
      },
      {
        name: "Profissional de Saúde",
        key: "PROFISSIONAL_DE_SAUDE",
        align: "left",
      },
      { name: "Veio da Fila", key: "FILA" },
      { name: "Situação", key: "SITUACAO" },
      {
        name: "Motivo não Comparecimento",
        key: "MOTIVO_NAO_COMPARECIMENTO",
        align: "left",
      },
      { name: "Marcação", key: "MARCACAO", align: "left" },
      { name: "Telefones", key: "TELEFONES", align: "left" },
    ];

    this.SITUATION = [
      { title: "Em Aberto", id: 1 },
      { title: "Efetivado", id: 2 },
      {
        title: "Não Efetivado",
        red: "#F96B70",
        id: 3,
        gridStart: 1,
        gridEnd: 3,
      },
    ];
    this.ITEMSRADIOFILTER = [
      { title: "Sim", blue: true, id: 1 },
      { title: "Não", blue: true, id: 2 },
    ];
  },

  methods: {
    selectedUnitHealth(pValue) {
      if (pValue) {
        this.form.uns_nome = pValue.label;
      } else {
        this.form.uns_nome = null;
      }
    },
    getSubprocedureValue(pValue) {
      if (pValue) {
        this.form.eas_nome_sub_procedimentos = pValue.label;
      } else {
        this.form.eas_nome_sub_procedimentos = null;
      }
    },
    onInputRadioCustomSchedule(item) {
      if (item) {
        this.form.schedule = item.id;
      } else {
        this.form.schedule = null;
      }
    },
    buildFilter() {
      const columnsToPrint = this.columnsToPrint.filter(
        (column) => column.key !== "DATA_MARCACAO",
      );

      const payLoad = {
        uns_id: this.form.uns_id,
        uns_nome: this.form.uns_nome,
        set_id: this.form.set_id,
        set_nome: this.form.set_nome,
        stp_id: Number(this.form.exe_codigo_procedimento),
        stp_novo_nome_procedimento: this.form.stp_novo_nome_procedimento,
        eas_nome_sub_procedimentos: this.form.eas_nome_sub_procedimentos,
        pes_id: this.form.pes_id,
        pes_nome: this.form.pes_nome,
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        fromQueue: this.form.fromQueue,
        situation: this.form.situation,
        emn_id: Number(this.form.reasonNotEffectiveId),
        orderByLabel: this.form.orderByLabel,
        concatSchedule: this.form.schedule,
        defaultPrintTable: true,
        columnsToPrint: columnsToPrint,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Exam/Report/GET_EXAM_DAY_REPORT",
          pData,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    selectingProfessional(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.pes_id = pValue.source.pes_id;
        this.form.pes_nome = pValue.source.pes_nome;
      } else {
        this.form.pes_id = null;
        this.form.pes_nome = null;
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
    },

    getProcedureValue(pValue) {
      if (pValue) {
        this.form.stp_novo_nome_procedimento =
          pValue.item.stp_novo_nome_procedimento;
      } else {
        this.form.stp_novo_nome_procedimento = null;
      }
    },

    onInputRadioCustom(pRadio) {
      if (pRadio) {
        this.form.situation = pRadio.id;
      } else {
        this.form.situation = null;
      }
      this.form.reasonNotEffectiveId = null;
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },
    onInputRadioQueue(pRadio) {
      if (pRadio) {
        this.form.fromQueue = pRadio.id;
      } else {
        this.form.fromQueue = null;
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
