<template>
  <Modulebox
    :granted="hasAllPermissions"
    title="Objetivos de Consulta"
    class="appointment-objective"
  >
    <form
      ref="element"
      class="form-container"
      @submit.stop.prevent="saveObjective"
    >
      <FormBase title="Objetivo" class="register-form">
        <Tooltip
          class="tooltip"
          startOpen
          message="Objetivos de consulta já utilizados ou importados só podem ter o
            campo CIAP alterado."
        >
          <IconInfoHelper slot="icon" hollow />
        </Tooltip>

        <RgValidatorForm ref="validatorSave">
          <div class="form">
            <div class="inputs">
              <RgInput
                id="objective"
                ref="objective"
                v-model="form.objective"
                :rules="{ required: true }"
                :disabled="
                  disableOnEditImported || disableFieldsWhenObjectiveIsImported
                "
                :class="{
                  disable:
                    disableOnEditImported ||
                    disableFieldsWhenObjectiveIsImported,
                }"
                :maxlength="255"
                label="Objetivo"
                placeholder="Digite o nome do novo objetivo"
              />

              <RgSelectUnithealth
                id="unit-health"
                ref="unitHealth"
                v-model="form.unitHealth"
                :rules="{ required: true }"
                :disabled="
                  this.edit ||
                  !this.canInclude ||
                  disableFieldsWhenObjectiveIsImported
                "
                :class="{
                  disable:
                    this.edit ||
                    !this.canInclude ||
                    disableFieldsWhenObjectiveIsImported,
                }"
                permission="consulta.objetivoConsulta"
                label="Unidade de Saúde"
              />

              <RgComboboxHealthPlan
                id="health-insurance"
                ref="healthInsurance"
                v-model="form.healthInsurance"
                :rules="{ required: true }"
                :disabled="
                  (!canInclude && !disableWhenEditing) ||
                  disableFieldsWhenObjectiveIsImported
                "
                :class="{
                  disable:
                    (!canInclude && !disableWhenEditing) ||
                    disableFieldsWhenObjectiveIsImported,
                }"
                label="Convênio"
              />

              <RgInput
                id="code"
                ref="code"
                v-model="form.code"
                :rules="{ required: true }"
                :disabled="
                  disableOnEditImported || disableFieldsWhenObjectiveIsImported
                "
                :class="{
                  disable:
                    disableOnEditImported ||
                    disableFieldsWhenObjectiveIsImported,
                }"
                :maxlength="10"
                label="Código"
                placeholder="000000000000"
              />

              <RgInput
                id="cost"
                ref="cost"
                v-model="form.cost"
                :rules="{ required: true, fn: validateCost }"
                :disabled="
                  (!canInclude && !disableWhenEditing) ||
                  disableFieldsWhenObjectiveIsImported
                "
                :class="{
                  disable:
                    (!canInclude && !disableWhenEditing) ||
                    disableFieldsWhenObjectiveIsImported,
                }"
                :maxlength="12"
                label="Custo"
                placeholder="000000,00"
              />

              <RgSuggestCiapTypeClassification
                id="ciap"
                ref="ciap"
                v-model="ciap"
                label="Ciap"
                :rules="{ forceSelection: true }"
                @selected="selectingCiap"
              />
            </div>

            <div class="buttons">
              <RgCleanButton
                v-if="!enableSaveEditButton || !selectedRow.tcu_id"
                id="clean-form"
                :permission="canInclude"
                @click="cleanForm"
              />

              <RgAddButton
                v-if="!enableSaveEditButton || !selectedRow.tcu_id"
                id="new-objective"
                :permission="canInclude"
                large
                @click="saveObjective"
              />

              <RgCancelButton
                v-if="edit"
                id="cancel-edit"
                medium
                @click="cancelEdit"
              />

              <RgSaveButton
                v-if="edit"
                id="save-edit"
                ref="save"
                :permission="canEdit"
                large
                @click="saveEditObjective"
              />
            </div>
          </div>

          <div class="separator" />

          <div class="import">
            <span class="info">
              <strong>
                Incluir objetivos da base de dados existente
                <i> (Tabela Unificada ou TUSS) </i>:
              </strong>
            </span>
            <LargeButton
              id="import"
              v-shortkey="['enter']"
              :label="'Importar'"
              title="Importar"
              :backgroundColor="'#1E88A9'"
              :permission="canInclude"
              class="import-button"
              @click="openModalImport"
            >
              <div slot="icon" class="icon">
                <IconArrowDownload />
              </div>
            </LargeButton>
          </div>
        </RgValidatorForm>
      </FormBase>

      <FormBase title="Objetivos Cadastrados" class="table-form">
        <div class="filter">
          <RgValidatorForm ref="validatorSearch">
            <RgSelectUnithealth
              id="search-unit-health"
              ref="searchUnitHealth"
              v-model="search.unitHealth"
              label="Unidade de Saúde"
              permission="consulta.objetivoConsulta"
              :rules="{ required: true }"
            />
          </RgValidatorForm>

          <RgInput
            id="search-objective"
            v-model="search.objective"
            label="Objetivo"
            placeholder="Digite o nome do objetivo"
          />

          <RgComboboxHealthPlan
            id="search-health-insurance"
            v-model="search.healthInsurance"
            label="Convênio"
          />

          <RgInput
            id="search-code"
            v-model="search.code"
            label="Código"
            placeholder="0000000000000"
          />

          <div class="buttons">
            <RgCleanButton
              id="clean-search"
              :permission="canShow"
              @click="cleanFilter"
            />
            <RgSearchButton
              id="search"
              ref="search"
              :permission="canShow"
              @submit="searchObjectives"
            />
          </div>

          <div class="edit">
            <RgEditButton
              id="edit-objective"
              large
              :class="{
                disabled: disableEditButton || disableWhenEditing,
              }"
              :disabled="disableEditButton || disableWhenEditing"
              :permission="canEdit"
              @click="editObjective"
            />
          </div>
        </div>

        <div
          v-if="showObjectiveList"
          class="table"
          :disabled="disableWhenEditing"
          :class="{
            disable: disableWhenEditing,
          }"
        >
          <RgTable :columns="COLUMNS" class="rg-table">
            <tr
              v-for="item in objectiveList"
              :key="item.tcu_id"
              slot="rows"
              class="tr"
              :class="{
                selected: selectedRow.tcu_id === item.tcu_id,
              }"
              @click="selectRow(item)"
            >
              <td class="objective">
                <span :title="item.tcu_novo_tipo_consulta" class="ellipsis">
                  {{ item.tcu_novo_tipo_consulta }}
                </span>
              </td>
              <td>
                {{ item.pls_nome }}
              </td>
              <td>
                {{ item.tcu_novo_codigo_procedimento }}
              </td>
              <td>
                {{ item.tcu_novo_custo }}
              </td>
              <td>
                {{ item.ciap_codigo }}
              </td>
              <td class="toggle">
                <RgToggleButton
                  v-model="item.tcu_ativo"
                  v-bind="propsToggleButton"
                  class="toggle"
                  :disabled="
                    (item.tcu_ativo && !canInactive) ||
                    (!item.tcu_ativo && !canInclude) ||
                    item.disable
                  "
                  @input="(toogle) => changeToggle(item, toogle)"
                />
              </td>
            </tr>
          </RgTable>
        </div>

        <div
          v-else-if="
            permission.showObjective &&
            (!objectiveList || objectiveList.length < 1)
          "
          class="empty-result"
        >
          <IconEmpty />
          <span>Não foram encontrados resultados para essa busca</span>
        </div>

        <RgList
          v-show="objectiveList && objectiveList.length > 0"
          ref="rgList"
          v-model="objectiveList"
          :search-function="searchList"
          :build-filter="generateFilter"
          :register-per-page="10"
          :max-register="10"
          :disabled="disableWhenEditing"
          :class="{
            disable: disableWhenEditing,
          }"
          backendLegacy
          class="list"
        >
        </RgList>
      </FormBase>
    </form>

    <ModalImportObjective
      id="modal-import-objective"
      :show="modalImport"
      @research="searchObjectives"
      @close="closeModalImport"
    />
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";
import { Modulebox } from "~tokio/foundation";
import {
  RgValidatorForm,
  RgInput,
  RgCleanButton,
  RgAddButton,
  RgSearchButton,
  RgSaveButton,
  RgEditButton,
  RgSelectUnithealth,
  LargeButton,
  IconArrowDownload,
  IconEmpty,
  RgToggleButton,
  RgCancelButton,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTable from "~tokio/foundation/rg-table/RgTable";
import RgList from "~tokio/foundation/rg-list/RgList";

import RgComboboxHealthPlan from "$appointment/submodules/register/component/combobox/rg-combobox-health-plan/RgComboboxHealthPlan";
import ModalImportObjective from "$appointment/submodules/register/component/modal/ModalImportObjective";
import RgSuggestCiapTypeClassification from "~tokio/primitive/suggest/rg-suggest-ciap-type-classification/RgSuggestCiapTypeClassification";

export default {
  name: "AppointmentObjective",
  components: {
    Modulebox,
    FormBase,
    RgInput,
    RgValidatorForm,
    RgCleanButton,
    RgAddButton,
    RgSearchButton,
    RgSaveButton,
    RgEditButton,
    LargeButton,
    RgSelectUnithealth,
    RgComboboxHealthPlan,
    ModalImportObjective,
    RgTable,
    RgToggleButton,
    RgCancelButton,
    IconEmpty,
    IconArrowDownload,
    RgList,
    RgSuggestCiapTypeClassification,
    Tooltip,
    IconInfoHelper,
  },

  data() {
    return {
      objectiveList: [],
      selectedRow: {},
      modalImport: false,
      enableSaveEditButton: false,
      edit: false,
      hasObjectives: null,
      ciap: null,
      form: {
        objective: null,
        unitHealth: null,
        healthInsurance: null,
        code: null,
        cost: null,
        ciapId: null,
      },
      search: {
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        objective: null,
        code: null,
        healthInsurance: null,
      },
      alreadyLinked: false,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    permission() {
      return {
        addObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.incluir",
          this.unitHealthId,
        ),
        deleteObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.excluir",
          this.unitHealthId,
        ),
        showObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.exibir",
          this.unitHealthId,
        ),
        editObjective: !!this.$Permissions.global.has(
          "consulta.objetivoConsulta.alterar",
          this.unitHealthId,
        ),
      };
    },

    hasAllPermissions() {
      return (
        this.permission.addObjective ||
        this.permission.deleteObjective ||
        this.permission.showObjective ||
        this.permission.editObjective
      );
    },

    canInclude() {
      return this.permission.addObjective;
    },

    canInactive() {
      return this.permission.deleteObjective;
    },

    canEdit() {
      return this.permission.editObjective;
    },

    canShow() {
      return (
        this.permission.addObjective ||
        this.permission.editObjective ||
        this.permission.deleteObjective ||
        this.permission.showObjective
      );
    },

    isImported() {
      return (
        this.selectedRow?.tcu_id_tabelas_unificadas !== "1" &&
        this.selectedRow?.tcu_id_tuss !== "1"
      );
    },

    showObjectiveList() {
      return this.canShow && this.objectiveList.length > 0;
    },

    disableEditButton() {
      return (
        !this.selectedRow ||
        !this.selectedRow.tcu_id ||
        !this.selectedRow.tcu_ativo
      );
    },

    disableWhenEditing() {
      return this.edit;
    },

    disableOnEditImported() {
      return this.edit
        ? this.alreadyLinked || this.isImported
        : !this.canInclude;
    },

    disableFieldsWhenObjectiveIsImported() {
      return this.edit && this.isImported;
    },

    propsToggleButton() {
      return {
        truthyText: "ATIVO",
        falsyText: "INATIVO",
        width: 30,
        height: 10,
        fontSize: 10,
        center: true,
        externalLabel: true,
        valueSync: true,
      };
    },

    isToDisable() {
      return (
        (!this.canInclude && !this.disableWhenEditing) || this.alreadyLinked
      );
    },
  },

  watch: {
    "search.unitHealth"(pValue, pPreviously) {
      if (!pValue) {
        this.objectiveList = [];
      }

      if (pValue !== pPreviously) {
        this.cleanForm();
        this.selectedRow = {};
        this.enableSaveEditButton = false;
      }
    },
  },

  async mounted() {
    if (this.canShow) {
      this.searchObjectives();
    }
  },

  created() {
    this.COLUMNS = [
      { name: "Objetivo", key: "tcu_novo_tipo_consulta" },
      { name: "Convênio", key: "pls_nome" },
      { name: "Código", key: "tcu_novo_codigo_procedimento" },
      { name: "Custo", key: "tcu_novo_custo" },
      { name: "CIAP", key: "ciap_codigo" },
      { name: "Situação", key: "tcu_ativo" },
    ];
  },

  methods: {
    async isFormValid() {
      return this.$refs.validatorSave
        ? this.$refs.validatorSave.validate()
        : false;
    },

    async isFormSearchValid() {
      return this.$refs.validatorSearch
        ? this.$refs.validatorSearch.validate()
        : false;
    },

    selectRow(pItem) {
      if (pItem.tcu_id === this.selectedRow.tcu_id) {
        this.cleanForm();
        this.edit = false;
        this.enableSaveEditButton = false;
        this.selectedRow = {};
        return;
      }

      if (!this.edit) {
        this.selectedRow = pItem;
      }
    },

    formatDataSave() {
      const variables = {
        tcu_id_planos_saude: Number(this.form.healthInsurance),
        tcu_id_unidades_saude: Number(this.form.unitHealth),
        tcu_novo_codigo_procedimento: this.form.code,
        tcu_novo_custo: this.form.cost,
        tcu_novo_tipo_consulta: this.form.objective,
        tcu_id_ab_ciap: this.form.ciapId,
      };
      return variables;
    },

    async saveObjective() {
      try {
        this.$loader.start();
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        const variables = this.formatDataSave();

        await this.$store.dispatch(
          "Appointment/Register/ADD_APPOINTMENT_OBJECTIVE",
          variables,
        );

        this.$toaster.success("Objetivo cadastrado com sucesso");
        this.cleanForm();
        await this.$refs.rgList.submitForm(true);
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao salvar objetivo.",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao salvar objetivo");
        }
      } finally {
        this.$loader.finish();
      }
    },

    async editObjective() {
      this.setScrollTop();

      this.form = {
        objective: this.selectedRow.tcu_novo_tipo_consulta,
        healthInsurance: this.selectedRow.pls_id,
        unitHealth: this.search.unitHealth,
        code: this.selectedRow.tcu_novo_codigo_procedimento,
        cost: this.selectedRow.tcu_novo_custo.replace(/(\.)/gi, ""),
        ciapId: this.selectedRow.tcu_id_ab_ciap,
      };

      if (this.$refs.ciap) {
        await this.$refs.ciap.forceSelection({
          cia_id: Number(this.selectedRow.tcu_id_ab_ciap),
          cia_descricao: this.selectedRow.ab_ciap,
          cia_codigo: this.selectedRow.ciap_codigo,
        });
      }

      this.alreadyLinked = !!Number(this.selectedRow.tipo_usado);
      this.edit = true;
      this.enableSaveEditButton = true;
    },

    formatDataEdit() {
      const variables = {
        tcu_id: this.selectedRow.tcu_id,
        tcu_id_planos_saude: Number(this.form.healthInsurance),
        tcu_id_tabelas_unificadas: this.selectedRow.tcu_id_tabelas_unificadas,
        tcu_id_tuss: this.selectedRow.tcu_id_tuss,
        tcu_id_unidades_saude: Number(this.form.unitHealth),
        tcu_novo_codigo_procedimento: Number(this.form.code),
        tcu_novo_custo: this.form.cost,
        tcu_novo_tipo_consulta: this.form.objective,
        tcu_id_ab_ciap: this.form.ciapId,
      };
      return variables;
    },

    async saveEditObjective() {
      try {
        const isInvalid = !(await this.isFormValid());

        if (isInvalid) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        const variables = this.formatDataEdit();

        await this.$store.dispatch(
          "Appointment/Register/EDIT_APPOINTMENT_OBJECTIVE",
          variables,
        );

        this.selectedRow = {};
        this.enableSaveEditButton = false;
        this.cleanForm();
        await this.$refs.rgList.submitForm(true);

        this.$toaster.success("Objetivo salvo com sucesso");
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao salvar objetivo",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao salvar objetivo");
        }
      } finally {
        this.$refs.save.actionDone();
      }
    },

    generateFilter() {
      const variables = {
        arrFiltros: {
          tcu_novo_codigo_procedimento: this.search.code,
          tcu_novo_tipo_consulta: this.search.objective,
          intIdUnidade: this.search.unitHealth,
          pls_id: this.search.healthInsurance,
        },
      };
      return variables;
    },

    searchList(pData) {
      return this.$store.dispatch(
        "Appointment/Register/SEARCH_APPOINTMENT_OBJECTIVE",
        {
          ...pData,
        },
      );
    },

    async searchObjectives() {
      const valid = await this.isFormSearchValid();

      if (!valid && !this.search.unitHealth) {
        this.$toaster.warning("Verifique os campos");
        this.$refs.search.actionSubmit();
        this.$refs.search.actionDone();
        return false;
      }

      this.$loader.start("Carregando...");
      await this.$refs.rgList.submitForm(true);
      this.$refs.search.actionDone();
      this.$loader.finish();
    },

    formatDataChange(pItem) {
      const variables = {
        tcu_id: pItem.tcu_id,
        tcu_id_planos_saude: Number(pItem.pls_id),
        tcu_id_tabelas_unificadas: pItem.tcu_id_tabelas_unificadas,
        tcu_id_tuss: pItem.tcu_id_tuss,
        tcu_id_unidades_saude: Number(this.search.unitHealth),
        tcu_novo_codigo_procedimento: pItem.tcu_novo_codigo_procedimento,
        tcu_novo_custo: pItem.tcu_novo_custo,
        tcu_novo_tipo_consulta: pItem.tcu_novo_tipo_consulta,
      };
      return variables;
    },

    async changeToggle(pItem, pValue) {
      try {
        const variables = this.formatDataChange(pItem);

        if (!pValue) {
          this.$loader.start("Alterando situação...");
          await this.$store.dispatch(
            "Appointment/Register/INACTIVE_APPOINTMENT_OBJECTIVE",
            variables,
          );
        } else {
          this.$loader.start("Alterando situação...");
          await this.$store.dispatch(
            "Appointment/Register/ACTIVE_APPOINTMENT_OBJECTIVE",
            variables,
          );
        }

        this.$toaster.success("Situação alterada com sucesso");
        await this.$refs.rgList.submitForm(true);
      } catch (Err) {
        this.$toaster.error(Err.message);
      } finally {
        this.$loader.finish();
      }
    },

    validateCost(pValue, pErrors) {
      if (pValue && pValue.length > 0) {
        const regexComma = /^[0-9]+(,[0-9]{1,2})?$/;
        const regexDot = /^[0-9]+(.[0-9]{1,2})?$/;

        const testComma = regexComma.test(pValue);
        const testDot = regexDot.test(pValue);

        if (!testComma && !testDot) {
          pErrors.push("É obrigatório que o campo seja decimal.");
          return false;
        }

        const caracterSplit = pValue.includes(".") ? "." : ",";
        const before = pValue.split(caracterSplit)[1];
        if (before?.length <= 1) {
          this.form.cost = pValue + "0";
        }

        return true;
      }
    },

    openModalImport() {
      this.modalImport = true;
    },

    closeModalImport() {
      this.modalImport = false;
    },

    setScrollTop() {
      const elementContainer = this.$refs.element;
      if (this.$refs.element) {
        elementContainer.scrollTop = 0;
      }
    },

    cancelEdit() {
      this.form.objective = null;
      this.form.unitHealth = null;
      this.form.healthInsurance = null;
      this.form.code = null;
      this.form.cost = null;
      this.form.ciapId = null;

      this.edit = false;
      this.alreadyLinked = false;
      this.enableSaveEditButton = false;
      this.selectedRow = {};
      this.ciap = null;
    },

    cleanForm() {
      this.form.objective = null;
      this.form.unitHealth = null;
      this.form.healthInsurance = null;
      this.form.code = null;
      this.form.cost = null;
      this.form.ciapId = null;
      this.ciap = null;

      this.edit = false;
      this.alreadyLinked = false;

      this.$refs.objective.cleanValidate();
      this.$refs.unitHealth.cleanValidate();
      this.$refs.healthInsurance.cleanValidate();
      this.$refs.code.cleanValidate();
      this.$refs.cost.cleanValidate();
    },

    async cleanFilter() {
      if (this.$refs.searchUnitHealth) {
        setTimeout(() => {
          this.$refs.searchUnitHealth.cleanValidate();
        }, 100);
      }

      this.edit = false;
      this.enableSaveEditButton = false;
      this.selectedRow = {};

      this.search.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.search.objective = null;
      this.search.healthInsurance = null;
      this.search.code = null;

      await this.searchObjectives();
    },

    selectingCiap(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;

      if (hasSuggestion) {
        this.$loader.start();
        this.form.ciapId = pValue.source.cia_id;
        this.$loader.finish();
      } else {
        this.form.ciapId = null;
        this.ciap = null;
      }
    },

    drawAbCode(pItem) {
      const hasValue =
        pItem?.ciap_codigo?.length > 0 && pItem?.ab_ciap?.length > 0;

      return hasValue ? `${pItem.ciap_codigo} - ${pItem.ab_ciap}` : "-";
    },
  },
};
</script>
