<style
  src="./HospitalizationBillingPatientInfo.scss"
  lang="scss"
  scoped
></style>
<template lang="html">
  <section class="hospitalization-billing-patient-info">
    <RgForm
      ref="form"
      v-shortkey="['ctrl', 's']"
      :action-external-disabled-status="!canSave"
      :show-next-button="true"
      class="form"
      @shortkey.native="saveByShortKey"
      @submit="savePatientTabInfo"
      @nextClicked="increaseStepProgress"
    >
      <div class="row">
        <RgInputSuscard
          ref="patientCns"
          v-model="patient.icf_cns"
          :another-rules="validatePatientCns"
          class="col-2"
          label="Cartão SUS"
          placeholder="000000000000000"
        />
        <RgSuggestPatient
          ref="validateInputName"
          v-model="patient.icf_nome"
          v-shortkey="['ctrl', 'i']"
          :rules="{ fn: validateName }"
          :disabled="isIndigent"
          class="col-5"
          label="Nome do Paciente"
          placeholder="Digite o nome do paciente"
          @shortkey.native="setIndigent"
          @selected="getPatient"
          @blur="setNames"
        />

        <RgInputMasked
          v-if="isIndigent"
          v-model="approximateAge"
          :mask="['XX']"
          :rules="{ required: isIndigent }"
          class="col-1 birth-field"
          label="Idade Aproximada"
        />

        <RgInputDate
          v-model="patient.icf_data_nascimento"
          :disabled="isIndigent"
          :rules="{ required: validationTabs }"
          class="col-2 birth-field"
          label="Nascimento"
          placeholder="00/00/0000"
          @blur="setResponsable"
        />

        <RgRadioCustom
          id="gender"
          ref="gender"
          v-bind="propsRadioCustom"
          class="col-2"
          :canUnselect="false"
          label="Sexo"
          :rules="{ required: true }"
          @input="onInputRadioCustom"
        />
      </div>

      <div class="row">
        <RgInput
          v-model="patient.icf_nome_mae"
          :rules="{ fn: validateOtherNames }"
          :disabled="isIndigent"
          class="col-5"
          label="Nome da Mãe"
          placeholder="Digite o nome da mãe"
          @blur="setNames"
        />

        <RgInput
          v-model="patient.icf_nome_responsavel"
          :rules="{ fn: validateOtherNames }"
          :disabled="isIndigent"
          class="col-5"
          label="Nome do Responsável"
          placeholder="Digite o nome do responsável"
          @blur="setNames"
        />

        <RgInputCep
          v-model="patient.icf_cep"
          :rules="{ required: validationTabs, fn: validateCepExists }"
          class="col-2"
          label="CEP"
          placeholder="00000-000"
          @blur="fillAddress"
        />
      </div>

      <div class="row">
        <RgInput
          v-model="patient.icf_logradouro"
          :rules="{ required: validationTabs, fn: validateSpecialCharacter }"
          class="col-4"
          label="Endereço"
          placeholder="Nome da rua. Ex. Atlântica"
        />

        <RgInputMasked
          v-model="patient.icf_logradouro_numero"
          :rules="{ fn: validateSpecialCharacter }"
          :mask="['##########']"
          class="col-1"
          label="Número"
          placeholder="Ex.: 56"
        />

        <RgInput
          v-model="patient.icf_complemento"
          :rules="{ fn: validateSpecialCharacter }"
          class="col-4"
          label="Complemento"
          placeholder="Ex.: Casa"
        />

        <RgInput
          v-model="patient.icf_bairro"
          :rules="{ fn: validateNeighborhood }"
          class="col-3"
          label="Bairro"
          placeholder="Ex.: Campo Grande"
        />
      </div>

      <div class="row">
        <RgTypeaheadPublicPlace
          ref="publicPlace"
          v-model="patient.icf_cod_tipo_logradouro"
          :rules="{ required: validationTabs }"
          class="col-2"
          label="Logradouro"
          placeholder="Ex.: Rua"
        />

        <RgTypeaheadCounty
          v-model="patient.icf_cod_municipio"
          :rules="{ required: validationTabs }"
          class="col-4"
          label="Município"
          placeholder="Digite o município"
        />

        <RgInputPhone
          v-model="dddTelephone"
          :rules="{ fn: validatePhone }"
          class="col-2"
          label="Telefone"
          placeholder="00000-0000"
        />

        <RgTypeaheadNationality
          ref="nationality"
          v-model="patient.icf_cod_nacionalidade"
          :rules="{ required: validationTabs && !isIndigent }"
          class="col-4"
          label="Nacionalidade"
          placeholder="Digite o nome do país"
        />
      </div>

      <div class="row">
        <RgComboboxRaceCor
          v-model="patient.icf_cod_raca"
          :rules="{ required: validationTabs }"
          class="col-3"
          label="Raça/Cor"
          placeholder="Selecione"
        />

        <RgTypeaheadEthnicity
          v-model="patient.icf_cod_etnia"
          :rules="{ required: validationTabs && !disableEthnicity }"
          :class="{ disabled: disableEthnicity }"
          :disabled="disableEthnicity"
          class="col-3"
          label="Etnia"
          placeholder="Selecione"
        />
        <RgComboboxDocumentType
          v-model="patient.icf_tipo_documento"
          :rules="{ required: validationTabs && !isIndigent }"
          class="col-3"
          label="Tipo de Documento"
          @change="changeDocumentByType"
        />
        <RgInputMasked
          v-model="patient.icf_documento"
          :mask="documentMask"
          :rules="{
            required: validationTabs && !isIndigent && !disableDocument,
          }"
          :disabled="disableDocument"
          :class="{ disabled: disableDocument }"
          class="col-3"
          label="Número do Documento"
          placeholder="Digite o número do documento"
        />
      </div>
    </RgForm>
  </section>
</template>
<script>
import EventBus from "~common/event/bus/EventBus";
import moment from "moment";
import { isEmpty } from "lodash";
import mask from "vue-the-mask";
import { RgForm } from "~tokio/foundation/form";
import {
  RgSuggestPatient,
  RgInput,
  RgInputMasked,
  RgInputDate,
  RgInputPhone,
  RgInputCep,
  RgRadioCustom,
} from "~tokio/primitive";
import RgTypeaheadNationality from "$billing/common/component/rg-typeahead-nationality/RgTypeaheadNationality";
import CheckCEP from "$billing/billing/action/CheckCEP";
import RgTypeaheadPublicPlace from "$billing/common/component/rg-typeahead-public-place/RgTypeaheadPublicPlace";
import RgTypeaheadCounty from "$billing/common/component/rg-typeahead-county/RgTypeaheadCounty";
import RgTypeaheadEthnicity from "$billing/common/component/rg-typeahead-ethnicity/RgTypeaheadEthnicity";
import RgComboboxRaceCor from "$billing/common/component/rg-combobox-race-cor/RgComboboxRaceCor";
import RgComboboxDocumentType from "$billing/submodules/aih/hospitalization-billing/common/component/combobox/rg-combobox-document-type/RgComboboxDocumentType";
import RgInputSuscard from "$billing/submodules/aih/hospitalization-billing/common/component/input/rg-input-suscard/RgInputSuscard";
import { removeSpecialCharacters } from "$billing/submodules/aih/hospitalization-billing/store/actions/HospitalizationBillingAihHelper";
import DocumentTypes from "$billing/submodules/aih/hospitalization-billing/common/service/DocumentTypes";
import { AlertError, ValidatePhone } from "../../../service/";

export default {
  name: "PatientInfo",
  components: {
    RgInput,
    RgInputMasked,
    RgInputDate,
    RgInputSuscard,
    RgTypeaheadNationality,
    RgTypeaheadPublicPlace,
    RgTypeaheadCounty,
    RgTypeaheadEthnicity,
    RgComboboxDocumentType,
    RgComboboxRaceCor,
    RgInputPhone,
    RgInputCep,
    RgSuggestPatient,
    RgForm,
    RgRadioCustom,
  },
  directives: {
    mask,
  },
  props: {
    validationTabs: {
      type: Boolean,
      default: false,
    },
    savingFather: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      patient: {
        icf_id: null,
        icf_nome: "",
        icf_nome_mae: "",
        icf_nome_responsavel: "",
        icf_data_nascimento: "",
        icf_cod_raca: "99",
        icf_cod_etnia: "",
        icf_cns: "",
        icf_sexo: "M",
        icf_tel: "",
        icf_tel_ddd: "",
        icf_cod_tipo_logradouro: "",
        icf_logradouro: "",
        icf_logradouro_numero: "",
        icf_complemento: "",
        icf_cep: "",
        icf_bairro: "",
        icf_cod_nacionalidade: "",
        icf_cod_municipio: "",
        icf_uf: "",
        icf_tipo_documento: "",
        icf_documento: null,
        icf_indigente: "0",
      },
      disableEthnicity: true,
      disableDocument: false,
      patientName: "",
      saveEnabled: true,
      selectingPatient: "",
      dddTelephone: "",
      approximateAge: "",
      documentMask: "###.###.###-##",
      changesDocumentType: 0,
      patientSearchedValue: true,
      patientId: null,
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.patient.icf_sexo;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    patientInfoInState() {
      return Object.assign(
        {},
        this.$store.getters[
          "Billing/HospitalizationBilling/GET_PATIENT_INFO_TAB"
        ],
      );
    },
    hospitalizationBillingCharacter() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_CHARACTER"
      ];
    },
    patientSearched() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_PATIENT_SEARCHED"
      ];
    },
    hospitalizationAihReleaseRequestCode() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE"
      ];
    },
    hasAdulthood() {
      let years = null;
      if (this.patient.icf_data_nascimento) {
        const birthYear = moment(
          this.patient.icf_data_nascimento,
          "DD/MM/YYYY",
        );
        years = moment().diff(birthYear, "years", false);
      }
      return years >= 18;
    },
    concatDDDTelephone() {
      return this.patient.icf_tel_ddd + this.patient.icf_tel;
    },
    isIndigent() {
      return parseInt(this.patient.icf_indigente) > 0;
    },
    canSave() {
      return !this.savingFather && !this.saving;
    },
  },
  watch: {
    patientSearched(pValue) {
      this.patientSearchedValue = pValue;
    },
    patientInfoInState(pData) {
      this.$nextTick(async () => {
        const document = this.patient.icf_documento;
        const documentType = this.patient.icf_tipo_documento;

        this.patient = Object.assign({}, this.patient, pData);

        if (!isEmpty(document)) {
          this.patient.icf_tipo_documento = documentType;
          this.patient.icf_documento = document;
        }

        if (!pData.icf_indigente) this.patient.icf_indigente = "0";
        else
          this.approximateAge =
            moment().year() -
            moment(this.patient.icf_data_nascimento, "DD/MM/YYYY").year();

        if (!this.patient.icf_id && this.patientSearchedValue) {
          this.selectTheFirstDocument();
          this.$store.commit(
            "Billing/HospitalizationBilling/SET_PATIENT_SEARCHED",
            false,
          );
        }
        this.setResponsable();
      });
    },
    concatDDDTelephone() {
      this.dddTelephone = this.concatDDDTelephone;
    },
    "patient.icf_cod_raca"() {
      this.disableEthnicity = !(this.patient.icf_cod_raca === "05");
      if (this.disableEthnicity) this.patient.icf_cod_etnia = "";
    },
    "patient.icf_nome"() {
      if (this.patient.icf_nome !== null && this.patient.icf_nome.length < 1) {
        this.$store.commit(
          "Billing/HospitalizationBilling/DESTROY_PATIENT_INFO_TAB",
        );
      }
    },
    "patient.icf_cns"(pData) {
      this.setPatientCns(pData);
    },
    "patient.icf_bairro"(pBairro) {
      if (pBairro) {
        const res = pBairro.replace(new RegExp("[{()}]", "g"), "");
        this.patient.icf_bairro = res.toUpperCase();
      }
    },
    "patient.icf_tipo_documento"(pData) {
      this.$nextTick(async () => {
        this.updateDisabledDocument();
        this.changeDocumentByType(pData);
      });
    },
    approximateAge() {
      const possibleBirth = moment()
        .add(-this.approximateAge, "years")
        .format("L");
      this.patient.icf_data_nascimento = possibleBirth;
    },
    hospitalizationBillingCharacter(pData) {
      this.$refs.patientCns.validate();
    },
    hospitalizationAihReleaseRequestCode(pData) {
      this.$refs.patientCns.validate();
    },
    savingFather(pData) {
      this.saving = pData;
    },
  },
  mounted() {
    // Escutando para atualizar o vuex
    EventBus.$on("updateVuex", this.setDataToState);
    EventBus.$on("resetData", this.resetData);
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.patient.icf_sexo = item.id;
      }
    },
    async saveByShortKey(pError, pRequestAction) {
      this.$refs.form.doAction(pRequestAction);
    },
    async savePatientTabInfo(pError, pRequestAction) {
      this.$nextTick(async () => {
        if (!pError && this.canSave) {
          this.saving = true;
          this.$emit("saveInfoTabs", pRequestAction);
          return true;
        }
      });

      return false;
    },
    increaseStepProgress() {
      this.$emit("increaseStepProgress", 1);
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$nextTick(async () => {
        this.patient.icf_cod_raca = "99";
        this.patient.icf_cod_etnia = "";
        this.patient.icf_cns = "";
        this.patient.icf_sexo = "M";
        if (this.$refs.publicPlace) this.$refs.publicPlace.inputValue = null;
        if (this.$refs.nationality) this.$refs.nationality.inputValue = null;
      });
    },
    async changeDocumentByType(pValue) {
      if (!pValue) return;
      const value = pValue[0] ? pValue[0].value : pValue;
      this.updateDisabledDocument(value);
      this.setMask(value);

      if (this.disableDocument) {
        this.patient.icf_documento = null;
      } else {
        const documentNumbers = Object.assign(
          {},
          await this.$store.getters[
            "Billing/HospitalizationBilling/GET_PATIENT_DOCUMENTS"
          ],
        );
        const patientData = this.$store.getters[
          "Billing/HospitalizationBilling/GET_PATIENT_INFO_TAB"
        ];
        if (!this.patient.icf_id) {
          this.patient.icf_documento = documentNumbers[value]
            ? documentNumbers[value]
            : null;
        } else {
          this.patient.icf_documento =
            this.changesDocumentType > 1 ? null : patientData.icf_documento;
        }
      }
      this.changesDocumentType++;
    },
    trimNames() {
      this.patient.icf_nome = this.patient.icf_nome
        ? this.patient.icf_nome.trim()
        : null;
      this.patient.icf_nome_mae = this.patient.icf_nome_mae
        ? this.patient.icf_nome_mae.trim()
        : null;
      this.patient.icf_nome_responsavel = this.patient.icf_nome_responsavel
        ? this.patient.icf_nome_responsavel.trim()
        : null;
    },
    getFirstDocumentTypeNotNull(pDocuments) {
      for (const key in pDocuments) {
        if (pDocuments[key]) return parseInt(key);
      }
    },
    selectTheFirstDocument() {
      if (!this.patient.icf_id) {
        const documentNumbers = Object.assign(
          {},
          this.$store.getters[
            "Billing/HospitalizationBilling/GET_PATIENT_DOCUMENTS"
          ],
        );

        if (!isEmpty(documentNumbers)) {
          const documentType = this.getFirstDocumentTypeNotNull(
            documentNumbers,
          );
          this.patient.icf_tipo_documento = documentType;
          this.patient.icf_documento = documentNumbers[documentType];
        }
      }
    },
    setPatientSearched(pValue = true) {
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_PATIENT_SEARCHED",
        pValue,
      );
    },
    getPatient(pName) {
      this.patientId = null;
      // this.$refs.validateInputName.innerSuggest.cleanValidate()
      if (
        pName.source &&
        pName.source.pes_nome &&
        pName.source.pes_nome.length > 2
      ) {
        this.patientId = pName.source.pes_id;
        this.$refs.validateInputName.validate();
        this.setPatientSearched();
        this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_PATIENT_INFO",
          {
            personId: pName.source.pes_id,
            personName: pName.source.pes_nome,
          },
        );
      }
    },
    setPatientCns(pCns) {
      this.patientSearchedValue = false;
      this.setPatientSearched(this.patientSearchedValue);
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_PATIENT_CNS",
        pCns || this.patient.icf_cns,
      );
    },
    tabIsValid(pErr) {
      return this.$refs.form.validate();
    },
    fillAddress() {
      const pCep = this.patient.icf_cep;
      if (isEmpty(pCep) || pCep.replace(/[^0-9]/gi, "").length !== 8) {
        return;
      }
      return CheckCEP(pCep)
        .then((pCepData) => {
          if (Object.keys(pCepData).length !== 0) {
            this.patient.icf_bairro = pCepData.bairro
              ? pCepData.bairro.toUpperCase()
              : "";
            this.patient.icf_cod_municipio = pCepData.mun_codigo;
            this.patient.icf_cod_tipo_logradouro = pCepData.tlg_codigo;
            this.patient.icf_logradouro = pCepData.logradouro
              ? pCepData.logradouro.toUpperCase()
              : "";
            this.patient.icf_logradouro_numero = "";
            this.patient.icf_complemento = "";
            this.patient.icf_uf = pCepData.est_sigla;
            return true;
          } else {
            AlertError("CEP não encontrado", "error");
            return false;
          }
        })
        .catch((pErr) => {
          return false;
        });
    },
    setMask(value) {
      Object.values(DocumentTypes).filter((item) => {
        if (item.id === value) {
          this.documentMask = item.mask;
        }
      });
    },
    updateDisabledDocument(pValue = null) {
      const value = pValue || this.patient.icf_tipo_documento;
      this.disableDocument = value === 99;
    },
    setIndigent() {
      const currentUnitHealth = this.$store.getters["Login/GET_UNIT_HEALTH"];
      this.patient.icf_indigente = "1";
      this.patient.icf_nome =
        "IGNORADO PREENCHIDO DE ACORDO COM PORT SAS 84/97";
      this.patient.icf_nome_mae =
        "IGNORADO PREENCHIDO DE ACORDO COM PORT SAS 84/97";
      this.patient.icf_nome_responsavel =
        "IGNORADO PREENCHIDO DE ACORDO COM PORT SAS 84/97";
      this.patient.icf_logradouro = " ";
      this.patient.icf_cep = currentUnitHealth.endereco.end_cep;
      this.patient.icf_tipo_documento = 99; // valor da opçao ignorado.
      this.updateDisabledDocument();
      this.fillAddress();
    },
    setTelDdd() {
      if (this.dddTelephone) {
        const telDdd = removeSpecialCharacters(this.dddTelephone).trim();
        this.patient.icf_tel_ddd = telDdd.substring(0, 2);
        this.patient.icf_tel = telDdd.substring(2);
      }
    },
    setDataToState() {
      this.setTelDdd();
      this.trimNames();
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_PATIENT_INFO_TAB",
        this.patient,
      );
    },
    setNames() {
      this.trimNames();
      this.setResponsable();
    },
    setResponsable() {
      const fields =
        !isEmpty(this.patient.icf_nome) &&
        !isEmpty(this.patient.icf_nome_mae) &&
        !isEmpty(this.patient.icf_data_nascimento);
      if (
        !this.patient.icf_nome_responsavel &&
        !this.patient.icf_id &&
        fields
      ) {
        const name = this.hasAdulthood
          ? this.patient.icf_nome
          : this.patient.icf_nome_mae;
        this.patient.icf_nome_responsavel = name;
      }
    },
    validateGender(pData, pErrors) {
      if (this.validationTabs && isEmpty(pData))
        return AlertError("Campo sexo obrigatório", pErrors);
      else return true;
    },
    validateOtherNames(pData, pErrors) {
      let res = pData.toLowerCase();
      res = res.replace(new RegExp(/[àáâãäå]/g), "a");
      res = res.replace(new RegExp(/[èéêë]/g), "e");
      res = res.replace(new RegExp(/[ìíîï]/g), "i");
      res = res.replace(new RegExp(/[òóôõö]/g), "o");
      res = res.replace(new RegExp(/[ùúûü]/g), "u");

      if (res === "nao informado") {
        pErrors.push("Campo inválido");
        return false;
      }
      return this.validateName(pData, pErrors, this.validationTabs);
    },
    validateName(pData, pErrors, pRequired = true) {
      const patientName = pData;
      if (this.isIndigent) return true;

      if (pRequired && isEmpty(patientName)) {
        pErrors.push("Campo obrigatório");
        return false;
      }
      const hasData = !isEmpty(patientName);
      if (hasData) {
        const data = patientName.trim();
        const splitedData = data.split(" ");
        const repeatedChar = data
          .toLowerCase()
          .split("")
          .join("")
          .match(/(.)\1+/g);

        if (repeatedChar) {
          const hasRepeatedChar = repeatedChar.filter(
            (item) => item.length > 2,
          );
          if (hasRepeatedChar.length) {
            pErrors.push("Nome inválido.");
            return false;
          }
        }

        if (this.patientId === null) {
          pErrors.push("Cadastro não encontrado. Use a busca de pacientes.");
          return false;
        }

        for (let index = 0; index < splitedData.length; index++) {
          const item = splitedData[index];
          if (item.length <= 1) {
            pErrors.push(
              "Nome inválido. Existe(m) palavra(s) com apenas 1 caracter.",
            );
            return false;
          }
        }

        const format = /[!@#$%^&*()_+\-={};':"\\|,.|0-9]+/;
        if (format.test(data)) {
          pErrors.push("Nome inválido. Remova os caracteres especiais.");
          return false;
        }
      }

      return true;
    },
    validateCepExists(pCep, pErrors) {
      if (!this.validationTabs) return true;

      if (isEmpty(pCep) || pCep.replace(/[^0-9]/gi, "").length !== 8) {
        return AlertError("O Cep deve conter 8 dígitos", pErrors);
      }

      return CheckCEP(pCep)
        .then((pCepData) => {
          return true;
        })
        .catch((pError) => {
          return AlertError(`O Cep: ${pCep} não existe!`, pErrors);
        });
    },
    validatePatientCns(pCns, pErrors) {
      if (!this.isIndigent) {
        if (isEmpty(this.patient.icf_cns)) {
          if (this.hospitalizationBillingCharacter === 1) {
            return AlertError(
              "Caracter de Atendimento Eletivo exige CNS do Paciente",
              pErrors,
            );
          }

          if (
            [2, 4, 5, 6].includes(this.hospitalizationBillingCharacter) &&
            this.hospitalizationAihReleaseRequestCode &&
            this.hospitalizationAihReleaseRequestCode.ics_cns !== 1
          ) {
            return AlertError(
              "Preencha o CNS do paciente ou solicite a liberação de CNS",
              pErrors,
            );
          }
        } else if (this.hospitalizationAihReleaseRequestCode.ics_cns === 1) {
          return AlertError(
            "Para CNS do paciente preenchido a liberação de CNS não poderá ser escolhida!",
            pErrors,
          );
        }
      }

      return true;
    },
    validateNeighborhood(pValue, pErrors) {
      if (!this.isIndigent && !isEmpty(pValue)) {
        // #1705
        // if (this.validationTabs && isEmpty(pValue)) {
        //   return AlertError('Campo obrigatório', pErrors)
        // }

        const format = /[^\w\s]/gi;
        if (format.test(pValue)) {
          pErrors.push("Nome inválido. Remova os caracteres especiais.");
          return false;
        }

        const numbers = pValue.replace(/\D+/g, "").trim();
        // Se existir somento número resultado inválido
        if (!isEmpty(numbers)) {
          return AlertError("Campo não pode conter números", pErrors);
        }
      }

      return true;
    },
    validateSpecialCharacter(pData, pErrors) {
      const format = /[^\w\s]/gi;
      if (format.test(pData)) {
        pErrors.push("Nome inválido. Remova os caracteres especiais.");
        return false;
      }

      return true;
    },
    validatePhone(pValue, pErrors) {
      if (!this.isIndigent) {
        if (this.validationTabs && isEmpty(pValue))
          AlertError("Campo obrigatório", pErrors);

        if (!isEmpty(pValue)) return ValidatePhone(pValue, pErrors);
      }

      return true;
    },
  },
};
</script>
