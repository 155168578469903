import { gql, GraphQLClient } from "@/common/request/GraphQLClient";

const query = gql`
  query($moduleID: Int!) {
    client_preferences(moduleID: $moduleID) {
      namespace
      pre_valor
    }
  }
`;
export default async ({ commit, state }, to) => {
  const splittedPath = to.fullPath.split("/");
  const moduleName = splittedPath[1];
  if (!moduleName || !state.route_module_map[moduleName]) {
    return true;
  }
  // caso nao tenha nenhuma preferência carregada garante que elas serao carregadas
  if (Object.keys(state.preferences).length <= 0) {
    commit("RESET_ALL_PREFERENCES_TO_RELOAD");
  }
  if (state.module_preferences_loaded[moduleName] !== true) {
    try {
      const { data } = await GraphQLClient.query({
        query,
        fetchPolicy: "no-cache",
        variables: { moduleID: state.route_module_map[moduleName] },
      });
      const preferences = {};
      if (data.client_preferences && data.client_preferences.length > 0) {
        data.client_preferences.forEach((item) => {
          preferences[item.namespace] = item.pre_valor;
        });
      }

      commit("ADD_PREFERENCES", { preferences });
      commit("INCLUDE_LOADED_PREFERENCE", moduleName);
    } catch (err) {
      commit("REMOVE_LOADED_PREFERENCE", moduleName);
      return err.graphQLErrors;
    }
  }
};
