<template>
  <Modulebox :title="titleMovements" class="pharmacy-movements">
    <form ref="element" class="form-container">
      <FormBase :title="subtitleMovements" class="register-form">
        <RgValidatorForm ref="validatorSave">
          <div class="form">
            <div class="inputs">
              <RgInput
                id="movement"
                ref="movement"
                v-model="form.ttr_nome"
                :rules="{ required: true }"
                :maxlength="50"
                label="Movimentação"
                placeholder="Digite o nome do Tipo de Movimentação"
                :disabled="disabledByModal"
              />
            </div>
            <div class="buttons">
              <RgCleanButton
                v-if="!this.edit"
                id="clean-form"
                large
                :disabled="disabledByModal"
                @click="cleanForm"
              />

              <RgAddButton
                v-if="!this.edit"
                id="new-objective"
                large
                :disabled="disabledByModal"
                :permission="hasPermissionCreateMovement"
                @click="newMovement"
              />

              <RgCancelButton
                v-if="this.edit"
                id="cancel-edit"
                medium
                class="new"
                :disabled="disabledByModal"
                @click="cancelEditMovement"
              />
              <RgSaveButton
                v-if="this.edit"
                id="save-edit"
                ref="save"
                medium
                :disabled="disabledByModal"
                @click="saveEditMovement"
              />
            </div>
          </div>
        </RgValidatorForm>
        <div class="separator" />
        <div class="table-form">
          <div class="inputs">
            <RgInput
              id="search-movement"
              v-model="search.movementSearch"
              label=""
              :disabled="disableWhenEditing || disabledByModal"
              :class="{ disable: disableWhenEditing || disabledByModal }"
              placeholder="Busca por Movimentação"
            />
            <div class="buttons">
              <RgSearchButton
                id="search-movement"
                ref="search"
                :disabled="disableWhenEditing || disabledByModal"
                :class="{ disable: disableWhenEditing || disabledByModal }"
                @submit="searchMovements"
              />
            </div>
          </div>

          <div class="action-buttons">
            <RgEditButton
              id="edit-movement"
              small
              title="Editar Tipo de Movimentação"
              :permission="hasPermissionEnableDisableMovement"
              :disabled="
                disableWhenEditing ||
                disableButton ||
                !isActiveMovement ||
                disabledByModal
              "
              @click="editMovement"
            />
            <RgLessButton
              id="delete-movement"
              small
              title="Excluir Tipo de Movimentação"
              :permission="hasPermissionEnableDisableMovement"
              :disabled="
                disableWhenEditing ||
                disableButton ||
                !isActiveMovement ||
                disabledByModal
              "
              @click="showModalDeleteMovement"
            />
          </div>
        </div>

        <div
          v-if="mutableMovements && mutableMovements.length > 0"
          class="table"
          :disabled="disableWhenEditing || disabledByModal"
          :class="{
            disable: disableWhenEditing || disabledByModal,
          }"
        >
          <RgTable :columns="COLUMNS" class="rg-table">
            <tr
              v-for="item in mutableMovements"
              :key="item.ttr_id"
              slot="rows"
              class="tr"
              :class="{
                selected: selectedRow.ttr_id === item.ttr_id,
              }"
              @click="selectRow(item)"
            >
              <td>
                {{ item.ttr_nome }}
              </td>

              <td>
                {{ getSituation(item.ttr_ativo) }}

                <ColorIndication
                  size="13px"
                  :value="actionColorLine.get(item)"
                  class="circle"
                />
              </td>
            </tr>
          </RgTable>
        </div>
        <div v-else class="empty-result">
          <IconEmpty />
          <span>Não foram encontrados resultados para essa busca</span>
        </div>

        <RgList
          v-show="mutableMovements && mutableMovements.length > 0"
          ref="rgList"
          v-model="mutableMovements"
          :disabled="disableWhenEditing || disabledByModal"
          :class="{
            disable: disableWhenEditing || disabledByModal,
          }"
          :search-function="searchList"
          :build-filter="generateFilter"
          :register-per-page="8"
          :max-register="10"
          :totalRegister="totalRegister"
          backendLegacy
          class="list"
        >
          <div class="botton">
            <div class="botton-buttons">
              <SmallButton
                id="inative-movement"
                title="Inativar Movimentação"
                :backgroundColor="'#1e88a9'"
                :permission="hasPermissionCreateMovement"
                :disabled="
                  !isActiveMovement || disableWhenEditing || disabledByModal
                "
                @click="openModalDesactiveMovements"
              >
                <IconDesactivate slot="icon" />
              </SmallButton>

              <SmallButton
                id="active-movement"
                title="Ativar Movimentação"
                :backgroundColor="'#1e88a9'"
                :permission="hasPermissionCreateMovement"
                :disabled="
                  !isInactiveMovement || disableWhenEditing || disabledByModal
                "
                @click="openModalActiveMovements"
              >
                <IconActivate slot="icon" />
              </SmallButton>
            </div>
          </div>
        </RgList>
      </FormBase>
    </form>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      :show="modalDeleteMovement"
      noReason
      title="Excluir o Tipo de Movimentação Selecionado"
      message="Deseja excluir esse Tipo de Movimentação?"
      msgSuccess="Tipo de Movimentação excluído com sucesso!"
      :bodyText="bodyText"
      :confirm="deleteMovementItem"
      @close="closeModalDeleteMovement"
      @reSearch="reSearch"
    />
    <ModalActiveDesactive
      :show="modalActiveDesactive"
      :title="titleActiveDesactive"
      :subtitle="messageActiveDesactive"
      :msgSuccess="msgSuccessActiveDesactive"
      :bodyText="bodyText"
      :isActive="isActive"
      :labelBtn="labelBtnActiveDesactive"
      :confirm="activeDesactiveMovementsItem"
      :textReason="textReason"
      @reason="reasonActiveDesactiveMovements"
      @close="closeActiveDesactive"
    />
  </Modulebox>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgList from "~tokio/foundation/rg-list/RgList";
import RgTable from "~tokio/foundation/rg-table/RgTable";
import {
  RgInput,
  RgCleanButton,
  RgAddButton,
  RgCancelButton,
  RgSaveButton,
  RgSearchButton,
  RgEditButton,
  RgValidatorForm,
  RgLessButton,
  SmallButton,
  IconEmpty,
  IconActivate,
  IconDesactivate,
  ColorIndication,
  ModalConfirmDeletion,
  ModalActiveDesactive,
} from "~tokio/primitive";

export default {
  name: "PharmacyMovements",
  components: {
    Modulebox,
    FormBase,
    RgInput,
    RgCleanButton,
    RgAddButton,
    RgCancelButton,
    RgSaveButton,
    RgSearchButton,
    RgEditButton,
    RgValidatorForm,
    RgLessButton,
    SmallButton,
    RgList,
    IconEmpty,
    IconActivate,
    IconDesactivate,
    RgTable,
    ColorIndication,
    ModalConfirmDeletion,
    ModalActiveDesactive,
  },

  data() {
    return {
      form: {
        ttr_nome: null,
      },
      search: {
        movementSearch: null,
      },
      mutableMovements: null,
      edit: false,
      selectedRow: {},
      enableSaveEditButton: false,
      modalDeleteMovement: false,
      titleActiveDesactive: "",
      messageActiveDesactive: "",
      msgSuccessActiveDesactive: "",
      labelBtnActiveDesactive: "",
      isActive: false,
      bodyText: null,
      modalActiveDesactive: false,
      textReason: "",
      totalRegister: 0,
    };
  },

  computed: {
    titleMovements() {
      return this.edit
        ? "Editar Tipos de Movimentação"
        : "Cadastrar Tipos de Movimentação";
    },
    subtitleMovements() {
      return this.edit
        ? "Descrição do Tipo de Movimentação"
        : "Cadastro e Busca de Movimentação";
    },
    isActiveMovement() {
      return this.selectedRow && this.selectedRow.ttr_ativo === "1";
    },
    isInactiveMovement() {
      return this.selectedRow && this.selectedRow.ttr_ativo === "0";
    },
    disableButton() {
      return !this.selectedRow;
    },
    hasPermissionCreateMovement() {
      return !!this.$Permissions.global.has("farmacia.tipoTransporte.incluir");
    },
    hasPermissionEditMovement() {
      return !!this.$Permissions.global.has("farmacia.tipoTransporte.alterar");
    },
    hasPermissionRemoveMovement() {
      return !!this.$Permissions.global.has("farmacia.tipoTransporte.excluir");
    },
    hasPermissionEnableDisableMovement() {
      return !!this.$Permissions.global.has(
        "farmacia.tipoTransporte.ativarInativar",
      );
    },
    disableWhenEditing() {
      return this.edit;
    },
    actionColorLine() {
      return {
        get: (item) => {
          if (item.ttr_ativo === "0") {
            return "#f96b70";
          }
          if (item.ttr_ativo === "1") {
            return "#37C871";
          }
        },
      };
    },
    disabledByModal() {
      return this.modalDeleteMovement || this.modalActiveDesactive;
    },
  },

  // watch: {
  //   mutableMovements(pValue) {
  //     if (pValue) {
  //       this.totalRegister = this.mutableMovements.length;
  //     }
  //   },
  // },

  async mounted() {
    this.searchMovements();
  },

  created() {
    this.COLUMNS = [
      { name: "Nome", key: "ttr_nome" },
      { name: "Situação", key: "ttr_ativo" },
    ];
  },

  methods: {
    async isFormValid() {
      return this.$refs.validatorSave
        ? this.$refs.validatorSave.validate()
        : false;
    },
    async newMovement() {
      try {
        this.$loader.start();
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
        const variables = {
          ttr_nome: this.form.ttr_nome.toUpperCase(),
        };

        const createdMovementId = await this.$store.dispatch(
          "Pharmacy/Register/CREATE_PHARMACY_MOVEMENT",
          variables,
        );
        this.$toaster.success("Tipo de Movimentação cadastrado com sucesso");
        variables.ttr_id = String(createdMovementId.dados);
        variables.ttr_ativo = "1";
        variables.ttr_motivo_inabilitacao = "";
        this.mutableMovements.unshift(variables);
        this.totalRegister++;
        this.cleanForm();
        this.hasSelectedRow = false;
        this.selectedRow = {};
        // this.reSearch();
      } catch (e) {
        const error = e.toString();
        const { esus_response } = e;
        if (esus_response?.hasError) {
          const isDuplicityError =
            esus_response.dados === "DuplicidadeTipoTransporteException";

          if (isDuplicityError) {
            this.$toaster.error(
              `Já existe registro com esse nome:  ${this.form.ttr_nome}.`,
              " Não foi possível cadastrar o Tipo de Movimentação",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao cadastrar o Tipo de Movimentação",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },
    async saveEditMovement() {
      try {
        this.$loader.start();
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
        const variables = {
          ttr_id: this.selectedRow.ttr_id,
          ttr_nome: this.form.ttr_nome.toUpperCase(),
        };

        await this.$store.dispatch(
          "Pharmacy/Register/EDIT_PHARMACY_MOVEMENT",
          variables,
        );
        this.$toaster.success("Tipo de Movimentação alterado com sucesso");
        this.cancelEditMovement();
        variables.ttr_ativo = "1";
        variables.ttr_motivo_inabilitacao = "";
        this.mutableMovements.splice(
          this.mutableMovements.findIndex((e) => e.ttr_id === variables.ttr_id),
          1,
        );
        this.mutableMovements.unshift(variables);
      } catch (e) {
        const error = e.toString();
        const { esus_response } = e;

        if (esus_response?.hasError) {
          const isDuplicityError =
            esus_response.dados === "DuplicidadeTipoTransporteException";

          if (isDuplicityError) {
            this.$toaster.error(
              `Já existe registro com esse nome:  ${this.form.ttr_nome}.`,
              " Não foi possível alterar o Tipo de Movimentação",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao alterar o Tipo de Movimentação",
          );
        }
      } finally {
        this.$loader.finish();
        this.$refs.save.actionDone();
      }
    },

    showModalDeleteMovement() {
      this.bodyText = this.selectedRow.ttr_nome;
      this.modalDeleteMovement = true;
    },
    closeModalDeleteMovement() {
      this.modalDeleteMovement = false;
    },
    async deleteMovementItem() {
      try {
        this.$loader.start();
        const variables = {
          intIdTipoTransporte: this.selectedRow.ttr_id,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/DELETE_PHARMACY_MOVEMENT",
          variables,
        );

        this.$toaster.success("Tipo de Movimentação excluído com sucesso");
        this.totalRegister = 0;
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e.message || e),
          "Não foi possível excluir o Tipo de Movimentação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    reasonActiveDesactiveMovements(pValue) {
      this.form.ttr_motivo_inabitacao = pValue;
    },
    async activeMovements() {
      try {
        this.$loader.start();
        const variables = {
          intIdTipoTransporte: this.selectedRow.ttr_id,
        };
        await this.$store.dispatch(
          "Pharmacy/Register/ACTIVATE_PHARMACY_MOVEMENT",
          variables,
        );

        this.$toaster.success("Tipo de Movimentação habilitado com sucesso");
        this.reSearch();
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e.message || e),
          "Não foi possível habilitar o Tipo de Movimentação",
        );
      } finally {
        this.$loader.finish();
        this.isActive = false;
      }
    },
    async desactiveMovements() {
      try {
        this.$loader.start();
        const variables = {
          intIdTipoTransporte: this.selectedRow.ttr_id,
          motivo: this.form.ttr_motivo_inabitacao,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/INACTIVATE_PHARMACY_MOVEMENT",
          variables,
        );

        this.$toaster.success("Tipo de Movimentação inabilitado com sucesso");
        this.reSearch();
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e.message || e),
          "Não foi possível inabilitar o Tipo de Movimentação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    activeDesactiveMovementsItem() {
      if (this.isActive) {
        this.activeMovements();
      } else {
        this.desactiveMovements();
      }
      this.modalActiveDesactive = false;
    },
    openModalActiveMovements() {
      this.titleActiveDesactive =
        "Habilitar o Tipo de Movimentação Selecionado";
      this.messageActiveDesactive =
        "Deseja habilitar o Tipo de Movimentação selecionado?";
      this.msgSuccessActiveDesactive =
        "Tipo de Movimentação habilitado com sucesso";
      this.labelBtnActiveDesactive = "Habilitar";
      this.bodyText = this.selectedRow.ttr_nome;
      this.modalActiveDesactive = true;
      this.textReason = this.selectedRow.ttr_motivo_inabilitacao;
      this.isActive = true;
    },
    openModalDesactiveMovements() {
      this.titleActiveDesactive =
        "Inabilitar o Tipo de Movimentação Selecionado";
      this.messageActiveDesactive =
        "O Tipo de Movimentação selecionado possui movimentações vinculadas. Por isso só é possível inabilitar o cadastro. Deseja inabilitar o Tipo de Movimentação selecionado?";
      this.msgSuccessActiveDesactive =
        "Tipo de Movimentação inabilitado com sucesso";
      this.labelBtnActiveDesactive = "Inabilitar";
      this.bodyText = this.selectedRow.ttr_nome;
      this.textReason = "";
      this.modalActiveDesactive = true;
    },
    closeActiveDesactive() {
      this.modalActiveDesactive = false;
      this.isActive = false;
    },
    async reSearch() {
      await this.$refs.rgList.submitForm(false, true);
      this.selectedRow = {};
    },
    cancelEditMovement() {
      this.edit = false;
      this.cleanForm();
      this.selectedRow = {};
    },
    editMovement() {
      this.form.ttr_nome = this.selectedRow.ttr_nome;
      this.edit = true;
    },
    getSituation(pValue) {
      return pValue === "1" ? "ATIVO" : "INATIVO";
    },
    cleanForm() {
      this.form.ttr_nome = null;
      this.$refs.movement.cleanValidate();
    },
    selectRow(pItem) {
      if (pItem.ttr_id === this.selectedRow.ttr_id) {
        this.cleanForm();
        this.edit = false;
        this.enableSaveEditButton = false;
        this.selectedRow = {};
        return;
      }

      if (!this.edit) {
        this.selectedRow = pItem;
      }
    },

    async searchMovements() {
      this.totalRegister = 0;
      this.$loader.start("Carregando...");
      await this.$refs.rgList.submitForm(true);
      this.$refs.search.actionDone();
      this.$loader.finish();
    },
    generateFilter() {
      const variables = {
        ttr_nome: this.search.movementSearch,
      };
      return variables;
    },
    searchList(pData) {
      this.selectedRow = {};
      return this.$store.dispatch(
        "Pharmacy/Register/SEARCH_PHARMACY_MOVEMENTS",
        {
          ...pData,
        },
      );
    },
  },
};
</script>
