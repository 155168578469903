import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation DownloadBilling($downloadBilling: DownloadBillingInputType) {
    DownloadBilling(downloadBilling: $downloadBilling) {
      fex_arquivo_gerado
      file
      fileName
    }
  }
`;

export default async ({ commit }, { variables }) => {
  const { data } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.DownloadBilling;
};
