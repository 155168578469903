<template lang="html">
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="14"
      cy="14"
      r="13"
      fill="white"
      stroke="#26789F"
      stroke-width="2"
    />
    <path
      d="M16.46 16.3555H12.29L11.3535 18.9531H10L13.8008 9H14.9492L18.7568 18.9531H17.4102L16.46 16.3555ZM12.6865 15.2754H16.0703L14.375 10.6201L12.6865 15.2754Z"
      fill="#26789F"
    />
  </svg>
</template>

<script>
export default {
  name: "IndicationActive",
};
</script>
