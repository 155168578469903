const REGEX_EMOJI = /[^a-zA-Zà-úÀ-Ú 0-9().,:|/¨´ÅÆÐØÞßåæøþ`^~'!*;=+{}?°ºª¹²³£¢¬()§&%#$@_-]+/g;

/*
 * Recebe uma String, e remove os espaços extras
 * @param pValue {String} String
 */
export const removeExtraSpaces = (pValue) => {
  if (!pValue) {
    return null;
  }

  return pValue.replace(/\s{2,}/g, " ").trim();
};

/*
Recebe uma String, e remove os caracteres especiais de acordo com o tipo da validação solicitada,
onde type 1 ignora acentos e alguns caracteres especiais, e o type 2 ignora qualquer tipo de
caractere, porém permite acentos e ç.
@param str {String} String
*/
export const replaceSpecial = (str, type = 1) => {
  if (str === null || str === "" || str === undefined) {
    return str;
  } else {
    let oldCaracter = "";
    let newCaracter = "";
    if (type === 1) {
      oldCaracter =
        "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ`^~'";
      newCaracter =
        "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr    ";
    }
    if (type === 2) {
      oldCaracter = "|/¨´ÅÆÐØÞßåæøþ`^~'!*;=-+{}[]?°ºª¹²³£¢¬()§&%#$\u005C".concat(
        '"',
      );
      newCaracter = "";
    }
    let newstr = "";
    let i, change, a;
    for (i = 0; i < str.length; i++) {
      change = false;
      for (a = 0; a < oldCaracter.length; a++) {
        if (str.substr(i, 1) === oldCaracter.substr(a, 1)) {
          newstr += newCaracter.substr(a, 1);
          change = true;
          break;
        }
      }
      if (change === false) {
        newstr += str.substr(i, 1);
      }
    }
    return newstr;
  }
};

export const removeSpecialCharacters = (pValue) => {
  const hasValue = pValue && pValue?.length > 0;

  return hasValue ? pValue.replace(/[^a-zA-Z ]/g, "") : pValue;
};

/*
Recebe uma String, e remove as tags html
@param str {String} String
*/
export const removeHtmlTag = (pValue) => {
  const hasValue = pValue && pValue?.length > 0;
  const regex = /(&nbsp;|<([^>]+)>)/gi;

  return hasValue ? pValue.replace(regex, "") : pValue;
};

export const formatError = (pValue) => {
  if (pValue) {
    const message = pValue.toString();
    if (message.includes("Error:")) {
      return message.substring(6, message.length);
    }
    return message;
  }
};

export const removeEmoji = (pValue) => {
  return pValue ? pValue.toString().replace(REGEX_EMOJI, "") : pValue;
};

export const hasEmoji = (pValue) => {
  return REGEX_EMOJI.test(pValue);
};
