import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchClassifications(
    $unitHealthId: Int!
    $serviceCode: String!
    $procedureCode: String!
  ) {
    SearchClassifications(
      unitHealthId: $unitHealthId
      serviceCode: $serviceCode
      procedureCode: $procedureCode
    ) {
      rows {
        CO_CLASSIFICACAO
        NO_CLASSIFICACAO
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchClassifications.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
