<style src="./RgInputHour.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :optional="optional" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model="inputValue"
            v-mask="'##:##'"
            :disabled="disabled"
            :readonly="disabled"
            :data-id="dataId"
            :class="{ 'on-error': hasError }"
            :placeholder="placeholder"
            :tabindex="disabled ? -1 : tabIndex"
            type="text"
            class="inputbox"
            @blur="validateBlur"
          />
          <div class="rg-input--side-action">
            <IconClock class="rg-input--icon" />
          </div>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>
<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";
import { IconClock } from "~tokio/primitive/icon";
import { mask } from "vue-the-mask";

import moment from "moment";
export default {
  name: "RgInputHour",
  components: {
    RgValidationAlert,
    RgFormBase,
    IconClock,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "Hora",
    },
    placeholder: {
      type: String,
      default: "hh:mm",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueHour: "",
      valueMinutes: "",
      anotherRules: {
        validHour: (pValue, pError) => {
          if (!pValue) {
            return true;
          }
          const isHour = moment(pValue, "HH:mm", true).isValid();
          const isHourWithSeconds = moment(pValue, "HH:mm:ss", true).isValid();
          const validateHour = !isHour && !isHourWithSeconds;
          if (validateHour) {
            pError.push("Hora deve seguir o padrão HH:MM. Ex: 14:40");
          }
          return !validateHour;
        },
      },
      error: [],
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
    hasError() {
      return this.error.length > 0;
    },
  },
  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
