import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamSectorReport(
    $uns_id: Int
    $set_id: Int
    $set_nome: String
    $type_scale: [Int]
    $name_type_scale: String
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchExamSectorReport(
      uns_id: $uns_id
      set_id: $set_id
      set_nome: $set_nome
      type_scale: $type_scale
      name_type_scale: $name_type_scale
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        SETOR
        DISPONIBILIZADOS
        AGENDADOS
        EFETIVADOS
        NAO_EFETIVADOS
        AGUARDANDO
        VAGAS_RESTANTES
        PRODUTIVIDADE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamSectorReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
