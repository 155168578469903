<template>
  <Modulebox
    title="Cadastro de Prioridades"
    class="appointment-priority"
    :granted="hasPermission"
  >
    <div class="filter">
      <FormBase title="Prioridades" class="formbase">
        <RgValidatorForm ref="validator">
          <div class="filter-area">
            <RgInput
              id="priority"
              ref="priority"
              v-model="form.pfi_nome"
              :rules="{ required: true }"
              :class="{ disable: !canInclude || editMode }"
              :disabled="!canInclude || editMode"
              :maxlength="45"
              placeholder="Digite o nome da prioridade"
              label="Prioridade"
            />

            <RgInputMasked
              id="weight"
              ref="weight"
              v-model="form.pfi_peso"
              :rules="{ required: true, fn: validateNumber }"
              :class="{ disable: !canInsertOrEdit }"
              :disabled="!canInsertOrEdit"
              placeholder="00"
              mask="##"
              label="Peso"
              class="weight"
              type="number"
            />

            <RgInputColor
              id="color"
              v-model="form.pfi_cor"
              :rules="{ required: true }"
              :disabled="!canInclude || editMode"
              :class="{ disable: !canInclude || editMode }"
              label="Cor"
            />

            <RgComboboxPrioridadeAb
              id="priority-ab"
              ref="priorityAb"
              v-model="form.pfi_id_ab_classificacao_risco_ab"
              label="Prioridade AB"
              :class="{ disable: !canInsertOrEdit }"
              :disabled="!canInsertOrEdit"
            />

            <div class="buttons">
              <RgCancelButton
                v-if="editMode"
                id="clear-edit"
                medium
                label="Cancelar"
                :permission="hasPermission"
                class="clear"
                @click="clearForm"
              />

              <RgSaveButton
                v-if="editMode"
                id="new-priority-edit"
                ref="updateBtn"
                :permission="canEdit"
                class="new"
                large
                @click="validateIfCanAdd"
              />

              <RgCleanButton
                v-if="!editMode"
                id="clear-new-priority"
                class="clear"
                :permission="hasPermission"
                @click="clearForm"
              />

              <RgAddButton
                v-if="!editMode"
                id="new-priority"
                :permission="canInclude"
                large
                class="new"
                @click="validateIfCanAdd"
              />
            </div>
          </div>
        </RgValidatorForm>
      </FormBase>
    </div>

    <FormBase ref="list" title="Prioridades Cadastradas" class="formbase">
      <div class="list">
        <div class="sub-title">
          <div class="left-area">
            <RgInputCheckbox
              id="active"
              :value="filterActive"
              :class="{ disable: editMode }"
              :disabled="editMode"
              label="Ativo"
              @change-value="changeCheckboxActive"
            />

            <RgInputCheckbox
              id="inactive"
              :value="filterInactive"
              :class="{ disable: editMode }"
              :disabled="editMode"
              label="Inativo"
              @change-value="changeCheckboxInactive"
            />
          </div>

          <div class="right-area">
            <RgEditButton
              id="edit-priority"
              large
              :disabled="!selectedRow.pfi_id || editMode"
              :permission="canEdit"
              @click="editPriority"
            />
          </div>
        </div>

        <div
          v-if="showPriorityList"
          :disabled="editMode"
          :class="{
            disable: editMode,
          }"
        >
          <RgTable :columns="COLUMNS">
            <tr
              v-for="item in mutablePriorityList"
              :key="item.pfi_id"
              slot="rows"
              :class="{ selected: selectedRow.pfi_id === item.pfi_id }"
              class="tr"
              @click="clickSelectRow(item)"
            >
              <td class="td">
                <ColorIndication :value="item.pfi_cor" class="align-color" />
              </td>

              <td class="td">{{ item.pfi_peso }}</td>

              <td class="td">{{ item.pfi_nome.toUpperCase() }}</td>

              <td class="td">{{ item.cra_descricao }}</td>

              <td class="td">
                <RgToggleButton
                  v-model="item.pfi_ativo"
                  v-bind="propsToggleBtn"
                  :class="{
                    disable:
                      (item.pfi_ativo && !canInactive) ||
                      (!item.pfi_ativo && !canInclude) ||
                      item.disable,
                  }"
                  :disabled="item.disable"
                  class="align-toggle"
                  @input="(toogle) => changeToggleButton(item, toogle)"
                />
              </td>
            </tr>
          </RgTable>
        </div>

        <div
          v-else-if="
            hasPermissionShowPriority &&
            (!mutablePriorityList || mutablePriorityList.length < 1)
          "
          class="empty-result"
        >
          <IconEmpty />
          <span>Não foram encontrados resultados</span>
        </div>
        <RgList
          v-show="showPriorityList"
          ref="rgList"
          v-model="mutablePriorityList"
          :search-function="search"
          :build-filter="generateFilter"
          :register-per-page="dynamicLimit"
          :max-register="40"
          :disabled="editMode"
          :viewPermission="hasPermission"
          backendLegacy
          class="list"
        />
      </div>
    </FormBase>

    <ModalConfirmDefault
      id="modal-confirm"
      :show="modalConfirmDuplicity"
      title="A cor selecionada está em uso"
      subtitle="Outras prioridades utilizam a mesma cor."
      message="Manter a cor da nova prioridade:"
      yesLabel="Confirmar"
      noLabel="Cancelar"
      @getYes="addOrEditPriority(false)"
      @getOut="closeModalConfirm"
      @close="closeModalConfirm"
    >
      <div slot="body-content" class="content-modal">
        <span>{{ form.pfi_nome }}</span>
        <div
          class="color-block"
          :style="{ 'background-color': form.pfi_cor }"
        />
      </div>
    </ModalConfirmDefault>

    <ModalWeightConflict
      id="modal-weight-conflict"
      :show="modalWeightConflict"
      @close="closeModalWeightConflict"
    />
  </Modulebox>
</template>

<script>
import { RgComboboxPrioridadeAb } from "$appointment/submodules/register/component";
import { mapGetters } from "vuex";
import RgList from "~tokio/foundation/rg-list/RgList";
import { Modulebox, FormBase, RgTable } from "~tokio/foundation";
import {
  RgInputColor,
  RgInput,
  RgInputMasked,
  RgCleanButton,
  RgAddButton,
  ColorIndication,
  RgToggleButton,
  RgValidatorForm,
  ModalConfirmDefault,
  ModalWeightConflict,
  RgEditButton,
  RgInputCheckbox,
  RgSaveButton,
  IconEmpty,
} from "~tokio/primitive";

const FORM_FILTER = {
  pfi_id: null,
  pfi_nome: "",
  pfi_id_ab_classificacao_risco_ab: "",
  pfi_peso: "",
  pfi_cor: "#000000",
};

export default {
  name: "AppointmentPriority",
  components: {
    Modulebox,
    RgInputColor,
    RgInput,
    FormBase,
    RgInputMasked,
    RgCleanButton,
    RgAddButton,
    RgTable,
    ColorIndication,
    RgToggleButton,
    RgValidatorForm,
    ModalConfirmDefault,
    ModalWeightConflict,
    RgComboboxPrioridadeAb,
    RgEditButton,
    RgInputCheckbox,
    RgSaveButton,
    RgList,
    IconEmpty,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      mutablePriorityList: [],
      modalConfirmDuplicity: false,
      modalWeightConflict: false,
      editMode: false,
      changeToggleItem: null,
      selectedRow: { pfi_id: null },
      filterActive: true,
      filterInactive: false,
      dynamicLimit: 5,
      auxiliarCount: 0,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    propsToggleBtn() {
      return {
        truthyText: "ATIVO",
        falsyText: "INATIVO",
        width: 30,
        height: 10,
        fontSize: 10,
        center: true,
        externalLabel: true,
        valueSync: true,
      };
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    clientId() {
      return this.$store.state.Login.user.usu_id_clientes;
    },

    canInclude() {
      return this.hasPermissionAddPriority;
    },

    canEdit() {
      return this.hasPermissionEditPriority;
    },

    canShow() {
      return this.hasPermissionShowPriority;
    },

    canInsertOrEdit() {
      return (
        (!this.editMode && this.canInclude) || (this.editMode && this.canEdit)
      );
    },

    canInactive() {
      return this.hasPermissionDeletePriority;
    },

    hasPermission() {
      return (
        this.hasPermissionShowPriority ||
        this.hasPermissionAddPriority ||
        this.hasPermissionEditPriority ||
        this.hasPermissionDeletePriority
      );
    },

    hasPermissionAddPriority() {
      return !!this.$Permissions.global.has(
        "consulta.prioridadeConsulta.incluir",
        this.unitHealthId,
      );
    },

    hasPermissionEditPriority() {
      return !!this.$Permissions.global.has(
        "consulta.prioridadeConsulta.editar",
        this.unitHealthId,
      );
    },

    hasPermissionDeletePriority() {
      return !!this.$Permissions.global.has(
        "consulta.prioridadeConsulta.excluir",
        this.unitHealthId,
      );
    },

    hasPermissionShowPriority() {
      return !!this.$Permissions.global.has(
        "consulta.prioridadeConsulta.exibir",
        this.unitHealthId,
      );
    },

    showPriorityList() {
      return this.canShow && this.mutablePriorityList.length > 0;
    },
  },

  watch: {
    selectedRow(pItem, pPreviously) {
      if (this.editMode && pItem !== pPreviously) {
        const {
          pfi_nome,
          pfi_id_ab_classificacao_risco_ab,
          pfi_peso,
          pfi_cor,
        } = this.selectedRow;

        this.form.pfi_nome = pfi_nome;
        this.form.pfi_peso = pfi_peso;
        this.form.pfi_cor = pfi_cor;
        this.form.pfi_id_ab_classificacao_risco_ab = pfi_id_ab_classificacao_risco_ab;
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Cor" },
      { name: "Peso" },
      { name: "Prioridade" },
      { name: "Prioridade AB" },
      { name: "Situação" },
    ];
  },

  async mounted() {
    // this.setDynamicLimit();
    await this.$refs.rgList.submitForm(true);
  },

  methods: {
    setDynamicLimit() {
      if (this.$refs.list && this.$refs.list.$el) {
        const lineHeight = 29;
        const limit = Math.ceil(
          (this.$refs.list.$el.offsetHeight - 210) / lineHeight,
        );
        this.dynamicLimit = limit > 0 ? limit : 5;
      }
    },

    async changeCheckboxActive(pValue) {
      if (this.auxiliarCount > 0) {
        this.filterActive = pValue;
        await this.$refs.rgList.submitForm(true);
      }
      this.auxiliarCount++;
    },

    async changeCheckboxInactive(pValue) {
      if (this.auxiliarCount > 0) {
        this.filterInactive = pValue;
        await this.$refs.rgList.submitForm(true);
      }
      this.auxiliarCount++;
    },

    editPriority() {
      if (this.selectedRow.pfi_id) {
        if (!this.editMode) {
          this.editMode = true;

          const {
            pfi_nome,
            pfi_id_ab_classificacao_risco_ab,
            pfi_peso,
            pfi_cor,
          } = this.selectedRow;

          this.form.pfi_nome = pfi_nome;
          this.form.pfi_peso = pfi_peso;
          this.form.pfi_cor = pfi_cor;
          this.form.pfi_id_ab_classificacao_risco_ab = pfi_id_ab_classificacao_risco_ab;
        }

        return;
      }

      if (this.editMode) {
        this.$toaster.warning(
          "Por favor, selecione uma prioridade para editar",
        );
      }

      this.editMode = false;
    },

    clickSelectRow(item) {
      if (item.pfi_id === this.selectedRow.pfi_id) {
        this.editMode = false;
        this.clearForm();
        return;
      }

      this.selectedRow = item;
    },

    async changeToggleButton(pItem, pValue) {
      try {
        if (!pValue) {
          if (!this.canInactive) {
            throw new Error("Sem permissão.");
          }
          this.$loader.start("Alterando situação...");

          await this.$store.dispatch(
            "Appointment/Register/DELETE_APPOINTMENT_PRIORITY",
            { intIdPrioridadeFila: pItem.pfi_id },
          );
        } else {
          if (!this.canInclude) {
            throw new Error("Sem permissão.");
          }

          await this.$store.dispatch(
            "Appointment/Register/ACTIVE_APPOINTMENT_PRIORITY",
            { intIdPrioridadeFila: pItem.pfi_id },
          );
        }

        this.$toaster.success("Situação alterada com sucesso");
      } catch (pErr) {
        this.handlePriorityError(pErr);
      }

      this.$loader.finish();
      await this.$refs.rgList.submitForm(false, true);
    },

    actSelectedWeight() {
      this.$toaster.warning("Não Implementado");
    },

    async validateIfCanAdd() {
      try {
        if (!this.canInclude) {
          throw new Error("Sem permissão.");
        }

        const isValid = await this.$refs.validator.validate();

        if (!isValid) {
          this.stopLoadingUpdateBtn();
          throw new Error("Verifique os campos.");
        }

        this.addOrEditPriority();
      } catch (err) {
        this.$toaster.warning(err.message);
      }
    },

    async addOrEditPriority(validate = true) {
      try {
        this.$loader.start();
        const { pfi_nome, pfi_peso, pfi_cor } = this.form;
        const { pfi_id, pfi_ativo } = this.selectedRow;

        if (pfi_id) {
          await this.$store.dispatch(
            "Appointment/Register/EDIT_APPOINTMENT_PRIORITY",
            {
              pfi_id,
              pfi_nome: pfi_nome.toUpperCase(),
              pfi_peso,
              pfi_cor: pfi_cor.replace("#", "0x"),
              pfi_id_modulos: this.appointmentModuleId,
              pfi_id_clientes: this.clientId,
              pfi_ativo: pfi_ativo ? 1 : 0,
              pfi_id_ab_classificacao_risco_ab: this.form
                .pfi_id_ab_classificacao_risco_ab,
            },
          );

          this.$toaster.success("Prioridade alterada com sucesso");
        } else {
          await this.$store.dispatch(
            "Appointment/Register/ADD_APPOINTMENT_PRIORITY",
            {
              data: {
                pfi_nome: pfi_nome.toUpperCase(),
                pfi_peso,
                pfi_cor: pfi_cor.replace("#", "0x"),
                pfi_id_modulos: this.appointmentModuleId,
                pfi_id_clientes: this.clientId,
                pfi_id_ab_classificacao_risco_ab: this.form
                  .pfi_id_ab_classificacao_risco_ab,
              },
              validate,
            },
          );
          this.$toaster.success("Prioridade cadastrada com sucesso");
        }

        await this.$refs.rgList.submitForm(false, true);
        this.closeModalConfirm();
        this.clearForm();
      } catch (pErr) {
        this.handlePriorityError(pErr);
      } finally {
        this.$loader.finish();
        this.stopLoadingUpdateBtn();
      }
    },

    handlePriorityError(pErr) {
      const isColorException =
        pErr.message === "A cor informada está vinculada a outra prioridade.";

      const isWeigthException =
        pErr.message === "Já existe uma prioridade com esse peso.";

      if (isColorException) {
        this.modalConfirmDuplicity = true;
      } else if (isWeigthException) {
        this.modalWeightConflict = true;
      } else {
        this.$toaster.warning(pErr.message);
      }
    },

    search(pData) {
      return this.$store.dispatch(
        "Appointment/Register/SEARCH_APPOINTMENT_PRIORITY",
        {
          ...pData,
        },
      );
    },

    generateFilter() {
      const filterValue =
        this.filterInactive === this.filterActive
          ? null
          : this.filterInactive
          ? 0
          : this.filterActive;

      const variables = {
        arrFiltros: {
          intIdCliente: this.clientId,
          intIdModulo: this.appointmentModuleId,
          pfi_ativo: filterValue,
        },
      };

      return variables;
    },

    validateNumber(pValue, pErrors) {
      if (pValue && Number(pValue) === 0) {
        pErrors.push("Informe um valor maior que zero.");
        return false;
      }

      return true;
    },

    clearForm() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.selectedRow = { pfi_id: null };
      this.$refs.priority.cleanValidate();
      this.$refs.priorityAb.cleanValidate();
      this.$refs.weight.cleanValidate();
      this.editMode = false;
    },

    closeModalConfirm() {
      this.modalConfirmDuplicity = false;
    },

    closeModalWeightConflict() {
      this.modalWeightConflict = false;
    },

    stopLoadingUpdateBtn() {
      if (this.editMode) {
        this.$refs.updateBtn.actionDone();
      }
    },
  },
};
</script>
