import AuthRequest from "~common/request/AuthRequest";

/**
 * @param {Object} payload
 */
export default async (payload) => {
  try {
    const { data } = await AuthRequest.get(
      "/queue/queue-info/list-status-export",
      {
        ...payload,
      },
    );

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
