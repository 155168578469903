import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  NaoPossuiPermissaoException: `Você não possui permissão para agendar uma consulta`,
  QuantidadeConsultaAlteracaoInvalidaException: `A quantidade de consultas informada não pode ser igual a zero. `,
  HorarioFinalInvalidoException: `Horário final menor que a última consulta agendada.`,
  HorarioInicialInvalidoException: `Horário inicial maior primeira consulta agendada.`,
  ConflitoAlteracaoDePeriodoHorarioException: `A escala agendada possui conflitos`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/consultas/controller-marcacao-consulta/alterar-dados-dia",
    { arrFiltro: variables },
  )
    .then(({ data }) => {
      // Esse tratamento ainda não está 100%. Temos conversar melhor sobre o mesmo em outra hora. Ronaldo 11/12/2020.
      // ... Por enquanto estou usando o arrayExceptions como paliativo.
      // data = HandleResponse(data)
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
