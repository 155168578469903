<template>
  <section class="patient-form">
    <FormBase title="Paciente" class="form-base-patient">
      <div class="grid-patient">
        <Tooltip
          message="O número do Prontuário Único é preenchido automaticamente ao finalizar o cadastro do paciente"
          startOpen
          class="tooltip"
        >
          <IconInfoHelper slot="icon" hollow />
        </Tooltip>

        <div class="row-1">
          <RgInput
            v-model="form.recordNumber"
            :class="{ disable: true }"
            :disabled="true"
            label="Prontuário Único"
            placeholder="000000000"
          />

          <RgInputMasked
            id="prontuario-unidade"
            v-model="form.ppr_numero"
            :class="{ disable: cantEdit || form.automaticUnitRecordNumber }"
            :disabled="cantEdit || form.automaticUnitRecordNumber"
            label="Prontuário da Unidade"
            mask="###########"
            placeholder="Digite um número de prontuário"
          />

          <div>
            <input
              id="prontuario-automatico"
              v-model="form.automaticUnitRecordNumber"
              class="checkbox"
              type="checkbox"
              :class="{ disable: hasUnitHealthRecordNumber }"
              :disabled="hasUnitHealthRecordNumber"
            />
            <label> Gerar Prontuário Automático</label>
          </div>

          <RgInput
            v-if="isPrivateSector"
            v-model="form.pes_empresa_pleni"
            :maxlength="100"
            label="Empresa"
            placeholder="Digite o nome da empresa"
          />
        </div>

        <div v-if="isPrivateSector" class="row-2">
          <span>Dados da Atenção Primária</span>
        </div>

        <div v-if="isPrivateSector" class="row-4">
          <RgSelectUnithealthMappedPep
            ref="unit"
            v-model="pleni.uns_id"
            :rules="{ required: isPrivateSector }"
            label="Unidade de Saúde"
          />

          <RgSelectSectorMappedPep
            ref="sector"
            v-model="pleni.set_id"
            label="Setores"
            :disabled="!pleni.uns_id"
            :rules="{ required: isPrivateSector }"
            :unit-health-id="pleni.uns_id"
          />

          <RgSelectPlaceMappedPep
            ref="place"
            v-model="pleni.lca_id"
            :disabled="!pleni.set_id"
            :rules="{ required: isPrivateSector }"
            label="Local de Atendimento"
            :sector-id="pleni.set_id"
          />
        </div>
      </div>
    </FormBase>
  </section>
</template>

<script>
import {
  RgSelectUnithealthMappedPep,
  RgSelectSectorMappedPep,
  RgSelectPlaceMappedPep,
  RgInput,
  RgInputMasked,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { mapGetters } from "vuex";

export default {
  name: "PatientForm",
  components: {
    FormBase,
    RgInput,
    RgInputMasked,
    RgSelectUnithealthMappedPep,
    RgSelectPlaceMappedPep,
    RgSelectSectorMappedPep,
    Tooltip,
    IconInfoHelper,
  },
  props: {
    cantEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPrivateSector: false,
      pleni: {
        uns_id: null,
        set_id: null,
        lca_id: null,
      },
      form: {
        pac_id: null,
        recordNumber: null,
        ppr_numero: null,
        automaticUnitRecordNumber: false,
        uns_id: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      patientData: "Person/Patient/GET_PATIENT_INFO",
      isNewBorn: "Person/Patient/IS_NEWBORN",
    }),

    hasUnitHealthRecordNumber() {
      if (!this.form.ppr_numero) return false;
      return true;
    },
  },

  watch: {
    patientData(pValue) {
      const hasValue = pValue && Object.keys(pValue).length > 0;
      const isToLoadData = !this.isNewBorn && hasValue;

      if (isToLoadData) {
        const patient = this.$utils.obj.DeepCopy(pValue);

        this.loadPatientInformation(patient);
      }
    },
    "form.ppr_numero"(pValue) {
      if (pValue) {
        this.form.automaticUnitRecordNumber = false;
      }
    },
  },

  mounted() {
    this.isPrivateSector = this.$store.getters["Login/GET_PRIVATE_SECTOR"];
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  methods: {
    loadPatientInformation(patient) {
      const hasUnitHealthFromStore = this.getValue(patient, "uns_id") > 0;

      const unitHealthId = hasUnitHealthFromStore
        ? this.getValue(patient, "uns_id")
        : this.form.uns_id;

      try {
        this.form.pac_id = patient && patient.pac_id ? patient.pac_id : null;

        this.form.uns_id = unitHealthId;
        this.form.recordNumber = this.getValue(patient, "pac_prontuario_unico");
        this.form.ppr_numero = this.getValue(
          patient,
          "record_numbers",
          "ppr_numero",
        );
        this.pleni.lca_id = this.getValue(patient, "pleni", "lca_id");
        this.pleni.set_id = this.getValue(patient, "pleni", "set_id");
        this.pleni.uns_id = this.getValue(patient, "pleni", "uns_id");
        this.form.pes_empresa_pleni = this.getValue(
          patient,
          "pleni",
          "pes_empresa",
        );

        if (
          patient &&
          (patient.pac_bloqueado === true || patient.pac_bloqueado === false)
        ) {
          this.$emit("isBloquedPatient", patient.pac_bloqueado);
        }
      } catch (pErr) {
        this.$toaster.error("Falha ao carregar as informações do paciente");
      }
    },

    getValue(pData, pKey, pChildKey = null) {
      let hasValue = null;
      let data = null;

      if (pChildKey) {
        hasValue = pData && pData[pKey] && pData[pKey][pChildKey];
        data = hasValue ? pData[pKey][pChildKey] : null;
      } else {
        hasValue = pData && pData[pKey];
        data = hasValue ? pData[pKey] : null;
      }
      return data;
    },

    getPatientForm() {
      const patient = {
        pac_id: this.form.pac_id,
        ppr_numero: this.form.ppr_numero,
        pac_prontuario_unico: Number(this.form.recordNumber),
        automatic_unit_record_number: this.form.automaticUnitRecordNumber,
        uns_id: this.form.uns_id,
        pleni_place_id: this.pleni.lca_id,
        pleni_company: this.form.pes_empresa_pleni,
      };

      return patient;
    },

    cleanForm() {
      this.form.pac_id = null;
      this.form.recordNumber = null;
      this.form.ppr_numero = null;
      this.form.automaticUnitRecordNumber = false;
      this.form.uns_id = "";
      this.cleanPleniInfo();
    },

    cleanPleniInfo() {
      this.pleni.uns_id = null;
      this.pleni.set_id = null;
      this.pleni.lca_id = null;
      if (this.$refs.unit) {
        this.$refs.unit.cleanValidate();
      }
      if (this.$refs.sector) {
        this.$refs.sector.cleanValidate();
      }
      if (this.$refs.place) {
        this.$refs.place.cleanValidate();
      }
    },
  },
};
</script>
