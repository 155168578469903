<template lang="html">
  <aside v-if="show" class="modal-search-cad-sus">
    <RgBaseModal
      ref="modalSearchCadSus"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Busca de Pacientes CADSUS</div>
      </div>

      <div slot="body" class="body">
        <div class="tooltip-info">
          <Tooltip
            message="Faça a busca de pacientes utilizando os filtros, selecione o cadastro desejado na listagem e clique no botão “confirmar”."
            startOpen
            class="tooltip"
          >
            <IconInfoHelper slot="icon" hollow />
          </Tooltip>
        </div>

        <div class="content">
          <div class="filter">
            <div class="header">
              <span class="title">Filtros</span>
            </div>

            <div class="body">
              <RgValidatorForm ref="validator">
                <RgInput
                  ref="name"
                  v-model="form.pes_nome"
                  :disabled="nameValidation"
                  :class="{ disable: nameValidation }"
                  class="input"
                  placeholder="Digite o nome do paciente"
                  label="Nome"
                />

                <RgInputDate
                  ref="dateOfBirth"
                  v-model="form.pes_nascimento"
                  :disabled="dateOfBirthValidation"
                  :class="{ disable: dateOfBirthValidation }"
                  :rules="{ required: hasName }"
                  class="input"
                  label="Nascimento"
                />

                <RgInput
                  ref="mothersName"
                  v-model="form.pes_mae"
                  :disabled="mothersNameValidation"
                  :class="{ disable: mothersNameValidation }"
                  class="input"
                  placeholder="Digite o nome da mãe do paciente"
                  label="Nome da Mãe"
                />

                <RgInputCns
                  ref="cns"
                  v-model="form.crs_numero"
                  class="input"
                  :disabled="cnsValidation"
                  :class="{ disable: cnsValidation }"
                />

                <RgInputCpf
                  ref="cpf"
                  v-model="form.cpf_numero"
                  class="input"
                  :disabled="cpfValidation"
                  :class="{ disable: cpfValidation }"
                  @blur="focusName"
                />
              </RgValidatorForm>
            </div>

            <div class="footer">
              <div class="action">
                <RgCleanButton
                  small
                  class="clean"
                  data-id="limpar-filtro"
                  title="Limpar"
                  @click="cleanForm"
                />

                <RgSearchButton
                  ref="btnSubmit"
                  v-shortkey="['enter']"
                  type="button"
                  data-id="buscar"
                  class="search"
                  title="Buscar"
                  :class="{
                    'disable unselect': noInputValues,
                  }"
                  :disabled="noInputValues"
                  @shortkey.native="searchCadsus"
                  @submit="searchCadsus"
                />
              </div>
            </div>
          </div>

          <div class="result">
            <div class="header">
              <span class="title">Resultados da Busca</span>
            </div>

            <div class="body">
              <div
                v-if="mutableHistoryList && mutableHistoryList.length > 0"
                class="table"
              >
                <RgTable :columns="COLUMNS">
                  <tr
                    v-for="(item, index) in mutableHistoryList"
                    :key="index"
                    slot="rows"
                    :class="{ selected: index === activeRow }"
                    class="tr"
                    @click="getCadSusDetail(item, index)"
                  >
                    <td class="td">
                      {{
                        item && item.NomeCompleto ? item.NomeCompleto.Nome : ""
                      }}
                    </td>

                    <td class="td">
                      {{ item ? item.dataNascimento : "" }}
                    </td>

                    <td class="td">
                      {{ item && item.Mae ? item.Mae.Nome : "" }}
                    </td>

                    <td class="td">
                      {{ getCNS(item) }}
                    </td>
                  </tr>
                </RgTable>
              </div>

              <div v-else class="no-result">
                <IconEmpty class="empty-icon" />
                <span class="info"
                  >Não foram encontrados resultados para essa busca</span
                >
              </div>

              <RgList
                v-show="mutableHistoryList && mutableHistoryList.length > 0"
                ref="rgList"
                v-model="mutableHistoryList"
                :search-function="searchFunction"
                :build-filter="generateFilter"
                :backend-legacy="true"
                :show-total="true"
                :register-per-page="10"
                :max-register="10"
                @pagination="clearLine"
              />
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="footer">
        <RgConfirmButton
          :disabled="Object.keys(selectedLine).length < 1"
          medium
          @click="selectPerson"
        />
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgList, RgTable } from "~tokio/foundation";

import {
  RgBaseModal,
  RgInput,
  RgInputDate,
  RgSearchButton,
  RgCleanButton,
  RgConfirmButton,
  Tooltip,
  RgValidatorForm,
  IconInfoHelper,
} from "~tokio/primitive";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import { IconEmpty } from "~tokio/primitive/icon/symbols";

export default {
  name: "ModalSearchCadSus",
  components: {
    IconEmpty,
    RgBaseModal,
    RgList,
    RgInput,
    RgInputDate,
    RgInputCns,
    RgInputCpf,
    RgSearchButton,
    RgCleanButton,
    RgConfirmButton,
    Tooltip,
    RgValidatorForm,
    RgTable,
    IconInfoHelper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutableHistoryList: null,
      selectedLine: {},
      activeRow: false,
      clickedSearch: false,
      form: {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        cpf_numero: "",
        crs_numero: "",
      },
      required: false,
      isSearching: false,
    };
  },
  computed: {
    hasName() {
      return !!this.form.pes_nome;
    },

    hasBirthOfDate() {
      return !!this.form.pes_nascimento;
    },

    hasMothersName() {
      return !!this.form.pes_mae;
    },

    hasCns() {
      return !!this.form.crs_numero;
    },

    hasCpf() {
      return !!this.form.cpf_numero;
    },

    nameValidation() {
      return this.hasCns || this.hasCpf || this.hasMothersName;
    },

    dateOfBirthValidation() {
      return !this.hasName;
    },

    mothersNameValidation() {
      return this.hasName || this.hasBirthOfDate || this.hasCns || this.hasCpf;
    },

    cnsValidation() {
      return this.disableDocuments || this.hasCpf;
    },

    cpfValidation() {
      return this.disableDocuments || this.hasCns;
    },

    disableDocuments() {
      return (
        (this.form.pes_nome || this.form.pes_nascimento || this.form.pes_mae)
          .length > 0
      );
    },

    noInputValues() {
      if (!this.hasCns && !this.hasCpf) {
        return !this.hasName && !this.hasBirthOfDate && !this.hasMothersName;
      } else {
        return false;
      }
    },
  },

  watch: {
    "form.pes_nome"(pValue) {
      if (!pValue) {
        this.form.pes_nascimento = "";
        this.cleanValidate();
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Paciente" },
      { name: "Data de Nascimento" },
      { name: "Nome da Mãe" },
      { name: "CNS" },
    ];
  },

  mounted() {
    this.focusName();
  },

  beforeDestroy() {
    this.cleanForm();
  },

  methods: {
    focusName() {
      if (this.$refs.name) {
        this.$refs.name.setFocus();
      }
    },

    async searchFunction(pData) {
      try {
        if (this.clickedSearch && !this.isSearching) {
          this.isSearching = true;

          const isValid = await this.isFormValid();
          if (!isValid) {
            this.$toaster.warning("Verifique os campos");
            this.$refs.btnSubmit.fail();
            return false;
          }

          delete pData.offset;

          this.$refs.btnSubmit.actionSubmit();
          this.$loader.start("Buscando paciente...");

          const rows = await this.$store.dispatch(
            "Person/SEARCH_CADSUS",
            pData,
          );

          const hasResult = rows && rows.length > 0;
          if (hasResult) {
            const patientList = { rows };
            return patientList;
          }

          return null;
        }
      } catch (pErr) {
        this.mutableHistoryList = null;
        this.$toaster.warning(
          "Não foi possível realizar a busca com os dados informados ou nenhum resultado foi encontrado",
        );
        this.$refs.btnSubmit.fail();
      } finally {
        this.$refs.btnSubmit.actionDone();
        this.$loader.finish();
        this.isSearching = false;
      }
    },

    generateFilter() {
      const cpf = this.form.cpf_numero
        ? this.form.cpf_numero.replace(/[^\d]+/g, "")
        : "";
      const crs = this.form.crs_numero
        ? this.form.crs_numero.replace(/[^\d]+/g, "")
        : "";

      const tipoPesquisa = this.form.pes_mae ? "IDENTICA" : "APROXIMADA";

      const arrfiltros = {
        cpf_numero: cpf,
        crs_numero: crs,
        nome: this.form.pes_nome ? this.form.pes_nome : "",
        nomeMae: this.form.pes_mae ? this.form.pes_mae : "",
        data_nascimento: this.form.pes_nascimento
          ? this.form.pes_nascimento
          : "",
        tipoPesquisa: tipoPesquisa,
      };

      return arrfiltros;
    },

    async searchCadsus() {
      this.clickedSearch = true;
      await this.$refs.rgList.submitForm();
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    getCadSusDetail(pItem, pIndex) {
      const isSameLine = this.selectedLine === pItem;

      if (isSameLine) {
        this.activeRow = null;
        this.selectedLine = {};
      } else {
        this.activeRow = pIndex;
        this.selectedLine = pItem;
      }
    },

    getCNS(pData) {
      if (!pData) return;
      if (pData.CNS) {
        return pData.CNS.numeroCNS;
      }
      if (pData.Cartoes && pData.Cartoes.CNS) {
        if (!Array.isArray(pData.Cartoes.CNS)) {
          return pData.Cartoes.CNS.numeroCNS;
        } else {
          for (const cns of pData.Cartoes.CNS) {
            if (this.form.crs_numero === cns.numeroCNS) {
              return cns.numeroCNS;
            }
          }
        }
      }
    },

    async selectPerson() {
      const item = this.selectedLine;

      if (this.hasCns) {
        this.$emit("close", item);
      } else {
        const arrfiltros = {
          cpf_numero: "",
          crs_numero: item.CNS.numeroCNS,
          nome: "",
          nomeMae: "",
          data_nascimento: "",
          tipoPesquisa: "APROXIMADA",
        };
        const result = await this.searchFunction(arrfiltros);
        this.$emit("close", result.rows[0]);
      }
      this.cleanForm();
    },

    close() {
      if (this.mutableHistoryList?.lenght > 0) {
        this.$refs.rgList.resetLimitOffset();
        this.$refs.rgList.clearStorePagination();
      }
      this.clickedSearch = false;
      this.$emit("close");
    },
    clearLine() {
      this.selectedLine = {};
      this.activeRow = null;
    },
    cleanForm() {
      this.form = {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        cpf_numero: "",
        crs_numero: "",
      };
      this.activeRow = null;
      this.mutableHistoryList = null;
      this.clickedSearch = false;

      if (this.mutableHistoryList?.lenght > 0) {
        this.$refs.rgList.resetLimitOffset();
        this.$refs.rgList.clearStorePagination();
      }
      this.selectedLine = {};
    },

    cleanValidate() {
      this.$refs.dateOfBirth.cleanValidate();
    },
  },
};
</script>
