import Request from "./index";
import Store from "@/store";
import { HandleUnauthorized, HandleForbidden } from "./error-handlers";
import { SERVER_CONFIG } from "~utils/Config";

const request = new Request(SERVER_CONFIG);

request.getHeaders = (headers) => {
  headers.authorization = Store.getters["Login/GET_TOKEN"];
  headers.unithealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  headers["Content-Type"] = headers["Content-Type"] || "application/json";
  return headers;
};

request.onunauthorized = () => {
  HandleUnauthorized();
};

request.onuforbidden = () => {
  HandleForbidden();
};

export default request;
