<template>
  <div v-show="show" class="modal-person-deficient">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-person-deficient-header">
        <span class="title">
          Sinalização do Paciente portador de deficiência
        </span>
      </div>
      <div slot="body" class="body-person-deficient">
        <div class="subtitle">
          O paciente é portador de deficiência ou mobilidade reduzida? <br />
          Se sim, clique nas opções abaixo e prossiga com o cadastro.
        </div>
        <RgRadioCustom
          id="validations"
          multSelect
          :refreshValue="listDeficient"
          size="18px"
          class="radio-custom-person-deficient"
          v-bind="propsRadioCustom"
          @input="onInputRadioCustom"
        />
      </div>
      <div slot="footer" class="modal-person-deficient-footer">
        <RgCancelButton medium label="Cancelar" @click="close" />
        <RgSaveButton medium label="Prosseguir" @click="save" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgSaveButton,
  RgCancelButton,
  RgRadioCustom,
} from "~tokio/primitive";

export default {
  name: "ModalPersonDeficient",
  components: {
    RgBaseModal,
    RgRadioCustom,
    RgSaveButton,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    reLoader: Function,
  },
  data() {
    return {
      listDeficient: [],
      oldListDeficient: [],
      isEdit: false,
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        {
          title: `Cadeirante`,
          id: "1",
          icon: "1",
          color: "#2186D6",
        },
        {
          title: `Deficiência Visual`,
          id: "2",
          icon: "2",
          color: "#2186D6",
        },
        {
          title: `Surdez`,
          id: "3",
          icon: "3",
          color: "#2186D6",
        },
        {
          title: `Autismo`,
          id: "4",
          icon: "4",
          color: "#2186D6",
        },
      ];

      const value = this.listDeficient;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },
  methods: {
    loaderListPerson() {
      const person = this.$store.getters["Person/Patient/GET_PERSONAL_DATA"];

      if (person.deficient && person.deficient !== "") {
        this.isEdit = true;
        const formatDeficient = person.deficient.replace(/'/g, `"`);
        this.oldListDeficient = Array.from(
          JSON.parse(formatDeficient).map((element) => {
            return element.pde_id_deficiencias;
          }),
        );
      }

      this.listDeficient = Array.from(this.oldListDeficient);
      this.$store.commit(
        "Person/Patient/SET_PERSON_DEFICIENT",
        Array.from(this.listDeficient),
      );
    },
    onInputRadioCustom(item) {
      this.listDeficient = Array.from(item);
    },
    save() {
      this.$emit("seletedDeficient", {
        list: Array.from(this.listDeficient),
        oldList: this.isEdit ? Array.from(this.oldListDeficient) : null,
      });
      this.$store.commit(
        "Person/Patient/SET_PERSON_DEFICIENT",
        Array.from(this.listDeficient),
      );
      this.$emit("close");
    },
    get() {
      return this.$store.getters["Person/Patient/GET_PERSON_DEFICIENT"];
    },

    close() {
      this.isEdit = false;
      const store = this.$store.getters["Person/Patient/GET_PERSON_DEFICIENT"];
      this.listDeficient = Array.from(store);
      this.$emit("close");
    },
  },
};
</script>
