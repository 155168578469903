<template>
  <div v-if="show" class="modal-hospitalization-billing-neonatal">
    <RgBaseModal :show="show" class="rg-base-modal" @close="close">
      <div slot="header" class="header">
        <div class="title">Dados Adicionais do Procedimento</div>
      </div>

      <div slot="body" class="content-modal">
        <RgContentHospitalizationBillingNeonatal
          :procedure="procedure"
          :required-documents="requiredDocuments"
          @onFinish="onFinish"
          @close="close"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import RgContentHospitalizationBillingNeonatal from "./rg-content-hospitalization-billing-neonatal/RgContentHospitalizationBillingNeonatal";
import { RgBaseModal } from "~tokio/primitive/notification";

export default {
  name: "ModalHospitalizationBillingNeonatal",
  components: {
    RgContentHospitalizationBillingNeonatal,
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {},
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    onFinish(event) {
      this.$emit("onFinish", event);
    },
  },
};
</script>
