<template>
  <Modulebox
    ref="modulebox"
    title="Importar Competências e Procedimentos (SIGTAP e BDSIA)"
    class="import-competence"
  >
    <div class="import-competence-body">
      <FormBase
        title="Dados da Configuração Atual"
        :class="{ disable: disabledByModal }"
      >
        <div class="import-info">
          <span class="label">
            CNES da Unidade: <strong>{{ cnes || "-" }} </strong>
          </span>

          <span class="label">
            Unidade de Saúde: <strong>{{ uns_nome || "-" }}</strong>
          </span>
          <span class="label">
            Competência Configurada: <strong>{{ period || "-" }}</strong>
          </span>
        </div>
        <div class="import-info">
          <span class="label">
            Arquivo SIGTAP Atual: <strong> {{ archiveSigtap || "-" }}</strong>
          </span>

          <span class="label">
            Arquivo BDSIA Atual: <strong> {{ archiveBdsia || "-" }}</strong>
          </span>
          <span class="label"> </span>
        </div>
        <LargeButton
          id="select-competence"
          label="Definir Competência"
          :backgroundColor="'#1E88A9'"
          class="btn-import-competence"
          :disabled="disabledByModal"
          @click="routerSelectCompetence()"
        >
          <IconCalendar slot="icon" class="icon-import-calendar" color="#fff" />
        </LargeButton>
      </FormBase>
      <FormBase
        title="Tabelas disponíveis para Importação"
        :class="{ disable: disabledByModal }"
      >
        <div class="tooltips">
          <Tooltip class="tooltip-print">
            <IconInfoHelper slot="icon" style="width: 28.5px; height: 28.5px" />
            <div slot="content" class="content">
              <div class="title">
                <span> Arquivos disponibilizados pelo “Datasus.gov.br”. </span>
              </div>
            </div>
          </Tooltip>
          <Tooltip class="tooltip-print">
            <IconLightBulbHelper slot="icon" />
            <div slot="content" class="content">
              <div class="title">
                <span>
                  Para importar no sistema as competências do “Datasus.gov.br”,
                  selecione um dos arquivos abaixo e aperte o botão “Importar”.
                </span>
              </div>
            </div>
          </Tooltip>
        </div>
        <div class="options">
          <div class="legend">
            <div class="container-icons-active">
              <i class="el-icon-download icons-active"></i>
            </div>
            <span>Importado no Sistema </span>
            <div class="container-icons-active">
              <i class="el-icon-upload icons-active"></i>
            </div>
            <span>Disponível para download </span>
            <div class="container-icons-active">
              <i class="el-icon-folder-add icons-active"></i>
            </div>
            <span>Pré-carregado no sistema EsusNG </span>
          </div>
          <div class="buttons">
            <RgAddButton
              id="upload-import"
              title="Importar tabelas manualmente"
              small
              :backgroundColor="'#1E88A9'"
              :disabled="disabledByModal"
              @click="nextModal(5)"
            />
            <RgHistoryButton
              id="show-history"
              title="Histórico"
              :disabled="disabledByModal"
              @click="openModalHistory"
            />
            <SmallButton
              id="reload-list"
              title="Carregar listagem"
              :backgroundColor="'#1E88A9'"
              :disabled="disabledReloadLinks || disabledByModal"
              @click="reloadListLinks"
            >
              <div slot="icon" class="container-icon-reload-links">
                <font-awesome-icon
                  icon="fa-solid fa-rotate fa-spin"
                  class="icon-loader-reload-links"
                />
              </div>
            </SmallButton>
            <LargeButton
              id="import"
              label="Importar"
              :backgroundColor="'#1E88A9'"
              :disabled="
                (!selectLinkBdsia && !selectLinkSigtap) || disabledByModal
              "
              @click="nextModal(1)"
            >
              <IconArrowDownload slot="icon" />
            </LargeButton>
          </div>
        </div>
        <div class="table">
          <SmartTable
            ref="smartTable"
            name="ListCompetenceTable"
            :columns="COLUMNS"
            :body="listCompetence"
            :total="Number(listCompetence.length)"
            :show-pagination="false"
            removeBtnColumns
            :disabled="disabledByModal"
            @getLine="selectLine"
          />
        </div>
      </FormBase>
    </div>
    <ModalInfoSigtapBdsia :show="showNextModal === 1" :showOld="nextModal" />
    <ModalTimeProcedureSigtapBdsia
      :show="showNextModal === 2"
      :showOld="nextModal"
      :data-form="dataTimeProcedure"
      :init-procedure="initImport"
      :has-error="hasError"
    />
    <ModalProcedureSigtapBdsia
      :show="showNextModal === 3"
      :data-form="dataProcedure"
      :disabled-close="disabledCloseModalProcedure"
      :showOld="nextModal"
      :reload-init="requestStatusProcedure"
      :in-progress="inProgress"
      :has-problem="hasError"
    />
    <ModalFinishSigtapBdsia
      :show="showNextModal === 4"
      :showOld="nextModal"
      @reload="load(true)"
    />
    <ModalUploadSigtapBdsia
      ref="modalUploadSigtapBdsia"
      :show="showNextModal === 5"
      :showOld="nextModal"
      :init-procedure="loadFileImportUpload"
      :typeImportProcedure="typeImportProcedure"
    />
    <ModalHistorySigtapBdsia :show="modalHistory" @close="closeModalHistory" />
  </Modulebox>
</template>

<script>
import { Modulebox } from "~tokio/foundation";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import {
  IconInfoHelper,
  RgHistoryButton,
  RgAddButton,
  Tooltip,
  LargeButton,
  IconArrowDownload,
  IconCalendar,
  IconLightBulbHelper,
  SmallButton,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ModalInfoSigtapBdsia from "../components/modal-info/ModalInfoSigtapBdsia.vue";
import ModalTimeProcedureSigtapBdsia from "../components/modal-time-procedure/ModalTimeProcedureSigtapBdsia.vue";
import ModalProcedureSigtapBdsia from "../components/modal-procedure/ModalProcedureSigtapBdsia.vue";
import ModalFinishSigtapBdsia from "../components/modal-finish/ModalFinishSigtapBdsia.vue";
import ModalHistorySigtapBdsia from "../components/modal-history/ModalHistorySigtapBdsia.vue";
import ModalUploadSigtapBdsia from "../components/modal-upload/ModalUploadSigtapBdsia.vue";
import SendFileSigtap from "../store/actions/upload-manual-sigtap-bdsia/SendFileSigtap";
import SendFileBdsia from "../store/actions/upload-manual-sigtap-bdsia/SendFileBdsia";
import moment from "moment";

const DATA_TIME_PROCEDURE = {
  time: null,
  position: null,
  type: null,
  totalQueue: 0,
  disabledButton: false,
};

const DATA_PROCEDURE = {
  procedureStep: [],
  message: "",
  type: null,
};

export default {
  name: "ImporteCompetence",
  components: {
    Modulebox,
    FormBase,
    IconInfoHelper,
    Tooltip,
    SmartTable,
    RgHistoryButton,
    LargeButton,
    RgAddButton,
    IconArrowDownload,
    ModalInfoSigtapBdsia,
    ModalTimeProcedureSigtapBdsia,
    ModalProcedureSigtapBdsia,
    ModalFinishSigtapBdsia,
    ModalHistorySigtapBdsia,
    ModalUploadSigtapBdsia,
    IconCalendar,
    IconLightBulbHelper,
    SmallButton,
  },

  data() {
    return {
      cnes: null,
      user_id: null,
      uns_nome: null,
      cmp: null,
      period: null,
      archiveSigtap: "-",
      archiveBdsia: "-",
      listCompetence: [],
      selectLinkSigtap: null,
      selectLinkBdsia: null,
      dataTimeProcedure: this.$utils.obj.DeepCopy(DATA_TIME_PROCEDURE),
      dataProcedure: this.$utils.obj.DeepCopy(DATA_PROCEDURE),
      showNextModal: 0,
      modalHistory: false,
      disabledCloseModalProcedure: true,
      inProgress: false,
      fileUploadSigtap: null,
      fileUploadBdsia: null,
      typeProcedureUploadManual: false,
      hasError: false,
      disabledReloadLinks: true,
    };
  },

  computed: {
    disabledByModal() {
      return (
        this.modalHistory ||
        this.showNextModal === 1 ||
        this.showNextModal === 2 ||
        this.showNextModal === 3 ||
        this.showNextModal === 4 ||
        this.showNextModal === 5
      );
    },
  },

  watch: {},

  mounted() {
    this.cnes = this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
    this.user_id = this.$store.getters["Login/GET_USER_ID"];
    this.uns_nome = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
    this.cmp = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    this.period = this.cmp ? moment(this.cmp, "YYYYMM").format("MM/YYYY") : "-";
    this.load();
    this.positionInQueue("SIGTAP");
    this.reloadProcedure();
  },

  created() {
    this.COLUMNS = [
      { name: "Competência", key: "competenceFormat", align: "left" },
      {
        name: "Situação SIGTAP",
        key: "statusSigtap",
        align: "center",
        active: true,
        html: true,
      },
      {
        name: "Arquivo SIGTAP",
        key: "fileNameSigtap",
        align: "left",
      },
      {
        name: "Situação BDSIA",
        key: "statusBdsia",
        align: "center",
        active: true,
        html: true,
      },
      {
        name: "Arquivo BDSIA",
        key: "fileNameBdsia",
        align: "left",
      },
    ];
  },

  methods: {
    openModalHistory() {
      this.modalHistory = true;
    },
    closeModalHistory() {
      this.modalHistory = false;
    },
    async reloadListLinks() {
      this.disabledReloadLinks = true;
      this.$loader.start();
      await this.$store.dispatch(
        "Billing/ImportBdsiaSigtap/UPLOAD_LINKS_SIGTAP_BDSIA",
      );
      setTimeout(() => {
        this.load();
      }, 16000);
    },
    async load(isReload = false) {
      try {
        this.$loader.start();

        this.$refs.modalUploadSigtapBdsia.clear();

        if (!isReload) {
          const resultLastCompetence = await this.$store.dispatch(
            "Billing/ImportBdsiaSigtap/SEARCH_HISTORY_IMPORT",
            {
              competence: this.cmp,
            },
          );

          this.archiveSigtap =
            resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_arquivo_sigtap;
          this.archiveBdsia =
            resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_arquivo_bdsia;
        }

        const resultSigtap = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/SEARCH_EXTERNAL_LIST_SIGTAP",
        );

        const resultBdsia = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/SEARCH_EXTERNAL_LIST_BDSIA",
        );

        const existCompetence = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/SEARCH_ALL_COMPETENCE",
        );

        const result = [];

        let resultLastCompetence;

        if (resultSigtap?.list) {
          resultLastCompetence = await this.$store.dispatch(
            "Billing/ImportBdsiaSigtap/SEARCH_HISTORY_IMPORT",
            {
              competence: this.cmp,
            },
          );
        }

        for (const sigtap of resultSigtap.list) {
          const cmpSigtap = sigtap.split("/")[7].split("_")[1];
          const fileNameSigtap = sigtap.split("/");

          const respectiveBdsia = resultBdsia.list.filter((element) => {
            const cmpBdsia = element
              .split("/")[5]
              .split("BDSIA")[1]
              .split(".exe")[0]
              .substring(0, 6);

            return cmpSigtap === cmpBdsia;
          });

          if (respectiveBdsia.length >= 1) {
            const fileNameBdsia = respectiveBdsia[0].split("/");
            const activeCompetenceSigtap = existCompetence.sigtapRows?.some(
              (e) => {
                return e.competence === cmpSigtap;
              },
            );

            const activeCompetenceBdsia = existCompetence.bdsiaRows?.some(
              (e) => {
                return e.competence === cmpSigtap;
              },
            );

            result.push({
              competence: cmpSigtap,
              competenceFormat: moment(cmpSigtap, "YYYYMM").format("MM-YYYY"),
              fileNameSigtap: fileNameSigtap[7],
              statusSigtap: `<i class="el-icon-download" style="color:${
                activeCompetenceSigtap ? "#3BC0F2" : "#CCCCCC"
              }; font-size: 20px "></i><i class="el-icon-upload" style="color:${
                resultSigtap.available ? "#3BC0F2" : "#CCCCCC"
              }; font-size: 20px"></i><i class="el-icon-folder-add" style="color:${
                resultSigtap.type === "new" ? "#CCCCCC" : "#3BC0F2"
              }; font-size: 20px"></i>`,
              fileNameBdsia: fileNameBdsia[5],
              statusBdsia: `<i class="el-icon-download" style="color:${
                activeCompetenceBdsia ? "#3BC0F2" : "#CCCCCC"
              }; font-size: 20px "></i><i class="el-icon-upload" style="color:${
                resultBdsia.available ? "#3BC0F2" : "#CCCCCC"
              }; font-size: 20px"></i><i class="el-icon-folder-add" style="color:${
                resultBdsia.type === "new" ? "#CCCCCC" : "#3BC0F2"
              }; font-size: 20px "></i>`,
              linkSigtap: sigtap,
              linkBdsia: respectiveBdsia[0],
            });

            if (this.period === "-") {
              this.archiveSigtap = null;
              this.archiveBdsia = null;
            } else {
              this.archiveSigtap =
                resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_arquivo_sigtap;
              this.archiveBdsia =
                resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_arquivo_bdsia;
            }
          }
        }

        this.listCompetence = result;
      } catch (e) {
        if (
          e.message.includes("notDownloadLinksFileBdsia") ||
          e.message.includes("notDownloadLinksFileSigtap")
        ) {
          this.$toaster.error(
            `O DataSUS apresenta instabilidade no acesso das tabelas. Tente novamente.`,
            "Falha na conexão dos dados SIGTAP/BDSIA",
          );
          this.disabledReloadLinks = false;
        } else {
          this.$toaster.error(
            `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
            "Falha na importação SIGTAP e BDSIA",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },

    async importSigtap(allImport) {
      try {
        this.$loader.start();
        await this.$store.dispatch("Billing/ImportBdsiaSigtap/IMPORT_SIGTAP", {
          variables: {
            importSigtap: {
              link: this.selectLinkSigtap,
              userId: String(this.user_id),
            },
          },
        });

        this.requestStatusProcedure("SIGTAP", allImport);
      } catch (error) {
        this.hasError = true;
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha na importação do arquivo SIGTAP",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async importBdsia() {
      try {
        this.$loader.start();
        await this.$store.dispatch("Billing/ImportBdsiaSigtap/IMPORT_BDSIA", {
          variables: {
            importBdsia: {
              link: this.selectLinkBdsia,
              userId: String(this.user_id),
            },
          },
        });

        this.requestStatusProcedure("BDSIA");
      } catch (error) {
        this.hasError = true;
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha na importação do arquivo BDSIA",
        );
      } finally {
        this.$loader.finish();
      }
    },

    async uploadFileSigtap(allImport) {
      await SendFileSigtap(this.fileUploadSigtap, {
        link: this.selectLinkSigtap,
        userId: String(this.user_id),
      });

      this.requestStatusProcedure("SIGTAP", allImport, true);
    },

    async uploadFileBdsia() {
      await SendFileBdsia(this.fileUploadBdsia, {
        link: this.selectLinkBdsia,
        userId: String(this.user_id),
      });

      this.requestStatusProcedure("BDSIA");
    },

    async requestStatusProcedure(type, allImport, uploadManual) {
      this.modificationStatusProcedures(type, allImport, uploadManual);

      const reload = setInterval(async () => {
        this.modificationStatusProcedures(
          type,
          allImport,
          reload,
          uploadManual,
        );
      }, 60000);
    },

    async modificationStatusProcedures(
      type,
      allImport,
      closeInterval,
      uploadManual,
    ) {
      try {
        const variables = {
          userId: String(this.user_id),
        };
        const URL =
          type === "SIGTAP"
            ? "STATUS_PROCEDURES_SIGTAP"
            : "STATUS_PROCEDURES_BDSIA";
        const result = await this.$store.dispatch(
          `Billing/ImportBdsiaSigtap/${URL}`,
          variables,
        );
        this.positionInQueue(type);

        this.dataProcedure.message = ``;

        const resultFilter = [];

        for (const procedure of result.status.procedures) {
          if (resultFilter.length < 1) {
            resultFilter.push(procedure);
          } else {
            if (
              resultFilter.filter((element) => {
                return element.code === procedure.code;
              }).length === 0
            ) {
              resultFilter.push(procedure);
            }
          }
        }

        this.dataProcedure.procedureStep = resultFilter.map((element) => {
          return element.code.toString();
        });

        for (const iterator of resultFilter) {
          this.dataProcedure.message += `
          ${iterator.message} <br/>`;
        }

        this.dataProcedure.type = type;

        this.inProgress = true;
        setTimeout(() => {
          this.inProgress = false;
        }, 59500);

        if (resultFilter.findIndex((element) => element.code === "900") >= 0) {
          if (closeInterval) {
            clearInterval(closeInterval);
          }
          if (
            resultFilter.findIndex((element) => element.code === "500") ===
              -1 &&
            allImport
          ) {
            const existMessageInfo = resultFilter.findIndex(
              (element) => element.code === "100",
            );
            const existStatusTimeOutFTP = resultFilter.findIndex(
              (element) => element.code === "499",
            );

            if (existMessageInfo > -1) {
              this.$toaster.info(
                resultFilter[existMessageInfo].message,
                resultFilter[existMessageInfo].title,
              );
            }

            if (existStatusTimeOutFTP > -1) {
              this.hasError = true;
              this.$toaster.error(
                resultFilter[existStatusTimeOutFTP].message,
                resultFilter[existStatusTimeOutFTP].title,
              );
            } else {
              setTimeout(() => {
                this.positionInQueue("BDSIA");
                this.dataTimeProcedure.disabledButton = true;
                this.nextModal(2);
                this.dataProcedure = this.$utils.obj.DeepCopy(DATA_PROCEDURE);
                if (uploadManual) {
                  this.uploadFileBdsia();
                } else {
                  this.importBdsia();
                }
              }, 5000);
            }
          } else {
            if (
              resultFilter.findIndex((element) => element.code === "500") > -1
            ) {
              const fileLoggerError = await this.$store.dispatch(
                "Billing/ImportBdsiaSigtap/SEARCH_LOGGER_ERROR",
              );
              this.downloadFile(fileLoggerError, "erro_log_sigtap_bdsia.log");
              this.hasError = true;
              this.disabledCloseModalProcedure = false;
              this.dataTimeProcedure = this.$utils.obj.DeepCopy(
                DATA_TIME_PROCEDURE,
              );
              this.selectLinkSigtap = null;
              this.selectLinkBdsia = null;
              this.$refs.smartTable.cleanSelectRow();
            } else {
              this.nextModal(4);
              this.dataTimeProcedure = this.$utils.obj.DeepCopy(
                DATA_TIME_PROCEDURE,
              );
              this.dataProcedure = this.$utils.obj.DeepCopy(DATA_PROCEDURE);
              this.selectLinkSigtap = null;
              this.selectLinkBdsia = null;
              this.$refs.smartTable.cleanSelectRow();
              this.hasError = false;
            }
          }
        }
      } catch (error) {
        this.hasError = true;
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha no retorno da situação de importação",
        );
      }
    },

    async positionInQueue(type) {
      try {
        switch (type) {
          case "SIGTAP":
            {
              const result = await this.$store.dispatch(
                "Billing/ImportBdsiaSigtap/LIST_QUEUE_SIGTAP",
              );
              const position = result.findIndex(
                (element) => element.userId === String(this.user_id),
              );
              this.timeProcedure(type, position + 1, result.length + 1);
            }
            break;
          case "BDSIA":
            {
              const result = await this.$store.dispatch(
                "Billing/ImportBdsiaSigtap/LIST_QUEUE_BDSIA",
              );
              const position = result.findIndex(
                (element) => element.userId === String(this.user_id),
              );
              this.timeProcedure(type, position + 1, result.length + 1);
            }
            break;
        }
      } catch (error) {
        this.hasError = true;
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha ao entrar na Fila de Atendimento",
        );
      }
    },

    async timeProcedure(type, position, listLength) {
      try {
        const variables = {
          position: parseInt(position),
        };
        switch (type) {
          case "BDSIA":
            {
              const result = await this.$store.dispatch(
                "Billing/ImportBdsiaSigtap/TIME_PROCEDURE_BDSIA",
                variables,
              );
              this.dataTimeProcedure.time = result.time;
              this.dataTimeProcedure.position = position || 0;
              this.dataTimeProcedure.type = type;
              this.dataTimeProcedure.totalQueue = listLength;
              if (position === 1) {
                setTimeout(() => {
                  this.nextModal(3);
                  this.$store.commit(
                    "Billing/ImportBdsiaSigtap/SET_DATE_IMPORT_BDSIA",
                    moment().format("DD/MM/YYYY - HH:mm"),
                  );
                }, 3000);
              }
            }
            break;
          case "SIGTAP":
            {
              const result = await this.$store.dispatch(
                "Billing/ImportBdsiaSigtap/TIME_PROCEDURE_SIGTAP",
                variables,
              );
              this.dataTimeProcedure.time = result.time;
              this.dataTimeProcedure.position = position || 0;
              this.dataTimeProcedure.type = type;
              this.dataTimeProcedure.totalQueue = listLength;
              if (position === 1) {
                setTimeout(() => {
                  this.nextModal(3);
                  this.$store.commit(
                    "Billing/ImportBdsiaSigtap/SET_DATE_IMPORT_SIGTAP",
                    moment().format("DD/MM/YYYY - HH:mm"),
                  );
                }, 3000);
              }
            }
            break;
        }
      } catch (error) {
        this.hasError = true;
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha ao definir o Tempo Médio de Atendimento",
        );
      }
    },

    async reloadProcedure() {
      try {
        const resultSigtap = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/LIST_QUEUE_SIGTAP",
        );
        const resultBdsia = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/LIST_QUEUE_BDSIA",
        );
        const positionSigtap = resultSigtap.findIndex(
          (element) => element.userId === String(this.user_id),
        );
        const positionBdsia = resultBdsia.findIndex(
          (element) => element.userId === String(this.user_id),
        );

        if (positionSigtap > -1) {
          this.dataProcedure.type = "SIGTAP";
          this.nextModal(3);
        } else if (positionBdsia > -1) {
          this.dataProcedure.type = "BDSIA";
          this.nextModal(3);
        }
      } catch (error) {
        this.hasError = true;
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha ao entrar na Fila de Atendimento",
        );
      }
    },

    selectLine(pValue) {
      if (pValue) {
        this.selectLinkSigtap = pValue.linkSigtap;
        this.selectLinkBdsia = pValue.linkBdsia;

        this.$store.commit(
          "Billing/ImportBdsiaSigtap/SET_DATA_IMPORT_SIGTAP_BDSIA",
          {
            name_file_sigtap: pValue.fileNameSigtap,
            name_file_bdsia: pValue.fileNameBdsia,
            cmp_import_sigtap: pValue.competence,
            cmp_import_bdsia: pValue.competence,
          },
        );
      } else {
        this.selectLinkSigtap = null;
        this.selectLinkBdsia = null;
      }
    },

    initImport() {
      this.hasError = false;
      if (this.typeProcedureUploadManual) {
        this.uploadFileSigtap(true);
      } else {
        this.importSigtap(true);
      }
    },

    loadFileImportUpload(filesData) {
      this.fileUploadSigtap = filesData.fileSigtap;
      this.fileUploadBdsia = filesData.fileBdsia;
      this.selectLinkSigtap = filesData.fileNameSigtap;
      this.selectLinkBdsia = filesData.fileNameBdsia;
      this.dataTimeProcedure.type = "SIGTAP";
      this.dataTimeProcedure.disabledButton = false;
    },

    typeImportProcedure(type) {
      this.typeProcedureUploadManual = type;
    },

    nextModal(callback) {
      this.showNextModal = callback;
      if (callback === 0) {
        this.hasError = false;
        this.selectLinkSigtap = null;
        this.selectLinkBdsia = null;
        this.$refs.smartTable.cleanSelectRow();
      }
      if (callback === 1) {
        this.positionInQueue("SIGTAP");
      }
    },

    routerSelectCompetence() {
      this.$router.push({
        name: "billing.bpa.define-period",
      });
    },

    downloadFile(value, name) {
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
