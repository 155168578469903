export default {
  GET_FILTER_PROVIDER: (state) => {
    return state.filter_provider;
  },
  GET_SELECTED_PROVIDER: (state) => state.selected_pharmacy_provider_data,

  GET_FILTER_INVENTORY: (state) => {
    return state.filter_inventory;
  },
  GET_SELECTED_INVENTORY: (state) => state.selected_pharmacy_inventory_data,
};
