<template>
  <section class="quick-registration-bulletin">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="form-base">
          <div class="container-patient">
            <span
              v-if="isPreRegistrationRoute && !isPatient"
              class="name-pre-registration"
            >
              <Tooltip
                :message="`Nome pré-cadastro: ${
                  preRegistrationData && preRegistrationData.prc_nome
                }`"
                icon-title="Informações do Paciente"
                startOpen
                class="tooltip"
              >
                <IconInfoHelper slot="icon" />
              </Tooltip>
            </span>

            <div
              v-show="!isPatient"
              class="patient-not-identified"
              :class="{ margin: isPreRegistrationRoute }"
            >
              <input
                id="patient-not-identified-qrb"
                v-model="patientNotIdentified"
                :disabled="
                  disablePatientNotIdentified ||
                  disabled ||
                  disabledByModalComponent
                "
                :class="{ disable: disablePatientNotIdentified }"
                :value="true"
                type="checkbox"
              />
              <label> Paciente não identificado </label>
            </div>

            <RgSuggestPatientInformation
              id="patient-qrb"
              ref="patientInfoSuggest"
              v-model="patient"
              :rules="{
                forceSelection: true,
                required: !patientNotIdentified,
              }"
              :disabledOnlySuggest="!!hasPatientSelected"
              :disabled="disablePatient || patientNotIdentified || disabled"
              :with-patient-module="['emergency', 'hospitalization']"
              :extra-data="['withoutAddress']"
              :pac_prontuario_unico="
                patientInfo && patientInfo.prontuario_unico
              "
              :withPatientEmergency="true"
              :withPatientHospitalization="true"
              @disabledByModalComponents="disabledByModalComponents"
              @patientInfo="getPatientData"
              @blockedPatient="blockedPatient($event)"
            />
          </div>
        </FormBase>

        <FormBase title="Responsável" class="form-base">
          <div class="container-responsible">
            <RgComboboxResponsibleType
              id="responsible-type-qrb"
              v-model="form.responsibleType"
              :disabled="
                sendNotIdentifiedToPep ||
                disabledPatient ||
                disabled ||
                disabledByModalComponent
              "
              :class="{ disable: sendNotIdentifiedToPep || disabledPatient }"
              :unidentifiedPatient="patientNotIdentified"
              default-text="Não informado"
              @select="selectedResponsibleType"
            />

            <RgInput
              v-if="!form.responsibleType"
              id="responsible-name-qrb"
              v-model="form.responsibleName"
              :disabled="
                disableResponsibleName ||
                sendNotIdentifiedToPep ||
                !form.responsibleType ||
                disabledPatient ||
                disabled ||
                disabledByModalComponent
              "
              :class="{
                disable:
                  sendNotIdentifiedToPep ||
                  !form.responsibleType ||
                  disabledPatient,
              }"
              :rules="{ required: false }"
              label="Nome do Responsável "
            />

            <RgInput
              v-else
              id="responsible-name-qrb"
              v-model="form.responsibleName"
              :disabled="
                disableResponsibleName ||
                sendNotIdentifiedToPep ||
                disabledPatient ||
                disabled ||
                disabledByModalComponent
              "
              :class="{
                disable: sendNotIdentifiedToPep || disabledPatient,
              }"
              :rules="{ required: true }"
              label="Nome do Responsável "
            />
          </div>
        </FormBase>

        <FormBase title="Boletim" class="form-base">
          <div class="container-bulletin">
            <div class="grid-bulletin">
              <div class="row-1 grid-bulletin">
                <RgInputDate
                  id="date-qrb"
                  v-model="form.date"
                  :disabled="
                    disableEdit ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{ disable: disableEdit || disabledPatient }"
                  :rules="{ fn: verifyRetroactiveDate }"
                  :max-date="new Date()"
                  label="Data da Entrada"
                />

                <RgInputHour
                  id="hour-qrb"
                  v-model="form.hour"
                  :disabled="
                    disableEdit ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{ disable: disableEdit || disabledPatient }"
                  :rules="{ fn: verifyRetroactiveHour }"
                  label="Hora da Entrada"
                  placeholder="hh:mm"
                />

                <RgSelectUnithealth
                  id="unit-qrb"
                  v-model="form.unit"
                  label="Unidade de Saúde"
                  class="inputitem"
                  :class="{
                    disable: true,
                  }"
                  disabled
                />

                <RgComboboxSectorPerUnitPep
                  id="sector-qrb"
                  ref="sector"
                  v-model="form.sector"
                  :disabled="
                    sendNotIdentifiedToPep ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: sendNotIdentifiedToPep || disabledPatient,
                  }"
                  :unitHealth="UNIT_HEALTH"
                  sectorType="EMERGENCIAL"
                  :operationId="operationId"
                  :active="true"
                  :rules="{ required: true }"
                  @change="selectingSector"
                />

                <RgComboboxPlacePerSectorPep
                  id="place-qrb"
                  ref="place"
                  v-model="form.place"
                  :disabled="
                    sendNotIdentifiedToPep ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable:
                      sendNotIdentifiedToPep || disabledPatient || !form.sector,
                  }"
                  :sector="Number(form.sector)"
                  :active="true"
                  :rules="{ required: true }"
                  :empty-default-text="'Selecione o setor'"
                />

                <RgComboboxHealthPlan
                  id="health-plan-qrb"
                  ref="healthPlan"
                  v-model="form.healthPlan"
                  :disabled="
                    sendNotIdentifiedToPep ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: sendNotIdentifiedToPep || disabledPatient,
                  }"
                  :rules="{ required: true }"
                />
              </div>

              <div class="row-2 grid-bulletin">
                <RgComboboxSpecialization
                  id="specialization-qrb"
                  v-model="form.specialization"
                  :disabled="
                    sendNotIdentifiedToPep ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: sendNotIdentifiedToPep || disabledPatient,
                  }"
                  default-text="Não informado"
                />
                <RgComboboxArrivalType
                  id="arrival-type-qrb"
                  v-model="form.arrivalType"
                  :disabled="
                    sendNotIdentifiedToPep ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: sendNotIdentifiedToPep || disabledPatient,
                  }"
                  default-text="Não informado"
                />
                <RgComboboxOccurrenceType
                  id="occurrence-type-qrb"
                  v-model="form.occurrenceType"
                  :disabled="
                    sendNotIdentifiedToPep ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: sendNotIdentifiedToPep || disabledPatient,
                  }"
                  default-text="Não informado"
                />
              </div>

              <div class="row-3 grid-bulletin">
                <RgTextArea
                  id="observations-qrb"
                  v-model="form.observations"
                  :disabled="
                    sendNotIdentifiedToPep ||
                    disabledPatient ||
                    disabled ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: sendNotIdentifiedToPep || disabledPatient,
                  }"
                  :maxlength="255"
                  label="Observações"
                />
              </div>
            </div>
          </div>
        </FormBase>
      </RgValidatorForm>
    </form>

    <ModalPatientNoWayOutBulletin
      ref="modalPatientNoWayOutBulletin"
      :show="showModalPatientNoWayOutBulletin"
      :list-no-way-out-bulletin="noWayOutBulletins"
      @close="actionCloseModalNoWayOutBulletin"
      @save="actionSaveForm"
    />
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  RgValidatorForm,
  RgInput,
  RgInputDate,
  RgInputHour,
  RgTextArea,
  RgSuggestPatientInformation,
  RgSelectUnithealth,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgComboboxSpecialization from "$emergency/bulletin/component/combobox/rg-combobox-specialization/RgComboboxSpecialization";
import RgComboboxResponsibleType from "$emergency/bulletin/component/combobox/rg-combobox-responsible-type/RgComboboxResponsibleType";
import RgComboboxHealthPlan from "$emergency/bulletin/component/combobox/rg-combobox-health-plan/RgComboboxHealthPlan";
import RgComboboxArrivalType from "$emergency/bulletin/component/combobox/rg-combobox-arrival-type/RgComboboxArrivalType";
import RgComboboxOccurrenceType from "$emergency/bulletin/component/combobox/rg-combobox-occurrence-type/RgComboboxOccurrenceType";
import RgComboboxSectorPerUnitPep from "$emergency/bulletin/component/combobox/rg-combobox-sector-per-unit-pep/RgComboboxSectorPerUnitPep";
import RgComboboxPlacePerSectorPep from "$emergency/bulletin/component/combobox/rg-combobox-place-per-sector-pep/RgComboboxPlacePerSectorPep";
import { ModalPatientNoWayOutBulletin } from "$emergency/bulletin/component/";

export default {
  name: "QuickRegistrationBulletin",
  components: {
    FormBase,
    RgValidatorForm,
    RgInput,
    RgInputDate,
    RgInputHour,
    RgTextArea,
    RgSuggestPatientInformation,
    RgComboboxSpecialization,
    RgComboboxResponsibleType,
    RgComboboxHealthPlan,
    RgComboboxSectorPerUnitPep,
    RgComboboxPlacePerSectorPep,
    RgComboboxArrivalType,
    RgComboboxOccurrenceType,
    Tooltip,
    RgSelectUnithealth,
    ModalPatientNoWayOutBulletin,
    IconInfoHelper,
  },

  props: {
    edit: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      bulletinData: null,
      patient: null,
      patientInfo: {},
      UNIT_HEALTH: 0,
      form: {
        responsibleType: null,
        responsibleName: null,
        date: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm"),
        specialization: null,
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        place: null,
        healthPlan: null,
        name: null,
        arrivalType: null,
        occurrenceType: null,
        observations: null,
      },
      prePatient: {
        gender: null,
        age: null,
        name: null,
      },
      bolId: null,
      localName: null,
      patientNotIdentified: false,
      preRegistrationData: null,
      fatherSelected: "2",
      showModalPatientNoWayOutBulletin: false,
      noWayOutBulletins: [],
      disableResponsibleName: false,
      disablePatient: false,
      preRegistrationId: 0,
      disabledByModalComponent: false,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    disabledPatient() {
      if (this.patientNotIdentified) {
        this.$emit("disableSave", false);
        return false;
      }

      if (!this.patient) {
        this.$emit("disableSave", true);
        return true;
      }

      const valid = Boolean(
        this.patient &&
          this.patientInfo.pes_id &&
          this.patientInfo.patient.pac_id_motivo_inabilitacao,
      );
      const tmp = !!(valid || !this.patientInfo.pes_id);
      this.$emit("disableSave", tmp);
      return tmp;
    },

    isPatient() {
      return this.patientInfo && this.patientInfo.pac_id;
    },

    hasPatientSelected() {
      return (
        this.preRegistrationData && this.preRegistrationData.prc_id_pessoas
      );
    },

    disableEdit() {
      return this.edit === true;
    },

    sendNotIdentifiedToPep() {
      return !this.bulletinData?.pac_id && this.bulletinData?.enviado === "SIM";
    },

    isPreRegistrationRoute() {
      const ret = ValidateIfRouteExistInBreadscrumb(
        "/emergency/pre-registration",
        this.$route.meta.breadcrumb,
      );
      return ret;
    },

    operationId() {
      return this.edit ? [92] : [91, 411, 470];
    },

    modulePath() {
      return "Emergency/Bulletin";
    },

    actionSave() {
      return this.edit
        ? `${this.modulePath}/EDIT_BULLETIN`
        : `${this.modulePath}/REGISTER_BULLETIN`;
    },

    bulletinSelected() {
      return this.$store.getters[`${this.modulePath}/GET_SELECTED_BULLETIN`];
    },

    disablePatientNotIdentified() {
      if (this.disableEdit) {
        const withPatient =
          this.patientInfo && Object.keys(this.patientInfo).length > 0;

        const hasPatientId =
          this.bulletinData?.pac_id && this.bulletinData.pac_id > 0;

        return !!(withPatient && hasPatientId);
      }

      return false;
    },
  },

  watch: {
    "form.place"(pValue) {
      if (Array.isArray(pValue)) {
        this.localName = pValue[0].text;
      }
    },

    patient(pPatient, pPrevioulsy) {
      const isOtherPatient = pPrevioulsy && pPatient !== pPrevioulsy;
      const isToCleanResponsibleInfo = isOtherPatient || !pPatient;

      if (isToCleanResponsibleInfo) {
        this.form.responsibleType = null;
        this.form.responsibleName = null;
        this.disableResponsibleName = false;
      }

      if (!pPatient || pPatient.length < 1) {
        this.$emit("disableSave", false);
      }

      (this.patientNotIdentified && this.patientNotIdentified === true) ||
      (this.edit && this.bulletinSelected?.pes_id?.length > 0)
        ? (this.disablePatient = true)
        : (this.disablePatient = false);
    },

    patientNotIdentified(pValue) {
      if (pValue) {
        this.$refs.patientInfoSuggest.cleanPatient();
        this.$refs.patientInfoSuggest.cleanValidate();
        this.patient = null;
        this.patientInfo = null;
      }

      if (!pValue || pValue === false) {
        this.disablePatient = false;
      }
    },

    "form.responsibleType"(pResponsibleType, pPrevioulsy) {
      const isOtherResponsibleType =
        pPrevioulsy && pResponsibleType !== pPrevioulsy;

      const isToCleanResponsibleType =
        isOtherResponsibleType || !pResponsibleType;

      if (isToCleanResponsibleType) {
        this.form.responsibleName = "";
        this.disableResponsibleName = false;
      }
    },
  },

  destroyed() {
    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/emergency/bulletin/registration",
      this.$route.meta.breadcrumb,
    );

    const validateIfExistEdit = ValidateIfRouteExistInBreadscrumb(
      "/emergency/bulletin/edit-registration",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteFromSchedulePerDay && !validateIfExistEdit) {
      this.$store.commit(`${this.modulePath}/UNSELECT_BULLETIN`);
    }
  },

  created() {
    this.UNIT_HEALTH = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  async mounted() {
    const bulletin = this.$store.getters[
      `${this.modulePath}/GET_SELECTED_BULLETIN`
    ];

    if (this.isPreRegistrationRoute) {
      this.preRegistrationData = this.$store.getters[
        "Emergency/PreRegistration/GET_SELECT_ROW_PRE_REGISTER"
      ];

      if (this.preRegistrationData.anticipationReason) {
        this.form.observations = this.preRegistrationData.anticipationReason;
      }

      const personId = this.preRegistrationData?.prc_id_pessoas;

      const hasPatientInPreRegistration = personId && Number(personId) > 0;

      if (hasPatientInPreRegistration) {
        this.$refs.patientInfoSuggest.fillPatientById(Number(personId));
      }

      this.form.sector = this.preRegistrationData.set_id;
      this.form.specialization = this.preRegistrationData.esp_id;

      this.preRegistrationId = this.preRegistrationData.prc_id;
    }

    if (!this.isPreRegistrationRoute) {
      const filter = this.$store.getters[
        "Emergency/Bulletin/GET_FILTER_EMERGENCY_BULLETIN"
      ];
      this.form.sector = filter?.sector;
    }

    this.bulletinData = bulletin;

    if (this.edit && bulletin && !this.isPreRegistrationRoute) {
      await this.mountBulletin(bulletin);
    }

    if (this.lastPersonIdSaved) {
      await this.$refs.patientInfoSuggest.fillPatientById(
        this.lastPersonIdSaved,
      );
    }
  },

  methods: {
    disabledByModalComponents(disabledByModal) {
      this.disabledByModalComponent = disabledByModal;
      this.$emit("disabledByModalComponents", this.disabledByModalComponent);
      return this.disabledByModalComponent;
    },

    verifyRetroactiveDate(pValue, pErrors) {
      const datePreRegistration =
        this.preRegistrationData.data_inicio +
        " " +
        this.preRegistrationData.hora_inicio;

      const datePreRegistrationFormated = moment(
        datePreRegistration,
        "DD/MM/YYYY HH:mm",
      );

      const dateRegistration = pValue + " " + this.form.hour;

      const dateRegistrationFormated = moment(
        dateRegistration,
        "DD/MM/YYYY HH:mm",
      );

      const dateIsNotValid = datePreRegistrationFormated.isAfter(
        dateRegistrationFormated,
      );

      if (dateIsNotValid) {
        pErrors.push(
          "A data e hora da entrada não pode ser retroativa à data e hora do pré-atendimento: " +
            this.preRegistrationData.data_inicio +
            " " +
            this.preRegistrationData.hora_inicio +
            " ",
        );
        return false;
      }
    },

    verifyRetroactiveHour(pValue, pErrors) {
      const datePreRegistration =
        this.preRegistrationData.data_inicio +
        " " +
        this.preRegistrationData.hora_inicio;

      const datePreRegistrationFormated = moment(
        datePreRegistration,
        "DD/MM/YYYY HH:mm",
      );

      const dateRegistration = this.form.date + " " + pValue;

      const dateRegistrationFormated = moment(
        dateRegistration,
        "DD/MM/YYYY HH:mm",
      );

      const dateIsNotValid = datePreRegistrationFormated.isAfter(
        dateRegistrationFormated,
      );

      if (dateIsNotValid) {
        pErrors.push(
          "A data e hora da entrada não pode ser retroativa à data e hora do pré-atendimento: " +
            this.preRegistrationData.data_inicio +
            " " +
            this.preRegistrationData.hora_inicio +
            " ",
        );
        return false;
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    formatData() {
      let variables = {};
      let patientEmergencyId = null;
      if (!this.patientNotIdentified) {
        patientEmergencyId = Number(
          this.patientInfo.patient.patient_emergency.pce_id,
        );
      }

      variables = {
        bol_acidente: 0,
        bol_atendido: 1,
        bol_id_emergencias_leitos_tipos_leito_log: 0,
        bol_id_especializacoes: this.form.specialization,
        bol_id_pacientes_emergencia: patientEmergencyId,
        bol_id_locais_atendimento: this.form.place,
        bol_id_planos_saude: this.form.healthPlan,
        bol_id_setores: this.form.sector,
        bol_id_tipos_chegada: this.form.arrivalType,
        bol_id_tipos_ocorrencia: this.form.occurrenceType,
        bol_id_tipos_responsaveis: this.form.responsibleType,
        bol_observacao: this.form.observations,
        bol_pre_cadastro_pep: null,
        bol_responsavel: this.form.responsibleName,
        bol_data: this.form.date || null,
        bol_hora: this.form.hour || null,
        prc_id: this.preRegistrationId,
        prc_id_especializacoes: 0,
        prc_id_setor: 0,
        prc_nome: "",
      };

      if (this.bolId) {
        variables = { ...variables, bol_id: Number(this.bolId) };
      }

      return variables;
    },

    async saveForm() {
      try {
        this.$loader.start();

        const variables = this.formatData();

        const respSave = await this.$store.dispatch(this.actionSave, {
          ...variables,
        });

        const bolId = respSave.idBoletim ? respSave.idBoletim : this.bolId;

        if (respSave.idBoletim || this.sendNotIdentifiedToPep) {
          const path = respSave.idBoletim
            ? "Emergency/Bulletin/SEND_BULLETIN_PEP"
            : "Emergency/Bulletin/IDENTIFY_PATIENT_PEP";
          const localName = this.localName || "";

          this.$store
            .dispatch(path, bolId)
            .then((data) => {
              this.$toaster.success("Boletim enviado para o PEP");
            })
            .catch((err) => {
              const hasIndigent = this.patientNotIdentified;

              const emptyValue = /É preciso atualizar os seguintes dados do Paciente/.test(
                err.message,
              );
              if (emptyValue && hasIndigent) {
                const indexMessage = err.message.indexOf("<br><br>É");

                err.message = err.message.slice(0, indexMessage);
              }

              const fillLocal = /Local de atendimento não mapeado:/.test(
                err.message,
              );

              if (fillLocal) {
                const local = `${
                  hasIndigent ? "<br><br>" : ""
                }${localName} <br>`;
                err.message = err.message + local;
              }

              this.$toaster.error(
                err.message,
                "Falha ao enviar boletim para o PEP",
              );
            });
        }

        this.$toaster.success("Salvo com sucesso");
        this.cleanForm();
        return respSave;
      } catch (pErr) {
        const error = pErr.toString();

        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao salvar boletim",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao salvar cadastro de saída");
        }
        return false;
      } finally {
        this.$loader.finish(1000);
      }
    },

    async mountBulletin(pBulletin) {
      try {
        this.$loader.start("Carregando boletim do paciente...");
        const bulletinId = Number(pBulletin.bol_id);

        const bulletin = await this.$store.dispatch(
          `${this.modulePath}/SEARCH_REGISTER_BULLETIN`,
          bulletinId,
        );

        if (bulletin) {
          if (
            !bulletin?.bol_id_pacientes_emergencia &&
            !this.lastPersonIdSaved
          ) {
            this.patientNotIdentified = true;
          } else {
            this.$refs.patientInfoSuggest.fillPatientById(
              Number(pBulletin.pes_id),
            );
          }

          this.bolId = bulletin?.bol_id;
          this.form.date = bulletin?.bol_data;
          this.form.hour = bulletin?.bol_hora;
          this.form.specialization = Number(bulletin?.bol_id_especializacoes);
          this.form.sector = Number(bulletin?.bol_id_setores);
          this.form.place = Number(bulletin?.bol_id_locais_atendimento);
          this.form.healthPlan = bulletin?.bol_id_planos_saude;
          this.form.arrivalType = bulletin?.bol_id_tipos_chegada;
          this.form.occurrenceType = bulletin?.bol_id_tipos_ocorrencia;
          this.form.observations = bulletin?.bol_observacao;

          this.$nextTick(() => {
            this.form.responsibleType = bulletin?.bol_id_tipos_responsaveis;
            this.form.responsibleName = bulletin?.bol_responsavel;
          });
        }
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish(1000);
      }
    },

    async getPatientData(pValue) {
      if (pValue && pValue.pes_id) {
        this.patientInfo = pValue;

        this.patientInfo.pac_id = pValue.patient.pac_id;
        this.patientInfo.pce_id = pValue.patient.patient_emergency.pce_id;

        this.patientNotIdentified = false;

        if (this.sendNotIdentifiedToPep) {
          this.$emit("disableSave", false);
        }
      } else {
        if (this.sendNotIdentifiedToPep) {
          this.$emit("disableSave", true);
        } else {
          this.$emit("disableSave", !this.patientNotIdentified);
        }
        this.patientInfo = {};
      }
    },

    cleanForm() {
      this.patient = null;
      this.$refs.patientInfoSuggest.cleanValidate();
      this.$refs.patientInfoSuggest.cleanPatient();
      this.disableResponsibleName = false;
      this.$refs.sector.cleanValidate();
      this.$refs.place.cleanValidate();
      this.$refs.healthPlan.cleanValidate();

      this.form = {
        responsibleType: null,
        responsibleName: null,
        date: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm"),
        specialization: null,
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        place: null,
        healthPlan: null,
        name: null,
        arrivalType: null,
        occurrenceType: null,
        observations: null,
      };
      this.localName = null;
    },

    async selectedResponsibleType(pValue) {
      const { value } = pValue;
      const patientSelected = !!this.patientInfo.pes_id;
      const isFatherOrMother = parseInt(value) < 3;

      if (!patientSelected || !isFatherOrMother) {
        this.disableResponsibleName = false;
        return;
      }

      const method =
        value === this.fatherSelected ? "GET_FATHER" : "GET_MOTHER";

      const name = await this.$store.dispatch(
        `${this.modulePath}/${method}`,
        this.patientInfo.pes_id,
      );

      const hasName = name?.length > 0;

      this.disableResponsibleName = hasName;

      this.form.responsibleName = name;
    },

    selectingSector(pValue) {
      this.form.place = null;
    },

    actionCloseModalNoWayOutBulletin() {
      this.showModalPatientNoWayOutBulletin = false;
    },

    async verifyPatientNoWayOutBulletin() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return;
        }

        if (this.edit || this.patientNotIdentified) {
          this.actionSaveForm();
          return;
        }

        const noWayOutBulletin = await this.getPatientNoWayOuBulletin();

        if (noWayOutBulletin.length > 0) {
          this.noWayOutBulletins = noWayOutBulletin;
          this.showModalPatientNoWayOutBulletin = true;
        } else {
          this.actionSaveForm();
        }
      } catch (pError) {
        console.error(pError);
      }
    },

    async getPatientNoWayOuBulletin() {
      const { pce_id } = this.patientInfo?.patient?.patient_emergency;

      return await this.$store.dispatch(
        "Emergency/Bulletin/GET_PATIENT_NO_WAY_OUT_BULLETIN",
        { intIdPacienteEmergencia: pce_id },
      );
    },

    actionSaveForm() {
      this.$emit("actionSaveForm");
    },

    blockedPatient(pValue) {
      this.$emit("disableSave", pValue);
    },
  },
};
</script>
