<template slot="subFilters">
  <div class="pharmacy-central-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          ref="productType"
          v-model="form.mtp_id"
          class="inputitem"
          @change="selectedType"
        />
        <RgSelectPharmacy
          v-model="form.far_id_origem"
          :unitHealthId="Number(globalFilters.unitHealth)"
          default-text="Selecione"
          byUser
          :userId="userId"
          class="input"
          label="Farmácia Origem"
          :typePharmacy="CENTRAL_PHARMACY_TYPE"
          :disabled="!globalFilters.unitHealth"
          :class="{ disable: !globalFilters.unitHealth }"
          @change="selectedOriginPharmacy"
        />

        <RgSelectPharmacy
          v-model="form.far_id_destino"
          :unitHealthId="Number(globalFilters.unitHealth)"
          default-text="Selecione"
          label="Farmácia Destino"
          openOnlyTop
          :typePharmacy="CENTRAL_PHARMACY_TYPE"
          class="input"
          :disabled="!globalFilters.unitHealth"
          :class="{ disable: !globalFilters.unitHealth }"
          @change="selectedDestinyPharmacy"
        />

        <RgSelectLotExpiration
          id="batch"
          ref="batch"
          v-model="form.trs_lote"
          :disabled="!globalFilters.product || !form.far_id_origem"
          :class="{ disable: !globalFilters.product || !form.far_id_origem }"
          :pharmacyId="Number(form.far_id_origem)"
          :productId="Number(globalFilters.product)"
          :isEntry="true"
          :blnSomenteDisponiveis="false"
          openOnlyTop
          @change="selectedLotExpiration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration.vue";
import { mapGetters } from "vuex";

const FORM_FILTER = {
  mtp_id: null,
  mtp_nome: null,
  far_nome_origem: null,
  far_nome_destino: null,
  trs_lote: null,
  far_id_origem: null,
  far_id_destino: null,
  fel_validade_lote: null,
};

export default {
  name: "PharmacyCentralTransferReport",
  components: {
    RgSelectPharmacy,
    RgSelectProductType,
    RgSelectLotExpiration,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      pharmacyId: null,
      productId: null,
      productTypeId: null,
      lotAndExpiration: null,
    };
  },
  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
    }),
    getNameSmartTable() {
      return "PharmacyCentralTransferReport";
    },
    columnTable() {
      return this.COLUMN;
    },
  },
  watch: {
    globalFilters: {
      handler(pValue) {
        this.pharmacyId = pValue.pharmacy;
        this.productId = pValue.product;
        if (!pValue.pharmacy) {
          this.clearSubFilter("pharmacy");
        }
        if (!pValue.product) {
          this.clearSubFilter("product");
        }
      },
      deep: true,
    },
    "globalFilters.unitHealth"(pValue) {
      if (!pValue && pValue === null) {
        this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      }
    },
    "globalFilters.product"(pValue) {
      if (!pValue && pValue === null) {
        this.form.trs_lote = null;
      }
    },
    "form.far_id_origem"(pValue) {
      if (!pValue && pValue === null) {
        this.form.trs_lote = null;
      }
    },
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
  },
  created() {
    this.CENTRAL_PHARMACY_TYPE = 1;
  },
  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Data", key: "DATA", align: "right" },
      { name: "Farmácia Origem", key: "FARMACIA_ORIGEM", align: "left" },
      { name: "Farmácia Destino", key: "FARMACIA_DESTINO", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Valor Total", key: "VALOR_TOTAL", align: "right" },
      { name: "Valor Unitário", key: "VALOR_UNITARIO", align: "right" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
    ]);
  },

  methods: {
    selectedType(pValue) {
      if (pValue) {
        this.form.mtp_nome = pValue.mtp_nome;
      } else {
        this.form.mtp_nome = null;
      }
    },
    selectedOriginPharmacy(pValue) {
      if (pValue) {
        this.form.far_nome_origem = pValue.label;
      } else {
        this.form.far_nome_origem = null;
      }
    },
    selectedDestinyPharmacy(pValue) {
      if (pValue) {
        this.form.far_nome_destino = pValue.label;
      } else {
        this.form.far_nome_destino = null;
      }
    },
    selectedLotExpiration(pValue) {
      if (pValue) {
        this.form.fel_validade_lote = pValue.label;
      } else {
        this.form.fel_validade_lote = null;
      }
    },
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          ...this.form,
          mtp_id: Number(this.form.mtp_id),
          far_id_origem: Number(this.form.far_id_origem),
          far_id_destino: Number(this.form.far_id_destino),
          loggedUserId: this.userId,
        };
        const globalFilters = {
          ...this.globalFilters,
          pharmacy: Number(this.globalFilters.pharmacy),
          product: Number(this.globalFilters.product),
        };
        const filters = {
          ...globalFilters,
          ...variables,
          ...pValue,
        };
        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CENTRAL_TRANSFER",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.document = null;
      this.disabledDocument = false;
    },
    clearSubFilter(option) {
      switch (option) {
        case "pharmacy":
        case "product":
          this.form.trs_lote = null;
          this.trs_lote = null;
          break;
        default:
          break;
      }
    },
  },
};
</script>
