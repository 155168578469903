import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($ins_id: Int!) {
    DeleteHospitalizationSolicitation(ins_id: $ins_id) {
      ins_id
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.DeleteHospitalizationSolicitation;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
