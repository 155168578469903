<template slot="subFilters">
  <div class="pharmacy-satellite-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectDestinyConsumption
          id="set-id-aor"
          ref="destinationSector"
          v-model="form.dco_id"
          default-text="Todos"
          empty-default-text="Todos"
          class="destination"
          label="Setor de Destino"
          @change="selectDco"
        />
        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import RgSelectDestinyConsumption from "$pharmacy/submodules/satellite/components/select/rg-select-destiny-consumption/RgSelectDestinyConsumption";

import moment from "moment";

const FORM_FILTER = {
  patientId: null,
  patientName: null,
  initialDate: moment().add(-90, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
};

export default {
  name: "PharmacySatelliteConsumptionReport",
  components: {
    RgSelectDestinyConsumption,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      resultTitle: "Medicamentos Dispensados para Pacientes",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      document: null,
      disabledDocument: false,
      dcoName: null,
    };
  },

  computed: {
    getNameSmartTable() {
      return "PharmacySatelliteConsumptionReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    hasPatienId() {
      return this.form.patientId && this.form.patientId !== 0;
    },
    getMinDateInitial() {
      const date = this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
      return date;
    },
    getMaxDateInitial() {
      const date = this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
      return date;
    },
  },
  watch: {
    "form.patientName"(pValue) {
      pValue && pValue.length > 0
        ? (this.disabledDocument = true)
        : (this.disabledDocument = false);
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Data", key: "DATA", align: "right" },
      { name: "Destino", key: "DESTINO_CONSUMO", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Farmácia", key: "FARMACIA", align: "left" },
      { name: "Usuário", key: "USUARIO", align: "left" },
      { name: "Observação", key: "OBSERVACAO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
    ]);
  },

  methods: {
    buildFilter() {
      const payLoad = {
        patientId: this.form.patientId,
        patientName: this.form.patientName,
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        columnsToPrint: this.globalFilters.columnsToPrint,
      };
      return payLoad;
    },

    async doSearchSubReport(pValue) {
      try {
        const filters = {
          ...this.globalFilters,
          ...this.form,
          ...pValue,
        };
        filters.dco_id = this.form.dco_id ? Number(this.form.dco_id) : null;
        filters.limiteFim = pValue.limit ? pValue.limit : null;
        filters.limiteInicio = pValue.offset !== null ? pValue.offset : null;
        filters.limiteFim =
          filters.limiteInicio > 0
            ? filters.limiteInicio + filters.limiteFim
            : filters.limiteFim;
        filters.uns_id = this.globalFilters.unitHealth
          ? Number(this.globalFilters.unitHealth)
          : null;
        filters.far_id = this.globalFilters.pharmacy
          ? Number(this.globalFilters.pharmacy)
          : null;
        filters.mpd_id = this.globalFilters.product
          ? Number(this.globalFilters.product)
          : null;
        filters.periodoFinal = this.globalFilters.finalDate;
        filters.periodoInicial = this.globalFilters.initialDate;
        filters.dcoName = this.dcoName ? this.dcoName : null;

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_SATELLITE_CONSUMPTION",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final.");
        return false;
      }
    },

    selectDco(pValue) {
      this.dcoName = pValue.label;
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial.");
        return false;
      }
    },

    selectingPatient(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.patientId = pValue.source.pes_id;
      } else {
        this.form.patientId = null;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.document = null;
      this.disabledDocument = false;

      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
