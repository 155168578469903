<template>
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="38"
      cy="38"
      r="36.5"
      fill="#53B1D8"
      stroke="#53B1D8"
      stroke-width="3"
    />
    <path
      d="M53.3438 29.6211C53.7752 29.6211 54.125 29.2713 54.125 28.8398V20.3438C54.125 19.0514 53.0736 18 51.7812 18H23.3438C22.0514 18 21 19.0514 21 20.3438V55.6562C21 56.9486 22.0514 58 23.3438 58H51.7812C53.0736 58 54.125 56.9486 54.125 55.6562V49.9006C54.125 49.4691 53.7752 49.1194 53.3438 49.1194C52.9123 49.1194 52.5625 49.4691 52.5625 49.9006V55.6562C52.5625 56.087 52.212 56.4375 51.7812 56.4375H23.3438C22.913 56.4375 22.5625 56.087 22.5625 55.6562V20.3438C22.5625 19.913 22.913 19.5625 23.3438 19.5625H51.7812C52.212 19.5625 52.5625 19.913 52.5625 20.3438V28.8398C52.5625 29.2713 52.9123 29.6211 53.3438 29.6211Z"
      fill="white"
    />
    <path
      d="M37.562 22.2188C33.5988 22.2188 30.3745 25.443 30.3745 29.4062C30.3745 31.4623 31.2425 33.3191 32.6309 34.6304C32.6569 34.6582 32.6846 34.6845 32.7148 34.7085C33.9938 35.8787 35.6959 36.5937 37.5619 36.5937C39.4279 36.5937 41.1301 35.8787 42.409 34.7085C42.4393 34.6845 42.4669 34.6581 42.4929 34.6304C43.8815 33.3191 44.7495 31.4623 44.7495 29.4062C44.7495 25.443 41.5252 22.2188 37.562 22.2188ZM37.562 35.0312C36.2863 35.0312 35.109 34.6038 34.1643 33.8853C34.8509 32.6677 36.1322 31.9062 37.562 31.9062C38.9918 31.9062 40.2731 32.6677 40.9597 33.8853C40.015 34.6038 38.8377 35.0312 37.562 35.0312ZM36.312 29.0938V28.5859C36.312 27.8967 36.8728 27.3359 37.562 27.3359C38.2512 27.3359 38.812 27.8967 38.812 28.5859V29.0938C38.812 29.783 38.2512 30.3438 37.562 30.3438C36.8728 30.3438 36.312 29.783 36.312 29.0938ZM42.0886 32.7409C41.5124 31.8863 40.7139 31.2217 39.7868 30.8114C40.1547 30.3359 40.3745 29.7402 40.3745 29.0938V28.5859C40.3745 27.0351 39.1129 25.7734 37.562 25.7734C36.0112 25.7734 34.7495 27.0351 34.7495 28.5859V29.0938C34.7495 29.7402 34.9693 30.3359 35.3372 30.8114C34.4101 31.2217 33.6116 31.8863 33.0354 32.7409C32.3457 31.807 31.937 30.6537 31.937 29.4062C31.937 26.3046 34.4604 23.7812 37.562 23.7812C40.6637 23.7812 43.187 26.3046 43.187 29.4062C43.187 30.6537 42.7783 31.807 42.0886 32.7409Z"
      fill="white"
    />
    <path
      d="M41.7808 52.2188H37.562C37.1306 52.2188 36.7808 52.5685 36.7808 53C36.7808 53.4315 37.1306 53.7812 37.562 53.7812H41.7808C42.2122 53.7812 42.562 53.4315 42.562 53C42.562 52.5685 42.2122 52.2188 41.7808 52.2188Z"
      fill="white"
    />
    <path
      d="M41.7812 47.8438H30.6875C30.2561 47.8438 29.9062 48.1935 29.9062 48.625C29.9062 49.0565 30.2561 49.4062 30.6875 49.4062H41.7812C42.2127 49.4062 42.5625 49.0565 42.5625 48.625C42.5625 48.1935 42.2127 47.8438 41.7812 47.8438Z"
      fill="white"
    />
    <path
      d="M41.7812 43.4688H30.6875C30.2561 43.4688 29.9062 43.8185 29.9062 44.25C29.9062 44.6815 30.2561 45.0312 30.6875 45.0312H41.7812C42.2127 45.0312 42.5625 44.6815 42.5625 44.25C42.5625 43.8185 42.2127 43.4688 41.7812 43.4688Z"
      fill="white"
    />
    <path
      d="M27.9027 39.3227C27.7574 39.1773 27.5559 39.0938 27.3496 39.0938C27.1441 39.0938 26.9425 39.1773 26.7973 39.3227C26.652 39.468 26.5684 39.6695 26.5684 39.875C26.5684 40.0805 26.6519 40.282 26.7973 40.4273C26.9434 40.5727 27.1441 40.6562 27.3496 40.6562C27.5559 40.6562 27.7566 40.5727 27.9027 40.4273C28.048 40.282 28.1316 40.0805 28.1316 39.875C28.1316 39.6695 28.048 39.468 27.9027 39.3227Z"
      fill="white"
    />
    <path
      d="M27.9027 43.6977C27.7566 43.5523 27.5559 43.4688 27.3496 43.4688C27.1441 43.4688 26.9434 43.5523 26.7973 43.6977C26.652 43.843 26.5684 44.0445 26.5684 44.25C26.5684 44.4555 26.6519 44.657 26.7973 44.8023C26.9434 44.9477 27.1441 45.0312 27.3496 45.0312C27.5559 45.0312 27.7566 44.9477 27.9027 44.8023C28.048 44.657 28.1316 44.4555 28.1316 44.25C28.1316 44.0445 28.048 43.843 27.9027 43.6977Z"
      fill="white"
    />
    <path
      d="M27.9027 48.0727C27.7566 47.9273 27.5559 47.8438 27.3496 47.8438C27.1441 47.8438 26.9434 47.9273 26.7973 48.0727C26.652 48.218 26.5684 48.4195 26.5684 48.625C26.5684 48.8305 26.6519 49.032 26.7973 49.1773C26.9425 49.3227 27.1441 49.4062 27.3496 49.4062C27.5559 49.4062 27.7574 49.3227 27.9027 49.1773C28.048 49.032 28.1316 48.8305 28.1316 48.625C28.1316 48.4195 28.048 48.218 27.9027 48.0727Z"
      fill="white"
    />
    <path
      d="M41.7812 39.0938H30.6875C30.2561 39.0938 29.9062 39.4435 29.9062 39.875C29.9062 40.3065 30.2561 40.6562 30.6875 40.6562H41.7812C42.2127 40.6562 42.5625 40.3065 42.5625 39.875C42.5625 39.4435 42.2127 39.0938 41.7812 39.0938Z"
      fill="white"
    />
    <path
      d="M59.3579 28.1812C58.5989 27.743 57.7147 27.6266 56.868 27.8534C56.0214 28.0803 55.3138 28.6233 54.8757 29.3823L45.0443 46.4106C44.9904 46.504 44.9564 46.6075 44.9444 46.7146L44.2524 52.9133C44.218 53.2218 44.3694 53.5214 44.6382 53.6766C44.7595 53.7466 44.8943 53.7813 45.0287 53.7813C45.1922 53.7813 45.355 53.7301 45.4921 53.6291L50.5143 49.9304C50.6011 49.8665 50.6737 49.7853 50.7275 49.6919L60.5589 32.6636C61.4636 31.0967 60.9248 29.0859 59.3579 28.1812ZM46.0033 51.312L46.3647 48.0751L48.6258 49.3806L46.0033 51.312ZM49.765 48.234L46.7881 46.5153L54.6775 32.8505L57.6545 34.5692L49.765 48.234ZM59.2057 31.8823L58.4357 33.2161L55.4587 31.4973L56.2288 30.1635C56.4583 29.7659 56.8289 29.4815 57.2724 29.3627C57.716 29.2438 58.1791 29.3049 58.5766 29.5344C58.9742 29.764 59.2586 30.1346 59.3774 30.578C59.4962 31.0215 59.4353 31.4847 59.2057 31.8823Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "RegisterPatient",
};
</script>
