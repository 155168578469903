<template lang="html">
  <div v-if="show" class="modal-deleting-link">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 v-if="!sectorInactivateLink" class="title">Excluir o {{ type }}</h1>
        <h1 v-else class="title">Ativar o {{ type }}</h1>
      </div>

      <div slot="body" class="body">
        <span v-if="!sectorInactivateLink" class="text">
          O {{ type }} selecionado possui Unidades de Saúde vinculadas.<br />
          Só é possível excluir os {{ titlePlural }} que não possuem vínculo.
        </span>
        <span v-else class="text">
          O {{ type }} selecionado possui vinculo com um Setor Inativo.<br />
          Desejar ativar o Setor?
        </span>
        <span v-if="!sectorInactivateLink" class="content">
          {{ inactivationInfo || "Não informado" }}
        </span>
        <span v-else class="content">
          {{ inactivationInfo || "Não informado" }} -
          {{ sectorInfo || "Não informado" }}
        </span>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel-btn" medium @click="cancel" />
        <MediumButton
          id="save-btn"
          title="Inativar"
          :label="sectorInactivateLink ? 'Ativar' : 'Inativar'"
          @click="save"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, MediumButton } from "~tokio/primitive";

export default {
  name: "ModalDeletingLink",

  components: {
    RgBaseModal,
    RgCancelButton,
    MediumButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    inactivationInfo: {
      type: String,
      required: true,
    },
    sectorInactivateLink: {
      type: Boolean,
      default: false,
    },
    sectorInfo: {
      type: String,
      required: true,
    },
  },

  computed: {
    titlePlural() {
      return this.type === "Setor" ? "Setores" : "Locais";
    },
  },

  created() {},

  methods: {
    save() {
      if (this.sectorInactivateLink) {
        this.$emit("activate");
      } else {
        this.$emit("save", this.type);
      }
    },

    cancel() {
      this.$emit("close");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
