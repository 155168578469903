import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query CriticizeConsolidatedBilling(
    $procedureCode: String
    $cboCode: String
    $competence: String
    $fco_id_unidades_saude: Int
    $sheet: String
    $amount: String
    $age: String
    $limit: Int
    $offset: Int
    $typeFilter: String
  ) {
    CriticizeConsolidatedBilling(
      fco_codigo_procedimento: $procedureCode
      fco_id_unidades_saude: $fco_id_unidades_saude
      fco_cbo: $cboCode
      fco_competencia: $competence
      fco_folha: $sheet
      fco_quantidade: $amount
      fco_idade: $age
      limit: $limit
      offset: $offset
      typeFilter: $typeFilter
    ) {
      rows {
        fco_id
        fco_competencia
        fco_folha
        fco_codigo_procedimento
        fco_cbo
        fco_quantidade
        fco_idade
        fco_folha
        procedure {
          CO_PROCEDIMENTO
          NO_PROCEDIMENTO
        }
        occupation {
          CO_OCUPACAO
          NO_OCUPACAO
        }
        CO_PROCEDIMENTO
        VALID_QT_MAXIMA_EXECUCAO
        VALID_VL_IDADE_MAXIMA
        VALID_VL_IDADE_MINIMA
        VALID_CO_OCUPACAO
        VALID_ATRIBUTOS_IDADE
        VALID_ATRIBUTOS_CBO
      }
      count
    }
  }
`;

export default async ({ commit, rootGetters }, variables) => {
  variables = variables || {};
  variables.competence = variables.competence
    ? variables.competence
    : rootGetters["Billing/BDSia/GET_PERIOD_DATE"];
  variables.fco_id_unidades_saude = variables.fco_id_unidades_saude
    ? variables.fco_id_unidades_saude
    : rootGetters["Login/GET_UNIT_HEALTH_ID"];

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const list = data.CriticizeConsolidatedBilling
      ? data?.CriticizeConsolidatedBilling.rows
      : [];

    let result = [];
    if (list && list.length > 0) {
      list.forEach((item, index) => {
        item.validProcedure = {
          CO_OCUPACAO: item.VALID_CO_OCUPACAO,
          QT_MAXIMA_EXECUCAO: item.VALID_QT_MAXIMA_EXECUCAO,
          VL_IDADE_MAXIMA: item.VALID_VL_IDADE_MAXIMA,
          VL_IDADE_MINIMA: item.VALID_VL_IDADE_MINIMA,
          ATRIBUTOS: {
            IDADE: item.VALID_ATRIBUTOS_IDADE,
            CBO: item.VALID_ATRIBUTOS_CBO,
          },
        };

        item.procedureName = item.procedure?.NO_PROCEDIMENTO;
        item.occupationName = item.occupation?.NO_OCUPACAO;

        item.sequence = (index + 1).toString();

        const aux = item.validProcedure;
        if (
          aux &&
          // COMENTADO DE ACORDO COM SOLICITAÇÃO DO CASO #7602
          // aux.QT_MAXIMA_EXECUCAO !== "0" &&
          aux.VL_IDADE_MAXIMA !== "0" &&
          aux.VL_IDADE_MINIMA !== "0" &&
          aux.CO_OCUPACAO !== "0" &&
          aux.ATRIBUTOS.IDADE !== "0" &&
          aux.ATRIBUTOS.CBO !== "0"
        ) {
          item.hasError = false;
        } else {
          item.hasError = true;
        }
      });

      result = list;
    }

    return {
      rows: result,
      total: data.CriticizeConsolidatedBilling.count,
    };
  } catch (e) {
    return Promise.reject(e.graphQLErrors);
  }
};
