<template>
  <div v-if="show" class="modal-patient-records-found-basic-attention">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div
        slot="header"
        class="modal-patient-records-found-basic-attention-header"
      >
        <div class="title">
          Fichas encontradas no cadastro de Atenção Básica
        </div>
      </div>
      <div slot="body" class="body">
        <div class="content">
          <span class="subtitle-modal">
            Identifique uma ficha AB que contém os dados do paciente.
            Vincule/selecione a mais adequada ao cadastro na relação abaixo:
          </span>

          <div v-if="byScheduling" class="information">
            <Tooltip
              message="Para salvar o agendamento, é obrigatório vincular o paciente à uma
              Ficha AB. Se necessário, crie uma nova ficha através do botão ao lado."
              start-open
              class="tooltip"
            >
              <IconExclamationHelper slot="icon" hollow />
            </Tooltip>

            <LargeButton
              id="new-record"
              :label="'Criar Nova Ficha'"
              title="Criar Nova Ficha"
              :backgroundColor="'#0BB98C'"
              class="new-record-button"
              @click="openModalRegisterNewRecord"
            >
              <div slot="icon" class="icon">
                <IconAdd />
              </div>
            </LargeButton>
          </div>

          <div
            v-for="(item, index) in individualRegistrationList"
            :key="item.index"
            :class="{ selected: selectedPatient === index }"
            class="registers-list"
            @click="getPatient(item, index)"
          >
            <div class="register">
              <div>
                <IconCheck v-show="selectedPatient === index" class="check" />
              </div>

              <PatientType :sex="patientSex(item)" class="patient-type" />

              <div class="column">
                <span class="ellipsis" :title="item.pab_nome">
                  Nome: {{ item.pab_nome }}
                </span>
                <span class="ellipsis" :title="item.pab_nome_mae">
                  Nome da Mãe: {{ item.pab_nome_mae }}
                </span>
              </div>

              <div class="column">
                <span>
                  Data de Nascimento: {{ formatDate(item.pab_nascimento) }}
                </span>
                <span> CPF: {{ formatCpf(item.pab_cpf) }} </span>
              </div>
              <div class="column">
                <section class="tooltip">
                  <Tooltip show-left :show-up="toShowUp(index)">
                    <IconInfoHelper slot="icon" />
                    <div slot="content" class="content">
                      <div class="row">
                        <span class="header-tooltip">
                          <strong>Cabeçalho da Ficha de Cadastro - AB:</strong>
                        </span>
                      </div>
                      <div class="row">
                        <span>
                          <strong>Data do Atendimento: </strong
                          >{{ formatDate(item.pab_data_atendimento) }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.unidade_nome" class="ellipsis">
                          <strong> Unidade: </strong>{{ item.unidade_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.area_nome" class="ellipsis">
                          <strong> Área: </strong>{{ item.area_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.microarea_nome" class="ellipsis">
                          <strong> Microárea: </strong>{{ item.microarea_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.equipe_nome" class="ellipsis">
                          <strong> Equipe: </strong>{{ item.equipe_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span
                          :title="item.pessoa_funcionario_nome"
                          class="ellipsis"
                        >
                          <strong> Responsável: </strong
                          >{{ item.pessoa_funcionario_nome }}
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                </section>
                <span class="cns">
                  CNS: {{ formatCns(item.pab_cartao_sus) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="patientHasNoBondOrInactiveBond" slot="footer" class="footer">
        <MediumButton
          :label="byScheduling ? 'Cancelar' : 'Não Vincular'"
          :title="byScheduling ? 'Cancelar' : 'Não Vincular'"
          :background-color="'#FF7F2A'"
          @click="close"
        />
        <MediumButton
          label="Vincular"
          title="Vincular"
          :disabled="!selectedPatientData"
          @click="save"
        />
      </div>

      <div v-else slot="footer" class="footer">
        <MediumButton
          label="Manter Vínculo"
          title="Manter Vínculo"
          :background-color="'#FF7F2A'"
          @click="close"
        />
        <MediumButton
          :disabled="!selectedPatientData"
          label="Atualizar Vínculo"
          title="Atualizar Vínculo"
          @click="save"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  IconCheck,
  Tooltip,
  IconInfoHelper,
  IconExclamationHelper,
  IconAdd,
  MediumButton,
  LargeButton,
} from "~tokio/primitive";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";
import PatientType from "$person/patient/component/icon/PatientType";
import { ToShowUp } from "~tokio/primitive/tooltip/util/ToShowUp.js";

export default {
  name: "ModalPatientRecordsFoundBasicAttention",

  components: {
    RgBaseModal,
    PatientType,
    IconCheck,
    Tooltip,
    IconInfoHelper,
    IconExclamationHelper,
    IconAdd,
    MediumButton,
    LargeButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
    },
    patientHasNoBondOrInactiveBond: {
      type: Boolean,
      default: false,
    },
    patientBasicAttentionId: {
      type: Number,
    },
    individualRegistrationList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    byScheduling: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedPatient: null,
      selectedPatientData: null,
    };
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.clear();
      }
    },
  },

  methods: {
    getPatient(pItem, pIndex) {
      const isSameLine = this.selectedPatient === pIndex;

      if (isSameLine) {
        this.selectedPatient = null;
        this.selectedPatientData = null;
      } else {
        this.selectedPatient = pIndex;
        this.selectedPatientData = pItem;
      }
    },

    formatDate(pValue) {
      if (!pValue) return "";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },

    formatCpf(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },

    formatCns(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
    },

    patientSex(pItem) {
      const gender = pItem.sexo;
      const masculine = gender === "MASCULINO" ? 1 : null;
      const feminine = gender === "FEMININO" ? 2 : null;
      return masculine || feminine || null;
    },

    async save() {
      const pVerifyOriginalRegistrationId =
        !this.patientBasicAttentionId || this.patientHasNoBondOrInactiveBond;

      const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
        this.selectedPatientData,
        this.patientBasicAttentionId,
        this.patientId,
        pVerifyOriginalRegistrationId,
      );

      const action = this.patientBasicAttentionId
        ? "UPDATE_AB_ESUS"
        : "CREATE_AB_ESUS";

      try {
        this.$loader.start();

        await this.$store.dispatch(`Person/Patient/${action}`, {
          abEsus: patientAbEsus,
        });
        this.$toaster.success("Operação realizada com sucesso");
        this.$emit("save");
      } catch (err) {
        this.$toaster.error("Erro ao vincular", err);
      } finally {
        this.$loader.finish();
      }
    },

    openModalRegisterNewRecord() {
      this.$emit("newRecord");
    },

    toShowUp(pItemIndex) {
      return this.individualRegistrationList?.length === 1
        ? true
        : ToShowUp(pItemIndex, this.individualRegistrationList);
    },

    close() {
      this.$emit("close");
    },

    clear() {
      this.selectedPatient = null;
      this.selectedPatientData = null;
    },
  },
};
</script>
