<template>
  <Modulebox
    title="Registrar Alta Administrativa"
    class="emergency-administrative-checkout-out-register"
  >
    <div class="emergency-administrative-checkout-out-register-body">
      <FormBase title="Pacientes Selecionados">
        <div class="table">
          <SmartTable
            ref="smartTable"
            name="EmergencyAdministrativeCheckOutRegisterTable"
            :columns="COLUMNS"
            :body="mutableAdministrativeCheckOut"
            :total="total"
            :initial-columns="6"
            :showPagination="false"
            toggleSelected
            removeBtnColumns
            hasCheck
            multSelect
            :dynamic-height="115"
            @getMultLines="selectMultLine"
          >
            <div slot="top-buttons" class="top-buttons">
              <RgLessButton
                title="Remover"
                :disabled="disabledRemove"
                @click="removeAdministrativeCheckOutToList"
              />
              <RgShowButton
                title="Detalhes do Boletim"
                :disabled="!hasAdministrativeCheckOutSelected"
                @click="openModalBulletinDetails"
              />
            </div>
          </SmartTable>
        </div>
      </FormBase>

      <FormBase title="Registro da Alta">
        <RgValidatorForm ref="validator">
          <div class="emergency-administrative-checkout-out-register-form">
            <div class="row-1">
              <RgInputDate
                v-model="form.date"
                label="Data da Saída"
                :max-date="new Date()"
                :rules="{ required: true }"
                :class="{
                  'disable unselect': true,
                }"
                disabled
              />
              <RgInputHour
                v-model="form.hour"
                v-mask="'##:##'"
                label="Horário da Saída"
                :rules="{ required: true }"
                :class="{
                  'disable unselect': true,
                }"
                disabled
              />
            </div>
            <div class="row-2">
              <RgSuggestEmployee
                id="employee"
                ref="employee"
                v-model="form.employee"
                label="Profissional"
                placeholder="Digite o nome do responsável pela saída"
                sectorRequired
                :sector="bulletinSector"
                :vusResponsavel="false"
                :rules="{ required: true, forceSelection: true }"
                @selected="selectEmployee"
              />

              <RgSuggestCbo
                ref="occupation"
                v-model="form.occupation"
                label="CBO/Ocupação"
                :employeeId="form.employeeId"
                :rules="{ required: true, forceSelection: true }"
                :disabled="!form.employee"
                :class="{ disable: !form.employee }"
                placeholder="Digite o CBO do profissional responsável pela saída"
                @selected="selectCbo"
              />
              <RgComboboxDestinationPlaceExit
                ref="destinationPlaceExit"
                v-model="form.destination"
              />
            </div>
            <RgTextArea
              v-model="form.observation"
              class="text-area"
              label="Justificativa"
              :rules="{ required: true }"
              :maxlength="255"
            />
          </div>
        </RgValidatorForm>
      </FormBase>
    </div>

    <div
      slot="footer"
      class="emergency-administrative-checkout-out-register-footer"
    >
      <RgCleanButton small @click="cleanForm" />
      <RgCancelButton medium @click="goBack" />
      <RgSaveButton medium @click="saveAdministrativeCheckOutRegister" />
    </div>

    <ModalBulletinDetails
      id="modal-bulletin-details"
      :show="showModalBulletinDetails"
      :bulletinData="bulletinData"
      @close="closeModalBulletinDetails"
    />
  </Modulebox>
</template>

<script>
import {
  RgCleanButton,
  RgCancelButton,
  RgSaveButton,
  RgValidatorForm,
  RgTextArea,
  RgInputDate,
  RgInputHour,
  RgShowButton,
  RgSuggestCbo,
  RgLessButton,
  RgSuggestEmployee,
} from "~tokio/primitive";
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { SmartTable, FormBase } from "~tokio/foundation";
import { RgComboboxDestinationPlaceExit } from "$emergency/bulletin/component";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ModalBulletinDetails from "$emergency/bulletin/component/modal/modal-bulletin-details/ModalBulletinDetails";

export default {
  name: "EmergencyAdministrativeCheckOutRegister",
  directives: { mask },
  components: {
    Modulebox,
    SmartTable,
    FormBase,
    RgCleanButton,
    RgCancelButton,
    RgSaveButton,
    RgValidatorForm,
    RgTextArea,
    RgInputDate,
    RgInputHour,
    RgComboboxDestinationPlaceExit,
    RgShowButton,
    RgLessButton,
    RgSuggestCbo,
    ModalBulletinDetails,
    RgSuggestEmployee,
  },
  data() {
    return {
      mutableAdministrativeCheckOut: [],
      selectedAdministrativeCheckOut: [],
      bulletinData: {},
      form: {
        date: this.$utils.date.BrazilianDateNow(),
        hour: this.$utils.date.BrazilianTimeFormat(),
        destination: null,
        observation: null,
        employee: null,
        occupation: null,
        occupationId: null,
        employeeId: null,
      },
      total: 0,
      showModalBulletinDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    hasAdministrativeCheckOutSelected() {
      return (
        this.selectedAdministrativeCheckOut &&
        Object.keys(this.selectedAdministrativeCheckOut).length === 1
      );
    },

    disabledRemove() {
      return (
        (this.selectedAdministrativeCheckOut &&
          Object.keys(this.selectedAdministrativeCheckOut).length < 1) ||
        Object.keys(this.selectedAdministrativeCheckOut).length ===
          Object.keys(this.mutableAdministrativeCheckOut).length
      );
    },

    bulletinSector() {
      const hasAdministrativeCheckOut =
        this.mutableAdministrativeCheckOut?.length > 0;
      return hasAdministrativeCheckOut
        ? this.mutableAdministrativeCheckOut[0].set_id
        : null;
    },
  },
  watch: {},
  created() {
    this.COLUMNS = [
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Boletim", key: "bol_numero", align: "right" },
      { name: "Entrada", key: "bol_data_hora" },
      { name: "Setor", key: "set_nome", align: "left" },
      { name: "Local de Atendimento", key: "lca_nome", align: "left" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
    ];
  },
  mounted() {
    const selectedAdministrativeCheckOutList = this.$store.getters[
      "Emergency/AdministrativeCheckOut/GET_SELECTED_ADMINISTRATIVE_CHECK_OUT"
    ];
    this.mutableAdministrativeCheckOut = selectedAdministrativeCheckOutList;

    this.total = selectedAdministrativeCheckOutList.length;

    if (this.total === 1) {
      this.selectMultLine(this.mutableAdministrativeCheckOut);
    }
  },
  beforeDestroy() {},
  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveAdministrativeCheckOutRegister() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();

        const dateTimeAdministrativeCheckout =
          this.form.date + " " + this.form.hour;
        const administrativeCheckoutDateTime = this.$utils.date.BrazilianDateTimeToDatabase(
          dateTimeAdministrativeCheckout,
        );

        const generalControlList = [];

        for (const bulletin of this.mutableAdministrativeCheckOut) {
          try {
            const response = await this.$store.dispatch(
              "Emergency/AdministrativeCheckOut/SAVE_ADMINISTRATIVE_CHECKOUT",
              {
                bol_id: bulletin.bol_id,
                bol_alta_administrativa_data: administrativeCheckoutDateTime,
              },
            );

            const hasSuccess = response && response.success;
            if (hasSuccess) {
              const generalControl = {
                registro_geral: bulletin.bol_registro_geral.toString(),
              };
              generalControlList.push(generalControl);
            }
          } catch {
            continue;
          }
        }

        const hasBulletinToSendPep = generalControlList.length > 0;

        if (hasBulletinToSendPep) {
          const variables = {
            fun_id: this.form.employeeId,
            ocp_id: this.form.occupationId,
            checkoutDateTime: administrativeCheckoutDateTime,
            destinationPlace: this.form.destination
              ? Number(this.form.destination)
              : null,
            observation: this.form.observation || null,
            generalControls: generalControlList,
          };

          const responsePep = await this.$store.dispatch(
            "Emergency/AdministrativeCheckOut/SEND_ADMINISTRATIVE_CHECKOUT_PEP",
            { administrativeCheckout: variables },
          );

          const hasPepSuccess = responsePep && responsePep.success;

          if (!hasPepSuccess) {
            this.$toaster.info(
              "A alta administrativa não foi registrada para alguns pacientes",
              "Boletim sem atendimento ou cancelado",
            );
            this.$router.go(-1);
            return;
          }
        }

        if (!hasBulletinToSendPep) {
          this.$toaster.info(
            "A alta administrativa não foi registrada para alguns pacientes",
            "Boletim sem atendimento ou cancelado",
          );
        } else {
          this.$toaster.success("Alta administrativa realizada com sucesso");
        }

        this.$router.go(-1);
      } catch (pErr) {
        this.$toaster.error(
          "Erro na comunicação entre os sistemas. Repita o registro de saída dos pacientes.",
          "Falha no envio da alta para o PEP",
        );
      } finally {
        this.$loader.finish();
      }
    },

    removeAdministrativeCheckOutToList() {
      if (
        this.mutableAdministrativeCheckOut &&
        this.mutableAdministrativeCheckOut.length > 1
      ) {
        for (const indexSelected in this.selectedAdministrativeCheckOut) {
          const positionIndex = this.mutableAdministrativeCheckOut.findIndex(
            (element) =>
              element.bol_id ===
              this.selectedAdministrativeCheckOut[indexSelected].bol_id,
          );

          if (positionIndex > -1) {
            this.mutableAdministrativeCheckOut.splice(positionIndex, 1);
          }
        }
        this.selectedAdministrativeCheckOut = [];
        this.$refs.smartTable.cleanMultSelectedRow();
      }
    },

    selectEmployee(pValue) {
      this.form.occupation = null;
      this.form.occupationId = null;
      this.form.employeeId = pValue?.source?.fun_id || null;
    },

    selectCbo(pValue) {
      this.form.occupationId = pValue?.source?.ocp_id || null;
    },

    selectMultLine(pValue, pIndex) {
      if (pValue) {
        if (pValue.length === 1) {
          this.bulletinData = pValue[0];
        }
        this.selectedAdministrativeCheckOut = pValue;
      } else {
        this.selectedAdministrativeCheckOut = [];
        this.bulletinData = null;
      }
    },

    openModalBulletinDetails() {
      this.showModalBulletinDetails = true;
    },

    closeModalBulletinDetails() {
      this.showModalBulletinDetails = false;
    },

    cleanForm() {
      this.form = {
        date: this.$utils.date.BrazilianDateNow(),
        hour: this.$utils.date.BrazilianTimeFormat(),
        destination: null,
        observation: null,
        employee: null,
        occupation: null,
        occupationId: null,
        employeeId: null,
      };
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
