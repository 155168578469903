import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path:
    "/exam/schedule/queue/external-schedule-form/new-patient/patient-care-history",
  name:
    "exam.schedule.queue.external-schedule-form.new-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: OpenSubModule("exam") },
      { label: "Fila de Regulação", link: "/exam/schedule/queue" },
      {
        label: "Agendar Externamente",
        link: "/exam/schedule/queue/external-schedule-form",
      },
      {
        label: "Editar Paciente",
        link: "/exam/schedule/queue/external-schedule-form/new-patient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
