<template>
  <div v-if="show" class="modal-patient-record-not-linked-basic-attention">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">
          O cadastro do paciente não possui mais vínculo com a ficha de Atenção
          Básica
        </div>
      </div>

      <div slot="body" class="body">
        <div class="content">
          <span class="subtitle-modal">
            Ao atualizar a situação do vínculo, constatou-se o
            <strong>cancelamento da ficha no AB</strong>.

            <span v-if="hasPatientCompatibleCards" class="subtitle-modal">
              Selecione e vincule <strong> uma nova ficha </strong> adequada ao
              paciente na relação abaixo:
            </span>
          </span>

          <span v-if="!hasPatientCompatibleCards" class="subtitle-modal">
            Não foram encontradas fichas compatíveis com o paciente selecionado
            durante a busca realizada na base de dados da Atenção Básica.
          </span>

          <div v-if="!hasPatientCompatibleCards" class="information">
            <Tooltip class="tooltip" startOpen>
              <IconLightBulbHelper slot="icon" hollow />
              <div slot="content" class="content">
                <span> Possível solução para essa situação: </span>
                <span>
                  Se necessário, busque as informações com a Unidade de Atenção
                  Básica na região onde o paciente reside.
                </span>
              </div>
            </Tooltip>
          </div>

          <div
            v-for="(item, index) in individualRegistrationList"
            v-else
            :key="item.index"
            :class="{ selected: selectedPatient === index }"
            class="registers-list"
            @click="getPatient(item, index)"
          >
            <div class="register">
              <div>
                <IconCheck v-show="selectedPatient === index" class="check" />
              </div>

              <PatientType :sex="patientSex(item)" class="patient-type" />

              <div class="column">
                <span class="ellipsis" :title="item.pab_nome">
                  Nome: {{ item.pab_nome }}
                </span>
                <span class="ellipsis" :title="item.pab_nome_mae">
                  Nome da Mãe: {{ item.pab_nome_mae }}
                </span>
              </div>

              <div class="column">
                <span>
                  Data de Nascimento: {{ formatDate(item.pab_nascimento) }}
                </span>
                <span> CPF: {{ formatCpf(item.pab_cpf) }} </span>
              </div>

              <div class="column">
                <section class="tooltip">
                  <Tooltip show-left :show-up="toShowUp(index)">
                    <IconInfoHelper slot="icon" />
                    <div slot="content" class="content">
                      <div class="row">
                        <span class="header-tooltip">
                          <strong>Cabeçalho da Ficha de Cadastro - AB:</strong>
                        </span>
                      </div>
                      <div class="row">
                        <span>
                          <strong>Data do Atendimento: </strong
                          >{{ formatDate(item.pab_data_atendimento) }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.unidade_nome" class="ellipsis">
                          <strong> Unidade: </strong>{{ item.unidade_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.area_nome" class="ellipsis">
                          <strong> Área: </strong>{{ item.area_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.microarea_nome" class="ellipsis">
                          <strong> Microárea: </strong>{{ item.microarea_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span :title="item.equipe_nome" class="ellipsis">
                          <strong> Equipe: </strong>{{ item.equipe_nome }}
                        </span>
                      </div>
                      <div class="row">
                        <span
                          :title="item.pessoa_funcionario_nome"
                          class="ellipsis"
                        >
                          <strong> Responsável: </strong
                          >{{ item.pessoa_funcionario_nome }}
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                </section>
                <span class="cns">
                  CNS: {{ formatCns(item.pab_cartao_sus) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="footer">
        <MediumButton
          v-show="hasPatientCompatibleCards"
          id="cancel"
          label="Não Vincular"
          :background-color="'#FF7F2A'"
          @click="cancel"
        />
        <MediumButton
          v-show="hasPatientCompatibleCards"
          id="save"
          label="Vincular"
          :disabled="!selectedPatientData"
          @click="save"
        />
        <MediumButton
          v-show="!hasPatientCompatibleCards"
          id="close"
          label="Fechar"
          @click="close"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  IconCheck,
  Tooltip,
  IconLightBulbHelper,
  IconInfoHelper,
  MediumButton,
} from "~tokio/primitive";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";
import PatientType from "$person/patient/component/icon/PatientType";
import { ToShowUp } from "~tokio/primitive/tooltip/util/ToShowUp.js";

export default {
  name: "ModalPatientRecordNotLinkedBasicAttention",
  components: {
    RgBaseModal,
    IconCheck,
    PatientType,
    Tooltip,
    IconInfoHelper,
    MediumButton,
    IconLightBulbHelper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
    },
    patientHasNoBondOrInactiveBond: {
      type: Boolean,
      default: false,
    },
    patientBasicAttentionId: {
      type: Number,
    },
    individualRegistrationList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectedPatient: null,
      selectedPatientData: null,
    };
  },

  computed: {
    hasPatientCompatibleCards() {
      return this.individualRegistrationList.length > 0;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },

  methods: {
    getPatient(pItem, pIndex) {
      const isSameLine = this.selectedPatient === pIndex;

      if (isSameLine) {
        this.selectedPatient = null;
        this.selectedPatientData = null;
      } else {
        this.selectedPatient = pIndex;
        this.selectedPatientData = pItem;
      }
    },

    formatDate(pValue) {
      if (!pValue) return "";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },

    formatCpf(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },

    formatCns(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
    },

    patientSex(pItem) {
      const gender = pItem.sexo;
      const masculine = gender === "MASCULINO" ? 1 : null;
      const feminine = gender === "FEMININO" ? 2 : null;
      return masculine || feminine || null;
    },

    async save() {
      const pVerifyOriginalRegistrationId =
        !this.patientBasicAttentionId || this.patientHasNoBondOrInactiveBond;

      const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
        this.selectedPatientData,
        this.patientBasicAttentionId,
        this.patientId,
        pVerifyOriginalRegistrationId,
      );

      try {
        this.$loader.start();

        await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
          abEsus: patientAbEsus,
        });
        this.$toaster.success("Operação realizada com sucesso");
        this.$emit("change");
      } catch (err) {
        this.$toaster.error("Erro ao vincular", err);
      } finally {
        this.$loader.finish();
      }
    },

    toShowUp(pItemIndex) {
      return this.individualRegistrationList?.length === 1
        ? true
        : ToShowUp(pItemIndex, this.individualRegistrationList);
    },

    cancel() {
      this.close();
    },

    close() {
      this.clear();
      this.$emit("close");
    },

    clear() {
      this.selectedPatient = null;
      this.selectedPatientData = null;
    },
  },
};
</script>
