import SEARCH_BPAI from "./SearchBPAI";
import SEARCH_SHEET_BPAI from "./SearchSheet";
import SAVE_BULLETIN_BILLING from "./SaveBulletinBilling";
import REMOVE_BULLETIN_BILLING from "./RemoveBulletinBilling";
import LAST_COMPETENCE_BPAI from "./LastCompetence";
import TRANSFER_BULLETIN_BILLING from "./TransferBulletinBilling";

export default {
  SEARCH_BPAI,
  SAVE_BULLETIN_BILLING,
  REMOVE_BULLETIN_BILLING,
  SEARCH_SHEET_BPAI,
  LAST_COMPETENCE_BPAI,
  TRANSFER_BULLETIN_BILLING,
};
