<template>
  <Modulebox
    ref="modulebox"
    title="Saída por Consumo de Produtos"
    class="pharmacy-satellite-consumption"
  >
    <div class="pharmacy-satellite-consumption-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Origem">
          <section class="pharmacy-satellite-consumption-origin-form">
            <RgSelectUnithealthPharmacy
              ref="unitHealth"
              v-model="form.unitHealth"
              :disabled="disabledByModal"
              :rules="{ required: true }"
              @change="selectedUnitHealth"
            />
            <RgSelectPharmacy
              ref="pharmacy"
              v-model="form.pharmacy"
              byUser
              :unitHealthId="form.unitHealth"
              :userId="userLoginId"
              :typePharmacy="SATELLITE_PHARMACY_TYPE"
              :rules="{ required: true }"
              :disabled="!form.unitHealth || disabledByModal"
              :class="{ disable: !form.unitHealth }"
              @change="selectPharmacy"
            />
            <RgSelectDestinyConsumption
              id="set-id-aor"
              ref="destinationSector"
              v-model="form.set_id"
              :rules="{ required: true }"
              :disabled="!form.unitHealth || disabledByModal"
              :class="{ disable: !form.unitHealth }"
              default-text="Todos"
              empty-default-text="Todos"
              class="inputitem"
              label="Setor de Destino"
              @change="selectedDestiny"
            />
            <RgInputDate
              ref="solicitationDate"
              v-model="form.solicitationDate"
              label="Data da Saída"
              :rules="{ required: true }"
              :disabled="true"
              :class="{ disable: true }"
            />
            <div class="area">
              <RgTextArea
                id="observations"
                ref="textArea"
                v-model="form.observations"
                :maxlength="250"
                label="Observações"
                class="inputbox"
                is-optional
                :rules="{ fn: validateObservation }"
                :disabled="disabledByModal"
              />
            </div>
          </section>
        </FormBase>

        <FormBase title="Produtos e Medicamentos">
          <section class="pharmacy-satellite-consumption-products">
            <div class="pharmacy-satellite-consumption-products-form">
              <!-- TODO: Mudar select product para select de produtos com movimentação -->
              <RgSelectProduct
                ref="product"
                v-model="form.product"
                :pharmacyId="Number(form.pharmacy)"
                :typeMovimentation="3"
                :rules="{ required: true }"
                :disabled="!form.pharmacy || disabledByModal"
                :class="{ disable: !form.pharmacy }"
                @change="selectedProduct"
              />
              <RgSelectTypePresentation
                id="presentation"
                ref="presentation"
                v-model="form.presentation"
                label="Apresentação"
                :disabled="true"
                :class="{ disable: true }"
                :rules="{ required: true }"
              />
              <!-- Div adicionada para manter espaço em branco no layout caso v-if seja false -->
              <div>
                <RgSelectLotExpiration
                  v-if="stockLotExpiration"
                  id="lot"
                  ref="lot"
                  v-model="form.lot"
                  :pharmacyId="Number(form.pharmacy)"
                  :productId="Number(form.product)"
                  :rules="{ required: true }"
                  :disabled="!form.product || disabledByModal"
                  :class="{ disable: !form.product }"
                  @change="selectedLotExpiration"
                />
              </div>
              <RgInput
                ref="inventory"
                v-model="form.stock"
                :rules="{ required: true }"
                :disabled="true"
                :class="{ disable: true }"
                label="Estoque"
                placeholder="000000000"
              />
              <RgInputNumber
                ref="amountRequested"
                v-model="form.amountRequested"
                :rules="{ required: true, fn: validateAmount }"
                :maxlength="10"
                label="Quantidade Dispensada"
                placeholder="000000000"
                :disabled="disabledByModal"
              />

              <div class="actions">
                <RgCleanButton
                  :disabled="disabledByModal"
                  @click="openModalConfirmField"
                />
                <RgAddButton
                  id="add-btn"
                  title="Adicionar Produto/Medicamento"
                  :disabled="disabledByModal"
                  @click="saveConsumption"
                />
              </div>
            </div>

            <hr class="hr" />
            <div class="pharmacy-satellite-search-list">
              <SmartTable
                ref="smartTable"
                name="PharmacyProductsConsumption"
                :columns="COLUMNS"
                :body="mutableProductList"
                :total="mutableProductList.length"
                :showPagination="false"
                :initial-columns="8"
                toggleSelected
                @getLine="selectLine"
              >
                <div slot="top-buttons" class="actions">
                  <div class="right-buttons">
                    <!-- TODO: ver as permissoes dos botões -->
                    <RgLessButton
                      id="excluir-movimentacao"
                      :permission="true"
                      small
                      title="Excluir Produto/Medicamento selecionado"
                      :disabled="selectedRow === null || disabledByModal"
                      @click="openModalDeleteProduct"
                    />
                  </div>
                </div>
              </SmartTable>
            </div>
          </section>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="pharmacy-satellite-consumption-footer">
      <MediumButton
        title="Voltar"
        :disabled="disabledByModal"
        label="Voltar"
        @click="goBack"
      />
    </div>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteProduct"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmField"
      @getYes="cleanForm"
      @getOut="closeModalConfirmField"
      @close="closeModalConfirmField"
    />
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInputDate,
  RgInputNumber,
  RgInput,
  RgAddButton,
  RgCleanButton,
  MediumButton,
  RgTextArea,
  RgLessButton,
  ModalConfirmDeletion,
  ModalConfirmFieldClearing,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";
import moment from "moment";

import { mapGetters } from "vuex";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectDestinyConsumption from "$pharmacy/submodules/satellite/components/select/rg-select-destiny-consumption/RgSelectDestinyConsumption";

const FORM = {
  unitHealth: null,
  pharmacy: null,
  type: null,
  solicitationDate: moment().format("DD/MM/YYYY"),
  product: null,
  typePresentation: null,
  lot: null,
  inventory: null,
  productLabel: null,
  amountRequested: null,
  pharmacyLabel: null,
  stock: null,
};

export default {
  name: "PharmacySatelliteConsumption",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectUnithealthPharmacy,
    RgSelectLotExpiration,
    RgSelectTypePresentation,
    RgInputDate,
    RgInputNumber,
    RgInput,
    RgCleanButton,
    RgAddButton,
    MediumButton,
    SmartTable,
    RgTextArea,
    RgSelectDestinyConsumption,
    RgLessButton,
    ModalConfirmDeletion,
    ModalConfirmFieldClearing,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableProductList: [],
      product: null,
      presentationType: null,
      lot: false,
      lotSelected: null,
      stock: null,
      presentationTypeText: null,
      selectedRow: null,
      amountRequested: null,
      unitHealthLabel: null,
      destinyLabel: null,
      code: null,
      modalDeleteMovement: false,
      modalConfirmField: false,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
    }),
    preferenceStockType() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.frp_tipo_estoque"
      ];
    },
    stockLotExpiration() {
      return this.preferenceStockType === "2";
    },
    propsModalConfirmDeletion() {
      const confirm = () => this.inactivateConsumption();

      const show = this.modalDeleteMovement;
      const noReason = true;

      const title = `Excluir Produto do Registro de Consumo`;
      const message = `Deseja remover o produto/medicamento?`;
      const msgSuccess = "Consumo excluído com sucesso";
      const bodyText = `${this.selectedRow?.product} - ${this.selectedRow?.lot} - ${this.selectedRow?.amountRequested}`;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
      };
    },
    disabledByModal() {
      return this.modalDeleteMovement || this.modalConfirmField;
    },
  },

  watch: {
    "form.unitHealth"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacy = null;
        this.form.product = null;
        this.form.set_id = null;
      }
    },
    "form.pharmacy"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.product = null;
      }
    },
    "form.product"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.typePresentation = null;
        this.form.lot = null;
        this.form.stock = null;
        this.form.amountRequested = null;
      }
    },
    "form.lot"(pValue, pPrev) {
      if (!pValue && pValue !== pPrev) {
        this.form.stock = null;
        this.form.amountRequested = null;
      } else {
        this.getStock();
      }
    },
  },

  async mounted() {
    await this.fillFilter();
  },

  created() {
    this.COLUMNS = [
      { name: "Código", key: "code", align: "left", active: "true" },
      { name: "Nome", key: "product", align: "left", active: "true" },
      {
        name: "Apresentação",
        key: "presentationType",
        align: "left",
        active: "true",
      },
      {
        name: "Quant. Dispensada",
        key: "amountRequested",
        align: "left",
        active: "true",
      },
      { name: "Destino", key: "destiny", align: "left" },
      { name: "Data", key: "solicitationDate", align: "left", active: "true" },
      {
        name: "Unidade de Saúde",
        key: "unitHealth",
        align: "left",
        active: "true",
      },
      { name: "Farmácia", key: "pharmacy", align: "left", active: "true" },
      { name: "Observações", key: "observations", align: "left" },
      { name: "Lote-Validade", key: "lot", align: "left" },
    ];

    this.CENTRAL_PHARMACY_TYPE = 1;
    this.SATELLITE_PHARMACY_TYPE = 2;

    this.PRODUCTS_HAVE_ENTRIES = 1;
  },

  methods: {
    async fillFilter() {
      const existFilterData = this.$store.getters[
        "Pharmacy/Satellite/GET_FILTER_SATELLITE"
      ];

      if (existFilterData) {
        this.form.unitHealth = existFilterData.unitHealth;
        this.form.pharmacy = existFilterData.pharmacy;
      }
    },
    closeModalDeleteProduct() {
      this.modalDeleteMovement = false;
    },
    openModalDeleteProduct() {
      this.modalDeleteMovement = true;
    },
    closeModalConfirmField() {
      this.modalConfirmField = false;
    },
    openModalConfirmField() {
      this.modalConfirmField = true;
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    selectedUnitHealth(pValue) {
      if (pValue) {
        this.unitHealthLabel = pValue.label;
      } else {
        this.unitHealthLabel = null;
      }
    },
    selectedDestiny(pValue) {
      if (pValue) {
        this.destinyLabel = pValue.label;
      } else {
        this.destinyLabel = null;
      }
    },
    selectedProduct(pProduct) {
      if (pProduct) {
        this.productLabel = pProduct.mpd_novo_principio_ativo;
        this.presentationTypeText = pProduct.mtu_novo_nome;
        this.form.presentation = pProduct.mtu_id;
        this.code = pProduct.mpd_codigo;
      } else {
        this.productLabel = null;
        this.presentationTypeText = null;
        this.form.presentation = null;
        this.code = null;
      }
    },

    selectPharmacy(pPharmacy) {
      if (pPharmacy) {
        this.pharmacyLabel = pPharmacy.label;
      } else {
        this.pharmacyLabel = null;
      }
    },
    async getStock() {
      try {
        if (this.form.pharmacy && this.form.product && this.form.lot) {
          this.$loader.start();

          const data = {
            pharmacy: this.form.pharmacy,
            product: this.form.product,
            lot: this.form.lot,
          };

          const result = await this.$store.dispatch(
            "Pharmacy/Satellite/GET_STOCK",
            data,
          );

          if (result) {
            this.form.stock = null;
            this.form.stock = result;
          } else {
            this.form.stock = null;
          }
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao obter o estoque");
      } finally {
        this.$loader.finish();
      }
    },
    verifyDuplicate() {
      const filterDuplicate = this.mutableProductList.filter((pData) => {
        if (
          pData.productId === this.form.product &&
          pData.lot === this.lotSelected
        )
          return true;
      });

      return filterDuplicate;
    },

    async saveConsumption() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }
      const existsDuplicate = this.verifyDuplicate();

      if (existsDuplicate.length > 0) {
        this.$toaster.info(
          "Escolha o mesmo produto com outro Lote - Validade para registrar a saída.",
          "O produto já faz parte desse registro de Saída",
        );
        return false;
      }

      try {
        this.$loader.start();

        const variables = this.parseDataSave();
        const unitHealth = this.form.unitHealth;
        let data = null;
        data = await this.$store.dispatch(
          "Pharmacy/Satellite/REGISTER_CONSUMPTION",
          variables,
        );
        variables.cos_id = data.dados;
        this.insertProductTable(variables);

        this.$toaster.success("Consumo registrado com sucesso");

        const info = {
          intIdMunicipioProduto: this.form.product,
          intIdFarmacia: this.form.pharmacy,
        };
        const dataLimit = await this.$store.dispatch(
          "Pharmacy/MIN_LIMIT_EXCEEDED",
          info,
        );

        if (dataLimit !== 0) {
          this.$toaster.info(
            "Faça uma solicitação para repor o estoque do produto.",
            "O estoque mínimo foi alcançado",
          );
        }

        this.cleanForm();
        this.form.unitHealth = unitHealth;
        this.form.pharmacy = variables.cos_id_farmacias;
        this.form.set_id = variables.cos_id_destinos_consumos;
        this.selectedRow = null;
        this.$refs.smartTable.cleanSelectRow();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao realizar cadastro",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async inactivateConsumption() {
      try {
        this.$loader.start();
        const cos_id = this.selectedRow.cos_id;
        await this.$store.dispatch(
          "Pharmacy/Satellite/INACTIVATE_CONSUMPTION",
          cos_id,
        );
        this.removeProductTable(cos_id);
        this.selectedRow = null;
        this.$refs.smartTable.cleanSelectRow();
        this.$toaster.success("Registro excluído com sucesso");

        // this.cleanForm();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao excluir Registro",
        );
      } finally {
        this.$loader.finish();
      }
    },
    removeProductTable(id) {
      this.mutableProductList.forEach((item, index) => {
        if (item.cos_id === id) this.mutableProductList.splice(index, 1);
      });
    },
    parseDataSave() {
      const variables = {
        cos_data: this.form.solicitationDate,
        cos_id_destinos_consumos: this.form.set_id,
        cos_id_farmacias: this.form.pharmacy,
        cos_id_municipios_produtos: this.form.product,
        cos_id_usuarios: this.userLoginId,
        cos_lote: this.form.lot,
        cos_observacao: this.form.observations,
        cos_quantidade: this.form.amountRequested.replace(/[.]/g, ""),
      };
      return variables;
    },
    insertProductTable(variables) {
      const data = {
        cos_id: variables.cos_id,
        solicitationDate: this.form.solicitationDate,
        pharmacy: this.pharmacyLabel,
        product: this.productLabel,
        code: this.code,
        unitHealth: this.unitHealthLabel,
        destiny: this.destinyLabel,
        productId: this.form.product,
        presentationType: this.presentationTypeText,
        lot: this.lotSelected,
        observations: this.form.observations,
        amountRequested: this.form.amountRequested.replace(/[.]/g, ""),
      };

      this.mutableProductList.push(data);
    },
    validateObservation(pValue, pErrors) {
      const hasSpecial = /[!$%^&*()@_^#¨|´~=`{}[\]:";'~<>?\\]/.test(pValue);
      if (hasSpecial) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: barra, ponto, traço, vírgula e símbolo de soma",
        );

        return false;
      }

      return true;
    },
    validateAmount(pValue, pErrors) {
      const amount = Number(pValue.replace(/[^0-9]/g, ""));
      const stock = Number(this.form.stock.replace(/[^0-9]/g, ""));
      if (pValue && Number(pValue) < 1) {
        pErrors.push("Informe um valor maior que zero.");
        return false;
      }
      if (amount > stock) {
        pErrors.push("A quantidade não pode ser maior que o estoque");
        return false;
      }

      return true;
    },
    hasDeleteOption() {
      // const options = [2, 6];
      return this.hasSelectedLine;
    },
    selectedLotExpiration(pLot) {
      if (pLot) {
        this.lotSelected = pLot.label;
        this.form.lot = pLot.value;
      } else {
        this.form.lot = null;
        this.lotSelected = null;
      }
    },
    hasSelectedLine() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },
    goBack() {
      this.$router.go(-1);
    },
    selectLine(pValue) {
      if (pValue && pValue !== this.selectedRow) {
        this.selectedRow = pValue;
      } else {
        this.selectedRow = null;
      }
      return this.selectedRow;
    },
    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM);
      // this.mutableProductList = [];
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "unitHealth",
        "pharmacy",
        "destinationSector",
        "solicitationDate",
        "textArea",
        "product",
        "typePresentation",
        "lot",
        "inventory",
        "amountRequested",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
