<style src="./UserFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="user-filter">
    <form class="form-container" @submit.prevent="searchFilter">
      <div class="filtro">
        <div class="division">
          <h3>Status de Usuario</h3>
          <hr />
          <!-- TODO: Transformar em border da classe division -->
        </div>

        <RgRadioCustom
          id="objective"
          v-bind="propsRadioCustom"
          class="custom-radio"
          label="Status de Usuario"
          :canUnselect="false"
          @input="onInputRadioCustom"
        />

        <div class="division">
          <h3>Busca por Unidade</h3>
          <hr />
          <!-- TODO: Transformar em border da classe division -->
        </div>
        <RgSelectUnithealth v-model="form.IdUnityHealth" class="al" />
        <div class="division">
          <h3>Busca por Região</h3>
          <hr />
          <!-- TODO: Transformar em border da classe division -->
        </div>
        <div class="row">
          <RgSuggestState
            ref="State"
            v-model="form.digitedUf"
            :rules="{ fn: forceSelectIfTypeOfUF }"
            label="UF"
            class="col-3"
            @selected="changeDigitedUF"
          />
          <RgSuggestCity
            ref="City"
            v-model="form.digitedMun"
            :rules="{ fn: forceSelectIfTypeOfMu }"
            class="col-9"
            label="Município"
            placeholder="Ex: Nova Friburgo"
            @selected="changeDigitedMU"
          />
        </div>
        <div class="division">
          <h3>Busca por Nome</h3>
          <hr />
          <!-- TODO: Transformar em border da classe division -->
        </div>
        <div class="row">
          <RgInput
            v-model="form.digitedLoginName"
            label="Nome de Usuário"
            placeholder="Ex: usuario_RGesus"
          />
        </div>
        <br />
        <div class="row">
          <RgInput
            v-model="form.digitedName"
            :rules="{ validatorIfExistNumber: true }"
            label="Nome do Usuário"
            placeholder="Ex: Angelina"
          />
        </div>
      </div>
    </form>
    <div class="actions">
      <button class="btn btn-primary" @click="searchFilter">Buscar</button>
      <button type="button" class="btn btn-primary" @click="addNewUser">
        Novo
      </button>
      <button class="col-4 btn btn-clean" @click="cleanForm">Limpar</button>
    </div>
  </section>
</template>

<script>
import { isEmpty } from "lodash";
import RgInput from "~tokio/primitive/input/rg-input/RgInput";
import RgSuggestCity from "$patient/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestState from "$patient/common/components/suggest/rg-suggest-state/RgSuggestState";
import { RgSelectUnithealth, RgRadioCustom } from "~tokio/primitive";

import { AlertError } from "$exam/common/services";

export default {
  name: "UserFilter",
  components: {
    RgSelectUnithealth,
    RgSuggestCity,
    RgInput,
    RgSuggestState,
    RgRadioCustom,
  },
  data() {
    return {
      form: {
        statusUser: 1,
        booleanStatusUser: 1,
        digitedUf: "",
        digitedLoginName: "",
        digitedName: "",
        digitedMun: "",
        digitedMuID: 0,
        digitedUfID: 0,
      },
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "ATIVO", color: "#03CE63", id: 1 },
        { title: "INATIVO", color: "#F96B70", id: 0 },
      ];

      const value = this.form.booleanStatusUser;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },
  mounted() {
    this.IdUnityHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.booleanStatusUser = item.id;
      }
    },
    addNewUser() {
      this.$router.push("/register/user/details");
    },
    cleanForm() {
      this.form.digitedUf = "";
      this.form.digitedLoginName = "";
      this.form.digitedName = "";
      this.form.digitedMun = "";
      this.form.digitedMuID = 0;
      this.form.digitedUfID = 0;

      this.$emit("clear-search", true);
    },
    searchFilter() {
      this.form.statusUser = Boolean(this.form.booleanStatusUser);
      this.$emit("submit-filter", this.form);
    },
    forceSelectIfTypeOfUF(pValue, pErrors) {
      return !isEmpty(pValue) && this.form.digitedUfID === 0
        ? AlertError("Selecione um item", pErrors)
        : true;
    },
    forceSelectIfTypeOfMu(pValue, pErrors) {
      return !isEmpty(pValue) && this.form.digitedMuID === 0
        ? AlertError("Selecione um item", pErrors)
        : true;
    },
    changeDigitedUF(pValue) {
      const { source } = pValue;
      this.form.digitedUfID = source && source.est_id ? source.est_id : 0;
      this.$refs.State.validate();
    },
    changeDigitedMU(pValue) {
      const { source } = pValue;
      this.form.digitedMuID = source && source.mun_id ? source.mun_id : 0;
      this.$refs.City.validate();
    },
  },
};
</script>
