<template>
  <section class="quick-appointment-external-schedule-regulation-form">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="form-base">
          <div class="actions-top">
            <RgLessButton
              v-show="form.patientList && form.patientList.length > 1"
              :disabled="!patientSelect"
              :class="{ disable: !patientSelect }"
              class="delete"
              title="Remover paciente da tabela"
              @click="removePatient"
            />
            <RgPersonCardButton
              :disabled="!patientSelect"
              :class="{ disable: !patientSelect }"
              class="register"
              title="Cadastro de Paciente"
              @click="editPatient"
            />
            <RgPrinterButton
              :disabled="!patientSelect"
              :class="{ disable: !patientSelect }"
              class="printer"
              title="Imprimir carteirinha"
              @click="patientPrintCard"
            />
            <!-- <RgHistoryButton
            :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="history"
                title="Histórico do paciente"
                @click="historyPatient"
              /> -->
          </div>

          <table v-if="form.patientList" class="table-patient">
            <div class="thead">
              <tr class="header-grid">
                <th class=""></th>
                <th class="patient">Pacientes</th>
                <th class="medical-record">Prontuário Único</th>
              </tr>
            </div>

            <div class="tbody">
              <tr
                v-for="(item, index) in form.patientList"
                :key="index"
                :class="{ selected: index === activeRow }"
                class="body-grid"
                @click="selectLine(item, index)"
              >
                <td>
                  <div v-if="index === activeRow" class="check">
                    <IconCheck />
                  </div>
                </td>
                <td>{{ item.pes_nome }}</td>
                <td>{{ item.pac_prontuario_unico }}</td>
              </tr>
            </div>
          </table>
        </FormBase>

        <FormBase title="Agendar Externo" class="form-base">
          <div class="schedule-area">
            <RgSelectExternalLocation
              id="select-external-location"
              v-model="form.aex_id_locais_externos"
              label="Destino"
              :rules="{ required: true }"
              :IdModulo="MODULE_ID"
            />

            <RgTextArea
              id="text-area"
              ref="textArea"
              v-model="form.aex_observacao"
              :maxlength="500"
              label="Observação"
              class="textarea"
            />
          </div>
        </FormBase>

        <FormBase
          v-if="form.patientList.length <= 1 && subprocedureList.length > 0"
          title="Subprocedimentos"
          class="form-base"
        >
          <SmartTable
            ref="smartTableSubprocedures"
            name="QuickExternalScehduleRegulationForm"
            removeBtnColumns
            v-bind="propsSmartTableSubprocedure"
            @getMultLines="onSelectedSubprocedures"
          />
        </FormBase>
      </RgValidatorForm>
    </form>
  </section>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable.vue";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import SearchPersonById from "./actions/SearchPersonById";
import { mapGetters } from "vuex";

import {
  RgValidatorForm,
  RgTextArea,
  RgSelectExternalLocation,
  RgPersonCardButton,
  RgPrinterButton,
  // RgHistoryButton,
  RgLessButton,
  IconCheck,
} from "~tokio/primitive";

const COLUMN_TABLE_SUBPROCEDURE = [
  { name: "Subprocedimentos", key: "subProcedimento" },
];

export default {
  name: "QuickAppointmentExternalScheduleRegulationForm",
  components: {
    FormBase,
    RgValidatorForm,
    RgTextArea,
    SmartTable,
    RgSelectExternalLocation,
    RgPersonCardButton,
    RgPrinterButton,
    // RgHistoryButton,
    RgLessButton,
    IconCheck,
  },

  data() {
    return {
      subprocedureList: [],
      selectedSubprocedures: [],
      activeRow: null,
      form: {
        aex_id_locais_externos: null,
        aex_observacao: "",
        patientList: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    propsSmartTableSubprocedure() {
      const body = this.subprocedureList;
      const columns = COLUMN_TABLE_SUBPROCEDURE;
      const indexColumn = "id";
      const showPagination = false;
      const total = parseInt(this.subprocedureList.length);
      const multSelect = true;
      const hasCheck = true;

      return {
        body,
        columns,
        indexColumn,
        showPagination,
        total,
        multSelect,
        hasCheck,
      };
    },

    patientSelect() {
      return this.activeRow !== null;
    },
  },

  watch: {
    selectedSubprocedures() {
      this.checkDisabledBtnSave();
    },
    "form.aex_id_locais_externos"(value) {
      if (!isNaN(parseInt(value))) {
        this.checkDisabledBtnSave();
      }
    },
    "form.patientList"(pValue) {
      if (pValue && pValue.length === 1) {
        this.selectLine(pValue[0], 0);
      }
    },
  },

  async created() {
    this.MODULE_ID = 33;
  },

  async mounted() {
    this.$loader.start();
    const existData = await this.$store.getters[
      "Exam/Schedule/GET_SELECTED_PATIENT_LIST_QUEUE"
    ];

    if (existData) {
      if (this.lastPersonIdSaved) {
        const patientData = await SearchPersonById({
          pesId: this.lastPersonIdSaved,
        });
        if (patientData) {
          const index = existData.findIndex(
            (row) => Number(row.pes_id) === Number(this.lastPersonIdSaved),
          );

          existData[index].pes_nome = patientData.pes_nome;
        }
        this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
      }

      this.form.patientList = existData;
      const idFilaExame = existData[0].fle_id;
      this.subprocedureList = await this.$store.dispatch(
        "Exam/Schedule/SUBPROCEDURES_REGULATION_EXTERNAL",
        idFilaExame,
      );
    } else {
      this.$router.push({ name: "exam.schedule.queue" });
      this.$toaster.info(
        "Será necessário realizar uma nova pesquisa.",
        "Você foi redirecionado para a busca",
      );
    }
    this.$loader.finish();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/queue/external-schedule-form",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/Schedule/UNSELECT_QUEUE_EXTERNAL_EXAM_DATA");
    }
  },

  methods: {
    checkDisabledBtnSave() {
      let disable = false;
      if (!this.form.aex_id_locais_externos) {
        disable = true;
      }

      // somente 1 paciente
      if (this.form.patientList.length <= 1) {
        // se tiver subprocedimento
        if (this.subprocedureList.length >= 1) {
          if (this.selectedSubprocedures.length <= 0) {
            disable = true;
          }
        }
      }

      this.$emit("disabledBtnSave", disable);
    },

    onSelectedSubprocedures(list) {
      this.selectedSubprocedures = list;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveForm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      if (!this.form.patientList) {
        this.$toaster.warning("Devem existir pacientes para salvar");
        return false;
      }

      try {
        this.$loader.start();

        const patientList = [];

        this.form.patientList.map((item, index) => {
          patientList.push({
            fil_id: item.fil_id,
            pac_id: item.pac_id,
            Paciente: item.pes_nome,
            pcc_id: item.pcc_id,
            "Prontuario Unico": item.pac_prontuario_unico,
            fle_id: item.fle_id,
            pes_id: item.pes_id,
          });
        });

        const variables = {
          aex_id_locais_externos: this.form.aex_id_locais_externos,
          aex_observacao: this.form.aex_observacao,
          idModulo: this.MODULE_ID,
          pacientes: patientList,
        };

        if (patientList.length <= 1 && this.subprocedureList.length >= 1) {
          variables.stp_subProcedimentos = [];
          this.selectedSubprocedures.map((el) => {
            variables.stp_subProcedimentos.push({
              id: el.id,
              subProcedimento: el.subProcedimento,
            });
          });
        }

        await this.$store.dispatch(
          "Exam/Schedule/SAVE_EXAM_EXTERNAL_SCHEDULE",
          variables,
        );

        const queueId = this.form.patientList.map(({ fil_id }) =>
          Number(fil_id),
        );
        const reason = this.form.patientList[0]
          .fil_motivo_agendamento_fora_topo;
        const reasonVariables = {
          queueId,
          reason,
        };

        if (reason) {
          await this.$store.dispatch(
            "Exam/Schedule/SAVE_EXAM_SCHEDULE_QUEUE_REASON",
            reasonVariables,
          );
        }

        this.$toaster.success("Agendamento externo realizado com sucesso");
        this.$router.push({
          name: "exam.schedule.queue",
        });
      } catch (Err) {
        this.$toaster.error("Erro ao salvar o agendamento externo");
      } finally {
        this.$loader.finish();
      }
    },

    selectLine(item, index) {
      this.activeRow = index;
      this.pesId = item.pes_id;
      this.indexPatient = index;
    },

    editPatient() {
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(this.pesId));
      this.$router.push({
        name: "exam.schedule.queue.external-schedule-form.new-patient",
      });
      this.activeRow = null;
    },

    async patientPrintCard() {
      try {
        if (this.pesId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: Number(this.pesId) },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
      this.activeRow = null;
    },

    removePatient(index) {
      if (this.form.patientList && this.form.patientList.length > 1) {
        this.form.patientList.splice(this.indexPatient, 1);
        this.$store.commit(
          "Exam/Schedule/SELECT_QUEUE_EXTERNAL_EXAM_DATA",
          Object.values(this.form.patientList),
        );
        this.activeRow = null;
      }
    },
  },
};
</script>
