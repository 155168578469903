import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyCentralBatchTraceabilityReport(
    $unitHealthId: Int
    $unitHealthName: String
    $pharmacyId: Int
    $pharmacyName: String
    $productId: Int
    $productName: String
    $productTypeId: Int
    $productTypeName: String
    $lot: String
    $lotAndExpiration: String
    $destinyType: String
    $patientId: Int
    $patientName: String
    $loggedUserId: Int!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyCentralBatchTraceabilityReport(
      unitHealthId: $unitHealthId
      unitHealthName: $unitHealthName
      pharmacyId: $pharmacyId
      pharmacyName: $pharmacyName
      productId: $productId
      productName: $productName
      productTypeId: $productTypeId
      productTypeName: $productTypeName
      lot: $lot
      lotAndExpiration: $lotAndExpiration
      destinyType: $destinyType
      patientId: $patientId
      patientName: $patientName
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PRODUTO
        NOME_COMERCIAL
        APRESENTACAO
        TIPO
        DESTINATARIO
        LOTE_VALIDADE
        QUANTIDADE
        TIPO_DESTINATARIO
        TELEFONE
        UNIDADE_SAUDE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyCentralBatchTraceabilityReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
