<style lang="scss" src="./RgBaseModal.scss" scoped></style>
<template>
  <div>
    <transition name="base-modal">
      <div
        v-show="show"
        id="modal"
        ref="modal"
        aria-modal="true"
        class="base-modal-mask"
        @click="noClose"
      >
        <div class="base-modal-wrapper" @click.self="close">
          <div
            :class="{ rounded: rounded }"
            :style="baseModalStyle"
            class="base-modal-container"
          >
            <div class="icon">
              <slot name="icon" />
            </div>

            <div
              class="base-modal-header"
              :class="{ headerflex: withCloseButton }"
            >
              <slot name="header" />
              <div
                v-if="withCloseButton"
                class="button-close unselect"
                @click.stop.prevent="closeButton"
              >
                X
              </div>
            </div>

            <div class="base-modal-body">
              <slot name="body">
                <slot />
              </slot>
              <div v-if="removeSubtitle" class="base-modal-sub-title">
                <slot name="sub-title" />
              </div>
            </div>

            <div class="base-modal-footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "RgBaseModal",
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: undefined,
      required: true,
    },
    sizeUnit: {
      type: String,
      default: "vw",
    },
    maxWidth: {
      type: Number,
      default: 90,
    },
    minWidth: {
      type: Number,
      default: 40,
    },
    maxHeight: {
      type: Number,
      default: 40,
    },
    minHeight: {
      type: Number,
      default: 32,
    },
    overflowY: {
      type: String,
      default: "initial",
    },
    overflowYScroll: {
      type: Boolean,
      default: false,
    },
    withCloseButton: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Object,
      default: null,
    },

    removeSubtitle: Boolean,
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    baseModalStyle() {
      const unit = this.size && this.size.unit ? this.size.unit : this.sizeUnit;

      return {
        "max-width":
          this.size && this.size.maxWidth
            ? this.size.maxWidth + unit
            : this.maxWidth,
        "min-width":
          this.size && this.size.minWidth
            ? this.size.minWidth + unit
            : this.minWidth,
        "max-height":
          this.size && this.size.maxHeight
            ? this.size.maxHeight + unit
            : this.maxHeight,
        "min-height":
          this.size && this.size.minHeight
            ? this.size.minHeight + unit
            : this.minHeight,
        "overflow-y": this.overflowYScroll ? "scroll" : this.overflowY,
      };
    },
  },

  methods: {
    noClose() {
      this.showModal = false;
    },
    closeButton() {
      this.$emit("close");
    },
    close() {
      if (this.showModal) this.$emit("close");
    },
  },
};
</script>
