<template>
  <section class="export-queue-info-form">
    <FormBase class="formbase" title="Exportar">
      <div class="row">
        <RgInputDate
          v-model="form.initialDate"
          label="Data Inicial"
          class="col-2"
        />
        <RgInputDate
          v-model="form.finalDate"
          label="Data Final"
          class="col-2"
        />
        <RgComboboxQueueType
          v-model="form.queueType"
          :default-value="false"
          default-text="Todos"
          class="col-4"
        />
        <RgComboboxQueueStatus
          v-model="form.queueStatus"
          :default-value="false"
          default-text="Todos"
          class="col-4"
        />
      </div>
      <LargeButton
        title="Exportar"
        label="Exportar .CSV"
        class="button"
        :background-color="'#1E88A9'"
        @click="exportCsv"
      >
        <div slot="icon" class="icon">
          <IconArrowDownload />
        </div>
      </LargeButton>
    </FormBase>
  </section>
</template>

<script>
import { RgInputDate, LargeButton } from "~tokio/primitive";

import { IconArrowDownload } from "~tokio/primitive/icon/symbols";

import FormBase from "~tokio/foundation/form-base/FormBase";
import RgComboboxQueueType from "$queue/common/components/rg-combobox-queue-type/RgComboboxQueueType";
import RgComboboxQueueStatus from "$queue/common/components/rg-combobox-queue-status/RgComboboxQueueStatus";

export default {
  name: "ExportQueueInfoForm",
  components: {
    FormBase,
    LargeButton,
    IconArrowDownload,
    RgInputDate,
    RgComboboxQueueType,
    RgComboboxQueueStatus,
  },

  data: () => {
    return {
      form: {
        initialDate: "",
        finalDate: "",
        queueType: 0,
        queueStatus: 0,
      },
    };
  },

  mounted() {
    this.form.finalDate = this.$utils.date.BrazilianDateFormat();
  },

  methods: {
    async exportCsv() {
      try {
        this.$loader.start("Requisitando exportação da fila");

        const { data } = await this.$store.dispatch("Queue/ExportQueueInfo", {
          data: { ...this.form },
        });

        this.$emit("update-status-export-list");

        this.$toaster.success(data.message);
      } catch (pErr) {
        this.$toaster.error("Falha ao Gerar arquivo de Exportação");
      } finally {
        this.$loader.finish();
      }
    },
  },
};
</script>
