<template>
  <div>
    <ModalHospitalizationDetails
      :show="showModalHospitalizationDetails"
      :hospitalization-info="info"
      @close="closeModalHospitalizationDetails"
    />

    <ModalHospitalizationExit
      :bed-id="bedId"
      :status="bedStatus"
      :hospitalization-extra="info"
      :situation-disabled="isBusy"
      :show="showModalHospitalizationExit"
      @reSearch="reSearchAfterChangeFlow"
      @close="closeModalHospitalizationExit"
    />

    <ModalPatientTransferExchange
      :show="showModalTransferExchange"
      :item="info"
      @reSearch="reSearchAfterChangeFlow"
      @close="closeModalTransferExchange"
    />

    <ModalHistoryTransfer
      :show="showModalHistoryTransfer"
      :hospitalization-extra="info"
      @close="closeModalHistoryTransfer"
    />

    <ModalOccupationHistory
      :show="showModalOccupationHistory"
      :hospitalization-extra="info"
      @close="closeModalOccupationHistory"
    />

    <ModalHospitalizationReason
      :bed-id="bedId"
      :hospitalization="info"
      :hospitalization-id="hospitalizationId"
      :indigentId="iniId"
      :status="bedStatus"
      :operation="newSituation"
      :show="showModalHospitalizationReason"
      title="Excluir Internação"
      @reSearch="reSearchAfterChangeFlow"
      @close="closeModalHospitalizationReason"
    />

    <ModalRemoveHospitalizationExit
      :show="showModalRemoveHospitalizationExit"
      :hospitalization-info="info"
      @reSearch="reSearchAfterChangeFlow"
      @close="closeModalRemoveHospitalizationExit"
    />
  </div>
</template>

<script>
import {
  ModalHospitalizationExit,
  ModalHospitalizationReason,
  ModalRemoveHospitalizationExit,
  ModalHospitalizationDetails,
} from "../../../../common/component";

import ModalHistoryTransfer from "../../modal/modal-history-transfer/ModalHistoryTransfer";
import ModalOccupationHistory from "../../modal/modal-occupation-history/ModalOccupationHistory";
import ModalPatientTransferExchange from "$hospitalization/common/component/modal/modal-patient-transfer-exchange/ModalPatientTransferExchange";
import PatientIdentification from "./html/PatientIdentification";

export default {
  name: "ManagerHospitalizationSearch",
  components: {
    ModalHospitalizationExit,
    ModalHistoryTransfer,
    ModalOccupationHistory,
    ModalHospitalizationReason,
    ModalRemoveHospitalizationExit,
    ModalPatientTransferExchange,
    ModalHospitalizationDetails,
  },
  props: {},
  data() {
    return {
      bedId: null,
      bedStatus: null,
      newSituation: null,
      hospitalizationId: null,
      iniId: null,
      info: null,
      showModalHospitalizationDetails: false,
      showModalHospitalizationExit: false,
      showModalTransferExchange: false,
      showModalHospitalizationReason: false,
      showModalRemoveHospitalizationExit: false,
      showModalHistoryTransfer: false,
      showModalOccupationHistory: false,
    };
  },
  computed: {
    isBusy() {
      return this.bedStatus === this.BED_OCUPADO;
    },
  },
  created() {
    this.BED_OCUPADO = 3;
  },
  methods: {
    reSearchAfterChangeFlow() {
      this.$emit("reSearch");
    },

    async sendHospitalizationToPep(pValue) {
      try {
        this.$loader.start();
        const hasHospitalizationId = pValue.int_id && pValue.int_id > 0;

        if (hasHospitalizationId) {
          const response = await this.$store.dispatch(
            "Hospitalization/SEND_HOSPITALIZATION_TO_PEP",
            { hospitalizationId: pValue.int_id },
          );

          const isSuccess = response && response.success;

          if (isSuccess) {
            this.$toaster.success(`${response.message}`);
          } else {
            this.$toaster.error(`${response.message}`);
          }
          this.$emit("reSearch");
        }
      } catch (pErr) {
        console.error(pErr);
        const error = pErr;
        this.$toaster.error(`${error}`);
      } finally {
        this.$loader.finish();
      }
    },

    async cancelSendHospitalizationToPep(pValue) {
      try {
        this.$loader.start();
        const hasHospitalizationId = pValue.int_id && pValue.int_id > 0;
        const hasGeneralRegistry = pValue.int_registro_geral > 0;

        if (hasHospitalizationId && hasGeneralRegistry) {
          const response = await this.$store.dispatch(
            "Hospitalization/CANCEL_SEND_HOSPITALIZATION_TO_PEP",
            {
              hospitalizationId: pValue.int_id,
              generalRegistry: pValue.int_registro_geral,
            },
          );

          const isSuccess = response && response.success;

          if (isSuccess) {
            this.$toaster.success(`${response.message}`);
          } else {
            this.$toaster.error(`${response.message}`);
          }
          this.$emit("reSearch");
        }
      } catch (pErr) {
        console.error(pErr);
        const error = pErr;
        this.$toaster.error(`${error}`);
      } finally {
        this.$loader.finish();
      }
    },

    printPatientIdentification(pValue) {
      const html = PatientIdentification(pValue);
      this.$utils.print.printHtml(html, 500, 500);
    },

    async printHospitalizationAuthorizationRequest(pValue) {
      const hasHospitalizationId = pValue.int_id && pValue.int_id > 0;

      if (hasHospitalizationId) {
        const html = await this.$store.dispatch(
          "Hospitalization/Hospitalizations/PRINT_HOSPITALIZATION_AUTHORIZATION_REQUEST",
          { int_id: pValue.int_id },
        );

        this.$utils.print.printHtml(html);
      }
    },

    async printRegistrationHospitalizationPatient(pValue) {
      const hasHospitalizationId = pValue.int_id && pValue.int_id > 0;

      if (hasHospitalizationId) {
        const html = await this.$store.dispatch(
          "Hospitalization/Hospitalizations/PRINT_REGISTRATION_HOSPITALIZATION_PATIENT",
          { int_id: pValue.int_id },
        );

        this.$utils.print.printHtml(html);
      }
    },

    // MODALS

    openModalHospitalizationDetails(pValue) {
      this.info = pValue;
      this.showModalHospitalizationDetails = true;
      this.$emit(
        "disabledByModalComponents",
        this.showModalHospitalizationDetails,
      );
    },

    closeModalHospitalizationDetails() {
      this.info = null;
      this.showModalHospitalizationDetails = false;
      this.$emit(
        "disabledByModalComponents",
        this.showModalHospitalizationDetails,
      );
    },

    openModalHospitalizationExit(pValue) {
      const isDataFilled = pValue && Object.keys(pValue).length > 0;

      if (isDataFilled) {
        this.bedId = pValue.inl_id;
        this.bedStatus = pValue.isl_id;

        this.info = pValue;

        this.showModalHospitalizationExit = true;
      }
      this.$emit(
        "disabledByModalComponents",
        this.showModalHospitalizationExit,
      );
    },

    closeModalHospitalizationExit() {
      this.bedId = null;
      this.bedStatus = null;
      this.info = null;

      this.showModalHospitalizationExit = false;
      this.$emit(
        "disabledByModalComponents",
        this.showModalHospitalizationExit,
      );
    },

    openModalTransferExchange(pValue) {
      this.info = pValue;

      this.showModalTransferExchange = true;
      this.$emit("disabledByModalComponents", this.showModalTransferExchange);
    },

    closeModalTransferExchange() {
      this.info = null;

      this.showModalTransferExchange = false;
      this.$emit("disabledByModalComponents", this.showModalTransferExchange);
    },

    openModalOccupationHistory(pValue) {
      const isDataFilled = pValue && Object.keys(pValue).length > 0;

      if (isDataFilled) {
        this.info = {
          int_id: pValue.int_id,
          pac_id: pValue.pac_id,
          pac_prontuario_unico: pValue.pac_prontuario_unico,
          pes_nome: pValue.paciente,
          itl_nome: pValue.itl_nome,
          int_numero: pValue.int_numero,
        };

        this.showModalOccupationHistory = true;
      }
      this.$emit("disabledByModalComponents", this.showModalOccupationHistory);
    },

    closeModalOccupationHistory() {
      this.info = null;
      this.showModalOccupationHistory = false;
      this.$emit("disabledByModalComponents", this.showModalOccupationHistory);
    },

    openModalHistoryTransfer(pValue) {
      this.info = {
        pci_id: pValue.int_id_pacientes_internacao,
        pes_nome: pValue.paciente,
        pac_prontuario_unico: pValue.pac_prontuario_unico,
        int_numero: pValue.int_numero,
        itl_nome: pValue.itl_nome,
      };

      this.showModalHistoryTransfer = true;
      this.$emit("disabledByModalComponents", this.showModalHistoryTransfer);
    },

    closeModalHistoryTransfer() {
      this.info = null;
      this.showModalHistoryTransfer = false;
      this.$emit("disabledByModalComponents", this.showModalHistoryTransfer);
    },

    openModalHospitalizationReason(pValue) {
      const isDataFilled = pValue && Object.keys(pValue).length > 0;

      if (isDataFilled) {
        this.bedId = pValue.inl_id;
        this.bedStatus = pValue.isl_id;
        this.newSituation = 1; // Vazio
        this.hospitalizationId = pValue.int_id;
        this.iniId = pValue.ini_id;
        this.info = pValue;
        this.showModalHospitalizationReason = true;
      }
      this.$emit(
        "disabledByModalComponents",
        this.showModalHospitalizationReason,
      );
    },

    closeModalHospitalizationReason() {
      this.bedId = null;
      this.bedStatus = null;
      this.newSituation = null;
      this.hospitalizationId = null;
      this.iniId = null;

      this.showModalHospitalizationReason = false;
      this.$emit(
        "disabledByModalComponents",
        this.showModalHospitalizationReason,
      );
    },

    openModalRemoveHospitalizationExit(pValue) {
      this.info = pValue;

      this.showModalRemoveHospitalizationExit = true;
      this.$emit(
        "disabledByModalComponents",
        this.showModalRemoveHospitalizationExit,
      );
    },

    closeModalRemoveHospitalizationExit() {
      this.showModalRemoveHospitalizationExit = false;
      this.$emit(
        "disabledByModalComponents",
        this.showModalRemoveHospitalizationExit,
      );
    },
  },
};
</script>
