<style
  src="./RgSuggestProfessionalSectorOccupationScaleActive.scss"
  lang="scss"
  scoped
></style>
<template>
  <RgSuggest
    :id="id"
    ref="innerSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :disabled="disabled"
    :search-on-focus="searchOnFocus"
    :rules="rules"
    min="0"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="professional-item"
    >
      <div class="professional-row-info">
        <div class="professional-name">
          <span>{{ item.pes_nome }}</span>
        </div>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchRgProfessionalSectorOccupationScaleActive } from "./action";

export default {
  name: "RgSuggestProfessionalSectorOccupationScaleActive",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    sectorId: {
      required: true,
    },
    ocuppationId: {
      required: false,
    },
    label: {
      type: String,
      default: "Profissional",
    },
    title: {
      type: String,
      default: "Profissional",
    },
    dataItem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      captionRaw: "pes_nome",
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.innerSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    doSearch(pSearchTerm, pLimit = null) {
      if (!this.sectorId || !this.ocuppationId) {
        return;
      }

      return SearchRgProfessionalSectorOccupationScaleActive({
        variables: {
          sectorId: this.sectorId,
          ocuppationId: this.ocuppationId,
          limit: pLimit,
        },
      });
    },

    forceSelection(pValue) {
      if (
        pValue &&
        !isEmpty(pValue.pes_nome) &&
        (pValue.pes_id > 0 || pValue.pes_id.length > 0)
      ) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_id: pValue.pes_id,
            pes_nome: pValue.pes_nome,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
        this.$refs.innerSuggest.validate();
      }
    },
  },
};
</script>
