<template>
  <div class="exam-user-productivity-report">
    <div class="grid">
      <div class="filter-title">
        <span class="title"> Dados do Relatório Selecionado </span>
      </div>
      <div class="tooltip-groupby">
        <Tooltip class="tooltip" clickable startOpen>
          <IconInfoHelper slot="icon" hollow />
          <div slot="content" class="content">
            <div class="title">
              <span>
                Os resultados desse relatório são agrupados por usuários e
                unidades de saúde:
              </span>
            </div>
            <div class="body">
              <span>
                No campo “Agrupar por” também é possível selecionar outros
                critérios de agrupamento dos resultados.
              </span>
            </div>
          </div>
        </Tooltip>
      </div>
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-eupr"
          v-model="form.uns_id"
          :rules="{ required: true }"
          permission="exame2.relatoriosESUSng.Exibir"
          class="inputitem"
        />

        <RgSelectSector
          id="set-id-eupr"
          v-model="form.set_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unit-health="form.uns_id"
          label="Setor"
          class="inputitem"
          @change="getSector"
        />

        <RgSelectProceduresFilter
          id="stp-id-eupr"
          ref="comboProcedures"
          v-model="form.stp_id"
          class="inputitem"
          label="Procedimento"
          :sector-id="Number(form.set_id)"
          :class="{ disable: !form.set_id }"
          :disabled="!form.set_id"
          default-text="Todos"
          @change="getProcedure"
        />

        <RgSelectPatientsQueueSubprocedures
          id="subprocedure"
          v-model="form.subprocedureId"
          :procedureName="form.stp_novo_nome_procedimento"
          :class="{ disable: !form.stp_novo_nome_procedimento }"
          :disabled="!form.stp_novo_nome_procedimento"
          @change="selectingSubProcedureQueue"
        />

        <RgComboboxHealthPlan
          id="health-insurance"
          ref="healthInsurance"
          v-model="form.healthInsurance"
          label="Convênio"
          @change="selectedHealthInsurance"
        />

        <RgSuggestCbo
          id="ocp-nome-eupr"
          ref="Cbo"
          v-model="form.ocp_nome"
          :disabled="!hasUnitHealth || !form.set_id"
          :class="{ disable: !hasUnitHealth || !form.set_id }"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          class="inputitem"
          @selected="selectedCbo"
        />

        <RgSuggestEmployee
          id="employee-request"
          ref="employeeRequest"
          v-model="employeeRequest"
          :maxlength="100"
          :vus-responsavel="false"
          label="Profissional"
          placeholder="Digite o nome do profissional"
          notNecessaryBond
          filterWithoutBond
          @selected="selectingRequestEmployee"
        />
        <RgSelectPlacesExam
          id="place"
          ref="place"
          v-model="form.exam_id_locais_atendimento"
          :disabled="!form.set_id"
          :class="{ disable: !form.set_id }"
          :sector-id="Number(form.set_id)"
          @change="selectedPlaces"
        />

        <RgSuggestUser
          id="suggest-user-eupr"
          ref="user"
          v-model="suggestUser"
          :rules="{ required: true }"
          class="inputitem"
          label="Usuário"
          @selected="selectedUser"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Período do Agendamento </span>
      </div>

      <div class="selectinput period">
        <RgInputDate
          id="initial-date-aupr"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{ fn: validateInitialDate }"
        />

        <RgInputDate
          id="final-date-aupr"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem"
          :rules="{ fn: validateFinalDate }"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Período do Procedimento Realizado </span>
      </div>

      <div class="selectinput period">
        <RgInputDate
          id="initial-date-appointment"
          v-model="form.initialDateExam"
          label="Data Inicial"
          :rules="{ required: true, fn: validateInitialDateExam }"
          class="inputitem"
        />

        <RgInputDate
          id="final-date-appointment"
          v-model="form.finalDateExam"
          label="Data Final"
          :rules="{ required: true, fn: validateFinalDateExam }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgTagCustom
          id="groupby"
          v-bind="propsTagCustom"
          @click="openModalGroupBy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgInputDate,
  RgSelectSector,
  RgSuggestCbo,
  IconInfoHelper,
  RgSuggestUser,
  Tooltip,
  RgTagCustom,
  RgSuggestEmployee,
} from "~tokio/primitive";
import RgSelectPlacesExam from "$exam/common/component/rg-select-places-exam/RgSelectPlacesExam";
import {
  RgComboboxHealthPlan,
  RgSelectPatientsQueueSubprocedures,
  RgSelectProceduresFilter,
} from "$exam/submodules/schedule/component/";
import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  uns_nome: null,
  set_id: null,
  set_nome: null,
  ocp_id: null,
  ocp_nome: null,
  usu_id: null,
  usu_nome: null,
  healthInsurance: null,
  healthInsuranceName: null,
  stp_id: null,
  subprocedureId: null,
  subprocedureName: null,
  stp_novo_nome_procedimento: null,
  exam_id_locais_atendimento: null,
  exam_id_locais_atendimento_name: null,
  employeeRequestName: null,
  employeeRequest: null,
  initialDate: null,
  finalDate: null,
  initialDateExam: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDateExam: moment().format("DD/MM/YYYY"),
};

export default {
  name: "ExamUserProductivityReport",
  components: {
    RgSelectUnithealth,
    RgInputDate,
    RgSelectSector,
    RgSelectProceduresFilter,
    RgSuggestCbo,
    IconInfoHelper,
    RgSuggestUser,
    Tooltip,
    RgTagCustom,
    RgComboboxHealthPlan,
    RgSelectPlacesExam,
    RgSuggestEmployee,
    RgSelectPatientsQueueSubprocedures,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    groupByName: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resultTitle: "Produtividade por Usuário",
      suggestUser: "",
      employeeRequest: null,
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },

  computed: {
    propsTagCustom() {
      let list = [];
      if (this.groupByName) {
        list = [{ title: this.groupByName.title, id: 1 }];
      } else {
        list = [
          {
            title: "Selecione",
            id: 1,
            color: "#fff",
            textColor: "#1E88A9",
            colorBorder: "#1E88A9",
          },
        ];
      }

      const values = [1];
      const label = "Agrupar por:";
      const labelDirection = "column";
      const hasClicker = true;
      return { list, values, label, labelDirection, hasClicker };
    },
    getNameSmartTable() {
      return "ExamUserProductivityReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    printReportCss() {
      return "@page { size: landscape; }";
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },
    getMaxDateInitial() {
      return this.form.initialDateExam
        ? new Date(
            moment(this.form.initialDateExam, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDateExam
        ? new Date(moment(this.form.initialDateExam, "DD/MM/YYYY").format())
        : null;
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue) {
        this.form.uns_nome = null;
        this.form.set_id = null;
        this.form.ocp_nome = null;
      } else if (pValue[0]) {
        this.form.uns_nome = pValue[0].text;
      }
    },
    "form.set_id"(pValue, pPrev) {
      if (!pValue) {
        this.form.exam_id_locais_atendimento = null;
        this.form.ocp_nome = null;
        this.form.stp_id = null;
      }
    },
    "form.stp_id"(pValue, pPrev) {
      if (!pValue) {
        this.form.subprocedureId = null;
      }
    },

    "form.initialDateExam"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDateExam = "";
      }

      if (pValue && !this.form.finalDateExam) {
        this.form.finalDateExam = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Usuário", key: "USUARIO", align: "left" },
      { name: "Porcentagem", key: "PERCENT", align: "right" },
      { name: "Unidade de Saúde", key: "UNS_NOME", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Profissional", key: "PROFISSIONAL", align: "left" },
      { name: "Local de Atendimento", key: "LOCAL_ATENDIMENTO", align: "left" },
      { name: "Convênio", key: "CONVENIO", align: "left" },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      { name: "SubProcedimentos", key: "SUBPROCEDIMENTOS", align: "left" },
      { name: "Data de Cadastro", key: "DATA_CADASTRO", align: "left" },
      { name: "Data de Agendamento", key: "DATA_EXAME", align: "left" },
    ];
  },

  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.form.uns_nome = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },

  methods: {
    openModalGroupBy() {
      this.$emit("openModalGroupBy");
    },
    buildFilter() {
      return {
        ...this.form,
        healthInsurance: Number(this.form.healthInsurance),
        exam_id_locais_atendimento: Number(
          this.form.exam_id_locais_atendimento,
        ),
        initialDate: this.form.initialDate
          ? this.$utils.date.BrazilianDateToDatabase(this.form.initialDate)
          : null,
        finalDate: this.form.finalDate
          ? this.$utils.date.BrazilianDateToDatabase(this.form.finalDate)
          : null,
        initialDateExam: this.$utils.date.BrazilianDateToDatabase(
          this.form.initialDateExam,
        ),
        finalDateExam: this.$utils.date.BrazilianDateToDatabase(
          this.form.finalDateExam,
        ),
        groupById: this.groupByName?.id,
        groupByName: this.groupByName?.title,
        columnsToPrint: this.columnsToPrint,
      };
    },

    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Exam/Report/GET_EXAM_USER_PRODUCTIVITY_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
    },

    getProcedure(pValue) {
      if (pValue !== null) {
        this.form.stp_novo_nome_procedimento = pValue.label;
      }
    },

    selectedCbo(pCbo) {
      this.form.ocp_id = pCbo.source?.ocp_id || null;
      this.form.ocp_nome = pCbo.source?.ocp_nome || null;
    },

    selectedUser(pUser) {
      this.form.usu_id = pUser.source?.usu_id || null;
      this.form.usu_nome = pUser.source?.usu_nome || null;
    },
    selectedPlaces(pValue) {
      this.form.exam_id_locais_atendimento_name = pValue?.label;
    },

    selectingSubProcedureQueue(pValue) {
      this.form.subprocedureName = pValue?.label || null;
    },
    selectingRequestEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.employeeRequest =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;
        this.form.employeeRequestName = pes_nome;
      } else {
        this.form.employeeRequest = null;
        this.form.employeeRequestName = null;
      }
    },
    selectedHealthInsurance(pValue) {
      this.form.healthInsuranceName = pValue ? pValue[0].text : null;
    },
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    validateInitialDateExam(pValue, pErrors) {
      const initialDate = moment(this.form.initialDateExam, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDateExam, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDateExam(pValue, pErrors) {
      const initialDate = moment(this.form.initialDateExam, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDateExam, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.suggestUser = null;
      this.suggestCbo = "";
      this.employeeRequest = null;
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      this.form.uns_nome = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

      if (this.$refs.occupation) {
        this.$refs.occupation.cleanValidate();
      }

      if (this.$refs.user) {
        this.$refs.user.cleanValidate();
      }

      this.$emit("cleanGroupBy", true);
    },
  },
};
</script>
