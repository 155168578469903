<template lang="html">
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.998 15.0487C19.998 17.8102 17.7593 20.0489 14.998 20.0489C12.2369 20.0489 9.99805 17.8102 9.99805 15.0487C9.99805 12.2876 12.2369 10.0488 14.998 10.0488C17.7593 10.0488 19.998 12.2876 19.998 15.0487ZM12.9999 13.5001C12.9999 13.2239 13.2237 13.0001 13.4999 13.0001C13.776 13.0001 13.9999 13.2239 13.9999 13.5001V16.5001C13.9999 16.7762 13.776 17.0001 13.4999 17.0001C13.2237 17.0001 12.9999 16.7762 12.9999 16.5001V13.5001ZM15.9999 13.5001C15.9999 13.2239 16.2237 13.0001 16.4999 13.0001C16.776 13.0001 16.9999 13.2239 16.9999 13.5001V16.5001C16.9999 16.7762 16.776 17.0001 16.4999 17.0001C16.2237 17.0001 15.9999 16.7762 15.9999 16.5001V13.5001Z"
      fill="white"
    />
    <path
      d="M2.49998 3.12507V0.625006C2.49998 0.279507 2.77949 0 3.12507 0C3.47049 0 3.75 0.279507 3.75 0.625006V3.12507C3.75 3.47049 3.47049 3.75 3.12507 3.75C2.77949 3.75004 2.49998 3.47049 2.49998 3.12507ZM13.1251 3.75004C13.4705 3.75004 13.7501 3.47053 13.7501 3.12511V0.625006C13.7501 0.279507 13.4705 0 13.1251 0C12.7796 0 12.4999 0.279507 12.4999 0.625006V3.12507C12.4999 3.47049 12.7796 3.75004 13.1251 3.75004ZM5.00005 7.50004H2.49998V10H5.00005V7.50004ZM2.49998 13.7501H5.00005V11.25H2.49998V13.7501ZM6.24998 10H8.75009V7.50004H6.24998V10ZM6.24998 13.7501H8.75009V11.25H6.24998V13.7501ZM1.25005 14.8756V6.24998H15V8.75005H16.2501V3.8746C16.2501 3.1153 15.6445 2.49998 14.8963 2.49998H14.375V3.12511C14.375 3.81352 13.8146 4.37509 13.1252 4.37509C12.4355 4.37509 11.8752 3.81352 11.8752 3.12511V2.49998H4.37496V3.12511C4.37496 3.81352 3.81467 4.37509 3.12511 4.37509C2.43539 4.37509 1.87506 3.81352 1.87506 3.12511V2.49998H1.35438C0.606169 2.49998 0 3.1153 0 3.8746V14.8757C0 15.6325 0.606169 16.2502 1.35438 16.2502H8.75009V15H1.35438C1.29774 14.9999 1.25005 14.9414 1.25005 14.8756ZM12.4999 10V7.50004H10.0001V10H12.4999Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "InactiveSchedule",
  props: {
    disabled: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "#1E88A9",
    },
  },
};
</script>
