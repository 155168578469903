import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PreRegistration from "$emergency/pre-registration/views/PreRegistration.vue";

export default {
  path: "/emergency/pre-registration",
  name: "emergency.pre-registration",
  components: {
    default: PreRegistration,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Atendimento", link: OpenSubModule("emergency") },
      { label: "Pré-Atendimento", link: "/emergency/pre-registration" },
    ],
  },
};
