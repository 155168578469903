import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPersonById(
    $pesId: Int!
    $withPatientAppointment: Boolean!
    $withPatientExam: Boolean!
    $withPatientHospitalization: Boolean!
    $withPatientEmergency: Boolean!
    $withTelephone: Boolean!
    $withEmergency: Boolean!
    $withHospitalization: Boolean!
    $withEthnicity: Boolean!
    $withNaturality: Boolean!
    $withNationality: Boolean!
    $unitHealth: Int
  ) {
    people(pes_id: $pesId, uns_id: $unitHealth) {
      rows {
        pes_id
        pes_nome
        pes_mae
        pes_pai
        pes_nascimento
        pes_id_locais_atendimento_pleni
        gender {
          sex_id
          sex_sigla
        }
        patient {
          pac_id
          pac_prontuario_unico
          pac_ativo
          pac_id_motivo_inabilitacao
          record_numbers {
            rows {
              ppr_numero
              ppr_id_unidades_saude
            }
          }
          patient_exam @include(if: $withPatientExam) {
            pxe_id
            pxe_id_pacientes
          }
          patient_appointment @include(if: $withPatientAppointment) {
            pcc_id_pacientes
            pcc_id
            pcc_ativo
          }
          patient_hospitalization @include(if: $withPatientHospitalization) {
            pci_id_pacientes
            pci_id
            pci_ativo
            hospitalization @include(if: $withHospitalization) {
              int_id
              int_numero
            }
          }
          patient_emergency @include(if: $withPatientEmergency) {
            pce_id_pacientes
            pce_id
            pce_ativo
            bulletin @include(if: $withEmergency) {
              bol_id
              bol_numero
              bol_id_pacientes_emergencia
              bol_id_setores
              sector {
                set_nome
              }
            }
          }
        }
        cns {
          crs_id
          crs_numero
        }
        cpf {
          cpf_id
          cpf_numero
        }
        address {
          end_logradouro
          end_cep
          end_numero
          end_complemento
          neighborhood {
            bai_id
            bai_nome
            city {
              mun_nome
              state {
                est_id
              }
            }
          }
          publicPlaceType {
            tlg_id
            tlg_nome
          }
        }
        telephones @include(if: $withTelephone) {
          rows {
            tel_id
            tel_id_pessoas
            tel_id_ddds
            tel_numero
            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
        ethnicity @include(if: $withEthnicity) {
          etn_id
          etn_nome
          etn_codigo_sisaih
        }
        city @include(if: $withNaturality) {
          mun_id
          mun_nome
          state {
            est_id
            est_sigla
            est_nome
          }
        }
        nationality @include(if: $withNationality) {
          nac_id
          nac_nome
        }
      }
    }
  }
`;

export default async (variables) => {
  variables.withPatientAppointment = variables.withPatientAppointment
    ? variables.withPatientAppointment
    : false;
  variables.withEmergency = variables.withEmergency
    ? variables.withEmergency
    : false;
  variables.withHospitalization = variables.withHospitalization
    ? variables.withHospitalization
    : false;
  variables.withPatientExam = variables.withPatientExam
    ? variables.withPatientExam
    : false;
  variables.withPatientHospitalization = variables.withPatientHospitalization
    ? variables.withPatientHospitalization
    : false;
  variables.withPatientEmergency = variables.withPatientEmergency
    ? variables.withPatientEmergency
    : false;

  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  if (data.people.rows.length < 1) return null;

  return data.people.rows[0];
};
