<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.006 13.1783C10.006 13.5307 9.80245 13.8507 9.48387 14.0001C9.36069 14.0584 9.2287 14.0866 9.09757 14.0866C8.88992 14.0866 8.68419 14.0152 8.51802 13.8774L3.55274 9.76294H0.908231C0.406716 9.76337 0 9.35644 0 8.85493V5.2364C0 4.73467 0.406716 4.32796 0.908231 4.32796H3.55296L8.51824 0.21351C8.78953 -0.011344 9.16572 -0.0592814 9.48408 0.0911947C9.80245 0.240596 10.0062 0.560895 10.0062 0.91301L10.006 13.1783Z"
      fill="white"
    />
    <rect
      x="20.7734"
      y="3"
      width="1.73563"
      height="10.9923"
      rx="0.867813"
      transform="rotate(45 20.7734 3)"
      fill="white"
    />
    <rect
      width="1.73563"
      height="10.9923"
      rx="0.867813"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 14.2266 3)"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "EndCall",
};
</script>
