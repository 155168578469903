import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import SearchPatientSchedule from "$appointment/submodules/schedule/views/search-patient-schedule/SearchPatientSchedule";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/patient-schedule",
  name: "appointment.schedule.patient-schedule",
  components: {
    default: SearchPatientSchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Regulação", link: OpenSubModule("appointment") },
      {
        label: "Agendados pela Fila",
        link: "/appointment/schedule/patient-schedule",
      },
    ],
  },
};
