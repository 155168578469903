<template>
  <div class="appointment-patients-schedule-queue-report">
    <section class="grid">
      <div class="filter-title">
        <span class="title"> Dados do Relatório Selecionado </span>
      </div>

      <RgSelectUnithealth
        id="unit-requesting"
        v-model="form.unitHealthRequest"
        :default-text="'Todas'"
        :permission="permissionScreen"
        label="Unidade de Saúde Solicitante"
        @change="selectingUnitHealthRequest"
      />
      <RgSelectUnithealth
        id="unit-requesting"
        v-model="form.unitHealth"
        :default-text="'Todas'"
        :permission="permissionScreen"
        label="Unidade de Saúde Destino"
        @change="selectingUnitHealth"
      />

      <RgSelectOccupationSynonymsUnit
        id="occupation"
        ref="cbo"
        v-model="occupation"
        with-bond
        placeholder="Digite a ocupação"
        label="Ocupação"
        :healthUnitId="form.unitHealth"
        @change="selectingCbo"
      />
      <RgSelectPriorityQueue
        id="priority"
        v-model="form.priority"
        :module-id="appointmentModuleId"
        label="Prioridade"
        default-text="Todas"
        class="inputitem"
        @change="selectingPriority"
      />
      <RgSuggestEmployee
        id="employee-request"
        ref="employeeRequest"
        v-model="employeeRequest"
        :maxlength="100"
        :vus-responsavel="false"
        label="Profissional Solicitante"
        placeholder="Digite o nome do profissional"
        notNecessaryBond
        filterWithoutBond
        @selected="selectingRequestEmployee"
      />
      <RgSuggestState
        id="state"
        ref="state"
        v-model="form.est_sigla"
        label="Estado"
        placeholder="Digite o estado"
        class="inputitem state"
        @selected="selectingState"
      />
      <RgSuggestCity
        id="city"
        v-model="form.mun_nome"
        :class="{ disable: disableCountry }"
        :disabled="disableCountry"
        :state="form.est_id"
        label="Município"
        placeholder="Digite o município"
        class="inputitem city"
        @selected="selectingMunicipality"
      />

      <RgSuggestUser
        id="user-origin"
        ref="user"
        v-model="userOrigin"
        label="Usuário da Inserção na Fila"
        @selected="selectingUserOrigin"
      />

      <RgSuggestUser
        id="user-exclusion"
        ref="user"
        v-model="userExclusion"
        label="Usuário da Exclusão"
        @selected="selectingUserExclusion"
      />

      <div class="filter-title">
        <span class="title"> Dados do Paciente </span>
      </div>

      <RgInput
        id="patient"
        ref="patient"
        v-model="form.patientName"
        :disabled="!!form.patient"
        :class="{ disable: !!form.patient }"
        placeholder="Digite o nome do paciente"
        label="Paciente"
      />

      <div class="selectinput">
        <RgSuggestSmartPerson
          id="documentation-patient"
          ref="patient"
          v-model="documentPatient"
          :disabled="disabledDocument"
          :with-patient-module="permissionSuggest"
          :enabled-patient="false"
          filterOnlyDocument
          @selected="selectingPatient"
        />
      </div>
      <div class="selectinput-gender">
        <RgRadioCustom
          id="gender"
          ref="gender"
          v-bind="propsRadioCustom"
          multSelect
          label="Sexo"
          @input="onInputRadioCustom"
        />
      </div>
      <div class="selectinput-age">
        <RgInputAge id="age" ref="age" @input="inputAge" />
      </div>

      <div class="filter-title">
        <span class="title"> Período de inclusão na fila </span>
      </div>
      <div class="period-date">
        <RgInputDate
          id="initial-date-entry"
          v-model="form.entryPeriodDateStart"
          :rules="{ fn: verifyEntryPeriodDate }"
          label="Data Inicial"
          popUp="top"
        />
        <RgInputDate
          id="final-date-entry"
          v-model="form.entryPeriodDateEnd"
          label="Data Final"
          popUp="top"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Período de exclusão da fila </span>
      </div>
      <div class="period-date">
        <RgInputDate
          id="initial-date-exclusion"
          v-model="form.exclusionPeriodDateStart"
          :rules="{ fn: verifyExclusionPeriodDate }"
          label="Data Inicial"
          popUp="top"
        />
        <RgInputDate
          id="final-date-exclusion"
          v-model="form.exclusionPeriodDateEnd"
          label="Data Final"
          popUp="top"
        />
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";

import {
  RgInput,
  RgInputDate,
  RgSelectUnithealth,
  RgSelectPriorityQueue,
  RgRadioCustom,
  RgInputAge,
} from "~tokio/primitive";
import { mapGetters } from "vuex";

import RgSuggestEmployee from "~tokio/primitive/suggest/rg-suggest-employee/RgSuggestEmployee";
import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson";
import RgSuggestUser from "~tokio/primitive/suggest/rg-suggest-user/RgSuggestUser.vue";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import { RgSuggestCity } from "$patient/common/components";
import RgSelectOccupationSynonymsUnit from "$appointment/common/component/rg-select-occupation-synonyms-unit/RgSelectOccupationSynonymsUnit";

const FORM_FILTER = {
  unitHealth: null,
  unitHealthName: null,
  userOriginId: null,
  userOriginName: null,
  userExclusionId: null,
  userExclusionName: null,
  unitHealthRequest: null,
  unitHealthRequestName: null,
  employeeRequest: null,
  employeeRequestName: null,
  patient: null,
  patientName: null,
  occupation: null,
  occupationName: null,
  priority: null,
  priorityName: null,
  est_sigla: null,
  mun_nome: null,
  mun_id: null,
  est_id: null,
  gender: null,
  entryPeriodDateStart: moment().add(-3, "months").format("DD/MM/YYYY"),
  entryPeriodDateEnd: moment().format("DD/MM/YYYY"),
  exclusionPeriodDateStart: moment().add(-3, "months").format("DD/MM/YYYY"),
  exclusionPeriodDateEnd: moment().format("DD/MM/YYYY"),
  columnsToPrint: null,
  maxAge: 0,
  minAge: 0,
};

export default {
  name: "AppointmentPatientsExcludedQueueReport",
  components: {
    RgInput,
    RgInputDate,
    RgSelectUnithealth,
    RgSuggestSmartPerson,
    RgSuggestEmployee,
    RgSuggestUser,
    RgSelectPriorityQueue,
    RgSuggestState,
    RgSuggestCity,
    RgSelectOccupationSynonymsUnit,
    RgRadioCustom,
    RgInputAge,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Pacientes Excluídos pela Fila",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      disabledDocument: false,
      employeeDestiny: null,
      user: null,
      documentPatient: null,
      userExclusion: null,
      userOrigin: null,
      occupation: null,
      employeeRequest: null,
      professionalBond: null,
    };
  },
  computed: {
    disableCountry() {
      return !this.form.est_id;
    },
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.form.gender;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
    }),
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }"
      );
    },
    getNameSmartTable() {
      return "AppointmentPatientsExcludedQueueReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    moduleId() {
      return this.$utils.app.ModulesId.APPOINTMENT;
    },
    permissionScreen() {
      return "consulta.pacientesExcluidosFilaConsulta";
    },
    permissionSuggest() {
      return ["appointment"];
    },
  },
  watch: {
    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disabledDocument = true;
      } else {
        this.disabledDocument = false;
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Idade", key: "IDADE", align: "right" },
      { name: "Sexo", key: "SEXO_NOME", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Prioridade", key: "PRIORIDADE", align: "right" },
      {
        name: "Unidade de Saúde Solicitante",
        key: "UNIDADE_SAUDE_SOLICITANTE",
        align: "left",
      },
      {
        name: "Profissional Solicitante",
        key: "PROFISSIONAL_SOLICITANTE",
        align: "left",
      },
      { name: "Estado", key: "ESTADO", align: "left" },
      { name: "Municipio", key: "MUNICIPIO", align: "left" },
      { name: "Data de Inserção", key: "DATA_INSERCAO", align: "left" },
      { name: "Data de Exclusão", key: "DATA_EXCLUSAO", align: "left" },
      {
        name: "Motivo da Exclusão",
        key: "MOTIVO_EXCLUSAO",
        align: "left",
      },
      { name: "Usuário da Exclusão", key: "USUARIO_EXCLUSAO", align: "left" },
      {
        name: "Unidade de Saúde Destino",
        key: "UNIDADE_SAUDE_ORIGEM",
        align: "left",
      },
      {
        name: "Usuário da inserção na fila",
        key: "USUARIO_ORIGEM",
        align: "left",
      },
    ];
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.gender = item;
      }
    },
    inputAge(item) {
      if (item) {
        this.form.maxAge = item.maxInputValue;
        this.form.minAge = item.minInputValue;
      }
    },
    prepareGender() {
      const hasValue = this.form.gender !== "";
      return hasValue
        ? this.$utils.app.Genders.GetIdByInitials(this.form.gender)
        : null;
    },

    buildFilter() {
      const SUBMODULE_ID = 246;
      const genders = this.prepareGender();

      const payload = {
        unitHealth: this.form.unitHealth,
        unitHealthName: this.form.unitHealthName,
        unitHealthRequest: this.form.unitHealthRequest,
        unitHealthRequestName: this.form.unitHealthRequestName,
        employeeRequest: this.form.employeeRequest,
        employeeRequestName: this.form.employeeRequestName,
        patient: this.form.patient,
        patientName: this.form.patientName,
        entryPeriodDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.entryPeriodDateStart,
        ),
        entryPeriodDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.entryPeriodDateEnd,
        ),
        exclusionPeriodDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.exclusionPeriodDateStart,
        ),
        exclusionPeriodDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.exclusionPeriodDateEnd,
        ),
        userOriginId: this.form.userOriginId,
        userOriginName: this.form.userOriginName,
        userExclusionId: this.form.userExclusionId,
        userExclusionName: this.form.userExclusionName,
        occupation: this.form.occupation,
        occupationName: this.form.occupationName,
        priority: this.form.priority,
        priorityName: this.form.priorityName,
        est_sigla: this.form.est_sigla,
        est_id: this.form.est_id,
        mun_nome: this.form.mun_nome,
        mun_id: this.form.mun_id,
        gender: genders,
        maxAge: this.form.maxAge,
        minAge: this.form.minAge,
        columnsToPrint: this.columnsToPrint,
        smd_id: SUBMODULE_ID,
        loggedUserId: this.userLoginId,
      };

      return payload;
    },

    async doSearch(pData) {
      try {
        this.$loader.start("Carregando...");

        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_PATIENTS_EXCLUDED_QUEUE_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish();
      }
    },

    selectingUnitHealth(pValue) {
      if (pValue) {
        this.form.unitHealthName = pValue.label;
      } else {
        this.form.unitHealthName = null;
      }
    },
    selectingPriority(pValue) {
      if (pValue) {
        this.form.priorityName = pValue.label;
      } else {
        this.form.priorityName = null;
      }
    },
    selectingUserOrigin(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.userOriginId = usu_id;
        this.form.userOriginName = usu_nome;
      } else {
        this.form.userOriginId = 0;
        this.form.userOriginName = null;
      }
    },
    selectingUserExclusion(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.userExclusionId = usu_id;
        this.form.userExclusionName = usu_nome;
      } else {
        this.form.userExclusionId = 0;
        this.form.userExclusionName = null;
      }
    },

    selectingUnitHealthRequest(pValue) {
      if (pValue) {
        this.form.unitHealthRequestName = pValue.label;
      } else {
        this.form.unitHealthRequestName = null;
      }
    },

    selectingRequestEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.employeeRequest =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;
        this.form.employeeRequestName = pes_nome;
        this.professionalBond =
          pValue.source?.employeeRelationship?.vin_id_funcionarios;
      } else {
        this.form.employeeRequest = null;
        this.form.employeeRequestName = null;
        this.professionalBond = null;
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source && pValue.source.patient) {
        const { pac_id } = pValue.source.patient;
        this.form.patient = pac_id;
      } else {
        this.form.patient = null;
      }
    },

    verifyEntryPeriodDate(pValue, pErrors) {
      const initialDate = moment(this.form.entryPeriodDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.entryPeriodDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    verifyExclusionPeriodDate(pValue, pErrors) {
      const initialDate = moment(
        this.form.exclusionPeriodDateStart,
        "DD/MM/YYYY",
      );
      const finalDate = moment(this.form.exclusionPeriodDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    selectingCbo(pValue) {
      if (pValue) {
        this.form.occupationName = pValue.label;
        this.form.occupation = Number(pValue.value);
      } else {
        this.form.occupationName = "";
        this.form.occupation = null;
      }
    },

    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.est_id = pValue.source.est_id;
      } else {
        this.form.est_id = null;
        this.form.mun_nome = null;
      }
    },

    selectingMunicipality(pValue) {
      if (pValue && pValue.source) {
        this.form.mun_id = pValue.source.mun_id;
      } else {
        this.form.mun_id = null;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.disabledDocument = false;
      this.employeeDestiny = null;
      this.user = null;
      this.documentPatient = null;
      this.userOrigin = null;
      this.userExclusion = null;
      this.occupation = null;
      this.employeeRequest = null;
      this.professionalBond = null;
      if (this.$refs.age) this.$refs.age.cleanValues();
      if (this.$refs.gender) this.$refs.gender.clearRadio();
    },
  },
};
</script>
