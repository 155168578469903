<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import SearchBasicAttentionAreasTeams from "./action/SearchBasicAttentionAreasTeams";

export default {
  name: "RgSelectBasicAttentionAreaTeam",
  extends: RgSelect,
  props: {
    unitHealth: {
      default: null,
    },
    label: {
      type: String,
      default: "Área/Equipe",
    },
  },
  data() {
    return {
      areas: [],
    };
  },
  watch: {
    areas: function (val) {
      this.updateOptions();
    },

    unitHealth(pValue) {
      this.mValue = null;
      this.doSearch();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.areas;
    },

    async doSearch() {
      if (!this.unitHealth) return Promise.resolve([]);

      this.data = [];
      const unitHealthId = Number(this.unitHealth);
      const data = await SearchBasicAttentionAreasTeams({ unitHealthId });

      this.areas = data.map((item) => {
        return {
          value: item.are_id,
          label: `${item.areaTeam}`,
          teamId: item.equ_id,
          equ_codigo_ine: item.equ_codigo_ine,
          unitHealthCnes: item.uns_cnes,
        };
      });
    },

    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
