import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchSpecialtiesAttendancesCountReport(
    $uns_id: Int!
    $initialDate: String!
    $finalDate: String!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchSpecialtiesAttendancesCountReport(
      uns_id: $uns_id
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        esp_id
        esp_nome
        uns_nome
        set_nome
        total
      }
    }
  }
`;

export default async (
  { commit },
  {
    uns_id,
    initialDate,
    finalDate,
    toPrint,
    toCsv,
    toExcel,
    limit,
    offset,
    columnsToPrint,
  },
) => {
  try {
    const variables = {
      uns_id,
      initialDate,
      finalDate,
      toPrint,
      toCsv,
      toExcel,
      limit,
      offset,
      columnsToPrint,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchSpecialtiesAttendancesCountReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
