<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10778 11.8915C8.44294 12.2266 8.98468 12.2266 9.31984 11.8915L15.5713 5.64002L17.5368 7.60554C17.7828 7.85069 18.1505 7.92613 18.4711 7.79155C18.7908 7.65954 19 7.34581 19 6.99951L19 1.85641C19 1.38239 18.616 0.999231 18.1428 0.999231L12.9997 0.999231C12.6534 0.999231 12.3405 1.20753 12.2077 1.52811C12.0748 1.8487 12.1485 2.21729 12.3937 2.46244L14.3592 4.42796L8.10778 10.6794C7.77262 11.0146 7.77262 11.5563 8.10778 11.8915Z"
      fill="white"
    />
    <path
      d="M15.5712 18.9989H3.57139C2.1537 18.9989 1 17.8452 1 16.4275V4.42771C1 3.01002 2.1537 1.85632 3.57139 1.85632H10.4284C10.9016 1.85632 11.2855 2.23946 11.2855 2.71345C11.2855 3.18744 10.9016 3.57058 10.4284 3.57058H3.57139C3.09911 3.57058 2.71426 3.95457 2.71426 4.42771V16.4275C2.71426 16.9007 3.09911 17.2846 3.57139 17.2846H15.5712C16.0435 17.2846 16.4283 16.9007 16.4283 16.4275V9.57048C16.4283 9.09649 16.8123 8.71335 17.2854 8.71335C17.7586 8.71335 18.1426 9.09649 18.1426 9.57048V16.4275C18.1426 17.8452 16.9889 18.9989 15.5712 18.9989Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ExitPharmacy",
};
</script>
