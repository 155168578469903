<template>
  <div v-show="show" class="modal-upload-import-sigtap-bdsia">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-history-movement-header">
        <span class="title"> Importação Manual das Tabelas SIGTAP/BDSIA </span>
      </div>
      <div slot="body" class="body-upload-sigtap-bdsia">
        <div class="content">
          Faça o download dos arquivos SIGTAP e BDSIA na página do <br />
          <a
            class="link"
            title="http://sigtap.datasus.gov.br/tabela-unificada/app/download.jsp"
            href="http://sigtap.datasus.gov.br/tabela-unificada/app/download.jsp"
            target="_blank"
          >
            DataSUS
            <font-awesome-icon
              :icon="['fas', 'arrow-up-right-from-square']"
              class="icon"
            />
          </a>
          e
          <a
            class="link"
            title="http://sia.datasus.gov.br/versao/listar_ftp_sia.php"
            href="http://sia.datasus.gov.br/versao/listar_ftp_sia.php"
            target="_blank"
          >
            SIASUS
            <font-awesome-icon
              :icon="['fas', 'arrow-up-right-from-square']"
              class="icon"
            />
          </a>
          para realizar manualmente a <br />
          importação das tabelas.
        </div>
        <FormBase
          title="Adicionar Arquivos"
          class="form-base-upload-sigtap-bdsia"
        >
          <div class="tooltips">
            <Tooltip class="tooltip-print">
              <IconInfoHelper
                slot="icon"
                style="width: 28.5px; height: 28.5px"
              />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Norma do DataSUS: os arquivos são enviados sem qualquer tipo
                    de alteração.
                  </span>
                </div>
              </div>
            </Tooltip>
            <Tooltip class="tooltip-print">
              <IconLightBulbHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Os arquivos SIGTAP e BDSIA devem ser do mesmo
                    mês/competência.
                  </span>
                </div>
              </div>
            </Tooltip>
          </div>
          <RgValidatorForm ref="validator" @submit.stop.prevent="submitFile">
            <div class="content-input-file">
              <div>
                <input
                  ref="fileSigtap"
                  class="input-file"
                  type="file"
                  @change="changeFileSigtap"
                />
                <label class="input-label">
                  <RgInput
                    ref="inputSigtap"
                    v-model="fileInfoSigtap.fileSize"
                    disabled
                    :needRemoveSpecialStrings="false"
                    :isUpperCase="false"
                    :rules="{ fn: checkFileSigtap }"
                    label="Enviar nova tabela SIGTAP"
                    placeholder="Selecione o arquivo “.zip” do seu computador"
                  />
                </label>
              </div>
              <RgAddButton
                class="new"
                type="button"
                @click="$refs.fileSigtap.click()"
              />
            </div>
            <div class="content-input-file">
              <div>
                <input
                  ref="fileBdsia"
                  class="input-file"
                  type="file"
                  @change="changeFileBdsia"
                />
                <label class="input-label">
                  <RgInput
                    ref="inputBdsia"
                    v-model="fileInfoBdsia.fileSize"
                    disabled
                    :needRemoveSpecialStrings="false"
                    :isUpperCase="false"
                    :rules="{ fn: checkFileBdsia }"
                    label="Enviar nova tabela BDSIA"
                    placeholder="Selecione o arquivo “.exe” do seu computador"
                  />
                </label>
              </div>
              <RgAddButton
                class="new"
                type="button"
                :disabled="!fileSigtap"
                @click="$refs.fileBdsia.click()"
              />
            </div>
            <hr />
            <div class="container-clear">
              <RgCleanButton
                large
                type="button"
                class="clean unselect"
                title="Limpar Arquivos"
                @click="clear"
              />
            </div>
          </RgValidatorForm>
        </FormBase>
        <div slot="footer" class="modal-upload-import-sigtap-bdsia-footer">
          <RgCancelButton
            id="cancel-btn"
            label="Cancelar"
            medium
            @click="close"
          />
          <MediumButton
            id="save-btn"
            label="Prosseguir"
            medium
            :disabled="!fileSigtap || !fileBdsia"
            @click="submitFile"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgInput,
  RgAddButton,
  RgCleanButton,
  MediumButton,
  RgCancelButton,
  RgValidatorForm,
  Tooltip,
  IconInfoHelper,
  IconLightBulbHelper,
} from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";
import moment from "moment";

export default {
  name: "ModalUploadSigtapBdsia",
  components: {
    RgBaseModal,
    RgInput,
    RgAddButton,
    FormBase,
    RgCleanButton,
    MediumButton,
    RgCancelButton,
    RgValidatorForm,
    Tooltip,
    IconInfoHelper,
    IconLightBulbHelper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showOld: {
      type: Function,
      default: () => {},
    },
    initProcedure: Function,
    typeImportProcedure: Function,
  },
  data() {
    return {
      fileInfoSigtap: {
        fileName: "",
        fileSize: "",
        size: 0,
      },
      fileInfoBdsia: {
        fileName: "",
        fileSize: "",
        size: 0,
      },
      fileSigtap: null,
      fileBdsia: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    changeFileSigtap() {
      const file = this.$refs.fileSigtap.files[0];
      this.fileInfoSigtap.fileName = file.name;
      this.fileInfoSigtap.fileSize = `${file.name} - (${Math.round(
        file.size / 1024,
      )} KB)`;
      this.fileInfoSigtap.size = Math.round(file.size / 1024);
      this.fileSigtap = file;
      this.$refs.inputSigtap.blur();
    },
    changeFileBdsia() {
      const file = this.$refs.fileBdsia.files[0];
      this.fileInfoBdsia.fileName = file.name;
      this.fileInfoBdsia.fileSize = `${file.name} - (${Math.round(
        file.size / 1024,
      )} KB)`;
      this.fileInfoBdsia.size = Math.round(file.size / 1024);
      this.fileBdsia = file;
      this.$refs.inputBdsia.blur();
    },
    checkFileSigtap(pValue, pErrors) {
      const fileNameSigtap = this.fileInfoSigtap.fileName;
      const fileSigtap = this.fileSigtap.file;

      if (
        (fileNameSigtap === "" && !fileSigtap) ||
        !fileNameSigtap.includes(".zip")
      ) {
        pErrors.push(`Selecione o arquivo SIGTAP no formato “.zip”`);
        return false;
      }

      const cmp = this.fileInfoSigtap.fileName.split("_")[1];

      if (
        this.fileInfoSigtap.size < 900 ||
        Number(cmp) > Number(moment().format("YYYYMM")) ||
        Number(cmp) < 201102
      ) {
        pErrors.push(`Selecione um arquivo SIGTAP válido`);
        return false;
      }

      return true;
    },
    checkFileBdsia(pValue, pErrors) {
      const fileNameBdsia = this.fileInfoBdsia.fileName;
      const fileBdsia = this.fileBdsia.file;
      const cmp = this.fileInfoSigtap.fileName.split("_")[1];

      if (
        (fileNameBdsia === "" && !fileBdsia) ||
        !fileNameBdsia.includes(".exe")
      ) {
        pErrors.push(`Selecione o arquivo BDSIA no formato “.exe”`);
        return false;
      }

      if (!fileNameBdsia.includes(cmp)) {
        pErrors.push(`Os arquivos devem ser do mesmo mês/competência`);
        return false;
      }

      const cmpBdsia = this.fileInfoBdsia.fileName
        .split("BDSIA")[1]
        .split(".exe")[0]
        .substring(0, 6);

      if (
        this.fileBdsia.size < 3000 ||
        Number(cmpBdsia) > Number(moment().format("YYYYMM")) ||
        Number(cmpBdsia) < 201102
      ) {
        pErrors.push(`Selecione um arquivo BDSIA válido`);
        return false;
      }

      return true;
    },
    async submitFile() {
      const valid = await this.isFormValid();
      if (!valid) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }
      if (!this.fileSigtap || !this.fileBdsia) {
        this.$toaster.error("Nenhum arquivo selecionado");
      } else {
        this.$store.commit(
          "Billing/ImportBdsiaSigtap/SET_DATA_IMPORT_SIGTAP_BDSIA",
          {
            name_file_sigtap: this.fileInfoSigtap.fileName,
            name_file_bdsia: this.fileInfoBdsia.fileName,
            cmp_import_sigtap: this.fileInfoSigtap.fileName.split("_")[1],
            cmp_import_bdsia: this.fileInfoSigtap.fileName.split("_")[1],
          },
        );
        this.typeImportProcedure(true);
        this.initProcedure({
          fileSigtap: this.fileSigtap,
          fileBdsia: this.fileBdsia,
          fileNameSigtap: this.fileInfoSigtap.fileName,
          fileNameBdsia: this.fileInfoBdsia.fileName,
        });
        this.showOld(2);
      }
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    clear() {
      this.fileInfoSigtap.fileName = "";
      this.fileInfoSigtap.fileSize = "";
      this.fileInfoBdsia.fileName = "";
      this.fileInfoBdsia.fileSize = "";
      this.fileSigtap = null;
      this.fileBdsia = null;
      this.$refs.fileSigtap.files = null;
      this.$refs.fileBdsia.files = null;
      this.$refs.fileSigtap.value = null;
      this.$refs.fileBdsia.value = null;
    },
    close() {
      this.typeImportProcedure(false);
      this.showOld(0);
      this.clear();
    },
  },
};
</script>
