import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBioslabIntegrationsError(
    $inb_id_laboratoriais_exames_bioslab: Int
  ) {
    SearchBioslabIntegrationsError(
      inb_id_laboratoriais_exames_bioslab: $inb_id_laboratoriais_exames_bioslab
    ) {
      rows {
        inb_id
        inb_mensagem_erro
      }
    }
  }
`;

export default async ({ commit }, inb_id_laboratoriais_exames_bioslab) => {
  const { data } = await GraphQLClient.query({
    query,
    variables: { inb_id_laboratoriais_exames_bioslab },
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabIntegrationsError.rows;
};
