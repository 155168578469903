<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4737 61.8108H50.5263C52.4415 61.8108 54 60.2522 54 58.3371V49.0739V37.9615V26.8492V15.7368V6.47368C54 4.55853 52.4415 3 50.5263 3H13.4737C11.5585 3 10 4.55853 10 6.47368V15.7368V26.8492V37.9615V49.0739V58.3371C10 60.2522 11.5585 61.8108 13.4737 61.8108ZM12.3158 16.8947V25.6913H30.84V16.8947H12.3158ZM33.1558 16.8947V25.6913H51.6842V16.8947H33.1558ZM51.6842 14.5789H33.1558V5.31579H50.5263C51.1632 5.31579 51.6842 5.83453 51.6842 6.47368V14.5789ZM30.84 14.5789H12.3158V6.47368C12.3158 5.83453 12.8368 5.31579 13.4737 5.31579H30.84V14.5789ZM30.84 28.0071H12.3158V36.8037H30.84V28.0071ZM51.6842 28.0071H33.1558V36.8037H51.6842V28.0071ZM12.3158 39.1194V47.916H30.84V39.1194H12.3158ZM33.1558 39.1194V47.916H51.6842V39.1194H33.1558ZM30.84 50.2318H12.3158V58.3371C12.3158 58.9762 12.8368 59.495 13.4737 59.495H30.84V50.2318ZM51.6842 50.2318H33.1558V59.495H50.5263C51.1632 59.495 51.6842 58.9762 51.6842 58.3371V50.2318Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "ForRegulation",
};
</script>
