<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchProvider from "./action/SearchProvider";

export default {
  name: "RgSelectProvider",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Fornecedor",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Digite o nome do Fornecedor",
    },
    onlyActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      provider: [],
    };
  },
  watch: {
    provider: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.provider;
    },
    async doSearch() {
      this.provider = [];

      const data = await SearchProvider();

      if (this.onlyActive) {
        data.forEach((item) => {
          const option = {
            value: item.data,
            label: item.label,
            ativo: item.frd_ativo,
          };

          if (option.ativo === "1") {
            this.provider.push(option);
          }
        });
      } else {
        this.provider = data.map((item) => {
          return {
            value: item.data,
            label: item.label,
          };
        });
      }
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
