import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    intIdSetor: variables.sectorId,
    intIdOcupacao: variables.occupationId,
    intIdVinculo: variables.bondId,
  };

  return AuthLegacyRequest.post(
    "faturamento/controller-atendimento/listar-dias-atendimento-por-profissional",
    sendData,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
