import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchProfessionalSectorOccupationScaleActive(
    $sectorId: Int
    $ocuppationId: Int
    $limit: Int
  ) {
    SearchProfessionalSectorOccupationScaleActive(
      sectorId: $sectorId
      ocuppationId: $ocuppationId
      limit: $limit
    ) {
      count
      rows {
        pes_id
        pes_nome
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.SearchProfessionalSectorOccupationScaleActive;
};
