<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnitHealthSubTypeSus from "./action/SearchUnitHealthSubTypeSus";
export default {
  name: "RgSelectUnitHealthSubTypeSus",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Tipo",
    },
    defaultText: {
      type: String,
      default: "Digite o tipo de classificação",
    },
    unitTypeSusId: {
      type: Number,
      default: 0,
    },
    labelEmptyOptions: {
      type: String,
      default: "Nenhum subtipo disponível",
    },
  },

  data: () => {
    return {
      valuesData: [],
    };
  },

  watch: {
    valuesData(val) {
      this.updateOptions();
    },

    unitTypeSusId() {
      this.valuesData = [];
      this.doSearch();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      const hasUnitTypeSusId = this.unitTypeSusId > 0;

      if (hasUnitTypeSusId) {
        const data = await SearchUnitHealthSubTypeSus({
          stu_id_tipos_unidades_saude_sus: this.unitTypeSusId,
        });

        if (data) {
          this.valuesData = data.map((item) => {
            return {
              value: item.stu_id,
              label: `${item.stu_codigo} - ${item.stu_nome}`,
            };
          });
        } else {
          this.valuesData = [];
        }
      }
      this.$emit("options", [...this.valuesData]);
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
