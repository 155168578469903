<style lang="scss" scoped></style>

<template>
  <div class="admission-filter">
    <div class="grid">
      <!-- UNIDADE DE SAÚDE -->
      <div class="selectinput">
        <RgSelectUnithealth
          id="select-unity-admission"
          v-model="form.selectUnity"
          class="inputitem"
          permission="internacao.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          :default-text="'Todas'"
        />
      </div>

      <!-- SETOR -->
      <div class="selectinput">
        <RgSelectSector
          id="selected-sector-admission"
          v-model="form.selectedSector"
          :disabled="disableSector"
          :class="{ disable: disableSector }"
          :permission="SECTOR_PERMISSION"
          :unit-health="form.selectUnity"
          :default-text="validateSectorOptions"
          class="inputitem"
        />
      </div>

      <!-- LOCAL DE ATENDIMENTO -->
      <div class="selectinput">
        <RgSelectPlaces
          id="selected-locale-admission"
          v-model="form.selectedLocale"
          :disabled="disableLocale"
          :class="{ 'disable unselect': disableLocale }"
          :sector="form.selectedSector"
          :default-text="'Todos'"
          :empty-default-text="'Nenhum local disponível'"
          class="inputitem"
        />
      </div>

      <!-- PERÍODO DE ENTRADA -->
      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período de Entrada</span>
      </div>
      <div class="selectinput">
        <div class="status">
          <div class="date">
            <RgInputDate
              id="entry-initial-date-admission"
              v-model="form.entryInitialDate"
              label="Data Inicial"
              placeholder="Digite a data inicial"
              class="date"
            />
            <RgInputDate
              id="entry-final-date-admission"
              v-model="form.entryFinalDate"
              label="Data Final"
              placeholder="Digite a data final"
              class="date"
            />
          </div>
        </div>
      </div>

      <!-- TIPO DE LEITO -->
      <div class="selectinput">
        <RgSuggestTypeBed
          id="bed-type-admission"
          v-model="form.bedType"
          class="inputitem"
          @selected="selectingBedInfo"
        />
      </div>

      <!-- SEXO -->
      <div class="selectinput">
        <RgRadioCustom
          id="genders-admission"
          ref="gender"
          label="Sexo"
          v-bind="propsRadioCustom"
          multSelect
          class="inputitem"
          @input="onInputRadioCustom"
        />
      </div>

      <!-- IDADE -->
      <div class="selectinput">
        <RgInputNumber
          id="age-admission"
          v-model="form.age"
          label="Idade"
          class="inputitem"
        />
      </div>

      <!-- CID PRINCIPAL -->
      <div class="selectinput">
        <RgSuggestCid
          id="main-cid-admission"
          ref="mainCid"
          v-model="form.mainCid"
          label="CID Principal"
          class="inputitem"
          @selected="selectingCidCode"
        />
      </div>

      <!-- PROCEDIMENTO DA INTERNAÇÃO -->
      <div class="selectinput">
        <RgSuggestProcedure
          id="procedure-admission"
          ref="inputProcedure"
          v-model="form.procedure"
          label="Procedimento"
          class="inputitem"
        />
      </div>

      <!-- TIPOS DE INTERNAÇÃO -->
      <div class="selectinput">
        <RgComboboxHospitalizationType
          id="hospitalization-type-admission"
          v-model="form.hospitalizationType"
          class="inputitem"
        />
      </div>

      <!-- CARÁTER DE INTERNAÇÃO -->
      <div class="selectinput">
        <RgComboboxHospitalizationServiceCharacter
          id="hospitalization-service-character-admission"
          v-model="form.hospitalizationServiceCharacter"
          label="Carater de Internação"
          class="inputitem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgSelectPlaces,
  RgInputDate,
  RgInputNumber,
  RgSuggestCid,
  RgSuggestProcedure,
  RgRadioCustom,
} from "~tokio/primitive";
import {
  RgSuggestTypeBed,
  RgComboboxHospitalizationType,
  RgComboboxHospitalizationServiceCharacter,
} from "$hospitalization/common/component";

export default {
  name: "Admission",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgSelectPlaces,
    RgSuggestTypeBed,
    RgInputDate,
    RgInputNumber,
    RgSuggestCid,
    RgSuggestProcedure,
    RgComboboxHospitalizationType,
    RgComboboxHospitalizationServiceCharacter,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Entradas Internação",
      form: {
        selectUnity: 0,
        selectedSector: null,
        selectedLocale: null,
        entryInitialDate: null,
        entryFinalDate: null,
        bedType: null,
        bedTypeId: null,
        genders: null,
        age: null,
        mainCid: null,
        mainCidId: null,
        procedure: null,
        hospitalizationType: null,
        hospitalizationServiceCharacter: null,
      },
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.form.genders;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    printReportCss() {
      return `@page {size: landscape;}`;
    },
    getNameSmartTable() {
      return "Admission";
    },
    columnTable() {
      return this.COLUMN;
    },
    disableSector() {
      return !this.form.selectUnity;
    },
    disableLocale() {
      return !this.form.selectedSector;
    },
    validateSectorOptions() {
      return this.form.selectUnity !== false
        ? "Todos"
        : "Nenhum setor disponível";
    },
    validateBedOptions() {
      return this.form.selectedLocale
        ? "Informe o leito"
        : "Nenhum leito disponível";
    },
    localId() {
      const isValidLocalId = this.form.selectedLocale > 0;
      return isValidLocalId ? this.form.selectedLocale : 0;
    },
  },
  watch: {
    "form.selectUnity"(pValue, pPrev) {
      this.form.bedType = null;

      if (!pValue || pValue !== pPrev) {
        this.form.selectedSector = null;
        this.form.selectedLocale = null;
      }
    },
    "form.selectedSector"(pValue, pPrev) {
      this.form.bedType = null;
      if (!pValue || pValue !== pPrev) {
        this.form.selectedLocale = null;
      }

      if (this.form.selectUnity === false) {
        this.form.selectedSector = false;
      }
    },
    "form.selectedLocale"(pValue, pPrev) {
      this.form.bedType = null;
    },
  },
  mounted() {
    this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.SECTOR_PERMISSION = 351;
    this.COLUMN = [
      { name: "Data", key: "int_data" },
      { name: "Hora entrada", key: "int_hora" },
      { name: "Prontuário", key: "pac_prontuario_unico" },
      { name: "Paciente", key: "nomePaciente" },
      { name: "Sexo", key: "sex_nome" },
      { name: "Idade", key: "idade" },
      { name: "Setor", key: "set_nome" },
      { name: "Local atendimento", key: "lca_nome" },
      { name: "Tipo de Leito", key: "itl_nome" },
      { name: "Leito", key: "inl_nome" },
      { name: "Profissional solicitante", key: "nomeFuncionario" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Cid 10 internação", key: "cid_nome" },
      {
        name: "Procedimento solicitado",
        key: "inp_novo_nome_procedimento",
      },
      { name: "Tipo de internação", key: "iti_nome" },
      { name: "Caráter internação", key: "ica_nome" },
      { name: "Tempo internação", key: "tempoInternado" },
    ];
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.genders = item;
      }
    },
    buildFilter() {
      const genders = this.prepareGender();

      const payLoad = {
        uns_id: this.form.selectUnity || null,
        sectorId: this.form.selectedSector || null,
        localeId: this.form.selectedLocale || null,
        entryInitialDate: this.form.entryInitialDate
          ? this.formatDate(this.form.entryInitialDate)
          : null,
        entryFinalDate: this.form.entryFinalDate
          ? this.formatDate(this.form.entryFinalDate)
          : null,
        bedTypeId: this.form.bedTypeId || null,
        gender: genders,
        age: Number(this.form.age) || null,
        cidId: this.form.mainCidId || null,
        procedureCode: this.form.procedure || null,
        hospitalizationTypeId: this.form.hospitalizationType || null,
        hospitalizationServiceCharacterId:
          this.form.hospitalizationServiceCharacter || null,
        columnsToPrint: this.columnsToPrint,
      };

      return payLoad;
    },
    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Hospitalization/Report/GET_ADMISSION_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    clearFilter() {
      this.form = {
        selectUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        selectedLocale: null,
        bedType: null,
        bedTypeId: null,
        entryInitialDate: null,
        entryFinalDate: null,
        genders: null,
        age: null,
        mainCid: null,
        mainCidId: null,
        procedure: null,
        hospitalizationType: null,
        hospitalizationServiceCharacter: null,
      };
      if (this.$refs.gender) {
        this.$refs.gender.clearRadio();
      }
    },
    formatDate(pDate) {
      return this.$utils.date.BrazilianDateToDatabase(pDate);
    },
    selectingCidCode(pCid) {
      const { source } = pCid;
      this.form.mainCidId = source.cid_id;
    },
    selectingBedInfo(pBedType) {
      const { source } = pBedType;
      this.form.bedTypeId = source.itl_id;
    },
    prepareGender() {
      const hasValue = this.form.genders !== "";

      return hasValue
        ? this.$utils.app.Genders.GetIdByInitials(this.form.genders)
        : null;
    },
  },
};
</script>
