import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    StandardProcedure {
      rows {
        pcp_codigo_procedimento_padrao
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.StandardProcedure.rows;
};
