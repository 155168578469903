import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveUnitHealthType($unitHealthType: UnitHealthTypeInputType) {
    SaveUnitHealthType(unitHealthType: $unitHealthType) {
      tus_id
    }
  }
`;
export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.mutate({ mutation, variables });
  return data.SaveUnitHealthType;
};
