<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchScale from "./action/SearchScale";
export default {
  name: "RgComboboxScale",
  mixins: [RgComboboxMixin],
  props: {
    strData: { type: String, required: true },
    intIdSetor: { type: [String, Number], required: true },
    blnEhaAtendimento: { type: Boolean, default: null },
    blnAtendComExamesMarcados: { type: Boolean, default: null },
    intIdVinculoSetor: [String, Number],
    isScaleDestiny: { type: Boolean, default: false },
  },

  data: () => {
    return {};
  },

  watch: {
    strData(value) {
      this.fillData();
    },
    intIdSetor(value) {
      this.fillData();
    },
    blnEhaAtendimento(value) {
      this.fillData();
    },
    blnAtendComExamesMarcados(value) {
      this.fillData();
    },
    intIdVinculoSetor(value) {
      this.fillData();
    },
  },

  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (this.noSearch()) {
        return;
      }
      this.$emit("loading", true);

      this.mData = [];

      const data = await SearchScale({
        strData: this.strData,
        intIdSetor: this.intIdSetor,
        blnEhaAtendimento: this.blnEhaAtendimento,
        blnAtendComExamesMarcados: this.blnAtendComExamesMarcados,
        intIdVinculoSetor: this.intIdVinculoSetor,
      });

      if (data.length === 0) {
        this.$toaster.warning("Não há escalas disponiveis");
        return;
      }

      if (this.isScaleDestiny) {
        data.forEach((item) => {
          if (
            item.eha_atendimento === "1" &&
            item.funcionario_inabilitado === "0"
          ) {
            this.mData.push({
              value: item.data,
              text: `${item.pes_nome} (${item.ees_nome}) - ${item.eea_inicio_atendimentos} - ${item.eea_final_atendimentos}`,
              eea_inicio_atendimentos: item.eea_inicio_atendimentos,
              item: item,
            });
          }
        });
      } else {
        data.forEach((item) => {
          this.mData.push({
            value: item.data,
            text: `${item.pes_nome} (${item.ees_nome}) - ${item.eea_inicio_atendimentos} - ${item.eea_final_atendimentos}`,
            eea_inicio_atendimentos: item.eea_inicio_atendimentos,
            item: item,
          });
        });
      }

      if (data.length === 1) {
        this.$emit("selectOnlyItem", {
          id: this.mData[0].value,
          item: this.mData[0],
        });
      }
      this.$emit("loading", false);
    },

    noSearch() {
      if (!this.strData || !this.intIdSetor) {
        return true;
      }
      return false;
    },
  },
};
</script>
