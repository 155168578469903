const {
  VUE_APP_BASE_URL,
  VUE_APP_BASE_LEGACY_URL,
  VUE_APP_TIMEOUT,
  VUE_APP_RESPONSE_TYPE,
  VUE_APP_MAX_REDIRECT,
} = process.env;

const SERVER_CONFIG = {
  baseURL: VUE_APP_BASE_URL,
  timeout: VUE_APP_TIMEOUT,
  responseType: VUE_APP_RESPONSE_TYPE,
  maxRedirects: VUE_APP_MAX_REDIRECT,
};

const LEGACY_SERVER_CONFIG = {
  baseURL: VUE_APP_BASE_LEGACY_URL,
  timeout: VUE_APP_TIMEOUT,
  responseType: VUE_APP_RESPONSE_TYPE,
  maxRedirects: VUE_APP_MAX_REDIRECT,
};

export { SERVER_CONFIG, LEGACY_SERVER_CONFIG };
export default { SERVER_CONFIG, LEGACY_SERVER_CONFIG };
