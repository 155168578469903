<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchCid10 from "./action/SearchCid10";
import ValidateCIDRequired from "./action/ValidateCIDRequired";

export default {
  name: "RgSelectCid10",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "CID - 10",
    },
    procedureCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    procedureCode(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.fillData();
      }
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async fillData() {
      this.valuesData = [];
      this.$loader.start();

      if (this.procedureCode) {
        const data = await SearchCid10({
          strCodigoProcedimento: this.procedureCode,
        });

        let validateCid = null;

        if (data && data.length > 0) {
          if (this.procedureCode && this.procedureCode.length > 0) {
            validateCid = await ValidateCIDRequired({
              strCodigoProcedimento: this.procedureCode,
            });
          }
        }

        if (validateCid) {
          this.$emit("required", true);
        } else {
          this.$emit("required", false);
        }

        this.valuesData = data.map((item) => {
          return { value: item.data, label: item.label };
        });

        if (this.valuesData && this.valuesData.length > 0) {
          this.$emit("disable", true);
          this.$emit("required", true);
        } else {
          this.$emit("disable", false);
          this.$emit("required", false);
        }
      }
      this.$loader.finish();
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
