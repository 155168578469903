<template lang="html">
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="17.5"
      height="17.5"
      rx="4.25"
      fill="white"
      stroke="#ABABAB"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "Square",
};
</script>
