<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import SearchBasicAttentionMicroAreas from "./action/SearchBasicAttentionMicroAreas";

export default {
  name: "RgSelectBasicAttentionMicroArea",
  extends: RgSelect,
  props: {
    area: {
      default: null,
    },
    label: {
      type: String,
      default: "Microárea",
    },
  },
  data() {
    return {
      microareas: [],
    };
  },
  watch: {
    microareas: function (val) {
      this.updateOptions();
    },

    area(pValue) {
      this.mValue = null;
      this.doSearch();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.microareas;
    },

    async doSearch() {
      if (!this.area) {
        this.microareas = [];
        return Promise.resolve([]);
      }

      this.data = [];
      const areaId = Number(this.area);
      const data = await SearchBasicAttentionMicroAreas({ areaId });

      this.microareas = data.map((item) => {
        return {
          value: item.mic_id,
          label: `${item.mic_descricao}`,
        };
      });
    },

    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
