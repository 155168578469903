<template>
  <div v-if="show">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="modal-patient-image-upload"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-patient-image-upload-header">
        <h1 class="title">{{ title }}</h1>
      </div>

      <div slot="body" class="modal-patient-image-upload-body">
        <FormBase title="Obter Foto">
          <div class="img-container">
            <div class="img-area"><img :src="img" class="img" /></div>
            <div class="btns-area">
              <RgAddButton
                :disabled="!AlreadyHasImage"
                class="mg-r"
                @click="openUpload"
              />
              <RgLessButton :disabled="AlreadyHasImage" @click="cleanImage" />
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="modal-patient-image-upload-footer">
        <div class="control-painel">
          <RgCancelButton medium class="btn-margim" @click="close" />
          <RgConfirmButton medium type="button" @click="confirm" />
        </div>
      </div>
    </RgBaseModal>

    <ModalUpload
      :show="modalUpload"
      :title="'Upload de Imagem'"
      justImage
      @upload="getUploadedFileInfo"
      @close="closeModalUpload"
    />
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgConfirmButton,
  RgCancelButton,
  RgAddButton,
  RgLessButton,
} from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";

import ModalUpload from "~tokio/primitive/modal/modal-upload/ModalUpload.vue";

export default {
  name: "ModalPatientImageUpload",
  components: {
    RgBaseModal,
    ModalUpload,
    RgAddButton,
    RgLessButton,
    RgConfirmButton,
    RgCancelButton,
    FormBase,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Foto do Paciente",
    },
    hasImage: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      modalUpload: false,
      modalConfirmRemove: false,
      img: "/static/person-image/photo-default3.png",
      img_data: null,
    };
  },

  computed: {
    AlreadyHasImage() {
      return this.img === "/static/person-image/photo-default3.png";
    },
  },

  watch: {
    show(pValue) {
      if (pValue && this.hasImage && this.hasImage.url) {
        this.img = this.hasImage.url;
        this.img_data = this.hasImage;
      }
    },
  },

  methods: {
    confirm() {
      this.$emit("upload", this.img_data);
      this.close();
    },

    cleanImage() {
      this.img = "/static/person-image/photo-default3.png";
      this.img_data = null;
    },

    getUploadedFileInfo(pValue) {
      this.img = pValue.url;
      this.img_data = pValue;
    },

    openUpload() {
      this.modalUpload = true;
    },

    closeModalUpload() {
      this.modalUpload = false;
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
