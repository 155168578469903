<template lang="html">
  <div class="rg-content-hospitalization-billing-vasectomy">
    <div class="team-info">
      <RgContentHospitalizationTeam
        :procedure="procedure"
        :required-documents="requiredDocuments"
        @setProfessionalList="setProfissionalList"
      />
    </div>
    <div class="vasectomy-info">
      <form class="form">
        <RgValidatorForm ref="validator">
          <FormBase title="Vasectomia">
            <div class="rg-content-hospitalization-billing-vasectomy-info">
              <RgInputMasked
                v-model="vasectomy.ilv_qtd_filhos"
                :rules="{ required: true }"
                label="Quantidade de Filhos "
                placeholder="0"
                mask="##"
                class="inputitem"
              />

              <RgComboboxInstructionLevel
                v-model="vasectomy.ilv_id_internacoes_grau_instrucoes"
                :rules="{ required: true }"
                label="Grau de Instrução"
                class="inputitem"
              />

              <RgComboboxCid
                v-model="vasectomy.ilv_codigo_cid10"
                :rules="{ required: true }"
                :procedure="procedure.iap_codigo_procedimentos"
                label="CID"
                class="inputitem"
              />

              <RgComboboxContraceptiveMethod
                v-model="vasectomy.ilv_id_internacoes_metodos_contraceptivos1"
                :rules="{ required: true }"
                label="Método Contraceptivo 1"
                class="inputitem"
              />
            </div>
          </FormBase>
          <div class="buttons">
            <MediumButton
              :disabled="!vasectomy.professionalTeamList.length > 0"
              label="Confirmar"
              backgroundColor="#0bb98c"
              @click="confirm"
            />

            <MediumButton
              title="Fechar"
              label="Fechar"
              @click.stop.prevent="close"
            />
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </div>
</template>

<script>
import mask from "vue-the-mask";
import { RgValidatorForm, RgInputMasked, MediumButton } from "~tokio/primitive";
import RgComboboxInstructionLevel from "../../../combobox/rg-combobox-instruction-level/RgComboboxInstructionLevel";
import RgComboboxContraceptiveMethod from "../../../combobox/rg-combobox-contraceptive-method/RgComboboxContraceptiveMethod";
import RgContentHospitalizationTeam from "$billing/submodules/aih/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import { RgComboboxCid } from "$billing/common";
import { ValidateTeamMembers } from "../../../../service";
import FormBase from "~tokio/foundation/form-base/FormBase";
export default {
  name: "RgContentHospitalizationBillingVasectomy",
  components: {
    FormBase,
    RgValidatorForm,
    RgInputMasked,
    RgComboboxInstructionLevel,
    RgComboboxContraceptiveMethod,
    RgContentHospitalizationTeam,
    RgComboboxCid,
    MediumButton,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      vasectomy: {
        type: "vasectomy",
        ilv_qtd_filhos: 0,
        ilv_id_internacoes_grau_instrucoes: "",
        ilv_id_internacoes_metodos_contraceptivos1: "",
        ilv_codigo_cid10: "",
        ilv_gestacao_alto_risco: 0,
        professionalTeamList: [],
      },
      professionalCaptionRaw: "pes_nome",
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.vasectomy = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.vasectomy);
      }
    },
    close() {
      this.$emit("close");
    },
    setProfissionalList(event) {
      this.vasectomy.professionalTeamList = event;
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      // if (!this.validateTeamMembers()) valid = false
      return valid;
    },
    validateTeamMembers() {
      return ValidateTeamMembers(this.vasectomy.professionalTeamList);
    },
  },
};
</script>
