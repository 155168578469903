<template lang="html">
  <div v-if="show" class="modal-discard-product">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-discard-product-header">
        <span class="title"> Descartar Produtos e Medicamentos </span>
      </div>
      <div slot="body" class="modal-discard-product-body">
        <RgValidatorForm ref="validator">
          <FormBase title="Dados do Descarte" class="form-base">
            <div class="grid discard-info">
              <RgSelectUnithealthPharmacy
                id="unit-health"
                ref="unitHealth"
                v-model="form.unitHealth"
                :typePharmacy="1"
                label="Unidade de Saúde"
                :disabled="isByEntry"
                :class="{ disable: isByEntry }"
                :rules="{ required: true }"
              />
              <RgSelectPharmacy
                id="pharmacy"
                ref="pharmacy"
                v-model="form.pharmacy"
                :unitHealthId="Number(form.unitHealth)"
                :typePharmacy="1"
                default-text="Selecione"
                class="input"
                :disabled="!form.unitHealth || isByEntry"
                :class="{ disable: !form.unitHealth || isByEntry }"
                :rules="{ required: true }"
              />
              <RgInputDate
                id="initial-date"
                ref="initialDate"
                v-model="form.initialDate"
                label="Data do Descarte"
                :disabled="blockedDateDiscard"
                :class="{ disable: blockedDateDiscard }"
                :max-date="getMaxDateInitial"
                :min-date="getMinDateInitial"
                :rules="{ required: true }"
              />
            </div>
          </FormBase>
          <FormBase title="Dados do Produto Selecionado" class="form-base">
            <div class="grid product-info">
              <RgSelectProduct
                ref="product"
                v-model="form.product"
                :typeMovimentation="3"
                :pharmacyId="Number(form.pharmacy)"
                :rules="{ required: true }"
                :disabled="!form.pharmacy || isByEntry"
                :class="{ disable: !form.pharmacy || isByEntry }"
                @change="selectedProduct"
              />
              <RgSelectTypePresentation
                id="presentation"
                ref="presentation"
                v-model="form.presentation"
                label="Apresentação"
                :disabled="true"
                :class="{ disable: true }"
                :rules="{ required: true }"
              />
              <RgSelectLotExpiration
                id="batch"
                ref="batch"
                v-model="form.batch"
                :disabled="!form.product"
                :isEntry="isByEntry"
                :class="{ disable: !form.product }"
                :pharmacyId="Number(form.pharmacy)"
                :productId="Number(form.product)"
                :rules="{ required: true }"
                blnSomenteDisponiveis
                @change="selectedBatch"
              />
            </div>
            <div class="grid extra-info">
              <RgInput
                ref="inventory"
                v-model="form.inventory"
                :rules="{ required: true }"
                label="Estoque"
                :disabled="true"
                :class="{ disable: true }"
                :maxlength="10"
                placeholder="000000000"
              />
              <RgInput
                id="quantity"
                ref="quantity"
                v-model="form.quantity"
                :rules="{
                  required: true,
                  number: true,
                  fn: permitedValues,
                }"
                label="Quantidade"
                :maxlength="10"
                placeholder="0000000000"
              />
              <RgComboboxReasonDiscard
                id="reason"
                ref="reason"
                v-model="form.reason"
                :rules="{ required: true }"
                openOnlyTop
              />
            </div>
            <div class="grid-observations">
              <RgTextArea
                id="observation"
                ref="observation"
                v-model="form.observation"
                class="inputitem"
                label="Observações"
                :maxlength="255"
                :rules="{ fn: validateEspecialCharacter }"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </div>

      <div slot="footer" class="modal-discard-product-footer">
        <RgCancelButton id="cancel" medium @click="close" />

        <RgSaveButton id="save" medium @click="saveDiscard"></RgSaveButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgValidatorForm,
  RgInputDate,
  RgInput,
  RgTextArea,
  RgCancelButton,
  RgSaveButton,
} from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgComboboxReasonDiscard from "$pharmacy/submodules/central/components/combobox/rg-combobox-reason-discard/RgComboboxReasonDiscard";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration.vue";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";

import moment from "moment";

const FORM = {
  unitHealth: null,
  pharmacy: null,
  initialDate: moment().format("DD/MM/YYYY"),
  reason: null,
  quantity: null,
  observation: null,
  product: null,
  presentation: null,
  inventory: null,
  batch: null,
  lote: null,
  data: null,
};

export default {
  name: "ModalDiscardProduct",
  components: {
    RgBaseModal,
    RgValidatorForm,
    FormBase,
    RgInputDate,
    RgSelectUnithealthPharmacy,
    RgSelectPharmacy,
    RgComboboxReasonDiscard,
    RgInput,
    RgTextArea,
    RgCancelButton,
    RgSaveButton,
    RgSelectTypePresentation,
    RgSelectLotExpiration,
    RgSelectProduct,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
    idUnithHealth: {
      type: Number,
      default: 0,
    },
    idPharmacy: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      isByEntry: false,
      lotMinLimitDateSelect: null,
    };
  },
  computed: {
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate ? new Date(moment().startOf("month")) : null;
    },

    preferenceDateDiscard() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.trs_bloquear_data_descarte"
      ];
    },
    blockedDateDiscard() {
      return this.preferenceDateDiscard === "1";
    },
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.mountData(this.selectedRow);
      }
    },

    "form.unitHealth"(pValue) {
      if (!pValue && pValue === null) {
        this.form.pharmacy = null;
        this.form.product = null;
      }
    },
    "form.pharmacy"(pValue) {
      if (!pValue && pValue === null) {
        this.form.product = null;
      }
    },
    "form.product"(pValue) {
      if (!pValue && pValue === null) {
        this.form.amountDismissed = null;
        this.form.batch = null;
      }
    },
    "form.batch"(pValue) {
      if (!pValue && pValue === null) {
        this.form.inventory = null;
      } else {
        this.getInventory();
      }
    },

    "form.inventory"(pValue) {
      if (!pValue && pValue === null) {
        this.form.quantity = null;
      }
    },
  },
  mounted() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  methods: {
    mountData(pValue) {
      if (pValue && pValue.situacao === "ENTRADA") {
        const dataLote = pValue.lote.split("|");
        this.form.product = pValue.mpd_id;
        this.form.batch = dataLote[0].trim();
        this.form.data = dataLote[0].trim();
        this.form.presentation = pValue.mtu_novo_nome;

        this.isByEntry = true;
      }
      this.form.pharmacy = this.idPharmacy || null;
      this.form.unitHealth = this.idUnithHealth || null;
    },
    validateEspecialCharacter(pValue, pErrors) {
      const hasSpecial = /[!$%^&*()@_^#¨|´~=`{}[\]:";'~<>?\\]/.test(pValue);

      if (hasSpecial) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: barra, ponto, traço, vírgula e símbolo de soma",
        );
        return false;
      }

      return true;
    },
    async selectedProduct(pProduct) {
      if (pProduct) {
        this.form.presentation = pProduct.mtu_id;
      } else {
        this.form.presentation = null;
      }
    },

    selectedBatch(pValue) {
      if (pValue) {
        const batch = pValue.label.split("|");
        this.form.lote = batch[0].trim();
        this.form.data = batch[1].trim();
        this.lotMinLimitDateSelect = pValue.trs_data;
      } else {
        this.form.lote = null;
        this.form.data = null;
        this.lotMinLimitDateSelect = null;
      }
    },
    async getInventory() {
      try {
        this.$loader.start();
        const variables = {
          intIdProduto: this.form.product,
          intIdFarmacia: this.form.pharmacy,
          strLote: this.form.batch,
        };
        const data = await this.$store.dispatch(
          "Pharmacy/Central/GET_QUANTITY_INVENTORY",
          variables,
        );
        this.form.inventory = data[0].estocado;
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e),
          "Erro ao carregar o estoque",
        );
      } finally {
        this.$loader.finish();
      }
    },
    permitedValues(pValue, pErrors) {
      if (this.form.quantity && this.form.inventory) {
        const isBiggerThan =
          Number(this.form.quantity.replace(/[.]/g, "")) >
          Number(this.form.inventory.replace(/[.]/g, ""));
        if (isBiggerThan) {
          pErrors.push(
            `O valor não pode ser maior do que ${this.form.inventory}`,
          );
          return false;
        }
        const isSmallerThan = Number(this.form.quantity) < 1;
        if (isSmallerThan) {
          pErrors.push("O valor não pode ser menor do que 1");
          return false;
        }
      }
    },
    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveDiscard() {
      const initialDate = moment(this.lotMinLimitDateSelect, "DD/MM/YYYY");
      const finalDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const initialDateIsAfter = initialDate.isAfter(finalDate);

      if (initialDateIsAfter) {
        this.$toaster.info(
          `Escolha uma data de descarte após o dia ${this.lotMinLimitDateSelect}`,
          "A data do descarte é anterior à data de entrada do produto",
        );
        return null;
      }
      const validate = await this.isFormValid();

      if (!validate) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            dsc_id_farmacias: Number(this.form.pharmacy),
            dsc_id_municipios_produtos: Number(this.form.product),
            dsc_id_motivos_descarte: Number(this.form.reason),
            dsc_quantidade: Number(this.form.quantity.replace(/[.]/g, "")),
            dsc_data: this.form.initialDate,
            dsc_lote: this.form.lote.trim(),
            dsc_validade_lote: this.form.data.trim(),
            dsc_tipo: "DESCARTE",
            dsc_obs: this.form.observation
              ? this.form.observation.toUpperCase()
              : "",
          },
        };

        await this.$store.dispatch(
          "Pharmacy/Central/REGISTER_DISCARD",
          variables,
        );
        this.$toaster.success("Descarte registrado com sucesso");

        const info = {
          intIdMunicipioProduto: this.form.product,
          intIdFarmacia: this.form.pharmacy,
        };
        const data = await this.$store.dispatch(
          "Pharmacy/MIN_LIMIT_EXCEEDED",
          info,
        );

        if (data !== 0) {
          this.$toaster.info(
            "Faça uma solicitação para repor o estoque do produto",
            "O estoque mínimo foi alcançado",
          );
        }

        this.$emit("reSearch", true);
        this.close(true);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error),
          "Erro ao realizar descarte",
        );
      } finally {
        this.$loader.finish();
      }
    },

    close(isSubmit = false) {
      const pharmacyId = isSubmit ? this.form.pharmacy : null;
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.cleanFieldsValidation();
      this.isByEntry = false;
      this.$emit("close", pharmacyId);
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "unitHealth",
        "pharmacy",
        "initialDate",
        "reason",
        "quantity",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
