import { render, staticRenderFns } from "./RgTabsItem.vue?vue&type=template&id=74097533&scoped=true&lang=html&"
import script from "./RgTabsItem.vue?vue&type=script&lang=js&"
export * from "./RgTabsItem.vue?vue&type=script&lang=js&"
import style0 from "./RgTabsItem.scss?vue&type=style&index=0&id=74097533&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74097533",
  null
  
)

export default component.exports