<template>
  <div class="exam-sub-procedures-performed-schedule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-esppr"
          ref="unitHealth"
          v-model="form.uns_id"
          permission="exame2.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-esppr"
          ref="sector"
          v-model="form.set_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unit-health="form.uns_id"
          :rules="{ required: true }"
          class="inputitem"
          default-text="Selecione"
          @change="getSector"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período do Exame</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-esppr"
          ref="initialDate"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{
            required: true,
          }"
          pop-up="bottom"
        />

        <RgInputDate
          id="final-date-esppr"
          ref="finalDate"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem mg"
          :disabled="!form.initialDate"
          :class="{ disable: !form.initialDate }"
          :rules="{ required: true }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          pop-up="bottom"
        />
      </div>

      <div class="selectinput">
        <Tooltip class="exam-sub-procedures-performed-schedule-tooltip">
          <IconInfoHelper slot="icon" />
          <div slot="content" class="content">
            <span>
              Só é possivel selecionar períodos de exame com intervalo de no
              máximo 1 mês.
            </span>
          </div>
        </Tooltip>
      </div>

      <!-- <div class="selectinput">
        <RgSelectSubprocedures
          id="subprocedures-esppr"
          v-model="subprocedures"
          class="inputitem"
          label="Subprocedimento"
          default-text="Todos"
          @change="getSubprocedureValue"
        />
      </div> -->

      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-esppr"
          ref="professional"
          v-model="form.pes_nome"
          :maxlength="100"
          :disabled="!hasUnitHealth"
          :unitHealthId="form.uns_id"
          :ocuppationId="form.ocp_id"
          :class="{ disable: !hasUnitHealth }"
          label="Profissional"
          class="inputitem"
          @selected="selectingProfessional"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgSuggestEmployee,
  RgInputDate,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";

import moment from "moment";
// import RgSelectSubprocedures from "$exam/common/component/rg-select-subprocedures/RgSelectSubprocedures";

const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: null,
  initialDate: "",
  finalDate: "",
  month: null,
  eas_id: null,
  eas_nome_sub_procedimentos: null,
  pes_id: null,
  pes_nome: null,
  monthName: null,
};

export default {
  name: "ExamSubProceduresPerformedReport",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgSuggestEmployee,
    // RgSelectSubprocedures,
    RgInputDate,
    Tooltip,
    IconInfoHelper,
  },
  data() {
    return {
      resultTitle: "Subprocedimentos Realizados",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      professionalBond: null,
      columns: [],
      legend: "",
      subprocedures: null,
    };
  },
  computed: {
    getNameSmartTable() {
      return "ExamSubProceduresPerformedReport";
    },

    printReportCss() {
      return (
        "@page {size: landscape; }" +
        "table { table-layout: auto !important; }" +
        ".report-column-1 { width: 200px; text-align: left; }" +
        ".report-column-1-print { width: 200px; text-align: left;}" +
        "th:last-child { width: 60px; }"
      );
    },

    columnTable() {
      return this.columns;
    },

    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },

    getLegend() {
      return this.legend;
    },

    initialColumns() {
      return 33;
    },

    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            this.getMinDateInitial.getFullYear(),
            this.getMinDateInitial.getMonth() + 1,
            0,
          )
        : null;
    },

    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
      }
    },
    "form.initialDate"(pValue) {
      this.form.finalDate = null;

      if (pValue) {
        const month = this.getMinDateInitial.toLocaleString("pt-br", {
          month: "long",
        });
        this.form.monthName = month.toUpperCase();
      }
    },
  },

  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  methods: {
    buildFilter() {
      this.mountLegend();
      return { ...this.form };
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
    },

    getSubprocedureValue(pValue) {
      if (pValue) {
        this.form.eas_nome_sub_procedimentos = pValue.label;
      } else {
        this.form.eas_nome_sub_procedimentos = null;
      }
    },

    mountLegend() {
      if (this.form.initialDate) {
        const numberInitialOfDayPerMonth = this.getMinDateInitial.getDate();
        const numberOfDayPerMonth = this.form.finalDate.slice(0, 2);
        this.legend = `${this.form.monthName} - ${numberInitialOfDayPerMonth} À ${numberOfDayPerMonth} DIAS `;
      } else {
        this.legend = "";
      }
    },

    mountColumns() {
      this.columns = [];
      this.$store.commit("User/RESET_SMART_TABLE_COLUMNS");

      const numberOfDayPerMonth = Number(this.form.finalDate.slice(0, 2)) || 0;
      const numberOfDayInitial = Number(this.form.initialDate.slice(0, 2)) || 0;

      this.columns.push({
        name: "Procedimento",
        key: "SUBPROCEDIMENTO",
        align: "left",
      });

      for (let item = numberOfDayInitial; item <= numberOfDayPerMonth; item++) {
        this.columns.push({ name: `${item}`, key: `DIA_${item}` });
      }

      this.columns.push({ name: "Total", key: "TOTAL" });
    },

    async doSearch(pData) {
      this.mountColumns();

      try {
        return this.$store.dispatch(
          "Exam/Report/GET_EXAM_SUB_PROCEDURES_PERFORMED_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    selectingProfessional(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.pes_id = pValue.source.pes_id;
        this.form.pes_nome = pValue.source.pes_nome;
      } else {
        this.form.pes_id = null;
        this.form.pes_nome = null;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.cleanFieldsValidation();
    },

    cleanFieldsValidation() {
      const fieldsRefs = ["sector", "initialDate", "finalDate"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
