<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchPlacePep from "./action/SearchPlacePep";
export default {
  name: "RgSelectPlacePep",
  extends: RgSelect,
  props: {
    sectorCode: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    sectorCode(pValue, pPrev) {
      if (pValue || pValue !== pPrev) {
        this.fillData();
      }
    },
    valuesData() {
      this.updateOptions();
    },
  },

  methods: {
    getData() {
      return this.valuesData;
    },
    async fillData() {
      if (this.sectorCode) {
        const data = await SearchPlacePep({ set_codigo: this.sectorCode });
        this.valuesData = data.map((item) => {
          return {
            value: item.data,
            label: item.label,
          };
        });

        if (this.valuesData.length === 1) {
          this.$emit("selectOnlyItem", this.valuesData[0].value);
        }
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
