// import LocalStorage from "~common/local-storage/LocalStorage";

const Request = (pRequest, pItensRequest, pClient, pUser, pAddress) => {
  // const logo = LocalStorage.getObject("client-logo");

  const nomeUnidade = pAddress.uns_nome;
  const cidadeUnidade = pAddress.endereco.neighborhood.city.mun_nome;
  const estadoUnidade = pAddress.endereco.neighborhood.city.state.est_sigla;
  const enderecoUnidade = pAddress.endereco.end_logradouro;
  const numeroUnidade = pAddress.endereco.end_numero;

  const nomeCliente = pClient;
  const unidade = nomeUnidade + " / " + cidadeUnidade + " - " + estadoUnidade;
  const endereco = enderecoUnidade + " - N°: " + numeroUnidade;
  const cnes = pAddress.uns_cnes;

  const data = new Date().toLocaleDateString();
  const hora = new Date().toLocaleTimeString();

  let dadosTabela = "";

  pItensRequest.forEach((item) => {
    dadosTabela += "<span> Cód.: <b>" + item.mpd_codigo + "</b></span>";
    dadosTabela +=
      "<span> Prod.: <b>" + item.mpd_novo_principio_ativo + "</b></span>";
    dadosTabela += "<span> Apr.: <b>" + item.mtu_novo_nome + "</b></span>";
    dadosTabela += "<span> Lote/Val.: <b>" + item.lote + "</b></span>";
    dadosTabela += "<span> Quant.: <b>" + item.trs_quantidade + "</b></span>";
  });

  const html = `
    <html lang="pt-br">
      <head>
          <meta charset="utf-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <title>Guia de Remessa</title>

          <style type="text/css">

          @media print {
              body {
                  margin: 0;
                  padding: 0;
                  line-height: 1.4em;
                  font: 12pt Georgia, "Times New Roman", Times, serif;
                  color: #000;
              }

              produtos {
                  page-break-inside: auto;
              }

              tabela-produtos {
                  page-break-inside: avoid; page-break-after: auto;
              }

              footer {

              }
          }

          body {
              font-family: Verdana, Geneva, sans-serif;
          }

          .header {
              display: flex;
              flex-direction: row;
              align-items: center;
          }

          header span {
              font-size: 7pt;
          }

          header div:first-child {
              margin: 0 0 5px 0;
          }

          .subtitle {
              display: flex;
              font-size: 10.5pt;
              font-weight: bold;
              color: #000;
              margin: 40px 0 4px 0;
          }

          hr {
              margin: 4px 0;
              border: 0.5px solid #CFCFCF;
          }

          .logoCliente {
              float: left;
              max-width: 100px;
              max-height: 100px;
              width: 100px;
              height: 100px;
          }

          .info {
              display: flex;
              flex-direction: column;
              font-size: 8.3pt;
              color: #000000;
              line-height: 13px;
              font-weight: 400;
              justify-content: center;
              margin-left: 16px;
          }

          .info-remessa {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              gap: 8px;
              font-size: 9pt;
              margin: 16px 0 32px 0;
          }

          .info-filtro span:last-child {
              font-weight: bold;
              word-break: break-all;
          }

          .produtos {
              margin: 16px 0 32px 0;
          }

          .tabela-produtos {
              display: grid;
              grid-template-columns: 10% 25% 25% 25% 14%;
              column-gap: 10px;
              row-gap: 4px;
              color: #4F5153;
              font-size: 9.8pt;
          }

          .tabela-produtos span {
              word-break: break-all;
          }

          .img-header {
              display: flex;
              width: 2.5cm;
              border: 0;
          }

          .esus {
              height: 35px;
              width: 35px;
              margin-top: 6px;
              align-items: center;
          }

          .sus {
              height: 50px;
              width: 140px;
              margin-left: auto;
          }

          footer {
              width: 100%;
              font-size: 9pt;
          }

          .footer-emissao {
              margin: 16px 0;
              text-align: center;
          }

          .footer-recebimento {
              text-align: center;
              margin: 16px 0;
          }

          .footer-recebimento div {
              margin: 0 0 25px 0;
          }

          .footer-recebimento div:nth-child(2) {
              margin: 0 0 35px 0;
          }

          .footer-recebimento div:nth-child(3) {
              margin: 0 0 5px 0;
          }
          </style>

      </head>
      <body>

          <header>
            <div class="header">
              <div class="img-header esus">
                <img src="/static/images/mini-logo-esus.png" class="esus"/>
              </div>

              <div class="info">
                  <span>${nomeCliente}</span>
                  <span>${unidade}</span>
                  <span>${endereco}</span>
                  <span>CNES da Unidade: ${cnes}</span>
              </div>

              <div class="img-header sus">
                <img src="/static/images/novo-sus-logo.jpg" class="sus"/>
              </div>
            </div>
          </header>

          <hr>

          <div class="subtitle">
            <span>Dados do Envio da Remessa</span>
          </div>

          <hr>

          <div class="info-remessa">
            <div class="info-filtro">
              <span>Origem:</span>
              <span>${pRequest.origem || pRequest.farmacia_origem}</span>
            </div>

            <div class="info-filtro">
              <span>Destino:</span>
              <span>${pRequest.destino || pRequest.farmacia_destino}</span>
            </div>

            <div class="info-filtro">
              <span>Data da Solicitação:</span>
              <span>${pRequest.trs_data || pRequest.str_data_pedido}</span>
            </div>

            <div class="info-filtro">
              <span>Número da Guia:</span>
              <span>${pRequest.trs_guia_remessa || pRequest.guide}</span>
            </div>

            <div class="info-filtro">
              <span>Emissor:</span>
              <span>${pUser}</span>
              </div>

              <div class="info-filtro">
              <span>Usuário:</span>
              <span>${pRequest.usu_nome}</span>
            </div>

          </div>

          <div class="subtitle">
              <span>Dados dos Produtos</span>
          </div>

          <hr>

          <section class="produtos">
            <div class="tabela-produtos"> ${dadosTabela} <div>
          </section>

          <footer>
              <hr>
              <div class="footer-recebimento">
                  <div>
                      <span><b>Declaro ter recebido os produtos acima</b></span>
                  </div>

                  <div>
                      <span>_________________________________________, às _______ : _______ de ______/______/________</span>
                  </div>

                  <div>
                      <span>Assinatura:____________________________________________________</span>
                  </div>

              </div>
              <hr>
              <div class="footer-emissao">
                  <span>Impresso em ${data} às ${hora}</span>
              </div>
          </footer>
      </body>
    </html>
  `;

  return html;
};

export default Request;
