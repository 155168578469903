import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import SearchBpaI from "$billing/submodules/bpa/bpa-i/view/search/SearchBpaI";

export default {
  path: "/billing/bpa/bpa-i",
  name: "billing.bpa.bpa-i",
  components: {
    default: SearchBpaI,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      {
        label: "BPA",
        link: OpenSubModule("billing"),
      },
      {
        label: "BPA-I",
      },
    ],
  },
};
