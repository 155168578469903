<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListSectorsWithBondAndPermission from "./action/ListSectorsWithBondAndPermission";

export default {
  name: "RgSelectSectorBondAndPermission",
  extends: RgSelect,
  props: {
    unitHealthId: {
      required: true,
      default: 0,
    },
    label: {
      type: String,
      default: "Setor",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    submoduleId: {
      required: true,
      default: 0,
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    unitHealthId(pValue, pPreviously) {
      this.mValue = null;
      const isDiferentValue = pValue !== pPreviously;

      if (isDiferentValue) {
        this.doSearch();
      }
    },

    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      const hasUnitHealthId = this.unitHealthId > 0;

      if (!hasUnitHealthId) return Promise.resolve([]);

      const data = await ListSectorsWithBondAndPermission({
        intIdUnidade: this.unitHealthId,
        intIdSubModulo: this.submoduleId,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: `${item.label}`,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
