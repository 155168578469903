<template lang="html">
  <svg
    width="26"
    height="26"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 20.3415L4 7.70732C4 6.21469 5.21469 5 6.70732 5L19.3415 5C20.8341 5 22.0488 6.21469 22.0488 7.70732L22.0488 12.0843C22.0488 12.5824 21.6454 12.9867 21.1464 12.9867C20.6473 12.9867 20.2439 12.5824 20.2439 12.0843L20.2439 7.70732C20.2439 7.21008 19.8397 6.80488 19.3415 6.80488L6.70732 6.80488C6.20918 6.80488 5.80488 7.21008 5.80488 7.70732L5.80488 20.3415C5.80488 20.8387 6.20918 21.2439 6.70732 21.2439L13.9269 21.2439C14.4259 21.2439 14.8293 21.6482 14.8293 22.1464C14.8293 22.6445 14.4259 23.0488 13.9269 23.0488L6.70732 23.0488C5.21469 23.0488 4 21.8341 4 20.3415Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 25C23.7614 25 26 22.7614 26 20C26 17.2386 23.7614 15 21 15C18.2386 15 16 17.2386 16 20C16 22.7614 18.2386 25 21 25ZM20.6516 17.5303C20.6516 17.2374 20.889 17 21.1819 17C21.4748 17 21.7122 17.2374 21.7122 17.5303V19.6515H23.8333C24.1262 19.6515 24.3636 19.8889 24.3636 20.1818C24.3636 20.4747 24.1262 20.7121 23.8333 20.7121H21.7122V22.8333C21.7122 23.1262 21.4748 23.3636 21.1819 23.3636C20.889 23.3636 20.6516 23.1262 20.6516 22.8333V20.7121H18.5303C18.2374 20.7121 18 20.4747 18 20.1818C18 19.8889 18.2374 19.6515 18.5303 19.6515H20.6516V17.5303Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Request",
};
</script>
