<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchProceduresQueue from "./action/SearchProceduresQueue";

export default {
  name: "RgSelectProceduresQueueExam",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Procedimento",
    },
    unitHealthId: {
      default: null,
    },
    hasRegularPermission: Boolean,
    hasViewRequestUnitHealthPermission: Boolean,
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    unitId() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const procedures = await SearchProceduresQueue({
        unitHealthId: this.unitHealthId,
        hasRegularPermission: this.hasRegularPermission,
        hasViewRequestUnitHealthPermission: this
          .hasViewRequestUnitHealthPermission,
      });

      this.valuesData = procedures.map((item) => {
        return {
          value: item.id,
          label: item.nome,
          procedure: item.procedimento,
        };
      });
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
