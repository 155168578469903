<style src="./LargeButton.scss" lang="scss" scoped></style>
<template>
  <div class="large-button">
    <RgButton
      :id="id"
      :data-id="dataId"
      :disabled="disabled"
      :style="{
        background: backgroundColor,
      }"
      :title="title"
      :permission="permission"
      class="button"
      @click="clicked"
    >
      <div
        class="label"
        :style="{
          color: disabled ? '#fff !important' : fontColor,
        }"
      >
        {{ label }}
      </div>
      <div v-show="separator" class="separator" />
      <div class="iconarea">
        <slot name="icon" class="icon" />
      </div>
    </RgButton>
  </div>
</template>
<script>
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "LargeButton",
  components: { RgButton },
  mixins: [RgButtonMixin],
  props: {
    separator: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "#53B1D8",
    },
    fontColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>
