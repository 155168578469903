<template lang="html">
  <div v-if="show" class="modal-hospitalization-reason">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span
          v-if="hasReceivedTitle"
          class="title default unselect -desinfecting"
        >
          {{ title }}
        </span>

        <div v-else class="title unselect">
          Alterar leito de

          {{
            isBusySituation
              ? "OCUPADO"
              : isMaintenanceSituation
              ? "MANUTENÇÃO"
              : isDesinfectionSituation
              ? "DESINFECÇÃO"
              : isReservationSituation
              ? "RESERVA"
              : isEmptySituation
              ? "VAZIO"
              : ""
          }}

          para

          {{
            isBusyOperation
              ? "OCUPADO"
              : isMaintenanceOperation
              ? "MANUTENÇÃO"
              : isDesinfectionOperation
              ? "DESINFECÇÃO"
              : isReservationOperation
              ? "RESERVA"
              : isEmptyOperation
              ? "VAZIO"
              : ""
          }}
        </div>
      </div>

      <div slot="body" class="body">
        <div class="information">
          <div class="status">
            <IconBed
              :color="setColorSituation"
              :shadow="setShadowSituation"
              class="bed-svg"
            />

            <IconArrowRight class="exchange-svg" />

            <IconBed
              :color="setColorOperation"
              :shadow="setShadowOperation"
              class="bed-svg"
            />
          </div>

          <div
            :class="{
              'area-three-columns': hospitalizationId,
              'area-two-columns': !hospitalizationId,
            }"
          >
            <span v-if="hospitalizationId" class="data">
              Paciente:
              <strong>
                {{ namePatient(hospitalization) }}
              </strong>
            </span>

            <span class="data">
              Unidade de Saúde:
              <strong>
                {{
                  hospitalization ? hospitalization.uns_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Local de Atendimento:
              <strong>
                {{
                  hospitalization ? hospitalization.lca_nome : "Não informado"
                }}
              </strong>
            </span>

            <span v-if="hospitalizationId" class="data">
              Número da Internação:
              <strong>
                {{
                  hospitalization ? hospitalization.int_numero : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Setor:
              <strong>
                {{
                  hospitalization ? hospitalization.set_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Leito:
              <strong>
                {{
                  hospitalization ? hospitalization.inl_nome : "Não informado"
                }}
              </strong>
            </span>
          </div>
        </div>
        <div class="textarea">
          <RgTextArea
            id="reason"
            ref="textArea"
            v-model="reason"
            :maxlength="500"
            :rules="{ required: true }"
            label="Motivo"
          />
        </div>
      </div>

      <div slot="footer" class="footer">
        <RgCleanButton id="clear-btn" small @click="cleanReason" />

        <RgCancelButton id="cancel-btn" medium @click="close" />

        <RgSaveButton
          id="save-btn"
          :class="{ 'disable not-allowed': !disableSaveButton }"
          :disabled="!disableSaveButton"
          medium
          @click="changeFlow"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCleanButton,
  RgSaveButton,
  RgTextArea,
  RgCancelButton,
} from "~tokio/primitive";
import {
  GetGQLEnum,
  BedSituations,
} from "$hospitalization/common/component/util/GetTypeBedValues";
import { IconBed, IconArrowRight } from "~tokio/primitive/icon/symbols";
import TypeBedOperationEnum from "$hospitalization/common/component/util/TypeBedOperationEnum";

export default {
  name: "ModalHospitalizationReason",
  components: {
    RgBaseModal,
    RgCleanButton,
    RgSaveButton,
    RgCancelButton,
    IconBed,
    RgTextArea,
    IconArrowRight,
  },
  props: {
    bedId: {
      type: Number,
      default: null,
    },
    hospitalization: {
      type: Object,
      default: () => {},
    },
    hospitalizationId: {
      type: Number,
      default: null,
    },
    indigentId: {
      type: Number,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: null,
    },
    operation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      reason: "",
      disableSaveButton: false,
    };
  },
  computed: {
    hasReceivedTitle() {
      return this.title !== null;
    },

    isEmptySituation() {
      return this.status === BedSituations.VAZIO;
    },

    isMaintenanceSituation() {
      return this.status === BedSituations.MANUTENCAO;
    },

    isBusySituation() {
      return this.status === BedSituations.OCUPADO;
    },

    isDesinfectionSituation() {
      return this.status === BedSituations.DESINFECCAO;
    },

    isReservationSituation() {
      return this.status === BedSituations.RESERVA;
    },

    isEmptyOperation() {
      return this.operation === BedSituations.VAZIO;
    },

    isMaintenanceOperation() {
      return this.operation === BedSituations.MANUTENCAO;
    },

    isBusyOperation() {
      return this.operation === BedSituations.OCUPADO;
    },

    isDesinfectionOperation() {
      return this.operation === BedSituations.DESINFECCAO;
    },

    isReservationOperation() {
      return this.operation === BedSituations.RESERVA;
    },

    setColorSituation() {
      if (this.isEmptySituation) {
        return "#66D48F";
      } else if (this.isReservationSituation) {
        return "#5295E2";
      } else if (this.isBusySituation) {
        return "#F96B70";
      } else if (this.isDesinfectionSituation) {
        return "#EDC76A";
      } else {
        return "#ABABAB";
      }
    },

    setShadowSituation() {
      if (this.isEmptySituation) {
        return "#66D48F";
      } else if (this.isReservationSituation) {
        return "#5295E2";
      } else if (this.isBusySituation) {
        return "#F96B70";
      } else if (this.isDesinfectionSituation) {
        return "#EDC76A";
      } else {
        return "#ABABAB";
      }
    },

    setColorOperation() {
      if (this.isEmptyOperation) {
        return "#5fd38d";
      } else if (this.isReservationOperation) {
        return "#80b3ff";
      } else if (this.isBusyOperation) {
        return "#ff5555";
      } else if (this.isDesinfectionOperation) {
        return "#efc764";
      } else {
        return "#ababab";
      }
    },

    setShadowOperation() {
      if (this.isEmptyOperation) {
        return "#2ca05a";
      } else if (this.isReservationOperation) {
        return "#0066ff";
      } else if (this.isBusyOperation) {
        return "#ff2a2a";
      } else if (this.isDesinfectionOperation) {
        return "#ffcc00";
      } else {
        return "#b3b3b3";
      }
    },
  },
  watch: {
    reason(pValue) {
      !pValue.trim() || pValue.length < 5
        ? (this.disableSaveButton = false)
        : (this.disableSaveButton = true);
    },
  },
  methods: {
    async changeFlow() {
      const isReasonSet = this.reason || this.reason.length < 5;
      try {
        if (!isReasonSet)
          throw new Error("O motivo deve ter no mínimo 5 caracteres.");

        let successMessage = "Leito atualizado com sucesso!";

        let operationType = TypeBedOperationEnum.MOVIMENTACAO;

        if (this.isReservationSituation) {
          operationType = TypeBedOperationEnum.EXCLUIR_RESERVA;
          successMessage = "Reserva excluída com sucesso!";
        }
        if (this.isBusySituation) {
          operationType = TypeBedOperationEnum.EXCLUIR_INTERNACAO;
          successMessage = "Internação excluída com sucesso!";
        }

        const variables = {
          int_id: this.hospitalizationId,
          inl_id: this.bedId,
          ihs_observacao: this.reason,
          situation: GetGQLEnum(this.status),
          new_situation: GetGQLEnum(this.operation),
          ihs_tipo_operacao: operationType,
          ini_id: this.indigentId,
        };
        this.$loader.start();
        await this.$store.dispatch(
          "Hospitalization/Bed/FLOW_CHANGE",
          variables,
        );
        this.$toaster.success(successMessage);
        this.$emit("reSearch", true);
        this.close(true);
      } catch (Err) {
        if (isReasonSet) {
          this.$toaster.error(Err);
        } else {
          this.$toaster.error("Problemas ao atualizar leito");
        }
      } finally {
        this.$loader.finish();
      }
    },

    namePatient(pValue) {
      if (!pValue) {
        return "NÃO INFORMADO";
      }

      if (pValue.paciente) {
        // Modal na busca de Internação
        if (pValue.paciente !== "INDIGENTE") {
          return pValue.paciente;
        }
        if (pValue.nome_paciente === "INDIGENTE") {
          return "PACIENTE NÃO IDENTIFICADO";
        }
      }

      if (pValue.nome_paciente) {
        // Modal na busca de Leitos
        if (pValue.nome_paciente !== "INDIGENTE") {
          return pValue.nome_paciente;
        }
        if (pValue.nome_paciente === "INDIGENTE") {
          return "PACIENTE NÃO IDENTIFICADO";
        }
      }
    },

    cleanReason() {
      this.reason = "";
      this.$refs.textArea.resetCharacter();
      this.$refs.textArea.cleanValidate();
    },

    close(pActionTaken = false) {
      this.cleanReason();
      this.$emit("close", pActionTaken === true);
    },
  },
};
</script>
