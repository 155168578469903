<template lang="html">
  <div v-if="show" class="modal-group-by">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">Agrupar resultados da pesquisa</div>

      <div slot="body" class="body">
        <span>
          Selecione o critério de agrupamento marcando uma opção
          <br />disponível abaixo:
        </span>

        <RgRadioCustom
          id="groupby"
          ref="groupby"
          v-bind="propsRadioCustom"
          :disableByComponent="disableByComponent"
          class="groupby"
          reorganize
          @input="onInputRadioCustom"
        />
      </div>

      <div slot="footer" class="footer">
        <RgCleanButton
          id="clean-modal-confirm"
          small
          class="yes"
          @click="clean"
        />
        <MediumButton
          id="continue-modal-confirm"
          label="Salvar"
          @click="close"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCleanButton,
  RgRadioCustom,
  MediumButton,
} from "~tokio/primitive";
export default {
  name: "ModalConfirmFieldClearing",
  components: {
    RgBaseModal,
    RgCleanButton,
    RgRadioCustom,
    MediumButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default:
        "Deseja realmente limpar todos os campos preenchidos nesse cadastro?",
    },
    report: {
      type: String,
      default: null,
    },
    cleanGroupBy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      disableByComponent: [],
      groupBy: null,
      groupByName: null,
    };
  },
  computed: {
    propsRadioCustom() {
      let list = [];
      if (this.report === "AppointmentUserProductivity") {
        list = [
          { title: "Usuário", id: 1 },
          { title: "Unidade de Saúde", id: 2 },
          { title: "Setor", id: 3 },
          { title: "Ocupação", id: 4 },
          { title: "Profissional", id: 5 },
          { title: "Local de Atendimento", id: 6 },
          { title: "Convênio", id: 7 },
          { title: "Objetivo de Consulta", id: 8 },
          { title: "Período de Cadastro", id: 9 },
          { title: "Período de Agendamento", id: 10 },
        ];
      }
      if (this.report === "ExamUserProductivityReport") {
        list = [
          { title: "Usuário", id: 1 },
          { title: "Unidade de Saúde", id: 2 },
          { title: "Setor", id: 3 },
          { title: "Ocupação", id: 4 },
          { title: "Profissional", id: 5 },
          { title: "Local de Atendimento", id: 6 },
          { title: "Convênio", id: 7 },
          { title: "Procedimento", id: 8 },
          { title: "Subprocedimento", id: 9 },
          { title: "Período de Cadastro", id: 10 },
          { title: "Período de Agendamento", id: 11, gridStart: 1, gridEnd: 3 },
        ];
      }
      const value = this.groupBy;
      const uniqueKey = "id";
      return { list, uniqueKey, value };
    },
  },
  watch: {
    cleanGroupBy(pValue) {
      if (pValue) {
        this.clean();
      }
    },

    groupBy(pValue) {
      if (pValue) {
        this.groupByName.title === "Subprocedimento"
          ? (this.disableByComponent = this.GROUP_BY_DISABLE_EXAM)
          : (this.disableByComponent = this.GROUP_BY_DISABLE);
      }
    },
  },
  mounted() {
    this.GROUP_BY_DISABLE = [1, 2];
    this.GROUP_BY_DISABLE_EXAM = [1, 2, 8];
    this.disableByComponent = this.GROUP_BY_DISABLE;
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.groupBy = item.id;
        this.groupByName = item;
      } else {
        this.groupBy = null;
        this.groupByName = null;
      }
    },
    clean() {
      this.groupBy = null;
      this.groupByName = null;
      this.$emit("select", this.groupByName);
    },

    close() {
      this.$emit("close");
      this.$emit("select", this.groupByName);
    },
  },
};
</script>
