import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamScheduleExcludedReport(
    $uns_id: Int
    $set_id: Int
    $ocp_id: Int
    $pes_id: Int
    $pes_nome: String
    $usu_id: Int
    $stp_id: String
    $set_nome: String
    $ocp_nome: String
    $usu_nome: String
    $stp_novo_nome_procedimento: String
    $orderby: Int
    $orderByLabel: String
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchExamScheduleExcludedReport(
      uns_id: $uns_id
      set_id: $set_id
      ocp_id: $ocp_id
      pes_id: $pes_id
      pes_nome: $pes_nome
      usu_id: $usu_id
      stp_id: $stp_id
      set_nome: $set_nome
      ocp_nome: $ocp_nome
      usu_nome: $usu_nome
      stp_novo_nome_procedimento: $stp_novo_nome_procedimento
      orderby: $orderby
      orderByLabel: $orderByLabel
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        DATA_HORA_EXAME
        PACIENTE
        CNS
        PRONTUARIO_UNIDADE
        PROCEDIMENTO
        PROFISSIONAL
        OCUPACAO
        SETOR
        AGENDADO_POR
        DATA_HORA_EXCLUSAO
        EXCLUIDO_POR
        MOTIVO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamScheduleExcludedReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
