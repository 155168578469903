<template slot="subFilters">
  <div class="pharmacy-satellite-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />
        <RgSelectLotExpiration
          ref="lotExpiration"
          v-model="form.lot"
          :pharmacyId="Number(pharmacyId)"
          :productId="Number(productId)"
          :isEntry="true"
          :disabled="!productId || !pharmacyId"
          :class="{
            disable: !productId || !pharmacyId,
          }"
          @change="selectedLotExpiration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration";

const FORM_FILTER = {
  productTypeId: null,
  productTypeName: null,
  lot: null,
  lotAndExpiration: null,
  columnsToPrint: [],
};

export default {
  name: "PharmacySatelliteEntryReport",
  components: {
    RgSelectProductType,
    RgSelectLotExpiration,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      pharmacyId: null,
      productId: null,
      userId: this.$store.getters["Login/GET_USER_ID"],
    };
  },

  watch: {
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
    globalFilters: {
      handler(pValue) {
        this.pharmacyId = pValue.pharmacy;
        this.productId = pValue.product;
        if (!pValue.pharmacy) {
          this.clearSubFilter("pharmacy");
        }
        if (!pValue.product) {
          this.clearSubFilter("product");
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Farmácia Origem", key: "FARMACIA_ORIGEM", align: "left" },
      { name: "Farmácia Destino", key: "FARMACIA_DESTINO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Entrada", key: "ENTRADA", align: "right" },
    ]);
  },

  methods: {
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          unitHealthId: this.globalFilters.unitHealth,
          unitHealthName: this.globalFilters.unitHealthName,
          productId: Number(this.globalFilters.product),
          productName: this.globalFilters.productName,
          pharmacyId: Number(this.globalFilters.pharmacy),
          pharmacyName: this.globalFilters.pharmacyName,
          productTypeId: Number(this.form.productTypeId),
          productTypeName: this.form.productTypeName,
          lot: this.form.lot,
          lotAndExpiration: this.form.lotAndExpiration,
          initialDate: this.globalFilters.initialDate,
          finalDate:
            this.globalFilters.finalDate || moment().format("DD/MM/YYYY"),
          loggedUserId: this.userId,
          columnsToPrint: this.columnsToPrint,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_SATELLITE_ENTRY",
          variables,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    selectedProductType(pType) {
      const hasSuggestion = pType && Object.keys(pType).length > 0;
      if (hasSuggestion) {
        this.form.productTypeName = pType.label;
      } else {
        this.form.productTypeName = "";
      }
    },
    selectedLotExpiration(pValue) {
      if (pValue) {
        this.form.lotAndExpiration = pValue.label;
      } else {
        this.form.lotAndExpiration = null;
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
    clearSubFilter(option) {
      switch (option) {
        case "pharmacy":
        case "product":
          this.form.lotAndExpiration = null;
          this.form.lot = null;
          break;
        default:
          break;
      }
    },
  },
};
</script>
