<template lang="html">
  <div v-if="show" class="modal-remove-hospitalization-from-bed">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" />

      <div slot="body" class="body">
        <div class="text">
          <span>Deseja realmente cancelar a reserva do leito?</span>
        </div>
      </div>

      <div slot="footer" class="footer">
        <button class="yes" @click="getYes">Sim</button>

        <button class="out" @click="getOut">Não</button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalRemoveHospitalizationFromBed",
  components: {
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getYes() {
      this.$emit("getYes", 1);
    },
    getOut() {
      this.$emit("getOut", 2);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
