<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3574 0.799683C10.6647 0.869042 10.9778 0.913222 11.2766 1.02154C13.67 1.88567 14.5113 4.87381 12.9175 6.85861C12.8101 6.99258 12.6894 7.11609 12.5826 7.25006C11.6932 8.3655 11.6001 10.109 12.3621 11.3375C12.3982 11.3959 12.4334 11.4553 12.4771 11.528H7.52458C7.73978 11.1907 7.92506 10.8548 8.0177 10.4805C8.33171 9.20921 8.12791 8.04579 7.24477 7.05006C5.32647 4.88806 6.37636 1.5479 9.18634 0.888519C9.32744 0.855265 9.47661 0.863341 9.61057 0.799683H10.3569H10.3574Z"
      fill="white"
    />
    <path
      d="M9.99468 16.3337C7.54859 16.3337 5.10298 16.3337 2.65689 16.3337C2.19513 16.3337 2.0089 16.1404 2.00178 15.6743C1.99418 15.1807 2.00178 14.6895 2.21508 14.2306C2.69299 13.2026 3.4987 12.6159 4.63362 12.6092C8.21035 12.5883 11.7876 12.5974 15.3648 12.6035C16.8042 12.6064 17.9805 13.7888 17.9985 15.2259C18.0009 15.3974 17.9999 15.5693 17.999 15.7413C17.9971 16.1185 17.7824 16.3332 17.4028 16.3337C15.1225 16.3342 12.8427 16.3337 10.5624 16.3337C10.3728 16.3337 10.1833 16.3337 9.99373 16.3337H9.99468Z"
      fill="white"
    />
    <path
      d="M10.01 17.4002C12.2604 17.4002 14.5103 17.4011 16.7606 17.3978C16.8899 17.3978 16.9454 17.4163 16.9378 17.565C16.9241 17.8543 16.9359 18.1451 16.9331 18.4353C16.9298 18.7774 16.7079 18.9992 16.363 18.9992C12.1231 19.0002 7.88318 19.0002 3.64374 18.9992C3.29077 18.9992 3.07319 18.776 3.07129 18.4197C3.06939 18.1356 3.07984 17.851 3.06701 17.5669C3.06036 17.423 3.10787 17.3964 3.24183 17.3969C5.46227 17.4011 7.68318 17.3997 9.90361 17.3997C9.93924 17.3997 9.97487 17.3997 10.01 17.3997V17.4002Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Revenue",
};
</script>
