<template>
  <div class="appointment-queue-report-tooltip">
    <Tooltip class="tooltip" startOpen>
      <IconInfoHelper slot="icon" hollow />
      <div slot="content" class="content">
        <span> O relatório não necessita de filtros para sua geração. </span>
        <p>Informações:</p>
        <ul class="list">
          <li class="item">
            Será gerado uma listagem com a quantidade de pacientes na fila de
            consultas por ocupação;
          </li>
        </ul>
      </div>
    </Tooltip>
  </div>
</template>

<script>
import { Tooltip, IconInfoHelper } from "~tokio/primitive";
export default {
  name: "AppointmentQueueReport",
  components: { Tooltip, IconInfoHelper },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Filas de Consultas",
      cleanSelectedReport: true,
    };
  },

  computed: {
    printReportCss() {
      return `@page {size: landscape;}`;
    },

    getNameSmartTable() {
      return "AppointmentQueueReport";
    },

    columnTable() {
      return this.COLUMN;
    },
  },
  created() {
    this.COLUMN = [
      { name: "Código", key: "CODIGO" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Inserido na Fila", key: "DATA" },
      { name: "Unidade de Saúde", key: "UNIDADE", align: "left" },
    ];
  },

  methods: {
    buildFilter() {},

    async doSearch(pData) {
      try {
        pData.columnsToPrint = this.columnsToPrint;
        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_QUEUE_LIST_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {},
  },
};
</script>
