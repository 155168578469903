import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import ServicesRatings from "@/app/billing/submodules/bpa/services-ratings/view/search/ServicesRatings.vue";

export default {
  path: "/billing/bpa/services-ratings",
  name: "billing.bpa.servicesRatings",
  components: {
    default: ServicesRatings,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      { label: "BPA", link: OpenSubModule("billing") },
      { label: "Serviços e Classificações", link: "/services-ratings" },
    ],
  },
};
