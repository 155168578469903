<template>
  <section class="quick-patient-details">
    <RgValidatorForm ref="validatorForm" class="content">
      <PersonPersonalData
        id="personal-data"
        ref="personal"
        class="mg-form"
        :disabledByModal="disabledByModal || this.modalPersonalData"
        @loadFromCadsus="loadFromCadsus"
        @disabledByModalComponents="disabledByModalComponents"
        @birthDateChanged="birthDate = $event"
        @listDeficient="selectListDeficient"
      />

      <PersonAddress id="address-data" ref="address" class="mg-form" />

      <PersonDocument
        id="document-data"
        ref="document"
        class="mg-form"
        :is-update="this.existsPesId"
        :birthDate="birthDate"
        :requiredCPF="cpfIsRequired"
        :requiredCNS="cnsIsRequired"
        :disabledByModal="disabledByModal || this.modalPersonalData"
        @disabledByModalComponents="disabledByModalComponents"
      />

      <PersonContacts id="contacts-data" ref="contacts" class="mg-form" />

      <PatientForm
        v-if="!hasLinkNewborn"
        id="patient-data"
        ref="patient"
        class="mg-form"
        @isBloquedPatient="getAndEmitPacBloqueadoValue"
      />
    </RgValidatorForm>
  </section>
</template>

<script>
import { DataDBFormatter } from "$person/helper/PersonDataFormatter";
import { RoutesPatientHistory } from "$person/helper/ExistsRoutesPatientHistory";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import { RgValidatorForm } from "~tokio/primitive";

import {
  PersonPersonalData,
  PersonAddress,
  PersonDocument,
  PersonContacts,
} from "$person/common/components/";

import { PatientForm } from "$person/patient/component/";
import { toast, AlertError } from "~tokio/primitive/notification";

import { mapGetters } from "vuex";

export default {
  name: "QuickPatientDetails",
  components: {
    RgValidatorForm,
    PersonPersonalData,
    PersonDocument,
    PersonAddress,
    PersonContacts,
    PatientForm,
  },
  props: {
    hasLinkNewborn: {
      type: Boolean,
      default: false,
    },
    showAnvilButton: {
      type: Boolean,
      default: false,
    },
    disabledByModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      birthDate: null,
      requiredCPF: false,
      modalPersonalData: false,
      checkedUplodFiles: null,
    };
  },
  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      isNewBorn: "Person/Patient/IS_NEWBORN",
      attachments: "Person/Patient/GET_ATTACHMENTS",
      isPrivateSector: "Login/GET_PRIVATE_SECTOR",
      preferences: "Login/GET_DOCUMENT_PREFERENCES",
    }),

    cpfIsRequired() {
      if (this.isPrivateSector) {
        return true;
      }

      const hasRequiredCPFPreference = this.validatePreferences("requiredCPF");
      const hasRequiredCPFUpdatePreference = this.validatePreferences(
        "requiredCPFUpdate",
      );

      if (this.existsPesId && !this.isNewBorn) {
        return hasRequiredCPFUpdatePreference
          ? !!this.preferences.requiredCPFUpdate
          : false;
      } else {
        return hasRequiredCPFPreference
          ? !!this.preferences.requiredCPF
          : false;
      }
    },

    cnsIsRequired() {
      const isFromBioslabDetails = ValidateIfRouteExistInBreadscrumb(
        "/exam/laboratory/details",
        this.$route.meta.breadcrumb,
      );

      if (isFromBioslabDetails) {
        return true;
      }

      const hasRequiredCNSPreference = this.validatePreferences("requiredCNS");
      const hasRequiredCNSUpdatePreference = this.validatePreferences(
        "requiredCNSUpdate",
      );

      if (this.existsPesId && !this.isNewBorn) {
        return hasRequiredCNSUpdatePreference
          ? !!this.preferences.requiredCNSUpdate
          : false;
      } else {
        return hasRequiredCNSPreference
          ? !!this.preferences.requiredCNS
          : false;
      }
    },
  },

  created() {
    this.FORM_ERROR = 1;
    this.DOCUMENT_ERROR = 2;
    this.TELEPHONE_ERROR = 3;
  },

  beforeDestroy() {
    const validateIfExist = RoutesPatientHistory(this.$route.meta.breadcrumb);

    if (!validateIfExist) {
      this.destroyPersonInfo();
    }

    this.$store.commit("Person/Patient/SET_PERSON_DEFICIENT", []);
  },

  async mounted() {
    const pData = this.$route.query;
    if (pData && pData.newPatient) {
      this.destroyPersonInfo();
    }
    await this.setPatientInfo();
  },

  methods: {
    disabledByModalComponents(disabledByModal) {
      this.modalPersonalData = disabledByModal;
      this.$emit("disabledByModalComponents", this.modalPersonalData);
      return this.modalPersonalData;
    },

    getAndEmitPacBloqueadoValue(pValue) {
      this.$emit("isBloquedPatient", pValue);
    },

    validatePreferences(Property) {
      return (
        this.preferences &&
        Object.keys(this.preferences).length > 0 &&
        this.preferences[Property] !== undefined
      );
    },

    goBack() {
      const routerValid = ValidateIfRouteExistInBreadscrumb(
        "/person/patient/details",
        this.$route.meta.breadcrumb,
      );

      const isHospitalizationSearchBed =
        this.$route.name ===
          "hospitalization.bed.search.hospitalization.new-patient" ||
        this.$route.name ===
          "hospitalization.bed.search.hospitalization.edit-patient";

      if (isHospitalizationSearchBed) {
        this.$router.push({
          name: "hospitalization.bed.search.new-hospitalization",
          params: {
            form: this.$route.params.form,
            fromMapBed: true,
            operation: this.$route.params.operation,
          },
        });
      } else {
        routerValid
          ? this.$router.push("/person/patient/search")
          : this.$router.go(-1);
      }
    },

    loadFromCadsus(pData) {
      this.$refs.address.loadFromCadsus(pData);
      this.$refs.document.loadFromCadsus(pData);
      this.$refs.contacts.loadFromCadsus(pData);
    },

    async setPatientInfo() {
      try {
        if (this.existsPesId) {
          this.$loader.start();
          const pPesId = this.$store.getters["Person/Patient/GET_PERSON_ID"];
          await this.$store.dispatch("Person/Patient/GET_PATIENT_BY_ID", {
            pesId: pPesId,
            logType: 1,
          });
          this.$loader.finish();
        }
      } catch (error) {
        this.$loader.finish();
        this.$toaster.error("Erro ao carregar os dados do paciente");
      }
    },

    async checkUploadedFiles(photoPerfil) {
      const files = [];

      const isNePerfilPhoto = photoPerfil && photoPerfil !== "FOTO_PERFIL.JPG";

      if (isNePerfilPhoto) {
        files.push(photoPerfil);
      }

      const hasFiles = files.length > 0;

      if (hasFiles) {
        const { data } = await this.$store.dispatch(
          "Upload/CHECK_UPLOADED_FILES",
          {
            files,
          },
        );

        this.checkedUplodFiles = data;
      }
    },

    removeInvalidUploadedFiles(pData) {
      const hasFilesToCheck = this.checkedUplodFiles?.length > 0;
      const data = this.$utils.obj.DeepCopy(pData);
      let removed = false;

      if (hasFilesToCheck) {
        this.checkedUplodFiles.forEach((item) => {
          const isInvalidPhotoPerfil =
            item.file === data.person.pes_foto && item.exists === false;

          if (isInvalidPhotoPerfil) {
            this.$refs.personal.setPhotoPerfilDefault();
            data.person.pes_foto = null;

            this.$toaster.warning(
              "Ocorreu um problema com upload da foto de perfil",
            );

            removed = true;
          }
        });
      }

      return { data, removed };
    },

    async savePatient() {
      try {
        this.$loader.start();

        const person = this.$refs.personal.getPersonalForm();
        const address = this.$refs.address.getAddressForm();
        const contacts = this.$refs.contacts.getContactForm();
        const documents = await this.$refs.document.getDocumentForm();
        const appNotification = this.$refs.document.getNotification();
        const attachments = this.$refs.document.getNewAttachments();
        const patient = this.$refs.patient.getPatientForm();

        const isValid = await this.$refs.validatorForm.validate();

        const documentValidation =
          this.cnsIsRequired === true &&
          !documents?.cns?.crs_numero &&
          this.cpfIsRequired === true &&
          !documents?.cpf?.cpf_numero;

        if (documentValidation) {
          this.$toaster.warning("O campo CNS é obrigatório");
          this.$toaster.warning("O campo CPF é obrigatório");
          return false;
        }

        if (this.cnsIsRequired === true && !documents?.cns?.crs_numero) {
          this.$toaster.warning("O campo CNS é obrigatório");
          return false;
        }

        if (this.cpfIsRequired === true && !documents?.cpf?.cpf_numero) {
          this.$toaster.warning("O campo CPF é obrigatório");
          return false;
        }

        const validateTelefone = await this.$refs.contacts.validateRequiredPhone();

        if (!isValid) {
          throw new Error(this.FORM_ERROR);
        }

        if (!validateTelefone) {
          throw new Error(this.TELEPHONE_ERROR);
        }

        let personData = {
          person: person.personForm,
          foreign: person.personForeignForm,
          address: address,
          attachments: attachments,
          documents: documents,
          contact: contacts,
          appNotification: appNotification,
          patient: patient,
          personDeficient:
            person?.personDeficient?.length > 0 ? person?.personDeficient : [],
        };

        await this.checkUploadedFiles(personData.person.pes_foto);
        const { data, removed } = this.removeInvalidUploadedFiles(personData);

        personData = data;

        if (removed) {
          return;
        }

        const variables = DataDBFormatter(data);

        const isCreate = !this.existsPesId || this.isNewBorn;

        let idPerson = 0;

        if (isCreate) {
          const personCreated = await this.$store.dispatch(
            "Person/Patient/CREATE_PATIENT",
            { ...variables },
          );

          idPerson = personCreated.rows.pes_id;

          this.toasterManager(personCreated);
        } else {
          variables.personId = person.personForm.pes_id;
          idPerson = variables.personId;

          const personUpdated = await this.$store.dispatch(
            "Person/Patient/UPDATE_PATIENT",
            { ...variables },
          );

          this.toasterManager(personUpdated);
        }

        // Salvando na store apenas para rotas que não vieram diretamento do menu > pacientes
        if (idPerson && this.$route.meta.breadcrumb[0].label !== "Pacientes") {
          this.$store.commit(
            "Person/Patient/SET_LAST_PERSON_ID_SAVE",
            idPerson,
          );
        }
      } catch (error) {
        this.$emit("fail");

        if (Number(error.message) === this.FORM_ERROR) {
          AlertError("Verifique os campos");
        } else if (Number(error.message) === this.DOCUMENT_ERROR) {
          AlertError("Verifique os outros documentos");
        } else if (Number(error.message) === this.TELEPHONE_ERROR) {
          AlertError("Verifique os telefones");
        } else if (this.existsPesId) {
          toast.error(error, "Erro ao atualizar: ");
        } else {
          toast.error(error);
        }
      } finally {
        this.$loader.finish();
      }
    },

    destroyPersonInfo() {
      this.$store.commit("Person/Patient/DESTROY_PERSON_INFO");
    },

    toasterManager(pData) {
      if (pData.success) {
        toast.success(`${pData.message}`);
      } else {
        toast.warning(`${pData.message}`);
      }

      this.goBack();
    },

    clean() {
      this.$refs.personal.cleanForm();
      this.$refs.address.cleanForm();
      this.$refs.contacts.cleanForm();
      this.$refs.document.cleanDocument();
      this.$refs.document.cleanNotification();
      this.$refs.patient.cleanForm();
      this.$store.commit("Person/Patient/DESTROY_PERSON_INFO");
    },

    selectListDeficient(listDeficient) {
      this.$emit("listDeficient", listDeficient);
    },

    loadDeficient() {
      this.$refs.personal.loadDeficient();
    },
  },
};
</script>
