import AuthRequest from "~common/request/AuthRequest";
export default (pBlob, pData) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  formData.append("file", pBlob);
  return AuthRequest.post(
    `import-sigtap-bdsia/upload-from-bdsia/?userId=${pData.userId}&link=${pData.link}`,
    formData,
    headers,
  );
};
