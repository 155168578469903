import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

import { BrazilianDateFormat } from "~utils/date";
const query = gql`
  query People(
    $name: String
    $mother: String
    $birthday: String
    $recordNumber: String
    $active: Boolean
    $notUnified: Boolean
    $isNotEmployee: Boolean
    $limit: Int
    $offset: Int
  ) {
    people(
      pes_nome: $name
      pes_mae: $mother
      pes_nascimento: $birthday
      pac_prontuario_unico: $recordNumber
      pes_ativo: $active
      notUnified: $notUnified
      isNotEmployee: $isNotEmployee
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        pes_id
        pes_nome
        pes_nascimento
        pes_mae
        pes_ativo
        pes_id_sexos
        patient {
          pac_id
          pac_prontuario_unico
          record_numbers {
            rows {
              ppr_id
              ppr_id_pacientes
              ppr_id_unidades_saude
              ppr_numero
            }
          }
        }
        cns {
          crs_id
          crs_numero
        }
        cpf {
          cpf_id
          cpf_numero
        }
        pisPasep {
          pis_id
          pis_numero
        }
      }
    }
  }
`;
const formatData = (pFilters) => {
  const filters = { ...pFilters };
  if (pFilters.birthday && pFilters.birthday.length === 10) {
    filters.birthday = pFilters.birthday.split("/").reverse().join("-");
  }

  return filters;
};

export default async ({ commit }, filters = {}) => {
  try {
    const variables = formatData(filters);

    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    data.people.rows.map((item) => {
      item.pes_nascimento = BrazilianDateFormat(item.pes_nascimento);
    });

    return data.people;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
