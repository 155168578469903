<style src="./RgSuggestCiapTypeClassification.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="innerSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :disabled="disabled"
    :rules="rules"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="ciap-item"
    >
      <div class="ciap-row-info">
        <div class="cia-code">
          <span>{{ item.cia_codigo }} - {{ item.cia_descricao }}</span>
        </div>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchCiapTypeClassification from "./action/SearchCiapTypeClassification";

export default {
  name: "RgSuggestCboUnifiedTable",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Ciap",
    },
    title: {
      type: String,
      default: "Ciap",
    },
    placeholder: {
      type: String,
      default: "Digite a ciap",
    },
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "cia_codigo" + " - " + "cia_descricao",
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.innerSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    doSearch(pSearchTerm, pLimit = null, offset = 0) {
      const variables = {
        limit: pLimit,
        offset: offset,
      };

      const isToSearchByCode = this.isCode(pSearchTerm);

      if (isToSearchByCode) {
        variables.code = pSearchTerm;
      } else {
        variables.description = pSearchTerm;
      }

      return SearchCiapTypeClassification({
        variables,
      });
    },

    isCode(pValue) {
      return /^[a-zA-Z][\d]+$/.test(pValue);
    },

    forceSelection(pValue) {
      const isValid =
        pValue &&
        !isEmpty(pValue.cia_descricao) &&
        pValue.cia_id > 0 &&
        !isEmpty(pValue.cia_descricao);

      if (isValid) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            cia_id: pValue.cia_id,
            cia_codigo: pValue.cia_codigo,
            cia_descricao: pValue.cia_descricao,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
        this.validate();
      }
    },
  },
};
</script>
