<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchLotExpiration from "./action/SearchLotExpiration";

export default {
  name: "RgSelectLotExpiration",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Lote e Validade",
    },
    pharmacyId: {
      type: Number,
      default: 0,
    },
    isEntry: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: 0,
    },
    execCallback: String,
    callback: Function,
    blnSomenteDisponiveis: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      batch: [],
      allData: [],
      selectFirst: null,
    };
  },
  watch: {
    productId(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    pharmacyId(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    batch: function (val) {
      this.updateOptions();
    },
    execCallback: {
      handler(pValue) {
        if (pValue)
          this.callback(
            this.allData.filter((element) => element.data === pValue)[0],
          );
      },
      deep: true,
    },
  },
  mounted() {
    this.selectFirst = this.$store.getters["Login/GET_PREFERENCES"][
      "tViewFarmacia.con_estado_combo_lote"
    ];
    this.doSearch();
  },
  methods: {
    getData() {
      return this.batch;
    },
    async doSearch() {
      this.batch = [];
      if (this.pharmacyId && this.productId) {
        const data = await SearchLotExpiration({
          intIdFarmacia: this.pharmacyId,
          intIdProduto: this.productId,
          blnSomenteDisponiveis: this.blnSomenteDisponiveis,
        });

        this.batch = data.map((item) => {
          return {
            value: item.data,
            label: item.label,
            fel_validade_lote: item.fel_validade_lote,
            trs_data: item.trs_data,
          };
        });
        this.allData = data;
        if (
          this.selectFirst === "1" &&
          this.blnSomenteDisponiveis &&
          !this.isEntry
        ) {
          this.mValue = this.batch[0];

          this.batch[0]
            ? this.$emit("change", this.batch[0])
            : this.$emit("change", null);

          this.batch[0]?.value
            ? this.$emit("input", this.batch[0].value)
            : this.$emit("input", null);
          // this.mValue = null;
        }
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
