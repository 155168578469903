import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
export default {
  path: "/exam/schedule/queue-insert/patient-care-history",
  name: "exam.schedule.queue-insert.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: OpenSubModule("exam") },
      { label: "Inserir na Fila", link: "/exam/schedule/queue-insert" },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
