<style src="./RgInputQuantity.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input-quantity">
    <RgFormBase
      :label="'Quantidade'"
      :optional="optional"
      :required="isRequired"
    >
      <div class="rg-input-base">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
        <button
          id="decrease"
          class="decrease"
          :disabled="disabled"
          @click.stop.prevent="decreaseLimit"
        >
          -
        </button>
        <input
          :id="id"
          v-model="inputValue"
          :disabled="disabled"
          :data-id="dataId"
          :class="{ 'on-error': hasError }"
          :placeholder="placeholder"
          :tabindex="tabIndex"
          min="1"
          type="number"
          class="input-box"
          @blur="validateBlur"
        />
        <button
          id="increase"
          class="increase"
          :disabled="disabled"
          @click.stop.prevent="increaseLimit"
        >
          +
        </button>
      </div>
    </RgFormBase>
  </fieldset>
</template>
<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgInputQuantity",
  components: {
    RgFormBase,
    RgValidationAlert,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    max: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      inputValue: 1,
    };
  },

  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },

    hasError() {
      return this.error.length > 0;
    },
  },

  watch: {
    inputValue(value) {
      if (this.max) {
        if (parseInt(value) > this.max) {
          this.$toaster.warning(
            `Valor máximo que pode ser inserido é ${this.max}`,
          );
          this.inputValue = this.max;
        }
      }

      if (this.min) {
        if (parseInt(value) < this.min) {
          this.$toaster.warning(
            `Valor mínimo que pode ser inserido é ${this.min}`,
          );
          this.inputValue = this.min;
        }
      }
    },
  },

  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },

    increaseLimit() {
      const value = Number(this.inputValue);
      const newValue = Number(value) + 1;
      this.inputValue = Number(newValue);
    },

    decreaseLimit() {
      if (this.inputValue > 1) {
        const value = Number(this.inputValue);
        const newValue = Number(value) - 1;
        this.inputValue = Number(newValue);
      }
    },
  },
};
</script>
