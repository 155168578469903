// emergency.bulletin.edit-patient

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientDetails from "$person/patient/view/patient-details/PatientDetails";

export default {
  path: "/emergency/bulletin/edit-patient",
  name: "emergency.bulletin.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Atendimento", link: OpenSubModule("emergency") },
      { label: "Boletins", link: "/emergency/bulletin" },
      { label: "Editar Paciente", link: "/emergency/bulletin/edit-patient" },
    ],
  },
};
