<template>
  <div v-if="show" class="modal-change-status-product">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-change-status-product-header">
        <div class="title">{{ title }}</div>
      </div>

      <div slot="body" class="modal-change-status-product-body">
        <div class="sub-title">{{ subtitle }}</div>

        <form class="form" @submit.prevent="save">
          <span v-if="productDescription" class="product">
            {{ productDescription }}
          </span>
          <RgValidatorForm ref="validator" class="validator">
            <RgTextArea
              ref="reason"
              v-model="form.reason"
              :maxlength="225"
              :rules="{ required: true }"
              :disabled="!this.productIsEnable"
              :class="{ disable: !this.productIsEnable }"
              label="Motivo"
              class="reason"
            />
          </RgValidatorForm>
        </form>
      </div>

      <div slot="footer" class="modal-change-status-product-footer">
        <RgCancelButton medium @click="close" />
        <MediumButton
          medium
          :title="buttonTitle"
          :label="buttonTitle"
          :disabled="form.reason.length < 5"
          @click="changeStatusProduct"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCancelButton,
  MediumButton,
  RgTextArea,
  RgValidatorForm,
} from "~tokio/primitive";

export default {
  name: "ModalChangeStatusProduct",
  components: {
    RgBaseModal,
    RgCancelButton,
    MediumButton,
    RgTextArea,
    RgValidatorForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      product: {},
      form: {
        reason: "",
      },
      productDescription: null,
    };
  },
  computed: {
    productIsEnable() {
      return this.product.mpd_bloqueado === "0";
    },
    title() {
      return this.productIsEnable
        ? "Inabilitar Produto ou Medicação"
        : "Habilitar Produto ou Medicação";
    },
    subtitle() {
      return this.productIsEnable
        ? "Deseja inabilitar o estoque selecionado?"
        : "Deseja habilitar o estoque selecionado?";
    },
    buttonTitle() {
      return this.productIsEnable ? "Inabilitar" : "Habilitar";
    },
  },

  watch: {
    async show(pValue) {
      if (pValue) {
        await this.getProduct();
      } else {
        this.close();
      }
    },
  },

  methods: {
    async getProduct() {
      this.$loader.start();
      this.product = await this.$store.dispatch(
        "Pharmacy/Register/GET_PRODUCT",
        {
          intIdMunicipioProduto: this.productId,
        },
      );
      if (this.product) {
        if (!this.productIsEnable) {
          this.form.reason = this.product.mpd_obs;
        }

        this.productDescription = this.product.mpd_novo_nome
          ? this.product.mpd_novo_principio_ativo +
            " - " +
            this.product.mpd_novo_nome +
            " - " +
            this.product.objMunicipioTipoUnidade.mtu_novo_nome
          : this.product.mpd_novo_principio_ativo +
            " - " +
            this.product.objMunicipioTipoUnidade.mtu_novo_nome;
      } else {
        this.$toaster.warning("Não foi possível obter informações do produto");
      }

      this.$loader.finish();
    },
    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async changeStatusProduct() {
      const validate = await this.isFormValid();

      if (!validate) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      try {
        this.$loader.start();

        const DISABLE = 1;
        const ENABLE = 0;

        const variables = {
          mpd_id: Number(this.product.mpd_id),
          mpd_bloqueado: this.productIsEnable ? DISABLE : ENABLE,
          mpd_obs: this.productIsEnable ? this.form.reason : null,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/CHANGE_STATUS_PRODUCT",
          variables,
        );

        this.$toaster.success("Estado alterado com sucesso");

        this.$emit("reSearch");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao alterar estado do produto.");
      } finally {
        this.close();
        this.$loader.finish();
      }
    },
    cleanForm() {
      this.product = {};
      this.form.reason = "";
    },
    close() {
      this.cleanForm();
      this.$emit("close");
    },
  },
};
</script>
