<template lang="html">
  <div class="rg-content-hospitalization-childbirth">
    <RgContentHospitalizationTeam
      :procedure="procedure"
      :required-documents="requiredDocuments"
      @setProfessionalList="setProfissionalList"
    />
    <form class="form">
      <RgValidatorForm ref="validator">
        <FormBase title="Parto">
          <div class="rg-content-hospitalization-childbirth-info">
            <RgInputMasked
              v-model="childBirth.ipa_numero_sis_prenatal"
              :rules="{ required: true }"
              label="Número SIS Pré Natal"
              type="number"
              placeholder="Digite o Número do SIS"
              mask="############"
            />
            <RgInputMasked
              v-model="childBirth.ipa_qtd_nascidos_vivos"
              :rules="{ required: true }"
              label="Nascidos/Vivos"
              type="number"
              placeholder="0"
              mask="#"
            />
            <RgInputMasked
              v-model="childBirth.ipa_qtd_obito_fetal"
              :rules="{ required: true }"
              label="Óbito/Fetal"
              type="number"
              placeholder="0"
              mask="#"
            />
            <RgInputMasked
              v-model="childBirth.ipa_qtd_alta"
              :rules="{ required: true }"
              label="Alta(s)"
              type="number"
              placeholder="0"
              mask="#"
            />
            <RgInputMasked
              v-model="childBirth.ipa_qtd_transferencia"
              :rules="{ required: true }"
              label="Transferência(s)"
              type="number"
              placeholder="0"
              mask="#"
            />
            <RgInputMasked
              v-model="childBirth.ipa_qtd_obito"
              :rules="{ required: true }"
              label="Óbito(s)"
              type="number"
              placeholder="0"
              mask="#"
            />
          </div>
        </FormBase>
        <div class="buttons">
          <MediumButton
            :disabled="!childBirth.professionalTeamList.length > 0"
            title="Confirmar"
            label="Confirmar"
            backgroundColor="#0bb98c"
            @click="confirm"
          />

          <MediumButton
            title="Fechar"
            label="Fechar"
            @click.stop.prevent="close"
          />
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { RgValidatorForm, RgInputMasked, MediumButton } from "~tokio/primitive";
import RgContentHospitalizationTeam from "$billing/submodules/aih/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import mask from "vue-the-mask";
import { AlertError } from "../../../../service";
import FormBase from "~tokio/foundation/form-base/FormBase";
export default {
  name: "RgContentHospitalizationBillingChildbirth",
  components: {
    RgValidatorForm,
    RgInputMasked,
    RgContentHospitalizationTeam,
    MediumButton,
    FormBase,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      childBirth: {
        type: "childbirth",
        professionalTeamList: [],
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.childBirth = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.childBirth);
      }
    },

    setProfissionalList(event) {
      this.childBirth.professionalTeamList = event;
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      if (!this.validateNumberOfBirth()) return false;
      // if (!this.validateTeamMembers()) return false
      return valid;
    },

    validateNumberOfBirth() {
      const total =
        parseInt(this.childBirth.ipa_qtd_obito) +
        parseInt(this.childBirth.ipa_qtd_transferencia) +
        parseInt(this.childBirth.ipa_qtd_alta);
      if (parseInt(this.childBirth.ipa_qtd_nascidos_vivos) < total) {
        AlertError(
          "Número de altas, transferências e óbitos não devem ultrapassar o total de nascidos vivos",
        );
        return false;
      }
      if (
        parseInt(this.childBirth.ipa_qtd_nascidos_vivos) <
        this.childBirth.ipa_qtd_obito_fetal
      ) {
        AlertError(
          "O total de nascidos vivos não devem ser menor do que o total de obito fetal",
        );
        return false;
      }
      return true;
    },

    validateTeamMembers() {
      const hasAnesthetist = this.childBirth.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 6,
      );
      const hasSurgeon = this.childBirth.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 1,
      );
      if (hasAnesthetist.length && hasSurgeon.length) {
        return true;
      }
      AlertError(
        "Necessário ao menos um cirurgião e um anestesista na equipe.",
      );
      return false;
    },
  },
};
</script>
