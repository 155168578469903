<template lang="html">
  <section class="hospitalization-billing-civil">
    <div>
      <form class="form">
        <RgValidatorForm ref="validator">
          <FormBase title="Registro Civil">
            <div class="hospitalization-billing-civil-info">
              <RgInputMasked
                v-model="civilRegister.irc_numero_declaracao"
                :rules="{ required: true }"
                label="Nº Declaração"
                placeholder="Digite o Número da Declaração"
                mask="###########"
              />
              <RgInput
                v-model="civilRegister.irc_nome_recem_nato"
                :rules="{ required: true, validateName }"
                label="Nome do Recém Nato"
                placeholder="Digite o Nome do Recém Nascido"
              />
            </div>
            <div class="hospitalization-billing-civil-info">
              <RgInputDate
                v-model="civilRegister.irc_data_emissao"
                :rules="{ required: true }"
                label="Data Emissão"
                placeholder="00/00/0000"
              />
              <RgInput
                v-model="civilRegister.irc_razao_social_cartorio"
                :rules="{ required: true, validateRegistryName }"
                label="Razão Social do Cartório"
                placeholder="Digite a razão social"
              />
            </div>
            <div class="hospitalization-billing-civil-info">
              <RgInputMasked
                v-model="civilRegister.irc_livro"
                :rules="{ required: true, fn: validateLength.bind(this, 8) }"
                label="Livro"
                placeholder="Número do Livro da Certidão de Nascimento"
                mask="########"
              />
              <RgInputMasked
                v-model="civilRegister.irc_folha"
                :rules="{ required: true, fn: validateLength.bind(this, 4) }"
                label="Folha"
                placeholder="Número da Folha da Certidão de Nascimento"
                mask="####"
              />
              <RgInputMasked
                v-model="civilRegister.irc_termo"
                :rules="{ required: true, fn: validateLength.bind(this, 8) }"
                label="Termo"
                placeholder="Número do Termo da Certidão de Nascimento"
                mask="########"
              />
            </div>
            <div class="hospitalization-billing-civil-info">
              <RgInputMasked
                v-model="civilRegister.irc_matricula_certidao_nascimento"
                :rules="{ required: true, fn: validateLength.bind(this, 32) }"
                class="col-6"
                label="Matrícula"
                placeholder="Número da Matrícula da Certidão de Nascimento"
                mask="################################"
              />

              <div class="hospitalization-billing-civil-button">
                <RgAddButton
                  medium
                  label="Adicionar Registro Civil"
                  title="Adicionar Registro Civil"
                  class="button-add"
                  @click="add"
                />
              </div>
            </div>
          </FormBase>
          <FormBase title="Lista de Procedimentos">
            <div v-if="civilRegisterList.length === 0" class="empty">
              <IconEmpty class="svg" />
              <span>Não há procedimentos cadastrados</span>
            </div>

            <div v-else class="row">
              <table>
                <tr>
                  <th>Nº Declaração</th>
                  <th>Nome do Recém Nato</th>
                  <th>Razão Social do Cartório</th>
                  <th>Livro</th>
                  <th>Folha</th>
                  <th>Termo</th>
                  <th>Matrícula</th>
                  <th>Data da Emissão</th>
                  <th />
                </tr>
                <tr v-for="(item, itemIdx) in civilRegisterList" :key="itemIdx">
                  <td>{{ item.irc_numero_declaracao }}</td>
                  <td>{{ item.irc_nome_recem_nato }}</td>
                  <td>{{ item.irc_razao_social_cartorio }}</td>
                  <td>{{ item.irc_livro }}</td>
                  <td>{{ item.irc_folha }}</td>
                  <td>{{ item.irc_termo }}</td>
                  <td>{{ item.irc_matricula_certidao_nascimento }}</td>
                  <td>{{ item.irc_data_emissao }}</td>
                </tr>
              </table>
            </div>
          </FormBase>
          <div class="buttons">
            <MediumButton
              :disabled="!civilRegisterList.length > 0"
              title="Confirmar"
              label="Confirmar"
              backgroundColor="#0bb98c"
              @click="confirm"
            />

            <MediumButton
              title="Fechar"
              label="Fechar"
              @click.stop.prevent="close"
            />
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </section>
</template>

<script>
import {
  RgValidatorForm,
  RgInput,
  RgInputMasked,
  RgInputDate,
  RgAddButton,
  MediumButton,
  IconEmpty,
} from "~tokio/primitive";
import mask from "vue-the-mask";
import { AlertError } from "../../../../service";
import FormBase from "~tokio/foundation/form-base/FormBase";
export default {
  name: "RgContentHospitalizationBillingCivil",
  components: {
    RgValidatorForm,
    RgInput,
    RgInputMasked,
    RgInputDate,
    RgAddButton,
    MediumButton,
    IconEmpty,
    FormBase,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
    births: {
      default: 0,
    },
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      civilRegisterList: [],
      civilRegister: {
        type: "civilRegister",
        irc_numero: "",
        irc_numero_declaracao: "",
        irc_nome_recem_nato: "",
        irc_data_emissao: "",
        irc_razao_social_cartorio: "",
        irc_livro: "",
        irc_folha: "",
        irc_termo: "",
        irc_matricula_certidao_nascimento: "",
      },
    };
  },
  watch: {
    "civilRegister.irc_nome_recem_nato"(pValue) {
      if (!pValue) return;
      this.civilRegister.irc_nome_recem_nato = pValue.toUpperCase();
    },
    "civilRegister.irc_razao_social_cartorio"(pValue) {
      if (!pValue) return;
      this.civilRegister.irc_razao_social_cartorio = pValue.toUpperCase();
    },
  },
  mounted() {
    if (this.procedure.additionalData) {
      this.civilRegisterList = this.procedure.additionalData;
    }
    this.civilRegister.irc_data_emissao = this.$utils.date.BrazilianDateFormat();
  },
  methods: {
    async add(event) {
      if (await this.validateForm()) {
        const item = Object.assign({}, this.civilRegister);
        item.irc_numero = this.getRegisterNumber();
        item.irc_data_emissao = item.irc_data_emissao
          .split("/")
          .reverse()
          .join("-");
        this.civilRegisterList.push(item);
        this.resetForm();
      }
    },
    confirm(event) {
      if (this.civilRegisterList.length < this.births) {
        AlertError("Necessário o registro de " + this.births + " nascimentos.");
        return;
      }
      this.$emit("onFinish", this.civilRegisterList);
    },
    close() {
      this.$emit("close");
    },
    resetForm() {
      this.civilRegister.irc_numero = "";
      this.civilRegister.irc_numero_declaracao = "";
      this.civilRegister.irc_nome_recem_nato = "";
      this.civilRegister.irc_razao_social_cartorio = "";
      this.civilRegister.irc_livro = "";
      this.civilRegister.irc_folha = "";
      this.civilRegister.irc_termo = "";
      this.civilRegister.irc_matricula_certidao_nascimento = "";
    },
    removeLine(idx) {
      this.civilRegisterList.splice(idx, 1);
    },
    editLine(idx) {
      this.civilRegister = this.civilRegisterList[idx];
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },
    validateLength(length, pValue) {
      if (pValue) {
        if (pValue.length < length) {
          AlertError("O campo deve conter " + length + " caracteres.");
          return false;
        }
        return true;
      }
      return false;
    },
    getRegisterNumber() {
      if (this.civilRegisterList.length > 0) {
        return this.civilRegisterList[0].irc_numero;
      }
      return "";
    },
    validateRegistryName(pValue) {
      if (pValue) {
        if (pValue.length > 20) {
          AlertError("Razão Social deve conter no máximo 20 caracteres");
          return false;
        }
        return true;
      }
      return false;
    },
    validateName(pValue) {
      if (pValue) {
        if (pValue.length > 70) {
          AlertError("Nome deve conter no máximo 70 caracteres");
          return false;
        }
        return true;
      }
      return false;
    },
  },
};
</script>
