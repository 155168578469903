<style src="./RgSuggestTransferOccupation.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="innerSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :open-only-top="openOnlyTop"
    :disabled="disabled"
    :rules="rules"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="cbo-item"
    >
      <div class="cbo-row-info">
        <div class="ocp-code">
          <span>{{ item.CO_OCUPACAO }}</span>
        </div>
        <div class="ocp-name">
          <span>{{ item.NO_OCUPACAO }}</span>
        </div>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchTransferOccupation } from "./action";

export default {
  name: "RgSuggestTransferCbo",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Ocupação",
    },
    title: {
      type: String,
      default: "Ocupação",
    },
    placeholder: {
      type: String,
      default: "Digite a ocupação",
    },
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    procedureList: {
      type: String,
      default: null,
    },
    openOnlyTop: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      captionRaw: `CO_OCUPACAO - NO_OCUPACAO`,
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.innerSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  watch: {},
  methods: {
    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    async doSearch(pSearchTerm, pLimit = null) {
      if (!this.procedureList || this.procedureList.length === 0) return;

      const variables = {
        proceduresList: this.procedureList,
        limit: pLimit,
      };

      if (!isNaN(pSearchTerm)) {
        variables.searchQueryCod = pSearchTerm;
      } else if (!isNaN(pSearchTerm.slice(0, 5))) {
        variables.searchQueryCod = pSearchTerm.split(" - ")[0];
        variables.searchQueryName = pSearchTerm.split(" - ")[1];
      } else {
        variables.searchQueryName = pSearchTerm;
      }
      const value = await SearchTransferOccupation({ variables });

      return {
        rows: value.rows.map((element) => {
          return {
            ...element,
          };
        }),
        count: value.count,
      };
    },
  },
};
</script>
