<template>
  <div class="person-duplicate-registration-report">
    <div class="grid">
      <Tooltip clickable startOpen class="tooltip">
        <IconInfoHelper slot="icon" hollow />
        <div slot="content" class="content">
          Selecione quais os critérios devem ser levados em consideração para a
          geração do relatório.
          <br />
          Deve ser escolhido ao menos um e no máximo dois critérios.
        </div>
      </Tooltip>

      <div class="selectinput">
        <RgInputCustomCheckbox
          v-model="form.filters"
          :rules="{ listRequired: true }"
          label="Critérios"
          :list="FILTERS"
          :max-selected="2"
          :value="form.filters"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Tooltip,
  IconInfoHelper,
  RgInputCustomCheckbox,
} from "~tokio/primitive";

export default {
  name: "PersonDuplicateRegistrationReport",
  components: { Tooltip, IconInfoHelper, RgInputCustomCheckbox },
  data() {
    return {
      resultTitle: "Duplicidade de Pacientes",
      form: {
        filters: [],
      },
    };
  },

  computed: {
    printReportCss() {
      return ".report-column-1 { white-space: normal !important; ; text-align: left;}";
    },

    getNameSmartTable() {
      return "PersonDuplicateRegistrationReport";
    },

    columnTable() {
      return this.COLUMN;
    },
  },
  created() {
    this.COLUMN = [
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Nome da Mãe", key: "pes_mae", align: "left" },
      { name: "Data de Nascimento", key: "pes_nascimento", align: "left" },
      {
        name: "Possíveis Duplicidades",
        key: "possiveis_duplicidades",
        align: "left",
      },
    ];

    this.FILTERS = [
      { label: "Nome", value: "pes_nome" },
      { label: "Mãe", value: "pes_mae" },
      { label: "Nascimento", value: "pes_nascimento" },
    ];
  },

  methods: {
    buildFilter() {
      return { filters: this.form.filters };
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Person/Report/GET_PERSON_DUPLICATE_REGISTRATION_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {
      this.form.filters = [];
    },
  },
};
</script>
