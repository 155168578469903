<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import ListUnithealthExamQueue from "./action/ListUnithealthExamQueue";
import ListUnithealthExamProcedureQueue from "./action/ListUnithealthExamProcedureQueue";
import ListUnitHealthExamExternallyQueue from "./action/ListUnithealthExamExternallyQueue";
import ListUnitHealthQueueAppointment from "./action/ListUnitHealthQueueAppointment";
import ListUnitHealthAppointmentExternallyQueue from "./action/ListUnitHealthAppointmentExternallyQueue";
import ListUnitHealthQueueHospitalization from "./action/ListUnitHealthQueueHospitalization";
import ListUnitHealthHospitalizationExternallyQueue from "./action/ListUnitHealthHospitalizationExternallyQueue";

export default {
  name: "RgSelectUnithealthQueue",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
    unitHealthId: {
      default: null,
    },
    moduleId: {
      required: true,
    },
    hasRegularPermission: Boolean,
    hasViewRequestUnitHealthPermission: Boolean,
    hasProcedure: Boolean,
    externally: Boolean,
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      let data = [];
      const isExam =
        this.moduleId === this.$store.state.Login.route_module_map.exam;
      const isAppointment =
        this.moduleId === this.$store.state.Login.route_module_map.appointment;
      const isHospitalization =
        this.moduleId ===
        this.$store.state.Login.route_module_map.hospitalization;

      if (isExam) {
        if (this.hasProcedure) {
          data = await ListUnithealthExamProcedureQueue();
        } else if (this.externally) {
          data = await ListUnitHealthExamExternallyQueue();
        } else {
          data = await ListUnithealthExamQueue({
            unitHealthId: this.unitHealthId,
            hasRegularPermission: this.hasRegularPermission,
            hasViewRequestUnitHealthPermission: this
              .hasViewRequestUnitHealthPermission,
          });
        }
      } else if (isAppointment) {
        if (this.externally) {
          data = await ListUnitHealthAppointmentExternallyQueue();
        } else {
          data = await ListUnitHealthQueueAppointment({
            unitHealthId: this.unitHealthId,
            hasRegularPermission: this.hasRegularPermission,
            hasViewRequestUnitHealthPermission: this
              .hasViewRequestUnitHealthPermission,
          });
        }
      } else if (isHospitalization) {
        if (this.externally) {
          data = await ListUnitHealthHospitalizationExternallyQueue();
        } else {
          data = await ListUnitHealthQueueHospitalization({
            unitHealthId: this.unitHealthId,
            hasRegularPermission: this.hasRegularPermission,
            hasViewRequestUnitHealthPermission: this
              .hasViewRequestUnitHealthPermission,
          });
        }
      }

      this.valuesData = data.map((item) => {
        return {
          value: item.uns_id,
          label: `${item.uns_nome}`,
        };
      });
    },
    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
