import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import HospitalizationSolicitationSearch from "$hospitalization/hospitalizations/view/solicitation/HospitalizationSolicitationSearch";

export default {
  path: "/hospitalization/hospitalization-solicitation/search",
  name: "hospitalization.hospitalization-solicitation.search",
  components: {
    default: HospitalizationSolicitationSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Internações", link: OpenSubModule("hospitalization") },
      { label: "Solicitações de Internação" },
    ],
  },
};
