import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ReportCentralizer from "$report/view/ReportCentralizer";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path: "/register/report",
  name: "register.report",
  components: {
    default: ReportCentralizer,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastro", link: OpenMenuModule("register") },
      {
        label: "Relatórios",
        link: "/register/report",
      },
    ],
  },
};
