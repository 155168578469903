<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnitHealthProcedure from "./action/SearchUnitHealthProcedure";

export default {
  name: "RgSelectUnitHealthProcedure",
  extends: RgSelect,
  props: {
    procedureName: {
      type: String,
    },
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    procedureName() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const userId = this.$store.getters["Login/GET_USER_ID"];

      if (userId && this.procedureName) {
        const data = await SearchUnitHealthProcedure(
          userId,
          this.procedureName,
        );

        data.forEach((item) => {
          this.valuesData.push({ value: item.data, label: item.label });
        });
      }
    },
  },
};
</script>
