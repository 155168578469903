<template lang="html">
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.995 0.739969C14.5964 1.64627 12.557 3.91194 10.0647 5.95112C8.2521 7.53716 6.66619 9.12315 4.17385 9.80287C3.26755 10.0295 2.81445 9.80303 2.81445 8.89672C2.81445 6.63096 2.81445 4.36526 2.81445 1.87292C2.81445 1.19319 2.8143 0.966642 3.72061 1.19322C6.21294 2.3261 8.47865 1.19312 10.7444 0.739969C12.3304 0.286817 13.9165 -0.166307 15.7291 0.0602686C16.4088 0.0602686 17.0888 0.060241 17.7686 0.739969H17.995Z"
      fill="white"
    />
    <path
      d="M1.3594 0.967298C1.3594 0.591895 1.0551 0.287598 0.679701 0.287598C0.304297 0.287598 0 0.591895 0 0.967298V19.3199C0 19.6953 0.304297 20 0.679701 20C1.0551 20 1.3594 19.6953 1.3594 19.3199V0.967298Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Observation",
};
</script>
