import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetAttendancesData(
    $uns_cnes: String
    $cmp: String
    $cbo: String
    $procedimento: String
    $idade: String
  ) {
    GetAttendancesData(
      uns_cnes: $uns_cnes
      cmp: $cmp
      cbo: $cbo
      procedimento: $procedimento
      idade: $idade
    ) {
      count
      rows {
        pes_nome
        pes_nascimento
        sex_nome
        crs_numero
        nomeFuncionario
        data_atendimento
        ocp_codigo
        ppd_quantidade
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data;
};
