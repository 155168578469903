import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchUnitHealthReport(
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $uns_id_tipos_unidades_saude_sus: Int
    $uns_id_sub_tipos_unidades_saude_sus: Int
    $tss_nome: String
    $stu_nome: String
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchUnitHealthReport(
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      uns_id_tipos_unidades_saude_sus: $uns_id_tipos_unidades_saude_sus
      uns_id_sub_tipos_unidades_saude_sus: $uns_id_sub_tipos_unidades_saude_sus
      tss_nome: $tss_nome
      stu_nome: $stu_nome
      columnsToPrint: $columnsToPrint
    ) {
      html
      csv
      excel
      fileName
      count
      rows {
        UNIDADE
        RAZAO_SOCIAL
        CNPJ
        CNES_ORGAO_EMISSOR
        TIPO_UNIDADE
        SUB_TIPO_UNIDADE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.SearchUnitHealthReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
