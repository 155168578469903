<style src="./SchedulingProcedureList.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="scheduling-procedure-list">
    <section class="procedure-form">
      <form class="procedure-list-form row" @submit.prevent="insertProcedure">
        <div class="form-requesting-portage col-2">
          <RgComboboxSurgeryPortage
            v-model="form.portage"
            label="Porte"
            @select="pushPortage"
          />
        </div>

        <div class="form-requesting-class col-3">
          <RgComboboxSurgeryClass
            v-model="form.class"
            label="Classe"
            @select="pushClass"
          />
        </div>

        <div class="form-requesting-procedure col-6">
          <RgTypeaheadSurgeryProcedure
            v-model="form.procedure"
            placeholder="Digite o procedimento"
            @select="pushProcedure"
          />
        </div>

        <div class="form-submit col-1">
          <button class="button">
            <IconCircleAdd class="button--icon" />
          </button>
        </div>
      </form>
    </section>
    <section class="form-list">
      <div class="requesting-list">
        <ul>
          <li>
            <ul class="form-list--container-header row">
              <li class="procedure-list--portage col-2">Porte</li>
              <li class="procedure-list--class col-3">Classe</li>
              <li class="procedure-list--procedure col-6">Procedimento</li>
              <li class="procedure-list--remove col-1" />
            </ul>
          </li>

          <li
            v-for="(item, i) in procedureList"
            :key="i"
            class="form-list--container-row"
          >
            <ul class="form-list--container-content row">
              <li class="form-list--portage col-2">{{ item.portage.text }}</li>
              <li class="form-list--class col-3">{{ item.class.text }}</li>
              <li class="form-list--procedure col-6">
                {{ item.procedure.text }}
              </li>
              <li class="form-list--remove col-1">
                <RgRemoveSmall @confirm-remove="removeItem(i)" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
import {
  toast,
  IconCircleAdd,
  RgRemoveSmall,
  RgValidatorMixin,
} from "~tokio/primitive";
import RgComboboxSurgeryClass from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-class/RgComboboxSurgeryClass";
import RgComboboxSurgeryPortage from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-portage/RgComboboxSurgeryPortage";
import RgTypeaheadSurgeryProcedure from "$surgery-center/surgery/component/combobox/rg-typeahead-surgery-procedure/RgTypeaheadSurgeryProcedure";
import SurgeryProcedureModel from "$surgery-center/surgery/model/SurgeryProcedureModel";

export default {
  name: "SchedulingProcedureList",
  components: {
    RgComboboxSurgeryClass,
    RgComboboxSurgeryPortage,
    RgTypeaheadSurgeryProcedure,
    IconCircleAdd,
    RgRemoveSmall,
  },
  mixins: [RgValidatorMixin],
  props: {
    value: {
      default: null,
    },
    surgeryId: {
      default: null,
    },
  },
  data() {
    return {
      anotherRules: {
        filled(procedures, pError) {
          if (procedures.length <= 0) {
            pError.push("Nenhum procedimento adicionado");
            return false;
          }
          return true;
        },
      },
      form: {
        class: "",
        portage: "",
        procedure: "",
      },
      lPortage: {
        text: "",
        value: "",
      },
      lClass: {
        text: "",
        value: "",
      },
      lProcedure: {
        text: "",
        value: "",
      },
      procedureList: [],
    };
  },
  computed: {
    validValue() {
      return this.procedures;
    },
    procedures() {
      return this.procedureList.map((item) => {
        return {
          cpr_id_tabelas_unificadas: item.procedure.value,
          cpr_id_classes: item.class.value,
          cpr_id_portes: item.portage.value,
        };
      });
    },
  },
  watch: {
    surgeryId(pValue, pOldValue) {
      if (pValue !== pOldValue && pValue > 0) {
        this.fillProcedures();
      } else {
        this.procedureList = [];
      }
    },
    value(pNewvalue) {
      if (pNewvalue === null || !pNewvalue.length) {
        this.cleanFields();
        this.procedureList = [];
      }
    },
  },
  methods: {
    insertProcedure() {
      if (this.isItensInvalid()) {
        toast.error(
          "Para adicionar um item na lista é preciso selecioná-lo no campo ao lado",
          "Campo vazio",
        );
        return false;
      }
      const itemList = {
        portage: { ...this.lPortage },
        class: { ...this.lClass },
        procedure: { ...this.lProcedure },
      };
      if (
        itemList.class.value &&
        itemList.portage.value &&
        itemList.procedure.value
      ) {
        for (let i = 0; i < this.procedureList.length; i++) {
          if (
            this.procedureList[i].class.value === itemList.class.value &&
            this.procedureList[i].portage.value === itemList.portage.value &&
            this.procedureList[i].procedure.value === itemList.procedure.value
          ) {
            toast.error(
              "O procedimento selecionado já esta incluido na lista de procedimentos com o mesmo porte e classe",
              "Falha ao incluir...",
            );
            return;
          }
        }
        this.procedureList.push({ ...itemList });
        this.$emit("input", this.procedures);
        this.cleanFields();
      }
    },
    pushPortage(e) {
      if (!e) {
        this.lPortage.text = "";
        this.lPortage.value = "";
      } else {
        this.lPortage.text = e.text;
        this.lPortage.value = e.value;
      }
    },
    pushClass(e) {
      if (!e) {
        this.lClass.text = "";
        this.lClass.value = "";
      } else {
        this.lClass.text = e.text;
        this.lClass.value = e.value;
      }
    },
    pushProcedure(e) {
      if (!e) {
        this.lProcedure.text = "";
        this.lProcedure.value = "";
      } else {
        this.lProcedure.text = e.text;
        this.lProcedure.value = e.value;
      }
    },
    removeItem(index) {
      this.procedureList.splice(index, 1);
      this.$emit("input", this.procedures);
    },
    fillProcedures() {
      SurgeryProcedureModel.getProcedureBySurgeryId(this.surgeryId).then(
        (pProceduresList) => {
          const procedureNew = [];
          const proceduresMapList = pProceduresList.map((pProcedureItem) => {
            procedureNew.push({
              cpr_id_tabelas_unificadas:
                pProcedureItem.cpr_id_tabelas_unificadas,
              cpr_id_classes: pProcedureItem.cpr_id_classes,
              cpr_id_portes: pProcedureItem.cpr_id_portes,
            });
            return {
              portage: {
                text: pProcedureItem.por_nome,
                value: pProcedureItem.por_id,
              },
              class: {
                text: pProcedureItem.cla_nome,
                value: pProcedureItem.cla_id,
              },
              procedure: {
                text: `${pProcedureItem.tuc_codigo_procedimento} - ${pProcedureItem.tuc_nome}`,
                value: pProcedureItem.tuc_id,
              },
            };
          });

          this.procedureList = proceduresMapList;
          this.$emit("input", procedureNew);
        },
      );
    },
    cleanFields() {
      this.form.portage = "";
      this.form.class = "";
      this.form.procedure = "";
      this.lProcedure = {};
      this.lClass = {};
      this.lPortage = {};
    },
    isItensInvalid() {
      const itemValue = this.lProcedure && this.lProcedure.value;
      const itemPortage = this.lPortage && this.lPortage.value;
      const itemClass = this.lClass && this.lClass.value;
      return !itemValue || !itemPortage || !itemClass;
    },
  },
};
</script>
