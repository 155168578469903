import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacySatelliteOutputReport(
    $unitHealth: Int
    $pharmacy: Int
    $product: Int
    $unitHealthName: String
    $pharmacyName: String
    $productName: String
    $mtp_id: Int
    $mtp_nome: String
    $usu_id: Int
    $usu_nome: String
    $rec_lote: String
    $rec_lote_validade_lote: String
    $pcf_id: Int
    $pcf_nome: String
    $rec_id_funcionarios: Int
    $rec_nome_funcionarios: String
    $mun_id: Int
    $mun_nome: String
    $est_nome: String
    $fap_id_usuarios: Int
    $initialDate: String
    $finalDate: String
    $loggedUserId: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacySatelliteOutputReport(
      uns_id: $unitHealth
      far_id: $pharmacy
      mpd_id: $product
      uns_nome: $unitHealthName
      far_nome: $pharmacyName
      mpd_nome: $productName
      mtp_id: $mtp_id
      mtp_nome: $mtp_nome
      usu_id: $usu_id
      usu_nome: $usu_nome
      rec_lote: $rec_lote
      rec_lote_validade_lote: $rec_lote_validade_lote
      pcf_id: $pcf_id
      pcf_nome: $pcf_nome
      rec_id_funcionarios: $rec_id_funcionarios
      rec_nome_funcionarios: $rec_nome_funcionarios
      mun_id: $mun_id
      mun_nome: $mun_nome
      est_nome: $est_nome
      fap_id_usuarios: $fap_id_usuarios
      initialDate: $initialDate
      finalDate: $finalDate
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        PRODUTO
        NOME_COMERCIAL
        UNIDADE_SAUDE
        FARMACIA
        APRESENTACAO
        TIPO
        LOTE_VALIDADE
        SAIDA
        DISPENSADO
        SOLICITADO
        PACIENTE
        ENDERECO
        USUARIO
        MEDICO_SOLICITANTE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacySatelliteOutputReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
