import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchRegulationProcedures(
    $inp_novo_nome_procedimento: String
    $inp_novo_codigo_procedimento: String
    $inp_sexo: String
    $limit: Int
  ) {
    customHospitalizationQueueProcedures(
      inp_novo_nome_procedimento: $inp_novo_nome_procedimento
      inp_novo_codigo_procedimento: $inp_novo_codigo_procedimento
      inp_sexo: $inp_sexo
      limit: $limit
    ) {
      count
      rows {
        inp_id
        inp_novo_nome_procedimento
        inp_novo_codigo_procedimento
      }
    }
  }
`;

export default async ({ variables }) => {
  try {
    const result = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return result.data.customHospitalizationQueueProcedures;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
