import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleOutpatientConsultationControl = (pEmployeeData, pData) => {
  const logo = LocalStorage.getObject("client-logo");
  const texto = "Relatório de Pendências";

  let dadosTabela = "";
  let unithealth = "";
  const resolutions = pData.filter((item) => item.fip_resolucao);

  if (pEmployeeData.uns_solicitante) {
    unithealth += `<div><label>Unidade de Saúde Solicitante: </label><span>${pEmployeeData.uns_solicitante}</span> </div>`;
  } else {
    unithealth += `<div><label>Unidade de Saúde Solicitante: </label><span>${pEmployeeData.uns_nome}</span> </div>`;
  }

  pData.forEach((item) => {
    "<tr>";
    dadosTabela += "<td>" + item.fip_motivo_pendencia + "</td>";
    dadosTabela += "<td>" + item.fip_data_pendencia + "</td>";
    dadosTabela += "<td>" + item.usuario_pendencia + "</td>";
    dadosTabela += "<td>" + item.fip_resolucao + "</td>";
    dadosTabela += "<td>" + item.fip_data_resolucao + "</td>";
    dadosTabela += "<td>" + item.usuario_resolucao + "</td></tr>";
  });

  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8">
    <title>Esus</title>
    <style type="text/css">
    @media print{@page {size: landscape}}

      page {
        background: white;
        display: block;
        margin: 0 auto;
        margin-bottom: 0.5cm;
      }

      page[size="landscape"] {
        width: 29.7cm;
        height: 21cm;
        margin: 20px;
      }

      .cabecalho {
        display: flex;
        align-items: center;
      }

      .titulo {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      }

      body {
        font-family: 'Montserrat', sans-serif;
      }

      h1 {
        font-size: 12pt;
        text-transform: uppercase;
        text-align: center;
      }

      h2 {
        font-size: 11pt;
        font-style: italic;
        text-transform: uppercase;
      }

      .logo-esus{
        margin-right: 40px;
      }

      hr {
        margin: 15px 0;
        border: 0.5px solid #CFCFCF;
      }

      .info-fila{
        display: grid;
        grid-template-columns: 4fr 1fr;
      }

      .info-tabela {
        display: grid;
        grid-template-columns: 5fr 2fr 2fr;
        margin-bottom: 2%;
    }

      .info-fila div {
        margin-bottom: 6px;
      }

      .info-cabecalho{
        display: grid;
        grid-template-columns: 3fr 1fr;
        column-gap: 20px;
      }

      .logo-cliente {
        width: 70px;
        margin-left: 68%;
      }

      label,
      span,
      p {
        font-size: 16px;
      }

      label {
        font-weight: 700;
      }

      table {
        width: 100%;
        font-size: 14px;
        top: 10%;
        border-collapse: collapse;
      }

      thead, tr {
        border: 1px solid grey;
      }

      tr:nth-child(even) {
        background-color: #f6f6f6;
      }

      th {
        border: 1px solid grey;
        font-weight: 700;
        text-align:left;
      }

      td {
        border: 1px solid grey;
      }

      th,td{
        padding:5px;
      }

      th:nth-child(1),
      tr td:nth-child(1) {
          width: 5%;
      }

      th:nth-child(2),
      tr td:nth-child(2) {
          width: 5%;
      }

      th:nth-child(3),
      tr td:nth-child(3) {
          width: 20%;
      }

      th:nth-child(4),
      tr td:nth-child(4) {
          width: 5%;
      }

      th:nth-child(5),
      tr td:nth-child(5) {
          width: 5%;
      }

      th:nth-child(6),
      tr td:nth-child(6) {
          width: 5%;
      }

      th:nth-child(7),
      tr td:nth-child(7) {
          width: 5%;
      }

      th:nth-child(8),
      tr td:nth-child(8) {
          width: 10%;
      }

      th:nth-child(9),
      tr td:nth-child(9) {
          width: 6%;
      }

      th:nth-child(10),
      tr td:nth-child(10) {
          width: 10%;
      }

      th:nth-child(11),
      tr td:nth-child(11) {
          width: 10%;
      }

      th:nth-child(12),
      tr td:nth-child(12) {
        width: 10%;
      }

    </style>
  </head>

  <body>
    <page size="landscape">
      <div class="cabecalho">
      <img src="/static/images/logo-esus-relatorio.png" class="logo-esus">
      <h3 class="titulo">${texto}</h3>
      </div>
      <hr />

      <div class="info-fila">
        <div class="info-cabecalho">
        ${unithealth}
        <div><label>Data: </label> <span>${
          pEmployeeData.fil_data_pedido ? pEmployeeData.fil_data_pedido : ""
        }</span> </div>
        <div><label>Usuário: </label> <span>${
          pEmployeeData.usu_nome ? pEmployeeData.usu_nome : ""
        }</span> </div>
        </div>
      <div>
      <img class="logo-cliente" src="${logo}">
      </div>
      </div>
      <hr/>
      <div class="info-tabela">
      <div><label>Paciente: </label> <span>${
        pEmployeeData.pes_nome ? pEmployeeData.pes_nome : ""
      }</span> </div>
        <div><label>Total de Pendências: </label> <span>${
          pData.length > 0 ? pData.length : "0"
        }</span> </div>
        <div><label>Total de Resoluções: </label> <span>${
          resolutions.length > 0 ? resolutions.length : "0"
        }</span> </div>
      </div>
      <table class="table">
        <thead class="header">
          <th> Pendência </th>
          <th> Data da Pendência </th>
          <th> Cadastrado por </th>
          <th> Resolução </th>
          <th> Data da Resolução </th>
          <th> Resolvido por </th>
        </thead>

        <div class="table">
          ${dadosTabela}
        <div>
      </table>
    </page>
  </body>

  </html>
    `;
  return html;
};

export default ScheduleOutpatientConsultationControl;
