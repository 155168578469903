<template lang="html">
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7514 8.01034C18.7514 6.14237 18.1369 4.31627 16.9677 2.86846C15.8136 1.4394 14.2052 0.425635 12.4117 0.0138345C12.1351 -0.0498668 11.8642 0.112049 11.7822 0.383639L9.48947 8.00885H3.04512V6.12141C3.04512 5.8236 2.85935 5.58214 2.56153 5.58214H0.53927C0.241458 5.58214 0 5.8236 0 6.12141C0 6.41922 0.241458 6.66068 0.53927 6.66068H1.96658V11.1583C1.96658 12.1366 2.8154 12.9297 3.7937 12.9297H8.51346L9.28859 13.7048L7.69424 15.2992C7.30246 15.0529 6.83963 14.9099 6.34377 14.9099C4.94046 14.9099 3.79875 16.0517 3.79875 17.455C3.79875 18.8583 4.94046 20 6.34377 20C7.54129 20 8.54764 19.1685 8.81734 18.0528H11.2852C11.5549 19.1685 12.5613 20 13.7587 20C15.1621 20 16.3038 18.8584 16.3038 17.455C16.3038 16.0517 15.1621 14.9099 13.7587 14.9099C13.2629 14.9099 12.8 15.0529 12.4083 15.2992L10.814 13.7048L11.5891 12.9297H17.0356C18.014 12.9297 18.7514 12.1366 18.7514 11.1583V8.01034ZM10.0637 12.9298L10.0513 12.9422L10.0389 12.9298H10.0637ZM6.34371 18.9215C5.53507 18.9215 4.87723 18.2637 4.87723 17.4551C4.87723 16.6464 5.53507 15.9885 6.34371 15.9885C7.15241 15.9885 7.81032 16.6464 7.81032 17.4551C7.81032 18.2637 7.15241 18.9215 6.34371 18.9215ZM13.7587 18.9215C12.95 18.9215 12.2922 18.2637 12.2922 17.4551C12.2922 16.6464 12.95 15.9885 13.7587 15.9885C14.5674 15.9885 15.2253 16.6464 15.2253 17.4551C15.2253 18.2637 14.5674 18.9215 13.7587 18.9215ZM11.6363 16.0527C11.4542 16.3274 11.3242 16.6392 11.2598 16.9743H8.84275C8.77838 16.6392 8.64834 16.3274 8.46621 16.0527L10.0513 14.4676L11.6363 16.0527Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Baby",
};
</script>
