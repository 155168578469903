<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchVacanciesAvailableScale from "./action/SearchVacanciesAvailableScale";

export default {
  name: "RgSelectVacanciesAvailableScale",
  extends: RgSelect,
  props: {
    scale: {
      type: Number,
    },
    date: {
      type: String,
    },
    label: {
      type: String,
      default: "Vagas",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    scale() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      this.valuesData = [];
      if (!this.scale) {
        return;
      }

      const data = await this.getMax();
      const max = data.length;

      let count = 1;
      while (count <= max) {
        let str = `${count} vagas`;
        if (count === 1) {
          str = `${count} vaga`;
        }
        this.valuesData.push({ value: count.toString(), label: str });
        count++;
      }
    },
    async getMax() {
      const data = await SearchVacanciesAvailableScale({
        intIdEscalaDeAgendamento: this.scale,
        strDataExame: this.date,
      });

      return data;
    },
  },
};
</script>
