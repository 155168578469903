import axios from "axios";

function Request(config) {
  this._config = config;
}

Request.prototype.get = function (url, data, headers) {
  return this._send(url, "GET", data, headers);
};

Request.prototype.post = function (url, data, headers) {
  return this._send(url, "POST", data, headers);
};

Request.prototype.put = function (url, data, headers) {
  return this._send(url, "PUT", data, headers);
};

Request.prototype.delete = function (url, data, headers) {
  return this._send(url, "DELETE", data, headers);
};

Request.prototype.getHeaders = function (headers) {
  return headers;
};

Request.prototype._send = function (url, pMethod, pData, pHeaders) {
  const method = pMethod || "GET";
  const data = pData || {};
  const headers = pHeaders || {};
  const fourMinutesInMiliseconds = 240000;

  var sender = axios.create(Object.assign(this._config, {}));
  this.onBeforeSend(url, pMethod, pData, pHeaders);
  var promise = sender.request({
    url: url,
    method: method,
    headers: this.getHeaders(headers),
    params: method === "GET" ? data : null,
    data: method !== "GET" ? data : null,
    timeout: fourMinutesInMiliseconds,
  });
  return this._prepareResponse(promise);
};

Request.prototype._prepareResponse = function (promise) {
  var self = this;
  promise
    .then(function (response) {
      self._handleSuccessResponse(response);
    })
    .catch(function (error) {
      self._handleErrorResponse(error);
    });
  return promise;
};

Request.prototype._handleSuccessResponse = function (response) {
  if (response.data.data) {
    response.data = response.data.data;
  }

  setTimeout(() => {
    const refreshRoute = localStorage.getItem("exec-refresh-page-refreshLogin");

    if (refreshRoute) {
      const objRefresh = JSON.parse(refreshRoute);

      if (objRefresh.exec === "active") {
        localStorage.setItem(
          "exec-refresh-page-refreshLogin",
          JSON.stringify({ exec: "inactive", refresh: objRefresh.refresh }),
        );
      }
    }
  }, 10000);

  return response;
};

Request.prototype._handleErrorResponse = function (error) {
  const httpStatus = error.response.status;

  switch (httpStatus) {
    case 401: {
      this.onunauthorized(error);
      break;
    }
    case 403: {
      this.onuforbidden(error);
      break;
    }
    default: {
      if (!error.response) {
        return this._noResponse(error);
      }

      if (error.response.data.errors) {
        error.response.errors = error.response.data.errors;
        delete error.response.data;
      }
    }
  }

  return error.response;
};

Request.prototype._noResponse = function (error) {
  return error;
};

Request.prototype.onunauthorized = function () {};

Request.prototype.onuforbidden = function () {};

Request.prototype.onBeforeSend = function () {};

export default Request;
