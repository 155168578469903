export default {
  GET_HOSPITALIZATION_INFO: (state) => state.hospitalization,
  GET_HOSPITALIZATION_INFO_ID: (state) =>
    state.hospitalization.form.hospitalization.int_id,
  GET_FILTER_HOSPITALIZATION_BED: (state) =>
    state.filter_hospitalization_bed_data,
  GET_FILTER_HOSPITALIZATION: (state) => state.filter_hospitalization_data,
  GET_HOSPITALIZATION_SOLICITATION: (state) =>
    state.hospitalization_solicitation_data,
  GET_FILTER_HOSPITALIZATION_SOLICITATION: (state) =>
    state.filter_hospitalization_solicitation_data,
};
