<template lang="html">
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    :fill="hollow ? '#FFFFFF' : '#2B5D8A'"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="13" stroke="#2B5D8A" stroke-width="2" />
    <path
      d="M14.013 6C10.9992 6 10.9001 7.81481 10.9992 8.42419C11.4992 11.5 11.9992 14 12.3853 16.9728C12.3854 17.6965 13.1303 18 14.0131 18C14.6751 18 15.613 17.6965 15.613 16.9728C15.9992 14 16.4992 11 16.9992 8.42419C17.1305 7.74782 16.9992 6 14.013 6Z"
      :fill="hollow ? '#2B5D8A' : '#FFFFFF'"
    />
    <path
      d="M14.0131 20C12.8628 20 12 20.909 12 22C12 23.0649 12.8627 24 14.0131 24C15.0849 24 16 23.0649 16 22C16 20.909 15.0848 20 14.0131 20Z"
      :fill="hollow ? '#2B5D8A' : '#FFFFFF'"
    />
  </svg>
</template>

<script>
export default {
  name: "ExclamationHelper",
  props: {
    hollow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
