import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import HospitalizationInsertQueueRoute from "$hospitalization/hospitalizations/queue/views/queue-insert/HospitalizationInsertQueue";

export default {
  path: "/hospitalization/queue/edit-insert",
  name: "hospitalization.queue.edit-insert",
  components: {
    default: HospitalizationInsertQueueRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Regulação", link: OpenSubModule("hospitalization") },
      { label: "Fila de Regulação", link: "/hospitalization/queue" },
      { label: "Editar Fila" },
    ],
  },
};
