<style src="./RgTitle.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-title--component">
    <span
      :style="{
        color: fontColor,
        background: backgroundColor,
        fontSize: fontSize,
      }"
      class="title-background"
    >
      <span :style="{ align: textAlign }" class="title">
        {{ title }}
      </span>
    </span>
  </fieldset>
</template>
<script>
export default {
  name: "RgTitle",
  components: {},
  mixins: [],
  props: {
    title: {
      type: String,
      required: true,
    },
    textAlign: {
      type: String,
      default: "left",
    },
    fontColor: {
      type: String,
      default: "#ffffff",
    },
    backgroundColor: {
      type: String,
      default: "#6f8894",
    },
    fontSize: {
      type: String,
      default: "14pt",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
