<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchModules from "./action/SearchModules";

export default {
  name: "RgComboboxModules",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Módulo",
    },
    dataId: {
      type: String,
      default: "modulos-id",
    },
    modulesId: {
      type: Array,
      default: () => [2, 14, 15, 33],
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      const variables = { mod_id: this.modulesId };
      return SearchModules(variables);
    },
    formatItem(item) {
      return {
        value: item.mod_id,
        text: `${item.mod_descricao.toUpperCase()}`,
      };
    },
  },
};
</script>
