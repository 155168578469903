import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyConsolidatedReport(
    $uns_id: Int
    $far_id: Int
    $far_nome: String
    $mpd_id: Int
    $mpd_nome: String
    $mtp_id: Int
    $mtp_nome: String
    $initialDate: String
    $finalDate: String
    $orderByLabel: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyConsolidatedReport(
      uns_id: $uns_id
      far_id: $far_id
      far_nome: $far_nome
      mpd_id: $mpd_id
      mpd_nome: $mpd_nome
      mtp_id: $mtp_id
      mtp_nome: $mtp_nome
      initialDate: $initialDate
      finalDate: $finalDate
      orderByLabel: $orderByLabel
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO_PRODUTO
        PRODUTO
        NOME_COMERCIAL
        TIPO_PRODUTO
        APRESENTACAO
        ENTRADA
        SAIDA
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyConsolidatedReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
