import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People($crs_numero: String, $isSearchable: Boolean) {
    people(cns: $crs_numero, isPatient: true, isSearchable: $isSearchable) {
      rows {
        pes_id
        pes_nome
        pes_nascimento
        pes_email
        gender {
          sex_sigla
        }
        ethnicity {
          etn_id
          etn_nome
          etn_codigo_sisaih
        }
        nationality {
          nac_id
          nac_nome
          nac_codigo
        }
        address {
          end_cep
          end_logradouro
          end_numero
          end_complemento
          publicPlaceType {
            tlg_id
            tlg_nome
            tlg_codigo
          }
          neighborhood {
            bai_nome
            city {
              mun_id
              mun_nome
              mun_codigo
              state {
                est_id
                est_sigla
              }
            }
          }
        }
        cns {
          crs_numero
        }
        telephones {
          rows {
            tel_id
            tel_id_pessoas
            tel_id_ddds
            tel_numero
            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.people.rows;
};
