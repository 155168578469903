<template lang="html">
  <div v-if="show" class="modal-print-emit-bulletin">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Emissão de Boletim</div>
      </div>

      <div slot="body" class="body">
        <FormBase withoutTitle>
          <div class="form-base-buttons">
            <LargeButton
              id="print-front-mpeb"
              class="btn-print"
              label="Frente"
              :separator="false"
              @click="print(PRINT_FRONT)"
            />
            <LargeButton
              id="prit-back-mpeb"
              class="btn-print"
              label="Verso"
              :separator="false"
              @click="print(PRINT_BACK)"
            />
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer"></div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, LargeButton } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  AttendedBulletinBackPageHTML,
  AttendedBulletinFrontPageHTML,
} from "../../../html";
import moment from "moment";

export default {
  name: "ModalPrintEmitBulletin",
  components: {
    RgBaseModal,
    FormBase,
    LargeButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    bulletinData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },

  created() {
    this.PRINT_FRONT = 1;
    this.PRINT_BACK = 2;
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async print(typePrint) {
      try {
        const data = await this.$store.dispatch(
          "Emergency/Bulletin/GET_PRINT_EMIT_BULLETIN_DATA",
          {
            intIdBoletim: this.bulletinData.bol_id,
            blnIndigente: !this.bulletinData.pes_id,
          },
        );

        data.data_atual = moment().format("DD/MM/YYYY");
        if (typePrint === this.PRINT_FRONT) {
          this.printFront(data);
        } else {
          this.printBack(data);
        }
      } catch (Err) {
        this.$toaster.error(Err.message);
      }
    },

    printFront(pData) {
      const html = AttendedBulletinFrontPageHTML(pData);
      this.$utils.print.printHtml(html);
    },

    printBack(pData) {
      const html = AttendedBulletinBackPageHTML(pData);
      this.$utils.print.printHtml(html);
    },
  },
};
</script>
