import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamProceduresPerformedReport(
    $uns_id: Int!
    $set_id: Int!
    $pes_id: Int
    $stp_id: String
    $set_nome: String
    $pes_nome: String
    $stp_novo_nome_procedimento: String
    $eas_nome_sub_procedimentos: String
    $initialDate: String!
    $finalDate: String!
    $monthName: String!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchExamProceduresPerformedReport(
      uns_id: $uns_id
      set_id: $set_id
      pes_id: $pes_id
      stp_id: $stp_id
      set_nome: $set_nome
      pes_nome: $pes_nome
      stp_novo_nome_procedimento: $stp_novo_nome_procedimento
      eas_nome_sub_procedimentos: $eas_nome_sub_procedimentos
      initialDate: $initialDate
      finalDate: $finalDate
      monthName: $monthName
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PROCEDIMENTO
        SUBPROCEDIMENTO
        DIA_1
        DIA_2
        DIA_3
        DIA_4
        DIA_5
        DIA_6
        DIA_7
        DIA_8
        DIA_9
        DIA_10
        DIA_11
        DIA_12
        DIA_13
        DIA_14
        DIA_15
        DIA_16
        DIA_17
        DIA_18
        DIA_19
        DIA_20
        DIA_21
        DIA_22
        DIA_23
        DIA_24
        DIA_25
        DIA_26
        DIA_27
        DIA_28
        DIA_29
        DIA_30
        DIA_31
        TOTAL
        VALOR_UNITARIO
        VALOR_TOTAL
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamProceduresPerformedReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
