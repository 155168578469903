<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM6.30859 6.63672C5.83203 6.43359 5.28125 6.65234 5.07812 7.12891C4.875 7.60547 5.09375 8.15625 5.57031 8.35938L6.03516 8.55859C6.71094 8.84766 7.41016 9.0625 8.12891 9.19531V11.1523C8.12891 11.3203 8.10156 11.4883 8.04688 11.6445L6.92578 15.0078C6.76172 15.5 7.02734 16.0312 7.51953 16.1953C8.01172 16.3594 8.54297 16.0938 8.70703 15.6016L9.66016 12.7422C9.71094 12.5938 9.84766 12.4922 10.0039 12.4922C10.1602 12.4922 10.3008 12.5938 10.3477 12.7422L11.3008 15.6016C11.4648 16.0938 11.9961 16.3594 12.4883 16.1953C12.9805 16.0312 13.2461 15.5 13.082 15.0078L11.9609 11.6445C11.9063 11.4844 11.8789 11.3203 11.8789 11.1523V9.19922C12.5977 9.0625 13.2969 8.85156 13.9727 8.5625L14.4375 8.36328C14.9141 8.16016 15.1328 7.60938 14.9297 7.13281C14.7266 6.65625 14.1758 6.4375 13.6992 6.64062L13.2305 6.83594C12.2109 7.27344 11.1133 7.5 10 7.5C8.88672 7.5 7.79297 7.27344 6.76953 6.83594L6.30469 6.63672H6.30859ZM10 6.25C10.4144 6.25 10.8118 6.08538 11.1049 5.79235C11.3979 5.49933 11.5625 5.1019 11.5625 4.6875C11.5625 4.2731 11.3979 3.87567 11.1049 3.58265C10.8118 3.28962 10.4144 3.125 10 3.125C9.5856 3.125 9.18817 3.28962 8.89515 3.58265C8.60212 3.87567 8.4375 4.2731 8.4375 4.6875C8.4375 5.1019 8.60212 5.49933 8.89515 5.79235C9.18817 6.08538 9.5856 6.25 10 6.25Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Accessibility",
};
</script>
