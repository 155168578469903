import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: {
      intIdLote: variables.lot,
      itp_id_municipios_produtos: variables.product,
      ped_id_farmacia_destino: variables.pharmacy,
    },
  };

  return AuthLegacyRequest.post(
    "farmacia/controller-pedido/obter-estoque",
    sendData,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
