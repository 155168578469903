<template>
  <div class="appointment-period-report">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-adr"
          v-model="form.uns_id"
          class="inputitem"
          @change="selectingUnitHealth"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período de Consultas</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-apr"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          pop-up="bottom"
          :rules="{
            required: true,
          }"
        />
        <RgInputDate
          id="final-date-apr"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem mg"
          pop-up="bottom"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          :rules="{ required: true }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgSelectUnithealth } from "~tokio/primitive";

const moment = require("moment");

export default {
  name: "AppointmentPeriodReport",
  components: {
    RgInputDate,
    RgSelectUnithealth,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Período de Consultas",
      setColumn: "",
      form: {
        uns_id: null,
        uns_nome: "",
        initialDate: moment().add(-31, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      },
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left }" +
        ".report-column-3 { white-space: normal !important }" +
        ".report-column-4 { white-space: normal !important; text-align: left }" +
        ".report-column-5 { white-space: normal !important; text-align: left }" +
        ".report-column-6 { white-space: normal !important; text-align: left }" +
        ".report-column-9 { white-space: normal !important; text-align: left }" +
        ".report-column-10 { white-space: normal !important; text-align: left }"
      );
    },
    columnTable() {
      return this.setColumn;
    },
    getNameSmartTable() {
      return "AppointmentPeriodReport";
    },
    getLegend() {
      return this.legend;
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(31, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },
  watch: {
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(31, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  created() {
    this.COLUMNS = [
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Idade", key: "IDADE" },
      { name: "CNS", key: "CNS" },
      { name: "Prontuário da Unidade", key: "PRONTUARIO_UNIDADE" },
      { name: "Telefones", key: "TELEFONES" },
      { name: "Endereço", key: "ENDERECO", align: "left" },
      { name: "Bairro", key: "BAIRRO", align: "left" },
      { name: "Cidade", key: "CIDADE", align: "left" },
      { name: "Nome da Mãe", key: "RESPONSAVEL_PACIENTE", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Data Atendimento", key: "DATA_ATENDIMENTO" },
      { name: "Hora Atendimento", key: "HORA_ATENDIMENTO" },
      { name: "Funcionário", key: "FUNCIONARIO", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
    ];

    this.COLUMNS_WITHOUT_RECORD = this.COLUMNS.filter((column) => {
      return column.key !== "PRONTUARIO_UNIDADE";
    });
  },
  mounted() {},
  methods: {
    buildFilter() {
      this.mountLegend();
      return {
        uns_id: this.form.uns_id,
        uns_nome: this.form.uns_nome,
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
        columnsToPrint: this.columnsToPrint,
      };
    },
    async doSearch(pData) {
      try {
        this.$loader.start("Carregando...");

        this.setColumn = this.form.uns_id
          ? this.COLUMNS
          : this.COLUMNS_WITHOUT_RECORD;

        return this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_PERIOD_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish(1000);
      }
    },
    mountLegend() {
      this.legend = "";
    },
    selectingUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.uns_nome = pValue.label;
      } else {
        this.form.uns_nome = "";
      }
    },
    clearFilter() {
      this.form = {
        uns_id: null,
        uns_nome: "",
        initialDate: moment().add(-31, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      };
    },
  },
};
</script>
