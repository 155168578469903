<style src="./RgInput.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input--component">
    <RgFormBase
      :label="label"
      :titleOrientation="titleOrientation"
      :optional="optional"
      :required="isRequired"
    >
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="getId"
            ref="inputFieldId"
            v-model.trim="inputValue"
            autocomplete="off"
            class="rg-input--textbox"
            v-bind:step="step ? step.toString() : 'none'"
            :data-id="dataId"
            :type="type"
            :class="{
              'on-error': error.length > 0,
              'cursor-mouse-hover': cursosMouseHover,
            }"
            :style="styleExtra"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :tabindex="disabled ? -1 : tabIndex"
            :disabled="disabled"
            :readonly="disabled || readonly"
            @blur="blur"
            @focus="checkFocus"
            @change="change"
          />
        </div>
      </div>
    </RgFormBase>
    <span v-if="legend" class="legend">{{ legend }}</span>
  </fieldset>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";
import { replaceSpecial } from "~utils/sanitize.js";

export default {
  name: "RgInput",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    titleOrientation: {
      type: String,
    },
    change: {
      type: Function,
      default: () => {},
    },
    step: {
      type: Number,
      default: null,
    },
    styleExtra: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cursosMouseHover: {
      type: Boolean,
      default: false,
    },
    removeSpace: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: String,
      default: "",
    },
    needRemoveSpecialStrings: {
      type: Boolean,
      default: true,
    },
    isUpperCase: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    getId() {
      return !isEmpty(this.id) ? this.id : this.dataId;
    },
    isRequired() {
      if (
        (this.rules && this.rules.required) ||
        (this.rules &&
          this.rules.compositeValue &&
          this.rules.compositeValue.required)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    inputValue(pValue) {
      const isString = typeof pValue === "string";
      if (isString && this.needRemoveSpecialStrings) {
        this.inputValue = this.removeSpecialStrings(pValue);
      }
      if (pValue && this.removeSpace) {
        this.inputValue = pValue.replace(" ", "");
      }
      this.$emit("onInput", pValue);
    },
  },
  methods: {
    blur() {
      this.validate();
      this.$emit("blur");
      if (this.isUpperCase) this.inputValue = this.inputValue?.toUpperCase();
    },
    removeSpecialStrings(params) {
      const typeValidation = 2;
      return replaceSpecial(params, typeValidation);
    },
  },
};
</script>
