import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  NaoPossuiPermissaoException: `Você não possui permissão`,
  ExameEscalaAgendamentoException: `Foi informado um tipo de escala inválido`,
  ExameConflitoEscalaDePeriodoHorarioException: `Escalas conflitantes`,
};

export default async (context, variable) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/cadastrar",
    variable,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
