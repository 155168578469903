import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/hospitalization/hospitalizations/search/details/edit-patient",
  name: "hospitalization.hospitalizations.search.details.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Internações", link: OpenSubModule("hospitalization") },
      {
        label: "Lista de Internações",
        link: "/hospitalization/hospitalizations/search",
      },
      {
        label: "Cadastrar Internação",
        link: "/hospitalization/hospitalizations/details",
      },
      { label: "Editar Paciente" },
    ],
  },
};
