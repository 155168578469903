import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query CriticizeBulletinBilling(
    $cnes: String
    $cboCode: String
    $competence: String
    $sheet: String
    $employeeCNS: String
    $limit: Int
    $offset: Int
    $typeFilter: String
  ) {
    CriticizeBulletinBilling(
      cnes: $cnes
      cboCode: $cboCode
      competence: $competence
      sheet: $sheet
      professionalCns: $employeeCNS
      limit: $limit
      offset: $offset
      typeFilter: $typeFilter
    ) {
      rows {
        bof_id
        bof_competencia
        bof_faturado
        bof_cns
        bof_nome
        pdf_cnpj
        pdf_id
        pdf_id_boletins_faturamento
        pdf_cod_procedimento
        pdf_cod_cbo
        pdf_quantidade
        pdf_folha
        pdf_origem_pep
        ocp_id
        ocp_nome
        idade
        NO_PROCEDIMENTO
        NO_OCUPACAO
        TP_SEXO
        CO_SERVICO
        NO_SERVICO
        CO_CLASSIFICACAO
        NO_CLASSIFICACAO
        CO_CID
        NO_CID
        VALID_CNS
        VALID_VL_IDADE_MAXIMA
        VALID_VL_IDADE_MINIMA
        VALID_TP_SEXO
        VALID_CO_CID
        VALID_CO_OCUPACAO
        VALID_CO_SERVICO
        VALID_CO_CLASSIFICACAO
        VALID_ATRIBUTOS_CNS
        VALID_ATRIBUTOS_AUTORIZACAO
        VALID_ATRIBUTOS_CNPJ
        VALID_ATRIBUTOS_SERVICO_CLASSIFICACAO
        VALID_ATRIBUTOS_CID
        VALID_QT_MAXIMA_EXECUCAO
      }
      count
    }
  }
`;

export default async ({ commit, rootGetters }, variables) => {
  variables = variables || {};
  variables.competence = variables.competence
    ? variables.competence
    : rootGetters["Billing/BDSia/GET_PERIOD_DATE"];
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const list = data.CriticizeBulletinBilling
      ? data.CriticizeBulletinBilling.rows
      : [];

    let result = [];

    if (list && list.length > 0) {
      list.forEach((item, index) => {
        item.validProcedure = {
          CO_CLASSIFICACAO: item.VALID_CO_CLASSIFICACAO,
          CO_OCUPACAO: item.VALID_CO_OCUPACAO,
          CO_SERVICO: item.VALID_CO_SERVICO,
          QT_MAXIMA_EXECUCAO: item.VALID_QT_MAXIMA_EXECUCAO,
          TP_SEXO: item.VALID_TP_SEXO,
          VL_IDADE_MAXIMA: item.VALID_VL_IDADE_MAXIMA,
          VL_IDADE_MINIMA: item.VALID_VL_IDADE_MINIMA,
          CO_CID: item.VALID_CO_CID,
          CNS: item.VALID_CNS,
          ATRIBUTOS: {
            AUTORIZACAO: item.VALID_ATRIBUTOS_AUTORIZACAO,
            CID: item.VALID_ATRIBUTOS_CID,
            CNPJ: item.VALID_ATRIBUTOS_CNPJ,
            CNS: item.VALID_ATRIBUTOS_CNS,
            SERVICO_CLASSIFICACAO: item.VALID_ATRIBUTOS_SERVICO_CLASSIFICACAO,
          },
        };
        item.sequence = String(((index + 1) / 10).toFixed(1)).replace(".", "");

        // variáveis auxiliares
        const aux = item.validProcedure;
        const aux2 = item.validProcedure.ATRIBUTOS;

        if (
          aux2.AUTORIZACAO !== "0" &&
          aux.CO_CID !== "0" &&
          aux2.CID !== "0" &&
          aux2.CNPJ !== "0" &&
          aux2.CNS !== "0" &&
          aux2.SERVICO_CLASSIFICACAO !== "0" &&
          aux.CO_CLASSIFICACAO !== "0" &&
          aux.CO_OCUPACAO !== "0" &&
          aux.CO_SERVICO !== "0" &&
          aux.QT_MAXIMA_EXECUCAO !== "0" &&
          aux.TP_SEXO !== "0" &&
          aux.VL_IDADE_MAXIMA !== "0" &&
          aux.VL_IDADE_MINIMA !== "0" &&
          aux.CNS !== "0"
        ) {
          item.hasError = false;
        } else {
          item.hasError = true;
        }

        item.origem_atendimento = item?.pdf_origem_pep === 1 ? "PEP" : "ESUS";
      });
      result = list;
    }

    return {
      rows: result,
      total: data.CriticizeBulletinBilling.count,
    };
  } catch (e) {
    return Promise.reject(e.graphQLErrors);
  }
};
