import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import ExamInsertQueueRoute from "$exam/submodules/schedule/views/queue-insert/ExamInsertQueue.vue";

export default {
  path: "/exam/schedule/queue/edit-insert",
  name: "exam.schedule.queue.edit-insert",
  components: {
    default: ExamInsertQueueRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: OpenSubModule("exam") },
      { label: "Fila de Regulação", link: "/exam/schedule/queue" },
      { label: "Editar Fila", link: "/exam/schedule/queue/edit-insert" },
    ],
  },
};
