var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"radio-status-bulletin"},[_c('div',{staticClass:"container unselect"},[_c('div',{staticClass:"radio -with-exit",class:{
        '-active-with-exit': _vm.selectedStatus.includes(_vm.STATUS.COM_SAIDA),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.STATUS.COM_SAIDA)}}},[_vm._v(" Com Saída ")]),_c('div',{staticClass:"radio -waiting",class:{
        '-active-waiting': _vm.selectedStatus.includes(
          _vm.STATUS.AGUARDANDO_ATENDIMENTO,
        ),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.STATUS.AGUARDANDO_ATENDIMENTO)}}},[_vm._v(" Aguardando atendimento ")]),_c('div',{staticClass:"radio -not-met",class:{
        '-active-not-met': _vm.selectedStatus.includes(_vm.STATUS.NAO_ATENDIDO),
      },on:{"click":function($event){return _vm.toggleStatus(_vm.STATUS.NAO_ATENDIDO)}}},[_vm._v(" Não atendido ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }