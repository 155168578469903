<template>
  <Modulebox
    ref="modulebox"
    title="Estorno de Produtos e Medicamentos"
    class="pharmacy-satellite-reversal"
  >
    <div class="pharmacy-satellite-reversal-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Origem">
          <section class="pharmacy-satellite-reversal-origin-form">
            <RgSelectUnithealthPharmacy
              ref="unitHealthOrigin"
              v-model="form.unitHealthOrigin"
              :disabled="
                this.mutableProductList.length > 0 || printed || disabledByModal
              "
              :class="{
                disable: this.mutableProductList.length > 0 || printed,
              }"
              :rules="{ required: true }"
            />
            <RgSelectPharmacy
              ref="pharmacyOrigin"
              v-model="form.pharmacyOrigin"
              byUser
              :unitHealthId="form.unitHealthOrigin"
              :userId="userLoginId"
              :typePharmacy="SATELLITE_PHARMACY_TYPE"
              :rules="{ required: true }"
              :disabled="
                !form.unitHealthOrigin ||
                this.mutableProductList.length > 0 ||
                printed ||
                disabledByModal
              "
              :class="{
                disable:
                  !form.unitHealthOrigin ||
                  this.mutableProductList.length > 0 ||
                  printed,
              }"
              @change="selectedPharmacyOrigin"
            />
            <RgComboboxPharmacyTypeTransport
              ref="type"
              v-model="form.type"
              label="Tipo"
              :rules="{ required: true }"
              :disabled="
                this.mutableProductList.length > 0 || printed || disabledByModal
              "
              :class="{
                disable: this.mutableProductList.length > 0 || printed,
              }"
              @change="selectedTypes"
            />
            <RgInputDate
              ref="returnDate"
              v-model="form.returnDate"
              label="Data da Devolução"
              :rules="{ required: true }"
              :disabled="true"
              :class="{ disable: true }"
            />
          </section>
        </FormBase>

        <FormBase title="Destino">
          <section class="pharmacy-satellite-reversal-destination-form">
            <RgSelectUnithealthPharmacy
              ref="unitHealthDestiny"
              v-model="form.unitHealthDestiny"
              :disabled="
                this.mutableProductList.length > 0 || printed || disabledByModal
              "
              :class="{
                disable: this.mutableProductList.length > 0 || printed,
              }"
              openOnlyBottom
              :rules="{ required: true }"
            />
            <RgSelectPharmacy
              ref="pharmacyDestiny"
              v-model="form.pharmacyDestiny"
              :unitHealthId="form.unitHealthDestiny"
              :typePharmacy="CENTRAL_PHARMACY_TYPE"
              :rules="{ required: true }"
              :disabled="
                !form.unitHealthDestiny ||
                this.mutableProductList.length > 0 ||
                printed ||
                disabledByModal
              "
              :class="{
                disable:
                  !form.unitHealthDestiny ||
                  this.mutableProductList.length > 0 ||
                  printed,
              }"
              openOnlyBottom
              @change="selectedPharmacyDestiny"
            />
            <RgInput
              ref="remittance"
              v-model="form.remittance"
              label="Guia de Remessa"
              :rules="{ required: true }"
              :disabled="true"
              :class="{ disable: true }"
            />
          </section>
        </FormBase>

        <FormBase title="Produtos e Medicamentos">
          <section class="pharmacy-satellite-reversal-products">
            <div class="pharmacy-satellite-reversal-products-form">
              <div class="form">
                <RgSelectProduct
                  ref="product"
                  v-model="form.product"
                  :pharmacyId="Number(form.pharmacyOrigin)"
                  :typeMovimentation="PRODUCTS_HAVE_ENTRIES"
                  :withMovement="true"
                  :rules="{ required: true }"
                  :disabled="disableProduct || printed || disabledByModal"
                  :class="{ disable: disableProduct || printed }"
                  @change="selectedProduct"
                />
                <RgSelectTypePresentation
                  ref="typePresentation"
                  v-model="form.typePresentation"
                  label="Apresentação"
                  :rules="{ required: true }"
                  :disabled="true"
                  :class="{ disable: true }"
                />
                <RgSelectLotExpiration
                  v-if="stockLotExpiration"
                  id="lot"
                  ref="lot"
                  v-model="form.lotExpiration"
                  :pharmacyId="Number(form.pharmacyOrigin)"
                  :productId="Number(form.product)"
                  :rules="{ required: true }"
                  :disabled="!form.product || printed"
                  :class="{
                    disable: !form.product || printed || disabledByModal,
                  }"
                  @change="selectedLotExpiration"
                />
                <RgInput
                  ref="stock"
                  v-model="form.stock"
                  :rules="{ required: true }"
                  :disabled="true"
                  :class="{ disable: true }"
                  label="Estoque"
                  placeholder="000000000"
                />
                <RgInputNumber
                  ref="amountReturned"
                  v-model="form.amountReturned"
                  :rules="{ required: true, fn: validateAmount }"
                  :maxlength="10"
                  :readonly="printed || disabledByModal"
                  :disabled="printed || disabledByModal"
                  :class="{ disable: printed }"
                  label="Quantidade Devolvida"
                  placeholder="000000000"
                />
              </div>
              <div class="actions">
                <RgCleanButton
                  :disabled="printed || disabledByModal"
                  @click="openModalConfirmField"
                />
                <RgAddButton
                  id="add-btn"
                  title="Adicionar Produto/Medicamento"
                  :disabled="printed || disabledByModal"
                  @click="openModalConfirmReversal"
                />
              </div>
            </div>

            <hr class="hr" />

            <SmartTable
              ref="smartTable"
              name="PharmacyProductsReversal"
              :columns="COLUMNS"
              :body="mutableProductList"
              :total="mutableProductList.length"
              :showPagination="false"
              :initial-columns="7"
            >
              <div slot="top-buttons" class="top-buttons">
                <RgPrinterButton
                  id=" printer-btn"
                  label="Imprimir Guia de Remessa"
                  title="Imprimir Guia de Remessa"
                  small
                  :disabled="mutableProductList.length === 0 || disabledByModal"
                  :class="{
                    disable: mutableProductList.length === 0,
                  }"
                  @click="printerReversal"
                />
              </div>
            </SmartTable>
          </section>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="pharmacy-satellite-reversal-footer">
      <MediumButton
        :disabled="disabledByModal"
        title="Voltar"
        label="Voltar"
        @click="goBack"
      />
    </div>
    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmField"
      @getYes="cleanForm"
      @getOut="closeModalConfirmField"
      @close="closeModalConfirmField"
    />
    <ModalConfirmDefault
      id="modal-confirm-default"
      :show="modalConfirmReversal"
      subtitle="A operação não poderá ser desfeita. Deseja realizar o registro de Saída?"
      :message="
        this.table.product +
        ' - ' +
        this.table.lotExpiration +
        ' - ' +
        this.form.amountReturned
      "
      title="Registrar Saída de Produtos"
      yes-label="Salvar"
      no-label="Cancelar"
      buttonsRight
      @getYes="saveReversal"
      @getOut="closeModalConfirmReversal"
      @close="closeModalConfirmReversal"
    />
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInput,
  RgInputDate,
  RgInputNumber,
  RgAddButton,
  RgCleanButton,
  MediumButton,
  RgPrinterButton,
  ModalConfirmFieldClearing,
  ModalConfirmDefault,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";
import moment from "moment";

import { mapGetters } from "vuex";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgComboboxPharmacyTypeTransport from "$pharmacy/common/combobox/rg-combobox-pharmacy-type-transport/RgComboboxPharmacyTypeTransport";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import DeliveryNoteHTML from "$pharmacy/submodules/central/html/DeliveryNoteHTML";

const FORM = {
  unitHealthOrigin: null,
  pharmacyOrigin: null,
  type: null,
  returnDate: moment().format("DD/MM/YYYY"),
  unitHealthDestiny: null,
  pharmacyDestiny: null,
  remittance: null,
  product: null,
  typePresentation: null,
  lotExpiration: null,
  lot: null,
  expiration: null,
  stock: null,
  amountReturned: null,
};

const TABLE = {
  code: null,
  product: null,
  typePresentation: null,
  type: null,
  lot: false,
  pharmacyOrigin: false,
  pharmacyDestiny: null,
  stock: null,
  amountReturned: null,
  lotExpiration: null,
};

export default {
  name: "PharmacySatelliteReversal",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectUnithealthPharmacy,
    RgComboboxPharmacyTypeTransport,
    RgSelectLotExpiration,
    RgSelectTypePresentation,
    RgInput,
    RgInputDate,
    RgInputNumber,
    RgCleanButton,
    RgAddButton,
    MediumButton,
    SmartTable,
    RgPrinterButton,
    ModalConfirmFieldClearing,
    ModalConfirmDefault,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      table: this.$utils.obj.DeepCopy(TABLE),
      mutableProductList: [],
      destinyPharmacy: null,
      originPharmacy: null,
      printed: false,
      modalConfirmField: false,
      modalConfirmReversal: false,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      userName: "Login/GET_USER_NAME",
      clientName: "Login/GET_CLIENT_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
    }),
    preferenceStockType() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.frp_tipo_estoque"
      ];
    },
    stockLotExpiration() {
      return this.preferenceStockType === "2";
    },
    disableProduct() {
      return !this.form.pharmacyOrigin || !this.form.pharmacyDestiny;
    },
    disabledByModal() {
      return this.modalConfirmField;
    },
  },

  watch: {
    "form.unitHealthOrigin"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacyOrigin = null;
        this.form.product = null;
      }
    },
    "form.pharmacyOrigin"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.product = null;
      }
    },
    "form.unitHealthDestiny"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacyDestiny = null;
      }
    },
    "form.product"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.typePresentation = null;
        this.form.lotExpiration = null;
        this.form.stock = null;
      }
    },
    "form.lotExpiration"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.stock = null;
      }
    },
  },

  async mounted() {
    this.getRemittanceGuide();

    await this.fillFilter();
  },

  created() {
    this.COLUMNS = [
      { name: "Código", key: "code", align: "left", active: "true" },
      { name: "Nome", key: "product", align: "left", active: "true" },
      {
        name: "Apresentação",
        key: "typePresentation",
        align: "left",
        active: "true",
      },
      { name: "Lote-Validade", key: "lot", align: "left" },
      { name: "Origem", key: "origin", align: "left", active: "true" },
      { name: "Destino", key: "destiny", align: "left", active: "true" },
      { name: "Estoque", key: "stock", align: "left", active: "true" },
      {
        name: "Quantidade",
        key: "amountReturned",
        align: "left",
        active: "true",
      },
      { name: "Saída", key: "returnDate", align: "left" },
      {
        name: "Guia de Remessa",
        key: "remittance",
        align: "left",
      },
      { name: "Tipo de Estorno", key: "type", align: "left" },
    ];

    this.CENTRAL_PHARMACY_TYPE = 1;
    this.SATELLITE_PHARMACY_TYPE = 2;

    this.PRODUCTS_HAVE_ENTRIES = 1;
  },

  methods: {
    async fillFilter() {
      const existFilterData = this.$store.getters[
        "Pharmacy/Satellite/GET_FILTER_SATELLITE"
      ];

      if (existFilterData) {
        this.form.unitHealthOrigin = existFilterData.unitHealth;
        this.form.pharmacyOrigin = existFilterData.pharmacy;
      }
    },
    async openModalConfirmReversal() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }
      const existsDuplicate = this.verifyDuplicate();

      if (existsDuplicate.length > 0) {
        this.$toaster.info(
          "Escolha o mesmo produto com outro Lote - Validade para registrar a saída.",
          "O produto já faz parte desse registro de Saída",
        );
        return false;
      }
      this.modalConfirmReversal = true;
    },
    closeModalConfirmReversal() {
      this.modalConfirmReversal = false;
    },
    closeModalConfirmField() {
      this.modalConfirmField = false;
    },
    openModalConfirmField() {
      this.modalConfirmField = true;
    },
    async getRemittanceGuide() {
      try {
        this.$loader.start();
        const result = await this.$store.dispatch(
          "Pharmacy/Satellite/GENERATE_REMITTANCE_GUIDE",
        );

        result && result.length > 0
          ? (this.form.remittance = result.toUpperCase())
          : (this.form.remittance = null);
      } catch (pErr) {
        this.$toaster.error("Erro ao gerar remessa");
      } finally {
        this.$loader.finish();
      }
    },
    async getStock() {
      try {
        if (this.form.pharmacyOrigin && this.form.product && this.form.lot) {
          this.$loader.start();

          const data = {
            pharmacy: Number(this.form.pharmacyOrigin),
            product: Number(this.form.product),
            lot: this.form.lot,
          };

          const result = await this.$store.dispatch(
            "Pharmacy/Satellite/GET_STOCK",
            data,
          );

          if (result) {
            this.form.stock = result;
            this.table.stock = result;
          } else {
            this.form.stock = null;
            this.table.stock = null;
          }
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao obter o estoque");
      } finally {
        this.$loader.finish();
      }
    },
    verifyDuplicate() {
      const filterDuplicate = this.mutableProductList.filter((pData) => {
        if (
          pData.code === this.table.code &&
          pData.lot === this.table.lotExpiration
        )
          return true;
      });

      return filterDuplicate;
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async saveReversal() {
      try {
        this.$loader.start();

        const variables = this.parseDataSave();

        await this.$store.dispatch(
          "Pharmacy/Satellite/REGISTER_REVERSAL",
          variables,
        );

        this.insertProductTable();

        this.$toaster.success("Estorno registrado com sucesso");
        const info = {
          intIdMunicipioProduto: this.form.product,
          intIdFarmacia: this.form.pharmacyOrigin,
        };
        const data = await this.$store.dispatch(
          "Pharmacy/MIN_LIMIT_EXCEEDED",
          info,
        );

        if (data !== 0) {
          this.$toaster.info(
            "Faça uma solicitação para repor o estoque do produto.",
            "O estoque mínimo foi alcançado",
          );
        }

        this.cleanFormSave();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao registrar estorno",
        );
      } finally {
        this.$loader.finish();
        this.modalConfirmReversal = false;
      }
    },
    parseDataSave() {
      const variables = {
        trs_data: this.form.returnDate,
        trs_guia_remessa: this.form.remittance,
        trs_id_farmacias_destino: this.form.pharmacyDestiny,
        trs_id_farmacias_origem: this.form.pharmacyOrigin,
        trs_id_municipios_produtos: this.form.product,
        trs_id_tipos_transportes: this.form.type,
        trs_id_usuarios: this.userLoginId,
        trs_lote: this.form.lot,
        trs_quantidade: this.form.amountReturned.replace(/[.]/g, ""),
        trs_validade_lote: this.form.expiration,
      };

      return variables;
    },
    insertProductTable() {
      const data = {
        code: this.table.code,
        product: this.table.product,
        typePresentation: this.table.typePresentation,
        type: this.table.type,
        lot: this.table.lotExpiration,
        origin: this.table.pharmacyOrigin,
        destiny: this.table.pharmacyDestiny,
        stock: this.table.stock,
        amountReturned: this.form.amountReturned.replace(/[.]/g, ""),
        returnDate: this.form.returnDate,
        remittance: this.form.remittance,
      };

      this.mutableProductList.push(data);
    },
    validateAmount(pValue, pErrors) {
      const amount = Number(pValue.replace(/[^0-9]/g, ""));
      const stock = Number(this.form.stock.replace(/[^0-9]/g, ""));

      if (pValue && Number(pValue) < 1) {
        pErrors.push("Informe um valor maior que zero.");
        return false;
      }

      if (stock < amount) {
        pErrors.push("A quantidade não pode ser maior que o estoque.");
        return false;
      }

      return true;
    },
    selectedPharmacyOrigin(pPharmacy) {
      if (pPharmacy) {
        this.table.pharmacyOrigin = pPharmacy.far_nome;
        this.originPharmacy = pPharmacy.far_nome;
      } else {
        this.table.pharmacyOrigin = null;
        this.originPharmacy = null;
      }
    },
    selectedPharmacyDestiny(pPharmacy) {
      if (pPharmacy) {
        this.table.pharmacyDestiny = pPharmacy.far_nome;
        this.destinyPharmacy = pPharmacy.far_nome;
      } else {
        this.table.pharmacyDestiny = null;
        this.destinyPharmacy = null;
      }
    },
    selectedTypes(pTypes) {
      if (pTypes) {
        this.table.type = pTypes[0].ttr_nome;
      } else {
        this.table.type = null;
      }
    },
    selectedProduct(pProduct) {
      if (pProduct) {
        this.form.typePresentation = pProduct.mtu_id;

        this.table.code = pProduct.mpd_codigo;
        this.table.product = pProduct.mpd_novo_principio_ativo;
        this.table.typePresentation = pProduct.mtu_novo_nome;
      } else {
        this.form.typePresentation = null;

        this.table.code = null;
        this.table.product = null;
        this.table.typePresentation = null;
      }
    },
    selectedLotExpiration(pLot) {
      if (pLot) {
        this.form.lot = pLot.value;
        this.form.expiration = pLot.fel_validade_lote;

        this.table.lotExpiration = pLot.label;

        this.getStock();
      } else {
        this.form.lot = null;
        this.form.expiration = null;

        this.table.lotExpiration = null;
      }
    },

    async printerReversal() {
      try {
        this.$loader.start();
        const variables = {
          strGuiaRemessa: this.form.remittance,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Central/PRINTER_OUTPUT",
          variables,
        );

        this.printed = true;
        const dataRequest = {};

        dataRequest.origem = this.originPharmacy;
        dataRequest.destino = this.destinyPharmacy;
        dataRequest.trs_data = this.form.returnDate;
        dataRequest.trs_guia_remessa = this.form.remittance;
        dataRequest.usu_nome = this.userName;

        const html = DeliveryNoteHTML(
          dataRequest,
          result,
          this.clientName,
          this.userName,
          this.unitHealth,
        );
        this.$utils.print.printHtml(html);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir guia de remessa",
        );
      } finally {
        this.$loader.finish();
      }
    },
    cleanFormSave() {
      this.form.product = null;
      this.form.typePresentation = null;
      this.form.lotExpiration = null;
      this.form.lot = null;
      this.form.expiration = null;
      this.form.stock = null;
      this.form.amountReturned = null;

      // this.getRemittanceGuide();
      this.cleanFieldsValidation();
    },
    cleanForm() {
      if (this.mutableProductList.length === 0) {
        this.form.unitHealthOrigin = null;
        this.form.pharmacyOrigin = null;
        this.form.type = null;
        this.form.returnDate = moment().format("DD/MM/YYYY");
        this.form.unitHealthDestiny = null;
        this.form.pharmacyDestiny = null;
        // this.form.remittance= null
        this.form.product = null;
        this.form.typePresentation = null;
        this.form.lotExpiration = null;
        this.form.lot = null;
        this.form.expiration = null;
        this.form.stock = null;
        this.form.amountReturned = null;

        // this.form = this.$utils.obj.DeepCopy(FORM);
        // this.table = this.$utils.obj.DeepCopy(TABLE);
        // this.getRemittanceGuide();
      } else {
        this.cleanFormSave();
      }
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "unitHealthOrigin",
        "pharmacyOrigin",
        "type",
        "returnDate",
        "unitHealthDestiny",
        "pharmacyDestiny",
        "remittance",
        "product",
        "typePresentation",
        "lot",
        "stock",
        "amountReturned",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
