import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetPlaceByBedType(
    $set_id: Int!
    $itl_id: Int
    $inl_id: Int
    $onlyActive: Boolean
    $situation: SituationBedTypeEnum
  ) {
    placeByBedType(
      set_id: $set_id
      itl_id: $itl_id
      inl_id: $inl_id
      situation: $situation
      onlyActive: $onlyActive
    ) {
      rows {
        lca_id
        lca_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.placeByBedType.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
