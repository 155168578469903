<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnithealthExternal from "./action/SearchUnithealthExternal";
export default {
  name: "RgSuggestUnithealthExternal",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Unidade Externa Solicitante",
    },
    defaultText: {
      type: String,
      default: "Digite o nome da unidade",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      let data = [];

      data = await SearchUnithealthExternal({
        arrFiltro: {
          ius_nome: null,
        },
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.ius_id,
          label: `${item.ius_nome}`,
        };
      });
    },
  },
};
</script>
