<template lang="html">
  <div v-if="show" class="modal-register-pharmacy">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-register-pharmacy-header">
        <span class="title">
          {{ edition ? "Editar Farmácia" : "Cadastrar Farmácia" }}
        </span>
      </div>

      <div slot="body" class="modal-register-pharmacy-body">
        <RgValidatorForm ref="validator">
          <FormBase title="Descrição da Farmácia" class="form-base">
            <div class="grid">
              <RgSelectUnithealth
                ref="unitHealthId"
                v-model="form.unitHealthId"
                :disabled="edition"
                :class="{ disable: edition }"
                :rules="{ required: true }"
                class="unit"
                @change="selectedUnithealth"
              />

              <RgSelectTypePharmacy
                ref="type"
                v-model="form.type"
                :disabled="edition"
                :class="{ disable: edition }"
                :rules="{ required: true }"
                class="type"
              />

              <RgInput
                ref="name"
                v-model="form.name"
                :rules="{ required: true }"
                :disabled="edition"
                :class="{ disable: edition }"
                :maxlength="45"
                label="Farmácia"
                placeholder="Digite o nome da farmácia"
                class="name"
              />

              <RgSelectPharmacyEmployee
                ref="employee"
                v-model="form.employee"
                :rules="{ required: true }"
                :disabled="!form.unitHealthId"
                :class="{ disable: !form.unitHealthId }"
                :unitHealthId="form.unitHealthId"
                openOnlyTop
                label="Profissional Responsável"
                class="employee"
              />

              <RgInput
                v-model="form.description"
                label="Descrição"
                placeholder="Digite a descrição da nova farmácia cadastrada"
                class="description"
                :maxlength="100"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </div>

      <div slot="footer" class="modal-register-pharmacy-footer">
        <RgCleanButton small @click="cleanForm" />

        <RgSaveButton medium @click="savePharmacy" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgBaseModal,
  RgValidatorForm,
  RgSelectUnithealth,
  RgInput,
  RgCleanButton,
  RgSaveButton,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

import RgSelectTypePharmacy from "$pharmacy/common/select/rg-select-type-pharmacy/RgSelectTypePharmacy";
import RgSelectPharmacyEmployee from "$pharmacy/submodules/register/common/components/select/rg-select-employee/RgSelectPharmacyEmployee";

const FORM = {
  pharmacyId: null,
  unitHealthId: null,
  unitHealthName: null,
  type: null,
  name: null,
  employee: null,
  employeeId: null,
  description: null,
};

export default {
  name: "ModalRegisterPharmacy",
  components: {
    RgBaseModal,
    RgValidatorForm,
    FormBase,
    RgInput,
    RgSelectPharmacyEmployee,
    RgSelectUnithealth,
    RgSelectTypePharmacy,
    RgCleanButton,
    RgSaveButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    edition: {
      type: Boolean,
      default: false,
    },
    pharmacyId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
    };
  },
  computed: {
    ...mapGetters({
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),
  },
  watch: {
    show(pValue) {
      if (pValue) {
        if (this.edition) this.fillForm();
      } else {
        this.close();
      }
    },
  },
  methods: {
    selectedUnithealth(pValue) {
      if (pValue) {
        this.form.unitHealthName = pValue.label;
      } else {
        this.form.unitHealthName = null;
      }
    },
    async fillForm() {
      try {
        this.$loader.start();

        const pharmacy = await this.$store.dispatch(
          "Pharmacy/Register/GET_PHARMACY",
          { intIdFarmacia: this.pharmacyId },
        );

        this.form.pharmacyId = pharmacy.far_id;
        this.form.unitHealthId = Number(pharmacy.far_id_unidades_saude);
        this.form.type = pharmacy.far_id_tipos_farmacia;
        this.form.name = pharmacy.far_nome;
        this.form.employee = pharmacy.far_id_funcionarios;
        this.form.description = pharmacy.far_descricao;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar farmácia");
      } finally {
        this.$loader.finish();
      }
    },
    async savePharmacy() {
      const isValid = this.$refs.validator
        ? await this.$refs.validator.validate()
        : false;

      if (!isValid) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            far_id: this.edition ? this.form.pharmacyId : null,
            far_id_funcionarios: Number(this.form.employee),
            far_id_tipos_farmacia: Number(this.form.type),
            far_id_unidades_saude: Number(this.form.unitHealthId),
            far_nome:
              this.form.name && this.form.name.length > 0
                ? this.form.name.toUpperCase()
                : null,
            far_descricao:
              this.form.description && this.form.description.length > 0
                ? this.form.description.toUpperCase()
                : null,
          },
        };

        if (this.edition) {
          await this.$store.dispatch(
            "Pharmacy/Register/EDIT_PHARMACY",
            variables,
          );
        } else {
          await this.$store.dispatch(
            "Pharmacy/Register/REGISTER_PHARMACY",
            variables,
          );
        }

        if (this.edition) {
          this.$toaster.success("Farmácia alterada com sucesso");
        } else {
          this.$toaster.success("Farmácia cadastrada com sucesso");
        }

        this.close();
        this.$emit("reSearch");
      } catch (pErr) {
        const error = pErr.toString();

        if (this.edition) {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao alterar farmácia.",
          );
        } else {
          const { esus_response } = pErr;
          if (esus_response?.hasError) {
            const isDuplicityError =
              esus_response.exception === "DuplicidadeFarmaciaException";

            if (isDuplicityError) {
              this.$toaster.error(
                `Já existe registro com essas informações:  ${this.form.unitHealthName} - ${this.form.name}.`,
                "Não foi possível cadastrar a Farmácia",
              );
            }
          } else {
            this.$toaster.error(
              this.$utils.sanitize.formatError(error.message || error),
              "Erro ao cadastrar farmácia",
            );
          }
        }
      } finally {
        this.$loader.finish();
      }
    },
    cleanFieldsValidation() {
      const fieldsRefs = ["unitHealthId", "type", "name", "employee"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cleanForm() {
      if (this.edition) {
        this.form.employee = null;
        this.form.employeeId = null;
        this.form.description = null;
      } else {
        this.form = this.$utils.obj.DeepCopy(FORM);
      }
      this.cleanFieldsValidation();
    },
    close() {
      this.cleanForm();
      this.$emit("close");
    },
  },
};
</script>
