<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchPharmacy from "./action/SearchPharmacy";
import SearchPharmacyByUser from "./action/SearchPharmacyByUser";

export default {
  name: "RgSelectPharmacy",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Farmácia",
    },
    unitHealthId: {
      type: Number,
      default: 0,
    },
    typePharmacy: {
      type: Number,
      default: null,
    },
    userId: {
      type: Number,
      default: 0,
    },
    viewPharmacyType: {
      type: Boolean,
      default: false,
    },
    byUser: {
      type: Boolean,
      default: false,
    },
    filter: {
      default: null,
    },
  },
  data: () => {
    return {
      pharmacy: [],
    };
  },
  watch: {
    unitHealthId(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    pharmacy: function (val) {
      this.updateOptions();
    },
    filter(pValue) {
      if (pValue && pValue.length > 0) {
        this.filterResult();
      }
    },
    typePharmacy() {
      this.doSearch();
    },
  },
  async mounted() {
    await this.doSearch();
  },
  methods: {
    getData() {
      return this.pharmacy;
    },
    async doSearch() {
      this.pharmacy = [];

      if (this.unitHealthId) {
        let data;
        if (this.byUser) {
          data = await SearchPharmacyByUser({
            intIdUnidade: this.unitHealthId,
            intIdUsuario: this.userId,
            intIdTipoFarmacia: this.typePharmacy,
            blnExibirTipo: this.viewPharmacyType,
          });
        } else {
          data = await SearchPharmacy({
            intIdUnidade: this.unitHealthId,
            intIdTipoFarmacia: this.typePharmacy,
            blnExibirTipo: this.viewPharmacyType,
          });
        }

        this.pharmacy = data.map((item) => {
          return {
            value: item.data,
            label: item.label,
            far_nome: item.far_nome,
            ...item,
          };
        });
      }
      if (this.filter && this.filter.length > 0) this.filterResult();
    },
    filterResult() {
      if (Array.isArray(this.filter)) {
        const aux = [];
        let aux2 = [];

        for (const pValue of this.filter) {
          aux2 = this.pharmacy.filter(
            (pData) => Number(pData.value) === Number(pValue.far_id),
          );
          aux2.forEach((item) => {
            if (!aux.includes(item)) aux.push(item);
          });
        }

        this.pharmacy = aux;
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
