<style src="./ModalConfirmSchedule.scss" lang="scss" scoped></style>
<template lang="html">
  <div v-if="show" class="modal-confirm-default">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">
          {{ title }}
        </h1>
      </div>

      <div slot="body" class="body">
        <div class="question">
          <span class="text">
            {{ question }}
          </span>
        </div>
        <FormBase title="Agendamentos do dia" class="content-history">
          <div class="table">
            <tr class="header">
              <th class="title">Data</th>
              <th class="title">Hora</th>
              <th class="title">Unidade de Saúde</th>
              <th class="title">Profissional</th>
              <th class="title">Ocupação</th>
              <th class="title">Local de Atendimento</th>
              <th class="title">Setor</th>
              <th class="title">Usuário</th>
              <th class="title">Status</th>
            </tr>

            <tr
              v-for="(item, index) in mutableListHistory"
              :key="index"
              class="body"
            >
              <td class="data">{{ item.eha_data_exame }}</td>
              <td class="data">{{ item.eap_hora }}</td>
              <td class="data">{{ item.uns_nome }}</td>
              <td class="data">{{ item.profissionalName }}</td>
              <td class="data">{{ item.occupationName }}</td>
              <td class="data">{{ item.lca_nome }}</td>
              <td class="data">{{ item.set_nome }}</td>
              <td class="data">{{ item.usu_nome }}</td>
              <td class="data">{{ item.status }}</td>
            </tr>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel-btn" medium @click="getCancel" />
        <MediumButton
          id="save-btn"
          title="salvar"
          label="Salvar agendamento"
          @click="save"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, MediumButton } from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalConfirmSchedule",
  components: {
    RgBaseModal,
    RgCancelButton,
    MediumButton,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientScheduleId: {
      type: Number,
    },
    scheduleDate: {
      type: String,
    },
  },
  data() {
    return {
      mutableListHistory: null,
      status: null,
    };
  },
  computed: {
    title() {
      const onePatient =
        this.mutableListHistory && this.mutableListHistory.length === 1;

      return onePatient
        ? "Este paciente já possui agendamento para o dia selecionado."
        : "Alguns destes pacientes já possuem agendamento para o dia selecionado.";
    },

    question() {
      return "Deseja agendar mesmo assim?";
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.searchHistory();
      }
    },
  },

  methods: {
    async searchHistory() {
      this.mutableListHistory = await this.$store.dispatch(
        "Exam/Schedule/SEARCH_HISTORY_SCHEDULE",
        {
          arrFormData: {
            ees_id: 0,
            eha_data_exame: this.scheduleDate,
            exa_id: 0,
            lag_codigo_agendamento: 0,
            lag_id: 0,
            les_id: 0,
            limiteFim: 1000000,
            limiteInicio: 0,
            pxe_id: this.patientScheduleId,
            set_id: 0,
            uns_id: 0,
          },
        },
      );
      this.mutableListHistory.forEach((item) => {
        item.profissionalName = item.pes_nome.split("-")[0];
        item.occupationName = item.pes_nome.split("-")[1];

        if (item.eap_id_exames_efetivacao === "1") {
          item.status = "EM ABERTO";
        } else if (item.eap_id_exames_efetivacao === "2") {
          item.status = "EFETIVADA";
        } else if (item.eap_id_exames_efetivacao === "3") {
          item.status = "NÃO EFETIVADA";
        }
      });
    },

    save() {
      this.$emit("save", 1);
    },

    getCancel() {
      this.$emit("cancel", 1);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
