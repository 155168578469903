import moment from "moment";

const EmployeeAttendanceScaleHTML = (pInfo, pArray) => {
  const emissao = moment().format("DD/MM/YYYY");

  let dadosTabela = "";
  let dadosCabecalho = "";
  pArray.forEach((item) => {
    dadosTabela += "<tr>";
    dadosTabela += item.dsm_nome ? `<td>${item.dsm_nome}</td>` : "";

    dadosTabela += item.eag_data_inicio_escala
      ? `<td>${item.eag_data_inicio_escala}</td>`
      : "";
    dadosTabela += item.eag_data_fim_escala
      ? `<td>${item.eag_data_fim_escala}</td>`
      : "";
    dadosTabela += item.cte_nome
      ? `<td>${item.cte_nome.toUpperCase()}</td>`
      : "";
    dadosTabela += item.set_nome ? `<td>${item.set_nome}</td>` : "";
    dadosTabela += item.ocp_nome ? `<td>${item.ocp_nome}</td>` : "";
    dadosTabela += item.pes_nome ? `<td>${item.pes_nome}</td>` : "";
    dadosTabela += item.dsm_sigla ? `<td>${item.dsm_sigla}</td>` : "";

    dadosTabela += item.eag_inicio_atendimentos
      ? `<td>${item.eag_inicio_atendimentos}</td>`
      : "";
    dadosTabela += item.validade ? `<td>${item.validade}</td>` : "";
    dadosTabela += item.eag_final_atendimentos
      ? `<td>${item.eag_final_atendimentos}</td>`
      : "";

    dadosTabela += item.eag_intervalo_consultas
      ? `<td>${item.eag_intervalo_consultas}</td>`
      : "";

    dadosTabela += item.eag_quantidade_consultas
      ? `<td>${item.eag_quantidade_consultas}</td>`
      : "";
    dadosTabela += item.uns_nome ? `<td>${item.uns_nome}</td>` : "";

    dadosTabela += "</tr>";
  });

  dadosCabecalho += "<tr>";
  dadosCabecalho += pArray[0].dsm_nome ? `<th>Dia do atendimento</th>` : "";
  dadosCabecalho += pArray[0].eag_data_inicio_escala
    ? `<th>Inicio da Escala</th>`
    : "";
  dadosCabecalho += pArray[0].eag_data_fim_escala
    ? `<th>Fim da Escala</th>`
    : "";
  dadosCabecalho += pArray[0].cte_nome ? `<th>Tipo da Escala</th>` : "";
  dadosCabecalho += pArray[0].set_nome ? `<th>Setor</th>` : "";
  dadosCabecalho += pArray[0].ocp_nome ? `<th>Ocupação</th>` : "";
  dadosCabecalho += pArray[0].pes_nome ? `<th>Profissional</th>` : "";
  dadosCabecalho += pArray[0].dsm_sigla ? `<th>Dia</th>` : "";
  dadosCabecalho += pArray[0].eag_inicio_atendimentos
    ? `<th>Horário inicial</th>`
    : "";
  dadosCabecalho += pArray[0].validade ? `<th>Validade</th>` : "";
  dadosCabecalho += pArray[0].eag_final_atendimentos
    ? `<th>Horário Final</th>`
    : "";
  dadosCabecalho += pArray[0].eag_intervalo_consultas
    ? `<th>Intervalo</th>`
    : "";
  dadosCabecalho += pArray[0].eag_quantidade_consultas ? `<th>Vagas</th>` : "";
  dadosCabecalho += pArray[0].uns_nome ? `<th>Unidade de Saúde</th>` : "";

  dadosCabecalho += "</tr>";

  const html = `
    <html>
      <head>
        <meta charset="utf-8" />
        <title>Esus</title>
        <style type="text/css">
          page {
            background: white;
            display: block;
            margin: 0 auto;
          }

          page[size="A4"] {
            width: 21cm;
          }

          body {
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            color: #424141;
          }

          .mg-b-1 {
            margin: 0.3cm 0 !important;
          }

          .h1 {
            font-size: 12pt;
            text-transform: uppercase;
            text-align: center;
            margin: 0;
            font-weight: bold;
            position: relative;
            bottom: 10px;
          }

          .center-locale {
            text-align: center;
            margin: 0;
            font-size: 12pt;
          }

          .info {
            font-size: 13.5px;
          }

          .field {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .label {
            text-align: right;
            margin: 0;
            width: 135px;
            font-weight: bold;
            margin-right: 10px;
          }

          .cabecalho {
            width: 100%;
            float: left;
            overflow: hidden;
            table-layout: fixed;
            display: block;
            word-wrap: break-word;
            position: relative;
            bottom: 30px;
          }

          .brasao {
            max-width: 150px;
            max-height: 100px;
            margin-right: 1%;
            float: right;
            width: 150px;
            height: 100px;
          }

          .tabela {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid;
          }

          th {
            border: 1px solid #cdcdcd;
            padding: 5px 3px;
            background-color: #f1f1f1;
            font-size: 10pt;
          }

          td {
            border: 1px solid #cdcdcd;
            background-color: #f9f9f9;
            font-size: 10pt;
            padding: 5px 3px;
            text-align: center;
          }

          .footer {
            display: flex;
            justify-content: space-between;
            font-size: 9pt;
          }
        </style>
      </head>

      <body>
        <page size="A4">
          <p class="center-locale">${pInfo.cli_nome || ""}</p>
          <div class="cabecalho">
            <img src="/static/images/logo-esus.png" class="brasao" />
          </div>

          <br />
          <br />

          <p class="h1">ESCALA DE ATENDIMENTOS</p>

          <br />

          <div class="info">
            <div class="field">
              <p class="label">Unidade de Saúde: </p>
              <span>${pInfo.uns_nome}</span>
            </div>
            <br />

            <div class="field">
              <p class="label">Setor: </p>
              <span>${pInfo.set_nome}</span>
            </div>
            <br />

            <div class="field">
              <p class="label">Nome: </p>
              <span>${pInfo.pes_nome}</span>
            </div>
            <br />

            <div class="field">
              <p class="label">Ocupação: </p>
              <span>${pInfo.ocp_nome}</span>
            </div>
            <br />

            <div class="field">
              <p class="label">Conselho de Classe: </p>
              <span>${pArray[0].fun_conselho_classe || ""}</span>
            </div>
          </div>

          <p class="center-locale mg-b-1">Escalas Válidas de Atendimento</p>
          <table class="tabela">
            <thead>
                ${dadosCabecalho}
            </thead>
            <tbody>
                ${dadosTabela}
            </tbody>
          </table>

          <div class="footer">
            <p>ESCALA DE ATENDIMENTOS</p>

            <p>Emitido em ${emissao}</p>
          </div>
        </page>
      </body>
    </html>
    `;
  return html;
};

export default EmployeeAttendanceScaleHTML;
