<template>
  <Modulebox
    title="Transferência de Consulta Profissional"
    class="transfer-appointments"
  >
    <div class="content">
      <FormBase class="area" title="Origem">
        <div class="tooltips">
          <Tooltip class="tooltip-print">
            <IconInfoHelper slot="icon" style="width: 28.5px; height: 28.5px" />
            <div slot="content" class="content">
              <div class="title">
                <span>
                  Para realizar as transferências, basta selecionar os pacientes
                  exibidos no resultado da busca.
                </span>
              </div>
            </div>
          </Tooltip>
          <Tooltip class="tooltip-print">
            <IconLightBulbHelper slot="icon" />
            <div slot="content" class="content">
              <div class="title">
                <span>
                  Para evitar duplicidades e conflitos de agenda, não é possivel
                  transferir pacientes enviados para o PEP.
                </span>
              </div>
            </div>
          </Tooltip>
        </div>
        <RgValidatorForm ref="validatorOrigin">
          <section class="origin-grid">
            <div class="form">
              <RgInputDate
                id="date-origin"
                ref="dateOrigin"
                v-model="form.origin.date"
                data-id="data"
                :rules="{ required: true }"
                :minDate="Date.now()"
              />
            </div>
            <div class="form">
              <ComboboxSector
                id="selected-sector"
                ref="comboboxSectorOrigin"
                v-model="form.origin.selectedIdSector"
                label="Setor"
                :uns_id="unitHealthId"
                :subModuleId="subModuleId"
                :rules="{ required: true }"
              />
            </div>
            <div class="form">
              <ComboboxOriginEmployee
                id="origin-employee"
                ref="comboboxOriginEmployee"
                v-model="form.origin.selectedIdEmployee"
                label="Escala"
                :class="{
                  disable: !form.origin.selectedIdSector || !form.origin.date,
                }"
                :disabled="!form.origin.selectedIdSector || !form.origin.date"
                :intIdUnidadeSaude="unitHealthId"
                :intIdSetor="form.origin.selectedIdSector"
                :strData="form.origin.date"
                :rules="{ required: true }"
                @change="onSelectEmployeeOrigin"
                @loading="loadingOriginEmployee"
              />
            </div>
          </section>
          <section class="second-grid">
            <div class="table">
              <div class="content">
                <div class="title">Pacientes agendados</div>
                <SmartTable
                  ref="smartTableOrigin"
                  name="TransferAppointments"
                  v-bind="propsSmartTableOrigin"
                  class="smart-table"
                  @getMultLines="onlistSelectedOriginPatients"
                />
              </div>
            </div>

            <div class="preview">
              <div class="title">Pacientes para transferência</div>
              <div id="preview-box" class="preview-box">
                <div
                  v-for="(item, index) in form.listSelectedOriginPatients"
                  :key="`pb-${index}`"
                  class="item"
                >
                  {{ item.pes_nome }}
                </div>
              </div>
              <div class="footer">
                <div class="end">
                  Pacientes selecionados:
                  {{ form.listSelectedOriginPatients.length }}
                </div>
              </div>
            </div>
          </section>
        </RgValidatorForm>
      </FormBase>

      <FormBase class="area" title="Destino">
        <RgValidatorForm ref="validatorDestiny">
          <section class="destiny-grid">
            <div class="form">
              <RgInputDate
                id="destiny-date"
                ref="dateDestiny"
                v-model="form.destiny.date"
                data-id="data"
                class="inputitem"
                :rules="{ required: true }"
                :minDate="Date.now()"
              />

              <ComboboxSector
                id="destiny-sector"
                ref="comboboxSectorDestiny"
                v-model="form.destiny.selectedIdSector"
                label="Setor"
                :uns_id="unitHealthId"
                :subModuleId="subModuleId"
                :rules="{ required: true }"
              />

              <ComboboxPlace
                id="place-destiny"
                ref="comboboxPlaceDestiny"
                v-model="form.destiny.selectedIdPlace"
                label="Local de Atendimento"
                :class="{ disable: !form.destiny.selectedIdSector }"
                :disabled="!form.destiny.selectedIdSector"
                :intIdSetor="form.destiny.selectedIdSector"
                :rules="{ required: true }"
              />

              <ComboboxDestinyEmployee
                id="destiny-employee"
                ref="comboboxDestinyEmployee"
                v-model="form.destiny.selectedIdEmployee"
                label="Escala"
                useDisabledOption
                :class="{
                  disable: !form.destiny.selectedIdSector || !form.destiny.date,
                }"
                :disabled="!form.destiny.selectedIdSector || !form.destiny.date"
                :strData="form.destiny.date"
                :intIdUnidadeSaude="unitHealthId"
                :intIdSetor="form.destiny.selectedIdSector"
                :blnHagAtendimento="null"
                :rules="{ required: true }"
                @change="onSelectEmployeeDestiny"
              />

              <div
                v-show="hasDestinyScale"
                id="vacancies-avaliable"
                :class="{
                  available: vacanciesAvaliable,
                  notAvailable: !vacanciesAvaliable,
                }"
                class="vacancies"
              >
                {{ vacanciesText }}
              </div>
            </div>
          </section>
        </RgValidatorForm>
      </FormBase>
    </div>

    <div class="appointments-footer">
      <RgCleanButton
        id="clean-btn"
        small
        type="button"
        title="Limpar"
        @click="confirmCleanForm"
      />

      <div class="margin-left">
        <RgSaveButton
          id="save-btn"
          ref="saveButton"
          large
          class="save"
          type="button"
          label="Transferir"
          @click="actionSaveForm"
        />
      </div>
    </div>

    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="actionCleanForm"
      @getOut="closeModalConfirmFieldClearing"
      @close="closeModalConfirmFieldClearing"
    />

    <ModalPrintTransfer
      id="modal-print-transfer"
      :v-if="showModalPrintTransfer"
      :show="showModalPrintTransfer"
      @confirm="actionPrint"
      @close="closeModalPrint"
    />

    <ModalConflictScale
      id="modal-conflict-scale"
      :show="modalConflitScale"
      :patientInfo="form.listSelectedOriginPatients"
      :title="title"
      :information="information"
      :scheduleInfo="scheduleInfo"
      :showSave="showSave"
      :scaleInfo="form.destiny.selectedIdEmployee"
      @save="actionSaveForm(false)"
      @close="closeModalConflictScale"
    />
  </Modulebox>
</template>

<script>
import {
  RgInputDate,
  RgCleanButton,
  RgSaveButton,
  IconInfoHelper,
  Tooltip,
  RgValidatorForm,
  ModalConfirmFieldClearing,
  ModalConflictScale,
  IconLightBulbHelper,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable.vue";
import { isObject } from "lodash";
import ComboboxPlace from "$appointment/submodules/schedule/component/combobox/transfer-place/RgComboboxTransferPlace";
import ComboboxSector from "$appointment/submodules/schedule/component/combobox/transfer-sector/RgComboboxTransferSector";
import ComboboxOriginEmployee from "$appointment/submodules/schedule/component/combobox/transfer-origin-employee/RgComboboxTransferOriginEmployee.vue";
import ComboboxDestinyEmployee from "$appointment/submodules/schedule/component/combobox/transfer-destiny-employee/RgComboboxTransferDestinyEmployee.vue";

import ModalPrintTransfer from "$appointment/submodules/schedule/component/modal/modal-print-transfer/ModalPrintTransfer";
import PrintTransferHTML from "$appointment/submodules/schedule/html/transfer/Transfer";

import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { mapGetters } from "vuex";

const FORM_BASE = {
  origin: {
    date: "",
    selectedIdSector: null,
    selectedIdEmployee: null,
    employee: null,
    sector: null,
  },
  destiny: {
    date: "",
    selectedIdSector: null,
    selectedIdPlace: null,
    selectedIdEmployee: null,
    employee: null,
    sector: null,
  },
  listSelectedOriginPatients: [],
  listOriginPatients: [],
  listDestinyPatients: [],
  availableVacancies: 0,
  loginUnitHealthId: null,
  loginUnitHealthName: null,
};

const COLUMN_TABLE = [
  { name: "Paciente", key: "pes_nome" },
  { name: "Horário", key: "con_hora" },
  { name: "Situação PEP", key: "situacao_pep" },
];

export default {
  name: "TransferAppointments",
  components: {
    RgCleanButton,
    RgSaveButton,
    RgInputDate,
    SmartTable,
    ComboboxSector,
    ComboboxOriginEmployee,
    ComboboxPlace,
    ComboboxDestinyEmployee,
    ModalPrintTransfer,
    Modulebox,
    FormBase,
    RgValidatorForm,
    ModalConfirmFieldClearing,
    ModalConflictScale,
    IconInfoHelper,
    Tooltip,
    IconLightBulbHelper,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      showModalPrintTransfer: false,
      sessionIdPrint: null,
      modalConfirmFieldClearing: false,
      modalConflitScale: false,
      title: null,
      information: null,
      showSave: false,
      scheduleInfo: [],
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    propsSmartTableOrigin() {
      const body = this.form.listOriginPatients;
      const total = this.form.listOriginPatients.length;
      const columns = COLUMN_TABLE;
      const indexColumn = "id";
      const showPagination = false;
      const multSelect = true;
      const removeBtnColumns = true;
      const hasCheck = true;

      return {
        body,
        total,
        columns,
        indexColumn,
        showPagination,
        multSelect,
        removeBtnColumns,
        hasCheck,
      };
    },

    disableDestiny() {
      return this.form.listSelectedOriginPatients.length <= 0;
    },

    disableBtnTransfer() {
      let disable = false;
      if (this.disableDestiny) {
        disable = true;
      } else {
        if (!this.form.destiny.employee || !this.form.destiny.selectedIdPlace) {
          disable = true;
        }
      }

      return disable;
    },

    vacanciesAvaliable() {
      return (
        this.form.availableVacancies &&
        this.form.availableVacancies > 0 &&
        this.form.listSelectedOriginPatients &&
        this.form.availableVacancies >=
          this.form.listSelectedOriginPatients.length
      );
    },

    vacanciesText() {
      if (this.vacanciesAvaliable) {
        return `Vagas disponíveis: ${this.form.availableVacancies}`;
      } else {
        return `Vagas insuficientes`;
      }
    },

    subModuleId() {
      return 43;
    },

    hasDestinyScale() {
      return this.form.availableVacancies && this.form.availableVacancies > 0;
    },
  },

  watch: {
    "form.origin.date"(pValue) {
      this.form.origin.selectedIdSector = null;
      this.form.origin.selectedIdEmployee = null;
      this.form.origin.employee = null;
    },

    "form.destiny.date"(pValue) {
      this.form.destiny.selectedIdSector = null;
    },

    "form.origin.employee"(pValue) {
      if (pValue) {
        this.changeListOriginPatients();
      }
    },

    "form.origin": {
      deep: true,
      handler(pValue) {
        if (this.isChargeOriginList(pValue)) {
        } else if (this.isCleanOriginList(pValue)) {
          this.form.listOriginPatients = [];
        }
        if (!pValue.selectedIdSector) {
          this.form.origin.employee = null;
          this.form.origin.selectedIdEmployee = null;
        } else if (Array.isArray(pValue)) {
          this.form.origin.employee = pValue[0].item;
        }
      },
    },

    "form.destiny": {
      deep: true,
      handler(pValue) {
        if (this.isCleanDestinyList(pValue)) {
          this.form.listDestinyPatients = [];
        }

        if (!pValue.selectedIdSector) {
          this.form.destiny.employee = null;
          this.form.destiny.selectedIdEmployee = null;
        }
      },
    },

    "form.origin.selectedIdEmployee"(pValue) {
      if (!pValue) {
        this.form.origin.employee = null;
      } else if (Array.isArray(pValue)) {
        this.form.origin.employee = pValue[0].item;
      }
    },

    "form.origin.selectedIdSector"(pValue) {
      if (Array.isArray(pValue)) {
        this.form.origin.sector = pValue[0].item;
      }

      if (pValue === false) {
        this.form.origin.sector = null;
      }
    },

    "form.destiny.selectedIdEmployee"(pValue) {
      if (Array.isArray(pValue)) {
        this.form.destiny.employee = pValue[0].item;
        this.form.availableVacancies = pValue[0].item.eag_quantidade_restantes;
      }

      if (!pValue || pValue === false) {
        this.form.destiny.employee = null;
        this.form.availableVacancies = 0;
      }
    },

    "form.destiny.selectedIdSector"(pValue) {
      if (Array.isArray(pValue)) {
        this.form.destiny.sector = pValue[0].item;
      }

      if (pValue === false) {
        this.form.destiny.sector = null;
      }

      this.form.destiny.selectedIdEmployee = null;
      this.form.destiny.selectedIdPlace = null;
      this.form.destiny.employee = null;
      this.form.availableVacancies = 0;
    },
  },

  mounted() {
    this.actionCleanForm();
  },

  methods: {
    loadingOriginEmployee(isLoading) {
      if (!isLoading) {
        this.$refs.smartTableOrigin.cleanMultSelectedRow();
        this.form.listSelectedOriginPatients = [];
        this.form.listOriginPatients = [];
      }
    },
    onSelectEmployeeOrigin(value) {
      if (value && typeof value === "object") {
        this.form.origin.employee = value[0].item;
      }
    },
    onSelectEmployeeDestiny(value) {
      if (value && typeof value === "object") {
        this.form.destiny.employee = value[0].item;
        this.form.availableVacancies = value[0].item.eag_quantidade_restantes;
      }
    },
    isChargeOriginList(value) {
      return value.date && value.employee && value.selectedIdSector;
    },

    isCleanOriginList(value) {
      return value.date || value.employee || value.selectedIdSector;
    },

    isChargeDestinyList(value) {
      return (
        value.date &&
        value.employee &&
        value.selectedIdSector &&
        value.selectedIdPlace
      );
    },

    isCleanDestinyList(value) {
      return value.date || value.employee || value.selectedIdSector;
    },

    async changeListOriginPatients() {
      try {
        this.$refs.smartTableOrigin.cleanMultSelectedRow();
        this.form.listSelectedOriginPatients = [];
        this.form.listOriginPatients = [];

        this.$loader.start();
        const result = await this.$store.dispatch(
          "Appointment/Schedule/GET_TRANSFER_ORIGIN_PATIENTS",
          {
            intIdEscalaAtendimento: this.form.origin.employee.eag_id,
            strDataConsulta: this.form.origin.date,
            intIdLocalAtendimento: null,
          },
        );

        if (result && result.length > 0) {
          result.forEach((item, index) => {
            if (item.con_id_enderecamento_pep) {
              item.situacao_pep = "ENVIADO";
            } else {
              item.situacao_pep = "NÃO ENVIADO";
            }
          });
          this.form.listOriginPatients = result;
        }
      } catch (e) {
        this.$toaster.error("Erro ao buscar pacientes agendados");
      } finally {
        this.$loader.finish();
      }
    },

    onlistSelectedOriginPatients(list) {
      // PACIENTES JA ENVIADOS PRO PEP NÃO PODEM SER TRANSFERIDOS
      const qtdList = list.length;
      this.form.listSelectedOriginPatients = list.filter(
        (item) => !item.con_id_enderecamento_pep,
      );
      if (qtdList !== this.form.listSelectedOriginPatients.length) {
        this.$toaster.info(
          "Verifique se os agendamentos selecionados possuem situação “Enviado para o PEP”.",
          "Transferência negada para Pacientes do PEP",
        );
      }
      // this.form.listSelectedOriginPatients = list;
    },
    closeModalConflictScale() {
      this.modalConflitScale = false;
    },

    actionCleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);

      this.form.loginUnitHealthId = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ID"
      ];

      this.form.loginUnitHealthName = this.$store.getters[
        "Login/GET_UNIT_HEALTH_NAME"
      ];

      this.$refs.smartTableOrigin.cleanMultSelectedRow();

      this.$refs.dateOrigin.cleanValidate();
      this.$refs.comboboxSectorOrigin.cleanValidate();
      this.$refs.comboboxOriginEmployee.cleanValidate();
      this.$refs.dateDestiny.cleanValidate();
      this.$refs.comboboxSectorDestiny.cleanValidate();
      this.$refs.comboboxPlaceDestiny.cleanValidate();
      this.$refs.comboboxDestinyEmployee.cleanValidate();
      this.modalConfirmFieldClearing = false;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;
    },

    async isFormValidOrigin() {
      return this.$refs.validatorOrigin
        ? this.$refs.validatorOrigin.validate()
        : false;
    },

    async isFormValidDestiny() {
      return this.$refs.validatorDestiny
        ? this.$refs.validatorDestiny.validate()
        : false;
    },

    async actionSaveForm(withValidation = true) {
      try {
        if (!withValidation) {
          this.closeModalConflictScale();
        }
        const validadeFormO = await this.isFormValidOrigin();
        const validadeFormD = await this.isFormValidDestiny();

        if (!validadeFormO || !validadeFormD) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.saveButton.actionDone();
          return;
        }

        if (this.form.listSelectedOriginPatients.length <= 0) {
          this.$toaster.warning(
            "É necessário selecionar consultas para realizar a transferência",
          );
          this.$refs.saveButton.actionDone();
          return;
        }

        if (this.disableBtnTransfer) {
          this.$toaster.error("Por favor, preencha todos os campos");
          this.$refs.saveButton.actionDone();
          return;
        }

        if (this.vacanciesNotAvaliable) {
          this.$toaster.error("Não há vagas disponíveis");
          this.$refs.saveButton.actionDone();
          return;
        }
        const consultas = [];

        this.form.listSelectedOriginPatients.map((el) => {
          consultas.push(el.con_id);
        });

        let destinyEmp = this.form.destiny.employee;

        if (destinyEmp.item) {
          destinyEmp = destinyEmp.item;
        }

        const histAgendamento = {
          hag_id_dias_semana: destinyEmp.eag_id_dias_semana,
          hag_id_escalas_agendamentos: destinyEmp.eag_id,
          hag_data_consulta: this.form.destiny.employee.data_consulta,
          hag_intervalo_consultas: destinyEmp.eag_intervalo_consultas,
          hag_quantidade_consultas: destinyEmp.eag_quantidade_consultas,
          hag_hora_inicio_consulta_funcionario:
            destinyEmp.eag_inicio_atendimentos,
        };

        if (this.isEqualOriginDestiny()) {
          this.$toaster.error(
            "A transferência não pode ser feita para a mesma escala no mesmo dia",
          );
          this.$refs.saveButton.actionDone();

          return;
        }
        this.$loader.start();

        const obj = {
          arrFormData: {
            consultas,
            histAgendamento,
          },

          intIdHistoricosAgendamentoDestino: destinyEmp.hag_id
            ? destinyEmp.hag_id
            : "0",

          intIdLocalAtendimento: this.form.destiny.selectedIdPlace,

          intIdEscalaAgendamentoOrigem: this.form.origin.employee.eag_id,

          blnPermissaoTransferenciaConsultaNormal: true,
          blnPermissaoTransferenciaConsultaReservaTecnica: true,
          blnValidarAgendamento: true,
        };

        this.sessionIdPrint = await this.$store.dispatch(
          "Appointment/Schedule/TRANSFER",
          obj,
        );

        this.$refs.saveButton.actionDone();

        if (this.sessionIdPrint.status === false) {
          this.$toaster.error(this.sessionIdPrint.msg);
          this.$loader.finish();
          return;
        }

        if (
          (this.sessionIdPrint.status &&
            this.sessionIdPrint.status.endsWith("Exception")) ||
          (this.sessionIdPrint.dados &&
            this.sessionIdPrint.dados.endsWith("Exception"))
        ) {
          const timePreference = this.$store.getters["Login/GET_PREFERENCES"][
            "tViewMarcacaoConsulta.con_tempo_entre_consultas"
          ];
          if (
            this.sessionIdPrint.status ===
            "TransferenciaConsultadoHojeException"
          ) {
            // $con_id_pacientes_consultas, $hag_data_consulta, $hag_id_escalas_agendamentos
            this.form.listSelectedOriginPatients.map((el) => {
              this.scheduleInfo.push({
                hag_id_escalas_agendamentos: destinyEmp.eag_id,
                hag_data_consulta: this.form.destiny.employee.data_consulta,
                con_id_pacientes_consultas: el.pcc_id,
              });
            });
            this.scheduleInfo = this.scheduleInfo.filter(
              (j, i) => this.scheduleInfo.indexOf(j) === i,
            );
            this.title =
              "O paciente já possui Consulta agendada no dia selecionado.";
            this.information =
              "Verifique na lista abaixo os respectivos horários marcados para decidir se deseja prosseguir com a marcação mesmo assim.";
            this.showSave = true;
            this.modalConflitScale = true;
          } else if (
            this.sessionIdPrint.status ===
            "TransferenciaConsultadoHojeHorarioException"
          ) {
            // $con_id_pacientes_consultas, $hag_data_consulta, $hag_id_escalas_agendamentos
            this.form.listSelectedOriginPatients.map((el) => {
              this.scheduleInfo.push({
                hag_id_escalas_agendamentos: destinyEmp.eag_id,
                hag_data_consulta: this.form.destiny.employee.data_consulta,
                con_id_pacientes_consultas: el.pcc_id,
              });
            });
            this.scheduleInfo = this.scheduleInfo.filter(
              (j, i) => this.scheduleInfo.indexOf(j) === i,
            );
            this.title =
              "O paciente já possui Consulta agendada no dia selecionado.";
            this.information =
              "O intervalo entre as consultas é menor ou igual a " +
              timePreference +
              " minutos.";
            this.showSave = false;
            this.modalConflitScale = true;
          } else if (
            this.sessionIdPrint.status ===
              "TransferenciaConsultaExistenteException" ||
            this.sessionIdPrint.status ===
              "PacienteConsultadoHojeAtendenteException" ||
            this.sessionIdPrint.dados ===
              "TransferenciaConsultaExistenteException"
          ) {
            // $con_id_pacientes_consultas, $hag_data_consulta, $hag_id_escalas_agendamentos
            this.form.listSelectedOriginPatients.map((el) => {
              this.scheduleInfo.push({
                hag_id_escalas_agendamentos: destinyEmp.eag_id,
                hag_data_consulta: this.form.destiny.employee.data_consulta,
                con_id_pacientes_consultas: el.pcc_id,
              });
            });
            this.scheduleInfo = this.scheduleInfo.filter(
              (j, i) => this.scheduleInfo.indexOf(j) === i,
            );
            this.title =
              "O paciente já possui Consulta agendada no dia selecionado com esse Profissional.";
            this.information = "";
            this.showSave = false;
            this.modalConflitScale = true;
          }
        } else {
          await this.changeListOriginPatients();
          this.$toaster.success("Transferência realizada com sucesso");
          this.showModalPrintTransfer = true;
          this.form.listSelectedOriginPatients = [];
          this.$refs.smartTableOrigin.cleanMultSelectedRow();
        }
      } catch (e) {
        this.$toaster.error(e.message);
        this.$refs.saveButton.fail();
      } finally {
        this.$loader.finish();
      }
    },

    async actionPrint() {
      try {
        const arr = {
          pes_nome_destino: this.form.destiny.employee.pes_nome,
          pes_nome_origem: this.form.origin.employee.pes_nome,
          set_nome_destino: this.form.destiny.sector.set_nome,
          set_nome_origem: this.form.origin.sector.set_nome,
          uns_nome: this.form.loginUnitHealthName,
        };

        const obj = {
          strSessao: isObject(this.sessionIdPrint)
            ? this.sessionIdPrint.status
            : this.sessionIdPrint,
          arrFiltros: arr,
        };

        const snapPrint = await this.$store.dispatch(
          "Appointment/Schedule/PRINT_TRANSFER",
          obj,
        );

        const html = PrintTransferHTML(snapPrint);
        this.$utils.print.printHtml(html);
      } catch (e) {
      } finally {
        this.closeModalPrint();
      }
    },

    closeModalPrint() {
      this.actionCleanForm();
      this.showModalPrintTransfer = false;
    },

    isEqualOriginDestiny() {
      const isSameDate = this.form.destiny.date === this.form.origin.date;

      const isSameSector =
        this.form.destiny.selectedIdSector ===
        this.form.origin.selectedIdSector;

      const isSameEmployee =
        this.form.destiny.selectedIdEmployee ===
        this.form.origin.selectedIdEmployee;

      return isSameDate && isSameSector && isSameEmployee;
    },
  },
};
</script>
