import { render, staticRenderFns } from "./RgSuggestPatientInformation.vue?vue&type=template&id=41a3bf62&scoped=true&"
import script from "./RgSuggestPatientInformation.vue?vue&type=script&lang=js&"
export * from "./RgSuggestPatientInformation.vue?vue&type=script&lang=js&"
import style0 from "./RgSuggestPatientInformation.scss?vue&type=style&index=0&id=41a3bf62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41a3bf62",
  null
  
)

export default component.exports