const Request = (pRequest, pItensRequest, pInfo) => {
  const dataEmissao = new Date().toLocaleString();
  const cns = pItensRequest[0].crs_numero || "-";
  let produtos = "";

  pItensRequest.forEach((item) => {
    const code = item.code || item.mpd_codigo || "-";
    const product = item.name || item.mpd_novo_principio_ativo || "-";
    const presentation = item.presentation || item.mtu_novo_nome || "-";
    const quantity = item.amountDismissed || item.rec_quantidade || "-";
    produtos += "<tr><td>" + code + "</td>";
    produtos += "<td>" + product + "</td>";
    produtos += "<td>" + presentation + "</td>";
    produtos += "<td>" + quantity + "</td></tr>";
  });

  const html = `
          <html lang="pt-br">
            <head>
                <meta charset="utf-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1">
                <title> Comprovante de Recebimento.html</title>

                <style type="text/css">
                html, body, div, span, p, table, tr, td {
                  margin: 0;
                  padding: 0;
                  border: 0;
                  font-size: 100%;
                  font: inherit;
                  vertical-align: baseline;
                }

                body {
                  width: 7cm;
                  margin: .1cm;
                  padding: 0 0 1cm 0;
                  font-size: 8pt;
                  font-family: Verdana, sans-serif;
                }

                div, p {

                  margin: .2cm auto;
                    word-wrap: break-word;
                    line-height: 120%;
                }

                .titulo-comprovante{
                  margin: 2rem 0 4rem 0;
                }

                .center {
                  text-align: center;
                }

                .recebi {
                  padding: .1cm 0 0 0;
                }

                .info-unidade, .info-emissao{
                  margin-left: 2rem;
                }

                .label {
                  width: 2.2cm;
                  display: inline-block;
                }

                .second-label{
                  margin-left: 8rem;
                }

                .label-assinatura {
                  width: 1.8cm;
                  display: inline-block;
                }

                hr {
                  margin: .7cm 0;
                }

                page {
                    background: white;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 0.5cm;
                }

                page[size="A4"] {
                    width: 21cm;
                }

                table {
                  width: 100%;
                  font-size: 11px;
                  font-weight: none;
                  top: 10%;
                  border-collapse: collapse;
                  margin: 15px 0;
                }

                tr {
                  border: 1px solid grey;
                }

                th {
                  border: 1px solid grey;
                }

                td {
                  border: 1px solid grey;
                }

              </style>

              </head>
              <body>
              <page size="A4">
              <div class="center">
              <p class="center">${pInfo.mun_nome || "-"}</p>
              <p class="center">${pInfo.clientName || "-"}</p>
              <p class="center">${pInfo.uns_nome || "-"}</p>
              </div>

              <div class="titulo-comprovante center">
              <p class="center">COMPROVANTE DE RECEBIMENTO DE PRODUTOS/MEDICAMENTOS</p>
              </div>

              <div class="info-unidade">
              <p><span class="label">Farmácia:</span>${
                pRequest.far_nome || "-"
              }</p>
              <p><span class="label">Paciente:</span>${
                pRequest.pes_nome || "-"
              }</p>
              <p><span class="label">CNS:</span>${cns}</p>
              </div>

              <div class="info-emissao">
              <p><span class="label">Emissor:</span>${pInfo.userName || "-"}
              <span class="label second-label">Recebido em:</span>${
                pRequest.rec_data || "-"
              }</p>
              </div>
              <div class="info-emissao">
              <p><span class="label">Dispensador:</span>${
                pRequest.usu_nome || "-"
              }<span class="label second-label">Impresso em:</span>${dataEmissao}</p>
              </div>


              <table class="table">
              <tr class="header">
                <th> Código </th>
                <th> Produto/Medicamento </th>
                <th> Apresentação </th>
                <th> Quantidade </th>
              </tr>

              <div class="table">
                ${produtos}
              <div>
            </table>

              <div class="info-recebimento center">

              <p>
              <span class="label-assinatura">Nome: </span>
              <span class="espaco-assinatura">__________________________________________________</span>
              </p>
              <p>
              <span class="label-assinatura">Identidade: </span>
              <span class="espaco-assinatura">__________________________________________________</span>
              </p>
              <p>
              <span class="label-assinatura">CPF: </span>
              <span class="espaco-assinatura">__________________________________________________</span>
              </p>
              <p>
              <span class="label-assinatura">Fone(s): </span>
              <span class="espaco-assinatura">__________________________________________________</span>
              </p>

              <p class="recebi center">Declaro ter recebido os produtos acima</p>
              <p class="center">_________________________</p>
              <p class="center">Assinatura</p>
              </div>
      </page>

      </body>
    </html>
  `;

  return html;
};

export default Request;
