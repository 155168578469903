export default {
  SET_APPOINTMENT_SELECT_LIST: (state, data) => {
    state.selected_appointment = data;
  },
  RESET_APPOINTMENT_SELECT_LIST: (state) => {
    state.selected_appointment = null;
  },

  SET_FILTER_APPOINTMENT_LIST: (state, data) => {
    state.filter_appointment_list = data;
  },

  RESET_FILTER_APPOINTMENT_LIST: (state) => {
    state.filter_appointment_list = null;
  },
};
