<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchScalesWithProcedure from "./action/SearchScalesWithProcedure";

export default {
  name: "RgSelectScalesWithProcedure",
  extends: RgSelect,
  props: {
    sectorId: {
      type: Number,
    },
    bondId: {
      type: Number,
    },
    procedureName: {
      type: String,
    },
    date: {
      type: String,
    },
    label: {
      type: String,
      default: "Escala",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    date() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (this.date) {
        const data = await SearchScalesWithProcedure({
          intIdSetor: this.sectorId,
          intVusId: this.bondId,
          strData: this.date,
          strNomeProcedimento: this.procedureName,
        });

        this.valuesData = data.map((item) => {
          return {
            value: item.data,
            label: item.label,
            eha_id: item.eha_id,
            eea_inicio_atendimentos: item.eea_inicio_atendimentos,
            eea_id_exames_tipo_escalas: item.eea_id_exames_tipo_escalas,
          };
        });
      }
    },
  },
};
</script>
