<template>
  <div class="person-unify-report">
    <div class="grid">
      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período de Unificação</span>
      </div>
      <div class="selectinput _flex">
        <RgInputDate
          id="initialDate"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="finalDate"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          label="Data Final"
          class="inputitem mg"
        />
      </div>
      <div class="selectinput">
        <RgInput
          id="unifyPatient"
          ref="unifyPatient"
          v-model="form.psl_nome"
          :disabled="hasBasePatient"
          :class="{ disable: hasBasePatient }"
          label="Paciente Unificado"
          placeholder="Digite o nome do paciente unificado"
          :maxlength="100"
        />
      </div>
      <div class="selectinput">
        <RgInput
          id="basePatient"
          ref="basePatient"
          v-model="form.pes_nome"
          :disabled="hasUnifyPatient"
          :class="{ disable: hasUnifyPatient }"
          label="Paciente Base"
          placeholder="Digite o nome do paciente base"
          :maxlength="100"
        />
      </div>
      <div class="selectinput">
        <RgSuggestUser
          id="usu-nome-aess"
          v-model="form.usu_nome"
          label="Usuário"
          placeholder="Digite o nome do usuário responsável"
          openOnlyTop
          @selected="selectingUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgInput, RgSuggestUser } from "~tokio/primitive";
import moment from "moment";
const FORM_FILTER = {
  initialDate: moment().add(-90, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  pes_nome: null,
  psl_nome: null,
  usu_nome: null,
};
export default {
  name: "PersonUnifyReport",
  components: {
    RgInputDate,
    RgInput,
    RgSuggestUser,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Pacientes Unificados",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },
  computed: {
    hasBasePatient() {
      return this.form.pes_nome?.length > 0;
    },
    hasUnifyPatient() {
      return this.form.psl_nome?.length > 0;
    },
    printReportCss() {
      return (
        " @page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }" +
        ".report-column-2 { white-space: normal !important; text-align: left; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; }" +
        ".report-column-5 { white-space: normal !important; text-align: left; }"
      );
    },

    getNameSmartTable() {
      return "PersonUnifyReport";
    },

    columnTable() {
      return this.COLUMN;
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
    },

    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },

  watch: {
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }
      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Paciente Unificado", key: "PACIENTE", align: "left" },
      { name: "Data de Nascimento", key: "NASCIMENTO", align: "left" },
      { name: "Nome da Mãe", key: "MAE", align: "left", active: "true" },
      { name: "Paciente Base", key: "PACIENTE_BASE", align: "left" },
      { name: "Unificação", key: "UNIFICACAO", align: "left" },
    ];
  },
  methods: {
    selectingUser(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.usu_nome = pValue.source.usu_nome;
      } else {
        this.form.usu_nome = "";
      }
    },
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    buildFilter() {
      const payLoad = {
        initialDate: this.form.initialDate
          ? this.formatDate(this.form.initialDate)
          : null,
        finalDate: this.form.finalDate
          ? this.formatDate(this.form.finalDate)
          : null,
        pes_nome: this.form.pes_nome,
        psl_nome: this.form.psl_nome,
        usu_nome: this.form.usu_nome,
        columnsToPrint: this.columnsToPrint,
      };

      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Person/Report/GET_PERSON_UNIFY_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    formatDate(pDate) {
      return this.$utils.date.BrazilianDateToDatabase(pDate);
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
