<template lang="html">
  <fieldset v-if="hasListValue" class="rg-input-custom-checkbox">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>

      <div class="container-items">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item"
          :class="{ active: checkActive(item.value) }"
          :style="style(item.color)"
          @click="toggleStatus(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";

export default {
  name: "RgInputCustomCheckbox",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin],
  props: {
    label: {
      default: "Selecione",
      type: String,
    },

    value: {
      default: null,
    },

    list: {
      default: [],
    },

    maxSelected: {
      default: 1,
      type: Number,
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    hasListValue() {
      return this.list?.length > 0;
    },

    isRequired() {
      return this.rules && this.rules.required;
    },

    validValue() {
      return this.selectedItems;
    },
  },
  watch: {
    value(pValue) {
      this.selectedItems = pValue;
    },
  },

  created() {
    this.DEFAULT_COLOR = "#2B5D8A";
  },

  methods: {
    toggleStatus(pItem) {
      const alredyInList = this.selectedItems.includes(pItem);

      if (alredyInList) {
        this.selectedItems.splice(this.selectedItems.indexOf(pItem), 1);
      } else {
        const itemsLength = this.selectedItems?.length + 1;
        const isValid = this.maxSelected >= itemsLength;

        if (isValid) {
          this.selectedItems.push(pItem);
        }
      }

      this.$emit("input", this.selectedItems);
    },

    checkActive(pItem) {
      return this.selectedItems.includes(pItem);
    },

    style(pColor) {
      const color = pColor || this.DEFAULT_COLOR;

      return {
        "--item-color": color,
      };
    },
  },
};
</script>
