<template>
  <section class="quick-exam-schedule-form">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="module-exam">
          <div class="content-patient">
            <RgSuggestPatientInformation
              id="patient"
              ref="patientInfo"
              v-model="suggestPatient"
              :rules="{ forceSelection: true, required: true }"
              :disabled="disablePatient"
              :with-patient-module="['exam2']"
              :pac_prontuario_unico="
                patientInfo && patientInfo.prontuario_unico
              "
              :withPatientExam="true"
              @patientInfo="selectingPatient"
              @edit="editPatient"
              @blockedPatient="blockedPatient($event)"
            />
          </div>
        </FormBase>

        <FormBase title="Profissional Solicitante" class="module-exam">
          <div class="content-employee">
            <RgInput
              id="unit-heath"
              disabled
              :value="employeeData.uns_nome"
              label="Unidade de Saúde"
              class="disabled-field"
            />
            <RgInput
              id="sector"
              disabled
              :value="employeeData.set_nome"
              label="Setor"
              class="disabled-field"
            />
            <RgInput
              id="employee"
              disabled
              :value="employeeData.fun_nome"
              label="Nome"
              class="name disabled-field"
            />

            <RgInput
              id="occupation"
              disabled
              :value="employeeData.ocp_nome"
              label="Ocupação"
              class="line-2 disabled-field"
            />

            <RgInput
              id="scale"
              disabled
              :value="scaleValue"
              label="Escala"
              class="line-2 disabled-field"
            />

            <RgInput
              id="vacancies"
              disabled
              :value="employeeData.eea_quantidade_restantes"
              label="Vagas"
              class="line-2 vacancies disabled-field"
            />
          </div>
        </FormBase>

        <FormBase title="Procedimento" class="module-exam">
          <div class="content-procedure">
            <div class="procedure-area">
              <RgSelectPlacesExam
                id="place"
                ref="place"
                v-model="form.id_locais_atendimento"
                label="Local de Atendimento"
                :disabled="!isPatient"
                :sectorId="employeeData.set_id"
                :rules="{ required: true }"
                class="place"
                @selectOnlyItem="selectOnlyItemPlace"
              />

              <RgSelectHealthPlanExam
                id="health-plans"
                ref="healthPlans"
                v-model="form.id_planos_saude"
                :variable="variableComboboxHealthPlans"
                label="Convênio"
                :disabled="!isPatient"
                :rules="{ required: true }"
                class="healtplans"
                @selectOnlyItem="selectOnlyItemHealthPlans"
              />
              <RgSelectScaleExam
                id="scale-exam"
                ref="scale"
                v-model="form.scale"
                :rules="{ required: true }"
                :dateExam="employeeData.data_exame"
                :sectorId="Number(employeeData.set_id)"
                :attendance="false"
                :attendanceScheduledExams="false"
                :sectorLink="Number(employeeData.eea_id_vinculos_setores)"
                :disabled="!isPatient || isEditing"
                :class="{ disable: isEditing || !isPatient }"
                label="Escala"
                class="scale"
                @selectOnlyItem="selectOnlyItemScale"
                @change="selectScale"
              />

              <RgSelectVacanciesExam
                id="vacancies-exam"
                ref="vacancies"
                v-model="form.vacancies"
                :rules="{ required: true }"
                :schedule-id="form.eea_id"
                :exam-date="employeeData.data_exame"
                :disabled="!isPatient"
                :edition="isEditing"
                :occupied-vacancies="occupiedVacancies"
                label="Vagas"
                class="vacancies"
                @selectOnlyItem="selectOnlyItemVacancies"
              />

              <RgComboboxHour
                v-if="showHour"
                id="hour-exam"
                ref="hour"
                v-model="form.hour"
                label="Horário"
                :disabled="disableHour || !isPatient"
                :class="{ disable: disableHour }"
                :rules="{ required: showHour }"
                :scaleSchedule="Number(form.scale)"
                :historySchedule="Number(form.eha_id)"
                :dateExam="employeeData.data_exame"
                :occupiedPlaces="Number(form.vacancies)"
                :eapId="Number(examData.eap_id)"
                :hasVacancies="true"
                emptyDefaultText="Nenhum item"
                default-text="Selecione"
                class="line-2"
                @selectOnlyItem="selectOnlyItemHour"
              />

              <RgSelectProceduresByHealthplan
                id="procedure"
                ref="comboProcedures"
                v-model="procedureId"
                label="Procedimento"
                :rules="{ required: true }"
                :variable="variableComboProcedures"
                :disabled="!isPatient || !form.id_planos_saude"
                class="line-2 procedure"
                @change="clickSelectedProcedure"
                @valuesData="loadListProcedures"
              />
            </div>
          </div>
        </FormBase>

        <FormBase title="Subprocedimento" class="module-exam">
          <div v-if="!procedureId" class="empty">
            <IconEmpty />
            <span class="title-selected-procedures">
              Não há procedimento selecionado
            </span>
          </div>

          <div v-else class="content-subprocedure">
            <div class="subprocedures">
              <div class="body-list">
                <SmartTable
                  ref="smartTable"
                  name="QuickExamScheduleForm"
                  :columns="COLUMN"
                  :body="mutableSubprocedureList || []"
                  :total="mutableSubprocedureList.length + 1"
                  :initial-columns="1"
                  :show-pagination="false"
                  removeBtnColumns
                  class="smart-table"
                  @getLine="selectLine"
                >
                  <div slot="top-buttons" class="top-buttons">
                    <RgInput
                      id="subprocedure"
                      v-model="subprocedure"
                      label=""
                      class="input"
                      placeholder="Busca por subprocedimento"
                    />
                    <RgSearchButton
                      id="subprocedure-btn"
                      ref="subprocedures"
                      v-shortkey="['enter']"
                      class="search"
                      @submit="searchSubprocedure"
                      @shortkey.native="searchSubprocedure"
                    />
                  </div>
                </SmartTable>
              </div>

              <div
                v-show="mutableSubprocedureList.length < 1"
                class="empty-procedure"
              >
                <IconEmpty class="empty-icon" />
                <span>Nenhum subprocedimento encontrado</span>
              </div>
            </div>

            <div class="content-selected">
              <div class="subprocedures-selected">
                <span class="title-grid">Subprocedimentos Selecionados</span>
              </div>

              <div v-if="hasListSelected" class="body-list-selected">
                <div class="content-body">
                  <div
                    v-for="(item, index) in selectedSubprocedures"
                    :key="index"
                    class="list"
                  >
                    <span class="data">{{ item.nomeSubProcedimento }}</span>
                    <button
                      id="remove-btn"
                      class="remove"
                      @click.stop.prevent="removeSubprocedureList(index)"
                    >
                      x
                    </button>
                  </div>
                </div>
              </div>

              <div v-else class="empty-procedure">
                <IconEmpty class="empty-icon" />
                <span>Nenhum subprocedimento selecionado</span>
              </div>
            </div>
          </div>
        </FormBase>
      </RgValidatorForm>
    </form>

    <ModalConfirmSchedule
      id="modal-confirm-schedule"
      ref="modalConfirmSchedule"
      :patientScheduleId="Number(patientInfo.pxe_id)"
      :scheduleDate="employeeData.data_exame || employeeData.DataExame"
      :show="showModalHistorySchedule"
      @save="saveFormByModalConfirmSchedule"
      @cancel="closeModalHistorySchedule"
      @close="closeModalHistorySchedule"
    />

    <ModalLinkSolicitation
      id="modal-link-solicitation"
      :show="showModalLinkSolicitation"
      :requested-data="requested"
      @not-linked="saveWithSolicitation"
      @linked="saveWithSolicitation"
      @close="closeModalLinkSolicitation"
    />
  </section>
</template>

<script>
import moment from "moment";
import { SmartTable, FormBase } from "~tokio/foundation";

import RgSelectPlacesExam from "$exam/common/component/rg-select-places-exam/RgSelectPlacesExam";
import RgSelectProceduresByHealthplan from "$exam/submodules/schedule/component/select/rg-select-procedures-by-healthplan/RgSelectProceduresByHealthplan";
import RgSelectVacanciesExam from "$exam/submodules/schedule/component/select/rg-select-vacancies-exam/RgSelectVacanciesExam";
import RgComboboxHour from "$exam/common/component/rg-combobox-hour/RgComboboxHour";
import RgSelectHealthPlanExam from "$exam/common/component/rg-select-healthplan-exam/RgSelectHealthPlanExam";
import RgSelectScaleExam from "$exam/common/component/rg-select-scale-exam/RgSelectScaleExam";
import ModalConfirmSchedule from "$exam/submodules/schedule/component/modal/modal-confirm-schedule/ModalConfirmSchedule";
import ModalLinkSolicitation from "$exam/submodules/schedule/component/modal/modal-link-solicitation/ModalLinkSolicitation";

import {
  RgValidatorForm,
  RgSuggestPatientInformation,
  IconEmpty,
  RgInput,
  RgSearchButton,
} from "~tokio/primitive";
import { mapGetters } from "vuex";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
export default {
  name: "QuickExamScheduleForm",

  components: {
    FormBase,
    RgValidatorForm,
    RgSuggestPatientInformation,
    RgSelectPlacesExam,
    RgSelectHealthPlanExam,
    RgSelectVacanciesExam,
    RgSelectScaleExam,
    IconEmpty,
    RgSelectProceduresByHealthplan,
    RgComboboxHour,
    RgInput,
    ModalConfirmSchedule,
    SmartTable,
    RgSearchButton,
    ModalLinkSolicitation,
  },

  props: {
    examData: Object,
    isPerDay: Boolean,
  },

  data() {
    return {
      form: {
        id_planos_saude: null,
        id_locais_atendimento: "",
        procedureCode: "",
        procedureNome: "",
        hour: "",
        sinoProcedureNome: "",
        vacancies: null,
        scale: null,
        eea_id: null,
        eha_id: null,
      },
      procedureId: "",
      hours: null,
      isPatient: null,
      hoursSelected: [],
      procedureCode: [],
      procedureCost: null,
      patientInfo: {},
      scaleData: {},
      suggestPatient: "",
      requested: null,
      selectedProcedure: null,
      confirmSolicitationLinkVariables: null,
      occupiedVacancies: null,
      showModalHistorySchedule: false,
      showModalLinkSolicitation: false,
      saveFromModal: false,
      subprocedure: null,
      selectedSubprocedures: [],
      mutableSubprocedureList: [],
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
      getFillForm: "Exam/Schedule/GET_FORM_SCHEDULE",
    }),

    scaleValue() {
      const hasInitialValue = this.employeeData.inicio?.length > 0;

      const initialScale = hasInitialValue
        ? `${this.employeeData.inicio} -`
        : "";

      const finalScale = this.employeeData.final || "";

      return `${initialScale} ${finalScale}`;
    },

    variableComboboxHealthPlans() {
      const obj = {
        intIdVinculoSetor: this.employeeData.vus_id,
      };
      return obj;
    },

    disablePatient() {
      return this.patientInfo && this.patientInfo.pac_id > 0 && this.isEditing;
    },

    employeeData() {
      return this.$store.getters["Exam/Schedule/GET_SELECTED_EMPLOYEE"];
    },

    variableComboProcedures() {
      return {
        intIdVinculoSetor: this.employeeData.vus_id,
        intIdPlanoSaude: this.form.id_planos_saude,
        epp_id: this.examData?.eap_id_exames_procedimentos_profissionais || 0,
        dataRef: !this.isEditing ? this.employeeData.data_exame : null,
      };
    },

    isEditing() {
      return this.examData != null && parseInt(this.examData.exa_id) > 0;
    },

    disableHour() {
      return !this.form.scale || !this.form.vacancies;
    },

    showHour() {
      return this.employeeData.eea_id_exames_tipo_escalas !== "1";
    },

    preferenceSchedulingSameDay() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_permitirAgendamentosMesmoDia"
      ];
    },

    schedulingSameDay() {
      return true;
    },

    hasList() {
      return this.mutableSubprocedureList?.length > 0;
    },

    hasListSelected() {
      return this.selectedSubprocedures?.length > 0;
    },
  },

  watch: {
    suggestPatient(pValue) {
      if (!pValue) {
        this.patientInfo = {};
      }
    },
    procedureId(pValue, pPrev) {
      if (pValue && pPrev && pValue !== pPrev) {
        this.selectedSubprocedures = [];
      }
    },
    subprocedure(pValue, pPrev) {
      if (!pValue || (pValue.length > 0 && pValue !== pPrev)) {
        this.subprocedureList();
      }
    },
  },

  created() {
    this.NO_REQUEST = 0;
    this.NOT_LINK_REQUEST = 1;
    this.LINK_REQUEST = 2;

    this.COLUMN = [
      {
        name: "Subprocedimentos",
        key: "nomeSubProcedimento",
        align: "left",
      },
    ];

    // Necessário para update do ESUS
    this.SKIP_SELECTED_PROCEDURE = true;
    this.SHOW_WARNINGS = true;
  },

  async mounted() {
    if (!(this.employeeData && this.examData)) {
      this.$parent.$parent.cancel();
      this.$toaster.info(
        "Será necessário realizar uma nova pesquisa.",
        "Você foi redirecionado para a busca",
      );
    }

    if (this.examData && this.examData.eap_id > 0) {
      this.scheduleLoadInfo = await this.$store.dispatch(
        "Exam/Schedule/SCHEDULE_LOAD",
        {
          intIdExameAgendamentoProcedimento: this.examData.eap_id,
        },
      );

      this.occupiedVacancies = this.scheduleLoadInfo.eap_vagas_ocupadas || null;
    }
    if (this.lastPersonIdSaved) {
      this.$refs.patientInfo.fillPatientById(this.lastPersonIdSaved);
    } else {
      if (this.isEditing) {
        this.loadDataForEditing();
        this.suggestPatient = this.examData.pes_nome;
      }
    }

    this.fillForm();

    this.hours = {
      begin: this.employeeData.inicio,
      end: this.employeeData.final,
    };
  },

  beforeDestroy() {
    const routePerDay = this.isEditing
      ? "/exam/schedule/per-day/edit/form"
      : "/exam/schedule/per-day/form";

    const routePerPeriod = this.isEditing
      ? "/exam/schedule/per-period/edit/form"
      : "/exam/schedule/per-period/form";

    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      routePerDay,
      this.$route.meta.breadcrumb,
    );

    const existThisRouteFromSchedulePerPeriod = ValidateIfRouteExistInBreadscrumb(
      routePerPeriod,
      this.$route.meta.breadcrumb,
    );

    const isNotRoutePerDayOrPerPeriod =
      !existThisRouteFromSchedulePerDay && !existThisRouteFromSchedulePerPeriod;

    if (isNotRoutePerDayOrPerPeriod) {
      this.removeSelectedData();
    }
  },

  methods: {
    saveWithSolicitation(solicitation, link) {
      if (link === this.NOT_LINK_REQUEST) {
        this.$parent.$parent.saveForm(
          this.saveFromModal,
          this.NOT_LINK_REQUEST,
        );
      } else if (link === this.LINK_REQUEST) {
        this.$parent.$parent.saveForm(
          this.saveFromModal,
          this.LINK_REQUEST,
          solicitation,
        );
      }
      this.saveFromModal = false;
    },

    async fillForm() {
      if (this.getFillForm) {
        this.form = this.getFillForm.form;
        this.procedureId = this.getFillForm.procedureId;
        this.mutableSubprocedureList = this.getFillForm.mutableSubprocedureList;
        this.selectedSubprocedures = this.getFillForm.selectedSubprocedures;
        if (this.getFillForm.selectedProcedure) {
          this.selectedProcedure = this.getFillForm.selectedProcedure;
        }
      }
      this.form.scale = this.employeeData.eea_id;
      this.$store.commit("Exam/Schedule/CLEAN_FORM_SCHEDULE");
    },

    async loadDataForEditing() {
      try {
        this.$loader.start();
        this.parseDataEdit();
      } catch (pErr) {
        this.$toaster.error("Erro ao carregar as informações do exame");
      } finally {
        this.$loader.finish();
      }
    },

    async parseDataEdit() {
      this.personData = await this.$store.dispatch(
        "Appointment/Schedule/GET_PATIENT_BASIC_INFO",
        {
          pesId: this.examData.pes_id,
        },
      );

      this.$refs.patientInfo.fillPatientById(this.personData.pes_id);
      this.fillPatientInfo(this.personData);

      this.patient = {
        pcc_id: this.examData.pcc_id,
        pac_id: this.examData.pac_id,
      };

      this.form = {
        eea_id: this.examData.eea_id,
        eap_id: this.examData.eap_id,
        eha_id: this.examData.eap_id_exames_historicos_agendamentos,
        id_locais_atendimento: this.examData.lca_id,
        id_planos_saude: this.examData.pls_id,
        scale: this.examData.eea_id,
        vacancies: this.examData.eap_vagas_ocupadas,
        hour: this.examData.eap_hora,
        procedureCode: this.examData.eap_id_sinonimos_procedimentos,
        procedureNome: this.examData.stp_novo_nome_procedimento,
      };

      const hasProcedureId =
        parseInt(this.examData.stp_id) && parseInt(this.examData.stp_id) > 0;
      const hasSynonymProcedureId =
        parseInt(this.examData.eap_id_sinonimos_procedimentos) &&
        parseInt(this.examData.eap_id_sinonimos_procedimentos) > 0;

      if (hasProcedureId && hasSynonymProcedureId) {
        this.procedureId = this.examData.eap_id_sinonimos_procedimentos;
      } else {
        this.procedureId = this.examData.stp_id;
      }

      this.subprocedureList();
      this.selectedSubprocedures = this.examData.subProcedimentos;
      this.getSubprocedure(this.selectedSubprocedures);
    },

    async subprocedureList() {
      const data = await this.$store.dispatch(
        "Exam/Schedule/GET_SUBPROCEDURE",
        {
          uns_id: parseInt(this.employeeData.uns_id),
          nomeProcedimento: this.form.procedureNome,
        },
      );
      Array.isArray(data)
        ? (this.mutableSubprocedureList = data)
        : (this.mutableSubprocedureList = []);
    },

    loadListProcedures(pList) {
      if (this.isEditing) {
        const filter = pList.filter((el) => {
          return el.value === this.procedureId;
        });

        if (filter.length > 0) {
          this.form.sinoProcedureNome = filter[0].stp_novo_nome_procedimento;
        }
      }
    },

    getSubprocedure(pValue) {
      this.selectedSubprocedures = pValue;
    },

    searchSubprocedure() {
      const newSubprocedures = [];
      if (this.subprocedure) {
        this.$refs.subprocedures.actionSubmit();
        this.mutableSubprocedureList.filter((item) => {
          if (
            item.nomeSubProcedimento.includes(this.subprocedure.toUpperCase())
          ) {
            newSubprocedures.push(item);
          }
        });

        if (newSubprocedures.length > 0) {
          this.mutableSubprocedureList = newSubprocedures;
        } else {
          this.mutableSubprocedureList = [];
        }
      }
      this.$refs.subprocedures.actionDone();
      this.$refs.smartTable.cleanSelectRow();
    },

    selectScale(pValue) {
      if (pValue && typeof pValue === "object") {
        this.form.eea_id = pValue.item.data;
        this.form.eha_id = pValue.item.eha_id;
        this.form.hour = pValue.eea_inicio_atendimentos;
        this.employeeData.fun_nome = pValue.item.pes_nome;
        this.employeeData.ocp_nome = pValue.item.ocp_nome;
        this.employeeData.eea_quantidade_restantes =
          pValue.item.eea_quantidade_restantes;
        this.employeeData.inicio = pValue.item.eea_inicio_atendimentos;
        this.employeeData.final = pValue.item.eea_final_atendimentos;
      } else {
        this.form.eea_id = null;
        this.form.eha_id = null;
        this.form.hour = "";
        this.employeeData.fun_nome = null;
        this.employeeData.ocp_nome = null;
        this.employeeData.eea_quantidade_restantes = null;
        this.employeeData.inicio = null;
        this.employeeData.final = null;
      }
    },

    clickSelectedProcedure(procedure) {
      if (procedure) {
        this.selectedProcedure = procedure;
        this.form.procedureNome = this.selectedProcedure.item.procedimento;
        this.form.sinoProcedureNome = this.selectedProcedure.label;
        this.procedureCode = [
          this.selectedProcedure.item.stp_novo_codigo_procedimento,
        ];
        this.procedureCost = this.selectedProcedure.item.eap_custo_procedimento;

        this.subprocedureList();
      } else {
        this.selectedProcedure = null;
        this.form.procedureNome = "";
        this.form.sinoProcedureNome = "";
        this.resetSubprocedureList();
      }
    },

    resetSubprocedureList() {
      this.selectedSubprocedures = [];
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
    },

    selectLine(pValue) {
      if (pValue) {
        const existSubProcedure = (item) => {
          return item.nomeSubProcedimento === pValue.nomeSubProcedimento;
        };

        if (!this.selectedSubprocedures.find(existSubProcedure)) {
          this.selectedSubprocedures.push(pValue);
          this.procedureCode.push(pValue.stp_novo_codigo_procedimento);
          return;
        }
      }
      this.$toaster.warning("Subprocedimento já selecionado");
    },

    closeModalLinkSolicitation() {
      this.showModalLinkSolicitation = false;
    },

    removeSubprocedureList(pIndex) {
      this.selectedSubprocedures.splice(pIndex, 1);
      this.$refs.smartTable.cleanSelectRow();
    },

    async selectingPatient(pPatient) {
      if (pPatient) {
        const hasPerson = pPatient && pPatient.pes_id > 0;

        this.isPatient = pPatient.patient !== null && hasPerson;

        if (this.isPatient) {
          this.fillPatientInfo(pPatient);
        } else {
          this.patientInfo = {};
        }
      }
    },

    editPatient() {
      const fillForm = {
        form: this.form,
        selectedSubprocedures: this.selectedSubprocedures,
        mutableSubprocedureList: this.mutableSubprocedureList,
        procedureId: this.procedureId,
        selectedProcedure: this.selectedProcedure,
      };

      this.$store.commit("Exam/Schedule/SET_FORM_SCHEDULE", fillForm);
    },

    fillPatientInfo(pData) {
      this.patientInfo = {
        pxe_id: pData.patient.patient_exam
          ? pData.patient.patient_exam.pxe_id
          : null,
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pac_nome: pData.pes_nome,
        sex_sigla: pData && pData.gender ? pData.gender.sex_sigla : null,
        pes_nascimento:
          pData && pData.pes_nascimento ? pData.pes_nascimento : null,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };

      if (!this.patientInfo.pxe_id && this.examData?.pxe_id) {
        this.patientInfo.pxe_id = this.examData.pxe_id;
      }
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async saveForm(
      isSaveByModal = false,
      link = this.NO_REQUEST,
      solicitation = null,
    ) {
      const isValidForm = await this.isFormValid();

      if (!isValidForm) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }
      const variables = this.isEditing
        ? this.parseEditSave()
        : this.parseSave();

      if (
        this.mutableSubprocedureList.length > 0 &&
        (!this.selectedSubprocedures || this.selectedSubprocedures.length < 1)
      ) {
        this.$toaster.warning("Informe os subprocedimentos");
        return false;
      }

      if (isSaveByModal) {
        if (this.confirmSolicitationLinkVariables) {
          const variablesConfirmed = this.confirmSolicitationLinkVariables;
          variablesConfirmed.blnExibirAvisos = false;
          this.confirmSolicitationLinkVariables = null;

          return this.saveAction(variablesConfirmed);
        }

        variables.blnExibirAvisos = false;
        this.saveFromModal = true;

        return this.saveAction(variables);
      }

      if (this.isEditing) {
        variables.blnExibirAvisos = false;
        return this.saveAction(variables);
      }

      if (link === this.NO_REQUEST) {
        const list = [
          {
            patientId: this.patientInfo.pac_id,
            procedureCodes: this.procedureCode,
          },
        ];

        const requested = await this.$store.dispatch(
          "Exam/Schedule/SEARCH_REQUESTED_QUEUE_EXAMS",
          {
            list,
          },
        );

        if (requested && requested.count > 0) {
          this.requested = requested;
          this.showModalLinkSolicitation = true;
        } else {
          return this.saveAction(variables);
        }
      } else if (link === this.NOT_LINK_REQUEST) {
        return this.saveAction(variables);
      } else if (link === this.LINK_REQUEST && solicitation) {
        variables.arrFormData.stp_procedimentos_solicitados = solicitation.map(
          (item) => {
            return {
              id: item.id,
              codigo_procedimento: item.codigo_procedimento,
            };
          },
        );

        return this.saveAction(variables);
      }
    },

    async saveAction(variables) {
      try {
        this.$loader.start();

        if (this.isEditing) {
          await this.$store.dispatch("Exam/Schedule/EDIT_SCHEDULE_PATIENT", {
            variables,
          });
          this.$toaster.success("Agendamento alterado");

          return true;
        } else {
          const permissions = {
            blnPermissaoNormal: true,
            blnPermissaoReserva: true,
          };

          this.confirmSolicitationLinkVariables = variables;
          const data = await this.$store.dispatch(
            "Exam/Schedule/SCHEDULE_PATIENT",
            {
              variables,
              permissions,
            },
          );

          this.$toaster.success("Agendamento realizado");

          if (data.status) {
            const appointmentId = data.dados;
            return appointmentId;
          }
        }
      } catch (pErr) {
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isErrorPatientDay = esus_response.message.includes(
            "Este paciente já possui procedimento agendado para",
          );

          if (this.schedulingSameDay && isErrorPatientDay) {
            this.showModalHistorySchedule = true;
            return false;
          } else if (!this.schedulingSameDay && isErrorPatientDay) {
            this.$toaster.error(esus_response.message);
            return false;
          }

          const isTimeUnavailableExecption =
            esus_response.message === "HorarioIndisponivelException";

          if (isTimeUnavailableExecption) {
            if (esus_response && esus_response.trace) {
              this.unavailableHour.nextAvailableHour = esus_response.trace;
              this.unavailableHour.show = true;
              this.unavailableHour.message = `${variables.con_hora} não está disponível. Agendar para ${this.unavailableHour.nextAvailableHour}?`;
              return false;
            }
          }

          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Falha ao salvar agendamento",
          );
          return false;
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Falha ao salvar agendamento",
          );
          return false;
        }
      } finally {
        this.$loader.finish(1000);
      }
    },

    parseSave() {
      const arrFormData = {
        eap_hora: this.setExamHour(),
        eap_id_exames_procedimentos_profissionais: this.selectedProcedure.item
          .data,
        eap_id_funcionarios: this.employeeData.fun_id,
        eap_id_locais_atendimento: this.form.id_locais_atendimento,
        eap_id_sinonimos_procedimentos: this.selectedProcedure.item.sip_id,
        eap_vagas_ocupadas: this.form.vacancies,
        eap_custo_procedimento: this.procedureCost
          ? this.formatProcedureCost(this.procedureCost)
          : null,
        eap_quantidade: 1,
        eea_id: this.form.scale,
        eha_data_exame: this.getFilterDate(),
        eha_id_exames_tipo_escalas: this.employeeData
          .eea_id_exames_tipo_escalas,
        exa_id_pacientes_exames: this.patientInfo.pxe_id,
        stp_subProcedimentos: this.formatListSubprocedure(),
        stp_ps: this.formatListSubprocedure(),
        vus_id: this.employeeData.vus_id,
      };

      const strTipoAgendamento = this.isPerDay
        ? "AGENDAMENTOPORDIA"
        : "AGENDAMENTOPORPERIODO";

      return { arrFormData, blnExibirAvisos: true, strTipoAgendamento };
    },

    parseEditSave() {
      let sip_id = this.selectedProcedure?.item?.sip_id;
      let epp_id = this.selectedProcedure?.item?.data;

      if (!sip_id) {
        sip_id = this.examData.eap_id_sinonimos_procedimentos;
      }

      if (!epp_id) {
        epp_id = this.examData.epp_id;
      }

      const arrFormData = {
        eap_custo_procedimento: this.procedureCost,
        eap_data_pedido: null,
        eap_hora: this.setExamHour(),
        eap_id: this.examData.eap_id,
        eap_id_exames_procedimentos_profissionais: epp_id,
        eap_id_filas_exames: 0,
        eap_id_funcionarios: this.employeeData.fun_id,
        eap_id_locais_atendimento: this.form.id_locais_atendimento,
        eap_id_sinonimos_procedimentos: sip_id,
        eap_quantidade: 1,
        eap_solicitante_externo: null,
        eap_vagas_ocupadas: this.form.vacancies,
        eea_id: this.form.scale,
        eha_data_exame: this.getFilterDate(),
        eha_id: 0,
        eha_id_exames_tipo_escalas: this.employeeData
          .eea_id_exames_tipo_escalas,
        exa_id_pacientes_exames: this.patientInfo.pxe_id,
        pls_is: 0,
        set_id: 0,
        stp_novo_nome_procedimento: null,
        stp_subProcedimentos: this.formatListSubprocedure(),
        stp_ps: this.formatListSubprocedure(),
        vus_id: this.employeeData.vus_id,
      };

      return {
        arrFormData,
        blnIgnorarProcInformado: this.SKIP_SELECTED_PROCEDURE,
        blnExibirAvisos: this.SHOW_WARNINGS,
      };
    },

    formatListSubprocedure() {
      const subprocedures = [];
      this.selectedSubprocedures.map((el) => {
        subprocedures.push(el.nomeSubProcedimento);
      });

      return subprocedures;
    },

    getPermissionsSchedule() {
      const today = moment({ h: 0, m: 0, s: 0 });
      const scheduleDate = moment(
        this.employeeData.data_consulta || this.employeeData.DataConsulta,
        "DD/MM/YYYY",
      );

      if (scheduleDate < today) {
        return {
          blnPermissaoNormal: this.$Permissions.sector.has(
            "consulta.agendamento.AgendamentoRetroativo",
            this.employeeData.set_id,
          ),
          blnPermissaoReserva: this.$Permissions.sector.has(
            "consulta.agendamento.agendamentoRetroativoReservaTecnica",
            this.employeeData.set_id,
          ),
        };
      }
      return {
        blnPermissaoNormal: this.$Permissions.sector.has(
          "consulta.agendamento.AgendamentoFuturo",
          this.employeeData.set_id,
        ),
        blnPermissaoReserva: this.$Permissions.sector.has(
          "consulta.agendamento.agendamentoFuturoReservaTecnica",
          this.employeeData.set_id,
        ),
      };
    },

    formatDataBR(pValue) {
      return pValue ? this.$utils.date.BrazilianDateFormat(pValue) : "";
    },

    getFilterDate() {
      const filterDate = this.employeeData.data_exame;
      return filterDate;
    },

    setExamHour() {
      return this.form.hour || this.employeeData.inicio;
    },

    saveFormByModalConfirmSchedule() {
      this.saveFromModal = true;
      this.showModalHistorySchedule = false;
      this.$parent.$parent.saveForm(true);
    },

    closeModalHistorySchedule() {
      this.showModalHistorySchedule = false;
    },

    selectOnlyItemScale(val) {
      this.form.scale = val;
      this.form.eea_id = val;
    },

    selectOnlyItemHealthPlans(val) {
      this.form.id_planos_saude = val;
    },

    selectOnlyItemPlace(val) {
      this.form.id_locais_atendimento = val;
    },

    selectOnlyItemVacancies(val) {
      this.form.vacancies = val;
    },

    selectOnlyItemHour(val) {
      this.form.hour = val;
    },

    cleanForm() {
      if (!this.isEditing) {
        this.suggestPatient = null;
        this.patientInfo = {};

        if (this.patient) {
          this.$refs.patient.cleanValidate();
        }
      }

      if (this.showHour) {
        this.form.hour = null;
        this.$refs.hour.cleanValidate();
      }

      this.scaleData = {};
      this.selectedSubprocedures = [];
      this.mutableSubprocedureList = [];
      this.selectedProcedure = null;
      this.procedureCode = null;
      this.procedureNome = null;
      this.procedureCost = null;
      this.procedureId = null;
      this.form.id_locais_atendimento = null;
      this.form.id_planos_saude = null;
      this.form.scale = null;
      this.form.eea_id = null;
      this.form.eha_id = null;
      this.form.vacancies = null;
      this.saveFromModal = false;
      this.$refs.place.cleanValidate();
      this.$refs.healthPlans.cleanValidate();
      this.$refs.scale.cleanValidate();
      this.$refs.vacancies.cleanValidate();
      this.$refs.comboProcedures.cleanValidate();
    },

    removeSelectedData() {
      this.$store.commit("Exam/Schedule/UNSELECT_EMPLOYEE_DATA");
    },

    blockedPatient(pValue) {
      this.$emit("canSave", !pValue);
    },

    formatProcedureCost(pValue) {
      if (!pValue) return null;

      return pValue.replace(".", "").replace(",", ".");
    },
  },
};
</script>
