import IconCircleAdd from "./CircleAdd";
import IconArrow from "./Arrow";
import IconActivate from "./Activate.vue";
import IconBarCode from "./BarCode";
import IconBasicAttention from "./BasicAttention";
import IconBed from "./Bed";
import IconBells from "./Bells";
import IconCake from "./Cake";
import IconCalendar from "./Calendar";
import IconCard from "./Card";
import IconCheck from "./Check";
import IconClipboard from "./Clipboard";
import IconClock from "./Clock";
import IconCross from "./Cross";
import IconClose from "./Close";
import IconDanger from "./Danger";
import IconDesactivate from "./Desactivate.vue";
import IconDocument from "./Document";
import IconDocumentPerson from "./DocumentPerson";
import IconArrowDown from "./ArrowDown";
import IconUnitHealth from "./UnitHealth";
import IconEmpty from "./Empty";
import IconLogoff from "./Logoff";
import IconOn from "./On";
import IconInfoHelper from "./InfoHelper.vue";
import IconNewHelper from "./NewHelper";
import IconExclamation from "./Exclamation";
import IconOnlyExclamation from "./OnlyExclamation";
import IconExclamationInverse from "./ExclamationInverse";
import IconExit from "./Exit";
import IconInfoHelperAbout from "./InfoHelperAbout";
import IconCancelExit from "./CancelExit";
import IconExitPerson from "./ExitPerson";
import IconIn from "./In";
import IconOut from "./Out";
import IconArrowExchange from "./ArrowExchange";
import IconFileImg from "./FileImg";
import IconTransf from "./Transfer";
import IconDiamondAlert from "./DiamondAlert";
import IconGarbage from "./Garbage";
import IconGenderFemale from "./GenderFemale";
import IconGenderMale from "./GenderMale";
import IconHistory from "./History";
import IconHospital from "./Hospital";
import IconArrowLeftPoint from "./ArrowLeftPoint";
import IconArrowLeft from "./ArrowLeft";
import IconArrowDoubleLeftPoint from "./ArrowDoubleLeftPoint";
import IconArrowDownload from "./ArrowDownload";
import IconArrowUpload from "./ArrowUpload";
import IconArrowDoubleRightPoint from "./ArrowDoubleRightPoint";
import IconLock from "./Lock";
import IconBaby from "./Baby";
import IconLocked from "./Locked";
import IconList from "./List";
import IconPerfil from "./Perfil";
import IconMenusquares from "./MenuSquares";
import IconMother from "./Mother";
import IconPrinter from "./Printer";
import IconEditPerson from "./EditPerson";
import IconTag from "./Tag";
import IconPencil from "./Pencil";
import IconMap from "./Map";
import IconPatientMan from "./PatientMan";
import IconPatientWoman from "./PatientWoman";
import IconIndigentWoman from "./IndigentWoman";
import IconPhoto from "./Photo";
import IconPlace from "./Place";
import IconPower from "./Power";
import IconRecords from "./Records";
import IconRemove from "./Remove";
import IconRightArrow from "./RightArrow";
import IconSad from "./Sad";
import IconPatientBioslab from "./PatientBioslab";
import IconSearch from "./Search";
import IconSector from "./Sector";
import IconArrowUp from "./ArrowUp";
import IconUpload from "./Upload";
import IconUser from "./User";
import IconNurse from "./Nurse";
import IconUserUserConfig from "./UserSolid";
import IconEllipsis from "./Ellipsis";
import IconTelephone from "./Telephone";
import IconCsv from "./Csv";
import IconAdd from "./Add";
import IconClean from "./Clean";
import IconSearchExam from "./SearchExam";
import IconPill from "./Pill";
import IconQrCode from "./QrCode";
import IconIndigent from "./Indigent";
import IconAttachment from "./Attachment";
import IconReload from "./Reload";
import IconDeadBed from "./DeadBed";
import IconArrowRight from "./ArrowRight";
import IconNewSchedule from "./NewSchedule";
import IconDeleteSchedule from "./DeleteSchedule";
import IconShow from "./Show";
import IconHourglass from "./Hourglass";
import IconRegulate from "./Regulate";
import IconUnregulate from "./Unregulate";
import IconExternSchedule from "./ExternSchedule";
import IconDeletePending from "./DeletePending";
import IconDeletePendingResolution from "./DeletePendingResolution";
import IconResolvePending from "./ResolvePending";
import IconLess from "./Less";
import IconArrowSmall from "./ArrowSmall";
import IconArrowDouble from "./ArrowDouble";
import IconSquare from "./Square";
import IconSquareChecked from "./SquareChecked";
import IconWebcam from "./Webcam";
import IconArrowSquare from "./ArrowSquare";
import IconCalendarLaboratoryExam from "./CalendarLaboratoryExam";
import IconEffectiveSchedule from "./EffectiveSchedule";
import IconOpenSchedule from "./OpenSchedule";
import IconNotEffectiveSchedule from "./NotEffectiveSchedule";
import IconPersonUndefined from "./PersonUndefined";
import IconCircleTable from "./CircleTable";
import IconCircleClose from "./CircleClose";
import IconSplit from "./Split";
import IconOff from "./Off";
import IconLightBulbHelper from "./LightBulbHelper";
import IconExclamationHelper from "./ExclamationHelper";
import IconConfigure from "./Configure";
import IconArrowCircle from "./ArrowCircle";
import IconHelp from "./Help";
import IconUnify from "./Unify";
import IconDangerHelper from "./DangerHelper";
import IconNotView from "./NotView";
import IconRgView from "./RgView";
import IconMedicalRecord from "./MedicalRecord";
import IconIndicationActive from "./IndicationActive";
import IconIndicationInactive from "./IndicationInactive";
import IconPdf from "./Pdf";
import IconMakeCall from "./MakeCall";
import IconCallNext from "./CallNext";
import IconEndCall from "./EndCall";
import IconStickRow from "./StickRow";
import IconSynchronize from "./Synchronize";
import IconStatus from "./Status";
import IconHospitalInternment from "./HospitalInternment";
import IconTypePresentation from "./TypePresentation";
import IconProductType from "./ProductType";
import IconAttendTransfer from "./AttendTransfer";
import IconConsumption from "./Consumption";
import IconDiscardPharmacy from "./Discard";
import IconEntryPharmacy from "./Entry";
import IconExitPharmacy from "./ExitPharmacy";
import IconPrescription from "./Prescription";
import IconRequestPharmacy from "./Request";
import IconRequestTransfer from "./RequestTransfer";
import IconRevenue from "./Revenue";
import IconReversal from "./Reversal";
import IconObservation from "./Observation";
import IconNewSheet from "./NewSheet";
import IconDuplicateSheet from "./DuplicateSheet";
import IconResend from "./Resend";
import IconConsistency from "./Consistency";
import IconConfigureOptions from "./ConfigureOptions.vue";
import IconFilter from "./Filter";
import IconAccessibility from "./Accessibility";
import IconNotShow from "./NotShow";
import IconInactiveSchedule from "./InactiveSchedule";
import IconActiveSchedule from "./ActiveSchedule";

export {
  IconSplit,
  IconMakeCall,
  IconCallNext,
  IconEndCall,
  IconStickRow,
  IconCircleAdd,
  IconMedicalRecord,
  IconArrow,
  IconBarCode,
  IconBasicAttention,
  IconBed,
  IconBells,
  IconCake,
  IconCalendar,
  IconArrowExchange,
  IconCard,
  IconCross,
  IconCsv,
  IconCheck,
  IconClipboard,
  IconClock,
  IconClose,
  IconDanger,
  IconDocumentPerson,
  IconArrowDownload,
  IconArrowUpload,
  IconFileImg,
  IconInfoHelper,
  IconPatientMan,
  IconIndigentWoman,
  IconDocument,
  IconArrowDown,
  IconEmpty,
  IconOnlyExclamation,
  IconExclamation,
  IconExclamationInverse,
  IconExit,
  IconCancelExit,
  IconExitPerson,
  IconTransf,
  IconGarbage,
  IconGenderFemale,
  IconGenderMale,
  IconIndigent,
  IconHospital,
  IconHistory,
  IconArrowLeft,
  IconArrowLeftPoint,
  IconArrowDoubleLeftPoint,
  IconDiamondAlert,
  IconCalendarLaboratoryExam,
  IconList,
  IconIn,
  IconOut,
  IconPatientBioslab,
  IconPatientWoman,
  IconLock,
  IconLocked,
  IconMenusquares,
  IconMother,
  IconNurse,
  IconPrinter,
  IconEditPerson,
  IconTag,
  IconPencil,
  IconOn,
  IconMap,
  IconPhoto,
  IconPlace,
  IconPower,
  IconRecords,
  IconRemove,
  IconRightArrow,
  IconSad,
  IconSearch,
  IconSector,
  IconArrowUp,
  IconUpload,
  IconNewHelper,
  IconUser,
  IconUserUserConfig,
  IconEllipsis,
  IconTelephone,
  IconSearchExam,
  IconOff,
  IconAdd,
  IconClean,
  IconArrowDoubleRightPoint,
  IconPill,
  IconQrCode,
  IconAttachment,
  IconBaby,
  IconReload,
  IconDeadBed,
  IconArrowRight,
  IconNewSchedule,
  IconDeleteSchedule,
  IconShow,
  IconHourglass,
  IconRegulate,
  IconUnregulate,
  IconExternSchedule,
  IconDeletePending,
  IconResolvePending,
  IconDeletePendingResolution,
  IconLess,
  IconArrowSmall,
  IconArrowDouble,
  IconSquare,
  IconSquareChecked,
  IconWebcam,
  IconArrowSquare,
  IconEffectiveSchedule,
  IconOpenSchedule,
  IconNotEffectiveSchedule,
  IconPersonUndefined,
  IconCircleTable,
  IconCircleClose,
  IconLogoff,
  IconInfoHelperAbout,
  IconUnitHealth,
  IconPerfil,
  IconLightBulbHelper,
  IconExclamationHelper,
  IconSynchronize,
  IconConfigure,
  IconHelp,
  IconArrowCircle,
  IconUnify,
  IconNotView,
  IconRgView,
  IconDangerHelper,
  IconIndicationActive,
  IconIndicationInactive,
  IconStatus,
  IconPdf,
  IconHospitalInternment,
  IconTypePresentation,
  IconProductType,
  IconDesactivate,
  IconActivate,
  IconAttendTransfer,
  IconConsumption,
  IconDiscardPharmacy,
  IconEntryPharmacy,
  IconExitPharmacy,
  IconPrescription,
  IconRequestPharmacy,
  IconRequestTransfer,
  IconRevenue,
  IconReversal,
  IconObservation,
  IconNewSheet,
  IconDuplicateSheet,
  IconResend,
  IconConsistency,
  IconConfigureOptions,
  IconFilter,
  IconAccessibility,
  IconNotShow,
  IconInactiveSchedule,
  IconActiveSchedule,
};
