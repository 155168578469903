import { render, staticRenderFns } from "./PharmacySatelliteRevenue.vue?vue&type=template&id=37adda48&"
import script from "./PharmacySatelliteRevenue.vue?vue&type=script&lang=js&"
export * from "./PharmacySatelliteRevenue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports