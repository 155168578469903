export default {
  SET_DATA_IMPORT_SIGTAP_BDSIA: (state, data) => {
    state.name_file_sigtap = data.name_file_sigtap;
    state.name_file_bdsia = data.name_file_bdsia;
    state.cmp_import_sigtap = data.cmp_import_sigtap;
    state.cmp_import_bdsia = data.cmp_import_bdsia;
  },
  SET_DATE_IMPORT_SIGTAP: (state, data) => {
    state.date_import_sigtap = data;
  },
  SET_DATE_IMPORT_BDSIA: (state, data) => {
    state.date_import_bdsia = data;
  },
  RESET_DATA_IMPORT_SIGTAP_BDSIA: (state) => {
    state.name_file_sigtap = null;
    state.name_file_bdsia = null;
    state.cmp_import_sigtap = null;
    state.cmp_import_bdsia = null;
    state.date_import_sigtap = null;
    state.date_import_bdsia = null;
  },
};
