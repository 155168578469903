var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modulebox',{ref:"modulebox",staticClass:"pharmacy-satellite-reversal",attrs:{"title":"Estorno de Produtos e Medicamentos"}},[_c('div',{staticClass:"pharmacy-satellite-reversal-body"},[_c('RgValidatorForm',{ref:"validator"},[_c('FormBase',{attrs:{"title":"Origem"}},[_c('section',{staticClass:"pharmacy-satellite-reversal-origin-form"},[_c('RgSelectUnithealthPharmacy',{ref:"unitHealthOrigin",class:{
              disable: this.mutableProductList.length > 0 || _vm.printed,
            },attrs:{"disabled":this.mutableProductList.length > 0 || _vm.printed || _vm.disabledByModal,"rules":{ required: true }},model:{value:(_vm.form.unitHealthOrigin),callback:function ($$v) {_vm.$set(_vm.form, "unitHealthOrigin", $$v)},expression:"form.unitHealthOrigin"}}),_c('RgSelectPharmacy',{ref:"pharmacyOrigin",class:{
              disable:
                !_vm.form.unitHealthOrigin ||
                this.mutableProductList.length > 0 ||
                _vm.printed,
            },attrs:{"byUser":"","unitHealthId":_vm.form.unitHealthOrigin,"userId":_vm.userLoginId,"typePharmacy":_vm.SATELLITE_PHARMACY_TYPE,"rules":{ required: true },"disabled":!_vm.form.unitHealthOrigin ||
              this.mutableProductList.length > 0 ||
              _vm.printed ||
              _vm.disabledByModal},on:{"change":_vm.selectedPharmacyOrigin},model:{value:(_vm.form.pharmacyOrigin),callback:function ($$v) {_vm.$set(_vm.form, "pharmacyOrigin", $$v)},expression:"form.pharmacyOrigin"}}),_c('RgComboboxPharmacyTypeTransport',{ref:"type",class:{
              disable: this.mutableProductList.length > 0 || _vm.printed,
            },attrs:{"label":"Tipo","rules":{ required: true },"disabled":this.mutableProductList.length > 0 || _vm.printed || _vm.disabledByModal},on:{"change":_vm.selectedTypes},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('RgInputDate',{ref:"returnDate",class:{ disable: true },attrs:{"label":"Data da Devolução","rules":{ required: true },"disabled":true},model:{value:(_vm.form.returnDate),callback:function ($$v) {_vm.$set(_vm.form, "returnDate", $$v)},expression:"form.returnDate"}})],1)]),_c('FormBase',{attrs:{"title":"Destino"}},[_c('section',{staticClass:"pharmacy-satellite-reversal-destination-form"},[_c('RgSelectUnithealthPharmacy',{ref:"unitHealthDestiny",class:{
              disable: this.mutableProductList.length > 0 || _vm.printed,
            },attrs:{"disabled":this.mutableProductList.length > 0 || _vm.printed || _vm.disabledByModal,"openOnlyBottom":"","rules":{ required: true }},model:{value:(_vm.form.unitHealthDestiny),callback:function ($$v) {_vm.$set(_vm.form, "unitHealthDestiny", $$v)},expression:"form.unitHealthDestiny"}}),_c('RgSelectPharmacy',{ref:"pharmacyDestiny",class:{
              disable:
                !_vm.form.unitHealthDestiny ||
                this.mutableProductList.length > 0 ||
                _vm.printed,
            },attrs:{"unitHealthId":_vm.form.unitHealthDestiny,"typePharmacy":_vm.CENTRAL_PHARMACY_TYPE,"rules":{ required: true },"disabled":!_vm.form.unitHealthDestiny ||
              this.mutableProductList.length > 0 ||
              _vm.printed ||
              _vm.disabledByModal,"openOnlyBottom":""},on:{"change":_vm.selectedPharmacyDestiny},model:{value:(_vm.form.pharmacyDestiny),callback:function ($$v) {_vm.$set(_vm.form, "pharmacyDestiny", $$v)},expression:"form.pharmacyDestiny"}}),_c('RgInput',{ref:"remittance",class:{ disable: true },attrs:{"label":"Guia de Remessa","rules":{ required: true },"disabled":true},model:{value:(_vm.form.remittance),callback:function ($$v) {_vm.$set(_vm.form, "remittance", $$v)},expression:"form.remittance"}})],1)]),_c('FormBase',{attrs:{"title":"Produtos e Medicamentos"}},[_c('section',{staticClass:"pharmacy-satellite-reversal-products"},[_c('div',{staticClass:"pharmacy-satellite-reversal-products-form"},[_c('div',{staticClass:"form"},[_c('RgSelectProduct',{ref:"product",class:{ disable: _vm.disableProduct || _vm.printed },attrs:{"pharmacyId":Number(_vm.form.pharmacyOrigin),"typeMovimentation":_vm.PRODUCTS_HAVE_ENTRIES,"withMovement":true,"rules":{ required: true },"disabled":_vm.disableProduct || _vm.printed || _vm.disabledByModal},on:{"change":_vm.selectedProduct},model:{value:(_vm.form.product),callback:function ($$v) {_vm.$set(_vm.form, "product", $$v)},expression:"form.product"}}),_c('RgSelectTypePresentation',{ref:"typePresentation",class:{ disable: true },attrs:{"label":"Apresentação","rules":{ required: true },"disabled":true},model:{value:(_vm.form.typePresentation),callback:function ($$v) {_vm.$set(_vm.form, "typePresentation", $$v)},expression:"form.typePresentation"}}),(_vm.stockLotExpiration)?_c('RgSelectLotExpiration',{ref:"lot",class:{
                  disable: !_vm.form.product || _vm.printed || _vm.disabledByModal,
                },attrs:{"id":"lot","pharmacyId":Number(_vm.form.pharmacyOrigin),"productId":Number(_vm.form.product),"rules":{ required: true },"disabled":!_vm.form.product || _vm.printed},on:{"change":_vm.selectedLotExpiration},model:{value:(_vm.form.lotExpiration),callback:function ($$v) {_vm.$set(_vm.form, "lotExpiration", $$v)},expression:"form.lotExpiration"}}):_vm._e(),_c('RgInput',{ref:"stock",class:{ disable: true },attrs:{"rules":{ required: true },"disabled":true,"label":"Estoque","placeholder":"000000000"},model:{value:(_vm.form.stock),callback:function ($$v) {_vm.$set(_vm.form, "stock", $$v)},expression:"form.stock"}}),_c('RgInputNumber',{ref:"amountReturned",class:{ disable: _vm.printed },attrs:{"rules":{ required: true, fn: _vm.validateAmount },"maxlength":10,"readonly":_vm.printed || _vm.disabledByModal,"disabled":_vm.printed || _vm.disabledByModal,"label":"Quantidade Devolvida","placeholder":"000000000"},model:{value:(_vm.form.amountReturned),callback:function ($$v) {_vm.$set(_vm.form, "amountReturned", $$v)},expression:"form.amountReturned"}})],1),_c('div',{staticClass:"actions"},[_c('RgCleanButton',{attrs:{"disabled":_vm.printed || _vm.disabledByModal},on:{"click":_vm.openModalConfirmField}}),_c('RgAddButton',{attrs:{"id":"add-btn","title":"Adicionar Produto/Medicamento","disabled":_vm.printed || _vm.disabledByModal},on:{"click":_vm.openModalConfirmReversal}})],1)]),_c('hr',{staticClass:"hr"}),_c('SmartTable',{ref:"smartTable",attrs:{"name":"PharmacyProductsReversal","columns":_vm.COLUMNS,"body":_vm.mutableProductList,"total":_vm.mutableProductList.length,"showPagination":false,"initial-columns":7}},[_c('div',{staticClass:"top-buttons",attrs:{"slot":"top-buttons"},slot:"top-buttons"},[_c('RgPrinterButton',{class:{
                  disable: _vm.mutableProductList.length === 0,
                },attrs:{"id":" printer-btn","label":"Imprimir Guia de Remessa","title":"Imprimir Guia de Remessa","small":"","disabled":_vm.mutableProductList.length === 0 || _vm.disabledByModal},on:{"click":_vm.printerReversal}})],1)])],1)])],1)],1),_c('div',{staticClass:"pharmacy-satellite-reversal-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('MediumButton',{attrs:{"disabled":_vm.disabledByModal,"title":"Voltar","label":"Voltar"},on:{"click":_vm.goBack}})],1),_c('ModalConfirmFieldClearing',{ref:"confirmClearing",attrs:{"id":"modal-confirm","show":_vm.modalConfirmField},on:{"getYes":_vm.cleanForm,"getOut":_vm.closeModalConfirmField,"close":_vm.closeModalConfirmField}}),_c('ModalConfirmDefault',{attrs:{"id":"modal-confirm-default","show":_vm.modalConfirmReversal,"subtitle":"A operação não poderá ser desfeita. Deseja realizar o registro de Saída?","message":this.table.product +
      ' - ' +
      this.table.lotExpiration +
      ' - ' +
      this.form.amountReturned,"title":"Registrar Saída de Produtos","yes-label":"Salvar","no-label":"Cancelar","buttonsRight":""},on:{"getYes":_vm.saveReversal,"getOut":_vm.closeModalConfirmReversal,"close":_vm.closeModalConfirmReversal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }