<style src="./RgSuggestUnitRecordNumber.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :mask="mask"
    :styles="enableStyle ? 'width: 155px;' : ''"
    min="0"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="unit-record-number-item"
    >
      <div class="unit-record-number-row-info">
        <span class="patient-name">{{ item.pes_nome }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchUnitRecordNumber from "./action/SearchUnitRecordNumber";

export default {
  name: "RgSuggestUnitRecordNumber",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    enableStyle: {
      Type: "boolean",
      default: true,
    },
  },
  data() {
    return {
      captionRaw: "ppr_numero",
      mask: "###############",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      const variables = this.defineQuery(pSearchTerm);
      return SearchUnitRecordNumber({ variables });
    },
    async selectingItemFromAihNumber(pSearchTerm) {
      const units = await this.doSearch(pSearchTerm);
      if (units && units.length > 0) {
        this.selectingItemFromSuggestList(units[0]);
        return true;
      }

      return false;
    },
    defineQuery(pSearchTerm, pUnitHealth) {
      const unitHealthId =
        pUnitHealth || this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      const number = pSearchTerm.toString().trim();

      const variables = {
        unitRecordNumber: number,
        unitHealthId: unitHealthId,
      };

      return variables;
    },
  },
};
</script>
