<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchOccupationByUnit from "./action/SearchOccupationByUnit";

export default {
  name: "RgSelectOccupationByUnit",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Ocupação",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    unitHealthId(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.fillData();
      }
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async fillData() {
      if (this.unitHealthId) {
        const data = await SearchOccupationByUnit({
          intIdUnidadeSaude: this.unitHealthId,
        });

        this.valuesData = data.map((item) => {
          return { value: item.data, label: item.label };
        });
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
