<template lang="html">
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15497 15.8942C7.95168 16.1136 7.67432 16.2361 7.38621 16.2361C7.09809 16.2361 6.82073 16.1136 6.61744 15.8942L0.477866 9.30405C-0.159289 8.62025 -0.159289 7.51142 0.477866 6.82891L1.24663 6.00364C1.88398 5.31984 2.91598 5.31984 3.55313 6.00364L7.38621 10.1176L16.4469 0.512512C17.0842 -0.171288 18.1172 -0.171288 18.7534 0.512512L19.5221 1.33777C20.1593 2.02157 20.1593 3.13019 19.5221 3.81292L8.15497 15.8942Z"
      :fill="backgroundColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Check",
  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },
  },
};
</script>
