<template lang="html">
  <div v-if="show && hasErrors" class="modal-show-error">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="rg-alert-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-show-error-header">
        <div class="title">
          <SmallButton :backgroundColor="'#ffa56a'">
            <IconDanger slot="icon" />
          </SmallButton>
          {{ title }}
        </div>
        <div class="subtitle">{{ subTitle }}</div>
      </div>

      <div slot="body" class="modal-show-error-body">
        <div class="internal-body">
          <div
            v-for="(item, index) in errors"
            :key="index"
            class="items-errors"
          >
            <div class="error" title="Erro" style="white-space: pre-line">
              <span>ERRO {{ ++index }}</span>
              {{ item.error }}
            </div>
          </div>
        </div>

        <div class="tooltip-area">
          <Tooltip startOpen class="tooltip">
            <IconLightBulbHelper slot="icon" hollow />
            <div slot="content" class="content">
              <span class="title">
                Caso seja necessário, entre em contato com o suporte Bioslab:
              </span>

              <ul class="list">
                <li class="item">Atendimento WhatsApp (21) 99311-9141;</li>
                <li class="item">
                  Atendimento por telefone (21) 3622-6400 - ramal 1.
                </li>
              </ul>
            </div>
          </Tooltip>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  SmallButton,
  IconDanger,
  IconLightBulbHelper,
  Tooltip,
} from "~tokio/primitive";

import { ERRORS } from "./utils/BioslabErrors";

export default {
  name: "ModalShowError",
  components: {
    RgBaseModal,
    SmallButton,
    IconDanger,
    IconLightBulbHelper,
    Tooltip,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    title() {
      return this.errors.some((item) => item.inb_mensagem_erro)
        ? "Falha na comunicação com o Bioslab"
        : "Erro no envio para o Bioslab";
    },
    subTitle() {
      return this.errors.some((item) => item.inb_mensagem_erro)
        ? "O serviço Bioslab está indisponível no momento. Confira os erros apresentados e tente enviar o pedido novamente."
        : "Faça uma revisão dos dados do pedido de acordo com os erros apresentados:";
    },
    hasErrors() {
      return this.errors && this.errors.length > 0;
    },
  },
  watch: {
    errors() {
      this.errorHandlerBioslab();
    },
  },
  methods: {
    errorHandlerBioslab() {
      this.errors.forEach(async (pError) => {
        const error = pError.error;

        if (error) {
          if (error.includes(ERRORS.TIMEOUT.ORIGINAL_MESSAGE)) {
            pError.error = ERRORS.TIMEOUT.NEW_MESSAGE;
          }

          if (error === ERRORS.WEBSERVICE.ORIGINAL_MESSAGE) {
            pError.error = ERRORS.WEBSERVICE.NEW_MESSAGE;
          }

          if (error.includes(ERRORS.SYSTEM.ORIGINAL_MESSAGE)) {
            pError.error = ERRORS.SYSTEM.NEW_MESSAGE;
          }

          if (error.includes(ERRORS.SERVER.ORIGINAL_MESSAGE)) {
            pError.error = ERRORS.SERVER.NEW_MESSAGE;
          }
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
