import { render, staticRenderFns } from "./SubmoduleList.vue?vue&type=template&id=3490d378&scoped=true&"
import script from "./SubmoduleList.vue?vue&type=script&lang=js&"
export * from "./SubmoduleList.vue?vue&type=script&lang=js&"
import style0 from "./SubmoduleList.scss?vue&type=style&index=0&id=3490d378&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3490d378",
  null
  
)

export default component.exports