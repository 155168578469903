import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetPatientById($pesId: Int) {
    people(pes_id: $pesId) {
      rows {
        pes_ativo
        pes_id
        pes_nome
        pes_nascimento
        pes_nome_social
        pes_mae
        pes_pai
        pes_profissao
        pes_email
        pes_obs
        pes_foto
        pes_foto_url
        pes_id_usuarios
        pes_id_locais_atendimento_pleni
        pes_empresa_pleni
        pes_data_unificacao
        foreign {
          est_id
          est_portaria
          est_chegada_brasil
          est_naturalizacao
          est_passaporte
        }
        attachments(fileinfo: true) {
          rows {
            pea_id
            pea_id_pessoas
            pea_id_documentos_anexos
            pea_anexos
            pea_url
            pea_url_remove
            pea_file_size
            pea_mimetype
            document_attachment {
              doa_id
              doa_nome
            }
          }
          count
        }
        gender {
          sex_id
          sex_sigla
        }
        religion {
          rel_id
        }
        bloodType {
          tps_id
        }
        maritalStatus {
          ecv_id
        }
        ethnicity {
          etn_id
        }
        nationality {
          nac_id
          nac_nome
          nac_ativo
          nac_codigo_ab
        }
        city {
          mun_id
          mun_nome
          mun_ativo
        }
        scholarity {
          esc_id
        }
        jobStatus {
          sit_id
        }
        telephones {
          rows {
            tel_id
            tel_numero
            tel_recado
            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
        address {
          end_cep
          end_logradouro
          end_numero
          end_complemento
          end_referencia
          publicPlaceType {
            tlg_id
            tlg_nome
          }
          subNeighborhood {
            sub_nome
          }
          neighborhood {
            bai_nome
            city {
              mun_id
              mun_nome
              state {
                est_id
                est_sigla
              }
            }
          }
        }
        cnh {
          cnh_id
          cnh_id_tipos_documento
          cnh_numero
        }
        cns {
          crs_id
          crs_id_tipos_documento
          crs_numero
        }
        cpf {
          cpf_id
          cpf_id_tipos_documento
          cpf_numero
        }
        rg {
          rgs_id
          rgs_id_tipos_documento
          rgs_id_orgaos_emissores
          rgs_numero
          rgs_expedicao
        }
        pisPasep {
          pis_id
          pis_id_tipos_documento
          pis_numero
        }
        weddingCertificate {
          ctc_id
          ctc_id_tipos_documento
          ctc_cartorio
          ctc_livro
          ctc_folha
          ctc_termo
          ctc_emissao
        }
        birthCertificate {
          ctc_id
          ctc_id_tipos_documento
          ctc_cartorio
          ctc_livro
          ctc_folha
          ctc_termo
          ctc_emissao
        }
        newBirthCertificate {
          ctn_id
          ctn_id_tipos_documento
          ctn_numero
        }
        ctps {
          ctt_id
          ctt_id_estados
          ctt_id_tipos_documento
          ctt_numero
          ctt_serie
          ctt_emissao

          state {
            est_id
            est_sigla
          }
        }
        voterTitle {
          tit_id
          tit_id_municipios
          tit_id_tipos_documento
          tit_numero
          tit_zona
          tit_secao

          city {
            mun_id
            mun_nome

            state {
              est_id
              est_sigla
            }
          }
        }
        user {
          usu_nome
        }
        notification {
          rows {
            nop_id
            nop_id_pessoas
            nop_id_ddds
            nop_celular
            nop_cpf
            nop_criado_em
            nop_alterado_em
            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
        patient {
          pac_id
          pac_prontuario_unico
          record_numbers {
            rows {
              ppr_numero
              ppr_id_unidades_saude
            }
          }
        }
        place {
          lca_id
          sector {
            set_id
            unit_health {
              uns_id
            }
          }
        }
      }
    }
  }
`;

export default async ({ commit, rootGetters }, { pesId = null }) => {
  try {
    const variables = { pesId };

    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const isWithoutResult = data.people.rows.length < 1;

    if (isWithoutResult) return false;

    const result = data.people.rows[0];
    return result;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
