const OpenModule = (pContext, pInfo) => {
  pContext.commit("SET_MENU_OPENED", true);
  pContext.commit("SET_MODULE", { id: pInfo.module });
};

const OpenSubModule = (pContext, pInfo) => {
  pContext.commit("SET_SUBMODULE", { id: pInfo });
  pContext.commit("SET_SUBMODULE_ID", { id: pInfo.id });
};

export default {
  OpenModule,
  OpenSubModule,
};
