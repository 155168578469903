<template slot="subFilters">
  <div class="pharmacy-central-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />

        <RgSelectPharmacy
          id="pharmacyOrigin"
          v-model="form.pharmacyOriginId"
          byUser
          :unitHealthId="globalFilters.unitHealth"
          :userId="userLoginId"
          :typePharmacy="SATELLITE_PHARMACY_TYPE"
          :disabled="!globalFilters.unitHealth"
          :class="{ disable: !globalFilters.unitHealth }"
          label="Farmácia Origem"
          class="inputitem"
          @change="selectedPharmacyOrigin"
        />

        <RgSelectPharmacy
          id="pharmacyDestiny"
          v-model="form.pharmacyDestinyId"
          byUser
          :unitHealthId="globalFilters.unitHealth"
          :userId="userLoginId"
          :typePharmacy="CENTRAL_PHARMACY_TYPE"
          :disabled="!globalFilters.unitHealth"
          :class="{ disable: !globalFilters.unitHealth }"
          label="Farmácia Destino"
          class="inputitem"
          @change="selectedPharmacyDestiny"
        />

        <RgInput
          id="remittanceGuide"
          v-model="form.remittanceGuide"
          class="inputitem"
          label="Guia de Remessa"
          placeholder="Digite o número da guia"
          :maxlength="20"
        />

        <RgSuggestUser
          id="user"
          v-model="suggestUser"
          label="Usuário"
          placeholder="Todos"
          :clientId="clientLoginId"
          @selected="selectedUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { RgInput, RgSuggestUser } from "~tokio/primitive";

import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";

const FORM_FILTER = {
  productTypeId: null,
  productTypeName: null,
  pharmacyOriginId: null,
  pharmacyOriginName: null,
  pharmacyDestinyId: null,
  pharmacyDestinyName: null,
  remittanceGuide: null,
  userId: null,
  userName: null,
};

export default {
  name: "PharmacyCentralReversalReport",
  components: {
    RgInput,
    RgSuggestUser,
    RgSelectProductType,
    RgSelectPharmacy,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      suggestUser: null,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      userLoginName: "Login/GET_USER_NAME",
      clientLoginId: "Login/GET_USER_ID_CLIENT",
    }),
    getNameSmartTable() {
      return "PharmacyCentralReversalReport";
    },
  },

  watch: {},

  created() {
    this.CENTRAL_PHARMACY_TYPE = 1;
    this.SATELLITE_PHARMACY_TYPE = 2;
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "NOME", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Farmácia Origem", key: "ORIGEM", align: "left" },
      { name: "Farmácia Destino", key: "DESTINO", align: "left" },
      { name: "Tipo de Movimentação", key: "TIPO_MOVIMENTACAO", align: "left" },
      { name: "Data", key: "DATA", align: "right" },
      { name: "Usuário", key: "USUARIO", align: "left" },
      { name: "Guia de Remessa", key: "GUIA_REMESSA", align: "left" },
      { name: "Valor Unitário", key: "VALOR_UNITARIO", align: "right" },
      { name: "Valor Total", key: "VALOR_TOTAL", align: "right" },
    ]);
  },

  methods: {
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          unitHealthId: this.globalFilters.unitHealth,
          unitHealthName: this.globalFilters.unitHealthName,
          productId: Number(this.globalFilters.product),
          productName: this.globalFilters.productName,
          initialDate: this.globalFilters.initialDate,
          finalDate: this.globalFilters.finalDate,
          pharmacyOriginId: Number(this.form.pharmacyOriginId),
          pharmacyOriginName: this.form.pharmacyOriginName,
          pharmacyDestinyId: Number(this.form.pharmacyDestinyId),
          pharmacyDestinyName: this.form.pharmacyDestinyName,
          productTypeId: Number(this.form.productTypeId),
          productTypeName: this.form.productTypeName,
          remittanceGuide: this.form.remittanceGuide,
          userId: this.form.userId,
          userName: this.form.userName,
          columnsToPrint: this.columnsToPrint,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CENTRAL_REVERSAL",
          variables,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    selectedProductType(pType) {
      const hasSuggestion = pType && Object.keys(pType).length > 0;
      if (hasSuggestion) {
        this.form.productTypeName = pType.label;
      } else {
        this.form.productTypeName = "";
      }
    },

    selectedPharmacyOrigin(pPharmacy) {
      const hasSuggestion = pPharmacy && Object.keys(pPharmacy).length > 0;
      if (hasSuggestion) {
        this.form.pharmacyOriginName = pPharmacy.label;
      } else {
        this.form.pharmacyOriginName = "";
      }
    },

    selectedPharmacyDestiny(pPharmacy) {
      const hasSuggestion = pPharmacy && Object.keys(pPharmacy).length > 0;
      if (hasSuggestion) {
        this.form.pharmacyDestinyName = pPharmacy.label;
      } else {
        this.form.pharmacyDestinyName = "";
      }
    },

    selectedUser(pUser) {
      const hasSuggestion = pUser && Object.keys(pUser).length > 0;
      if (hasSuggestion) {
        this.form.userId = pUser?.source?.usu_id;
        this.form.userName = pUser?.source?.usu_nome;
      } else {
        this.form.userId = null;
        this.form.userName = "";
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.suggestUser = null;
    },
  },
};
</script>
