<template>
  <FoundationSearchExclusions :moduleType="2" :printHtml="printHtml" />
</template>

<script>
import FoundationSearchExclusions from "~tokio/foundation/pages/patient/foundation-search-exclusions/FoundationSearchExclusions.vue";
import PatientListHTML from "$exam/submodules/schedule/html/search-patient-exclude/PatientListHTML";

export default {
  name: "ExamSearchPatientExclude",
  components: {
    FoundationSearchExclusions,
  },

  computed: {
    printHtml() {
      return {
        patientsList: (info, snap) => this.actPatientListHTML(info, snap),
      };
    },
  },

  methods: {
    async actPatientListHTML(info, snap) {
      return PatientListHTML(info, snap);
    },
  },
};
</script>
