import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import ListServiceScale from "$exam/submodules/register/view/list-service-scale/ListServiceScale";

export default {
  path: "/exam/register/list-service-scale",
  name: "exam.register.list-service-scale",
  components: {
    default: ListServiceScale,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Cadastro", link: OpenSubModule("exam") },
      {
        label: "Escalas de Atendimento",
        link: "/exam/register/list-service-scale",
      },
    ],
  },
};
