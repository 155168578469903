<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.187567 7.12849C0.437656 6.8784 0.843132 6.8784 1.09322 7.12849C1.12352 7.15878 1.16311 7.19991 1.21182 7.25052C2.0007 8.07009 5.18129 11.3744 10 11.3744C12.6026 11.3744 14.3328 10.7856 15.6399 9.98995C16.7668 9.30404 17.5964 8.45952 18.4404 7.6004C18.5948 7.44329 18.7496 7.28569 18.9068 7.12849C19.1569 6.8784 19.5623 6.8784 19.8124 7.12849C20.0625 7.37857 20.0625 7.78405 19.8124 8.03414C19.6694 8.17717 19.5241 8.32537 19.3754 8.47706C18.5328 9.33666 17.5805 10.3081 16.3059 11.084C14.7805 12.0125 12.8161 12.6552 10 12.6552C4.60358 12.6552 1.07037 8.95695 0.297009 8.14746C0.250468 8.09875 0.213922 8.06049 0.187567 8.03414C-0.0625223 7.78405 -0.0625223 7.37857 0.187567 7.12849Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5079 4.92462C14.3428 5.23741 13.9554 5.35715 13.6426 5.19207C13.6309 5.18591 13.6153 5.17729 13.5958 5.16653C13.285 4.99528 11.9887 4.28088 10.0006 4.28088C8.91269 4.28088 8.20281 4.41116 7.68159 4.5786C7.23577 4.72182 6.91455 4.89427 6.56121 5.08395C6.49515 5.11941 6.42797 5.15548 6.35865 5.19207C6.04586 5.35715 5.65846 5.23741 5.49338 4.92462C5.3283 4.61183 5.44804 4.22444 5.76083 4.05936C5.8182 4.02908 5.87746 3.99715 5.93891 3.96403C6.29465 3.77235 6.72411 3.54094 7.28984 3.35919C7.96126 3.1435 8.80699 3.0001 10.0006 3.0001C12.3131 3.0001 13.8435 3.84142 14.191 4.03246C14.2117 4.04384 14.2282 4.05291 14.2404 4.05936C14.5532 4.22444 14.6729 4.61183 14.5079 4.92462Z"
      fill="white"
    />
    <path
      d="M10.0004 14.9221C9.68038 14.9221 9.4209 15.1816 9.4209 15.5017V16.8539C9.4209 17.174 9.68038 17.4335 10.0004 17.4335C10.3205 17.4335 10.58 17.174 10.58 16.8539V15.5017C10.58 15.1816 10.3205 14.9221 10.0004 14.9221Z"
      fill="white"
    />
    <path
      d="M6.54133 14.1471C6.2633 13.9886 5.90939 14.0854 5.75079 14.3634L4.97954 15.7157C4.82098 15.9937 4.9178 16.3476 5.19583 16.5062C5.47389 16.6648 5.8278 16.5679 5.98636 16.2899L6.75761 14.9377C6.91621 14.6596 6.81935 14.3057 6.54133 14.1471Z"
      fill="white"
    />
    <path
      d="M14.2481 14.3634C14.0895 14.0854 13.7355 13.9886 13.4575 14.1471C13.1795 14.3057 13.0827 14.6596 13.2413 14.9376L14.0125 16.2899C14.171 16.5679 14.5249 16.6648 14.803 16.5062C15.0811 16.3477 15.1779 15.9937 15.0193 15.7157L14.2481 14.3634Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "NotShow",
};
</script>
