<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnithealth from "./action/SearchUnithealth";

export default {
  name: "RgSelectUnithealthPerProceduresExams",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    submoduleId: {
      required: true,
      type: Number,
      default: 0,
    },
    procedureName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    procedureName(pValue, pPrev) {
      this.mValue = null;
      this.doSearch();
    },

    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const data = await SearchUnithealth(this.submoduleId, this.procedureName);

      this.valuesData = data.map((item) => {
        return { value: item.data, label: item.label };
      });
    },
    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
