<style src="./SubmoduleList.scss" lang="scss" scoped></style>
<template>
  <section class="module-list">
    <div
      v-for="(subModule, idx) in filteredSubmodules"
      :id="replaceString(subModule.name)"
      :key="idx"
      :class="{
        'menu-button--selected': idx === selectedSubModuleId,
        'inactive-button': subModule.active === false,
      }"
      :data-submodulename="subModule.className"
      :title="
        subModule.active === false
          ? 'Não implementado ou desativado temporariamente'
          : subModule.name
      "
      class="menu-button menu-button--medium-size"
      @click="selectingItem(idx)"
    >
      <component :is="subModule.iconName" class="icon" />
      <span class="module-name">
        {{ subModule.name }}
      </span>

      <div v-if="!subModule.link" class="have-children">
        <IconEllipsis class="symbol" />
      </div>
    </div>
  </section>
</template>

<script>
import { GetSubModulesFromJson } from "../menu-map/GetSubModulesFromJson";
import * as Icons from "~tokio/primitive/icon";
import { IconEllipsis } from "~tokio/primitive";
import { replaceSpecial } from "~utils/sanitize.js";

export default {
  name: "SubmoduleList",
  components: {
    ...Icons,
    IconEllipsis,
  },
  data() {
    return {
      subModules: [],
    };
  },
  computed: {
    selected() {
      return this.$store.state.MainMenu.selectedSubModule;
    },
    selectedModule() {
      return this.$store.state.MainMenu.selectedModule;
    },
    selectedSubModuleId() {
      return this.selected ? this.selected.id : null;
    },
    filteredSubmodules() {
      return this.subModules.filter((item) => {
        return item.active !== false && this.hasPermission(item.permission);
      });
    },
    isFavoriteMode() {
      return this.$store.state.Home.favorite_mode;
    },
  },
  watch: {
    selectedModule: function () {
      this.subModules = [];
      this.loadSubModules(this.selectedModule);
    },
  },
  mounted() {
    if (this.selectedModule) {
      this.loadSubModules(this.selectedModule);
    }
  },
  methods: {
    loadSubModules({ id }) {
      const subModules = GetSubModulesFromJson(id);
      this.subModules = subModules;

      if (this.selectedSubModuleId !== null) {
        this.selectingItem(this.selectedSubModuleId);
      }
    },
    selectingItem(pSubModuleId) {
      this.$emit("change", {
        id: pSubModuleId,
        ...this.subModules[pSubModuleId],
      });

      if (this.subModules[pSubModuleId].link) {
        if (!this.isFavoriteMode) {
          this.$emit("open-link", this.subModules[pSubModuleId].link);

          this.$store.commit(
            "Home/PUSH_RECENT_OPERATION",
            this.subModules[pSubModuleId],
          );
        } else {
          this.$store.commit(
            "Home/SET_NEW_FAVORITE",
            this.subModules[pSubModuleId],
          );
          this.$parent.closeMenu();
        }
      }
    },
    hasPermission(pPermission) {
      return this.$Permissions.global.has(pPermission);
    },
    replaceString(params) {
      return replaceSpecial(params).replaceAll(" ", "-").toLowerCase();
    },
  },
};
</script>
