import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleVoucherHTML = (pData) => {
  const logo = LocalStorage.getObject("client-logo");
  let html = ``;
  html += `
    <!DOCTYPE html>
    <html>

    <head>
        <meta charset="UTF-8">
        <style type="text/css">
            html,
            body,
            div,
            span,
            p {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: baseline;
            }

            body {
                width: 7cm;
                margin: .1cm;
                font-size: 8pt;
                font-family: Verdana, sans-serif;
            }

            div,
            p {
                width: 6.5cm;
                margin: .2cm auto;
                word-wrap: break-word;
                line-height: 120%;
            }

            .logo {
                width: 60px;
            }

            .strong {
                font-weight: bold;
                text-transform: uppercase;
            }

            .label {
                width: 2.2cm;
                display: inline-block;
						}

            .client-logo {
              display: grid;
              grid-template-rows: 1fr;
              grid-template-columns: 30% 50%;
              align-items: center;
            }

            @media print {
                body {
                    width: 7cm;
                    margin: .1cm;
                    font-size: 8pt;
                    font-family: Verdana, sans-serif;
                }

                div,
                p {
                    width: 6.5cm;
                    margin: .2cm auto;
                    word-wrap: break-word;
                    line-height: 120%;
                }

                .logo {
                    width: 60px;
                }

                .strong {
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .label {
                    width: 2.2cm;
                    display: inline-block;
                }

                @page {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }

            }
        </style>
    </head>


    <div class="client-logo">
      <div><img class="logo" src="${logo}"></div>
      <div>${pData.cli_nome}</div>
    </div>

    <body>
        <p>${pData.uns_nome}</p>

        <p>${pData.tlg_nome} ${pData.end_logradouro},
        ${pData.end_numero} ${pData.end_complemento} -
        ${pData.bai_nome} ${pData.mun_nome}</p>

        <hr />

        <p>Referência: ${pData.end_referencia}</p>

        <p>Internação: <b>${pData.int_data} às ${pData.int_hora}</b></p>

        <p>Paciente: ${pData.pes_nome}</p>

        <p>Nome Social: ${pData.pes_nome_social}</p>

        <p><span class="label">Prontuário:</span> ${pData.ppr_numero}</p>

        <p><span class="label">Prontuário Único:</span> ${pData.pac_prontuario_unico}</p>

        <p><span class="label">Cartão SUS:</span> ${pData.crs_numero}</p>

        <p><span class="label">Local de Espera:</span> ${pData.ile_nome}</p>

        <p><span class="label">Profissional Responsável:</span>
        <span>${pData.profissional_responsavel}</span></p>

        <p>CID: ${pData.cid}</p>

        <p>Procedimento: ${pData.procedimento}</p>

        <p>Obs.: ${pData.observacoes}</p>

        <hr />

        <p>Inserido na Fila: <b>${pData.data_inserido_fila}</b></p>
        <p>por: <b>${pData.usuario_inserido_fila}</b></p>

        <p>Regulado na Fila: <b>${pData.data_regulado_fila}</b></p>
        <p>por: <b>${pData.usuario_regulado_fila}</b></p>

        <p>Agendado na Fila: <b>${pData.data_agendado_fila}</b></p>
        <p>por: <b>${pData.usuario_agendado_fila}</b></p>

			</body>

    </html>
		`;

  return html;
};

export default ScheduleVoucherHTML;
