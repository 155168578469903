import GetAppointmentReport from "./GetAppointmentReport";
import GetAppointmentPeriodReport from "./GetAppointmentPeriodReport";
import GetAppointmentDisabledPeriodsReport from "./GetAppointmentDisabledPeriodsReport";
import GetAppointmentHistoryScheduleReport from "./GetAppointmentHistoryScheduleReport";
import GetAppointmentExcludedReport from "./GetAppointmentExcludedReport";
import GetAppointmentSearchScaleExcludedReport from "./GetAppointmentSearchScaleExcludedReport";
import GetAppointmentUserProductivityReport from "./GetAppointmentUserProductivityReport";
import GetAppointmentOccupationReport from "./GetAppointmentOccupationReport";
import GetAppointmentProfessionalProductivityReport from "./GetAppointmentProfessionalProductivityReport";
import GetAppointmentQueueListReport from "./GetAppointmentQueueListReport";
import GetAppointmentPatientsQueueReport from "./GetAppointmentPatientsQueueReport";
import GetAppointmentPatientsScheduledQueueReport from "./GetAppointmentPatientsScheduledQueueReport";
import GetAppointmentPatientsExcludedQueueReport from "./GetAppointmentPatientsExcludedQueueReport";
import GetAppointmentPatientsSentPepByEffectedReport from "./GetAppointmentPatientsSentPepByEffectedReport";
import GetAppointmentPatientsExternallyScheduledReport from "./GetAppointmentPatientsExternallyScheduledReport";
import GetAppointmentPatientsPendencyQueueReport from "./GetAppointmentPatientsPendencyQueueReport";

export default {
  GET_APPOINTMENT_REPORT: GetAppointmentReport,
  GET_APPOINTMENT_PERIOD_REPORT: GetAppointmentPeriodReport,
  GET_APPOINTMENT_DISABLED_PERIODS_REPORT: GetAppointmentDisabledPeriodsReport,
  GET_APPOINTMENT_HISTORY_SCHEDULE_REPORT: GetAppointmentHistoryScheduleReport,
  GET_APPOINTMENT_EXCLUDED_REPORT: GetAppointmentExcludedReport,
  GET_APPOINTMENT_SEARCH_SCALE_EXCLUDED_REPORT: GetAppointmentSearchScaleExcludedReport,
  GET_APPOINTMENT_USER_PRODUCTIVITY_REPORT: GetAppointmentUserProductivityReport,
  GET_APPOINTMENT_OCCUPATION_REPORT: GetAppointmentOccupationReport,
  GET_APPOINTMENT_PROFESSIONAL_PRODUCTIVITY_REPORT: GetAppointmentProfessionalProductivityReport,
  GET_APPOINTMENT_QUEUE_LIST_REPORT: GetAppointmentQueueListReport,
  GET_APPOINTMENT_PATIENTS_QUEUE_REPORT: GetAppointmentPatientsQueueReport,
  GET_APPOINTMENT_PATIENTS_SCHEDULED_QUEUE_REPORT: GetAppointmentPatientsScheduledQueueReport,
  GET_APPOINTMENT_PATIENTS_EXCLUDED_QUEUE_REPORT: GetAppointmentPatientsExcludedQueueReport,
  GET_APPOINTMENT_PATIENTS_EXTERNALLY_SCHEDULED_REPORT: GetAppointmentPatientsExternallyScheduledReport,
  GET_APPOINTMENT_PATIENTS_SENT_PEP_BY_EFFECTED_REPORT: GetAppointmentPatientsSentPepByEffectedReport,
  GET_APPOINTMENT_PATIENTS_PENDENCY_QUEUE_REPORT: GetAppointmentPatientsPendencyQueueReport,
};
