import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchDailyEmergencyReport(
    $uns_id: Int
    $uns_nome: String
    $initialDate: String
    $finalDate: String
    $limit: Int
    $offset: Int
    $toCsv: Boolean
    $toPrint: Boolean
    $toExcel: Boolean
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchDailyEmergencyReport(
      uns_id: $uns_id
      uns_nome: $uns_nome
      initialDate: $initialDate
      finalDate: $finalDate
      limit: $limit
      offset: $offset
      toCsv: $toCsv
      toPrint: $toPrint
      toExcel: $toExcel
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        IDADE
        TELEFONES
        ENDERECO
        BAIRRO
        CIDADE
        NOME_DA_MAE
        UNIDADE_DE_SAUDE
        DATA_ATENDIMENTO
        HORA_ATENDIMENTO
        SETOR
        USUARIO
      }
    }
  }
`;

export default async (
  { commit },
  {
    uns_id,
    uns_nome,
    initialDate,
    finalDate,
    toCsv,
    toPrint,
    toExcel,
    limit,
    offset,
    columnsToPrint,
  },
) => {
  try {
    const variables = {
      uns_id,
      uns_nome,
      toCsv,
      toPrint,
      initialDate,
      finalDate,
      toExcel,
      limit,
      offset,
      columnsToPrint,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchDailyEmergencyReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
