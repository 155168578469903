import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query OccupationSynonyms(
    $soc_nome: String
    $ocp_codigo: String
    $noPermission: Boolean
    $limit: Int
  ) {
    occupationSynonyms(
      soc_nome: $soc_nome
      ocp_codigo: $ocp_codigo
      limit: $limit
      noPermission: $noPermission
    ) {
      rows {
        soc_id
        soc_nome
        ocp_codigo
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    fetchPolicy: "no-cache",
    variables,
  }).catch((err) => {
    console.error(err);
  });
  return data.occupationSynonyms;
};
