import Request from "~common/request";
import { SERVER_CONFIG } from "~utils/Config";

export default (pToken) => {
  const request = new Request(SERVER_CONFIG);
  return request.get(
    "/access-control/get-login-info",
    {},
    { authorization: pToken },
  );
};
