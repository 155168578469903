import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchTransferOccupation(
    $proceduresList: String
    $searchQueryCod: String
    $searchQueryName: String
    $limit: Int
  ) {
    SearchTransferOccupation(
      proceduresList: $proceduresList
      searchQueryCod: $searchQueryCod
      searchQueryName: $searchQueryName
      limit: $limit
    ) {
      count
      rows {
        NO_OCUPACAO
        CO_OCUPACAO
      }
    }
  }
`;

export default async ({ variables }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    }).catch((err) => {
      console.error(err);
    });
    return data.SearchTransferOccupation;
  } catch (err) {
    return err.graphQLErrors;
  }
};
