<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.26161 19.7483C8.61044 19.4127 8.6105 18.8684 8.26161 18.5327L5.05023 15.4431L14.5803 15.443C15.0736 15.443 15.4736 15.0582 15.4736 14.5835C15.4736 14.1088 15.0736 13.7239 14.5803 13.7239L5.05005 13.724L8.26149 10.6341C8.61038 10.2985 8.61038 9.75419 8.26149 9.41851C7.91259 9.08283 7.34697 9.08283 6.99808 9.41851L2.26165 13.9757C2.09405 14.1369 1.99995 14.3555 1.99995 14.5835C1.99995 14.8114 2.09411 15.0301 2.26165 15.1913L6.9982 19.7484C7.34709 20.084 7.91271 20.084 8.26161 19.7483Z"
      fill="white"
    />
    <path
      d="M11.7384 0.251686C11.3896 0.587311 11.3895 1.13158 11.7384 1.46726L14.9498 4.55693L5.41974 4.55699C4.92636 4.55699 4.52637 4.94183 4.52637 5.41653C4.52637 5.89123 4.92636 6.27608 5.41974 6.27608L14.95 6.27602L11.7385 9.36592C11.3896 9.70154 11.3896 10.2458 11.7385 10.5815C12.0874 10.9172 12.653 10.9172 13.0019 10.5815L17.7384 6.02429C17.9059 5.86309 18.0001 5.64448 18.0001 5.41653C18.0001 5.18858 17.9059 4.96991 17.7384 4.80872L13.0018 0.25163C12.6529 -0.0839941 12.0873 -0.0839954 11.7384 0.251686Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "EmployeeTransfer",
};
</script>
