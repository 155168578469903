<style src="./RgInputNumber.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model.lazy="inputValue"
            v-money="maskFormat"
            class="input-number"
            :readonly="readonly"
            :maxlength="maxlength"
            type="text"
            :disabled="disabled"
            :class="{ 'on-error': error.length > 0 }"
            @blur="blur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";

import { VMoney } from "v-money";

export default {
  name: "RgInputNumber",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    money: VMoney,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      default: "Número",
    },
    format: {
      default: null,
    },
    readonly: {
      default: false,
    },
    disabled: {
      default: false,
    },
    maxlength: {
      type: Number,
    },
    notAcceptNegatives: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      maskFormat: {},
      defaultFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {},
  mounted() {
    this.maskFormat = Object.assign({}, this.defaultFormat, this.format);
  },
  methods: {
    blur() {
      this.$emit("blur", this.value);
      this.inputValue = this.inputValue.replace(/[^\d]+/g, "");
      this.validate();
    },
    formatNumber(pValue) {
      return pValue.replace(/[.]/g, "");
    },
  },
};
</script>
