<style src="./ModalLaboratoryExam.scss" lang="scss" scoped></style>
<template lang="html">
  <aside class="modal-effective-exam">
    <div slot="header" class="confirm-header" />

    <div slot="body" class="confirm-body">
      <div>
        <span class="smalltitle">Dados do Profissional</span>
        <div class="row">
          <div class="col-8 cell-info">
            <span class="title">Profissional solicitante</span>
            <span class="value">{{
              examInfo.leb_profissional_solicitante
                ? examInfo.leb_profissional_solicitante
                : "NÃO INFORMADO"
            }}</span>
          </div>
          <div class="col-1 cell-info">
            <span class="title">UF:</span>
            <span class="value">{{
              examInfo.leb_uf_profissional
                ? examInfo.leb_uf_profissional
                : "NÃO INFORMADO"
            }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-4 cell-info">
            <span class="title"> CPF: </span>
            <span class="value">
              {{
                examInfo.leb_cpf_profissional
                  ? examInfo.leb_cpf_profissional
                  : "NÃO INFORMADO"
              }}</span
            >
          </div>

          <div class="col-4 cell-info">
            <span class="title"> Número do Conselho:</span>
            <span class="value">{{
              examInfo.leb_sigla_conselho
                ? examInfo.leb_sigla_conselho
                : "NÃO INFORMADO"
            }}</span>
          </div>

          <div class="col-4 cell-info">
            <span class="title"> Conselho: </span>
            <span class="value">{{
              examInfo.leb_numero_conselho
                ? examInfo.leb_numero_conselho
                : "NÃO INFORMADO"
            }}</span>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 25px">
        <span class="smalltitle">Dados do Paciente :</span>
        <span style="background-color: #ddd" class="smalltitle">
          {{ examInfo.leb_nome_paciente }}
        </span>
        <div class="row">
          <div class="col-1 cell-info">
            <label class="input-title">Peso</label>
            <RgInput
              v-model="form.peso"
              label=""
              placeholder="Digite"
              class="row-field mt-0 input"
            />
          </div>
          <div class="col-1 cell-info">
            <label class="input-title">Altura</label>
            <RgInput
              v-model="form.altura"
              label=""
              placeholder="Digite"
              class="row-field mt-0 input"
            />
          </div>
          <div class="col-10 cell-info">
            <label class="input-title">Medicamentos</label>
            <textarea
              v-model="form.medicamentos"
              class="txtarea-align"
              maxlength="255"
            />
          </div>
        </div>
      </div>

      <div v-if="isWoman" class="row" style="margin-bottom: 20px">
        <!-- Radio  -->
        <div class="col-2 cell-info">
          <label class="input-title">Menstruação</label>
          <div style="margin-top: 20px">
            <label class="radio">
              <input
                v-model="form.menstruada"
                type="radio"
                value="1"
                class="radio-input"
              />
              <span class="radion-span">Sim</span>
            </label>
            <label class="radio">
              <input
                v-model="form.menstruada"
                type="radio"
                value="0"
                class="radio-input"
                checked
              />
              <span class="radion-span">Não</span>
            </label>
          </div>
        </div>
        <!-- Fim Radio -->
        <div class="col-10 cell-info">
          <label class="input-title"> Informações Menstruação</label>
          <textarea
            v-model="form.info_menstruada"
            class="txtarea-align"
            maxlength="255"
          />
        </div>
      </div>

      <span class="smalltitle">Procedimentos: </span>
      <div class="procedures-list">
        <div class="row exam">
          <div class="col-7 cell-info">
            <span class="title"> Nome</span>
            <span class="value">{{ procedureName }}</span>
          </div>
          <div class="col-3 cell-info">
            <span class="title"> Código</span>
            <span class="value">{{ procedureCode }}</span>
          </div>
          <div class="col-2 cell-info">
            <span class="title">CID</span>
            <span class="value">{{
              form.cid ? form.cid : "NÃO INFORMADO"
            }}</span>
          </div>

          <div class="col-9 cell-info last-col">
            <span class="title" style="padding-bottom: 0px">
              Objeto de Análise
            </span>
            <ExamVueSelectBox
              v-model="objectAnalise"
              :limit-object="analysisObject"
            />
          </div>
          <div class="col-3 cell-info last-col">
            <span class="title">Material de Coleta</span>
            <div v-if="showEditCollectMaterial" style="margin-bottom: 10px">
              <RgInput
                v-model="collectMaterial"
                style="background: #f1f1f1"
                label=""
                placeholder="Ex: Sangue, Urina..."
                class="row-field mt-0 input"
              />
            </div>
            <div v-else>
              <span class="value">{{ biologicalMaterial }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { RgInput } from "~tokio/primitive";

import EXAM_TYPES from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-type/GetExamTypes";
import ExamVueSelectBox from "$exam/common/component/exam-vue-select-box/ExamVueSelectBox";

export default {
  name: "ModalLaboratoryExam",
  components: {
    RgInput,
    ExamVueSelectBox,
  },
  filters: {
    /** @param {String} value */
    uppercase(value) {
      return !value ? "" : value.toUpperCase();
    },
  },
  props: {
    examInfo: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      showEditCollectMaterial: false,
      collectMaterial: "",
      objectAnalise: "",
      form: {
        peso: "",
        altura: "",
        menstruada: 0,
        info_menstruada: "",
        medicamentos: "",
      },
    };
  },
  computed: {
    procedureCode() {
      return this.examInfo.bioslabInfo.lle_codigo_sigtap
        ? this.examInfo.bioslabInfo.lle_codigo_sigtap
        : "NÃO INFORMADO";
    },
    analysisObject() {
      return this.examInfo.biosLabInfo ? this.examInfo.biosLabInfo : {};
    },
    biologicalMaterial() {
      return this.examInfo.bioslabInfo.lle_material
        ? this.examInfo.bioslabInfo.lle_material
        : "NÃO INFORMADO";
    },
    procedureName() {
      return this.examInfo.bioslabInfo.lle_procedimentos
        ? this.examInfo.bioslabInfo.lle_procedimentos
        : "NÃO INFORMADO";
    },
    isWoman() {
      return this.examInfo.leb_sexo_paciente === "F" ? 1 : 0;
    },
  },
  watch: {},
  methods: {
    isBoletim(pItem) {
      return pItem.examType === EXAM_TYPES.EMERGENCIA;
    },
    prepareDataToBioslab() {
      return {
        bioslabLaboratoryExam: {
          leb_menstruada: this.form.menstruada,
          leb_informacoes_menstruacao: this.form.info_menstruada,
          leb_medicamentos: this.form.medicamentos,
          leb_cid: this.examInfo.exe_codigo_cid,
          leb_peso: this.form.peso,
          leb_altura: this.form.altura,
          leb_cpf_profissional: this.examInfo.leb_cpf_profissional,
          leb_uf_profissional: this.examInfo.leb_uf_profissional,
          leb_profissional_solicitante: this.examInfo
            .leb_profissional_solicitante,
          leb_sigla_conselho: this.examInfo.leb_sigla_conselho,
          leb_numero_conselho: this.examInfo.leb_numero_conselho,
        },
        bioslabLaboratoryPatientExams: {
          lpe_objeto_analise: this.objectAnalise
            .map((item) => item.label)
            .join(", "),
          lpe_material_biologico: this.biologicalMaterial,
        },
      };
    },
    prepareDataToCreateForBioslab() {
      return {
        bioslabLaboratoryExam: {
          leb_menstruada: this.form.menstruada,
          leb_informacoes_menstruacao: this.form.info_menstruada,
          leb_medicamentos: this.form.medicamentos,
          leb_peso: this.form.peso,
          leb_altura: this.form.altura,
        },
        bioslabLaboratoryPatientExams: {
          lpe_objeto_analise: this.objectAnalise,
          lpe_material_biologico: this.collectMaterial,
        },
      };
    },
    infoForm() {
      return this.form;
    },
  },
};
</script>
