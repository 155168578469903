<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchEthnicity from "./action/SearchEthnicity";

export default {
  name: "RgComboboxEthnicity",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const data = await SearchEthnicity();

      const NAO_INFORMADO = 6;

      const filterRace = data.filter((item) => {
        return item.etn_id !== NAO_INFORMADO;
      });

      filterRace.forEach((item) => {
        this.mData.push({ value: item.etn_id, text: item.etn_raca_ab });
      });

      return this.mData;
    },
    getMData() {
      return this.mData;
    },
  },
};
</script>
