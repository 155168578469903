<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxTypePerson",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Tipo de Fornecedor",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data: () => {
    return {
      mData: [
        { value: "1", text: "FÍSICA" },
        { value: "2", text: "JURÍDICA" },
      ],
    };
  },
};
</script>
