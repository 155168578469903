import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamPatientsQueueReport(
    $procedureId: Int
    $subprocedureId: Int
    $pfi_id: Int
    $usu_id: Int
    $pes_id: Int
    $uns_id: Int
    $employeeId: Int
    $cli_id: Int
    $procedureName: String
    $subprocedureName: String
    $pfi_nome: String
    $usu_nome: String
    $situation: String
    $inclusionPeriodInitial: String
    $inclusionPeriodFinal: String
    $patientName: String
    $gender: Int
    $startingAge: String
    $finalAge: String
    $uns_nome: String
    $employeeName: String
    $requestDateInitial: String
    $requestDateFinal: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchExamPatientsQueueReport(
      procedureId: $procedureId
      subprocedureId: $subprocedureId
      pfi_id: $pfi_id
      pfi_nome: $pfi_nome
      situation: $situation
      usu_id: $usu_id
      pes_id: $pes_id
      uns_id: $uns_id
      employeeId: $employeeId
      cli_id: $cli_id
      procedureName: $procedureName
      subprocedureName: $subprocedureName
      usu_nome: $usu_nome
      inclusionPeriodInitial: $inclusionPeriodInitial
      inclusionPeriodFinal: $inclusionPeriodFinal
      patientName: $patientName
      gender: $gender
      startingAge: $startingAge
      finalAge: $finalAge
      uns_nome: $uns_nome
      employeeName: $employeeName
      requestDateInitial: $requestDateInitial
      requestDateFinal: $requestDateFinal
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        POSICAO
        PROCEDIMENTO
        SUB_PROCEDIMENTO
        PACIENTE
        CNS
        PRONTUARIO_UNIDADE
        TEMPO_FILA
        UNIDADE_INCLUSAO
        DATA_INCLUSAO
        UNIDADE_SOLICITACAO
        PROFISSIONAL_SOLICITACAO
        SITUACAO
        PRIORIDADE
        DATA_NASCIMENTO
        NOME_MAE
        SEXO
        IDADE
        TELEFONE
        MUNICIPIO_ESTADO
        USUARIO_INCLUSAO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamPatientsQueueReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
