import FilterProvider from "./provider/FilterProvider";
import CleanProvider from "./provider/CleanProvider";
import SelectProvider from "./provider/SelectProvider";

import FilterInventory from "./inventory/FilterInventory";
import CleanInventory from "./inventory/CleanInventory";
import SelectInventory from "./inventory/SelectInventory";

export default {
  ...FilterProvider,
  ...CleanProvider,
  ...SelectProvider,
  ...FilterInventory,
  ...CleanInventory,
  ...SelectInventory,
};
