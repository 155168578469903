<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import { SearchEthnicity } from "./action";

export default {
  name: "RgSelectEthnicity",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Etnia",
    },
    loadData: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ethnicity: [],
    };
  },
  watch: {
    ethnicity: function (val) {
      this.updateOptions();
    },
    loadData: {
      handler(pValue) {
        if (pValue === this.ETHNICITY_INDIAN) {
          this.getEthnicity();
        }
      },
    },
  },
  created() {
    this.ETHNICITY_INDIAN = 5;
  },
  /*   mounted() {
    this.getEthnicity();
  }, */
  methods: {
    getData() {
      return this.ethnicity;
    },
    async getEthnicity() {
      const result = await SearchEthnicity({ ens_descricao: "" });

      this.ethnicity = Array.from(
        result.map((element) => {
          return {
            label: element.ens_descricao,
            value: element.ens_id,
            ...element,
          };
        }),
      );
    },
  },
};
</script>
