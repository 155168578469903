<template>
  <div v-show="show" class="modal-new-complexity">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-new-complexity-header">
        <span class="title">
          {{ isEdition ? "Editar" : "Adicionar" }} Complexidade ao Procedimento
          Selecionado
        </span>
      </div>
      <div slot="body">
        <FormBase title="Dados do Procedimento">
          <div v-if="isBatch">
            <SmartTable
              ref="smartTable"
              name="ListHistoryImport"
              :columns="COLUMNS"
              :body="listHistory"
              :total="Number(listHistory.length)"
              :show-pagination="false"
              removeBtnColumns
              dontSelect
              :dynamicHeight="tableHeight"
              separatorSeeMore=";"
              class="smart-table"
            />
          </div>
          <div v-if="!isBatch" class="complexity-info">
            <div class="container-info">
              <span class="content">
                Procedimento:

                <strong>
                  {{ dataComplexity.procedureCode || "-" }} -
                  {{ dataComplexity.procedureName || "-" }}
                </strong>
              </span>
            </div>
            <div class="container-data">
              <span class="content">
                Instrumento:
                <strong> {{ dataComplexity.instrument || "-" }} </strong>
              </span>
              <span class="content">
                Quantidade:
                <strong> {{ dataComplexity.quantity || "-" }} </strong>
              </span>
            </div>
            <div class="container-info">
              <span class="content">
                CID-10:
                <strong>
                  {{ dataComplexity.cid || "-" }}
                </strong>
              </span>
              <span class="content">
                Ocupação:
                <strong> {{ dataComplexity.occupation || "-" }} </strong>
              </span>
            </div>
          </div>

          <div class="build-complexity">
            <span class="subtitle">Complexidade</span>
            <hr />
            <Tooltip
              class="tooltip"
              startOpen
              message="Para atribuir/adicionar uma complexidade para o procedimento, basta clicar em uma das opções disponíveis nas pílulas"
            >
              <IconLightBulbHelper slot="icon" />
            </Tooltip>

            <RgRadioCustom
              id="validations"
              class="radio-custom-complexity"
              v-bind="propsRadioCustom"
              :disabled="isDisabledRadioCustom"
              :class="{ disable: isDisabledRadioCustom }"
              @input="onInputRadioCustom"
            />
          </div>
        </FormBase>
        <div slot="footer" class="modal-new-complexity-footer">
          <RgCancelButton medium label="Cancelar" @click="close" />
          <RgSaveButton
            medium
            label="Salvar"
            :disabledButton="isDisabledRadioCustom || isDisabledButton"
            :disabled="isDisabledRadioCustom || isDisabledButton"
            :class="{ disable: isDisabledRadioCustom || isDisabledButton }"
            @click="saveComplexity"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  Tooltip,
  IconLightBulbHelper,
  RgRadioCustom,
  RgSaveButton,
  RgCancelButton,
} from "~tokio/primitive";
import { FormBase, SmartTable } from "~tokio/foundation";

const FORM = {
  idCustom: null,
  complexity: null,
  procedureCode: null,
  procedureName: null,
  cid: null,
  instrument: null,
  quantity: null,
  occupation: null,
};

export default {
  name: "ModalNewComplexity",
  components: {
    RgBaseModal,
    FormBase,
    Tooltip,
    IconLightBulbHelper,
    RgRadioCustom,
    SmartTable,
    RgSaveButton,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isEdition: {
      type: Boolean,
      default: false,
    },
    selectedLine: {
      type: Array,
      default: () => [],
    },
    reLoader: Function,
  },
  data() {
    return {
      dataComplexity: this.$utils.obj.DeepCopy(FORM),
      listHistory: [],
      isDisabledRadioCustom: false,
      isDisabledButton: false,
    };
  },
  computed: {
    isBatch() {
      return this.selectedLine?.length > 1;
    },
    propsRadioCustom() {
      const list = [
        { title: "Não se Aplica", id: "0" },
        { title: "Atenção Básica", id: "1" },
        { title: "Média", id: "2" },
        { title: "Alta", id: "3" },
      ];

      let value = "0";
      if (this.selectedLine?.length === 1) {
        value = this.dataComplexity.complexity;
        this.disabledRadioCustom(false);
      }
      if (this.selectedLine?.length > 1) {
        const equalsComplexity = !this.listHistory.every(
          (element, i, array) => {
            return array.find((element2) => {
              return element2.complexityCode !== element.complexityCode;
            });
          },
        );

        if (equalsComplexity) {
          value = this.dataComplexity.complexity;
          this.disabledRadioCustom(false);
          this.disabledButton(false);
        } else {
          value = null;
          this.disabledRadioCustom(true);
          this.disabledButton(true);
        }
      }

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    tableHeight() {
      return window.innerHeight <= 636 ? 150 : 300;
    },
  },
  watch: {
    show: {
      handler(pValue) {
        if (pValue) {
          this.fillData();
        }
        if (!pValue) {
          this.close();
        }
      },
    },
  },
  created() {
    this.COLUMNS = [
      {
        name: "Instrumento",
        key: "instrument",
        align: "left",
        seeMore: "true",
      },
      {
        name: "Código",
        key: "procedureCode",
        align: "right",
      },
      {
        name: "Procedimento",
        key: "procedureName",
        align: "left",
      },
      {
        name: "Quantidade",
        key: "quantity",
        align: "right",
      },
      { name: "CID-10", key: "cid", align: "left", seeMore: "true" },
      {
        name: "Ocupação",
        key: "occupation",
        align: "left",
        active: "true",
        seeMore: "true",
      },
    ];
  },
  methods: {
    fillData() {
      if (this.selectedLine.length === 1) {
        this.dataComplexity = {
          idCustom: this.selectedLine[0]?.idCustom,
          complexity: this.selectedLine[0]?.complexityCode,
          procedureCode: this.selectedLine[0].procedureCode,
          procedureName: this.selectedLine[0].procedureName,
          cid: this.selectedLine[0].cid,
          instrument: this.selectedLine[0].instrument,
          quantity: this.selectedLine[0].quantity,
          occupation: this.selectedLine[0].occupation,
        };
      } else if (this.selectedLine.length > 1) {
        this.listHistory = this.selectedLine;
        this.dataComplexity.complexity = this.listHistory[0]?.complexityCode;
      } else {
        this.dataComplexity = this.$utils.obj.DeepCopy(FORM);
      }
    },

    onInputRadioCustom(item) {
      if (item) {
        this.disabledButton(false);
        this.dataComplexity.complexity = item.id;
      } else {
        this.disabledButton(true);
      }
    },
    disabledRadioCustom(value) {
      this.isDisabledRadioCustom = value;
    },
    disabledButton(value) {
      this.isDisabledButton = value;
    },
    async saveComplexity() {
      if (!this.isEdition) {
        try {
          this.$loader.start();

          const variables = this.dataComplexity.procedureCode
            ? {
                procedureComplexity: {
                  type: "CRIAR",
                  com_tp_complexidade: this.dataComplexity.complexity.toString(),
                  complexity: [
                    {
                      com_co_procedimento: this.dataComplexity.procedureCode,
                    },
                  ],
                },
              }
            : {
                procedureComplexity: {
                  type: "CRIAR",
                  com_tp_complexidade: this.dataComplexity.complexity.toString(),
                  complexity: this.listHistory.map((element) => {
                    return {
                      com_co_procedimento: element.procedureCode,
                    };
                  }),
                },
              };

          await this.$store.dispatch("Billing/Complexity/SAVE_COMPLEXITY", {
            variables,
          });

          this.dataComplexity.procedureCode
            ? this.$toaster.success(
                "A complexidade do procedimento foi adicionada com sucesso",
              )
            : this.$toaster.success(
                "A complexidade dos procedimentos foram adicionadas com sucesso",
              );

          this.close();
        } catch (error) {
          this.$toaster.error(error, "Erro ao Adicionar Complexidade");
        } finally {
          this.$loader.finish();
        }
      } else {
        try {
          this.$loader.start();

          const variables = this.dataComplexity.procedureCode
            ? {
                procedureComplexity: {
                  type: "ALTERAR",
                  com_tp_complexidade: this.dataComplexity.complexity.toString(),
                  complexity: [
                    {
                      com_id: this.dataComplexity.idCustom
                        ? Number(this.dataComplexity.idCustom)
                        : undefined,
                      com_co_procedimento: this.dataComplexity.procedureCode,
                    },
                  ],
                },
              }
            : {
                procedureComplexity: {
                  type: "ALTERAR",
                  com_tp_complexidade: this.dataComplexity.complexity.toString(),
                  complexity: this.listHistory.map((element) => {
                    return {
                      com_id: element.idCustom
                        ? Number(element.idCustom)
                        : undefined,
                      com_co_procedimento: element.procedureCode,
                    };
                  }),
                },
              };

          await this.$store.dispatch("Billing/Complexity/SAVE_COMPLEXITY", {
            variables,
          });

          this.dataComplexity.procedureCode
            ? this.$toaster.success(
                "A complexidade do procedimento foi alterada com sucesso",
              )
            : this.$toaster.success(
                "A complexidade dos procedimentos foram alteradas com sucesso",
              );

          this.close();
        } catch (error) {
          this.$toaster.error(error, "Erro ao Editar Complexidade");
        } finally {
          this.reLoader();
        }
      }
    },
    close() {
      this.$emit("close");
      this.dataComplexity = this.$utils.obj.DeepCopy(FORM);
      this.isDisabledButton = false;
      this.isDisabledRadioCustom = false;
      this.listHistory = [];
    },
  },
};
</script>
