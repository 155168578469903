<template lang="html">
  <div v-if="show" class="modal-confirm-field-clearing">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        Deseja realmente limpar os campos preenchidos?
      </div>

      <div slot="body" class="body">
        <span>Essa operação não poderá ser desfeita</span>
      </div>

      <div slot="footer" class="footer">
        <RgCleanButton
          id="clean-modal-confirm"
          large
          class="yes"
          @click="getYes"
        />
        <MediumButton
          id="continue-modal-confirm"
          label="Continuar Cadastro"
          @click="getOut"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCleanButton, MediumButton } from "~tokio/primitive";

export default {
  name: "ModalConfirmFieldClearing",
  components: {
    RgBaseModal,
    RgCleanButton,
    MediumButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default:
        "Deseja realmente limpar todos os campos preenchidos nesse cadastro?",
    },
  },
  methods: {
    getYes() {
      this.$emit("getYes", 1);
      this.close();
    },

    getOut() {
      this.$emit("getOut", 2);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
