<template>
  <div v-if="show" class="modal-confirm-transfer">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="modal-title">
          <span class="title">Confirmação da Transferência</span>
        </div>
      </div>

      <div slot="body" class="body">
        <span class="question">
          {{ confirmationPhrase }}
          <strong class="data">
            {{ getNamePatient }}
          </strong>
          ?
        </span>

        <div class="box light">
          <span class="title">ORIGEM</span>
          <span class="label-text">
            Unidade de Saúde:
            <strong> {{ getValue("origin", "uns_nome") }} </strong>
          </span>
          <span class="label-text">
            Setor:
            <strong> {{ getValue("origin", "set_nome") }} </strong>
          </span>
          <span class="label-text">
            Local de Atendimento:
            <strong> {{ getValue("origin", "lca_nome") }} </strong>
          </span>
          <span class="label-text">
            Leito:
            <strong>{{ getValue("origin", "inl_nome") }}</strong>
          </span>
        </div>

        <div class="divider unselect">
          <IconArrowRight class="exchange-svg" />
        </div>

        <div class="box dark">
          <span class="title">DESTINO</span>
          <span class="label-text">
            Unidade de Saúde:
            <strong> {{ getValue("destiny", "uns_nome") }} </strong>
          </span>
          <span class="label-text">
            Setor:
            <strong> {{ getValue("destiny", "set_nome") }} </strong>
          </span>
          <span class="label-text">
            Local de Atendimento:
            <strong> {{ getValue("destiny", "lca_nome") }} </strong>
          </span>
          <span class="label-text">
            Leito:
            <strong> {{ getValue("destiny", "inl_nome") }} </strong>
          </span>
        </div>

        <div v-show="status === 'RESERVA'" class="checkbox-reservation">
          <input
            v-model="confirmHospitalization"
            type="checkbox"
            class="checkbox"
            value="1"
          />
          <label class="label-text">
            Deseja efetivar a internação do paciente?
          </label>
          <InformationTooltip
            :message="'Ao efetivar a internação do paciente a situação do leito muda de reserva para ocupado.'"
            class="tooltip"
          />
        </div>
      </div>

      <div slot="footer" class="footer">
        <div class="action unselect">
          <RgSaveButton
            id="save-btn"
            ref="save"
            :class="{ disable: isSaving }"
            :disabled="isSaving"
            title="Salvar"
            medium
            type="button"
            class="save"
            @click="saveForm"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>
<script>
import {
  RgBaseModal,
  RgSaveButton,
  IconArrowRight,
  InformationTooltip,
} from "~tokio/primitive";

export default {
  name: "ModalHistoryTransfer",
  components: {
    RgBaseModal,
    RgSaveButton,
    IconArrowRight,
    InformationTooltip,
  },
  props: {
    information: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    transfer: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      confirmHospitalization: false,
      modalFlowToBusy: false,
      form: {
        isl_id: null,
      },
      isSaving: false,
    };
  },
  computed: {
    confirmationPhrase() {
      const existsInformation = this.information && this.information.origin;
      const namePatient =
        this.information.origin.nome_paciente ||
        this.information.origin.paciente;

      return existsInformation &&
        (namePatient === "INDIGENTE" ||
          namePatient === "PACIENTE NÃO IDENTIFICADO")
        ? "Deseja realmente transferir "
        : "Deseja realmente transferir o(a) paciente  ";
    },
    getNamePatient() {
      const existsInformation = this.information && this.information.origin;
      const namePatient =
        this.information.origin.nome_paciente ||
        this.information.origin.paciente;
      return existsInformation && namePatient === "INDIGENTE"
        ? "PACIENTE NÃO IDENTIFICADO"
        : namePatient;
    },
  },
  methods: {
    prepareTransfer() {
      const hasOriginData =
        this.information.origin &&
        Object.keys(this.information.origin).length > 0;

      const hasDestinyData =
        this.information.destiny &&
        Object.keys(this.information.destiny).length > 0;

      if (!hasOriginData || !hasDestinyData) {
        throw new Error("Não foi possível realizar transferência");
      }

      const originUnit = this.information.origin.uns_id;
      const destinyUnit = Number(this.information.destiny.uns_id);
      const originBedId =
        this.information.origin.inl_id || this.information.origin.id;

      const data = {
        int_id: this.information.origin.int_id,
        origin_bed_id: originBedId,
        origin_situation_id: this.information.origin.isl_id,
        origin_bed_type_id: this.information.origin.itl_id,

        destiny_bed_id: this.information.destiny.id,
        destiny_situation_id: this.form.isl_id,
        destiny_bed_type_id: this.information.destiny.itl_id,
        observation: this.information.origin.ihs_observacao,
        confirm_hospitalization: this.confirmHospitalization,
      };

      if (data.confirm_hospitalization) {
        data.destiny_situation_id = 3;
      }

      const unitIsDifferent = originUnit !== destinyUnit;

      if (unitIsDifferent) {
        data.destiny_unit_health_id = destinyUnit;
      }

      return data;
    },

    async saveForm() {
      try {
        this.isSaving = true;

        await this.$refs.save.actionSubmit();

        if (this.status === "OCUPADO") {
          this.form.isl_id = 3;
        }

        if (this.status === "RESERVA") {
          this.form.isl_id = 2;
        }

        const isPatientTransfer =
          this.show && this.transfer && this.form.isl_id;

        if (isPatientTransfer) {
          const transfer = this.prepareTransfer();

          const response = await this.$store.dispatch(
            "Hospitalization/PATIENT_TRANSFER",
            {
              transfer,
            },
          );

          const hasSuccess = response && response.success;

          hasSuccess
            ? this.$toaster.success(response.message)
            : this.$toaster.error(response.message);
        }
        await this.$refs.save.actionDone();

        this.$emit("reSearch", true);
        this.close();
      } catch (pErr) {
        this.$refs.save.fail();
        this.$toaster.error(pErr);
        console.log("pErr", pErr);
      }

      this.isSaving = false;
    },

    getValue(pType, pKey = null, pAlternativeKey = null) {
      const hasValue = this.information[pType];
      const value =
        this.information[pType][pKey] ||
        this.information[pType][pAlternativeKey];

      return hasValue ? value : "Não Informado";
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
