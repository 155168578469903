<style src="./RgInputCheckbox.scss" lang="scss"></style>
<template lang="html">
  <section
    class="checkbox--component"
    :title="permission ? title : 'Usuário sem permissão.'"
  >
    <RgValidationAlert :alert="error" />
    <input
      :id="id"
      v-model="inputValue"
      :disabled="permission ? disabled : true"
      :class="{
        disable: permission ? disabled : true,
        disablebchk: permission ? disabled : true,
        nopermission: !permission,
      }"
      class="checkmark"
      type="checkbox"
    />
    <span>
      <strong>{{ label }}</strong>
    </span>
  </section>
</template>

<script>
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";

export default {
  name: "RgInputCheckbox",
  components: {
    RgValidationAlert,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "",
    },
    value: Boolean,
    disabled: Boolean,
    title: {
      type: String,
      default: "",
    },
    permission: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      inputValue: false,
    };
  },

  watch: {
    inputValue(value) {
      this.emit(value);
    },
  },

  mounted() {
    this.inputValue = this.value;
  },

  methods: {
    emit(val) {
      const emitValue = Boolean(val);
      this.$emit("change-value", emitValue);
    },
  },
};
</script>
