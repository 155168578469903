import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  NaoPossuiPermissaoException: `Você não possui permissão para agendar uma consulta`,
  QuantidadeConsultaAlteracaoInvalidaException: `A quantidade de consultas informada não pode ser igual a zero. `,
  HorarioFinalInvalidoException: `Horário final menor que a última consulta agendada.`,
  HorarioInicialInvalidoException: `Horário inicial maior primeira consulta agendada.`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-historico-agendamento/obter-dados-escala",
    variables,
  )
    .then(({ data }) => {
      const { dados } = HandleResponse(data, {
        extraExceptionsErrors: arrayExceptions,
      });
      return dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
