import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  NaoPossuiPermissaoException: `Você não possui permissão para agendar uma consulta`,
  QuantidadeConsultaAlteracaoInvalidaException: `A quantidade de consultas informada não pode ser igual a zero `,
  HorarioFinalInvalidoException: `Horário final menor que a última consulta agendada`,
  HorarioInicialInvalidoException: `Horário inicial maior primeira consulta agendada`,
  ExameConflitoAlteracaoDePeriodoHorarioException: `A escala agendada possui conflitos`,
  ExameHorarioInicialInvalidoException: `Existem exames agendados para este profissional.`,
  ExameHorarioFinalInvalidoException: `Horário final menor que o último exame agendado.`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-historico-agendamento/alterar-dados-dia",
    { blnExibirAvisos: variables.blnExibirAvisos, arrFormData: variables },
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
