<template lang="html">
  <div v-if="show" class="modal-confirm-queue-deletion">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        Impressão Transferência de Consultas
      </div>

      <div slot="body" class="body">
        <div>Deseja imprimir a ficha de transferência?</div>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel-btn" medium @click="close" />

        <RgConfirmButton
          id="confirm-btn"
          ref="confirmButton"
          medium
          class="yes"
          @click="actionConfirm"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, RgConfirmButton } from "~tokio/primitive";

export default {
  name: "ModalPrintTransfer",
  components: {
    RgBaseModal,
    RgCancelButton,
    RgConfirmButton,
  },
  props: {
    show: Boolean,
  },
  methods: {
    async actionConfirm() {
      this.$emit("confirm", true);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
