<template>
  <div v-show="show" class="modal-transfer-employee">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <section slot="header" class="modal-transfer-employee-header">
        <div class="title">Trocar Profissional</div>
      </section>

      <section slot="body" class="modal-transfer-employee-body">
        <span class="information">
          Altere para profissional vinculado à Unidade de Saúde desde que ambos
          possuam CBOs compatíveis com o procedimento realizado.
        </span>

        <section class="patients-info">
          <FormBase title="Pacientes selecionados">
            <div class="data-info">
              <div class="employee-info">
                <span class="content">
                  CBO:
                  <strong>
                    {{ this.form.cboCode || "NÃO INFORMADO" }}
                  </strong>
                </span>
                <span class="content">
                  Profissional atual:
                  <strong>
                    {{ this.form.employeeName || "NÃO INFORMADO" }}
                  </strong>
                </span>
              </div>
              <hr />
              <div class="employee-info">
                <span class="content">
                  Total de Registros:
                  <strong>
                    {{ this.patientList.length || "NÃO INFORMADO" }}
                  </strong>
                </span>
                <RgLessButton
                  id="excluir"
                  :disabled="!hasSelectedLinePatient"
                  title="Excluir Registro"
                  class="btn-action"
                  @click="removeLine"
                />
              </div>
            </div>
            <div class="table-patient-info">
              <SmartTable
                ref="smartTablePatient"
                name="ListPatientInfo"
                :show-pagination="false"
                removeBtnColumns
                :columns="COLUMNS"
                class="smart-table"
                :body="patientList"
                :total="Number(patientListLength)"
                @getLine="selectLine"
              />
            </div>
          </FormBase>
        </section>

        <section class="new-employee-info">
          <RgValidatorForm ref="validator" @submit.stop.prevent="save">
            <FormBase title="Dados do novo Profissional">
              <div class="employee-info">
                <RgSuggestTransferOccupation
                  ref="occupation"
                  v-model="form.newCbo"
                  :rules="{
                    required: true,
                  }"
                  openOnlyTop
                  :procedure-list="procedureList"
                  @selected="selectNewCbo"
                />
                <RgSuggestTransferEmployee
                  ref="employee"
                  v-model="form.newEmployee"
                  label="CNS e Nome do Profissional"
                  placeholder="Digite o nº do CNS ou nome do Profissional"
                  :rules="{
                    required: true,
                  }"
                  :disabled="!form.newCboCode"
                  :class="{
                    disable: !form.newCboCode,
                  }"
                  openOnlyTop
                  :unitHealthId="unitHealthId"
                  :occupationCode="form.newCboCode"
                  :pesName="form.employeeName"
                  @selected="selectNewEmployee"
                />
              </div>
            </FormBase>
          </RgValidatorForm>
        </section>
      </section>

      <section slot="footer" class="modal-transfer-employee-footer">
        <RgCancelButton
          id="cancel-btn"
          label="Cancelar"
          medium
          @click="close"
        />
        <MediumButton id="save-btn" label="Salvar" medium @click="save" />
      </section>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  MediumButton,
  RgCancelButton,
  RgLessButton,
  RgValidatorForm,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import { FormBase } from "~tokio/foundation";
import RgSuggestTransferEmployee from "./../rg-suggest/rg-suggest-transfer-employee/RgSuggestTransferEmployee";
import RgSuggestTransferOccupation from "./../rg-suggest/rg-suggest-transfer-occupation/RgSuggestTransferOccupation";

export default {
  name: "ModalTransferEmployee",
  components: {
    RgBaseModal,
    MediumButton,
    RgCancelButton,
    SmartTable,
    FormBase,
    RgLessButton,
    RgSuggestTransferEmployee,
    RgSuggestTransferOccupation,
    RgValidatorForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        employeeName: null,
        newEmployeeName: null,
        newEmployee: null,
        newEmployeeCns: null,
        newEmployeeIne: null,
        employeeIne: null,
        cboCode: null,
        newCbo: null,
        newCboCode: null,
        employeeCns: null,
      },
      selectedLinePatient: null,
      selectedIndex: null,
      procedureList: null,
      patientListLength: 0,
      patientList: [],
    };
  },
  computed: {
    hasSelectedLinePatient() {
      return (
        this.selectedLinePatient &&
        Object.keys(this.selectedLinePatient)?.length > 0 &&
        this.patientList?.length > 1
      );
    },
    unitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.fillData(this.formData);
      } else {
        this.close();
      }
    },
    "form.newCbo"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form = {
          ...this.form,
          newEmployee: null,
          newEmployeeName: null,
          newEmployeeCns: null,
          newEmployeeIne: null,
        };
      }
    },
  },
  mounted() {},
  created() {
    this.COLUMNS = [
      {
        name: "Data do registro",
        key: "data_atendimento",
        align: "left",
      },
      {
        name: "Boletim",
        key: "bol_numero",
        align: "right",
      },
      {
        name: "Paciente",
        key: "bof_nome",
        align: "left",
      },
      {
        name: "Procedimento",
        key: "procedimento",
        align: "left",
      },
    ];
  },
  methods: {
    selectLine(pValue, pIndex) {
      this.selectedLinePatient = pValue;
      this.selectedIndex = pIndex;
    },
    filterProcedureList() {
      this.procedureList = null;
      this.patientList.map((element) => {
        if (
          element.pdf_cod_procedimento !== null &&
          !this.procedureList?.includes(element.pdf_cod_procedimento)
        ) {
          this.procedureList += element.pdf_cod_procedimento + ",";
        }
      });

      this.procedureList = this.procedureList?.slice(4, -1);
    },
    removeLine() {
      this.patientList.splice(this.selectedIndex, 1);
      this.selectedLinePatient = null;
      this.selectedIndex = null;
      this.patientListLength = this.patientList?.length;
      this.$refs.smartTablePatient.cleanSelectRow();
      this.filterProcedureList();
    },
    fillData(pValue) {
      this.patientList = Array.from(this.formData);

      this.form = {
        employeeName: pValue[0].pdf_nome_profissional,
        cboCode: pValue[0].pdf_cod_cbo,
        employeeCns: pValue[0].pdf_cns,
        employeeIne: pValue[0].pdf_cod_ine,
      };
      this.patientListLength = this.patientList?.length;

      this.filterProcedureList();
    },

    selectNewEmployee(pValue) {
      this.form.newEmployeeCns = pValue?.source?.cns_profissional || null;
      this.form.newEmployeeIne = pValue?.source?.equ_codigo_ine || null;
      this.form.newEmployeeName = pValue?.source?.pes_nome || null;
    },
    selectNewCbo(pValue) {
      this.form.newCboCode = pValue?.source?.CO_OCUPACAO || null;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async save() {
      try {
        const valid = await this.isFormValid();

        if (!valid) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        this.$loader.start();
        const variables = {
          transferBulletinBilling: {
            bof_cnes: this.patientList[0].bof_cnes,
            bof_competencia: this.patientList[0].bof_competencia,
            pdf_cns: this.form.newEmployeeCns,
            pdf_nome_profissional: this.form.newEmployeeName,
            pdf_cod_ine: this.form.newEmployeeIne,
            pdf_cod_cbo: this.form.newCboCode,
            list_pdf_ids: this.patientList.map((element) => {
              return element.pdf_id;
            }),
          },
        };

        await this.$store.dispatch("Billing/BPAI/TRANSFER_BULLETIN_BILLING", {
          variables,
        });

        this.$toaster.success(
          "A troca de profissional foi registrada com sucesso.",
        );

        this.close();
        this.$emit("reSearch");
      } catch (error) {
        this.$toaster.error(
          "Erro na comunicação com o servidor. Tente novamente ou contate o suporte.",
          "Falha na troca de profissional",
        );
      }
    },
    close() {
      this.form = {
        employeeName: null,
        cboCode: null,
        employeeCns: null,
      };
      this.procedureList = null;
      this.patientList = [];
      this.patientListLength = 0;
      this.$refs.occupation.cleanValidate();
      this.$refs.employee.cleanValidate();
      this.$emit("close");
    },
  },
};
</script>
