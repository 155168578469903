export default {
  SET_BULLETIN_LIST: (state, bulletinList) => {
    state.bulletinList = bulletinList;
  },
  SELECT_BULLETIN_ITEM: (state, bulletinId) => {
    state.selectedBulletinId = bulletinId;
  },
  SELECT_BULLETIN_STATUS: (state, bulletinStatus) => {
    state.bulletinStatus = bulletinStatus;
  },
  SELECT_BOF_ID: (state, bofId) => {
    state.bofId = bofId;
  },
  SELECT_BULLETIN_NUMBER: (state, selectedBulletinNumber) => {
    state.selectedBulletinNumber = selectedBulletinNumber;
  },
  DESTROY_BULLETIN: (state) => {
    state.bofId = null;
    state.bulletinList = [];
    state.bulletinStatus = null;
    state.searchFilter = {};
    state.selectedBulletinId = null;
    state.selectedBulletinNumber = null;
  },
  SET_BULLETIN_SEARCH_FILTER: (state, form) => {
    state.searchFilter.initialDate = form.initialDate;
    state.searchFilter.finalDate = form.finalDate;
    state.searchFilter.pes_nome = form.pes_nome;
    state.searchFilter.bol_numero = form.bol_numero;
    state.searchFilter.status = form.status;
  },
};
