<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

export default {
  name: "RgSelectObjectAnalisis",
  extends: RgSelect,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Objeto de Análise",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    data() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      this.valuesData = this.data.map((item) => {
        return {
          value: item.lob_nome_objetos,
          label: item.lob_nome_objetos,
          id: item.lob_id,
        };
      });
    },
  },
};
</script>
