<template lang="html">
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18"
      cy="18"
      r="5.5"
      :fill="disabled ? '#ccc' : '#1E88A9'"
      :stroke="disabled ? '#ccc' : '#1E88A9'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9999 23C20.7612 23 22.9999 20.7614 22.9999 17.9999C22.9999 15.2387 20.7612 13 17.9999 13C15.2388 13 13 15.2388 13 17.9999C13 20.7614 15.2388 23 17.9999 23Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0022 14.8181C17.7093 14.8181 17.4719 15.0555 17.4719 15.3484V17.4696H15.3506C15.0577 17.4696 14.8203 17.707 14.8203 17.9999C14.8203 18.2928 15.0577 18.5302 15.3506 18.5302H17.4719V20.6514C17.4719 20.9443 17.7093 21.1817 18.0022 21.1817C18.2951 21.1817 18.5325 20.9443 18.5325 20.6514V18.5302H20.6536C20.9465 18.5302 21.1839 18.2928 21.1839 17.9999C21.1839 17.707 20.9465 17.4696 20.6536 17.4696H18.5325V15.3484C18.5325 15.0555 18.2951 14.8181 18.0022 14.8181Z"
      :fill="disabled ? '#ccc' : '#1E88A9'"
    />
    <circle
      cx="5.95536"
      cy="6.88847"
      r="3.77411"
      transform="rotate(46.5277 5.95536 6.88847)"
      fill="white"
    />
    <rect
      x="9.01562"
      y="3.41028"
      width="0.83869"
      height="9.22559"
      rx="0.419345"
      transform="rotate(46.5277 9.01562 3.41028)"
      :fill="disabled ? '#ccc' : '#1E88A9'"
    />
    <rect
      x="12.9102"
      y="9.31848"
      width="5.49707"
      height="10.078"
      rx="2.74854"
      transform="rotate(-89.925 12.9102 9.31848)"
      fill="white"
    />
    <rect
      x="18.4102"
      y="10.2418"
      width="0.916178"
      height="7.32943"
      rx="0.458089"
      transform="rotate(-179.925 18.4102 10.2418)"
      :fill="disabled ? '#ccc' : '#1E88A9'"
    />
    <path
      d="M9.21907 19.1167C9.11616 18.6416 8.90249 18.2099 8.66114 17.7916C8.26003 17.096 7.78884 16.4459 7.34412 15.7787C7.00941 15.2766 6.6671 14.7787 6.43065 14.2191C6.37478 14.0868 6.28682 13.9983 6.13637 14C5.98984 14.0017 5.90359 14.0895 5.85116 14.2191C5.76515 14.4313 5.65734 14.6324 5.54218 14.8299C5.16851 15.4709 4.73407 16.072 4.32267 16.6882C3.95341 17.241 3.58146 17.7921 3.30899 18.4015C3.12571 18.8112 2.99339 19.2338 3.00025 19.6891C3.00124 19.8513 3.0152 20.012 3.04068 20.1723C3.30139 21.8125 4.86566 22.9911 6.51592 22.7745C7.5715 22.6358 8.37103 22.0933 8.89318 21.1661C9.25264 20.5281 9.37564 19.84 9.21883 19.1165L9.21907 19.1167ZM8.33525 19.4544C8.25562 20.4769 7.57959 21.2668 6.58796 21.4878C6.45687 21.517 6.32284 21.5251 6.18856 21.5354C5.96118 21.5525 5.83964 21.3822 5.84185 21.2433C5.84454 21.0659 5.97049 20.952 6.17582 20.9468C6.47206 20.9395 6.7487 20.8613 6.99716 20.7001C7.48378 20.3842 7.72881 19.9312 7.74964 19.3532C7.75699 19.1446 7.91724 19.0148 8.10909 19.0577C8.237 19.0863 8.32888 19.2025 8.33501 19.3446C8.33648 19.3811 8.33501 19.4178 8.33501 19.4544H8.33525Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "TypePresentation",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
