import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacySatelliteConsumptionReport(
    $uns_id: Int
    $far_id: Int
    $mpd_id: Int
    $smd_id: Int
    $dco_id: Int
    $dcoName: String
    $pharmacyName: String
    $productName: String
    $periodoInicial: String
    $periodoFinal: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limiteFim: Int
    $limiteInicio: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacySatelliteConsumptionReport(
      uns_id: $uns_id
      far_id: $far_id
      mpd_id: $mpd_id
      smd_id: $smd_id
      dco_id: $dco_id
      dcoName: $dcoName
      pharmacyName: $pharmacyName
      productName: $productName
      periodoInicial: $periodoInicial
      periodoFinal: $periodoFinal
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limiteFim: $limiteFim
      limiteInicio: $limiteInicio
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PRODUTO
        NOME_COMERCIAL
        CODIGO
        APRESENTACAO
        TIPO
        QUANTIDADE
        DATA
        DESTINO_CONSUMO
        UNIDADE_SAUDE
        FARMACIA
        USUARIO
        OBSERVACAO
        LOTE_VALIDADE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacySatelliteConsumptionReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
