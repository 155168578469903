import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import EmergencyAdministrativeCheckOutSearch from "$emergency/administrative-check-out/views/search/EmergencyAdministrativeCheckOutSearch.vue";

export default {
  path: "/emergency/administrative-check-out/search",
  name: "emergency.administrative-check-out.search",
  components: {
    default: EmergencyAdministrativeCheckOutSearch,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      {
        label: "Alta Administrativa",
      },
    ],
  },
};
