<template lang="html">
  <ModuleBox
    :granted="hasPermission"
    title="Faturamento AIH"
    class="hospitalization-billing-details"
  >
    <div slot="title" class="hospitalization-billing-details-title">
      <div class="hospitalization-billing-status">
        <div :class="`status status-${status}`">
          {{ statusTitle }}
        </div>
      </div>
      <RgAddButton medium label="Novo +" title="Novo" @click="handleNew" />
      <MediumButton
        v-shortkey="['ctrl', 'f']"
        :disabled="!canBill"
        label="Faturar"
        title="Faturar"
        @shortkey.native="billAihForShortkey"
        @click="billAih"
      />
    </div>
    <HospitalizationBillingAihInfo
      ref="aihHeader"
      :validation-tabs="validationTabs"
      @patientUnitRecordNumberSelected="setPersonId"
    />
    <RgFormPanel>
      <RgFormPanelItem
        v-shortkey="['ctrl', '1']"
        :class="{
          active: stepProgress === 1 && errors.patient,
          validated: !errors.patient,
          'active-with-error': stepProgress === 1 && !errors.patient,
        }"
        @shortkey.native="changeStep(1)"
        @change-step="changeStep(1)"
      >
        <IconDocumentPerson slot="icon" />
        Paciente
      </RgFormPanelItem>
      <RgFormPanelItem
        v-shortkey="['ctrl', '2']"
        :class="{
          active: stepProgress === 2 && errors.hospitalization,
          validated: !errors.hospitalization,
          'active-with-error': stepProgress === 2 && !errors.hospitalization,
        }"
        @shortkey.native="changeStep(2)"
        @change-step="changeStep(2)"
      >
        <IconHospitalInternment slot="icon" />
        Internação
      </RgFormPanelItem>
      <RgFormPanelItem
        v-shortkey="['ctrl', '3']"
        :class="{
          active: stepProgress === 3 && errors.diagnosticTab,
          validated: !errors.diagnosticTab,
          'active-with-error': stepProgress === 3 && !errors.diagnosticTab,
        }"
        @shortkey.native="changeStep(3)"
        @change-step="changeStep(3)"
      >
        <IconClipboard slot="icon" />
        Diagnóstico
      </RgFormPanelItem>
      <RgFormPanelItem
        v-shortkey="['ctrl', '4']"
        :class="{
          active: stepProgress === 4 && errors.procedureTab,
          validated: !errors.procedureTab,
          'active-with-error': stepProgress === 4 && !errors.procedureTab,
        }"
        @shortkey.native="changeStep(4)"
        @change-step="changeStep(4)"
      >
        <IconSyringe slot="icon" />
        Procedimento
      </RgFormPanelItem>
    </RgFormPanel>
    <div class="tabs">
      <HospitalizationBillingPatientInfo
        v-show="stepProgress === 1"
        ref="patientTab"
        :validation-tabs="validationTabs"
        :saving-father="saving"
        @saveInfoTabs="saveInfoTabs"
        @increaseStepProgress="stepProgress++"
      />
      <HospitalizationBillingHospitalizationInfo
        v-show="stepProgress === 2"
        ref="hospitalizationTab"
        :validation-tabs="validationTabs"
        :saving-father="saving"
        @saveInfoTabs="saveInfoTabs"
        @increaseStepProgress="stepProgress++"
      />
      <HospitalizationBillingDiagnosticInfo
        v-show="stepProgress === 3"
        ref="diagnosticTab"
        :validation-tabs="validationTabs"
        :saving-father="saving"
        @saveInfoTabs="saveInfoTabs"
        @increaseStepProgress="stepProgress++"
      />
      <HospitalizationBillingProcedureInfo
        v-show="stepProgress === 4"
        ref="procedureTab"
        :step-progress="stepProgress"
        :validation-tabs="validationTabs"
        :saving-father="saving"
        @saveInfoTabs="saveInfoTabs"
      />
    </div>
    <RgConfirmModal
      :show="confirmNew"
      :message="newMsg"
      title="Confirmação"
      style="background-color: red"
      @close="closeModal"
      @confirmed="newBilling"
      @denied="closeModal"
    />
  </ModuleBox>
</template>

<script>
import EventBus from "~common/event/bus/EventBus";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import {
  RgFormPanel,
  RgFormPanelItem,
} from "$surgery-center/foundation/component/rg-form-panel";
import RgConfirmModal from "~tokio/primitive/notification/modal/RgConfirmModal";
import {
  IconClipboard,
  IconDocumentPerson,
  IconSyringe,
  IconHospitalInternment,
  MediumButton,
  RgAddButton,
} from "~tokio/primitive";

import {
  HospitalizationBillingPatientInfo,
  HospitalizationBillingHospitalizationInfo,
  HospitalizationBillingDiagnosticInfo,
  HospitalizationBillingProcedureInfo,
  HospitalizationBillingAihInfo,
} from "$billing/submodules/aih/hospitalization-billing/common/component";
import {
  AlertError,
  ExecuteActionAvoidingConcurrency,
} from "../../common/service";

// 0 - em aberto | 1 - faturado | 2 - exportado | 3 - excluido.
const EM_ABERTO = 0;
const FATURADO = 1;

export default {
  name: "HospitalizationBillingDetail",
  components: {
    ModuleBox,
    RgConfirmModal,
    RgFormPanel,
    RgFormPanelItem,
    HospitalizationBillingAihInfo,
    HospitalizationBillingPatientInfo,
    HospitalizationBillingHospitalizationInfo,
    HospitalizationBillingDiagnosticInfo,
    HospitalizationBillingProcedureInfo,
    IconClipboard,
    IconSyringe,
    IconDocumentPerson,
    IconHospitalInternment,
    RgAddButton,
    MediumButton,
  },
  data() {
    return {
      billing: false,
      savingTabs: false,
      validationTabs: false,
      toggleExpandListState: true,
      stepProgress: 1,
      title: "Em aberto",
      errors: {
        aih: true,
        patient: true,
        hospitalization: true,
        diagnosticTab: true,
        procedureTab: true,
      },
      newMsg:
        "Os campos atuais serão limpos para uma nova inclusão, deseja continuar?",
      confirmNew: false,
      personId: null,
    };
  },
  computed: {
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.faturar",
        this.$store.getters["Login/GET_UNI_HEALTH_ID"],
      );
    },
    status() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_AIH_STATUS"
      ];
    },
    aihId() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_BILLING_AIH_ID"
      ];
    },
    canBill() {
      return !this.saving && !!this.aihId;
    },
    saving() {
      return this.savingTabs || this.billing;
    },
    statusTitle() {
      switch (this.status) {
        case 0:
          return "Em aberto";

        case 1:
          return "Faturado";

        case 2:
          return "Exportado";

        case 3:
          return "Excluido";

        default:
          return "-";
      }
    },
  },
  watch: {
    personId() {
      if (this.personId) {
        this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_PATIENT_INFO",
          { personId: this.personId },
        );
      }
      this.$store.commit(
        "Billing/HospitalizationBilling/DESTROY_PATIENT_INFO_TAB",
      );
    },
  },
  mounted() {
    const unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.$store.dispatch(
      "Billing/HospitalizationBilling/POPULATE_AIH_INFO_TABS",
      unitHealthId,
    );
  },
  beforeDestroy() {
    this.$store.dispatch(
      "Billing/HospitalizationBilling/DESTROY_AIH_INFO_TABS",
    );
  },
  methods: {
    changeStep(pStepIndex) {
      this.stepProgress = pStepIndex;
    },
    async firstTwoTabsAreValid() {
      this.errors.patient = await this.$refs.patientTab.tabIsValid();
      this.errors.hospitalization = await this.$refs.hospitalizationTab.tabIsValid();

      return this.errors.patient && this.errors.hospitalization;
    },
    async tabsAreValid() {
      const firstTwoTabsAreValid = await this.firstTwoTabsAreValid();
      this.errors.aih = await this.$refs.aihHeader.tabIsValid();
      this.errors.diagnosticTab = await this.$refs.diagnosticTab.tabIsValid();
      this.errors.procedureTab = await this.$refs.procedureTab.tabIsValid();

      return (
        this.errors.aih &&
        firstTwoTabsAreValid &&
        this.errors.diagnosticTab &&
        this.errors.procedureTab
      );
    },
    async billAihForShortkey() {
      if (this.canBill) this.billAih();
    },
    setPersonId(pPersonId) {
      this.personId = pPersonId;
    },
    dispatchBill(status) {
      return this.$store.dispatch(
        "Billing/HospitalizationBilling/BILL_HOSPITALIZATION_BILLING_AIH",
        {
          hospitalizationBillingAihId: this.aihId,
          status,
        },
      );
    },
    async billAih() {
      if (!this.saving) {
        this.billing = true;
        return ExecuteActionAvoidingConcurrency(async () => {
          try {
            const success = await this.saveInfoTabs(null, true);

            if (success) {
              this.validationTabs = true; // Ativa validações das abas para Faturamento

              this.$nextTick(async () => {
                const valid = await this.tabsAreValid();

                try {
                  if (!valid) throw new Error();

                  await this.dispatchBill(FATURADO);

                  this.$toaster.success("AIH Faturada com sucesso.");
                } catch (error) {
                  AlertError("Verifique os dados das abas.", [], "error");
                }
              });
            } else {
              AlertError("Verifique os dados das abas.", [], "error");
            }
          } catch (error) {
          } finally {
            this.billing = false;
          }
        }, this.$nextTick);
      }
    },
    forceChildrenUpdateVuex() {
      EventBus.$emit("updateVuex");
    },
    async saveBillingAihTabs() {
      const savedData = await this.$store.dispatch(
        "Billing/HospitalizationBilling/SAVE_HOSPITALIZATION_BILLING_AIH_TABS",
      );
      this.$toaster.success("Salvo com sucesso");
      this.$store.dispatch(
        "Billing/HospitalizationBilling/SET_RETURN_AIH_INFO_TABS",
        savedData,
      );
    },
    async saveInfoTabs(pRequestAction = null, pBilling = false) {
      let success = false;

      if (pBilling || !this.savingTabs) {
        this.savingTabs = true;

        success = await ExecuteActionAvoidingConcurrency(async () => {
          let internalSuccess = false;
          this.forceChildrenUpdateVuex();
          const valid = await this.firstTwoTabsAreValid();

          if (valid) {
            try {
              await this.saveBillingAihTabs();
              await this.dispatchBill(EM_ABERTO);
              internalSuccess = true;
            } catch (error) {
              internalSuccess = AlertError(
                this.errorMessage(error),
                [],
                "error",
              );
            }
          }

          this.savingTabs = false;
          if (pRequestAction) pRequestAction();

          return internalSuccess;
        }, this.$nextTick);
      }

      return success;
    },
    errorMessage(pErr) {
      return pErr[0] && pErr[0].message
        ? pErr[0].message
        : "Não foi possível salvar.";
    },
    newBilling() {
      this.confirmNew = false;
      this.$store.dispatch(
        "Billing/HospitalizationBilling/DESTROY_AIH_INFO_TABS",
      );
      Object.assign(this.$data, this.$options.data.apply(this));
      EventBus.$emit("resetData");
    },
    closeModal() {
      this.confirmNew = false;
    },
    handleNew() {
      this.confirmNew = true;
    },
  },
};
</script>
