<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.9957 38.4321C61.4846 39.4652 60.6469 39.8075 59.5154 39.7434C58.2338 39.6715 58.2307 39.7262 58.2307 41.0265C58.2291 43.9101 58.226 46.7937 58.2275 49.6772C58.2275 49.9914 58.2307 50.3071 58.065 50.5853C57.8009 51.0291 57.4008 51.2464 56.885 51.1651C56.3708 51.0838 56.0614 50.7525 55.9301 50.257C55.9051 50.1648 55.9066 50.0632 55.9066 49.9663C55.9066 46.206 55.9035 42.4457 55.9144 38.6853C55.916 37.8976 56.3536 37.4663 57.1538 37.4366C57.7383 37.4147 58.3229 37.4319 58.8933 37.4319C58.9887 37.2537 58.848 37.1974 58.7792 37.1224C56.8834 35.0781 54.983 33.037 53.0872 30.9927C52.9105 30.8036 52.8027 30.6082 52.5151 30.9193C50.5756 33.0307 48.6157 35.1219 46.6652 37.2209C46.6308 37.2584 46.6152 37.3115 46.5558 37.4287C47.1669 37.4287 47.7248 37.4209 48.2828 37.4303C49.2174 37.4475 49.6535 37.8632 49.655 38.79C49.6597 42.4722 49.655 46.1544 49.6456 49.8366C49.6425 50.665 49.1908 51.1713 48.5 51.1776C47.7795 51.1838 47.3451 50.6743 47.3451 49.8007C47.3451 46.6639 47.3279 43.5272 47.3638 40.3905C47.3701 39.8012 47.1966 39.6543 46.6527 39.7168C46.2479 39.7622 45.8337 39.7278 45.4242 39.7247C44.6225 39.72 44.0301 39.3574 43.7144 38.6291C43.3972 37.9007 43.5378 37.2131 44.0739 36.627C44.9022 35.7205 45.7446 34.825 46.5808 33.9263C48.1359 32.2555 49.6878 30.5848 51.2461 28.9188C52.1775 27.9216 53.3607 27.9138 54.3015 28.9188C56.6599 31.4381 59.0059 33.9716 61.3643 36.4894C61.6456 36.7895 61.816 37.1427 62.0004 37.4944V38.4306L61.9957 38.4321Z"
      fill="white"
    />
    <path
      d="M2.00156 37.3803C3.19562 35.6892 4.74289 34.3123 6.11356 32.7791C7.2701 31.485 8.47198 30.2316 9.65978 28.9656C10.6538 27.9075 11.8181 27.9091 12.7965 28.9594C15.1221 31.4569 17.4415 33.9622 19.778 36.4504C20.3844 37.0958 20.6345 37.8023 20.2859 38.6306C19.9312 39.4746 19.2232 39.7653 18.3433 39.7293C17.9541 39.7137 17.5602 39.7559 17.1726 39.7184C16.7522 39.6778 16.6569 39.8341 16.66 40.2373C16.6756 43.3553 16.6663 46.4733 16.6631 49.5912C16.6631 49.7663 16.6694 49.9429 16.6538 50.1164C16.6006 50.729 16.1396 51.1667 15.541 51.1776C14.9471 51.1885 14.4688 50.7728 14.3891 50.1601C14.3641 49.9679 14.3657 49.771 14.3657 49.5772C14.3688 46.0497 14.3719 42.5222 14.3782 38.9948C14.3797 37.7804 14.7314 37.4319 15.9442 37.4287C16.4256 37.4287 16.9085 37.4287 17.3883 37.4287C17.4086 37.2115 17.2649 37.1474 17.1789 37.0552C15.3081 35.0391 13.4326 33.0276 11.5603 31.013C11.3696 30.8067 11.2586 30.6036 10.9492 30.9427C9.02837 33.0432 7.07943 35.1203 5.14143 37.2068C5.10549 37.2443 5.0836 37.2943 4.99608 37.4303C5.60093 37.4303 6.13387 37.4256 6.66839 37.4303C7.69678 37.4413 8.09844 37.8351 8.09844 38.84C8.09844 42.5238 8.09844 46.206 8.09375 49.8898C8.09375 50.6712 7.64051 51.1682 6.96065 51.1776C6.29016 51.187 5.81661 50.6837 5.78066 49.9226C5.77284 49.7663 5.7791 49.6116 5.7791 49.4553C5.78066 46.4154 5.76659 43.374 5.79941 40.3342C5.80567 39.795 5.64312 39.6559 5.14925 39.7168C4.86167 39.7528 4.5616 39.7012 4.27246 39.7278C3.15186 39.8278 2.40479 39.3496 2 38.3118V37.3756L2.00156 37.3803Z"
      fill="white"
    />
    <path
      d="M43.6426 22.9813C42.5455 21.7825 41.5374 20.6807 40.5278 19.5788C37.799 16.6031 35.0639 13.6335 32.3507 10.6453C32.035 10.2967 31.8959 10.3155 31.588 10.6578C28.2152 14.4166 24.8222 18.1597 21.4338 21.906C21.1212 22.2514 20.8102 22.5999 20.5008 22.9469C20.6227 23.1438 20.793 23.0531 20.9321 23.0516C22.2981 23.0344 23.6656 23.0031 25.0316 22.9891C25.9772 22.9797 26.3882 23.3845 26.3882 24.3332C26.3882 30.3753 26.3866 36.416 26.382 42.4581C26.382 43.2833 25.9412 43.7975 25.2504 43.8069C24.5487 43.8163 24.0782 43.2818 24.0782 42.4269C24.0751 38.8416 24.0814 35.2547 24.0845 31.6694C24.0861 29.7017 24.0782 27.7325 24.0986 25.7648C24.1017 25.3819 23.9923 25.2662 23.6078 25.2756C22.5935 25.3006 21.5776 25.2803 20.5617 25.285C19.5333 25.2897 18.7253 24.8989 18.2986 23.9362C17.861 22.95 18.1423 22.0967 18.8535 21.3168C22.5982 17.2095 26.3335 13.0928 30.0657 8.97297C30.4971 8.49628 30.9722 8.11493 31.6271 8.02741C32.4554 7.91488 33.1759 8.14619 33.7432 8.76979C37.602 13.0115 41.4593 17.2532 45.3071 21.5043C45.9339 22.1982 46.0901 23.0188 45.7182 23.894C45.3478 24.7645 44.6585 25.2334 43.7177 25.2662C42.7033 25.3022 41.6859 25.3115 40.6731 25.2693C40.1167 25.2459 39.9292 25.3522 39.9323 25.9742C39.9604 31.3725 39.9448 36.7707 39.9386 42.169C39.9386 42.4206 39.9339 42.6785 39.8792 42.9223C39.7526 43.474 39.2681 43.8288 38.7258 43.8038C38.1647 43.7772 37.6989 43.3427 37.6333 42.7707C37.613 42.5972 37.6239 42.4206 37.6239 42.2456C37.6255 36.3409 37.6286 30.4347 37.6302 24.5301C37.6302 23.3282 37.9849 22.9813 39.2103 22.9797C40.6356 22.9797 42.061 22.9797 43.6426 22.9797V22.9813Z"
      fill="white"
    />
    <path
      d="M26.377 46.7357C26.377 47.0858 26.3942 47.4375 26.3738 47.7876C26.3363 48.408 25.8862 48.8472 25.2892 48.8722C24.6734 48.8988 24.178 48.5284 24.1092 47.9001C24.0232 47.1077 24.017 46.3028 24.1139 45.5104C24.1842 44.929 24.7047 44.5321 25.2611 44.5492C25.8425 44.5664 26.3301 45.0259 26.3707 45.6261C26.3957 45.9949 26.3754 46.3653 26.3754 46.7357H26.377Z"
      fill="white"
    />
    <path
      d="M39.9203 46.7655C39.9203 47.1155 39.9516 47.4703 39.9141 47.8157C39.8391 48.5034 39.3389 48.9144 38.6825 48.8707C38.0667 48.8301 37.6385 48.3721 37.6229 47.7001C37.6088 47.0374 37.6057 46.3747 37.6244 45.7136C37.6416 45.0681 38.1074 44.5899 38.7122 44.554C39.3124 44.518 39.8359 44.9525 39.9078 45.598C39.9516 45.9824 39.9156 46.3763 39.9156 46.7655H39.9188H39.9203Z"
      fill="white"
    />
    <path
      d="M39.9293 51.3261C39.9293 51.4621 39.9371 51.598 39.9293 51.734C39.8809 52.4951 39.4026 52.9703 38.7165 52.9437C38.057 52.9187 37.635 52.4389 37.6194 51.6902C37.6147 51.4183 37.6131 51.1464 37.6194 50.8744C37.6397 50.1742 38.0695 49.6944 38.7009 49.66C39.3557 49.6241 39.8574 50.0883 39.9231 50.801C39.9387 50.9744 39.9262 51.151 39.9262 51.3261H39.9309H39.9293Z"
      fill="white"
    />
    <path
      d="M26.375 51.337C26.3766 52.4342 26.0093 52.9453 25.2247 52.9437C24.4464 52.9421 24.0635 52.4092 24.0666 51.3292C24.0666 51.0963 24.0619 50.8603 24.101 50.6322C24.2057 50.0211 24.6996 49.6272 25.2903 49.6585C25.8811 49.6897 26.3281 50.1367 26.3719 50.7525C26.3859 50.9463 26.3734 51.1417 26.3734 51.3355L26.375 51.337Z"
      fill="white"
    />
    <path
      d="M32.0086 33.6324H30.0289C29.4818 33.6324 29.0391 34.0751 29.0391 34.6222C29.0391 35.1694 29.4818 35.6121 30.0289 35.6121H31.0188C31.0188 36.1592 31.4615 36.6019 32.0087 36.6019C32.5558 36.6019 32.9985 36.1592 32.9985 35.6121V35.4297C34.1481 35.0197 34.9781 33.9313 34.9781 32.6425C34.9781 31.0051 33.6461 29.6731 32.0086 29.6731C31.4634 29.6731 31.0187 29.2295 31.0187 28.6833C31.0187 28.1372 31.4634 27.6934 32.0086 27.6934H33.9882C34.5354 27.6934 34.9781 27.2507 34.9781 26.7036C34.9781 26.1564 34.5354 25.7137 33.9882 25.7137H32.9984C32.9984 25.1666 32.5556 24.7239 32.0085 24.7239C31.4613 24.7239 31.0186 25.1666 31.0186 25.7137V25.8961C29.869 26.3061 29.0391 27.3945 29.0391 28.6833C29.0391 30.3207 30.3711 31.6527 32.0086 31.6527C32.5538 31.6527 32.9984 32.0964 32.9984 32.6425C32.9984 33.1887 32.5538 33.6324 32.0086 33.6324Z"
      fill="white"
    />
    <path
      d="M52.7953 43.9476H51.7534C51.4654 43.9476 51.2324 44.1806 51.2324 44.4686C51.2324 44.7565 51.4654 44.9895 51.7534 44.9895H52.2744C52.2744 45.2775 52.5074 45.5105 52.7954 45.5105C53.0833 45.5105 53.3164 45.2775 53.3164 44.9895V44.8936C53.9214 44.6778 54.3582 44.1049 54.3582 43.4266C54.3582 42.5648 53.6572 41.8638 52.7953 41.8638C52.5084 41.8638 52.2743 41.6303 52.2743 41.3428C52.2743 41.0554 52.5084 40.8218 52.7953 40.8218H53.8372C54.1252 40.8218 54.3582 40.5888 54.3582 40.3009C54.3582 40.0129 54.1252 39.7799 53.8372 39.7799H53.3163C53.3163 39.4919 53.0832 39.2589 52.7953 39.2589C52.5073 39.2589 52.2743 39.4919 52.2743 39.7799V39.8759C51.6693 40.0917 51.2324 40.6645 51.2324 41.3428C51.2324 42.2046 51.9335 42.9057 52.7953 42.9057C53.0823 42.9057 53.3163 43.1392 53.3163 43.4266C53.3163 43.7141 53.0823 43.9476 52.7953 43.9476Z"
      fill="white"
    />
    <path
      d="M11.2231 43.9476H10.1811C9.89318 43.9476 9.66016 44.1806 9.66016 44.4686C9.66016 44.7565 9.89318 44.9895 10.1811 44.9895H10.7021C10.7021 45.2775 10.9351 45.5105 11.2231 45.5105C11.5111 45.5105 11.7441 45.2775 11.7441 44.9895V44.8936C12.3491 44.6778 12.786 44.1049 12.786 43.4266C12.786 42.5648 12.0849 41.8638 11.2231 41.8638C10.9361 41.8638 10.7021 41.6303 10.7021 41.3428C10.7021 41.0554 10.9361 40.8218 11.2231 40.8218H12.265C12.5529 40.8218 12.786 40.5888 12.786 40.3009C12.786 40.0129 12.5529 39.7799 12.265 39.7799H11.744C11.744 39.4919 11.511 39.2589 11.223 39.2589C10.935 39.2589 10.702 39.4919 10.702 39.7799V39.8759C10.097 40.0917 9.66016 40.6645 9.66016 41.3428C9.66016 42.2046 10.3612 42.9057 11.2231 42.9057C11.51 42.9057 11.744 43.1392 11.744 43.4266C11.744 43.7141 11.51 43.9476 11.2231 43.9476Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Apac",
};
</script>
