import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/person/patient/search/registernewborn",
  name: "person.patient.search.registernewborn",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastro", link: OpenMenuModule("register") },
      { label: "Paciente", link: OpenSubModule("register") },
      { label: "Cadastros de Pacientes", link: "/person/patient/search" },
      { label: "Vincular Recém-Nascido" },
    ],
  },
};
