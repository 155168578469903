<template>
  <section class="person-address">
    <FormBase title="Endereço" class="form-base-address">
      <div class="grid-address">
        <div class="row-1">
          <RgInputCep
            id="cep"
            ref="cep"
            v-model="form.address.end_cep"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            :rules="{ required: true, fn: validateCepExists }"
            label="CEP"
            @blur="searchAndFillAddressByCep"
          />

          <RgSuggestPlace
            ref="publicPlaceType"
            v-model="form.address.tlg_nome"
            :class="{ disable: disablepublicPlaceType || cantEdit }"
            :disabled="disablepublicPlaceType || cantEdit"
            :rules="{ required: isThereCEP }"
            @selected="selectingPlace"
          />

          <RgInput
            ref="address"
            v-model="form.address.end_logradouro"
            :class="{ disable: disableAddress || cantEdit }"
            :disabled="disableAddress || cantEdit"
            :rules="{ required: isThereCEP }"
            :maxlength="100"
            data-id="endereco"
            label="Logradouro"
            title="Logradouro"
            placeholder="Digite o nome do logradouro"
          />

          <RgInput
            ref="neighborhood"
            v-model="form.address.bai_nome"
            :class="{ disable: disableNeighborhood || cantEdit }"
            :disabled="disableNeighborhood || cantEdit"
            :rules="{ required: isThereCEP }"
            :maxlength="100"
            data-id="bairro"
            label="Bairro"
            placeholder="Digite o nome do bairro"
            title="Bairro"
          />
          <RgSuggestCity
            ref="city"
            v-model="form.address.mun_nome"
            :class="{ disable: disableCity || cantEdit }"
            :disabled="disableCity || cantEdit"
            :rules="{ required: isThereCEP }"
            @selected="selectingCity"
          />
        </div>

        <div class="row-2">
          <RgSuggestState
            ref="state"
            v-model="form.address.est_sigla"
            :class="{ disable: disableState || cantEdit }"
            :disabled="disableState || cantEdit"
            :rules="{ required: isThereCEP }"
            @selected="changeDigitedUF"
          />
          <RgInput
            ref="numberField"
            v-model="form.address.end_numero"
            :class="{ disable: !isThereCEP || cantEdit }"
            :disabled="!isThereCEP || cantEdit"
            :rules="{ required: isThereCEP, fn: validateSpecialCharacters }"
            :maxlength="10"
            data-id="numero"
            label="Nº"
            title="Número"
            placeholder="Ex.: 56"
          />

          <RgInput
            v-model="form.address.end_complemento"
            :class="{ disable: !isThereCEP || cantEdit }"
            :disabled="!isThereCEP || cantEdit"
            data-id="complemento"
            label="Complemento"
            title="Complemento"
            placeholder="Ex.: Casa"
            :maxlength="45"
          />

          <RgInput
            v-model="form.address.end_referencia"
            :class="{ disable: !isThereCEP || cantEdit }"
            :disabled="!isThereCEP || cantEdit"
            data-id="referencia"
            label="Referência"
            title="Referência"
            placeholder="Ex.: Comércio ou praça próximos ao domicílio"
            :maxlength="75"
          />

          <div
            v-show="unitHealthAddressPreference && !existsPesId"
            class="unit-health-address-preference"
          >
            <input
              id="endereco-unidade"
              v-model="unitHealthAddress"
              type="checkbox"
              @change="fillUnitHealthAddress"
            />
            <label> Endereço da Unidade </label>
          </div>
        </div>
      </div>
    </FormBase>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import { toast } from "~tokio/primitive/notification";
import { RgInput } from "~tokio/primitive";

import RgInputCep from "$person/common/components/input/rg-input-cep/RgInputCep";
import RgSuggestPlace from "$person/common/components/suggest/rg-suggest-place/RgSuggestPlace";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";

import FormBase from "~tokio/foundation/form-base/FormBase";
import CheckCEP from "$billing/billing/action/CheckCEP";

export default {
  name: "PersonAdress",
  components: {
    FormBase,
    RgInput,
    RgInputCep,
    RgSuggestState,
    RgSuggestPlace,
    RgSuggestCity,
  },
  props: {
    cantEdit: {
      type: Boolean,
      default: false,
    },
    isToSearchCep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasPlaceType: false,
      hasPublicPlace: false,
      hasNeighborhood: false,
      hasCity: false,
      hasState: false,
      unitHealthAddress: false,
      form: {
        address: {
          est_id: 0,
          mun_id: 0,
          tlg_id: 0,
          bai_nome: "",
          end_cep: "",
          end_numero: "",
          end_logradouro: "",
          end_complemento: "",
          mun_nome: "",
          est_sigla: "",
          tlg_nome: "",
          end_referencia: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      AddressData: "Person/Patient/GET_ADDRESS",
      UnitHealthAddress: "Login/GET_UNIT_HEALTH_ADDRESS",
    }),

    isThereCEP() {
      return this.form.address.end_cep.length >= 9;
    },

    disablepublicPlaceType() {
      if (!this.isThereCEP || this.hasPlaceType) {
        return true;
      }
      return false;
    },

    disableAddress() {
      if (!this.isThereCEP || this.hasPublicPlace) {
        return true;
      }
      return false;
    },

    disableNeighborhood() {
      if (!this.isThereCEP || this.hasNeighborhood) {
        return true;
      }
      return false;
    },

    disableCity() {
      if (!this.isThereCEP || this.hasCity) {
        return true;
      }
      return false;
    },

    disableState() {
      if (!this.isThereCEP || this.hasState) {
        return true;
      }
      return false;
    },

    unitHealthAddressPreference() {
      return !!Number(
        this.$store.getters["Login/GET_ADDRESS_UNIT_HEALTH_PREFERENCE"],
      );
    },
  },
  watch: {
    "form.address.end_cep"(pValue, pPreviously) {
      const isEmptyCep = pValue.length === 0 || pValue === "";

      if (isEmptyCep) {
        this.cleanForm();
      }
    },

    AddressData(pValue) {
      if (pValue) {
        this.mountData(pValue);
      }
    },
  },

  methods: {
    async mountData(pData) {
      if (this.isToSearchCep) {
        await this.searchAndFillAddressByCep(pData.end_cep);
      } else {
        this.fillAddress(pData);
      }
    },

    selectingPlace(pValue) {
      const source = pValue && pValue.source;
      if (source) {
        this.form.address.tlg_id = source.tlg_id;
        this.form.address.end_id_tipos_logradouros = source.tlg_id;
      } else {
        this.form.address.tlg_id = null;
        this.form.address.end_id_tipos_logradouros = null;
      }
    },

    selectingCity(pValue) {
      const source = pValue && pValue.source;
      this.form.address.mun_id = source && source.mun_id;
      this.form.address.mun_nome = source && source.mun_nome;
    },

    changeDigitedUF(pValue) {
      const { source } = pValue;
      this.form.address.est_id = source && source.est_id ? source.est_id : 0;
    },

    validateCepExists(pCep, pErrors) {
      const messageError = "CEP inválido";

      if (isEmpty(pCep)) {
        return true;
      }

      const invalidLength = pCep.replace(/[^0-9]/gi, "").length !== 8;

      if (invalidLength) {
        pErrors.push(messageError);
        return false;
      }

      return CheckCEP(pCep)
        .then((cep) => {
          const hasCep = Object.keys(cep).length > 0;

          if (!hasCep) {
            pErrors.push(messageError);
            return false;
          }

          return true;
        })
        .catch((pErr) => {
          pErrors.push(messageError);
          return false;
        });
    },

    async searchAndFillAddressByCep(pValue) {
      const currentCep = pValue ? pValue.replace(/[^0-9]/gi, "") : null;
      const isInvalidCep = currentCep ? currentCep.length !== 8 : true;

      if (isInvalidCep) return;

      try {
        this.$loader.start();
        const searchCep = await CheckCEP(currentCep);

        const hasCep = Object.keys(searchCep).length !== 0;

        if (hasCep) {
          this.hasPublicPlace = !!searchCep.temLogradouro;

          this.form.address.mun_id = searchCep.mun_id;
          this.form.address.mun_nome = searchCep.mun_nome;
          this.hasCity = !!searchCep.mun_nome;

          this.form.address.est_id = searchCep.est_id;
          this.form.address.est_sigla = searchCep.est_sigla;
          this.hasState = !!searchCep.est_sigla;

          this.form.address.end_id_tipos_logradouros = searchCep.tlg_id;
          this.form.address.tlg_id = searchCep.tlg_id;
          this.form.address.tlg_nome = searchCep.tlg_nome;
          this.form.address.publicPlaceType = searchCep.tlg_nome;

          this.$refs.publicPlaceType.forceSelection({
            tlg_id: searchCep.tlg_id ? searchCep.tlg_id : null,
            tlg_nome: searchCep.tlg_nome ? searchCep.tlg_nome : null,
          });
          this.hasPlaceType = !!searchCep.tlg_nome;

          this.form.address.end_logradouro = null;
          if (
            !this.form.address.end_logradouro ||
            searchCep.logradouro !== null
          ) {
            this.form.address.end_logradouro = searchCep.logradouro
              ? searchCep.logradouro.toUpperCase()
              : "";
          }

          this.form.address.bai_nome = null;
          if (!this.form.address.bai_nome || searchCep.bairro !== null) {
            this.form.address.bai_nome = searchCep.bairro
              ? searchCep.bairro.toUpperCase()
              : "";
          }

          this.hasNeighborhood = !!this.form.address.bai_nome;

          this.form.address.end_referencia = null;

          this.cleanValidate();
          return true;
        } else {
          toast.error("CEP não encontrado");
          return false;
        }
      } catch (pErr) {
        toast.error("CEP não encontrado");
      } finally {
        this.$loader.finish();
      }
    },

    fillAddress(pData) {
      this.form.address.end_cep = pData.end_cep;

      this.form.address.tlg_id = pData?.publicPlaceType?.tlg_id;
      this.form.address.tlg_nome = pData?.publicPlaceType?.tlg_nome;

      this.form.address.end_id_tipos_logradouros =
        pData?.publicPlaceType?.tlg_id;
      this.form.address.est_id = pData?.neighborhood?.city?.state?.est_id;
      this.form.address.est_sigla = pData?.neighborhood?.city?.state?.est_sigla;

      this.form.address.bai_nome = pData?.neighborhood?.bai_nome;

      this.form.address.mun_id = pData?.neighborhood?.city?.mun_id;
      this.form.address.mun_nome = pData?.neighborhood?.city?.mun_nome;

      this.form.address.end_logradouro = pData.end_logradouro;
      this.form.address.end_numero = pData.end_numero;
      this.form.address.end_complemento = pData.end_complemento;
      this.form.address.end_referencia = pData.end_referencia;
    },

    async fillUnitHealthAddress() {
      if (this.unitHealthAddress) {
        const cep = this.UnitHealthAddress.end_cep;

        this.form.address.end_cep = cep;
        this.form.address.end_id_tipos_logradouros = this.UnitHealthAddress.publicPlace?.tlg_id;
        this.form.address.tlg_id = this.UnitHealthAddress.publicPlace?.tlg_id;
        this.form.address.tlg_nome = this.UnitHealthAddress.publicPlace?.tlg_nome;
        this.form.address.end_logradouro = this.UnitHealthAddress.end_logradouro;
        this.form.address.bai_nome = this.UnitHealthAddress.neighborhood?.bai_nome;
        this.form.address.mun_id = this.UnitHealthAddress.neighborhood?.city?.mun_id;
        this.form.address.mun_nome = this.UnitHealthAddress.neighborhood?.city?.mun_nome;
        this.form.address.est_id = this.UnitHealthAddress.neighborhood?.city?.state?.est_id;
        this.form.address.est_sigla = this.UnitHealthAddress.neighborhood?.city?.state?.est_sigla;
        this.form.address.end_numero = this.UnitHealthAddress.end_numero;
        this.form.address.end_complemento = this.UnitHealthAddress.end_complemento;
        this.form.address.end_referencia = this.UnitHealthAddress.end_referencia;
      } else {
        this.cleanForm();
      }
    },

    validateSpecialCharacters(pValue, pErrors) {
      const format = /[!@#$%^&*()_+\-={};':"\\|,.|]+/gi;
      if (format.test(pValue)) {
        pErrors.push("Campo inválido. Remova os caracteres especiais.");
        return false;
      }
      return true;
    },

    removeExtraSpacesBeforeSend() {
      this.form.end_referencia = this.$utils.sanitize.removeExtraSpaces(
        this.form.end_referencia,
      );
      this.form.end_complemento = this.$utils.sanitize.removeExtraSpaces(
        this.form.end_complemento,
      );
      this.form.end_logradouro = this.$utils.sanitize.removeExtraSpaces(
        this.form.end_logradouro,
      );
      this.form.bai_nome = this.$utils.sanitize.removeExtraSpaces(
        this.form.bai_nome,
      );
    },

    getAddressForm() {
      this.removeExtraSpacesBeforeSend();
      return this.form.address;
    },

    loadFromCadsus(pData) {
      if (!pData) return;
      this.cleanForm();
      if (
        pData.Enderecos &&
        pData.Enderecos.Endereco &&
        pData.Enderecos.Endereco.CEP
      ) {
        const cep = pData.Enderecos.Endereco.CEP.numeroCEP;
        const numero = pData.Enderecos.Endereco.numero;
        const complemento = pData.Enderecos.Endereco.complemento;

        this.form.address.end_cep = cep;
        this.searchAndFillAddressByCep(cep);

        if (!this.form.address.end_logradouro) {
          this.form.address.end_logradouro =
            pData.Enderecos.Endereco.nomeLogradouro;
        }
        if (!this.form.address.bai_nome) {
          this.form.address.bai_nome =
            pData.Enderecos.Endereco.Bairro.descricaoBairro;
        }
        if (!this.form.address.end_numero) {
          this.form.address.end_numero = numero;
        }
        if (!this.form.address.end_complemento) {
          this.form.address.end_complemento = complemento;
        }
      }
    },

    cleanForm() {
      this.hasPlaceType = false;
      this.hasPublicPlace = false;
      this.hasNeighborhood = false;
      this.hasCity = false;
      this.hasState = false;
      this.unitHealthAddress = false;
      this.form.address = {
        est_id: 0,
        mun_id: 0,
        tlg_id: 0,
        bai_nome: "",
        end_cep: "",
        end_numero: "",
        end_logradouro: "",
        end_complemento: "",
        mun_nome: "",
        est_sigla: "",
        tlg_nome: "",
        end_referencia: "",
      };
      this.cleanValidate();
    },

    cleanValidate() {
      this.$refs.cep.cleanValidate();
      this.$refs.publicPlaceType.cleanValidate();
      this.$refs.address.cleanValidate();
      this.$refs.neighborhood.cleanValidate();
      this.$refs.city.cleanValidate();
      this.$refs.state.cleanValidate();
      this.$refs.numberField.cleanValidate();
    },
  },
};
</script>
