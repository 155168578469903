<style src="./RgSuggestTransferEmployee.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="rgSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :open-only-top="openOnlyTop"
    :rules="rules"
    :disabled="disabled"
    :maxlength="maxlength"
    min="0"
    @clear="clear"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="employee-item"
    >
      <div class="employee-row-top">
        <span class="employee-name">
          {{ item.cns_profissional }} -
          {{ item.pes_nome }}
        </span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchTransferEmployee } from "./action";

export default {
  name: "RgSuggestEmployee",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    unitHealthId: {
      type: Number,
      default: null,
    },
    occupationCode: {
      type: String,
      default: null,
    },
    pesName: {
      type: String,
      default: "",
    },
    dataItem: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "CNS e Nome do Profissional ",
    },
    placeholder: {
      type: String,
      default: "Digite o nome do profissional",
    },
    title: {
      type: String,
      default: "CNS e Nome do Profissional ",
    },
    openOnlyTop: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
    },
  },
  data() {
    return {
      captionRaw: "cns_profissional - pes_nome",
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.rgSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.rgSuggest.validate();
    },

    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },

    async doSearch(pSearchTerm, pLimit = null) {
      if (
        this.occupationCode !== "" &&
        this.pesName !== "" &&
        this.unitHealthId
      ) {
        const variables = {
          pes_nome: this.pesName,
          uns_id: this.unitHealthId,
          ocp_code: this.occupationCode,
          searchQuery: pSearchTerm,
          limit: pLimit,
        };

        const value = await SearchTransferEmployee({ variables });

        return {
          rows: value.rows.map((element) => {
            return {
              ...element,
            };
          }),
          count: value.count,
        };
      }
    },

    clear() {
      this.$emit("clear");
    },
  },
};
</script>
