import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  DuplicidadeMunicipioTipoUnidadeException: `Já existe registro com essas informações`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "farmacia/controller-municipio-tipo-unidade/cadastrar",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
