import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacyProductType from "$pharmacy/submodules/register/view/product-type/PharmacyProductType";

export default {
  path: "/pharmacy/product-type",
  name: "pharmacy.product-type",
  components: {
    default: PharmacyProductType,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Cadastro",
        link: OpenSubModule("pharmacy"),
      },
      { label: "Produtos", link: "/pharmacy/product" },
      {
        label: "Cadastrar Tipos de Produto",
        link: "/pharmacy/product-type",
      },
    ],
  },
};
