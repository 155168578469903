<template>
  <section v-if="show" class="modal-view-procedure-reviews">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <section slot="header" class="modal-view-procedure-reviews-header">
        <div class="title">Visualizar Críticas do Procedimento</div>
      </section>

      <section slot="body" class="modal-view-procedure-reviews-body">
        <div class="alert-icon">
          <font-awesome-icon
            v-if="hasError"
            icon="fa-solid fa-circle-exclamation"
            shake
            style="color: #f96b70"
            class="icon"
          />
          <font-awesome-icon
            v-else
            icon="icon fa-solid fa-circle-check"
            beat
            style="color: #0bb98c"
            class="icon"
          />
          <span v-if="hasError" class="message">
            Foram encontradas inconsistências nos dados do Procedimento
          </span>
          <span v-else class="message">
            Os dados do Procedimento estão de acordo com a tabela SIGTAP
          </span>
        </div>

        <hr class="separator" />

        <div class="subtitle">
          <span v-if="isBPAC"> Dados do Procedimento BPA - C </span>
          <span v-else> Dados do Procedimento BPA - I </span>
        </div>

        <section class="information">
          <div v-if="hasError" class="information-tooltip">
            <Tooltip class="tooltip" startOpen :message="messageTooltip">
              <IconLightBulbHelper slot="icon" />
            </Tooltip>
          </div>

          <section v-if="isBPAC">
            <section class="grid-information">
              <div class="review" :class="{ hasError: isCriticalCompetence }">
                Competência:
                <span>
                  {{
                    formatCompetence(
                      criticismSelected.fco_competencia ||
                        criticismSelected.competence,
                    )
                  }}
                </span>
                <RgValidationAlert :alert="validateCompetence" />
              </div>
              <div
                v-if="criticismSelected.fco_folha"
                class="review"
                :class="{ hasError: isCriticalSheet }"
              >
                Folha:
                <span>
                  {{ formatSheet(criticismSelected.fco_folha) }}
                </span>
                <RgValidationAlert :alert="validateSheet" />
              </div>
              <div class="review" :class="{ hasError: isCriticalProcedure }">
                Procedimento:
                <span>
                  {{
                    criticismSelected.fco_codigo_procedimento
                      ? getValue(
                          criticismSelected,
                          "procedureName",
                          "fco_codigo_procedimento",
                        )
                      : getValue(
                          criticismSelected,
                          "NO_PROCEDIMENTO",
                          "codigo_procedimento",
                        )
                  }}
                </span>
                <RgValidationAlert :alert="validateProcedure" />
              </div>
              <div class="review" :class="{ hasError: isCriticalCBO }">
                CBO:
                <span>
                  {{
                    criticismSelected.fco_cbo
                      ? getValue(criticismSelected, "occupationName", "fco_cbo")
                      : getValue(criticismSelected, "cbo_name", "cbo")
                  }}
                </span>
                <RgValidationAlert :alert="validateCBO" />
              </div>
              <div class="review" :class="{ hasError: isCriticalAge }">
                Idade:
                <span>
                  {{
                    criticismSelected.fco_idade
                      ? getValue(criticismSelected, "fco_idade")
                      : getValue(criticismSelected, "idade")
                  }}
                </span>
                <RgValidationAlert :alert="validateAge" />
              </div>
              <div class="review">
                Quantidade:
                <span>
                  {{
                    criticismSelected.fco_quantidade
                      ? getValue(criticismSelected, "fco_quantidade")
                      : getValue(criticismSelected, "quantidade")
                  }}
                </span>

                <RgValidationAlert :alert="validateQuantity" />
              </div>
            </section>
          </section>

          <section v-else>
            <section class="grid-information">
              <div class="review patient-name">
                Paciente:
                <span>
                  {{
                    criticismSelected.bof_nome
                      ? getValue(criticismSelected, "bof_nome")
                      : getValue(criticismSelected, "pes_nome")
                  }}
                </span>
              </div>
              <div class="review" :class="{ hasError: isCriticalCompetence }">
                Competência:
                <span>
                  {{
                    formatCompetence(
                      criticismSelected.bof_competencia ||
                        criticismSelected.competence,
                    )
                  }}
                </span>
                <RgValidationAlert :alert="validateCompetence" />
              </div>
              <div
                v-if="criticismSelected.pdf_folha"
                class="review"
                :class="{ hasError: isCriticalSheet }"
              >
                Folha:
                <span> {{ formatSheet(criticismSelected.pdf_folha) }} </span>
                <RgValidationAlert :alert="validateSheet" />
              </div>
              <div class="review" :class="{ hasError: isCriticalProcedure }">
                Procedimento:
                <span>
                  {{
                    criticismSelected.ppd_codigo_procedimento
                      ? getValue(
                          criticismSelected,
                          "NO_PROCEDIMENTO",
                          "ppd_codigo_procedimento",
                        )
                      : getValue(
                          criticismSelected,
                          "NO_PROCEDIMENTO",
                          "pdf_cod_procedimento",
                        )
                  }}
                </span>
                <RgValidationAlert :alert="validateProcedure" />
              </div>
              <div class="review" :class="{ hasError: isCriticalCBO }">
                CBO:
                <span>
                  {{
                    criticismSelected.pdf_cod_cbo
                      ? getValue(
                          criticismSelected,
                          "NO_OCUPACAO",
                          "pdf_cod_cbo",
                        )
                      : getValue(
                          criticismSelected,
                          "NO_OCUPACAO",
                          "CO_OCUPACAO",
                        )
                  }}
                </span>
                <RgValidationAlert :alert="validateCBO" />
              </div>
              <div class="review" :class="{ hasError: isCriticalAge }">
                Idade: <span> {{ getValue(criticismSelected, "idade") }} </span>
                <RgValidationAlert :alert="validateAge" />
              </div>
              <div class="review" :class="{ hasError: isCriticalGender }">
                Sexo:
                <span>
                  {{
                    criticismSelected.TP_SEXO
                      ? formatGender(criticismSelected.TP_SEXO)
                      : formatGender(criticismSelected.sex_sigla)
                  }}
                </span>
                <RgValidationAlert :alert="validateGender" />
              </div>
              <div class="review" :class="{ hasError: isCriticalQuantity }">
                Quantidade:
                <span>
                  {{
                    criticismSelected.pdf_quantidade
                      ? getValue(criticismSelected, "pdf_quantidade")
                      : getValue(criticismSelected, "quantidade")
                  }}
                </span>
                <RgValidationAlert :alert="validateQuantity" />
              </div>
              <div class="review" :class="{ hasError: isCriticalService }">
                Serviço:
                <span>
                  {{ getValue(criticismSelected, "NO_SERVICO", "CO_SERVICO") }}
                </span>
                <RgValidationAlert :alert="validateService" />
              </div>
              <div
                class="review"
                :class="{ hasError: isCriticalClassification }"
              >
                Classificação:
                <span>
                  {{
                    getValue(
                      criticismSelected,
                      "NO_CLASSIFICACAO",
                      "CO_CLASSIFICACAO",
                    )
                  }}
                </span>
                <RgValidationAlert :alert="validateClassification" />
              </div>

              <div class="review" :class="{ hasError: isCriticalCID }">
                CID:
                <span>
                  {{ getValue(criticismSelected, "NO_CID", "CO_CID") }}
                </span>
                <RgValidationAlert :alert="validateCID" />
              </div>

              <div
                v-if="criticismSelected.pdf_folha"
                class="review"
                :class="{ hasError: isCriticalCNPJ }"
              >
                CNPJ:
                <span>
                  {{ getValue(criticismSelected, "pdf_cnpj") }}
                </span>
                <RgValidationAlert :alert="validateCNPJ" />
              </div>

              <div
                v-if="isCriticalEqualsCNS"
                class="review"
                :class="{ hasError: isCriticalEqualsCNS }"
              >
                CNS:
                <span>
                  {{
                    criticismSelected.crs_numero
                      ? getValue(criticismSelected, "crs_numero")
                      : getValue(criticismSelected, "bof_cns")
                  }}
                </span>
                <RgValidationAlert :alert="validateEqualsCNS" />
              </div>

              <div
                v-if="!isCriticalEqualsCNS"
                class="review"
                :class="{ hasError: isCriticalCNS }"
              >
                CNS:
                <span>
                  {{
                    criticismSelected.bof_cns
                      ? getValue(criticismSelected, "bof_cns")
                      : getValue(criticismSelected, "crs_numero")
                  }}
                </span>
                <RgValidationAlert :alert="validateCNS" />
              </div>
            </section>
          </section>
        </section>
      </section>
    </RgBaseModal>
  </section>
</template>

<script>
import {
  RgBaseModal,
  RgValidationAlert,
  Tooltip,
  IconLightBulbHelper,
} from "~tokio/primitive";

export default {
  name: "ModalViewProcedureReviews",
  components: {
    RgBaseModal,
    RgValidationAlert,
    Tooltip,
    IconLightBulbHelper,
  },
  props: {
    show: {
      type: Boolean,
      dafault: false,
    },
    isBPAC: {
      require: true,
      type: Boolean,
      dafault: false,
    },
    criticismSelected: {
      require: true,
      type: Object,
      dafault: () => {},
    },
  },
  data() {
    return {
      hasError: false,
      validateCompetence: [],
      validateSheet: [],
      validateProcedure: [],
      validateCBO: [],
      validateAge: [],
      validateEqualsCNS: [],
      validateGender: [],
      validateQuantity: [],
      validateService: [],
      validateClassification: [],
      validateCID: [],
      validateCNS: [],
      validateCNPJ: [],
    };
  },
  computed: {
    messageTooltip() {
      return "Passe o cursor do mouse sobre os campos que apresentam Crítica, sinalizados pela cor vermelha";
    },
    hasCriticism() {
      return (
        this.criticismSelected && Object.keys(this.criticismSelected).length > 0
      );
    },
    hasValidProcedure() {
      return (
        this.criticismSelected.validProcedure &&
        Object.keys(this.criticismSelected.validProcedure).length > 0
      );
    },
    isCriticalCompetence() {
      return this.validateCompetence && this.validateCompetence.length > 0;
    },
    isCriticalSheet() {
      return this.validateSheet && this.validateSheet.length > 0;
    },
    isCriticalProcedure() {
      return this.validateProcedure && this.validateProcedure.length > 0;
    },
    isCriticalCBO() {
      return this.validateCBO && this.validateCBO.length > 0;
    },
    isCriticalAge() {
      return this.validateAge && this.validateAge.length > 0;
    },
    isCriticalEqualsCNS() {
      return this.validateEqualsCNS && this.validateEqualsCNS.length > 0;
    },
    isCriticalGender() {
      return this.validateGender && this.validateGender.length > 0;
    },
    isCriticalQuantity() {
      return this.validateQuantity && this.validateQuantity.length > 0;
    },
    isCriticalService() {
      return this.validateService && this.validateService.length > 0;
    },
    isCriticalClassification() {
      return (
        this.validateClassification && this.validateClassification.length > 0
      );
    },
    isCriticalCID() {
      return this.validateCID && this.validateCID.length > 0;
    },
    isCriticalCNS() {
      return this.validateCNS && this.validateCNS.length > 0;
    },
    isCriticalCNPJ() {
      return this.validateCNPJ && this.validateCNPJ.length > 0;
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.loadCriticsm(this.criticismSelected);
      } else {
        this.close();
      }
    },
  },
  methods: {
    loadCriticsm(pCriticism) {
      this.hasError = pCriticism.hasError;
      if (this.hasCriticism) {
        if (this.hasValidProcedure) {
          const validateCriterion = pCriticism.validProcedure;
          const ERROR = "0";

          if (validateCriterion.CO_OCUPACAO === ERROR) {
            this.validateCBO.push("CBO incompatível com Procedimento");
          }
          if (validateCriterion.QT_MAXIMA_EXECUCAO === ERROR) {
            this.validateQuantity.push(
              "O valor é maior/menor que a quantidade permitida para esse procedimento",
            );
          }
          if (validateCriterion.ATRIBUTOS.IDADE === ERROR) {
            this.validateAge.push("A idade do paciente não foi inserida");
          }
          if (validateCriterion.CNS === ERROR) {
            this.validateEqualsCNS.push(
              "Os números CNS do médico e do paciente não podem ser iguais",
            );
          }
          if (validateCriterion.VL_IDADE_MAXIMA === ERROR) {
            this.validateAge.push(
              "Valor não compatível com idade máx. e mínima",
            );
          }
          if (validateCriterion.VL_IDADE_MINIMA === ERROR) {
            this.validateAge.push(
              "Valor não compatível com idade máx. e mínima",
            );
          }
          if (
            validateCriterion.CO_CLASSIFICACAO === ERROR ||
            (!pCriticism.CO_CLASSIFICACAO &&
              validateCriterion.ATRIBUTOS.SERVICO_CLASSIFICACAO === ERROR)
          ) {
            this.validateClassification.push(
              "Classificação incompatível com Procedimento",
            );
          }
          if (
            validateCriterion.CO_SERVICO === ERROR ||
            (!pCriticism.CO_SERVICO &&
              validateCriterion.ATRIBUTOS.SERVICO_CLASSIFICACAO === ERROR)
          ) {
            this.validateService.push("Serviço incompatível com Procedimento");
          }
          if (validateCriterion.TP_SEXO === ERROR) {
            this.validateGender.push(
              "O procedimento não é realizado para o sexo inserido",
            );
          }
          if (
            validateCriterion.CO_CID === ERROR ||
            validateCriterion.ATRIBUTOS.CID === ERROR
          ) {
            this.validateCID.push("CID incompatível com Procedimento");
          }
          if (validateCriterion.CBO === ERROR) {
            this.validateCBO.push("O CBO do profissional não foi inserido");
          }
          if (validateCriterion.ATRIBUTOS.CNS === ERROR) {
            this.validateCNS.push("O CNS do paciente não foi inserido");
          }
          if (validateCriterion.ATRIBUTOS.CNPJ === ERROR) {
            this.validateCNPJ.push("O CNPJ do profissional não foi inserido");
          }
        }
      } else {
        this.$toaster.warning("Não foi possível carregar os dados.");
      }
    },
    getValue(pItem, pKey, pConcat) {
      if (pItem && pItem[pKey]) {
        if (!pConcat) {
          return pItem[pKey];
        } else if (pItem[pConcat]) {
          return pItem[pConcat] + " - " + pItem[pKey];
        } else {
          return "Não informado" + " - " + pItem[pKey];
        }
      } else {
        return "Não informado";
      }
    },
    formatCompetence(pCompetence) {
      const month = pCompetence ? pCompetence.substring(4, 6) : "NÃO INFORMADO";
      const year = pCompetence ? pCompetence.substring(0, 4) : "NÃO INFORMADO";
      return month + "/" + year;
    },
    formatSheet(pSheet) {
      if (pSheet.length === 1) {
        return "00" + pSheet;
      } else if (pSheet.length === 2) {
        return "0" + pSheet;
      } else {
        return pSheet;
      }
    },
    formatGender(pGender) {
      if (pGender) {
        return pGender === "M" ? "MASCULINO" : "FEMININO";
      } else {
        return "NÃO INFORMADO";
      }
    },
    close() {
      this.hasError = false;
      this.validateCompetence = [];
      this.validateSheet = [];
      this.validateProcedure = [];
      this.validateCBO = [];
      this.validateCNPJ = [];
      this.validateCNS = [];
      this.validateAge = [];
      this.validateEqualsCNS = [];
      this.validateGender = [];
      this.validateQuantity = [];
      this.validateService = [];
      this.validateClassification = [];
      this.validateCID = [];
      this.$emit("close");
    },
  },
};
</script>
