export default {
  GET_SELECTED_EMPLOYEE: (state) => state.selected_employee_data,
  GET_EMPLOYEE_SEARCH_RESULT: (state) => state.employee_search_result,
  GET_SELECTED_EXTERNAL_HOSPITALIZATION_RESULT: (state) =>
    state.selected_queue_external_hospitalization_data,
  GET_FILTER_HOSPITALIZATION_QUEUE: (state) =>
    state.filter_hospitalization_queue_data,
  GET_TYPE_SCHEDULE: (state) => state.type_schedule,
  GET_FILTER_EXTERNALLY_SCHEDULE: (state) => state.filter_externally_schedule,
  GET_FILTER_SCHEDULE_PATIENT_LIST: (state) =>
    state.filter_schedule_patient_list,
  GET_FILTER_PATIENT_EXCLUDE: (state) => state.filter_patient_exclude_data,
};
