import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const duplicationErrors = {
  BloquearDuplicidadeAoInserirFilaConsultaException: `Paciente já consta na fila para essa ocupação.`,
  InformarDuplicidadeAoInserirFilaConsultaException: `Aviso: paciente já consta na fila para essa ocupação.`,
};

export default async (
  context,
  { idFilaConsulta, strMotivo, validarDuplicidade },
) => {
  return AuthLegacyRequest.post(
    "consultas/controller-fila-consulta/voltar-paciente-fila",
    {
      idFilaConsulta,
      strMotivo,
      validarDuplicidade,
    },
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: duplicationErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
