<template>
  <div v-show="show" class="modal-history-sigtap-bdsia">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-history-movement-header">
        <span class="title"> Histórico de Competências Importadas </span>
      </div>
      <div slot="body" class="modal-history-movement-body">
        <FormBase title="Dados da Competência Atual">
          <div class="container-data">
            <span class="content"
              >Competência SIGTAP: <strong>{{ competenceSigtap }}</strong></span
            >
            <span class="content"
              >Competência BDSIA: <strong>{{ competenceBdsia }}</strong></span
            >
            <span class="content"
              >Arquivo SIGTAP: <strong>{{ fileSigtap }}</strong></span
            >
            <span class="content"
              >Arquivo BDSIA: <strong>{{ fileBdsia }}</strong></span
            >
          </div>
          <hr />
          <div class="table-history-import">
            <SmartTable
              ref="smartTable"
              name="ListHistoryImport"
              :columns="COLUMNS"
              :body="listHistory"
              :total="Number(listHistory.length)"
              :show-pagination="false"
              removeBtnColumns
              class="smart-table"
            />
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import moment from "moment";

export default {
  name: "ModalHistorySigtapBdsia",
  components: {
    RgBaseModal,
    FormBase,
    SmartTable,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listHistory: [],
      competenceBdsia: "-",
      competenceSigtap: "-",
      fileBdsia: "-",
      fileSigtap: "-",
      cmp: null,
    };
  },
  computed: {},
  watch: {
    show: {
      handler(pValue) {
        if (pValue) {
          this.loadData();
        }
      },
    },
  },
  mounted() {
    this.cmp = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
  },
  created() {
    this.COLUMNS = [
      {
        name: "Data da Importação",
        key: "data_importacao",
        align: "left",
        active: true,
      },
      {
        name: "Competência",
        key: "competenceFormat",
        active: true,
      },
      {
        name: "Arquivo SIGTAP",
        key: "hsb_arquivo_sigtap",
        align: "left",
        active: true,
      },
      {
        name: "Arquivo BDSIA",
        key: "hsb_arquivo_bdsia",
        align: "left",
        active: true,
      },
      { name: "Usuário(a)", key: "usu_nome", align: "left", active: true },
    ];
  },
  methods: {
    async loadData() {
      try {
        this.$loader.start();

        const result = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/SEARCH_HISTORY_IMPORT",
          {
            orderDate: true,
          },
        );

        this.listHistory = result.SearchHistoryImportSigtapBdsia?.rows?.map(
          (element) => {
            return {
              ...element,
              competenceFormat: moment(
                element.hsb_competencia_sigtap,
                "YYYYMM",
              ).format("MM-YYYY"),
            };
          },
        );

        const lastCompetence = this.listHistory.filter(
          (element) => element.hsb_competencia_sigtap === this.cmp,
        );

        if (lastCompetence.length > 0) {
          this.competenceSigtap = moment(
            lastCompetence[0].hsb_competencia_sigtap,
            "YYYYMM",
          ).format("MM/YYYY");
          this.competenceBdsia = moment(
            lastCompetence[0].hsb_competencia_bdsia,
            "YYYYMM",
          ).format("MM/YYYY");
          this.fileSigtap = lastCompetence[0].hsb_arquivo_sigtap;
          this.fileBdsia = lastCompetence[0].hsb_arquivo_bdsia;
        }
      } catch (error) {
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha ao obter o Histórico de Importação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
