<template>
  <section class="person-document">
    <FormBase title="Documentos" class="form-base-document">
      <Tooltip
        message="Para manter as informações atualizadas, é recomendado o registro da documentação do paciente no cadastro"
        startOpen
        class="tooltip"
      >
        <IconExclamationHelper slot="icon" hollow />
      </Tooltip>

      <div class="grid-document">
        <section class="documents">
          <RgValidatorForm ref="validator">
            <div class="first-area">
              <RgComboboxDocumentType
                id="tipo-documento"
                v-model="documentTypeId"
                :disabled="editDocument"
                class="document-type"
                @change="getDocumentType"
              />

              <!-- FIXME: Campos NÚMERO DO DOCUMENTO-->
              <RgInputCpf
                v-show="documentType === 'CPF'"
                id="cpf"
                ref="cpf"
                v-model="form.cpf.cpf_numero"
                :rules="{
                  required: isRequiredCpf,
                }"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                label="Número do Documento"
                class="document-number"
                @onPaste="onPaste"
              />

              <RgInput
                v-show="documentType === 'IDENTIDADE'"
                id="identidade"
                ref="identity"
                v-model="form.rg.rgs_numero"
                v-mask="'##.###.###-#'"
                :class="{ disable: cantEdit }"
                :rules="{ required: identityIsRequired }"
                :disabled="cantEdit"
                label="Número do Documento"
                placeholder="00.000.000-0"
                class="document-number"
              />

              <RgInputCns
                v-show="documentType === 'CNS'"
                id="cns"
                ref="cns"
                v-model="form.cns.crs_numero"
                :requiredValue="isRequiredCns"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                label="Número do Documento"
                class="document-number"
              />

              <RgInputCnh
                v-show="documentType === 'CNH'"
                id="cnh"
                v-model="form.cnh.cnh_numero"
                :rules="{ fn: validateCnh }"
                label="Número do Documento"
                placeholder="00.000.000-0"
                class="document-number"
              />

              <RgInput
                v-show="documentType === 'CTPS'"
                id="ctps"
                ref="ctps"
                v-model="form.ctps.ctt_numero"
                v-mask="'###############'"
                :rules="{ required: ctpsIsRequired, number: true }"
                :maxlength="15"
                label="Número do Documento"
                placeholder="000000000000000"
                class="document-number"
              />

              <RgInputPis
                v-show="documentType === 'PIS'"
                id="pis"
                ref="pis"
                v-model="form.pisPasep.pis_numero"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                label="Número do Documento"
                class="document-number"
              />

              <RgInput
                v-show="documentType === 'CERTIDAO DE NASCIMENTO NOVA'"
                id="certidao-nascimento"
                v-model="form.newBirthCertificate.ctn_numero"
                v-mask="'################################'"
                :maxlength="32"
                label="Número do Documento"
                placeholder="000000000"
                class="document-number"
              />

              <RgInput
                v-show="documentType === 'TÍTULO DE ELEITOR'"
                id="titulo-eleitor"
                ref="voterRegistration"
                v-model="form.voterRegistration.tit_numero"
                v-mask="'############'"
                :rules="{
                  required: voterRegistrationIsRequired,
                  fn: validateTitleNumber,
                }"
                :maxlength="13"
                label="Número do Documento"
                placeholder="000000000000"
                class="document-number"
              />

              <!-- TODO: A regra irá analisar o campo -->
              <!-- <RgInput
              v-else-if="hasLinkNewborn"
              v-model="form.dnv"
              :class="{ disable: cantEdit && !hasLinkNewborn }"
              :disabled="cantEdit && !hasLinkNewborn"
              label="DNV"
              title="Declaração de Nascido Vivo"
            /> -->

              <RgInput
                v-show="disableDocumentNumber"
                id="certidao"
                :disabled="disableDocumentNumber"
                :class="{ disable: disableDocumentNumber }"
                label="Número do Documento"
                placeholder="00000000000"
                class="document-number"
              />
              <!-- FIXME: Fim dos campos NÚMERO DO DOCUMENTO-->

              <div class="buttons">
                <RgAddButton
                  v-show="!editDocument"
                  id="adicionar-documento"
                  :disabled="disabledAddButton"
                  @click="addDocumentList"
                />
                <MediumButton
                  v-show="!editDocument"
                  id="anexos"
                  :background-color="'#1E88A9'"
                  label="Anexos"
                  @click="showModalAttachments"
                />
                <MediumButton
                  v-show="editDocument"
                  id="cancelar-edicao-documento"
                  :background-color="'#FF7F2A'"
                  label="Cancelar"
                  @click="cancelEditDocumentList"
                />
                <MediumButton
                  v-show="editDocument"
                  id="salvar-edicao-documento"
                  :background-color="'#1E88A9'"
                  :disabled="disabledAddButton"
                  label="Salvar"
                  @click="saveEditDocumentList"
                />
              </div>
            </div>

            <!-- FIXME: Demais campos de acordo com o TIPO DE DOCUMENTO-->
            <div class="second-area">
              <div v-show="documentType === 'CPF'" class="area">
                <RgToggleButton
                  id="receber-notificacao-cpf"
                  :value="receiveNotification"
                  :valueSync="true"
                  :description="'Receber notificações pelo App ?'"
                  @change="handleAppNotification"
                />
              </div>

              <div v-show="documentType === 'IDENTIDADE'" class="area">
                <div class="grid identity">
                  <RgComboboxIssuingBody
                    id="orgao-emissor-identidade"
                    ref="issuingBody"
                    v-model="form.rg.rgs_id_orgaos_emissores"
                    :class="{ disable: cantEdit }"
                    :disabled="cantEdit"
                    :rules="{ required: identityIsRequired }"
                    label="Órgão Emissor"
                    @change="getIssuingBody"
                  />

                  <RgInputDate
                    id="data-emissao-identidade"
                    ref="emissionDate"
                    v-model="form.rg.rgs_expedicao"
                    :class="{ disable: cantEdit }"
                    :rules="{
                      required: identityIsRequired,
                      fn: verifyDateEntry,
                    }"
                    :disabled="cantEdit"
                    :max-date="new Date()"
                    label="Data de Emissão"
                  />
                </div>
              </div>

              <div
                v-show="documentType === 'CERTIDAO DE CASAMENTO'"
                class="area"
              >
                <div class="grid certificate">
                  <div class="row-1">
                    <RgInput
                      id="cartorio-certidao-casamento"
                      ref="registry"
                      v-model="form.weddingCertificate.ctc_cartorio"
                      :rules="{ required: weddingCertificateIsRequired }"
                      :maxlength="100"
                      label="Cartório"
                      placeholder="Digite o nome do cartório"
                    />

                    <RgInput
                      id="livro-certidao-casamento"
                      ref="book"
                      v-model="form.weddingCertificate.ctc_livro"
                      :rules="{ required: weddingCertificateIsRequired }"
                      :maxlength="20"
                      label="Livro"
                      placeholder="Digite os dados do livro de registro"
                    />

                    <RgInput
                      id="folha-certidao-casamento"
                      ref="leaf"
                      v-model="form.weddingCertificate.ctc_folha"
                      v-mask="'####'"
                      :rules="{
                        required: weddingCertificateIsRequired,
                      }"
                      :maxlength="4"
                      label="Folha"
                      placeholder="0000"
                    />
                  </div>

                  <div class="row-2">
                    <RgInput
                      id="termo-certidao-casamento"
                      ref="term"
                      v-model="form.weddingCertificate.ctc_termo"
                      v-mask="'########'"
                      :rules="{
                        required: weddingCertificateIsRequired,
                      }"
                      :maxlength="8"
                      label="Termo"
                      placeholder="00000000"
                    />

                    <RgInputDate
                      id="data-emissao-certidao-casamento"
                      ref="dateIssue"
                      v-model="form.weddingCertificate.ctc_emissao"
                      :rules="{ required: weddingCertificateIsRequired }"
                      :max-date="new Date()"
                      label="Data de Emissão"
                    />
                  </div>
                </div>
              </div>

              <div
                v-show="documentType === 'CERTIDAO DE NASCIMENTO'"
                class="area"
              >
                <div class="grid certificate">
                  <div class="row-1">
                    <RgInput
                      id="cartorio-certidao-nascimento"
                      ref="registryBirth"
                      v-model="form.birthCertificate.ctc_cartorio"
                      :rules="{ required: birthCertificateIsRequired }"
                      :maxlength="100"
                      label="Cartório"
                      placeholder="Digite o nome do cartório"
                    />

                    <RgInput
                      id="livro-certidao-nascimento"
                      ref="bookBirth"
                      v-model="form.birthCertificate.ctc_livro"
                      :rules="{ required: birthCertificateIsRequired }"
                      :maxlength="20"
                      label="Livro"
                      placeholder="Digite os dados do livro de registro"
                    />

                    <RgInput
                      id="folha-certidao-nascimento"
                      ref="leafBirth"
                      v-model="form.birthCertificate.ctc_folha"
                      v-mask="'####'"
                      :rules="{
                        required: birthCertificateIsRequired,
                      }"
                      :maxlength="4"
                      label="Folha"
                      placeholder="0000"
                    />
                  </div>

                  <div class="row-2">
                    <RgInput
                      id="termo-certidao-nascimento"
                      ref="termBirth"
                      v-model="form.birthCertificate.ctc_termo"
                      v-mask="'########'"
                      :rules="{
                        required: birthCertificateIsRequired,
                      }"
                      :maxlength="8"
                      label="Termo"
                      placeholder="00000000"
                    />

                    <RgInputDate
                      id="data-emissao-certidao-nascimento"
                      ref="dateIssueBirth"
                      v-model="form.birthCertificate.ctc_emissao"
                      :rules="{ required: birthCertificateIsRequired }"
                      :max-date="new Date()"
                      label="Data de Emissão"
                    />
                  </div>
                </div>
              </div>

              <div v-show="documentType === 'CTPS'" class="area">
                <div class="grid ctps">
                  <RgSuggestState
                    id="estado-ctps"
                    ref="stateCtps"
                    v-model="form.ctps.est_sigla"
                    :rules="{ required: ctpsIsRequired }"
                    label="Estado"
                    placeholder="Selecione"
                    @selected="selectingCtpsState"
                  />

                  <RgInput
                    id="serie-ctps"
                    ref="serial"
                    v-model="form.ctps.ctt_serie"
                    v-mask="'###############'"
                    :rules="{ required: ctpsIsRequired, number: true }"
                    :maxlength="15"
                    label="Série"
                    placeholder="000000000000000"
                  />

                  <RgInputDate
                    id="data-emissao-ctps"
                    ref="dateIssueCtps"
                    v-model="form.ctps.ctt_emissao"
                    :rules="{ required: ctpsIsRequired }"
                    :max-date="new Date()"
                    class="dateIssue"
                    label="Data de Emissão"
                  />
                </div>
              </div>

              <div v-show="documentType === 'TÍTULO DE ELEITOR'" class="area">
                <div class="grid voter-registration">
                  <RgInput
                    id="zona-titulo-eleitor"
                    ref="zone"
                    v-model="form.voterRegistration.tit_zona"
                    v-mask="'###'"
                    :rules="{
                      required: voterRegistrationIsRequired,
                      number: true,
                    }"
                    :maxlength="3"
                    label="Zona"
                    placeholder="0-00"
                  />

                  <RgInput
                    id="secao-titulo-eleitor"
                    ref="section"
                    v-model="form.voterRegistration.tit_secao"
                    v-mask="'####'"
                    :rules="{
                      required: voterRegistrationIsRequired,
                      number: true,
                    }"
                    :maxlength="4"
                    label="Seção"
                    placeholder="0000"
                  />

                  <RgSuggestState
                    id="estado-titulo-eleitor"
                    ref="stateVoterRegistration"
                    v-model="form.voterRegistration.est_sigla"
                    :rules="{ required: voterRegistrationIsRequired }"
                    label="Estado"
                    placeholder="Selecione"
                    @selected="selectingVoterRegistrationState"
                  />

                  <RgSuggestCity
                    id="cidade-titulo-eleitor"
                    ref="cityVoterRegistration"
                    v-model="form.voterRegistration.mun_nome"
                    :disabled="!form.voterRegistration.est_id"
                    :state="form.voterRegistration.est_id"
                    :filterByState="true"
                    :rules="{ required: voterRegistrationIsRequired }"
                    @selected="selectingVoterRegistrationCity"
                  />
                </div>
              </div>
            </div>
          </RgValidatorForm>

          <!-- FIXME: Fim dos campos -->
        </section>

        <section class="table">
          <SmartTable
            v-if="documents.length > 0"
            ref="smartTable"
            name="PersonDocuments"
            :disabled="editDocument"
            :columns="COLUMNS"
            :body="documents"
            :total="documents.length"
            :show-pagination="false"
            :initial-columns="5"
            toggleSelected
            remove-btn-columns
            @getLine="selectLine"
          >
            <div slot="top-buttons" class="top-buttons">
              <RgEditButton
                :disabled="disabledEditButton"
                class="edit"
                @click="editDocumentList"
              />
              <RgLessButton
                :disabled="disabledEditButton"
                @click="removeDocumentList"
              />
            </div>
          </SmartTable>
          <div v-else class="empty">
            <IconEmpty class="svg" />
            <span>Não há documentos cadastrados</span>
          </div>
        </section>
      </div>
    </FormBase>
    <ModalAttachments
      :show="modalAttachments"
      :disabled="!modalAttachments"
      @confirmed="saveAttachmentsIntoMemory"
      @close="closeModalAttachments"
      @totalChanged="updateTotalFiles"
    />
  </section>
</template>

<script>
import {
  Tooltip,
  IconEmpty,
  RgValidatorForm,
  RgEditButton,
  RgLessButton,
  RgAddButton,
  MediumButton,
  RgInput,
  RgToggleButton,
  RgInputCnh,
  RgInputDate,
  IconExclamationHelper,
} from "~tokio/primitive";

import moment from "moment";

import { AlertError } from "~tokio/primitive/notification";

import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

import { SmartTable, FormBase } from "~tokio/foundation";

import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputPis from "$person/common/components/input/rg-input-pis/RgInputPis";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgComboboxDocumentType from "$person/common/components/combobox/rg-combobox-document-type/RgComboboxDocumentType";
import RgComboboxIssuingBody from "$person/common/components/combobox/rg-combobox-issuing-body/RgComboboxIssuingBody";
import ModalAttachments from "$person/common/components/modal/modal-attachments/ModalAttachments";

const FORM_DOCUMENTS = {
  weddingCertificate: {
    ctc_id: 0,
    ctc_cartorio: "",
    ctc_livro: "",
    ctc_folha: "",
    ctc_termo: "",
    ctc_emissao: "",
  },
  birthCertificate: {
    ctc_id: 0,
    ctc_cartorio: "",
    ctc_livro: "",
    ctc_folha: "",
    ctc_termo: "",
    ctc_emissao: "",
  },
  newBirthCertificate: {
    ctn_id: 0,
    ctn_numero: "",
  },
  ctps: {
    ctt_id: 0,
    ctt_numero: "",
    ctt_serie: "",
    ctt_emissao: "",
    ctt_id_estados: 0,
    est_sigla: "",
  },
  voterRegistration: {
    tit_id: 0,
    tit_id_municipios: 0,
    tit_numero: "",
    tit_zona: "",
    tit_secao: "",
    est_id: 0,
    est_sigla: "",
    mun_nome: "",
  },
  rg: {
    rgs_id: 0,
    rgs_numero: "",
    rgs_id_orgaos_emissores: 0,
    rgs_orgaos_nome: "",
    rgs_expedicao: "",
  },
  cpf: {
    cpf_id: 0,
    cpf_numero: "",
  },
  cnh: {
    cnh_id: 0,
    cnh_numero: "",
  },
  cns: {
    crs_id: 0,
    crs_numero: "",
  },
  pisPasep: {
    pis_id: 0,
    pis_numero: "",
  },
};

export default {
  name: "PersonDocument",
  directives: { mask },
  components: {
    RgValidatorForm,
    SmartTable,
    FormBase,
    Tooltip,
    IconEmpty,
    RgInput,
    RgInputDate,
    RgInputCnh,
    RgInputCns,
    RgInputCpf,
    RgInputPis,
    RgComboboxIssuingBody,
    RgComboboxDocumentType,
    RgSuggestCity,
    RgSuggestState,
    RgToggleButton,
    RgEditButton,
    RgLessButton,
    RgAddButton,
    MediumButton,
    ModalAttachments,
    IconExclamationHelper,
  },
  props: {
    pesId: {
      type: Number,
      default: 0,
    },
    cantEdit: {
      type: Boolean,
      default: false,
    },
    hasLinkNewborn: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    birthDate: Date,
    requiredCPF: Boolean,
    requiredCNS: Boolean,
    disabledByModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      documents: [],
      documentsRemoved: [],
      document: {},
      documentType: null,
      documentTypeId: null,
      weddingCertificate: null,
      isRequiredCpf: false,
      isRequiredCns: false,
      receiveNotification: false,
      selectedDocument: null,
      selectedDocumentIndex: null,
      new_attachments: [],
      attach_total_quantity: 0,
      issuingBody: null,
      editDocument: false,
      documentRequired: false,
      form: this.$utils.obj.DeepCopy(FORM_DOCUMENTS),
      notification: {
        nop_id: null,
        cpf_numero: null,
      },
      modalAttachments: false,
      validatePrivateSector: false,
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      documentData: "Person/Patient/GET_DOCUMENT",
      contactData: "Person/Patient/GET_CONTACT",
      isNewBorn: "Person/Patient/IS_NEWBORN",
      isPrivateSector: "Login/GET_PRIVATE_SECTOR",
    }),

    identityIsRequired() {
      return (
        this.form.rg?.rgs_numero?.length > 0 ||
        this.form.rg?.rgs_id_orgaos_emissores > 0 ||
        this.form.rg?.rgs_expedicao?.length > 0
      );
    },

    weddingCertificateIsRequired() {
      return (
        this.form.weddingCertificate?.ctc_cartorio?.length > 0 ||
        this.form.weddingCertificate?.ctc_livro?.length > 0 ||
        this.form.weddingCertificate?.ctc_folha?.length > 0 ||
        this.form.weddingCertificate?.ctc_termo?.length > 0 ||
        this.form.weddingCertificate?.ctc_emissao?.length > 0
      );
    },

    birthCertificateIsRequired() {
      return (
        this.form.birthCertificate?.ctc_cartorio?.length > 0 ||
        this.form.birthCertificate?.ctc_livro?.length > 0 ||
        this.form.birthCertificate?.ctc_folha?.length > 0 ||
        this.form.birthCertificate?.ctc_termo?.length > 0 ||
        this.form.birthCertificate?.ctc_emissao?.length > 0
      );
    },

    ctpsIsRequired() {
      return (
        this.form.ctps?.ctt_numero?.length > 0 ||
        this.form.ctps?.ctt_id_estados > 0 ||
        this.form.ctps?.ctt_serie?.length > 0 ||
        this.form.ctps?.ctt_emissao?.length > 0
      );
    },

    voterRegistrationIsRequired() {
      return (
        this.form.voterRegistration?.tit_numero?.length > 0 ||
        this.form.voterRegistration?.tit_zona?.length > 0 ||
        this.form.voterRegistration?.tit_secao?.length > 0 ||
        this.form.voterRegistration?.est_id > 0 ||
        this.form.voterRegistration?.tit_id_municipios > 0
      );
    },

    disableDocumentNumber() {
      return (
        this.documentType === "CERTIDAO DE CASAMENTO" ||
        this.documentType === "CERTIDAO DE NASCIMENTO"
      );
    },

    disabledAddButton() {
      if (!this.documentType) {
        return true;
      } else if (this.documentType === "CERTIDAO DE CASAMENTO") {
        return (
          !this.form.weddingCertificate.ctc_cartorio ||
          !this.form.weddingCertificate.ctc_livro ||
          !this.form.weddingCertificate.ctc_folha ||
          !this.form.weddingCertificate.ctc_termo ||
          !this.form.weddingCertificate.ctc_emissao
        );
      } else if (this.documentType === "CERTIDAO DE NASCIMENTO") {
        return (
          !this.form.birthCertificate.ctc_cartorio ||
          !this.form.birthCertificate.ctc_livro ||
          !this.form.birthCertificate.ctc_folha ||
          !this.form.birthCertificate.ctc_termo ||
          !this.form.birthCertificate.ctc_emissao
        );
      } else if (this.documentType === "IDENTIDADE") {
        return (
          !this.form.rg.rgs_numero ||
          !this.form.rg.rgs_id_orgaos_emissores ||
          !this.form.rg.rgs_expedicao
        );
      } else if (this.documentType === "TÍTULO DE ELEITOR") {
        return (
          !this.form.voterRegistration.tit_numero ||
          !this.form.voterRegistration.tit_zona ||
          !this.form.voterRegistration.tit_secao ||
          !this.form.voterRegistration.est_id ||
          !this.form.voterRegistration.tit_id_municipios
        );
      } else if (this.documentType === "CTPS") {
        return (
          !this.form.ctps.ctt_numero ||
          !this.form.ctps.ctt_id_estados ||
          !this.form.ctps.ctt_serie ||
          !this.form.ctps.ctt_emissao
        );
      } else {
        return (
          (this.documentType === "CERTIDAO DE NASCIMENTO NOVA" &&
            this.form.newBirthCertificate.ctn_numero.length < 4) ||
          (this.documentType === "CNH" &&
            this.form.cnh.cnh_numero.length < 4) ||
          (this.documentType === "CNS" &&
            this.form.cns.crs_numero.length < 4) ||
          (this.documentType === "CPF" &&
            this.form.cpf.cpf_numero.length < 4) ||
          (this.documentType === "PIS" &&
            this.form.pisPasep.pis_numero.length < 4)
        );
      }
    },

    disabledEditButton() {
      return !this.selectedDocument;
    },
  },

  watch: {
    documentType(pValue, pPrev) {
      const validateCnsTable = this.documents.some((el) => {
        return el.document === "CNS";
      });

      const validateCpfTable = this.documents.some((el) => {
        return el.document === "CPF";
      });

      if (validateCnsTable && this.requiredCNS === true) {
        this.isRequiredCns = false;
      }

      if (validateCpfTable && this.requiredCPF === true) {
        this.isRequiredCpf = false;
      }

      if (this.requiredCNS === false) {
        this.isRequiredCns = false;
      }

      if (this.requiredCPF === false) {
        this.isRequiredCpf = false;
      }

      if (!pValue) {
        this.isRequiredCpf = false;
        this.isRequiredCns = false;
      }

      if (pValue && pPrev && pValue !== pPrev) {
        this.form = this.$utils.obj.DeepCopy(FORM_DOCUMENTS);
        this.cleanFieldsValidation();
      }
    },

    receiveNotification(pValue) {
      pValue === true
        ? (this.isRequiredCpf = true)
        : (this.isRequiredCpf = false);
    },

    documentData(pValue) {
      const isToLoadData = !this.isNewBorn && pValue;

      if (isToLoadData) {
        this.mountDocumentList(pValue);
      }
    },

    contactData(pValue) {
      if (pValue) {
        this.setNotification(pValue);
      }
    },

    async "form.cpf.cpf_numero"(pValue) {
      if (pValue) {
        const cleanValue = this.removeDashesAndDots(pValue);
        const isCorrectLength = cleanValue.length >= 11;

        if (!isCorrectLength) {
          this.receiveNotification = false;
          this.handleAppNotification(false);
        }
      } else {
        this.receiveNotification = false;
        this.handleAppNotification(false);
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Documento", key: "document", align: "left" },
      { name: "Dados", key: "data", align: "left" },
      { name: " ", key: "notification" },
    ];
  },

  methods: {
    validateRequiredCns() {
      const validateCNSTable = this.documents.some((el) => {
        return el.document === "CNS";
      });

      if (this.requiredCNS === true && !validateCNSTable) {
        validateCNSTable === true
          ? (this.isRequiredCns = false)
          : (this.isRequiredCns = true);

        this.documentType = "CNS";
        this.documentTypeId = 41;

        this.validateAddDocumentList();

        return validateCNSTable;
      } else {
        this.isRequiredCns = false;
      }
    },

    validateRequiredCpf() {
      const validateCpfTable = this.documents.some((el) => {
        return el.document === "CPF";
      });

      if (this.requiredCPF === true && !validateCpfTable) {
        this.documentType = "CPF";
        this.documentTypeId = 31;

        if (this.requiredCPF === true) {
          this.isRequiredCpf = true;
        } else {
          this.isRequiredCpf = false;
        }

        this.validateAddDocumentList();

        return validateCpfTable;
      } else {
        this.isRequiredCpf = false;
      }
    },

    validateAddDocumentList() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    mountDocumentList(pData) {
      if (pData.otherDocuments?.weddingCertificate?.ctc_id > 0) {
        const concatInfo =
          pData.otherDocuments.weddingCertificate.ctc_cartorio +
          " | " +
          pData.otherDocuments.weddingCertificate.ctc_livro +
          " | " +
          pData.otherDocuments.weddingCertificate.ctc_folha +
          " | " +
          pData.otherDocuments.weddingCertificate.ctc_termo +
          " | " +
          pData.otherDocuments.weddingCertificate.ctc_emissao;

        this.documents.push({
          document: "CERTIDAO DE CASAMENTO",
          documentId: 37,
          data: concatInfo,
          notification: " ",
          weddingCertificate: {
            ctc_id: pData.otherDocuments.weddingCertificate.ctc_id,
            ctc_cartorio: pData.otherDocuments.weddingCertificate.ctc_cartorio,
            ctc_livro: pData.otherDocuments.weddingCertificate.ctc_livro,
            ctc_folha: pData.otherDocuments.weddingCertificate.ctc_folha,
            ctc_termo: pData.otherDocuments.weddingCertificate.ctc_termo,
            ctc_emissao: pData.otherDocuments.weddingCertificate.ctc_emissao,
          },
        });
      }

      if (pData.otherDocuments?.birthCertificate?.ctc_id > 0) {
        const concatInfo =
          pData.otherDocuments.birthCertificate.ctc_cartorio +
          " | " +
          pData.otherDocuments.birthCertificate.ctc_livro +
          " | " +
          pData.otherDocuments.birthCertificate.ctc_folha +
          " | " +
          pData.otherDocuments.birthCertificate.ctc_termo +
          " | " +
          pData.otherDocuments.birthCertificate.ctc_emissao;

        this.documents.push({
          document: "CERTIDAO DE NASCIMENTO",
          documentId: 36,
          data: concatInfo,
          notification: " ",
          birthCertificate: {
            ctc_id: pData.otherDocuments.birthCertificate.ctc_id,
            ctc_cartorio: pData.otherDocuments.birthCertificate.ctc_cartorio,
            ctc_livro: pData.otherDocuments.birthCertificate.ctc_livro,
            ctc_folha: pData.otherDocuments.birthCertificate.ctc_folha,
            ctc_termo: pData.otherDocuments.birthCertificate.ctc_termo,
            ctc_emissao: pData.otherDocuments.birthCertificate.ctc_emissao,
          },
        });
      }

      if (pData.otherDocuments?.newBirthCertificate?.ctn_id > 0) {
        this.documents.push({
          document: "CERTIDAO DE NASCIMENTO NOVA",
          documentId: 38,
          notification: " ",
          data: pData.otherDocuments.newBirthCertificate.ctn_numero,
          newBirthCertificate: {
            ctn_id: pData.otherDocuments.newBirthCertificate.ctn_id,
            ctn_numero: pData.otherDocuments.newBirthCertificate.ctn_numero,
          },
        });
      }

      if (pData.otherDocuments?.cnh?.cnh_id > 0) {
        this.documents.push({
          document: "CNH",
          documentId: 42,
          notification: " ",
          data: pData.otherDocuments.cnh.cnh_numero,
          cnh: {
            cnh_id: pData.otherDocuments.cnh.cnh_id,
            cnh_numero: pData.otherDocuments.cnh.cnh_numero,
          },
        });
      }

      if (pData?.cns?.crs_id > 0) {
        this.documents.push({
          document: "CNS",
          documentId: 41,
          notification: " ",
          data: pData.cns.crs_numero.replace(
            /(\d{3})(\d{4})(\d{4})(\d{4})/,
            "$1 $2 $3 $4",
          ),
          cns: {
            crs_id: pData.cns.crs_id,
            crs_numero: pData.cns.crs_numero,
          },
        });
      }

      if (pData?.cpf?.cpf_id > 0) {
        this.documents.push({
          document: "CPF",
          documentId: 31,
          notification:
            this.contactData?.notification?.rows[0]?.nop_cpf?.length > 0
              ? "NOTIFICAÇÃO"
              : " ",
          data: pData.cpf.cpf_numero.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4",
          ),
          cpf: {
            cpf_id: pData.cpf.cpf_id,
            cpf_numero: pData.cpf.cpf_numero,
          },
        });
      }

      if (pData.otherDocuments?.ctps?.ctt_id > 0) {
        const concatInfo =
          pData.otherDocuments.ctps.ctt_numero +
          " | " +
          pData.otherDocuments.ctps.state.est_sigla +
          " | " +
          pData.otherDocuments.ctps.ctt_serie +
          " | " +
          pData.otherDocuments.ctps.ctt_emissao;

        this.documents.push({
          document: "CTPS",
          documentId: 33,
          data: concatInfo,
          notification: " ",
          ctps: {
            ctt_id: pData.otherDocuments.ctps.ctt_id,
            ctt_id_estados: pData.otherDocuments.ctps.ctt_id_estados,
            ctt_numero: pData.otherDocuments.ctps.ctt_numero,
            ctt_serie: pData.otherDocuments.ctps.ctt_serie,
            ctt_emissao: pData.otherDocuments.ctps.ctt_emissao,
            est_sigla: pData.otherDocuments.ctps.state.est_sigla,
          },
        });
      }

      if (pData?.rg?.rgs_id > 0) {
        const concatInfo =
          pData.rg.rgs_numero.replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})/,
            "$1.$2.$3-$4",
          ) +
          " | " +
          pData.rg.issuingBody.org_nome +
          " | " +
          pData.rg.rgs_expedicao;

        this.documents.push({
          document: "IDENTIDADE",
          documentId: 28,
          notification: " ",
          data: concatInfo,
          rg: {
            rgs_id: pData.rg.rgs_id,
            rgs_id_orgaos_emissores: pData.rg.rgs_id_orgaos_emissores,
            rgs_numero: pData.rg.rgs_numero,
            rgs_orgaos_nome: pData.rg.issuingBody.org_nome,
            rgs_expedicao: pData.rg.rgs_expedicao,
          },
        });
      }

      if (pData?.pisPasep?.pis_id > 0) {
        this.documents.push({
          document: "PIS",
          documentId: 35,
          data: pData.pisPasep.pis_numero,
          notification: " ",
          pisPasep: {
            pis_id: pData.pisPasep.pis_id,
            pis_numero: pData.pisPasep.pis_numero,
          },
        });
      }

      if (pData.otherDocuments?.voterTitle?.tit_id > 0) {
        const concatInfo =
          pData.otherDocuments.voterTitle.tit_numero +
          " | " +
          pData.otherDocuments.voterTitle.tit_zona +
          " | " +
          pData.otherDocuments.voterTitle.tit_secao +
          " | " +
          pData.otherDocuments.voterTitle.city.mun_nome +
          " - " +
          pData.otherDocuments.voterTitle.city.state.est_sigla;

        this.documents.push({
          document: "TÍTULO DE ELEITOR",
          documentId: 32,
          data: concatInfo,
          notification: " ",
          voterRegistration: {
            tit_id: pData.otherDocuments.voterTitle.tit_id,
            tit_id_municipios:
              pData.otherDocuments.voterTitle.tit_id_municipios,
            tit_numero: pData.otherDocuments.voterTitle.tit_numero,
            tit_zona: pData.otherDocuments.voterTitle.tit_zona,
            tit_secao: pData.otherDocuments.voterTitle.tit_secao,
            est_id: pData.otherDocuments.voterTitle.city.state.est_id,
            est_sigla: pData.otherDocuments.voterTitle.city.state.est_sigla,
            mun_nome: pData.otherDocuments.voterTitle.city.mun_nome,
          },
        });
      }
    },

    async addDocumentList() {
      const validateDuplicateDocument = this.documents.some((el) => {
        return el.document === this.documentType;
      });

      const validateCnsTable = this.documents.some((el) => {
        return el.document === "CNS";
      });

      const validateCpfTable = this.documents.some((el) => {
        return el.document === "CPF";
      });

      if (this.requiredCNS === true && !validateCnsTable) {
        this.isRequiredCns = false;
        this.isRequiredCpf = false;
      } else if (this.requiredCPF === true && !validateCpfTable) {
        this.isRequiredCpf = false;
        this.isRequiredCns = false;
      }

      const validateForm = await this.validateAddDocumentList();

      if (!validateForm) {
        AlertError("Verifique os campos");
        return false;
      }

      if (validateDuplicateDocument) {
        AlertError("Este documento já foi adicionado");
        return false;
      }

      const documentType = this.documentType;

      switch (documentType) {
        case "CERTIDAO DE CASAMENTO": {
          const concatInfo =
            this.form.weddingCertificate.ctc_cartorio +
            " | " +
            this.form.weddingCertificate.ctc_livro +
            " | " +
            this.form.weddingCertificate.ctc_folha +
            " | " +
            this.form.weddingCertificate.ctc_termo +
            " | " +
            this.form.weddingCertificate.ctc_emissao;

          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "CERTIDAO DE CASAMENTO") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.weddingCertificate.ctc_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            data: concatInfo,
            notification: " ",
            weddingCertificate: {
              ctc_cartorio: this.form.weddingCertificate.ctc_cartorio,
              ctc_livro: this.form.weddingCertificate.ctc_livro,
              ctc_folha: this.form.weddingCertificate.ctc_folha,
              ctc_termo: this.form.weddingCertificate.ctc_termo,
              ctc_emissao: this.form.weddingCertificate.ctc_emissao,
              ctc_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "CERTIDAO DE NASCIMENTO": {
          const concatInfo =
            this.form.birthCertificate.ctc_cartorio +
            " | " +
            this.form.birthCertificate.ctc_livro +
            " | " +
            this.form.birthCertificate.ctc_folha +
            " | " +
            this.form.birthCertificate.ctc_termo +
            " | " +
            this.form.birthCertificate.ctc_emissao;

          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "CERTIDAO DE NASCIMENTO") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.birthCertificate.ctc_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            data: concatInfo,
            notification: " ",
            birthCertificate: {
              ctc_cartorio: this.form.birthCertificate.ctc_cartorio,
              ctc_livro: this.form.birthCertificate.ctc_livro,
              ctc_folha: this.form.birthCertificate.ctc_folha,
              ctc_termo: this.form.birthCertificate.ctc_termo,
              ctc_emissao: this.form.birthCertificate.ctc_emissao,
              ctc_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "CERTIDAO DE NASCIMENTO NOVA": {
          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "CERTIDAO DE NASCIMENTO NOVA") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.newBirthCertificate.ctn_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            notification: " ",
            data: this.form.newBirthCertificate.ctn_numero,
            newBirthCertificate: {
              ctn_numero: this.form.newBirthCertificate.ctn_numero,
              ctn_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "CNH": {
          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "CNH") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.cnh.cnh_id;
            }
          });
          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            notification: " ",
            data: this.form.cnh.cnh_numero,
            cnh: {
              cnh_numero: this.form.cnh.cnh_numero,
              cnh_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "CNS": {
          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "CNS") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.cns.crs_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            notification: " ",
            data: this.form.cns.crs_numero,
            cns: {
              crs_numero: this.form.cns.crs_numero,
              crs_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "CPF": {
          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "CPF") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.cpf.cpf_id;
            }
          });

          if (this.receiveNotification === true) {
            this.documents.push({
              document: this.documentType,
              documentId: this.documentTypeId,
              data: this.form.cpf.cpf_numero,
              notification: "NOTIFICAÇÃO",
              cpf: {
                cpf_numero: this.form.cpf.cpf_numero,
                cpf_id: removedDocumentId,
              },
            });
          } else {
            this.documents.push({
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: this.form.cpf.cpf_numero,
              cpf: {
                cpf_numero: this.form.cpf.cpf_numero,
                cpf_id: removedDocumentId,
              },
            });
          }

          this.cleanForm();

          return true;
        }
        case "CTPS": {
          const concatInfo =
            this.form.ctps.ctt_numero +
            " | " +
            this.form.ctps.est_sigla +
            " | " +
            this.form.ctps.ctt_serie +
            " | " +
            this.form.ctps.ctt_emissao;

          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "CTPS") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.ctps.ctt_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            notification: " ",
            data: concatInfo,
            ctps: {
              ctt_numero: this.form.ctps.ctt_numero,
              ctt_serie: this.form.ctps.ctt_serie,
              ctt_emissao: this.form.ctps.ctt_emissao,
              ctt_id_estados: this.form.ctps.ctt_id_estados,
              est_sigla: this.form.ctps.est_sigla,
              ctt_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "IDENTIDADE": {
          const concatInfo =
            this.form.rg.rgs_numero +
            " | " +
            (this.issuingBody || this.form.rg.rgs_orgaos_nome) +
            " | " +
            this.form.rg.rgs_expedicao;

          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved) => {
            if (documentRemoved.document === "IDENTIDADE") {
              this.documentsRemoved.splice(documentRemoved, 1);
              removedDocumentId = documentRemoved.rg.rgs_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            notification: " ",
            data: concatInfo,
            rg: {
              rgs_numero: this.form.rg.rgs_numero,
              rgs_expedicao: this.form.rg.rgs_expedicao,
              rgs_id_orgaos_emissores: this.form.rg.rgs_id_orgaos_emissores,
              rgs_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "PIS": {
          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved) => {
            if (documentRemoved.document === "PIS") {
              this.documentsRemoved.splice(documentRemoved, 1);
              removedDocumentId = documentRemoved.pisPasep.pis_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            notification: " ",
            data: this.form.pisPasep.pis_numero,
            pisPasep: {
              pis_numero: this.form.pisPasep.pis_numero,
              pis_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
        case "TÍTULO DE ELEITOR": {
          const concatInfo =
            this.form.voterRegistration.tit_numero +
            " | " +
            this.form.voterRegistration.tit_zona +
            " | " +
            this.form.voterRegistration.tit_secao +
            " | " +
            this.form.voterRegistration.mun_nome +
            " - " +
            this.form.voterRegistration.est_sigla;

          let removedDocumentId = null;

          this.documentsRemoved.forEach((documentRemoved, index) => {
            if (documentRemoved.document === "TÍTULO DE ELEITOR") {
              this.documentsRemoved.splice(index, 1);
              removedDocumentId = documentRemoved.voterRegistration.tit_id;
            }
          });

          this.documents.push({
            document: this.documentType,
            documentId: this.documentTypeId,
            notification: " ",
            data: concatInfo,
            voterRegistration: {
              tit_id_municipios: this.form.voterRegistration.tit_id_municipios,
              tit_numero: this.form.voterRegistration.tit_numero,
              tit_zona: this.form.voterRegistration.tit_zona,
              tit_secao: this.form.voterRegistration.tit_secao,
              est_id: this.form.voterRegistration.est_id,
              est_sigla: this.form.voterRegistration.est_sigla,
              mun_nome: this.form.voterRegistration.mun_nome,
              tit_id: removedDocumentId,
            },
          });

          this.cleanForm();

          return true;
        }
      }
      this.cleanForm();

      return false;
    },

    async editDocumentList() {
      this.editDocument = true;

      const documentData = this.selectedDocument;

      if (documentData) {
        switch (documentData.document) {
          case "CERTIDAO DE CASAMENTO": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            const date = documentData.weddingCertificate.ctc_emissao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              this.form.weddingCertificate.ctc_emissao = this.$utils.date.BrazilianDateFormat(
                documentData.weddingCertificate.ctc_emissao,
              );
            } else {
              this.form.weddingCertificate.ctc_emissao =
                documentData.weddingCertificate.ctc_emissao;
            }

            this.form.weddingCertificate.ctc_id =
              documentData.weddingCertificate.ctc_id || null;
            this.form.weddingCertificate.ctc_cartorio =
              documentData.weddingCertificate.ctc_cartorio;
            this.form.weddingCertificate.ctc_livro =
              documentData.weddingCertificate.ctc_livro;
            this.form.weddingCertificate.ctc_folha =
              documentData.weddingCertificate.ctc_folha;
            this.form.weddingCertificate.ctc_termo =
              documentData.weddingCertificate.ctc_termo;

            return true;
          }
          case "CERTIDAO DE NASCIMENTO": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            const date = documentData.birthCertificate.ctc_emissao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              this.form.birthCertificate.ctc_emissao = this.$utils.date.BrazilianDateFormat(
                documentData.birthCertificate.ctc_emissao,
              );
            } else {
              this.form.birthCertificate.ctc_emissao =
                documentData.birthCertificate.ctc_emissao;
            }

            this.form.birthCertificate.ctc_id =
              documentData.birthCertificate.ctc_id || null;

            this.form.birthCertificate.ctc_cartorio =
              documentData.birthCertificate.ctc_cartorio;
            this.form.birthCertificate.ctc_livro =
              documentData.birthCertificate.ctc_livro;
            this.form.birthCertificate.ctc_folha =
              documentData.birthCertificate.ctc_folha;
            this.form.birthCertificate.ctc_termo =
              documentData.birthCertificate.ctc_termo;

            return true;
          }
          case "CERTIDAO DE NASCIMENTO NOVA": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.newBirthCertificate.ctn_id =
              documentData.newBirthCertificate.ctn_id || null;

            this.form.newBirthCertificate.ctn_numero =
              documentData.newBirthCertificate.ctn_numero;

            return true;
          }
          case "CNH": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.cnh.cnh_id = documentData.cnh.cnh_id || null;

            this.form.cnh.cnh_numero = documentData.cnh.cnh_numero;

            return true;
          }
          case "CNS": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.cns.crs_id = documentData.cns.crs_id || null;

            this.form.cns.crs_numero = documentData.cns.crs_numero;

            return true;
          }
          case "CPF": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.cpf.cpf_id = documentData.cpf.cpf_id || null;

            this.form.cpf.cpf_numero = documentData.cpf.cpf_numero;

            if (documentData.notification?.length > 1) {
              this.receiveNotification = true;
            }

            return true;
          }
          case "CTPS": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.ctps.ctt_id = documentData.ctps.ctt_id || null;

            this.form.ctps.ctt_numero = documentData.ctps.ctt_numero;
            this.form.ctps.ctt_serie = documentData.ctps.ctt_serie;

            if (this.$refs.stateCtps) {
              this.$refs.stateCtps.forceSelection({
                est_id: documentData.ctps.ctt_id_estados,
                est_sigla: documentData.ctps.est_sigla,
              });
            }

            const date = documentData.ctps.ctt_emissao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              this.form.ctps.ctt_emissao = this.$utils.date.BrazilianDateFormat(
                documentData.ctps.ctt_emissao,
              );
            } else {
              this.form.ctps.ctt_emissao = documentData.ctps.ctt_emissao;
            }

            return true;
          }
          case "IDENTIDADE": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.rg.rgs_id = documentData.rg.rgs_id || null;

            this.form.rg.rgs_numero = documentData.rg.rgs_numero;
            this.form.rg.rgs_id_orgaos_emissores =
              documentData.rg.rgs_id_orgaos_emissores;
            this.form.rg.rgs_orgaos_nome = documentData.rg.rgs_orgaos_nome;

            const date = documentData.rg.rgs_expedicao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              this.form.rg.rgs_expedicao = this.$utils.date.BrazilianDateFormat(
                documentData.rg.rgs_expedicao,
              );
            } else {
              this.form.rg.rgs_expedicao = documentData.rg.rgs_expedicao;
            }

            return true;
          }
          case "PIS": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.pisPasep.pis_id = documentData.pisPasep.pis_id || null;

            this.form.pisPasep.pis_numero = documentData.pisPasep.pis_numero;
            return true;
          }
          case "TÍTULO DE ELEITOR": {
            this.documentType = documentData.document;
            this.documentTypeId = documentData.documentId;

            this.form.voterRegistration.tit_id =
              documentData.voterRegistration.tit_id || null;
            this.form.voterRegistration.tit_numero =
              documentData.voterRegistration.tit_numero;
            this.form.voterRegistration.tit_zona =
              documentData.voterRegistration.tit_zona;
            this.form.voterRegistration.tit_secao =
              documentData.voterRegistration.tit_secao;

            if (this.$refs.stateVoterRegistration) {
              this.$refs.stateVoterRegistration.forceSelection({
                est_id: documentData.voterRegistration.est_id,
                est_sigla: documentData.voterRegistration.est_sigla,
              });
            }

            if (this.$refs.cityVoterRegistration) {
              this.$refs.cityVoterRegistration.forceSelection({
                mun_id: documentData.voterRegistration.tit_id_municipios,
                mun_nome: documentData.voterRegistration.mun_nome,
              });
            }

            return true;
          }
        }
        return false;
      }
    },

    async saveEditDocumentList() {
      let documentData = null;

      const validateForm = await this.validateAddDocumentList();

      if (validateForm) {
        const documentType = this.documentType;

        switch (documentType) {
          case "CERTIDAO DE CASAMENTO": {
            const concatInfo =
              this.form.weddingCertificate.ctc_cartorio +
              " | " +
              this.form.weddingCertificate.ctc_livro +
              " | " +
              this.form.weddingCertificate.ctc_folha +
              " | " +
              this.form.weddingCertificate.ctc_termo +
              " | " +
              this.form.weddingCertificate.ctc_emissao;

            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              data: concatInfo,
              notification: " ",
              weddingCertificate: {
                ctc_id: this.form.weddingCertificate.ctc_id || null,
                ctc_cartorio: this.form.weddingCertificate.ctc_cartorio,
                ctc_livro: this.form.weddingCertificate.ctc_livro,
                ctc_folha: this.form.weddingCertificate.ctc_folha,
                ctc_termo: this.form.weddingCertificate.ctc_termo,
                ctc_emissao: this.form.weddingCertificate.ctc_emissao,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "CERTIDAO DE NASCIMENTO": {
            const concatInfo =
              this.form.birthCertificate.ctc_cartorio +
              " | " +
              this.form.birthCertificate.ctc_livro +
              " | " +
              this.form.birthCertificate.ctc_folha +
              " | " +
              this.form.birthCertificate.ctc_termo +
              " | " +
              this.form.birthCertificate.ctc_emissao;

            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              data: concatInfo,
              notification: " ",
              birthCertificate: {
                ctc_id: this.form.birthCertificate.ctc_id || null,
                ctc_cartorio: this.form.birthCertificate.ctc_cartorio,
                ctc_livro: this.form.birthCertificate.ctc_livro,
                ctc_folha: this.form.birthCertificate.ctc_folha,
                ctc_termo: this.form.birthCertificate.ctc_termo,
                ctc_emissao: this.form.birthCertificate.ctc_emissao,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "CERTIDAO DE NASCIMENTO NOVA": {
            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: this.form.newBirthCertificate.ctn_numero,
              newBirthCertificate: {
                ctn_id: this.form.newBirthCertificate.ctn_id || null,
                ctn_numero: this.form.newBirthCertificate.ctn_numero,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "CNH": {
            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: this.form.cnh.cnh_numero,
              cnh: {
                cnh_id: this.form.cnh.cnh_id || null,
                cnh_numero: this.form.cnh.cnh_numero,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "CNS": {
            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: this.form.cns.crs_numero.replace(
                /(\d{3})(\d{4})(\d{4})(\d{4})/,
                "$1 $2 $3 $4",
              ),
              cns: {
                crs_id: this.form.cns.crs_id || null,
                crs_numero: this.form.cns.crs_numero,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "CPF": {
            if (this.receiveNotification === true) {
              documentData = {
                document: this.documentType,
                documentId: this.documentTypeId,
                data: this.form.cpf.cpf_numero,
                notification: "NOTIFICAÇÃO",
                cpf: {
                  cpf_id: this.form.cpf.cpf_id || null,
                  cpf_numero: this.form.cpf.cpf_numero,
                },
              };
            } else {
              documentData = {
                document: this.documentType,
                documentId: this.documentTypeId,
                notification: " ",
                data: this.form.cpf.cpf_numero,
                cpf: {
                  cpf_id: this.form.cpf.cpf_id || null,
                  cpf_numero: this.form.cpf.cpf_numero,
                },
              };
            }
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "CTPS": {
            const concatInfo =
              this.form.ctps.ctt_numero +
              " | " +
              this.form.ctps.est_sigla +
              " | " +
              this.form.ctps.ctt_serie +
              " | " +
              this.form.ctps.ctt_emissao;

            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: concatInfo,
              ctps: {
                ctt_id: this.form.ctps.ctt_id || null,
                ctt_numero: this.form.ctps.ctt_numero,
                ctt_serie: this.form.ctps.ctt_serie,
                ctt_emissao: this.form.ctps.ctt_emissao,
                ctt_id_estados: this.form.ctps.ctt_id_estados,
                est_sigla: this.form.ctps.est_sigla,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "IDENTIDADE": {
            const concatInfo =
              this.form.rg.rgs_numero +
              " | " +
              (this.form.rg.rgs_orgaos_nome || this.issuingBody) +
              " | " +
              this.form.rg.rgs_expedicao;

            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: concatInfo,
              rg: {
                rgs_id: this.form.rg.rgs_id || null,
                rgs_numero: this.form.rg.rgs_numero,
                rgs_expedicao: this.form.rg.rgs_expedicao,
                rgs_id_orgaos_emissores: this.form.rg.rgs_id_orgaos_emissores,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "PIS": {
            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: this.form.pisPasep.pis_numero,
              pisPasep: {
                pis_id: this.form.pisPasep.pis_id || null,
                pis_numero: this.form.pisPasep.pis_numero,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
          case "TÍTULO DE ELEITOR": {
            const concatInfo =
              this.form.voterRegistration.tit_numero +
              " | " +
              this.form.voterRegistration.tit_zona +
              " | " +
              this.form.voterRegistration.tit_secao +
              " | " +
              this.form.voterRegistration.mun_nome +
              " - " +
              this.form.voterRegistration.est_sigla;

            documentData = {
              document: this.documentType,
              documentId: this.documentTypeId,
              notification: " ",
              data: concatInfo,
              voterRegistration: {
                tit_id: this.form.voterRegistration.tit_id || null,
                tit_id_municipios: this.form.voterRegistration
                  .tit_id_municipios,
                tit_numero: this.form.voterRegistration.tit_numero,
                tit_zona: this.form.voterRegistration.tit_zona,
                tit_secao: this.form.voterRegistration.tit_secao,
                est_id: this.form.voterRegistration.est_id,
                est_sigla: this.form.voterRegistration.est_sigla,
                mun_nome: this.form.voterRegistration.mun_nome,
              },
            };
            this.documents[this.selectedDocumentIndex] = documentData;
            this.cleanSelectedDocument();
            this.cleanForm();
            return true;
          }
        }
        this.cleanForm();
        return false;
      } else {
        AlertError("Verifique os campos");
      }
    },

    selectLine(pValue, pIndex) {
      if (pValue) {
        this.selectedDocument = pValue;
        this.selectedDocumentIndex = pIndex;
      } else {
        this.selectedDocument = null;
        this.selectedDocumentIndex = null;
      }
      this.editDocument = false;
      this.cleanForm();
    },

    cleanSelectedDocument() {
      this.selectedDocument = null;
      this.selectedDocumentIndex = null;
      this.editDocument = false;

      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
    },

    cancelEditDocumentList() {
      this.cleanSelectedDocument();
      this.cleanForm();
    },

    async removeDocumentList() {
      this.documents.splice(this.selectedDocumentIndex, 1);

      if (this.documentHasId()) {
        this.documentsRemoved.push(this.selectedDocument);
      }

      this.cleanSelectedDocument();
    },

    documentHasId() {
      if (
        this.selectedDocument.weddingCertificate?.ctc_id ||
        this.selectedDocument.birthCertificate?.ctc_id ||
        this.selectedDocument.newBirthCertificate?.ctn_id ||
        this.selectedDocument.cnh?.cnh_id ||
        this.selectedDocument.cpf?.cpf_id ||
        this.selectedDocument.cns?.crs_id ||
        this.selectedDocument.ctps?.ctt_id ||
        this.selectedDocument.rg?.rgs_id ||
        this.selectedDocument.pisPasep?.pis_id ||
        this.selectedDocument.voterRegistration?.tit_id
      ) {
        return true;
      }

      return false;
    },

    getDocumentType(pValue) {
      if (pValue) {
        this.documentType = pValue[0].text;
        this.documentTypeId = pValue[0].value;
      } else {
        this.documentType = null;
        this.documentTypeId = null;
      }

      if (
        pValue[0].text === "CPF" &&
        pValue[0].value === 31 &&
        this.requiredCPF === true
      ) {
        this.isRequiredCpf = true;
      } else {
        this.isRequiredCpf = true;
      }

      if (
        pValue[0].text === "CNS" &&
        pValue[0].value === 41 &&
        this.requiredCNS === true
      ) {
        this.isRequiredCns = true;
      } else {
        this.isRequiredCns = true;
      }
    },

    getIssuingBody(pValue) {
      pValue ? (this.issuingBody = pValue[0].text) : (this.issuingBody = null);
    },

    onPaste() {
      this.cleanNotification();
    },

    updateTotalFiles(total) {
      this.attach_total_quantity = total;
    },

    saveAttachmentsIntoMemory(attachments) {
      this.new_attachments = attachments;
    },

    handleAppNotification(pValue) {
      let cpfNumber = this.notification.cpf_numero;

      if (pValue) {
        const cpf = this.form.cpf;
        const hasNumber = cpf.cpf_numero && cpf.cpf_numero.length > 0;
        const isToNotificate = pValue && hasNumber;
        cpfNumber = isToNotificate ? cpf.cpf_numero : null;

        this.notification.cpf_numero = cpfNumber;
      } else {
        cpfNumber = null;
        this.cleanNotification();
      }

      this.receiveNotification = pValue;
    },

    setNotification(pData) {
      if (pData && pData.notification) {
        pData.notification.rows.forEach((item) => {
          const hasValidValue = item.nop_cpf !== null;
          const sameValue = item.nop_cpf === this.notification.cpf_numero;

          if (hasValidValue && sameValue) {
            this.notification.nop_id = item.nop_id;
            this.receiveNotification = true;
          }
        });
      }
      this.handleAppNotification(this.receiveNotification);
    },

    /**
     * @returns {Array} um array de objetos com {filename, documentType}
     */
    getNewAttachments() {
      return this.new_attachments.map((item) => {
        return {
          filename: item.filename,
          document_type: item.document_attachment.doa_id,
        };
      });
    },

    async getDocumentForm() {
      let weddingCertificate,
        birthCertificate,
        newBirthCertificate,
        cnh,
        cns,
        cpf,
        ctps,
        rg,
        pisPasep,
        voterRegistration;

      const documentForm = this.$utils.obj.DeepCopy(this.documents);

      if (this.documentsRemoved.length > 0) {
        this.documentsRemoved.forEach((document) => {
          if (document.document === "CERTIDAO DE CASAMENTO") {
            documentForm.push({
              weddingCertificate: {},
            });
          }
          if (document.document === "CERTIDAO DE NASCIMENTO") {
            documentForm.push({
              birthCertificate: {},
            });
          }
          if (document.document === "CERTIDAO DE NASCIMENTO NOVA") {
            documentForm.push({
              newBirthCertificate: {},
            });
          }
          if (document.document === "CNH") {
            documentForm.push({
              cnh: {},
            });
          }
          if (document.document === "CNS") {
            documentForm.push({
              cns: {},
            });
          }
          if (document.document === "CPF") {
            documentForm.push({
              cpf: {},
            });
          }
          if (document.document === "CTPS") {
            documentForm.push({
              ctps: {},
            });
          }
          if (document.document === "IDENTIDADE") {
            documentForm.push({
              rg: {},
            });
          }
          if (document.document === "PIS") {
            documentForm.push({
              pisPasep: {},
            });
          }
          if (document.document === "TÍTULO DE ELEITOR") {
            documentForm.push({
              voterRegistration: {},
            });
          }
        });
      }

      documentForm.forEach((item) => {
        if (item.weddingCertificate && Object.keys(item).length > 0) {
          weddingCertificate = item.weddingCertificate;
          if (weddingCertificate?.ctc_emissao) {
            const date = item.weddingCertificate.ctc_emissao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              weddingCertificate.ctc_emissao =
                item.weddingCertificate.ctc_emissao;
            } else {
              weddingCertificate.ctc_emissao = this.$utils.date.BrazilianDateToDatabase(
                item.weddingCertificate.ctc_emissao,
              );
            }
          }
        }

        if (item.birthCertificate && Object.keys(item).length > 0) {
          birthCertificate = item.birthCertificate;
          if (birthCertificate?.ctc_emissao) {
            const date = item.birthCertificate.ctc_emissao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              birthCertificate.ctc_emissao = item.birthCertificate.ctc_emissao;
            } else {
              birthCertificate.ctc_emissao = this.$utils.date.BrazilianDateToDatabase(
                item.birthCertificate.ctc_emissao,
              );
            }
          }
        }

        if (item.newBirthCertificate && Object.keys(item).length > 0) {
          newBirthCertificate = item.newBirthCertificate;
        }

        if (item.cnh && Object.keys(item).length > 0) {
          cnh = item.cnh;
          if (cnh?.cnh_numero) {
            cnh.cnh_numero = this.removeDashesAndDots(item.cnh.cnh_numero);
          }
        }

        if (item.cns && Object.keys(item).length > 0) {
          cns = item.cns;
          if (cns?.crs_numero) {
            cns.crs_numero = this.removeDashesAndDots(item.cns.crs_numero);
          }
        }

        if (item.cpf && Object.keys(item).length > 0) {
          cpf = item.cpf;
          if (cpf?.cpf_numero) {
            cpf.cpf_numero = this.removeDashesAndDots(item.cpf.cpf_numero);
          }
        }

        if (item.ctps && Object.keys(item).length > 0) {
          ctps = item.ctps;
          if (ctps?.ctt_emissao) {
            const date = item.ctps.ctt_emissao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              ctps.ctt_emissao = item.ctps.ctt_emissao;
            } else {
              ctps.ctt_emissao = this.$utils.date.BrazilianDateToDatabase(
                item.ctps.ctt_emissao,
              );
            }
          }

          delete ctps.est_sigla;
        }

        if (item.rg && Object.keys(item).length > 0) {
          rg = item.rg;

          if (
            rg?.rgs_numero &&
            rg?.rgs_id_orgaos_emissores &&
            rg?.rgs_expedicao
          ) {
            rg.rgs_numero = this.removeDashesAndDots(item.rg.rgs_numero);
            rg.rgs_id_orgaos_emissores = item.rg.rgs_id_orgaos_emissores
              ? Number(item.rg.rgs_id_orgaos_emissores)
              : null;

            const date = item.rg.rgs_expedicao.split("-");

            if (date.length > 1 && date[0].length === 4) {
              rg.rgs_expedicao = item.rg.rgs_expedicao;
            } else {
              rg.rgs_expedicao = this.$utils.date.BrazilianDateToDatabase(
                item.rg.rgs_expedicao,
              );
            }
          }
          delete rg.rgs_orgaos_nome;
        }

        if (item.pisPasep && Object.keys(item).length > 0) {
          pisPasep = item.pisPasep;
          if (pisPasep?.pis_numero) {
            pisPasep.pis_numero = this.removeDashesAndDots(
              item.pisPasep.pis_numero,
            );
          }
        }

        if (item.voterRegistration && Object.keys(item).length > 0) {
          voterRegistration = item.voterRegistration;
          delete voterRegistration.est_id;
          delete voterRegistration.est_sigla;
          delete voterRegistration.mun_nome;
        }
      });

      const allDocuments = {
        weddingCertificate,
        birthCertificate,
        newBirthCertificate,
        cnh,
        cns,
        cpf,
        ctps,
        rg,
        pisPasep,
        voterRegistration,
      };

      const validateCNS = this.validateRequiredCns();

      if (validateCNS !== false && this.requiredCPF === true) {
        this.validateRequiredCpf();
      }

      return allDocuments;
    },

    getNotification() {
      const isNotEmpty =
        this.notification.cpf_numero && this.notification.cpf_numero.length > 0;

      if (isNotEmpty) {
        return this.notification;
      }

      return {};
    },

    async loadFromCadsus(pData) {
      if (!pData) return;
      this.cleanDocument();
      if (pData.Cartoes && pData.Cartoes.CNS) {
        if (!Array.isArray(pData.Cartoes.CNS)) {
          this.form.cns.crs_numero = pData.Cartoes.CNS.numeroCNS;
          this.documentType = "CNS";
          this.documentTypeId = 41;
        } else {
          for (const cns of pData.Cartoes.CNS) {
            if (cns.tipoCartao === "D") {
              this.form.cns.crs_numero = cns.numeroCNS;
              this.documentType = "CNS";
              this.documentTypeId = 41;

              break;
            }
          }
        }
        await this.addDocumentList();
      }

      if (pData.CPF) {
        this.form.cpf.cpf_numero = pData.CPF.numeroCPF;
        this.documentType = "CPF";
        this.documentTypeId = 31;
        await this.addDocumentList();
      }

      if (pData.Documentos) {
        if (pData.Documentos.Identidade) {
          this.form.rg.rgs_numero =
            pData.Documentos.Identidade.numeroIdentidade;
          this.form.rg.rgs_id_orgaos_emissores =
            pData.Documentos.Identidade.OrgaoEmissor.orgId || 0;
          this.form.rg.rgs_expedicao =
            pData.Documentos.Identidade.dataExpedicao;
          this.form.rg.rgs_orgaos_nome =
            pData.Documentos.Identidade.OrgaoEmissor.nomeOrgaoEmissor;
          this.documentType = "IDENTIDADE";
          this.documentTypeId = 28;
          await this.addDocumentList();
        }
        if (pData.Documentos.NIS) {
          this.form.pisPasep.pis_numero = pData.Documentos.NIS.numeroDocumento;
          this.documentType = "PIS";
          this.documentTypeId = 35;
          await this.addDocumentList();
        }
      }
    },

    validateCnh(pValue, pErrors) {
      if (pValue) {
        const isValid =
          this.form.cnh_numero && this.form.cnh_numero.length < 12;

        if (isValid) {
          pErrors.push("CNH inválida", pErrors);
          return false;
        }
      }
      return true;
    },

    validateTitleNumber(pValue, pErrors) {
      if (pValue) {
        const isValidTitle = pValue && pValue.length < 12;

        if (isValidTitle) {
          pErrors.push("Título de eleitor inválido");
          return false;
        }
      }
      return true;
    },

    verifyDateEntry(pValue, pErrors) {
      if (!pValue) return true;

      const dateEntry = moment(pValue, "DD/MM/YYYY").format();
      const birthDate = moment(this.birthDate).format();

      const validDate = dateEntry >= birthDate;

      if (!validDate) {
        pErrors.push(
          "A data da emissão não pode ser menor que a data de nascimento",
        );
        return false;
      }
      return true;
    },

    removeDashesAndDots(pText) {
      return pText && pText.length > 0
        ? pText.toString().replace(/[^\d]/g, "")
        : null;
    },

    selectingCtpsState(pValue) {
      if (pValue && pValue.source) {
        this.form.ctps.ctt_id_estados = pValue.source.est_id
          ? pValue.source.est_id
          : 0;
      }
    },

    selectingVoterRegistrationState(pValue) {
      const source = pValue && pValue.source;

      this.form.voterRegistration.est_id =
        source && source.est_id ? source.est_id : 0;
      this.form.voterRegistration.tit_id_municipios = 0;
      this.form.voterRegistration.mun_nome = "";
    },

    selectingVoterRegistrationCity(pValue) {
      const source = pValue && pValue.source;

      this.form.voterRegistration.tit_id_municipios = source && source.mun_id;
      this.form.voterRegistration.mun_nome = source && source.mun_nome;
    },

    showModalAttachments() {
      this.modalAttachments = true;
      this.$emit("disabledByModalComponents", this.modalAttachments);
    },

    closeModalAttachments() {
      this.modalAttachments = false;
      this.$emit("disabledByModalComponents", this.modalAttachments);
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "cpf",
        "identity",
        "cns",
        "ctps",
        "pis",
        "voterRegistration",
        "issuingBody",
        "emissionDate",
        "registry",
        "book",
        "leaf",
        "term",
        "dateIssue",
        "registryBirth",
        "bookBirth",
        "leafBirth",
        "termBirth",
        "dateIssueBirth",
        "stateCtps",
        "serial",
        "dateIssueCtps",
        "zone",
        "section",
        "stateVoterRegistration",
        "cityVoterRegistration",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cleanNotification() {
      this.receiveNotification = false;
      this.notification.cpf_numero = null;
    },

    cleanTable() {
      this.documents = [];
      this.selectedDocument = null;
      this.selectedDocumentIndex = null;

      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
    },

    cleanForm() {
      this.documentType = null;
      this.documentTypeId = null;
      this.form = this.$utils.obj.DeepCopy(FORM_DOCUMENTS);
      this.cleanFieldsValidation();
    },

    cleanDocument() {
      this.cleanForm();
      this.cleanTable();
    },
  },
};
</script>
