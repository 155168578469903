import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "integracoes/controller-integracao-rg-pep/combo-box-unidade-saude-pep",
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      const withData = data?.dados?.length > 0;

      if (withData) {
        data.dados.map((item) => {
          item.label = item.label.toUpperCase();
        });

        data.dados.sort(function (a, b) {
          return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
        });
      }
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
