import SEARCH_REVERSAL from "./pharmacy-satellite-search/SearchReversal";
import SEARCH_REVENUE from "./pharmacy-satellite-search/SearchRevenue";
import SEARCH_REQUEST from "./pharmacy-satellite-search/SearchRequest";
import SEARCH_CONSUMPTION from "./pharmacy-satellite-search/SearchConsumption";
import SEARCH_ENTRY from "./pharmacy-satellite-search/SearchEntry";
import SEARCH_PRESCRIPTION from "./pharmacy-satellite-search/SearchPrescription";

import REGISTER_REVERSAL from "./reversal/RegisterReversal";
import GENERATE_REMITTANCE_GUIDE from "./reversal/GenerateRemittanceGuide";
import GET_STOCK from "./reversal/GetStock";

import REGISTER_CONSUMPTION from "./consumption/RegisterConsumption";
import INACTIVATE_CONSUMPTION from "./consumption/InactivateConsumption";

import REGISTER_REQUEST from "./request/RegisterRequest";
import INACTIVATE_REQUEST from "./request/InactivateRequest";
import INACTIVE_ORDERED_ITEM from "./request/InactiveOrderedItem";
import GET_REQUEST from "./request/GetRequest";
import SEARCH_ITEMS_BY_ORDER from "./request/SearchItemsByOrder";
import GET_PRINT_REQUEST from "./request/GetPrintRequest";
import SEARCH_OBSERVATION_REQUEST from "./request/SearchObservationRequest";
import REGISTER_OBSERVATION_REQUEST from "./request/RegisterObservationRequest";

import SEARCH_PHARMACY_PATIENT from "./revenue/SearchPharmacyPatient";
import REGISTER_REVENUE from "./revenue/RegisterRevenue";
import INACTIVATE_REVENUE from "./revenue/InactivateRevenue";
import SEARCH_HISTORY_PATIENT from "./revenue/SearchHistoryPatient";
import GET_SUMMARY_PATIENT from "./revenue/GetSummaryPatient";
import GET_PRINT_REVENUE from "./revenue/GetPrintRevenue";
import GET_LAST_PRODUCT_DELIVERY from "./revenue/GetLastProductDelivery";

export default {
  SEARCH_CONSUMPTION,
  SEARCH_REVERSAL,
  SEARCH_REVENUE,
  SEARCH_REQUEST,
  SEARCH_ENTRY,
  SEARCH_PRESCRIPTION,
  REGISTER_REVERSAL,
  GENERATE_REMITTANCE_GUIDE,
  GET_STOCK,
  SEARCH_PHARMACY_PATIENT,
  REGISTER_REVENUE,
  INACTIVATE_REVENUE,
  REGISTER_CONSUMPTION,
  INACTIVATE_CONSUMPTION,
  REGISTER_REQUEST,
  GET_REQUEST,
  SEARCH_ITEMS_BY_ORDER,
  INACTIVATE_REQUEST,
  INACTIVE_ORDERED_ITEM,
  SEARCH_HISTORY_PATIENT,
  GET_SUMMARY_PATIENT,
  GET_PRINT_REQUEST,
  SEARCH_OBSERVATION_REQUEST,
  REGISTER_OBSERVATION_REQUEST,
  GET_PRINT_REVENUE,
  GET_LAST_PRODUCT_DELIVERY,
};
