import IconAboutSoftware from "./AboutSoftware";
import IconIntegration from "./Integration";
import IconRegisterPatient from "./RegisterPatient";
import IconSupport from "./Support";
import IconRegulationQueue from "./RegulationQueue";

export {
  IconAboutSoftware,
  IconIntegration,
  IconRegisterPatient,
  IconSupport,
  IconRegulationQueue,
};
