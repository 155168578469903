import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const duplicationErrors = {
  BloquearDuplicidadeAoInserirFilaConsultaException: `Paciente já consta na fila para essa ocupação.`,
  InformarDuplicidadeAoInserirFilaConsultaException: `Aviso: paciente já consta na fila para essa ocupação`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-agendamento-consulta/excluir",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: duplicationErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
