import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query CriticizeLegacyConsolidatedBilling(
    $procedureCode: String
    $cboCode: String
    $competence: String
    $amount: String
    $age: String
    $cnes: String
    $limit: Int
    $offset: Int
    $typeFilter: String
  ) {
    CriticizeLegacyConsolidatedBilling(
      cnes: $cnes
      fco_codigo_procedimento: $procedureCode
      fco_cbo: $cboCode
      fco_competencia: $competence
      fco_quantidade: $amount
      fco_idade: $age
      limit: $limit
      offset: $offset
      typeFilter: $typeFilter
    ) {
      rows {
        ppd_id
        uns_cnes
        competence
        cbo
        cbo_name
        codigo_procedimento
        quantidade
        idade
        CO_CID
        NO_CID
        NO_PROCEDIMENTO
        crs_numero
        id_pessoa
        ppd_id
        pes_nome
        hag_data_consulta
        ppd_id_funcionarios
        ppd_id_ocupacoes_cbo
        ppd_id_consultas
        nomeFuncionario
        uns_id
        CO_PROCEDIMENTO
        VALID_QT_MAXIMA_EXECUCAO
        VALID_VL_IDADE_MAXIMA
        VALID_VL_IDADE_MINIMA
        VALID_CO_OCUPACAO
        VALID_ATRIBUTOS_IDADE
        VALID_ATRIBUTOS_CBO
        info_atendimento
      }
      count
    }
  }
`;

export default async ({ commit, rootGetters }, variables) => {
  variables = variables || {};
  variables.competence = variables.competence
    ? variables.competence
    : rootGetters["Billing/BDSia/GET_PERIOD_DATE"];

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const list = data.CriticizeLegacyConsolidatedBilling
      ? data?.CriticizeLegacyConsolidatedBilling.rows
      : [];

    let result = [];
    if (list && list.length > 0) {
      list.forEach((item, index) => {
        item.validProcedure = {
          CO_OCUPACAO: item.VALID_CO_OCUPACAO,
          QT_MAXIMA_EXECUCAO: item.VALID_QT_MAXIMA_EXECUCAO,
          VL_IDADE_MAXIMA: item.VALID_VL_IDADE_MAXIMA,
          VL_IDADE_MINIMA: item.VALID_VL_IDADE_MINIMA,
          ATRIBUTOS: {
            IDADE: item.VALID_ATRIBUTOS_IDADE,
            CBO: item.VALID_ATRIBUTOS_CBO,
          },
        };

        item.info_atendimento = JSON.parse(item.info_atendimento);

        item.occupationName = item.cbo_name;
        item.sequence = (index + 1).toString();
        const aux = item.validProcedure;

        if (
          aux &&
          aux.QT_MAXIMA_EXECUCAO !== "0" &&
          aux.VL_IDADE_MAXIMA !== "0" &&
          aux.VL_IDADE_MINIMA !== "0" &&
          aux.CO_OCUPACAO !== "0" &&
          aux.ATRIBUTOS.IDADE !== "0" &&
          aux.ATRIBUTOS.CBO !== "0"
        ) {
          item.hasError = false;
        } else {
          item.hasError = true;
        }
      });

      result = list;
    }

    return {
      rows: result,
      total: data.CriticizeLegacyConsolidatedBilling.count,
    };
  } catch (e) {
    return Promise.reject(e.graphQLErrors);
  }
};
