<template>
  <!-- FIXME: TOOLTIP CLICK -->
  <section v-if="startOpen" class="open-tooltip">
    <div class="container">
      <div>
        <slot name="icon" class="icon" />
      </div>

      <div
        v-if="showMessage"
        class="message-slot"
        :class="{ clickable: clickable }"
        @click="clicked"
      >
        <slot name="content" class="content" />
      </div>

      <div
        v-if="showMessage && hasMessage"
        class="message"
        :class="{ border: showMessage, clickable: clickable }"
        @click="clicked"
      >
        <span>
          {{ message }}
        </span>
      </div>
    </div>
  </section>

  <!-- FIXME: TOOLTIP HOVER -->
  <section v-else class="hover-tooltip">
    <div class="container">
      <slot name="icon" class="icon" />
      <span
        id="hover-tooltip"
        class="hover-tooltip box"
        :style="{
          'margin-top': hasBorderRadiusTooltip ? '-21px' : marginTitle,
        }"
        :class="{
          'show-left': showLeft,
          'show-up': showUp,
          'border-radius-tooltip': hasBorderRadiusTooltip,
          'padding-border-radius': hasBorderRadiusTooltip,
        }"
      >
        <slot name="content" class="content" />
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: "Tooltip",
  components: {},
  props: {
    startOpen: {
      type: Boolean,
      default: false,
    },
    showUp: {
      type: Boolean,
      default: false,
    },
    showLeft: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    clickableLeft: {
      type: Boolean,
      default: false,
    },
    marginTitle: {
      type: String,
      default: "-27px",
    },
  },

  data() {
    return {
      showMessage: false,
      hasBorderRadiusTooltip: false,
    };
  },

  computed: {
    hasMessage() {
      return this.message && this.message.length > 0;
    },
  },

  mounted() {
    if (this.startOpen) {
      this.showMessage = true;
    }
    this.calcBorderRadiusTooltip();
  },

  methods: {
    openMessage() {
      this.showMessage = !this.showMessage;
    },
    clicked() {
      if (this.clickable) this.$emit("click", true);
    },
    calcBorderRadiusTooltip() {
      this.hasBorderRadiusTooltip =
        document.querySelector("#hover-tooltip")?.scrollHeight > 0 &&
        document.querySelector("#hover-tooltip")?.scrollHeight <= 65;
    },
  },
};
</script>
