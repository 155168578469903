<template>
  <div v-if="show" class="modal-hospitalization-billing-vasectomy">
    <RgBaseModal :show="show" class="rg-base-modal" @close="close">
      <div slot="header" class="header">
        <div class="title">Dados Adicionais do Procedimento</div>
      </div>

      <div slot="body" class="content-modal">
        <RgContentHospitalizationBillingVasectomy
          :procedure="procedure"
          :required-documents="requiredDocuments"
          @onFinish="onFinish"
          @close="close"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import RgContentHospitalizationBillingVasectomy from "./rg-content-hospitalization-billing-vasectomy/RgContentHospitalizationBillingVasectomy";
import { RgBaseModal } from "~tokio/primitive/notification";

export default {
  name: "ModalHospitalizationBillingVasectomy",
  components: {
    RgContentHospitalizationBillingVasectomy,
    RgBaseModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {},
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {},
  methods: {
    onFinish(event) {
      this.$emit("onFinish", event);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
