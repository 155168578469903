<style src="./InputCnh.scss" lang="scss" scoped></style>

<template>
  <fieldset class="rg-input--component">
    <RgFormBase label="Número do CNH" class="form-base">
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <RgValidationAlert :alert="error" />
          <input
            v-model="form.info.cnh_numero"
            v-mask="'###########'"
            placeholder="00000000000"
            type="text"
            @blur="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import {
  RgValidatorMixin,
  RgValidationAlert,
  RgInputMixin,
} from "~tokio/primitive";
import { mask } from "vue-the-mask";
import GetCnhByPersonId from "./actions/GetCnhByPersonId";

export default {
  name: "InputCnh",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    documentData: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        info: {
          cnh_numero: "",
        },
        mutationName: "AddCnhToPerson",
        mutationParams: "$pes_id: Int, $cnh_numero: String",
        mutationArgs: "pes_id: $pes_id, cnh_numero: $cnh_numero",
        mutationReturnField: "cnh_numero",
      },
    };
  },
  watch: {
    "form.info": {
      handler: function () {
        const form = JSON.parse(JSON.stringify(this.form));
        form.info.cnh_numero = form.info.cnh_numero
          ? form.info.cnh_numero.replace(/(\s)/gi, "")
          : "";
        this.$emit("formatted-data", form);
      },
      deep: true,
    },
    documentData(pValue) {
      this.form.info = pValue.info;
    },
  },
  mounted() {
    this.readInfo();
  },
  methods: {
    validateBlur() {
      this.validate();
    },
    async readInfo() {
      const data = await GetCnhByPersonId();
      this.form.info.cnh_numero = data.cnh.cnh_numero;
    },
    clean() {
      this.form.info.cnh_numero = "";
    },
  },
};
</script>
