<template slot="subFilters">
  <div class="pharmacy-central-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />
        <RgInput
          id="numberOfMonths"
          v-model="form.numberOfMonths"
          :rules="{ required: true, fn: validateNumberMonths }"
          label="Meses para Término do Estoque"
          class="inputitem"
          :maxlength="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import { RgInput } from "~tokio/primitive";

const FORM_FILTER = {
  productTypeId: null,
  productTypeName: null,
  numberOfMonths: "1",
};

export default {
  name: "PharmacyCentralEndForecastReport",
  components: {
    RgSelectProductType,
    RgInput,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      document: null,
      disabledDocument: false,
      pharmacyId: null,
      productId: null,
    };
  },
  mounted() {
    this.columnsTable([
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Nome", key: "NOME", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Consumo Médio", key: "CONSUMO_MEDIO", align: "right" },
      { name: "Meses/Dias Restantes", key: "MESES_RESTANTES", align: "left" },
    ]);
  },
  methods: {
    async doSearchSubReport(pValue) {
      try {
        const filters = {
          unitHealthId: this.globalFilters.unitHealth,
          unitHealthName: this.globalFilters.unitHealthName,
          productId: Number(this.globalFilters.product),
          productName: this.globalFilters.productName,
          pharmacyId: Number(this.globalFilters.pharmacy),
          pharmacyName: this.globalFilters.pharmacyName,
          productTypeId: Number(this.form.productTypeId),
          productTypeName: this.form.productTypeName,
          numberOfMonths: Number(this.form.numberOfMonths),
          columnsToPrint: this.columnsToPrint,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CENTRAL_END_FORECAST",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    selectedProductType(pType) {
      const hasSuggestion = pType && Object.keys(pType).length > 0;

      if (hasSuggestion) {
        this.form.productTypeName = pType.label;
      } else {
        this.form.productTypeId = null;
        this.form.productTypeName = "";
      }
    },
    validateNumberMonths(pValue, pErrors) {
      if (!pValue || pValue === "0") {
        pErrors.push("Mínimo de 1 mês");
        return false;
      }

      return true;
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
  },
};
</script>
