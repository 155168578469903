import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentSchedulePerDayRoute from "$appointment/submodules/schedule/views/per-day/appointment-schedule-per-day/AppointmentSchedulePerDay.vue";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/per-day",
  name: "appointment.schedule.per-day",
  components: {
    default: AppointmentSchedulePerDayRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Agendamento", link: OpenSubModule("appointment") },
      { label: "Agendamentos por Dia", link: "/appointment/schedule/per-day" },
    ],
  },
};
