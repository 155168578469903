<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3725 5.20024C7.83511 5.20024 5.29774 5.20024 2.76037 5.20024C2.23525 5.20024 2 4.96219 2 4.43147C2 4.1318 2 3.83214 2 3.53247C2 3.05356 2.24786 2.80151 2.72256 2.80151C3.72659 2.80151 4.73061 2.80151 5.73464 2.80151C6.17574 2.80151 6.38579 2.63627 6.49081 2.20917C6.55102 1.96692 6.60704 1.72466 6.67425 1.48521C6.76107 1.17854 6.98792 1.0021 7.30579 1.0021C9.35725 0.9993 11.4087 0.9993 13.4602 1.0021C13.778 1.0021 14.0049 1.17714 14.0917 1.48521C14.1617 1.73026 14.2177 1.98092 14.2794 2.22738C14.3802 2.62787 14.6 2.8001 15.0145 2.8001C16.0242 2.8001 17.0352 2.8001 18.0448 2.8001C18.5097 2.8001 18.7646 3.05216 18.766 3.51427C18.766 3.83354 18.766 4.15141 18.766 4.47068C18.766 4.94819 18.5153 5.20024 18.042 5.20024C15.485 5.20024 12.9295 5.20024 10.3725 5.20024Z"
      fill="white"
    />
    <path
      d="M16.6612 6.40027C14.5719 6.40027 12.4826 6.40027 10.3933 6.40027C8.30407 6.40027 6.1896 6.40027 4.08912 6.40027C3.5206 6.40027 3.22373 6.74615 3.28534 7.31607C3.34976 7.90561 3.40297 8.49654 3.45898 9.08747C3.58921 10.4682 3.71664 11.8475 3.84687 13.2282C3.9785 14.6145 4.12133 15.9994 4.24176 17.3871C4.32438 18.338 5.01333 18.9989 5.96835 18.9989C8.91181 19.0003 11.8553 19.0003 14.7987 18.9989C15.7551 18.9989 16.4469 18.3394 16.5253 17.3871C16.6051 16.4223 16.7046 15.4603 16.797 14.4969C16.9174 13.2282 17.0378 11.9609 17.1583 10.6922C17.2647 9.57338 17.3655 8.45453 17.4817 7.33568C17.5433 6.74335 17.2549 6.40027 16.6612 6.40027ZM7.989 14.1244C7.989 14.5221 7.74675 14.7966 7.39527 14.7994C7.04239 14.8022 6.79313 14.5277 6.79173 14.1342C6.79173 12.9789 6.79173 11.8223 6.79173 10.667C6.79173 10.2735 7.04239 9.99908 7.39527 10.0019C7.74675 10.0047 7.989 10.2777 7.989 10.6768C7.989 11.258 7.989 11.8391 7.989 12.4202C7.989 13.0014 7.989 13.5573 7.989 14.1258V14.1244ZM10.9829 14.1244C10.9829 14.5221 10.7406 14.7966 10.3891 14.7994C10.0363 14.8022 9.7856 14.5277 9.7856 14.1342C9.7856 12.9789 9.7856 11.8223 9.7856 10.667C9.7856 10.2735 10.0363 9.99908 10.3891 10.0019C10.7406 10.0047 10.9829 10.2777 10.9829 10.6768C10.9829 11.2524 10.9829 11.8265 10.9829 12.402C10.9829 12.9775 10.9829 13.5517 10.9829 14.1272V14.1244ZM13.9767 14.1244C13.9767 14.5221 13.7345 14.7966 13.383 14.7994C13.0301 14.8022 12.7795 14.5277 12.7795 14.1342C12.7795 12.9789 12.7795 11.8223 12.7795 10.667C12.7795 10.2735 13.0301 9.99908 13.383 10.0019C13.7345 10.0047 13.9767 10.2777 13.9767 10.6768C13.9767 11.2524 13.9767 11.8265 13.9767 12.402C13.9767 12.9775 13.9767 13.5517 13.9767 14.1272V14.1244Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Discard",
};
</script>
