<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchProceduresFilter from "./action/SearchProceduresFilter";

export default {
  name: "RgSelectProceduresFilter",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Procedimento",
    },
    unitHealthId: {
      default: null,
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: Number,
      default: null,
    },
    sectorId: {
      default: null,
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    sectorId() {
      this.doSearch();
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (!this.sectorId) return Promise.resolve([]);

      const data = await SearchProceduresFilter({ intIdSetor: this.sectorId });

      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: `${item.stp_novo_nome_procedimento}`,
          item: item,
        };
      });
    },
    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
