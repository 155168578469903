<template lang="html">
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7423 14.4741L11.5387 0.868946C11.2155 0.332969 10.6259 0 9.99998 0C9.37408 0 8.78451 0.332969 8.46127 0.868985L0.257635 14.4741C-0.076389 15.0281 -0.0862719 15.7222 0.231814 16.2854C0.549978 16.8487 1.14943 17.1986 1.79631 17.1986H18.2037C18.8505 17.1986 19.45 16.8487 19.7682 16.2854C20.0863 15.7221 20.0764 15.028 19.7423 14.4741ZM18.6344 15.6451C18.5468 15.8002 18.3817 15.8965 18.2037 15.8965H1.79631C1.61822 15.8965 1.45318 15.8002 1.36564 15.6452C1.27806 15.4901 1.2808 15.299 1.37271 15.1465L9.57643 1.54137C9.66541 1.39383 9.82772 1.30215 10 1.30215C10.1723 1.30215 10.3346 1.39383 10.4236 1.54137L18.6272 15.1465C18.7192 15.299 18.7219 15.4901 18.6344 15.6451Z"
      fill="white"
    />
    <path
      d="M10.0049 5.35733C9.50961 5.35733 9.12305 5.62311 9.12305 6.09428C9.12305 7.53186 9.29215 9.59764 9.29215 11.0353C9.29219 11.4098 9.6184 11.5668 10.005 11.5668C10.2949 11.5668 10.7056 11.4098 10.7056 11.0353C10.7056 9.59768 10.8747 7.5319 10.8747 6.09428C10.8747 5.62315 10.4761 5.35733 10.0049 5.35733Z"
      fill="white"
    />
    <path
      d="M10.0318 12.4005C9.50027 12.4005 9.10156 12.8234 9.10156 13.3308C9.10156 13.8261 9.50023 14.261 10.0318 14.261C10.5271 14.261 10.95 13.8261 10.95 13.3308C10.95 12.8234 10.5271 12.4005 10.0318 12.4005Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Danger",
};
</script>
