<template>
  <Modulebox
    ref="modulebox"
    title="Produção de Remessa Automatizada BPA"
    class="automated-shipping-production"
  >
    <div class="automated-shipping-production-top-tooltip-info">
      <LargeButton
        id="select-competence"
        label="Definir Competência"
        :backgroundColor="'#1E88A9'"
        class="btn-import-competence"
        :disabled="disabledByModal"
        @click="selectCompetence"
      >
        <IconCalendar slot="icon" class="icon-import-calendar" color="#fff" />
      </LargeButton>
    </div>

    <FormBase title="Busca de Registros" :class="{ disable: disabledByModal }">
      <RgRadioCustom
        class="type-BPA"
        v-bind="propsRadioCustom"
        :disabledByModal="disabledByModal"
        @input="onInputRadioCustom"
      />

      <section class="automated-shipping-production-search">
        <RgValidatorForm ref="validatorBPA">
          <div
            v-if="isBPAC || isLegacyBPAC"
            class="automated-shipping-production-bpa-c-search"
          >
            <RgInput
              id="cnes-bpa-c"
              ref="CNESBPAC"
              v-model="searchBPAC.cnes"
              label="CNES"
              placeholder="000000"
              disabled
              :rules="{ required: true }"
              :class="{ disable: true }"
            />

            <RgSelectCompetence
              id="competence-bpa-c"
              ref="competenceBPAC"
              v-model="searchBPAC.competence"
              :rules="{ required: true }"
              :class="{ disable: disabledByModal }"
              :disabled="disabledByModal"
            />

            <RgSelectSheetBpaC
              v-if="!isLegacyBPAC"
              id="sheet-bpa-c"
              ref="sheetBPAC"
              v-model="searchBPAC.sheet"
              :needInitialValue="false"
              :period="searchBPAC.competence"
              :class="{ disable: disabledByModal }"
              :disabled="disabledByModal"
            />

            <RgSuggestProcedure
              id="procedure-bpa-c"
              v-model="searchBPAC.procedure"
              label="Procedimento"
              type="('01')"
              fieldCaption="CO_PROCEDIMENTO - NO_PROCEDIMENTO"
              :period="searchBPAC.competence"
              :occupationCode="searchBPAC.cboCode || null"
              :isBilling="true"
              :class="{ disable: disabledByModal }"
              :disabled="disabledByModal"
              @selected="selectProcedure"
            />

            <RgSuggestCboProcedure
              id="cbo-bpa-c"
              v-model="searchBPAC.cbo"
              label="CBO"
              sigtap
              :procedureCode="searchBPAC.procedure || null"
              :competence="searchBPAC.competence"
              :isBilling="true"
              :class="{ disable: disabledByModal }"
              :disabled="disabledByModal"
              @selected="selectOccupationBPAC"
            />

            <RgInput
              id="age-bpa-c"
              v-model="searchBPAC.age"
              label="Idade"
              placeholder="000"
              type="text"
              :rules="{ number: true }"
              :maxlength="3"
              :class="{ disable: !searchBPAC.procedure || disabledByModal }"
              :disabled="!searchBPAC.procedure || disabledByModal"
            />

            <RgInput
              id="amount-bpa-c"
              v-model="searchBPAC.amount"
              label="Quantidade"
              placeholder="0000"
              :rules="{ number: true }"
              :maxlength="4"
              :class="{ disable: !searchBPAC.procedure || disabledByModal }"
              :disabled="!searchBPAC.procedure || disabledByModal"
            />
          </div>

          <div v-else class="automated-shipping-production-bpa-i-search">
            <RgInput
              id="cnes-bpai"
              ref="CNESBPAI"
              v-model="searchBPAI.cnes"
              label="CNES"
              disabled
              :rules="{ required: true }"
              :class="{ disable: true }"
            />

            <RgSelectCompetence
              id="competence-bpai"
              ref="competenceBPAI"
              v-model="searchBPAI.competence"
              :rules="{ required: true }"
              :class="{ disable: disabledByModal }"
              :disabled="disabledByModal"
            />

            <RgSuggestEmployee
              id="employee-bpai"
              ref="employeeBPAI"
              v-model="searchBPAI.employee"
              label="CNS ou Nome do Profissional"
              :unitHealthId="unitHealthId"
              :class="{ disable: disabledByModal }"
              :disabled="disabledByModal"
              @selected="selectEmployeeBPAI"
            />

            <RgSuggestCbo
              id="cbo-bpai"
              ref="cboBPAI"
              v-model="searchBPAI.cbo"
              label="CBO"
              :employeeId="searchBPAI.employeeId"
              :unitHealthId="unitHealthId"
              :disabled="!searchBPAI.employee || disabledByModal"
              :class="{ disable: !searchBPAI.employee || disabledByModal }"
              @selected="selectOccupationBPAI"
            />

            <RgSelectSheetBpaI
              v-if="!isLegacyBPAI"
              ref="sheetBPAI"
              v-model="searchBPAI.sheet"
              :filterRegister="{
                cnes: searchBPAI.cnes,
                cns: searchBPAI.employeeCNS,
                cbo: searchBPAI.ocp_codigo,
                competence: searchBPAI.competence,
              }"
              :disabled="
                !searchBPAI.employee || !searchBPAI.cbo || disabledByModal
              "
              :class="{
                disable:
                  !searchBPAI.employee || !searchBPAI.cbo || disabledByModal,
              }"
            />
          </div>
        </RgValidatorForm>

        <div class="automated-shipping-production-actions">
          <RgCleanButton :disabled="disabledByModal" @click="clearFilter" />
          <RgSearchButton
            ref="searchBtn"
            :disabled="disabledByModal"
            @submit="searchFilter(false)"
          />
        </div>
      </section>

      <hr class="separator" />

      <div
        v-if="isBPAC || isLegacyBPAC"
        class="container-dropdown-automated-shipping-production"
      >
        <RgDropdownTypeBpa
          ref="dropdownBpac"
          :disabled="disabledByModal"
          @on-criticize="selectFilterCriticizeBPAC"
        />
      </div>
      <div v-else class="container-dropdown-automated-shipping-production">
        <RgDropdownTypeBpa
          ref="dropdownBpai"
          :disabled="disabledByModal"
          @on-criticize="selectFilterCriticizeBPAI"
        />
      </div>

      <section
        v-if="hasBPACList || hasBPAIList || isFilter === true"
        class="automated-shipping-production-smart-table"
      >
        <div v-if="isBPAC || isLegacyBPAC">
          <SmartTable
            ref="smartTableBPAC"
            name="ConsistencyBPACList"
            :columns="this.typeBPA !== 3 ? COLUMNS_BPAC : COLUMNS_LEGACY_BPAC"
            :body="mutableBPACList"
            :initial-columns="8"
            :total="mutableBPACList.length"
            :show-pagination="false"
            :colorLine="colorLine"
            :class="{ criticism: hasBPACList, disable: disabledByModal }"
            class="smart-table"
            toggle-selected
            circle-indicator
            :disabled="disabledByModal"
            @getLine="selectBPAC"
          >
            <div slot="top-buttons" class="options-buttons">
              <div class="top-buttons">
                <RgEditButton
                  title="Editar BPAC"
                  :disabled="
                    !hasBPACSelected ||
                    isLegacyBPAC ||
                    disabledByModal ||
                    disableRegister
                  "
                  @click="openModalCreateBPAC"
                />
                <RgShowButton
                  v-show="!isLegacyBPAC"
                  title="Analisar Críticas"
                  :disabled="!hasBPACSelected || disabledByModal"
                  backgroundColor="#1E88A9"
                  @click="openModalViewProceduresReview"
                />
                <RgDropdown
                  v-show="isLegacyBPAC"
                  id="paper-dropdown"
                  ref="btnPaper"
                  small
                  show-down
                  show-left
                  :action-options="itemActionMovement()"
                  :disabled="!hasBPACSelected || disabledByModal"
                  background-color="#1e88a9"
                >
                  <IconShow slot="icon" />
                </RgDropdown>
                <SmallButton
                  title="Exportar BPA"
                  backgroundColor="#1E88A9"
                  :disabled="disabledByModal"
                  @click="exportBPA"
                >
                  <IconArrowDownload slot="icon" />
                </SmallButton>
              </div>
            </div>
          </SmartTable>
        </div>

        <div v-else>
          <SmartTable
            ref="smartTableBPAI"
            name="ConsistencyBPAIList"
            :columns="this.typeBPA !== 4 ? COLUMNS_BPAI : COLUMNS_LEGACY_BPAI"
            :body="mutableBPAIList"
            :initial-columns="8"
            :total="mutableBPAIList.length"
            :show-pagination="false"
            :colorLine="colorLine"
            :class="{ criticism: hasBPAIList, disable: disabledByModal }"
            class="smart-table"
            toggle-selected
            circle-indicator
            :disabled="disabledByModal"
            @getLine="selectBPAI"
          >
            <div slot="top-buttons" class="options-buttons">
              <div class="top-buttons">
                <RgEditButton
                  title="Editar BPAI"
                  :disabled="
                    !hasBPAISelected ||
                    isLegacyBPAI ||
                    disabledByModal ||
                    disableRegister
                  "
                  @click="editBPAI"
                />
                <RgShowButton
                  title="Analisar Críticas"
                  :disabled="
                    !(hasBPAISelected || hasBPAILegacySelected) ||
                    disabledByModal
                  "
                  @click="openModalViewProceduresReview"
                />
                <SmallButton
                  title="Exportar BPA"
                  backgroundColor="#1E88A9"
                  :disabled="disabledByModal"
                  @click="exportBPA"
                >
                  <IconArrowDownload slot="icon" />
                </SmallButton>
              </div>
            </div>
          </SmartTable>
        </div>
      </section>
      <section v-else class="automated-shipping-production-empty-list">
        <IconEmpty class="icon" />
        <span class="message">
          FAÇA UMA BUSCA PARA EXIBIR OS RESULTADOS AQUI
        </span>
      </section>
      <RgPagination
        v-show="hasBPACList || hasBPAIList || isFilter === true"
        ref="paginator"
        :data-length="countItem"
        :item-per-page="pagination.limit"
        :maxRegister="20"
        :disabled="disabledByModal"
        :class="{ disable: disabledByModal }"
        @pagination="setPagination"
      />
    </FormBase>

    <ModalViewProcedureReviews
      id="modal-view-procedure-reviews"
      :show="modalViewProcedureReviews"
      :isBPAC="isBPAC || isLegacyBPAC"
      :criticismSelected="
        isBPAC || isLegacyBPAC ? this.selectedBPAC : this.selectedBPAI
      "
      isCriticism
      @close="closeModalViewProceduresReview"
    />
    <ModalViewAttendances
      id="modal-view-attendances"
      :show="modalViewAttendances"
      :selectedBPAC="selectedBPAC"
      @close="closeModalViewAttendances"
    />

    <ModalCreateBpaC
      id="modal-create-bpa-c"
      :show="modalCreateBpaC"
      :selectedData="selectedBPAC"
      edition
      @reSearch="reSearchBPAC"
      @close="closeModalCreateBPAC"
    />
    <ModalClassificationProcedure
      id="modal-classification-procedure"
      :show="modalClassificationProcedure"
      :serviceInfo="appointmentSelected"
      :procedureInfo="procedureSelected"
      isEditing
      @reSearch="reSearchLegacyBPAI"
      @close="closeModalClassificationProcedure"
    />
  </Modulebox>
</template>

<script>
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import RgSuggestCboProcedure from "$hospitalization/common/component/suggest/rg-suggest-cbo-procedure/RgSuggestCboProcedure";
import RgDropdownTypeBpa from "$billing/submodules/bpa/automated-shipping-production/components/dropdown-type-bpa/RgDropdownTypeBpa";
import ModalViewProcedureReviews from "$billing/submodules/bpa/automated-shipping-production/components/modal-view-procedure-reviews/ModalViewProcedureReviews";
import ModalCreateBpaC from "$billing/submodules/bpa/bpa-c/components/modal/modal-create-bpa-c/ModalCreateBpaC";
import ModalClassificationProcedure from "$billing/submodules/bpa/attendances/components/modal/modal-classification-procedure/ModalClassificationProcedure";
import ModalViewAttendances from "$billing/submodules/bpa/automated-shipping-production/components/modal-view-attendances/ModalViewAttendances";
import { mapGetters } from "vuex";
import moment from "moment";
import { Modulebox, FormBase, SmartTable } from "~tokio/foundation";
import { RgSelectSheet as RgSelectSheetBpaC } from "$billing/submodules/bpa/bpa-c/components";
import { RgSelectSheet as RgSelectSheetBpaI } from "$billing/submodules/bpa/bpa-i/components";
import {
  RgSuggestEmployee,
  RgSelectCompetence,
  RgSuggestCbo,
} from "$billing/common/components/index";
import {
  RgValidatorForm,
  IconArrowDownload,
  IconEmpty,
  IconShow,
  RgInput,
  RgSuggestProcedure,
  RgRadioCustom,
  RgEditButton,
  RgCleanButton,
  RgSearchButton,
  RgShowButton,
  SmallButton,
  IconCalendar,
  LargeButton,
  RgDropdown,
} from "~tokio/primitive";
import RgPagination from "~tokio/foundation/pagination/RgPagination";

const FORM_FILTER_BPAC = {
  cnes: null,
  competence: null,
  sheet: null,
  procedure: null,
  procedureCode: null,
  cbo: null,
  age: null,
  amount: null,
  cboCode: null,
};

const FORM_FILTER_BPAI = {
  cnes: null,
  competence: null,
  employee: null,
  cbo: null,
  sheet: null,
  employeeId: null,
  employeeCNS: null,
  cboCode: null,
  professionalName: null,
  vin_id: null,
  ocp_id: null,
  ocp_nome: null,
  ocp_codigo: null,
};

export default {
  name: "AutomatedShippingProduction",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    SmartTable,
    IconArrowDownload,
    IconShow,
    IconEmpty,
    RgInput,
    RgSuggestProcedure,
    RgSuggestCbo,
    RgRadioCustom,
    RgCleanButton,
    RgSearchButton,
    RgShowButton,
    RgSuggestEmployee,
    RgSelectCompetence,
    RgSelectSheetBpaC,
    RgSelectSheetBpaI,
    RgSuggestCboProcedure,
    RgEditButton,
    SmallButton,
    ModalViewProcedureReviews,
    ModalCreateBpaC,
    ModalClassificationProcedure,
    RgDropdownTypeBpa,
    LargeButton,
    IconCalendar,
    RgPagination,
    RgDropdown,
    ModalViewAttendances,
  },
  data() {
    return {
      typeBPA: 1,
      searchBPAC: this.$utils.obj.DeepCopy(FORM_FILTER_BPAC),
      searchBPAI: this.$utils.obj.DeepCopy(FORM_FILTER_BPAI),
      mutableBPACList: [],
      mutableBPAIList: [],
      selectedBPAC: {},
      selectedBPAI: {},
      unitHealthId: null,
      modalViewProcedureReviews: false,
      modalCreateBpaC: false,
      filterBpai: "all",
      filterBpac: "all",
      auxBpacList: null,
      auxBpaiList: null,
      isFilter: false,
      modalClassificationProcedure: false,
      modalViewAttendances: false,
      appointmentSelected: {},
      procedureSelected: {},
      activeFilter: false,
      countItem: 0,
      disableRegister: true,
      pagination: {
        limit: 20,
        offset: 0,
        current: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      cnes: "Login/GET_UNIT_HEALTH_CNES" || null,
      competence: "Billing/BDSia/GET_FORMATTED_PERIOD_DATE" || null,
      currentCompetence: "Billing/BDSia/GET_PERIOD_DATE",
    }),
    disabledByModal() {
      return (
        this.modalClassificationProcedure ||
        this.modalViewAttendances ||
        this.modalViewProcedureReviews ||
        this.modalCreateBpaC
      );
    },
    propsRadioCustom() {
      const BPAC = 1;
      const BPAI = 2;
      const LegacyBPAC = 3;
      const LegacyBPAI = 4;
      const value = this.typeBPA;
      const uniqueKey = "id";
      const canUnselect = false;
      const list = [
        { title: "Produção Manual BPA-C", blue: true, id: BPAC },
        { title: "Produção Manual BPA-I", blue: true, id: BPAI },
        { title: "Atendimentos BPA-C", blue: true, id: LegacyBPAC },
        { title: "Atendimentos BPA-I", blue: true, id: LegacyBPAI },
      ];

      return { list, uniqueKey, value, canUnselect };
    },
    colorLine() {
      return {
        get: (item) => {
          if (item.hasError) {
            return GetColorRowSmartTable("red");
          } else {
            return GetColorRowSmartTable("green");
          }
        },
      };
    },
    isBPAC() {
      return this.typeBPA && this.typeBPA === 1;
    },
    isBPAI() {
      return this.typeBPA && this.typeBPA === 2;
    },
    isLegacyBPAC() {
      return this.typeBPA && this.typeBPA === 3;
    },
    isLegacyBPAI() {
      return this.typeBPA && this.typeBPA === 4;
    },
    isCriticism() {
      return this.selectedBPAC
        ? this.selectedBPAC.hasError
        : this.selectedBPAI
        ? this.selectedBPAI.hasError
        : null;
    },
    hasBPACList() {
      return this.mutableBPACList && this.mutableBPACList.length > 0;
    },
    hasBPAIList() {
      return this.mutableBPAIList && this.mutableBPAIList.length > 0;
    },
    hasBPACSelected() {
      return this.selectedBPAC && Object.keys(this.selectedBPAC).length > 0;
    },
    hasBPAISelected() {
      return this.selectedBPAI && Object.keys(this.selectedBPAI).length > 0;
    },
    hasBPAILegacySelected() {
      return (
        this.appointmentSelected &&
        Object.keys(this.appointmentSelected).length > 0 &&
        this.procedureSelected &&
        Object.keys(this.procedureSelected).length > 0
      );
    },
  },
  watch: {
    "searchBPAC.procedure"(pValue, pPrev) {
      if (!pValue) {
        this.searchBPAC.age = null;
        this.searchBPAC.amount = null;
        this.searchBPAC.procedureCode = null;
      } else {
        this.searchBPAC.procedureCode = this.searchBPAC.procedure.substring(
          0,
          this.searchBPAC.procedure.indexOf("-") - 1,
        );
      }
    },
    "searchBPAI.employee"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.searchBPAI.cbo = null;
        this.searchBPAI.cboCode = null;
        this.searchBPAI.sheet = null;
      }
    },
    "searchBPAI.cbo"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.searchBPAI.sheet = null;
      }
    },
    "searchBPAI.competence"(pValue) {
      if (pValue) {
        this.validCompetence(false);
        this.mutableBPAIList = [];
      }
    },
    "searchBPAC.competence"(pValue, pPrev) {
      if (pValue) {
        this.validCompetence(false);
        this.mutableBPACList = [];
      }
    },
    typeBPA(pValue, pPrev) {
      if (pValue && pPrev && pValue !== pPrev && !this.activeFilter) {
        this.clearFilter();
      }
    },
  },
  mounted() {
    this.$store.commit("Billing/BPAI/CLEAN_REGISTER_BILLING_BPAI");
    this.unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.searchBPAC.cnes = this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
    this.searchBPAI.cnes = this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
    this.searchBPAC.competence = this.currentCompetence;
    this.searchBPAI.competence = this.currentCompetence;

    this.fillFilters();
  },
  created() {
    this.COLUMNS_BPAC = [
      {
        name: "Crítica",
        key: "",
        active: true,
        align: "center",
      },
      {
        name: "Competência",
        key: "fco_competencia",
        align: "right",
        active: true,
      },
      { name: "Folha", key: "fco_folha", align: "right", active: true },
      {
        name: "Procedimento",
        key: "procedureName",
        align: "left",
        active: true,
      },
      { name: "CBO", key: "occupationName", align: "left", active: true },
      { name: "Idade", key: "fco_idade", align: "right", active: true },
      {
        name: "Quantidade",
        key: "fco_quantidade",
        align: "right",
        active: true,
      },
    ];
    this.COLUMNS_BPAI = [
      {
        name: "Crítica",
        key: "",
        active: true,
        align: "center",
      },
      {
        name: "Origem do Atendimento",
        key: "origem_atendimento",
        align: "left",
        active: true,
      },
      {
        name: "Competência",
        key: "bof_competencia",
        align: "right",
        active: true,
      },
      { name: "Folha", key: "pdf_folha", align: "right", active: true },
      {
        name: "Procedimento",
        key: "NO_PROCEDIMENTO",
        align: "left",
        active: true,
      },
      { name: "CBO", key: "NO_OCUPACAO", align: "left", active: true },
      { name: "Paciente", key: "bof_nome", align: "left", active: false },
      { name: "Idade", key: "idade", align: "right", active: true },
      {
        name: "Quantidade",
        key: "pdf_quantidade",
        align: "right",
        active: true,
      },
    ];
    this.COLUMNS_LEGACY_BPAI = [
      {
        name: "Crítica",
        key: "",
        active: true,
        align: "center",
      },
      {
        name: "Competência",
        key: "competence",
        align: "right",
        active: true,
      },
      {
        name: "Procedimento",
        key: "NO_PROCEDIMENTO",
        align: "left",
        active: true,
      },
      { name: "CBO", key: "NO_OCUPACAO", align: "left", active: true },
      { name: "Paciente", key: "pes_nome", align: "left", active: false },
      { name: "Idade", key: "idade", align: "right", active: true },
      { name: "Quantidade", key: "quantidade", align: "right", active: true },
    ];
    this.COLUMNS_LEGACY_BPAC = [
      {
        name: "Crítica",
        key: "",

        align: "center",
        active: true,
      },
      {
        name: "Competência",
        key: "competence",
        align: "right",
        active: true,
      },
      {
        name: "Procedimento",
        key: "NO_PROCEDIMENTO",
        align: "left",
        active: true,
      },
      { name: "CBO", key: "cbo_name", align: "left", active: true },
      { name: "Idade", key: "idade", align: "right", active: true },
      { name: "Quantidade", key: "quantidade", align: "right", active: true },
    ];
  },
  methods: {
    selectProcedure(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { CO_PROCEDIMENTO } = source;

        this.searchBPAC.procedureCode = CO_PROCEDIMENTO;
      } else {
        this.searchBPAC.procedureCode = null;
      }
    },
    selectCompetence() {
      this.$router.push({
        name: "billing.bpa.define-period",
      });
    },
    editBPAI() {
      this.isBPAI
        ? this.editRouteBPAI()
        : this.openModalClassificationProcedure();
    },
    async searchFilter(isPagination) {
      try {
        this.$loader.start();

        this.isFilter = false;
        this.auxBpacList = null;
        this.auxBpaiList = null;
        this.clearSelectedBPA();

        if (!isPagination) {
          this.$refs.paginator.initLimit(20);
          this.cleanPagination();
        }

        await this.validCompetence(true);

        if (this.isBPAC) {
          await this.searchFilterBPAC();
        }
        if (this.isBPAI) {
          await this.searchFilterBPAI();
        }
        if (this.isLegacyBPAC) {
          await this.searchFilterLegacyBPAC();
        }
        if (this.isLegacyBPAI) {
          await this.searchFilterLegacyBPAI();
        }
      } finally {
        this.$loader.finish();
      }
    },
    async validateSearchFormBPA() {
      return this.$refs.validatorBPA
        ? this.$refs.validatorBPA.validate()
        : false;
    },
    async searchFilterBPAC() {
      try {
        const validate = await this.validateSearchFormBPA();

        if (!validate) {
          this.$toaster.warning("Verifique os campos");
          return;
        }

        const variables = {
          ...this.searchBPAC,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          typeFilter: this.filterBpac,
        };
        const result = await this.$store.dispatch(
          "Billing/Review/SEARCH_CRITICIZE_BPAC",
          variables,
        );

        this.mutableBPACList = result.rows;
        this.countItem = result.total;
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionSubmit();
      } catch (pErr) {
        this.$toaster.error("Não foi possível realizar a busca BPAC", pErr);
      } finally {
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionDone();
      }
    },
    async searchFilterBPAI() {
      try {
        const validate = await this.validateSearchFormBPA();

        if (!validate) {
          this.$toaster.warning("Verifique os campos");
          return;
        }

        const variables = {
          cboCode: this.searchBPAI.cboCode
            ? String(this.searchBPAI.cboCode)
            : null,
          cnes: this.searchBPAI.cnes,
          competence: this.searchBPAI.competence,
          employeeCNS: this.searchBPAI.employeeCNS,
          sheet: this.searchBPAI.sheet,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          typeFilter: this.filterBpai,
        };

        const result = await this.$store.dispatch(
          "Billing/Review/SEARCH_CRITICIZE_BPAI",
          variables,
        );

        this.mutableBPAIList = result.rows;
        this.countItem = result.total;

        if (this.$refs.searchBtn) this.$refs.searchBtn.actionSubmit();

        this.$store.commit("Billing/Review/SET_FILTER_AUTOMATED_BPAI", {
          typeBPA: this.typeBPA,
          ...this.searchBPAI,
          current: this.pagination.current,
          typeFilter: this.filterBpai,
        });
      } catch (pErr) {
        this.$toaster.error("Não foi possível realizar a busca BPAI");
      } finally {
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionDone();
      }
    },
    async searchFilterLegacyBPAC() {
      try {
        const validate = await this.validateSearchFormBPA();
        if (!validate) {
          this.$toaster.warning("Verifique os campos");
          return;
        }
        const variables = {
          ...this.searchBPAC,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          typeFilter: this.filterBpac,
        };

        const result = await this.$store.dispatch(
          "Billing/Review/SEARCH_CRITICIZE_LEGACY_BPAC",
          variables,
        );

        this.mutableBPACList = result.rows;
        this.countItem = result.total;
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionSubmit();
      } catch (pErr) {
        this.$toaster.error("Não foi possível realizar a busca BPAC");
      } finally {
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionDone();
      }
    },
    async searchFilterLegacyBPAI() {
      try {
        const validate = await this.validateSearchFormBPA();
        if (!validate) {
          this.$toaster.warning("Verifique os campos");
          return;
        }
        const variables = {
          ...this.searchBPAI,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          typeFilter: this.filterBpai,
        };
        variables.cboCode = variables.cboCode
          ? variables.cboCode.toString()
          : null;

        const result = await this.$store.dispatch(
          "Billing/Review/SEARCH_CRITICIZE_LEGACY_BPAI",
          variables,
        );

        this.mutableBPAIList = result.rows;
        this.countItem = result.total;
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionSubmit();
      } catch (pErr) {
        this.$toaster.error("Não foi possível realizar a busca BPAI", pErr);
      } finally {
        if (this.$refs.searchBtn) this.$refs.searchBtn.actionDone();
      }
    },
    reSearchBPAC() {
      if (this.$refs.smartTableBPAC) this.$refs.smartTableBPAC.cleanSelectRow();
      this.searchFilterBPAC();
    },
    reSearchLegacyBPAI() {
      if (this.$refs.smartTableBPAI) this.$refs.smartTableBPAI.cleanSelectRow();
      this.searchFilterLegacyBPAI();
    },
    setPagination(pPagination) {
      this.pagination = pPagination;
      this.searchFilter(true);
    },
    itemActionMovement() {
      return [
        {
          label: "Visualizar críticas",
          action: this.openModalViewProceduresReview,
        },
        {
          label: "Visualizar atendimentos",
          action: this.openModalViewAttendances,
        },
      ];
    },
    openModalViewAttendances() {
      this.modalViewAttendances = true;
    },
    closeModalViewAttendances() {
      this.modalViewAttendances = false;
      this.clearSelectedBPA();
    },
    openModalClassificationProcedure() {
      this.modalClassificationProcedure = true;
    },
    closeModalClassificationProcedure() {
      this.modalClassificationProcedure = false;
      this.searchFilter();
    },
    selectFilterCriticizeBPAC(typeCriticize) {
      this.pagination.limit = 20;
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.filterBpac = typeCriticize;
      this.searchFilter();
    },
    selectFilterCriticizeBPAI(typeCriticize) {
      this.pagination.limit = 20;
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.filterBpai = typeCriticize;
      this.searchFilter();
    },
    selectOccupationBPAC(pValue) {
      if (pValue && Object.keys(pValue.source).length > 0) {
        const { ocp_codigo } = pValue.source;
        this.searchBPAC.cboCode = ocp_codigo;
      } else {
        this.searchBPAC.cboCode = null;
        this.searchBPAC.cbo = null;
      }
    },
    selectEmployeeBPAI(pValue) {
      if (pValue && Object.keys(pValue.source).length > 0) {
        const { crs_numero, fun_id } = pValue.source;
        this.searchBPAI.employeeCNS = crs_numero;
        this.searchBPAI.employeeId = fun_id;
        this.searchBPAI.professionalName = pValue.source.pes_nome;
        this.searchBPAI.vin_id = pValue.source.vin_id;
      } else {
        this.searchBPAI.employeeCNS = null;
        this.searchBPAI.employeeId = null;
        this.searchBPAI.professionalName = null;
        this.searchBPAI.vin_id = null;
      }
    },
    selectOccupationBPAI(pValue) {
      if (pValue && Object.keys(pValue.source).length > 0) {
        this.searchBPAI.cboCode = pValue.source?.ocp_codigo;
        this.searchBPAI.ocp_codigo = pValue.source?.ocp_codigo;
        this.searchBPAI.ocp_id = pValue.source?.ocp_id;
        this.searchBPAI.ocp_nome = pValue.source?.ocp_nome;
      } else {
        this.searchBPAI.cboCode = null;
        this.searchBPAI.ocp_id = null;
        this.searchBPAI.ocp_nome = null;
      }
    },
    selectBPAC(pItem) {
      if (pItem) {
        if (this.isBPAC) {
          this.selectedBPAC = {
            ...pItem,
            fco_idade_requerida:
              pItem.VALID_ATRIBUTOS_IDADE === "1" &&
              (pItem.fco_idade === "" || pItem.fco_idade === null)
                ? 0
                : 1,
          };
        } else if (this.isLegacyBPAC) {
          if (pItem) {
            this.selectedBPAC = {
              ...pItem,
              uns_cnes: pItem.uns_cnes,
              cmp: pItem.competence,
              cbo: pItem.cbo,
              procedimento: pItem.codigo_procedimento,
              procedimento_nome: pItem.NO_PROCEDIMENTO,
              idade: pItem.idade,
              quantidade_total: pItem.quantidade,
            };
          }
        }
      } else {
        this.selectedBPAC = {};
      }
    },
    selectBPAI(pItem) {
      if (this.isBPAI) {
        if (pItem) {
          this.selectedBPAI = pItem;
        } else {
          this.selectedBPAI = {};
        }
      } else if (this.isLegacyBPAI) {
        if (pItem) {
          this.selectedBPAI = pItem;
          this.appointmentSelected = {
            idPessoa: pItem.id_pessoa,
            fun_id: pItem.ppd_id_funcionarios,
            con_id: pItem.ppd_id_consultas,
            dataHoraAtendimento: pItem.hag_data_consulta,
            pes_nome: pItem.pes_nome,
            nomeFuncionario: pItem.nomeFuncionario,
            crs_numero: pItem.crs_numero,
            ocp_nome: pItem.NO_OCUPACAO,
            uns_id: pItem.uns_id,
          };
          this.procedureSelected = {
            ppd_id: pItem.ppd_id,
            tum_id_ocupacoes_cbo: pItem.ppd_id_ocupacoes_cbo,
            sus_pa_id_dv: pItem.ppd_codigo_procedimento,
            ppd_cid10: pItem.CO_CID,
            ppd_servico: pItem.CO_SERVICO,
            ppd_classificacao: pItem.CO_CLASSIFICACAO,
            ppd_quantidade: pItem.quantidade,
          };
        } else {
          this.selectedBPAI = {};
          this.procedureSelected = {};
          this.appointmentSelected = {};
        }
      }
    },
    onInputRadioCustom(pItem) {
      this.isFilter = false;
      this.mutableBPACList = [];
      this.mutableBPAIList = [];
      if (pItem) {
        this.activeFilter = false;
        this.typeBPA = pItem.id;
        this.$store.commit("Billing/Review/RESET_FILTER_AUTOMATED_BPAI");
      }
    },
    editRouteBPAI() {
      this.$store.commit("Billing/BPAI/SELECT_REGISTER_BILLING_BPAI", {
        ...this.selectedBPAI,
      });
      this.$router.push({
        name: "billing.bpa.automated-shipping-production.bpa-i.edit",
      });
    },
    exportBPA() {
      this.$router.push({
        name: "billing.bpa.automated-shipping-production.export-billing",
      });
    },
    openModalCreateBPAC() {
      this.modalCreateBpaC = true;
    },
    closeModalCreateBPAC() {
      this.modalCreateBpaC = false;
      this.searchFilter();
    },
    openModalViewProceduresReview() {
      this.modalViewProcedureReviews = true;
    },
    closeModalViewProceduresReview() {
      this.modalViewProcedureReviews = false;
      this.clearSelectedBPA();
    },
    async fillFilters() {
      try {
        this.$loader.start();
        const filterData = await this.$store.getters[
          "Billing/Review/GET_FILTER_AUTOMATED_BPAI"
        ];

        if (filterData?.typeBPA === 2) {
          this.typeBPA = await filterData.typeBPA;
          this.activeFilter = true;
          if (
            filterData &&
            Object.keys(filterData).length > 0 &&
            this.$refs.employeeBPAI &&
            this.$refs.cboBPAI
          ) {
            await this.$refs.competenceBPAI.getCompetences();
            this.searchBPAI.competence = filterData.competence;
            this.searchBPAI.employee = filterData.employee;
            this.searchBPAI.employeeCNS = filterData.employeeCNS;
            this.searchBPAI.cbo = filterData.cbo;
            this.searchBPAI.cboCode = filterData.cboCode;
            this.searchBPAI.sheet = filterData.sheet;
            this.filterBpai = filterData.typeFilter;

            await this.$refs.dropdownBpai.loadValue(filterData.typeFilter);
            await this.searchFilterBPAI();
            await this.$refs.paginator.setPage(filterData.current);
            this.validCompetence(true);
          } else {
            this.fillFilters();
          }
        }
      } catch (pErr) {
        this.$toaster.warning("Não foi possível preencher os filtros.");
      } finally {
        this.$loader.finish();
      }
    },
    clearFilter() {
      this.isFilter = false;
      this.cleanFieldsValidation();
      this.clearSearchBPAC();
      this.clearSearchBPAI();
    },
    clearSearchBPAC() {
      const currentSearchBPACCompetence = this.searchBPAC.competence;
      this.searchBPAC = this.$utils.obj.DeepCopy(FORM_FILTER_BPAC);
      this.searchBPAC.competence =
        currentSearchBPACCompetence || this.currentCompetence;
      this.searchBPAC.cnes = this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
      this.mutableBPACList = [];
      this.selectedBPAC = {};
      this.auxBpacList = null;
      this.filterBpac = "all";
      if (this.$refs.smartTableBPAC) this.$refs.smartTableBPAC.cleanSelectRow();
      if (this.$refs.dropdownBpac) this.$refs.dropdownBpac.clearValue();
    },
    clearSearchBPAI() {
      const currentSearchBPAICompetence = this.searchBPAI.competence;
      this.searchBPAI = this.$utils.obj.DeepCopy(FORM_FILTER_BPAI);
      this.searchBPAI.competence =
        currentSearchBPAICompetence || this.currentCompetence;
      this.searchBPAI.cnes = this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
      this.mutableBPAIList = [];
      this.selectedBPAI = {};
      this.auxBpaiList = null;
      this.filterBpai = "all";
      if (this.$refs.smartTableBPAI) this.$refs.smartTableBPAI.cleanSelectRow();
      if (this.$refs.dropdownBpai) this.$refs.dropdownBpai.clearValue();
    },
    cleanFieldsValidation() {
      const fieldsRefsBPAC = ["CNESBPAC", "competenceBPAC", "sheetBPAC"];
      const fieldsRefsBPAI = [
        "CNESBPAI",
        "competenceBPAI",
        "employeeBPAI",
        "sheetBPAI",
      ];
      (this.isBPAC || this.isLegacyBPAC
        ? fieldsRefsBPAC
        : fieldsRefsBPAI
      ).forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    clearSelectedBPA() {
      if (this.$refs.smartTableBPAI) this.$refs.smartTableBPAI.cleanSelectRow();
      this.selectedBPAI = {};
      if (this.$refs.smartTableBPAC) this.$refs.smartTableBPAC.cleanSelectRow();
      this.selectedBPAC = {};
      this.procedureSelected = {};
      this.appointmentSelected = {};
    },
    cleanPagination() {
      this.pagination.limit = 20;
      this.pagination.current = 1;
      this.pagination.offset = 0;
    },

    async validCompetence(hasLoader) {
      if (!hasLoader) this.$loader.start();

      const result = await this.$store.dispatch(
        "Billing/BPAI/LAST_COMPETENCE_BPAI",
      );

      const lastCompetence = result[0].DT_COMPETENCIA;
      const limitRetroactive = moment(lastCompetence, "YYYY-MM")
        .subtract(3, "months")
        .format("YYYYMM");

      if (this.isBPAC || this.isLegacyBPAC) {
        this.disableRegister =
          Number(limitRetroactive) > Number(this.searchBPAC.competence);
      }

      if (this.isBPAI || this.isLegacyBPAI) {
        this.disableRegister =
          Number(limitRetroactive) > Number(this.searchBPAI.competence);
      }

      if (!hasLoader) this.$loader.finish();
    },
  },
};
</script>
