<template>
  <div v-if="show" class="modal-import-type-presentation">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-import-type-presentation-header">
        <div class="title">
          Importar Tipos de Apresentação de Produtos/Medicamentos
        </div>
      </div>

      <div slot="body" class="modal-import-type-presentation-body">
        <FormBase title="Tipos Disponíveis para Importação">
          <section class="table">
            <SmartTable
              ref="smartTable"
              name="PharmacyImportTypePresentation"
              :columns="COLUMNS"
              :body="mutableTypePresentationList"
              :total="mutableTypePresentationList.length"
              :initial-columns="2"
              toggleSelected
              removeBtnColumns
              multSelect
              :showPagination="false"
              @getMultLines="selectLines"
            />
          </section>
        </FormBase>
      </div>

      <div slot="footer" class="modal-import-type-presentation-footer">
        <RgCancelButton medium @click="cancel" />
        <MediumButton
          medium
          title="Importar"
          label="Importar"
          @click="importTypePresentation"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, MediumButton } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import SearchTypeUnit from "./action/SearchTypeUnit";

export default {
  name: "ModalImportTypePresentation",
  components: {
    RgBaseModal,
    FormBase,
    SmartTable,
    RgCancelButton,
    MediumButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        typesPresentation: [],
      },
      mutableTypePresentationList: [],
    };
  },
  computed: {},
  watch: {
    async show(pValue) {
      if (pValue) {
        await this.searchTypeUnit();
      } else {
        this.close();
      }
    },
  },
  created() {
    this.COLUMNS = [
      { name: "Sigla", key: "tud_codigo", align: "left" },
      { name: "Apresentação", key: "tud_nome", align: "left" },
    ];
  },
  methods: {
    async searchTypeUnit() {
      try {
        this.$loader.start();

        const result = await SearchTypeUnit();
        this.mutableTypePresentationList = result;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar tipos de unidade");
      } finally {
        this.$loader.finish();
      }
    },

    selectLines(pValue) {
      const types = [];
      const selectedTypesPresentation = pValue;

      selectedTypesPresentation.forEach((item) => {
        types.push(item.tud_id);
      });
      this.form.typesPresentation = types;
    },

    async importTypePresentation() {
      try {
        if (this.form.typesPresentation.length < 1) {
          this.$toaster.warning(
            "Pelo menos um registro deverá ser selecionado para a importação",
          );
          return;
        }

        this.$loader.start();

        const variables = {
          arrFormData: this.form.typesPresentation,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/IMPORT_TYPE_PRESENTATION",
          variables,
        );

        this.$toaster.success(
          "Os dados foram importados.",
          "Tipos de apresentação importados com sucesso",
        );

        this.close();
        this.$emit("reSearch");
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao importar tipo de apresentação",
        );
      } finally {
        this.$loader.finish();
      }
    },

    cancel() {
      this.close();
    },

    close() {
      this.form.typesPresentation = [];
      this.mutableTypePresentationList = [];
      this.$emit("close");
    },
  },
};
</script>
