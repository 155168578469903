<template>
  <div v-if="show" class="modal-see-request-details">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-see-request-details-header">
        <span class="title"> Solicitação de Produtos e Medicamentos </span>
      </div>

      <div slot="body" class="modal-see-request-details-body">
        <FormBase title="Dados de Origem e Destino">
          <section class="origin-destination-data">
            <div class="unit-origin">
              <span> Unidade de Saúde Origem: </span>
              <span>
                {{ data.unitHealthOrigin }}
              </span>
            </div>
            <div class="farmacy-origin">
              <span> Farmácia Origem: </span>
              <span>
                {{ data.farmacyOrigin }}
              </span>
            </div>
            <div class="unit-destiny">
              <span> Unidade de Saúde Destino: </span>
              <span> {{ data.unitHealthDestiny }} </span>
            </div>
            <div class="farmacy-destiny">
              <span> Farmácia Destino: </span>
              <span>
                {{ data.farmacyDestiny }}
              </span>
            </div>
            <hr class="hr" />
            <div class="date">
              <span> Data: </span>
              <span> {{ data.requestDate }} </span>
            </div>
            <div class="number">
              <span> Número: </span>
              <span> {{ data.requestNumber }} </span>
            </div>
          </section>
        </FormBase>

        <FormBase title="Lista de Atendimentos" class="list-requests">
          <div class="list">
            <section
              v-for="item in mutableProductList"
              :key="item"
              class="product-list"
            >
              <div class="code">
                <span>Código: </span>
                <span>{{ item.mpd_codigo }}</span>
              </div>
              <hr class="separator" />
              <div>
                <span class="product-name">Nome: </span>
                <span>{{ item.mpd_novo_principio_ativo }}</span>
              </div>
              <div class="type">
                <span>Tipo: </span>
                <span>{{ item.mtp_nome }}</span>
              </div>
              <div class="situation">
                <span>Situação: </span>
                <span>{{ item.std_nome }}</span>
              </div>
              <div class="presentation">
                <span>Apresentação: </span>
                <span>{{ item.mtu_novo_nome }}</span>
              </div>
              <div class="requested">
                <span>Quant. Solicitada: </span>
                <span>{{ item.itp_quantidade_solicitada }}</span>
              </div>
              <div class="delivered">
                <span>Quant. Entregue: </span>
                <span>{{ item.trs_quantidade || "-" }}</span>
              </div>
            </section>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="modal-see-request-details-footer">
        <RgRadioCustom
          id="status"
          label="Situação: "
          v-bind="propsRadio"
          :value="statusOrder"
          class="situation"
          disabled
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgRadioCustom } from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalRequestDetails",
  components: {
    FormBase,
    RgBaseModal,
    RgRadioCustom,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: {},
      request: {},
      itemsByOrder: {},
      mutableProductList: [],
    };
  },
  computed: {
    propsRadio() {
      const list = [
        { title: "Aberto", blue: true, id: 1, active: true },
        {
          title: "Fechado",
          blue: true,
          id: 2,
          active: false,
        },
      ];
      const uniqueKey = "id";

      return { list, uniqueKey };
    },
    statusOrder() {
      const INACTIVE_STATUS = "1";

      const OPEN = 1;
      const CLOSED = 2;

      return this.request.ped_status === INACTIVE_STATUS ? CLOSED : OPEN;
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.getRequest(this.requestId);
      }
    },
  },
  created() {
    this.COLUMNS = [
      { name: "Código", key: "mpd_codigo", active: true },
      { name: "Nome", key: "mpd_novo_principio_ativo", active: true },
      { name: "Apresentação", key: "mtu_novo_nome", active: true },
      { name: "Tipo", key: "mtp_nome", active: true },
      {
        name: "Quant. Solicitada",
        key: "itp_quantidade_solicitada",
        active: true,
      },
      { name: "Quant. Entregue", key: "trs_quantidade", active: true },
      { name: "Situação", key: "std_nome", active: true },
    ];
  },
  methods: {
    async getRequest(pRequestId) {
      try {
        this.$loader.start();

        this.request = await this.$store.dispatch(
          "Pharmacy/Satellite/GET_REQUEST",
          { intIdPedido: pRequestId },
        );

        await this.searchItemsByOrder(pRequestId);
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao carregar solicitação");
      } finally {
        this.$loader.finish();
      }
    },
    async searchItemsByOrder(pRequestId) {
      try {
        this.$loader.start();

        const variables = {
          intIdPedido: pRequestId,
          blnSomenteDisponiveis: false,
          blnDispensacoes: true,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_ITEMS_BY_ORDER",
          variables,
        );

        this.itemsByOrder = result.dados;
        this.mutableProductList = this.itemsByOrder;

        this.getValues();
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao carregar os itens da solicitação");
      } finally {
        this.$loader.finish();
      }
    },
    getValues() {
      const unitHealthOrigin = this.request?.nomeUnidadeOrigem?.uns_nome || "-";
      const unitHealthDestiny =
        this.request?.nomeUnidadeDestino?.uns_nome || "-";
      const farmacyOrigin = this.itemsByOrder[0]?.solicitante || "-";
      const farmacyDestiny = this.itemsByOrder[0]?.atendente || "-";
      const requestDate = this.request?.ped_data_pedido || "-";
      const requestNumber = this.request?.ped_numero || "-";

      this.data = {
        unitHealthOrigin: unitHealthOrigin,
        unitHealthDestiny: unitHealthDestiny,
        farmacyOrigin: farmacyOrigin,
        farmacyDestiny: farmacyDestiny,
        requestDate: requestDate,
        requestNumber: requestNumber,
      };
    },
    close() {
      this.mutableProductList = [];
      this.$emit("close");
    },
  },
};
</script>
