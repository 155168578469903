<template>
  <div v-if="show" class="modal-patient-bond-basic-attention">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">
          Paciente vinculado à Ficha de Cadastro Individual da Atenção Básica
        </div>
      </div>

      <div slot="body" class="body">
        <FormBase title="Dados da Atenção Básica" class="form-base-ab">
          <div>
            <Tooltip
              class="tooltip"
              startOpen
              :message="`Atualizada em: ${patientBasicAttentionLastDateChange}`"
            >
              <IconInfoHelper slot="icon" />
            </Tooltip>
          </div>

          <section class="basic-attention-section">
            <div class="basic-attention-section-subtitle">
              <span> Cadastro Cidadão </span>
              <hr />
            </div>
            <div class="row">
              <span>
                Situação:
                <b>{{ patientSituation }}</b>
              </span>
              <span>
                Motivo:
                <b>{{ patientSituationReason }}</b>
              </span>
            </div>
          </section>

          <section class="basic-attention-section">
            <div class="basic-attention-section-subtitle">
              <span> Dados do Cadastro Individual </span>
              <hr />
            </div>

            <div class="row">
              <span>
                Responsável:
                <b>{{ individualRegistration.pae_responsavel }}</b>
              </span>
              <span></span>
              <span></span>
              <span>
                Nome:
                <b>{{ individualRegistration.pae_nome_paciente }}</b>
              </span>
              <span>
                Data de Nascimento:
                <b>{{ formatDate(individualRegistration.pae_nascimento) }}</b>
              </span>
              <span></span>
              <span>
                Nome Social:
                <b>{{ individualRegistration.pae_nome_social }} </b>
              </span>
              <span>
                CNS:
                <b>{{ formatCns(individualRegistration.pae_cartao_sus) }}</b>
              </span>
              <span>
                CPF: <b>{{ formatCpf(individualRegistration.pae_cpf) }}</b>
              </span>
              <span>
                Telefone Celular:
                <b>{{ individualRegistration.pae_celular }}</b>
              </span>
              <span>
                Telefone:
                <b>{{ individualRegistration.pae_telefone }}</b>
              </span>
              <span>
                E-mail:
                <b>{{ individualRegistration.pae_email }}</b>
              </span>
              <span class="address">
                Endereço:
                <b>{{ individualRegistration.pae_endereco }}</b>
              </span>
            </div>
          </section>

          <section class="basic-attention-section">
            <div class="basic-attention-section-subtitle">
              <span> Dados da Visita Domiciliar </span>
              <hr />
            </div>
            <div class="row">
              <span>
                Data da Última Visita:
                <b>{{
                  formatDate(individualRegistration.pae_data_visita_domiciliar)
                }}</b>
              </span>
              <span>
                Unidade de Saúde:
                <b>{{
                  individualRegistration.pae_unidade_saude_visita_domiciliar
                }}</b>
              </span>
              <span></span>
              <span>
                Área:
                <b>{{ individualRegistration.pae_area_visita_domiciliar }}</b>
              </span>
              <span>
                Equipe:
                <b>{{ individualRegistration.pae_equipe_visita_domiciliar }}</b>
              </span>
              <span>
                Microárea:
                <b>{{
                  individualRegistration.pae_microarea_visita_domiciliar
                }}</b>
              </span>
              <span>
                Responsável:
                <b>{{
                  individualRegistration.pae_responsavel_visita_domiciliar
                }}</b>
              </span>
              <span>
                Ocupação:
                <b>{{
                  individualRegistration.pae_ocupacao_responsavel_visita_domiciliar
                }}</b>
              </span>
            </div>
          </section>
        </FormBase>

        <FormBase title="Dados do Cadastro Esus">
          <Tooltip
            v-show="errors"
            class="tooltip"
            startOpen
            message="Foram encontradas divergências entre o cadastro Esus e ficha AB, sinalizadas em vermelho"
          >
            <IconExclamationHelper slot="icon" hollow />
          </Tooltip>

          <div class="esus-registration">
            <div class="row">
              <span class="color-indication">
                Nome: <b>{{ esusRegistration.pes_nome }}</b>
                <ColorIndication
                  v-if="errorName"
                  :value="'#F96B70'"
                  class="align-color"
                />
              </span>
              <span></span>
              <span></span>
              <span class="color-indication">
                Data de Nascimento:
                <b>{{ esusRegistration.pes_nascimento }}</b>
                <ColorIndication
                  v-if="errorBirth"
                  :value="'#F96B70'"
                  class="align-color"
                />
              </span>
              <span class="color-indication">
                CNS: <b>{{ formatCns(esusRegistration.cns) }}</b>
                <ColorIndication
                  v-if="errorCns"
                  :value="'#F96B70'"
                  class="align-color"
                />
              </span>
              <span class="color-indication">
                CPF: <b>{{ formatCpf(esusRegistration.cpf) }}</b>
                <ColorIndication
                  v-if="errorCpf"
                  :value="'#F96B70'"
                  class="align-color"
                />
              </span>
              <span class="contact">
                Contatos:
                <b>{{ esusRegistration.telephones }}</b>
              </span>
              <span class="address">
                Endereço:
                <b>{{ esusRegistration.address }}</b>
              </span>
            </div>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  IconInfoHelper,
  IconExclamationHelper,
  Tooltip,
  ColorIndication,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalPatientBondBasicAttention",
  components: {
    RgBaseModal,
    FormBase,
    IconInfoHelper,
    IconExclamationHelper,
    Tooltip,
    ColorIndication,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    individualRegistration: {
      type: Object,
      default: () => {},
    },
    esusRegistration: {
      type: Object,
      default: () => {},
    },
    patientBasicAttentionLastDateChange: {
      type: String,
    },
  },
  data() {
    return {
      errorName: false,
      errorMother: false,
      errorGender: false,
      errorBirth: false,
      errorCpf: false,
      errorCns: false,
      errors: false,
    };
  },

  computed: {
    patientSituation() {
      return this.individualRegistration.pae_motivo_saida_cadastro_cidadao
        ? "COM SAÍDA"
        : "SEM SAÍDA";
    },
    patientSituationReason() {
      const patientSituation = this.individualRegistration
        .pae_motivo_saida_cadastro_cidadao;
      const hasDateDeath = this.individualRegistration.pae_data_obito;

      if (patientSituation && hasDateDeath) {
        return patientSituation + " - " + this.formatDate(hasDateDeath);
      }

      if (patientSituation && !hasDateDeath) {
        return patientSituation;
      }

      return null;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.compare();
      }
    },
  },

  methods: {
    compare() {
      if (
        this.esusRegistration.pes_nome !==
        this.individualRegistration.pae_nome_paciente
      ) {
        this.errors = true;
        this.errorName = true;
      }
      if (
        this.esusRegistration.pes_mae !==
        this.individualRegistration.pae_nome_mae
      ) {
        this.errors = true;
        this.errorMother = true;
      }
      if (
        this.individualRegistration.pae_sexo !==
        this.formatGender(this.esusRegistration.sex_sigla)
      ) {
        this.errors = true;
        this.errorGender = true;
      }
      if (
        this.esusRegistration.pes_nascimento !==
        this.formatDate(this.individualRegistration.pae_nascimento)
      ) {
        this.errors = true;
        this.errorBirth = true;
      }
      if (
        (this.esusRegistration.cpf || null) !==
        (this.individualRegistration.pae_cpf || null)
      ) {
        this.errors = true;
        this.errorCpf = true;
      }
      if (
        (this.esusRegistration.cns || null) !==
        (this.individualRegistration.pae_cartao_sus || null)
      ) {
        this.errors = true;
        this.errorCns = true;
      }
    },

    formatDate(pValue) {
      if (!pValue) return "";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },

    formatCpf(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },

    formatCns(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
    },

    formatGender(pValue) {
      if (!pValue) return "";
      return pValue === 1
        ? "MASCULINO"
        : pValue === 2
        ? "FEMININO"
        : "NÃO INFORMADO";
    },

    close() {
      this.clear();
      this.$emit("close");
    },

    clear() {
      this.errorName = false;
      this.errorMother = false;
      this.errorGender = false;
      this.errorBirth = false;
      this.errorCpf = false;
      this.errorCns = false;
      this.errors = false;
    },
  },
};
</script>
