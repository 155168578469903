<style src="./RgUnitHealthSelector.scss" lang="scss" scoped></style>

<template lang="html">
  <section class="rg-unit-health">
    <section class="rg-unit-health--search">
      <button class="rg-unit-health--btn-goback" @click="goBack">
        <IconArrowLeft class="rg-unit-health--btn-icon" />
        <span class="rg-unit-health--btn-caption">Voltar</span>
      </button>
      <RgUnitHealthSearch class="rg-unit-health--finder" @search="findBy" />
    </section>

    <div class="rg-unit-health--header">
      <h2 class="rg-unit-health--title">Minhas Unidades</h2>
    </div>

    <div class="rg-unit-health--unit-list">
      <div
        v-for="(item, idx) in searchUnit"
        :key="idx"
        :class="{
          'rg-unit-health--unit-item__current':
            item.value === selectedUnitHealth,
        }"
        class="rg-unit-health--unit-item"
        @click="selectUnitHealth(item)"
      >
        <div class="rg-unit-health--unit-item-icon">
          <IconHospital />
        </div>
        <span class="rg-unit-health--unit-text">{{ item.text }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import { IconHospital, IconArrowLeft } from "~tokio/primitive";
import RgUnitHealthSearch from "./unit-health-search/RgUnitHealthSearch";
import SetCurrentUnitHealth from "../../service/SetCurrentUnitHealth";

export default {
  name: "RgUnitHealthSelector",
  components: {
    IconHospital,
    IconArrowLeft,
    RgUnitHealthSearch,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    permitedUnitHealths() {
      return this.$store.getters["Login/GET_PERMITED_UNIT_HEALTH_LIST"];
    },
    selectedUnitHealth() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    searchUnit: function () {
      const tempHealthUnit = Object.assign(this.permitedUnitHealths);
      const tempSearch = this.search.toUpperCase();
      const filter = tempHealthUnit.filter((pData) => {
        const tempText = pData.text.toUpperCase();
        return tempText.indexOf(tempSearch) >= 0;
      });
      return filter;
    },
  },
  async mounted() {
    try {
      this.$loader.start();
      await this.$store.dispatch("Login/FILL_PERMITED_UNIT_HEALTH_LIST");
    } finally {
      this.$loader.finish(3000);
    }
  },
  methods: {
    findBy(pData) {
      this.search = pData;
    },
    goBack() {
      this.$router.go(-1);
    },
    async selectUnitHealth(pUnitHealth) {
      try {
        this.$loader.start("Carregando unidade...");
        await this.$store.dispatch(
          "Login/SET_CURRENT_UNIT_HEALTH",
          pUnitHealth.value,
        );
        this.$store.commit("Home/RESET_RECENT_OPERATION");
        this.$store.commit("Home/RESET_NEW_FAVORITE");
        this.$store.commit("Billing/CLEAN_MODULE_BILLING");
        await this.$store.dispatch("Login/LOAD_PERMISSIONS_BY_UNIT");
        await SetCurrentUnitHealth(pUnitHealth.value);
        this.$router.push("/");
      } catch (e) {
        throw new Error("Falha ao atualizar a Unidade de Saúde corrente ");
      } finally {
        this.$loader.finish();
      }
    },
  },
};
</script>
