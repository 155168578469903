export default {
  SET_NUMBER: (state, pNumber) => {
    state.periodNumber = pNumber;
  },
  SET_PERIOD_DATE: (state, pDate) => {
    state.periodDate = pDate;
  },
  SET_FORMATTED_PERIOD_DATE: (state, pDate) => {
    state.formattedPeriodDate = pDate;
  },
  SET_UNIFIED_TABLE_VERSION: (state, pNumber) => {
    state.unifiedTableVersion = pNumber;
  },
  CLEAN_MODULE_BILLING_BDSIA: (state) => {
    state.periodNumber = null;
    state.periodDate = null;
    state.formattedPeriodDate = null;
    state.unifiedTableVersion = null;
  },
};
