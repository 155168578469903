<style src="./SchedulingSurgeryForm.scss" lang="scss" scoped></style>
<template lang="html">
  <RgForm
    :show-submit="!hideButtonForm"
    :action-label="actionLabel"
    @submit="submit"
  >
    <section class="scheduling-surgery-form-content">
      <section class="patient--info">
        <SchedulingPatientInfo
          v-model="form.pac_id"
          :pac-id="form.pac_id"
          :surgery="true"
        />
      </section>
      <section>
        <div class="surgery-form">
          <div class="row">
            <RgComboboxRequestingClinician
              v-model="form.cci_id_internacoes_clinicas"
              :rules="{ required: true }"
              class="col-6"
            />
            <RgComboboxSurgeryRequestingPhysician
              v-model="form.cci_id_funcionarios"
              :rules="{ required: true }"
              :clinician="form.cci_id_internacoes_clinicas"
              class="col-6"
            />
          </div>
          <div class="row">
            <RgComboboxSurgeryReason
              v-model="form.cci_id_finalidades"
              label="Finalidade da Cirurgia"
              class="col-12"
            />
          </div>
          <div class="surgery-type-container">
            <div class="row">
              <RgComboboxSector
                v-model="form.cci_id_setores"
                :rules="{ required: true }"
                class="col-6 combobox-row"
                label="Centro Cirúrgico"
                @change="checkSurgeryConflict"
              />
              <RgComboboxPlace
                v-model="form.cci_id_locais_atendimento"
                :sector="form.cci_id_setores"
                :rules="{ required: true }"
                empty-default-text="Selecione um centro cirúrgico"
                class="col-6 combobox-row"
                label="Sala"
                @change="checkSurgeryConflict"
              />
            </div>

            <div class="row row-margin">
              <RgInputDate
                v-model="form.cci_data_inicio"
                :rules="{ required: true }"
                class="col-4"
                label="Data Prevista"
                placeholder="00/00/0000"
                @blur="checkSurgeryConflict"
              />
              <RgInputHour
                v-model="form.cci_hora_inicio"
                :rules="{ required: true }"
                class="col-4"
                label="Hora"
                placeholder="00:00"
                @blur="checkSurgeryConflict"
              />
              <RgInputHour
                v-model="form.cci_duracao"
                :rules="{ required: true }"
                class="col-4"
                label="Duração"
                @blur="checkSurgeryConflict"
              />
            </div>
            <RgAddConditionedModal
              :show="showConditioned"
              :surgeries-details="surgeryConflictDetail"
              @choose="chooseConditionedType"
            />
          </div>

          <section class="row">
            <RgComboboxSurgeryAnesthesia
              v-model="form.cci_id_tipos_anestesias"
              :rules="{ required: true }"
              class="col-6"
              label="Tipo de Anestesia"
            />
            <RgComboboxSurgeryXRay
              v-model="form.cci_id_raios_x"
              :rules="{ required: true }"
              class="col-6 combobox-row"
              label="Raio-X"
            />
          </section>

          <div class="row">
            <RgRadioCustom
              id="radio-cti"
              :value="form.cci_retorno_infeccao"
              :list="ITEMSRADIOFILTER"
              uniqueKey="id"
              class="rg-radio col-3 combobox-row"
              label="Retorno por Infecção"
              :canUnselect="false"
              @input="onInputRadioCci"
            />

            <RgComboboxPatientSurgeries
              v-model="form.surgery_relationship"
              :surgery="form.cci_id"
              :patient="form.pac_id"
              :disable="isInfecctionReturn"
              class="col-9"
            />
          </div>

          <div class="row">
            <RgRadioCustom
              id="radio-cti"
              :value="form.cci_cti"
              :list="ITEMSRADIOFILTER"
              uniqueKey="id"
              class="rg-radio col-2 combobox-row"
              label="CTI"
              :canUnselect="false"
              @input="onInputRadioCti"
            />

            <RgRadioCustom
              id="radio-cti"
              :value="form.cci_id_tipos_cirurgias"
              :list="ITEMSRADIOFILTERSURGERY"
              uniqueKey="id"
              class="rg-radio col-3 combobox-row"
              label="Cirurgia Condicionada"
              :canUnselect="false"
              @input="onInputRadioSurgery"
            />
            <RgRadioCustom
              id="radio-cti"
              :value="form.cci_reserva_sangue"
              :list="ITEMSRADIOFILTER"
              uniqueKey="id"
              class="rg-radio col-2 combobox-row"
              label="Reservar Sangue"
              :canUnselect="false"
              @input="onInputRadioBlood"
            />

            <!-- <fieldset class="rg-radio col-4"> -->
            <RgRadioCustom
              id="radio-blood"
              :value="form.cci_id_tipos_procedimentos"
              :list="ITEMSRADIOFILTERPROCEDURE"
              uniqueKey="id"
              class="rg-radio col-4 combobox-row"
              label="Tipo de Procedimento"
              :canUnselect="false"
              @input="onInputRadioProcedure"
            />
            <!-- <label class="description--radio">Tipo de Procedimento</label>
              <div class="form-requesting--radio">
                <label class="radio">
                  <input
                    v-model="form.cci_id_tipos_procedimentos"
                    type="radio"
                    name="procedure-type"
                    :value="1"
                    class="rg-radio"
                  />
                  <span>Hospitalar</span>
                </label>
                <label class="radio">
                  <input
                    v-model="form.cci_id_tipos_procedimentos"
                    type="radio"
                    name="procedure-type"
                    :value="2"
                    class="rg-radio"
                  />
                  <span>Ambulatorial</span>
                </label>
              </div> -->
            <!-- </fieldset> -->
          </div>
        </div>
      </section>

      <section class="additional-info">
        <RgTabs :itens="itens">
          <section slot="procedimentos" class="scheduling-procedure-list">
            <SchedulingProcedureList
              v-model="form.procedures"
              :surgery-id="form.cci_id"
              @valid="checkValidateProcedure"
            />
          </section>
          <section slot="equipe" class="scheduling-surgery-employees">
            <SchedulingSurgeryEmployee
              v-model="form.employees"
              :surgery-id="form.cci_id"
              :sector-id="form.cci_id_setores"
              @valid="checkValidateEmployees"
            />
          </section>
          <section slot="material" class="scheduling-surgery-material">
            <SurgeryMaterial
              v-model="form.materials"
              :surgery-id="form.cci_id"
              :sector-id="form.cci_id_setores"
            />
          </section>
          <section slot="kits" class="scheduling-surgery-kits">
            <SurgeryKits
              v-model="form.kits"
              :surgery-id="form.cci_id"
              :sector-id="form.cci_id_setores"
            />
          </section>
          <section slot="equipamentos" class="scheduling-surgery-equipment">
            <SurgeryEquipment
              v-model="form.equipments"
              :surgery-id="form.cci_id"
            />
          </section>
          <section slot="observacao" class="scheduling-surgery-observation">
            <SurgeryObservation v-model="form.cci_observacao" />
          </section>
        </RgTabs>
      </section>
    </section>

    <div slot="aftercommand" class="after-command--container">
      <span v-if="hideButtonForm">
        <RgConfirmationButton type="warning" @confirm="reScheduleSurgery"
          >Reagendar</RgConfirmationButton
        >
      </span>
      <RgConfirmationButton type="danger" @confirm="cleanSelection"
        >Limpar</RgConfirmationButton
      >
      <span v-if="hideButtonForm === false">
        <RgSuspend
          :selected="selectedItem"
          :granted="hasSuspendPermission"
          label="Suspender Agendamento"
          @suspend-action="confirmModalSuspend"
        />
        <RgSuspendModal
          :show="showAlertSuspend"
          :message="alertMessage"
          :surgery-id="form.cci_id"
          :sector-id="form.cci_id_setores"
          title="Atenção!"
          @confirmed="dismissModalSuspend"
          @denied="userDeniedSuspend"
        />
      </span>

      <span v-show="hideButtonForm === false">
        <RgCancel
          :selected="selectedItem"
          :granted="hasCancelPermission"
          label="Cancelar Agendamento"
          @cancel-action="confirmModal"
        />
        <RgCancelModal
          :show="showAlert"
          :message="alertMessage"
          :surgery-id="form.cci_id"
          :sector-id="form.cci_id_setores"
          title="Atenção!"
          @confirmed="dismissModal"
          @denied="userDenied"
        />
      </span>
    </div>
  </RgForm>
</template>

<script>
import {
  RgConfirmationButton,
  RgInputHour,
  RgInputDate,
  RgRadioCustom,
} from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import { RgTabs } from "~tokio/foundation/container";
import RgComboboxSurgeryAnesthesia from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-anesthesia/RgComboboxSurgeryAnesthesia";
import RgComboboxSurgeryXRay from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-x-ray/RgComboboxSurgeryXRay";
import RgComboboxSurgeryRequestingPhysician from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-requesting-physician/RgComboboxSurgeryRequestingPhysician";
import RgComboboxRequestingClinician from "$surgery-center/surgery/component/combobox/rg-combobox-requesting-clinician/RgComboboxRequestingClinician";
import SchedulingPatientInfo from "$surgery-center/surgery/component/scheduling-patient-info/SchedulingPatientInfo";
import {
  RgComboboxSector,
  RgComboboxPlace,
} from "$surgery-center/unit-health/component";
import RgCancel from "$surgery-center/foundation/component/rg-cancel/RgCancel";
import RgCancelModal from "$surgery-center/foundation/component/rg-cancel-modal/RgCancelModal";
import RgAddConditionedModal from "$surgery-center/foundation/component/rg-add-conditioned-modal/RgAddConditionedModal";
import SchedulingProcedureList from "$surgery-center/surgery/component/scheduling-procedure-list/SchedulingProcedureList";
import SchedulingSurgeryEmployee from "$surgery-center/surgery/component/scheduling-surgery-employee/SchedulingSurgeryEmployee";
import SurgeryFormMixin from "$surgery-center/surgery/component/mixin/SurgeryFormMixin";
import SurgeryObservation from "$surgery-center/surgery/component/surgery-observation/SurgeryObservation";
import SurgeryMaterial from "$surgery-center/surgery/component/surgery-material/SurgeryMaterial";
import SurgeryKits from "$surgery-center/surgery/component/surgery-kits/SurgeryKits";
import SurgeryEquipment from "$surgery-center/surgery/component/surgery-equipment/SurgeryEquipment";
import RgSuspend from "$surgery-center/foundation/component/rg-suspend/RgSuspend";
import RgSuspendModal from "$surgery-center/foundation/component/rg-suspend-modal/RgSuspendModal";
import RgComboboxPatientSurgeries from "$surgery-center/surgery/component/combobox/rg-combobox-patient-surgeries/RgComboboxPatientSurgeries";
import RgComboboxSurgeryReason from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-reason/RgComboboxSurgeryReason";

export default {
  name: "SchedulingRequestForm",
  components: {
    RgInputDate,
    RgComboboxSurgeryRequestingPhysician,
    RgComboboxSurgeryAnesthesia,
    RgComboboxSurgeryXRay,
    RgComboboxRequestingClinician,
    RgInputHour,
    RgForm,
    SchedulingPatientInfo,
    RgComboboxSector,
    RgCancel,
    RgCancelModal,
    RgAddConditionedModal,
    RgComboboxPlace,
    SchedulingProcedureList,
    SchedulingSurgeryEmployee,
    RgTabs,
    SurgeryObservation,
    SurgeryMaterial,
    SurgeryKits,
    SurgeryEquipment,
    RgConfirmationButton,
    RgSuspend,
    RgSuspendModal,
    RgComboboxPatientSurgeries,
    RgComboboxSurgeryReason,
    RgRadioCustom,
  },
  extends: SurgeryFormMixin,
  data() {
    return {
      validProcedures: true,
      validEmployees: true,
      itens: [
        { label: "Procedimentos", slot: "procedimentos", validated: true },
        { label: "Equipe", slot: "equipe", validated: true },
        { label: "Material", slot: "material" },
        { label: "Kits", slot: "kits" },
        { label: "Equipamentos", slot: "equipamentos" },
        { label: "Observação", slot: "observacao" },
      ],
    };
  },
  computed: {
    isInfecctionReturn() {
      return (
        this.form.cci_retorno_infeccao === 0 ||
        this.form.cci_retorno_infeccao === "0"
      );
    },
    actionLabel() {
      return this.isUpdating
        ? "Atualizar Agendamento"
        : "Cadastrar Agendamento";
    },
    surgeryState() {
      return 2;
    },
    hideButtonForm() {
      return (
        this.isSurgeryCanceled ||
        this.isSurgeryPerformed ||
        this.isSurgerySuspended
      );
    },
    validateMaterials() {
      return this.form.materials ? this.form.materials.length > 0 : false;
    },
    validateKits() {
      return this.form.kits ? this.form.kits.length > 0 : false;
    },
  },
  created() {
    this.ITEMSRADIOFILTER = [
      { title: "Sim", color: "#5ebaef", id: 1 },
      { title: "Não", color: "#5ebaef", id: 0 },
    ];
    this.ITEMSRADIOFILTERSURGERY = [
      { title: "Sim", color: "#5ebaef", id: 2 },
      { title: "Não", color: "#5ebaef", id: 1 },
    ];
    this.ITEMSRADIOFILTERPROCEDURE = [
      { title: "Hospitalar", color: "#5ebaef", id: 1 },
      { title: "Ambulatorial", color: "#5ebaef", id: 2 },
    ];
  },
  methods: {
    onInputRadioCti(item) {
      if (item) {
        this.form.cci_cti = item.id;
      }
    },
    onInputRadioSurgery(item) {
      if (item) {
        this.form.cci_id_tipos_cirurgias = item.id;
      }
    },
    onInputRadioCci(item) {
      const noOption = 0;
      if (item) {
        this.form.cci_retorno_infeccao = item.id;
      }
      if (item === noOption) {
        this.form.surgery_relationship = null;
      }
    },
    onInputRadioBlood(item) {
      if (item) {
        this.form.cci_reserva_sangue = item.id;
      }
    },
    onInputRadioProcedure(item) {
      if (item) {
        this.form.cci_id_tipos_procedimentos = item.id;
      }
    },
    reScheduleSurgery() {
      this.$store
        .dispatch(
          "SurgeryCenter/Surgery/RE_SCHEDULE_SURGERY",
          this.formatScheduleForm(this.form),
        )
        .then((pRes) => {
          this.$store.dispatch("SurgeryCenter/Surgery/filter");
          this.$store.dispatch("SurgeryCenter/Surgery/refreshCalendar");
          this.cleanSelection();
          this.toast.success(
            "O reagendamento da cirurgia foi efetuada com sucesso",
            "Atualização de agendamento...",
          );
        })
        .catch((pErr) => {
          this.toast.error("Erro ao Reagendar");
        });
    },
    checkValidateProcedure(errors) {
      if (errors) {
        this.itens[0].validated = false;
        return;
      }
      this.itens[0].validated = true;
    },
    checkValidateEmployees(errors) {
      if (errors) {
        this.itens[1].validated = false;
        return;
      }
      this.itens[1].validated = true;
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      if (this.isUpdating) {
        this.update(pDone);
      } else {
        this.create(pDone);
      }
    },
    create(pDone) {
      this.$store
        .dispatch(
          "SurgeryCenter/Surgery/SCHEDULE_SURGERY",
          this.formatScheduleForm(this.form),
        )
        .then((pRes) => {
          this.$store.dispatch("SurgeryCenter/Surgery/filter");
          this.$store.dispatch("SurgeryCenter/Surgery/refreshCalendar");
          pDone();
          this.cleanSelection();
          this.toast.success(
            "O cadastro do agendamento cirúrgico foi efetuado com sucesso",
            "Cadastro de agendamento...",
          );
        })
        .catch((pErr) => {
          pDone();
          this.toast.error("Erro ao Cadastrar Agendamento");
        });
    },
    update(pDone) {
      this.$store
        .dispatch(
          "SurgeryCenter/Surgery/UPDATE_SCHEDULE_SURGERY",
          this.formatScheduleForm(this.form),
        )
        .then((pRes) => {
          this.$store.dispatch("SurgeryCenter/Surgery/filter");
          this.$store.dispatch("SurgeryCenter/Surgery/refreshCalendar");
          pDone();
          this.cleanSelection();
          this.toast.success(
            "A atualização do agendamento cirúrgico foi efetuada com sucesso",
            "Atualização de agendamento...",
          );
        })
        .catch((pErr) => {
          pDone();
          this.toast.error("Erro ao Alterar Agendamento");
        });
    },
    formatScheduleForm(pForm) {
      const form = this.formatForm(pForm);
      form.team = this.form.employees.map((pItem) => {
        return {
          ceq_id_funcoes: pItem.ceq_id_funcoes,
          ceq_id_funcionarios: pItem.ceq_id_funcionarios,
          ceq_nome_funcionario: pItem.ceq_nome_funcionario,
          ceq_id_ocupacoes_cbo: pItem.ceq_id_ocupacoes_cbo,
          ceq_ocupacoes_cbo: pItem.ceq_ocupacoes_cbo,
        };
      });
      form.kits = this.form.kits;
      form.equipments = this.form.equipments;
      form.materials = this.form.materials;
      delete form.pac_id;
      return form;
    },
    // resetComboPatientSurgeries() {
    //   this.form.surgery_relationship = null;
    // },
  },
};
</script>
