<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchRgProfessionalSectorOccupationScaleActive from "./action/SearchRgProfessionalSectorOccupationScaleActive";

export default {
  name: "RgSelectProfessionalSectorOccupationScaleActive",
  extends: RgSelect,
  props: {
    sectorId: {
      required: true,
    },
    ocuppationId: {
      required: false,
    },
    label: {
      type: String,
      default: "Profissional",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    sectorId() {
      this.doSearch();
    },
    ocuppationId() {
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      this.valuesData = [];
      if (!this.sectorId || !this.ocuppationId) {
        return;
      }
      const data = await SearchRgProfessionalSectorOccupationScaleActive({
        intIdSetor: this.sectorId,
        intIdOcupacao: this.ocuppationId || 0,
        blnConcatOcupacao: false,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: item.label.split("-")[0],
          ocp_id: Number(item.ocp_id),
        };
      });
    },
  },
};
</script>
