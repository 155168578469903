import Vue from "vue";
import App from "./App";
import router from "./route";
import VueShortKey from "vue-shortkey";
import PermissionManager from "@/app/access-control/model/PermissionManager";
import store from "@/store";
import Toaster from "~tokio/primitive/notification/toaster/Toaster";
import Utils from "~utils/install";
import Directives from "~common/directives/install";
import Sentry from "~tools/Sentry";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToggleButton from "vue-js-toggle-button";
import VCalendar from "v-calendar";

import { DatePicker } from "element-ui";
import FontAwesomeIcons from "~tokio/primitive/icon/font-awesome/FontAwesomeIcons";

import lang from "element-ui/lib/locale/lang/pt-br";
import locale from "element-ui/lib/locale";

import "element-ui/lib/theme-chalk/index.css";
import PermissionLoaderPlugin, {
  createLoader,
} from "~tokio/primitive/loader/permission-loader";
import MedicalLoaderPlugin from "~tokio/primitive/loader/medical-loader";
import "./styles/main.scss";

locale.use(lang);

Vue.config.productionTip = false;
Vue.component("v-select", vSelect);
Vue.component("DatePicker", DatePicker);
Vue.use(PermissionManager);
Vue.use(VueShortKey, { prevent: ["textarea"] });
Vue.use(Toaster);
Vue.use(ToggleButton);
Vue.use(VCalendar);
Vue.use(PermissionLoaderPlugin, store);
Vue.use(MedicalLoaderPlugin, store);
Vue.use(Utils);
Vue.use(Directives);
FontAwesomeIcons(Vue);

Vue.config.devtools = process.env.NODE_ENV !== "production";

router.beforeEach(async (to, from, next) => {
  const loaderPermission = createLoader(store);
  try {
    const isLoggedIn = !!store.getters["Login/GET_TOKEN"];
    const isLoginRoute = to.name === "Login";
    const isAccessControlRoute = to.name === "accesscontrol.access";

    if (isLoggedIn && isLoginRoute) {
      return next({ path: "/home" });
    }
    if (isLoggedIn || isLoginRoute || isAccessControlRoute) {
      loaderPermission.start("Carregando Preferências");
      await store.dispatch("Login/LOAD_PREFERENCES_BY_MODULES", to);
      loaderPermission.message("Carregando Permissões");
      await store.dispatch("Login/LOAD_PERMISSIONS_BY_MODULES_AND_UNIT", to);
      loaderPermission.message("Carregando Competência");
      await store.dispatch("Billing/BDSia/LOAD_CURRENT_BILLING_COMPETENCE", to);
      loaderPermission.finish(500, "Carregando...");
      await next();
    } else {
      next({ path: "/login" });
    }
  } catch (pErr) {
    loaderPermission.finish(
      2000,
      "Erro ao carregar tela, você não foi redirecionado",
    );
  }
});

router.afterEach(() => {
  store.dispatch("Login/REFRESH_TOKENS");
});

// Sentry integration
Sentry(Vue);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
