import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($examId: Int!, $reason: String!) {
    RemoveEffectivedExam(examId: $examId, reason: $reason)
  }
`;

export default async ({ commit }, { examId, reason }) => {
  const variables = {
    examId,
    reason,
  };

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.RemoveEffectivedExam;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
