import FilterSatellite from "./FilterSatellite";
import CleanSatellite from "./CleanSatellite";
import SelectSatellite from "./SelectSatellite";
import FormSatellite from "./FormSatellite";

export default {
  ...FilterSatellite,
  ...CleanSatellite,
  ...SelectSatellite,
  ...FormSatellite,
};
