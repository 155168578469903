import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

export default {
  path: "/appointment/schedule/per-day/form/patient-care-history",
  name: "appointment.schedule.form.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Agendamento", link: OpenSubModule("appointment") },
      { label: "Agendamentos por Dia", link: "/appointment/schedule/per-day" },
      { label: "Agendar Consulta", link: "/appointment/schedule/per-day/form" },
      { label: "Historico de Atendimentos" },
    ],
  },
};
