<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import SearchBasicAttentionEmployee from "./action/SearchBasicAttentionEmployee";

export default {
  name: "RgSelectBasicAttentionEmployee",
  extends: RgSelect,
  props: {
    team: {
      default: null,
    },
    unitHealth: {
      default: null,
    },
    label: {
      type: String,
      default: "Funcionário",
    },
  },
  data() {
    return {
      employees: [],
    };
  },
  watch: {
    employees: function (val) {
      this.updateOptions();
    },

    team(pValue) {
      this.mValue = null;
      this.doSearch();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.employees;
    },

    async doSearch() {
      if (!this.team) {
        this.employees = [];
        return Promise.resolve([]);
      }

      this.data = [];
      const teamId = Number(this.team);
      const unsId = Number(this.unitHealth);
      const data = await SearchBasicAttentionEmployee({ teamId, unsId });

      this.employees = data.map((item) => {
        return {
          value: item.fun_id,
          label: `${item.pes_nome}`,
          occupation: item.ctf_codigo_cbo,
          cns: item.fun_crs_numero_ab,
          fab_id: item.fab_id,
        };
      });
    },

    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
