<style src="./RgComboboxMixin.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-combobox--component">
    <RgFormBase :label="label" :required="isRequired" :in-line="inLine">
      <div slot="right-label">
        <RgValidationAlert v-if="isRequired" :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--combobox-container">
          <select
            :id="id"
            v-model="selectedItem"
            :title="selectedItem ? titleSelected : defaultText"
            :data-id="dataId"
            :disabled="disableCombo || disableLoading || disable"
            :tabindex="disableCombo ? -1 : tabindex"
            class="rg-combobox--base-select"
            :class="{ 'on-error': hasError }"
            @change="optionSelected"
            @blur="blur"
          >
            <option v-if="showDefaultOption" :value="optionDefaultValue">
              {{ optionDefaultText }}
            </option>
            <option
              v-for="(entry, idx) in listEntry"
              :key="idx"
              :value="entry.value"
              :disabled="useDisabledOption ? entry.disabledOption : false"
              :class="{
                disable: useDisabledOption ? entry.disabledOption : false,
              }"
            >
              {{ entry.text }}
            </option>
          </select>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script type="text/javascript">
import RgBaseComboboxMixin from "./RgBaseComboboxMixin";
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";

export default {
  name: "RgComboboxMixin",
  components: {
    RgFormBase,
    RgValidationAlert,
  },
  mixins: [RgBaseComboboxMixin, RgValidatorMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum item para selecionar",
    },
    tabindex: {
      type: String,
      default: "0",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inLine: Boolean,
    useDisabledOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleSelected: "",
      disableLoading: false,
    };
  },
  computed: {
    validValue() {
      return this.selectedItem;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
    hasError() {
      return this.error.length > 0;
    },
  },
  methods: {
    optionSelected() {
      const myItem =
        this.selectedItem === this.defaultValue
          ? this.selectedItem
          : this.listEntry.filter((pItem) => pItem.value === this.selectedItem);

      if (myItem && myItem[0].text) {
        this.titleSelected = myItem[0].text;
      }

      this.$emit("input", myItem);
      this.$emit("change", myItem);
      this.validate();
      return false;
    },
    blur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
