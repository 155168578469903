<template lang="html">
  <section class="hospitalization-billing-material">
    <div>
      <form class="form">
        <RgValidatorForm ref="validator">
          <FormBase title="Materiais">
            <div class="hospitalization-billing-material-info">
              <RgInputCnpj
                v-model="material.ima_cnpj_fornecedor"
                :rules="{ required: true }"
                class="inputitem"
                label="CNPJ do Fornecedor"
              />
              <RgInputCnpj
                v-model="material.ima_cnpj_fabricante"
                class="inputitem"
                label="CNPJ do Fabricante"
              />
              <RgInputMasked
                v-model="material.ima_nota_fiscal"
                :rules="{ required: true }"
                class="inputitem"
                label="Nota Fiscal"
                placeholder="Digite a Nota Fiscal do Procedimento"
                mask="####################"
              />
            </div>
            <div class="hospitalization-billing-material-info">
              <RgInputMasked
                v-model="material.ima_lote"
                class="inputitem"
                label="Lote"
                placeholder="Digite o Lote do Procedimento"
                mask="####################"
              />
              <RgInputMasked
                v-model="material.ima_registro_anvisa"
                class="inputitem"
                label="Registro do Produto na ANVISA"
                placeholder="Digite o Registro do Procedimento"
                mask="####################"
              />
              <RgInputMasked
                v-model="material.ima_serie"
                class="inputitem"
                label="Número da Série"
                placeholder="Digite o Número Procedimento"
                mask="####################"
              />
            </div>
          </FormBase>

          <div class="buttons">
            <MediumButton
              title="Confirmar"
              label="Confirmar"
              backgroundColor="#0bb98c"
              @click="confirm"
            />

            <MediumButton
              title="Fechar"
              label="Fechar"
              @click.stop.prevent="close"
            />
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </section>
</template>

<script>
import {
  RgInputMasked,
  RgInputCnpj,
  RgValidatorForm,
  MediumButton,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
export default {
  name: "RgContentHospitalizationBillingMaterial",
  components: {
    RgInputMasked,
    RgInputCnpj,
    RgValidatorForm,
    MediumButton,
    FormBase,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      material: {
        type: "material",
        ima_cnpj_fornecedor: "",
        ima_cnpj_fabricante: "",
        ima_registro_anvisa: "",
        ima_nota_fiscal: "",
        ima_lote: "",
        ima_serie: "",
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.material = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.sanitizeFields();
        this.$emit("onFinish", this.material);
      }
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },

    sanitizeFields() {
      this.material.ima_cnpj_fornecedor = this.material.ima_cnpj_fornecedor
        ? this.material.ima_cnpj_fornecedor.replace(/[^\d]+/g, "")
        : null;
      this.material.ima_cnpj_fabricante = this.material.ima_cnpj_fabricante
        ? this.material.ima_cnpj_fabricante.replace(/[^\d]+/g, "")
        : null;
    },
  },
};
</script>
