<template>
  <div class="integration-services">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :item-height="34.5"
      :maxRegister="40"
      resultTitle="Serviços integrados com o PEP"
      @count="getCountValue"
    >
      <div slot="filters" class="integration-services-filter">
        <div class="grid">
          <div class="filter-subtitle filter-title">
            <span class="period-subtitle">Período</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.initialDate"
                :rules="{ fn: validateDate }"
                label="Data Inicial"
                class="inputitem"
              />

              <RgInputDate
                v-model="form.finalDate"
                label="Data Final"
                class="inputitem"
              />
            </div>
          </div>

          <div class="selectinput">
            <RgComboboxExamSyncType v-model="form.examSyncType" />
          </div>

          <div class="selectinput">
            <RgInput
              v-model="form.personName"
              label="Paciente"
              placeholder="Digite o nome do paciente"
              class=""
            />
          </div>

          <div class="selectinput">
            <RgRadioCustom
              id="from-queue-adr"
              :value="form.status"
              :list="ITEMSRADIOFILTER"
              uniqueKey="id"
              class="inputitem"
              label="Listar por Solicitação"
              @input="onInputRadio"
            />
          </div>
        </div>
      </div>

      <div class="integration-services-list">
        <div v-if="hasList" class="top-area">
          <div slot="legends" class="legends">
            <div class="circle -green" />
            <span class="legend">Sincronizado</span>
            <div class="circle -red" />
            <span class="legend">Não sincronizado</span>
          </div>

          <LargeButton
            label="Forçar Sincronização"
            backgroundColor="#1E88A9"
            class="syncronize-button"
            :class="{ 'disable unselect': !isLineWithNegativeStatus }"
            :disabled="!isLineWithNegativeStatus"
            @click="handleSincronization"
          >
            <div slot="icon" class="icon">
              <IconSynchronize />
            </div>
          </LargeButton>
        </div>

        <RgTable :columns="COLUMNS">
          <tr
            v-for="item in mutableList"
            :key="item.iep_id"
            slot="rows"
            :class="{ selected: selectedLine.iep_id === item.iep_id }"
            class="tr"
            @click="selectLine(item)"
          >
            <td class="td">
              <ColorIndication
                :value="actionColorLine(item)"
                size="13px"
                class="align-color"
              />
            </td>

            <td class="td">{{ item.pes_nome }}</td>

            <td class="td">{{ formatDate(item.iep_data_log) }}</td>

            <td class="td">{{ item.tipo }}</td>
            <td class="td" :title="item.iep_mensagem_erro || '-'">
              {{ item.iep_mensagem_erro || "-" }}
            </td>
          </tr>
        </RgTable>

        <div v-show="hasList" class="bottom-area">
          <MediumButton
            :class="{ 'disable unselect': !hasMessageErrorOnSelected }"
            :disabled="!hasMessageErrorOnSelected"
            title="Copiar mensagem de erro"
            label="Copiar mensagem de erro"
            class="button"
            @click="copyError"
          />
        </div>
      </div>
    </RgSearch>
  </div>
</template>

<script>
import moment from "moment";
import RgComboboxExamSyncType from "$configuration/common/component/rg-combobox-exam-sync-type/RgComboboxExamSyncType";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import { RgSearch, RgTable } from "~tokio/foundation";
import {
  ColorIndication,
  LargeButton,
  IconSynchronize,
  RgInputDate,
  RgInput,
  MediumButton,
  RgRadioCustom,
} from "~tokio/primitive";

const FORM = {
  initialDate: null,
  finalDate: null,
  examSyncType: null,
  personName: null,
  status: 0,
};

export default {
  name: "IntegrationServices",
  components: {
    RgSearch,
    RgTable,
    ColorIndication,
    LargeButton,
    IconSynchronize,
    RgInputDate,
    RgInput,
    RgComboboxExamSyncType,
    MediumButton,
    RgRadioCustom,
  },

  data() {
    return {
      mutableList: [],
      total: 0,
      form: this.$utils.obj.DeepCopy(FORM),
      selectedLine: {},
      onMouseEnter: false,
    };
  },

  computed: {
    hasList() {
      return this.mutableList?.length > 0;
    },

    hasItemSelected() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },

    isLineWithNegativeStatus() {
      return this.hasItemSelected && this.selectedLine.iep_status === 0;
    },

    hasMessageErrorOnSelected() {
      const hasLineSelected =
        this.selectedLine && Object.keys(this.selectedLine).length > 0;

      const hasMessageError = this.selectedLine.iep_mensagem_erro !== null;

      return hasLineSelected && hasMessageError;
    },
  },

  watch: {},

  created() {
    this.COLUMNS = [
      { name: "Status" },
      { name: "Paciente" },
      { name: "Data/Hora" },
      { name: "Tipo" },
      { name: "Mensagens de Erro" },
    ];
    this.ITEMSRADIOFILTER = [
      { title: "Sincronizado", color: "#03CE63", id: 1 },
      { title: "Não sincronizado", color: "#F96B70", id: 0 },
    ];
  },

  methods: {
    onInputRadio(pRadio) {
      if (pRadio) {
        this.form.status = pRadio.id;
      } else {
        this.form.status = null;
      }
    },
    actionColorLine(pItem) {
      const textColor = pItem.iep_status ? "green" : "red";
      const color = GetColorRowSmartTable(textColor);
      return color;
    },

    formatDate(pValue) {
      return pValue ? this.$utils.date.BrazilianDateTimeFormat(pValue) : "-";
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    selectLine(pValue) {
      this.selectedLine = pValue;
    },

    async research() {
      await this.$refs.RgSearch.performSearch();
    },

    searchFilter(pData) {
      return this.$store.dispatch(
        "Configuration/SEARCH_INTEGRATION_SERVICES",
        pData,
      );
    },

    generateFilter() {
      const variables = {
        initialDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.initialDate,
        ),
        finalDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.finalDate,
        ),
        type: this.form.examSyncType,
        pes_nome: this.form.personName,
        iep_status: this.form.status,
      };

      return variables;
    },

    async handleSincronization() {
      try {
        const iepId = this.selectedLine.iep_id;

        const {
          success,
          message,
        } = await this.$store.dispatch(
          "Configuration/RESEND_PEP_EXAM_INTEGRATION_TO_PEP",
          { iep_id: iepId },
        );

        success
          ? this.$toaster.success(message)
          : this.$toaster.error("Falha no reenvio");

        await this.research();
      } catch (pErr) {
        this.$toaster.error("Falha no reenvio");
      }
    },

    async copyError() {
      const error = this.selectedLine.iep_mensagem_erro;

      await this.$utils.navigator.CopyToClipboard(error);
    },

    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.selectedLine = {};
    },

    validateDate(pValue, pErrors) {
      const initialDate = moment(this.form.dateInitial, "DD/MM/YYYY");
      const finalDate = moment(this.form.dateFinal, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
  },
};
</script>
