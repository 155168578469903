import GET_PROCEDURE_PEP from "./GetProcedurePEP";
import ALTER_PROCEDURE_PEP from "./AlterProcedurePEP";
import REMOVE_PROCEDURE_PEP from "./RemoveProcedurePEP";
import RESEND_PROCEDURE_PEP from "./ResendProcedurePEP";

export default {
  GET_PROCEDURE_PEP,
  ALTER_PROCEDURE_PEP,
  REMOVE_PROCEDURE_PEP,
  RESEND_PROCEDURE_PEP,
};
