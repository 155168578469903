import { render, staticRenderFns } from "./RgSuggestProcedureEffectivation.vue?vue&type=template&id=e8af3716&scoped=true&"
import script from "./RgSuggestProcedureEffectivation.vue?vue&type=script&lang=js&"
export * from "./RgSuggestProcedureEffectivation.vue?vue&type=script&lang=js&"
import style0 from "./RgSuggestProcedureEffectivation.scss?vue&type=style&index=0&id=e8af3716&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8af3716",
  null
  
)

export default component.exports