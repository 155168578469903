import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleVoucherHTML = (pValue) => {
  const logo = LocalStorage.getObject("client-logo");
  let html = ``;
  pValue.forEach((element) => {
    html += `
    <!DOCTYPE html>
    <html>

    <head>
        <meta charset="utf-8">
        <title>Nova Impressão (Normal) de Agendamento</title>
        <style type="text/css">
            body {
                font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
            }

            page {
                background: white;
                display: block;
                margin: 0 auto;
                margin-bottom: 0.5cm;
            }

            page[size="A4"] {
                width: 21cm;
                height: 30.5cm;
            }

            h1 {
                text-align: center;
                text-transform: uppercase;
                font-size: 14pt;
            }

            h2 {
                margin: 33px 0 0 0;
                font-size: 12pt;
            }

            h3 {
                text-transform: uppercase;
                font-size: 11pt;
            }

            hr {
                width: 70%;
                display: block;
                height: 1px;
                border: 0;
                border-top: 1px solid #666;
            }

            p,
            label,
            span {
                font-size: 11pt;
            }

            .meia-folha {
                width: 47%;
                float: left;
                border: 0;
            }

            .meia-folha:first-child {
                padding: 0 45px 0 0;
            }

            .logo-cliente {
                width: 65px;
                margin: 0 20px 0 0;
                float: left;
            }

            .info-unidade {
                margin: 40px 0 22px 0;
            }

            .info-agendamento {
                margin: 20px 0 0 0;
            }

            .info-agendamento div {
                margin: 0 0 6px 0;
            }

            .info-agendamento label {
                width: 135px;
                display: inline-block;
                font-weight: 700;
            }

            .referencia {
                text-transform: uppercase;
                text-align: center;
            }

            @media print {

                body,
                page {
                    margin: 0;
                    box-shadow: 0;
                }

            }
        </style>
    </head>

    <body>

        <page size="A4">
            <div class="meia-folha">
                <div>
                    <h1>Via do paciente</h1>
                    <img class="logo-cliente" src="${logo}">
                    <h2>${element.cli_nome}</h2>
                </div>

                <div class="info-unidade">
                    <h3>${element.uns_nome}</h3>
                    <p>${element.tlg_nome} ${element.end_logradouro} ${element.end_numero} ${element.end_complemento} ${element.bai_nome} - ${element.mun_nome}</p>
                </div>

                <hr>

                <div class="info-agendamento">
                    <p class="referencia">${element.end_referencia}</p>

                    <div><label>Data:</label> <span>${element.eha_data_exame} às ${element.eap_hora}</span></div>
                    <div><label>Paciente:</label> <span>${element.pes_nome}</span></div>
                    <div><label>Nome Social:</label> <span>${element.pes_nome_social}</span></div>
                    <div><label>Prontuário:</label> <span>${element.ppr_numero}</span></div>
                    <div><label>Prontuário Único:</label> <span>${element.pac_prontuario_unico}</span></div>
                    <div><label>Cartão SUS:</label> <span>${element.crs_numero}</span></div>
                    <div><label>Setor:</label> <span>${element.set_nome}</span></div>
                    <div><label>Local:</label> <span>${element.lca_nome}</span></div>
                    <div><label>Profissional:</label> <span>${element.atendente}</span></div>
                    <div><label>Ocupação:</label> <span>${element.ocp_nome}</span></div>
                    <div><label style="width: 200px;">${element.agendadoPelaFila}</label></div>
                    <div><label>${element.lblNomeRegulador}</label> <span>${element.nomeRegulador}</span></div>

                    <div><label>Observações:</label> <span>${element.observacoes}</span></div>
                </div>
            </div>


            <div class="meia-folha">
                <div>
                    <h1>Via do ambulatório</h1>
                    <img class="logo-cliente" src="${logo}">
                    <h2>${element.cli_nome}</h2>
                </div>

                <div class="info-unidade">
                    <h3>${element.uns_nome}</h3>
                    <p>${element.tlg_nome} ${element.end_logradouro} ${element.end_numero} ${element.bai_nome} - ${element.mun_nome}</p>
                </div>

                <hr>

                <div class="info-agendamento">
                    <p class="referencia">${element.end_referencia}</p>

                    <div><label>Data:</label> <span>${element.eha_data_exame} às ${element.eap_hora}</span></div>
                    <div><label>Paciente:</label> <span>${element.pes_nome}</span></div>
                    <div><label>Nome Social:</label> <span>${element.pes_nome_social}</span></div>
                    <div><label>Prontuário:</label> <span>${element.ppr_numero}</span></div>
                    <div><label>Prontuário Único:</label> <span>${element.pac_prontuario_unico}</span></div>
                    <div><label>Cartão SUS:</label> <span>${element.crs_numero}</span></div>
                    <div><label>Setor:</label> <span>${element.set_nome}</span></div>
                    <div><label>Local:</label> <span>${element.lca_nome}</span></div>
                    <div><label>Profissional:</label> <span>${element.atendente}</span></div>
                    <div><label>Ocupação:</label> <span>${element.ocp_nome}</span></div>
                    <div><label style="width: 200px;">${element.agendadoPelaFila}</label></div>
                    <div><label>${element.lblNomeRegulador}</label> <span>${element.nomeRegulador}</span></div>

                    <div><label>Observações:</label> <span>${element.observacoes}</span></div>
                </div>
            </div>
        </page>

    </body>

    </html>
      `;
  });

  return html;
};

export default ScheduleVoucherHTML;
