<style src="./RgLogin.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="rg-login">
    <div class="rg-login--container">
      <div class="rg-login--box">
        <div class="rg-login--inner">
          <div class="rg-login--header-brand">
            <img class="" src="/static/icons/logo.svg" alt="RG Esus" />
          </div>

          <form class="login" @submit.prevent="login">
            <RgValidatorForm ref="validator">
              <RgInput
                id="cliente"
                v-model="loginForm.client"
                :rules="{ required: true }"
                label="Cliente"
                class="login--fields"
                tab-index="2"
                :isUpperCase="false"
              />
              <RgInput
                id="usuario"
                v-model="loginForm.login"
                :rules="{ required: true }"
                label="Usuário"
                class="login--fields"
                tab-index="2"
                :isUpperCase="false"
              />
              <RgInputPassword
                id="senha"
                v-model="loginForm.password"
                :rules="{ required: true }"
                :eye-style="{ 'margin-right': '4px' }"
                label="Senha"
                class="login--fields"
                tab-index="3"
                :isUpperCase="false"
              />

              <div v-if="isDeveloping" class="dev-config">
                <RgToggleButton
                  marginAutoLeft
                  :value="useLegacyBackend"
                  description="Utilizar Servidor Legado :"
                  @change="changeUseLegacyBackend"
                />
              </div>

              <div class="status">
                <div class="status-servidores">
                  Status do Servidor :
                  <span
                    title="Servidor do Esus"
                    class="span left"
                    @click="checkStatus('backendStatus')"
                  >
                    <Component :is="backendStatus" class="icon-server" />
                  </span>
                  <span
                    title="Servidor do Esus Legado"
                    class="span"
                    @click="checkStatus('backendLegacyStatus')"
                  >
                    <Component :is="backendLegacyStatus" class="icon-server" />
                  </span>
                </div>
              </div>

              <div class="rg-input--command">
                <RgCleanButton
                  medium
                  type="button"
                  class="clean unselect"
                  title="Limpar"
                  @click="clearForm"
                />

                <RgSubmitButton
                  id="acessar"
                  ref="btnSubmit"
                  label="Acessar"
                  class="unselect"
                  wait-label="Efetuando login"
                  @submit="login"
                />
              </div>
            </RgValidatorForm>
          </form>
          <div class="footer-logo">
            <!-- <img
              src="/static/images/logo-esus+-sistemas.png"
              alt="RG Sistema"
            > -->
          </div>

          <div class="footer">Versão do sistema: {{ version }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  RgInput,
  IconAnimCheck,
  IconAnimExclamation,
  RgToggleButton,
  RgInputPassword,
  RgValidatorForm,
  RgSubmitButton,
  RgCleanButton,
} from "~tokio/primitive/";

import Request from "~common/request/";
import { LEGACY_SERVER_CONFIG, SERVER_CONFIG } from "~utils/Config";

export default {
  name: "RgLogin",
  components: {
    RgSubmitButton,
    RgInput,
    RgInputPassword,
    RgValidatorForm,
    RgCleanButton,
    RgToggleButton,
    IconAnimCheck,
    IconAnimExclamation,
  },
  data() {
    return {
      backendStatus: "IconAnimExclamation",
      backendLegacyStatus: "IconAnimExclamation",
      loginForm: {
        client: "",
        login: "",
        password: "",
      },
    };
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    lastLogin() {
      return this.$store.getters["Login/GET_LAST_LOGIN_INFO"];
    },
    isDeveloping() {
      return process.env.NODE_ENV === "development";
    },
    useLegacyBackend() {
      return this.$store.state.Login.useLegacyBackend;
    },
  },
  mounted() {
    this.loginForm.client = this.lastLogin.clientCode;
    this.loginForm.login = this.lastLogin.userLogin;
    this.checkStatus("backendStatus");
    this.checkStatus("backendLegacyStatus");
  },
  methods: {
    login() {
      if (this.validateLogin()) {
        const loginCredentials = {
          client: this.loginForm.client,
          username: this.loginForm.login,
          password: this.loginForm.password,
        };
        this.$store
          .dispatch("Login/LOGIN", loginCredentials)
          .then(() => {
            this.goToUrl("/home");
          })
          .catch((pErr) => {
            this.$refs.btnSubmit.fail();
            this.$toaster.error(
              "Verifique se o nome e a senha estão corretos",
              "Não foi possível efetuar o login",
            );
          });
      }
    },
    clearForm() {
      this.loginForm.client = "";
      this.loginForm.login = "";
      this.loginForm.password = "";
    },
    checkStatus(pType) {
      let config = null;
      if (pType === "backendLegacyStatus") {
        config = LEGACY_SERVER_CONFIG;
      } else if (pType === "backendStatus") {
        config = SERVER_CONFIG;
      }
      if (!config) {
        this[pType] = "IconAnimExclamation";
      }
      const request = new Request(config);
      request.get("/").then((result) => {
        if (result.status === 200) {
          this[pType] = "IconAnimCheck";
        } else {
          this[pType] = "IconAnimExclamation";
        }
      });
    },
    changeUseLegacyBackend(v) {
      this.$store.commit("Login/SET_USE_LEGACY_BACKEND", v);
    },
    validateLogin() {
      return this.$refs.validator.validate();
    },
    goToUrl(pParams) {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
