<template>
  <section class="exam-schedule-per-period-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProceduresLinked
          id="procedures"
          ref="comboProcedures"
          v-model="form.procedureId"
          class="inputitem"
          label="Procedimento"
          default-text="Todos"
          :submodule-id="submoduleId"
          @change="procedureLinkedSelected"
        />
      </div>

      <div class="selectinput">
        <RgSelectUnithealthPerProceduresExams
          id="unit-health"
          ref="unitHealth"
          v-model="form.unit"
          :rules="{ required: true }"
          :submodule-id="submoduleId"
          :procedureName="form.procedureName"
          class="inputitem"
          label="Unidade de Saúde"
          @change="clearDependencies"
        />
      </div>

      <div class="selectinput">
        <RgSelectSectorLegacy
          id="sector"
          ref="sector"
          v-model="form.sector"
          :class="{ 'disable unselect': !hideSector }"
          :disabled="!hideSector"
          :rules="{ required: true }"
          :unit-health-id="Number(form.unit)"
          class="inputitem"
          label="Setor"
          :empty-default-text="'Selecione uma Unidade'"
          :submodule-id="submoduleId"
          @change="clearDependenciesSector"
        />
      </div>

      <div class="selectinput">
        <RgSelectScaleType
          id="scale-type"
          v-model="form.selectedScale"
          :disabled="!hideSector"
          :class="{ disable: !hideSector }"
          :unit-health-id="form.unit"
          class="inputitem"
          label="Tipos de Escala"
          :empty-default-text="'Selecione uma Unidade'"
          default-text="Todas"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCboSector
          id="cbo"
          ref="Cbo"
          v-model="form.cbo"
          :disabled="!this.form.sector"
          :class="{ disable: !this.form.sector }"
          :sectorId="Number(form.sector)"
          label="Ocupação"
          class="inputitem"
          activeProfessional
          @selected="selectedCbo"
        />
      </div>
      <div class="selectinput">
        <RgSelectProfessionalSectorOccupationScaleActive
          id="employee"
          ref="employee"
          v-model="form.employee"
          :sectorId="Number(form.sector)"
          :ocuppation-id="Number(form.ocp_id)"
          class="inputitem"
          :disabled="!form.sector"
          :class="{ disable: !form.sector }"
          @selected="selectedEmployee"
        />
      </div>

      <div class="periods">
        <div class="lack-period">
          {{ this.form.initialLackPeriodText }}

          <RgInput
            id="initial-period"
            v-model="form.initialPeriod"
            :disabled="true"
            label=""
            class="lack-value"
            type="number"
          />

          {{ this.form.finalLackPeriodText }}
        </div>

        <div class="marking-period">
          {{ this.form.initialMarkingPeriodText }}

          <RgInput
            id="final-period"
            v-model="form.finalPeriod"
            :disabled="true"
            label=""
            class="marking-value"
            type="number"
          />

          {{ this.form.finalMarkingPeriodText }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";

import {
  RgInput,
  RgSelectSectorLegacy,
  RgSuggestCboSector,
} from "~tokio/primitive";

import {
  RgSelectProceduresLinked,
  RgSelectUnithealthPerProceduresExams,
  RgSelectScaleType,
  RgSelectProfessionalSectorOccupationScaleActive,
} from "$exam/submodules/schedule/component/";

export default {
  name: "ExamScheduleFilterPerPeriod",
  components: {
    RgSelectUnithealthPerProceduresExams,
    RgSelectSectorLegacy,
    RgSelectScaleType,
    RgSelectProceduresLinked,
    RgInput,
    RgSuggestCboSector,
    RgSelectProfessionalSectorOccupationScaleActive,
  },

  data() {
    return {
      form: {
        procedureName: "",
        procedureId: null,
        unit: 0,
        sector: null,
        ocp_id: null,
        cbo: null,
        employee: null,
        selectedScale: null,
        eca_quantidade: null,
        epm_quantidade: null,
        epm_id_exames_intervalo_periodo: null,
        eca_id_exames_intervalo_periodo: null,
        exe_codigo_procedimento: 0,
        initialPeriod: 0,
        finalPeriod: 30,
        initialLackPeriodText: "",
        finalLackPeriodText: "",
        initialMarkingPeriodText: "",
        finalMarkingPeriodText: "",
      },
      sectorInVuex: null,
    };
  },

  computed: {
    hideSector() {
      return this.form.unit > 0;
    },

    variableComboProcedures() {
      return {
        intIdVinculoSetor: this.form.sector,
        intIdPlanoSaude: this.form.selectedHealthPlan,
      };
    },

    submoduleId() {
      return 99;
    },
  },

  watch: {
    async "form.sector"(pValue) {
      const sectorId = Number(pValue);
      const isValidSector = Number.isInteger(sectorId);
      const invalidValue = pValue === false;

      if (isValidSector) {
        const isDifferentSector = this.sectorInVuex !== pValue;

        if (isDifferentSector) {
          this.sectorInVuex = null;
          const periodsAndDaysInfo = await this.$store.dispatch(
            "Exam/Schedule/GET_DAYS_AND_PERIODS_INFO_FROM_SECTOR",
            {
              intIdUnidadeSaude: this.form.unit,
              intIdSetor: sectorId,
            },
          );

          this.mountFilter(periodsAndDaysInfo);
        }
      } else if (invalidValue) {
        this.defaultPeriodFilter();
      }
      const withoutProcedureAndUnit =
        !this.form.procedureId > 0 && !this.form.unit > 0;

      if (withoutProcedureAndUnit) {
        this.form.sector = null;
      }
    },
  },

  mounted() {
    this.defaultPeriodFilter();
  },

  created() {
    this.TIPO_PERIODO_HORAS = 1;
    this.TIPO_PERIODO_DIAS = 2;
    this.TIPO_PERIODO_SEMANAS = 3;
  },

  methods: {
    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.ocp_id = pValue.source.ocp_id;
      } else {
        this.form.ocp_id = 0;
        this.form.employee = null;
      }
    },
    selectedEmployee(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.employeeId = pValue.source.pes_id;
      } else {
        this.form.employeeId = 0;
      }
    },

    clearDependenciesSector(pValue) {
      if (!pValue) {
        this.form.selectedScale = null;
        this.defaultPeriodFilter();
      }
    },

    clearDependencies(pValue) {
      if (!pValue) {
        this.form.sector = null;
        this.form.selectedScale = null;
      }
    },

    setMarkingPeriodText() {
      const PERIODO_MARCACAO_DIAS_INICIO = "hoje + período de";
      const PERIODO_MARCACAO_DIAS_FIM = "dias.";
      const PERIODO_MARCACAO_SEMANAS_INICIO = "hoje + período de";
      const PERIODO_MARCACAO_SEMANAS_FIM = "semana(s).";

      const intervalPeriod = Number(this.form.epm_id_exames_intervalo_periodo);

      switch (intervalPeriod) {
        case this.TIPO_PERIODO_DIAS:
          this.form.initialMarkingPeriodText = PERIODO_MARCACAO_DIAS_INICIO;
          this.form.finalMarkingPeriodText = PERIODO_MARCACAO_DIAS_FIM;
          break;

        case this.TIPO_PERIODO_SEMANAS:
          this.form.initialMarkingPeriodText = PERIODO_MARCACAO_SEMANAS_INICIO;
          this.form.finalMarkingPeriodText = PERIODO_MARCACAO_SEMANAS_FIM;
          break;
      }
    },

    setLackPeriodText() {
      const CARENCIA_HORAS_INICIO = "Hora atual +";
      const CARENCIA_HORAS_FIM = "hora(s) até";
      const CARENCIA_DIAS_INICIO = "Hoje +";
      const CARENCIA_DIAS_FIM = "dia(s) até +";
      const CARENCIA_SEMANAS_INICIO = "Hoje +";
      const CARENCIA_SEMANAS_FIM = "semana(s) até +";

      const intervalPeriod = Number(this.form.eca_id_exames_intervalo_periodo);

      switch (intervalPeriod) {
        case this.TIPO_PERIODO_HORAS:
          this.form.initialLackPeriodText = CARENCIA_HORAS_INICIO;
          this.form.finalLackPeriodText = CARENCIA_HORAS_FIM;
          break;

        case this.TIPO_PERIODO_DIAS:
          this.form.initialLackPeriodText = CARENCIA_DIAS_INICIO;
          this.form.finalLackPeriodText = CARENCIA_DIAS_FIM;
          break;

        case this.TIPO_PERIODO_SEMANAS:
          this.form.initialLackPeriodText = CARENCIA_SEMANAS_INICIO;
          this.form.finalLackPeriodText = CARENCIA_SEMANAS_FIM;
          break;
      }
    },

    cleanFilter() {
      this.form = {
        procedureName: "",
        procedureId: null,
        unit: 0,
        sector: null,
        ocp_id: null,
        cbo: null,
        employee: null,
        employeeId: null,
        selectedScale: null,
        eca_quantidade: null,
        epm_quantidade: null,
        epm_id_exames_intervalo_periodo: null,
        eca_id_exames_intervalo_periodo: null,
        exe_codigo_procedimento: 0,
        initialLackPeriodText: "Hora atual +",
        finalLackPeriodText: "hora(s) até",
        initialMarkingPeriodText: "hoje + período de",
        finalMarkingPeriodText: "dias.",
        initialPeriod: 0,
        finalPeriod: 30,
      };
      this.$refs.unitHealth.cleanValidate();
      this.$refs.sector.cleanValidate();

      this.sectorInVuex = null;
    },

    mountFilter(pValue) {
      const isValidValue = pValue && Object.keys(pValue).length > 0;

      if (isValidValue) {
        this.form.epm_quantidade = pValue.epm_quantidade;
        this.form.eca_quantidade = pValue.eca_quantidade;

        this.form.epm_id_exames_intervalo_periodo =
          pValue.epm_id_exames_intervalo_periodo;

        this.form.eca_id_exames_intervalo_periodo =
          pValue.eca_id_exames_intervalo_periodo;

        this.form.initialPeriod = Number(pValue.eca_quantidade);
        this.form.finalPeriod = Number(pValue.epm_quantidade);

        this.setLackPeriodText();
        this.setMarkingPeriodText();
      } else {
        this.defaultPeriodFilter();
      }
    },

    defaultPeriodFilter() {
      this.form.initialLackPeriodText = "Hora atual +";
      this.form.finalLackPeriodText = "hora(s) até";
      this.form.initialMarkingPeriodText = "hoje + período de";
      this.form.finalMarkingPeriodText = "dias.";
      this.form.initialPeriod = 0;
      this.form.finalPeriod = 30;
      this.form.epm_quantidade = 30;
      this.form.epm_id_exames_intervalo_periodo = 2;
      this.form.eca_id_exames_intervalo_periodo = 1;
      this.form.eca_quantidade = 0;
    },

    getForm() {
      return this.form;
    },

    getVariables() {
      return {
        arrFormData: {
          data_inicial: moment().format("DD/MM/YYYY"),
          set_id: this.form.sector,
          stp_id: this.form.procedureId,
          uns_id: this.form.unit,
          ees_id: this.form.selectedScale,
          epm_quantidade: this.form.epm_quantidade,
          eca_quantidade: this.form.eca_quantidade,
          epm_id_exames_intervalo_periodo: this.form
            .epm_id_exames_intervalo_periodo,
          eca_id_exames_intervalo_periodo: this.form
            .eca_id_exames_intervalo_periodo,
          limiteFim: 1000000,
          limiteInicio: 0,
          intIdOcupacao: this.form.ocp_id,
          intIdPessoa: this.form.employeeId,
          stp_novo_nome_procedimento: this.form.procedureName,
        },
      };
    },

    procedureLinkedSelected(pProcedure) {
      if (pProcedure) {
        this.form.procedureName = pProcedure.label;
      } else {
        this.form.procedureName = "";
        this.form.unit = 0;
      }
    },

    async fillFilter() {
      try {
        const filter = await this.$store.getters[
          "Exam/Schedule/GET_FILTER_EXAM_PER_PERIOD"
        ];

        const data = this.$utils.obj.DeepCopy(filter);

        if (data) {
          this.form.procedureId = data.procedureId;
          this.form.unit = data.unit;
          this.form.sector = data.sector;
          this.form.epm_quantidade = data.epm_quantidade;
          this.form.exe_codigo_procedimento = data.exe_codigo_procedimento;
          this.form.procedureName = data.procedureName;

          this.form.employee = data.employee;
          this.form.selectedScale = data.selectedScale;
          this.form.eca_quantidade = data.eca_quantidade;
          this.form.epm_quantidade = data.epm_quantidade;
          this.form.epm_id_exames_intervalo_periodo =
            data.epm_id_exames_intervalo_periodo;
          this.form.eca_id_exames_intervalo_periodo =
            data.eca_id_exames_intervalo_periodo;
          this.form.exe_codigo_procedimento = data.exe_codigo_procedimento;
          this.form.initialPeriod = data.initialPeriod;
          this.form.finalPeriod = data.finalPeriod;
          this.form.initialLackPeriodText = data.initialLackPeriodText;
          this.form.finalLackPeriodText = data.finalLackPeriodText;
          this.form.initialMarkingPeriodText = data.initialMarkingPeriodText;
          this.form.finalMarkingPeriodText = data.finalMarkingPeriodText;

          this.sectorInVuex = data.sector;

          this.form.initialPeriod = data.initialPeriod;
          this.form.finalPeriod = data.finalPeriod;

          if (this.$refs.Cbo) {
            const cboCodeAndName = data.cbo.split(" - ");
            this.$refs.Cbo.forceSelection({
              ocp_codigo: cboCodeAndName[0],
              ocp_nome: cboCodeAndName[1],
              ocp_id: data.ocp_id,
            });
          }
          if (this.$refs.employee) {
            this.$refs.employee.forceSelection({
              pes_id: data.employeeId,
              pes_nome: data.employee,
            });
          }
          return true;
        }
      } catch (Err) {
        console.error(Err);
        this.$toaster.warning("Erro ao carregar os dados da navegação");
        return false;
      }
    },
  },
};
</script>
