<template lang="html">
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="13.5" fill="#03CE63" stroke="white" />
    <path
      d="M18.7443 8.36364V20H16.6193L11.5568 12.6761H11.4716V20H9.01136V8.36364H11.1705L16.1932 15.6818H16.2955V8.36364H18.7443Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "NewHelper",
};
</script>
