var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"rg-button",class:{
    disable: _vm.permission ? _vm.disabled : true,
    disablebtn: _vm.permission ? _vm.disabled : true,
    nopermission: !_vm.permission,
  },style:({
    background: _vm.backgroundColor,
  }),attrs:{"id":_vm.id,"data-id":_vm.dataId,"disabled":_vm.permission ? _vm.disabled : true,"title":_vm.permission ? _vm.title : 'Usuário sem permissão'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }