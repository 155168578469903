import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacySatelliteReversalReport(
    $unitHealthId: Int
    $unitHealthName: String
    $pharmacyOriginId: Int
    $pharmacyOriginName: String
    $pharmacyDestinyId: Int
    $pharmacyDestinyName: String
    $productId: Int
    $productName: String
    $initialDate: String
    $finalDate: String
    $productTypeId: Int
    $productTypeName: String
    $remittanceGuide: String
    $userId: Int
    $userName: String
    $loggedUserId: Int!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacySatelliteReversalReport(
      unitHealthId: $unitHealthId
      unitHealthName: $unitHealthName
      pharmacyOriginId: $pharmacyOriginId
      pharmacyOriginName: $pharmacyOriginName
      pharmacyDestinyId: $pharmacyDestinyId
      pharmacyDestinyName: $pharmacyDestinyName
      productId: $productId
      productName: $productName
      initialDate: $initialDate
      finalDate: $finalDate
      productTypeId: $productTypeId
      productTypeName: $productTypeName
      remittanceGuide: $remittanceGuide
      userId: $userId
      userName: $userName
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        NOME
        NOME_COMERCIAL
        UNIDADE_SAUDE
        TIPO
        APRESENTACAO
        LOTE_VALIDADE
        QUANTIDADE
        ORIGEM
        DESTINO
        TIPO_MOVIMENTACAO
        DATA
        USUARIO
        GUIA_REMESSA
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacySatelliteReversalReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
