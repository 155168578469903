import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPatientsExternallyScheduledReport(
    $patient: Int
    $patientName: String
    $unitHealth: Int
    $unitHealthName: String
    $occupation: Int
    $occupationName: String
    $destiny: Int
    $destinyName: String
    $schedulePeriodStart: String
    $schedulePeriodEnd: String
    $columnsToPrint: [ColumnsToPrintInputType]
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchPatientsExternallyScheduledReport(
      patient: $patient
      patientName: $patientName
      unitHealth: $unitHealth
      unitHealthName: $unitHealthName
      occupation: $occupation
      occupationName: $occupationName
      destiny: $destiny
      destinyName: $destinyName
      schedulePeriodStart: $schedulePeriodStart
      schedulePeriodEnd: $schedulePeriodEnd
      columnsToPrint: $columnsToPrint
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        TELEFONE
        DATA_AGENDAMENTO
        OCUPACAO
        DESTINO
        UNIDADE_SOLICITANTE
        USUARIO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPatientsExternallyScheduledReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
