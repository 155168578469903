<style src="./RgSearch.scss" lang="scss" scoped></style>
<template>
  <div class="report-container">
    <ModuleBox
      ref="filterbox"
      :granted="hasPermission"
      :disabled="disabled"
      :title="filterTitle"
      :class="{ 'module-box--filter wrap': isRetractable }"
      class="filter-box"
      collapse
    >
      <div class="filter-area">
        <RgValidatorForm ref="validatorModule" class="rg-validator">
          <slot name="extra" />
          <!-- SLOT: Filters -->
        </RgValidatorForm>
        <!-- SLOT: Before Filters -->

        <form
          class="form-container small-scroll"
          :class="{
            'all-report-size': isCentralizerRoute,
            'specific-report': isReport && !isCentralizerRoute,
          }"
          @submit.prevent="submitForm"
        >
          <RgValidatorForm ref="validator" class="rg-validator">
            <slot name="filters" />
            <!-- SLOT: Filters -->
          </RgValidatorForm>
        </form>
      </div>

      <!-- FILTER ACTIONS -->
      <div class="filter-actions">
        <RgCleanButton
          id="limpa-rgsearch"
          small
          data-id="limpar"
          class="clean"
          title="Limpar"
          :disabled="disabled || disableButtonWhenNoFilterSelected"
          @click="clearForm"
        />
        <RgSearchButton
          id="busca-rgsearch"
          ref="btnSubmit"
          v-shortkey="{
            enter: hasSearchFunction && !disabled ? ['enter'] : [''],
          }"
          :disabled="!hasSearchFunction || disabled"
          small
          data-id="buscar"
          class="search"
          label="Buscar"
          title="Buscar"
          @shortkey.native="submitForm"
          @submit="submitForm"
        />
      </div>
    </ModuleBox>

    <ModuleBox
      ref="contentList"
      :granted="hasPermission"
      :disabled="disabled"
      :title="resultTitle"
      :showTitle="showTitle"
      :class="{
        'oclusion margin-50': !withoutOclusion && isRetractable,
        'margin-left': isRetractable,
      }"
      class="module-box--report"
    >
      <slot name="buttons-top" />

      <div slot="title" class="title">
        <div
          v-if="isHelper"
          class="helper title-space"
          @click="showModalHelper"
        >
          <IconInfoHelper title="Ajuda" />
        </div>

        <slot name="menu-top" />

        <RgAddButton
          v-if="canShowNewButton"
          id="novo"
          large
          :label="label"
          :title="title"
          data-id="novo"
          class="new title-space"
          @click="goToNew"
        />
      </div>

      <slot name="search-top" />

      <div
        v-show="!countItem && showEmptyMessage"
        ref="listContent"
        class="no-result"
      >
        <IconEmpty />
        <span class="info">
          Não foram encontrados resultados para essa busca
        </span>
      </div>

      <div
        v-if="countItem > 0 || !showEmptyMessage"
        ref="listContent"
        class="list-content"
      >
        <!-- SLOT: Content -->
        <slot />
      </div>

      <div slot="buttons-bottom" class="buttons-bottom">
        <slot name="menu-bottom" />
      </div>

      <!-- SLOT: Footer -->
      <div v-if="showFooter" slot="footer" class="list-footer">
        <!-- SLOT: Footer -->
        <RgPagination
          ref="paginator"
          is-search-component
          :disabled="disabled"
          :data-length="countItem"
          :max-register="maxRegister"
          @pagination="setPagination"
        />
      </div>
    </ModuleBox>

    <!-- MODAL HELPER -->
    <RgBaseModal
      v-if="isHelper"
      ref="Modal"
      v-shortkey="['esc']"
      :show="showHelper"
      class="rg-alert-base-modal"
    >
      <div slot="header" class="history-header">
        <span class="title">
          <slot name="title-header" />
        </span>

        <div
          slot="header"
          data-id="fechar-modal-etiqueta"
          class="button-close unselect"
          @click.stop.prevent="closeHelper"
        >
          X
        </div>
      </div>

      <div slot="body">
        <slot name="body-helper" />
      </div>

      <div slot="footer">
        <slot name="footer-helper" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import moment from "moment";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import { RgValidatorForm, IconInfoHelper, RgBaseModal } from "~tokio/primitive";
import { AlertError } from "$exam/common/services";
import { PrintReport, DownloadFile } from "./helpers";

import { IconEmpty } from "~tokio/primitive/icon/symbols";

import {
  RgCleanButton,
  RgAddButton,
  RgSearchButton,
} from "~tokio/primitive/button";
import RgPagination from "~tokio/foundation/pagination/RgPagination";

export default {
  name: "RgSearch",
  components: {
    ModuleBox,
    RgValidatorForm,
    RgAddButton,
    IconInfoHelper,
    RgCleanButton,
    RgBaseModal,
    RgSearchButton,
    IconEmpty,
    RgPagination,
  },

  filters: {
    formatDateBR: (pDate) => moment(pDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
  },

  props: {
    /**
     * @param {*} filters
     * @returns {html: string, count: number}
     */
    searchFunction: {
      type: Function,
      required: true,
    },
    disableButtonWhenNoFilterSelected: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    clearFunction: {
      required: true,
    },
    itemHeight: {
      type: Number,
      default: 0,
    },
    searchAreaHeight: {
      type: Number,
      default: 0,
    },
    itemWidth: {
      type: Number,
      default: 0,
    },
    individualHeight: {
      type: Number,
      default: 0,
    },
    filterTitle: {
      type: String,
      default: "Filtros",
    },
    permission: {
      type: String,
      default: "",
    },
    resultTitle: {
      type: String,
    },
    buildFilter: {
      type: Function,
      required: true,
    },
    isReport: {
      type: Boolean,
      default: false,
    },
    infiniteLimit: {
      type: Boolean,
      default: false,
    },
    printReportCss: {
      default: "",
    },
    newUrl: {
      type: String,
      default: "",
    },
    showNewButton: {
      type: Boolean,
      default: false,
    },
    csvReport: {
      type: Function,
      default: () => {},
    },
    isRetractable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Novo",
    },
    maxRegister: {
      type: Number,
      default: 15,
    },
    hiddenRegisters: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    showEmptyMessage: {
      type: Boolean,
      default: true,
    },
    titleLoader: {
      type: String,
      default: "Aguarde...",
    },
    disabledShorkey: Boolean,
    disabled: Boolean,
    predefinedLimit: null,
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
    columnToSeeMore: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      countItem: 0,
      isWaitingResult: false,
      withoutOclusion: false,
      modalSeeMore: false,
      showHelper: false,
      timeout: null,
      selectedData: null,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
        total: 0,
      },
      html: "",
      initialOffSetHeight: null,
    };
  },

  computed: {
    allowedShowButton() {
      if (this.selectedData !== null && this.selectedData !== undefined) {
        return Object.keys(this.selectedData).length > 0;
      }

      return false;
    },
    hasNext() {
      return (
        this.pagination.total <= this.pagination.offset + this.pagination.limit
      );
    },
    hasPrevious() {
      return !this.pagination.offset;
    },
    numberOfPages() {
      if (!this.pagination.total || !this.pagination.limit) return 1;
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    currentPage() {
      return this.numberOfPages === 0 ? 0 : this.pagination.current;
    },
    canShowNewButton() {
      return !this.isReport && (!isEmpty(this.newUrl) || this.showNewButton);
    },
    hasSearchFunction() {
      return !!this.searchFunction;
    },

    hasPermission() {
      if (this.permission) {
        return this.$Permissions.global.has(this.permission);
      } else {
        return true;
      }
    },
    isCentralizerRoute() {
      return this.$route.path === "/report/centralizer";
    },
  },

  watch: {
    selectedRow(pValue) {
      this.selectedData = pValue;
    },
  },

  mounted() {
    this.cleanLastPersonId();
    this.watchModulebox();
    this.getStorePagination();
  },

  methods: {
    watchModulebox() {
      this.$watch(
        () => this.$refs.filterbox.reversed,
        (pValue) => {
          this.withoutOclusion = pValue;
        },
      );
    },

    isFilterModuleFormValid() {
      return this.$refs.validatorModule
        ? this.$refs.validatorModule.validate()
        : false;
    },
    isFilterFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    formatDate(pDate) {
      this.$utils.date.BrazilianDateToDatabase(pDate);
    },

    prepareData(pToPrint = false, pToCsv = false, pToExcel = false) {
      const filterData = this.buildFilter();

      const data = {
        ...filterData,
        toPrint: pToPrint,
        toCsv: pToCsv,
        toExcel: pToExcel,
      };

      const sameRoute = this.sameRoute();

      if (sameRoute) {
        this.getStorePagination();
      }

      if (this.infiniteLimit) {
        this.pagination.limit = 99999;
      }

      if (data.arrFormData) {
        data.arrFormData.limiteInicio = this.pagination.offset;
        data.arrFormData.limiteFim = this.pagination.limit;
        return data;
      }

      if (data.arrFiltros) {
        data.arrFiltros.limiteInicio = this.pagination.offset;
        data.arrFiltros.limiteFim = this.pagination.limit;
        return data;
      }

      if (data.arrFiltro) {
        data.arrFiltro.limiteInicio = this.pagination.offset;
        data.arrFiltro.limiteFim = this.pagination.limit;
        return data;
      }

      return pToPrint || pToCsv || pToExcel
        ? data
        : {
            ...data,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          };
    },

    async submitForm(submitFromButton = false, reSearch = false) {
      if (this.disabledShorkey) {
        return;
      }

      try {
        const isFormModuleValid = await this.isFilterModuleFormValid();
        const isFormValid = await this.isFilterFormValid();

        if (!(isFormModuleValid && isFormValid) || !this.hasSearchFunction) {
          throw new Error("Verifique os campos e tente novamente.");
        } else {
          this.$refs.btnSubmit.actionSubmit();
          if (submitFromButton) {
            if (this.$refs.paginator) {
              this.$refs.paginator.resetLimitOffset();
            }
            this.setItemPerPage();
            this.$emit("submitFromButton");
          } else if (reSearch) {
            this.setItemPerPage();
          }

          this.$emit("beforePerformSearch", true);
          await this.performSearch(submitFromButton);

          if (submitFromButton && this.isRetractable)
            this.$refs.filterbox.toggleCollapse();
        }
      } catch (error) {
        AlertError(error.message);
        this.$refs.btnSubmit.fail();
      }
    },

    async performSearch(submitFromButton = false, hasLoader = true) {
      try {
        if (hasLoader) this.$loader.start("Buscando...");

        const data = this.prepareData();
        const result = await this.searchFunction(data);

        if (!result) {
          this.$refs.btnSubmit.actionDone();
          return;
        }

        this.countItem = result.rows ? result.count : Number(result.total);

        this.updateValues(result);

        this.$emit("afterPerformSearch", submitFromButton);
        this.$loader.finish();
      } catch (error) {
        this.$refs.btnSubmit.fail();
        this.clearForm();
        throw new Error(error.message);
      } finally {
        if (hasLoader) this.$loader.finish();
      }
    },

    updateValues(pResult) {
      if (this.isReport) {
        this.html = pResult.html;
        this.pagination.total = Number(pResult.count) || 0;
        this.countItem = Number(pResult.count) || 0;
      }
      if (isNaN(this.pagination.total)) {
        this.pagination.total = 0;
      }
      if (isNaN(this.countItem)) {
        this.countItem = 0;
      }
      const rows = pResult.rows
        ? pResult.rows
        : pResult.data || pResult.recordSet;

      const currentStorePagination =
        this.$store.getters["Pagination/GET_CURRENT_PAGINATION"] || null;

      const sameRoute = this.sameRoute();

      const getPaginationFromStore =
        !this.isReport &&
        currentStorePagination &&
        currentStorePagination !== 1 &&
        sameRoute;

      if (getPaginationFromStore) {
        this.getStorePagination();
      } else {
        this.pagination.total =
          Number(pResult.count) >= 0
            ? Number(pResult.count)
            : Number(pResult.total);

        if (
          this.pagination.total > 0 &&
          this.pagination.total <= this.pagination.limit
        ) {
          this.pagination.limit = this.pagination.total;
        }
      }
      if (this.countItem === 0 && rows.length < 1) {
        if (this.$refs.paginator) {
          this.$refs.paginator.resetLimitOffset();
        }
      }

      this.setStorePagination();

      this.$emit("input", rows);
      this.$emit("afterSearch", rows);
      this.$emit("count", pResult.total || pResult.count);

      if (this.$refs.btnSubmit) {
        this.$refs.btnSubmit.actionDone();
      }
    },

    setPagination(pPagination) {
      this.pagination = pPagination;
      this.$nextTick(() => {
        this.submitForm();
      });
    },

    setItemPerPage() {
      if (this.predefinedLimit) {
        this.pagination.limit = this.predefinedLimit;
        this.pagination.offset = 0;
        this.pagination.current = 1;
      } else {
        const hasItemWidth = this.itemWidth && this.itemWidth > 0;

        const hasSearchAreaHeight =
          this.searchAreaHeight && this.searchAreaHeight > 0;

        if (!this.initialOffSetHeight) {
          this.initialOffSetHeight = this.$refs.contentList.getContentListHeight();
        }

        const contentListHeight = this.$refs.contentList.getContentListHeight();

        const currentContentListHeight =
          this.initialOffSetHeight >= contentListHeight
            ? this.initialOffSetHeight
            : contentListHeight;

        const contentHeight = hasSearchAreaHeight
          ? this.searchAreaHeight
          : currentContentListHeight;

        const contentWidth = this.$refs.contentList.getContentListWidth();

        if (hasItemWidth) {
          const containerArea = contentHeight * contentWidth;
          const itemArea = this.itemHeight * this.itemWidth;

          this.pagination.limit = Math.floor(containerArea / itemArea);
        } else {
          this.pagination.limit = Math.floor(contentHeight / this.itemHeight);
        }
      }
      this.setStorePagination();
    },

    sameRoute() {
      const routeName = this.$route.name;
      const routeStorePagination = this.$store.getters[
        "Pagination/GET_ROUTE_PAGINATION"
      ];

      const updateStore =
        routeName && routeStorePagination
          ? routeName.includes(routeStorePagination)
          : null;

      return updateStore;
    },

    setStorePagination() {
      const routeName = this.$route.name;

      this.$store.commit(
        "Pagination/SET_CURRENT_PAGINATION",
        this.pagination?.current,
      );
      this.$store.commit(
        "Pagination/SET_LIMIT_PAGINATION",
        this.pagination?.limit,
      );
      this.$store.commit(
        "Pagination/SET_OFFSET_PAGINATION",
        this.pagination?.offset,
      );
      this.$store.commit("Pagination/SET_TOTAL_PAGINATION", this.countItem);
      this.$store.commit("Pagination/SET_ROUTE_PAGINATION", routeName);
    },

    getStorePagination() {
      const offset = this.$store.getters["Pagination/GET_OFFSET_PAGINATION"];
      const limit = this.$store.getters["Pagination/GET_LIMIT_PAGINATION"];
      const current = this.$store.getters["Pagination/GET_CURRENT_PAGINATION"];
      const total = this.$store.getters["Pagination/GET_TOTAL_PAGINATION"];

      this.pagination.offset = offset;
      this.pagination.limit = limit;
      this.pagination.current = current;
      this.pagination.total = total;
    },

    goToNew() {
      this.$router.push(this.newUrl);
    },

    async printReport() {
      try {
        if (this.isWaitingResult) {
          return;
        }
        this.isWaitingResult = true;

        const data = this.prepareData(true);
        const result = await this.searchFunction(data);

        PrintReport(result, this.printReportCss);
      } catch (error) {
        AlertError("Não foi possível gerar a impressão.");
      }
      this.isWaitingResult = false;
    },

    async downloadCsvReport() {
      try {
        this.$refs.btnExcel.setWaiting();
        if (this.isWaitingResult) {
          this.$refs.btnExcel.setStartOrFinish();
          return;
        }
        this.isWaitingResult = true;

        const data = this.prepareData(false, true);
        const result = await this.searchFunction(data);
        DownloadFile(result.csv, "csv", result.fileName);
      } catch (error) {
        AlertError(error.message);
      }
      this.isWaitingResult = false;
      this.$refs.btnExcel.setStartOrFinish();
    },

    showModalHelper() {
      this.showHelper = true;
    },

    closeHelper() {
      this.showHelper = false;
    },

    clearStorePagination() {
      this.$store.commit("Pagination/DESTROY_PAGINATION");
      this.$store.commit("Pagination/DESTROY_ROUTE_PAGINATION");
    },

    clearForm() {
      this.clearFunction();
      this.clearPagination();
      this.clearStorePagination();
      this.html = "";
      this.selectedData = null;
      this.$refs.validatorModule.cleanValidate();
      this.$refs.validator.cleanValidate();
      this.$emit("clear", true);
    },

    clearPagination() {
      this.countItem = 0;
      this.pagination.offset = 0;
      this.pagination.total = 0;
      this.pagination.current = 1;
      if (this.$refs.paginator) this.$refs.paginator.resetLimitOffset();
      this.setItemPerPage();
    },

    cleanLastPersonId() {
      this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    },
  },
};
</script>
