export const clickOutside = {
  name: "clickOutside",
  directive: {
    bind: function (element, binding, vnode) {
      element.event = function (event) {
        const isTargetElement =
          element === event.target || element.contains(event.target);

        if (!isTargetElement) {
          vnode.context[binding.expression](event);
        }
      };

      document.body.addEventListener("click", element.event);
    },
    unbind: function (element) {
      document.body.removeEventListener("click", element.event);
    },
  },
};
