<template>
  <Modulebox
    :title="
      isEdition
        ? `Editar Estoques Mínimo e Máximo`
        : `Cadastrar Estoques Mínimo e Máximo`
    "
    class="pharmacy-inventory-register"
  >
    <div class="pharmacy-inventory-register-body">
      <form class="form-container" @submit.stop.prevent="saveProvider">
        <div class="grid-informations">
          <RgValidatorForm ref="validator">
            <FormBase title="Dados da Farmácia" class="register-form">
              <div
                class="row-1"
                :class="{
                  'with-clean': !isEdition,
                  'without-clean': isEdition,
                }"
              >
                <RgSelectUnithealthPharmacy
                  id="unit-health"
                  ref="unitHealth"
                  v-model="form.unitHealth"
                  :disabled="isEdition"
                  :class="{ disable: isEdition }"
                  label="Unidade de Saúde"
                  :rules="{ required: true }"
                />
                <RgSelectPharmacy
                  id="pharmacy"
                  ref="pharmacy"
                  v-model="form.pharmacy"
                  viewPharmacyType
                  :unitHealthId="Number(form.unitHealth)"
                  :disabled="!form.unitHealth || isEdition"
                  :class="{ disable: !form.unitHealth || isEdition }"
                  :rules="{ required: true }"
                  @change="selectedPharmacy"
                />
                <RgCleanButton v-if="!isEdition" @click="cleanFormPharmacy" />
                <div class="pharmacy-information">
                  <div class="title">
                    <span class="title">Farmácia Selecionada</span>
                  </div>

                  <div class="container">
                    <div class="pharmacy-info">
                      <span class="text-info">
                        Unidade de Saúde:
                        <strong>{{ pharmacyData.uns_nome }}</strong>
                      </span>
                      <span class="text-info">
                        Farmácia:
                        <strong>{{ pharmacyData.far_nome }}</strong>
                      </span>
                      <span class="text-info">
                        Município:
                        <strong>{{ pharmacyData.mun_nome }}</strong>
                      </span>
                    </div>
                  </div>

                  <div class="container">
                    <div class="pharmacy-info2">
                      <span class="text-info">
                        Tipo de Farmácia:
                        <strong>{{ pharmacyData.tpf_nome }}</strong>
                      </span>
                      <span class="text-info">
                        Responsável:
                        <strong>{{ pharmacyData.pes_nome }}</strong>
                      </span>
                      <span class="text-info">
                        <strong></strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </FormBase>
            <FormBase title="Medicamentos" class="product-info">
              <div class="row-1">
                <RgSelectProduct
                  id="product"
                  ref="product"
                  v-model="form.product"
                  :disabled="!form.pharmacy || isEdition"
                  :class="{ disable: !form.pharmacy || isEdition }"
                  :openOnlyTop="blockResponsive"
                  perClient
                  label="Produto ou Medicamento"
                  :rules="{ required: true }"
                  @change="selectedProduct"
                />

                <RgSelectTypePresentation
                  id="presentation"
                  ref="presentation"
                  v-model="form.presentation"
                  :disabled="true"
                  :class="{ disable: true }"
                  label="Apresentação"
                  :rules="{ required: true }"
                />
                <div></div>
                <RgInput
                  id="min"
                  ref="min"
                  v-model="form.min"
                  :class="{ disable: !form.product }"
                  :disabled="!form.product"
                  :rules="{ required: true, number: true }"
                  class="inputitem"
                  label="Quantidade Mínima"
                  title="Número"
                  :maxlength="10"
                  placeholder="0000000000"
                />
                <RgInput
                  id="max"
                  ref="max"
                  v-model="form.max"
                  :class="{ disable: !form.product }"
                  :disabled="!form.product"
                  :rules="{ required: true, number: true }"
                  class="inputitem"
                  label="Quantidade Máxima"
                  title="Número"
                  :maxlength="10"
                  placeholder="0000000000"
                />
                <RgCleanButton @click="cleanFormProduct" />
              </div>
            </FormBase>
          </RgValidatorForm>
        </div>
      </form>
    </div>

    <div slot="footer" class="footer">
      <div class="footer-buttons">
        <MediumButton
          id="cancel-btn"
          backgroundColor="#fb7e36"
          label="Voltar"
          @click="close"
        />
        <MediumButton
          id="salvar-btn"
          label="Salvar"
          medium
          @click="saveInventory"
        />
      </div>
    </div>
  </Modulebox>
</template>

<script>
import {
  MediumButton,
  RgCleanButton,
  RgValidatorForm,
  RgInput,
} from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";

const FORM_INVENTORY = {
  unitHealth: null,
  pharmacy: null,
  product: null,
  presentation: null,
  max: null,
  min: null,
  mam_id: null,
};
const FORM_PHARMACY = {
  uns_nome: null,
  far_nome: null,
  tpf_nome: null,
  mun_nome: null,
  pes_nome: null,
};

export default {
  name: "PharmacyInventoryRegister",
  components: {
    Modulebox,
    RgCleanButton,
    FormBase,
    RgSelectUnithealthPharmacy,
    MediumButton,
    RgValidatorForm,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectTypePresentation,
    RgInput,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_INVENTORY),
      pharmacyData: this.$utils.obj.DeepCopy(FORM_PHARMACY),
      pharmacyInventory: null,
      pharmacyProviderId: null,
    };
  },
  computed: {
    isEdition() {
      return this.$route.name === "pharmacy.register.inventory.edit";
    },
    blockResponsive() {
      return window.innerHeight <= 636;
    },
  },
  watch: {
    "form.product"(pValue) {
      if (pValue && pValue !== null) {
        this.getInventory();
      } else {
        this.form.presentation = null;
        this.form.max = null;
        this.form.min = null;
        this.form.mam_id = null;
      }
    },
    "form.unitHealth"(pValue, pPrev) {
      if (pValue === null || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacy = null;
        this.form.product = null;
        this.form.presentation = null;
        this.form.max = null;
        this.form.min = null;
        this.form.mam_id = null;
      }
    },
    "form.pharmacy"(pValue, pPrev) {
      if (pValue === null || (pValue && pPrev && pValue !== pPrev)) {
        this.form.product = null;
        this.form.presentation = null;
        this.form.max = null;
        this.form.min = null;
        this.form.mam_id = null;
      }
    },
  },

  mounted() {
    const data = this.$store.getters[
      "Pharmacy/Register/GET_SELECTED_INVENTORY"
    ];
    const existFilterData = this.$store.getters[
      "Pharmacy/Register/GET_FILTER_INVENTORY"
    ];

    if (existFilterData && !data) {
      this.form.unitHealth = existFilterData.unitHealth;
      this.form.pharmacy = existFilterData.pharmacy;
    } else {
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      if (this.isEdition && data) {
        this.pharmacyInventoryId = data.mam_id;
        this.form = {
          unitHealth: Number(data.uns_id),
          pharmacy: data.far_id,
          product: data.mpd_id,
          presentation: data.mtu_id,
          max: data.mam_maximo,
          min: data.mam_minimo,
          mam_id: data.mam_id,
        };
      }
    }
  },
  destroyed() {
    this.$store.commit("Pharmacy/Register/UNSELECT_PHARMACY_INVENTORY");
  },

  methods: {
    selectedProduct(pProduct) {
      if (pProduct) {
        this.form.presentation = pProduct.mtu_id;
      } else {
        this.form.presentation = null;
      }
    },
    selectedPharmacy(pValue) {
      if (pValue && pValue !== null) {
        this.pharmacyData = {
          uns_nome: pValue.uns_nome,
          far_nome: pValue.far_nome.replace(/- SATÉLITE| - CENTRAL/gi, ""),
          tpf_nome: pValue.tpf_nome,
          mun_nome: pValue.mun_nome,
          pes_nome: pValue.pes_nome,
        };
      } else {
        this.pharmacyData = this.$utils.obj.DeepCopy(FORM_PHARMACY);
      }
    },
    async getInventory() {
      try {
        this.$loader.start();
        const variables = {
          intIdFarmacia: Number(this.form.pharmacy),
          intIdProduto: Number(this.form.product),
        };
        const data = await this.$store.dispatch(
          "Pharmacy/Register/GET_INVENTORY",
          variables,
        );
        if (data) {
          this.form.max = data.mam_maximo;
          this.form.min = data.mam_minimo;
          this.form.mam_id = data.mam_id;
        } else {
          this.form.max = null;
          this.form.min = null;
          this.form.mam_id = null;
        }
      } catch (e) {
        this.$toaster.error(
          "Erro ao carregar Máximo e Mínimo cadastrados do produto",
          this.$utils.sanitize.formatError(e),
        );
      } finally {
        this.$loader.finish();
      }
    },
    async formPharmacyValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    parsePharmacyInventory() {
      const variables = {
        mam_id_farmacias: this.form.pharmacy,
        mam_id_produtos: this.form.product,
        mam_minimo: this.form.min,
        mam_maximo: this.form.max,
      };
      if (this.isEdition || this.form.mam_id !== null) {
        variables.mam_id = this.form.mam_id;
      }
      return variables;
    },

    async saveInventory() {
      try {
        if (!(await this.formPharmacyValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        if (
          Number(this.form.min) >= Number(this.form.max) &&
          Number(this.form.min) > 0 &&
          Number(this.form.max) > 0
        ) {
          this.$toaster.info(
            "Insira um valor menor que a quantidade de estoque máximo.",
            "O estoque mínimo deve ser menor",
          );
          return false;
        }

        const variables = this.parsePharmacyInventory();
        this.$loader.start();
        if (this.isEdition || this.form.mam_id !== null) {
          await this.$store.dispatch(
            "Pharmacy/Register/EDIT_PHARMACY_INVENTORY",
            {
              ...variables,
            },
          );
          this.$toaster.success("Estoques alterados com sucesso");
        } else {
          await this.$store.dispatch(
            "Pharmacy/Register/CREATE_PHARMACY_INVENTORY",
            {
              ...variables,
            },
          );
          this.$toaster.success("Estoques cadastrados com sucesso");
        }
        this.$router.push({
          name: "pharmacy.register.inventory",
        });
      } catch (e) {
        if (this.isEdition) {
          this.$toaster.error(
            this.$utils.sanitize.formatError(e),
            "Erro ao alterar o Estoque",
          );
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(e),
            "Erro ao cadastrar o Estoque",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },
    close() {
      this.$router.push({ name: "pharmacy.register.inventory" });
    },
    cleanFormPharmacy() {
      this.form.unitHealth = null;
      this.form.pharmacy = null;
    },
    cleanFormProduct() {
      if (this.isEdition) {
        this.form.min = null;
        this.form.max = null;
      } else {
        this.form.product = null;
        this.form.presentation = null;
        this.form.min = null;
        this.form.max = null;
      }
    },
    cleanForm() {
      this.cleanFormPharmacy();
      this.cleanFormProduct();
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "unitHealth",
        "pharmacy",
        "product",
        "presentation",
        "min",
        "max",
      ];
      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
