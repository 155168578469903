<template>
  <div class="exam-schedule-queue">
    <RgSearch
      ref="rgsearch"
      v-model="mutableExamQueue"
      v-bind="propsRGSearch"
      :disabled="disabledByModal"
      @afterPerformSearch="afterSearchFilter"
      @viewClickPagination="viewClickPagination"
      @submitFromButton="actionCleanSelection"
    >
      <div slot="filters" class="exam-schedule-queue-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectProceduresQueueExam
              id="procedure"
              v-model="form.procedureId"
              class="inputitem"
              label="Procedimento"
              default-text="Todos"
              :unit-health-id="getUnitHealthId"
              :hasRegularPermission="hasRegularPermission"
              :hasViewRequestUnitHealthPermission="
                hasViewRequestUnitHealthPermission
              "
              @change="selectProcedureQueue"
            />
          </div>

          <div class="selectinput">
            <RgSelectSubproceduresQueueByUnitExam
              id="subprocedure"
              v-model="form.subprocedureId"
              class="inputitem"
              label="Subprocedimento"
              :procedureName="form.procedureName"
              :disabled="!form.procedureId"
              default-text="Todos"
              @change="selectSubProcedureQueue"
            />
          </div>

          <div class="selectinput">
            <RgSelectUnithealthQueue
              id="unit-health"
              v-model="form.unit"
              :unit-health-id="getUnitHealthId"
              :module-id="examModuleId"
              :hasRegularPermission="hasRegularPermission"
              :hasViewRequestUnitHealthPermission="
                hasViewRequestUnitHealthPermission
              "
              label="Unidade de Saúde Solicitante"
              class="inputitem"
              :default-text="'Todas'"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              id="patient"
              ref="patient"
              v-model="form.patientName"
              :disabled="disableNameInput"
              :class="{ disable: disableNameInput }"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              id="document"
              ref="document"
              v-model="form.document"
              :disabled="disableDocumentInput"
              :class="{ disable: disableDocumentInput }"
              :with-patient-module="['exam2']"
              :enabled-patient="false"
              :rules="{ forceSelection: true }"
              filterOnlyDocument
              class="inputitem"
              @selected="selectingPatient"
            />
          </div>

          <div class="selectinput">
            <RgSelectPriorityQueue
              id="priority"
              v-model="form.priority"
              :module-id="33"
              label="Prioridade"
              default-text="Todas"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSelectSituation
              id="situation"
              v-model="form.situation"
              :default-text="'Todas'"
              label="Situação"
              class="inputitem"
            />
          </div>
          <div class="selectinput">
            <RgSuggestEmployee
              id="employee"
              ref="employee"
              v-model="requestingProfessional"
              label="Profissional Solicitante"
              placeholder="Digite o profissional solicitante"
              notNecessaryBond
              filterWithoutBond
              @selected="selectingProfessional"
            />
          </div>

          <div class="selectinput">
            <RgSuggestState
              id="state"
              ref="state"
              v-model="form.est_sigla"
              label="Estado"
              class="inputitem state"
              placeholder="Digite o estado"
              @selected="selectingState"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCity
              id="city"
              v-model="form.mun_nome"
              :class="{ disable: disableCounty }"
              :disabled="disableCounty"
              :state="form.est_id"
              label="Município"
              placeholder="Digite o município"
              class="inputitem city"
              @selected="selectingMunicipality"
            />
          </div>

          <div class="filter-subtitle filter-title">
            <span class="period-subtitle">Período do Pedido</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-order-period"
                v-model="form.initialOrderPeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                id="final-order-period"
                v-model="form.finalOrderPeriod"
                label="Data Final"
                class="date"
              />
            </div>
          </div>

          <div class="filter-subtitle filter-title">
            <span class="period-subtitle">Período de Inclusão na Fila</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-inclusion-period"
                v-model="form.initialInclusionDate"
                :rules="{ fn: verifyInclusionInitialDate }"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                id="final-inclusion-period"
                v-model="form.finalInclusionDate"
                label="Data Final"
                class="date"
              />
            </div>
          </div>

          <div class="selectinput">
            <RgRadioCustom
              id="from-queue-adr"
              :value="form.filterRadio"
              :list="ITEMSRADIOFILTER"
              uniqueKey="id"
              class="inputitem"
              label="Listar por Solicitação"
              @input="onInputRadio"
            />
          </div>
        </div>
      </div>

      <div
        v-if="mutableExamQueue.length <= 0"
        slot="menu-top"
        class="top-button"
      >
        <RgAddButton
          id="new-exam"
          large
          label="Novo"
          title="Inserir na fila"
          class="item"
          :class="{ insert: !hasExamQueue }"
          :permission="hasInsertPermission"
          @click="registrationQueue"
        />
      </div>

      <div class="menu-top">
        <div v-show="hasExamQueue" class="top-btn">
          <RgButton
            v-if="!equalListSize"
            id="select-all"
            class="select-btn"
            @click="selectAllList"
          >
            <IconSquare />
          </RgButton>

          <RgButton
            v-if="equalListSize"
            id="unselect-all"
            class="select-btn"
            @click="unselectAllList"
          >
            <IconSquareChecked />
          </RgButton>

          <RgButton
            id="reverse-list"
            class="select-btn"
            title="Inverter seleção"
            @click="reverseListSelection"
          >
            <IconArrowSquare />
          </RgButton>

          <p class="text">
            Linhas Selecionadas:
            {{ selectedRows.length }}
          </p>

          <div class="position-btn">
            <input
              id="queue-advance"
              v-model="queueAmountAdvance"
              :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
              :class="{
                disable: !hasOnlyOneLineSelected || hasPatientInactive,
              }"
              class="field"
              maxlength="5"
              title="Informe o tamanho de intervalo para o passo de subida na fila"
            />
            <SmallButton
              id="queue-advance-btn"
              :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
              :backgroundColor="'#1E88A9'"
              @click="advancePatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg" />
            </SmallButton>

            <input
              id="queue-back"
              v-model="queueAmountBack"
              :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
              :class="{
                disable: !hasOnlyOneLineSelected || hasPatientInactive,
              }"
              class="field"
              maxlength="5"
              title="Informe o tamanho de intervalo para o passo de descida na fila"
            />
            <SmallButton
              id="queue-back-btn"
              :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
              :backgroundColor="'#1E88A9'"
              @click="backPatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg arrow-invert" />
            </SmallButton>

            <input
              id="queue-position"
              v-model="queuePosition"
              :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
              :class="{
                disable: !hasOnlyOneLineSelected || hasPatientInactive,
              }"
              class="field large"
              maxlength="5"
              title="Informe o posição deseja para mover o registro na fila"
            />
            <SmallButton
              id="queue-position-btn"
              :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
              :backgroundColor="'#1E88A9'"
              :permission="hasRegularPermission"
              @click="movePatientInQueue"
            >
              <IconArrowDouble slot="icon" class="svg" />
            </SmallButton>
          </div>
        </div>

        <div class="more-buttons">
          <RgAddButton
            id="new-exam-queue"
            small
            title="Inserir na fila"
            class="insert-button"
            :class="{ insert: !hasExamQueue }"
            :permission="hasInsertPermission"
            @click="registrationQueue"
          />

          <RgEditButton
            v-show="hasExamQueue"
            id="edit-exam-queue"
            :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
            :permission="hasEditPermission"
            title="Editar Fila"
            @click="alterQueue"
          />

          <RgLessButton
            v-show="hasExamQueue"
            id="delete-exam-queue"
            :disabled="!enableButtonForSelectedLines"
            :permission="hasDeletePermission"
            title="Excluir Paciente da Fila"
            @click="openModalConfirmQueueDeletion"
          />

          <RgDropdown
            id="history-exam-queue"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            title="Histórico de Atendimentos"
            :item="listSelected[0] || {}"
            :action-options="optionsDropdownHistory(listSelected)"
            small
            @click="patientCareHistory"
          >
            <IconHistory slot="icon" />
          </RgDropdown>

          <RgShowButton
            v-show="hasExamQueue"
            id="view-patient"
            :disabled="!hasOnlyOneLineSelected"
            title="Visualizar paciente na fila"
            @click="openModalPatientDataRegulationQueue"
          />

          <RgPrinterButton
            v-show="hasExamQueue"
            id="printer-exam-queue"
            :disabled="!hasOnlyOneLineSelected"
            title="Imprimir o comprovante de paciente na fila"
            @click="printProofInsertionQueue"
          />

          <RgDropdown
            v-show="hasExamQueue"
            id="options-person"
            :disabled="!hasOnlyOneLineSelected"
            :item="listSelected[0] || {}"
            :action-options="optionsDropdownMore(listSelected)"
            small
          >
            <IconCard slot="icon" />
          </RgDropdown>

          <SmallButton
            v-show="hasExamQueue"
            id="update-patient-ab-esus"
            title="Atualizar dados AB"
            :disabled="
              !hasOnlyOneLineSelected ||
              disabledByModal ||
              patientHasNoActiveBond ||
              hasPatientInactive
            "
            @click="updatePatientAbEsus"
          >
            <IconBasicAttention
              slot="icon"
              class="svg"
              :disabled="
                !hasOnlyOneLineSelected ||
                disabledByModal ||
                patientHasNoActiveBond ||
                hasPatientInactive
              "
            />
          </SmallButton>

          <div
            v-if="mutableExamQueue && mutableExamQueue.length > 0"
            class="dropdown-button-columns box-scroll"
          >
            <div class="button">
              <span class="text unselect">Colunas</span>
              <IconArrow class="svg" />
            </div>

            <div
              class="dropdown dropdown-absolute"
              @mouseleave="openDropdownColumns"
            >
              <span class="label unselect">Colunas Exibidas</span>

              <label
                v-for="(col, index) in activeHeader"
                :key="col.key"
                :class="{ 'disable unselect': hasOnlyOneActiveHeader }"
                :disabled="hasOnlyOneActiveHeader"
                class="item actives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>

              <span class="label unselect">Colunas Não Exibidas</span>

              <label
                v-for="(col, index) in inactiveHeader"
                :key="col.key"
                class="item inactives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div ref="table" class="exam-schedule-queue-result">
        <div class="content-table">
          <table class="table">
            <tr>
              <th class="title-table" />
              <th class="title-table" />
              <th
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                :style="{ 'text-align': col.align }"
                class="title-table"
              >
                {{ col.name }}
              </th>
            </tr>

            <tr
              v-for="(item, index) in mutableExamQueue"
              :key="index"
              :class="{ selected: selectedRows.includes(item.fil_id) }"
              class="tr row-content"
              @click="getValueRow(item, index)"
            >
              <td class="patient-disabled">
                <div
                  :class="{ 'icon-disable': item.pac_bloqueado === '1' }"
                  :title="
                    item.pac_bloqueado === '1'
                      ? `INATIVO - ${item.mip_nome}`
                      : ''
                  "
                ></div>
              </td>
              <td class="icon-check">
                <div v-if="selectedRows.includes(item.fil_id)" class="check">
                  <IconCheck />
                </div>
              </td>

              <td
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                class="result"
                :class="{
                  priority: item[col.key] === item.pfi_nome,
                }"
                :style="{ textAlign: col.align }"
              >
                <span
                  v-if="item[col.key] !== item.icon_id"
                  :class="{ breath: item[col.key] === item.pfi_nome }"
                >
                  {{ item[col.key] || "-" }}
                </span>
                <div
                  v-if="item[col.key] === item.pfi_nome"
                  class="circle"
                  :title="item.pfi_nome"
                  :style="{
                    background: priorityColor(item),
                  }"
                ></div>
                <div
                  v-if="item[col.key] === item.icon_id"
                  class="icon_deficient"
                >
                  <div v-for="(icon, id) in item.icon_id" :key="id">
                    <CentralizerIcons
                      :name="`CentralizerIcons${id}`"
                      :iconId="icon"
                      margin="0"
                      size="20px"
                      :stroke="selectedRows.includes(item.fil_id) ? '#fff' : ''"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-show="hasExamQueue" slot="menu-bottom" class="painel-button">
        <div class="others-btn">
          <RgHourglassButton
            id="pendencies"
            :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
            small
            title="Pendência"
            class="bottom hourglass"
            @click="pendenciesQueue"
          />

          <RgRegulateButton
            id="regulate"
            small
            title="Regular"
            class="bottom regulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledRegulateButton ||
              hasPatientInactive
            "
            :permission="hasRegularPermission"
            @click="regulateAct"
          />

          <RgUnregulateButton
            id="unregulate"
            small
            title="Desregular"
            class="bottom unregulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledUnregulateButton ||
              hasPatientInactive
            "
            :permission="hasRegularPermission"
            @click="unRegulateAct"
          />

          <RgNewScheduleButton
            id="new-schedule"
            :disabled="!enableButtonForSelectedLines || hasPatientInactive"
            :permission="hasSchedulePermission"
            small
            title="Agendar"
            class="bottom"
            @click="scheduleRegulationQueue"
          />

          <RgExternScheduleButton
            id="schedule-extern"
            :disabled="!enableButtonForSelectedLines || hasPatientInactive"
            :permission="hasExternalSchedulePermission"
            small
            title="Agendar externo"
            class="bottom"
            @click="clickExternalSchedule"
          />
        </div>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmQueueDeletion"
      @reSearch="fillFilter"
    />

    <ModalPatientDataRegulationQueue
      id="modal-patient-data-regulation-queue"
      :show="modalPatientDataRegulationQueue"
      :patientInfo="listSelected[0] || {}"
      @update="updateTelephonePatienAbEsus"
      @inactivate="inactivatePatienAbEsus"
      @close="closeModalPatientDataRegulationQueue"
    />

    <ModalPatientImageInRow
      id="modal-patient-image-in-row"
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="Number(filId)"
      :modId="examModuleId"
      @reSearch="reSearchAct"
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      id="modal-type-print"
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :exam-ids="examIdsToPrint"
      :is-in-batch="true"
      @close="closeModalTypesPrint"
      @printed="printed"
    />

    <ModalTypePrintInsertQueue
      id="modal-type-print-insert-queue"
      :show="modalPrint"
      :filId="Number(filId) || 0"
      @close="closeModalPrint"
    />

    <ModalObservationQueue
      id="modal-observation-queue"
      :show="modalObservationQueue"
      :patientData="listSelected[0]"
      :modId="examModuleId"
      @close="closeModalObservationQueue"
    />

    <ModalSimpleHistory
      id="modal-simple-history"
      :show="showModalSimpleHistory"
      :filter="filterHistoryQueue"
      @close="showModalSimpleHistory = false"
    />

    <ModalConfirmAnticipateScheduling
      id="modal-confirm-antecipate-scheduling"
      v-bind="propsModalConfirmAnticipateScheduling"
      @close="closeModalConfirmAnticipateScheduling"
    />

    <ModalPatientRecordNotLinkedBasicAttention
      :show="modalPatientRecordNotLinkedBasicAttention"
      @close="closeModalPatientRecordNotLinkedBasicAttention"
    />
  </div>
</template>

<script>
import {
  RgInputDate,
  RgSelectUnithealthQueue,
  RgAddButton,
  RgNewScheduleButton,
  RgDropdown,
  IconCard,
  RgHourglassButton,
  RgRegulateButton,
  RgUnregulateButton,
  RgExternScheduleButton,
  RgSuggestSmartPerson,
  IconCheck,
  RgEditButton,
  RgPrinterButton,
  IconArrow,
  IconSquareChecked,
  IconArrowSquare,
  IconArrowSmall,
  IconArrowDouble,
  IconSquare,
  IconBasicAttention,
  RgLessButton,
  RgSelectSituation,
  RgSelectPriorityQueue,
  RgInput,
  SmallButton,
  IconHistory,
  RgShowButton,
  RgButton,
  RgRadioCustom,
  RgSuggestEmployee,
} from "~tokio/primitive";

import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import ModalTypePrintInsertQueue from "$exam/submodules/schedule/component/modal/modal-types-print-insert-queue/ModalTypesPrintInsertQueue";
import CentralizerIcons from "~tokio/primitive/icon/font-awesome/icons/CentralizerIcons";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
  ModalObservationQueue,
  ModalConfirmAnticipateScheduling,
} from "~tokio/primitive/modal";
import { RgSuggestCity } from "$patient/common/components";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import { AlertError } from "~tokio/primitive/notification";
import {
  ModalTypesPrint,
  RgSelectProceduresQueueExam,
  RgSelectSubproceduresQueueByUnitExam,
} from "$exam/submodules/schedule/component/";

import ModalSimpleHistory from "$queue/common/components/modal/modal-simple-history/ModalSimpleHistory";
import ModalPatientDataRegulationQueue from "$exam/submodules/schedule/component/modal/modal-patient-data-regulation-queue/ModalPatientDataRegulationQueue";
import ModalPatientRecordNotLinkedBasicAttention from "$person/patient/component/modal/modal-patient-record-not-linked-basic-attention/ModalPatientRecordNotLinkedBasicAttention";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";
import moment from "moment";
import { mapGetters } from "vuex";
import { DateNow } from "~utils/date";

import {
  ScheduleVoucherHTML,
  ScheduleThermalOneWayHTML,
} from "$exam/submodules/schedule/html";

const baseForm = {
  procedureId: null,
  procedureName: "",
  subprocedureName: "",
  subprocedure: null,
  unit: null,
  priority: null,
  situation: null,
  est_id: null,
  est_sigla: null,
  mun_id: null,
  mun_nome: null,
  initialOrderPeriod: null,
  finalOrderPeriod: null,
  initialInclusionDate: null,
  finalInclusionDate: null,
  filterRadio: null,
  patientName: null,
  patientId: null,
  document: null,
  requestingProfessionalId: null,
};

export default {
  name: "ExamScheduleQueue",
  components: {
    RgSearch,
    RgInputDate,
    RgSuggestState,
    RgSuggestCity,
    RgSelectUnithealthQueue,
    RgSelectPriorityQueue,
    RgAddButton,
    RgSelectSituation,
    RgNewScheduleButton,
    RgHourglassButton,
    RgRegulateButton,
    RgUnregulateButton,
    RgExternScheduleButton,
    RgSuggestSmartPerson,
    IconCheck,
    IconArrow,
    RgInput,
    IconArrowSmall,
    IconArrowSquare,
    IconSquare,
    IconArrowDouble,
    IconSquareChecked,
    IconBasicAttention,
    RgEditButton,
    RgPrinterButton,
    RgLessButton,
    ModalPatientImageInRow,
    ModalConfirmDeletion,
    ModalConfirmAnticipateScheduling,
    RgDropdown,
    IconCard,
    ModalTypesPrint,
    ModalTypePrintInsertQueue,
    ModalPatientDataRegulationQueue,
    RgSelectSubproceduresQueueByUnitExam,
    RgSelectProceduresQueueExam,
    ModalSimpleHistory,
    SmallButton,
    ModalObservationQueue,
    IconHistory,
    RgShowButton,
    RgButton,
    RgRadioCustom,
    CentralizerIcons,
    ModalPatientRecordNotLinkedBasicAttention,
    RgSuggestEmployee,
  },

  data() {
    return {
      selectedRows: [],
      listSelected: [],
      mutableExamQueue: [],
      header: this.COLUMNS,
      unitLogged: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      form: Object.assign({}, baseForm),
      filId: null,
      modalConfirmQueueDeletion: false,
      modalPatientImageInRow: false,
      modalObservationQueue: false,
      modalConfirmAnticipateScheduling: false,
      modalPatientDataRegulationQueue: false,
      disabledRegulateButton: false,
      disabledUnregulateButton: false,
      modalPrint: false,
      modalTypesPrint: false,
      examIdsToPrint: [],
      disableNameInput: false,
      disableDocumentInput: false,
      queueAmountAdvance: 1,
      queueAmountBack: 1,
      queuePosition: 1,
      showModalSimpleHistory: false,
      selectedQueueOrder: [],
      isExternaSchedule: false,
      modalPatientRecordNotLinkedBasicAttention: false,
      requestingProfessional: null,
    };
  },

  computed: {
    disabledByModal() {
      return (
        this.modalConfirmQueueDeletion ||
        this.modalConfirmAnticipateScheduling ||
        this.modalPatientImageInRow ||
        this.modalTypesPrint ||
        this.modalPrint
      );
    },

    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      getColumns: "User/GET_SMART_TABLE_COLUMNS",
    }),

    propsRGSearch() {
      const buildFilter = () => this.generateFilter();
      const searchFunction = (data) => this.actionsSearchFilter(data);
      const clearFunction = () => this.actionCleanFilterForm();
      const showFooter = this.hasExamQueue;
      const maxRegister = 30;
      const itemHeight = 40.6;
      const backendLegacy = true;
      const showTitle = true;
      const showEmptyMessage = true;
      const resultTitle = "Fila de Regulação";

      return {
        buildFilter,
        searchFunction,
        clearFunction,
        itemHeight,
        backendLegacy,
        maxRegister,
        showTitle,
        showEmptyMessage,
        showFooter,
        resultTitle,
      };
    },

    propsModalConfirmDeletion() {
      const maxlength = 250;
      const title =
        this.listSelected.length > 1
          ? "Excluir Pacientes da Fila"
          : "Excluir Paciente da Fila";
      const subtitle =
        'Ao remover o paciente da "Regulação", o registro vai para a lista "Excluídos pela Fila"';
      const show = this.modalConfirmQueueDeletion;
      const bodyText =
        this.listSelected.length > 1 ? "" : this.listSelected[0]?.pes_nome;
      const confirm = (reason) => this.deleteQueue(reason);
      return { show, confirm, maxlength, title, subtitle, bodyText };
    },

    propsModalConfirmAnticipateScheduling() {
      const show = this.modalConfirmAnticipateScheduling;
      const confirm = (reason) => this.confirmAnticipateSchedule(reason);
      const subtitle =
        "Ao agendar o paciente fora do topo da fila, é necessário registrar um motivo.";
      const msgSuccess = "Agendamento antecipado com sucesso!";
      const bodyText =
        this.listSelected.length === 1
          ? this.listSelected[0]?.pes_nome
          : this.listSelected.length > 1
          ? this.listSelected.length + " pacientes selecionados"
          : "";
      const btnRemoveTitle = "Agendar";

      return {
        show,
        confirm,
        subtitle,
        msgSuccess,
        bodyText,
        btnRemoveTitle,
      };
    },

    examModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },
    activeHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => item.active);
      }
      return false;
    },

    inactiveHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => !item.active);
      }
      return false;
    },

    hasOnlyOneActiveHeader() {
      return this.activeHeader.length === 1;
    },

    enableButtonForSelectedLines() {
      return this.selectedRows.length > 0;
    },

    hasOnlyOneLineSelected() {
      return this.selectedRows.length === 1;
    },

    hasExamQueue() {
      return this.mutableExamQueue.length > 0;
    },

    patientHasNoActiveBond() {
      return this.listSelected?.length > 0
        ? !(this.listSelected[0].pae_ativo === 1)
        : true;
    },

    disableCounty() {
      return !this.form.est_id;
    },

    printOptionsPreference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewBuscaFilaExame.opcoesImpressao"
      ];
    },

    requireReasonScheduleOffTop() {
      return !!Number(
        this.$store.getters["Login/GET_PREFERENCES"][
          "tViewExame2.fle_motivo_agendar_fora_topo"
        ],
      );
    },

    allowedEditRegulatedPatient() {
      return !!Number(
        this.$store.getters["Login/GET_PREFERENCES"][
          "tViewBuscaFilaExame.rbt_alterar_paciente_regulado"
        ],
      );
    },

    equalListSize() {
      return this.listSelected.length === this.mutableExamQueue.length;
    },

    filterHistoryQueue() {
      if (this.listSelected[0] === undefined) {
        return {};
      }
      const { fil_id, pes_id, pes_nome } = this.listSelected[0];
      return { fil_id, pes_id, pes_nome };
    },

    hasInsertPermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.incluir");
    },

    hasEditPermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.alterar");
    },

    hasDeletePermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.excluir");
    },

    hasSchedulePermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.agendar");
    },

    hasSchedulePermissionOnePatientNotFirst() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.agendarPacienteForaTopoFila",
      );
    },

    hasSchedulePermissionBatch() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.agendarPacientesPelaFilaEmLote",
      );
    },

    hasRegularPermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.regular");
    },

    hasExternalSchedulePermission() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.agendarExternamente",
      );
    },

    hasViewRequestUnitHealthPermission() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.visualizarUnidadeSolicitante",
      );
    },

    getUnitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },

    patientIsRegulate() {
      return this.listSelected.every((item) => {
        return item.fep_ordem_regulada !== "0";
      });
    },
    hasPatientInactive() {
      return this.listSelected.some((item) => item.pac_bloqueado === "1");
    },
  },

  watch: {
    "form.est_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.mun_id = null;
        this.form.mun_nome = "";
      }
    },

    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.document = null;
        this.form.patientId = null;
        this.disableDocumentInput = true;
      } else {
        this.disableDocumentInput = false;
      }
    },

    "form.document"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disableNameInput = true;
        this.form.patientName = null;
      } else {
        this.disableNameInput = false;
      }
    },

    activeHeader(pValue, pPrev) {
      if (pPrev && pValue[0]?.key !== "without_result") {
        const link = this.$route.path;

        this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
          link,
          usu_id: this.userLoginId,
          uns_id: this.unitHealthId,
          columns: pValue,
          name: this.name || "ExamScheduleQueue",
        });
      }
    },

    listSelected(pValue) {
      const isRegulate = pValue.every((item) => {
        return item.fep_ordem_regulada !== "0";
      });

      const isUnregulate = pValue.every((item) => {
        return item.fep_ordem_regulada === "0";
      });

      if (isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = false;
      }

      if (!isRegulate && isUnregulate) {
        this.disabledRegulateButton = false;
        this.disabledUnregulateButton = true;
      }

      if (!isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = true;
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Ordem", key: "ordem_regulada", align: "center" },
      { name: "Pendência", key: "pendencia", align: "center" },
      { name: "Prioridade", key: "pfi_nome", align: "center" },
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "PCD", key: "icon_id", align: "center", icon: "true" },
      { name: "Telefone", key: "telefone", align: "center" },
      { name: "Idade", key: "idade", align: "center" },
      {
        name: "Profissional Solicitante",
        key: "profissional_solicitante",
        align: "left",
      },
      { name: "Município", key: "mun_nome", align: "left" },
      { name: "Procedimento", key: "nome", align: "left", active: "true" },
      { name: "SubProcedimentos", key: "subprocedimentos", align: "left" },
      { name: "Anexo", key: "anexo", align: "center", active: "true" },
      { name: "Data do Pedido", key: "fil_data_pedido", align: "center" },
      { name: "Data na Fila", key: "fil_data", align: "center" },
      { name: "Unidade de Saúde Inserção", key: "unidade_fila", align: "left" },
      {
        name: "Unidade de Saúde Solicitante",
        key: "uns_solicitante",
        align: "left",
      },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];

    this.ITEMSRADIOFILTER = [
      { title: "Sim", id: 1 },
      { title: "Não", id: 2 },
      { title: "Indiferente", id: 0 },
    ];
  },

  mounted() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_LIST_QUEUE_DATA");

    const isExamSchedule = this.$route.params.isExamSchedule;
    if (isExamSchedule) {
      this.viewExamScheduleVoucher(this.$route.params.examId);
    }

    this.mountTableHeader(this.COLUMNS, 7);
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/schedule/queue",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/Schedule/RESET_FILTER_EXAM_QUEUE");
    }
  },

  methods: {
    selectingProfessional(pProfessional) {
      const source = pProfessional && pProfessional.source;
      if (source) {
        this.form.requestingProfessionalId =
          source.employeeRelationship.vin_id_funcionarios;
      } else {
        this.form.requestingProfessionalId = null;
      }
    },
    onInputRadio(pRadio) {
      if (pRadio) {
        this.form.filterRadio = pRadio.id;
      } else {
        this.form.filterRadio = null;
      }
    },
    afterSearchFilter() {
      if (this.$refs.table) {
        const elementContainer = this.$refs.table;
        elementContainer.scrollTop = 0;
      }

      this.selectedRows = [];
      this.listSelected = [];
    },

    selectProcedureQueue(pProcedure) {
      if (pProcedure) {
        const procedureName = pProcedure.procedure || pProcedure.label;
        this.form.procedureName = procedureName;
      } else {
        this.form.subprocedureId = null;
        this.form.procedureName = null;
      }
    },

    selectSubProcedureQueue(pSubProcedure) {
      if (pSubProcedure) {
        this.form.subprocedureName = pSubProcedure.label;
      } else {
        this.form.subprocedureName = null;
      }
    },

    mountTableHeader(ArrColumns, initialColumns = 1) {
      let header;
      const link = this.$route.path;
      const savedHeader = this.getColumns(
        link,
        this.userLoginId,
        this.unitHealthId,
        this.name || "ExamScheduleQueue",
      );

      if (savedHeader) {
        const activeKey = [];
        savedHeader.columns.forEach((item) => {
          activeKey.push(item.key);
        });

        header = ArrColumns.map((item) => {
          if (activeKey.includes(item.key)) {
            return {
              ...item,
              name: item.name,
              key: item.key,
              align: item.align,
              active: true,
            };
          } else {
            return {
              ...item,
              name: item.name,
              key: item.key,
              align: item.align,
              active: false,
            };
          }
        });
      } else {
        header = ArrColumns.map((item, idx) => {
          const active = idx < initialColumns;
          return {
            ...item,
            name: item.name,
            key: item.key,
            active,
          };
        });
      }

      this.header = header;
      return header;
    },

    async actionsSearchFilter(pData) {
      try {
        this.$loader.start();

        if (pData.arrFormData.limiteFim === 0)
          pData.arrFormData.limiteFim = null;

        const data = await this.$store.dispatch(
          "Exam/Schedule/SEARCH_EXAM_QUEUE",
          pData,
        );
        for (const item of data.data) {
          if (item.pep_deficiencias && item.pep_deficiencias !== "") {
            item.icon_id = item.pep_deficiencias.split(",");
          }
        }
        return data;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.loadPreferences();
        this.$loader.finish();
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          fle_nome_procedimento: this.form.procedureName
            ? this.form.procedureName
            : "TODOS",
          esu_nome_exames_procedimentos_filho: this.form.subprocedureName,
          uns_id: this.form.unit || 0,
          uns_atual: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
          pfi_id: this.form.priority,
          situacao: this.form.situation,
          requestingProfessionalId: this.form.requestingProfessionalId,
          est_id: this.form.est_id,
          mun_id: this.form.mun_id,
          periodoInicial: this.form.initialOrderPeriod,
          periodoInicialFila: this.form.initialInclusionDate,
          periodoFinal: this.form.finalOrderPeriod,
          periodoFinalFila: this.form.finalInclusionDate,
          permissaoRegular: this.hasRegularPermission ? 1 : 0,
          permissaoUnidadeSolicitante: this.hasViewRequestUnitHealthPermission
            ? 1
            : 0,
          somenteRegulados: false,
          rgb_agendado: this.form.filterRadio,
          pes_nome: this.form.patientName,
          pes_id: this.form.patientId,
        },
      };

      this.$store.commit("Exam/Schedule/SET_FILTER_EXAM_QUEUE", this.form);

      return variables;
    },

    async getValueRow(pItem) {
      const queueId = pItem.fil_id;
      const hasQueueId = queueId && Number(queueId) > 0;

      if (hasQueueId) {
        const alreadySelected = this.selectedRows.includes(queueId);

        if (alreadySelected) {
          this.selectedRows.splice(this.selectedRows.indexOf(queueId), 1);

          this.listSelected = this.listSelected.filter(function (el) {
            return el.fil_id !== queueId;
          });

          this.filId = queueId;
          this.selectedQueueOrder.splice(
            this.selectedQueueOrder.indexOf(pItem.ordem_regulada),
            1,
          );
        } else {
          this.selectedRows.push(queueId);
          this.listSelected.push(pItem);
          this.filId = queueId;
          this.selectedQueueOrder.push(pItem.ordem_regulada);
        }
      }
    },

    validateSchedule() {
      const equalsProcedure = this.listSelected.every((item) => {
        return (
          this.listSelected[0].fle_nome_procedimento ===
          item.fle_nome_procedimento
        );
      });

      if (!equalsProcedure) {
        throw new Error(
          "O procedimento dos pacientes selecionados precisam ser iguais",
        );
      }

      const isRegulate = this.listSelected.every((item) => {
        return item.fep_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return equalsProcedure && isRegulate && hasSchedulePermission;
    },

    validateSchedulePermission() {
      const minValueOrder = 1;
      const selectedQueueOrderSort = this.selectedQueueOrder.sort();
      const firstOrderSelected =
        Number(selectedQueueOrderSort[0]) === minValueOrder;

      const hasOnlyOnePatientSelected = this.selectedRows.length === 1;

      const allQueueOrder = this.mutableExamQueue.map(
        (row) => row.ordem_regulada,
      );

      // verifica se os pacientes foram selecionados em ordem
      const hasConsectivePatients = this.checkIfArrayHasConsecutiveElements(
        allQueueOrder,
        this.selectedQueueOrder,
      );

      if (hasOnlyOnePatientSelected) {
        // fora da ordem selecionado e sem permissão de agendar fora do topo da fila
        if (
          !firstOrderSelected &&
          !this.hasSchedulePermissionOnePatientNotFirst
        ) {
          throw new Error("Sem permissão para agendar fora do topo da fila");
        }
      } else {
        // permissão em lote
        if (!this.hasSchedulePermissionBatch) {
          throw new Error("Sem permissão para agendar em lote");
        }

        // não tem a permissão para fora do topo, não estão em sequência ou não é o primeiro na ordem
        if (
          !this.hasSchedulePermissionOnePatientNotFirst &&
          (!firstOrderSelected || !hasConsectivePatients)
        ) {
          throw new Error(
            "Sem permissão para agendar fora do topo da fila. Apenas o primeiro e subsequentes são permitidos",
          );
        }
      }

      // fora do topo ou pacientes em sequência
      const OnePatientOutOfTop =
        hasOnlyOnePatientSelected && !firstOrderSelected;
      const patientsOutOfOrder =
        (firstOrderSelected &&
          !hasOnlyOnePatientSelected &&
          !hasConsectivePatients) ||
        (!hasOnlyOnePatientSelected && !firstOrderSelected);

      if (
        this.requireReasonScheduleOffTop &&
        (OnePatientOutOfTop || patientsOutOfOrder)
      ) {
        this.modalConfirmAnticipateScheduling = true;
        return false;
      }

      return true;
    },

    checkIfArrayHasConsecutiveElements(allItens, itensSelected) {
      itensSelected.sort();
      const startIndex = allItens.lastIndexOf(itensSelected[0]);
      let consecutive = true;
      let countFound = 0;
      for (let i = startIndex; i < allItens.length; i++) {
        itensSelected.indexOf(allItens[i]) === -1
          ? (consecutive = false)
          : countFound++;
        if (countFound === itensSelected.length) {
          break;
        }
      }
      return consecutive;
    },

    selectAllList() {
      this.mutableExamQueue.forEach((item) => {
        if (!this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    unselectAllList() {
      this.mutableExamQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        }
      });
    },

    reverseListSelection() {
      this.mutableExamQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        } else {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    viewClickPagination(pValue) {
      if (pValue) {
        this.unselectAllList();
      }
    },

    toogleStatus(pColumn, pKey) {
      pColumn.active = !pColumn.active;

      const index = this.getHeaderIndex(pKey);
      const activesLength = this.getActiveColumnsLength();
      const maxReached = activesLength > this.MAX_COLUMNS;
      const middle = activesLength / 2;
      const currentIndex = index + 1;

      if (maxReached) {
        const idx =
          currentIndex > middle
            ? this.getFirsActiveColumnIndex()
            : this.getLastActiveColumnIndex();

        this.header[idx].active = false;
      }
    },

    getHeaderIndex(pKey) {
      return this.header.findIndex((item) => item.key === pKey);
    },

    getActiveColumnsLength() {
      return this.getActiveColumnsIndex().length;
    },

    getActiveColumnsIndex() {
      const actives = [];
      this.header.forEach((item, index) => {
        if (item.active) actives.push(index);
      });
      return actives;
    },

    getLastActiveColumnIndex() {
      const activeColumns = this.getActiveColumnsIndex();
      return activeColumns[activeColumns.length - 1];
    },

    getFirsActiveColumnIndex() {
      return this.getActiveColumnsIndex()[0];
    },

    pendenciesQueue() {
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "exam.schedule.queue.pendencies" });
    },

    registrationQueue() {
      this.$router.push({
        name: "exam.schedule.queue.queue-insert",
        params: { fromRegulation: true },
      });
    },

    alterQueue() {
      if (this.patientIsRegulate && !this.allowedEditRegulatedPatient) {
        this.$toaster.warning("Não é possível alterar paciente regulado");
        return;
      }
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "exam.schedule.queue.edit-insert" });
    },

    async deleteQueue(reason) {
      try {
        const arrQueue = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        const variables = {
          arrQueue: arrQueue,
          reason: reason,
        };

        await this.$store.dispatch(
          "Exam/Schedule/REMOVE_EXAM_QUEUE",
          variables,
        );

        this.$toaster.success("Paciente(s) removido(s) da fila com sucesso");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o paciente da fila");
      } finally {
        this.$loader.finish();
      }
    },

    scheduleRegulationQueue() {
      this.isExternaSchedule = false;
      try {
        if (this.validateSchedule()) {
          this.$store.commit(
            "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
            Object.values(this.listSelected),
          );
          this.$router.push({ name: "exam.schedule.queue.schedule" });
          return;
        }
        return;
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    validateExternalSchedule() {
      const isRegulate = this.listSelected.every((item) => {
        return item.fep_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados.");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return isRegulate && hasSchedulePermission;
    },

    clickExternalSchedule() {
      this.isExternaSchedule = true;
      try {
        if (this.validateExternalSchedule()) {
          this.$store.commit(
            "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
            Object.values(this.listSelected),
          );

          this.$router.push({
            name: "exam.schedule.queue.external-schedule-form",
          });
        }
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    confirmAnticipateSchedule(reason) {
      try {
        this.listSelected.forEach(
          (element) => (element.fil_motivo_agendamento_fora_topo = reason),
        );

        this.$store.commit(
          "Exam/Schedule/SELECT_PATIENT_LIST_QUEUE_DATA",
          Object.values(this.listSelected),
        );

        this.isExternaSchedule
          ? this.$router.push({
              name: "exam.schedule.queue.external-schedule-form",
            })
          : this.$router.push({ name: "exam.schedule.queue.schedule" });

        return;
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    priorityColor(pValue) {
      return pValue.pfi_cor.replace("0x", "#");
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source) {
        this.form.patientName = null;
        this.form.patientId = pValue.source.pes_id;
      } else {
        this.form.patientId = null;
      }
    },

    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.est_id = pValue.source.est_id;
      } else {
        this.form.est_id = null;
      }
    },

    selectingMunicipality(pValue) {
      if (pValue && pValue.source) {
        this.form.mun_id = pValue.source.mun_id;
      } else {
        this.form.mun_id = null;
      }
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Exam/Schedule/GET_FILTER_EXAM_QUEUE"
        ];

        this.$loader.start("Carregando dados da busca de fila");

        if (existFilterData) {
          await this.$refs.state.forceSelection({
            est_id: existFilterData.est_id,
            est_sigla: existFilterData.est_sigla,
          });

          if (this.$refs.document && existFilterData.patientId)
            this.$refs.document.fillPatientById(existFilterData.patientId);

          this.form = existFilterData;

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
      } finally {
        this.$loader.finish();
      }
    },

    async reSearchAct() {
      await this.$refs.rgsearch.performSearch();
    },

    async advancePatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado",
          );
          return;
        }

        if (!this.queueAmountAdvance) {
          this.$toaster.warning("Informe a posição na fila");
          return;
        }
        this.$loader.start();
        await this.$store.dispatch("Exam/Schedule/ADVANCE_PATIENT_QUEUE", {
          intIdFila: this.listSelected[0].fil_id,
          intQtdPosicoes: this.queueAmountAdvance,
        });
        await this.$refs.rgsearch.performSearch();
        this.queueAmountAdvance = 1;
        this.actionCleanSelection();
        this.$toaster.success("Operação realizada com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async backPatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado",
          );
          return;
        }

        if (!this.queueAmountBack) {
          this.$toaster.warning("Informe a posição na fila");
          return;
        }

        this.$loader.start();
        await this.$store.dispatch("Exam/Schedule/BACK_PATIENT_QUEUE", {
          intIdFila: this.listSelected[0].fil_id,
          intQtdPosicoes: this.queueAmountBack,
        });
        await this.$refs.rgsearch.performSearch();
        this.queueAmountBack = 1;
        this.actionCleanSelection();
        this.$toaster.success("Operação realizada com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async movePatientInQueue() {
      try {
        const isRegulate = this.listSelected.every((item) => {
          return item.fep_ordem_regulada !== "0";
        });

        if (!isRegulate) {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado",
          );
          return;
        }
        this.$loader.start();
        await this.$store.dispatch("Exam/Schedule/MOVE_PATIENT_QUEUE", {
          intIdFila: this.selectedRows[0],
          intPosicao: this.queuePosition,
        });
        await this.$refs.rgsearch.performSearch();
        this.queuePosition = 1;
        this.actionCleanSelection();
        this.$toaster.success("Operação realizada com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async regulateAct() {
      try {
        this.$loader.start();

        this.hasAnyPendency();

        const onlyWithoutPendency = (ele) =>
          ele.fil_pendencia === "0" || ele.fil_pendencia === "";
        const formatArrayPayload = (ele) => ele.fil_id;

        const arrQueues = this.listSelected
          .filter(onlyWithoutPendency)
          .map(formatArrayPayload);

        if (arrQueues.length > 0) {
          await this.$store.dispatch(
            "Exam/Schedule/REGULATE_EXAM_QUEUE",
            arrQueues,
          );

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
          this.$toaster.success(
            "Paciente(s) regulado(s) com sucesso.",
            "Sucesso",
          );
        }
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async unRegulateAct() {
      try {
        this.$loader.start();

        const arrQueues = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        await this.$store.dispatch(
          "Exam/Schedule/UNREGULATE_EXAM_QUEUE",
          arrQueues,
        );

        await this.clearRowsSelected();
        await this.$refs.rgsearch.performSearch();
        this.$toaster.success("Paciente(s) desregulado(s) com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    printProofInsertionQueue() {
      this.modalPrint = true;
    },

    optionsDropdownHistory(pItem) {
      return [
        {
          label: "Histórico do Paciente na Fila",
          action: this.patientHistoryQueue,
        },
        {
          label: "Histórico de Atendimentos",
          action: this.patientCareHistory,
          disable: !this.hasOnlyOneLineSelected,
        },
      ];
    },

    async updatePatientAbEsus() {
      try {
        this.$loader.start();

        const itemSelected = this.listSelected[0];

        const patientAbEsusId = itemSelected.pae_id;
        const patientId = itemSelected.pac_id;
        const baseCardId = itemSelected.pae_ficha_base
          ? Number(itemSelected.pae_ficha_base)
          : null;

        const patientIndividualRegistration = await this.getIndividualRegistration();

        const hasNoRecord = patientIndividualRegistration.length === 0;

        if (hasNoRecord) {
          this.inactivatePatientBondBasicAttention(patientAbEsusId);
          this.showModalPatientRecordNotLinkedBasicAttention();
          return;
        }

        const hasOneRecord = patientIndividualRegistration.length === 1;

        const individualRegistrationWithSameId = patientIndividualRegistration.find(
          (x) =>
            Number(x.pab_id) === baseCardId ||
            Number(x.pab_id_payload) === baseCardId,
        );

        if (hasOneRecord && individualRegistrationWithSameId) {
          const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
            individualRegistrationWithSameId,
            patientAbEsusId,
            patientId,
          );

          await this.updatePatientBondBasicAttention(patientAbEsus);
          await this.updateTelephonePatienAbEsus();

          this.$toaster.success("Operação realizada com sucesso");
        }
      } catch {
        this.$toaster.warning("Não foi possível atualizar os dados");
      } finally {
        this.$loader.finish();
      }
    },

    async getIndividualRegistration() {
      const itemSelected =
        this.listSelected &&
        this.listSelected.length > 0 &&
        this.listSelected[0];

      if (!itemSelected || !itemSelected.pae_ativo) return null;

      const baseCardId = itemSelected.pae_ficha_base
        ? Number(itemSelected.pae_ficha_base)
        : null;
      const patientCpf = itemSelected.pae_cpf || null;
      const patientCns = itemSelected.pae_cartao_sus || null;

      if (baseCardId && (patientCpf || patientCns)) {
        const variables = {
          baseCardId: baseCardId,
          cpf: patientCpf,
          cns: patientCns,
        };

        try {
          const result = await this.$store.dispatch(
            "Person/Patient/GET_AB_INDIVIDUAL_REGISTRATION",
            variables,
          );

          return result?.rows;
        } catch {
          return null;
        }
      }

      return null;
    },

    async updatePatientBondBasicAttention(pData) {
      if (pData) {
        await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
          abEsus: pData,
        });
      }
    },

    async updateTelephonePatienAbEsus() {
      const selectedRows = this.selectedRows;
      const listSelected = this.listSelected;

      await this.$refs.rgsearch.performSearch();

      this.selectedRows = selectedRows;
      this.listSelected = listSelected;
    },

    async inactivatePatientBondBasicAttention(pPatientAbEsusId) {
      const INATIVO = 0;
      const dataAbEsusInactive = {
        pae_id: Number(pPatientAbEsusId),
        pae_ativo: INATIVO,
        pae_ultima_alteracao: DateNow(),
      };
      await this.updatePatientBondBasicAttention(dataAbEsusInactive);
      await this.updateTelephonePatienAbEsus();
      this.listSelected[0].pae_ativo = 0;
    },

    async inactivatePatienAbEsus() {
      this.closeModalPatientDataRegulationQueue();
      await this.inactivatePatientBondBasicAttention(
        this.listSelected[0].pae_id,
      );
    },

    showModalPatientRecordNotLinkedBasicAttention() {
      this.modalPatientRecordNotLinkedBasicAttention = true;
    },

    closeModalPatientRecordNotLinkedBasicAttention() {
      this.modalPatientRecordNotLinkedBasicAttention = false;
    },

    optionsDropdownMore(pItem) {
      return [
        {
          label: "Arquivos do Paciente na Fila",
          action: this.openModalPatientImageInRow,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Observações do Paciente na Fila",
          action: this.observationPatient,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Cadastro do Paciente",
          action: this.editPatient,
        },
      ];
    },

    editPatient() {
      const patientId = this.listSelected[0].pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(patientId));

      this.$router.push({
        name: `exam.schedule.queue.edit-patient`,
      });
    },

    observationPatient() {
      if (this.filId) {
        this.openModalObservationQueue();
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação",
        );
      }
    },

    openModalPatientDataRegulationQueue() {
      this.modalPatientDataRegulationQueue = true;
    },

    closeModalPatientDataRegulationQueue() {
      this.modalPatientDataRegulationQueue = false;
    },

    openModalObservationQueue() {
      this.modalObservationQueue = true;
    },

    closeModalObservationQueue() {
      this.modalObservationQueue = false;
    },

    openModalConfirmAnticipateScheduling() {
      this.modalConfirmAnticipateScheduling = true;
    },

    closeModalConfirmAnticipateScheduling() {
      this.modalConfirmAnticipateScheduling = false;
      this.clearRowsSelected();
    },

    patientImagesQueue() {
      this.$toaster.warning("Não implementado");
    },

    patientHistoryQueue() {
      this.showModalSimpleHistory = true;
    },

    patientOccupationHistory() {
      this.$toaster.warning("Não implementado");
    },

    openModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = true;
    },

    closeModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = false;
      this.clearRowsSelected();
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
      this.clearRowsSelected();
    },

    patientCareHistory() {
      const patientInfo = this.listSelected[0];
      this.$router.push({
        name: "exam.schedule.queue.patient-care-history",
        params: patientInfo,
      });
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    clearRowsSelected() {
      this.listSelected = [];
      this.selectedRows = [];
      this.selectedQueueOrder = [];
    },

    closeModalPrint() {
      this.modalPrint = false;
    },

    actionCleanSelection() {
      this.selectedRows = [];
      this.listSelected = [];
      this.selectedQueueOrder = [];
    },

    actionCleanFilterForm() {
      this.selectedRows = [];
      this.listSelected = [];
      this.selectedQueueOrder = [];
      this.mutableExamQueue = [];
      this.queueAmountAdvance = 1;
      this.queueAmountBack = 1;
      this.queuePosition = 1;
      this.requestingProfessional = null;
      this.form = Object.assign({}, baseForm);
      this.$refs.document.cleanValidate();
    },

    async viewExamScheduleVoucher(examIds) {
      if (Array.isArray(examIds) && examIds.length > 0) {
        const convertedExamIdsToPrint = examIds.map((examId) => {
          return Number(examId);
        });

        this.examIdsToPrint = convertedExamIdsToPrint.slice();
      }

      if (!Array.isArray(examIds) && examIds) {
        this.examIdsToPrint.push(Number(examIds));
      }

      switch (this.printOptionsPreference) {
        case "2": // Abre as opções de impressão
          await this.openModalTypesPrint();
          break;
        case "3": // Impressora Térmica
          await this.printThermalOneWay();
          break;
        case "4": // Impressora Normal
          await this.printScheduleVoucher();
          break;
        default:
          break;
      }
    },

    hasAnyPendency() {
      const hasPendency = (element) =>
        element.fil_pendencia !== "0" && element.fil_pendencia !== "";
      const isPendency = this.listSelected.find(hasPendency);
      if (isPendency) {
        this.$toaster.warning(
          "Pacientes com pendências não podem ser regulados.",
          "Atenção",
        );
      }
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialOrderPeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalOrderPeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyInclusionInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialInclusionDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalInclusionDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    printed(pIdsExams) {
      this.registerScheduleVoucherBatch(pIdsExams);
    },

    async registerScheduleVoucherBatch(pIdsExams) {
      try {
        await this.$store.dispatch(
          "Exam/Schedule/REGISTER_SCHEDULE_VOUCHER_BATCH",
          pIdsExams,
        );
      } catch (pError) {
        console.error(pError);
        this.$toaster.error(pError.message || pError);
      }
    },

    async printScheduleVoucher() {
      const data = await this.getDataToPrint();
      const html = ScheduleVoucherHTML(data);
      await this.registerScheduleVoucherBatch(this.examIdsToPrint);

      this.$utils.print.printHtml(html);
    },

    async printThermalOneWay() {
      const data = await this.getDataToPrint();
      const html = ScheduleThermalOneWayHTML(data);
      await this.registerScheduleVoucherBatch(this.examIdsToPrint);

      this.$utils.print.printHtml(html);
    },

    async getDataToPrint() {
      const payload = { ids: this.examIdsToPrint };
      const path = "GET_PRINT_SCHEDULE_BATCH";

      return await this.$store.dispatch(`Exam/Schedule/${path}`, payload);
    },

    openDropdownColumns() {
      this.savePreferences();
    },

    async savePreferences() {
      try {
        const modulesListId = this.$store.getters["Login/GET_ROUTE_MODULE_MAP"];
        const locationModule = window.location.pathname;
        let moduleId = null;

        for (const iterator of Object.keys(modulesListId)) {
          if (locationModule.includes(iterator)) {
            moduleId =
              modulesListId[iterator] === null ? 0 : modulesListId[iterator];
          }
        }

        const variables = {
          preferenceColumnTable: {
            moduleId,
            userId: this.userLoginId,
            name: this.name || "ExamScheduleQueue",
            unitHealthId: this.unitHealthId,
            link: this.$route.path,
            columns: this.header
              .filter((element) => element.active === true)
              .map((element) => {
                delete element.icon;
                return element;
              }),
          },
        };
        await this.$store.dispatch("User/SAVE_PREFERENCE_COLUMNS", variables);
      } catch (error) {
        this.$toaster.error("Erro ao salvar preferência de colunas");
      }
    },

    async loadPreferences() {
      try {
        if (
          !this.getColumns(
            this.$route.path,
            this.userLoginId,
            this.unitHealthId,
            this.name || "ExamScheduleQueue",
          )
        ) {
          const modulesListId = this.$store.getters[
            "Login/GET_ROUTE_MODULE_MAP"
          ];
          const locationModule = window.location.pathname;
          const moduleId = [];

          for (const iterator of Object.keys(modulesListId)) {
            if (locationModule.includes(iterator)) {
              moduleId.push(
                modulesListId[iterator] === null ? 0 : modulesListId[iterator],
              );
            }
          }

          const result = await this.$store.dispatch(
            "User/SEARCH_PREFERENCE_COLUMNS",
            {
              moduleId:
                moduleId.length > 1
                  ? moduleId[moduleId.length - 1].toString()
                  : moduleId.toString(),
              userId: this.userLoginId,
              unitHealthId: this.unitHealthId,
            },
          );

          for (const columnsTable of result) {
            if (
              !this.getColumns(
                columnsTable.link,
                columnsTable.usu_id,
                columnsTable.uns_id,
                columnsTable.name,
              )
            ) {
              this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
                ...columnsTable,
              });
              if (
                this.userLoginId === columnsTable.usu_id &&
                columnsTable.name === (this.name || "ExamScheduleQueue") &&
                this.unitHealthId === columnsTable.uns_id &&
                this.$route.path === columnsTable.link
              ) {
                if (columnsTable.columns.length > 0) {
                  for (const col of this.header) {
                    col.active = false;
                  }
                  for (const option of columnsTable.columns) {
                    for (const col of this.header) {
                      if (col.key === option.key) {
                        col.active = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        this.$toaster.error("Erro ao buscar preferência de colunas");
      }
    },
  },
};
</script>
