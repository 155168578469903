import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchEmployeeTeamByUnitHealth($unitHealthScheduleId: Int!) {
    SearchEmployeeTeamByUnitHealth(
      are_id_unidades_saude: $unitHealthScheduleId
    ) {
      count
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  if (data.SearchEmployeeTeamByUnitHealth.count < 1) return false;

  return true;
};
