import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBulletinPerson(
    $peopleName: String
    $crs_numero: String
    $competence: String
    $cnes: String
    $limit: Int
    $offset: Int
  ) {
    bulletinPerson(
      nameAndCns: $peopleName
      competence: $competence
      bof_cns: $crs_numero
      bof_cnes: $cnes
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        bof_id
        bof_nome
        bof_cns
        bof_data_nascimento
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  data.bulletinPerson.rows.map((item) => {
    const formatCns = item?.bof_cns ? item.bof_cns : "000000000000000";
    item.bof_cns = formatCns;
  });

  return data.bulletinPerson;
};
