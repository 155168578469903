var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rg-icon-button",attrs:{"title":_vm.permission ? _vm.title : 'Usuário sem permissão'}},[_c('div',{staticClass:"button",on:{"mouseover":_vm.mouseOver,"mouseleave":_vm.mouseLeave,"click":_vm.clicked}},[_c('div',{staticClass:"left",class:{
        blue: _vm.blue,
        red: _vm.red,
        green: _vm.green,
        hover: _vm.hover,
        disabled: _vm.permission ? _vm.disabled : true,
      }},[(_vm.title)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_c('div',{staticClass:"title"},[_vm._t("title")],2)]),_c('div',{staticClass:"right",class:{
        blue: _vm.blue,
        red: _vm.red,
        green: _vm.green,
        hover: _vm.hover,
        disabled: _vm.permission ? _vm.disabled : true,
      }},[(_vm.showWaiting)?_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"icon-spinner"})]):_vm._e(),(_vm.showNormalCaption || _vm.showDone)?_c('div',{staticClass:"icon"},[_vm._t("icon")],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }