<template lang="html">
  <div class="hospitalization">
    <RgSearch
      ref="RgSearch"
      v-model="mutableBillingList"
      result-title="Lista de Internação"
      :item-height="80"
      :max-register="20"
      :clear-function="cleanForm"
      :search-function="search"
      :build-filter="generateFilter"
    >
      <div slot="filters" class="hospitalization-billing-search-filter">
        <div class="grid">
          <div class="filter-title">
            <span class="title">Tipo de Busca</span>
          </div>
          <!-- <div class="container"> -->
          <RgRadioCustom
            id="bed-situation"
            v-bind="propsRadioCustom"
            :canUnselect="false"
            class="selectinput"
            @input="onInputRadioCustom"
          />
          <div class="filter-title">
            <span class="title">Período</span>
          </div>

          <div v-if="!aihTypeSelected" class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-date-edr"
                ref="initialDate"
                v-model="form.initialDate"
                label="Data Inicial"
                class="inputitem"
              />

              <RgInputDate
                id="final-date-edr"
                ref="finalDate"
                v-model="form.finalDate"
                label="Data Final"
                class="inputitem mg"
              />
            </div>
          </div>

          <div v-else class="selectinput">
            <div class="period">
              <RgInputDate
                v-model="form.hospitalizationDate"
                class="inputitem"
                label="Data de Internação"
              />

              <RgInputDate
                v-model="form.exitDate"
                class="inputitem mg"
                label="Data da Saída"
              />
            </div>
          </div>
          <div class="selectinput">
            <RgInput
              v-if="!aihTypeSelected"
              ref="hospitalizationNumberField"
              v-model="form.intNumero"
              label="Número da Internação"
              placeholder="Informe o número da Internação"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgInput
              v-if="aihTypeSelected"
              ref="hospitalizationNumberField"
              v-model="form.iafNumeroAih"
              label="Número da AIH"
              placeholder="Informe o número da AIH"
              class="inputitem"
            />
          </div>
          <div class="selectinput">
            <RgInputMasked
              v-model="form.iafProntuario"
              mask="###############"
              class="inputitem"
              label="Prontuário"
              placeholder="Informe o número do prontuário"
            />
          </div>
          <div class="selectinput">
            <RgInput
              ref="personField"
              v-model="form.pesNome"
              class="inputitem"
              label="Paciente"
              placeholder="Informe o nome do paciente"
            />
          </div>
          <div class="selectinput">
            <RgRadioCustom
              v-if="aihTypeSelected"
              id="status"
              v-bind="propsRadioCustomStatus"
              :canUnselect="false"
              class="selectinput"
              label="Status da AIH"
              @input="onInputRadioCustomStatus"
            />
          </div>
        </div>
      </div>

      <div slot="menu-top" class="title-button">
        <RgAddButton
          id="novo-paciente"
          large
          label="Novo"
          title="Cadastrar Internação"
          class="item"
          @click="newHospitalizationBilling"
        />
      </div>
      <div class="hospitalization-billing-search-result">
        <div ref="patientList" class="hospitalization-billing-list">
          <div
            v-for="(item, index) in mutableBillingList"
            :key="index"
            :class="{ active: index === activeRow }"
            class="hospitalization-billing"
            @click="selectBulletin(item, index)"
          >
            <div class="info-column">
              <span v-if="aihTypeSelected" :title="item.iaf_numero_aih | aih">
                Número da AIH
                <strong> {{ item.iaf_numero_aih | aih }}</strong>
              </span>
              <span v-else :title="item.int_numero">
                Número Internação: <strong>{{ item.int_numero }}</strong>
              </span>

              <span :title="item.int_data | dateBR">
                Data da Internação:
                <strong>{{ item.int_data | dateBR }}</strong>
              </span>
              <span :title="item.ppr_numero | fillZero">
                Número do Prontuário:
                <strong>{{ item.ppr_numero | fillZero }}</strong>
              </span>
            </div>

            <div class="info-column">
              <span :title="isIndigent(item)">
                Nome do Paciente:
                <strong> {{ isIndigent(item) }}</strong>
              </span>

              <span :title="item.isa_data | dateBR">
                Data de Saída:
                <strong>{{ item.isa_data | dateBR }}</strong>
              </span>
              <span :title="item | Cns">
                Número do CNS:
                <strong>{{ item | Cns }}</strong>
              </span>
            </div>
            <div class="info-column">
              <span :title="hasBirthDate(item) | dateBR">
                Data de Nascimento:
                <strong>{{ hasBirthDate(item) | dateBR }}</strong>
              </span>

              <span
                v-if="getPreviousAihNumber(item)"
                :title="getPreviousAihNumber(item) | aih"
              >
                Número anterior da AIH
                <strong>{{ getPreviousAihNumber(item) | aih }}</strong>
              </span>

              <span v-if="getAihNumber(item)" :title="getAihNumber(item) | aih">
                Número da AIH
                <strong>{{ getAihNumber(item) | aih }}</strong>
              </span>
            </div>

            <div class="info-column">
              <div v-if="openedOrBilled(item.iaf_faturado)">
                <RgLessButton small @click="removeAih(item.iaf_id)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RgSearch>
  </div>
</template>

<script>
import { RgSearch } from "~tokio/foundation";
import {
  RgInput,
  RgInputMasked,
  RgInputDate,
  RgAddButton,
  RgLessButton,
  RgRadioCustom,
} from "~tokio/primitive";
import moment from "moment";

export default {
  name: "Hospitalization",
  components: {
    RgSearch,
    RgInput,
    RgInputMasked,
    RgInputDate,
    RgAddButton,
    RgLessButton,
    RgRadioCustom,
  },
  filters: {
    zeroFill(pValue) {
      return pValue.toString().padStart(4, "0");
    },
    Cns(pValue) {
      if (!pValue.patient) return "NÃO INFORMADO";
      return pValue.patient.person.cns.crs_numero
        ? pValue.patient.person.cns.crs_numero.replace(
            /(\d{3})(\d{4})(\d{4})(\d{4})/gi,
            "$1 $2 $3 $4",
          )
        : "NÃO INFORMADO";
    },
    dateBR(pValue) {
      if (!pValue) return "NÃO INFORMADO";
      return moment(pValue).format("DD/MM/YYYY");
    },
    aih(pValue) {
      if (!pValue) return "000000000000-0";
      return pValue.replace(/(\d{12})(\d{1})/gi, "$1-$2");
    },
    fillZero(pNumber) {
      if (!pNumber) return "NÃO INFORMADO";
      return `0000000000${pNumber}`.slice(-10);
    },
  },
  data() {
    return {
      mutableBillingList: [],
      form: {
        intNumero: "",
        initialDate: "",
        finalDate: "",
        iafNumeroAih: "",
        hospitalizationDate: "",
        exitDate: "",
        iafProntuario: "",
        status: "0",
        pesNome: "",
        isAihType: "I",
      },

      filterData: {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        status: "null",
        period: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
      },
      pagination: {
        limit: 5,
        offset: 0,
        total: 0,
        current: 1,
      },
      selectedHospitalizationBilling: {},
      activeRow: null,
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: " Internação", id: "I" },
        { title: "AIH", id: "A" },
      ];
      const value = this.form.isAihType;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomStatus() {
      const list = [
        { title: "Aberto", id: "0" },
        { title: "Faturado", id: "1" },
        { title: "Exportado", id: "2" },
      ];
      const value = this.form.status;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    uns_id() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    offsetZero() {
      return this.pagination.offset === 0;
    },
    getHospitalizationAihList() {
      return this.$store.getters[
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_LIST"
      ];
    },
    listSize() {
      return this.getHospitalizationAihList &&
        this.getHospitalizationAihList.count
        ? this.getHospitalizationAihList.count
        : 0;
    },
    contentLessThanOffset() {
      return this.pagination.limit * this.pagination.current >= this.listSize;
    },
    aihTypeSelected() {
      return this.form?.isAihType === "A";
    },
  },
  watch: {
    uns_id: function () {
      this.filterData.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },

  created() {},
  methods: {
    onInputRadioCustom(item) {
      if (item && item.id === "I") {
        this.changeAih(false);
      }
      if (item && item.id === "A") {
        this.changeAih(true);
      }
    },
    onInputRadioCustomStatus(item) {
      if (item) {
        this.form.status = item.id;
      }
    },
    selectBulletin(pItem, pIndex) {
      const isSameBulletin =
        this.selectedHospitalizationBilling.int_id === pItem.int_id;

      if (isSameBulletin) {
        this.activeRow = null;
        this.selectedHospitalizationBilling = {};
      } else {
        this.activeRow = pIndex;
        this.selectedHospitalizationBilling = pItem;
        this.billing(pItem);
      }
    },

    getAihNumber(item) {
      if (
        !item.hospitalizationBillingAih ||
        item.hospitalizationBillingAih.iaf_faturado > 0
      )
        return false;

      return item.hospitalizationBillingAih.iaf_numero_aih;
    },

    getPreviousAihNumber(item) {
      if (
        !item.hospitalizationBillingAih ||
        item.hospitalizationBillingAih.iaf_faturado === 0
      )
        return false;

      return item.hospitalizationBillingAih.iaf_numero_aih;
    },
    isIndigent(item) {
      if (item.patient && item.patient.person) {
        return item.patient.person.pes_nome;
      }

      return "PACIENTE NÃO IDENTIFICADO";
    },
    hasBirthDate(item) {
      if (item.patient && item.patient.person) {
        return item.patient.person.pes_nascimento;
      }

      return null;
    },

    newHospitalizationBilling() {
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_ID",
        null,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_BILLING_AIH_ID",
        null,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_PATIENT_UNIT_RECORD_NUMBER",
        null,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_BILLING_INFO_TAB_AIH_NUMERO_ANTERIOR",
        null,
      );
      this.$router.push("detail");
    },

    generateFilter() {
      const variables = {
        // HOSPITALIZATION
        intNumero: this.form.intNumero,
        initialDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.initialDate,
        ),
        finalDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.finalDate,
        ),
        // AIH
        iafNumeroAih: this.form.iafNumeroAih,
        hospitalizationDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.hospitalizationDate,
        ),
        exitDate: this.$utils.date.BrazilianDateToDatabase(this.form.exitDate),
        iafProntuario: this.form.iafProntuario,
        // BOTH
        status: this.form.status,
        pesNome: this.form.pesNome,
        isAihType: this.aihTypeSelected,
      };

      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_AIH_LIST",
        this.form,
      );
      return variables;
    },

    billing({ int_id, iaf_id, inf_aih_numero_anterior }) {
      if (int_id)
        this.$store.commit(
          "Billing/HospitalizationBilling/SET_HOSPITALIZATION_ID",
          int_id,
        );
      if (iaf_id)
        this.$store.commit(
          "Billing/HospitalizationBilling/SET_HOSPITALIZATION_BILLING_AIH_ID",
          iaf_id,
        );
      if (inf_aih_numero_anterior)
        this.$store.commit(
          "Billing/HospitalizationBilling/SET_BILLING_INFO_TAB_AIH_NUMERO_ANTERIOR",
          inf_aih_numero_anterior,
        );

      this.$router.push("detail");
    },
    async search(data) {
      return this.$store.dispatch(
        "Billing/HospitalizationBilling/GET_HOSPITALIZATION_AIH_LIST",
        data,
      );
    },
    changeAih(pItem) {
      this.form.isAihType = pItem ? "A" : "I";
      this.cleanFilterForm();
    },
    cleanForm() {
      this.form = {
        initialDate: "",
        finalDate: "",
        pesNome: "",
        intNumero: "",
        iafNumeroAih: "",
        hospitalizationDate: "",
        exitDate: "",
        iafProntuario: "",
        status: "0",
        isAihType: "I",
      };
    },

    cleanFilterForm() {
      this.form = {
        initialDate: "",
        finalDate: "",
        pesNome: "",
        intNumero: "",
        iafNumeroAih: "",
        hospitalizationDate: "",
        exitDate: "",
        iafProntuario: "",
        status: "0",
        isAihType: this.form.isAihType,
      };
    },
    openedOrBilled(iafFaturado) {
      return ["0", "1"].includes(iafFaturado);
    },
    async removeAih(aihId) {
      const STATUS_EXCLUIDO = 3;
      try {
        this.$store.dispatch(
          "Billing/HospitalizationBilling/BILL_HOSPITALIZATION_BILLING_AIH",
          {
            hospitalizationBillingAihId: aihId,
            status: STATUS_EXCLUIDO,
          },
        );

        this.$toaster.success("AIH Excluida com sucesso");
        await this.$refs.RgSearch.performSearch();
        this.activeRow = null;
        this.selectedHospitalizationBilling = {};
      } catch (pErr) {
        this.$toaster.error("Houve um erro ao excluir a AIH");
      }
    },
  },
};
</script>
