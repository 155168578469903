import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchCustomHospitalizationSearch(
    $int_id: Int
    $limit: Int
    $offset: Int
  ) {
    CustomHospitalizationSearch(
      int_id: $int_id
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        lca_id
        set_id
        uns_id
        lca_nome
        set_nome
        uns_nome
        paciente
        inp_novo_codigo_procedimento
        inp_novo_nome_procedimento
        fun_responsavel_nome
        fun_solicitante_nome
        itl_nome
        int_id
        pes_id
        pes_mae
        pes_pai
        int_numero
        int_data
        int_hora
        int_responsavel
        inl_id
        isl_nome
        isl_id
        pac_prontuario_unico
        cid_nome
        cid_codigo
        ocp_nome
        ocp_codigo
        iti_nome
        int_obs
        ica_nome
        via_id
        via_nome
        int_previsao_alta
        int_id_internacoes_caracteres
        crs_numero
        enviadoPep
        possuiSaidaPep
        int_id_enderecamento_pep
        int_data_alta_pep
        int_hora_alta_pep
        int_id_internacoes_grupos_saidas_pep
        int_id_funcionarios_pep
        int_id_internacoes_locais_destino_pep
        int_cbo_alta_pep
        int_obs_alta_pep
        igs_codigo
        igs_nome
        igs_ativo
        fun_nome
        vin_id
        ild_nome
        int_causa_obito_alta_pep
        usu_nome
        tempo_internado
        ini_id
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.CustomHospitalizationSearch.rows[0];
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
