import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query BasicPatientInfo($pesId: Int!) {
    people(pes_id: $pesId) {
      rows {
        pes_id
        pes_nome
        pes_nascimento
        pes_mae
        gender {
          sex_id
          sex_sigla
          sex_nome
        }
        cpf {
          cpf_id
          cpf_numero
        }
        cns {
          crs_id
          crs_numero
        }
        patient {
          pac_id
          pac_prontuario_unico
          record_numbers {
            rows {
              ppr_numero
              ppr_id_unidades_saude
            }
          }
        }
      }
    }
  }
`;

export default async (context, { pesId = null }) => {
  const variables = {
    pesId: Number(pesId),
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  if (data.people.rows.length < 1) return null;

  return data.people.rows[0];
};
