import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import HandleActionError from "@/common/request/error-handlers/HandleActionError";

const query = gql`
  query PharmacyUnit(
    $far_id_tipos_farmacia: Int!
    $far_id_unidades_saude: Int!
  ) {
    pharmacyUnit(
      far_id_tipos_farmacia: $far_id_tipos_farmacia
      far_id_unidades_saude: $far_id_unidades_saude
    ) {
      count
      rows {
        far_id
        far_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.pharmacyUnit.rows;
  } catch (error) {
    return Promise.reject(HandleActionError(error));
  }
};
