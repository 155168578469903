import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query CriticizeLegacyBulletinBilling(
    $cboCode: String
    $competence: String
    $cnes: String
    $employeeCNS: String
    $limit: Int
    $offset: Int
    $typeFilter: String
  ) {
    CriticizeLegacyBulletinBilling(
      cnes: $cnes
      cboCode: $cboCode
      competence: $competence
      professionalCns: $employeeCNS
      limit: $limit
      offset: $offset
      typeFilter: $typeFilter
    ) {
      rows {
        NO_PROCEDIMENTO
        NO_OCUPACAO
        CO_SERVICO
        CO_CLASSIFICACAO
        CO_CID
        NO_SERVICO
        NO_CLASSIFICACAO
        NO_CID
        CO_OCUPACAO
        ppd_codigo_procedimento
        sex_sigla
        profissional_cns
        competence
        idade
        quantidade
        crs_numero
        id_pessoa
        ppd_id
        pes_nome
        hag_data_consulta
        ppd_id_funcionarios
        ppd_id_ocupacoes_cbo
        ppd_id_consultas
        nomeFuncionario
        uns_id
        VALID_CNS
        VALID_VL_IDADE_MAXIMA
        VALID_VL_IDADE_MINIMA
        VALID_TP_SEXO
        VALID_CO_CID
        VALID_CO_OCUPACAO
        VALID_CO_SERVICO
        VALID_CO_CLASSIFICACAO
        VALID_ATRIBUTOS_CNS
        VALID_ATRIBUTOS_SERVICO_CLASSIFICACAO
        VALID_ATRIBUTOS_CID
        VALID_QT_MAXIMA_EXECUCAO
      }
      count
    }
  }
`;

export default async ({ commit, rootGetters }, variables) => {
  variables = variables || {};
  variables.competence = variables.competence
    ? variables.competence
    : rootGetters["Billing/BDSia/GET_PERIOD_DATE"];
  variables.fco_id_unidades_saude = variables.fco_id_unidades_saude
    ? variables.fco_id_unidades_saude
    : rootGetters["Login/GET_UNIT_HEALTH_ID"];

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const list = data?.CriticizeLegacyBulletinBilling?.rows
      ? data.CriticizeLegacyBulletinBilling.rows
      : [];

    let result = [];
    if (list && list.length > 0) {
      list.forEach((item, index) => {
        item.validProcedure = {
          CO_CLASSIFICACAO: item.VALID_CO_CLASSIFICACAO,
          CO_OCUPACAO: item.VALID_CO_OCUPACAO,
          CO_SERVICO: item.VALID_CO_SERVICO,
          QT_MAXIMA_EXECUCAO: item.VALID_QT_MAXIMA_EXECUCAO,
          TP_SEXO: item.VALID_TP_SEXO,
          VL_IDADE_MAXIMA: item.VALID_VL_IDADE_MAXIMA,
          VL_IDADE_MINIMA: item.VALID_VL_IDADE_MINIMA,
          CO_CID: item.VALID_CO_CID,
          CNS: item.VALID_CNS,
          ATRIBUTOS: {
            CID: item.VALID_ATRIBUTOS_CID,
            CNS: item.VALID_ATRIBUTOS_CNS,
            SERVICO_CLASSIFICACAO: item.VALID_ATRIBUTOS_SERVICO_CLASSIFICACAO,
          },
        };

        item.procedureName = item.NO_PROCEDIMENTO;
        item.occupationName = item.NO_OCUPACAO;
        item.sequence = (index + 1).toString();
        const aux = item.validProcedure;

        if (
          aux &&
          aux.CO_CID !== "0" &&
          aux.CO_CLASSIFICACAO !== "0" &&
          aux.CO_OCUPACAO !== "0" &&
          aux.CO_SERVICO !== "0" &&
          aux.QT_MAXIMA_EXECUCAO !== "0" &&
          aux.TP_SEXO !== "0" &&
          aux.VL_IDADE_MAXIMA !== "0" &&
          aux.VL_IDADE_MINIMA !== "0" &&
          aux.ATRIBUTOS.CID !== "0" &&
          aux.ATRIBUTOS.CNS !== "0" &&
          aux.CNS !== "0" &&
          aux.ATRIBUTOS.SERVICO_CLASSIFICACAO !== "0"
        ) {
          item.hasError = false;
        } else {
          item.hasError = true;
        }
      });

      result = list;
    }

    return {
      rows: result,
      total: data.CriticizeLegacyBulletinBilling.count,
    };
  } catch (e) {
    return Promise.reject(e.graphQLErrors);
  }
};
