import MainDiagnosis from "$billing/submodules/aih/hospitalization-billing/views/report/main-diagnosis/MainDiagnosis";
import AihPerSpecialty from "$billing/submodules/aih/hospitalization-billing/views/report/aih-per-specialty/AihPerSpecialty";
import AihPerPacient from "$billing/submodules/aih/hospitalization-billing/views/report/aih-per-patient/AihPerPatient";
import SecondaryProcedure from "$billing/submodules/aih/hospitalization-billing/views/report/secondary-procedure/SecondaryProcedure";
import MainProcedure from "$billing/submodules/aih/hospitalization-billing/views/report/main-procedure/MainProcedure";
import Homonyms from "$billing/submodules/aih/hospitalization-billing/views/report/homonyms/Homonyms";
import UnbilliedExits from "$billing/submodules/aih/hospitalization-billing/views/report/unbillied-exits/UnbilliedExits";
import PreviewValues from "$billing/submodules/aih/hospitalization-billing/views/report/preview-values/PreviewValues";
import EmergencyReport from "$billing/submodules/aih/hospitalization-billing/views/report/daily/emergency-report/EmergencyReport";
import InternalizationReport from "$billing/submodules/aih/hospitalization-billing/views/report/daily/internalization-report/InternalizationReport";

import DailyAdmissionCensusReport from "$hospitalization/report/view/census/DailyAdmissionCensusReport";
import Admission from "$hospitalization/report/view/admission/Admission";
import DischargedReport from "$hospitalization/report/view/discharged/HospitalizationDischargedReport";

import SpecialtiesAttendancesCount from "$emergency/view/report/specialties-attendances-count/SpecialtiesAttendancesCount";
import SpecialtiesAttendances from "$emergency/view/report/specialties-attendances/SpecialtiesAttendances";
import SpecialtiesAttendancesByDay from "$emergency/view/report/specialties-attendances-by-day/SpecialtiesAttendancesByDay";

import QuantitativeExamReport from "$exam/submodules/report/view/quantitative-exam-report/QuantitativeExamReport";
import ExamScheduleExcludedReport from "$exam/submodules/report/view/exam-schedule-excluded-report/ExamScheduleExcludedReport";
import ExamQueueReport from "$exam/submodules/report/view/exam-queue-report/ExamQueueReport";
import ExamDayReport from "$exam/submodules/report/view/exam-day-report/ExamDayReport";
import ExamByDayReport from "$exam/submodules/report/view/exam-by-day-report/ExamByDayReport";
import ExamUserProductivityReport from "$exam/submodules/report/view/exam-user-productivity-report/ExamUserProductivityReport";
import ExamExcludeScaleSchedule from "$exam/submodules/report/view/exam-exclude-scale-schedule/ExamExcludeScaleSchedule";
import ExamDisabledPeriodsReport from "$exam/submodules/report/view/exam-disabled-periods-report/ExamDisabledPeriodsReport";
import ExamScheduleHistoryReport from "$exam/submodules/report/view/exam-schedule-history-report/ExamScheduleHistoryReport";
import ExamSectorReport from "$exam/submodules/report/view/exam-sector-report/ExamSectorReport";
import ExamProceduresPerformedReport from "$exam/submodules/report/view/exam-procedures-performed-report/ExamProceduresPerformedReport";
import ExamSubProceduresPerformedReport from "$exam/submodules/report/view/exam-sub-procedures-performed-report/ExamSubProceduresPerformedReport";
import ExamProfessionalProductivityReport from "$exam/submodules/report/view/exam-professional-productivity-report/ExamProfessionalProductivityReport";
import ExamPatientsQueueReport from "$exam/submodules/report/view/exam-patients-queue-report/ExamPatientsQueueReport";
import ExamPatientsPendencyQueueReport from "$exam/submodules/report/view/exam-patients-pendency-queue-report/ExamPatientsPendencyQueueReport";
import ExamPatientsScheduledQueueReport from "$exam/submodules/report/view/exam-patients-scheduled-queue-report/ExamPatientsScheduledQueueReport";
import ExamPatientsExcludedQueueReport from "$exam/submodules/report/view/exam-patients-excluded-queue-report/ExamPatientsExcludedQueueReport";
import ExamPatientsExternallyScheduledReport from "$exam/submodules/report/view/exam-patients-externally-scheduled-report/ExamPatientsExternallyScheduledReport";
import CeilingReport from "$exam/submodules/report/view/ceiling-report/CeilingReport";

import AppointmentDailyReport from "$appointment/submodules/report/views/appointment-daily-report/AppointmentDailyReport";
import AppointmentPeriodReport from "$appointment/submodules/report/views/appointment-period-report/AppointmentPeriodReport";
import AppointmentDisabledPeriodsReport from "$appointment/submodules/report/views/appointment-disabled-periods-report/AppointmentDisabledPeriodsReport";
import AppointmentHistoryScheduleReport from "$appointment/submodules/report/views/appointment-history-schedule-report/AppointmentHistoryScheduleReport";
import AppointmentExcludeScaleSchedule from "$appointment/submodules/report/views/appointment-exclude-scale-schedule/AppointmentExcludeScaleSchedule";
import AppointmentPatientsSentPepByEffectedReport from "$appointment/submodules/report/views/appointment-patients-sent-pep-by-effected-report/AppointmentPatientsSentPepByEffectedReport.vue";
import AppointmentExcludedReport from "$appointment/submodules/report/views/appointment-excluded-report/AppointmentExcludedReport";
import AppointmentUserProductivityReport from "$appointment/submodules/report/views/appointment-user-productivity-report/AppointmentUserProductivityReport";
import AppointmentProfessionalProductivityReport from "$appointment/submodules/report/views/appointment-professional-productivity-report/AppointmentProfessionalProductivityReport";
import AppointmentOccupationdReport from "$appointment/submodules/report/views/appointment-occupation-report/AppointmentOccupationReport";
import AppointmentQueueReport from "$appointment/submodules/report/views/appointment-queue-report/AppointmentQueueReport";
import AppointmentPatientsQueueReport from "$appointment/submodules/report/views/appointment-patients-queue-report/AppointmentPatientsQueueReport";
import AppointmentPatientsPendencyQueueReport from "$appointment/submodules/report/views/appointment-patients-pendency-queue-report/AppointmentPatientsPendencyQueueReport";
import AppointmentPatientsScheduledQueueReport from "$appointment/submodules/report/views/appointment-patients-scheduled-report/AppointmentPatientsScheduledReport";
import AppointmentPatientsExcludedQueueReport from "$appointment/submodules/report/views/appointment-patients-excluded-queue-report/AppointmentPatientsExcludedQueueReport";
import AppointmentPatientsExternallyScheduledReport from "$appointment/submodules/report/views/appointment-patients-externally-scheduled-report/AppointmentPatientsExternallyScheduledReport";

import PharmacyConsolidatedReport from "$pharmacy/submodules/report/view/consolidated/PharmacyConsolidatedReport";
import PharmacyMedicationsDispensedPatientsReport from "$pharmacy/submodules/report/view/medications-dispensed-patients-report/PharmacyMedicationsDispensedPatientsReport";
import PharmacyCentralReport from "$pharmacy/submodules/report/view/central/PharmacyCentralReport";
import PharmacySatelliteReport from "$pharmacy/submodules/report/view/satellite/PharmacySatelliteReport";

import UnitHealthReport from "$unit-health/submodules/report/view/unit-health/UnitHealthReport";

import BasicAttentionDuplicateRegistrationReport from "$basic-attention/submodules/report/view/basic-attention-duplicate-registration-report/BasicAttentionDuplicateRegistrationReport";

import PersonDuplicateRegistrationReport from "$person/submodules/report/view/person-duplicate-registration-report/PersonDuplicateRegistrationReport";
import PersonUnifyReport from "$person/submodules/report/view/person-unify-report/PersonUnifyReport";

const MODULES = [
  {
    label: "Atenção Básica",
    active: true,
    permission: "cadastro.relatoriosESUSng.Exibir",
    value: [
      {
        value: BasicAttentionDuplicateRegistrationReport,
        label: "Duplicidade de Ficha de Cadastro Individual",
      },
    ],
  },
  {
    label: "Cadastro",
    active: true,
    permission: "cadastro.relatoriosESUSng.Exibir",
    value: [
      {
        value: PersonDuplicateRegistrationReport,
        label: "Duplicidade de Pacientes",
      },
      {
        value: CeilingReport,
        label: "Emissão de teto",
      },
      {
        value: PersonUnifyReport,
        label: "Pacientes Unificados",
      },
      {
        value: UnitHealthReport,
        label: "Unidades de Saúde",
      },
    ],
  },
  {
    label: "Centro Cirúrgico",
    active: false,
    permission: "centro_cirurgico.relatoriosESUSng.Exibir",
    value: [],
  },
  {
    label: "Consulta",
    active: true,
    permission: "consulta.relatoriosESUSng.Exibir",
    value: [
      {
        value: AppointmentExcludedReport,
        label: "Agendamentos Excluídos",
      },
      {
        value: AppointmentDailyReport,
        label: "Consultas por Dia",
      },
      {
        value: AppointmentOccupationdReport,
        label: "Consultas por Ocupação ",
      },
      {
        value: AppointmentPatientsSentPepByEffectedReport,
        label: "Enviados PEP por Efetivados",
      },
      {
        value: AppointmentExcludeScaleSchedule,
        label: "Escalas Excluídas",
      },
      {
        value: AppointmentQueueReport,
        label: "Filas de Consultas",
      },
      {
        value: AppointmentHistoryScheduleReport,
        label: "Histórico de Agendamentos do Paciente",
      },
      {
        value: AppointmentPatientsExternallyScheduledReport,
        label: "Pacientes Agendados Externamente",
      },
      {
        value: AppointmentPatientsScheduledQueueReport,
        label: "Pacientes Agendados pela Fila",
      },
      {
        value: AppointmentPatientsExcludedQueueReport,
        label: "Pacientes Excluídos pela Fila",
      },
      {
        value: AppointmentPatientsQueueReport,
        label: "Pacientes na Fila",
      },
      {
        value: AppointmentPatientsPendencyQueueReport,
        label: "Pacientes na Fila com Pendência",
      },
      {
        value: AppointmentDisabledPeriodsReport,
        label: "Períodos Inabilitados",
      },
      {
        value: AppointmentUserProductivityReport,
        label: "Produtividade do Usuário",
      },
      {
        value: AppointmentProfessionalProductivityReport,
        label: "Produtividade Profissional de Saúde",
      },
      {
        value: AppointmentPeriodReport,
        label: "Relatório Diário",
      },
    ],
  },
  {
    label: "Exame",
    active: true,
    permission: "exame2.relatoriosESUSng.Exibir",
    value: [
      {
        value: ExamScheduleExcludedReport,
        label: "Agendamentos Excluídos",
      },
      {
        value: ExamDayReport,
        label: "Dia do Exame",
      },
      {
        value: ExamExcludeScaleSchedule,
        label: "Escalas Excluídas",
      },
      {
        value: ExamSectorReport,
        label: "Exames por Setor ",
      },
      {
        value: ExamQueueReport,
        label: "Filas de Exames",
      },
      {
        value: ExamScheduleHistoryReport,
        label: "Histórico de Agendamentos do Paciente",
      },
      {
        value: ExamPatientsExternallyScheduledReport,
        label: "Pacientes Agendados Externamente",
      },
      {
        value: ExamPatientsScheduledQueueReport,
        label: "Pacientes Agendados pela Fila",
      },
      {
        value: ExamPatientsExcludedQueueReport,
        label: "Pacientes Excluídos pela Fila",
      },
      {
        value: ExamPatientsQueueReport,
        label: "Pacientes na Fila",
      },
      {
        value: ExamPatientsPendencyQueueReport,
        label: "Pacientes na Fila com Pendência",
      },
      {
        value: ExamDisabledPeriodsReport,
        label: "Períodos Inabilitados",
      },
      {
        value: ExamProceduresPerformedReport,
        label: "Procedimentos e Subprocedimentos Efetivados",
      },
      {
        value: ExamUserProductivityReport,
        label: "Produtividade do Usuário",
      },
      {
        value: ExamProfessionalProductivityReport,
        label: "Produtividade Profissional de Saúde",
      },
      {
        active: false,
        value: QuantitativeExamReport,
        label: "Relatório Quantitativo de Exame",
      },
      {
        value: ExamByDayReport,
        label: "Relatório de Exames Diário",
      },
      {
        value: ExamSubProceduresPerformedReport,
        label: "Subprocedimentos Realizados",
        active: false,
      },
    ],
  },
  {
    label: "Emergência",
    active: true,
    permission: "emergencia.relatoriosESUSng.Exibir",
    value: [
      {
        value: SpecialtiesAttendances,
        label: "Entrada e Saída de Boletim por Especialidade",
      },
      {
        value: SpecialtiesAttendancesCount,
        label: "Registro de Atendimentos por Especialidade",
      },
      {
        value: SpecialtiesAttendancesByDay,
        label: "Registro Diario de Emergência por Especialidade",
      },
      {
        value: EmergencyReport,
        label: "Relatório Diário",
      },
    ],
  },
  {
    label: "Farmácia",
    active: true,
    permission: "farmacia.relatoriosESUSng.Exibir",
    value: [
      {
        value: PharmacyCentralReport,
        label: "Farmácia Central",
      },
      {
        value: PharmacyConsolidatedReport,
        label: "Relatório Consolidado",
      },
      {
        value: PharmacyMedicationsDispensedPatientsReport,
        label: "Relatório de Medicamentos Dispensados para Pacientes",
      },
      {
        value: PharmacySatelliteReport,
        label: "Farmácia Satélite",
      },
    ],
  },
  {
    label: "Faturamento",
    active: true,
    permission: "faturamento.relatoriosESUSng.Exibir",
    value: [
      {
        value: MainDiagnosis,
        label: "AIH por Diagnóstico Principal",
      },
      {
        value: AihPerSpecialty,
        label: "AIH por Especialidade",
      },
      {
        value: AihPerPacient,
        label: "AIH por Paciente",
      },
      {
        value: SecondaryProcedure,
        label: "AIH por Procedimento Especial e Secundário",
      },
      {
        value: MainProcedure,
        label: "AIH por Procedimento Principal",
      },
      {
        value: Homonyms,
        label: "Homônimos",
      },
      {
        value: UnbilliedExits,
        label: "Internação Saídas Não Faturadas",
      },
      {
        value: PreviewValues,
        label: "Prévia Valor",
      },
    ],
  },
  {
    label: "Internação",
    active: true,
    permission: "internacao.relatoriosESUSng.Exibir",
    value: [
      {
        value: DischargedReport,
        label: "Alta de Internação",
      },
      {
        value: DailyAdmissionCensusReport,
        label: "Censo Diário de Internação",
      },
      {
        value: Admission,
        label: "Entradas",
      },
      {
        value: InternalizationReport,
        label: "Relatório Diário",
      },
    ],
  },
];

export default MODULES.filter((module) => module.active !== false).map(
  (module) => {
    return !module.value || module.value.length === 0
      ? module
      : {
          ...module,
          value: module.value.filter((report) => report.active !== false),
        };
  },
);
