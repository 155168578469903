<template lang="html">
  <aside v-if="show" class="modal-show-bed-history">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">Histórico do Leito</span>
      </div>

      <div slot="body" class="body">
        <!-- <form> -->
        <RgValidatorForm ref="validator" class="modal-show-bed-history-body">
          <div class="information">
            <RgBedStatus
              :generate-situation-status="
                hospitalizationBed
                  ? hospitalizationBed.inl_id_internacoes_situacoes_leito
                  : null
              "
              class="status"
            />

            <div class="grid">
              <span class="data">
                Unidade de Saúde:
                <strong>
                  {{
                    hospitalizationBed && hospitalizationBed.uns_nome
                      ? hospitalizationBed.uns_nome
                      : "Não informado"
                  }}
                </strong>
              </span>

              <span class="data">
                Local de Atendimento:
                <strong>
                  {{
                    hospitalizationBed && hospitalizationBed.lca_nome
                      ? hospitalizationBed.lca_nome
                      : "Não informado"
                  }}
                </strong>
              </span>

              <span class="data">
                Setor:
                <strong>
                  {{
                    hospitalizationBed && hospitalizationBed.set_nome
                      ? hospitalizationBed.set_nome
                      : "Não informado"
                  }}
                </strong>
              </span>

              <span class="data">
                Leito:
                <strong>
                  {{
                    hospitalizationBed && hospitalizationBed.inl_nome
                      ? hospitalizationBed.inl_nome
                      : "Não informado"
                  }}
                </strong>
              </span>
            </div>
          </div>

          <div class="filter">
            <div class="search-body">
              <RgInputDate
                id="initial-date"
                v-model="tradeInitialDate"
                :rules="{ fn: verifyInitialDate }"
                :label="'Data Inicial'"
                class="align-date-input"
                :max-date="new Date()"
              />

              <RgInputDate
                id="final-date"
                v-model="tradeFinalDate"
                :label="'Data Final'"
                class="align-date-input"
                :max-date="new Date()"
              />

              <RgInput
                id="search"
                v-model="searchOnTable"
                :placeholder="'Informe o que deseja buscar'"
                label="Busca"
                class="input"
              />

              <div class="actions unselect">
                <RgCleanButton
                  id="clear-btn"
                  small
                  data-id="limpar"
                  class="clean"
                  title="Limpar"
                  @click="cleanInput"
                />

                <RgSearchButton
                  id="search-btn"
                  ref="btnSubmit"
                  v-shortkey="['enter']"
                  type="button"
                  data-id="buscar"
                  class="search"
                  title="Buscar"
                  @shortkey.native="searchHistory"
                  @submit="searchHistory"
                />
              </div>
            </div>
          </div>

          <RgList
            ref="rgList"
            v-model="mutableHistoryList"
            :search-function="search"
            :build-filter="generateFilter"
            :show-total="false"
            :register-per-page="6"
            class="rg-list"
            :max-register="6"
          >
            <div v-show="!hasCountListHistory" class="no-result">
              <IconEmpty class="svg" />
              <span class="info"
                >Não foram encontrados resultados para essa busca</span
              >
            </div>

            <div class="modal-show-bed-history-table">
              <table v-show="hasCountListHistory" class="table">
                <tr class="tr title-table">
                  <th class="th">Da Situação</th>
                  <th class="th">Para a Situação</th>
                  <th class="th">Usuário</th>
                  <th class="th">Data</th>
                </tr>

                <tr
                  v-for="(item, index) in mutableHistoryList"
                  :key="index"
                  :class="{ 'active-row': index === activeRow }"
                  class="tr row-content"
                  v-on:click="selectLine(item, index)"
                  @click="getHistoryBedDetails(item.ihs_id, index)"
                >
                  <td class="result">
                    {{ item.situacao_origem }}
                  </td>

                  <td class="result">
                    {{ item.situacao_destino }}
                  </td>

                  <td class="limit-text-120 result">
                    {{ item.usu_nome }}
                  </td>

                  <td class="result">
                    {{ item.data_troca }}
                    <span class="hours">
                      {{ item.hora_troca }}
                    </span>
                  </td>

                  <td class="arrow-body result">
                    <div class="arrow" />
                  </td>
                </tr>
              </table>

              <div
                v-show="hasCountListHistory && activeRow !== false"
                class="extra-details"
              >
                <div
                  v-if="bedHistoryDetails && bedHistoryDetails.int_id"
                  class="detail small-scroll"
                >
                  <span class="titledetails">Detalhes</span>

                  <div class="title">
                    <span>
                      {{ bedHistoryDetails.ihs_tipo_operacao }}
                    </span>
                    <span class="icon"><IconIn class="svg" /></span>
                  </div>

                  <div class="align-texts">
                    <span class="space-texts">
                      {{ labelOperation }}
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.int_data
                          ? bedHistoryDetails.int_data
                          : disableDetailsHistory
                          ? ""
                          : "NÃO INFORMADO"
                      }}</strong>
                      às
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.int_hora
                          ? bedHistoryDetails.int_hora
                          : disableDetailsHistory
                          ? ""
                          : "NÃO INFORMADO"
                      }}</strong>
                    </span>

                    <span class="space-texts">
                      {{ labelOperationNumber }}
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.int_numero
                          ? bedHistoryDetails.int_numero
                          : disableDetailsHistory
                          ? ""
                          : "NÃO INFORMADO"
                      }}</strong>
                    </span>

                    <span class="space-texts">
                      Paciente:
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.nome_paciente
                          ? bedHistoryDetails.nome_paciente
                          : disableDetailsHistory
                          ? ""
                          : "PACIENTE NÃO IDENTIFICADO"
                      }}</strong>
                    </span>

                    <span
                      v-if="
                        bedHistoryDetails &&
                        bedHistoryDetails.ihs_observacao &&
                        !isExit
                      "
                      class="space-texts"
                    >
                      Motivo:
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.ihs_observacao
                          ? bedHistoryDetails.ihs_observacao
                          : disableDetailsHistory
                          ? ""
                          : "NÃO INFORMADO"
                      }}</strong>
                    </span>
                  </div>

                  <div
                    :class="{
                      _none: !(bedHistoryDetails && bedHistoryDetails.isa_id),
                    }"
                    class="title"
                  >
                    <span>Dados da Saída</span>

                    <span class="icon"><IconOut class="svg" /></span>
                  </div>

                  <div
                    :class="{
                      _none: !(bedHistoryDetails && bedHistoryDetails.isa_id),
                    }"
                    class="align-texts"
                  >
                    <span class="space-texts">
                      Saída:
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.isa_data
                          ? bedHistoryDetails.isa_data
                          : !(bedHistoryDetails && bedHistoryDetails.isa_id)
                          ? ""
                          : "NÃO INFORMADO"
                      }}</strong>
                      às
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.isa_hora
                          ? bedHistoryDetails.isa_hora
                          : !(bedHistoryDetails && bedHistoryDetails.isa_id)
                          ? ""
                          : "NÃO INFORMADO"
                      }}</strong>
                    </span>
                    <span class="space-texts">
                      Motivo:
                      <strong>{{
                        bedHistoryDetails && bedHistoryDetails.igs_nome
                          ? bedHistoryDetails.igs_nome
                          : !(bedHistoryDetails && bedHistoryDetails.isa_id)
                          ? ""
                          : "NÃO INFORMADO"
                      }}</strong>
                    </span>
                  </div>
                </div>

                <div v-else class="detail small-scroll">
                  <span class="titledetails">Detalhes</span>

                  <div class="title">
                    <span>MOVIMENTAÇÃO</span>
                    <span class="icon"><IconReload class="svg" /></span>
                  </div>

                  <div class="align-texts">
                    <span class="space-texts">
                      Motivo:
                      <strong>
                        {{
                          bedHistoryDetails && bedHistoryDetails.ihs_observacao
                            ? bedHistoryDetails.ihs_observacao
                            : "NÃO INFORMADO"
                        }}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </RgList>
        </RgValidatorForm>
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import moment from "moment";
import RgList from "~tokio/foundation/rg-list/RgList";
import RgBedStatus from "$hospitalization/common/component/status/rg-bed-status/RgBedStatus";
import { AlertError } from "~tokio/primitive/notification";
import {
  IconIn,
  IconOut,
  IconReload,
  IconEmpty,
} from "~tokio/primitive/icon/symbols";
import {
  RgBaseModal,
  RgInput,
  RgInputDate,
  RgValidatorForm,
} from "~tokio/primitive";
import { RgSearchButton, RgCleanButton } from "~tokio/primitive/button";

export default {
  name: "ModalShowBedDetails",
  components: {
    RgBaseModal,
    RgList,
    RgInput,
    RgInputDate,
    RgSearchButton,
    RgCleanButton,
    IconIn,
    IconOut,
    IconReload,
    RgValidatorForm,
    RgBedStatus,
    IconEmpty,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hospitalizationBed: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bedMovement: {},
      mutableHistoryList: [],
      countHistoryList: 0,
      bedHistoryDetails: {},
      bedInfo: {},
      searchOnTable: "",
      tradeInitialDate: "",
      tradeFinalDate: "",

      activeRow: false,
      labelOperation: null,
      labelOperationNumber: null,
      isExit: false,
    };
  },
  computed: {
    disableDetailsHistory() {
      return !(this.bedHistoryDetails && this.bedHistoryDetails.int_id);
    },
    hasCountListHistory() {
      return this.countHistoryList > 0;
    },
  },
  watch: {
    hospitalizationBed(pValue) {
      if (pValue && pValue.id && this.show) {
        this.bedInfo = pValue;
        this.searchHistory();
      }
    },

    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    bedHistoryDetails(pValue) {
      if (pValue && pValue.int_previsao_alta) {
        this.bedHistoryDetails.int_previsao_alta = this.$utils.date.BrazilianDateFormat(
          pValue.int_previsao_alta,
        );
      }

      if (pValue && pValue.int_data) {
        this.bedHistoryDetails.int_data = this.$utils.date.BrazilianDateFormat(
          pValue.int_data,
        );
      }

      if (pValue && pValue.int_hora) {
        this.bedHistoryDetails.int_hora = moment(
          this.bedHistoryDetails.int_hora,
          "HH:mm",
        ).format("HH:mm");
      }

      if (pValue && pValue.isa_data) {
        this.bedHistoryDetails.isa_data = this.$utils.date.BrazilianDateFormat(
          pValue.isa_data,
        );
      }

      this.customizeDetails(pValue);
    },

    async mutableHistoryList(pValue, index) {
      if (pValue) {
        this.countHistoryList = pValue.length;

        if (pValue.length > 0) {
          const firstBedHistoryDetailsId = pValue[0].ihs_id;
          await this.getHistoryBedDetails(firstBedHistoryDetailsId, 0);
        }
      }
    },
  },
  methods: {
    search(pData) {
      return this.$store.dispatch(
        "Hospitalization/Bed/SEARCH_CUSTOM_HISTORY_HOSPITALIZATION_BED",
        { ...pData },
      );
    },

    generateFilter() {
      const variables = {
        inl_id: (this.hospitalizationBed && this.hospitalizationBed.id) || 0,
        filter: this.searchOnTable || null,
        initialExchangeDate:
          this.$utils.date.BrazilianDateToDatabase(this.tradeInitialDate) ||
          null,
        finalExchangeDate:
          this.$utils.date.BrazilianDateToDatabase(this.tradeFinalDate) ||
          moment().format("YYYY-MM-DD"),
      };
      return variables;
    },

    async getHistoryBedDetails(pBedHistoryId, index) {
      try {
        if (this.hospitalizationBed && this.hospitalizationBed.id) {
          this.bedHistoryDetails = null;
          const bedHistoryId = pBedHistoryId;
          const bedId = this.hospitalizationBed.id;

          const result = await this.$store.dispatch(
            "Hospitalization/Bed/SEARCH_CUSTOM_HISTORY_HOSPITALIZATION_BED_DETAILS",
            { ihs_id: bedHistoryId, inl_id: bedId },
          );

          if (result) {
            this.bedHistoryDetails = result;
          }

          if (!result) {
            this.bedMovement = this.mutableHistoryList[index];
          }
        }
      } catch (pErr) {
        console.log("pErr", pErr);
      }
    },

    async searchHistory() {
      try {
        if (!(await this.isFormValid())) {
          this.$refs.btnSubmit.actionDone();
          return AlertError("Verifique os campos");
        }
        if (this.show && this.hospitalizationBed) {
          this.$refs.btnSubmit.actionSubmit();
          await this.$refs.rgList.submitForm(true);
          this.$refs.btnSubmit.actionDone();
        }
      } catch (pErr) {
        this.$refs.btnSubmit.fail();
      }
    },

    isFormValid() {
      if (this.$refs.validator === undefined) return true;
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    selectLine(pValue, pIndex) {
      this.activeRow = pIndex;
    },

    verifyInitialDate(pValue, pErrors) {
      const initialDate = moment(this.tradeInitialDate, "DD-MM-YYYY");
      const finalDate = moment(this.tradeFinalDate, "DD-MM-YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }

      return true;
    },

    customizeDetails(pValue) {
      if (pValue && pValue.ihs_tipo_operacao) {
        if (
          pValue.ihs_tipo_operacao === "RESERVA" ||
          pValue.ihs_tipo_operacao === "EXCLUIR RESERVA"
        ) {
          this.labelOperation = "Reserva:";
          this.labelOperationNumber = "N. da Reserva:";
        } else {
          this.labelOperation = "Internação:";
          this.labelOperationNumber = "N. da Internação:";
          this.isExit = pValue.ihs_tipo_operacao === "SAÍDA";
        }
      }
    },

    cleanInput() {
      this.searchOnTable = "";
      this.tradeInitialDate = "";
      this.tradeFinalDate = "";
      this.searchHistory();
      this.activeRow = false;
    },

    cleanValues() {
      this.bedInfo = null;
      this.mutableHistoryList = null;
      this.bedHistoryDetails = null;
    },

    close() {
      this.$emit("close");
      this.$refs.rgList.resetLimitOffset();
      this.cleanValues();
      this.cleanInput();
    },
  },
};
</script>
