<template lang="html">
  <fieldset class="radio-status-bulletin">
    <div class="container unselect">
      <div
        :class="{
          '-active-with-exit': selectedStatus.includes(STATUS.COM_SAIDA),
        }"
        class="radio -with-exit"
        @click="toggleStatus(STATUS.COM_SAIDA)"
      >
        Com Saída
      </div>
      <div
        :class="{
          '-active-waiting': selectedStatus.includes(
            STATUS.AGUARDANDO_ATENDIMENTO,
          ),
        }"
        class="radio -waiting"
        @click="toggleStatus(STATUS.AGUARDANDO_ATENDIMENTO)"
      >
        Aguardando atendimento
      </div>
      <div
        :class="{
          '-active-not-met': selectedStatus.includes(STATUS.NAO_ATENDIDO),
        }"
        class="radio -not-met"
        @click="toggleStatus(STATUS.NAO_ATENDIDO)"
      >
        Não atendido
      </div>
    </div>
  </fieldset>
</template>

<script>
import STATUS from "./Status";

export default {
  name: "RadioStatusBulletin",
  props: {
    value: {
      default: [0, 1, 2],
    },
  },
  data() {
    return {
      selectedStatus: [0, 1, 2],
    };
  },
  computed: {
    STATUS() {
      return { ...STATUS };
    },
  },
  watch: {
    value(pValue) {
      this.selectedStatus = pValue;
    },
  },
  mounted() {
    this.selectedStatus = [0, 1, 2];
  },
  methods: {
    toggleStatus(pItem) {
      if (this.selectedStatus.includes(pItem)) {
        this.selectedStatus.splice(this.selectedStatus.indexOf(pItem), 1);
      } else {
        this.selectedStatus.push(pItem);
      }
      this.$emit("input", this.selectedStatus);
    },
  },
};
</script>
