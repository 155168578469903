import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: variables.arrFormData,
    arrFormDataHistoricoSituacaoLeito:
      variables.arrFormDataHistoricoSituacaoLeito,
    idIternacoesSituacoesLeito: variables.idIternacoesSituacoesLeito,
  };

  return AuthLegacyRequest.post(
    "internacoes/controller-fila-internacao/agendar-pela-fila",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
