<template>
  <div v-if="show">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="modal-advanced-patient-search"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-advanced-patient-search-header">
        <h1 class="title">Busca Avançada de Pacientes</h1>
      </div>

      <div slot="body" class="modal-advanced-patient-search-body">
        <div class="filter">
          <h1 class="title">Filtros</h1>

          <div class="fields">
            <RgValidatorForm ref="validator" class="grid">
              <div class="selectinput">
                <RgInputSmartPersonName
                  id="person-name"
                  v-model="personName"
                  :disabled="hasDocument || hasRecordNumber"
                  :class="{ disable: hasDocument || hasRecordNumber }"
                  @value="nameValue"
                  @typeName="nameType"
                />
              </div>

              <div class="selectinput">
                <RgInputDate
                  id="birth-date"
                  v-model="form.pes_nascimento"
                  :disabled="hasDocument || hasRecordNumber"
                  :class="{ disable: hasDocument || hasRecordNumber }"
                  label="Data de Nascimento"
                />
              </div>

              <div class="selectinput">
                <RgInput
                  id="mother-name"
                  v-model="form.pes_mae"
                  label="Nome da Mãe"
                  :disabled="hasDocument || hasRecordNumber"
                  :class="{ disable: hasDocument || hasRecordNumber }"
                  placeholder="Digite o nome da mãe do paciente"
                />
              </div>

              <div class="selectinput">
                <RgSuggestSmartPersonDocument
                  id="person-document"
                  v-model="personDocument"
                  :rules="{ forceSelection: true }"
                  :disabled="hasInfoPatients || hasRecordNumber"
                  :class="{ disable: hasInfoPatients || hasRecordNumber }"
                  @selected="selectingPatientDocument"
                />
              </div>

              <div class="selectinput">
                <RgSuggestSmartPersonRecord
                  id="person-record"
                  v-model="personRecord"
                  :rules="{ forceSelection: true }"
                  :disabled="hasInfoPatients || hasDocument"
                  :class="{ disable: hasInfoPatients || hasDocument }"
                  @selected="selectingPatientRecord"
                />
              </div>
            </RgValidatorForm>
          </div>

          <div class="footer-filter">
            <RgCleanButton
              id="clear-btn"
              type="button"
              class="space"
              @click="cleanForm"
            />
            <RgSearchButton
              id="search-btn"
              ref="btnSubmit"
              v-shortkey="['enter']"
              type="button"
              data-id="buscar"
              title="Buscar"
              @shortkey.native="searchByClick"
              @submit="searchByClick"
            />
          </div>
        </div>

        <div ref="searchArea" class="search">
          <h1 class="title">Resultados da Busca</h1>

          <div v-show="mutableData.length === 0" class="no-result">
            <IconEmpty class="empty-icon" />
            <span class="info">
              Não foram encontrados resultados para essa busca
            </span>
          </div>

          <RgList
            v-show="mutableData.length > 0"
            ref="rgList"
            v-model="mutableData"
            class="list"
            :search-function="searchFunction"
            :build-filter="generateFilter"
            :show-total="true"
            :register-per-page="itemPerPage"
            :max-register="34"
          >
            <RgTable ref="rgTable" :columns="COLUMNS" class="rg-table">
              <tr
                v-for="item in mutableData"
                :key="item.pes_id"
                slot="rows"
                class="tr"
                :class="{
                  selected: selectRow && item.pes_id === selectRow.pes_id,
                }"
                @click="setSelectRow(item)"
              >
                <td>{{ item.pes_nome }}</td>
                <td>
                  {{ $utils.date.BrazilianDateFormat(item.pes_nascimento) }}
                </td>
                <td>{{ item.pes_mae }}</td>
              </tr>
            </RgTable>
          </RgList>

          <div class="footer-paginate">
            <MediumButton
              v-show="mutableData.length > 0"
              id="selection-btn"
              title="Selecionar"
              :disabled="disableSelectButton"
              label="Selecionar"
              @click="emitSelection"
            />
          </div>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgValidatorForm,
  RgInput,
  RgInputDate,
  IconEmpty,
  MediumButton,
  RgInputSmartPersonName,
} from "~tokio/primitive";

import { RgCleanButton, RgSearchButton } from "~tokio/primitive/button";
import { RgList, RgTable } from "~tokio/foundation";

import RgSuggestSmartPersonRecord from "~tokio/primitive/suggest/rg-suggest-smart-person-record/RgSuggestSmartPersonRecord";
import RgSuggestSmartPersonDocument from "~tokio/primitive/suggest/rg-suggest-smart-person-document/RgSuggestSmartPersonDocument";
import { AlertError } from "~tokio/primitive/notification";

const FORM = {
  pes_id: null,
  pes_nome: "",
  pes_nome_social: "",
  pes_mae: "",
  pes_nascimento: "",
};

export default {
  name: "ModalAdvancedPatientSearch",
  components: {
    RgBaseModal,
    RgList,
    RgTable,
    RgInput,
    RgInputDate,
    RgCleanButton,
    RgSearchButton,
    RgValidatorForm,
    MediumButton,
    IconEmpty,
    RgSuggestSmartPersonRecord,
    RgSuggestSmartPersonDocument,
    RgInputSmartPersonName,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Busca Avançada de Pacientes",
    },
    withPatientModule: {
      type: Array,
      default: function () {
        return [];
      },
    },
    extraData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    enabledPatient: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableData: [],
      selectRow: null,
      personName: null,
      personNameType: "name",
      personDocument: null,
      personRecord: null,
      hasRecordNumber: false,
      hasDocument: false,
      itemPerPage: 14,
      submitClicked: false,
    };
  },

  computed: {
    withPatientAppointment() {
      return this.withPatientModule.includes("appointment");
    },
    withPatientExam() {
      return this.withPatientModule.includes("exam2");
    },
    withPatientHospitalization() {
      return this.withPatientModule.includes("hospitalization");
    },
    withPatientEmergency() {
      return this.withPatientModule.includes("emergency");
    },
    withTelephone() {
      return this.extraData.includes("telephone");
    },
    withEthnicity() {
      return this.extraData.includes("ethnicity");
    },
    withNaturality() {
      return this.extraData.includes("naturality");
    },
    withNationality() {
      return this.extraData.includes("nationality");
    },
    withoutAddress() {
      return this.extraData.includes("withoutAddress");
    },
    disableSelectButton() {
      return !this.selectRow || !this.selectRow.pes_id;
    },

    hasInfoPatients() {
      return (
        this.personName?.length > 0 ||
        this.form.pes_nascimento?.length > 0 ||
        this.form.pes_mae?.length > 0
      );
    },
  },

  watch: {
    show(pValue) {
      if (pValue) {
        this.calculateItemPerPage();
      }
    },
    personRecord(pValue) {
      const recordNumber = pValue ? pValue.toString() : "";
      if (recordNumber && recordNumber.length > 0) {
        this.hasRecordNumber = true;
      } else {
        this.hasRecordNumber = false;
      }
    },
    personDocument(pValue) {
      if (pValue && pValue.length > 0) {
        this.hasDocument = true;
      } else {
        this.hasDocument = false;
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Paciente", key: "pes_nome" },
      { name: "Data de Nascimento", key: "pes_nascimento" },
      { name: "Nome da Mãe", key: "pes_mae" },
    ];

    this.ITEM_SIZE = 26.5;
    this.RGPAGINATOR = 42;
    this.TABLE_HEADER = 26;
  },

  methods: {
    nameType(pValue) {
      this.personNameType = pValue;
    },

    nameValue(pValue) {
      if (this.personNameType === "name") {
        this.form.pes_nome = pValue;
        this.form.pes_nome_social = "";
      } else if (this.personNameType === "socialName") {
        this.form.pes_nome_social = pValue;
        this.form.pes_nome = "";
      } else {
        this.form.pes_nome = "";
        this.form.pes_nome_social = "";
      }
    },

    selectingPatientDocument(pValue) {
      if (pValue && pValue.source) {
        this.form.pes_id = pValue.source.pes_id;
      } else {
        this.form.pes_id = null;
      }
    },

    selectingPatientRecord(pValue) {
      if (pValue && pValue.source) {
        this.form.pes_id = pValue.source.pes_id;
      } else {
        this.form.pes_id = null;
      }
    },

    calculateItemPerPage() {
      this.$nextTick(() => {
        if (this.$refs.searchArea) {
          const searchAreaHeight = this.$refs.searchArea.offsetHeight;

          this.itemPerPage = Math.floor(
            (searchAreaHeight - this.RGPAGINATOR - this.TABLE_HEADER) /
              this.ITEM_SIZE,
          );
        }
      });
    },

    async searchByClick() {
      this.calculateItemPerPage();
      this.submitClicked = true;
      await this.$refs.rgList.submitForm(true);
    },

    scrollTop() {
      if (this.$refs.rgTable && this.$refs.rgTable.$refs.tbody) {
        this.$refs.rgTable.$refs.tbody.scrollTop = 0;
      }
    },

    async searchFunction(pData) {
      try {
        const validateForm = await this.isFormValid();

        if (this.submitClicked === true) {
          this.$refs.btnSubmit.actionSubmit();

          if (!validateForm) {
            this.$toaster.warning("Verifique os campos");
            this.$refs.btnSubmit.fail();
            return false;
          }

          const data = await this.$store.dispatch(
            "Person/Patient/SEARCH_PERSON",
            pData,
          );

          this.$nextTick(() => {
            this.scrollTop();
          });

          this.$refs.btnSubmit.actionDone();
          this.selectRow = false;

          return data;
        }
      } catch (pErr) {
        this.mutableData = [];
        this.$toaster.warning(
          "Não foi possível realizar a busca com os dados informados ou nenhum resultado foi encontrado",
        );
        if (this.$refs.btnSubmit) {
          this.$refs.btnSubmit.fail();
        }
      } finally {
        this.$loader.finish();
      }
    },

    generateFilter() {
      const {
        pes_nascimento,
        pes_mae,
        pes_nome,
        pes_nome_social,
        pes_id,
      } = this.form;

      return {
        peopleName: pes_nome,
        peopleSocialName: pes_nome_social,
        birthDate: pes_nascimento
          ? this.$utils.date.BrazilianDateToDatabase(pes_nascimento)
          : "",
        pes_mae,
        pes_id,
        withPatientAppointment: this.withPatientAppointment,
        withPatientExam: this.withPatientExam,
        withPatientHospitalization: this.withPatientHospitalization,
        withPatientEmergency: this.withPatientEmergency,
        withTelephone: this.withTelephone,
        withEthnicity: this.withEthnicity,
        withNaturality: this.withNaturality,
        withNationality: this.withNationality,
        withoutAddress: this.withoutAddress,
        isSearchable: this.enabledPatient,
        active: true,
      };
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    setSelectRow(pData) {
      if (this.selectRow && this.selectRow.pes_id === pData.pes_id) {
        this.selectRow = null;
        return;
      }

      this.selectRow = pData;
    },

    emitSelection() {
      if (this.selectRow && this.selectRow.pes_id) {
        const patientReasonBlocked = this.selectRow.patient
          ?.disqualification_reasons?.mip_nome;
        if (patientReasonBlocked) {
          return AlertError(
            "Paciente inativo. Motivo: " + `${patientReasonBlocked}`,
          );
        } else {
          this.$emit("selected", this.selectRow);
          this.close();
        }
      }
    },

    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.mutableData = [];
      this.selectRow = null;
      this.personName = null;
      this.personNameType = "name";
      this.personDocument = null;
      this.personRecord = null;
      this.hasRecordNumber = false;
      this.hasDocument = false;
      this.submitClicked = false;

      this.scrollTop();
    },

    close() {
      this.submitClicked = false;
      this.$emit("close");
      this.cleanForm();
    },
  },
};
</script>
