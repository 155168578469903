<template lang="html">
  <div
    v-if="show"
    v-shortkey="['enter']"
    class="modal-delete-bulletin"
    @shortkey="
      {
      }
    "
  >
    <form class="form-container" @submit.stop.prevent="actionDeleteBulletin">
      <RgValidatorForm ref="validator">
        <RgBaseModal
          ref="Modal"
          v-shortkey="['esc']"
          :show="show"
          with-close-button
          class="rg-base-modal"
          @close="close"
          @shortkey.native="close"
        >
          <div slot="header" class="header">
            <div class="title">Excluir Boletim</div>
          </div>

          <div slot="body" class="body">
            <div class="informations">
              <PatientType :sex="patientGender" class="patient-type" />

              <div class="area">
                <span class="data">
                  Paciente:
                  <strong>
                    {{ bulletinData.pes_nome }}
                  </strong>
                </span>

                <span class="data">
                  Boletim:
                  <strong>
                    {{ bulletinData.bol_numero }}
                  </strong>
                </span>
              </div>

              <div class="area">
                <span class="data">
                  Unidade de Saúde:
                  <strong>
                    {{ bulletinData.uns_nome }}
                  </strong>
                </span>

                <span class="data">
                  Setor:
                  <strong>
                    {{ bulletinData.set_nome }}
                  </strong>
                </span>
              </div>
            </div>

            <div class="texts-area">
              <RgTextArea
                id="reason-mdb"
                ref="textArea"
                v-model="reason"
                :rules="{ required: true }"
                :maxlength="255"
                class="area"
                label="Motivo"
              />
            </div>
          </div>

          <div slot="footer" class="footer">
            <RgCancelButton medium @click="close" />
            <RgLessButton
              :disabled="disableDeleteButton"
              large
              @click="actionDeleteBulletin"
            />
          </div>
        </RgBaseModal>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import {
  RgValidatorForm,
  RgBaseModal,
  RgCancelButton,
  RgLessButton,
  RgTextArea,
} from "~tokio/primitive";

import PatientType from "$person/patient/component/icon/PatientType";

export default {
  name: "ModalDeleteBulletin",
  components: {
    RgValidatorForm,
    RgBaseModal,
    RgCancelButton,
    RgLessButton,
    RgTextArea,
    PatientType,
  },
  props: {
    show: Boolean,
    bulletinData: {
      type: Object,
    },
  },
  data() {
    return {
      reason: null,
      patientGender: null,
      disableDeleteButton: true,
    };
  },
  watch: {
    bulletinData(bulletinData) {
      this.patientGender = this.bulletinData.pep_sexo
        ? this.bulletinData.pep_sexo.toUpperCase() === "M"
          ? 1
          : this.bulletinData.pep_sexo.toUpperCase() === "F"
          ? 2
          : null
        : null;
    },
    reason(pValue) {
      pValue?.length < 5
        ? (this.disableDeleteButton = true)
        : (this.disableDeleteButton = false);
    },
  },
  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async actionDeleteBulletin() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        this.$loader.start();

        await this.$store.dispatch("Emergency/Bulletin/REMOVE_BULLETIN", {
          intIdBoletim: this.bulletinData.bol_id,
          strMotivo: this.reason,
        });

        this.$toaster.success("Boletim removido com sucesso");
        this.close();
        this.$emit("reSearch");
      } catch (pErr) {
        console.log("pErr:", pErr);

        const error = pErr.toString();
        if (error.includes("Error:")) {
          this.$toaster.error(
            error.substring(6, error.length),
            "Erro ao excluir boletim",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao excluir boletim");
        }
      } finally {
        this.$loader.finish();
      }
    },

    cleanReason() {
      this.reason = null;
      this.$refs.textArea.resetCharacter();
    },

    close() {
      this.cleanReason();
      this.$emit("close");
    },
  },
};
</script>
