<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchDocumentType from "~tokio/primitive/combobox/rg-combobox-document-type/action/SearchDocumentTypes";

export default {
  name: "RgComboboxDocumentType",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Tipo de Documento",
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const data = await SearchDocumentType();

      data.forEach((item) => {
        if (item.doc_id !== 43 && item.doc_id !== 34) {
          this.mData.push({ value: item.doc_id, text: item.doc_nome });
        }
      });
    },
  },
};
</script>
