import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { DeepCopy } from "~utils/object";
import { BrazilianDateFormat } from "~utils/date";

const query = gql`
  query GetPatientByPesIdForUnify($pesId: Int) {
    people(pes_id: $pesId) {
      rows {
        pes_id
        pes_ativo
        pes_nome
        pes_nascimento
        pes_mae
        pes_id_sexos
        cns {
          crs_id
          crs_id_tipos_documento
          crs_numero
        }
        cpf {
          cpf_id
          cpf_id_tipos_documento
          cpf_numero
        }
        pisPasep {
          pis_id
          pis_id_tipos_documento
          pis_numero
        }
        patient {
          pac_id
          pac_prontuario_unico
          record_numbers {
            rows {
              ppr_id
              ppr_id_pacientes
              ppr_id_unidades_saude
              ppr_numero
            }
          }
        }
      }
    }
  }
`;

export default async ({ commit, rootGetters }, { pesId = null }) => {
  try {
    const variables = { pesId };

    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const response = DeepCopy(data.people.rows[0]);

    response.pes_nascimento = prepareDate(response.pes_nascimento);

    return response;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};

const prepareDate = (pDate) => {
  return pDate ? BrazilianDateFormat(pDate) : null;
};
