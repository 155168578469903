<template>
  <section class="patient-search">
    <RgSearch
      ref="RgSearch"
      v-model="mutablePatientList"
      :disabled="disabledByModal"
      :build-filter="generateFilter"
      :clear-function="cleanForm"
      :search-function="searchFilter"
      :item-height="74"
      :max-register="20"
      result-title="Cadastros de Pacientes"
      @viewClickPagination="actionCleanSelection"
      @afterPerformSearch="actionCleanSelection"
    >
      <div slot="filters" class="patient-search-filter">
        <div class="grid">
          <div class="selectinput">
            <RgInput
              id="paciente"
              v-model="form.pes_nome"
              :disabled="hasDocument"
              :class="{ disable: hasDocument }"
              label="Paciente"
              class="inputitem"
              placeholder="Digite o nome do paciente"
            />
          </div>

          <div class="selectinput">
            <RgInputDate
              id="data-nascimento"
              ref="birthdayDate"
              v-model="form.pes_nascimento"
              :disabled="hasDocument"
              :class="{ disable: hasDocument }"
              label="Data de Nascimento"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgInput
              id="nome-mae"
              v-model="form.pes_mae"
              :disabled="hasDocument"
              :class="{ disable: hasDocument }"
              label="Nome da Mãe"
              class="inputitem"
              placeholder="Digite o nome da mãe do paciente"
            />
          </div>

          <div class="filter-title">
            <span class="title">Documentos</span>
          </div>

          <div class="selectinput">
            <RgInputCpf
              id="cpf"
              v-model="form.cpf"
              :disabled="cpfValidation"
              :class="{ disable: cpfValidation }"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgInputCns
              id="cns"
              v-model="form.crs_numero"
              :disabled="cnsValidation"
              :class="{ disable: cnsValidation }"
              class="inputitem"
              label="CNS"
            />
          </div>

          <div class="selectinput">
            <RgInputMasked
              id="prontuario-unico"
              ref="number"
              v-model="form.recordNumber"
              :mask="['##########']"
              :disabled="recordNumberValidation"
              :class="{ disable: recordNumberValidation }"
              type="number"
              data-id="prontuario"
              label="Prontuário Único"
              class="inputitem"
              title="Número do Prontuário"
              placeholder="Digite o prontuário único"
            />
          </div>

          <div class="selectinput">
            <RgInputMasked
              id="prontuario-unidade"
              ref="number"
              v-model="form.ppr_numero"
              :mask="['###########']"
              :disabled="pprValidation"
              :class="{ disable: pprValidation }"
              type="number"
              data-id="prontuario"
              label="Prontuário da Unidade"
              class="inputitem"
              title="Número da Unidade"
              placeholder="Digite o prontuário da unidade"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período do Cadastro</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="data-inicial"
                v-model="form.initialRegistrationDate"
                label="Data Inicial"
                class="inputitem"
                :disabled="hasDocument"
                :class="{ disable: hasDocument }"
              />
              <RgInputDate
                id="data-final"
                v-model="form.finalRegistrationDate"
                label="Data Final"
                class="inputitem"
                :disabled="hasDocument"
                :class="{ disable: hasDocument }"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="sub-title">Situação</span>
          </div>

          <RgRadioCustom
            id="active-inactive"
            ref="radio"
            :disabled="hasDocument"
            v-bind="propsRadioCustomActive"
            multSelect
            class="radio-status"
            @input="onInputRadioCustomActive"
          />
        </div>
      </div>

      <div slot="search-top" class="top-button">
        <div class="tooltip-info">
          <Tooltip
            message="Cada paciente possui um painel individual de funcionalidades no canto direito da tela"
            startOpen
          >
            <IconLightBulbHelper slot="icon" hollow />
          </Tooltip>
        </div>

        <div class="actions">
          <RgRadioCustom
            id="gender"
            ref="gender"
            :disabled="hasDocument"
            v-bind="propsRadioCustom"
            multSelect
            class="radio-gender"
            @input="onInputRadioCustom"
          />

          <div class="right">
            <RgAddButton
              id="cadastrar-paciente"
              small
              title="Cadastrar Paciente"
              :permission="hasCreatePatientPermission"
              @click="newPatient"
            />

            <RgPersonCardButton
              id="editar-paciente"
              class="edit-button"
              title="Cadastro do Paciente"
              :disabled="!patientSelect"
              :permission="hasViewEditPatientPermission"
              @click="editPatient(selectedPatient)"
            />

            <RgHistoryButton
              id="historico"
              title="Histórico"
              :disabled="!patientSelect"
              :permission="hasHistoryPatientPermission"
              @click="patientCareHistory"
            />

            <RgDropdown
              id="imprimir"
              title="Impressões"
              :item="selectedPatient"
              :disabled="!patientSelect"
              :action-options="itemActionOptionsPrinter(selectedPatient)"
              :permission="hasAnyPrintPermission"
              small
            >
              <IconPrinter slot="icon" />
            </RgDropdown>

            <SmallButton
              v-if="!isPrivateSector"
              id="vincula-recem-nascido"
              title="Vincular Recém-nascido"
              :disabled="!patientSelect"
              :background-color="'#1E88A9'"
              :permission="hasNewBornPatientPermission"
              @click="registerNewborn(selectedPatient)"
            >
              <IconBaby slot="icon" class="icon" />
            </SmallButton>
          </div>
        </div>
      </div>

      <div class="patient-search-result">
        <div ref="patientList" class="patient-list">
          <div
            v-for="(item, index) in mutablePatientList"
            :key="index"
            :class="`${
              index === activeRow ? 'active' : ''
            } column-pcd-${isDeficientClass}`"
            class="patient"
            @click="selectLine(item, index)"
          >
            <div class="type">
              <PatientType :sex="item.gender.sex_id" />
            </div>
            <div class="container-deficient">
              <div
                v-for="(deficient, id) in item.deficient"
                :key="id"
                :title="deficient.dfi_descricao"
                class="deficient"
                :class="{ 'deficient-border': index === activeRow }"
              >
                <CentralizerIcons
                  :iconId="deficient.pde_id_deficiencias"
                  color="#fff"
                  size="24px"
                  margin="0"
                />
              </div>
            </div>
            <div class="info-column">
              <span :title="item.pes_nome">
                Nome: <strong>{{ item.pes_nome }}</strong>
              </span>

              <span :title="item.pes_mae">
                Nome da Mãe: <strong>{{ item.pes_mae }}</strong>
              </span>
            </div>

            <div class="info-column">
              <span>
                Data de Nascimento:
                <strong>{{ birthDateFormat(item) }}</strong>
              </span>

              <span>
                Prontuário Único:
                <strong>{{ renderPatientRecordNumber(item) }}</strong>
              </span>
            </div>

            <div class="info-column">
              <span>
                CPF:
                <strong>{{ cpfFormat(item) }}</strong>
              </span>

              <span>
                CNS:
                <strong>{{ cnsFormat(item) }}</strong>
              </span>
            </div>

            <div class="extra-column">
              <div class="status">
                <Tooltip
                  :class="{ 'show-up': toShowUp(index) }"
                  :show-up="toShowUp(index)"
                  show-left
                  class="tooltip"
                >
                  <PatientStatus
                    slot="icon"
                    class="icon"
                    :active="getStatusPatient(item)"
                    @click="openModalChangeStatusPatient(item)"
                  />
                  <div slot="content" class="content">
                    <strong v-if="hasReason(item)" class="title">
                      Cadastro Inativo
                    </strong>

                    <strong v-else class="title status-active">
                      Cadastro Ativo
                    </strong>

                    <div v-if="hasReason(item)" class="list">
                      <span v-if="hasInactivatedDate(item)" class="item">
                        Data:
                        <strong> {{ hasInactivatedDate(item) }} </strong>
                      </span>
                      <span class="item">
                        Usuário:
                        <strong :title="hasInactivatedUser(item)">
                          {{ hasInactivatedUser(item) }}
                        </strong>
                      </span>
                      <span class="item">
                        Motivo:
                        <strong>
                          {{ disqualificationReasons(item) || "" }}
                        </strong>
                      </span>
                    </div>
                  </div>
                </Tooltip>
              </div>

              <SmallButton
                v-show="disabledActionsGrid(item)"
                title="Gerar Prontuário Único"
                :background-color="'#1E88A9'"
                @click="generateRecordNumber(item.pes_id)"
              >
                <IconMedicalRecord slot="icon" class="icon" />
              </SmallButton>

              <RgPleniIntegrationButton
                v-if="isPrivateSector"
                class="pleni"
                :title="setPleniIntegrationTitle(item)"
                :integration-status="setPleniIntegrationStatus(item)"
                @click="handlePleniIntegration(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </RgSearch>

    <ModalChangeStatusPatient
      :show="modalChangeStatusPatient"
      :patient-info="patientData"
      @close="closeModalChangeStatusPatient"
      @reSearch="reSearch"
    />
  </section>
</template>

<script>
import moment from "moment";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import RgSearch from "~tokio/foundation/rg-search/RgSearch.vue";

import {
  RgInput,
  RgInputDate,
  IconBaby,
  IconMedicalRecord,
  RgPersonCardButton,
  RgInputMasked,
  RgPleniIntegrationButton,
  SmallButton,
  RgDropdown,
  IconPrinter,
  RgAddButton,
  RgHistoryButton,
  Tooltip,
  IconLightBulbHelper,
  RgRadioCustom,
} from "~tokio/primitive";

import CentralizerIcons from "~tokio/primitive/icon/font-awesome/icons/CentralizerIcons";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";

import PatientType from "$person/patient/component/icon/PatientType";
import PatientStatus from "$person/patient/component/icon/patient-status/PatientStatus";

import ModalChangeStatusPatient from "$person/patient/component/modal/modal-change-status-patient/ModalChangeStatusPatient";

export default {
  name: "PatientSearch",
  components: {
    RgDropdown,
    IconPrinter,
    RgSearch,
    RgInput,
    RgInputDate,
    RgInputCpf,
    RgInputCns,
    IconBaby,
    ModalChangeStatusPatient,
    RgPersonCardButton,
    RgInputMasked,
    RgPleniIntegrationButton,
    SmallButton,
    RgAddButton,
    RgHistoryButton,
    PatientType,
    IconMedicalRecord,
    PatientStatus,
    Tooltip,
    IconLightBulbHelper,
    RgRadioCustom,
    CentralizerIcons,
  },
  data() {
    return {
      selectedPatient: {},
      mutablePatientList: [],
      isPrivateSector: false,
      activeRow: null,
      form: {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        genders: null,
        cpf: null,
        crs_numero: null,
        ppr_numero: null,
        recordNumber: null,
        activePatient: null,
        initialRegistrationDate: "",
        finalRegistrationDate: "",
      },
      patientData: {},
      // MODAL
      modalChangeStatusPatient: false,
      isDeficientClass: 0,
    };
  },

  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.form.genders;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomActive() {
      const list = [
        { title: "Ativo", id: 1 },
        { title: "Inativo", id: 0 },
      ];

      const value = this.form.activePatient;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    disabledByModal() {
      return this.modalChangeStatusPatient;
    },
    disableDocuments() {
      return (
        (this.form.pes_nome || this.form.pes_nascimento || this.form.pes_mae)
          .length > 0
      );
    },

    disableOtherInfo() {
      return (
        this.cpf ||
        this.cns ||
        this.unitMedicalRecord ||
        this.singleMedicalRecord
      );
    },

    hasCpf() {
      return this.form.cpf && this.form.cpf.length > 0;
    },

    hasCns() {
      return this.form.crs_numero && this.form.crs_numero.length > 0;
    },

    hasRecordNumber() {
      if (!this.form.recordNumber) return false;
      return this.form.recordNumber !== "" && this.form.recordNumber.length > 0;
    },

    hasPpr() {
      if (!this.form.ppr_numero) return false;
      return this.form.ppr_numero !== "" && this.form.ppr_numero.length > 0;
    },

    pprValidation() {
      return (
        this.hasRecordNumber ||
        this.hasCns ||
        this.hasCpf ||
        this.disableDocuments
      );
    },

    recordNumberValidation() {
      return this.hasPpr || this.hasCns || this.hasCpf || this.disableDocuments;
    },

    cnsValidation() {
      return (
        this.hasPpr ||
        this.hasRecordNumber ||
        this.hasCpf ||
        this.disableDocuments
      );
    },

    cpfValidation() {
      return (
        this.hasPpr ||
        this.hasRecordNumber ||
        this.hasCns ||
        this.disableDocuments
      );
    },

    hasDocument() {
      return this.hasCpf || this.hasCns || this.hasRecordNumber || this.hasPpr;
    },

    hasPatients() {
      return this.mutablePatientList && this.mutablePatientList.length > 0;
    },

    patientSelect() {
      return this.activeRow !== null;
    },

    hasCreatePatientPermission() {
      return !!this.$Permissions.global.has("cadastro.pessoa.incluir");
    },

    hasViewEditPatientPermission() {
      return (
        !!this.$Permissions.global.has("cadastro.pessoa.exibir") ||
        !!this.$Permissions.global.has("cadastro.pessoa.alterar")
      );
    },

    hasHistoryPatientPermission() {
      return !!this.$Permissions.global.has(
        "cadastro.prontuario.historicoGeralPaciente",
      );
    },

    hasPrintCardPatientPermission() {
      return !!this.$Permissions.global.has(
        "cadastro.prontuario.imprimirCarteirinha",
      );
    },

    hasPrintTokenPatientPermission() {
      return !!this.$Permissions.global.has(
        "cadastro.prontuario.imprimirFicha",
      );
    },

    hasAnyPrintPermission() {
      return (
        this.hasPrintCardPatientPermission ||
        this.hasPrintTokenPatientPermission
      );
    },

    hasNewBornPatientPermission() {
      return !!this.$Permissions.global.has(
        "cadastro.pessoa.vincularRecemNascido",
      );
    },

    hasChangeStatusPatientPermission() {
      return !!this.$Permissions.global.has(
        "cadastro.prontuario.habilitarInabilitarPaciente",
      );
    },
  },

  watch: {
    mutablePatientList(pList) {
      if (!pList || pList.length < 1) {
        this.form.genders = null;
        this.form.activePatient = null;

        if (this.$refs.gender) {
          this.$refs.gender.clearRadio();
        }

        if (this.$refs.radio) {
          this.$refs.radio.clearRadio();
        }
      }
    },
  },

  mounted() {
    this.isPrivateSector = this.$store.getters["Login/GET_PRIVATE_SECTOR"];
    this.fillFilter();
  },

  destroyed() {
    const existThisRoute = ValidateIfRouteExistInBreadscrumb(
      "/person/patient/search",
      this.$route.meta.breadcrumb,
    );
    if (!existThisRoute) {
      this.$store.commit("Person/Patient/RESET_FILTER_PATIENT");
    }
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.genders = item;
        this.activeRow = null;
        this.selectedPatient = {};
        this.$refs.RgSearch.submitForm(true);
        this.$loader.finish();
      }
    },
    onInputRadioCustomActive(item) {
      if (item) {
        this.form.activePatient = item;
      }
    },
    async searchFilter(pData) {
      if (this.$refs.patientList) this.$refs.patientList.scrollTop = 0;
      const data = await this.$store.dispatch(
        "Person/Patient/GET_PATIENT_LIST",
        {
          ...pData,
        },
      );
      this.isDeficientClass = 0;
      data.rows.forEach((item) => {
        if (item.deficient && item.deficient !== "") {
          const formatDeficient = item.deficient.replace(/'/g, `"`);
          item.deficient = JSON.parse(formatDeficient);
          this.isDeficientClass =
            this.isDeficientClass < item.deficient.length
              ? item.deficient.length
              : this.isDeficientClass;
        }
      });
      return data;
    },

    prepareGender() {
      const hasValue = this.form.genders !== "";

      return hasValue
        ? this.$utils.app.Genders.GetIdByInitials(this.form.genders)
        : null;
    },

    generateFilter() {
      const genders = this.prepareGender();

      const variables = {
        name: this.form.pes_nome
          ? this.$utils.sanitize.removeExtraSpaces(this.form.pes_nome)
          : null,
        birthday: this.form.pes_nascimento
          ? this.$utils.date.BrazilianDateToDatabase(this.form.pes_nascimento)
          : null,
        mother: this.form.pes_mae
          ? this.$utils.sanitize.removeExtraSpaces(this.form.pes_mae)
          : null,
        cpf: this.form.cpf ? this.form.cpf : null,
        cns: this.form.crs_numero ? this.form.crs_numero : null,
        unitRecordNumber: this.form.ppr_numero
          ? this.removeLeadingZero(this.form.ppr_numero)
          : null,
        recordNumber: this.form.recordNumber ? this.form.recordNumber : null,
        gender: genders,
        isPatient: true,
        active: true,
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        activePatient: this.form.activePatient,
        initialRegistrationDate: this.form.initialRegistrationDate
          ? this.$utils.date.BrazilianDateToDatabase(
              this.form.initialRegistrationDate,
            )
          : null,
        finalRegistrationDate: this.form.finalRegistrationDate
          ? this.$utils.date.BrazilianDateToDatabase(
              this.form.finalRegistrationDate,
            )
          : null,
      };

      this.$store.commit("Person/Patient/SET_FILTER_PATIENT", this.form);

      return variables;
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Person/Patient/GET_FILTER_PATIENT"
        ];

        if (existFilterData) {
          this.form.pes_nome = existFilterData.pes_nome;
          this.form.genders = existFilterData.genders;
          this.form.pes_nascimento = existFilterData.pes_nascimento;
          this.form.pes_mae = existFilterData.pes_mae;
          this.form.cpf = existFilterData.cpf;
          this.form.crs_numero = existFilterData.crs_numero;
          this.form.ppr_numero = existFilterData.ppr_numero;
          this.form.recordNumber = existFilterData.recordNumber;
          this.form.initialRegistrationDate =
            existFilterData.initialRegistrationDate;
          this.form.finalRegistrationDate =
            existFilterData.finalRegistrationDate;

          await this.$refs.RgSearch.performSearch();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      } finally {
        this.$loader.finish();
      }
    },

    async generateRecordNumber(personId) {
      try {
        // this.$loader.start("Gerando prontuário único");

        await this.$store.dispatch("Patient/CREATE_NEW_PATIENT", personId);
        this.$refs.RgSearch.submitForm(false, true);

        this.$toaster.success("Sucesso ao gerar o prontuário");
      } catch (error) {
        this.$toaster.error("Erro ao gerar o prontuário");
      } finally {
        this.$loader.finish();
      }
    },

    removeLeadingZero(pValue) {
      return pValue.replace(/^0+/, "");
    },

    reSearch() {
      this.$refs.RgSearch.submitForm(false, true);
    },

    birthDateFormat(pItem) {
      return moment(pItem?.pes_nascimento, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    cpfFormat(pItem) {
      const cpf = pItem?.cpf?.cpf_numero;

      if (cpf) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/gi, "$1.$2.$3-$4");
      }
    },

    cnsFormat(pItem) {
      const cns = pItem?.cns?.crs_numero;

      if (cns) {
        return cns.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
      }
    },

    // getPatient() {
    //   // this.$loader.start("Carregando pacientes...");
    //   this.activeRow = null;
    //   this.selectedPatient = {};
    //   this.$refs.RgSearch.submitForm(true);
    //   this.$loader.finish();
    // },

    selectLine(pItem, pIndex) {
      const isSameLine = this.selectedPatient.pes_id === pItem.pes_id;

      if (isSameLine) {
        this.activeRow = null;
        this.selectedPatient = {};
      } else {
        this.activeRow = pIndex;
        this.selectedPatient = pItem;
      }
    },

    getStatusPatient(pItem) {
      const patient = pItem && pItem.patient;
      return patient ? patient.pac_bloqueado === false : true;
    },

    disabledActionsGrid(pItem) {
      return !pItem.patient;
    },

    renderPatientRecordNumber(pItem) {
      return pItem.patient && pItem.patient.pac_prontuario_unico
        ? pItem.patient.pac_prontuario_unico
        : "Não informado";
    },

    hasReason(pItem) {
      return (
        pItem.patient &&
        pItem.patient.disqualification_reasons &&
        pItem.patient.disqualification_reasons.mip_nome &&
        pItem.patient.disqualification_reasons.mip_nome.length > 0
      );
    },

    hasInactivatedDate(pItem) {
      return pItem?.patient ? pItem.patient.pac_data_inabilitacao : "";
    },

    hasInactivatedUser(pItem) {
      return pItem?.patient?.inactivated_by
        ? pItem.patient.inactivated_by.usu_nome
        : "";
    },

    disqualificationReasons(pItem) {
      if (this.hasReason) {
        return pItem.patient.disqualification_reasons.mip_nome || "";
      }
    },

    hasUnitMedicalRecord(pItem) {
      const getUnit = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      if (pItem && pItem.patient && pItem.patient.record_numbers) {
        const record = pItem.patient.record_numbers;
        record.rows.forEach((item) => {
          if (item.ppr_id_unidades_saude === getUnit) {
            pItem.patient.record_numbers.rows.ppr_numero = item.ppr_numero;
            return true;
          }
        });
        const result = pItem.patient.record_numbers.rows.ppr_numero;
        return result || "Não informado";
      } else {
        return "Não informado";
      }
    },

    openModalChangeStatusPatient(pItem) {
      if (pItem && !pItem.patient) return;
      if (!this.hasChangeStatusPatientPermission) {
        this.$toaster.warning("Usuário sem permissão para realizar a ação");
        return;
      }

      this.modalChangeStatusPatient = true;
      this.patientData = pItem;
    },

    closeModalChangeStatusPatient() {
      this.modalChangeStatusPatient = false;
      this.patientData = {};
    },

    itemActionOptionsPrinter(pItem) {
      return [
        {
          label: "Imprimir carteirinha",
          action: this.printCard,
          disable: !this.hasPrintCardPatientPermission,
        },
        {
          label: "Imprimir ficha",
          action: this.printToken,
          disable: !this.hasPrintTokenPatientPermission,
        },
      ];
    },

    async printCard(data) {
      try {
        const hasPersonId = data.pes_id && data.pes_id > 0;

        if (hasPersonId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: data.pes_id },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
    },

    async printToken(data) {
      try {
        const hasPersonId = data.pes_id && data.pes_id > 0;
        const unsName = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
        const unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
        if (hasPersonId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_TOKEN",
            { pes_id: data.pes_id, uns_nome: unsName, uns_id: unsId },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error("Erro ao gerar a impressão da ficha do paciente");
      }
    },

    isPatient(pItem) {
      return pItem.patient && pItem.patient.pac_id ? pItem.patient.pac_id : 0;
    },

    toShowUp(pItemIndex) {
      if (this.mutablePatientList.length > 1) {
        const listSize = this.mutablePatientList.length;
        const rest = listSize - 1;

        return this.mutablePatientList.length >= 2 && pItemIndex >= rest;
      }
    },

    patientCareHistory() {
      const patientInfo = this.selectedPatient;
      this.$router.push({
        name: "person.patient.search.patient-care-history",
        params: patientInfo,
      });
    },

    actionCleanSelection() {
      this.selectedPatient = {};
      this.activeRow = null;
    },

    cleanForm() {
      this.form = {
        pes_nome: "",
        pes_nascimento: "",
        pes_mae: "",
        genders: null,
        activePatient: null,
        cpf: null,
        crs_numero: null,
        ppr_numero: null,
        recordNumber: null,
        initialRegistrationDate: "",
        finalRegistrationDate: "",
      };
      this.mutablePatientList = [];
      this.activeRow = null;
      this.selectedPatient = {};

      this.$refs.birthdayDate.cleanValidate();

      if (this.$refs.gender) {
        this.$refs.gender.clearRadio();
      }

      if (this.$refs.radio) {
        this.$refs.radio.clearRadio();
      }
    },

    newPatient() {
      this.$router.push({
        name: "person.patient.details",
      });
    },

    editPatient(pPatient) {
      this.$store.commit("Person/Patient/SET_PERSON_ID", pPatient.pes_id);
      this.$router.push({ name: "person.patient.search.editpatient" });
    },

    registerNewborn(pPatient) {
      this.$store.commit("Person/Patient/SET_PERSON_ID", pPatient.pes_id);
      this.$store.commit("Person/Patient/SET_NEWBORN", true);

      this.$router.push({ name: "person.patient.search.registernewborn" });
    },

    checkIntegrationPleni(pData) {
      const data = Object.assign({}, pData);
      const hasPleniIntegration = data.pleniIntegration !== null;
      return hasPleniIntegration;
    },

    setPleniIntegrationTitle(pItem) {
      const successTitle = "Paciente enviado para o Pleni";
      const warningTitle = "Problemas no envio para o Pleni. Reenviar";
      const withoutIntegrationTitle = "Paciente não enviado para o Pleni";

      const hasPleniIntegration = this.checkIntegrationPleni(pItem);

      if (hasPleniIntegration && pItem.pleniIntegration.ipn_status === 1) {
        return successTitle;
      } else if (
        hasPleniIntegration &&
        pItem.pleniIntegration.ipn_status === 0 &&
        pItem.pleniIntegration.ipn_mensagem_erro.length > 0
      ) {
        return warningTitle;
      }

      return withoutIntegrationTitle;
    },

    setPleniIntegrationStatus(pItem) {
      const status = this.checkIntegrationPleni(pItem)
        ? pItem.pleniIntegration.ipn_status
        : null;

      return status;
    },

    async handlePleniIntegration(pItem) {
      try {
        const personId = pItem.pes_id;
        const withoutIntegration = !this.checkIntegrationPleni(pItem);

        if (pItem.pleniIntegration?.ipn_status === 1) {
          this.$toaster.warning("Paciente já consta no Pleni");
        } else if (withoutIntegration) {
          this.openRegisterPatient(personId);
        } else {
          // this.$loader.start("Reenviando dados para o Pleni");
          await this.resendPatientToLotus(personId);
          this.$refs.RgSearch.submitForm(false, true);
        }
      } catch (error) {
        this.$toaster.error("Erro ao enviar para o Pleni");
      } finally {
        this.$loader.finish();
      }
    },

    async resendPatientToLotus(pPersonId) {
      const response = await this.$store.dispatch(
        "Person/Patient/RESEND_PATIENT_TO_LOTUS",
        { personId: pPersonId },
      );

      const hasSuccess = response && response.success;

      hasSuccess
        ? this.$toaster.success("Sucesso ao enviar para o Pleni")
        : this.$toaster.warning("Falha ao enviar. Favor verifique o cadastro");
    },

    openRegisterPatient(pPersonId) {
      const hash = "#patient-data";

      this.$store.commit("Person/Patient/SET_PERSON_ID", pPersonId);
      this.$router.push({ name: "person.patient.search.editpatient", hash });
    },
  },
};
</script>
