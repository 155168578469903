import GET_LIST_DAYS_SERVICE_BY_PROFESSIONAL from "./GetListDaysServiceByProfessional";
import GET_APPOINTMENTS from "./GetAppointments";
import GET_CONSULTATION_PROCEDURES from "./GetConsultationProcedures";
import GET_PROCEDURES from "./GetProcedures";
import GET_OUTPATIENT_CONTROL_SHEET from "./GetOutpatientControlSheet";
import REMOVE_PROCEDURE from "./RemoveProcedure";
import REGISTER_PROCEDURE from "./RegisterProcedure";
import EDIT_PROCEDURE from "./EditProcedure";
import CHECK_PROCEDURE_HAS_SERVICE_CID from "./CheckProcedureHasServiceCid";
import VALIDATES_REGISTERED_PROCEDURE from "./ValidatesRegisteredProcedure";
import GET_STANDARD_PROCEDURES from "./GetStandardProcedures";

export default {
  GET_LIST_DAYS_SERVICE_BY_PROFESSIONAL,
  GET_APPOINTMENTS,
  GET_PROCEDURES,
  GET_CONSULTATION_PROCEDURES,
  GET_OUTPATIENT_CONTROL_SHEET,
  REMOVE_PROCEDURE,
  REGISTER_PROCEDURE,
  EDIT_PROCEDURE,
  CHECK_PROCEDURE_HAS_SERVICE_CID,
  VALIDATES_REGISTERED_PROCEDURE,
  GET_STANDARD_PROCEDURES,
};
