var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modulebox',{staticClass:"appointment-registration-queue",attrs:{"title":_vm.isEdition ? `Editar Fila` : `Inserir na Fila`}},[_c('div',{staticClass:"appointment-registration-queue-body"},[_c('form',{staticClass:"form-container",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveForm.apply(null, arguments)}}},[_c('RgValidatorForm',{ref:"validator"},[_c('FormBase',{staticClass:"module-patient",attrs:{"title":"Paciente"}},[_c('div',{staticClass:"content-inputs"},[_c('RgSuggestPatientInformation',{ref:"patientInfoSuggest",staticClass:"patient-input",attrs:{"id":"patient","rules":{ forceSelection: true, required: true },"disabledOnlySuggest":_vm.isEdition,"with-patient-module":['appointment'],"pac_prontuario_unico":_vm.patientInfo && _vm.patientInfo.prontuario_unico,"enabled-patient":false,"is-patient":false,"extra-data":['withoutAddress'],"disabled":_vm.disabledByModal,"withPatientAppointment":true},on:{"disabledByModalComponents":_vm.disabledByModalComponents,"patientInfo":_vm.getPatientData},model:{value:(_vm.suggestPatient),callback:function ($$v) {_vm.suggestPatient=$$v},expression:"suggestPatient"}})],1)]),_c('FormBase',{staticClass:"module-regulation",attrs:{"title":"Dados da Regulação"}},[_c('div',{staticClass:"content-grid"},[_c('div',{staticClass:"row-1 content-grid"},[_c('RgInputDate',{class:{ disable: true },attrs:{"id":"entry-date","disabled":true,"tabIndex":"-1","label":"Data de Entrada"},model:{value:(_vm.form.entryDate),callback:function ($$v) {_vm.$set(_vm.form, "entryDate", $$v)},expression:"form.entryDate"}}),_c('RgInputHour',{class:{ disable: true },attrs:{"id":"entry-hour","disabled":true,"tabIndex":"-1","label":"Hora de Entrada"},model:{value:(_vm.form.entryHour),callback:function ($$v) {_vm.$set(_vm.form, "entryHour", $$v)},expression:"form.entryHour"}}),_c('RgSelectUnitHealthLinkedCbo',{ref:"unitInsertion",class:{
                  disable: !_vm.hasIdPatient,
                },attrs:{"id":"unit-insertion","disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"tabIndex":!_vm.hasIdPatient ? '-1' : '0',"rules":{ required: true },"label":"Unidade de Inserção"},on:{"change":_vm.selectingOriginUnit},model:{value:(_vm.form.originUnit),callback:function ($$v) {_vm.$set(_vm.form, "originUnit", $$v)},expression:"form.originUnit"}}),_c('RgSelectOccupationSynonymsUnit',{ref:"cbo",class:{ disable: !_vm.hasIdPatient },attrs:{"id":"occupation","healthUnitId":Number(_vm.form.originUnit),"rules":{ required: true },"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"tabIndex":!_vm.hasIdPatient ? '-1' : '0',"with-bond":"","placeholder":"Informe a ocupação","label":"Ocupação"},on:{"change":_vm.selectingCbo},model:{value:(_vm.occupation),callback:function ($$v) {_vm.occupation=$$v},expression:"occupation"}})],1)])]),_c('FormBase',{staticClass:"module-solicitation",attrs:{"title":"Dados da Solicitação"}},[_c('div',{staticClass:"content-grid"},[_c('RgSuggestEmployee',{ref:"employee",class:{ disable: !_vm.hasIdPatient || _vm.isEdition },attrs:{"id":"employee","label":"Profissional Solicitante","disabled":!_vm.hasIdPatient ||
                _vm.isEdition ||
                _vm.disabledByModal ||
                _vm.disabledByModalComponent,"rules":{
                required: true,
                forceSelection: _vm.enableFreeRequesterEmployee,
              },"maxlength":100,"autoSelectIfHasOne":_vm.enableFreeRequesterEmployee,"placeholder":"Digite o profissional solicitante","notNecessaryBond":"","filterWithoutBond":""},on:{"selected":_vm.selectingProfessional,"clear":function($event){_vm.form.classCouncil = null}},model:{value:(_vm.requestingProfessional),callback:function ($$v) {_vm.requestingProfessional=$$v},expression:"requestingProfessional"}}),_c('RgInput',{class:{
                disable:
                  _vm.disableClassCouncil ||
                  _vm.isEdition ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent,
              },attrs:{"id":"class-council","label":"Conselho de Classe","rules":{ required: !_vm.form.requestingProfessionalId },"disabled":_vm.disableClassCouncil ||
                _vm.isEdition ||
                _vm.disabledByModal ||
                _vm.disabledByModalComponent,"maxlength":20,"placeholder":"Digite o conselho de classe"},model:{value:(_vm.form.classCouncil),callback:function ($$v) {_vm.$set(_vm.form, "classCouncil", $$v)},expression:"form.classCouncil"}}),_c('RgSelectUnithealthLegacy',{ref:"unitSolicitation",class:{ disable: !_vm.hasIdPatient },attrs:{"id":"unit-solicitation","disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"tabIndex":!_vm.hasIdPatient ? '-1' : '0',"rules":{ required: true },"label":"Unidade de Saúde Solicitante"},model:{value:(_vm.form.requesterUnit),callback:function ($$v) {_vm.$set(_vm.form, "requesterUnit", $$v)},expression:"form.requesterUnit"}}),_c('RgSelectPriority',{ref:"priority",staticClass:"inputitem",class:{ disable: !_vm.hasIdPatient },attrs:{"id":"priority","rules":{ required: true },"module-id":2,"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"tabIndex":!_vm.hasIdPatient ? '-1' : '0',"label":"Prioridade"},model:{value:(_vm.form.priority),callback:function ($$v) {_vm.$set(_vm.form, "priority", $$v)},expression:"form.priority"}}),_c('RgInputDate',{ref:"date",class:{
                disable:
                  !_vm.hasIdPatient ||
                  _vm.isEdition ||
                  _vm.disabledByModal ||
                  _vm.disabledByModalComponent,
              },attrs:{"id":"request-date","rules":{ maxTodayDate: true, required: true },"tabIndex":!_vm.hasIdPatient ? '-1' : '0',"disabled":!_vm.hasIdPatient ||
                _vm.isEdition ||
                _vm.disabledByModal ||
                _vm.disabledByModalComponent,"max-date":new Date(),"label":"Data da Solicitação"},model:{value:(_vm.form.requestDate),callback:function ($$v) {_vm.$set(_vm.form, "requestDate", $$v)},expression:"form.requestDate"}}),(_vm.isEdition)?_c('RgInput',{class:{ disable: !_vm.hasIdPatient || _vm.isEdition },attrs:{"id":"name-regulator","label":"Autorizado por","disabled":_vm.isEdition || _vm.disabledByModal || _vm.disabledByModalComponent,"tabIndex":!_vm.hasIdPatient ? '-1' : '0'},model:{value:(_vm.dataLoadAlter.nomeRegulador),callback:function ($$v) {_vm.$set(_vm.dataLoadAlter, "nomeRegulador", $$v)},expression:"dataLoadAlter.nomeRegulador"}}):_vm._e()],1),_c('div',{staticClass:"area",class:{ disable: !_vm.hasIdPatient }},[_c('div',{staticClass:"cbo-area"},[_c('p',{staticClass:"label"},[_vm._v("Ocupações")]),_c('div',{staticClass:"table-area"},_vm._l((_vm.queuesPerPerson),function(queuePerPerson,id){return _c('div',{key:id,staticClass:"item"},[_vm._v(" "+_vm._s(queuePerPerson.nome)+" ")])}),0)]),_c('div',{staticClass:"text-area"},[(!_vm.isEdition)?_c('RgTextArea',{ref:"textArea",staticClass:"inputbox",class:{ disable: !_vm.hasIdPatient },attrs:{"id":"observations","maxlength":500,"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"label":"Observações","is-optional":""},model:{value:(_vm.form.observations),callback:function ($$v) {_vm.$set(_vm.form, "observations", $$v)},expression:"form.observations"}}):_vm._e(),(!_vm.isEdition)?_c('RgTextArea',{ref:"textArea",staticClass:"inputbox",class:{
                  disable: !_vm.hasIdPatient,
                },attrs:{"id":"motivations","maxlength":500,"disabled":!_vm.hasIdPatient || _vm.disabledByModal || _vm.disabledByModalComponent,"label":"Motivo","is-optional":""},model:{value:(_vm.form.motivations),callback:function ($$v) {_vm.$set(_vm.form, "motivations", $$v)},expression:"form.motivations"}}):_vm._e()],1)])])],1)],1)]),_c('div',{staticClass:"footer"},[_c('RgCleanButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEdition),expression:"!isEdition"}],staticClass:"clean",attrs:{"id":"clean-btn","small":"","title":"Limpar","disabled":_vm.disabledByModal || _vm.disabledByModalComponent},on:{"click":_vm.showConfirmFieldClearing}}),(_vm.showBackButton)?_c('RgCancelButton',{ref:"cancelButton",staticClass:"cancel",attrs:{"id":"cancel-btn","medium":"","disabled":_vm.disabledByModal || _vm.disabledByModalComponent},on:{"click":_vm.cancel}}):_vm._e(),_c('RgSaveButton',{staticClass:"save",attrs:{"id":"save-btn","medium":"","disabled":!_vm.hasIdPatient ||
        _vm.disabledSave ||
        _vm.disabledByModal ||
        _vm.disabledByModalComponent},on:{"click":function($event){return _vm.saveForm(false)}}}),(!_vm.isEdition)?_c('MediumButton',{staticClass:"save",attrs:{"id":"save-insert-archive-btn","medium":"","label":"Salvar e inserir arquivo","title":"Salvar e inserir arquivo","disabled":!_vm.hasIdPatient ||
        _vm.disabledSave ||
        _vm.disabledByModal ||
        _vm.disabledByModalComponent},on:{"click":function($event){return _vm.saveForm(true)}}}):_vm._e()],1),_c('ModalConfirmFieldClearing',{ref:"confirmClearing",attrs:{"id":"modal-confirm-clearing","show":_vm.modalConfirmFieldClearing},on:{"getYes":_vm.confirmCleanForm,"getOut":_vm.closeConfirmFieldClearing,"close":_vm.closeConfirmFieldClearing}}),_c('ModalTypePrintInsertQueue',{attrs:{"id":"modal-type-print","show":_vm.modalPrint,"filId":_vm.fil_id},on:{"close":_vm.closeModalPrint}}),_c('ModalPatientImageInRow',{ref:"modalPatientImageInRow",attrs:{"id":"modal-patient-image","show":_vm.modalPatientImageInRow,"fill-id":_vm.fil_id,"modId":_vm.appointmentModuleId},on:{"close":_vm.closeModalPatientImageInRow}}),_c('RgConfirmModal',{attrs:{"id":"modal-confirm","show":_vm.modal.showConfirmModal,"title":"Atenção","message":_vm.modal.msgModalConfirm},on:{"close":function($event){return _vm.handleModal(0)},"confirmed":function($event){return _vm.handleModal(1)},"denied":function($event){return _vm.handleModal(2)}}}),_c('ModalPatientDuplicationQueue',{attrs:{"id":"modal-patient-duplication-queue","show":_vm.modalPatientDuplicationQueue,"module-id":_vm.appointmentModuleId,"patient-name":_vm.patientDuplicationName,"queue-info":_vm.queueDuplicationInfo,"block-duplication":_vm.blockPatientDuplication},on:{"save":_vm.saveDuplication,"close":_vm.closeModalPatientDuplicationQueue,"cancel":_vm.closeModalPatientDuplicationQueue}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }