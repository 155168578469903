<template lang="html">
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.62292 2.60349C4.5749 2.03412 4.67094 1.49906 5.00707 1.01887C5.41866 0.435787 5.97431 0.106516 6.70145 0.0447781C7.08559 0.010479 7.46974 -0.0169602 7.85389 0.0447781C8.38896 0.133956 8.58789 0.737619 8.3478 1.15607C8.19002 1.43046 7.94993 1.54708 7.62752 1.54708C7.32569 1.54708 7.03072 1.51964 6.72888 1.58824C6.43391 1.64997 6.17324 1.93123 6.15952 2.23992C6.1458 2.56919 6.19382 2.90532 6.11836 3.23459C6.04976 3.5227 5.90571 3.72164 5.61759 3.81082C5.3432 3.89313 5.10997 3.87255 4.88359 3.6599C4.69838 3.48154 4.60234 3.28947 4.62292 3.03566C4.63664 2.8916 4.62292 2.74754 4.62292 2.60349Z"
      fill="white"
    />
    <path
      d="M17.3891 15.3832C17.2382 15.3832 17.0805 15.3626 16.9364 15.3832C16.5934 15.445 16.2436 15.0608 16.175 14.8276C16.0858 14.5052 16.2367 14.1073 16.5248 13.9427C16.6757 13.8603 16.8472 13.8398 17.0187 13.8398C17.2039 13.8398 17.3891 13.826 17.5744 13.8398C18.1437 13.8809 18.4456 13.4968 18.473 12.948C18.4867 12.6324 18.4181 12.31 18.521 12.0013C18.6033 11.7475 18.9875 11.5074 19.2413 11.5349C19.6323 11.576 19.9136 11.823 19.9616 12.1454C20.0576 12.8382 20.0439 13.5242 19.7078 14.1553C19.2893 14.9442 18.6171 15.3489 17.7253 15.3764C17.6155 15.3764 17.4989 15.3764 17.3891 15.3764V15.3832Z"
      fill="white"
    />
    <path
      d="M17.203 1.54019C16.8737 1.56077 16.565 1.56077 16.3249 1.25894C16.1328 1.01199 16.1123 0.758172 16.2083 0.49064C16.2975 0.257406 16.4621 0.0859107 16.7296 0.0516116C17.4636 -0.0444257 18.1839 -0.037566 18.8493 0.346584C19.5284 0.730733 19.8783 1.32754 19.9606 2.1027C19.9881 2.39081 20.0086 2.67206 20.0018 2.95331C19.9881 3.52954 19.6588 3.87253 19.158 3.85195C18.815 3.83823 18.4789 3.5021 18.4652 3.15225C18.4515 2.84356 18.5063 2.53486 18.424 2.22617C18.3005 1.73913 18.081 1.55391 17.5871 1.54019C17.4568 1.54019 17.3333 1.54019 17.203 1.54019Z"
      fill="white"
    />
    <path
      d="M12.2718 1.54026C11.7505 1.54026 11.2292 1.51968 10.7147 1.54712C10.4266 1.56084 10.0287 1.14925 10.0013 0.909157C9.94637 0.43583 10.2688 0.07912 10.6049 0.0379612C10.7764 0.0173817 10.9479 0.00366211 11.1194 0.00366211C11.9837 0.00366211 12.8481 0.00366211 13.7124 0.00366211C14.028 0.00366211 14.3367 0.0791201 14.5081 0.387812C14.6385 0.621045 14.6453 0.874858 14.5287 1.11495C14.3984 1.36876 14.1926 1.54026 13.8839 1.54026C13.3488 1.54026 12.8138 1.54026 12.2787 1.54026H12.2718Z"
      fill="white"
    />
    <path
      d="M18.4653 7.69329C18.4653 7.17194 18.479 6.6506 18.4653 6.12925C18.4516 5.78626 18.7466 5.43641 19.1924 5.38839C19.5354 5.34723 19.8853 5.66278 19.9676 6.01949C20.0293 6.30761 20.0019 6.58886 20.0088 6.87011C20.0156 7.60411 20.0225 8.34497 20.0088 9.07897C20.0019 9.56602 19.8235 9.83355 19.4463 9.97075C18.9592 10.1422 18.4584 9.68263 18.4722 9.2779C18.4927 8.7497 18.4722 8.22149 18.4722 7.69329H18.4653Z"
      fill="white"
    />
    <path
      d="M15.3446 6.71948C15.3309 6.18442 15.0977 5.74539 14.7616 5.36124C14.2745 4.81245 13.616 4.62038 12.9094 4.62038C9.42462 4.61352 5.93984 4.61352 2.45505 4.62038C2.02974 4.62038 1.61129 4.67526 1.21342 4.89477C0.445126 5.3338 0.0198172 5.97176 0.00609761 6.85668C-0.00762201 8.67453 0.00609761 10.4924 0.00609761 12.3102C0.00609761 14.1281 0.00609761 15.9459 0.00609761 17.7638C0.00609761 17.9902 0.0403967 18.2028 0.108995 18.4086C0.403967 19.3347 1.32318 20.0069 2.29728 20.0001C5.93984 19.9864 9.5824 20.0001 13.2318 20.0001C13.6503 20.0001 14.0276 19.8423 14.3568 19.6159C15.0702 19.1289 15.3927 18.4155 15.3927 17.5717C15.3721 13.9566 15.4613 10.3415 15.3515 6.71948H15.3446ZM13.8492 17.5237C13.8492 18.1136 13.5268 18.4703 12.9231 18.4635C9.39718 18.4498 5.8781 18.4566 2.35215 18.4566C1.83081 18.4566 1.5427 18.1685 1.5427 17.6334C1.5427 15.8499 1.5427 14.0595 1.5427 12.2759C1.5427 10.5198 1.55642 8.77057 1.53584 7.01445C1.52898 6.47939 1.87883 6.15012 2.40703 6.15012C3.27823 6.15012 4.14942 6.15012 5.02748 6.15012C7.65479 6.15012 10.2821 6.15012 12.9094 6.15012C13.2387 6.15012 13.5474 6.21872 13.7326 6.53427C13.8355 6.70576 13.8561 6.89784 13.8561 7.09677C13.8561 10.5747 13.8561 14.0458 13.8561 17.5237H13.8492Z"
      fill="white"
    />
    <path
      d="M6.93493 13.977C6.93493 13.73 6.92807 13.4831 6.93493 13.2293C6.93493 13.0989 6.89377 13.0715 6.77029 13.0715C6.29697 13.0715 5.82364 13.0715 5.35031 13.0715C5.00732 13.0715 4.65747 12.756 4.61631 12.4404C4.55457 11.9739 4.86327 11.6309 5.21998 11.5761C5.7276 11.5006 6.23523 11.5555 6.74286 11.5418C6.88691 11.5418 6.93493 11.5143 6.92807 11.3565C6.91435 10.8832 6.92807 10.4099 6.92121 9.93657C6.91435 9.60044 7.22304 9.26431 7.65521 9.23001C8.00506 9.20257 8.35491 9.5044 8.41665 9.84739C8.50583 10.3413 8.45095 10.8352 8.45781 11.3223C8.45781 11.5075 8.51269 11.5418 8.68418 11.5418C9.07519 11.528 9.4662 11.528 9.86407 11.5418C10.1728 11.5555 10.4677 11.6104 10.6461 11.9122C10.7833 12.1523 10.7833 12.3992 10.6804 12.6393C10.5569 12.9137 10.3374 13.0784 10.0218 13.0784C9.56224 13.0784 9.10949 13.0784 8.64988 13.0784C8.50583 13.0784 8.45781 13.1058 8.46467 13.2567C8.47839 13.6683 8.48525 14.073 8.46467 14.4846C8.44409 14.9442 8.28631 15.2118 7.90902 15.3558C7.42198 15.541 6.88691 15.054 6.92807 14.6493C6.94865 14.4297 6.92807 14.2034 6.92807 13.977H6.93493Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "DuplicateSheet",
};
</script>
