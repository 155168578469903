<template>
  <div class="appointment-daily-report">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-adr"
          v-model="form.uns_id"
          permission="consulta.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-adr"
          v-model="form.set_id"
          :unit-health="form.uns_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          default-text="Todos"
          class="inputitem"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          id="suggest-occupation-adr"
          v-model="suggestOccupation"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :sectorId="form.set_id"
          :employeeId="professionalBond"
          label="Ocupação"
          class="inputitem"
          @selected="selectingOccupation"
        />
      </div>

      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-adr"
          v-model="form.pes_nome"
          :unitHealthId="form.uns_id && form.uns_id > 0 ? form.uns_id : null"
          :ocuppationId="Number(form.ocp_id)"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          label="Profissional"
          title="Profissional"
          @selected="selectingProfessional"
        />
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="from-queue-adr"
          :value="form.fromQueue"
          :list="ITEMSRADIOFILTER"
          uniqueKey="id"
          class="inputitem"
          label="Agendado pela Fila"
          @input="onInputRadioQueue"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período de Consultas</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-adr"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{
            required: true,
          }"
        />
        <RgInputDate
          id="final-date-adr"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem mg"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          :rules="{ required: true }"
        />
      </div>

      <div class="filter-title">
        <span class="title">Situação da Consulta</span>
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="situation-adr"
          :value="form.situation"
          :list="SITUATION"
          uniqueKey="id"
          class="inputitem"
          reorganize
          @input="onInputRadioCustom"
        />
      </div>

      <div class="selectinput">
        <ComboboxReasonsNotEffective
          id="reason-not-effective-adr"
          v-model="form.reasonNotEffectiveId"
          :disabled="!isNotEffectivated"
          :class="{ disable: !isNotEffectivated }"
          label="Motivo Não Comparecimento"
          class="inputitem"
          default-text="Todos"
        />
      </div>

      <div class="filter-title">
        <span class="title">Ordenação</span>
      </div>

      <div class="selectinput">
        <RgComboboxOrderBy
          id="orderby-adr"
          v-model="form.orderby"
          :data="ORDERS"
          :rules="{ required: true }"
          label="Ordenar por"
          class="inputitem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgInputDate,
  RgSelectUnithealth,
  RgSelectSector,
  RgSuggestEmployee,
  RgRadioCustom,
  RgSuggestCbo,
  RgComboboxOrderBy,
} from "~tokio/primitive";
import ComboboxReasonsNotEffective from "$appointment/submodules/schedule/component/combobox/combobox-reasons-not-effective/ComboboxReasonsNotEffective";
const moment = require("moment");

export default {
  name: "AppointmentDailyReport",
  components: {
    RgInputDate,
    RgSelectUnithealth,
    RgSelectSector,
    RgSuggestEmployee,
    RgRadioCustom,
    ComboboxReasonsNotEffective,
    RgSuggestCbo,
    RgComboboxOrderBy,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isRequired: false,
      resultTitle: "Consultas por Dia",
      form: {
        initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        uns_nome: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        set_id: null,
        ocp_nome: null,
        ocp_id: null,
        pes_id: null,
        pes_nome: "",
        fromQueue: null,
        situation: null,
        reasonNotEffectiveId: null,
        set_nome: "",
        mne_nome: "",
        orderby: 1,
        orderByLabel: "Data e Hora",
      },
      suggestOccupation: null,
      professionalBond: null,
      legend: "",
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; }" +
        ".report-column-3 { white-space: normal !important; }" +
        ".report-column-4 { white-space: normal !important; }" +
        ".report-column-8 { white-space: normal !important; }" +
        ".report-column-9 { white-space: normal !important; }" +
        ".report-column-11 { white-space: normal !important; }" +
        ".report-column-14 { white-space: normal !important; }" +
        ".report-column-15 { white-space: normal !important; }"
      );
    },

    columnTable() {
      return this.COLUMN;
    },

    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },

    hasSector() {
      return this.form.set_id > 0;
    },

    isNotEffectivated() {
      return this.form.situation && this.form.situation === 3;
    },

    getNameSmartTable() {
      return "AppointmentDailyReport";
    },

    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },

    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },

    getLegend() {
      return this.legend;
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
        this.form.set_nome = null;

        this.suggestOccupation = null;
      } else if (pValue[0]) {
        this.form.uns_nome = pValue[0].text;
      }
    },

    "form.ocp_id"(pValue, pPrev) {
      if (!pValue && !this.form.pes_id) {
        this.professionalBond = null;
      }
    },

    "form.pes_id"(pValue, pPrev) {
      if (!pValue && !this.form.ocp_id) {
        this.professionalBond = null;
      }
    },

    "form.reasonNotEffectiveId"(pValue, pPrev) {
      if (!pValue) {
        this.form.mne_nome = null;
      }

      if (pValue && typeof pValue === "object") {
        this.form.mne_nome = pValue[0].text;
      }
    },

    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Consulta", key: "DATA_HORA_CONSULTA" },
      { name: "Prontuário Único", key: "PRONTUARIO_UNICO", align: "left" },
      {
        name: "Prontuário da Unidade",
        key: "PRONTUARIO_UNIDADE",
        align: "left",
      },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Sexo", key: "SEXO" },
      { name: "Idade", key: "IDADE" },
      { name: "CNS ou CPF", key: "CNS_OU_CPF", align: "left" },
      {
        name: "Profissional de Saúde",
        key: "PROFISSIONAL_DE_SAUDE",
        align: "left",
      },

      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Objetivo", key: "OBJETIVO", align: "left" },
      { name: "Veio da Fila", key: "FILA" },
      { name: "Situação", key: "SITUACAO", align: "left" },
      {
        name: "Motivo não Comparecimento",
        key: "MOTIVO_NAO_COMPARECIMENTO",
        align: "left",
      },
      { name: "Marcação", key: "MARCACAO" },
      { name: "Telefones", key: "TELEFONES" },
    ];

    this.SITUATION = [
      { title: "Em Aberto", color: "#FFD42A", id: 1 },
      { title: "Efetivada", color: "#03CE63", id: 2 },
      { title: "Não Efetivada", red: "#F96B70", id: 3 },
    ];
    this.ITEMSRADIOFILTER = [
      { title: "Sim", blue: true, id: 1 },
      { title: "Não", blue: true, id: 2 },
    ];

    this.ORDERS = [
      { value: 1, text: "Data e Hora" },
      { value: 2, text: "Setor" },
      { value: 3, text: "Profissional" },
      { value: 4, text: "Paciente" },
    ];
  },

  mounted() {},

  methods: {
    buildFilter() {
      this.mountLegend();

      return {
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
        uns_id: this.form.uns_id,
        set_id: this.form.set_id,
        ocp_id: Number(this.form.ocp_id),
        pes_id: this.form.pes_id,
        fromQueue: this.form.fromQueue,
        situation: this.form.situation,
        mne_id: Number(this.form.reasonNotEffectiveId),
        ocp_nome: this.form.ocp_nome,
        pes_nome: this.form.pes_nome,
        set_nome: this.form.set_nome,
        mne_nome: this.form.mne_nome,
        orderby: this.form.orderby,
        orderByLabel: this.form.orderByLabel,
        columnsToPrint: this.columnsToPrint,
      };
    },

    mountLegend() {
      this.legend = this.form.uns_nome;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_REPORT",
          pData,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {
      this.form = {
        initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        uns_nome: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        set_id: null,
        ocp_nome: null,
        ocp_id: null,
        pes_id: null,
        pes_nome: "",
        fromQueue: null,
        situation: null,
        reasonNotEffectiveId: null,
        set_nome: "",
        mne_nome: "",
        orderby: 1,
        orderByLabel: "Data e Hora",
      };
      this.professionalBond = null;
      this.suggestOccupation = "";
    },

    selectingOccupation(pValue) {
      const source = pValue?.source;
      if (source) {
        this.form.ocp_id = source.ocp_id;
        this.form.ocp_nome = source.ocp_nome;
      } else {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
        this.form.pes_nome = null;
      }
    },

    selectingProfessional(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.pes_id = pValue.source.pes_id;
        this.form.pes_nome = pValue.source.pes_nome;
        this.professionalBond =
          pValue.source?.employeeRelationship?.vin_id_funcionarios;
      } else {
        this.form.pes_id = 0;
        this.form.pes_nome = null;
        this.professionalBond = null;
      }
    },

    onInputRadioCustom(pRadio) {
      if (pRadio) {
        this.form.situation = pRadio.id;
      } else {
        this.form.situation = null;
      }
      this.form.reasonNotEffectiveId = null;
    },
    onInputRadioQueue(pRadio) {
      if (pRadio) {
        this.form.fromQueue = pRadio.id;
      } else {
        this.form.fromQueue = null;
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
    },
  },
};
</script>
