var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rg-contact-list--component scrollstyle"},[(_vm.contactList.length === 0)?_c('div',{staticClass:"rg-contact-list--no-content"},[_c('div',{staticClass:"rg-contact-list--empty-icon-container"},[_c('IconEmpty',{staticClass:"rg-contact-list--empty-icon"})],1),_c('span',{staticClass:"rg-contact-list--empty-caption"},[_vm._v(" Essa pessoa ainda não possui um contato cadastrado ")])]):_vm._e(),_c('ul',{staticClass:"rg-contact-list--list-container"},_vm._l((_vm.contactList),function(contactItem,contactItemIdx){return _c('li',{key:contactItemIdx},[_c('ul',{staticClass:"rg-contact-list--list"},[_c('li',{staticClass:"rg-contact-list--list__icon"},[(
              _vm.myEsusInfo.mes_ddd_celular ===
              `${contactItem.ddd.ddd_codigo}${contactItem.tel_numero}`
            )?_c('IconMeuEsus',{staticClass:"rg-contact-list--icon"}):_vm._e(),(
              _vm.myEsusInfo.mes_ddd_celular !==
              `${contactItem.ddd.ddd_codigo}${contactItem.tel_numero}`
            )?_c('IconTelephone',{staticClass:"rg-contact-list--icon"}):_vm._e()],1),_c('li',{staticClass:"list--phone-number"},[_c('span',[_vm._v("("+_vm._s(contactItem.ddd.ddd_codigo)+") "+_vm._s(contactItem.tel_numero))])]),_c('li',{staticClass:"list--message"},[_c('span',[_vm._v(_vm._s(contactItem.tel_recado))])]),_c('ContactOptions',{attrs:{"contact-info":contactItem,"contact-item-index":contactItemIdx},on:{"suspend-item":_vm.suspendItem}})],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }