<template>
  <ModuleBox title="Cadastrar Internação" class="hospitalization-details">
    <QuickHospitalizationDetails
      ref="hospDetails"
      class="hospitalization-details-quick"
      :fromRegulation="true"
      @disabledByModalComponents="disabledByModalComponents"
      @blockedPatient="enableDisableSave($event)"
      @close="goBack"
    />

    <div class="footer">
      <RgCleanButton
        v-show="!hospitalization_id"
        id="clean-btn"
        small
        type="button"
        title="Limpar"
        class="buttons"
        :disabled="disabledByModal"
        @click="clearForm"
      />

      <RgCancelButton
        id="cancel-btn"
        medium
        class="buttons"
        type="button"
        title="Cancelar"
        :disabled="disabledByModal"
        @click="goBack"
      />

      <RgSaveButton
        id="save-btn"
        :class="{ disable: isSaving || isBlocked }"
        :disabled="isSaving || isBlocked || disabledByModal"
        medium
        class="buttons"
        type="button"
        @click="saveForm"
      />
    </div>
  </ModuleBox>
</template>

<script>
import { QuickHospitalizationDetails } from "../../../component";
import { RgCleanButton, RgSaveButton, RgCancelButton } from "~tokio/primitive";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";

export default {
  name: "HospitalizationRegisterScheduleQueue",
  components: {
    QuickHospitalizationDetails,
    ModuleBox,
    RgCleanButton,
    RgSaveButton,
    RgCancelButton,
  },
  data() {
    return {
      hospitalization_id: null,
      patient_id: null,
      hospitalizationData: {},
      isSaving: false,
      operation: 0,
      isBlocked: false,
      disabledByModal: false,
    };
  },

  mounted() {
    let pData = this.$route.params.form;

    if (!pData) {
      pData = this.$store.getters[
        "Hospitalization/Hospitalizations/GET_HOSPITALIZATION_INFO"
      ];
    }

    this.mountPage(pData);
  },
  methods: {
    disabledByModalComponents(disabledByModalComponent) {
      this.disabledByModal = disabledByModalComponent;
    },

    mountPage(pData) {
      if (pData.int_id) {
        this.hospitalization_id = pData.int_id;
      }
    },

    async saveForm() {
      this.isSaving = true;
      await this.$refs.hospDetails.saveForm();
      this.isSaving = false;
    },

    enableDisableSave(pValue) {
      this.isBlocked = pValue;
    },

    clearForm() {
      this.$refs.hospDetails.confirmCleanForm();
      this.hospitalization_id = null;
      this.$store.commit(
        "Hospitalization/Hospitalizations/RESET_HOSPITALIZATION_INFO",
      );
    },

    goBack() {
      this.$router.push("/hospitalization/queue");
    },
  },
};
</script>
