import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const duplicationErrors = {
  BloquearDuplicidadeAoInserirFilaExameException: `Paciente já consta na fila para esse procedimento.`,
  InformarDuplicidadeAoInserirFilaExameException: `Aviso: paciente já consta na fila para esse procedimento`,
};

export default async (
  context,
  intIdAgendamento,
  idModulo,
  voltarPraFila,
  validarDuplicidade,
) => {
  return AuthLegacyRequest.post(
    "tabelas/controller-agendamento-externo/excluir",
    intIdAgendamento,
    idModulo,
    voltarPraFila,
    validarDuplicidade,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: duplicationErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
