import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import HospitalizationDetails from "$hospitalization/hospitalizations/view/details/HospitalizationDetails.vue";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/hospitalization/hospitalizations/search/details",
  name: "hospitalization.hospitalizations.search.details",
  components: {
    default: HospitalizationDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Internações", link: OpenSubModule("hospitalization") },
      {
        label: "Lista de Internações",
        link: "/hospitalization/hospitalizations/search",
      },
      { label: "Cadastrar Internação" },
    ],
  },
};
