import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyCentralAverageConsumptionReport(
    $unitHealth: Int
    $pharmacy: Int
    $product: Int
    $mtp_id: Int
    $mtp_nome: String
    $productName: String
    $far_nome: String
    $year: String
    $month: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyCentralAverageConsumptionReport(
      uns_id: $unitHealth
      far_id: $pharmacy
      mpd_id: $product
      mtp_id: $mtp_id
      mtp_nome: $mtp_nome
      productName: $productName
      far_nome: $far_nome
      year: $year
      month: $month
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        DESCRICAO
        NOME_COMERCIAL
        TIPO
        COLUNAS_POR_MES {
          title
          value
        }
        CONSUMO_MEDIO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyCentralAverageConsumptionReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
