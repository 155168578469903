import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation ExportBilling($exportBilling: ExportBillingInputType) {
    ExportBilling(exportBilling: $exportBilling) {
      fex_id_usuarios
      fex_data_hora
      fex_arquivo_gerado
      fex_id_modulos
      fex_competencia
      fex_cnes
      fex_id_unidades_saude
      fex_quantidade_folha
      fex_inicio_bpac
      fex_inicio_bpai
      file
      fileName
    }
  }
`;

export default async ({ commit }, { variables }) => {
  const { data } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.ExportBilling;
};
