<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchProceduresLinked from "./action/SearchProceduresLinked";

export default {
  name: "RgSelectProceduresLinked",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Procedimento",
    },
    submoduleId: {
      required: true,
    },
  },
  data() {
    return {
      procedures: [],
    };
  },

  watch: {
    procedures: function (val) {
      this.updateOptions();
    },
  },

  mounted() {
    this.searchProcedures();
  },

  methods: {
    getData() {
      return this.procedures;
    },
    async searchProcedures() {
      const data = await SearchProceduresLinked(this.submoduleId);

      this.procedures = data.map((item) => {
        return { value: item.data, label: item.label };
      });
    },
  },
};
</script>
