<template slot="subFilters">
  <div class="pharmacy-central-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
        />
        <RgComboboxMonth
          v-model="form.month"
          class="col-4"
          label="Mês"
          :rules="{ required: true }"
          :style="'min-width: 100%;'"
        />
        <div class="div-year">
          <RgInput
            id="set-year"
            v-model="form.year"
            class="input-number"
            :rules="{ required: true }"
            label="Ano"
            :maxlength="4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgComboboxMonth from "$pharmacy/common/combobox/rg-combobox-month/RgComboboxMonth";
import { RgInput } from "~tokio/primitive";

const FORM_FILTER = {
  productTypeId: null,
  lotAndExpiration: null,
};

export default {
  name: "PharmacyCentralAverageConsumptionReport",
  components: {
    RgSelectProductType,
    RgComboboxMonth,
    RgInput,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      document: null,
      disabledDocument: false,
      pharmacyId: null,
      productId: null,
      columnsMonths: [],
      currentColumnsTable: [],
      columnsTableSon: [
        { name: "Codigo", key: "CODIGO", align: "right" },
        { name: "Descrição", key: "DESCRICAO", align: "left" },
        { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
        { name: "Tipo", key: "TIPO", align: "left" },
      ],
    };
  },

  watch: {
    globalFilters: {
      handler(pValue) {
        this.pharmacyId = pValue.pharmacy;
        this.productId = pValue.product;
      },
      deep: true,
    },
  },

  mounted() {
    const today = new Date();
    this.form.year = today.getFullYear();
    this.columnsTable(this.columnsTableSon);
  },

  methods: {
    async doSearchSubReport(pValue) {
      try {
        this.form.year = this.form.year.toString();
        const filters = {
          ...this.globalFilters,
          ...this.form,
          ...pValue,
        };
        filters.pharmacy = filters.pharmacy ? Number(filters.pharmacy) : null;
        filters.product = filters.product ? Number(filters.product) : null;
        filters.mtp_id = filters.productTypeId
          ? Number(filters.productTypeId)
          : null;
        this.columnsTableSon =
          this.columnsMonths.length > 0 ? [] : this.columnsTableSon;
        this.columnsTableSon =
          this.columnsMonths.length > 0
            ? [
                { name: "Codigo", key: "CODIGO" },
                { name: "Descrição", key: "DESCRICAO", align: "left" },
                {
                  name: "Nome Comercial",
                  key: "NOME_COMERCIAL",
                  align: "left",
                },
                { name: "Tipo", key: "TIPO" },
              ]
            : this.columnsTableSon;
        this.columnsMonths = [];
        filters.columnsToPrint = this.columnsToPrint;
        if (pValue.toPrint || pValue.toCsv || pValue.toExcel) {
          this.$loader.start("Carregando...");
        }
        const result = await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CENTRAL_AVERAGE_CONSUMPTION",
          filters,
        );
        if (!result.rows || result.rows.length === 0) {
          return result;
        }
        if (pValue.toPrint || pValue.toCsv || pValue.toExcel) {
          this.$loader.finish();
          return result;
        }
        result.rows[0].COLUNAS_POR_MES.forEach(async (element) => {
          const aux = {
            name: element.title,
            key: element.title,
            align: "right",
          };
          this.columnsTableSon.push(aux);
          if (this.columnsTableSon.length > this.columnsMonths.length) {
            this.columnsMonths.push(aux);
          }
        });
        const aux = {
          name: "Consumo Médio",
          key: "CONSUMO_MEDIO",
          align: "right",
        };
        this.columnsTableSon.push(aux);
        result.rows.forEach((i) => {
          i.COLUNAS_POR_MES.forEach((j) => {
            i[`${j.title}`] = j.value;
          });
          delete i.COLUNAS_POR_MES;
        });
        this.columnsTable(this.columnsTableSon);
        return result;
      } catch (Err) {
        this.$loader.finish();
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.document = null;
      this.disabledDocument = false;
    },
  },
};
</script>
