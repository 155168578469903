<template>
  <section class="favorite-operation-container">
    <div class="header">
      <h3 class="title">Meus Favoritos</h3>

      <SmallHollowButton
        v-if="favoriteList.length > 0"
        class="delete"
        title="Remover favorito"
        :borderColor="'#F96B70'"
        :class="{ isActive: removeMode }"
        @click="removeFavorite"
      >
        <IconClose slot="icon" :color="'#F96B70'" class="icon" />
      </SmallHollowButton>

      <SmallHollowButton
        class="favorite"
        title="Adicionar favorito"
        @click="newFavorite"
      >
        <IconAdd slot="icon" :color="'#1E88A9'" class="icon" />
      </SmallHollowButton>
    </div>

    <div ref="modulesArea" class="favorite-list">
      <div
        v-for="item in favoriteList"
        v-show="favoriteList.length > 0"
        :key="item.opf_id"
        class="list"
      >
        <p class="module from unselect">
          {{ item.opf_modulos }}
        </p>

        <button
          class="button-list unselect"
          :class="{
            canRemove: removeMode,
            noPermission: !removeMode && !hasPermission(item.opf_permissao),
          }"
          :disabled="!removeMode && !hasPermission(item.opf_permissao)"
          :title="
            !removeMode && !hasPermission(item.opf_permissao)
              ? 'Usuário sem permissão.'
              : item.opf_nome_rota
          "
          @click.stop.prevent="go(item)"
        >
          <component :is="item.opf_nome_icone" class="icon" />
          <p class="text">
            {{ item.opf_nome_rota }}
          </p>
        </button>
      </div>

      <div v-show="favoriteList.length <= 0" class="empty">
        <IconEmpty class="svg" />
        <span>Não foram encontradas operações favoritas</span>
      </div>
    </div>
    <MainMenu ref="mainMenu" class="hide" />
  </section>
</template>

<script>
import {
  SmallHollowButton,
  IconClose,
  IconEmpty,
  IconAdd,
} from "~tokio/primitive";
import * as Icons from "~tokio/primitive/icon/modules";
import MainMenu from "~tokio/foundation/main-header/main-menu/MainMenu.vue";
import { mapGetters } from "vuex";

export default {
  name: "FavoriteOperationContainer",
  components: {
    SmallHollowButton,
    MainMenu,
    ...Icons,
    IconClose,
    IconEmpty,
    IconAdd,
  },

  data() {
    return {
      favoriteList: [],
      removeMode: false,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),
    newFavoriteOperation() {
      return this.$store.state.Home.new_favorite;
    },
    calcItemPerPage() {
      const size_item =
        window.outerHeight >= 800 ? this.SIZE_ITEM_BIG : this.SIZE_ITEM_DEFAULT;
      return Math.floor(this.$refs.modulesArea.offsetWidth / size_item);
    },
  },

  watch: {
    newFavoriteOperation(pValue) {
      if (pValue) {
        if (pValue.link) {
          this.saveNewFavorite(pValue);
        }

        this.$store.commit("Home/RESET_NEW_FAVORITE");
        this.$store.commit("Home/DISABLE_FAVORITE_MODE");
      }
    },

    unitHealthId(pValue) {
      if (pValue && this.userLoginId) {
        this.mountFavoriteList();
      }
    },
  },

  created() {
    this.SIZE_ITEM_DEFAULT = 80;
    this.SIZE_ITEM_BIG = 100;
  },

  beforeDestroy() {
    this.$store.commit("Home/DISABLE_FAVORITE_MODE");
  },

  mounted() {
    if (this.unitHealthId && this.userLoginId) {
      this.mountFavoriteList();
    }
  },

  methods: {
    prepareFavoriteData(pFavorite) {
      return {
        opf_id_unidades_saude: this.unitHealthId,
        opf_id_usuarios: this.userLoginId,
        opf_rota: pFavorite.link,
        opf_nome_rota: pFavorite.name,
        opf_nome_icone: pFavorite.iconName,
        opf_modulos: pFavorite.module,
        opf_permissao: Array.isArray(pFavorite.permission)
          ? pFavorite.permission.toString()
          : pFavorite.permission,
        opf_ativo: pFavorite.active ? 1 : 0,
      };
    },

    async saveNewFavorite(pFavoriteData) {
      try {
        this.$loader.start("Carregando...");
        const variables = this.prepareFavoriteData(pFavoriteData);

        const favoriteSaved = await this.$store.dispatch(
          "Home/SAVE_FAVORITE_OPERATION",
          { favorite: variables },
        );

        this.favoriteList.unshift(favoriteSaved);
        this.$toaster.success("Operação adicionada com sucesso");
      } catch (e) {
        this.$toaster.error(e);
      } finally {
        this.$loader.finish();
      }
    },

    async mountFavoriteList() {
      try {
        const data = await this.$store.dispatch(
          "Home/SEARCH_FAVORITE_OPERATION",
          {
            opf_id_unidades_saude: this.unitHealthId,
            opf_id_usuarios: this.userLoginId,
          },
        );
        this.favoriteList = Object.assign([], data.rows);
      } catch (e) {
        this.$toaster.error(e);
      }
    },

    removeFavorite() {
      this.removeMode = !this.removeMode;
    },

    newFavorite() {
      this.removeMode = false;
      this.$store.commit("Home/ACTIVE_FAVORITE_MODE");
      this.$refs.mainMenu.openMenu();
    },

    go(pData) {
      if (this.removeMode) {
        this.removeFromFavorite(pData);
        return;
      }

      this.$store.commit("Home/PUSH_RECENT_OPERATION", {
        iconName: pData.opf_nome_icone,
        module: pData.opf_modulos,
        name: pData.opf_nome_rota,
        link: pData.opf_rota,
      });

      this.$router.push(pData.opf_rota);
    },

    async removeFromFavorite(pData) {
      try {
        const { opf_id } = await this.$store.dispatch(
          "Home/REMOVE_FAVORITE_OPERATION",
          {
            opf_id: pData.opf_id,
          },
        );

        const index = this.favoriteList.findIndex(
          (item) => item.opf_id === opf_id,
        );

        if (index === -1) {
          throw new Error("Operação já foi removida.");
        }

        this.favoriteList.splice(index, 1);
        this.$toaster.success("Operação removida com sucesso");
      } catch (e) {
        this.$toaster.error(e);
      }
    },

    hasPermission(pPermission) {
      const moreThanOnePermission = pPermission.indexOf(",");
      if (moreThanOnePermission !== -1) {
        const permissions = pPermission.split(",");
        return this.$Permissions.global.has(permissions);
      }

      return this.$Permissions.global.has(pPermission);
    },
  },
};
</script>
