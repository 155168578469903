<style src="./ExamSectorReport.scss" lang="scss" scoped></style>

<template>
  <div class="exam-sector-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-esr"
          v-model="form.uns_id"
          permission="exame2.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-esr"
          v-model="form.set_id"
          :unit-health="form.uns_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          default-text="Todos"
          empty-default-text="Todos"
          class="inputitem"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgComboboxTypeScale
          id="type-scale-esr"
          ref="typeScale"
          v-model="form.type_scale"
          :uns_id="form.uns_id"
          :usu_id="userId"
          label="Tipo de Escala"
          default-text="Todos"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-esr"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="final-date-esr"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          label="Data Final"
          class="inputitem mg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgInputDate,
} from "~tokio/primitive";

import RgComboboxTypeScale from "$exam/submodules/register/common/combobox/rg-combobox-type-scale/RgComboboxTypeScale.vue";
import { mapGetters } from "vuex";
import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: null,
  type_scale: null,
  name_type_scale: "",
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
};

export default {
  name: "ExamSectorReport",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgComboboxTypeScale,
    RgInputDate,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Exames por Setor",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      userId: null,
    };
  },
  computed: {
    getNameSmartTable() {
      return "ExamSectorReport";
    },

    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
    }),

    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }"
      );
    },

    columnTable() {
      return this.COLUMN;
    },

    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },

    getTypeScaleList() {
      if (this.$refs.typeScale) {
        const typeScaleList = this.$refs.typeScale.getList();
        if (typeScaleList && typeScaleList.length > 0) {
          const typeScaleFilter = typeScaleList.map((typeScale) =>
            Number(typeScale.value),
          );
          return typeScaleFilter;
        }
      }
      return null;
    },

    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
    },

    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },
  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
      }
    },
    "form.set_id"(pValue, pPrev) {
      if (!pValue) {
        this.form.set_nome = null;
      }

      if (!pValue || pValue !== pPrev) {
        this.form.ocp_nome = null;
      }
    },

    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },

    "form.type_scale"(pValue) {
      if (pValue && typeof pValue === "object") {
        this.form.name_type_scale = pValue[0].text;
      }
    },
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.userId = this.userLogin.user.usu_id;

    this.COLUMN = [
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Disponibilizados", key: "DISPONIBILIZADOS" },
      { name: "Agendados", key: "AGENDADOS" },
      { name: "Efetivados", key: "EFETIVADOS" },
      { name: "Não Efetivados", key: "NAO_EFETIVADOS" },
      { name: "Aguardando", key: "AGUARDANDO" },
      { name: "Vagas Restantes", key: "VAGAS_RESTANTES" },
      { name: "Produtividade", key: "PRODUTIVIDADE" },
    ];
  },
  methods: {
    buildFilter() {
      const payLoad = {
        uns_id: this.form.uns_id || null,
        set_id: this.form.set_id || null,
        set_nome: this.form.set_nome || "",
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        type_scale: this.form.type_scale
          ? Number(this.form.type_scale)
          : this.getTypeScaleList,
        name_type_scale: this.form.type_scale
          ? this.form.name_type_scale
          : null,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Exam/Report/GET_EXAM_SECTOR_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
