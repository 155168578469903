<style src="./RadioComparative.scss" lang="scss" scoped></style>

<template lang="html">
  <div class="radio-comparative-style">
    <section class="radio-custom">
      <div
        id="radiocomparative"
        class="radio-container unselect"
        :class="{ active }"
        @click="updateState()"
      >
        Modo Comparativo <IconShow :fill="active ? 'white' : '#1e88a9'" />
      </div>
    </section>
  </div>
</template>

<script>
import { IconShow } from "~tokio/primitive/icon/symbols";
export default {
  name: "RadioComparative",
  components: {
    IconShow,
  },

  props: {
    resultCallback: Function,
    startActive: { type: Boolean, default: false },
  },

  data() {
    return {
      active: false,
    };
  },

  watch: {
    startActive: {
      handler(pValue) {
        this.active = pValue;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    updateState() {
      this.resultCallback(!this.active);
      this.active = !this.active;
    },
  },
};
</script>
