import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import IntegrationServices from "@app/configuration/views/Integration-services/IntegrationServices";
import UserGroupAuth from "~common/utils/UserGroupAuth";
import { UserGroup } from "~common/utils/app/";

export default {
  path: "/configuration/integration-services",
  name: "configuration.integration-services",
  components: {
    default: IntegrationServices,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Configuração", link: "/" },
      { label: "Lista de serviços" },
    ],
    restrictTo: [UserGroup.ADM, UserGroup.SUPPORT, UserGroup.DEVELOP],
  },

  beforeEnter: UserGroupAuth,
};
