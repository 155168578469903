import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetPreferenceColumnTable(
    $moduleId: String
    $userId: Int
    $unitHealthId: Int
  ) {
    GetPreferenceColumnTable(
      moduleId: $moduleId
      userId: $userId
      unitHealthId: $unitHealthId
    ) {
      rows {
        par_id_usuarios
        par_nome_relatorio
        par_id_coluna
        par_nome_coluna
        par_alinhamento
        par_id_unidades_saude
        par_url
        par_id_modulos
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const resultSearch = await data.GetPreferenceColumnTable.rows;

    if (resultSearch.length > 0) {
      const filterTables = [];
      for (const iterator of resultSearch) {
        const formatIterator = {
          par_id_usuarios: iterator.par_id_usuarios,
          par_nome_relatorio: iterator.par_nome_relatorio,
          par_id_unidades_saude: iterator.par_id_unidades_saude,
          par_url: iterator.par_url,
          par_id_modulos: iterator.par_id_modulos,
        };
        if (!filterTables.includes(JSON.stringify(formatIterator))) {
          filterTables.push(JSON.stringify(formatIterator));
        }
      }

      const convertTablesFilters = filterTables.map((element) => {
        const convertJSON = JSON.parse(element);
        return {
          link: convertJSON.par_url,
          usu_id: convertJSON.par_id_usuarios,
          uns_id: convertJSON.par_id_unidades_saude,
          columns: [],
          name: convertJSON.par_nome_relatorio,
          par_id_modulos: convertJSON.par_id_modulos,
        };
      });

      const resultFormat = convertTablesFilters.map((element) => {
        const idModules = element.par_id_modulos;
        delete element.par_id_modulos;
        return {
          ...element,
          columns: resultSearch
            .filter(
              (item) =>
                item.par_id_usuarios === element.usu_id &&
                item.par_nome_relatorio === element.name &&
                item.par_id_unidades_saude === element.uns_id &&
                item.par_url === element.link &&
                item.par_id_modulos === idModules,
            )
            .map((item) => {
              return {
                name: item.par_nome_coluna,
                key: item.par_id_coluna,
                align: item.par_alinhamento,
                active: true,
              };
            }),
        };
      });

      return resultFormat;
    } else {
      return [];
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
