<template>
  <div class="appointment-exclude-scale-schedule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-aess"
          v-model="form.uns_id"
          permission="consulta.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
          @change="selectUnithealth"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-aess"
          v-model="form.set_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unit-health="form.uns_id"
          class="inputitem"
          default-text="Todos"
          @change="getSector"
        />
      </div>
      <div class="selectinput">
        <RgInput
          id="patient"
          ref="patient"
          v-model="form.patientName"
          :disabled="!!form.patient"
          :class="{ disable: !!form.patient }"
          placeholder="Digite o nome do paciente"
          label="Paciente"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          id="ocp-nome-aess"
          ref="occupation"
          v-model="form.ocp_nome"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          :employeeId="professionalBond"
          label="Ocupação"
          class="inputitem"
          @selected="selectedCbo"
        />
      </div>

      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-aess"
          ref="professional"
          v-model="form.pes_nome"
          :maxlength="100"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :ocuppationId="form.ocp_id"
          label="Profissional"
          class="inputitem"
          @selected="selectingProfessional"
        />
      </div>
      <div class="selectinput">
        <RgRadioCustom
          id="sentPep"
          ref="sentPep"
          v-bind="propsRadioCustomSentPep"
          multSelect
          label="Situação PEP"
          @input="onInputRadioCustomSentPep"
        />
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="gender"
          ref="gender"
          v-bind="propsRadioCustomEffective"
          multSelect
          label="Situação da Efetivação"
          @input="onInputRadioCustomEffective"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período do Agendamento</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-aess"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{
            required: true,
          }"
        />
        <RgInputDate
          id="final-date-aess"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem mg"
          :rules="{ required: true }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgInputDate,
  RgInput,
  RgSelectSector,
  RgSuggestCbo,
  RgSuggestEmployee,
  RgRadioCustom,
} from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: "",
  ocp_nome: "",
  ocp_id: null,
  pes_id: null,
  pes_nome: "",
  sentPep: null,
  effected: null,
  type_scale: null,
  name_type_scale: "",
  usu_nome: "",
  usu_id: null,
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  orderby: 1,
  orderByLabel: "Data e Hora",
};

export default {
  name: "AppointmentPatientsSentPepByEffectedReport",
  components: {
    RgSelectUnithealth,
    RgInputDate,
    RgInput,
    RgSelectSector,
    RgSuggestCbo,
    RgSuggestEmployee,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      resultTitle: "Enviados PEP por Efetivados",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      professionalBond: null,
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; }" +
        ".report-column-6 { white-space: normal !important; }" +
        ".report-column-8 { white-space: normal !important; }"
      );
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "AppointmentPatientsSentPepByEffectedReport";
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
    propsRadioCustomSentPep() {
      const list = [
        { title: "Enviado", id: "E" },
        { title: "Não enviado", id: "N" },
      ];

      const uniqueKey = "id";

      return { list, uniqueKey };
    },
    propsRadioCustomEffective() {
      const list = [
        { title: "Efetivado", id: "E" },
        { title: "Não efetivado", id: "N" },
      ];

      const uniqueKey = "id";

      return { list, uniqueKey };
    },
  },
  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
      }
    },

    "form.ocp_id"(pValue) {
      if (!pValue && !this.form.pes_id) {
        this.professionalBond = null;
      }
    },
    "form.pes_id"(pValue) {
      if (!pValue && !this.form.ocp_id) {
        this.professionalBond = null;
      }
    },
    "form.type_scale"(pValue) {
      if (pValue && typeof pValue === "object") {
        this.form.name_type_scale = pValue[0].text;
      }
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  created() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

    this.COLUMN = [
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Profissional", key: "PROFISSIONAL_DE_SAUDE", align: "left" },
      { name: "Data do Agendamento", key: "DATA_AGENDAMENTO", align: "left" },
      { name: "Data da Efetivação", key: "DATA_EFETIVACAO", align: "left" },
      { name: "Situação PEP", key: "SITUACAO_PEP" },
      { name: "Situação Efetivação", key: "SITUACAO_EFETIVACAO" },
      { name: "Envio para PEP", key: "DATA_ENVIO_PEP" },
    ];

    this.ORDERS = [
      { value: 1, text: "Data e Hora" },
      { value: 2, text: "Setor" },
      { value: 3, text: "Profissional" },
    ];
  },
  methods: {
    buildFilter() {
      const payLoad = {
        unitHealth: this.form.uns_id || null,
        unitHealthName: this.form.uns_nome || null,
        sector: this.form.set_id || null,
        employee: this.form.pes_id || null,
        occupation: this.form.ocp_id || null,
        smd_id: this.form.smd_id || null,
        sectorName: this.form.set_nome || "",
        occupationName: this.form.ocp_nome || "",
        patientName: this.form.patientName || "",
        employeeName: this.form.pes_nome || "",
        sentPep: this.form.sentPep,
        effected: this.form.effected,
        schedulingPeriodDateStart: this.form.initialDate,
        schedulingPeriodDateEnd: this.form.finalDate,
        orderby: this.form.orderby,
        orderByLabel: this.form.orderByLabel,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },
    onInputRadioCustomSentPep(item) {
      if (item) {
        if (item.length === 1) {
          if (item[0] === "E") {
            this.form.sentPep = true;
          } else {
            this.form.sentPep = false;
          }
        } else {
          this.form.sentPep = null;
        }
      } else this.form.sentPep = null;
    },
    onInputRadioCustomEffective(item) {
      if (item) {
        if (item.length === 1) {
          if (item[0] === "E") {
            this.form.effected = true;
          } else {
            this.form.effected = false;
          }
        } else {
          this.form.effected = null;
        }
      } else this.form.effected = null;
    },

    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_PATIENTS_SENT_PEP_BY_EFFECTED_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.professionalBond = null;
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
      this.form.ocp_nome = null;
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.form.ocp_nome = pValue.source.ocp_nome;
        this.$loader.finish();
      } else {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
      }
    },

    selectUnithealth(pValue) {
      if (pValue && pValue.label) {
        this.form.uns_nome = pValue.label;
      }
    },

    selectingProfessional(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.pes_id = pValue.source.pes_id;
        this.form.pes_nome = pValue.source.pes_nome;
        this.professionalBond =
          pValue.source?.employeeRelationship?.vin_id_funcionarios;
      } else {
        this.form.pes_id = 0;
        this.form.pes_nome = null;
        this.professionalBond = null;
      }
    },

    selectingUser(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.usu_id = pValue.source.usu_id;
      } else {
        this.form.usu_id = 0;
      }
    },
  },
};
</script>
