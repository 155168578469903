import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
const query = gql`
  query SearchDailyAdmissionCensusReport(
    $inl_extra: Int
    $inl_id: Int
    $inl_nome: String
    $inl_observacao: Int
    $isl_id: Int
    $isl_nome: String
    $iti_id: Int
    $iti_nome: String
    $itl_id: Int
    $itl_nome: String
    $lca_id: Int
    $lca_nome: String
    $mun_id: Int
    $mun_nome: String
    $ocp_id: Int
    $ocp_nome: String
    $set_id: Int
    $set_nome: String
    $uns_id: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchDailyAdmissionCensusReport(
      inl_extra: $inl_extra
      inl_id: $inl_id
      inl_nome: $inl_nome
      inl_observacao: $inl_observacao
      isl_id: $isl_id
      isl_nome: $isl_nome
      iti_id: $iti_id
      iti_nome: $iti_nome
      itl_id: $itl_id
      itl_nome: $itl_nome
      lca_id: $lca_id
      lca_nome: $lca_nome
      mun_id: $mun_id
      mun_nome: $mun_nome
      ocp_id: $ocp_id
      ocp_nome: $ocp_nome
      set_id: $set_id
      set_nome: $set_nome
      uns_id: $uns_id
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        inl_nome
        uns_nome
        set_nome
        lca_nome
        tipos_do_leito
        iti_nome
        isl_nome
        leito_extra
        leito_obs
        pes_nome
        pac_prontuario_unico
        ppr_numero
        int_data
        idade
        ocp_nome
        cid_nome
        nome_procedimento_solicitado
        tempo_internado
        mun_nome
        via_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchDailyAdmissionCensusReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
