<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnitHealthTypeSus from "./action/SearchUnitHealthTypeSus";
export default {
  name: "RgSelectUnitHealthTypeSus",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Tipo",
    },
    defaultText: {
      type: String,
      default: "Digite o tipo de classificação",
    },
  },

  data: () => {
    return {
      valuesData: [],
    };
  },

  watch: {
    valuesData(val) {
      this.updateOptions();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      const data = await SearchUnitHealthTypeSus({});

      this.valuesData = data.map((item) => {
        return {
          value: item.tss_id,
          label: `${item.tss_codigo} - ${item.tss_nome}`,
        };
      });
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
