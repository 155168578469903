<template>
  <article class="pharmacy-product">
    <RgSearch
      ref="rgSearch"
      v-model="mutableProductList"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :item-height="34.5"
      :maxRegister="40"
      :disabled="disabledByModal"
      result-title="Produtos e Medicamentos"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
      @submitFromButton="cleanSelectRow"
      @viewClickPagination="cleanSelectRow"
    >
      <div slot="filters" class="pharmacy-product-filter">
        <div class="filters">
          <RgInput
            v-model="form.code"
            label="Código"
            placeholder="Digite o n° do produto ou medicamento"
            :maxlength="15"
          />

          <RgInput
            v-model="form.activePrinciple"
            label="Princípio Ativo"
            placeholder="Digite o princípio ativo do produto"
          />

          <RgSelectProductType
            v-model="form.productType"
            label="Tipo de Produto/Medicamento"
            class="product-type"
          />

          <RgInput
            v-model="form.commercialName"
            label="Nome Comercial"
            placeholder="Digite o nome popular do produto"
          />
        </div>
      </div>

      <div v-if="!hasListProduct" slot="menu-top" class="top-button">
        <RgAddButton
          id="new-product"
          large
          label="Novo"
          title="Cadastrar Produto ou Medicamento"
          :disabled="disabledByModal"
          @click="newProduct"
        />
      </div>

      <div ref="radios" slot="search-top" class="pharmacy-product-top-buttons">
        <div slot="legends" class="radios">
          <RgRadioCustom
            id="status"
            v-bind="propsRadio"
            :disabledByModal="disabledByModal"
            :class="{ disabled: disabledByModal }"
            @input="onInputRadioCustom"
          />
        </div>
      </div>

      <div class="pharmacy-product-result">
        <SmartTable
          ref="smartTable"
          name="PharmacyProduct"
          :columns="COLUMNS"
          :body="mutableProductList"
          :total="Number(total)"
          :initial-columns="6"
          :show-pagination="false"
          :dynamic-height="getSpaceTable"
          index-column="mpd_id"
          toggleSelected
          @getLine="selectLine"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgAddButton
              id="new-product"
              small
              title="Cadastrar Produto ou Medicamento"
              :permission="canRegister"
              :disabled="disabledByModal"
              @click="newProduct"
            />
            <RgEditButton
              id="edit-product"
              small
              title="Editar Produto ou Medicamento"
              :permission="canEdit"
              :disabled="!productSelected || disabledByModal"
              @click="editProduct"
            />
            <RgLessButton
              id="delete-product"
              small
              title="Excluir Produto ou Medicamento"
              :permission="canRemove"
              :disabled="!productSelected || disabledByModal"
              @click="openModalRemoveProduct"
            />
          </div>
        </SmartTable>
      </div>

      <div
        ref="footer"
        slot="menu-bottom"
        class="pharmacy-product-bottom-buttons"
      >
        <SmallButton
          id="disable-product"
          backgroundColor="#1E88A9"
          title="Inabilitar Produto"
          :permission="canDisable"
          :disabled="!productSelected || productIsDisable || disabledByModal"
          @click="openModalChangeStatusProduct"
        >
          <div slot="icon" class="icon"><IconDesactivate /></div>
        </SmallButton>
        <SmallButton
          id="enable-product"
          backgroundColor="#1E88A9"
          title="Habilitar Produto"
          :permission="canEnable"
          :disabled="!productSelected || productIsEnable || disabledByModal"
          @click="openModalChangeStatusProduct"
        >
          <div slot="icon" class="icon"><IconActivate /></div>
        </SmallButton>
        <SmallButton
          id="type-presentation"
          :permission="canViewTypePresentation"
          backgroundColor="#1E88A9"
          title="Tipos de Apresentação"
          :disabled="!canViewTypePresentation || disabledByModal"
          @click="typePresentation"
        >
          <div slot="icon" class="icon">
            <IconTypePresentation
              :disabled="!canViewTypePresentation || disabledByModal"
            />
          </div>
        </SmallButton>
        <SmallButton
          id="product-type"
          backgroundColor="#1E88A9"
          :permission="canViewProductType"
          title="Tipos de Produto"
          :disabled="!canViewProductType || disabledByModal"
          @click="productType"
        >
          <div slot="icon" class="icon">
            <IconProductType
              :disabled="!canViewTypePresentation || disabledByModal"
            />
          </div>
        </SmallButton>
      </div>
    </RgSearch>

    <ModalRegisterProduct
      id="modal-register-product"
      :show="modalRegisterProduct"
      :productId="productId"
      :edition="isEdition"
      @close="closeModalRegisterProduct"
      @reSearch="reSearch"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalRemoveProduct"
      @reSearch="reSearch"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalChangeStatusProduct
      id="modal-change-status-product"
      :show="modalChangeStatusProduct"
      :productId="productId"
      @close="closeModalChangeStatusProduct"
      @reSearch="reSearch"
    />
  </article>
</template>

<script>
import { mapGetters } from "vuex";

import {
  RgInput,
  RgAddButton,
  RgEditButton,
  RgLessButton,
  RgRadioCustom,
  SmallButton,
  IconDanger,
  IconTypePresentation,
  IconProductType,
  IconDesactivate,
  IconActivate,
} from "~tokio/primitive";

import ModalRegisterProduct from "$pharmacy/submodules/register/common/components/modal/modal-register-product/ModalRegisterProduct";
import ModalChangeStatusProduct from "$pharmacy/submodules/register/common/components/modal/modal-change-status-product/ModalChangeStatusProduct";
import ModalConfirmDeletion from "~tokio/primitive/modal/modal-confirm-deletion/ModalConfirmDeletion";

import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

const FORM = {
  code: null,
  activePrinciple: null,
  productType: null,
  commercialName: null,
  statusProduct: null,
};

export default {
  name: "PharmacyProduct",
  components: {
    RgSearch,
    SmartTable,
    RgInput,
    RgAddButton,
    RgEditButton,
    RgLessButton,
    RgRadioCustom,
    SmallButton,
    IconDanger,
    IconTypePresentation,
    IconProductType,
    IconDesactivate,
    IconActivate,
    RgSelectProductType,
    ModalRegisterProduct,
    ModalConfirmDeletion,
    ModalChangeStatusProduct,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableProductList: [],
      productData: {},
      total: 0,
      productId: null,
      isEdition: false,
      modalRegisterProduct: false,
      modalRemoveProduct: false,
      modalChangeStatusProduct: false,
      tableHeight: null,
    };
  },
  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),
    hasListProduct() {
      return this.mutableProductList?.length > 0;
    },
    productSelected() {
      return Object.keys(this.productData).length > 0;
    },
    productIsEnable() {
      return this.productData.mpd_bloqueado === "0";
    },
    productIsDisable() {
      return this.productData.mpd_bloqueado === "1";
    },
    getSpaceTable() {
      return this.tableHeight;
    },
    disabledByModal() {
      return (
        this.modalRegisterProduct ||
        this.modalRemoveProduct ||
        this.modalChangeStatusProduct
      );
    },
    propsModalConfirmDeletion() {
      const show = this.modalRemoveProduct;
      const confirm = () => this.removeProduct();
      const noReason = true;
      const title = "Excluir Produto";
      const message = "A operação não poderá ser desfeita";
      const btnRemoveTitle = "Excluir";
      const bodyText = this.productData
        ? this.productData.mpd_novo_nome
          ? this.productData.mpd_novo_principio_ativo +
            " - " +
            this.productData.mpd_novo_nome
          : this.productData.mpd_novo_principio_ativo
        : "NÃO INFORMADO";

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
      };
    },
    propsRadio() {
      const list = [
        { title: "Habilitados", blue: true, id: 2 },
        { title: "Inabilitados", blue: true, id: 1 },
      ];
      const value = this.form.statusProduct;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    permission() {
      return {
        register: !!this.$Permissions.global.has(
          "farmacia.cadastroProduto.incluir",
          this.unitHealthId,
        ),
        edit: !!this.$Permissions.global.has(
          "farmacia.cadastroProduto.alterar",
          this.unitHealthId,
        ),
        remove: !!this.$Permissions.global.has(
          "farmacia.cadastroProduto.excluir",
          this.unitHealthId,
        ),
        enable: !!this.$Permissions.global.has(
          "farmacia.cadastroProduto.habilitar",
          this.unitHealthId,
        ),
        disable: !!this.$Permissions.global.has(
          "farmacia.cadastroProduto.inabilitar",
          this.unitHealthId,
        ),
        viewTypePresentation: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoApresentacao.exibir",
          this.unitHealthId,
        ),
        viewProductType: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoProduto.exibir",
          this.unitHealthId,
        ),
      };
    },
    canRegister() {
      return this.permission.register;
    },
    canEdit() {
      return this.permission.edit;
    },
    canRemove() {
      return this.permission.remove;
    },
    canEnable() {
      return this.permission.enable;
    },
    canDisable() {
      return this.permission.disable;
    },
    canViewTypePresentation() {
      return this.permission.viewTypePresentation;
    },
    canViewProductType() {
      return this.permission.viewProductType;
    },
  },
  watch: {},
  mounted() {},
  created() {
    this.COLUMNS = [
      { name: "Município", key: "mun_nome", align: "left" },
      { name: "Código", key: "mpd_codigo", align: "left" },
      {
        name: "Apresentação",
        key: "mtu_novo_nome",
        align: "left",
      },
      {
        name: "Princípio Ativo",
        key: "mpd_novo_principio_ativo",
        align: "left",
      },
      { name: "Tipo", key: "mtp_nome", align: "left" },
      { name: "Nome", key: "nome", align: "left" },
    ];
  },
  methods: {
    generateFilter() {
      const variables = {
        arrFormData: {
          mpd_codigo: this.form.code,
          mpd_novo_principio_ativo: this.form.activePrinciple,
          mpd_id_municipios_tipos_produtos: this.form.productType,
          mpd_novo_nome: this.form.commercialName,
          mpd_bloqueado: this.form.statusProduct,
        },
      };

      return variables;
    },
    async searchFilter(pData) {
      return await this.$store.dispatch(
        "Pharmacy/Register/SEARCH_PRODUCT",
        pData,
      );
    },
    onInputRadioCustom(item) {
      if (item) {
        this.form.statusProduct = item.id;
        this.reSearch();
      } else {
        this.form.statusProduct = null;
        this.reSearch();
      }
    },
    reSearch() {
      this.$refs.rgSearch.submitForm(false, true);
      this.productId = null;
      this.productData = {};
    },
    changeStatusFilter() {
      this.productId = null;
      this.productData = {};
      this.$refs.RgSearch.submitForm(true);
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },
    selectLine(pValue) {
      if (pValue) {
        this.productData = pValue;
        this.productId = Number(pValue.mpd_id);
      } else {
        this.productData = {};
        this.productId = null;
      }
    },
    newProduct() {
      this.modalRegisterProduct = true;
    },
    editProduct() {
      this.isEdition = true;
      this.modalRegisterProduct = true;
    },
    async removeProduct() {
      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            mpd_id: Number(this.productId) || null,
            mpd_bloqueado: true,
          },
        };

        await this.$store.dispatch(
          "Pharmacy/Register/DELETE_PRODUCT",
          variables,
        );

        this.$toaster.success("Produto excluído com sucesso");
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o Produto",
        );
      } finally {
        this.$loader.finish();
      }
    },
    typePresentation() {
      this.$router.push({ name: "pharmacy.type-presentation" });
    },
    productType() {
      this.$router.push({ name: "pharmacy.product-type" });
    },
    afterSearchFilter() {
      this.getDynamicHeight();
      this.cleanSelectRow();
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgSearch?.$refs.listContent) {
          const searchArea = this.$refs.rgSearch.$refs.listContent.offsetHeight;
          const radios = this.$refs.radios.offsetHeight;
          const footer = this.$refs.footer.offsetHeight;

          this.tableHeight = searchArea - radios - footer;
        }
      });
    },
    openModalRemoveProduct() {
      this.modalRemoveProduct = true;
    },
    openModalChangeStatusProduct() {
      this.modalChangeStatusProduct = true;
    },
    closeModalRegisterProduct() {
      this.isEdition = false;
      this.modalRegisterProduct = false;
    },
    closeModalRemoveProduct() {
      this.modalRemoveProduct = false;
    },
    closeModalChangeStatusProduct() {
      this.modalChangeStatusProduct = false;
    },
    cleanSelectRow() {
      this.productData = {};
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.mutableProductList = [];
      this.productData = {};
      this.total = 0;
      this.productId = null;
      this.isEdition = false;
      this.modalRegisterProduct = false;
      this.modalRemoveProduct = false;
      this.modalChangeStatusProduct = false;
    },
  },
};
</script>
