import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PatientDetails from "$person/patient/view/patient-details/PatientDetails";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/queue/edit-insert/edit-patient",
  name: "exam.schedule-regulation.queue.edit-insert.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: OpenSubModule("exam") },
      { label: "Fila de Regulação", link: "/exam/schedule/queue" },
      { label: "Editar Fila", link: "/exam/schedule/queue/edit-insert" },
      {
        label: "Editar Paciente",
        link: "/exam/schedule/queue/edit-insert/edit-patient",
      },
    ],
  },
};
