export default {
  GET_TOKEN: (state) => state.token,
  GET_LEGACY_TOKEN: (state) => state.legacyToken,
  GET_USER_NAME: (state) => (state.user ? state.user.usu_nome : ""),
  GET_USER_ID_CLIENT: (state) => (state.user ? state.user.usu_id_clientes : ""),
  GET_USER_ID_GROUP: (state) =>
    state.user ? state.user.usu_id_grupos_usuario : "",
  GET_USER_ID: (state) => (state.user ? state.user.usu_id : ""),
  GET_CLIENT_CODE: (state) => state.clientCode,
  GET_CLIENT_NAME: (state) => state.clientName,
  GET_UNIT_HEALTH: (state) => state.unitHealth,
  GET_UNIT_HEALTH_NAME: (state) => state.unitHealth.uns_nome,
  GET_UNIT_HEALTH_CNES: (state) => state.unitHealth.uns_cnes,
  GET_UNIT_HEALTH_ID: (state) => state.unitHealth.uns_id,
  GET_UNIT_HEALTH_ADDRESS_ID: (state) => state.unitHealth.uns_id_enderecos,
  GET_UNIT_HEALTH_ADDRESS: (state) => state.unitHealth.endereco,
  GET_ACTIVE_LOGIN: (state) => state.loginActive,
  GET_PERMITED_UNIT_HEALTH_LIST: (state) => state.permitedUnitHealthList,
  GET_LOGIN_CREDENTIANS: (state) => ({
    clientCode: state.clientCode,
    userName: state.user.usu_login,
  }),
  GET_COMPLETE_LOGIN_INFO: (state) => ({
    unitHealth: state.unitHealth,
    clientCode: state.clientCode,
    token: state.token,
    user: state.user,
    permissions: state.permissions,
    preferences: state.preferences,
  }),
  GET_LAST_LOGIN_INFO: (state) => state.lastLogin,
  GET_UNIT_HEALTH_ISSUING_BODY: (state) => state.unitHealth.uns_orgao_emissor,
  GET_PRIVATE_SECTOR: (state) => state.privateSector,
  GET_CADSUS: (state) => state.cadsus,
  GET_BASIC_ATTENTION_INTEGRATION: (state) => state.basicAttentionIntegration,
  GET_PHONE_REQUIRED: (state) => state.phoneRequired,
  GET_RECORD_NUMBER_PREFERENCES: (state) => state.recordNumberRequired,
  GET_HOSPITALIZATION_UNIT_HEALTH_RECORD_NUMBER_PREFERENCES: (state) =>
    state.hospitalizationUnitHealthRecordNumberRequired,
  GET_DOCUMENT_PREFERENCES: (state) => state.preferences.documents,
  GET_ADDRESS_UNIT_HEALTH_PREFERENCE: (state) => state.addressUnitHealth,
  GET_BACKEND_LEGACY_URL: (state) => state.backendLegacyUrl,
  GET_PREFERENCES: (state) => state.preferences,
  GET_USER_HEALTH_OCCUPATIONS: (state) => state.userHealthOccupations,
  GET_ROUTE_MODULE_MAP: (state) => state.route_module_map,
  GET_REFRESH_PREFERENCE_COLUMN_GRID: (state) =>
    state.refreshPreferenceColumnGrid,
};
