<template lang="html">
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 19C19.7614 19 22 16.7614 22 14C22 11.2386 19.7614 9 17 9C14.2386 9 12 11.2386 12 14C12 16.7614 14.2386 19 17 19ZM16.0825 16.5737C16.1637 16.5737 16.2418 16.5392 16.2991 16.4774L19.5016 13.0737C19.6811 12.8814 19.6811 12.5691 19.5016 12.3764L19.285 12.1439C19.1057 11.9513 18.8147 11.9513 18.6352 12.1439L16.0825 14.85L15.0026 13.6909C14.8231 13.4983 14.5323 13.4983 14.3528 13.6909L14.1362 13.9234C13.9567 14.1157 13.9567 14.4281 14.1362 14.6208L15.8659 16.4774C15.9232 16.5392 16.0013 16.5737 16.0825 16.5737Z"
      fill="white"
    />
    <path
      d="M0.998046 15.3415L0.998047 2.70732C0.998047 1.21469 2.21273 -7.35844e-07 3.70537 -6.70598e-07L16.3396 -1.18341e-07C17.8322 -5.30956e-08 19.0469 1.21469 19.0469 2.70732L19.0469 7.0843C19.0469 7.58244 18.6435 7.98674 18.1444 7.98674C17.6454 7.98674 17.242 7.58244 17.242 7.0843L17.242 2.70732C17.242 2.21008 16.8377 1.80488 16.3396 1.80488L3.70537 1.80488C3.20722 1.80488 2.80293 2.21008 2.80293 2.70732L2.80293 15.3415C2.80293 15.8387 3.20722 16.2439 3.70537 16.2439L10.9249 16.2439C11.424 16.2439 11.8273 16.6482 11.8273 17.1464C11.8273 17.6445 11.424 18.0488 10.9249 18.0488L3.70537 18.0488C2.21273 18.0488 0.998046 16.8341 0.998046 15.3415Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Activate",
};
</script>
