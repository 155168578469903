import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveHospitalizationAndSolicitation(
    $hospitalization: HospitalizationInputType
    $ins_id: Int!
  ) {
    SaveHospitalizationAndSolicitation(
      hospitalization: $hospitalization
      ins_id: $ins_id
    ) {
      int_id
    }
  }
`;
const formatData = (pData) => {
  return {
    ...pData.patient,
    ...pData.hospitalization,
    ...pData.companion,
    indigent: pData.indigent,
    is_indigent: pData.is_indigent,
    uns_id: pData.uns_id,
    pes_id: pData.pes_id,
  };
};

export default async ({ commit }, variables) => {
  try {
    const hospitalization = formatData(variables.hospitalization);
    variables = { hospitalization, ins_id: variables.ins_id };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SaveHospitalizationAndSolicitation;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
