<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchAppointmentObjective from "./action/SearchAppointmentObjective";

export default {
  name: "RgSelectAppointmentObjective",
  extends: RgSelect,
  props: {
    healthUnitId: {
      required: true,
    },
    healthPlansId: {
      required: true,
    },
    active: {
      required: true,
    },
    label: {
      type: String,
      default: "Objetivo de Consulta",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    healthPlansId() {
      this.doSearch();
    },
    valuesData() {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (!this.healthUnitId || !this.healthPlansId) {
        return;
      }

      const data = await SearchAppointmentObjective({
        intIdUnidadeSaude: this.healthUnitId,
        intIdPlanoSaude: this.healthPlansId,
        ativo: this.active,
      });

      this.valuesData = data.map((item) => {
        return { value: item.data, label: item.label };
      });
    },
  },
};
</script>
