<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.40441 17.2528C1.10221 17.1239 1 16.8929 1 16.5684C1.01333 15.2663 1.00444 13.9642 1.00444 12.6665C1.00444 12.2088 1.19554 12.0177 1.64884 12.0177C1.96437 12.0177 2.27546 12.0265 2.59099 12.0177C2.72876 12.0132 2.75542 12.0532 2.75542 12.1865C2.75098 13.8353 2.75542 15.4885 2.75542 17.1373C2.75542 17.1773 2.75542 17.2217 2.75542 17.2617H1.40441V17.2528Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.81694 16.8972C3.81917 17.0039 3.82139 17.1106 3.82139 17.2172C3.82141 17.2172 3.82144 17.2173 3.82146 17.2173H3.81742V17.2528H8.64373C8.6423 17.2513 8.64086 17.2498 8.63941 17.2484C8.64218 17.2497 8.64494 17.2512 8.64769 17.2528H13.2651C13.2662 17.2507 13.2672 17.2481 13.2683 17.2453C13.2719 17.2365 13.2761 17.2261 13.2829 17.2261C13.8784 17.1639 14.345 16.875 14.7583 16.4573C15.6715 15.5375 16.5872 14.6226 17.5034 13.7072C17.809 13.4019 18.1147 13.0965 18.4203 12.7909C18.7181 12.4931 18.7136 12.1865 18.4025 11.9021C17.4159 10.991 15.8694 11.0044 14.9006 11.9465C14.2997 12.5296 13.7102 13.124 13.1208 13.7185C12.9734 13.8671 12.826 14.0157 12.6785 14.1641C12.363 14.4841 11.9897 14.6352 11.5364 14.6352C10.8729 14.6322 10.2114 14.6332 9.54919 14.6342C9.21786 14.6347 8.88636 14.6352 8.55437 14.6352C8.54006 14.6352 8.52548 14.6355 8.51076 14.6357C8.46605 14.6366 8.42007 14.6374 8.3766 14.6308C8.14995 14.6041 7.99441 14.4797 7.9233 14.2663C7.85664 14.0575 7.90997 13.8664 8.06996 13.7153C8.19439 13.5953 8.34549 13.5686 8.50993 13.5686H11.5453C11.5596 13.5686 11.5739 13.5687 11.5882 13.5687C11.7028 13.5692 11.8173 13.5696 11.9319 13.5419C12.603 13.3908 12.994 12.8709 13.0118 12.1154C13.0207 11.7021 12.8163 11.4888 12.403 11.4888H10.8075C10.6431 11.4888 10.4787 11.4754 10.3142 11.4443C9.83872 11.351 9.40319 11.1555 8.981 10.9333C7.82553 10.3244 6.62562 10.1333 5.34572 10.4311C4.34135 10.6666 3.8125 11.2888 3.8125 12.3198V16.5773C3.8125 16.6839 3.81472 16.7906 3.81694 16.8972Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2193 2C9.38192 2 8.70312 2.67879 8.70312 3.51613V8.84906C8.70312 9.6864 9.38192 10.3652 10.2193 10.3652H15.5522C16.3895 10.3652 17.0683 9.6864 17.0683 8.84906V3.51613C17.0683 2.67879 16.3895 2 15.5522 2H10.2193ZM9.95774 3.51613C9.95774 3.3717 10.0748 3.25462 10.2193 3.25462H15.5522C15.6966 3.25462 15.8137 3.3717 15.8137 3.51613V8.84906C15.8137 8.99349 15.6966 9.11058 15.5522 9.11058H10.2193C10.0748 9.11058 9.95774 8.99349 9.95774 8.84906V3.51613Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Consumption",
};
</script>
