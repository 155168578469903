<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchSector from "./action/SearchSector";

export default {
  name: "RgSelectSectorEmployee",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Setor",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    vinId: {
      type: Number,
      default: null,
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
  },
  data: () => {
    return {
      sector: [],
    };
  },
  watch: {
    vinId(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    sector: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.sector;
    },
    async doSearch() {
      this.Sector = [];

      if (this.vinId) {
        const data = await SearchSector({
          intUnsId: this.unitHealthId,
          intVinId: this.vinId,
        });

        this.sector = data.map((item) => {
          return {
            value: item.set_id,
            label: item.set_nome,
          };
        });
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
