<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchOccupationSynonymsUnit from "./action/SearchOccupationSynonymsUnit";
export default {
  name: "RgSelectOccupationSynonymsUnit",
  extends: RgSelect,
  props: {
    healthUnitId: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    healthUnitId() {
      this.doSearch();
    },
    valuesData() {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      const data = await SearchOccupationSynonymsUnit({
        intIdUnidade: this.healthUnitId,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
          ocp_id: Number(item.ocp_id),
        };
      });
    },
  },
};
</script>
