import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query(
    $cnes: String
    $competence: String
    $professionalCns: String
    $cboCode: String
  ) {
    SearchSheetBulletinBilling(
      cnes: $cnes
      competence: $competence
      professionalCns: $professionalCns
      cboCode: $cboCode
    ) {
      rows {
        pdf_folha
        total
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.SearchSheetBulletinBilling.rows;
};
