import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchAbIntegrations($pesId: Int!) {
    SearchAbIntegrations(ina_id_pessoas: $pesId) {
      rows {
        ina_id
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.SearchAbIntegrations.rows;
};
