import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import NewPreRegistration from "$emergency/pre-registration/views/new-pre-registration/NewPreRegistration.vue";

export default {
  path: "/emergency/pre-registration/new",
  name: "emergency.pre-registration.new",
  components: {
    default: NewPreRegistration,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Atendimento", link: OpenSubModule("emergency") },
      { label: "Pré-Atendimento", link: "/emergency/pre-registration" },
      {
        label: "Cadastrar Pré-Atendimento",
        link: "/emergency/pre-registration/new",
      },
    ],
  },
};
