import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import EmergencyBulletinSearchRoute from "$emergency/bulletin/views/EmergencyBulletinSearch.vue";

export default {
  path: "/emergency/bulletin",
  name: "emergency.bulletin",
  components: {
    default: EmergencyBulletinSearchRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Atendimento", link: OpenSubModule("emergency") },
      { label: "Boletins", link: "/emergency/bulletin" },
    ],
  },
};
