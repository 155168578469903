const clientNameTitle = (cli_nome) => {
  return cli_nome ? `<h2>${cli_nome}</h2>` : "";
};

const effectivationPassword = (eap_senha_efetivacao) => {
  return eap_senha_efetivacao
    ? `<label>Senha Efetivação: </label><span>${eap_senha_efetivacao}</span>`
    : "";
};

const unitAddress = (data) => {
  return `
    <p>
      ${data.tlg_nome} ${data.end_logradouro} ${data.end_numero} ${data.end_complemento} ${data.bai_nome} - ${data.mun_nome}
    </p>`;
};

const style = (data) => {
  return `
    <style type="text/css">
      html,
      body,
      div,
      span,
      p {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }

      body {
        width: 7cm;
        margin: .1cm;
        font-size: 8pt;
        font-family: Verdana, sans-serif;
      }

      div,
      p {
        width: 6.5cm;
        margin: .2cm auto;
        word-wrap: break-word;
        line-height: 120%;
      }

      .logo {
        width: 60px;
      }

      .strong {
        font-weight: bold;
        text-transform: uppercase;
      }

      .label {
        width: 3.2cm;
        display: inline-block;
      }

      .fila {
        display: ${data.agendadoPelaFila ? "block" : "none"}
      }

      @media print {
        body {
          width: 7cm;
          margin: .1cm;
          font-size: 8pt;
          font-family: Verdana, sans-serif;
        }

        div,
        p {
          width: 6.5cm;
          margin: .2cm auto;
          word-wrap: break-word;
          line-height: 120%;
        }

        .logo {
          width: 60px;
        }

        .strong {
          font-weight: bold;
          text-transform: uppercase;
        }

        .label {
          width: 2.2cm;
          display: inline-block;
        }

        @page {
          margin: 0;
          padding: 0;
          border: 0;
        }

      }
    </style>
  `;
};

const drawPreparationProcedure = (hasPreparation, procedure, preparation) => {
  let html = "";
  if (hasPreparation === "1") {
    html = `<p>Preparo Procedimento: ${procedure}${preparation}</p> <hr />`;
  }
  return html;
};

const drawPreparationSubprocedure = (data, show) => {
  const validData = data?.length > 0;

  let html = "";

  if (show && validData) {
    data.forEach((item) => {
      html += `
      <div>
        <label>Preparo Subprocedimento: ${item.nome_suprocedimento}</label>
        <span>${item.preparo}</span>
      </div>
      <hr />
      `;
    });
  }

  return html;
};

const ScheduleThermalOneWayHTML = (pListExams, printPreparationSub) => {
  let html = ``;

  pListExams.forEach((element) => {
    html += `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8">
          ${style(element)}
        </head>
        <body>
          <div>
            <img id="imageLogoContainer" class="logo">
            ${clientNameTitle(element.cli_nome)}
          </div>

          <p>${element.uns_nome}</p>

          ${unitAddress(element)}

          <p>Referência: ${element.end_referencia}</p>

          <p>Comparecer ao exame</p>

          <p>
            Data:
            <b>${element.eha_data_exame}</b> as <b>${element.eap_hora}</b>
          </p>

          <p>
            <span class="label">Paciente:</span>
            ${element.pes_nome}
          </p>

          <p>
            <span class="label">Nome Social:</span>
            ${element.pes_nome_social}
          </p>

          <p>
            <span class="label">Prontuário:</span>
            ${element.ppr_numero}
          </p>

          <p>
            <span class="label">Pront. Único:</span>
            ${element.pac_prontuario_unico}
          </p>

          <p>
            <span class="label">Cartão Sus:</span>
            ${element.crs_numero}
          </p>

          <p>
            <span class="label">Setor:</span>
            ${element.set_nome}
          </p>

          <p>
            <span class="label">Local:</span>
            ${element.lca_nome}
          </p>

          <p>
            <span class="label">Procedimento:</span>
            ${element.stp_novo_nome_procedimento}
          </p>

          <p>
            <span class="label">Subprocedimento(s):</span>
            ${element.subprocedimentos}
          </p>

          <p>
            <span class="label">Profissional:</span>
            <span class="strong">${element.atendente}</span>
          </p>

          <p>
            Ocupação:
            <span class="strong">${element.ocp_nome}</span>
          </p>

          <p>${element.observacoes ? element.observacoes : "-"}</p>

          <p>Exame marcado no dia</p>

          <p>${element.data_marcacao} <b>as</b> ${element.hora_marcacao}</p>

          <p><b>por: </b>${element.usu_nome}</p>

          <div class="fila">
            <label style="width: 200px;">${element.agendadoPelaFila}</label>
          </div>
          <div class="fila">
            <label>${element.lblNomeRegulador}</label>
            <span>${element.nomeRegulador}</span>
          </div>

          ${effectivationPassword(element.eap_senha_efetivacao)}

          <hr/>

          ${drawPreparationProcedure(
            element.possuiRecomendacao,
            element.stp_novo_nome_procedimento,
            element.recomendacaoPreparo,
          )}

          ${drawPreparationSubprocedure(
            element.recomendacaoPreparoSub,
            printPreparationSub,
          )}
        </body>
      </html>
		`;
  });

  return html;
};

export default ScheduleThermalOneWayHTML;
