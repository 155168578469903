export default {
  GET_FORM_SATELLITE: (state) => {
    return state.form_satellite;
  },
  GET_TABLE_SATELLITE: (state) => {
    return state.table_satellite;
  },
  GET_FILTER_SATELLITE: (state) => {
    return state.filter_satellite;
  },
  GET_SELECTED_SATELLITE: (state) => state.selected_pharmacy_satellite_data,
};
