import AuthRequest from "~common/request/AuthRequest";
import Store from "@/store";
import moment from "moment";

export default () => {
  const uns_id = Store.getters["Login/GET_UNIT_HEALTH_ID"];

  return AuthRequest.get("billing/bdsia/get-current-period", { uns_id })
    .then(async (response) => {
      const validateCompetence = await validateImportCompetence(response.data);

      if (!validateCompetence) {
        Store.commit("Billing/BDSia/CLEAN_MODULE_BILLING_BDSIA");
        return { data: false };
      } else {
        const formatedPeriod = formatPeriod(response.data);
        const formatedPeriodDate = formatPeriodDate(response.data);
        Store.commit("Billing/BDSia/SET_NUMBER", formatedPeriod);
        Store.commit("Billing/BDSia/SET_PERIOD_DATE", response.data);
        Store.commit(
          "Billing/BDSia/SET_FORMATTED_PERIOD_DATE",
          formatedPeriodDate,
        );
        return { formatedPeriod: formatedPeriod, competence: response.data };
      }
    })
    .catch((error) => {
      return error;
    });
};

const formatPeriod = (pPeriod) => {
  const periodMonth = pPeriod.substr(4, 2);
  const periodYear = pPeriod.substr(0, 4);
  return `Mês ${periodMonth} de ${periodYear}`;
};

const formatPeriodDate = (pPeriod) => {
  const periodMonth = pPeriod.substr(4, 2);
  const periodYear = pPeriod.substr(0, 4);
  return `${periodMonth}/${periodYear}`;
};

const validateImportCompetence = async (pCompetenceDate) => {
  const competences = await Store.dispatch(
    "Billing/ImportBdsiaSigtap/SEARCH_AVAILABLE_COMPETENCE",
  );

  const competencesAvailable = [];

  for (const bdsia of competences.bdsiaRows) {
    const position = competences.sigtapRows.findIndex(
      (element) => element.competence === bdsia.competence,
    );
    if (position > -1) {
      competencesAvailable.push(
        moment(bdsia.competence, "YYYYMM").format("YYYY-MM"),
      );
    }
  }

  const formatDate = moment(pCompetenceDate, "YYYYMM").format("YYYY-MM");

  const validCompetenceInCalendar = competencesAvailable.includes(formatDate);

  return validCompetenceInCalendar;
};
