import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import EditProcedure from "$billing/submodules/bpa/procedures/view/register/RegisterProcedures";
export default {
  path: "/billing/bpa/procedures/edit",
  name: "billing.bpa.procedures.edit",
  components: {
    default: EditProcedure,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      {
        label: "BPA",
        link: OpenSubModule("billing"),
      },
      {
        label: "Procedimentos",
        link: "/billing/bpa/procedures",
      },
      {
        label: "Editar Procedimentos",
      },
    ],
  },
};
