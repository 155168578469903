<template lang="html">
  <svg
    width="22"
    height="22"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00076 14.3011L1.00076 4.17078C1.00076 2.97396 1.97472 2 3.17155 2L13.3019 2C14.4987 2 15.4727 2.97396 15.4727 4.17078L15.4727 7.68032C15.4727 8.07975 15.1492 8.40392 14.7491 8.40392C14.3489 8.40392 14.0255 8.07975 14.0255 7.68032L14.0255 4.17078C14.0255 3.77208 13.7013 3.44719 13.3019 3.44719L3.17155 3.44719C2.77212 3.44719 2.44795 3.77208 2.44795 4.17078L2.44795 14.3011C2.44795 14.6998 2.77212 15.0247 3.17155 15.0247L8.9603 15.0247C9.36045 15.0247 9.6839 15.3489 9.6839 15.7483C9.6839 16.1477 9.36045 16.4719 8.9603 16.4719L3.17155 16.4719C1.97472 16.4719 1.00076 15.4979 1.00076 14.3011Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6451 13.2646C18.6451 15.4789 16.8501 17.2739 14.636 17.2739C12.4221 17.2739 10.627 15.4789 10.627 13.2646C10.627 11.0507 12.4221 9.25562 14.636 9.25562C16.8501 9.25562 18.6451 11.0507 18.6451 13.2646ZM17.1847 13.2646C17.1847 13.0298 16.9943 12.8394 16.7595 12.8394L12.5074 12.8394C12.2726 12.8394 12.0822 13.0298 12.0822 13.2646C12.0822 13.4994 12.2726 13.6898 12.5074 13.6898L16.7595 13.6898C16.9943 13.6898 17.1847 13.4994 17.1847 13.2646Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Reversal",
};
</script>
