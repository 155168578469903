import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import ExamScheduleQueue from "$exam/submodules/schedule/views/queue/ExamScheduleQueue";

export default {
  path: "/exam/schedule/queue",
  name: "exam.schedule.queue",
  components: {
    default: ExamScheduleQueue,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: OpenSubModule("exam") },
      { label: "Fila de Regulação", link: "/exam/schedule/queue" },
    ],
  },
};
