<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchPharmacyTypeTransport from "./action/SearchPharmacyTypeTransport";

export default {
  name: "RgComboboxPharmacyTypeTransport",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Tipos",
    },
    /** Retorna apenas tipos de transportes que estão ATIVOS */
    onlyActiveTypes: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return SearchPharmacyTypeTransport({
        blnAtivo: this.onlyActiveTypes,
      });
    },
    formatItem(item) {
      return {
        id: item.data,
        value: item.data,
        text: item.label,
        ttr_ativo: item.ttr_ativo,
        ttr_nome: item.ttr_nome,
      };
    },
  },
};
</script>
