import Patient from "$person/patient/store";
import Report from "$person/submodules/report/store";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

export default {
  namespaced: true,
  modules: {
    Patient,
    Report,
  },
  state,
  actions,
  getters,
  mutations,
};
