const ScheduleVoucherHTML = (pData) => {
  const senha = (eap_senha_efetivacao) => {
    if (eap_senha_efetivacao) {
      return `<p><b>Senha Efetivação: </b>${eap_senha_efetivacao}</p>`;
    } else {
      return "";
    }
  };
  let html = ``;
  html += `
    <!DOCTYPE html>
    <html>

    <head>
        <meta charset="UTF-8">
        <style type="text/css">
            html,
            body,
            div,
            span,
            p {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: baseline;
            }

            body {
                width: 7cm;
                margin: .1cm;
                font-size: 8pt;
                font-family: Verdana, sans-serif;
            }

            div,
            p {
                width: 6.5cm;
                margin: .2cm auto;
                word-wrap: break-word;
                line-height: 120%;
            }

            .logo {
                width: 60px;
            }

            .strong {
                font-weight: bold;
                text-transform: uppercase;
            }

            .label {
                width: 2.2cm;
                display: inline-block;
						}

						.fila {
							display: ${pData.agendadoPelaFila ? "block" : "none"}
						}

            @media print {
                body {
                    width: 7cm;
                    margin: .1cm;
                    font-size: 8pt;
                    font-family: Verdana, sans-serif;
                }

                div,
                p {
                    width: 6.5cm;
                    margin: .2cm auto;
                    word-wrap: break-word;
                    line-height: 120%;
                }

                .logo {
                    width: 60px;
                }

                .strong {
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .label {
                    width: 2.2cm;
                    display: inline-block;
                }

                @page {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }

            }
        </style>
    </head>

    <body>
        <div>
					<img id="imageLogoContainer" class="logo">
            ${pData.cli_nome}
        </div>

        <p>${pData.uns_nome}</p>

        <p>${pData.tlg_nome} ${pData.end_logradouro}, ${pData.end_numero} ${
    pData.end_complemento
  } - ${pData.bai_nome} ${pData.mun_nome}</p>

        <p>Referência: ${pData.end_referencia}</p>

        <p>Comparecer ao exame</p>

        <p>Data: <b>${pData.eha_data_exame}</b> as
          <b>${pData.eap_hora}</b></p>

        <p><span class="label">Paciente:</span> ${pData.pes_nome}</p>

        <p><span class="label">Nome Social:</span> ${pData.pes_nome_social}</p>

        <p><span class="label">Prontuário:</span> ${pData.ppr_numero}</p>

        <p><span class="label">Pront. Único:</span> ${
          pData.pac_prontuario_unico
        }</p>

        <p><span class="label">Cartão Sus:</span> ${pData.crs_numero}</p>

        <p><span class="label">Setor:</span> ${pData.set_nome}</p>

        <p><span class="label">Local:</span> ${pData.lca_nome}</p>

        <p><span class="label">Procedimento:</span> ${
          pData.stp_novo_nome_procedimento
        }</p>

        <p><span class="label">SubProcedimentos:</span> ${
          pData.subprocedimentos
        }</p>

        <p>Profissional: <span class="strong">${pData.atendente}</span></p>

        <p>Ocupação: <span class="strong">${pData.ocp_nome}</span</p>

        <p>${pData.observacoes ? pData.observacoes : ""}</p>

        <p>Exame marcado no dia</p>

        <p><b>${pData.data_marcacao} </b> as
          <b> ${pData.hora_marcacao}</b></p>

        <p>por: <b>${pData.usu_nome}</b></p>

        <div class="fila"><label style="width: 200px;">${
          pData.agendadoPelaFila
        }</label></div>
        <div class="fila"><label>${pData.lblNomeRegulador}</label>
        <span><b>${pData.nomeRegulador}</b></span></div>
        ${senha(pData.eap_senha_efetivacao)}
        ${pData.recomendacaoPreparo}
        <hr />
			</body>

    </html>
		`;

  return html;
};

export default ScheduleVoucherHTML;
