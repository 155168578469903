<template lang="html">
  <div class="main-page">
    <section class="main-page--main-content">
      <router-view name="header" />
      <section class="main-content">
        <router-view :key="$route.path" />
      </section>
    </section>

    <RefreshLoginVue id="refreshLoginFast" style="display: none" />
    <router-view name="rootModal" />
    <MedicalLoaderGlobal />
    <PermissionLoaderGlobal />
  </div>
</template>
<script>
import RefreshLoginVue from "./app/access-control/view/RefreshLogin.vue";

export default {
  name: "App",
  components: {
    RefreshLoginVue,
  },
  data() {
    return {
      beforeUnloadTime: 0,
      gapTime: 0,
    };
  },

  computed: {
    isLoginPage() {
      return window.location.pathname.includes("/login");
    },
  },

  async created() {
    this.$store.dispatch("Login/LOAD_LOGIN_INFO");
  },

  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
    this.loadModalRefreshToken();
    addEventListener("popstate", async (event) => {
      if (
        document.getElementById("refreshLoginFast").getAttribute("style") ===
        "display: flex"
      ) {
        document
          .querySelector("#refreshLoginFast")
          .setAttribute("style", "display: none");
        localStorage.setItem("refresh-token-store", "inactive");
        this.logoff();
      }
    });
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e),
    );

    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },

  methods: {
    beforeunloadHandler() {
      this.beforeUnloadTime = new Date().getTime();
    },

    async unloadHandler(event) {
      this.gapTime = new Date().getTime() - this.beforeUnloadTime;

      if (this.gapTime <= 5) {
        if (!this.isLoginPage) {
          await this.logoff();
        }
      }
    },

    async logoff() {
      await this.$store.dispatch("Login/LOGOFF");
      this.$router.push({ name: "Login" });
    },

    loadModalRefreshToken() {
      const isRefresh = localStorage.getItem("refresh-token-store");
      if (isRefresh === "active" || isRefresh === null) {
        if (
          !(
            window.location.pathname.includes("/login") ||
            window.location.pathname === "/"
          )
        ) {
          document
            .querySelector("#refreshLoginFast")
            .setAttribute("style", "display: flex");
          localStorage.setItem("refresh-token-store", "active");
        } else {
          document
            .querySelector("#refreshLoginFast")
            .setAttribute("style", "display: none");
          localStorage.setItem("refresh-token-store", "inactive");
        }
      }
    },
  },
};
</script>
