import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBasicAttentionPatient($pacId: Int!) {
    SearchBasicAttentionPatient(pab_id_pacientes: $pacId) {
      count
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  if (data.SearchBasicAttentionPatient.count < 1) return false;

  return true;
};
