import moment from "moment";

const PatientCareHistoryHTML = (pInfo, pData) => {
  const emissao = moment().format("DD/MM/YYYY");
  let body = "";

  pData.forEach((item) => {
    body += "<tr>";
    body += "<td>" + item.nome_modulo + "</td>";
    body += "<td>" + item.uns_nome + "</td>";
    body += "<td>" + item.set_nome + "</td>";
    body += "<td>" + item.usu_nome + "</td>";
    body += "<td>" + item.dataHora + "</td>";
    body += "<td>" + item.data_agendamento + "</td>";
    body += "<td>" + item.data_order_by_max + "</td>";
    body += "<td>" + item.regulacao + "</td>";
    body += "<td>" + item.possui_alteracao + "</td></tr>";
  });

  const html = `
  <!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <title>HISTORICO GERAL DO PACIENTE</title>
      <style type="text/css">

      * {
        margin: 0;
        padding: 0;
        font-family: "Montserrat", sans-serif;
      }

    body {
      display: block;
      margin: 2em;
      font: 12pt roboto, Arial, sans-serif;
      color: #444444;
    }

    h1 {
      font-size: 24pt;
    }

    h2 {
      font-size: 18pt;
    }

    h3 {
      font-size: 11pt;
    }

    div {
      width: 100%;
    }

    section {
      display: flex;
      margin-bottom: 0.5rem;
    }

    .mg-l-05 {
      margin-left: 0.5cm;
    }

    header,
    footer,
    aside,
    nav,
    form,
    iframe {
      display: none;
    }

    p {
      font-size: 8.5pt;
      font-weight: normal;
    }

    .text {
      font-weight: 600;
    }

      table {
        margin: 10px 0px;
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
      }

      th:nth-child(1),
      tr td:nth-child(1) {
          width: 20%;
          font-size: 7.5pt;
      }

      th:nth-child(2),
      tr td:nth-child(2) {
          width: 30%;
          word-break: break-word;
          font-size: 8pt;
      }

      th:nth-child(3),
      tr td:nth-child(3) {
        width: 30%;
        word-break: break-word;
        font-size: 8pt;
      }

      th:nth-child(4),
      tr td:nth-child(4) {
        width: 30%;
        word-break: break-word;
        font-size: 8pt;
      }

      th:nth-child(5),
      tr td:nth-child(5) {
        width: 30%;
        word-break: break-word;
        font-size: 8pt;
      }

      th:nth-child(6),
      tr td:nth-child(6) {
          width: 20%;
      }

      th:nth-child(7),
      tr td:nth-child(7) {
          width: 20%;
          font-size: 8pt;
      }

      th:nth-child(8),
      tr td:nth-child(8) {
          width: 20%;
          font-size: 8pt;
      }

      th:nth-child(9),
      tr td:nth-child(9) {
          width: 20%;
          font-size: 8pt;
      }


      td, th {
        font-size: 8pt;
        border: 1px solid #cdcdcd;
        padding: 0.1cm 0.09cm;
        text-align: center;
      }

      tr {
        border: 1px solid #cdcdcd !important;
      }

    /* print styles  */
    @media print {
      body {
        margin: 0;
        background-color: #fff;
        font-size: 12pt;
      }

      p {
        widows: 3;
        orphans: 3;
      }
    }

    /* HEADER */
    .header {
      border-bottom: 1px solid #cdcdcd;
      padding-bottom: 0.3cm;
      height: 5rem;
    }

    .img-header {
      width: 2.5cm;
      border: 0;
    }

    .center {
      text-align: center;
      justify-content:center;
      align-self: center;
    }

    .weight {
      font-weight: bolder;
    }

    .title-header {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-evenly;
    }

    /* FILTER */
    .filter {
      display: grid;
      row-gap: 0.3cm;
      column-gap: 0.5cm;
      width: fit-content;
      justify-content: space-between;
    }

    .margin-center-filter {
      margin: 0 0.3cm;
    }

    img {
      width: 1.8cm;
      height: 1.9cm;
      margin-top: 2px;
    }

    .esus {
      height: 55px;
      width: 55px;
      margin-top: 6px;
    }
    </style>

    <page size="A4">

    <section class="header">
      <div class="img-header esus">
        <img src="/static/images/mini-logo-esus.png" class="esus"/>
      </div>

      <div class="title-header">
        <h3 class="mg-l-05">HISTÓRICO GERAL DO PACIENTE</h3>

        <p class="mg-l-05">
        <span class="text">${pInfo.unitHealthData.uns_nome} / ${
    pInfo.unitHealthData.mun_nome
  } - ${pInfo.unitHealthData.est_sigla}</span>
        </p>

        <p class="mg-l-05">
        <span class="text">${pInfo.unitHealthData.bai_nome} - Nº ${
    pInfo.unitHealthData.end_numero
  }</span>
        </p>

        <p class="mg-l-05">
          Data de Emissão: <span class="text">${emissao}</span>
        </p>
        <p class="mg-l-05">
          Usuário: <span class="text">${pInfo.userName}</span>
        </p>
    </section>

    </head>

    <body>
      <div class="patient">
      <p>Paciente: <b>${pInfo.patientName || pInfo.pac_nome}</b></p>
      </div>

      <table class="table">
      <thead>
        <tr>
          <th>Módulo</th>
          <th>Unidade de Saúde</th>
          <th>Setor</th>
          <th>Usuário de Criação do Registro</th>
          <th>Data de criação</th>
          <th>Data do Agendamento</th>
          <th>Data da última modificação</th>
          <th>Regulação</th>
          <th>Alteração</th>
        </tr>
      </thead>
      <tbody>
          ${body}
      </tbody>
    </table>
    </page>
  </body>
</html>
  `;

  return html;
};

export default PatientCareHistoryHTML;
