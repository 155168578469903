const AttendedBulletinFrontPageHTML = (pData) => {
  const hasIdade = pData.idade;
  const hasDay = hasIdade && pData.idade.days >= 0;
  const dayTitle = hasDay ? pData.idade.days + " dia(s)" : "";
  const hasMonth = hasIdade && pData.idade.months > 0;
  const monthTitle = hasMonth ? pData.idade.months + " mês(es) " : "";
  const hasYear = hasIdade && pData.idade.years > 0;
  const yearTitle = hasYear ? pData.idade.years + " ano(s) " : "";

  const fullAge = yearTitle + monthTitle + dayTitle;

  const html = `
  <!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <title>Esus</title>
    <style type="text/css">
      body {
        font-size: 12px;
        font-family: Arial, Helvetica, sans-serif;
      }

      label {
        padding-left: 10px;
      }

      .table {
        width: 210mm;
      }

      .border {
        border: 1px solid black;
      }

      .border-bottom {
        border-bottom: 1px solid black;
      }

      .border-right {
        border-right: 1px solid black;
      }

      .size-53-mm {
        width: 53mm;
      }

      .size-120-mm {
        width: 120mm;
      }

      .size-90-mm {
        width: 90mm;
      }

      .size-82-mm {
        width: 82mm;
      }

      .size-70-mm {
        width: 70mm;
      }

      .size-50 {
        width: 50%;
      }

      .size-20 {
        width: 20%;
      }

      .mg-bottom {
        margin-bottom: 1mm;
      }

      .mg-left {
        margin-left: 5mm;
      }

      .mg-left-8 {
        margin-left: 8mm;
      }

      .checkbox {
        padding: 0.1px 7px;
        margin: 0 6px;
        border: 1px solid black;
        width: 0.5mm;
        height: 0.5mm;
      }

      .textarea-25 {
        padding-bottom: 25mm;
      }

      .textarea-35 {
        padding-bottom: 35mm;
      }

      .center {
        text-align: center;
      }

      .mg-top-end {
        margin-top: 20px;
      }

    </style>
  </head>

  <body>
    <table cellpadding="4" cellspacing="0" class="table">
      <tr class="title">
        <td>${pData.uns_nome}</td>
      </tr>
      <tr class="title">
        <td>BOLETIM DE ATENDIMENTO MÉDICO: <label class="label">${
          pData.bol_numero
        }</label></td>
      </tr>
      <tr class="title">
        <td>
          CLASSIFICAÇÃO DE RISCO: <label class="label">${
            pData.classificacao
          }</label>
        </td>
        <td class="size-53-mm">SERVIÇO DE PRONTO SOCORRO</td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border">
      <tr class="border">
        <td>DATA: <label></label>${pData.bol_data || ""}</td>
        <td>HORA: <label></label>${pData.bol_hora || ""}</td>
        <td>USUÁRIO: <label>${pData.usu_nome || ""}</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td>NOME: <label>${pData.pes_nome || ""}</label></td>
        <td class="size-53-mm">NASCIMENTO: <label>${
          pData.pes_nascimento || ""
        }</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td class="size-82-mm">PROFISSÃO: <label>${
          pData.pes_profissao || ""
        }</label></td>
        <td>SEXO: <label>${pData.sex_sigla || ""}</label></td>
        <td>ETNIA: <label>${pData.etn_nome || ""}</label></td>
        <td>IDADE: <label>${fullAge || ""} </label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td class="size-50">MÃE: <label>${pData.pes_mae || ""}</label></td>
        <td class="size-50">PAI: <label>${pData.pes_pai || ""}</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td class="size-70-mm">PRONTUÁRIO: <label>${
          pData.ppr_numero || ""
        }</label></td>
        <td class="size-70-mm">PRONTUÁRIO ÚNICO: <label>${
          pData.pac_prontuario_unico || ""
        }</label></td>
        <td class="size-70-mm">CNS: <label>${
          pData.cartao_sus || ""
        }</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td class="size-50">CPF: <label>${pData.cpf || ""}</label></td>
        <td class="size-50">RG: <label>${pData.rg || ""}</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td>ESTADO CIVIL: <label>${pData.ecv_nome || ""}</label></td>
        <td>NATURALIDADE: <label>${pData.naturalidade || ""}</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td class="size-120-mm">ENDEREÇO: <label>${
          pData.end_logradouro || ""
        }</label></td>
        <td>NÚMERO: <label>${pData.end_numero || ""}</label></td>
        <td>CEP: <label>${pData.end_cep || ""}</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border">
      <tr>
        <td class="size-70-mm">ESTADO: <label>${
          pData.est_nome || ""
        }</label></td>
        <td class="size-70-mm">MUNICIPIO: <label>${
          pData.mun_nome || ""
        }</label></td>
        <td class="size-70-mm">BAIRRO: <label>${
          pData.bai_nome || ""
        }</label></td>
      </tr>
    </table>
    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td>TELEFONE: <label>${pData.telefone || ""}</label></td>
        <td class="size-53-mm">SANGUE: <label>${pData.tps_rh || ""}</label></td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td rowspan="3" class="border-right size-50">
          <span class="checkbox" style="${
            pData.res_nome && pData.res_nome === "PAI"
              ? "background-color:black;"
              : ""
          }"></span>
          PAI
          <span class="checkbox" style="${
            pData.res_nome && pData.res_nome === "MAE"
              ? "background-color:black;"
              : ""
          }"></span>
          MÃE
          <span class="checkbox" style="${
            pData.res_nome && pData.res_nome === "CÔNJUGE"
              ? "background-color:black;"
              : ""
          }"></span>
          CÔNJUGE
          <span class="checkbox" style="${
            pData.res_nome && pData.res_nome === "OUTRO"
              ? "background-color:black;"
              : ""
          }"></span>
          OUTRO
          <span class="checkbox"  style="${
            pData.res_nome && pData.res_nome === "NENHUM"
              ? "background-color:black;"
              : ""
          }"></span>
          NENHUM
        </td>
        <td>SETOR: <label>${pData.set_nome || ""}</label></td>
      </tr>
      <tr>
        <td>ESPECIALIDADE: <label>${pData.esp_nome || ""}</label></td>
      </tr>
      <tr>
        <td>CONVÊNIO: <label>${pData.pls_nome || ""}</label></td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td class="size-50">TIPO CHEGADA: <label>${
          pData.tpc_nome || ""
        }</label></td>
        <td>TIPO OCORRÊNCIA: <label>${pData.tpo_nome || ""}</label></td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td class="size-50">QUEIXA PRINCIPAL: <label></label></td>
        <td>CAUSA DA LESÃO: <label></label></td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td class="textarea-25">OBSERVAÇÕES DE ENTRADA: ${
          pData.bol_observacao || ""
        }</td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td class="textarea-35">ANAMNESE:</td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td>
          P.A: <label class="mg-left">X</label
          ><label class="mg-left-8">mmHg</label>
        </td>
        <td class="size-20">F.C.: <label class="mg-left-8">bpm</label></td>
        <td class="size-20">T.AX.: <label class="mg-left-8">°C</label></td>
        <td class="size-20">FR.: <label class="mg-left-8">lpm</label></td>
        <td>PESO.: <label class="mg-left-8">kg</label></td>
      </tr>
    </table>

    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td>
          <span class="checkbox"></span>
          EUPNÉICO
        </td>
        <td>
          <span class="checkbox"></span>
          DISPNÉICO
        </td>
        <td>
          <span class="checkbox"></span>
          TAQUIPNÉICO
        </td>
        <td>
          <span class="checkbox"></span>
          ACIANÓTICO
        </td>
        <td>
          <span class="checkbox"></span>
          CIANÓTICO
        </td>
      </tr>
      <tr>
        <td>
          <span class="checkbox"></span>
          HIDRATADO
        </td>
        <td>
          <span class="checkbox"></span>
          DESIDRATADO
        </td>
        <td>
          <span class="checkbox"></span>
          NORMOCORADO
        </td>
        <td>
          <span class="checkbox"></span>
          HIPOCORADO
        </td>
        <td>
          <span class="checkbox"></span>
          SUDOREICO
        </td>
      </tr>
      <tr>
        <td>
          <span class="checkbox"></span>
          ANICTÉRICO
        </td>
        <td>
          <span class="checkbox"></span>
          ICTÉRICO
        </td>
        <td>
          <span class="checkbox"></span>
          PULSO CHEIO
        </td>
        <td>
          <span class="checkbox"></span>
          PULSO FINO
        </td>
        <td>
          <span class="checkbox"></span>
          ETILISMO
        </td>
      </tr>
      <tr>
        <td>
          <span class="checkbox"></span>
          ACORDADO
        </td>
        <td>
          <span class="checkbox"></span>
          ORIENTADO
        </td>
        <td>
          <span class="checkbox"></span>
          DESORIENTADO
        </td>
        <td>
          <span class="checkbox"></span>
          TORPOROSO
        </td>
        <td>
          <span class="checkbox"></span>
          COMATOSO
        </td>
      </tr>
      </tr>
      <tr>
        <td>
          <span class="checkbox"></span>
          ISOCÓRICO
        </td>
        <td>
          <span class="checkbox"></span>
          ANISOCÓRICO
        </td>
        <td>
          <span class="checkbox"></span>
          MIDRIÁTICO
        </td>
        <td>
          <span class="checkbox"></span>
          MIÓTICO
        </td>
        <td>
          <span class="checkbox"></span>
          RIGIDEZ NUCA
        </td>
      </tr>
    </table>


    <table cellpadding="4" cellspacing="0" class="table border mg-bottom">
      <tr>
        <td>CABEÇA:</td>
      </tr>
      <tr>
        <td>TÓRAX:</td>
      </tr>
      <tr>
        <td>ABDOME:</td>
      </tr>
      <tr>
        <td>MEMBROS:</td>
      </tr>
    </table>
    <div class="center">${pData.municipio_unidade} - ${
    pData.estado_unidade
  } - ${pData.data_atual}</div>

    <div class="center mg-top-end">
      <hr class="size-50">
      <i>Assinatura do Paciente</i>
    </div>
  </body>
</html>

    `;
  return html;
};

export default AttendedBulletinFrontPageHTML;
