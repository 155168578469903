import LIST_CONSOLIDATED_BILLING from "./ListConsolidatedBilling";
import SAVE_CONSOLIDATED_BILLING from "./SaveConsolidatedBilling";
import REMOVE_CONSOLIDATED_BILLING from "./RemoveConsolidatedBilling";
import GET_QUANTITY_SHEET_CONSOLIDATED_BILLING from "./GetQuantitySheetConsolidatedBilling";
import GET_PROCEDURE_DETAILS from "./GetProcedureDetails";
import LIST_BPAC from "./ListBpac";

export default {
  LIST_CONSOLIDATED_BILLING,
  SAVE_CONSOLIDATED_BILLING,
  REMOVE_CONSOLIDATED_BILLING,
  GET_QUANTITY_SHEET_CONSOLIDATED_BILLING,
  GET_PROCEDURE_DETAILS,
  LIST_BPAC,
};
