import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveConsolidatedBilling(
    $consolidatedBilling: ConsolidatedBillingInputType
  ) {
    SaveConsolidatedBilling(consolidatedBilling: $consolidatedBilling) {
      fco_id
      fco_codigo_procedimento
      fco_cbo
      fco_quantidade
      fco_idade
      fco_folha
      procedure {
        NO_PROCEDIMENTO
      }
      occupation {
        NO_OCUPACAO
      }
    }
  }
`;

export default async ({ commit, dispatch }, { variables = {} }) => {
  const { data = null } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((pErr) => {
    throw pErr;
  });

  return data;
};
