const Request = (pRequest, pItensRequest, pInfo) => {
  const dataEmissao = new Date().toLocaleString();

  let produtos = "";

  pItensRequest.forEach((item) => {
    const code = item.code || item.mpd_codigo;
    const product = item.name || item.mpd_novo_principio_ativo;
    const quantity = item.amountDismissed || item.rec_quantidade;
    produtos += "<div>";
    produtos += "<p><b>Cód: </b> " + code + "</p>";
    produtos += "<p><b>Produto: </b> " + product + "</p>";
    produtos += "<p><b>Qtd: </b>" + quantity + "</p>";
    produtos += "</div>";
  });

  const html = `
    <html lang="pt-br">
      <head>
          <meta charset="utf-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <title> Comprovante de Recebimento.html</title>

          <style type="text/css">
          html, body, div, span, p, table, tr, td {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
          }

          body {
            width: 7cm;
            margin: .1cm;
            padding: 0 0 1cm 0;
            font-size: 8pt;
            font-family: Verdana, sans-serif;
          }

          div, p {
              width: 6.5cm;
            margin: .2cm auto;
              word-wrap: break-word;
              line-height: 120%;
          }

          .relacao-produto div {
            border-top: 1px dotted #000;
          }

          .relacao-produto div:last-child {
            border-bottom: 1px dotted #000;
          }

          .center {
            text-align: center;
          }

          .recebi {
            padding: .1cm 0 0 0;
          }

          .label {
            width: 2.2cm;
            display: inline-block;
          }

          .label-assinatura {
            width: 1.8cm;
            display: inline-block;
          }

          hr {
            margin: .7cm 0;
          }

          @media print{
               body {
              width: 7cm;
              margin: .1cm;
              padding: 0 0 1cm 0;
              font-size: 8pt;
              font-family: Verdana, sans-serif;
            }

            div, p {
                width: 6.5cm;
              margin: .2cm auto;
                word-wrap: break-word;
                line-height: 120%;
            }

            .relacao-produto div {
              border-top: 1px dotted #000;
            }

            .relacao-produto div:last-child {
              border-bottom: 1px dotted #000;
            }

            .center {
              text-align: center;
            }

            .recebi {
              padding: .1cm 0 0 0;
            }

            .label {
              width: 2.2cm;
              display: inline-block;
            }

            .label-assinatura {
              width: 1.8cm;
              display: inline-block;
            }

            hr {
              margin: .7cm 0;
            }

              @page {
                margin: 0;
                padding: 0;
                border: 0;
              }

          }
        </style>

      </head>
      <body>

      <p class="center">${pInfo.mun_nome || "-"}</p>
      <p class="center">${pInfo.clientName}</p>
      <p class="center">${pInfo.uns_nome}</p>

      <p><span class="label">Farmácia:</span>${pRequest.far_nome}</p>
      <p><span class="label">Paciente:</span>${pRequest.pes_nome}</p>
      <p><span class="label">Data de Recebimento:</span>${pRequest.rec_data}</p>
      <p><span class="label">Emissor:</span>${pInfo.userName}</p>
      <p><span class="label">Dispensador:</span>${pRequest.usu_nome}</p>
      <p><span class="label">Impresso em:</span>${dataEmissao}</p>

      <div class="relacao-produto">
        ${produtos}
      </div>

      <p class="recebi center">Recebi de ${pInfo.clientName} os medicamentos
      acima descritos.</p>
      <p class="center">_________________________</p>
      <p class="center">Assinatura</p>

      <p><span class="label-assinatura">Identidade:</span>_________________________</p>
      <p><span class="label-assinatura">CPF:</span>_________________________</p>
      <p><span class="label-assinatura">Fone(s):</span>_________________________</p>
      <p><span class="label-assinatura">Endereço:</span>_________________________</p>

      <hr>






      </body>
    </html>
  `;

  return html;
};

export default Request;
