<style src="./RgButtonPrintSurgery.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-printer">
    <button
      :disabled="!surgerySelected"
      type="button"
      class="btn btn-small btn-clean"
      @click="printSurgery('/surgery-center/print-surgery')"
    >
      <span class="button">Imprimir</span>
      <div v-if="printCalculate" class="rg-spinner rg-spinner__medium" />
    </button>
    <RgIframe v-if="showPrint" :src="printLink" class="iframe-hidden" />
  </div>
</template>
<script>
import RgIframe from "~tokio/foundation/rg-iframe/RgIframe";
export default {
  name: "RgButtonPrintSurgery",
  components: {
    RgIframe,
  },
  props: {
    surgery: {
      default: null,
    },
  },
  data: () => {
    return {
      printCalculate: false,
      showPrint: false,
      hashRefresh: "123",
    };
  },
  computed: {
    surgeryToPrint() {
      return this.surgery
        ? this.surgery
        : this.$store.state.SurgeryCenter.Surgery.selectedItem;
    },
    surgerySelected() {
      return this.surgeryToPrint !== null;
    },
    printLink() {
      const FILTER = this.preparePrintData(this.surgeryToPrint);
      const AUX = `${this.linkTo}?filter=${FILTER}&${this.hashRefresh}`;
      return encodeURI(AUX);
    },
  },
  methods: {
    preparePrintData(completeFilter) {
      const dataToGrab = [
        "pes_nome",
        "cci_id_status_controles_cirurgico",
        "cci_numero",
        "bed_sector",
        "bed_place",
        "pes_nascimento",
        "bed",
        "unitRecordNumber",
        "pes_mae",
        "bed_number",
        "cns",
        "inc_nome",
        "fun_nome",
        "set_nome",
        "lca_nome",
        "cta_nome",
        "rax_nome",
        "cci_cti",
        "tcr_nome",
        "cci_reserva_sangue",
        "tpp_nome",
        "cci_data_inicio",
        "cci_hora_inicio",
        "cci_duracao",
        "procedures",
        "cci_observacao",
      ];
      const pData = {
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
      };
      dataToGrab.forEach((key) => {
        pData[key] = completeFilter[key];
      });
      return JSON.stringify(pData);
    },
    printSurgery(pDoc) {
      this.printCalculate = true;
      if (this.surgerySelected) {
        this.linkTo = pDoc;
        this.hashRefresh = new Date().getTime();
        this.showPrint = true;
        setTimeout(() => {
          this.printCalculate = false;
          this.showPrint = false;
        }, 2000);
      } else {
        this.printCalculate = false;
        this.showPrint = false;
      }
    },
  },
};
</script>
