<template>
  <div v-if="show" class="modal-observation-request">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title"> {{ modalTitle }} </span>
      </div>

      <div slot="body" class="body">
        <FormBase v-show="!seeResults" title="Registro de Obs." class="form">
          <div class="textarea">
            <RgTextArea
              ref="observation"
              v-model="form.observation"
              :maxlength="150"
              :rules="{ fn: validateObservation, required: true }"
              label="Observação"
            />
          </div>
          <div class="actions">
            <RgCancelButton medium @click="close"></RgCancelButton>
            <RgSaveButton
              :disabled="form.observation.length < 5"
              medium
              @click="registerObservation"
            ></RgSaveButton>
          </div>
        </FormBase>

        <FormBase title="Observações">
          <div v-if="verifyLengthList">
            <SmartTable
              ref="smartTable"
              name="ObservationRequest"
              class="smart-table"
              :columns="COLUMNS"
              :body="mutableObservationList"
              :total="mutableObservationList.length"
              :initial-columns="1"
              :showPagination="false"
              removeBtnColumns
            />
          </div>
          <div v-else class="no-results">
            <IconInfoHelper class="icon" hollow :background="'#6f8894'" />
            <span class="message">
              REGISTRE UMA OBSERVAÇÃO PARA EXIBIR AQUI
            </span>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgTextArea,
  RgCancelButton,
  RgSaveButton,
  IconInfoHelper,
} from "~tokio/primitive";

import { FormBase, SmartTable } from "~tokio/foundation";
export default {
  name: "ModalObservationRequest",
  components: {
    RgBaseModal,
    FormBase,
    SmartTable,
    RgTextArea,
    RgCancelButton,
    RgSaveButton,
    IconInfoHelper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestData: {
      type: Array,
      default: () => [],
    },
    seeResults: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutableObservationList: [],
      form: {
        observation: "",
      },
    };
  },
  computed: {
    modalTitle() {
      return this.seeResults
        ? "Visualizar observações sobre a Solicitação"
        : "Incluir observações sobre a Solicitação";
    },
    verifyLengthList() {
      return (
        this.mutableObservationList && this.mutableObservationList.length > 0
      );
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.searchObservation();
      }
    },
  },
  created() {
    this.COLUMNS = [
      { name: "Observação", key: "observacao", align: "left" },
      { name: "Usuário", key: "usu_nome", align: "left", active: "true" },
      { name: "Data", key: "data_observacao", align: "left", active: "true" },
      { name: "Hora", key: "hora_observacao", align: "left", active: "true" },
    ];
  },
  methods: {
    validateObservation(pValue, pErrors) {
      const format = /[!@#$%^&*<>`°ºª§£?¢¬~()_={};':"\\||]+/gi;
      if (format.test(pValue)) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: barra, ponto, traço, vírgula e símbolo de soma",
        );
        return false;
      }
      return true;
    },
    async searchObservation() {
      try {
        this.$loader.start();
        const requestData = this.requestData[0];

        const variables = {
          arrFiltros: {
            far_id: requestData.far_id_origem
              ? requestData.far_id_origem
              : requestData.far_id,
            ped_id: requestData.ped_id,
            ped_numero: requestData.ped_numero,
          },
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_OBSERVATION_REQUEST",
          variables,
        );

        this.mutableObservationList = result.dados.recordSet;
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao buscar observações",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async registerObservation() {
      try {
        this.$loader.start();
        const requestData = this.requestData[0];

        const variables = {
          arrFormData: {
            sob_id_farmacias: requestData.far_id_origem
              ? requestData.far_id_origem
              : requestData.far_id,
            sob_id_pedidos: requestData.ped_id,
            sob_observacao: this.form.observation,
          },
        };

        await this.$store.dispatch(
          "Pharmacy/Satellite/REGISTER_OBSERVATION_REQUEST",
          variables,
        );

        this.form.observation = "";
        await this.searchObservation();

        this.$toaster.success("Observação cadastrada com sucesso");
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao cadastrar observação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    close() {
      this.mutableObservationList = [];
      this.form.observation = "";
      this.$emit("close");
    },
  },
};
</script>
