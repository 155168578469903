import SEARCH_CLASSIFICATIONS from "./SearchClassifications";
import GET_CLASSIFICATIONS_BY_SERVICE from "./GetClassificationsByService";
import REGISTER_CLASSIFICATION from "./RegisterClassification";
import OBTAIN_CLASSIFICATION_BY_SERVICE from "./ObtainClassificationByService";
export default {
  SEARCH_CLASSIFICATIONS,
  GET_CLASSIFICATIONS_BY_SERVICE,
  REGISTER_CLASSIFICATION,
  OBTAIN_CLASSIFICATION_BY_SERVICE,
};
