import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import BuildComplexity from "$billing/submodules/complexity/view/BuildComplexity.vue";

export default {
  path: "/billing/complexity",
  name: "billing.complexity",
  components: {
    default: BuildComplexity,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      { label: "Atribuir Complexidade", link: "/complexity" },
    ],
  },
};
