<template>
  <Modulebox :granted="disableModuleBox" :title="title" class="patient-details">
    <div v-show="edit" slot="info" class="manager-page-info">
      <Tooltip
        :message="'Data de cadastro: ' + drawRegisterDate()"
        startOpen
        class="tooltip"
      >
        <IconInfoHelper slot="icon" hollow />
      </Tooltip>
    </div>

    <div slot="title" class="manager-page">
      <RgDropdown
        v-show="showButtonHasActiveBond"
        id="patient-has-bond-ab"
        large
        label="Vinculado ao AB"
        class="not-linked-ab"
        :disabled="disabledByModal"
        :item="dataPatientBasicAttention"
        :action-options="itemActionOptions(dataPatientBasicAttention)"
      >
        <IconReload slot="icon" />
      </RgDropdown>

      <LargeHollowButton
        v-show="showButtonHasNoBondOrInactiveBond"
        id="patient-has-no-bond-ab"
        :disabled="disabledByModal"
        :label="'Vincular ao AB'"
        title="Vincular ao AB"
        class="linked-ab"
        borderColor="#26789F"
        fontColor="#26789F"
        @click="createPatientBondBasicAttention"
      >
        <div slot="icon" class="icon">
          <IconAdd :color="'#26789f'" />
        </div>
      </LargeHollowButton>

      <RgEditButton
        v-show="edit"
        id="edit-patient"
        large
        :permission="canEdit"
        :disabled="disableEditPatient || disableEdit"
        title="Editar Cadastro do Paciente"
        @click="editButton"
      />
      <RgHistoryButton
        v-show="edit"
        id="history-patient"
        small
        :disabled="disabledByModal"
        :permission="canHistory || disabledByModal"
        title="Histórico de Atendimentos"
        @click="patientCareHistory"
      />
    </div>

    <QuickPatientDetails
      ref="patientDetails"
      :disabled="edit && !this.isEdit"
      :class="{
        disable: edit && !this.isEdit,
      }"
      :isToSearchCep="this.isEdit"
      show-anvil-button
      @fail="statusFail"
      @success="statusSuccess"
      @isBloquedPatient="getBloquedPatientValue"
      @listDeficient="selectedDeficient"
    />

    <footer slot="footer" class="footer">
      <div class="radio-active">
        <RgRadioCustom
          v-show="showRgRadioActiveInactive"
          id="radio"
          ref="radio"
          v-bind="propsRadioCustom"
          :canUnselect="false"
          :class="{
            disable:
              (edit && !this.isEdit) || !edit || !dataPatient.patient.pac_id,
          }"
          :permission="canHabilitInabilit"
          :disabled="
            (edit && !this.isEdit) || !edit || !dataPatient.patient.pac_id
          "
          @input="onInputRadioCustom"
        />

        <Tooltip
          v-show="!showRgRadioActiveInactive"
          :class="{
            disable: edit && !this.isEdit,
          }"
          showUp
        >
          <div slot="icon">
            <RgRadioCustom
              v-show="!showRgRadioActiveInactive"
              id="radio"
              ref="radio"
              v-bind="propsRadioCustom"
              :canUnselect="false"
              :class="{
                disable:
                  (edit && !this.isEdit) ||
                  !edit ||
                  !dataPatient.patient.pac_id,
              }"
              :permission="canHabilitInabilit"
              :disabled="
                (edit && !this.isEdit) || !edit || !dataPatient.patient.pac_id
              "
              @input="onInputRadioCustom"
            />
          </div>
          <div slot="content" class="content">
            <strong class="status"> Cadastro Inativo <br /> </strong>

            <span
              v-if="dataPatient.patient.pac_data_inabilitacao"
              class="title"
            >
              Data:
              <strong>
                {{ dataPatient.patient.pac_data_inabilitacao }}
              </strong>
              <br />
              Usuário:
              <strong> {{ dataPatient.patient.inactivated_by }} </strong>
              <br />
              Motivo:
              <strong>
                {{ dataPatient.patient.disqualification_reasons }}
              </strong>
            </span>
            <span v-else class="title">
              <strong>
                Usuário: {{ dataPatient.patient.inactivated_by }}
              </strong>
              <br />
              <strong
                >Motivo: {{ dataPatient.patient.disqualification_reasons }}
              </strong>
              <br />
            </span>
          </div>
        </Tooltip>
      </div>
      <RgTagCustom id="deficient-tag" size="18px" v-bind="propsTagCustom" />

      <div class="painel">
        <RgCleanButton
          v-show="!existsPesId && !isNewBorn"
          id="limpar-cadastro-cliente"
          small
          data-id="limpar"
          type="button"
          title="Limpar"
          class="buttons"
          @click="showConfirmFieldClearing"
        />

        <RgCancelButton
          v-show="this.isEdit || !edit"
          id="cancelar-cadastro-cliente"
          medium
          class="buttons"
          type="button"
          title="Cancelar"
          @click="goBack"
        />

        <MediumButton
          v-if="edit && !this.isEdit"
          medium
          class="buttons"
          type="button"
          title="Voltar"
          label="Voltar"
          @click="goBack"
        />

        <RgSaveButton
          v-else
          v-show="hasPermission"
          id="salvar-cadastro-cliente"
          ref="saveButton"
          medium
          data-id="salvar"
          type="button"
          title="Salvar"
          class="buttons"
          @click="save"
        />
      </div>
    </footer>

    <ModalPatientBondBasicAttention
      :show="modalPatientBondBasicAttention"
      :esusRegistration="dataIndividualRegistration"
      :individualRegistration="dataPatientBasicAttention"
      :patientBasicAttentionLastDateChange="patientBasicAttentionLastDateChange"
      @close="closeModalPatientBondBasicAttention"
    />

    <ModalPatientRecordsFoundBasicAttention
      :show="modalPatientRecordsFoundBasicAttention"
      :patient-id="patientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :patientHasNoBondOrInactiveBond="patientHasNoBondOrInactiveBond"
      @close="closeModalPatientRecordsFoundBasicAttention"
      @save="savePatientRecordsFoundBasicAttention"
    />

    <ModalPatientRecordNotLinkedBasicAttention
      :show="modalPatientRecordNotLinkedBasicAttention"
      :patient-id="patientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :patientHasNoBondOrInactiveBond="patientHasNoBondOrInactiveBond"
      @close="closeModalPatientRecordNotLinkedBasicAttention"
      @change="changePatientNotLinkedBasicAttention"
    />

    <ModalPatientRecordLinkedBasicAttention
      :show="modalPatientRecordLinkedBasicAttention"
      @close="closeModalPatientRecordLinkedBasicAttention"
      @change="changePatientRecordLinkBasicAttention"
    />

    <ModalPatientNewRecordFoundBasicAttention
      :show="modalPatientNewRecordFoundBasicAttention"
      :patient-id="patientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :currentIndividualRegistrationDate="currentIndividualRegistrationDate"
      :patientHasNoBondOrInactiveBond="patientHasNoBondOrInactiveBond"
      @close="closeModalPatientNewRecordFoundBasicAttention"
      @change="changePatientNewRecordFoundBasicAttention"
    />

    <ModalChangeStatusPatient
      :show="modalChangeStatusPatient"
      :patientInfo="dataPatient"
      @reasonDisqualification="patientReason"
      @close="closeModalChangeStatusPatient"
      @save="changeStatusPatient"
    />

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />

    <MedicalLoader
      :to-close="loading"
      :title-loader="'Aguarde...'"
      :title-success="'Sucesso!'"
      :title-failed="'Erro. '"
      @close="closeLoader"
    />
  </Modulebox>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import QuickPatientDetails from "$person/patient/component/container/quick-patient-details/QuickPatientDetails";
import ModalChangeStatusPatient from "$person/patient/component/modal/modal-change-status-patient/ModalChangeStatusPatient";
import ModalPatientRecordsFoundBasicAttention from "$person/patient/component/modal/modal-patient-records-found-basic-attention/ModalPatientRecordsFoundBasicAttention";
import ModalPatientBondBasicAttention from "$person/patient/component/modal/modal-patient-bond-basic-attention/ModalPatientBondBasicAttention";
import ModalPatientRecordNotLinkedBasicAttention from "$person/patient/component/modal/modal-patient-record-not-linked-basic-attention/ModalPatientRecordNotLinkedBasicAttention";
import ModalPatientRecordLinkedBasicAttention from "$person/patient/component/modal/modal-patient-record-linked-basic-attention/ModalPatientRecordLinkedBasicAttention";
import ModalPatientNewRecordFoundBasicAttention from "$person/patient/component/modal/modal-patient-new-record-found-basic-attention/ModalPatientNewRecordFoundBasicAttention";
import {
  RgCleanButton,
  RgSaveButton,
  RgCancelButton,
  MedicalLoader,
  RgHistoryButton,
  RgEditButton,
  LargeHollowButton,
  RgDropdown,
  IconReload,
  Tooltip,
  MediumButton,
  IconInfoHelper,
  IconAdd,
  RgRadioCustom,
  RgTagCustom,
} from "~tokio/primitive";
import { mapGetters } from "vuex";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import { BrazilianDateShortTimeFormat, DateNow } from "~utils/date";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";

export default {
  name: "PatientDetails",
  components: {
    Modulebox,
    RgSaveButton,
    RgCleanButton,
    RgCancelButton,
    ModalConfirmFieldClearing,
    QuickPatientDetails,
    MedicalLoader,
    RgHistoryButton,
    RgEditButton,
    LargeHollowButton,
    RgDropdown,
    IconReload,
    Tooltip,
    MediumButton,
    ModalChangeStatusPatient,
    ModalPatientBondBasicAttention,
    ModalPatientRecordNotLinkedBasicAttention,
    ModalPatientRecordLinkedBasicAttention,
    ModalPatientRecordsFoundBasicAttention,
    ModalPatientNewRecordFoundBasicAttention,
    IconInfoHelper,
    IconAdd,
    RgRadioCustom,
    RgTagCustom,
  },

  data() {
    return {
      loading: false,
      modalConfirmFieldClearing: false,
      modalChangeStatusPatient: false,
      modalPatientBondBasicAttention: false,
      modalPatientRecordsFoundBasicAttention: false,
      modalPatientRecordNotLinkedBasicAttention: false,
      modalPatientRecordLinkedBasicAttention: false,
      modalPatientNewRecordFoundBasicAttention: false,
      modalPersonalData: false,
      pesId: null,
      isActive: 1,
      isEdit: false,
      disableEdit: false,
      patientActive: true,
      patientHasActiveBond: false,
      patientHasNoBondOrInactiveBond: false,
      disableEditPatient: false,
      dataIndividualRegistration: {},
      dataPatient: {
        pes_id: null,
        pes_nome: null,
        patient: {
          pac_id: null,
          pac_bloqueado: null,
          pac_ativo: null,
          pac_data_inabilitacao: null,
        },

        pes_motivo: null,
      },
      dataPatientBasicAttention: {},
      individualRegistrationList: [],
      currentIndividualRegistrationDate: null,
      listSelectedDeficient: [],
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      personalData: "Person/Patient/GET_PERSONAL_DATA",
      isNewBorn: "Person/Patient/IS_NEWBORN",
      patientInfo: "Person/Patient/GET_PATIENT_INFO",
      personLog: "Person/Patient/GET_PERSON_LOG",
      documentData: "Person/Patient/GET_DOCUMENT",
      contactData: "Person/Patient/GET_CONTACT",
      addressData: "Person/Patient/GET_ADDRESS",
      basicAttentionIntegration: "Login/GET_BASIC_ATTENTION_INTEGRATION",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),
    propsRadioCustom() {
      const list = [
        { title: "Ativo", id: 1 },
        { title: "Inativo", id: 0 },
      ];

      const value = this.dataPatient.patient.pac_ativo || this.isActive;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsTagCustom() {
      const list = [
        {
          title: `Cadeirante`,
          id: "1",
          icon: "1",
          color: "#2186D6",
        },
        {
          title: `Deficiência Visual`,
          id: "2",
          icon: "2",
          color: "#2186D6",
        },
        {
          title: `Surdez`,
          id: "3",
          icon: "3",
          color: "#2186D6",
        },
        {
          title: `Autismo`,
          id: "4",
          icon: "4",
          color: "#2186D6",
        },
      ];

      const values = this.listSelectedDeficient;

      return { list, values };
    },
    disabledByModal() {
      return (
        this.modalConfirmFieldClearing ||
        this.modalChangeStatusPatient ||
        this.modalPatientBondBasicAttention ||
        this.modalPatientRecordsFoundBasicAttention ||
        this.modalPatientRecordNotLinkedBasicAttention ||
        this.modalPatientRecordLinkedBasicAttention ||
        this.modalPatientNewRecordFoundBasicAttention
      );
    },

    hasPermission() {
      if (this.isNewBorn && this.hasNewBornPatientPermission) return true;

      if (this.existsPesId) {
        return this.$Permissions.global.has("cadastro.pessoa.alterar");
      } else {
        return this.$Permissions.global.has("cadastro.pessoa.incluir");
      }
    },

    hasNewBornPatientPermission() {
      return !!this.$Permissions.global.has(
        "cadastro.pessoa.vincularRecemNascido",
      );
    },

    title() {
      const isRegisterMode = !this.existsPesId;
      const isEditMode = this.existsPesId && !this.isNewBorn;

      if (isRegisterMode) {
        return "Cadastrar Paciente";
      }

      if (isEditMode) {
        return "Cadastro do Paciente";
      }

      if (this.isNewBorn) {
        return "Vincular Recém-Nascido";
      }

      return "";
    },

    showRgRadioActiveInactive() {
      return (
        (!this.isEdit && this.isActive === 1) ||
        (this.isEdit && this.isActive === 1)
      );
    },

    permission() {
      return {
        habilitarInabilitarPatient: !!this.$Permissions.global.has(
          "cadastro.prontuario.habilitarInabilitarPaciente",
        ),
        historyPatient: !!this.$Permissions.global.has(
          "cadastro.prontuario.historicoGeralPaciente",
        ),
        editPatient: !!this.$Permissions.global.has("cadastro.pessoa.alterar"),
      };
    },

    disableModuleBox() {
      const isRegisterMode = !(this.existsPesId && !this.isNewBorn);
      const granted = !this.permissioneditPatient;

      return this.isEdit ? granted : isRegisterMode;
    },

    canEdit() {
      return this.permission.editPatient;
    },

    canHistory() {
      return this.permission.historyPatient;
    },

    canHabilitInabilit() {
      return this.permission.habilitarInabilitarPatient;
    },

    edit() {
      if (this.existsPesId && !this.isNewBorn) {
        return true;
      }
      return false;
    },

    patientId() {
      return this.dataPatient?.patient?.pac_id;
    },

    patientBasicAttentionId() {
      return this.dataPatientBasicAttention?.pae_id;
    },

    patientBasicAttentionLastDateChange() {
      return this.dataPatientBasicAttention
        ? BrazilianDateShortTimeFormat(
            this.dataPatientBasicAttention.pae_ultima_alteracao,
          )
        : null;
    },

    hasBasicAttentionIntegration() {
      return (
        !!this.basicAttentionIntegration &&
        !!this.currentUnitHealth?.unidades_saude_integracao_ab
      );
    },

    showButtonHasActiveBond() {
      return (
        this.edit &&
        this.hasBasicAttentionIntegration &&
        this.patientHasActiveBond
      );
    },

    showButtonHasNoBondOrInactiveBond() {
      return (
        this.edit &&
        this.hasBasicAttentionIntegration &&
        this.patientHasNoBondOrInactiveBond &&
        (this.dataIndividualRegistration?.cpf ||
          this.dataIndividualRegistration?.cns)
      );
    },
  },

  watch: {
    isActive(pValue, pPrev) {
      if (this.canHabilitInabilit && this.isEdit) {
        if (Number(this.patientActive) !== pValue) {
          this.modalChangeStatusPatient = true;
        }
      }
    },
    personalData(pValue) {
      if (pValue) {
        this.dataPatient.pes_nome = pValue.pes_nome;
        this.dataPatient.pes_id = pValue.pes_id;
        this.dataIndividualRegistration.pes_nome = pValue.pes_nome;
        this.dataIndividualRegistration.pes_mae = pValue.pes_mae;
        this.dataIndividualRegistration.sex_sigla = pValue.gender?.sex_id;
        this.dataIndividualRegistration.pes_nascimento = pValue.pes_nascimento;

        if (pValue.deficient && pValue.deficient !== "") {
          const formatDeficient = pValue.deficient.replace(/'/g, `"`);
          this.listSelectedDeficient = JSON.parse(formatDeficient).map(
            (element) => {
              return element.pde_id_deficiencias;
            },
          );
        }
      }
    },
    documentData(pValue) {
      if (pValue) {
        this.dataIndividualRegistration.cpf = pValue.cpf?.cpf_numero;
        this.dataIndividualRegistration.cns = pValue.cns?.crs_numero;
      }
    },
    contactData(pValue) {
      if (pValue) {
        const telephones = pValue.telephones ? pValue.telephones.rows : [];
        if (telephones?.length > 0) {
          const tels = telephones.map((telephone) => {
            return "(" + telephone.ddd.ddd_codigo + ") " + telephone.tel_numero;
          });
          this.dataIndividualRegistration.telephones = tels.join(", ");
        }
      } else {
        this.dataIndividualRegistration.telephones = null;
      }
    },
    addressData(pValue) {
      if (pValue) {
        const publicPlaceType = pValue.publicPlaceType?.tlg_nome || "";
        const publicPlace = pValue.end_logradouro || "";
        const number = pValue.end_numero || "SN";
        const neighborhood = pValue.neighborhood?.bai_nome || "";
        const city = pValue.neighborhood?.city?.mun_nome || "";
        const state = pValue.neighborhood?.city?.state?.est_sigla || "";
        const cep = pValue.end_cep || "";
        const address = publicPlaceType.concat(
          " ",
          publicPlace,
          ", ",
          number,
          " - ",
          neighborhood,
          " - ",
          city,
          " - ",
          state,
          ", " + cep,
        );

        this.dataIndividualRegistration.address = address;
      }
    },
    async patientInfo(pValue) {
      if (pValue) {
        this.dataPatient.patient.pac_bloqueado = pValue.pac_bloqueado;
        this.dataPatient.patient.pac_id = pValue.pac_id;
        this.dataPatient.patient.pac_data_inabilitacao =
          pValue.pac_data_inabilitacao;
        this.dataPatient.patient.inactivated_by = pValue.inactivated_by;
        this.dataPatient.patient.disqualification_reasons =
          pValue.disqualification_reasons;
        this.patientActive = !pValue.pac_bloqueado;

        if (
          pValue.pae_id &&
          !this.dataIndividualRegistration?.cpf &&
          !this.dataIndividualRegistration?.cns
        ) {
          this.patientHasActiveBond = false;
          this.patientHasNoBondOrInactiveBond = true;
        } else {
          this.patientHasActiveBond = !!pValue.pae_ativo;
          this.patientHasNoBondOrInactiveBond =
            !pValue.pae_id || pValue.pae_ativo === 0;
        }
      }
    },
  },

  mounted() {
    if (this.$route.params) {
      this.disableEdit = this.$route.params.disableEditPatient
        ? this.$route.params.disableEditPatient
        : false;
    }
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.isActive = item.id;
      }
    },
    changeStatusPatient() {
      this.$refs.patientDetails.setPatientInfo();
    },

    patientReason(pValue) {
      this.dataPatient.pes_motivo = pValue;
    },

    closeModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
    },

    closeModalChangeStatusPatient() {
      this.modalChangeStatusPatient = false;
    },

    getBloquedPatientValue(pBoolean) {
      this.isActive = !pBoolean ? 1 : 0;
    },

    statusSuccess() {
      this.$refs.saveButton.actionDone();
    },

    statusFail() {
      this.$refs.saveButton.fail();
    },

    save() {
      this.$refs.patientDetails.savePatient();
    },

    patientCareHistory() {
      const routeName = this.$route.name;
      const patientInfo = this.dataPatient;

      this.$router.push({
        name: `${routeName}.patient-care-history`,
        params: patientInfo,
      });
    },

    showModalPatientBondBasicAttention() {
      this.modalPatientBondBasicAttention = true;
    },

    showModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = true;
    },

    showModalPatientRecordNotLinkedBasicAttention() {
      this.modalPatientRecordNotLinkedBasicAttention = true;
    },

    showModalPatientRecordLinkedBasicAttention() {
      this.modalPatientRecordLinkedBasicAttention = true;
    },

    showModalPatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = true;
    },

    closeLoader() {
      this.loading = false;
    },

    closeModalPatientBondBasicAttention() {
      this.modalPatientBondBasicAttention = false;
    },

    closeModalPatientRecordNotLinkedBasicAttention() {
      this.modalPatientRecordNotLinkedBasicAttention = false;
    },

    closeModalPatientRecordLinkedBasicAttention() {
      this.modalPatientRecordLinkedBasicAttention = false;
    },

    closeModalPatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = false;
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = false;
      this.$refs.patientDetails.clean();
    },

    goBack() {
      const routerMapBedValid = ValidateIfRouteExistInBreadscrumb(
        "/hospitalization/bed/search",
        this.$route.meta.breadcrumb,
      );

      if (routerMapBedValid) {
        this.$router.push({
          name: "hospitalization.bed.search.new-hospitalization",
          params: this.$route.params,
        });
      } else {
        const routerValid = ValidateIfRouteExistInBreadscrumb(
          "/person/patient/details",
          this.$route.meta.breadcrumb,
        );

        if (routerValid) {
          this.$router.push("/person/patient/search");
        } else {
          const routerRevenueValid = ValidateIfRouteExistInBreadscrumb(
            "/pharmacy/satellite/revenue",
            this.$route.meta.breadcrumb,
          );
          if (routerRevenueValid) {
            this.$router.push({
              name: "pharmacy.satellite.revenue",
              params: this.$route.params,
            });
          } else {
            this.$router.go(-1);
          }
        }
      }
    },

    editButton() {
      this.isEdit = !this.isEdit;
      this.disableEditPatient = true;
      this.$refs.patientDetails.loadDeficient();
    },

    drawRegisterDate() {
      const hasDate =
        Array.isArray(this.personLog?.rows) && this.personLog?.rows?.length > 0;

      if (hasDate) {
        return this.$utils.date.BrazilianDateFormat(
          this.personLog?.rows[0]?.psl_data_log,
        );
      }

      return "Não Informado";
    },

    itemActionOptions(pItem) {
      return [
        {
          label: "Visualizar vínculo",
          action: this.viewPatientBondBasicAttention,
          disable: this.disabledByModal,
        },
        {
          label: "Alterar vínculo",
          action: this.showModalPatientRecordLinkedBasicAttention,
          disable: this.disabledByModal || !this.canEdit,
        },
      ];
    },

    async viewPatientBondBasicAttention() {
      try {
        this.$loader.start();

        await this.searchPatientBondBasicAttention();

        if (!(await this.getIndividualRegistration())) {
          this.showModalPatientBondBasicAttention();
          return;
        }

        const hasNoRecord = this.individualRegistrationList.length === 0;
        const hasOneRecord = this.individualRegistrationList.length === 1;
        const patientBaseRecordId = this.dataPatientBasicAttention
          ?.pae_ficha_base;
        const individualRegistrationWithSameId = this.individualRegistrationList.find(
          (x) =>
            Number(x.pab_id) === patientBaseRecordId ||
            Number(x.pab_id_payload) === patientBaseRecordId,
        );

        if (hasOneRecord && individualRegistrationWithSameId) {
          const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
            this.individualRegistrationList[0],
            this.dataPatientBasicAttention.pae_id,
            this.patientId,
          );

          await this.updatePatientBondBasicAttention(patientAbEsus);
          await this.searchPatientBondBasicAttention();
          this.showModalPatientBondBasicAttention();
          return;
        }

        const hasMoreThanOneRecords =
          this.individualRegistrationList.length > 1;

        if (hasMoreThanOneRecords && individualRegistrationWithSameId) {
          const individualRegistrationWithGreaterDate = await this.getIndividualRegistrationWithGreaterDate();
          if (individualRegistrationWithGreaterDate) {
            const updatePatientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
              individualRegistrationWithGreaterDate,
              this.dataPatientBasicAttention.pae_id,
              this.patientId,
            );
            await this.updatePatientBondBasicAttention(updatePatientAbEsus);
            await this.searchPatientBondBasicAttention();
            this.showModalPatientBondBasicAttention();
          } else {
            this.currentIndividualRegistrationDate =
              individualRegistrationWithSameId.pab_data_atendimento;
            this.showModalPatientNewRecordFoundBasicAttention();
          }
          return;
        }

        if (hasNoRecord || !individualRegistrationWithSameId) {
          await this.inactivatePatientBondBasicAttention();
          await this.getIndividualRegistration(false);
          this.showModalPatientRecordNotLinkedBasicAttention();
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao visualizar o vínculo", pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async getIndividualRegistration(sendBaseCardId = true) {
      const patientCpf = this.dataIndividualRegistration?.cpf;
      const patientCns = this.dataIndividualRegistration?.cns;
      const patientAbEsusIsActive =
        this.dataPatientBasicAttention?.pae_ativo || false;

      if (patientCpf || patientCns) {
        const variables = {
          ...(sendBaseCardId &&
            patientAbEsusIsActive && {
              baseCardId: this.dataPatientBasicAttention?.pae_ficha_base,
            }),

          cpf: patientCpf,
          cns: patientCns,
        };

        try {
          const result = await this.$store.dispatch(
            "Person/Patient/GET_AB_INDIVIDUAL_REGISTRATION",
            variables,
          );
          this.individualRegistrationList = result?.rows;
          return this.individualRegistrationList;
        } catch {
          return null;
        }
      }

      return null;
    },

    async searchPatientBondBasicAttention() {
      if (this.patientId) {
        try {
          const dataAbEsus = await this.$store.dispatch(
            "Person/Patient/SEARCH_AB_ESUS",
            { pacId: Number(this.patientId) },
          );

          if (dataAbEsus) {
            this.patientHasActiveBond = dataAbEsus.pae_ativo === 1;
            this.patientHasNoBondOrInactiveBond = dataAbEsus.pae_ativo === 0;
          } else {
            this.patientHasNoBondOrInactiveBond = true;
          }

          this.dataPatientBasicAttention = dataAbEsus || {};
        } catch (err) {
          this.$toaster.error("Erro ao buscar vínculo", err);
        }
      }
    },

    async createPatientBondBasicAttention() {
      try {
        this.$loader.start();
        if (!(await this.getIndividualRegistration())) {
          this.$toaster.warning(
            "Não foi possível consultar os dados na base do Atenção Básica",
          );
          return;
        }

        if (this.individualRegistrationList.length === 0) {
          this.$toaster.warning(
            "Não foram encontradas fichas compatíveis com o cadastro do paciente na base do Atenção Básica",
          );
        } else {
          await this.searchPatientBondBasicAttention();
          this.showModalPatientRecordsFoundBasicAttention();
        }
      } catch (err) {
        this.$toaster.error("Erro ao criar vínculo", err);
      } finally {
        this.$loader.finish();
      }
    },

    async updatePatientBondBasicAttention(pData) {
      if (pData) {
        try {
          await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
            abEsus: pData,
          });
          this.changeStatusPatient();
        } catch (err) {
          this.$toaster.error("Erro ao atualizar o vínculo", err);
        }
      }
    },

    async changePatientRecordLinkBasicAttention() {
      try {
        this.$loader.start();

        await this.searchPatientBondBasicAttention();

        if (!(await this.getIndividualRegistration(false))) {
          this.$toaster.warning(
            "Não foi possível consultar os dados na base do Atenção Básica",
          );
          return;
        }

        const hasNoRecord = this.individualRegistrationList.length === 0;
        if (hasNoRecord) {
          this.inactivatePatientBondBasicAttention();
          this.showModalPatientRecordNotLinkedBasicAttention();
          return;
        }

        if (this.individualRegistrationList.length > 0) {
          this.showModalPatientRecordsFoundBasicAttention();
        }
      } finally {
        this.$loader.finish();
      }
    },

    async changePatientNotLinkedBasicAttention() {
      this.modalPatientRecordNotLinkedBasicAttention = false;
      try {
        this.$loader.start();
        await this.searchPatientBondBasicAttention();
        this.showModalPatientBondBasicAttention();
      } finally {
        this.$loader.finish();
      }
    },

    async changePatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = false;
      try {
        this.$loader.start();
        await this.searchPatientBondBasicAttention();
        this.showModalPatientBondBasicAttention();
      } finally {
        this.$loader.finish();
      }
    },

    async savePatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
      try {
        this.$loader.start();
        await this.searchPatientBondBasicAttention();
      } finally {
        this.$loader.finish();
      }
    },

    async inactivatePatientBondBasicAttention(paeId = null) {
      const INATIVO = 0;
      const dataAbEsusInactive = {
        pae_id: paeId || this.dataPatientBasicAttention.pae_id,
        pae_ativo: INATIVO,
        pae_ultima_alteracao: DateNow(),
      };
      this.updatePatientBondBasicAttention(dataAbEsusInactive);
    },

    async getIndividualRegistrationWithGreaterDate() {
      const patientBaseRecordId = this.dataPatientBasicAttention
        ?.pae_ficha_base;

      const individualRegistrationSameId = this.individualRegistrationList.find(
        (x) =>
          Number(x.pab_id) === patientBaseRecordId ||
          Number(x.pab_id_payload) === patientBaseRecordId,
      );

      const individualRegistrationSameIdDate = new Date(
        individualRegistrationSameId.pab_data_atendimento,
      );

      const maxDate = new Date(
        Math.max(
          ...this.individualRegistrationList.map((item) => {
            return new Date(item.pab_data_atendimento);
          }),
        ),
      );

      if (individualRegistrationSameIdDate.getTime() === maxDate.getTime()) {
        return individualRegistrationSameId;
      }

      return null;
    },

    selectedDeficient(listDeficient) {
      this.listSelectedDeficient = listDeficient;
    },
  },
};
</script>
