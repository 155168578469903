import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async ({
  intIdUnidade,
  tipoSetor,
  arrIdOperacoes,
  blnSomenteAtivos,
}) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-setor/combo-box-setores-por-unidade-pep",
    { intIdUnidade, tipoSetor, arrIdOperacoes, blnSomenteAtivos },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
