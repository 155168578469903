// import Store from "@/store";
// import Router from "@/route";
import { toast } from "~tokio/primitive/notification";

const HandleUnauthorized = () => {
  if (!window.location.pathname.includes("/login")) {
    document
      .querySelector("#refreshLoginFast")
      .setAttribute("style", "display: flex");
    localStorage.setItem("refresh-token-store", "active");

    const refreshRoute = localStorage.getItem("exec-refresh-page-refreshLogin");

    if (refreshRoute) {
      const objRefresh = JSON.parse(refreshRoute);
      localStorage.setItem(
        "exec-refresh-page-refreshLogin",
        JSON.stringify({ ...objRefresh, refresh: objRefresh.exec }),
      );
    }
  }

  toast.error(
    "Sua sessão possívelmente expirou ou você não tem permissão para executar essa ação.",
    "Por favor, realize o login novamente.",
  );

  // Store.dispatch("Login/LOGOFF");
  // Router.push("/login");
};

export default HandleUnauthorized;
