import SEARCH_PROCEDURE from "./SearchProcedure";
import SEARCH_SPA from "./SearchSpa";
import EXPORT_PROCEDURE from "./ExportProcedure";
import DELETE_PHARMACY_PROCEDURE from "./DeleteProcedure";
import DELETE_PHARMACY_PROCEDURE_LOT from "./DeleteProcedureLot";

export default {
  SEARCH_PROCEDURE,
  SEARCH_SPA,
  DELETE_PHARMACY_PROCEDURE,
  EXPORT_PROCEDURE,
  DELETE_PHARMACY_PROCEDURE_LOT,
};
