<script>
import RgRemoteTypeaheadMixin from "$billing/common/component/selection/mixin/RgRemoteTypeaheadMixin";
import { SearchCounty, FindCountyByCod } from "./action";

export default {
  name: "RgTypeheadCounty",
  mixins: [RgRemoteTypeaheadMixin],
  props: {
    label: {
      default: "Município",
    },
    min: {
      default: "2",
    },
  },
  data() {
    return {
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  methods: {
    doSearch() {
      const value = this.$utils.sanitize.removeSpecialCharacters(
        this.inputValue,
      );
      return SearchCounty({ searchQuery: value });
    },
    doFind(pValue) {
      return FindCountyByCod(pValue);
    },
    formatItem(item) {
      return {
        value: item.mun_codigo,
        text: `${item.mun_nome} / ${item.est_sigla}`,
      };
    },
  },
};
</script>
