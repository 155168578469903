<template>
  <div class="rg-list" :class="{ disable: disabled }">
    <slot />
    <div class="rg-list-footer">
      <RgPagination
        v-if="showTotal"
        ref="paginator"
        :data-length="countItem"
        :disabled="disabled"
        :item-per-page="registerPerPage"
        :max-register="maxRegister"
        @pagination="setPagination"
      />
    </div>
  </div>
</template>

<script>
import RgPagination from "~tokio/foundation/pagination/RgPagination";
import { AlertError } from "$exam/common/services";

export default {
  name: "RgList",
  components: {
    RgPagination,
  },
  props: {
    /**
     * @param {*} filters
     * @returns {html: string, count: number}
     */
    searchFunction: {
      type: Function,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    clearFunction: {
      type: Function,
    },

    individualHeight: {
      type: Number,
      default: 0,
    },

    buildFilter: {
      type: Function,
    },

    registerPerPage: {
      type: Number,
      default: 10,
    },

    totalRegister: {
      type: Number,
      default: 0,
    },

    maxRegister: {
      type: Number,
      default: 15,
    },

    backendLegacy: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    viewPermission: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countItem: 0,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },

  computed: {
    hasSearchFunction() {
      return !!this.searchFunction();
    },
  },

  watch: {
    totalRegister(pValue) {
      if (pValue > 0) {
        this.countItem = this.countItem + this.totalRegister;
      }
    },
  },

  created() {
    this.LEGADO_OBJECT_SEARCH = ["arrFiltros"];
  },

  mounted() {
    if (this.totalRegister !== 0) {
      this.countItem = this.totalRegister;
    }
  },

  methods: {
    setPagination(pPagination) {
      this.$emit("pagination", pPagination);
      this.pagination = pPagination;
      this.$nextTick(() => {
        this.submitForm();
      });
    },
    prepareData() {
      const data = {
        ...this.buildFilter(),
      };

      if (this.backendLegacy) {
        const nameObject = Object.keys(data);
        const hasThisObject = this.LEGADO_OBJECT_SEARCH.find((item) => {
          return nameObject.includes(item) ? item : null;
        });

        if (hasThisObject) {
          data[hasThisObject].limiteInicio = this.pagination.offset;
          data[hasThisObject].limiteFim = this.pagination.limit;
        } else {
          data.limiteInicio = this.pagination.offset;
          data.limiteFim = this.pagination.limit;
        }

        return data;
      } else {
        return {
          ...data,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        };
      }
    },

    async submitForm(submitFromButton = false, reSearch = false) {
      if (!this.viewPermission) return null;

      try {
        this.$loader.start();
        if (submitFromButton) {
          if (this.$refs.paginator) {
            this.$refs.paginator.resetLimitOffset();
          }
          this.pagination.limit = this.registerPerPage;
        } else if (reSearch) {
          this.pagination.limit = this.registerPerPage;
        }

        this.setStorePagination();

        const data = this.prepareData();
        const result = await this.searchFunction(data);

        if (result) {
          if (result.count || result.total) {
            this.countItem = result.count || Number(result.total) || 0;
          } else {
            if (result.rows && result.rows.length > 0) {
              this.countItem = result.rows[0].totalRegistros || 0;
            }
          }
          if (
            (result.resultado && result.resultado.length > 0) ||
            (result.data && result.data.length > 0) ||
            (result.rows && result.rows.length > 0)
          ) {
            this.updateValues(result);
          }
        }
      } catch (error) {
        if (error.message && typeof error.message === "string") {
          return AlertError(error.message);
        }
        throw error;
      } finally {
        this.$loader.finish();
      }
    },
    resetLimitOffset() {
      this.countItem = 0;
      if (this.$refs.paginator) {
        this.$refs.paginator.resetLimitOffset();
        this.$refs.paginator.loadPagination();
      }
    },
    updateValues(pResult) {
      const rows = pResult.rows || pResult.data || pResult.resultado;

      if (this.countItem === 0 && rows.length < 1) {
        this.resetLimitOffset();
      }
      this.setStorePagination();

      this.$emit("input", rows);
      this.$emit("afterSearch", rows);
    },
    setStorePagination() {
      const routeName = this.$route.name;

      this.$store.commit(
        "Pagination/SET_CURRENT_PAGINATION",
        this.pagination.current,
      );
      this.$store.commit(
        "Pagination/SET_LIMIT_PAGINATION",
        this.pagination.limit,
      );
      this.$store.commit(
        "Pagination/SET_OFFSET_PAGINATION",
        this.pagination.offset,
      );
      this.$store.commit(
        "Pagination/SET_TOTAL_PAGINATION",
        this.pagination.total,
      );
      this.$store.commit("Pagination/SET_ROUTE_PAGINATION", routeName);
    },
    clearStorePagination() {
      this.$store.commit("Pagination/DESTROY_PAGINATION");
      this.$store.commit("Pagination/DESTROY_ROUTE_PAGINATION");
    },
  },
};
</script>
