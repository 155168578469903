<style src="./SchedulingRequestForm.scss" lang="scss" scoped></style>
<template lang="html">
  <RgForm
    :show-submit="!hideButtonForm"
    :action-label="actionLabel"
    @submit="submit"
  >
    <section class="scheduling-request-form-content">
      <section class="patient--info">
        <SchedulingPatientInfo
          v-model="form.pac_id"
          :pac-id="form.pac_id"
          :surgery-code="form.cci_numero"
        />
      </section>
      <section class="schedule-form">
        <div class="row">
          <RgComboboxRequestingClinician
            v-model="form.cci_id_internacoes_clinicas"
            :rules="{ required: true }"
            class="col-6"
          />
          <RgComboboxSurgeryRequestingPhysician
            v-model="form.cci_id_funcionarios"
            :rules="{ required: true }"
            :clinician="form.cci_id_internacoes_clinicas"
            class="col-6"
          />
        </div>

        <div class="row">
          <RgComboboxSurgeryReason
            v-model="form.cci_id_finalidades"
            label="Finalidade da Cirurgia"
            class="col-12"
          />
        </div>

        <div class="surgery-type-container">
          <div class="row">
            <RgComboboxSector
              v-model="form.cci_id_setores"
              :rules="{ required: true }"
              class="col-6 combobox-row"
              label="Centro Cirúrgico"
              @change="checkSurgeryConflict"
            />
            <RgComboboxPlace
              v-model="form.cci_id_locais_atendimento"
              :sector="form.cci_id_setores"
              :rules="{ required: true }"
              empty-default-text="Selecione um centro cirúrgico"
              class="col-6 combobox-row"
              label="Sala"
              @blur="checkSurgeryConflict"
            />
          </div>

          <div class="row row-margin">
            <RgInputDate
              v-model="form.cci_data_inicio"
              :rules="{ required: true }"
              class="col-4"
              label="Data Prevista"
              placeholder="00/00/0000"
              @blur="checkSurgeryConflict"
            />
            <RgInputHour
              v-model="form.cci_hora_inicio"
              :rules="{ required: true }"
              class="col-4"
              label="Hora"
              placeholder="00:00"
              @blur="checkSurgeryConflict"
            />
            <RgInputHour
              v-model="form.cci_duracao"
              :rules="{ required: true }"
              class="col-4"
              label="Duração"
              @blur="checkSurgeryConflict"
            />
          </div>
          <RgAddConditionedModal
            :show="showConditioned"
            :surgeries-details="surgeryConflictDetail"
            @choose="chooseConditionedType"
          />
        </div>

        <section class="row">
          <RgComboboxSurgeryAnesthesia
            v-model="form.cci_id_tipos_anestesias"
            :rules="{ required: true }"
            class="col-6"
            label="Tipo de Anestesia"
          />
          <RgComboboxSurgeryXRay
            v-model="form.cci_id_raios_x"
            :rules="{ required: true }"
            class="col-6 combobox-row"
            label="Raio-X"
          />
        </section>
        <div class="row">
          <RgRadioCustom
            id="radio-cti"
            :value="form.cci_cti"
            :list="ITEMSRADIOFILTER"
            uniqueKey="id"
            class="rg-radio col-2 combobox-row"
            label="CTI"
            :canUnselect="false"
            @input="onInputRadioCti"
          />
          <RgRadioCustom
            id="radio-surgery"
            :value="form.cci_id_tipos_cirurgias"
            :list="ITEMSRADIOFILTERSURGERY"
            uniqueKey="id"
            class="rg-radio col-3 combobox-row"
            label="Cirurgia Condicionada"
            :canUnselect="false"
            @input="onInputRadioSurgery"
          />
          <RgRadioCustom
            id="radio-blood"
            :value="form.cci_reserva_sangue"
            :list="ITEMSRADIOFILTER"
            uniqueKey="id"
            class="rg-radio col-2 combobox-row"
            label="Reservar Sangue"
            :canUnselect="false"
            @input="onInputRadioBlood"
          />

          <!-- <fieldset class="rg-radio col-4"> -->
          <RgRadioCustom
            id="radio-blood"
            :value="form.cci_id_tipos_procedimentos"
            :list="ITEMSRADIOFILTERPROCEDURE"
            uniqueKey="id"
            class="rg-radio col-4 combobox-row"
            label="Tipo de Procedimento"
            :canUnselect="false"
            @input="onInputRadioProcedure"
          />
          <!-- <label class="description--radio">Tipo de Procedimento</label>
            <div class="form-requesting--radio">
              <label class="radio">
                <input
                  v-model="form.cci_id_tipos_procedimentos"
                  type="radio"
                  name="procedure-type"
                  :value="1"
                  class="rg-radio"
                />
                <span>Hospitalar</span>
              </label>
              <label class="radio">
                <input
                  v-model="form.cci_id_tipos_procedimentos"
                  type="radio"
                  name="procedure-type"
                  :value="2"
                  class="rg-radio"
                />
                <span>Ambulatorial</span>
              </label>
            </div> -->
          <!-- </fieldset> -->
        </div>
      </section>
      <section class="additional-info">
        <RgTabs :itens="itens">
          <!-- <rg-tabs-item label="Procedimentos" @click="chooseTab(0)" :selected="tabSelected === 0" :validated="validProcedures" />
        <rg-tabs-item label="Observação" @click="chooseTab(1)" :selected="tabSelected === 1" /> -->
          <div slot="procedimentos">
            <SchedulingProcedureList
              v-model="form.procedures"
              :surgery-id="form.cci_id"
              @valid="checkValidateProcedure"
            />
          </div>
          <div slot="observacao">
            <SurgeryObservation v-model="form.cci_observacao" />
          </div>
        </RgTabs>
      </section>
    </section>
    <div slot="aftercommand" class="after-command--container">
      <RgCleanButton large class="align-clean" @click="cleanSelection" />
    </div>
  </RgForm>
</template>

<script>
import {
  RgCleanButton,
  RgInputHour,
  RgInputDate,
  RgRadioCustom,
} from "~tokio/primitive";
import { RgForm } from "~tokio/foundation/form";
import { RgTabs } from "~tokio/foundation/container";
import RgComboboxSurgeryAnesthesia from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-anesthesia/RgComboboxSurgeryAnesthesia";
import RgComboboxSurgeryXRay from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-x-ray/RgComboboxSurgeryXRay";
import RgComboboxSurgeryRequestingPhysician from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-requesting-physician/RgComboboxSurgeryRequestingPhysician";
import RgComboboxRequestingClinician from "$surgery-center/surgery/component/combobox/rg-combobox-requesting-clinician/RgComboboxRequestingClinician";
import SchedulingPatientInfo from "$surgery-center/surgery/component/scheduling-patient-info/SchedulingPatientInfo";
import SchedulingProcedureList from "$surgery-center/surgery/component/scheduling-procedure-list/SchedulingProcedureList";
import {
  RgComboboxSector,
  RgComboboxPlace,
} from "$surgery-center/unit-health/component";
import RgAddConditionedModal from "$surgery-center/foundation/component/rg-add-conditioned-modal/RgAddConditionedModal";
import SurgeryFormMixin from "$surgery-center/surgery/component/mixin/SurgeryFormMixin";
import SurgeryObservation from "$surgery-center/surgery/component/surgery-observation/SurgeryObservation";
import RgComboboxSurgeryReason from "$surgery-center/surgery/component/combobox/rg-combobox-surgery-reason/RgComboboxSurgeryReason";
export default {
  name: "SchedulingRequestForm",
  components: {
    RgInputDate,
    RgComboboxSurgeryRequestingPhysician,
    RgComboboxSurgeryAnesthesia,
    RgComboboxSurgeryXRay,
    RgComboboxRequestingClinician,
    RgInputHour,
    RgForm,
    SchedulingPatientInfo,
    SchedulingProcedureList,
    RgComboboxSector,
    RgAddConditionedModal,
    RgComboboxPlace,
    RgTabs,
    SurgeryObservation,
    RgCleanButton,
    RgComboboxSurgeryReason,
    RgRadioCustom,
  },
  extends: SurgeryFormMixin,
  data() {
    return {
      validProcedures: true,
      itens: [
        {
          label: "Procedimentos",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "procedimentos",
        },
        {
          label: "Observação",
          granted: this.$Permissions.global.has("centro_cirurgico"),
          slot: "observacao",
        },
      ],
    };
  },
  computed: {
    actionLabel() {
      return this.isUpdating ? "Atualizar Pedido" : "Cadastrar Pedido";
    },
    surgeryState() {
      return 1;
    },
    hideButtonForm() {
      return (
        this.isSurgeryCanceled ||
        this.isSurgeryPerformed ||
        this.isSurgeryScheduled ||
        this.isSurgerySuspended
      );
    },
  },
  created() {
    this.ITEMSRADIOFILTER = [
      { title: "Sim", color: "#5ebaef", id: 1 },
      { title: "Não", color: "#5ebaef", id: 0 },
    ];
    this.ITEMSRADIOFILTERSURGERY = [
      { title: "Sim", color: "#5ebaef", id: 2 },
      { title: "Não", color: "#5ebaef", id: 1 },
    ];
    this.ITEMSRADIOFILTERPROCEDURE = [
      { title: "Hospitalar", color: "#5ebaef", id: 1 },
      { title: "Ambulatorial", color: "#5ebaef", id: 2 },
    ];
  },
  methods: {
    onInputRadioCti(item) {
      if (item) {
        this.form.cci_cti = item.id;
      }
    },
    onInputRadioSurgery(item) {
      if (item) {
        this.form.cci_id_tipos_cirurgias = item.id;
      }
    },
    onInputRadioBlood(item) {
      if (item) {
        this.form.cci_reserva_sangue = item.id;
      }
    },
    onInputRadioProcedure(item) {
      if (item) {
        this.form.cci_id_tipos_procedimentos = item.id;
      }
    },
    checkValidateProcedure(errors) {
      if (errors) {
        this.validProcedures = false;
        return;
      }
      this.validProcedures = true;
    },
    submit(pErr, pDone) {
      if (pErr) {
        return;
      }
      if (this.isUpdating) {
        this.update(pDone);
      } else {
        this.create(pDone);
      }
    },
    create(pDone) {
      this.$store
        .dispatch(
          "SurgeryCenter/Surgery/REQUEST_SURGERY",
          this.formatForm(this.form),
        )
        .then((pRes) => {
          this.$store.dispatch("SurgeryCenter/Surgery/filter");
          pDone();
          this.cleanSelection();
          this.toast.success(
            "O cadastro do pedido cirúrgico foi efetuado com sucesso",
            "Cadastro de pedido...",
          );
        })
        .catch((pErr) => {
          pDone();
          this.toast.error("Erro ao Cadastrar Pedido");
        });
    },
    update(pDone) {
      this.$store
        .dispatch(
          "SurgeryCenter/Surgery/UPDATE_REQUEST_SURGERY",
          this.formatForm(this.form),
        )
        .then((pRes) => {
          this.$store.dispatch("SurgeryCenter/Surgery/filter");
          pDone();
          this.cleanSelection();
          this.toast.success(
            "A atualização do pedido cirúrgico foi efetuada com sucesso",
            "Atualização de pedido...",
          );
        })
        .catch((pErr) => {
          pDone();
        });
    },
  },
};
</script>
