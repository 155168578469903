<style src="./Material.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="material">
    <ModuleBox class="surgery-map-filter" title="Cadastrar Material">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-10">
            <RgValidationAlert :alert="error" class="rg-input--alert" />
            <RgInput
              v-model="form.materialName"
              :rules="{ required: true }"
              label="Nome do Material"
            />
          </div>

          <div class="col-2">
            <button type="submit" class="btn btn-success">
              <IconCircleAdd class="button-save" />
              <span class="label">Salvar</span>
            </button>
          </div>
        </div>
      </form>
    </ModuleBox>

    <ModuleBox class="surgery-map-filter" title="Listar Material" />
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import {
  RgInput,
  IconCircleAdd,
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive";

export default {
  name: "Material",
  components: {
    ModuleBox,
    RgInput,
    IconCircleAdd,
    RgValidationAlert,
  },
  mixins: {
    RgValidatorMixin,
  },
  data() {
    return {
      form: {
        materialName: "",
      },
      anotherRules: {
        validList: (pValue, pError) => {
          pError.push("É obrigatório inserir um item na lista");
          return false;
        },
      },
      error: [],
    };
  },
  computed: {
    validValue() {
      return true;
    },
  },
  methods: {
    submitForm() {},
    validate() {
      return false;
    },
  },
};
</script>
