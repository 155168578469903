<style src="./SmallHollowButton.scss" lang="scss" scoped></style>
<template>
  <div class="small-hollow-button">
    <RgButton
      :id="id"
      :disabled="disabled"
      :permission="permission"
      :data-id="dataId"
      :style="{
        border: `3px solid ${borderColor}`,
      }"
      :title="title"
      class="button"
      @click="clicked"
    >
      <slot name="icon" class="icon" />
    </RgButton>
  </div>
</template>
<script>
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "SmallHollowButton",
  components: { RgButton },
  mixins: [RgButtonMixin],
  props: {
    borderColor: {
      type: String,
      default: "#1E88A9",
    },
  },
};
</script>
