import Report from "$emergency/view/report/store";
import Bulletin from "$emergency/bulletin/store";
import PreRegistration from "$emergency/pre-registration/store";
import AdministrativeCheckOut from "$emergency/administrative-check-out/store";
import mutations from "./mutations";

export default {
  namespaced: true,
  mutations,
  modules: {
    Report,
    Bulletin,
    PreRegistration,
    AdministrativeCheckOut,
  },
};
