<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import {
  SearchAppointmentPriority,
  SearchExamPriority,
  SearchHospitalizationPriority,
} from "./action";
export default {
  name: "RgSelectPriorityQueue",
  extends: RgSelect,
  props: {
    moduleId: {
      required: true,
      type: Number,
    },
    label: {
      type: String,
      default: "Prioridade",
    },
    defaultText: {
      type: String,
      default: "Todas",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  computed: {
    moduleIsAppointment() {
      return this.moduleId === 2;
    },
    moduleIsExam() {
      return this.moduleId === 33;
    },
    moduleIsHospitalization() {
      return this.moduleId === 14;
    },
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (this.moduleIsAppointment) {
        const data = await SearchAppointmentPriority();
        this.valuesData = data.map((item) => {
          return {
            value: item.fcp_id_prioridades_fila,
            label: item.fcp_prioridades_fila,
          };
        });
      } else if (this.moduleIsExam) {
        const data = await SearchExamPriority();
        this.valuesData = data.map((item) => {
          return {
            value: item.fep_id_prioridades_fila,
            label: item.fep_prioridades_fila,
          };
        });
      } else if (this.moduleIsHospitalization) {
        const data = await SearchHospitalizationPriority();
        this.valuesData = data.map((item) => {
          return {
            value: item.fnp_id_prioridades_fila,
            label: item.fnp_prioridades_fila,
          };
        });
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
