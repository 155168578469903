<template>
  <div v-show="show" class="modal-procedure-import-sigtap-bdsia">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      :with-close-button="
        !disabledClose ||
        (enabledReload && !disableButton) ||
        hasError ||
        hasProblem
      "
      class="rg-base-modal"
      @close="close"
    >
      <div
        v-if="!enabledReload"
        slot="header"
        class="modal-history-movement-header"
      >
        <span class="title">
          Atendimento {{ dataForm.type }} em andamento.<br />Aguarde a
          finalização do processo.
        </span>
      </div>
      <div v-if="!enabledReload" slot="body">
        <div>
          <div class="subtitle">
            Para garantir o sucesso da importação, não atualize essa página ou
            feche seu navegador durante o atendimento.
          </div>
          <div class="content">
            <font-awesome-icon
              v-if="hasError"
              icon="fa-solid fa-circle-exclamation"
              shake
              class="icon-error"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-rotate fa-spin"
              :spin="inProgress"
              class="icon-loader"
            />
          </div>
          <div class="content">
            <strong>{{ statusText }}</strong>
            <br />
            {{ currentTime }}
          </div>
        </div>
        <Steps :active="dataForm.procedureStep.length" class="steps">
          <Step
            icon="el-icon-download"
            :status="`${modificationStatus(1, '002').status}`"
            :class="`${modificationStatus(1, '002').class}`"
          ></Step>
          <Step
            icon="el-icon-upload2"
            :status="`${modificationStatus(2, '003').status}`"
            :class="`${modificationStatus(2, '003').class}`"
          ></Step>
          <Step
            icon="el-icon-view"
            :status="`${modificationStatus(3, '005').status}`"
            :class="`${modificationStatus(3, '005').class}`"
          ></Step>
          <Step
            icon="el-icon-download"
            :status="`${modificationStatus(4, '004').status}`"
            :class="`${modificationStatus(4, '004').class}`"
          ></Step>
          <Step
            icon="el-icon-check"
            :status="`${modificationStatus(5, '900').status}`"
            :class="`${modificationStatus(5, '900').class}`"
          ></Step>
        </Steps>
        <div class="content-info" v-html="dataForm.message"></div>
      </div>
      <div
        v-if="enabledReload"
        slot="header"
        class="modal-history-movement-header"
      >
        <span class="title">
          Existe um processo de Importação <br />em andamento
        </span>
      </div>
      <div v-if="enabledReload" slot="body">
        <div>
          <div class="subtitle-2">
            Houve uma interrupção na importação <br />das tabelas SIGTAP/BDSIA
            no sistema.
          </div>
          <div class="subtitle">
            Ao retomar o andamento da Importação das tabelas, certifique-se da
            disponibilidade para reiniciar o processo de inserção dos dados no
            sistema. Não é possivel cancelar ou interromper a Importação.
          </div>
          <div class="subtitle">
            <strong>
              <em>
                Os dados são disponibilizados pelo DataSUS, podendo apresentar
                <br />
                falha ou indisponibilidade do serviço.
              </em>
            </strong>
          </div>
          <div class="subtitle">Deseja Prosseguir com a Importação?</div>
        </div>
        <div class="modal-procedure-import-sigtap-bdsia-footer">
          <RgCancelButton
            id="cancel-btn"
            label="Cancelar"
            medium
            :disabled="disableButton"
            :disabledButton="disableButton"
            @click="close"
          />
          <MediumButton
            v-if="enabledReload"
            id="save-btn"
            label="Prosseguir"
            medium
            :disabled="disableButton"
            :disabledButton="disableButton"
            @click="reload"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, MediumButton, RgCancelButton } from "~tokio/primitive";
import { Steps, Step } from "element-ui";
import moment from "moment";

export default {
  name: "ModalProcedureSigtapBdsia",
  components: {
    RgBaseModal,
    MediumButton,
    RgCancelButton,
    Steps,
    Step,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showOld: {
      type: Function,
      default: () => {},
    },
    dataForm: Object,
    disabledClose: {
      type: Boolean,
      default: true,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    hasProblem: {
      type: Boolean,
      default: false,
    },
    reloadInit: Function,
  },
  data() {
    return {
      currentTime: moment().format("DD/MM/YYYY - HH:mm"),
      enabledReload: false,
      user_id: null,
      disableButton: false,
    };
  },
  computed: {
    statusText() {
      return this.dataForm.procedureStep.includes("100") ||
        this.dataForm.procedureStep.includes("500")
        ? "Falha no processo de importação"
        : this.dataForm.procedureStep.includes("900")
        ? `Etapa ${this.dataForm.procedureStep.length - 1}/5`
        : `Etapa ${this.dataForm.procedureStep.length - 1}/5`;
    },
    hasError() {
      return this.dataForm.procedureStep.includes("500");
    },
  },
  watch: {
    show: {
      handler(pValue) {
        if (pValue) {
          this.loadDate();
        }
      },
    },
    dataForm: {
      handler(pValue) {
        if (pValue && this.dataForm.procedureStep.length > 0) {
          this.enabledReload = false;
        } else {
          this.enabledReload = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.user_id = this.$store.getters["Login/GET_USER_ID"];
  },
  methods: {
    modificationStatus(position, code) {
      const value =
        this.dataForm.procedureStep[position] === code && code !== "900"
          ? this.dataForm.procedureStep[position + 1]
            ? { class: "step-success", status: "success" }
            : { class: "step-finish", status: "finish" }
          : this.dataForm.procedureStep[position] === "100" ||
            this.dataForm.procedureStep[position] === "500"
          ? { class: "step-error", status: "error" }
          : this.dataForm.procedureStep[position] === "900"
          ? this.dataForm.procedureStep.includes("100") ||
            this.dataForm.procedureStep.includes("500")
            ? { class: "step-error", status: "error" }
            : { class: "step-success", status: "success" }
          : this.dataForm.procedureStep.includes("100") ||
            this.dataForm.procedureStep.includes("500")
          ? { class: "step-error", status: "error" }
          : { class: "step-wait", status: "wait" };
      return value;
    },
    async loadDate() {
      const dataImport = {
        ...this.$store.getters[
          "Billing/ImportBdsiaSigtap/GET_DATA_IMPORT_SIGTAP_BDSIA"
        ],
      };
      switch (this.dataForm.type) {
        case "SIGTAP":
          this.currentTime = dataImport.date_import_sigtap;
          break;
        case "BDSIA":
          this.currentTime = dataImport.date_import_bdsia;
          break;
      }
    },
    async positionInQueue() {
      try {
        this.$loader.start();
        const resultSigtap = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/LIST_QUEUE_SIGTAP",
        );
        const resultBdsia = await this.$store.dispatch(
          "Billing/ImportBdsiaSigtap/LIST_QUEUE_BDSIA",
        );
        const positionSigtap = resultSigtap.findIndex(
          (element) => element.userId === String(this.user_id),
        );
        const positionBdsia = resultBdsia.findIndex(
          (element) => element.userId === String(this.user_id),
        );

        if (positionSigtap > -1) {
          this.reloadInit("SIGTAP", false);
        } else if (positionBdsia > -1) {
          this.reloadInit("BDSIA", false);
        } else {
          this.$toaster.info(
            "Confira no histórico de importações as tabelas disponíveis no sistema.",
            "Os arquivos SIGTAP/BDSIA já estão importados",
          );
          this.close();
        }
      } catch (error) {
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha ao entrar na Fila de Atendimento",
        );
        this.disableButton = false;
      } finally {
        this.$loader.finish();
      }
    },
    reload() {
      this.disableButton = true;
      this.positionInQueue();
    },
    close() {
      this.showOld(0);
    },
  },
};
</script>
