<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
export default {
  name: "RgSelectExamOrigin",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Origem",
    },
  },
  data() {
    return {
      origin: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.origin = [];

      this.origin = [
        { value: 1, label: "Internação" },
        { value: 2, label: "Emergência" },
        { value: 3, label: "Outros" },
      ];

      return this.origin;
    },
  },
};
</script>
