<template lang="html">
  <div v-if="show" class="modal-remove-hospitalization-exit">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">Excluir Saída</span>
      </div>

      <div slot="body" class="body">
        <div class="initial-info">
          <RgTypePatient
            :pac-id="isPatient"
            :sex="isPatient ? patientSex : indigentSex"
            class="icon"
          />

          <div class="info">
            <div class="text">
              Data e Hora da Saída:
              <b>
                {{
                  hospitalizationOut && hospitalizationOut.isa_data
                    ? hospitalizationOut.isa_hora
                      ? hospitalizationOut.isa_data +
                        " " +
                        hospitalizationOut.isa_hora
                      : hospitalizationOut.isa_data
                    : "Não informado"
                }}
              </b>
            </div>

            <div class="text">
              Motivo da Saída:
              <b>{{
                hospitalizationOut && hospitalizationOut.igs_nome
                  ? hospitalizationOut.igs_nome
                  : "Não informado"
              }}</b>
            </div>
          </div>

          <div class="info">
            <div class="text">
              Número da Internação:
              <b>{{
                hospitalizationInfo && hospitalizationInfo.int_numero
                  ? hospitalizationInfo.int_numero
                  : ""
              }}</b>
            </div>

            <div class="text">
              Paciente:
              <b>{{
                hospitalizationInfo && hospitalizationInfo.paciente
                  ? hospitalizationInfo.paciente
                  : ""
              }}</b>
            </div>
          </div>
        </div>

        <FormBase title="Internação">
          <RgValidatorForm ref="validator">
            <RgTextArea
              ref="reason"
              v-model="form.observation"
              :maxlength="500"
              :rules="{ required: true }"
              class="textarea"
              label="Motivo"
            />

            <div class="info-hospitalization">
              <RgSelectUnithealth v-model="form.selectUnity" disabled />

              <RgSelectSector
                v-model="form.selectedSector"
                :default-text="
                  form.selectUnity !== false
                    ? 'Todos'
                    : 'Nenhum setor disponível'
                "
                :unit-health="form.selectUnity"
                disabled
              />

              <RgSelectPlaces
                ref="place"
                v-model="form.selectedLocale"
                :sector="form.selectedSector"
                :default-text="'Todos'"
                :empty-default-text="'Nenhum local disponível'"
                disabled
              />

              <RgSuggestHospitalizationBed
                ref="bed"
                v-model="form.bed"
                :bed-id="getBedId"
                :class="{ disable: !form.selectedLocale }"
                :local-id="getLocale"
                :include-actual-bed-in-search="validLocaleForInclude"
                :exclude-actual-bed-in-search="validLocaleForExclude"
                :isActive="1"
                disabled
                @selected="selectingHospitalizationBed"
              />
            </div>
          </RgValidatorForm>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <div class="manager">
          <RgCleanButton
            :class="{ disable: isSaving || !isBedEmpty }"
            :disabled="isSaving || !isBedEmpty"
            small
            type="button"
            class="clean"
            @click="clean"
          />
          <RgSaveButton
            ref="save"
            :class="{ disable: isSaving || !isBedEmpty || !disableSaveButton }"
            :disabled="isSaving || !isBedEmpty || !disableSaveButton"
            title="Salvar"
            medium
            type="button"
            class="save"
            @click="removeHospitalizationExit"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgTextArea,
  RgSaveButton,
  RgCleanButton,
  RgSelectUnithealth,
  RgSelectSector,
  RgSelectPlaces,
  RgValidatorForm,
} from "~tokio/primitive";
import { toast, AlertError } from "~tokio/primitive/notification";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient.vue";
import RgSuggestHospitalizationBed from "$hospitalization/common/component/suggest/rg-suggest-hospitalization-bed/RgSuggestHospitalizationBed.vue";
import TYPEBED from "$hospitalization/common/component/util/GetTypeBedValues";

export default {
  name: "ModalRemoveHospitalizationExit",
  components: {
    RgBaseModal,
    FormBase,
    RgTextArea,
    RgTypePatient,
    RgSaveButton,
    RgCleanButton,
    RgSelectUnithealth,
    RgSelectSector,
    RgSelectPlaces,
    RgSuggestHospitalizationBed,
    RgValidatorForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hospitalizationInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        observation: null,
        selectUnity: null,
        selectedSector: null,
        selectedLocale: null,
        bed: null,
        bedId: null,
      },
      hospitalizationOut: null,
      isSaving: false,
      isBedEmpty: true,
      disableSaveButton: false,
    };
  },
  computed: {
    isPatient() {
      return this.hospitalizationInfo && this.hospitalizationInfo.pac_id
        ? this.hospitalizationInfo.pac_id
        : 0;
    },

    indigentSex() {
      return this.hospitalizationInfo && this.hospitalizationInfo.ini_id_sexos
        ? this.hospitalizationInfo.ini_id_sexos
        : null;
    },

    patientSex() {
      return this.hospitalizationInfo && this.hospitalizationInfo.pes_id_sexos
        ? this.hospitalizationInfo.pes_id_sexos
        : null;
    },

    hasFillTextArea() {
      return !this.form.observation || this.form.observation.length < 5;
    },

    getBedId() {
      return this.hospitalizationInfo && this.hospitalizationInfo.inl_id
        ? this.hospitalizationInfo.inl_id
        : 0;
    },

    getLocale() {
      return this.form.selectedLocale
        ? this.form.selectedLocale
        : this.hospitalizationInfo && this.hospitalizationInfo.lca_id
        ? this.hospitalizationInfo.lca_id
        : 0;
    },

    validLocaleForInclude() {
      const existLca_id =
        this.hospitalizationInfo && this.hospitalizationInfo.lca_id;
      const existBedSituation =
        this.hospitalizationInfo &&
        this.hospitalizationInfo.inl_id_internacoes_situacoes_leito;
      return (
        existLca_id &&
        this.hospitalizationInfo.lca_id === this.form.selectedLocale &&
        existBedSituation &&
        this.hospitalizationInfo.inl_id_internacoes_situacoes_leito === 1
      );
    },

    validLocaleForExclude() {
      const existLca_id =
        this.hospitalizationInfo && this.hospitalizationInfo.lca_id;
      const existBedSituation =
        this.hospitalizationInfo &&
        this.hospitalizationInfo.inl_id_internacoes_situacoes_leito;
      return (
        existLca_id &&
        this.hospitalizationInfo.lca_id !== this.form.selectedLocale &&
        existBedSituation &&
        this.hospitalizationInfo.inl_id_internacoes_situacoes_leito !== 1
      );
    },
  },
  watch: {
    hospitalizationInfo(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    show(pValue) {
      if (pValue) {
        this.getOutHospData(this.hospitalizationInfo);
        this.fillInput(this.hospitalizationInfo);
      }
    },

    "form.observation"(pValue) {
      if (!pValue) return true;

      !pValue.trim() || pValue.length < 5
        ? (this.disableSaveButton = false)
        : (this.disableSaveButton = true);
    },

    "form.selectedSector"(pValue) {
      this.form.selectedLocale = null;
      this.form.bed = "";
      this.form.bedId = null;
    },

    "form.selectedLocale"(pValue) {
      this.$refs.bed.clearData();
      this.form.bed = "";
      this.form.bedId = null;
    },
  },
  methods: {
    async getOutHospData(pItem) {
      this.hospitalizationOut = await this.$store.dispatch(
        "Hospitalization/Hospitalizations/SEARCH_CUSTOM_HOSPITALIZATION_OUTPUT_DETAIL",
        {
          int_id: pItem.int_id || 0,
          inl_id: pItem.inl_id || 0,
          int_id_ocupacoes_cbo: pItem.int_id_ocupacoes_cbo || 0,
        },
      );
    },

    async fillInput(pValue) {
      this.form.selectUnity = pValue.uns_id;
      this.form.selectedSector = pValue.set_id;
      this.$nextTick(async () => {
        await this.$refs.place.doSearch();
        this.form.selectedLocale = pValue.lca_id;
        this.form.bedId = pValue.inl_id;
        this.$refs.bed.forceSelection(pValue.inl_nome);
      });

      this.isBedEmpty =
        pValue.inl_id_internacoes_situacoes_leito === TYPEBED.VAZIO ||
        pValue.inl_id_internacoes_situacoes_leito === TYPEBED.DESINFECCAO;
      if (!this.isBedEmpty) {
        AlertError("Leito não disponível");
      }
    },

    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async removeHospitalizationExit() {
      try {
        this.isSaving = true;
        await this.$refs.save.actionSubmit();

        if (!(await this.isFormValid())) {
          return AlertError("Verifique os Campos");
        }

        if (this.hasFillTextArea) {
          return AlertError(
            "O Campo observação deve ter mais de 5 caracteres.",
          );
        }

        await this.$store.dispatch(
          "Hospitalization/DELETE_HOSPITALIZATION_EXIT",
          {
            hospitalizationId: this.hospitalizationInfo.int_id,
            bedId: this.form.bedId,
            patientId: this.hospitalizationInfo.pac_id,
            typeBedId: this.hospitalizationInfo.itl_id,
            indigentId: this.hospitalizationInfo.ini_id,
            hospitalizationExitId: this.hospitalizationInfo.isa_id,
            observationHistoric: this.form.observation,
            deathCause: this.hospitalizationOut.isa_causa_obito,
          },
        );

        toast.success("A saída foi excluída com sucesso!");

        this.$emit("reSearch", true);
        this.close();
      } catch (error) {
        toast.error(error);
        await this.$refs.save.fail();
      } finally {
        this.isSaving = false;
        await this.$refs.save.actionDone();
      }
    },

    selectingHospitalizationBed(pBed) {
      if (pBed && pBed.source && pBed.source.inl_id) {
        this.form.bedId =
          pBed.source && pBed.source.inl_id ? pBed.source.inl_id : 0;
      } else {
        this.form.bed = "";
        this.form.bedId = null;
      }
    },

    clean() {
      if (this.$refs.reason) this.$refs.reason.cleanValidate();
      if (this.$refs.reason) this.$refs.reason.resetCharacter();
      this.form.observation = null;
    },

    close() {
      this.clean();
      this.hospitalizationOut = null;
      if (this.$refs.bed) this.$refs.bed.clearData();
      if (this.$refs.bed) this.$refs.bed.cleanValidate();
      this.$emit("close");
    },
  },
};
</script>
