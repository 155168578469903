import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchSubproceduresExam(
    $esu_id: Int
    $esu_nome_exames_procedimentos_filho: String
    $limit: Int
  ) {
    SearchSubproceduresExam(
      esu_id: $esu_id
      esu_nome_exames_procedimentos_filho: $esu_nome_exames_procedimentos_filho
      limit: $limit
    ) {
      count
      rows {
        esu_id
        esu_nome_exames_procedimentos_pai
        esu_nome_exames_procedimentos_filho
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
  }).catch((err) => {
    console.error(err);
  });
  return data.SearchSubproceduresExam;
};
