import GetPharmacyConsolidatedReport from "./GetPharmacyConsolidatedReport";
import GetPharmacyMedicationsDispensedPatientsReport from "./GetPharmacyMedicationsDispensedPatientsReport";

// central
import GetPharmacyCentralReversalReport from "./pharmacy-central/GetPharmacyCentralReversalReport";
import GetPharmacyCentralDiscardReport from "./pharmacy-central/GetPharmacyCentralDiscardReport";
import GetPharmacyCentralRequestsReport from "./pharmacy-central/GetPharmacyCentralRequestsReport";
import GetPharmacyCentralEntryReport from "./pharmacy-central/GetPharmacyCentralEntryReport";
import GetPharmacyCentralTransferReport from "./pharmacy-central/GetPharmacyCentralTransferReport";
import GetPharmacyCentralOutputReport from "./pharmacy-central/GetPharmacyCentralOutputReport";
import GetPharmacyCentralTotalConsumptionReport from "./pharmacy-central/GetPharmacyCentralTotalConsumptionReport";
import GetPharmacyCentralBatchTraceabilityReport from "./pharmacy-central/GetPharmacyCentralBatchTraceabilityReport";
import GetPharmacyCentralValidityReport from "./pharmacy-central/GetPharmacyCentralValidityReport";
import GetPharmacyCentralStockProductReport from "./pharmacy-central/GetPharmacyCentralStockProductReport";
import GetPharmacyCentralAverageConsumptionReport from "./pharmacy-central/GetPharmacyCentralAverageConsumptionReport";
import GetPharmacyCentralRequestsTransferReport from "./pharmacy-central/GetPharmacyCentralRequestsTransferReport";
import GetPharmacyCentralEntryOutputReport from "./pharmacy-central/GetPharmacyCentralEntryOutputReport";
import GetPharmacyCentralEndForecastReport from "./pharmacy-central/GetPharmacyCentralEndForecastReport";

// satellite
import GetPharmacySatelliteReversalReport from "./pharmacy-satellite/GetPharmacySatelliteReversalReport";
import GetPharmacySatelliteConsumptionReport from "./pharmacy-satellite/GetPharmacySatelliteConsumptionReport";
import GetPharmacySatelliteEntryReport from "./pharmacy-satellite/GetPharmacySatelliteEntryReport";
import GetPharmacySatelliteOutputReport from "./pharmacy-satellite/GetPharmacySatelliteOutputReport";
import GetPharmacySatelliteRequestsReport from "./pharmacy-satellite/GetPharmacySatelliteRequestsReport";
import GetPharmacySatelliteExpectedReturnReport from "./pharmacy-satellite/GetPharmacySatelliteExpectedReturnReport";
import GetPharmacySatelliteStockProductReport from "./pharmacy-satellite/GetPharmacySatelliteStockProductReport";
import GetPharmacySatelliteServiceHistoryReport from "./pharmacy-satellite/GetPharmacySatelliteServiceHistoryReport";
import GetPharmacySatelliteProductValidityReport from "./pharmacy-satellite/GetPharmacySatelliteProductValidityReport";
import GetPharmacySatelliteProductForPatientsReport from "./pharmacy-satellite/GetPharmacySatelliteProductForPatientsReport";
import GetPharmacySatelliteEntryOutputReport from "./pharmacy-satellite/GetPharmacySatelliteEntryOutputReport";
import GetPharmacySatelliteDemandProductReport from "./pharmacy-satellite/GetPharmacySatelliteDemandProductReport";

export default {
  GET_PHARMACY_CONSOLIDATED_REPORT: GetPharmacyConsolidatedReport,
  GET_PHARMACY_MEDICATIONS_DISPENSED_PATIENTS: GetPharmacyMedicationsDispensedPatientsReport,
  GET_PHARMACY_CENTRAL_REVERSAL: GetPharmacyCentralReversalReport,
  GET_PHARMACY_CENTRAL_REQUESTS: GetPharmacyCentralRequestsReport,
  GET_PHARMACY_CENTRAL_DISCARD: GetPharmacyCentralDiscardReport,
  GET_PHARMACY_CENTRAL_ENTRY: GetPharmacyCentralEntryReport,
  GET_PHARMACY_CENTRAL_TRANSFER: GetPharmacyCentralTransferReport,
  GET_PHARMACY_CENTRAL_OUTPUT: GetPharmacyCentralOutputReport,
  GET_PHARMACY_CENTRAL_TOTAL_CONSUMPTION: GetPharmacyCentralTotalConsumptionReport,
  GET_PHARMACY_CENTRAL_BATCH_TRACEABILITY: GetPharmacyCentralBatchTraceabilityReport,
  GET_PHARMACY_CENTRAL_VALIDITY: GetPharmacyCentralValidityReport,
  GET_PHARMACY_CENTRAL_EXPECTED_RETURN: GetPharmacySatelliteExpectedReturnReport,
  GET_PHARMACY_CENTRAL_STOCK_PRODUCT: GetPharmacyCentralStockProductReport,
  GET_PHARMACY_CENTRAL_AVERAGE_CONSUMPTION: GetPharmacyCentralAverageConsumptionReport,
  GET_PHARMACY_SATELLITE_OUTPUT: GetPharmacySatelliteOutputReport,
  GET_PHARMACY_SATELLITE_REVERSAL: GetPharmacySatelliteReversalReport,
  GET_PHARMACY_SATELLITE_CONSUMPTION: GetPharmacySatelliteConsumptionReport,
  GET_PHARMACY_SATELLITE_ENTRY: GetPharmacySatelliteEntryReport,
  GET_PHARMACY_SATELLITE_REQUESTS: GetPharmacySatelliteRequestsReport,
  GET_PHARMACY_SATELLITE_STOCK_PRODUCT: GetPharmacySatelliteStockProductReport,
  GET_PHARMACY_SATELLITE_EXPECTED_RETURN: GetPharmacySatelliteExpectedReturnReport,
  GET_PHARMACY_SATELLITE_SERVICE_HISTORY: GetPharmacySatelliteServiceHistoryReport,
  GET_PHARMACY_SATELLITE_PRODUCT_VALIDITY: GetPharmacySatelliteProductValidityReport,
  GET_PHARMACY_SATELLITE_PRODUCT_FOR_PATIENTS_REPORT: GetPharmacySatelliteProductForPatientsReport,
  GET_PHARMACY_CENTRAL_REQUESTS_TRANSFER: GetPharmacyCentralRequestsTransferReport,
  GET_PHARMACY_CENTRAL_ENTRY_OUTPUT: GetPharmacyCentralEntryOutputReport,
  GET_PHARMACY_SATELLITE_ENTRY_OUTPUT: GetPharmacySatelliteEntryOutputReport,
  GET_PHARMACY_SATELLITE_DEMAND_PRODUCT: GetPharmacySatelliteDemandProductReport,
  GET_PHARMACY_CENTRAL_END_FORECAST: GetPharmacyCentralEndForecastReport,
};
