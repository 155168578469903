import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleOutpatientConsultationControl = (pEmployeeData, pData) => {
  const logo = LocalStorage.getObject("client-logo");
  const texto = "Lista de Agendamentos";

  let dadosTabela = "";
  let number = 1;
  let unithealth = "";

  if (pEmployeeData.uns_nome) {
    unithealth += `<div><label>Unidade de Saúde: </label><span>${pEmployeeData.uns_nome}</span> </div>`;
  } else {
    unithealth += `<div><label>Unidade de Saúde: </label><span>${pEmployeeData.unithealth}</span> </div>`;
  }

  pData.forEach((item) => {
    "<tr >";
    dadosTabela += "<td>" + number++ + "</td>";
    dadosTabela += "<td>" + item.eap_hora + "</td>";
    dadosTabela += "<td>" + item.pes_nome + "</td>";
    dadosTabela += "<td>" + item.pac_prontuario_unico + "</td>";
    dadosTabela += "<td>" + item.stp_novo_nome_procedimento + "</td></tr>";
  });

  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="utf-8">
    <title>Esus</title>
    <style type="text/css">
    @media print{@page {size: landscape}}

      page {
        background: white;
        display: block;
        margin: 0 auto;
        margin-bottom: 0.5cm;
      }

      page[size="landscape"] {
        width: 28.7cm;
        height: 21cm;
        margin: 20px;
      }

      .cabecalho {
        display: flex;
        align-items: center;
      }

      .titulo {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      }

      body {
        font-family: 'Montserrat', sans-serif;
      }

      h1 {
        font-size: 12pt;
        text-transform: uppercase;
        text-align: center;
      }

      h2 {
        font-size: 11pt;
        font-style: italic;
        text-transform: uppercase;
      }

      .logo-esus{
        margin-right: 40px;
      }

      hr {
        margin: 15px 0;
        border: 0.5px solid #CFCFCF;
      }

      .info-fila{
        display: grid;
        grid-template-columns: 4fr 1fr;
      }

      .info-cabecalho{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 20px;
        row-gap: 10px;
      }

      .logo-cliente {
        width: 70px;
        margin-left: 68%;
      }

      label,
      span,
      p {
        font-size: 16px;
      }

      label {
        font-weight: 700;
      }

      table {
        width: 100%;
        font-size: 14px;
        top: 10%;
        border-collapse: collapse;
      }

      thead, tr {
        border: 1px solid grey;
      }

      tr:nth-child(even) {
        background-color: #f6f6f6;
      }

      th {
        border: 1px solid grey;
        font-weight: 700;
        text-align:left;
      }

      td {
        border: 1px solid grey;
      }

      th,td{
        padding:5px;
      }

      th:nth-child(1),
      tr td:nth-child(1) {
          width: 5%;
      }

      th:nth-child(2),
      tr td:nth-child(2) {
          width: 5%;
      }

      th:nth-child(3),
      tr td:nth-child(3) {
          width: 15%;
      }

      th:nth-child(4),
      tr td:nth-child(4) {
          width: 5%;
      }

      th:nth-child(5),
      tr td:nth-child(5) {
          width: 15%;
      }


      .flex {
        display: flex;
      }

      .date {
        margin-left: auto;
      }
    </style>
  </head>

  <body>
    <page size="landscape">
      <div class="cabecalho">
      <img src="/static/images/logo-esus-relatorio.png" class="logo-esus">
      <h3 class="titulo">${texto}</h3>
      </div>
      <hr />

      <div class="info-fila">
        <div class="info-cabecalho">
          <div class="flex">
            <div>${unithealth}</div>
            <div class="date">
              <label>Data: </label>
              <span> ${pEmployeeData.data_exame} </span>
            </div>
          </div>
          <div>
            <label>Profissional: </label>
            <span>
            ${
              pEmployeeData.pes_nome
                ? pEmployeeData.pes_nome
                : pEmployeeData.fun_nome
            }
            </span>
          </div>
          <div>
            <label>Ocupação: </label>
            <span>
            ${pEmployeeData.ocp_nome || "Não informado"}
            </span>
          </div>
        </div>
      <div>

      <img class="logo-cliente" src="${logo}">
      </div>
      </div>

      <hr/>

      <div style="display: flex; justify-content: space-between;">
        <div>
          <label>Início: </label>
          <span>${pEmployeeData.eea_inicio_atendimentos}</span>
        </div>

        <div>
          <label>Quant. de horários: </label>
          <span>${pEmployeeData.eea_quantidade_exames}</span>
        </div>

        <div>
          <label>Ocupados: </label>
          <span>${pEmployeeData.eea_quantidade_marcadas}</span>
        </div>

        <div>
          <label>Livre: </label>
          <span>${pEmployeeData.eea_quantidade_restantes}</span>
        </div>
      </div>

      <div style="margin-top: 16px; margin-bottom: 16px;">
        <label>Pacientes agendados para o dia/período: </label>
        <span>${pData.length}</span>
      </div>

      <table class="table">
        <thead class="header">
          <th> Nº </th>
          <th> Hora </th>
          <th> Paciente </th>
          <th> Pront. Único </th>
          <th> Procedimento </th>
        </thead>

        <div class="table">
          ${dadosTabela}
        <div>
      </table>
    </page>
  </body>

  </html>
    `;
  return html;
};

export default ScheduleOutpatientConsultationControl;
