<template>
  <div>
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show && isReady"
      class="modal-patient-image-in-row"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-patient-image-in-row-header">
        <span class="title">{{ title }}</span>
      </div>

      <div slot="body" class="modal-patient-image-in-row-body">
        <FormBase title="Arquivos">
          <div class="full-area">
            <div class="half-area">
              <div>
                <input
                  ref="file"
                  class="input-file"
                  type="file"
                  :accept="acceptTypes"
                  :disabled="viewOnly"
                  @change="uploadFile"
                />
                <div @click="openFileDialog">
                  <label class="input-label">
                    <!-- disabled -->
                    <RgInput
                      id="input-file"
                      readonly
                      label="Enviar Novo Arquivo"
                      :placeholder="drawInformationMessage()"
                      :legend="'É permitido arquivos de até 3MB'"
                      cursosMouseHover
                    />
                  </label>
                </div>
              </div>

              <RgAddButton
                id="new-button"
                class="new"
                type="button"
                :disabled="!file || viewOnly"
                @click="confirmUpload"
              />
            </div>

            <div class="file-list">
              <span v-if="files && files.length > 0">Arquivos Enviados</span>
              <div v-if="files && files.length > 0" class="top-btns">
                <RgLessButton
                  id="less-button"
                  :disabled="!hasSelectedDocument || viewOnly"
                  title="Remover arquivo"
                  @click="removeFile(selectedData)"
                />
                <a
                  id="icon-arrow-download"
                  :disabled="!hasSelectedDocument"
                  :class="{
                    disable: !hasSelectedDocument,
                    disablebtn: !hasSelectedDocument,
                  }"
                  class="download"
                  title="Baixar"
                  @click="actionDownload"
                >
                  <IconArrowDownload class="svg" />
                </a>
                <a
                  id="icon-show"
                  :href="selectedData !== null ? selectedData.url : null"
                  :disabled="!hasSelectedDocument"
                  :class="{
                    disable: !hasSelectedDocument,
                    disablebtn: !hasSelectedDocument,
                  }"
                  target="_blank"
                  class="see"
                  title="Visualizar"
                >
                  <IconShow />
                </a>
              </div>
              <div v-if="files && files.length > 0">
                <RgTable :columns="COLUMNS" class="rg-table">
                  <tr
                    v-for="(item, index) in files"
                    :key="index"
                    slot="rows"
                    class="tr"
                    :class="{ selected: index === activeRow }"
                    @click="selectLine(item, index)"
                  >
                    <td>
                      {{ item.filename || item.name }}
                    </td>
                    <td>
                      {{ item.dateHour || item.hourDate }}
                    </td>
                  </tr>
                </RgTable>
              </div>
              <div v-else class="empty-result">
                <IconEmpty />
                <span>Não foram encontrados arquivos</span>
              </div>
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton
          id="cancel-button"
          medium
          type="button"
          @click="close"
        />
        <RgConfirmButton
          id="confirm-button"
          :disabled="viewOnly"
          medium
          type="button"
          @click="confirm"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTable from "~tokio/foundation/rg-table/RgTable";
import { AlertError } from "~tokio/primitive/notification";
import SendFile from "~tokio/primitive/modal/modal-upload/actions/SendFile";
import {
  RgBaseModal,
  RgAddButton,
  RgConfirmButton,
  IconArrowDownload,
  RgInput,
  RgCancelButton,
  RgLessButton,
  IconEmpty,
} from "~tokio/primitive";
import { IconShow } from "~tokio/primitive/icon/symbols";
import RemoveUploadedFile from "./actions/RemoveUploadedFile";
import DownloadByUrl from "~common/utils/DownloadByUrl";
import SaveNewFiles from "./actions/SaveNewFiles";
import GetQueueFiles from "./actions/GetQueueFiles";

export default {
  name: "ModalPatientImageInRow",
  components: {
    RgBaseModal,
    IconEmpty,
    IconShow,
    IconArrowDownload,
    RgAddButton,
    RgConfirmButton,
    FormBase,
    RgLessButton,
    RgInput,
    RgTable,
    RgCancelButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Arquivos do Paciente na Fila",
    },
    fillId: {
      type: Number,
      default: 0,
    },
    modId: {
      type: Number,
      required: true,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: Array,
      default: () => {
        return ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
      },
    },
    maxSizeInKB: {
      type: Number,
      default: 3145728,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tempFiles: [],
      savedFiles: [],
      selectedIndex: null,
      selectedData: null,
      isReady: false,
      activeRow: null,
      file: null,
    };
  },

  computed: {
    files() {
      return [].concat(this.savedFiles).concat(this.tempFiles);
    },
    hasFiles() {
      return this.files.length > 0;
    },
    hasSelectedDocument() {
      return this.selectedData && Object.keys(this.selectedData).length > 0;
    },
    existFile() {
      return this.file !== null;
    },
    acceptTypes() {
      return this.accept.join(", ");
    },
  },

  watch: {
    async show(pValue) {
      if (pValue) {
        this.queueId = this.fillId;
        this.loadSavedFiles();
      }
    },
  },

  created() {
    this.COLUMNS = [{ name: "Documento" }, { name: "Data do Envio" }];
  },

  async mounted() {
    this.loadSavedFiles();
  },

  methods: {
    selectLine(pItem, pIndex) {
      const isSameLine = this.selectedData === pItem;

      if (isSameLine) {
        this.activeRow = null;
        this.selectedData = {};
        this.selectedIndex = pIndex;
      } else {
        this.activeRow = pIndex;
        this.selectedData = pItem;
        this.selectedIndex = null;
      }
    },

    async actionDownload() {
      try {
        if (!this.hasSelectedDocument) {
          return;
        }
        this.$loader.start();
        const url = this.selectedData !== null ? this.selectedData.url : null;
        const name = `${this.selectedData.ipf_imagens}`;
        await DownloadByUrl(url, name);
      } catch (pErr) {
        this.$toaster.error("Falha ao fazer download do arquivo");
      } finally {
        this.$loader.finish();
      }
    },

    openFileDialog() {
      this.$refs.file.click();
    },

    uploadFile(pFile) {
      const fileInUpload = this.$refs.file.files[0];
      const isValid = this.validateFile(fileInUpload);

      if (isValid) {
        this.file = fileInUpload;
      } else {
        this.$refs.file.value = null;
      }
    },

    drawInformationMessage() {
      return this.existFile
        ? "Arquivo inserido"
        : "Selecione um documento do computador";
    },

    async loadSavedFiles() {
      try {
        if (!this.queueId) {
          return;
        }

        this.$loader.start();
        const { data } = await GetQueueFiles(this.queueId);
        this.savedFiles = data;
        this.isReady = true;
      } catch (pErr) {
        this.$toaster.error("Falha ao buscar arquivos");
      } finally {
        this.$loader.finish();
      }
    },

    validateFile(pFile) {
      const hasFile = pFile !== null;

      if (!hasFile) {
        AlertError("Adicione um arquivo");
        return false;
      }

      const hasInvalidSize = pFile.size > this.maxSizeInKB;

      if (hasInvalidSize) {
        AlertError("Arquivo com tamanho maior que o esperado");
        return false;
      }

      const fileType = pFile.type;
      const hasInvalidTypes = !this.accept.includes(fileType);

      if (hasInvalidTypes) {
        AlertError(`Formato inválido. Permitidos: ${this.acceptTypes}`);
        return false;
      }

      return true;
    },

    getUploadedFileInfo(pValue) {
      this.tempFiles = this.tempFiles.concat(pValue);
    },

    confirmUpload() {
      if (this.existFile) {
        this.$loader.start();

        SendFile(this.file)
          .then((result) => {
            this.$loader.finish();
            this.getUploadedFileInfo(result.data.fileInfo);
          })
          .catch((err) => {
            this.$loader.finish();
            this.$toaster.error("Falha ao enviar o arquivo:" + err.message);
          });
        this.file = null;
        this.$refs.file.value = null;
      } else {
        AlertError("Adicione um arquivo.");
      }
    },

    async saveFiles() {
      try {
        this.$loader.start("Salvando arquivos");

        const fileNames = this.tempFiles.map((file) => file.filename);
        await SaveNewFiles(this.queueId, fileNames, this.modId);

        await this.loadSavedFiles();
        this.tempFiles = [];

        this.$toaster.success("Arquivos enviados com sucesso");
        this.$emit("reSearch", true);
        this.close();
      } catch (pErr) {
        this.$toaster.error("Falha ao salvar arquivos" + pErr);
      } finally {
        this.$loader.finish();
      }
    },

    removeFile(selectedFile) {
      this.$loader.start();

      RemoveUploadedFile(selectedFile.url_remove, this.modId)
        .then(() => {
          this.$toaster.success("Arquivo removido com sucesso");
          this.$emit("reSearch", true);
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao remover o arquivo:" + pErr.message);
        })
        .finally(() => {
          this.tempFiles = this.tempFiles.filter((item) => {
            return item.filename !== selectedFile.filename;
          });

          this.savedFiles = this.savedFiles.filter((item) => {
            return item.filename !== selectedFile.filename;
          });

          this.selectedIndex = null;
          this.selectedData = null;
          this.activeRow = null;
          this.$loader.finish();
        });
    },

    async confirm() {
      const hasTempFiles = this.tempFiles?.length > 0;

      if (hasTempFiles) {
        await this.saveFiles();
      } else {
        this.$toaster.warning("Nenhum arquivo adicionado");
      }

      const files = this.$utils.obj.DeepCopy(this.tempFiles);
      this.clean();
      this.$emit("confirmed", files, this.files?.length);
      this.$emit("close");
    },

    close() {
      const hasSavedFiles = this.savedFiles && this.savedFiles.length > 0;
      if (this.tempFiles.length > 0) {
        const proceed = confirm(
          "Deseja confirmar os anexos que foram enviados nessa tela?",
        );

        if (proceed === true) {
          return this.confirm();
        }

        // tentar remover arquivos temporarios que nao foram confirmados
        this.tempFiles.forEach((item) => {
          this.removeFile(item);
        });

        this.$emit("close", hasSavedFiles);
      } else {
        this.$emit("close", hasSavedFiles);
      }

      this.clean();
    },

    clean() {
      this.queueId = null;
      this.tempFiles = [];
      this.savedFiles = [];
      this.selectedIndex = null;
      this.selectedData = null;
      this.isReady = false;
      this.activeRow = null;
      this.file = null;
      this.$refs.file.value = null;
    },
  },
};
</script>
