<template>
  <div class="ceiling-report">
    <div class="grid">
      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Dados do Relatório Selecionado</span>
      </div>
      <div class="selectinput">
        <RgSelectUnithealth
          id="unit-health"
          ref="unitHealth"
          v-model="form.unitHealth"
          :rules="{ required: true }"
          class="input"
          label="Unidade de Saúde"
          placeholder="Digite a Unidade de Saúde"
          withCity
          @change="selectedUnitHealth"
        />
      </div>
      <div class="selectinput">
        <RgSuggestProceduresExam
          id="procedure-exame"
          v-model="form.procedure"
          label="Procedimento"
          @selected="selectProcedure"
        />
      </div>
      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período</span>
      </div>
      <div class="selectinput _flex">
        <RgInputDate
          id="initialDate"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          :max-date="new Date()"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="finalDate"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxFinalDate"
          label="Data Final"
          class="inputitem mg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgSelectUnithealth } from "~tokio/primitive";

import RgSuggestProceduresExam from "@app/exam/common/component/rg-suggest-procedure-exam/RgSuggestProceduresExam";

import moment from "moment";

const FORM_FILTER = {
  unitHealth: null,
  procedure: null,
  procedureCode: null,
  procedureName: null,
  uns_nome: null,
  mun_nome: null,
  initialDate: moment().add(-61, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
};

export default {
  name: "CeilingReport",
  components: {
    RgInputDate,
    RgSelectUnithealth,
    RgSuggestProceduresExam,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Emissão de Teto",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      minFinalDate: null,
      maxFinalDate: null,
    };
  },
  computed: {
    printReportCss() {
      return (
        " @page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: right; }" +
        ".report-column-2 { white-space: normal !important; text-align: left; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; }" +
        ".report-column-5 { white-space: normal !important; text-align: left; }" +
        ".report-column-6 { white-space: normal !important; text-align: right; }" +
        ".report-column-7 { white-space: normal !important; text-align: right; }" +
        ".report-column-8 { white-space: normal !important; text-align: right; }" +
        ".report-column-9 { white-space: normal !important; text-align: right; }" +
        ".report-column-10 { white-space: normal !important; text-align: right; }" +
        ".report-column-11 { white-space: normal !important; text-align: right; }" +
        ".report-column-12 { white-space: normal !important; text-align: right; }"
      );
    },
    getNameSmartTable() {
      return "CeilingReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    getMaxFinalDate() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(61, "days")
              .format(),
          )
        : null;
    },
  },
  watch: {
    "form.finalDate"(pValue, pPrev) {
      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
    },
  },
  created() {
    this.MAX_DAYS_DATE_INTERVAL = 61;
    this.COLUMN = [
      { name: "Competência", key: "COMPETENCIA" },
      { name: "Unidade de Saúde", key: "UNIDADE_NOME", align: "left" },
      { name: "Município", key: "MUNICIPIO_NOME", align: "left" },
      {
        name: "Cód. do Procedimento",
        key: "CODIGO_PROCEDIMENTO",
      },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      { name: "Grupo", key: "GRUPO", align: "left" },
      { name: "Subgrupo", key: "SUB_GRUPO", align: "left" },
      { name: "Forma de Org", key: "FORM_ORG", align: "left" },
      { name: "Efetivados", key: "EFETIVADO", align: "right" },
      { name: "Não Efetivados", key: "NAO_EFETIVADO", align: "right" },
      { name: "Abertos", key: "ABERTO", align: "right" },
      { name: "Valor Total", key: "TOTAL", align: "right" },
      { name: "Valor Unitário", key: "VALOR_UNITARIO", align: "right" },
      { name: "Valor Restante", key: "VALOR_RESTANTE", align: "right" },
      { name: "Teto do Procedimento", key: "TOTAL_TETO", align: "right" },
    ];
  },
  methods: {
    selectedUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.uns_nome = pValue.uns_nome;
        this.form.mun_nome = pValue.mun_nome;
      } else {
        this.form.uns_nome = null;
        this.form.mun_nome = null;
      }
    },
    selectProcedure(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const {
          stp_novo_codigo_procedimento,
          stp_novo_nome_procedimento,
        } = source;

        this.form.procedureCode = stp_novo_codigo_procedimento;
        this.form.procedureName = stp_novo_nome_procedimento;
      } else {
        this.form.procedureCode = null;
        this.form.procedureName = null;
      }
    },
    buildFilter() {
      const payLoad = {
        uns_id: this.form.unitHealth,
        procedureCode: this.form.procedureCode,
        procedureName: this.form.procedureName,
        uns_nome: this.form.uns_nome,
        mun_nome: this.form.mun_nome,
        initialDate: this.form.initialDate
          ? this.formatDate(this.form.initialDate)
          : null,
        finalDate: this.form.finalDate
          ? this.formatDate(this.form.finalDate)
          : null,
        columnsToPrint: this.columnsToPrint,
      };

      return payLoad;
    },
    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Exam/Report/GET_CEILING_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }

      const daysDateInterval = finalDate.diff(initialDate, "days");

      if (daysDateInterval > this.MAX_DAYS_DATE_INTERVAL) {
        pErrors.push(
          "O periodo entre a data inicial e final não pode ser superior a 2 meses",
        );
        return false;
      }
    },
    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }

      const daysDateInterval = finalDate.diff(initialDate, "days");

      if (daysDateInterval > this.MAX_DAYS_DATE_INTERVAL) {
        pErrors.push(
          "O periodo entre a data inicial e final não pode ser superior a 2 meses",
        );
        return false;
      }
    },
    formatDate(pDate) {
      return this.$utils.date.BrazilianDateToDatabase(pDate);
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);

      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
