<template>
  <div class="procedures">
    <RgSearch
      ref="rgsearch"
      v-model="mutableProcedures"
      :result-title="'Importar Procedimentos DataSUS para Profissionais da Saúde'"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :searchAreaHeight="tableHeight"
      :item-height="32"
      :max-register="30"
      :disabled="disabledByModal"
      @count="getCountValue"
      @submitFromButton="cleanSelectRow"
      @viewClickPagination="cleanSelectRow"
      @afterPerformSearch="afterSearchFilter"
    >
      <div slot="filters" class="procedures-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-health"
              ref="unitHealth"
              v-model="form.unitHealth"
              :rules="{ required: true }"
              :class="{ disable: true }"
              :disabled="true"
              class="inputitem"
              label="Unidade de Saúde"
              permission="faturamento.faturar"
            />
          </div>
          <div class="selectinput">
            <RgSuggestOccupation
              id="cbo"
              ref="cbo"
              v-model="form.cbo"
              :class="{ disable: disabledByModal || !form.unitHealth }"
              :disabled="disabledByModal || !form.unitHealth"
              :rules="{ required: true }"
              :unitHealthId="form.unitHealth"
              :subModuleId="54"
              label="Ocupação"
              class="inputitem"
              @change="selectedOccupation"
            />
          </div>
          <div class="selectinput">
            <RgSuggestEmployee
              ref="employee"
              v-model="form.employee.nameAndCns"
              label="Profissional"
              :disabled="disabledByModal || !form.unitHealth || !form.cbo"
              :class="{
                disable: disabledByModal || !form.unitHealth || !form.cbo,
              }"
              :unitHealthId="form.unitHealth"
              :ocuppationId="Number(form.cbo)"
              class="inputitem"
              @selected="selectedEmployee"
            />
          </div>
          <div class="selectinput">
            <RgSuggestProcedure
              id="procedure"
              ref="Procedure"
              v-model="form.procedure"
              :class="{
                disable: disabledByModal || !form.unitHealth || !form.cbo,
              }"
              :disabled="disabledByModal || !form.unitHealth || !form.cbo"
              :occupationCode="form.ocp_codigo"
              :isBilling="true"
              type="('01', '02')"
              label="Procedimento"
              placeholder="Digite o cód. ou nome do procedimento"
            />
          </div>
        </div>
      </div>

      <div slot="menu-top" class="top-button">
        <RgAddButton
          v-if="!hasMutableProcedures"
          id="new-registration"
          :permission="hasPermissionProcedure"
          large
          label="Novo"
          title="Cadastrar Procedimento"
          :disabled="disabledByModal"
          @click="newProcedure"
        />
      </div>
      <div class="procedures-search-list">
        <SmartTable
          ref="smartTable"
          name="Procedure"
          :columns="COLUMNS"
          :body="mutableProcedures"
          :dynamic-height="getSpaceTable"
          :total="Number(total)"
          :initial-columns="7"
          index-column="frd_id"
          toggle-selected
          :showPagination="false"
          class="smart-table"
          @getLine="selectLine"
        >
          <div slot="top-buttons" class="actions">
            <div class="right-buttons">
              <RgAddButton
                id="cadastrar-procedimento"
                :permission="hasPermissionProcedure"
                small
                class="btns"
                title="Cadastrar Procedimento"
                :disabled="disabledByModal"
                @click="newProcedure"
              />

              <RgEditButton
                id="editar-procedimento"
                :permission="hasPermissionProcedure"
                small
                title="Editar Procedimento"
                :disabled="!hasSelectedLine || disabledByModal"
                @click="editProcedure"
              />

              <RgLessButton
                id="excluir-procedimento"
                :permission="hasPermissionProcedure"
                small
                title="Excluir Procedimento"
                :disabled="!hasSelectedLine || disabledByModal"
                @click="openModalDeleteProcedure"
              />
            </div>
          </div>
        </SmartTable>
      </div>
      <div ref="footer" slot="menu-bottom" class="procedures-footer"></div>
    </RgSearch>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalProcedure"
      @reSearch="reSearch"
    />
  </div>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import {
  RgEditButton,
  RgAddButton,
  RgLessButton,
  ModalConfirmDeletion,
  RgSelectUnithealth,
  RgSuggestProcedure,
  RgSuggestEmployee,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgSuggestOccupation from "$billing/submodules/bpa/procedures/components/select/rg-select-occupation/RgSelectOccupation";

const FORM_PROCEDURE = {
  unitHealth: null,
  cbo: null,
  ocp_codigo: null,
  employee: {
    professionalId: null,
    nameAndCns: null,
    professionalName: null,
    vin_id: null,
    fun_crs_numero_ab: null,
  },
  procedure: null,
};

export default {
  name: "SearchProcedures",
  components: {
    RgSearch,
    SmartTable,
    RgSuggestEmployee,
    RgEditButton,
    RgAddButton,
    RgLessButton,
    ModalConfirmDeletion,
    RgSelectUnithealth,
    RgSuggestProcedure,
    RgSuggestOccupation,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_PROCEDURE),
      showModalDeleteProcedure: false,
      mutableProcedures: [],
      editProcedureRow: null,
      total: 0,
      tableHeight: null,
      selectedRow: null,
      bodyText: "",
    };
  },

  computed: {
    getSpaceTable() {
      return this.tableHeight;
    },
    hasMutableProcedures() {
      return !(this.mutableProcedures && this.mutableProcedures.length === 0);
    },
    hasSelectedLine() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },

    hasPermissionProcedure() {
      return !!this.$Permissions.global.has("faturamento.faturar");
    },

    disabledByModal() {
      return this.showModalDeleteProcedure;
    },
    propsModalConfirmDeletion() {
      const title = `Excluir Procedimento Importado`;
      const message = `O procedimento será removido da lista de importações realizadas para o profissional.`;
      const confirm = () => this.deleteProcedure();
      const msgSuccess = "Procedimento excluído com sucesso";
      const show = this.showModalDeleteProcedure;
      const noReason = true;
      const bodyText = `${this.selectedRow?.pes_nome} - ${this.selectedRow?.sus_pa_id_dv} - ${this.selectedRow?.sus_pa_dc}`;
      const hasSearch = true;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
      };
    },
  },

  watch: {
    "form.unitHealth"(pValue, pPrev) {
      if (!pValue && pValue !== pPrev) {
        this.form.cbo = null;
        this.form.employee = null;
        this.form.procedure = null;
      }
    },
    "form.cbo"(pValue, pPrev) {
      if (!pValue && pValue !== pPrev) {
        this.form.employee.nameAndCns = null;
      }
    },
  },

  mounted() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/billing/bpa/procedures",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Billing/Procedure/RESET_FILTER_BILLING_PROCEDURE");
    }
  },

  created() {
    this.COLUMNS = [
      { name: "CNES", key: "uns_cnes" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
      { name: "Importado para", key: "pes_nome", align: "left" },
      { name: "Ocupação", key: "ocp_nome", align: "left" },
      { name: "Setor", key: "set_nome", align: "left" },
      {
        name: "Código",
        key: "sus_pa_id_dv",
      },
      {
        name: "Procedimento",
        key: "sus_pa_dc",
        align: "left",
      },
      { name: "Custo Real", key: "sus_pa_total", align: "right" },
    ];
  },

  methods: {
    selectedOccupation(pValue) {
      if (pValue) {
        this.form.ocp_codigo = pValue.codigo;
      } else {
        this.form.ocp_codigo = null;
      }
    },

    selectedEmployee(pValue) {
      if (pValue.source) {
        this.form.employee.professionalName = pValue.source.pes_nome;
        this.form.employee.professionalId = pValue.source.fun_id;
        this.form.employee.vin_id = pValue.source.vin_id;
      } else {
        this.form.employee.professionalName = null;
        this.form.employee.professionalId = null;
        this.form.employee.vin_id = null;
      }
    },

    afterSearchFilter() {
      this.getDynamicHeight();
      this.cleanSelectRow();
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgsearch?.$refs.listContent) {
          const footerSlot = 45;
          const searchArea = this.$refs.rgsearch.$refs.listContent.offsetHeight;

          this.tableHeight = searchArea - footerSlot;
        }
      });
    },

    selectLine(pValue) {
      if (pValue) {
        this.selectedRow = pValue;
        this.$store.commit(
          "Billing/Procedure/SELECT_REGISTER_BILLING_PROCEDURE",
          pValue,
        );
      } else {
        this.selectedRow = null;
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          uns_id: this.form.unitHealth,
          vin_id: this.form.employee.vin_id,
          ocp_id: this.form.cbo,
          codigo: this.form.procedure,
          blnOnlyEmployee: true,
        },
      };

      this.$store.commit(
        "Billing/Procedure/SET_FILTER_BILLING_PROCEDURE",
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },
    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Billing/Procedure/GET_FILTER_BILLING_PROCEDURE"
        ];

        if (existFilterData) {
          this.$loader.start();
          this.form.cbo = existFilterData.cbo;
          this.form.procedure = existFilterData.procedure;
          this.form.unitHealth = existFilterData.unitHealth;
          this.form.vin_id = existFilterData.vin_id;
          this.$refs.employee.forceSelection({
            pes_nome: existFilterData.employee.professionalName,
            vin_id: existFilterData.employee.vin_id,
            fun_id: existFilterData.employee.professionalId,
          });

          await this.$refs.rgsearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning(Err, "Erro ao carregar os dados da navegação");
        this.$loader.finish();
      }
    },

    async searchFilter(pData) {
      try {
        this.$loader.start("Buscando...");
        return await this.$store.dispatch(
          "Billing/Procedure/SEARCH_PROCEDURE",
          pData,
        );
      } catch (e) {
        this.$toaster.warning(e);
      } finally {
        this.$loader.finish();
      }
    },

    async reSearch() {
      this.cleanSelectRow();
      if (this.$refs.rgsearch)
        await this.$refs.rgsearch.submitForm(false, true);
    },

    cleanSelectRow() {
      this.selectedRow = null;
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
    },

    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM_PROCEDURE);
      this.mutableProcedures = [];
      this.selectedRow = null;
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    editProcedure() {
      this.$router.push({ name: "billing.bpa.procedures.edit" });
    },
    newProcedure() {
      this.$router.push({
        name: "billing.bpa.procedures.register",
      });
    },
    closeNewProcedure() {
      this.editProcedureRow = null;
    },

    openModalDeleteProcedure() {
      this.showModalDeleteProcedure = true;
    },
    async deleteProcedure() {
      try {
        this.$loader.start();
        const variables = {
          intIdTabelaUnificadaMunicipio: this.selectedRow.tum_id,
        };
        await this.$store.dispatch(
          "Billing/Procedure/DELETE_PHARMACY_PROCEDURE",
          variables,
        );

        this.$toaster.success("Procedimento excluído com sucesso");
      } catch (error) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o Procedimento",
        );
      } finally {
        this.$loader.finish();
      }
    },
    closeModalProcedure() {
      this.showModalDeleteProcedure = false;
    },
  },
};
</script>
