<template lang="html">
  <div v-if="show" class="modal-conflict-professional-scales">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Não foi possível cadastrar a escala</div>
      </div>

      <div slot="body" class="body">
        <div class="info-area">
          <span class="info">Outras escalas possuem dados semelhantes</span>
        </div>

        <FormBase title="Conflitos" class="list-area">
          <SmartTable
            ref="smartTable"
            name="ModalConflictProfessionalScale"
            :columns="COLUMNS"
            :body="list"
            :total="listTotal"
            :colorLine="actionColorLine"
            :initial-columns="8"
            :show-pagination="false"
            circle-indicator
            remove-btn-columns
            dont-select
            class="conflict-list"
          >
            <div slot="legends" class="legends">
              <div class="circle -green" />
              <span class="legend">Na Validade</span>
              <div class="circle -red" />
              <span class="legend">Fora da Validade</span>
              <div class="circle -blue" />
              <span class="legend">Em Breve</span>
            </div>
          </SmartTable>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <MediumButton
          id="retomar-cadastro"
          title="Retomar cadastro"
          label="Retomar o cadastro"
          @click="close"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import moment from "moment";

import { SmartTable, FormBase } from "~tokio/foundation";
import { RgBaseModal, MediumButton } from "~tokio/primitive";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";

export default {
  name: "ModalConflictProfessionalScales",
  components: {
    RgBaseModal,
    SmartTable,
    FormBase,
    MediumButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    conflictList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      list: [],
    };
  },

  computed: {
    listTotal() {
      const total = this.list.length || 0;
      return total;
    },

    actionColorLine() {
      return {
        get: (item) => {
          const { eag_data_inicio_escala, eag_data_fim_escala } = item;
          const now = moment(new Date()).format("YYYY-MM-DD");

          const initial = moment(eag_data_inicio_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          const final = moment(eag_data_fim_escala, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          );

          if (
            eag_data_inicio_escala !== "N/A" &&
            moment(now).isBefore(initial)
          ) {
            // escalas q data inicio > atual (EM BREVE)
            return GetColorRowSmartTable("blue");
          } else if (
            eag_data_fim_escala !== "N/A" &&
            moment(now).isAfter(final)
          ) {
            // escalas q data final < atual (VENCIDAS)
            return GetColorRowSmartTable("red");
          }
          // não entrando nestas regras significa que está na validade
          return GetColorRowSmartTable("green");
        },
      };
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    conflictList(pValue) {
      if (pValue) {
        this.prepareList();
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Conflito", key: "conflict_text" },
      { name: "Tipo", key: "cte_nome" },
      { name: "Unidade de Saúde", key: "uns_nome" },
      { name: "Setor", key: "set_nome" },
      { name: "Dia", key: "dsm_nome" },
      { name: "Horário de Início", key: "eag_inicio_atendimentos" },
      { name: "Horário de Término", key: "eag_final_atendimentos" },
      { name: "Validade", key: "validade" },
    ];
  },

  mounted() {
    this.prepareList();
  },

  methods: {
    prepareList() {
      this.list = this.conflictList.map((item) => {
        return {
          ...item,
          validade: `${item.eag_data_inicio_escala} - ${item.eag_data_fim_escala}`,
          cte_nome: item.cte_nome.toUpperCase(),
          conflict_text: item.hag_data_consulta || "Escala",
          eag_inicio_atendimentos:
            item.hag_hora_inicio_consulta_funcionario ||
            item.eag_inicio_atendimentos,
          eag_final_atendimentos:
            item.hag_hora_fim_consulta_funcionario ||
            item.eag_final_atendimentos,
        };
      });
    },

    getValue(pItem, pKey) {
      return pItem[pKey] || "Não informado";
    },

    close() {
      this.list = [];
      this.$emit("close");
    },
  },
};
</script>
