import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacySatelliteConsumption from "$pharmacy/submodules/satellite/view/consumption/PharmacySatelliteConsumption";

export default {
  path: "/pharmacy/satellite/consumption",
  name: "pharmacy.satellite.consumption",
  components: {
    default: PharmacySatelliteConsumption,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Satélite",
        link: "/pharmacy/satellite",
      },
      {
        label: "Consumo",
      },
    ],
  },
};
