<template>
  <div v-if="show" class="modal-patient-new-record-found-basic-attention">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div
        slot="header"
        class="modal-patient-new-record-found-basic-attention-header"
      >
        <div class="title">
          Nova Ficha encontrada no cadastro de Atenção Básica
        </div>
      </div>

      <div
        slot="body"
        class="modal-patient-new-record-found-basic-attention-body"
      >
        <div class="content">
          <span class="subtitle-modal">
            Ao atualizar a situação do vínculo constatou-se a
            <strong>disponibilidade de uma nova ficha AB</strong>. Compare os
            dados e, <strong> se necessário, vincule a nova ficha</strong> ao
            cadastro do paciente:
          </span>

          <Tooltip
            message="Para salvar o agendamento, é obrigatório vincular o paciente à uma
              Ficha AB"
            start-open
            class="tooltip"
          >
            <IconExclamationHelper slot="icon" hollow />
          </Tooltip>

          <div class="register">
            <div
              v-for="(item, index) in individualRegistrationList"
              :key="item.index"
              :class="{ selected: selectedPatient === index }"
              class="registers-list"
              @click="getPatient(item, index)"
            >
              <div class="register">
                <div>
                  <IconCheck v-show="selectedPatient === index" class="check" />
                </div>

                <PatientType :sex="patientSex(item)" class="patient-type" />

                <div class="column">
                  <span class="ellipsis" :title="item.pab_nome">
                    Nome: {{ item.pab_nome }}
                  </span>
                  <span class="ellipsis" :title="item.pab_nome_mae">
                    Nome da Mãe: {{ item.pab_nome_mae }}
                  </span>
                </div>

                <div class="column">
                  <span>
                    Data de Nascimento:
                    {{ formatDate(item.pab_nascimento) }}
                  </span>
                  <span> CPF: {{ formatCpf(item.pab_cpf) }} </span>
                </div>

                <div class="column">
                  <section class="tooltip">
                    <Tooltip
                      v-if="hasGreaterDate(item)"
                      show-left
                      :show-up="toShowUp(index)"
                    >
                      <IconNewHelper slot="icon" />
                      <div slot="content" class="content">
                        <div class="row">
                          <span class="header-tooltip">
                            <strong>
                              Nova Ficha de Cadastro AB disponível:
                            </strong>
                          </span>
                        </div>
                        <div class="row">
                          <span>
                            <strong>Data do Atendimento</strong>:
                            {{ formatDate(item.pab_data_atendimento) }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.unidade_nome" class="ellipsis">
                            <strong> Unidade: </strong>{{ item.unidade_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.area_nome" class="ellipsis">
                            <strong> Área: </strong>{{ item.area_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.microarea_nome" class="ellipsis">
                            <strong> Microárea: </strong>
                            {{ item.microarea_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.equipe_nome" class="ellipsis">
                            <strong> Equipe: </strong>{{ item.equipe_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span
                            :title="item.pessoa_funcionario_nome"
                            class="ellipsis"
                          >
                            <strong> Responsável: </strong>
                            {{ item.pessoa_funcionario_nome }}
                          </span>
                        </div>
                      </div>
                    </Tooltip>

                    <Tooltip show-left :show-up="toShowUp(index)">
                      <IconInfoHelper
                        v-if="!hasGreaterDate(item, index)"
                        slot="icon"
                      />
                      <div slot="content" class="content">
                        <div class="row">
                          <span class="header-tooltip">
                            <strong>Cabeçalho da Atenção Básica-AB:</strong>
                          </span>
                        </div>
                        <div class="row">
                          <span>
                            <strong>Data do Atendimento</strong>:
                            {{ formatDate(item.pab_data_atendimento) }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.unidade_nome" class="ellipsis">
                            <strong> Unidade: </strong>{{ item.unidade_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.area_nome" class="ellipsis">
                            <strong> Área: </strong>{{ item.area_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.microarea_nome" class="ellipsis">
                            <strong> Microárea: </strong>
                            {{ item.microarea_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span :title="item.equipe_nome" class="ellipsis">
                            <strong> Equipe: </strong>{{ item.equipe_nome }}
                          </span>
                        </div>
                        <div class="row">
                          <span
                            :title="item.pessoa_funcionario_nome"
                            class="ellipsis"
                          >
                            <strong> ACS: </strong
                            >{{ item.pessoa_funcionario_nome }}
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                  </section>
                  <span class="cns">
                    CNS: {{ formatCns(item.pab_cartao_sus) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        slot="footer"
        class="modal-patient-new-record-found-basic-attention-footer"
      >
        <RgCancelButton id="cancel" medium @click="cancel" />
        <MediumButton
          id="save"
          label="Atualizar Vínculo"
          :disabled="!selectedPatientData"
          @click="change"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  IconCheck,
  Tooltip,
  IconNewHelper,
  IconInfoHelper,
  IconExclamationHelper,
  MediumButton,
  RgCancelButton,
} from "~tokio/primitive";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";
import PatientType from "$person/patient/component/icon/PatientType";
import moment from "moment";
import { ToShowUp } from "~tokio/primitive/tooltip/util/ToShowUp.js";

export default {
  name: "ModalPatientNewRecordFoundBasicAttention",
  components: {
    RgBaseModal,
    IconCheck,
    PatientType,
    Tooltip,
    IconNewHelper,
    IconInfoHelper,
    IconExclamationHelper,
    MediumButton,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
    },
    patientHasNoBondOrInactiveBond: {
      type: Boolean,
      default: false,
    },
    patientBasicAttentionId: {
      type: Number,
    },
    currentIndividualRegistrationDate: {
      type: String,
    },
    individualRegistrationList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectedPatient: null,
      selectedPatientData: null,
    };
  },

  computed: {},

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },

  methods: {
    getPatient(pItem, pIndex) {
      const isSameLine = this.selectedPatient === pIndex;
      if (isSameLine) {
        this.selectedPatient = null;
        this.selectedPatientData = null;
      } else {
        this.selectedPatient = pIndex;
        this.selectedPatientData = pItem;
      }
    },

    formatDate(pValue) {
      if (!pValue) return "";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },

    formatCpf(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },

    formatCns(pValue) {
      if (!pValue) return "";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
    },

    patientSex(pItem) {
      const gender = pItem.sexo;
      const masculine = gender === "MASCULINO" ? 1 : null;
      const feminine = gender === "FEMININO" ? 2 : null;
      return masculine || feminine || null;
    },

    async change() {
      const pVerifyOriginalRegistrationId =
        !this.patientBasicAttentionId || this.patientHasNoBondOrInactiveBond;

      const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
        this.selectedPatientData,
        this.patientBasicAttentionId,
        this.patientId,
        pVerifyOriginalRegistrationId,
      );

      try {
        this.$loader.start();

        await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
          abEsus: patientAbEsus,
        });
        this.$toaster.success("Operação realizada com sucesso");
        this.$emit("change");
      } catch (err) {
        this.$toaster.error("Erro ao vincular", err);
      } finally {
        this.$loader.finish();
      }
    },

    hasGreaterDate(pItem) {
      if (
        pItem?.pab_data_atendimento &&
        this.currentIndividualRegistrationDate
      ) {
        const individualRegistrationDate = moment(pItem.pab_data_atendimento);
        const patientAbEsusDate = moment(
          this.currentIndividualRegistrationDate,
        );
        return individualRegistrationDate.isAfter(patientAbEsusDate);
      }
      return false;
    },

    toShowUp(pItemIndex) {
      return this.individualRegistrationList?.length === 1
        ? true
        : ToShowUp(pItemIndex, this.individualRegistrationList);
    },

    cancel() {
      this.close();
    },

    close() {
      this.clear();
      this.$emit("close");
    },

    clear() {
      this.selectedPatient = null;
      this.selectedPatientData = null;
    },
  },
};
</script>
