<template>
  <Modulebox class="col-12" title="Informações Sobre as Filas">
    <ExportQueueInfoForm @update-status-export-list="searchList" />
    <StatusExportList :list="list" @update-status-export-list="searchList" />
  </Modulebox>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ExportQueueInfoForm from "$queue/common/components/export-queue-info-form/ExportQueueInfoForm";
import StatusExportList from "$queue/common/components/status-export-list/StatusExportList";

import SearchStatusExport from "$queue/store/actions/SearchStatusExport";

export default {
  name: "ExportQueueInfo",
  components: {
    Modulebox,
    StatusExportList,
    ExportQueueInfoForm,
  },

  data() {
    return {
      list: [],
    };
  },

  async mounted() {
    await this.searchList();
  },

  methods: {
    async searchList() {
      try {
        this.$loader.start("Buscando lista de exportações");

        const { rows } = await SearchStatusExport();

        this.list = this.formatList(rows);
      } catch (pErr) {
        this.$toaster.error("Erro ao obter lista de exportações");
      } finally {
        this.$loader.finish();
      }
    },

    formatList(pList) {
      return pList.map((item) => {
        item.ett_criado_em = this.$utils.date.BrazilianDateTimeFormat(
          item.ett_criado_em,
        );

        item.ett_alterado_em = this.$utils.date.BrazilianDateTimeFormat(
          item.ett_alterado_em,
        );

        return item;
      });
    },
  },
};
</script>
