<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchExternalSchedulingProcedures from "./action/SearchExternalSchedulingProcedures";

export default {
  name: "RgSelectExternalSchedulingProcedures",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Procedimento",
    },
  },
  data: () => {
    return {
      procedure: [],
    };
  },
  watch: {
    procedure: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.procedure;
    },
    async doSearch() {
      this.procedure = [];

      const data = await SearchExternalSchedulingProcedures();

      this.procedure = data.map((item) => {
        return {
          value: item.fle_nome_procedimento,
          label: item.label,
        };
      });
    },
  },
};
</script>
