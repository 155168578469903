import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  NaoPossuiPermissaoException: `Você não possui permissão`,
  ExameEscalaAgendamentoException: `Foi informado um tipo de escala inválido`,
  ExameConflitoEscalaDePeriodoHorarioException: `Escalas conflitantes`,
  ExamePeriodoInvalidoException: `Existem exames agendados para uma data posterior à validade final informada`,
  ExamePeriodoInvalidoHistoricoException: `Existem inabilitações para o período inferior ao informado na validade inicial`,
  ExamePossuiProcedimentosAgendadosException: `Existem exames agendados para esta escala`,
};

export default async (context, variable) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/alterar",
    variable,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
