<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import getData from "./action";
export default {
  name: "RgComboboxTransferOriginEmployee",
  mixins: [RgComboboxMixin],
  props: {
    intIdUnidadeSaude: {
      required: true,
    },
    intIdSetor: {
      required: true,
    },
    strData: {
      required: true,
    },
  },
  watch: {
    intIdUnidadeSaude() {
      this.fillData();
    },
    intIdSetor() {
      this.fillData();
    },
    strData() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (!this.intIdUnidadeSaude || !this.intIdSetor || !this.strData) {
        return Promise.resolve([]);
      }

      this.disableLoading = true;
      this.$emit("loading", this.disableLoading);

      this.mData = [];

      const data = await getData({
        arrFiltro: {
          blnHagAtendimento: true,
          intIdUnidadeSaude: this.intIdUnidadeSaude,
          intIdSetor: this.intIdSetor,
          strData: this.strData,
        },
      });

      if (data.length === 0) {
        this.$toaster.warning("Não há escalas disponiveis");
        return;
      }

      data.forEach((item) => {
        this.mData.push({
          item: item,
          value: item.eag_id,
          text: `${item.pes_nome} (${item.ocp_nome}) - ${item.eag_inicio_atendimentos}`,
        });
      });
      this.disableLoading = false;
      this.$emit("loading", this.disableLoading);
    },

    getMData() {
      return this.mData;
    },
  },
};
</script>
