import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacyMovements from "$pharmacy/submodules/register/view/movements/PharmacyMovements.vue";

export default {
  path: "/pharmacy/register/movement",
  name: "pharmacy.register.movement",
  components: {
    default: PharmacyMovements,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Cadastro",
        link: OpenSubModule("pharmacy"),
      },
      {
        label: "Tipos de Movimentação",
      },
    ],
  },
};
