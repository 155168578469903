<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";

export default {
  name: "RgComboboxPeriodScale",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Tipo de Horário",
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      this.mData = [
        { value: 1, text: "POR ESCALA" },
        { value: 2, text: "POR INTERVALO" },
      ];
    },
  },
};
</script>
