import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PatientRegistrationSettings from "@app/configuration/views/patient-registration-settings/PatientRegistrationSettings";

export default {
  path: "/configuration/patient-registration-settings",
  name: "configuration.patient-registration-settings",
  components: {
    default: PatientRegistrationSettings,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [{ label: "Configurações", link: "/" }, { label: "Cadastro" }],
  },
};
