<style src="./RgInputAge.scss" lang="scss" scoped></style>
<template>
  <fieldset class="rg-input-age">
    <RgFormBase :label="'Idade'" :optional="optional" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input-base">
        <button
          id="decrease"
          class="decrease"
          :disabled="disabled"
          @click.stop.prevent="minDecreaseLimit"
        >
          -
        </button>
        <input
          :id="id"
          v-model="minInputValue"
          :disabled="disabled"
          :data-id="dataId"
          :class="{ 'on-error': hasError }"
          :placeholder="placeholder"
          :tabindex="tabIndex"
          :maxlength="maxlength"
          :min="min"
          :max="max"
          type="number"
          class="input-box"
          @blur="validateBlur"
        />
        <button
          id="increase"
          class="increase"
          :disabled="disabled"
          @click.stop.prevent="minIncreaseLimit"
        >
          +
        </button>
        <span class="filter-age">até</span>
        <button
          id="decrease"
          class="decrease"
          :disabled="disabled"
          @click.stop.prevent="maxDecreaseLimit"
        >
          -
        </button>
        <input
          :id="id"
          v-model="maxInputValue"
          :disabled="disabled"
          :data-id="dataId"
          :class="{ 'on-error': hasError }"
          :placeholder="placeholder"
          :tabindex="tabIndex"
          :maxlength="maxlength"
          :min="min"
          :max="max"
          type="number"
          class="input-box"
          @blur="validateBlur"
        />
        <button
          id="increase"
          class="increase"
          :disabled="disabled"
          @click.stop.prevent="maxIncreaseLimit"
        >
          +
        </button>
      </div>
    </RgFormBase>
  </fieldset>
</template>
<script>
import { RgFormBase } from "~tokio/foundation/container";
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";

export default {
  name: "RgInputQuantity",
  components: {
    RgFormBase,
    RgValidationAlert,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    max: {
      type: Number,
      default: 120,
    },
    min: {
      type: Number,
      default: 0,
    },
    maxlength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      minInputValue: 0,
      maxInputValue: 0,
      anotherRules: {
        validateMinValue(pValue, pErrors) {
          if (Number(this.minInputValue) > 120) {
            pErrors.push("A idade mínima deve ser menor ou igual a 120");
            return false;
          }
          if (Number(this.minInputValue) > Number(this.maxInputValue)) {
            pErrors.push(
              "A idade mínima deve ser menor ou igual à idade máxima",
            );
            return false;
          }
          return true;
        },
        validateMaxValue(pValue, pErrors) {
          if (Number(this.maxInputValue) > 120) {
            pErrors.push("A idade máxima deve ser menor ou igual a 120");
            return false;
          }
          return true;
        },
      },
    };
  },

  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },

    hasError() {
      return this.error.length > 0;
    },
  },

  watch: {
    minInputValue(pValue) {
      if (Number(pValue) > 120) {
        this.error.push("A idade mínima deve ser menor ou igual a 120");
      }
      if (Number(pValue) > Number(this.maxInputValue)) {
        this.error.push(
          "A idade mínima deve ser menor ou igual à idade máxima",
        );
      }
      if (!pValue) {
        this.minInputValue = 0;
      }
      if (pValue) {
        this.minInputValue = Number(pValue.toString().replace(/^0+/, ""));
      } else {
        this.error = [];
      }
      this.$emit("input", {
        maxInputValue: this.maxInputValue,
        minInputValue: this.minInputValue,
      });
    },
    maxInputValue(pValue) {
      if (Number(pValue) > 120) {
        this.error.push("A idade máxima deve ser menor ou igual a 120");
      }
      if (!pValue) {
        this.maxInputValue = 0;
      }
      if (pValue) {
        this.maxInputValue = Number(pValue.toString().replace(/^0+/, ""));
      } else {
        this.error = [];
      }
      this.$emit("input", {
        maxInputValue: this.maxInputValue,
        minInputValue: this.minInputValue,
      });
    },
  },

  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },

    maxIncreaseLimit() {
      const value = Number(this.maxInputValue);
      const newValue = Number(value) + 1;
      this.maxInputValue = Number(newValue);
    },

    maxDecreaseLimit() {
      if (this.maxInputValue > 0) {
        const value = Number(this.maxInputValue);
        const newValue = Number(value) - 1;
        this.maxInputValue = Number(newValue);
      }
    },
    minIncreaseLimit() {
      const value = Number(this.minInputValue);
      const newValue = Number(value) + 1;
      this.minInputValue = Number(newValue);
    },

    minDecreaseLimit() {
      if (this.minInputValue > 0) {
        const value = Number(this.minInputValue);
        const newValue = Number(value) - 1;
        this.minInputValue = Number(newValue);
      }
    },

    cleanValues() {
      this.minInputValue = 0;
      this.maxInputValue = 0;
    },
  },
};
</script>
