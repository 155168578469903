import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetAbIndividualRegistration(
    $baseCardId: Int
    $cpf: String
    $cns: String
  ) {
    GetAbIndividualRegistration(
      pab_id: $baseCardId
      pab_cpf: $cpf
      pab_cartao_sus: $cns
    ) {
      rows {
        pab_id
        pab_id_payload
        pab_nome
        pab_nome_mae
        pab_nascimento
        pab_cpf
        pab_cartao_sus
        pab_data_atendimento
        pab_ativo
        pab_id_ab_motivo_desabilitacao
        sexo
        unidade_id
        unidade_nome
        equipe_id
        equipe_nome
        area_id
        area_nome
        funcionario_id
        pessoa_funcionario_nome
        ocupacao_nome
        ocupacao_codigo
        microarea_id
        microarea_nome
        microarea_codigo
        visita_domiciliar {
          unidade_nome
          area_nome
          equipe_nome
          pessoa_funcionario_nome
          ocupacao_nome
          ocupacao_codigo
          microarea_nome
          data_atendimento
          equipe {
            equipe_id
            equipe_nome
            funcionario_nome
            ocupacao_nome
            ocupacao_codigo
          }
        }
        equipe {
          equipe_id
          equipe_nome
          funcionario_nome
          ocupacao_nome
          ocupacao_codigo
        }
        pab_nome_social
        pab_nome_pai
        raca_cor
        etnia_indigina
        pab_telefone_residencial
        pab_celular
        pab_email
        endereco_domicilio
        municipio_nascimento
        pais_nascimento
        pab_data_naturalizacao
        pab_portaria_naturalizacao
        pab_data_entrada_brasil
        motivo_saida_cadastro
        scc_numero_obito
        scc_data_obito
        ocupacao_paciente
        ocupacao_codigo_paciente
        situacao_saude
        codigo_ine_cabecalho_ficha
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.GetAbIndividualRegistration;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
