import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query City(
    $munNome: String
    $munId: Int
    $mun_id_estados: Int
    $limit: Int
    $mun_ativo: Boolean
    $mun_codigo: Int
  ) {
    cities(
      mun_nome: $munNome
      mun_id: $munId
      mun_id_estados: $mun_id_estados
      mun_ativo: $mun_ativo
      mun_codigo: $mun_codigo
      limit: $limit
    ) {
      count
      rows {
        mun_id
        mun_nome
        mun_codigo
        state {
          est_id
          est_sigla
          est_nome
        }
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.cities;
};
