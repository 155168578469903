<style src="./RgSuggestPatient.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient">
    <RgSuggest
      ref="innerSuggest"
      v-model="inputValue"
      :disabled="disabled"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataItem"
        :idx="idx"
        :item="item"
        class="patient-item"
      >
        <div class="patient">
          <div class="name">
            <IconUser class="icon" />
            <span>
              Nome: <strong> {{ item.pes_nome }} </strong>
            </span>
          </div>
          <div class="birthday">
            <IconCalendar class="icon" />
            <span>
              Data de Nascimento: {{ formatDate(item.pes_nascimento) }}
            </span>
          </div>
          <div class="mother">
            <IconGenderFemale class="icon" />
            <span> Nome da Mãe: {{ item.pes_mae }} </span>
          </div>
          <div class="cns">
            <IconCard class="icon" />
            <span>
              CNS: {{ formatCns(item.cns.crs_numero) || "Não informado" }}
            </span>
          </div>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import {
  IconUser,
  IconCalendar,
  IconGenderFemale,
  IconCard,
} from "~tokio/primitive/icon";
import { SearchPatient } from "./action";

export default {
  name: "RgSuggestPatient",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconUser,
    IconCalendar,
    IconGenderFemale,
    IconCard,
  },
  mixins: [RgSuggestMixin],
  props: {
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enabledPatient: {
      type: Boolean,
      default: false,
    },
    filterByCompetence: Boolean,
    filterByCnsOrName: Boolean,
  },
  data() {
    return {
      captionRaw: "pes_nome",
    };
  },
  mounted() {
    if (this.filterByCnsOrName) {
      this.captionRaw = "cns.crs_numero - pes_nome";
    }
  },
  methods: {
    async doSearch(pSearchTerm, pLimit = null) {
      if (pSearchTerm !== "") {
        const variables = { peopleName: pSearchTerm };

        variables.notUnified = true;

        if (this.filterByCompetence) {
          variables.filterByCompetence = this.filterByCompetence;
          variables.currentDateCompetence = this.currentCompetence();
        }

        if (this.filterByCnsOrName) {
          const cns = pSearchTerm.replace(/[-.\s]/g, "");
          const isCns = /^\d+$/.test(cns);

          if (isCns) {
            variables.peopleName = null;
            variables.crs_numero = cns;
          } else {
            variables.peopleName = pSearchTerm;
          }
        }

        if (this.enabledPatient) variables.isSearchable = this.enabledPatient;

        variables.limit = pLimit;

        const result = await SearchPatient({ variables });
        this.hasSuggestPatient(result);
        return result;
      }
    },
    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.pes_nome) && pValue.patient.pac_id > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          const data = [];
          data.push({
            pes_id: pValue.pes_id,
            pes_nome: pValue.pes_nome,
            patient: {
              pac_id: pValue.patient.pac_id,
            },
            cns: { crs_numero: pValue.cns.crs_numero || "000000000000000" },
            ...pValue,
          });
          this.suggestionList = data;
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    hasSuggestPatient(pValue) {
      return pValue && pValue.rows && pValue.rows.length > 0;
    },
    formatDate(pValue) {
      if (!pValue) return "Não informado";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },
    formatCns(pValue) {
      return pValue?.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },
    currentCompetence() {
      const competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      const formattedCompetence = this.$utils.date.CompetenceFirstDayToDatabase(
        competence,
      );

      return competence ? formattedCompetence : null;
    },
  },
};
</script>
