import store from "@/store";

const OpenMenuModule = (moduleName) => {
  return () => {
    store.dispatch("MainMenu/OpenModule", { module: moduleName });
  };
};

const OpenSubModule = (moduleName) => {
  return () => {
    const subModuleId = store.getters["MainMenu/GET_SUBMODULE_ID"];
    store.dispatch("MainMenu/OpenModule", { module: moduleName });
    store.dispatch(
      "MainMenu/OpenSubModule",
      subModuleId !== null ? subModuleId : null,
    );
  };
};

export { OpenMenuModule, OpenSubModule };
