<template>
  <Modulebox class="integrations" title="Tokens de Integração">
    <div class="integration-form">
      <RgValidatorForm ref="validator" class="rg-validator">
        <FormBase title="Cadastro" class="register">
          <RgInput
            v-model="form.itg_nome"
            :rules="{ required: true }"
            data-id="nome"
            label="Nome"
            placeholder="Digite o nome"
          />

          <RgSuggestUser
            v-model="username"
            :rules="{ required: true }"
            placeholder="Selecione um Usuário"
            label="Usuário"
            @selected="selectingUser"
          />

          <RgInputDate
            ref="initialValidity"
            v-model="form.itg_validade"
            :rules="{ required: true }"
            label="Validade"
            class="validity"
          />

          <div class="actions">
            <RgCleanButton class="clear" @click="clearForm" />

            <RgAddButton
              medium
              class="buttons"
              type="button"
              @click="createIntegration"
            />
          </div>
        </FormBase>
      </RgValidatorForm>
    </div>

    <FormBase title="Tokens Cadastrados" class="form-base list">
      <SmartTable
        ref="smartTableList"
        name="IntegrationTokenRegister"
        class="integration-table"
        :columns="COLUMNS"
        :body="list"
        :total="totalList"
        :show-pagination="false"
        has-check
        remove-btn-columns
        toggle-selected
        @getLine="getValueRow"
      />

      <div v-show="hasList" class="painel-button">
        <RgLessButton
          title="Excluir"
          large
          :disabled="!hasItemSelected"
          :class="{ 'disable unselect': !hasItemSelected }"
          @click="removeIntegration"
        />

        <MediumButton
          :class="{ 'disable unselect': !hasItemSelected }"
          :disabled="!hasItemSelected"
          title="Copiar Token"
          label="Copiar Token"
          class="button"
          @click="copyToken"
        />
      </div>
    </FormBase>
  </Modulebox>
</template>

<script>
import moment from "moment";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import FormBase from "~tokio/foundation/form-base/FormBase";

import {
  RgInput,
  RgValidatorForm,
  RgAddButton,
  RgCleanButton,
  RgInputDate,
  RgSuggestUser,
  RgLessButton,
  MediumButton,
} from "~tokio/primitive";

const FORM = {
  itg_nome: null,
  itg_id_usuarios: null,
  itg_validade: null,
};

export default {
  name: "Integrations",
  components: {
    Modulebox,
    FormBase,
    RgInput,
    RgValidatorForm,
    RgAddButton,
    RgCleanButton,
    RgInputDate,
    RgSuggestUser,
    SmartTable,
    RgLessButton,
    MediumButton,
  },

  data() {
    return {
      list: [],
      selectedItem: null,
      username: "",
      form: this.$utils.obj.DeepCopy(FORM),
    };
  },

  computed: {
    totalList() {
      return this.list.length || 0;
    },

    hasList() {
      return this.list.length > 0;
    },

    hasItemSelected() {
      return this.selectedItem && Object.keys(this.selectedItem).length > 0;
    },
  },

  mounted() {
    this.fetchIntegrations();
    this.setDefaultValidity();
  },

  created() {
    this.COLUMNS = [
      { name: "Nome", key: "itg_nome" },
      { name: "Usuário", key: "usu_nome" },
      { name: "Token", key: "itg_token" },
      { name: "Cadastro", key: "itg_criado_em" },
      { name: "Validade", key: "itg_validade" },
    ];
  },
  methods: {
    async fetchIntegrations() {
      try {
        const integrationList = await this.$store.dispatch(
          "Configuration/SEARCH_INTEGRATIONS",
          { itg_ativo: 1 },
        );

        this.list = this.formatList(integrationList);
      } catch (pErr) {
        this.$toaster.error(pErr);
      }
    },

    async removeIntegration() {
      try {
        const integrationId = this.selectedItem.itg_id;

        await this.$store.dispatch(
          "Configuration/SOFT_DELETETE_INTEGRATIONS",
          integrationId,
        );

        this.fetchIntegrations();
        this.$toaster.success("Removido com sucesso");
      } catch (pErr) {
        console.log(pErr);
        this.$toaster.error("Problemas ao remover");
      }
    },

    async createIntegration() {
      try {
        const variables = {
          itg_nome: this.form.itg_nome,
          itg_id_usuarios: this.form.itg_id_usuarios,
          itg_validade: this.$utils.date.BrazilianDateToDatabase(
            this.form.itg_validade,
          ),
        };

        await this.$store.dispatch(
          "Configuration/CREATE_INTEGRATIONS",
          variables,
        );
        this.fetchIntegrations();
        this.clearForm();
        this.$toaster.success("Salvo com sucesso");
      } catch (pErr) {
        console.log(pErr);
        this.$toaster.error("Problemas ao gravar");
      }
    },

    async selectingUser(pUser) {
      const hasSuggestion = Object.keys(pUser.source).length > 0;
      if (hasSuggestion) {
        this.form.itg_id_usuarios = pUser.source.usu_id;
      }
    },

    formatList(pIntegrations) {
      return pIntegrations.map((integration) => {
        integration.itg_criado_em = this.$utils.date.BrazilianDateFormat(
          integration.itg_criado_em,
        );

        integration.itg_validade = this.$utils.date.BrazilianDateFormat(
          integration.itg_validade,
        );

        integration.usu_nome = integration.user?.usu_nome;

        return integration;
      });
    },

    getValueRow(pItem) {
      const data = this.$utils.obj.DeepCopy(pItem);
      const hasValue = data && data.itg_id > 0;

      this.selectedItem = hasValue ? data : null;
    },

    setDefaultValidity() {
      const DAYS = 365;

      const defaultValidity = moment().add(DAYS, "days").format("DD/MM/YYYY");

      this.form.itg_validade = defaultValidity;
    },

    async copyToken() {
      const token = this.selectedItem.itg_token;

      await this.$utils.navigator.CopyToClipboard(token);
    },

    clearForm() {
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.setDefaultValidity();
    },
  },
};
</script>
