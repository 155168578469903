<template>
  <div class="appointment-user-productivity-report">
    <div class="grid">
      <div class="filter-title">
        <span class="title"> Dados do Relatório Selecionado </span>
      </div>

      <div class="tooltip-groupby">
        <Tooltip class="tooltip" clickable startOpen>
          <IconInfoHelper slot="icon" hollow />
          <div slot="content" class="content">
            <div class="title">
              <span>
                Os resultados desse relatório são agrupados por usuários e
                unidades de saúde:
              </span>
            </div>
            <div class="body">
              <span>
                No campo “Agrupar por” também é possível selecionar outros
                critérios de agrupamento dos resultados.
              </span>
            </div>
          </div>
        </Tooltip>
      </div>

      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-aupr"
          v-model="form.uns_id"
          permission="consulta.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
        <RgSelectSector
          id="set-id-aupr"
          v-model="form.set_id"
          :unit-health="form.uns_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          label="Setor"
          class="inputitem"
          @change="getSector"
        />

        <RgComboboxHealthPlan
          id="health-insurance"
          ref="healthInsurance"
          v-model="form.healthInsurance"
          label="Convênio"
          @change="selectedHealthInsurance"
        />

        <RgSuggestCbo
          id="ocp-nome-aupr"
          ref="Cbo"
          v-model="form.ocp_nome"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          :disabled="!hasUnitHealth || !form.set_id"
          :class="{ disable: !hasUnitHealth || !form.set_id }"
          class="inputitem"
          @selected="selectedCbo"
        />

        <RgSuggestEmployee
          id="employee-request"
          ref="employeeRequest"
          v-model="employeeRequest"
          :maxlength="100"
          :vus-responsavel="false"
          label="Profissional"
          placeholder="Digite o nome do profissional"
          notNecessaryBond
          filterWithoutBond
          @selected="selectingRequestEmployee"
        />
        <RgSelectAppointmentObjective
          id="objective"
          ref="objective"
          v-model="form.con_id_tipos_consultas_unidade"
          :disabled="!form.healthInsurance || !form.uns_id"
          :class="{ disable: !form.healthInsurance || !form.uns_id }"
          :health-unit-id="form.uns_id"
          :health-plans-id="form.healthInsurance"
          :active="1"
          @change="selectedObjective"
        />
        <RgSelectPlacesAppointment
          id="place"
          ref="place"
          v-model="form.con_id_locais_atendimento"
          :disabled="!form.set_id"
          :class="{ disable: !form.set_id }"
          :sector-id="Number(form.set_id)"
          @change="selectedPlaces"
        />

        <RgSuggestUser
          id="suggest-user-aupr"
          ref="user"
          v-model="suggestUser"
          :rules="{ required: true }"
          class="inputitem"
          label="Usuário"
          @selected="selectedUser"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Período do Agendamento </span>
      </div>

      <div class="selectinput period">
        <RgInputDate
          id="initial-date-aupr"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{ fn: validateInitialDate }"
        />

        <RgInputDate
          id="final-date-aupr"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem"
          :rules="{ fn: validateFinalDate }"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Período da Consulta Realizada </span>
      </div>

      <div class="selectinput period">
        <RgInputDate
          id="initial-date-appointment"
          v-model="form.initialDateAppointment"
          label="Data Inicial"
          :rules="{ required: true, fn: validateInitialDateAppointment }"
          class="inputitem"
        />

        <RgInputDate
          id="final-date-appointment"
          v-model="form.finalDateAppointment"
          label="Data Final"
          :rules="{ required: true, fn: validateFinalDateAppointment }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          class="inputitem"
        />
      </div>
      <div class="selectinput">
        <RgTagCustom
          id="groupby"
          v-bind="propsTagCustom"
          @click="openModalGroupBy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgInputDate,
  RgSelectUnithealth,
  RgSelectSector,
  RgSuggestCbo,
  RgSuggestUser,
  RgSuggestEmployee,
  RgTagCustom,
  IconInfoHelper,
  Tooltip,
} from "~tokio/primitive";
import moment from "moment";
import RgComboboxHealthPlan from "$appointment/submodules/register/component/combobox/rg-combobox-health-plan/RgComboboxHealthPlan";
import RgSelectAppointmentObjective from "$appointment/common/component/rg-select-appointment-objective/RgSelectAppointmentObjective";
import RgSelectPlacesAppointment from "$appointment/common/component/rg-select-places-appointment/RgSelectPlacesAppointment";

const FORM_FILTER = {
  uns_id: null,
  uns_nome: null,
  set_id: null,
  set_nome: null,
  ocp_id: null,
  ocp_nome: null,
  usu_id: null,
  usu_nome: null,
  healthInsurance: null,
  healthInsuranceName: null,
  con_id_tipos_consultas_unidade: null,
  con_id_tipos_consultas_unidade_name: null,
  con_id_locais_atendimento: null,
  con_id_locais_atendimento_name: null,
  employeeRequestName: null,
  employeeRequest: null,
  initialDate: null,
  finalDate: null,
  initialDateAppointment: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDateAppointment: moment().format("DD/MM/YYYY"),
};

export default {
  name: "AppointmentUserProductivity",
  components: {
    RgInputDate,
    RgSelectUnithealth,
    RgSelectSector,
    RgSuggestCbo,
    RgSuggestUser,
    RgComboboxHealthPlan,
    RgSelectAppointmentObjective,
    RgSelectPlacesAppointment,
    RgSuggestEmployee,
    RgTagCustom,
    IconInfoHelper,
    Tooltip,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    groupByName: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resultTitle: "Produtividade por Usuário",
      suggestUser: "",
      suggestCbo: "",
      legend: "",
      employeeRequest: null,
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },

  computed: {
    propsTagCustom() {
      let list = [];
      if (this.groupByName) {
        list = [{ title: this.groupByName.title, id: 1 }];
      } else {
        list = [
          {
            title: "Selecione",
            id: 1,
            color: "#fff",
            textColor: "#1E88A9",
            colorBorder: "#1E88A9",
          },
        ];
      }

      const values = [1];
      const label = "Agrupar por:";
      const labelDirection = "column";
      const hasClicker = true;
      return { list, values, label, labelDirection, hasClicker };
    },
    columnTable() {
      return this.COLUMN;
    },
    getLegend() {
      return this.legend;
    },
    getNameSmartTable() {
      return "AppointmentUserProductivityReport";
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; }"
      );
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },
    getMaxDateInitial() {
      return this.form.initialDateAppointment
        ? new Date(
            moment(this.form.initialDateAppointment, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDateAppointment
        ? new Date(
            moment(this.form.initialDateAppointment, "DD/MM/YYYY").format(),
          )
        : null;
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue) {
        this.form.uns_nome = null;
        this.form.set_id = null;
        this.form.ocp_nome = null;
        this.form.con_id_tipos_consultas_unidade = null;
      } else if (pValue[0]) {
        this.form.uns_nome = pValue[0].text;
      }
    },
    "form.set_id"(pValue, pPrev) {
      if (!pValue) {
        this.form.con_id_locais_atendimento = null;
        this.form.ocp_nome = null;
      }
    },
    "form.healthInsurance"(pValue, pPrev) {
      if (!pValue) {
        this.form.con_id_tipos_consultas_unidade = null;
      }
    },

    "form.initialDateAppointment"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDateAppointment = "";
      }

      if (pValue && !this.form.finalDateAppointment) {
        this.form.finalDateAppointment = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Usuário", key: "USUARIO", align: "left" },
      { name: "Porcentagem", key: "PERCENT", align: "right" },
      { name: "Unidade de Saúde", key: "UNS_NOME", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Profissional", key: "PROFISSIONAL", align: "left" },
      { name: "Local de Atendimento", key: "LOCAL_ATENDIMENTO", align: "left" },
      { name: "Convênio", key: "CONVENIO", align: "left" },
      { name: "Objetivo de Consulta", key: "OBJETIVO", align: "left" },
      { name: "Data de Cadastro", key: "DATA_CADASTRO", align: "left" },
      { name: "Data de Agendamento", key: "DATA_CONSULTA", align: "left" },
    ];
  },

  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.form.uns_nome = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },

  methods: {
    openModalGroupBy() {
      this.$emit("openModalGroupBy");
    },
    buildFilter() {
      return {
        ...this.form,
        healthInsurance: Number(this.form.healthInsurance),
        con_id_tipos_consultas_unidade: Number(
          this.form.con_id_tipos_consultas_unidade,
        ),
        con_id_locais_atendimento: Number(this.form.con_id_locais_atendimento),
        initialDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.initialDate,
        ),
        finalDate: this.$utils.date.BrazilianDateToDatabase(
          this.form.finalDate,
        ),
        initialDateAppointment: this.$utils.date.BrazilianDateToDatabase(
          this.form.initialDateAppointment,
        ),
        finalDateAppointment: this.$utils.date.BrazilianDateToDatabase(
          this.form.finalDateAppointment,
        ),
        groupById: this.groupByName?.id,
        groupByName: this.groupByName?.title,
        columnsToPrint: this.columnsToPrint,
      };
    },

    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_USER_PRODUCTIVITY_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    mountLegend() {
      this.legend = this.form.uns_nome;
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
      this.form.ocp_nome = null;
    },

    selectedCbo(pCbo) {
      this.form.ocp_id = pCbo.source?.ocp_id || null;
      this.form.ocp_nome = pCbo.source?.ocp_nome || null;
    },

    selectedUser(pUser) {
      this.form.usu_id = pUser.source?.usu_id || null;
      this.form.usu_nome = pUser.source?.usu_nome || null;
    },
    selectedHealthInsurance(pValue) {
      this.form.healthInsuranceName = pValue ? pValue[0].text : null;
    },
    selectingRequestEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.employeeRequest =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;
        this.form.employeeRequestName = pes_nome;
      } else {
        this.form.employeeRequest = null;
        this.form.employeeRequestName = null;
      }
    },
    selectedObjective(pValue) {
      this.form.con_id_tipos_consultas_unidade_name = pValue?.label;
    },
    selectedPlaces(pValue) {
      this.form.con_id_locais_atendimento_name = pValue?.label;
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    validateInitialDateAppointment(pValue, pErrors) {
      const initialDate = moment(
        this.form.initialDateAppointment,
        "DD/MM/YYYY",
      );
      const finalDate = moment(this.form.finalDateAppointment, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDateAppointment(pValue, pErrors) {
      const initialDate = moment(
        this.form.initialDateAppointment,
        "DD/MM/YYYY",
      );
      const finalDate = moment(this.form.finalDateAppointment, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },
    clearFilter() {
      this.suggestUser = null;
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.employeeRequest = null;
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      this.form.uns_nome = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

      if (this.$refs.Cbo) {
        this.$refs.Cbo.cleanValidate();
      }

      if (this.$refs.user) {
        this.$refs.user.cleanValidate();
      }

      this.$emit("cleanGroupBy", true);
    },
  },
};
</script>
