import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchProcedureEffectived(
    $procedureName: String
    $procedureCode: String
    $limit: Int
    $offset: Int
  ) {
    SearchProcedureEffectived(
      NO_PROCEDIMENTO: $procedureName
      CO_PROCEDIMENTO: $procedureCode
      limit: $limit
      offset: $offset
    ) {
      rows {
        CO_PROCEDIMENTO
        NO_PROCEDIMENTO
        ID_SINONIMOS
      }
      count
    }
  }
`;

export default async ({ variables }) => {
  const result = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  return result ? result.data.SearchProcedureEffectived : [];
};
