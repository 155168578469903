import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import HandleActionError from "@/common/request/error-handlers/HandleActionError";

const query = gql`
  query searchEmergencyAdministrativeCheckOut(
    $uns_id: Int!
    $set_id: Int
    $patientName: String
    $bulletin: Int
    $initialPeriod: String
    $finalPeriod: String!
    $checkoutStatus: Int!
    $limit: Int
    $offset: Int
  ) {
    SearchEmergencyAdministrativeCheckOut(
      uns_id: $uns_id
      set_id: $set_id
      initialPeriod: $initialPeriod
      patientName: $patientName
      bulletin: $bulletin
      finalPeriod: $finalPeriod
      checkoutStatus: $checkoutStatus
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        bol_id
        bol_id_enderecamento_pep
        bol_numero
        bol_responsavel
        bol_data_hora
        bol_id_setores
        bol_observacao
        bol_registro_geral
        pes_id
        pac_id
        pes_nome
        set_id
        set_nome
        lca_nome
        uns_nome
        usu_nome
        saida
        notSentToPep
        situacaoAlta
        enviado
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.SearchEmergencyAdministrativeCheckOut;
  } catch (pError) {
    return Promise.reject(HandleActionError(pError));
  }
};
