import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation UpdatePatient(
    $personId: Int!
    $person: PersonInputType
    $address: AddressInputType
    $documents: DocumentsInputType
    $attachments: [AttachmentsInputType]
    $telephones: [TelephoneInputType]
    $notifications: [NotificationPeopleInputType]
    $patient: PatientInputType
    $personDeficient: [String]
  ) {
    UpdatePerson(
      personId: $personId
      person: $person
      address: $address
      documents: $documents
      attachments: $attachments
      telephones: $telephones
      notifications: $notifications
      patient: $patient
      personDeficient: $personDeficient
    ) {
      rows {
        pes_id
        pes_nome
      }
      success
      message
    }
  }
`;

const UpdatePatient = async (store, personInfo) => {
  const variables = personInfo;

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.UpdatePerson;
  } catch (pErr) {
    const jsonResponse = JSON.parse(JSON.stringify(pErr));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};

export default UpdatePatient;
