import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";
const arrayExceptions = {
  DuplicidadeMunicipioTipoProdutoException: `Já existe registro com essas informações`,
  MunicipioTipoProdutoPossuiProdutoVinculadoException: `Tipos de Produto vinculados a outros registros não podem ser alterados`,
};
export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "farmacia/controller-municipio-tipo-produto/alterar",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
