<style src="./RgPaginationButton.scss" lang="scss" scoped></style>
<template>
  <RgButton
    :data-id="dataId"
    :permission="permission"
    :title="title"
    :disabled="disabled"
    class="rg-pagination-button"
    type="button"
    @click="clicked"
  >
    <div class="rg-pagination-button-small">
      <IconArrowLeftPoint
        v-if="!double"
        :class="{ rotate: rotate, disable: disabled }"
        class="svg"
      />
      <IconArrowDoubleRightPoint
        v-else
        :class="{ rotate: rotate, disable: disabled }"
        class="svg"
      />
    </div>
  </RgButton>
</template>

<script>
import {
  IconArrowLeftPoint,
  IconArrowDoubleRightPoint,
} from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "RgPaginationButton",
  components: {
    IconArrowLeftPoint,
    IconArrowDoubleRightPoint,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    double: {
      type: Boolean,
      default: false,
    },
    rotate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
