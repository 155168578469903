<template lang="html">
  <div class="hospitalization-billing-team">
    <RgContentHospitalizationTeam
      :procedure="procedure"
      :required-documents="requiredDocuments"
      @setProfessionalList="setProfissionalList"
    />

    <div class="buttons">
      <MediumButton
        :disabled="!team.professionalTeamList.length > 0"
        title="Confirmar"
        label="Confirmar"
        backgroundColor="#0bb98c"
        @click="confirm"
      />

      <MediumButton title="Fechar" label="Fechar" @click.stop.prevent="close" />
    </div>
  </div>
</template>

<script>
import RgContentHospitalizationTeam from "$billing/submodules/aih/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import mask from "vue-the-mask";
import { ValidateTeamMembers } from "../../../../service";
import { MediumButton } from "~tokio/primitive";
export default {
  name: "RgContentHospitalizationBillingTeam",
  components: {
    RgContentHospitalizationTeam,
    MediumButton,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      team: {
        type: "team",
        professionalTeamList: [],
      },
    };
  },
  mounted() {},
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.team);
      }
    },

    async validateForm() {
      // if (!this.validateTeamMembers()) return false
      return true;
    },

    setProfissionalList(event) {
      this.team.professionalTeamList = event;
    },

    validateTeamMembers() {
      return ValidateTeamMembers(this.tincture.professionalTeamList);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
