<template>
  <div v-if="show" class="modal-patient-data-regulation-queue-hospitalization">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-patient-data-regulation-queue-header">
        <div class="title">Dados do Paciente na Regulação de Internação</div>
      </div>

      <div slot="body" class="modal-patient-data-regulation-queue-body">
        <FormBase title="Dados do Paciente" class="form-base">
          <section class="patient-data">
            <span>
              Nome:
              <strong>{{ getValue(dataPatientQueue, "pep_nome") }}</strong>
            </span>
            <span>
              Nome da Mãe:
              <strong>{{ getValue(dataPatientQueue, "pep_mae") }}</strong>
            </span>
            <span>
              Sexo:
              <strong>
                {{ formatGender(getValue(dataPatientQueue, "pep_sexo")) }}
              </strong>
            </span>
            <span>
              Data de Nascimento:
              <strong>
                {{ getValue(dataPatientQueue, "pep_nascimento") }}
              </strong>
            </span>
            <span>
              CPF:
              <strong>
                {{ formatCpf(getValue(dataPatientQueue, "cpf_numero")) }}
              </strong>
            </span>
            <span>
              CNS:
              <strong>
                {{ formatCns(getValue(dataPatientQueue, "crs_numero")) }}
              </strong>
            </span>
            <span>
              Prontuário:
              <strong>
                {{ getValue(dataPatientQueue, "ppr_numero") }}
              </strong>
            </span>
            <span>
              Prontuário Único:
              <strong>
                {{ getValue(dataPatientQueue, "pac_prontuario_unico") }}
              </strong>
            </span>
            <span class="contacts">
              Contatos:
              <strong>
                <strong>
                  {{ getValue(dataPatientQueue, "pep_telefone") }}
                </strong>
              </strong>
            </span>
          </section>
          <span></span>
          <div v-if="hasPatientAbEsus" class="sub-title">
            <h1 class="title">Dados da Atenção Básica</h1>
            <hr />
          </div>
          <section v-if="hasPatientAbEsus" class="basic-attention-data">
            <div>
              <Tooltip
                class="tooltip"
                startOpen
                :message="`Atualizada em: ${getValue(
                  dataPatientQueue,
                  'pae_ultima_alteracao',
                )}`"
              >
                <IconInfoHelper slot="icon" />
              </Tooltip>
            </div>
            <span class="telephone-ab">
              Telefone AB:
              <strong>
                {{ getValue(dataPatientQueue, "pae_telefone") }}
              </strong>
            </span>
            <span class="cell-phone-ab">
              Celular AB:
              <strong>
                {{ getValue(dataPatientQueue, "pae_celular") }}
              </strong>
            </span>
            <span class="update-ab">
              <LargeButton
                id="abData"
                :label="'Atualizar Dados AB'"
                :backgroundColor="'#1E88A9'"
                @click="updatePatientAbEsus"
              >
                <IconReload slot="icon" />
              </LargeButton>
            </span>
          </section>

          <div class="sub-title">
            <h1 class="title">Dados da Regulação</h1>
            <hr />
          </div>

          <section class="queue-data">
            <span>
              Prioridade:
              <strong>
                {{ getValue(dataPatientQueue, "fnp_prioridades_fila") }}
              </strong>
            </span>
            <span>
              Pendência:
              <strong> {{ getValue(dataPatientQueue, "pendencia") }} </strong>
            </span>
            <span>
              Profissional Solicitante:
              <strong>
                {{ getValue(dataPatientQueue, "profissionalSolicitante") }}
              </strong>
            </span>
            <span>
              Data da Entrada:
              <strong>
                {{ formatDate(getValue(dataPatientQueue, "fil_data")) }}
              </strong>
            </span>
            <span>
              Hora da Entrada:
              <strong>
                {{ formatHour(getValue(dataPatientQueue, "fil_data")) }}
              </strong>
            </span>
            <span>
              Data do Pedido:
              <strong>
                {{ getValue(dataPatientQueue, "fnp_data_pedido") }}
              </strong>
            </span>
            <span>
              Unidade de Saúde Inserção:
              <strong>
                {{ getValue(dataPatientQueue, "fnp_unidades_saude_fila") }}
              </strong>
            </span>
            <span>
              Unidade de Saúde Solicitante:
              <strong>
                {{
                  getValue(dataPatientQueue, "fnp_unidades_saude_solicitante")
                }}
              </strong>
            </span>
          </section>

          <div class="sub-title">
            <h1 class="title">Internação</h1>
            <hr />
          </div>

          <section class="hospitalization-data">
            <span class="all-row">
              Tipo de Leito:
              <strong>
                {{ getValue(dataPatientQueue, "itl_nome") }}
              </strong>
            </span>
            <span>
              Procedimento Principal:
              <strong>
                {{ getValue(dataPatientQueue, "proc1") }}
              </strong>
            </span>

            <span>
              CID Principal:
              <strong>
                {{ getValue(dataPatientQueue, "cid1") }}
              </strong>
            </span>
            <span>
              Procedimento Secundário:
              <strong>
                {{ getValue(dataPatientQueue, "proc2") }}
              </strong>
            </span>

            <span>
              CID Secundário:
              <strong>
                {{ getValue(dataPatientQueue, "cid2") }}
              </strong>
            </span>
            <span>
              Procedimento Terciário:
              <strong>
                {{ getValue(dataPatientQueue, "proc3") }}
              </strong>
            </span>

            <span>
              CID Terciário:
              <strong>
                {{ getValue(dataPatientQueue, "cid3") }}
              </strong>
            </span>
          </section>
        </FormBase>
      </div>
      <div
        v-if="this.dataPatientQueue"
        slot="footer"
        class="modal-footer-patient-data-regulation-queue"
      >
        <RgTagCustom id="situation" label="Situação:" v-bind="propsTagCustom" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgBaseModal,
  Tooltip,
  IconInfoHelper,
  LargeButton,
  IconReload,
  RgTagCustom,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SearchPatientDataRegulationQueue from "./action/SearchPatientDataRegulationQueue";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";

export default {
  name: "ModalPatientDataRegulationQueue",
  components: {
    RgBaseModal,
    FormBase,
    Tooltip,
    IconInfoHelper,
    LargeButton,
    IconReload,
    RgTagCustom,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataPatientQueue: null,
      situation: [],
    };
  },
  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    hasPatientAbEsus() {
      return Number(this.dataPatientQueue?.pae_ativo);
    },
    propsTagCustom() {
      const titleStatus =
        this.dataPatientQueue?.pac_bloqueado === "1" ? "INATIVO" : "ATIVO";
      const titleInactive = this.dataPatientQueue?.mip_nome;

      const list = [
        { title: titleStatus, id: 1 },
        {
          title: titleInactive,
          id: 2,
          color: "#F96B70",
          colorBorder: "#F96B70",
        },
      ];
      const values = titleInactive ? [1, 2] : [1];

      return { list, values };
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.getPatientDataRegulationQueue();
      } else {
        this.close();
      }
    },
  },
  methods: {
    getValue(pItem, pKey) {
      const value = pItem && pItem[pKey] ? pItem[pKey] : "Não informado";
      return value;
    },

    async getPatientDataRegulationQueue() {
      try {
        this.$loader.start();
        const fltId = Number(this.patientInfo.flt_id);

        const variables = {
          intIdFila: fltId,
          intIdUnidade: this.unitHealthId,
        };

        const data = await SearchPatientDataRegulationQueue(variables);
        this.dataPatientQueue = data[0];
      } catch (pErr) {
        this.$toaster.error("Erro a carregar informações da modal");
      } finally {
        this.$loader.finish();
      }
    },

    async updatePatientAbEsus() {
      try {
        this.$loader.start();

        const patientAbEsusId = this.dataPatientQueue.pae_id;
        const patientId = this.dataPatientQueue.pae_id_pacientes;
        const baseCardId = this.dataPatientQueue.pae_ficha_base
          ? Number(this.dataPatientQueue.pae_ficha_base)
          : null;

        const patientIndividualRegistration = await this.getIndividualRegistration();

        const hasNoRecord = patientIndividualRegistration.length === 0;

        if (hasNoRecord) {
          this.$toaster.warning(
            "O cadastro do paciente não possui mais vínculo com a ficha de Atenção Básica",
          );

          this.$emit("inactivate");
          return;
        }

        const hasOneRecord = patientIndividualRegistration.length === 1;

        const individualRegistrationWithSameId = patientIndividualRegistration.find(
          (x) =>
            Number(x.pab_id) === baseCardId ||
            Number(x.pab_id_payload) === baseCardId,
        );

        if (hasOneRecord && individualRegistrationWithSameId) {
          const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
            individualRegistrationWithSameId,
            patientAbEsusId,
            patientId,
          );

          await this.updatePatientBondBasicAttention(patientAbEsus);
          await this.getPatientDataRegulationQueue();

          this.$emit("update");

          this.$toaster.success("Operação realizada com sucesso");
        }
      } catch {
        this.$toaster.warning("Não foi possível atualizar os dados");
      } finally {
        this.$loader.finish();
      }
    },

    async getIndividualRegistration() {
      const baseCardId = this.dataPatientQueue.pae_ficha_base
        ? Number(this.dataPatientQueue.pae_ficha_base)
        : null;
      const patientCpf = this.dataPatientQueue.pae_cpf || null;
      const patientCns = this.dataPatientQueue.pae_cartao_sus || null;

      if (baseCardId && (patientCpf || patientCns)) {
        const variables = {
          baseCardId: baseCardId,
          cpf: patientCpf,
          cns: patientCns,
        };

        try {
          const result = await this.$store.dispatch(
            "Person/Patient/GET_AB_INDIVIDUAL_REGISTRATION",
            variables,
          );

          return result?.rows;
        } catch {
          return null;
        }
      }

      return null;
    },

    async updatePatientBondBasicAttention(pData) {
      if (pData) {
        await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
          abEsus: pData,
        });
      }
    },

    formatDate(pValue) {
      const split = pValue.split(" ");
      const date = split[0];
      return date;
    },

    formatHour(pValue) {
      const split = pValue.split(" ");
      const hour = split[1].substring(0, 5);
      return hour;
    },

    formatGender(pValue) {
      return pValue && (pValue === "M" || pValue === "m")
        ? "MASCULINO"
        : pValue && (pValue === "F" || pValue === "f")
        ? "FEMININO"
        : "INDEFINIDO";
    },

    formatCpf(pValue) {
      return pValue?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/gi, "$1.$2.$3-$4");
    },

    formatCns(pValue) {
      return pValue?.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },

    close() {
      this.dataPatientQueue = null;
      this.$emit("close");
    },
  },
};
</script>
