<template>
  <Modulebox
    ref="modulebox"
    :title="`${titlePage} Registro na Remessa BPA-I`"
    class="bpa-i-register"
    :disabled="disabledByModal"
  >
    <RgValidatorForm ref="validator" @submit.stop.prevent="submitSave">
      <FormBase
        title="Dados do Profissional"
        class="form-professional-bpai"
        :class="{ disable: disabledByModal }"
      >
        <RgSuggestEmployee
          ref="employee"
          v-model="form.employee.professionalCnsName"
          label="CNS e Nome do Profissional"
          placeholder="Digite o número de CNS ou nome do Profissional"
          :rules="{ required: true }"
          :disabled="typeRegister === LIST_TYPE_REGISTER.edit"
          :class="{ disable: typeRegister === LIST_TYPE_REGISTER.edit }"
          :unitHealthId="form.unitHealthId"
          @selected="selectedEmployee"
        />
        <RgSuggestCbo
          ref="employeeCBO"
          v-model="form.employee.cbo"
          label="CBO"
          placeholder="000000"
          :rules="{ required: true, forceSelection: true }"
          :employeeId="form.employee.professionalId"
          :unitHealthId="form.unitHealthId"
          :disabled="
            typeRegister === LIST_TYPE_REGISTER.edit ||
            !form.employee.professionalCnsName
          "
          :class="{
            disable:
              typeRegister === LIST_TYPE_REGISTER.edit ||
              !form.employee.professionalCnsName,
          }"
          @selected="selectedCbo"
        />
      </FormBase>
      <FormBase
        title="Dados da Remessa"
        class="form-shipping-bpai"
        :class="{ disable: disabledByModal }"
      >
        <RgInput
          v-model="form.cnes"
          label="CNES"
          :disabled="true"
          :class="{ disable: true }"
          :rules="{ required: true }"
          placeholder="000000"
        />
        <RgSelectCompetence
          v-model="form.competence"
          label="Competência"
          :rules="{ required: true }"
          :disabled="typeRegister === LIST_TYPE_REGISTER.edit"
          :class="{ disable: typeRegister === LIST_TYPE_REGISTER.edit }"
          isRegister
        />
        <RgSelectSheet
          ref="sheet"
          v-model="form.sheet"
          :callbackLastSheet="selectLastSheet"
          :register="typeRegister !== LIST_TYPE_REGISTER.edit"
          :disabled="true"
          :class="{ disable: true }"
          :rules="{ required: true }"
          :filterRegister="{
            cnes: form.cnes,
            cns: form.employee.professionalCns,
            cbo: form.employee.cboCode,
            competence: form.competence,
          }"
        />
      </FormBase>
      <FormBase
        title="Dados do Paciente/Individuo"
        class="form-patient-bpai"
        :class="{ disable: disabledByModal }"
      >
        <RgSuggestPatientCns
          ref="patientCns"
          v-model="form.patient.patientCns"
          label="CNS"
          :maxlength="15"
          :rules="{ fn: validateCns, required: cnsRequired }"
          @selected="selectedPatientCns"
        />
        <RgSuggestPatient
          v-show="typeRegister !== LIST_TYPE_REGISTER.edit"
          ref="patientName"
          v-model="form.patient.patientName"
          label="Nome"
          filterByCompetence
          :rules="{ required: true }"
          class="grid-occupation-2"
          @selected="selectedPatientName"
        />
        <RgInput
          v-show="typeRegister === LIST_TYPE_REGISTER.edit"
          v-model="form.patient.patientName"
          :rules="{ name: true, required: true }"
          class="grid-occupation-2"
          label="Nome"
          placeholder="Ex. Antonio Augusto de Souza"
        />
        <RgRadioCustom
          id="gender"
          ref="gender"
          v-bind="propsRadioCustom"
          :canUnselect="false"
          label="Sexo"
          :disabledByModal="
            !(form.patient.patientName || form.patient.patientCns)
          "
          :class="{
            disable: !(form.patient.patientName || form.patient.patientCns),
          }"
          :disabled="!(form.patient.patientName || form.patient.patientCns)"
          :rules="{ required: true }"
          @input="inputRadioCustom"
        />
        <RgInputDate
          ref="birthdayDate"
          v-model="form.patient.birthDate"
          :rules="{ required: true }"
          :class="{
            disable: !(form.patient.patientName || form.patient.patientCns),
          }"
          :disabled="!(form.patient.patientName || form.patient.patientCns)"
          :max-date="new Date()"
          label="Data de Nascimento"
        />
        <RgComboboxEthnicity
          ref="raceColor"
          v-model="form.patient.raceColor"
          :rules="{ required: true }"
          class="top"
          label="Raça/Cor"
          :class="{
            disable: !(form.patient.patientName || form.patient.patientCns),
          }"
          :disabled="!(form.patient.patientName || form.patient.patientCns)"
          @change="selectedRaceColor"
        />
        <RgSuggestEthnicityAb
          ref="ethnicityAb"
          v-model="form.patient.ethnicity"
          :rules="{ required: ETHNICITY_INDIAN === form.patient.raceColor }"
          label="Etnia"
          :disabled="ETHNICITY_INDIAN !== form.patient.raceColor"
          :class="{ disable: ETHNICITY_INDIAN !== form.patient.raceColor }"
          concatenateCodeName
          @selected="selectedEthnicity"
        />
        <RgSuggestNationality
          id="nacionalidade"
          ref="nationality"
          v-model="form.patient.nationality"
          :rules="{ required: true, forceSelection: true }"
          label="Nacionalidade"
          :class="{
            disable: !(form.patient.patientName || form.patient.patientCns),
          }"
          :disabled="!(form.patient.patientName || form.patient.patientCns)"
          placeholder="Digite o nome do país de origem"
          @selected="selectedNationality"
        />
        <RgInputCep
          ref="cep"
          v-model="form.patient.address.cep"
          label="CEP"
          :class="{
            disable: !(form.patient.patientName || form.patient.patientCns),
          }"
          :disabled="!(form.patient.patientName || form.patient.patientCns)"
          :rules="{ required: true, fn: validateCep }"
          @blur="fillAddress"
        />
        <RgSuggestCity
          ref="city"
          v-model="form.patient.address.city"
          :rules="{ required: isThereCEP, forceSelection: true }"
          :disabled="disableCity"
          class="grid-occupation-3"
          :class="{ disable: disableCity }"
          @selected="selectedCity"
        />
        <RgSuggestPlace
          id="logradouro"
          ref="place"
          v-model="form.patient.address.publicPlace"
          label="Tipo de Logradouro"
          class="grid-occupation-1"
          :rules="{ required: isThereCEP, forceSelection: true }"
          :disabled="disablePublicPlace"
          :class="{ disable: disablePublicPlace }"
          @selected="selectedPlace"
        />
        <RgInput
          id="endereco"
          ref="address"
          v-model="form.patient.address.name"
          class="grid-occupation-4"
          label="Logradouro"
          placeholder="Digite o nome do logradouro"
          :rules="{ required: isThereCEP }"
          :disabled="disableAddress"
          :class="{ disable: disableAddress }"
          :maxlength="100"
        />
        <RgInput
          id="numero"
          ref="numberField"
          v-model="form.patient.address.numberAddress"
          label="Número"
          placeholder="Ex.: 56"
          :rules="{ required: true }"
          :disabled="!isThereCEP"
          :class="{ disable: !isThereCEP }"
          :maxlength="10"
        />
        <RgInput
          id="complemento"
          v-model="form.patient.address.complement"
          label="Complemento"
          placeholder="Ex.: Casa"
          :disabled="!isThereCEP"
          :class="{ disable: !isThereCEP }"
          :maxlength="45"
        />
        <RgInput
          id="bairro"
          ref="neighborhood"
          v-model="form.patient.address.neighborhood"
          label="Bairro"
          placeholder="Digite o nome do bairro"
          :rules="{ required: isThereCEP }"
          :maxlength="100"
          :disabled="disableNeighborhood"
          :class="{ disable: disableNeighborhood }"
        />
        <RgSuggestState
          ref="state"
          v-model="form.patient.address.state"
          :rules="{ required: isThereCEP, forceSelection: true }"
          :disabled="disableState"
          :class="{ disable: disableState }"
          @selected="selectedState"
        />
        <RgSelectDddInputPhone
          ref="phone"
          v-model="form.patient.phone.phoneNumber"
          :class="{
            disable: !(form.patient.patientName || form.patient.patientCns),
          }"
          :rules="{ fn: validatePhone }"
          :disabled="!(form.patient.patientName || form.patient.patientCns)"
          @getDdd="getDdd"
        />
        <RgInputEmail
          v-model="form.patient.email"
          class="mail grid-occupation-3"
          label="E-mail"
          placeholder="Digite um e-mail"
          :maxlength="75"
          :class="{
            disable: !(form.patient.patientName || form.patient.patientCns),
          }"
          :disabled="!(form.patient.patientName || form.patient.patientCns)"
        />
        <div class="sub-footer-button">
          <RgCleanButton
            large
            type="button"
            class="clean unselect"
            title="Limpar"
            :disabled="disabledByModal"
            @click="confirmFormPatient"
          />
        </div>
      </FormBase>
      <FormBase
        title="Dados do Procedimento Realizado"
        class="form-procedure-bpai"
        :class="{ disable: disabledByModal }"
      >
        <div class="grid-group-1">
          <RgInput
            v-model="form.sequence"
            label="Sequência"
            :disabled="true"
            :class="{ disable: true }"
            placeholder="00"
          />
          <RgInputDate
            ref="serviceDate"
            v-model="form.serviceDate"
            :rules="{ required: true }"
            :max-date="maxValidDate"
            :min-date="minValidDate"
            label="Data de Atendimento"
          />
        </div>
        <RgSuggestProcedure
          ref="procedure"
          v-model="form.procedure"
          :rules="{ forceSelection: true, required: true }"
          placeholder="Digite o Código ou o Nome do procedimento"
          label="Código ou Nome do Procedimento"
          class="grid-occupation-5"
          :competence="form.competence"
          :sex="form.patient.patientSex"
          :birth="form.patient.birthDate"
          :alertEmptyResult="true"
          :occupation="Number(form.employee.cboCode)"
          :disabled="disableProcedure"
          :class="{
            disable: disableProcedure,
          }"
          @selected="selectedProcedure"
        />
        <div class="grid-group-1">
          <RgInputQuantity
            ref="quantity"
            v-model="form.quantity"
            label="Quantidade"
            :min="1"
            :max="Number(form.quantityMax)"
            :rules="{ required: true }"
            :disabled="!form.procedureCode"
            :class="{ disable: !form.procedureCode }"
            :maxlength="4"
          />
          <RgInputCnpj
            id="cnpj"
            v-model="form.cnpj"
            label="CNPJ"
            placeholder="00.000.000/0001-00"
            :disabled="!form.procedureCode"
            :class="{ disable: !form.procedureCode }"
          />
        </div>
        <RgSelectService
          ref="service"
          v-model="form.service"
          :rules="{ required: isServiceRequired }"
          :unitHealthId="form.unitHealthId ? Number(form.unitHealthId) : null"
          :procedureCode="
            form.procedureCode ? String(form.procedureCode) : null
          "
          :disabled="disableProcedure || !form.procedureCode || disabledService"
          :class="{
            disable: disableProcedure || !form.procedureCode || disabledService,
          }"
          @required="requiredService"
          @disable="disableService"
        />
        <RgSelectClassification
          ref="classification"
          v-model="form.classification"
          class="grid-occupation-4"
          :rules="{ required: isClassificationRequired }"
          :unitHealthId="form.unitHealthId ? Number(form.unitHealthId) : null"
          :procedureCode="
            form.procedureCode ? String(form.procedureCode) : null
          "
          :serviceCode="form.service ? String(form.service) : null"
          :disabled="
            disableProcedure ||
            !form.procedureCode ||
            !form.service ||
            disabledClassification
          "
          :class="{
            disable:
              disableProcedure ||
              !form.procedureCode ||
              !form.service ||
              disabledClassification,
          }"
          @required="requiredClassification"
          @disable="disableClassification"
          @change="selectedClassification"
        />
        <RgSuggestCid
          ref="cid"
          v-model="form.cid"
          label="CID"
          class="grid-occupation-3"
          :rules="{ required: cidRequired }"
          :procedureCode="form.procedureCode"
          :patientGender="form.patient.patientSex"
          :disabled="disableProcedure || !form.procedureCode || disabledCid"
          :class="{
            disable: disableProcedure || !form.procedureCode || disabledCid,
          }"
          :competence="form.competence"
          @selected="selectedCid"
        />
        <RgComboboxServiceCharacter
          ref="serviceCharacter"
          v-model="form.character"
          :rules="{ required: serviceCharacterRequired }"
          :disabled="!form.procedureCode"
          :class="{ disable: !form.procedureCode }"
        />
        <RgInput
          ref="authorization"
          v-model="form.numberAuthorization"
          label="Nº de autorização"
          placeholder="0000000000000"
          :maxlength="13"
          :disabled="!form.procedureCode"
          :class="{ disable: !form.procedureCode }"
        />
        <div class="sub-footer-button">
          <RgCleanButton
            large
            type="button"
            class="clean unselect"
            title="Limpar"
            :disabled="disabledByModal"
            @click="confirmFormProcedure"
          />
        </div>
      </FormBase>
    </RgValidatorForm>

    <div slot="footer" class="bpa-i-footer-register">
      <RgCancelButton
        medium
        label="Cancelar"
        :disabled="
          disabledByModal ||
          (!form.procedure && typeRegister === LIST_TYPE_REGISTER.edit)
        "
        :disabledButton="
          disabledByModal ||
          (!form.procedure && typeRegister === LIST_TYPE_REGISTER.edit)
        "
        @click="goBack"
      />
      <RgSaveButton
        medium
        label="Salvar"
        :disabled="disabledByModal"
        @click="submitSave"
      />
    </div>

    <ModalConfirmFieldClearing
      id="modal-confirm-clearing"
      ref="confirmClearing"
      :show="showModalConfirmFieldClearing"
      @getYes="cleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />
  </Modulebox>
</template>

<script>
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import { isEmpty } from "lodash";
import RgInputSuscardHelper from "~tokio/primitive/input/rg-input-suscard/RgInputSuscardHelper";
import {
  RgSaveButton,
  RgInput,
  RgInputDate,
  RgInputEmail,
  RgInputCnpj,
  RgValidatorForm,
  RgCancelButton,
  RgRadioCustom,
  RgCleanButton,
  RgSuggestEthnicityAb,
} from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgInputCep from "$person/common/components/input/rg-input-cep/RgInputCep";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import {
  RgSelectClassification,
  RgSuggestProcedure,
  RgSuggestEmployee,
  RgSuggestPatient,
  RgSuggestPatientCns,
  RgSelectCompetence,
  RgComboboxServiceCharacter,
  RgSelectService,
  RgComboboxEthnicity,
  RgSuggestCity,
  RgSuggestPlace,
  RgSuggestCbo,
  RgSuggestCid,
  RgSuggestNationality,
  RgSuggestState,
  RgInputQuantity,
} from "$billing/common/components/index";
import { RgSelectSheet } from "$billing/submodules/bpa/bpa-i/components";
import moment from "moment";
import CheckCEP from "$billing/billing/action/CheckCEP";
import SearchPersonById from "$billing/submodules/bpa/bpa-i/store/actions/SearchPersonById";
import RgSelectDddInputPhone from "$person/common/components/input/rg-select-ddd-input-phone/RgSelectDddInputPhone";

const FORM_REGISTER = {
  unitHealthId: null,
  cnes: null,
  competence: null,
  sheet: null,
  employee: {
    professionalId: null,
    professionalCnsName: null,
    professionalName: null,
    professionalCns: null,
    professionalBondId: null,
    employeePersonId: null,
    cbo: null,
    cboId: null,
    cboCode: null,
    cboName: null,
  },
  patient: {
    personId: null,
    patientCnsId: null,
    patientCns: null,
    patientName: null,
    patientSex: null,
    birthDate: null,
    raceColor: null,
    raceColorCode: null,
    ethnicityId: null,
    ethnicity: null,
    ethnicityCode: null,
    nationalityId: null,
    nationality: null,
    nationalityCode: null,
    address: {
      addressId: null,
      cep: null,
      city: null,
      cityId: null,
      cityCode: null,
      publicPlaceId: null,
      publicPlace: null,
      publicPlaceCode: null,
      numberAddress: null,
      complement: null,
      neighborhood: null,
      stateId: null,
      state: null,
      name: null,
    },
    phone: {
      ddd: {
        dddId: null,
        dddCode: null,
      },
      phoneId: null,
      phonePersonId: null,
      phoneDddId: null,
      phoneNumber: null,
    },
    email: null,
  },
  sequence: null,
  serviceDate: null,
  procedureCode: null,
  procedure: null,
  quantity: 1,
  quantityMax: 9999,
  cnpj: null,
  service: null,
  classification: null,
  classificationCode: null,
  cid: null,
  cidCode: null,
  character: null,
  numberAuthorization: null,
  ddd: null,
};

export default {
  name: "RegisterBpaI",
  components: {
    Modulebox,
    FormBase,
    RgCancelButton,
    RgSaveButton,
    RgCleanButton,
    RgInput,
    RgSelectCompetence,
    RgSuggestEmployee,
    RgSuggestCbo,
    RgSuggestCid,
    RgSuggestPatient,
    RgComboboxEthnicity,
    RgInputDate,
    RgSuggestNationality,
    RgInputCep,
    RgSuggestCity,
    RgSuggestState,
    RgSelectDddInputPhone,
    RgInputEmail,
    RgSuggestProcedure,
    RgSelectSheet,
    RgInputCnpj,
    RgSelectClassification,
    RgSuggestPatientCns,
    RgSuggestEthnicityAb,
    RgSelectService,
    RgComboboxServiceCharacter,
    RgSuggestPlace,
    RgInputQuantity,
    RgValidatorForm,
    RgRadioCustom,
    ModalConfirmFieldClearing,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_REGISTER),
      typeRegister: null,
      titlePage: "Adicionar Novo",
      registerSelected: null,
      hasAddress: false,
      hasPublicPlace: false,
      hasCity: false,
      hasState: false,
      hasNeighborhood: false,
      typeFormClear: null,
      disabledService: true,
      disabledClassification: true,
      disabledCid: true,
      cnsRequired: false,
      cidRequired: false,
      serviceCharacterRequired: false,
      showModalConfirmFieldClearing: false,
      hasCns: false,
      isServiceRequired: false,
      isClassificationRequired: false,
      hasMandatoryService: false,
    };
  },

  computed: {
    disabledByModal() {
      return this.showModalConfirmFieldClearing;
    },
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];
      const value = this.form.patient.patientSex;
      const uniqueKey = "id";
      return { list, uniqueKey, value };
    },
    minValidDate() {
      const competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      const dateSelected = this.form.competence;
      const date = moment(dateSelected || competence, "YYYYMM")
        .subtract(3, "months")
        .format("YYYY-MM-[02]");
      return new Date(date);
    },
    maxValidDate() {
      const competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      const actualDate = moment().format("YYYYMM");
      const date = this.form.competence
        ? this.form.competence > actualDate ||
          this.form.competence === actualDate
          ? moment().add(1, "days").format("YYYY-MM-DD")
          : this.$utils.date.CompetenceLastDayToDatabase(this.form.competence)
        : this.$utils.date.CompetenceLastDayToDatabase(competence);
      return new Date(date);
    },
    isThereCEP() {
      return this.form?.patient?.address?.cep?.length >= 9;
    },
    disablePublicPlace() {
      if (!this.isThereCEP || this.hasPublicPlace) {
        return true;
      }
      return false;
    },
    disableAddress() {
      if (!this.isThereCEP || this.hasAddress) {
        return true;
      }
      return false;
    },
    disableNeighborhood() {
      if (!this.isThereCEP || this.hasNeighborhood) {
        return true;
      }
      return false;
    },
    disableCity() {
      if (!this.isThereCEP || this.hasCity) {
        return true;
      }
      return false;
    },
    disableState() {
      if (!this.isThereCEP || this.hasState) {
        return true;
      }
      return false;
    },
    disableProcedure() {
      return (
        !this.form.employee.cboCode ||
        !this.form.competence ||
        !this.form.patient.patientSex ||
        !this.form.patient.birthDate
      );
    },
  },
  watch: {
    "form.service"(pValue, pPrev) {
      if (!pValue || (pPrev && pValue !== pPrev)) {
        this.form.classification = null;
        this.form.classificationCode = null;
      }
    },
    "form.procedure"(pValue, pPrev) {
      if (!pValue || (pPrev && pValue !== pPrev)) {
        this.cleanFieldsValidationProcedure();
        this.cleanInfoProcedure();
      }
    },
    "form.patient.patientName"(pValue, pPrev) {
      if (!pValue && pPrev) {
        this.cleanPatient();
      }
    },
    "form.employee.cbo"(pValue, pPrev) {
      if (!pValue && pPrev) {
        this.cleanFormProcedure();
      }
    },
    "form.patient.birthDate"(pValue, pPrev) {
      if (pValue && pPrev !== null) {
        this.cleanProcedure();
      }
    },
    "form.sheet"(pValue, pPrev) {
      if (pValue === null) {
        this.form.sheet = pPrev;
      }
    },
    "form.patient.address.cep"(pValue, pPrev) {
      if (!pValue && pPrev) {
        this.cleanAddress();
        this.cleanFieldsValidationAddress();
      }
      if (pValue && pPrev && pValue !== pPrev && pValue.length === 9) {
        this.cleanFieldsValidationAddress();
      }
    },
    "form.patient.raceColor"(pValue, pPrev) {
      if (!pValue || (pPrev && pValue !== pPrev)) {
        this.form.patient.ethnicity = null;
        this.form.patient.ethnicityId = null;
        this.form.patient.ethnicityCode = null;
      }
    },
    "form.competence"(pValue, pPrev) {
      if (!pValue || (pValue !== pPrev && pPrev)) {
        this.form.serviceDate = null;
      }
    },
  },
  created() {
    this.ETHNICITY_INDIAN = 5;
    this.NATIONALITY_NOT_INFORMED = "999";
    this.RACE_NOT_INFORMED = "99";
    this.LIST_TYPE_REGISTER = {
      register: 1,
      edit: 2,
      duplicate: 3,
    };
  },
  mounted() {
    this.form.unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.typeRequest();
  },
  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/billing/bpa/bpa-i",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Billing/BPAI/RESET_EMPLOYEE_REGISTER_BPAI");
    }
  },
  methods: {
    requiredService(pRequired) {
      if (pRequired) {
        this.isServiceRequired = true;
        this.isClassificationRequired = true;
      } else {
        this.isServiceRequired = false;
        this.isClassificationRequired = false;
      }
    },
    disableService(pDisabled) {
      if (pDisabled) {
        this.disabledService = true;
      } else {
        this.disabledService = false;
      }
    },
    requiredClassification(pRequired) {
      if (pRequired) {
        this.isClassificationRequired = true;
      } else {
        this.isClassificationRequired = false;
      }
    },
    disableClassification(pDisabled) {
      if (pDisabled) {
        this.disabledClassification = true;
      } else {
        this.disabledClassification = false;
      }
    },
    async typeRequest() {
      switch (this.$router.history.current.name) {
        case "billing.bpa.bpa-i.register": {
          this.typeRegister = this.LIST_TYPE_REGISTER.register;
          this.loadStore();
          break;
        }
        case "billing.bpa.bpa-i.edit": {
          this.typeRegister = this.LIST_TYPE_REGISTER.edit;
          this.titlePage = "Editar";
          await this.fillData(this.LIST_TYPE_REGISTER.edit);
          break;
        }
        case "billing.bpa.automated-shipping-production.bpa-i.edit": {
          this.typeRegister = this.LIST_TYPE_REGISTER.edit;
          this.titlePage = "Editar";
          await this.fillData(this.LIST_TYPE_REGISTER.edit);
          break;
        }
        case "billing.bpa.bpa-i.duplicate": {
          this.typeRegister = this.LIST_TYPE_REGISTER.duplicate;
          this.titlePage = "Duplicar";
          this.loadStore();
          this.fillData(this.LIST_TYPE_REGISTER.duplicate);
          break;
        }
        default:
          break;
      }
    },
    loadStore() {
      this.form.cnes = this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
      this.form.competence = this.$store.getters[
        "Billing/BDSia/GET_PERIOD_DATE"
      ];
    },
    async fillData(pTypeRequest) {
      try {
        this.$loader.start();

        this.registerSelected = await {
          ...this.$store.getters["Billing/BPAI/GET_REGISTER_BILLING_BPAI"],
        };

        const result = await this.$store.dispatch("Billing/BPAI/SEARCH_BPAI", {
          bof_id: this.registerSelected.pdf_id_boletins_faturamento,
          pdf_id: this.registerSelected.pdf_id,
        });

        const data = result.rows[0];

        this.form.employee.professionalName = data.pdf_nome_profissional;
        this.form.employee.professionalCns = data.pdf_cns || "000000000000000";
        this.form.employee.professionalCnsName = `${
          data.pdf_cns || "000000000000000"
        } - ${data.pdf_nome_profissional}`;

        await this.$refs.employeeCBO?.forceSelection({
          ocp_id: this.registerSelected.ocp_id,
          ocp_nome: this.registerSelected.ocp_nome,
          ocp_codigo: this.registerSelected.pdf_cod_cbo,
        });

        this.form.unitHealthId = data.bof_id_unidades_saude;
        this.form.cnes = data.bof_cnes;

        const hasPersonId = data.bof_id_pessoas && data.bof_id_pessoas > 0;

        if (hasPersonId) {
          this.form.patient.personId = data.bof_id_pessoas;
          const patientData = await SearchPersonById({
            pesId: Number(data.bof_id_pessoas),
          });

          this.form.patient.patientCns = patientData?.cns?.crs_numero;

          await this.$refs.patientName?.forceSelection({
            pes_id: patientData.pes_id,
            pes_nome: patientData.pes_nome,
            patient: {
              pac_id: patientData.patient.pac_id,
            },
            cns: { crs_numero: patientData.cns.crs_numero },
            ...patientData,
          });
        } else {
          if (data.bof_nome && data.bof_nome.length > 0) {
            this.form.patient.patientName = data.bof_nome;
            this.$refs.patientName.$refs.innerSuggest.$refs.input.focus();
            this.$refs.patientName.$refs.innerSuggest.inputValue = this.form.patient.patientName;
            this.$refs.patientName.$refs.innerSuggest.focusMe(
              this.form.patient.patientName,
            );

            if (await this.$refs.patient?.hasSuggestPatient()) {
              this.$nextTick(() => {
                this.form.patient.birthDate = data.bof_data_nascimento
                  ? moment(data.bof_data_nascimento, "YYYY-MM-DD").format(
                      "DD/MM/YYYY",
                    )
                  : null;
              });
            } else {
              this.$refs.patientName.$refs.innerSuggest.$refs.input.blur();
              this.loadDataFromUnregisteredPatient(data);
            }
          }
        }
        if (pTypeRequest === this.LIST_TYPE_REGISTER.edit) {
          this.form.competence = data.bof_competencia;
          this.form.sheet = data.pdf_folha;
          this.form.sequence = this.registerSelected.sequence;
          this.form.serviceDate =
            (await data.pdf_data_atendimento) !== "0000-00-00"
              ? moment(data.pdf_data_atendimento, "YYYY-MM-DD").format(
                  "DD/MM/YYYY",
                )
              : null;
          this.form.procedureCode = data.pdf_cod_procedimento;

          const procedure = await data?.procedimento?.split(" - ");

          if (procedure && procedure.length > 0) {
            const variablesProcedure = await {
              competence: data.bof_competencia,
              occupation: this.registerSelected.pdf_cod_cbo,
              procedureCode: procedure[0],
              procedureName: procedure[1],
              sex: data.bof_sexo,
              birth: moment(data.bof_data_nascimento, "YYYY-MM-DD").format(
                "DD/MM/YYYY",
              ),
            };

            const rulesProcedure = await this.$refs.procedure?.loadRulesProcedure(
              variablesProcedure,
            );

            if (rulesProcedure) {
              await this.$refs.procedure?.forceSelection({
                CO_OCUPACAO: rulesProcedure.CO_OCUPACAO,
                CO_PROCEDIMENTO: procedure[0],
                NO_PROCEDIMENTO: procedure[1],
                QT_MAXIMA_EXECUCAO: rulesProcedure.QT_MAXIMA_EXECUCAO,
                TP_SEXO: rulesProcedure.TP_SEXO,
                VL_IDADE_MAXIMA: rulesProcedure.VL_IDADE_MAXIMA,
                VL_IDADE_MINIMA: rulesProcedure.VL_IDADE_MINIMA,
                cidObrigatorio: rulesProcedure.cidObrigatorio,
                cnsObrigatorio: rulesProcedure.cnsObrigatorio,
                caraterAtendimentoObrigatorio:
                  rulesProcedure.caraterAtendimentoObrigatorio,
                possuiCid: rulesProcedure.possuiCid,
                possuiServico: rulesProcedure.possuiServico,
              });
            }

            this.form.quantity = data.pdf_quantidade
              ? Number(data.pdf_quantidade)
              : null;

            this.form.cnpj = data.pdf_cnpj;
            this.form.service = data.pdf_servico || null;
            this.form.classification = data.pdf_classificacao || null;
            this.form.classificationCode = data.pdf_classificacao || null;
            this.form.cidCode = data.pdf_cid || null;

            const cid = await data?.cid?.split(" - ");

            if (cid && cid.length > 0) {
              await this.$refs.cid?.forceSelection({
                cid_codigo: cid[0],
                cid_nome: cid[1],
              });
            }

            this.form.character = data.pdf_id_internacoes_caracteres;
            this.form.numberAuthorization = data.pdf_num_autorizacao;

            await this.loadDataFromUnregisteredPatient(data);
          }
        }
      } catch (error) {
        this.$toaster.error(error, "Erro ao carregar dados do BPA-I");
      } finally {
        this.$loader.finish();
      }
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async submitSave() {
      if (
        this.hasMandatoryService &&
        this.disabledService &&
        this.disabledClassification
      ) {
        if (!this.form.service) {
          this.$toaster.info(
            'Verifique em "Faturamento/BPA/Serviços e Classificações" as opções disponíveis.',
            "O Serviço não foi importado para a Unidade",
          );
          return;
        }

        if (!this.form.classification) {
          this.$toaster.info(
            'Verifique em "Faturamento/BPA/Serviços e Classificações" as opções disponíveis.',
            "A Classificação não foi importada para a Unidade",
          );
          return;
        }
      }

      const valid = await this.isFormValid();

      if (!valid) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      this.saveBulletin();
    },
    generationVariables() {
      const SEX_MASCULINE = 1;
      const SEX_FEMININE = 2;
      const SEX_UNDEFINED = 3;

      const variables = {
        bulletinBilling: {
          bof_nome: this.form.patient.patientName,
          bof_id_unidades_saude: this.form.unitHealthId,
          bof_id_pessoas: this.form.patient.personId,
          bof_cns: this.form.patient.patientCns || null,
          bof_sexo:
            this.form.patient.patientSex === "X"
              ? "M"
              : this.form.patient.patientSex,
          bof_data_nascimento: moment(
            this.form.patient.birthDate,
            "DD/MM/YYYY",
          ).format("YYYY-MM-DD"),
          bof_cod_nacionalidade: this.form.patient.nationalityCode,
          bof_cod_raca: this.form.patient.raceColorCode,
          bof_cod_etnia: this.form.patient.ethnicityCode
            ? String(this.form.patient.ethnicityCode)
            : null,
          bof_cep: this.form.patient.address.cep
            ? this.form.patient.address.cep.replace(/-/g, "")
            : null,
          bof_cod_municipio: this.form.patient.address.cityCode,
          bof_cod_tipo_logradouro: this.form.patient.address.publicPlaceCode,
          bof_endereco: this.form.patient.address.name,
          bof_endereco_numero: this.form.patient.address.numberAddress,
          bof_endereco_complemento:
            this.form.patient.address.complement || null,
          bof_bairro: this.form.patient.address.neighborhood,
          bof_tel: this.form.patient.phone.phoneNumber
            ? this.form.patient.phone.phoneNumber.replace(/-/g, "")
            : "",
          bof_tel_ddd: this.form.patient.phone.ddd.dddCode
            ? this.form.patient.phone.ddd.dddCode
            : "",
          bof_ddd_telefone: "",
          bof_email: this.form.patient.email || null,
          bof_cnes: this.form.cnes,
          bof_competencia: this.form.competence,
          bof_faturado: 0,
          pdf_cod_procedimento: this.form.procedureCode,
          pdf_quantidade: this.form.quantity
            ? Number(this.form.quantity)
            : null,
          pdf_cnpj: this.form.cnpj
            ? this.form.cnpj.replace(/[^0-9]/g, "")
            : null,
          pdf_servico: this.form.service ? this.form.service : null,
          pdf_classificacao: this.form.classification
            ? this.form.classification
            : null,
          pdf_cid: this.form.cidCode,
          pdf_id_internacoes_caracteres: this.form.character
            ? this.form.character
            : null,
          pdf_num_autorizacao: this.form.numberAuthorization,
          pdf_cns: this.form.employee.professionalCns,
          pdf_nome_profissional: this.form.employee.professionalName,
          pdf_cod_cbo: String(this.form.employee.cboCode),
          pdf_data_atendimento: moment(
            this.form.serviceDate,
            "DD/MM/YYYY",
          ).format("YYYY-MM-DD"),
          pdf_cod_ine: null,
          pdf_folha: this.form.sheet,
        },
        patientBulletinBilling: {
          pes_id: this.form.patient.personId,
          crs_id: this.form.patient.patientCnsId,
          crs_numero: this.form.patient.patientCns || null,
          pes_nome: this.form.patient.patientName,
          pes_id_sexos: this.form.patient.patientSex
            ? this.form.patient.patientSex === "M"
              ? SEX_MASCULINE
              : SEX_FEMININE
            : SEX_UNDEFINED,
          pes_nascimento: this.form.patient.birthDate,
          pes_id_etnias: Number(this.form.patient.raceColor),
          pes_id_ab_etnias: this.form.patient.ethnicityId
            ? Number(this.form.patient.ethnicityId)
            : null,
          pes_id_municipios: Number(this.form.patient.address.cityId),
          pes_id_nacionalidades: Number(this.form.patient.nationalityId),
          pes_email: this.form.patient.email || null,
          end_id: Number(this.form.patient.address.addressId),
          end_id_tipos_logradouros: Number(
            this.form.patient.address.publicPlaceId,
          ),
          end_logradouro: this.form.patient.address.name,
          end_numero: this.form.patient.address.numberAddress,
          end_complemento: this.form.patient.address.complement,
          end_cep: this.form.patient.address.cep,
          mun_id: Number(this.form.patient.address.cityId),
          bai_nome: this.form.patient.address.neighborhood,
          tel_id: this.form.patient.phone.phoneId || null,
          tel_id_ddds: this.form.patient.phone.phoneDddId || null,
          tel_id_pessoas: this.form.patient.personId || null,
          tel_numero:
            this.form.patient.phone.phoneNumber &&
            this.form.patient.phone.phoneNumber.length > 0
              ? this.form.patient.phone.phoneNumber.replace("-", "")
              : null,
        },
      };

      return variables;
    },
    async saveBulletin() {
      try {
        this.$loader.start();
        const variables = this.generationVariables();

        switch (this.typeRegister) {
          case this.LIST_TYPE_REGISTER.register: {
            await this.$store.dispatch("Billing/BPAI/SAVE_BULLETIN_BILLING", {
              variables,
            });
            this.$toaster.success(
              "A sequência dessa remessa foi registrada com sucesso",
            );
            this.$store.commit(
              "Billing/BPAI/SET_EMPLOYEE_REGISTER_BPAI",
              this.form.employee,
            );

            this.cleanForm();
            await this.reloadEmployee();
            break;
          }
          case this.LIST_TYPE_REGISTER.edit: {
            await this.$store.dispatch("Billing/BPAI/SAVE_BULLETIN_BILLING", {
              variables: {
                bulletinBilling: {
                  ...variables.bulletinBilling,
                  bof_id: this.registerSelected.pdf_id_boletins_faturamento,
                  pdf_id: this.registerSelected.pdf_id,
                },
                patientBulletinBilling: {
                  ...variables.patientBulletinBilling,
                },
              },
            });
            this.$toaster.success(
              "A sequência dessa remessa foi alterada com sucesso",
            );
            this.goBack();
            break;
          }
          case this.LIST_TYPE_REGISTER.duplicate: {
            await this.$store.dispatch("Billing/BPAI/SAVE_BULLETIN_BILLING", {
              variables,
            });
            this.$toaster.success("O registro foi duplicado com sucesso");
            this.goBack();
            break;
          }
          default:
            break;
        }

        const filterData = this.$store.getters[
          "Billing/BPAI/GET_FILTER_BILLING_BPAI"
        ];

        const hasObjectPatient =
          filterData &&
          filterData.patient &&
          Object.keys(filterData.patient).length > 0;

        if (hasObjectPatient) {
          if (
            filterData.patient.cns &&
            filterData.patient.name &&
            filterData.patient.cns.length > 0 &&
            filterData?.patient.name.length > 0
          ) {
            filterData.patient.cns = this.form.patient.patientCns;
            filterData.patient.name = this.form.patient.patientName;
            filterData.patient.nameAndCns =
              this.form.patient.patientCns +
              " - " +
              this.form.patient.patientName;

            this.$store.commit(
              "Billing/BPAI/SET_FILTER_BILLING_BPAI",
              filterData,
            );
          }
        }
      } catch (err) {
        if (err.message.includes("subscriptionLimitExceeded")) {
          this.$toaster.info(
            `Uma nova Folha foi criada com o último registro inserido.`,
            "O Limite de Registros por Folha foi Atingido",
          );
          this.goBack();
        } else if (err.message.includes("recordAlreadyExists")) {
          this.$toaster.info(
            `Faça uma busca pelo profissional ou procedimento e confira os dados.`,
            "O registro já faz parte dessa remessa",
          );
          this.goBack();
        } else if (err.message.includes("registrationLimitAlreadyReached")) {
          this.$toaster.info(
            `A quantidade máxima por folha é de 99 registros.`,
            "Limite permitido por folha atingido",
          );
          this.goBack();
        } else {
          this.$toaster.error(err.message, "Erro ao registrar remessa");
        }
      } finally {
        this.$loader.finish();
      }
    },
    async reloadEmployee() {
      this.$loader.start();
      const employee = this.$store.getters[
        "Billing/BPAI/GET_EMPLOYEE_REGISTER_BPAI"
      ];
      this.form.employee = employee;
      if (employee) {
        await this.$refs.employee.forceSelection({
          pes_id: employee.employeePersonId,
          pes_nome: employee.professionalName,
          fun_id: employee.professionalId,
          vin_id: employee.professionalBondId,
          person: { cns: { crs_numero: employee.professionalCns } },
        });
        await this.$refs.employeeCBO.forceSelection({
          ocp_id: employee.cboId,
          ocp_codigo: employee.cboCode,
          ocp_nome: employee.cboName,
        });
      }
      await this.loadStore();
      this.form.employee.professionalCns = employee.professionalCns;
      await this.$refs.sheet.getSheets();
      this.$loader.finish();
    },
    selectedEmployee(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        const data = pValue.source;
        this.form.employee.professionalId = data.fun_id;
        this.form.employee.professionalCns =
          data.crs_numero && data.crs_numero.length > 0
            ? data.crs_numero
            : data?.person?.cns?.crs_numero;
        this.form.employee.employeePersonId = data.pes_id;
        this.form.employee.professionalName = data.pes_nome;
        this.form.employee.professionalBondId = data.vin_id;
      } else {
        this.form.employee.cbo = null;
      }
    },
    selectedCbo(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        this.form.employee.cboId = pValue.source?.ocp_id;
        this.form.employee.cboCode = pValue.source?.ocp_codigo;
        this.form.employee.cboName = pValue.source?.ocp_nome;
      }
    },
    selectLastSheet(pValue) {
      if (this.typeRegister !== this.LIST_TYPE_REGISTER.edit) {
        this.form.sheet = pValue.value;
        this.form.sequence = String(
          ((pValue.total + 1) / 10).toFixed(1),
        ).replace(".", "");
      }
    },
    selectedPatientCns(pValue) {
      if (pValue && pValue.source?.pes_nome) {
        this.hasCns = true;
        this.form.patient.patientName = pValue.source.pes_nome;
      }
      this.selectedPatient(pValue);
    },
    selectedPatientName(pValue) {
      if (
        pValue &&
        pValue.source?.cns?.crs_numero &&
        pValue.source?.cns?.crs_numero !== "000000000000000"
      ) {
        this.hasCns = true;
        this.form.patient.patientCns = pValue.source?.cns?.crs_numero;
      } else {
        this.hasCns = false;
      }
      if (this.typeRegister !== this.LIST_TYPE_REGISTER.edit) {
        this.selectedPatient(pValue);
      }
    },
    selectedPatient(pValue) {
      const hasObjectPatient =
        pValue && pValue.source && Object.keys(pValue.source).length > 0;

      if (hasObjectPatient) {
        const data = pValue.source;

        this.form.patient.personId = data.pes_id;
        this.form.patient.patientSex = data.gender?.sex_sigla;
        this.form.patient.birthDate = data.pes_nascimento
          ? moment(data.pes_nascimento, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;
        this.form.patient.raceColor = data.ethnicity?.etn_id;
        this.form.patient.raceColorCode = data.ethnicity?.etn_codigo_sisaih;

        this.$refs.ethnicityAb.forceSelection({
          ens_id: data.ethnicityAb?.ens_id,
          ens_codigo_ab: data.ethnicityAb?.ens_codigo_ab,
          ens_descricao: data.ethnicityAb?.ens_descricao,
        });
        this.form.patient.patientCnsId = data.cns?.crs_id;

        if (data.nationality?.nac_codigo !== this.NATIONALITY_NOT_INFORMED) {
          this.$refs.nationality.forceSelection({
            nac_id: data.nationality?.nac_id,
            nac_codigo: data.nationality?.nac_codigo,
            nac_nome: data.nationality?.nac_nome,
          });
        }
        this.form.patient.address.addressId = data.address.end_id;

        if (
          data.address &&
          data.address.end_cep &&
          this.typeRegister !== this.LIST_TYPE_REGISTER.edit
        ) {
          this.form.patient.address.cep = data.address.end_cep;
          this.fillAddress(data.address.end_cep, data.address);
        }

        const dddIs00 = data.telephones?.rows[0]?.tel_id_ddds === 1;

        this.$refs.phone.fillValueById(
          data.telephones?.rows[0]?.tel_numero,
          dddIs00 ? null : Number(data.telephones?.rows[0]?.tel_id_ddds),
        );

        this.form.patient.phone.ddd.dddId =
          data.telephones?.rows[0]?.ddd?.ddd_id;

        this.form.patient.phone.ddd.dddCode =
          data.telephones?.rows[0]?.ddd?.ddd_codigo;

        this.form.patient.phone.phoneId = data.telephones?.rows[0]?.tel_id;

        this.form.patient.phone.phoneDddId =
          data.telephones?.rows[0]?.tel_id_ddds;

        this.form.patient.phone.phoneNumber =
          data.telephones?.rows[0]?.tel_numero;

        this.form.patient.phone.phonePersonId =
          data.telephones?.rows[0]?.tel_id_pessoas;

        this.form.patient.email = data?.pes_email;
      }
    },
    selectedRaceColor(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.patient.raceColor = pValue[0]?.etn_id;
        this.form.patient.raceColorCode = pValue[0]?.etn_codigo_sisaih;
      }
    },
    selectedEthnicity(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        this.form.patient.ethnicityId = pValue.source?.ens_id;
        this.form.patient.ethnicity = pValue.source?.ens_descricao;
        this.form.patient.ethnicityCode = pValue.source?.ens_codigo_ab;
      }
    },
    selectedNationality(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        const data = pValue && pValue.source;
        this.form.patient.nationalityId = data?.nac_id;
        this.form.patient.nationality = data?.nac_nome;
        this.form.patient.nationalityCode = data?.nac_codigo;
      }
    },
    selectedCity(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        const data = pValue && pValue.source;
        this.form.patient.address.cityId = data?.mun_id;
        this.form.patient.address.city = data?.mun_nome;
        this.form.patient.address.cityCode = data?.mun_codigo;
      }
    },
    selectedPlace(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        const data = pValue && pValue.source;
        this.form.patient.address.publicPlaceId = data?.tlg_id;
        this.form.patient.address.publicPlaceCode = data?.tlg_codigo;
      }
    },
    selectedState(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        const data = pValue && pValue.source;
        this.form.patient.address.stateId = data?.est_id;
      }
    },
    selectedProcedure(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        const data = pValue && pValue.source;

        this.form.procedureCode = data.CO_PROCEDIMENTO;
        this.form.quantityMax = Number(data.QT_MAXIMA_EXECUCAO);

        if (data.possuiServico === "SIM") {
          this.disabledService = false;
          this.hasMandatoryService = true;
        } else {
          this.disabledService = true;
          this.hasMandatoryService = false;
        }

        if (data.possuiCid === "SIM") {
          this.disabledCid = false;
          this.cidRequired = true;
        } else {
          this.disabledCid = true;
          this.cidRequired = false;
        }

        data.cnsObrigatorio === "SIM"
          ? (this.cnsRequired = true)
          : (this.cnsRequired = false);

        this.serviceCharacterRequired =
          data.caraterAtendimentoObrigatorio === "SIM";
      } else {
        this.form.procedureCode = null;
        this.disabledService = true;
        this.disabledCid = true;
        this.cnsRequired = false;
        this.cidRequired = false;
        this.serviceCharacterRequired = false;
      }
    },
    selectedClassification(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        this.form.classificationCode =
          pValue?.length > 0 ? pValue[0].value : null;
      } else {
        this.form.classificationCode = null;
      }
    },
    selectedCid(pValue) {
      if (pValue && pValue.source && Object.keys(pValue.source).length > 0) {
        this.form.cidCode = pValue.source?.cid_codigo;
      }
    },
    getDdd(pValue) {
      if (pValue) {
        this.ddd = pValue;
        this.form.patient.phone.ddd.dddId = pValue?.ddd_id;
        this.form.patient.phone.ddd.dddCode = pValue?.ddd_codigo;
        this.form.patient.phone.phoneDddId = pValue?.ddd_id;
      } else {
        this.ddd = pValue;
        this.form.patient.phone.ddd.dddId = null;
        this.form.patient.phone.ddd.dddCode = null;
        this.form.patient.phone.phoneDddId = null;
        this.form.patient.phone.phoneNumber = null;
      }
    },
    validatePhone(pValue, pErrors) {
      if (!pValue) {
        this.$refs.phone.cleanPhone();
        return true;
      }

      if (pValue && pValue.length < 9) {
        pErrors.push("Digite todos os números do telefone");
        return false;
      } else {
        if (!this.ddd) {
          pErrors.push("Selecione um DDD");
          return false;
        }
      }

      return true;
    },
    inputRadioCustom(pItem) {
      if (pItem) {
        if (this.form.patient.patientSex !== pItem.id) {
          this.form.procedure = null;
          this.form.procedureCode = null;
          this.form.quantityMax = 9999;
        }
        this.form.patient.patientSex = pItem.id;
      }
    },
    validateCep(pCep, pErrors) {
      if (isEmpty(pCep)) {
        return true;
      }

      if (pCep.replace(/[^0-9]/gi, "").length !== 8) {
        pErrors.push("CEP inválido");
        return false;
      }

      return CheckCEP(pCep)
        .then(() => {
          return true;
        })
        .catch(() => {
          this.cleanAddress();
          pErrors.push(`O CEP ${pCep} não existe`);
          return false;
        });
    },
    async fillAddress(pValue, pAddress) {
      const currentCep = pValue ? pValue.replace(/[^0-9]/gi, "") : null;
      const isInvalidCep = currentCep ? currentCep.length !== 8 : true;
      if (isInvalidCep) return;

      try {
        this.$loader.start();
        const searchCep = await CheckCEP(currentCep);
        const hasCep = Object.keys(searchCep).length !== 0;

        if (pAddress && Object.keys(pAddress).length > 0) {
          if (pAddress.municipio) {
            const city = pAddress.municipio.split("-");
            this.$refs.city.forceSelection({
              mun_codigo: pAddress.bof_cod_municipio,
              mun_nome: city[1],
            });
          } else if (pAddress.neighborhood.city) {
            this.$refs.city.forceSelection({
              mun_id: pAddress.neighborhood.city.mun_id,
              mun_codigo: pAddress.neighborhood.city.mun_codigo,
              mun_nome: pAddress.neighborhood.city.mun_nome,
            });
          } else {
            this.form.patient.address.city = null;
          }

          if (pAddress.bof_cod_tipo_logradouro) {
            const address = pAddress.logradouro.split("-");
            this.$refs.place.forceSelection({
              tlg_codigo: pAddress.bof_cod_tipo_logradouro,
              tlg_nome: address[1],
            });
          } else if (pAddress.publicPlaceType) {
            this.$refs.place.forceSelection({
              tlg_id: pAddress.publicPlaceType.tlg_id,
              tlg_codigo: pAddress.publicPlaceType.tlg_codigo,
              tlg_nome: pAddress.publicPlaceType.tlg_nome,
            });
          } else {
            this.form.patient.address.publicPlace = null;
          }

          if (pAddress.bof_endereco) {
            this.form.patient.address.name = pAddress.bof_endereco;
          } else if (pAddress.end_logradouro) {
            this.form.patient.address.name = pAddress.end_logradouro;
          } else {
            this.form.patient.address.name = null;
          }

          if (pAddress.bof_bairro) {
            this.form.patient.address.neighborhood = pAddress.bof_bairro;
          } else if (pAddress.neighborhood.bai_nome) {
            this.form.patient.address.neighborhood =
              pAddress.neighborhood.bai_nome;
          } else {
            this.form.patient.address.neighborhood = null;
          }

          if (pAddress.bof_endereco_numero) {
            this.form.patient.address.numberAddress =
              pAddress.bof_endereco_numero;
          } else if (pAddress.end_numero) {
            this.form.patient.address.numberAddress = pAddress.end_numero;
          } else {
            this.form.patient.address.numberAddress = null;
          }

          if (pAddress.bof_endereco_complemento) {
            this.form.patient.address.complement =
              pAddress.bof_endereco_complemento;
          } else if (pAddress.end_complemento) {
            this.form.patient.address.complement = pAddress.end_complemento;
          } else {
            this.form.patient.address.complement = null;
          }

          if (pAddress.est_id) {
            this.$refs.state.forceSelection({
              est_id: pAddress.est_id,
              est_sigla: pAddress.est_sigla,
            });
          } else if (pAddress.neighborhood.city.state) {
            this.$refs.state.forceSelection({
              est_id: pAddress.neighborhood.city.state.est_id,
              est_sigla: pAddress.neighborhood.city.state.est_sigla,
            });
          } else {
            this.form.patient.address.state = null;
          }
        } else if (hasCep) {
          this.form.patient.address.city = null;
          this.form.patient.address.cityId = null;
          this.form.patient.address.cityCode = null;
          this.$refs.city.forceSelection({
            mun_id: searchCep.mun_id,
            mun_codigo: searchCep.mun_codigo,
            mun_nome: searchCep.mun_nome,
          });
          this.form.patient.address.city =
            searchCep.mun_codigo && searchCep.mun_nome
              ? `${searchCep.mun_codigo} - ${searchCep.mun_nome.toUpperCase()}`
              : null;

          this.form.patient.address.publicPlaceId = null;
          this.form.patient.address.publicPlace = null;
          this.form.patient.address.publicPlaceCode = null;

          if (
            !this.form.patient.address.publicPlaceId ||
            searchCep.tlg_nome !== null
          ) {
            this.$refs.place.forceSelection({
              tlg_id: searchCep.tlg_id,
              tlg_codigo: searchCep.tlg_codigo,
              tlg_nome: searchCep.tlg_nome,
            });

            this.form.patient.address.publicPlace =
              searchCep.tlg_codigo && searchCep.tlg_nome
                ? `${
                    searchCep.tlg_codigo
                  } - ${searchCep.tlg_nome.toUpperCase()}`
                : null;
          }

          this.form.patient.address.name = null;
          if (
            !this.form.patient.address.name ||
            searchCep.logradouro !== null
          ) {
            this.form.patient.address.name = searchCep.logradouro
              ? searchCep.logradouro.toUpperCase()
              : "";
          }

          this.form.patient.address.neighborhood = null;
          if (
            !this.form.patient.address.neighborhood ||
            searchCep.bairro !== null
          ) {
            this.form.patient.address.neighborhood = searchCep.bairro
              ? searchCep.bairro?.toUpperCase()
              : "";
          }

          this.form.patient.address.stateId = searchCep.est_id;
          this.form.patient.address.state = searchCep.est_sigla;
          this.$refs.state.forceSelection({
            est_id: searchCep.est_id,
            est_sigla: searchCep.est_sigla,
          });

          this.form.patient.address.numberAddress = null;
          this.form.patient.address.complement = null;
        } else {
          this.$toaster.warning("CEP não encontrado");
          return false;
        }
        this.hasState = !!searchCep.est_sigla;
        this.hasCity = !!searchCep.mun_nome;
        this.hasPublicPlace = !!searchCep.tlg_nome;
        this.hasAddress = !!searchCep.logradouro;
        this.hasNeighborhood = !!searchCep.bairro;

        return true;
      } catch (pErr) {
        this.$toaster.warning("CEP não encontrado");
      } finally {
        this.$loader.finish();
      }
    },
    validateCns(pValue, pErrors) {
      return RgInputSuscardHelper.checkDigit(pValue, pErrors);
    },
    loadDataFromUnregisteredPatient(pData) {
      const hasObjectPatient =
        pData && pData.source && Object.keys(pData.source).length > 0;

      let data = pData;
      if (hasObjectPatient) {
        data = pData.source;
      }
      if (data.bof_cns !== "000000000000000") {
        this.form.patient.patientCns = data.bof_cns;
      }
      const hasBofCep = data.bof_cep && data.bof_cep.length > 0;

      if (hasBofCep) {
        this.form.patient.address.cep = data.bof_cep;
        this.fillAddress(data.bof_cep, data);
      }

      this.form.patient.patientName = data.bof_nome;
      this.form.patient.patientSex = data.bof_sexo;
      this.form.patient.birthDate = this.$utils.date.BrazilianDateFormat(
        data.bof_data_nascimento,
      );

      if (data.bof_cod_raca !== this.RACE_NOT_INFORMED) {
        this.$refs.raceColor.forceSelect(data.bof_cod_raca);
        this.form.patient.raceColorCode = data.bof_cod_raca;
      }

      this.form.patient.ethnicity = Number(data.bof_cod_etnia);
      if (data?.bof_cod_nacionalidade !== this.NATIONALITY_NOT_INFORMED) {
        this.form.patient.nationalityCode = data?.bof_cod_nacionalidade;
        const nationality = data?.nacionalidade.split("-");

        this.$refs.nationality.forceSelection({
          nac_codigo: nationality[0],
          nac_nome: nationality[1],
        });
      }
      this.form.patient.email = data.bof_email;

      this.$refs.phone.fillValueById(data.bof_tel, data.bof_tel_ddd, true);

      this.$refs.ethnicityAb.forceSelection({
        ens_id: data.ens_id,
        ens_codigo_ab: data.ens_codigo_ab,
        ens_descricao: data.ens_descricao,
      });

      if (data.bof_cep) {
        this.form.patient.address.cep = data.bof_cep;
        this.fillAddress(data.bof_cep, data);
      }
    },
    confirmFormPatient() {
      this.typeFormClear = "patient";
      this.showModalConfirmFieldClearing = true;
    },
    confirmFormProcedure() {
      this.typeFormClear = "procedure";
      this.showModalConfirmFieldClearing = true;
    },
    closeConfirmFieldClearing() {
      this.showModalConfirmFieldClearing = false;
    },
    cleanFieldsValidationAddress() {
      const fieldsRefs = [
        "cep",
        "place",
        "address",
        "neighborhood",
        "city",
        "state",
        "numberField",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cleanFieldsValidationEmployee() {
      const fieldsRefs = ["employee", "employeeCBO"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cleanFieldsValidationPatient() {
      const fieldsRefs = [
        "patientCns",
        "patientName",
        "gender",
        "birthdayDate",
        "raceColor",
        "ethnicity",
        "nationality",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });

      this.cleanFieldsValidationAddress();
    },
    cleanFieldsValidationProcedure() {
      const fieldsRefs = [
        "service",
        "classification",
        "serviceDate",
        "procedure",
        "quantity",
        "cid",
        "authorization",
        "service",
        "classification",
        "serviceCharacter",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cleanFieldsValidation() {
      this.cleanFieldsValidationEmployee();
      this.cleanFieldsValidationPatient();
      this.cleanFieldsValidationProcedure();
    },
    cleanAddress() {
      this.form.patient.address = {
        city: null,
        cityId: null,
        cityCode: null,
        publicId: null,
        publicPlace: null,
        publicPlaceCode: null,
        neighborhood: null,
        state: null,
        stateId: null,
        name: null,
      };
    },
    cleanPatient() {
      this.form.patient = {
        patientCns: null,
        patientName: null,
        patientSex: null,
        birthDate: null,
        raceColor: null,
        raceColorCode: null,
        ethnicity: null,
        nationality: null,
        nationalityCode: null,
        address: {
          cep: null,
        },
        phone: {
          ddd: {
            dddId: null,
            dddCode: null,
          },
          phoneId: null,
          phonePersonId: null,
          phoneDddId: null,
          phoneNumber: null,
        },
        email: null,
      };
      this.ddd = null;
      this.cleanFieldsValidationPatient();
      this.$refs.phone.cleanPhone();
    },
    cleanInfoProcedure() {
      this.form.service = null;
      this.form.classification = null;
      this.form.classificationCode = null;
      this.form.cid = null;
      this.form.cidCode = null;
      this.form.quantity = 1;
    },
    cleanProcedure() {
      this.form.procedureCode = null;
      this.form.procedure = null;
      this.form.service = null;
      this.form.classification = null;
      this.form.classificationCode = null;
      this.form.cid = null;
      this.form.cidCode = null;
    },
    cleanFormProcedure() {
      this.form.serviceDate = null;
      this.form.procedureCode = null;
      this.form.procedure = null;
      this.form.quantity = 1;
      this.form.quantityMax = 9999;
      this.form.cnpj = null;
      this.form.service = null;
      this.form.classification = null;
      this.form.classificationCode = null;
      this.form.cid = null;
      this.form.cidCode = null;
      this.form.character = null;
      this.form.numberAuthorization = null;
      this.disabledService = true;
      this.disabledClassification = true;
      this.disabledCid = true;
      this.cnsRequired = false;
      this.cidRequired = false;
      this.serviceCharacterRequired = false;

      this.isServiceRequired = false;
      this.isClassificationRequired = false;

      this.hasMandatoryService = false;

      this.cleanFieldsValidationProcedure();
    },
    cleanForm() {
      if (this.typeFormClear === "patient") {
        this.cleanPatient();
      } else if (this.typeFormClear === "procedure") {
        this.cleanFormProcedure();
      } else {
        this.form = this.$utils.obj.DeepCopy(FORM_REGISTER);
        this.cleanPatient();
        this.cleanFormProcedure();
        this.cleanFieldsValidation();
      }
      this.form.unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.typeFormClear = null;
    },
    goBack() {
      this.$store.commit("Billing/BPAI/RESET_EMPLOYEE_REGISTER_BPAI");
      this.$router.push({ name: "billing.bpa.bpa-i" });
    },
  },
};
</script>
