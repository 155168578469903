import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation HistoryImportSigtapBdsia(
    $historyImport: HistoryImportSigtapBdsiaInputType
  ) {
    HistoryImportSigtapBdsia(historyImport: $historyImport) {
      hsb_competencia_bdsia
      hsb_competencia_sigtap
      hsb_arquivo_bdsia
      hsb_arquivo_sigtap
      data_importacao
    }
  }
`;

export default async ({ commit }, { variables }) => {
  const { data } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data;
};
