import FilterQueue from "./FilterQueue";
import SelectedQueueExternalHospitalizationData from "./SelectedQueueExternalHospitalizationData";
import FilterExternallySchedule from "./FilterExternallySchedule";
import FilterPatientExclude from "./FilterPatientExclude";
import FilterSchedulePatientList from "./FilterSchedulePatientList";

export default {
  ...FilterQueue,
  ...SelectedQueueExternalHospitalizationData,
  ...FilterExternallySchedule,
  ...FilterPatientExclude,
  ...FilterSchedulePatientList,
};
