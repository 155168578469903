<template lang="html">
  <svg
    id="Layer_1"
    enable-background="new 0 0 504.711 504.711"
    height="512"
    viewBox="0 0 504.711 504.711"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m310.94 213.523v-104.196c0-8.836-7.163-16-16-16h-140.049v-51.586c0-14.117-17.038-21.321-27.163-11.462l-122.893 119.683c-6.454 6.285-6.439 16.654 0 22.925l122.893 119.685c10.112 9.847 27.163 2.669 27.163-11.462v-51.586h140.049c8.837-.001 16-7.165 16-16.001z"
    />
    <path
      d="m499.876 331.841-122.892-119.685c-10.112-9.847-27.163-2.669-27.163 11.462v51.586h-140.049c-8.837 0-16 7.164-16 16v104.196c0 8.836 7.163 16 16 16h140.049v51.586c0 14.161 17.098 21.263 27.163 11.462l122.893-119.684c6.454-6.284 6.439-16.652-.001-22.923z"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowExchange",
};
</script>
