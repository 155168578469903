import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExamFormSchedule from "$exam/submodules/schedule/views/exam-form-schedule/ExamFormSchedule";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/per-periods/form",
  name: "exam.schedule.per-periods.form",
  components: {
    default: ExamFormSchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consultas", link: OpenMenuModule("Exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      {
        label: "Agendamentos por Período",
        link: "/exam/schedule/per-periods",
      },
      { label: "Agendar" },
    ],
  },
};
