<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchRaceCor from "./action/SearchRaceCor";

export default {
  name: "RgComboboxRaceCor",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Raça/Cor",
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return { mData: [] };
  },
  methods: {
    async doSearch() {
      this.mData = [];

      const data = await SearchRaceCor();
      const NAO_INFORMADO = 6;

      const filterRace = data.filter((item) => {
        return item.etn_id !== NAO_INFORMADO;
      });

      filterRace.forEach((item) => {
        this.mData.push(item);
      });

      return this.mData;
    },
    formatItem(item) {
      return {
        id: item.etn_id,
        value: item.etn_codigo_sisaih,
        text: item.etn_nome,
      };
    },
  },
};
</script>
