<template>
  <Modulebox title="Unificar Cadastros" class="unify-patient-register">
    <div class="body">
      <Tooltip
        startOpen
        clickable
        class="tooltip"
        message=" Selecione o cadastro que será utilizado como base para realizar a
            unificação"
      >
        <IconLightBulbHelper slot="icon" hollow />
      </Tooltip>
      <div class="top-buttons">
        <RgLessButton
          :disabled="disableRemoveButton || disabledByModal"
          @click="removePatientList(selectedPatient)"
        />
        <RgPersonCardButton
          :disabled="!hasSelectedPatient || disabledByModal"
          title="Cadastro do Paciente"
          @click="patientRegister"
        />
        <RgHistoryButton
          :disabled="!hasSelectedPatient || disabledByModal"
          title="Histórico de Atendimentos"
          @click="patientCareHistory"
        />
      </div>
      <div
        v-for="(item, index) in mutableListPatient"
        :key="item.pes_id"
        :class="{ selected: selectedPatient === index }"
        class="list-patient"
        @click="getPatient(item, index)"
      >
        <div class="icon">
          <PatientType :sex="patientSex(item)" class="patient-type" />
        </div>

        <div class="column">
          <span class="ellipsis">
            Nome: <b>{{ item.pes_nome }}</b>
          </span>
          <span>
            Nome da Mãe: <b>{{ item.pes_mae }}</b>
          </span>
        </div>

        <div class="column">
          <span>
            Data de Nascimento: <b>{{ item.pes_nascimento }}</b>
          </span>
          <span>
            Prontuário Único:
            <b>{{
              (item.patient && item.patient.pac_prontuario_unico) ||
              "Não informado"
            }}</b>
          </span>
        </div>

        <div class="column">
          <span>
            CPF:
            <b>{{ (item.cpf && item.cpf.cpf_numero) || "Não informado" }}</b>
          </span>
          <span>
            CNS:
            <b>{{ (item.cns && item.cns.crs_numero) || "Não informado" }}</b>
          </span>
        </div>
      </div>

      <FormBase
        v-if="hasSelectedPatient"
        title="Selecionar Dados para Unificação"
        class="unify-form"
      >
        <Tooltip
          message="Selecione os dados válidos que serão mantidos no cadastro base. Os demais serão descartados"
          startOpen
          clickable
          class="tooltip-form"
        >
          <IconLightBulbHelper slot="icon" hollow />
        </Tooltip>

        <div class="unify-list">
          <div class="header">
            <div class="item">
              <span>Nome</span>
              <span>Data de Nascimento</span>
              <span>Nome da Mãe</span>
              <span>Prontuário da Unidade</span>
              <span>CNS</span>
              <span>CPF</span>
              <span>PIS</span>
            </div>
          </div>

          <div class="body">
            <div
              v-for="item in mutableListPatient"
              :key="item.pes_id"
              class="item"
              :disabled="disabledByModal"
            >
              <div class="patientName" :title="item.pes_nome">
                <input
                  class="radio"
                  type="radio"
                  name="patientName"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  @change="radioPatientName(item.pes_nome)"
                />
                <span class="ellipsis">{{ item.pes_nome }}</span>
              </div>

              <div class="data">
                <input
                  type="radio"
                  class="radio"
                  name="birthDate"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  @change="radioBirthDate(item.pes_nascimento)"
                />
                <span>{{ item.pes_nascimento }}</span>
              </div>

              <div class="motherName" :title="item.pes_mae">
                <input
                  class="radio"
                  type="radio"
                  name="motherName"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  @change="radioMotherName(item.pes_mae)"
                />
                <span class="ellipsis">{{ item.pes_mae }}</span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="unitRecordNumber"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  @change="radioUnitRecordNumber(item)"
                />
                <span>{{
                  fillunitRecordNumbers(item) || "Não informado"
                }}</span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="cnsNumber"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  :disabled="hasCnsError(item)"
                  @change="radioCnsNumber(item.cns && item.cns.crs_numero)"
                />
                <span>{{
                  (item.cns && item.cns.crs_numero) || "Não informado"
                }}</span>
                <span>
                  <RgValidationAlert
                    v-show="hasCnsError(item)"
                    :alert="hasCnsError(item)"
                    class="rg-input--icon"
                  />
                </span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="cpfNumber"
                  :value="item.cpf && item.cpf_numero"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  :disabled="hasCpfError(item)"
                  @change="radioCpfNumber(item.cpf && item.cpf.cpf_numero)"
                />
                <span
                  >{{ (item.cpf && item.cpf.cpf_numero) || "Não informado" }}
                </span>
                <span>
                  <RgValidationAlert
                    v-show="hasCpfError(item)"
                    :alert="hasCpfError(item)"
                    class="rg-input--icon"
                  />
                </span>
              </div>

              <div class="data">
                <input
                  class="radio"
                  type="radio"
                  name="pisNumber"
                  :checked="item.pes_id === patientSelectedData.pes_id"
                  :disabled="item.pisPasep.pis_erro"
                  @change="radioPisNumber(item.pisPasep.pis_numero)"
                />
                <span>{{ item.pisPasep.pis_numero || "Não informado" }}</span>
                <span>
                  <RgValidationAlert
                    v-show="item.pisPasep.pis_erro"
                    :alert="item.pisPasep.pis_erro"
                    class="rg-input--icon"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </FormBase>
    </div>

    <div slot="footer" class="footer">
      <RgCancelButton medium :disabled="disabledByModal" @click="cancel" />
      <RgSaveButton
        title="Confirmar"
        label="Confirmar"
        medium
        :disabled="!hasSelectedPatient || disabledByModal"
        @click="openConfirmPatientUnification"
      />
    </div>

    <ModalPatientRecordsFoundBasicAttention
      :show="modalPatientRecordsFoundBasicAttention"
      :patient-id="unifiedPatientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :patientHasNoBondOrInactiveBond="patientHasNoBondOrInactiveBond"
      @close="closeModalPatientRecordsFoundBasicAttention"
      @save="closeModalPatientRecordsFoundBasicAttention"
    />

    <ModalConfirmPatientUnification
      :show="showConfirmPatientUnification"
      :patient-info="patientSelectedData || {}"
      :data-unification="form"
      @save="saveUnification"
      @close="closeConfirmPatientUnification"
    />
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";
import { Modulebox, FormBase } from "~tokio/foundation";
import { BrazilianDateToDatabase, DateNow } from "~utils/date";
import PatientType from "$person/patient/component/icon/PatientType";
import ModalConfirmPatientUnification from "$person/patient/component/modal/modal-confirm-patient-unification/ModalConfirmPatientUnification";
import ModalPatientRecordsFoundBasicAttention from "$person/patient/component/modal/modal-patient-records-found-basic-attention/ModalPatientRecordsFoundBasicAttention";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import Pis from "$person/common/components/input/rg-input-pis/utils/RgInputPisHelper";
import Cpf from "$person/common/components/input/rg-input-cpf/utils/RgInputCpfHelper";
import RgInputSuscardHelper from "~tokio/primitive/input/rg-input-suscard/RgInputSuscardHelper";

import {
  Tooltip,
  IconLightBulbHelper,
  RgCancelButton,
  RgSaveButton,
  RgLessButton,
  RgPersonCardButton,
  RgHistoryButton,
} from "~tokio/primitive";

export default {
  name: "UnifyPatientRegister",
  components: {
    Tooltip,
    Modulebox,
    FormBase,
    IconLightBulbHelper,
    PatientType,
    RgCancelButton,
    RgSaveButton,
    RgLessButton,
    RgPersonCardButton,
    RgHistoryButton,
    ModalConfirmPatientUnification,
    ModalPatientRecordsFoundBasicAttention,
    RgValidationAlert,
  },

  props: {},

  data() {
    return {
      mutableListPatient: [],
      patientSelectedData: {},
      selectedPatient: null,
      dataPatientBasicAttention: {},
      individualRegistrationList: [],
      patientHasBondBasicAttention: false,
      unifiedPatientId: null,
      form: {
        pes_nome: null,
        pes_nascimento: null,
        pes_mae: null,
        cpf_numero: null,
        crs_numero: null,
        ppr_numero: null,
        ppr_id: null,
        pis_numero: null,
        pac_prontuario_unico: null,
        ppr_id_pacientes: null,
        ppr_id_unidades_saude: null,
      },
      showConfirmPatientUnification: false,
      modalPatientRecordsFoundBasicAttention: false,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      basicAttentionIntegration: "Login/GET_BASIC_ATTENTION_INTEGRATION",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),
    disabledByModal() {
      return this.showConfirmPatientUnification;
    },
    hasSelectedPatient() {
      return this.selectedPatient !== null;
    },
    disableRemoveButton() {
      return !this.hasSelectedPatient || this.mutableListPatient.length <= 2;
    },
    patientBasicAttentionId() {
      return this.dataPatientBasicAttention?.pae_id;
    },
    patientHasNoBondOrInactiveBond() {
      return (
        !this.patientHasBondBasicAttention ||
        this.patientHasInactiveBondBasicAttention
      );
    },
    patientHasInactiveBondBasicAttention() {
      const INATIVO = 0;
      return this.dataPatientBasicAttention?.pae_ativo === INATIVO;
    },
    hasBasicAttentionIntegration() {
      return (
        !!this.basicAttentionIntegration &&
        !!this.currentUnitHealth?.unidades_saude_integracao_ab
      );
    },
  },

  destroyed() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
  },

  async mounted() {
    this.mutableListPatient = this.$store.getters[
      "Person/Patient/GET_SELECTED_PATIENTS_UNIFY"
    ];

    if (this.lastPersonIdSaved) {
      this.updatePatientEdited(this.lastPersonIdSaved);
    }
    this.validateDocuments();
  },

  methods: {
    fillunitRecordNumbers(pValue) {
      const unitRecordNumberId = this.existsUnitRecordNumberPatient(pValue);
      return unitRecordNumberId !== null && unitRecordNumberId.length > 0
        ? unitRecordNumberId[0].ppr_numero
        : null;
    },

    existsUnitRecordNumberPatient(pValue) {
      const unitRecordNumberRows = pValue.patient?.record_numbers?.rows;
      if (unitRecordNumberRows?.length > 0) {
        const unitRecordNumberList = [];
        unitRecordNumberRows.forEach((item) => {
          if (item.ppr_id_unidades_saude === this.unitHealthId) {
            unitRecordNumberList.push({
              ppr_numero: item.ppr_numero,
              ppr_id: item.ppr_id,
              ppr_id_pacientes: item.ppr_id_pacientes,
              ppr_id_unidades_saude: item.ppr_id_unidades_saude,
            });
          }
        });
        return unitRecordNumberList;
      }
      return null;
    },

    radioPatientName(pValue) {
      this.form.pes_nome = pValue || null;
    },

    radioBirthDate(pValue) {
      this.form.pes_nascimento = pValue || null;
    },

    radioMotherName(pValue) {
      this.form.pes_mae = pValue || null;
    },

    radioUnitRecordNumber(pValue) {
      const unitRecordNumberInfo =
        this.existsUnitRecordNumberPatient(pValue) || null;
      if (unitRecordNumberInfo?.length > 0) {
        this.form.ppr_id = unitRecordNumberInfo[0].ppr_id || null;
        this.form.ppr_numero = unitRecordNumberInfo[0].ppr_numero || null;
        this.form.ppr_id_pacientes =
          unitRecordNumberInfo[0].ppr_id_pacientes || null;
        this.form.ppr_id_unidades_saude =
          unitRecordNumberInfo[0].ppr_id_unidades_saude || null;
      } else {
        this.form.ppr_id = null;
        this.form.ppr_numero = null;
        this.form.ppr_id_pacientes = null;
        this.form.ppr_id_unidades_saude = null;
      }
    },

    radioCnsNumber(pValue) {
      this.form.crs_numero = pValue || null;
    },

    hasCnsError(pValue) {
      return pValue && pValue.cns?.cns_erro;
    },

    hasCpfError(pValue) {
      return pValue && pValue.cpf?.cpf_erro;
    },

    radioCpfNumber(pValue) {
      this.form.cpf_numero = pValue || null;
    },

    radioPisNumber(pValue) {
      this.form.pis_numero = pValue || null;
    },

    setColumnValue(item, index) {
      return item.concat(index);
    },

    getPatient(pItem, pIndex) {
      const isSameLine = this.selectedPatient === pIndex;
      if (isSameLine) {
        this.selectedPatient = null;
        this.patientSelectedData = null;
        this.form.pac_prontuario_unico = null;
        this.$emit("getLine", null);
      } else {
        this.selectedPatient = pIndex;
        this.patientSelectedData = pItem;
        this.form.pac_prontuario_unico =
          pItem.patient && pItem.patient.pac_prontuario_unico;
        this.setForm();
      }
    },

    setForm() {
      this.radioPatientName(this.patientSelectedData.pes_nome);
      this.radioBirthDate(this.patientSelectedData.pes_nascimento);
      this.radioMotherName(this.patientSelectedData.pes_mae);
      this.radioCnsNumber(this.patientSelectedData.cns?.crs_numero);
      this.radioCpfNumber(this.patientSelectedData.cpf?.cpf_numero);
      this.radioPisNumber(this.patientSelectedData.pisPasep.pis_numero);
      this.radioUnitRecordNumber(this.patientSelectedData);
    },

    formatListRecordNumber(pValue, basePatient) {
      const unitRecordNumberList = [];
      if (!basePatient) {
        const unitRecordNumberRows = pValue.patient?.record_numbers?.rows;
        if (
          unitRecordNumberRows !== null &&
          unitRecordNumberRows !== undefined &&
          unitRecordNumberRows.length > 0
        ) {
          unitRecordNumberRows.forEach((item) => {
            unitRecordNumberList.push({
              ppr_id: item.ppr_id,
              ppr_id_pacientes: item.ppr_id_pacientes,
              ppr_id_unidades_saude: item.ppr_id_unidades_saude,
              ppr_numero: item.ppr_numero,
            });
          });
        } else {
          unitRecordNumberList.push({
            ppr_id: null,
            ppr_id_pacientes: null,
            ppr_id_unidades_saude: null,
            ppr_numero: null,
          });
        }
      } else {
        const unitRecordNumberPatientSelected = this.existsUnitRecordNumberPatient(
          this.patientSelectedData,
        );
        unitRecordNumberList.push({
          ppr_id:
            unitRecordNumberPatientSelected?.length > 0
              ? pValue.ppr_id !== unitRecordNumberPatientSelected[0].ppr_id
                ? unitRecordNumberPatientSelected[0].ppr_id
                : pValue.ppr_id
              : null,
          ppr_id_pacientes: this.patientSelectedData.patient
            ? this.patientSelectedData.patient.pac_id
            : null,
          ppr_id_unidades_saude: pValue.ppr_id_unidades_saude,
          ppr_numero: pValue.ppr_numero,
        });
      }
      return unitRecordNumberList;
    },

    async saveUnification() {
      try {
        this.closeConfirmPatientUnification();
        this.$loader.start();
        const variables = [];
        const filterUnitRecordNumberPatientUnit = this.fillunitRecordNumbers(
          this.patientSelectedData,
        );

        const basePatient = {
          pes_id: this.patientSelectedData.pes_id,
          pes_nome: this.form.pes_nome,
          pes_nascimento: BrazilianDateToDatabase(this.form.pes_nascimento),
          pes_mae: this.form.pes_mae,
          cns: {
            crs_id: this.patientSelectedData.cns
              ? this.patientSelectedData.cns.crs_id
              : null,
            crs_numero: this.form.crs_numero,
          },
          cpf: {
            cpf_id: this.patientSelectedData.cpf
              ? this.patientSelectedData.cpf.cpf_id
              : null,
            cpf_numero: this.form.cpf_numero,
          },

          pisPasep: {
            pis_id: this.patientSelectedData.pisPasep
              ? this.patientSelectedData.pisPasep.pis_id
              : null,
            pis_numero: this.form.pis_numero,
          },
          pac_id: this.patientSelectedData.patient
            ? this.patientSelectedData.patient.pac_id
            : null,
          ...(filterUnitRecordNumberPatientUnit !== this.form.ppr_numero && {
            recordNumber: this.formatListRecordNumber(this.form, true),
          }),
          basePatient: true,
        };

        variables.push(basePatient);

        const filterNotBasePatients = (patient) =>
          patient.pes_id !== this.patientSelectedData.pes_id;
        this.mutableListPatient.filter(filterNotBasePatients).map((patient) => {
          const notBasePatient = {
            pes_id: patient.pes_id,
            pac_id: patient.patient?.pac_id,
            cns: {
              crs_id: patient.cns ? patient.cns.crs_id : null,
              crs_numero: null,
            },
            cpf: {
              cpf_id: patient.cpf ? patient.cpf.cpf_id : null,
              cpf_numero: null,
            },
            pisPasep: {
              pis_id: patient.pisPasep ? patient.pisPasep.pis_id : null,
              pis_numero: null,
            },
            recordNumber: this.formatListRecordNumber(patient, false),
            basePatient: false,
          };
          variables.push(notBasePatient);
        });

        const response = await this.$store.dispatch(
          "Person/Patient/UNIFY_PATIENT",
          {
            patients: variables,
          },
        );

        const hasSuccess = response && response.success;
        if (hasSuccess) {
          this.unifiedPatientId = response.pac_id;
          this.$toaster.success("Operação realizada com sucesso");

          if (this.hasBasicAttentionIntegration) {
            await this.searchPatientBondBasicAttention();

            const patientCpf = this.form.cpf_numero;
            const patientCns = this.form.crs_numero;

            if (patientCpf || patientCns) {
              await this.getIndividualRegistration();
            } else {
              if (this.dataPatientBasicAttention?.pae_ativo) {
                this.inactivatePatientBondBasicAttention();
              }
            }

            if (this.individualRegistrationList.length > 0) {
              this.openModalPatientRecordsFoundBasicAttention();
            } else {
              this.$router.push({ name: `person.patient.unify` });
            }
          } else {
            this.$router.push({ name: `person.patient.unify` });
          }

          this.$store.commit("Person/Patient/RESET_FILTER_UNIFY_PATIENT");
          this.$store.commit("Person/Patient/UNSELECT_PATIENTS_UNIFY");
        } else {
          this.$toaster.error(response.message);
        }
      } catch (pErr) {
        this.$toaster.error(pErr.message || pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async inactivatePatientBondBasicAttention() {
      const INATIVO = 0;
      const dataAbEsusInactive = {
        pae_id: this.dataPatientBasicAttention.pae_id,
        pae_ativo: INATIVO,
        pae_ultima_alteracao: DateNow(),
      };
      try {
        await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
          abEsus: dataAbEsusInactive,
        });
      } catch (err) {
        this.$toaster.error("Erro ao inativar o vínculo", err);
      }
    },

    validateDocuments() {
      let pErrs = [];
      this.mutableListPatient.forEach((patient) => {
        pErrs = [];
        const cpfNUmber = patient.cpf && patient.cpf.cpf_numero;
        if (!this.validateCpf(cpfNUmber, pErrs)) {
          patient.cpf.cpf_erro = pErrs;
        }
        pErrs = [];
        const cnsNUmber = patient.cns && patient.cns.crs_numero;
        if (!this.validateCns(cnsNUmber, pErrs)) {
          patient.cns.cns_erro = pErrs;
        }
        pErrs = [];
        const pisNumber = patient.pisPasep && patient.pisPasep.pis_numero;
        if (!this.validatePis(pisNumber, pErrs)) {
          patient.pisPasep.pis_erro = pErrs;
        }
      });
    },

    validateCpf(pValue, pErrs) {
      if (!pValue) return true;
      return Cpf.checkDigit(pValue, pErrs);
    },

    validateCns(pValue, pErrs) {
      if (!pValue) return true;
      return RgInputSuscardHelper.checkDigit(pValue, pErrs);
    },

    validatePis(pValue, pErrs) {
      if (!pValue) return true;
      return Pis.checkDigit(pValue, pErrs);
    },

    validateAllDocuments() {
      if (this.form.cpf_numero) {
        const cpfFilter = (patient) =>
          patient.cpf && patient.cpf.cpf_numero === this.form.cpf_numero;
        const cpfSelected = this.mutableListPatient.filter(cpfFilter);

        const hasCpfError =
          cpfSelected &&
          cpfSelected.length > 0 &&
          cpfSelected[0].cpf &&
          cpfSelected[0].cpf.cpf_erro;

        if (hasCpfError) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
      }

      if (this.form.crs_numero) {
        const crsFilter = (patient) =>
          patient.cns && patient.cns.crs_numero === this.form.crs_numero;
        const cnsSelected = this.mutableListPatient.filter(crsFilter);

        const hasCnsError =
          cnsSelected &&
          cnsSelected.length > 0 &&
          cnsSelected[0].cns &&
          cnsSelected[0].cns.cns_erro;

        if (hasCnsError) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
      }

      if (this.form.pis_numero) {
        const pisFilter = (patient) =>
          patient.pisPasep &&
          patient.pisPasep.pis_numero === this.form.pis_numero;
        const pisSelected = this.mutableListPatient.filter(pisFilter);

        const hasPisError =
          pisSelected &&
          pisSelected.length > 0 &&
          pisSelected[0].pisPasep &&
          pisSelected[0].pisPasep.pis_erro;

        if (hasPisError) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
      }
      return true;
    },

    patientRegister() {
      const personId = this.patientSelectedData.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", personId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", personId);

      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.patient-record`,
      });
    },

    removePatientList(pIndex) {
      this.mutableListPatient.splice(pIndex, 1);
      this.selectedPatient = null;
    },

    patientSex(pItem) {
      return pItem?.pes_id_sexos ? pItem.pes_id_sexos : null;
    },

    patientCareHistory() {
      const patientInfo = this.patientSelectedData;
      this.$router.push({
        name: "person.patient.unify.register.patient-care-history",
        params: patientInfo,
      });
    },

    openConfirmPatientUnification() {
      if (!this.validateAllDocuments()) return;
      this.showConfirmPatientUnification = true;
    },

    closeConfirmPatientUnification() {
      this.showConfirmPatientUnification = false;
    },

    async updatePatientEdited(pPersonId) {
      try {
        if (pPersonId) {
          this.$loader.start();
          const data = await this.$store.dispatch(
            "Person/Patient/GET_PATIENT_BY_PESID_FOR_UNIFY",
            { pesId: pPersonId, getRows: true },
          );

          const index = this.mutableListPatient.findIndex(
            (item) => item.pes_id === data.pes_id,
          );

          if (index !== -1) {
            const arrayAux = this.mutableListPatient;
            this.mutableListPatient = [];

            arrayAux[index] = data;

            this.mutableListPatient = arrayAux;
          }

          this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
        }
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    openModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = true;
    },

    closeModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
      this.$router.push({ name: `person.patient.unify` });
    },

    async searchPatientBondBasicAttention() {
      if (this.unifiedPatientId) {
        try {
          const dataAbEsus = await this.$store.dispatch(
            "Person/Patient/SEARCH_AB_ESUS",
            { pacId: this.unifiedPatientId },
          );

          if (dataAbEsus) {
            this.patientHasBondBasicAttention = true;
          }

          this.dataPatientBasicAttention = dataAbEsus || {};
        } catch (err) {
          this.$toaster.error("Erro ao buscar vínculo", err);
        }
      }
    },

    async getIndividualRegistration() {
      const patientCpf = this.form.cpf_numero;
      const patientCns = this.form.crs_numero;
      const patientAbEsusIsActive =
        this.dataPatientBasicAttention?.pae_ativo || false;

      if (patientCpf || patientCns) {
        const variables = {
          ...(patientAbEsusIsActive && {
            baseCardId: this.dataPatientBasicAttention?.pae_ficha_base,
          }),
          cpf: patientCpf,
          cns: patientCns,
        };

        try {
          this.$loader.start();
          const result = await this.$store.dispatch(
            "Person/Patient/GET_AB_INDIVIDUAL_REGISTRATION",
            variables,
          );
          this.individualRegistrationList = result?.rows;
          return this.individualRegistrationList;
        } catch {
          return null;
        } finally {
          this.$loader.finish();
        }
      }

      return null;
    },

    cancel() {
      this.$router.push({ name: "person.patient.unify" });
    },
  },
};
</script>
