<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M339.129,261.148c-7.669-23.893-25.686-44.203-50.732-57.186c-3.672-1.904-8.203-0.471-10.109,3.207    c-1.906,3.677-0.471,8.204,3.206,10.11c21.506,11.148,36.901,28.356,43.351,48.453c1.021,3.183,3.968,5.21,7.14,5.21    c0.76,0,1.532-0.116,2.294-0.361C338.222,269.315,340.394,265.092,339.129,261.148z"
            fill="#ffffff"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M406.27,438.275h-14.69V409.33c0-4.142-3.357-7.5-7.5-7.5h-13.51V284.64c0-63.174-51.396-114.57-114.57-114.57    c-30.592,0-59.362,11.917-81.015,33.559c-21.639,21.649-33.556,50.42-33.556,81.012v11.59c0,4.142,3.357,7.5,7.5,7.5    s7.5-3.358,7.5-7.5v-11.59c0-26.586,10.357-51.591,29.162-70.405c18.817-18.807,43.821-29.165,70.408-29.165    c54.903,0,99.57,44.667,99.57,99.57v117.19h-77.53v-23.332c8.546-5.051,15.37-12.897,19.178-22.667    c1.504-3.859-0.405-8.208-4.265-9.711c-3.86-1.505-8.208,0.404-9.712,4.264c-2.93,7.519-8.528,13.329-15.475,16.562    c-0.132,0.053-0.261,0.111-0.39,0.171c-3.848,1.725-8.09,2.68-12.526,2.68c-16.801,0-30.469-13.668-30.469-30.469    c0-16.801,13.668-30.469,30.469-30.469c11.887,0,22.766,6.995,27.717,17.819c1.722,3.767,6.172,5.425,9.939,3.701    c3.767-1.723,5.424-6.173,3.701-9.939c-7.385-16.147-23.618-26.581-41.357-26.581c-25.071,0-45.469,20.397-45.469,45.469    c0,16.605,8.952,31.153,22.278,39.089v23.412h-75.23v-76.06c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v76.06h-13.51    c-4.143,0-7.5,3.358-7.5,7.5v28.945h-14.69c-4.143,0-7.5,3.358-7.5,7.5V504.5c0,4.142,3.357,7.5,7.5,7.5h174.497    c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5H113.229v-43.725h14.594c0.032,0,0.064,0.005,0.097,0.005    c0.033,0,0.064-0.004,0.097-0.005h255.967c0.032,0,0.064,0.005,0.097,0.005c0.033,0,0.064-0.004,0.097-0.005h14.593V497h-88.996    c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h96.496c4.143,0,7.5-3.358,7.5-7.5v-58.725    C413.77,441.633,410.412,438.275,406.27,438.275z M246.659,384.044h0.001c2.659,0.486,5.393,0.753,8.19,0.753    c2.788,0,5.524-0.254,8.189-0.733v17.766h-16.38V384.044z M376.58,438.275H135.42V416.83h241.16V438.275z"
            fill="#ffffff"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M256,0c-4.143,0-7.5,3.358-7.5,7.5v127.817c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V7.5C263.5,3.358,260.143,0,256,0    z"
            fill="#ffffff"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M217.648,141.665l-62.16-111.684c-2.015-3.619-6.581-4.921-10.201-2.906c-3.619,2.014-4.92,6.582-2.906,10.201    L204.54,148.96c1.372,2.464,3.927,3.854,6.561,3.854c1.234,0,2.485-0.305,3.641-0.948    C218.361,149.852,219.662,145.284,217.648,141.665z"
            fill="#ffffff"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M183.527,162.675L70.598,102.806c-3.658-1.941-8.2-0.546-10.139,3.113c-1.94,3.66-0.547,8.199,3.113,10.14l112.929,59.869    c1.119,0.594,2.321,0.875,3.506,0.875c2.688,0,5.287-1.449,6.633-3.988C188.58,169.155,187.187,164.616,183.527,162.675z"
            fill="#ffffff"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M366.715,27.075c-3.62-2.016-8.187-0.713-10.201,2.906l-62.16,111.684c-2.015,3.62-0.714,8.187,2.905,10.201    c1.155,0.643,2.406,0.948,3.641,0.948c2.634,0,5.188-1.39,6.561-3.854l62.16-111.684    C371.635,33.657,370.334,29.089,366.715,27.075z"
            fill="#ffffff"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M451.541,105.919c-1.94-3.659-6.48-5.052-10.139-3.113l-112.929,59.869c-3.66,1.94-5.054,6.48-3.113,10.14    c1.347,2.54,3.944,3.988,6.633,3.988c1.185,0,2.387-0.282,3.506-0.875l112.929-59.869    C452.088,114.119,453.481,109.579,451.541,105.919z"
            fill="#ffffff"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Emergencies",
};
</script>
