<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchEmployeeSectorOccupation from "./action/SearchEmployeeSectorOccupation";

export default {
  name: "RgSelectEmployeeSectorOccupation",
  extends: RgSelect,
  props: {
    sectorId: {
      required: true,
    },
    occupationId: {
      required: true,
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    sectorId() {
      this.doSearch();
    },
    valuesData() {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (!this.sectorId || !this.occupationId) return Promise.resolve([]);

      this.disableLoading = true;
      const data = await SearchEmployeeSectorOccupation({
        intIdSetor: this.sectorId,
        intIdOcupacao: this.occupationId,
      });

      this.valuesData = data.map((item) => {
        return { value: item.data, label: item.pes_nome };
      });
      this.disableLoading = false;
    },
  },
};
</script>
