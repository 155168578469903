<style src="./RgSelectDddInputPhone.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-smart-phone">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="error rg-input--alert" />
      </div>

      <div class="input-area rg-input--textbox-container">
        <select
          id="ddd"
          v-model="dddItem"
          class="select-ddd"
          :disabled="disabled"
          :class="{ 'on-error': hasError }"
        >
          <option :value="null">DDD</option>

          <option v-for="(item, idx) in ddds" :key="idx" :value="item">
            {{ item.ddd_codigo }}
          </option>
        </select>

        <input
          v-if="notAcceptCellphone"
          :id="id"
          v-model="telephone"
          v-mask="['####-####']"
          class="input rg-input--textbox"
          placeholder="0000-0000"
          autocomplete="off"
          :disabled="disabled"
          @blur="validateBlur"
          @change="change"
          @focus="checkFocus"
          @input="$emit('input', $event.target.value)"
        />
        <input
          v-else
          :id="id"
          v-model="telephone"
          v-mask="['####-####', '#####-####']"
          class="input rg-input--textbox"
          placeholder="00000-0000"
          autocomplete="off"
          :disabled="disabled"
          :class="{ 'on-error': hasError }"
          @blur="validateBlur"
          @change="change"
          @focus="checkFocus"
          @input="$emit('input', $event.target.value)"
        />
      </div>
    </RgFormBase>
  </div>
</template>
<script>
import {
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive/validation";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import { RgFormBase } from "~tokio/foundation/container";
import { mask } from "vue-the-mask";

import { SearchDdd } from "./action";

export default {
  name: "RgSelectDddInputPhone",
  components: {
    RgFormBase,
    RgValidationAlert,
  },

  directives: {
    mask,
  },

  mixins: [RgValidatorMixin, RgInputMixin],

  props: {
    label: {
      type: String,
      default: "Telefone",
    },
    change: {
      type: Function,
      default: () => {},
    },
    loadingValue: {
      type: Object,
    },
    notAcceptCellphone: { type: Boolean, default: false },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ddds: null,
      dddItem: null,
      telephone: null,
    };
  },

  computed: {
    hasError() {
      return this.error.length > 0;
    },
    validValue() {
      return this.telephone;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },

  watch: {
    dddItem(pValue) {
      if (!pValue) {
        this.telephone = null;
      }
      this.$emit("getDdd", pValue);
    },
    loadingValue: {
      handler(pValue) {
        if (pValue.phoneNumber !== "" && pValue.ddd.ddd_id) {
          this.dddItem = {
            ddd_id: pValue.ddd.ddd_id,
            ddd_codigo: pValue.ddd.ddd_code,
            __typename: "Ddd",
          };
          this.telephone = pValue.phoneNumber;
        }
      },
      deep: true,
    },
  },

  async mounted() {
    this.ddds = await SearchDdd();
  },

  methods: {
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },

    fillValue(pTel, pDdd) {
      this.dddItem = pDdd;
      this.telephone = pTel;

      if (!Array.isArray(pDdd)) {
        this.ddds.map((item) => {
          if (item.ddd_codigo === pDdd) {
            this.dddItem = item;
          }
        });
      }
    },

    async fillValueById(pTel, pDdd, byDddCode = false) {
      const ddds = this.ddds;

      if (!ddds) {
        const result = await SearchDdd();
        this.ddds = result;
      }

      this.dddItem = pDdd;
      this.telephone = pTel;

      if (!Array.isArray(pDdd)) {
        this.ddds.map((item) => {
          if (byDddCode) {
            if (item.ddd_codigo === pDdd) {
              this.dddItem = item;
            }
          } else {
            if (item.ddd_id === pDdd) {
              this.dddItem = item;
            }
          }
        });
      }
    },

    cleanPhone() {
      this.dddItem = null;
      this.telephone = null;
    },
  },
};
</script>
