<style src="./RgSuggestHospitalizationAih.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    ref="suggest"
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    title="Autorização de Internação Hospitalar"
    min="0"
    class="rg-suggest-hospitalization-aih"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="hospitalization-aih-item"
    >
      <div class="hospitalization-aih-row-info">
        <span class="aih">{{ formatAihNumber(item) }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchHospitalizationAih from "./action/SearchHospitalizationAih";

export default {
  name: "RgTypeaheadHospitalizationAih",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "AIH:",
    },
    min: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      captionRaw: "iaf_numero_aih - icf_nome",
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  methods: {
    doSearch() {
      const searchQuery = this.inputValue;

      if (Number.isInteger(+this.inputValue)) {
        return SearchHospitalizationAih({ iafNumeroAih: searchQuery });
      } else {
        return SearchHospitalizationAih({ icfNome: searchQuery });
      }
    },
    doFind(pValue) {
      return SearchHospitalizationAih(
        { iafNumeroAih: pValue },
        { findOne: true },
      );
    },
    formatingItem(pItem) {
      this.inputValue = this.formatAihNumber(pItem);
    },
    formatAihNumber(pItem) {
      const aihNumber =
        pItem.iaf_numero_aih.substr(0, pItem.iaf_numero_aih.length - 1) +
        "-" +
        pItem.iaf_numero_aih[pItem.iaf_numero_aih.length - 1];
      return `${aihNumber} - ${pItem.icf_nome}`;
    },
    validate() {
      this.$refs.suggest.validate();
    },
  },
};
</script>
