<style src="./AihPerPatient.scss" lang="scss" scoped></style>
<template>
  <div class="grid">
    <div class="selectinput">
      <RgSelectUnithealth
        id="uns-id-app"
        v-model="form.unsId"
        :rules="{ required: true }"
        permission="faturamento.relatoriosESUSng.Exibir"
        class="inputitem"
      />
    </div>
    <div class="selectinput">
      <RgInputMasked
        id="presentation-app"
        v-model="form.presentation"
        :mask="['BM/DDDD']"
        :tokens="tokens"
        :rules="{ fn: validatePeriod }"
        label="Apresentação"
        placeholder="mm/aaaa"
        class="inputitem"
      />
    </div>
    <div class="selectinput">
      <RgInputMasked
        id="medical-record-app"
        v-model="form.medicalRecord"
        :mask="['###############']"
        class="inputitem"
        label="Prontúario"
        placeholder="Digite o prontuário"
      />
    </div>
    <div class="selectinput">
      <RgInput
        id="name-app"
        v-model="form.name"
        class="inputitem"
        label="Nome do Paciente"
        placeholder="Digite o nome do paciente"
      />
    </div>
  </div>
</template>

<script>
import { RgInput, RgInputMasked, RgSelectUnithealth } from "~tokio/primitive";
import {
  ValidatePeriodBilling,
  MountPresentation,
} from "../../../common/service/";

export default {
  name: "AihPerPatient",
  components: {
    RgInput,
    RgInputMasked,
    RgSelectUnithealth,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "AIH por Paciente",
      form: {
        unsId: 0,
        presentation: "",
        medicalRecord: "",
        name: "",
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    printReportCss() {
      return (
        ".report-column-1-print { width: 200px; }" +
        ".report-column-2-print { width: 100px; }" +
        ".report-column-3-print { width: 200px; }" +
        ".report-column-4-print { width: 130px; }" +
        ".report-column-5-print { width: 130px; }" +
        ".report-column-6-print { width: 130px; }"
      );
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "AihPerPatient";
    },
  },
  watch: {
    "form.name"(pValue, pPrev) {
      if (this.form.name) {
        this.form.name = this.form.name.toUpperCase();
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "icf_nome" },
      { name: "Prontuário", key: "iaf_prontuario" },
      { name: "Número AIH", key: "iaf_numero_aih" },
      { name: "Valor Serviço Hospitalar", key: "valor_servico_hospitalar" },
      {
        name: "Valor Serviço Profissional",
        key: "valor_servico_profissional",
      },
      { name: "Total", key: "total" },
    ];
  },
  mounted() {
    this.form.unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.updatePresentationDate();
  },
  methods: {
    buildFilter() {
      return {
        unsId: this.form.unsId ? this.form.unsId : null,
        name: this.form.name ? this.form.name : null,
        presentation: this.form.presentation
          ? this.form.presentation.replace("/", "")
          : null,
        medicalRecord: this.form.medicalRecord ? this.form.medicalRecord : null,
        columnsToPrint: this.columnsToPrint,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_AIH_PER_PATIENT_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      this.form.presentation = MountPresentation(cmp);
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.form.presentation, pErrors);
    },
    clearFilter() {
      this.form = {
        unsId: 0,
        presentation: "",
        medicalRecord: "",
        name: "",
      };
    },
  },
};
</script>
