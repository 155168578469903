<style src="./UserProfile.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="user-profile" tabindex="0">
    <div class="user-profile--profile-photo">
      <!-- <img :src="avatar" /> -->
      <IconPerfil class="svg" />
    </div>
    <div class="user-profile--menu">
      <span class="user-profile--name">{{ username }}</span>
      <span class="user-profile--occupation">{{ occupation }}</span>

      <ul class="user-profile--menu-item">
        <li
          class="user-profile--action-helper"
          title="Obtenha atendimento para solucionar suas dúvidas e solicitações"
          @click.stop="help"
        >
          <div class="user-profile--helper">
            <IconHelp />
          </div>
          Suporte
        </li>

        <li
          class="user-profile--action-about"
          title="Sobre o Sistema"
          @click.stop="about"
        >
          <div class="user-profile--about-icon">
            <IconInfoHelperAbout />
          </div>
          Sobre o Sistema
        </li>

        <li
          class="user-profile--action-logoff"
          title="Sair do sistema"
          @click.stop="logoff"
        >
          <div class="user-profile--power-icon">
            <IconLogoff class="svg" />
          </div>
          Sair do Sistema
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import {
  IconLogoff,
  IconInfoHelperAbout,
  IconPerfil,
  IconHelp,
} from "~tokio/primitive/";

export default {
  name: "UserProfile",
  components: {
    IconLogoff,
    IconInfoHelperAbout,
    IconPerfil,
    IconHelp,
  },
  data() {
    return {
      toggleUserProfileOpen: true,
      occupation: "",
    };
  },
  computed: {
    username() {
      return this.$store.getters["Login/GET_USER_NAME"];
    },
    avatar() {
      return "/static/person-image/photo-default.png";
    },
  },
  created() {
    this.HELPDESK_DEFAULT = process.env.VUE_APP_HELPDESK_URL;
  },
  methods: {
    logoff() {
      this.$store.dispatch("Login/LOGOFF");
      this.$router.push({ name: "Login" });
    },

    about() {
      this.$router.push({
        name: "aboutsoftware",
      });
      localStorage.setItem(
        "exec-refresh-page-refreshLogin",
        JSON.stringify({ exec: "active", refresh: "active" }),
      );
    },

    help() {
      const url = this.HELPDESK_DEFAULT;
      window.open(url);
    },
  },
};
</script>
