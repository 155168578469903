import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFiltro: {
      ...variables,
    },
  };

  return AuthLegacyRequest.post(
    "faturamento/controller-atendimento/buscar-consultas-por-data-escala-atendimento-nova-busca",
    sendData,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
