<script>
import RgBaseSelectionMixin from "./RgBaseSelectionMixin";
export default {
  name: "RgBaseComboboxMixin",
  mixins: [RgBaseSelectionMixin],
  props: {
    singleSelection: {
      type: Boolean,
      default: true,
    },
    /**
     * Default value to be used when there is no item selected
     */
    defaultValue: {
      type: Boolean,
      default: null,
    },
    /**
     * Default text to be shown when there is no item selected
     */
    defaultText: {
      type: String,
      default: "Selecione",
    },
    /**
     * Value to be used when there is no data
     */
    emptyDefaultValue: {
      type: Boolean,
      default: null,
    },
    /**
    * Text to be shown when there is no data  this.$emit('input', this.selectedItem)
      // // custom event
      this.$emit('select', this.selectedItem  this.$emit('input', this.selectedItem)
      // // custom event
      this.$emit('select', this.selectedItem)  this.$emit('input', this.selectedItem)
      // // custom event
      this.$emit('select', this.selectedItem)
    */
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
    /**
     * if false will not show the "default option"
     */
    showDefaultOption: {
      type: Boolean,
      default: true,
    },
    /**
     * true means that the select will be disabled when it has no data, set false to prevent that
     */
    disableOnEmpty: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
  },
  data: function () {
    return {
      selectedItem: "",
      mData: [],
      listEntry: [],
    };
  },
  computed: {
    disableCombo: function () {
      return this.disabled !== undefined
        ? this.disabled
        : this.disableOnEmpty && !this.hasData;
    },
    hasData: function () {
      return this.data.length > 0 || this.mData.length > 0;
    },
    optionDefaultValue: function () {
      return this.hasData ? this.defaultValue : this.emptyDefaultValue;
    },
    optionDefaultText: function () {
      return this.hasData ? this.defaultText : this.emptyDefaultText;
    },
    completeItem() {
      const selectedCompleteItem = this.listEntry.filter(
        (item) => item.value === this.selectedItem,
      );
      return selectedCompleteItem.length > 0 ? selectedCompleteItem[0] : [];
    },
  },
  watch: {
    selectedItem: function (newValue) {
      this.selectItem(newValue);
    },
    value: function (newValue) {
      this.applyValue();
    },
    listEntry: function (pValue) {
      this.applyValue();
      if (pValue && pValue.length === 1 && !this.disabled) {
        this.selectedItem = pValue[0].value;
        this.$emit("change", pValue);
      }
    },
    disabled(pValue) {
      if (!pValue) {
        if (this.listEntry && this.listEntry.length === 1) {
          this.selectedItem = this.listEntry[0].value;
        }
      } else {
        if (this.listEntry && this.listEntry.length === 1) {
          this.selectedItem = null;
        }
      }
    },
    mData: function () {
      this.populateList();
    },
  },
  mounted() {
    this.selectedItem = this.value;
  },
  created() {
    this.populateList();
    this.applyValue();
    this.checkSingleSelection();
  },
  methods: {
    selectItem: function (e) {
      if (this.selectedItem) {
        this.$emit("input", this.selectedItem);
        this.$emit("select", this.completeItem);
      }
    },
    applyValue: function () {
      // this.listEntry = this.data.length > 0 ? this.data : this.mData
      if (this.isValidValue(this.value)) {
        this.selectedItem = this.value;
      } else {
        this.selectedItem = this.optionDefaultValue;
      }
    },
    populateList() {
      this.listEntry =
        this.data.length > 0 ? Array.from(this.data) : Array.from(this.mData);
      this.checkProperItem();
    },
    checkProperItem: function () {
      if (this.selectedItem === undefined && this.isValidValue(this.value)) {
        this.selectedItem = this.value;
      } else {
        this.selectedItem = this.optionDefaultValue;
      }
    },
    checkSingleSelection() {
      if (this.listEntry.length === 1 && this.singleSelection) {
        this.selectedItem = this.listEntry[0].value;
      } else if (this.listEntry.length <= 0) {
        this.selectedItem = null;
      }
    },
    isValidValue(pValue) {
      if (!pValue) {
        return false;
      }
      let exists = false;
      this.listEntry.map(({ value }) => {
        // eslint-disable-next-line eqeqeq
        if (value == pValue) {
          exists = true;
        }
      });
      return exists;
    },
  },
};
</script>
