<template>
  <Tooltip class="appointment-queue-report-tooltip">
    <IconInfoHelper slot="icon" />
    <div slot="content" class="content">
      <span> O relatório não necessita de filtros para sua geração. </span>
      <p>Informações:</p>
      <ul class="list">
        <li class="item">
          O relatório apresenta os pacientes que possuem mais de uma ficha de
          cadastro individual baseado no nome do paciente.
        </li>
      </ul>
    </div>
  </Tooltip>
</template>

<script>
import { Tooltip, IconInfoHelper } from "~tokio/primitive";
export default {
  name: "BasicAttentionDuplicateRegistrationReport",
  components: { Tooltip, IconInfoHelper },
  data() {
    return {
      resultTitle: "Duplicidade de Ficha de Cadastro Individual",
      cleanSelectedReport: true,
    };
  },

  computed: {
    printReportCss() {
      return ".report-column-1 { white-space: normal !important; ; text-align: left;}";
    },

    getNameSmartTable() {
      return "BasicAttentionDuplicateRegistrationReport";
    },

    columnTable() {
      return this.COLUMN;
    },
  },
  created() {
    this.COLUMN = [{ name: "Paciente", key: "PACIENTE", align: "left" }];
  },

  methods: {
    buildFilter() {},

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "BasicAttention/Report/GET_BASIC_ATTENTION_DUPLICATE_REGISTRATION_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {},
  },
};
</script>
