<template lang="html">
  <svg
    width="62"
    height="57"
    viewBox="0 0 62 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.5183 47.5504L43.1119 50.085L43.1119 55.2013C43.1119 55.7121 43.5284 56.1262 44.0424 56.1262L61.0695 56.1262C61.5835 56.1262 62 55.7121 62 55.2014L62 38.3909C62 37.8801 61.5835 37.466 61.0695 37.466L44.0424 37.466C43.5284 37.466 43.1119 37.8801 43.1119 38.3909L43.1119 43.573L39.5276 46.0354C39.2778 46.2069 39.1281 46.4887 39.1262 46.7905C39.1244 47.0922 39.2706 47.3758 39.5183 47.5504ZM44.5715 44.8187C44.8229 44.6461 44.9729 44.3616 44.9729 44.058L44.9729 39.3158L60.139 39.3158L60.139 54.2765L44.9729 54.2765L44.9729 49.6072C44.9729 49.3076 44.8267 49.0264 44.5808 48.8529L41.6788 46.8061L44.5715 44.8187Z"
      fill="white"
    />
    <path
      d="M22.4817 9.32333L18.8881 6.78881L18.8881 1.67243C18.8881 1.16165 18.4716 0.747555 17.9576 0.747555L0.930501 0.747558C0.416492 0.747559 7.24026e-08 1.16165 1.61709e-07 1.67243L3.10094e-06 18.4829C3.19025e-06 18.9937 0.416495 19.4078 0.930504 19.4078L17.9576 19.4077C18.4716 19.4077 18.8881 18.9937 18.8881 18.4829L18.8881 13.3008L22.4724 10.8384C22.7222 10.6669 22.8719 10.3851 22.8738 10.0833C22.8756 9.78157 22.7294 9.49794 22.4817 9.32333ZM17.4285 12.055C17.1771 12.2277 17.0271 12.5122 17.0271 12.8158L17.0271 17.558L1.861 17.558L1.861 2.5973L17.0271 2.5973L17.0271 7.26654C17.0271 7.56619 17.1733 7.84736 17.4192 8.02086L20.3212 10.0677L17.4285 12.055Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.312 10.0777C34.312 11.5718 33.1023 12.7834 31.6089 12.7864H31.5977C30.1042 12.7834 28.8945 11.5718 28.8945 10.0777C28.8945 8.58169 30.1073 7.36895 31.6033 7.36895C33.0993 7.36895 34.312 8.58169 34.312 10.0777ZM26.4868 10.0777C26.4868 12.3793 28.0065 14.326 30.0972 14.9689V23.2443C28.0058 23.8868 26.4854 25.8338 26.4854 28.136C26.4854 30.4381 28.0058 32.3851 30.0972 33.0277V41.9045C28.0058 42.547 26.4854 44.494 26.4854 46.7962C26.4854 49.6219 28.7761 51.9127 31.6019 51.9127C34.4276 51.9127 36.7184 49.6219 36.7184 46.7962C36.7184 44.4942 35.1982 42.5473 33.107 41.9046V33.0275C35.1982 32.3848 36.7184 30.4379 36.7184 28.136C36.7184 25.834 35.1982 23.8871 33.107 23.2444V14.9697C35.1989 14.3274 36.7198 12.3802 36.7198 10.0777C36.7198 7.25192 34.4291 4.96118 31.6033 4.96118C28.7775 4.96118 26.4868 7.25192 26.4868 10.0777ZM31.6019 30.8447C33.0979 30.8447 34.3106 29.632 34.3106 28.136C34.3106 26.64 33.0979 25.4272 31.6019 25.4272C30.1059 25.4272 28.8931 26.64 28.8931 28.136C28.8931 29.632 30.1059 30.8447 31.6019 30.8447ZM34.3106 46.7962C34.3106 48.2922 33.0979 49.5049 31.6019 49.5049C30.1059 49.5049 28.8931 48.2922 28.8931 46.7962C28.8931 45.3002 30.1059 44.0874 31.6019 44.0874C33.0979 44.0874 34.3106 45.3002 34.3106 46.7962Z"
      fill="white"
    />
    <path
      d="M10.2327 13.9902H8.41075V7.76239L6.62109 8.32382V6.84211L10.0656 5.56299H10.2327V13.9902Z"
      fill="white"
    />
    <path
      d="M55.3788 51.9127H49.532V50.5678L52.2252 47.5601C52.8889 46.7572 53.2208 46.1194 53.2208 45.6467C53.2208 45.2636 53.1421 44.9722 52.9848 44.7725C52.8275 44.5728 52.5993 44.473 52.3 44.473C52.0046 44.473 51.7648 44.6075 51.5807 44.8764C51.3965 45.1413 51.3045 45.4735 51.3045 45.8729H49.3594C49.3594 45.3268 49.4879 44.8235 49.7449 44.3629C50.002 43.8983 50.3588 43.5356 50.8153 43.2748C51.2719 43.014 51.7821 42.8835 52.3461 42.8835C53.2515 42.8835 53.9478 43.1057 54.435 43.5499C54.9261 43.9941 55.1716 44.6319 55.1716 45.4633C55.1716 45.8138 55.1102 46.1561 54.9875 46.4903C54.8647 46.8204 54.6729 47.1689 54.412 47.5357C54.155 47.8984 53.7387 48.3854 53.1632 48.9967L52.0813 50.3233H55.3788V51.9127Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Priority",
};
</script>
