import AuthRequest from "~common/request/AuthRequest";
import LocalStorage from "~common/local-storage/LocalStorage";

export default (clientCode) => {
  return AuthRequest.get("/client/get-logo-client-by-code", {
    cli_codigo: clientCode,
  }).then((pRes) => {
    const clientLogoString = Buffer.from(
      pRes.data.cli_logo_base64.data,
    ).toString();
    LocalStorage.setObject("client-logo", clientLogoString);
    return clientLogoString;
  });
};
