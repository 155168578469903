import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation UnifyPatient($patients: [UnifyPatientInputType]) {
    UnifyPatient(patients: $patients) {
      pac_id
      success
      message
    }
  }
`;

export default async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.UnifyPatient;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
