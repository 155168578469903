<template>
  <section class="configuration-container">
    <div class="title-area">
      <IconConfigure />
      <h3 class="title">{{ title }}</h3>
    </div>

    <div class="carrousel-area">
      <RgPaginationButton
        :disabled="disabledNextAndPrev"
        class="arrow"
        @click="back"
      />

      <div class="content-area">
        <div ref="carouselArea" class="container-area">
          <div
            v-for="item in showOnCarousel"
            :key="item.index"
            :class="{
              fadeleft,
              faderight,
              'content-carousel fade': item.permission,
            }"
          >
            <ListItemCarousel
              v-show="item.permission"
              :title="item.title"
              :subtitle="item.subtitle"
              :listItem="item.list"
              :redirect="item.redirect"
            >
              <component :is="item.icon" slot="icon" class="svg-title" />
            </ListItemCarousel>
          </div>
        </div>
        <div class="pagination">
          <RgButton
            v-for="(radio, index) in carouselPaginatedData"
            :key="index"
            class="paginate-button"
            :class="{ active: actualPage === index }"
            name="paginate"
            @click="setPage(index)"
          />
        </div>
      </div>

      <RgPaginationButton
        :disabled="disabledNextAndPrev"
        class="arrow"
        :rotate="true"
        @click="next"
      />
    </div>
  </section>
</template>

<script>
import { IconConfigure, RgPaginationButton, RgButton } from "~tokio/primitive";
import * as Icons from "~tokio/primitive/icon/quick-access";
import ListItemCarousel from "./list-item-carousel/ListItemCarousel";

export default {
  name: "ConfigurationContainer",
  components: {
    IconConfigure,
    RgPaginationButton,
    ListItemCarousel,
    ...Icons,
    RgButton,
  },
  props: {
    title: {
      type: String,
      default: "Configurações",
    },
  },
  data() {
    return {
      showOnCarousel: [],
      carouselPaginatedData: [],
      fadeleft: false,
      faderight: false,
      showPerPage: 2,
      actualPage: 0,
      dataCarousel: [],
    };
  },

  computed: {
    disabledNextAndPrev() {
      return this.carouselPaginatedData.length === 1;
    },
    carouselByUserGroup() {
      const cardsWithPermission = this.dataCarousel.filter((item) => {
        const hasPermission = item.permission === true;
        return hasPermission;
      });

      const carousel = cardsWithPermission.filter((item) => {
        const hasRestriction =
          Array.isArray(item.restrictTo) && item.restrictTo.length > 0;

        const isSameUserGroup = hasRestriction
          ? item.restrictTo.includes(this.userGroup)
          : false;

        return hasRestriction ? isSameUserGroup : true;
      });
      return carousel;
    },

    hasPermissionConfigureRegistration() {
      return !!this.$Permissions.global.has(
        "cadastro.configuracoes.configurar",
      );
    },
  },

  watch: {
    hasPermissionConfigureRegistration(pValue) {
      this.setCarousel();
    },
  },
  created() {
    this.DATA_CAROUSEL = [
      {
        index: 0,
        permission: true,
        title: "Integrações",
        subtitle: "PEP • PLENI • MeuEsus",
        icon: "IconIntegration",
        restrictTo: [
          this.$utils.app.UserGroup.ADM,
          this.$utils.app.UserGroup.SUPPORT,
          this.$utils.app.UserGroup.DEVELOP,
        ],
        list: [
          {
            label: "Sincronizações com o PEP",
            route: "/configuration/integration-services",
          },
          {
            label: "Tokens de Integração",
            route: "/configuration/integration-token-register",
          },
        ],
      },
      {
        index: 1,
        permission: true,
        title: "Sobre o Sistema",
        subtitle: "Atualizações • Privacidade • Jurídico",
        icon: "IconAboutSoftware",
        list: [],
        redirect: () => {
          return "/aboutsoftware";
        },
      },
      {
        index: 2,
        permission: true,
        title: "Suporte",
        subtitle:
          "Obtenha atendimento para solucionar suas dúvidas e solicitações",
        icon: "IconSupport",
        list: [],
        redirect: this.setHelpdeskInformation,
      },
      {
        index: 3,
        permission: false,
        title: "Configurações de Cadastro",
        subtitle: "Endereço • Documentos • Telefones",
        icon: "IconRegisterPatient",
        list: [],
        redirect: () => {
          return "/configuration/patient-registration-settings";
        },
      },
      {
        index: 4,
        permission: true,
        title: "Regulação",
        subtitle: `Gestão de Dados para o Portal da Regulação <br> Consultas • Exames • Internação`,
        icon: "IconRegulationQueue",
        list: [
          {
            label: "Exportar Informações sobre as Filas",
            route: "/register/configuration/export-queue-info",
          },
        ],
        restrictTo: [
          this.$utils.app.UserGroup.ADM,
          this.$utils.app.UserGroup.SUPPORT,
          this.$utils.app.UserGroup.DEVELOP,
        ],
      },
    ];
    this.ITEM_WIDTH_MAX = 465;
    this.ITEM_WIDTH_MIN = 331.5;
    this.HELPDESK_DEFAULT = process.env.VUE_APP_HELPDESK_URL;
  },

  mounted() {
    this.dataCarousel = this.DATA_CAROUSEL;
    this.setCarousel(false);
  },

  methods: {
    setHelpdeskInformation() {
      return this.HELPDESK_DEFAULT;
    },

    setCarousel(hasLoader = true) {
      if (hasLoader) this.$loader.start("Carregando");

      const permission = this.hasPermissionConfigureRegistration;
      this.dataCarousel[3].permission = permission;
      this.userGroup = this.$store.getters["Login/GET_USER_ID_GROUP"];

      this.restartItem();

      if (hasLoader) this.$loader.finish(1000);
    },

    setPage(pIndex) {
      if (this.carouselPaginatedData.length === 1) {
        return;
      }

      const prevIndex = this.carouselPaginatedData.findIndex(
        (item) => item === this.showOnCarousel,
      );

      if (prevIndex > pIndex) {
        this.fadeleft = true;
        this.faderight = false;
      } else {
        this.fadeleft = false;
        this.faderight = true;
      }

      this.actualPage = pIndex;
      this.showOnCarousel = this.carouselPaginatedData[pIndex];
    },

    restartItem() {
      this.carouselPaginatedData = [];
      const containerWidth = this.$refs.carouselArea.offsetWidth;
      const itemWidth =
        window.outerWidth <= 1340 ? this.ITEM_WIDTH_MIN : this.ITEM_WIDTH_MAX;
      this.showPerPage = Math.ceil(containerWidth / itemWidth);

      for (
        let item = 0;
        item < this.carouselByUserGroup.length;
        item = item + this.showPerPage
      ) {
        this.carouselPaginatedData.push(
          this.carouselByUserGroup.slice(item, item + this.showPerPage),
        );
      }

      this.showOnCarousel = this.carouselPaginatedData[0];
    },

    back() {
      this.fadeleft = true;
      this.faderight = false;

      const index = this.carouselPaginatedData.findIndex(
        (item) => item === this.showOnCarousel,
      );

      index - 1 >= 0
        ? (this.actualPage = index - 1)
        : (this.actualPage = this.carouselPaginatedData.length - 1);

      this.showOnCarousel = this.carouselPaginatedData[this.actualPage];
    },

    next() {
      this.fadeleft = false;
      this.faderight = true;

      const index = this.carouselPaginatedData.findIndex(
        (item) => item === this.showOnCarousel,
      );

      index + 1 < this.carouselPaginatedData.length
        ? (this.actualPage = index + 1)
        : (this.actualPage = 0);

      this.showOnCarousel = this.carouselPaginatedData[this.actualPage];
    },
  },
};
</script>
