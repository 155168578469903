export default {
  SET_LIMIT_PAGINATION: (state, data) => {
    state.limit = data;
  },
  SET_OFFSET_PAGINATION: (state, data) => {
    state.offset = data;
  },
  SET_CURRENT_PAGINATION: (state, data) => {
    state.current = data;
  },
  SET_TOTAL_PAGINATION: (state, data) => {
    state.total = data;
  },
  SET_ROUTE_PAGINATION: (state, data) => {
    state.route = data;
  },
  DESTROY_PAGINATION(state) {
    state.limit = 10;
    state.offset = 0;
    state.current = 1;
    state.total = 0;
  },
  DESTROY_ROUTE_PAGINATION(state) {
    state.route = null;
  },
};
