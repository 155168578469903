import UserSearch from "$user/view/user-search/store";
import UserDetail from "$user/view/user-detail/store";
import mutations from "./mutations";
import getters from "./getters";
import state from "./state";
import actions from "./actions";

export default {
  namespaced: true,
  getters,
  mutations,
  state,
  actions,
  modules: {
    UserSearch,
    UserDetail,
  },
};
