<template>
  <Modulebox
    :title="individualOrLot"
    class="appointment-register-schedule-queue"
  >
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <div class="body">
          <FormBase
            v-if="!moreThanOnePatient"
            title="Paciente"
            class="content-form"
          >
            <div class="content">
              <RgSuggestPatientInformation
                id="patient"
                ref="patient"
                v-model="suggestPatient"
                class="inputpatient"
                :with-patient-module="['appointment']"
                :extra-data="['withoutAddress']"
                :enabled-patient="false"
                :withPatientAppointment="true"
                :rules="{ forceSelection: true, required: true }"
                :disabled="true"
                @patientInfo="getPatientData"
              />
            </div>

            <Tooltip class="tooltip" startOpen :message="messageTooltip">
              <IconExclamationHelper slot="icon" />
            </Tooltip>

            <div class="documents">
              <RgInputCns
                id="input-cns"
                v-model="document.cns"
                :required-value="requiredCns"
              />
              <RgInputCpf
                id="input-cpf"
                v-model="document.cpf"
                :rules="{ required: requiredCpf }"
              />
            </div>
          </FormBase>

          <FormBase
            v-if="moreThanOnePatient && !isBasicAttentionSector"
            title="Pacientes"
            class="content-form"
          >
            <div class="actions-top">
              <RgLessButton
                v-show="mutableListPatient && mutableListPatient.length > 1"
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="delete"
                title="Remover paciente do agendamento"
                @click="removePatientToList"
              />
              <RgPersonCardButton
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="register"
                title="Cadastro de Paciente"
                @click="editPatient"
              />
              <RgHistoryButton
                id="history-calls"
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="history"
                title="Histórico de Atendimentos"
                @click="patientCareHistory"
              />
              <RgPrinterButton
                :disabled="!patientSelect"
                :class="{ disable: !patientSelect }"
                class="printer"
                title="Imprimir carteirinha"
                @click="patientPrintCard"
              />
            </div>

            <table v-if="moreThanOnePatient" class="table-patient">
              <div class="thead">
                <tr class="header-grid">
                  <th class=""></th>
                  <th class="patient">Pacientes</th>
                  <th class="medical-record">Cartão SUS</th>
                  <th class="medical-record">CPF</th>
                  <th class="medical-record">Prontuário Único</th>
                </tr>
              </div>

              <div class="tbody">
                <tr
                  v-for="(item, index) in mutableListPatient"
                  :key="index"
                  :class="{ selected: index === activeRow }"
                  class="body-grid"
                  @click="selectLine(item, index)"
                >
                  <td>
                    <div v-if="index === activeRow" class="check">
                      <IconCheck />
                    </div>
                  </td>
                  <td>{{ item.pes_nome }}</td>
                  <td>{{ item.pep_cartaosus }}</td>
                  <td>{{ item.pep_cpf_numero }}</td>
                  <td>{{ item.pac_prontuario_unico }}</td>
                </tr>
              </div>
            </table>
          </FormBase>

          <FormBase title="Profissional" class="module-employee">
            <div class="content-grid">
              <Tooltip
                v-if="mutableListPatient.length > 1"
                class="tooltip"
                startOpen
                message="Não é possivel realizar agendamentos em lote para setores da Atenção Básica."
              >
                <IconExclamationHelper slot="icon" hollow />
              </Tooltip>

              <div class="row-1 content-grid">
                <RgSuggestCbo
                  ref="cbo"
                  v-model="occupation"
                  :disabled="true"
                  :class="{ disable: true }"
                  placeholder="Informe a ocupação"
                />

                <RgSelectUnithealth
                  id="select-unithealth"
                  ref="unit"
                  v-model="form.unitHealth"
                  :rules="{ required: true }"
                  :permission="'consulta.filaConsulta.agendar'"
                  label="Unidade de Saúde"
                  @change="getUnitHealth"
                />

                <RgSelectSector
                  id="select-sector"
                  ref="sector"
                  v-model="form.sector"
                  :rules="{ required: true }"
                  :disabled="!form.unitHealth"
                  :class="{ disable: !form.unitHealth }"
                  :unit-health="form.unitHealth"
                  default-text="Selecione"
                  class="inputitem"
                  :isBatchScheduleQueue="mutableListPatient.length > 1"
                  @change="getSector"
                />

                <RgSelectEmployeeSectorOccupation
                  id="select-employee"
                  ref="employee"
                  v-model="form.responsibleProfessional"
                  :disabled="!form.sector"
                  :class="{ disable: !form.sector }"
                  :rules="{ required: true }"
                  :sector-id="form.sector"
                  :occupation-id="occupationIdFromList"
                  label="Profissional"
                  @change="selectingEmployeeSector"
                />

                <RgInputDate
                  id="input-date"
                  ref="date"
                  v-model="form.date"
                  :rules="{ required: true }"
                  :disabled="
                    !form.responsibleProfessional ||
                    this.periodsDate.length === 0
                  "
                  :class="{
                    disable:
                      !form.responsibleProfessional ||
                      this.periodsDate.length === 0,
                  }"
                  :dates-available="datesEnable"
                  disable-no-available
                  label="Data de Atendimento"
                />
              </div>

              <div class="row-2 content-grid">
                <RgComboboxScale
                  id="combobox-scale"
                  ref="scale"
                  v-model="form.scale"
                  :disabled="!form.date"
                  :class="{ disable: !form.date }"
                  :rules="{ required: true }"
                  :sector-id="form.sector"
                  :occupation-id="occupationIdFromList"
                  :person-id="form.responsibleProfessional"
                  :unit-id="form.unitHealth"
                  :date="form.date"
                  label="Escala"
                  @change="changeScale"
                />

                <RgComboboxHoursEmployerSchedule
                  v-if="blockedAppointmentSchedulingTime"
                  id="combobox-hour"
                  ref="comboHour"
                  v-model="form.hour"
                  :disabled="!form.date || !getNumberScale"
                  :class="{ disable: !form.date || !getNumberScale }"
                  :schedule-scale-id="getNumberScale"
                  :schedule-history-id="scaleScheduleHistoryId"
                  :initial-hour="scaleInitialHour"
                  :appointment-date="getDateSchedule"
                  :rules="{ required: blockedAppointmentSchedulingTime }"
                  label="Horário"
                />

                <RgSelectPlacesAppointment
                  id="select-places-appointment"
                  ref="locale"
                  v-model="form.locale"
                  :disabled="!form.sector"
                  :class="{ disable: !form.sector }"
                  :rules="{ required: true }"
                  :sector-id="form.sector"
                  label="Local de Atendimento"
                  @change="selectingPlace"
                />

                <RgInputHour
                  v-if="!blockedAppointmentSchedulingTime"
                  id="input-hour"
                  ref="hour"
                  v-model="form.hour"
                  :rules="{ required: !blockedAppointmentSchedulingTime }"
                  placeholder="10:00"
                  label="Hora *"
                />

                <RgSelectHealthPlans
                  id="select-health-plans"
                  ref="healthPlan"
                  v-model="form.healthPlan"
                  :health-unit-id="form.unitHealth"
                  :rules="{ required: true }"
                  label="Convênio"
                  @change="selectingHealthPlans"
                />

                <RgSelectAppointmentObjective
                  id="select-appointment-objective"
                  ref="objective"
                  v-model="form.objective"
                  :rules="{ required: true }"
                  :health-unit-id="form.unitHealth"
                  :health-plans-id="form.healthPlan"
                  :active="1"
                  :disabled="!form.healthPlan"
                  :class="{ disable: !form.healthPlan }"
                  label="Objetivo de Consulta"
                />
              </div>
            </div>
          </FormBase>
        </div>
      </RgValidatorForm>
    </form>

    <div slot="footer" class="footer">
      <RgCleanButton
        small
        title="Limpar"
        class="clean"
        @click="showConfirmFieldClearing"
      />

      <RgCancelButton
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton
        ref="save"
        medium
        title="Salvar"
        :disabled="disableBySave"
        class="save"
        @click="saveForm"
      />
    </div>

    <ModalConfirmFieldClearing
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />

    <ModalConfirmDefault
      :show="unavailableHour.show"
      :message="unavailableHour.message"
      title="Horário Indisponível"
      yes-label="Sim"
      no-label="Não"
      @getYes="changeToAvailableHourAndSave"
      @getOut="closeModalUnavailableHour"
    />

    <ModalIndividualPatientRegistrationForm
      :show="modalIndividualPatientRegistrationForm"
      :personId="Number(pesId)"
      :scheduleData="employeeData"
      :lastIndividualRegistration="lastIndividualRegistration"
      :patientBasicAttentionId="patientBasicAttentionId"
      @save="saveIndividualPatientRegistrationForm"
      @cancel="closeModalIndividualPatientRegistrationForm"
      @close="closeModalIndividualPatientRegistrationForm"
    />

    <ModalPatientRecordsFoundBasicAttention
      :show="modalPatientRecordsFoundBasicAttention"
      by-scheduling
      :patient-id="patientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :patientHasNoBondOrInactiveBond="patientHasNoBondOrInactiveBond"
      @newRecord="showNewRecordFoundBasicAttention"
      @save="savePatientRecordFoundBasicAttention"
      @close="closeModalPatientRecordsFoundBasicAttention"
    />

    <ModalPatientNewRecordFoundBasicAttention
      :show="modalPatientNewRecordFoundBasicAttention"
      :patient-id="patientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :currentIndividualRegistrationDate="currentIndividualRegistrationDate"
      @close="closeModalPatientNewRecordFoundBasicAttention"
      @change="changePatientNewRecordFoundBasicAttention"
    />
    <ModalConflictScale
      id="modal-conflict-scale"
      :show="modalConflitScale"
      :patientInfo="mutableListPatient"
      :title="title"
      :information="information"
      :scheduleInfo="mutableScheduleInfo"
      :showSave="showSave"
      :scaleInfo="scaleInfo"
      @save="saveAnyway"
      @close="closeModalConflictScale"
    />
  </Modulebox>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSaveButton,
  RgCleanButton,
  RgSuggestCbo,
  RgValidatorForm,
  RgInputDate,
  RgSelectSector,
  RgSelectHealthPlans,
  ModalConfirmDefault,
  RgPersonCardButton,
  RgPrinterButton,
  RgHistoryButton,
  RgLessButton,
  RgCancelButton,
  RgInputHour,
  IconCheck,
  RgSuggestPatientInformation,
  Tooltip,
  IconExclamationHelper,
  ModalConflictScale,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";
import ModalIndividualPatientRegistrationForm from "$appointment/submodules/schedule/component/modal/modal-individual-patient-registration-form/ModalIndividualPatientRegistrationForm";
import ModalPatientRecordsFoundBasicAttention from "$person/patient/component/modal/modal-patient-records-found-basic-attention/ModalPatientRecordsFoundBasicAttention";
import ModalPatientNewRecordFoundBasicAttention from "$person/patient/component/modal/modal-patient-new-record-found-basic-attention/ModalPatientNewRecordFoundBasicAttention";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";

import RgSelectPlacesAppointment from "$appointment/common/component/rg-select-places-appointment/RgSelectPlacesAppointment";
import RgSelectAppointmentObjective from "$appointment/common/component/rg-select-appointment-objective/RgSelectAppointmentObjective";
import RgComboboxScale from "$appointment/common/component/rg-combobox-scale/RgComboboxScale";
import RgSelectEmployeeSectorOccupation from "$appointment/common/component/rg-select-employee-sector-occupation/RgSelectEmployeeSectorOccupation";
import RgComboboxHoursEmployerSchedule from "$appointment/common/component/rg-combobox-hours-employer-schedule/RgComboboxHoursEmployerSchedule";
import SearchPersonById from "$appointment/submodules/schedule/store/actions/SearchPersonById";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";
import { DateNow } from "~utils/date";
import { mapGetters } from "vuex";

export default {
  name: "AppointmentRegisterScheduleQueue",
  components: {
    RgInputCns,
    RgInputCpf,
    RgSelectUnithealth,
    RgSaveButton,
    Modulebox,
    FormBase,
    RgCleanButton,
    RgSuggestCbo,
    RgSelectPlacesAppointment,
    RgSelectHealthPlans,
    RgValidatorForm,
    RgInputDate,
    RgSelectSector,
    RgSelectAppointmentObjective,
    RgComboboxScale,
    ModalConfirmFieldClearing,
    RgSelectEmployeeSectorOccupation,
    RgComboboxHoursEmployerSchedule,
    ModalConfirmDefault,
    RgPersonCardButton,
    RgPrinterButton,
    RgSuggestPatientInformation,
    Tooltip,
    RgHistoryButton,
    RgLessButton,
    RgCancelButton,
    ModalIndividualPatientRegistrationForm,
    ModalPatientNewRecordFoundBasicAttention,
    ModalPatientRecordsFoundBasicAttention,
    RgInputHour,
    IconCheck,
    IconExclamationHelper,
    ModalConflictScale,
  },

  data() {
    return {
      mutableListPatient: [],
      datesEnable: [],
      periodsDate: [],
      getDateSchedule: null,
      getNumberScale: null,
      occupation: null,
      pesId: null,
      patientCpf: null,
      title: null,
      information: null,
      patientCns: null,
      indexPatient: null,
      modalConfirmFieldClearing: false,
      activeRow: null,
      suggestPatient: null,
      isBasicAttentionSector: false,
      selectedPatient: {},
      showSave: false,
      form: {
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        responsibleProfessional: null,
        date: "",
        hour: null,
        scale: null,
        locale: null,
        healthPlan: null,
        objective: null,
      },
      unavailableHour: {
        show: false,
        message: "",
        nextAvailableHour: "",
      },
      document: {
        cpf: null,
        cpf_id: null,
        cns: null,
        crs_id: null,
      },
      hasCns: false,
      hasCpf: false,
      selectedScale: null,
      occupationIdFromList: null,
      reasonToAnticipate: null,
      dataPatientBasicAttention: null,
      individualRegistrationList: [],
      moreThanOnePatient: false,
      modalIndividualPatientRegistrationForm: false,
      modalPatientNewRecordFoundBasicAttention: false,
      modalPatientRecordsFoundBasicAttention: false,
      modalNewRecordFoundBasicAttention: false,
      employeeData: null,
      lastIndividualRegistration: null,
      currentIndividualRegistrationDate: null,
      modalConflitScale: false,
      scaleInfo: [],
      mutableScheduleInfo: [],
      disableBySave: false,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
      basicAttentionIntegration: "Login/GET_BASIC_ATTENTION_INTEGRATION",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),

    preference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewMarcacaoConsulta.con_bloquear_horario_agendamento"
      ];
    },

    blockedAppointmentSchedulingTime() {
      return this.preference === "1";
    },

    isIndividual() {
      return this.mutableListPatient && this.mutableListPatient.length === 1;
    },

    individualOrLot() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Agendar Consulta em Lote"
        : "Agendar Consulta";
    },

    patientOrPatients() {
      return this.mutableListPatient && this.mutableListPatient.length > 1
        ? "Pacientes"
        : "Paciente";
    },

    manyPatients() {
      return this.mutableListPatient && this.mutableListPatient.length > 1;
    },

    patientSelect() {
      return this.activeRow !== null;
    },

    scaleScheduleHistoryId() {
      return this.selectedScale && this.selectedScale.hag_id;
    },

    scaleInitialHour() {
      return this.selectedScale && this.selectedScale.eag_inicio_atendimentos;
    },

    messageTooltip() {
      return this.isBasicAttentionSector
        ? "O cadastro do Paciente é vinculado a Ficha de Atenção Básica: os documentos “CPF” e/ou “Cartão SUS” possuem preenchimento obrigatório."
        : "Sempre mantenha as informações do paciente atualizadas. Recomenda-se o registro da documentação, principalmente CNS e CPF.";
    },

    requiredCpf() {
      return (
        this.preferenceCpfMandatoryScheduling === "1" ||
        (this.isBasicAttentionSector && !this.document.cns)
      );
    },

    requiredCns() {
      return (
        this.preferenceCnsMandatoryScheduling === "1" ||
        (this.isBasicAttentionSector && !this.document.cpf)
      );
    },

    preferenceCpfMandatoryScheduling() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_cpf_agendamento_consulta"
      ];
    },

    preferenceCnsMandatoryScheduling() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_cns_agendamento_consulta"
      ];
    },

    patientId() {
      return Number(this.selectedPatient?.pac_id);
    },

    hasBasicAttentionIntegration() {
      return (
        !!this.basicAttentionIntegration &&
        !!this.currentUnitHealth?.unidades_saude_integracao_ab
      );
    },

    patientBasicAttentionId() {
      return this.dataPatientBasicAttention?.pae_id;
    },

    patientHasNoBondOrInactiveBond() {
      const INACTIVE = 0;
      return (
        !this.dataPatientBasicAttention ||
        this.dataPatientBasicAttention?.pae_ativo === INACTIVE
      );
    },
  },

  watch: {
    "form.sector"(pValue) {
      this.form.responsibleProfessional = null;
      this.form.locale = null;
      this.form.date = "";
      this.form.hour = null;
      this.form.scale = null;
      this.$refs.employee.cleanValidate();
      this.$refs.scale.cleanValidate();
    },

    "form.responsibleProfessional"(pProfessional) {
      this.setDaysPossible();
    },

    "form.scale"(pScale) {
      if (pScale && Array.isArray(pScale)) {
        this.getNumberScale = Number(pScale[0].value);
      } else if (pScale) {
        this.getNumberScale = pScale;
      }
    },

    "form.date"(pDate) {
      this.changeDate(pDate);
    },

    mutableListPatient(pValue) {
      if (pValue && pValue.length === 1) {
        this.selectLine(pValue[0], 0);
      }
    },
  },

  async mounted() {
    const existData = await this.$store.getters[
      "Appointment/Schedule/GET_SELECTED_EXTERNAL_APPOINTMENT_RESULT"
    ];

    if (existData) {
      if (this.lastPersonIdSaved) {
        const patientData = await SearchPersonById({
          pesId: this.lastPersonIdSaved,
        });

        if (patientData) {
          const index = existData.findIndex(
            (row) => Number(row.pes_id) === Number(this.lastPersonIdSaved),
          );
          existData[index].pes_nome = patientData.pes_nome;
          existData[index].pep_cartaosus = patientData.cns?.crs_numero;
          existData[index].pep_cpf_numero = patientData.cpf?.cpf_numero;
          existData[index].pac_prontuario_unico =
            patientData.patient?.pac_prontuario_unico;
        }
        this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
      }

      this.mutableListPatient = existData;

      if (this.mutableListPatient.length === 1) {
        this.$refs.patient.$refs.patient.fillPatientById(
          this.mutableListPatient[0].pes_id,
        );
      } else {
        this.moreThanOnePatient = true;
      }

      this.occupationIdFromList = Number(existData[0].ocp_id);
      await this.$refs.cbo.fillByOccupationId(this.occupationIdFromList);
    } else {
      this.cancel();
      this.$toaster.info(
        "Será necessário realizar uma nova pesquisa.",
        "Você foi redirecionado para a busca",
      );
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/queue",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Appointment/Schedule/UNSELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
      );
    }
  },

  methods: {
    getSector(pValue) {
      if (
        pValue?.type === "ATENÇÃO BÁSICA" ||
        pValue?.type === "Atenção Básica"
      ) {
        this.isBasicAttentionSector = true;
      }
    },

    getUnitHealth(pValue) {
      this.employeeData = {
        uns_id: pValue?.uns_id,
        uns_nome: pValue?.label,
      };

      this.clearForm();
    },

    async saveAnyway() {
      try {
        this.$loader.start();
        this.disableBySave = true;
        const variables = this.formatData();
        const permissions = {
          blnPermissaoNormal: this.$Permissions.sector.has(
            "consulta.filaConsulta.agendar",
          ),
          blnPermissaoReserva: this.$Permissions.sector.has(
            "consulta.filaConsulta.agendarNaFilaReservaTecnica",
          ),
        };

        variables[0].blnAlertarPacienteConsultadoHoje = false;

        this.$loader.start();
        this.modalConflitScale = false;

        const result = await this.$store.dispatch(
          "Appointment/Schedule/SAVE_REGISTER_SCHEDULE_QUEUE_LOT",
          { variables, permissions },
        );
        this.$toaster.success("Agendamento realizado com sucesso");

        const params = {
          isAppontmentSchedule: true,
          appointmentId: result.dados,
        };

        this.$router.push({
          name: "appointment.schedule.queue",
          params,
        });
      } catch (pErr) {
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const hasDisplay = esus_response.dados === "DisplayException";

          if (hasDisplay) {
            this.$toaster.error(
              this.$utils.sanitize.formatError(pErr),
              "Falha ao salvar agendamento",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Falha ao salvar agendamento",
          );
        }
      } finally {
        this.disableBySave = false;
        this.$loader.finish();
      }
    },

    selectingHealthPlans(pHealth) {
      if (!pHealth) {
        this.form.objective = null;
      }
    },

    selectingPlace(pPlace) {
      if (!pPlace) {
        this.form.responsibleProfessional = null;
      }
    },

    selectingEmployeeSector(pEmployee) {
      if (!pEmployee) {
        this.form.date = null;
        this.form.scale = null;
        this.form.hour = null;
        this.form.healthPlan = null;
        this.form.objective = null;
      }
    },

    changeScale(scale) {
      if (scale && scale.length > 0) {
        const item = scale[0].item;
        this.selectedScale = {
          eag_inicio_atendimentos: item.eag_inicio_atendimentos.substr(0, 5),
          data: this.form.date,
          eag_id: item.data,
          hag_id: item.hag_id,
          eag_id_dias_semana: item.eag_id_dias_semana,
          eag_quantidade_consultas: item.eag_quantidade_consultas,
          eag_intervalo_consultas: item.eag_intervalo_consultas,
          eag_quantidade_marcadas: item.eag_quantidade_marcadas,
        };
      } else {
        this.getNumberScale = null;
        this.form.hour = null;
        this.selectedScale = null;
      }
    },

    changeDate(pDate) {
      this.clearScaleHour();

      if (pDate && pDate.length === 10) {
        this.dateIsValid = true;

        this.getDateSchedule = this.form.date
          ? this.form.date.replaceAll("-", "/")
          : "";
      }
    },
    closeModalConflictScale() {
      this.modalConflitScale = false;
    },

    cancel() {
      this.$router.go(-1);
    },

    async getPatientData(pPatient) {
      const hasPerson = pPatient && pPatient.pes_id > 0;

      if (pPatient?.patient && hasPerson) {
        this.pesId = Number(pPatient.pes_id);

        this.hasCpf =
          pPatient.cpf?.cpf_numero && pPatient.cpf?.cpf_numero.length > 0;
        this.document.cpf = pPatient.cpf?.cpf_numero
          ? pPatient.cpf.cpf_numero
          : null;
        this.document.cpf_id = pPatient.cpf?.cpf_id
          ? pPatient.cpf.cpf_id
          : null;

        this.hasCns =
          pPatient.cns?.crs_numero && pPatient.cns?.crs_numero.length > 0;
        this.document.cns = pPatient.cns?.crs_numero
          ? pPatient.cns.crs_numero
          : null;
        this.document.crs_id = pPatient.cns?.crs_id
          ? pPatient.cns.crs_id
          : null;
      } else {
        this.document = {
          cpf: null,
          cns: null,
        };
        this.hasCpf = false;
        this.hasCns = false;
      }
    },

    async saveForm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();
        this.disableBySave = true;

        if (!(await this.manageDocuments())) return false;

        if (await this.verifyIndividualRecordModalToOpen()) return false;

        await this.saveSchedule();
      } finally {
        this.disableBySave = false;
        this.$loader.finish();
      }
    },

    async saveSchedule() {
      try {
        const variables = this.formatData();

        const permissions = {
          blnPermissaoNormal: this.$Permissions.sector.has(
            "consulta.filaConsulta.agendar",
          ),
          blnPermissaoReserva: this.$Permissions.sector.has(
            "consulta.filaConsulta.agendarNaFilaReservaTecnica",
          ),
        };

        const result = await this.$store.dispatch(
          "Appointment/Schedule/SAVE_REGISTER_SCHEDULE_QUEUE_LOT",
          { variables, permissions },
        );

        const queueId = this.mutableListPatient.map(({ fil_id }) =>
          Number(fil_id),
        );

        const reason = this.mutableListPatient[0]
          .fil_motivo_agendamento_fora_topo;

        const reasonVariables = {
          queueId,
          reason,
        };

        if (reason) {
          await this.$store.dispatch(
            "Appointment/Schedule/SAVE_REGISTER_SCHEDULE_QUEUE_LOT_REASON",
            reasonVariables,
          );
        }

        this.$toaster.success("Agendamento realizado com sucesso");

        const params = {
          isAppontmentSchedule: true,
          appointmentId: result.dados,
        };

        this.$router.push({
          name: "appointment.schedule.queue",
          params,
        });

        return true;
      } catch (pErr) {
        const timePreference = this.$store.getters["Login/GET_PREFERENCES"][
          "tViewMarcacaoConsulta.con_tempo_entre_consultas"
        ];
        const { esus_response } = pErr;
        const hasDisplay = esus_response.dados === "DisplayException";
        if (esus_response?.hasError) {
          const isScheduleError =
            esus_response.message === "HorarioIndisponivelException";
          const hasSchedule =
            esus_response.dados === "PacienteConsultadoHojeException" ||
            esus_response.dados === "PacienteConsultadoHojeHorarioException";

          const hasScheduleDay =
            esus_response.dados === "PacienteConsultadoHojeAtendenteException";

          if (isScheduleError) {
            this.unavailableHour.nextAvailableHour = esus_response.message;
            this.unavailableHour.show = true;
            this.unavailableHour.message = `O horário não está disponível. Agendar para ${this.unavailableHour.nextAvailableHour}?`;
            return false;
          }

          if (hasSchedule) {
            this.title =
              "O paciente já possui Consulta agendada no dia selecionado.";
            this.information =
              "Verifique na lista abaixo os respectivos horários marcados para decidir se deseja prosseguir com a marcação mesmo assim.";
            this.showSave = true;
            this.modalConflitScale = true;
          }

          if (hasScheduleDay) {
            this.title = "O paciente já possui Consulta agendada no dia.";
            this.information =
              "O intervalo entre as consultas é menor ou igual a " +
              timePreference +
              " minutos.";
            this.showSave = false;
            this.modalConflitScale = true;
          }

          if (hasDisplay) {
            this.$toaster.error(
              this.$utils.sanitize.formatError(pErr),
              "Falha ao salvar agendamento",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Falha ao salvar agendamento",
          );
        }
      } finally {
        this.$refs.save.actionDone();
      }
    },

    formatData() {
      const patients = [];

      this.mutableListPatient.map((item, index) => {
        patients.push({
          blnAlertarPacienteConsultadoHoje: true,
          con_hora: this.form.hour,
          con_id_filas_consultas: Number(item.flc_id),
          con_id_locais_atendimento: Number(this.form.locale),
          con_id_pacientes_consultas: Number(item.pcc_id),
          con_id_planos_saude: Number(this.form.healthPlan),
          con_id_tipos_consultas_unidade: Number(this.form.objective),
          eag_quantidade_marcadas: this.selectedScale.eag_quantidade_marcadas,
          hag_data_consulta: this.form.date.replaceAll("-", "/"),
          hag_hora_inicio_consulta_funcionario:
            this.selectedScale.hag_id === "0"
              ? this.selectedScale.eag_inicio_atendimentos
              : this.form.hour,
          hag_id_dias_semana: Number(this.selectedScale.eag_id_dias_semana),
          hag_id_escalas_agendamentos: Number(this.selectedScale.eag_id),
          set_id: Number(this.form.sector),
          tcu_id_unidades_saude: Number(this.form.unitHealth),
          tipo_agendamento: 0,
          hag_quantidade_consultas: this.selectedScale.eag_quantidade_consultas,
          hag_intervalo_consultas: this.selectedScale.eag_intervalo_consultas,
          hag_id: this.selectedScale.hag_id,
        });
      });
      this.mutableScheduleInfo = patients;

      return patients;
    },

    async verifyIndividualRecordModalToOpen() {
      if (
        this.hasBasicAttentionIntegration &&
        this.isIndividual &&
        this.isBasicAttentionSector &&
        this.patientId
      ) {
        await this.searchPatientBondBasicAttention();
        const hasActiveBond = this.dataPatientBasicAttention?.pae_ativo;

        if (hasActiveBond) {
          if (await this.getIndividualRegistration()) {
            const hasNoRecord = this.individualRegistrationList.length === 0;

            if (hasNoRecord) {
              this.lastIndividualRegistration = null;
              this.modalIndividualPatientRegistrationForm = true;
              return true;
            }

            const hasOneRecord = this.individualRegistrationList.length === 1;
            const patientBaseRecordId = this.dataPatientBasicAttention
              ?.pae_ficha_base;
            const individualRegistrationWithSameId = this.individualRegistrationList.find(
              (x) =>
                Number(x.pab_id) === patientBaseRecordId ||
                Number(x.pab_id_payload) === patientBaseRecordId,
            );

            if (hasOneRecord && individualRegistrationWithSameId) {
              const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
                this.individualRegistrationList[0],
                this.dataPatientBasicAttention.pae_id,
                this.patientId,
              );
              this.updatePatientBondBasicAttention(patientAbEsus);
              return false;
            }

            if (hasOneRecord && !individualRegistrationWithSameId) {
              this.inactivatePatientBondBasicAttention();
              this.showModalPatientRecordsFoundBasicAttention();
              return true;
            }

            const hasMoreThanOneRecord =
              this.individualRegistrationList.length > 1;

            if (hasMoreThanOneRecord && individualRegistrationWithSameId) {
              const individualRegistrationWithGreaterDate = await this.getIndividualRegistrationWithGreaterDate();
              if (individualRegistrationWithGreaterDate) {
                const updatePatientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
                  individualRegistrationWithGreaterDate,
                  this.dataPatientBasicAttention.pae_id,
                  this.patientId,
                );
                this.updatePatientBondBasicAttention(updatePatientAbEsus);
                return false;
              } else {
                this.currentIndividualRegistrationDate =
                  individualRegistrationWithSameId.pab_data_atendimento;
                this.showModalPatientNewRecordFoundBasicAttention();
                return true;
              }
            }

            if (hasMoreThanOneRecord && !individualRegistrationWithSameId) {
              this.inactivatePatientBondBasicAttention();
              this.showModalPatientRecordsFoundBasicAttention();
              return true;
            }
          }

          return false;
        }

        if (!hasActiveBond) {
          if (await this.getIndividualRegistration()) {
            const hasOneOrMoreRecords =
              this.individualRegistrationList.length > 0;
            if (hasOneOrMoreRecords) {
              this.showModalPatientRecordsFoundBasicAttention();
              return true;
            }
          }

          this.lastIndividualRegistration = null;
          this.modalIndividualPatientRegistrationForm = true;
          return true;
        }
      }

      return false;
    },

    async searchPatientBondBasicAttention() {
      if (this.patientId) {
        const dataAbEsus = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_AB_ESUS",
          { pacId: this.patientId },
        );

        this.dataPatientBasicAttention = dataAbEsus || null;
      }
    },

    async getIndividualRegistration(sendBaseCardId = true) {
      const patientCpf = this.removeDashesAndDots(this.document.cpf);
      const patientCns = this.document.cns;
      const patientAbEsusIsActive =
        this.dataPatientBasicAttention?.pae_ativo || false;

      if (patientCpf || patientCns) {
        const variables = {
          ...(sendBaseCardId &&
            patientAbEsusIsActive && {
              baseCardId: this.dataPatientBasicAttention?.pae_ficha_base,
            }),
          cpf: patientCpf,
          cns: patientCns,
        };
        try {
          const result = await this.$store.dispatch(
            "Appointment/Schedule/GET_AB_INDIVIDUAL_REGISTRATION",
            variables,
          );
          this.individualRegistrationList = result?.rows;
          return this.individualRegistrationList;
        } catch {
          return null;
        }
      }

      return null;
    },

    async updatePatientBondBasicAttention(pData) {
      if (pData) {
        try {
          await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
            abEsus: pData,
          });
        } catch (err) {
          this.$toaster.error("Erro ao atualizar o vínculo", err);
        }
      }
    },

    async getLastIndividualRegistrationByDate() {
      if (this.individualRegistrationList?.length === 0) return null;

      const maxDate = new Date(
        Math.max(
          ...this.individualRegistrationList.map((item) => {
            return new Date(item.pab_data_atendimento + " 00:00:00");
          }),
        ),
      );

      const maxDateFormatted = moment(maxDate).format("YYYY-MM-DD");

      const individualRegistrationSameDate = this.individualRegistrationList.filter(
        (x) => x.pab_data_atendimento === maxDateFormatted,
      );

      if (individualRegistrationSameDate.length === 1) {
        return individualRegistrationSameDate[0];
      }

      if (individualRegistrationSameDate.length > 1) {
        return individualRegistrationSameDate.reduce((prev, current) =>
          Number(prev.pab_id) > Number(current.pab_id) ? prev : current,
        );
      }

      return null;
    },

    savePatientRecordFoundBasicAttention() {
      this.modalPatientRecordFoundBasicAttention = false;
      this.saveSchedule();
    },

    changePatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = false;
      this.saveSchedule();
    },

    async manageDocuments() {
      if (!this.isIndividual) return true;

      const currentCpf = this.patientCpf;
      const newCpf = this.removeDashesAndDots(this.document.cpf);

      const currentCns = this.patientCns;
      const newCns = this.document.cns;

      if (currentCpf === newCpf && currentCns === newCns) return true;

      const cpf = await this.manageCpf(this.pesId);
      const cns = await this.manageCns(this.pesId);

      if (!cpf || cpf.ManageCpfSchedulingPatient.success === false) {
        return false;
      }

      if (!cns || cns.ManageCnsSchedulingPatient.success === false) {
        return false;
      }

      if (this.$refs.patient) {
        this.$refs.patient.fillPatientById(this.pesId);
      }

      return true;
    },

    async manageCpf(pPesId) {
      let variables = {};
      const removeCpf = this.hasCpf && !this.document.cpf;

      try {
        if (removeCpf) {
          variables = {
            pes_id: pPesId,
            cpf: {},
          };
        } else {
          variables = {
            pes_id: pPesId,
            cpf: {
              cpf_id: this.document.cpf_id || null,
              cpf_numero: this.document.cpf
                ? this.removeDashesAndDots(this.document.cpf)
                : "",
            },
          };
        }

        return await this.$store.dispatch(
          "Person/MANAGE_CPF_TO_PERSON",
          variables,
        );
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      }
    },

    async manageCns(pPesId) {
      let variables = {};

      const removeCns = this.hasCns && !this.document.cns;

      try {
        if (removeCns) {
          variables = {
            pes_id: pPesId,
            cns: {},
          };
        } else {
          variables = {
            pes_id: pPesId,
            cns: {
              crs_id: this.document.crs_id || null,
              crs_numero: this.document.cns,
            },
          };
        }

        return await this.$store.dispatch(
          "Person/MANAGE_CNS_TO_PERSON",
          variables,
        );
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      }
    },

    async saveIndividualPatientRegistrationForm() {
      this.modalIndividualPatientRegistrationForm = false;
      this.saveSchedule();
    },

    async inactivatePatientBondBasicAttention(paeId = null) {
      const INATIVO = 0;
      const dataAbEsusInactive = {
        pae_id: paeId || this.dataPatientBasicAttention.pae_id,
        pae_ativo: INATIVO,
        pae_ultima_alteracao: DateNow(),
      };
      this.updatePatientBondBasicAttention(dataAbEsusInactive);
    },

    async getIndividualRegistrationWithGreaterDate() {
      const patientBaseRecordId = this.dataPatientBasicAttention
        ?.pae_ficha_base;

      const individualRegistrationSameId = this.individualRegistrationList.find(
        (x) =>
          Number(x.pab_id) === patientBaseRecordId ||
          Number(x.pab_id_payload) === patientBaseRecordId,
      );

      const individualRegistrationSameIdDate = new Date(
        individualRegistrationSameId.pab_data_atendimento,
      );

      const maxDate = new Date(
        Math.max(
          ...this.individualRegistrationList.map((item) => {
            return new Date(item.pab_data_atendimento);
          }),
        ),
      );

      if (individualRegistrationSameIdDate.getTime() === maxDate.getTime()) {
        return individualRegistrationSameId;
      }

      return null;
    },

    showModalPatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = true;
    },

    closeModalIndividualPatientRegistrationForm() {
      this.modalIndividualPatientRegistrationForm = false;
    },

    closeModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
    },

    closeModalPatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = false;
    },

    showModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = true;
    },

    async showNewRecordFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
      this.lastIndividualRegistration = await this.getLastIndividualRegistrationByDate();
      this.modalIndividualPatientRegistrationForm = true;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async setDaysPossible() {
      try {
        if (!Number(this.form.responsibleProfessional)) {
          return;
        }

        this.$loader.start("Carregando datas...");
        const pData = {
          intIdSetor: this.form.sector,
          intIdOcupacao: this.occupationIdFromList,
          intIdPessoa: Number(this.form.responsibleProfessional),
          intIdUnidadeSaude: this.form.unitHealth,
          strDataInicial: moment().format("DD/MM/YYYY"),
          strDataFinal: moment().add("years", 1).format("DD/MM/YYYY"),
        };

        this.periodsDate = await this.$store.dispatch(
          "Appointment/Schedule/GET_DAY_ON_WEEK_FOR_PERIODS",
          pData,
        );

        const isArrayPeriodDate = this.periodsDate?.length > 0;
        const hasPeriodDate = isArrayPeriodDate && this.periodsDate !== 0;

        if (hasPeriodDate) {
          this.periodsDate = this.periodsDate.map((item) => {
            return {
              eag_inicio_atendimentos: item.eag_inicio_atendimentos.substr(
                0,
                5,
              ),
              data: item.data,
              eag_id: item.eag_id,
              hag_id: item.hag_id,
              eag_id_dias_semana: item.eag_id_dias_semana,
              eag_quantidade_consultas: item.eag_quantidade_consultas,
              eag_intervalo_consultas: item.eag_intervalo_consultas,
              eag_quantidade_marcadas: item.eag_quantidade_marcadas,
            };
          });
        } else {
          this.$toaster.info(
            "Escolha outro profissional com datas disponíveis para realizar o agendamento.",
            "O Profissional não possui escala cadastrada",
          );
        }

        this.clearScaleHour();

        this.datesEnable = [];

        if (hasPeriodDate) {
          this.datesEnable = this.periodsDate.map((item) => {
            return moment(item.data, "DD-MM-YYYY").format("YYYY-MM-DD");
          });
        }

        const currentDate =
          this.form.date &&
          moment(this.form.date, "DD-MM-YYYY").format("YYYY-MM-DD");

        if (this.datesEnable && this.datesEnable.includes(currentDate)) {
          this.changeDate(this.form.date);
          this.$refs.scale.fillData();
        } else {
          this.form.date = null;
          this.$refs.date.cleanValidate();
        }
      } catch (Err) {
        this.periodsDate = [];
        this.datesEnable = [];
        this.$toaster.error(Err, "Erro na busca pelas datas");
      } finally {
        this.$loader.finish();
      }
    },

    getAttributeDayByDate(pDate, pAtrr) {
      pDate = pDate.replaceAll("-", "/");
      if (this.periodsDate.length <= 0) return;
      return this.periodsDate.find((item) => item.data === pDate)[pAtrr];
    },

    editPatient() {
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(this.pesId));
      this.$router.push({
        name: "appointment.schedule.queue.schedule.edit-patient",
      });
      this.activeRow = null;
    },

    async patientPrintCard() {
      try {
        if (this.pesId) {
          const html = await this.$store.dispatch(
            "Person/Patient/PRINT_PATIENT_CARD",
            { pes_id: Number(this.pesId) },
          );

          this.$utils.print.printHtml(html);
        }
      } catch (Err) {
        this.$toaster.error(
          "Erro ao gerar a impressão da carteirinha do paciente",
        );
      }
    },

    patientCareHistory() {
      const patientInfo = this.selectedPatient;

      if (this.isIndividual) {
        this.$router.push({
          name: "appointment.schedule.queue.schedule.patient-care-history",
          params: patientInfo,
        });
      } else {
        this.$router.push({
          name:
            "appointment.schedule.queue.batch.schedule.patient-care-history",
          params: patientInfo,
        });
      }
    },

    removePatientToList() {
      if (this.mutableListPatient && this.mutableListPatient.length > 1) {
        this.mutableListPatient.splice(this.indexPatient, 1);
        this.activeRow = null;
      }
    },

    selectLine(item, index) {
      this.activeRow = index;
      this.pesId = Number(item.pes_id);
      this.indexPatient = index;
      this.patientCpf = item.pep_cpf_numero;
      this.patientCns = item.pep_cartaosus;
      this.selectedPatient = item;
    },

    async changeToAvailableHourAndSave() {
      try {
        this.form.hour = this.unavailableHour.nextAvailableHour;
      } catch (err) {}
      this.closeModalUnavailableHour();
    },

    closeModalUnavailableHour() {
      this.unavailableHour.show = false;
      this.unavailableHour.message = "";
      this.unavailableHour.nextAvailableHour = "";
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    confirmCleanForm() {
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },

    clearScaleHour() {
      this.form.scale = null;
      this.getNumberScale = null;
      this.selectedScale = null;
      this.form.hour = null;
      this.$refs.scale.cleanValidate();
      if (this.$refs.hour) this.$refs.hour.cleanValidate();
      if (this.$refs.comboHour) this.$refs.comboHour.cleanValidate();
    },

    clearForm() {
      this.form.sector = null;
      this.form.date = null;
      this.form.hour = null;
      this.form.locale = null;
      this.form.healthPlan = null;
      this.form.objective = null;
      this.$refs.unit.cleanValidate();
      this.$refs.sector.cleanValidate();
      this.$refs.locale.cleanValidate();
      this.$refs.employee.cleanValidate();
      this.$refs.healthPlan.cleanValidate();
      this.$refs.objective.cleanValidate();
      this.$refs.date.cleanValidate();
      this.$refs.scale.cleanValidate();
      this.getNumberScale = null;
      this.selectedScale = null;
      if (this.$refs.hour) this.$refs.hour.cleanValidate();
      if (this.$refs.comboHour) this.$refs.comboHour.cleanValidate();
    },
  },
};
</script>
