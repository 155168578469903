import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import EmergencyAdministrativeCheckOutRegister from "$emergency/administrative-check-out/views/register/EmergencyAdministrativeCheckOutRegister";

export default {
  path: "/emergency/administrative-check-out/register",
  name: "emergency.administrative-check-out.register",
  components: {
    default: EmergencyAdministrativeCheckOutRegister,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      {
        label: "Alta Administrativa",
        link: "/emergency/administrative-check-out/search",
      },
      {
        label: "Registrar Alta",
      },
    ],
  },
};
