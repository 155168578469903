import Vue from "vue";
import Vuex from "vuex";

import Login from "$access-control/store";
import UnitHealth from "$unit-health/store";
import Billing from "$billing/store";
import Bioslab from "$bioslab/store";
import Person from "$person/store";
import Home from "$home/store";
import Patient from "$patient/store";
import Exam from "$exam/store";
import Hospitalization from "$hospitalization/store";
import Appointment from "$appointment/store";
import Emergency from "$emergency/store";
import SurgeryCenter from "$surgery-center/store";
import Employee from "$employee/store";
import Queue from "$queue/store";
import Notifications from "$notifications/store";
import Configuration from "$configuration/store";
import User from "$user/store";
import Pharmacy from "$pharmacy/store";
import BasicAttention from "$basic-attention/store";

import MainMenu from "~tokio/foundation/main-header/main-menu/store";
import Upload from "./upload/";
import Pagination from "./pagination/";

import createdPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const vuex = {
  modules: {
    Login,
    Billing,
    Bioslab,
    Person,
    Patient,
    Exam,
    Hospitalization,
    Appointment,
    Emergency,
    SurgeryCenter,
    Employee,
    Queue,
    Notifications,
    User,
    Home,
    Pharmacy,
    UnitHealth,
    BasicAttention,
    MainMenu,
    Configuration,
    Upload,
    Pagination,
  },
};

if (process.env.NODE_ENV !== "test") vuex.plugins = [createdPersistedState()];
export default new Vuex.Store(vuex);
