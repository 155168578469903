<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import GetWatingPlaces from "./action";

export default {
  name: "RgSelectWaitingPlace",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Local de Espera",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      let data = [];

      data = await GetWatingPlaces({
        arrFiltro: {
          ile_nome: null,
        },
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.ile_id,
          label: `${item.ile_nome}`,
        };
      });
    },
  },
};
</script>
