import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/schedule/per-day/edit/form/edit-patient",
  name: "appointment.schedule.edit.form.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Agendamento", link: OpenSubModule("appointment") },
      { label: "Agendamentos por Dia", link: "/appointment/schedule" },
      {
        label: "Editar Consulta",
        link: "/appointment/schedule/per-day/edit/form",
      },
      { label: "Cadastro do Paciente" },
    ],
  },
};
