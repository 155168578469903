import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchComplexity(
    $procedureCode: String
    $procedureName: String
    $complexity: String
    $cid: String
    $register: String
    $occupation: String
    $isCustom: Int
    $limit: Int
    $offset: Int
  ) {
    SearchComplexity(
      procedureCode: $procedureCode
      procedureName: $procedureName
      complexity: $complexity
      cid: $cid
      register: $register
      occupation: $occupation
      isCustom: $isCustom
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        complexityCode
        complexityName
        customComplexityExist
        idCustom
        procedureCode
        procedureName
        quantity
        instrument
        cid
        occupation
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchComplexity;
  } catch (err) {
    return Promise.reject(err);
  }
};
