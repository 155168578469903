<template>
  <RgSuggest
    :id="id"
    ref="suggest"
    v-model="inputValue"
    :label="label"
    :search-on-focus="searchOnFocus"
    :placeholder="placeholder"
    :rules="rules"
    :title="inputValue"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="rg-suggest-hospitalization-exit-groups _fit-content"
    >
      <div class="items _fit-content">
        <span class="item">{{ formatItem(item) }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchHospitalizationExitGroups from "./action/SearchHospitalizationExitGroups";
import { isEmpty } from "lodash";
export default {
  name: "RgSuggestHospitalizationExitGroups",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Motivo da Saída",
    },
    min: {
      type: String,
      default: "1",
    },
    placeholder: {
      type: String,
      default: "Digite o motivo da saída",
    },
    title: {
      type: String,
      default: "Motivo da Saída",
    },
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      captionRaw: "igs_nome",
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    doSearch(searchQuery, pLimit = null) {
      return SearchHospitalizationExitGroups({
        igs_nome: searchQuery,
        igs_ativo: this.active,
        limit: pLimit,
      });
    },
    formatItem(pItem) {
      return pItem.igs_nome;
    },
    validate() {
      this.$refs.suggest.validate();
    },
    cleanValidate() {
      this.$refs.suggest.cleanValidate();
    },
    forceSelection(pValue) {
      const hasData = !isEmpty(pValue);
      const isValidData = hasData && pValue.igs_nome !== null;

      if (hasData && isValidData) {
        this.$refs.suggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            igs_nome: pValue.igs_nome,
            igs_ativo: pValue.igs_ativo,
          });
        }

        this.$refs.suggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
