<template lang="html">
  <div class="rg-content-hospitalization-billing-childbirth-tincture">
    <RgContentHospitalizationTeam
      :procedure="procedure"
      :required-documents="requiredDocuments"
      @setProfessionalList="setProfissionalList"
    />
    <form class="form">
      <RgValidatorForm ref="validator">
        <FormBase title="Parto com Laqueadura">
          <div
            class="rg-content-hospitalization-billing-childbirth-tincture-info"
          >
            <RgInputMasked
              v-model="childbirthTincture.ipa_numero_sis_prenatal"
              :rules="{ required: true }"
              label="Número SIS Pré Natal"
              type="number"
              placeholder="Digite o Número do SIS"
              mask="############"
              class="inputitem"
            />
            <RgInputMasked
              v-model="childbirthTincture.ipa_qtd_nascidos_vivos"
              :rules="{ required: true }"
              label="Nascidos/Vivos"
              type="number"
              placeholder="0"
              mask="#"
              class="inputitem"
            />
            <RgInputMasked
              v-model="childbirthTincture.ipa_qtd_obito_fetal"
              :rules="{ required: true }"
              label="Óbito/Fetal"
              type="number"
              placeholder="0"
              mask="#"
              class="inputitem"
            />
            <RgInputMasked
              v-model="childbirthTincture.ipa_qtd_alta"
              :rules="{ required: true }"
              label="Alta(s)"
              type="number"
              placeholder="0"
              mask="#"
              class="inputitem"
            />
            <RgInputMasked
              v-model="childbirthTincture.ipa_qtd_transferencia"
              :rules="{ required: true }"
              label="Transferência(s)"
              type="number"
              placeholder="0"
              mask="#"
              class="inputitem"
            />
            <RgInputMasked
              v-model="childbirthTincture.ipa_qtd_obito"
              :rules="{ required: true }"
              label="Óbito(s)"
              type="number"
              placeholder="0"
              mask="#"
              class="inputitem"
            />
          </div>
          <div
            class="rg-content-hospitalization-billing-childbirth-tincture-info"
          >
            <RgInputMasked
              v-model="childbirthTincture.ilv_qtd_filhos"
              :rules="{ required: true }"
              label="Quantidade de Filhos"
              placeholder="0"
              mask="##"
              class="inputitem"
            />

            <RgRadioCustom
              id="rg-radio"
              v-bind="propsRadioCustom"
              class="rg-radio inputitem"
              label="Gestação Alto Risco"
              :canUnselect="false"
              @input="onInputRadioCustom"
            />

            <RgComboboxInstructionLevel
              v-model="childbirthTincture.ilv_id_internacoes_grau_instrucoes"
              :rules="{ required: true }"
              label="Grau de Instrução"
              class="inputitem"
            />

            <RgComboboxCid
              v-model="childbirthTincture.ilv_codigo_cid10"
              :procedure="procedure.iap_codigo_procedimentos"
              :rules="{ required: true }"
              label="CID Principal"
              title="Classificação Internacional de Doenças"
              class="inputitem"
              @input="cidSelected"
            />

            <RgComboboxContraceptiveMethod
              v-model="
                childbirthTincture.ilv_id_internacoes_metodos_contraceptivos1
              "
              :rules="{ required: true }"
              label="Método Contraceptivo 1"
              class="inputitem"
            />

            <RgComboboxContraceptiveMethod
              v-model="
                childbirthTincture.ilv_id_internacoes_metodos_contraceptivos2
              "
              :rules="{ required: true }"
              label="Método Contraceptivo 2"
              class="inputitem"
            />
          </div>
        </FormBase>
        <div class="buttons">
          <MediumButton
            :disabled="!childbirthTincture.professionalTeamList.length > 0"
            title="Confirmar"
            label="Confirmar"
            backgroundColor="#0bb98c"
            @click="confirm"
          />

          <MediumButton
            title="Fechar"
            label="Fechar"
            @click.stop.prevent="close"
          />
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import {
  RgValidatorForm,
  RgInputMasked,
  MediumButton,
  RgRadioCustom,
} from "~tokio/primitive";
import RgComboboxCid from "$billing/common/component/rg-combobox-cid/RgComboboxCid";
import RgContentHospitalizationTeam from "$billing/submodules/aih/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import RgComboboxInstructionLevel from "../../../combobox/rg-combobox-instruction-level/RgComboboxInstructionLevel";
import RgComboboxContraceptiveMethod from "../../../combobox/rg-combobox-contraceptive-method/RgComboboxContraceptiveMethod";
import mask from "vue-the-mask";
import { AlertError } from "../../../../service";
import FormBase from "~tokio/foundation/form-base/FormBase";
export default {
  name: "RgContentHospBillChildbirthTincture",
  components: {
    RgValidatorForm,
    RgInputMasked,
    RgContentHospitalizationTeam,
    RgComboboxInstructionLevel,
    RgComboboxContraceptiveMethod,
    RgComboboxCid,
    MediumButton,
    FormBase,
    RgRadioCustom,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      professionalCaptionRaw: "pes_nome",
      childbirthTincture: {
        type: "childbirth-tincture",
        professionalTeamList: [],
      },
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Sim", id: true },
        { title: "Não", id: false },
      ];

      const value = false;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.childbirthTincture = this.procedure.additionalData[0];
    }
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.childbirthTincture.ilv_gestacao_alto_risco = item.id;
      }
    },
    async confirm(pEvent) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.childbirthTincture);
      }
    },

    setProfissionalList(pEvent) {
      this.childbirthTincture.professionalTeamList = pEvent;
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      if (!this.validateNumberOfBirth()) return false;
      // if (!this.validateTeamMembers()) return false
      return valid;
    },

    validateNumberOfBirth() {
      const total =
        parseInt(this.childbirthTincture.ipa_qtd_obito) +
        parseInt(this.childbirthTincture.ipa_qtd_transferencia) +
        parseInt(this.childbirthTincture.ipa_qtd_alta);
      if (parseInt(this.childbirthTincture.ipa_qtd_nascidos_vivos) < total) {
        AlertError(
          "Número de altas, transferências e óbitos não devem ultrapassar o total de nascidos vivos",
        );
        return false;
      }
      if (
        parseInt(this.childBirth.ipa_qtd_nascidos_vivos) <
        this.childBirth.ipa_qtd_obito_fetal
      ) {
        AlertError(
          "O total de nascidos vivos não devem ser menor do que o total de obito fetal",
        );
        return false;
      }

      return true;
    },

    validateTeamMembers() {
      const hasAnesthetist = this.childbirthTincture.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 6,
      );
      const hasSurgeon = this.childbirthTincture.professionalTeamList.filter(
        (prof) => prof.ieq_id_internacoes_funcoes_profissionais === 1,
      );
      if (hasAnesthetist.length && hasSurgeon.length) {
        return true;
      }
      AlertError(
        "Necessário ao menos um cirurgião e um anestesista na equipe.",
      );
      return false;
    },

    cidSelected(pData) {
      if (pData && pData[0].text) {
        this.childbirthTincture.ilv_codigo_cid10 = pData[0].text;
      }
    },
  },
};
</script>
