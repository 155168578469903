var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"rg-input--component",class:{ activated: _vm.hasFocus },attrs:{"tabindex":"-1"},on:{"focus":_vm.focusMe,"blur":_vm.blurMe}},[_c('RgFormBase',{attrs:{"label":_vm.label,"required":_vm.isRequired,"title":_vm.title}},[_c('div',{attrs:{"slot":"right-label"},slot:"right-label"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error.length > 0),expression:"error.length > 0"}],staticClass:"rg-input--alert-over-positioning"},[_c('RgValidationAlert',{staticClass:"rg-input--icon",attrs:{"alert":_vm.error}})],1)]),_c('div',{staticClass:"rg-input--base"},[_c('div',{staticClass:"rg-input--textbox-container"},[_c('div',{staticClass:"rg-input--search-icon"},[_c('IconSearch',{staticClass:"icon-search"})],1),_vm._v(" "+_vm._s(_vm.selected)+" "),_c('div',{staticClass:"rg-input--side-action"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWait),expression:"showWait"}],staticClass:"rg-input--btn-calendar"},[_c('div',{staticClass:"rg-input-wait"})])]),_c('div',{},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",staticClass:"rg-input--typeahead-input",class:{
              'rg-input--typeahead-input--selected': _vm.selectedValidValue,
              'on-error': _vm.error.length > 0,
            },attrs:{"disabled":_vm.disable,"placeholder":_vm.placeholder,"tabindex":_vm.tabIndex,"type":"text","autocomplete":"off"},domProps:{"value":(_vm.inputValue)},on:{"keyup":_vm.search,"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.navigate.apply(null, arguments)},"focus":_vm.focusMe,"blur":_vm.blurMe,"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearching),expression:"isSearching"}],ref:"searchBox",staticClass:"rg-input--searchbox-result"},[_c('ul',{staticClass:"rg-input--seachbox-list"},_vm._l((_vm.items),function(item,i){return _c('div',{key:i,class:{
                  'rg-input--search-item': true,
                  hover: i === _vm.navigation,
                },on:{"click":function($event){return _vm.select(item, i)}}},[_vm._t("item",function(){return [_c('li',{domProps:{"innerHTML":_vm._s(item.text)}})]},{"item":item})],2)}),0)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }