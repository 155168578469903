<template>
  <div v-if="show" class="modal-inactive-exams">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-inactive-exams-header">
        <span class="title">Excluir Exames Inativos</span>
      </div>

      <div slot="body" class="modal-inactive-exams-body">
        <div class="information">
          Somente exames ativos são agendados. Os
          <strong>procedimentos inativos abaixo serão excluídos</strong>
          automaticamente do pedido ao prosseguir com o agendamento:
        </div>

        <FormBase title="Exames Inativos" class="content-form">
          <RgTable :columns="COLUMNS" class="modal-inactive-exams-rgtable">
            <tr
              v-for="exam in exams"
              :key="exam.lle_id"
              slot="rows"
              class="modal-inactive-exams-tr"
              :class="{ 'active-rowns': compareActiveRows(exam) }"
              @click="selectRowns(exam)"
            >
              <td class="td">{{ exam.lle_procedimentos }}</td>

              <td class="td">{{ exam.lle_codigo_bioslab_mne }}</td>
            </tr>
          </RgTable>
        </FormBase>
      </div>

      <div slot="footer" class="modal-inactive-exams-footer">
        <RgCancelButton medium @click="close" />

        <LargeButton
          v-shortkey="['enter']"
          label="Excluir e Agendar"
          :backgroundColor="'#F96B70'"
          @click="removeInactiveExams"
        >
          <div slot="icon">
            <IconDanger />
          </div>
        </LargeButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCancelButton,
  LargeButton,
  IconDanger,
} from "~tokio/primitive";

import { FormBase, RgTable } from "~tokio/foundation/";

export default {
  name: "ModalInactiveExams",
  components: {
    RgBaseModal,
    FormBase,
    RgTable,
    RgCancelButton,
    LargeButton,
    IconDanger,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    exams: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      arrayExams: [],
    };
  },

  created() {
    this.COLUMNS = [
      { name: "Exame", key: "lle_procedimentos" },
      { name: "Código", key: "lle_codigo_bioslab_mne" },
    ];
  },

  methods: {
    selectRowns(pValue) {
      const existRow = this.arrayExams.findIndex(
        (item) => item.lle_id === pValue.lle_id,
      );

      if (existRow === -1) this.arrayExams.push(pValue);
      else {
        this.arrayExams.splice(existRow, 1);
      }
    },
    compareActiveRows(pValue) {
      const arrayId = this.arrayExams.map((item) => item.lle_id);
      return arrayId.includes(pValue.lle_id);
    },

    removeInactiveExams() {
      this.$emit("removeInactiveExams", this.arrayExams);
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
