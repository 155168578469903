import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";
const arrayExceptions = {
  ExclusaoFornecedorException: `Não foi possível excluir. Este registro está vinculado com outros registros no sistema. `,
  FornecedorPossuiMovimentacaoException: `Fornecedores com notas fiscais vinculadas não podem ser excluídos.`,
};
export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "farmacia/controller-fornecedor/excluir",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { arrayExceptions });
    })
    .catch((pErr) => {
      console.log(pErr);
      throw pErr;
    });
};
