import Request from "~common/request";
import GetOperationsPermissions from "~tokio/foundation/main-header/main-menu/menu-map/GetOperationsPermissions";
import { LEGACY_SERVER_CONFIG, SERVER_CONFIG } from "~utils/Config";

const EXTRA_PERMISSIONS = [
  "cadastro.pessoa.alterar",
  "cadastro.configuracoes.configurar",
];

export default async (credentials, useLegacyBackend) => {
  const request = new Request(SERVER_CONFIG);
  const requestLegacy = new Request(LEGACY_SERVER_CONFIG);

  try {
    const namespaces = GetOperationsPermissions().concat(EXTRA_PERMISSIONS);
    const loginResponse = await request.post(
      "/access-control/login-light",
      Object.assign({ namespaces }, credentials),
    );
    let legacyInfo = {
      token: null,
    };
    if (useLegacyBackend === true) {
      legacyInfo = await requestLegacy
        .post("/niveisacesso/login-sem-permissoes", {
          usuario: credentials.username,
          senha: credentials.password,
          cliente: credentials.client,
        })
        .then((response) => {
          return response.data;
        });
    }

    return {
      data: loginResponse.data,
      legacy: legacyInfo,
    };
  } catch (err) {
    throw new Error("Falha ao Realizar Login");
  }
};
