import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationSolicitation(
    $bol_numero: Int
    $pes_nome: String
    $unidentifiedPatient: Boolean
    $fun_id: Int
    $initialDateSolicitation: String
    $finalDateSolicitation: String
    $limit: Int
    $offset: Int
  ) {
    hospitalizationSolicitation(
      bol_numero: $bol_numero
      pes_nome: $pes_nome
      unidentifiedPatient: $unidentifiedPatient
      fun_id: $fun_id
      initialDateSolicitation: $initialDateSolicitation
      finalDateSolicitation: $finalDateSolicitation
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        ins_id
        ins_sinais_sintomas
        ins_justificativa_internacao
        ins_resultados
        ins_diagnostico_inicial
        ins_codigo_procedimento_pep
        ins_nome_procedimento_pep
        data_hora
        pac_id
        paciente
        pacienteId
        pep_sexo
        profissional
        profissionalId
        uns_nome
        set_nome
        lca_nome
        bol_numero
        ocp_nome
        cid_id
        cid_codigo
        cid_nome
        inp_novo_nome_procedimento
        ica_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.hospitalizationSolicitation;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
