<style src="./SpecialtiesAttendances.scss" lang="scss" scoped></style>
<template>
  <div class="grid">
    <div class="selectinput">
      <RgSelectUnithealth
        id="uns-id-sa"
        v-model="form.uns_id"
        :rules="{ required: true }"
        permission="emergencia.relatoriosESUSng.Exibir"
        class="inputitem"
      />
    </div>
    <div class="filter-subtitle filter-title">
      <span class="period-subtitle">Período</span>
    </div>
    <div class="selectinput period">
      <RgInputDate
        id="initial-date-sa"
        v-model="form.initialDate"
        :rules="{ required: true }"
        class="inputitem"
        label="Data inicial"
        placeholder="Informe a data de início"
        pop-up="bottom"
      />

      <RgInputDate
        id="final-date-sa"
        v-model="form.finalDate"
        :rules="{ required: true }"
        class="inputitem"
        label="Data final"
        placeholder="Informe a data final"
        pop-up="bottom"
      />
    </div>

    <div class="selectinput-radio">
      <label class="radio">
        <input
          id="closed-sa"
          v-model="form.closed"
          :value="true"
          type="radio"
          class="rg-radio"
          name="specialties-bam-status"
        />
        <span>Com Saída</span>
      </label>
      <label class="radio">
        <input
          id="closed-sa"
          v-model="form.closed"
          :value="false"
          type="radio"
          class="rg-radio"
          name="specialties-bam-status"
        />
        <span>Sem Saída</span>
      </label>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgSelectUnithealth } from "~tokio/primitive";
import moment from "moment";

export default {
  name: "SpecialtiesAttendances",
  components: {
    RgInputDate,
    RgSelectUnithealth,
  },
  filters: {
    formatDateBR(pDate) {
      return moment(pDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Entrada e Saída de Boletim por Especialidade",
      form: {
        initialDate: moment().format("DD/MM/YYYY"),
        finalDate: moment().add(2, "days").format("DD/MM/YYYY"),
        uns_id: 0,
        closed: true,
      },
      list: {
        opened: [],
        closed: [],
        total: 0,
        totalClosed: 0,
        totalOpened: 0,
      },
      printCalculate: false,
      showPrint: false,
      hashRefresh: "123",
      linkTo: "/emergency/print/specialties-attendances",
      pagination: {
        limit: 10,
        offset: 0,
        total: 0,
        current: 1,
      },
    };
  },
  computed: {
    printLink() {
      const completeFilter = this.buildFilter();
      completeFilter.form.closed = this.form.closed;
      delete completeFilter.limit;
      delete completeFilter.offset;
      const FILTER = JSON.stringify(completeFilter);
      const AUX = `${this.linkTo}?filter=${FILTER}&${this.hashRefresh}`;
      return encodeURI(AUX);
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "SpecialtiesAttendances";
    },
    useIndexAsKey() {
      return true;
    },
  },
  watch: {},
  created() {
    this.COLUMN = [
      { name: "BAM", key: "bol_numero" },
      { name: "Nome do Paciente", key: "pes_nome" },
      { name: "Data de Atendimento", key: "bol_data" },
      { name: "Hora do Atendimento", key: "bol_hora" },
      { name: "Especialidade", key: "esp_nome" },
    ];
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    // this.form.initialDate = moment().format("DD-MM-YYYY");
    // this.form.finalDate = moment().add(2, "days").format("DD-MM-YYYY");
  },
  methods: {
    buildFilter() {
      const data = {
        uns_id: this.form.uns_id,
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
        closed: this.form.closed,
        columnsToPrint: this.columnsToPrint,
      };
      return data;
    },
    printReportCss() {
      return (
        ".report-column-1-print { width: 200px; }" +
        ".report-column-2-print { width: 100px; }" +
        ".report-column-3-print { width: 200px; }" +
        ".report-column-4-print { width: 130px; }" +
        ".report-column-5-print { width: 130px; }" +
        ".report-column-6-print { width: 130px; }" +
        "tbody tr:nth-of-type(odd) { background-color: rgba(0,0,0,.05); }"
      );
    },
    clearFilter() {
      this.form = {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"] || 0,
        initialDate: moment().format("DD/MM/YYYY"),
        finalDate: moment().add(2, "days").format("DD/MM/YYYY"),
        closed: true,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Emergency/Report/GET_ATTENDANCES_SPECIALTIES",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    formatDate(pDate) {
      this.$utils.date.BrazilianDateToDatabase(pDate);
    },
  },
};
</script>
