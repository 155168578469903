import SEARCH_HOSPITALIZATION_QUEUE from "./SearchHospitalizationQueue";
import ADD_HOSPITALIZATION_QUEUE from "./AddHospitalizationQueue";
import EDIT_HOSPITALIZATION_QUEUE from "./EditHospitalizationQueue";
import SEARCH_HOSPITALIZATION_QUEUES_PER_PERSON from "./SearchHospitalizationQueuesPerPerson";
import BACK_PATIENT_QUEUE from "./BackPatientQueue";
import MOVE_PATIENT_QUEUE from "./MovePatientQueue";
import ADVANCE_PATIENT_QUEUE from "./AdvancePatientQueue";
import REGULATION_HOSPITALIZATION_QUEUE from "./RegulationHospitalizationQueue";
import UNREGULATE_HOSPITALIZATION_QUEUE from "./UnregulateHospitalizationQueue";
import REMOVE_HOSPITALIZATION_QUEUE from "./RemoveHospitalizationQueue";
import GET_SCHEDULE_PROOF_INSERTION_QUEUE from "./GetScheduleProofInsertionQueue";
import GET_DATA_SCHEDULE_VOUCHER from "./GetDataScheduleVoucher";
import SAVE_HOSPITALIZATION_EXTERNAL_SCHEDULE from "./SaveHospitalizationExternalSchedule";
import SEARCH_PENDENCY_QUEUE from "./SearchPendencyQueue";
import SAVE_PENDENCY_QUEUE from "./SavePendencyQueue";
import SAVE_RESOLUTION_QUEUE from "./SaveResolutionQueue";
import REMOVE_PENDENCY_QUEUE from "./RemovePendencyQueue";
import REMOVE_RESOLUTION_QUEUE from "./RemoveResolutionQueue";
import SEARCH_PATIENT_OBSERVATION_QUEUE from "./SearchPatientObservationQueue";
import SAVE_OBSERVATION_QUEUE from "./SaveObservationQueue";
import GET_DATA_PATIENT_HOSPITALIZATION_QUEUE from "./GetDataPatientHospitalizationQueue";
import GET_PATIENT_BASIC_INFO from "./GetBasicPatientInfo";
import GET_PRINT_PENDENCY_QUEUE from "./GetPrintPendencyQueue";
import GET_PRINT_DATA_SCHEDULE_REGISTRATION from "./GetPrintDataScheduleRegistration";

import SEARCH_EXTERNALLY_SCHEDULE from "./externally-schedule/SearchHospitalizationExternallySchedule";
import REMOVE_EXTERNALLY_SCHEDULE from "./externally-schedule/RemoveHospitalizationExternallySchedule";
import EDIT_EXTERNALLY_SCHEDULE from "./externally-schedule/EditExternallySchedule";

import SCHEDULE_PATIENT_HOSPITALIZATION from "./search-patient-schedule/SchedulePatientHospitalization";
import DELETE_SCHEDULE_QUEUE from "./search-patient-schedule/DeleteScheduleQueue";
import PRINT_PATIENT_SCHEDULE_LIST from "./search-patient-schedule/print/PrintPatientScheduleList";
import GET_PRINT_SCHEDULE_BATCH from "./search-patient-schedule/print/PrintPatientScheduleVoucher";
import REGISTER_SCHEDULE_VOUCHER_BATCH from "./search-patient-schedule/print/RegisterScheduleVoucherBatch";

import SEARCH_PATIENT_EXCLUDE_IN_QUEUE from "./patient-exclude-queue/SearchPatientExcludeInQueue";
import GET_PRINT_PATIENT_EXCLUDE from "./patient-exclude-queue/GetPrintPatientExclude";
import BACK_PATIENT_FROM_QUEUE from "./patient-exclude-queue/BackPatientFromQueue";

export default {
  SEARCH_HOSPITALIZATION_QUEUE,
  ADD_HOSPITALIZATION_QUEUE,
  EDIT_HOSPITALIZATION_QUEUE,
  SEARCH_HOSPITALIZATION_QUEUES_PER_PERSON,
  BACK_PATIENT_QUEUE,
  MOVE_PATIENT_QUEUE,
  ADVANCE_PATIENT_QUEUE,
  UNREGULATE_HOSPITALIZATION_QUEUE,
  REGULATION_HOSPITALIZATION_QUEUE,
  REMOVE_HOSPITALIZATION_QUEUE,
  GET_SCHEDULE_PROOF_INSERTION_QUEUE,
  GET_DATA_SCHEDULE_VOUCHER,
  SAVE_HOSPITALIZATION_EXTERNAL_SCHEDULE,
  SEARCH_PENDENCY_QUEUE,
  SAVE_PENDENCY_QUEUE,
  SAVE_RESOLUTION_QUEUE,
  REMOVE_PENDENCY_QUEUE,
  REMOVE_RESOLUTION_QUEUE,
  SEARCH_PATIENT_OBSERVATION_QUEUE,
  SAVE_OBSERVATION_QUEUE,
  GET_DATA_PATIENT_HOSPITALIZATION_QUEUE,
  GET_PATIENT_BASIC_INFO,
  GET_PRINT_PENDENCY_QUEUE,
  GET_PRINT_DATA_SCHEDULE_REGISTRATION,
  SEARCH_EXTERNALLY_SCHEDULE,
  REMOVE_EXTERNALLY_SCHEDULE,
  SEARCH_PATIENT_EXCLUDE_IN_QUEUE,
  EDIT_EXTERNALLY_SCHEDULE,
  GET_PRINT_PATIENT_EXCLUDE,
  BACK_PATIENT_FROM_QUEUE,
  SCHEDULE_PATIENT_HOSPITALIZATION,
  DELETE_SCHEDULE_QUEUE,
  PRINT_PATIENT_SCHEDULE_LIST,
  GET_PRINT_SCHEDULE_BATCH,
  REGISTER_SCHEDULE_VOUCHER_BATCH,
};
