import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchUnitHealthType(
    $tus_nome: String
    $tus_codigo: String
    $limit: Int
    $offset: Int
  ) {
    unitHealthTypes(
      tus_nome: $tus_nome
      tus_codigo: $tus_codigo
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        tus_id
        tus_nome
        tus_codigo
        sus {
          tss_codigo
        }
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return prepareData(data.unitHealthTypes);
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};

const prepareData = (pData) => {
  const hasResult = pData?.count > 0;

  if (!hasResult) {
    return pData;
  }
  const data = pData;

  data.rows = pData.rows.map((row) => {
    return { ...row, tss_codigo: row?.sus?.tss_codigo };
  });

  return data;
};
