import IconGeometryCircle from "./GeometryCircle";
import IconGeometryDiamond from "./GeometryDiamond";
import IconGeometryHexagon from "./GeometryHexagon";
import IconGeometryTriangle from "./GeometryTriangle";
import IconGeometrySquare from "./GeometrySquare";
import IconGeometrySuperStar from "./GeometrySuperStar";

export {
  IconGeometryCircle,
  IconGeometryDiamond,
  IconGeometryHexagon,
  IconGeometryTriangle,
  IconGeometrySquare,
  IconGeometrySuperStar,
};
