import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamProfessionalProductivityReport(
    $uns_id: Int
    $set_id: Int
    $ocp_id: Int
    $pes_id: Int
    $scale: Int
    $set_nome: String
    $uns_nome: String
    $ocp_nome: String
    $pes_nome: String
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchExamProfessionalProductivityReport(
      uns_id: $uns_id
      set_id: $set_id
      ocp_id: $ocp_id
      pes_id: $pes_id
      scale: $scale
      uns_nome: $uns_nome
      set_nome: $set_nome
      ocp_nome: $ocp_nome
      pes_nome: $pes_nome
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PROFISSIONAL
        SETOR
        ESCALA
        PROCEDIMENTO
        DISPONIBILIZADOS
        AGENDADOS
        EFETIVADOS
        NAO_EFETIVADOS
        AGUARDANDO
        VAGAS_RESTANTES
        PRODUTIVIDADE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamProfessionalProductivityReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
