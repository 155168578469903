import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import HandleActionError from "@/common/request/error-handlers/HandleActionError";

const query = gql`
  query PharmacyProductCountyType($mtp_id_clientes: Int!, $mtp_id: Int) {
    pharmacyProductCountyType(
      mtp_id_clientes: $mtp_id_clientes
      mtp_id: $mtp_id
    ) {
      count
      rows {
        mtp_id
        mtp_nome
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.pharmacyProductCountyType.rows;
  } catch (error) {
    return Promise.reject(HandleActionError(error));
  }
};
