import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "faturamento/controller-faturamento-servico-classificacao-unidade/obter-classificacoes-por-servico",
    { arrFormData: variables },
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
