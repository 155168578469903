<template lang="html">
  <div v-if="show" class="modal-see-more">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">Vizualizar Detalhes</h1>
      </div>
      <div slot="body" class="body">
        <FormBase :title="title" class="form-base">
          <div class="data">
            <span
              v-for="col in dataToSee"
              :key="col.key"
              class="data-info"
              :title="col.data"
            >
              {{ col.label }}: <b>{{ col.data }}</b>
            </span>
          </div>
          <div v-show="this.dataToSeeMore.length > 0">
            <hr />
            <div
              v-for="col in dataToSeeMore"
              :key="col.key"
              class="data-see-more"
            >
              <span class="data-see-more-title">{{ col.label }}:</span>
              <span class="data-see-more-body">
                {{ col.data }}
              </span>
            </div>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalSeeMore",
  components: {
    RgBaseModal,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    selectedData: {
      type: Object,
      default: () => {},
    },
    columnsToSee: {
      type: Array,
      default: () => [],
    },
    columnToSeeMore: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dataToSee: [],
      dataToSeeMore: [],
    };
  },
  watch: {
    selectedData(pValue) {
      if (pValue && pValue !== null) {
        this.dataToSee = [];
        this.dataToSeeMore = [];
        this.compareData();
      }
    },
    columnsToSee(pValue) {
      if (pValue && pValue.length > 0 && this.selectedData !== null) {
        this.dataToSee = [];
        this.dataToSeeMore = [];
        this.compareData();
      }
    },
  },
  methods: {
    compareData() {
      for (const data of this.columnsToSee) {
        if (data.key === this.columnToSeeMore) {
          this.dataToSeeMore.push({
            label: data.name,
            data: this.selectedData[data.key],
            key: data.key,
          });
        } else {
          this.dataToSee.push({
            label: data.name,
            data: this.selectedData[data.key],
            key: data.key,
          });
        }
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
