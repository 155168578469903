import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query UnitHealthAppointmentExternallyQueue {
    unitHealthAppointmentExternallyQueue {
      rows {
        uns_id
        uns_nome
      }
    }
  }
`;
export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.unitHealthAppointmentExternallyQueue.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
