<script>
import { RgRemoteComboboxMixin } from "~tokio/primitive";
import SearchReasonDiscard from "./action/SearchReasonDiscard";

export default {
  name: "RgSelectReasonDiscard",
  mixins: [RgRemoteComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Motivo",
    },
  },
  data() {
    return {};
  },
  methods: {
    doSearch() {
      return SearchReasonDiscard();
    },
    formatItem(item) {
      return {
        id: item.data,
        value: item.data,
        text: item.label,
      };
    },
  },
};
</script>
