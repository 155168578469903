<style src="./HospitalizationFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="hospitalization-filter">
    <ModuleBox
      :granted="hasPermission"
      class="module-box"
      title="Filtro de busca"
    >
      <form class="form-container" @submit.prevent="submitForm">
        <RgValidatorForm ref="validator" class="validator-form">
          <div class="validator-container">
            <div class="row">
              <div class="col-12">
                <span class="label">Tipo de Busca</span>
                <div class="container">
                  <input
                    id="internacao"
                    name="search"
                    value="I"
                    class="_none"
                    @click="changeAih(false)"
                  />
                  <label
                    :class="{ 'active-open': !form.isAihType }"
                    class="radio-status"
                    for="internacao"
                  >
                    Internação
                  </label>
                  <input
                    id="aih"
                    name="search"
                    value="A"
                    class="_none"
                    @click="changeAih(true)"
                  />
                  <label
                    :class="{ 'active-open': form.isAihType }"
                    class="radio-status"
                    for="aih"
                  >
                    AIH
                  </label>
                </div>
              </div>
            </div>
            <div class="filter-subtitle filter-title">
              <span class="period-subtitle">Período</span>
            </div>
            <div v-if="!form.isAihType" class="row date-field">
              <RgInputDate
                v-model="form.initialDate"
                class="col-6"
                label="Data Inicial"
              />
              <RgInputDate
                v-model="form.finalDate"
                class="col-6"
                label="Data Final"
              />
            </div>
            <div v-else class="row date-field">
              <RgInputDate
                v-model="form.hospitalizationDate"
                class="col-6"
                label="Data de Internação"
              />
              <RgInputDate
                v-model="form.exitDate"
                class="col-6"
                label="Data da Saída"
              />
            </div>

            <div class="row">
              <RgInput
                v-if="!form.isAihType"
                ref="hospitalizationNumberField"
                v-model="form.intNumero"
                v-shortkey="['ctrl', 'i']"
                label="Número da Internação"
                placeholder="Digite o número da Internação"
                class="col-6"
                @shortkey.native="setHospitalizationNumberFieldFocus"
              />

              <RgInput
                v-else
                ref="hospitalizationNumberField"
                v-model="form.iafNumeroAih"
                v-shortkey="['ctrl', 'i']"
                label="Número da AIH"
                placeholder="Digite o número da AIH"
                class="col-6"
                @shortkey.native="setHospitalizationNumberFieldFocus"
              />

              <RgInputMasked
                v-model="form.iafProntuario"
                mask="###############"
                class="col-6"
                label="Prontuário"
                placeholder="Digite o número do prontuário"
              />
            </div>

            <div class="row">
              <RgInput
                ref="personField"
                v-model="form.pesNome"
                v-shortkey="['ctrl', 'p']"
                class="col-12"
                label="Paciente"
                placeholder="Digite o nome do paciente"
                @shortkey.native="setPersonFieldFocus"
              />
            </div>

            <div class="row">
              <!-- <RgRadioHospitalizationFilterStatusOption
                v-if="form.isAihType"
                v-model="form.status"
                :aih="form.isAihType"
                class="col-12"
              /> -->
            </div>
          </div>

          <div class="commands">
            <button class="col-4 btn btn-primary" @click="submitForm">
              Buscar
            </button>
            <button class="col-4 btn btn-primary" @click="billing">Novo</button>
            <button class="col-4 btn btn-clean" @click="cleanForm">
              Limpar
            </button>
          </div>
        </RgValidatorForm>
      </form>
    </ModuleBox>
  </section>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import {
  RgInput,
  RgInputDate,
  RgValidatorForm,
  RgInputMasked,
} from "~tokio/primitive";
// import RgRadioHospitalizationFilterStatusOption from "../../radio/rg-radio-hospitalization-filter-status-option/RgRadioHospitalizationFilterStatusOption";

export default {
  name: "HospitalizationFilter",
  components: {
    ModuleBox,
    RgInput,
    RgInputMasked,
    RgInputDate,
    // RgRadioHospitalizationFilterStatusOption,
    RgValidatorForm,
  },
  data() {
    return {
      form: {
        // HOSPITALIZATION
        intNumero: "",
        initialDate: "",
        finalDate: "",
        // AIH
        iafNumeroAih: "",
        hospitalizationDate: "",
        exitDate: "",
        authorizationDate: "",
        iafProntuario: "",
        // BOTH
        status: "0",
        pesNome: "",
        isAihType: false,
      },
    };
  },
  computed: {
    getUnitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    hasPermission() {
      return this.$Permissions.global.has(
        "faturamento.faturar",
        this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      );
    },
  },
  watch: {
    "form.status"() {
      this.submitForm();
    },
  },
  mounted() {
    this.submitForm();
  },
  methods: {
    submitForm() {
      if (this.validateForm()) {
        const sendObject = this.prepareFilter();
        this.$emit("submit-filter", sendObject);
      }
    },
    billing() {
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_ID",
        null,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_HOSPITALIZATION_BILLING_AIH_ID",
        null,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_PATIENT_UNIT_RECORD_NUMBER",
        null,
      );
      this.$store.commit(
        "Billing/HospitalizationBilling/SET_BILLING_INFO_TAB_AIH_NUMERO_ANTERIOR",
        null,
      );
      this.$router.push("detail");
    },
    prepareFilter() {
      const sendObject = {
        ...this.form,
        ...this.sanitizeFields(),
        uns_id: this.getUnitHealthId,
      };
      return sendObject;
    },
    sanitizeFields() {
      let retObject = {};

      for (const item in this.form) {
        if (this.form[item]) {
          retObject[item] = this.form[item];
        }
      }

      retObject = this.form.isAihType
        ? this.sanitizeAihFields(retObject)
        : this.sanitizeHospitalizationFields(retObject);

      return retObject;
    },
    sanitizeHospitalizationFields(retObject) {
      retObject.iafNumeroAih = null;

      if (retObject.intNumero) {
        retObject.intNumero = parseInt(retObject.intNumero);
      }

      if (retObject.initialDate) {
        retObject.initialDate = this.$utils.date.BrazilianDateToDatabase(
          retObject.initialDate,
        );
      }

      if (retObject.finalDate) {
        retObject.finalDate = this.$utils.date.BrazilianDateToDatabase(
          retObject.finalDate,
        );
      }

      return retObject;
    },
    sanitizeAihFields(retObject) {
      retObject.intNumero = null;

      if (retObject.iafNumeroAih) {
        retObject.iafNumeroAih = parseInt(retObject.iafNumeroAih);
      }

      if (retObject.hospitalizationDate) {
        retObject.hospitalizationDate = this.$utils.date.BrazilianDateToDatabase(
          retObject.hospitalizationDate,
        );
      }

      if (retObject.exitDate) {
        retObject.exitDate = this.$utils.date.BrazilianDateToDatabase(
          retObject.exitDate,
        );
      }

      if (retObject.authorizationDate) {
        retObject.authorizationDate = this.$utils.date.BrazilianDateToDatabase(
          retObject.authorizationDate,
        );
      }

      return retObject;
    },
    setHospitalizationNumberFieldFocus() {
      this.$refs.hospitalizationNumberField.setFocus();
    },
    setPersonFieldFocus() {
      this.$refs.personField.setFocus();
    },
    cleanForm() {
      this.form = {
        initialDate: "",
        finalDate: "",
        pesNome: "",
        intNumero: "",
        iafNumeroAih: "",
        hospitalizationDate: "",
        exitDate: "",
        authorizationDate: "",
        iafProntuario: "",
        status: "0",
        isAihType: this.form.isAihType,
      };
      this.submitForm();
    },
    validateForm() {
      return true;
    },
    changeAih(pItem) {
      this.form.isAihType = pItem;
      this.submitForm();
    },
  },
};
</script>
