<template>
  <div class="pharmacy-satellite">
    <RgSearch
      ref="rgSearch"
      v-model="mutablePharmacySatellite"
      :result-title="'Produtos'"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :searchAreaHeight="tableHeight"
      :item-height="34.5"
      :maxRegister="40"
      :disabled="disabledByModal"
      @count="getCountValue"
      @afterPerformSearch="afterSearchFilter"
    >
      <div slot="filters" class="pharmacy-satellite-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealthPharmacy
              id="unit-health"
              ref="unitHealth"
              v-model="form.unitHealth"
              :typePharmacy="2"
              class="input"
              label="Unidade de Saúde"
              :rules="{ required: true }"
              @change="selectedUnitHealth"
            />
          </div>
          <div class="selectinput">
            <RgSelectPharmacy
              v-model="form.pharmacy"
              :unitHealthId="Number(form.unitHealth)"
              :typePharmacy="2"
              default-text="Selecione"
              class="input"
              :disabled="!form.unitHealth"
              :class="{ disable: !form.unitHealth }"
              :rules="{ required: true }"
              @change="selectedPharmacy"
            />
          </div>
          <div class="selectinput">
            <RgSelectProduct
              v-model="form.product"
              :pharmacyId="Number(form.pharmacy)"
              perClient
              default-text="Selecione"
              class="input"
              :disabled="!form.pharmacy || isRequest"
              :class="{ disable: !form.pharmacy || isRequest }"
            />
          </div>
          <div class="selectinput">
            <RgSuggestPatient
              ref="patient"
              v-model="form.patientName"
              :withInfo="false"
              placeholder="Digite o nome do paciente"
              label="Paciente"
              :disabled="!form.pharmacy || !hasRevenue"
              :class="{ disable: !form.pharmacy || !hasRevenue }"
              @selected="getPatientInfo"
            />
          </div>
          <div class="selectinput">
            <RgInput
              v-model="form.guide"
              :label="labelGuide"
              :placeholder="placeholderGuide"
              :disabled="!form.pharmacy || !hasGuide"
              :class="{ disable: !form.pharmacy || !hasGuide }"
              :rules="{ fn: validateValues }"
            />
          </div>
          <div class="selectinput">
            <RgComboboxPharmacyOrderStatus
              v-model="form.status"
              label="Status"
              :disabled="!form.pharmacy || !hasStatus"
              :isReport="false"
              :class="{ disable: !form.pharmacy || !hasStatus }"
            />
          </div>
          <div class="filter-title">
            <span class="title">Período</span>
          </div>
          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-date"
                ref="initialDate"
                v-model="form.initialDate"
                class="input"
                label="Data Inicial"
                :rules="{ required: true, fn: validateInitialDate }"
                popUp="bottom"
              />
              <RgInputDate
                id="final-date"
                ref="finalDate"
                v-model="form.finalDate"
                :rules="{ required: true, fn: validateFinalDate }"
                class="input"
                label="Data Final"
                popUp="bottom"
              />
            </div>
          </div>
        </div>
        <Tooltip class="tooltip" clickable startOpen>
          <IconInfoHelper slot="icon" hollow />
          <div slot="content" class="content">
            <div class="title">
              <span
                >Após o preenchimento dos campos acima, é necessário selecionar
                um dos filtros disponíveis:</span
              >
              <ul class="list">
                <li class="item">
                  Estão localizados na tela ao lado abaixo do título "Produtos";
                </li>
                <li class="item">
                  Os filtros exibem os resultados correspondentes para cada tipo
                  de Entrada ou Saída.
                </li>
              </ul>
            </div>
          </div>
        </Tooltip>
      </div>
      <div ref="radios" slot="search-top" class="pharmacy-product-top-buttons">
        <div slot="legends" class="radios">
          <RgRadioCustom
            id="status"
            v-bind="propsRadio"
            :disabledByModal="disabledByModal"
            @input="onInputRadioCustom"
          />
        </div>
      </div>

      <div class="pharmacy-satellite-search-list">
        <SmartTable
          ref="smartTable"
          :name="nameSmartTable"
          :columns="collumnsPharmacySatellite"
          :body="mutablePharmacySatellite"
          :dynamic-height="getSpaceTable"
          :total="Number(total)"
          :colorLine="actionColorLine"
          circle-indicator
          :initial-columns="7"
          :index-column="indexPharmacySatellite"
          :second-index-column="secondIndexPharmacySatellite"
          toggle-selected
          :showPagination="false"
          class="smart-table"
          @getLine="selectLine"
        >
          <div slot="top-buttons" class="actions">
            <div class="right-buttons">
              <!-- TODO: ver as permissoes dos botões -->
              <RgEditButton
                id="edicao-movimentacao"
                small
                title="Editar Movimentação do Produto"
                :disabled="!hasEditOption || disabledByModal || isClosedRequest"
                @click="editRequest"
              />
              <RgLessButton
                id="excluir-movimentacao"
                small
                title="Excluir Movimentação do Produto"
                :disabled="
                  !hasDeleteOption || disabledByModal || isClosedRequest
                "
                @click="deleteProduct"
              />
              <RgShowButton
                v-show="!hasShowDropdownOption"
                id="visualizar-movimentacao"
                small
                title="Visualizar Movimentação do Produto"
                :disabled="!hasShowOption || disabledByModal"
                @click="showMovement"
              />
              <RgPrinterButton
                id="impressao"
                small
                title="Imprimir Movimentação do Produto"
                :disabled="!hasPrinterOption || disabledByModal"
                @click="printer"
              />
              <RgDropdown
                v-show="hasShowDropdownOption"
                id="paper-dropdown"
                ref="btnPaper"
                small
                show-down
                show-left
                :action-options="itemActionMovement()"
                :disabled="!this.hasSelectedLine || disabledByModal"
                background-color="#1e88a9"
              >
                <IconShow slot="icon" />
              </RgDropdown>
            </div>
          </div>
        </SmartTable>
      </div>
      <div
        ref="footer"
        slot="menu-bottom"
        class="pharmacy-satellite-bottom-buttons"
      >
        <SmallButton
          id="solicitacao"
          title="Solicitação"
          :backgroundColor="'#1E88A9'"
          :disabled="!hasRequestOption || disabledByModal"
          @click="requestProduct"
        >
          <IconRequestPharmacy slot="icon" />
        </SmallButton>
        <SmallButton
          id="estorno"
          title="Saída por Estorno"
          :permission="hasPermission"
          :backgroundColor="'#1E88A9'"
          :disabled="!hasReversalOption || disabledByModal"
          @click="reversalProduct"
        >
          <IconReversal slot="icon" />
        </SmallButton>
        <SmallButton
          id="receita"
          title="Saída por Receita"
          :disabled="!hasRevenueOption || disabledByModal"
          :backgroundColor="'#1E88A9'"
          @click="revenueProduct"
        >
          <IconRevenue slot="icon" />
        </SmallButton>
        <SmallButton
          id="consumo"
          title="Saída por Consumo"
          :backgroundColor="'#1E88A9'"
          :disabled="!hasConsumptionOption || disabledByModal"
          @click="consumptionTransfer"
        >
          <IconConsumption slot="icon" />
        </SmallButton>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteMovement"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
    <ModalSeeRevenue
      id="modal-see-revenue"
      :show="modalShowRevenue"
      :filters="filters"
      :typeMovement="7"
      :unitHealthId="this.form.unitHealth"
      :pharmacyId="this.form.pharmacy"
      @close="closeModalShowMovement"
    />

    <ModalSeeRequestDetails
      id="modal-see-request-details"
      :show="showModalRequestDetails"
      :requestId="requestId"
      @close="closeModalRequestDetails"
    />
    <ModalTypesPrint
      id="modal-types-print"
      :show="modalTypesPrint"
      :infosPrint="infosPrint"
      :dataPrint="dataPrint"
      :selectedRow="selectedRow"
      @close="closeModalTypesPrint"
    />
    <ModalObservationRequest
      :show="showModalObservationRequest"
      :requestData="observationData"
      seeResults
      @close="closeModalObservationRequest"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import {
  IconRequestPharmacy,
  RgEditButton,
  RgLessButton,
  IconRevenue,
  RgShowButton,
  RgPrinterButton,
  SmallButton,
  IconConsumption,
  RgRadioCustom,
  RgInputDate,
  IconReversal,
  ModalConfirmDeletion,
  Tooltip,
  RgInput,
  IconInfoHelper,
  RgDropdown,
  IconShow,
} from "~tokio/primitive";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import ModalSeeRevenue from "$pharmacy/submodules/satellite/components/modal/modal-see-revenue/ModalSeeRevenue";
import RgComboboxPharmacyOrderStatus from "$pharmacy/common/combobox/rg-combobox-pharmacy-order-status/RgComboboxPharmacyOrderStatus";

import RequestHTML from "$pharmacy/submodules/satellite/html/RequestHTML";
import DeliveryNoteHTML from "$pharmacy/submodules/central/html/DeliveryNoteHTML";

import ModalSeeRequestDetails from "$pharmacy/common/modal/modal-see-request-details/ModalSeeRequestDetails";
import ModalTypesPrint from "$pharmacy/submodules/satellite/components/modal/modal-types-print/ModalTypesPrint";
import RgSuggestPatient from "$pharmacy/common/suggest/rg-suggest-patient/RgSuggestPatient.vue";

import ModalObservationRequest from "$pharmacy/submodules/satellite/components/modal/modal-observation-request/ModalObservationRequest";

const FORM_SATELLITE = {
  unitHealth: null,
  pharmacy: null,
  product: null,
  statusProduct: null,
  initialDate: moment().format("01/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  uns_nome: null,
  far_nome: null,
  guide: null,
  patientName: null,
  status: null,
};

export default {
  name: "PharmacySatelliteSearch",
  components: {
    RgSearch,
    RgEditButton,
    RgLessButton,
    SmartTable,
    IconRequestPharmacy,
    IconRevenue,
    RgShowButton,
    RgPrinterButton,
    ModalTypesPrint,
    SmallButton,
    IconConsumption,
    RgRadioCustom,
    RgSelectUnithealthPharmacy,
    RgSelectPharmacy,
    RgInputDate,
    RgSelectProduct,
    IconReversal,
    ModalConfirmDeletion,
    ModalSeeRevenue,
    Tooltip,
    IconInfoHelper,
    ModalSeeRequestDetails,
    RgInput,
    RgSuggestPatient,
    RgComboboxPharmacyOrderStatus,
    ModalObservationRequest,
    RgDropdown,
    IconShow,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_SATELLITE),
      titleActiveDesactive: "",
      messageActiveDesactive: "",
      msgSuccessActiveDesactive: "",
      labelBtnActiveDesactive: "",
      mutablePharmacySatellite: [],
      editPharmacySatelliteRow: null,
      isEdit: false,
      isActive: false,
      showModalActiveDesactive: false,
      total: 0,
      tableHeight: null,
      selectedRow: null,
      bodyText: null,
      textReason: "",
      requestId: null,
      modalShowMovement: false,
      modalShowRevenue: false,
      filters: null,
      typeMovement: null,
      modalDeleteMovement: false,
      showModalRequestDetails: false,
      infosPrint: {},
      dataPrint: null,
      modalTypesPrint: false,
      showModalObservationRequest: false,
      observationData: [],
    };
  },

  computed: {
    ...mapGetters({
      clientName: "Login/GET_CLIENT_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
      userName: "Login/GET_USER_NAME",
    }),
    disabledByModal() {
      return (
        this.showModalRequestDetails ||
        this.modalShowRevenue ||
        this.modalDeleteMovement
      );
    },

    hasRevenue() {
      return this.form.statusProduct === this.PHARMACY_SATELLITE_REVENUE;
    },
    hasGuide() {
      const options = [
        this.PHARMACY_SATELLITE_REVERSAL,
        this.PHARMACY_SATELLITE_REQUEST,
      ];

      return options.includes(this.form.statusProduct);
    },
    hasStatus() {
      const options = [this.PHARMACY_SATELLITE_REQUEST];

      return options.includes(this.form.statusProduct);
    },
    labelGuide() {
      const options = [this.PHARMACY_SATELLITE_REQUEST];

      return options.includes(this.form.statusProduct)
        ? "Número do Pedido"
        : "Guia de Remessa";
    },
    placeholderGuide() {
      const options = [this.PHARMACY_SATELLITE_REQUEST];

      return options.includes(this.form.statusProduct)
        ? "Digite o número do pedido"
        : "Digite a guia de remessa";
    },
    hasPermission() {
      return !!this.$Permissions.global.has(
        "farmacia.movimentacaoSatelite.estorno",
      );
    },
    getSpaceTable() {
      return this.tableHeight;
    },
    actionColorLine() {
      return {
        get: (item) => {
          if (item.situacao === "ENTRADA") {
            return GetColorRowSmartTable("green");
          } else {
            return GetColorRowSmartTable("blue");
          }
        },
      };
    },
    hasMutablePharmacySatellite() {
      return !(
        this.mutablePharmacySatellite &&
        this.mutablePharmacySatellite.length === 0
      );
    },
    hasSelectedLine() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },

    hasEditOption() {
      const options = [this.PHARMACY_SATELLITE_REQUEST];
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    hasDeleteOption() {
      const options = [
        this.PHARMACY_SATELLITE_REQUEST,
        this.PHARMACY_SATELLITE_REVENUE,
        this.PHARMACY_SATELLITE_CONSUMPTION,
      ];
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    hasShowOption() {
      const options = [
        this.PHARMACY_SATELLITE_REQUEST,
        this.PHARMACY_SATELLITE_REVENUE,
      ];
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    hasShowDropdownOption() {
      const options = [this.PHARMACY_SATELLITE_REQUEST];
      return options.includes(this.form.statusProduct);
    },
    hasPrinterOption() {
      const options = [
        this.PHARMACY_SATELLITE_REQUEST,
        this.PHARMACY_SATELLITE_REVERSAL,
        this.PHARMACY_SATELLITE_REVENUE,
      ];
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },

    hasReversalOption() {
      return this.form.statusProduct === this.PHARMACY_SATELLITE_REVERSAL;
    },
    hasRequestOption() {
      return this.form.statusProduct === this.PHARMACY_SATELLITE_REQUEST;
    },
    hasRevenueOption() {
      return this.form.statusProduct === this.PHARMACY_SATELLITE_REVENUE;
    },
    hasConsumptionOption() {
      return this.form.statusProduct === this.PHARMACY_SATELLITE_CONSUMPTION;
    },

    propsRadio() {
      const list = [
        { title: "Entrada", color: "#03CE63", id: 1 },
        { title: "Solicitação", id: 2 },
        { title: "Estorno", id: 3 },
        { title: "Receita", id: 5 },
        { title: "Consumo", id: 6 },
      ];
      const value = this.form.statusProduct;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    secondIndexPharmacySatellite() {
      const statusProduct = this.form.statusProduct;
      let index = null;
      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_REQUEST:
          index = "mpd_id";
          break;
      }
      return index;
    },
    indexPharmacySatellite() {
      const statusProduct = this.form.statusProduct;
      let index = null;
      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_ENTRY:
        case this.PHARMACY_SATELLITE_REVERSAL:
        case this.PHARMACY_SATELLITE_CONSUMPTION:
          index = "trs_id";
          break;
        case this.PHARMACY_SATELLITE_REQUEST:
          index = "ped_id";
          break;
        case this.PHARMACY_SATELLITE_REVENUE:
          index = "cos_id";
          break;
      }

      return index;
    },

    collumnsPharmacySatellite() {
      const statusProduct = this.form.statusProduct;
      let COLLUMNS_PHARMACY_SATELLITE = [];
      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_ENTRY:
          COLLUMNS_PHARMACY_SATELLITE = this.COLUMNS_ENTRY;
          break;
        case this.PHARMACY_SATELLITE_REQUEST:
          COLLUMNS_PHARMACY_SATELLITE = this.COLUMNS_REQUEST;
          break;
        case this.PHARMACY_SATELLITE_REVERSAL:
          COLLUMNS_PHARMACY_SATELLITE = this.COLUMNS_REVERSAL;
          break;
        case this.PHARMACY_SATELLITE_REVENUE:
          COLLUMNS_PHARMACY_SATELLITE = this.COLUMNS_REVENUE;
          break;
        case this.PHARMACY_SATELLITE_CONSUMPTION:
          COLLUMNS_PHARMACY_SATELLITE = this.COLUMNS_CONSUMPTION;
          break;
      }

      return COLLUMNS_PHARMACY_SATELLITE;
    },

    nameSmartTable() {
      const statusProduct = this.form.statusProduct;
      let NAME_SMART_TABLE_PHARMACY_SATELLITE = [];
      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_ENTRY:
          NAME_SMART_TABLE_PHARMACY_SATELLITE = `pharmacySatelliteEntry`;
          break;
        case this.PHARMACY_SATELLITE_REQUEST:
          NAME_SMART_TABLE_PHARMACY_SATELLITE = `pharmacySatelliteRequest`;
          break;
        case this.PHARMACY_SATELLITE_REVERSAL:
          NAME_SMART_TABLE_PHARMACY_SATELLITE = `pharmacySatelliteReversal`;
          break;
        case this.PHARMACY_SATELLITE_REVENUE:
          NAME_SMART_TABLE_PHARMACY_SATELLITE = `pharmacySatelliteRevenue`;
          break;
        case this.PHARMACY_SATELLITE_CONSUMPTION:
          NAME_SMART_TABLE_PHARMACY_SATELLITE = `pharmacySatelliteConsumption`;
          break;
      }

      return NAME_SMART_TABLE_PHARMACY_SATELLITE;
    },
    propsModalConfirmDeletion() {
      const statusProduct = this.form.statusProduct;

      let confirm = null;
      let movement = null;
      let msgSuccess = null;
      let movementMessage = null;
      const show = this.modalDeleteMovement;
      const noReason = true;
      let bodyText = "";

      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_REQUEST:
          confirm = () => this.inactivateRequest();
          msgSuccess = "Solicitação excluída com sucesso";
          movement = "Solicitação";
          movementMessage = "das solicitações realizadas";
          bodyText = `Nº da solicitação: ${this.selectedRow?.ped_numero} - ${this.selectedRow?.situacao}`;
          break;

        case this.PHARMACY_SATELLITE_REVENUE:
          confirm = () => this.inactivateRevenue();
          msgSuccess = "Receita excluída com sucesso";
          movement = "Receita";
          movementMessage = "das receitas realizadas";
          bodyText = `${this.selectedRow?.mpd_novo_principio_ativo} - ${this.selectedRow?.lote} - ${this.selectedRow?.rec_quantidade} `;
          break;

        case this.PHARMACY_SATELLITE_CONSUMPTION:
          confirm = () => this.inactivateConsumption();
          msgSuccess = "Consumo excluído com sucesso";
          movement = "Consumo";
          movementMessage = "dos consumos realizados";
          bodyText = `${this.selectedRow?.mpd_novo_principio_ativo} - ${this.selectedRow?.lote} - ${this.selectedRow?.cos_quantidade}`;
          break;
      }

      const title = `Excluir Registro de ${movement}`;
      const message = `O registro selecionado será removido ${movementMessage} na Farmácia`;
      const hasSearch = true;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
      };
    },
    isClosedRequest() {
      return (
        this.form.statusProduct === this.PHARMACY_SATELLITE_REQUEST &&
        this.selectedRow.situacao === "FECHADO"
      );
    },
    isRequest() {
      return this.form.statusProduct === this.PHARMACY_SATELLITE_REQUEST;
    },
  },

  watch: {
    "form.statusProduct"(pValue, pPrev) {
      if (pValue !== pPrev) {
        this.mutablePharmacySatellite = [];
        this.selectedRow = null;
        if (this.$refs.smartTable) {
          this.$refs.smartTable.cleanSelectRow();
        }
        this.$refs.rgSearch.clearPagination();
        this.form.guide = null;
        this.form.status = null;
        this.form.patientName = null;
      }
    },
    "form.guide"(pValue) {
      if (pValue === "") {
        this.form.guide = null;
      }
    },
    "form.unitHealth"(pValue) {
      if (!pValue && pValue === null) {
        this.form.pharmacy = null;
        this.form.product = null;
        this.form.uns_nome = null;
        this.form.far_nome = null;
      }
    },
    "form.pharmacy"(pValue) {
      if (!pValue && pValue === null) {
        this.form.product = null;
        this.form.far_nome = null;
      }
    },
  },

  async mounted() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    await this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/pharmacy/satellite",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Pharmacy/Satellite/RESET_FILTER_PHARMACY_SATELLITE");
    }
  },

  created() {
    this.COLLUMS = [
      { name: "Situação", key: "situacao", align: "left", active: "true" },
      { name: "Código", key: "mpd_codigo", align: "left", active: "true" },
      {
        name: "Nome",
        key: "mpd_novo_principio_ativo",
        align: "left",
        active: "true",
      },
      {
        name: "Apresentação",
        key: "mtu_novo_nome",
        align: "left",
        active: "true",
      },
      { name: "Tipo", key: "mtp_nome", align: "left", active: "true" },
    ];
    this.COLUMNS_ENTRY = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "trs_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Entrada", key: "trs_data", align: "left", active: "true" },
      { name: "Origem", key: "origem", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Mínimo", key: "entrada", align: "left" },
      { name: "Máximo", key: "saida", align: "left" },
      { name: "Estoque Lote", key: "total_lote", align: "left" },
      { name: "Estoque Total", key: "total_lote", align: "left" },
    ];
    this.COLUMNS_REQUEST = [
      { name: "Situação", key: "situacao", align: "left", active: "true" },
      { name: "Número", key: "ped_numero", align: "left", active: "true" },
      { name: "Data", key: "ped_data_pedido", align: "left", active: "true" },
      {
        name: "Farmácia Atendente",
        key: "farmacia_atendente",
        align: "left",
        active: "true",
      },
    ];
    this.COLUMNS_REVERSAL = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "trs_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Data", key: "trs_data", align: "left", active: "true" },
      { name: "Destino", key: "destino", align: "left" },
      { name: "Guia da Remessa", key: "trs_guia_remessa", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      {
        name: "Tipo de Estorno",
        key: "ttr_nome",
        align: "left",
        active: "true",
      },
    ];

    this.COLUMNS_REVENUE = [
      { name: "Situação", key: "situacao", align: "left" },
      { name: "Código", key: "mpd_codigo", align: "left" },
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Nome", key: "mpd_novo_principio_ativo", align: "left" },
      { name: "Apresentação", key: "mtu_novo_nome", align: "left" },
      { name: "Saída", key: "rec_data", align: "left", active: "true" },
      { name: "Retorno", key: "rec_retorno", align: "left" },
      {
        name: "Dispensado",
        key: "rec_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Solicitado", key: "rec_solicitada", align: "left" },
      { name: "Solicitante", key: "solicitante", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Mínimo", key: "entrada", align: "left" },
      { name: "Máximo", key: "saida", align: "left" },
      { name: "Estoque", key: "total_lote", align: "left" },
    ];
    this.COLUMNS_CONSUMPTION = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "cos_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Fármacia", key: "far_nome", align: "left" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
      { name: "Destino", key: "dco_nome", align: "left" },
      {
        name: "Data",
        key: "cos_data",
        align: "left",
        active: "true",
      },
      { name: "Observação", key: "cos_observacao", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
    ];
    this.PHARMACY_SATELLITE_ENTRY = 1;
    this.PHARMACY_SATELLITE_REQUEST = 2;
    this.PHARMACY_SATELLITE_REVERSAL = 3;
    this.PHARMACY_SATELLITE_PRESCRIPTION = 4;
    this.PHARMACY_SATELLITE_REVENUE = 5;
    this.PHARMACY_SATELLITE_CONSUMPTION = 6;
  },

  methods: {
    validateValues(pValue, pErrors) {
      const isValidNumber = /^[0-9]+$/.test(pValue);

      const hasSpecial = /[:]/.test(pValue);
      const options = [this.PHARMACY_SATELLITE_REQUEST];

      const hasRequest = options.includes(this.form.statusProduct);

      if ((!isValidNumber || hasSpecial) && hasRequest && pValue !== null) {
        pErrors.push("O campo deve conter apenas valores numéricos");
        return false;
      }
      return true;
    },
    itemActionMovement() {
      return [
        {
          label: "Visualizar Movimentação",
          action: this.showMovement,
        },
        {
          label: "Visualizar Observações",
          action: this.viewObservations,
        },
      ];
    },

    viewObservations() {
      this.showModalObservationRequest = true;
      this.observationData = [
        {
          far_id: this.selectedRow.idFarmaciaAtendente,
          ped_id: this.selectedRow.ped_id,
          ped_numero: this.selectedRow.ped_numero,
        },
      ];
    },
    closeModalObservationRequest() {
      this.showModalObservationRequest = false;
    },
    getPatientInfo(pValue) {
      const source = pValue?.source;

      if (source) {
        this.form.patientId = source.pcf_id;
      } else {
        this.form.patientId = null;
      }
    },
    selectedUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.uns_nome = pValue.label;
      } else {
        this.form.uns_nome = "";
      }
    },
    selectedPharmacy(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.far_nome = pValue.label;
      } else {
        this.form.far_nome = "";
      }
    },

    closeModalDeleteMovement() {
      this.modalDeleteMovement = false;
    },

    editRequest() {
      this.$router.push({
        name: "pharmacy.satellite.edit-request",
        params: this.selectedRow,
      });
    },
    async inactivateRequest() {
      if (this.selectedRow.ped_id) {
        try {
          this.$loader.start();

          await this.$store.dispatch("Pharmacy/Satellite/INACTIVATE_REQUEST", {
            intIdPedido: Number(this.selectedRow.ped_id),
          });

          this.$toaster.success("Solicitação excluída com sucesso");
          this.$refs.smartTable.cleanSelectRow();
          this.reSearch();
        } catch (pErr) {
          const error = pErr.toString();

          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Não foi possível excluir a solicitação",
          );
        } finally {
          this.$loader.finish();
        }
      } else {
        this.$toaster.warning("Não foi possível encontrar o id da solicitação");
      }
    },

    async inactivateRevenue() {
      try {
        this.$loader.start();

        await this.$store.dispatch(
          "Pharmacy/Satellite/INACTIVATE_REVENUE",
          Number(this.selectedRow.rec_id),
        );

        this.$toaster.success("Saída excluída com sucesso");
        this.$refs.smartTable.cleanSelectRow();
        this.reSearch();
      } catch (pErr) {
        const error = pErr.toString();
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir a saída",
        );
      } finally {
        this.$loader.finish();
      }
    },

    deleteProduct() {
      this.modalDeleteMovement = true;
    },

    requestProduct() {
      this.$router.push({ name: "pharmacy.satellite.request" });
    },
    reversalProduct() {
      this.$router.push({ name: "pharmacy.satellite.reversal" });
    },

    revenueProduct() {
      this.$router.push({ name: "pharmacy.satellite.revenue" });
    },
    showMovement() {
      const statusProduct = this.form.statusProduct;

      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_REVENUE:
          this.modalShowRevenue = true;
          this.filters = Number(this.selectedRow.pcf_id);
          break;
        case this.PHARMACY_SATELLITE_REQUEST:
          this.openModalRequestDetails();
          this.requestId = Number(this.selectedRow.ped_id);
          break;
      }
    },
    closeModalShowMovement() {
      const statusProduct = this.form.statusProduct;

      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_REVENUE:
          this.modalShowRevenue = false;
          this.filters = null;
          break;
      }
    },

    printer() {
      const statusProduct = this.form.statusProduct;

      switch (statusProduct) {
        case this.PHARMACY_SATELLITE_REQUEST:
          this.printerRequest();
          break;
        case this.PHARMACY_SATELLITE_REVENUE:
          this.printerRevenue();
          break;
        case this.PHARMACY_SATELLITE_REVERSAL:
          this.printerReversal();
          break;
      }
    },
    async printerReversal() {
      try {
        this.$loader.start();
        const variables = {
          strGuiaRemessa: this.selectedRow.trs_guia_remessa,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Central/PRINTER_OUTPUT",
          variables,
        );

        this.printed = true;
        const dataRequest = {};

        dataRequest.origem = this.selectedRow.origem;
        dataRequest.destino = this.selectedRow.destino;
        dataRequest.trs_data = this.selectedRow.trs_data;
        dataRequest.trs_guia_remessa = this.selectedRow.trs_guia_remessa;
        dataRequest.usu_nome = this.userName;

        const html = DeliveryNoteHTML(
          dataRequest,
          result,
          this.clientName,
          this.userName,
          this.unitHealth,
        );

        this.$utils.print.printHtml(html);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir guia de remessa",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async printerRevenue() {
      try {
        this.$loader.start();
        const variable = {
          strDataRecebimento: this.selectedRow.rec_data,
          strFarmacia: this.form.pharmacy,
          intPaciente: this.selectedRow.pcf_id,
          quantidadeDeVias: 1,
          idUnidade: this.form.unitHealth,
        };

        this.dataPrint = await this.$store.dispatch(
          "Pharmacy/Satellite/GET_PRINT_REVENUE",
          variable,
        );
        this.infosPrint.clientName = this.clientName;
        this.infosPrint.userName = this.userName;
        this.infosPrint.uns_nome = this.unitHealth.uns_nome;
        this.infosPrint.mun_nome = this.unitHealth.endereco.neighborhood.city.mun_nome;

        this.$loader.finish();
        this.modalTypesPrint = true;
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir comprovante de recebimento",
        );
        this.$loader.finish();
      }
    },
    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },
    async printerRequest() {
      try {
        this.$loader.start();
        const variable = {
          intIdPedido: this.selectedRow.ped_id,
        };

        const objRequest = this.selectedRow;

        const orderItems = await this.$store.dispatch(
          "Pharmacy/Satellite/GET_PRINT_REQUEST",
          variable,
        );

        const html = RequestHTML(
          objRequest,
          orderItems,
          this.clientName,
          this.userName,
          this.unitHealth,
        );
        this.$utils.print.printHtml(html);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },

    consumptionTransfer() {
      this.$router.push({ name: "pharmacy.satellite.consumption" });
    },

    afterSearchFilter() {
      // GET DYNAMIC HEIGHT
      this.getDynamicHeight();
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
        this.selectedRow = null;
      }
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgSearch?.$refs.listContent) {
          const searchArea = this.$refs.rgSearch.$refs.listContent.offsetHeight;
          const radios = this.$refs.radios.offsetHeight;
          const footer = this.$refs.footer.offsetHeight;
          this.tableHeight = searchArea - radios - footer - 10;
          if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        }
      });
    },

    selectLine(pValue) {
      this.selectedRow = pValue;
      this.$store.commit(
        "Pharmacy/Satellite/SELECT_PHARMACY_SATELLITE",
        pValue,
      );
    },

    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },
    generateFilter() {
      const variables = {
        arrFormData: {
          uns_id: this.form.unitHealth,
          far_id: Number(this.form.pharmacy),
          mpd_id: this.form.product,
          strPeriodoInicial: this.form.initialDate,
          strPeriodoFinal: this.form.finalDate,
          ...(this.form.statusProduct === this.PHARMACY_SATELLITE_REQUEST && {
            ped_numero: this.form.guide,
          }),
          ...(this.form.statusProduct === this.PHARMACY_SATELLITE_REVERSAL && {
            trs_guia_remessa: this.form.guide,
          }),
          ...(this.form.statusProduct === this.PHARMACY_SATELLITE_REVENUE && {
            pcf_id: this.form.patientId,
          }),
          ...(this.form.statusProduct === this.PHARMACY_SATELLITE_REQUEST && {
            ped_status:
              this.form.status === null ? -1 : this.form.status === 1 ? 0 : 1,
          }),
        },
      };

      if (this.form.statusProduct === this.PHARMACY_SATELLITE_REQUEST)
        variables.intIdTipoFarmacia = 2;

      this.$store.commit(
        "Pharmacy/Satellite/SET_FILTER_SATELLITE",
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },
    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Pharmacy/Satellite/GET_FILTER_SATELLITE"
        ];

        if (existFilterData) {
          this.$loader.start();

          this.form.unitHealth = existFilterData.unitHealth;
          this.form.statusProduct = existFilterData.statusProduct;
          this.form.product = existFilterData.product;
          this.form.pharmacy = existFilterData.pharmacy;

          this.form.initialDate = existFilterData.initialDate;
          this.form.finalDate = existFilterData.finalDate;
          this.form.patientName = existFilterData.patientName;
          this.form.status = existFilterData.status;
          this.form.guide = existFilterData.guide;
          await this.$refs.rgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning(Err, "Erro ao carregar os dados da navegação");
        this.$loader.finish();
      }
    },

    async searchFilter(pData) {
      try {
        const statusProduct = this.form.statusProduct;

        switch (statusProduct) {
          case this.PHARMACY_SATELLITE_ENTRY:
            return await this.searchEntry(pData);
          case this.PHARMACY_SATELLITE_REQUEST:
            return await this.searchRequest(pData);
          case this.PHARMACY_SATELLITE_REVERSAL:
            return await this.searchReversal(pData);
          case this.PHARMACY_SATELLITE_REVENUE:
            return await this.searchRevenue(pData);
          case this.PHARMACY_SATELLITE_CONSUMPTION:
            return await this.searchConsumption(pData);
          default: {
            this.$toaster.warning(
              `Os filtros estão localizados abaixo do título "Produtos".`,
              "Selecione um dos filtros para exibir os resultados",
            );
          }
        }
      } catch (pErr) {
        this.$toaster.warning(pErr);
      }
    },
    async searchEntry(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_ENTRY",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchRequest(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_REQUEST",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchReversal(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_REVERSAL",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchRevenue(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_REVENUE",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchConsumption(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_CONSUMPTION",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    isFormValid() {
      return this.$refs.rgSearch.$refs.validator
        ? this.$refs.rgSearch.$refs.validator.validate()
        : false;
    },
    async reSearch() {
      const isInvalid = !(await this.isFormValid());

      if (isInvalid) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      this.selectedRow = {};
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
      this.$refs.rgSearch.clearPagination();
      this.$refs.rgSearch.performSearch();
    },
    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM_SATELLITE);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.mutablePharmacySatellite = [];
      this.selectedRow = [];
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },
    onInputRadioCustom(item) {
      if (item) {
        this.form.statusProduct = item.id;
        this.reSearch();
      } else {
        this.form.statusProduct = null;
        this.reSearch();
      }
    },
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    async inactivateConsumption() {
      if (this.selectedRow.cos_id) {
        try {
          this.$loader.start();
          const cos_id = this.selectedRow.cos_id;
          await this.$store.dispatch(
            "Pharmacy/Satellite/INACTIVATE_CONSUMPTION",
            cos_id,
          );
          this.$toaster.success("Consumo excluído com sucesso");
          this.reSearch();
        } catch (pErr) {
          const error = pErr.toString();

          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Não foi possível excluir o consumo",
          );
        } finally {
          this.$loader.finish();
        }
      } else {
        this.$toaster.error("Não foi possível encontrar o id do consumo");
      }
    },
    openModalRequestDetails() {
      this.showModalRequestDetails = true;
    },
    closeModalRequestDetails() {
      this.showModalRequestDetails = false;
    },
  },
};
</script>
