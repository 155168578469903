import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

export default {
  path:
    "/emergency/bulletin/edit-registration/edit-patient/patient-care-history",
  name:
    "emergency.bulletin.edit-registration.edit-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Emergência", link: OpenMenuModule("emergency") },
      { label: "Atendimento", link: OpenSubModule("emergency") },
      { label: "Boletins", link: "/emergency/bulletin" },
      {
        label: "Editar Boletim",
        link: "/emergency/bulletin/edit-registration",
      },
      {
        label: "Editar Paciente",
        link: "/emergency/bulletin/edit-registration/edit-patient",
      },
      {
        label: "Histórico de Atendimentos",
      },
    ],
  },
};
