<style src="./VueSelect.scss" lang="scss" scoped></style>

<template>
  <div
    ref="vueSelect"
    :dir="dir"
    :class="dropdownClasses"
    class="dropdown v-select"
  >
    <div
      ref="toggle"
      class="dropdown-toggle"
      :class="{ 'on-error': hasErrors }"
      @mousedown.prevent="toggleDropdown"
    >
      <div ref="selectedOptions" class="vs__selected-options">
        <slot
          v-for="option in valueAsArray"
          :option="typeof option === 'object' ? option : { [label]: option }"
          :deselect="deselect"
          :multiple="multiple"
          :disabled="disabled"
          name="selected-option-container"
        >
          <span
            :key="option.index"
            :data-item="dataItem"
            :style="{
              'padding-left': inputPaddingLeft,
              position: multiple ? 'relative' : 'absolute',
            }"
            class="selected-tag"
          >
            <slot
              v-bind="typeof option === 'object' ? option : { [label]: option }"
              name="selected-option"
            >
              {{ getOptionLabel(option) }}
            </slot>
            <button
              v-if="multiple"
              :disabled="disabled"
              type="button"
              class="close"
              aria-label="Remover opção"
              @click="deselect(option)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
        </slot>

        <input
          :id="inputId"
          ref="search"
          v-model="search"
          v-shortkey="{
            enter: open ? ['enter'] : [''],
            esc: open ? ['esc'] : [''],
          }"
          :data-id="dataId"
          :disabled="disabled"
          :placeholder="searchPlaceholder"
          :tabindex="tabindex"
          :readonly="!searchable"
          :aria-expanded="dropdownOpen"
          :style="{ 'padding-left': inputPaddingLeft }"
          :title="title"
          type="search"
          class="form-control"
          autocomplete="off"
          role="combobox"
          aria-label="Buscar por opção"
          @shortkey="action"
          @keydown.delete="maybeDeleteValue"
          @keydown.esc="onEscape"
          @keydown.13="typeAheadSelect"
          @keydown.up.prevent="typeAheadUp"
          @keydown.down.prevent="typeAheadDown"
          @keydown.tab="onTab"
          @blur="onSearchBlur"
          @focus="onSearchFocus"
        />
      </div>
      <div class="vs__actions">
        <button
          v-show="showClearButton"
          :disabled="disabled"
          :class="{ isdisabled: disabled }"
          type="button"
          class="clear"
          title="Limpar seleção"
          @click="clearSelection"
        >
          <IconClose class="icon-close" />
        </button>

        <i
          v-if="!noDrop"
          ref="openIndicator"
          :style="{ display: showOpenIndicator }"
          role="presentation"
          class="open-indicator"
        />

        <slot name="spinner">
          <div v-show="mutableLoading" class="spinner">Carregando...</div>
        </slot>
      </div>
    </div>

    <transition :name="transition">
      <ul
        v-if="dropdownOpen"
        ref="dropdownMenu"
        :style="{ 'max-height': responsiveHeight }"
        class="dropdown-menu"
        :class="{ 'show-top': showBottom, 'show-bottom': !showBottom }"
        role="listbox"
        @mousedown="onMousedown"
      >
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          :title="getOptionLabel(option)"
          :class="{
            active: isOptionSelected(option),
            highlight: index === typeAheadPointer,
          }"
          role="option"
        >
          <!-- @mouseover="typeAheadPointer = index" -->
          <a @mousedown.prevent.stop="select(option)">
            <slot
              v-bind="typeof option === 'object' ? option : { [label]: option }"
              name="option"
            >
              {{ getOptionLabel(option) }}
            </slot>
          </a>
        </li>
        <li
          v-if="!filteredOptions.length"
          class="no-options"
          @mousedown.stop=""
        >
          <slot name="no-options">{{ labelEmptyOptions }}</slot>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { IconClose } from "~tokio/primitive";
import pointerScroll from "../mixins/pointerScroll";
import typeAheadPointer from "../mixins/typeAheadPointer";
import ajax from "../mixins/ajax";

export default {
  name: "VueSelect",
  components: { IconClose },
  mixins: [pointerScroll, typeAheadPointer, ajax],
  props: {
    /**
     * Contains the currently selected value. Very similar to a
     * `value` attribute on an <input>. You can listen for changes
     * using 'change' event using v-on
     * @type {Object||String||null}
     */
    value: {
      default: null,
    },

    dataId: {
      type: String,
      default: "",
    },
    dataItem: {
      type: String,
      default: "",
    },
    /**
     * An array of strings or objects to be used as dropdown choices.
     * If you are using an array of objects, vue-select will look for
     * a `label` key (ex. [{label: 'This is Foo', value: 'foo'}]). A
     * custom label key can be set with the `label` prop.
     * @type {Array}
     */
    options: {
      type: Array,
      default() {
        return [];
      },
    },

    labelEmptyOptions: {
      type: String,
      default: "Nenhum resultado encontrado.",
    },

    /**
     * Disable the entire component.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true,
    },

    /**
     * Sets the max-height property on the dropdown list.
     * @deprecated
     * @type {String}
     */
    maxHeight: {
      type: String,
      default: "400px",
    },

    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true,
    },

    /**
     * Equivalent to the `multiple` attribute on a `<select>` input.
     * @type {Boolean}
     */
    multiple: {
      type: Boolean,
      default: false,
    },

    /**
     * Equivalent to the `placeholder` attribute on an `<input>`.
     * @type {String}
     */
    placeholder: {
      type: String,
      default: "",
    },

    /**
     * Sets a Vue transition property on the `.dropdown-menu`. vue-select
     * does not include CSS for transitions, you'll need to add them yourself.
     * @type {String}
     */
    transition: {
      type: String,
      default: "fade",
    },

    /**
     * Enables/disables clearing the search text when an option is selected.
     * @type {Boolean}
     */
    clearSearchOnSelect: {
      type: Boolean,
      default: true,
    },

    /**
     * Close a dropdown when an option is chosen. Set to false to keep the dropdown
     * open (useful when combined with multi-select, for example)
     * @type {Boolean}
     */
    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    /**
     * Tells vue-select what key to use when generating option
     * labels when each `option` is an object.
     * @type {String}
     */
    label: {
      type: String,
      default: "label",
    },

    /**
     * Tells vue-select what key to use when generating option
     * values when each `option` is an object.
     * @type {String}
     */
    index: {
      type: String,
      default: null,
    },

    /**
     * Callback to generate the label text. If {option}
     * is an object, returns option[this.label] by default.
     *
     * Label text is used for filtering comparison and
     * displaying. If you only need to adjust the
     * display, you should use the `option` and
     * `selected-option` slots.
     *
     * @type {Function}
     * @param  {Object || String} option
     * @return {String}
     */
    getOptionLabel: {
      type: Function,
      default(option) {
        let optionValue = Object.assign({}, option);
        if (this.index) {
          optionValue = this.findOptionByIndexValue(option);
        }

        if (typeof optionValue === "object") {
          if (!optionValue[this.label]) {
            return console.warn(
              `[vue-select warn]: Label key "optionValue.${this.label}" does not` +
                ` exist in options object ${JSON.stringify(optionValue)}.\n` +
                "http://sagalbot.github.io/vue-select/#ex-labels",
            );
          }
          return optionValue[this.label];
        }
        return optionValue;
      },
    },

    /**
     * An optional callback function that is called each time the selected
     * value(s) change. When integrating with Vuex, use this callback to trigger
     * an action, rather than using :value.sync to retreive the selected value.
     * @type {Function}
     * @param {Object || String} val
     */
    onChange: {
      type: Function,
      default: function (val) {
        this.$emit("input", val);
      },
    },

    /**
     * Select the current value if selectOnTab is enabled
     */
    onTab: {
      type: Function,
      default: function () {
        if (this.selectOnTab) {
          this.typeAheadSelect();
        }
      },
    },

    /**
     * Enable/disable creating options from searchInput.
     * @type {Boolean}
     */
    taggable: {
      type: Boolean,
      default: false,
    },

    /**
     * Set the tabindex for the input field.
     * @type {Number}
     */
    tabindex: {
      type: Number,
      default: null,
    },

    /**
     * When true, newly created tags will be added to
     * the options list.
     * @type {Boolean}
     */
    pushTags: {
      type: Boolean,
      default: false,
    },

    /**
     * When true, existing options will be filtered
     * by the search text. Should not be used in conjunction
     * with taggable.
     * @type {Boolean}
     */
    filterable: {
      type: Boolean,
      default: true,
    },

    /**
     * Callback to determine if the provided option should
     * match the current search text. Used to determine
     * if the option should be displayed.
     * @type   {Function}
     * @param  {Object || String} option
     * @param  {String} label
     * @param  {String} search
     * @return {Boolean}
     */
    filterBy: {
      type: Function,
      default(option, label, search) {
        return (
          (label || "")
            .toLowerCase()
            .indexOf(this.replaceSpecial(search.toLowerCase())) > -1
        );
      },
    },

    /**
     * Callback to filter results when search text
     * is provided. Default implementation loops
     * each option, and returns the result of
     * this.filterBy.
     * @type   {Function}
     * @param  {Array} list of options
     * @param  {String} search text
     * @param  {Object} vSelect instance
     * @return {Boolean}
     */
    filter: {
      type: Function,
      default(options, search) {
        return options.filter((option) => {
          let label = this.getOptionLabel(option);

          label = this.replaceSpecial(label);

          if (typeof label === "number") {
            label = label.toString();
          }
          return this.filterBy(option, label, search);
        });
      },
    },

    /**
     * User defined function for adding Options
     * @type {Function}
     */
    createOption: {
      type: Function,
      default(newOption) {
        let optionValue = Object.assign({}, newOption);
        if (typeof this.mutableOptions[0] === "object") {
          optionValue = { [this.label]: optionValue };
        }
        this.$emit("option:created", optionValue);
        return optionValue;
      },
    },

    /**
     * When false, updating the options will not reset the select value
     * @type {Boolean}
     */
    resetOnOptionsChange: {
      type: Boolean,
      default: false,
    },

    /**
     * Disable the dropdown entirely.
     * @type {Boolean}
     */
    noDrop: {
      type: Boolean,
      default: false,
    },

    openOnlyBottom: {
      type: Boolean,
      default: false,
    },

    openOnlyTop: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets the id of the input element.
     * @type {String}
     * @default {null}
     */
    inputId: {
      type: String,
      default: "",
    },

    /**
     * Sets RTL support. Accepts 'ltr', 'rtl', 'auto'.
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/dir
     * @type {String}
     * @default 'auto'
     */
    dir: {
      type: String,
      default: "auto",
    },
    /**
     * When true, hitting the 'tab' key will select the current select value
     * @type {Boolean}
     */
    selectOnTab: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: "",
      open: false,
      showBottom: false,
      mutableValue: null,
      mutableOptions: [],
      responsiveHeight: this.maxHeight,
    };
  },
  computed: {
    validValue() {
      return this.mutableValue;
    },
    inputPaddingLeft() {
      return this.multiple ? "0" : "23px";
    },
    showOpenIndicator() {
      return this.multiple ? "flex" : "none";
    },
    /**
     * Classes to be output on .dropdown
     * @return {Object}
     */
    dropdownClasses() {
      return {
        open: this.dropdownOpen,
        single: !this.multiple,
        searching: this.searching,
        searchable: this.searchable,
        unsearchable: !this.searchable,
        loading: this.mutableLoading,
        rtl: this.dir === "rtl", // This can be removed - styling is handled by `dir="rtl"` attribute
        // disabled: this.disabled,
      };
    },

    /**
     * If search text should clear on blur
     * @return {Boolean} True when single and clearSearchOnSelect
     */
    clearSearchOnBlur() {
      return this.clearSearchOnSelect && !this.multiple;
    },

    /**
     * Return the current state of the
     * search input
     * @return {Boolean} True if non empty value
     */
    searching() {
      return !!this.search;
    },

    /**
     * Return the current state of the
     * dropdown menu.
     * @return {Boolean} True if open
     */
    dropdownOpen() {
      return this.noDrop ? false : this.open && !this.mutableLoading;
    },

    /**
     * Return the placeholder string if it's set
     * & there is no value selected.
     * @return {String} Placeholder text
     */
    searchPlaceholder() {
      if (this.isValueEmpty && this.placeholder) {
        return this.placeholder;
      }
      return false;
    },

    /**
     * The currently displayed options, filtered
     * by the search elements value. If tagging
     * true, the search text will be prepended
     * if it doesn't already exist.
     *
     * @return {array}
     */
    filteredOptions() {
      if (!this.filterable && !this.taggable) {
        return this.mutableOptions.slice();
      }
      const options = this.search.length
        ? this.filter(this.mutableOptions, this.search, this)
        : this.mutableOptions;

      if (
        this.taggable &&
        this.search.length &&
        !this.optionExists(this.search)
      ) {
        options.unshift(this.search);
      }
      return options;
    },

    /**
     * Check if there aren't any options selected.
     * @return {Boolean}
     */
    isValueEmpty() {
      if (this.mutableValue) {
        if (typeof this.mutableValue === "object") {
          return !Object.keys(this.mutableValue).length;
        }
        return !this.valueAsArray.length;
      }

      return true;
    },

    /**
     * Return the current value in array format.
     * @return {Array}
     */
    valueAsArray() {
      if (this.multiple && this.mutableValue) {
        return this.mutableValue;
      } else if (this.mutableValue) {
        return [].concat(this.mutableValue);
      }

      return [];
    },

    /**
     * Determines if the clear button should be displayed.
     * @return {Boolean}
     */
    showClearButton() {
      return (
        !this.multiple &&
        this.clearable &&
        !this.open &&
        this.mutableValue != null
      );
    },
  },
  watch: {
    /**
     * When the value prop changes, update
     * the internal mutableValue.
     * @param  {mixed} val
     * @return {void}
     */
    value(val) {
      this.mutableValue = val;
    },

    /**
     * Maybe run the onChange callback.
     * @param  {string|object} val
     * @param  {string|object} old
     * @return {void}
     */
    mutableValue(val, old) {
      if ((this.multiple && this.onChange) || (this.onChange && val !== old)) {
        this.onChange(val);
      }
    },

    /**
     * When options change, update
     * the internal mutableOptions.
     * @param  {array} val
     * @return {void}
     */
    options(val) {
      this.mutableOptions = val;
    },

    /**
     * Maybe reset the mutableValue
     * when mutableOptions change.
     * @return {[type]} [description]
     */
    mutableOptions(val) {
      if (!this.taggable && this.resetOnOptionsChange) {
        this.mutableValue = this.multiple ? [] : null;
      }
      if (val && val.length === 1 && !this.disabled) {
        this.select(val[0]);
      }
    },

    disabled(pValue) {
      if (!pValue) {
        if (this.mutableOptions.length === 1) {
          this.select(this.mutableOptions[0]);
        }
      } else {
        if (this.mutableOptions.length < 1) {
          this.mutableValue = this.multiple ? [] : null;
        }
      }
    },

    /**
     * Always reset the mutableValue when
     * the multiple prop changes.
     * @param  {Boolean} val
     * @return {void}
     */
    multiple(val) {
      this.mutableValue = val ? [] : null;
    },
  },

  /**
   * Clone props into mutable values,
   * attach any event listeners.
   */
  created() {
    this.mutableValue = this.value;
    this.mutableOptions = this.options.slice(0);
    this.mutableLoading = this.loading;

    this.$on("option:created", this.maybePushTag);
  },

  mounted() {
    this.setResponsiveSize();
  },

  methods: {
    setResponsiveSize() {
      if (window.innerHeight <= 636) {
        this.responsiveHeight = "125px";
      } else {
        this.responsiveHeight = this.maxHeight;
      }
    },
    /**
     * Select a given option.
     * @param  {Object|String} option
     * @return {void}
     */
    select(option) {
      let optionValue = Object.assign({}, option);
      if (!this.isOptionSelected(optionValue)) {
        if (this.taggable && !this.optionExists(optionValue)) {
          optionValue = this.createOption(optionValue);
        }
        if (this.index) {
          if (!optionValue[this.index]) {
            return console.warn(
              `[vue-select warn]: Index key "optionValue.${this.index}" does not` +
                ` exist in options object ${JSON.stringify(optionValue)}.`,
            );
          }
          optionValue = optionValue[this.index];
        }
        if (this.multiple && !this.mutableValue) {
          this.mutableValue = [optionValue];
        } else if (this.multiple) {
          this.mutableValue.push(optionValue);
        } else {
          this.mutableValue = optionValue;
        }
      }

      this.onAfterSelect(optionValue);
    },

    /**
     * De-select a given option.
     * @param  {Object|String} option
     * @return {void}
     */
    deselect(option) {
      if (this.multiple) {
        let ref = -1;
        this.mutableValue.forEach((val) => {
          if (
            val === option ||
            (this.index && val === option[this.index]) ||
            (typeof val === "object" && val[this.label] === option[this.label])
          ) {
            ref = val;
          }
        });
        var index = this.mutableValue.indexOf(ref);
        this.mutableValue.splice(index, 1);
      } else {
        this.mutableValue = null;
      }
    },

    /**
     * Clears the currently selected value(s)
     * @return {void}
     */
    clearSelection() {
      if (this.disabled) {
        return;
      }
      this.mutableValue = this.multiple ? [] : null;
    },

    /**
     * Called from this.select after each selection.
     * @param  {Object|String} option
     * @return {void}
     */
    onAfterSelect(option) {
      if (this.closeOnSelect) {
        this.open = false;
        this.$refs.search.blur();
      }

      if (this.clearSearchOnSelect) {
        this.search = "";
      }
    },

    /**
     * Toggle the visibility of the dropdown menu.
     * @param  {Event} e
     * @return {void}
     */
    toggleDropdown(e) {
      if (
        e.target === this.$refs.openIndicator ||
        e.target === this.$refs.search ||
        e.target === this.$refs.toggle ||
        e.target.classList.contains("selected-tag") ||
        e.target === this.$el
      ) {
        if (this.open) {
          this.$refs.search.blur(); // dropdown will close on blur
        } else {
          if (!this.disabled) {
            this.open = true;
            this.openBottomOrTop();
            this.$refs.search.focus();
          }
        }
      }
    },

    blockResponsive() {
      return window.innerHeight <= 636;
    },

    openBottomOrTop() {
      if (this.openOnlyTop) {
        this.showBottom = true;
        return;
      }

      if (this.openOnlyBottom || this.blockResponsive()) {
        this.showBottom = false;
        return;
      }

      this.$nextTick(() => {
        const hasModulebox = this.getComponent("Modulebox");
        if (hasModulebox) {
          const body = hasModulebox.$refs.myself;
          const bodyMapPosition = body.getBoundingClientRect().bottom;

          const size = Number(
            this.responsiveHeight.substr(0, this.responsiveHeight.length - 2),
          );
          const margin = 25;

          const elementMapPosition = this.$refs.vueSelect.getBoundingClientRect();
          const elementPositionFull =
            elementMapPosition.bottom +
            this.$refs.vueSelect.offsetHeight +
            size +
            margin;

          this.showBottom = elementPositionFull >= bodyMapPosition;
        }
      });
    },

    getComponent(componentName) {
      let component = null;
      let parent = this.$parent;
      while (parent && !component) {
        if (parent.$options.name === componentName) {
          component = parent;
        }
        parent = parent.$parent;
      }
      return component;
    },

    /**
     * Check if the given option is currently selected.
     * @param  {Object|String}  option
     * @return {Boolean}        True when selected | False otherwise
     */
    isOptionSelected(option) {
      let selected = false;
      this.valueAsArray.forEach((value) => {
        if (typeof value === "object") {
          selected = this.optionObjectComparator(value, option);
        } else if (value === option || value === option[this.index]) {
          selected = true;
        }
      });
      return selected;
    },

    /**
     * Determine if two option objects are matching.
     *
     * @param value {Object}
     * @param option {Object}
     * @returns {boolean}
     */
    optionObjectComparator(value, option) {
      if (this.index && value === option[this.index]) {
        return true;
      } else if (
        value[this.label] === option[this.label] ||
        value[this.label] === option
      ) {
        return true;
      } else if (this.index && value[this.index] === option[this.index]) {
        return true;
      }
      return false;
    },

    /**
     * Finds an option from this.options
     * where option[this.index] matches
     * the passed in value.
     *
     * @param value {Object}
     * @returns {*}
     */
    findOptionByIndexValue(value) {
      let optionValue = Object.assign({}, value);
      this.options.forEach((_option) => {
        if (
          JSON.stringify(_option[this.index]) === JSON.stringify(optionValue)
        ) {
          optionValue = _option;
        }
      });
      return optionValue;
    },

    /**
     * If there is any text in the search input, remove it.
     * Otherwise, blur the search input to close the dropdown.
     * @return {void}
     */
    onEscape() {
      if (!this.search.length) {
        this.$refs.search.blur();
      } else {
        this.search = "";
      }
    },

    /**
     * Close the dropdown on blur.
     * @emits  {search:blur}
     * @return {void}
     */
    onSearchBlur() {
      if (this.mousedown && !this.searching) {
        this.mousedown = false;
      } else {
        if (this.clearSearchOnBlur) {
          this.search = "";
        }
        this.open = false;

        this.$emit("search:blur");
      }
    },

    /**
     * Open the dropdown on focus.
     * @emits  {search:focus}
     * @return {void}
     */
    onSearchFocus() {
      this.open = true;
      this.$emit("search:focus");
    },

    /**
     * Delete the value on Delete keypress when there is no
     * text in the search input, & there's tags to delete
     * @return {this.value}
     */
    maybeDeleteValue() {
      let returnValue = true;
      if (!this.$refs.search.value.length && this.mutableValue) {
        if (this.multiple) {
          returnValue = this.mutableValue.pop();
        } else {
          returnValue = this.mutableValue = null;
        }
        return returnValue;
      }
    },

    /**
     * Determine if an option exists
     * within this.mutableOptions array.
     *
     * @param  {Object || String} option
     * @return {boolean}
     */
    optionExists(option) {
      let exists = false;

      this.mutableOptions.forEach((opt) => {
        if (typeof opt === "object" && opt[this.label] === option) {
          exists = true;
        } else if (opt === option) {
          exists = true;
        }
      });

      return exists;
    },

    /**
     * If push-tags is true, push the
     * given option to mutableOptions.
     *
     * @param  {Object || String} option
     * @return {void}
     */
    maybePushTag(option) {
      if (this.pushTags) {
        this.mutableOptions.push(option);
      }
    },

    /**
     * Event-Handler to help workaround IE11 (probably fixes 10 as well)
     * firing a `blur` event when clicking
     * the dropdown's scrollbar, causing it
     * to collapse abruptly.
     * @return {void}
     */
    onMousedown() {
      this.mousedown = true;
    },

    action(event) {
      switch (event.srcKey) {
        case "enter":
          this.typeAheadSelect(event);
          break;
        case "esc":
          this.onEscape();
          break;
      }
    },

    replaceSpecial(str) {
      str = this.$utils.sanitize.removeEmoji(str);
      this.search = this.$utils.sanitize.removeEmoji(str);
      if (str === null || str === "" || str === undefined) {
        return str;
      } else {
        const oldCaracter =
          "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
        const newCaracter =
          "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        let newstr = "";
        let i, change, a;
        for (i = 0; i < str.length; i++) {
          change = false;
          for (a = 0; a < oldCaracter.length; a++) {
            if (str.substr(i, 1) === oldCaracter.substr(a, 1)) {
              newstr += newCaracter.substr(a, 1);
              change = true;
              break;
            }
          }
          if (change === false) {
            newstr += str.substr(i, 1);
          }
        }
        return newstr;
      }
    },
  },
};
</script>
