<template>
  <Modulebox ref="modulebox" title="Registrar Ações em BPA-I" class="bpa-i">
    <div slot="info" class="header-modulebox-bpai">
      <RadioComparative
        :disabled="disabledByModal"
        :class="{ disable: disabledByModal }"
        :result-callback="selectComparativeMode"
      />
    </div>

    <div class="top-button">
      <LargeButton
        id="select-competence"
        label="Definir Competência"
        :backgroundColor="'#1E88A9'"
        class="btn-import-competence"
        :disabled="disabledByModal"
        @click="selectCompetence"
      >
        <IconCalendar slot="icon" class="icon-import-calendar" color="#fff" />
      </LargeButton>
    </div>
    <FormBase title="Busca de Registros">
      <section class="bpa-i-search">
        <RgValidatorForm ref="validator">
          <section class="bpa-i-search-content">
            <div class="bpa-i-form-search">
              <RgInput
                ref="cnes"
                v-model="formSearch.cnes"
                label="CNES"
                :rules="{ required: true }"
                :disabled="true"
                :class="{ disable: true }"
              />

              <RgSelectCompetence
                ref="competence"
                v-model="formSearch.competence"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                :rules="{ required: true }"
              />

              <RgSuggestEmployee
                ref="employee"
                v-model="formSearch.employee.nameAndCns"
                label="CNS e Nome do Profissional"
                placeholder="Digite o nº do CNS ou nome do Profissional"
                :rules="{
                  required:
                    !formSearch.patient.nameAndCns && !formSearch.bulletin,
                  forceSelection: true,
                }"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                :unitHealthId="formSearch.unitHealthId"
                @selected="selectEmployee"
              />

              <RgSuggestCbo
                ref="occupation"
                v-model="formSearch.occupation"
                label="CBO/Ocupação"
                :employeeId="formSearch.employee.professionalId"
                :unitHealthId="formSearch.unitHealthId"
                :rules="{
                  required:
                    !formSearch.patient.nameAndCns && !formSearch.bulletin,
                }"
                :disabled="!formSearch.employee.nameAndCns || disabledByModal"
                :class="{
                  disable: !formSearch.employee.nameAndCns || disabledByModal,
                }"
                placeholder="Digite o CBO do profissional"
                @selected="selectCbo"
              />

              <RgSelectSheet
                ref="sheet"
                v-model="formSearch.sheet"
                :rules="{
                  required:
                    !formSearch.patient.nameAndCns && !formSearch.bulletin,
                }"
                :filterRegister="{
                  cnes: formSearch.cnes,
                  cns: formSearch.employee.cns,
                  cbo: formSearch.ocp_codigo,
                  competence: formSearch.competence,
                }"
                :disabled="
                  !formSearch.employee.nameAndCns ||
                  !formSearch.occupation ||
                  disabledByModal
                "
                :class="{
                  disable:
                    !formSearch.employee.nameAndCns ||
                    !formSearch.occupation ||
                    disabledByModal,
                }"
              />

              <RgInputDate
                id="initial-date"
                v-model="formSearch.dateInitial"
                label="Data do Início"
                class="inputitem"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                :max-date="maxValidDate"
                :min-date="minValidDate"
                :rules="{ fn: validateInitialDate }"
              />

              <RgInputDate
                id="final-date"
                v-model="formSearch.dateFinal"
                label="Data do Final"
                class="inputitem mg"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                :max-date="maxValidDate"
                :min-date="minValidDate"
                :rules="{ fn: validateFinalDate }"
              />

              <RgSuggestPatientBulletin
                ref="patientName"
                v-model="formSearch.patient.nameAndCns"
                :rules="{
                  required:
                    !formSearch.employee.nameAndCns && !formSearch.bulletin,
                }"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                label="CNS e Nome do Paciente"
                placeholder="Digite o nº do CNS ou nome do Paciente"
                :competence="formSearch.competence"
                :cnes="formSearch.cnes"
                @selected="selectPatientName"
              />

              <RgInput
                id="bulletin-ebs"
                ref="bulletin"
                v-model="formSearch.bulletin"
                :rules="{
                  required:
                    !formSearch.employee.nameAndCns &&
                    !formSearch.patient.nameAndCns,
                }"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
                class="inputitem"
                placeholder="Digite o número do boletim"
                label="Boletim"
              />

              <RgSelectBulletinOrigin
                id="exam-type"
                v-model="formSearch.origin"
                class="origin"
                label="Origem"
                default-text="Todos"
                :class="{ disable: disabledByModal }"
                :disabled="disabledByModal"
              />
            </div>

            <div class="bpa-i-form-search-actions">
              <RgCleanButton
                :disabled="disabledByModal"
                @click="cleanFormSearch"
              />
              <RgSearchButton
                ref="searchBtn"
                :disabled="disabledByModal"
                @submit="searchBPAI"
              />
            </div>
          </section>
        </RgValidatorForm>
      </section>

      <hr class="separator" />

      <section
        v-if="listRegisterBPAIWithoutFilter.length === 0"
        class="no-result"
      >
        <div class="group-options">
          <RgAddButton
            id="adicionar-procedimento"
            small
            data-id="adicionar-procedimento"
            title="Adicionar Registro"
            :disabled="disableRegister || disabledByModal"
            @click="selectModeRegister('register')"
          />
          <RgEditButton
            id="editar-procedimento"
            title="Editar Registro Selecionado"
            disabled
            @click="selectModeRegister('edit')"
          />

          <RgLessButton
            id="excluir-procedimento"
            disabled
            title="Excluir Registro"
            @click="openModalDeleteRegister"
          />

          <SmallButton
            id="transferir-procedimento"
            title="Transferir Registros em Lote"
            disabled
            :backgroundColor="'#1E88A9'"
            @click="openEmployeeTransfer"
          >
            <IconEmployeeTransfer slot="icon" />
          </SmallButton>
          <SmallButton
            id="duplicar-procedimento"
            title="Duplicar Registro Selecionado"
            disabled
            :backgroundColor="'#1E88A9'"
            @click="selectModeRegister('duplicate')"
          >
            <IconDuplicateSheet slot="icon" />
          </SmallButton>
          <LargeButton
            id="exibir-excluidos"
            label="Exibir Excluídos"
            disabled
            :backgroundColor="'#1E88A9'"
          >
            <IconShow slot="icon" fill="white" />
          </LargeButton>
        </div>
        <div class="info">
          <IconEmpty />
          <span v-if="notResultSearch" class="info">
            Não foram encontrados resultados para essa busca
          </span>
          <span v-else class="info">
            Faça uma busca para exibir os resultados aqui
          </span>
        </div>
      </section>

      <section v-else class="bpa-i-table">
        <SmartTable
          ref="smartTable"
          name="BpaI"
          :columns="COLUMNS"
          :body="listRegisterBPAI"
          :initial-columns="25"
          :total="totalExibitionBpai"
          index-column="pdf_id"
          second-index-column="bof_id"
          toggleSelected
          :disabled="disabledByModal"
          withoutResultText="Existem registros excluídos. Clique em Exibir Excluídos."
          :showPagination="!isSearchEmployee"
          class="smart-table"
          :class="{ disable: disabledByModal }"
          multSelect
          hasCheck
          @getMultLines="selectLine"
          @pagination="getPagination"
        >
          <div slot="top-buttons" class="group-options">
            <RgAddButton
              id="adicionar-procedimento"
              small
              data-id="adicionar-procedimento"
              title="Adicionar Registro"
              :disabled="disableRegister || !isExibitionAll || disabledByModal"
              @click="selectModeRegister('register')"
            />

            <RgEditButton
              id="editar-procedimento"
              title="Editar Registro Selecionado"
              :disabled="
                disabledButtons ||
                disableRegister ||
                disabledButtonLineWhite ||
                !isExibitionAll ||
                disabledByModal
              "
              @click="selectModeRegister('edit')"
            />
            <RgLessButton
              id="excluir-procedimento"
              :disabled="
                disabledButtons ||
                disableRegister ||
                disabledButtonLineWhite ||
                !isExibitionAll ||
                disabledByModal
              "
              title="Excluir Registro"
              @click="openModalDeleteRegister"
            />

            <SmallButton
              id="transferir-procedimento"
              title="Trocar profissional "
              :disabled="
                disableRegister ||
                disableTransferEmployee ||
                !isSearchEmployee ||
                disabledButtonLineWhite ||
                !isExibitionAll ||
                disabledByModal
              "
              :backgroundColor="'#1E88A9'"
              @click="openEmployeeTransfer"
            >
              <IconEmployeeTransfer slot="icon" />
            </SmallButton>
            <SmallButton
              id="duplicar-procedimento"
              title="Duplicar Registro Selecionado"
              :disabled="
                disabledButtons ||
                disableRegister ||
                disabledButtonLineWhite ||
                !isExibitionAll ||
                disabledByModal
              "
              :backgroundColor="'#1E88A9'"
              @click="selectModeRegister('duplicate')"
            >
              <IconDuplicateSheet slot="icon" />
            </SmallButton>

            <LargeHollowButton
              v-show="isExibitionAll"
              id="exibir-excluidos"
              label="Exibir Excluídos"
              :disabled="!isSearchEmployee || disabledByModal"
              :borderColor="'#1E88A9'"
              :fontColor="'#1E88A9'"
              @click="filterRegisters(true, true)"
            >
              <IconShow
                slot="icon"
                :fill="
                  !isSearchEmployee || disabledByModal ? '#fff' : '#1E88A9'
                "
              />
            </LargeHollowButton>
            <LargeButton
              v-show="!isExibitionAll"
              id="ocultar-excluidos"
              label="Ocultar Excluídos"
              :disabled="!isSearchEmployee || disabledByModal"
              :backgroundColor="'#1E88A9'"
              @click="filterRegisters(false, true)"
            >
              <IconNotShow slot="icon" />
            </LargeButton>
          </div>
        </SmartTable>

        <p v-if="isSearchEmployee" class="total-records">
          <strong>Registros por folha: {{ total }}/99</strong>
        </p>
      </section>
    </FormBase>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      hasSearch
      @close="closeModalDeleteRegister"
      @reSearch="searchBPAI"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalTransferEmployee
      :show="modalTransferEmployee"
      :formData="dataTranferEmployee"
      @close="closeModalTransferEmployee"
      @reSearch="searchBPAI"
    />
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgCleanButton,
  RgSearchButton,
  RgEditButton,
  RgAddButton,
  RgLessButton,
  SmallButton,
  IconDuplicateSheet,
  RgInput,
  IconEmpty,
  LargeButton,
  IconCalendar,
  RgInputDate,
  ModalConfirmDeletion,
  IconEmployeeTransfer,
  IconShow,
  IconNotShow,
  LargeHollowButton,
} from "~tokio/primitive";

import { mapGetters } from "vuex";
import { Modulebox } from "~tokio/foundation";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import moment from "moment";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import {
  RadioComparative,
  RgSelectSheet,
  RgSelectBulletinOrigin,
  ModalTransferEmployee,
} from "$billing/submodules/bpa/bpa-i/components";

import {
  RgSuggestEmployee,
  RgSelectCompetence,
  RgSuggestCbo,
  RgSuggestPatientBulletin,
} from "$billing/common/components/index";

export default {
  name: "SearchBpaI",
  components: {
    Modulebox,
    FormBase,
    SmartTable,
    RgValidatorForm,
    RgCleanButton,
    RgSearchButton,
    RgEditButton,
    RgAddButton,
    RgLessButton,
    RgInputDate,
    SmallButton,
    IconDuplicateSheet,
    RgInput,
    RgSelectCompetence,
    RgSelectSheet,
    RgSuggestEmployee,
    RgSuggestCbo,
    RgSelectBulletinOrigin,
    RadioComparative,
    IconEmpty,
    LargeButton,
    IconCalendar,
    ModalConfirmDeletion,
    IconEmployeeTransfer,
    ModalTransferEmployee,
    IconShow,
    IconNotShow,
    LargeHollowButton,
    RgSuggestPatientBulletin,
  },

  data() {
    return {
      formSearch: {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        cnes: this.$store.getters["Login/GET_UNIT_HEALTH_CNES"],
        competence: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
        employee: {
          professionalId: null,
          nameAndCns: null,
          cns: null,
          professionalName: null,
          vin_id: null,
          crs_numero: null,
        },
        patient: {
          name: null,
          cns: null,
          bof_id: null,
          nameAndCns: null,
        },
        sheet: null,
        occupation: null,
        ocp_id: null,
        ocp_nome: null,
        ocp_codigo: null,
        dateInitial: "",
        dateFinal: "",
        bulletin: null,
        origin: null,
      },
      mutableRegisterList: [],
      listRegisterBPAI: [],
      listRegisterBPAIWithoutFilter: [],
      selectedRow: {},
      dataTranferEmployee: [],
      total: 0,
      totalExibitionBpai: 0,
      disabledButtons: true,
      disableRegister: true,
      disableTransferEmployee: true,
      disabledButtonLineWhite: true,
      isSearchEmployee: false,
      modalDeleteRegister: false,
      notResultSearch: false,
      modalTransferEmployee: false,
      isExibitionAll: true,
      hasFilter: false,
      cleanDateInitialAndFinal: true,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      cnes: "Login/GET_UNIT_HEALTH_CNES",
      competence: "Billing/BDSia/GET_FORMATTED_PERIOD_DATE",
      searchFilter: "Billing/BPAI/GET_FILTER_BILLING_BPAI",
    }),
    disabledByModal() {
      return this.modalTransferEmployee || this.modalDeleteRegister;
    },
    propsTagCustom() {
      const list = [
        {
          title: "Modo Comparativo",
          blue: true,
          id: 1,
          value: "",
        },
      ];
      const uniqueKey = "id";
      return { list, uniqueKey };
    },
    filterIsComplete() {
      return !!(
        this.formSearch.cnes &&
        this.formSearch.competence &&
        this.formSearch.employee.nameAndCns &&
        this.formSearch.occupation &&
        this.formSearch.sheet
      );
    },
    propsModalConfirmDeletion() {
      const show = this.modalDeleteRegister;
      const registerSelected = {
        ...this.$store.getters["Billing/BPAI/GET_REGISTER_BILLING_BPAI"],
      };
      const confirm = () => this.removeBulletinBilling(this.selectedRow);
      const noReason = true;

      const title = "Excluir Sequencia de Registro BPA-I";
      const message =
        "O registro selecionado será removido da remessa <br/> contida na Folha da produção atual";
      const btnRemoveTitle = "Excluir";
      const bodyText = `Folha: ${registerSelected?.pdf_folha} <br />
      Sequencia: ${registerSelected?.sequence} <br />
      Procedimento: ${registerSelected?.procedimento} <br />
      Paciente: ${registerSelected?.bof_nome}`;
      const hasSearch = true;
      const justifyContent = false;
      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
        hasSearch,
        justifyContent,
      };
    },

    minValidDate() {
      const dateSelected = this.formSearch.competence;
      const date = moment(dateSelected, "YYYYMM")
        .subtract(3, "months")
        .format("YYYY-MM-[02]");
      return new Date(date);
    },
    maxValidDate() {
      const date = moment(this.formSearch.competence, "YYYYMM")
        .add(1, "months")
        .format("YYYY-MM");
      return new Date(date);
    },
  },
  watch: {
    "formSearch.competence"(pValue) {
      if (pValue) {
        if (!this.hasFilter) this.$loader.start();
        this.validCompetence();
        this.cleanList();
        this.mutableRegisterList = [];
      }
      if (this.cleanDateInitialAndFinal) {
        this.formSearch.dateFinal = "";
        this.formSearch.dateInitial = "";
      } else {
        this.cleanDateInitialAndFinal = true;
      }
      if (!this.hasFilter) this.$loader.finish();
    },
    "formSearch.sheet"(pValue) {
      if (!pValue) {
        this.cleanList();
      }
    },
    "formSearch.occupation"(pValue) {
      if (!pValue) {
        this.formSearch.sheet = null;
        this.cleanList();
      }
    },
    "formSearch.bulletin"(pValue) {
      if (pValue) {
        this.$refs.patientName.cleanValidate();
        this.$refs.employee.cleanValidate();
        this.$refs.occupation.cleanValidate();
        this.$refs.sheet.cleanValidate();
      } else {
        this.cleanList();
      }
    },
    "formSearch.employee.nameAndCns"(pValue) {
      if (!pValue) {
        this.formSearch.occupation = null;
        this.formSearch.sheet = null;
        this.formSearch.employee = {
          professionalId: null,
          nameAndCns: null,
          cns: null,
          professionalName: null,
          vin_id: null,
          crs_numero: null,
        };
        this.cleanList();
      } else {
        this.$refs.patientName.cleanValidate();
        this.$refs.bulletin.cleanValidate();
      }
    },
    "formSearch.patient.nameAndCns"(pValue) {
      if (!pValue) {
        this.formSearch.patient.name = null;
        this.formSearch.patient.cns = null;
        this.formSearch.patient.bof_id = null;
        this.formSearch.patient.nameAndCns = null;
        this.cleanList();
      } else {
        this.$refs.employee.cleanValidate();
        this.$refs.occupation.cleanValidate();
        this.$refs.sheet.cleanValidate();
        this.$refs.bulletin.cleanValidate();
      }
    },
  },

  async mounted() {
    try {
      this.$loader.start();
      this.notResultSearch = false;
      if (this.searchFilter) {
        this.hasFilter = true;
        await this.fillFilters();
        await this.searchBPAI();
      } else {
        this.validCompetence();
      }
    } catch (pErr) {
      this.$toaster.warning("Não foi possível preencher os filtros");
    } finally {
      this.hasFilter = false;
      this.$loader.finish();
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/billing/bpa/bpa-i",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Billing/BPAI/RESET_FILTER_BILLING_BPAI");
      this.$store.commit("Billing/BPAI/CLEAN_REGISTER_BILLING_BPAI");
    }
  },

  created() {
    this.COLUMNS = [
      {
        name: "Atendimento",
        key: "data_atendimento",
        align: "left",
      },
      {
        name: "Competência",
        key: "bof_competencia",
        align: "right",
      },
      { name: "Folha", key: "pdf_folha", align: "right" },
      { name: "Sequência", key: "sequence", align: "right" },
      {
        name: "Origem do Atendimento",
        key: "origem_atendimento",
        align: "left",
      },
      { name: "Boletim", key: "bol_numero", align: "right" },

      {
        name: "CNS do Paciente",
        key: "bof_cns",
        align: "right",
      },
      { name: "Paciente", key: "bof_nome", align: "left" },
      {
        name: "Nascimento",
        key: "data_nascimento",
        align: "left",
      },
      { name: "Sexo", key: "bof_sexo", align: "left" },
      {
        name: "CNS do Profissional",
        key: "pdf_cns",
        align: "right",
      },
      {
        name: "Profissional",
        key: "pdf_nome_profissional",
        align: "left",
      },
      {
        name: "CBO",
        key: "pdf_cod_cbo",
        align: "right",
      },
      {
        name: "Procedimento",
        key: "procedimento",
        align: "left",
      },
      {
        name: "Quantidade",
        key: "pdf_quantidade",
        align: "right",
      },
      { name: "CID", key: "pdf_cid", align: "left" },
      {
        name: "Caráter de Atendimento",
        key: "pdf_id_internacoes_caracteres",
        align: "right",
      },
      {
        name: "Autorização",
        key: "pdf_num_autorizacao",
        align: "left",
      },
      { name: "Raça/Cor", key: "bof_cod_raca", align: "right" },
      {
        name: "Etnia",
        key: "bof_cod_etnia",
        align: "right",
      },
      {
        name: "Nacionalidade",
        key: "bof_cod_nacionalidade",
        align: "right",
      },
      {
        name: "Serviço",
        key: "pdf_servico",
        align: "right",
      },
      {
        name: "Classificação",
        key: "pdf_classificacao",
        align: "left",
      },
      { name: "CEP", key: "bof_cep", align: "right" },
      {
        name: "Município",
        key: "bof_cod_municipio",
        align: "right",
      },
      {
        name: "Logradouro",
        key: "bof_cod_tipo_logradouro",
        align: "right",
      },
      { name: "Endereço", key: "bof_endereco", align: "left" },
      {
        name: "Complemento",
        key: "bof_endereco_complemento",
        align: "left",
      },
      {
        name: "Número",
        key: "bof_endereco_numero",
        align: "right",
      },
      { name: "Bairro", key: "bof_bairro", align: "left" },
      { name: "DDD", key: "bof_tel_ddd", align: "right" },
      {
        name: "Telefone",
        key: "bof_tel",
        align: "right",
      },

      { name: "E-mail", key: "bof_email", align: "left" },
    ];
  },

  methods: {
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.formSearch.dateInitial, "DD/MM/YYYY");
      const finalDate = moment(this.formSearch.dateFinal, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.formSearch.dateInitial, "DD/MM/YYYY");
      const finalDate = moment(this.formSearch.dateFinal, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },
    async selectPatientName(pValue) {
      const data =
        pValue &&
        Object.keys(pValue).length > 0 &&
        Object.keys(pValue.source).length > 0
          ? pValue.source
          : null;
      if (data) {
        const cns = data.bof_cns ? data.bof_cns : null;
        this.formSearch.patient.name = data.bof_nome;
        this.formSearch.patient.cns = cns;
        this.formSearch.patient.bof_id = data?.bof_id;
        this.formSearch.patient.nameAndCns = await (data?.bof_cns +
          " - " +
          data?.bof_nome);
      } else {
        this.formSearch.patient.bof_id = null;
      }
    },
    selectCompetence() {
      this.$router.push({
        name: "billing.bpa.define-period",
      });
    },
    async validateFormSearch() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async searchBPAI() {
      try {
        if (!this.hasFilter) this.$loader.start();

        if (
          !this.formSearch.employee.nameAndCns &&
          !this.formSearch.patient.nameAndCns &&
          !this.formSearch.bulletin
        ) {
          this.$toaster.info(
            "Escolha um desses campos obrigatórios para preencher e realizar a busca.",
            "Insira um profissional, paciente ou boletim para buscar",
          );
          return false;
        }

        if (!this.hasFilter) {
          const validateForm = await this.validateFormSearch();
          if (!validateForm) {
            this.$toaster.warning("Verifique os campos");
            return false;
          }
        }

        let patientName;
        let patientCns;

        const format = /[-]+/;
        const validate = format.test(this.formSearch.patient.nameAndCns);

        if (!validate) {
          if (isNaN(this.formSearch.patient.nameAndCns)) {
            patientName = this.formSearch.patient.nameAndCns;
            this.formSearch.patient.name = patientName;
          } else {
            patientCns = this.formSearch.patient.nameAndCns;
            this.formSearch.patient.cns = patientCns;
          }
        }
        const isTransfer = this.isTransferEmployee();
        this.isExibitionAll = true;

        const variables = {
          cnes: this.formSearch.cnes,
          professionalCns: this.formSearch.employee.cns,
          professionalName: this.formSearch.employee.professionalName,
          patientName: this.formSearch.patient.name || patientName || null,
          patientCns: this.formSearch.patient.cns || patientCns || null,
          cboCode: this.formSearch.ocp_codigo
            ? String(this.formSearch.ocp_codigo)
            : null,
          competence: this.formSearch.competence,
          sheet: this.formSearch.sheet,
          bulletin: this.formSearch.bulletin ? this.formSearch.bulletin : null,
          origin:
            this.formSearch.origin && this.formSearch.origin !== "null"
              ? this.formSearch.origin
              : null,
          dateInitial: this.formSearch.dateInitial
            ? this.formSearch.dateInitial
            : null,
          dateFinal: this.formSearch.dateFinal
            ? this.formSearch.dateFinal
            : null,
          offset: !isTransfer ? this.pagination.offset : undefined,
          limit: !isTransfer ? this.pagination.limit : undefined,
        };

        const result = await this.$store.dispatch(
          "Billing/BPAI/SEARCH_BPAI",
          variables,
        );

        this.notResultSearch = true;

        this.listRegisterBPAIWithoutFilter = result.rows.map((element) => {
          element.data_atendimento =
            element.data_atendimento === "00/00/0000"
              ? null
              : element.data_atendimento;
          element.data_nascimento =
            element.data_nascimento === "00/00/0000"
              ? null
              : element.data_nascimento;
          return {
            ...element,
          };
        });

        this.filterRegisters();
        if (!isTransfer) {
          this.totalExibitionBpai = result.count;
        }
        this.total = result.count;

        this.$store.commit(
          "Billing/BPAI/SET_FILTER_BILLING_BPAI",
          this.formSearch,
        );

        this.validCompetence();
      } catch (pErr) {
        this.$toaster.error("Erro ao buscar BPAI");
      } finally {
        if (this.$refs.searchBtn) {
          this.$refs.searchBtn.actionSubmit();
          this.$refs.searchBtn.actionDone();
        }
        if (!this.hasFilter) this.$loader.finish();
      }
    },
    async fillFilters() {
      try {
        const filterData = this.$store.getters[
          "Billing/BPAI/GET_FILTER_BILLING_BPAI"
        ];

        if (filterData && Object.keys(filterData).length > 0) {
          if (filterData.competence) {
            this.formSearch.competence = filterData.competence;
          }
          if (filterData.employee) {
            this.$refs.employee.forceSelection({
              pes_id: filterData.employee.employeePersonId,
              pes_nome: filterData.employee.professionalName,
              vin_id: filterData.employee.vin_id,
              fun_id: filterData.employee.professionalId,
              person: {
                cns: {
                  crs_numero: filterData.employee.cns
                    ? filterData.employee.cns
                    : "000000000000000",
                },
              },
            });
          }
          if (filterData.patient) {
            if (filterData.patient.bof_id) {
              this.$refs.patientName.forceSelection({
                bof_nome: filterData.patient.name,
                bof_id: filterData.patient.bof_id,
                bof_cns: filterData.patient.cns
                  ? filterData.patient.cns
                  : "000000000000000",
              });
            } else {
              let patientName;
              let patientCns;

              this.$refs.patientName.inputValue = filterData.patient.nameAndCns;

              const format = /[-]+/;
              const validate = format.test(this.formSearch.patient.nameAndCns);

              if (!validate) {
                if (isNaN(this.formSearch.patient.nameAndCns)) {
                  patientName = this.formSearch.patient.nameAndCns;
                  this.formSearch.patient.name = patientName;
                } else {
                  patientCns = this.formSearch.patient.nameAndCns;
                  this.formSearch.patient.cns = patientCns;
                }
              }

              this.formSearch.patient.name = filterData.patient.name || null;
              this.formSearch.patient.cns = filterData.patient.cns || null;
            }
          }
          this.$refs.occupation.forceSelection({
            ocp_id: Number(filterData.ocp_id),
            ocp_nome: filterData.ocp_nome,
            ocp_codigo: filterData.ocp_codigo,
          });
          this.formSearch.employee.nameAndCns = filterData.employee.nameAndCns;
          this.formSearch.employee.cns = filterData.employee.cns;

          this.formSearch.occupation = filterData.occupation;
          this.formSearch.sheet = filterData.sheet;
          this.cleanDateInitialAndFinal = false;
          this.formSearch.dateInitial = filterData.dateInitial;
          this.formSearch.dateFinal = filterData.dateFinal;
          this.formSearch.bulletin = filterData.bulletin;
          this.formSearch.origin = filterData.origin;
        }
      } catch (pErr) {
        this.$toaster.warning("Não foi possível preencher os filtros");
      }
    },
    selectLine(pRegister) {
      if (pRegister?.length === 1) {
        this.disabledButtons = false;
        if (
          pRegister[0].pdf_cod_procedimento &&
          pRegister[0].pdf_cod_procedimento !== ""
        ) {
          this.disabledButtonLineWhite = false;
        } else {
          this.disabledButtonLineWhite = true;
        }
        this.disableTransferEmployee = false;
        this.selectedRow = pRegister[0];
        this.dataTranferEmployee = pRegister;
      } else if (pRegister?.length > 1) {
        this.dataTranferEmployee = pRegister;
        this.disableTransferEmployee = false;
        this.disabledButtons = true;

        if (this.isExibitionAll) {
          this.disabledButtonLineWhite = false;
        } else {
          this.disabledButtonLineWhite = true;
        }
      } else {
        this.disableTransferEmployee = true;
        this.disabledButtons = true;
        this.disabledButtonLineWhite = true;
        this.selectedRow = null;
        this.dataTranferEmployee = [];
      }
      this.$store.commit("Billing/BPAI/CLEAN_REGISTER_BILLING_BPAI");
      this.$store.commit("Billing/BPAI/SELECT_REGISTER_BILLING_BPAI", {
        ...pRegister[0],
      });
    },
    selectComparativeMode(pActive) {
      if (pActive) {
        this.$router.push({ name: "billing.bpa.bpa-i.comparative-mode" });
      }
    },
    selectModeRegister(pValue) {
      switch (pValue) {
        case "register": {
          this.$router.push({ name: "billing.bpa.bpa-i.register" });
          break;
        }
        case "edit": {
          this.$router.push({ name: "billing.bpa.bpa-i.edit" });
          break;
        }
        case "duplicate": {
          this.$router.push({ name: "billing.bpa.bpa-i.duplicate" });
          break;
        }
        default:
          break;
      }
    },
    selectEmployee(pValue) {
      if (pValue.source) {
        this.formSearch.employee.cns =
          pValue.source.cns || pValue.source.crs_numero;
        this.formSearch.employee.professionalName = pValue.source.pes_nome;
        this.formSearch.employee.professionalId = pValue.source.fun_id;
        this.formSearch.employee.vin_id = pValue.source.vin_id;
        this.formSearch.employee.crs_numero = pValue.source.crs_numero;
      }
    },
    selectCbo(pValue) {
      this.formSearch.ocp_id = pValue.source?.ocp_id;
      this.formSearch.ocp_codigo = pValue.source?.ocp_codigo;
      this.formSearch.ocp_nome = pValue.source?.ocp_nome;
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "cnes",
        "competence",
        "employee",
        "sheet",
        "occupation",
        "patientName",
        "bulletin",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    async validCompetence() {
      const result = await this.$store.dispatch(
        "Billing/BPAI/LAST_COMPETENCE_BPAI",
      );

      const lastCompetence = result[0].DT_COMPETENCIA;
      const limitRetroactive = moment(lastCompetence, "YYYY-MM")
        .subtract(3, "months")
        .format("YYYYMM");

      if (Number(limitRetroactive) <= Number(this.formSearch.competence)) {
        this.disableRegister = false;
      } else {
        this.disableRegister = true;
      }
    },
    cleanList() {
      this.listRegisterBPAI = [];
      this.listRegisterBPAIWithoutFilter = [];
      this.selectedRow = {};
      if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
      this.cleanFieldsValidation();
      this.cleanPagination();
    },
    cleanFormSearch() {
      this.formSearch = {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        cnes: this.$store.getters["Login/GET_UNIT_HEALTH_CNES"],
        competence: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
        employee: {
          professionalId: null,
          nameAndCns: null,
          cns: null,
          professionalName: null,
          vin_id: null,
          crs_numero: null,
        },
        patient: {
          name: null,
          cns: null,
          bof_id: null,
          nameAndCns: null,
        },
        sheet: null,
        occupation: null,
        ocp_id: null,
        ocp_nome: null,
        ocp_codigo: null,
        dateInitial: "",
        dateFinal: "",
        bulletin: null,
        origin: null,
      };
      this.notResultSearch = false;
      this.mutableRegisterList = [];
      this.listRegisterBPAIWithoutFilter = [];
      this.listRegisterBPAI = [];
      this.selectedRow = null;
      this.dataTranferEmployee = [];
      this.total = 0;
      this.disabledButtons = true;
      this.cleanFieldsValidation();
      this.$store.commit("Billing/BPAI/RESET_FILTER_BILLING_BPAI");
      this.$store.commit("Billing/BPAI/CLEAN_REGISTER_BILLING_BPAI");
      this.cleanPagination();
    },
    openModalDeleteRegister() {
      this.modalDeleteRegister = true;
    },
    closeModalDeleteRegister() {
      this.modalDeleteRegister = false;
      this.selectedRow = null;
      this.dataTranferEmployee = [];
      this.disabledButtons = true;
      this.disableTransferEmployee = true;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
    },
    async removeBulletinBilling(pData) {
      try {
        await this.$store.dispatch("Billing/BPAI/REMOVE_BULLETIN_BILLING", {
          variables: {
            bulletinBilling: {
              bof_id: Number(pData.bof_id),
              pdf_id: Number(pData.pdf_id),
            },
          },
        });
        this.disabledButtons = true;
        this.$store.commit("Billing/BPAI/CLEAN_REGISTER_BILLING_BPAI");
      } catch (pErr) {
        this.$toaster.error("Erro ao remover BPAI");
      } finally {
        this.$loader.finish();
      }
    },

    openEmployeeTransfer() {
      this.modalTransferEmployee = true;
    },
    closeModalTransferEmployee() {
      this.modalTransferEmployee = false;
      this.selectedRow = null;
      this.dataTranferEmployee = [];
      this.disableTransferEmployee = true;
      this.disabledButtons = true;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
    },
    isTransferEmployee() {
      this.formSearch.employee.cns !== null
        ? (this.isSearchEmployee = true)
        : (this.isSearchEmployee = false);
      return this.formSearch.employee.cns !== null;
    },
    async getPagination(pPagination) {
      this.pagination = pPagination;
      this.searchBPAI();
    },
    cleanPagination() {
      this.pagination.limit = 10;
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },
    filterRegisters(isExibitionAll = false, byButton = false) {
      let aux = this.listRegisterBPAIWithoutFilter;
      if (!isExibitionAll) {
        aux = aux.filter((item) => {
          return !(
            item.pdf_cod_procedimento === null ||
            item.pdf_cod_procedimento === ""
          );
        });
      }

      this.listRegisterBPAI = aux;

      this.listRegisterBPAI.map((element, index) => {
        element.sequence = String(((index + 1) / 10).toFixed(1)).replace(
          ".",
          "",
        );
        return element;
      });
      this.totalExibitionBpai = this.listRegisterBPAI.length;
      if (byButton) this.isExibitionAll = !isExibitionAll;
      this.selectedRow = null;
      this.disabledButtonLineWhite = true;
      this.disabledButtons = true;
      this.disableTransferEmployee = true;
      this.dataTranferEmployee = Array.from([]);
      if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
    },
  },
};
</script>
