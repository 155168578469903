<template>
  <div v-show="show" class="modal-finish-import-sigtap-bdsia">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-history-movement-header">
        <span class="title"> Atendimento SIGTAP/ BDSIA Finalizado </span>
      </div>
      <div slot="body">
        <FormBase withoutTitle>
          <div class="header-icon">
            <font-awesome-icon
              icon="fa-solid fa-circle-check"
              beat
              class="icon-style"
            />
            <div class="subtitle">A importação foi realizada com sucesso</div>
            <hr />
          </div>
          <div class="content">
            <span class="title-content">
              <strong> Dados da Importação </strong>
            </span>
            <span>
              Competência SIGTAP: <strong>{{ competenceSigtap }}</strong>
            </span>
            <span :title="fileNameSigtap">
              Arquivo SIGTAP:
              <strong>{{
                fileNameSigtap.length > 21
                  ? `${fileNameSigtap.slice(0, 21)}...`
                  : fileNameSigtap
              }}</strong>
            </span>
            <span>
              Competência BDSIA: <strong>{{ competenceBdsia }}</strong>
            </span>
            <span :title="fileNameBdsia">
              Arquivo BDSIA:
              <strong>{{
                fileNameBdsia.length > 21
                  ? `${fileNameBdsia.slice(0, 21)}...`
                  : fileNameBdsia
              }}</strong>
            </span>
            <span class="date-import">
              Data da Importação: <strong>{{ dateImport }}</strong>
            </span>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import moment from "moment";

export default {
  name: "ModalFinishSigtapBdsia",
  components: {
    RgBaseModal,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showOld: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      competenceSigtap: "-",
      competenceBdsia: "-",
      fileNameSigtap: "-",
      fileNameBdsia: "-",
      dateImport: "-",
    };
  },
  computed: {},
  watch: {
    show: {
      handler(pValue) {
        if (pValue) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$store.commit(
        "Billing/ImportBdsiaSigtap/RESET_DATA_IMPORT_SIGTAP_BDSIA",
      );
      this.$emit("reload");
      this.showOld(0);
    },
    async loadData() {
      const dataImport = {
        ...this.$store.getters[
          "Billing/ImportBdsiaSigtap/GET_DATA_IMPORT_SIGTAP_BDSIA"
        ],
      };

      this.competenceBdsia = moment(
        dataImport.cmp_import_bdsia,
        "YYYYMM",
      ).format("MM/YYYY");
      this.competenceSigtap = moment(
        dataImport.cmp_import_sigtap,
        "YYYYMM",
      ).format("MM/YYYY");
      this.fileNameBdsia = dataImport.name_file_bdsia;
      this.fileNameSigtap = dataImport.name_file_sigtap;
      this.dateImport = moment().format("DD/MM/YYYY - HH:mm");

      this.save();
    },
    async save() {
      try {
        await this.$store.dispatch("Billing/ImportBdsiaSigtap/HISTORY_IMPORT", {
          variables: {
            historyImport: {
              competenceBdsia: moment(this.competenceBdsia, "MM/YYYY").format(
                "YYYYMM",
              ),
              competenceSigtap: moment(this.competenceSigtap, "MM/YYYY").format(
                "YYYYMM",
              ),
              fileBdsia: this.fileNameBdsia,
              fileSigtap: this.fileNameSigtap,
              dateImport: moment().format(),
            },
          },
        });
      } catch (error) {
        this.$toaster.error(
          `Erro na comunicação com o servidor. Tente novamente ou contate o suporte.`,
          "Falha ao registrar o Histórico de Importação",
        );
      }
    },
  },
};
</script>
