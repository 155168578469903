import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    intIdVinculo: variables.bondId,
    strDataConsulta: variables.serviceDate,
  };

  return AuthLegacyRequest.post(
    "faturamento/controller-atendimento/carregar-ficha-controle-ambulatorial",
    sendData,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
