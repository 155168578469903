<script>
import RgRemoteSelect from "~tokio/primitive/selection/rg-select/RgRemoteSelect";
import GetCriticalReleaseRequest from "./action/GetCriticalReleaseRequest.js";

export default {
  name: "RgRemoteSelectCriticalReleaseRequest",
  mixins: [RgRemoteSelect],
  props: {
    /**
     * Tell a label to RgFormBase
     * @type {String}
     */
    label: {
      type: String,
      default: "Selecione as liberações de crítica:",
    },
    /**
     * Equivalent to the `multiple` attribute on a `<select>` input.
     * @type {Boolean}
     */
    multiple: {
      type: Boolean,
      default: true,
    },
    canBeSelected: {
      type: Function,
      default: (item) => {
        const can = item.canBeSelected(this.selectedItems);
        if (can) {
          this.selectedItems.push(item);
        }
        return can;
      },
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutableOptions: [],
    };
  },
  methods: {
    async doSearch() {
      const criticalReleaseRequest = GetCriticalReleaseRequest();
      this.mutableOptions = await this.removeSelectedItens(
        criticalReleaseRequest,
      );
      return criticalReleaseRequest;
    },
    removeSelectedItens(values) {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve(
            values.filter((item) => {
              if (Array.isArray(this.value)) {
                return (
                  this.value.find((item2) => item2.value === item.id) ===
                  undefined
                );
              } else {
                return item.id !== this.value.value;
              }
            }),
          );
        });
      });
    },
    formatItem(item) {
      return {
        value: item.id,
        label: `${item.id} - ${item.name}`,
        canBeSelected: item.canBeSelected,
      };
    },
  },
};
</script>
