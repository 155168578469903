import LocalStorage from "~common/local-storage/LocalStorage";
const ScheduleVoucherHTML = (pValue) => {
  const logo = LocalStorage.getObject("client-logo");
  const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta charset="UTF-8">
    <title>Ficha de Internacao do Paciente</title>
  </head>
  <style type="text/css">
		body{
			  font-size: 12px;
			  font-family: Arial, sans-serif;
		}

		.brd{
				border: 1px solid;
				overflow: hidden;
				padding:2px;
				font-weight:bold;
				height:15px;
			}

		td{font-size: 10px;}

		.w99{width: 99%;}
		.w80{width: 80%;}
		.w70{width: 60%;}
		.w60{width: 70%;}
		.w75{width: 75%;}
		.w50{width: 50%;}
		.w40{width: 40%;}
		.w29{width: 29%;}
		.w24{width: 24%;}
		.w25{width: 25%;}
		.w20{width: 20%;}
		.w19{width: 19%;}
		.w16{width: 16%;}
		.w10{width: 10%;}
		.w5 {width: 5% ;}

		header{
			/*border: 1px solid black;*/
			display: inline-block;
			width: 100%;
		}

		.logoCliente{
			float: left;
			width:  70px;
			height: 50px;
		}

		.logoEsus{
			float: right;
			width:  70px;
			height: 70px;
		}

		article{
			/*border: 1px solid black;*/
			width: 100%;
			display: inline-block;
			margin-top: 0px;
		}

		.titulo{
			text-align: center;
		}

		table {
			border: 0px auto;
			width: 100%;
			margin-top: 0px;

		}

		h1, h3 {
			margin-top: -10px;
			margin-bottom: -15px;
		}

		h2{
			margin-top: -10px;
			margin-bottom: -10px;
		}

		label{font-size: 10px;}



  </style>
  <body>
  <header>
    <table class="brd">
      <tr>
        <td class="w20 titulo"><img src="${logo}" class="logoCliente"></td>
        <td class="w60 titulo"><h2><p>FICHA DE INTERNAÇÃO DO PACIENTE Nº : ${pValue.int_numero}</p></h2></td>
        </td>
      </tr>
  </header>
  <article>
    <div>
      <table width="100%">
        <tr>
          <td>
            <label>Unidade de Saúde</label>
          </td>
          <td>
            <label>CNES</label>
          </td>
          <td>
            <label>Profissional Solicitante</label>
          </td>
        </tr>
        <tr>
          <td class="brd w70">${pValue.Clinica}</td>
          <td class="brd w20">${pValue.uns_cnes}</td>
          <td class="brd w29">${pValue.profissionalSolicitante}</td>

        </tr>
      </table>
    </div>
  </article>
  <article>
  </article>
  <article>
  <h3 class="titulo"><p>DADOS DO PACIENTE</p></h3>
        <div>
          <table width="100%">
            <tr>
              <td>
                <label>Prontuário Único</label>
              </td>
              <td>
                <label>Prontuário</label>
              </td>
              <td>
                <label>Data</label>
              </td>
              <td>
                <label>Hora</label>
              </td>
              <td>
                <label>Enfermaria</label>
              </td>
              <td>
                <label>Leito</label>
              </td>
            </tr>
            <tr>
              <td class="brd">${pValue.pac_prontuario_unico}</td>
              <td class="brd">${pValue.ppr_numero}</td>
              <td class="brd">${pValue.int_data}</td>
              <td class="brd">${pValue.int_hora}</td>
              <td class="brd">${pValue.lca_nome}</td>
              <td class="brd">${pValue.inl_nome}</td>
            </tr>
          </table>
        </div>

        <div>
          <table width="100%">
            <tr>
              <td>
                <label>Nome</label>
              <td>
                <label>Nº Cartao SUS</label>
              </td>
            </tr>
            <tr>
              <td class="brd w75">${pValue.paciente}</td>
              <td class="brd w25">${pValue.cartao_sus}</td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <label>CPF</label>
              <td>
                <label>RG</label>
              </td>
            </tr>
            <tr>
              <td class="brd w50">${pValue.pac_cpf}</td>
              <td class="brd w50">${pValue.pac_rg}</td>
            </tr>
          </table>
        </div>
        <div>
          <table width="100%">
            <tr>
              <td>
                <label>Endereço</label>
              </td>
              <td>
                <label>Bairro</label>
              </td>
              <td>
                <label>Município</label>
              </td>
              <td>
                <label>UF</label>
              </td>
            </tr>
            <tr>
              <td class="brd w45">${pValue.end_logradouro}</td>
              <td class="brd w25">${pValue.bairro}</td>
              <td class="brd w25">${pValue.municipio}</td>
              <td class="brd w5">${pValue.UF}</td>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <td style="width:15%;">
                <label>Cep</label>
              </td>
              <td style="width:45%;">
                <label>Telefone</label>
              </td>
              <td style="width:10%;">
                <label>Nascimento</label>
              </td>
              <td style="width:5%;">
                <label>Idade</label>
              </td>
              <td style="width:5%;">
                <label>Sexo</label>
              </td>
              <td style="width:10%;">
                <label>Etnia</label>
              </td>
              <td style="width:10%;">
                <label>Estado Civil</label>
              </td>
            </tr>
            <tr>
              <td class="brd">${pValue.end_cep}</td>
              <td class="brd">${pValue.telefones}</td>
              <td class="brd">${pValue.nascimento}</td>
              <td class="brd">${pValue.idade}</td>
              <td class="brd">${pValue.sexo}</td>
              <td class="brd">${pValue.etnia}</td>
              <td class="brd">${pValue.estadoCivil}</td>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <td style="width:50%;">
                <label>Naturalidade</label>
              </td>
              <td style="width:5%;">
                <label>UF</label>
              </td>
              <td style="width:45%;">
                <label>Condição de Dependência</label>
              </td>
            </tr>
            <tr>
              <td class="brd">${pValue.naturalidade}</td>
              <td class="brd">${pValue.UF_res}</td>
              <td class="brd">${pValue.itr_nome}</td>
            </tr>
          </table>
          </div>
        <div>
          <table>
            <tr>
              <td style="width:75%;">
                <label>Nome da Mãe</label>
              </td>
              <!-- Comentei este campo pois no cadastro de pessoa
                somente coletamos texto com nome da mãe, não documentos
                referente a mesma leonam 08/03/17
              <td style="width:25%;">
                <label>Nº Cartao SUS/Identificação Civi</label>
              </td> -->
            </tr>
            <tr>
              <td class="brd">${pValue.pes_mae}</td>
              <!-- <td class="brd">&nbsp;</td> -->
            </tr>
          </table>
          <table>
            <tr>
              <td class="w70" >
                      <label>Responsável</label>
              </td>
              <td class="w20" >
              <label>CPF do Responsável</label>
              <td class="w20" >
                <label>RG do Responsável</label>
              </td>
            </tr>
            <tr>
              <td class="brd w70" >${pValue.int_responsavel}</td>
              <td class="brd w20">${pValue.pac_resp_cpf}</td>
              <td class="brd w20">${pValue.pac_resp_rg}</td>
            </tr>
            <tr>
            </tr>
            <tr>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <td style="width:50%;">
                <label>Escolaridade</label>
              </td>
              <td style="width:30%;">
                <label>Profissão/Ocupação</label>
              </td>
              <td style="width:10%;">
                <label>CBO</label>
              </td>
              <td style="width:10%;">
                <label>Situação</label>
              </td>
            </tr>
            <tr>
              <td class="brd">${pValue.escolaridade}</td>
              <td class="brd">${pValue.pes_profissao}</td>
              <td class="brd">&nbsp;</td>
              <td class="brd">&nbsp;</td>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <td style="width:15%;">
                <label>Plano de Saúde</label>
              </td>
              <td style="width:45%;">
                <label>Empresa</label>
              </td>
              <td style="width:15%;">
                <label>Tipo de Plano</label>
              </td>
              <td style="width:25%;">
                <label>Código de Usuário/Beneficiário<label>
              </td>
            </tr>
            <tr>
              <td class="brd">${pValue.pls_nome}</td>
              <td class="brd">&nbsp;</td>
              <td class="brd">&nbsp;</td>
              <td class="brd">&nbsp;</td>
            </tr>
          </table>
        </div>
  </article>
  <article>
    <h3 class="titulo"><p>ADMISSÃO</p></h3>
    <div>
      <table width="100%">
        <tr>
          <td style="width:25%;">
            <label>Data</td>
          <td style="width:25%;">
            <label>Hora</label>
          </td>
          <td style="width:25%;">
            <label>Registro nº</label>
          </td>
          <td style="width:25%;">
            <label>AIH nº</label>
          </td>
        </tr>
        <tr>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
        </tr>
      </table>
    </div>
    <div>
      <table width="100%">
        <tr>
          <td style="width:33%;">
            <label>Unidade Hospitalar</label>
          </td>
          <td style="width:33%;">
            <label>Cidade</label>
          </td>
          <td style="width:33%;">
            <label>AIH nº<label>
          </td>
        </tr>
        <tr>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
        </tr>
      </table>
    </div>
  </article>
  <article>
    <h3 class="titulo"><p>MODO DE SAÍDA</p></h3>
    <div style="padding-top:10px;">
      <table width="100%" class="brd">
        <tr>
          <td>Médica (&nbsp;&nbsp;) </td>
          <td>Óbito (&nbsp;&nbsp;) </td>
          <td>Evasão (&nbsp;&nbsp;) </td>
          <td>Transferência (&nbsp;&nbsp;) </td>
          <td>Outro (&nbsp;&nbsp;)&nbsp;&nbsp;____________________________________________________</td>
        </tr>
      </table>
    </div>
    <div>
      <table width="100%">
        <tr>
          <td style="width:33%;">
            <label>Unidade Hospitalar</label>
          </td>
          <td style="width:33%;">
            <label>Cidade</label>
          </td>
          <td style="width:33%;">
            <label>AIH nº</label>
          </td>
        </tr>
        <tr>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
          <td class="brd">${pValue.aih_num_saida}</td>
        </tr>
      </table>
    </div>
    <div>
      <table width="100%">
        <tr>
          <td>
            <label>Diagnóstico:<label>
          </td>
        </tr>
        <tr>
          <td class="brd">&nbsp;</td>
        </tr>
      </table>
    </div>
    <div>
      <table width="100%">
        <tr>
          <td style="width:60%;">
            <label>Óbito</label>
          </td>
          <td style="width:20%;">
            <label>Nº D.O</label>
          </td>
          <td style="width:20%;">
            <label>Data de Saída</label>
          </td>
        </tr>
        <tr>
          <td class="brd"> (&nbsp;&nbsp;) > 24h (&nbsp;&nbsp;) < 24h Data :____/____/______.  Hora : ___:___:___</td>
          <td class="brd"></td>
          <td class="brd">${pValue.isa_data}</td>
        </tr>
      </table>
    </div>
    <div>
      <table width="100%">
        <tr>
          <td style="width:20%;">
            <label>DNV nº</label>
          </td>
          <td style="width:20%;">
            <label>Certidão nº</label>
          </td>
          <td style="width:10%;">
            <label>Livro</label>
          </td>
          <td style="width:10%;">
            <label>Folha</label>
          </td>
          <td style="width:40%;">
            <label>Cartório</label>
          </td>
        </tr>
        <tr>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
          <td class="brd">&nbsp;</td>
        </tr>
      </table>
    </div>
  </article>
  <article>
    <h3 class="titulo"><p>TERMO DE RESPONSABILIDADE/CONSENTIMENTO</p></h3>
    <table>
      <tr>
        <td class="brd">
            Eu,___________________________________________________, na condição de_________________, RG nº______________________ responsável pelo paciente acima identificado, autorizo a realização dos procedimentos e/ou transfusão sanguínea necessários ao restabelecimento do mesmo, estando ciente de que deverei acompanhar o tratamento e que fui informado dos regulamentos internos do hospital, devendo cooperar com a instituição naquilo que for necessário e possível. <br> _______________________________  ____/____/_____.

        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
    </table>
  </article>
  <article>
    <div>
      <table width="100%">
        <tr>
          <td style="width:50%;">Médico Responsável (assinatura)</td>
          <td style="width:35%;">Carimbo Médico</td>
          <td style="width:15%;">Data</td>
        </tr>
        <tr>
          <td class="brd"></td>
          <td class="brd"></td>
          <td class="brd">____/____/______.</td>
        </tr>
      </table>
    </div>
  </article>
  <article>
    <div>
      <table width="100%">
        <tr>
          <td style="width:50%;">Usuário</td>
        </tr>
        <tr>
          <td class="brd">${pValue.usu_nome}</td>
        </tr>
      </table>
    </div>
  </article>
  </body>
  </html>
`;

  return html;
};

export default ScheduleVoucherHTML;
