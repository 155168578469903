<style src="./RgInputSuscard.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>

      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <TheMask
            v-model="inputValue"
            :placeholder="placeholder"
            :data-id="dataId"
            mask="### #### #### ####"
            type="text"
            :class="{ 'on-error': hasError }"
            @keydown.native="keydown"
            @keyup.native="keyup"
            @blur.native="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";
import RgInputSuscardHelper from "./RgInputSuscardHelper";
import { TheMask } from "vue-the-mask";

export default {
  name: "RgInputSuscard",
  components: {
    RgValidationAlert,
    RgFormBase,
    TheMask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      default: "CNS",
    },
    placeholder: {
      type: String,
      default: "000 0000 0000 0000",
    },
  },
  data() {
    return {
      anotherRules: {
        checkCns: RgInputSuscardHelper.checkDigit,
      },
    };
  },
  computed: {
    hasError() {
      return this.error.length > 0;
    },
  },
  methods: {
    validateBlur() {
      this.validate();
    },
    keydown(ev) {
      this.$emit("keydown", ev);
    },
    keyup(ev) {
      this.$emit("keyup", ev);
    },
  },
};
</script>
