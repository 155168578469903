import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchConsolidatedBillingSheet($period: String, $unitHealthId: Int!) {
    SearchConsolidatedBillingSheet(
      fco_competencia: $period
      fco_id_unidades_saude: $unitHealthId
    ) {
      rows {
        fco_folha
        registros
        existe_espaco
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.SearchConsolidatedBillingSheet.rows;
};
