<template>
  <article class="pharmacy-pharmacies">
    <RgSearch
      ref="rgSearch"
      v-model="mutablePharmacyList"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :item-height="34.5"
      :maxRegister="40"
      result-title="Farmácias"
      :disabled="disableByModal"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
      @submitFromButton="cleanSelectRow"
      @viewClickPagination="cleanSelectRow"
    >
      <div slot="filters" class="pharmacy-pharmacies-filter">
        <div class="filters">
          <RgSelectUnithealth
            v-model="form.unitHealthId"
            :rules="{ required: true }"
          />
          <RgSelectPharmacy
            v-model="form.pharmacyId"
            :disabled="!form.unitHealthId"
            :unitHealthId="Number(form.unitHealthId)"
          />
        </div>
      </div>

      <div v-if="!hasListPharmacy" slot="menu-top" class="top-button">
        <RgAddButton
          id="new-pharmacy"
          large
          label="Novo"
          title="Cadastrar Farmácia"
          :disabled="disableByModal"
          @click="newPharmacy"
        />
      </div>

      <div
        ref="radios"
        slot="search-top"
        class="pharmacy-pharmacies-top-buttons"
      >
        <div slot="legends" class="radios">
          <RgRadioCustom
            id="status"
            v-bind="propsRadio"
            :disabledByModal="disableByModal"
            @input="onInputRadioCustom"
          />
        </div>
      </div>

      <div class="pharmacy-pharmacies-result">
        <SmartTable
          ref="smartTable"
          name="PharmacyPharmacies"
          :columns="COLUMNS"
          :body="mutablePharmacyList"
          :total="Number(total)"
          :initial-columns="6"
          :show-pagination="false"
          :dynamic-height="getSpaceTable"
          index-column="far_id"
          toggleSelected
          @getLine="selectLine"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgAddButton
              id="new-pharmacy"
              small
              title="Cadastrar Farmácia"
              :permission="canRegister"
              :disabled="disableByModal"
              @click="newPharmacy"
            />
            <RgEditButton
              id="edit-pharmacy"
              small
              title="Editar Farmácia"
              :permission="canEdit"
              :disabled="!pharmacySelected || disableByModal"
              @click="editPharmacy"
            />
            <RgLessButton
              id="delete-pharmacy"
              small
              title="Excluir Farmácia"
              :permission="canRemove"
              :disabled="!pharmacySelected || disableByModal"
              @click="openModalRemovePharmacy"
            />
          </div>
        </SmartTable>
      </div>

      <div
        ref="footer"
        slot="menu-bottom"
        class="pharmacy-pharmacies-bottom-buttons"
      ></div>
    </RgSearch>

    <ModalRegisterPharmacy
      id="modal-register-pharmacy"
      :show="modalRegisterPharmacy"
      :pharmacyId="pharmacyId"
      :edition="isEdition"
      @close="closeModalRegisterPharmacy"
      @reSearch="reSearch"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalRemovePharmacy"
      @reSearch="reSearch"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
  </article>
</template>

<script>
import {
  RgSelectUnithealth,
  RgAddButton,
  RgEditButton,
  RgLessButton,
  RgRadioCustom,
  IconDanger,
} from "~tokio/primitive";

import ModalRegisterPharmacy from "$pharmacy/submodules/register/common/components/modal/modal-register-pharmacy/ModalRegisterPharmacy";
import ModalConfirmDeletion from "~tokio/primitive/modal/modal-confirm-deletion/ModalConfirmDeletion";

import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

export default {
  name: "PharmacyPharmacies",
  components: {
    RgSearch,
    SmartTable,
    RgSelectUnithealth,
    RgAddButton,
    RgEditButton,
    RgLessButton,
    RgRadioCustom,
    RgSelectPharmacy,
    IconDanger,
    ModalRegisterPharmacy,
    ModalConfirmDeletion,
  },
  data() {
    return {
      form: {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        pharmacyId: null,
        typePharmacyId: null,
      },
      mutablePharmacyList: [],
      pharmacyData: {},
      total: 0,
      pharmacyId: null,
      isEdition: false,
      modalRegisterPharmacy: false,
      modalRemovePharmacy: false,
    };
  },
  computed: {
    hasListPharmacy() {
      return this.mutablePharmacyList?.length > 0;
    },
    pharmacySelected() {
      return Object.keys(this.pharmacyData).length > 0;
    },
    getSpaceTable() {
      return this.tableHeight;
    },
    disableByModal() {
      return this.modalRegisterPharmacy || this.modalRemovePharmacy;
    },
    propsModalConfirmDeletion() {
      const show = this.modalRemovePharmacy;
      const confirm = () => this.removePharmacy();
      const noReason = true;
      const title = "Excluir a Farmácia Selecionada";
      const message = "A operação não poderá ser desfeita";
      const btnRemoveTitle = "Excluir";
      const bodyText = this.pharmacyData.far_nome;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
      };
    },
    propsRadio() {
      const list = [
        { title: "Central", blue: true, id: 1 },
        { title: "Satélite", blue: true, id: 2 },
      ];
      const value = this.form.typePharmacyId;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    permission() {
      return {
        register: !!this.$Permissions.global.has(
          "farmacia.cadastroFarmacia.incluir",
          this.unitHealthId,
        ),
        edit: !!this.$Permissions.global.has(
          "farmacia.cadastroFarmacia.alterar",
          this.unitHealthId,
        ),
        remove: !!this.$Permissions.global.has(
          "farmacia.cadastroFarmacia.excluir",
          this.unitHealthId,
        ),
        enable: !!this.$Permissions.global.has(
          "farmacia.cadastroFarmacia.habilitar",
          this.unitHealthId,
        ),
        disable: !!this.$Permissions.global.has(
          "farmacia.cadastroFarmacia.inabilitar",
          this.unitHealthId,
        ),
      };
    },
    canRegister() {
      return this.permission.register;
    },
    canEdit() {
      return this.permission.edit;
    },
    canRemove() {
      return this.permission.remove;
    },
    canEnable() {
      return this.permission.enable;
    },
    canDisable() {
      return this.permission.disable;
    },
    canViewTypePresentation() {
      return this.permission.viewTypePresentation;
    },
    canViewProductType() {
      return this.permission.viewProductType;
    },
  },
  watch: {},
  mounted() {},
  created() {
    this.COLUMNS = [
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
      { name: "Farmácia", key: "tpf_nome", align: "left" },
      {
        name: "Nome",
        key: "far_nome",
        align: "left",
      },
      {
        name: "Responsável",
        key: "pes_nome",
        align: "left",
      },
      { name: "Descrição", key: "far_descricao", align: "left" },
    ];
  },
  methods: {
    generateFilter() {
      const variables = {
        arrFormData: {
          far_id_unidades_saude: this.form.unitHealthId,
          far_id_tipos_farmacia: this.form.typePharmacyId,
          far_id: this.form.pharmacyId,
        },
      };

      return variables;
    },
    async searchFilter(pData) {
      return await this.$store.dispatch(
        "Pharmacy/Register/SEARCH_PHARMACY",
        pData,
      );
    },
    onInputRadioCustom(item) {
      if (item) {
        this.form.typePharmacyId = item.id;
        this.reSearch();
      } else {
        this.form.typePharmacyId = null;
        this.reSearch();
      }
    },
    reSearch() {
      this.$refs.rgSearch.submitForm(false, true);
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
      this.pharmacyId = null;
      this.pharmacyData = {};
    },
    changeStatusFilter() {
      this.pharmacyId = null;
      this.pharmacyData = {};
      this.$refs.RgSearch.submitForm(true);
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },
    selectLine(pValue) {
      if (pValue) {
        this.pharmacyData = pValue;
        this.pharmacyId = Number(pValue.far_id);
      } else {
        this.pharmacyData = {};
        this.pharmacyId = null;
      }
    },
    newPharmacy() {
      this.modalRegisterPharmacy = true;
    },
    editPharmacy() {
      this.isEdition = true;
      this.modalRegisterPharmacy = true;
    },
    async removePharmacy() {
      try {
        this.$loader.start();

        const variables = {
          intIdFarmacia: this.pharmacyId || null,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/DELETE_PHARMACY",
          variables,
        );

        this.$toaster.success("Farmácia excluída com sucesso");
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir a Farmácia",
        );
      } finally {
        this.$loader.finish();
      }
    },
    afterSearchFilter() {
      this.getDynamicHeight();
      this.cleanSelectRow();
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgSearch?.$refs.listContent) {
          const searchArea = this.$refs.rgSearch.$refs.listContent.offsetHeight;
          const radios = this.$refs.radios.offsetHeight;
          const footer = this.$refs.footer.offsetHeight;

          this.tableHeight = searchArea - radios - footer;
          if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        }
      });
    },
    openModalRemovePharmacy() {
      this.modalRemovePharmacy = true;
    },
    closeModalRegisterPharmacy() {
      this.isEdition = false;
      this.modalRegisterPharmacy = false;
    },
    closeModalRemovePharmacy() {
      this.modalRemovePharmacy = false;
    },
    cleanSelectRow() {
      this.pharmacyData = {};
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    cleanForm() {
      this.form = {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        pharmacyId: null,
        typePharmacyId: null,
      };
      this.mutablePharmacyList = [];
      this.pharmacyData = {};
      this.total = 0;
      this.pharmacyId = null;
      this.isEdition = false;
      this.modalRegisterPharmacy = false;
      this.modalRemovePharmacy = false;
      this.modalChangeStatusPharmacy = false;
    },
  },
};
</script>
