<style src="./RgTypePatient.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-type-patient">
    <div v-if="pacId && sex" class="patient">
      <IconPatientMan v-if="sex === 1" class="svg" />
      <IconPatientWoman v-else-if="sex === 2" class="svg" />
      <IconPersonUndefined v-else class="svg" />
    </div>

    <div v-else-if="!pacId && sex" class="indigent">
      <IconIndigent v-if="sex === 1" class="svg" />
      <IconIndigentWoman v-else-if="sex === 2" class="svg" />
      <IconPersonUndefined v-else class="svg" />
    </div>

    <div v-else>
      <IconPersonUndefined class="svg" />
    </div>
  </div>
</template>
<script>
import {
  IconPatientMan,
  IconPatientWoman,
  IconIndigent,
  IconIndigentWoman,
  IconPersonUndefined,
} from "~tokio/primitive/icon/symbols";
export default {
  name: "RgTypePatient",
  components: {
    IconPatientMan,
    IconPatientWoman,
    IconIndigent,
    IconIndigentWoman,
    IconPersonUndefined,
  },
  props: {
    pacId: {
      type: Number,
      default: 0,
    },
    sex: {
      type: Number,
      default: null,
    },
  },
};
</script>
