<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchPlaceForSector from "./action/SearchPlaceForSector";

export default {
  name: "RgSelectorPlacebySectorExam",
  extends: RgSelect,
  props: {
    sectorId: {
      type: Number,
    },
    label: {
      type: String,
      default: "Local de Atendimento",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    sectorId() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (this.sectorId) {
        const data = await SearchPlaceForSector({
          intIdSetor: this.sectorId,
        });

        this.valuesData = data.map((item) => {
          return { value: item.data, label: item.lca_nome };
        });
      }
    },
  },
};
</script>
