import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  DisplayExceptions: `Erro ao salvar cadastro de saída`,
};

export default async (context, variables) => {
  const sendData = {
    intIdDestino: variables.idDestiny,
    motivo: variables.reason,
  };
  return AuthLegacyRequest.post(
    "/emergencias/controller-destino/inativar",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
