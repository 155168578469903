import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query BioslabLaboratoryExam(
    $leb_id: Int
    $unitHealth: Int
    $sectorId: Int
    $patient: String
    $patientId: Int
    $recordNumber: Int
    $cns: String
    $bulletinNumber: Int
    $hospitalizationNumber: Int
    $solicitationNumber: String
    $requestEmployeeId: Int
    $examType: [Int]
    $examStatus: [Int]
    $leb_nascimento_paciente: String
    $requestFinalDate: String
    $requestInitialDate: String
    $schedulingInitialDate: String
    $schedulingFinalDate: String
    $orderField: String
    $orderDirection: String
    $limit: Int
    $offset: Int
  ) {
    SearchBioslabLaboratoryExam(
      leb_id: $leb_id
      uns_id: $unitHealth
      set_id: $sectorId
      patient: $patient
      patientId: $patientId
      pac_prontuario_unico: $recordNumber
      cns: $cns
      bol_numero: $bulletinNumber
      int_numero: $hospitalizationNumber
      solicitationNumber: $solicitationNumber
      request_employee_id: $requestEmployeeId
      examType: $examType
      leb_id_laboratoriais_status_pedido: $examStatus
      leb_nascimento_paciente: $leb_nascimento_paciente
      requestInitialDate: $requestInitialDate
      requestFinalDate: $requestFinalDate
      schedulingInitialDate: $schedulingInitialDate
      schedulingFinalDate: $schedulingFinalDate
      orderField: $orderField
      orderDirection: $orderDirection
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        leb_id
        leb_id_profissional_solicitante
        leb_id_pacientes
        leb_data_agendamento
        leb_data_solicitacao
        leb_hora_agendamento
        leb_profissional_solicitante
        leb_sigla_conselho
        leb_numero_conselho
        leb_cpf_profissional
        leb_uf_profissional
        leb_nome_paciente
        leb_sexo_paciente
        leb_nascimento_paciente
        leb_endereco_paciente
        leb_bairro_paciente
        leb_municipio_paciente
        leb_uf_paciente
        leb_cep_paciente
        leb_telefone_paciente
        leb_cor_paciente
        leb_nacionalidade_paciente
        leb_naturalidade_paciente
        leb_peso
        leb_altura
        leb_menstruada
        leb_informacoes_menstruacao
        leb_medicamentos
        leb_cid
        leb_id_laboratoriais_status_pedido
        leb_codigo_solicitacao
        leb_motivo_nao_autorizacao
        leb_id_unidades_saude
        leb_id_setores
        leb_prontuario
        leb_id_internacoes
        leb_id_boletins
        leb_mensagem_erro
        bol_numero
        int_numero
        leb_logradouro_paciente
        leb_complemento_paciente
        leb_cns_paciente
        procedure {
          CO_PROCEDIMENTO
          NO_PROCEDIMENTO
          TP_SEXO
        }
        biosLabInfo {
          lpe_id
          lpe_id_laboratoriais_exames_bioslab
          lpe_codigo_sigtap
          lpe_codigo_exame_bioslab_mne
          lpe_objeto_analise
          lpe_material_biologico
          lpe_identificador_exame_bioslab
          lpe_situacao
          lpe_mensagem_erro
          lpe_ativo
          object_analise_id
          lpe_url_resultado
        }
        biosLabIntegrationsError {
          inb_id
          inb_mensagem_erro
        }
        patient {
          pac_prontuario_unico
          person {
            pes_id
            pes_nome
            cns {
              crs_numero
            }
          }
        }
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchBioslabLaboratoryExam;
};
