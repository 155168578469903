import GetCurrentPeriod from "../action/GetCurrentPeriod";
import UploadLinksSigtapBdsia from "@app/billing/submodules/import-competence/store/actions/upload-links-sigtap-bdsia/UploadLinksSigtapBdsia";

export default {
  LOAD_CURRENT_BILLING_COMPETENCE: async (
    { commit, state, rootGetters },
    to,
  ) => {
    const splittedPath = to.fullPath.split("/");
    const moduleName = splittedPath[1];
    const billingModule = moduleName === "billing";

    if (billingModule) {
      UploadLinksSigtapBdsia();

      const hasNotModuleName =
        !moduleName || !rootGetters["Login/GET_ROUTE_MODULE_MAP"][moduleName];

      if (hasNotModuleName) {
        return true;
      }

      const currentCompetenceLoaded = !!state.periodDate;

      if (currentCompetenceLoaded) {
        return true;
      }

      await GetCurrentPeriod();
    }
  },
};
