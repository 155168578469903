<template>
  <div v-if="show" class="modal-patient-record-linked-basic-attention">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">
          O cadastro do paciente já possui vínculo com uma Ficha da Atenção
          Básica
        </div>
      </div>

      <div slot="body" class="body">
        <div class="content">
          <span class="information">
            Ao alterar o vínculo será removida a Ficha da Atenção Básica atual
            para que uma nova seja selecionada no lugar.
          </span>
        </div>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel" medium @click="cancel" />
        <MediumButton
          id="save"
          medium
          label="Alterar"
          title="Alterar"
          @click="change"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, MediumButton } from "~tokio/primitive";

export default {
  name: "ModalPatientRecordLinkedBasicAttention",
  components: {
    RgBaseModal,
    RgCancelButton,
    MediumButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },

  methods: {
    change() {
      this.close();
      this.$emit("change");
    },
    cancel() {
      this.close();
      this.$emit("cancel");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
