<style src="./RgDropdownTypeBpa.scss" lang="scss" scoped></style>
<template>
  <div
    v-clickOutside="clickOutside"
    class="dropdown-button"
    :disabled="disabled"
    @click="activeButton"
  >
    <div class="container-dropdown">
      <div class="icon"><IconFilter /></div>
      <div class="label">
        <div class="baseCriticize">
          <div v-if="type === 'all'" class="allCriticize">
            <div class="with"></div>
            <div class="without"></div>
          </div>
          <div
            v-else-if="type"
            :class="type === 'without' ? 'withoutCriticize' : 'withCriticize'"
          ></div>
          {{ label }}
          <i class="el-icon-caret-bottom sub-icon"></i>
        </div>
      </div>
    </div>
    <div
      :class="{
        'show-up': showUp,
        'show-left': showLeft,
        'dropdown-open': isActive,
      }"
      class="dropdown"
    >
      <span
        v-for="option in actionOptions"
        :key="generateIdentifier(option)"
        :data-id="generateIdentifier(option)"
        :class="{ 'disable unselect': option.disable }"
        :disabled="option.disable"
        class="item"
        @click="handleClick(option)"
      >
        <div class="baseCriticize">
          <div v-if="option.type === 'all'" class="allCriticize">
            <div class="with"></div>
            <div class="without"></div>
          </div>
          <div
            v-else
            :class="
              option.type === 'without' ? 'withoutCriticize' : 'withCriticize'
            "
          ></div>
          {{ option.label }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
// import { IconEmpty } from "~tokio/primitive/";
import { IconFilter } from "~tokio/primitive/";

export default {
  name: "RgDropdownTypeBpa",
  components: {
    IconFilter,
    // IconEmpty,
  },
  props: {
    /**
     * @type {{label: string, action: Function}[]} actionOptions
     */
    itemId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "#1e88a9",
    },
    notBackgroundWhenDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showUp: {
      type: Boolean,
      default: false,
    },
    showLeft: {
      type: Boolean,
      default: false,
    },
    rotateIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      label: "Filtrar Resultados",
      type: null,
      statusWaiting: false,
      isActive: false,
      actionOptions: [
        {
          label: "Somente Críticas",
          type: "with",
        },
        {
          label: "Somente sem Críticas",
          type: "without",
        },
        {
          label: "Todos",
          type: "all",
        },
      ],
    };
  },
  methods: {
    generateIdentifier(pOption) {
      return !this.itemId || this.itemId === 0
        ? pOption.label
        : `${pOption.label}-${this.itemId}`;
    },
    /**
     * @param {any} pItem
     * @param {{label: string, action: Function}[]} pOption
     */
    handleClick(pOption) {
      this.label = pOption.label;
      this.type = pOption.type;
      this.$emit("on-criticize", pOption.type);
    },
    setWaiting() {
      this.statusWaiting = true;
    },
    setStartOrFinish() {
      this.statusWaiting = false;
    },
    activeButton() {
      this.isActive = !this.isActive;
    },
    clickOutside() {
      this.isActive = false;
    },
    loadValue(type) {
      const option = this.actionOptions.find(
        (element) => element.type === type,
      );
      this.label = option.label;
      this.type = option.type;
    },
    clearValue() {
      this.label = "Filtrar Resultados";
      this.type = null;
      this.statusWaiting = false;
      this.isActive = false;
    },
  },
};
</script>
