import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query TimeProcedureSigtap($position: Int) {
    TimeProcedureSigtap(position: $position) {
      time
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.TimeProcedureSigtap;
  } catch (err) {
    return Promise.reject(err);
  }
};
