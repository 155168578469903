var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smart-table",class:{ disable: _vm.disabled }},[(_vm.showTableMenu)?_c('div',{ref:"tableMenu",staticClass:"table-menu"},[(_vm.showSelectAllPage && _vm.multSelect && _vm.body.length > 0)?_c('div',{staticClass:"mult-selectors"},[(!_vm.selectAll)?_c('RgButton',{staticClass:"check",attrs:{"id":"check-btn","type":"button","disabled":_vm.disabled},on:{"click":_vm.selectAllList}},[_c('IconSquare')],1):_vm._e(),(_vm.selectAll)?_c('RgButton',{staticClass:"check",attrs:{"id":"uncheck-btn","type":"button","disabled":_vm.disabled},on:{"click":_vm.unselectAllList}},[_c('IconSquareChecked')],1):_vm._e(),_c('RgButton',{staticClass:"check",attrs:{"id":"reverse-list","type":"button","disabled":_vm.body.length === 0 || _vm.disabled},on:{"click":_vm.reverseListSelection}},[_c('IconArrowSquare')],1),_c('span',{staticClass:"text"},[_vm._v(" Linhas selecionadas: "+_vm._s(_vm.multSelectedRow.length)+" ")])],1):_vm._e(),_vm._t("legends"),_vm._t("top-buttons"),(!_vm.removeBtnColumns)?_c('div',{directives:[{name:"clickOutside",rawName:"v-clickOutside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"dropdown-button box-scroll",class:{ 'dropdown-button-fixed': _vm.fixedDropdown }},[_c('div',{staticClass:"button",on:{"click":_vm.toggle}},[_c('span',{staticClass:"text unselect"},[_vm._v("Colunas")]),_c('IconArrow',{staticClass:"svg"})],1),(_vm.active === true)?_c('div',{staticClass:"dropdown",class:{
          'dropdown-fixed': _vm.fixedDropdown,
          'dropdown-absolute': !_vm.fixedDropdown,
        }},[_c('span',{staticClass:"label"},[_vm._v("Colunas Exibidas")]),_vm._l((_vm.activeHeader),function(col,index){return _c('label',{key:col.key,staticClass:"item actives unselect",class:{ 'disable unselect': _vm.hasOnlyOneActiveHeader },attrs:{"disabled":_vm.hasOnlyOneActiveHeader}},[_vm._v(" "+_vm._s(col.name)+" "),_c('input',{staticClass:"checkbox",attrs:{"id":index,"type":"checkbox"},domProps:{"checked":col.active},on:{"change":function($event){return _vm.toggleStatus(col, col.key)}}})])}),_c('span',{staticClass:"label"},[_vm._v("Colunas Não Exibidas")]),_vm._l((_vm.inactiveHeader),function(col,index){return _c('label',{key:col.key,staticClass:"item inactives unselect"},[_vm._v(" "+_vm._s(col.name)+" "),_c('input',{staticClass:"checkbox",attrs:{"id":index,"type":"checkbox"},domProps:{"checked":col.active},on:{"change":function($event){return _vm.toggleStatus(col, col.key)}}})])})],2):_vm._e()]):_vm._e()],2):_vm._e(),_c('div',{ref:"scrollArea",staticClass:"table-dropdown",style:(_vm.actDynamicHeight)},[_c('div',{ref:"itemHeight",staticClass:"table"},[_c('thead',{staticClass:"theader"},[_c('tr',{staticClass:"title"},_vm._l((_vm.columnTable),function(col,idx){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(col.active),expression:"col.active"}],key:idx,staticClass:"text",style:({ textAlign: col.align || col.alignTitle })},[_vm._v(" "+_vm._s(col.name)+" ")])}),0)]),_c('tbody',{ref:"tbody",staticClass:"tbody"},_vm._l((_vm.dataTable),function(item,index){return _c('tr',{key:_vm.getRowKey(item, index),staticClass:"body",class:{
            active: _vm.isSelectedRow(item, index),
            disable: _vm.isDisabledRow(item),
            opaque: _vm.isOpaque ? _vm.isOpaque.check(item) : false,
            color: _vm.colorLine && !_vm.circleIndicator ? _vm.colorLine.get(item) : null,
          },style:({
            'background-color':
              _vm.colorLine && !_vm.circleIndicator ? _vm.colorLine.get(item) : null,
            color: item.color,
          }),attrs:{"disabled":_vm.isDisabledRow(item)},on:{"click":function($event){return _vm.getItem(item, index)}}},_vm._l((_vm.columnTable),function(col,idx){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(col.active),expression:"col.active"}],key:idx,staticClass:"data",class:{ datalimit: _vm.minHeight },style:({ textAlign: col.align })},[_c('div',{class:{
                'has-check':
                  ((_vm.circleIndicator || _vm.hasCheck) &&
                    (_vm.isSelectedAndCheckRow(index, idx) ||
                      (idx === _vm.firstIndexSelected && _vm.showCircleColor))) ||
                  col.statusColor,
              }},[(_vm.hasLines)?_c('span',{staticClass:"marks"},[(_vm.isSelectedAndCheckRow(index, idx))?_c('span',{staticClass:"check"},[_c('IconCheck',{staticClass:"svg"})],1):_vm._e(),(
                    ((idx === _vm.firstIndexSelected && _vm.showColorInFirstColumn) ||
                      col.statusColor) &&
                    _vm.showCircleColor
                  )?_c('ColorIndication',{staticClass:"circle-color",attrs:{"value":_vm.colorLine.get(item)}}):_vm._e()],1):_vm._e(),(col.seeMore)?_c('span',[_vm._v(" "+_vm._s(_vm.seeMoreItem(item[col.key]))+" ")]):_vm._e(),(col.html)?_c('span',{domProps:{"innerHTML":_vm._s(item[col.key])}}):_vm._e(),(!col.concat && !col.seeMore && !col.html)?_c('span',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.circleIndicator ? item[col.key] || "" : item[col.key] || "-")+" ")]):_vm._e(),(col.concat)?_c('div',_vm._l((_vm.separateItens(
                    item[col.key],
                    col.concat,
                  )),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item)+" ")])])}),0):_vm._e()])])}),0)}),0)])]),_c('div',[_vm._t("bottom-buttons")],2),(_vm.showPagination)?_c('RgPagination',{ref:"paginator",attrs:{"data-length":_vm.total,"item-per-page":_vm.itemPerPage,"max-register":_vm.maxRegister,"disabled":_vm.disabled,"from-modal":_vm.fromModal,"isFromSmartTable":""},on:{"pagination":_vm.setPagination}}):_vm._e(),(!_vm.showPagination)?_vm._t("default"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }