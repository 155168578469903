<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchOccupationsAttendantsBySector from "./action/SearchOccupationsAttendantsBySector";

export default {
  name: "RgSelectOcupationAttendantsBySector",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Ocupação",
    },
    sectorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      occupation: [],
    };
  },
  watch: {
    sectorId(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.doSearch();
      }
    },
    occupation: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.occupation;
    },
    async doSearch() {
      const result = await SearchOccupationsAttendantsBySector({
        intIdSetor: this.sectorId,
      });

      this.occupation = result.map((item) => {
        return {
          value: item.data,
          label: item.label,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
