<template>
  <Modulebox
    ref="modulebox"
    title="Configurações do Módulo de Cadastro"
    class="patient-registration-settings"
  >
    <FormBase title="Campos do formulário" class="form-base">
      <section class="form-base-content">
        <div class="setting">
          <strong>
            Utilizar o endereço da Unidade de Saúde no cadastro de novos
            pacientes
          </strong>
          <RgToggleButton
            v-model="settings.addressHealthUnit"
            v-bind="propsToggleBtn"
          />
        </div>

        <hr />

        <div class="setting">
          <strong>
            Preenchimento obrigatório de CNS no cadastro de novos pacientes
          </strong>
          <RgToggleButton
            v-model="settings.mandatoryCnsRegister"
            v-bind="propsToggleBtn"
          />
        </div>

        <div class="setting">
          <strong :class="{ disable: !settings.mandatoryCnsRegister }">
            Preenchimento obrigatório de CNS ao editar o cadastro de pacientes
          </strong>
          <RgToggleButton
            v-model="settings.mandatoryCnsEdition"
            v-bind="propsToggleBtn"
            :disabled="!settings.mandatoryCnsRegister"
          />
        </div>

        <hr />

        <div class="setting">
          <strong>
            Preenchimento obrigatório de CPF no cadastro de novos pacientes
          </strong>
          <RgToggleButton
            v-model="settings.mandatoryCpfRegister"
            v-bind="propsToggleBtn"
          />
        </div>

        <div class="setting">
          <strong :class="{ disable: !settings.mandatoryCpfRegister }">
            Preenchimento obrigatório de CPF ao editar o cadastro de pacientes
          </strong>
          <RgToggleButton
            v-model="settings.mandatoryCpfEdition"
            v-bind="propsToggleBtn"
            :disabled="!settings.mandatoryCpfRegister"
          />
        </div>

        <hr />

        <div class="setting">
          <strong>
            Preenchimento obrigatório de telefone no cadastro/edição de
            pacientes
          </strong>
          <RgToggleButton
            v-model="settings.mandatoryTelehpone"
            v-bind="propsToggleBtn"
          />
        </div>
      </section>
    </FormBase>

    <FormBase title="Campos da Busca do Paciente" class="form-base">
      <section class="form-base-content">
        <div class="setting">
          <strong>
            Alternar entre a busca por Prontuário da Unidade e Prontuário Único
          </strong>
          <RgRadioCustom
            id="validations"
            v-bind="propsRadioCustom"
            @input="onInputRadioCustom"
          />
        </div>
      </section>
    </FormBase>

    <section slot="footer" class="footer">
      <MediumButton
        label="Cancelar"
        :background-color="'#FF7F2A'"
        @click="goBack"
      />
      <MediumButton label="Salvar" @click="save" />
    </section>
  </Modulebox>
</template>

<script type="text/javascript">
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { RgToggleButton, RgRadioCustom, MediumButton } from "~tokio/primitive";
import { mapGetters } from "vuex";

const PREFERENCE_FIELD_ID = {
  addressHealthUnit: 132,
  mandatoryCnsRegister: 153,
  mandatoryCnsEdition: 172,
  mandatoryCpfRegister: 154,
  mandatoryCpfEdition: 173,
  mandatoryTelehpone: 163,
  mandatoryRecordNumber: 177,
};

export default {
  name: "PatientRegistrationSettings",
  components: {
    Modulebox,
    FormBase,
    RgToggleButton,
    RgRadioCustom,
    MediumButton,
  },

  data() {
    return {
      settings: {
        addressHealthUnit: false,
        mandatoryCnsRegister: false,
        mandatoryCnsEdition: false,
        mandatoryCpfRegister: false,
        mandatoryCpfEdition: false,
        mandatoryTelehpone: false,
        mandatoryRecordNumber: false,
      },
      initialAddressHealthUnit: false,
      initialMandatoryCnsRegister: false,
      initialMandatoryCnsEdition: false,
      initialMandatoryCpfRegister: false,
      initialMandatoryCpfEdition: false,
      initialMandatoryTelehpone: false,
      initialMandatoryRecordNumber: false,
    };
  },

  computed: {
    ...mapGetters({
      addressUnitHealthPreference: "Login/GET_ADDRESS_UNIT_HEALTH_PREFERENCE",
      documentPreferences: "Login/GET_DOCUMENT_PREFERENCES",
      phoneRequiredPreference: "Login/GET_PHONE_REQUIRED",
      recordNumberPreference: "Login/GET_RECORD_NUMBER_PREFERENCES",
      userId: "Login/GET_USER_ID",
    }),

    propsToggleBtn() {
      return {
        truthyText: "ATIVO",
        falsyText: "INATIVO",
        externalLabel: true,
        valueSync: true,
      };
    },

    propsRadioCustom() {
      const list = [
        { title: "Prontuário da Unidade", blue: true, id: 1 },
        { title: "Prontuário Único", blue: true, id: 0 },
      ];
      const value = Number(this.settings.mandatoryRecordNumber);
      const uniqueKey = "id";

      return { list, value, uniqueKey };
    },

    addressHealthUnitPreference() {
      return !!this.addressUnitHealthPreference;
    },

    mandatoryCpfRegisterPreference() {
      return !!this.documentPreferences.requiredCPF;
    },

    mandatoryCpfEditionPreference() {
      return !!this.documentPreferences.requiredCPFUpdate;
    },

    mandatoryCnsRegisterPreference() {
      return !!this.documentPreferences.requiredCNS;
    },

    mandatoryCnsEditionPreference() {
      return !!this.documentPreferences.requiredCNSUpdate;
    },

    mandatoryTelehponePreference() {
      return !!this.phoneRequiredPreference;
    },

    mandatoryRecordNumberPreference() {
      return !!this.recordNumberPreference;
    },
  },

  watch: {
    "settings.mandatoryCnsRegister"(pValue) {
      if (!pValue) {
        this.settings.mandatoryCnsEdition = false;
      }
    },
    "settings.mandatoryCpfRegister"(pValue) {
      if (!pValue) {
        this.settings.mandatoryCpfEdition = false;
      }
    },
  },

  mounted() {
    this.fillForm();
  },

  methods: {
    fillForm() {
      this.settings.addressHealthUnit = this.addressHealthUnitPreference;
      this.settings.mandatoryCpfRegister = this.mandatoryCpfRegisterPreference;
      this.settings.mandatoryCpfEdition = this.mandatoryCpfEditionPreference;
      this.settings.mandatoryCnsRegister = this.mandatoryCnsRegisterPreference;
      this.settings.mandatoryCnsEdition = this.mandatoryCnsEditionPreference;
      this.settings.mandatoryTelehpone = this.mandatoryTelehponePreference;
      this.settings.mandatoryRecordNumber = this.mandatoryRecordNumberPreference;
      this.UpdateInitialPreferences();
    },

    async save() {
      try {
        const variables = this.GetPreferences();

        if (variables.length === 0) {
          this.$toaster.warning("Nenhuma configuração foi alterada");
          return;
        }
        const response = await this.$store.dispatch(
          "Configuration/SAVE_PREFERENCES",
          {
            preferences: variables,
          },
        );

        const hasSuccess = response && response.success;

        if (hasSuccess) {
          this.UpdateInitialPreferences();
          this.UpdateStorePreferences();
          this.$toaster.success("Operação realizada com sucesso");
        } else {
          this.$toaster.error(response.message);
        }
      } catch (pErr) {
        this.$toaster.error(pErr.message || pErr);
      } finally {
        this.$loader.finish();
      }
    },

    GetPreferences() {
      const preferences = [];
      const clientId = this.$store.state.Login.user.usu_id_clientes;
      const userId = this.userId;

      if (this.settings.addressHealthUnit !== this.initialAddressHealthUnit) {
        preferences.push({
          pre_id_campos: PREFERENCE_FIELD_ID.addressHealthUnit,
          pre_valor: this.settings.addressHealthUnit ? "1" : "0",
          pre_id_clientes: clientId,
          pre_id_usuarios: userId,
        });
      }

      if (
        this.settings.mandatoryCnsRegister !== this.initialMandatoryCnsRegister
      ) {
        preferences.push({
          pre_id_campos: PREFERENCE_FIELD_ID.mandatoryCnsRegister,
          pre_valor: this.settings.mandatoryCnsRegister ? "1" : "0",
          pre_id_clientes: clientId,
          pre_id_usuarios: userId,
        });
      }

      if (
        this.settings.mandatoryCnsEdition !== this.initialMandatoryCnsEdition
      ) {
        preferences.push({
          pre_id_campos: PREFERENCE_FIELD_ID.mandatoryCnsEdition,
          pre_valor: this.settings.mandatoryCnsEdition ? "1" : "0",
          pre_id_clientes: clientId,
          pre_id_usuarios: userId,
        });
      }

      if (
        this.settings.mandatoryCpfRegister !== this.initialMandatoryCpfRegister
      ) {
        preferences.push({
          pre_id_campos: PREFERENCE_FIELD_ID.mandatoryCpfRegister,
          pre_valor: this.settings.mandatoryCpfRegister ? "1" : "0",
          pre_id_clientes: clientId,
          pre_id_usuarios: userId,
        });
      }

      if (
        this.settings.mandatoryCpfEdition !== this.initialMandatoryCpfEdition
      ) {
        preferences.push({
          pre_id_campos: PREFERENCE_FIELD_ID.mandatoryCpfEdition,
          pre_valor: this.settings.mandatoryCpfEdition ? "1" : "0",
          pre_id_clientes: clientId,
          pre_id_usuarios: userId,
        });
      }

      if (this.settings.mandatoryTelehpone !== this.initialMandatoryTelehpone) {
        preferences.push({
          pre_id_campos: PREFERENCE_FIELD_ID.mandatoryTelehpone,
          pre_valor: this.settings.mandatoryTelehpone ? "1" : "0",
          pre_id_clientes: clientId,
          pre_id_usuarios: userId,
        });
      }

      if (
        this.settings.mandatoryRecordNumber !==
        this.initialMandatoryRecordNumber
      ) {
        preferences.push({
          pre_id_campos: PREFERENCE_FIELD_ID.mandatoryRecordNumber,
          pre_valor: this.settings.mandatoryRecordNumber ? "1" : "0",
          pre_id_clientes: clientId,
          pre_id_usuarios: userId,
        });
      }

      return preferences;
    },

    UpdateInitialPreferences() {
      this.initialAddressHealthUnit = this.settings.addressHealthUnit;
      this.initialMandatoryCpfRegister = this.settings.mandatoryCpfRegister;
      this.initialMandatoryCpfEdition = this.settings.mandatoryCpfEdition;
      this.initialMandatoryCnsRegister = this.settings.mandatoryCnsRegister;
      this.initialMandatoryCnsEdition = this.settings.mandatoryCnsEdition;
      this.initialMandatoryTelehpone = this.settings.mandatoryTelehpone;
      this.initialMandatoryRecordNumber = this.settings.mandatoryRecordNumber;
    },

    UpdateStorePreferences() {
      this.$store.commit(
        "Login/SET_ADDRESS_UNIT_HEALTH",
        this.settings.addressHealthUnit,
      );
      this.$store.commit(
        "Login/SET_CNS_REQUIRED",
        this.settings.mandatoryCnsRegister,
      );
      this.$store.commit(
        "Login/SET_CNS_UPDATE_REQUIRED",
        this.settings.mandatoryCnsEdition,
      );
      this.$store.commit(
        "Login/SET_CPF_REQUIRED",
        this.settings.mandatoryCpfRegister,
      );
      this.$store.commit(
        "Login/SET_CPF_UPDATE_REQUIRED",
        this.settings.mandatoryCpfEdition,
      );
      this.$store.commit(
        "Login/SET_PHONE_REQUIRED",
        this.settings.mandatoryTelehpone,
      );
      this.$store.commit(
        "Login/SET_RECORD_NUMBER_REQUIRED",
        this.settings.mandatoryRecordNumber,
      );
    },

    onInputRadioCustom(pItem) {
      if (pItem) {
        this.settings.mandatoryRecordNumber = pItem.id;
      } else {
        this.settings.mandatoryRecordNumber = null;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
