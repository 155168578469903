<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchConsolidatedBillingSheet from "./action/SearchConsolidatedBillingSheet";

export default {
  name: "RgSelectSheet",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Folha",
    },
    period: {
      type: String,
      default: null,
    },
    editValue: {
      type: String,
      default: null,
    },
    onlyAvailable: {
      type: Boolean,
      default: false,
    },
    needInitialValue: {
      type: Boolean,
      default: true,
    },
    editModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheets: [],
    };
  },
  watch: {
    sheets: function (val) {
      this.updateOptions();
    },
    period: async function (val) {
      this.updateOptions();
      await this.getSheets();
    },
  },

  methods: {
    getData() {
      return this.sheets;
    },
    async getSheets() {
      if (!this.period) return Promise.resolve([]);
      this.sheets = [];
      const unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      const variables = {
        period: this.period,
        unitHealthId: unitHealthId,
      };

      this.sheets = Array.from(await SearchConsolidatedBillingSheet(variables));

      if (this.sheets.length > 0) {
        this.sheets = this.sheets.map((item, index) => {
          return {
            ...item,
            label:
              item.fco_folha.toString().length === 1
                ? "00" + item.fco_folha
                : item.fco_folha.toString().length > 2
                ? item.fco_folha
                : "0" + item.fco_folha,
            value: item.fco_folha.toString(),
          };
        });
        if (this.onlyAvailable) {
          const sheets = this.sheets;
          this.sheets = this.sheets.filter(
            (item) => item.existe_espaco === "1",
          );

          if (this.sheets.length === 0) {
            this.sheets.push({
              existe_espaco: "1",
              fco_folha: sheets[sheets.length - 1].fco_folha + 1,
              label:
                (sheets[sheets.length - 1].fco_folha + 1).toString().length ===
                1
                  ? "00" + (sheets[sheets.length - 1].fco_folha + 1)
                  : (sheets[sheets.length - 1].fco_folha + 1).toString()
                      .length > 2
                  ? sheets[sheets.length - 1].fco_folha + 1
                  : "0" + (sheets[sheets.length - 1].fco_folha + 1),

              registros: 0,
              value: (sheets[sheets.length - 1].fco_folha + 1).toString(),
            });
            if (this.needInitialValue) {
              this.mValue = this.sheets[this.sheets.length - 1];
            }
            this.$emit("lastSheet", this.sheets.length);
            this.$emit("newSheet", this.sheets[0]);
          } else {
            this.$emit(
              "lastSheet",
              this.sheets[this.sheets.length - 1].fco_folha,
            );
            if (this.needInitialValue) {
              this.mValue = this.sheets[0];
            }
          }
        } else {
          this.$emit(
            "lastSheet",
            this.sheets[this.sheets.length - 1].fco_folha,
          );

          if (this.needInitialValue) {
            this.mValue = this.sheets[this.sheets.length - 1];
          }
          if (this.editModal) {
            this.mValue = this.sheets[Number(this.editValue) - 1];
          }
        }
      } else {
        if (this.needInitialValue) this.mValue = null;
        this.sheets = [
          {
            existe_espaco: "1",
            fco_folha: 1,
            label: "001",
            registros: 0,
            value: "1",
          },
        ];
      }
    },
    addNewSheet() {
      this.sheets = this.sheets.filter((element) => element.value !== -1);
      if (this.createdNewSheet === -1) {
        this.sheets.push({
          value: this.sheets.length + 1,
          label: `00${this.sheets.length + 1}`,
        });
      }
      this.selectedNewSheet(this.sheets.length);
      if (this.newSheet) {
        this.sheets.push({ value: -1, label: "Adicionar Folha" });
      }
    },
  },
};
</script>
