<template lang="html">
  <ModuleBox title="Definir Período para Faturamento" class="define-period">
    <main class="define-period-body">
      <FormBase
        title="Dados da Competência Atual"
        :class="{ disable: disabledByModal }"
      >
        <div class="form">
          <div class="row info-competence">
            <span>
              Competência SIGTAP: <b>{{ competenceSigtap || "-" }} </b>
            </span>
            <span>
              Competência BDSIA: <b>{{ competenceBdsia || "-" }}</b>
            </span>
            <span>
              Arquivo SIGTAP: <b> {{ fileSigtap || "-" }} </b>
            </span>
            <span>
              Arquivo BDSIA: <b>{{ fileBdsia || "-" }} </b>
            </span>
          </div>
          <hr class="hr" />
          <LargeButton
            id="import"
            label="Importar Nova Competência"
            :backgroundColor="'#1E88A9'"
            class="btn-import-competence"
            :disabled="disabledByModal"
            @click="openModalConfirmDefault(true)"
          >
            <IconArrowDownload slot="icon" />
          </LargeButton>
        </div>
      </FormBase>
      <RgValidatorForm ref="validator">
        <FormBase
          title="Seleção da Competência"
          :class="{ disable: disabledByModal }"
        >
          <div class="tooltips">
            <Tooltip class="tooltip-competence">
              <IconLightBulbHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Para realizar o faturamento, é necessário selecionar a
                    competência vigente para o mês atual. Serão considerados os
                    dados do mês vigente, mais os 3 meses anteriores para
                    faturamento retroativo
                  </span>
                </div>
              </div>
            </Tooltip>
            <Tooltip class="tooltip-competence">
              <IconExclamationHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    É possível configurar uma competência fora do prazo de
                    faturamento ( 4 meses, mês atual mais 3 meses retroativos)
                    para consulta
                  </span>
                </div>
              </div>
            </Tooltip>
          </div>
          <div class="form">
            <div class="row">
              <RgDatePicker
                v-model="competenceDate"
                type="month"
                format="MMMM yyyy"
                :rules="{ required: true }"
                label="Competência"
                :picker-options="pickerOptions"
                :disabled="disabledByModal"
                :class="{ disable: disabledByModal }"
              />
              <LargeButton
                id="import"
                label="Definir Competência"
                :backgroundColor="'#1E88A9'"
                class="btn-select-competence"
                :disabled="!competenceDate || disabledByModal"
                @click="openModalConfirmDefault(false)"
              >
                <IconCheck slot="icon" />
              </LargeButton>
            </div>
          </div>
        </FormBase>
      </RgValidatorForm>
    </main>

    <div slot="footer" class="period-footer">
      <MediumButton
        class="back-btn"
        label="Voltar"
        :disabled="disabledByModal"
        @click="goBack"
      />
    </div>

    <ModalConfirmDefault
      id="modal-confirm-default"
      v-bind="propsModalConfirmDefault"
      @getYes="confirmDefault"
      @getOut="closeModalConfirmDefault"
      @close="closeModalConfirmDefault"
    />
  </ModuleBox>
</template>

<script>
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ChangeCurrentPeriod from "../actions/ChangeCurrentPeriod";
import CheckPeriod from "../actions/CheckPeriod";
import GetCurrentPeriod from "$billing/bdsia/action/GetCurrentPeriod";
import {
  LargeButton,
  ModalConfirmDefault,
  RgDatePicker,
  IconExclamationHelper,
  IconLightBulbHelper,
  Tooltip,
  IconArrowDownload,
  IconCheck,
  RgValidatorForm,
  MediumButton,
} from "~tokio/primitive";
import moment from "moment";

export default {
  name: "DefinePeriod",
  components: {
    RgValidatorForm,
    ModuleBox,
    FormBase,
    LargeButton,
    IconArrowDownload,
    ModalConfirmDefault,
    IconCheck,
    RgDatePicker,
    IconExclamationHelper,
    IconLightBulbHelper,
    Tooltip,
    MediumButton,
  },
  data: () => {
    return {
      pickerOptions: {
        disabledDate() {
          return true;
        },
      },
      competenceDate: "",
      year: "",
      month: "",
      showModal: false,
      cmp: null,
      modalConfirmDefault: false,
      isImport: false,
      competenceSigtap: "-",
      competenceBdsia: "-",
      fileSigtap: "-",
      fileBdsia: "-",
      validCompetenceInCalendar: false,
    };
  },
  computed: {
    propsModalConfirmDefault() {
      const show = this.modalConfirmDefault;
      const cmpYear = this.cmp?.period ? this.cmp.period.substring(0, 4) : "";
      const cmpMonth = this.cmp?.period ? this.cmp.period.substring(4, 6) : "";
      const title = this.isImport
        ? "Importar Competências e Procedimentos (SIGTAP e BDSIA)"
        : "Definir nova competência para o módulo de “Faturamento”";
      const subtitle = this.isImport
        ? "Você será redirecionado para Importação de Competências e Procedimentos. Deseja Prosseguir?"
        : "Deseja alterar a configuração atual para outro período de faturamento?";
      const message = this.isImport
        ? ""
        : `Configuração atual: ${cmpMonth} ${cmpYear} <br/> Nova configuração: ${this.month} ${this.year}`;
      const yesLabel = this.isImport ? "Prosseguir" : "Trocar Competência";
      const buttonsRight = true;

      return {
        show,
        title,
        subtitle,
        yesLabel,
        message,
        buttonsRight,
      };
    },
    periodDate() {
      return `${this.year}${this.month}`;
    },
    unitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    disabledByModal() {
      return this.modalConfirmDefault;
    },
  },
  watch: {
    competenceDate(pValue) {
      if (pValue) {
        this.month = ((new Date(pValue).getMonth() + 1) / 10)
          .toFixed(1)
          .toString()
          .replace(".", "");
        this.year = new Date(pValue).getFullYear().toString();
      }
    },
  },
  async mounted() {
    try {
      this.$loader.start();
      const resultCompetence = await GetCurrentPeriod();

      if (resultCompetence && resultCompetence.data === false) {
        this.competenceDate = "";
        this.$toaster.info(
          "Confira se tabelas e competência selecionadas são do mesmo período.",
          "A competência atual é incompatível com o sistema",
        );
        this.$store.commit("Billing/BDSia/CLEAN_MODULE_BILLING_BDSIA");
        this.validCompetenceInCalendar = true;
      }

      const lastPeriod = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      const lastUnifiedTableVersion = this.$store.getters[
        "Billing/BDSia/GET_UNIFIED_TABLE_VERSION"
      ];

      this.cmp = {
        period: lastPeriod || resultCompetence.competence || null,
        unifiedTableVersion: lastUnifiedTableVersion || null,
      };

      if (this.cmp) {
        this.year = this.cmp.period ? this.cmp.period.substring(0, 4) : null;
        this.month = this.cmp.period ? this.cmp.period.substring(4, 6) : null;
        this.competenceDate =
          this.year && this.month
            ? new Date(this.year + "/" + this.month)
            : null;
      }

      await this.loadAvailableCompetence();
      if (this.year !== "" && this.month !== "") {
        await this.loadLastCompetence();
      }
    } catch (error) {
      this.$toaster.error(error, "Erro ao buscar competências");
    } finally {
      this.$loader.finish();
    }
  },
  methods: {
    async loadLastCompetence() {
      const resultLastCompetence = await this.$store.dispatch(
        "Billing/ImportBdsiaSigtap/SEARCH_HISTORY_IMPORT",
        {
          competence: this.periodDate,
        },
      );
      if (this.validCompetenceInCalendar === false) {
        this.competenceSigtap = resultLastCompetence
          .SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_competencia_sigtap
          ? moment(
              resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]
                ?.hsb_competencia_sigtap,
              "YYYYMM",
            ).format("MM/YYYY")
          : "-";
        this.competenceBdsia = resultLastCompetence
          .SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_competencia_bdsia
          ? moment(
              resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]
                ?.hsb_competencia_bdsia,
              "YYYYMM",
            ).format("MM/YYYY")
          : "-";
        this.fileSigtap =
          resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_arquivo_sigtap;
        this.fileBdsia =
          resultLastCompetence.SearchHistoryImportSigtapBdsia?.rows[0]?.hsb_arquivo_bdsia;
      } else {
        this.competenceSigtap = "-";
        this.competenceBdsia = "-";
        this.fileSigtap = "-";
        this.fileBdsia = "-";
        this.validCompetenceInCalendar = false;
      }
    },
    async loadAvailableCompetence() {
      const competences = await this.$store.dispatch(
        "Billing/ImportBdsiaSigtap/SEARCH_AVAILABLE_COMPETENCE",
        { isRetroactive: true, isFutureCompetence: true },
      );

      const competencesAvailable = [];

      for (const bdsia of competences.bdsiaRows) {
        const position = competences.sigtapRows.findIndex(
          (element) => element.competence === bdsia.competence,
        );
        if (position > -1) {
          competencesAvailable.push(
            moment(bdsia.competence, "YYYYMM").format("YYYY-MM"),
          );
        }
      }

      this.pickerOptions = {
        disabledDate(time) {
          return !competencesAvailable.includes(
            moment(time.getTime()).format("YYYY-MM"),
          );
        },
      };

      const validCompetenceInCalendar = competencesAvailable.includes(
        moment(this.competenceDate).format("YYYY-MM"),
      );

      if (!validCompetenceInCalendar) {
        this.$store.commit("Billing/BDSia/CLEAN_MODULE_BILLING_BDSIA");
        this.competenceDate = "";
      }
    },
    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    closeModalConfirmDefault() {
      this.modalConfirmDefault = false;
      this.isImport = false;
    },
    openModalConfirmDefault(isImport = false) {
      this.isImport = isImport;
      this.modalConfirmDefault = true;
    },
    confirmDefault() {
      if (this.isImport) {
        this.$router.push({
          name: "billing.import-competence",
        });
      } else {
        this.period();
      }
    },
    confirmPeriodChange() {
      const validMounth = this.month && !isNaN(this.month);
      const validYear = this.year && !isNaN(this.year);
      if (!validMounth || !validYear) {
        this.$toaster.warning("Verifique os campos");
        this.$refs.btnSubmit.fail();
        return false;
      }
      this.showModal = true;
    },

    async changePeriod() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();
        const checkPeriod = await CheckPeriod(
          this.periodDate,
          this.unitHealthId,
        );
        const newPeriod = `${this.year}${this.month}`;

        this.cmp.status =
          newPeriod === checkPeriod.data.period ||
          newPeriod === moment().format("YYYYMM") ||
          newPeriod === moment().add(1, "month").format("YYYYMM");

        if (this.cmp.status) {
          this.$store.commit(
            "Billing/BDSia/SET_UNIFIED_TABLE_VERSION",
            checkPeriod.data.period,
          );

          await ChangeCurrentPeriod(this.periodDate, this.unitHealthId);
          await GetCurrentPeriod();
          this.closeModalConfirmDefault();
          this.$toaster.success(
            `Agora o módulo de “Faturamento” exibe dados da competência “${this.month} ${this.year}”`,
            "Competência definida com sucesso",
          );

          this.cmp.period = newPeriod;
          this.cmp.unifiedTableVersion = checkPeriod.data.period;
          this.loadLastCompetence();
        } else {
          this.$toaster.info(
            "Importe as tabelas SIGTAP e BDSIA compatíveis com o período selecionado.",
            "O período é incompatível com o Sistema",
          );
        }
      } catch (pErr) {
        if (pErr.includes("401")) {
          this.$toast.error(
            "Sua sessão possívelmente expirou ou você não tem permissão para executar essa ação.",
            "Por favor, realize o login novamente.",
          );
        } else {
          this.$toaster.error(pErr.message, "Erro ao definir competência");
        }
      } finally {
        this.showModal = false;
        this.$loader.finish();
      }
    },

    async period() {
      this.changePeriod();
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
