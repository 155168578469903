import { render, staticRenderFns } from "./RgHourglassButton.vue?vue&type=template&id=4108ff95&scoped=true&"
import script from "./RgHourglassButton.vue?vue&type=script&lang=js&"
export * from "./RgHourglassButton.vue?vue&type=script&lang=js&"
import style0 from "./RgHourglassButton.scss?vue&type=style&index=0&id=4108ff95&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4108ff95",
  null
  
)

export default component.exports