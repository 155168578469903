<style src="./RgTypeaheadMixin.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset
    :class="{ activated: hasFocus }"
    tabindex="-1"
    class="rg-input--component"
    @focus="focusMe"
    @blur="blurMe"
  >
    <RgFormBase :label="label" :required="isRequired" :title="title">
      <div slot="right-label">
        <div v-show="error.length > 0" class="rg-input--alert-over-positioning">
          <RgValidationAlert :alert="error" class="rg-input--icon" />
        </div>
      </div>

      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <div class="rg-input--search-icon">
            <IconSearch class="icon-search" />
          </div>
          {{ selected }}
          <div class="rg-input--side-action">
            <div v-show="showWait" class="rg-input--btn-calendar">
              <div class="rg-input-wait" />
            </div>

            <!-- <div
              v-show="error.length > 0"
              class="rg-input--alert-over-positioning"
            >
              <RgValidationAlert :alert="error" class="rg-input--icon" />
            </div> -->
          </div>
          <!-- <div slot="right-label">
            <RgValidationAlert :alert="error" class="rg-input--alert" />
          </div> -->

          <div class="">
            <input
              ref="input"
              v-model="inputValue"
              :disabled="disable"
              :placeholder="placeholder"
              :class="{
                'rg-input--typeahead-input--selected': selectedValidValue,
                'on-error': error.length > 0,
              }"
              :tabindex="tabIndex"
              type="text"
              autocomplete="off"
              class="rg-input--typeahead-input"
              @keyup="search"
              @keydown.up.down.13="navigate"
              @focus="focusMe"
              @blur="blurMe"
            />

            <div
              v-show="isSearching"
              ref="searchBox"
              class="rg-input--searchbox-result"
            >
              <ul class="rg-input--seachbox-list">
                <div
                  v-for="(item, i) in items"
                  :key="i"
                  :class="{
                    'rg-input--search-item': true,
                    hover: i === navigation,
                  }"
                  @click="select(item, i)"
                >
                  <slot :item="item" name="item">
                    <li v-html="item.text" />
                  </slot>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import { DebounceDirective } from "../../input/directive/debounce/Debounce";
import RgBaseSelectionMixin from "./RgBaseSelectionMixin";
import { IconSearch } from "~tokio/primitive/icon";

let debounceTime = null;

export default {
  name: "RgTypeaheadMixin",
  components: {
    RgValidationAlert,
    RgFormBase,
    IconSearch,
  },
  directives: {
    debounceDirective: DebounceDirective,
  },
  mixins: [RgValidatorMixin, RgBaseSelectionMixin],
  props: {
    debounce: {
      type: String,
      default: "250",
    },
    min: {
      type: String,
      default: "0",
    },
    selected: {
      type: Object,
      default: function () {
        return null;
      },
    },
    tabIndex: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    invalidMessage: {
      type: String,
      default: "Item selecionado não é válido",
    },
  },
  data() {
    return {
      navigation: 0,
      items: [],
      inputValue: "",
      hasFocus: false,
      focusIntention: false,
      showWait: false,
      selectedValidValue: false,
      title: "",
      anotherRules: {
        validItem: (pValue, pError) => {
          if (this.disable) {
            return true;
          }
          if (
            !this.inputValue ||
            (this.inputValue.length === 0 && !this.selectedItem)
          ) {
            return true;
          }
          let valid = false;
          if (
            this.selectedItem &&
            this.selectedItem.text === this.inputValue &&
            this.selectedItem.value
          ) {
            valid = true;
          }
          if (!valid) {
            pError.push(this.invalidMessage);
          }
          return valid;
        },
      },
    };
  },
  computed: {
    selectedValueStyle() {
      return this.selectedValidValue
        ? "rg-input--typeahead-input--selected"
        : "";
    },
    isSearching() {
      return this.items.length > 0 && this.hasFocus;
    },
    validValue() {
      return this.inputValue;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    selected: function () {
      this.selectedItem = this.selected;
    },
    data: function (pVal) {
      this.items = [];
    },
    inputValue: function (pVal) {
      this.error = [];
      if (this.inputValue && this.inputValue.length <= 0) {
        this.items = [];
      }
    },
    value: function (pVal, pOldVal) {
      if (this.value) {
        this.valueChanged(pVal, pOldVal);
      } else if (this.value !== null) {
        this.error = [];
        this.invalidValue(pVal, pOldVal);
      }
    },
  },
  methods: {
    navigate(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      if (ev.keyCode === 38) {
        this.navigateUp();
      }

      if (ev.keyCode === 40) {
        this.navigateDown();
      }

      if (ev.keyCode === 13) {
        this.select(this.items[this.navigation]);
      }
      return false;
    },
    navigateUp() {
      if (this.navigation <= 0) {
        this.navigation = this.items.length - 1;
        return;
      }
      this.navigation--;
      this.scrollUp();
    },
    scrollUp() {
      const el = this.$refs.searchBox.querySelectorAll("ul  li")[
        this.navigation
      ];
      this.$refs.searchBox.scrollTop =
        el.offsetTop - this.$refs.searchBox.offsetTop - 50;
    },
    navigateDown() {
      if (this.navigation >= this.items.length - 1) {
        this.navigation = 0;
        return;
      }
      this.navigation++;
      this.scrollDown();
    },
    scrollDown() {
      const el = this.$refs.searchBox.querySelectorAll("ul li")[
        this.navigation
      ];
      this.$refs.searchBox.scrollTop =
        el.offsetTop - this.$refs.searchBox.offsetTop;
    },
    select(item) {
      if (item) {
        this.selectedValidValue = true;
        this.selectedItem = item;
        this.inputValue = item.text;
        this.validate();
        this.$emit("select", this.selectedItem);
        this.$emit("input", this.selectedItem.value);
        this.items = [];
      }
    },
    search(ev) {
      if (!this.inputValue || this.inputValue.length === 0) {
        this.selectedValidValue = false;
        this.$emit("input", null);
        this.$emit("select", null);
        this.items = [];
      } else if (this.isElementFocused() && this.shouldSearch(ev)) {
        this.selectedValidValue = false;
        this.$emit("input", null);
        this.$emit("select", null);
        this.showWait = true;
        // sanitize the search
        this.selectedItem = null;
        this.items = [];
        clearTimeout(debounceTime);
        debounceTime = setTimeout(() => {
          this.performSearch();
        }, this.debounce);
      } else {
        clearTimeout(debounceTime);
      }
    },
    isElementFocused() {
      return this.$refs.input === document.activeElement;
    },
    shouldSearch(pEv) {
      const hasMoreThenMin = this.inputValue.length >= this.min;
      const isAValidKey = pEv.key.length === 1;
      const isNotCtrl = pEv.ctrlKey === false;
      const isNotShift = pEv.shiftKey === false;
      const isNotAlt = pEv.altKey === false;
      const isBackSpace = pEv.keyCode === 8;
      if (
        hasMoreThenMin &&
        ((isAValidKey && isNotCtrl && isNotShift && isNotAlt) || isBackSpace)
      ) {
        return true;
      }
      return false;
    },
    // By default just emit a event, this method was created to be overwritten for their childs
    // performSearch () {
    //   this.$emit('search', this.inputValue)
    // },
    blurMe() {
      this.focusIntention = false;
      setTimeout(() => {
        if (!this.focusIntention) {
          this.validate();
          this.hasFocus = false;
          this.showWait = false;
        }
      }, 50);
    },
    focusMe() {
      this.focusIntention = true;
      this.hasFocus = true;
      this.$refs.input.focus();
    },
    setSelectedItem(text, value) {
      this.selectedItem = {
        value: value,
        text: text,
      };
    },
    valueChanged(pVal, pOldVal) {
      // to be overwritten
    },
    invalidValue(pVal, pOldVal) {
      this.selectedItem = null;
      this.inputValue = "";
    },
  },
};
</script>
