<template>
  <div class="changelog-container">
    <h3 class="title title-box center">Atualizações</h3>

    <div class="changelog-list small-scroll">
      <div
        v-for="(item, index) in versionEsus"
        v-show="versionEsus && versionEsus.length > 0"
        :key="index"
        class="list"
        @click="aboutVersion(item)"
      >
        <p class="title">Novidades da Versão {{ item.ave_nome_versao }}</p>
        <div class="subtitle-version">
          <IconCheck class="svg" />
          <p class="text">
            Atualização
            {{ $utils.date.BrazilianDateFormat(item.ave_data_versao) }}
          </p>
        </div>
      </div>

      <div v-show="!versionEsus" class="empty">
        <IconEmpty class="svg" />

        Não foram encontradas atualizações
      </div>
    </div>
    <IconMedicHome class="medic" />
  </div>
</template>

<script>
import { IconCheck, IconMedicHome, IconEmpty } from "~tokio/primitive";

export default {
  name: "ChangelogContainer",
  components: {
    IconCheck,
    IconMedicHome,
    IconEmpty,
  },
  data() {
    return {
      versionEsus: null,
    };
  },
  computed: {},
  mounted() {
    this.getVersionData();
  },
  methods: {
    async getVersionData() {
      try {
        this.versionEsus = await this.$store.dispatch("Home/SEARCH_CHANGELOG", {
          ave_nome_versao: process.env.VUE_APP_VERSION,
        });
      } catch (e) {
        console.log(e);
      }
    },

    aboutVersion(pVersion) {
      this.$router.push({ name: "aboutsoftware", params: pVersion });
      localStorage.setItem(
        "exec-refresh-page-refreshLogin",
        JSON.stringify({ exec: "active", refresh: "active" }),
      );
    },
  },
};
</script>
