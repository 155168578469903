<template>
  <div class="exam-patients-scheduled-queue-report">
    <section class="grid">
      <div class="filter-title">
        <span class="title"> Dados de Agendamento (Destino) </span>
      </div>

      <RgSelectUnithealth
        id="unit-destiny"
        v-model="form.unitHealthDestiny"
        label="Unidade de Saúde"
        default-text="Todas"
        :permission="permissionScreen"
        @change="selectingUnitHealthDestiny"
      />

      <RgSelectSector
        id="sector-destiny"
        v-model="form.sectorDestiny"
        label="Setor"
        default-text="Todos"
        :disabled="!form.unitHealthDestiny"
        :unit-health="form.unitHealthDestiny"
        @change="selectingSectorDestiny"
      />

      <RgSelectPlaces
        id="place-destiny"
        v-model="form.placeDestiny"
        default-text="Todos"
        :disabled="!form.sectorDestiny"
        :sector="form.sectorDestiny"
        @change="selectingPlaceDestiny"
      />

      <RgSelectProceduresFilter
        id="procedures-destiny"
        ref="procedure"
        v-model="form.procedureDestiny"
        :disabled="!form.sectorDestiny"
        :class="{ disable: !form.sectorDestiny }"
        :variable="{ intIdSetor: form.sectorDestiny }"
        label="Procedimento"
        placeholder="Digite o nome do procedimento"
        @change="selectingProcedureDestiny"
      />

      <RgSuggestEmployee
        id="employee-destiny"
        ref="employee"
        v-model="employeeDestiny"
        :maxlength="100"
        :sector="form.sectorDestiny"
        :vus-responsavel="false"
        :disabled="!form.sectorDestiny"
        :class="{ disable: !form.sectorDestiny }"
        label="Profissional"
        placeholder="Digite o nome do profissional"
        sectorRequired
        @selected="selectingEmployeeDestiny"
      />

      <RgSuggestUser
        id="user-destiny"
        ref="userDestiny"
        v-model="userDestiny"
        label="Usuário"
        @selected="selectingUserDestiny"
      />

      <div class="period-date">
        <RgInputDate
          id="initial-date-destiny"
          v-model="form.scheduleDateStart"
          :rules="{ fn: verifyScheduleDate }"
          label="Data Inicial"
        />
        <RgInputDate
          id="final-date-destiny"
          v-model="form.scheduleDateEnd"
          label="Data Final"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Dados do Paciente </span>
      </div>

      <RgInput
        id="patient"
        ref="patient"
        v-model="form.patientName"
        :disabled="!!form.patientId"
        :class="{ disable: !!form.patientId }"
        placeholder="Digite o nome do paciente"
        label="Paciente"
      />

      <div class="selectinput">
        <RgSuggestSmartPerson
          id="documentation-patient"
          ref="patient"
          v-model="documentPatient"
          :disabled="disabledDocument"
          :with-patient-module="['exame2']"
          :enabled-patient="false"
          filterOnlyDocument
          @selected="selectingPatient"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Dados da Regulação (Origem) </span>
      </div>

      <RgSelectUnithealth
        id="unit-origin"
        v-model="form.unitHealthOrigin"
        label="Unidade de Saúde"
        :default-text="'Todas'"
        :permission="'consulta.filaConsulta.exibir'"
        @change="selectingUnitHealthOrigin"
      />

      <RgSuggestUser
        id="user-origin"
        ref="userOrigin"
        v-model="userOrigin"
        label="Usuário"
        @selected="selectingUserOrigin"
      />

      <div class="period-date">
        <RgInputDate
          id="initial-date-origin"
          v-model="form.insertDateStart"
          :rules="{ fn: verifyRegulationDate }"
          label="Data Inicial"
        />
        <RgInputDate
          id="final-date-origin"
          v-model="form.insertDateEnd"
          label="Data Final"
        />
      </div>

      <div class="filter-title">
        <span class="title"> Dados da Solicitação </span>
      </div>

      <RgSelectUnithealth
        id="unit-requesting"
        v-model="form.unitHealthRequest"
        :default-text="'Todas'"
        :permission="'consulta.filaConsulta.exibir'"
        @change="selectingUnitHealthRequest"
      />

      <RgSuggestEmployee
        id="employee-request"
        ref="employeeRequest"
        v-model="employeeRequest"
        :maxlength="100"
        :vus-responsavel="false"
        label="Profissional Solicitante"
        placeholder="Digite o nome do profissional"
        @selected="selectingRequestEmployee"
      />

      <RgSelectPriorityQueue
        id="priority"
        v-model="form.priority"
        :module-id="moduleId"
        label="Prioridade"
        default-text="Todas"
        @change="selectingPriority"
      />

      <RgRadioCustom
        id="with-print"
        ref="rgRadioCustom"
        :list="RADIO_DATA"
        :value="form.withPrint"
        uniqueKey="id"
        class="radio-custom"
        @input="onInputRadioCustom"
      />
    </section>
  </div>
</template>

<script>
import moment from "moment";

import {
  RgInput,
  RgInputDate,
  RgSelectUnithealth,
  RgSelectSector,
  RgSelectPlaces,
  RgSelectPriorityQueue,
} from "~tokio/primitive";

import RgSuggestEmployee from "~tokio/primitive/suggest/rg-suggest-employee/RgSuggestEmployee";
import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson";
import RgSuggestUser from "~tokio/primitive/suggest/rg-suggest-user/RgSuggestUser.vue";

import { RgRadioCustom } from "~tokio/primitive/radio";
import { RgSelectProceduresFilter } from "$exam/submodules/schedule/component/";
import { mapGetters } from "vuex";

const FORM_FILTER = {
  unitHealthDestiny: null,
  unitHealthDestinyName: null,
  sectorDestiny: null,
  sectorDestinyName: null,
  placeDestiny: null,
  placeDestinyName: null,
  procedureDestiny: null,
  procedureDestinyName: null,
  employeeDestiny: null,
  employeeDestinyName: null,
  userDestiny: null,
  userDestinyName: null,
  scheduleDateStart: null,
  scheduleDateEnd: null,
  patientId: null,
  unitHealthOrigin: null,
  userOrigin: null,
  insertDateStart: null,
  insertDateEnd: null,
  unitHealthRequest: null,
  employeeRequest: null,
  priority: null,
  patientName: null,
  unitHealthOriginName: null,
  unitHealthRequestName: null,
  requestingProfessionalName: null,
  priorityName: null,
  withPrint: 2,
  columnsToPrint: null,
};

export default {
  name: "ExamPatientsScheduledQueueReport",
  components: {
    RgInput,
    RgInputDate,
    RgSelectPriorityQueue,
    RgSelectUnithealth,
    RgSelectProceduresFilter,
    RgSelectSector,
    RgSelectPlaces,
    RgSuggestSmartPerson,
    RgSuggestEmployee,
    RgSuggestUser,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Pacientes Agendados pela Fila",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      disabledDocument: false,
      employeeDestiny: null,
      userDestiny: null,
      documentPatient: null,
      userOrigin: null,
      employeeRequest: null,
    };
  },
  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
    }),
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }"
      );
    },
    getNameSmartTable() {
      return "ExamPatientsScheduledQueueReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    moduleId() {
      return this.$utils.app.ModulesId.EXAM;
    },
    permissionScreen() {
      return "exame2.buscaPacientesAgendadosFilaExames.exibir";
    },
  },
  watch: {
    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disabledDocument = true;
      } else {
        this.disabledDocument = false;
      }
    },
  },
  created() {
    this.RADIO_DATA = [
      { title: "Impresso", blue: true, id: 1 },
      { title: "Não Impresso", blue: true, id: 0 },
    ];

    this.COLUMN = [
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Data de Agendamento", key: "DATA_AGENDAMENTO" },
      {
        name: "Unidade de Saúde Destino",
        key: "UNIDADE_SAUDE_DESTINO",
        align: "left",
      },
      { name: "Setor Destino", key: "SETOR_DESTINO", align: "left" },
      {
        name: "Local de Atendimento Destino",
        key: "LOCAL_ATENDIMENTO_DESTINO",
        align: "left",
      },
      {
        name: "Procedimento",
        key: "PROCEDIMENTO",
        align: "left",
      },
      { name: "Profissional", key: "PROFISSIONAL", align: "left" },
      { name: "Usuário Destino", key: "USUARIO_DESTINO", align: "left" },
      { name: "Data de Entrada", key: "DATA_ENTRADA" },
      {
        name: "Unidade de Saúde Origem",
        key: "UNIDADE_SAUDE_ORIGEM",
        align: "left",
      },
      { name: "Prioridade", key: "PRIORIDADE" },
      { name: "Usuário Origem", key: "USUARIO_ORIGEM", align: "left" },
      {
        name: "Unidade de Saúde Solicitante",
        key: "UNIDADE_SAUDE_SOLICITANTE",
        align: "left",
      },
      {
        name: "Profissional Solicitante",
        key: "PROFISSIONAL_SOLICITANTE",
        align: "left",
      },
      { name: "Impresso", key: "IMPRESSO" },
    ];
  },
  methods: {
    buildFilter() {
      const SUBMODULE_ID = 164;

      const payload = {
        unitHealthDestiny: this.form.unitHealthDestiny,
        unitHealthDestinyName: this.form.unitHealthDestinyName,
        sectorDestiny: this.form.sectorDestiny,
        sectorDestinyName: this.form.sectorDestinyName,
        placeDestiny: this.form.placeDestiny,
        placeDestinyName: this.form.placeDestinyName,
        procedureDestiny: this.form.procedureDestiny,
        procedureDestinyName: this.form.procedureDestinyName,
        employeeDestiny: this.form.employeeDestiny,
        employeeDestinyName: this.form.employeeDestinyName,
        userDestiny: this.form.userDestiny,
        userDestinyName: this.form.userDestinyName,
        scheduleDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.scheduleDateStart,
        ),
        scheduleDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.scheduleDateEnd,
        ),
        patientId: this.form.patientId,
        patientName: this.form.patientName,
        unitHealthOrigin: this.form.unitHealthOrigin,
        unitHealthOriginName: this.form.unitHealthOriginName,
        userOrigin: this.form.userOrigin,
        userOriginName: this.form.userOriginName,
        insertDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.insertDateStart,
        ),
        insertDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.insertDateEnd,
        ),
        unitHealthRequest: this.form.unitHealthRequest,
        unitHealthRequestName: this.form.unitHealthRequestName,
        employeeRequest: this.form.employeeRequest,
        employeeRequestName: this.form.employeeRequestName,
        priority: this.form.priority,
        priorityName: this.form.priorityName,
        withPrint: this.form.withPrint,
        columnsToPrint: this.columnsToPrint,
        smd_id: SUBMODULE_ID,
        loggedUserId: this.userLoginId,
      };

      return payload;
    },

    async doSearch(pData) {
      try {
        this.$loader.start("Carregando...");

        return await this.$store.dispatch(
          "Exam/Report/GET_EXAM_PATIENTS_SCHEDULED_QUEUE_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish();
      }
    },

    selectingUnitHealthDestiny(pValue) {
      if (pValue) {
        this.form.unitHealthDestinyName = pValue.label;
      } else {
        this.form.unitHealthDestinyName = null;
      }
    },

    selectingSectorDestiny(pValue) {
      if (pValue) {
        this.form.sectorDestinyName = pValue.label;
      } else {
        this.form.sectorDestinyName = null;
      }
    },

    selectingPlaceDestiny(pValue) {
      if (pValue) {
        this.form.placeDestinyName = pValue.label;
      } else {
        this.form.placeDestinyName = null;
      }
    },

    selectingProcedureDestiny(pValue) {
      if (pValue) {
        this.form.procedureDestinyName = pValue.label;
      } else {
        this.form.procedureDestinyName = null;
      }
    },

    selectingEmployeeDestiny(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.employeeDestiny =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;

        this.form.employeeDestinyName = pes_nome;
      } else {
        this.form.employeeDestiny = null;
        this.form.employeeDestinyName = null;
      }
    },

    selectingUserDestiny(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.userDestiny = usu_id;
        this.form.userDestinyName = usu_nome;
      } else {
        this.form.userDestiny = 0;
        this.form.userDestinyName = null;
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source && pValue.source.patient) {
        const { pac_id } = pValue.source.patient;
        this.form.patientId = pac_id;
      } else {
        this.form.patientId = null;
      }
    },

    selectingUnitHealthOrigin(pValue) {
      if (pValue) {
        this.form.unitHealthOriginName = pValue.label;
      } else {
        this.form.unitHealthOriginName = null;
      }
    },

    selectingUserOrigin(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.userOrigin = usu_id;
        this.form.userOriginName = usu_nome;
      } else {
        this.form.userOrigin = null;
        this.form.userOriginName = null;
      }
    },

    selectingUnitHealthRequest(pValue) {
      if (pValue) {
        this.form.unitHealthRequestName = pValue.label;
      } else {
        this.form.unitHealthRequestName = null;
      }
    },

    selectingRequestEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.employeeRequest =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;
        this.form.employeeRequestName = pes_nome;
      } else {
        this.form.employeeRequest = null;
        this.form.employeeRequestName = null;
      }
    },

    selectingPriority(pValue) {
      if (pValue) {
        this.form.priorityName = pValue.label;
      } else {
        this.form.priorityName = null;
      }
    },

    onInputRadioCustom(item) {
      if (item) {
        this.form.withPrint = item.id;
      } else {
        this.form.withPrint = 2;
      }
    },

    verifyScheduleDate(pValue, pErrors) {
      const initialDate = moment(this.form.scheduleDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.scheduleDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyRegulationDate(pValue, pErrors) {
      const initialDate = moment(this.form.insertDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.insertDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.disabledDocument = false;
      this.employeeDestiny = null;
      this.userDestiny = null;
      this.documentPatient = null;
      this.userOrigin = null;
      this.employeeRequest = null;
      if (this.$refs.rgRadioCustom) {
        this.$refs.rgRadioCustom.forceUpdate();
      }
    },
  },
};
</script>
