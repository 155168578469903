import { toast } from "~tokio/primitive";
import { pick } from "lodash";
import moment from "moment";
import { BrazilianDateToDatabase } from "~utils/date";

export default {
  name: "SurgeryFormMixin",
  data() {
    return {
      form: {
        pac_id: 0,
        cci_id_tipos_anestesias: 0,
        cci_id_raios_x: 0,
        cci_id_internacoes_clinicas: 0,
        cci_id_funcionarios: 0,
        cci_cti: 0,
        cci_reserva_sangue: 0,
        cci_id_tipos_procedimentos: 1,
        cci_id_tipos_cirurgias: 1,
        cci_data_inicio: "",
        cci_hora_inicio: "",
        cci_duracao: "",
        cci_observacao: "",
        cci_id_setores: 0,
        cci_id_finalidades: null,
        cci_retorno_infeccao: 0,
        procedures: [],
      },
      cleanedForm: {},
      showAlert: false,
      showAlertSuspend: false,
      alertMessage: "",
      showConditioned: false,
      typeOfSurgery: "rotine",
      surgeryConflictDetail: {},
      tabSelected: 0,
    };
  },
  computed: {
    selectedItem() {
      return this.$store.state.SurgeryCenter.Surgery.selectedItem;
    },
    isSurgeryCanceled() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos === 3
      );
    },
    isSurgeryRequested() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos === 1
      );
    },
    isSurgeryScheduled() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos === 2
      );
    },
    isSurgeryPerformed() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos === 4
      );
    },
    isSurgerySuspended() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos === 5
      );
    },
    surgeryState() {
      throw new Error(
        "you need to specify the form surgery state, if it is shcedule or request",
      );
    },
    hideButtonForm() {
      throw new Error(
        "you need to specify when the form main button should be hidden",
      );
    },
    isUpdating() {
      return (
        this.selectedItem &&
        this.selectedItem.cci_id_status_controles_cirurgicos ===
          this.surgeryState
      );
    },
    hasCancelPermission() {
      return this.$Permissions.global.has("centro_cirurgico.cirurgia.cancelar");
    },
    hasSuspendPermission() {
      return this.$Permissions.global.has(
        "centro_cirurgico.cirurgia.suspender",
      );
    },
    hasReschedulePermission() {
      return this.$Permissions.global.has(
        "centro_cirurgico.cirurgia.reagendar",
      );
    },
  },
  watch: {
    selectedItem: function (pVal) {
      if (pVal) {
        const preForm = { ...pVal };
        preForm.cci_id_setores = pVal.cci_id_setores || false;
        preForm.cci_data_inicio = moment(pVal.cci_data_inicio).format(
          "DD/MM/YYYY",
        );
        this.form = preForm;
      } else {
        this.cleanForm();
      }
    },
  },
  methods: {
    chooseTab(pChooseTab) {
      this.tabSelected = pChooseTab;
    },
    checkSurgeryConflict() {
      const infoStatus = this.formatHourAndPlace();
      if (infoStatus) {
        this.$store
          .dispatch("SurgeryCenter/Surgery/CHECK_SURGERY_CONFLICT", infoStatus)
          .then((conflictResponse) => {
            if (conflictResponse.data === true) return;
            const sameSurgery =
              this.form.cci_id === conflictResponse.data.surgeries.cci_id;
            if (conflictResponse.data !== true && !sameSurgery) {
              this.surgeryConflictDetail = conflictResponse.data.surgeries;
              this.showConditioned = true;
            }
          })
          .catch((pErr) => {
            this.toast.error(
              "Você não possui permissão para agendar nesse <b>centro cirúrgico</b>",
            );
          });
      }
    },
    formatHourAndPlace() {
      if (!this.validateHourAndPlace()) {
        return false;
      }
      const infoReturned = {};
      const attendancePlace = Array.isArray(this.form.cci_id_locais_atendimento)
        ? this.form.cci_id_locais_atendimento[0].value
        : this.form.cci_id_locais_atendimento;
      const sector = Array.isArray(this.form.cci_id_setores)
        ? this.form.cci_id_setores[0].value
        : this.form.cci_id_setores;
      infoReturned.cci_data_inicio = this.form.cci_data_inicio;
      infoReturned.cci_hora_inicio = this.form.cci_hora_inicio;
      infoReturned.cci_duracao = this.form.cci_duracao;
      infoReturned.lca_id_locais_atendimento = attendancePlace;
      infoReturned.cci_id_setores = sector;
      return infoReturned;
    },
    validateHourAndPlace() {
      const validDataInicio =
        this.form.cci_data_inicio !== "Invalid date" &&
        this.form.cci_data_inicio !== "";
      const validHoraInicio = this.form.cci_hora_inicio !== "";
      const validDuracao = this.form.cci_duracao !== "";
      const validIdSetores = this.form.cci_id_setores !== false;
      const validLocaisAtendimento =
        this.form.lca_id_locais_atendimento !== false;

      return (
        validDataInicio &&
        validHoraInicio &&
        validDuracao &&
        validIdSetores &&
        validLocaisAtendimento
      );
    },
    cleanForm() {
      this.form = { ...this.cleanedForm };
      if (this.$refs.form) {
        this.$refs.form.cleanValidate();
      }
    },
    cleanSelection() {
      this.$store.commit("SurgeryCenter/Surgery/CLEAN_SELECTION");
      this.cleanForm();
    },
    confirmModal() {
      this.showAlert = true;
      this.alertMessage =
        "Você esta cancelando um pedido da lista, ao realizar esta ação você estará mudando o estado do pedido para CANCELADO, deseja continuar?";
    },
    confirmModalSuspend() {
      this.showAlertSuspend = true;
      this.alertMessage =
        "Você esta suspendendo um pedido da lista, ao realizar esta ação você estará mudando o estado do pedido para SUSPENSO, deseja continuar?";
    },
    dismissModal() {
      this.showAlert = false;
      this.cleanSelection();
    },
    userDenied() {
      this.showAlert = false;
    },
    dismissModalSuspend() {
      this.showAlertSuspend = false;
      this.cleanSelection();
    },
    userDeniedSuspend() {
      this.showAlertSuspend = false;
    },
    formatForm(pForm) {
      const formatedForm = {
        pac_id: pForm.pac_id,
        cci_id: pForm.cci_id,
        cci_id_pacientes_cirurgicos: pForm.cci_id_pacientes_cirurgicos,
        cci_id_funcionarios: pForm.cci_id_funcionarios,
        cci_id_setores: pForm.cci_id_setores,
        cci_id_locais_atendimento: pForm.cci_id_locais_atendimento,
        cci_id_internacoes_clinicas: pForm.cci_id_internacoes_clinicas,
        cci_id_raios_x: pForm.cci_id_raios_x,
        cci_id_tipos_anestesias: pForm.cci_id_tipos_anestesias,
        cci_id_tipos_cirurgias: pForm.cci_id_tipos_cirurgias,
        cci_id_tipos_procedimentos: pForm.cci_id_tipos_procedimentos,
        cci_data_inicio: BrazilianDateToDatabase(pForm.cci_data_inicio),
        cci_hora_inicio: pForm.cci_hora_inicio,
        cci_duracao: pForm.cci_duracao,
        cci_reserva_sangue: pForm.cci_reserva_sangue,
        cci_cti: pForm.cci_cti,
        cci_observacao: pForm.cci_observacao,
        procedures: pForm.procedures,
        surgery_relationship: pForm.surgery_relationship,
        cci_id_finalidades: pForm.cci_id_finalidades,
        cci_retorno_infeccao: pForm.cci_retorno_infeccao,
      };
      return formatedForm;
    },
    chooseConditionedType(pConditionedType) {
      switch (pConditionedType.status) {
        case "CONDITIONED":
          this.form.cci_id_tipos_cirurgias = 2;
          break;
        case "ROTINE":
          this.form.cci_id_tipos_cirurgias = 1;
          break;
        default:
          this.form.cci_id_tipos_cirurgias = 1;
      }
      this.showConditioned = false;
    },
    filterObjectsKeys(pObjs, pKeys) {
      return pObjs.map((pObj) => pick(pObj, pKeys));
    },
  },
  created() {
    this.toast = toast;
  },
  mounted() {
    this.cleanedForm = { ...this.form };
  },
  destroyed() {
    this.cleanSelection();
  },
};
