import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async () => {
  return AuthLegacyRequest.post("farmacia/controller-motivo-descarte/listar")
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
