import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import moment from "moment";

const query = gql`
  query {
    SearchAllCompetence {
      sigtapRows {
        competence
      }
      bdsiaRows {
        competence
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });

  const existCompetence =
    data.SearchAllCompetence?.bdsiaRows?.length > 0 &&
    data.SearchAllCompetence?.sigtapRows?.length > 0;

  if (existCompetence) {
    if (variables?.isRetroactive) {
      const bdsiaRows = data.SearchAllCompetence.bdsiaRows.slice(0, 4);
      const positionSigtap = data.SearchAllCompetence.sigtapRows.findIndex(
        (element) => {
          return element.competence === bdsiaRows[0].competence;
        },
      );

      const sigtapRows = data.SearchAllCompetence.sigtapRows.slice(
        positionSigtap,
        positionSigtap + 4,
      );

      const validationCompetence =
        moment().format("DD") < "15"
          ? moment(bdsiaRows[0]?.competence, "YYYYMM")
              .add(1, "months")
              .format("YYYYMM")
          : bdsiaRows[0]?.competence;

      if (
        validationCompetence <= moment().format("YYYYMM") &&
        variables?.isFutureCompetence
      ) {
        const newCompetence = moment(bdsiaRows[0]?.competence, "YYYYMM")
          .add(1, "months")
          .format("YYYYMM");
        bdsiaRows.unshift({ competence: newCompetence });
        sigtapRows.unshift({ competence: newCompetence });
      }

      return { bdsiaRows, sigtapRows };
    } else {
      const validationCompetence =
        moment().format("DD") < "15"
          ? moment(data.SearchAllCompetence.bdsiaRows[0]?.competence, "YYYYMM")
              .add(1, "months")
              .format("YYYYMM")
          : data.SearchAllCompetence.bdsiaRows[0]?.competence;

      if (validationCompetence <= moment().format("YYYYMM")) {
        const newCompetence = moment(
          data.SearchAllCompetence.bdsiaRows[0]?.competence,
          "YYYYMM",
        )
          .add(1, "months")
          .format("YYYYMM");
        data.SearchAllCompetence.bdsiaRows.unshift({
          competence: newCompetence,
        });
        data.SearchAllCompetence.sigtapRows.unshift({
          competence: newCompetence,
        });
      }

      return data.SearchAllCompetence;
    }
  } else {
    return { bdsiaRows: [], sigtapRows: [] };
  }
};
