<template>
  <section class="person-personal-data">
    <FormBase
      title="Dados Pessoais"
      class="form-base-personal-data"
      :disabled="disabledByModalPersonalData"
    >
      <div class="grid-personal-data">
        <div class="line-1-2">
          <div :class="{ 'active-img': existsPesId !== null }" class="img">
            <img
              :src="avatar"
              class="avatar"
              :class="{ 'hover-add': addPhoto }"
              alt="Foto da Pessoa"
              @click="openModalChoose"
              @mouseover="hoverEffect(true)"
              @mouseleave="hoverEffect(false)"
            />

            <img
              v-show="editPhoto"
              :src="avatar_photo"
              class="avatar-edit"
              alt="Foto da Pessoa"
              @click="openModalChoose"
            />
            <!-- <div
              :disabled="!isAllowedIncludeAvatar"
              title="Tirar uma foto pela webcam"
              :class="{ disable: !isAllowedIncludeAvatar }"
              class="button-webcam-photo"
              @click="openModalWebcam"
            >
              <div class="label-photo">
                <IconWebcam />
              </div>
            </div> -->
            <!-- <div
              :disabled="!isAllowedIncludeAvatar"
              :class="{ disable: !isAllowedIncludeAvatar }"
              title="Enviar uma foto do seu computador"
              class="button-add-photo"
              @click="openModalUpload"
            >
              <div class="label-photo">
                <IconAdd />
              </div>
            </div> -->

            <!-- <div
              :disabled="!isAllowedAlterAndRemoveAvatar"
              :class="{ disable: !isAllowedAlterAndRemoveAvatar }"
              title="Remover foto"
              type="button"
              class="button-remove-photo"
              @click="confirmRemovePhoto"
            >
              <IconAdd />
            </div> -->
          </div>

          <div class="row">
            <div v-bind:class="[cadsus ? 'row-1-cadsus' : 'row-1']">
              <RgInput
                id="nome"
                ref="name"
                v-model="form.pes_nome"
                :rules="{
                  compositeValue: REQUIRE_COMPOSITE_NAME,
                  validateEspecialCharacter: 'name',
                  cannotContainNumbers: true,
                }"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                :maxlength="100"
                :label="hasLinkNewborn ? 'Filho(a) de' : 'Nome'"
                placeholder="Digite o nome completo do paciente"
              />

              <LargeButton
                v-if="cadsus"
                id="cadsus"
                :class="{ disable: !!form.pes_id }"
                :disabled="!!form.pes_id"
                :label="'CADSUS'"
                class="btn-cadsus"
                :backgroundColor="'#1E88A9'"
                @click="showModalSearchCadSus"
              >
                <IconArrowDownload slot="icon" />
              </LargeButton>
              <RgInput
                id="razao-social"
                v-model="form.pes_nome_social"
                :class="{ disable: cantEdit }"
                :rules="{
                  name: true,
                  validateEspecialCharacter: 'name',
                  cannotContainNumbers: true,
                }"
                :disabled="cantEdit"
                :maxlength="100"
                label="Nome Social"
                placeholder="Digite o nome social do paciente"
              />

              <RgRadioCustom
                id="gender"
                ref="gender"
                v-bind="propsRadioCustom"
                :canUnselect="false"
                label="Sexo"
                :rules="{ required: true }"
                :class="{ disable: cantEdit || disabledByModal }"
                :disabled="cantEdit || disabledByModal"
                @input="onInputRadioCustom"
              />
              <RgComboboxGenre
                ref="genre"
                v-model="form.pes_id_generos"
                :class="{ disable: cantEdit || disabledByModal }"
                :disabled="cantEdit || disabledByModal"
              />
            </div>

            <div class="row-2">
              <RgInput
                id="nome-mae"
                ref="motherName"
                v-model="form.pes_mae"
                :rules="{
                  compositeValue: REQUIRE_COMPOSITE_NAME,
                  validateEspecialCharacter: 'name',
                  cannotContainNumbers: true,
                }"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                :maxlength="100"
                label="Nome da Mãe"
                placeholder="Digite o nome da mãe do paciente"
              />

              <RgInput
                id="nome-pai"
                v-model="form.pes_pai"
                :rules="{
                  compositeValue: COMPOSITE_NAME,
                  validateEspecialCharacter: 'name',
                  cannotContainNumbers: true,
                }"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                :maxlength="100"
                label="Nome do Pai"
                placeholder="Digite o nome do pai do paciente"
              />

              <RgComboboxEthnicity
                id="etnia"
                ref="ethnicity"
                v-model="form.pes_id_etnias"
                :rules="{ required: true }"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                class="top"
                label="Raça/Cor"
              />

              <RgSuggestEthnicityAb
                id="etnia-ab"
                ref="ethnicityAb"
                v-model="ethnicityAb"
                :disabled="!isIndigenousRace"
                :class="{ disable: !isIndigenousRace }"
                label="Etnia"
                class="ethnicity-ab"
                :rules="{ required: isIndigenousRace }"
                @selected="selectingEthnicityAb"
              />

              <RgComboboxBloodType
                id="tipo-sanguineo"
                v-model="form.pes_id_tipos_sanguineos"
                :class="{ disable: cantEdit }"
                :disabled="cantEdit"
                class="top"
                label="Tipo Sanguíneo"
              />
            </div>
          </div>
        </div>

        <div :class="isBrazillian ? 'row-3' : 'row-3-foreign'">
          <RgInputDate
            id="data-nascimento"
            ref="birthdayDate"
            v-model="form.pes_nascimento"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            :rules="{ required: true, fn: verifyDateEntry }"
            :max-date="new Date()"
            label="Nascimento"
          />

          <RgSuggestNationality
            id="nacionalidade"
            ref="nationality"
            v-model="nationality"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            :rules="{
              required: isHealthCenter,
              forceSelection: isHealthCenter,
            }"
            data-id="nacionalidade"
            label="Nacionalidade"
            placeholder="Digite o nome do país de origem"
            @selected="selectingNationality"
          />

          <RgSuggestCity
            v-if="isBrazillian"
            id="naturalidade"
            ref="naturalness"
            v-model="naturality"
            :rules="{
              required: !isPrivateSector && isHealthCenter,
              forceSelection: isHealthCenter,
            }"
            :class="{ disable: !form.pes_id_nacionalidades || cantEdit }"
            :disabled="!form.pes_id_nacionalidades || cantEdit"
            title="Nome do Munícipio"
            data-id="naturalidade"
            label="Naturalidade"
            placeholder="Digite o nome da cidade de nascimento"
            @selected="selectingNaturalness"
          />

          <div v-else :class="{ disable: cantEdit }" class="naturality">
            <input
              id="e-naturalizado"
              v-model="isNaturalized"
              :class="{ disable: cantEdit }"
              :disabled="cantEdit"
              type="checkbox"
              name="naturality"
              value="1"
              @change="setNaturalized($event)"
            />
            <label class="label" for="naturality">Naturalizado</label>
          </div>

          <RgInputDate
            v-if="!isBrazillian && isNaturalized"
            id="data-naturalizacao"
            ref="naturalized"
            v-model="formForeign.est_naturalizacao"
            :rules="{ required: isHealthCenter }"
            :max-date="new Date()"
            label="Data de Naturalização"
            class="top"
          />

          <RgInput
            v-if="!isBrazillian && isNaturalized"
            id="portaria"
            ref="ordinance"
            v-model="formForeign.est_portaria"
            label="Portaria"
            :rules="{ required: isHealthCenter }"
            :maxlength="16"
            placeholder="Digite o número da portaria"
          />

          <RgInputDate
            v-if="!isBrazillian && !isNaturalized"
            id="chegada-brasil"
            ref="arrived"
            v-model="formForeign.est_chegada_brasil"
            :rules="{ required: isHealthCenter }"
            :max-date="new Date()"
            label="Data de Chegada ao Brasil"
            class="top"
          />

          <RgInput
            v-if="!isBrazillian && !isNaturalized"
            id="passaporte"
            ref="passport"
            v-model="formForeign.est_passaporte"
            :rules="{ required: isHealthCenter }"
            label="Passaporte"
            :maxlength="8"
            placeholder="Digite o número do passaporte"
          />

          <RgComboboxScholarity
            id="escolaridade"
            v-model="form.pes_id_escolaridades"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            class="top"
            label="Escolaridade"
          />
        </div>

        <div class="row-4">
          <RgComboboxReligion
            id="religiao"
            v-model="form.pes_id_religiao"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            class="top"
            label="Religião"
          />

          <RgComboboxMaritalStatus
            id="estado-civil"
            v-model="form.pes_id_estados_civis"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            class="top"
            label="Estado Civil"
          />

          <RgComboboxJobStatus
            id="situacao-profissional"
            v-model="form.pes_id_situacao_trabalho"
            :class="{ disable: cantEdit }"
            :disabled="cantEdit"
            class="top"
            label="Situação Profissional"
          />

          <RgInput
            id="profissao"
            v-model="form.pes_profissao"
            :class="{ disable: !form.pes_id_situacao_trabalho }"
            :disabled="!form.pes_id_situacao_trabalho"
            placeholder="Digite o nome da profissão"
            class="top"
            label="Profissão"
            :maxlength="100"
          />
        </div>
        <div class="row-5">
          <LargeButton
            id="deficient"
            :label="'O paciente é portador de deficiência?'"
            :backgroundColor="'#1E88A9'"
            @click="openModalPersonDeficient"
          >
            <IconAccessibility slot="icon" />
          </LargeButton>
        </div>
      </div>
    </FormBase>

    <!-- MODAL -->
    <ModalSearchCadSus
      v-if="modalSearchCadSus"
      ref="modalCadSus"
      :show="modalSearchCadSus"
      @close="closeModalSearchCadSus"
    />

    <ModalChooseWayPhoto
      :show="modalChooseWayPhoto"
      :hasImage="personPhotoData"
      @upload="getUploadedFileInfo"
      @close="closeModalChooseWayPhoto"
    />

    <ModalPersonDeficient
      ref="modalPersonDeficient"
      :show="modalPersonDeficient"
      @close="closeModalPersonDeficient"
      @seletedDeficient="setDeficient"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgInput,
  RgInputDate,
  LargeButton,
  ModalChooseWayPhoto,
  RgRadioCustom,
  IconAccessibility,
  RgSuggestEthnicityAb,
} from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";

import moment from "moment";

import RgComboboxEthnicity from "$person/common/components/combobox/rg-combobox-ethnicity/RgComboboxEthnicity";
import RgComboboxBloodType from "$person/common/components/combobox/rg-combobox-blood-type/RgComboboxBloodType";
import RgComboboxMaritalStatus from "$person/common/components/combobox/rg-combobox-marital-status/RgComboboxMaritalStatus";
import RgComboboxGenre from "$person/common/components/combobox/rg-combobox-genre/RgComboboxGenre";

import RgSuggestNationality from "$person/common/components/suggest/rg-suggest-nationality/RgSuggestNationality";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";

import RgComboboxScholarity from "$person/common/components/combobox/rg-combobox-scholarity/RgComboboxScholarity";
import RgComboboxReligion from "$person/common/components/combobox/rg-combobox-religion/RgComboboxReligion";
import RgComboboxJobStatus from "$person/common/components/combobox/rg-combobox-job-status/RgComboboxJobStatus";
import ModalSearchCadSus from "$person/common/components/modal/modal-search-cad-sus/ModalSearchCadSus";
import ModalPersonDeficient from "$person/common/components/modal/modal-person-deficient/ModalPersonDeficient";
import { RemoveUploadedFile } from "~tokio/primitive/modal/modal-upload/index";

import { IconArrowDownload } from "~tokio/primitive/icon/symbols";

import SearchBloodType from "$person/common/components/combobox/rg-combobox-blood-type/action/SearchBloodType";
import SearchEthnicity from "$person/common/components/combobox/rg-combobox-ethnicity/action/SearchEthnicity";
import SearchNationality from "$person/common/components/suggest/rg-suggest-nationality/action/SearchNationality";
import SearchState from "$person/common/components/suggest/rg-suggest-state/action/SearchState";
import SearchCity from "$person/common/components/suggest/rg-suggest-city/action/SearchCity";

export default {
  name: "PersonPersonalData",
  components: {
    RgInput,
    FormBase,
    RgComboboxEthnicity,
    RgComboboxBloodType,
    RgComboboxMaritalStatus,
    RgSuggestNationality,
    RgSuggestCity,
    RgComboboxScholarity,
    RgComboboxReligion,
    RgComboboxJobStatus,
    RgInputDate,
    ModalSearchCadSus,
    IconArrowDownload,
    LargeButton,
    ModalChooseWayPhoto,
    RgComboboxGenre,
    RgRadioCustom,
    ModalPersonDeficient,
    IconAccessibility,
    RgSuggestEthnicityAb,
  },
  props: {
    cantEdit: {
      type: Boolean,
      default: false,
    },
    hasLinkNewborn: {
      type: Boolean,
      default: false,
    },
    disabledByModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      personModel: null,
      personPhotoState: false,
      modalSearchCadSus: false,
      modalChooseWayPhoto: false,
      modalPersonDeficient: false,
      personPhotoData: {},
      personListDeficient: [],
      form: {
        pes_id: null,
        pes_nome: null,
        pes_foto: null,
        pes_nascimento: "",
        pes_nome_social: null,
        pes_mae: null,
        pes_pai: null,
        pes_id_sexos: 1,
        pes_id_etnias: null,
        pes_id_tipos_sanguineos: null,
        pes_id_estados_civis: null,
        pes_id_nacionalidades: null,
        pes_id_municipios: null,
        pes_id_escolaridades: null,
        pes_id_religiao: null,
        pes_profissao: null,
        pes_id_situacao_trabalho: null,
        sex_sigla: "",
        pes_ativo: null,
        pes_id_generos: null,
        pes_id_ab_etnias: null,
      },
      formForeign: {
        est_id: null,
        est_chegada_brasil: null,
        est_naturalizacao: null,
        est_portaria: null,
        est_passaporte: null,
      },
      isNaturalized: false,
      nationality: null,
      ethnicityAb: null,
      naturality: null,
      isBrazillian: true,
      requiredNaturalized: false,
      requiredForeign: false,
      cadsus: false,
      isPrivateSector: false,
      avatar_photo: "/static/person-image/photo-default1.png",
      typeUnithHealthSus: "",
    };
  },
  computed: {
    ...mapGetters({
      personalData: "Person/Patient/GET_PERSONAL_DATA",
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      documentForeign: "Person/Patient/GET_DOCUMENT_FOREIGN",
    }),

    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.form.sex_sigla;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },

    disabledByModalPersonalData() {
      return this.modalSearchCadSus || this.modalChooseWayPhoto;
    },

    avatar() {
      if (this.form.pes_foto_url) {
        return this.form.pes_foto_url;
      }
      if (this.personPhotoData.url) {
        return this.personPhotoData.url;
      }
      return this.avatar_photo;
    },

    hasPatient() {
      return this.form.pes_id && this.form.pes_id > 0;
    },

    addPhoto() {
      return this.avatar_photo === "/static/person-image/photo-add.png";
    },

    editPhoto() {
      return this.avatar_photo === "/static/person-image/photo-edit.png";
    },

    isHealthCenter() {
      return this.typeUnithHealthSus === 2;
    },

    isIndigenousRace() {
      const INDIGENOUS_ID = 5;
      return this.form.pes_id_etnias === INDIGENOUS_ID;
    },
  },
  watch: {
    "form.sex_sigla"(pValue) {
      const genderId = this.$utils.app.Genders.GetIdByInitials(pValue);
      this.form.pes_id_sexos = genderId;
    },

    "form.pes_id_municipios"(pValue) {
      const isForeign = this.$utils.app.CustomCities.ESTRANGEIRO === pValue;
      const isNaturalized =
        pValue === this.$utils.app.CustomCities.NATURALIZADO;
      const isNotForeign = !(isForeign || isNaturalized);

      if (isNotForeign) {
        this.cleanForeignForm();
      }

      if (isNaturalized) {
        this.isNaturalized = true;
      }
    },

    "form.pes_nascimento"(pValue) {
      const birthDate = moment(pValue, "DD-MM-YYYY").toDate();
      this.$emit("birthDateChanged", birthDate);
    },

    "form.pes_id_etnias"() {
      if (!this.isIndigenousRace) {
        this.ethnicityAb = null;
        this.form.pes_id_ab_etnias = null;
      }
    },

    personalData(pValue) {
      if (pValue) {
        this.loadPatientInformation(pValue);
      }
    },
  },
  created() {
    this.FROM_BRAZIL = 1;
    this.REQUIRE_COMPOSITE_NAME = {
      required: true,
      message: "nome e sobrenome",
    };
    this.COMPOSITE_NAME = {
      required: false,
      message: "nome e sobrenome",
    };
  },
  mounted() {
    this.cadsus = this.$store.getters["Login/GET_CADSUS"];
    this.isPrivateSector = this.$store.getters["Login/GET_PRIVATE_SECTOR"];
    this.$nextTick(() => {
      this.selectedNationalityDefault();
    });
    const unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH"];
    this.typeUnithHealthSus = unitHealth.uns_id_tipos_unidades_saude_sus;
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.sex_sigla = item.id;
      }
    },
    closeModalChooseWayPhoto() {
      this.modalChooseWayPhoto = false;
      this.$emit("disabledByModalComponents", this.modalChooseWayPhoto);
    },
    closeModalPersonDeficient() {
      this.modalPersonDeficient = false;
      this.$emit("disabledByModalComponents", this.modalPersonDeficient);
    },

    hoverEffect(pBoolean) {
      if (!this.form.pes_foto_url && !this.personPhotoData.url) {
        if (pBoolean) {
          this.avatar_photo = "/static/person-image/photo-add.png";
        } else {
          this.avatar_photo = "/static/person-image/photo-default1.png";
        }
      } else {
        if (pBoolean) {
          this.avatar_photo = "/static/person-image/photo-edit.png";
        } else {
          this.avatar_photo = "/static/person-image/photo-default1.png";
        }
      }
    },

    loadPatientInformation(pPersonalData) {
      const isNewBorn = this.$store.getters["Person/Patient/IS_NEWBORN"];
      this.mountData(pPersonalData, isNewBorn);
    },

    mountData(pData, isNewBorn = false) {
      let sexSigla;
      let sexId;

      if (pData.gender?.sex_id === 3 || pData.sex_id === 3) {
        sexSigla = null;
        sexId = null;
      } else {
        sexSigla = pData.gender ? pData.gender.sex_sigla : pData.sex_sigla;
        sexId = pData.gender ? pData.gender.sex_id : pData.sex_id;
      }

      if (isNewBorn) {
        this.form.pes_nome = `FILHO DE ${
          pData.pes_nome ? pData.pes_nome : ""
        } `;
        this.form.pes_nome_social = "";

        const isMalePatient = sexSigla === "M";
        const isFemalePatient = sexSigla === "F";

        if (isMalePatient) {
          this.form.pes_pai = pData.pes_nome;
          this.form.pes_mae = "";
        } else if (isFemalePatient) {
          this.form.pes_mae = pData.pes_nome;
          this.form.pes_pai = "";
        }
      } else {
        const form = { ...this.form };

        this.form = Object.assign(form, {
          pes_id: pData.pes_id,
          pes_nome: pData.pes_nome,
          pes_foto: pData.pes_foto,
          pes_foto_url: pData.pes_foto_url,
          pes_nascimento: pData.pes_nascimento,
          pes_nome_social: pData.pes_nome_social,
          pes_mae: pData.pes_mae,
          pes_pai: pData.pes_pai,
          sex_sigla: sexSigla,
          pes_id_sexos: sexId,
          pes_id_etnias: pData.ethnicity ? pData.ethnicity.etn_id : null,
          pes_id_ab_etnias: pData.pes_id_ab_etnias,
          pes_id_tipos_sanguineos: pData.bloodType
            ? pData.bloodType.tps_id
            : null,
          pes_id_estados_civis: pData.maritalStatus
            ? pData.maritalStatus.ecv_id
            : null,
          pes_id_escolaridades: pData.scholarity
            ? pData.scholarity.esc_id
            : null,
          pes_id_religiao: pData.religion ? pData.religion.rel_id : null,
          pes_profissao: pData.pes_profissao,
          pes_id_situacao_trabalho: pData.jobStatus
            ? pData.jobStatus.sit_id
            : null,
          pes_ativo: pData?.pes_ativo,
          pes_id_generos: pData.pes_id_generos ? pData.pes_id_generos : null,
        });

        this.$refs.nationality.forceSelection({
          nac_id: pData.nationality ? pData.nationality.nac_id : null,
          nac_nome: pData.nationality ? pData.nationality.nac_nome : null,
        });

        this.$refs.ethnicityAb.forceSelection({
          ens_id: pData.ethnicityAb ? pData.ethnicityAb.ens_id : null,
          ens_descricao: pData.ethnicityAb
            ? pData.ethnicityAb.ens_descricao
            : null,
        });

        if (this.$refs.naturalness) {
          this.$refs.naturalness.forceSelection({
            mun_id: pData.city ? pData.city.mun_id : null,
            mun_nome: pData.city ? pData.city.mun_nome : null,
          });
        }

        if (pData.pes_foto_url && pData.pes_foto) {
          this.personPhotoData.filename = pData.pes_foto;
          this.personPhotoData.url = pData.pes_foto_url;
        }

        this.setForeignerData();
      }
      this.removeExtraSpacesBeforeSend();
    },

    async confirmRemovePhoto() {
      if (this.personPhotoData && this.personPhotoData.url_remove) {
        await RemoveUploadedFile(this.personPhotoData.url_remove);
        this.personPhotoData = {};
      }
      // seto para null, e remove no backend no confirmar
      if (this.form.pes_foto) {
        this.form.pes_foto = null;
        this.form.pes_foto_url = null;
        this.personPhotoData = {};
        // se ja existe uma foto para o perfil salva, ao confirmar, excluo ela e faço um update na tabela pessoas sem essa foto
        // enviar pes_id para o serviço
      }
    },

    selectingEthnicityAb(pData) {
      const source = pData.source;
      const hasValue = source && source.ens_id && source.ens_id > 0;

      if (hasValue) {
        this.form.pes_id_ab_etnias = source.ens_id;
        this.ethnicityAb = source.ens_descricao;
      } else {
        this.form.pes_id_ab_etnias = null;
        this.ethnicityAb = null;
      }
    },

    selectingNationality(pData) {
      const source = pData.source;
      const hasValue = source && source.nac_id && source.nac_id > 0;
      const nacId = hasValue ? source.nac_id : null;

      if (hasValue) {
        this.form.pes_id_nacionalidades = source.nac_id;
        this.nationality = source.nac_nome;

        const isforeign = nacId !== this.FROM_BRAZIL;

        if (isforeign) {
          this.form.pes_id_municipios = this.$utils.app.CustomCities.ESTRANGEIRO;
          this.isBrazillian = false;
        } else {
          this.isBrazillian = true;
          this.form.pes_id_municipios = null;
          this.naturality = null;
        }
      } else {
        this.isBrazillian = true;
        this.nationality = null;
        this.naturality = null;
        this.form.pes_id_nacionalidades = null;
        this.form.pes_id_municipios = null;
      }
    },

    selectingNaturalness(pValue) {
      const source = pValue.source;
      if (source) {
        this.form.pes_id_municipios = source && source.mun_id;
        this.naturality = source && source.mun_nome;
      } else {
        this.form.pes_id_municipios = null;
        this.naturality = null;
      }
    },

    showModalSearchCadSus() {
      this.modalSearchCadSus = true;
      this.$emit("disabledByModalComponents", this.modalSearchCadSus);
    },

    closeModalSearchCadSus(pValue) {
      this.loadFromCadsus(pValue);
      this.modalSearchCadSus = false;
      this.$emit("disabledByModalComponents", this.modalSearchCadSus);
    },

    async loadFromCadsus(pData) {
      if (!pData) return;
      this.cleanForm();
      const form = { ...this.form };
      this.form = Object.assign(form, {
        pes_nome: this.validateField(pData.NomeCompleto.Nome),
        pes_nascimento: pData.dataNascimento,
        pes_nome_social: this.validateField(pData.NomeSocial),
        sex_sigla: pData.Sexo.codigoSexo,
        pes_id_sexos: pData.Sexo.codigoSexo === "M" ? 1 : 2,
        pes_mae: this.validateField(pData.Mae.Nome),
        pes_pai: this.validateField(pData.Pai.Nome),
        pes_id_etnias: pData.RacaCor
          ? await this.getEthnicityId(pData.RacaCor.codigoRacaCor)
          : null,
        pes_id_tipos_sanguineos: pData.TipoSanguineo
          ? await this.getBloodyTypeId(pData.TipoSanguineo)
          : null,
      });

      if (pData.DadosNacionalidade) {
        const nacNome = pData.DadosNacionalidade.PaisNascimento.nomePais;
        const nationality = await SearchNationality({ nacNome: nacNome });
        if (nationality) {
          const nacId = nationality.rows[0].nac_id;

          if (this.$refs.nationality) {
            this.$refs.nationality.forceSelection({
              nac_id: nacId,
              nac_nome: nacNome,
            });
          }
          const source = {
            nac_id: nacId,
            nac_nome: nacNome,
          };
          pData.source = source;
          this.selectingNationality(pData);
        }
      }

      if (pData.MunicipioNascimento && pData.MunicipioNascimento.UF) {
        const state = await SearchState({
          ufSigla: pData.MunicipioNascimento.UF.codigoUF,
        });
        if (state) {
          const munNome = pData.MunicipioNascimento.nomeMunicipio;

          if (this.$refs.naturalness) {
            const city = await SearchCity({
              munNome: munNome,
              mun_id_estados: state.est_id,
            });

            if (city) {
              const munId = city.rows[0].mun_id;
              this.form.pes_id_municipios = munId;
              this.naturality = munNome;
              this.$refs.naturalness.forceSelection({
                mun_id: munId,
                mun_nome: munNome,
              });
            }
          }
        }
      }
      this.$emit("loadFromCadsus", pData);
    },

    async getEthnicityId(pValue) {
      const data = await SearchEthnicity();
      const ethnicity = data.find((e) => e.etn_codigo_sisaih === pValue);
      return ethnicity ? ethnicity.etn_id : null;
    },

    async getBloodyTypeId(pValue) {
      const data = await SearchBloodType();
      const bloodType = data.find((b) => b.tps_rh === pValue);
      return bloodType ? bloodType.tps_id : null;
    },

    validateField(pValue) {
      const format = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/;
      if (format.test(pValue) && pValue !== "SEM INFORMAÇÃO") {
        return pValue;
      }
      return "";
    },

    cleanForm() {
      // PERSONAL DATA
      this.form.pes_nome = null;
      this.form.pes_nascimento = "";
      this.form.pes_nome_social = null;
      this.form.pes_mae = null;
      this.form.pes_pai = null;
      this.form.pes_id_etnias = null;
      this.form.pes_id_tipos_sanguineos = null;
      this.form.pes_id_estados_civis = null;
      this.form.pes_id_nacionalidades = null;
      this.form.pes_id_municipios = null;
      this.form.pes_id_escolaridades = null;
      this.form.pes_id_escolaridades = null;
      this.form.pes_id_religiao = null;
      this.form.pes_profissao = null;
      this.form.pes_id_situacao_trabalho = null;
      this.form.pes_id_sexos = 0;
      this.form.pes_id_generos = null;
      this.form.pes_id_ab_etnias = null;
      this.form.sex_sigla = "";
      this.form.pes_ativo = null;
      this.nationality = null;
      this.naturality = null;
      this.$refs.gender.cleanValidate();
      this.$refs.ethnicity.cleanValidate();
      this.$refs.ethnicityAb.cleanValidate();
      this.$refs.birthdayDate.cleanValidate();
      this.$refs.nationality.cleanValidate();
      if (this.$refs.naturalness) {
        this.$refs.naturalness.cleanValidate();
      }
      this.$refs.name.cleanValidate();
      this.$refs.motherName.cleanValidate();

      // FOREIGN DATA
      this.cleanForeignForm();
    },

    cleanForeignForm() {
      this.formForeign.est_chegada_brasil = null;
      this.formForeign.est_naturalizacao = null;
      this.formForeign.est_portaria = null;
      this.formForeign.est_passaporte = null;
      this.isNaturalized = false;
      this.cleanValidateNaturalized();
      this.cleanValidateForeign();
    },

    removeExtraSpacesBeforeSend() {
      this.form.pes_nome = this.$utils.sanitize.removeExtraSpaces(
        this.form.pes_nome,
      );
      this.form.pes_mae = this.$utils.sanitize.removeExtraSpaces(
        this.form.pes_mae,
      );
      this.form.pes_nome_social = this.$utils.sanitize.removeExtraSpaces(
        this.form.pes_nome_social,
      );
      this.form.pes_pai = this.$utils.sanitize.removeExtraSpaces(
        this.form.pes_pai,
      );
      this.form.pes_profissao = this.$utils.sanitize.removeExtraSpaces(
        this.form.pes_profissao,
      );
      this.formForeign.est_portaria = this.$utils.sanitize.removeExtraSpaces(
        this.formForeign.est_portaria,
      );
      this.formForeign.est_passaporte = this.$utils.sanitize.removeExtraSpaces(
        this.formForeign.est_passaporte,
      );
    },

    getPersonalForm() {
      this.removeExtraSpacesBeforeSend();

      if (this.personPhotoData && this.personPhotoData.filename) {
        this.form.pes_foto = this.personPhotoData.filename;
      }

      this.personListDeficient = this.$refs.modalPersonDeficient.get();
      return {
        personForm: Object.assign({}, this.form),
        personPhotoData: this.personPhotoData,
        personForeignForm: this.getForeignerData(),
        personDeficient: this.personListDeficient,
      };
    },

    setPhotoPerfilDefault() {
      this.form.pes_foto = null;
      this.personPhotoData = {};
    },

    mountForeignValidateData() {
      const notNaturalized = {
        est_chegada_brasil: this.formForeign.est_chegada_brasil,
        est_passaporte: this.formForeign.est_passaporte,
        est_naturalizacao: null,
        est_portaria: null,
      };

      const naturalized = {
        est_naturalizacao: this.formForeign.est_naturalizacao,
        est_portaria: this.formForeign.est_portaria,
        est_chegada_brasil: null,
        est_passaporte: null,
      };

      return this.isNaturalized ? naturalized : notNaturalized;
    },

    areEmpty(pFirst, pSecond) {
      const isFirstFilled = pFirst !== null && pFirst !== "";
      const isSecondFilled = pSecond !== null && pSecond !== "";
      return isFirstFilled && isSecondFilled;
    },

    checkForeignerValues() {
      const data = Object.assign({}, this.formForeign);
      delete data.est_id;

      const foreignerValues = Object.values(data);

      return foreignerValues.some((item) => {
        return item !== null && item !== "";
      });
    },

    getForeignerData() {
      const values = Object.values(this.formForeign);

      const hasValue = values.some((item) => {
        return item !== null && item !== "";
      });

      const data = Object.assign({}, this.formForeign);

      return hasValue ? data : null;
    },

    setForeignerData() {
      if (this.documentForeign.est_id) {
        this.formForeign.est_id = this.documentForeign.est_id;
        this.formForeign.est_chegada_brasil = this.documentForeign.est_chegada_brasil;
        this.formForeign.est_naturalizacao = this.documentForeign.est_naturalizacao;
        this.formForeign.est_portaria = this.documentForeign.est_portaria;
        this.formForeign.est_passaporte = this.documentForeign.est_passaporte;
      }
    },

    setNaturalized(e) {
      const checked = e.target.checked;

      this.form.pes_id_municipios = checked
        ? this.$utils.app.CustomCities.NATURALIZADO
        : this.$utils.app.CustomCities.ESTRANGEIRO;

      this.cleanForeignForm();
    },

    isAllowedIncludeAvatar() {
      return this.$Permissions.global.has("cadastro.pessoa.incluirFoto");
    },

    isAllowedAlterAndRemoveAvatar() {
      return this.$Permissions.global.has("cadastro.pessoa.alterarExcluirFoto");
    },

    cleanValidateNaturalized() {
      if (this.$refs.naturalized) {
        this.$refs.naturalized.cleanValidate();
      }
      if (this.$refs.ordinance) {
        this.$refs.ordinance.cleanValidate();
      }
    },

    cleanValidateForeign() {
      if (this.$refs.arrived) {
        this.$refs.arrived.cleanValidate();
      }
      if (this.$refs.passport) {
        this.$refs.passport.cleanValidate();
      }

      this.requiredForeign = false;
    },

    openModalChoose() {
      this.modalChooseWayPhoto = true;
      this.$emit("disabledByModalComponents", this.modalChooseWayPhoto);
    },
    openModalPersonDeficient() {
      this.modalPersonDeficient = true;
      this.$emit("disabledByModalComponents", this.modalPersonDeficient);
    },
    async getUploadedFileInfo(pData) {
      await this.confirmRemovePhoto();
      if (pData) {
        this.form.pes_foto = null;
        this.form.pes_foto_url = null;
        this.personPhotoData = { ...pData };
      }
    },
    verifyDateEntry(pValue, pErrors) {
      if (!pValue) return true;
      const dateEntry = this.form.pes_nascimento;
      const dateConverted = moment(dateEntry, "DD/MM/YYYY HH:mm");

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push("Data de Nascimento não pode ser maior que a data atual");
        return false;
      }
      return true;
    },

    selectedNationalityDefault() {
      return this.$refs.nationality.forceSelection({
        nac_id: 1,
        nac_nome: "BRASIL",
      });
    },

    setDeficient(listDeficient) {
      if (listDeficient.oldList) {
        const notEqualsLists =
          JSON.stringify(listDeficient.oldList) !==
          JSON.stringify(listDeficient.list);
        this.personListDeficient = notEqualsLists ? listDeficient.list : null;
      } else {
        this.personListDeficient = listDeficient.list;
      }
      this.$emit("listDeficient", listDeficient.list);
    },

    loadDeficient() {
      this.$refs.modalPersonDeficient.loaderListPerson();
    },
  },
};
</script>
