import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationPriority($priority: String) {
    hospitalizationPriority(priority: $priority) {
      rows {
        fnp_id_prioridades_fila
        fnp_prioridades_fila
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.hospitalizationPriority.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
