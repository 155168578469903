<script>
// import { isNumber } from "lodash";
import RgSelect from "../../selection/rg-select/RgSelect";
import ListPlacesMappedPep from "./action/ListPlacesMappedPep";

export default {
  name: "RgSelectPlaceMappedPep",
  extends: RgSelect,
  props: {
    label: {
      default: "Local de Atendimento",
    },
    placeId: {
      default: null,
    },
    sectorId: {
      default: null,
    },
    defaultText: {
      type: String,
      default: "Digite o nome do local",
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  // computed: {
  //   filter() {
  //     if (!isNumber(this.sectorId)) {
  //       this.resetCombobox();
  //       return null;
  //     } else {
  //       return {
  //         set_id: this.sectorId,
  //       };
  //     }
  //   },
  // },
  watch: {
    sectorId() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData() {
      this.updateOptions();
    },
  },
  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      const data = await ListPlacesMappedPep({
        placeId: this.placeId,
        sectorId: this.sectorId,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.lca_id,
          label: item.lca_nome,
        };
      });
    },
  },
};
</script>
