<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import SearchHealthPlansWithObjective from "./action/SearchHealthPlansWithObjective";
export default {
  name: "RgSelectHealthPlansWithObjective",
  extends: RgSelect,
  props: {
    moduleId: {
      required: true,
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    healthUnitId() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData() {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const data = await SearchHealthPlansWithObjective({
        intModulo: this.moduleId,
      });

      this.valuesData = data.map((item) => {
        return { value: item.data, label: item.pls_nome };
      });
    },
  },
};
</script>
