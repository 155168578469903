import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { DeepCopy } from "~utils/object";
import { BrazilianDateFormat } from "~utils/date";

const query = gql`
  query GetPatientById($pesId: Int, $logType: Int) {
    people(pes_id: $pesId) {
      rows {
        pes_ativo
        pes_id
        pes_nome
        pes_nascimento
        pes_nome_social
        pes_mae
        pes_pai
        pes_profissao
        pes_email
        pes_obs
        pes_foto
        pes_foto_url
        pes_id_usuarios
        pes_id_locais_atendimento_pleni
        pes_id_generos
        pes_id_ab_etnias
        pes_id_municipios
        pes_data_unificacao
        pes_empresa_pleni
        foreign {
          est_id
          est_portaria
          est_chegada_brasil
          est_naturalizacao
          est_passaporte
        }
        attachments(fileinfo: true) {
          rows {
            pea_id
            pea_id_pessoas
            pea_id_documentos_anexos
            pea_anexos
            pea_url
            pea_url_remove
            pea_file_size
            pea_mimetype
            document_attachment {
              doa_id
              doa_nome
            }
          }
          count
        }
        gender {
          sex_id
          sex_sigla
        }
        religion {
          rel_id
        }
        bloodType {
          tps_id
        }
        maritalStatus {
          ecv_id
        }
        ethnicity {
          etn_id
        }
        ethnicityAb {
          ens_id
          ens_descricao
        }
        nationality {
          nac_id
          nac_nome
        }
        city {
          mun_id
          mun_nome
        }
        scholarity {
          esc_id
        }
        jobStatus {
          sit_id
        }
        telephones {
          rows {
            tel_id
            tel_numero
            tel_recado
            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
        address {
          end_cep
          end_logradouro
          end_numero
          end_complemento
          end_referencia
          publicPlaceType {
            tlg_id
            tlg_nome
          }
          subNeighborhood {
            sub_nome
          }
          neighborhood {
            bai_nome
            city {
              mun_id
              mun_nome
              state {
                est_id
                est_sigla
              }
            }
          }
        }
        cnh {
          cnh_id
          cnh_id_tipos_documento
          cnh_numero
        }
        cns {
          crs_id
          crs_id_tipos_documento
          crs_numero
        }
        cpf {
          cpf_id
          cpf_id_tipos_documento
          cpf_numero
        }
        rg {
          rgs_id
          rgs_id_tipos_documento
          rgs_id_orgaos_emissores
          rgs_numero
          rgs_expedicao
          issuingBody {
            org_id
            org_codigo
            org_nome
          }
        }
        pisPasep {
          pis_id
          pis_id_tipos_documento
          pis_numero
        }
        weddingCertificate {
          ctc_id
          ctc_id_tipos_documento
          ctc_cartorio
          ctc_livro
          ctc_folha
          ctc_termo
          ctc_emissao
        }
        birthCertificate {
          ctc_id
          ctc_id_tipos_documento
          ctc_cartorio
          ctc_livro
          ctc_folha
          ctc_termo
          ctc_emissao
        }
        newBirthCertificate {
          ctn_id
          ctn_id_tipos_documento
          ctn_numero
        }
        ctps {
          ctt_id
          ctt_id_estados
          ctt_id_tipos_documento
          ctt_numero
          ctt_serie
          ctt_emissao

          state {
            est_id
            est_sigla
          }
        }
        voterTitle {
          tit_id
          tit_id_municipios
          tit_id_tipos_documento
          tit_numero
          tit_zona
          tit_secao

          city {
            mun_id
            mun_nome

            state {
              est_id
              est_sigla
            }
          }
        }
        user {
          usu_nome
        }
        notification {
          rows {
            nop_id
            nop_id_pessoas
            nop_id_ddds
            nop_celular
            nop_cpf
            nop_criado_em
            nop_alterado_em
            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
        patient {
          pac_id
          pac_prontuario_unico
          pac_bloqueado
          pac_data_inabilitacao
          record_numbers {
            rows {
              ppr_numero
              ppr_id_unidades_saude
            }
          }
          disqualification_reasons {
            mip_id
            mip_nome
            mip_permanente
          }
          inactivated_by {
            usu_nome
          }
          basic_attention {
            rows {
              pae_id
              pae_ativo
              pae_celular
              pae_telefone
              pae_ultima_alteracao
            }
          }
        }
        place {
          lca_id
          sector {
            set_id
            unit_health {
              uns_id
            }
          }
        }
        log(logType: $logType) {
          rows {
            psl_data_log
          }
        }
        deficient
      }
    }
  }
`;

export default async (
  { commit, rootGetters },
  { pesId = null, logType = 1 },
) => {
  try {
    const variables = { pesId, logType };

    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    const isWithoutResult = data.people.rows.length < 1;

    if (isWithoutResult) return false;

    const result = data.people.rows[0];
    const noRefFields = DeepCopy(result);

    const fieldsAddress = noRefFields.address;
    const fieldsContact = prepareContact(noRefFields);
    const fieldsDocument = prepareDocuments(noRefFields);
    const fieldsAttachments = prepareAttachment(noRefFields);
    const patientRecordSplitted = splitPatientRecord(noRefFields, rootGetters);
    const fieldsPrepared = preparePersonalDetail(noRefFields);

    commit("SET_PERSONAL_DETAIL", fieldsPrepared);
    commit("SET_ADDRESS", fieldsAddress);
    commit("SET_CONTACT", fieldsContact);
    commit("SET_DOCUMENT", fieldsDocument);
    commit("SET_ATTACHMENTS", fieldsAttachments);
    commit("SET_PERSON_LOG", noRefFields?.log);

    if (!rootGetters["Person/Patient/IS_NEWBORN"]) {
      commit("SET_PATIENT_INFO", patientRecordSplitted);
    }
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};

const preparePersonalDetail = (pFields) => {
  const fields = DeepCopy(pFields);

  delete fields.address;
  delete fields.telephones;
  delete fields.patient;
  delete fields.user;
  delete fields.cns;
  delete fields.rg;
  delete fields.cpf;
  delete fields.pisPasep;
  delete fields.voterTitle;
  delete fields.cnh;
  delete fields.newBirthCertificate;
  delete fields.weddingCertificate;
  delete fields.birthCertificate;
  delete fields.ctps;
  delete fields.pes_email;

  fields.pes_nascimento = BrazilianDateFormat(fields.pes_nascimento);

  return fields;
};

const splitPatientRecord = (pFields, rootGetters) => {
  const fields = DeepCopy(pFields);

  const unitHealthId = rootGetters["Login/GET_UNIT_HEALTH_ID"];

  const haveRecordNumber =
    fields.patient && fields.patient.record_numbers.rows.length > 0;
  const recordNumber = haveRecordNumber
    ? fields.patient.record_numbers.rows.filter(
        (item) => item.ppr_id_unidades_saude === unitHealthId,
      )
    : [];

  const basicAttention = prepareBasicAttention(fields);

  const pleni = preparePleni(fields);

  const patientRecord = {
    uns_id: unitHealthId,
    pac_id: fields.patient ? fields.patient.pac_id : null,
    pac_bloqueado: fields.patient && fields.patient.pac_bloqueado,
    pac_prontuario_unico: fields.patient
      ? fields.patient.pac_prontuario_unico
      : "",
    pac_data_inabilitacao: fields.patient
      ? fields.patient.pac_data_inabilitacao
      : "",
    inactivated_by: fields.patient?.inactivated_by
      ? fields.patient.inactivated_by.usu_nome
      : "",
    disqualification_reasons: fields.patient?.disqualification_reasons
      ? fields.patient.disqualification_reasons.mip_nome
      : null,
    record_numbers: {
      ppr_numero: recordNumber.length > 0 ? recordNumber[0].ppr_numero : null,
      ppr_id_unidades_saude: unitHealthId,
    },
    pleni: pleni,
    ...basicAttention,
  };

  return patientRecord;
};

const preparePleni = (pFields) => {
  const hasPlace = pFields.place !== null;

  let data = null;

  if (hasPlace) {
    const pleniPlaceId = pFields.place.lca_id;

    const pleniSectorId =
      pFields.place.sector && pFields.place.sector.set_id
        ? pFields.place.sector.set_id
        : null;

    const pleniUnitHealthId =
      pFields.place.sector.unit_health &&
      pFields.place.sector.unit_health.uns_id
        ? pFields.place.sector.unit_health.uns_id
        : null;

    data = {
      lca_id: pleniPlaceId,
      set_id: pleniSectorId,
      uns_id: pleniUnitHealthId,
      pes_empresa: pFields.pes_empresa_pleni,
    };

    return data;
  }
};

const prepareDate = (pDate) => {
  return pDate ? BrazilianDateFormat(pDate) : null;
};

const prepareDocuments = (pFields) => {
  const fields = DeepCopy(pFields);

  fields.rg.rgs_expedicao = prepareDate(fields.rg.rgs_expedicao);
  fields.ctps.ctt_emissao = prepareDate(fields.ctps.ctt_emissao);
  fields.weddingCertificate.ctc_emissao = prepareDate(
    fields.weddingCertificate.ctc_emissao,
  );
  fields.birthCertificate.ctc_emissao = prepareDate(
    fields.birthCertificate.ctc_emissao,
  );
  fields.foreign.est_chegada_brasil = prepareDate(
    fields.foreign.est_chegada_brasil,
  );
  fields.foreign.est_naturalizacao = prepareDate(
    fields.foreign.est_naturalizacao,
  );

  return {
    cns: fields.cns,
    cpf: fields.cpf,
    rg: fields.rg,
    foreign: fields.foreign,
    pisPasep: fields.pisPasep,
    otherDocuments: {
      cnh: fields.cnh,
      newBirthCertificate: fields.newBirthCertificate,
      ctps: fields.ctps,
      voterTitle: fields.voterTitle,
      weddingCertificate: fields.weddingCertificate,
      birthCertificate: fields.birthCertificate,
    },
  };
};

const prepareAttachment = (pFields) => {
  if (
    !pFields.attachments ||
    !pFields.attachments.rows ||
    pFields.attachments.rows.length <= 0
  ) {
    return [];
  }
  return pFields.attachments.rows.map((item) => {
    return {
      filename: item.pea_anexos,
      originalname: item.pea_anexos,
      size: item.pea_file_size,
      mimetype: item.pea_mimetype,
      url: item.pea_url,
      url_remove: item.pea_url_remove,
      document_attachment: { ...item.document_attachment },
    };
  });
};

const prepareContact = (pFields) => {
  const fields = DeepCopy(pFields);

  return {
    telephones: fields.telephones,
    emails: fields.pes_email,
    notification: fields.notification,
  };
};

const prepareBasicAttention = (pFields) => {
  if (pFields.patient) {
    const fields = DeepCopy(pFields);

    const basicAttention = fields.patient.basic_attention;
    const hasBasicAttentionInfo =
      basicAttention.rows && basicAttention.rows.length > 0;

    return hasBasicAttentionInfo ? basicAttention.rows[0] : null;
  }
};
