<template>
  <div v-if="show" class="modal-reason-active-inactive">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-reason-active-inactive-header">
        <div class="title">
          Dados da escala {{ isActive ? "Habilitada" : "Inabilitada" }}
        </div>
      </div>

      <div slot="body" class="modal-reason-active-inactive-body">
        <FormBase title="Dados da Escala">
          <div class="container-info">
            <span class="content">
              Unidade de Saúde:
              <strong>
                {{ scheduleInfo.uns_nome || "-" }}
              </strong>
            </span>
            <span class="content">
              Setor:
              <strong>
                {{ scheduleInfo.set_nome || "-" }}
              </strong>
            </span>
            <span class="content">
              Tipo de Escala:
              <strong>
                {{ scheduleInfo.ees_nome || scheduleInfo.type || "-" }}
              </strong>
            </span>
            <span class="content">
              Nome do Profissional:
              <strong>
                {{ scheduleInfo.fun_nome || scheduleInfo.pes_nome || "-" }}
              </strong>
            </span>
            <span v-if="scheduleInfo.eti_nome" class="content">
              Tipo de horário:
              <strong>
                {{ scheduleInfo.eti_nome || "-" }}
              </strong>
            </span>
            <span v-else class="content">
              Motivo:
              <strong>
                {{ scheduleInfo.motivo_inabilitar || "-" }}
              </strong>
            </span>
            <span class="content">
              Início:
              <strong>
                {{
                  scheduleInfo.inicio ||
                  scheduleInfo.eag_inicio_atendimentos ||
                  "-"
                }}
              </strong>
            </span>
            <span class="content">
              Máx. de agendamentos:
              <strong>
                {{
                  scheduleInfo.eea_quantidade_exames ||
                  scheduleInfo.eag_quantidade_consultas ||
                  "-"
                }}
              </strong>
            </span>
            <span class="content">
              Quantidade:
              <strong>
                {{
                  scheduleInfo.eea_quantidade_marcadas ||
                  scheduleInfo.eag_quantidade_marcadas ||
                  "-"
                }}
              </strong>
            </span>

            <span class="content">
              Agendamentos que restam:
              <strong>
                {{
                  scheduleInfo.eea_quantidade_restantes ||
                  scheduleInfo.eag_quantidade_restantes ||
                  "-"
                }}
              </strong>
            </span>

            <span v-if="scheduleInfo.eti_nome" class="content">
              Motivo:
              <strong>
                {{ scheduleInfo.motivo_inabilitar || "-" }}
              </strong>
            </span>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { FormBase } from "~tokio/foundation";

import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalConflictScale",
  components: {
    RgBaseModal,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    scheduleInfo: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },

  methods: {
    async close() {
      this.$emit("close");
    },
  },
};
</script>
