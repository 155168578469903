import SEARCH_ENTRY from "./pharmacy-central-search/SearchEntry";
import SEARCH_OUTPUT from "./pharmacy-central-search/SearchOutput";
import SEARCH_REVERSAL from "./pharmacy-central-search/SearchReversal";
import SEARCH_DISPOSAL from "./pharmacy-central-search/SearchDisposal";
import SEARCH_REQUEST from "./pharmacy-central-search/SearchRequest";
import SEARCH_TRANSFER from "./pharmacy-central-search/SearchTransfer";
import SEARCH_ATTENDED_TRANSFER from "./pharmacy-central-search/SearchAttendedTransfer";
import SEARCH_REQUEST_TRANSFER from "./pharmacy-central-search/SearchRequestTransfer";

import REGISTER_ENTRY from "./entry/RegisterEntry";
import EDIT_ENTRY from "./entry/EditEntry";
import INACTIVATE_ENTRY from "./entry/InactivateEntry";
import GET_PRODUCT_ENTRY from "./entry/GetProductEntry";
import GET_EXPIRATION_DATE from "./entry/GetExpirationDate";
import GENERATE_REMITTANCE_GUIDE from "./entry/GenerateRemittanceGuide";

import REGISTER_OUTPUT from "./output/RegisterOutput";
import PRINTER_OUTPUT from "./output/PrinterOutput";
import GET_PRODUCT_OUTPUT from "./output/GetOutput";
import EDIT_OUTPUT from "./output/EditOutput";
import INACTIVATE_OUTPUT from "./output/InactivateOutput";

import REGISTER_DISCARD from "./discard/RegisterDiscard";
import GET_QUANTITY_INVENTORY from "./discard/GetQuantityInventory";
import INACTIVATE_DISPOSAL from "./discard/InactivateDiscard";

import REGISTER_TRANSFER_REQUEST from "./pharmacy-central-transfer-request/RegisterTransferRequest";
import SEARCH_ITENS_BY_REQUEST from "./pharmacy-central-transfer-request/SearchItemsByRequest";
import INACTIVATE_TRANSFER_REQUEST from "./pharmacy-central-transfer-request/InactivateTransferRequest";
import INACTIVATE_TRANSFER_REQUEST_ITEM from "./pharmacy-central-transfer-request/InactivateTransferRequestItem";
import GET_TRANSFER_REQUEST from "./pharmacy-central-transfer-request/GetTransferRequest";
import SEARCH_OBSERVATION_TRANSFER_REQUEST from "./pharmacy-central-transfer-request/SearchObservationTransferRequest";
import REGISTER_OBSERVATION_TRANSFER_REQUEST from "./pharmacy-central-transfer-request/RegisterObservationTransferRequest";
import GET_PRINT_TRANSFER_REQUEST from "./pharmacy-central-transfer-request/GetPrintTransferRequest";

import GET_TRANSFER_ATTEND from "./pharmacy-central-attend-transfer/GetTransferAttend";
import SEARCH_ITENS_TRANSFER_ATTEND from "./pharmacy-central-attend-transfer/SearchItensAttendTransfer";
import SEARCH_GUIDE_SHIPPING from "./pharmacy-central-attend-transfer/SearchGuideShipping";
import SEARCH_STOCK_ITEM from "./pharmacy-central-attend-transfer/SearchStockItens";
import ATTEND_ITEM from "./pharmacy-central-attend-transfer/AttendItem";
import UNAVAILABLE_ITEM from "./pharmacy-central-attend-transfer/UnavailableItem";
import CLOSE_REQUEST_TRANSFER from "./pharmacy-central-attend-transfer/CloseRequestTransfer";

import GET_STOCK_ATTEND_REQUEST from "./attend-request/GetStock";
import DISPENSE_ATTEND_REQUEST from "./attend-request/RegisterDispense";
import CLOSE_ORDER_ATTEND_REQUEST from "./attend-request/CloseOrder";
import UNAVAILABLE_ORDER_ATTEND_REQUEST from "./attend-request/UnavailableOrder";
import GET_REMITTANCE_GUIDE from "./attend-request/GetRemittanceGuide";
import GET_PRINT_REMITTANCE_GUIDE_REQUEST from "./attend-request/GetPrintRemittanceGuideRequest";

export default {
  SEARCH_ENTRY,
  SEARCH_OUTPUT,
  SEARCH_REVERSAL,
  SEARCH_DISPOSAL,
  SEARCH_REQUEST,
  SEARCH_TRANSFER,
  SEARCH_ATTENDED_TRANSFER,
  SEARCH_REQUEST_TRANSFER,
  REGISTER_ENTRY,
  EDIT_ENTRY,
  INACTIVATE_ENTRY,
  GET_PRODUCT_ENTRY,
  GET_EXPIRATION_DATE,
  GENERATE_REMITTANCE_GUIDE,
  REGISTER_OUTPUT,
  REGISTER_DISCARD,
  GET_QUANTITY_INVENTORY,
  INACTIVATE_DISPOSAL,
  PRINTER_OUTPUT,
  GET_PRODUCT_OUTPUT,
  EDIT_OUTPUT,
  INACTIVATE_OUTPUT,
  REGISTER_TRANSFER_REQUEST,
  SEARCH_ITENS_BY_REQUEST,
  INACTIVATE_TRANSFER_REQUEST,
  INACTIVATE_TRANSFER_REQUEST_ITEM,
  GET_TRANSFER_REQUEST,
  GET_TRANSFER_ATTEND,
  SEARCH_ITENS_TRANSFER_ATTEND,
  SEARCH_STOCK_ITEM,
  SEARCH_GUIDE_SHIPPING,
  SEARCH_OBSERVATION_TRANSFER_REQUEST,
  REGISTER_OBSERVATION_TRANSFER_REQUEST,
  GET_PRINT_TRANSFER_REQUEST,
  ATTEND_ITEM,
  UNAVAILABLE_ITEM,
  CLOSE_REQUEST_TRANSFER,
  GET_STOCK_ATTEND_REQUEST,
  DISPENSE_ATTEND_REQUEST,
  GET_REMITTANCE_GUIDE,
  CLOSE_ORDER_ATTEND_REQUEST,
  UNAVAILABLE_ORDER_ATTEND_REQUEST,
  GET_PRINT_REMITTANCE_GUIDE_REQUEST,
};
