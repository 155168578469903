<template lang="html">
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 14.0487C23 16.8102 20.7613 19.0489 18 19.0489C15.2389 19.0489 13 16.8102 13 14.0487C13 11.2876 15.2388 9.04883 18 9.04883C20.7613 9.04883 23 11.2876 23 14.0487ZM16.0018 12.5001C16.0018 12.2239 16.2257 12.0001 16.5018 12.0001C16.7779 12.0001 17.0018 12.2239 17.0018 12.5001V15.5001C17.0018 15.7762 16.7779 16.0001 16.5018 16.0001C16.2257 16.0001 16.0018 15.7762 16.0018 15.5001V12.5001ZM19.0018 12.5001C19.0018 12.2239 19.2257 12.0001 19.5018 12.0001C19.7779 12.0001 20.0018 12.2239 20.0018 12.5001V15.5001C20.0018 15.7762 19.7779 16.0001 19.5018 16.0001C19.2257 16.0001 19.0018 15.7762 19.0018 15.5001V12.5001Z"
      fill="white"
    />
    <path
      d="M0.998047 15.3415L0.998047 2.70732C0.998047 1.21469 2.21273 -7.35844e-07 3.70537 -6.70598e-07L16.3396 -1.18341e-07C17.8322 -5.30956e-08 19.0469 1.21469 19.0469 2.70732L19.0469 7.0843C19.0469 7.58244 18.6435 7.98674 18.1444 7.98674C17.6454 7.98674 17.242 7.58244 17.242 7.0843L17.242 2.70732C17.242 2.21008 16.8377 1.80488 16.3396 1.80488L3.70537 1.80488C3.20722 1.80488 2.80293 2.21008 2.80293 2.70732L2.80293 15.3415C2.80293 15.8387 3.20722 16.2439 3.70537 16.2439L10.9249 16.2439C11.424 16.2439 11.8273 16.6482 11.8273 17.1464C11.8273 17.6445 11.424 18.0488 10.9249 18.0488L3.70537 18.0488C2.21273 18.0488 0.998047 16.8341 0.998047 15.3415Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Desactivate",
};
</script>
