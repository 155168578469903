import { render, staticRenderFns } from "./ArrowDoubleRightPoint.vue?vue&type=template&id=31af6a7d&lang=html&"
import script from "./ArrowDoubleRightPoint.vue?vue&type=script&lang=js&"
export * from "./ArrowDoubleRightPoint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports