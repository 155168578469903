import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation($lebId: Int, $unsId: Int) {
    BioslabSendRequestServiceXML(leb_id: $lebId, uns_id: $unsId)
  }
`;

export default async ({ commit }, variables) => {
  try {
    const context = {
      timeout: 120000,
    };

    const { data } = await GraphQLClient.mutate({
      mutation,
      variables,
      context,
    });

    return data.BioslabSendRequestServiceXML;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    const message = jsonResponse?.graphQLErrors[0]?.message || err.message;
    return Promise.reject(message);
  }
};
