<style src="./RgSuggestClassification.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="classification-item"
    >
      <div class="classification-row-top">
        <b class="classification-name">{{ item.NO_CLASSIFICACAO }}</b>
      </div>
      <div class="classification-row-info">
        {{ item.CO_CLASSIFICACAO }}
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchClassification } from "./action";

export default {
  name: "RgSuggestClassification",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    placeholder: {
      type: String,
      default: "Digite o nome do serviço",
    },
    codeService: {
      type: String,
      default: "",
    },
    codeProcedure: {
      type: String,
      default: "",
    },
    initValue: Object,
  },
  data() {
    return {
      captionRaw: "CO_CLASSIFICACAO - NO_CLASSIFICACAO",
    };
  },
  watch: {
    initValue: {
      handler(pValue) {
        this.forceSelection(pValue);
      },
      deep: true,
    },
  },
  methods: {
    async doSearch(pSearchTerm) {
      if (pSearchTerm !== "") {
        const result = await SearchClassification({
          procedureCod: this.codeProcedure,
          serviceCod: this.codeService,
        });

        const resultFilterCode = result.filter((element) =>
          !isNaN(parseInt(pSearchTerm.slice(0, 3)))
            ? element.CO_CLASSIFICACAO.toLowerCase().includes(
                pSearchTerm.slice(0, 3).toLowerCase(),
              )
            : element,
        );

        const resultFilter = resultFilterCode.filter((element) =>
          !isNaN(parseInt(pSearchTerm.slice(0, 3)))
            ? element.NO_CLASSIFICACAO.replace(/([^\w]*)/g, "")
                .toLowerCase()
                .includes(
                  pSearchTerm
                    .replace(/([^\w]*)/g, "")
                    .slice(5)
                    .toLowerCase(),
                )
            : element.NO_CLASSIFICACAO.toLowerCase().includes(
                pSearchTerm.toLowerCase(),
              ),
        );
        return resultFilter;
      }
    },
    async forceSelection(pValue) {
      const result = await SearchClassification(this.codeService);
      const filter = await result.filter((element) => {
        if (element.CO_CLASSIFICACAO === pValue.code) {
          return element;
        }
      });
      if (filter.length > 0) {
        this.inputValue = `${filter[0].CO_CLASSIFICACAO} - ${filter[0].NO_CLASSIFICACAO}`;
      }
    },
  },
};
</script>
