<template lang="html">
  <IconWheelchairUser
    v-if="iconId === ID_ICONS.ICONWHEELCHAIRUSER"
    :fill="color"
    :size="size"
    :margin="margin"
    :stroke="stroke"
  />
  <IconVisualImpairment
    v-else-if="iconId === ID_ICONS.ICONVISUALIMPAIRMENT"
    :fill="color"
    :size="size"
    :margin="margin"
    :stroke="stroke"
  />
  <IconDeafness
    v-else-if="iconId === ID_ICONS.ICONDEAFNESS"
    :fill="color"
    :size="size"
    :margin="margin"
    :stroke="stroke"
  />
  <IconAutism
    v-else-if="iconId === ID_ICONS.ICONAUTISM"
    :fill="color"
    :size="size"
    :margin="margin"
    :stroke="stroke"
  />
</template>

<script>
import IconWheelchairUser from "./IconWheelchairUser.vue";
import IconVisualImpairment from "./IconVisualImpairment.vue";
import IconDeafness from "./IconDeafness.vue";
import IconAutism from "./IconAutism.vue";

export default {
  name: "CentralizerIcons",
  components: {
    IconWheelchairUser,
    IconVisualImpairment,
    IconDeafness,
    IconAutism,
  },
  props: {
    iconId: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "#2186d6",
    },
    size: {
      type: String,
      default: "14px",
    },
    margin: {
      type: String,
      default: "0 5px 0 0 ",
    },
    stroke: {
      type: String,
      default: "",
    },
  },
  created() {
    this.ID_ICONS = {
      ICONWHEELCHAIRUSER: "1",
      ICONVISUALIMPAIRMENT: "2",
      ICONDEAFNESS: "3",
      ICONAUTISM: "4",
    };
  },
};
</script>
