import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query UnitHealthHospitalizationQueue(
    $unitHealthId: Int
    $hasRegularPermission: Boolean
    $hasViewRequestUnitHealthPermission: Boolean
  ) {
    unitHealthHospitalizationQueue(
      uns_id: $unitHealthId
      hasRegularPermission: $hasRegularPermission
      hasViewRequestUnitHealthPermission: $hasViewRequestUnitHealthPermission
    ) {
      rows {
        uns_id
        uns_nome
      }
    }
  }
`;
export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.unitHealthHospitalizationQueue.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
