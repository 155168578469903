import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBasicAttentionEthnicity(
    $ens_descricao: String
    $ens_codigo_ab: String
    $limit: Int
  ) {
    SearchBasicAttentionEthnicity(
      ens_descricao: $ens_descricao
      ens_codigo_ab: $ens_codigo_ab
      limit: $limit
    ) {
      count
      rows {
        ens_id
        ens_codigo_ab
        ens_codigo_cadsus
        ens_descricao
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.SearchBasicAttentionEthnicity;
};
