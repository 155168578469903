<style src="./RgSuggestEmployee.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="rgSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :open-only-top="openOnlyTop"
    :rules="rules"
    :disabled="disabled"
    :maxlength="maxlength"
    min="0"
    @clear="clear"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="employee-item"
    >
      <div class="employee-row-top">
        <span class="employee-name">
          {{ item.person.cns.crs_numero }} -
          {{ item.pes_nome }}
        </span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { SearchEmployee } from "./action";

export default {
  name: "RgSuggestEmployee",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    unitHealthId: {
      type: Number,
      default: null,
    },
    occupationId: {
      type: Number,
      default: null,
    },
    dataItem: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Profissional Solicitante",
    },
    placeholder: {
      type: String,
      default: "Digite o nome do profissional",
    },
    title: {
      type: String,
      default: "Profissional",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
    },
    notNecessaryBond: {
      type: Boolean,
      default: false,
    },
    openOnlyTop: {
      type: Boolean,
      default: false,
    },
    searchOnly: {
      type: Boolean,
      default: false,
    },
    filterWithoutBond: Boolean,
  },
  data() {
    return {
      captionRaw: "person.cns.crs_numero - pes_nome",
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.rgSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.rgSuggest.validate();
    },

    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },

    async doSearch(pSearchTerm, pLimit = null) {
      if (this.searchOnly || (!this.searchOnly && pSearchTerm !== "")) {
        const variables = {
          crs_numero: undefined,
          pesName: "",
          uns_id: this.unitHealthId,
          ocp_id: this.occupationId,
          limit: pLimit,
        };

        if (!isNaN(pSearchTerm)) {
          variables.crs_numero = pSearchTerm;
        } else if (!isNaN(pSearchTerm.slice(0, 3))) {
          variables.crs_numero = pSearchTerm.split(" - ")[0];
          variables.pesName = pSearchTerm.split(" - ")[1];
        } else {
          variables.pesName = pSearchTerm;
        }

        const value = await SearchEmployee({ variables });

        return {
          rows: value.rows.map((element) => {
            return {
              ...element,
              crs_numero: element.person.cns.crs_numero,
            };
          }),
          count: value.count,
        };
      }
    },

    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.pes_nome) && pValue.vin_id > 0) {
        this.$refs.rgSuggest.itemSelected = true;

        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_id: pValue.pes_id,
            pes_nome: pValue.pes_nome,
            vin_id: pValue.vin_id,
            fun_id: pValue.fun_id,
            person: {
              cns: {
                crs_numero: pValue.person.cns.crs_numero || "000000000000000",
              },
            },
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }

      if (this.notNecessaryBond && this.notNecessaryBond === true) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            pes_id: pValue.pes_id,
            person: { cns: { crs_numero: pValue.person.cns.crs_numero } },
            ...pValue,
          });
        }

        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },

    clear() {
      this.$emit("clear");
    },
  },
};
</script>
