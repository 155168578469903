import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";
import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import AppointmentObjective from "$appointment/submodules/register/views/appointment-objective/AppointmentObjective";

export default {
  path: "/appointment/register/objective",
  name: "appointment.register.objective",
  components: {
    default: AppointmentObjective,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Cadastro", link: OpenSubModule("appointment") },
      {
        label: "Objetivos de Consulta",
        link: "/appointment/register/objective",
      },
    ],
  },
};
