<style src="./RgInputCep.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :title="title" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model="inputValue"
            v-mask="'#####-###'"
            :data-id="'CEP'"
            :placeholder="placeholder"
            :tabindex="tabIndex"
            :disabled="disabled"
            autocomplete="off"
            type="text"
            :class="{ 'on-error': hasError }"
            @blur="blur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";

import { mask } from "vue-the-mask";

export default {
  name: "RgInputCep",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "CEP",
    },
    title: {
      type: String,
      default: "Código de Endereçamento Postal",
    },
    placeholder: {
      type: String,
      default: "00.000-000",
    },
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
    hasError() {
      return this.error.length > 0;
    },
  },
  methods: {
    blur() {
      this.$emit("blur", this.value);
      this.validate();
    },
  },
};
</script>
