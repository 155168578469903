export default {
  GET_REGISTER_BILLING_BPAI: (state) => {
    return state.selected_register_billing_bpai;
  },
  GET_FILTER_BILLING_BPAI: (state) => {
    return state.filter_billing_bpai;
  },
  GET_EMPLOYEE_REGISTER_BPAI: (state) => {
    return state.selected_employee_register_bpai;
  },
};
