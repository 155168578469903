import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  PacienteConsultadoHojeException: `Este paciente já possui consulta agendada para outro profissional`,
  HorarioIndisponivelException: `Problemas ao salvar agendamento`,
};

export default async (context, { variables, permissions = {} }) => {
  const sendData = {
    arrFormData: variables,
    blnPermissaoNormal: permissions.blnPermissaoNormal || false,
    blnPermissaoReserva: permissions.blnPermissaoReserva || false,
  };
  return AuthLegacyRequest.post(
    "/consultas/controller-agendamento-consulta/agendar-multiplos-horarios",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
