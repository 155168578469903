import moment from "moment";

export default {
  RESET_HOSPITALIZATION_INFO: (state) => {
    state.hospitalization = {
      form: {
        patient: {
          pac_id: null,
        },
        hospitalization: {
          int_id: null,
          int_id_planos_saude: null,
          int_id_pacientes_internacao: null,
          int_id_filas_internacao: null,
          int_id_internacoes_tipos_responsavel: null,
          int_id_internacoes_tipos_internacao: null,
          int_id_ocupacoes_cbo: null,
          int_id_funcionarios_responsavel: null,
          int_id_funcionarios: null,
          int_id_internacoes_modalidade: null,
          int_id_cid10: null,
          int_id_internacoes_procedimentos: null,
          int_id_internacoes_caracteres: null,
          int_previsao_alta: null,
          int_data: moment().format("DD/MM/YYYY"),
          int_hora: moment().format("HH:mm"),
          int_ultima_situacao_internacao: null,
          ihs_observacao: null,
          inl_id: null,
          itl_id: 0,
        },
        companion: {
          via_nome: null,
          documents: [],
        },
        indigent: {
          ini_id: null,
          ini_descricao: null,
          ini_id_sexos: null,
          ini_data_aproximada: null,
        },
        uns_id: 0,
        is_indigent: false,
        set_id: null,
        lca_id: null,
        ihs_id: null,
      },
      auxInfo: {
        patient: {
          pes_mae: null,
          pes_pai: null,
          disabledCompanionName: false,
        },
      },
      patientInfo: {},
      indigentAge: null,
      mainCid: null,
      genderByCid: "",
      selectedTypeBed: null,
      requesterEmployee: null,
      responsibleEmployee: null,
      suggestPatient: null,
      suggestHospitalizationProcedures: null,
      cboCode: null,
      documentTypeSelected: {
        dva_id_documentos: null,
        dva_nome: null,
      },
      isl_id: 0,
      bed: null,
      lastStatusBed: null,
      documentType: null,
      dva_numero_documentos: null,
      editMode: false,
      editIndex: null,
      disableModule: false,
      disableLocale: false,
      validateAddBtn: false,
      showModalHistoryPatient: false,
      showDocInfo: 1,
    };
  },

  SET_HOSPITALIZATION_INFO: (state, hospitalization) => {
    state.hospitalization = hospitalization;
  },

  SET_HOSPITALIZATION_SOLICITATION_INFO: (state, solicitation) => {
    state.hospitalization_solicitation_data = solicitation;
  },

  RESET_HOSPITALIZATION_SOLICITATION_INFO: (state) => {
    state.hospitalization_solicitation_data = null;
  },

  SET_FILTER_HOSPITALIZATION_BED: (state, data) => {
    state.filter_hospitalization_bed_data = data;
  },

  RESET_FILTER_HOSPITALIZATION_BED: (state) => {
    state.filter_hospitalization_bed_data = null;
  },

  SET_FILTER_HOSPITALIZATION: (state, data) => {
    state.filter_hospitalization_data = data;
  },

  RESET_FILTER_HOSPITALIZATION: (state) => {
    state.filter_hospitalization_data = null;
  },

  SET_FILTER_HOSPITALIZATION_SOLICITATION: (state, data) => {
    state.filter_hospitalization_solicitation_data = data;
  },

  RESET_FILTER_HOSPITALIZATION_SOLICITATION: (state) => {
    state.filter_hospitalization_solicitation_data = null;
  },
};
