import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import HospitalizationBedSearchRoute from "$hospitalization/bed/views/bed-search/HospitalizationBedSearch.vue";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/hospitalization/bed/search",
  name: "hospitalization.bed.search",
  components: {
    default: HospitalizationBedSearchRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Leitos", link: OpenSubModule("hospitalization") },
      { label: "Lista de Leitos" },
    ],
  },
};
