import Request from "./index";
import Store from "@/store";
import _ from "lodash";
import { HandleUnauthorized } from "./error-handlers";
import { LEGACY_SERVER_CONFIG } from "~utils/Config";

/**
 * Objeto com tipos de exceptions e mensagens relacionadas customizadas
 */
export const exceptionsErrors = {
  ErroDesconhecido: "Erro desconhecido no Backend Legado",
  SemPermissaoEscalasAgendamentoException:
    "Sem permissão para a escala de agendamento.",
  SessaoExpiradaException:
    "Sua sessão possívelmente expirou ou você não tem permissão para executar essa ação. Por favor, realize o login novamente.",
  PacienteConsultadoHojeException:
    "Não foi possível agendar, pois este paciente já possui consulta nessa data.",
};

/**
 *
 * @param {*} responseData os dados da resposta
 * @param {*} extraExceptionsErrors Objeto relacionando tipos de exceptions do backend com mensagens customizadas para serem sobrescritas pelas mensagens definidas em exceptionsErrors
 */
export const getErrorMessage = (responseData, extraExceptionsErrors = {}) => {
  const allErrors = Object.assign({}, exceptionsErrors, extraExceptionsErrors);

  const exceptionType = responseData.dados;

  const trace = responseData.trace;
  const isTraceDifferentString = typeof exceptionType !== "string";

  if (isTraceDifferentString) {
    return allErrors.ErroDesconhecido;
  }

  const isDataArray = Array.isArray(trace);

  if (isDataArray) {
    return responseData.dados;
  }

  /**
   * tenta pegar uma mensagem em linguagem natural vinda do backend na variavel trace caso exista e nao exista
   * uma mensagem customizada relacionada utiliza a mesma
   *
   * */

  const isNotInAllErrors = !allErrors[exceptionType];
  const isTraceTypeString = typeof trace === "string";
  const isTraceMoreOneWord = trace.split(" ").length > 0;

  if (isNotInAllErrors && isTraceTypeString && isTraceMoreOneWord) {
    return responseData.trace;
  }

  /**
   * retorna uma mensagem customizada ou uma msg padrao
   */

  return allErrors[exceptionType] || allErrors.ErroDesconhecido;
};

/**
 *
 * @param {*} responseData
 * @throws Error Caso encontre algo errado na requisição lança um erro
 */
export const HandleResponse = (
  responseData,
  { extraExceptionsErrors = {} } = {},
) => {
  const { dados } = responseData;
  if (Number.isInteger(Number(dados))) {
    responseData.dados = Number(dados);
  }

  const hasResponseData = Object.keys(responseData)?.length > 0;

  if (!hasResponseData) {
    throw new Error("Não foi possível realizar a operação");
  }

  const hasExceptions =
    Array.isArray(responseData.detalhes_exececao) &&
    responseData.detalhes_exececao.length > 0;

  const isExceptionTypeOnDados =
    typeof responseData.dados === "string" &&
    responseData.dados.indexOf(" ") === -1;

  if (responseData.status === true) {
    if (isExceptionTypeOnDados || hasExceptions) {
      const { dados, trace } = responseData;

      let exceptionName = "";
      let hasError = false;

      const dataIsNotNumber = isNaN(Number(dados));

      if (dataIsNotNumber) {
        const exceptionsName = Object.keys(extraExceptionsErrors);

        hasError = true;

        exceptionName = exceptionsName.includes(dados)
          ? dados
          : "Generic Exception";
      }
      let message = getErrorMessage(responseData, extraExceptionsErrors);

      if (message.includes("Error:")) {
        message = message.substring(6, message.length);
      }

      const e = Error(message);

      responseData.hasError = hasError;
      responseData.message = message || trace;
      responseData.exception = exceptionName;

      e.esus_response = responseData;

      throw e;
    }
  } else if (responseData.status === false) {
    let message = getErrorMessage(responseData, extraExceptionsErrors);

    if (message.includes("Error:")) {
      message = message.substring(6, message.length);
    }
    responseData.message = message;
    throw Error(responseData.message);
  }

  return responseData;
};

const request = new Request(LEGACY_SERVER_CONFIG);

request.getHeaders = (headers) => {
  headers.authorization = Store.getters["Login/GET_LEGACY_TOKEN"];
  headers.unithealth = Store.getters["Login/GET_UNIT_HEALTH_ID"];
  headers["Content-Type"] = headers["Content-Type"] || "application/json";
  return headers;
};

request._handleErrorResponse = function (error) {
  if (!error.response) {
    return this._noResponse(error);
  }
  if (error.response.data.errors) {
    error.response.errors = error.response.data.errors;
    delete error.response.data;
  }
  const exceptionType = _.get(
    error.response,
    "errors.exception_type",
    "ErroDesconhecido",
  );
  const errorMessage =
    exceptionsErrors[exceptionType] || exceptionsErrors.ErroDesconhecido;

  if (exceptionType === "SessaoExpiradaException") {
    HandleUnauthorized();
  }

  error.message = errorMessage;
  error.response.message = errorMessage;
  return error.response;
};

request.onunauthorized = () => {
  HandleUnauthorized();
};
export default request;
