import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

const arrayExceptions = [
  "ConsultaEfetivadaException",
  "ProntuarioEncaminhadoException",
  "QuantidadeLimiteDeConsultasException",
  "LimiteAtendimentosExcedidoException",
  "LimiteHorarioAtendimentosExcedidoException",
  "HorarioIndisponivelException",
  "ConsultaException",
  "ConsultaPeriodoCarenciaException",
];

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "consultas/controller-marcacao-consulta/transferir-consultas",
    variables,
  )
    .then(({ data }) => {
      if (data?.dados) {
        if (arrayExceptions.includes(data.dados)) {
          return {
            status: false,
            msg: data.trace,
          };
        }
        return {
          status: data.dados,
          msg: data.trace,
        };
      } else {
        return {
          status: false,
          msg: "Problemas ao realizar transferência",
        };
      }
    })
    .catch((pErr) => {
      const NETWORK_ERROR = "Network Error";
      console.log(pErr);

      const errorMessage = pErr.message || pErr;

      const message = errorMessage.includes(NETWORK_ERROR)
        ? "Erro ao transferir"
        : "Problemas ao realizar transferência";

      throw new Error(message);
    });
};
