import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, appointmentId) => {
  return AuthLegacyRequest.post(
    "pacientes/controller-paciente/buscar-detalhes-historico-consulta-paciente",
    { idConsulta: appointmentId },
  )
    .then(({ data }) => {
      data = HandleResponse(data);

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
