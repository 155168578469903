<template lang="html">
  <div v-if="show" class="modal-solicitation-details">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Detalhes da Solicitação de Internação</div>
      </div>

      <div slot="body" class="body">
        <div class="informations">
          <RgTypePatient
            :pac-id="Number(isPatient)"
            :sex="isPatient ? patientGender : null"
            class="patient-type"
          />

          <div class="area">
            <span class="data">
              Paciente:
              <strong>
                {{ getValue(solicitationData, "paciente") }}
              </strong>
            </span>

            <span class="data">
              Boletim:
              <strong>
                {{ getValue(solicitationData, "bol_numero") }}
              </strong>
            </span>
          </div>

          <div class="area">
            <span class="data">
              Unidade de Saúde:
              <strong>
                {{ getValue(solicitationData, "uns_nome") }}
              </strong>
            </span>

            <span class="data">
              Setor:
              <strong>
                {{ getValue(solicitationData, "set_nome") }}
              </strong>
            </span>
          </div>

          <div class="area">
            <span class="data">
              Local de Atendimento:
              <strong>
                {{ getValue(solicitationData, "lca_nome") }}
              </strong>
            </span>
          </div>
        </div>

        <FormBase title="Solicitação de Internação" class="area">
          <div class="content-info">
            <span>
              Ocupação:
              <b>{{ getValue(solicitationData, "ocp_nome") }}</b>
            </span>

            <span class="ellipsis">
              Profissional Solicitante:
              <b>{{ getValue(solicitationData, "profissional") }}</b>
            </span>

            <span class="ellipsis">
              Caráter da Internação:
              <b>{{ getValue(solicitationData, "ica_nome") }}</b>
            </span>
            <span class="ellipsis">
              CID:
              <b>{{ getValue(solicitationData, "cid_nome") }}</b>
            </span>
            <span class="procedure">
              Procedimento Solicitado:
              <b>{{
                solicitationData.inp_novo_nome_procedimento
                  ? getValue(solicitationData, "inp_novo_nome_procedimento")
                  : getValue(solicitationData, "ins_nome_procedimento_pep")
              }}</b>
            </span>
          </div>

          <RgTextArea
            id="justification-hospitalization"
            v-model="solicitationData.ins_justificativa_internacao"
            :viewCharacters="false"
            :disabled="true"
            class="text-area"
            label="Justificativa da Internação"
          />
          <RgTextArea
            id="symptoms"
            v-model="solicitationData.ins_sinais_sintomas"
            :viewCharacters="false"
            :disabled="true"
            class="text-area"
            label="Condições que Justificam a Internação"
          />
          <RgTextArea
            id="results"
            v-model="solicitationData.ins_resultados"
            :viewCharacters="false"
            :disabled="true"
            class="text-area"
            label="Principais Resultados de Provas Diagnósticas (Resultados de Exames Realizados)"
          />
          <RgTextArea
            id="diagnosis"
            v-model="solicitationData.ins_diagnostico_inicial"
            :viewCharacters="false"
            :disabled="true"
            class="text-area"
            label="Diagnóstico Inicial"
          />
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgBaseModal, RgTextArea } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient";

export default {
  name: "ModalSolicitationDetails",
  components: {
    RgBaseModal,
    RgTextArea,
    FormBase,
    RgTypePatient,
  },
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    solicitationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      personData: null,
      patientGender: null,
    };
  },

  computed: {
    isPatient() {
      return this.solicitationData && this.solicitationData.pac_id
        ? this.solicitationData.pac_id
        : 0;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.getSexPatient();
      }
    },
  },

  methods: {
    getValue(pItem, pKey) {
      return pItem[pKey] || "Não informado";
    },

    getSexPatient() {
      this.patientGender = this.solicitationData.pep_sexo
        ? this.solicitationData.pep_sexo === "M"
          ? 1
          : this.solicitationData.pep_sexo === "F"
          ? 2
          : null
        : null;
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
