export default {
  SET_FILTER_ADMINISTRATIVE_CHECK_OUT: (state, data) => {
    state.filter_administrative_check_out_data = data;
  },
  RESET_FILTER_ADMINISTRATIVE_CHECK_OUT: (state) => {
    state.filter_administrative_check_out_data = null;
  },
  SELECT_ADMINISTRATIVE_CHECK_OUT: (state, data) => {
    state.selected_administrative_check_out_data = data;
  },
  UNSELECT_ADMINISTRATIVE_CHECK_OUT: (state) => {
    state.selected_administrative_check_out_data = null;
  },
};
