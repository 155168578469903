import HospitalizationDetail from "$billing/submodules/aih/hospitalization-billing/views/hospitalization-billing-detail/HospitalizationBillingDetail";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

export default {
  path: "/billing/hospitalization-billing/detail",
  name: "billing.hospitalization-billing.detail",
  components: {
    default: HospitalizationDetail,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      {
        label: "AIH",
        link: OpenSubModule("billing"),
      },
      {
        label: "Internação AIH",
        link: "/billing/hospitalization-billing/search",
      },
      { label: "Nova AIH", link: "/billing/hospitalization-billing/detail" },
    ],
  },
};
