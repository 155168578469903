<template lang="html">
  <svg
    width="409"
    height="261"
    viewBox="0 0 409 261"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M404 119.9C390.5 102.7 375.8 86.6001 359.6 71.9001C331.8 46.5001 301.4 25.2001 266 11.8001C235.1 0.100072 203.4 -3.19993 170.9 3.60007C141.6 9.70007 115.2 22.5001 90.6 39.0001C59 60.1001 31.8 86.2001 7.8 115.7C4.7 119.5 1.6 123.3 0 128C0 129.6 0 131.2 0 132.8C1.2 135.3 2.1 137.9 3.9 140.1C37.1 182 75.7 217.4 124 241.2C147 252.5 171.2 259.5 196.9 260.7C234.6 262.4 268.9 251.1 301.1 232.5C341.6 209 375.1 177.6 403.9 141C410.2 133.2 410.2 127.7 404 119.9ZM376.2 132.7C348.3 165.3 316.9 193.7 278.8 214C256 226.1 228.3 233.8 199.1 233.9C183.2 234.1 163 228.8 143.6 220.3C109.1 205.3 80.1 182.4 53.8 155.8C46.4 148.3 39.3 140.6 32.5 132.6C31.1 131 31 130 32.4 128.3C60.4 95.4001 91.9 66.7001 130.5 46.6001C155.6 33.6001 182.1 25.7001 210.8 27.1001C229.9 28.0001 248 33.0001 265.4 40.6001C299.8 55.6001 328.6 78.4001 354.9 104.8C362.3 112.3 369.4 120 376.2 128C377.6 129.9 377.7 130.9 376.2 132.7Z"
      fill="#707070"
    />
    <path
      d="M204.5 49.5001C160 49.3001 123.7 85.5001 123.6 130.3C123.5 175 159.7 211.3 204.3 211.3C248.9 211.3 285.2 174.9 285.1 130.3C285 85.8001 249.1 49.8001 204.5 49.5001ZM204.3 184.4C174.7 184.3 150.7 160.4 150.5 130.6C150.2 101 174.7 76.4001 204.4 76.4001C234 76.4001 258 100.4 258.2 130.2C258.4 160 234 184.5 204.3 184.4Z"
      fill="#707070"
    />
  </svg>
</template>

<script>
export default {
  name: "RgView",
};
</script>
