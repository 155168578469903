import { render, staticRenderFns } from "./RgSurgeryKitTypeForm.vue?vue&type=template&id=0ca22a43&scoped=true&lang=html&"
import script from "./RgSurgeryKitTypeForm.vue?vue&type=script&lang=js&"
export * from "./RgSurgeryKitTypeForm.vue?vue&type=script&lang=js&"
import style0 from "./RgSurgeryKitTypeForm.scss?vue&type=style&index=0&id=0ca22a43&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ca22a43",
  null
  
)

export default component.exports