import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchAppointmentPriority($priority: String) {
    appointmentPriority(priority: $priority) {
      rows {
        fcp_id_prioridades_fila
        fcp_prioridades_fila
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.appointmentPriority.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
