import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveBulletinBilling(
    $bulletinBilling: BulletinBillingInputType
    $patientBulletinBilling: PatientBulletinBillingInputType
  ) {
    SaveBulletinBilling(
      bulletinBilling: $bulletinBilling
      patientBulletinBilling: $patientBulletinBilling
    ) {
      bof_id
      bof_nome
      bof_id_boletins
      bof_id_unidades_saude
      bof_registro_geral
      bof_cns
      bof_sexo
      bof_data_nascimento
      bof_cod_nacionalidade
      bof_cod_raca
      bof_cod_etnia
      bof_cep
      bof_cod_municipio
      bof_cod_tipo_logradouro
      bof_endereco
      bof_endereco_numero
      bof_endereco_complemento
      bof_bairro
      bof_tel
      bof_tel_ddd
      bof_ddd_telefone
      bof_email
      bof_cnes
      bof_competencia
      bof_faturado
    }
  }
`;

export default async ({ commit }, { variables }) => {
  const { data } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data;
};
