<template>
  <section v-if="show" class="modal-view-attendances">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <section slot="header" class="modal-view-attendances-header">
        <div class="title">Visualizar Atendimentos BPA-C</div>
      </section>

      <section slot="body" class="modal-view-attendances-body">
        <FormBase title="Dados dos Atendimentos" class="form-base">
          <div class="form-data">
            <div class="container-data">
              <span class="content">
                Procedimento:
                <strong> {{ selectedBPAC.procedimento_nome }} </strong>
              </span>
              <span class="content">
                Quantidade:
                <strong> {{ selectedBPAC.quantidade_total }} </strong>
              </span>
            </div>
            <hr />
            <div class="table-attendances">
              <SmartTable
                ref="smartTable"
                name="ListAttendances"
                :columns="COLUMNS"
                :body="mutableBPAC"
                :total="Number(mutableBPAC.length)"
                :show-pagination="false"
                removeBtnColumns
                dontSelect
                class="smart-table"
              />
            </div>
          </div>
        </FormBase>
      </section>
    </RgBaseModal>
  </section>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import { FormBase, SmartTable } from "~tokio/foundation";
export default {
  name: "ModalViewAttendances",
  components: {
    RgBaseModal,
    FormBase,
    SmartTable,
  },
  props: {
    show: {
      type: Boolean,
      dafault: false,
    },
    selectedBPAC: {
      type: Object,
      dafault: () => {},
    },
  },
  data() {
    return {
      mutableBPAC: [],
    };
  },
  computed: {},
  watch: {
    show(pValue) {
      if (pValue) {
        this.loadAttendance(this.selectedBPAC);
      } else {
        this.close();
      }
    },
  },
  created() {
    this.COLUMNS = [
      {
        name: "Paciente",
        key: "pes_nome",
        align: "left",
        active: true,
      },
      {
        name: "Data de nascimento",
        key: "pes_nascimento",
        align: "right",
        active: true,
      },
      {
        name: "Sexo",
        key: "sex_nome",
        align: "left",
        active: true,
      },
      {
        name: "CNS do Paciente",
        key: "crs_numero",
        align: "left",
        active: true,
      },
      {
        name: "Profissional",
        key: "nomeFuncionario",
        align: "left",
        active: true,
      },
      {
        name: "Atendimento",
        key: "data_atendimento",
        align: "right",
        active: true,
      },
      { name: "CBO", key: "ocp_codigo", align: "right", active: true },
      {
        name: "Quantidade",
        key: "ppd_quantidade",
        align: "right",
        active: true,
      },
    ];
  },
  methods: {
    async loadAttendance(pValue) {
      try {
        this.$loader.start();
        const variables = {
          ...pValue,
        };

        const result = await this.$store.dispatch(
          "Billing/Review/GET_ATTENDANCES_DATA",
          variables,
        );

        this.mutableBPAC = result.GetAttendancesData.rows;
        this.countItem = result.GetAttendancesData.count;
      } catch (pErr) {
        this.$toaster.error("Não foi possível realizar a busca do atendimento");
      } finally {
        this.$loader.finish();
      }
    },
    close() {
      this.mutableBPAC = [];
      this.$emit("close");
    },
  },
};
</script>
