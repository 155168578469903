<template>
  <div class="exam-patients-pendency-queue-report">
    <section class="grid">
      <div class="filter-title">
        <span class="title"> Dados do Relatório Selecionado </span>
      </div>
      <RgSelectProceduresQueueExam
        id="procedure"
        ref="procedure"
        v-model="form.procedureId"
        :rules="{ required: true }"
        class="inputitem"
        label="Procedimento"
        default-text="Todos"
        hasRegularPermission
        openOnlyBottom
        @change="selectProcedureQueue"
      />

      <RgSelectSubproceduresQueueByUnitExam
        id="subprocedure"
        v-model="form.subprocedureId"
        :procedureName="form.procedureName"
        :disabled="!form.procedureId"
        class="inputitem"
        label="Subprocedimento"
        default-text="Todos"
        openOnlyBottom
        @change="selectSubProcedureQueue"
      />

      <RgSelectUnithealthQueue
        id="unit"
        v-model="form.unitHealthRequest"
        label="Unidade de Saúde Solicitante"
        :module-id="examModuleId"
        :default-text="'Todas'"
        hasRegularPermission
        openOnlyBottom
        @change="selectingUnitHealthRequest"
      />
      <div class="filter-title">
        <span class="title"> Período de Inclusão na Fila </span>
      </div>
      <div class="period-date">
        <RgInputDate
          id="initial-date-queue"
          ref="queuePeriodDateStart"
          v-model="form.queuePeriodDateStart"
          :rules="{ fn: verifyQueuePeriodDate }"
          label="Data Inicial"
          popUp="top"
          :max-date="new Date()"
        />
        <RgInputDate
          id="final-date-queue"
          ref="queuePeriodDateEnd"
          v-model="form.queuePeriodDateEnd"
          label="Data Final"
          popUp="top"
          :max-date="new Date()"
        />
      </div>
      <div class="filter-title">
        <span class="title"> Período do Pedido </span>
      </div>
      <div class="period-date">
        <RgInputDate
          id="initial-request-date"
          ref="requestPeriodDateStart"
          v-model="form.requestPeriodDateStart"
          :rules="{ fn: verifyRequestPeriodDate }"
          label="Data Inicial"
          popUp="top"
          :max-date="new Date()"
        />
        <RgInputDate
          id="final-request-date"
          ref="requestPeriodDateEnd"
          v-model="form.requestPeriodDateEnd"
          label="Data Final"
          popUp="top"
          :max-date="new Date()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";

import { RgSelectUnithealthQueue, RgInputDate } from "~tokio/primitive";

import {
  RgSelectProceduresQueueExam,
  RgSelectSubproceduresQueueByUnitExam,
} from "$exam/submodules/schedule/component/";

const FORM_FILTER = {
  procedureId: null,
  procedureName: "",
  subprocedureName: "",
  subprocedure: null,
  unitHealthRequest: null,
  unitHealthRequestName: null,
  requestPeriodDateStart: null,
  requestPeriodDateEnd: null,
  queuePeriodDateStart: null,
  queuePeriodDateEnd: null,
  columnsToPrint: null,
};

export default {
  name: "ExamPatientsPendencyQueueReport",
  components: {
    RgSelectProceduresQueueExam,
    RgSelectSubproceduresQueueByUnitExam,
    RgSelectUnithealthQueue,
    RgInputDate,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Pacientes na Fila com Pendência",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },
  computed: {
    examModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; }" +
        ".report-column-2 { white-space: normal !important; text-align: left; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; }" +
        ".report-column-5 { white-space: normal !important; }" +
        ".report-column-6 { white-space: normal !important; }" +
        ".report-column-7 { white-space: normal !important; text-align: left; }" +
        ".report-column-8 { white-space: normal !important; text-align: left; }" +
        ".report-column-9 { white-space: normal !important; text-align: left; }"
      );
    },
    getNameSmartTable() {
      return "ExamPatientsPendencyQueueReport";
    },
    initialColumns() {
      return 9;
    },
    columnTable() {
      return this.COLUMN;
    },
  },
  created() {
    this.COLUMN = [
      { name: "Data de Inclusão na Fila", key: "DATA_INCLUSAO_FILA" },
      {
        name: "Unidade de Saúde Solicitante",
        key: "UNIDADE_SAUDE_SOLICITANTE",
        align: "left",
      },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Mãe", key: "MAE", align: "left" },
      { name: "Nascimento", key: "NASCIMENTO" },
      { name: "CNS", key: "CNS" },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      {
        name: "SubProcedimento",
        key: "SUBPROCEDIMENTO",
        align: "left",
        concat: ",",
      },
      { name: "Pendência", key: "PENDENCIA", align: "left" },
    ];
  },
  methods: {
    async doSearch(pData) {
      try {
        this.$loader.start();

        return await this.$store.dispatch(
          "Exam/Report/GET_EXAM_PATIENTS_PENDENCY_QUEUE_REPORT",
          pData,
        );
      } catch (error) {
        this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish();
      }
    },
    buildFilter() {
      const variables = {
        procedureName: this.form.procedureName,
        subProcedureName: this.form.subprocedureName,
        unitHealthRequest: this.form.unitHealthRequest,
        unitHealthRequestName: this.form.unitHealthRequestName,
        requestPeriodDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.requestPeriodDateStart,
        ),
        requestPeriodDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.requestPeriodDateEnd,
        ),
        queuePeriodDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.queuePeriodDateStart,
        ),
        queuePeriodDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.queuePeriodDateEnd,
        ),
        columnsToPrint: this.columnsToPrint,
      };

      return variables;
    },
    selectProcedureQueue(pProcedure) {
      if (pProcedure) {
        const procedureName = pProcedure.procedure || pProcedure.label;
        this.form.procedureName = procedureName;
      } else {
        this.form.subprocedureId = null;
        this.form.procedureName = null;
      }
    },
    selectSubProcedureQueue(pSubProcedure) {
      if (pSubProcedure) {
        this.form.subprocedureName = pSubProcedure.label;
      } else {
        this.form.subprocedureName = null;
      }
    },
    selectingUnitHealthRequest(pValue) {
      if (pValue) {
        this.form.unitHealthRequestName = pValue.label;
      } else {
        this.form.unitHealthRequestName = null;
      }
    },
    verifyQueuePeriodDate(pValue, pErrors) {
      const initialDate = moment(this.form.queuePeriodDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.queuePeriodDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    verifyRequestPeriodDate(pValue, pErrors) {
      const initialDate = moment(
        this.form.requestPeriodDateStart,
        "DD/MM/YYYY",
      );
      const finalDate = moment(this.form.requestPeriodDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "procedure",
        "requestPeriodDateStart",
        "requestPeriodDateEnd",
        "queuePeriodDateStart",
        "queuePeriodDateEnd",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
