import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchProceduresExam(
    $procedureName: String
    $procedureCode: String
    $limit: Int
    $offset: Int
  ) {
    SearchProceduresExam(
      procedureName: $procedureName
      procedureCode: $procedureCode
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        stp_novo_nome_procedimento
        stp_novo_codigo_procedimento
      }
    }
  }
`;

export default async ({ variables }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.SearchProceduresExam.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
