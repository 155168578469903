import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation ManageCpfSchedulingPatient($pes_id: Int, $cpf: CpfInputType) {
    ManageCpfSchedulingPatient(pes_id: $pes_id, cpf: $cpf) {
      success
      message
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.mutate({ mutation, variables }).catch(
    (err) => {
      throw err;
    },
  );
  return data;
};
