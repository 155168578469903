var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rg-save-button"},[_c('RgButton',{staticClass:"button",class:{
      'rg-save-button -small': _vm.small,
      'rg-save-button -medium': _vm.medium,
      'rg-save-button -large': _vm.large,
      'rg-save-button -xlarge': _vm.xlarge,
    },style:({
      background: _vm.backgroundColor,
    }),attrs:{"id":_vm.id,"title":_vm.title,"data-id":_vm.dataId,"permission":_vm.permission,"disabled":_vm.disabled,"type":"button"},on:{"click":_vm.clicked}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.medium || _vm.large),expression:"medium || large"}],staticClass:"text"},[_c('span',[_vm._v(_vm._s(_vm.label))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.large),expression:"large"}],staticClass:"separator"}),(_vm.showNormalCaption && !_vm.medium)?_c('div',{staticClass:"icon-check"},[_c('IconCheck')],1):_vm._e(),(_vm.showWaiting && !_vm.medium)?_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"icon-spinner"})]):_vm._e(),(_vm.showDone && !_vm.medium)?_c('div',{staticClass:"icon-check"},[_c('IconCheck')],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }