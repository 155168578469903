import { render, staticRenderFns } from "./SurgeryMaterial.vue?vue&type=template&id=0924c8fe&scoped=true&lang=html&"
import script from "./SurgeryMaterial.vue?vue&type=script&lang=js&"
export * from "./SurgeryMaterial.vue?vue&type=script&lang=js&"
import style0 from "./SurgeryMaterial.scss?vue&type=style&index=0&id=0924c8fe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0924c8fe",
  null
  
)

export default component.exports