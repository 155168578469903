<template>
  <FoundationSearchSchedules :module-type="2" :printHtml="printHtml" />
</template>

<script>
import FoundationSearchSchedules from "~tokio/foundation/pages/patient/foundation-search-schedules/FoundationSearchSchedules";

import PatientListHTML from "$exam/submodules/schedule/html/search-patient-schedule/PatientListHTML";
import ScheduleVoucherHTML from "$exam/submodules/schedule/html/search-patient-schedule/ScheduleVoucherHTML";

export default {
  name: "ExamSearchPatientSchedule",
  components: {
    FoundationSearchSchedules,
  },

  data() {
    return {};
  },

  computed: {
    printHtml() {
      return {
        patientsList: (payload, row) => this.actPatientListHTML(payload, row),

        scheduleVoucher: (payload, row) =>
          this.actScheduleVoucherHTML(payload, row),
      };
    },
  },

  methods: {
    async actPatientListHTML(payload, row) {
      const snapPrint = await this.$store.dispatch(
        "Exam/Schedule/PRINT_PATIENT_SCHEDULE_LIST",
        payload,
      );
      const obj = {
        ...snapPrint,
        ...row,
      };
      return PatientListHTML(obj);
    },

    async actScheduleVoucherHTML(payload, row) {
      await this.$store.dispatch(
        "Exam/Schedule/REGISTER_SCHEDULE_VOUCHER_BATCH",
        payload.ids,
      );

      const snapPrint = await this.$store.dispatch(
        "Exam/Schedule/GET_PRINT_SCHEDULE_BATCH",
        payload,
      );

      const obj = {
        ...snapPrint[0],
        ...row,
      };
      return ScheduleVoucherHTML(obj);
    },
  },
};
</script>
