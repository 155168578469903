<template>
  <Modulebox
    ref="modulebox"
    :title="
      isEditing
        ? 'Editar Solicitação de Produtos e Medicamentos'
        : 'Solicitar Produtos e Medicamentos'
    "
    class="pharmacy-satellite-request"
  >
    <div class="pharmacy-satellite-request-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Dados do Atendimento">
          <section class="pharmacy-satellite-request-origin">
            <RgSelectUnithealthPharmacy
              ref="unitHealthOrigin"
              v-model="form.unitHealthOrigin"
              label="Unidade de Saúde (Origem)"
              :rules="{ required: true }"
              :disabled="isEditing || disabledByModal"
              :class="{ disable: isEditing }"
              @change="selectedUnitHealthOrigin"
            />
            <RgSelectPharmacy
              ref="pharmacyOrigin"
              v-model="form.pharmacyOrigin"
              label="Farmácia (Origem)"
              byUser
              :unitHealthId="form.unitHealthOrigin"
              :userId="userLoginId"
              :typePharmacy="SATELLITE_PHARMACY_TYPE"
              :rules="{ required: true }"
              :disabled="!form.unitHealthOrigin || isEditing || disabledByModal"
              :class="{ disable: !form.unitHealthOrigin || isEditing }"
              @change="selectedPharmacyOrigin"
            />
            <div></div>
            <RgInputDate
              ref="requestDate"
              v-model="form.requestDate"
              label="Data da Solicitação"
              :rules="{ required: true }"
              :disabled="true"
              class="request-date"
              :class="{ disable: true }"
            />
          </section>
          <hr class="separator" />
          <section class="pharmacy-satellite-request-destination">
            <RgSelectUnithealthPharmacy
              ref="unitHealthDestiny"
              v-model="form.unitHealthDestiny"
              label="Unidade de Saúde (Destino)"
              :rules="{ required: true }"
              :disabled="isEditing || disabledByModal"
              :class="{ disable: isEditing }"
              @change="selectedUnitHealthDestiny"
            />
            <RgSelectPharmacy
              ref="pharmacyDestiny"
              v-model="form.pharmacyDestiny"
              label="Farmácia (Destino)"
              :unitHealthId="form.unitHealthDestiny"
              :typePharmacy="CENTRAL_PHARMACY_TYPE"
              :rules="{ required: true }"
              :disabled="
                !form.unitHealthDestiny || isEditing || disabledByModal
              "
              :class="{ disable: !form.unitHealthDestiny || isEditing }"
              @change="selectedPharmacyDestiny"
            />
            <div></div>
            <RgInput
              ref="requestNumber"
              v-model="form.requestNumber"
              :rules="{ required: true }"
              :disabled="true"
              :class="{ disable: true }"
              label="Número da Solicitação"
            />
          </section>
        </FormBase>

        <FormBase title="Produtos e Medicamentos">
          <section class="pharmacy-satellite-request-products">
            <Tooltip
              message="Só é possível incluir “Observações” no pedido após incluir um produto/medicamento."
              class="tooltip-info"
              startOpen
            >
              <IconLightBulbHelper slot="icon" />
            </Tooltip>
            <div class="pharmacy-satellite-request-products-form">
              <div class="form">
                <RgSelectProduct
                  ref="product"
                  v-model="form.product"
                  perClient
                  :disabled="disabledByModal"
                  :rules="{ required: true }"
                  @change="selectedProduct"
                />
                <RgSelectTypePresentation
                  ref="typePresentation"
                  v-model="form.typePresentation"
                  label="Apresentação"
                  :rules="{ required: true }"
                  :disabled="true"
                  :class="{ disable: true }"
                />
                <div></div>
                <RgInputNumber
                  ref="requestedQuantity"
                  v-model="form.requestedQuantity"
                  :rules="{ required: true, fn: validateAmount }"
                  :maxlength="12"
                  label="Quantidade Solicitada"
                  placeholder="000000000"
                  :disabled="disabledByModal"
                />
              </div>
              <div class="actions">
                <RgCleanButton
                  :disabled="disabledByModal"
                  @click="openModalConfirmField"
                />
                <RgAddButton
                  id="add-btn"
                  title="Adicionar Produto/Medicamento"
                  :disabled="disabledByModal"
                  @click="saveRequest"
                />
              </div>
            </div>

            <hr class="separator" />

            <div class="table">
              <SmartTable
                ref="smartTable"
                name="PharmacyProductsRequest"
                :columns="COLUMNS"
                :body="mutableProductList"
                :total="mutableProductList.length"
                :showPagination="false"
                :initial-columns="7"
                toggleSelected
                @getLine="selectLine"
              >
                <div slot="top-buttons" class="top-buttons">
                  <RgLessButton
                    id="delete-btn"
                    title="Excluir Produto/Medicamento selecionado"
                    :disabled="!hasButtonSelectedTable || disabledByModal"
                    @click="openModalDeleteProduct"
                  />
                  <RgPrinterButton
                    title="Imprimir Comprovante de Solicitação"
                    :disabled="mutableProductList.length < 1 || disabledByModal"
                    @click="printerRequest"
                  />
                </div>
              </SmartTable>
            </div>
          </section>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="pharmacy-satellite-request-footer">
      <LargeButton
        backgroundColor="#1E88A9"
        label="Observações"
        separator
        :disabled="mutableProductList.length < 1 || disabledByModal"
        @click="openModalObservationRequest"
      >
        <IconObservation slot="icon" class="icon" />
      </LargeButton>
      <MediumButton
        :disabled="disabledByModal"
        title="Voltar"
        label="Voltar"
        @click="goBack"
      />
    </div>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteProduct"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmField"
      @getYes="cleanForm"
      @getOut="closeModalConfirmField"
      @close="closeModalConfirmField"
    />
    <ModalObservationRequest
      :show="showModalObservationRequest"
      :requestData="mutableProductList"
      @close="closeModalObservationRequest"
    />
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInputDate,
  RgInputNumber,
  RgInput,
  RgAddButton,
  RgCleanButton,
  RgLessButton,
  RgPrinterButton,
  MediumButton,
  Tooltip,
  IconLightBulbHelper,
  LargeButton,
  IconObservation,
  ModalConfirmDeletion,
  ModalConfirmFieldClearing,
} from "~tokio/primitive";

import moment from "moment";

import { mapGetters } from "vuex";
import { Modulebox } from "~tokio/foundation";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";

import ModalObservationRequest from "$pharmacy/submodules/satellite/components/modal/modal-observation-request/ModalObservationRequest";

import RequestHTML from "$pharmacy/submodules/satellite/html/RequestHTML";

const FORM = {
  unitHealthOrigin: null,
  unitHealthDestiny: null,
  product: null,
  requestedQuantity: null,
  requestDate: moment().format("DD/MM/YYYY"),
  requestNumber: "0",
  pharmacyOrigin: false,
  pharmacyDestiny: null,
  mpd_codigo: null,
};

export default {
  name: "PharmacySatelliteRequest",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectUnithealthPharmacy,
    RgSelectTypePresentation,
    RgInputDate,
    RgInputNumber,
    RgInput,
    RgCleanButton,
    RgAddButton,
    RgLessButton,
    RgPrinterButton,
    MediumButton,
    SmartTable,
    Tooltip,
    IconLightBulbHelper,
    LargeButton,
    IconObservation,
    ModalObservationRequest,
    ModalConfirmDeletion,
    ModalConfirmFieldClearing,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableProductList: [],
      selectedRow: {},
      requestData: null,
      isEditing: false,
      hasButtonSelectedTable: false,
      showModalObservationRequest: false,
      modalDeleteMovement: false,
      modalConfirmField: false,
      unitHealthOrigin: null,
      pharmacyOrigin: null,
      unitHealthDestiny: null,
      pharmacyDestiny: null,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      clientName: "Login/GET_CLIENT_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
      userName: "Login/GET_USER_NAME",
    }),
    propsModalConfirmDeletion() {
      const confirm = () => this.inactiveOrderedItem();

      const show = this.modalDeleteMovement;
      const noReason = true;

      const title = `Excluir Produto do Registro de Solicitação`;
      const message = `Deseja remover o produto/medicamento?`;
      const msgSuccess = "Solicitação excluída com sucesso";
      const bodyText = `${this.selectedRow?.product} - ${this.selectedRow?.typePresentation} - ${this.selectedRow?.request}`;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
      };
    },
    disabledByModal() {
      return (
        this.modalDeleteMovement ||
        this.modalConfirmField ||
        this.showModalObservationRequest
      );
    },
  },

  watch: {
    "form.unitHealthOrigin"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacyOrigin = null;
        this.form.product = null;
      }
    },
    "form.unitHealthDestiny"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacyDestiny = null;
        this.form.product = null;
      }
    },
  },

  async mounted() {
    const isEditing = this.$route.name === "pharmacy.satellite.edit-request";

    if (isEditing) {
      const requestData = this.$route.params;

      this.isEditing = true;
      this.mountBulletin(requestData);
    } else {
      await this.fillFilter();
    }
  },

  created() {
    this.COLUMNS = [
      { name: "Código", key: "code", align: "left", active: "true" },
      { name: "Nome", key: "product", align: "left", active: "true" },
      {
        name: "Apresentação",
        key: "typePresentation",
        align: "left",
        active: "true",
      },
      { name: "Tipo", key: "type", align: "left", active: "true" },
      {
        name: "Quant. Solicitada",
        key: "request",
        align: "left",
        active: "true",
      },
      {
        name: "Quant. Entregue",
        key: "delivered",
        align: "left",
        active: "true",
      },
      { name: "Situação", key: "situation", align: "left", active: "true" },
      { name: "Unidade Origem", key: "unitHealthOrigin", align: "left" },
      { name: "Farmácia Origem", key: "pharmacyOrigin", align: "left" },
      { name: "Unidade Destino", key: "unitHealthDestiny", align: "left" },
      { name: "Farmácia Destino", key: "pharmacyDestiny", align: "left" },
      { name: "Número da Solicitação", key: "requestNumber", align: "left" },
    ];

    this.CENTRAL_PHARMACY_TYPE = 1;
    this.SATELLITE_PHARMACY_TYPE = 2;

    this.PRODUCTS_HAVE_ENTRIES = 1;
  },

  methods: {
    async fillFilter() {
      const existFilterData = this.$store.getters[
        "Pharmacy/Satellite/GET_FILTER_SATELLITE"
      ];

      if (existFilterData) {
        this.form.unitHealthOrigin = existFilterData.unitHealth;
        this.form.pharmacyOrigin = existFilterData.pharmacy;
      }
    },
    selectedUnitHealthOrigin(pValue) {
      if (pValue) {
        this.unitHealthOrigin = pValue.label;
      } else {
        this.unitHealthOrigin = null;
      }
    },

    selectedPharmacyOrigin(pValue) {
      if (pValue) {
        this.pharmacyOrigin = pValue.far_nome;
      } else {
        this.pharmacyOrigin = null;
      }
    },
    selectedUnitHealthDestiny(pValue) {
      if (pValue) {
        this.unitHealthDestiny = pValue.label;
      } else {
        this.unitHealthDestiny = null;
      }
    },
    selectedPharmacyDestiny(pValue) {
      if (pValue) {
        this.pharmacyDestiny = pValue.far_nome;
      } else {
        this.pharmacyDestiny = null;
      }
    },
    closeModalDeleteProduct() {
      this.modalDeleteMovement = false;
    },
    openModalDeleteProduct() {
      this.modalDeleteMovement = true;
    },
    closeModalConfirmField() {
      this.modalConfirmField = false;
    },
    openModalConfirmField() {
      this.modalConfirmField = true;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async searchItemsByOrder(pIdRequest) {
      try {
        this.$loader.start();

        const variables = {
          intIdPedido: pIdRequest,
          blnSomenteDisponiveis: false,
          blnDispensacoes: true,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_ITEMS_BY_ORDER",
          variables,
        );

        const hasRequestNumber = Object.keys(result.dados).length > 0;

        if (hasRequestNumber) {
          this.form.requestNumber = Number(result.dados[0].ped_numero);
          this.requestData = result.dados[0];
          this.insertProductTable(result.dados);
        }
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao buscar quantidade de pedidos",
        );
      } finally {
        this.$loader.finish();
      }
    },
    verifyDuplicate() {
      const filterDuplicate = this.mutableProductList.filter((pData) => {
        if (pData.code === this.form.mpd_codigo) return true;
      });

      return filterDuplicate;
    },

    async saveRequest() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }
      const existsDuplicate = this.verifyDuplicate();

      if (existsDuplicate.length > 0) {
        this.$toaster.info(
          "É possível excluir o produto e adicioná-lo novamente com outra quantidade.",
          "O produto já faz parte do registro de Entrada",
        );
        return false;
      }

      try {
        this.$loader.start();

        const variables = this.parseDataSave();

        const result = await this.$store.dispatch(
          "Pharmacy/Satellite/REGISTER_REQUEST",
          variables,
        );

        if (result && result.dados > 0) {
          const requestId = result.dados;
          this.searchItemsByOrder(requestId);
        }

        this.$toaster.success("Solicitação realizada com sucesso");

        this.cleanProduct();
        if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        this.hasButtonSelectedTable = false;
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao realizar solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async mountBulletin(pRequest) {
      try {
        this.$loader.start();

        const request = await this.$store.dispatch(
          "Pharmacy/Satellite/GET_REQUEST",
          { intIdPedido: pRequest.ped_id },
        );

        await this.searchItemsByOrder(request.ped_id);

        this.form.unitHealthOrigin = Number(request.idUnidadeOrigem);
        this.form.pharmacyOrigin = request.ped_id_farmacia_origem;
        this.form.requestDate = request.ped_data_pedido;

        this.form.unitHealthDestiny = Number(request.idUnidadeDestino);
        this.form.pharmacyDestiny = request.ped_id_farmacia_destino;
        this.form.requestNumber = request.ped_numero;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao carregar solicitação");
      } finally {
        this.$loader.finish();
      }
    },
    async inactiveOrderedItem() {
      try {
        this.$loader.start();

        const requestId = this.selectedRow.ped_id;
        const orderItemId = this.selectedRow.itp_id;

        await this.$store.dispatch("Pharmacy/Satellite/INACTIVE_ORDERED_ITEM", {
          intItemPedido: orderItemId,
        });

        await this.searchItemsByOrder(requestId);
        if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        this.hasButtonSelectedTable = false;
        this.$toaster.success("Solicitação excluída com sucesso");
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o registro",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async printerRequest() {
      try {
        this.$loader.start();
        const objRequest = {
          farmacia_satelite: this.requestData.solicitante,
          farmacia_atendente: this.requestData.atendente,
          ped_data_pedido: this.requestData.ped_data_pedido,
          ped_numero: this.requestData.ped_numero,
          usu_nome: this.requestData.usu_nome,
          situacao: this.requestData.ped_status,
        };

        const orderItems = await this.$store.dispatch(
          "Pharmacy/Satellite/GET_PRINT_REQUEST",
          { intIdPedido: this.requestData.ped_id },
        );

        const html = RequestHTML(
          objRequest,
          orderItems,
          this.clientName,
          this.userName,
          this.unitHealth,
        );
        this.$utils.print.printHtml(html);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir comprovante de solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    parseDataSave() {
      const variables = {
        itp_id_municipios_produtos: this.form.product,
        itp_quantidade_solicitada: this.form.requestedQuantity
          ? this.form.requestedQuantity.replace(/[^0-9]/g, "")
          : null,
        ped_data_pedido: this.form.requestDate,
        ped_id_farmacia_destino: this.form.pharmacyDestiny,
        ped_id_farmacia_origem: this.form.pharmacyOrigin,
        ped_numero: this.form.requestNumber,
      };

      return variables;
    },
    insertProductTable(pData) {
      this.mutableProductList = [];
      pData.map((item) => {
        const data = {
          ped_id: item.ped_id,
          itp_id: item.itp_id,
          far_id_origem: item.far_origem,
          ped_numero: item.ped_numero,
          solicitante: item.solicitante,
          code: item.mpd_codigo,
          product: item.mpd_novo_principio_ativo,
          typePresentation: item.mtu_novo_nome,
          type: item.mtp_nome,
          request: item.itp_quantidade_solicitada,
          delivered: item.trs_quantidade,
          situation: item.std_nome,
          requestNumber: this.form.requestNumber,
          unitHealthOrigin: this.unitHealthOrigin,
          pharmacyOrigin: this.pharmacyOrigin,
          unitHealthDestiny: this.unitHealthDestiny,
          pharmacyDestiny: this.pharmacyDestiny,
        };
        this.mutableProductList.push(data);
      });
    },
    selectLine(pProduct) {
      if (pProduct) {
        this.selectedRow = pProduct;
        this.hasButtonSelectedTable = true;
      } else {
        this.selectedRow = {};
        this.hasButtonSelectedTable = false;
      }
    },
    validateAmount(pValue, pErrors) {
      if (pValue && Number(pValue) < 1) {
        pErrors.push("Informe um valor maior que zero.");
        return false;
      }

      return true;
    },
    selectedProduct(pProduct) {
      if (pProduct) {
        this.form.typePresentation = pProduct.mtu_id;
        this.form.mpd_codigo = pProduct.mpd_codigo;
      } else {
        this.form.typePresentation = null;
      }
    },
    openModalObservationRequest() {
      this.showModalObservationRequest = true;
    },
    closeModalObservationRequest() {
      this.showModalObservationRequest = false;
    },
    cleanProduct() {
      this.form.product = null;
      this.form.requestedQuantity = null;
    },
    cleanForm() {
      if (this.isEditing) {
        this.form.product = null;
        this.form.requestedQuantity = null;
        this.form.mpd_codigo = null;
      } else {
        this.form = this.$utils.obj.DeepCopy(FORM);
      }
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "unitHealthOrigin",
        "pharmacyOrigin",
        "requestDate",
        "unitHealthDestiny",
        "pharmacyDestiny",
        "product",
        "typePresentation",
        "requestedQuantity",
        "requestNumber",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
