import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation RemoveBulletinBilling($bulletinBilling: BulletinBillingInputType) {
    RemoveBulletinBilling(bulletinBilling: $bulletinBilling) {
      bof_id
    }
  }
`;

export default async ({ commit }, { variables }) => {
  const { data } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data;
};
