import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    strCompetencia: variables.period,
    strMesASerFaturado: variables.month,
    strAnoASerFaturado: variables.year,
    intIdUnidadeSaudeCombo: variables.uns_id,
  };
  return AuthLegacyRequest.post(
    "/faturamento/controller-faturamento/listar-faturamento-bpac",
    sendData,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
