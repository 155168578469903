<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxSituation",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Situação",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
  },
  data: () => {
    return {
      mData: [
        { value: "1", text: "ATIVO" },
        { value: "2", text: "INATIVO" },
      ],
    };
  },
};
</script>
