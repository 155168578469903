<style src="./ExamLaboratoryDetails.scss" lang="scss" scoped></style>
<template>
  <Modulebox
    :granted="hasPermission"
    :title="setTitle"
    class="exam-laboratory-details large-scroll"
  >
    <div slot="title" class="title">
      <div class="buttons-title">
        <!-- <RgShowTicketsButton
          v-show="blockScreen"
          data-id="etiqueta"
          title="Etiquetas"
          class="tags"
          @show-tags="showModalTags"
        /> -->
        <LargeButton
          v-show="hasError"
          id="error-scheduling"
          ref="ErrorButton"
          class="space"
          title="Erros"
          label="Falha no Agendamento"
          :backgroundColor="'#ffa56a'"
          @click="showModalError(errors)"
        >
          <IconDanger slot="icon" />
        </LargeButton>

        <!-- <RgAddButton
          v-show="buttons.new"
          id="new-order"
          small
          title="Cadastrar Pedido"
          class="new-exam space"
          type="button"
          @click="prepareNewExam"
        /> -->
      </div>
    </div>

    <form class="content" @submit.stop.prevent="saveSolicitationEffectivation">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="form-base">
          <div class="patient-area-form">
            <div>
              <RgSuggestPatientInformation
                id="patient"
                ref="patient"
                v-model="suggestPatient"
                :disabled="
                  disableInputsInRequestData || blockScreen || isSolicitation
                "
                :rules="{ required: true, forceSelection: true }"
                :withPatientModule="[
                  'emergency',
                  'emergency_data',
                  'hospitalization',
                  'hospitalization_data',
                ]"
                :extra-data="[
                  'telephone',
                  'ethnicity',
                  'naturality',
                  'nationality',
                ]"
                :disable-only-edit="blockScreen"
                :activePatient="1"
                confirmToRemove
                filterBulletinHospitalization
                message="Todos os campos informados serão apagados, deseja continuar ?"
                data-id="paciente"
                data-item="paciente"
                class="suggest-patient"
                @patientInfo="selectingPatient"
              />
            </div>

            <div class="width-fit">
              <Tooltip
                message="O cadastro do paciente não será alterado. Os dados abaixo fazem parte somente deste pedido."
                startOpen
                class="tooltip"
              >
                <IconInfoHelper slot="icon" hollow />
              </Tooltip>
            </div>

            <div class="form-bottom">
              <RgComboboxEthnicity
                id="ethnicity"
                ref="ethnicity"
                v-model="examPatientData.colorAndRaceId"
                label="Raça/Cor"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: !validate.colorAndRace }"
                @change="ethnicityPatient"
              />

              <RgSuggestNationality
                id="nationality"
                ref="nationality"
                v-model="examPatientData.nationality"
                label="Nacionalidade"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: !validate.nationality }"
                placeholder="Digite o nome do país"
                @selected="selectNationality"
              />

              <RgSuggestCity
                v-if="isBrazillian"
                id="naturalness"
                ref="city"
                v-model="examPatientData.city"
                label="Naturalidade"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: !validate.city }"
                placeholder="Digite o nome do município"
              />

              <div v-else class="naturality">
                <input
                  id="is-naturalized"
                  v-model="isNaturalized"
                  type="checkbox"
                  name="naturality"
                  value="1"
                  @change="setNaturalized($event)"
                />
                <label class="label" for="naturality">Naturalizado</label>
              </div>

              <RgSelectDddInputPhone
                id="telephone"
                ref="telephone"
                v-model="examPatientData.telephone"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: true }"
                @getDdd="getDddData"
              />
              <!-- <RgInput
                id="telephone"
                ref="telephone"
                v-model="examPatientData.telephone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :rules="{ required: true }"
                placeholder="(00) 00000-0000"
                label="Telefone"
              /> -->

              <RgInputCns
                id="cns"
                ref="cns"
                v-model="examPatientData.cns"
                :disabled="!form.pes_id || blockScreen"
                :class="{
                  disable: !form.pes_id || blockScreen,
                }"
                :requiredValue="true"
              />
            </div>
          </div>
        </FormBase>

        <FormBase title="Dados do Pedido" class="form-base">
          <div class="width-fit">
            <Tooltip
              start-open
              class="tooltip"
              message="Para realizar o agendamento é necessário informar data e hora"
            >
              <IconExclamationHelper slot="icon" hollow />
            </Tooltip>
          </div>

          <section class="scheduling-information">
            <RgInputDate
              id="schedule-date"
              ref="scheduleDate"
              v-model="form.leb_data_agendamento"
              :disabled="blockScreen || !patientIsValid"
              :class="{
                disable: blockScreen || !patientIsValid,
              }"
              :rules="{
                required: obligatorinessScheduleHour || isScheduleExam,
                fn: verifyRetroactiveDate,
              }"
              data-id="data-exame-agendado"
              label="Data do Agendamento"
            />

            <RgInputHour
              id="schedule-hour"
              ref="scheduleHour"
              v-model="form.leb_hora_agendamento"
              :disabled="blockScreen || !patientIsValid"
              :class="{
                disable: blockScreen || !patientIsValid,
              }"
              :rules="{
                required: obligatorinessScheduleDate || isScheduleExam,
              }"
              data-id="hora-exame-agendado"
              label="Hora do Agendamento"
              placeholder="hh:mm"
            />
          </section>

          <hr class="separator" />

          <section class="request-area-form">
            <div class="row1">
              <RgSuggestEmployee
                id="employee"
                ref="requester"
                v-model="suggestRequestEmployee"
                :rules="{
                  forceSelection: true,
                  fn: validateProfessionalData,
                  required: true,
                }"
                :disabled="
                  disableInputsInRequestData || blockScreen || !patientIsValid
                "
                :class="{
                  disable:
                    disableInputsInRequestData ||
                    blockScreen ||
                    !patientIsValid,
                }"
                notNecessaryBond
                filterWithoutBond
                data-id="profissional-solicitante"
                data-item="profissional-solicitante"
                placeholder="Digite o nome do profissional solicitante"
                label="Profissional Solicitante"
                @selected="selectingRequestEmployee"
              />

              <RgSuggestCid
                id="cid"
                ref="mainCid"
                v-model="mainCid"
                :disabled="
                  disableInputsInRequestData || blockScreen || !patientIsValid
                "
                :class="{
                  disable:
                    disableInputsInRequestData ||
                    blockScreen ||
                    !patientIsValid,
                }"
                data-id="cid"
                label="CID"
                placeholder="Digite a classificação"
                @selected="selectingCidCode"
              />

              <RgSelectUnithealth
                id="unit-health"
                ref="unitHealth"
                v-model="form.selectedUnity"
                default-text="Selecione"
                :rules="{ required: true }"
                :disabled="blockScreen || !patientIsValid || hasProtocolNumber"
                :class="{
                  disable: blockScreen || !patientIsValid || hasProtocolNumber,
                }"
                :filterAll="true"
              />

              <RgSelectSector
                id="sector"
                ref="sector"
                v-model="form.selectedSector"
                :unit-health="form.selectedUnity"
                :disabled="
                  blockScreen ||
                  !patientIsValid ||
                  !form.selectedUnity ||
                  hasProtocolNumber
                "
                :rules="{ required: true }"
                :class="{
                  disable:
                    blockScreen ||
                    !patientIsValid ||
                    !form.selectedUnity ||
                    hasProtocolNumber,
                }"
                default-text="Selecione"
              />
            </div>

            <div class="row2">
              <RgInputDate
                id="request-data"
                ref="requestData"
                v-model="form.leb_data_solicitacao"
                :disabled="
                  disableInputsInRequestData || blockScreen || !patientIsValid
                "
                :class="{
                  disable:
                    disableInputsInRequestData ||
                    blockScreen ||
                    !patientIsValid,
                }"
                :rules="{ required: true }"
                data-id="data-exame"
                label="Data da Solicitação"
              />

              <RgInput
                id="protocol-number"
                v-model="protocolNumber"
                :class="{ disable: true }"
                :disabled="true"
                data-id="solicitacao"
                label="N° Protocolo"
                title="Número da Solicitação"
              />
            </div>

            <div class="row3">
              <div class="patient-info-area">
                <RgInputMasked
                  id="weight"
                  ref="weight"
                  v-model="patientWeight"
                  label="Peso"
                  placeholder="Ex.: 60,5"
                  :rules="{
                    fn: weightIsValid,
                    required: !validate.heightAndWeight,
                  }"
                  :mask="setMaskWeight()"
                  :typeMask="'kg'"
                  :maxlength="5"
                  :disabled="!form.pes_id || blockScreen"
                  :class="{
                    disable: !form.pes_id || blockScreen,
                  }"
                />

                <RgInputMasked
                  id="height"
                  ref="height"
                  v-model="patientHeight"
                  :mask="('#', '#,##')"
                  :disabled="!form.pes_id || blockScreen"
                  :class="{
                    disable: !form.pes_id || blockScreen,
                  }"
                  :rules="{
                    fn: heightIsValid,
                    required: !validate.heightAndWeight,
                  }"
                  :typeMask="'m'"
                  data-id="altura"
                  label="Altura"
                  placeholder="Ex.: 1,68"
                  class="metric-values"
                />
              </div>

              <div class="woman-info">
                <div
                  :class="{
                    disable: disabledRadioGender || blockScreen,
                  }"
                >
                  <label class="small-title">Menstruação</label>

                  <div class="radio-menstruation" data-id="radio-menstruacao">
                    <input
                      id="radio-menstruacao-sim"
                      v-model="patientInfo.menstruada"
                      :disabled="disabledRadioGender || blockScreen"
                      :class="{
                        disable: disabledRadioGender || blockScreen,
                      }"
                      type="radio"
                      value="S"
                    />
                    <span data-id="menstrua" class="radion-span">Sim</span>

                    <input
                      id="radio-menstrua-nao"
                      v-model="patientInfo.menstruada"
                      :disabled="disabledRadioGender || blockScreen"
                      :class="{
                        disable: disabledRadioGender || blockScreen,
                      }"
                      type="radio"
                      value="N"
                    />
                    <span class="radion-span" data-id="nao-menstrua">Não</span>
                  </div>
                </div>

                <RgInputDate
                  id="date-menstrua"
                  ref="dateMenst"
                  v-model="patientInfo.data_menstruacao"
                  :disabled="!isMenstruationRadioTrue || blockScreen"
                  :class="{
                    disable: !isMenstruationRadioTrue || blockScreen,
                  }"
                  :rules="{ fn: validateDateMenstruation }"
                  data-id="data-menstruacao"
                  label="Data da Última Menstruação"
                />
              </div>
            </div>

            <div class="row4">
              <RgTextArea
                id="medicamentos"
                v-model="patientInfo.info_medicamento"
                :disabled="blockScreen || !patientIsValid"
                :class="{ disable: blockScreen || !patientIsValid }"
                :maxlength="255"
                data-id="info-medicamentos"
                label="Medicamentos em Uso"
                class="text-area-info"
              />
              <RgTextArea
                id="info-menstruacao"
                v-model="patientInfo.info_menstruacao"
                :disabled="
                  disableTextAreaMenstruation || blockScreen || !patientIsValid
                "
                :class="{
                  disable:
                    disableTextAreaMenstruation ||
                    blockScreen ||
                    !patientIsValid,
                }"
                :maxlength="255"
                label="Motivo da Ausência de Menstruação"
                class="text-area-info"
              />
            </div>
          </section>
        </FormBase>

        <FormBase title="Procedimentos" class="form-base">
          <div class="procedure-area-form">
            <div class="width-fit">
              <Tooltip
                message="Todos os resultados disponíveis de exame são impressos em um único/mesmo arquivo."
                startOpen
                class="tooltip"
              >
                <IconInfoHelper slot="icon" hollow />
              </Tooltip>
            </div>
            <div>
              <RgValidatorForm ref="validatorExam" class="form-area">
                <RgSuggestProcedureBioslab
                  id="procedure"
                  ref="procedure"
                  v-model="suggestProcedure"
                  :rules="{ required: !hasProcedureList, forceSelection: true }"
                  :disabled="disableProcedureSuggest || blockScreen"
                  :class="{
                    disable: disableProcedureSuggest || blockScreen,
                  }"
                  :alert-empty-result="true"
                  data-id="procedimento"
                  label="Exames Laboratoriais"
                  placeholder="Digite o Procedimento"
                  field-caption="lle_procedimentos"
                  class="exam"
                  @selected="selectingProcedure"
                />

                <RgInput
                  id="material"
                  ref="material"
                  v-model="exams.collectMaterial"
                  :disabled="
                    !matbio || blockScreen || !suggestProcedure.length > 0
                  "
                  :class="{
                    disable:
                      !matbio || blockScreen || !suggestProcedure.length > 0,
                  }"
                  :rules="{ required: requiredMaterial }"
                  data-id="material-coleta"
                  class="material"
                  label="Material de Coleta"
                />

                <RgSelectObjectAnalisis
                  id="object-analisis"
                  ref="objectAnalisis"
                  v-model="exams.objectAnalisis.lob_nome_objetos"
                  :data="objectExam"
                  :disabled="
                    !suggestProcedure.length > 0 ||
                    showObjectExam ||
                    blockScreen
                  "
                  :class="{
                    disable:
                      !suggestProcedure.length > 0 ||
                      showObjectExam ||
                      blockScreen,
                  }"
                  :rules="{
                    required: requiredObjectAnalisis,
                  }"
                  class="object"
                  @change="selectObjectAnalisis"
                />

                <div class="procedure-buttons">
                  <RgCleanButton
                    id="clear-procedure"
                    data-id="limpar-procedimento"
                    title="Limpar"
                    class="clean"
                    small
                    :disabled="disableCleanButton"
                    @click="cleanProcedureInfo"
                  />

                  <RgAddButton
                    id="new-procedure"
                    data-id="adicionar-procedimento"
                    title="Adicionar Procedimento"
                    class="new"
                    small
                    :disabled="disableAddProcedure || blockScreen"
                    @click="addProcedure"
                  />
                </div>
              </RgValidatorForm>
            </div>

            <div class="line" />
            <div class="procedure-list-area">
              <SmartTable
                ref="smartTable"
                name="ExamLaboratoryDetails"
                :columns="COLUMN_TABLE"
                :body="procedureList"
                :total="qtdExam"
                :colorLine="hasResult ? actionColorLine : null"
                :initial-columns="hasResult ? 6 : 5"
                :dontSelect="hasResult"
                :show-pagination="false"
                :save-columns-on-store="false"
                :circleIndicator="hasResult"
                toggle-selected
                remove-btn-columns
                @getLine="getValueRow"
              >
                <div v-if="hasResult" slot="legends" class="legends">
                  <div class="circle --green" />
                  <span class="legend">Resultado Disponível</span>
                  <div class="circle --red" />
                  <span class="legend">Resultado Pendente</span>
                </div>

                <div slot="top-buttons" class="_flex painel-button">
                  <RgLessButton
                    id="delete-procedure"
                    title="Excluir"
                    :disabled="!selectLine || blockScreen"
                    @click="removeFromProcedureList"
                  />
                  <SmallButton
                    v-show="buttons.pdf"
                    id="download-procedure"
                    :disabled="!hasUrl"
                    :class="{ disabled: !hasUrl }"
                    :open-url="hasUrl"
                    :send-url="getUrl"
                    class="space"
                    title="Baixar Resultado"
                    :backgroundColor="'#26789f'"
                  >
                    <IconArrowDownload slot="icon" />
                  </SmallButton>
                </div>
              </SmartTable>

              <div class="quantity-exams" :class="{ disable: blockScreen }">
                <span>Registros encontrados: {{ qtdExam }}</span>
              </div>
            </div>
          </div>
        </FormBase>
      </RgValidatorForm>
    </form>

    <div class="commands">
      <div v-show="buttons.emblem" class="emblem">
        <span class="title-footer">Status: </span>
        <span
          :class="{
            'solicitation-emblem': buttons.solicitation && buttons.agendation,
            'agendation-emblem': buttons.agendation && !buttons.solicitation,
            'pending-emblem': buttons.pending,
            'partially-attended-emblem': buttons.partiallyAttended,
            'attended-emblem': buttons.attended,
          }"
          class="emblem-status unselect"
        >
          {{ currentStatus }}
        </span>
      </div>

      <div class="buttons-footer">
        <RgCleanButton
          v-show="buttons.showClear && buttons.showButtons"
          id="clean-btn"
          type="button"
          class="space"
          data-id="limpar-tela"
          title="Limpar todos os campos"
          @click="showConfirmFieldClearing"
        />

        <MediumButton
          id="cancel-solicitation"
          :label="blockScreen ? 'Voltar' : 'Cancelar'"
          backgroundColor="#ff7f2a"
          class="space"
          title="Cancelar"
          @click="cancelAction"
        />

        <MediumButton
          v-show="
            buttons.solicitation &&
            !buttons.edit &&
            buttons.showButtons &&
            !hasScheduleDateTime &&
            !isScheduleExam
          "
          id="save-solicitation"
          :label="buttons.titleSaveSolicititation"
          title="Solicitar"
          :disabled="buttonDisabled"
          :class="{ disabled: buttonDisabled }"
          class="space"
          @click="saveSolicitationEffectivation"
        />

        <RgEditButton
          v-show="buttons.edit && buttons.showButtons && !hasScheduleDateTime"
          id="atualizar-pendencia"
          class="space"
          large
          name="Atualizar"
          :disabled="buttonDisabled"
          :class="{ disabled: buttonDisabled }"
          @click="editExam(buttons.pending)"
        />

        <RgNewScheduleButton
          v-show="
            ((buttons.agendation || buttons.pending) &&
              buttons.showButtons &&
              showButtonAgendation &&
              !blockScreen) ||
            isScheduleExam
          "
          id="salvar-agendamento"
          :label="'Agendar'"
          backgroundColor="#26789f"
          class="space"
          large
          :disabled="buttonDisabled"
          :class="{ disabled: buttonDisabled }"
          @click="saveSchedule(buttons.pending)"
        />
      </div>
    </div>

    <MedicalLoader
      ref="loader"
      :to-close="loading"
      :status="loader"
      :title-loader="'Aguarde, enviando para o Bioslab...'"
      :title-success="'Enviado com sucesso!'"
      :title-failed="'Erro no envio para o Bioslab... '"
      @close="closeLoader"
    />

    <ModalShowError
      id="modal-show-error"
      :show="showError"
      :errors="errors"
      @close="closeErrorModal"
      @shortkey.native="closeErrorModal"
    />

    <!-- <ModalShowTags
      :can-print="canPrint"
      :bioslab-id="leb_id"
      :show="showTags"
      @close="closeTagModal"
    /> -->

    <ModalConfirmRemoveExam
      id="nodal-confirm-remove-exam"
      :show="showModalConfirmRemove"
      type="button"
      data-id="modal-confirma-exclusao"
      :removeFromModal="false"
      @exam-removed="removeFromProcedureListProtocolNumber($event)"
      @close="closeModalConfirmRemove"
    />

    <ModalConfirmAddExam
      id="modal-confirm-add-exam"
      :show="showModalConfirmAdd"
      type="button"
      data-id="modal-confirma-adicao"
      @exam-add="addProcedure($event)"
      @close="closeModalConfirmAdd"
    />

    <ModalConfirmFieldClearing
      id="modal-confirm-field-clearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />

    <ModalInactiveExams
      id="modal-inactive-exams"
      :exams="inactiveExams"
      :show="showInactiveExams"
      @removeInactiveExams="removeInactiveExams"
      @close="closeModalInactiveExams"
    />
  </Modulebox>
</template>

<script>
import moment from "moment";
import { SmartTable, FormBase } from "~tokio/foundation";
import { AlertError } from "~tokio/primitive/notification";
import { mask } from "vue-the-mask";
import { isEmpty, clone } from "lodash";
import {
  ModalShowError,
  // ModalShowTags,
} from "$exam/submodules/view/individual-effectivation/component/modal";
import { SearchCidByCode, GetEmployeeById } from "./action";
import {
  RgSuggestProcedureBioslab,
  RgSuggestEmployee,
  RgSuggestCid,
  RgInputHour,
  RgInput,
  RgValidatorForm,
  RgInputDate,
  // RgPersonCardButton,
  // RgShowTicketsButton,
  RgTextArea,
  RgSuggestPatientInformation,
  Tooltip,
  RgLessButton,
  RgSelectUnithealth,
  RgSelectSector,
  MediumButton,
  RgNewScheduleButton,
  RgEditButton,
  RgInputMasked,
  IconInfoHelper,
  IconExclamationHelper,
  RgAddButton,
  LargeButton,
  IconDanger,
  IconArrowDownload,
  SmallButton,
} from "~tokio/primitive";
import {
  RgInputCns,
  RgSuggestNationality,
  RgSuggestCity,
  RgComboboxEthnicity,
} from "$person/common/components";
import RgSelectObjectAnalisis from "$exam/submodules/schedule/component/select/rg-select-object-analisis/RgSelectObjectAnalisis";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import MedicalLoader from "~tokio/primitive/loader/medical-loader/MedicalLoader.vue";
import PreparedBioslabExtraInfoToSave from "$exam/store/actions/PreparedBioslabExtraInfoToSave";
import PrepareBioslabExamToSave from "$exam/store/actions/PrepareBioslabExamToSave";
import RgCleanButton from "~tokio/primitive/button/rg-clean-button/RgCleanButton";
import SearchBioslabLaboratoryObject from "./action/SearchBioslabLaboratoryObject";
import EXAM_STATUS_LABORATORY from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status-laboratory/GetExamStatusLaboratory";
import ModalConfirmRemoveExam from "$exam/submodules/view/individual-effectivation/component/modal/modal-confirm-remove-exam/ModalConfirmRemoveExam";
import ModalInactiveExams from "$exam/submodules/schedule/component/modal/modal-inactive-exams/ModalInactiveExams";
import ModalConfirmAddExam from "$exam/submodules/view/individual-effectivation/component/modal/modal-confirm-add-exam/ModalConfirmAddExam";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import ValidationLaboratoryDetailsMixin from "$exam/submodules/view/laboratory-details/mixin/ValidationLaboratoryDetailsMixin.js";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";

import RgSelectDddInputPhone from "$person/common/components/input/rg-select-ddd-input-phone/RgSelectDddInputPhone";

export default {
  name: "ExamLaboratoryDetails",
  components: {
    RgSelectDddInputPhone,
    Modulebox,
    MedicalLoader,
    ModalShowError,
    ModalConfirmRemoveExam,
    ModalConfirmAddExam,
    RgInputHour,
    RgSuggestEmployee,
    // ModalShowTags,
    RgInput,
    SmartTable,
    RgInputDate,
    RgLessButton,
    RgValidatorForm,
    RgSuggestCid,
    IconArrowDownload,
    RgSuggestProcedureBioslab,
    IconDanger,
    RgAddButton,
    RgCleanButton,
    SmallButton,
    ModalConfirmFieldClearing,
    // RgShowTicketsButton,
    FormBase,
    RgInputCns,
    RgSuggestNationality,
    RgSuggestCity,
    RgSelectSector,
    RgTextArea,
    RgComboboxEthnicity,
    RgSuggestPatientInformation,
    Tooltip,
    RgSelectObjectAnalisis,
    RgSelectUnithealth,
    MediumButton,
    RgNewScheduleButton,
    RgEditButton,
    RgInputMasked,
    ModalInactiveExams,
    IconInfoHelper,
    IconExclamationHelper,
    LargeButton,
  },
  directives: {
    mask,
  },
  mixins: [ValidationLaboratoryDetailsMixin],
  data() {
    return {
      buttonDisabled: false,
      obligatorinessScheduleDate: false,
      obligatorinessScheduleHour: false,
      examPatientData: {},
      lastIndex: 0,
      protocolNumber: null,
      index: null,
      bulletinNumber: "",
      hospitalizationNumber: "",
      recordNumber: "",
      suggestProcedure: "",
      suggestPatient: "",
      urlExam: "",
      patientWeight: null,
      patientHeight: null,
      isNaturalized: null,
      currentStatus: "",
      currentStatusId: EXAM_STATUS_LABORATORY.NOVO,
      suggestRequestEmployee: "",
      setTitle: "Cadastrar Pedido",
      examData: {},
      examInfo: {},
      errors: [],
      procedureList: [],
      procedureListToRemove: [],
      objectExam: [],
      leb_id: 0,
      qtdExam: 0,
      loader: 0,
      sectorLink: null,
      mainCid: null,
      hasProcedure: false,
      isEmployeeSelected: false,
      disableInputsInRequestData: false,
      disabledInfoMenstruation: false,
      disableDateSchedule: false,
      disableHourSchedule: false,
      modalConfirmFieldClearing: false,
      patientIsValid: false,
      showTags: false,
      loading: false,
      matbio: false,
      showError: false,
      isSelectedNationality: false,
      showModalConfirmRemove: false,
      showModalConfirmAdd: false,
      procedure: {
        no_procecimento: "",
        tp_sexo: "",
        vl_idade_minima: "",
        vl_idade_maxima: "",
      },
      buttons: {
        new: false,
        solicitation: true,
        agendation: true,
        edit: false,
        pending: false,
        partiallyAttended: false,
        attended: false,
        showProcedure: true,
        editPerson: true,
        titleUpdate: false,
        showButtons: true,
        disableButtons: false,
        emblem: false,
        showClear: true,
        pdf: false,
      },
      obligatoriness: {
        multiplo: "N",
        dum: "N",
        peso: "N",
        altura: "N",
        cor: "N",
        nacionalidade: "N",
        naturalidade: "N",
      },
      validate: {
        colorAndRace: true,
        heightAndWeight: true,
        nationality: true,
        city: true,
      },
      patientInfo: {
        pes_id: 0,
        sexo_sigla: "",
        nasc: "",
        nome: "",
        peso: "",
        altura: "",
        info_medicamento: "",
        cor: "",
        menstruada: "N", // RADIO
        data_menstruacao: "", // DATA MENSTRUAÇÃO
        info_menstruacao: "", // TEXTAREA
        othersData: {},
      },
      exams: {
        collectMaterial: "",
        objectAnalisis: {
          lob_nome_objetos: "",
          lob_id: null,
        },
        codigoExameMne: "",
      },
      employeeRequest: {
        id: 0,
        name: "",
        cpf: "",
        uf: "",
        conselho: null,
        numberCons: null,
      },
      form: {
        pes_id: null,
        exe_id: 0,
        selectedUnity: null,
        selectedSector: null,
        exe_id_unidades_saude: null,
        exe_id_pacientes: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
        exe_id_boletins: null,
        exe_id_internacoes: null,
        exe_codigo_procedimento: null,
        exe_codigo_cid: null,
        exe_hora_exame_sol: null,
        leb_data_solicitacao: moment().format("DD/MM/YYYY"),
        leb_data_agendamento: "",
        leb_hora_agendamento: "",
        exe_id_exames_efetivacao: null,
      },
      selectLine: null,
      selectLineIndex: null,
      inactiveExams: [],
      showInactiveExams: false,
      isScheduleExam: false,
    };
  },
  computed: {
    actionColorLine() {
      return {
        get: (item) => {
          if (item.resultado) {
            return GetColorRowSmartTable("green");
          } else {
            return GetColorRowSmartTable("red");
          }
        },
      };
    },

    hasScheduleDateTime() {
      return this.form.leb_data_agendamento || this.form.leb_hora_agendamento;
    },

    hasProcedureList() {
      return this.procedureList && this.procedureList.length > 0;
    },

    hasError() {
      return this.errors && this.errors?.length > 0;
    },

    hasProtocolNumber() {
      return (
        this.examData &&
        this.examData.leb_codigo_solicitacao &&
        this.examData.leb_codigo_solicitacao.length > 0
      );
    },
  },

  watch: {
    "form.leb_hora_agendamento"(pValue) {
      if (!pValue) {
        this.$refs.scheduleDate.cleanValidate();
      }

      if (pValue && pValue.length > 0) {
        this.obligatorinessScheduleHour = true;
      } else {
        this.obligatorinessScheduleHour = false;
      }
    },
    "form.leb_data_agendamento"(pValue) {
      if (!pValue) {
        this.$refs.scheduleHour.cleanValidate();
      }

      if (pValue && pValue.length > 0) {
        this.obligatorinessScheduleDate = true;
      } else {
        this.obligatorinessScheduleDate = false;
      }
    },

    "examPatientData.nationality"(pValue) {
      if (this.isBrazillian) {
        this.examPatientData.city = "";
      } else if (!this.isBrazillian) {
        if (this.isNaturalized) {
          this.examPatientData.city = "NATURALIZADO";
        } else if (!this.isNaturalized) {
          this.examPatientData.city = "ESTRANGEIRO";
        }
      }
    },

    patientWeight(pValue) {
      this.patientInfo.peso = this.sanitizeNumber(pValue);
    },

    patientHeight(pValue) {
      this.patientInfo.altura = this.sanitizeNumber(pValue);
    },

    suggestProcedure(pValue) {
      this.suggestProcedure = pValue;
      if (pValue && pValue.length < 1) {
        this.cleanProcedureInfo();
      }
      if (!pValue) {
        this.$refs.material.cleanValidate();
        this.$refs.objectAnalisis.cleanValidate();
      }
    },

    procedureList(pValue) {
      this.qtdExam = pValue.length;
    },

    "patientInfo.menstruada"(pValue) {
      if (pValue === "S") {
        this.patientInfo.info_menstruacao = "";
      }

      if (pValue === "N") {
        this.patientInfo.data_menstruacao = "";
      }

      if (this.isWoman && pValue === null) {
        this.patientInfo.menstruada = "N";
        this.patientInfo.info_menstruacao = "";
      }
      this.$refs.dateMenst.validate();
    },
  },

  created() {
    this.COLUMN_TABLE = [
      { name: "Exame", key: "nome" },
      { name: "Código Bioslab", key: "codigoExameMne" },
      { name: "Código Sigtap", key: "codigo" },
      { name: "Material de Coleta", key: "collectMaterial" },
      { name: "Objeto de Análise", key: "lob_nome_objetos" },
      { name: "Resultado", key: "resultadoInfo" },
    ];
  },

  destroyed() {
    const existThisRoute = ValidateIfRouteExistInBreadscrumb(
      "/exam/laboratory",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRoute) {
      this.$store.commit("Exam/RESET_FILTER_BIOSLAB");
    }

    this.inactiveExams = [];
  },

  mounted() {
    try {
      if (this.lastPersonIdSaved) {
        this.$refs.patient.fillPatientById(this.lastPersonIdSaved);
      }

      this.examData = this.$route.params.examData;

      if (!this.examData) {
        this.examData = this.$store.getters["Exam/Laboratory/GET_EXAMINFO"];
      }
      this.patientWeight = this.patientInfo.peso;
      this.patientHeight = this.patientInfo.altura;

      this.setExamOrPatient();

      this.mountExam(this.examData);
      this.mountPage(this.examData.leb_id_laboratoriais_status_pedido);
    } catch (err) {
      this.$toaster.error(err.message);
    }
  },

  methods: {
    getDddData(pValue) {
      this.examPatientData.ddd = pValue;
    },
    setMaskWeight() {
      return this.startedWithZero(this.patientWeight)
        ? "#,##"
        : ["#,#", "##,#", "###,#"];
    },

    startedWithZero(pValue) {
      return pValue?.substring(0, 1) === "0";
    },

    cancelAction() {
      this.$router.push({
        name: "exam.laboratory",
      });
    },

    selectObjectAnalisis(pObject) {
      if (pObject) {
        this.exams.objectAnalisis = {
          lob_nome_objetos: pObject.value,
          lob_id: pObject.id,
        };
      } else {
        this.exams.objectAnalisis = {
          lob_nome_objetos: "",
          lob_id: null,
        };
      }
    },

    getValueRow(pRow, index) {
      if (pRow) {
        this.selectLine = pRow;
        this.selectLineIndex = index;
      } else {
        this.selectLine = null;
        this.selectLineIndex = null;
      }
    },

    selectNationality(pValue) {
      if (pValue) {
        this.isSelectedNationality = true;
      } else {
        this.isSelectedNationality = false;
      }
    },

    setNaturalized(e) {
      if (this.isNaturalized === true)
        this.examPatientData.city = "NATURALIZADO";
      else if (this.isNaturalized === false)
        this.examPatientData.city = "ESTRANGEIRO";
    },

    setExamOrPatient() {
      const ethnicity = this.$refs.ethnicity.getMData();

      let objEthnicity = ethnicity.find((item) => {
        return item.text === this.examData.leb_cor_paciente;
      });

      if (!objEthnicity) {
        objEthnicity = ethnicity.find((item) => {
          return item.value === this.patientInfo.othersData.pes_etnias_id;
        });
      }

      this.examPatientData = {
        gender: this.examData?.leb_sexo_paciente
          ? this.examData.leb_sexo_paciente
          : this.patientInfo.sexo_sigla,
        birthday: this.examData?.leb_nascimento_paciente
          ? moment(this.examData.leb_nascimento_paciente).format("DD/MM/YYYY")
          : this.patientInfo.othersData.pes_nascimento,
        colorAndRace: this.examData?.leb_cor_paciente
          ? this.examData.leb_cor_paciente
          : this.patientInfo.othersData.pes_etnias_nomes,
        colorAndRaceId: objEthnicity ? objEthnicity.value : null,
        telephone: this.examData?.leb_telefone_paciente
          ? this.examData.leb_telefone_paciente
          : this.patientInfo.othersData.tel_full,
        cns: this.examData?.leb_cns_paciente
          ? this.examData.leb_cns_paciente
          : this.patientInfo.othersData.crs_numero,
        nationality: this.examData?.leb_nacionalidade_paciente
          ? this.examData.leb_nacionalidade_paciente
          : this.patientInfo.othersData.nac_nome,
        city:
          this.examData?.leb_naturalidade_paciente ||
          this.patientInfo.othersData.naturalidade,
      };

      if (this.$refs.nationality) {
        this.$refs.nationality.forceSelection({
          nac_nome: this.examPatientData.nationality,
        });
        this.isSelectedNationality = true;
      }

      if (!this.isBrazillian) {
        if (this.examPatientData.city === "NATURALIZADO")
          this.isNaturalized = true;
        else if (this.examPatientData.city === "ESTRANGEIRO")
          this.isNaturalized = false;
      }

      if (this.$refs.city) {
        this.$refs.city.forceSelection({
          mun_nome: this.examPatientData.city,
        });
      }
    },

    ethnicityPatient(pValue) {
      if (pValue) {
        this.examPatientData.colorAndRace = pValue[0].text;
      } else {
        this.examPatientData.colorAndRace = "";
      }
    },

    prepareNewExam() {
      this.mountPage(EXAM_STATUS_LABORATORY.NOVO);
      this.cleanForm();
      this.$router.push({ name: "exam.laboratory.details" });
    },

    mountPage(status) {
      this.isScheduleExam =
        status === 1 &&
        this.$route.name === "exam.laboratory.schedule-exam-request";

      this.buttons.titleUpdate = true;

      if (!status) {
        this.setTitle = "Cadastrar Pedido";
        this.buttons.new = false;
        this.buttons.emblem = false;
        this.buttons.showClear = true;
        this.buttons.solicitation = true;
        this.buttons.disableButtons = false;
        this.buttons.showButtons = true;
        this.buttons.editPerson = true;
        this.buttons.titleSaveSolicititation = "Solicitar";
        this.currentStatusId = EXAM_STATUS_LABORATORY.NOVO;
        this.currentStatus = "";
      } else {
        this.isScheduleExam
          ? (this.setTitle = "Agendar Pedido")
          : (this.setTitle = "Visualizar Pedido");
        this.currentStatusId = status;
        this.buttons.new = false;
        this.buttons.emblem = true;
        this.buttons.showClear = false;

        switch (status) {
          case EXAM_STATUS_LABORATORY.SOLICITADO:
            this.currentStatus = "Solicitado";
            this.buttons.titleSaveSolicititation = "Atualizar";
            this.buttons.solicitation = true;
            this.buttons.agendation = true;
            this.isScheduleExam
              ? (this.buttons.edit = false)
              : (this.buttons.edit = true);
            break;
          case EXAM_STATUS_LABORATORY.AGENDADO:
            this.currentStatus = "Agendado";
            this.disabledInfoMenstruation = true;
            this.buttons.agendation = true;
            this.buttons.pending = false;
            this.buttons.edit = false;
            break;
          case EXAM_STATUS_LABORATORY.PENDENTE:
            this.currentStatus = "Pendente";
            this.buttons.pending = true;
            this.buttons.solicitation = false;
            this.buttons.agendation = false;
            this.buttons.edit = true;
            break;
          case EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO:
            this.currentStatus = "Parcialmente Atendido";
            this.buttons.partiallyAttended = true;
            this.buttons.pdf = true;
            this.buttons.edit = false;
            break;
          case EXAM_STATUS_LABORATORY.ATENDIDO:
            this.currentStatus = "Atendido";
            this.disabledInfoMenstruation = true;
            this.buttons.attended = true;
            this.buttons.pdf = true;
            this.buttons.edit = false;
            break;
        }

        const isInStates = [
          EXAM_STATUS_LABORATORY.AGENDADO,
          EXAM_STATUS_LABORATORY.PARCIALMENTE_ATENDIDO,
          EXAM_STATUS_LABORATORY.ATENDIDO,
        ].includes(status);

        if (isInStates) {
          this.setTitle = "Visualizar Pedido";
          this.buttons.solicitation = false;
          this.buttons.pending = false;
          this.buttons.editPerson = false;
          this.buttons.showProcedure = false;
          this.buttons.showButtons = false;
          this.buttons.disableButtons = true;
          this.buttons.new = true;
          if (status === EXAM_STATUS_LABORATORY.AGENDADO) {
            this.buttons.showButtons = true;
          }
        }
      }
    },

    async examObjects(pCodeSigtap, pCodeObjectBioslab, validate = true) {
      let result = [];
      let sex = "";
      let filterResult = [];

      if (validate) {
        sex = this.examPatientData.gender;

        result = await SearchBioslabLaboratoryObject({
          lle_codigo_sigtap: pCodeSigtap,
          lle_codigo_bioslab_mne: pCodeObjectBioslab,
        });

        filterResult = [...result];
        for (let i = 0; i < result.length; i++) {
          if (sex === "M") {
            if (result[i].lob_id === 5) {
              filterResult.splice(i, 1);
            }
            if (result[i].lob_id === 6) {
              filterResult.splice(i, 1);
            }
          } else if (sex === "F") {
            if (result[i].lob_id === 7) {
              filterResult.splice(i, 1);
            }
          }
        }
      }
      this.objectExam = filterResult;
    },

    async getListProcedure(pValue) {
      this.inactiveExams = [];

      try {
        const exams = await this.$store.dispatch(
          "Bioslab/SEARCH_BIOSLAB_LABORATORY_PATIENT_EXAMS",
          {
            lpe_id_laboratoriais_exames_bioslab: pValue,
            lpe_ativo: "1",
            withExamData: true,
          },
        );

        const hasExams = Array.isArray(exams) && exams.length > 0;

        if (hasExams) {
          exams.forEach(async (item) => {
            let examReady = false;

            if (item.lpe_url_resultado && item.lpe_url_resultado.length > 0) {
              this.urlExam = item.lpe_url_resultado;
              examReady = true;
            }

            if (item.exam.lle_id) {
              const procedure = item.exam;
              const {
                CO_PROCEDIMENTO,
                NO_PROCEDIMENTO,
                TP_SEXO,
                VL_IDADE_MAXIMA,
                VL_IDADE_MINIMA,
              } = item.exam.procedure;

              const isInactiveExam = procedure.lle_ativo !== "1";

              if (isInactiveExam) {
                this.inactiveExams.push({
                  lpe_id: item.lpe_id,
                  lle_id: procedure.lle_id,
                  lle_codigo_bioslab_mne: procedure.lle_codigo_bioslab_mne,
                  lle_procedimentos: procedure.lle_procedimentos,
                  lle_ativo: procedure.lle_ativo,
                });
              }

              const obj = {
                lpe_id: item.lpe_id,
                nome: procedure.lle_procedimentos,
                codigo: item.lpe_codigo_sigtap,
                resultado: examReady,
                resultadoInfo: examReady ? "Disponível" : "Pendente",
                collectMaterial: item.lpe_material_biologico,
                codigoExameMne: item.lpe_codigo_exame_bioslab_mne,
                pep_id: item.lpe_id_externo_pep,
                lob_nome_objetos: item.lpe_objeto_analise,
                objectAnalisis: {
                  lob_nome_objetos: item.lpe_objeto_analise,
                },
                obligatoriness: {
                  multiplo: procedure.lle_multiplo,
                  dum: procedure.lle_dum,
                  peso: procedure.lle_peso,
                  altura: procedure.lle_altura,
                  cor: procedure.lle_cor,
                  nacionalidade: procedure.lle_nacionalidade,
                  naturalidade: procedure.lle_naturalidade,
                },
                procedure: {
                  co_procedimento: CO_PROCEDIMENTO,
                  no_procecimento: NO_PROCEDIMENTO,
                  tp_sexo: TP_SEXO,
                  vl_idade_maxima: VL_IDADE_MAXIMA,
                  vl_idade_minima: VL_IDADE_MINIMA,
                },
              };

              this.procedureList.push(obj);
            }

            // ORDER ARRAY BY lpe_id_externo_pep
            this.procedureList.sort((a, b) => {
              return a.pep_id - b.pep_id;
            });
          });
        }
      } catch (err) {
        this.$toaster.error(err.message);
      }
    },

    async mountExamByLebId(pLebId, pClearFormBefore = false) {
      const results = await this.searchBioslabLaboratoryExam(pLebId);
      const hasResults = results && results.rows && results.rows.length > 0;

      if (hasResults) {
        if (pClearFormBefore) {
          this.cleanForm();
        }

        await this.mountExam(results.rows[0]);
      }
    },

    async mountExam(pExam, pUpdateVuex = true) {
      this.form.exe_id_unidades_saude = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ID"
      ];

      if (pUpdateVuex) {
        this.updateVuex(pExam);
      }

      if (pExam.leb_id) {
        this.$nextTick(async () => {
          this.$loader.start();

          this.leb_id = pExam.leb_id;

          const pesId =
            pExam.patient && pExam.patient.person
              ? pExam.patient.person.pes_id
              : 0;

          this.$refs.patient.fillPatientById(pesId, {
            bol_numero: pExam.bol_numero,
            int_numero: pExam.int_numero,
          });

          this.form.exe_id_boletins = pExam.leb_id_boletins;
          this.form.exe_id_internacoes = pExam.leb_id_internacoes;

          this.form.selectedUnity = pExam.leb_id_unidades_saude;

          this.protocolNumber = pExam.leb_codigo_solicitacao;

          if (this.protocolNumber && this.protocolNumber.length > 0) {
            this.disableInputsInRequestData = true;
          }

          this.recordNumber = pExam.leb_prontuario;
          this.patientInfo.sexo_sigla = pExam.leb_sexo_paciente;

          const employeeExtraInfo = await this.$store.dispatch(
            "Employee/GET_EMPLOYEE_SECTOR_RELATIONSHIPS",
            {
              criterias: {
                employeeId: Number(pExam.leb_id_profissional_solicitante),
              },
            },
          );

          const employee_vin_id =
            employeeExtraInfo && employeeExtraInfo.length > 0
              ? employeeExtraInfo[0].employeeRelationship.vin_id
              : 0;

          this.getListProcedure(pExam.leb_id);

          this.employeeRequest.id = pExam.leb_id_profissional_solicitante;
          this.isEmployeeSelected = false;

          const employeeData = await GetEmployeeById({
            funId: Number(pExam.leb_id_profissional_solicitante),
          });

          const requester = {
            pes_nome: employeeData?.basicInfo?.pes_nome || null,
            pes_id: employeeData?.basicInfo?.pes_id || null,
            fun_conselho_classe:
              employeeData?.employeeData?.fun_conselho_classe || null,
            vin_id: employee_vin_id,
            person: {
              address: {
                neighborhood: {
                  city: {
                    state: {
                      est_sigla:
                        employeeData?.basicInfo?.address?.neighborhood?.city
                          ?.state?.est_sigla || null,
                    },
                  },
                },
              },
              cpf: {
                cpf_numero: employeeData?.basicInfo?.cpf?.cpf_numero || null,
              },
            },
            employeeRelationship: {
              employee: {
                fun_id: pExam.leb_id_profissional_solicitante,
              },
              vin_id_funcionarios: pExam.leb_id_profissional_solicitante,
            },
          };

          this.form.selectedSector = pExam.leb_id_setores;

          if (this.$refs.requester) {
            this.$refs.requester.forceSelection(requester);
          }

          this.patientInfo.pes_id = pesId;

          this.form.leb_data_agendamento = pExam.leb_data_agendamento
            ? moment(pExam.leb_data_agendamento, "YYYY-MM-DD").format(
                "DD/MM/YYYY",
              )
            : "";

          this.form.leb_hora_agendamento = pExam.leb_hora_agendamento;

          this.patientInfo.info_medicamento = pExam.leb_medicamentos;

          this.patientInfo.menstruada = pExam.leb_menstruada;

          this.form.leb_data_solicitacao = this.$utils.date.BrazilianDateFormat(
            pExam.leb_data_solicitacao,
          );

          if (pExam.leb_informacoes_menstruacao) {
            if (this.isMenstruationRadioTrue) {
              this.patientInfo.data_menstruacao = this.$utils.date.BrazilianDateFormat(
                pExam.leb_informacoes_menstruacao,
              );
            } else {
              this.patientInfo.info_menstruacao =
                pExam.leb_informacoes_menstruacao;
            }
            if (this.$refs.dateMenst) {
              this.$refs.dateMenst.validate();
            }
          }

          const numberWeight = pExam.leb_peso && Number(pExam.leb_peso);
          if (numberWeight && numberWeight > 0) {
            this.patientWeight = this.startedWithZero(pExam.leb_peso)
              ? numberWeight.toFixed(2)
              : numberWeight.toFixed(1);
          } else {
            this.patientWeight = null;
          }

          this.patientHeight =
            pExam.leb_altura && pExam.leb_altura !== "0.00"
              ? pExam.leb_altura
              : null;

          this.handleIntegrationExamErrors(pExam);

          const cidData = await SearchCidByCode({
            cid_codigo: pExam.leb_cid,
          });

          if (cidData && this.$refs.mainCid) {
            this.$refs.mainCid.forceSelection({
              cid_codigo: cidData.cid_codigo,
              cid_nome: cidData.cid_nome,
            });
          }

          if (this.examPatientData?.telephone?.length >= 10) {
            const telefone = this.examPatientData.telephone;
            const numeroTel = telefone.substr(2);
            const numeroDdd = telefone.substr(0, 2);
            this.$refs.telephone.fillValue(numeroTel, numeroDdd);
          }

          this.$loader.finish();
        });
      }
    },

    async handleIntegrationExamErrors(pExam) {
      this.errors = [];

      const hasIntegrationError =
        pExam.biosLabIntegrationsError?.length > 0 &&
        pExam.biosLabIntegrationsError[0].inb_mensagem_erro;

      if (hasIntegrationError) {
        this.errors.push({
          error: hasIntegrationError,
        });
      } else {
        const isScheduleOrPendent =
          pExam.leb_id_laboratoriais_status_pedido === 1 ||
          pExam.leb_id_laboratoriais_status_pedido === 3;

        if (isScheduleOrPendent) {
          const hasError = pExam?.leb_mensagem_erro?.length > 0;

          if (hasError) {
            this.errors.push({ error: pExam.leb_mensagem_erro });
          }

          const hasPatientExamError = pExam.biosLabInfo.some(
            (item) => item.lpe_mensagem_erro,
          );

          if (hasPatientExamError) {
            pExam.biosLabInfo.forEach(async (exam) => {
              const hasError = exam?.lpe_mensagem_erro?.length > 0;
              if (hasError) {
                this.errors.push({
                  error: exam.lpe_mensagem_erro,
                });
              }
            });
          }
        }
      }
    },

    async addProcedure(reason = null) {
      if (
        !this.protocolNumber &&
        !this.disableAddProcedure &&
        this.validateAddProcedures()
      ) {
        this.procedureList.push({
          nome: this.suggestProcedure,
          codigo: this.form.exe_codigo_procedimento,
          collectMaterial: this.exams.collectMaterial,
          objectAnalisis: this.exams.objectAnalisis,
          lob_nome_objetos: this.exams.objectAnalisis.lob_nome_objetos,
          codigoExameMne: this.exams.codigoExameMne,
          procedure: this.procedure,
          obligatoriness: clone(this.obligatoriness),
        });
        this.cleanProcedureInfo();
      }

      if (this.protocolNumber && this.protocolNumber.length > 1) {
        this.showModalConfirmAdd = true;

        if (reason) {
          if (this.validateAddProcedures()) {
            this.procedureList.push({
              nome: this.suggestProcedure,
              codigo: this.form.exe_codigo_procedimento,
              collectMaterial: this.exams.collectMaterial,
              objectAnalisis: this.exams.objectAnalisis,
              lob_nome_objetos: this.exams.objectAnalisis.lob_nome_objetos,
              codigoExameMne: this.exams.codigoExameMne,
              obligatoriness: clone(this.obligatoriness),
              procedure: this.procedure,
              reasonInclusion: reason,
            });
            this.cleanProcedureInfo();
          }
          this.closeModalConfirmAdd();
        }
      }
    },

    canAddMultiplo() {
      const auxObject = {
        nome: this.suggestProcedure,
        codigo: this.form.exe_codigo_procedimento,
        codigoExameMne: this.exams.codigoExameMne,
        material: this.exams.collectMaterial,
        lob_nome_objetos: !isEmpty(this.exams.objectAnalisis)
          ? this.exams.objectAnalisis.lob_nome_objetos
          : null,
      };

      const getProcedureObjectAnalisys = (pProcedure) => {
        return !isEmpty(pProcedure.objectAnalisis)
          ? pProcedure.objectAnalisis.lob_nome_objetos
          : null;
      };

      const singleCheck = (pProcedure) => {
        return (
          pProcedure.nome === auxObject.nome &&
          getProcedureObjectAnalisys(pProcedure) === auxObject.lob_nome_objetos
        );
      };

      let existsEqual;

      if (this.obligatoriness.multiplo !== "S") {
        existsEqual = this.procedureList.some(singleCheck);
      }

      return !existsEqual;
    },

    hasMultiplo(examRules = "N", procedure) {
      let count = 0;

      this.procedureList.forEach((item) => {
        const singleCheck =
          item.nome === procedure.nome &&
          (item.objectAnalisis === procedure.objectAnalisis ||
            item.objectAnalisis.lob_nome_objetos ===
              procedure.objectAnalisis.lob_nome_objetos);

        const multipleCheck =
          singleCheck && item.collectMaterial === procedure.material;

        if (examRules === "N") {
          if (singleCheck) {
            count++;
          }
        } else {
          if (multipleCheck) {
            count++;
          }
        }
      });

      return count <= 1;
    },

    validateAge() {
      const minAge = (this.procedure.vl_idade_minima / 12).toFixed(1);
      const maxAge = (this.procedure.vl_idade_maxima / 12).toFixed(1);

      const patienteBirth = moment(
        this.examPatientData.birthday,
        "DD/MM/YYYY",
      ).format("MM-DD-YYYY");

      const ageDifference = moment().diff(patienteBirth);
      const diffDuration = moment.duration(ageDifference);
      const age = diffDuration.years() + "." + diffDuration.months();

      return (
        parseFloat(age) >= parseFloat(minAge) &&
        parseFloat(age) <= parseFloat(maxAge)
      );
    },

    validateColorRace(examRules = "N") {
      const race = this.examPatientData?.colorAndRace;
      if (
        (this.obligatoriness.cor === "S" || examRules === "S") &&
        (!race ||
          race === "" ||
          race === "NÃO INFORMADO" ||
          race === "SEM INFORMAÇÃO")
      ) {
        this.validate.colorAndRace = false;
        return false;
      }
      this.validate.colorAndRace = true;
      return true;
    },

    validateHeightAndWeight(examRulesWeight = "N", examRulesHeight = "N") {
      const weight = this.patientWeight;

      const height = this.patientHeight;

      if (
        (this.obligatoriness.peso === "S" ||
          examRulesWeight === "S" ||
          this.obligatoriness.altura === "S" ||
          examRulesHeight === "S") &&
        (!weight || !height)
      ) {
        this.validate.heightAndWeight = false;
        return false;
      }
      this.validate.heightAndWeight = true;
      return true;
    },

    weightIsValid(pValue, pErrors) {
      pValue = pValue ? pValue.replace(",", ".") : "";

      if (!pValue || (pValue >= 0.5 && pValue <= 500)) {
        return true;
      } else {
        const errorMessage =
          pValue > 500 ? "O valor máximo é 500." : "O valor mínimo é 0,5.";
        return AlertError(errorMessage, pErrors);
      }
    },

    heightIsValid(pValue, pErrors) {
      pValue = pValue ? pValue.replace(",", ".") : "";
      if (!pValue || (pValue >= 0.2 && pValue <= 2.5)) {
        return true;
      } else {
        const errorMessage =
          pValue > 2.5 ? "O valor máximo é 2,5." : "O valor mínimo é 0,2.";
        return AlertError(errorMessage, pErrors);
      }
    },

    validateNationality(examRules = "N") {
      if (
        (this.obligatoriness.nacionalidade === "S" || examRules === "S") &&
        !this.examPatientData.nationality
      ) {
        this.validate.nationality = false;
        return false;
      }
      this.validate.nationality = true;
      return true;
    },

    validateCity(examRules = "N") {
      if (
        (this.obligatoriness.naturalidade === "S" || examRules === "S") &&
        !this.examPatientData.city
      ) {
        this.validate.city = false;
        return false;
      }
      this.validate.city = true;
      return true;
    },

    validateDateMenstruation(pValue, pErrors) {
      if (this.isMenstruationRadioTrue && pValue) {
        const convertData = this.$utils.date.BrazilianDateToDatabase(pValue);
        if (!pValue || pValue.length < 10) {
          return AlertError(
            "A data da última menstruação é obrigatória.",
            pErrors,
          );
        }

        if (moment(convertData).isSameOrAfter(moment())) {
          return AlertError("A data não pode ser futura.", pErrors);
        }
      }
      return true;
    },

    validateObjectAnalysis() {
      if (this.objectExam.length > 0 && !this.exams.objectAnalisis) {
        return false;
      }
      return true;
    },

    checkSexIsValidFemale() {
      if (this.procedure.tp_sexo === "I" || this.procedure.tp_sexo === "N") {
        return true;
      } else {
        return this.procedure.tp_sexo === "F";
      }
    },

    checkSexIsValidMale() {
      if (this.procedure.tp_sexo === "I" || this.procedure.tp_sexo === "N") {
        return true;
      } else {
        return this.procedure.tp_sexo === "M";
      }
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    confirmCleanForm() {
      this.cleanForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    async editExam(isPending = false) {
      if (isPending) {
        this.buttonDisabled = true;
        this.saveEffectivation(false);
      } else {
        this.buttonDisabled = true;
        this.saveEffectivation(false, EXAM_STATUS_LABORATORY.SOLICITADO);
      }
    },

    async saveSchedule(hasError = false) {
      if (hasError) {
        this.buttonDisabled = true;
        this.saveEffectivation(true, EXAM_STATUS_LABORATORY.PENDENTE);
      } else {
        this.buttonDisabled = true;
        this.saveEffectivation(true, EXAM_STATUS_LABORATORY.AGENDADO);
      }
    },

    async saveSolicitationEffectivation() {
      this.buttonDisabled = true;
      this.saveEffectivation(false, EXAM_STATUS_LABORATORY.SOLICITADO);
    },

    hasInactiveExams() {
      const hasInactiveExams = this.inactiveExams?.length > 0;

      if (hasInactiveExams) {
        this.showModalInactiveExams();

        return true;
      }

      return false;
    },

    async saveEffectivation(
      pSendToBioslab,
      pStatus = null,
      reschedule = false,
    ) {
      try {
        let savedValue;

        const validation = await this.$refs.validator.validate();

        if (!validation) {
          this.buttonDisabled = false;
          return AlertError("Verifique os campos");
        }

        if (!this.hasProcedureList) {
          this.buttonDisabled = false;
          return AlertError(
            "É obrigatório adicionar ao menos um exame no pedido",
          );
        }

        const isSchedule =
          pSendToBioslab &&
          (pStatus === EXAM_STATUS_LABORATORY.AGENDADO ||
            pStatus === EXAM_STATUS_LABORATORY.PENDENTE);

        let hasInactive = false;

        if (isSchedule) {
          hasInactive = this.hasInactiveExams();
        }

        if (hasInactive) {
          return;
        }

        this.examInfo = this.getExamInfo(reschedule);

        const isValidProcedures = await this.isProceduresValid();

        if (isValidProcedures) {
          let status;

          this.hasError
            ? (status = EXAM_STATUS_LABORATORY.PENDENTE)
            : (status = pStatus || this.currentStatusId);

          savedValue = await this.saveExamLaboratoryDetails(status, reschedule);

          if (savedValue) {
            await this.mountExamByLebId(savedValue.leb_id, true);

            if (pSendToBioslab) {
              await this.handleSendToBioslab(savedValue, status);
            } else {
              this.mountPage(savedValue.leb_id_laboratoriais_status_pedido);
            }
          }
        }

        this.buttonDisabled = false;

        const hasErrors = Array.isArray(this.errors) && this.errors?.length > 0;

        if (!hasErrors) {
          this.$router.push("/exam/laboratory");
          this.$toaster.success("Exame salvo com sucesso");
        }
      } catch (error) {
        if (!this.showError) {
          const message = error?.message || error;
          this.$toaster.warning(message);
        }

        this.buttonDisabled = false;
      }
    },

    getExamInfo() {
      const procedureList = this.procedureList.map((procedure) => {
        const { obligatoriness, ...procedureInfo } = procedure;
        return procedureInfo;
      });

      const extraInfoPerson = this.prepareDataToBioslab();

      let info_mentruation = this.patientInfo.info_menstruacao;

      if (this.patientInfo.data_menstruacao && this.isMenstruationRadioTrue) {
        info_mentruation = this.$utils.date.BrazilianDateToDatabase(
          this.patientInfo.data_menstruacao,
        );
      }

      return {
        leb_id: this.leb_id,
        extraInfoPerson,
        procedureList,
        leb_id_pacientes: this.form.exe_id_pacientes,
        leb_id_internacoes: this.form.exe_id_internacoes,
        leb_id_unidades_saude: this.form.selectedUnity,
        leb_id_setores: this.form.selectedSector,
        leb_nome_paciente: this.patientInfo.nome,
        leb_sexo_paciente: this.examPatientData.gender,
        leb_id_profissional_solicitante: this.employeeRequest.id,
        leb_profissional_solicitante: this.employeeRequest.name,
        leb_cpf_profissional: this.employeeRequest.cpf,
        leb_uf_profissional: this.employeeRequest.uf,
        leb_sigla_conselho: this.employeeRequest.conselho,
        leb_numero_conselho: this.employeeRequest.numberCons,
        leb_menstruada: this.patientInfo.menstruada,
        leb_peso: this.patientInfo.peso,
        leb_altura: this.patientInfo.altura,
        leb_medicamentos: this.patientInfo.info_medicamento,
        leb_informacoes_menstruacao: info_mentruation,
        exe_codigo_cid: this.form.exe_codigo_cid,
        exe_id_boletins: this.form.exe_id_boletins,
        exe_codigo_procedimento: this.form.exe_codigo_procedimento,
        exe_id_vinculos: this.form.exe_id_vinculos_solicitante,
        exe_id_unidades_saude: this.form.exe_id_unidades_saude,
        leb_data_agendamento: this.form.leb_data_agendamento
          ? this.$utils.date.BrazilianDateToDatabase(
              this.form.leb_data_agendamento,
            )
          : null,
        leb_hora_agendamento: this.form.leb_hora_agendamento
          ? this.form.leb_hora_agendamento
          : null,
        leb_data_solicitacao: this.$utils.date.BrazilianDateToDatabase(
          this.form.leb_data_solicitacao,
        ),
        leb_prontuario: Number(this.recordNumber),
        leb_codigo_solicitacao: this.protocolNumber,
      };
    },

    async saveExamLaboratoryDetails(pStatus) {
      const validateProcedure = await this.validateProcedureList();

      if (!validateProcedure) {
        return AlertError(
          "As informações da menstruação podem ter sido alteradas. Favor verificar se os campos estão preenchidos corretamente.",
        );
      }

      let procedureInfo = [];

      let examInfo = null;

      examInfo =
        pStatus === EXAM_STATUS_LABORATORY.AGENDADO
          ? PrepareBioslabExamToSave(
              this.examInfo,
              EXAM_STATUS_LABORATORY.SOLICITADO,
            )
          : PrepareBioslabExamToSave(this.examInfo, pStatus);

      this.examInfo.procedureList.forEach((item, index) => {
        const bioslabLaboratoryPatientExams = PreparedBioslabExtraInfoToSave(
          item,
        );
        procedureInfo = procedureInfo.concat(bioslabLaboratoryPatientExams);
      });

      const resultsID = await this.$store.dispatch("Bioslab/SAVE_TO_BIOSLAB", {
        examInfo,
        procedureInfo,
      });

      if (this.protocolNumber && this.protocolNumber.length > 1) {
        this.procedureListToRemove.forEach(async (item) => {
          const auxItem = await this.$store.dispatch(
            "Bioslab/SEARCH_BIOSLAB_LABORATORY_PATIENT_EXAMS",
            {
              lpe_id_laboratoriais_exames_bioslab: item.lpe_id,
              lpe_ativo: "0",
            },
          );
          if (item.lpe_id === auxItem.lpe_id) {
            await this.$store.dispatch(
              "Bioslab/SAVE_BIOSLAB_LABORATORY_PATIENT_EXAMS",
              item,
            );
          }
        });
      }

      return !examInfo || !resultsID || !resultsID.SaveToBioslab
        ? null
        : {
            ...examInfo,
            ...resultsID.SaveToBioslab,
          };
    },

    async handleSendToBioslab(pExamInfo, pStatus) {
      let examsStatus = null;
      let results = [];
      let leb_id = pExamInfo.leb_id;

      try {
        const isNotRequested = pStatus !== EXAM_STATUS_LABORATORY.SOLICITADO;

        if (isNotRequested) {
          examsStatus = await this.sendToBioslab({ leb_id });

          results = await this.searchBioslabLaboratoryExam(leb_id);

          const withoutExamStatus =
            !examsStatus || typeof examsStatus === "string";

          if (withoutExamStatus) {
            this.cleanForm();
            this.loader = 2;
            this.mountExam(results.rows[0]);

            const isStatusScheduled =
              pStatus === EXAM_STATUS_LABORATORY.AGENDADO;

            isStatusScheduled
              ? this.mountPage(EXAM_STATUS_LABORATORY.SOLICITADO)
              : this.mountPage(pStatus);
          } else {
            const isScheduled =
              examsStatus === EXAM_STATUS_LABORATORY.AGENDADO &&
              pStatus === EXAM_STATUS_LABORATORY.AGENDADO;

            if (isScheduled) {
              this.examInfo.leb_id = leb_id;
              pExamInfo = PrepareBioslabExamToSave(this.examInfo, pStatus);

              const proceduresWithIds = results.rows[0].biosLabInfo;

              const resultsID = await this.$store.dispatch(
                "Bioslab/SAVE_TO_BIOSLAB",
                {
                  pExamInfo,
                  proceduresWithIds,
                },
              );

              const hasResult = resultsID && resultsID.SaveToBioslab;

              if (hasResult) {
                leb_id = resultsID.SaveToBioslab.leb_id;
                examsStatus =
                  resultsID.SaveToBioslab.leb_id_laboratoriais_status_pedido;
              }
            }

            await this.mountExamByLebId(leb_id, true);
            this.mountPage(examsStatus || pStatus);
          }
        }
      } catch (pError) {
        throw new Error(pError);
      } finally {
        const data = await this.searchBioslabLaboratoryExam(leb_id);
        const hasResults = data?.rows && data.rows.length > 0;

        if (hasResults) {
          await this.handleIntegrationExamErrors(data.rows[0]);

          const hasErrors = this.errors && this.errors.length > 0;

          if (hasErrors) {
            this.showError = hasErrors;
          }
        }
      }
    },

    async sendToBioslab({ leb_id }) {
      this.loading = true;
      this.loader = 0;

      try {
        const item = {
          leb_id,
          uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        };

        const send = await this.$store.dispatch(
          "Exam/Laboratory/SEND_BIOSLAB_REQUEST_SERVICE",
          { lebId: item.leb_id, unsId: item.uns_id },
        );

        this.loader = send === 2 ? 1 : 2;

        return send;
      } catch (pErr) {
        this.closeLoader();
        throw new Error(pErr);
      }
    },

    async searchBioslabLaboratoryExam(pLebId) {
      const params = { leb_id: pLebId };
      return this.$store.dispatch(
        "Exam/Laboratory/SEARCH_BIOSLAB_LABORATORY_EXAM",
        params,
      );
    },

    async selectingPatient(pPatient) {
      const withoutPerson = !pPatient || !pPatient.pes_id;

      if (withoutPerson) {
        this.cleanPersonalData();
        this.cleanProcedures();
        this.cleanPatientData();

        this.bulletinNumber = "";
        this.hospitalizationNumber = "";
        this.protocolNumber = null;
        this.recordNumber = null;
        this.form.pes_id = null;
        this.patientInfo.othersData = {};
        this.mainCid = "";
        this.patientIsValid = false;
        this.employeeRequest = {
          id: null,
          name: "",
          cpf: "",
          uf: "",
          conselho: "",
          numberCons: "",
        };
        this.form.selectedUnity = null;
        this.form.selectedSector = null;
        this.isEmployeeSelected = false;
        this.suggestRequestEmployee = "";
        this.patientInfo.nome = "";
        return;
      }

      if (!pPatient?.patient) {
        const result = await this.$store.dispatch(
          "Person/Patient/CREATE_PATIENT_FOR_PERSON",
          {
            pes_id: pPatient.pes_id,
          },
        );
        pPatient.patient = result;
      }

      this.form.pes_id = pPatient.pes_id;

      this.form.exe_id_pacientes =
        pPatient && pPatient.patient ? pPatient.patient.pac_id : null;

      this.recordNumber =
        pPatient && pPatient.patient
          ? String(pPatient.patient.pac_prontuario_unico)
          : null;

      if (this.form.pes_id) {
        this.buttons.editPerson = true;
      }

      this.patientInfo.sexo_sigla = pPatient.gender
        ? pPatient.gender.sex_sigla
        : pPatient.sex_sigla;

      const existPhone =
        pPatient.telephones.rows && pPatient.telephones.rows.length > 0;

      const birthday =
        pPatient && pPatient.pes_nascimento ? pPatient.pes_nascimento : null;

      this.patientInfo.nome = pPatient?.pes_nome;

      this.form.exe_id_boletins =
        pPatient?.patient?.patient_emergency?.bulletin?.bol_id;

      this.form.exe_id_internacoes =
        pPatient?.patient?.patient_hospitalization?.hospitalization?.int_id;

      this.hospitalizationNumber =
        pPatient?.patient?.patient_hospitalization?.hospitalization?.int_numero;
      this.form.bulletinNumber =
        pPatient?.patient?.patient_emergency?.bulletin?.bol_numero;

      this.patientInfo.othersData = {
        pes_id: pPatient?.pes_id,
        pes_nome: pPatient?.pes_nome,
        pes_nascimento: moment(birthday).format("DD/MM/YYYY"),
        crs_numero: pPatient?.crs_numero || pPatient?.cns?.crs_numero,
        mun_nome: pPatient?.address?.neighborhood?.city?.mun_nome || null,
        nac_nome: pPatient?.nationality?.nac_nome || null,
        est_sigla: pPatient?.address?.neighborhood?.city?.state?.est_sigla,
        bai_nome: pPatient?.address?.neighborhood?.bai_nome,
        end_logradouro: pPatient?.address?.end_logradouro,
        end_numero: pPatient?.address?.end_numero,
        end_cep: pPatient?.address?.end_cep,
        end_complemento: pPatient?.address?.end_complemento,
        pes_etnias_id: pPatient?.ethnicity?.etn_id,
        pes_etnias_nomes: pPatient?.ethnicity?.etn_nome,
        tlg_nome: pPatient?.address?.publicPlaceType?.tlg_nome,
        tel_full: existPhone
          ? `(${pPatient?.telephones?.rows[0]?.ddd?.ddd_codigo}) ${pPatient?.telephones?.rows[0]?.tel_numero}`
          : null,
        naturalidade: pPatient?.city?.mun_nome || null,
      };

      this.setExamOrPatient();

      try {
        this.patientIsValid = true;
      } catch (Err) {
        this.$toaster.warning(Err);
        this.patientIsValid = false;
      }
    },

    prepareDataToBioslab() {
      return {
        leb_nome_paciente: this.patientInfo.othersData.pes_nome,
        leb_sexo_paciente: this.examPatientData.gender,
        leb_endereco_paciente: this.patientInfo.othersData.end_logradouro,
        leb_id_pacientes: this.patientInfo.othersData.pes_id,
        leb_uf_paciente: this.patientInfo.othersData.est_sigla,
        leb_nacionalidade_paciente: this.examPatientData.nationality,
        leb_naturalidade_paciente: this.examPatientData.city,
        leb_cns_paciente: this.examPatientData.cns,
        leb_municipio_paciente: this.patientInfo.othersData.mun_nome,
        leb_bairro_paciente: this.patientInfo.othersData.bai_nome,
        leb_end_numero_paciente: this.patientInfo.othersData.end_numero,
        leb_cep_paciente: this.patientInfo.othersData.end_cep,
        leb_end_complemento_paciente: this.patientInfo.othersData
          .end_complemento,
        leb_cor_paciente: this.examPatientData.colorAndRace,
        leb_nascimento_paciente: moment(
          this.examPatientData.birthday,
          "DD/MM/YYYY",
        ).format("YYYY-MM-DD"),
        leb_logradouro_paciente: this.patientInfo.othersData.tlg_nome,
        leb_telefone_paciente: `${this.$refs.telephone.dddItem.ddd_codigo}${this.$refs.telephone.telephone}`,
      };
    },

    async selectingRequestEmployee(pRequestEmployee) {
      const hasRequestEmployee = !isEmpty(pRequestEmployee.source);
      if (hasRequestEmployee) {
        const source = pRequestEmployee.source;
        const { person } = source;

        const employeeId = source?.employeeRelationship?.employee?.fun_id;

        const employeeClassConcil = await this.$store.dispatch(
          "Employee/GET_CLASS_COUNCIL",
          {
            employeeId: employeeId,
          },
        );

        this.isEmployeeSelected = true;
        this.form.exe_id_vinculos_solicitante = source.vin_id;
        this.employeeRequest.name = source.pes_nome;
        this.employeeRequest.cpf = person.cpf.cpf_numero;
        this.employeeRequest.id = employeeId;

        const hasEmployeeClassConcil =
          Array.isArray(employeeClassConcil) &&
          Object.keys(employeeClassConcil).length > 0;

        this.employeeRequest.uf = hasEmployeeClassConcil
          ? employeeClassConcil[0].coc_uf_conselho
          : null;

        this.employeeRequest.numberCons = hasEmployeeClassConcil
          ? employeeClassConcil[0].coc_numero_conselho
          : null;

        this.employeeRequest.conselho = hasEmployeeClassConcil
          ? employeeClassConcil[0].coc_tipo_conselho
          : null;
      } else {
        this.employeeRequest = {
          id: null,
          name: "",
          cpf: "",
          uf: "",
          conselho: "",
          numberCons: "",
        };
        this.isEmployeeSelected = false;
      }
    },

    selectingProcedure(pRequestProcedure) {
      const request =
        pRequestProcedure && pRequestProcedure.source
          ? pRequestProcedure.source
          : {};

      const bioslabCode = request.lle_codigo_bioslab_mne
        ? request.lle_codigo_bioslab_mne.trim()
        : "";

      if (
        !pRequestProcedure.source ||
        !pRequestProcedure.source.lle_codigo_sigtap
      ) {
        this.hasProcedure = false;
        this.exams.collectMaterial = "";
        this.exams.objectAnalisis = {
          lob_nome_objetos: "",
          lob_id: null,
        };
      } else {
        this.hasProcedure = true;
        this.form.exe_codigo_procedimento =
          pRequestProcedure.source.lle_codigo_sigtap;

        this.examObjects(
          pRequestProcedure.source.lle_codigo_sigtap,
          bioslabCode,
        );

        this.exams.codigoExameMne =
          pRequestProcedure.source.lle_codigo_bioslab_mne;
        this.exams.collectMaterial = pRequestProcedure.source.lle_material;

        this.matbio = this.exams.collectMaterial === "MATBIO";

        if (this.matbio === true && this.exams.collectMaterial === "MATBIO") {
          this.exams.collectMaterial = "";
        }

        this.obligatoriness = {
          multiplo: pRequestProcedure.source.lle_multiplo,
          dum: pRequestProcedure.source.lle_dum,
          peso: pRequestProcedure.source.lle_peso,
          altura: pRequestProcedure.source.lle_altura,
          cor: pRequestProcedure.source.lle_cor,
          nacionalidade: pRequestProcedure.source.lle_nacionalidade,
          naturalidade: pRequestProcedure.source.lle_naturalidade,
        };

        this.procedure = {
          no_procecimento: pRequestProcedure.source.procedure.NO_PROCEDIMENTO,
          tp_sexo: pRequestProcedure.source.procedure.TP_SEXO,
          vl_idade_minima: pRequestProcedure.source.procedure.VL_IDADE_MINIMA,
          vl_idade_maxima: pRequestProcedure.source.procedure.VL_IDADE_MAXIMA,
        };
      }
    },

    selectingCidCode(pCidCode) {
      if (pCidCode.source) {
        const cid = pCidCode.source;
        this.form.exe_codigo_cid = cid.cid_codigo;
      } else {
        this.form.exe_codigo_cid = "";
        this.mainCid = "";
      }
    },

    afterSaveBioslab(pData) {
      this.patientInfo.sexo_sigla = pData.leb_sexo_paciente;
      if (
        this.examData.leb_sexo_paciente === "F" &&
        pData.leb_sexo_paciente !== this.examData.leb_sexo_paciente
      ) {
        this.patientInfo.info_menstruacao = "";
        this.patientInfo.menstruada = "N";
      }

      this.updateVuex({
        ...this.examData,
        ...pData,
      });
    },

    updateVuex(pExamData) {
      this.examData = pExamData;
      return this.$store.commit("Exam/Laboratory/SET_EXAMINFO", {
        ...pExamData,
      });
    },

    removeFromProcedureListProtocolNumber(reason) {
      if (reason) {
        const bioslabLaboratoryPatientExams = {
          lpe_id: this.procedureList[this.selectLineIndex].lpe_id,
          lpe_motivo_exclusao: reason,
        };
        this.procedureListToRemove.push({ bioslabLaboratoryPatientExams });
        this.procedureList.splice(this.selectLineIndex, 1);
        this.selectLine = null;
        this.selectLineIndex = null;
        this.$refs.smartTable.cleanSelectRow();
        this.closeModalConfirmRemove();
      }
    },

    async removeFromProcedureList() {
      if (this.protocolNumber && this.protocolNumber.length > 1) {
        this.showModalConfirmRemove = true;
      } else {
        this.procedureList.splice(this.selectLineIndex, 1);
        this.selectLine = null;
        this.selectLineIndex = null;
        this.$refs.smartTable.cleanSelectRow();
      }
    },

    updateInactiveExams(pExamId) {
      return this.inactiveExams.filter((exam) => exam.lpe_id !== pExamId);
    },

    removeInactiveExams() {
      const hasInactiveExams =
        Array.isArray(this.inactiveExams) && this.inactiveExams.length > 0;

      if (hasInactiveExams) {
        const inactiveIds = [];

        this.inactiveExams.forEach((exam) => {
          inactiveIds.push(exam.lpe_id);

          this.procedureListToRemove.push({
            lpe_id: exam.lpe_id,
            lpe_motivo_exclusao: "Exame Inativo",
          });
        });

        const activeExams = this.procedureList.filter((procedure) => {
          const isOfPep = procedure?.lpe_id > 0;
          return isOfPep ? !inactiveIds.includes(procedure.lpe_id) : true;
        });

        this.inactiveExams = [];
        this.procedureList = activeExams;
        this.closeModalInactiveExams();
        this.saveEffectivation(true, EXAM_STATUS_LABORATORY.PENDENTE);
      }
    },

    sanitizeNumber(pValue) {
      return !pValue
        ? ""
        : pValue.replace(",", ".").replace(/[ a-zA-Z á]/g, "");
    },

    patientRecord() {
      this.$store.commit(
        "Person/Patient/SET_LAST_PERSON_ID_SAVE",
        this.form.pes_id,
      );
      this.$store.commit("Person/Patient/SET_PERSON_ID", this.form.pes_id);
      this.$router.push({
        name: `exam.${
          this.examData.leb_id_laboratoriais_status_pedido
            ? "edit-laboratory"
            : "laboratory"
        }.patient-edit`,
      });
    },

    registerPatient() {
      this.$router.push({ name: "exam.laboratory.patient-details" });
    },

    showModalError(pValue) {
      this.showError = true;
      this.errors = pValue;
    },

    closeErrorModal() {
      this.showError = false;
      if (this.$refs.loader) {
        this.$refs.loader.close();
      }
    },

    showModalTags() {
      this.showTags = true;
    },

    closeTagModal() {
      this.showTags = false;
    },

    showModalConfirmRemoveExam(pItem, pIndex) {
      this.showModalConfirmRemove = true;
    },

    showModalInactiveExams() {
      this.showInactiveExams = true;
    },

    closeModalInactiveExams() {
      this.showInactiveExams = false;
      this.buttonDisabled = false;
    },

    closeModalConfirmRemove() {
      this.showModalConfirmRemove = false;
    },

    closeModalConfirmAdd() {
      this.showModalConfirmAdd = false;
    },

    closeLoader() {
      this.loading = false;
    },

    cleanForm() {
      this.buttons.emblem = false;
      this.sectorLink = null;
      this.cleanDataRequest();
      this.cleanPersonalData();
      this.cleanProcedures();
      this.cleanPatientData();
      this.$refs.telephone.telephone = null;
      this.$refs.telephone.dddItem = null;
      this.selectLine = null;
      this.selectLineIndex = null;
      this.$store.commit("Exam/Laboratory/RESET_EXAMINFO");
      this.cleanFieldsValidation();
    },

    cleanPatientData() {
      this.examPatientData = {};
    },

    cleanDataRequest() {
      this.leb_id = 0;
      this.bulletinNumber = "";
      this.hospitalizationNumber = "";
      this.recordNumber = "";
      this.suggestPatient = "";
      this.patientInfo = {
        sexo_sigla: "",
        nasc: "",
        nome: "",
        info_medicamento: "",
        info_menstruacao: "",
        menstruada: "N",
      };
      this.validate = {
        colorAndRace: true,
        heightAndWeight: true,
        nationality: true,
        city: true,
      };
      this.protocolNumber = null;
      this.mainCid = null;
      this.suggestRequestEmployee = "";
      this.employeeRequest = {
        id: null,
        name: "",
        cpf: "",
        uf: "",
        conselho: "",
        numberCons: "",
      };
      this.isEmployeeSelected = false;
      if (this.$refs.patient) this.$refs.patient.cleanPatientInfo();
      this.cleanFieldsValidation();
      this.form = {
        pes_id: null,
        exe_id: 0,
        selectedUnity: null,
        selectedSector: null,
        exe_id_unidades_saude: null,
        exe_id_pacientes: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
        exe_id_boletins: null,
        exe_id_internacoes: null,
        exe_codigo_procedimento: null,
        exe_codigo_cid: null,
        exe_hora_exame_sol: null,
        leb_data_solicitacao: "",
        leb_data_agendamento: "",
        leb_hora_agendamento: "",
        exe_id_exames_efetivacao: null,
      };
      this.errors = [];
      this.showError = false;
    },

    cleanPatient() {
      this.bulletinNumber = "";
      this.hospitalizationNumber = "";
      this.recordNumber = "";
      this.form.exe_id_pacientes = null;
      this.suggestPatient = "";
      this.patientInfo = {
        sexo_sigla: "",
        nasc: "",
        nome: "",
        info_medicamento: "",
        info_menstruacao: "",
        menstruada: "N",
      };
      this.suggestRequestEmployee = "";
      this.employeeRequest = {
        id: null,
        name: "",
        cpf: "",
        uf: "",
        conselho: "",
        numberCons: "",
      };
    },

    cleanPersonalData() {
      this.patientWeight = "";
      this.patientHeight = "";
      this.patientInfo.info_medicamento = "";
      this.patientInfo.info_menstruacao = "";
      this.patientInfo.menstruada = "N";
      this.patientInfo.data_menstruacao = "";
    },

    cleanProcedures() {
      this.suggestProcedure = "";
      this.procedureList = [];
      this.procedureListToRemove = [];
      this.exams = {
        collectMaterial: "",
        objectAnalisis: "",
        codigoExameMne: "",
      };
      this.index = null;
    },

    cleanProcedureInfo() {
      this.hasProcedure = false;
      this.suggestProcedure = "";
      this.exams.collectMaterial = "";
      this.matbio = false;
      this.exams.codigoExameMne = "";
      this.exams.objectAnalisis = {
        lob_nome_objetos: "",
        lob_id: null,
      };
      this.objectExam = [];
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "ethnicity",
        "nationality",
        "city",
        "telephone",
        "cns",
        "requester",
        "unitHealth",
        "sector",
        "requestData",
        "scheduleDate",
        "scheduleHour",
        "weight",
        "height",
        "dateMenst",
        "procedure",
        "material",
        "objectAnalisis",
      ];
      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
