import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import ImportCompetence from "$billing/submodules/import-competence/view/ImportCompetence.vue";

export default {
  path: "/billing/import-competence",
  name: "billing.import-competence",
  components: {
    default: ImportCompetence,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Faturamento", link: OpenMenuModule("billing") },
      { label: "Importar Competências", link: "/import-competence" },
    ],
  },
};
