import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreateAbEsus($abEsus: AbEsusInputType!) {
    CreateAbEsus(abEsus: $abEsus) {
      pae_id
    }
  }
`;

const CreateAbEsus = async (store, abEsusInfo) => {
  const variables = abEsusInfo;

  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.CreateAbEsus;
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};

export default CreateAbEsus;
