<style src="./RgForm.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="rg-form">
    <form @submit.prevent>
      <slot />
      <slot name="command">
        <section class="rg-form--commands align">
          <slot name="aftercommand" />
          <RgCleanButton
            v-if="hasClean"
            :class="{ action: !canClean }"
            :disabled="!canClean"
            large
            @click="cleanOperation"
          />

          <MediumButton
            v-if="hasCancel"
            title="Cancelar"
            label="Cancelar"
            :disabled="!canCancel"
            :backgroundColor="'#e36f40'"
            @click="cancelOperation"
          />

          <RgSubmitButton
            v-if="showSubmit"
            ref="btnSubmit"
            medium
            class="submit"
            :label="actionLabel"
            :wait-label="actionLabelWait"
            :done-label="actionLabelDone"
            :disabled="!canSubmit"
            @submit="doAction"
            @complete="actionCompleted"
          />
          <hr />
          <MediumButton
            v-show="showNextButton"
            title="Próximo"
            label="Próximo"
            @click="nextButtonProgress"
          />
        </section>
      </slot>
    </form>
  </section>
</template>

<script>
import {
  RgSubmitButton,
  RgValidatorForm,
  MediumButton,
  RgCleanButton,
} from "../../primitive";
export default {
  name: "RgForm",
  components: {
    RgSubmitButton,
    MediumButton,
    RgCleanButton,
  },
  extends: { ...RgValidatorForm },
  props: {
    showNextButton: {
      type: Boolean,
      default: false,
    },
    hasCancel: {
      type: Boolean,
      default: false,
    },
    hasClean: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
    actionLabel: {
      type: String,
      default: "Salvar",
    },
    actionLabelWait: {
      type: String,
      default: "Salvando",
    },
    actionLabelDone: {
      type: String,
      default: "Finalizado!",
    },
    actionExternalDisabledStatus: {
      type: Boolean,
      default: false,
    },
    cancelExternalDisabledStatus: {
      type: Boolean,
      default: false,
    },
    cleanExternalDisabledStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cancelDisabledStatus: false,
      cleanDisabledStatus: false,
      actionDisabledStatus: false,
    };
  },
  computed: {
    canSubmit() {
      return !this.actionDisabledStatus && !this.actionExternalDisabledStatus;
    },
    canCancel() {
      return !this.cancelDisabledStatus && !this.cancelExternalDisabledStatus;
    },
    canClean() {
      return !this.cleanDisabledStatus && !this.cleanExternalDisabledStatus;
    },
  },
  methods: {
    async doAction() {
      const result = await this.validate();
      if (result) {
        this.cancelDisabledStatus = true;
        this.cleanDisabledStatus = true;
        this.$emit("submit", null, this.requestAction);
      } else {
        this.$emit("submit", this.getError(), null);
        this.$refs.btnSubmit.fail();
      }
    },
    cancelOperation() {
      this.$emit("cancel");
    },
    cleanOperation() {
      this.$emit("clean");
    },
    requestAction() {
      this.actionCompleted();
    },
    actionCompleted() {
      this.cancelDisabledStatus = false;
      this.cleanDisabledStatus = false;
      this.$refs.btnSubmit.actionNormalState();
    },
    nextButtonProgress() {
      this.$emit("nextClicked", true);
    },
  },
};
</script>
