<style src="./RgSuggestPatient.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient">
    <RgSuggest
      ref="innerSuggest"
      v-model="inputValue"
      :disabled="disabled"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataItem"
        :idx="idx"
        :item="item"
        class="patient-item"
      >
        <div class="patient-row-top">
          <span class="patient-name"> {{ item.pes_nome }} </span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";

import SearchPatient from "./action/SearchPatient";

export default {
  name: "RgSuggestPatient",
  components: {
    RgSuggest,
    RgSuggestItem,
  },

  mixins: [RgSuggestMixin],
  props: {
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "pes_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm, pLimit) {
      return SearchPatient({ strNome: pSearchTerm, limit: pLimit });
    },
    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.pes_nome) && pValue.pcf_id > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            pcf_id: pValue.pcf_id,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    validate() {
      this.$refs.innerSuggest.validate();
    },
  },
};
</script>
