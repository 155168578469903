import { render, staticRenderFns } from "./RgCleanButton.vue?vue&type=template&id=3c480d6a&scoped=true&"
import script from "./RgCleanButton.vue?vue&type=script&lang=js&"
export * from "./RgCleanButton.vue?vue&type=script&lang=js&"
import style0 from "./RgCleanButton.scss?vue&type=style&index=0&id=3c480d6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c480d6a",
  null
  
)

export default component.exports