import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const duplicationErrors = {
  BloquearDuplicidadeAoInserirFilaExameException: `Paciente já consta na fila para esse procedimento.`,
  InformarDuplicidadeAoInserirFilaExameException: `Aviso: paciente já consta na fila para esse procedimento`,
};

export default async (context, { variables, validarDuplicidade }) => {
  const sendData = {
    arrFormData: variables,
    validarDuplicidade: validarDuplicidade,
  };
  return AuthLegacyRequest.post(
    "exames2/controller-fila-exame/alterar",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: duplicationErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
