<style src="./RgInputCpf.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model="inputValue"
            v-mask="'###.###.###-##'"
            :placeholder="placeholder"
            :tabindex="disabled ? -1 : tabIndex"
            :disabled="disabled"
            :readonly="disabled"
            autocomplete="off"
            type="text"
            :class="{ 'on-error': error.length > 0 }"
            @blur="validateBlur"
            @paste="onPaste"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { mask } from "vue-the-mask";
import { RgFormBase } from "~tokio/foundation/container";
import {
  RgInputMixin,
  RgValidatorMixin,
  RgValidationAlert,
} from "~tokio/primitive";
import Cpf from "./utils/RgInputCpfHelper";

export default {
  name: "RgInputCpf",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "CPF",
    },
    placeholder: {
      type: String,
      default: "000.000.000-00",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anotherRules: {
        checkCpf: (pValue, pErrs) => {
          return Cpf.checkDigit(pValue, pErrs, this.isRequired);
        },
      },

      error: [],
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  methods: {
    onPaste(event) {
      const value = event.clipboardData.getData("text");
      this.$emit("onPaste", value);
    },
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
