<template>
  <div v-if="show">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      class="modal-webcam"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">{{ title }}</h1>
      </div>
      <div slot="body" class="body">
        <FormBase title="Obter Foto">
          <div class="webcam-container">
            <label class="input">
              Câmera:
              <select v-model="selectedCamera" class="select">
                <option
                  v-for="camera in cameras"
                  :key="camera.deviceId"
                  :value="camera.deviceId"
                >
                  {{ camera.label }}
                </option>
              </select>
            </label>

            <video
              v-show="!existFile && !alreadyHasImage"
              ref="webcam"
              class="webcam"
              autoplay
              playsinline
            />

            <canvas
              v-show="existFile && !alreadyHasImage"
              ref="canvas"
              class="webcam"
            ></canvas>

            <img v-if="alreadyHasImage" :src="alreadyHasImage" class="img" />

            <div class="actions">
              <SmallButton
                class="-photo"
                label="Tirar foto"
                backgroundColor="#1E88A9"
                :class="{ disable: existFile || !!alreadyHasImage }"
                :disabled="existFile || !!alreadyHasImage"
                @click="snap"
              >
                <IconPhoto slot="icon" class="svg" />
              </SmallButton>

              <RgLessButton
                title="Remover foto"
                :class="{ disable: !existFile && !alreadyHasImage }"
                :disabled="!existFile && !alreadyHasImage"
                @click="confirmRemovePhoto"
              />
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <div class="control-painel">
          <RgCancelButton medium class="btn-margim" @click="close" />

          <RgConfirmButton medium type="button" @click="confirmUpload" />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgConfirmButton,
  RgCancelButton,
  RgLessButton,
  IconPhoto,
  SmallButton,
} from "~tokio/primitive";

import { FormBase } from "~tokio/foundation";
import Webcam from "webcam-easy";
import SendFile from "./actions/SendFile";
let webcam = null;

export default {
  name: "ModalWebcam",

  components: {
    RgBaseModal,
    RgConfirmButton,
    RgCancelButton,
    RgLessButton,
    FormBase,
    IconPhoto,
    SmallButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Tirar uma Foto pela Camera",
    },
    hasImage: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      cameras: [],
      selectedCamera: null,
      dataupload: null,
      file: null,
      alreadyHasImage: "",
    };
  },

  computed: {
    existFile() {
      return this.file !== null;
    },
  },

  watch: {
    selectedCamera(pValue) {
      if (this.show) {
        webcam.stop();
        webcam._selectedDeviceId = pValue;
        webcam.start();
      }
    },

    async show(pValue) {
      if (pValue) {
        if (this.hasImage && this.hasImage.url) {
          this.alreadyHasImage = this.hasImage.url;
        }

        webcam
          .start()
          .then((result) => {
            if (webcam.webcamList.length <= 0) {
              webcam.stop();
              this.$emit("close");
              this.$toaster.error(
                "Verifique as Configuraçoes do seu Computador",
                "Nenhuma webcam encontrada",
              );
            }
            this.cameras = webcam.webcamList;
            this.selectedCamera = this.cameras[0].deviceId;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        webcam.stop();
      }
    },
  },
  mounted() {
    webcam = new Webcam(this.$refs.webcam, "user", this.$refs.canvas);
  },
  methods: {
    confirmRemovePhoto() {
      this.file = null;
      this.alreadyHasImage = "";
    },

    snap() {
      const data = webcam.snap();
      this.file = data;
    },
    confirmUpload() {
      if (!this.file) {
        this.$emit("upload", null);
        this.close();
      } else {
        // send to backend uploads and emit filename
        this.$loader.start("Enviando Arquivo...");
        SendFile(this.file)
          .then((result) => {
            this.$loader.finish();
            this.$emit("upload", result.data.fileInfo);
            this.$emit("close");
            this.file = null;
            this.$refs.canvas
              .getContext("2d")
              .clearRect(
                0,
                0,
                this.$refs.canvas.width,
                this.$refs.canvas.height,
              );
          })
          .catch((err) => {
            this.file = null;
            this.$loader.finish();
            this.$toaster.error("Falha ao enviar o arquivo:" + err.message);
          });
      }
    },

    uploadImage(pFile) {
      this.file = this.$refs.file.files[0];
    },

    close() {
      this.dataupload = null;
      this.img_data = null;
      this.$emit("close");
    },
  },
};
</script>
