<template lang="html">
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8359 0.0996094C14.1295 1.39314 15.423 2.68667 16.617 3.98021H16.5175C15.3235 3.98021 14.1295 3.98021 13.0349 3.98021C12.9354 3.98021 12.8359 3.98021 12.8359 3.7812C12.8359 2.58717 12.8359 1.39314 12.8359 0.298614C12.8359 0.199112 12.8359 0.0996094 12.8359 0.0996094Z"
      fill="white"
    />
    <path
      d="M8.06024 9.25342H6.86621V11.8405H8.06024C8.45825 11.8405 8.85626 11.8405 9.05527 11.741C9.25427 11.6415 9.55278 11.542 9.65228 11.343C9.75178 11.144 9.85128 10.8455 9.85128 10.547C9.85128 10.1489 9.75178 9.85043 9.55278 9.65143C9.35377 9.35292 8.85626 9.25342 8.06024 9.25342Z"
      fill="white"
    />
    <path
      d="M16.8159 5.27363C16.8159 5.07463 16.7164 4.97512 16.5174 4.97512C16.4179 4.97512 16.4179 4.97512 16.3184 4.97512C15.1244 4.97512 13.8308 4.97512 12.6368 4.97512C12.5373 4.97512 12.5373 4.97512 12.4378 4.97512C11.9403 4.97512 11.7413 4.77612 11.7413 4.27861C11.7413 4.1791 11.7413 4.1791 11.7413 4.0796C11.7413 2.88557 11.7413 1.59204 11.7413 0.39801C11.7413 0 11.7413 0 11.3433 0C8.45771 0 5.47264 0 2.58706 0C2.38806 0 2.18905 0 1.99005 0.0995025C0.79602 0.39801 0 1.39303 0 2.58706C0 7.56219 0 12.4378 0 17.4129C0 17.8109 0.0995025 18.3085 0.39801 18.7065C0.79602 19.403 1.49254 19.9005 2.28856 20H2.38806C2.48756 20 2.58706 20 2.58706 20C6.56716 20 10.4478 20 14.4279 20C14.6269 20 14.8259 20 15.0249 19.9005C16.0199 19.5025 16.7164 18.5075 16.7164 17.4129C16.7164 13.4328 16.7164 9.45274 16.7164 5.47264C16.8159 5.37313 16.8159 5.27363 16.8159 5.27363ZM10.8458 12.5373C10.3483 13.0348 9.55224 13.2338 8.45771 13.2338H6.86567V15.6219C6.86567 15.9204 6.76617 16.2189 6.66667 16.4179C6.56716 16.6169 6.26866 16.6169 5.97015 16.6169C5.67164 16.6169 5.47264 16.5174 5.37313 16.3184C5.27363 16.1194 5.17413 15.9204 5.17413 15.6219V9.05473C5.17413 8.65672 5.27363 8.35821 5.47264 8.25871C5.67164 8.1592 5.87065 7.9602 6.26866 7.9602H8.45771C9.15423 7.9602 9.65174 8.0597 9.95025 8.1592C10.3483 8.25871 10.6468 8.45771 10.8458 8.65672C11.0448 8.85572 11.3433 9.15423 11.4428 9.45274C11.5423 9.75124 11.6418 10.1493 11.6418 10.5473C11.6418 11.4428 11.4428 12.0398 10.8458 12.5373Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "MedicalRecord",
};
</script>
