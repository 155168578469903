<template>
  <Modulebox title="Transferência de Agendamentos" class="transfer-exams">
    <div class="content">
      <FormBase class="area" title="Origem">
        <RgValidatorForm ref="validatorOrigin">
          <section class="origin-grid">
            <div class="form">
              <RgInputDate
                id="origin-date"
                ref="dateOrigin"
                v-model="form.origin.date"
                data-id="data"
                class="inputitem"
                :minDate="Date.now()"
                :rules="{ required: true }"
              />

              <ComboboxSector
                id="origin-sector"
                ref="comboboxSectorOrigin"
                v-model="form.origin.selectedSector"
                label="Setor"
                :uns_id="unitHealthId"
                :subModuleId="subModuleId"
                :rules="{ required: true }"
              />

              <div class="scale">
                <ComboboxScale
                  id="origin-scale"
                  ref="scaleOrigin"
                  v-model="form.origin.selectIdScale"
                  :strData="form.origin.date"
                  :intIdSetor="form.origin.sector.data || 0"
                  :class="{
                    disable: !form.origin.selectedSector || !form.origin.date,
                  }"
                  :disabled="!form.origin.selectedSector || !form.origin.date"
                  label="Escala"
                  :rules="{ required: true }"
                  @selectOnlyItem="onSelectOnlyItemScaleOrigin"
                  @change="onSelectScaleOrigin"
                  @loading="loadingOriginScale"
                />
              </div>
            </div>

            <div class="table">
              <div class="content">
                <span class="title">Pacientes agendados</span>
                <SmartTable
                  ref="smartTableOrigin"
                  name="TransferExams"
                  v-bind="propsSmartTableOrigin"
                  class="smart-table"
                  @getMultLines="onlistSelectedOriginPatients"
                />
              </div>
            </div>

            <div class="preview">
              <div class="title">
                <span>Pacientes para transferência</span>
                <span>Vagas Ocupadas</span>
              </div>
              <div class="preview-box" :style="style">
                <div
                  v-for="(item, index) in form.listSelectedOriginPatients"
                  :key="`pb-${index}`"
                  class="item"
                >
                  <span>{{ item.pes_nome }}</span>
                  <span> {{ item.eap_vagas_ocupadas }}</span>
                </div>
              </div>
              <div class="footer">
                <span>
                  Pacientes selecionados:
                  {{ form.listSelectedOriginPatients.length }}
                </span>
                <span>
                  Vagas selecionadas:
                  {{ calculateVacancies(form.listSelectedOriginPatients) }}
                </span>
              </div>
            </div>
          </section>
        </RgValidatorForm>
      </FormBase>

      <FormBase class="area" title="Destino">
        <RgValidatorForm ref="validatorDestiny">
          <section class="destiny-grid">
            <div class="form">
              <RgInputDate
                id="destiny-date"
                ref="dateDestiny"
                v-model="form.destiny.date"
                data-id="data"
                class="inputitem"
                :minDate="Date.now()"
                :rules="{ required: true }"
              />

              <ComboboxSector
                id="destiny-sector"
                ref="comboboxSectorDestiny"
                v-model="form.destiny.selectedSector"
                label="Setor"
                :uns_id="unitHealthId"
                :subModuleId="subModuleId"
                :rules="{ required: true }"
              />
              <ComboboxPlace
                id="destiny-place"
                ref="comboboxPlaceDestiny"
                v-model="form.destiny.selectedIdPlace"
                label="Local de Atendimento"
                :class="{
                  disable: !form.destiny.selectedSector,
                }"
                :disabled="!form.destiny.selectedSector"
                :set_id="form.destiny.selectedSector"
                :rules="{ required: true }"
              />

              <ComboboxScale
                id="destiny-scale"
                ref="scaleDestiny"
                v-model="form.destiny.selectIdScale"
                isScaleDestiny
                :strData="form.destiny.date"
                :intIdSetor="form.destiny.sector.data || 0"
                :class="{
                  disable: !form.destiny.selectedSector || !form.destiny.date,
                }"
                :disabled="!form.destiny.selectedSector || !form.destiny.date"
                label="Escala"
                :rules="{ required: true }"
                @selectOnlyItem="onSelectOnlyItemScaleDestiny"
                @change="onSelectScaleDestiny"
              />
              <div
                :class="{
                  available: vacanciesAvaliable,
                  notAvailable: vacanciesNotAvaliable,
                }"
                class="vacancies"
              >
                {{ vacanciesText }}
              </div>
            </div>
          </section>
        </RgValidatorForm>
      </FormBase>
    </div>

    <div class="exams-footer">
      <RgCleanButton
        id="clear-btn"
        small
        type="button"
        title="Limpar"
        class="clean"
        @click="actionCleanForm"
      />

      <div class="margin-left">
        <RgSaveButton
          id="save-btn"
          ref="saveButton"
          large
          class="save"
          type="button"
          label="Transferir"
          @click="actionSaveForm"
        />
      </div>
    </div>

    <ModalPrintTransfer
      id="modal-print-transfer"
      :v-if="showModalPrintTransfer"
      :show="showModalPrintTransfer"
      @confirm="actionPrint"
      @close="closeModalPrint"
    />

    <ModalTransfHistory
      id="modal-transf-history"
      :v-if="showModalTransfHistory"
      :show="showModalTransfHistory"
      @confirm="actionPrint"
      @close="closeModalHistory"
    />
  </Modulebox>
</template>

<script>
import {
  RgInputDate,
  RgCleanButton,
  RgSaveButton,
  RgValidatorForm,
} from "~tokio/primitive";

import SmartTable from "~tokio/foundation/smart-table/SmartTable.vue";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";

import ComboboxPlace from "$exam/submodules/schedule/component/combobox/transfer/place/RgComboboxTransferPlace";
import ComboboxSector from "$exam/submodules/schedule/component/combobox/transfer/sector/RgComboboxTransferSector";
import ComboboxScale from "$exam/submodules/schedule/component/combobox/transfer/scale/RgComboboxTransferScale";

import ModalPrintTransfer from "$exam/submodules/schedule/component/modal/modal-print-transfer/ModalPrintTransfer";
import ModalTransfHistory from "$exam/submodules/schedule/component/modal/modal-transfer-history/ModalTransfHistory";
import PrintTransferHTML from "$exam/submodules/schedule/html/transfer/Transfer";
import { mapGetters } from "vuex";

const formBase = Object.freeze({
  origin: {
    date: "",
    selectedSector: 0,
    selectIdScale: null,
    scale: null,
    sector: {
      data: "",
    },
  },
  destiny: {
    date: "",
    selectedSector: 0,
    selectedIdPlace: null,
    selectIdScale: null,
    scale: null,
    sector: {
      data: "",
    },
  },
  listSelectedOriginPatients: [],
  listOriginPatients: [],
  listDestinyPatients: [],
  availableVacancies: 0,
  loginUnitHealthId: null,
  loginUnitHealthName: null,
});

const COLUMN_TABLE = [
  { name: "Horário", key: "eap_hora" },
  { name: "Paciente", key: "pes_nome" },
  { name: "Procedimento", key: "stp_novo_nome_procedimento" },
  { name: "Vagas Ocupadas", key: "eap_vagas_ocupadas" },
];

export default {
  name: "TransferExams",
  components: {
    RgCleanButton,
    RgSaveButton,
    RgInputDate,
    SmartTable,
    ComboboxSector,
    ComboboxPlace,
    ModalPrintTransfer,
    ModalTransfHistory,
    Modulebox,
    FormBase,
    ComboboxScale,
    RgValidatorForm,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(formBase),
      showModalPrintTransfer: false,
      showModalTransfHistory: false,
      sessionIdPrint: null,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    propsSmartTableOrigin() {
      const body = this.form.listOriginPatients;
      const total = body ? this.form.listOriginPatients.length : 0;
      const columns = COLUMN_TABLE;
      const indexColumn = "id";
      const showPagination = false;
      const multSelect = true;
      const removeBtnColumns = true;
      const hasCheck = true;

      return {
        body,
        total,
        columns,
        indexColumn,
        showPagination,
        multSelect,
        removeBtnColumns,
        hasCheck,
      };
    },

    disableDestiny() {
      return this.form.listSelectedOriginPatients.length <= 0;
    },

    disableBtnTransfer() {
      let disable = false;
      if (this.disableDestiny) {
        disable = true;
      } else {
        if (!this.form.destiny.scale || !this.form.destiny.selectedIdPlace) {
          disable = true;
        }
      }

      return disable;
    },

    vacanciesText() {
      if (
        this.form.availableVacancies <
        this.form.listSelectedOriginPatients.length
      ) {
        return `Vagas insuficientes`;
      }
      return `Vagas disponíveis: ${this.form.availableVacancies}`;
    },

    vacanciesAvaliable() {
      return (
        this.form.availableVacancies > 0 &&
        this.form.availableVacancies >=
          this.form.listSelectedOriginPatients.length
      );
    },

    vacanciesNotAvaliable() {
      return (
        this.form.availableVacancies === 0 ||
        this.form.availableVacancies <
          this.form.listSelectedOriginPatients.length
      );
    },

    subModuleId() {
      return 101;
    },

    style() {
      if (this.form.listOriginPatients.length > 0) {
        return { "margin-top": "34px" };
      } else {
        return { "margin-top": "16px" };
      }
    },
  },

  watch: {
    "form.origin.date"(pValue) {
      this.form.origin.selectIdScale = null;
      this.form.origin.scale = null;
    },
    "form.destiny.date"(pValue) {
      this.form.availableVacancies = 0;
    },

    async "form.origin.scale"(value) {
      await this.chargeListOriginPatients();
    },

    "form.origin": {
      deep: true,
      handler(value) {
        if (this.isChargeList(value)) {
        } else if (this.isCleanList(value)) {
          this.form.listOriginPatients = [];
        }
      },
    },

    "form.destiny": {
      deep: true,
      handler(value) {
        if (this.isCleanList(value)) {
          this.form.listDestinyPatients = [];
        }
      },
    },

    "form.origin.selectedSector"(value) {
      this.form.origin.selectIdScale = null;
      this.form.origin.scale = null;

      if (Array.isArray(value)) {
        this.form.origin.sector = value[0].item;
      }
      if (value === false) {
        this.form.origin.sector = null;
      } else if (!value) {
        this.form.origin.selectIdScale = { ...null };
        this.form.origin.selectedSector = { ...null };
        this.form.origin.sector = { ...null };
      }
    },

    "form.destiny.selectedSector"(value) {
      this.form.destiny.selectIdScale = null;
      this.form.destiny.scale = null;
      this.form.destiny.selectedIdPlace = null;
      this.form.availableVacancies = 0;
      if (Array.isArray(value)) {
        this.form.destiny.sector = value[0].item;
      }
      if (value === false) {
        this.form.destiny.sector = null;
      } else if (!value) {
        this.form.destiny.selectIdScale = { ...null };
        this.form.destiny.selectedSector = { ...null };
        this.form.destiny.selectedIdPlace = { ...null };
        this.form.destiny.scale = { ...null };
        this.form.destiny.sector = { ...null };
      }
    },
  },

  mounted() {
    this.actionCleanForm();
  },

  methods: {
    calculateVacancies(pList) {
      let sum = 0;
      pList.forEach((item) => {
        sum += Number(item.eap_vagas_ocupadas);
      });
      return sum;
    },
    loadingOriginScale(isLoading) {
      if (!isLoading) {
        this.$refs.smartTableOrigin.cleanMultSelectedRow();
        this.form.listSelectedOriginPatients = [];
        this.form.listOriginPatients = [];
      }
    },

    isChargeList(value) {
      return value.date && value.scale && value.selectedSector;
    },
    isCleanList(value) {
      return value.date || value.scale || value.selectedSector;
    },

    async chargeListOriginPatients() {
      try {
        if (
          !this.form.origin.date ||
          !this.form.origin.selectIdScale ||
          !this.form.origin.selectedSector
        )
          return false;
        this.$refs.smartTableOrigin.cleanMultSelectedRow();
        this.form.listSelectedOriginPatients = [];
        this.$loader.start();
        const list = await this.$store.dispatch("Exam/Schedule/GET_ORIGIN", {
          arrFormdata: {
            eea_id: this.form.origin.scale.data
              ? this.form.origin.scale.data
              : this.form.origin.scale.item.data,
            eha_data_exame: this.form.origin.date,
            limiteFim: 1000000,
            limiteInicio: 0,
          },
        });
        this.form.listOriginPatients = list?.recordSet || [];
      } catch (err) {
        this.$toaster.error("Erro ao buscar pacientes agendados");
      } finally {
        this.$loader.finish();
      }
    },

    onlistSelectedOriginPatients(list) {
      this.form.listSelectedOriginPatients = list;
    },

    onSelectOnlyItemScaleOrigin({ id, item }) {
      this.form.origin.selectIdScale = id;
      this.form.origin.scale = item;
    },
    onSelectScaleOrigin(val) {
      if (val && typeof val === "object") {
        this.form.origin.scale = val[0].item;
      }
    },

    onSelectOnlyItemScaleDestiny({ id, item }) {
      this.form.destiny.selectIdScale = id;
      this.form.destiny.scale = item;

      const qtde = item.eea_quantidade_restantes;
      if (qtde) {
        this.form.availableVacancies = qtde;
      } else {
        this.form.availableVacancies = item.item.eea_quantidade_restantes;
      }
    },
    onSelectScaleDestiny(val) {
      if (val && typeof val === "object") {
        this.form.destiny.scale = val[0].item;
        this.form.availableVacancies = val[0].item.eea_quantidade_restantes;
      }
    },

    actionCleanForm() {
      this.form = this.$utils.obj.DeepCopy(formBase);
      this.form.loginUnitHealthId = this.$store.getters[
        "Login/GET_UNIT_HEALTH_ID"
      ];
      this.form.loginUnitHealthName = this.$store.getters[
        "Login/GET_UNIT_HEALTH_NAME"
      ];

      this.cleanFieldsValidation();

      this.$refs.smartTableOrigin.cleanMultSelectedRow();
      if (this.$refs.saveButton) this.$refs.saveButton.actionDone();
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "dateOrigin",
        "comboboxSectorOrigin",
        "scaleOrigin",
        "dateDestiny",
        "comboboxSectorDestiny",
        "comboboxPlaceDestiny",
        "scaleDestiny",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    async isFormValidOrigin() {
      return this.$refs.validatorOrigin
        ? this.$refs.validatorOrigin.validate()
        : false;
    },
    async isFormValidDestiny() {
      return this.$refs.validatorDestiny
        ? this.$refs.validatorDestiny.validate()
        : false;
    },
    async actionSaveForm() {
      try {
        const isFormOriginValid = await this.isFormValidOrigin();
        const isFormDestinyValid = await this.isFormValidDestiny();
        const isInvalidForm = !isFormOriginValid || !isFormDestinyValid;

        if (isInvalidForm) {
          this.$toaster.warning("Verifique os campos");

          if (this.$refs.saveButton) {
            this.$refs.saveButton.actionDone();
          }
          return;
        }

        const withoutPatientSelected =
          this.form.listSelectedOriginPatients.length <= 0;

        if (withoutPatientSelected) {
          this.$toaster.warning(
            "É necessário selecionar agendamentos para realizar a transferência",
          );

          if (this.$refs.saveButton) {
            this.$refs.saveButton.actionDone();
          }

          return;
        }

        if (this.disableBtnTransfer) {
          this.$toaster.error("Por favor, preencha todos os campos");
          if (this.$refs.saveButton) this.$refs.saveButton.actionDone();
          return;
        }

        if (this.vacanciesNotAvaliable) {
          this.$toaster.error("Não há vagas disponíveis");
          if (this.$refs.saveButton) this.$refs.saveButton.actionDone();
          return;
        }

        const agendamentos = [];
        const procedimentos = [];

        this.form.listSelectedOriginPatients.map((el) => {
          agendamentos.push(el.eap_id);
          procedimentos.push(el.stp_id);
        });

        let destinyScale = this.form.destiny.scale;

        if (destinyScale.item) {
          destinyScale = destinyScale.item;
        }

        const historico = {
          eha_hora_final_atendimentos: destinyScale.eea_final_atendimentos,
          eha_hora_inicio_atendimentos: destinyScale.eea_inicio_atendimentos,
          eha_id: destinyScale.eha_id ? destinyScale.eha_id : 0,
          eha_id_dias_semana: destinyScale.eea_id_dias_semana,
          eha_id_exames_escala: destinyScale.eea_id_exames_escala,
          eha_id_exames_escalas_agendamentos: destinyScale.data,
          eha_id_exames_tipo_escalas: destinyScale.eea_id_exames_tipo_escalas,
          eha_intervalo_exames:
            destinyScale.eha_id !== "0"
              ? destinyScale.eha_intervalo_exames
              : destinyScale.eea_intervalo_exames,
          eha_quantidade_exames: destinyScale.eea_quantidade_exames,

          eha_data_exame: this.form.destiny.date,
        };

        if (this.isEqualOriginDestiny()) {
          this.$toaster.error(
            "A transferência não pode ser feita para a mesma escala no mesmo dia",
          );

          if (this.$refs.saveButton) this.$refs.saveButton.actionDone();
          return;
        }

        const ocupacao = destinyScale.ocp_id;
        const profissional = destinyScale.pes_id;

        const obj = {
          arrFormData: {
            agendamentos,
            historico,
            ocupacao,
            procedimentos,
            profissional,
          },

          intIdHistoricosAgendamentoDestino: destinyScale.eha_id
            ? destinyScale.eha_id
            : "0",

          intIdLocalAtendimento: this.form.destiny.selectedIdPlace,
          intIdSetorDestino: this.form.destiny.sector.data,
        };

        this.sessionIdPrint = await this.$store.dispatch(
          "Exam/Schedule/TRANSFER",
          obj,
        );

        if (this.$refs.saveButton) this.$refs.saveButton.actionDone();

        if (this.sessionIdPrint.status === false) {
          this.$toaster.error(this.sessionIdPrint.msg);
        } else {
          await this.chargeListOriginPatients();
          this.form.listSelectedOriginPatients = [];
          this.$refs.smartTableOrigin.cleanMultSelectedRow();
          this.showModalPrintTransfer = true;
          this.$toaster.success("Operação realizada com sucesso");
        }
      } catch (e) {
        this.$toaster.error(e.message);
        this.$refs.saveButton.fail();
      }
    },

    isEqualOriginDestiny() {
      return (
        this.form.destiny.date === this.form.origin.date &&
        this.form.destiny.selectedIdSector ===
          this.form.origin.selectedIdSector &&
        this.form.destiny.scale.eha_id === this.form.origin.scale.eha_id
      );
    },

    async actionPrint() {
      try {
        const arr = {
          pes_nome_destino: this.form.destiny.scale.pes_nome,
          pes_nome_origem: this.form.origin.scale.pes_nome,
          set_nome_destino: this.form.destiny.sector.set_nome,
          set_nome_origem: this.form.origin.sector.set_nome,
          uns_nome: this.form.loginUnitHealthName,
        };

        const obj = {
          strSessao: this.sessionIdPrint,
          arrFiltros: arr,
        };

        const snapPrint = await this.$store.dispatch(
          "Exam/Schedule/TRANSFER_PRINT",
          obj,
        );

        const html = PrintTransferHTML(snapPrint);
        this.$utils.print.printHtml(html);
      } catch (e) {
        console.eror("erro", e);
      } finally {
        this.closeModalPrint();
      }
    },

    closeModalPrint() {
      this.actionCleanForm();
      this.showModalPrintTransfer = false;
    },

    openModalHistory() {
      this.showModalTransfHistory = true;
    },

    closeModalHistory() {
      this.showModalTransfHistory = false;
    },
  },
};
</script>
