<template>
  <div class="pharmacy-central">
    <RgSearch
      ref="rgSearch"
      v-model="mutablePharmacyCentral"
      :result-title="'Produtos'"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :searchAreaHeight="tableHeight"
      :item-height="34.5"
      :maxRegister="40"
      :disabled="disabledByModal"
      @count="getCountValue"
      @afterPerformSearch="afterSearchFilter"
    >
      <div slot="filters" class="pharmacy-central-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealthPharmacy
              id="unit-health"
              ref="unitHealth"
              v-model="form.unitHealth"
              :typePharmacy="1"
              class="input"
              label="Unidade de Saúde"
              :rules="{ required: true }"
              @change="selectedUnitHealth"
            />
          </div>
          <div class="selectinput">
            <RgSelectPharmacy
              v-model="form.pharmacy"
              :unitHealthId="Number(form.unitHealth)"
              :typePharmacy="1"
              default-text="Selecione"
              class="input"
              :disabled="!form.unitHealth"
              :class="{ disable: !form.unitHealth }"
              :rules="{ required: true }"
              @change="selectedPharmacy"
            />
          </div>
          <div class="selectinput">
            <RgSelectProduct
              v-model="form.product"
              :pharmacyId="Number(form.pharmacy)"
              default-text="Selecione"
              class="input"
              perClient
              blnMpdBloqueado
              :disabled="!form.pharmacy || isTransfer"
              :class="{ disable: !form.pharmacy || isTransfer }"
            />
          </div>
          <div class="selectinput">
            <RgInput
              v-model="form.guide"
              :label="labelGuide"
              :placeholder="placeholderGuide"
              :disabled="!form.pharmacy || !hasGuide"
              :rules="{ fn: validateValues }"
              :class="{ disable: !form.pharmacy || !hasGuide }"
            />
          </div>
          <div class="selectinput">
            <RgComboboxPharmacyOrderStatus
              v-model="form.status"
              label="Status"
              :disabled="!form.pharmacy || !hasStatus"
              :isReport="false"
              :class="{ disable: !form.pharmacy || !hasStatus }"
            />
          </div>
          <div class="filter-title">
            <span class="title">Período</span>
          </div>
          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-date"
                ref="initialDate"
                v-model="form.initialDate"
                class="input"
                label="Data Inicial"
                :rules="{ required: true, fn: validateInitialDate }"
                popUp="bottom"
              />
              <RgInputDate
                id="final-date"
                ref="finalDate"
                v-model="form.finalDate"
                :rules="{ required: true, fn: validateFinalDate }"
                class="input"
                label="Data Final"
                popUp="bottom"
              />
            </div>
          </div>
        </div>
        <Tooltip class="tooltip" clickable startOpen>
          <IconInfoHelper slot="icon" hollow />
          <div slot="content" class="content">
            <div class="title">
              <span
                >Após o preenchimento dos campos acima, é necessário selecionar
                um dos filtros disponíveis:</span
              >
              <ul class="list">
                <li class="item">
                  Estão localizados na tela ao lado abaixo do título "Produtos";
                </li>
                <li class="item">
                  Os filtros exibem os resultados correspondentes para cada tipo
                  de Entrada ou Saída.
                </li>
              </ul>
            </div>
          </div>
        </Tooltip>
      </div>
      <div ref="radios" slot="search-top" class="pharmacy-product-top-buttons">
        <div slot="legends" class="radios">
          <RgRadioCustom
            id="status"
            v-bind="propsRadio"
            :disabledByModal="disabledByModal"
            @input="onInputRadioCustom"
          />
        </div>
      </div>

      <div class="pharmacy-central-search-list">
        <SmartTable
          ref="smartTable"
          :name="nameSmartTable"
          :columns="collumnsPharmacyCentral"
          :body="mutablePharmacyCentral"
          :dynamic-height="getSpaceTable"
          :total="Number(total)"
          :colorLine="actionColorLine"
          circle-indicator
          :initial-columns="7"
          :index-column="indexPharmacyCentral"
          :second-index-column="secondIndexPharmacyCentral"
          toggle-selected
          :showPagination="false"
          class="smart-table"
          @getLine="selectLine"
        >
          <div slot="top-buttons" class="actions">
            <div class="right-buttons">
              <!-- TODO: ver as permissoes dos botões -->

              <RgEditButton
                id="edicao-movimentacao"
                small
                title="Editar Movimentação do Produto"
                :disabled="
                  !hasEditOption || disabledByModal || isClosedTransferRequest
                "
                @click="editProduct"
              />
              <RgLessButton
                id="excluir-movimentacao"
                small
                title="Excluir Movimentação do Produto"
                :disabled="
                  !hasDeleteOption || disabledByModal || isClosedTransferRequest
                "
                @click="openModalDeleteMovement"
              />
              <RgHistoryButton
                id="historico-movimentacao"
                small
                title="Histórico de Movimentação do Produto"
                :disabled="!hasHistoryOption || disabledByModal"
                @click="historyProduct"
              />
              <RgShowButton
                v-show="!hasShowDropdownOption"
                id="visualizar-movimentacao"
                small
                title="Visualizar Movimentação do Produto"
                :disabled="!hasShowOption || disabledByModal"
                @click="viewMovement"
              />
              <RgPrinterButton
                id="impressao"
                small
                title="Imprimir Movimentação do Produto"
                :disabled="
                  !hasPrinterOption || disabledByModal || isOpenTransfer
                "
                @click="printer"
              />
              <RgDropdown
                v-show="hasShowDropdownOption"
                id="paper-dropdown"
                ref="btnPaper"
                small
                show-down
                show-left
                :action-options="itemActionMovement()"
                :disabled="!this.hasSelectedLine || disabledByModal"
                background-color="#1e88a9"
              >
                <IconShow slot="icon" />
              </RgDropdown>
            </div>
          </div>
        </SmartTable>
      </div>
      <div
        ref="footer"
        slot="menu-bottom"
        class="pharmacy-central-bottom-buttons"
      >
        <SmallButton
          id="registrar-entrada"
          title="Registrar Entrada"
          :disabled="!hasEntryOption || disabledByModal"
          :backgroundColor="'#0BB98C'"
          @click="registerEntry"
        >
          <IconEntryPharmacy slot="icon" />
        </SmallButton>

        <SmallButton
          id="registrar-saida"
          title="Registrar Saída"
          :backgroundColor="'#f96b70'"
          :disabled="!hasExitOption || disabledByModal || isClosedRequest"
          @click="registerExit"
        >
          <IconExitPharmacy slot="icon" />
        </SmallButton>

        <SmallButton
          id="descarte-produto"
          title="Descartar Produto"
          :permission="hasPermission"
          :disabled="!hasDiscardOption || disabledByModal"
          :backgroundColor="'#26789F'"
          @click="discardProduct"
        >
          <IconDiscardPharmacy slot="icon" />
        </SmallButton>

        <SmallButton
          id="solicitar-transferencia"
          title="Solicitar Transferência"
          :backgroundColor="'#1e88a9'"
          :disabled="!hasRequestTransferOption || disabledByModal"
          @click="requestTransfer"
        >
          <IconRequestTransfer slot="icon" />
        </SmallButton>

        <SmallButton
          id="atender-transferencia"
          title="Atender Transferência"
          :disabled="
            !hasAttendTransferOption || disabledByModal || isClosedTransfer
          "
          :backgroundColor="'#1e88a9'"
          @click="attendTransfer"
        >
          <IconAttendTransfer slot="icon" />
        </SmallButton>
      </div>
    </RgSearch>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteMovement"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
    <ModalDiscardProduct
      id="modal-discard-product"
      :show="showModalDiscardProduct"
      :selected-row="selectedRow"
      :id-unith-health="form.unitHealth"
      :id-pharmacy="form.pharmacy"
      @close="closeModalDiscardProduct"
      @reSearch="reSearch"
    />
    <ModalSeeDiscard
      id="modal-see-discard"
      :show="showModalSeeDiscard"
      :selected-row="selectedRow"
      :id-unith-health="form.unitHealth"
      :id-pharmacy="form.pharmacy"
      :unit-health-name="form.uns_nome"
      :pharmacy-name="form.far_nome"
      @close="closeModalSeeDiscard"
    />
    <ModalSeeTransfer
      id="modal-see-transfer"
      :show="showModalSeeTransfer"
      :selected-row="selectedRow"
      @close="closeModalSeeTransfer"
    />
    <ModalSeeMovementTransferRequest
      id="modal-see-reuqest-transfer"
      :show="showModalSeeMovementTransferRequest"
      :selected-row="selectedRow"
      :id-unith-health="form.unitHealth"
      :id-pharmacy="form.pharmacy"
      :unit-health-name="form.uns_nome"
      :pharmacy-name="form.far_nome"
      :type-movement="typeMovement"
      @close="closeModalSeeMovementTransferRequest"
    />
    <ModalSeeRequestDetails
      id="modal-see-request-details"
      :show="showModalRequestDetails"
      :requestId="requestId"
      @close="closeModalRequestDetails"
    />
    <ModalPrintMovement
      id="modal-print-movement"
      :show="modalPrintMovement"
      :selected-row="selectedRow"
      :id-unith-health="form.unitHealth"
      :id-pharmacy="form.pharmacy"
      :filters="filters"
      :type-movement="typeMovement"
      @close="closeModalPrintMovement"
    />
    <ModalObservationRequest
      :show="showModalObservationRequest"
      :requestData="observationData"
      seeResults
      @close="closeModalObservationRequest"
    />
    <ModalObservationTransferRequest
      title="Visualizar observações sobre o Atendimento de Solicitação"
      seeResults
      :show="showModalObservationTransferRequest"
      :request-data="observationData"
      @close="closeModalObservationTransferRequest"
    />
  </div>
</template>

<script>
import moment from "moment";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import {
  RgEditButton,
  RgLessButton,
  IconEntryPharmacy,
  IconExitPharmacy,
  RgHistoryButton,
  RgShowButton,
  RgPrinterButton,
  IconDiscardPharmacy,
  RgRadioCustom,
  RgInputDate,
  RgInput,
  ModalConfirmDeletion,
  Tooltip,
  IconInfoHelper,
  RgDropdown,
  IconShow,
  SmallButton,
  IconRequestTransfer,
  IconAttendTransfer,
} from "~tokio/primitive";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import ModalDiscardProduct from "$pharmacy/submodules/central/components/modal/modal-discard-product/ModalDiscardProduct";
import ModalSeeDiscard from "$pharmacy/submodules/central/components/modal/modal-see-discard/ModalSeeDiscard.vue";
import DeliveryNoteHTML from "$pharmacy/submodules/central/html/DeliveryNoteHTML";
import TransferRequestHTML from "$pharmacy/submodules/central/html/TransferRequestHTML";
import RemittanceGuideRequest from "$pharmacy/submodules/central/html/RemittanceGuideRequest";
import ModalPrintMovement from "$pharmacy/common/modal/modal-print-movement/ModalPrintMovement";
import ModalSeeMovementTransferRequest from "$pharmacy/submodules/central/components/modal/modal-see-movement-transfer-request/ModalSeeMovementTransferRequest";
import ModalSeeTransfer from "$pharmacy/submodules/central/components/modal/modal-see-transfer/ModalSeeTransfer.vue";
import { mapGetters } from "vuex";
import ModalSeeRequestDetails from "$pharmacy/common/modal/modal-see-request-details/ModalSeeRequestDetails";
import RgComboboxPharmacyOrderStatus from "$pharmacy/common/combobox/rg-combobox-pharmacy-order-status/RgComboboxPharmacyOrderStatus";
import ModalObservationRequest from "$pharmacy/submodules/satellite/components/modal/modal-observation-request/ModalObservationRequest";
import ModalObservationTransferRequest from "$pharmacy/submodules/central/components/modal/modal-observation-transfer-request/ModalObservationTransferRequest";

const FORM_CENTRAL = {
  unitHealth: null,
  pharmacy: null,
  product: null,
  statusProduct: null,
  initialDate: moment().format("01/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  uns_nome: null,
  far_nome: null,
  guide: null,
  status: null,
};

export default {
  name: "PharmacyCentralSearch",
  components: {
    RgEditButton,
    RgLessButton,
    RgSearch,
    SmartTable,
    IconEntryPharmacy,
    IconExitPharmacy,
    RgHistoryButton,
    RgShowButton,
    RgPrinterButton,
    IconDiscardPharmacy,
    RgRadioCustom,
    RgSelectUnithealthPharmacy,
    ModalConfirmDeletion,
    RgSelectPharmacy,
    RgInputDate,
    RgInput,
    RgSelectProduct,
    ModalDiscardProduct,
    ModalSeeDiscard,
    ModalSeeTransfer,
    ModalPrintMovement,
    Tooltip,
    IconInfoHelper,
    ModalSeeMovementTransferRequest,
    ModalSeeRequestDetails,
    RgComboboxPharmacyOrderStatus,
    RgDropdown,
    IconShow,
    ModalObservationRequest,
    ModalObservationTransferRequest,
    SmallButton,
    IconRequestTransfer,
    IconAttendTransfer,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_CENTRAL),
      titleActiveDesactive: "",
      messageActiveDesactive: "",
      msgSuccessActiveDesactive: "",
      labelBtnActiveDesactive: "",
      mutablePharmacyCentral: [],
      modalDeleteMovement: false,
      editPharmacyCentralRow: null,
      isEdit: false,
      isActive: false,
      showModalActiveDesactive: false,
      total: 0,
      tableHeight: null,
      selectedRow: {},
      bodyText: null,
      deleteRecordPreference: null,
      textReason: "",
      showModalDiscardProduct: false,
      showModalSeeMovement: false,
      showModalSeeMovementTransferRequest: false,
      modalPrintMovement: false,
      remittance: null,
      filters: null,
      typeMovement: null,
      showModalSeeDiscard: false,
      showModalSeeTransfer: false,
      showModalRequestDetails: false,
      requestId: null,
      observationData: [],
      showModalObservationRequest: false,
      showModalObservationTransferRequest: false,
    };
  },

  computed: {
    ...mapGetters({
      clientName: "Login/GET_CLIENT_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
      userName: "Login/GET_USER_NAME",
    }),
    hasGuide() {
      const options = [
        this.PHARMACY_CENTRAL_OUTPUT,
        this.PHARMACY_CENTRAL_REVERSAL,
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
      ];

      return options.includes(this.form.statusProduct);
    },
    labelGuide() {
      const options = [
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
      ];

      return options.includes(this.form.statusProduct)
        ? "Número do Pedido"
        : "Guia de Remessa";
    },
    placeholderGuide() {
      const options = [
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
      ];

      return options.includes(this.form.statusProduct)
        ? "Digite o número do pedido"
        : "Digite a guia de remessa";
    },

    isClosedRequest() {
      const options = [this.PHARMACY_CENTRAL_REQUEST];
      return (
        options.includes(this.form.statusProduct) &&
        this.selectedRow?.situacao === "FECHADO"
      );
    },

    isClosedTransferRequest() {
      const options = [this.PHARMACY_CENTRAL_REQUEST_TRANSFER];
      return (
        options.includes(this.form.statusProduct) &&
        this.selectedRow?.situacao === "TRANSF. FECHADA"
      );
    },
    hasStatus() {
      const options = [
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
      ];
      return options.includes(this.form.statusProduct);
    },
    hasPermission() {
      return !!this.$Permissions.global.has(
        "farmacia.movimentacaoCentral.descarte",
      );
    },
    hasSelectedLine() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },
    hasEditOption() {
      const options = [
        this.PHARMACY_CENTRAL_ENTRY,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
      ];
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    hasDeleteOption() {
      const options = [
        this.PHARMACY_CENTRAL_ENTRY,
        this.PHARMACY_CENTRAL_OUTPUT,
        this.PHARMACY_CENTRAL_DISPOSAL,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
      ];
      if (
        this.form.statusProduct === this.PHARMACY_CENTRAL_ENTRY ||
        this.form.statusProduct === this.PHARMACY_CENTRAL_OUTPUT
      ) {
        if (this.deleteRecordPreference === "1") {
          return this.hasSelectedLine;
        } else {
          return false;
        }
      }
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    hasHistoryOption() {
      // const options = [this.PHARMACY_CENTRAL_REQUEST_TRANSFER];
      // return options.includes(this.form.statusProduct) && this.hasSelectedLine;
      return false;
    },

    hasShowDropdownOption() {
      const options = [
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
      ];
      return options.includes(this.form.statusProduct);
    },

    hasShowOption() {
      const options = [
        this.PHARMACY_CENTRAL_DISPOSAL,
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_TRANSFER,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
      ];
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    hasPrinterOption() {
      const options = [
        this.PHARMACY_CENTRAL_OUTPUT,
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_TRANSFER,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
      ];
      // if (this.form.statusProduct === this.PHARMACY_CENTRAL_ATTENDED_TRANSFER) {
      //   return (
      //     options.includes(this.form.statusProduct) &&
      //     this.hasSelectedLine &&
      //     this.selectedRow?.str_id_status === "1"
      //   );
      // } else {
      // }
      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    hasEntryOption() {
      return this.form.statusProduct === this.PHARMACY_CENTRAL_ENTRY;
    },
    hasExitOption() {
      return (
        this.form.statusProduct === this.PHARMACY_CENTRAL_OUTPUT ||
        (this.hasSelectedLine &&
          this.form.statusProduct === this.PHARMACY_CENTRAL_REQUEST) ||
        (this.hasSelectedLine &&
          this.selectedRow?.estocado > 0 &&
          this.form.statusProduct === this.PHARMACY_CENTRAL_ENTRY)
      );
    },
    hasDiscardOption() {
      return (
        this.form.statusProduct === this.PHARMACY_CENTRAL_DISPOSAL ||
        (this.form.statusProduct === this.PHARMACY_CENTRAL_ENTRY &&
          this.hasSelectedLine &&
          Number(this.selectedRow?.total_lote) !== 0)
      );
    },

    hasRequestTransferOption() {
      const options = [this.PHARMACY_CENTRAL_REQUEST_TRANSFER];
      return options.includes(this.form.statusProduct); // só com 1 => selectLine
    },
    hasAttendTransferOption() {
      const options = [this.PHARMACY_CENTRAL_ATTENDED_TRANSFER];

      return options.includes(this.form.statusProduct) && this.hasSelectedLine;
    },
    isClosedTransfer() {
      return this.selectedRow.str_id_status === "1";
    },
    isOpenTransfer() {
      return (
        this.selectedRow.str_id_status === "0" &&
        this.form.statusProduct === this.PHARMACY_CENTRAL_ATTENDED_TRANSFER
      );
    },

    isTransfer() {
      const options = [
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
        this.PHARMACY_CENTRAL_REQUEST,
      ];
      return options.includes(this.form.statusProduct);
    },
    actionColorLine() {
      return {
        get: (item) => {
          if (item.situacao === "ENTRADA") {
            return GetColorRowSmartTable("green");
          }
          if (item.situacao === "SAIDA") {
            return GetColorRowSmartTable("red");
          } else {
            return GetColorRowSmartTable("blue");
          }
        },
      };
    },
    getSpaceTable() {
      return this.tableHeight;
    },
    hasPharmacyCentral() {
      return !(
        this.mutablePharmacyCentral && this.mutablePharmacyCentral.length === 0
      );
    },
    propsModalConfirmDeletion() {
      const statusProduct = this.form.statusProduct;

      let confirm = null;
      let movement = null;
      let movementMessage = null;
      let msgSuccess = null;
      const show = this.modalDeleteMovement;
      const noReason = true;
      let bodyText = "";

      switch (statusProduct) {
        case this.PHARMACY_CENTRAL_ENTRY:
          confirm = () => this.deleteEntry();
          movement = "Entrada";
          movementMessage = "das entradas realizadas";
          msgSuccess = "Entrada excluída com sucesso";
          bodyText = `${this.selectedRow?.mpd_novo_principio_ativo} - ${this.selectedRow?.lote} - ${this.selectedRow?.trs_quantidade} `;
          break;
        case this.PHARMACY_CENTRAL_OUTPUT:
          confirm = () => this.deleteOutput();
          movement = "Saída";
          movementMessage = "das saídas realizadas";
          msgSuccess = "Saída excluída com sucesso";
          bodyText = `${this.selectedRow?.mpd_novo_principio_ativo} - ${this.selectedRow?.lote} - ${this.selectedRow?.trs_quantidade}  `;
          break;
        case this.PHARMACY_CENTRAL_DISPOSAL:
          confirm = () => this.deleteDisposal();
          movement = "Descarte";
          movementMessage = "dos descartes realizados";
          msgSuccess = "Descarte excluído com sucesso";
          bodyText = `${this.selectedRow?.mpd_novo_principio_ativo} - ${this.selectedRow?.lote} - ${this.selectedRow?.dsc_quantidade}`;
          break;
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          confirm = () => this.deleteRequestTranfer();
          movement = "Solicitação de Transferência";
          movementMessage = "das solicitações de transferência realizadas";
          msgSuccess = "Solicitação de transferência excluída com sucesso";
          bodyText = `Nº da solicitação: ${this.selectedRow?.str_numero} - ${this.selectedRow?.situacao}`;
          break;
      }

      const title = `Excluir Registro de ${movement}`;
      const message = `O registro selecionado será removido ${movementMessage} na Farmácia`;
      const hasSearch = true;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
      };
    },
    propsRadio() {
      const list = [
        { title: "Entrada", color: "#03CE63", id: 1 },
        { title: "Saída", color: "#03CE63", id: 2 },
        { title: "Estorno", id: 3 },
        { title: "Descarte", id: 4 },
        { title: "Solicitação", id: 5 },
        { title: "Transferência", id: 6 },
        { title: "Solicitações Transf.", id: 7 },
        { title: "Atendimento Transf.", id: 8 },
      ];
      const value = this.form.statusProduct;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    secondIndexPharmacyCentral() {
      const statusProduct = this.form.statusProduct;
      let index = null;
      switch (statusProduct) {
        case this.PHARMACY_CENTRAL_REQUEST:
          index = "mpd_id";
          break;
        // outras buscas que precise de segundo index
      }
      return index;
    },

    indexPharmacyCentral() {
      const statusProduct = this.form.statusProduct;
      let index = null;
      switch (statusProduct) {
        case this.PHARMACY_CENTRAL_ENTRY:
        case this.PHARMACY_CENTRAL_OUTPUT:
        case this.PHARMACY_CENTRAL_REVERSAL:
        case this.PHARMACY_CENTRAL_TRANSFER:
          index = "trs_id";
          break;
        case this.PHARMACY_CENTRAL_DISPOSAL:
          index = "dsc_id";
          break;
        case this.PHARMACY_CENTRAL_REQUEST:
          index = "ped_id";
          break;
        case this.PHARMACY_CENTRAL_ATTENDED_TRANSFER:
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          index = "str_id";
          break;
      }

      return index;
    },
    collumnsPharmacyCentral() {
      const statusProduct = this.form.statusProduct;
      let COLLUMNS_PHARMACY_CENTRAL = [];
      switch (statusProduct) {
        case this.PHARMACY_CENTRAL_ENTRY:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_ENTRY;
          break;
        case this.PHARMACY_CENTRAL_OUTPUT:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_OUTPUT;
          break;
        case this.PHARMACY_CENTRAL_REVERSAL:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_REVERSAL;
          break;
        case this.PHARMACY_CENTRAL_DISPOSAL:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_DISPOSAL;
          break;
        case this.PHARMACY_CENTRAL_REQUEST:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_REQUEST;
          break;
        case this.PHARMACY_CENTRAL_TRANSFER:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_TRANSFER;
          break;
        case this.PHARMACY_CENTRAL_ATTENDED_TRANSFER:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_ATTENDED_TRANSFER;
          break;
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          COLLUMNS_PHARMACY_CENTRAL = this.COLUMNS_REQUEST_TRANSFER;
          break;
      }

      return COLLUMNS_PHARMACY_CENTRAL;
    },
    nameSmartTable() {
      const statusProduct = this.form.statusProduct;
      let NAME_SMART_TABLE_PHARMACY_CENTRAL = [];
      switch (statusProduct) {
        case this.PHARMACY_CENTRAL_ENTRY:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralEntry`;
          break;
        case this.PHARMACY_CENTRAL_OUTPUT:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralOutput`;
          break;
        case this.PHARMACY_CENTRAL_REVERSAL:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralReversal`;
          break;
        case this.PHARMACY_CENTRAL_DISPOSAL:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralDisposal`;
          break;
        case this.PHARMACY_CENTRAL_REQUEST:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralRequest`;
          break;
        case this.PHARMACY_CENTRAL_TRANSFER:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralTransfer`;
          break;
        case this.PHARMACY_CENTRAL_ATTENDED_TRANSFER:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralAttendedTransfer`;
          break;
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          NAME_SMART_TABLE_PHARMACY_CENTRAL = `pharmacyCentralRequestTransfer`;
          break;
      }

      return NAME_SMART_TABLE_PHARMACY_CENTRAL;
    },
    disabledByModal() {
      return (
        this.modalDeleteMovement ||
        this.showModalDiscardProduct ||
        this.showModalSeeDiscard ||
        this.showModalSeeTransfer ||
        this.showModalSeeMovementTransferRequest ||
        this.showModalRequestDetails ||
        this.modalPrintMovement
      );
    },
  },

  watch: {
    "form.statusProduct"(pValue, pPrev) {
      if (pValue !== pPrev) {
        this.mutablePharmacyCentral = [];
        this.selectedRow = null;
        if (this.$refs.smartTable) {
          this.$refs.smartTable.cleanSelectRow();
        }
        this.$refs.rgSearch.clearPagination();
        this.form.guide = null;
        this.form.status = null;
      }
    },
    "form.guide"(pValue) {
      if (pValue === "") {
        this.form.guide = null;
      }
    },
    "form.unitHealth"(pValue) {
      if (!pValue && pValue === null) {
        this.form.pharmacy = null;
        this.form.product = null;
        this.form.uns_nome = null;
        this.form.far_nome = null;
      }
    },
    "form.pharmacy"(pValue) {
      if (!pValue && pValue === null) {
        this.form.product = null;
        this.form.far_nome = null;
      }
    },
  },

  mounted() {
    this.deleteRecordPreference = this.$store.getters["Login/GET_PREFERENCES"][
      "tViewFarmacia.trs_permitir_exclusao"
    ];
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.fillFilter();
    const remittanceParams = this.$route.params.remittance;
    if (remittanceParams !== undefined) {
      this.modalPrintMovement = true;
      this.filters = remittanceParams;
    }
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/pharmacy/central",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Pharmacy/Central/RESET_FILTER_PHARMACY_CENTRAL");
    }
  },

  created() {
    this.COLLUMS = [
      { name: "Situação", key: "situacao", align: "left", active: "true" },
      { name: "Código", key: "mpd_codigo", align: "left", active: "true" },
      {
        name: "Nome",
        key: "mpd_novo_principio_ativo",
        align: "left",
        active: "true",
      },
      {
        name: "Apresentação",
        key: "mtu_novo_nome",
        align: "left",
        active: "true",
      },
      { name: "Tipo", key: "mtp_nome", align: "left", active: "true" },
    ];
    this.COLUMNS_ENTRY = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "trs_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Entrada", key: "trs_data", align: "left", active: "true" },
      { name: "Nota Fiscal", key: "tec_nota_fiscal", align: "left" },
      { name: "Empenho", key: "tec_numero_empenho", align: "left" },
      { name: "Valor Unitário", key: "valor_unitario", align: "left" },
      { name: "Valor Total", key: "trs_valor", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Mínimo", key: "entrada", align: "left" },
      { name: "Máximo", key: "saida", align: "left" },
      { name: "Estoque Lote", key: "estocado", align: "left" },
      { name: "Estoque Total", key: "total_lote", align: "left" },
    ];
    this.COLUMNS_OUTPUT = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "trs_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Saída", key: "trs_data", align: "left", active: "true" },
      { name: "Destino", key: "destino", align: "left" },
      { name: "Guia da Remessa", key: "trs_guia_remessa", align: "left" },
      { name: "Valor Unitário", key: "valor_unitario", align: "left" },
      { name: "Valor Total", key: "valor", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Mínimo", key: "entrada", align: "left" },
      { name: "Máximo", key: "saida", align: "left" },
      { name: "Estoque", key: "total_lote", align: "left" },
    ];
    this.COLUMNS_REVERSAL = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "trs_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Data", key: "trs_data", align: "left", active: "true" },
      { name: "Origem", key: "origem", align: "left" },
      { name: "Guia da Remessa", key: "trs_guia_remessa", align: "left" },
      { name: "Valor Unitário", key: "valor_unitario", align: "left" },
      { name: "Valor Total", key: "valor", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Tipo de Estorno", key: "ttr_nome", align: "left" },
    ];
    this.COLUMNS_DISPOSAL = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "dsc_quantidade",
        align: "left",
        active: "true",
      },
      { name: "Data", key: "dsc_data", align: "left", active: "true" },
      { name: "Motivo", key: "mdc_nome", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Observações", key: "dsc_obs", align: "left" },
      { name: "Valor Unitário", key: "valor_unitario", align: "left" },
      { name: "Valor Total", key: "valor", align: "left" },
    ];
    this.COLUMNS_REQUEST = [
      { name: "Situação", key: "situacao", align: "left", active: "true" },
      { name: "Número", key: "ped_numero", align: "left", active: "true" },
      { name: "Data", key: "ped_data_pedido", align: "left", active: "true" },
      {
        name: "Solicitante",
        key: "solicitante",
        align: "left",
        active: "true",
      },
    ];
    this.COLUMNS_TRANSFER = [
      ...this.COLLUMS,
      {
        name: "Quantidade",
        key: "trs_quantidade",
        align: "left",
        active: "true",
      },
      {
        name: "Data da Entrada",
        key: "trs_data",
        align: "left",
        active: "true",
      },
      {
        name: "Valor Unitário",
        key: "valor_unitario",
        align: "left",
      },
      {
        name: "Valor Total",
        key: "trs_valor",
        align: "left",
      },

      { name: "Origem", key: "origem", align: "left" },
      { name: "Guia de Remessa", key: "trs_guia_remessa", align: "left" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Mínimo", key: "entrada", align: "left" },
      { name: "Máximo", key: "saida", align: "left" },
      { name: "Estoque", key: "total_lote", align: "left" },
    ];
    this.COLUMNS_ATTENDED_TRANSFER = [
      { name: "Situação", key: "situacao", align: "left", active: "true" },
      { name: "Número", key: "str_numero", align: "left", active: "true" },
      {
        name: "Data",
        key: "str_data_pedido",
        align: "left",
        active: "true",
      },
      {
        name: "Solicitante",
        key: "solicitante",
        align: "left",
        active: "true",
      },
    ];
    this.COLUMNS_REQUEST_TRANSFER = [
      ...this.COLUMNS_ATTENDED_TRANSFER,
      { name: "Farmácia Destino", key: "farmacia_destino", align: "left" },
      { name: "Farmácia Origem", key: "farmacia_origem", align: "left" },
    ];

    this.PHARMACY_CENTRAL_ENTRY = 1;
    this.PHARMACY_CENTRAL_OUTPUT = 2;
    this.PHARMACY_CENTRAL_REVERSAL = 3;
    this.PHARMACY_CENTRAL_DISPOSAL = 4;
    this.PHARMACY_CENTRAL_REQUEST = 5;
    this.PHARMACY_CENTRAL_TRANSFER = 6;
    this.PHARMACY_CENTRAL_REQUEST_TRANSFER = 7;
    this.PHARMACY_CENTRAL_ATTENDED_TRANSFER = 8;
  },

  methods: {
    validateValues(pValue, pErrors) {
      const isValidNumber = /^[0-9]+$/.test(pValue);

      const hasSpecial = /[:]/.test(pValue);
      const options = [
        this.PHARMACY_CENTRAL_REQUEST,
        this.PHARMACY_CENTRAL_ATTENDED_TRANSFER,
        this.PHARMACY_CENTRAL_REQUEST_TRANSFER,
      ];

      const hasRequest = options.includes(this.form.statusProduct);

      if (hasSpecial && pValue !== null) {
        pErrors.push("O campo deve conter apenas valores numéricos");
        return false;
      }
      if ((!isValidNumber || hasSpecial) && hasRequest && pValue !== null) {
        pErrors.push("O campo deve conter apenas valores numéricos");
        return false;
      }
      return true;
    },
    itemActionMovement() {
      return [
        {
          label: "Visualizar Movimentação",
          action: this.viewMovement,
        },
        {
          label: "Visualizar Observações",
          action: this.viewObservations,
        },
      ];
    },

    viewObservations() {
      if (this.form.statusProduct === this.PHARMACY_CENTRAL_REQUEST) {
        this.showModalObservationRequest = true;
        this.observationData = [
          {
            far_id: this.selectedRow.idFarmaciaAtendente,
            ped_id: this.selectedRow.ped_id,
            ped_numero: this.selectedRow.ped_numero,
          },
        ];
      }
      if (this.form.statusProduct === this.PHARMACY_CENTRAL_REQUEST_TRANSFER) {
        this.showModalObservationTransferRequest = true;
        this.observationData = [
          {
            far_id: this.selectedRow.idFarmaciaAtendente,
            str_id: this.selectedRow.str_id,
            str_numero: this.selectedRow.str_numero,
          },
        ];
      }
      if (this.form.statusProduct === this.PHARMACY_CENTRAL_ATTENDED_TRANSFER) {
        this.showModalObservationTransferRequest = true;
        this.observationData = [
          {
            far_id: this.selectedRow.idFarmaciaAtendente,
            str_id: this.selectedRow.str_id,
            str_numero: this.selectedRow.str_numero,
          },
        ];
      }
    },

    closeModalPrintMovement() {
      this.modalPrintMovement = false;
      this.filters = null;
    },
    openModalDeleteMovement() {
      this.modalDeleteMovement = true;
    },

    closeModalDeleteMovement() {
      this.modalDeleteMovement = false;
    },
    closeModalObservationTransferRequest() {
      this.showModalObservationTransferRequest = false;
    },
    closeModalObservationRequest() {
      this.showModalObservationRequest = false;
    },

    selectedUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.uns_nome = pValue.label;
      } else {
        this.form.uns_nome = "";
      }
    },
    selectedPharmacy(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.far_nome = pValue.label;
      } else {
        this.form.far_nome = "";
      }
    },
    editProduct() {
      switch (this.form.statusProduct) {
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          this.$router.push({
            name: "pharmacy.central.edit-transfer-request",
            params: this.selectedRow,
          });
          break;
        case this.PHARMACY_CENTRAL_ENTRY:
          this.$router.push({ name: "pharmacy.central.edit-entry" });
          break;
      }
    },
    async printerDeliveryNote() {
      try {
        this.$loader.start();
        let guide = null;
        if (this.selectedRow.trs_guia_remessa) {
          guide = this.selectedRow.trs_guia_remessa;
        } else {
          guide = await this.$store.dispatch(
            "Pharmacy/Central/SEARCH_GUIDE_SHIPPING",
            {
              str_id: this.selectedRow.str_id,
            },
          );
          guide = guide.dados;
        }

        const variables = {
          strGuiaRemessa: guide,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Central/PRINTER_OUTPUT",
          variables,
        );
        if (result.length > 0) {
          const html = DeliveryNoteHTML(
            {
              ...this.selectedRow,
              guide,
            },
            result,
            this.clientName,
            this.userName,
            this.unitHealth,
          );
          this.$utils.print.printHtml(html);
        } else {
          this.$toaster.warning(
            "Não existem medicamentos atendidos para essa transferência",
          );
        }
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir guia de remessa",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async printerRemittanceGuideRequest() {
      try {
        this.$loader.start();
        const variable = {
          intIdPedido: Number(this.selectedRow.ped_id),
          blnSomenteDisponiveis: true,
          blnDispensacoes: true,
        };
        const objRequest = this.selectedRow;
        const orderItems = await this.$store.dispatch(
          "Pharmacy/Central/GET_PRINT_REMITTANCE_GUIDE_REQUEST",
          variable,
        );
        if (orderItems.length > 0) {
          const html = RemittanceGuideRequest(
            objRequest,
            orderItems,
            this.clientName,
            this.userName,
            this.unitHealth,
          );
          this.$utils.print.printHtml(html);
        } else {
          this.$toaster.warning(
            "Não existem medicamentos atendidos para essa solicitação",
          );
        }
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir guia de remessa",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async printerRequest() {
      try {
        this.$loader.start();
        const variable = {
          intIdSolicitacao: this.selectedRow.str_id,
        };
        const objRequest = this.selectedRow;
        const orderItems = await this.$store.dispatch(
          "Pharmacy/Central/GET_PRINT_TRANSFER_REQUEST",
          variable,
        );

        const html = TransferRequestHTML(
          objRequest,
          orderItems,
          this.clientName,
          this.userName,
          this.unitHealth,
        );
        this.$utils.print.printHtml(html);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async deleteEntry() {
      try {
        this.$loader.start();

        const variables = {
          intIdTransporte: Number(this.selectedRow.trs_id),
          intIdTransporteCentral: Number(this.selectedRow.tec_id),
        };
        await this.$store.dispatch(
          "Pharmacy/Central/INACTIVATE_ENTRY",
          variables,
        );

        this.$toaster.success("Entrada excluída com sucesso");
        this.reSearch();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Naõ foi possível excluir a entrada",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async deleteDisposal() {
      try {
        const dataDiscard = moment(this.selectedRow.dsc_data, "DD/MM/YYYY");
        const data = moment(moment().format("01/MM/YYYY"), "DD/MM/YYYY");
        if (dataDiscard.isBefore(data)) {
          this.$toaster.error(
            "Descartes efetuados fora do mês corrente não podem ser excluídos.",
            "Não foi possível excluir o descarte",
          );
          return false;
        }

        this.$loader.start();
        await this.$store.dispatch(
          "Pharmacy/Central/INACTIVATE_DISPOSAL",
          Number(this.selectedRow.dsc_id),
        );

        this.$toaster.success("Descarte excluído com sucesso");
        this.reSearch();
      } catch (pErr) {
        const error = pErr.toString();
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o descarte",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async deleteRequestTranfer() {
      try {
        this.$loader.start();
        await this.$store.dispatch(
          "Pharmacy/Central/INACTIVATE_TRANSFER_REQUEST",
          Number(this.selectedRow.str_id),
        );

        this.$toaster.success(
          "Solicitação de transferência excluída com sucesso",
        );
        this.reSearch();
      } catch (pErr) {
        const error = pErr.toString();
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir a solicitação de transferência",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async deleteOutput() {
      try {
        this.$loader.start();

        await this.$store.dispatch(
          "Pharmacy/Central/INACTIVATE_OUTPUT",
          Number(this.selectedRow.trs_id),
        );

        this.$toaster.success("Saída excluída com sucesso");
        this.reSearch();
      } catch (pErr) {
        const error = pErr.toString();
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir a saída",
        );
      } finally {
        this.$loader.finish();
      }
    },
    registerEntry() {
      this.$router.push({ name: "pharmacy.central.entry" });
    },
    registerExit() {
      if (this.form.statusProduct === this.PHARMACY_CENTRAL_REQUEST) {
        this.$router.push({
          name: "pharmacy.central.attend.request",
          params: this.selectedRow,
        });
      } else {
        if (
          this.hasSelectedLine &&
          this.form.statusProduct === this.PHARMACY_CENTRAL_ENTRY
        )
          this.$router.push({ name: "pharmacy.central.output" });
        else this.$router.push({ name: "pharmacy.central.output.batch" });
      }
    },
    historyProduct() {
      this.$toaster.warning("Ainda em desenvolvimento");
    },
    closeModalSeeDiscard() {
      this.showModalSeeDiscard = false;
    },
    closeModalSeeTransfer() {
      this.showModalSeeTransfer = false;
    },

    viewMovement() {
      const statusProduct = this.form.statusProduct;
      switch (statusProduct) {
        case this.PHARMACY_CENTRAL_DISPOSAL:
          this.showModalSeeDiscard = true;
          break;
        case this.PHARMACY_CENTRAL_TRANSFER:
          this.showModalSeeTransfer = true;
          break;
        case this.PHARMACY_CENTRAL_REQUEST:
          this.showModalRequestDetails = true;
          this.requestId = Number(this.selectedRow.ped_id);
          break;
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          this.typeMovement = 5;
          this.showModalSeeMovementTransferRequest = true;
          break;
        case this.PHARMACY_CENTRAL_ATTENDED_TRANSFER:
          this.typeMovement = 4;
          this.showModalSeeMovementTransferRequest = true;
          break;
        default:
          break;
      }
    },
    printer() {
      const statusProduct = this.form.statusProduct;

      switch (statusProduct) {
        case this.PHARMACY_CENTRAL_OUTPUT:
        case this.PHARMACY_CENTRAL_TRANSFER:
          this.printerDeliveryNote();
          break;
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          this.printerRequest();
          break;
        case this.PHARMACY_CENTRAL_ATTENDED_TRANSFER:
          this.printerDeliveryNote();
          break;
        case this.PHARMACY_CENTRAL_REQUEST:
          this.printerRemittanceGuideRequest();
          break;
        default:
          break;
        // outras telas
      }
    },
    discardProduct() {
      this.showModalDiscardProduct = true;
    },
    requestTransfer() {
      this.$router.push({ name: "pharmacy.central.transfer.request" });
    },
    attendTransfer() {
      this.$router.push({ name: "pharmacy.central.attend.transfer" });
    },

    closeModalDiscardProduct(pValue) {
      this.showModalDiscardProduct = false;
      this.selectedRow = null;
      this.form.pharmacy = pValue || this.form.pharmacy;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    closeModalSeeMovement() {
      this.showModalSeeMovement = false;
    },
    closeModalSeeMovementTransferRequest() {
      this.showModalSeeMovementTransferRequest = false;
    },
    afterSearchFilter() {
      this.getDynamicHeight();

      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
        this.selectedRow = null;
      }
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgSearch?.$refs.listContent) {
          const searchArea = this.$refs.rgSearch.$refs.listContent.offsetHeight;
          const radios = this.$refs.radios.offsetHeight;
          const footer = this.$refs.footer.offsetHeight;
          this.tableHeight = searchArea - radios - footer - 10;
          if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        }
      });
    },
    selectLine(pValue) {
      if (pValue) {
        this.selectedRow = pValue;
      } else {
        this.selectedRow = null;
      }
      this.$store.commit("Pharmacy/Central/SELECT_PHARMACY_CENTRAL", pValue);
    },

    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },
    generateFilter() {
      const variables = {
        arrFormData: {
          uns_id: this.form.unitHealth,
          far_id: Number(this.form.pharmacy),
          mpd_id: this.form.product,
          strPeriodoInicial: this.form.initialDate,
          strPeriodoFinal: this.form.finalDate,
          ...((this.form.statusProduct === this.PHARMACY_CENTRAL_REQUEST ||
            this.form.statusProduct ===
              this.PHARMACY_CENTRAL_ATTENDED_TRANSFER) && {
            ped_numero: this.form.guide,
            ped_status:
              this.form.status === null ? -1 : this.form.status === 1 ? 0 : 1,
          }),
          ...(this.form.statusProduct ===
            this.PHARMACY_CENTRAL_REQUEST_TRANSFER && {
            str_numero: this.form.guide,
            str_status:
              this.form.status === null ? -1 : this.form.status === 1 ? 0 : 1,
          }),
          ...((this.form.statusProduct === this.PHARMACY_CENTRAL_OUTPUT ||
            this.form.statusProduct === this.PHARMACY_CENTRAL_REVERSAL) && {
            trs_guia_remessa: this.form.guide,
          }),
          // ...((this.form.statusProduct === this.PHARMACY_CENTRAL_REQUEST ||
          //   this.form.statusProduct ===
          //     this.PHARMACY_CENTRAL_REQUEST_TRANSFER) && {
          //   str_status:
          //     this.form.status === null ? -1 : this.form.status === 1 ? 0 : 1,
          // }),
        },
      };

      if (this.form.statusProduct === this.PHARMACY_CENTRAL_REQUEST)
        variables.intIdTipoFarmacia = this.PHARMACY_CENTRAL_ENTRY;

      this.$store.commit(
        "Pharmacy/Central/SET_FILTER_CENTRAL",
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },
    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Pharmacy/Central/GET_FILTER_CENTRAL"
        ];

        if (existFilterData) {
          this.$loader.start();
          this.form.unitHealth = existFilterData.unitHealth;
          this.form.statusProduct = existFilterData.statusProduct;
          this.form.product = existFilterData.product;
          this.form.pharmacy = existFilterData.pharmacy;

          this.form.initialDate = existFilterData.initialDate;
          this.form.finalDate = existFilterData.finalDate;
          this.form.status = existFilterData.status;
          this.form.guide = existFilterData.status;
          await this.$refs.rgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning(Err, "Erro ao carregar os dados da navegação");
        this.$loader.finish();
      }
    },
    async searchFilter(pData) {
      try {
        const statusProduct = this.form.statusProduct;

        switch (statusProduct) {
          case this.PHARMACY_CENTRAL_ENTRY:
            return await this.searchEntry(pData);
          case this.PHARMACY_CENTRAL_OUTPUT:
            return await this.searchOutput(pData);
          case this.PHARMACY_CENTRAL_REVERSAL:
            return await this.searchReversal(pData);
          case this.PHARMACY_CENTRAL_DISPOSAL:
            return await this.searchDisposal(pData);
          case this.PHARMACY_CENTRAL_REQUEST:
            return await this.searchRequest(pData);
          case this.PHARMACY_CENTRAL_TRANSFER:
            return await this.searchTransfer(pData);
          case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
            return await this.searchRequestTransfer(pData);
          case this.PHARMACY_CENTRAL_ATTENDED_TRANSFER:
            return await this.searchAttendedTransfer(pData);
          default: {
            this.$toaster.warning(
              `Os filtros estão localizados abaixo do título "Produtos".`,
              "Selecione um dos filtros para exibir os resultados",
            );
          }
        }
      } catch (pErr) {
        this.$toaster.warning(pErr);
      }
    },
    async searchEntry(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_ENTRY",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchOutput(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_OUTPUT",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchReversal(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_REVERSAL",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchDisposal(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_DISPOSAL",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchRequest(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_REQUEST",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchTransfer(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_TRANSFER",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchRequestTransfer(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_REQUEST_TRANSFER",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    async searchAttendedTransfer(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_ATTENDED_TRANSFER",
          pData,
        );
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },
    isFormValid() {
      return this.$refs.rgSearch.$refs.validator
        ? this.$refs.rgSearch.$refs.validator.validate()
        : false;
    },
    async reSearch(isDiscart = false) {
      this.form.initialDate = isDiscart
        ? moment().format("01/MM/YYYY")
        : this.form.initialDate;
      this.form.finalDate = isDiscart
        ? moment().format("DD/MM/YYYY")
        : this.form.finalDate;
      const isInvalid = !(await this.isFormValid());
      if (isInvalid) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      this.selectedRow = null;
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
      this.$refs.rgSearch.clearPagination();
      this.$refs.rgSearch.performSearch();
    },
    cleanForm() {
      this.form = this.$utils.obj.DeepCopy(FORM_CENTRAL);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.mutablePharmacyCentral = [];
      this.selectedRow = null;
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },
    onInputRadioCustom(item) {
      if (item) {
        this.form.statusProduct = item.id;
        this.reSearch();
      } else {
        this.form.statusProduct = null;
        this.reSearch();
      }
    },
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },
    closeModalRequestDetails() {
      this.showModalRequestDetails = false;
    },
  },
};
</script>
