import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchAbEsus($pacId: Int!) {
    SearchAbEsus(pae_id_pacientes: $pacId) {
      rows {
        pae_id
        pae_id_pacientes
        pae_ficha
        pae_ficha_base
        pae_nome_paciente
        pae_nome_mae
        pae_nascimento
        pae_cpf
        pae_cartao_sus
        pae_sexo
        pae_data_atendimento
        pae_unidade_saude
        pae_area
        pae_microarea
        pae_equipe
        pae_responsavel
        pae_ocupacao_responsavel
        pae_data_visita_domiciliar
        pae_responsavel_visita_domiciliar
        pae_ocupacao_responsavel_visita_domiciliar
        pae_unidade_saude_visita_domiciliar
        pae_area_visita_domiciliar
        pae_equipe_visita_domiciliar
        pae_microarea_visita_domiciliar
        pae_ativo
        pae_ultima_alteracao
        pae_nome_social
        pae_nome_pai
        pae_raca_cor
        pae_etnia
        pae_telefone
        pae_celular
        pae_email
        pae_endereco
        pae_naturalidade
        pae_nacionalidade
        pae_data_naturalizacao
        pae_portaria_naturalizacao
        pae_data_entrada_brasil
        pae_motivo_saida_cadastro_cidadao
        pae_numero_obito
        pae_data_obito
        pae_ocupacao_paciente
        pae_codigo_ocupacao_paciente
        pae_situacao_saude
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.SearchAbEsus.rows[0];
};
