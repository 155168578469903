import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query GetQuantitySheetConsolidatedBilling(
    $fco_id_unidades_saude: Int!
    $period: String!
    $sheet: String!
  ) {
    GetQuantitySheetConsolidatedBilling(
      fco_id_unidades_saude: $fco_id_unidades_saude
      fco_competencia: $period
      fco_folha: $sheet
    ) {
      rows {
        registros
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.GetQuantitySheetConsolidatedBilling?.rows[0]?.registros;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors);
  }
};
