<template lang="html">
  <div v-if="show" class="modal-confirm-inactivate-activate">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">{{ activateInactivateTitle }} o {{ type }}</h1>
      </div>

      <div slot="body" class="body">
        <span class="text">
          Deseja {{ activateInactivateTitle }} o {{ type }}?
        </span>
        <span class="content">
          {{ inactivationActivationInfo || "Não informado" }}
        </span>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel-btn" medium @click="cancel" />
        <MediumButton
          id="save-btn"
          :title="activateInactivateTitle"
          :label="activateInactivateTitle"
          @click="save"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, MediumButton } from "~tokio/primitive";

export default {
  name: "ModalConfirmInactivateActivate",

  components: {
    RgBaseModal,
    RgCancelButton,
    MediumButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    activateInactivate: {
      type: Boolean,
      default: false,
    },
    inactivationActivationInfo: {
      type: String,
      required: true,
    },
  },

  computed: {
    activateInactivateTitle() {
      return this.activateInactivate ? "Inativar" : "Ativar";
    },
  },

  created() {},

  methods: {
    save() {
      this.$emit("save", this.type);
    },

    cancel() {
      this.$emit("close");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
