<style src="./RgSuggestPatientInformation.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient-information">
    <div v-if="hasPatientNotIdentified" class="patient-not-identified">
      <input
        id="patient-not-identified"
        v-model="patientNotIdentified"
        type="checkbox"
        value="1"
        :disabled="disabledByModal"
      />
      <label for="label"> Paciente não identificado </label>
    </div>

    <div v-if="!patientNotIdentified" class="patient">
      <div class="search-patient">
        <RgSuggestSmartPerson
          :id="id"
          ref="patient"
          v-model="suggestPatient"
          :label="label"
          :with-patient-module="withPatientModule"
          :rules="rules"
          :remove-filter="removeFilter"
          :extra-data="extraData"
          :disabled="disabled || disabledOnlySuggest || disabledByModal"
          :permit-free-text="permitFreeText"
          :auto-select-if-has-one="autoSelectIfHasOne"
          :show-option-radios="showOptionRadios"
          :filterBulletinHospitalization="filterBulletinHospitalization"
          :confirmToRemove="confirmToRemove"
          :message="message"
          :activePatient="activePatient"
          class="input"
          @selected="selectingPatient"
          @cleanPerson="cleanPatientInfo"
        />

        <div v-show="showButtons" class="actions">
          <RgPersonCardButton
            id="editar-rgSuggest"
            :disabled="!isPerson || disableOnlyEdit || disabledByModal"
            class="card"
            title="Cadastro de Paciente"
            small
            @click="editPatient"
          />

          <RgHistoryButton
            id="history-rgSuggest"
            :disabled="!isPerson || disabledByModal"
            class="card"
            title="Histórico de Atendimento"
            small
            @click="historyPatient"
          />

          <SmallButton
            id="search-rgSuggest"
            class="button-open-modal"
            backgroundColor="#1e88a9"
            title="Busca avançada de pacientes"
            :disabled="isPerson || disabledByModal"
            @click="openModalSearch"
          >
            <IconSearchExam slot="icon" class="svg" />
          </SmallButton>

          <RgAddButton
            id="new-rgSuggest"
            class="new"
            title="Cadastro de Paciente"
            small
            :disabled="isPerson || disabledByModal"
            @click="newPatient"
          />
        </div>
      </div>

      <div class="patient-information">
        <div class="title">
          <span class="title">Paciente Selecionado</span>
        </div>

        <div class="container">
          <div
            class="person"
            :class="{ hasMoreData: filterBulletinHospitalization }"
          >
            <span class="text-info">
              <IconPatientBioslab class="svg" />
              Nome: <strong>{{ patientInfo.pac_nome || "" }}</strong>
            </span>
            <span class="text-info">
              <IconCalendar class="svg" />
              Nascimento:
              <strong :title="fullAgeFormated(patientInfo.pes_nascimento)">
                {{ formatDataBR(patientInfo.pes_nascimento) || "" }}
                <i>{{ partAgeFormated(patientInfo.pes_nascimento) }}</i>
              </strong>
            </span>
            <span class="text-info">
              <IconPatientBioslab class="svg" />
              Nome da Mãe:
              <strong>{{ patientInfo.pes_mae || "" }}</strong>
            </span>
            <span class="text-info">
              <IconTelephone class="svg" />
              Telefone:
              <strong :title="concatPatientTel">{{ patientTel || "" }}</strong>
            </span>
          </div>
        </div>

        <div class="container">
          <div
            class="document"
            :class="{ hasMoreData: filterBulletinHospitalization }"
          >
            <span class="text-info">
              <IconCard class="svg" />
              CNS: <strong>{{ patientInfo.crs_numero || "" }}</strong>
            </span>
            <span class="text-info">
              <IconCard class="svg" />
              CPF: <strong>{{ patientInfo.cpf || "" }}</strong>
            </span>
            <span class="text-info">
              <IconCard class="svg" />
              Prontuário Único:
              <strong>{{
                patientInfo.prontuario_unico
                  ? patientInfo.prontuario_unico
                  : pac_prontuario_unico || ""
              }}</strong>
            </span>
            <span class="text-info">
              <IconCard class="svg" />
              Prontuário da Unidade:
              <strong>{{
                patientInfo.ppr_numero ? patientInfo.ppr_numero : ""
              }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="not-patient">
      <RgRadioCustom
        id="gender"
        ref="gender"
        v-bind="propsRadioCustom"
        :canUnselect="false"
        label="Sexo"
        :rules="{ required: true }"
        :disabled="disabledByModal"
        @input="onInputRadioCustom"
      />

      <RgInput
        id="age"
        v-model="age"
        :disabled="disabledByModal"
        :rules="{ required: true }"
        label="Idade aproximada"
      />
      <RgInput
        id="description"
        v-model="description"
        label="Descrição"
        :disabled="disabledByModal"
      />
    </div>

    <ModalAdvancedPatientSearch
      id="modal-advanced-patient-search"
      :show="showModalSearch"
      :withPatientModule="withPatientModule"
      :extraData="extraData"
      @selected="getPatientFromModal"
      @close="closeModalSearch"
    />
  </div>
</template>
<script>
import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson";
import ModalAdvancedPatientSearch from "$person/patient/component/modal/modal-advanced-patient-search/ModalAdvancedPatientSearch";
import moment from "moment";
import { AlertError } from "~tokio/primitive/notification";

import {
  IconPatientBioslab,
  IconTelephone,
  IconCard,
  IconCalendar,
  RgPersonCardButton,
  RgAddButton,
  RgHistoryButton,
  RgInput,
  SmallButton,
  IconSearchExam,
  RgRadioCustom,
} from "~tokio/primitive";

export default {
  name: "RgSuggestPatientInformation",
  components: {
    RgSuggestSmartPerson,
    IconPatientBioslab,
    IconTelephone,
    IconCard,
    IconCalendar,
    RgPersonCardButton,
    RgAddButton,
    RgHistoryButton,
    RgInput,
    SmallButton,
    ModalAdvancedPatientSearch,
    IconSearchExam,
    RgRadioCustom,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Object,
      default: () => {},
      required: true,
    },
    showCurrentStatus: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disableOnlyEdit: {
      type: Boolean,
      default: false,
    },
    confirmToRemove: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    removeFilter: {
      type: Array,
      default: () => [],
    },
    withPatientModule: {
      type: Array,
    },
    hasPatientNotIdentified: {
      type: Boolean,
      default: false,
    },
    disabledOnlySuggest: {
      type: Boolean,
      default: false,
    },
    extraData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    permitFreeText: {
      type: Boolean,
      default: false,
    },
    disableEditPatient: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pac_prontuario_unico: null,
    filterBulletinHospitalization: Boolean,
    activePatient: Number,
    createPatient: {
      type: Boolean,
      default: true,
    },
    withPatientEmergency: Boolean,
    withPatientAppointment: Boolean,
    withPatientHospitalization: Boolean,
    autoSelectIfHasOne: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    showOptionRadios: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      suggestPatient: null,
      patientNotIdentified: null,
      patientInfo: {},
      gender: null,
      age: null,
      description: null,
      showModalSearch: false,
    };
  },

  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.gender;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    disabledByModal() {
      return this.showModalSearch;
    },
    isPerson() {
      return this.patientInfo.pes_id && this.patientInfo.pes_id > 0;
    },
    withTelephone() {
      return this.extraData.includes("telephone");
    },
    patientTel() {
      let telephone = "";
      if (
        this.patientInfo.telephones?.rows[0]?.ddd?.ddd_codigo &&
        this.patientInfo.telephones?.rows[0]?.tel_numero
      ) {
        telephone =
          "(" +
          this.patientInfo.telephones?.rows[0]?.ddd?.ddd_codigo +
          ")" +
          (this.patientInfo.telephones?.rows[0]?.tel_numero.length > 8
            ? this.patientInfo.telephones?.rows[0]?.tel_numero.substr(0, 5)
            : this.patientInfo.telephones?.rows[0]?.tel_numero.substr(0, 4)) +
          "-" +
          (this.patientInfo.telephones?.rows[0]?.tel_numero.length > 8
            ? this.patientInfo.telephones?.rows[0]?.tel_numero.substr(5, 9)
            : this.patientInfo.telephones?.rows[0]?.tel_numero.substr(4, 9));
      } else {
        telephone = null;
      }
      return telephone;
    },
    concatPatientTel() {
      let telephone = "";
      if (this.patientInfo?.telephones?.rows.length > 2) {
        this.patientInfo.telephones.rows.forEach((item, index) => {
          if (index >= 1) {
            if (index > 1) telephone += " / ";
            telephone +=
              "(" +
              item?.ddd?.ddd_codigo +
              ")" +
              (item.tel_numero.length > 8
                ? item?.tel_numero.substr(0, 5)
                : item?.tel_numero.substr(0, 4)) +
              "-" +
              (item.tel_numero.length > 8
                ? item?.tel_numero.substr(5, 9)
                : item?.tel_numero.substr(4, 9));
          }
        });
      } else if (this.patientInfo?.telephones?.rows.length === 2) {
        telephone =
          "(" +
          this.patientInfo.telephones?.rows[1]?.ddd?.ddd_codigo +
          ")" +
          this.patientInfo.telephones?.rows[1]?.tel_numero;
      } else {
        telephone = "";
      }
      return telephone;
    },
  },

  watch: {
    suggestPatient() {
      this.$emit("input", this.suggestPatient);
    },

    patientNotIdentified(pValue) {
      if (pValue && pValue === true) {
        this.cleanPatient();
      } else {
        this.cleanNotPatient();
      }
    },
    gender(pGender) {
      if (pGender) {
        this.notPatient();
      }
    },
    age(pAge) {
      if (pAge) {
        this.notPatient();
      }
    },
    description(pDescription) {
      if (pDescription) {
        this.notPatient();
      }
    },
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.gender = item.id;
      }
    },
    getPatientFromModal(pData) {
      this.$refs.patient.emitData(pData);
    },

    async fillPatientById(pPersonId, extraData = null) {
      const hasPersonId = pPersonId && Number(pPersonId) > 0;

      if (hasPersonId) {
        await this.$refs.patient.fillPatientById(pPersonId, extraData);
      }
    },

    async selectingPatient(pValue) {
      const pData = pValue?.source;

      const patient = pData?.patient;
      const hasPatient = patient && patient.pac_id > 0;
      let newPatient = null;

      if (hasPatient && patient.disqualification_reasons) {
        const patientReasonBlocked = patient.disqualification_reasons.mip_nome;

        this.$emit("blockedPatient", true);

        return AlertError(
          "Paciente inativo. Motivo: " + `${patientReasonBlocked}`,
        );
      }

      this.$emit("blockedPatient", false);

      if (this.createPatient) {
        const isPerson = pData?.pes_id;
        const isPatient = pData?.patient?.pac_id;

        if (isPerson && !isPatient) {
          newPatient = await this.$store.dispatch(
            "Person/Patient/CREATE_PATIENT_FOR_PERSON",
            {
              pes_id: pData.pes_id,
              withPatientAppointment: this.withPatientAppointment,
              withPatientEmergency: this.withPatientEmergency,
              withPatientHospitalization: this.withPatientHospitalization,
            },
          );

          this.fillPatientById(isPerson);
        }
      }

      this.patientInfo = {
        pes_id: pData?.pes_id,
        pac_id: pData?.patient?.pac_id || newPatient?.pac_id,
        pac_nome: pData?.pes_nome,
        sex_sigla: pData?.gender?.sex_sigla,
        pes_nascimento: pData?.pes_nascimento,
        crs_numero: pData?.cns?.crs_numero,
        pes_mae: pData?.pes_mae,
        telephones: pData?.telephones,
        cpf: pData?.cpf?.cpf_numero,
        prontuario_unico:
          pData?.patient?.pac_prontuario_unico ||
          newPatient?.pac_prontuario_unico,
        ppr_numero:
          pData?.patient?.record_numbers?.rows[0]?.ppr_numero ||
          pData?.ppr_numero,
        bulletin:
          pData?.patient?.patient_emergency?.bulletin?.bol_numero &&
          `${pData?.patient?.patient_emergency?.bulletin?.bol_numero} (${pData?.patient?.patient_emergency?.bulletin?.sector?.set_nome})`,
        hospitalization:
          pData?.patient?.patient_hospitalization?.hospitalization?.int_numero,
      };

      this.$emit("patientInfo", pData);
    },

    notPatient() {
      this.patientInfo = {
        gender: this.gender,
        age: this.age,
        description: this.description,
      };

      this.$emit("patientInfo", this.patientInfo);
    },

    editPatient() {
      const patientId = this.patientInfo.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", patientId);
      this.$store.commit("Person/Patient/SET_LAST_PERSON_ID_SAVE", patientId);

      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.edit-patient`,
        params: { disableEditPatient: this.disableEditPatient },
      });

      this.$emit("edit");
    },

    newPatient() {
      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.new-patient`,
      });
    },

    historyPatient() {
      const patientInfo = this.patientInfo;
      const routeName = this.$route.name;

      this.$router.push({
        name: `${routeName}.patient-care-history`,
        params: patientInfo,
      });
    },

    formatDataBR(pValue) {
      if (!pValue) return "";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },

    cleanNotPatient() {
      this.patientInfo = {};
      this.gender = null;
      this.age = null;
      this.description = null;
    },

    cleanPatient() {
      this.suggestPatient = null;
      this.patientInfo = {};
    },

    cleanPatientInfo() {
      this.patientNotIdentified = null;
      this.cleanNotPatient();
      this.cleanPatient();
    },

    cleanValidate() {
      this.$refs.patient.cleanValidate();
    },

    openModalSearch() {
      this.$refs.patient.validfilters = [];
      this.$refs.patient.captionRaw = "pes_nome";
      this.$refs.patient.colFilter = { label: "Nome", col: "peopleName" };
      this.showModalSearch = true;
      this.$emit("disabledByModalComponents", this.showModalSearch);
    },

    closeModalSearch() {
      this.showModalSearch = false;
      this.$emit("disabledByModalComponents", this.showModalSearch);
    },

    fullAge(pValue) {
      const birthDate = moment(pValue);
      const currentDate = moment();
      const isBirthDateValid = currentDate.isAfter(birthDate);

      if (!pValue || !isBirthDateValid) return "";

      const birthDateDay = birthDate.date();
      const birthDateMonth = birthDate.month() + 1;
      const birthDateYear = birthDate.year();

      let currentDateDay = currentDate.date();
      let currentDateMonth = currentDate.month() + 1;
      let currentDateYear = currentDate.year();

      let lastMonth = currentDateMonth - 1;

      if (lastMonth === 0) lastMonth = 12;

      if (currentDateDay < birthDateDay) {
        switch (lastMonth) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            currentDateDay += 31;
            currentDateMonth--;
            break;
          }
          default: {
            currentDateDay += 30;
            currentDateMonth--;
            break;
          }
        }
      }

      const ageDays = currentDateDay - birthDateDay;

      if (currentDateMonth < birthDateMonth) {
        currentDateMonth += 12;
        currentDateYear--;
      }

      const ageMonths = currentDateMonth - birthDateMonth;

      const ageYears = currentDateYear - birthDateYear;

      return {
        ageYears,
        ageMonths,
        ageDays,
      };
    },

    fullAgeFormated(pValue) {
      const { ageYears, ageMonths, ageDays } = this.fullAge(pValue);
      const hasDay = ageDays >= 0;
      const dayTitle = hasDay ? ageDays + " dia(s)" : "";
      const hasMonth = ageMonths > 0;
      const monthTitle = hasMonth ? ageMonths + " mês(es) " : "";
      const hasYear = ageYears > 0;
      const yearTitle = hasYear ? ageYears + " ano(s) " : "";

      return yearTitle + monthTitle + dayTitle;
    },

    partAgeFormated(pValue) {
      const { ageYears, ageMonths, ageDays } = this.fullAge(pValue);

      const hasDay = ageDays >= 0;
      const dayTitle = ageDays === 1 ? "(1 dia)" : "(" + ageDays + " dias)";
      const hasMonth = ageMonths > 0;
      const monthTitle =
        ageMonths === 1 ? "(1 mês)" : "(" + ageMonths + " meses)";
      const hasYear = ageYears > 0;
      const yearTitle = ageYears === 1 ? "(1 ano)" : "(" + ageYears + " anos)";

      const partOfAge = hasYear
        ? yearTitle
        : hasMonth
        ? monthTitle
        : hasDay
        ? dayTitle
        : "";

      return partOfAge;
    },
  },
};
</script>
