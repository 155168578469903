<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnithealthPharmacy from "./action/SearchUnithealthPharmacy";
import SearchUnithealthPharmacyWithoutPermission from "./action/SearchUnithealthPharmacyWithoutPermission";

export default {
  name: "RgSelectUnithealthPharmacy",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    typePharmacy: {
      type: Number,
      default: null,
    },
    filter: {
      type: Array,
      default: null,
    },
    withPermission: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      unitHealth: [],
    };
  },
  watch: {
    unitHealth: function (val) {
      this.updateOptions();
    },
    filter(pValue) {
      if (pValue && pValue.length > 0) {
        this.filterResult();
      }
    },
  },
  async mounted() {
    await this.doSearch();
  },
  methods: {
    getData() {
      return this.unitHealth;
    },
    async doSearch() {
      let data;
      this.unitHealth = [];

      if (this.withPermission === true) {
        data = await SearchUnithealthPharmacyWithoutPermission({
          intTipoFarmacia: this.typePharmacy,
        });
      } else {
        data = await SearchUnithealthPharmacy({
          intTipoFarmacia: this.typePharmacy,
        });
      }

      this.unitHealth = data.map((item) => {
        return {
          value: Number(item.data),
          label: item.label,
        };
      });
    },
    filterResult() {
      const aux = [];
      let aux2 = [];

      for (const pValue of this.filter) {
        aux2 = this.unitHealth.filter(
          (pData) => Number(pData.value) === Number(pValue.uns_id),
        );
        aux2.forEach((item) => {
          if (!aux.includes(item)) aux.push(item);
        });
      }
      this.unitHealth = aux;
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
