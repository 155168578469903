import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchUnitHealthSubTypeSus(
    $stu_id_tipos_unidades_saude_sus: Int!
    $limit: Int
  ) {
    unitHealthSubTypeSus(
      stu_id_tipos_unidades_saude_sus: $stu_id_tipos_unidades_saude_sus
      limit: $limit
    ) {
      rows {
        stu_id
        stu_codigo
        stu_nome
      }
    }
  }
`;
export default async ({
  stu_id_tipos_unidades_saude_sus = 0,
  limit = 1000,
}) => {
  const variables = {
    stu_id_tipos_unidades_saude_sus,
    limit,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.unitHealthSubTypeSus.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
