<template lang="html">
  <div v-if="show" class="modal-register-product">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-register-product-header">
        <span class="title">
          {{ edition ? "Editar Produto" : "Cadastrar Produto" }}
        </span>
      </div>

      <div slot="body" class="modal-register-product-body">
        <RgValidatorForm ref="validator">
          <FormBase title="Descrição do Produto" class="form-base">
            <div class="grid">
              <RgSuggestCity
                ref="city"
                v-model="form.city"
                :rules="{ required: true }"
                :class="{ disable: true }"
                disabled
                label="Município"
                class="city"
                @selected="selectingMunicipality"
              />
              <RgInput
                ref="code"
                v-model="form.code"
                :rules="{ required: true }"
                label="Código"
                placeholder="Digite o número do código"
                class="code"
                :maxlength="15"
              />
              <RgSelectTypePresentation
                ref="typePresentation"
                v-model="form.typePresentation"
                :rules="{ required: true }"
                maxHeight="150px"
                class="type-presentation"
                @change="selectedTypePresentation"
              />
              <RgSelectProductType
                ref="productType"
                v-model="form.productType"
                :rules="{ required: true }"
                maxHeight="80px"
                label="Tipo de Produto"
                class="product-type"
              />
              <RgInput
                ref="activePrinciple"
                v-model="form.activePrinciple"
                label="Príncípio Ativo"
                placeholder="Digite a descrição do princípio ativo da medicação/produto que será cadastrado"
                class="active-principle"
                :rules="{ required: true, fn: validateEspecialCharacter }"
                :needRemoveSpecialStrings="false"
                :maxlength="700"
                :disabled="edition"
                :class="{ disable: edition }"
              />
              <RgInput
                ref="commercialName"
                v-model="form.commercialName"
                label="Nome Comercial"
                placeholder="Digite o nome do produto/medicamento que será cadastrado"
                class="commercial-name"
                :rules="{ fn: validateEspecialCharacter }"
                :needRemoveSpecialStrings="false"
                :maxlength="100"
                :disabled="edition"
                :class="{ disable: edition }"
              />
              <RgSelectMedicineAB
                ref="medicineAB"
                v-model="form.medicineAB"
                openOnlyTop
                maxHeight="200px"
                class="medicine-AB"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </div>

      <div slot="footer" class="modal-register-product-footer">
        <RgCleanButton small @click="cleanForm" />
        <RgSaveButton medium @click="saveProduct" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgBaseModal,
  RgValidatorForm,
  RgInput,
  RgCleanButton,
  RgSaveButton,
} from "~tokio/primitive";

import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import FormBase from "~tokio/foundation/form-base/FormBase";

import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectMedicineAB from "$pharmacy/common/select/rg-select-medicine-AB/RgSelectMedicineAB";

const FORM = {
  productId: null,
  city: null,
  cityId: null,
  code: null,
  typePresentation: null,
  activePrinciple: null,
  productType: null,
  medicineAB: null,
  commercialName: null,
  typePresentationName: null,
};

export default {
  name: "ModalRegisterProduct",
  components: {
    RgBaseModal,
    RgValidatorForm,
    FormBase,
    RgInput,
    RgSuggestCity,
    RgSelectTypePresentation,
    RgSelectProductType,
    RgSelectMedicineAB,
    RgCleanButton,
    RgSaveButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    edition: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
    };
  },
  computed: {
    ...mapGetters({
      clientId: "Login/GET_USER_ID_CLIENT",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),
  },
  watch: {
    show(pValue) {
      if (pValue) {
        if (this.edition) {
          this.fillForm();
        } else {
          this.mountedInfo();
        }
      } else {
        this.close();
      }
    },
  },
  methods: {
    selectedTypePresentation(pValue) {
      if (pValue) {
        this.form.typePresentationName = pValue.label;
      } else {
        this.form.typePresentationName = null;
      }
    },
    async mountedInfo() {
      this.$loader.start();
      this.form.code = await this.$store.dispatch(
        "Pharmacy/Register/GET_NEW_CODE_PRODUCT",
      );

      const hasCity = this.currentUnitHealth?.endereco?.neighborhood?.city;

      if (hasCity && this.$refs.city) {
        const city = this.currentUnitHealth.endereco.neighborhood.city;

        this.$refs.city.forceSelection({
          mun_id: city ? city.mun_id : null,
          mun_nome: city ? city.mun_nome : null,
        });
      }
      this.$loader.finish();
    },
    async fillForm() {
      const product = await this.$store.dispatch(
        "Pharmacy/Register/GET_PRODUCT",
        { intIdMunicipioProduto: this.productId },
      );

      if (product) {
        this.$loader.start();

        if (this.$refs.city) {
          this.$refs.city.forceSelection({
            mun_id: product.objMunicipio.mun_id,
            mun_nome: product.objMunicipio.mun_nome,
          });
        }

        this.form = {
          productId: product.mpd_id,
          city: product.objMunicipio.mun_nome,
          cityId: product.objMunicipio.mun_id,
          code: product.mpd_codigo,
          typePresentation: product.mpd_id_municipios_tipos_unidade,
          activePrinciple: product.mpd_novo_principio_ativo,
          productType: product.mpd_id_municipios_tipos_produtos,
          medicineAB: product.mpd_id_ab_medicamentos_catmat,
          commercialName: product.mpd_novo_nome,
        };
      }
      this.$loader.finish();
    },
    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async saveProduct() {
      const validate = await this.isFormValid();

      if (!validate) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            mpd_id: Number(this.form.productId) || null,
            mpd_id_clientes: this.clientId,
            mpd_id_municipios_tipos_unidade: Number(this.form.typePresentation),
            mpd_id_municipios_tipos_produtos: Number(this.form.productType),
            mpd_id_municipios: Number(this.form.cityId),
            mpd_id_ab_medicamentos_catmat: Number(this.form.medicineAB),
            mpd_codigo: this.form.code,
            mpd_novo_principio_ativo: this.form.activePrinciple
              ? this.form.activePrinciple.toUpperCase()
              : null,
            mpd_novo_nome: this.form.commercialName
              ? this.form.commercialName.toUpperCase()
              : null,
          },
        };

        if (this.edition) {
          await this.$store.dispatch(
            "Pharmacy/Register/EDIT_PRODUCT",
            variables,
          );
          this.$toaster.success("Produto alterado com sucesso");
        } else {
          await this.$store.dispatch(
            "Pharmacy/Register/REGISTER_PRODUCT",
            variables,
          );
          this.$toaster.success("Produto cadastrado com sucesso");
        }

        this.close();
        this.$emit("reSearch");
      } catch (pErr) {
        const error = pErr.toString();
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isDuplicityProductError =
            esus_response.exception === "DuplicidadeMunicipioProdutoException";
          const isDuplicityError =
            esus_response.dados === "NovoPrincipioAtivoProdutoException";

          if (isDuplicityProductError || isDuplicityError) {
            if (this.edition) {
              this.$toaster.error(
                `Já existe registro com essas informações:  ${this.form.activePrinciple} - ${this.form.typePresentationName}.`,
                " Não foi possível alterar o Produto",
              );
            } else {
              this.$toaster.error(
                `Já existe registro com essas informações:  ${this.form.activePrinciple} - ${this.form.typePresentationName}.`,
                " Não foi possível cadastrar o Produto",
              );
            }
          }
        } else {
          if (this.edition) {
            this.$toaster.error(
              this.$utils.sanitize.formatError(error.message || error),
              "Não foi possível alterar o produto",
            );
          } else {
            this.$toaster.error(
              this.$utils.sanitize.formatError(error.message || error),
              "Não foi possível cadastrar o produto",
            );
          }
        }
      } finally {
        this.$loader.finish();
      }
    },
    selectingMunicipality(pValue) {
      const source = pValue.source;
      if (source) {
        this.form.cityId = source && source.mun_id;
      } else {
        this.form.cityId = null;
      }
    },
    validateEspecialCharacter(pValue, pErrors) {
      const hasSpecial = /[!$%^&*()@_^#¨|´~=`{}[\]:";'~<>?\\]/.test(pValue);

      if (hasSpecial) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: barra, ponto, traço, vírgula e símbolo de soma",
        );
        return false;
      }

      return true;
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "city",
        "code",
        "typePresentation",
        "activePrinciple",
        "productType",
        "medicineAB",
        "commercialName",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cleanForm() {
      if (this.edition) {
        this.form.code = null;
        this.form.typePresentation = null;
        this.form.productType = null;
        this.form.medicineAB = null;
      } else {
        this.form = this.$utils.obj.DeepCopy(FORM);
      }
      this.mountedInfo();
      this.cleanFieldsValidation();
    },
    close() {
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.cleanFieldsValidation();
      this.$emit("close");
    },
  },
};
</script>
