<template>
  <Modulebox
    ref="modulebox"
    title="Visualizar Registros de BPA-I"
    class="bpa-i-comparative-mode"
  >
    <div slot="info" class="header-modulebox-bpai">
      <RadioComparative
        :result-callback="selectComparativeMode"
        :start-active="true"
      />
    </div>

    <FormBase title="Atendimentos Registrados na folha">
      <div class="body-comparative-mode">
        <div>
          <Tooltip
            message='Selecione um dos registros na listagem abaixo para visualizar as informações na seção "Detalhes de atendimentos"'
            startOpen
            class="tooltip"
          >
            <IconLightBulbHelper slot="icon" />
          </Tooltip>

          <section class="bpa-i-search">
            <RgValidatorForm ref="validator" class="bpa-i-search-content">
              <div class="bpa-i-form-search">
                <RgInput
                  ref="cnes"
                  v-model="formSearch.cnes"
                  label="CNES"
                  :rules="{ required: true }"
                  :disabled="true"
                  :class="{ disable: true }"
                />
                <RgSelectCompetence
                  ref="competence"
                  v-model="formSearch.competence"
                  :rules="{ required: true }"
                />
                <RgSuggestEmployee
                  ref="employee"
                  v-model="formSearch.employee.nameAndCns"
                  label="CNS e Nome do Profissional"
                  placeholder="Digite o CNS ou Nome do Profissional"
                  :rules="{ required: true }"
                  :unitHealthId="formSearch.unitHealthId"
                  @selected="selectEmployee"
                />
                <RgSuggestPatient
                  ref="patientName"
                  v-model="formSearch.patient.nameAndCns"
                  label="CNS e Nome do Paciente"
                  placeholder="Digite o nº do CNS ou nome do Paciente"
                  filterByCnsOrName
                  filterByCompetence
                  @selected="selectPatientName"
                />
                <RgSuggestCbo
                  ref="occupation"
                  v-model="formSearch.occupation"
                  label="CBO/Ocupação"
                  :employeeId="formSearch.employee.professionalId"
                  :unitHealthId="formSearch.unitHealthId"
                  :rules="{ required: true }"
                  :disabled="!formSearch.employee.nameAndCns"
                  :class="{ disable: !formSearch.employee.nameAndCns }"
                  placeholder="Digite o CBO do profissional"
                  @selected="selectCbo"
                />
                <RgSelectSheet
                  ref="sheet"
                  v-model="formSearch.sheet"
                  :rules="{ required: true }"
                  :filterRegister="{
                    cnes: formSearch.cnes,
                    cns: formSearch.employee.cns,
                    cbo: formSearch.ocp_codigo,
                    competence: formSearch.competence,
                  }"
                  :disabled="
                    !formSearch.occupation || !formSearch.employee.nameAndCns
                  "
                  :class="{
                    disable:
                      !formSearch.occupation || !formSearch.employee.nameAndCns,
                  }"
                />
              </div>
              <div class="bpa-i-form-search-actions">
                <RgCleanButton @click="cleanFormSearch" />
                <RgSearchButton ref="searchBtn" @submit="searchBPAI" />
              </div>
            </RgValidatorForm>
          </section>
          <section v-if="listRegisterBPAI.length === 0" class="no-result">
            <IconEmpty />
            <span class="info">
              FAÇA UMA BUSCA PARA EXIBIR OS RESULTADOS AQUI
            </span>
          </section>

          <section v-else class="bpa-i-table">
            <SmartTable
              ref="smartTable"
              name="BpaI"
              :columns="COLUMNS"
              :body="listRegisterBPAI"
              :initial-columns="10"
              :total="listRegisterBPAI.length"
              :showPagination="false"
              toggleSelected
              removeBtnColumns
              class="smart-table"
              @getLine="selectLine"
            >
            </SmartTable>
            <p class="register-count">
              <strong>
                Registros por folha: {{ listRegisterBPAI.length }}/99
              </strong>
            </p>
          </section>
        </div>
        <hr class="separator" />

        <section v-if="hasSelectedLine" class="bpa-i-details">
          <RgRadioCustom
            id="status"
            v-bind="propsRadio"
            :canUnselect="false"
            class="radio-custom-comparative"
            @input="onInputRadioCustom"
          />

          <div>
            <Tooltip
              :message="`CNES: ${cnes} Competência: ${competence} Folha: ${formSearch.sheet}`"
              startOpen
              class="tooltip"
            >
              <IconLightBulbHelper slot="icon" />
            </Tooltip>

            <div
              v-if="showGroup(LIST_GROUP.professional)"
              class="result-details"
            >
              <span>
                CNS do Profissional:
                <strong> {{ formatCns(selectedRow.pdf_cns) }} </strong>
              </span>
              <span>
                Nome do Profissional:
                <strong>
                  {{ getValue(selectedRow, "pdf_nome_profissional") }}
                </strong>
              </span>
              <span>
                CBO:
                <strong> {{ getValue(selectedRow, "pdf_cod_cbo") }} </strong>
              </span>
            </div>

            <div v-if="showGroup(LIST_GROUP.patient)" class="result-details">
              <span>
                CNS do Paciente:
                <strong>
                  {{ formatCns(selectedRow.bof_cns) }}
                </strong>
              </span>
              <span>
                Nome:
                <strong> {{ getValue(selectedRow, "bof_nome") }} </strong>
              </span>
              <span>
                Sexo:
                <strong> {{ formatGender(selectedRow.bof_sexo) }} </strong>
              </span>
              <span>
                Data de Nascimento:
                <strong>
                  {{ getValue(selectedRow, "data_nascimento") }}
                </strong>
              </span>
              <span>
                Raça/Cor:
                <strong>
                  {{ getValue(selectedRow, "raca") }}
                </strong>
              </span>
              <span>
                Etnia:
                <strong>
                  {{ getValue(selectedRow, "etnia") }}
                </strong>
              </span>

              <span>
                Nacionalidade:
                <strong>
                  {{ getValue(selectedRow, "nacionalidade") }}
                </strong>
              </span>
              <span>
                CEP: <strong> {{ formatCep(selectedRow.bof_cep) }} </strong>
              </span>
              <span>
                Logradouro:
                <strong>
                  {{ getValue(selectedRow, "logradouro") }}
                </strong>
              </span>
              <span>
                Número:
                <strong>
                  {{ getValue(selectedRow, "bof_endereco_numero") }}
                </strong>
              </span>
              <span>
                Complemento:
                <strong>
                  {{ getValue(selectedRow, "bof_endereco_complemento") }}
                </strong>
              </span>
              <span>
                Bairro:
                <strong> {{ getValue(selectedRow, "bof_bairro") }} </strong>
              </span>
              <span>
                Cidade:
                <strong>
                  {{ getValue(selectedRow, "municipio") }}
                </strong>
              </span>

              <span>
                UF:
                <strong>
                  {{ getValue(selectedRow, "est_sigla") }}
                </strong>
              </span>
              <span>
                Telefone:
                <strong>
                  {{ formatPhone(selectedRow) }}
                </strong>
              </span>
              <span>
                E-mail:
                <strong>
                  {{ getValue(selectedRow, "bof_email") }}
                </strong>
              </span>
            </div>

            <div v-if="showGroup(LIST_GROUP.procedure)" class="result-details">
              <span>
                Sequencia:
                <strong>
                  {{ getValue(selectedRow, "sequence") }}
                </strong>
              </span>
              <span>
                Data do Atendimento:
                <strong>
                  {{ getValue(selectedRow, "data_atendimento") }}
                </strong>
              </span>
              <span>
                Procedimento:
                <strong> {{ getValue(selectedRow, "procedimento") }} </strong>
              </span>
              <span>
                CNPJ:
                <strong> {{ getValue(selectedRow, "pdf_cnpj") }} </strong>
              </span>
              <span>
                Serviço:
                <strong> {{ getValue(selectedRow, "servico") }} </strong>
              </span>
              <span>
                Classificação:
                <strong> {{ getValue(selectedRow, "classificacao") }} </strong>
              </span>
              <span>
                CID:
                <strong> {{ getValue(selectedRow, "cid") }} </strong>
              </span>
              <span>
                Caráter de Atendimento:
                <strong>
                  {{ getValue(selectedRow, "ica_nome") }}
                </strong>
              </span>
              <span>
                Número da Autorização:
                <strong>
                  {{ getValue(selectedRow, "pdf_num_autorizacao") }}
                </strong>
              </span>
            </div>
          </div>
        </section>

        <section v-else class="bpa-i-not-details">
          <IconInfoHelper slot="icon" :background="'#6f8894'" />
          <span class="message">DETALHES DO ATENDIMENTO</span>
        </section>
      </div>
    </FormBase>
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  Tooltip,
  RgInput,
  IconInfoHelper,
  IconLightBulbHelper,
  RgRadioCustom,
  RgCleanButton,
  RgSearchButton,
  IconEmpty,
} from "~tokio/primitive";

import {
  RgSuggestEmployee,
  RgSelectCompetence,
  RgSuggestCbo,
  RgSuggestPatient,
} from "$billing/common/components/index";

import { mapGetters } from "vuex";
import { Modulebox } from "~tokio/foundation";
import { RadioComparative } from "$billing/submodules/bpa/bpa-i/components";
import RgSelectSheet from "$billing/submodules/bpa/bpa-i/components/select/rg-select-sheet/RgSelectSheet";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

const GROUP_DETAILS = {
  professional: 1,
  patient: 2,
  procedure: 3,
};

export default {
  name: "ComparativeMode",
  components: {
    Modulebox,
    FormBase,
    SmartTable,
    RgValidatorForm,
    Tooltip,
    IconInfoHelper,
    IconLightBulbHelper,
    RadioComparative,
    RgRadioCustom,
    RgCleanButton,
    RgSearchButton,
    RgInput,
    RgSelectSheet,
    RgSelectCompetence,
    RgSuggestCbo,
    RgSuggestEmployee,
    IconEmpty,
    RgSuggestPatient,
  },

  data() {
    return {
      listRegisterBPAI: [],
      selectedRow: null,
      selectGroupInfo: GROUP_DETAILS.professional,
      formSearch: {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        cnes: this.$store.getters["Login/GET_UNIT_HEALTH_CNES"],
        competence: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
        employee: {
          professionalId: null,
          nameAndCns: null,
          cns: null,
          professionalName: null,
          vin_id: null,
          crs_numero: null,
        },
        patient: {
          name: null,
          cns: null,
          pac_id: null,
          nameAndCns: null,
        },
        sheet: null,
        occupation: null,
        ocp_id: null,
        ocp_nome: null,
        ocp_codigo: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      cnes: "Login/GET_UNIT_HEALTH_CNES",
      competence: "Billing/BDSia/GET_FORMATTED_PERIOD_DATE",
    }),
    propsRadio() {
      const list = [
        { title: "Profissional", blue: true, id: GROUP_DETAILS.professional },
        { title: "Paciente", blue: true, id: GROUP_DETAILS.patient },
        { title: "Procedimento", blue: true, id: GROUP_DETAILS.procedure },
      ];
      const value = this.selectGroupInfo;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    hasSelectedLine() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },
    filterIsComplete() {
      return !!(
        this.formSearch.cnes &&
        this.formSearch.competence &&
        this.formSearch.employee.nameAndCns &&
        this.formSearch.occupation &&
        this.formSearch.sheet
      );
    },
  },
  watch: {
    "formSearch.sheet"(pValue) {
      if (!pValue) {
        this.cleanList();
      }
    },
    "formSearch.occupation"(pValue) {
      if (!pValue) {
        this.formSearch.sheet = null;
        this.cleanList();
      }
    },
    "formSearch.employee.nameAndCns"(pValue) {
      if (!pValue) {
        this.formSearch.occupation = null;
        this.formSearch.sheet = null;
        this.cleanList();
      }
    },
    "formSearch.patient.nameAndCns"(pValue) {
      if (!pValue) {
        this.formSearch.patient.name = null;
        this.formSearch.patient.cns = null;
        this.formSearch.patient.pac_id = null;
        this.formSearch.patient.nameAndCns = null;
      }
    },
  },

  mounted() {
    this.fillFilters();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/billing/bpa/bpa-i",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Billing/BPAI/RESET_FILTER_BILLING_BPAI");
      this.$store.commit("Billing/BPAI/CLEAN_REGISTER_BILLING_BPAI");
    }
  },

  created() {
    this.COLUMNS = [
      {
        name: "Competência",
        key: "bof_competencia",
        align: "right",
        active: "true",
      },
      { name: "Folha", key: "pdf_folha", align: "right", active: "true" },
      { name: "Sequência", key: "sequence", align: "right", active: "true" },
      { name: "CNS", key: "bof_cns", align: "right", active: "true" },
      { name: "Paciente", key: "bof_nome", align: "left", active: "true" },
      {
        name: "Nascimento",
        key: "data_nascimento",
        align: "left",
        active: "true",
      },
      { name: "Sexo", key: "bof_sexo", align: "left", active: "true" },
      {
        name: "Município",
        key: "bof_cod_municipio",
        align: "right",
        active: "true",
      },
      {
        name: "Atendimento",
        key: "data_atendimento",
        align: "left",
        active: "true",
      },
      {
        name: "Procedimento",
        key: "pdf_cod_procedimento",
        align: "right",
        active: "true",
      },
      {
        name: "Quantidade",
        key: "pdf_quantidade",
        align: "right",
        active: "true",
      },
      { name: "CID", key: "pdf_cid", align: "left", active: "true" },
      {
        name: "Caráter de Atend.",
        key: "pdf_id_internacoes_caracteres",
        align: "right",
        active: "true",
      },
      {
        name: "Autorização",
        key: "pdf_num_autorizacao",
        align: "left",
        active: "true",
      },
      { name: "Raça/Cor", key: "bof_cod_raca", align: "right", active: "true" },
      {
        name: "Etnia",
        key: "bof_cod_etnia",
        align: "right",
        active: "true",
      },
      {
        name: "Nacionalidade",
        key: "bof_cod_nacionalidade",
        align: "right",
        active: "true",
      },
      {
        name: "Serviço",
        key: "pdf_servico",
        align: "right",
        active: "true",
      },
      {
        name: "Classificação",
        key: "pdf_classificacao",
        align: "left",
        active: "true",
      },
      { name: "CEP", key: "bof_cep", align: "right", active: "true" },
      {
        name: "Logradouro",
        key: "bof_cod_tipo_logradouro",
        align: "right",
        active: "true",
      },
      { name: "Endereço", key: "bof_endereco", align: "left", active: "true" },
      {
        name: "Complemento",
        key: "bof_endereco_complemento",
        align: "left",
        active: "true",
      },
      {
        name: "Número",
        key: "bof_endereco_numero",
        align: "right",
        active: "true",
      },
      { name: "Bairro", key: "bof_bairro", align: "left", active: "true" },
      { name: "DDD", key: "bof_tel_ddd", align: "right", active: "true" },
      {
        name: "Telefone",
        key: "bof_tel",
        align: "right",
        active: "true",
      },
      { name: "E-mail", key: "bof_email", align: "left", active: "true" },
    ];

    this.LIST_GROUP = GROUP_DETAILS;
  },
  methods: {
    cleanSelectRow() {
      this.selectedRow = {};
      this.selectGroupInfo = GROUP_DETAILS.professional;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    async validateFormSearch() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async searchBPAI() {
      try {
        this.cleanSelectRow();

        this.$loader.start();

        if (!this.filterIsComplete) {
          const validate = await this.validateFormSearch();

          if (!validate) {
            this.$toaster.warning("Verifique os campos");
            return false;
          }
        }

        let patientName;
        let patientCns;

        const format = /[-]+/;
        const validate = format.test(this.formSearch.patient.nameAndCns);

        if (!validate) {
          if (isNaN(this.formSearch.patient.nameAndCns)) {
            patientName = this.formSearch.patient.nameAndCns;
            this.formSearch.patient.name = patientName;
          } else {
            patientCns = this.formSearch.patient.nameAndCns;
            this.formSearch.patient.cns = patientCns;
          }
        }

        const variables = {
          cnes: this.formSearch.cnes,
          professionalCns: this.formSearch.employee.cns,
          patientName: this.formSearch.patient.name || patientName || null,
          patientCns: this.formSearch.patient.cns || patientCns || null,
          cboCode: String(this.formSearch.ocp_codigo),
          competence: this.formSearch.competence,
          sheet: this.formSearch.sheet,
        };

        const result = await this.$store.dispatch(
          "Billing/BPAI/SEARCH_BPAI",
          variables,
        );

        this.listRegisterBPAI = result.rows;

        this.$store.commit(
          "Billing/BPAI/SET_FILTER_BILLING_BPAI",
          this.formSearch,
        );
      } catch (pErr) {
        this.$toaster.error("Erro ao buscar BPAI");
      } finally {
        if (this.$refs.searchBtn) {
          this.$refs.searchBtn.actionSubmit();
          this.$refs.searchBtn.actionDone();
        }
        this.$loader.finish();
      }
    },
    selectComparativeMode(pActive) {
      if (!pActive) {
        this.$router.push({ name: "billing.bpa.bpa-i" });
      }
    },
    showGroup(pGroupId) {
      return this.selectGroupInfo === pGroupId;
    },
    onInputRadioCustom(item) {
      if (item) {
        this.selectGroupInfo = item.id;
      }
    },
    getValue(pItem, pKey) {
      const value = pItem && pItem[pKey] ? pItem[pKey] : "NÃO INFORMADO";
      return value;
    },
    selectEmployee(pValue) {
      if (pValue.source) {
        this.formSearch.employee.cns =
          pValue.source.cns || pValue.source.crs_numero;
        this.formSearch.employee.professionalName = pValue.source.pes_nome;
        this.formSearch.employee.professionalId = pValue.source.fun_id;
        this.formSearch.employee.vin_id = pValue.source.vin_id;
        this.formSearch.employee.crs_numero = pValue.source.crs_numero;
      } else {
        this.formSearch.employee.cns = null;
        this.formSearch.employee.professionalName = null;
        this.formSearch.employee.professionalId = null;
        this.formSearch.employee.vin_id = null;
        this.formSearch.employee.crs_numero = null;
      }
    },
    selectPatientName(pValue) {
      const data =
        pValue && Object.keys(pValue).length > 0 ? pValue.source : null;

      if (data) {
        const cns =
          data.cns && data.cns.crs_numero ? data.cns.crs_numero : null;

        this.formSearch.patient.name = data.pes_nome;
        this.formSearch.patient.cns = cns;
        this.formSearch.patient.pac_id = data?.patient?.pac_id;
        this.formSearch.patient.nameAndCns = cns + " - " + data.pes_nome;
      } else {
        this.formSearch.patient.pac_id = null;
      }
    },
    selectCbo(pValue) {
      if (pValue) {
        this.formSearch.ocp_id = pValue.source?.ocp_id;
        this.formSearch.ocp_codigo = pValue.source?.ocp_codigo;
        this.formSearch.ocp_nome = pValue.source?.ocp_nome;
      } else {
        this.formSearch.ocp_id = null;
        this.formSearch.ocp_codigo = null;
        this.formSearch.ocp_nome = null;
      }
      // this.formSearch.cboCode = pValue.source?.ocp_codigo;
    },
    formatCns(pCns) {
      return (
        pCns?.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4") ||
        "NÃO INFORMADO"
      );
    },
    formatGender(pGender) {
      if (pGender) {
        return pGender === "M" ? "MASCULINO" : "FEMININO";
      } else {
        return "NÃO INFORMADO";
      }
    },
    formatCep(pCep) {
      if (pCep) {
        const cep = pCep.replace(/[^\d]/g, "");
        return cep.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
      } else {
        return "NÃO INFORMADO";
      }
    },
    formatPhone(pSelectedRow) {
      const hasDdd =
        pSelectedRow &&
        pSelectedRow.bof_tel_ddd &&
        pSelectedRow.bof_tel_ddd.length > 0;

      const hasPhone =
        pSelectedRow && pSelectedRow.bof_tel && pSelectedRow.bof_tel.length > 0;

      if (hasDdd && hasPhone) {
        let formatedPhone = pSelectedRow.bof_tel;

        formatedPhone = formatedPhone.replace(/(\d)(\d{4})$/, "$1-$2");

        return "(" + pSelectedRow.bof_tel_ddd + ")" + " " + formatedPhone;
      } else {
        return "NÃO INFORMADO";
      }
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "cnes",
        "competence",
        "employee",
        "sheet",
        "occupation",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cleanList() {
      this.listRegisterBPAI = [];
      this.selectedRow = {};
      this.selectGroupInfo = GROUP_DETAILS.professional;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
      this.cleanFieldsValidation();
    },
    cleanFormSearch() {
      this.formSearch = {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        cnes: this.$store.getters["Login/GET_UNIT_HEALTH_CNES"],
        competence: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
        employee: {
          professionalId: null,
          nameAndCns: null,
          cns: null,
          professionalName: null,
          vin_id: null,
          crs_numero: null,
        },
        patient: {
          name: null,
          cns: null,
          pac_id: null,
          nameAndCns: null,
        },
        sheet: null,
        occupation: null,
        ocp_id: null,
        ocp_nome: null,
        ocp_codigo: null,
      };
      this.cleanList();
      this.cleanFieldsValidation();
      this.$store.commit("Billing/BPAI/RESET_FILTER_BILLING_BPAI");
      this.$store.commit("Billing/BPAI/CLEAN_REGISTER_BILLING_BPAI");
    },
    selectLine(item) {
      this.selectedRow = item;
    },
    async fillFilters() {
      try {
        this.$loader.start();

        const filterData = this.$store.getters[
          "Billing/BPAI/GET_FILTER_BILLING_BPAI"
        ];

        if (filterData && Object.keys(filterData).length > 0) {
          if (filterData.employee) {
            this.$refs.employee.forceSelection({
              pes_id: filterData.employee.employeePersonId,
              pes_nome: filterData.employee.professionalName,
              vin_id: filterData.employee.vin_id,
              fun_id: filterData.employee.professionalId,
              person: {
                cns: {
                  crs_numero: filterData.employee.cns,
                },
              },
            });
          }

          if (filterData.patient) {
            if (filterData.patient.pac_id) {
              this.$refs.patientName.forceSelection({
                pes_nome: filterData.patient.name,
                patient: {
                  pac_id: filterData.patient.pac_id,
                },
                cns: { crs_numero: filterData.patient.cns },
              });
            } else {
              let patientName;
              let patientCns;

              this.$refs.patientName.inputValue = filterData.patient.nameAndCns;

              const format = /[-]+/;
              const validate = format.test(this.formSearch.patient.nameAndCns);

              if (!validate) {
                if (isNaN(this.formSearch.patient.nameAndCns)) {
                  patientName = this.formSearch.patient.nameAndCns;
                  this.formSearch.patient.name = patientName;
                } else {
                  patientCns = this.formSearch.patient.nameAndCns;
                  this.formSearch.patient.cns = patientCns;
                }
              }

              this.formSearch.patient.name = filterData.patient.name || null;
              this.formSearch.patient.cns = filterData.patient.cns || null;
            }
          }
          this.$refs.occupation.forceSelection({
            ocp_id: Number(filterData.ocp_id),
            ocp_nome: filterData.ocp_nome,
            ocp_codigo: filterData.ocp_codigo,
          });
          this.formSearch.employee.nameAndCns = filterData.employee.nameAndCns;
          this.formSearch.occupation = filterData.occupation;
          this.formSearch.employee.cns = filterData.employee.cns;
          this.formSearch.sheet = filterData.sheet;

          if (this.filterIsComplete) {
            this.searchBPAI();
          }
        }
      } catch (pErr) {
        this.$toaster.warning("Não foi possível preencher os filtros.");
      } finally {
        this.$loader.finish();
      }
    },
  },
};
</script>
