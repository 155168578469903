<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchMedicineAB from "./action/SearchMedicineAB";

export default {
  name: "RgSelectMedicineAB",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Medicamento AB",
    },
  },
  data: () => {
    return {
      medicineAB: [],
    };
  },
  watch: {
    medicineAB: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.medicineAB;
    },
    async doSearch() {
      this.medicineAB = [];

      const data = await SearchMedicineAB();

      this.medicineAB = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
          mec_nome: item.mec_nome,
        };
      });
    },
  },
};
</script>
