import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/hospitalization/queue/edit-insert/edit-patient/patient-care-history",
  name: "hospitalization.queue.edit-insert.edit-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Regulação", link: OpenSubModule("hospitalization") },
      { label: "Fila de Regulação", link: "/hospitalization/queue" },
      {
        label: "Editar Fila",
        link: "/hospitalization/queue/edit-insert",
      },
      {
        label: "Editar Paciente",
        link: "/hospitalization/queue/edit-insert/edit-patient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
