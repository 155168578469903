<style src="./ExamByDayReport.scss" lang="scss" scoped></style>

<template>
  <div class="exam-by-day-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-adr"
          v-model="form.uns_id"
          class="inputitem"
          @change="selectingUnitHealth"
        />
      </div>
      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-edr"
          ref="initialDate"
          v-model="form.initialDate"
          :max-date="getMaxDateInitial"
          :rules="{ required: true }"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="final-date-edr"
          ref="finalDate"
          v-model="form.finalDate"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          :rules="{ required: true }"
          label="Data Final"
          class="inputitem mg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgSelectUnithealth } from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  uns_nome: "",
  initialDate: moment().add(-31, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  orderByLabel: "Agendamento",
};

export default {
  name: "ExamByDayReport",
  components: {
    RgInputDate,
    RgSelectUnithealth,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Relatório Diário de Exames",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      document: null,
      disabledDocument: false,
      setColumn: "",
    };
  },
  computed: {
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(31, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
    getNameSmartTable() {
      return "ExamByDayReport";
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { width: 100px}" +
        ".report-column-2 { white-space: normal !important; text-align: left; width: 150px}" +
        ".report-column-4 { white-space: normal !important }" +
        ".report-column-5 { width: 100px}" +
        ".report-column-6 { white-space: normal !important; text-align: left; width: 400px}" +
        ".report-column-7 { white-space: normal !important; text-align: left; width: 150px}" +
        ".report-column-8 { white-space: normal !important; text-align: left; width: 150px}" +
        ".report-column-9 { white-space: normal !important; text-align: left; width: 150px}" +
        ".report-column-10 { white-space: normal !important; text-align: left; width: 100px}"
      );
    },
    columnTable() {
      return this.setColumn;
    },
  },
  watch: {
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(31, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  mounted() {},
  created() {
    this.COLUMNS = [
      { name: "Agendamento", key: "AGENDAMENTO" },
      { name: "Profissional", key: "PROFISSIONAL", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      {
        name: "Subprocedimento",
        key: "SUB_PROCEDIMENTO",
        align: "left",
        concat: ",",
      },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Idade", key: "IDADE" },
      { name: "CNS", key: "CNS" },
      { name: "Prontuário da Unidade", key: "PRONTUARIO_UNIDADE" },
      { name: "Telefones", key: "TELEFONES", align: "left" },
      { name: "Endereço", key: "ENDERECO", align: "left" },
      { name: "Bairro", key: "BAIRRO", align: "left" },
      { name: "Cidade", key: "CIDADE", align: "left" },
      { name: "Nome da Mãe", key: "RESPONSAVEL_PACIENTE", align: "left" },
    ];

    this.COLUMNS_WITHOUT_RECORD = this.COLUMNS.filter((column) => {
      return column.key !== "PRONTUARIO_UNIDADE";
    });
  },
  methods: {
    buildFilter() {
      const payLoad = {
        uns_id: this.form.uns_id,
        uns_nome: this.form.uns_nome,
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        fromQueue: this.form.fromQueue,
        orderByLabel: this.form.orderByLabel,
        defaultPrintTable: false,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },
    async doSearch(pData) {
      try {
        this.setColumn = this.form.uns_id
          ? this.COLUMNS
          : this.COLUMNS_WITHOUT_RECORD;

        return await this.$store.dispatch(
          "Exam/Report/GET_EXAM_BY_DAY_REPORT",
          pData,
        );
      } catch (Err) {
        this.$toaster.error(Err, "Erro ao gerar o relatorio:");
      }
    },
    selectingUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.uns_nome = pValue.label;
      } else {
        this.form.uns_nome = "";
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
