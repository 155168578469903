<style src="./RefreshLogin.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="rg-login">
    <div class="rg-login--container">
      <div class="rg-login--box">
        <div class="rg-login--inner">
          <div class="rg-login--header-brand">
            <img class="" src="/static/icons/logo.svg" alt="RG Esus" />
          </div>

          <form class="login" @submit.prevent="login" @keydown.enter="login">
            <RgValidatorForm ref="validator">
              <RgInput
                id="clienteRefresh"
                v-model="loginForm.client"
                :rules="{ required: true }"
                label="Cliente"
                class="login--fields"
                tab-index="2"
                :isUpperCase="false"
                :disabled="true"
                :class="{ disable: true }"
              />
              <RgInput
                id="usuarioRefresh"
                v-model="loginForm.login"
                :rules="{ required: true }"
                label="Usuário"
                class="login--fields"
                tab-index="2"
                :isUpperCase="false"
                :disabled="true"
                :class="{ disable: true }"
              />
              <RgInputPassword
                id="senhaRefresh"
                v-model="loginForm.password"
                :rules="{ required: true }"
                :eye-style="{ 'margin-right': '4px' }"
                label="Senha"
                class="login--fields"
                tab-index="3"
                :isUpperCase="false"
              />

              <!-- <div v-if="isDeveloping" class="dev-config">
                <RgToggleButton
                  marginAutoLeft
                  :value="useLegacyBackend"
                  description="Utilizar Servidor Legado :"
                  @change="changeUseLegacyBackend"
                />
              </div> -->

              <div class="status">
                <div class="status-servidores">
                  Status do Servidor :
                  <span
                    title="Servidor do Esus"
                    class="span left"
                    @click="checkStatus('backendStatus')"
                  >
                    <Component :is="backendStatus" class="icon-server" />
                  </span>
                  <span
                    title="Servidor do Esus Legado"
                    class="span"
                    @click="checkStatus('backendLegacyStatus')"
                  >
                    <Component :is="backendLegacyStatus" class="icon-server" />
                  </span>
                </div>
              </div>

              <div class="rg-input--command">
                <RgCleanButton
                  id="CancelarRefresh"
                  medium
                  type="button"
                  class="clean unselect"
                  title="Cancelar"
                  @click="cancel"
                />

                <RgSubmitButton
                  id="acessarRefresh"
                  ref="btnSubmit"
                  v-shortkey="['enter']"
                  label="Acessar"
                  class="unselect"
                  wait-label="Efetuando login"
                  @submit="login"
                />
              </div>
            </RgValidatorForm>
          </form>
          <div class="footer-logo">
            <!-- <img
              src="/static/images/logo-esus+-sistemas.png"
              alt="RG Sistema"
            /> -->
          </div>

          <div class="footer">Versão do sistema: {{ version }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  RgInput,
  IconAnimCheck,
  IconAnimExclamation,
  RgToggleButton,
  RgInputPassword,
  RgValidatorForm,
  RgSubmitButton,
  RgCleanButton,
} from "~tokio/primitive/";

import Request from "~common/request/";
import { LEGACY_SERVER_CONFIG, SERVER_CONFIG } from "~utils/Config";

export default {
  name: "RgRefreshLogin",
  components: {
    RgSubmitButton,
    RgInput,
    RgInputPassword,
    RgValidatorForm,
    RgCleanButton,
    RgToggleButton,
    IconAnimCheck,
    IconAnimExclamation,
  },
  data() {
    return {
      backendStatus: "IconAnimExclamation",
      backendLegacyStatus: "IconAnimExclamation",
      loginForm: {
        client: "",
        login: "",
        password: "",
      },
      attempts: 3,
      unitHealth: {},
    };
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    lastLogin() {
      return this.$store.getters["Login/GET_LAST_LOGIN_INFO"];
    },
    isDeveloping() {
      return process.env.NODE_ENV === "development";
    },
    useLegacyBackend() {
      return this.$store.state.Login.useLegacyBackend;
    },
  },
  mounted() {
    this.loginForm.client = this.lastLogin.clientCode;
    this.loginForm.login = this.lastLogin.userLogin;
    this.checkStatus("backendStatus");
    this.checkStatus("backendLegacyStatus");
    this.loadFormLogin();

    const modalElement = document.querySelector(".rg-login");
    if (modalElement) {
      modalElement.addEventListener("keydown", this.handleKeyDownEnter);
    }
  },
  beforeDestroy() {
    const modalElement = document.querySelector(".rg-login");
    modalElement.removeEventListener("keydown", this.handleKeyDownEnter);
  },
  methods: {
    handleKeyDownEnter(event) {
      const isTab = event.key === "Tab";
      const isEnter = event.key === "Enter";
      if (isTab || isEnter) {
        event.preventDefault();
      }
    },

    login() {
      if (this.validateLogin()) {
        const loginCredentials = {
          client: this.loginForm.client,
          username: this.loginForm.login,
          password: this.loginForm.password,
          isModal: true,
        };
        this.unitHealth = Object.assign(
          {},
          this.$store.getters["Login/GET_UNIT_HEALTH"],
        );

        this.$store
          .dispatch("Login/LOGIN", loginCredentials)
          .then(() => {
            this.$refs.btnSubmit.actionDone();
            this.loginForm.password = "";
            document
              .querySelector("#refreshLoginFast")
              .setAttribute("style", "display: none");
            localStorage.setItem("refresh-token-store", "inactive");
            this.$loader.start();
            this.refreshPage();
          })
          .catch((pErr) => {
            this.attempts--;
            if (this.attempts === 0) {
              localStorage.setItem("refresh-token-store", "active");
              this.$toaster.info(
                `Você foi direcionado para tela inicial do sistema para entrar em uma nova sessão.`,
                "O limite de tentativas de login foi excedido",
              );
              this.cancel();
            } else {
              this.$refs.btnSubmit.fail();
              this.$toaster.error(
                `Você possui mais 0${this.attempts} tentativas para entrar novamente no sistema.`,
                "Falha ao realizar o login, usuário ou senha incorretos",
              );
            }
          });
      }
    },
    async cancel() {
      this.loginForm.password = "";
      document
        .querySelector("#refreshLoginFast")
        .setAttribute("style", "display: none");
      localStorage.setItem("refresh-token-store", "inactive");
      await this.$store.dispatch("Login/LOGOFF");
      this.$router.push({ name: "Login" });
    },
    checkStatus(pType) {
      let config = null;
      if (pType === "backendLegacyStatus") {
        config = LEGACY_SERVER_CONFIG;
      } else if (pType === "backendStatus") {
        config = SERVER_CONFIG;
      }
      if (!config) {
        this[pType] = "IconAnimExclamation";
      }
      const request = new Request(config);
      request.get("/").then((result) => {
        if (result.status === 200) {
          this[pType] = "IconAnimCheck";
        } else {
          this[pType] = "IconAnimExclamation";
        }
      });
    },
    changeUseLegacyBackend(v) {
      this.$store.commit("Login/SET_USE_LEGACY_BACKEND", v);
    },
    validateLogin() {
      return this.$refs.validator.validate();
    },

    loadFormLogin() {
      const observer = new MutationObserver(() => {
        if (document.getElementById("refreshLoginFast")) {
          // removendo bug do enter & tab ao abrir a modal de refresh
          setTimeout(() => {
            this.loginForm.client = this.lastLogin.clientCode;
            this.loginForm.login = this.lastLogin.userLogin;

            document.getElementById("senhaRefresh").focus();
            this.loginForm.password = " ";
            this.loginForm.password = "";
          }, 100);
        }
      });
      observer.observe(document.querySelector("#refreshLoginFast"), {
        attributes: true,
      });
    },

    refreshPage() {
      this.$store.commit("Login/DEFINE_UNIT_HEALTH", this.unitHealth);
      this.$store.commit("Login/RESET_ALL_PERMISSIONS_TO_RELOAD");
      this.$store.commit("Login/RESET_ALL_PREFERENCES_TO_RELOAD");

      this.$store.dispatch("Login/LOAD_PERMISSIONS_BY_UNIT");

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    },
  },
};
</script>
