import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleExclamation,
  faRotate,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

export default (instanceVue) => {
  library.add(faCircleCheck);
  library.add(faCircleExclamation);
  library.add(faRotate);
  library.add(faArrowUpRightFromSquare);

  instanceVue.component("font-awesome-icon", FontAwesomeIcon);
};
