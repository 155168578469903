import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchRequestedQueueExams(
    $list: [ProceduresCodeByPatientInputType]!
    $limit: Int
    $offset: Int
  ) {
    SearchRequestedQueueExams(list: $list, limit: $limit, offset: $offset) {
      count
      rows {
        id
        solicitante
        procedimento
        data_solicitacao
        codigo_procedimento
        paciente
        exe_id_pacientes
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  return data.SearchRequestedQueueExams;
};
