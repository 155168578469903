import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";
const arrayExceptions = {
  FuncionarioInabilitacaoDependenciaConsultaException: `Não foi possível inabilitar os profissionais`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/consultas/controller-marcacao-consulta/validar-inabilitacao",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
