<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import HealtPlan from "./action/HealthPlan";

export default {
  name: "RgSelectHealthPlanExam",
  extends: RgSelect,
  props: {
    variable: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: "Convênio",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    variable() {
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const data = await HealtPlan(this.variable);

      data.forEach((item) => {
        if (parseInt(item.totalProcVinculados) > 0) {
          this.valuesData.push({ value: item.data, label: item.pls_nome });
        }
      });

      if (data.length === 1) {
        this.$emit("selectOnlyItem", this.valuesData[0].value);
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
