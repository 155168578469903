<template lang="html">
  <div v-if="show" class="modal-see-transfer">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <!-- TODO: Verificar depois as telas que utilizam essa modal -->
      <div slot="header" class="modal-see-transfer-header">
        <span class="title">
          Dados da Transferência dos Produtos e Medicamentos
        </span>
      </div>

      <div class="modal-see-transfer-type">
        <div slot="body" class="modal-see-transfer-body">
          <FormBase title="Dados de Origem e Destino" class="form-base">
            <section class="grid">
              <span class="text-info">
                Unidade de Saúde Origem:
                <strong :title="transfer.unidade_origem">
                  {{ transfer.unidade_origem || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Farmácia Origem:
                <strong :title="transfer.origem">
                  {{ transfer.origem || "-" }}</strong
                >
              </span>

              <span class="text-info">
                Data:
                <strong :title="transfer.trs_data">
                  {{ transfer.trs_data || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Unidade de Saúde Destino:
                <strong :title="transfer.unidade_destino">
                  {{ transfer.unidade_destino || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Farmácia Destino:
                <strong :title="transfer.destino">
                  {{ transfer.destino || "-" }}</strong
                >
              </span>

              <span class="text-info">
                Número da Guia:
                <strong :title="transfer.trs_guia_remessa">
                  {{ transfer.trs_guia_remessa || "-" }}</strong
                >
              </span>
            </section>
          </FormBase>
          <FormBase title="Dados do Produto Transferido" class="form-base">
            <section class="grid-product">
              <span class="text-info">
                Código:
                <strong :title="transfer.mpd_codigo">
                  {{ transfer.mpd_codigo || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Nome:
                <strong :title="transfer.mpd_novo_principio_ativo">
                  {{ transfer.mpd_novo_principio_ativo || "-" }}</strong
                >
              </span>

              <span class="text-info">
                Apresentação:
                <strong :title="transfer.mtu_novo_nome">
                  {{ transfer.mtu_novo_nome || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Lote e Validade:
                <strong :title="transfer.lote">
                  {{ transfer.lote || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Usuário:
                <strong :title="transfer.usu_nome">
                  {{ transfer.usu_nome || "-" }}</strong
                >
              </span>
            </section>
          </FormBase>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";

export default {
  name: "ModalSeeTransfer",
  components: {
    RgBaseModal,

    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      transfer: {},
    };
  },
  computed: {},
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },

    selectedRow(pValue) {
      if (pValue && pValue !== null && pValue.situacao === "TRANSFERÊNCIA") {
        this.mountedFormTransfer(pValue);
      }
    },
  },
  created() {},
  mounted() {},

  methods: {
    mountedFormTransfer(pValue) {
      this.transfer = {
        unidade_origem: pValue.unidade_origem,
        origem: pValue.origem,
        unidade_destino: pValue.unidade_destino,
        destino: pValue.destino,
        trs_data: pValue.trs_data,
        trs_guia_remessa: pValue.trs_guia_remessa,
        mpd_codigo: pValue.mpd_codigo,
        mpd_novo_principio_ativo: pValue.mpd_novo_principio_ativo,
        mtu_novo_nome: pValue.mtu_novo_nome,
        lote: pValue.lote,
        usu_nome: pValue.usu_nome,
      };
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
