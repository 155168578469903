<template lang="html">
  <section
    :class="{ 'module-box--disable': disabled || !granted, reverse: reversed }"
    class="modulebox"
    @click="moduleBoxClick"
  >
    <TitleBar
      v-show="showTitle"
      :reversed="true"
      :caption="reversed ? '' : title"
      :percentual="percentual"
      :waiting-mode="waitingMode"
      :has-back-btn="hasBackBtn"
    >
      <div slot="info" class="modulebox--info">
        <slot name="info" />
      </div>

      <div slot="actions" class="modulebox--actions">
        <slot name="title" />

        <div
          v-if="canCollapse"
          :class="{ reverse: reversed }"
          class="btn-goback"
          @click="toggleCollapse"
        >
          <div>
            <IconArrowLeftPoint class="icon" data-id="esconder-mostrar" />
          </div>
        </div>
      </div>
    </TitleBar>

    <div
      v-show="!reversed"
      ref="myself"
      :class="{ 'add-padding': !noPadding }"
      class="modulebox--content medium-scroll"
    >
      <slot />
    </div>

    <div v-show="reversed" class="text-reversed"></div>

    <!-- <div :class="{ 'modulebox--cover-disabled': disabled || !granted }" /> -->

    <slot name="buttons-bottom" />

    <slot name="footer" />
  </section>
</template>

<script>
import TitleBar from "./title-bar/TitleBar";
import { IconArrowLeftPoint } from "~tokio/primitive";

export default {
  name: "Modulebox",
  components: {
    TitleBar,
    IconArrowLeftPoint,
  },
  props: {
    // Title to repassed to the internal component Title
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Granted verify if the user have permission into modules
    granted: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    // the percentual can be used like a status to how much that modulebox is completed
    // it is rendered on the top of the component
    percentual: {
      type: Number,
      default: 0,
    },
    waitingMode: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    changeReversed: {
      type: Boolean,
      default: false,
    },
    isRetractable: {
      type: Boolean,
      default: false,
    },
    showCloseOption: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    titleLoader: {
      type: String,
      default: "Aguarde...",
    },
    hasBackBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reversed: false,
      loading: false,
    };
  },
  computed: {
    canCollapse() {
      return this.granted && this.collapse;
    },
    disable_components() {
      return this.disabled || !this.granted;
    },
  },
  watch: {
    disable_components: function (pVal) {
      this.moduleBoxState(pVal);
    },
  },
  mounted() {
    this.moduleBoxState(this.disable_components);
  },
  methods: {
    moduleBoxState(pVal) {
      const mySelf = this.$refs.myself;

      const inputs = mySelf.getElementsByTagName("input");
      const button = mySelf.getElementsByTagName("button");
      const select = mySelf.getElementsByTagName("select");
      const radio = mySelf.getElementsByTagName("radio");
      const fieldset = mySelf.getElementsByTagName("fieldset");

      this.disableRange(pVal, inputs, "text");
      this.disableRange(pVal, button, "button");
      this.disableRange(pVal, select, "select");
      this.disableRange(pVal, radio, "radio");
      this.disableRange(pVal, fieldset, "fieldset");
    },
    // Logic to disable all components inside the modulebox when the disable
    // prop change
    disableRange(pStatus, pElm, pType = null) {
      for (var i = 0; i < pElm.length; i++) {
        if (
          (pType && pElm[i].type === pType) ||
          pElm[i].disabled === undefined
        ) {
          pElm[i].disabled = pStatus;
        }
      }
    },
    toggleCollapse(pMouseEvent, pToggleIfOpened = false) {
      if (!pToggleIfOpened || (pToggleIfOpened && !this.reversed)) {
        this.reversed = !this.reversed;
      }
    },
    moduleBoxClick(pValue) {
      this.$emit("moduleBoxClick", pValue);
    },
    getContentListHeight() {
      return this.$refs.myself.offsetHeight;
    },
    getContentListWidth() {
      return this.$refs.myself.offsetWidth;
    },
    sendCloseEvent() {
      this.$emit("closeEvent", true);
    },
    setScrollTop() {
      const elementContainer = this.$refs.myself;
      if (this.$refs.myself) {
        elementContainer.scrollTop = 0;
      }
    },
  },
};
</script>
