<template>
  <section class="foundation-disabled-periods">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :build-filter="generateFilter"
      :clearFunction="actionCleanForm"
      :max-register="40"
      :item-height="31"
      :search-function="actionSearch"
      :disabled="disabledByModal"
      resultTitle="Profissionais"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="foundation-disabled-periods-filter">
        <!-- FILTER AREA -->
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="unidade"
              v-model="form.uns_id"
              :permission="permissionScreen"
              :rules="{ required: true }"
              label="Unidade de Saúde"
              class="inputitem"
              disabled
            />
          </div>

          <div class="selectinput">
            <RgSelectSector
              id="setor"
              v-model="form.set_id"
              class="inputitem"
              label="Setor"
              default-text="Selecione um setor"
              :disabled="!hasUnitHealth || disabledByModal"
              :class="{ disable: !hasUnitHealth }"
              :unit-health="form.uns_id"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCboSector
              id="cbo"
              ref="Cbo"
              v-model="form.ocp_nome"
              :disabled="!hasSector || disabledByModal"
              :class="{ disable: !hasSector || disabledByModal }"
              :sectorId="Number(form.set_id)"
              activeProfessional
              label="Ocupação"
              placeholder="Digite o nome da ocupação"
              class="inputitem"
              @selected="selectedCbo"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              id="employee"
              ref="employee"
              v-model="form.pes_nome"
              :maxlength="100"
              :ocuppation-id="form.ocp_id"
              :sector="form.set_id"
              :vus-responsavel="false"
              :disabled="!hasSector || disabledByModal"
              :class="{ disable: !hasSector }"
              label="Profissional"
              placeholder="Digite o nome do profissional"
              class="inputitem"
              sectorRequired
              @selected="selectingEmployee"
            />
          </div>
        </div>
      </div>

      <div class="foundation-disabled-periods-search">
        <!-- SEARCH AREA -->

        <SmartTable
          ref="smartTable"
          :name="
            isAppointment
              ? 'AppointmentFoundationDisabledPeriods'
              : 'ExamFoundationDisabledPeriods'
          "
          :columns="COLUMN_TABLE"
          :body="mutableList"
          :dynamic-height="getSpaceTable"
          :total="Number(totalMutableList)"
          :initial-columns="4"
          :show-pagination="false"
          index-column="eag_id"
          has-check
          toggle-selected
          mult-select
          class="smart-table"
          remove-btn-columns
          @getMultLines="selectLine"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgShowButton
              id="show-disableds-act"
              title="Visualizar Inabilitados"
              :disabled="!onlyOneSelect || disabledByModal"
              large
              @click="showDisabledsAct"
            />
          </div>
        </SmartTable>
      </div>

      <div
        v-if="totalMutableList > 0"
        ref="footerSlot"
        slot="menu-bottom"
        class="footer-table"
      >
        <LargeButton
          id="turn-on-act"
          :disabled="!isSelect || disabledByModal"
          title="Habilitar Período"
          label="Habilitar Período"
          separator
          class="button"
          backgroundColor="#30AF58"
          @click="turnOnAct"
        >
          <IconOn slot="icon" />
        </LargeButton>

        <LargeButton
          id="turn-off-act"
          :disabled="!isSelect || disabledByModal"
          title="Inabilitar Período"
          label="Inabilitar Período"
          separator
          class="button"
          backgroundColor="#F96B70"
          @click="turnOffAct"
        >
          <IconOff slot="icon" />
        </LargeButton>
      </div>
    </RgSearch>

    <ModalEnableDisablePeriod
      id="modal-enable-disable-period"
      :show="showModalEnableDisablePeriod"
      :is-appointment="isAppointment"
      :type="type"
      :professional-data="selectedLine"
      :unit-health-id="form.uns_id"
      @close="closeModalEnableDisablePeriod"
      @reSearch="reSearchFilter"
      @error="handleErrorDisabledPeriod"
    />

    <ModalInformation
      id="modal-information"
      class="modal-information"
      v-bind="propsModalProfessionalAppointment"
      @close="closeModalProfessionalAppointment"
    >
      <div slot="body-content" class="content">
        <SmartTable
          :name="
            isAppointment
              ? 'AppointmentFoundationDisabledPeriodsProfessional'
              : 'ExamFoundationDisabledPeriodsProfessional'
          "
          :columns="PROFESSIONAL_COLUMN_TABLE"
          :body="professionalAppointmentList"
          :total="totalProfessionalAppointment"
          :initial-columns="4"
          :show-pagination="false"
          :removeBtnColumns="true"
          dontSelect
        />
      </div>
    </ModalInformation>

    <ModalDisabledPeriods
      id="modal-disabled-periods"
      :show="modalShowDisabledPeriods"
      :is-appointment="isAppointment"
      :professional-data="firstLine"
      @close="closeModalShow"
      @reSearch="reSearchFilter"
    />
  </section>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import { IconOn, IconOff } from "~tokio/primitive/icon";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ModalDisabledPeriods from "~tokio/primitive/modal/modal-disabled-periods/ModalDisabledPeriods.vue";
import ModalEnableDisablePeriod from "~tokio/primitive/modal/modal-enable-disable-period/ModalEnableDisablePeriod";
import ModalInformation from "~tokio/primitive/modal/modal-information/ModalInformation.vue";
import RgSuggestCboSector from "~tokio/primitive/suggest/rg-suggest-cbo-sector/RgSuggestCboSector.vue";
import RgSuggestEmployee from "~tokio/primitive/suggest/rg-suggest-employee/RgSuggestEmployee.vue";

import { LargeButton, RgShowButton } from "~tokio/primitive/button";
import { RgSelectUnithealth, RgSelectSector } from "~tokio/primitive";

export default {
  name: "FoundationDisabledPeriods",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgSearch,
    RgSuggestCboSector,
    RgSuggestEmployee,
    LargeButton,
    IconOn,
    IconOff,
    SmartTable,
    RgShowButton,
    ModalEnableDisablePeriod,
    ModalDisabledPeriods,
    ModalInformation,
  },

  props: {
    isAppointment: Boolean,
  },

  data() {
    return {
      type: null,
      modalShowDisabledPeriods: false,
      showModalEnableDisablePeriod: false,
      showModalProfessionalAppointment: false,
      totalProfessionalAppointment: 0,
      totalMutableList: 0,
      professionalAppointmentList: [],
      mutableList: [],
      selectedLine: [],
      tableHeight: null,
      form: {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        set_id: 0,
        ocp_id: 0,
        ocp_nome: "",
        ocp_codigo: "",
        pes_nome: "",
        vin_id: 0,
        pes_id: 0,
      },
    };
  },

  computed: {
    propsModalProfessionalAppointment() {
      const show = this.showModalProfessionalAppointment;
      const title = "Não foi possível inabilitar.";
      const subtitleType = this.isAppointment
        ? "consulta(s) agendada(s)"
        : "agendamento(s)";
      const subtitle =
        "Exite(m) funcionário(s) com " +
        subtitleType +
        " para o período de inabilitação informado.";

      return {
        show,
        title,
        subtitle,
      };
    },

    firstLine() {
      return this.selectedLine && this.selectedLine[0]
        ? this.selectedLine[0]
        : {};
    },

    isSelect() {
      return this.selectedLine && this.selectedLine.length > 0;
    },

    onlyOneSelect() {
      return this.selectedLine && this.selectedLine.length === 1;
    },

    hasUnitHealth() {
      return this.form.uns_id && this.form.uns_id > 0;
    },

    hasSector() {
      return this.form.set_id && this.form.set_id > 0;
    },

    hasCbo() {
      return this.form.ocp_id && this.form.ocp_id > 0;
    },

    permissionScreen() {
      return this.isAppointment
        ? "consulta.habilitacaoInabilitacaoAtendimento"
        : "exame2.habInabAtendimento";
    },

    getSpaceTable() {
      return this.tableHeight;
    },

    disabledByModal() {
      return (
        this.modalShowDisabledPeriods ||
        this.showModalEnableDisablePeriod ||
        this.showModalProfessionalAppointment
      );
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.set_id = 0;
      }
    },

    "form.set_id"(pValue) {
      if (!pValue || !isNaN(pValue)) {
        this.form.ocp_nome = "";
        this.form.ocp_codigo = "";
        this.form.ocp_id = 0;
      }
    },

    "form.ocp_nome"() {
      this.form.pes_nome = "";
      this.form.vin_id = 0;
      this.form.pes_id = 0;
    },
  },

  created() {
    this.COLUMN_TABLE = [
      { name: "Unidade de Saúde", key: "uns_nome" },
      { name: "Setor", key: "set_nome" },
      { name: "Profissional", key: "pes_nome" },
      { name: "Ocupação", key: "ocp_nome" },
    ];
    this.PROFESSIONAL_COLUMN_TABLE = [
      { name: "Profissional", key: "pes_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      {
        name: this.isAppointment ? "Data da Consulta" : "Data do Exame",
        key: this.isAppointment ? "hag_data_consulta" : "eha_data_exame",
      },
    ];
  },

  mounted() {
    this.handleAfterSearchFilter();
  },

  methods: {
    closeModalShow() {
      this.modalShowDisabledPeriods = false;
    },

    turnOnAct() {
      this.showModalEnableDisablePeriod = true;
      this.type = "turnOnAct";
    },

    turnOffAct() {
      this.showModalEnableDisablePeriod = true;
      this.type = "turnOffAct";
    },

    closeModalEnableDisablePeriod() {
      this.showModalEnableDisablePeriod = false;
    },

    openModalProfessionalAppointment() {
      this.showModalProfessionalAppointment = true;
    },

    closeModalProfessionalAppointment() {
      this.showModalProfessionalAppointment = false;
    },

    showDisabledsAct() {
      this.modalShowDisabledPeriods = true;
    },

    selectLine(pValue) {
      this.selectedLine = pValue;
    },

    getCountValue(pValue) {
      this.totalMutableList = Number(pValue) || 0;
    },

    afterSearchFilter() {
      this.handleAfterSearchFilter();
    },

    handleAfterSearchFilter() {
      // GET DYNAMIC HEIGHST

      if (this.$refs.RgSearch?.$refs.listContent) {
        // GET DYNAMIC HEIGHST
        const footerSlot = this.$refs.footerSlot
          ? this.$refs.footerSlot.offsetHeight
          : 60;
        const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;
        this.tableHeight = searchArea - footerSlot;

        if (this.$refs.smartTable) {
          this.$refs.smartTable.cleanMultSelectedRow();
          this.$refs.smartTable.setScrollTopZero();
        }
        this.selectedLine = null;
      }
    },

    reSearchFilter() {
      this.mutableList = [];
      this.$refs.RgSearch.submitForm(false, true);
      this.$refs.smartTable.cleanMultSelectedRow();
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.form.ocp_nome = pValue.source.ocp_nome;
        this.form.ocp_codigo = pValue.source.ocp_codigo;
        this.$loader.finish();
      } else {
        this.form.ocp_id = 0;
        this.form.ocp_nome = "";
        this.form.ocp_codigo = "";
      }
    },

    selectingEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_id, pes_nome, vin_id } = source;

        this.form.pes_id = pes_id;
        this.form.pes_nome = pes_nome;
        this.form.vin_id = vin_id;
      } else {
        this.form.pes_id = 0;
        this.form.vin_id = 0;
        this.form.pes_nome = "";
      }
    },

    // SET PAYLOAD FROM REQUEST
    generateFilter() {
      const { uns_id, set_id, ocp_id, pes_id } = this.form;
      const variables = {
        arrFormData: {
          blnComEscala: true,
          pes_id,
          ocp_id,
          set_id,
          uns_id,
          smd_id: this.isAppointment ? 45 : 108,
        },
      };

      return variables;
    },

    //  RETURN REQUEST DATA
    async actionSearch(pData) {
      const modules = this.isAppointment ? "Appointment" : "Exam";
      const path = `${modules}/Schedule/SEARCH_DISABLED_PERIODS`;

      return this.$store.dispatch(path, pData);
    },

    handleErrorDisabledPeriod(pData) {
      this.professionalAppointmentList = [];
      const PROFESSIONAL_DISABLE_EXCEPTION = [
        "FuncionarioInabilitacaoDependenciaConsultaException",
        "FuncionarioInabilitacaoDependenciaExameException",
      ];

      const isProfessionalDisableError = PROFESSIONAL_DISABLE_EXCEPTION.includes(
        pData.exception,
      );

      if (isProfessionalDisableError) {
        const exceptionDetails = Object.assign({}, pData.detalhes_exececao);

        const professionalAppointmentData = this.isAppointment
          ? exceptionDetails.CONSULTA
          : exceptionDetails.EXAME;

        if (professionalAppointmentData) {
          professionalAppointmentData.forEach((appointment) => {
            appointment.forEach((item) => {
              this.professionalAppointmentList.push(item);
            });
          });

          this.totalProfessionalAppointment = Number(
            this.professionalAppointmentList.length,
          );

          this.openModalProfessionalAppointment();
        }
      } else {
        this.$toaster.error(pData.mensagem);
      }
    },

    // CLEAN FILTER
    actionCleanForm() {
      this.mutableList = [];
      this.selectedLine = [];
      this.form = {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        set_id: 0,
        ocp_id: 0,
        ocp_nome: "",
        ocp_codigo: "",
        pes_nome: "",
        vin_id: 0,
        pes_id: 0,
      };
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanMultSelectedRow();
      }
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
};
</script>
