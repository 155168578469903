<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchReasonsDisqualification from "./action/SearchReasonsDisqualification";

export default {
  name: "RgComboboxReasonsDisqualification",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchReasonsDisqualification();
      data.forEach((item) => {
        if (item.mip_ativo === true) {
          this.mData.push({ value: item.mip_id, text: item.mip_nome });
        }
      });
    },
    getMData() {
      return this.mData;
    },
  },
};
</script>
