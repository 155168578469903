import SEARCH_CRITICIZE_BPAC from "./SearchCriticizeBpac";
import SEARCH_CRITICIZE_BPAI from "./SearchCriticizeBpai";
import SEARCH_CRITICIZE_LEGACY_BPAC from "./SearchCriticizeLegacyBpac";
import SEARCH_CRITICIZE_LEGACY_BPAI from "./SearchCriticizeLegacyBpai";
import GET_ATTENDANCES_DATA from "./GetAttendancesData";

export default {
  SEARCH_CRITICIZE_BPAC,
  SEARCH_CRITICIZE_BPAI,
  SEARCH_CRITICIZE_LEGACY_BPAC,
  SEARCH_CRITICIZE_LEGACY_BPAI,
  GET_ATTENDANCES_DATA,
};
