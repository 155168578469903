import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Sectors($unitHealth: Int!, $permission: Int, $limit: Int) {
    sectors(
      set_id_unidades_saude: $unitHealth
      permission: $permission
      limit: $limit
    ) {
      rows {
        set_id
        set_nome
        set_responsavel
        set_ativo
        set_tipos_atendimento_pep
      }
    }
  }
`;
export default async ({
  unitHealth = null,
  permission = null,
  limit = 1000,
}) => {
  const variables = {
    unitHealth,
    permission,
    limit,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.sectors.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
