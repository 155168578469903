import GET_PATIENT_LIST from "./GetPatientList";
import GET_PATIENT_BY_ID from "./GetPatientById";
import GET_PATIENT_LIST_UNIFY from "./GetPatientListUnify";
import CREATE_PATIENT from "./CreatePatient";
import RESEND_PATIENT_TO_LOTUS from "./ResendPatientToLotus";
import UPDATE_PATIENT from "./UpdatePatient";
import PRINT_PATIENT_TOKEN from "./PrintPatientToken";
import PRINT_PATIENT_CARD from "./PrintPatientCard";
import GET_CUSTOM_HISTORY_PATIENT_LIST from "./GetCustomHistoryPatientList";
import CREATE_PATIENT_FOR_PERSON from "./CreatePatientForPerson";
import SEARCH_PATIENTS_GENERAL_HISTORY from "./SearchPatientsGeneralHistory";
import UNIFY_PATIENT from "./UnifyPatient";
import GET_PATIENT_BY_PESID_FOR_UNIFY from "./GetPatientByPesIdForUnify";
import SEARCH_PERSON from "./SearchPerson";

import GET_DETAILS_HISTORY_CONSULTATION_PATIENT from "./GetDetailsHistoryConsultationPatient";
import GET_DETAILS_HISTORY_QUEUE_CONSULTATION_PATIENT from "./GetDetailsHistoryQueueConsultationPatient";

import GET_DETAILS_HISTORY_EXAM_PATIENT from "./GetDetailsHistoryExamPatient";
import GET_DETAILS_HISTORY_EXAM_EFFECTIVE_PATIENT from "./GetDetailsHistoryExamEffectivePatient";
import GET_DETAILS_HISTORY_QUEUE_EXAM_PATIENT from "./GetDetailsHistoryQueueExamPatient";

import GET_DETAILS_HISTORY_HOSPITALIZATION_PATIENT from "./GetDetailsHistoryHospitalizationPatient";
import GET_DETAILS_HISTORY_QUEUE_HOSPITALIZATION_PATIENT from "./GetDetailsHistoryQueueHospitalizationPatient";

import GET_DETAILS_HISTORY_EMERGECY_PATIENT from "./GetDetailsHistoryEmergencyPatient";

import CHANGE_STATUS_PATIENT from "./ChangePatientStatus";

import SEARCH_AB_ESUS from "./SearchAbEsus";
import CREATE_AB_ESUS from "./CreateAbEsus";
import UPDATE_AB_ESUS from "./UpdateAbEsus";

import GET_AB_INDIVIDUAL_REGISTRATION from "./GetAbIndividualRegistration";

export default {
  GET_PATIENT_LIST,
  GET_PATIENT_BY_ID,
  GET_PATIENT_LIST_UNIFY,
  CREATE_PATIENT,
  PRINT_PATIENT_TOKEN,
  PRINT_PATIENT_CARD,
  GET_CUSTOM_HISTORY_PATIENT_LIST,
  UPDATE_PATIENT,
  RESEND_PATIENT_TO_LOTUS,
  CREATE_PATIENT_FOR_PERSON,
  SEARCH_PATIENTS_GENERAL_HISTORY,
  UNIFY_PATIENT,
  GET_PATIENT_BY_PESID_FOR_UNIFY,
  SEARCH_PERSON,
  GET_DETAILS_HISTORY_CONSULTATION_PATIENT,
  GET_DETAILS_HISTORY_QUEUE_CONSULTATION_PATIENT,
  GET_DETAILS_HISTORY_EXAM_PATIENT,
  GET_DETAILS_HISTORY_QUEUE_EXAM_PATIENT,
  GET_DETAILS_HISTORY_HOSPITALIZATION_PATIENT,
  GET_DETAILS_HISTORY_QUEUE_HOSPITALIZATION_PATIENT,
  GET_DETAILS_HISTORY_EMERGECY_PATIENT,
  CHANGE_STATUS_PATIENT,
  SEARCH_AB_ESUS,
  CREATE_AB_ESUS,
  UPDATE_AB_ESUS,
  GET_AB_INDIVIDUAL_REGISTRATION,
  GET_DETAILS_HISTORY_EXAM_EFFECTIVE_PATIENT,
};
