import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyCentralEndForecastReport(
    $unitHealthId: Int
    $unitHealthName: String
    $pharmacyId: Int
    $pharmacyName: String
    $productId: Int
    $productName: String
    $productTypeId: Int
    $productTypeName: String
    $numberOfMonths: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyCentralEndForecastReport(
      unitHealthId: $unitHealthId
      unitHealthName: $unitHealthName
      pharmacyId: $pharmacyId
      pharmacyName: $pharmacyName
      productId: $productId
      productName: $productName
      productTypeId: $productTypeId
      productTypeName: $productTypeName
      numberOfMonths: $numberOfMonths
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        TIPO
        NOME
        NOME_COMERCIAL
        QUANTIDADE
        CONSUMO_MEDIO
        MESES_RESTANTES
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyCentralEndForecastReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
