import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, appointmentQueueId) => {
  return AuthLegacyRequest.post(
    "pacientes/controller-paciente/buscar-detalhes-historico-fila-consulta-paciente",
    { idFilaConsulta: appointmentQueueId },
  )
    .then(({ data }) => {
      data = HandleResponse(data);

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
