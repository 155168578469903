<template lang="html">
  <aside v-if="show" class="modal-not-effective-exam">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      class="rg-base-modal"
      with-close-button
      :show="show"
      @close="close"
      @shortkey.native="close"
    >
      <section slot="header" class="header">
        <div class="title">Não Efetivar</div>
      </section>

      <section slot="body" class="body">
        <div class="description">
          Selecione o motivo que justifica a ausência de efetivação
        </div>
        <RgComboboxNotEffectivatedReason
          v-model="reasonNotEffectivated"
          label="Motivo"
          class="reason"
          :rules="{ required: true }"
        />
      </section>

      <section slot="footer" class="footer">
        <RgCancelButton label="Cancelar" medium @click="close" />
        <RgSaveButton
          :disabled="!reasonNotEffectivated"
          medium
          @click="notEffectivateExam"
        />
      </section>
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgBaseModal, RgCancelButton, RgSaveButton } from "~tokio/primitive";
import RgComboboxNotEffectivatedReason from "$exam/submodules/view/individual-effectivation/component/combobox/rg-combobox-not-effectivated-reason/RgComboboxNotEffectivatedReason";
import PrepareExamToSave from "$exam/store/actions/PrepareExamToSave";
import { mapGetters } from "vuex";

const NOT_EFFECTUATE = 3;

export default {
  name: "ModalNotEffectiveExam",
  components: {
    RgBaseModal,
    RgCancelButton,
    RgSaveButton,
    RgComboboxNotEffectivatedReason,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    examInfo: {
      type: [Object, Array],
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      reasonNotEffectivated: null,
    };
  },
  computed: {
    ...mapGetters({
      loggedUserId: "Login/GET_USER_ID",
    }),
    inBatch() {
      return this.examInfo && this.examInfo.length > 1;
    },
  },
  watch: {
    show(pValue) {},
  },
  mounted() {
    this.unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.unitHealthName = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },
  methods: {
    async notEffectivateExam() {
      try {
        this.$loader.start();

        const data = [];
        const exams = this.examInfo;

        const preparedExamInfo = PrepareExamToSave(
          exams,
          NOT_EFFECTUATE,
          null,
          this.reasonNotEffectivated,
          true,
          this.loggedUserId,
        );

        preparedExamInfo.forEach((item) => {
          if (item.examId) {
            data.push(item);
          }
        });

        await this.$store.dispatch(
          "Exam/IndividualEffectivation/EFFECTTIVATE_EXAMS_BY_ID",
          data,
        );

        this.$toaster.success("Exame não efetivado com sucesso");
        this.close();
        this.$emit("reSearch");
      } catch (pError) {
        this.$toaster.error(pError, "Erro ao não efetivar");
      } finally {
        this.$loader.finish();
      }
    },
    close() {
      this.reasonNotEffectivated = null;
      this.$emit("close");
    },
  },
};
</script>
