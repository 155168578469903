import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  Cid10ObrigatorioException: `CID10 obrigatório`,
  SexoObrigatorioException: `Sexo não permitido para o procedimento`,
  IdadeObrigatoriaException: `Idade não permitida para o procedimento`,
  ProcedimentoNaoVinculadoException: `Procedimento não importado para o profissional`,
  ProcedimentoDuplicadoException: `Procedimento já adicionado`,
  NaoPossuiCartaoSusException: `Profissional sem CNS`,
  ProcedimentoFeitoNaCompetenciaException: `Procedimento não pode ser faturado na competência atual`,
  ServicoClassificacaoExcepition: `Procedimento exige serviço e classificação`,
  QuantidadeMaximaExcedidaException: `Procedimento incompatível com a quantidade informada`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "faturamento/controller-atendimento/alterar",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
