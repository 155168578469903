<template lang="html">
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9748_127)">
      <path
        d="M41.52 15.6591C38.9859 13.0669 36.422 10.503 33.8297 7.96901C32.5087 6.67645 30.5081 6.67787 29.1885 7.96901C26.5963 10.503 24.0394 13.074 21.4954 15.6563C20.6526 16.5118 20.4142 17.5901 20.742 18.7493C21.4074 21.1074 24.2863 21.902 26.0926 20.2221C26.758 19.6035 27.3639 18.921 27.9981 18.2683C28.1102 19.6006 28.1074 20.8676 28.1116 22.1332C28.1173 24.1863 29.5603 25.7115 31.4956 25.7172C33.4381 25.7215 34.8967 24.2019 34.9038 22.1559C34.908 20.8861 34.9038 19.6162 34.9038 18.1662C35.643 18.9224 36.2503 19.5864 36.9015 20.205C38.2154 21.4536 40.1834 21.4366 41.4405 20.1951C42.7005 18.9508 42.7785 16.9488 41.5186 15.6605L41.52 15.6591ZM40.2373 18.9707C39.5818 19.6148 38.6638 19.5651 37.9316 18.8373C36.8902 17.8029 35.853 16.7644 34.8186 15.7244C34.515 15.4193 34.1943 15.1966 33.7403 15.3895C33.2962 15.5782 33.1983 15.9429 33.204 16.3841C33.2153 17.356 33.2082 18.3293 33.2082 19.3012C33.2082 20.2561 33.211 21.211 33.2082 22.1659C33.2025 23.247 32.503 24.0118 31.5212 24.0174C30.5379 24.0231 29.8157 23.2569 29.8143 22.1871C29.81 20.2958 29.8086 18.4031 29.8157 16.5118C29.8171 16.0507 29.7944 15.6194 29.2964 15.3994C28.7629 15.1639 28.4337 15.4902 28.1003 15.8265C27.1028 16.8296 26.1039 17.8313 25.0993 18.8273C24.3417 19.5793 23.4095 19.6219 22.7554 18.9479C22.1183 18.291 22.1736 17.3716 22.9029 16.6409C25.3264 14.2133 27.7526 11.7885 30.1803 9.36372C31.0331 8.51242 31.9795 8.50391 32.828 9.34953C35.2698 11.7843 37.7075 14.2232 40.1422 16.665C40.8602 17.3858 40.8928 18.3322 40.2402 18.9721L40.2373 18.9707Z"
        fill="white"
      />
      <path
        d="M55.9287 30.0462C55.0377 29.1353 54.1296 28.2428 53.2286 27.3419C51.6267 25.7414 50.0418 24.1225 48.4172 22.5448C47.1062 21.2721 45.0928 21.3132 43.8371 22.5859C42.5956 23.843 42.5856 25.8124 43.82 27.1305C44.1223 27.4525 44.4429 27.759 44.7551 28.0712C45.08 28.3961 45.4035 28.721 45.7965 29.114C44.3933 29.114 43.1205 29.1097 41.8464 29.114C39.8288 29.1211 38.312 30.5669 38.3021 32.4851C38.2921 34.4332 39.8089 35.8989 41.8507 35.9074C43.1234 35.9116 44.3961 35.9074 45.8178 35.9074C45.1013 36.6168 44.4699 37.2283 43.8555 37.8554C43.1489 38.5776 42.807 39.453 42.9205 40.459C43.0737 41.8083 43.8016 42.7674 45.0857 43.2144C46.3698 43.6613 47.5347 43.3605 48.4981 42.4042C50.9839 39.9354 53.4641 37.461 55.9287 34.9724C57.3604 33.528 57.3504 31.4991 55.9287 30.0448V30.0462ZM54.8036 33.6869C52.2879 36.2252 49.7609 38.7521 47.2239 41.2677C46.6124 41.875 45.6632 41.8438 45.0701 41.2635C44.4585 40.6661 44.4316 39.7098 45.0516 39.0728C46.1002 37.9959 47.1714 36.9403 48.2384 35.8804C48.5506 35.5697 48.8301 35.2561 48.64 34.7695C48.4427 34.2658 48.0284 34.2048 47.5559 34.2076C45.6632 34.2175 43.7704 34.2147 41.8776 34.2104C40.7638 34.209 39.9933 33.4968 40.0033 32.4922C40.0146 31.5076 40.7737 30.8194 41.8634 30.8152C42.8183 30.8109 43.7746 30.8152 44.7295 30.8152C45.7199 30.8152 46.7103 30.8095 47.7021 30.818C48.1306 30.8223 48.4626 30.6875 48.6371 30.2703C48.806 29.866 48.6428 29.551 48.3619 29.2701C47.2622 28.1691 46.1569 27.0723 45.063 25.9642C44.6175 25.5116 44.4699 24.9611 44.687 24.3538C44.8941 23.7749 45.3198 23.4273 45.9313 23.3322C46.4379 23.2528 46.882 23.4117 47.2395 23.7678C49.7708 26.2905 52.3035 28.8118 54.8177 31.3501C55.4832 32.0226 55.4775 33.0144 54.8078 33.6897L54.8036 33.6869Z"
        fill="white"
      />
      <path
        d="M41.8775 45.3851C40.7694 43.6797 38.4041 43.3959 36.9044 44.8204C36.2489 45.4418 35.6543 46.1271 35.0315 46.7841C34.9038 45.3922 34.8995 44.1053 34.9066 42.8184C34.9094 42.2608 34.8059 41.7288 34.5647 41.2279C33.8879 39.8218 32.3328 39.0613 30.8174 39.3863C29.2524 39.7225 28.14 41.0335 28.1159 42.6184C28.0946 43.9734 28.1116 45.3298 28.1116 46.8195C27.4405 46.1427 26.8729 45.561 26.294 44.9892C24.8922 43.603 22.8703 43.5605 21.5593 44.8857C20.2681 46.1924 20.3177 48.2072 21.6884 49.582C24.1232 52.0238 26.5608 54.4614 29.0041 56.8947C30.4811 58.3674 32.513 58.3674 34.0056 56.9004C34.8385 56.0817 35.6572 55.2517 36.483 54.4259C38.1204 52.7886 39.7662 51.1583 41.3937 49.5096C42.5444 48.3434 42.7232 46.6876 41.8775 45.3851V45.3851ZM40.1224 48.3774C37.7004 50.8065 35.2755 53.2327 32.8464 55.656C31.9738 56.5272 31.0373 56.5187 30.1591 55.6433C27.7441 53.2312 25.3306 50.8192 22.9185 48.4044C22.1609 47.6453 22.1055 46.7245 22.771 46.0619C23.4364 45.3993 24.3459 45.4546 25.115 46.2165C26.1067 47.1998 27.0929 48.1901 28.079 49.1791C28.4096 49.5111 28.7288 49.8573 29.268 49.6373C29.827 49.4089 29.8185 48.9265 29.8128 48.4356C29.8043 47.5332 29.81 46.6322 29.81 45.7299C29.81 44.758 29.8058 43.7846 29.81 42.8127C29.8171 41.7699 30.5209 41.0179 31.4871 41.0066C32.4718 40.9952 33.2011 41.76 33.2054 42.8269C33.211 44.7537 33.2125 46.6819 33.2025 48.6087C33.1997 49.0684 33.2877 49.4543 33.7602 49.643C34.2184 49.826 34.5334 49.5848 34.8342 49.2826C35.8572 48.254 36.8817 47.2296 37.9104 46.2066C38.668 45.4532 39.596 45.4021 40.2543 46.0704C40.8928 46.7188 40.846 47.651 40.121 48.3788L40.1224 48.3774Z"
        fill="white"
      />
      <path
        d="M21.392 29.1211C20.0384 29.0984 18.6833 29.1168 17.1751 29.1168C17.903 28.4031 18.5443 27.8058 19.1501 27.173C20.4186 25.8492 20.4129 23.8501 19.1587 22.5887C17.9015 21.3246 15.8811 21.2834 14.5785 22.566C12.0317 25.0731 9.50038 27.5972 6.99749 30.1483C5.66801 31.5033 5.66801 33.5166 7.00033 34.8744C9.51599 37.4383 12.0615 39.9723 14.6197 42.4936C15.4909 43.352 16.5763 43.6074 17.7568 43.2668C20.1192 42.5872 20.9025 39.7013 19.2083 37.9107C18.5854 37.2524 17.9129 36.6423 17.1538 35.9045C18.6975 35.9045 20.0554 35.9258 21.4132 35.8988C23.2521 35.8634 24.715 34.3367 24.7107 32.4979C24.7065 30.6619 23.2351 29.148 21.392 29.1182V29.1211ZM21.0798 34.2104C20.1604 34.2118 19.2395 34.2104 18.3201 34.2104C17.3297 34.2104 16.3394 34.2175 15.3476 34.2076C14.8992 34.2019 14.5459 34.3268 14.3714 34.7737C14.2025 35.2064 14.4125 35.5186 14.7034 35.808C15.7817 36.8821 16.8587 37.9561 17.9299 39.0359C18.3939 39.5027 18.5514 40.0659 18.3244 40.6902C18.1144 41.2663 17.6788 41.6096 17.0672 41.6976C16.5607 41.77 16.1209 41.604 15.7647 41.2464C13.2476 38.7351 10.7277 36.2252 8.2234 33.7011C7.53241 33.0044 7.53241 32.0169 8.2234 31.3217C10.7149 28.8103 13.2178 26.3089 15.7278 23.816C16.3961 23.152 17.3382 23.1534 17.9569 23.7735C18.5798 24.3978 18.5769 25.3243 17.9143 26.001C16.887 27.051 15.8428 28.0825 14.8027 29.1182C14.4792 29.4389 14.1713 29.7581 14.3813 30.2703C14.587 30.7712 15.0085 30.8194 15.4767 30.8166C17.3524 30.8081 19.2268 30.8109 21.1025 30.8137C22.2334 30.8137 23.0052 31.4976 23.0123 32.4965C23.0208 33.5166 22.2433 34.2076 21.0798 34.2104V34.2104Z"
        fill="white"
      />
      <path
        d="M31.4816 27.4155C28.6736 27.4297 26.4048 29.7226 26.4134 32.5361C26.4219 35.3156 28.7091 37.6042 31.4802 37.6056C34.3179 37.607 36.6037 35.3213 36.6037 32.4822C36.6037 29.6899 34.2895 27.3999 31.4816 27.4141V27.4155ZM31.4929 35.9073C29.6158 35.8988 28.1004 34.365 28.1117 32.4822C28.1231 30.6079 29.6668 29.094 31.5483 29.1139C33.4212 29.1338 34.9238 30.6789 34.9039 32.5659C34.8855 34.409 33.346 35.9158 31.4915 35.9073H31.4929Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9748_127">
        <rect width="51" height="51" fill="white" transform="translate(6 7)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "Movements",
};
</script>
