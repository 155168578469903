<template>
  <div class="exam-patients-queue-report">
    <div class="grid">
      <div class="filter-title">
        <span class="title">Dados da Regulação</span>
      </div>

      <div class="selectinput">
        <RgSelectPatientsQueueProcedures
          id="procedure"
          v-model="form.procedureName"
          :unit-health-id="getUnitHealthId"
          :hasRegularPermission="hasRegularPermission"
          :hasViewRequestUnitHealthPermission="
            hasViewRequestUnitHealthPermission
          "
          @change="selectingProcedureQueue"
        />
      </div>

      <div class="selectinput">
        <RgSelectPatientsQueueSubprocedures
          id="subprocedure"
          v-model="form.subprocedureId"
          :procedureName="form.procedureName"
          :disabled="!form.procedureName"
          @change="selectingSubProcedureQueue"
        />
      </div>

      <div class="selectinput">
        <RgSelectPriorityQueue
          id="priority"
          v-model="form.pfi_id"
          :module-id="getExamModuleId"
          @change="selectingPriority"
        />
      </div>

      <div class="selectinput">
        <RgSelectSituation id="situation" v-model="form.situation" />
      </div>

      <div class="selectinput">
        <RgSuggestUser
          id="user"
          v-model="form.usu_nome"
          label="Usuário da Inclusão"
          @selected="selectingUser"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período da Inclusão</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="inclusion-period-initial"
          ref="inclusionPeriodInitial"
          v-model="form.inclusionPeriodInitial"
          :rules="{ fn: validateInclusionInitialDate }"
          label="Data Inicial"
        />
        <RgInputDate
          id="inclusion-period-final"
          ref="inclusionPeriodFinal"
          v-model="form.inclusionPeriodFinal"
          :rules="{ fn: validateInclusionFinalDate }"
          label="Data Final"
        />
      </div>

      <div class="filter-title">
        <span class="title">Dados do Paciente</span>
      </div>

      <div class="selectinput">
        <RgInput
          id="patient-name"
          v-model="form.patientName"
          label="Paciente"
          placeholder="Digite o nome do paciente"
          :disabled="hasPatienId"
          :class="{ disable: hasPatienId }"
        />
      </div>

      <div class="selectinput">
        <RgSuggestSmartPerson
          id="document"
          ref="document"
          v-model="document"
          filter-only-document
          :disabled="disabledDocument"
          :rules="{ forceSelection: true }"
          :enabled-patient="false"
          :with-patient-module="['exam2']"
          @selected="selectingPatient"
        />
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="gender"
          ref="sex"
          label="Sexo"
          v-bind="propsRadioCustom"
          multSelect
          :disabled="hasPatienId"
          @input="onInputRadioCustom"
        />
      </div>

      <div class="selectinput _flex">
        <RgInput
          id="starting-age"
          ref="startingAge"
          v-model="form.startingAge"
          label="Idade de"
          :rules="{
            required: hasStartingAge || hasFinalAge,
            fn: validateStartingAge,
          }"
          :maxlength="3"
          :disabled="hasPatienId"
          :class="{ disable: hasPatienId }"
        />
        <RgInput
          id="final-age"
          ref="finalAge"
          v-model="form.finalAge"
          label="Idade até"
          :rules="{
            required: hasFinalAge || hasStartingAge,
            fn: validateFinalAge,
          }"
          :maxlength="3"
          :disabled="hasPatienId"
          :class="{ disable: hasPatienId }"
        />
      </div>

      <div class="filter-title">
        <span class="title">Dados da Solicitação</span>
      </div>

      <div class="selectinput">
        <RgSelectUnithealthQueue
          id="unithealth"
          v-model="form.uns_id"
          :unit-health-id="getUnitHealthId"
          :module-id="getExamModuleId"
          :hasRegularPermission="hasRegularPermission"
          :hasViewRequestUnitHealthPermission="
            hasViewRequestUnitHealthPermission
          "
          :default-text="'Todas'"
          @change="selectingUnitHealth"
        />
      </div>

      <div class="selectinput">
        <RgSuggestEmployee
          id="employee"
          v-model="form.employeeName"
          label="Profissional Solicitante"
          @selected="selectingProfessional"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período da Solicitação</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="request-date-initial"
          ref="requestDateInitial"
          v-model="form.requestDateInitial"
          :rules="{ fn: validateRequestInitialDate }"
          label="Data Inicial"
        />
        <RgInputDate
          id="request-date-final"
          ref="requestDateFinal"
          v-model="form.requestDateFinal"
          :rules="{ fn: validateRequestFinalDate }"
          label="Data Final"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  RgSelectPriorityQueue,
  RgSelectSituation,
  RgSuggestUser,
  RgInputDate,
  RgSelectUnithealthQueue,
  RgSuggestEmployee,
  RgSuggestSmartPerson,
  RgInput,
  RgRadioCustom,
} from "~tokio/primitive";
import {
  RgSelectPatientsQueueProcedures,
  RgSelectPatientsQueueSubprocedures,
} from "$exam/submodules/schedule/component/";

const FORM_FILTER = {
  procedureId: null,
  procedureName: "",
  subprocedureId: null,
  subprocedureName: "",
  soc_nome: "",
  pfi_id: null,
  pfi_nome: "",
  situation: null,
  usu_id: null,
  usu_nome: "",
  inclusionPeriodInitial: "",
  inclusionPeriodFinal: "",
  patientName: "",
  pes_id: null,
  gender: null,
  startingAge: null,
  finalAge: null,
  uns_id: null,
  uns_nome: "",
  employeeId: null,
  employeeName: "",
  requestDateInitial: "",
  requestDateFinal: "",
  cli_id: null,
};

export default {
  name: "ExamPatientsQueueReport",
  components: {
    RgSelectPatientsQueueProcedures,
    RgSelectPatientsQueueSubprocedures,
    RgSelectPriorityQueue,
    RgSelectSituation,
    RgSuggestUser,
    RgInputDate,
    RgSelectUnithealthQueue,
    RgSuggestEmployee,
    RgSuggestSmartPerson,
    RgInput,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Pacientes na Fila",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      document: null,
      disabledDocument: false,
      setColumn: "",
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.form.gender;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }"
      );
    },
    columnTable() {
      return this.setColumn;
    },
    getNameSmartTable() {
      return "ExamPatientsQueueReport";
    },
    getExamModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },
    getUnitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
    hasStartingAge() {
      return this.form.startingAge && this.form.startingAge.length > 0;
    },
    hasFinalAge() {
      return this.form.finalAge && this.form.finalAge.length > 0;
    },
    hasPatienId() {
      return this.form.pes_id && this.form.pes_id !== 0;
    },
    hasRegularPermission() {
      return !!this.$Permissions.global.has("exame2.filaExame.regular");
    },
    hasViewRequestUnitHealthPermission() {
      return !!this.$Permissions.global.has(
        "exame2.filaExame.visualizarUnidadeSolicitante",
      );
    },
  },
  watch: {
    "form.patientName"(pValue) {
      pValue && pValue.length > 0
        ? (this.disabledDocument = true)
        : (this.disabledDocument = false);
    },
  },
  created() {
    this.COLUMNS = [
      { name: "Posição", key: "POSICAO" },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      {
        name: "Subprocedimento",
        key: "SUB_PROCEDIMENTO",
        align: "left",
        concat: ",",
      },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "CNS", key: "CNS" },
      { name: "Prontuário da Unidade", key: "PRONTUARIO_UNIDADE" },
      { name: "Tempo na Fila", key: "TEMPO_FILA", align: "left" },
      {
        name: "Unidade de Saúde da Inclusão",
        key: "UNIDADE_INCLUSAO",
        align: "left",
      },
      { name: "Data da Inclusão", key: "DATA_INCLUSAO" },
      {
        name: "Unidade de Saúde da Solicitação",
        key: "UNIDADE_SOLICITACAO",
        align: "left",
      },
      {
        name: "Profissional da Solicitação",
        key: "PROFISSIONAL_SOLICITACAO",
        align: "left",
      },
      { name: "Situação", key: "SITUACAO" },
      { name: "Prioridade", key: "PRIORIDADE" },
      { name: "Data de Nascimento", key: "DATA_NASCIMENTO" },
      { name: "Nome da Mãe", key: "NOME_MAE", align: "left" },
      { name: "Sexo", key: "SEXO" },
      { name: "Idade", key: "IDADE" },
      { name: "Telefone", key: "TELEFONE" },
      { name: "Município / UF", key: "MUNICIPIO_ESTADO", align: "left" },
      { name: "Usuário da Inclusão", key: "USUARIO_INCLUSAO", align: "left" },
    ];

    this.COLUMNS_WITHOUT_RECORD = this.COLUMNS.filter((column) => {
      return column.key !== "PRONTUARIO_UNIDADE";
    });
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.gender = item;
      }
    },
    buildFilter() {
      const genders = this.prepareGender();
      const payload = {
        procedureId: this.form.procedureId
          ? Number(this.form.procedureId)
          : null,
        procedureName: this.form.procedureName,
        subprocedureId: this.form.subprocedureId
          ? Number(this.form.subprocedureId)
          : null,
        subprocedureName: this.form.subprocedureName,
        pfi_id: this.form.pfi_id ? Number(this.form.pfi_id) : null,
        pfi_nome: this.form.pfi_nome,
        situation: this.form.situation,
        usu_id: this.form.usu_id ? Number(this.form.usu_id) : null,
        usu_nome: this.form.usu_nome,
        inclusionPeriodInitial: this.$utils.date.BrazilianDateToDatabase(
          this.form.inclusionPeriodInitial,
        ),
        inclusionPeriodFinal: this.$utils.date.BrazilianDateToDatabase(
          this.form.inclusionPeriodFinal,
        ),
        pes_id: this.form.pes_id ? Number(this.form.pes_id) : null,
        patientName: this.form.patientName,
        gender: genders,
        startingAge: this.form.startingAge,
        finalAge: this.form.finalAge,
        uns_id: this.form.uns_id ? Number(this.form.uns_id) : null,
        uns_nome: this.form.uns_nome,
        employeeId: this.form.employeeId ? Number(this.form.employeeId) : null,
        employeeName: this.form.employeeName,
        requestDateInitial: this.$utils.date.BrazilianDateToDatabase(
          this.form.requestDateInitial,
        ),
        requestDateFinal: this.$utils.date.BrazilianDateToDatabase(
          this.form.requestDateFinal,
        ),
        cli_id: this.$store.getters["Login/GET_USER_ID_CLIENT"],
        columnsToPrint: this.columnsToPrint,
      };
      return payload;
    },

    async doSearch(pData) {
      try {
        this.$loader.start("Carregando...");

        this.setColumn = this.form.uns_id
          ? this.COLUMNS
          : this.COLUMNS_WITHOUT_RECORD;

        return await this.$store.dispatch(
          "Exam/Report/GET_EXAM_PATIENTS_QUEUE_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish();
      }
    },

    selectingProcedureQueue(pValue) {
      if (pValue) {
        this.form.procedureId = pValue.id;
      } else {
        this.form.procedureId = null;
        this.form.procedureName = null;
        this.form.subprocedureId = null;
        this.form.subprocedureName = null;
      }
    },

    selectingSubProcedureQueue(pValue) {
      if (pValue) {
        this.form.subprocedureName = pValue.label;
      } else {
        this.form.subprocedureName = null;
        this.form.subprocedureId = null;
      }
    },

    selectingUser(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.usu_id = pValue.source.usu_id;
      } else {
        this.form.usu_id = null;
      }
    },

    selectingPatient(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.pes_id = pValue.source.pes_id;
      } else {
        this.form.pes_id = null;
      }
    },

    selectingProfessional(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.employeeId =
          pValue.source.employeeRelationship.vin_id_funcionarios;
      } else {
        this.form.employeeId = null;
      }
    },

    selectingPriority(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.pfi_nome = pValue.label;
      } else {
        this.form.pfi_nome = "";
      }
    },

    selectingUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.uns_nome = pValue.label;
      } else {
        this.form.uns_nome = "";
      }
    },

    prepareGender() {
      const hasValue = this.form.gender && this.form.gender !== "";
      return hasValue
        ? this.$utils.app.Genders.GetIdByInitials(this.form.gender)
        : null;
    },

    validateStartingAge(pValue, pErrors) {
      const startingAge = parseInt(pValue);
      const finalDate = parseInt(this.form.finalAge);

      if (startingAge > 120) {
        pErrors.push("A idade inicial não pode ser maior que 120");
        return false;
      }

      const ageIsNotValid = startingAge > finalDate;

      if (ageIsNotValid) {
        pErrors.push("A idade inicial não pode ser maior que a idade final");
        return false;
      }
    },

    validateFinalAge(pValue, pErrors) {
      const finalDate = parseInt(pValue);
      const startingAge = parseInt(this.form.startingAge);

      if (finalDate > 120) {
        pErrors.push("A idade final não pode ser maior que 120");
        return false;
      }

      const ageIsNotValid = startingAge > finalDate;

      if (ageIsNotValid) {
        pErrors.push("A idade inicial não pode ser maior que a idade final");
        return false;
      }
    },

    validateInclusionInitialDate(pValue, pErrors) {
      const initialDate = moment(
        this.form.inclusionPeriodInitial,
        "DD/MM/YYYY",
      );
      const finalDate = moment(this.form.inclusionPeriodFinal, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateInclusionFinalDate(pValue, pErrors) {
      const initialDate = moment(
        this.form.inclusionPeriodInitial,
        "DD/MM/YYYY",
      );
      const finalDate = moment(this.form.inclusionPeriodFinal, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    validateRequestInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.requestDateInitial, "DD/MM/YYYY");
      const finalDate = moment(this.form.requestDateFinal, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateRequestFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.requestDateInitial, "DD/MM/YYYY");
      const finalDate = moment(this.form.requestDateFinal, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.document = null;
      this.disabledDocument = false;

      if (this.$refs.gender) {
        this.$refs.gender.clearRadio();
      }
      if (this.$refs.startingAge) {
        this.$refs.startingAge.cleanValidate();
      }
      if (this.$refs.finalAge) {
        this.$refs.finalAge.cleanValidate();
      }
      if (this.$refs.requestDateInitial) {
        this.$refs.requestDateInitial.cleanValidate();
      }
      if (this.$refs.requestDateFinal) {
        this.$refs.requestDateFinal.cleanValidate();
      }
      if (this.$refs.inclusionPeriodInitial) {
        this.$refs.inclusionPeriodInitial.cleanValidate();
      }
      if (this.$refs.inclusionPeriodFinal) {
        this.$refs.inclusionPeriodFinal.cleanValidate();
      }
      if (this.$refs.sex) {
        this.$refs.sex.clearRadio();
      }
    },
  },
};
</script>
