import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import { BrazilianDateFormat } from "~utils/date";

const query = gql`
  query EffectivedExams(
    $patient: String
    $employee: String
    $patientId: Int
    $recordNumber: Int
    $hospitalizationNumber: Int
    $requestEployee: String
    $examType: Int
    $typeScale: String
    $examStatus: [Int]
    $bulletinNumber: Int
    $procedureCode: String
    $requestFinalDate: String
    $requestInitialDate: String
    $effectivenessInitialDate: String
    $effectivenessFinalDate: String
    $unitHealth: Int
    $sector: Int
    $exe_id_vinculos: Int
    $exe_id_vinculos_solicitante: Int
    $orderFilter: String
    $orderDirection: String
    $isLaboratory: Boolean
    $limit: Int
    $offset: Int
  ) {
    effectivedExams(
      exe_id_unidades_saude: $unitHealth
      int_numero: $hospitalizationNumber
      patientId: $patientId
      exe_id_setores: $sector
      patient: $patient
      pac_prontuario_unico: $recordNumber
      request_employee: $requestEployee
      employee: $employee
      examType: $examType
      tipo_escala: $typeScale
      exe_id_exames_efetivacao: $examStatus
      bol_numero: $bulletinNumber
      exe_codigo_procedimento: $procedureCode
      requestFinalDate: $requestFinalDate
      requestInitialDate: $requestInitialDate
      effectivenessInitialDate: $effectivenessInitialDate
      effectivenessFinalDate: $effectivenessFinalDate
      exe_id_vinculos: $exe_id_vinculos
      exe_id_vinculos_solicitante: $exe_id_vinculos_solicitante
      orderFilter: $orderFilter
      orderDirection: $orderDirection
      isLaboratory: $isLaboratory
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        requestDate
        patientName
        pac_prontuario_unico
        request_employee
        procedimento
        auxiliary_index
        exe_id
        exe_codigo_procedimento
        exe_data_solicitacao
        exe_id_unidades_saude
        exe_id_pacientes
        exe_id_vinculos
        exe_id_vinculos_solicitante
        exe_id_exames_efetivacao
        exe_id_exames_motivos_nao_efetivacao
        exe_id_exames_agendamentos_procedimentos
        exe_id_exames_agendamentos_sub_procedimentos
        exe_id_sinonimos_procedimentos
        exe_data_exame
        exe_data_efetivacao
        exe_codigo_cid
        exe_id_setores
        exe_id_boletins
        exe_observacao
        motivo_nao_efetivacao
        cod_procedimento
        procedimento
        procedimento_principal
        bol_numero
        int_numero
        editavel
        examType
        tipo_escala
        setor
        sector {
          set_nome
        }
        cid10 {
          cid_codigo
          cid_nome
        }
        procedure {
          NO_PROCEDIMENTO
          CO_PROCEDIMENTO
        }
        employeeRelationship {
          vin_id
          employee {
            person {
              pes_nome
            }
          }
        }
        patient {
          pac_id
          person {
            pes_id
            pes_nome
            gender {
              sex_id
              sex_sigla
            }
          }
        }
        requestEmployeeRelationship {
          vin_id
          employee {
            fun_conselho_classe
            fun_crs_numero_ab
            person {
              pes_nome
              cpf {
                cpf_id
                cpf_id_tipos_documento
                cpf_numero
              }
              city {
                state {
                  est_sigla
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default async ({ commit }, { variables }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    }).catch((err) => {
      console.error(err);
    });

    commit("SET_LIST", { list: data.effectivedExams.rows });
    commit("SET_COUNT", { count: data.effectivedExams.count });

    data.effectivedExams.rows.map((item) => {
      if (item.requestDate.length > 10) {
        item.requestDate = BrazilianDateFormat(item.requestDate);
      }
    });

    return Promise.resolve(data.effectivedExams);
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
