<style src="./AppointmentOccupationReport.scss" lang="scss" scoped></style>

<template>
  <div class="occupation-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-aor"
          v-model="form.uns_id"
          permission="consulta.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-aor"
          v-model="form.set_id"
          :unit-health="form.uns_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          default-text="Todos"
          empty-default-text="Todos"
          class="inputitem"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          id="ocp-nome-aor"
          v-model="form.ocp_nome"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          label="Ocupação"
          class="inputitem"
          @selected="selectedCbo"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-aor"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="final-date-aor"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          label="Data Final"
          class="inputitem mg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgSuggestCbo,
  RgInputDate,
} from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: null,
  ocp_nome: null,
  ocp_id: null,
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
};

export default {
  name: "AppointmentOccupationReport",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgSuggestCbo,
    RgInputDate,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Consultas por Ocupação",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }"
      );
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "AppointmentOccupationReport";
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },
  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
      }
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.COLUMN = [
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Disponibilizadas", key: "DISPONIBILIZADAS" },
      { name: "Agendadas", key: "AGENDADAS" },
      { name: "Efetivadas", key: "EFETIVADAS" },
      { name: "Não Efetivadas", key: "NAO_EFETIVADAS" },
      { name: "Aguardando", key: "AGUARDANDO" },
      { name: "Vagas Restantes", key: "VAGAS_RESTANTES" },
      { name: "Produtividade", key: "PRODUTIVIDADE" },
    ];
  },
  methods: {
    buildFilter() {
      const payLoad = {
        uns_id: this.form.uns_id || null,
        set_id: this.form.set_id || null,
        set_nome: this.form.set_nome || "",
        ocp_id: this.form.ocp_id || null,
        ocp_nome: this.form.ocp_nome || "",
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_OCCUPATION_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
      this.form.ocp_nome = null;
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.form.ocp_nome = pValue.source.ocp_nome;
        this.$loader.finish();
      } else {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
