<style src="./ModalRegisterSingleInformation.scss" lang="scss" scoped></style>
<template lang="html">
  <div v-if="show" class="modal-confirm-default">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">{{ title }}</h1>
      </div>

      <div slot="body" class="body">
        <span class="subtitle"> {{ subtitle }} </span>
        <div class="text">
          <RgTextArea
            id="information"
            ref="textArea"
            v-model="text"
            :maxlength="500"
            class="textarea"
          />
        </div>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton
          v-if="showCancelButton"
          id="cancel-btn"
          medium
          class="button"
          @click="close"
        />
        <RgSaveButton id="save-btn" medium class="button" @click="save" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgSaveButton,
  RgTextArea,
  RgCancelButton,
} from "~tokio/primitive";

export default {
  name: "ModalConfirmDefault",
  components: {
    RgBaseModal,
    RgSaveButton,
    RgTextArea,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      text: null,
    };
  },

  methods: {
    save() {
      this.$emit("save");
    },

    close() {
      this.text = "";
      this.$emit("close");
    },
  },
};
</script>
