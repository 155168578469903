import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHistoryImportSigtapBdsia(
    $competence: String
    $orderDate: Boolean
  ) {
    SearchHistoryImportSigtapBdsia(
      competence: $competence
      orderDate: $orderDate
    ) {
      count
      rows {
        hsb_competencia_bdsia
        hsb_competencia_sigtap
        hsb_arquivo_bdsia
        hsb_arquivo_sigtap
        data_importacao
        usu_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data;
};
