import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacyInventoryRegister from "$pharmacy/submodules/register/view/inventory-register/PharmacyInventoryRegister";

export default {
  path: "/pharmacy/register/inventory/edit",
  name: "pharmacy.register.inventory.edit",
  components: {
    default: PharmacyInventoryRegister,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Cadastro",
        link: OpenSubModule("pharmacy"),
      },
      {
        label: "Estoque Mínimo e Máximo",
        link: "/pharmacy/register/inventory",
      },
      {
        label: "Editar Estoque Mínimo e Máximo",
      },
    ],
  },
};
