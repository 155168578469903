import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchAbEsus($pacId: Int!) {
    SearchAbEsus(pae_id_pacientes: $pacId) {
      rows {
        pae_id
        pae_id_pacientes
        pae_ficha
        pae_ficha_base
        pae_nome_paciente
        pae_nome_mae
        pae_nascimento
        pae_cpf
        pae_cartao_sus
        pae_sexo
        pae_data_atendimento
        pae_unidade_saude
        pae_area
        pae_microarea
        pae_equipe
        pae_responsavel
        pae_ocupacao_responsavel
        pae_data_visita_domiciliar
        pae_responsavel_visita_domiciliar
        pae_ocupacao_responsavel_visita_domiciliar
        pae_unidade_saude_visita_domiciliar
        pae_area_visita_domiciliar
        pae_equipe_visita_domiciliar
        pae_microarea_visita_domiciliar
        pae_ativo
        pae_ultima_alteracao
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.SearchAbEsus.rows[0];
};
