import { render, staticRenderFns } from "./RgTitle.vue?vue&type=template&id=9550529a&scoped=true&"
import script from "./RgTitle.vue?vue&type=script&lang=js&"
export * from "./RgTitle.vue?vue&type=script&lang=js&"
import style0 from "./RgTitle.scss?vue&type=style&index=0&id=9550529a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9550529a",
  null
  
)

export default component.exports