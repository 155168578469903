<template>
  <div v-show="show" class="modal-time-procedure-import-sigtap-bdsia">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      :with-close-button="!disableButton || hasError"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-history-movement-header">
        <span class="title"> Fila de Atendimento {{ dataForm.type }} </span>
      </div>
      <div slot="body">
        <div class="subtitle">
          Quando for iniciada, a importação
          <strong>não poderá ser cancelada</strong>.
        </div>
        <div class="container-progress-time">
          <div class="body-progress-time">
            <p>Tempo médio em minutos:</p>
            <h1>{{ dataForm.time }}</h1>
            <p>
              Posição na fila:
              {{ dataForm.position }}
            </p>
          </div>

          <Progress
            type="circle"
            :percentage="progressTime"
            :show-text="false"
            :width="250"
            :stroke-width="12.5"
            color="#2B5D8A"
          >
          </Progress>
        </div>
      </div>
      <div slot="footer">
        <div
          v-if="!dataForm.disabledButton"
          class="modal-time-procedure-import-sigtap-bdsia-footer"
        >
          <RgCancelButton
            id="cancel-btn"
            label="Cancelar"
            medium
            :disabled="disableButton"
            :disabledButton="disableButton"
            @click="close"
          />
          <MediumButton
            id="save-btn"
            label="Iniciar Importação"
            medium
            :disabled="disableButton"
            @click="save"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, MediumButton, RgCancelButton } from "~tokio/primitive";
import { Progress } from "element-ui";

export default {
  name: "ModalTimeProcedureSigtapBdsia",
  components: {
    RgBaseModal,
    MediumButton,
    RgCancelButton,
    Progress,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showOld: {
      type: Function,
      default: () => {},
    },
    dataForm: Object,
    initProcedure: Function,
  },
  data() {
    return {
      disableButton: false,
    };
  },
  computed: {
    progressTime() {
      return this.dataForm.position < 1
        ? 0
        : this.dataForm.position === 1
        ? 100
        : 100 - (this.dataForm.position * 100) / this.dataForm.totalQueue;
    },
  },
  watch: {
    "dataForm.type": {
      handler(pValue) {
        if (pValue === "SIGTAP") {
          this.disableButton = false;
        } else {
          this.disableButton = true;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    save() {
      this.disableButton = true;
      this.initProcedure();
    },
    close() {
      this.showOld(0);
    },
  },
};
</script>
