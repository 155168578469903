<template>
  <div v-if="show" class="modal-confirm-patient-unification">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Confirmar Unificação dos Cadastros</div>
      </div>

      <div slot="body" class="body">
        <div class="sub-title">
          A unificação de pacientes não poderá ser desfeita. Faça uma revisão
          dos dados cadastrados antes de finalizar a operação
        </div>

        <Tooltip
          message="Após realizar a unificação os outros cadastros selecionados para a união dos dados serão excluídos permanentemente."
          class="tooltip"
          startOpen
        >
          <IconExclamationHelper slot="icon" hollow />
        </Tooltip>

        <FormBase title="Prévia do Cadastro Unificado">
          <div v-if="dataUnification" class="patient-unification">
            <span>
              Nome: <b>{{ dataUnification.pes_nome }}</b>
            </span>

            <span>
              Data de Nascimento:
              <b>{{ dataUnification.pes_nascimento }}</b>
            </span>

            <span>
              CPF:
              <b>{{
                formatCpf(dataUnification.cpf_numero) || "Não informado"
              }}</b>
            </span>

            <span>
              PIS: <b>{{ dataUnification.pis_numero || "Não informado" }}</b>
            </span>

            <span>
              Nome da Mãe: <b>{{ dataUnification.pes_mae }}</b>
            </span>

            <span>
              Prontuário Único:
              <b>{{
                dataUnification.pac_prontuario_unico || "Não informado"
              }}</b>
            </span>

            <span>
              CNS:
              <b>{{
                formatCns(dataUnification.crs_numero) || "Não informado"
              }}</b>
            </span>

            <span>
              Prontuário da Unidade:
              <b>{{ dataUnification.ppr_numero || "Não informado" }}</b>
            </span>
          </div>
        </FormBase>

        <div class="confirm-checkbox">
          <input v-model="confirmUnify" type="checkbox" />
          Os dados foram devidamente revisados por mim. A unificação está apta
          para ser realizada.
        </div>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton medium @click="close"> </RgCancelButton>
        <LargeButton
          title="Unificar"
          label="Unificar"
          :disabled="!confirmUnify"
          :background-color="'#F96B70'"
          @click="save"
        >
          <IconDanger slot="icon" />
        </LargeButton>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCancelButton,
  LargeButton,
  Tooltip,
  IconExclamationHelper,
  IconDanger,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalPatientCareHistory",
  components: {
    RgBaseModal,
    FormBase,
    RgCancelButton,
    LargeButton,
    Tooltip,
    IconExclamationHelper,
    IconDanger,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Object,
      default: () => {},
    },
    dataUnification: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      confirmUnify: false,
    };
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },

  methods: {
    formatCpf(pValue) {
      return pValue?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/gi, "$1.$2.$3-$4");
    },

    formatCns(pValue) {
      return pValue?.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },

    isPatient(pItem) {
      return pItem?.patient?.pac_id ? pItem.patient.pac_id : 0;
    },

    patientSex(pItem) {
      return pItem?.pes_id_sexos ? pItem.pes_id_sexos : null;
    },

    save() {
      this.$emit("save");
    },

    close() {
      this.confirmUnify = false;
      this.$emit("close");
    },
  },
};
</script>
