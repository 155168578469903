import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamUserPermission($usuId: Int, $setId: Int) {
    searchExamUserPermission(usu_id: $usuId, set_id: $setId) {
      rows {
        epe_id_escala
        eoe_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.searchExamUserPermission.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
