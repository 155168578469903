<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchVacanciesAvailableScale from "./action/SearchVacanciesAvailableScale";

export default {
  name: "RgSelectVacanciesExam",
  extends: RgSelect,
  props: {
    scheduleId: {
      type: [String, Number],
      default: null,
    },
    examDate: {
      type: [String, Number],
      default: null,
    },
    occupiedVacancies: {
      type: [String, Number],
      default: null,
    },
    edition: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "Vagas",
    },
  },

  data() {
    return {
      valuesData: [],
    };
  },

  watch: {
    scheduleId() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      try {
        this.valuesData = [];
        const max = await this.getMax();

        let count = 1;
        while (count <= max) {
          let str = `${count} vagas`;
          if (count === 1) {
            str = `${count} vaga`;
          }
          this.valuesData.push({ value: count.toString(), label: str });
          count++;
        }

        if (this.valuesData.length === 1) {
          this.$emit("selectOnlyItem", this.valuesData[0].value);
        }

        if (max === 0) {
          this.$toaster.info(
            "Realize o agendamento do paciente em outra escala.",
            "A escala selecionada não possui vagas disponíveis",
          );
        }
      } catch (e) {
        this.$toaster.error("Não foi possível carregar a quantidade de vagas");
      }
    },

    async getMax() {
      if (!this.scheduleId || !this.examDate) return null;
      const data = await SearchVacanciesAvailableScale({
        eea_id: this.scheduleId,
        dataExame: this.examDate,
      });

      return this.edition
        ? parseInt(data) + parseInt(this.occupiedVacancies || 0)
        : parseInt(data);
    },
  },
};
</script>
