/**
 * @param {{ graphQLErrors: any[], networkError: any }} pError
 */
const HandleActionError = (pError) => {
  let message = "";

  const hasGraphQLErrors = pError?.graphQLErrors?.length > 0;

  if (hasGraphQLErrors) {
    message = pError.graphQLErrors[0].message;
  }

  const hasNetworkError = pError?.networkError?.result?.errors?.length > 0;

  if (hasNetworkError) {
    message = pError.networkError.result.errors[0].message;
  }

  return { message };
};

module.exports = HandleActionError;
