import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/exames2/controller-exame-escala-agendamento/buscar-profissionais-por-dia",
    variables,
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      data.dados.data = data.dados.recordSet;

      data.dados.data = data.dados.data.map((item) => {
        const validateDisableRow =
          Number(item.funcionario_inabilitado) === 1 ||
          Number(item.eha_atendimento) === 0;

        if (validateDisableRow) {
          item.disabledItem = true;
        }

        return item;
      });

      const all = data.dados.data;

      const list = all.filter((el) => {
        return (
          !el.disabledItem &&
          !(parseInt(el.eea_quantidade_restantes) === 0) &&
          !(parseInt(el.vinculo_procedimento_ativo) === 0) &&
          !(parseInt(el.possuiProcedimento) === 0)
        );
      });

      const processDisabled = all.filter((el) => {
        return parseInt(el.vinculo_procedimento_ativo) === 0;
      });

      const withoutAssociatedProcedure = all.filter((el) => {
        return parseInt(el.possuiProcedimento) === 0;
      });

      const listNoVacancy = all.filter((el) => {
        return !el.disabledItem && parseInt(el.eea_quantidade_restantes) === 0;
      });

      const listDisabled = all.filter((el) => {
        return el.disabledItem;
      });

      const uniqueItems = {};
      const finalList = [];

      const generateUniqueId = (item) =>
        `${item.data_exame}_${item.fun_id}_${item.eea_id}_${item.set_id}`;

      const addUniqueItems = (array) => {
        array.forEach((item) => {
          const uniqueId = generateUniqueId(item);
          if (!uniqueItems[uniqueId]) {
            uniqueItems[uniqueId] = true;
            finalList.push(item);
          }
        });
      };

      addUniqueItems(list);
      addUniqueItems(processDisabled);
      addUniqueItems(withoutAssociatedProcedure);
      addUniqueItems(listNoVacancy);
      addUniqueItems(listDisabled);

      data.dados.data = finalList;
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
