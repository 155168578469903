import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    SearchAllCompetence {
      sigtapRows {
        competence
      }
      bdsiaRows {
        competence
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchAllCompetence || [];
};
