<template>
  <Modulebox
    :granted="hasViewPermission"
    :title="drawTitle"
    class="exam-type-scale"
  >
    <section class="exam-type-scale-body">
      <form class="form-container" @submit.stop.prevent="insertUpdateTypeScale">
        <RgValidatorForm
          ref="validator"
          v-shortkey="['enter']"
          @shortkey.native="() => {}"
        >
          <FormBase title="Tipo de Escala">
            <section class="form-grid">
              <RgInput
                id="type"
                ref="type"
                v-model="form.type"
                label="Tipo"
                placeholder="Digite a tipificação da escala"
                :maxlength="50"
                :rules="{ required: true }"
                :class="{
                  disable: !hasInsertOrUpdatePermission || disabledByModal,
                }"
                :disabled="!hasInsertOrUpdatePermission || disabledByModal"
              />

              <RgInputColor
                id="color"
                v-model="form.color"
                label="Cor"
                class="color"
                :rules="{ required: true }"
                :class="{
                  disable: !hasInsertOrUpdatePermission || disabledByModal,
                }"
                :disabled="!hasInsertOrUpdatePermission || disabledByModal"
              />

              <div class="actions">
                <RgCleanButton
                  v-if="!editMode"
                  id="clear-scale"
                  :permission="hasViewPermission"
                  :disabled="disabledByModal"
                  small
                  title="Limpar"
                  @click="clearForm"
                />
                <RgCancelButton
                  v-if="editMode"
                  id="cancel-edit"
                  :permission="hasViewPermission"
                  :disabled="!selectedRow.ees_id || disabledByModal"
                  medium
                  label="Cancelar"
                  @click="clearForm"
                />

                <RgAddButton
                  v-if="!editMode"
                  id="save-edit-scale"
                  ref="insertBtn"
                  :disabled="disabledByModal"
                  :permission="hasInsertPermission"
                  large
                  @click="insertUpdateTypeScale"
                />
                <RgSaveButton
                  v-if="editMode"
                  id="save-scale"
                  ref="updateBtn"
                  :disabled="disabledByModal"
                  :permission="hasUpdatePermission"
                  large
                  @click="insertUpdateTypeScale"
                />
              </div>
            </section>
          </FormBase>

          <FormBase title="Tipos Cadastrados">
            <section class="form-table">
              <RgList
                ref="rgList"
                v-model="mutableGridList"
                :search-function="search"
                :build-filter="generateFilter"
                :register-per-page="5"
                :max-register="8"
                :disabled="disabledByModal || editMode"
                :class="{
                  disable: editMode,
                }"
                backendLegacy
                class="list"
              >
                <RgTable
                  v-bind="propsRgTable"
                  :disabled="editMode"
                  :class="{
                    disable: editMode,
                  }"
                >
                  <div slot="header" class="my-header">
                    <RgEditButton
                      id="edit-scale"
                      small
                      :disabled="
                        !selectedRow.ees_id || disabledByModal || editMode
                      "
                      :permission="hasUpdatePermission"
                      @click="clickEdit"
                    />
                    <RgLessButton
                      id="remove-scale"
                      backgroundColor="#F96B70"
                      title="Remover"
                      :disabled="
                        !selectedRow.ees_id || disabledByModal || editMode
                      "
                      :permission="hasDeletePermission"
                      @click="clickDelete"
                    />
                  </div>
                  <tr
                    v-for="item in mutableGridList"
                    :key="item.ees_id"
                    slot="rows"
                    class="tr"
                    :class="{
                      selected: selectedRow.ees_id === item.ees_id,
                    }"
                    @click="clickSelectRow(item)"
                  >
                    <td class="data">
                      <IconCircleTable :color="item.ees_cor" />
                    </td>

                    <td class="data text">
                      {{ item.ees_nome }}
                    </td>
                  </tr>
                </RgTable>
              </RgList>
            </section>
          </FormBase>
        </RgValidatorForm>
      </form>
    </section>

    <!-- Deletar tipo de escala -->
    <GenericTypeScale
      v-if="showModalDeletion"
      id="modal-deletion"
      v-bind="propsModalDeletion"
      @close="onCloseModalDeletion"
    >
      <div slot="message" class="modal-message">
        <span class="name">
          {{ selectedRow.ees_nome }} selecionada para exclusão
        </span>
      </div>
      <div slot="footer" class="footer">
        <div class="footer-buttons">
          <RgCancelButton
            id="cancel-btn"
            medium
            @click="onCloseModalDeletion"
          />
          <LargeButton
            id="remove-btn"
            backgroundColor="#F96B70"
            label="Excluir"
            separator
            @click="actionRemove"
          >
            <IconDanger slot="icon" class="icon" />
          </LargeButton>
        </div>
      </div>
    </GenericTypeScale>

    <!-- Cor duplicada -->
    <GenericTypeScale
      v-if="showModalDuplicityColor"
      id="modal-duplicity-color"
      v-bind="propsModalDuplicityColor"
      class="generic-type"
      @close="onCloseModalDuplicityColor"
    >
      <div slot="message" class="modal-message">
        <div class="information">Manter a cor do novo tipo de escala:</div>
        <div class="scale">
          <div class="name">{{ form.type }}</div>
          <div class="square-color" :style="{ backgroundColor: form.color }" />
        </div>
      </div>
      <div slot="footer" class="footer">
        <RgCancelButton
          id="close-duplicity-btn"
          medium
          @click="onCloseModalDuplicityColor"
        />
        <RgConfirmButton
          id="confirm-duplicity-btn"
          medium
          @click="confirmDuplicityColor"
        />
      </div>
    </GenericTypeScale>
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInput,
  RgInputColor,
  RgCleanButton,
  RgSaveButton,
  RgConfirmButton,
  RgEditButton,
  RgLessButton,
  RgCancelButton,
  LargeButton,
  IconDanger,
} from "~tokio/primitive";
import RgList from "~tokio/foundation/rg-list/RgList";

import { RgAddButton } from "~tokio/primitive/button";
import { IconCircleTable } from "~tokio/primitive/icon/symbols";

import FormBase from "~tokio/foundation/form-base/FormBase";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import RgTable from "~tokio/foundation/rg-table/RgTable.vue";
import { mapGetters } from "vuex";

import GenericTypeScale from "../../common/modal/generic-type-scale/GenericTypeScale";

const FORM_BASE = {
  type: null,
  color: "#000000",
};

const COLUMNS_DYNAMIC_TABLE = [
  { name: "Cor", key: "ees_cor" },
  { name: "Tipo de Escala", key: "ees_nome" },
];

export default {
  name: "ExamTypeScale",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgInput,
    RgInputColor,
    RgCleanButton,
    RgAddButton,
    RgTable,
    IconCircleTable,
    RgConfirmButton,
    RgEditButton,
    RgLessButton,
    RgSaveButton,
    GenericTypeScale,
    LargeButton,
    IconDanger,
    RgCancelButton,
    RgList,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      editMode: false,
      mutableGridList: null,
      selectedRow: { ees_id: null, ees_cor: null },
      showModalDeletion: false,
      showModalDuplicityColor: false,
      numberDuplicityColor: 0,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    propsRgTable() {
      return {
        columns: COLUMNS_DYNAMIC_TABLE,
      };
    },

    propsToggleBtn() {
      return {
        truthyText: "ATIVO",
        falsyText: "INATIVO",
        width: 70,
        fontSize: 10,
        center: true,
      };
    },

    propsModalDeletion() {
      const show = this.showModalDeletion;
      const title = "Excluir o tipo de escala selecionado";
      const subtitle = `A operação não poderá ser desfeita`;

      return {
        show,
        title,
        subtitle,
      };
    },

    propsModalDuplicityColor() {
      const show = this.showModalDuplicityColor;
      const title = "A cor selecionada está em uso";
      const subtitle = `Outros tipos de escala utilizam a mesma cor`;

      return {
        show,
        title,
        subtitle,
      };
    },

    drawTitle() {
      return "Tipos de Escala";
    },

    hasViewPermission() {
      const hasViewPermission = this.$Permissions.global.has(
        "exame2.examesEscala.exibir",
        this.unitHealthId,
      );

      return (
        hasViewPermission ||
        this.hasInsertPermission ||
        this.hasUpdatePermission ||
        this.hasDeletePermission
      );
    },

    hasInsertOrUpdatePermission() {
      return (
        this.hasInsertPermission || (this.hasUpdatePermission && this.editMode)
      );
    },

    hasInsertPermission() {
      return this.$Permissions.global.has(
        "exame2.examesEscala.incluir",
        this.unitHealthId,
      );
    },

    hasUpdatePermission() {
      return this.$Permissions.global.has(
        "exame2.examesEscala.alterar",
        this.unitHealthId,
      );
    },

    hasDeletePermission() {
      return this.$Permissions.global.has(
        "exame2.examesEscala.excluir",
        this.unitHealthId,
      );
    },

    disabledByModal() {
      return this.showModalDeletion || this.showModalDuplicityColor;
    },
  },

  watch: {
    selectedRow(pItem, pPreviously) {
      if (this.editMode && pItem !== pPreviously) {
        this.form.type = pItem.ees_nome;
        this.form.color = pItem.ees_cor;
      }
    },
  },

  async mounted() {
    if (this.hasViewPermission) {
      this.$loader.start("Carregando...");
      await this.$refs.rgList.submitForm(true);
      this.$loader.finish();
    }
  },

  methods: {
    /*
     * AÇÕES DE MODAL
     */
    openModalDeletion() {
      this.showModalDeletion = true;
    },
    onCloseModalDeletion() {
      this.showModalDeletion = false;
    },

    onCloseModalDuplicityColor() {
      this.showModalDuplicityColor = false;
    },
    confirmDuplicityColor() {
      this.showModalDuplicityColor = false;
      this.insertUpdateTypeScale(false);
    },

    /*
     * AÇÕES DOS BTN
     */
    clearForm() {
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);
      this.selectedRow = { ees_id: null, ees_cor: null };
      this.editMode = false;
      this.$refs.type.cleanValidate();
    },

    async insertUpdateTypeScale(isCheckDuplicityColor = true) {
      const isInvalid = !(await this.isFormValid());

      if (isInvalid) {
        this.$toaster.warning("Por favor, verifique os campos");
        this.stopLoadingUpdateBtn();
        return;
      }

      const checkChangeColor = this.selectedRow.ees_cor !== this.form.color;
      const checkChangeName = this.selectedRow.ees_nome !== this.form.type;

      if (checkChangeColor && !checkChangeName) {
        if (isCheckDuplicityColor) {
          this.numberDuplicityColor = await this.checkDuplicity();
          if (parseInt(this.numberDuplicityColor) >= 1) {
            this.showModalDuplicityColor = true;
            this.stopLoadingUpdateBtn();
            return;
          }
        }
      }

      const ret = this.editMode
        ? await this.actionUpdate()
        : await this.actionSave();

      if (this.checkException(ret)) {
        this.stopLoadingUpdateBtn();
        return;
      }

      const msg = this.editMode
        ? "Registro atualizado com sucesso."
        : "Novo registro cadastrado com sucesso.";

      this.$toaster.success(msg);

      this.clearForm();
      await this.$refs.rgList.submitForm();

      this.stopLoadingUpdateBtn();
    },

    /*
     * AÇÕES DO GRID
     */
    clickEdit() {
      if (this.selectedRow.ees_id) {
        if (!this.editMode) {
          this.editMode = true;
          this.form.type = this.selectedRow.ees_nome;
          this.form.color = this.selectedRow.ees_cor;
        }
        return;
      }

      if (this.editMode) {
        this.$toaster.warning(
          "Por favor, selecione um tipo de escala para editar",
        );
      }

      this.editMode = false;
    },

    async clickDelete() {
      if (this.selectedRow.ees_id) {
        this.openModalDeletion();
        return;
      }

      this.editMode = false;
      this.$toaster.warning(
        "Por favor, selecione um tipo de escala para remover",
      );
    },

    clickSelectRow(item) {
      if (item.ees_id === this.selectedRow.ees_id) {
        this.editMode = false;
        this.clearForm();
        this.selectedRow = { ees_id: null, ees_cor: null };
        return;
      }
      this.selectedRow = item;
    },

    /*
     * BANCO DE DADOS
     */

    search(pData) {
      return this.$store.dispatch("Exam/Register/TYPE_SCALE_SEARCH", {
        ...pData,
      });
    },

    generateFilter() {
      const variables = {
        arrFiltros: {
          ees_cor: null,
          ees_id: 0,
        },
      };
      return variables;
    },

    async checkDuplicity() {
      try {
        this.$loader.start("Carregando lista de tipos de escala.");
        const lessEesId = true;
        return await this.$store.dispatch(
          "Exam/Register/TYPE_SCALE_CHECK_DUPLICITY",
          this.getArrFormData(lessEesId),
        );
      } catch (e) {
        this.$toaster.error("Erro inesperado ao carregar a lista");
        console.error(e);
        return false;
      } finally {
        this.$loader.finish();
      }
    },

    async actionSave() {
      try {
        this.$loader.start("Criando novo tipo de escala");
        const lessEesId = true;
        return await this.$store.dispatch(
          "Exam/Register/TYPE_SCALE_REGISTER",
          this.getArrFormData(lessEesId),
        );
      } catch (e) {
        this.$toaster.error("Erro inesperado ao criar novo tipo de escala");
        console.error(e);
        return false;
      } finally {
        this.$loader.finish();
      }
    },

    async actionUpdate() {
      try {
        this.$loader.start("Atualizando tipo de escala.");
        return await this.$store.dispatch(
          "Exam/Register/TYPE_SCALE_UPDATE",
          this.getArrFormData(),
        );
      } catch (e) {
        this.$toaster.error("Erro inesperado ao atualizar tipo de escala");
        console.error(e);
        return false;
      } finally {
        this.$loader.finish();
      }
    },

    async actionRemove() {
      try {
        this.$loader.start("Removendo tipo de escala.");
        const snap = await this.$store.dispatch(
          "Exam/Register/TYPE_SCALE_DELETE",
          {
            intIdExameEscala: this.selectedRow.ees_id,
          },
        );
        if (snap === "AdoUnableDeleteEntryException") {
          this.$toaster.warning(
            "Não foi possível remover. Este registro está vinculado com outros registros no sistema.",
          );
          return;
        }
        this.$toaster.success("Registro removido com sucesso");
        this.clearForm();
        await this.$refs.rgList.submitForm();
      } catch (e) {
        this.$toaster.error("Erro inesperado ao atualizar tipo de escala");
        console.error(e);
        return false;
      } finally {
        this.onCloseModalDeletion();
        this.$loader.finish();
      }
    },

    /*
     * OUTROS
     */
    checkException(snap) {
      if (snap === "DuplicidadeExameEscalaException") {
        this.$toaster.warning(
          "Já existe um tipo de escala cadastrado com essa mesma nomenclatura",
        );
        return true;
      }

      if (snap === "DisplayException" && this.editMode) {
        this.$toaster.warning(
          "Não é possível alterar o tipo de escala selecionado pois ele já está sendo utilizado no sistema",
        );
        return true;
      }
      return false;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    getArrFormData(lessEesId = false) {
      const obj = {
        arrFormData: {
          ees_cor: this.form.color
            .replace("#", "0x")
            .replace("0x000000", "0x0"),
          ees_nome: this.form.type,
        },
      };
      if (!lessEesId) {
        obj.arrFormData.ees_id = this.selectedRow.ees_id;
      }
      return obj;
    },

    stopLoadingUpdateBtn() {
      if (this.editMode) {
        this.$refs.updateBtn.actionDone();
      }
    },
  },
};
</script>
