import actions from "./actions";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";
import Report from "$unit-health/submodules/report/store";

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
  modules: {
    Report,
  },
};
