<template>
  <ModuleBox
    :title="
      hospitalization_id && !isFromMapBed
        ? 'Editar Internação'
        : 'Cadastrar Internação'
    "
    class="hospitalization-details"
  >
    <QuickHospitalizationDetails
      ref="hospDetails"
      class="hospitalization-details-quick"
      :fromMapBed="isFromMapBed"
      :fromSolicitation="isFromSolicitation"
      :operation="operation"
      @disabledByModalComponents="disabledByModalComponents"
      @blockedPatient="enableDisableSave($event)"
      @close="goBack"
    />

    <div class="footer">
      <RgCleanButton
        v-show="!hospitalization_id"
        id="clean-btn"
        small
        type="button"
        title="Limpar"
        class="buttons"
        :disabled="disabledByModal"
        @click="clearForm"
      />

      <RgCancelButton
        id="cancel-btn"
        medium
        class="buttons"
        type="button"
        title="Cancelar"
        :disabled="disabledByModal"
        @click="goBack"
      />

      <RgSaveButton
        id="save-btn"
        :class="{ disable: isSaving || isBlocked }"
        :disabled="isSaving || isBlocked || disabledByModal"
        medium
        class="buttons"
        type="button"
        @click="saveForm"
      />
    </div>
  </ModuleBox>
</template>

<script>
import { QuickHospitalizationDetails } from "../../component";
import { RgCleanButton, RgSaveButton, RgCancelButton } from "~tokio/primitive";
import ModuleBox from "~tokio/foundation/modulebox/Modulebox";

export default {
  name: "HospitalizationDetails",
  components: {
    QuickHospitalizationDetails,
    ModuleBox,
    RgCleanButton,
    RgSaveButton,
    RgCancelButton,
  },
  data() {
    return {
      hospitalization_id: null,
      patient_id: null,
      hospitalizationData: {},
      isSaving: false,
      isFromMapBed: false,
      isFromSolicitation: false,
      operation: 0,
      isBlocked: false,
      disabledByModal: false,
    };
  },

  created() {
    const mapBedData = this.$store.getters[
      "Hospitalization/Hospitalizations/GET_HOSPITALIZATION_INFO"
    ];

    this.isFromMapBed = this.$route.params.fromMapBed || mapBedData?.fromMapBed;
    this.operation =
      this.$route.params.operation || mapBedData?.fromMapBedOperation;
  },
  mounted() {
    let pData = this.$route.params.form;

    if (!pData) {
      pData = this.$store.getters[
        "Hospitalization/Hospitalizations/GET_HOSPITALIZATION_INFO"
      ];
    }

    this.isFromSolicitation =
      this.$route.name ===
      "hospitalization.hospitalization-solicitation.register";

    this.mountPage(pData);
  },
  methods: {
    disabledByModalComponents(disabledByModalComponent) {
      this.disabledByModal = disabledByModalComponent;
    },

    mountPage(pData) {
      if (pData.int_id) {
        this.hospitalization_id = pData.int_id;
      }
    },

    async saveForm() {
      this.isSaving = true;
      await this.$refs.hospDetails.saveForm();
      this.isSaving = false;
    },

    enableDisableSave(pValue) {
      this.isBlocked = pValue;
    },

    clearForm() {
      this.$refs.hospDetails.confirmCleanForm();
      this.hospitalization_id = null;
      this.$store.commit(
        "Hospitalization/Hospitalizations/RESET_HOSPITALIZATION_INFO",
      );
    },

    goBack() {
      const routeName = this.$route.name;

      if (routeName === "hospitalization.bed.search.new-hospitalization") {
        this.$router.push("/hospitalization/bed/search");
      }

      if (
        routeName === "hospitalization.hospitalization-solicitation.register"
      ) {
        this.$router.push(
          "/hospitalization/hospitalization-solicitation/search",
        );
      }

      if (
        routeName === "hospitalization.hospitalizations.search.details" ||
        routeName === "hospitalization.hospitalizations.edit-details" ||
        routeName === "hospitalization.hospitalizations.details"
      ) {
        this.$router.push("/hospitalization/hospitalizations/search");
      }
    },
  },
};
</script>
