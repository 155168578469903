<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import getData from "./action";
export default {
  name: "RgComboboxTransferSector",
  mixins: [RgComboboxMixin],
  props: {
    uns_id: {
      required: true,
    },
    subModuleId: {
      required: true,
    },
  },
  watch: {
    intIdUnidade() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (!this.uns_id) return Promise.resolve([]);

      this.mData = [];

      this.disableLoading = true;
      const data = await getData({
        intIdUnidade: this.uns_id,
        intIdSubModulo: this.subModuleId,
      });

      data.forEach((item) => {
        this.mData.push({ item: item, value: item.data, text: item.label });
      });
      this.disableLoading = false;
    },
  },
};
</script>
