import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const duplicationErrors = {
  BloquearDuplicidadeAoInserirFilaInternacaoException: `Paciente já consta na fila para esse tipo de leito.`,
  InformarDuplicidadeAoInserirFilaInternacaoException: `Aviso: paciente já consta na fila para esse tipo de leito`,
};

export default async (context, { variables, validarDuplicidade }) => {
  const sendData = {
    arrFormData: variables,
    validarDuplicidade: validarDuplicidade,
  };

  return AuthLegacyRequest.post(
    "/internacoes/controller-fila-internacao/adicionar",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data, {
        extraExceptionsErrors: duplicationErrors,
      });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
