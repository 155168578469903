<style src="./RgSuggestState.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-state">
    <RgSuggest
      :id="id"
      ref="rgSuggest"
      v-model="inputValue"
      :label="label"
      :title="title"
      :placeholder="placeholder"
      :search-on-focus="searchOnFocus"
      :disabled="disabled"
      :rules="rules"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="state-item"
      >
        <div class="state-row-top">
          <span class="state-sigla">{{ item.est_sigla }}</span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import SearchState from "./action/SearchState";

export default {
  name: "RgSuggestState",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "UF",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "UF",
    },
    title: {
      type: String,
      default: "Unidade da Federação",
    },
  },
  data() {
    return {
      captionRaw: "est_sigla",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.rgSuggest.validate();
    },

    doSearch(pSearchTerm, pLimit = null) {
      return SearchState({ ufSigla: pSearchTerm, limit: pLimit });
    },

    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },
  },
};
</script>
