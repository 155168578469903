import { render, staticRenderFns } from "./AppointmentProfessionalProductivityReport.vue?vue&type=template&id=4942d163&scoped=true&"
import script from "./AppointmentProfessionalProductivityReport.vue?vue&type=script&lang=js&"
export * from "./AppointmentProfessionalProductivityReport.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentProfessionalProductivityReport.scss?vue&type=style&index=0&id=4942d163&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4942d163",
  null
  
)

export default component.exports