import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";
const arrayExceptions = {
  DisplayException: `Não foi possivel excluir o Setor. Possíveis causas :<br><br>Permissões vinculadas ao setor. <br>Local de Atendimento vinculado ao setor. <br>Telefones cadastrados para o setor.`,
};
export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-setor/excluir",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
