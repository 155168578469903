import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
const query = gql`
  query SearchExamDayReport(
    $initialDate: String
    $finalDate: String
    $uns_id: Int
    $set_id: Int
    $set_nome: String
    $uns_nome: String
    $pes_id: Int
    $pes_nome: String
    $fromQueue: Int
    $situation: Int
    $emn_id: Int
    $stp_id: Int
    $stp_novo_nome_procedimento: String
    $eas_nome_sub_procedimentos: String
    $orderby: Int
    $orderByLabel: String
    $defaultPrintTable: Boolean
    $concatSchedule: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchExamDayReport(
      initialDate: $initialDate
      finalDate: $finalDate
      uns_id: $uns_id
      uns_nome: $uns_nome
      set_id: $set_id
      set_nome: $set_nome
      pes_id: $pes_id
      pes_nome: $pes_nome
      fromQueue: $fromQueue
      situation: $situation
      emn_id: $emn_id
      stp_id: $stp_id
      stp_novo_nome_procedimento: $stp_novo_nome_procedimento
      eas_nome_sub_procedimentos: $eas_nome_sub_procedimentos
      orderby: $orderby
      orderByLabel: $orderByLabel
      defaultPrintTable: $defaultPrintTable
      concatSchedule: $concatSchedule
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        DATA_MARCACAO
        PRONTUARIO_UNICO
        PRONTUARIO_UNIDADE
        PACIENTE
        SEXO
        IDADE
        CNS_OU_CPF
        PROCEDIMENTO
        SUB_PROCEDIMENTO
        PROFISSIONAL_DE_SAUDE
        FILA
        SITUACAO
        MOTIVO_NAO_COMPARECIMENTO
        MARCACAO
        TELEFONES
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamDayReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
