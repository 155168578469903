import { render, staticRenderFns } from "./RgComboboxMixin.vue?vue&type=template&id=37a37e9e&scoped=true&"
import script from "./RgComboboxMixin.vue?vue&type=script&lang=js&"
export * from "./RgComboboxMixin.vue?vue&type=script&lang=js&"
import style0 from "./RgComboboxMixin.scss?vue&type=style&index=0&id=37a37e9e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a37e9e",
  null
  
)

export default component.exports