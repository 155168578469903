<template>
  <div class="pharmacy-central-report-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealthPharmacy
          id="unit-health"
          ref="unitHealth"
          v-model="form.unitHealth"
          openOnlyBottom
          class="input"
          label="Unidade de Saúde"
          :typePharmacy="1"
          :rules="{ required: true }"
          @change="selectedUnitHealth"
        />

        <RgSelectPharmacy
          v-model="form.pharmacy"
          :unitHealthId="Number(form.unitHealth)"
          :typePharmacy="hasBothPharmacy"
          :viewPharmacyType="hasBothPharmacy === 0"
          default-text="Selecione"
          openOnlyBottom
          byUser
          class="input"
          :disabled="!form.unitHealth || hasPharmacyOriginDestiny"
          :class="{ disable: !form.unitHealth || hasPharmacyOriginDestiny }"
          :userId="userId"
          :rules="
            hasNotRules
              ? { required: false }
              : { required: !hasPharmacyOriginDestiny }
          "
          @change="selectedPharmacy"
        />
        <RgSelectProduct
          v-model="form.product"
          :pharmacyId="Number(form.pharmacy)"
          default-text="Selecione"
          openOnlyBottom
          class="input"
          perClient
          blnMpdBloqueado
          @change="selectedProduct"
        />
      </div>
      <div class="filter-title">
        <span class="title">Período</span>
      </div>
      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-pcr"
          ref="initialDate"
          v-model="form.initialDate"
          :disabled="hasPeriod"
          :class="{ disable: hasPeriod }"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />

        <RgInputDate
          id="final-date-pcr"
          ref="finalDate"
          v-model="form.finalDate"
          :disabled="hasPeriod"
          :class="{ disable: hasPeriod }"
          :rules="{ required: true, fn: validateFinalDate }"
          label="Data Final"
          class="inputitem"
          :min-date="getMinDateInitial"
          :max-date="getMaxDateInitial"
        />
      </div>
      <div v-if="isReportStock" class="tooltip-stock">
        <Tooltip class="tooltip" clickable startOpen>
          <IconInfoHelper slot="icon" hollow />
          <div slot="content" class="content">
            <div class="title">
              <span>Condições dos resultados na:</span>
              <ul class="list">
                <li class="item">
                  Coluna Estoque Inicial: quantidade de produtos armazenados no
                  horário de 00:00 da data inicial;
                </li>
                <li class="item">
                  Coluna Estoque Final: quantidade de produtos armazenados até o
                  horário de 23:59 da data final;
                </li>
                <li class="item">
                  Coluna Estoque Atual: quantidade de produtos disponível
                  atualmente (independente dos filtros de data).
                </li>
              </ul>
            </div>
          </div>
        </Tooltip>
      </div>

      <div v-if="idSubReport" class="filter-title">
        <span class="title">Dados do Relatório Selecionado</span>
      </div>
      <div slot="filters">
        <!-- <keep-alive> -->
        <component
          :is="component"
          ref="SubReport"
          class="component"
          :global-filters="form"
          :columns-table="generateColumns"
          :search-report="doSearch"
          :columnsToPrint="columnsToPrint"
        />
        <!-- </keep-alive> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgInputDate,
  RgSuggestSmartPerson,
  RgInput,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import { mapGetters } from "vuex";
import moment from "moment";

import { LIST_REPORT_PHARMACY_CENTRAL } from "$report/view/ListReportsPharmacy";

const FORM_FILTER = {
  unitHealth: null,
  unitHealthName: null,
  pharmacy: null,
  pharmacyName: null,
  product: null,
  productName: null,
  initialDate: moment().add(-90, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
};

export default {
  name: "PharmacyCentralReport",
  components: {
    RgInput,
    RgInputDate,
    RgSuggestSmartPerson,
    LIST_REPORT_PHARMACY_CENTRAL,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectUnithealthPharmacy,
    Tooltip,
    IconInfoHelper,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    idSubReport: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      resultTitle: "Medicamentos Dispensados para Pacientes",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      component: null,
      columnsTable: [],
      idSubReportNumber: null,
    };
  },

  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
    }),
    hasPharmacyOriginDestiny() {
      const options = [
        this.PHARMACY_CENTRAL_TRANSFER_REPORT,
        this.PHARMACY_CENTRAL_REVERSAL_REPORT,
        this.PHARMACY_CENTRAL_TOTAL_CONSUMPTION_REPORT,
        this.PHARMACY_CENTRAL_REQUESTS_REPORT,
        this.PHARMACY_CENTRAL_REQUESTS_TRANSFER_REPORT,
      ];
      return options.includes(this.idSubReportNumber);
    },
    isReportStock() {
      return this.idSubReportNumber === this.PHARMACY_CENTRAL_STOCK_PRODUCT;
    },
    hasPeriod() {
      const options = [
        this.PHARMACY_CENTRAL_AVERAGE_CONSUMPTION_REPORT,
        this.PHARMACY_CENTRAL_BATCH_TRACEABILITY,
        this.PHARMACY_CENTRAL_END_FORECAST,
      ];
      return options.includes(this.idSubReportNumber);
    },
    hasNotRules() {
      const options = [this.PHARMACY_CENTRAL_BATCH_TRACEABILITY];
      return options.includes(this.idSubReportNumber);
    },
    hasBothPharmacy() {
      const options = [this.PHARMACY_CENTRAL_BATCH_TRACEABILITY];
      return options.includes(this.idSubReportNumber) ? 0 : 1;
    },
    getNameSmartTable() {
      return "PharmacyCentralReport";
    },
    columnTable() {
      return this.columnsTable;
    },
    getMinDateInitial() {
      const date = this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
      return date;
    },
    getMaxDateInitial() {
      const date = this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
      return date;
    },
    printReportCss() {
      let extraCollumns = "";
      if (
        this.idSubReportNumber ===
        this.PHARMACY_CENTRAL_REQUESTS_TRANSFER_REPORT
      ) {
        extraCollumns =
          ".report-column-16 { white-space: normal !important; text-align: left; word-break: break-word; }" +
          ".report-column-17 { white-space: normal !important; text-align: left; word-break: break-word; }" +
          ".report-column-18 { white-space: normal !important; text-align: left; word-break: break-word; }";
      }
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-2 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-5 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-6 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-7 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-8 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-9 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-10 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-11 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-11 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-12 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-13 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-14 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        ".report-column-15 { white-space: normal !important; text-align: left; word-break: break-word; }" +
        extraCollumns
      );
    },
  },
  watch: {
    "form.unitHealth"(pValue) {
      if (!pValue) {
        this.form.pharmacy = null;
      }
    },
    "form.patientName"(pValue) {
      pValue && pValue.length > 0
        ? (this.disabledDocument = true)
        : (this.disabledDocument = false);
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },

    idSubReport(pValue) {
      if (pValue !== null) {
        this.component = LIST_REPORT_PHARMACY_CENTRAL.filter((item) => {
          return item.id === pValue;
        })[0].value;
        this.idSubReportNumber = pValue;
        this.clearFilter();
      } else {
        this.component = null;
        this.generateColumns([]);
        this.$toaster.warning(
          "Os filtros estão localizados abaixo do título 'Relatórios'.",
          "Selecione um dos Filtros para exibir os resultados",
        );
      }
    },
  },
  mounted() {
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.form.unitHealthName = this.$store.getters[
      "Login/GET_UNIT_HEALTH_NAME"
    ];
  },
  created() {
    this.PHARMACY_CENTRAL_TRANSFER_REPORT = 12;
    this.PHARMACY_CENTRAL_REVERSAL_REPORT = 6;
    this.PHARMACY_CENTRAL_TOTAL_CONSUMPTION_REPORT = 8;
    this.PHARMACY_CENTRAL_AVERAGE_CONSUMPTION_REPORT = 7;
    this.PHARMACY_CENTRAL_REQUESTS_TRANSFER_REPORT = 13;
    this.PHARMACY_CENTRAL_REQUESTS_REPORT = 14;
    this.PHARMACY_CENTRAL_BATCH_TRACEABILITY = 9;
    this.PHARMACY_CENTRAL_END_FORECAST = 11;
    this.PHARMACY_CENTRAL_STOCK_PRODUCT = 3;
  },
  methods: {
    buildFilter() {},
    doSearch() {},

    generateColumns(pValue) {
      this.columnsTable = this.component ? pValue : [];
    },
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final.");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial.");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.unitHealthName = this.$store.getters[
        "Login/GET_UNIT_HEALTH_NAME"
      ];
      if (this.$refs.SubReport) this.$refs.SubReport.clearFilter();
    },

    selectedUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.unitHealthName = pValue.label;
      } else {
        this.form.unitHealthName = "";
      }
    },

    selectedProduct(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.productName = pValue.label;
      } else {
        this.form.productName = "";
      }
    },

    selectedPharmacy(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.pharmacyName = pValue.label;
      } else {
        this.form.pharmacyName = "";
      }
    },
  },
};
</script>
