import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($unitHealthId: String, $userId: Int, $cmp: String) {
    SearchHistoryExportBilling(
      idUns: $unitHealthId
      userId: $userId
      cmp: $cmp
    ) {
      rows {
        fex_data_hora
        fex_arquivo_gerado
        fex_competencia
        fex_cnes
        fex_quantidade_folha
        fex_inicio_bpac
        fex_inicio_bpai
        uns_nome
        format_date
        usu_nome
        fex_tipo_arquivo
      }
    }
  }
`;

/* fex_data_inicio_exportacao
fex_data_final_exportacao */

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  const newList = data.SearchHistoryExportBilling.rows.map((element) => {
    switch (element.fex_tipo_arquivo) {
      case "1-nbpai":
        element.fex_tipo_arquivo = "BPA-I";
        break;
      case "2-nbpac":
        element.fex_tipo_arquivo = "BPA-C";
        break;
      case "3-todos":
        element.fex_tipo_arquivo = "TODOS";
        break;
    }
    return element;
  });

  return newList;
};
