import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import AppointmentScaleRegister from "$appointment/submodules/register/views/appointment-scale-register/AppointmentScaleRegister";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/appointment/register/scale/search/update",
  name: "appointment.register.scale.search.update",
  components: {
    default: AppointmentScaleRegister,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Cadastro", link: OpenSubModule("appointment") },
      {
        label: "Escalas de Atendimento",
        link: "/appointment/register/scale/search",
      },
      {
        label: "Editar Escala",
        link: "/appointment/register/scale/search/update",
      },
    ],
  },
};
