<style src="./SurgeryMapFilter.scss" lang="scss" scoped></style>
<template lang="html">
  <section>
    <form @submit.prevent="searchMap">
      <section slot="content" class="surgery-map-filter">
        <div class="row">
          <RgSelectUnithealth
            v-model="filter.uns_id"
            permission="centro_cirurgico"
            :rules="{ required: true }"
            class="col-3 combobox-row"
            label="Unidade de Saúde"
          />
          <RgComboboxSector
            v-model="filter.set_id"
            :unit-health="filter.uns_id"
            default-text="Todos"
            class="col-3 combobox-row"
            label="Centro Cirúrgico"
          />
          <RgComboboxPlace
            v-model="filter.lca_id"
            :sector="filter.set_id"
            empty-default-text="Selecione um centro cirúrgico"
            default-text="Todos"
            class="col-3 combobox-row"
            label="Sala"
          />
          <RgComboboxRequestingClinician
            v-model="filter.inc_id"
            default-text="Todas"
            class="col-3"
          />

          <RgInputDate
            v-model="filter.initialDate"
            :rules="{ required: true }"
            label="Data Inicial"
            class="col-2"
          />
          <RgInputDate
            v-model="filter.finalDate"
            :rules="{ required: true }"
            label="Data Final"
            class="col-2"
          />
          <RgInputHour
            v-model="filter.hourStart"
            class="col-2"
            label="Hora início"
          />
          <RgInputHour
            v-model="filter.hourEnd"
            class="col-2"
            label="Hora final"
          />
          <div class="container--print-icon col-4">
            <button
              type="button"
              class="col-4 action-buttons"
              @click="searchMap"
            >
              Gerar Mapa
            </button>
            <button
              type="button"
              class="col-4 action-buttons"
              @click="printMap('/surgery-center/print-map')"
            >
              <span class="button">Imprimir</span>
              <div
                v-if="printCalculate"
                class="rg-spinner rg-spinner__medium"
              />
            </button>
          </div>
        </div>
        <RgIframe v-if="showPrintMap" :src="printLink" class="iframe-hidden" />
      </section>
      <div slot="command" />
    </form>
  </section>
</template>

<script>
import {
  RgInputDate,
  RgInputHour,
  RgValidatorForm,
  RgSelectUnithealth,
} from "~tokio/primitive";
import SurgeryControlModel from "$surgery-center/surgery/model/SurgeryControlModel";
import RgIframe from "~tokio/foundation/rg-iframe/RgIframe";
import {
  RgComboboxSector,
  RgComboboxPlace,
} from "$surgery-center/unit-health/component";
import RgComboboxRequestingClinician from "$surgery-center/surgery/component/combobox/rg-combobox-requesting-clinician/RgComboboxRequestingClinician";

export default {
  name: "SurgeryMapFilter",
  components: {
    RgInputDate,
    RgInputHour,
    RgIframe,
    RgComboboxRequestingClinician,
    RgComboboxSector,
    RgComboboxPlace,
    RgSelectUnithealth,
  },
  extends: { ...RgValidatorForm },
  data() {
    return {
      filter: {
        initialDate: "",
        finalDate: "",
        hourStart: "",
        hourEnd: "",
        set_id: null,
        uns_id: null,
        lca_id: null,
        inc_id: null,
      },
      showPrintMap: false,
      linkTo: null,
      hashRefresh: "123",
      printCalculate: false,
      searchCalculate: false,
    };
  },
  computed: {
    printLink() {
      const formatedFilter = this.formatFilters(this.filter);
      const completeFilter = Object.assign({}, formatedFilter);
      const FILTER = JSON.stringify(completeFilter);
      const AUX = `${this.linkTo}?filter=${FILTER}&${this.hashRefresh}`;
      return encodeURI(AUX);
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.showPrintMap = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.filter.initialDate = this.$utils.date.BrazilianDateFormat();
    this.filter.finalDate = this.$utils.date.BrazilianDateFormat();
    this.filter.hourStart = "00:00";
    this.filter.hourEnd = "23:59";
    this.filter.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    performSearch() {
      return new Promise((resolve, reject) => {
        if (this.validate()) {
          SurgeryControlModel.getSurgeryMap(this.formatFilters(this.filter))
            .then((pRes) => {
              resolve(pRes);
            })
            .catch((pErr) => {
              reject(new Error("Nenhum Mapa Cirurgico Encontrado"));
              this.$toaster.error(
                "Nenhum mapa cirúrgico encontrado para este período",
                "Busca falhou...",
              );
            });
        } else {
          reject(new Error("Campos Inválidos"));
          this.$toaster.error(
            "Os campos do filtro precisam estar preenchidos corretamente",
            "Busca falhou...",
          );
        }
      });
    },
    searchMap() {
      this.searchCalculate = true;
      this.performSearch()
        .then((pRes) => {
          this.searchCalculate = false;
          this.$emit("map-data", pRes);
        })
        .catch((pErr) => {
          this.$emit("map-data", "");
        });
    },
    formatFilters(pFilter) {
      if (pFilter) {
        const newFilters = {
          initial_date: pFilter.initialDate
            ? this.$utils.date.BrazilianDateToDatabase(pFilter.initialDate)
            : "",
          final_date: pFilter.finalDate
            ? this.$utils.date.BrazilianDateToDatabase(pFilter.finalDate)
            : "",
          initial_hour: pFilter.hourStart ? pFilter.hourStart + ":00" : "",
          final_hour: pFilter.hourEnd ? pFilter.hourEnd + ":00" : "",
          cci_id_status_controles_cirurgicos: 2,
          uns_id: pFilter.uns_id,
          set_id: pFilter.set_id,
          lca_id: pFilter.lca_id,
          inc_id: pFilter.inc_id,
        };

        return newFilters;
      }
    },
    printMap(pDoc) {
      this.printCalculate = true;
      this.linkTo = pDoc;
      this.hashRefresh = new Date().getTime();
      this.showPrintMap = true;
      setTimeout(() => {
        this.printCalculate = false;
      }, 2000);
    },
  },
};
</script>
