<template lang="html">
  <div v-if="show" class="modal-observation-schedule">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      class="rg-base-modal"
      with-close-button
      :show="show"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-observation-schedule-header">
        <div class="title">Observações do Agendamento</div>
      </div>

      <div slot="body" class="modal-observation-schedule-body">
        <FormBase title="Paciente" class="form">
          <div class="container-data">
            <span class="content">
              Paciente:
              <strong>
                {{ externallyScheduleData.pes_nome }}
              </strong>
            </span>

            <span class="content">
              Destino:
              <strong>
                {{ externallyScheduleData.lex_nome }}
              </strong>
            </span>
          </div>
          <RgTextArea
            id="observation"
            ref="textArea"
            v-model="form.observation"
            disabled
            :maxlength="500"
            label="Observações"
            :viewCharacters="false"
            class="textarea"
          />
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgTextArea } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalObservationSchedule",
  components: {
    RgBaseModal,
    RgTextArea,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    externallyScheduleData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        observation: null,
      },
    };
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.form.observation = this.externallyScheduleData.aex_observacao;
      }
    },
  },

  methods: {
    close() {
      this.form = {
        observation: null,
      };
      this.$emit("close");
    },
  },
};
</script>
