import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacySatelliteDemandProductReport(
    $unitHealthId: Int
    $unitHealthName: String
    $pharmacyId: Int
    $pharmacyName: String
    $productId: Int
    $productName: String
    $productTypeId: Int
    $productTypeName: String
    $initialDate: String
    $finalDate: String
    $loggedUserId: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacySatelliteDemandProductReport(
      unitHealthId: $unitHealthId
      unitHealthName: $unitHealthName
      pharmacyId: $pharmacyId
      pharmacyName: $pharmacyName
      productId: $productId
      productName: $productName
      productTypeId: $productTypeId
      productTypeName: $productTypeName
      initialDate: $initialDate
      finalDate: $finalDate
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        PRODUTO
        NOME_COMERCIAL
        UNIDADE_SAUDE
        FARMACIA
        APRESENTACAO
        TIPO
        QUANTIDADE_SOLICITADA
        QUANTIDADE_DISPENSADA
        DIFERENCA
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacySatelliteDemandProductReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
