<template>
  <div class="quick-appointment-schedule-form-per-day">
    <form class="form-container" @submit.stop.prevent="saveForm">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="module-patient">
          <div class="content">
            <RgSuggestPatientInformation
              id="patient"
              ref="patient"
              v-model="suggestPatient"
              :with-patient-module="['appointment']"
              :enabled-patient="false"
              :rules="{ forceSelection: true, required: true }"
              :disabledOnlySuggest="disabledPatientForm || isEditing"
              :extra-data="['withoutAddress']"
              :withPatientAppointment="true"
              class="inputpatient"
              @edit="editPatient"
              @patientInfo="getPatientData"
              @blockedPatient="blockedPatient($event)"
            />
          </div>

          <Tooltip class="tooltip" startOpen :message="messageTooltip">
            <IconExclamationHelper slot="icon" />
          </Tooltip>

          <div class="documents">
            <RgInputCns
              id="select-cns"
              v-model="document.cns"
              :required-value="requiredCns"
              :class="{ disable: isEditing }"
              :disabled="isEditing"
              data-id="select-cns"
            />
            <RgInputCpf
              id="select-cpf"
              v-model="document.cpf"
              :rules="{ required: requiredCpf }"
              :class="{ disable: isEditing }"
              :disabled="isEditing"
              data-id="select-cpf"
            />
          </div>
        </FormBase>

        <FormBase
          title="Profissional"
          class="module-employee"
          :class="{ 'disable unselect': disableOrEnableModule }"
        >
          <div class="grid row-1">
            <RgInput
              id="ocp-nome"
              :value="employeeData.ocp_nome"
              :disabled="true"
              :class="{ disable: true }"
              label="Ocupação"
              class="disabled-field"
            />
            <RgInput
              id="uns-nome"
              :value="employeeData.uns_nome"
              :disabled="true"
              :class="{ disable: true }"
              label="Unidade de Saúde"
              class="disabled-field"
            />
            <RgInput
              id="set-nome"
              :value="employeeData.set_nome"
              :disabled="true"
              :class="{ disable: true }"
              label="Setor"
              class="disabled-field"
            />

            <RgInput
              id="professional"
              :value="employeeData.pes_nome || employeeData.fun_nome"
              :class="{ disable: true }"
              :disabled="true"
              label="Nome do Profissional"
              class="disabled-field"
            />

            <RgInput
              v-if="!typeSchedule"
              id="data-consulta"
              :value="employeeData.data_consulta || employeeData.DataConsulta"
              :disabled="true"
              :class="{ disable: true }"
              :needRemoveSpecialStrings="false"
              label="Data Atendimento"
              class="disabled-field"
            />
          </div>

          <div class="grid row-2">
            <RgInput
              v-if="!typeSchedule"
              id="scale"
              :value="scaleValue"
              :disabled="true"
              :class="{ disable: true }"
              label="Escala"
              class="disabled-field"
            />

            <RgInputHour
              v-if="!blockedAppointmentSchedulingTime && !typeSchedule"
              id="hour"
              ref="hour"
              v-model="form.con_hora"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :rules="{
                required: !blockedAppointmentSchedulingTime && !typeSchedule,
              }"
              placeholder="10:00"
              label="Hora *"
            />

            <RgComboboxHoursEmployerSchedule
              v-if="blockedAppointmentSchedulingTime && !typeSchedule"
              id="combo-hour"
              ref="comboHour"
              v-model="form.con_hora"
              :schedule-scale-id="employeeData.eag_id"
              :schedule-history-id="employeeData.hag_id"
              :initial-hour="employeeData.eag_inicio_atendimentos"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :appointment-date="
                employeeData.data_consulta || employeeData.DataConsulta
              "
              :alter-hour="form.hourAppointmentAlter"
              :rules="{
                required: blockedAppointmentSchedulingTime && !typeSchedule,
              }"
              label="Horário"
              noLoader
              @finishLoader="onFinishLoaderRgComboboxHoursEmployerSchedule"
            />

            <RgSelectPlacesAppointment
              id="place"
              ref="place"
              v-model="form.con_id_locais_atendimento"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :sector-id="employeeData.set_id"
              :rules="{ required: true }"
              label="Local de Atendimento"
              openOnlyTop
            />

            <RgSelectHealthPlansWithObjective
              id="health-plans"
              ref="healthPlans"
              v-model="form.con_id_planos_saude"
              :class="{ 'disable unselect': disableOrEnableModule }"
              :disabled="disableOrEnableModule"
              :module-id="subModuleId"
              :rules="{ required: true }"
              label="Convênio"
              openOnlyTop
              @change="chooseHealthPlan"
            />

            <RgSelectAppointmentObjective
              id="objective"
              ref="objective"
              v-model="form.con_id_tipos_consultas_unidade"
              :disabled="!form.con_id_planos_saude"
              :class="{ disable: !form.con_id_planos_saude }"
              :health-unit-id="employeeData.uns_id"
              :health-plans-id="form.con_id_planos_saude"
              :active="1"
              :rules="{ required: true }"
              label="Objetivo de Consulta"
              openOnlyTop
            />
          </div>
        </FormBase>

        <div v-if="typeSchedule" class="module-multiple">
          <FormBase
            title="Agendamento Múltiplos"
            class="area-1"
            :class="{ 'disable unselect': disableOrEnableModule }"
          >
            <div class="grid row-3">
              <div class="row-3-1">
                <RgInputDate
                  id="date"
                  ref="dateInput"
                  v-model="form.date"
                  :dates-available="datesEnable"
                  :disableNoAvailable="datesEnable.length === 0"
                  :class="{ 'disable unselect': disableOrEnableModule }"
                  :disabled="disableOrEnableModule"
                  label="Data"
                />

                <RgComboboxScale
                  id="scale"
                  ref="scale"
                  v-model="form.scale"
                  :disabled="!form.date"
                  :class="{ disable: !form.date }"
                  :sector-id="Number(employeeData.set_id)"
                  :occupation-id="Number(this.employeeData.ocp_id)"
                  :person-id="employeeData.pes_id"
                  :unit-id="Number(employeeData.uns_id)"
                  :date="form.date"
                  label="Escala"
                  @change="changeScale"
                />

                <RgComboboxHoursEmployerSchedule
                  v-if="blockedAppointmentSchedulingTime && typeSchedule"
                  id="combo-hour-multiple"
                  ref="comboHourMultiple"
                  v-model="form.con_hora"
                  :disabled="!dateIsValid || !getNumberScale"
                  :class="{ disable: !dateIsValid || !getNumberScale }"
                  :schedule-scale-id="getNumberScale"
                  :schedule-history-id="getHistoryId"
                  :initial-hour="getInitialHour"
                  :appointment-date="getDateSchedule"
                  :alter-hour="form.hourAppointmentAlter"
                  label="Horário"
                  noLoader
                  @finishLoader="onFinishLoaderRgComboboxHoursEmployerSchedule"
                />

                <RgAddButton
                  id="add-date-hour"
                  small
                  data-id="adicionar-procedimento"
                  title="Adicionar"
                  class="button-add unselect"
                  type="button"
                  @click="addDateHour"
                />
              </div>
            </div>
          </FormBase>

          <div
            class="list"
            :class="{ 'disable unselect': disableOrEnableModule }"
          >
            <SmartTable
              ref="smartTable"
              name="AppointmentScaleRegister"
              :columns="COLUMNS"
              :body="dateHourList"
              :total="dateHourList.length"
              :initial-columns="2"
              toggle-selected
              removeBtnColumns
              :showPagination="false"
              class="registered-scales"
              @getLine="selectLine"
            >
              <div slot="top-buttons" class="top-buttons">
                <RgLessButton
                  id="remove-scale"
                  :disabled="!hasSelectedLine"
                  class="btns"
                  title="Remover"
                  @click="removeOne"
                />
              </div>
            </SmartTable>
          </div>
        </div>

        <span v-if="typeSchedule" class="total-schedule">
          Total de agendamentos : {{ dateHourList.length }}
        </span>
      </RgValidatorForm>
    </form>

    <ModalConflictScale
      id="modal-conflict-scale"
      :show="showModalHistorySchedule"
      :patientInfo="[patientInfoConflict]"
      :title="title"
      :information="information"
      :scheduleInfo="[this.formatData()]"
      :showSave="showSave"
      @save="actSaveFormByModalConfirmSchedule"
      @close="closeModalConflictScale"
    />

    <ModalConfirmDefault
      id="modal-confirm-default"
      :show="unavailableHour.show"
      :message="unavailableHour.message"
      title="Horário Indisponível"
      yes-label="SIM"
      no-label="NÃO"
      @getYes="changeToAvailableHourAndSave"
      @getOut="closeModalUnavailableHour"
    />

    <ModalIndividualPatientRegistrationForm
      :show="showModalIndividualPatientRegistrationForm"
      :personId="patientInfo.pes_id"
      :scheduleData="employeeData"
      :lastIndividualRegistration="lastIndividualRegistration"
      :patientBasicAttentionId="patientBasicAttentionId"
      @save="saveIndividualPatientRegistrationForm"
      @cancel="closeModalIndividualPatientRegistrationForm"
      @close="closeModalIndividualPatientRegistrationForm"
    />

    <ModalPatientRecordsFoundBasicAttention
      :show="modalPatientRecordsFoundBasicAttention"
      by-scheduling
      :patient-id="patientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :patientHasNoBondOrInactiveBond="patientHasNoBondOrInactiveBond"
      @newRecord="showNewRecordFoundBasicAttention"
      @save="savePatientRecordsFoundBasicAttention"
      @close="closeModalPatientRecordsFoundBasicAttention"
    />

    <ModalPatientNewRecordFoundBasicAttention
      :show="modalPatientNewRecordFoundBasicAttention"
      :patient-id="patientId"
      :patientBasicAttentionId="patientBasicAttentionId"
      :individualRegistrationList="individualRegistrationList"
      :currentIndividualRegistrationDate="currentIndividualRegistrationDate"
      @close="closeModalPatientNewRecordFoundBasicAttention"
      @change="changePatientNewRecordFoundBasicAttention"
    />
  </div>
</template>
<script>
import RgSelectPlacesAppointment from "$appointment/common/component/rg-select-places-appointment/RgSelectPlacesAppointment";
import RgSelectAppointmentObjective from "$appointment/common/component/rg-select-appointment-objective/RgSelectAppointmentObjective";
import RgComboboxHoursEmployerSchedule from "$appointment/common/component/rg-combobox-hours-employer-schedule/RgComboboxHoursEmployerSchedule";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import RgComboboxScale from "$appointment/common/component/rg-combobox-scale/RgComboboxScale";
import ModalIndividualPatientRegistrationForm from "$appointment/submodules/schedule/component/modal/modal-individual-patient-registration-form/ModalIndividualPatientRegistrationForm";
import ModalPatientRecordsFoundBasicAttention from "$person/patient/component/modal/modal-patient-records-found-basic-attention/ModalPatientRecordsFoundBasicAttention";
import ModalPatientNewRecordFoundBasicAttention from "$person/patient/component/modal/modal-patient-new-record-found-basic-attention/ModalPatientNewRecordFoundBasicAttention";
import { DateNow } from "~utils/date";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import moment from "moment";
import {
  ModalConfirmDefault,
  RgValidatorForm,
  RgInputHour,
  RgInput,
  RgInputDate,
  RgAddButton,
  RgLessButton,
  RgSuggestPatientInformation,
  RgSelectHealthPlansWithObjective,
  Tooltip,
  IconExclamationHelper,
  ModalConflictScale,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { AlertError } from "~tokio/primitive/notification";
import { mapGetters } from "vuex";

import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";

export default {
  name: "QuickAppointmentScheduleForm",
  components: {
    ModalConfirmDefault,
    RgValidatorForm,
    RgInputHour,
    RgInput,
    RgInputCns,
    RgInputCpf,
    RgSelectPlacesAppointment,
    RgSuggestPatientInformation,
    RgAddButton,
    RgSelectHealthPlansWithObjective,
    RgSelectAppointmentObjective,
    FormBase,
    RgComboboxHoursEmployerSchedule,
    RgInputDate,
    RgComboboxScale,
    SmartTable,
    ModalIndividualPatientRegistrationForm,
    ModalPatientNewRecordFoundBasicAttention,
    ModalPatientRecordsFoundBasicAttention,
    RgLessButton,
    Tooltip,
    IconExclamationHelper,
    ModalConflictScale,
  },
  props: {
    disabledPatient: {
      default: false,
    },
    typeSchedule: {
      type: Boolean,
      default: false,
    },
    employeeData: {
      default: () => {
        return {};
      },
    },
    appointmentData: {
      default: () => {
        return {
          con_id: null,
          set_id: null,
          con_id_efetivacao_consulta: null,
          pac_id: null,
          tcu_id_unidades_saude: null,
          pcc_id: null,
          pes_id: null,
          pes_nome: null,
          con_hora: null,
          consulta: null,
          hag_data_consulta: null,
          con_id_historicos_agendamento: null,
          tcu_novo_tipo_consulta: null,
          efc_nome: null,
          uns_nome: null,
          con_numero: null,
          enviado: null,
          sme_nome: null,
          atendida: null,
          fil_id: null,
          con_id_planos_saude: null,
          con_id_agendado_por: null,
          con_id_tipos_consultas_unidade: null,
          con_id_pacientes_consultas: null,
          con_id_locais_atendimento: null,
          con_id_motivos_nao_efetivacao: null,
          con_id_filas_consultas: null,
          con_id_status_meu_esus: null,
          con_motivo_cancelamento_meu_esus: null,
          con_obs: null,
          blnAlertarPacienteConsultadoHoje: null,
        };
      },
    },
  },
  data() {
    return {
      unavailableHour: {
        show: false,
        message: "",
        nextAvailableHour: "",
      },
      patientInfoConflict: null,
      title: null,
      information: null,
      showSave: false,
      suggestPatient: null,
      patientInfo: {},
      showModalHistorySchedule: false,
      dateIsValid: false,
      hourIsValid: false,
      disabledPatientForm: false,
      dateHourList: [],
      datesEnable: [],
      patient: {
        pac_id: null,
        pcc_id: null,
      },
      form: {
        con_id_locais_atendimento: "",
        con_id_planos_saude: "",
        con_id_tipos_consultas_unidade: "",
        con_hora: "",
        con_hora_unico: "",
        con_hora_multiplo: "",
        date: "",
        date_multiplo: "",
        hourAppointmentAlter: "",
        scale: null,
      },
      periodsDate: [],
      finishLoader: {
        comboHour: false,
        edit: false,
      },
      document: {
        cpf: null,
        cpf_id: null,
        cns: null,
        crs_id: null,
      },
      getNumberScale: null,
      getDateSchedule: null,
      selectedScale: null,
      showModalIndividualPatientRegistrationForm: false,
      modalPatientNewRecordFoundBasicAttention: false,
      modalPatientRecordsFoundBasicAttention: false,
      modalNewRecordFoundBasicAttention: false,
      dataPatientBasicAttention: null,
      individualRegistrationList: [],
      lastIndividualRegistration: null,
      selectedLine: null,
      selectedIndex: null,
      hasCpf: false,
      hasCns: false,
      hasPerson: false,
      disableOrEnableModule: false,
      currentIndividualRegistrationDate: null,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
      getFillForm: "Appointment/Schedule/GET_FORM_APPOINTMENT",
      basicAttentionIntegration: "Login/GET_BASIC_ATTENTION_INTEGRATION",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),

    scaleValue() {
      const hasInitialValue =
        this.employeeData.eag_inicio_atendimentos?.length > 0;

      const initialScale = hasInitialValue
        ? `${this.employeeData.eag_inicio_atendimentos} -`
        : "";

      const finalScale = this.employeeData.eag_final_atendimentos || "";

      return `${initialScale} ${finalScale}`;
    },

    hasSelectedLine() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },

    subModuleId() {
      return 2;
    },

    disabledModalPatientHistory() {
      return !this.patient.pac_id;
    },

    isPerDay() {
      return !this.$route.name.includes("per-period");
    },

    isEditing() {
      return (
        (this.appointmentData != null && this.appointmentData.con_id > 0) ||
        this.$route.name === "appointment.schedule.per-periods.edit.form"
      );
    },

    blockPatientEdition() {
      return this.disabledPatient || this.isEditing;
    },

    preference() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewMarcacaoConsulta.con_bloquear_horario_agendamento"
      ];
    },

    blockedAppointmentSchedulingTime() {
      return this.preference === "1";
    },

    preferenceSchedulingSameDay() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_permitirAgendamentosMesmoDia"
      ];
    },

    preferenceCpfMandatoryScheduling() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_cpf_agendamento_consulta"
      ];
    },

    preferenceCnsMandatoryScheduling() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_cns_agendamento_consulta"
      ];
    },

    schedulingSameDay() {
      return this.preferenceSchedulingSameDay === "1";
    },

    getHistoryId() {
      return this.selectedScale?.hag_id;
    },

    getInitialHour() {
      return this.selectedScale?.eag_inicio_atendimentos;
    },

    scheduleDates() {
      if (this.typeSchedule) {
        return Array.from(
          new Set(
            this.dateHourList.map((elem) => {
              return elem.hag_data_consulta;
            }),
          ),
        );
      } else {
        return (
          this.employeeData.DataConsulta || this.employeeData.data_consulta
        );
      }
    },

    patientId() {
      return Number(this.patient?.pac_id);
    },

    hasBasicAttentionIntegration() {
      return (
        !!this.basicAttentionIntegration &&
        !!this.currentUnitHealth?.unidades_saude_integracao_ab
      );
    },

    patientBasicAttentionId() {
      return this.dataPatientBasicAttention?.pae_id;
    },

    patientHasNoBondOrInactiveBond() {
      return (
        !this.patientHasBondBasicAttention ||
        this.patientHasInactiveBondBasicAttention
      );
    },

    individualRegistration() {
      return this.individualRegistrationList?.length === 1
        ? this.individualRegistrationList[0]
        : null;
    },

    isBasicAttention() {
      return this.employeeData.atencaoBasica === "1";
    },

    messageTooltip() {
      return this.isBasicAttention
        ? "O cadastro do Paciente é vinculado a Ficha de Atenção Básica: os documentos “CPF” e/ou “Cartão SUS” possuem preenchimento obrigatório."
        : "Sempre mantenha as informações do paciente atualizadas. Recomenda-se o registro da documentação, principalmente CNS e CPF.";
    },

    requiredCpf() {
      return (
        this.preferenceCpfMandatoryScheduling === "1" ||
        (this.isBasicAttention && !this.document.cns)
      );
    },

    requiredCns() {
      return (
        this.preferenceCnsMandatoryScheduling === "1" ||
        (this.isBasicAttention && !this.document.cpf)
      );
    },
  },

  watch: {
    finishLoader: {
      handler() {
        if (
          this.isEditing &&
          this.finishLoader.comboHour &&
          this.finishLoader.edit
        ) {
          this.$loader.finish();
        }
      },
      deep: true,
    },

    async "form.date"(pValue) {
      if (pValue && pValue.length === 10) {
        this.dateIsValid = true;

        if (this.periodsDate && this.periodsDate.length <= 0) {
          await this.setDaysPossible();
        }

        this.getDateSchedule = pValue ? pValue.replaceAll("-", "/") : "";
      } else {
        this.dateIsValid = false;
        this.getDateSchedule = null;
        this.form.scale = null;
        this.getNumberScale = null;
        this.selectedScale = null;
        this.form.con_hora = null;
      }
    },

    "form.con_hora"(pValue) {
      if (pValue && pValue.length === 5) {
        this.hourIsValid = true;
      } else {
        this.hourIsValid = false;
      }
      if (this.typeSchedule) {
        this.form.date_multiplo = this.form.date;
        this.form.con_hora_multiplo = this.form.con_hora;
      } else {
        this.form.con_hora_unico = this.form.con_hora;
      }
    },

    "form.scale"(pScale) {
      if (pScale && Array.isArray(pScale)) {
        this.getNumberScale = Number(pScale[0].value);
      }
      this.hourIsValid = false;
    },

    dateHourList(pValue) {
      pValue && pValue.length > 0 && this.patient.pac_id
        ? (this.disabledPatientForm = true)
        : (this.disabledPatientForm = false);
    },

    appointmentData() {
      this.loadDataForEditing();
    },

    typeSchedule(pValue) {
      if (!pValue) {
        this.form.con_hora = this.form.con_hora_unico || this.form.con_hora;
      } else {
        this.form.con_hora = this.form.con_hora_multiplo || null;
        this.setDaysPossible();
      }
    },
    suggestPatient(pValue) {
      if (!pValue) {
        this.patientInfo = {};
      }
    },

    hasPerson(pValue) {
      if (pValue) {
        this.disableOrEnableModule = false;
      } else {
        this.disableOrEnableModule = true;
      }
    },
  },

  created() {
    if (this.isEditing) {
      this.suggestPatient = this.appointmentData.pes_nome;
    }

    this.COLUMNS = [
      { name: "Data", key: "hag_data_consulta" },
      { name: "Hora", key: "con_hora" },
    ];
  },

  mounted() {
    if (this.lastPersonIdSaved) {
      this.$refs.patient.fillPatientById(this.lastPersonIdSaved);
      this.fillForm();
    }

    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-day/form",
      this.$route.meta.breadcrumb,
    );

    this.loadDataForEditing();

    if (
      !existThisRouteFromSchedulePerDay &&
      this.periodsDate &&
      this.periodsDate.length <= 0
    ) {
      this.setDaysPossible();
    }
  },

  methods: {
    editPatient() {
      const fillForm = {
        form: this.form,
        employeeData: this.employeeData,
        typeSchedule: this.typeSchedule,
        dateHourList: this.dateHourList,
      };
      this.$store.commit("Appointment/Schedule/SET_FORM_APPOINTMENT", fillForm);
    },
    closeModalConflictScale() {
      this.showModalHistorySchedule = false;
    },
    chooseHealthPlan(pValue) {
      if (!pValue) {
        this.form.con_id_tipos_consultas_unidade = null;
      }
    },

    fillForm() {
      if (this.getFillForm) {
        this.$store.commit(
          "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
          this.getFillForm.employeeData,
        );

        this.form = this.getFillForm.form;
        this.dateHourList = this.getFillForm.dateHourList;

        this.$store.commit("Appointment/Schedule/CLEAN_FORM_APPOINTMENT");
      }
    },

    selectLine(pValue, pIndex) {
      this.selectedLine = pValue;
      this.selectedIndex = pIndex;
    },

    onFinishLoaderRgComboboxHoursEmployerSchedule() {
      this.finishLoader.comboHour = true;
    },

    enableAdd() {
      if (!this.patient.pac_id) {
        return AlertError("Informe o nome do paciente");
      }

      if (!this.form.con_id_locais_atendimento) {
        return AlertError("Informe o local de atendimento");
      }

      if (!this.form.con_id_planos_saude) {
        return AlertError("Informe o convênio");
      }

      if (!this.form.con_id_tipos_consultas_unidade) {
        return AlertError("Informe o objetivo da consulta");
      }

      if (!this.dateIsValid || !this.form.scale || !this.hourIsValid) {
        return AlertError("Informe a data, a escala e o horário");
      }

      return true;
    },

    async addDateHour() {
      const appointmentDate = this.form.date.replace(/-/g, "/");
      const appointmentScale = this.selectedScale.data;

      const sameAppointment = this.dateHourList.some(
        (item) =>
          item.hag_data_consulta === appointmentDate &&
          item.eag_id === appointmentScale,
      );

      if (this.enableAdd()) {
        if (sameAppointment) {
          return AlertError("Já existe uma agendamento nessa data e escala");
        }
        const newValue = {
          con_id: this.appointmentData.con_id || 0,
          con_id_historicos_agendamento: this.selectedScale.hag_id,
          eag_id: this.selectedScale.data,
          eag_quantidade_marcadas: this.selectedScale.eag_quantidade_marcadas,
          hag_hora_inicio_consulta_funcionario: this.selectedScale.eag_inicio_atendimentos.substr(
            0,
            5,
          ),
          hag_id: this.selectedScale.hag_id,
          hag_id_dias_semana: this.selectedScale.eag_id_dias_semana,

          hag_id_escalas_agendamentos: this.selectedScale.data,
          hag_intervalo_consultas: this.selectedScale.eag_intervalo_consultas,
          hag_quantidade_consultas: this.selectedScale.eag_quantidade_consultas,
          blnAlertarPacienteConsultadoHoje: true,
          con_hora: this.form.con_hora,
          con_id_locais_atendimento: this.form.con_id_locais_atendimento,
          con_id_pacientes_consultas: this.patient.pcc_id,
          con_id_planos_saude: this.form.con_id_planos_saude,
          con_id_tipos_consultas_unidade: this.form
            .con_id_tipos_consultas_unidade,
          hag_data_consulta: this.form.date.replace(/-/g, "/"),
          set_id: this.employeeData.set_id,
          tcu_id_unidades_saude: this.employeeData.uns_id,
          tipo_agendamento: "",
        };

        this.dateHourList.push(newValue);
        this.form.date = "";
        this.dateIsValid = false;
        this.form.scale = null;
        this.getNumberScale = null;
        this.selectedScale = null;
        this.form.con_hora = "";
        this.form.con_hora_multiplo = "";
        this.hourIsValid = false;
      }
    },

    formatDataBR(pValue) {
      return pValue ? this.$utils.date.BrazilianDateFormat(pValue) : "";
    },

    removeAll() {
      this.dateHourList = [];
    },

    removeOne() {
      this.dateHourList.splice(this.selectedIndex, 1);
      this.selectedLine = null;
      this.selectedIndex = null;
      this.$refs.smartTable.cleanSelectRow();
    },

    async actSaveFormByModalConfirmSchedule() {
      const result = await this.saveSchedule(true);
      this.showModalHistorySchedule = false;
      this.$emit("saveSchedule", result);
    },

    async saveIndividualPatientRegistrationForm() {
      this.showModalIndividualPatientRegistrationForm = false;
      this.saveSchedule();
    },

    async saveForm() {
      const isInvalid = !(await this.isFormValid());

      if (isInvalid) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();

        if (!(await this.manageDocuments())) return false;

        if (await this.verifyIndividualRecordModalToOpen()) return false;
      } finally {
      }
      await this.saveSchedule();
      this.$loader.finish();
    },

    async saveSchedule(isSaveByModal = false) {
      const isAppointmentBatchHasTableEmpty =
        this.typeSchedule &&
        (this.dateHourList.length < 1 || !this.dateHourList);

      if (isAppointmentBatchHasTableEmpty) {
        this.$toaster.warning("Informe os Agendamentos Múltiplos");
        return false;
      }

      const variables = this.formatData();

      if (isSaveByModal) {
        variables.blnAlertarPacienteConsultadoHoje = false;
      }

      let data = null;

      try {
        this.$loader.start();

        if (this.isEditing) {
          const permissions = {
            blnPermissaoAlterarNormal: this.$Permissions.sector.has(
              "consulta.agendamento.alterar",
              this.employeeData.set_id,
            ),
            blnPermissaoAlterarReservaTecnica: this.$Permissions.sector.has(
              "consulta.agendamento.alterarAgendamentoDiaReservaTecnica",
              this.employeeData.set_id,
            ),
          };

          await this.$store.dispatch(
            "Appointment/Schedule/EDIT_SCHEDULE_PATIENT",
            { variables, permissions },
          );

          this.$toaster.success("Agendamento alterado com sucesso");
          this.$router.go(-1);
          return true;
        } else {
          const permissions = this.getPermissionsSchedule();

          if (this.typeSchedule) {
            if (isSaveByModal) {
              this.dateHourList.forEach((date) => {
                date.blnAlertarPacienteConsultadoHoje = false;
              });
            }
            data = await this.$store.dispatch(
              "Appointment/Schedule/SCHEDULE_PATIENT_IN_BATCH",
              { variables: this.dateHourList, permissions },
            );
          } else {
            data = await this.$store.dispatch(
              "Appointment/Schedule/SCHEDULE_PATIENT",
              { variables, permissions },
            );
          }

          this.$toaster.success("Agendamento realizado com sucesso");
          const appointmentId = data.dados;
          this.$emit("saveSchedule", appointmentId);
          return appointmentId;
        }
      } catch (pErr) {
        const { esus_response } = pErr;
        if (this.isEditing && esus_response?.hasError) {
          if (esus_response.exception === "AlteracaoConsultaException") {
            this.$toaster.success("Agendamento alterado com sucesso");
            this.$router.go(-1);
            return true;
          } else {
            this.$toaster.error(
              this.$utils.sanitize.formatError(pErr),
              "Erro ao salvar agendamento",
            );
            return false;
          }
        } else {
          const timePreference = this.$store.getters["Login/GET_PREFERENCES"][
            "tViewMarcacaoConsulta.con_tempo_entre_consultas"
          ];
          const hasSchedule =
            esus_response.dados === "PacienteConsultadoHojeException";

          const hasScheduleDay =
            esus_response.dados === "PacienteConsultadoHojeHorarioException";
          let hasScheduleAttendance =
            esus_response.dados === "PacienteConsultadoHojeAtendenteException";
          hasScheduleAttendance =
            esus_response.dados === "TransferenciaConsultaExistenteException";
          if (hasScheduleDay) {
            this.title = "O paciente já possui Consulta agendada no dia.";
            this.information =
              "O intervalo entre as consultas é menor ou igual a " +
              timePreference +
              " minutos.";
            this.showSave = false;
            this.patientInfoConflict = {
              pcc_id: this.patientInfo.pcc_id,
              pes_nome: this.patientInfo.pac_nome,
              pep_cpf_numero: this.patientInfo.cpf,
              pep_cartaosus: this.patientInfo.crs_numero,
              pac_prontuario_unico: this.patientInfo.prontuario_unico,
            };

            this.showModalHistorySchedule = true;
          } else if (hasSchedule) {
            this.title =
              "O paciente já possui Consulta agendada no dia selecionado.";
            this.information =
              "Verifique na lista abaixo os respectivos horários marcados para decidir se deseja prosseguir com a marcação mesmo assim.";
            this.showSave = true;
            this.patientInfoConflict = {
              pcc_id: this.patientInfo.pcc_id,
              pes_nome: this.patientInfo.pac_nome,
              pep_cpf_numero: this.patientInfo.cpf,
              pep_cartaosus: this.patientInfo.crs_numero,
              pac_prontuario_unico: this.patientInfo.prontuario_unico,
            };
            this.showModalHistorySchedule = true;
          } else if (hasScheduleAttendance) {
            this.title =
              "O paciente já possui Consulta agendada no dia selecionado com esse Profissional.";
            this.information = "";
            this.showSave = false;
            this.patientInfoConflict = {
              pcc_id: this.patientInfo.pcc_id,
              pes_nome: this.patientInfo.pac_nome,
              pep_cpf_numero: this.patientInfo.cpf,
              pep_cartaosus: this.patientInfo.crs_numero,
              pac_prontuario_unico: this.patientInfo.prontuario_unico,
            };
            this.showModalHistorySchedule = true;
          } else {
            this.$toaster.error(
              this.$utils.sanitize.formatError(pErr),
              "Erro ao salvar agendamento",
            );
            return false;
          }
        }
      } finally {
        this.$loader.finish(1000);
      }
    },

    async verifyIndividualRecordModalToOpen() {
      const isBasicAttentionSector = this.employeeData.atencaoBasica === "1";
      const hasPatientId = this.patientId;

      if (
        this.hasBasicAttentionIntegration &&
        isBasicAttentionSector &&
        hasPatientId
      ) {
        await this.searchPatientBondBasicAttention();
        const hasActiveBond = this.dataPatientBasicAttention?.pae_ativo;

        if (hasActiveBond) {
          if (await this.getIndividualRegistration()) {
            const hasNoRecord = this.individualRegistrationList.length === 0;

            if (hasNoRecord) {
              this.lastIndividualRegistration = null;
              this.showModalIndividualPatientRegistrationForm = true;
              return true;
            }

            const hasOneRecord = this.individualRegistrationList.length === 1;
            const patientBaseRecordId = this.dataPatientBasicAttention
              ?.pae_ficha_base;
            const individualRegistrationWithSameId = this.individualRegistrationList.find(
              (x) =>
                Number(x.pab_id) === patientBaseRecordId ||
                Number(x.pab_id_payload) === patientBaseRecordId,
            );

            if (hasOneRecord && individualRegistrationWithSameId) {
              const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
                this.individualRegistrationList[0],
                this.dataPatientBasicAttention.pae_id,
                this.patientId,
                true,
              );
              await this.updatePatientBondBasicAttention(patientAbEsus);
              return false;
            }

            if (hasOneRecord && !individualRegistrationWithSameId) {
              this.inactivatePatientBondBasicAttention();
              this.showModalPatientRecordsFoundBasicAttention();
              return true;
            }

            const hasMoreThanOneRecord =
              this.individualRegistrationList.length > 1;

            if (hasMoreThanOneRecord && individualRegistrationWithSameId) {
              const individualRegistrationWithGreaterDate = await this.getIndividualRegistrationWithGreaterDate();
              if (individualRegistrationWithGreaterDate) {
                const updatePatientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
                  individualRegistrationWithGreaterDate,
                  this.dataPatientBasicAttention.pae_id,
                  this.patientId,
                  true,
                );
                await this.updatePatientBondBasicAttention(updatePatientAbEsus);
                return false;
              } else {
                this.currentIndividualRegistrationDate =
                  individualRegistrationWithSameId.pab_data_atendimento;
                this.showModalPatientNewRecordFoundBasicAttention();
                return true;
              }
            }

            if (hasMoreThanOneRecord && !individualRegistrationWithSameId) {
              this.inactivatePatientBondBasicAttention();
              this.showModalPatientRecordsFoundBasicAttention();
              return true;
            }
          }
          return false;
        }

        if (!hasActiveBond) {
          if (await this.getIndividualRegistration()) {
            const hasRecord = this.individualRegistrationList.length > 0;
            if (hasRecord) {
              this.showModalPatientRecordsFoundBasicAttention();
              return true;
            }
          }

          this.lastIndividualRegistration = null;
          this.showModalIndividualPatientRegistrationForm = true;
          return true;
        }
      }

      return false;
    },

    async searchPatientBondBasicAttention() {
      if (this.patientId) {
        const dataAbEsus = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_AB_ESUS",
          { pacId: this.patientId },
        );

        this.dataPatientBasicAttention = dataAbEsus || null;
      }
    },

    async getIndividualRegistration(sendBaseCardId = true) {
      const patientCpf = this.removeDashesAndDots(this.document.cpf);
      const patientCns = this.document.cns;
      const patientAbEsusIsActive =
        this.dataPatientBasicAttention?.pae_ativo || false;

      if (patientCpf || patientCns) {
        const variables = {
          ...(sendBaseCardId &&
            patientAbEsusIsActive && {
              baseCardId: this.dataPatientBasicAttention?.pae_ficha_base,
            }),
          cpf: patientCpf,
          cns: patientCns,
        };
        try {
          const result = await this.$store.dispatch(
            "Appointment/Schedule/GET_AB_INDIVIDUAL_REGISTRATION",
            variables,
          );
          this.individualRegistrationList = result?.rows;
          return this.individualRegistrationList;
        } catch {
          return null;
        }
      }

      return null;
    },

    async updatePatientBondBasicAttention(pData) {
      if (pData) {
        try {
          await this.$store.dispatch("Appointment/Schedule/UPDATE_AB_ESUS", {
            abEsus: pData,
          });
        } catch (err) {
          this.$toaster.error(err);
        }
      }
    },

    async getIndividualRegistrationWithGreaterDate() {
      const patientBaseRecordId = this.dataPatientBasicAttention
        ?.pae_ficha_base;

      const individualRegistrationSameId = this.individualRegistrationList.find(
        (x) =>
          Number(x.pab_id) === patientBaseRecordId ||
          Number(x.pab_id_payload) === patientBaseRecordId,
      );

      const individualRegistrationSameIdDate = new Date(
        individualRegistrationSameId.pab_data_atendimento,
      );

      const maxDate = new Date(
        Math.max(
          ...this.individualRegistrationList.map((item) => {
            return new Date(item.pab_data_atendimento);
          }),
        ),
      );

      if (individualRegistrationSameIdDate.getTime() === maxDate.getTime()) {
        return individualRegistrationSameId;
      }

      return null;
    },

    async getLastIndividualRegistrationByDate() {
      if (this.individualRegistrationList?.length === 0) return null;

      const maxDate = new Date(
        Math.max(
          ...this.individualRegistrationList.map((item) => {
            return new Date(item.pab_data_atendimento + " 00:00:00");
          }),
        ),
      );

      const maxDateFormatted = moment(maxDate).format("YYYY-MM-DD");

      const individualRegistrationSameDate = this.individualRegistrationList.filter(
        (x) => x.pab_data_atendimento === maxDateFormatted,
      );

      if (individualRegistrationSameDate.length === 1) {
        return individualRegistrationSameDate[0];
      }

      if (individualRegistrationSameDate.length > 1) {
        return individualRegistrationSameDate.reduce((prev, current) =>
          Number(prev.pab_id) > Number(current.pab_id) ? prev : current,
        );
      }

      return null;
    },

    async manageDocuments() {
      const currentCpf = this.patientInfo.cpf;
      const newCpf = this.removeDashesAndDots(this.document.cpf);

      const currentCns = this.patientInfo.crs_numero;
      const newCns = this.document.cns;

      if (currentCpf === newCpf && currentCns === newCns) return true;

      const cpf = await this.manageCpf(this.patientInfo.pes_id);
      const cns = await this.manageCns(this.patientInfo.pes_id);

      if (!cpf || cpf.ManageCpfSchedulingPatient.success === false) {
        return false;
      }

      if (!cns || cns.ManageCnsSchedulingPatient.success === false) {
        return false;
      }

      this.$refs.patient.fillPatientById(this.patientInfo.pes_id);

      return true;
    },

    async manageCpf(pPesId) {
      let variables = {};
      const removeCpf = this.hasCpf && !this.document.cpf;

      try {
        if (removeCpf) {
          variables = {
            pes_id: pPesId,
            cpf: {},
          };
        } else {
          variables = {
            pes_id: pPesId,
            cpf: {
              cpf_id: this.document.cpf_id || null,
              cpf_numero: this.document.cpf
                ? this.removeDashesAndDots(this.document.cpf)
                : "",
            },
          };
        }

        return await this.$store.dispatch(
          "Person/MANAGE_CPF_TO_PERSON",
          variables,
        );
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      }
    },

    async manageCns(pPesId) {
      let variables = {};

      const removeCns = this.hasCns && !this.document.cns;

      try {
        if (removeCns) {
          variables = {
            pes_id: pPesId,
            cns: {},
          };
        } else {
          variables = {
            pes_id: pPesId,
            cns: {
              crs_id: this.document.crs_id || null,
              crs_numero: this.document.cns,
            },
          };
        }

        return await this.$store.dispatch(
          "Person/MANAGE_CNS_TO_PERSON",
          variables,
        );
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      }
    },

    getPermissionsSchedule() {
      const today = moment({ h: 0, m: 0, s: 0 });

      const scheduleDate = moment(
        this.employeeData.data_consulta || this.employeeData.DataConsulta,
        "DD/MM/YYYY",
      );

      if (this.isPerDay) {
        if (scheduleDate < today) {
          return {
            blnPermissaoNormal: this.$Permissions.sector.has(
              "consulta.agendamento.AgendamentoRetroativo",
              this.employeeData.set_id,
            ),
            blnPermissaoReserva: this.$Permissions.sector.has(
              "consulta.agendamento.agendamentoRetroativoReservaTecnica",
              this.employeeData.set_id,
            ),
          };
        }

        return {
          blnPermissaoNormal: this.$Permissions.sector.has(
            "consulta.agendamento.AgendamentoFuturo",
            this.employeeData.set_id,
          ),
          blnPermissaoReserva: this.$Permissions.sector.has(
            "consulta.agendamento.agendamentoFuturoReservaTecnica",
            this.employeeData.set_id,
          ),
        };
      } else {
        return {
          blnPermissaoNormal: this.$Permissions.sector.has(
            "consulta.agendamentoCompleto.incluir",
            this.employeeData.set_id,
          ),
          blnPermissaoReserva: this.$Permissions.sector.has(
            "consulta.agendamentoCompleto.agendarReservaTecnica",
            this.employeeData.set_id,
          ),
        };
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    formatData() {
      const form = {};

      form.con_id = this.appointmentData.con_id || 0;

      form.blnAlertarPacienteConsultadoHoje = true;
      form.con_hora = this.form.con_hora;
      form.con_id_historicos_agendamento = this.employeeData.hag_id;
      form.con_id_locais_atendimento = this.form.con_id_locais_atendimento;
      form.con_id_pacientes_consultas = this.patient.pcc_id;
      form.con_id_planos_saude = this.form.con_id_planos_saude;
      form.con_id_tipos_consultas_unidade = this.form.con_id_tipos_consultas_unidade;
      form.eag_id = this.employeeData.eag_id;
      form.eag_quantidade_marcadas = this.employeeData.eag_quantidade_marcadas;
      form.hag_data_consulta =
        this.employeeData.data_consulta || this.employeeData.DataConsulta;
      form.hag_hora_inicio_consulta_funcionario =
        this.employeeData.hag_hora_inicio_consulta_funcionario ||
        this.employeeData.eag_inicio_atendimentos;
      form.hag_id = this.employeeData.hag_id;
      form.hag_id_dias_semana =
        this.employeeData.hag_id_dias_semana ||
        this.employeeData.eag_id_dias_semana;
      form.hag_id_escalas_agendamentos =
        this.employeeData.hag_id_escalas_agendamentos ||
        this.employeeData.eag_id;
      form.hag_intervalo_consultas =
        this.employeeData.hag_intervalo_consultas ||
        this.employeeData.eag_intervalo_consultas;
      form.hag_quantidade_consultas =
        this.employeeData.hag_quantidade_consultas ||
        this.employeeData.eag_quantidade_consultas;
      form.set_id = this.employeeData.set_id;
      form.tcu_id_unidades_saude = this.employeeData.uns_id;
      form.tipo_agendamento = "";

      return form;
    },

    async getPatientData(pPatient) {
      this.hasPerson = pPatient && pPatient.pes_id > 0;

      if (pPatient && Object.keys(pPatient).length > 0) {
        this.hasCpf =
          pPatient.cpf?.cpf_numero && pPatient.cpf?.cpf_numero.length > 0;
        this.document.cpf = pPatient.cpf?.cpf_numero
          ? pPatient.cpf.cpf_numero
          : null;
        this.document.cpf_id = pPatient.cpf?.cpf_id
          ? pPatient.cpf.cpf_id
          : null;
        this.hasCns =
          pPatient.cns?.crs_numero && pPatient.cns?.crs_numero.length > 0;
        this.document.cns = pPatient.cns?.crs_numero
          ? pPatient.cns.crs_numero
          : null;
        this.document.crs_id = pPatient.cns?.crs_id
          ? pPatient.cns.crs_id
          : null;
      } else {
        this.document = {
          cpf: null,
          cns: null,
        };
        this.hasCpf = false;
        this.hasCns = false;
      }

      if (pPatient?.patient && this.hasPerson) {
        this.patient.pac_id =
          pPatient && pPatient.patient ? pPatient.patient.pac_id : null;

        this.patient.pcc_id =
          this.patient.pac_id && pPatient.patient.patient_appointment
            ? pPatient.patient.patient_appointment.pcc_id
            : null;

        this.fillPatientInfo(pPatient);
      } else {
        this.patientInfo = {};
        this.patient = {
          pac_id: "",
          pcc_id: "",
        };

        this.blockedPatient(true);
      }
    },

    fillPatientInfo(pData) {
      this.patientInfo = {
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pcc_id: pData.patient?.patient_appointment?.pcc_id,
        pac_nome: pData.pes_nome,
        sex_sigla: pData && pData.gender ? pData.gender.sex_sigla : null,
        pes_nascimento:
          pData && pData.pes_nascimento ? pData.pes_nascimento : null,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };
    },

    closeModalHistorySchedule() {
      this.showModalHistorySchedule = false;
    },

    closeModalIndividualPatientRegistrationForm() {
      this.showModalIndividualPatientRegistrationForm = false;
    },

    closeModalPatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = false;
    },

    closeModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
    },

    showModalPatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = true;
    },

    showModalPatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = true;
    },

    async showNewRecordFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
      this.lastIndividualRegistration = await this.getLastIndividualRegistrationByDate();
      this.showModalIndividualPatientRegistrationForm = true;
    },

    changePatientNewRecordFoundBasicAttention() {
      this.modalPatientNewRecordFoundBasicAttention = false;
      this.saveSchedule();
    },

    async savePatientRecordsFoundBasicAttention() {
      this.modalPatientRecordsFoundBasicAttention = false;
      this.saveSchedule();
    },

    async inactivatePatientBondBasicAttention(paeId = null) {
      const INATIVO = 0;
      const dataAbEsusInactive = {
        pae_id: paeId || this.dataPatientBasicAttention.pae_id,
        pae_ativo: INATIVO,
        pae_ultima_alteracao: DateNow(),
      };
      this.updatePatientBondBasicAttention(dataAbEsusInactive);
    },

    async changeToAvailableHourAndSave() {
      try {
        this.form.con_hora = this.unavailableHour.nextAvailableHour;
        this.$emit("saveWithAvailableHour");
      } catch (err) {}
    },

    closeModalUnavailableHour() {
      this.unavailableHour.show = false;
      this.unavailableHour.message = "";
      this.unavailableHour.nextAvailableHour = "";
    },

    async loadDataForEditing() {
      if (this.isEditing && this.appointmentData.pes_id) {
        this.$loader.start();
        this.form.con_hora = this.appointmentData.con_hora;
        this.form.con_id_locais_atendimento = this.appointmentData.con_id_locais_atendimento;
        this.form.con_id_planos_saude = this.appointmentData.con_id_planos_saude;
        this.form.con_id_tipos_consultas_unidade = this.appointmentData.con_id_tipos_consultas_unidade;
        this.patient = {
          pcc_id: this.appointmentData.pcc_id,
          pac_id: this.appointmentData.pac_id,
        };
        this.form.hourAppointmentAlter = this.appointmentData.con_hora;

        await this.$store
          .dispatch("Appointment/Schedule/GET_PATIENT_BASIC_INFO", {
            pesId: this.appointmentData.pes_id,
          })
          .then((personData) => {
            this.$refs.patient.fillPatientById(personData.pes_id);
          })
          .catch((pErr) => {
            this.$toaster.error(
              "Falha ao carregar os dados da pessoa para exibição, isso não afeta a alteraçao dos dados: " +
                pErr.message,
            );
          })
          .finally(() => {
            this.finishLoader.edit = true;
          });
      }
    },

    async setDaysPossible() {
      try {
        this.$loader.start();

        const pData = {
          intIdSetor: Number(this.employeeData.set_id),
          intIdOcupacao: Number(this.employeeData.ocp_id),
          intIdPessoa: Number(this.employeeData.pes_id),
          intIdUnidadeSaude: Number(this.employeeData.uns_id),
          strDataInicial: moment().format("DD/MM/YYYY"),
          strDataFinal: moment().add("years", 1).format("DD/MM/YYYY"),
        };

        this.periodsDate = await this.$store.dispatch(
          "Appointment/Schedule/GET_DAY_ON_WEEK_FOR_PERIODS",
          pData,
        );

        this.periodsDate = this.periodsDate.map((item) => {
          return {
            eag_inicio_atendimentos: item.eag_inicio_atendimentos.substr(0, 5),
            data: item.data,
            eag_id: item.eag_id,
            hag_id: item.hag_id,
            eag_id_dias_semana: item.eag_id_dias_semana,
            eag_quantidade_consultas: item.eag_quantidade_consultas,
            eag_intervalo_consultas: item.eag_intervalo_consultas,
            eag_quantidade_marcadas: item.eag_quantidade_marcadas,
          };
        });

        this.datesEnable = this.periodsDate.map((item) =>
          moment(item.data, "DD-MM-YYYY").format("YYYY-MM-DD"),
        );

        if (this.datesEnable.length === 0) {
          this.$toaster.warning("O profissional selecionado não possui escala");
        }
      } catch (Err) {
        console.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    changeScale(scale) {
      if (scale && scale.length > 0) {
        const item = scale[0].item;
        this.selectedScale = item;
        this.getNumberScale = scale[0].value;
      } else {
        this.getNumberScale = null;
        this.form.con_hora = null;
        this.selectedScale = null;
      }
    },

    cleanForm() {
      this.suggestPatient = null;
      this.patientInfo = {};
      this.patient = {
        pac_id: null,
        pcc_id: null,
      };
      this.$refs.patient.cleanPatient();

      this.dateIsValid = false;
      this.hourIsValid = false;
      this.dateHourList = [];
      this.getDateSchedule = null;
      this.selectedScale = null;
      this.getNumberScale = null;

      this.form = {
        con_id_locais_atendimento: "",
        con_id_planos_saude: "",
        con_id_tipos_consultas_unidade: "",
        con_hora: "",
        date: "",
        scale: null,
      };

      this.document = {
        cpf: null,
        cns: null,
      };
      this.$refs.patient.cleanValidate();
      this.$refs.place.cleanValidate();
      this.$refs.healthPlans.cleanValidate();
      this.$refs.objective.cleanValidate();

      if (!this.blockedAppointmentSchedulingTime && !this.typeSchedule) {
        this.$refs.hour.cleanValidate();
      }

      if (this.blockedAppointmentSchedulingTime && !this.typeSchedule) {
        this.$refs.comboHour.cleanValidate();
      }

      this.disabledPatientForm = false;
    },

    getAttributeDayByDate(pDate, pAtrr) {
      if (!pDate || this.periodsDate.length <= 0) return;
      return this.periodsDate.find((ele) => ele.data === pDate)[pAtrr];
    },

    blockedPatient(pValue) {
      this.$emit("canSave", !pValue);
    },

    selectOnlyScale(value) {
      this.form.scale = value;
    },

    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },
  },
};
</script>
