import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { Vue as VueIntegration } from "@sentry/integrations";

export default (_Vue) => {
  const {
    VUE_APP_SENTRY_ENABLED,
    VUE_APP_SENTRY_DSN,
    VUE_APP_SENTRY_AMBIENTE,
    VUE_APP_SENTRY_VERSION,
  } = process.env;

  if (VUE_APP_SENTRY_ENABLED === "false") {
    return;
  }

  const options = {
    _Vue,
    integrations: [
      new VueIntegration({
        _Vue,
        attachProps: true,
      }),
      new Integrations.BrowserTracing({
        tracingOrigins: ["*"],
      }),
    ],
    tracesSampleRate: 0.025,
    dsn: VUE_APP_SENTRY_DSN,
    environment: VUE_APP_SENTRY_AMBIENTE,
    release: VUE_APP_SENTRY_VERSION,
    logErrors: true,
    attachStacktrace: true,
    autoSessionTracking: false,
  };

  Sentry.init(options);
};
