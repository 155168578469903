<style src="./RgSuggestPharmacyProductCounty.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-product-county">
    <RgSuggest
      :id="id"
      ref="rgSuggest"
      v-model="inputValue"
      :label="label"
      :placeholder="placeholder"
      :search-on-focus="searchOnFocus"
      min="0"
      class="product-county-suggest"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="product-county-item"
      >
        <div class="product-county-row-info">
          <div class="pc-code">
            <span>{{ item.mpd_codigo }}</span>
          </div>
          <div class="pc-name">
            <span>{{ item.mpd_novo_principio_ativo }}</span>
          </div>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import SearchPharmacyProductCounty from "./action/SearchPharmacyProductCounty";

export default {
  name: "RgSuggestPharmacyProductCounty",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Produto",
    },
    placeholder: {
      type: String,
      default: "Informe o nome do produto",
    },
    clientId: {
      type: Number,
    },
    fieldCaption: {
      type: String,
      default: "mpd_id",
    },
  },
  data() {
    return {
      captionRaw: "mpd_novo_principio_ativo",
      suggestionList: [],
    };
  },

  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.innerSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },

  methods: {
    doSearch(pSearchTerm, pLimit = null) {
      return SearchPharmacyProductCounty({
        mpd_id_clientes: this.clientId,
        mpd_codigo: pSearchTerm,
        mpd_novo_principio_ativo: pSearchTerm,
        limit: pLimit,
      });
    },

    forceSelection(pValue) {
      if (
        pValue &&
        !isEmpty(pValue.mpd_id) &&
        pValue.mpd_codigo > 0 &&
        pValue.mpd_novo_principio_ativo !== null
      ) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            value: pValue.mpd_id,
            label: `${pValue.mpd_codigo} - ${pValue.mpd_novo_principio_ativo}`,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
