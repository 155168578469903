import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/effectivation/details/new-patient",
  name: "exam.effectivation.details.new-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      {
        label: "Efetivação",
        link: "/exam/effectivation",
      },
      {
        label: "Cadastrar Exame",
        link: "/exam/effectivation/details",
      },
      {
        label: "Cadastrar Paciente",
        link: "/exam/effectivation/details/new-patient",
      },
    ],
  },
};
