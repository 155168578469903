<style src="./RgSuggestProcedure.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="rgSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :disabled="disabled"
    :search-on-focus="searchOnFocus"
    :rules="rules"
    min="2"
    class="suggest-procedure"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="procedure-item"
    >
      <div class="procedure-row-top">
        <b class="procedure-name">{{ item.NO_PROCEDIMENTO }}</b>
      </div>
      <div class="procedure-row-info">
        {{ item.CO_PROCEDIMENTO }}
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { isEmpty } from "lodash";
import { SearchProcedure } from "./action";
export default {
  name: "RgSuggestProcedure",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    fieldCaption: {
      type: String,
      default: "CO_PROCEDIMENTO",
    },
    sectorLink: {
      type: [Number, Array, Boolean],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    sectorLinkRequired: {
      type: Boolean,
      default: false,
    },
    period: {
      type: String,
      default: null,
    },
    occupationCode: {
      type: String,
      default: null,
    },

    alertEmptyResult: {
      type: Boolean,
      default: false,
    },
    onlyLaboratory: {
      type: Boolean,
      default: false,
    },
    dataItem: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Digite o procedimento",
    },
    title: {
      type: String,
      default: "Procedimento",
    },
    isEffectivation: Boolean,
    isBilling: Boolean,
  },
  data() {
    return {
      captionRaw: "CO_PROCEDIMENTO",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  watch: {},
  mounted() {
    this.captionRaw = this.fieldCaption;
  },
  methods: {
    validate() {
      if (this.$refs.rgSuggest) this.$refs.rgSuggest.validate();
    },
    doSearch(pSearchTerm, pLimit = null) {
      if (this.isBilling && !pSearchTerm && !this.occupationCode) {
        return;
      }

      if (
        !this.sectorLinkRequired ||
        (this.sectorLinkRequired && this.sectorLink && this.sectorLink > 0)
      ) {
        const variables = this.defineQuery(pSearchTerm, pLimit);
        return this.searchProcedure(variables);
      }
    },
    async searchProcedure(variables) {
      const procedures = await SearchProcedure({ variables });
      if (this.alertEmptyResult && procedures.length < 1) {
        this.$toaster.warning("Nenhum procedimento encontrado");
      }

      return procedures;
    },
    defineQuery(pSearchTerm, pLimit = null, offset = 0) {
      const periodDate = this.period ? this.period : this.getPeriod();
      const variables = {
        procedureName: null,
        procedureCode: null,
        occupationCode: this.occupationCode,
        sectorLink: this.sectorLink,
        type: this.type,
        periodDate,
        isEffectivation: this.isEffectivation,
        limit: pLimit,
        offset: offset,
      };

      const query = pSearchTerm.toString().trim();

      if (query) {
        if (query !== null && query.includes("-")) {
          const term = query.split(" - ");
          variables.procedureCode = term[0];
        } else {
          !isNaN(query)
            ? (variables.procedureCode = query)
            : (variables.procedureName = query);
        }
      }

      if (this.onlyLaboratory) {
        variables.isLaboratory = true;
      }

      return variables;
    },
    getPeriod() {
      return this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    },

    forceSelection(pValue) {
      if (
        pValue &&
        !isEmpty(pValue.CO_PROCEDIMENTO) &&
        !isEmpty(pValue.NO_PROCEDIMENTO)
      ) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            NO_PROCEDIMENTO: pValue.NO_PROCEDIMENTO,
            CO_PROCEDIMENTO: pValue.CO_PROCEDIMENTO,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
