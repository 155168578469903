<template>
  <Modulebox
    title="Cadastro de Procedimentos"
    class="exam-priority"
    :granted="hasPermission"
  >
  </Modulebox>
</template>

<script>
import { Modulebox } from "~tokio/foundation";

export default {
  name: "ExamProcedure",
  components: {
    Modulebox,
  },

  data() {
    return {};
  },
};
</script>
