import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const createMutation = gql`
  mutation($exam: EffectivedExamInputType) {
    CreateEffectivedExam(exam: $exam) {
      exe_id
      exe_id_pacientes
    }
  }
`;

const updateMutation = gql`
  mutation($examId: Int, $exam: EffectivedExamInputType) {
    UpdateEffectivedExamValidated(examId: $examId, exam: $exam) {
      exe_id
      exe_id_pacientes
    }
  }
`;

const saveMutationBatch = gql`
  mutation($exams: [EffectivedExamInputType]) {
    SaveEffectivedExamBatch(exams: $exams) {
      exe_id
    }
  }
`;

const updateStatus = (commit, status, itemIndex) => {
  commit("SET_EXAM_STATUS_BY_INDEX", {
    itemIndex,
    status,
  });
};

/**
 * @param {*} commit
 * @param {{ itemIndex: Number, examId: Number, exam: Object } | { itemIndex: Number, examId: Number, exam: Object }[]} pData
 */
export default async ({ commit }, pData) => {
  let mutateObject = {};
  let returnedKey = "";

  if (Array.isArray(pData)) {
    returnedKey = "SaveEffectivedExamBatch";
    mutateObject = {
      variables: {
        exams: pData.map((item) => {
          if (item.exam.exe_id) delete item.exam.exe_data_solicitacao;
          return item.exam;
        }),
      },
      mutation: saveMutationBatch,
    };
  } else {
    const hasId = pData.examId && pData.examId > 0;
    returnedKey = !hasId
      ? "CreateEffectivedExam"
      : "UpdateEffectivedExamValidated";
    mutateObject = {
      variables: { examId: pData.examId, exam: pData.exam },
      mutation: !hasId ? createMutation : updateMutation,
    };
  }

  try {
    const { data } = await GraphQLClient.mutate(mutateObject);

    if (mutateObject.variables.exam) {
      updateStatus(
        commit,
        mutateObject.variables.exam.exe_id_exames_efetivacao,
        pData.itemIndex,
      );
    } else {
      pData.map((item, index) => {
        updateStatus(commit, item.exam.exe_id_exames_efetivacao, index);
      });
    }

    return data[returnedKey];
  } catch (err) {
    const jsonResponse = JSON.parse(JSON.stringify(err));
    return Promise.reject(jsonResponse.graphQLErrors[0].message);
  }
};
