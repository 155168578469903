<template lang="html">
  <div class="hospitalization-billing-work-accident">
    <form class="form">
      <RgValidatorForm ref="validator">
        <FormBase title="Acidente de Trabalho">
          <div class="hospitalization-billing-work-accident-info">
            <RgInputCnpj
              v-model="workAccident.iav_cnpj_empresa"
              :rules="{ required: true }"
              label="CNPJ da Empresa"
              class="inputitem"
            />
            <RgComboboxHospitalizationSocialSecurityBond
              v-model="workAccident.iav_id_internacoes_vinculos_previdencia"
              :rules="{ required: true }"
              label="Vínculo da previdência"
              class="inputitem"
            />
          </div>

          <div class="hospitalization-billing-work-accident-info">
            <RgTypeaheadHospitalizationCnaer
              v-model="workAccident.iav_id_internacoes_cnaer"
              :rules="{ required: true }"
              label="Atividade Econômica"
              placeholder="Digite a Atividade Econômica"
              class="inputitem"
            />
            <RgSuggestCbo
              v-model="workAccident.iav_codigo_ocupacoes_cbo"
              :rules="{ fn: validateCbo }"
              label="CBO"
              placeholder="Digite o Código do CBO"
              class="inputitem"
            />
          </div>
        </FormBase>
        <div class="buttons">
          <MediumButton
            label="Confirmar"
            backgroundColor="#0bb98c"
            @click="confirm"
          />

          <MediumButton
            title="Fechar"
            label="Fechar"
            @click.stop.prevent="close"
          />
        </div>
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { RgInputCnpj, RgValidatorForm, MediumButton } from "~tokio/primitive";
import RgComboboxHospitalizationSocialSecurityBond from "../../../combobox/rg-combobox-hospitalization-social-security-bond/RgComboboxHospitalizationSocialSecurityBond";
import RgTypeaheadHospitalizationCnaer from "../../../suggest/rg-typeahead-hospitalization-cnaer/RgTypeaheadHospitalizationCnaer";
import { RgSuggestCbo } from "$billing/common";
import { AlertError, ValidateCbo } from "../../../../service";
import FormBase from "~tokio/foundation/form-base/FormBase";
export default {
  name: "RgContentHospitalizationBillingWorkAccident",
  components: {
    RgInputCnpj,
    RgValidatorForm,
    RgComboboxHospitalizationSocialSecurityBond,
    RgTypeaheadHospitalizationCnaer,
    RgSuggestCbo,
    MediumButton,
    FormBase,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      workAccident: {
        type: "workAccident",
        iav_codigo_ocupacoes_cbo: "",
        iav_id_internacoes_vinculos_previdencia: "",
        iav_id_internacoes_cnaer: "",
        iav_cnpj_empresa: "",
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.workAccident = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.sanitizeFields();
        this.$emit("onFinish", this.workAccident);
      }
    },
    close() {
      this.$emit("close");
    },
    validateCbo(pCbo, pErrors) {
      return isEmpty(pCbo)
        ? AlertError("Campo obrigatório", pErrors)
        : ValidateCbo(
            this.procedure.iap_codigo_procedimentos,
            pCbo,
            this.procedure.iap_competencia,
            pErrors,
          );
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },
    sanitizeFields() {
      this.workAccident.iav_cnpj_empresa = this.workAccident.iav_cnpj_empresa
        ? this.workAccident.iav_cnpj_empresa.replace(/[^\d]+/g, "")
        : null;
    },
  },
};
</script>
