import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import HandleActionError from "@/common/request/error-handlers/HandleActionError";

const query = gql`
  query PharmacyProductCounty(
    $mpd_id_clientes: Int!
    $mpd_codigo: String
    $mpd_novo_principio_ativo: String
    $limit: Int
  ) {
    pharmacyProductCounty(
      mpd_id_clientes: $mpd_id_clientes
      mpd_codigo: $mpd_codigo
      mpd_novo_principio_ativo: $mpd_novo_principio_ativo
      limit: $limit
    ) {
      count
      rows {
        mpd_id
        mpd_novo_principio_ativo
        mpd_codigo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.pharmacyProductCounty.rows;
  } catch (error) {
    return Promise.reject(HandleActionError(error));
  }
};
