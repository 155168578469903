import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People(
    $peopleName: String
    $isSearchable: Boolean
    $crs_numero: String
    $notUnified: Boolean
    $filterByCompetence: Boolean
    $currentDateCompetence: String
    $limit: Int
    $offset: Int
  ) {
    people(
      pes_nome: $peopleName
      isPatient: true
      isSearchable: $isSearchable
      notUnified: $notUnified
      filterByCompetence: $filterByCompetence
      currentDateCompetence: $currentDateCompetence
      cns: $crs_numero
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        pes_id
        pes_nome
        pes_nascimento
        pes_email
        pes_id_ab_etnias
        pes_mae
        gender {
          sex_sigla
        }
        ethnicity {
          etn_id
          etn_nome
          etn_codigo_sisaih
        }
        ethnicityAb {
          ens_id
          ens_descricao
          ens_codigo_ab
        }
        nationality {
          nac_id
          nac_nome
          nac_codigo
        }
        address {
          end_id
          end_cep
          end_logradouro
          end_numero
          end_complemento
          publicPlaceType {
            tlg_id
            tlg_nome
            tlg_codigo
          }
          neighborhood {
            bai_nome
            city {
              mun_id
              mun_nome
              mun_codigo
              state {
                est_id
                est_sigla
                est_codigo
              }
            }
          }
        }
        cns {
          crs_id
          crs_numero
        }
        patient {
          pac_id
          pac_prontuario_unico
        }
        telephones {
          rows {
            tel_id
            tel_id_ddds
            tel_numero
            tel_id_pessoas
            ddd {
              ddd_codigo
              ddd_id
            }
          }
        }
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  data.people.rows.map((item) => {
    const formatCns = item?.cns?.crs_numero
      ? item.cns.crs_numero
      : "000000000000000";
    item.cns.crs_numero = formatCns;
  });

  return data.people;
};
