<template lang="html">
  <div v-if="show" class="modal-edit-externally-schedule">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      class="rg-base-modal"
      with-close-button
      :show="show"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Editar Agendamento Externo</div>
      </div>

      <div slot="body" class="body">
        <div class="area">
          <span class="data">
            Paciente:
            <strong>
              {{ getValue(externallyScheduleData, "pes_nome") }}
            </strong>
          </span>

          <span class="data">
            Unidade de Origem:
            <strong>
              {{ getValue(externallyScheduleData, "lex_nome") }}
            </strong>
          </span>
        </div>

        <FormBase title="Dados do Agendamento" class="form">
          <RgSelectExternalLocation
            id="external-location"
            v-model="form.externalLocation"
            :rules="{ required: true }"
            :IdModulo="moduleId"
            class="external-location"
            label="Local de Destino"
          />

          <RgTextArea
            id="observation"
            ref="textArea"
            v-model="form.observation"
            :maxlength="500"
            label="Observações"
            class="textarea"
          />
        </FormBase>

        <div slot="footer" class="footer">
          <RgCancelButton id="cancel-btn" medium @click="cancel" />
          <RgSaveButton id="save-btn" medium @click="save" />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgTextArea,
  RgSaveButton,
  RgCancelButton,
  RgSelectExternalLocation,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalEditExternallySchedule",
  components: {
    RgBaseModal,
    RgSelectExternalLocation,
    RgTextArea,
    FormBase,
    RgSaveButton,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    moduleId: {
      type: Number,
      default: 0,
    },
    moduleType: {
      type: Number,
      default: 2,
    },
    externallyScheduleData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        externalLocation: null,
        observation: null,
      },
    };
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.$loader.start("Carregando agendamento externo...");
        this.form.externalLocation = this.externallyScheduleData.aex_id_locais_externos;
        this.form.observation = this.externallyScheduleData.aex_observacao;
        this.$loader.finish();
      }
    },
  },

  created() {
    this.TYPE_MODULE = {
      APPOINTMENT: 1,
      EXAM: 2,
      HOSPITALIZATION: 3,
    };
  },

  methods: {
    getValue(pItem, pKey) {
      return pItem[pKey] || "Não informado";
    },

    async save() {
      try {
        this.$loader.start();
        const path = this.getModuleType().saveRoute;

        const variables = {
          aex_id: this.externallyScheduleData.aex_id,
          aex_id_locais_externos: this.form.externalLocation,
          aex_observacao: this.form.observation,
          idModulo: this.moduleId,
        };

        await this.$store.dispatch(path, variables);
        this.$toaster.success("Paciente alterado com sucesso");

        this.$loader.finish();
        this.close();
        this.$emit("reSearch", true);
      } catch (pErr) {
        this.$loader.finish();
        this.$toaster.error(pErr, "Erro ao tentar alterar o paciente");
      }
    },

    clean() {
      this.form = {
        externalLocation: null,
        observation: null,
      };
    },

    cancel() {
      this.clean();
      this.$emit("close");
    },

    close() {
      this.clean();
      this.$emit("close");
    },

    getModuleType() {
      switch (this.moduleType) {
        case this.TYPE_MODULE.APPOINTMENT:
          return {
            saveRoute: "Appointment/Schedule/EDIT_EXTERNALLY_SCHEDULE",
          };
        case this.TYPE_MODULE.EXAM:
          return {
            saveRoute: "Exam/Schedule/EDIT_EXTERNALLY_SCHEDULE",
          };
        case this.TYPE_MODULE.HOSPITALIZATION:
          return {
            saveRoute: "Hospitalization/Queue/EDIT_EXTERNALLY_SCHEDULE",
          };
      }
    },
  },
};
</script>
