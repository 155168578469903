var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rg-radio-custom",class:{ inline: _vm.inline }},[_c('RgFormBase',{attrs:{"label":_vm.label,"required":_vm.isRequired},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleStatus.apply(null, arguments)}}},[_c('div',{attrs:{"slot":"right-label"},slot:"right-label"},[_c('RgValidationAlert',{attrs:{"alert":_vm.error}})],1),_c('section',{staticClass:"grid-radio",class:{
        reorganize: _vm.reorganize,
        'radio-custom-carousel': _vm.carousel,
        'radio-custom': !_vm.carousel,
        margin: _vm.label !== null,
      },style:({ gridTemplateColumns: `repeat(${_vm.listSize}, ${_vm.colWidth}fr)` })},_vm._l((_vm.list),function(el,idx){return _c('div',{key:`rc-${idx}`,staticClass:"radio-container unselect",class:{
          active:
            el.active || _vm.selectedList.includes(el.id) || el.id === _vm.value,
          mg: _vm.reorganize,
          'disabled-by-modal':
            _vm.disabledByModal ||
            (_vm.disableByComponent && _vm.disableByComponent.includes(el.id)),
          blockfield: _vm.disabled,
          'inline-size': _vm.inline,
        },style:(` ${
          el.active || _vm.selectedList.includes(el.id) || el.id === _vm.value
            ? 'background-color:' + el.color
            : ''
        };
         ${el.color ? 'border-color:' + el.color : ''};
         ${el.color ? 'color:' + el.color : ''};
         ${
           el.gridStart && el.gridEnd
             ? 'grid-column:' + el.gridStart + '/' + el.gridEnd
             : ''
         };
         `),attrs:{"id":_vm.replaceString(el.title)},on:{"click":function($event){return _vm.onClickContainer(el, idx)}}},[_c('CentralizerIcons',{attrs:{"iconId":el.icon,"color":el.color}}),_vm._v(" "+_vm._s(el.title)+" ")],1)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }