import Store from "@/store";

function UserGroupAuth(to, __, next) {
  const restrictTo = to?.meta?.restrictTo;
  const hasRestriction = Array.isArray(restrictTo) && restrictTo?.length > 0;

  if (hasRestriction) {
    const userGroup = Store.getters["Login/GET_USER_ID_GROUP"];

    const isAllowed = restrictTo.includes(userGroup);

    isAllowed ? next() : next("/");
  }

  next();
}

export default UserGroupAuth;
export { UserGroupAuth };
