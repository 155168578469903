<template>
  <div class="hospitalization-discharged-report">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-hdr"
          v-model="form.uns_id"
          permission="internacao.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-hdr"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{
            required: true,
          }"
          pop-up="bottom"
        />
        <RgInputDate
          id="final-date-hdr"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem mg"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          :rules="{ required: true }"
          pop-up="bottom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgSelectUnithealth } from "~tokio/primitive";
const moment = require("moment");

export default {
  name: "HospitalizationDischargedReport",
  components: {
    RgInputDate,
    RgSelectUnithealth,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isRequired: false,
      resultTitle: "Alta de Internação",
      form: {
        initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        uns_nome: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        orderby: 1,
        orderByLabel: "Paciente",
      },
      legend: "",
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left }" +
        ".report-column-4 { white-space: normal !important; text-align: left }" +
        ".report-column-5 { white-space: normal !important; text-align: left }" +
        ".report-column-6 { white-space: normal !important; text-align: left }" +
        ".report-column-7 { white-space: normal !important; text-align: left }" +
        ".report-column-8 { white-space: normal !important; text-align: left }" +
        ".report-column-9 { white-space: normal !important; text-align: left }"
      );
    },

    columnTable() {
      return this.COLUMN;
    },

    getNameSmartTable() {
      return "HospitalizationDischargedReport";
    },

    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },

    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },

    getLegend() {
      return this.legend;
    },
  },

  watch: {
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Nascimento", key: "NASCIMENTO" },
      { name: "Data da Saída", key: "DATA_SAIDA" },
      { name: "Tipo de Alta", key: "TIPO_ALTA", align: "left" },
      { name: "Endereço", key: "ENDERECO", align: "left" },
      { name: "Bairro", key: "BAIRRO", align: "left" },
      { name: "Município", key: "MUNICIPIO", align: "left" },
      { name: "Telefone", key: "TELEFONE", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
    ];
  },

  mounted() {},

  methods: {
    buildFilter() {
      this.mountLegend();
      return {
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
        uns_id: this.form.uns_id,
        orderby: this.form.orderby,
        orderByLabel: this.form.orderByLabel,
        columnsToPrint: this.columnsToPrint,
      };
    },

    mountLegend() {
      this.legend = this.form.uns_nome;
    },

    async doSearch(pData) {
      try {
        this.$loader.start("Carregando...");

        return this.$store.dispatch(
          "Hospitalization/Report/GET_HOSPITALIZATION_DISCHARGED_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish(1000);
      }
    },

    clearFilter() {
      this.form = {
        initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        uns_nome: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
      };
    },
  },
};
</script>
