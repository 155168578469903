import moment from "moment";
import LocalStorage from "~common/local-storage/LocalStorage";

const upperCase = (pData) => {
  return pData ? pData.toUpperCase() : pData;
};

const DetailsPatientCareAppointmentScheduleHTML = async (
  pInfo,
  pDataHistory,
  pSelectedLine,
  pScheduleDetail,
) => {
  const logo = LocalStorage.getObject("client-logo");

  const emissao = moment().format("DD/MM/YYYY");
  let body = "";

  pScheduleDetail.forEach((item) => {
    body += `<div class="title">`;
    body += `<span>${
      upperCase(item.tlc_nome) || upperCase(item.tlg_nome) || ""
    } - ${item.data_hora_log}</span>`;
    body += `</div>`;

    body += `<hr class="border" style="margin: 4px 0; border-color: #000000"/>`;

    body += `<section class="data">`;
    if (item.pfi_nome) {
      body += `<span>Prioridade: <strong>${item.pfi_nome}</strong></span>`;
    }

    if (item.pes_nome) {
      body += `<span>Solicitante: <strong>${item.pes_nome}</strong></span>`;
    }

    if (
      item.ocp_nome ||
      item.flc_nome_ocupacao ||
      item.flc_nome_sinonimos_ocupacoes
    ) {
      body += `<span>Ocupação: <strong>${
        item.ocp_nome ||
        item.flc_nome_ocupacao ||
        item.flc_nome_sinonimos_ocupacoes
      }</strong></span>`;
    }

    if (pSelectedLine.uns_nome) {
      body += `<span>Unidade Solicitante: <strong>${pSelectedLine.uns_nome}</strong></span>`;
    }

    if (item.uns_nome && item.tipo === "Agendamento") {
      body += `<span>Unidade Destino: <strong>${item.uns_nome}</strong></span>`;
    }

    if (
      item.data_agendamento &&
      item.tipo === "Agendamento" &&
      !upperCase(item.tlc_nome).includes("EFETIVADO")
    ) {
      body += `<span>Data Agendamento: <strong>${item.data_agendamento}</strong></span>`;
    }

    if (item.data_inserido_fila) {
      body += `<span>Data da Solicitação: <strong>${item.data_inserido_fila}</strong></span>`;
    }

    if (item.pes_nome_consulta_funcionario) {
      body += `<span>Profissional: <strong>${item.pes_nome_consulta_funcionario}</strong></span>`;
    }

    if (
      item.efc_nome &&
      item.tlc_nome !== "EXCLUÍDO" &&
      item.tlg_nome !== "EXCLUÍDO"
    ) {
      body += `<span>Status Efetivação: <strong>${item.efc_nome}</strong></span>`;
    }

    if (item.pls_nome) {
      body += `<span>Convênio: <strong>${item.pls_nome}</strong></span>`;
    }

    if (item.lca_nome) {
      body += `<span>Local de Atendimento: <strong>${item.lca_nome}</strong></span>`;
    }

    if (item.usu_nome) {
      body += `<span>Usuário: <strong>${item.usu_nome}</strong></span>`;
    }

    if (item.cnl_obs || item.flg_obs) {
      body += `<span>Motivo Exclusão: <strong>
      ${upperCase(item.cnl_obs) || upperCase(item.flg_obs) || ""}
      </strong></span>`;
    }

    if (item.tcu_novo_tipo_consulta) {
      body += `<span>Objetivo: <strong>${item.tcu_novo_tipo_consulta}</strong></span>`;
    }

    body += `</section>`;
  });

  const html = `
  <!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <title>Detalhes do Atendimento Prestado ao Paciente</title>
      <style type="text/css">

      * {
        margin: 0;
        padding: 0;
        font-family: "Montserrat", sans-serif;
      }

      body {
        display: block;
        margin: 2em;
        font-family: "Montserrat", sans-serif;
        color: #444444;
      }

      div {
        width: 100%;
      }

      .mg-l-05 {
        margin-left: 0.5cm;
      }

      header,
      footer,
      aside,
      nav,
      form,
      iframe {
        display: none;
      }

      /* print styles  */
      @media print {
        body {
          margin: 0;
          background-color: #fff;
          font-size: 12pt;
        }

        p {
          widows: 3;
          orphans: 3;
        }
      }

      /* HEADER */

      .header {
        height: 11rem;
      }

      .img-header {
        width: 2cm;
        border: 1px solid #CFCFCF50;
        padding: 4px;
        border-radius: 10px;
      }

      .border {
        border: 0px;
        border-top: 1px solid #CFCFCF50;
      }

      .esus {
        height: 48px;
        width: 48px;
      }

      .center {
        text-align: center;
        justify-content:center;
        align-self: center;
      }

      .weight {
        font-weight: bolder;
      }

      .title-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 11pt;
        gap: 15px;
        margin-bottom: 15px;
        font-family: "Montserrat", sans-serif;
      }

      .info-header {
        display: grid;
        grid-template-columns: 1fr 0fr;
        justify-content: space-between;
        align-items: center;
        font-size: 9pt;
        font-family: "Montserrat", sans-serif;
        margin: 10px 0;
      }

      .info-header-data {
        display: grid;
        grid-template-columns: 1fr 0.5fr;
        row-gap: 16px;
        font-family: "Montserrat", sans-serif;
      }

      /* FILTER */
      .filter {
        display: grid;
        row-gap: 0.3cm;
        column-gap: 0.5cm;
        width: fit-content;
        justify-content: space-between;
      }

      .margin-center-filter {
        margin: 0 0.3cm;
      }

      img {
        width: 1.8cm;
        height: 1.9cm;
        margin-top: 2px;
      }

      /* BODY */
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 25px;
        font-size: 11pt;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
      }

      .details {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 10px;
        font-size: 10pt;
        margin: 15px 0;
        font-family: "Montserrat", sans-serif;
      }

      .data {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 16;
        gap: 8px;
        margin: 16px 0;
        font-size: 9pt;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
      }
    </style>

    <page size="A4">

      <section class="header">
        <section class="title-header">
          <img src="/static/images/mini-logo-esus.png" class="img-header esus"/>
          <h1>Detalhes do Atendimento Prestado ao Paciente</h1>
        </section>

        <hr class="border" />

        <section class="info-header">
          <div class="info-header-data">
            <span class="text">
              <strong>Unidade de Saúde: </strong>
              ${pInfo.unitHealthData.uns_nome}
              / ${pInfo.unitHealthData.mun_nome}
              - ${pInfo.unitHealthData.est_sigla}
              ${pInfo.unitHealthData.bai_nome}
              - Nº ${pInfo.unitHealthData.end_numero}
            </span>

            <span class="text" style="margin-left: auto; margin-right: 30px">
              <strong>Data: </strong> <span class="text"> ${emissao} </span>
            </span>

            <span class="text">
              <strong>Usuário: </strong>
              <span class="text"> ${pInfo.userName} </span>
            </span>
          </div>

          <div>
            <img src="${logo}" style="margin-left: auto;">
          </div>
        </section>
      </section>

      <hr class="border" />

      <section class="details">
        <p style="grid-area: 1/4/1/1">
          <strong>Paciente: </strong>
          <span class="text"> ${pInfo.patientName || pInfo.pac_nome}</span>
        </p>
        <p>
          <strong>Módulo: </strong>
          <span class="text"> ${pDataHistory.nome_modulo}</span>
        </p>
        <p>
          <strong>Setor: </strong>
          <span class="text"> ${pDataHistory.set_nome}</span>
        </p>
        <p>
          <strong>Data da última modificação: </strong>
          <span class="text"> ${pDataHistory.data}</span>
        </p>
        <p>
          <strong>Regulação: </strong>
          <span class="text"> ${pDataHistory.regulacao}</span>
        </p>
        <p>
          <strong>Alteração: </strong>
          <span class="text"> ${pDataHistory.possui_alteracao}</span>
        </p>
      </section>
    </head>

    <body>
      ${body}
    </page>
  </body>
</html>
  `;

  return html;
};

export default DetailsPatientCareAppointmentScheduleHTML;
