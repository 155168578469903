export default {
  GET_DATA_IMPORT_SIGTAP_BDSIA: (state) => {
    return {
      name_file_sigtap: state.name_file_sigtap,
      name_file_bdsia: state.name_file_bdsia,
      cmp_import_sigtap: state.cmp_import_sigtap,
      cmp_import_bdsia: state.cmp_import_bdsia,
      date_import_sigtap: state.date_import_sigtap,
      date_import_bdsia: state.date_import_bdsia,
    };
  },
};
