<style src="./RgSuggestSmartPerson.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient">
    <div
      v-if="filterBulletinHospitalization"
      class="filter_bulletin_hspitalization"
    >
      <label for="buletinOrHospitalization">
        <input
          id="buletinOrHospitalization"
          v-model="buletinOrHospitalization"
          value="0"
          type="checkbox"
          :disabled="disabled"
        />
        Buscar por boletim ou internação
      </label>
    </div>
    <RgSuggest
      :id="id"
      ref="innerSuggest"
      v-model="inputValue"
      :disabled="disabled"
      :class="{ disable: disabled }"
      :data-id="dataId"
      :placeholder="placeholderSuggest"
      :rules="{ ...rules, fn: validateField }"
      :label="labelSuggest"
      :confirmToRemove="confirmToRemove"
      :message="message"
      :mask="mask"
      :diff-for-large-list="65"
      :auto-select-if-has-one="autoSelectIfHasOne"
      :min="minLength"
      @itemSelected="clearMask"
      @clean="setMask"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataItem"
        :idx="idx"
        :item="item"
        class="patient-item"
        @itemSelected="clearMask"
      >
        <div
          :class="{ 'field-search': colFilter.col === 'peopleName' }"
          class="pes-name"
          title="Nome"
        >
          <IconUser />
          <span class="mg-l-5">
            Nome: <strong> {{ item.pes_nome }} </strong>
          </span>
        </div>

        <div :class="{ 'field-search': colFilter.col === 'birthDate' }">
          <IconCalendar />
          <span class="mg-l-5">
            Nascimento :
            <strong> {{ item.pes_nascimento | dateBR }} </strong>
          </span>
        </div>

        <div class="pes-mother" title="Nome da Mãe">
          <IconGenderFemale />
          <span class="mg-l-5">
            Mãe: <strong> {{ item.pes_mae }} </strong>
          </span>
        </div>

        <div
          v-if="colFilter.col === 'bulletin'"
          :class="{ 'field-search': colFilter.col === 'bulletin' }"
        >
          <IconSector />
          <span class="mg-l-5">
            Setor:
            <strong>{{
              item.patient &&
              item.patient.patient_emergency &&
              item.patient.patient_emergency.bulletin &&
              item.patient.patient_emergency.bulletin.sector.set_nome
                ? item.patient.patient_emergency.bulletin.sector.set_nome
                : ""
            }}</strong>
          </span>
        </div>

        <div class="group">
          <div :class="{ 'field-search': colFilter.col === 'cns' }">
            <IconCard />
            <span class="mg-l-5">
              CNS:
              <strong> {{ item.cns && item.cns.crs_numero | Cns }} </strong>
            </span>
          </div>

          <div :class="{ 'field-search': colFilter.col === 'cpf' }">
            <IconCard />
            <span class="mg-l-5">
              CPF: <strong> {{ item.cpf.cpf_numero | Cpf }} </strong>
            </span>
          </div>
        </div>

        <div :class="{ 'field-search': colFilter.col === 'recordNumber' }">
          <IconCard />
          <span class="mg-l-5">
            Prontuário Único:
            <strong>
              {{ item.patient ? item.patient.pac_prontuario_unico : "" }}
            </strong>
          </span>
        </div>

        <div :class="{ 'field-search': colFilter.col === 'recordUnitNumber' }">
          <IconCard />
          <span class="mg-l-5">
            Prontuário da Unidade:
            <strong>
              {{
                item.patient &&
                item.patient.record_numbers &&
                item.patient.record_numbers.rows[0]
                  ? item.patient.record_numbers.rows[0].ppr_numero
                  : ""
              }}
            </strong>
          </span>
        </div>
      </RgSuggestItem>
    </RgSuggest>

    <div v-if="showOptionRadios" class="filter-suggest-patient">
      <div v-for="(item, idx) in validFilters" :key="idx" class="input">
        <input
          :id="item.col"
          v-model="colFilter"
          :value="item"
          :disabled="disabled"
          type="radio"
          name="filter"
        />
        <span class="align-span">
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import {
  IconCalendar,
  IconGenderFemale,
  IconUser,
  IconCard,
  IconSector,
} from "~tokio/primitive/icon";
import { mapGetters } from "vuex";
import { SearchPerson, SearchPersonById } from "./action";

import moment from "moment";

export default {
  name: "RgSuggestSmartPerson",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconCalendar,
    IconGenderFemale,
    IconCard,
    IconUser,
    IconSector,
  },
  filters: {
    Cns(pValue) {
      if (!pValue) return "N/A";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },

    Cpf(pValue) {
      if (!pValue) return "N/A";
      return pValue;
    },

    dateBR(pValue) {
      const isValid = moment(pValue).isValid();
      return isValid
        ? moment(pValue).format("DD/MM/YYYY")
        : "Data não informada";
    },
  },
  mixins: [RgSuggestMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removeFilter: {
      type: Array,
      default: () => [],
    },
    enabledPatient: {
      type: Boolean,
      default: false,
    },
    withPatientModule: {
      type: Array,
      default: function () {
        return [];
      },
    },
    extraData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showOptionRadios: {
      type: Boolean,
      default: true,
    },
    permitFreeText: {
      type: Boolean,
      default: false,
    },
    filterOnlyDocument: Boolean,
    filterBulletinHospitalization: Boolean,
    activePatient: Number,
    autoSelectIfHasOne: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      captionRaw: "pes_nome",
      colFilter: { label: "Nome", col: "peopleName" },
      filters: [
        { label: "Nome", col: "peopleName" },
        { label: "Data de Nascimento", col: "birthDate" },
        { label: "CPF", col: "cpf" },
        { label: "CNS", col: "cns" },
      ],
      documentFilters: [
        { label: "Prontuário Único", col: "recordNumber" },
        { label: "Prontuário da Unidade", col: "recordUnitNumber" },
        { label: "CPF", col: "cpf" },
        { label: "CNS", col: "cns" },
      ],
      bulletinHospitalizationFilter: [
        { label: "Boletim", col: "bulletin" },
        { label: "Internação", col: "hospitalization" },
      ],
      mask: null,
      minLength: "3",
      validfilters: [],
      buletinOrHospitalization: false,
      searchByModal: false,
    };
  },
  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),
    recordNumberPreference() {
      return Boolean(
        this.$store.getters["Login/GET_RECORD_NUMBER_PREFERENCES"],
      );
    },
    existFilter() {
      return this.hasFilter && this.hasFilter.length > 0;
    },
    labelSuggest() {
      this.reset();
      if (this.filterOnlyDocument) {
        return "Documentação";
      } else {
        return this.label?.length > 0
          ? this.label
          : "Escolha uma das opções abaixo e faça a busca";
      }
    },
    placeholderSuggest() {
      switch (this.colFilter.col) {
        case "peopleName":
          return "Digite o nome do paciente";
        case "cpf":
          return "Digite o CPF do paciente";
        case "cns":
          return "Digite o CNS do paciente";
        case "recordNumber":
          return "Digite o prontuário único do paciente";
        case "recordUnitNumber":
          return "Digite o prontuário do paciente na unidade";
        case "birthDate":
          return "Digite a data de nascimento do paciente";
        case "bulletin":
          return "Digite o número do boletim";
        case "hospitalization":
          return "Digite o número da internação";
        default:
          return "Documento";
      }
    },
    withPatientAppointment() {
      return this.withPatientModule.includes("appointment");
    },
    withPatientExam() {
      return this.withPatientModule.includes("exam2");
    },
    withPatientHospitalization() {
      return this.withPatientModule.includes("hospitalization");
    },
    withPatientEmergency() {
      return this.withPatientModule.includes("emergency");
    },
    withEmergency() {
      return this.withPatientModule.includes("emergency_data");
    },
    withHospitalization() {
      return this.withPatientModule.includes("hospitalization_data");
    },
    withTelephone() {
      return this.extraData.includes("telephone");
    },
    withEthnicity() {
      return this.extraData.includes("ethnicity");
    },
    withNaturality() {
      return this.extraData.includes("naturality");
    },
    withNationality() {
      return this.extraData.includes("nationality");
    },
    withoutAddress() {
      return this.extraData.includes("withoutAddress");
    },
  },
  watch: {
    "colFilter.col"(pValue) {
      if (this.filterOnlyDocument && this.$refs.innerSuggest) {
        this.$refs.innerSuggest.cleanSuggest();
      }
      this.setMask();
      this.$emit("cleanPerson");
    },
    inputValue(pValue) {
      if (!pValue) {
        this.$store.commit("Person/Patient/DESTROY_PERSON_INFO");
        this.cleanValidate();
      }
    },
    buletinOrHospitalization(pValue) {
      this.setDefaultFilter();
      this.$refs.innerSuggest.cleanSuggest();
    },
  },

  created() {
    this.setDefaultFilter();
  },

  mounted() {
    this.setMask();
  },

  methods: {
    setDefaultFilter() {
      const hasUnitRecordNumber = this.filters?.some(
        (unitRecordNumber) => unitRecordNumber.col === "recordUnitNumber",
      );
      const hasRecordNumber = this.filters?.some(
        (unitRecordNumber) => unitRecordNumber.col === "recordNumber",
      );

      if (this.recordNumberPreference && !hasUnitRecordNumber) {
        this.filters.push({
          label: "Prontuário da Unidade",
          col: "recordUnitNumber",
        });
      } else {
        if (!hasRecordNumber) {
          this.filters.push({ label: "Prontuário Único", col: "recordNumber" });
        }
      }

      if (this.filterOnlyDocument) {
        this.validFilters = this.documentFilters;
      } else if (this.buletinOrHospitalization) {
        this.validFilters = this.bulletinHospitalizationFilter;
      } else {
        this.validFilters = this.filters.filter(
          (filter) =>
            !this.removeFilter.find((remove) => remove.label === filter.label),
        );
      }

      if (this.filterOnlyDocument) {
        this.colFilter =
          this.validFilters &&
          this.validFilters[Number(this.recordNumberPreference)];
      } else {
        this.colFilter = this.validFilters && this.validFilters[0];
      }
    },

    emitData(pData) {
      this.setDefaultFilter();

      this.$refs.innerSuggest.itemSelected = true;

      const withoutSuggestionList =
        !this.suggestionList ||
        (this.suggestionList && this.suggestionList.length === 0);

      if (withoutSuggestionList) {
        this.suggestionList.push({
          ...pData,
        });
      }

      this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pData);
      this.searchByModal = true;
    },

    async doSearch(pSearchTerm, pLimit = null) {
      try {
        const variables = this.mountFilter(pSearchTerm);

        variables.unitHealth = this.unitHealthId;
        variables.limit = pLimit;
        variables.withPatientAppointment = this.withPatientAppointment;
        variables.withPatientExam = this.withPatientExam;
        variables.withPatientHospitalization = this.withPatientHospitalization;
        variables.withPatientEmergency = this.withPatientEmergency;
        variables.withEmergency = this.withEmergency;
        variables.withHospitalization = this.withHospitalization;
        // ALTERADO PARA TRUE PARA EXIBIÇÃO GLOBAL DO TELEFONE
        variables.withTelephone = true;
        variables.withEthnicity = this.withEthnicity;
        variables.withNaturality = this.withNaturality;
        variables.withNationality = this.withNationality;
        variables.withoutAddress = this.withoutAddress;

        variables.active = true;
        variables.activePatient = this.activePatient
          ? [this.activePatient]
          : null;

        if (this.enabledPatient) variables.isSearchable = this.enabledPatient;
        if (this.searchByModal) this.$loader.start();

        const person = await SearchPerson(variables);
        return person;
      } catch (e) {
        this.$toaster.error("Erro ao trazer as informações");
      } finally {
        if (this.searchByModal) {
          this.$loader.finish();
          this.searchByModal = false;
        }
      }
    },

    mountFilter(pSearchTerm) {
      switch (this.colFilter.col) {
        case "cpf":
          return { cpf: pSearchTerm.replace(/\.|-/gm, "") };
        case "cns":
          return { cns: pSearchTerm.replace(/\.|-/gm, "") };
        case "recordNumber":
          return { recordNumber: pSearchTerm.toString().padStart(10, "0") };
        case "recordUnitNumber":
          return {
            recordUnitNumber: pSearchTerm.toString(),
          };
        case "birthDate":
          return { birthDate: this.formatDate(pSearchTerm) };
        case "bulletin":
          return { bulletinNumber: Number(pSearchTerm) };
        case "hospitalization":
          return { hospitalizationNumber: Number(pSearchTerm) };
        default:
          return { peopleName: pSearchTerm };
      }
    },

    async fillPatientById(pPersonId) {
      const variables = {
        pesId: Number(pPersonId),
        withPatientAppointment: this.withPatientAppointment,
        withPatientExam: this.withPatientExam,
        withPatientHospitalization: this.withPatientHospitalization,
        withPatientEmergency: this.withPatientEmergency,
        withEmergency: this.withEmergency,
        withHospitalization: this.withHospitalization,
        // ALTERADO PARA TRUE PARA EXIBIÇÃO GLOBAL DO TELEFONE
        withTelephone: true,
        withEthnicity: this.withEthnicity,
        withNaturality: this.withNaturality,
        withNationality: this.withNationality,
      };

      if (this.withEmergency || this.withHospitalization) {
        variables.unitHealth = this.unitHealthId;
      }
      const patientData = await SearchPersonById(variables);

      const objPatient = await this.mountObjectPatient(patientData);

      this.forceSelection(objPatient);
      this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");

      return patientData;
    },

    async mountObjectPatient(patientData) {
      const mun_nome =
        patientData.address?.neighborhood?.city?.mun_nome ||
        patientData.mun_nome;

      const objPatient = {
        pes_id: patientData.pes_id,
        pes_nome: patientData.pes_nome,
        pac_nome: patientData.pes_nome,
        mun_nome: mun_nome,
        pes_nascimento: patientData.pes_nascimento,
        sex_sigla: patientData.gender.sex_sigla,
        pes_mae: patientData.pes_mae,
        pes_pai: patientData.pes_pai,
        telephones: patientData.telephones,
        cpf: patientData && patientData.cpf ? patientData.cpf : null,
        pac_prontuario_unico:
          patientData && patientData.patient
            ? patientData.patient?.pac_prontuario_unico
            : null,
        ppr_numero:
          patientData?.patient?.record_numbers &&
          patientData.patient.record_numbers.rows.length > 0
            ? patientData.patient?.record_numbers.rows[0].ppr_numero
            : null,
        cns: patientData && patientData.cns ? patientData.cns : null,
        patient: {
          pac_id:
            patientData && patientData.patient
              ? patientData?.patient?.pac_id
              : null,
          pac_prontuario_unico:
            patientData && patientData.patient
              ? patientData.patient?.pac_prontuario_unico
              : null,
          pac_id_motivo_inabilitacao:
            patientData && patientData.patient
              ? patientData.patient?.pac_id_motivo_inabilitacao
              : null,
        },
        address: {
          end_cep: patientData.address?.end_cep,
          end_complemento: patientData.address?.end_complemento,
          end_logradouro: patientData.address?.end_logradouro,
          end_numero: patientData.address?.end_numero,
          neighborhood: {
            bai_id: patientData.address?.neighborhood?.bai_id,
            bai_nome: patientData.address?.neighborhood?.bai_nome,
            city: {
              mun_nome: patientData.address?.neighborhood?.city?.mun_nome,
            },
          },
          publicPlaceType: {
            tlg_id: patientData.address?.publicPlaceType?.tlg_id,
            tlg_nome: patientData.address?.publicPlaceType?.tlg_nome,
          },
        },
      };

      if (this.withPatientAppointment) {
        const auxData = patientData.patient.patient_appointment;

        objPatient.patient.patient_appointment = {
          pcc_ativo: auxData.pcc_ativo,
          pcc_id: auxData.pcc_id,
          pcc_id_pacientes: auxData.pcc_id_pacientes,
        };
      }

      if (this.withPatientExam) {
        const auxData = patientData.patient.patient_exam;

        objPatient.patient.patient_exam = {
          pxe_id: auxData.pxe_id,
          pxe_id_pacientes: auxData.pxe_id_pacientes,
        };
      }

      if (this.withPatientHospitalization) {
        const auxData = patientData.patient.patient_hospitalization;

        objPatient.patient.patient_hospitalization = {
          pci_ativo: auxData.pci_ativo,
          pci_id: auxData.pci_id,
          pci_id_pacientes: auxData.pci_id_pacientes,
        };

        if (
          this.withHospitalization &&
          patientData.patient.patient_hospitalization.hospitalization
        ) {
          const auxData =
            patientData.patient.patient_hospitalization.hospitalization;

          objPatient.patient.patient_hospitalization.hospitalization = {
            int_id: auxData.int_id,
            int_numero: auxData.int_numero,
          };
        }
      }

      if (this.withPatientEmergency) {
        const auxData = patientData.patient.patient_emergency;

        objPatient.patient.patient_emergency = {
          pce_ativo: auxData.pce_ativo,
          pce_id: auxData.pce_id,
          pce_id_pacientes: auxData.pce_id_pacientes,
        };

        if (
          this.withEmergency &&
          patientData.patient.patient_emergency.bulletin
        ) {
          const auxData = patientData.patient.patient_emergency.bulletin;

          objPatient.patient.patient_emergency.bulletin = {
            bol_id: auxData.bol_id,
            bol_numero: auxData.bol_numero,
            bol_id_pacientes_emergencia: auxData.bol_id_pacientes_emergencia,
            bol_id_setores: auxData.bol_id_setores,
            sector: {
              set_nome: auxData.sector.set_nome,
            },
          };
        }
      }

      if (this.withTelephone) {
        const auxData = patientData.telephones.rows;

        objPatient.telephones = {
          rows: auxData,
        };
      }

      if (this.withEthnicity) {
        const auxData = patientData.ethnicity;

        objPatient.ethnicity = {
          etn_codigo_sisaih: auxData.etn_codigo_sisaih,
          etn_id: auxData.etn_id,
          etn_nome: auxData.etn_nome,
        };
      }

      if (this.withNaturality) {
        const auxData = patientData.city;

        objPatient.city = {
          mun_id: auxData.mun_id,
          mun_nome: auxData.mun_nome,
          state: {
            est_id: auxData.state?.est_id,
            est_nome: auxData.state?.est_nome,
            est_sigla: auxData.state?.est_sigla,
          },
        };
      }

      if (this.withNationality) {
        const auxData = patientData.nationality;

        objPatient.nationality = {
          nac_id: auxData.nac_id,
          nac_nome: auxData.nac_nome,
        };
      }

      return objPatient;
    },

    forceSelection(pValue) {
      const hasPersonPatientData =
        pValue &&
        !isEmpty(pValue.pes_nome) &&
        pValue.patient &&
        pValue.patient.pac_id > 0;

      if (this.permitFreeText) {
        this.suggestionList.push({
          pes_nome: pValue.pes_nome,
        });

        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      } else if (hasPersonPatientData) {
        if (this.$refs.innerSuggest) {
          this.$refs.innerSuggest.itemSelected = true;
        }

        const withoutSuggestionList =
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0);

        if (withoutSuggestionList) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            patient: {
              pac_id: pValue.patient.pac_id,
            },
            ...pValue,
          });
        }
        if (this.$refs.innerSuggest?.$parent) {
          this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
        }
      }
    },

    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    reset() {
      this.inputValue = "";

      if (this.$refs.innerSuggest) {
        this.$refs.innerSuggest.resetSuggestionList();
        this.$refs.innerSuggest.$refs.input.focus();
      }
    },

    formatDate(pDate) {
      return this.$utils.date.BrazilianDateToDatabase(pDate);
    },

    clearMask() {
      this.mask = null;
    },

    validateField(pValue, pErrors) {
      if (!pValue) {
        return true;
      }

      const format = /[!@#$%^&*()_+\-={};':"\\|,.|0-9]+/;

      if (this.colFilter.col === "peopleName" && format.test(pValue)) {
        pErrors.push("Não pode conter números e nem caracteres especiais.");
        return false;
      }

      if (this.permitFreeText && pValue && pValue.length < 2) {
        pErrors.push("Mínimo de 2 caracteres.");
        return false;
      }

      if (
        this.colFilter.col === "cpf" &&
        !this.$utils.app.Document.verifyCpfNumber(pValue)
      ) {
        pErrors.push(" CPF Inválido.");
        return false;
      }

      if (
        this.colFilter.col === "cns" &&
        !this.$utils.app.Document.verifyCnsNumber(pValue)
      ) {
        pErrors.push(" CNS Inválido.");
        return false;
      }

      return true;
    },

    setMask() {
      switch (this.colFilter.col) {
        case "cpf":
          this.mask = "###########";
          this.captionRaw = "cpf.cpf_numero";
          this.minLength = "10";
          break;
        case "cns":
          this.mask = "###############";
          this.captionRaw = "cns.crs_numero";
          this.minLength = "14";
          break;
        case "birthDate":
          this.mask = "##/##/####";
          this.captionRaw = "pes_nascimento";
          this.minLength = "8";
          break;
        case "recordUnitNumber":
          this.mask = "################";
          this.captionRaw = "patient.record_numbers.rows.[0].ppr_numero";
          this.minLength = "0";
          break;
        case "recordNumber":
          this.mask = "################";
          this.captionRaw = "patient.pac_prontuario_unico";
          this.minLength = "0";
          break;
        case "bulletin":
          this.mask = "##########";
          this.captionRaw = "patient.patient_emergency.bulletin.bol_numero";
          this.minLength = "0";
          break;
        case "hospitalization":
          this.mask = "##########";
          this.captionRaw =
            "patient.patient_hospitalization.hospitalization.int_numero";
          this.minLength = "0";
          break;
        default:
          this.mask = null;
          this.captionRaw = "pes_nome";
          this.minLength = "3";
      }
    },
  },
};
</script>
