var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modulebox',{ref:"modulebox",staticClass:"pharmacy-type-presentation",attrs:{"title":_vm.isEdition
      ? 'Editar Apresentação de Produto'
      : 'Cadastrar Apresentação de Produto'}},[_c('FormBase',{staticClass:"register-form",attrs:{"title":_vm.isEdition
        ? 'Descrição da Apresentação'
        : 'Cadastro e Busca de Apresentação'}},[_c('div',{staticClass:"pharmacy-type-presentation-body"},[_c('RgValidatorForm',{ref:"validator",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.newTypePresentation.apply(null, arguments)}}},[_c('section',{staticClass:"pharmacy-type-presentation-form"},[_c('RgSuggestCity',{ref:"city",staticClass:"city",class:{ disable: true },attrs:{"rules":{ required: true },"disabled":"","label":"Município"},on:{"selected":_vm.selectingMunicipality},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('RgInput',{ref:"acronym",staticClass:"acronym",attrs:{"rules":{
              required: true,
              validatorIfExistNumber: true,
            },"disabled":_vm.disabledByModal,"label":"Sigla","placeholder":"SIGLA","maxlength":6},model:{value:(_vm.form.acronym),callback:function ($$v) {_vm.$set(_vm.form, "acronym", $$v)},expression:"form.acronym"}}),_c('RgInput',{ref:"name",staticClass:"name",attrs:{"rules":{
              required: true,
              fn: _vm.validateInputName,
            },"label":"Nome","placeholder":"Digite a apresentação do produto. Ex.: 'Caixa'","disabled":_vm.disabledByModal,"needRemoveSpecialStrings":false,"maxlength":45},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('div',{staticClass:"actions"},[(_vm.isEdition)?_c('RgCancelButton',{attrs:{"medium":"","disabled":_vm.disabledByModal},on:{"click":_vm.cancelPresentationTypeEditing}}):_c('RgCleanButton',{attrs:{"disabled":_vm.disabledByModal,"large":""},on:{"click":_vm.cleanInfoTypePresentation}}),(_vm.isEdition)?_c('RgSaveButton',{attrs:{"medium":"","disabled":_vm.disabledByModal},on:{"click":_vm.savePresentationTypeEditing}}):_c('RgAddButton',{attrs:{"large":"","disabled":_vm.disabledByModal,"permission":_vm.canRegister},on:{"click":_vm.newTypePresentation}})],1)],1)]),_c('hr',{staticClass:"hr"}),_c('section',{staticClass:"pharmacy-type-presentation-search"},[_c('div',{staticClass:"search"},[_c('RgInput',{class:{
              disable:
                _vm.isEdition || _vm.modalImportTypePresentation || _vm.disabledByModal,
            },attrs:{"label":"","disabled":_vm.isEdition || _vm.modalImportTypePresentation || _vm.disabledByModal,"placeholder":"Buscar por apresentação"},model:{value:(_vm.search.typePresentation),callback:function ($$v) {_vm.$set(_vm.search, "typePresentation", $$v)},expression:"search.typePresentation"}}),_c('RgSearchButton',{ref:"searchBtn",class:{
              disable:
                _vm.isEdition || _vm.modalImportTypePresentation || _vm.disabledByModal,
            },attrs:{"disabled":_vm.isEdition || _vm.modalImportTypePresentation || _vm.disabledByModal},on:{"submit":function($event){return _vm.searchTypePresentation(true)}}})],1),_c('div',{staticClass:"actions"},[_c('RgEditButton',{attrs:{"permission":_vm.canEdit,"disabled":_vm.disableActionsTable || _vm.disabledByModal,"title":"Editar Apresentação de Produto"},on:{"click":_vm.editTypePresentation}}),_c('RgLessButton',{attrs:{"permission":_vm.canRemove,"disabled":_vm.disableActionsTable || _vm.disabledByModal,"title":"Excluir Apresentação de Produto"},on:{"click":_vm.openModalConfirmDeletion}}),_c('RgImportButton',{attrs:{"disabled":_vm.isEdition || _vm.disabledByModal,"large":""},on:{"click":_vm.importTypePresentation}})],1)]),_c('section',{staticClass:"pharmacy-type-presentation-table",class:{ disable: _vm.isEdition || _vm.disabledByModal },attrs:{"disabled":_vm.isEdition || _vm.disabledByModal}},[_c('SmartTable',{ref:"smartTable",attrs:{"name":"PharmacyTypePresentation","columns":_vm.COLUMNS,"body":_vm.mutableTypePresentationList,"initial-columns":2,"total":_vm.total,"max-register":25,"disabled":_vm.isEdition || _vm.disabledByModal,"item-per-page":20,"dynamicHeight":_vm.getSpaceTable,"index-column":"mtu_id","toggleSelected":"","removeBtnColumns":""},on:{"getLine":_vm.selectLine,"pagination":_vm.setPagination}})],1)],1)]),_c('div',{staticClass:"pharmacy-type-presentation-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('MediumButton',{attrs:{"medium":"","label":"Voltar"},on:{"click":_vm.goBack}})],1),_c('ModalImportTypePresentation',{attrs:{"show":_vm.modalImportTypePresentation},on:{"close":_vm.closeModalImportTypePresentation,"reSearch":_vm.searchTypePresentation}}),_c('ModalConfirmDeletion',_vm._b({attrs:{"id":"modal-confirm-deletion"},on:{"close":_vm.closeModalConfirmDeletion}},'ModalConfirmDeletion',_vm.propsModalConfirmDeletion,false),[_c('div',{staticClass:"icon",attrs:{"slot":"icon"},slot:"icon"},[_c('IconDanger')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }