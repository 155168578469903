<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

/* A modificação da MAX_REGISTERS
pode provocar inconsistencias no banco, uma vez q as folhas são definidas por esse limite,
e uma vez q tais dados são salvos e n modificados no banco,
alterações podem provocar retrabalho no banco */

const MAX_REGISTERS = 99;

export default {
  name: "RgSelectSheet",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Folha",
    },
    register: {
      type: Boolean,
      default: false,
    },
    filterRegister: Object,
    callbackLastSheet: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      sheets: [],
    };
  },
  watch: {
    sheets: function (val) {
      this.updateOptions();
    },
    filterRegister: {
      handler(pValue, pPrev) {
        const duplicateRequest =
          JSON.stringify(pPrev) === JSON.stringify(pValue);

        if (
          pValue.cns &&
          pValue.cnes &&
          pValue.cbo &&
          pValue.competence &&
          !duplicateRequest
        ) {
          this.getSheets();
        }
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      return this.sheets;
    },
    async getSheets() {
      this.sheets = await this.searchSheets();
    },
    async searchSheets() {
      const listSheets = [];
      const result = this.filterArrayInvalid(
        await this.$store.dispatch("Billing/BPAI/SEARCH_SHEET_BPAI", {
          cnes: this.filterRegister.cnes,
          professionalCns: this.filterRegister.cns,
          cboCode: String(this.filterRegister.cbo),
          competence: this.filterRegister.competence,
        }),
      );
      if (result.length < 1) {
        listSheets.push({
          value: this.formatSheet(1),
          label: this.formatSheet(1),
          total: 0,
        });
      } else {
        let notComplete = false;
        for (const item of result) {
          listSheets.push({
            value: this.formatSheet(item.pdf_folha),
            label: this.formatSheet(item.pdf_folha),
            total: item.total,
          });
          if (item.total < MAX_REGISTERS) {
            notComplete = true;
          }
        }
        if (!notComplete && this.register) {
          listSheets.push({
            value: this.formatSheet(result.length + 1),
            label: this.formatSheet(result.length + 1),
            total: 0,
          });
        }
      }

      this.callbackLastSheet(listSheets[listSheets.length - 1]);
      return listSheets;
    },
    formatSheet(pValue) {
      return String((pValue / 100).toFixed(2)).replace(".", "");
    },
    filterArrayInvalid(array) {
      return array.filter((element) => {
        element.pdf_folha = Number(element.pdf_folha);
        if (element.pdf_folha !== 0) return element;
      });
    },
  },
};
</script>
