import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  DiferencaMaiorPermitidaException: `O envio para o PEP é realizado 1 dia antes do agendamento`,
};

export default async (context, conId) => {
  return AuthLegacyRequest.post(
    "/integracoes/controller-integracao-rg-pep/enviar-atendimento",
    {
      con_id: conId,
    },
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
