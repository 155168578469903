<template>
  <div class="exam-procedures-performed-schedule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-epps"
          v-model="form.uns_id"
          permission="exame2.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-epps"
          ref="sector"
          v-model="form.set_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unit-health="form.uns_id"
          :rules="{ required: true }"
          class="inputitem"
          default-text="Selecione"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgSelectProceduresFilter
          id="stp-id-eppr"
          ref="comboProcedures"
          v-model="form.stp_id"
          class="inputitem"
          label="Procedimento"
          :sector-id="Number(form.set_id)"
          default-text="Todos"
          @change="getProcedureValue"
        />
        <!-- :disabled="!form.set_id" -->
      </div>

      <div class="selectinput">
        <RgSuggestSubprocedures
          id="subprocedures-esppr"
          v-model="form.eas_nome_sub_procedimentos"
          class="inputitem"
          label="Subprocedimento"
          default-text="Todos"
        />
      </div>

      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-eppr"
          ref="professional"
          v-model="form.pes_nome"
          :maxlength="100"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :ocuppationId="form.ocp_id"
          label="Profissional"
          class="inputitem"
          @selected="selectingProfessional"
        />
      </div>
      <div class="filter-title">
        <span class="title">Período da Efetivação</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-eppr"
          ref="initialDate"
          v-model="form.initialDate"
          label="Data Inicial"
          class="inputitem"
          :rules="{
            required: true,
            fn: validateInitialDate,
          }"
          popUp="top"
        />

        <RgInputDate
          id="final-date-eppr"
          ref="finalDate"
          v-model="form.finalDate"
          label="Data Final"
          class="inputitem mg"
          :disabled="!form.initialDate"
          :class="{ disable: !form.initialDate }"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateInitial"
          popUp="top"
        />
      </div>
      <div class="selectinput">
        <Tooltip class="exam-procedures-performed-schedule-tooltip" startOpen>
          <IconInfoHelper slot="icon" />
          <div slot="content" class="content">
            <span> Só é possível selecionar datas dentro do mesmo mês. </span>
          </div>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgInputDate,
  RgSelectSector,
  RgSuggestEmployee,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";
import moment from "moment";

import RgSelectProceduresFilter from "$exam/submodules/schedule/component/select/rg-select-procedures-filter/RgSelectProceduresFilter";
import RgSuggestSubprocedures from "$exam/common/component/rg-suggest-subprocedures/RgSuggestSubProcedures";

const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: null,
  initialDate: moment().format("01/MM/YYYY"),
  finalDate: moment().endOf("month").format("DD/MM/YYYY"),
  month: null,
  stp_id: null,
  stp_novo_nome_procedimento: null,
  pes_id: null,
  pes_nome: null,
  monthName: null,
  eas_nome_sub_procedimentos: null,
};

export default {
  name: "ExamProceduresPerformedReport",
  components: {
    RgSelectUnithealth,
    RgInputDate,
    RgSelectSector,
    RgSuggestEmployee,
    RgSelectProceduresFilter,
    RgSuggestSubprocedures,
    Tooltip,
    IconInfoHelper,
  },

  data() {
    return {
      resultTitle: "Procedimentos e Subprocedimentos Efetivados",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      professionalBond: null,
      columns: [],
      legend: "",
      subprocedures: null,
    };
  },
  computed: {
    getNameSmartTable() {
      return "ExamProceduresPerformedReport";
    },

    printReportCss() {
      return (
        "@page {size: landscape; }" +
        "table { table-layout: auto !important; }" +
        ".report-column-1 { width: 200px; text-align: left; }" +
        ".report-column-1-print { width: 200px; text-align: left;}" +
        "th:last-child { width: 60px; }"
      );
    },

    columnTable() {
      return this.columns;
    },

    initialColumns() {
      return 36;
    },

    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },

    getLegend() {
      return this.legend;
    },

    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY").endOf("month").format(),
          )
        : null;
    },
  },
  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
      }
    },

    "form.initialDate"(pValue, pPrev) {
      if (pValue) {
        if (this.$refs.initialDate) {
          this.$refs.initialDate.cleanValidate();
        }
      }
      if (pValue !== pPrev) {
        this.form.finalDate = moment(this.form.initialDate, "DD/MM/YYYY")
          .endOf("month")
          .format("DD/MM/YYYY");
      }
    },
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.$store.commit("User/RESET_SMART_TABLE_COLUMNS");
  },

  created() {
    this.MAX_DAYS_DATE_INTERVAL = 31;
  },

  methods: {
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },
    buildFilter() {
      this.mountLegend();
      return {
        ...this.form,
      };
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
    },

    getProcedureValue(pValue) {
      if (pValue) {
        this.form.stp_novo_nome_procedimento = pValue.label;
      } else {
        this.form.stp_novo_nome_procedimento = null;
      }
    },

    mountLegend() {
      if (this.form.initialDate) {
        const numberInitialOfDayPerMonth = this.form.initialDate.slice(0, 2);
        const numberOfDayPerMonth = this.form.finalDate.slice(0, 2);
        this.form.monthName = moment(this.form.initialDate, "DD/MM/YYYY")
          .format("MMMM")
          .toUpperCase();

        this.legend = `${this.form.monthName} - ${numberInitialOfDayPerMonth} À ${numberOfDayPerMonth} DIAS `;
      } else {
        this.legend = "";
      }
    },

    mountColumns() {
      this.columns = [];
      this.$store.commit("User/RESET_SMART_TABLE_COLUMNS");

      const numberOfDayPerMonth = Number(this.form.finalDate.slice(0, 2)) || 0;
      const numberOfDayInitial = Number(this.form.initialDate.slice(0, 2)) || 0;

      this.addColumnsTable("Procedimento", "PROCEDIMENTO");
      this.addColumnsTable("Subprocedimento", "SUBPROCEDIMENTO");

      for (let item = numberOfDayInitial; item <= numberOfDayPerMonth; item++) {
        const newKey = `DIA_${item}`;
        this.addColumnsTable(`${item}`, newKey, "right");
      }
      this.addColumnsTable("Total", "TOTAL", "right");
      this.addColumnsTable("Valor Unitário", "VALOR_UNITARIO", "right");
      this.addColumnsTable("Valor Total", "VALOR_TOTAL", "right");
    },

    addColumnsTable(name, key, align = "left") {
      this.columns.push({ name, key, align, active: true });
    },

    async doSearch(pData) {
      this.mountColumns();
      try {
        return this.$store.dispatch(
          "Exam/Report/GET_EXAM_PROCEDURES_PERFORMED_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    selectingProfessional(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.pes_id = pValue.source.pes_id;
        this.form.pes_nome = pValue.source.pes_nome;
      } else {
        this.form.pes_id = null;
        this.form.pes_nome = null;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.cleanFieldsValidation();
    },

    cleanFieldsValidation() {
      const fieldsRefs = ["sector", "initialDate", "finalDate"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
