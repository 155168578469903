<template>
  <Modulebox
    ref="modulebox"
    :granted="hasPermission"
    :title="title"
    class="service-scale"
  >
    <section class="service-scale-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Profissional">
          <section class="professional-grid">
            <RgSelectUnithealthLegacy
              id="unit-health"
              ref="unitHealth"
              v-model="form.unity"
              :disabled="isEdition || lockAfterSaving || disabledByModal"
              :class="{ disable: isEdition || lockAfterSaving }"
              :rules="{ required: true }"
              :subModuleId="subModuleId"
            />

            <RgSelectSectorBondAndPermission
              id="sector"
              v-model="form.sector"
              :unit-health-id="form.unity"
              :submodule-id="subModuleId"
              :disabled="
                !hasUnithealth ||
                isEdition ||
                lockAfterSaving ||
                disabledByModal
              "
              :class="{
                disable: !hasUnithealth || isEdition || lockAfterSaving,
              }"
              :rules="{ required: true }"
              default-text="Selecione"
            />

            <RgSuggestCboSector
              id="occupation"
              ref="occupation"
              v-model="form.occupation"
              :rules="{ required: true, forceSelection: true }"
              active-professional
              :disabled="!hasSector || isEdition || disabledByModal"
              :class="{ disable: !hasSector || isEdition }"
              :sector-id="Number(form.sector)"
              @selected="selectingCbo"
            />

            <RgSelectEmployeeBySectorOccupationLegacy
              id="professional"
              ref="professional"
              v-model="form.professional"
              :sector-id="Number(form.sector)"
              :occupation-id="Number(occupationId)"
              :disabled="!hasOccupation || isEdition || disabledByModal"
              :class="{ disable: !hasOccupation || isEdition }"
              label="Profissional"
              placeholder="Digite o nome do profissional"
              :rules="{ required: true }"
              @change="selectingEmployee"
            />
          </section>
        </FormBase>

        <FormBase title="Escala">
          <section class="form-grid">
            <div class="item-radio">
              <input
                id="scale"
                v-model="form.period"
                type="radio"
                name="r-scale"
                class="radio-scale"
                :value="1"
                :disabled="isEdition || disabledByModal"
                :class="{ disable: isEdition }"
              />
              <label for="scale">Por escala</label>
              <input
                id="period"
                v-model="form.period"
                type="radio"
                name="r-scale"
                class="radio-scale"
                :disabled="isEdition || disabledByModal"
                :class="{ disable: isEdition }"
                :value="2"
              />
              <label for="period">Por intervalo</label>
            </div>

            <div class="second-row">
              <div class="item">
                <RgComboboxWeekDays
                  id="week-days"
                  ref="weekDays"
                  v-model="form.weekDays"
                  :rules="{ required: true }"
                  :disabled="isEdition || disabledByModal"
                  :class="{ disable: isEdition }"
                />
              </div>
              <div class="item">
                <RgInputHour
                  id="hour-start"
                  ref="hourStart"
                  v-model="form.hourStart"
                  label="Horário de Início"
                  :disabled="disabledByModal"
                  :rules="{ required: true }"
                  @blur="calculateTimes"
                />
              </div>
              <div v-if="!isByScale" class="item">
                <RgInput
                  id="interval"
                  ref="interval"
                  v-model="form.interval"
                  :maxlength="2"
                  placeholder="Digite em minutos"
                  label="Intervalo"
                  :disabled="disabledByModal"
                  :rules="{ required: true, fn: validateInterval }"
                  @blur="calculateTimes"
                />
              </div>
              <div class="item">
                <RgInputMasked
                  id="vacancies"
                  ref="vacancies"
                  v-model="form.vacancies"
                  type="number"
                  mask="###"
                  :rules="{ required: true, fn: verifyMinNumber }"
                  :disabled="disabledByModal"
                  placeholder="Quantidade de vagas"
                  label="Quantidade de Vagas"
                  @blur="calculateTimes"
                />
              </div>
              <div class="item">
                <RgInputHour
                  id="hour-end"
                  ref="hourEnd"
                  v-model="form.hourEnd"
                  :rules="{ required: true, fn: verifyMaxHour }"
                  :disabled="!isByScale || disabledByModal"
                  :class="{ disable: !isByScale }"
                  label="Horário de Término"
                />
              </div>
            </div>

            <div class="third-row">
              <div v-if="!isByScale" class="item">
                <RgInputHour
                  id="last-hour"
                  ref="lastHour"
                  v-model="form.lastHour"
                  :rules="{ required: true, fn: verifyMaxHour }"
                  :disabled="true"
                  :class="{ disable: true }"
                  label="Último Horário Disponível"
                />
              </div>
              <div class="item">
                <RgInputDate
                  id="validate-date-start"
                  ref="validateDateStart"
                  v-model="form.validateDateStart"
                  :disabled="disabledByModal"
                  :rules="{ required: true }"
                  label="Início da Validade"
                />
              </div>
              <div class="item">
                <RgInputDate
                  id="validate-date-end"
                  ref="validateDateEnd"
                  v-model="form.validateDateEnd"
                  :disabled="disabledByModal"
                  :rules="{ fn: validateDate }"
                  label="Término da Validade"
                />
              </div>
              <div class="item">
                <RgComboTypeScale
                  id="type-scale"
                  ref="typeScale"
                  v-model="form.typeScale"
                  :rules="{ required: true }"
                  :disabled="disabledByModal"
                  :uns_id="Number(form.unity)"
                  :usu_id="Number(userId)"
                  label="Tipo de Escala"
                />
              </div>
              <div class="buttons">
                <RgCleanButton
                  v-if="!isEdition"
                  id="clean-scale"
                  :disabled="disabledByModal"
                  @click="cleanForm"
                />

                <RgCancelButton
                  v-if="isEdition"
                  id="cancel-edit-scale"
                  medium
                  :disabled="disabledByModal"
                  @click="cancelEditScale"
                />

                <RgSaveButton
                  v-if="isEdition"
                  id="save-edit-scale"
                  ref="save"
                  large
                  :permission="hasPermissionIncludes || hasPermissionEdit"
                  :disable="!hasCompleted"
                  @click="saveScale"
                />

                <RgAddButton
                  v-else
                  id="new-scale"
                  large
                  :permission="hasPermissionIncludes || hasPermissionEdit"
                  :disable="!hasCompleted"
                  @click="saveScale"
                />
              </div>
            </div>
          </section>
        </FormBase>

        <FormBase title="Escalas Cadastradas">
          <section class="form-grid --grid">
            <div
              class="table"
              :disabled="isEdition || disabledByModal"
              :class="{ disable: isEdition }"
            >
              <GridServiceScale
                ref="gridServiceScale"
                has-max-register
                :initial-columns="8"
                :show-new-button="false"
                :body="listGridServiceScale"
                :total="Number(total)"
                :show-pagination="true"
                :bond="bond"
                :isEdition="isEdition"
                :registerScale="listGridServiceScale[0]"
                :selectedProfessional="selectedProfessional"
                :disabled="disabledByModal"
                listingRegistry
                @openModalDeleteScale="openModalDeleteScale"
                @openModalHistoryScales="openModalHistoryScales"
                @selectedRow="setSelectedRow"
                @edit="editScale"
                @pagination="setPagination"
              />
            </div>
          </section>
        </FormBase>
      </RgValidatorForm>
    </section>

    <section slot="footer" class="footer">
      <RgBackButton
        id="cancel-btn"
        medium
        title="Voltar"
        :disabled="disabledByModal"
        @click="goBack"
      />
    </section>

    <ModalScaleConflict
      id="modal-scale-conflict"
      ref="modalConflictScale"
      :show="showModalConflictScale"
      :conflicts="conflictListScale"
      :filter="filterModalScaleConflict"
      @close="closeModalConflictScale"
      @confirm="closeModalConflictScale"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDelete"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalHistoryProfessionalScales
      id="modal-history-scales"
      :show="showModalHistoryScales"
      :sector-link-id="sectorLinkId"
      :professional-info="professionalInfo"
      @close="closeModalHistoryScales"
    />
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgSelectUnithealthLegacy,
  RgSelectSectorBondAndPermission,
  RgComboboxWeekDays,
  RgInput,
  RgInputHour,
  RgInputDate,
  RgInputMasked,
  RgSuggestCboSector,
  RgSelectEmployeeBySectorOccupationLegacy,
  RgCleanButton,
  RgSaveButton,
  RgAddButton,
  RgBackButton,
  RgCancelButton,
  ModalConfirmDeletion,
  IconDanger,
} from "~tokio/primitive";

import { mapGetters } from "vuex";
import moment from "moment";

import FormBase from "~tokio/foundation/form-base/FormBase";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";

import GridServiceScale from "../../common/grid/service-scale/GridServiceScale";

import RgComboTypeScale from "../../common/combobox/rg-combobox-type-scale/RgComboboxTypeScale";

import ModalHistoryProfessionalScales from "$exam/submodules/register/common/modal/modal-history-professional-scales/ModalHistoryProfessionalScales";
import ModalScaleConflict from "../../common/modal/scale-conflict/ScaleConflict";

const FORM = {
  unity: null,
  sector: null,
  occupation: null,
  professional: null,
  period: 1,
  weekDays: null,
  hourStart: null,
  interval: null,
  vacancies: null,
  hourEnd: null,
  lastHour: null,
  validateDateStart: null,
  validateDateEnd: null,
  typeScale: null,
};

export default {
  name: "ServiceScale",
  components: {
    Modulebox,
    FormBase,
    GridServiceScale,
    IconDanger,
    ModalConfirmDeletion,
    ModalHistoryProfessionalScales,
    ModalScaleConflict,
    RgComboTypeScale,
    RgInputMasked,
    RgSuggestCboSector,
    RgSelectEmployeeBySectorOccupationLegacy,
    RgValidatorForm,
    RgSelectUnithealthLegacy,
    RgSelectSectorBondAndPermission,
    RgComboboxWeekDays,
    RgInput,
    RgInputHour,
    RgInputDate,
    RgAddButton,
    RgSaveButton,
    RgCleanButton,
    RgCancelButton,
    RgBackButton,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      listGridServiceScale: [],
      total: 0,
      selectedRow: null,
      isEdition: null,
      userId: null,
      occupationId: null,
      professionalId: null,
      bond: null,
      sectorLinkId: 0,
      professionalInfo: {},
      title: "Cadastro de Escala",
      lockAfterSaving: false,
      showModalDeleteShow: false,
      showModalHistoryScales: false,
      showModalConflictScale: false,
      selectedProfessional: false,
      scaleEndSpentDay: false,
      hasCompleted: true,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
      conflictListScale: [],
      scaleHourEnd: null,
      intervalHourEnd: null,
    };
  },

  computed: {
    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),
    disabledByModal() {
      return (
        this.showModalDeleteShow ||
        this.showModalHistoryScales ||
        this.showModalConflictScale
      );
    },

    subModuleId() {
      const SUB_MODULE_EXAM_SCALE_ID = 116;
      return SUB_MODULE_EXAM_SCALE_ID;
    },

    propsModalConfirmDeletion() {
      const show = this.showModalDeleteShow;
      const noReason = true;
      const title = "Excluir a escala selecionada";
      const message = "A operação não poderá ser desfeita";
      const msgSuccess = "Escala excluída com sucesso!";
      const btnRemoveTitle = "Excluir Escala";
      const confirm = () => this.removeScale();

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },

    hasPermission() {
      return this.$Permissions.global.has(
        "exame2.examesEscala.exibir",
        this.unitHealthId,
      );
    },

    hasPermissionIncludes() {
      return !!this.$Permissions.global.has(
        "exame2.examesEscala.incluir",
        this.unitHealthId,
      );
    },

    hasPermissionEdit() {
      return !!this.$Permissions.global.has(
        "exame2.examesEscala.alterar",
        this.unitHealthId,
      );
    },

    hasUnithealth() {
      return this.form.unity > 0;
    },

    hasSector() {
      return this.form.sector > 0;
    },

    hasOccupation() {
      return this.occupationId > 0;
    },

    hasEmployee() {
      return this.professionalId > 0;
    },

    canCalculteTime() {
      return (
        !!this.form.hourStart && !!this.form.interval && !!this.form.vacancies
      );
    },

    isByScale() {
      return this.form.period === 1;
    },

    filterModalScaleConflict() {
      const parse = this.parseDataToSearchList();
      return parse;
    },

    areAllProfessionalInfoFilled() {
      const areAllFilled =
        this.hasUnithealth &&
        this.hasSector &&
        this.hasOccupation &&
        this.hasEmployee;

      return areAllFilled;
    },
  },

  watch: {
    "form.unity"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.sector = null;
      }
    },
    "form.sector"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.occupation = null;
      }
    },
    "form.occupation"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.professional = null;
      }

      if (!pValue) {
        this.occupationId = null;
        this.listGridServiceScale = [];
        this.total = 0;
      }
    },
    "form.professional"(pValue) {
      if (!pValue) {
        this.professionalId = null;
        this.listGridServiceScale = [];
        this.total = 0;
      }
    },
    "form.period"(pValue) {
      this.isByScale
        ? (this.form.hourEnd = this.scaleHourEnd)
        : (this.form.hourEnd = this.intervalHourEnd);

      this.cleanFieldsValidation();
    },
    async areAllProfessionalInfoFilled(pValue) {
      if (pValue && pValue === true) {
        await this.getListScales();
      } else {
        this.listGridServiceScale = [];
        this.total = 0;
      }
    },
  },

  async mounted() {
    this.userId = this.userLogin.user.usu_id;

    this.isEdition = this.$route?.name === "exam.register.service-scale.edit";

    if (this.isEdition) {
      this.fillFormForEditing();
      this.title = "Editar Escala";
    } else {
      this.fillForm();
    }
  },

  methods: {
    verifyMinNumber(pValue, pErrors) {
      if (pValue && pValue <= 0) {
        pErrors.push("O valor deve ser maior que zero");
        return false;
      }
      return true;
    },

    verifyMaxHour(pValue, pErrors) {
      this.scaleHourEnd = pValue || null;

      if (!pValue) {
        return false;
      }

      if (this.isByScale && pValue < this.form.hourStart) {
        pErrors.push("Horário final não pode ser menor que horário inicial");
        return false;
      }

      return true;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    parseDataToSave() {
      const variables = {
        arrFormData: {
          eea_id_exames_tipo_escalas: this.form.period,
          eea_id_dias_semana: this.form.weekDays,
          eea_inicio_atendimentos: this.form.hourStart,
          eea_intervalo_exames: this.form.interval || 0,
          eea_quantidade_exames: this.form.vacancies,
          eea_final_atendimentos: this.form.hourEnd,
          eea_data_inicio_escala: this.form.validateDateStart,
          eea_data_fim_escala: this.form.validateDateEnd || null,
          eea_id_exames_escala: Number(this.form.typeScale),
          eea_id_vinculos_setores: this.bond,
          eea_quantidade_peso: 0,
          eea_id: this.isEdition ? Number(this.selectedRow.eea_id) : null,
          ees_id: 0,
        },
      };

      return variables;
    },

    async saveScale() {
      const validateForm = !(await this.isFormValid());
      if (validateForm) {
        this.$toaster.warning("Verifique os campos");
        if (this.$refs.save) {
          this.$refs.save.actionDone();
        }
        return false;
      }

      if (this.scaleEndSpentDay) {
        this.$toaster.warning(
          "A escala ultrapassou 23:59. Caso seja necessário o plantão duplo, faça duas escalas.",
        );
        this.$refs.save.actionDone();
        return false;
      }

      this.registerOrEditScale();
    },

    async registerOrEditScale() {
      try {
        this.$loader.start();
        this.hasCompleted = false;
        const path = this.isEdition
          ? "Exam/Register/EDIT_SERVICE_SCALE"
          : "Exam/Register/REGISTER_SERVICE_SCALE";

        const variable = this.parseDataToSave();

        if (!variable) {
          return;
        }

        await this.$store.dispatch(path, variable);

        const toaster = this.isEdition
          ? "Escala alterada com sucesso!"
          : "Escala cadastrada com sucesso!";

        this.$toaster.success(toaster);

        if (this.$refs.save) {
          this.$refs.save.actionDone();
        }

        if (!this.isEdition) {
          this.lockAfterSaving = true;
        }

        this.cleanForm();
        this.getListScales();
      } catch (err) {
        const { esus_response } = err;
        if (esus_response?.hasError) {
          this.$toaster.warning(esus_response.message);
          this.handleScaleErros(esus_response);
        } else {
          this.$toaster.warning(this.$utils.sanitize.formatError(err));
        }
      } finally {
        if (this.$refs.save) {
          this.$refs.save.actionDone();
        }

        if (this.isEdition) {
          this.isEdition = false;
          this.cleanForm();
        }

        this.$loader.finish();
        this.hasCompleted = true;
      }
    },

    handleScaleErros(pData) {
      const isConflictScaleError =
        pData.exception === "ConflitoEscalaDePeriodoHorarioException" ||
        pData.exception === "ExameConflitoEscalaDePeriodoHorarioException";

      const exceptionDetails = Object.assign({}, pData.detalhes_exececao);

      if (isConflictScaleError) {
        this.showModalConflictScale = true;

        const scaleList = Array.isArray(exceptionDetails?.escalas)
          ? exceptionDetails?.escalas
          : [];

        const historyList = Array.isArray(exceptionDetails?.historicos)
          ? exceptionDetails?.historicos
          : [];

        const hasConflictsData = scaleList.length > 0 || historyList.length > 0;

        const list = scaleList.concat(historyList);

        this.conflictListScale = hasConflictsData ? list : [];
      } else {
        const errorMessage = pData.mensagem || "Problemas ao salvar escala";
        this.$toaster.error(errorMessage);
      }
    },

    editScale() {
      this.fillScaleForEditing();
      this.isEdition = true;
      this.title = "Editar Escala";
      this.$refs.modulebox.setScrollTop();
    },

    async removeScale() {
      try {
        this.$loader.start("Excluindo a escala selecionada...");
        const obj = {
          intIdExameEscalaAgendamento: this.selectedRow.eea_id,
        };
        await this.$store.dispatch("Exam/Register/REMOVE_TYPE_SCALE", obj);
        this.$toaster.success("Exclusão realizada com sucesso");

        this.getListScales();
        this.$refs.gridServiceScale.cleanSelectRow();
      } catch (err) {
        this.$toaster.error(
          "Existe movimentação na escala do profissional",
          err.message,
        );
      } finally {
        this.$loader.finish();
      }
    },

    cancelEditScale() {
      this.isEdition = false;
      this.title = "Cadastro de Escala";
      this.cleanForm();
    },

    parseDataToSearchList() {
      const variables = {
        arrFormData: {
          uns_id: this.form.unity,
          set_id: this.form.sector,
          ocp_id: this.occupationId,
          pes_id: this.professionalId,
          smd_id: this.subModuleId,
          limiteInicio: this.pagination.offset,
          limiteFim: this.pagination.limit,
        },
      };

      return variables;
    },

    async fillForm() {
      const data = this.$store.getters["Exam/Register/GET_FILTER_TYPE_SCALE"];

      if (data) {
        this.form = this.$utils.obj.DeepCopy(FORM);
        this.form.unity = data.selectedUnity;
        this.form.sector = data.selectedSector;

        if (this.$refs.occupation) {
          await this.$refs.occupation.forceSelection({
            ocp_id: Number(data.selectedCboId),
            ocp_nome: data.selectedCboName,
            ocp_codigo: data.selectedCboCode,
          });
        }

        if (this.$refs.professional) {
          this.form.professional = data.selectedEmployee;
        }
      } else {
        this.form.unity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      }
    },

    async fillFormForEditing() {
      this.cleanFieldsValidation();

      this.selectedRow = this.$store.getters[
        "Exam/Register/GET_SELECT_ROW_LIST_SERVICE_SCALE"
      ];

      const dateEnd =
        this.selectedRow.eea_data_fim_escala === "N/A"
          ? null
          : this.selectedRow.eea_data_fim_escala;

      this.form.unity = this.selectedRow.uns_id;
      this.form.sector = this.selectedRow.set_id;

      if (this.$refs.occupation) {
        await this.$refs.occupation.forceSelection({
          ocp_id: Number(this.selectedRow.ocp_id),
          ocp_nome: this.selectedRow.ocp_nome,
          ocp_codigo: this.selectedRow.ocp_codigo,
        });
      }

      if (this.$refs.professional) {
        this.form.professional = this.selectedRow.pes_id;
      }

      this.form.period = this.selectedRow.eti_nome === "POR ESCALA" ? 1 : 2;
      this.form.weekDays = this.selectedRow.dsm_id;
      this.form.hourStart = this.selectedRow.eea_inicio_atendimentos;
      this.form.interval = this.selectedRow.eea_intervalo_exames;
      this.form.vacancies = this.selectedRow.eea_quantidade_exames;
      this.intervalHourEnd = this.isByScale
        ? null
        : this.selectedRow.eea_final_atendimentos;
      this.scaleHourEnd = this.isByScale
        ? this.selectedRow.eea_final_atendimentos
        : null;
      this.form.hourEnd = this.selectedRow.eea_final_atendimentos;
      this.calculateLastHour();
      this.form.validateDateStart = this.selectedRow.eea_data_inicio_escala;
      this.form.validateDateEnd = dateEnd;
      this.form.typeScale = this.selectedRow.ees_id;
    },

    async fillScaleForEditing() {
      this.cleanFieldsValidation();

      this.selectedRow = this.$store.getters[
        "Exam/Register/GET_SELECT_ROW_LIST_SERVICE_SCALE"
      ];

      const dateEnd =
        this.selectedRow.eea_data_fim_escala === "N/A"
          ? null
          : this.selectedRow.eea_data_fim_escala;

      this.form.period = this.selectedRow.eti_nome === "POR ESCALA" ? 1 : 2;
      this.form.weekDays = this.selectedRow.dsm_id;
      this.form.hourStart = this.selectedRow.eea_inicio_atendimentos;
      this.form.interval = this.selectedRow.eea_intervalo_exames;
      this.form.vacancies = this.selectedRow.eea_quantidade_exames;
      this.intervalHourEnd = this.isByScale
        ? null
        : this.selectedRow.eea_final_atendimentos;
      this.scaleHourEnd = this.isByScale
        ? this.selectedRow.eea_final_atendimentos
        : null;
      this.form.hourEnd = this.selectedRow.eea_final_atendimentos;
      this.calculateLastHour();
      this.form.validateDateStart = this.selectedRow.eea_data_inicio_escala;
      this.form.validateDateEnd = dateEnd;
      this.form.typeScale = this.selectedRow.ees_id;
    },

    async getListScales() {
      this.listGridServiceScale = [];
      this.total = 0;

      try {
        this.$loader.start("Buscando escalas cadastradas...");
        const path = "Exam/Register/LIST_SERVICE_SCALE_SEARCH";

        const variables = this.parseDataToSearchList();
        const data = await this.$store.dispatch(path, variables);

        if (data?.recordSet) {
          this.listGridServiceScale = data.recordSet;
          this.total = Number(data.total);
        }
      } catch (pErr) {
        this.$toaster.warning(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async setPagination(pPagination) {
      if (pPagination && this.areAllProfessionalInfoFilled) {
        this.pagination = pPagination;
        await this.getListScales();
      }
    },

    setSelectedRow(row) {
      this.selectedRow = row;
      this.$store.commit(
        "Exam/Register/SET_SELECT_ROW_LIST_SERVICE_SCALE",
        row,
      );
    },

    selectingCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;

      if (hasSuggestion) {
        this.$loader.start();
        this.occupationId = pValue.source.ocp_id;
        this.$loader.finish();
      } else {
        this.occupationId = 0;
        this.occupation = null;
      }
    },

    async selectingEmployee(pValue) {
      if (pValue) {
        const { value, vus_id } = pValue;

        this.professionalId = value;
        this.bond = Number(vus_id);
        this.selectedProfessional = true;
        await this.getListScales();
      } else {
        this.professionalId = 0;
        this.bond = 0;
        this.listGridServiceScale = [];
      }
    },

    calculateTimes() {
      if (this.canCalculteTime && !this.isByScale) {
        this.calculateHourEnd();
        this.calculateLastHour();
        this.calculateFinalTime();
      }
    },

    calculateLastHour() {
      const intervalInMinutes = Number(this.form.interval);

      const lastAvailableTime = moment(this.form.hourEnd, "HH:mm")
        .subtract(intervalInMinutes, "m")
        .format("HH:mm");

      this.form.lastHour = lastAvailableTime;
    },

    calculateHourEnd() {
      const sumOfScale = this.form.vacancies * this.form.interval;

      const finalTime = moment(this.form.hourStart, "HH:mm")
        .add(sumOfScale, "m")
        .format("HH:mm");

      this.form.hourEnd = finalTime;
      this.intervalHourEnd = finalTime;
    },

    calculateFinalTime() {
      const sumMinOfScale = this.form.vacancies * this.form.interval;

      const finalTime = moment(this.form.hourStart, "HH:mm").add(
        sumMinOfScale,
        "m",
      );

      this.scaleEndSpentDay = false;
      if (sumMinOfScale > 0) {
        const minutesTo24Hour = moment("23:59:59", "HH:mm").diff(
          finalTime,
          "minutes",
        );
        if (minutesTo24Hour < 0) {
          this.scaleEndSpentDay = true;
        }
      }

      this.form.hourEnd = finalTime.format("HH:mm");
    },

    validateInterval(pValue, pErrors) {
      const invalidQuantity = pValue && (pValue === "0" || pValue === "00");
      const invalidInterval = parseInt(pValue) < 1 || parseInt(pValue) > 60;

      if (invalidQuantity) {
        pErrors.push("O valor deve ser maior que zero");
        return false;
      }

      if (invalidInterval) {
        pErrors.push(`Intervalo deve ser entre 1 e 60 minutos`);
        return false;
      }
      return true;
    },

    validateDate(val, error) {
      if (!val) {
        return true;
      }

      const final = moment(val, "DD/MM/YYYY");
      const start = moment(this.form.validateDateStart, "DD/MM/YYYY");
      if (final.isBefore(start)) {
        error.push(
          `Término da Validade não pode ser menor que Início da Validade`,
        );
        return false;
      }
    },

    openModalDeleteScale() {
      this.showModalDeleteShow = true;
    },

    openModalHistoryScales() {
      this.sectorLinkId = Number(this.listGridServiceScale[0]?.vus_id);

      this.professionalInfo = {
        pes_nome: this.listGridServiceScale[0]?.pes_nome,
        uns_nome: this.listGridServiceScale[0]?.uns_nome,
        set_nome: this.listGridServiceScale[0]?.set_nome,
        ocp_nome: this.listGridServiceScale[0]?.ocp_nome,
      };

      this.showModalHistoryScales = true;
    },

    closeModalDelete() {
      this.showModalDeleteShow = false;
    },

    closeModalHistoryScales() {
      this.showModalHistoryScales = false;
    },

    closeModalConflictScale() {
      this.showModalConflictScale = false;
    },

    cleanForm() {
      this.form.weekDays = 0;
      this.form.hourStart = null;
      this.form.interval = null;
      this.form.vacancies = null;
      this.form.hourEnd = null;
      this.form.lastHour = null;
      this.form.validateDateStart = null;
      this.form.validateDateEnd = null;
      this.form.typeScale = null;
      this.scaleHourEnd = null;
      this.intervalHourEnd = null;
      this.cleanFieldsValidation();
      this.$refs.gridServiceScale.cleanSelectRow();
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "weekDays",
        "hourStart",
        "interval",
        "vacancies",
        "hourEnd",
        "lastHour",
        "validateDateStart",
        "validateDateEnd",
        "typeScale",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
