import mutations from "./mutations";

import Bulletin from "$billing/bulletin/store";
import BDSia from "$billing/bdsia/store";
import Billing from "$billing/billing/store";
import HospitalizationBilling from "$billing/submodules/aih/hospitalization-billing/store";
import PeriodHospitalizationBilling from "$billing/submodules/aih/define-hospitalization-period/store";

import BPAI from "$billing/submodules/bpa/bpa-i/store";
import Attendance from "$billing/submodules/bpa/attendances/store";
import PEPPendingProcedures from "$billing/submodules/bpa/pep-pending-procedures/store";
import Review from "$billing/submodules/bpa/automated-shipping-production/store";

import BpacBilling from "$billing/submodules/bpa/bpa-c/store";
import Procedure from "$billing/submodules/bpa/procedures/store";

import ExportBilling from "$billing/submodules/bpa/export-billing/store";
import ServicesRatings from "$billing/submodules/bpa/services-ratings/store";

import ImportBdsiaSigtap from "$billing/submodules/import-competence/store";

import Complexity from "$billing/submodules/complexity/store";
export default {
  namespaced: true,
  mutations,
  modules: {
    Bulletin,
    BDSia,
    Billing,
    HospitalizationBilling,
    PeriodHospitalizationBilling,
    BPAI,
    BpacBilling,
    Attendance,
    ExportBilling,
    Procedure,
    ServicesRatings,
    PEPPendingProcedures,
    Review,
    ImportBdsiaSigtap,
    Complexity,
  },
};
