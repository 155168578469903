<template>
  <div class="hospitalization-schedule-queue">
    <RgSearch
      ref="rgsearch"
      v-model="mutableHospitalizationQueue"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="40.6"
      :max-register="30"
      :show-title="true"
      :show-empty-message="true"
      :showFooter="hasHospitalizationQueue"
      :result-title="'Fila de Regulação'"
      :disabled="disabledByModal"
      @afterPerformSearch="afterSearchFilter"
      @viewClickPagination="viewClickPagination"
      @submitFromButton="actionCleanSelection"
    >
      <div slot="filters" class="hospitalization-schedule-queue-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSuggestTypeBed
              id="bed-type"
              ref="bedType"
              v-model="form.bedType"
              :rules="{ forceSelection: true }"
              class="inputitem"
              placeholder="Todos"
              @selected="selectingBedType"
            />
          </div>

          <div class="selectinput">
            <RgSelectUnithealthQueue
              id="unit"
              v-model="form.unit"
              :unit-health-id="getUnitHealthId"
              :module-id="moduleId"
              :hasRegularPermission="hasRegularPermission"
              :hasViewRequestUnitHealthPermission="
                hasViewRequestUnitHealthPermission
              "
              label="Unidade de Saúde Solicitante"
              class="inputitem"
              :default-text="'Todas'"
              :disabled="disabledByModal"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              id="patient"
              ref="patient"
              v-model="form.patientName"
              :disabled="disableNameInput"
              :class="{ disable: disableNameInput }"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              id="document"
              ref="patient"
              v-model="form.document"
              :disabled="disableDocumentInput"
              :class="{ disable: disableDocumentInput }"
              :with-patient-module="['hospitalization']"
              filterOnlyDocument
              @selected="selectingPatient"
            />
          </div>

          <div class="selectinput">
            <RgSelectPriority
              id="priority"
              v-model="form.priority"
              :module-id="hospitalizationModuleId"
              label="Prioridade"
              default-text="Todas"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSelectSituation
              id="situation"
              v-model="form.situation"
              default-text="Todas"
              label="Situação"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSuggestState
              id="state"
              ref="state"
              v-model="form.est_sigla"
              label="Estado"
              class="inputitem state"
              placeholder="Digite o estado"
              @selected="selectingState"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCity
              id="city"
              ref="city"
              v-model="form.mun_nome"
              :class="{ disable: disableCounty }"
              :disabled="disableCounty"
              :state="form.est_id"
              label="Município"
              placeholder="Digite o município"
              class="inputitem city"
              @selected="selectingMunicipality"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período do Pedido</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-order-date"
                v-model="form.initialOrderPeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                id="final-order-date"
                v-model="form.finalOrderPeriod"
                label="Data Final"
                class="date"
                :disabled="disabledByModal"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Período de Inclusão na Fila</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-inclusion-date"
                v-model="form.initialInclusionDate"
                :rules="{ fn: verifyInclusionInitialDate }"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                id="final-inclusion-date"
                v-model="form.finalInclusionDate"
                label="Data Final"
                class="date"
                :disable="disabledByModal"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Procedimentos e CIDs</span>
          </div>

          <div class="selectinput">
            <RgSuggestProcedures
              id="procedures"
              ref="mainProcedure"
              v-model="form.procedureOne"
              data-id="procedimento"
              data-item="procedimento-item"
              placeholder="Digite o cód. ou nome do procedimento"
              label="Procedimentos"
              @selected="selectingProcedureOne"
            />
          </div>

          <div class="selectinput">
            <RgSuggestProcedures
              id="procedures"
              ref="secondProcedure"
              v-model="form.procedureTwo"
              data-id="procedimento"
              data-item="procedimento-item"
              placeholder="Digite o cód. ou nome do procedimento"
              label=""
              @selected="selectingProcedureTwo"
            />
          </div>

          <div class="selectinput">
            <RgSuggestProcedures
              id="procedures"
              ref="thirdProcedure"
              v-model="form.procedureThree"
              data-id="procedimento"
              data-item="procedimento-item"
              placeholder="Digite o cód. ou nome do procedimento"
              label=""
              @selected="selectingProcedureThree"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCid
              id="main-cid"
              ref="mainCid"
              v-model="form.cidOne"
              data-id="CID"
              label="CID dos procedimentos"
              class="cid"
              placeholder="Digite o cód. ou nome do CID"
              @selected="selectingCidOne"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCid
              id="main-cid"
              ref="secondCid"
              v-model="form.cidTwo"
              data-id="CID"
              label=""
              class="cid"
              placeholder="Digite o cód. ou nome do CID"
              @selected="selectingCidTwo"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCid
              id="main-cid"
              ref="thirdCid"
              v-model="form.cidThree"
              data-id="CID"
              label=""
              class="cid"
              placeholder="Digite o cód. ou nome do CID"
              @selected="selectingCidThree"
            />
          </div>
        </div>
      </div>

      <div
        v-if="mutableHospitalizationQueue.length <= 0"
        slot="menu-top"
        class="top-button"
      >
        <RgAddButton
          id="registration-queue"
          large
          label="Novo"
          title="Inserir na fila"
          class="item"
          :disabled="disabledByModal"
          :permission="hasInsertPermission"
          @click="registrationQueue"
        />
      </div>

      <div class="menu-top">
        <div v-show="hasHospitalizationQueue" class="top-btn">
          <RgButton
            v-if="!equalListSize"
            class="select-btn"
            @click="selectAllList"
          >
            <IconSquare />
          </RgButton>

          <RgButton
            v-if="equalListSize"
            class="select-btn"
            @click="unselectAllList"
          >
            <IconSquareChecked />
          </RgButton>

          <RgButton
            class="select-btn reverse-select"
            title="Inverter seleção"
            @click="reverseListSelection"
          >
            <IconArrowSquare />
          </RgButton>

          <p class="text">
            Linhas Selecionadas:
            {{ selectedRows.length }}
          </p>

          <div class="position-btn">
            <input
              id="queue-advance"
              v-model="queueAmountAdvance"
              class="field"
              maxlength="5"
              :disabled="
                !hasOnlyOneLineSelected || disabledByModal || hasPatientInactive
              "
              :class="{
                disable:
                  !hasOnlyOneLineSelected ||
                  disabledByModal ||
                  hasPatientInactive,
              }"
              title="Informe o tamanho de intervalo para o passo de subida na fila"
            />
            <SmallButton
              id="queue-advance-btn"
              :disabled="
                !hasOnlyOneLineSelected || disabledByModal || hasPatientInactive
              "
              :backgroundColor="'#1E88A9'"
              :permission="hasRegularPermission"
              @click="advancePatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg" />
            </SmallButton>

            <input
              id="queue-back"
              v-model="queueAmountBack"
              class="field"
              maxlength="5"
              :disabled="
                !hasOnlyOneLineSelected || disabledByModal || hasPatientInactive
              "
              :class="{
                disable:
                  !hasOnlyOneLineSelected ||
                  disabledByModal ||
                  hasPatientInactive,
              }"
              title="Informe o tamanho de intervalo para o passo de descida na fila"
            />
            <SmallButton
              id="queue-btn"
              :disabled="
                !hasOnlyOneLineSelected || disabledByModal || hasPatientInactive
              "
              :backgroundColor="'#1E88A9'"
              :permission="hasRegularPermission"
              @click="backPatientInQueue"
            >
              <IconArrowSmall slot="icon" class="svg arrow-invert" />
            </SmallButton>

            <input
              id="queue-position"
              v-model="queuePosition"
              class="field large"
              maxlength="5"
              :disabled="
                !hasOnlyOneLineSelected || disabledByModal || hasPatientInactive
              "
              :class="{
                disable:
                  !hasOnlyOneLineSelected ||
                  disabledByModal ||
                  hasPatientInactive,
              }"
              title="Informe o posição deseja para mover o registro na fila"
            />
            <SmallButton
              id="queue-btn"
              :disabled="
                !hasOnlyOneLineSelected || disabledByModal || hasPatientInactive
              "
              :backgroundColor="'#1E88A9'"
              :permission="hasRegularPermission"
              @click="movePatientInQueue"
            >
              <IconArrowDouble slot="icon" class="svg" />
            </SmallButton>
          </div>
        </div>

        <div class="more-buttons">
          <RgAddButton
            id="new-registration-queue"
            small
            title="Inserir na fila"
            class="insert-button"
            :disabled="disabledByModal"
            :permission="hasInsertPermission"
            @click="registrationQueue"
          />

          <RgEditButton
            id="edit-registration"
            :disabled="
              !hasOnlyOneLineSelected ||
              disabledByModal ||
              disabledRegulateButton ||
              hasPatientInactive
            "
            :permission="hasEditPermission"
            title="Editar Fila"
            @click="alterQueue"
          />

          <RgLessButton
            id="delete-registration"
            :disabled="!enableButtonForSelectedLines || disabledByModal"
            :permission="hasDeletePermission"
            title="Excluir Paciente da Fila"
            @click="openModalConfirmQueueDeletion"
          />

          <RgDropdown
            v-show="hasHospitalizationQueue"
            id="options-history"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            :item="listSelected[0] || {}"
            :action-options="optionsDropdownHistory(listSelected)"
            small
          >
            <IconHistory slot="icon" />
          </RgDropdown>

          <RgShowButton
            v-show="hasHospitalizationQueue"
            id="view-patient"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            title="Visualizar paciente na fila"
            @click="openModalPatientDataRegulationQueue()"
          />

          <RgPrinterButton
            id="printer-registration"
            :disabled="!hasOnlyOneLineSelected"
            title="Imprimir o comprovante de paciente na fila"
            @click="printProofInsertionQueue"
          />

          <RgDropdown
            v-show="hasHospitalizationQueue"
            id="options-person"
            :disabled="!hasOnlyOneLineSelected || disabledByModal"
            :item="listSelected[0] || {}"
            :action-options="optionsDropdownPerson(listSelected)"
            small
          >
            <IconCard slot="icon" />
          </RgDropdown>

          <SmallButton
            v-show="hasHospitalizationQueue"
            id="update-patient-ab-esus"
            title="Atualizar dados AB"
            :disabled="
              !hasOnlyOneLineSelected ||
              disabledByModal ||
              patientHasNoActiveBond ||
              hasPatientInactive
            "
            @click="updatePatientAbEsus"
          >
            <IconBasicAttention
              slot="icon"
              class="svg"
              :disabled="
                !hasOnlyOneLineSelected ||
                disabledByModal ||
                patientHasNoActiveBond ||
                hasPatientInactive
              "
            />
          </SmallButton>

          <div
            v-if="
              mutableHospitalizationQueue &&
              mutableHospitalizationQueue.length > 0
            "
            class="dropdown-button-columns box-scroll"
          >
            <div class="button">
              <span class="text unselect">Colunas</span>
              <IconArrow class="svg" />
            </div>

            <div
              class="dropdown dropdown-absolute"
              @mouseleave="openDropdownColumns"
            >
              <span class="label unselect">Colunas Exibidas</span>

              <label
                v-for="(col, index) in activeHeader"
                :key="col.key"
                :class="{ 'disable unselect': hasOnlyOneActiveHeader }"
                :disabled="hasOnlyOneActiveHeader"
                class="item actives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>

              <span class="label unselect">Colunas Não Exibidas</span>

              <label
                v-for="(col, index) in inactiveHeader"
                :key="col.key"
                class="item inactives unselect"
              >
                {{ col.name }}
                <input
                  :id="index"
                  :checked="col.active"
                  class="checkbox"
                  type="checkbox"
                  @change="toogleStatus(col, col.key)"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div ref="table" class="hospitalization-schedule-queue-result">
        <div class="content-table">
          <table class="table">
            <tr>
              <th class="title-table" />
              <th class="title-table" />
              <th
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                :style="{ 'text-align': col.align }"
                class="title-table"
              >
                {{ col.name }}
              </th>
            </tr>

            <tr
              v-for="(item, index) in mutableHospitalizationQueue"
              :key="index"
              :class="{ selected: selectedRows.includes(item.fil_id) }"
              class="tr row-content"
              @click="getValueRow(item, index)"
            >
              <td class="patient-disabled">
                <div
                  :class="{ 'icon-disable': item.pac_bloqueado === '1' }"
                  :title="
                    item.pac_bloqueado === '1'
                      ? `INATIVO - ${item.mip_nome}`
                      : ''
                  "
                ></div>
              </td>
              <td class="icon-check">
                <div v-if="selectedRows.includes(item.fil_id)" class="check">
                  <IconCheck />
                </div>
              </td>
              <td
                v-for="(col, index) in header"
                v-show="col.active"
                :key="index"
                :class="{ priority: item[col.key] === item.pfi_nome }"
                :style="{ textAlign: col.align }"
                class="result"
              >
                <span
                  v-if="item[col.key] !== item.icon_id"
                  :class="{
                    breath: item[col.key] === item.pfi_nome,
                  }"
                >
                  {{ item[col.key] || "-" }}
                </span>
                <div
                  v-if="item[col.key] === item.pfi_nome"
                  class="circle"
                  :title="item.pfi_nome"
                  :style="{
                    background: priorityColor(item),
                  }"
                ></div>
                <div
                  v-if="item[col.key] === item.icon_id"
                  class="icon-deficient"
                >
                  <div v-for="(icon, id) in item.icon_id" :key="id">
                    <CentralizerIcons
                      :iconId="icon"
                      margin="0"
                      size="20px"
                      :stroke="selectedRows.includes(item.fil_id) ? '#fff' : ''"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        v-show="hasHospitalizationQueue"
        slot="menu-bottom"
        class="painel-button"
      >
        <div class="others-btn">
          <RgHourglassButton
            id="pendencies"
            :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
            small
            title="Pendência"
            class="bottom"
            @click="pendenciesQueue"
          />

          <RgRegulateButton
            id="regulate"
            small
            title="Regular"
            class="bottom regulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledRegulateButton ||
              disabledByModal ||
              hasPatientInactive
            "
            :permission="hasRegularPermission"
            @click="regulate"
          />

          <RgUnregulateButton
            id="unregulate"
            small
            title="Desregular"
            class="bottom unregulate"
            :disabled="
              !enableButtonForSelectedLines ||
              disabledUnregulateButton ||
              disabledByModal ||
              hasPatientInactive
            "
            :permission="hasRegularPermission"
            @click="unRegulate"
          />

          <RgNewScheduleButton
            id="schedule-regulation"
            :disabled="!hasOnlyOneLineSelected || hasPatientInactive"
            :permission="hasSchedulePermission"
            small
            class="bottom"
            @click="scheduleRegulationQueue"
          />

          <RgExternScheduleButton
            id="schedule-regulation-extern"
            :disabled="!enableButtonForSelectedLines || hasPatientInactive"
            :permission="hasExternalSchedulePermission"
            small
            text="Agendar externamente"
            title="Agendar externamente"
            class="bottom"
            @click="externalSchedule"
          />
        </div>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmQueueDeletion"
      @reSearch="fillFilter"
    />

    <ModalPatientDataRegulationQueue
      id="modal-patient-data-regulation-queue"
      :show="modalPatientDataRegulationQueue"
      :patientInfo="listSelected[0] || {}"
      @close="closeModalPatientDataRegulationQueue"
    />

    <ModalPatientImageInRow
      id="modal-patient-image-row"
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="Number(selectedRows[0])"
      :modId="hospitalizationModuleId"
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      id="modal-types-print"
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :hospitalization-id="hospitalizationIdToPrint"
      @close="closeModalTypesPrint"
    />

    <ModalTypePrintInsertQueue
      id="modal-print-insert-queue"
      :show="modalPrint"
      :filId="Number(filId) || 0"
      @close="closeModalPrint"
    />

    <ModalSimpleHistory
      id="modal-simple-history"
      :show="showModalSimpleHistory"
      :filter="filterHistoryQueue"
      @close="showModalSimpleHistory = false"
    />

    <ModalObservationQueue
      id="modal-observation-queue"
      :show="modalObservationQueue"
      :patientData="listSelected[0]"
      :modId="hospitalizationModuleId"
      @close="closeModalObservationQueue"
    />

    <ModalPatientRecordNotLinkedBasicAttention
      :show="modalPatientRecordNotLinkedBasicAttention"
      @close="closeModalPatientRecordNotLinkedBasicAttention"
    />

    <ModalConfirmAnticipateScheduling
      id="modal-confirm-antecipate-scheduling"
      v-bind="propsModalConfirmAnticipateScheduling"
      @close="closeModalConfirmAnticipateScheduling"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  RgInputDate,
  RgNewScheduleButton,
  RgDropdown,
  RgHourglassButton,
  RgRegulateButton,
  RgUnregulateButton,
  RgExternScheduleButton,
  IconCheck,
  RgAddButton,
  RgEditButton,
  RgPrinterButton,
  RgInput,
  IconArrow,
  IconSquareChecked,
  IconArrowSmall,
  IconArrowDouble,
  IconSquare,
  IconArrowSquare,
  RgLessButton,
  RgSelectSituation,
  RgSelectPriority,
  RgSuggestCid,
  SmallButton,
  RgButton,
  IconHistory,
  RgShowButton,
  IconBasicAttention,
  IconCard,
  RgSuggestSmartPerson,
  RgSelectUnithealthQueue,
} from "~tokio/primitive";

import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgSuggestProcedures from "$hospitalization/hospitalizations/queue/component/suggest/procedures/RgSuggestProcedures";
import ModalTypePrintInsertQueue from "$hospitalization/hospitalizations/queue/component/modal/modal-type-print-insert-queue/ModalTypePrintInsertQueue";

import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
  ModalObservationQueue,
  ModalConfirmAnticipateScheduling,
} from "~tokio/primitive/modal";

import { RgSuggestCity } from "$patient/common/components";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import { AlertError } from "~tokio/primitive/notification";
import ModalTypesPrint from "$hospitalization/hospitalizations/queue/component/modal/modal-types-print/ModalTypesPrint";
import ModalSimpleHistory from "$queue/common/components/modal/modal-simple-history/ModalSimpleHistory";
import ModalPatientDataRegulationQueue from "$hospitalization/hospitalizations/queue/component/modal/modal-patient-data-regulation-queue/ModalPatientDataRegulationQueue";
import { ConvertIndividualRegistrationToPatientAbEsus } from "$person/helper/ConvertIndividualRegistrationToPatientAbEsus";
import ModalPatientRecordNotLinkedBasicAttention from "$person/patient/component/modal/modal-patient-record-not-linked-basic-attention/ModalPatientRecordNotLinkedBasicAttention";

import {
  printThermalOneWay,
  printThermalTwoWay,
  printScheduleVoucher,
} from "$appointment/common/utils/printsScheduleVoucherAppointment";

import moment from "moment";
import CentralizerIcons from "~tokio/primitive/icon/font-awesome/icons/CentralizerIcons";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import { DateNow } from "~utils/date";

export default {
  name: "HospitalizationQueue",
  components: {
    RgSearch,
    RgInputDate,
    RgSuggestState,
    RgSuggestCity,
    RgSelectPriority,
    RgSelectSituation,
    RgNewScheduleButton,
    RgHourglassButton,
    RgRegulateButton,
    RgUnregulateButton,
    RgExternScheduleButton,
    IconCheck,
    IconArrow,
    IconArrowSmall,
    IconSquare,
    IconArrowDouble,
    IconArrowSquare,
    IconSquareChecked,
    IconCard,
    RgAddButton,
    RgEditButton,
    RgInput,
    RgPrinterButton,
    RgLessButton,
    ModalPatientImageInRow,
    ModalConfirmDeletion,
    RgDropdown,
    ModalTypesPrint,
    ModalTypePrintInsertQueue,
    RgSuggestTypeBed,
    RgSuggestProcedures,
    RgSuggestCid,
    ModalSimpleHistory,
    SmallButton,
    ModalObservationQueue,
    ModalPatientDataRegulationQueue,
    ModalConfirmAnticipateScheduling,
    RgButton,
    CentralizerIcons,
    IconHistory,
    RgShowButton,
    IconBasicAttention,
    RgSuggestSmartPerson,
    RgSelectUnithealthQueue,
    ModalPatientRecordNotLinkedBasicAttention,
  },

  data() {
    return {
      selectedRows: [],
      listSelected: [],
      mutableHospitalizationQueue: [],
      queueData: {},
      header: this.COLLUMNS,
      form: {
        document: null,
        bedTypeId: null,
        bedType: null,
        unit: null,
        pes_id: null,
        patientName: null,
        priority: null,
        situation: null,
        est_id: null,
        est_sigla: null,
        mun_id: null,
        mun_nome: null,
        initialOrderPeriod: moment().add(-1, "years").format("DD/MM/YYYY"),
        finalOrderPeriod: moment().format("DD/MM/YYYY"),
        initialInclusionDate: moment().add(-1, "years").format("DD/MM/YYYY"),
        finalInclusionDate: moment().format("DD/MM/YYYY"),
        ocp_codigo: null,
        soc_nome: null,
        suggestPatient: null,
        cidIdOne: null,
        cidCodeOne: null,
        cidIdTwo: null,
        cidCodeTwo: null,
        cidIdThree: null,
        cidCodeThree: null,
        procedureIdOne: null,
        procedureCodeOne: null,
        procedureIdTwo: null,
        procedureCodeTwo: null,
        procedureIdThree: null,
        procedureCodeThree: null,
      },
      disableDocumentInput: false,
      modalPatientRecordNotLinkedBasicAttention: false,

      filId: null,
      modalPatientDataRegulationQueue: false,
      modalConfirmQueueDeletion: false,
      modalPatientImageInRow: false,
      modalConfirmAnticipateScheduling: false,
      disabledRegulateButton: false,
      disabledUnregulateButton: false,
      modalObservationQueue: false,
      modalPrint: false,
      modalTypesPrint: false,
      disableNameInput: false,
      hospitalizationIdToPrint: null,
      queueAmountAdvance: 1,
      queueAmountBack: 1,
      queuePosition: 1,
      showModalSimpleHistory: false,
      isExternaSchedule: false,
      selectedQueueOrder: [],
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      getColumns: "User/GET_SMART_TABLE_COLUMNS",
    }),

    getUnitHealthId() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },

    propsModalConfirmDeletion() {
      const maxlength = 250;
      const title =
        this.listSelected.length > 1
          ? "Excluir Pacientes da Fila"
          : "Excluir Paciente da Fila";
      const subtitle =
        'Ao remover o paciente da "Regulação", o registro vai para a lista "Excluídos pela Fila"';
      const show = this.modalConfirmQueueDeletion;
      const bodyText =
        this.listSelected.length > 1 ? "" : this.listSelected[0]?.pes_nome;
      const confirm = (reason) => this.deleteQueue(reason);
      return { show, confirm, maxlength, title, subtitle, bodyText };
    },

    propsModalConfirmAnticipateScheduling() {
      const show = this.modalConfirmAnticipateScheduling;
      const confirm = (reason) => this.confirmAnticipateSchedule(reason);
      const subtitle =
        "Ao agendar o paciente fora do topo da fila, é necessário registrar um motivo.";
      const msgSuccess = "Agendamento antecipado com sucesso!";
      const bodyText =
        this.listSelected.length === 1
          ? this.listSelected[0]?.pes_nome
          : this.listSelected.length > 1
          ? this.listSelected.length + " pacientes selecionados"
          : "";
      const btnRemoveTitle = "Agendar";

      return {
        show,
        confirm,
        subtitle,
        msgSuccess,
        bodyText,
        btnRemoveTitle,
      };
    },

    hasSchedulePermissionOnePatientNotFirst() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.agendarPacienteForaTopoFila",
      );
    },

    preferences() {
      const preferences = {
        allowedEditRegulatedPatient: !!Number(
          this.$store.getters["Login/GET_PREFERENCES"][
            "tViewBuscaFilaInternacao.rbt_alterar_paciente_regulado"
          ],
        ),
        printOptionsPreference: this.$store.getters["Login/GET_PREFERENCES"][
          "tViewBuscaFilaInternacao.opcoesImpressao"
        ],
        requireReasonScheduleOffTop: !!Number(
          this.$store.getters["Login/GET_PREFERENCES"][
            "tViewInternacao.flt_motivo_agendar_fora_topo"
          ],
        ),
      };
      return preferences;
    },

    disabledByModal() {
      return (
        this.modalConfirmQueueDeletion ||
        this.modalPatientImageInRow ||
        this.modalObservationQueue ||
        this.modalPrint ||
        this.modalTypesPrint ||
        this.showModalSimpleHistory ||
        this.modalConfirmAnticipateScheduling
      );
    },

    hospitalizationModuleId() {
      return this.$store.state.Login.route_module_map.hospitalization;
    },

    activeHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => item.active);
      }
      return false;
    },

    inactiveHeader() {
      const isHeaderFilled = this.header && this.header.length > 0;
      if (isHeaderFilled) {
        return this.header.filter((item) => !item.active);
      }
      return false;
    },

    hasOnlyOneActiveHeader() {
      return this.activeHeader.length === 1;
    },

    patientHasNoActiveBond() {
      return this.listSelected?.length > 0
        ? !Number(this.listSelected[0].pae_ativo)
        : true;
    },

    enableButtonForSelectedLines() {
      return this.selectedRows.length > 0;
    },

    hasOnlyOneLineSelected() {
      return this.selectedRows.length === 1;
    },

    hasHospitalizationQueue() {
      return this.mutableHospitalizationQueue.length > 0;
    },

    disableCounty() {
      return !this.form.est_id;
    },

    equalListSize() {
      return (
        this.listSelected.length === this.mutableHospitalizationQueue.length
      );
    },

    patientIsRegulate() {
      return this.listSelected.every((item) => {
        return item.fnp_ordem_regulada !== "0";
      });
    },

    hasInsertPermission() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.incluir",
      );
    },

    hasEditPermission() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.alterar",
      );
    },

    hasDeletePermission() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.excluir",
      );
    },

    hasSchedulePermission() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.agendar",
      );
    },

    hasExternalSchedulePermission() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.agendarExternamente",
      );
    },

    hasRegularPermission() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.regular",
      );
    },

    hasViewRequestUnitHealthPermission() {
      return !!this.$Permissions.global.has(
        "internacao.filaInternacao.visualizarUnidadeSolicitante",
      );
    },

    filterHistoryQueue() {
      if (this.listSelected[0] === undefined) {
        return {};
      }
      const { fil_id, pes_id, pes_nome } = this.listSelected[0];
      return { fil_id, pes_id, pes_nome };
    },

    moduleId() {
      return 14;
    },
    hasPatientInactive() {
      return this.listSelected.some((item) => item.pac_bloqueado === "1");
    },
  },

  watch: {
    "form.est_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.mun_id = null;
        this.form.mun_nome = "";
      }
    },

    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.document = null;
        this.form.pes_id = null;
        this.disableDocumentInput = true;
      } else {
        this.disableDocumentInput = false;
      }
    },

    "form.document"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disableNameInput = true;
        this.form.patientName = null;
      } else {
        this.disableNameInput = false;
      }
    },

    activeHeader(pValue, pPrev) {
      if (pPrev && pValue[0]?.key !== "without_result") {
        const link = this.$route.path;

        this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
          link,
          usu_id: this.userLoginId,
          uns_id: this.unitHealthId,
          columns: pValue,
          name: this.name || "HospitalizationQueue",
        });
      }
    },

    listSelected(pValue) {
      const isRegulate = pValue.every((item) => {
        return item.fnp_ordem_regulada !== "0";
      });

      const isUnregulate = pValue.every((item) => {
        return item.fnp_ordem_regulada === "0";
      });

      if (isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = false;
      }

      if (!isRegulate && isUnregulate) {
        this.disabledRegulateButton = false;
        this.disabledUnregulateButton = true;
      }

      if (!isRegulate && !isUnregulate) {
        this.disabledRegulateButton = true;
        this.disabledUnregulateButton = true;
      }
    },
  },

  created() {
    this.COLLUMNS = [
      { name: "Ordem", key: "ordem_regulada" },
      { name: "Pendência", key: "pendencia" },
      { name: "Prioridade", key: "pfi_nome" },
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "PCD", key: "icon_id", align: "center" },
      { name: "Telefone", key: "telefone", align: "left" },
      { name: "Tipo de Leito", key: "nome", align: "left" },
      { name: "Idade", key: "idade" },
      { name: "Município", key: "mun_nome", align: "left" },
      { name: "Anexo", key: "anexo" },
      { name: "Data na Fila", key: "fil_data" },
      { name: "Data do Pedido", key: "fil_data_pedido" },
      { name: "Unidade de Saúde Inserção", key: "unidade_fila", align: "left" },
      {
        name: "Unidade de Saúde Solicitante",
        key: "uns_solicitante",
        align: "left",
      },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
  },

  mounted() {
    this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
    this.$store.commit(
      "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
    );

    const isHospitalizationSchedule = this.$route.params
      .isHospitalizationSchedule;
    if (isHospitalizationSchedule) {
      this.viewHospitalizationScheduleVoucher(
        this.$route.params.hospitalizationId,
      );
    }

    this.mountTableHeader(this.COLLUMNS, 6);
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Hospitalization/Queue/RESET_FILTER_HOSPITALIZATION_QUEUE",
      );
    }
  },

  methods: {
    afterSearchFilter() {
      if (this.$refs.table) {
        const elementContainer = this.$refs.table;
        elementContainer.scrollTop = 0;
      }

      this.selectedRows = [];
      this.listSelected = [];
    },
    async selectingPatient(pPatient) {
      const source = pPatient.source ? pPatient.source : null;
      const hasPerson = source && source.pes_id > 0;

      if (hasPerson) {
        this.form.pes_id = source.pes_id;
      } else {
        this.form.pes_id = null;
      }
    },
    mountTableHeader(ArrColumns, initialColumns = 1) {
      let header;
      const link = this.$route.path;
      const savedHeader = this.getColumns(
        link,
        this.userLoginId,
        this.unitHealthId,
        this.name || "HospitalizationQueue",
      );

      if (savedHeader) {
        const activeKey = [];
        savedHeader.columns.forEach((item) => {
          activeKey.push(item.key);
        });

        header = ArrColumns.map((item) => {
          if (activeKey.includes(item.key)) {
            return {
              ...item,
              name: item.name,
              key: item.key,
              align: item.align,
              active: true,
            };
          } else {
            return {
              ...item,
              name: item.name,
              key: item.key,
              align: item.align,
              active: false,
            };
          }
        });
      } else {
        header = ArrColumns.map((item, idx) => {
          const active = idx < initialColumns;
          return {
            ...item,
            name: item.name,
            key: item.key,
            active,
          };
        });
      }

      this.header = header;
      return header;
    },

    async searchFilter(pData) {
      try {
        this.$loader.start();

        if (pData.arrFormData.limiteFim === 0)
          pData.arrFormData.limiteFim = null;

        const data = await this.$store.dispatch(
          "Hospitalization/Queue/SEARCH_HOSPITALIZATION_QUEUE",
          pData,
        );

        for (const item of data.data) {
          if (item.pep_deficiencias && item.pep_deficiencias !== "") {
            item.icon_id = item.pep_deficiencias.split(",");
          }
        }
        return data;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.loadPreferences();
        this.$loader.finish();
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          itl_id_internacoes_tipos_leito: this.form.bedTypeId,
          uns_id: this.form.unit,
          pes_nome: this.form.patientName,
          pes_id: this.form.pes_id,
          pfi_id: this.form.priority,
          situacao: this.form.situation,
          est_id: this.form.est_id,
          mun_id: this.form.mun_id,
          flt_id_internacoes_procedimentos_1: this.form.procedureIdOne,
          flt_id_internacoes_procedimentos_2: this.form.procedureIdTwo,
          flt_id_internacoes_procedimentos_3: this.form.procedureIdThree,
          flt_id_cid10_1: this.form.cidIdOne,
          flt_id_cid10_2: this.form.cidIdTwo,
          flt_id_cid10_3: this.form.cidIdThree,
          periodoInicial: this.form.initialOrderPeriod,
          periodoInicialFila: this.form.initialInclusionDate,
          periodoFinal: this.form.finalOrderPeriod,
          periodoFinalFila: this.form.finalInclusionDate,
          permissaoRegular: this.hasRegularPermission ? 1 : 0,
          permissaoUnidadeSolicitante: this.hasViewRequestUnitHealthPermission
            ? 1
            : 0,
          somenteRegulados: false,
          uns_atual: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        },
      };

      this.$store.commit(
        "Hospitalization/Queue/SET_FILTER_HOSPITALIZATION_QUEUE",
        this.form,
      );

      return variables;
    },

    async getValueRow(pItem) {
      const queueId = pItem.fil_id;
      const hasQueueId = queueId && Number(queueId) > 0;
      if (hasQueueId) {
        const alreadySelected = this.selectedRows.includes(queueId);

        if (alreadySelected) {
          this.selectedRows.splice(this.selectedRows.indexOf(queueId), 1);

          this.listSelected = this.listSelected.filter(function (el) {
            return el.fil_id !== queueId;
          });
          this.filId = Number(queueId);

          if (this.selectedQueueOrder)
            this.selectedQueueOrder.splice(
              this.selectedQueueOrder.indexOf(pItem.ordem_regulada),
              1,
            );
        } else {
          this.selectedRows.push(queueId);
          this.listSelected.push(pItem);
          this.filId = Number(queueId);
          if (this.selectedQueueOrder)
            this.selectedQueueOrder.push(pItem.ordem_regulada);
        }
      }
    },

    validateSchedule() {
      const isRegulate = this.listSelected.every((item) => {
        return item.fnp_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return isRegulate && hasSchedulePermission;
    },

    validateSchedulePermission() {
      const minValueOrder = 1;
      const selectedQueueOrderSort = this.selectedQueueOrder.sort();
      const firstOrderSelected =
        Number(selectedQueueOrderSort[0]) === minValueOrder;

      const hasOnlyOnePatientSelected = this.selectedRows.length === 1;

      if (hasOnlyOnePatientSelected) {
        // fora da ordem selecionado e sem permissão para agendar fora do topo da fila
        if (
          !firstOrderSelected &&
          !this.hasSchedulePermissionOnePatientNotFirst
        ) {
          throw new Error("Sem permissão para agendar fora do topo da fila");
        }
      }

      // fora do topo ou pacientes em sequência
      const OnePatientOutOfTop =
        hasOnlyOnePatientSelected && !firstOrderSelected;
      const patientsOutOfOrder =
        (firstOrderSelected && !hasOnlyOnePatientSelected) ||
        (!hasOnlyOnePatientSelected && !firstOrderSelected);

      if (
        this.preferences.requireReasonScheduleOffTop &&
        (OnePatientOutOfTop || patientsOutOfOrder)
      ) {
        this.modalConfirmAnticipateScheduling = true;
        return false;
      }
      return true;
    },

    selectAllList() {
      this.mutableHospitalizationQueue.forEach((item) => {
        if (!this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    unselectAllList() {
      this.mutableHospitalizationQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        }
      });
    },

    reverseListSelection() {
      this.mutableHospitalizationQueue.forEach((item) => {
        if (this.selectedRows.includes(item.fil_id)) {
          this.selectedRows.splice(this.selectedRows.indexOf(item.fil_id), 1);
          this.listSelected.splice(this.listSelected.indexOf(item), 1);
        } else {
          this.selectedRows.push(item.fil_id);
          this.listSelected.push(item);
        }
      });
    },

    viewClickPagination(pValue) {
      if (pValue) {
        this.unselectAllList();
      }
    },

    toogleStatus(pColumn, pKey) {
      pColumn.active = !pColumn.active;

      const index = this.getHeaderIndex(pKey);
      const activesLength = this.getActiveColumnsLength();
      const maxReached = activesLength > this.MAX_COLUMNS;
      const middle = activesLength / 2;
      const currentIndex = index + 1;

      if (maxReached) {
        const idx =
          currentIndex > middle
            ? this.getFirsActiveColumnIndex()
            : this.getLastActiveColumnIndex();

        this.header[idx].active = false;
      }
    },

    getHeaderIndex(pKey) {
      return this.header.findIndex((item) => item.key === pKey);
    },

    getActiveColumnsLength() {
      return this.getActiveColumnsIndex().length;
    },

    getActiveColumnsIndex() {
      const actives = [];
      this.header.forEach((item, index) => {
        if (item.active) actives.push(index);
      });
      return actives;
    },

    getLastActiveColumnIndex() {
      const activeColumns = this.getActiveColumnsIndex();
      return activeColumns[activeColumns.length - 1];
    },

    getFirsActiveColumnIndex() {
      return this.getActiveColumnsIndex()[0];
    },

    pendenciesQueue() {
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "hospitalization.queue.pendencies" });
    },

    registrationQueue() {
      this.$router.push({
        name: "hospitalization.queue.insert",
        params: { fromRegulation: true },
      });
    },

    alterQueue() {
      if (
        this.patientIsRegulate &&
        !this.preferences.allowedEditRegulatedPatient
      ) {
        this.$toaster.warning("Não é possível alterar paciente regulado");
        return;
      }
      if (this.listSelected && this.listSelected.length > 1) {
        this.$toaster.warning(
          "Para essa operação só é permitida a seleção de um único paciente",
        );
        return;
      }
      this.$store.commit(
        "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        Object.values(this.listSelected),
      );
      this.$router.push({ name: "hospitalization.queue.edit-insert" });
    },

    async deleteQueue(reason) {
      try {
        const arrQueue = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        const variables = {
          arrQueue: arrQueue,
          reason: reason,
        };

        await this.$store.dispatch(
          "Hospitalization/Queue/REMOVE_HOSPITALIZATION_QUEUE",
          variables,
        );

        this.$toaster.success("Paciente(s) removido(s) da fila com sucesso");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o paciente da fila");
      } finally {
        this.$loader.finish();
      }
    },

    async scheduleRegulationQueue() {
      try {
        this.isExternaSchedule = false;
        if (this.validateSchedule()) {
          this.$store.commit(
            "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
            this.listSelected[0],
          );
          this.$router.push({
            name: "hospitalization.queue.schedule",
          });
          return;
        }
        return;
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    validateExternalSchedule() {
      const isRegulate = this.listSelected.every((item) => {
        return item.fnp_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        throw new Error("Só é possível agendar para pacientes regulados");
      }

      const hasSchedulePermission = this.validateSchedulePermission();

      return isRegulate && hasSchedulePermission;
    },

    externalSchedule() {
      const isRegulate = this.listSelected.every((item) => {
        return item.fnp_ordem_regulada !== "0";
      });

      if (!isRegulate) {
        this.$toaster.warning("Só é possível agendar para pacientes regulados");
        return;
      }

      this.isExternaSchedule = true;

      this.$store.commit(
        "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        Object.values(this.listSelected),
      );

      this.$router.push({
        name: "hospitalization.queue.external-schedule-form",
      });
    },

    confirmAnticipateSchedule(reason) {
      try {
        this.listSelected.forEach(
          (element) => (element.fil_motivo_agendamento_fora_topo = reason),
        );

        this.$store.commit(
          "Hospitalization/Queue/SELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
          this.listSelected[0],
        );
        this.isExternaSchedule
          ? this.$router.push({
              name: "hospitalization.queue.external-schedule-form",
            })
          : this.$router.push({
              name: "hospitalization.queue.schedule",
            });

        return;
      } catch (Err) {
        AlertError(Err.message || Err);
      }
    },

    priorityColor(pValue) {
      return pValue.pfi_cor.replace("0x", "#");
    },

    selectingBedType(pBedType) {
      const source = pBedType && pBedType.source;
      if (source) {
        this.form.bedTypeId = source.itl_id;
      } else {
        this.form.bedTypeId = null;
      }
    },

    selectingProcedureOne(pProcedure) {
      const source = pProcedure.source;
      this.form.procedureIdOne = source ? source.inp_id : null;
      this.form.procedureCodeOne = source
        ? source.inp_novo_codigo_procedimento
        : null;
    },

    selectingProcedureTwo(pProcedure) {
      const source = pProcedure.source;
      this.form.procedureIdTwo = source ? source.inp_id : null;
      this.form.procedureCodeTwo = source
        ? source.inp_novo_codigo_procedimento
        : null;
    },

    selectingProcedureThree(pProcedure) {
      const source = pProcedure.source;
      this.form.procedureIdThree = source ? source.inp_id : null;
      this.form.procedureCodeThree = source
        ? source.inp_novo_codigo_procedimento
        : null;
    },

    selectingCidOne(pCid) {
      const source = pCid && pCid.source;
      this.form.cidIdOne = source ? source.cid_id : null;
      this.form.cidCodeOne = source ? source.cid_codigo : null;
    },

    selectingCidTwo(pCid) {
      const source = pCid && pCid.source;
      this.form.cidIdTwo = source ? source.cid_id : null;
      this.form.cidCodeTwo = source ? source.cid_codigo : null;
    },

    selectingCidThree(pCid) {
      const source = pCid && pCid.source;
      this.form.cidIdThree = source ? source.cid_id : null;
      this.form.cidCodeThree = source ? source.cid_codigo : null;
    },

    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.est_id = pValue.source.est_id;
      } else {
        this.form.est_id = null;
      }
    },

    selectingMunicipality(pValue) {
      if (pValue && pValue.source) {
        this.form.mun_id = pValue.source.mun_id;
      } else {
        this.form.mun_id = null;
      }
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Hospitalization/Queue/GET_FILTER_HOSPITALIZATION_QUEUE"
        ];

        if (existFilterData) {
          this.$loader.start();

          await this.$refs.bedType.forceSelection({
            itl_id: existFilterData.bedTypeId,
            itl_nome: existFilterData.bedType,
          });

          if (this.$refs.patient && existFilterData.pes_id)
            this.$refs.patient.fillPatientById(existFilterData.pes_id);

          await this.$refs.state.forceSelection({
            est_id: existFilterData.est_id,
            est_sigla: existFilterData.est_sigla,
          });

          await this.$refs.city.forceSelection({
            mun_id: existFilterData.mun_id,
            mun_nome: existFilterData.mun_nome,
          });

          await this.$refs.mainProcedure.forceSelection({
            inp_id: existFilterData.procedureIdOne,
            inp_novo_codigo_procedimento: existFilterData.procedureCodeOne,
            inp_novo_nome_procedimento: existFilterData.procedureOne,
          });

          await this.$refs.secondProcedure.forceSelection({
            inp_id: existFilterData.procedureIdTwo,
            inp_novo_codigo_procedimento: existFilterData.procedureCodeTwo,
            inp_novo_nome_procedimento: existFilterData.procedureTwo,
          });

          await this.$refs.thirdProcedure.forceSelection({
            inp_id: existFilterData.procedureIdThree,
            inp_novo_codigo_procedimento: existFilterData.procedureCodeThree,
            inp_novo_nome_procedimento: existFilterData.procedureThree,
          });

          await this.$refs.mainCid.forceSelection({
            cid_id: existFilterData.cidIdOne,
            cid_codigo: existFilterData.cidCodeOne,
            cid_nome: existFilterData.cidOne,
          });

          await this.$refs.secondCid.forceSelection({
            cid_id: existFilterData.cidIdTwo,
            cid_codigo: existFilterData.cidCodeTwo,
            cid_nome: existFilterData.cidTwo,
          });

          await this.$refs.thirdCid.forceSelection({
            cid_id: existFilterData.cidIdThree,
            cid_codigo: existFilterData.cidCodeThree,
            cid_nome: existFilterData.cidThree,
          });

          this.form = existFilterData;

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    async advancePatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado",
          );
          return;
        }

        if (!this.queueAmountAdvance) {
          this.$toaster.warning("Informe a posição na fila");
          return;
        }

        this.$loader.start();

        await this.$store.dispatch(
          "Hospitalization/Queue/ADVANCE_PATIENT_QUEUE",
          {
            intIdFila: this.listSelected[0].fil_id,
            intQtdPosicoes: this.queueAmountAdvance,
          },
        );

        await this.$refs.rgsearch.performSearch();
        this.queueAmountAdvance = 1;
        this.actionCleanSelection();
        this.$toaster.success("Operação realizada com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async backPatientInQueue() {
      try {
        if (this.listSelected[0].ordem_regulada === "EM ESPERA") {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado",
          );
          return;
        }

        if (!this.queueAmountBack) {
          this.$toaster.warning("Informe a posição na fila");
          return;
        }

        this.$loader.start();

        await this.$store.dispatch("Hospitalization/Queue/BACK_PATIENT_QUEUE", {
          intIdFila: this.listSelected[0].fil_id,
          intQtdPosicoes: this.queueAmountBack,
        });

        await this.$refs.rgsearch.performSearch();

        this.queueAmountBack = 1;
        this.actionCleanSelection();
        this.$toaster.success("Operação realizada com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async movePatientInQueue() {
      try {
        const isRegulate = this.listSelected.every((item) => {
          return item.fnp_ordem_regulada !== "0";
        });

        if (!isRegulate) {
          this.$toaster.warning(
            "Não é possível ordenar um paciente que não esteja regulado",
          );
          return;
        }

        this.$loader.start();

        await this.$store.dispatch("Hospitalization/Queue/MOVE_PATIENT_QUEUE", {
          intIdFila: this.selectedRows[0],
          intPosicao: this.queuePosition,
        });

        await this.$refs.rgsearch.performSearch();

        this.queuePosition = 1;
        this.actionCleanSelection();
        this.$toaster.success("Operação realizada com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async regulate() {
      try {
        this.$loader.start();

        this.hasAnyPendency();

        // ADICIONADA COMPARAÇÃO COM "" PARA PACIENTES INSERIDOS PELO LEGADO
        const onlyWithoutPendency = (ele) =>
          ele.fil_pendencia === "0" || ele.fil_pendencia === "";
        const formatArrayPayload = (ele) => ele.fil_id;

        const arrQueues = this.listSelected
          .filter(onlyWithoutPendency)
          .map(formatArrayPayload);

        if (arrQueues.length > 0) {
          await this.$store.dispatch(
            "Hospitalization/Queue/REGULATION_HOSPITALIZATION_QUEUE",
            arrQueues,
          );

          this.clearRowsSelected();
          await this.$refs.rgsearch.performSearch();
          this.$toaster.success(
            "Paciente(s) regulado(s) com sucesso",
            "Sucesso!",
          );
        }
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    async unRegulate() {
      try {
        this.$loader.start();

        const arrQueues = this.listSelected.map((ele) => {
          return ele.fil_id;
        });

        await this.$store.dispatch(
          "Hospitalization/Queue/UNREGULATE_HOSPITALIZATION_QUEUE",
          arrQueues,
        );

        await this.clearRowsSelected();
        await this.$refs.rgsearch.performSearch();
        this.$toaster.success("Paciente(s) desregulado(s) com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message || Err);
      } finally {
        this.$loader.finish();
      }
    },

    optionsDropdownHistory(pItem) {
      return [
        {
          label: "Histórico do Paciente na Fila",
          action: this.patientHistoryQueue,
        },
        {
          label: "Histórico de Atendimentos",
          action: this.patientCareHistory,
          disable: !this.hasOnlyOneLineSelected,
        },
      ];
    },

    optionsDropdownPerson(pItem) {
      return [
        {
          label: "Arquivos do Paciente na Fila",
          action: this.openModalPatientImageInRow,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Observações do Paciente na Fila",
          action: this.openModalObservationQueue,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Cadastro do Paciente",
          action: this.editPatient,
          disable: !this.hasOnlyOneLineSelected,
        },
      ];
    },

    editPatient() {
      const patientId = this.listSelected[0].pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(patientId));

      this.$router.push({
        name: `hospitalization.queue.edit-patient`,
      });
    },

    openModalPatientDataRegulationQueue() {
      this.modalPatientDataRegulationQueue = true;
    },

    async updatePatientAbEsus() {
      try {
        this.$loader.start();
        const itemSelected = this.listSelected[0];

        const patientAbEsusId = itemSelected.pae_id;
        const patientId = itemSelected.pac_id;
        const baseCardId = itemSelected.pae_ficha_base
          ? Number(itemSelected.pae_ficha_base)
          : null;

        const patientIndividualRegistration = await this.getIndividualRegistration();

        const hasNoRecord = patientIndividualRegistration
          ? patientIndividualRegistration.length === 0
          : false;

        if (hasNoRecord) {
          this.inactivatePatientBondBasicAttention(patientAbEsusId);
          this.showModalPatientRecordNotLinkedBasicAttention();
          return;
        }

        const hasOneRecord = patientIndividualRegistration
          ? patientIndividualRegistration.length === 1
          : false;

        const individualRegistrationWithSameId = patientIndividualRegistration.find(
          (x) =>
            Number(x.pab_id) === baseCardId ||
            Number(x.pab_id_payload) === baseCardId,
        );

        if (hasOneRecord && individualRegistrationWithSameId) {
          const patientAbEsus = await ConvertIndividualRegistrationToPatientAbEsus(
            individualRegistrationWithSameId,
            patientAbEsusId,
            patientId,
          );

          await this.updatePatientBondBasicAttention(patientAbEsus);
          await this.updateTelephonePatienAbEsus();

          this.$toaster.success("Operação realizada com sucesso");
        }
      } catch (e) {
        this.$toaster.warning("Não foi possível atualizar os dados", e);
      } finally {
        this.$loader.finish();
      }
    },

    async updatePatientBondBasicAttention(pData) {
      if (pData) {
        await this.$store.dispatch("Person/Patient/UPDATE_AB_ESUS", {
          abEsus: pData,
        });
      }
    },

    async updateTelephonePatienAbEsus() {
      const selectedRows = this.selectedRows;
      const listSelected = this.listSelected;

      await this.$refs.rgsearch.performSearch();

      this.selectedRows = selectedRows;
      this.listSelected = listSelected;
    },

    showModalPatientRecordNotLinkedBasicAttention() {
      this.modalPatientRecordNotLinkedBasicAttention = true;
    },

    closeModalPatientRecordNotLinkedBasicAttention() {
      this.modalPatientRecordNotLinkedBasicAttention = false;
    },

    async inactivatePatientBondBasicAttention(pPatientAbEsusId) {
      const INATIVO = 0;
      const dataAbEsusInactive = {
        pae_id: Number(pPatientAbEsusId),
        pae_ativo: INATIVO,
        pae_ultima_alteracao: DateNow(),
      };
      await this.updatePatientBondBasicAttention(dataAbEsusInactive);
      await this.updateTelephonePatienAbEsus();
      this.listSelected[0].pae_ativo = 0;
    },

    async getIndividualRegistration() {
      const itemSelected =
        this.listSelected &&
        this.listSelected.length > 0 &&
        this.listSelected[0];

      if (!itemSelected || !itemSelected.pae_ativo) return null;

      const baseCardId = itemSelected.pae_ficha_base
        ? Number(itemSelected.pae_ficha_base)
        : null;
      const patientCpf = itemSelected.pae_cpf || null;
      const patientCns = itemSelected.pae_cartao_sus || null;

      if (baseCardId && (patientCpf || patientCns)) {
        const variables = {
          baseCardId: baseCardId,
          cpf: patientCpf,
          cns: patientCns,
        };

        try {
          const result = await this.$store.dispatch(
            "Person/Patient/GET_AB_INDIVIDUAL_REGISTRATION",
            variables,
          );

          return result?.rows;
        } catch {
          return null;
        }
      }

      return null;
    },
    openModalConfirmAnticipateScheduling() {
      this.modalConfirmAnticipateScheduling = true;
    },

    closeModalConfirmAnticipateScheduling() {
      this.modalConfirmAnticipateScheduling = false;
      this.clearRowsSelected();
    },

    printProofInsertionQueue() {
      this.modalPrint = true;
    },

    openModalObservationQueue() {
      this.modalObservationQueue = true;
    },

    closeModalObservationQueue() {
      this.modalObservationQueue = false;
    },

    optionsDropdownMore(pItem) {
      return [
        {
          label: "Arquivos do paciente na fila",
          action: this.openModalPatientImageInRow,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Observações do paciente na fila",
          action: this.observationPatient,
          disable: !this.hasOnlyOneLineSelected,
        },
        {
          label: "Histórico do paciente na fila",
          action: this.patientHistoryQueue,
        },
        // {
        //   label: "Histórico de ocupação do paciente",
        //   action: this.patientOccupationHistory,
        // },
      ];
    },

    observationPatient() {
      if (this.filId) {
        this.openModalObservationQueue();
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação",
        );
      }
    },

    patientImagesQueue() {
      this.$toaster.warning("Não implementado");
    },

    patientHistoryQueue() {
      this.showModalSimpleHistory = true;
    },

    patientCareHistory() {
      const patientInfo = this.listSelected[0];
      this.$router.push({
        name: "hospitalization.queue.patient-care-history",
        params: patientInfo,
      });
    },

    closeModalPatientDataRegulationQueue() {
      this.modalPatientDataRegulationQueue = false;
    },

    patientOccupationHistory() {
      this.$toaster.warning("Não implementado");
    },

    openModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = true;
    },

    closeModalConfirmQueueDeletion() {
      this.modalConfirmQueueDeletion = false;
      this.clearRowsSelected();
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow(hasSavedFiles) {
      this.modalPatientImageInRow = false;
      this.mutableHospitalizationQueue.forEach((item) => {
        if (item.fil_id === this.selectedRows[0]) {
          item.anexo = hasSavedFiles === true ? "SIM" : "NÃO";
        }
      });
      this.clearRowsSelected();
      this.selectedRows = [];
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    clearRowsSelected() {
      this.listSelected = [];
      this.selectedRows = [];
      this.selectedQueueOrder = [];
    },

    closeModalPrint() {
      this.modalPrint = false;
    },

    actionCleanSelection() {
      this.selectedRows = [];
      this.listSelected = [];
      this.selectedQueueOrder = [];
    },

    cleanForm() {
      this.selectedRows = [];
      this.listSelected = [];
      this.mutableHospitalizationQueue = [];
      this.cbo = null;
      this.form = {
        document: null,
        bedType: null,
        unit: null,
        patientName: null,
        priority: null,
        situation: null,
        est_id: null,
        est_sigla: null,
        mun_id: null,
        mun_nome: null,
        procedureIdOne: null,
        procedureIdTwo: null,
        procedureIdThree: null,
        cidIdOne: null,
        cidIdTwo: null,
        cidIdThree: null,
        initialOrderPeriod: moment().add(-1, "years").format("DD/MM/YYYY"),
        finalOrderPeriod: moment().format("DD/MM/YYYY"),
        initialInclusionDate: moment().add(-1, "years").format("DD/MM/YYYY"),
        finalInclusionDate: moment().format("DD/MM/YYYY"),
      };
      this.queueAmountAdvance = 1;
      this.queueAmountBack = 1;
      this.queuePosition = 1;
    },

    async viewHospitalizationScheduleVoucher(hospitalizationID) {
      this.hospitalizationIdToPrint = hospitalizationID;
      switch (this.preferences.printOptionsPreference) {
        case "2": // Impressão Normal
          this.openModalTypesPrint();
          break;
        case "6": // Impressão Normal
          await printScheduleVoucher(hospitalizationID);
          break;
        case "7": // Impressora Térmica 1 via
          await printThermalOneWay(hospitalizationID);
          break;
        case "8": // Impressora Térmica 2 vias
          await printThermalTwoWay(hospitalizationID);
          break;
        default:
          break;
      }
    },

    hasAnyPendency() {
      const hasPendency = (element) => {
        // ADICIONADA COMPARAÇÃO COM "" PARA PACIENTES INSERIDOS PELO LEGADO
        return element.fil_pendencia !== "0" && element.fil_pendencia !== "";
      };
      const isPendency = this.listSelected.find(hasPendency);
      if (isPendency) {
        this.$toaster.warning(
          "Pacientes com pendências não podem ser regulados.",
          "Atenção",
        );
      }
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialOrderPeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalOrderPeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyInclusionInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialInclusionDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalInclusionDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    openDropdownColumns() {
      this.savePreferences();
    },

    async savePreferences() {
      try {
        const modulesListId = this.$store.getters["Login/GET_ROUTE_MODULE_MAP"];
        const locationModule = window.location.pathname;
        let moduleId = null;

        for (const iterator of Object.keys(modulesListId)) {
          if (locationModule.includes(iterator)) {
            moduleId =
              modulesListId[iterator] === null ? 0 : modulesListId[iterator];
          }
        }

        const variables = {
          preferenceColumnTable: {
            moduleId,
            userId: this.userLoginId,
            name: this.name || "HospitalizationQueue",
            unitHealthId: this.unitHealthId,
            link: this.$route.path,
            columns: this.header
              .filter((element) => element.active === true)
              .map((element) => {
                delete element.icon;
                return element;
              }),
          },
        };
        await this.$store.dispatch("User/SAVE_PREFERENCE_COLUMNS", variables);
      } catch (error) {
        this.$toaster.error("Erro ao salvar preferência de colunas");
      }
    },

    async loadPreferences() {
      try {
        if (
          !this.getColumns(
            this.$route.path,
            this.userLoginId,
            this.unitHealthId,
            this.name || "HospitalizationQueue",
          )
        ) {
          const modulesListId = this.$store.getters[
            "Login/GET_ROUTE_MODULE_MAP"
          ];
          const locationModule = window.location.pathname;
          const moduleId = [];

          for (const iterator of Object.keys(modulesListId)) {
            if (locationModule.includes(iterator)) {
              moduleId.push(
                modulesListId[iterator] === null ? 0 : modulesListId[iterator],
              );
            }
          }

          const result = await this.$store.dispatch(
            "User/SEARCH_PREFERENCE_COLUMNS",
            {
              moduleId:
                moduleId.length > 1
                  ? moduleId[moduleId.length - 1].toString()
                  : moduleId.toString(),
              userId: this.userLoginId,
              unitHealthId: this.unitHealthId,
            },
          );

          for (const columnsTable of result) {
            if (
              !this.getColumns(
                columnsTable.link,
                columnsTable.usu_id,
                columnsTable.uns_id,
                columnsTable.name,
              )
            ) {
              this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
                ...columnsTable,
              });

              if (
                this.userLoginId === columnsTable.usu_id &&
                columnsTable.name === (this.name || "HospitalizationQueue") &&
                this.unitHealthId === columnsTable.uns_id &&
                this.$route.path === columnsTable.link
              ) {
                if (columnsTable.columns.length > 0) {
                  for (const col of this.header) {
                    col.active = false;
                  }
                  for (const option of columnsTable.columns) {
                    for (const col of this.header) {
                      if (col.key === option.key) {
                        col.active = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        this.$toaster.error("Erro ao buscar preferência de colunas");
      }
    },
  },
};
</script>
