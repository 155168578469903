<script>
import { RgComboboxMixin } from "~tokio/primitive";

export default {
  name: "RgComboboxPharmacyOrderStatus",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Situação do Pedido",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    isReport: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    if (this.isReport) {
      return {
        mData: [
          { text: "Aberto", value: 1 },
          { text: "Fechado", value: 2 },
          { text: "Excluído", value: 3 },
          { text: "Todos", value: 4 },
        ],
      };
    } else {
      return {
        mData: [
          { text: "Aberto", value: 1 },
          { text: "Fechado", value: 2 },
        ],
      };
    }
  },
};
</script>
