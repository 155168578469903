import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/per-day/edit/form/edit-patient",
  name: "exam.schedule.per-day.edit.form.edit-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      { label: "Agendamentos por Dia", link: "/exam/schedule/per-day" },
      { label: "Editar Exame", link: "/exam/schedule/per-day/edit/form" },
      { label: "Editar Paciente" },
    ],
  },
};
