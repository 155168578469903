import { render, staticRenderFns } from "./SmallButton.vue?vue&type=template&id=35fb9bf9&scoped=true&"
import script from "./SmallButton.vue?vue&type=script&lang=js&"
export * from "./SmallButton.vue?vue&type=script&lang=js&"
import style0 from "./SmallButton.scss?vue&type=style&index=0&id=35fb9bf9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35fb9bf9",
  null
  
)

export default component.exports