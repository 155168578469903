import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  FuncionarioInabilitacaoDependenciaExameException: `Não foi possível inabilitar os profissionais`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/exames2/controller-exame-hab-inab-atendimento/validar-inabilitacao-exames",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
