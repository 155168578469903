import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFila: variables.queueId,
    strMotivo: variables.reason,
  };

  return AuthLegacyRequest.post(
    "exames2/controller-fila-exame/cadastrar-motivo-agendamento-fora-topo",
    sendData,
  )
    .then(({ data }) => {})
    .catch((pErr) => {
      throw pErr;
    });
};
