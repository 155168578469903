const USER_GROUP = {
  ADM: 1,
  SUPPORT: 2,
  SUPERVISOR: 3, // gestores
  MANAGER: 4,
  EMPLOYEE: 5,
  UNIT_ADM: 6,
  DEVELOP: 7,
  EXTERNAL_SYSTEM: 8,
};

export default { ...USER_GROUP };
