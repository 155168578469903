import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchServices($unitHealthId: Int!, $procedureCode: String!) {
    SearchServices(unitHealthId: $unitHealthId, procedureCode: $procedureCode) {
      rows {
        CO_SERVICO
        NO_SERVICO
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchServices.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
