<template slot="subFilters">
  <div class="pharmacy-central-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          ref="productType"
          v-model="form.mtp_id"
          class="inputitem"
          @change="selectedType"
        />

        <RgSuggestUser
          id="user"
          v-model="form.usu_nome"
          label="Usuário"
          placeholder="Todos"
          :clientId="clientLoginId"
          @selected="selectedUser"
        />

        <RgComboboxReasonDiscard
          id="reason"
          ref="reason"
          v-model="form.mdc_id"
          class="inputitem"
          @change="selectedReason"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgComboboxReasonDiscard from "$pharmacy/submodules/central/components/combobox/rg-combobox-reason-discard/RgComboboxReasonDiscard";
import { RgSuggestUser } from "~tokio/primitive";

const FORM_FILTER = {
  mtp_id: null,
  usu_nome: null,
  usu_id: null,
  mdc_id: null,
  mtp_nome: null,
  mdc_nome: null,
  columnsToPrint: [],
};

export default {
  name: "PharmacyCentralDiscardReport",
  components: {
    RgSelectProductType,
    RgSuggestUser,
    RgComboboxReasonDiscard,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },

  computed: {
    getNameSmartTable() {
      return "PharmacyCentralDiscardReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    ...mapGetters({
      clientLoginId: "Login/GET_USER_ID_CLIENT",
    }),
  },
  watch: {
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Farmácia", key: "FARMACIA", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Data", key: "DATA", align: "right" },
      { name: "Motivo", key: "MOTIVO", align: "left" },
      { name: "Observação", key: "OBSERVACAO", align: "left" },
      { name: "Usuário", key: "USUARIO", align: "left" },
      { name: "Valor Unitário", key: "VALOR_UNITARIO", align: "right" },
      { name: "Valor Total", key: "VALOR_TOTAL", align: "right" },
    ]);
  },

  methods: {
    selectedUser(pValue) {
      const source = pValue?.source;
      if (source) {
        this.form.usu_id = source.usu_id;
      } else {
        this.form.usu_id = null;
      }
    },
    selectedType(pValue) {
      if (pValue) {
        this.form.mtp_nome = pValue.mtp_nome;
      } else {
        this.form.mtp_nome = null;
      }
    },
    selectedReason(pValue) {
      if (pValue && pValue.length > 0) {
        this.form.mdc_nome = pValue[0].text;
      } else {
        this.form.mdc_nome = null;
      }
    },
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          ...this.form,
          mtp_id: Number(this.form.mtp_id),
          usu_id: Number(this.form.usu_id),
          mdc_id: Number(this.form.mdc_id),
        };
        const globalFilters = {
          ...this.globalFilters,
          pharmacy: Number(this.globalFilters.pharmacy),
          product: Number(this.globalFilters.product),
        };

        const filters = {
          ...globalFilters,
          ...variables,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CENTRAL_DISCARD",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
  },
};
</script>
