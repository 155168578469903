<template>
  <Modulebox
    title="Atender Solicitações de Produtos e Medicamentos"
    class="pharmacy-central-attend-request"
  >
    <main class="pharmacy-central-attend-request-body">
      <FormBase title="Dados do Atendimento" class="form-base">
        <section class="service-data">
          <RgSelectPharmacy
            ref="pharmacy"
            v-model="request.ped_id_farmacia_destino"
            label="Farmácia de Destino (Atendente)"
            byUser
            disabled
            :userId="userId"
            :unitHealthId="Number(request.idUnidadeDestino)"
            :typePharmacy="CENTRAL_PHARMACY_TYPE"
            :rules="{ required: true }"
            :class="{ disable: true }"
          />
          <RgSelectPharmacy
            ref="pharmacy"
            v-model="request.ped_id_farmacia_origem"
            label="Farmácia de Origem (Solicitante)"
            byUser
            disabled
            :userId="userId"
            :unitHealthId="Number(request.idUnidadeOrigem)"
            :typePharmacy="SATELLITE_PHARMACY_TYPE"
            :rules="{ required: true }"
            :class="{ disable: true }"
          />
          <RgInput
            v-model="request.remittanceGuide"
            label="Guia de Remessa"
            placeholder="Número da Guia"
            :rules="{ required: true }"
            :class="{ disable: true }"
            disabled
          />
          <RgInput
            v-model="request.ped_numero"
            label="Número"
            placeholder="Número da Solicitação"
            :rules="{ required: true }"
            :class="{ disable: true }"
            disabled
          />
          <RgInputDate
            v-model="request.ped_data_pedido"
            label="Data da Solicitação"
            :rules="{ required: true }"
            :class="{ disable: true }"
            disabled
          />
        </section>
      </FormBase>

      <FormBase title="Produtos e Medicamentos" class="form-base">
        <RgValidatorForm ref="validator">
          <div class="tooltips">
            <Tooltip class="tooltip-print">
              <IconInfoHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Lote com estoque insuficiente para atendimento: para atender
                    a quantidade restante, selecione o pedido e escolha outro
                    lote.
                  </span>
                </div>
              </div>
            </Tooltip>
          </div>
          <section class="products-data">
            <RgSelectProduct
              v-model="form.product"
              perClient
              :withPresentation="false"
              :rules="{ required: true }"
              :disabled="true"
              :class="{ disable: true }"
              @change="selectedProduct"
            />
            <RgSelectTypePresentation
              v-model="form.presentationType"
              label="Apresentação"
              :rules="{ required: true }"
              :disabled="true"
              :class="{ disable: true }"
            />
            <RgSelectLotExpiration
              ref="lotExpiration"
              v-model="form.lotExpiration"
              label="Lote e Validade"
              :pharmacyId="Number(request.ped_id_farmacia_destino)"
              :productId="Number(productId)"
              :rules="{ required: true }"
              :disabled="
                statusOrderClosed || disabledByModal || !this.form.product
              "
              :class="{ disable: statusOrderClosed || !this.form.product }"
              @change="selectedLotExpiration"
            />
            <RgInputDate
              v-model="form.serviceDate"
              label="Data de Atendimento"
              :rules="{ required: true }"
              :class="{ disable: true }"
              disabled
            />
            <RgInput
              ref="stock"
              v-model="form.stock"
              label="Estoque"
              readonly
              :rules="{ required: true }"
              :class="{ disable: true }"
              disabled
            />
            <RgInputNumber
              ref="amount"
              v-model="form.amount"
              label="Quantidade"
              placeholder="000000"
              :rules="{ required: true, fn: validateAmount }"
              :disabled="
                statusOrderClosed || disabledByModal || !this.form.product
              "
              :class="{ disable: statusOrderClosed || !this.form.product }"
            />
            <div class="actions">
              <SmallButton
                id="disable-product"
                backgroundColor="#1E88A9"
                title="Indisponível"
                :disabled="
                  statusOrderClosed ||
                  !this.form.product ||
                  disabledByModal ||
                  asPartiallyServiced
                "
                @click="modalConfirmReturn = !modalConfirmReturn"
              >
                <div slot="icon" class="icon icon-unavailable">
                  <IconAdd />
                </div>
              </SmallButton>
              <RgCleanButton
                :disabled="
                  statusOrderClosed || disabledByModal || !this.form.product
                "
                @click="cleanFields"
              />
              <RgAddButton
                id="add-btn"
                title="Adicionar Produto/Medicamento"
                :disabled="
                  statusOrderClosed || !this.form.product || disabledByModal
                "
                @click="dispenseProduct"
              />
            </div>
          </section>
        </RgValidatorForm>

        <hr class="separator" />

        <section class="product-tables">
          <div>
            <span class="table-title">
              Produtos e Medicamentos aguardando atendimento
            </span>
            <SmartTable
              ref="smartTable"
              name="PharmacyCentralAttendRequest"
              :columns="COLUMNS"
              :body="mutableItemsByOrderList"
              :total="mutableItemsByOrderList.length"
              :showPagination="false"
              :removeBtnColumns="true"
              :initial-columns="5"
              toggle-selected
              :dontSelect="this.request.ped_status === '1'"
              class="smart-table-attend-transfer"
              @getLine="selectLine"
            />
          </div>
          <div>
            <span class="table-title">Produtos e Medicamentos atendidos</span>
            <ul class="products-shipping scroll-table">
              <li
                v-for="(item, index) of mutableProductsForShipping"
                :key="index"
                class="item-list"
              >
                <span :title="item.mpd_codigo" class="ellipsis">
                  {{ item.mpd_codigo }}
                </span>
                <span :title="item.mpd_novo_principio_ativo" class="ellipsis">
                  {{ item.mpd_novo_principio_ativo }}
                </span>
                <span :title="item.mtu_novo_nome" class="ellipsis">
                  {{ item.mtu_novo_nome }}
                </span>
                <span :title="item.trs_quantidade" class="ellipsis">
                  {{ item.trs_quantidade || "-" }}
                </span>
                <span :title="item.std_nome" class="ellipsis">
                  {{ item.std_nome }}
                </span>
              </li>
              <li
                v-if="mutableProductsForShipping.length === 0"
                class="default-item-list"
              >
                Nenhum item para envio
              </li>
            </ul>
          </div>
        </section>
      </FormBase>
    </main>

    <footer class="pharmacy-central-attend-request-footer">
      <RgRadioCustom
        id="status"
        label="Situação:"
        v-bind="propsRadio"
        :value="statusOrder"
        class="situation"
        disabled
      />
      <div class="actions">
        <LargeButton
          label="Observações"
          backgroundColor="#1e88a9"
          :disabled="disabledByModal"
          @click="openModalObservationRequest"
        >
          <IconObservation slot="icon" class="icon" />
        </LargeButton>
        <LargeButton
          label="Fechar Pedido"
          backgroundColor="#ff7f2a"
          :disabled="statusOrderClosed || disabledByModal || isWaiting"
          @click="closeOrder"
        >
          <IconCheck slot="icon" class="icon" />
        </LargeButton>
        <MediumButton
          label="Voltar"
          :disabled="disabledByModal"
          @click="goBack"
        />
      </div>
    </footer>
    <ModalConfirmDefault
      id="modal-confirm-default"
      :show="modalConfirmReturn"
      subtitle="Deseja não atender a solicitação por motivo de Estoque Indisponível?"
      title="Indisponibilidade de Estoque"
      :message="productSelected.message"
      yes-label="Salvar"
      no-label="Cancelar"
      class="modal-confirm-return"
      buttonsRight
      @getYes="unavailableOrder"
      @getOut="closeModalUnavailableOrder"
      @close="closeModalUnavailableOrder"
    />

    <ModalObservationRequest
      :show="showModalObservationRequest"
      :requestData="observationData"
      @close="closeModalObservationRequest"
    />
  </Modulebox>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration";

import ModalObservationRequest from "$pharmacy/submodules/satellite/components/modal/modal-observation-request/ModalObservationRequest";

import {
  RgValidatorForm,
  RgInput,
  RgInputDate,
  RgInputNumber,
  RgRadioCustom,
  RgCleanButton,
  RgAddButton,
  SmallButton,
  MediumButton,
  LargeButton,
  ModalConfirmDefault,
  Tooltip,
  IconInfoHelper,
} from "~tokio/primitive";

import {
  IconAdd,
  IconCheck,
  IconObservation,
} from "~tokio/primitive/icon/symbols";

const ID_STATUS_ITEM_REQUEST = {
  waiting: "1",
  answered: "2",
  partiallyServiced: "3",
  unavailableItem: "4",
};

const FORM = {
  product: null,
  presentationType: null,
  lotExpiration: null,
  serviceDate: moment().format("DD/MM/YYYY"),
  stock: "0",
  amount: null,
};

export default {
  name: "PharmacyCentralAttendRequest",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    SmartTable,
    RgInput,
    RgInputDate,
    RgInputNumber,
    RgRadioCustom,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectTypePresentation,
    RgSelectLotExpiration,
    RgCleanButton,
    RgAddButton,
    SmallButton,
    MediumButton,
    LargeButton,
    IconAdd,
    IconObservation,
    IconCheck,
    ModalObservationRequest,
    ModalConfirmDefault,
    Tooltip,
    IconInfoHelper,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      request: {},
      productSelected: {},
      mutableItemsByOrderList: [],
      mutableProductsForShipping: [],
      observationData: [],
      orderId: null,
      pharmacyId: null,
      productId: null,
      lot: null,
      expiration: null,
      modalConfirmReturn: false,
      showModalObservationRequest: null,
    };
  },
  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
    }),
    propsRadio() {
      const list = [
        { title: "Aberto", blue: true, id: 1, active: true },
        {
          title: "Fechado",
          blue: true,
          id: 2,
          active: false,
        },
      ];
      const uniqueKey = "id";

      return { list, uniqueKey };
    },
    statusOrder() {
      const INACTIVE_STATUS = "1";

      const OPEN = 1;
      const CLOSED = 2;

      return this.request.ped_status === INACTIVE_STATUS ? CLOSED : OPEN;
    },
    statusOrderClosed() {
      const CLOSED = "1";

      return this.request.ped_status === CLOSED;
    },
    asPartiallyServiced() {
      return (
        this.productSelected &&
        this.productSelected.itp_id_status_itens_pedido === "3"
      );
    },
    disabledByModal() {
      return this.showModalObservationRequest || this.modalConfirmReturn;
    },
    isWaiting() {
      let waiting = false;
      const statusWaiting = "1";
      if (this.mutableItemsByOrderList.length === 0) {
        waiting = false;
      } else {
        this.mutableItemsByOrderList.forEach((item) => {
          if (item.itp_id_status_itens_pedido === statusWaiting) {
            waiting = true;
          }
        });
      }

      return waiting;
    },
  },
  watch: {},
  created() {
    this.CENTRAL_PHARMACY_TYPE = 1;
    this.SATELLITE_PHARMACY_TYPE = 2;

    this.COLUMNS = [
      { name: "Código", key: "mpd_codigo", active: true, align: "left" },
      {
        name: "Nome",
        key: "mpd_novo_principio_ativo",
        active: true,
        align: "left",
      },
      {
        name: "Apresentação",
        key: "mtu_novo_nome",
        active: true,
        align: "left",
      },
      {
        name: "Solicitado",
        key: "itp_quantidade_solicitada",
        active: true,
        align: "left",
      },
      { name: "Restante", key: "rest", active: true, align: "left" },
    ];
  },
  mounted() {
    this.loadRequestData();
  },
  destroyed() {
    this.$store.commit("Pharmacy/Central/UNSELECT_PHARMACY_CENTRAL");
  },
  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async loadRequestData(pParams = this.$route.params) {
      this.orderId = pParams.ped_id;

      try {
        this.$loader.start();

        this.request = await this.$store.dispatch(
          "Pharmacy/Satellite/GET_REQUEST",
          { intIdPedido: this.orderId },
        );

        const remittanceGuide = await this.$store.dispatch(
          "Pharmacy/Central/GET_REMITTANCE_GUIDE",
          { ped_id: this.orderId },
        );

        this.request.remittanceGuide = remittanceGuide.toUpperCase();

        this.observationData = [
          {
            far_id: this.request.ped_id_farmacia_destino,
            ped_id: this.request.ped_id,
            ped_numero: this.request.ped_numero,
          },
        ];

        await this.searchItemsByOrder(this.orderId);
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr.message || pErr),
          "Erro ao carregar solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    closeModalUnavailableOrder() {
      this.modalConfirmReturn = false;
    },
    async searchItemsByOrder(pRequestId) {
      this.mutableItemsByOrderList = [];
      this.mutableProductsForShipping = [];

      try {
        this.$loader.start();

        const variables = {
          intIdPedido: pRequestId,
          blnSomenteDisponiveis: false,
          blnDispensacoes: true,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_ITEMS_BY_ORDER",
          variables,
        );

        result.dados.map((item) => {
          const waiting =
            item.itp_id_status_itens_pedido === ID_STATUS_ITEM_REQUEST.waiting;
          const partiallyServiced =
            item.itp_id_status_itens_pedido ===
            ID_STATUS_ITEM_REQUEST.partiallyServiced;

          if (
            (waiting || partiallyServiced) &&
            Number(item.ist_quantidade_solicitada) -
              Number(item.trs_quantidade) !==
              0
          ) {
            const result = this.mutableItemsByOrderList.find(
              (variable) => item.mpd_codigo === variable.mpd_codigo,
            );

            if (!result) this.mutableItemsByOrderList.push(item);
            if (!waiting) this.mutableProductsForShipping.push(item);
          } else {
            this.mutableProductsForShipping.push(item);
          }
        });

        let total = 0;
        if (this.mutableItemsByOrderList.length > 0) {
          for (const pValue of this.mutableItemsByOrderList) {
            this.mutableProductsForShipping.forEach((item) => {
              if (Number(item.mpd_codigo) === Number(pValue.mpd_codigo)) {
                total += Number(item.trs_quantidade);

                pValue.rest =
                  Number(pValue.itp_quantidade_solicitada) - Number(total);
              }
            });

            if (total === 0)
              pValue.rest = Number(pValue.itp_quantidade_solicitada);
            total = 0;
          }
        } else {
          this.mutableItemsByOrderList.forEach((item) => {
            item.rest = Number(item.itp_quantidade_solicitada);
          });
        }
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr.message || pErr),
          "Erro ao carregar os itens da solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async getStock() {
      try {
        if (
          this.request.ped_id_farmacia_destino &&
          this.form.product &&
          this.lot
        ) {
          this.$loader.start();

          const data = {
            lot: this.lot,
            product: Number(this.form.product),
            pharmacy: Number(this.request.ped_id_farmacia_destino),
          };

          const result = await this.$store.dispatch(
            "Pharmacy/Central/GET_STOCK_ATTEND_REQUEST",
            data,
          );

          result ? (this.form.stock = result) : (this.form.stock = "0");
        }
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr.message || pErr),
          "Erro ao obter o estoque do produto",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async dispenseProduct() {
      const validateForm = await this.isFormValid();

      if (!validateForm) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.$loader.start();

        const data = {
          arrFormData: {
            intEstocado: Number(this.form.amount.replace(/[.]/g, "")),
            totalDispensado: Number(this.form.amount.replace(/[.]/g, "")),
            intIdLote: this.lot,
            strValidadeLote: this.expiration,
            intSolicitado: Number(
              this.productSelected.itp_quantidade_solicitada.replace(
                /[.]/g,
                "",
              ),
            ),
            itp_id: Number(this.productSelected.itp_id),
            itp_id_municipios_produtos: Number(
              this.productSelected.itp_id_municipios_produtos,
            ),
            itp_id_status_itens_pedido: this.generationStatusItem(),
            ped_id: Number(this.request.ped_id),
            ped_id_farmacia_destino: Number(
              this.request.ped_id_farmacia_destino,
            ),
            ped_id_farmacia_origem: Number(this.request.ped_id_farmacia_origem),
            strGuiaRemessa: this.request.remittanceGuide,
          },
        };

        await this.$store.dispatch(
          "Pharmacy/Central/DISPENSE_ATTEND_REQUEST",
          data,
        );

        this.$toaster.success("Solicitação atendida com sucesso");
        const info = {
          intIdMunicipioProduto: Number(this.productId),
          intIdFarmacia: Number(this.request.ped_id_farmacia_destino),
        };

        const infoLimit = await this.$store.dispatch(
          "Pharmacy/MIN_LIMIT_EXCEEDED",
          info,
        );

        if (infoLimit !== 0) {
          this.$toaster.info(
            "Faça uma solicitação para repor o estoque do produto.",
            "O estoque mínimo foi alcançado",
          );
        }
        await this.loadRequestData();
        this.cleanForm();
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr.message || pErr),
          "Erro ao atender solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async unavailableOrder() {
      try {
        this.$loader.start();
        await this.$store.dispatch(
          "Pharmacy/Central/UNAVAILABLE_ORDER_ATTEND_REQUEST",
          {
            intIdPedido: this.request.ped_id,
            intIdItemPedido: this.productSelected.itp_id,
          },
        );
        this.modalConfirmReturn = false;
        this.$toaster.success("Pedido indisponibilizado com sucesso");
        await this.loadRequestData();
        this.cleanForm();
      } catch (pErr) {
        this.modalConfirmReturn = false;
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr.message || pErr),
          "Erro ao indisponibilizar produto",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async closeOrder() {
      // const validate = this.validateCloseOrder();
      // if (validate) {
      //   this.$toaster.warning(
      //     "É necessário atender todos os itens para fechar o pedido",
      //   );
      //   return;
      // }

      try {
        this.$loader.start();
        await this.$store.dispatch(
          "Pharmacy/Central/CLOSE_ORDER_ATTEND_REQUEST",
          { intIdPedido: this.request.ped_id },
        );

        this.$toaster.success("Pedido fechado com sucesso");
        await this.loadRequestData();
        this.cleanForm();
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr.message || pErr),
          "Não foi possível fechar o pedido",
        );
      } finally {
        this.$loader.finish();
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    generationStatusItem() {
      const quantity = Number(this.form.amount);
      const rest = Number(this.productSelected.rest) - Number(quantity);

      if (quantity === 0) return ID_STATUS_ITEM_REQUEST.waiting;
      if (rest > 0) return ID_STATUS_ITEM_REQUEST.partiallyServiced;
      if (rest === 0) return ID_STATUS_ITEM_REQUEST.answered;
    },
    validateExistingProductShippingTable(pProductId) {
      return this.mutableItemsByOrderList.find(
        (item) => item.itp_id_municipios_produtos === pProductId,
      );
    },
    // validateCloseOrder() {
    //   return this.mutableItemsByOrderList.find(
    //     (item) =>
    //       item.itp_id_status_itens_pedido ===
    //       ID_STATUS_ITEM_REQUEST.waiting.toString(),
    //   );
    // },
    validateAmount(pValue, pErrors) {
      const amount = Number(pValue.replace(/[^0-9]/g, ""));
      const stock = Number(this.form.stock.replace(/[^0-9]/g, ""));
      const rest = Number(this.productSelected.rest.replace(/[.]/g, ""));

      if (amount && amount < 1) {
        pErrors.push("Informe um valor maior que zero");
        return false;
      }

      if (stock < amount) {
        pErrors.push("A quantidade não pode ser maior que o estoque");
        return false;
      }

      const requestAmount = Number(
        this.productSelected.itp_quantidade_solicitada.replace(/[.]/g, ""),
      );

      if (requestAmount < amount) {
        pErrors.push("A quantidade não pode ser maior que a solicitada");
        return false;
      }
      if (rest < amount) {
        pErrors.push("A quantidade não pode ser maior que o restante");
        return false;
      }

      return true;
    },
    selectLine(pItem) {
      if (pItem) {
        this.productSelected = pItem;
        this.form.product = pItem.itp_id_municipios_produtos;
        this.productId = pItem.itp_id_municipios_produtos;
        this.productSelected.message =
          this.productSelected.mpd_novo_principio_ativo +
          " - " +
          this.productSelected.mtu_novo_nome;
      } else {
        this.productSelected = {};
        this.form = this.$utils.obj.DeepCopy(FORM);
        this.productId = null;
      }
    },
    selectedProduct(pProduct) {
      if (pProduct) {
        this.productId = pProduct.value;

        const productId = pProduct.value;
        const hasProductList = this.validateExistingProductShippingTable(
          productId,
        );

        if (hasProductList) {
          this.productSelected = hasProductList;

          this.form.presentationType = pProduct.mtu_id || null;
          this.form.code = pProduct.mpd_codigo;
        } else {
          this.form.presentationType = pProduct.mtu_id || null;
          this.form.code = pProduct.mpd_codigo;

          this.$toaster.warning("O produto selecionado não foi solicitado");
        }
      } else {
        this.productId = null;
        this.cleanForm();
      }
    },
    async selectedLotExpiration(pLotExpiration) {
      if (pLotExpiration) {
        this.lot = pLotExpiration.value;
        this.expiration = pLotExpiration.fel_validade_lote;
        await this.getStock();
      } else {
        this.form.stock = "0";
        this.lot = null;
        this.expiration = null;
      }
    },
    openModalObservationRequest() {
      this.showModalObservationRequest = true;
    },
    closeModalObservationRequest() {
      this.showModalObservationRequest = false;
    },
    cleanForm() {
      this.productSelected = {};
      this.form = this.$utils.obj.DeepCopy(FORM);
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },

    cleanFields() {
      this.form.lotExpiration = null;
      this.form.stock = "0";
      this.form.amount = null;
      this.cleanFieldsValidation();
    },

    cleanFieldsValidation() {
      const fieldsRefs = ["lotExpiration", "stock", "amount"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
