<template slot="subFilters">
  <div class="pharmacy-satellite-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.mtp_id"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />
        <RgSuggestPatient
          ref="patient"
          v-model="form.pcf_nome"
          :withInfo="false"
          placeholder="Digite o nome do paciente"
          label="Paciente"
          @selected="getPatientInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSuggestPatient from "$pharmacy/common/suggest/rg-suggest-patient/RgSuggestPatient.vue";
import { mapGetters } from "vuex";

const FORM_FILTER = {
  pcf_id: null,
  pcf_nome: null,
  mtp_id: null,
  mtp_nome: null,
};

export default {
  name: "PharmacySatelliteServiceHistoryReport",
  components: {
    RgSelectProductType,
    RgSuggestPatient,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },
  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
    }),
  },
  watch: {
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Data", key: "DATA", align: "right" },
      { name: "Farmácia", key: "FARMACIA", align: "left" },
      { name: "Usuário", key: "USUARIO", align: "left" },
    ]);
  },

  methods: {
    selectedProductType(pValue) {
      if (pValue) {
        this.form.mtp_nome = pValue.mtp_nome;
      } else {
        this.form.mtp_nome = null;
      }
    },

    getPatientInfo(pValue) {
      const source = pValue?.source;

      if (source) {
        this.form.pcf_id = source.pcf_id;
      } else {
        this.form.pcf_id = null;
      }
    },

    async doSearchSubReport(pValue) {
      try {
        const variables = {
          ...this.form,
          mtp_id: Number(this.form.mtp_id),
          pcf_id: Number(this.form.pcf_id),
          loggedUserId: this.userLoginId,
        };
        const globalFilters = {
          ...this.globalFilters,
          initialDate: this.$utils.date.BrazilianDateToDatabase(
            this.globalFilters.initialDate,
          ),
          finalDate: this.$utils.date.BrazilianDateToDatabase(
            this.globalFilters.finalDate,
          ),
          pharmacy: Number(this.globalFilters.pharmacy),
          product: Number(this.globalFilters.product),
        };
        const filters = {
          ...globalFilters,
          ...variables,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_SATELLITE_SERVICE_HISTORY",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
  },
};
</script>
