import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFiltro: {
      ...variables,
    },
  };

  return AuthLegacyRequest.post(
    "faturamento/controller-atendimento/buscar-procedimentos-para-atendimento-nova-busca",
    sendData,
  )
    .then(({ data }) => {
      const result = data.dados.recordSet.map((item) => {
        return {
          ppd_id: item.ppd_id,
          sus_pa_id_dv: item.sus_pa_id_dv,
          fcs_codigo_simplificado: item.fcs_codigo_simplificado,
          sus_pa_dc: item.sus_pa_dc.toUpperCase(),
          ppd_cid10: item.ppd_cid10,
          ppd_classificacao: item.ppd_classificacao,
          ppd_quantidade: item.ppd_quantidade,
          ppd_servico: item.ppd_servico,
          tum_id_ocupacoes_cbo: item.tum_id_ocupacoes_cbo,
          nomeFuncionario: item.nomeFuncionario,
          ocp_nome: item.ocp_nome,
        };
      });

      return { rows: result, count: data.dados.total };
    })
    .catch((pErr) => {
      throw pErr;
    });
};
