<template>
  <FoundationSearchSchedules :module-type="3" :printHtml="printHtml" />
</template>

<script>
import FoundationSearchSchedules from "~tokio/foundation/pages/patient/foundation-search-schedules/FoundationSearchSchedules";

import PatientListHTML from "$hospitalization/hospitalizations/queue/html/search-patient-schedule/PatientListHTML.js";
import ScheduleHospitalizationHTML from "$hospitalization/hospitalizations/queue/html/ScheduleHospitalizationHTML";
import ScheduleVoucherHTML from "$hospitalization/hospitalizations/queue/html/search-patient-schedule/ScheduleVoucherHTML";

export default {
  name: "HospitalizationSearchPatientSchedule",
  components: {
    FoundationSearchSchedules,
  },

  data() {
    return {};
  },

  computed: {
    printHtml() {
      return {
        patientsList: (payload, row) => this.actPatientListHTML(payload, row),
        hospitalizationAuthorization: (payload, row) =>
          this.actHospitalizationAuthorization(payload, row),
        scheduleVoucher: (payload, row) =>
          this.actScheduleVoucherHTML(payload, row),
      };
    },
  },

  methods: {
    async actPatientListHTML(payload, row) {
      const snapPrint = await this.$store.dispatch(
        "Hospitalization/Queue/PRINT_PATIENT_SCHEDULE_LIST",
        payload,
      );
      const obj = {
        ...snapPrint,
        ...row,
      };
      return PatientListHTML(obj);
    },

    async actHospitalizationAuthorization(payload, row) {
      const snapPrint = await this.$store.dispatch(
        "Hospitalization/Queue/GET_PRINT_DATA_SCHEDULE_REGISTRATION",
        payload.intIdInternacao,
      );
      const obj = {
        ...snapPrint,
        ...row,
      };
      return ScheduleHospitalizationHTML(obj);
    },

    async actScheduleVoucherHTML(payload, row) {
      await this.$store.dispatch(
        "Hospitalization/Queue/REGISTER_SCHEDULE_VOUCHER_BATCH",
        { intIdInternacao: row.int_id },
      );

      const snapPrint = await this.$store.dispatch(
        "Hospitalization/Queue/GET_PRINT_SCHEDULE_BATCH",
        payload,
      );

      const obj = {
        ...snapPrint,
        ...row,
      };
      return ScheduleVoucherHTML(obj);
    },
  },
};
</script>
