<template>
  <section class="history-register-container">
    <div class="filter">
      <div class="subtitle-modal">
        <span class="text">Histórico do Cadastro</span>
      </div>

      <RgInput
        v-model="filter.searchData"
        label="Busca"
        placeholder="Busque aqui"
        class="input"
      />
      <div class="actions unselect">
        <RgCleanButton
          small
          data-id="limpar"
          class="clean"
          title="Limpar"
          @click="cleanInput"
        />
        <RgSearchButton
          ref="btnSubmit"
          v-shortkey="['enter']"
          type="button"
          data-id="buscar"
          class="search"
          title="Buscar"
          @shortkey.native="searchHistory"
          @submit="searchHistory"
        />
      </div>
    </div>

    <RgList
      ref="rgList"
      v-model="mutableHistoryList"
      :search-function="searchFilter"
      :build-filter="generateFilter"
      :show-total="false"
      :register-per-page="7"
      :max-register="7"
      class="rg-list"
    >
      <div class="history-register-container-list">
        <div class="header">
          <p>Data e Hora</p>
          <p>Unidade de Saúde</p>
          <p>Funcionário</p>
          <p>Ações</p>
        </div>

        <div
          v-for="(item, idx) in mutableHistoryList"
          :key="idx"
          :class="{ active: isSelectedRow(idx) }"
          class="body"
          @click="getItem(item, idx)"
        >
          <p>{{ moment(item.psl_data_log).format("DD/MM/YYYY HH:mm:ss") }}</p>
          <p>{{ item.uns_nome }}</p>
          <p>{{ item.usu_nome }}</p>
          <p>{{ item.tlp_nome }}</p>
        </div>
      </div>
    </RgList>
  </section>
</template>

<script>
import { RgInput } from "~tokio/primitive";
import RgList from "~tokio/foundation/rg-list/RgList";
import moment from "moment";
import { RgSearchButton, RgCleanButton } from "~tokio/primitive/button";

export default {
  name: "HistoryRegisterContainer",
  components: {
    RgInput,
    RgList,
    RgSearchButton,
    RgCleanButton,
  },
  data() {
    return {
      filter: {
        searchData: null,
      },
      mutableHistoryList: [],
      selectedRow: null,
      moment: moment,
    };
  },
  methods: {
    searchFilter(pData) {
      return this.$store.dispatch(
        "Person/Patient/GET_CUSTOM_HISTORY_PATIENT_LIST",
        { ...pData },
      );
    },
    generateFilter() {
      const pPesId = this.$store.getters["Person/Patient/GET_PERSON_ID"];
      const pSearchData = this.filter.searchData;
      return { pes_id: pPesId, searchData: pSearchData };
    },
    isSelectedRow(pIndex) {
      return this.selectedRow === pIndex;
    },
    getItem(pItem, pIndex) {
      this.selectedRow = pIndex;
      if (pItem) {
        this.preparePatientData(pItem);

        this.$emit("getLine", pItem);
      }
    },
    cleanSelectRow() {
      this.selectedRow = null;
    },
    async loadData() {
      await this.$refs.rgList.submitForm(true);
    },
    searchHistory() {
      const pFilter = this.generateFilter();

      this.searchFilter(pFilter);
      this.loadData();
      this.$refs.btnSubmit.actionDone();
    },
    preparePatientData(pPatienteData) {
      const noRefFields = this.$utils.obj.DeepCopy(pPatienteData);

      const fieldsAddress = noRefFields.address;
      const fieldsContact = this.prepareContact(noRefFields);
      const fieldsDocument = this.prepareDocuments(noRefFields);
      const patientRecordSplitted = this.splitPatientRecord(noRefFields);
      const fieldsPrepared = this.preparePersonalDetail(noRefFields);

      this.$store.commit("Person/Patient/SET_PERSONAL_DETAIL", fieldsPrepared);
      this.$store.commit("Person/Patient/SET_ADDRESS", fieldsAddress);
      this.$store.commit("Person/Patient/SET_CONTACT", fieldsContact);
      this.$store.commit("Person/Patient/SET_DOCUMENT", fieldsDocument);

      if (!this.$store.getters["Person/Patient/IS_NEWBORN"]) {
        this.$store.commit(
          "Person/Patient/SET_PATIENT_INFO",
          patientRecordSplitted,
        );
      }
    },
    prepareContact(pFields) {
      const fields = this.$utils.obj.DeepCopy(pFields);

      return {
        telephones: fields.telephones,
        emails: fields.pes_email,
      };
    },
    prepareDocuments(pFields) {
      const fields = this.$utils.obj.DeepCopy(pFields);

      fields.rg.rgs_expedicao = this.$utils.date.BrazilianDateFormat(
        fields.rg.rgs_expedicao,
      );
      fields.ctps.ctt_emissao = this.$utils.date.BrazilianDateFormat(
        fields.ctps.ctt_emissao,
      );
      fields.weddingCertificate.ctc_emissao = this.$utils.date.BrazilianDateFormat(
        fields.weddingCertificate.ctc_emissao,
      );
      fields.birthCertificate.ctc_emissao = this.$utils.date.BrazilianDateFormat(
        fields.birthCertificate.ctc_emissao,
      );

      return {
        cns: fields.cns,
        cpf: fields.cpf,
        rg: fields.rg,
        pisPasep: fields.pisPasep,
        otherDocuments: {
          cnh: fields.cnh,
          newBirthCertificate: fields.newBirthCertificate,
          ctps: fields.ctps,
          voterTitle: fields.voterTitle,
          weddingCertificate: fields.weddingCertificate,
          birthCertificate: fields.birthCertificate,
        },
      };
    },
    splitPatientRecord(pFields) {
      const fields = this.$utils.obj.DeepCopy(pFields);

      const unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];

      const haveRecordNumber =
        fields.patient && fields.patient.record_numbers.rows.length > 0;
      const recordNumber = haveRecordNumber
        ? fields.patient.record_numbers.rows.filter(
            (item) => item.ppr_id_unidades_saude === unitHealthId,
          )
        : [];

      const basicAttention = this.prepareBasicAttention(fields);

      const patientRecord = {
        pac_prontuario_unico: fields.patient
          ? fields.patient.pac_prontuario_unico
          : "",
        record_numbers: {
          ppr_numero:
            recordNumber.length > 0 ? recordNumber[0].ppr_numero : null,
          ppr_id_unidades_saude: unitHealthId,
        },
        ...basicAttention,
      };
      return patientRecord;
    },
    preparePersonalDetail(pFields) {
      const fields = this.$utils.obj.DeepCopy(pFields);

      delete fields.address;
      delete fields.telephones;
      delete fields.patient;
      delete fields.user;
      delete fields.cns;
      delete fields.rg;
      delete fields.cpf;
      delete fields.pisPasep;
      delete fields.voterTitle;
      delete fields.cnh;
      delete fields.newBirthCertificate;
      delete fields.weddingCertificate;
      delete fields.birthCertificate;
      delete fields.ctps;
      delete fields.pes_email;

      fields.pes_nascimento = this.$utils.date.BrazilianDateFormat(
        fields.pes_nascimento,
      );

      return fields;
    },
    prepareBasicAttention(pFields) {
      const fields = this.$utils.obj.DeepCopy(pFields);

      const basicAttention = fields.patient.basic_attention.rows;
      const hasBasicAttentionInfo =
        basicAttention.length && basicAttention.length > 0;

      if (!hasBasicAttentionInfo) return null;

      return basicAttention[0];
    },
    cleanInput() {
      this.filter.searchData = null;
      this.searchHistory();
      this.cleanSelectRow();
    },
  },
};
</script>
