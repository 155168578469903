<template>
  <div class="foundation-search-excludes-schedules">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :build-filter="generateFilter"
      :clearFunction="actionCleanForm"
      :max-register="40"
      :item-height="29.5"
      :search-function="actionSearchFilter"
      resultTitle="Lista de Pacientes Excluídos pela Fila"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="foundation-search-excludes-schedules-filter">
        <div class="grid">
          <div class="filter-title">
            <span class="title">Dados da Regulação</span>
          </div>

          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-origin"
              v-model="form.unitOrigem"
              :permission="getModuleType().permissionScreen"
              label="Unidade de Saúde"
              class="inputitem"
              default-text="Todas"
            />
          </div>

          <div class="selectinput">
            <RgSuggestUser
              id="user-exclude"
              ref="userExclude"
              v-model="form.userNameExclusion"
              label="Usuário da Exclusão"
              clearFunction
              placeholder="Digite o nome de usuário"
              @selected="selectingUserExclusion"
            />
          </div>

          <div class="selectinput">
            <RgSuggestUser
              id="user-origin"
              ref="userOrigin"
              v-model="form.userNameOrigin"
              label="Usuário de Origem"
              clearFunction
              placeholder="Digite o nome de usuário"
              @selected="selectingUserOrigin"
            />
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-date"
                v-model="form.initialDate"
                label="Data Inicial"
                class="date"
                pop-up="bottom"
                :rules="{ fn: validateDate }"
              />

              <RgInputDate
                id="final-date"
                v-model="form.finalDate"
                label="Data Final"
                class="date"
                pop-up="bottom"
                :rules="{ fn: validateDate }"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Dados da Solicitação </span>
          </div>

          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-solicitate"
              v-model="form.unitSolicitate"
              :permission="getModuleType().permissionScreen"
              label="Unidade de Saúde solicitante"
              class="inputitem"
              default-text="Todas"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              id="employee-solicitate"
              ref="employee"
              v-model="form.requestingProfessional"
              class="inputitem"
              label="Profissional solicitante"
              placeholder="Digite o profissional solicitante"
              @selected="selectingProfessional"
            />
          </div>
          <div class="selectinput">
            <RgSuggestTypeBed
              v-show="moduleType === TYPE_MODULE.HOSPITALIZATION"
              id="bed-type"
              ref="typeBed"
              v-model="form.bedType"
              class="inputitem"
              placeholder="Todos"
              @selected="selectingBedType"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente </span>
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              id="smart-person"
              ref="person"
              v-model="form.pes_nome"
              label="Paciente"
              :enabled-patient="false"
              :rules="{ forceSelection: true }"
              @selected="selectingPerson"
            />
          </div>
        </div>
      </div>

      <div class="foundation-search-excludes-schedules-search">
        <SmartTable
          ref="SmartTable"
          :name="getModuleType().smartTableName"
          :columns="getModuleType().columnSmartTable"
          class="smart-table"
          :dynamic-height="getSpaceTable"
          :initial-columns="5"
          toggle-selected
          v-bind="propsSmartTable"
          @getLine="selectRowData"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgLessButton
              id="delete-patient"
              :disabled="!selectedRow"
              @click="openModalBackPatientFromQueue"
            />
            <RgPrinterButton
              id="printer-patient"
              title="Lista de Pacientes Excluídos"
              @click="printExcludedPatient"
            />
            <RgDropdown
              id="options-patient"
              :item="selectedRow || {}"
              :disabled="!selectedRow"
              :action-options="itensActionOptionsPerson(selectedRow)"
              small
              show-down
              show-left
            >
              <IconCard slot="icon" />
            </RgDropdown>
          </div>
        </SmartTable>
      </div>
    </RgSearch>

    <ModalInformationPrint
      id="modal-information-print"
      :show="modalInformationPrint"
      :list-size="totalList"
      @close="closeModalInformationPrint"
    />

    <ModalObservationQueue
      id="modal-observation-queue"
      :show="modalObservationQueue"
      :patientData="selectedRow"
      :modId="getModuleType().moduleId"
      @close="closeModalObservationQueue"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalBackPatientFromQueue"
      @close="closeModalBackPatientFromQueue"
    />

    <ModalPatientImageInRow
      id="modal-patient-image-in-row"
      :show="modalPatientImageInRow"
      :fill-id="filId"
      :modId="getModuleType().moduleId"
      hideButtons
      view-only
      @close="closeModalPatientImageInRow"
    />

    <ModalPatientDuplicationQueue
      id="modal-patient-duplication-queue"
      :show="modalPatientDuplicationQueue"
      :module-id="getModuleType().moduleId"
      :patient-name="patientDuplicationName"
      :queue-info="queueDuplicationInfo"
      :block-duplication="blockPatientDuplication"
      @save="saveDuplication"
      @close="closeModalPatientDuplicationQueue"
      @cancel="closeModalPatientDuplicationQueue"
    />
  </div>
</template>

<script>
import ModalObservationQueue from "~tokio/primitive/modal/modal-observation-queue/ModalObservationQueue";

import moment from "moment";
import { RgInputDate, RgDropdown } from "~tokio/primitive";
import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson.vue";
import RgSuggestEmployee from "~tokio/primitive/suggest/rg-suggest-employee/RgSuggestEmployee.vue";
import RgSuggestUser from "~tokio/primitive/suggest/rg-suggest-user/RgSuggestUser.vue";
import { RgPrinterButton, RgLessButton } from "~tokio/primitive/button";
import { RgSelectUnithealth } from "~tokio/primitive/select";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import {
  ModalPatientImageInRow,
  ModalInformationPrint,
  ModalConfirmDeletion,
  ModalPatientDuplicationQueue,
} from "~tokio/primitive/modal";
import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";

const INITIAL_COLUMNS = 9;

const FORM_BASE = {
  unitOrigem: null,
  userName: "",
  usu_id_origin: null,
  usu_id_exclusion: null,
  initialDate: "",
  finalDate: "",
  unitSolicitate: null,
  requestingProfessional: "",
  pes_nome: "",
  pes_id: null,
  fun_id: null,
  vin_id: null,
  bedTypeId: null,
  bedType: null,
};

export default {
  name: "FoundationSearchExclusions",
  components: {
    RgSearch,
    SmartTable,
    RgSelectUnithealth,
    RgSuggestEmployee,
    RgInputDate,
    RgSuggestSmartPerson,
    RgSuggestUser,
    RgPrinterButton,
    RgDropdown,
    ModalPatientImageInRow,
    ModalInformationPrint,
    RgLessButton,
    ModalConfirmDeletion,
    ModalObservationQueue,
    ModalPatientDuplicationQueue,
    RgSuggestTypeBed,
  },

  props: {
    moduleType: {
      type: Number,
      default: 2,
    },
    printHtml: Object,
  },

  data() {
    return {
      mutableList: [],
      tableHeight: null,
      totalList: 0,
      selectedRow: null,
      modalPatientImageInRow: false,
      modalBackPatientFromQueue: false,
      modalInformationPrint: false,
      modalObservationQueue: false,
      requestingProfessional: null,
      filId: null,
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      modalPatientDuplicationQueue: false,
      patientDuplicationName: "",
      queueDuplicationInfo: "",
      blockPatientDuplication: false,
      reasonReturn: "",
      validateDuplication: true,
    };
  },
  computed: {
    getSpaceTable() {
      return this.tableHeight;
    },

    propsSmartTable() {
      const body = this.mutableList;
      const total = Number(this.totalList);
      const initialColumns = INITIAL_COLUMNS;
      const indexColumn = this.flg_id;
      const showPagination = false;

      return {
        body,
        total,
        initialColumns,
        indexColumn,
        showPagination,
      };
    },

    propsModalBackPatientFromQueue() {
      const title = "Retornar o Paciente para Fila";
      const subtitle =
        'Ao remover o paciente da lista "Excluídos pela Fila", o mesmo retornará para a "Fila de Regulação"';
      const bodyText = this.selectedRow && this.selectedRow.nomePaciente;
      const show = this.modalBackPatientFromQueue;
      const maxlength = 255;
      const confirm = (reason) => this.backPatientFromQueue(reason);

      return {
        show,
        confirm,
        title,
        subtitle,
        bodyText,
        maxlength,
      };
    },

    getUnitHealthName() {
      return this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
    },
  },

  created() {
    this.COLUMN_APPOINTMENT = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Exclusão", key: "flg_data" },
      { name: "Motivo da Exclusão", key: "flg_obs", align: "left" },
      { name: "Ocupação", key: "nomeSinonimosOcupacoes" },
      { name: "Usuário da Exclusão", key: "usuarioExclusao" },
      { name: "Unidade de Saúde Origem", key: "unidadeFila" },
      { name: "Usuário de Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
    ];

    this.COLUMN_EXAM = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Exclusão", key: "flg_data" },
      { name: "Motivo da Exclusão", key: "flg_obs", align: "left" },
      { name: "Procedimento", key: "nomeProcedimento" },
      { name: "Usuário da Exclusão", key: "usuarioExclusao" },
      { name: "Unidade de Saúde Origem", key: "unidadeFila" },
      { name: "Usuário de Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
    ];
    this.COLUMN_HOSPITALIZATION = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Exclusão", key: "flg_data" },
      { name: "Motivo da Exclusão", key: "flg_obs", align: "left" },
      { name: "Tipo de Leito", key: "nomeLeito", align: "left" },
      { name: "Usuário da Exclusão", key: "usuarioExclusao" },
      { name: "Unidade de Saúde Origem", key: "unidadeFila" },
      { name: "Usuário de Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
    ];
    this.TYPE_MODULE = {
      APPOINTMENT: 1,
      EXAM: 2,
      HOSPITALIZATION: 3,
    };
  },
  beforeDestroy() {
    const router = this.getModuleType().validateIfExistRoute;
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      `${router}`,
      this.$route.meta.breadcrumb,
    );
    if (!validateIfExist) {
      const router = this.getModuleType().actionCleanFormRoute;
      this.$store.commit(`${router}`);
    }
  },

  mounted() {
    this.fillFilter();
  },

  methods: {
    closeModalPatientDuplicationQueue() {
      this.modalPatientDuplicationQueue = false;
    },
    saveDuplication() {
      this.modalPatientDuplicationQueue = false;
      this.backPatientFromQueue(this.reasonReturn, false);
    },
    selectingProfessional(pProfessional) {
      const source =
        pProfessional &&
        pProfessional.source &&
        pProfessional.source.employeeRelationship;
      if (source) {
        const { pes_nome, vin_id } = pProfessional.source;
        const { fun_id } = pProfessional.source.employeeRelationship.employee;

        this.form.requestingProfessional = pes_nome;
        this.form.fun_id = fun_id;
        this.form.vin_id = vin_id;
      } else {
        this.form.requestingProfessional = "";
        this.form.fun_id = null;
      }
    },

    selectingPerson(pValue) {
      const hasPerson = pValue?.source?.pes_id > 0;
      if (hasPerson) {
        const { pes_id } = pValue.source;
        this.form.pes_id = pes_id;
      } else {
        this.form.pes_id = null;
        this.form.pes_nome = "";
      }
    },

    selectingUserOrigin(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id_origin = usu_id;
        this.form.userNameOrigin = usu_nome;
      } else {
        this.form.usu_id_origin = null;
        this.form.userNameOrigin = "";
      }
    },
    selectingUserExclusion(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id_exclusion = usu_id;
        this.form.userNameExclusion = usu_nome;
      } else {
        this.form.usu_id_exclusion = null;
        this.form.userNameExclusion = "";
      }
    },

    closeModalInformationPrint() {
      this.modalInformationPrint = false;
    },

    closeModalBackPatientFromQueue() {
      this.modalBackPatientFromQueue = false;
    },

    async printExcludedPatient() {
      try {
        this.$loader.start("Preparando impressão...");

        if (this.totalList > 1000) {
          this.modalInformationPrint = true;
          return;
        }

        this.$loader.start();
        const router = this.getModuleType().printExcludedPatientRoute;
        const path = `${router}`;

        const variables = {
          arrFormData: {
            uns_id: this.form.unitOrigem,
            smd_id: this.getModuleType().subModuleId,
            fil_id_agendado_por: this.form.usu_id_origin,
            periodoFilaInicial: this.form.initialDate,
            periodoFilaFinal: this.form.finalDate,
            uns_solicitante: this.form.unitSolicitate,
            fun_id_solicitante: this.form.requestingProfessional,
            pes_id: this.form.pes_id,
          },
          modulo: this.getModuleType().moduleId,
        };

        const data = await this.$store.dispatch(path, variables);

        const clientName = this.$store.getters["Login/GET_CLIENT_NAME"];
        const info = {
          cli_nome: clientName,
        };

        const html = await this.printHtml.patientsList(info, data);
        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      } finally {
        this.$loader.finish();
      }
    },

    async openModalBackPatientFromQueue() {
      this.modalBackPatientFromQueue = true;
    },
    actionsModalDuplicity(data) {
      const { exception } = data;
      const EXCEPTION_BLOCK_EXAME =
        "BloquearDuplicidadeAoInserirFilaExameException";
      const EXCEPTION_INFORM_EXAME =
        "InformarDuplicidadeAoInserirFilaExameException";
      const EXCEPTION_BLOCK_CONSULTA =
        "BloquearDuplicidadeAoInserirFilaConsultaException";
      const EXCEPTION_INFORM_CONSULTA =
        "InformarDuplicidadeAoInserirFilaConsultaException";
      const EXCEPTION_BLOCK_HOSPITALIZATION =
        "BloquearDuplicidadeAoInserirFilaInternacaoException";
      const EXCEPTION_INFORM_HOSPITALIZATION =
        "InformarDuplicidadeAoInserirFilaInternacaoException";

      const isExceptionDuplicityExam =
        exception === EXCEPTION_BLOCK_EXAME ||
        exception === EXCEPTION_INFORM_EXAME;
      const isExceptionDuplicityAppointment =
        exception === EXCEPTION_BLOCK_CONSULTA ||
        exception === EXCEPTION_INFORM_CONSULTA;
      const isExceptionDuplicityHospitalization =
        exception === EXCEPTION_BLOCK_HOSPITALIZATION ||
        exception === EXCEPTION_INFORM_HOSPITALIZATION;

      if (isExceptionDuplicityExam) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.nomePaciente;
        this.queueDuplicationInfo = this.selectedRow.nomeProcedimento;

        if (exception === EXCEPTION_INFORM_EXAME) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK_EXAME) {
          this.blockPatientDuplication = true;
        }
      }
      if (isExceptionDuplicityAppointment) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.nomePaciente;
        this.queueDuplicationInfo = this.selectedRow.nomeSinonimosOcupacoes;

        if (exception === EXCEPTION_BLOCK_CONSULTA) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_INFORM_CONSULTA) {
          this.blockPatientDuplication = true;
        }
      }
      if (isExceptionDuplicityHospitalization) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.nomePaciente;
        this.queueDuplicationInfo = this.selectedRow.nomeLeito;

        if (exception === EXCEPTION_INFORM_HOSPITALIZATION) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK_HOSPITALIZATION) {
          this.blockPatientDuplication = true;
        }
      }
    },

    async backPatientFromQueue(pReason, validarDuplicidade = true) {
      try {
        this.$loader.start();
        this.reasonReturn = pReason;
        const router = this.getModuleType().backPatientFromQueueRoute;
        const path = `${router}`;
        let variables = {
          strMotivo: pReason,
          validarDuplicidade: validarDuplicidade,
        };

        switch (this.moduleType) {
          case this.TYPE_MODULE.APPOINTMENT:
            variables = {
              idFilaConsulta: Number(this.selectedRow.fil_id),
              ...variables,
            };
            break;
          case this.TYPE_MODULE.EXAM:
            variables = {
              idFilaExame: Number(this.selectedRow.fil_id),
              ...variables,
            };
            break;
          case this.TYPE_MODULE.HOSPITALIZATION:
            variables = {
              idFilaInternacao: Number(this.selectedRow.fil_id),
              ...variables,
            };
            break;
        }

        this.validateDuplication = validarDuplicidade;

        await this.$store.dispatch(path, variables);

        this.$toaster.success("Operação realizada com sucesso");
        this.reasonReturn = "";
        this.reSearchTable();
      } catch (pErr) {
        const { esus_response } = pErr;

        if (esus_response?.hasError) {
          if (this.validateDuplication) {
            this.actionsModalDuplicity(esus_response);
            if (this.modalPatientDuplicationQueue) return;
          }

          this.$toaster.error(
            "Não foi possível realizar operação: ",
            esus_response.message,
          );

          return;
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Não foi possível realizar operação",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },

    itensActionOptionsPerson(row) {
      return [
        {
          label: "Arquivos do paciente na fila",
          action: this.openModalPatientImageInRow,
        },
        {
          label: "Observações do paciente na fila",
          action: this.openModalObservationQueue,
        },
      ];
    },

    openModalObservationQueue() {
      this.selectedRow.uns_nome = this.getUnitHealthName;
      this.modalObservationQueue = true;
    },

    closeModalObservationQueue() {
      this.modalObservationQueue = false;
    },

    selectRowData(row) {
      this.selectedRow = row;
      this.filId = Number(this.selectedRow?.fil_id);
    },

    actionAfterSearchFilter(submitFromButton) {
      if (this.$refs.SmartTable) {
        this.$refs.SmartTable.cleanSelectRow();
        this.$refs.SmartTable.setScrollTopZero();
      }
      this.selectedRow = null;

      // GET DYNAMIC HEIGHT
      if (this.$refs.RgSearch.$refs.listContent) {
        this.tableHeight =
          this.$refs.RgSearch.$refs.listContent.offsetHeight - 35;
      }
    },

    async fillFilter() {
      try {
        const router = this.getModuleType().fillFilterRouter;
        const existFilterData = this.$store.getters[`${router}`];

        if (existFilterData) {
          this.$loader.start("Carregando dados da ultima busca");

          if (this.$refs.employee) {
            this.$refs.employee.forceSelection({
              pes_nome: existFilterData.requestingProfessional,
              vin_id: existFilterData.vin_id,
              fun_id: existFilterData.fun_id,
            });
          }

          if (this.$refs.userOrigin) {
            this.$refs.userOrigin.forceSelection({
              usu_nome: existFilterData.userNameOrigin,
              usu_id: existFilterData.usu_id_origin,
            });
          }
          if (this.$refs.userExclude) {
            this.$refs.userExclude.forceSelection({
              usu_nome: existFilterData.userNameExclusion,
              usu_id: existFilterData.usu_id_exclusion,
            });
          }
          if (this.$refs.typeBed) {
            this.$refs.typeBed.forceSelection({
              itl_nome: existFilterData.bedType,
              itl_id: existFilterData.bedTypeId,
            });
          }

          if (this.$refs.person && existFilterData.pes_id) {
            this.$refs.person.fillPatientById(existFilterData.pes_id);
          }

          this.form = existFilterData;

          if (this.$refs.SmartTable) {
            this.$refs.SmartTable.cleanSelectRow();
          }
          await this.$refs.RgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    reSearchTable() {
      this.$refs.RgSearch.submitForm(false, true);
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          fil_comprovante_impresso: null,
          fil_id_agendado_por: this.form.usu_id_origin,
          fil_id_excluido_por: this.form.usu_id_exclusion,
          fun_id: 0,
          fun_id_solicitante: this.form.fun_id,
          lca_id: 0,
          ocp_id: 0,
          pes_id: this.form.pes_id,
          periodoFilaInicial: this.form.initialDate,
          periodoFilaFinal: this.form.finalDate,
          periodoFinal: "",
          periodoInicial: "",
          pfi_id: 0,
          set_id: 0,
          smd_id: this.getModuleType().subModuleId,
          uns_id: this.form.unitOrigem,
          uns_id_destino: 0,
          uns_solicitante: this.form.unitSolicitate,
          usu_id_agendamento: 0,
          itl_id: this.form.bedTypeId,
        },
      };

      const router = this.getModuleType().generateFilterRoute;

      this.$store.commit(`${router}`, this.form);

      return variables;
    },

    async actionSearchFilter(pData) {
      try {
        this.$loader.start();

        const router = this.getModuleType().actionSearchFilterRoute;
        const path = `${router}`;

        const data = await this.$store.dispatch(path, pData);
        return data;
      } catch (e) {
        this.$toaster.error("Erro ao buscar os pacientes");
      } finally {
        this.$loader.finish();
      }
    },

    getCountValue(pValue) {
      this.totalList = Number(pValue) || 0;
    },

    openModalPatientImageInRow() {
      this.modalPatientImageInRow = true;
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    actionCleanForm() {
      this.mutableList = [];
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);
      if (this.$refs.SmartTable) {
        this.$refs.SmartTable.cleanSelectRow();
      }
      this.selectedRow = null;
      this.filId = null;
      const router = this.getModuleType().actionCleanFormRoute;
      this.$store.commit(`${router}`);
    },

    validateDate(value, errors) {
      if (this.form.initialDate && this.form.finalDate) {
        const initial = moment(this.form.initialDate, "DD/MM/YYYY");
        const final = moment(this.form.finalDate, "DD/MM/YYYY");

        const isValidInitialValidity = moment(initial).isSameOrBefore(final);
        if (!isValidInitialValidity) {
          errors.push("A data inicial não pode ser maior que a data final");
          return false;
        }
      }
      return true;
    },

    selectingBedType(pBedType) {
      const source = pBedType && pBedType.source;
      if (source) {
        this.form.bedTypeId = source.itl_id;
      } else {
        this.form.bedTypeId = null;
      }
    },

    getModuleType() {
      switch (this.moduleType) {
        case this.TYPE_MODULE.APPOINTMENT:
          return {
            smartTableName: "AppointmentFoundationExternallySchedule",
            columnSmartTable: this.COLUMN_APPOINTMENT,
            permissionSuggest: ["appointment"],
            moduleId: this.$store.state.Login.route_module_map.appointment,
            subModuleId: 246,
            validateIfExistRoute: "/appointment/schedule/patient-exclude",
            actionSearchFilterRoute:
              "Appointment/Schedule/SEARCH_PATIENT_EXCLUDE_IN_QUEUE",
            actionCleanFormRoute:
              "Appointment/Schedule/RESET_FILTER_PATIENT_EXCLUDE",
            printExcludedPatientRoute:
              "Appointment/Schedule/GET_PRINT_PATIENT_EXCLUDE",
            backPatientFromQueueRoute:
              "Appointment/Schedule/BACK_PATIENT_FROM_QUEUE",
            fillFilterRouter: "Appointment/Schedule/GET_FILTER_PATIENT_EXCLUDE",
            generateFilterRoute:
              "Appointment/Schedule/SET_FILTER_PATIENT_EXCLUDE",
            permissionScreen: "consulta.pacientesExcluidosFilaConsulta",
          };
        case this.TYPE_MODULE.EXAM:
          return {
            smartTableName: "ExamFoundationExternallySchedule",
            columnSmartTable: this.COLUMN_EXAM,
            permissionSuggest: ["exame2"],
            moduleId: this.$store.state.Login.route_module_map.exam,
            subModuleId: 245,
            validateIfExistRoute: "/exam/schedule/patient-exclude",
            actionSearchFilterRoute:
              "Exam/Schedule/SEARCH_PATIENT_EXCLUDE_IN_QUEUE",
            actionCleanFormRoute: "Exam/Schedule/RESET_FILTER_PATIENT_EXCLUDE",
            printExcludedPatientRoute:
              "Exam/Schedule/GET_PRINT_PATIENT_EXCLUDE",
            backPatientFromQueueRoute: "Exam/Schedule/BACK_PATIENT_FROM_QUEUE",
            fillFilterRouter: "Exam/Schedule/GET_FILTER_PATIENT_EXCLUDE",
            generateFilterRoute: "Exam/Schedule/SET_FILTER_PATIENT_EXCLUDE",
            permissionScreen: "exame2.pacientesExcluidosFilaExame",
          };
        case this.TYPE_MODULE.HOSPITALIZATION:
          return {
            smartTableName: "HospitalizationFoundationExternallySchedule",
            columnSmartTable: this.COLUMN_HOSPITALIZATION,
            permissionSuggest: ["hospitalization"],
            moduleId: this.$store.state.Login.route_module_map.hospitalization,
            subModuleId: 247,
            validateIfExistRoute: "/hospitalization/queue/patient-exclude",
            actionSearchFilterRoute:
              "Hospitalization/Queue/SEARCH_PATIENT_EXCLUDE_IN_QUEUE",
            actionCleanFormRoute:
              "Hospitalization/Queue/RESET_FILTER_PATIENT_EXCLUDE",
            printExcludedPatientRoute:
              "Hospitalization/Queue/GET_PRINT_PATIENT_EXCLUDE",
            backPatientFromQueueRoute:
              "Hospitalization/Queue/BACK_PATIENT_FROM_QUEUE",
            fillFilterRouter:
              "Hospitalization/Queue/GET_FILTER_PATIENT_EXCLUDE",
            generateFilterRoute:
              "Hospitalization/Queue/SET_FILTER_PATIENT_EXCLUDE",
            permissionScreen: "internacao.pacientesExcluidosFilaInternacao",
          };
      }
    },
  },
};
</script>
