export default {
  name: "RgButtonMixin",
  props: {
    id: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: true,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
