<template>
  <div v-if="show">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="modal-attachments"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">{{ title }}</h1>
      </div>

      <div slot="body" class="body">
        <FormBase title="Arquivos">
          <div class="full-area">
            <div class="half-area">
              <RgComboboxDocumentAttachmentType
                v-model="documentType"
                label="Tipo de Documento"
                @change="selectDocumentType"
              />

              <div>
                <input
                  ref="file"
                  class="input-file"
                  type="file"
                  :accept="acceptTypes"
                  @change="uploadFile"
                />
                <label class="input-label" @click="openFileDialog">
                  <RgInput
                    disabled
                    label="Enviar Novo Arquivo"
                    :placeholder="drawInformationMessage()"
                    :legend="'É permitido arquivos de até 3MB'"
                  />
                </label>
              </div>

              <RgAddButton
                class="new"
                type="button"
                :disabled="!documentType || !file"
                @click="confirmUpload"
              />
            </div>

            <div class="file-list">
              <span v-if="files && files.length > 0">Arquivos Enviados</span>
              <div v-if="files && files.length > 0" class="top-btns">
                <RgLessButton
                  :disabled="!hasSelectedDocument"
                  title="Remover arquivo"
                  @click="removeFile(selectedData)"
                />
                <a
                  :disabled="!hasSelectedDocument"
                  :class="{ disable: !hasSelectedDocument }"
                  class="download"
                  title="Baixar"
                  @click="actionDownload"
                >
                  <IconArrowDownload class="svg" />
                </a>
                <a
                  :href="selectedData !== null ? selectedData.url : null"
                  :disabled="!hasSelectedDocument"
                  :class="{ disable: !hasSelectedDocument }"
                  target="_blank"
                  class="see"
                  title="Visualizar"
                >
                  <IconShow />
                </a>
              </div>
              <div v-if="files && files.length > 0">
                <RgTable :columns="COLUMNS" class="rg-table">
                  <tr
                    v-for="(item, index) in files"
                    :key="index"
                    slot="rows"
                    class="tr"
                    :class="{ selected: index === activeRow }"
                    @click="selectLine(item, index)"
                  >
                    <td>
                      {{ item.filename || item.name }}
                    </td>
                    <td>
                      <strong>
                        {{ item.document_attachment.doa_nome }}
                      </strong>
                    </td>
                  </tr>
                </RgTable>
              </div>
              <div v-else class="empty-result">
                <IconEmpty />
                <span>Não foram encontrados arquivos</span>
              </div>
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton medium type="button" @click="close" />
        <RgConfirmButton medium type="button" @click="confirm" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgTable from "~tokio/foundation/rg-table/RgTable";

import { AlertError } from "~tokio/primitive/notification";
import SendFile from "~tokio/primitive/modal/modal-upload/actions/SendFile";

import {
  RgBaseModal,
  RgAddButton,
  RgConfirmButton,
  IconArrowDownload,
  RgInput,
  RgCancelButton,
  RgLessButton,
  IconEmpty,
} from "~tokio/primitive";
import { IconShow } from "~tokio/primitive/icon/symbols";

import RgComboboxDocumentAttachmentType from "$person/common/components/combobox/rg-combobox-document-attachment-type/RgComboboxDocumentAttachmentType";
import RemoveUploadedFile from "./actions/RemoveUploadedFile";
import DownloadByUrl from "@/common/utils/DownloadByUrl";
import { DeepCopy } from "@/common/utils/object";
import { mapGetters } from "vuex";

export default {
  name: "ModalAttachments",
  components: {
    FormBase,
    RgTable,
    RgBaseModal,
    IconShow,
    IconArrowDownload,
    RgAddButton,
    RgConfirmButton,
    RgComboboxDocumentAttachmentType,
    RgInput,
    RgCancelButton,
    RgLessButton,
    IconEmpty,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Anexos do Paciente",
    },
    accept: {
      type: Array,
      default: () => {
        return ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
      },
    },
    maxSizeInKB: {
      type: Number,
      default: 3145728,
    },
  },

  data() {
    return {
      tempFiles: [],
      selectedIndex: null,
      selectedData: null,
      documentType: null,
      selectedDocumentType: null,
      activeRow: null,
      file: null,
    };
  },

  computed: {
    ...mapGetters({
      existsPesId: "Person/Patient/EXISTS_PES_ID",
      pesId: "Person/Patient/GET_PERSON_ID",
      attachments: "Person/Patient/GET_ATTACHMENTS",
    }),
    files: {
      set(attachments, tempFiles) {
        attachments = this.attachments;
        tempFiles = this.tempFiles;
      },
      get() {
        return [].concat(this.attachments).concat(this.tempFiles);
      },
    },
    hasFiles() {
      return this.files.length > 0;
    },
    hasSelectedDocument() {
      return this.selectedData && Object.keys(this.selectedData).length > 0;
    },
    existFile() {
      return this.file !== null;
    },
    acceptTypes() {
      return this.accept.join(", ");
    },
  },

  watch: {
    files: function () {
      this.$emit("totalChanged", this.files.length);
    },
    documentType(pValue) {
      if (!pValue) {
        this.file = null;
        this.$refs.file.value = null;
      }
    },
  },

  created() {
    this.COLUMNS = [{ name: "Documento" }, { name: "Tipo" }];
  },

  methods: {
    selectLine(pItem, pIndex) {
      const isSameLine = this.selectedData === pItem;

      if (isSameLine) {
        this.activeRow = null;
        this.selectedData = {};
        this.selectedIndex = pIndex;
      } else {
        this.activeRow = pIndex;
        this.selectedData = pItem;
        this.selectedIndex = null;
      }
    },

    actionDownload() {
      const url = this.selectedData !== null ? this.selectedData.url : null;
      const name = `${this.selectedData.document_attachment.doa_nome}_${this.selectedData.filename}`;
      DownloadByUrl(url, name);
    },

    selectDocumentType(pDocType) {
      if (pDocType) {
        this.selectedDocumentType = pDocType[0];
      } else {
        this.selectedDocumentType = null;
      }
    },

    openFileDialog() {
      if (!this.documentType || !this.selectedDocumentType) {
        return this.$toaster.warning(
          "É preciso selecionar o tipo de documento antes de envia-lo",
        );
      }
      this.$refs.file.click();
    },

    cleanSelectionDocumentType() {
      this.selectedDocumentType = null;
      this.documentType = null;
    },

    confirm() {
      this.activeRow = null;
      this.selectedIndex = null;
      this.selectedData = null;
      this.documentType = null;

      // // save files on backend
      if (this.tempFiles.length <= 0) {
        this.$toaster.warning("Nenhum arquivo adicionado");
      }

      // emitir evento para tela principal, com tempFiles, juntando nomes dos arquivos e seus tipos
      this.$emit("confirmed", DeepCopy(this.tempFiles), this.files.length);
      this.$emit("close");
    },

    removeFile(selectedFile) {
      RemoveUploadedFile(selectedFile.url_remove)
        .then(() => {
          this.$toaster.success(`Arquivo {${selectedFile.filename}} removido`);
        })
        .catch((pErr) => {
          this.$toaster.error("Falha ao remover o arquivo:" + pErr.message);
        })
        .finally(() => {
          // remove from both if exists
          this.tempFiles = this.tempFiles.filter((item) => {
            return item.filename !== selectedFile.filename;
          });
          // TODO call mutation to update or reload attachments on person
          this.$store.commit("Person/Patient/REMOVE_ATTACHMENT", selectedFile);
          this.selectedIndex = null;
          this.selectedData = null;
          this.cleanSelectionDocumentType();
        });
    },

    close() {
      if (this.tempFiles.length > 0) {
        const proceed = confirm(
          "Deseja confirmar os anexos que foram enviados nessa tela?",
        );

        if (proceed) {
          return this.confirm();
        }

        // tentar remover arquivos temporarios que nao foram confirmados
        this.tempFiles.forEach((item) => {
          this.removeFile(item);
        });

        return this.confirm();
      } else {
        this.$emit("close");
      }

      this.activeRow = null;
      this.selectedIndex = null;
      this.selectedData = null;
      this.documentType = null;
      this.selectedDocumentType = null;
      this.file = null;
      this.files = null;
      this.tempFiles = [];
      this.$refs.file.value = null;
    },

    getUploadedFileInfo(pValue) {
      const info = {
        ...pValue,
        document_attachment: {
          doa_id: this.selectedDocumentType.value,
          doa_nome: this.selectedDocumentType.text,
        },
      };
      this.cleanSelectionDocumentType();
      this.tempFiles = this.tempFiles.concat(info);
    },

    drawInformationMessage() {
      return this.existFile
        ? "Arquivo inserido"
        : "Selecione um documento do computador";
    },

    confirmUpload() {
      if (this.existFile) {
        this.$loader.start("Enviando Arquivo...");

        SendFile(this.file)
          .then((result) => {
            this.$loader.finish();
            this.getUploadedFileInfo(result.data.fileInfo);
          })
          .catch((err) => {
            this.file = null;
            this.$loader.finish();
            this.$toaster.error("Falha ao enviar o arquivo:" + err.message);
          });

        this.file = null;
      } else {
        AlertError("Adicione um arquivo.");
      }
    },

    validateFile(pFile) {
      const hasFile = pFile !== null;

      if (!hasFile) {
        AlertError("Adicione um arquivo");
        return false;
      }

      const hasInvalidSize = pFile.size > this.maxSizeInKB;

      if (hasInvalidSize) {
        AlertError("Arquivo com tamanho maior que o esperado");
        return false;
      }

      const fileType = pFile.type;
      const hasInvalidTypes = !this.accept.includes(fileType);

      if (hasInvalidTypes) {
        AlertError(`Formato inválido. Permitidos: ${this.acceptTypes}`);
        return false;
      }

      return true;
    },

    uploadFile(pFile) {
      const fileInUpload = this.$refs.file.files[0];

      const isValid = this.validateFile(fileInUpload);

      if (isValid) {
        this.file = fileInUpload;
      } else {
        this.$refs.file.value = null;
      }
    },
  },
};
</script>
