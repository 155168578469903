<template>
  <section class="list-type-scale">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      v-bind="propsRgSearch"
      :disabled="disabledByModal"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <!-- Filtros -->
      <div slot="filters" class="list-type-scale-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealthLegacy
              id="unit-health"
              v-model="form.selectedUnity"
              :rules="{ required: true }"
              :subModuleId="subModuleId"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSelectSectorBondAndPermission
              id="sector"
              v-model="form.selectedSector"
              :unit-health-id="form.selectedUnity"
              :submodule-id="subModuleId"
              :disabled="disableSector"
              default-text="Todos"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCboSector
              id="occupation"
              ref="occupation"
              v-model="form.selectedCbo"
              :disabled="disableCbo"
              :class="{ disable: disableCbo }"
              :sectorId="Number(form.selectedSector)"
              label="Ocupação"
              placeholder="Digite o nome da ocupação"
              class="inputitem"
              active-professional
              @selected="selectingOccupation"
            />
          </div>

          <div class="selectinput">
            <RgSelectEmployeeBySectorOccupationLegacy
              id="employee"
              ref="employee"
              v-model="form.selectedEmployee"
              :sector-id="Number(form.selectedSector)"
              :occupation-id="Number(form.selectedCboId)"
              :disabled="disableEmployee"
              :class="{ disable: disableEmployee }"
              placeholder="Digite o nome do profissional"
              class="inputitem"
              @change="selectedEmployee"
            />
          </div>

          <div class="filter-title">
            <span class="title">Período da Escala</span>
          </div>

          <div class="selectinput">
            <RgComboboxWeekDays
              id="week-days"
              v-model="form.selectedWeekDays"
              default-text="Todos"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxTypeScale
              id="type-scale"
              v-model="form.selectedTypeScale"
              label="Tipo de Escala"
              default-text="Todas"
              :uns_id="Number(form.selectedUnity)"
              :usu_id="Number(usu_id)"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <RgComboboxTypeTime
              id="type-time"
              v-model="form.selectedTypeTime"
              label="Tipo de Horário da Escala"
              default-text="Todos"
              :unitHealthId="Number(form.selectedUnity)"
              :userId="Number(usu_id)"
              class="inputitem"
            />
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputHour
                id="start-hour"
                v-model="form.periodHourStart"
                label="Hora Inicial"
                placeholder="hh:mm"
                class="inputitem"
              />
              <RgInputHour
                id="end-hour"
                v-model="form.periodHourEnd"
                label="Hora Final"
                placeholder="hh:mm"
                class="inputitem"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title">Validade</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="start-date"
                v-model="form.valDateStart"
                class="inputitem"
                label="Data Inicial"
              />
              <RgInputDate
                id="end-date"
                v-model="form.valDateEnd"
                label="Data Final"
                :rules="{ fn: validateDateEnd }"
                class="inputitem"
              />
            </div>
          </div>

          <div class="selectinput">
            <RgRadioCustom
              id="validations"
              v-bind="propsRadioCustom"
              @input="onInputRadioCustom"
            />
          </div>
        </div>
      </div>

      <div v-if="!hasListScale" slot="menu-top" class="top-button">
        <RgAddButton
          id="new-scale"
          large
          label="Novo"
          title="Nova Escala"
          @click="clickNewServiceScale"
        />
      </div>

      <!-- Result da busca -->
      <div class="list-type-scale-search">
        <GridServiceScale
          ref="ListServiceScale"
          :dynamic-height="getSpaceTable"
          :body="mutableList"
          :total="Number(getCountValue)"
          :show-pagination="false"
          @openModalDeleteScale="openModalDeleteScale"
          @openModalHistoryScales="openModalHistoryScales"
          @selectedRow="setSelectedRow"
        />
      </div>

      <div
        v-if="hasListScale"
        ref="footerSlot"
        slot="menu-bottom"
        class="footer-table"
      >
        <RgInputDate
          id="divide-date"
          v-model="splitDate"
          label="Data inicial da divisão: "
          inLine
          :disabled="!selectedRow || disabledByModal"
        />
        <SmallButton
          id="divide"
          backgroundColor="#26789f"
          class="button"
          title="Dividir"
          :disabled="!selectedRow"
          :class="{ disabled: !selectedRow }"
          @click="openModalSplitScale"
        >
          <div slot="icon" class="icon"><IconSplit /></div>
        </SmallButton>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDelete"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalHistoryProfessionalScales
      id="modal-history-scales"
      :show="modalHistoryScales"
      :sector-link-id="sectorLinkId"
      :professional-info="professionalInfo"
      @close="closeModalHistoryScales"
    />

    <ModalConfirmDefault
      id="modal-confirm"
      v-bind="propsModalConfirmDefault"
      @getYes="divisionScale"
      @getOut="clickCloseModalSplashScale"
      @close="clickCloseModalSplashScale"
    >
      <div slot="body-content" class="content-modal-confirm">
        <div>
          Escala 1: de {{ initialDateFirstScale }} a {{ finalDateFirstScale }}
        </div>
        <div>
          Escala 2: de {{ initialDateSecondScale }} a
          {{ finalDateSecondScale }}
        </div>
      </div>
    </ModalConfirmDefault>
  </section>
</template>

<script>
import {
  RgSelectUnithealthLegacy,
  RgComboboxWeekDays,
  RgSelectSectorBondAndPermission,
  RgInputHour,
  RgInputDate,
  RgRadioCustom,
  RgSuggestCboSector,
  RgAddButton,
  SmallButton,
  RgSelectEmployeeBySectorOccupationLegacy,
} from "~tokio/primitive";

import GridServiceScale from "../../common/grid/service-scale/GridServiceScale";
import ModalHistoryProfessionalScales from "$exam/submodules/register/common/modal/modal-history-professional-scales/ModalHistoryProfessionalScales";

import {
  ModalConfirmDeletion,
  ModalConfirmDefault,
} from "~tokio/primitive/modal";
import { IconDanger, IconSplit } from "~tokio/primitive/icon/symbols";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import RgComboboxTypeScale from "../../common/combobox/rg-combobox-type-scale/RgComboboxTypeScale";
import RgComboboxTypeTime from "$exam/submodules/register/common/combobox/rg-combobox-type-time/RgComboboxTypeTime";

import moment from "moment";

import { mapGetters } from "vuex";

const FILTER_FORM = {
  selectedUnity: null,
  selectedUnityId: 0,
  selectedSector: null,
  selectedCbo: null,
  selectedCboId: 0,
  selectedCboName: null,
  selectedCboCode: null,
  selectedEmployee: null,
  selectedEmployeeVusId: null,
  selectedEmployeeName: null,
  selectedWeekDays: null,
  selectedTypeScale: null,
  selectedTypeTime: null,
  periodHourStart: null,
  periodHourEnd: null,
  valDateStart: null,
  valDateEnd: null,
};

export default {
  name: "ListTypeScale",
  components: {
    RgSearch,
    RgSelectUnithealthLegacy,
    RgComboboxWeekDays,
    RgInputHour,
    RgInputDate,
    RgRadioCustom,
    RgComboboxTypeScale,
    RgSelectSectorBondAndPermission,
    GridServiceScale,
    ModalConfirmDeletion,
    ModalHistoryProfessionalScales,
    IconDanger,
    SmallButton,
    IconSplit,
    RgSuggestCboSector,
    RgAddButton,
    RgSelectEmployeeBySectorOccupationLegacy,
    RgComboboxTypeTime,
    ModalConfirmDefault,
  },

  data() {
    return {
      mutableList: null,
      total: 0,
      form: this.$utils.obj.DeepCopy(FILTER_FORM),
      searchOffset: 0,
      tableHeight: 0,
      selectedRow: null,
      sectorLinkId: 0,
      professionalInfo: {},
      modalDeleteShow: false,
      modalHistoryScales: false,
      modalSplashScale: false,
      usu_id: null,
      splitDate: null,
      textSplitPatOne: null,
      textSplitPatTwo: null,
    };
  },

  computed: {
    disabledByModal() {
      return (
        this.modalDeleteShow || this.modalHistoryScales || this.modalSplashScale
      );
    },

    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
    }),

    getSpaceTable() {
      return this.tableHeight;
    },

    propsModalConfirmDefault() {
      const show = this.modalSplashScale;
      const title = "Divisão da escala selecionada";
      const subtitle = "O período selecionado será dividido em duas escalas";
      const yesLabel = "Confirmar";
      const noLabel = "Cancelar";
      const buttonsRight = true;

      return {
        show,
        title,
        subtitle,
        yesLabel,
        noLabel,
        buttonsRight,
      };
    },

    propsModalConfirmDeletion() {
      const show = this.modalDeleteShow;
      const confirm = () => this.actionRemoveItem();

      const noReason = true;
      const title = "Excluir a escala selecionada";
      const message = "A operação não poderá ser desfeita";
      const msgSuccess = "Escala excluída com sucesso!";
      const btnRemoveTitle = "Excluir Escala";

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },

    propsRadioCustom() {
      const list = [
        { title: "Na validade", color: "#03CE63", id: 1 },
        { title: "Fora da validade", color: "#F96B70", id: 2 },
      ];
      const value = this.form.tipoEscala;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },

    propsRgSearch() {
      return {
        buildFilter: () => this.generateFilter(),
        searchFunction: (data) => this.actionSearchFilter(data),
        clearFunction: () => this.actionClearForm(),
        itemHeight: 34.5,
        maxRegister: 40,
        resultTitle: "Lista das Escalas",
        backendLegacy: true,
      };
    },

    disableSector() {
      return this.form.selectedUnity <= 0;
    },

    disableCbo() {
      return this.form.selectedSector <= 0;
    },

    disableEmployee() {
      return this.form.selectedCbo <= 0;
    },

    hasListScale() {
      return this.mutableList?.length > 0;
    },

    initialDateFirstScale() {
      return this.selectedRow?.eea_data_inicio_escala;
    },

    finalDateFirstScale() {
      if (!this.selectedRow) return;

      const { eea_data_inicio_escala } = this.selectedRow;

      return eea_data_inicio_escala === this.splitDate
        ? eea_data_inicio_escala
        : this.splitDate;
    },

    initialDateSecondScale() {
      return moment(this.finalDateFirstScale, "DD/MM/YYYY")
        .add(1, "days")
        .format("DD/MM/YYYY");
    },

    finalDateSecondScale() {
      return this.selectedRow?.eea_data_fim_escala;
    },

    subModuleId() {
      const SUB_MODULE_EXAM_SCALE_ID = 116;
      return SUB_MODULE_EXAM_SCALE_ID;
    },
  },

  watch: {
    "form.selectedUnity"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.selectedSector = null;
      }
    },
    "form.selectedSector"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.selectedCbo = null;
      }
    },
    "form.selectedCbo"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.selectedEmployee = null;
      }
    },
  },

  destroyed() {
    this.cleanVuexFilter();
  },

  async mounted() {
    this.form.selectedUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.usu_id = this.userLogin.user.usu_id;

    this.getVuexFilter();
  },

  methods: {
    generateFilter() {
      const variables = {
        arrFormData: {
          uns_id: this.form.selectedUnity,
          set_id: this.form.selectedSector,
          ocp_id: this.form.selectedCboId || null,
          pes_id: this.form.selectedEmployee || null,
          dsm_id: this.form.selectedWeekDays,
          ees_id: this.form.selectedTypeScale,
          eti_id: this.form.selectedTypeTime,
          eea_inicio_atendimentos: this.form.periodHourStart,
          eea_final_atendimentos: this.form.periodHourEnd,
          eea_data_inicio_escala: this.form.valDateStart,
          eea_data_fim_escala: this.form.valDateEnd,
          tipoEscala: this.form.tipoEscala,
          smd_id: this.subModuleId,
        },
      };
      this.setVuexFilter(this.form);
      return variables;
    },

    async actionSearchFilter(data) {
      try {
        const path = "Exam/Register/LIST_SERVICE_SCALE_SEARCH";
        const snap = await this.$store.dispatch(path, data);
        return snap;
      } catch (e) {
        this.$toaster.warning(e);
      }
    },

    async actionClearForm() {
      this.form = this.$utils.obj.DeepCopy(FILTER_FORM);
      this.form.selectedUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.mutableList = null;
      this.resetFilterVuex();
      this.cleanForm();
    },

    async afterSearchFilter() {
      this.getDynamicHeight();
      this.cleanForm();
    },

    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.RgSearch?.$refs.listContent) {
          const footerSlot =
            this.$refs.footerSlot && this.$refs.footerSlot.offsetHeight;
          const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;

          this.tableHeight = searchArea - footerSlot - 15;

          if (this.$refs.gridServiceScale)
            this.$refs.gridServiceScale.cleanSelectRow();
        }
      });
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    onInputRadioCustom(item) {
      if (item) {
        this.form.tipoEscala = item.id;
      } else {
        this.form.tipoEscala = null;
      }
    },

    selectingOccupation(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;

      if (hasSuggestion) {
        this.form.selectedCboName = pValue.source.ocp_nome;
        this.form.selectedCboId = pValue.source.ocp_id;
        this.form.selectedCboCode = pValue.source.ocp_codigo;
      } else {
        this.form.selectedCboName = null;
        this.form.selectedCboId = null;
        this.form.selectedCboCode = null;
      }
    },

    selectedEmployee(pValue) {
      this.form.selectedEmployeeVusId = pValue ? pValue.vus_id : null;
      this.form.selectedEmployeeName = pValue ? pValue.label : null;
    },

    clickNewServiceScale() {
      this.$router.push({ name: "exam.register.service-scale" });
    },

    openModalDeleteScale() {
      this.modalDeleteShow = true;
    },

    openModalHistoryScales() {
      this.sectorLinkId = Number(this.selectedRow?.vus_id);
      this.professionalInfo = {
        pes_nome: this.selectedRow?.pes_nome,
        uns_nome: this.selectedRow?.uns_nome,
        set_nome: this.selectedRow?.set_nome,
        ocp_nome: this.selectedRow?.ocp_nome,
      };

      this.modalHistoryScales = true;
    },

    closeModalDelete() {
      this.modalDeleteShow = false;
    },

    closeModalHistoryScales() {
      this.modalHistoryScales = false;
      this.cleanProfessionalInfo();
    },

    clickCloseModalSplashScale() {
      this.modalSplashScale = false;
    },

    async divisionScale() {
      try {
        const variables = {
          intIdExameEscalaAgendamento: this.selectedRow.eea_id,
          strDataUltimoDia: this.splitDate,
        };

        await this.$store.dispatch(
          "Exam/Register/SPLIT_SERVICE_SCALE",
          variables,
        );

        this.$refs.RgSearch.submitForm(false, true);

        this.modalSplashScale = false;
        this.splitDate = null;

        this.$toaster.success("Divisão realizada com sucesso");
      } catch (err) {
        this.$toaster.error("Não foi possível realizar a divisão da escala");
      }
    },

    openModalSplitScale() {
      if (!this.validateDivisionDate()) return;
      this.modalSplashScale = true;
    },

    validateDivisionDate() {
      if (!this.splitDate) {
        this.$toaster.warning("Informe a data inicial da divisão");
        return false;
      }

      const { eea_data_inicio_escala, eea_data_fim_escala } = this.selectedRow;

      if (eea_data_inicio_escala === eea_data_fim_escala) {
        this.$toaster.warning(
          "A escala selecionada possui validade de apenas um dia e não pode ser dividida",
        );
        return false;
      }

      const initial = moment(eea_data_inicio_escala, "DD/MM/YYYY");

      const final = moment(eea_data_fim_escala, "DD/MM/YYYY");

      const splitDate = moment(this.splitDate, "DD/MM/YYYY");

      if (splitDate.isBefore(initial)) {
        this.$toaster.warning(
          "A data da divisão não pode ser menor que a data de início da escala",
        );
        return false;
      }

      if (splitDate.isSameOrAfter(final)) {
        this.$toaster.warning(
          "A data da divisão não pode ser maior ou igual que a data de término da escala",
        );
        return false;
      }

      return true;
    },

    setSelectedRow(row) {
      this.selectedRow = row;
      this.$store.commit(
        "Exam/Register/SET_SELECT_ROW_LIST_SERVICE_SCALE",
        row,
      );
    },

    async fillFilter(filter) {
      try {
        this.form = filter;

        if (this.$refs.occupation) {
          this.$refs.occupation.forceSelection({
            ocp_id: Number(filter.selectedCboId),
            ocp_nome: filter.selectedCboName,
            ocp_codigo: filter.selectedCboCode,
          });
        }

        await this.$refs.RgSearch.performSearch();
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      }
    },

    reSearch() {
      this.$refs.RgSearch.submitForm(false, true);
    },

    async actionRemoveItem() {
      try {
        this.$loader.start("Excluindo a escala selecionada...");
        const obj = {
          intIdExameEscalaAgendamento: this.selectedRow.eea_id,
        };
        await this.$store.dispatch("Exam/Register/REMOVE_TYPE_SCALE", obj);
        this.$toaster.success("Exclusão realizada com sucesso");

        this.reSearch();
      } catch (err) {
        this.$toaster.error(err.message);
      } finally {
        this.$loader.finish();
      }
    },

    cleanVuexFilter() {
      const existThisRoute = ValidateIfRouteExistInBreadscrumb(
        "/exam/register/list-service-scale",
        this.$route.meta.breadcrumb,
      );
      if (!existThisRoute) {
        this.resetFilterVuex();
      }
    },

    cleanForm() {
      if (this.$refs.gridServiceScale) {
        this.$refs.gridServiceScale.cleanSelectRow();
      }

      this.selectedRow = null;
      this.splitDate = null;

      this.cleanProfessionalInfo();
    },

    resetFilterVuex() {
      this.$store.commit("Exam/Register/CLEAN_FORM_FILTER_TYPE_SCALE");
    },

    setVuexFilter(filter) {
      this.$store.commit("Exam/Register/SET_FORM_FILTER_TYPE_SCALE", filter);
    },

    getVuexFilter() {
      const filter = this.$store.getters["Exam/Register/GET_FILTER_TYPE_SCALE"];
      if (filter) {
        this.fillFilter(filter);
      }
    },

    validateDateEnd(val, error) {
      if (!val) {
        return true;
      }

      const final = moment(val, "DD/MM/YYYY");
      const start = moment(this.form.valDateStart, "DD/MM/YYYY");
      if (final.isBefore(start)) {
        error.push(`A data inicial não pode ser maior que a data final.`);
        return false;
      }
    },

    cleanProfessionalInfo() {
      this.sectorLinkId = 0;
      this.professionalInfo = {};
    },
  },
};
</script>
