const LOGIN_ACTIVITY_LOGGED = 0;
const LOGIN_ACTIVITY_LOGOFF = 1;
const LOGIN_ACTIVITY_EXPIRED = 2;

export default {
  LOGIN(
    state,
    {
      unitHealth = {},
      loginInfo = {},
      token = null,
      user = {},
      permissions = {},
      preferences = {},
      clientName = "",
    },
  ) {
    state.unitHealth = unitHealth;
    state.clientCode = loginInfo ? loginInfo.client : "";
    state.clientName = clientName;
    state.token = token;
    state.user = user;
    state.backendLegacyUrl = loginInfo ? loginInfo.backendLegacyUrl : null;
    state.permissions = permissions;
    state.loginActive = LOGIN_ACTIVITY_LOGGED;
    state.lastLogin.clientCode = state.clientCode;
    state.lastLogin.userLogin = state.user.usu_login;

    const hasStatePreferences = Object.keys(state.preferences).length > 0;
    state.preferences = hasStatePreferences ? state.preferences : preferences;
  },
  UPDATE_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  LOGOFF(state) {
    state.unitHealth = {};
    state.clientCode = "";
    state.clientName = "";
    state.token = "";
    state.legacyToken = "";
    state.user = {};
    state.permissions = [];
    state.preferences = {};
    state.loginActive = LOGIN_ACTIVITY_LOGOFF;
    state.privateSector = false;
    state.cadsus = false;
    state.basicAttentionIntegration = false;
    state.backendLegacyUrl = null;
    state.module_preferences_loaded = {};
    state.module_permissions_loaded = {};
    state.userHealthOccupations = null;
  },
  EXPIRE_LOGIN(state) {
    state.token = "";
    state.legacyToken = "";
    state.loginActive = LOGIN_ACTIVITY_EXPIRED;
  },
  DEFINE_UNIT_HEALTH(state, unitHealth) {
    state.unitHealth = unitHealth;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_LEGACY_TOKEN(state, token) {
    state.legacyToken = token;
  },
  SET_UNIT_HEALTH_ID(state, pUniHealthId) {
    state.unitHealth.uns_id = pUniHealthId;
  },
  SET_PERMITED_UNIT_HEALTH_LIST(state, pUnitHealthList) {
    state.permitedUnitHealthList = pUnitHealthList;
  },
  SET_BACKEND_LEGACY_URL(state, pBackendLegacyUrl) {
    state.backendLegacyUrl = pBackendLegacyUrl;
  },
  SET_USE_LEGACY_BACKEND(state, pValue) {
    state.useLegacyBackend = pValue;
  },
  INCLUDE_LOADED_PREFERENCE(state, moduleName) {
    state.module_preferences_loaded[moduleName] = true;
  },
  REMOVE_LOADED_PREFERENCE(state, moduleName) {
    state.module_preferences_loaded[moduleName] = false;
  },
  RESET_ALL_PREFERENCES_TO_RELOAD(state) {
    state.module_preferences_loaded = {};
  },
  ADD_PREFERENCES(state, { preferences }) {
    if (!state.preferences) {
      state.preferences = {};
    }

    state.preferences = Object.assign({}, state.preferences, preferences);
  },
  SET_PRIVATE_SECTOR(state, pPrivateSector) {
    state.privateSector = pPrivateSector;
  },
  SET_CADSUS(state, pCadsus) {
    state.cadsus = pCadsus;
  },
  SET_BASIC_ATTENTION_INTEGRATION(state, pbasicAttentionIntegration) {
    state.basicAttentionIntegration = pbasicAttentionIntegration;
  },
  SET_PHONE_REQUIRED(state, pPhoneRequired) {
    state.phoneRequired = pPhoneRequired;
  },
  SET_RECORD_NUMBER_REQUIRED(state, pRecordNumberRequired) {
    state.recordNumberRequired = pRecordNumberRequired;
  },
  SET_HOSPITALIZATION_UNIT_HEALTH_RECORD_NUMBER_PREFERENCES(
    state,
    pHospitalizationUnitHealthRecordNumberRequired,
  ) {
    state.hospitalizationUnitHealthRecordNumberRequired = pHospitalizationUnitHealthRecordNumberRequired;
  },

  SET_CNS_REQUIRED(state, pCnsRequired) {
    state.preferences.documents.requiredCNS = pCnsRequired;
  },
  SET_CNS_UPDATE_REQUIRED(state, pCnsUpdateRequired) {
    state.preferences.documents.requiredCNSUpdate = pCnsUpdateRequired;
  },
  SET_CPF_REQUIRED(state, pCpfRequired) {
    state.preferences.documents.requiredCPF = pCpfRequired;
  },
  SET_CPF_UPDATE_REQUIRED(state, pCpfUpdateRequired) {
    state.preferences.documents.requiredCPFUpdate = pCpfUpdateRequired;
  },
  SET_ADDRESS_UNIT_HEALTH(state, pAddressUnitHealth) {
    state.addressUnitHealth = pAddressUnitHealth;
  },
  SET_USER_HEALTH_OCCUPATIONS(state, pOccupations) {
    state.userHealthOccupations = pOccupations;
  },
  CLEAR_PRIVATE_SECTOR(state) {
    state.privateSector = false;
  },
  CLEAR_CADSUS(state) {
    state.cadsus = false;
  },
  CLEAR_PHONE_REQUIRED(state) {
    state.phoneRequired = false;
  },
  CLEAR_RECORD_NUMBER_REQUIRED(state) {
    state.recordNumberRequired = null;
  },
  CLEAR_ADDRESS_UNIT_HEALTH(state) {
    state.addressUnitHealth = false;
  },
  CLEAR_USER_HEALTH_OCCUPATIONS(state) {
    state.userHealthOccupations = null;
  },
  INCLUDE_LOADED_PERMISSIONS(state, { moduleName, unitHealthId }) {
    if (!state.module_permissions_loaded[unitHealthId]) {
      state.module_permissions_loaded[unitHealthId] = {};
    }

    state.module_permissions_loaded[unitHealthId][moduleName] = true;
  },
  REMOVE_LOADED_PERMISSIONS(state, { moduleName, unitHealthId }) {
    state.module_permissions_loaded[unitHealthId][moduleName] = false;
  },
  RESET_ALL_PERMISSIONS_TO_RELOAD(state) {
    state.module_permissions_loaded = {};
  },
  SET_REFRESH_PREFERENCE_COLUMN_GRID(state, status) {
    state.refreshPreferenceColumnGrid = status;
  },
};
