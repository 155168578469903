import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

const RoutesPatientHistory = (route) => {
  const routes = [
    // Cadastro de Pacientes
    { link: "/person/patient/search/editpatient" },
    { link: "/person/patient/search/editpatient/patient-care-history" },
    { link: "/person/patient/search/patient-care-history" },

    // Unificação de Pacientes
    { link: "/person/patient/unify/register/patient-record" },
    {
      link:
        "/person/patient/unify/register/patient-record/patient-care-history",
    },

    //  Pré-Atendimento
    { link: "/emergency/pre-registration/bulletin" },
    { link: "/emergency/pre-registration/bulletin/edit-patient" },
    {
      link:
        "/emergency/pre-registration/bulletin/edit-patient/patient-care-history",
    },
    { link: "/emergency/pre-registration/bulletin/patient-care-history" },

    //  Boletins
    { link: "/emergency/bulletin/edit-patient" },
    { link: "/emergency/bulletin/edit-patient/patient-care-history" },
    { link: "/emergency/bulletin/patient-care-history" },
    { link: "/emergency/bulletin/edit-registration" },
    { link: "/emergency/bulletin/edit-registration/edit-patient" },
    {
      link:
        "/emergency/bulletin/edit-registration/edit-patient/patient-care-history",
    },
    { link: "/emergency/bulletin/edit-registration/patient-care-history" },
    { link: "/emergency/bulletin/registration" },
    { link: "/emergency/bulletin/registration/edit-patient" },
    {
      link:
        "/emergency/bulletin/registration/edit-patient/patient-care-history",
    },
    { link: "/emergency/bulletin/registration/patient-care-history" },

    //  Alta Administrativa
    { link: "/emergency/administrative-check-out/edit-patient" },
    {
      link:
        "/emergency/administrative-check-out/edit-patient/patient-care-history",
    },
    { link: "/emergency/administrative-check-out/patient-care-history" },

    //  Agendamentos por Dia (Exame)
    { link: "/exam/schedule/per-day/form" },
    { link: "/exam/schedule/per-day/form/edit-patient" },
    {
      link: "/exam/schedule/per-day/form/edit-patient/patient-care-history",
    },
    { link: "/exam/schedule/per-day/edit/form" },
    { link: "/exam/schedule/per-day/edit/form/edit-patient" },
    {
      link:
        "/exam/schedule/per-day/edit/form/edit-patient/patient-care-history",
    },
    { link: "/exam/schedule/per-day/patient" },
    {
      link: "/exam/schedule/per-day/patient/patient-care-history",
    },
    {
      link: "/exam/schedule/per-day/patient-care-history",
    },

    //  Agendamentos por Período (Exame)
    { link: "/exam/schedule/per-period/form" },
    { link: "/exam/schedule/per-period/form/edit-patient" },
    {
      link: "/exam/schedule/per-period/form/edit-patient/patient-care-history",
    },
    {
      link: "/exam/schedule/per-period/form/patient-care-history",
    },
    { link: "/exam/schedule/per-period/edit/form" },
    { link: "/exam/schedule/per-period/edit/form/edit-patient" },
    {
      link:
        "/exam/schedule/per-period/edit/form/edit-patient/patient-care-history",
    },
    {
      link: "/exam/schedule/per-period/edit/form/patient-care-history",
    },
    {
      link: "/exam/schedule/per-period/patient",
    },
    {
      link: "/exam/schedule/per-period/patient/patient-care-history",
    },
    {
      link: "/exam/schedule/per-period/patient-care-history",
    },

    //  Efetivação (Exame)
    { link: "/exam/effectivation/edit" },
    { link: "/exam/effectivation/edit/edit-patient" },
    { link: "/exam/effectivation/edit/edit-patient/patient-care-history" },
    { link: "/exam/effectivation/edit/patient-care-history" },
    { link: "/exam/effectivation/details" },
    { link: "/exam/effectivation/details/edit-patient" },
    { link: "/exam/effectivation/details/edit-patient/patient-care-history" },
    { link: "/exam/effectivation/details/patient-care-history" },

    //  Exames Laboratoriais
    { link: "/exam/laboratory/details" },
    { link: "/exam/laboratory/details/edit-patient" },
    { link: "/exam/laboratory/details/edit-patient/patient-care-history" },
    { link: "/exam/laboratory/details/patient-care-history" },
    { link: "/exam/laboratory/edit-details" },
    { link: "/exam/laboratory/edit-details/edit-patient" },
    { link: "/exam/laboratory/edit-details/edit-patient/patient-care-history" },
    { link: "/exam/laboratory/edit-details/patient-care-history" },
    { link: "/exam/laboratory/patient-care-history" },
    { link: "/exam/laboratory/edit-patient" },
    { link: "/exam/laboratory/edit-patient/patient-care-history" },
    { link: "/exam/laboratory/schedule-exam-request" },
    { link: "/exam/laboratory/schedule-exam-request/edit-patient" },
    {
      link:
        "/exam/laboratory/schedule-exam-request/edit-patient/patient-care-history",
    },
    { link: "/exam/laboratory/schedule-exam-request/patient-care-history" },

    //  Fila de Regulação (Exame)
    { link: "/exam/schedule/queue" },
    { link: "/exam/schedule/queue/patient-care-history" },
    { link: "/exam/schedule/queue/edit-patient" },
    { link: "/exam/schedule/queue/edit-patient/patient-care-history" },
    { link: "/exam/schedule/queue/schedule" },
    { link: "/exam/schedule/queue/schedule/edit-patient" },
    {
      link: "/exam/schedule/queue/schedule/edit-patient/patient-care-history",
    },
    { link: "/exam/schedule/queue/schedule/patient-care-history" },
    { link: "/exam/schedule/queue/queue-insert" },
    { link: "/exam/schedule/queue/queue-insert/edit-patient" },
    {
      link:
        "/exam/schedule/queue/queue-insert/edit-patient/patient-care-history",
    },
    { link: "/exam/schedule/queue/queue-insert/patient-care-history" },
    { link: "/exam/schedule/queue/edit-insert" },
    { link: "/exam/schedule/queue/edit-insert/edit-patient" },
    {
      link:
        "/exam/schedule/queue/edit-insert/edit-patient/patient-care-history",
    },
    { link: "/exam/schedule/queue/external-schedule-form" },
    { link: "/exam/schedule/queue/external-schedule-form/new-patient" },
    {
      link: "/exam/schedule/queue/external-schedule-form/patient-care-history",
    },

    //  Inserir na Fila (Exame)
    { link: "/exam/schedule/queue-insert" },
    { link: "/exam/schedule/queue-insert/edit-patient" },
    {
      link: "/exam/schedule/queue-insert/edit-patient/patient-care-history",
    },
    { link: "/exam/schedule/queue-insert/patient-care-history" },

    // Agendamentos por Dia (Consulta)
    { link: "/appointment/schedule/per-day/form" },
    { link: "/appointment/schedule/per-day/form/edit-patient" },
    {
      link:
        "/appointment/schedule/per-day/form/edit-patient/patient-care-history",
    },
    { link: "/appointment/schedule/per-day/edit/form" },
    { link: "/appointment/schedule/per-day/edit/form/edit-patient" },
    {
      link:
        "/appointment/schedule/per-day/edit/form/edit-patient/patient-care-history",
    },
    { link: "/appointment/schedule/per-day/editpatient" },
    {
      link: "/appointment/schedule/per-day/editpatient/patient-care-history",
    },
    {
      link: "/appointment/schedule/per-day/patient-care-history",
    },

    //  Agendamentos por Período (Consulta)
    { link: "/appointment/schedule/per-periods/form" },
    { link: "/appointment/schedule/per-periods/form/edit-patient" },
    {
      link:
        "/appointment/schedule/per-periods/form/edit-patient/patient-care-history",
    },
    {
      link: "/appointment/schedule/per-periods/form/patient-care-history",
    },
    { link: "/appointment/schedule/per-periods/edit/form" },
    { link: "/appointment/schedule/per-periods/edit/form/edit-patient" },
    {
      link:
        "/appointment/schedule/per-periods/edit/form/edit-patient/patient-care-history",
    },
    {
      link: "/appointment/schedule/per-periods/edit/form/patient-care-history",
    },
    {
      link: "/appointment/schedule/per-periods/edit-patient",
    },
    {
      link:
        "/appointment/schedule/per-periods/edit-patient/patient-care-history",
    },
    {
      link: "/appointment/schedule/per-periods/patient-care-history",
    },

    //  Fila de Regulação (Consulta)
    { link: "/appointment/schedule/queue" },
    { link: "/appointment/schedule/queue/patient-care-history" },
    { link: "/appointment/schedule/queue/edit-patient" },
    { link: "/appointment/schedule/queue/edit-patient/patient-care-history" },
    { link: "/appointment/schedule/queue/schedule" },
    { link: "/appointment/schedule/queue/schedule/edit-patient" },
    {
      link:
        "/appointment/schedule/queue/schedule/edit-patient/patient-care-history",
    },
    { link: "/appointment/schedule/queue/schedule/patient-care-history" },
    { link: "/appointment/schedule/queue/insert" },
    { link: "/appointment/schedule/queue/insert/edit-patient" },
    {
      link:
        "/appointment/schedule/queue/insert/edit-patient/patient-care-history",
    },
    { link: "/appointment/schedule/queue/insert/patient-care-history" },
    { link: "/appointment/schedule/queue/edit-insert" },
    { link: "/appointment/schedule/queue/edit-insert/edit-patient" },
    {
      link:
        "/appointment/schedule/queue/edit-insert/edit-patient/patient-care-history",
    },
    { link: "/appointment/schedule/queue/external-schedule-form" },
    { link: "/appointment/schedule/queue/external-schedule-form/new-patient" },
    {
      link:
        "/appointment/schedule/queue/external-schedule-form/patient-care-history",
    },

    //  Inserir na Fila (Consulta)
    { link: "/appointment/schedule/queue-insert" },
    { link: "/appointment/schedule/queue-insert/edit-patient" },
    {
      link:
        "/appointment/schedule/queue-insert/edit-patient/patient-care-history",
    },
    { link: "/appointment/schedule/queue-insert/patient-care-history" },

    //  Leitos
    { link: "/hospitalization/bed/search/new-hospitalization" },
    {
      link:
        "/hospitalization/bed/search/new-hospitalization/patient-care-history",
    },
    {
      link: "/hospitalization/bed/search/new-hospitalization/edit-patient",
    },
    {
      link:
        "/hospitalization/bed/search/new-hospitalization/edit-patient/patient-care-history",
    },

    //  Internação
    { link: "/hospitalization/hospitalizations/search/details" },
    { link: "/hospitalization/hospitalizations/search/details/edit-patient" },
    {
      link:
        "/hospitalization/hospitalizations/search/details/edit-patient/patient-care-history",
    },
    {
      link:
        "/hospitalization/hospitalizations/search/details/patient-care-history",
    },
    { link: "/hospitalization/hospitalizations/details" },
    { link: "/hospitalization/hospitalizations/details/edit-patient" },
    {
      link:
        "/hospitalization/hospitalizations/details/edit-patient/patient-care-history",
    },
    { link: "/hospitalization/hospitalizations/details/patient-care-history" },
    { link: "/hospitalization/hospitalizations/edit-details" },
    { link: "/hospitalization/hospitalizations/edit-details/edit-patient" },
    {
      link:
        "/hospitalization/hospitalizations/edit-details/edit-patient/patient-care-history",
    },
    {
      link:
        "/hospitalization/hospitalizations/edit-details/patient-care-history",
    },
    { link: "/hospitalization/hospitalizations/edit-details" },
    { link: "/hospitalization/hospitalization-solicitation/register" },
    {
      link:
        "/hospitalization/hospitalization-solicitation/register/edit-patient",
    },
    {
      link:
        "/hospitalization/hospitalization-solicitation/register/edit-patient/patient-care-history",
    },
    {
      link:
        "/hospitalization/hospitalization-solicitation/register/patient-care-history",
    },

    //  Fila de Regulação (Internação)
    { link: "/hospitalization/queue" },
    {
      link: "/hospitalization/queue/external-schedule-form",
    },
    {
      link:
        "/hospitalization/queue/external-schedule-form/patient-care-history",
    },
    {
      link: "/hospitalization/queue/external-schedule-form/edit-patient",
    },
    {
      link:
        "/hospitalization/queue/external-schedule-form/edit-patient/patient-care-history",
    },
    {
      link:
        "/hospitalization/queue/external-schedule-form/edit-patient/patient-care-history",
    },
    { link: "/hospitalization/queue/insert/edit-patient/patient-care-history" },
    { link: "/hospitalization/queue/insert/edit-patient" },
    {
      link:
        "/hospitalization/queue/queue-insert/edit-patient/patient-care-history",
    },
    {
      link: "/hospitalization/queue/queue-insert/edit-patient",
    },
    { link: "/hospitalization/queue/insert/patient-care-history" },
    { link: "/hospitalization/queue/insert-queue/patient-care-history" },
    {
      link:
        "/hospitalization/queue/edit-insert/edit-patient/patient-care-history",
    },
    { link: "/hospitalization/queue/edit-insert/edit-patient" },
    { link: "/hospitalization/queue/edit-insert/patient-care-history" },
    {
      link: "/hospitalization/queue/schedule/edit-patient/patient-care-history",
    },
    { link: "/hospitalization/queue/schedule/patient-care-history" },
    { link: "/hospitalization/queue/schedule/edit-patient" },
    { link: "/hospitalization/queue/schedule" },

    //  Farmácia
    { link: "/pharmacy/satellite/revenue" },
    { link: "/pharmacy/satellite/revenue/edit-patient" },
    { link: "/pharmacy/satellite/revenue/edit-patient/patient-care-history" },
    { link: "/pharmacy/satellite/revenue/patient-care-history" },
  ];

  const validateIfExist = routes.find((item) => {
    const validate = ValidateIfRouteExistInBreadscrumb(item.link, route);
    return validate === true;
  });
  return validateIfExist;
};

export { RoutesPatientHistory };
