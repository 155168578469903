<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import getData from "./action";
export default {
  name: "RgComboboxTransferDestinyEmployee",
  mixins: [RgComboboxMixin],
  props: {
    strData: {
      required: true,
    },
    intIdUnidadeSaude: {
      required: true,
    },
    intIdSetor: {
      required: true,
    },
    blnHagAtendimento: [Number, String],
  },
  watch: {
    strData() {
      this.fillData();
    },
    intIdUnidadeSaude() {
      this.fillData();
    },
    intIdSetor() {
      this.fillData();
    },
    blnHagAtendimento() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      if (!this.strData || !this.intIdUnidadeSaude || !this.intIdSetor) {
        return Promise.resolve([]);
      }

      this.mData = [];

      this.disableLoading = true;
      const data = await getData({
        arrFiltro: {
          strData: this.strData,
          intIdUnidadeSaude: this.intIdUnidadeSaude,
          intIdSetor: this.intIdSetor,
          blnHagAtendimento: this.blnHagAtendimento,
        },
      });

      if (data.length === 0) {
        this.$toaster.warning("Não há escalas disponiveis");
        return;
      }

      data.forEach((item) => {
        const option = {
          item: item,
          value: item.eag_id,
          text: `${item.pes_nome} - ${item.eag_inicio_atendimentos}`,
        };

        if (this.useDisabledOption) {
          const scaleDisabled = item.eag_ativo === "0";
          const employeeDisabled = item.funcionario_inabilitado === "1";
          const attendanceDisabled = item.hag_atendimento === "0";

          option.disabledOption =
            scaleDisabled || employeeDisabled || attendanceDisabled;
        }

        this.mData.push(option);
      });
      this.disableLoading = false;
    },
  },
};
</script>
