<style src="./ModalShowTags.scss" lang="scss" scoped></style>
<template lang="html">
  <div v-if="show" class="modal-show-error">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      withCloseButton
      :show="show"
      class="rg-alert-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="confirm-header">
        <h2 class="title unselect">Etiquetas:</h2>
      </div>

      <div slot="body" class="confirm-body">
        <RgComboboxPrinters
          v-model="printer"
          class="printer"
          :printers="printers"
          @select="setPrinter"
        />
        <div class="patient-tag" :class="{ disable: !hasPatientTag }">
          <label for="printPatientTag">
            <input
              id="printPatientTag"
              v-model="isToPrintPatientTag"
              type="checkbox"
            />
            Imprimir etiqueta do Paciente
          </label>
        </div>
        <div class="tags">
          <div v-if="!hasExamTags">
            <div class="empty-values">
              <IconEmpty />
              <span class="span">NÃO HÁ ETIQUETAS DISPONÍVEIS</span>
            </div>
          </div>
          <div v-for="(item, index) in examTags" v-else :key="index">
            <div
              :class="{ 'badge-border': item.let_impresso === 0 }"
              class="struct"
            >
              <div v-show="item.let_impresso === 0" class="new-badge">
                <span>Novo</span>
              </div>
              <div class="check">
                <div v-show="canPrint" data-id="checked" class="checkbox">
                  <input
                    :id="index"
                    v-model="checkedTags"
                    :value="index"
                    type="checkbox"
                  />
                  <label :for="index" />
                </div>
              </div>
              <div class="item-1">
                <span class="name-people-tag" title="Nome do Paciente"
                  >{{ item.leb_nome_paciente }}
                </span>
              </div>
              <div class="item-2">
                <span class="procedure-tag" title="Nome do Procedimento"
                  >{{ item.procedures }}
                </span>
              </div>
              <div class="item-3">
                <span class="code-tag" title="Código da Etiqueta"
                  >{{ item.let_codigo_etiqueta }}
                </span>
              </div>
              <div class="item-4">
                <IconBarCode class="icon-tag" />
              </div>
              <div class="item-5">
                <span class="count-print"
                  >Tentativas de impressões : {{ item.let_impresso }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="confirm-actions-footer unselected">
        <button
          :disabled="!canPrint"
          class="input-tag btn btn-success btn-small"
          @click="print()"
        >
          Imprimir
        </button>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { QZFindPrinters, QZCertificate, QZSign } from "~common/utils/QZTray";
import { RgBaseModal, RgComboboxPrinters } from "~tokio/primitive";
import { IconBarCode, IconEmpty } from "~tokio/primitive/icon/symbols";
import PrintTag from "../helpers/PrintTag";

export default {
  name: "ModalShowTags",
  components: {
    RgBaseModal,
    IconBarCode,
    IconEmpty,
    RgComboboxPrinters,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    bioslabId: {
      type: Number,
      default: 0,
    },
    canPrint: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      printers: [],
      printer: "",
      examTags: [],
      patientTag: null,
      selectTags: "",
      checkedTags: [],
      isToPrintPatientTag: false,
      countPrintExams: [],
      countPrintPatient: null,
    };
  },
  computed: {
    hasExamTags() {
      return Array.isArray(this.examTags) && this.examTags.length > 0;
    },

    hasPatientTag() {
      return this.patientTag && Object.keys(this.patientTag).length > 0;
    },
  },
  watch: {
    bioslabId(pValue) {
      const hasId = this.bioslabId !== 0;

      if (hasId) {
        this.getTags();
      }
    },

    examTags(pValue) {
      this.examTags = pValue;
    },

    async show(pValue) {
      if (pValue) {
        await this.getPrinters();
        this.printer = this.setDefaultPrinter();
      }
    },
  },
  created() {
    this.DEFAULT_PRINTER = "zebra";
  },

  async mounted() {
    await this.getCertificate();
    await this.getSignature();
  },

  methods: {
    close() {
      this.selectTags = "";
      this.checkedTags = [];
      this.isToPrintPatientTag = false;
      this.countPrintPatient = null;
      this.countPrintExams = [];
      this.$emit("close");
    },

    async getPatientTag() {
      try {
        const patientTag = await this.$store.dispatch(
          "Exam/IndividualEffectivation/SEARCH_BIOSLAB_PATIENT_TAG",
          { lip_id_laboratoriais_exames_bioslab: this.bioslabId },
        );

        return patientTag.length > 0 ? patientTag[0] : "";
      } catch (pErr) {
        console.log(pErr);
        this.$toaster.warning("Não foi possível obter etiqueta do paciente");
      }
    },

    async getExamTags() {
      try {
        const examTags = await this.$store.dispatch(
          "Exam/IndividualEffectivation/SEARCH_BIOSLAB_TAG_INFO",
          { leb_id: this.bioslabId },
        );

        return examTags;
      } catch (pErr) {
        this.$toaster.warning(
          "Não foi possível obter etiqueta(s) do(s) exame(s)",
        );
      }
    },

    async getTags() {
      this.examTags = await this.getExamTags();

      this.patientTag = await this.getPatientTag();

      const auxTags = [];

      this.examTags = this.examTags.filter((element) => {
        if (!auxTags[element.let_codigo_etiqueta]) {
          auxTags[element.let_codigo_etiqueta] = [];
        }

        auxTags[element.let_codigo_etiqueta].push(element);

        if (auxTags[element.let_codigo_etiqueta].length > 1) return false;

        element.procedures = this.getCurrentProcedures(element.let_impressao);
        return element;
      });
    },

    getCurrentProcedures(pContent) {
      const startString = 'A3,210,0,1,1,1,N,"';
      const start = pContent.search(startString);
      const end = pContent.search('A5,189,3,2,1,1,N,"');
      return pContent
        .substring(start + startString.length, end)
        .replace('"', "")
        .replace(",", ", ");
    },

    async getPrinters() {
      const availablePrinters = await QZFindPrinters();

      this.printers = availablePrinters.map((printer) => {
        return { value: printer, text: printer };
      });
    },

    setDefaultPrinter() {
      const printerSavedOnStore = this.$store.getters[
        "Bioslab/GET_BIOSLAB_TAG_PRINTER"
      ];

      const printerSavedExists = this.printers.some((item) => {
        return item.value === printerSavedOnStore;
      });

      if (printerSavedExists) {
        return printerSavedOnStore;
      }

      const hasPrinterNamedZebra = this.printers.some((item) => {
        return item.value === this.DEFAULT_PRINTER;
      });

      if (hasPrinterNamedZebra) {
        return this.DEFAULT_PRINTER;
      }

      return null;
    },

    setPrinter(pValue) {
      if (pValue) {
        this.$store.commit("Bioslab/SET_BIOSLAB_TAG_PRINTER", pValue.value);
      }
    },

    async getCertificate() {
      await QZCertificate();
    },

    async getSignature() {
      await QZSign();
    },

    prepareTagsToPrint() {
      const examsToCountPrint = [];
      let patientToCountPrint = null;

      this.examTags.forEach((item, index) => {
        if (this.checkedTags.includes(index)) {
          this.selectTags += item.let_impressao;
          examsToCountPrint.push(item.let_id);
          item.let_impresso += 1;
        }
      });

      if (this.isToPrintPatientTag) {
        this.selectTags += this.patientTag.lip_impressao;
        this.patientTag.lip_impresso += 1;
        patientToCountPrint = this.patientTag.lip_id;
      }

      this.countPrintExams = examsToCountPrint;
      this.countPrintPatient = patientToCountPrint;
    },

    async incrementPrintCounter() {
      await this.$store.dispatch("Exam/IndividualEffectivation/COUNT_PRINT", {
        patientTag: this.countPrintPatient,
        examsTag: this.countPrintExams,
      });
    },

    async print() {
      try {
        this.prepareTagsToPrint();

        const withoutTagsToPrint = this.selectTags === "";

        if (withoutTagsToPrint)
          throw new Error("Nenhum registro a ser impresso");

        this.incrementPrintCounter();

        await PrintTag(this.selectTags, this.printer);

        this.selectTags = "";
        this.$toaster.success("Etiqueta enviada para impressão");
      } catch (error) {
        this.selectTags = "";
        this.$toaster.warning(error.message);
      }
    },
  },
};
</script>
