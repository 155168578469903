import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchCeilingReport(
    $uns_id: Int
    $procedureCode: String
    $procedureName: String
    $uns_nome: String
    $mun_nome: String
    $initialDate: String
    $finalDate: String
    $columnsToPrint: [ColumnsToPrintInputType]
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchCeilingReport(
      uns_id: $uns_id
      procedureCode: $procedureCode
      procedureName: $procedureName
      uns_nome: $uns_nome
      mun_nome: $mun_nome
      initialDate: $initialDate
      columnsToPrint: $columnsToPrint
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        EFETIVADO
        ABERTO
        NAO_EFETIVADO
        PROCEDIMENTO
        CODIGO_PROCEDIMENTO
        UNIDADE_NOME
        GRUPO
        SUB_GRUPO
        FORM_ORG
        MUNICIPIO_NOME
        COMPETENCIA
        VALOR_UNITARIO
        TOTAL
        TOTAL_TETO
        VALOR_RESTANTE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchCeilingReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
