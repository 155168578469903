import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, intIdExameEscalaAgendamento) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-escala-agendamento/inativar",
    intIdExameEscalaAgendamento,
  )
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
