<template lang="html">
  <div v-show="show" class="modal-effective-exam">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      class="rg-base-modal"
      :show="show"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">
          {{ inBatch ? "Efetivar Exame em Lote" : "Efetivar Exame" }}</span
        >
      </div>

      <div slot="body" class="body">
        <div v-if="!inBatch" class="informations">
          <RgTypePatient
            :pac-id="Number(isPatient)"
            :sex="isPatient ? patientGender : null"
            class="patient-type"
          />

          <div class="area">
            <span class="data">
              Paciente:
              <strong>
                {{ getValue(examInfo[0], "patientName") }}
              </strong>
            </span>

            <span class="data">
              Prontuário Único:
              <strong>
                {{ getValue(examInfo[0], "pac_prontuario_unico") }}
              </strong>
            </span>
          </div>

          <div class="area">
            <span class="data">
              Unidade de Saúde:
              <strong>
                {{ unitHealthName }}
              </strong>
            </span>

            <span class="data">
              Boletim/Internação:
              <strong>
                {{
                  getValue(examInfo[0], "int_numero") ||
                  getValue(examInfo[0], "bol_numero")
                }}
              </strong>
            </span>
          </div>
        </div>

        <FormBase v-if="!inBatch" title="Solicitação" class="area">
          <div class="solicitation-info">
            <span>
              Procedimento:
              <b>
                {{ getValue(examInfo[0], "procedimento") }}
              </b>
            </span>

            <span>
              Data:
              <b>
                {{
                  formatDataBR(getValue(examInfo[0], "exe_data_solicitacao"))
                }}
              </b>
            </span>

            <span>
              Profissional Solicitante:

              <b> {{ getValue(examInfo[0], "request_employee") }} </b>
            </span>

            <span>
              CID:
              <b> {{ getValue(examInfo[0], "cid_nome") }} </b>
            </span>
          </div>
        </FormBase>

        <FormBase v-else title="Solicitação" class="area">
          <SmartTable
            ref="smartTable"
            name="patientsEffectiveExam"
            :columns="COLUMN_TABLE"
            :body="mutableExamList"
            :total="Number(mutableExamList.length)"
            :initial-columns="5"
            :show-pagination="false"
            remove-btn-columns
            dont-select
            class="smart-table"
          />
        </FormBase>

        <FormBase title="Efetivação" class="area">
          <RgValidatorForm ref="validator" class="effectuation-info">
            <RgInputDate
              id="effected-date"
              ref="date"
              v-model="form.exe_data_efetivacao"
              :rules="{ required: true, fn: validateEffectedDate }"
              :max-date="new Date()"
              label="Data da Efetivação"
            />
            <RgInputHour
              id="effected-hour"
              ref="hour"
              v-model="effectedHour"
              :rules="{ required: true, fn: validateHour }"
              label="Hora da Efetivação"
            />
            <RgSelectSector
              id="sector"
              ref="sector"
              v-model="form.exe_id_setores"
              openOnlyTop
              defaultText="Digite o nome do setor"
              :rules="{ required: true }"
              :disabled="!form.exe_data_efetivacao"
              :unit-health="unitHealthId"
            />
            <RgSuggestEmployee
              id="employee"
              ref="employee"
              v-model="form.employee"
              label="Profissional Executante"
              openOnlyTop
              :rules="{ required: true, forceSelection: true }"
              :disabled="!form.exe_id_setores"
              :class="{ disable: !form.exe_id_setores }"
              :sector="form.exe_id_setores"
              :sector-required="true"
              :vusResponsavel="false"
              @selected="selectingEmployee"
            />
          </RgValidatorForm>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel-btn" medium @click="cancel" />
        <RgSaveButton
          id="save-btn"
          v-shortkey="['enter']"
          medium
          @click="effectivateExam"
          @shortkey.native="effectivateExam"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";

import {
  RgBaseModal,
  RgValidatorForm,
  RgCancelButton,
  RgSaveButton,
  RgInputDate,
  RgInputHour,
  RgSelectSector,
  RgSuggestEmployee,
} from "~tokio/primitive";

import { FormBase, SmartTable } from "~tokio/foundation";

import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient";
import PrepareExamToSave from "$exam/store/actions/PrepareExamToSave";

const EFFECTUATE = 2;

export default {
  name: "ModalEffectiveExam",
  components: {
    FormBase,
    SmartTable,
    RgBaseModal,
    RgValidatorForm,
    RgTypePatient,
    RgCancelButton,
    RgSaveButton,
    RgInputDate,
    RgInputHour,
    RgSelectSector,
    RgSuggestEmployee,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    examInfo: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      mutableExamList: [],
      codeProcedure: null,
      patientGender: null,
      effectedHour: null,
      form: {
        employee: null,
        exe_data_efetivacao: moment().format("DD/MM/YYYY"),
        exe_id_setores: null,
        exe_id_vinculos: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      unitHealthName: "Login/GET_UNIT_HEALTH_NAME",
      loggedUserId: "Login/GET_USER_ID",
    }),
    inBatch() {
      return this.examInfo && this.examInfo.length > 1;
    },
    isPatient() {
      return this.examInfo[0] && this.examInfo[0].patient.pac_id
        ? this.examInfo[0].patient.pac_id
        : 0;
    },
    canNotEffectRetroactive() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.naoEfetivarRetroativo",
        this.unitHealthId,
      );
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.fillData();
      } else {
        this.close();
      }
    },
    "form.exe_id_setores"(pValue, pPrev) {
      if (pValue !== pPrev) {
        this.form.exe_id_vinculos = null;
        this.form.employee = null;
      }
    },
  },
  created() {
    this.COLUMN_TABLE = [
      { name: "Data", key: "data_solicitacao" },
      { name: "Paciente", key: "patientName" },
      { name: "Boletim", key: "bol_numero" },
      { name: "Procedimento", key: "procedimento" },
      { name: "Profissional Solicitante", key: "request_employee" },
      { name: "CID", key: "cid_nome" },
    ];
  },
  mounted() {},
  methods: {
    fillData() {
      let equalsSector = true;
      let equalsEmployee = true;
      if (this.inBatch) {
        this.examInfo.forEach((element) => {
          element.cid_nome = element.cid10?.cid_nome;
          this.codeProcedure = element.exe_codigo_procedimento;
          element.data_solicitacao = moment(
            element.exe_data_solicitacao,
          ).format("DD/MM/YYYY HH:mm:ss");
        });
        this.mutableExamList = this.examInfo;

        equalsSector = !this.examInfo.every((element, i, array) => {
          return array.find((element2) => {
            return element2.exe_id_setores !== element.exe_id_setores;
          });
        });
        equalsEmployee = !this.examInfo.every((element, i, array) => {
          return array.find((element2) => {
            return element2.request_employee !== element.request_employee;
          });
        });
      } else {
        const gender = this.examInfo[0].patient.person.gender.sex_sigla;
        this.patientGender = gender === "M" ? 1 : gender === "F" ? 2 : null;
        this.examInfo[0].cid_nome = this.examInfo[0].cid10?.cid_nome;
      }

      if (this.examInfo[0].exe_id_setores && equalsSector) {
        this.form.exe_id_setores = this.examInfo[0].exe_id_setores;
        if (equalsEmployee) {
          this.form.employee = this.examInfo[0].request_employee;
          if (this.$refs.employee) {
            this.$refs.employee.forceSelection({
              pes_nome: this.examInfo[0].request_employee,
              vin_id: Number(
                this.examInfo[0].requestEmployeeRelationship.vin_id,
              ),
            });
          }
        }
      }
      this.effectedHour = moment().format("HH:mm");
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async effectivateExam() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        this.$loader.start();

        const data = [];
        const exams = this.examInfo;

        exams.forEach((item) => {
          item.exe_id_unidades_saude = this.unitHealthId;
          item.exe_id_setores = this.form.exe_id_setores;
          item.exe_id_vinculos = this.form.exe_id_vinculos;
          item.exe_data_efetivacao = `${this.$utils.date.BrazilianDateToDatabase(
            this.form.exe_data_efetivacao,
          )} ${this.effectedHour}:00`;
        });

        const preparedExamInfo = PrepareExamToSave(
          exams,
          EFFECTUATE,
          this.form.exe_id_vinculos,
          null,
          true,
          this.loggedUserId,
        );

        preparedExamInfo.forEach((item, index) => {
          data.itemIndex = index;
          if (item.examId) {
            data.push(item);
          }
        });

        await this.$store.dispatch(
          "Exam/IndividualEffectivation/EFFECTTIVATE_EXAMS_BY_ID",
          data,
        );

        this.$toaster.success("Efetivação cadastrada com sucesso");
        this.close();
        this.$emit("reSearch");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao efetivar");
      } finally {
        this.$loader.finish();
      }
    },

    validateEffectedDate(pValue, pErrors) {
      if (!pValue) {
        return true;
      }

      const requestDate = this.$utils.date.BrazilianDateFormat(
        this.examInfo[0].exe_data_solicitacao,
      );

      const validDate = this.$utils.date.IsSameOrBefore(
        requestDate,
        "DD/MM/YYYY",
        pValue,
        "DD/MM/YYYY",
      );

      if (this.canNotEffectRetroactive && !validDate) {
        pErrors.push(
          "A data da efetivação não pode ser anterior à data da solicitação",
        );
        return false;
      }
    },

    validateHour(pValue, pErrors) {
      if (!pValue) {
        return;
      }
      const currentDateTime = moment(new Date(), "DD/MM/YYYY HH:mm");
      const dateHourEffective = this.form.exe_data_efetivacao + " " + pValue;
      const formatDateEffective = moment(dateHourEffective, "DD/MM/YYYY HH:mm");
      const validateFutureTime = currentDateTime.isAfter(formatDateEffective);

      if (!validateFutureTime) {
        pErrors.push("A hora da efetivação não pode ser futura");
        return false;
      }
    },

    getValue(pItem, pKey) {
      const value = pItem && pItem[pKey] ? pItem[pKey] : "Não informado";
      return value;
    },

    formatDataBR(pValue) {
      return pValue ? this.$utils.date.BrazilianDateFormat(pValue) : "";
    },

    selectingEmployee(pEmployee) {
      this.form.exe_id_vinculos = pEmployee.source.vin_id || null;
      this.form.employee = pEmployee.source.pes_nome;
    },

    cleanForm() {
      this.mutableExamList = [];
      this.patientGender = null;
      this.effectedHour = null;

      this.form = {
        employee: null,
        exe_data_efetivacao: moment().format("DD/MM/YYYY"),
        exe_id_setores: null,
        exe_id_vinculos: null,
      };

      this.$refs.date.cleanValidate();
      this.$refs.hour.cleanValidate();
      this.$refs.sector.cleanValidate();
      this.$refs.employee.cleanValidate();
    },

    cancel() {
      this.close();
    },

    close() {
      this.cleanForm();
      this.$emit("close");
    },
  },
};
</script>
