<style src="./PreviewValues.scss" lang="scss" scoped></style>
<template>
  <div class="grid">
    <div class="selectinput">
      <RgSelectUnithealth
        id="uns-id-pv"
        v-model="form.unsId"
        :rules="{ required: true }"
        permission="faturamento.relatoriosESUSng.Exibir"
        class="inputitem"
      />
    </div>
    <div class="selectinput">
      <RgInputMasked
        id="presentation-pv"
        v-model="form.presentation"
        :mask="['BM/DDDD']"
        :tokens="tokens"
        :rules="{ fn: validatePeriod }"
        label="Apresentação"
        placeholder="mm/aaaa"
        class="inputitem"
      />
    </div>
  </div>
</template>

<script>
import { RgInputMasked, RgSelectUnithealth } from "~tokio/primitive";
import {
  ValidatePeriodBilling,
  MountPresentation,
} from "../../../common/service/";

export default {
  name: "PreviewValues",
  components: {
    RgInputMasked,
    RgSelectUnithealth,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Relatório de Prévia Valor",
      form: {
        unsId: 0,
        status: "0",
        presentation: "",
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "PreviewValues";
    },
  },
  watch: {
    "form.name"() {
      if (this.form.name) {
        this.form.name = this.form.name.toUpperCase();
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Apresentação", key: "iaf_apresentacao" },
      { name: "Qtd de AIH", key: "quantidade_aih" },
      { name: "Total Serviço Hospitalar", key: "total_servico_hospitalar" },
      {
        name: "Total Serviço Profissional",
        key: "total_servico_profissional",
      },
      { name: "Total", key: "total" },
    ];
  },
  mounted() {
    this.form.unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.updatePresentationDate();
  },
  methods: {
    buildFilter() {
      return {
        unsId: this.form.unsId ? this.form.unsId : null,
        presentation: this.form.presentation
          ? this.form.presentation.replace("/", "")
          : null,
        status: this.form.status,
        columnsToPrint: this.columnsToPrint,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_PREVIEW_VALUES_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      this.form.presentation = MountPresentation(cmp);
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.form.presentation, pErrors);
    },
    clearFilter() {
      this.form = {
        unsId: 0,
        status: "0",
      };
    },
  },
};
</script>
