<template>
  <div class="rg-pagination unselect">
    <div class="filter-registers unselected">
      <button
        type="button"
        data-id="menos"
        class="btnsfilters"
        :class="{ disable: disableLimitLess || disabled }"
        :disabled="disableLimitLess || disabled"
        @click="decreaseLimit"
      >
        -
      </button>
      <input
        v-model="pagination.limit"
        class="inputfilter unselect"
        min="1"
        :disabled="disabled"
        :class="{ disable: disabled }"
        max="20"
        title="Registros por Página"
        @blur="navigationLimit"
      />
      <button
        type="button"
        data-id="mais"
        class="btnsfilters"
        :class="{ disable: disableLimitPlus || disabled }"
        :disabled="disableLimitPlus || disabled"
        @click="increaseLimit"
      >
        +
      </button>
    </div>

    <div class="pagination-selector">
      <RgPaginationButton
        :disabled="hasPrevious || disabled"
        :double="true"
        :rotate="true"
        :class="{ disable: hasPrevious || disabled }"
        data-id="inicio"
        title="Início"
        @click="pageInitial"
      />
      <RgPaginationButton
        :disabled="hasPrevious || disabled"
        :class="{ disable: hasPrevious || disabled }"
        data-id="anterior"
        title="Anterior"
        class="left"
        @click="pagePrevious"
      />

      <div class="body-pagination">
        <div
          v-if="currentNumber(pagination.current)"
          :class="{ current: currentNumber(pagination.current) }"
          class="item-pagination"
        >
          <input
            v-model="pagination.current"
            class="current-page"
            min="1"
            :max="numberOfPages"
            type="number"
            title="Página Atual"
            :disabled="!isSettable || disabled"
            :class="{
              disable: !isSettable || disabled,
              extend: pagination.current.length > 2,
            }"
            @blur="setCurrentPage"
            @keypress="onlyNumber"
          />

          <span title="Total de Páginas">/ {{ numberOfPages }}</span>
        </div>
      </div>

      <RgPaginationButton
        :disabled="hasNext || disabled"
        :class="{ disable: hasNext || disabled }"
        data-id="proxima"
        title="Próximo"
        :rotate="true"
        @click="pageNext"
      />
      <RgPaginationButton
        :disabled="hasNext || disabled"
        :double="true"
        :class="{ disable: hasNext || disabled }"
        data-id="final"
        title="Final"
        @click="pageFinal"
      />
    </div>

    <div class="list-pagination">Total de Registros: {{ dataLength }}</div>
  </div>
</template>

<script>
import RgPaginationButton from "~tokio/primitive/button/rg-pagination-button/RgPaginationButton";

export default {
  name: "RgPagination",
  components: {
    RgPaginationButton,
  },
  props: {
    dataLength: {
      type: Number,
      default: 0,
    },
    maxRegister: {
      type: Number,
      default: 15,
    },
    isSettable: {
      type: Boolean,
      default: true,
    },
    isSearchComponent: {
      type: Boolean,
      default: false,
    },
    isFromSmartTable: {
      type: Boolean,
      default: false,
    },
    itemPerPage: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fromModal: Boolean,
  },
  data() {
    return {
      countItem: 0,
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },
  computed: {
    numberOfPages() {
      if (!this.dataLength || !this.pagination.limit) return 1;
      return Math.ceil(this.dataLength / this.pagination.limit);
    },
    currentPage() {
      return this.numberOfPages === 0 ? 0 : this.pagination.current;
    },
    hasNext() {
      return (
        this.dataLength <= this.pagination.offset + this.pagination.limit ||
        this.pagination.current === this.numberOfPages
      );
    },
    hasPrevious() {
      return !this.pagination.offset;
    },
    disableLimitPlus() {
      return (
        this.pagination.limit >= this.maxRegister ||
        (this.hasNext && this.numberOfPages === 1)
      );
    },
    disableLimitLess() {
      return this.pagination.limit < 2;
    },
  },

  watch: {
    dataLength(pValue) {
      if (this.fromModal) return;

      const hasItemPerPage =
        this.itemPerPage < pValue && this.itemPerPage > 0
          ? this.itemPerPage
          : pValue;

      const conditionLimit =
        (pValue && pValue < this.pagination.limit) ||
        (this.itemPerPage < this.pagination.limit && this.itemPerPage > 0);

      if (this.isFromSmartTable) {
        this.pagination.limit =
          pValue < this.pagination.limit
            ? pValue
            : this.itemPerPage > 0
            ? this.itemPerPage
            : 10;

        return;
      }

      const storeLimitPagination = this.$store.getters[
        "Pagination/GET_LIMIT_PAGINATION"
      ];

      if (conditionLimit) {
        this.pagination.limit = hasItemPerPage;
      } else {
        const valueBiggerThanLimit =
          pValue &&
          pValue > this.pagination.limit &&
          pValue > storeLimitPagination;

        if (valueBiggerThanLimit) {
          this.pagination.limit = storeLimitPagination;
        } else {
          this.pagination.limit = pValue;
        }
      }

      this.setStorePagination();
    },
    "pagination.current"(pValue, pPrev) {
      if (pValue && pPrev && pValue !== pPrev) {
        this.setStorePagination();
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getStorePagination();
    });
  },

  methods: {
    navigationLimit() {
      this.pagination.limit = Number(this.pagination.limit);
      if (this.pagination.limit > this.maxRegister) {
        this.pagination.limit = this.maxRegister - 1;
      }
      if (this.pagination.limit < 1) {
        this.pagination.limit = 1;
      }
      if (this.pagination.limit > 50) {
        this.pagination.limit = 50;
      }
      if (this.dataLength !== 0) {
        this.setPage(this.pagination.current);
      }
      this.setStorePagination();
    },

    decreaseLimit() {
      if (this.pagination.limit > 1) {
        this.pagination.limit = this.pagination.limit - 1;
        if (this.dataLength !== 0) {
          this.setPage(this.pagination.current);
        }
      }
      this.setStorePagination();
    },

    increaseLimit() {
      if (this.pagination.limit < this.maxRegister) {
        this.pagination.limit = this.pagination.limit + 1;
        if (this.dataLength !== 0) {
          this.setPage(this.pagination.current);
        }
      }
      this.setStorePagination();
    },

    pageInitial() {
      if (this.hasPrevious) {
        return;
      }
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.setStorePagination();
      this.loadPagination();
    },

    pagePrevious() {
      if (this.hasPrevious) {
        return;
      }
      if (this.pagination.offset <= 0) {
        this.pagination.offset = 0;
      } else {
        this.pagination.offset =
          Number(this.pagination.offset) - Number(this.pagination.limit);

        this.pagination.current = Number(this.pagination.current) - 1;
      }
      this.setStorePagination();
      this.loadPagination();
    },

    pageNext() {
      if (this.hasNext) {
        return;
      }
      this.pagination.offset =
        Number(this.pagination.offset) + Number(this.pagination.limit);

      this.pagination.current = Number(this.pagination.current) + 1;
      this.setStorePagination();
      this.loadPagination();
    },

    pageFinal() {
      if (this.hasNext) {
        return;
      }
      this.pagination.offset = (this.numberOfPages - 1) * this.pagination.limit;
      this.pagination.current = this.numberOfPages;
      this.setStorePagination();
      this.loadPagination();
    },

    setCurrentPage() {
      let value = Number(this.pagination.current);
      const isInteger = Number.isInteger(value);

      if (isInteger) {
        if (value > this.numberOfPages) {
          value = this.numberOfPages;
        }

        if (value === 0) {
          value = 1;
        }

        this.setPage(value);
      }
    },

    setPage(pValue) {
      this.pagination.offset = (pValue - 1) * this.pagination.limit;
      this.pagination.current = pValue;

      if (this.pagination.current > this.numberOfPages) {
        this.pagination.offset =
          (this.numberOfPages - 1) * this.pagination.limit;
        this.pagination.current = this.numberOfPages;
      }
      this.setStorePagination();
      this.loadPagination();
    },

    currentNumber(pItem) {
      return this.pagination.current === pItem;
    },

    loadPagination() {
      this.$emit("pagination", this.pagination);
    },

    onlyNumber(pEvent) {
      const event = pEvent || window.event;
      const charCode = event.which ? event.which : event.keyCode;

      const notIsNumber =
        charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46;

      return notIsNumber ? event.preventDefault() : true;
    },

    getStorePagination() {
      if (this.isSearchComponent) {
        const offset = this.$store.getters["Pagination/GET_OFFSET_PAGINATION"];
        const limit = this.$store.getters["Pagination/GET_LIMIT_PAGINATION"];
        const current = this.$store.getters[
          "Pagination/GET_CURRENT_PAGINATION"
        ];
        const total = this.$store.getters["Pagination/GET_TOTAL_PAGINATION"];
        this.pagination.offset = offset;
        this.pagination.limit = limit;
        this.pagination.current = current;
        this.pagination.total = total;
      }
    },

    setStorePagination() {
      if (this.isSearchComponent) {
        const routeName = this.$route.name;

        this.$store.commit(
          "Pagination/SET_CURRENT_PAGINATION",
          this.pagination.current,
        );
        this.$store.commit(
          "Pagination/SET_LIMIT_PAGINATION",
          this.pagination.limit,
        );
        this.$store.commit(
          "Pagination/SET_OFFSET_PAGINATION",
          this.pagination.offset,
        );
        this.$store.commit("Pagination/SET_TOTAL_PAGINATION", this.dataLength);
        this.$store.commit("Pagination/SET_ROUTE_PAGINATION", routeName);
      }
    },

    resetLimitOffset() {
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },

    clearStorePagination() {
      this.$store.commit("Pagination/DESTROY_PAGINATION");
      this.$store.commit("Pagination/DESTROY_ROUTE_PAGINATION");
    },

    initLimit(value) {
      this.pagination.limit = value;
      this.$store.commit("Pagination/SET_LIMIT_PAGINATION", value);
    },
  },
};
</script>
