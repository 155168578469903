export default {
  selected_employee_data: null,
  selected_hospitalization_data: null,
  selected_queue_external_hospitalization_data: null,
  filter_per_day_data: null,
  filter_per_periods_data: null,
  filter_hospitalization_queue_data: null,
  type_schedule: false,
  filter_externally_schedule: null,
  filter_schedule_patient_list: null,
  filter_patient_exclude_data: null,
};
