<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchPharmacyEmployee from "./action/SearchPharmacyEmployee";

export default {
  name: "RgSelectPharmacyEmployee",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Funcionário Responsável",
    },
    unitHealthId: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      pharmacy: [],
    };
  },
  watch: {
    unitHealthId(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    pharmacy: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    if (this.unitHealthId) this.doSearch();
  },
  methods: {
    getData() {
      return this.pharmacy;
    },
    async doSearch() {
      this.pharmacy = [];

      const SUB_MODULE_PHARMACY = 14;

      const data = await SearchPharmacyEmployee({
        intIdUnidadeSaude: this.unitHealthId,
        intIdSubModulo: SUB_MODULE_PHARMACY,
      });

      this.pharmacy = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
