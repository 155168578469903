import { render, staticRenderFns } from "./RgTypePatient.vue?vue&type=template&id=3c26df6a&scoped=true&lang=html&"
import script from "./RgTypePatient.vue?vue&type=script&lang=js&"
export * from "./RgTypePatient.vue?vue&type=script&lang=js&"
import style0 from "./RgTypePatient.scss?vue&type=style&index=0&id=3c26df6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c26df6a",
  null
  
)

export default component.exports