<template>
  <div :class="mainClass">
    <RgSearch
      ref="RgSearch"
      v-model="mutableExamList"
      :search-function="actionSearchFilter"
      :clear-function="actionCleanForm"
      :build-filter="generateFilter"
      :show-footer="false"
      :show-title="false"
      :show-empty-message="false"
      :showTitle="false"
      :disabledShorkey="showModalConfirmDeletion"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters">
        <FilterPerDay v-if="isPerDay" ref="FilterPerDay" />
        <FilterPerPeriod v-else ref="FilterPerPeriod" />
      </div>

      <div v-if="mutableExamList.length > 0" :class="searchClass">
        <div class="content-grid">
          <legend class="legend">Profissionais</legend>

          <div class="table-one">
            <SmartTable
              ref="smartTableEmployee"
              :name="getSmartTableName + 'Professional'"
              :columns="COLUMN_TABLE_ONE"
              :body="mutableExamList"
              :total="Number(mutableExamList.length)"
              :initial-columns="INITIAL_COLUMNS"
              :show-pagination="false"
              :isOpaque="hasVacancyUnavailable"
              :colorLine="actionColorLine"
              index-column="eea_id"
              second-index-column="data_exame"
              toggleSelected
              @getLine="selectEmployeeData"
            >
              <div slot="legends" class="captions">
                <div class="circle --orange" />
                <span class="caption">Procedimento inabilitado</span>

                <div class="circle --green" />
                <span class="caption">Sem procedimento associado</span>

                <div class="circle --grey" />
                <span class="caption">Sem vagas disponíveis</span>

                <div class="circle --red" />
                <span class="caption">Inabilitado no dia</span>
              </div>
            </SmartTable>
          </div>

          <div class="painel-button">
            <RgNewScheduleButton
              id="new-schedule"
              :permission="hasSchedulePermission"
              :disabled="!scheduleSelected || scaleActiveInactive"
              title="Agendar exame"
              class="buttons-top"
              @click="actionSchedule"
            />

            <RgEditButton
              id="edit-scale"
              :disabled="!scheduleSelected || scaleActiveInactive"
              :permission="hasViewScalePermission || hasEditScalePermission"
              title="Editar Escala de Atendimento"
              class="buttons-top"
              @click="actionOpenModalChangeProfessionalScale"
            />
            <SmallButton
              id="inactive"
              title="Inabilitar"
              class="buttons-top"
              :backgroundColor="'#1e88a9'"
              :permission="hasActiveInactiveSchedulePermission"
              :disabled="!rulesInactive"
              @click="openModalActiveInactive(true)"
            >
              <IconInactiveSchedule
                slot="icon"
                :disabled="
                  !rulesInactive || hasActiveInactiveSchedulePermission
                "
              />
            </SmallButton>
            <SmallButton
              id="active"
              title="Habilitar"
              class="buttons-top"
              :permission="hasActiveInactiveSchedulePermission"
              :backgroundColor="'#1e88a9'"
              :disabled="!rulesActive"
              @click="openModalActiveInactive(false)"
            >
              <IconActiveSchedule
                slot="icon"
                :disabled="!rulesActive || hasActiveInactiveSchedulePermission"
              />
            </SmallButton>

            <RgShowButton
              id="show-reason"
              class="buttons-top"
              title="Detalhes Escala Inativada"
              :disabled="
                !(
                  scheduleSelected &&
                  hasReasonActiveInactive &&
                  scaleActiveInactive
                )
              "
              @click="openModalReasonActiveInactive"
            />

            <RgDropdown
              id="printer-control-outpatient"
              :disabled="!this.hasScheduledPatient || scaleActiveInactive"
              :item="employeeData || {}"
              :action-options="itemActionOptionsControl(employeeData)"
              show-up
              show-left
              small
            >
              <IconPrinter slot="icon" />
            </RgDropdown>
          </div>

          <div class="separator" />

          <legend class="legend">Pacientes Agendados para a Escala</legend>

          <div class="table-two">
            <SmartTable
              ref="smartTableSchedule"
              :name="getSmartTableName + 'Patient'"
              :columns="COLUMN_TABLE_TWO"
              :body="mutableExamSchedule.data"
              :total="Number(mutableExamSchedule.total)"
              :initial-columns="7"
              :show-pagination="false"
              toggleSelected
              @getLine="getSchedulePatientScaleRow"
            />
          </div>

          <div class="painel-button">
            <RgEditButton
              id="edit-exam"
              :disabled="examSelected"
              :permission="hasEditSchedulePermission"
              class="buttons-top"
              title="Editar Agendamento"
              @click="actionEditExamAppointment"
            />

            <RgShowButton
              id="show-exam"
              :disabled="examSelected"
              class="buttons-top"
              title="Detalhes"
              @click="actionOpenModalExamDetails"
            />

            <RgDeleteScheduleButton
              id="delete-exam"
              :disabled="examSelected"
              :permission="hasDeleteSchedulePermission"
              title="Excluir Agendamento"
              class="buttons-top"
              @click="openModalConfirmSchedulingDeletion"
            />

            <RgHistoryButton
              id="history-attendances"
              :disabled="examSelected"
              :permission="hasPatientHistoryPermission"
              class="buttons-top"
              title="Histórico de Atendimentos"
              @click="patientCareHistory"
            />

            <RgPersonCardButton
              id="edit-patient"
              :disabled="examSelected"
              class="buttons-top"
              @click="actionPatientRecord"
            />

            <RgDropdown
              id="options-person"
              :disabled="examSelected"
              :item="employeeData || {}"
              :action-options="itemActionOptionsPerson(employeeData)"
              show-up
              show-left
              small
            >
              <IconPatientBioslab slot="icon" />
            </RgDropdown>

            <RgDropdown
              id="options-printer"
              :disabled="examSelected"
              :item="employeeData || {}"
              :action-options="itemActionOptionsPrinter(employeeData)"
              small
              show-up
              show-left
            >
              <IconPrinter slot="icon" />
            </RgDropdown>
          </div>
        </div>
      </div>

      <div v-else class="no-result">
        <IconEmpty />
        <span class="message">
          Não foram encontrados resultados para essa busca
        </span>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      :maxlength="250"
      @close="actionCloseModalConfirmDeletion"
      @reason="setReason"
      @reSearch="reSearchTables"
    />
    <ModalActiveDesactive
      id="modal-active-desactive"
      v-bind="propsModalActiveDesactive"
      @reason="setReason"
      @close="closeModalActiveDesactive"
    />

    <ModalReasonActiveInactive
      id="modal-active-desactive"
      :show="showModalReasonActiveInactive"
      :isActive="isActive"
      :scheduleInfo="employeeData"
      @close="closeModalReasonActiveInactive"
    />

    <ModalChangeProfessionalScale
      id="modal-change-professional-scale"
      ref="modalChangeProfessionalScale"
      :show="showModalChangeProfessionalScale"
      :snap="employeeData || {}"
      :canEdit="hasEditScalePermission"
      @reSearch="reSearchTables"
      @close="actionCloseModalChangeProfessionalScale"
      @error="handleErrorChangeProfessionalScale"
    />

    <ModalExamDetails
      id="modal-exam-details"
      :show="showModalExamDetails"
      :procedure="patientData ? patientData.stp_novo_nome_procedimento : ''"
      :pxe-id="patientData ? Number(patientData.pxe_id) : 0"
      :unity-health="patientData ? Number(patientData.uns_id) : 0"
      :exam-id="patientData ? Number(patientData.exa_id) : 0"
      @close="actionCloseModalExamDetails"
    />

    <ModalPatientImageInRow
      id="patient-image-in-row"
      ref="modalPatientImageInRow"
      :show="showModalPatientImageInRow"
      :fill-id="filId"
      :modId="examModuleId"
      hideButtons
      view-only
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      id="modal-types-print"
      ref="modalTypePrint"
      :show="showModalTypesPrint"
      :preparationSub="printPreparationSub"
      :exam-ids="[examIdToPrint]"
      @close="actionCloseModalTypesPrint"
    />

    <ModalScaleConflict
      id="modal-scale-conflict"
      ref="modalScaleConflict"
      :show="showModalScaleConflict"
      :conflicts="scaleConflicts"
      :history="scaleHistory"
      @close="actionCloseModalScaleConflict"
    />

    <ModalObservationQueue
      id="modal-observation-queue"
      :show="modalObservationQueue"
      :patientData="patientData"
      :modId="examModuleId"
      @close="closeModalObservationQueue"
    />
  </div>
</template>

<script>
import moment from "moment";
import { isEmpty } from "lodash";
import ConsultationControlPortraitHTML from "@/app/exam/submodules/schedule/html/ConsultationControlPortraitHTML";
import ConsultationControlLandscapeHTML from "@/app/exam/submodules/schedule/html/ConsultationControlLandscapeHTML";
import AppointmentListPortraitHTML from "@/app/exam/submodules/schedule/html/AppointmentListPortraitHTML";
import AppointmentListLandscapeHTML from "@/app/exam/submodules/schedule/html/AppointmentListLandscapeHTML";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import RecommendationsSheetHTML from "$exam/submodules/schedule/html/RecommendationsSheetHTML";

import {
  RgNewScheduleButton,
  RgDeleteScheduleButton,
  RgEditButton,
  IconPatientBioslab,
  RgPersonCardButton,
  RgShowButton,
  IconEmpty,
  IconPrinter,
  RgDropdown,
  RgHistoryButton,
  IconInactiveSchedule,
  IconActiveSchedule,
  SmallButton,
  ModalActiveDesactive,
  ModalReasonActiveInactive,
} from "~tokio/primitive";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import {
  ModalChangeProfessionalScale,
  ModalScaleConflict,
  ModalExamDetails,
  ModalTypesPrint,
} from "$exam/submodules/schedule/component/";

import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
  ModalObservationQueue,
} from "~tokio/primitive/modal";

import FilterPerDay from "$exam/submodules/schedule/component/main/component/filter/FilterPerDay";
import FilterPerPeriod from "$exam/submodules/schedule/component/main/component/filter/FilterPerPeriod";

import ExamMixinPerDay from "$exam/submodules/schedule/component/main/mixin/ExamMixinPerDay";
import ExamMixinPerPeriod from "$exam/submodules/schedule/component/main/mixin/ExamMixinPerPeriod";
import PermissionsMixin from "$exam/submodules/schedule/component/main/mixin/PermissionsMixin";

import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";

export default {
  name: "ExamSchedulePerDay",
  components: {
    RgSearch,
    SmartTable,
    RgNewScheduleButton,
    RgHistoryButton,
    RgEditButton,
    RgDropdown,
    IconPatientBioslab,
    IconPrinter,
    RgPersonCardButton,
    RgDeleteScheduleButton,
    ModalConfirmDeletion,
    ModalChangeProfessionalScale,
    ModalPatientImageInRow,
    ModalTypesPrint,
    ModalScaleConflict,
    FilterPerDay,
    FilterPerPeriod,
    RgShowButton,
    ModalExamDetails,
    IconEmpty,
    ModalObservationQueue,
    IconInactiveSchedule,
    IconActiveSchedule,
    SmallButton,
    ModalActiveDesactive,
    ModalReasonActiveInactive,
  },
  mixins: [ExamMixinPerDay, ExamMixinPerPeriod, PermissionsMixin],
  props: {
    isPerDay: Boolean,
  },

  data() {
    return {
      showModalChangeProfessionalScale: false,
      mutableExamList: [],
      employeeData: null,
      payloadFilterExamSchedule: {},
      patientData: null,
      filId: null,
      totalAgenda: 0,
      procedureId: "",
      modalChangeProfessionalScale: false,
      eapId: null,
      showModalConfirmDeletion: false,
      showModalPatientImageInRow: false,
      showModalExamDetails: false,
      showModalTypesPrint: false,
      printPreparationSub: false,
      showModalScaleConflict: false,
      modalObservationQueue: false,
      showModalActiveInactive: false,
      showModalReasonActiveInactive: false,
      isActive: false,
      examIdToPrint: null,
      mutableExamSchedule: {
        data: [],
        total: 0,
      },
      form: {
        selectedUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        selectedSector: null,
        exe_codigo_procedimento: null,
        selectedScale: null,
        date: moment().format("DD/MM/YYYY"),
        selectedHealthPlan: null,
      },
      routersNotResetFilterPerDay: [
        "exam.schedule.per-day.form",
        "exam.schedule.per-day.patient",
        "exam.schedule.per-day.edit.form",
      ],
      routersNotResetFilterPerPeriod: [
        "exam.schedule.per-period.edit.form",
        "exam.schedule.per-period.patient",
        "exam.schedule.per-period.form",
      ],
      scaleConflicts: [],
      scaleHistory: [],
      examPermission: [],
    };
  },

  computed: {
    rulesInactive() {
      return (
        this.scheduleSelected &&
        this.scaleCurrentAndFutureDates &&
        this.scaleWithoutScheduledPatient &&
        !this.scaleActiveInactive &&
        !this.isHolidayOrBreak
      );
    },
    rulesActive() {
      return this.scheduleSelected && this.scaleActiveInactive;
    },
    hasReasonActiveInactive() {
      return this.employeeData?.motivo_inabilitar !== "";
    },

    isHolidayOrBreak() {
      return this.employeeData?.motivo_inabilitar.match(/feriado|recesso/i);
    },
    scaleActiveInactive() {
      return (
        parseInt(this.employeeData?.funcionario_inabilitado) === 1 ||
        parseInt(this.employeeData?.eha_atendimento) === 0
      );
    },
    actionColorLine() {
      return {
        get: (item) => {
          if (
            parseInt(item.funcionario_inabilitado) === 1 ||
            parseInt(item.eha_atendimento) === 0
          ) {
            return GetColorRowSmartTable("red");
          }
          if (parseInt(item.eea_quantidade_restantes) === 0) {
            return GetColorRowSmartTable("grey");
          }
          if (parseInt(item.vinculo_procedimento_ativo) === 0) {
            return GetColorRowSmartTable("orange");
          }
          if (parseInt(item.possuiProcedimento) === 0) {
            return GetColorRowSmartTable("green");
          }
        },
      };
    },

    getSmartTableName() {
      return `ExamSchedule${this.isPerDay ? "PerDay" : "PerPeriod"}`;
    },

    examModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },

    hasVacancyUnavailable() {
      return {
        check: (row) => {
          if (row.eea_quantidade_restantes) {
            return parseInt(row.eea_quantidade_restantes) <= 0;
          }
        },
      };
    },

    mainClass() {
      return this.isPerDay
        ? "exam-schedule-per-day"
        : "exam-schedule-per-period";
    },

    searchClass() {
      return this.isPerDay
        ? "exam-schedule-per-day-search"
        : "exam-schedule-per-period-search";
    },

    selectedEmployee() {
      return this.$store.getters["Exam/Schedule/GET_SELECTED_EMPLOYEE"];
    },

    examSelected() {
      return !this.employeeData || !this.patientData;
    },

    scheduleSelected() {
      return this.employeeData !== null;
    },

    hasFreeSchedule() {
      return (
        this.employeeData &&
        Number(this.employeeData.eea_quantidade_restantes) > 0
      );
    },

    hasScheduledPatient() {
      return (
        this.mutableExamSchedule.data &&
        this.mutableExamSchedule.data.length > 0
      );
    },

    appointmentIdToRemove() {
      return this.patientData && this.patientData.exa_id
        ? Number(this.patientData.exa_id)
        : 0;
    },

    canSchedulePatient() {
      return this.scheduleSelected && this.hasPermissionsToSchedule();
    },

    canEditSchedulePatient() {
      return !this.examSelected && this.hasPermissionsToEditSchedule();
    },

    printOptionsPreference() {
      return this.isPerDay
        ? this.$store.getters["Login/GET_PREFERENCES"][
            "tViewBuscaAgendamentoDia.opcoesImpressao"
          ]
        : this.$store.getters["Login/GET_PREFERENCES"][
            "tViewBuscaAgendamentoPeriodo.opcoesImpressao"
          ];
    },

    propsModalConfirmDeletion() {
      const show = this.showModalConfirmDeletion;

      const confirm = () => this.actionDeleteSchedule();

      const msgSuccess = "Agendamento removido com sucesso!";

      return { show, confirm, msgSuccess };
    },
    propsModalActiveDesactive() {
      const show = this.showModalActiveInactive;
      let title = null;
      let subtitle = null;
      let msgSuccess = null;
      if (this.isActive) {
        title = "Inabilitar Escala";
        subtitle = "Selecione o motivo para inabilitar a escala selecionada";
        msgSuccess = "Escala inativada com sucesso";
      } else {
        title = "Habilitar Escala";
        msgSuccess = "Escala habilitada com sucesso";
      }
      const confirm = () => this.actionActiveInactiveSchedule();
      const textReason = this.employeeData?.motivo_inabilitar;
      const bodyText = `Tipo de escala: ${this.employeeData?.ees_nome} <br/>
                      Profissional: ${this.employeeData?.fun_nome} <br/>
                      Tipo de horário: ${this.employeeData?.eti_nome} <br/>
                      Início: ${this.employeeData?.inicio}`;
      const isActive = this.isActive;

      const labelBtn = "Salvar";
      const justifyContent = false;
      const isSchedule = true;

      return {
        show,
        title,
        subtitle,
        msgSuccess,
        confirm,
        textReason,
        bodyText,
        isActive,
        labelBtn,
        justifyContent,
        isSchedule,
      };
    },

    hasSchedulePermission() {
      if (!this.employeeData || this.examPermission.length === 0) return false;

      const typeScale = this.employeeData.eea_id_exames_escala;

      const hasScheduleRetroactivePerDayPermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "agendamentoRetroativo",
      );

      const hasScheduleFuturePerDayPermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "agendamentoFuturo",
      );

      const hasSchedulePerPeriodPermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "agendamentoPorPeriodo",
      );

      const filter = this.$store.getters[
        "Exam/Schedule/GET_FILTER_EXAM_PER_DAY"
      ];

      const now = moment(new Date()).format("YYYY-MM-DD");
      const filterDate = (filter && moment(filter.date, "DD/MM/YYYY")) || null;

      const hasRetroactiveScheduleDate =
        (filterDate && filterDate.isBefore(moment(now))) || false;

      const hasPerDaySchedulePermission =
        this.isPerDay && hasRetroactiveScheduleDate
          ? hasScheduleRetroactivePerDayPermission.length > 0
          : hasScheduleFuturePerDayPermission.length > 0 || false;

      const hasPerPeriodSchedulePermission =
        (!this.isPerDay && hasSchedulePerPeriodPermission.length > 0) || false;

      return this.isPerDay
        ? hasPerDaySchedulePermission
        : hasPerPeriodSchedulePermission;
    },

    hasViewScalePermission() {
      return !!this.$Permissions.sector.has(
        "exame2.alteracaoDadosDia.exibir",
        this.employeeData?.set_id,
      );
    },

    hasEditScalePermission() {
      if (!this.employeeData || !this.examPermission) return false;

      const typeScale = this.employeeData.eea_id_exames_escala;

      const hasEditScalePermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "alterarDadosDoDia",
      );

      return hasEditScalePermission.length > 0 || false;
    },

    hasEditSchedulePermission() {
      if (!this.employeeData || !this.examPermission) return false;

      const typeScale = this.employeeData.eea_id_exames_escala;

      const hasPerDayEditSchedulePermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "alterarPorDia",
      );

      const hasPerPeriodEditSchedulePermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "alterarPorPeriodo",
      );

      return this.isPerDay
        ? hasPerDayEditSchedulePermission.length > 0
        : hasPerPeriodEditSchedulePermission.length > 0;
    },
    hasActiveInactiveSchedulePermission() {
      if (!this.employeeData || !this.examPermission) return false;

      const typeScale = this.employeeData.eea_id_exames_escala;

      const hasPerDayEditSchedulePermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "habilitarInabilitar",
      );

      return hasPerDayEditSchedulePermission.length > 0;
    },

    hasDeleteSchedulePermission() {
      if (!this.employeeData || this.examPermission.length === 0) return false;

      const typeScale = this.employeeData.eea_id_exames_escala;

      const hasExcludeScheduleRetroactivePerDayPermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "exclusaoRetroativa",
      );

      const hasExcludeScheduleFuturePerDayPermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "exclusaoFutura",
      );

      const hasExcludeSchedulePerPeriodPermission = this.examPermission.filter(
        (item) =>
          Number(item.epe_id_escala) === Number(typeScale) &&
          item.eoe_nome === "excluirPorPeriodo",
      );

      const filter = this.$store.getters[
        "Exam/Schedule/GET_FILTER_EXAM_PER_DAY"
      ];

      const now = moment(new Date()).format("YYYY-MM-DD");
      const filterDate = (filter && moment(filter.date, "DD/MM/YYYY")) || null;

      const hasRetroactiveScheduleDate =
        (filterDate && filterDate.isBefore(moment(now))) || false;

      const hasExcludePerDaySchedulePermission =
        this.isPerDay && hasRetroactiveScheduleDate
          ? hasExcludeScheduleRetroactivePerDayPermission.length > 0
          : hasExcludeScheduleFuturePerDayPermission.length > 0 || false;

      const hasExcludePerPeriodSchedulePermission =
        (!this.isPerDay && hasExcludeSchedulePerPeriodPermission.length > 0) ||
        false;

      return this.isPerDay
        ? hasExcludePerDaySchedulePermission
        : hasExcludePerPeriodSchedulePermission;
    },

    hasPatientHistoryPermission() {
      return !!this.$Permissions.sector.has(
        "cadastro.prontuario.historicoGeralPaciente",
        this.employeeData?.set_id,
      );
    },

    hasViewPatientObservation() {
      return !!this.$Permissions.sector.has(
        "exame2.filaExame.exibirObservacao",
        this.employeeData?.set_id,
      );
    },

    scaleWithoutScheduledPatient() {
      return Number(this.employeeData?.eea_quantidade_marcadas) === 0;
    },
    scaleCurrentAndFutureDates() {
      return moment(this.employeeData?.data_exame, "DD/MM/YYYY").isSameOrAfter(
        moment(),
        "day",
      );
    },
  },

  created() {
    this.INITIAL_COLUMNS = 8;

    const isPerPeriod = !this.isPerDay;

    if (isPerPeriod) {
      this.COLUMN_TABLE_ONE = [
        { name: "Profissional", key: "fun_nome" },
        { name: "Ocupação", key: "ocp_nome" },
        { name: "Dia da Semana", key: "dsm_nome" },
        { name: "Início", key: "inicio" },
        { name: "Qtd. de Horários", key: "eea_quantidade_exames" },
        { name: "Qtd. Ocupada", key: "eea_quantidade_marcadas" },
        { name: "Qtd. Livre", key: "eea_quantidade_restantes" },
        { name: "Tipo de Escala", key: "ees_nome" },
        { name: "Tipo de Horário", key: "eti_nome" },
      ];
    } else {
      this.COLUMN_TABLE_ONE = [
        { name: "Profissional", key: "fun_nome" },
        { name: "Ocupação", key: "ocp_nome" },
        { name: "Início", key: "inicio" },
        { name: "Qtd. de Horários", key: "eea_quantidade_exames" },
        { name: "Qtd. Ocupada", key: "eea_quantidade_marcadas" },
        { name: "Qtd. Livre", key: "eea_quantidade_restantes" },
        { name: "Tipo de Escala", key: "ees_nome" },
        { name: "Tipo de Horário", key: "eti_nome" },
      ];
    }

    this.COLUMN_TABLE_TWO = [
      { name: "Hora", key: "eap_hora" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Procedimento", key: "stp_novo_nome_procedimento" },
      { name: "Subprocedimentos", key: "subProcedimento" },
      { name: "Vagas Ocupadas", key: "eap_vagas_ocupadas" },
      { name: "Status Meu Esus", key: "sme_nome" },
    ];

    if (isPerPeriod) {
      this.COLUMN_TABLE_ONE.splice(2, 0, { name: "Data", key: "data_exame" });
      this.INITIAL_COLUMNS = 9;
    }
  },

  destroyed() {
    this.cleanVuex();
  },

  mounted() {
    const isExamSchedule = this.$route.params.isExamSchedule;

    if (isExamSchedule) {
      this.handleExamSchedulePrint(this.$route.params.examId);
    }

    this.fillFilter();
  },

  methods: {
    openModalActiveInactive(isActive) {
      if (this.isHolidayOrBreak) {
        const motivo = this.employeeData?.motivo_inabilitar;
        this.$toaster.info(
          `Motivo: ${motivo}`,
          "Profissional inabilitado neste dia",
        );
        return;
      }
      this.showModalActiveInactive = true;
      this.isActive = isActive;
    },
    closeModalActiveDesactive() {
      this.showModalActiveInactive = false;
      this.isActive = false;
    },
    openModalReasonActiveInactive() {
      this.showModalReasonActiveInactive = true;
    },
    closeModalReasonActiveInactive() {
      this.showModalReasonActiveInactive = false;
    },
    cleanVuex() {
      if (!this.isPerDay) {
        const foundRoute = this.findRouterNotResetFilter(this.$route.name);

        if (!foundRoute) {
          this.removeFilterPerPeriod();
        }

        const perDayExist = ValidateIfRouteExistInBreadscrumb(
          this.routePerDay,
          this.$route.meta.breadcrumb,
        );

        if (perDayExist) {
          this.$store.commit("Exam/Schedule/RESET_FILTER_DATE");
          this.$store.commit("Exam/Schedule/RESET_FILTER_EXAMS_PER_DAY");

          this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");
          this.$store.commit("Exam/Schedule/UNSELECT_EMPLOYEE_DATA");
        }
      } else {
        const foundRoute = this.findRouterNotResetFilter(this.$route.name);

        if (!foundRoute) {
          this.removeFilterPerDay();
        }
        const perPeriodExist = ValidateIfRouteExistInBreadscrumb(
          this.routePerPeriod,
          this.$route.meta.breadcrumb,
        );

        if (perPeriodExist) {
          this.$store.commit("Exam/Schedule/RESET_FILTER_EXAMS_PER_PERIOD");

          this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");
          this.$store.commit("Exam/Schedule/UNSELECT_EMPLOYEE_DATA");
        }
      }
    },

    async reSearchTables() {
      try {
        await this.$refs.RgSearch.performSearch();

        if (this.selectedEmployee) {
          if (this.$refs.smartTableEmployee) {
            this.$refs.smartTableEmployee.selectRow(this.selectedEmployee);
            await this.selectEmployeeData(this.selectedEmployee, true);
          }
        }
      } catch (pError) {
        this.$toaster.error(pError);
      }
    },

    async printControl(htmlFunction) {
      try {
        this.$loader.start();
        const client = this.$store.getters["Login/GET_CLIENT_CODE"];
        const employee = { client, ...this.employeeData };

        const variable = {
          eha_id: employee.eha_id,
          profissional: employee.profissional,
        };

        const data = await this.$store.dispatch(
          "Exam/Schedule/GET_PRINT_CONSULTATION_CONTROL",
          variable,
        );

        const html = htmlFunction(employee, data);

        this.$utils.print.printHtml(html);
      } catch (pError) {
        this.$toaster.error(
          "Erro ao gerar impressão",
          this.$utils.sanitize.formatError(pError),
        );
      } finally {
        this.$loader.finish();
      }
    },

    async actionPrintControlPortrait() {
      await this.printControl(ConsultationControlPortraitHTML);
    },

    async actionPrintControlLandscape() {
      await this.printControl(ConsultationControlLandscapeHTML);
    },
    async actionPrintSchedulesPortrait() {
      await this.printControl(AppointmentListPortraitHTML);
    },

    async actionPrintSchedulesLandscape() {
      await this.printControl(AppointmentListLandscapeHTML);
    },

    generateFilter() {
      const filter = this.isPerDay
        ? this.$refs.FilterPerDay.getForm()
        : this.$refs.FilterPerPeriod.getForm();

      const variables = this.isPerDay
        ? this.$refs.FilterPerDay?.getVariables()
        : this.$refs.FilterPerPeriod?.getVariables();

      this.setFilterOnStore(filter);

      return variables;
    },

    setFilterOnStore(filter) {
      const path = this.isPerDay
        ? "Exam/Schedule/SET_FILTER_EXAMS_PER_DAY"
        : "Exam/Schedule/SET_FILTER_EXAMS_PER_PERIOD";

      this.$store.commit(path, filter);
    },

    async actionSearchFilter(pData) {
      try {
        const path = this.isPerDay
          ? "Exam/Schedule/SEARCH_EMPLOYEE_AGENDA"
          : "Exam/Schedule/SEARCH_EMPLOYEE_SCHEDULE_PERIOD";
        const data = await this.$store.dispatch(path, pData);

        const filter = this.$store.getters[
          this.isPerDay
            ? "Exam/Schedule/GET_FILTER_EXAM_PER_DAY"
            : "Exam/Schedule/GET_FILTER_EXAM_PER_PERIOD"
        ];

        const filterSector = this.isPerDay
          ? Number(filter.selectedSector)
          : Number(filter.sector);

        const variables = {
          usuId: this.$store.getters["Login/GET_USER_ID"],
          setId: filterSector,
        };

        this.examPermission = await this.$store.dispatch(
          "Exam/Schedule/SEARCH_EXAM_PERMISSION",
          variables,
        );

        if (!data) this.mutableExamList = [];

        return data;
      } catch (pErr) {
        const error = pErr.toString();
        if (error.includes("Error:")) {
          this.$toaster.warning(
            error.substring(6, error.length),
            "Erro ao buscar agendamentos",
          );
        } else {
          this.$toaster.error(pErr, "Erro ao buscar agendamentos");
        }
      }
    },

    actionAfterSearchFilter(submitFromButton) {
      if (this.$refs.smartTableSchedule) {
        this.$refs.smartTableSchedule.cleanSelectRow();
      }
      if (this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.cleanSelectRow();
      }
      this.mutableExamSchedule = {
        data: [],
        total: 0,
      };
      this.employeeData = null;

      if (submitFromButton && this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.setScrollTopZero();
      }
    },

    generateFilterExamSchedule(idHistorySchedule) {
      const filter = this.isPerDay
        ? this.$refs.FilterPerDay.getForm()
        : this.$refs.FilterPerPeriod.getForm();

      const variables = {
        arrFormData: {
          uns_id: filter.selectedUnity || filter.unit,
          set_id: filter.selectedSector || filter.sector,
          ees_id: filter.selectedScale,
          data_inicial: filter.date || moment().format("DD/MM/YYYY"),
          eha_id: Number(idHistorySchedule),
        },
      };
      return variables;
    },

    async searchFilterExamsSchedule() {
      try {
        this.mutableExamSchedule = await this.$store.dispatch(
          "Exam/Schedule/SEARCH_APPOINTMENT_SCHEDULE",
          this.payloadFilterExamSchedule,
        );

        if (this.isPerDay) {
          const filter = this.$refs.FilterPerDay?.getVariables();
          if (filter?.arrFormData.stp_id) {
            this.mutableExamSchedule.data = this.mutableExamSchedule.data.filter(
              (el) => {
                return (
                  parseInt(el.stp_id) === parseInt(filter.arrFormData.stp_id)
                );
              },
            );
          }
        }

        this.$store.commit(
          "Exam/Schedule/SET_EXAM_SCHEDULE",
          this.mutableExamSchedule,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      }
    },

    actionPatientRecord() {
      const patientId = this.patientData.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(patientId));

      const routerName = this.isPerDay ? this.perDay : this.perPeriod;

      this.$router.push({
        name: `exam.schedule.${routerName}.patient`,
      });
    },

    async selectEmployeeData(pData) {
      try {
        if (pData) {
          const index = this.mutableExamList.findIndex((row) => {
            return (
              row.eea_id === pData.eea_id && row.data_exame === pData.data_exame
            );
          });
          this.employeeData = this.mutableExamList[index];

          this.$store.commit(
            "Exam/Schedule/SELECT_EMPLOYEE_DATA",
            this.employeeData,
          );

          this.cleanAppointmentList();
          if (
            this.employeeData?.eha_id &&
            Number(this.employeeData?.eea_quantidade_marcadas) > 0
          ) {
            this.$loader.start();
            this.payloadFilterExamSchedule = this.generateFilterExamSchedule(
              this.employeeData.eha_id,
            );

            await this.searchFilterExamsSchedule();
          }
        } else {
          this.$store.commit("Exam/Schedule/UNSELECT_EMPLOYEE_DATA");
          this.employeeData = null;
          this.cleanAppointmentList();
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao selecionar os agendamentos", pErr);
      } finally {
        this.$loader.finish();
      }
    },

    getSchedulePatientScaleRow(pValue) {
      this.$store.commit("Exam/Schedule/SELECT_PATIENT_DATA", pValue);

      this.patientData = pValue;
      this.filId = (pValue && Number(pValue.fil_id)) || null;
    },

    getCountValue(pValue) {
      this.totalAgenda = Number(pValue) || 0;
    },

    actionSchedule() {
      if (!this.hasFreeSchedule) {
        return this.$toaster.warning(
          "Profissional não tem mais vagas disponíveis!",
          "Sem vagas",
        );
      }

      if (this.employeeData && this.employeeData.possuiProcedimento === "0") {
        return this.$toaster.warning(
          "A escala não pode ser usada pois este profissional não possui um procedimento associado a ele",
        );
      }

      this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");

      this.$store.commit(
        "Exam/Schedule/SELECT_EMPLOYEE_DATA",
        this.employeeData,
      );

      const routeName = this.isPerDay
        ? "exam.schedule.per-day.form"
        : "exam.schedule.per-period.form";

      this.$router.push({ name: routeName });
    },

    validateProcedure(pItem, pErrors) {
      if (this.validateEmployee({}, pErrors) && isEmpty(pItem)) {
        pErrors.push("Campo obrigatório");
      }

      return pErrors.length <= 0;
    },

    itemActionOptionsControl(pItem) {
      return [
        {
          label: "Lista de agendamentos (Retrato)",
          action: () => {
            this.actionPrintSchedulesPortrait();
          },
        },
        {
          label: "Lista de agendamentos (Paisagem)",
          action: () => {
            this.actionPrintSchedulesLandscape();
          },
        },
        {
          label: "Ficha de controle amb. (Retrato)",
          action: () => {
            this.actionPrintControlPortrait();
          },
        },
        {
          label: "Ficha de controle amb. (Paisagem)",
          action: () => {
            this.actionPrintControlLandscape();
          },
        },
      ];
    },

    itemActionOptionsPerson(pItem) {
      return [
        {
          label: "Arquivos do paciente na fila",
          action: this.actionOpenModalPatientImageInRow,
          disable: false,
        },
        {
          label: "Observações do paciente na fila",
          action: this.actionObservationPatient,
          disable: !this.hasViewPatientObservation,
        },
      ];
    },

    itemActionOptionsPrinter(pItem) {
      if (!this.patientData) {
        return;
      }

      return [
        {
          label: "Imprimir comprovante e recomendações",
          action: () => {
            this.examIdToPrint = this.patientData.eap_id;
            this.actionOpenModalTypesPrint(true);
          },
          disable: false,
        },
        {
          label: "Imprimir comprovante de agendamento",
          action: () => {
            this.examIdToPrint = this.patientData.eap_id;
            this.actionOpenModalTypesPrint(false);
          },
          disable: false,
        },
        {
          label: "Imprimir recomendações e preparo do exame",
          action: this.actionPrintRecommendationsSheet,
          disable: false,
        },
      ];
    },

    actionOpenModalTypesPrint(pValue = false) {
      this.showModalTypesPrint = true;
      this.printPreparationSub = pValue;
    },

    actionCloseModalTypesPrint() {
      this.showModalTypesPrint = false;
      this.printPreparationSub = false;
    },

    actionCloseModalScaleConflict() {
      this.showModalScaleConflict = false;
    },

    actionOpenModalScaleConflict() {
      this.showModalScaleConflict = true;
    },

    openModalObservationQueue() {
      this.modalObservationQueue = true;
    },

    closeModalObservationQueue() {
      this.modalObservationQueue = false;
    },

    patientCareHistory() {
      const patientInfo = this.patientData;
      const routeName = this.isPerDay
        ? "exam.schedule.per-day.patient-care-history"
        : "exam.schedule.per-period.patient-care-history";

      this.$router.push({
        name: routeName,
        params: patientInfo,
      });
    },

    itemActionOptionsPep(pItem) {
      return [
        {
          label: "Enviar atendimento para o PEP",
          disable: false,
        },
        {
          label: "Cancelar atendimento no PEP",
          disable: false,
        },
      ];
    },

    actionObservationPatient() {
      if (this.filId) {
        this.openModalObservationQueue();
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação",
        );
      }
    },

    actionOpenModalPatientImageInRow() {
      if (this.filId) {
        this.showModalPatientImageInRow = true;
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação",
        );
      }
    },
    closeModalPatientImageInRow() {
      this.showModalPatientImageInRow = false;
    },

    async actionPrintRecommendationsSheet() {
      const data = {
        client: this.$store.getters["Login/GET_CLIENT_CODE"],
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_NAME"],
        hour: moment().format("HH:mm"),
        date: moment().format("DD/MM/YYYY"),
      };

      try {
        const withoutSubProcedure = this.patientData.subProcedimento === "NÃO";

        const path = withoutSubProcedure
          ? "Exam/Schedule/GET_PRINT_PROCEDURE_RECOMMENDATIONS_SHEET"
          : "Exam/Schedule/GET_PRINT_SUB_PROCEDURE_RECOMMENDATIONS_SHEET";

        const result = await this.$store.dispatch(path, {
          intIdExameAgendamentoProcedimento: this.patientData.eap_id,
        });

        const hasRecomendation =
          (result.length > 0 && result[0].possuiRecomendacao === "1") ||
          (result && result.possuiRecomendacao === "1");

        if (hasRecomendation) {
          const html = RecommendationsSheetHTML(result, data);
          this.$utils.print.printHtml(html);
        } else {
          this.$toaster.warning(`Não existem recomendações para este exame`);
        }
      } catch (error) {
        this.$toaster.error(error);
      }
    },

    openModalConfirmSchedulingDeletion() {
      this.showModalConfirmDeletion = true;
    },

    actionOpenModalExamDetails() {
      this.showModalExamDetails = true;
    },

    actionCloseModalExamDetails() {
      this.showModalExamDetails = false;
    },

    cleanAppointmentList() {
      this.patientData = null;
      this.$refs.smartTableSchedule.cleanSelectRow();
      this.mutableExamSchedule = {
        data: [],
        total: 0,
      };
      this.$store.commit("Exam/Schedule/UNSELECT_PATIENT_DATA");
    },
    actionCleanForm() {
      this.mutableExamList = [];
      this.employeeData = null;
      this.patientData = null;
      this.payloadFilterExamSchedule = {};
      this.mutableExamSchedule = {
        data: [],
        total: 0,
      };
      this.totalAgenda = 0;
      this.procedureId = "";

      this.isPerDay
        ? this.$refs.FilterPerDay.cleanFilter()
        : this.$refs.FilterPerPeriod.cleanFilter();

      if (this.$refs.smartTableEmployee)
        this.$refs.smartTableEmployee.cleanSelectRow();

      if (this.$refs.smartTableSchedule)
        this.$refs.smartTableSchedule.cleanSelectRow();

      this.cleanVuex();
    },

    actionCloseModalConfirmDeletion() {
      this.showModalConfirmDeletion = false;
    },

    setReason(value) {
      this.reason = value;
    },

    async actionDeleteSchedule() {
      try {
        this.$loader.start("Excluindo...");
        const variables = {
          intIdExameAgendamentoProcedimento: this.patientData.eap_id,
          strMotivo: this.reason,
        };
        await this.$store.dispatch("Exam/Schedule/REMOVE_EXAM", variables);

        this.$toaster.success("Exclusão realizada com sucesso");
        await this.searchFilterExamsSchedule();
        this.clearSelectionSchedule();
      } catch (err) {
        this.$toaster.error(err.message);
      } finally {
        this.$loader.finish();
      }
    },
    async actionActiveInactiveSchedule() {
      try {
        this.$loader.start();
        const path = `Exam/Schedule/ENABLE_DISABLE_PERIODS`;
        const idsHollidays = [16, 11, 10, 18];
        const isHolliday =
          idsHollidays.includes(Number(this.reason)) ||
          this.reason.match(/feriado|recesso/i);

        const auxArr = [];
        if (isHolliday) {
          this.mutableExamList.forEach((item) => {
            if (
              moment(item.data_exame, "DD/MM/YYYY").isSame(
                moment(this.employeeData.data_exame, "DD/MM/YYYY"),
              )
            ) {
              const exists = auxArr.some((elem) => elem.vus_id === item.vus_id);

              if (!exists) {
                auxArr.push({ set_id: item.set_id, vus_id: item.vus_id });
              }
            }
          });
        } else {
          auxArr.push({
            set_id: this.employeeData.set_id,
            vus_id: this.employeeData.vus_id,
          });
        }

        const variables = {
          arrVinculosSetores: auxArr,
          strDataInicial: this.employeeData?.data_exame,
          strDataFinal: this.employeeData?.data_exame,
          strOperacao: this.isActive ? "INABILITAR" : "HABILITAR",
          intIdObservacao: this.isActive ? this.reason : 0,
          strHoraInicio: isHolliday
            ? null
            : this.employeeData?.eea_inicio_atendimentos,
          strHoraFinal: isHolliday
            ? null
            : this.employeeData?.eea_final_atendimentos,
        };

        await this.$store.dispatch(path, variables);

        this.$toaster.success(
          `Período ${this.isActive ? "inabilitado" : "habilitado"} com sucesso`,
        );
        await this.$refs.RgSearch.performSearch(false, false);
        if (this.$refs.smartTableEmployee) {
          this.$refs.smartTableEmployee.cleanSelectRow();
        }
        this.employeeData = null;
        this.$store.commit("Exam/Schedule/UNSELECT_EMPLOYEE_DATA");
      } catch (err) {
        this.$toaster.error(
          `Erro ao ${this.isActive ? "inabilitar" : "habilitar"} período`,
          this.$utils.sanitize.formatError(err),
        );
      } finally {
        this.$loader.finish();
      }
    },

    actionCloseModalChangeProfessionalScale() {
      this.showModalChangeProfessionalScale = false;
    },

    actionOpenModalChangeProfessionalScale() {
      this.showModalChangeProfessionalScale = true;
    },

    actionEditExamAppointment() {
      const routeName = this.isPerDay
        ? "exam.schedule.per-day.edit.form"
        : "exam.schedule.per-period.edit.form";

      this.$router.push({ name: routeName });
    },

    hasPermissionsToEditSchedule() {
      // const isReservaTecnica = this.employeeData.cte_id === "2";
      // if (isReservaTecnica) {
      //   return this.$Permissions.sector.has(
      //     this.permissions.agendamentoAlterarAgendamentoDiaReservaTecnica,
      //     this.employeeData.set_id,
      //   );
      // } else {
      //   return this.$Permissions.sector.has(
      //     this.permissions.agendamentoAlterar,
      //     this.employeeData.set_id,
      //   );
      // }
      return true;
    },

    async fillFilter() {
      const complete = this.isPerDay
        ? await this.$refs.FilterPerDay.fillFilter()
        : await this.$refs.FilterPerPeriod.fillFilter();
      if (complete) {
        this.reSearchTables();
      }
    },

    async handleExamSchedulePrint(examId) {
      this.examIdToPrint = examId;
      this.actionOpenModalTypesPrint();
    },

    clearSelectionSchedule() {
      this.patientData = null;
      this.$refs.smartTableSchedule.cleanSelectRow();
    },

    findRouterNotResetFilter(pRouteName) {
      const routers = this.isPerDay
        ? this.routersNotResetFilterPerDay
        : this.routersNotResetFilterPerPeriod;

      const foundRoute = !!routers.find((ele) => ele === pRouteName);

      return foundRoute;
    },

    handleErrorChangeProfessionalScale(pData) {
      const CONFLICT_SCALE_EXCEPTION =
        "ExameConflitoAlteracaoDePeriodoHorarioException";

      const isConflictScaleError = pData.exception === CONFLICT_SCALE_EXCEPTION;

      if (isConflictScaleError) {
        const exceptionDetails = Object.assign({}, pData.detalhes_exececao);
        const hasConflictsData = exceptionDetails.escalas?.length > 0;
        const hasHistoryData = exceptionDetails.historicos?.length > 0;

        this.scaleConflicts = hasConflictsData ? exceptionDetails.escalas : [];

        this.scaleHistory = hasHistoryData ? exceptionDetails.historicos : [];

        this.actionOpenModalScaleConflict();
      } else {
        this.$toaster.error(pData);
      }
    },
  },
};
</script>
