<template>
  <div class="emergency-administrative-checkout-out-search">
    <RgSearch
      ref="rgSearch"
      v-model="mutableAdministrativeCheckOut"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :max-register="30"
      :predefined-limit="30"
      :result-title="'Alta Administrativa'"
      :show-empty-message="true"
      :showFooter="hasAdministrativeCheckOut"
      @submitFromButton="actionCleanSelection"
      @viewClickPagination="actionCleanSelection"
      @count="getCountValue"
      @afterPerformSearch="afterSearchFilter"
    >
      <div
        slot="filters"
        class="emergency-administrative-checkout-out-search-filter"
      >
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="unitHealth"
              v-model="form.unitHealthId"
              :rules="{ required: true }"
              label="Unidade de Saúde"
              disabled
            />
          </div>

          <div class="selectinput">
            <RgSelectSector
              id="sector"
              v-model="form.sectorId"
              :unitHealth="Number(form.unitHealthId)"
            />
          </div>
          <div class="selectinput">
            <RgInput
              id="patient"
              v-model="form.patientName"
              :rules="{ fn: validateName }"
              label="Nome do Paciente"
              placeholder="Digite o nome do paciente"
              :maxlength="100"
            />
          </div>
          <div class="selectinput">
            <RgInput
              id="boletin"
              v-model="form.bulletin"
              label="Boletim"
              placeholder="Digite o nº do boletim"
            />
          </div>
          <div class="filter-title">
            <span class="title">Período</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-period"
                ref="initialPeriod"
                v-model="form.initialPeriod"
                :rules="{ fn: verifyInitialDate }"
                :max-date="initialPeriod"
                label="Data Inicial"
              />
              <RgInputDate
                id="final-period"
                ref="finalPeriod"
                v-model="form.finalPeriod"
                :rules="{ required: true, fn: verifyFinalDate }"
                :max-date="finalPeriod"
                label="Data Final"
              />
            </div>
          </div>
          <div class="tooltip-info">
            <Tooltip class="tooltip" startOpen>
              <IconExclamationHelper slot="icon" hollow />
              <div slot="content" class="content">
                <span> Para realizar Alta Administrativa em lote: </span>
                <ul class="list">
                  <li class="item">
                    É possível selecionar somente os <br />
                    boletins da mesma página de <br />resultados da busca;
                  </li>
                  <li class="item">
                    A quantidade máxima de boletins <br />selecionadas para Alta
                    é de 30 <br />boletins.
                  </li>
                </ul>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

      <div slot="search-top" class="top-button">
        <div class="tooltip-info">
          <Tooltip class="tooltip">
            <IconInfoHelper slot="icon" startOpen />
            <div slot="content">
              {{ messageTooltip }}
            </div>
          </Tooltip>
        </div>

        <div class="actions">
          <div class="status-radio">
            <RgRadioCustom
              id="objective"
              v-bind="propsRadioCustom"
              class="radio-type-table"
              @input="onInputRadioCustom"
            />
          </div>

          <div class="top-buttons">
            <RgShowButton
              id="show-bulletin-ebs"
              :disabled="!hasOneAdministrativeCheckOutSelected"
              title="Detalhes do Boletim"
              @click="openModalBulletinDetails"
            />

            <RgPersonCardButton
              id="edit-patient-ebs"
              :disabled="!hasOneAdministrativeCheckOutSelected"
              @click="editPatient"
            />

            <RgHistoryButton
              id="history-ebs"
              :disabled="!hasOneAdministrativeCheckOutSelected"
              title="Histórico de Atendimentos"
              @click="patientCareHistory"
            />
          </div>
        </div>
      </div>

      <div class="emergency-administrative-checkout-out-search-result">
        <div v-if="hasAdministrativeCheckOut" class="content-grid">
          <div class="table">
            <SmartTable
              ref="smartTable"
              name="EmergencyAdministrativeCheckOutSearchTable"
              :columns="COLUMNS"
              :body="mutableAdministrativeCheckOut"
              :total="total"
              :initial-columns="7"
              :colorLine="actionColorLine"
              :dynamic-height="getSpaceTable"
              circleIndicator
              toggleSelected
              removeBtnColumns
              :multSelect="isOpenCheckOut"
              :showPagination="false"
              class="smart-table"
              @getMultLines="selectLines"
              @getLine="selectLine"
            />
          </div>
        </div>
      </div>

      <div
        v-show="hasAdministrativeCheckOut"
        slot="menu-bottom"
        class="bottom-button"
      >
        <div class="actions">
          <SmallButton
            id="register-exit"
            :disabled="!hasOpenCheckOutSelected"
            :backgroundColor="'#37c871'"
            :title="'Registrar Alta Administrativa'"
            @click="registerAdministrativeCheckOut"
          >
            <IconExit slot="icon" class="icon" />
          </SmallButton>
        </div>
      </div>
    </RgSearch>

    <ModalBulletinDetails
      id="modal-bulletin-details"
      :show="showModalBulletinDetails"
      :bulletinData="administrativeCheckOutList[0]"
      @close="closeModalBulletinDetails"
    />
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgInput,
  RgInputDate,
  RgShowButton,
  RgPersonCardButton,
  RgHistoryButton,
  RgRadioCustom,
  SmallButton,
  Tooltip,
  IconExit,
  IconInfoHelper,
  IconExclamationHelper,
} from "~tokio/primitive";
import { mapGetters } from "vuex";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ModalBulletinDetails from "$emergency/bulletin/component/modal/modal-bulletin-details/ModalBulletinDetails";

export default {
  name: "EmergencyAdministrativeCheckOutSearch",
  components: {
    RgSearch,
    SmartTable,
    RgSelectUnithealth,
    RgSelectSector,
    RgInput,
    RgInputDate,
    RgShowButton,
    RgPersonCardButton,
    RgHistoryButton,
    RgRadioCustom,
    SmallButton,
    Tooltip,
    IconExit,
    IconInfoHelper,
    ModalBulletinDetails,
    IconExclamationHelper,
  },
  data() {
    return {
      mutableAdministrativeCheckOut: [],
      administrativeCheckOutList: [],
      checkoutStatus: 0,
      total: 0,
      form: {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sectorId: null,
        initialPeriod: null,
        finalPeriod: null,
        patientName: null,
        bulletin: null,
      },
      showModalBulletinDetails: false,
      dynamicHeight: null,
      maxInitialDate: null,
      maxFinalDate: null,
      limit: 30,
    };
  },
  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),
    hasAdministrativeCheckOut() {
      return this.mutableAdministrativeCheckOut?.length > 0;
    },
    getSpaceTable() {
      return this.dynamicHeight;
    },
    hasOneAdministrativeCheckOutSelected() {
      return (
        this.administrativeCheckOutList &&
        this.administrativeCheckOutList.length === 1
      );
    },
    hasOpenCheckOutSelected() {
      return this.administrativeCheckOutList?.length > 0 && this.isOpenCheckOut;
    },
    isOpenCheckOut() {
      return this.checkoutStatus === this.CHECKOUT_STATUS.OPEN;
    },
    messageTooltip() {
      return "Essa tela exibe boletins do PEP, que possuem situação/status “Em Aberto” ou “Aguardando Atendimento”.";
    },
    propsRadioCustom() {
      const list = [
        { title: "Alta em andamento", color: "#1E88A9", id: 1 },
        { title: "Alta não registrada", color: "#F96B70", id: 2 },
      ];
      const value = this.checkoutStatus;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    actionColorLine() {
      return {
        get: () => {
          if (this.checkoutStatus === this.CHECKOUT_STATUS.OPEN) {
            return GetColorRowSmartTable("grey");
          }
          if (this.checkoutStatus === this.CHECKOUT_STATUS.REGISTER_CHECKOUT) {
            return GetColorRowSmartTable("blue");
          }
          if (this.checkoutStatus === this.CHECKOUT_STATUS.PENDING) {
            return GetColorRowSmartTable("red");
          }
        },
      };
    },
    todayLessOneDay() {
      const lessOneDay = this.$utils.date.Subtract(1, "days", "YYYY/MM/DD");
      return new Date(lessOneDay);
    },
    initialPeriod() {
      return this.maxInitialDate;
    },
    finalPeriod() {
      return this.maxFinalDate;
    },
  },
  watch: {},
  created() {
    this.COLUMNS = [
      { name: "Situação de Alta", key: "", align: "center" },
      { name: "", key: "situacaoAlta" },
      { name: "Boletim", key: "bol_numero", align: "right" },
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Entrada", key: "bol_data_hora" },
      { name: "Setor", key: "set_nome", align: "left" },
      { name: "Usuário", key: "usu_nome", align: "left" },
    ];
    this.CHECKOUT_STATUS = {
      OPEN: 0,
      REGISTER_CHECKOUT: 1,
      PENDING: 2,
    };
  },
  mounted() {
    this.filterDateFill();
    this.fillFilter();
  },
  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/emergency/administrative-check-out/search",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Emergency/AdministrativeCheckOut/RESET_FILTER_ADMINISTRATIVE_CHECK_OUT",
      );
    }
  },
  methods: {
    async fillFilter() {
      try {
        this.$loader.start();

        const filterData = this.$store.getters[
          "Emergency/AdministrativeCheckOut/GET_FILTER_ADMINISTRATIVE_CHECK_OUT"
        ];

        if (filterData) {
          this.form.unitHealthId = filterData.unitHealthId;
          this.form.sectorId = filterData.sectorId;
          this.form.initialPeriod = filterData.initialPeriod;
          this.form.finalPeriod = filterData.finalPeriod;

          this.actionCleanSelection();
          this.reSearchTable();
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao carregar os dados da navegação", pErr);
      } finally {
        this.$loader.finish();
      }
    },
    generateFilter() {
      const MIN_DATE = "1900-01-01";

      const variables = {
        uns_id: this.form.unitHealthId,
        set_id: this.form.sectorId,
        initialPeriod: this.form.initialPeriod
          ? this.$utils.date.BrazilianDateToDatabase(this.form.initialPeriod)
          : MIN_DATE,
        finalPeriod: this.setFinalPeriod(),
        patientName: this.form.patientName,
        bulletin: this.form.bulletin ? Number(this.form.bulletin) : null,
        checkoutStatus: this.checkoutStatus || 0,
      };

      this.$store.commit(
        "Emergency/AdministrativeCheckOut/SET_FILTER_ADMINISTRATIVE_CHECK_OUT",
        this.form,
      );

      return variables;
    },
    async searchFilter(pData) {
      try {
        this.mutableAdministrativeCheckOut = [];
        return await this.$store.dispatch(
          "Emergency/AdministrativeCheckOut/SEARCH_ADMINISTRATIVE_CHECK_OUT",
          pData,
        );
      } catch (pErr) {
        this.$toaster.error(pErr.message || pErr);
      }
    },
    async onInputRadioCustom(pItem) {
      this.checkoutStatus = pItem?.id || 0;

      this.defineMaxPeriod();

      this.$loader.start();
      this.actionCleanSelection();
      await this.$refs.rgSearch.submitForm(true);
      this.$loader.finish();
    },
    defineMaxPeriod() {
      let maxDate;

      if (this.checkoutStatus === this.CHECKOUT_STATUS.OPEN) {
        maxDate = new Date(this.$utils.date.Subtract(1, "days", "YYYY/MM/DD"));

        this.resetPeriodDates();
      }

      if (
        this.checkoutStatus === this.CHECKOUT_STATUS.REGISTER_CHECKOUT ||
        this.checkoutStatus === this.CHECKOUT_STATUS.PENDING
      ) {
        maxDate = new Date();
      }

      if (this.$refs.finalPeriod) {
        this.$refs.finalPeriod.setMaxDate(maxDate);
      }
      if (this.$refs.initialPeriod) {
        this.$refs.initialPeriod.setMaxDate(maxDate);
      }
    },
    async reSearchTable() {
      try {
        this.actionCleanSelection();
        this.$store.commit("Pagination/SET_LIMIT_PAGINATION", this.limit);
        await this.$refs.rgSearch.performSearch();
      } catch (pErr) {
        this.$toaster.error("Erro ao carregar os dados da navegação", pErr);
      }
    },
    async selectLines(pItem) {
      if (pItem) {
        this.administrativeCheckOutList = pItem;
      } else {
        this.administrativeCheckOutList = [];
      }

      this.$store.commit(
        "Emergency/AdministrativeCheckOut/SELECT_ADMINISTRATIVE_CHECK_OUT",
        this.administrativeCheckOutList,
      );
    },
    async selectLine(pItem) {
      if (pItem) {
        this.administrativeCheckOutList = [pItem];
      } else {
        this.administrativeCheckOutList = [];
      }

      this.$store.commit(
        "Emergency/AdministrativeCheckOut/SELECT_ADMINISTRATIVE_CHECK_OUT",
        this.administrativeCheckOutList,
      );
    },
    registerAdministrativeCheckOut() {
      this.$router.push({
        name: "emergency.administrative-check-out.register",
      });
    },
    editPatient() {
      this.$store.commit(
        "Person/Patient/SET_PERSON_ID",
        Number(this.administrativeCheckOutList[0].pes_id),
      );
      this.$router.push({
        name: "emergency.administrative-check-out.edit-patient",
      });
    },
    verifyInitialDate(pValue, pErrors) {
      const validInitialDate = this.$utils.date.IsAfter(
        pValue,
        "DD/MM/YYYY",
        this.form.finalPeriod,
        "DD/MM/YYYY",
      );

      if (validInitialDate) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    verifyFinalDate(pValue, pErrors) {
      const validInitialDate = this.$utils.date.IsBefore(
        pValue,
        "DD/MM/YYYY",
        this.form.initialPeriod,
        "DD/MM/YYYY",
      );

      if (validInitialDate) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },
    resetPeriodDates() {
      if (
        this.form.initialPeriod &&
        !this.$utils.date.IsBefore(
          this.form.initialPeriod,
          "DD/MM/YYYY",
          this.$utils.date.BrazilianDateFormat(),
          "DD/MM/YYYY",
        )
      ) {
        this.form.initialPeriod = this.$utils.date.Subtract(
          1,
          "days",
          "DD/MM/YYYY",
        );
      }
      if (
        !this.$utils.date.IsBefore(
          this.form.finalPeriod,
          "DD/MM/YYYY",
          this.$utils.date.BrazilianDateFormat(),
          "DD/MM/YYYY",
        )
      ) {
        this.form.finalPeriod = this.$utils.date.Subtract(
          1,
          "days",
          "DD/MM/YYYY",
        );
      }
    },
    setFinalPeriod() {
      if (this.checkoutStatus === this.CHECKOUT_STATUS.OPEN) {
        return this.$utils.date.BrazilianDateToDatabase(this.form.finalPeriod);
      }

      return this.$utils.date.Add(
        this.$utils.date.BrazilianDateToDatabase(this.form.finalPeriod),
        1,
        "days",
        "YYYY-MM-DD",
      );
    },
    filterDateFill() {
      this.form.initialPeriod = this.$utils.date.Subtract(
        1,
        "days",
        "DD/MM/YYYY",
      );
      this.form.finalPeriod = this.$utils.date.Subtract(
        1,
        "days",
        "DD/MM/YYYY",
      );

      this.maxInitialDate = new Date(
        this.$utils.date.Subtract(1, "days", "YYYY/MM/DD"),
      );

      this.maxFinalDate = new Date(
        this.$utils.date.Subtract(1, "days", "YYYY/MM/DD"),
      );
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgSearch?.$refs.listContent) {
          const headerHeight = 65;
          const minHeight = 350;

          this.dynamicHeight =
            this.$refs.rgSearch.$refs.listContent.offsetHeight - headerHeight;

          this.dynamicHeight =
            this.dynamicHeight > minHeight ? minHeight : this.dynamicHeight;
        }

        this.actionCleanSelection();
      });
    },
    afterSearchFilter() {
      this.getDynamicHeight();
      if (this.$refs.smartTable) {
        this.actionCleanSelection();
      }
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },
    validateName(pValue, pErrors) {
      if (pValue && pValue.length < 3) {
        pErrors.push("Digite ao menos 3 caracteres");
        return false;
      }
    },
    openModalBulletinDetails() {
      this.showModalBulletinDetails = true;
    },
    closeModalBulletinDetails() {
      this.showModalBulletinDetails = false;
    },

    patientCareHistory() {
      const patientInfo = this.administrativeCheckOutList[0];
      this.$router.push({
        name: "emergency.administrative-check-out.search.patient-care-history",
        params: patientInfo,
      });
    },

    actionCleanSelection() {
      this.administrativeCheckOutList = [];
      if (this.$refs.smartTable) {
        this.$refs.smartTable.setScrollTopZero();
        this.$refs.smartTable.cleanMultSelectedRow();
      }
    },
    cleanForm() {
      this.checkoutStatus = this.CHECKOUT_STATUS.OPEN;
      this.defineMaxPeriod();
      const initialPeriod = this.form.initialPeriod;
      const finalPeriod = this.form.finalPeriod;

      this.administrativeCheckOutList = [];
      this.mutableAdministrativeCheckOut = [];

      this.form = {
        unitHealthId: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sectorId: null,
        patientName: null,
        bulletin: null,
        initialPeriod: initialPeriod,
        finalPeriod: finalPeriod,
      };
    },
  },
};
</script>
