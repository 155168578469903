import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  PossuiDestinoException: `O boletim informado já possui uma saída provavelmente informada por outro usuário`,

  DestinoException: `Horário de Saida retroativo deve ser menor do que a hora atual`,
};

export default async (context, arrFormData) => {
  return AuthLegacyRequest.post("/emergencias/controller-destino/cadastrar", {
    arrFormData,
  })
    .then(({ data }) => {
      const { dados } = HandleResponse(data, {
        extraExceptionsErrors: arrayExceptions,
      });
      return dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
