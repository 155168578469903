import MenuMap from "./MenuMap.json";

const GetSubModulesPermissionsReport = () => {
  let permissions = [];

  for (const module in MenuMap) {
    MenuMap[module].subModule.map((subModule) => {
      const listPermissions =
        Array.isArray(subModule.permission) && subModule.permission.length > 0;

      if (subModule && listPermissions) {
        subModule.permission.forEach((item) => {
          if (Array.isArray(item)) {
            item.map((perm) => {
              permissions.push(perm);
            });
          } else {
            permissions.push(item);
          }
        });
      } else {
        permissions.push(subModule.permission);
      }
    });
  }
  // sanitize
  permissions = Array.from(
    new Set(
      permissions.filter((permission) => {
        if (typeof permission !== "string") {
          return false;
        }
        return permission.split(".").length >= 2;
      }),
    ),
  );
  return permissions;
};

export default GetSubModulesPermissionsReport;
