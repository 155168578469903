<template slot="subFilters">
  <div class="pharmacy-satellite-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectPharmacy
          id="requestingPharmacy"
          v-model="form.requestingPharmacyId"
          byUser
          :unitHealthId="globalFilters.unitHealth"
          :userId="userLoginId"
          :typePharmacy="SATELLITE_PHARMACY_TYPE"
          :disabled="!globalFilters.unitHealth"
          :class="{ disable: !globalFilters.unitHealth }"
          label="Farmácia Solicitante"
          class="inputitem"
          @change="selectedPharmacyRequesting"
        />

        <RgSelectPharmacy
          id="pharmacyAttendant"
          v-model="form.pharmacyAttendantId"
          byUser
          :unitHealthId="globalFilters.unitHealth"
          :userId="userLoginId"
          :typePharmacy="CENTRAL_PHARMACY_TYPE"
          :disabled="!globalFilters.unitHealth"
          :class="{ disable: !globalFilters.unitHealth }"
          label="Farmácia Atendente"
          class="inputitem"
          @change="selectedPharmacyAttendant"
        />

        <RgSuggestUser
          id="user"
          v-model="suggestUser"
          label="Solicitante"
          placeholder="Todos"
          @selected="selectedUser"
        />

        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />

        <RgComboboxPharmacyOrderStatus
          id="productType"
          v-model="form.situationOrderId"
          class="inputitem"
          @change="selectedPharmacyOrderStatus"
        />

        <span class="label">Situação dos Itens</span>

        <Tooltip class="tooltip" marginTitle="-27px">
          <IconLightBulbHelper slot="icon" />
          <div slot="content" class="content margin-title">
            <div class="title">
              <span>É possivel marcar mais de uma opção</span>
            </div>
          </div>
        </Tooltip>

        <RgRadioCustom
          id="product-type"
          ref="product"
          v-bind="propsRadioCustom"
          multSelect
          reorganize
          @input="onInputRadioCustom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  RgSuggestUser,
  RgRadioCustom,
  Tooltip,
  IconLightBulbHelper,
} from "~tokio/primitive";

import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgComboboxPharmacyOrderStatus from "$pharmacy/common/combobox/rg-combobox-pharmacy-order-status/RgComboboxPharmacyOrderStatus";

const FORM_FILTER = {
  requestingPharmacyId: null,
  requestingPharmacyName: null,
  pharmacyAttendantId: null,
  pharmacyAttendantName: null,
  productTypeId: null,
  productTypeName: null,
  productId: null,
  productName: null,
  situationOrderId: null,
  situationOrderName: null,
  situationItem: null,
  userId: null,
  userName: null,
};

export default {
  name: "PharmacySatelliteRequestsReport",
  components: {
    RgSuggestUser,
    RgSelectProductType,
    RgSelectPharmacy,
    RgComboboxPharmacyOrderStatus,
    RgRadioCustom,
    Tooltip,
    IconLightBulbHelper,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      productId: null,
      suggestUser: null,
    };
  },
  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
    }),
    propsRadioCustom() {
      const list = [
        { title: "Aguardando", id: 1 },
        { title: "Atendido", id: 2 },
        { title: "Excluído", id: 3 },
        { title: "Indisponível", id: 4 },
        { title: "Parcialmente Atendido", id: 5, gridStart: 1, gridEnd: 3 },
      ];

      const value = this.form.situationItem;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    getNameSmartTable() {
      return "PharmacySatelliteRequestsReport";
    },
  },
  watch: {
    "globalFilters.unitHealth"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.requestingPharmacyId = null;
        this.form.requestingPharmacyName = null;
        this.form.pharmacyAttendantId = null;
        this.form.pharmacyAttendantName = null;
      }
    },
  },
  created() {
    this.CENTRAL_PHARMACY_TYPE = 1;
    this.SATELLITE_PHARMACY_TYPE = 2;
  },
  mounted() {
    this.columnsTable([
      { name: "Pedido", key: "PEDIDO", align: "right" },
      {
        name: "Farmácia Solicitante",
        key: "FARMACIA_SOLICITANTE",
        align: "left",
      },
      { name: "Farmácia Atendente", key: "FARMACIA_ATENDENTE", align: "left" },
      { name: "Nome", key: "NOME", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Data da Solicitação", key: "DATA_SOLICITACAO", align: "right" },
      {
        name: "Usuário Solicitante",
        key: "USUARIO_SOLICITANTE",
        align: "left",
      },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Situação do Pedido", key: "SITUACAO_PEDIDO", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Situação do Item", key: "SITUACAO_ITEM", align: "left" },
      {
        name: "Quantidade Solicitada",
        key: "QUANTIDADE_SOLICITADA",
        align: "right",
      },
      {
        name: "Quantidade Atendida",
        key: "QUANTIDADE_ATENDIDA",
        align: "right",
      },
    ]);
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.situationItem = item;
      }
    },
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          unitHealthId: this.globalFilters.unitHealth,
          unitHealthName: this.globalFilters.unitHealthName,
          productId: Number(this.globalFilters.product),
          productName: this.globalFilters.productName,
          initialDate: this.globalFilters.initialDate,
          finalDate: this.globalFilters.finalDate,
          columnsToPrint: this.columnsToPrint,
          ...pValue,
          requestingPharmacyId: Number(this.form.requestingPharmacyId),
          requestingPharmacyName: this.form.requestingPharmacyName,
          pharmacyAttendantId: Number(this.form.pharmacyAttendantId),
          pharmacyAttendantName: this.form.pharmacyAttendantName,
          userId: this.form.userId,
          userName: this.form.userName,
          productTypeId: Number(this.form.productTypeId),
          productTypeName: this.form.productTypeName,
          situationOrderId: this.form.situationOrderId,
          situationOrderName: this.form.situationOrderName,
          situationItem: this.form.situationItem,
          loggedUserId: this.userLoginId,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_SATELLITE_REQUESTS",
          variables,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    selectedProductType(pType) {
      const hasSuggestion = pType && Object.keys(pType).length > 0;
      if (hasSuggestion) {
        this.form.productTypeName = pType.label;
      } else {
        this.form.productTypeName = "";
      }
    },
    selectedPharmacyOrderStatus(pOrderStatus) {
      const hasSuggestion = pOrderStatus.length > 0;
      if (hasSuggestion) {
        this.form.situationOrderName = pOrderStatus[0].text;
      } else {
        this.form.situationOrderName = "";
      }
    },
    selectedPharmacyRequesting(pPharmacy) {
      const hasSuggestion = pPharmacy && Object.keys(pPharmacy).length > 0;
      if (hasSuggestion) {
        this.form.requestingPharmacyName = pPharmacy.label;
      } else {
        this.form.requestingPharmacyName = "";
      }
    },
    selectedPharmacyAttendant(pPharmacy) {
      const hasSuggestion = pPharmacy && Object.keys(pPharmacy).length > 0;
      if (hasSuggestion) {
        this.form.pharmacyAttendantName = pPharmacy.label;
      } else {
        this.form.pharmacyAttendantName = "";
      }
    },
    selectedUser(pUser) {
      const hasSuggestion = pUser && Object.keys(pUser).length > 0;
      if (hasSuggestion) {
        this.form.userId = pUser?.source?.usu_id;
        this.form.userName = pUser?.source?.usu_nome;
      } else {
        this.form.userId = null;
        this.form.userName = "";
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      if (this.$refs.product) {
        this.$refs.product.clearRadio();
      }
    },
  },
};
</script>
