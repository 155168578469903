var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"rg-input--component"},[_c('RgFormBase',{attrs:{"label":_vm.label,"required":_vm.isRequired,"in-line":_vm.inLine,"has-label":_vm.hasLabel}},[_c('div',{attrs:{"slot":"right-label"},slot:"right-label"},[_c('RgValidationAlert',{staticClass:"rg-input--icon",attrs:{"alert":_vm.error}})],1),_c('div',{staticClass:"rg-input--base",class:{ 'in-line': _vm.inLine }},[_c('div',{staticClass:"rg-input--textbox-container",style:({ 'margin-top': _vm.marginTop })},[(_vm.useDatepicker)?_c('v-date-picker',{attrs:{"masks":_vm.masks,"model-config":_vm.returnConfig,"available-dates":_vm.formattedAvailabelDate,"min-date":_vm.getMinDate,"max-date":_vm.getMaxDate,"attributes":_vm.attributes,"firstDayOfWeek":1},nativeOn:{"shortkey":function($event){return _vm.cleanDatePicker.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, togglePopover, inputEvents }){return [_c('label',{staticClass:"label-area"},[_c('div',{staticClass:"rg-input--side-action",on:{"click":function($event){return togglePopover({ placement: _vm.popUp })}}},[_c('IconCalendar',{staticClass:"rg-input--icon"})],1),_c('input',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"inputbox",class:{ 'on-error': _vm.hasError },style:(_vm.styles),attrs:{"id":_vm.id,"disabled":_vm.disabled,"readonly":_vm.disabled,"data-id":_vm.dataId,"placeholder":_vm.placeholder,"tabindex":_vm.disabled ? -1 : _vm.tabIndex,"type":"text"},domProps:{"value":inputValue},on:{"blur":_vm.validateBlur,"change":_vm.change,"click":() => {
                    togglePopover({ placement: _vm.popUp });
                    if (!_vm.formattedAvailabelDate) _vm.loadSetMaxAndMinDate();
                  },"focus":(e) => {
                    _vm.moveCursorEnd(e);
                  }}},inputEvents))])]}}],null,false,1757976121),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"},{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"inputbox",class:{ 'on-error': _vm.hasError },style:(_vm.styles),attrs:{"id":_vm.id,"disabled":_vm.disabled,"readonly":_vm.disabled,"data-id":_vm.dataId,"placeholder":_vm.placeholder,"tabindex":_vm.disabled ? -1 : _vm.tabIndex,"type":"text"},domProps:{"value":(_vm.inputValue)},on:{"blur":_vm.validateBlur,"click":() => {
                _vm.togglePopover({ placement: _vm.popUp });
                _vm.loadSetMaxAndMinDate();
              },"focus":(e) => {
                _vm.moveCursorEnd(e);
              },"change":_vm.change,"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),_c('div',{staticClass:"rg-input--side-action"},[_c('IconCalendar',{staticClass:"rg-input--icon"})],1)])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }