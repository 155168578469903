<template lang="html">
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.74998 0.687507V3.43758C2.74998 3.81754 3.05744 4.12504 3.43758 4.125C3.81754 4.125 4.125 3.81754 4.125 3.43758V0.687507C4.125 0.307458 3.81754 0 3.43758 0C3.05744 0 2.74998 0.307458 2.74998 0.687507ZM15.1252 3.43763C15.1252 3.81758 14.8175 4.12504 14.4376 4.12504C14.0576 4.12504 13.7499 3.81754 13.7499 3.43758V0.687507C13.7499 0.307458 14.0576 0 14.4376 0C14.8175 0 15.1252 0.307458 15.1252 0.687507V3.43763ZM16.5 22.0001C19.5374 22.0001 22 19.5376 22 16.4999C22 13.4626 19.5374 11 16.5 11C13.4627 11 11 13.4627 11 16.4999C11 19.5376 13.4628 22.0001 16.5 22.0001ZM2.74998 8.25004H5.50006V11H2.74998V8.25004ZM5.50006 15.1251H2.74998V12.3749H5.50006V15.1251ZM9.6251 11H6.87498V8.25004H9.6251V11ZM9.6251 15.1251H6.87498V12.3749H9.6251V15.1251ZM1.37506 6.87498V16.3632C1.37506 16.4356 1.42752 16.4999 1.48982 16.4999H9.6251V17.8752H1.48982C0.666786 17.8752 0 17.1957 0 16.3632V4.26206C0 3.42684 0.666786 2.74998 1.48982 2.74998H2.06257V3.43763C2.06257 4.19487 2.67893 4.81259 3.43763 4.81259C4.19614 4.81259 4.81246 4.19487 4.81246 3.43763V2.74998H13.0627V3.43763C13.0627 4.19487 13.6791 4.81259 14.4377 4.81259C15.1961 4.81259 15.8125 4.19487 15.8125 3.43763V2.74998H16.386C17.2089 2.74998 17.8751 3.42684 17.8751 4.26206V9.62505H16.5V6.87498H1.37506ZM13.7499 8.25004V11H11.0001V8.25004H13.7499Z"
      fill="white"
    />
    <path
      d="M15.6804 19.8844C15.6136 19.9586 15.5224 20 15.4277 20C15.333 20 15.2419 19.9586 15.1751 19.8844L13.1571 17.6564C12.9476 17.4253 12.9476 17.0504 13.1571 16.8196L13.4098 16.5406C13.6192 16.3095 13.9584 16.3095 14.1679 16.5406L15.4277 17.9315L18.8321 14.1734C19.0416 13.9422 19.3812 13.9422 19.5902 14.1734L19.8429 14.4524C20.0524 14.6836 20.0524 15.0584 19.8429 15.2892L15.6804 19.8844Z"
      :fill="disabled ? '#CCCCCC' : color"
    />
  </svg>
</template>

<script>
export default {
  name: "EffectiveSchedule",
  props: {
    disabled: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "#1E88A9",
    },
  },
};
</script>
