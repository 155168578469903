<template>
  <div class="unit-health-report">
    <div class="grid">
      <Tooltip clickable startOpen class="tooltip">
        <IconInfoHelper slot="icon" hollow />
        <div slot="content" class="content">
          Selecione quais os critérios devem ser levados em consideração para a
          geração do relatório.
        </div>
      </Tooltip>
      <div class="selectinput">
        <RgSelectUnitHealthTypeSus
          id="filtro-tipo-unidade"
          ref="unitTypeSus"
          v-model="form.uns_id_tipos_unidades_saude_sus"
          label="Tipo de Unidade"
          defaultText="Todos"
          @change="getUnitTypeSusName"
        />
      </div>

      <div class="selectinput">
        <RgSelectUnitHealthSubTypeSus
          id="filtro-sub-tipo-unidade"
          ref="unitSubTypeSus"
          v-model="form.uns_id_sub_tipos_unidades_saude_sus"
          :unit-type-sus-id="form.uns_id_tipos_unidades_saude_sus"
          :disabled="!form.uns_id_tipos_unidades_saude_sus"
          :class="{ disable: !form.uns_id_tipos_unidades_saude_sus }"
          label="Subtipo de Unidade"
          defaultText="Todos"
          @options="handleoptions"
          @change="getUnitSubTypeSusName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, IconInfoHelper } from "~tokio/primitive";
import RgSelectUnitHealthTypeSus from "$unit-health/common/components/select/rg-select-unit-health-type-sus/RgSelectUnitHealthTypeSus.vue";
import RgSelectUnitHealthSubTypeSus from "$unit-health/common/components/select/rg-select-unit-health-sub-type-sus/RgSelectUnitHealthSubTypeSus.vue";

const FORM_FILTER = {
  uns_id_tipos_unidades_saude_sus: null,
  uns_id_sub_tipos_unidades_saude_sus: null,
  tss_nome: null,
  stu_nome: null,
};

export default {
  name: "UnitHealthReport",
  components: {
    Tooltip,
    IconInfoHelper,
    RgSelectUnitHealthTypeSus,
    RgSelectUnitHealthSubTypeSus,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Unidades de Saúde",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      hasSubTypeOptions: false,
    };
  },

  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; ; text-align: left;}"
      );
    },

    getNameSmartTable() {
      return "UnitHealthReport";
    },

    columnTable() {
      return this.COLUMN;
    },
  },

  watch: {
    "form.uns_id_tipos_unidades_saude_sus"(pValue, pPrev) {
      const isToCleanSubType = !pValue || (pValue && pPrev !== null);

      if (isToCleanSubType) {
        this.form.uns_id_sub_tipos_unidades_saude_sus = null;
        this.form.tss_nome = null;
      }
    },
    "form.uns_id_sub_tipos_unidades_saude_sus"(pValue, pPrev) {
      const isToCleanSubType = !pValue || (pValue && pPrev !== null);

      if (isToCleanSubType) {
        this.form.stu_nome = null;
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Unidade", key: "UNIDADE", align: "left" },
      { name: "Razão Social", key: "RAZAO_SOCIAL", align: "left" },
      { name: "CNPJ", key: "CNPJ", align: "center" },
      {
        name: "CNES - Orgão Emissor",
        key: "CNES_ORGAO_EMISSOR",
        align: "left",
      },
      { name: "Tipo de Unidade", key: "TIPO_UNIDADE", align: "left" },
      { name: "Subtipo de Unidade", key: "SUB_TIPO_UNIDADE", align: "left" },
    ];
  },

  methods: {
    getUnitTypeSusName(pValue) {
      if (pValue) {
        const { label } = pValue;
        this.form.tss_nome = label;
      }
    },

    getUnitSubTypeSusName(pValue) {
      if (pValue) {
        const { label } = pValue;
        this.form.stu_nome = label;
      }
    },

    buildFilter() {
      return {
        ...this.form,
        columnsToPrint: this.columnsToPrint,
      };
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "UnitHealth/Report/GET_UNIT_HEALTH_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    handleoptions(pValue) {
      const hasOptions = Array.isArray(pValue) && pValue?.length > 0;
      this.hasSubTypeOptions = hasOptions;
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
  },
};
</script>
