<template>
  <Modulebox
    :title="isEdition ? `Editar Fornecedor` : `Cadastrar Fornecedor`"
    class="pharmacy-provider-register"
  >
    <div class="pharmacy-provider-register-body">
      <form class="form-container" @submit.stop.prevent="saveProvider">
        <RgValidatorForm ref="validator">
          <FormBase title="Dados do Fornecedor" class="form-base">
            <div class="grid-informations">
              <div class="row-1">
                <RgRadioCustom
                  id="type-person"
                  ref="typePerson"
                  v-bind="propsRadioCustom"
                  class="radio-type-person"
                  :canUnselect="false"
                  :class="{ disable: isEdition || disabledByModal }"
                  :disabled="isEdition || disabledByModal"
                  with-title
                  label="Pessoa"
                  :rules="{ required: true }"
                  @input="onInputRadioCustom"
                />

                <RgInput
                  id="name-provider"
                  ref="nameProvider"
                  v-model="provider.frd_nome"
                  :disabled="isEdition || disabledByModal"
                  :class="{ disable: isEdition || disabledByModal }"
                  class="inputitem"
                  label="Nome"
                  placeholder="Digite o nome do fornecedor/fabricante que será cadastrado"
                  :rules="{ required: true }"
                />
              </div>
              <div class="row-2">
                <RgInputCpf
                  id="cpf"
                  ref="cpf"
                  v-model="provider.frd_cpf"
                  :disabled="isLegal || isEdition || disabledByModal"
                  :class="{ disable: isLegal || isEdition || disabledByModal }"
                  class="inputitem"
                  :rules="{ required: isPrivate }"
                />
                <RgInputCnpj
                  id="cnpj"
                  ref="cnpj"
                  v-model="provider.frd_cnpj"
                  :class="{
                    disable: isPrivate || isEdition || disabledByModal,
                  }"
                  :disabled="isPrivate || isEdition || disabledByModal"
                  class="inputitem"
                  :rules="{ required: isLegal }"
                />
                <RgInput
                  id="state-registration"
                  ref="stateRegistration"
                  v-model="provider.frd_inscricao_estadual"
                  :class="{ disable: isPrivate || disabledByModal }"
                  :disabled="isPrivate || disabledByModal"
                  class="inputitem"
                  label="Inscrição Estadual"
                  placeholder="000000000000000"
                  :maxlength="15"
                />
                <RgInput
                  id="municipal-registration"
                  ref="municipalRegistration"
                  v-model="provider.frd_inscricao_municipal"
                  :class="{ disable: isPrivate || disabledByModal }"
                  :disabled="isPrivate || disabledByModal"
                  class="inputitem"
                  label="Inscrição Municipal"
                  placeholder="000000000000000"
                  :maxlength="15"
                />
              </div>
              <div class="row-3">
                <RgInput
                  id="contact-name"
                  ref="contactName"
                  v-model="provider.frd_contato"
                  :class="{ disable: disabledByModal }"
                  :disabled="disabledByModal"
                  class="inputitem"
                  label="Contato"
                  placeholder="Digite o nome do responsável pelo fornecedor/fabricante"
                  :rules="{ required: true }"
                />

                <RgSelectDddInputPhone
                  id="contact-telephone"
                  ref="phone"
                  v-model="provider.telephone"
                  :rules="{ required: true, fn: validatePhone }"
                  :class="{ disable: disabledByModal }"
                  :disabled="disabledByModal"
                  @getDdd="getDdd"
                />

                <RgInputEmail
                  id="contact-email"
                  ref="contactEmail"
                  v-model="provider.frd_email"
                  :class="{ disable: disabledByModal }"
                  :disabled="disabledByModal"
                  class="inputitem"
                  label="E-mail"
                  placeholder="contato@contato.com.br"
                  :maxlength="75"
                />
              </div>
              <div class="row-4">
                <RgTextArea
                  id="observation"
                  ref="observation"
                  v-model="provider.frd_obs"
                  :class="{ disable: disabledByModal }"
                  :disabled="disabledByModal"
                  class="inputitem"
                  label="Observações"
                  :maxlength="255"
                />
              </div>
              <div class="row-5">
                <RgCleanButton
                  id="clean-provider-btn"
                  small
                  class="clean-btn"
                  :disabled="disabledByModal"
                  @click="openModalConfirmFieldClearingProvider"
                />
              </div>
            </div>
          </FormBase>
          <FormBase title="Endereço" class="module-patient">
            <div class="grid-address">
              <div class="row-1">
                <RgInputCep
                  id="cep"
                  ref="cep"
                  v-model="address.cep"
                  class="inputitem"
                  :class="{ disable: disabledByModal }"
                  :disabled="disabledByModal"
                  :rules="{ required: true }"
                  @blur="formatAddress"
                />

                <RgSuggestPlace
                  id="place"
                  ref="publicPlaceType"
                  v-model="address.place"
                  class="inputitem"
                  :class="{
                    disable:
                      disablePublicPlaceType || !isThereCEP || disabledByModal,
                  }"
                  :disabled="
                    disablePublicPlaceType || !isThereCEP || disabledByModal
                  "
                  :rules="{ required: isThereCEP }"
                  @selected="selectingPlace"
                />
                <RgInput
                  id="address"
                  ref="address"
                  v-model="address.address"
                  class="inputitem"
                  label="Logradouro"
                  title="Logradouro"
                  :class="{ disable: disableAddress || disabledByModal }"
                  :disabled="disableAddress || disabledByModal"
                  :rules="{ required: isThereCEP }"
                  placeholder="Digite o nome do logradouro"
                />
                <RgInput
                  id="number"
                  ref="numberField"
                  v-model="address.number"
                  :class="{ disable: !isThereCEP || disabledByModal }"
                  :disabled="!isThereCEP || disabledByModal"
                  :rules="{ required: true, addressNumber: true }"
                  class="inputitem"
                  label="Número"
                  title="Número"
                  :maxlength="10"
                  placeholder="000000"
                />
              </div>

              <div class="row-2">
                <RgInput
                  id="complement"
                  ref="complement"
                  v-model="address.complement"
                  :class="{ disable: !isThereCEP || disabledByModal }"
                  :disabled="!isThereCEP || disabledByModal"
                  class="inputitem"
                  label="Complemento"
                  title="Complemento"
                  placeholder="casa/apto"
                  :maxlength="30"
                />
                <RgInput
                  id="neighborhood"
                  ref="neighborhood"
                  v-model="address.neighborhood"
                  class="inputitem"
                  label="Bairro"
                  placeholder="Digite o nome do bairro"
                  title="Bairro"
                  :class="{
                    disable:
                      disableNeighborhood || !isThereCEP || disabledByModal,
                  }"
                  :disabled="
                    disableNeighborhood || !isThereCEP || disabledByModal
                  "
                  :rules="{ required: true }"
                />
                <RgSuggestCity
                  id="city"
                  ref="city"
                  v-model="address.city"
                  class="inputitem"
                  :class="{ disable: disableCity }"
                  :disabled="disableCity || disabledByModal"
                  :rules="{ required: isThereCEP || disabledByModal }"
                  @selected="selectingCity"
                />
                <RgSuggestState
                  id="state"
                  ref="state"
                  v-model="address.state"
                  class="inputitem"
                  :class="{ disable: disableCity }"
                  :disabled="disableCity || disabledByModal"
                  :rules="{ required: isThereCEP || disabledByModal }"
                  @selected="selectingUF"
                />
              </div>
              <div class="row-3">
                <RgInput
                  id="reference"
                  ref="reference"
                  v-model="address.reference"
                  :class="{ disable: !isThereCEP || disabledByModal }"
                  :disabled="!isThereCEP || disabledByModal"
                  class="inputitem"
                  label="Referência"
                  title="Referência"
                  placeholder="Ex.: Comércio ou praça próximos ao domicílio"
                  :maxlength="75"
                />
                <RgCleanButton
                  id="clean-address-btn"
                  small
                  class="clean-btn"
                  :disabled="disabledByModal"
                  @click="openModalConfirmFieldClearingAddress"
                />
              </div>
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="footer">
      <MediumButton
        id="cancel-btn"
        backgroundColor="#fb7e36"
        label="Voltar"
        :disabled="disabledByModal"
        @click="close"
      />
      <MediumButton
        id="salvar-btn"
        label="Salvar"
        medium
        :disabled="disabledByModal"
        @click="saveProvider"
      />
    </div>
    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="cleanForm"
      @getOut="closeModalConfirmFieldClearing"
      @close="closeModalConfirmFieldClearing"
    />
  </Modulebox>
</template>

<script>
import {
  RgInput,
  RgInputCnpj,
  RgInputEmail,
  RgTextArea,
  MediumButton,
  RgCleanButton,
  RgValidatorForm,
  ModalConfirmFieldClearing,
  RgRadioCustom,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgInputCep from "$person/common/components/input/rg-input-cep/RgInputCep";
import RgSuggestPlace from "$person/common/components/suggest/rg-suggest-place/RgSuggestPlace";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import CheckCEP from "$billing/billing/action/CheckCEP";
import RgSelectDddInputPhone from "$person/common/components/input/rg-select-ddd-input-phone/RgSelectDddInputPhone";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";

const FORM_PROVIDER = {
  tipoFornecedor: 1,
  frd_cnpj: null,
  frd_contato: null,
  frd_cpf: null,
  frd_email: null,
  frd_id: null,
  frd_id_clientes: null,
  frd_id_ddds_telefone: null,
  frd_id_enderecos: null,
  frd_id_municipios: null,
  frd_inscricao_estadual: null,
  frd_inscricao_municipal: null,
  frd_nome: null,
  frd_obs: null,
  frd_telefone: null,
  telephone: null,
  situacao: null,
};

const FORM_ADDRESS = {
  cep: null,
  place: null,
  placeId: null,
  address: null,
  number: null,
  complement: null,
  neighborhood: null,
  state: null,
  stateId: null,
  city: null,
  reference: null,
};

export default {
  name: "PharmacyProviderRegister",
  components: {
    RgCleanButton,
    RgInput,
    RgInputCpf,
    RgInputCnpj,
    RgSelectDddInputPhone,
    RgInputEmail,
    RgTextArea,
    RgInputCep,
    RgSuggestState,
    RgSuggestPlace,
    RgSuggestCity,
    MediumButton,
    RgValidatorForm,
    ModalConfirmFieldClearing,
    Modulebox,
    FormBase,
    RgRadioCustom,
  },

  data() {
    return {
      provider: this.$utils.obj.DeepCopy(FORM_PROVIDER),
      address: this.$utils.obj.DeepCopy(FORM_ADDRESS),
      hasCep: true,
      modalConfirmFieldClearing: false,
      isClearingProvider: false,
      hasPlaceType: false,
      hasPublicPlace: false,
      hasNeighborhood: false,
      pharmacyProvider: null,
      pharmacyProviderId: null,
      hasCity: false,
      hasState: false,
      ddd: null,
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Física", id: 1 },
        { title: "Jurídica", id: 2 },
      ];

      const value = this.provider.tipoFornecedor;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    disabledByModal() {
      return this.modalConfirmFieldClearing;
    },
    disableNeighborhood() {
      if (!this.isThereCEP || this.hasNeighborhood) {
        return true;
      }
      return false;
    },
    isEdition() {
      return this.$route.name === "pharmacy.register.provider.edit";
    },

    disablePublicPlaceType() {
      if (!this.isThereCEP || this.hasPlaceType) {
        return true;
      }
      return false;
    },

    disableAddress() {
      if (!this.isThereCEP || this.hasPublicPlace) {
        return true;
      }
      return false;
    },

    disableCity() {
      if (!this.isThereCEP || this.hasCity) {
        return true;
      }
      return false;
    },

    disableState() {
      if (!this.isThereCEP || this.hasState) {
        return true;
      }
      return false;
    },

    isPrivate() {
      return this.provider.tipoFornecedor === 1;
    },
    isLegal() {
      return this.provider.tipoFornecedor === 2;
    },
    titleProvider() {
      return this.isEdition ? "Editar" : "Cadastrar";
    },
    isThereCEP() {
      return this.address.cep ? this.address.cep.length >= 9 : false;
    },
  },
  watch: {
    "address.cep"(pValue, pPrev) {
      if (pValue) {
        const isEmptyCep = pValue.length === 0 || pValue === "";

        if (isEmptyCep) {
          this.cleanAddress();
        }
      } else {
        this.cleanAddress();
      }
    },
    "provider.tipoFornecedor"(pValue) {
      if (pValue && pValue === 1) {
        this.provider.frd_cnpj = null;
        this.provider.frd_inscricao_estadual = null;
        this.provider.frd_inscricao_municipal = null;
        this.$refs.cnpj.cleanValidate();
      }
      if (pValue && pValue === 2) {
        this.provider.frd_cpf = null;
        this.$refs.cpf.cleanValidate();
      }
    },
  },

  mounted() {
    this.pharmacyProvider = this.$store.getters[
      "Pharmacy/Register/GET_SELECTED_PROVIDER"
    ];
    if (this.isEdition && this.pharmacyProvider) {
      this.pharmacyProviderId = this.pharmacyProvider.frd_id;

      this.formatProvider(this.pharmacyProvider);
    }
  },
  destroyed() {
    this.$store.commit("Pharmacy/Register/UNSELECT_PHARMACY_PROVIDER");
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.provider.tipoFornecedor = item.id;
      }
    },
    async formatAddress(pValue) {
      const currentCep = pValue ? pValue.replace(/[^0-9]/gi, "") : null;
      const isInvalidCep = currentCep ? currentCep.length !== 8 : true;

      if (isInvalidCep) return;
      try {
        this.$loader.start();
        const searchCep = await CheckCEP(pValue);
        const hasCep = Object.keys(searchCep).length !== 0;
        if (hasCep) {
          this.hasCep = true;
          this.$nextTick(() => {
            this.hasPublicPlace = !!searchCep.temLogradouro;

            this.address.frd_id_municipios = searchCep.mun_id;
            this.address.city = searchCep.mun_nome;
            this.hasCity = !!searchCep.mun_nome;

            this.address.stateId = searchCep.est_id;
            this.address.state = searchCep.est_sigla;
            this.hasState = !!searchCep.est_sigla;

            this.address.placeId = searchCep.tlg_id;
            this.address.place = searchCep.tlg_nome;

            this.$refs.publicPlaceType.forceSelection({
              tlg_id: searchCep.tlg_id ? searchCep.tlg_id : null,
              tlg_nome: searchCep.tlg_nome ? searchCep.tlg_nome : null,
            });
            this.hasPlaceType = !!searchCep.tlg_nome;

            this.address.address = null;
            if (!this.address.address || searchCep.logradouro !== null) {
              this.address.address = searchCep.logradouro
                ? searchCep.logradouro.toUpperCase()
                : "";
            }

            this.address.neighborhood = null;
            if (!this.address.neighborhood || searchCep.bairro !== null) {
              this.address.neighborhood = searchCep.bairro
                ? searchCep.bairro.toUpperCase()
                : "";
            }
            this.hasNeighborhood = !!searchCep.bairro;
          });
        } else {
          this.$toaster.error("CEP não encontrado");

          return false;
        }
      } catch (e) {
        this.$toaster.error("CEP não encontrado");
        this.hasCep = false;
        this.hasPublicPlace = false;
        this.hasCity = false;
        this.hasState = false;
        this.hasPlaceType = false;
        this.hasNeighborhood = false;
        this.cleanAddressWithoutRegisteredCep();
        if (this.isEdition) {
          this.formatAddressWithoutRegisteredCep(this.selectedRow);
        }
      } finally {
        this.$loader.finish();
      }
    },
    formatAddressWithoutRegisteredCep(pValue) {
      this.address.address = pValue.end_logradouro;
      this.address.place = pValue.tlg_nome;
      this.address.placeId = pValue.tlg_id;
      this.address.neighborhood = pValue.bai_nome;
      this.address.city = pValue.mun_nome;
      this.address.state = pValue.est_sigla;
      this.address.frd_id_municipios = pValue.mun_id;
      this.address.stateId = pValue.est_id;
    },
    formatProvider(pValue) {
      this.$loader.start();
      if (pValue) {
        this.provider = {
          frd_id: pValue.frd_id,
          frd_id_clientes: pValue.frd_id_clientes,
          frd_id_enderecos: pValue.frd_id_enderecos,
          tipoFornecedor: pValue.tipoFornecedor === "FISICA" ? 1 : 2,
          frd_nome: pValue.frd_nome,
          frd_cpf: pValue.frd_cpf,
          frd_cnpj: pValue.frd_cnpj,
          frd_inscricao_estadual: pValue.frd_inscricao_estadual,
          frd_inscricao_municipal: pValue.frd_inscricao_municipal,
          frd_contato: pValue.frd_contato,
          frd_telefone: pValue.frd_telefone,
          frd_id_ddds_telefone: pValue.frd_id_ddds_telefone,
          frd_email: pValue.frd_email,
          frd_obs: pValue.frd_obs,
        };
        this.address = {
          cep: pValue.end_cep,
          number: pValue.end_numero,
          complement: pValue.end_complemento,
          reference: pValue.end_referencia,
        };
        this.formatAddress(pValue.end_cep);

        this.$refs.phone.fillValueById(
          pValue.frd_telefone,
          Number(pValue.frd_id_ddds_telefone),
        );
      }
    },
    async formProviderValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    selectingUF(pValue) {
      const source = pValue && pValue.source;
      this.address.stateId = source && source.est_id;
    },
    selectingCity(pValue) {
      const source = pValue && pValue.source;
      this.address.frd_id_municipios = source && source.mun_id;
    },
    selectingPlace(pValue) {
      const source = pValue && pValue.source;
      this.address.placeId = source && source.tlg_id;
    },
    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },
    toUpperCase(pValue) {
      if (pValue !== null && pValue !== undefined) {
        return pValue.toUpperCase();
      }
      return null;
    },
    parsePharmacyProvider() {
      const variables = {
        frd_nome: this.toUpperCase(this.provider.frd_nome),
        frd_cpf: this.removeDashesAndDots(this.provider.frd_cpf) || null,
        frd_cnpj: this.removeDashesAndDots(this.provider.frd_cnpj) || null,
        frd_inscricao_estadual: this.provider.frd_inscricao_estadual,
        frd_inscricao_municipal: this.provider.frd_inscricao_municipal,
        frd_contato: this.toUpperCase(this.provider.frd_contato),
        frd_email: this.toUpperCase(this.provider.frd_email),
        frd_obs: this.toUpperCase(this.provider.frd_obs),
        frd_id_ddds_telefone: this.$refs.phone?.dddItem?.ddd_id,
        frd_telefone: this.removeDashesAndDots(this.$refs.phone?.telephone),
        frd_id_ddds_fax: null,
        frd_id_municipios: this.address.frd_id_municipios,
        objEndereco: {
          end_cep: this.removeDashesAndDots(this.address.cep),
          end_id_tipos_logradouros: this.address.placeId,
          end_logradouro: this.toUpperCase(this.address.address),
          end_numero: this.address.number,
          end_complemento: this.toUpperCase(this.address.complement),
          bai_id_municipios: this.address.frd_id_municipios,
          bai_nome: this.toUpperCase(this.address.neighborhood),
          est_id: this.address.stateId,
          end_referencia: this.toUpperCase(this.address.reference),
        },
      };
      if (this.isEdition) {
        variables.frd_id_clientes = this.provider.frd_id_clientes;
        variables.frd_id_enderecos = this.provider.frd_id_enderecos;
        variables.frd_id = this.provider.frd_id;
      }

      return variables;
    },
    async saveProvider() {
      try {
        if (!(await this.formProviderValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
        const variables = this.parsePharmacyProvider();
        this.$loader.start();
        if (this.isEdition) {
          await this.$store.dispatch(
            "Pharmacy/Register/EDIT_PHARMACY_PROVIDER",
            {
              ...variables,
            },
          );
          this.$toaster.success("Fornecedor alterado com sucesso");
        } else {
          await this.$store.dispatch(
            "Pharmacy/Register/CREATE_PHARMACY_PROVIDER",
            {
              ...variables,
            },
          );
          this.$toaster.success("Fornecedor cadastrado com sucesso");
        }
        this.$emit("close");
        if (this.hasMutablePharmacyProvider) this.$emit("reSearch");
        this.$router.push({ name: "pharmacy.register.provider" });
      } catch (e) {
        if (this.isEdition) {
          this.$toaster.error(
            this.$utils.sanitize.formatError(e),
            "Erro ao alterar o Fornecedor",
          );
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(e),
            "Não foi possível cadastrar o Fornecedor",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },
    getDdd(pValue) {
      this.ddd = pValue;
    },
    validatePhone(pValue, pErrors) {
      if (!pValue) {
        if (this.$refs.phone) {
          this.$refs.phone.cleanPhone();
        }
        return true;
      }
      if (pValue && pValue.length < 9) {
        pErrors.push("Digite todos os números do telefone");
        return false;
      } else {
        if (!this.ddd) {
          pErrors.push("Selecione um DDD");
          return false;
        }
      }
      return true;
    },
    close() {
      this.$router.push({ name: "pharmacy.register.provider" });
    },
    openModalConfirmFieldClearingProvider() {
      this.modalConfirmFieldClearing = true;
      this.isClearingProvider = true;
    },
    openModalConfirmFieldClearingAddress() {
      this.modalConfirmFieldClearing = true;
    },
    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
      this.isClearingProvider = false;
    },
    cleanAddressWithoutRegisteredCep() {
      this.address.address = null;
      this.address.place = null;
      this.address.placeId = null;
      this.address.neighborhood = null;
      this.address.city = null;
      this.address.state = null;
      this.address.frd_id_municipios = null;
      this.address.stateId = null;
    },
    cleanAddress() {
      this.address = this.$utils.obj.DeepCopy(FORM_ADDRESS);
      this.cleanFieldsValidationAddress();
    },
    cleanForm() {
      if (this.isClearingProvider) {
        this.cleanFormProvider();
      } else {
        this.cleanAddress();
      }
    },
    cleanFormProvider() {
      if (this.isEdition) {
        this.provider.frd_inscricao_estadual = null;
        this.provider.frd_inscricao_municipal = null;
        this.provider.frd_contato = null;
        this.provider.frd_email = null;
        this.provider.frd_obs = null;
      } else {
        this.provider = this.$utils.obj.DeepCopy(FORM_PROVIDER);
      }
      this.$refs.phone.cleanPhone();
      this.cleanFieldsValidationProvider();
    },
    cleanFieldsValidationAddress() {
      const fieldsRefs = [
        "cep",
        "publicPlaceType",
        "address",
        "numberField",
        "neighborhood",
        "city",
        "state",
      ];
      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cleanFieldsValidationProvider() {
      const fieldsRefs = [
        "typePerson",
        "nameProvider",
        "cpf",
        "cnpj",
        "contactName",
        "phone",
      ];
      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
