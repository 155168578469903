<style src="./RgSuggestPlace.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-place">
    <RgSuggest
      ref="rgSuggest"
      v-model="inputValue"
      :label="label"
      :disabled="disabled"
      :placeholder="placeholder"
      :tab-index="tabIndex"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :idx="idx"
        :item="item"
        class="place-item"
      >
        <div class="place-row-top">
          <span class="place-name">
            {{ item.tlg_codigo }} - {{ item.tlg_nome }}
          </span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchPlace } from "./action";

export default {
  name: "RgSuggestPlace",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "tlg_codigo - tlg_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      const variables = {
        tlgNome: null,
        tlg_codigo: null,
      };

      if (!isNaN(pSearchTerm)) {
        variables.tlg_codigo = pSearchTerm;
      } else if (!isNaN(pSearchTerm.slice(0, 3))) {
        variables.tlg_codigo = pSearchTerm.split(" - ")[0];
        variables.tlgNome = pSearchTerm.split(" - ")[1];
      } else {
        variables.tlgNome = pSearchTerm;
      }
      return SearchPlace(variables);
    },
    forceSelection(pValue) {
      if (pValue && pValue.tlg_codigo) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            tlg_codigo: pValue.tlg_codigo,
            tlg_nome: pValue.tlg_nome,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
