<template lang="html">
  <svg
    width="55"
    height="56"
    viewBox="0 0 55 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 26.7C0.59 25.01 1.61 23.8 3.51 23.62C5.47 23.44 7.23 24.75 7.61 26.67C7.99 28.59 6.89 30.47 5.02 31.09C3.18 31.69 1.16 30.79 0.35 29C0.24 28.76 0.12 28.53 0 28.3C0 27.77 0 27.23 0 26.7Z"
      fill="white"
    />
    <path
      d="M27.4003 0C29.0603 0.52 30.3303 1.42 30.6203 3.27C30.9403 5.31 29.6803 7.19 27.7203 7.63C25.7503 8.08 23.7503 6.92 23.1803 5C22.5903 3.02 23.6303 0.99 25.5903 0.27C25.8403 0.18 26.0903 0.09 26.3303 0C26.6903 0 27.0403 0 27.4003 0Z"
      fill="white"
    />
    <path
      d="M39.1301 13.99C40.2901 13.99 41.4401 13.96 42.6001 13.99C49.5001 14.23 54.9801 19.92 55.0001 26.84C55.0101 33.81 49.5301 39.57 42.6001 39.79C40.0501 39.87 37.5001 39.8 34.9501 39.81C34.1501 39.81 33.8301 39.43 33.8401 38.66C33.8501 36.05 33.8501 33.44 33.8401 30.83C33.8401 30.07 34.1401 29.66 34.9501 29.68C35.8401 29.7 36.7301 29.69 37.6201 29.68C38.3201 29.68 38.6601 29.99 38.6601 30.7C38.6601 31.83 38.6801 32.95 38.6601 34.08C38.6401 34.72 38.8701 35.01 39.5301 34.99C40.5101 34.96 41.4901 35.01 42.4701 34.97C46.8501 34.78 50.2201 31.2 50.2001 26.8C50.1801 22.48 46.7501 18.94 42.4001 18.79C41.4501 18.76 40.5001 18.8 39.5501 18.77C38.8801 18.74 38.6601 19.02 38.6801 19.66C38.7101 20.76 38.6701 21.85 38.6801 22.95C38.6801 23.69 38.3401 24.06 37.6101 24.05C36.6601 24.04 35.7101 24.01 34.7601 23.99C34.1401 23.97 33.8501 23.63 33.8601 23.02C33.8601 20.35 33.8601 17.68 33.8601 15.01C33.8601 14.25 34.2401 13.95 34.9701 13.96C36.3601 13.97 37.7601 13.96 39.1501 13.96L39.1301 13.99Z"
      fill="white"
    />
    <path
      d="M21.2604 2.43002V6.24002C19.7304 6.67002 19.6004 7.97002 19.6304 9.43002C19.7004 12.66 19.6604 15.89 19.7204 19.12C19.7304 19.88 19.4704 20.08 18.7504 20.07C15.3704 20.01 11.9904 20.02 8.6204 19.96C7.3204 19.94 6.3604 20.35 5.9504 21.59H2.2204C1.8604 19.73 3.2004 17.33 5.3404 16.37C6.2304 15.97 7.2604 15.74 8.2404 15.7C10.5704 15.61 12.9104 15.67 15.3704 15.67C15.3704 15.3 15.3704 14.98 15.3704 14.67C15.3704 12.54 15.3204 10.4 15.3604 8.27002C15.4204 4.92002 18.0004 2.38002 21.2604 2.42002V2.43002Z"
      fill="white"
    />
    <path
      d="M21.179 52.5201C19.189 52.5201 17.439 51.7101 16.409 49.8801C15.839 48.8601 15.489 47.6201 15.399 46.4501C15.249 44.3901 15.369 42.3001 15.379 40.2201C15.379 39.9001 15.379 39.5901 15.379 39.2001C14.019 39.2001 12.789 39.2001 11.549 39.2001C10.249 39.2001 8.93902 39.2501 7.63902 39.1801C4.47902 39.0101 2.03902 36.3601 2.16902 33.3101H5.94902C6.35902 34.7901 7.59902 34.9601 9.04903 34.9301C12.249 34.8601 15.449 34.8801 18.659 34.8601C18.979 34.8601 19.299 34.8601 19.739 34.8601C19.739 36.3001 19.739 37.6501 19.739 39.0001C19.719 41.4301 19.699 43.8601 19.659 46.3001C19.639 47.3101 20.019 48.0701 20.949 48.5501C21.129 48.6501 21.349 48.9101 21.349 49.1001C21.339 50.2101 21.259 51.3201 21.199 52.5201H21.179Z"
      fill="white"
    />
    <path
      d="M32.3995 52.53C32.3995 51.35 32.3795 50.26 32.4195 49.18C32.4195 48.96 32.6295 48.66 32.8195 48.56C33.6895 48.08 34.0895 47.37 34.0895 46.39C34.0795 44.91 34.1095 43.43 34.0795 41.95C34.0695 41.39 34.2395 41.15 34.8295 41.17C35.8095 41.21 36.7795 41.19 37.7595 41.17C38.2095 41.17 38.3995 41.32 38.3895 41.79C38.3695 43.51 38.4495 45.23 38.3495 46.94C38.1895 49.79 36.1695 52.01 33.4395 52.46C33.0995 52.52 32.7595 52.51 32.3995 52.53Z"
      fill="white"
    />
    <path
      d="M26.8204 55.2301C24.7004 55.2101 22.9704 53.4501 23.0104 51.3401C23.0504 49.2601 24.7804 47.5601 26.8404 47.5601C28.9604 47.5601 30.6904 49.3001 30.6804 51.4201C30.6804 53.5601 28.9504 55.2501 26.8104 55.2401L26.8204 55.2301Z"
      fill="white"
    />
    <path
      d="M38.3707 12.41H34.0707C34.0707 11.33 34.0207 10.27 34.0807 9.22C34.1707 7.81 33.8707 6.68 32.4707 6.24V2.47C35.3807 2.21 38.1207 4.71 38.3507 7.96C38.4507 9.4 38.3707 10.86 38.3707 12.41Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ImportCompetence",
};
</script>
