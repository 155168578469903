<template>
  <Modulebox
    ref="modulebox"
    :title="
      isEdition
        ? 'Editar Apresentação de Produto'
        : 'Cadastrar Apresentação de Produto'
    "
    class="pharmacy-type-presentation"
  >
    <FormBase
      :title="
        isEdition
          ? 'Descrição da Apresentação'
          : 'Cadastro e Busca de Apresentação'
      "
      class="register-form"
    >
      <div class="pharmacy-type-presentation-body">
        <RgValidatorForm
          ref="validator"
          @submit.stop.prevent="newTypePresentation"
        >
          <section class="pharmacy-type-presentation-form">
            <RgSuggestCity
              ref="city"
              v-model="form.city"
              :rules="{ required: true }"
              :class="{ disable: true }"
              disabled
              label="Município"
              class="city"
              @selected="selectingMunicipality"
            />
            <RgInput
              ref="acronym"
              v-model="form.acronym"
              :rules="{
                required: true,
                validatorIfExistNumber: true,
              }"
              :disabled="disabledByModal"
              label="Sigla"
              placeholder="SIGLA"
              class="acronym"
              :maxlength="6"
            />
            <RgInput
              ref="name"
              v-model="form.name"
              :rules="{
                required: true,
                fn: validateInputName,
              }"
              label="Nome"
              placeholder="Digite a apresentação do produto. Ex.: 'Caixa'"
              class="name"
              :disabled="disabledByModal"
              :needRemoveSpecialStrings="false"
              :maxlength="45"
            />
            <div class="actions">
              <RgCancelButton
                v-if="isEdition"
                medium
                :disabled="disabledByModal"
                @click="cancelPresentationTypeEditing"
              />
              <RgCleanButton
                v-else
                :disabled="disabledByModal"
                large
                @click="cleanInfoTypePresentation"
              />
              <RgSaveButton
                v-if="isEdition"
                medium
                :disabled="disabledByModal"
                @click="savePresentationTypeEditing"
              />
              <RgAddButton
                v-else
                large
                :disabled="disabledByModal"
                :permission="canRegister"
                @click="newTypePresentation"
              />
            </div>
          </section>
        </RgValidatorForm>
        <hr class="hr" />
        <section class="pharmacy-type-presentation-search">
          <div class="search">
            <RgInput
              v-model="search.typePresentation"
              label=""
              :disabled="
                isEdition || modalImportTypePresentation || disabledByModal
              "
              :class="{
                disable:
                  isEdition || modalImportTypePresentation || disabledByModal,
              }"
              placeholder="Buscar por apresentação"
            />
            <RgSearchButton
              ref="searchBtn"
              :disabled="
                isEdition || modalImportTypePresentation || disabledByModal
              "
              :class="{
                disable:
                  isEdition || modalImportTypePresentation || disabledByModal,
              }"
              @submit="searchTypePresentation(true)"
            />
          </div>
          <div class="actions">
            <RgEditButton
              :permission="canEdit"
              :disabled="disableActionsTable || disabledByModal"
              title="Editar Apresentação de Produto"
              @click="editTypePresentation"
            />
            <RgLessButton
              :permission="canRemove"
              :disabled="disableActionsTable || disabledByModal"
              title="Excluir Apresentação de Produto"
              @click="openModalConfirmDeletion"
            />
            <RgImportButton
              :disabled="isEdition || disabledByModal"
              large
              @click="importTypePresentation"
            />
          </div>
        </section>
        <section
          class="pharmacy-type-presentation-table"
          :disabled="isEdition || disabledByModal"
          :class="{ disable: isEdition || disabledByModal }"
        >
          <SmartTable
            ref="smartTable"
            name="PharmacyTypePresentation"
            :columns="COLUMNS"
            :body="mutableTypePresentationList"
            :initial-columns="2"
            :total="total"
            :max-register="25"
            :disabled="isEdition || disabledByModal"
            :item-per-page="20"
            :dynamicHeight="getSpaceTable"
            index-column="mtu_id"
            toggleSelected
            removeBtnColumns
            @getLine="selectLine"
            @pagination="setPagination"
          />
        </section>
      </div>
    </FormBase>

    <div slot="footer" class="pharmacy-type-presentation-footer">
      <MediumButton medium label="Voltar" @click="goBack" />
    </div>

    <ModalImportTypePresentation
      :show="modalImportTypePresentation"
      @close="closeModalImportTypePresentation"
      @reSearch="searchTypePresentation"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmDeletion"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgValidatorForm,
  RgInput,
  RgCleanButton,
  RgSearchButton,
  RgAddButton,
  RgEditButton,
  RgLessButton,
  RgImportButton,
  RgCancelButton,
  RgSaveButton,
  MediumButton,
  ModalConfirmDeletion,
} from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";

import ModalImportTypePresentation from "$pharmacy/submodules/register/common/components/modal/modal-import-type-presentation/ModalImportTypePresentation";

const FORM = {
  city: null,
  cityId: null,
  acronym: null,
  name: null,
};

export default {
  name: "PharmacyTypePresentation",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgInput,
    RgSuggestCity,
    RgCleanButton,
    RgSearchButton,
    RgAddButton,
    RgEditButton,
    RgLessButton,
    RgImportButton,
    RgCancelButton,
    RgSaveButton,
    SmartTable,
    ModalImportTypePresentation,
    MediumButton,
    ModalConfirmDeletion,
  },

  data() {
    return {
      hasSelectedTypePresentation: false,
      mutableTypePresentationList: [],
      typePresentationData: {},
      form: this.$utils.obj.DeepCopy(FORM),
      total: 0,
      search: {
        typePresentation: null,
      },
      isEdition: false,
      pagination: {
        limit: 20,
        offset: 0,
        current: 1,
      },
      firstId: null,
      tableHeight: 0,
      modalImportTypePresentation: false,
      modalConfirmDeletion: false,
    };
  },

  computed: {
    ...mapGetters({
      clientId: "Login/GET_USER_ID_CLIENT",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),
    disableActionsTable() {
      return this.isEdition || !this.hasSelectedTypePresentation;
    },
    getSpaceTable() {
      return this.tableHeight;
    },
    propsModalConfirmDeletion() {
      const show = this.modalConfirmDeletion;
      const confirm = () => this.removeTypePresentation();

      const noReason = true;
      const title = "Excluir a Apresentação Selecionada";
      const message = "A operação não poderá ser desfeita";
      const bodyText = this.typePresentationData.mtu_novo_nome;
      const msgSuccess = "Apresentação excluída com sucesso!";
      const btnRemoveTitle = "Excluir";

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        btnRemoveTitle,
      };
    },
    permission() {
      return {
        register: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoApresentacao.incluir",
          this.unitHealthId,
        ),
        edit: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoApresentacao.alterar",
          this.unitHealthId,
        ),
        remove: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoApresentacao.excluir",
          this.unitHealthId,
        ),
      };
    },
    canRegister() {
      return this.permission.register;
    },
    canEdit() {
      return this.permission.edit;
    },
    canRemove() {
      return this.permission.remove;
    },
    disabledByModal() {
      return this.modalImportTypePresentation || this.modalConfirmDeletion;
    },
  },

  watch: {
    "form.acronym"(pValue) {
      if (pValue) {
        this.form.acronym = pValue.trim().replace(/\s/gi, "");
      }
    },
  },

  async mounted() {
    this.mountedInfo();
    await this.searchTypePresentation(false, false);
  },

  created() {
    this.COLUMNS = [
      {
        name: "Apresentação",
        key: "mtu_novo_nome",
        align: "left",
      },
      {
        name: "Sigla",
        key: "mtu_novo_codigo",
        align: "left",
      },
    ];
  },

  methods: {
    mountedInfo() {
      this.$loader.start();

      const hasCity = this.currentUnitHealth?.endereco?.neighborhood?.city;

      if (hasCity && this.$refs.city) {
        const city = this.currentUnitHealth.endereco.neighborhood.city;

        this.$refs.city.forceSelection({
          mun_id: city ? city.mun_id : null,
          mun_nome: city ? city.mun_nome : null,
        });
      }

      this.$loader.finish();
    },
    async searchTypePresentation(pButtonSearch = false, toFirst = false) {
      try {
        this.$loader.start();

        if (pButtonSearch) {
          this.$refs.smartTable.$refs.paginator.resetLimitOffset();
        }

        const variables = {
          arrFormData: {
            mtu_novo_nome: this.search.typePresentation,
            limiteInicio: this.pagination.offset,
            limiteFim: this.pagination.limit,
          },
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Register/SEARCH_TYPE_PRESENTATION",
          variables,
        );
        this.mutableTypePresentationList = result.recordSet;
        this.total = Number(result.total);
        if (toFirst) {
          // LÓGICA CRIADA PARA ADICIONAR REGISTRO RECEM
          // CRIADO/EDITADO EM PRIMEIRO LUGAR DA GRID
          variables.arrFormData.limiteFim = result.total;
          variables.arrFormData.limiteInicio = 0;
          const resultAll = await this.$store.dispatch(
            "Pharmacy/Register/SEARCH_TYPE_PRESENTATION",
            variables,
          );
          let aux = null;
          for (let i = 0; i < resultAll.recordSet.length; i++) {
            if (
              resultAll.recordSet[i] &&
              (resultAll.recordSet[i].mtu_id === this.firstId ||
                Number(resultAll.recordSet[i].mtu_id) === this.firstId)
            ) {
              aux = resultAll.recordSet[i];
            }
          }
          this.mutableTypePresentationList.forEach((element, index) => {
            if (
              element.mtu_id === this.firstId ||
              Number(element.mtu_id) === this.firstId
            ) {
              this.mutableTypePresentationList.splice(index, 1);
            }
          });
          this.mutableTypePresentationList.unshift(aux);
          this.firstId = null;
        }
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar tipos de apresentação");
      } finally {
        if (!this.isEdition && !this.modalImportTypePresentation)
          this.getDynamicHeight();
        this.$refs.searchBtn.actionDone();
        this.$loader.finish();
      }
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async savePresentationTypeEditing() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            mtu_id: this.typePresentationData.mtu_id,
            mtu_id_clientes: this.clientId,
            mtu_id_municipios: this.form.cityId,
            mtu_novo_codigo: this.form.acronym.toUpperCase(),
            mtu_novo_nome: this.form.name.toUpperCase(),
          },
        };

        await this.$store.dispatch(
          "Pharmacy/Register/EDIT_TYPE_PRESENTATION",
          variables,
        );
        this.firstId = variables.arrFormData.mtu_id;
        this.$toaster.success("Apresentação de Produto alterada com sucesso");

        await this.searchTypePresentation(false, true);
      } catch (pErr) {
        const error = pErr.toString();
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isDuplicityError =
            esus_response.exception ===
            "DuplicidadeMunicipioTipoUnidadeException";

          if (isDuplicityError) {
            this.$toaster.error(
              `Já existe registro com essas informações: ${this.form.name} – ${this.form.acronym}.`,
              "Não foi possível alterar a Apresentação de Produto",
            );
          }
          const isBondError =
            esus_response.exception === "TipoUnidadeVinculadoException";

          if (isBondError) {
            this.$toaster.error(
              `Tipos de Apresentação vinculados a outros registros não podem ser alterados.`,
              "Não foi possível alterar a Apresentação de Produto",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao alterar tipo de apresentação",
          );
        }
      } finally {
        this.cancelPresentationTypeEditing();
        this.$loader.finish();
      }
    },
    async newTypePresentation() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            mtu_id_clientes: this.clientId,
            mtu_id_municipios: this.form.cityId,
            mtu_novo_codigo: this.form.acronym.toUpperCase(),
            mtu_novo_nome: this.form.name.toUpperCase(),
          },
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Register/REGISTER_TYPE_PRESENTATION",
          variables,
        );
        this.firstId = result.dados;
        this.$toaster.success("Apresentação de Produto cadastrada com sucesso");

        await this.searchTypePresentation(false, true);
      } catch (pErr) {
        const error = pErr.toString();
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isDuplicityError =
            esus_response.exception ===
            "DuplicidadeMunicipioTipoUnidadeException";

          if (isDuplicityError) {
            this.$toaster.error(
              `Já existe registro com essas informações: ${this.form.name} – ${this.form.acronym}.`,
              "Não foi possível cadastrar a Apresentação de Produto",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao cadastrar tipo de apresentação",
          );
        }
      } finally {
        this.cleanInfoTypePresentation();
        this.$loader.finish();
      }
    },
    editTypePresentation() {
      this.isEdition = true;
      this.form.acronym = this.typePresentationData.mtu_novo_codigo;
      this.form.name = this.typePresentationData.mtu_novo_nome;
    },
    async removeTypePresentation() {
      try {
        this.$loader.start();

        const variables = {
          intIdMunicipioTipoUnidade: this.typePresentationData.mtu_id,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/DELETE_TYPE_PRESENTATION",
          variables,
        );

        this.$toaster.success("Apresentação de Produto excluída com sucesso");

        this.$refs.smartTable.cleanSelectRow();
        await this.searchTypePresentation();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir a Apresentação de Produto",
        );
      } finally {
        this.cleanInfoTypePresentation();
        this.$loader.finish();
      }
    },
    cancelPresentationTypeEditing() {
      this.cleanInfoTypePresentation();
    },
    importTypePresentation() {
      this.modalImportTypePresentation = true;
    },
    closeModalImportTypePresentation() {
      this.modalImportTypePresentation = false;
    },
    openModalConfirmDeletion() {
      this.modalConfirmDeletion = true;
    },
    closeModalConfirmDeletion() {
      this.modalConfirmDeletion = false;
    },
    cleanInfoTypePresentation() {
      this.isEdition = false;
      this.form.acronym = null;
      this.form.name = null;
      this.typePresentationData = {};
      this.hasSelectedTypePresentation = false;

      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
      if (this.$refs.acronym) this.$refs.acronym.cleanValidate();
      if (this.$refs.name) this.$refs.name.cleanValidate();
    },
    async setPagination(pPagination) {
      this.cleanSelectRow();
      this.pagination = pPagination;
      await this.searchTypePresentation();
    },
    selectLine(pValue) {
      if (pValue) {
        this.hasSelectedTypePresentation = true;
        this.typePresentationData = pValue;
      } else {
        this.hasSelectedTypePresentation = false;
        this.typePresentationData = {};
      }
    },
    selectingMunicipality(pValue) {
      const source = pValue.source;
      if (source) {
        this.form.cityId = source && source.mun_id;
      } else {
        this.form.cityId = null;
      }
    },
    validateInputName(pValue, pErrors) {
      const hasSpecial = /[-!$%^&*()@_^+#¨|´~=`{}[\]:";'~<>?\\/]/.test(pValue);
      const hasNumber = /[\d+]/.test(pValue);
      if (hasSpecial && hasNumber) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: vírgula e ponto",
        );
        pErrors.push("O campo não pode possuir números");
        return false;
      }
      if (hasSpecial) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: vírgula e ponto",
        );
        return false;
      }
      if (hasNumber) {
        pErrors.push("O campo não pode possuir números");
        return false;
      }

      return true;
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.modulebox) {
          const modulebox = this.$refs.modulebox.$refs.myself.offsetHeight;

          this.tableHeight = modulebox - 290;

          if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
        }
      });
    },
    cleanSelectRow() {
      this.$refs.smartTable.cleanSelectRow();
      this.hasSelectedTypePresentation = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
