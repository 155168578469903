<style
  src="./PharmacyMedicationsDispensedPatientsReport.scss"
  lang="scss"
  scoped
></style>

<template>
  <div class="pharmacy-medications-dispensed-patients-filter">
    <div class="grid">
      <div class="filter-title">
        <span class="title">Período de Dispensação</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-pcr"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />

        <RgInputDate
          id="final-date-pcr"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          label="Data Final"
          class="inputitem"
          :min-date="getMinDateInitial"
          :max-date="getMaxDateInitial"
        />
      </div>

      <div class="filter-title">
        <span class="title">Dados do Paciente</span>
      </div>

      <div class="selectinput">
        <RgInput
          id="patient-name"
          v-model="form.patientName"
          label="Paciente"
          placeholder="Digite o nome do paciente"
          :disabled="hasPatienId"
          :class="{ disable: hasPatienId }"
        />
      </div>

      <div class="selectinput">
        <RgSuggestSmartPerson
          id="document"
          ref="document"
          v-model="document"
          filter-only-document
          :disabled="disabledDocument"
          :rules="{ forceSelection: true }"
          :enabled-patient="false"
          @selected="selectingPatient"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgSuggestSmartPerson, RgInput } from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  patientId: null,
  patientName: null,
  initialDate: moment().add(-90, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
};

export default {
  name: "PharmacyMedicationsDispensedPatientsReport",
  components: {
    RgInput,
    RgInputDate,
    RgSuggestSmartPerson,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Medicamentos Dispensados para Pacientes",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      document: null,
      disabledDocument: false,
    };
  },

  computed: {
    getNameSmartTable() {
      return "PharmacyMedicationsDispensedPatientsReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    hasPatienId() {
      return this.form.patientId && this.form.patientId !== 0;
    },
    getMinDateInitial() {
      const date = this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
      return date;
    },
    getMaxDateInitial() {
      const date = this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
      return date;
    },
  },
  watch: {
    "form.patientName"(pValue) {
      pValue && pValue.length > 0
        ? (this.disabledDocument = true)
        : (this.disabledDocument = false);
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  mounted() {},

  created() {
    this.COLUMN = [
      { name: "Data", key: "DATA" },
      { name: "Produto", key: "PRODUTO", align: "left" },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Idade", key: "IDADE" },
      { name: "Telefone", key: "TELEFONE" },
      { name: "Endereço", key: "ENDERECO", align: "left" },
      { name: "Nome da Mãe", key: "MAE", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
    ];
  },

  methods: {
    buildFilter() {
      const payLoad = {
        patientId: this.form.patientId,
        patientName: this.form.patientName,
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_MEDICATIONS_DISPENSED_PATIENTS",
          pData,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final.");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial.");
        return false;
      }
    },

    selectingPatient(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.form.patientId = pValue.source.pes_id;
      } else {
        this.form.patientId = null;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.document = null;
      this.disabledDocument = false;

      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
