import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const stock_item = await AuthLegacyRequest.post(
    "farmacia/controller-solicitacao-transferencia/obter-estoque",
    { arrFormData: variables },
  );

  return HandleResponse(stock_item.data).dados;
};
