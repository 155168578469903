<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import SITUATION from "./enum/SituationEnum";
export default {
  name: "RgSelectSituation",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Situação",
    },
    defaultText: {
      type: String,
      default: "Todas",
    },
  },
  data() {
    return {
      valuesData: [
        { value: SITUATION.REGULADOS, label: "Regulados" },
        { value: SITUATION.PENDENTES, label: "Pendentes" },
        { value: SITUATION.ESPERA, label: "Em espera" },
      ],
    };
  },
  methods: {
    getData() {
      return this.valuesData;
    },
  },
};
</script>
