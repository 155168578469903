<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxInstrument",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      type: String,
      default: "Instrumento",
    },
  },
  data: () => {
    return {
      mData: [
        { value: "01", text: "BPA (CONSOLIDADO)" },
        { value: "02", text: "BPA (INDIVIDUALIZADO)" },
        { value: "03", text: "AIH (PROC. PRINCIPAL)" },
        { value: "04", text: "AIH (PROC. ESPECIAL)" },
        { value: "05", text: "AIH (PROC. SECUNDÁRIO)" },
        { value: "06", text: "APAC (PROC. PRINCIPAL)" },
        { value: "07", text: "APAC (PROC. SECUNDÁRIO)" },
        { value: "08", text: "RAAS (ATENÇÃO DOMICILIAR)" },
        { value: "09", text: "RAAS (ATENÇÃO PSICOSSOCIAL)" },
        { value: "10", text: "E-SUS APS (ATENÇÃO PRIMÁRIA À SAÚDE)" },
      ],
    };
  },
};
</script>
