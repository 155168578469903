<script>
import RgSelect from "../../selection/rg-select/RgSelect";

export default {
  name: "RgSelectMonth",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Mês",
    },
  },
  data() {
    return {
      months: [],
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  watch: {
    months: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.getInfoMonths();
  },
  methods: {
    isLeap() {
      const leapYear =
        (this.year % 4 === 0 && this.year % 100 !== 0) || this.year % 400 === 0;
      return leapYear;
    },
    formatFebruary() {
      return this.isLeap() ? "29" : "28";
    },
    getData() {
      return this.months;
    },
    getInfoMonths() {
      this.months = [
        {
          value: 1,
          label: "JANEIRO",
          initialDate: this.year + "-" + "01" + "-" + "01",
          finalDate: this.year + "-" + "01" + "-" + "31",
        },
        {
          value: 2,
          label: "FEVEREIRO",
          initialDate: this.year + "-" + "02" + "-" + "01",
          finalDate: this.year + "-" + "02" + "-" + this.formatFebruary(),
        },
        {
          value: 3,
          label: "MARÇO",
          initialDate: this.year + "-" + "03" + "-" + "01",
          finalDate: this.year + "-" + "03" + "-" + "31",
        },
        {
          value: 4,
          label: "ABRIL",
          initialDate: this.year + "-" + "04" + "-" + "01",
          finalDate: this.year + "-" + "04" + "-" + "30",
        },
        {
          value: 5,
          label: "MAIO",
          initialDate: this.year + "-" + "05" + "-" + "01",
          finalDate: this.year + "-" + "05" + "-" + "31",
        },
        {
          value: 6,
          label: "JUNHO",
          initialDate: this.year + "-" + "06" + "-" + "01",
          finalDate: this.year + "-" + "06" + "-" + "30",
        },
        {
          value: 7,
          label: "JULHO",
          initialDate: this.year + "-" + "07" + "-" + "01",
          finalDate: this.year + "-" + "07" + "-" + "31",
        },
        {
          value: 8,
          label: "AGOSTO",
          initialDate: this.year + "-" + "08" + "-" + "01",
          finalDate: this.year + "-" + "08" + "-" + "31",
        },
        {
          value: 9,
          label: "SETEMBRO",
          initialDate: this.year + "-" + "09" + "-" + "01",
          finalDate: this.year + "-" + "09" + "-" + "30",
        },
        {
          value: 10,
          label: "OUTUBRO",
          initialDate: this.year + "-" + "10" + "-" + "01",
          finalDate: this.year + "-" + "10" + "-" + "31",
        },
        {
          value: 11,
          label: "NOVEMBRO",
          initialDate: this.year + "-" + "11" + "-" + "01",
          finalDate: this.year + "-" + "11" + "-" + "30",
        },
        {
          value: 12,
          label: "DEZEMBRO",
          initialDate: this.year + "-" + "12" + "-" + "01",
          finalDate: this.year + "-" + "12" + "-" + "31",
        },
      ];
    },
  },
};
</script>
