import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation ChangePatientStatus($patient: PatientInputType) {
    ChangePatientStatus(patient: $patient)
  }
`;

export default async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.ChangePatientStatus;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
