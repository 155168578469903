<template lang="html">
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M256,0C143.36,0,51.2,92.16,51.2,204.8S143.36,409.6,256,409.6s204.8-92.16,204.8-204.8S368.64,0,256,0z M256,307.2
			c-56.32,0-102.4-46.08-102.4-102.4S199.68,102.4,256,102.4s102.4,46.08,102.4,102.4S312.32,307.2,256,307.2z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256,153.6c-5.12,0-10.24,0-12.8,2.56C256,179.2,227.84,204.8,207.36,192c-2.56,2.56-2.56,7.68-2.56,12.8
			c0,28.16,23.04,51.2,51.2,51.2s51.2-23.04,51.2-51.2C307.2,176.64,284.16,153.6,256,153.6z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M394.24,422.4c-40.96,25.6-87.04,38.4-135.68,38.4c-51.2,0-97.28-12.8-135.68-38.4l-43.52,71.68
			c-5.12,7.68,0,17.92,10.24,17.92h332.8c10.24,0,15.36-10.24,15.36-17.92L394.24,422.4z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Webcam",
};
</script>
