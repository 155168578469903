<script>
import RgRemoteSelect from "~tokio/primitive/selection/rg-select/RgRemoteSelect";
import GetBioslabLaboratoryObject from "./action/GetBioslabLaboratoryObject";

export default {
  name: "ExamVueSelectBox",
  mixins: [RgRemoteSelect],
  props: {
    label: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Selecione os Objetos de Análise",
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    sigtap: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      codeObject: "",
    };
  },
  // watch: {
  //   sigtap: {
  //     immediate: true,
  //     deep: true,
  //     handler (pValue) {
  //       console.log('pValue:', pValue)
  //       this.sigtap = pValue
  //       this.doSearch()
  //     }
  //   }
  // },
  methods: {
    async doSearch() {
      console.log("this.sigtap:", this.sigtap);
      const options = await GetBioslabLaboratoryObject({
        lle_codigo_sigtap: this.sigtap,
      });
      return options.rows.map((option) => {
        return {
          id: option.lob_id,
          name: option.lob_nome_objetos,
        };
      });
    },
    formatItem(item) {
      return {
        value: item.id,
        label: `${item.name}`,
      };
    },
  },
};
</script>
