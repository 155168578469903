import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";

export default {
  path: "/hospitalization/hospitalization-solicitation/register/new-patient",
  name: "hospitalization.hospitalization-solicitation.register.new-patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Internações", link: OpenSubModule("hospitalization") },
      {
        label: "Solicitações de Internação",
        link: "/hospitalization/hospitalization-solicitation/search",
      },
      {
        label: "Cadastrar de Internação",
        link: "/hospitalization/hospitalization-solicitation/register",
      },
      { label: "Cadastrar Paciente" },
    ],
  },
};
