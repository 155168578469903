<template>
  <div class="daily-admission-census-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="select-unity-census"
          v-model="form.uns_id"
          class="inputitem"
          :default-text="'Selecione'"
          permission="internacao.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
        />
      </div>
      <div class="selectinput">
        <RgSelectSector
          id="selected-sector-census"
          v-model="form.set_id"
          :permission="SECTOR_PERMISSION"
          :unit-health="form.uns_id"
          :default-text="validateSectorOptions"
          :disabled="!form.uns_id"
          :class="{ disable: !form.uns_id }"
          class="inputitem"
          @change="selectingSector"
        />
      </div>
      <div class="selectinput">
        <RgSelectPlaces
          id="selected-locale-census"
          v-model="form.lca_id"
          :sector="form.set_id"
          :default-text="'Todos'"
          :empty-default-text="'Nenhum local disponível'"
          :disabled="!form.set_id"
          :class="{ disable: !form.set_id }"
          class="inputitem"
          @change="selectingPlace"
        />
      </div>
      <div class="selectinput">
        <RgSuggestHospitalizationBed
          id="bed"
          ref="bed"
          v-model="form.inl_nome"
          :disabled="disableBed"
          :class="{ 'disable unselect': disableBed }"
          :local-id="form.lca_id"
          :placeholder="validateBedOptions"
          class="inputitem"
          @selected="selectingHospitalizationBed"
        />
      </div>
      <div class="selectinput">
        <RgSuggestTypeBed
          id="bed-type-census"
          v-model="form.itl_nome"
          class="inputitem"
          @selected="selectingBedInfo"
        />
      </div>
      <div class="selectinput">
        <RgComboboxHospitalizationType
          id="hospitalization-type"
          v-model="form.iti_id"
          :default-text="'Todos'"
          class="inputitem"
          @select="selectingHospitalizationType"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          id="cbo"
          ref="cbo"
          v-model="form.ocp_nome"
          label="Ocupação"
          class="inputitem"
          @selected="selectingCbo"
        />
      </div>
      <div class="selectinput">
        <RgComboboxBedType
          id="situation"
          v-model="form.isl_id"
          class="inputitem"
          @select="selectingBedType"
        />
      </div>
      <div class="selectinput">
        <RgSuggestState
          id="estado"
          ref="state"
          v-model="form.est_sigla"
          label="Estado"
          class="inputitem"
          placeholder="Selecione"
          @selected="selectingState"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCity
          ref="city"
          v-model="form.mun_nome"
          class="inputitem"
          :disabled="!form.est_id"
          :state="form.est_id"
          :class="{ disable: !form.est_id }"
          filterByState
          @selected="selectingCity"
        />
      </div>
      <div class="selectinput">
        <RgRadioCustom
          id="status"
          ref="status"
          v-bind="propsRadioCustomBed"
          multSelect
          class="inputitem"
          label="Listar por Leito"
          @input="onInputRadioCustomBed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgSelectPlaces,
  RgComboboxBedType,
  RgRadioCustom,
} from "~tokio/primitive";

import {
  RgSuggestTypeBed,
  RgComboboxHospitalizationType,
  RgSuggestHospitalizationBed,
} from "$hospitalization/common/component";
import RgSuggestCbo from "~tokio/primitive/suggest/rg-suggest-cbo/RgSuggestCbo";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: "",
  lca_id: null,
  lca_nome: "",
  itl_nome: null,
  itl_id: null,
  inl_id: null,
  ocp_id: null,
  ocp_nome: "",
  isl_id: null,
  isl_nome: "",
  bedStatus: [],
  est_sigla: null,
  est_id: null,
  mun_id: null,
  mun_nome: "",
  inl_nome: "",
  iti_nome: "",
  iti_id: null,
};
export default {
  name: "DailyAdimissionCensusReport",
  components: {
    RgComboboxBedType,
    RgSelectUnithealth,
    RgSelectSector,
    RgSelectPlaces,
    RgSuggestTypeBed,
    RgSuggestHospitalizationBed,
    RgSuggestState,
    RgSuggestCity,
    RgSuggestCbo,
    RgComboboxHospitalizationType,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      resultTitle: "Censo Hospitalar",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      dontSelect: false,
      seeMore: true,
      titleSeeMore: "Dados do Leito",
    };
  },
  computed: {
    propsRadioCustomBed() {
      const list = [
        { title: "Extra", id: 1, color: "#56b0c9" },
        { title: "Observação", id: 2, color: "#ffa56a" },
      ];

      const value = this.form.bedStatus;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    disableBed() {
      return !this.form.lca_id;
    },
    getNameSmartTable() {
      return "DailyAdmissionCensusReport";
    },
    columnTable() {
      return this.COLUMN;
    },

    validateSectorOptions() {
      return this.form.uns_id !== false ? "Todos" : "Nenhum setor disponível";
    },

    validateBedOptions() {
      return this.form.lca_id ? "Digite o leito" : "Todos";
    },
    localId() {
      const isValidLocalId = this.form.lca_id > 0;
      return isValidLocalId ? this.form.lca_id : 0;
    },
    printReportCss() {
      return (
        " @page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }" +
        ".report-column-2 { white-space: normal !important; text-align: left; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; }" +
        ".report-column-5 { white-space: normal !important; text-align: left; }" +
        ".report-column-6 { white-space: normal !important; text-align: left; }" +
        ".report-column-7 { white-space: normal !important; text-align: center; }" +
        ".report-column-8 { white-space: normal !important; text-align: center; }" +
        ".report-column-9 { white-space: normal !important; text-align: left; }" +
        ".report-column-10 { white-space: normal !important; text-align: right; }" +
        ".report-column-11 { white-space: normal !important; text-align: right; }" +
        ".report-column-12 { white-space: normal !important; text-align: center; }" +
        ".report-column-13 { white-space: normal !important; text-align: center; }" +
        ".report-column-14 { white-space: normal !important; text-align: left; }" +
        ".report-column-15 { white-space: normal !important; text-align: left; }" +
        ".report-column-16 { white-space: normal !important; text-align: left; }" +
        ".report-column-17 { white-space: normal !important; text-align: left; }" +
        ".report-column-18 { white-space: normal !important; text-align: left; }" +
        ".report-column-19 { white-space: normal !important; text-align: left; }" +
        ".report-column-20 { white-space: normal !important; text-align: left; }"
      );
    },
  },
  watch: {
    "form.uns_id"(pValue) {
      if (!pValue) {
        this.form.set_id = null;
        this.form.lca_id = null;
        this.form.inl_id = null;
        this.form.inl_nome = "";
      }
    },
    "form.set_id"(pValue) {
      if (!pValue) {
        this.form.lca_id = null;
        this.form.inl_id = null;
        this.form.inl_nome = "";
      }
    },
    "form.lca_id"(pValue) {
      if (!pValue) {
        this.form.inl_id = null;
        this.form.inl_nome = "";
      }
    },
    "form.iti_id"(pValue) {
      if (!pValue && pValue === null) {
        this.form.iti_nome = "";
      }
    },
    "form.isl_id"(pValue) {
      if (!pValue && pValue === null) {
        this.form.isl_nome = "";
      }
    },
    "form.est_sigla"(pValue) {
      if (!pValue && pValue === "") {
        this.form.est_id = null;
        this.form.mun_id = null;
        this.form.mun_nome = "";
      }
    },
    "form.mun_id"(pValue) {
      if (!pValue && pValue === "") {
        this.form.mun_id = null;
        this.form.mun_nome = "";
      }
    },
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.SECTOR_PERMISSION = 351;
    this.COLUMN = [
      { name: "Leito", key: "inl_nome", align: "left" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
      { name: "Setor", key: "set_nome", align: "left" },
      { name: "Local atendimento", key: "lca_nome", align: "left" },
      {
        name: "Tipo de Leito",
        key: "tipos_do_leito",
        align: "left",
        seeMore: true,
      },
      { name: "Situação do Leito", key: "isl_nome", align: "left" },
      { name: "Leito Extra", key: "leito_extra", align: "center" },
      { name: "Leito de Observação", key: "leito_obs", align: "center" },
      { name: "Tipo de internação", key: "iti_nome", align: "left" },
      { name: "Paciente", key: "pes_nome", align: "left" },
      { name: "Prontuário Único", key: "pac_prontuario_unico", align: "right" },
      { name: "Prontuário da Unidade", key: "ppr_numero", align: "right" },
      { name: "Data da Internação", key: "int_data", align: "center" },
      { name: "Idade", key: "idade", align: "center" },
      { name: "Ocupação", key: "ocp_nome", align: "left" },
      { name: "CID Principal ", key: "cid_nome", align: "left" },
      {
        name: "Procedimento",
        key: "nome_procedimento_solicitado",
        align: "left",
      },
      { name: "Tempo de Internação", key: "tempo_internado", align: "left" },
      { name: "Município", key: "mun_nome", align: "left" },
      { name: "Acompanhante", key: "via_nome", align: "left" },
    ];
  },
  methods: {
    onInputRadioCustomBed(item) {
      if (item) {
        this.form.bedStatus = item;
      }
    },
    selectingSector(pValue) {
      const source = pValue && pValue.label;
      this.form.set_nome = source;
    },
    selectingPlace(pValue) {
      const source = pValue && pValue.label;
      this.form.lca_nome = source;
    },
    selectingBedType(pValue) {
      const source = pValue && pValue.text;
      this.form.isl_nome = source;
    },
    selectingHospitalizationType(pValue) {
      const source = pValue && pValue.text;
      if (source) {
        this.form.iti_nome = source;
      } else {
        this.form.iti_nome = null;
      }
    },
    selectingCity(pValue) {
      const source = pValue && pValue.source;
      this.form.mun_id = source && source.mun_id;
      this.form.mun_nome = source && source.mun_nome;
    },
    selectingState(pValue) {
      if (pValue && pValue.source) {
        this.form.est_sigla = pValue.source.est_sigla
          ? pValue.source.est_sigla
          : 0;
        this.form.est_id = pValue.source.est_id ? pValue.source.est_id : 0;
      }
    },
    selectingBedInfo(pBedType) {
      const source = pBedType && pBedType.source;
      this.form.itl_id = source && source.itl_id ? source.itl_id : null;
    },
    selectingHospitalizationBed(pBed) {
      const source = pBed && pBed.source;
      this.form.inl_id = source && source.inl_id ? source.inl_id : null;
    },
    selectingCbo(pValue) {
      const source = pValue && pValue.source;
      this.form.ocp_id = source.ocp_id ? source.ocp_id : null;
    },
    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Hospitalization/Report/GET_DAILY_ADMISSION_CENSUS_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    buildFilter() {
      const payLoad = {
        uns_id: this.form.uns_id,
        set_id: this.form.set_id,
        set_nome: this.form.set_nome,
        lca_id: this.form.lca_id,
        lca_nome: this.form.lca_nome,
        itl_nome: this.form.itl_nome,
        itl_id: this.form.itl_id,
        inl_id: this.form.inl_id,
        ocp_id: this.form.ocp_id,
        ocp_nome: this.form.ocp_nome,
        isl_id: this.form.isl_id,
        isl_nome: this.form.isl_nome,
        iti_id: this.form.iti_id,
        iti_nome: this.form.iti_nome,
        mun_id: this.form.mun_id,
        mun_nome: this.form.mun_nome,
        inl_extra:
          this.form.bedStatus[0] === 1 || this.form.bedStatus[1] === 1 ? 1 : 0,
        inl_observacao:
          this.form.bedStatus[0] === 2 || this.form.bedStatus[1] === 2 ? 1 : 0,
        inl_nome: this.form.inl_nome,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      if (this.$refs.status) {
        this.$refs.status.clearRadio();
      }
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
};
</script>
