<template>
  <Modulebox :title="title" class="patient-care-history">
    <div class="patient-care-history-body">
      <FormBase title="Busca de Atendimentos" class="filters">
        <RgValidatorForm ref="validator">
          <div class="fields">
            <RgInput
              v-if="patientInfo.pes_nome"
              id="pessoa"
              v-model="patientInfo.pes_nome"
              :disabled="true"
              :class="{ disable: true }"
              class="patient-name"
              label="Nome do Paciente"
            />

            <RgInput
              v-else
              id="paciente"
              v-model="patientInfo.pac_nome"
              :disabled="true"
              :class="{ disable: true }"
              class="patient-name"
              label="Nome do Paciente"
            />
            <RgComboboxModules id="modulo" v-model="form.moduleId" />

            <RgInputDate
              id="data-inicial"
              ref="initialDate"
              v-model="form.initialDate"
              :rules="{ fn: validateDate }"
              label="Data Inicial"
              pop-up="right"
            />

            <RgInputDate
              id="data-final"
              ref="finalDate"
              v-model="form.finalDate"
              :rules="{ fn: validateDate }"
              label="Data Final"
              pop-up="right"
            />

            <RgRadioCustom
              id="regulacao"
              label="Regulação"
              v-bind="propsRadio"
              @input="inputRadioCustom"
            />

            <div class="actions">
              <RgCleanButton id="limpar" @click="cleanFilter" />
              <RgSearchButton
                id="buscar"
                ref="search"
                @submit="searchFilter(true)"
              />
            </div>
          </div>
        </RgValidatorForm>

        <hr class="hr" />

        <span class="subtitle">Lista de Atendimentos</span>

        <section v-if="hasHistory" class="attendances">
          <Tooltip
            class="tooltip"
            message='Selecione um dos atendimentos da listagem para visualizar as informações na seção "Detalhes de atendimentos"'
            startOpen
          >
            <IconLightBulbHelper slot="icon" hollow />
          </Tooltip>

          <div class="printer">
            <RgDropdown
              id="printer-ebs"
              label="Imprimir"
              :disabled="!hasHistory"
              :class="{ disable: !hasHistory }"
              :action-options="itemActionOptionsPrinter(selectedLine)"
              large
            >
              <IconPrinter slot="icon" />
            </RgDropdown>
          </div>

          <SmartTable
            ref="smartTable"
            name="ModalPatientCareHistory"
            :columns="COLUMN"
            :body="mutableAttendanceList"
            :total="total"
            :initial-columns="9"
            :item-per-page="pagination.limit"
            :max-register="10"
            :removeBtnColumns="true"
            toggle-selected
            class="smart-table-patient-history"
            @getLine="selectLine"
            @pagination="getPagination"
          />

          <div v-if="!hasSelectedLine" class="service-details">
            <IconInfoHelper class="icon" :background="'#6f8894'" />
            <span class="message"> DETALHES DO ATENDIMENTO </span>
          </div>
        </section>

        <section v-else class="no-result">
          <IconEmpty />
          <span class="message">
            Não foram encontrados resultados para essa busca
          </span>
        </section>

        <section v-if="hasHistory" class="details">
          <!-- APPOINTMENT -->
          <section v-if="hasSelectedLine && isAppointment" class="appointment">
            <div
              v-if="isRegulation && attendanceQueueDetail.length > 0"
              class="regulation"
            >
              <span
                v-for="(item, index) in attendanceQueueDetail"
                :key="index"
                class="row"
              >
                <span class="details-title">
                  Regulação: {{ item.data_hora_log }} -
                  {{ item.tlg_nome }}
                </span>
                <hr />

                <div class="details-area">
                  <span>
                    Prioridade:
                    <strong>{{
                      item.pfi_nome || attendanceScheduleDetail.pfi_nome
                    }}</strong>
                  </span>
                  <span>
                    Solicitante:
                    <strong>{{
                      item.pes_nome ||
                      attendanceScheduleDetail.pes_nome_solicitante_fila
                    }}</strong>
                  </span>
                  <span>
                    Ocupação:
                    <strong>{{
                      item.flc_nome_ocupacao ||
                      attendanceScheduleDetail.ocp_nome
                    }}</strong>
                  </span>
                </div>

                <div class="details-area">
                  <span>
                    Unidade Solicitante:
                    <strong>{{
                      item.uns_nome || attendanceScheduleDetail.uns_nome
                    }}</strong>
                  </span>
                  <span>
                    Unidade Destino:
                    <strong>{{ selectedLine.uns_nome }}</strong>
                  </span>
                  <span>
                    Data da Solicitação:
                    <strong>{{
                      item.data_inserido_fila ||
                      attendanceScheduleDetail.data_inserido_fila
                    }}</strong>
                  </span>
                  <span>
                    Usuário:
                    <strong>{{
                      item.usu_nome || attendanceScheduleDetail.usu_nome
                    }}</strong>
                  </span>
                </div>
              </span>
            </div>

            <div
              v-if="
                isRegulation && Object.keys(attendanceScheduleDetail).length > 0
              "
              class="regulation"
            ></div>

            <div v-if="isExternally" class="externally-schedule">
              <span class="details-title"
                >Agendamento Externo:
                {{
                  attendanceQueueDetail[attendanceQueueDetail.length - 1]
                    .data_hora_log
                }}
              </span>
              <hr />
              <div class="details-area">
                <span>
                  Local:
                  <strong>{{
                    attendanceQueueDetail[0].local_agendamento_externo
                  }}</strong>
                </span>
                <span>
                  Observação:
                  <strong>{{
                    attendanceQueueDetail[0].observacao_agendamento_externo
                  }}</strong>
                </span>
              </div>
            </div>

            <div v-if="isSchedule">
              <span
                v-for="(item, index) in attendanceScheduleDetail"
                :key="index"
                class="row"
              >
                <br />
                <span class="details-title">
                  {{
                    upperCase(item.tlc_nome) || upperCase(item.tlg_nome) || ""
                  }}
                  -
                  {{ item.data_hora_log }}
                </span>
                <hr />
                <div class="details-area">
                  <span v-if="item.pfi_nome">
                    Prioridade:
                    <strong>{{ item.pfi_nome }}</strong>
                  </span>
                  <span v-if="item.pes_nome">
                    Solicitante:
                    <strong>{{ item.pes_nome }}</strong>
                  </span>
                  <span
                    v-if="
                      item.ocp_nome ||
                      item.flc_nome_ocupacao ||
                      item.flc_nome_sinonimos_ocupacoes
                    "
                  >
                    Ocupação:
                    <strong>{{
                      item.ocp_nome ||
                      item.flc_nome_ocupacao ||
                      item.flc_nome_sinonimos_ocupacoes
                    }}</strong>
                  </span>
                  <span v-if="item.uns_nome">
                    Unidade Solicitante:
                    <strong>{{ item.uns_nome }}</strong>
                  </span>
                  <span
                    v-if="selectedLine.uns_nome && item.tipo === 'Agendamento'"
                  >
                    Unidade Destino:
                    <strong>{{ selectedLine.uns_nome }}</strong>
                  </span>
                  <span
                    v-if="
                      item.data_agendamento &&
                      item.tipo === 'Agendamento' &&
                      !upperCase(item.tlc_nome).includes('EFETIVADO')
                    "
                  >
                    Data Agendamento:
                    <strong>{{ item.data_agendamento }}</strong>
                  </span>
                  <span v-if="item.data_inserido_fila">
                    Data da Solicitação:
                    <strong>{{ item.data_inserido_fila }}</strong>
                  </span>
                  <span v-if="item.pes_nome_consulta_funcionario">
                    Profissional:
                    <strong>{{ item.pes_nome_consulta_funcionario }}</strong>
                  </span>
                  <span
                    v-if="
                      item.efc_nome &&
                      item.tlc_nome !== 'EXCLUÍDO' &&
                      item.tlg_nome !== 'EXCLUÍDO'
                    "
                  >
                    Status Efetivação:
                    <strong>{{ item.efc_nome }}</strong>
                  </span>
                  <span v-if="item.pls_nome">
                    Convênio:
                    <strong>{{ item.pls_nome }}</strong>
                  </span>
                  <span v-if="item.lca_nome">
                    Local de Atendimento:
                    <strong>{{ item.lca_nome }}</strong>
                  </span>
                  <span v-if="item.usu_nome">
                    Usuário:
                    <strong>{{ item.usu_nome }}</strong>
                  </span>
                  <span v-if="item.cnl_obs || item.flg_obs">
                    Motivo Exclusão:
                    <strong>
                      {{
                        upperCase(item.cnl_obs) || upperCase(item.flg_obs) || ""
                      }}
                    </strong>
                  </span>
                  <span v-if="item.tcu_novo_tipo_consulta">
                    Objetivo:
                    <strong>{{ item.tcu_novo_tipo_consulta }}</strong>
                  </span>
                </div>
              </span>
            </div>
          </section>

          <!-- HOSPITALIZATION -->
          <section v-else-if="hasSelectedLine && isHospitalization">
            <div v-if="isRegulation" class="regulation">
              <span class="details-title">Regulação</span>
              <hr />
              <div class="details-area">
                <span>
                  Prioridade:
                  <strong>{{
                    attendanceQueueDetail.pfi_nome ||
                    attendanceScheduleDetail.pfi_nome
                  }}</strong>
                </span>
                <span>
                  Solicitante:
                  <strong>{{
                    attendanceQueueDetail.pes_nome ||
                    attendanceScheduleDetail.pes_nome_solicitante_fila
                  }}</strong>
                </span>
                <span>
                  Tipo do Leito:
                  <strong>{{
                    attendanceQueueDetail.itl_nome ||
                    attendanceScheduleDetail.itl_nome
                  }}</strong>
                </span>
              </div>
              <div class="details-area">
                <span>
                  Unidade Solicitante:
                  <strong>{{
                    attendanceQueueDetail.uns_nome ||
                    attendanceScheduleDetail.uns_nome
                  }}</strong>
                </span>
                <span>
                  Unidade Destino:
                  <strong>{{ selectedLine.uns_nome }}</strong>
                </span>
                <span>
                  Data da Solicitação:
                  <strong>{{
                    attendanceQueueDetail.data_inserido_fila ||
                    attendanceScheduleDetail.data_inserido_fila
                  }}</strong>
                </span>
                <span v-if="isSchedule || isExternally">
                  Data da Internação:
                  <strong>{{
                    isExternally
                      ? attendanceQueueDetail.data_agendamento_externo
                      : selectedLine.data
                  }}</strong>
                </span>
              </div>
            </div>

            <div v-if="isExternally" class="externally-schedule">
              <span class="details-title">Agendamento Externo</span>
              <hr />
              <div class="details-area">
                <span>
                  Local:
                  <strong>{{
                    attendanceQueueDetail.local_agendamento_externo
                  }}</strong>
                </span>
                <span>
                  Observação:
                  <strong>{{
                    attendanceQueueDetail.observacao_agendamento_externo
                  }}</strong>
                </span>
              </div>
            </div>

            <div
              v-if="isSchedule && attendanceScheduleDetail.length > 0"
              class="regulation"
            >
              <span
                v-for="(item, index) in attendanceScheduleDetail"
                :key="index"
                class="row"
              >
                <span class="details-title">
                  {{ upperCase(item.tlg_nome) + " - " + item.inl_data }}
                </span>
                <hr />
                <div class="details-area">
                  <span v-if="item.int_numero">
                    Internação:
                    <strong>{{ item.int_numero }}</strong>
                  </span>
                  <span v-if="item.inp_novo_nome_procedimento">
                    Procedimento:
                    <strong>{{ item.inp_novo_nome_procedimento }}</strong>
                  </span>
                  <span v-if="item.data_saida">
                    Data da Saída:
                    <strong>{{ item.data_saida }}</strong>
                  </span>
                </div>
                <div class="details-area">
                  <span v-if="item.inl_nome">
                    Leito:
                    <strong>{{ item.inl_nome }}</strong>
                  </span>
                  <span v-if="item.set_nome">
                    Setor:
                    <strong>{{ item.set_nome }}</strong>
                  </span>
                  <span v-if="item.leito_origem">
                    Origem:
                    <strong>{{ item.leito_origem }}</strong>
                  </span>
                  <span v-if="item.leito_destino">
                    Destino:
                    <strong>{{ item.leito_destino }}</strong>
                  </span>
                  <span v-if="item.cid_nome">
                    CID:
                    <strong>{{ item.cid_nome }}</strong>
                  </span>
                  <span v-if="item.motivo_saida">
                    Motivo da Saída:
                    <strong>{{ item.motivo_saida }}</strong>
                  </span>
                  <span v-if="item.destino_saida">
                    Local de Destino da Saída:
                    <strong>{{ item.destino_saida }}</strong>
                  </span>
                  <span v-if="item.fun_saida">
                    Funcionário Responsável pela Saída:
                    <strong>{{ item.fun_saida }}</strong>
                  </span>
                  <span v-if="item.ocp_saida">
                    Ocupação responsável saída:
                    <strong>{{ item.ocp_saida }}</strong>
                  </span>
                  <span v-if="item.usu_nome">
                    Usuário:
                    <strong>{{ item.usu_nome }}</strong>
                  </span>
                  <span v-if="item.obs_exclusao">
                    Motivo da exclusão:
                    <strong>{{ item.obs_exclusao }}</strong>
                  </span>
                </div>
              </span>
            </div>
          </section>

          <!-- EMERGENCY -->
          <section v-else-if="hasSelectedLine && isEmergency" class="emergency">
            <div>
              <span class="details-title">Boletim</span>
              <hr />
              <div class="details-area">
                <span>
                  Boletim:
                  <strong>{{ attendanceScheduleDetail.bol_numero }}</strong>
                </span>
                <span>
                  Data da Saída:
                  <strong>{{ attendanceScheduleDetail.data_saida }}</strong>
                </span>
                <span>
                  Usuário:
                  <strong>{{ attendanceScheduleDetail.usu_nome }}</strong>
                </span>
              </div>
              <div class="details-area">
                <span>
                  Motivo da Saída:
                  <strong> {{ attendanceScheduleDetail.dsg_nome }}</strong>
                </span>
                <span>
                  Local de Destino:
                  <strong>{{ attendanceScheduleDetail.lcd_nome }}</strong>
                </span>
              </div>
            </div>
          </section>

          <!-- EXAM -->
          <section v-else-if="hasSelectedLine && isExam" class="exam">
            <div
              v-if="isRegulation && attendanceQueueDetail.length > 0"
              class="regulation"
            >
              <span
                v-for="(item, index) in attendanceQueueDetail"
                :key="index"
                class="row"
              >
                <span class="details-title">
                  Regulação: {{ item.data_hora_log }} -
                  {{ item.tlg_nome }}
                </span>
                <hr />
                <div class="details-area">
                  <span>
                    Prioridade:
                    <strong>{{
                      item.pfi_nome || attendanceScheduleDetail.pfi_nome
                    }}</strong>
                  </span>
                  <span v-if="item.pes_nome_profissional">
                    Profissional:
                    <strong>{{ item.pes_nome_profissional }}</strong>
                  </span>
                  <span>
                    Solicitante:
                    <strong>{{
                      item.pes_nome ||
                      attendanceScheduleDetail.pes_nome_solicitante_fila
                    }}</strong>
                  </span>
                  <span v-if="!isSchedule">
                    Procedimento:
                    <strong>{{ item.feg_nome_procedimento }}</strong>
                  </span>
                </div>

                <div class="details-area">
                  <span>
                    Unidade Solicitante:
                    <strong>{{
                      item.uns_nome || attendanceScheduleDetail.uns_nome
                    }}</strong>
                  </span>
                  <span>
                    Unidade Destino:
                    <strong>{{ selectedLine.uns_nome }}</strong>
                  </span>
                  <span>
                    Data da Solicitação:
                    <strong>{{
                      item.data_inserido_fila ||
                      attendanceScheduleDetail.data_inserido_fila
                    }}</strong>
                  </span>
                  <span v-if="item.ocp_nome">
                    Ocupação:
                    <strong>{{ item.ocp_nome }}</strong>
                  </span>
                  <span v-if="item.pls_nome">
                    Convênio:
                    <strong>{{ item.pls_nome }}</strong>
                  </span>
                  <span v-if="item.lca_nome">
                    Local de Atendimento:
                    <strong>{{ item.lca_nome }}</strong>
                  </span>
                  <span v-if="item.eex_nome">
                    Status:
                    <strong>{{ item.eex_nome }}</strong>
                  </span>
                  <span v-if="item.subprocedimentos">
                    Subprocedimento:
                    <strong>{{ item.subprocedimentos }}</strong>
                  </span>
                  <span v-if="item.motivo_exclusao">
                    Motivo exclusão:
                    <strong>{{ item.motivo_exclusao }}</strong>
                  </span>
                  <span>
                    Usuário:
                    <strong>{{
                      item.usu_nome || attendanceScheduleDetail.usu_nome
                    }}</strong>
                  </span>
                </div>
              </span>
            </div>

            <div
              v-if="
                isRegulation && Object.keys(attendanceScheduleDetail).length > 0
              "
              class="regulation"
            ></div>

            <div v-if="isExternally" class="externally-schedule">
              <span class="details-title"
                >Agendamento Externo:
                {{
                  attendanceQueueDetail[attendanceQueueDetail.length - 1]
                    .data_hora_log
                }}</span
              >
              <hr />
              <div class="details-area">
                <span>
                  Local:
                  <strong>{{
                    attendanceQueueDetail[0].local_agendamento_externo
                  }}</strong>
                </span>
                <span>
                  Observação:
                  <strong>{{
                    attendanceQueueDetail[0].observacao_agendamento_externo
                  }}</strong>
                </span>
              </div>
            </div>

            <div v-if="isSchedule" class="regulation">
              <span
                v-for="(item, index) in attendanceScheduleDetail"
                :key="index"
                class="row"
              >
                <br /><br />
                <span class="details-title">
                  {{
                    upperCase(item.tlg_nome) || upperCase(item.tlc_nome) || ""
                  }}
                  -
                  {{ item.data_hora_log }}
                </span>
                <hr />
                <div class="details-area">
                  <span v-if="item.ees_nome">
                    Tipo de Escala:
                    <strong>{{ item.ees_nome }}</strong>
                  </span>
                  <span
                    v-if="
                      item.sip_sinonimo ||
                      item.feg_nome_procedimento ||
                      item.stp_novo_nome_procedimento
                    "
                  >
                    Procedimento:
                    <strong>{{
                      item.sip_sinonimo ||
                      item.feg_nome_procedimento ||
                      item.stp_novo_nome_procedimento
                    }}</strong>
                  </span>
                  <span v-if="item.subprocedimento">
                    {{
                      "Subprocedimento " + item.tlg_nome?.toLowerCase() + ": "
                    }}
                    <strong>{{ item.subprocedimento }}</strong>
                  </span>

                  <span v-if="item.ocp_nome">
                    Ocupação:
                    <strong>{{ item.ocp_nome }}</strong>
                  </span>
                  <span v-if="item.motivo_nao_efetivacao">
                    Motivo não Efetivação:
                    <strong>{{ upperCase(item.motivo_nao_efetivacao) }}</strong>
                  </span>
                  <span v-if="item.pls_nome">
                    Convênio:
                    <strong>{{ item.pls_nome }}</strong>
                  </span>
                  <span v-if="item.lca_nome">
                    Local de Atendimento:
                    <strong>{{ item.lca_nome }}</strong>
                  </span>
                  <span
                    v-if="
                      item.data_efetivacao &&
                      item.data_efetivacao !== '01/01/1970 00:00:00'
                    "
                  >
                    Data Efetivação:
                    <strong>{{ item.data_efetivacao }}</strong>
                  </span>
                  <span
                    v-if="
                      item.pes_nome_profissional &&
                      item.tlg_id &&
                      item.tlg_id !== '1' &&
                      item.tlg_id !== '4'
                    "
                  >
                    {{
                      item.subprocedimento !== ""
                        ? "Profissional Solicitante:"
                        : "Profissional:"
                    }}
                    <strong>{{ item.pes_nome_profissional }}</strong>
                  </span>
                  <span
                    v-if="
                      item.pes_nome_profissional &&
                      selectedLine.nome_modulo === 'EXAMES EFETIVAÇÃO'
                    "
                  >
                    Profissional:
                    <strong>{{ item.pes_nome_profissional }}</strong>
                  </span>
                  <span v-if="item.pes_nome_executante">
                    Profissional Executante:
                    <strong>{{ item.pes_nome_executante }}</strong>
                  </span>
                  <span v-if="item.eex_nome && item.tlg_nome !== 'EXCLUIDO'">
                    Status Efetivação:
                    <strong>{{ item.eex_nome }}</strong>
                  </span>
                  <span v-if="item.set_nome">
                    Setor:
                    <strong>{{ item.set_nome }}</strong>
                  </span>
                  <span v-if="item.subprocedimentosAdicionados">
                    Subprocedimento(s) Adicionado(s):
                    <strong>{{ item.subprocedimentosAdicionados }}</strong>
                  </span>
                  <span v-if="item.subprocedimentosRemovidos">
                    Subprocedimento(s) Removido(s):
                    <strong>{{ item.subprocedimentosRemovidos }}</strong>
                  </span>
                  <span v-if="item.motivo_exclusao || item.flg_obs">
                    Motivo exclusão:
                    <strong>{{ item.motivo_exclusao || item.flg_obs }}</strong>
                  </span>
                  <span v-if="item.pfi_nome">
                    Prioridade:
                    <strong>{{
                      item.pfi_nome || attendanceScheduleDetail.pfi_nome
                    }}</strong>
                  </span>
                  <span v-if="item.pes_nome || item.pes_nome_solicitante_fila">
                    Solicitante:
                    <strong>{{
                      item.pes_nome ||
                      attendanceScheduleDetail.pes_nome_solicitante_fila ||
                      item.pes_nome_solicitante_fila
                    }}</strong>
                  </span>
                  <span v-if="item.eal_vagas_ocupadas">
                    Vagas Ocupadas:
                    <strong>{{ item.eal_vagas_ocupadas }}</strong>
                  </span>
                  <span v-if="!isSchedule">
                    Procedimento:
                    <strong>{{ item.feg_nome_procedimento }}</strong>
                  </span>
                  <span v-if="item.uns_nome">
                    Unidade Solicitante:
                    <strong>{{
                      item.uns_nome || attendanceScheduleDetail.uns_nome
                    }}</strong>
                  </span>
                  <span
                    v-if="selectedLine.uns_nome && item.tipo === 'Agendamento'"
                  >
                    Unidade Destino:
                    <strong>{{ selectedLine.uns_nome }}</strong>
                  </span>
                  <span
                    v-if="
                      item.data_exame &&
                      selectedLine.nome_modulo !== 'EXAMES EFETIVAÇÃO'
                    "
                  >
                    Data Agendamento:
                    <strong>{{ item.data_exame }}</strong>
                  </span>
                  <span v-if="item.fil_data_pedido">
                    Data Pedido:
                    <strong>{{ item.fil_data_pedido }}</strong>
                  </span>
                  <span v-if="item.data_inserido_fila">
                    Data da Solicitação:
                    <strong>{{
                      item.data_inserido_fila ||
                      attendanceScheduleDetail.data_inserido_fila
                    }}</strong>
                  </span>
                  <span v-if="item.efl_data_solicitacao">
                    Data da Solicitação:
                    <strong>{{
                      item.efl_data_solicitacao ||
                      attendanceScheduleDetail.efl_data_solicitacao
                    }}</strong>
                  </span>
                  <span v-if="item.usu_nome">
                    Usuário:
                    <strong>{{ item.usu_nome }}</strong>
                  </span>
                </div>
              </span>
            </div>
          </section>
        </section>
      </FormBase>
    </div>

    <div class="patient-care-history-footer">
      <MediumButton label="Voltar" title="Voltar" @click="goBack" />
    </div>
  </Modulebox>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import moment from "moment";
import RgComboboxModules from "$hospitalization/common/component/combobox/rg-combobox-modules/RgComboboxModules";
import { FormBase, SmartTable } from "~tokio/foundation";
import PatientCareHistoryHTML from "$person/patient/html/patient-care-history/PatientCareHistoryHTML.js";
import DetailsPatientCareAppointmentScheduleHTML from "@/app/person/patient/html/patient-care-history/DetailsPatientCareAppointmentScheduleHTML";
import DetailsPatientCareAppointmentScheduleExternallyHTML from "@/app/person/patient/html/patient-care-history/DetailsPatientCareAppointmentScheduleExternallyHTML";
import DetailsPatientCareAppointmentRegulationHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareAppointmentRegulationHTML";
import DetailsPatientCareHospitalizationRegulationHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareHospitalizationRegulationHTML";
import DetailsPatientCareHospitalizationScheduleExternallyHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareHospitalizationScheduleExternallyHTML";
import DetailsPatientCareHospitalizationScheduleHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareHospitalizationScheduleHTML";
import DetailsPatientCareEmergencyHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareEmergencyHTML";
import DetailsPatientCareExamRegulationHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareExamRegulationHTML";
import DetailsPatientCareExamScheduleExternallyHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareExamScheduleExternallyHTML";
import DetailsPatientCareExamScheduleHTML from "@app/person/patient/html/patient-care-history/DetailsPatientCareExamScheduleHTML";
import {
  RgValidatorForm,
  RgInput,
  RgInputDate,
  RgCleanButton,
  RgSearchButton,
  RgRadioCustom,
  IconEmpty,
  IconInfoHelper,
  IconLightBulbHelper,
  MediumButton,
  IconPrinter,
  RgDropdown,
} from "~tokio/primitive";

import Tooltip from "~tokio/primitive/tooltip/tooltip/Tooltip";

export default {
  name: "PatientCareHistory",
  components: {
    Modulebox,
    RgValidatorForm,
    FormBase,
    SmartTable,
    RgInput,
    RgInputDate,
    RgCleanButton,
    RgSearchButton,
    RgRadioCustom,
    RgComboboxModules,
    IconEmpty,
    IconInfoHelper,
    IconPrinter,
    RgDropdown,
    Tooltip,
    IconLightBulbHelper,
    MediumButton,
  },
  data() {
    return {
      mutableAttendanceList: [],
      total: 0,
      selectedLine: {},
      attendanceScheduleDetail: {},
      attendanceQueueDetail: {},
      patientInfo: {},
      form: {
        patientId: null,
        patientName: null,
        moduleId: null,
        initialDate: null,
        finalDate: null,
        regulation: null,
      },
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },
  computed: {
    title() {
      return "Histórico de Atendimentos do Paciente";
    },

    hasHistory() {
      return this.mutableAttendanceList.length > 0;
    },

    hasSelectedLine() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },

    isAppointment() {
      return this.selectedLine.id_modulo === "2";
    },

    isHospitalization() {
      return this.selectedLine.id_modulo === "14";
    },

    isEmergency() {
      return this.selectedLine.id_modulo === "15";
    },

    isExam() {
      return this.selectedLine.id_modulo === "33";
    },

    isRegulation() {
      return this.selectedLine.regulacao === "SIM";
    },

    isExternally() {
      return (
        this.isRegulation &&
        this.attendanceQueueDetail &&
        this.attendanceQueueDetail.length > 0 &&
        this.attendanceQueueDetail[0].local_agendamento_externo.length > 0
      );
    },

    isSchedule() {
      return (
        this.attendanceScheduleDetail &&
        Object.keys(this.attendanceScheduleDetail).length > 0
      );
    },

    propsRadio() {
      const list = [
        { title: "Sim", blue: true, id: 1 },
        { title: "Não", blue: true, id: 2 },
      ];
      const value = this.form.regulation;
      const uniqueKey = "id";
      return { list, uniqueKey, value };
    },
  },
  watch: {
    "form.moduleId"(pValue, pPrev) {
      if (!pValue || (pPrev && pPrev !== pValue)) {
        this.cleanInformationAttendances();
      }
    },
  },
  mounted() {
    const params = this.$route.params;
    this.patientInfo = params;
    if (!(this.patientInfo?.pes_nome || this.patientInfo?.pac_nome)) {
      this.$router.go(-1);
      this.$toaster.info(
        "Selecione um registro para continuar o trabalho em andamento.",
        "Você foi redirecionado para a busca",
      );
    }
  },
  created() {
    this.COLUMN = [
      { name: "Módulo", key: "nome_modulo" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
      { name: "Setor", key: "set_nome", align: "left" },
      {
        name: "Usuário de Criação do Registro",
        key: "usu_nome",
        align: "left",
      },
      {
        name: "Data de Criação",
        key: "data_criacao",
        align: "center",
      },
      {
        name: "Data do Agendamento",
        key: "data_agendamento",
        align: "center",
      },
      {
        name: "Data da última modificação",
        key: "data_order_by_max",
        align: "center",
      },
      { name: "Regulação", key: "regulacao", align: "center" },
      { name: "Alteração", key: "possui_alteracao", align: "center" },
    ];
  },
  methods: {
    upperCase(pData) {
      return pData ? pData.toUpperCase() : pData;
    },
    itemActionOptionsPrinter(pSelectLine) {
      return [
        {
          label: "Histórico de Atendimentos",
          action: this.printHistoryPatientCare,
          disable: false,
        },
        {
          label: "Detalhes do Atendimento",
          action: () => {
            this.printDetailsPatientCare(pSelectLine);
          },
          disable: !this.hasSelectedLine,
        },
      ];
    },

    async printHistoryPatientCare() {
      try {
        const unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH"];
        const unitHealthData = {
          uns_nome: unitHealth.uns_nome,
          mun_nome:
            unitHealth.endereco?.neighborhood?.city?.mun_nome ||
            "NÃO INFORMADO",
          est_sigla:
            unitHealth.endereco?.neighborhood?.city?.state?.est_sigla ||
            "NÃO INFORMADO",
          bai_nome:
            unitHealth.endereco?.neighborhood?.bai_nome || "NÃO INFORMADO",
          end_numero: unitHealth.endereco?.end_numero || "NÃO INFORMADO",
        };

        const userName = this.$store.getters["Login/GET_USER_NAME"];
        const info = {
          userName,
          unitHealthData,
          patientName: this.patientInfo.pes_nome || this.patientInfo.pac_nome,
        };

        const data = this.mutableAttendanceList;
        const html = await PatientCareHistoryHTML(info, data);
        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      }
    },

    async printDetailsPatientCare(pSelectLine) {
      try {
        let html;
        const data = pSelectLine;

        const unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH"];
        const unitHealthData = {
          uns_nome: unitHealth.uns_nome,
          mun_nome:
            unitHealth.endereco?.neighborhood?.city?.mun_nome ||
            "NÃO INFORMADO",
          est_sigla:
            unitHealth.endereco?.neighborhood?.city?.state?.est_sigla ||
            "NÃO INFORMADO",
          bai_nome:
            unitHealth.endereco?.neighborhood?.bai_nome || "NÃO INFORMADO",
          end_numero: unitHealth.endereco?.end_numero || "NÃO INFORMADO",
        };

        const userName = this.$store.getters["Login/GET_USER_NAME"];
        const info = {
          userName,
          unitHealthData,
          patientName: this.patientInfo.pes_nome || this.patientInfo.pac_nome,
        };

        if (this.isAppointment) {
          if (this.isRegulation && this.attendanceQueueDetail.length > 0) {
            html = await DetailsPatientCareAppointmentRegulationHTML(
              info,
              data,
              this.selectedLine,
              this.attendanceQueueDetail,
              this.attendanceScheduleDetail,
            );
          } else if (this.isExternally) {
            html = await DetailsPatientCareAppointmentScheduleExternallyHTML(
              info,
              data,
              this.attendanceQueueDetail,
            );
          } else if (this.isSchedule) {
            html = await DetailsPatientCareAppointmentScheduleHTML(
              info,
              data,
              this.selectedLine,
              this.attendanceScheduleDetail,
            );
          } else {
            this.$toaster.warning("Não foi possível gerar a impressão");
          }
        }

        if (this.isHospitalization) {
          if (this.isRegulation) {
            html = await DetailsPatientCareHospitalizationRegulationHTML(
              info,
              data,
              this.selectedLine,
              this.attendanceQueueDetail,
              this.attendanceScheduleDetail,
            );
          } else if (this.isExternally) {
            html = await DetailsPatientCareHospitalizationScheduleExternallyHTML(
              info,
              data,
              this.attendanceQueueDetail,
            );
          } else if (
            this.isSchedule &&
            this.attendanceScheduleDetail.length > 0
          ) {
            html = await DetailsPatientCareHospitalizationScheduleHTML(
              info,
              data,
              this.attendanceScheduleDetail,
            );
          } else {
            this.$toaster.warning("Não foi possível gerar a impressão");
          }
        }

        if (this.isEmergency) {
          html = await DetailsPatientCareEmergencyHTML(
            info,
            data,
            this.attendanceScheduleDetail,
          );
        }

        if (this.isExam) {
          if (this.isRegulation && this.attendanceQueueDetail.length > 0) {
            html = await DetailsPatientCareExamRegulationHTML(
              info,
              data,
              this.selectedLine,
              this.attendanceQueueDetail,
              this.attendanceScheduleDetail,
            );
          } else if (this.isExternally) {
            html = await DetailsPatientCareExamScheduleExternallyHTML(
              info,
              data,
              this.attendanceQueueDetail,
            );
          } else if (this.isSchedule) {
            html = await DetailsPatientCareExamScheduleHTML(
              info,
              data,
              this.selectedLine,
              this.attendanceScheduleDetail,
            );
          } else {
            this.$toaster.warning("Não foi possível gerar a impressão");
          }
        }

        this.$utils.print.printHtml(html);
      } catch (error) {
        this.$toaster.error(error.message);
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    generateFilter() {
      const variables = {
        arrFiltro: {
          pes_id: this.patientInfo.pes_id,
          mod_id: this.form.moduleId,
          periodoInicial: this.form.initialDate,
          periodoFinal: this.form.finalDate,
          rdg_regulacao: this.form.regulation,
          limiteInicio: this.pagination.offset,
          limiteFim: this.pagination.limit,
        },
      };

      return variables;
    },

    async searchFilter(submitFromButton = false) {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          this.$refs.search.actionDone();
          return false;
        }

        if (this.hasSelectedLine) {
          if (this.$refs.smartTable) {
            this.$refs.smartTable.cleanSelectRow();
          }
          this.selectedLine = {};
        }

        if (submitFromButton) {
          this.pagination = {
            limit: 10,
            offset: 0,
            current: 1,
          };
        }

        this.$loader.start();

        this.$refs.search.actionSubmit();
        const variables = this.generateFilter();

        const generalHistory = await this.$store.dispatch(
          "Person/Patient/SEARCH_PATIENTS_GENERAL_HISTORY",
          variables,
        );

        this.mutableAttendanceList = generalHistory.data;
        this.total = Number(generalHistory.total);

        this.$refs.search.actionDone();
      } catch (pErr) {
        this.$toaster.error(pErr);
        this.$refs.search.actionDone();
      } finally {
        this.$loader.finish();
      }
    },

    getPagination(pPagination) {
      this.pagination = pPagination;
      this.searchFilter();
    },

    async searchDetailsHistoryConsultationPatient() {
      try {
        this.$loader.start();

        return await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_CONSULTATION_PATIENT",
          Number(this.selectedLine.id),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchDetailsHistoryQueueConsultationPatient() {
      try {
        this.$loader.start();

        return await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_QUEUE_CONSULTATION_PATIENT",
          Number(this.selectedLine.id_fila),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchDetailsHistoryExamPatient() {
      try {
        this.$loader.start();

        return await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_EXAM_PATIENT",
          Number(this.selectedLine.id),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchDetailsHistoryExamEffectivePatient() {
      try {
        this.$loader.start();

        return await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_EXAM_EFFECTIVE_PATIENT",
          Number(this.selectedLine.id),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchDetailsHistoryQueueExamPatient() {
      try {
        this.$loader.start();

        return await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_QUEUE_EXAM_PATIENT",
          Number(this.selectedLine.id_fila),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchDetailsHistoryHospitalizationPatient() {
      try {
        this.$loader.start();
        this.attendanceScheduleDetail = await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_HOSPITALIZATION_PATIENT",
          Number(this.selectedLine.id),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchDetailsHistoryQueueHospitalizationPatient() {
      try {
        this.$loader.start();

        this.attendanceQueueDetail = await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_QUEUE_HOSPITALIZATION_PATIENT",
          Number(this.selectedLine.id_fila),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async searchDetailsHistoryEmergencyPatient() {
      try {
        this.$loader.start();

        this.attendanceScheduleDetail = await this.$store.dispatch(
          "Person/Patient/GET_DETAILS_HISTORY_EMERGECY_PATIENT",
          Number(this.selectedLine.id),
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    async selectLine(pValue) {
      this.attendanceScheduleDetail = {};
      this.attendanceQueueDetail = {};

      this.selectedLine = pValue;

      if (this.hasSelectedLine) {
        if (this.isAppointment) {
          const aux1 =
            (await this.searchDetailsHistoryQueueConsultationPatient()) || [];
          const aux2 =
            (await this.searchDetailsHistoryConsultationPatient()) || [];

          aux1.forEach((item) => (item.tipo = "Regulação"));
          aux2.forEach((item) => (item.tipo = "Agendamento"));

          const aux3 = aux2.concat(aux1);

          this.attendanceScheduleDetail = aux3.sort(function (a, b) {
            return (
              new Date(
                moment(a.data_hora_log, "DD/MM/YYYY - HH:mm").format(
                  "YYYY-MM-DD HH:mm",
                ),
              ).getTime() -
              new Date(
                moment(b.data_hora_log, "DD/MM/YYYY - HH:mm").format(
                  "YYYY-MM-DD HH:mm",
                ),
              ).getTime()
            );
          });

          // FILTRO PARA ALTERAÇÕES SISTÊMICAS INDESEJADAS
          this.attendanceScheduleDetail.forEach((item, index) => {
            if (
              item.tipo === "Agendamento" &&
              item.tlc_nome === "AGENDADO" &&
              this.attendanceScheduleDetail[index + 1]?.tipo === "Regulação" &&
              (this.attendanceScheduleDetail[index + 1]?.tlc_nome ===
                "ALTERADO" ||
                this.attendanceScheduleDetail[index + 1]?.tlg_nome ===
                  "ALTERADO")
            ) {
              this.attendanceScheduleDetail.splice(index + 1, 1);
            }
          });
        }

        if (this.isHospitalization) {
          if (this.isRegulation && this.selectedLine.id_fila) {
            this.searchDetailsHistoryQueueHospitalizationPatient();
          } else {
            this.searchDetailsHistoryHospitalizationPatient();
          }
        }

        if (this.isExam) {
          let aux3;
          if (this.selectedLine.nome_modulo === "EXAMES EFETIVAÇÃO") {
            aux3 =
              (await this.searchDetailsHistoryExamEffectivePatient()) || [];
            if (aux3) aux3.forEach((item) => (item.tipo = "Efetivação"));
          } else {
            const aux1 =
              (await this.searchDetailsHistoryQueueExamPatient()) || [];
            const aux2 = (await this.searchDetailsHistoryExamPatient()) || [];
            aux1.forEach((item) => (item.tipo = "Regulação"));
            aux2.forEach((item) => (item.tipo = "Agendamento"));
            aux3 = aux2.concat(aux1);
          }
          this.attendanceScheduleDetail = aux3.sort(function (a, b) {
            return (
              new Date(
                moment(a.data_hora_log, "DD/MM/YYYY - HH:mm").format(
                  "YYYY-MM-DD HH:mm",
                ),
              ).getTime() -
              new Date(
                moment(b.data_hora_log, "DD/MM/YYYY - HH:mm").format(
                  "YYYY-MM-DD HH:mm",
                ),
              ).getTime()
            );
          });
          this.attendanceScheduleDetail.forEach((item, index) => {
            if (item.tipo === "Regulação") {
              if (item.tlg_id === "5")
                item.subprocedimentosRemovidos = item.subprocedimentos;
              if (item.tlg_id === "4")
                item.subprocedimentosAdicionados = item.subprocedimentos;
            }
          });
        }

        if (this.isEmergency) {
          this.searchDetailsHistoryEmergencyPatient();
        }
      }
    },

    selectingPatient(pPatient) {
      const source = pPatient.source ? pPatient.source : null;
      const hasPerson = source && source.pes_id > 0;

      if (hasPerson) {
        this.form.patientId = source.pes_id;
        this.form.patientName = source.pes_nome;
      }
    },

    inputRadioCustom(pRadio) {
      pRadio
        ? (this.form.regulation = pRadio.id)
        : (this.form.regulation = null);
    },

    validateDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const validation = initialDate.isAfter(finalDate);

      if (validation) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    cleanInformationAttendances() {
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
      this.mutableAttendanceList = [];
      this.total = 0;
      this.selectedLine = {};
      this.attendanceScheduleDetail = {};
      this.attendanceQueueDetail = {};
    },

    cleanPagination() {
      this.pagination.limit = 10;
      this.pagination.offset = 0;
      this.pagination.current = 1;
    },

    cleanFilter() {
      this.cleanInformationAttendances();

      this.$refs.initialDate.cleanValidate();
      this.$refs.finalDate.cleanValidate();

      this.form = {
        patientId: null,
        patientName: null,
        moduleId: null,
        initialDate: null,
        finalDate: null,
        regulation: null,
      };

      this.cleanPagination();
    },

    goBack() {
      this.$router.go(-1);
    },

    close() {
      this.cleanFilter();
      this.$emit("close");
    },
  },
};
</script>
