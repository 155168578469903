export default {
  CLEAN_MODULE_EXAM: (state) => {
    state.Schedule.selected_employee_data = null;
    state.Schedule.selected_exam_data = null;
    state.Schedule.selected_patient_data = null;
    state.Schedule.filter_per_day_data = null;
    state.Schedule.filter_per_period_data = null;
    state.Schedule.filter_date = null;
    state.Schedule.filter_exam_queue_data = null;
    state.Schedule.selected_queue_external_exam_data = null;
    state.Schedule.exam_schedule = null;
    state.Schedule.selected_patient_list_queue_data = null;

    state.Register.filter_form_type_scale = null;
    state.Register.form_schedule = null;
    state.Register.selected_row_list_service_scale = null;

    state.IndividualEffectivation.selected_data = null;
    state.IndividualEffectivation.filter = null;

    state.exam_permission_list = null;
  },
  SET_FILTER_BIOSLAB: (state, filter_bioslab) => {
    state.filter_bioslab = filter_bioslab;
  },
  RESET_FILTER_BIOSLAB: (state) => {
    state.filter_bioslab = null;
  },
  SET_EXAM_PERMISSION_LIST: (state, list_permission) => {
    state.exam_permission_list = list_permission;
  },
};
