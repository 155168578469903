<style src="./EmergencyReport.scss" lang="scss" scoped></style>
<template>
  <div class="grid">
    <div class="selectinput">
      <RgSelectUnithealth
        id="uns-id-adr"
        v-model="form.uns_id"
        class="inputitem"
        @change="selectingUnitHealth"
      />
    </div>
    <div class="filter-subtitle filter-title">
      <span class="period-subtitle">Período de Emergências</span>
    </div>
    <div class="selectinput _flex">
      <RgInputDate
        id="initial-date-er"
        v-model="form.initialDate"
        label="Data início"
        placeholder="Digite a Data Inicial"
        :rules="{ required: true }"
        class="inputitem"
      />
      <RgInputDate
        id="final-date-er"
        v-model="form.finalDate"
        label="Data final"
        placeholder="Digite a Data Final"
        :max-date="getMaxDateInitial"
        :min-date="getMinDateInitial"
        :rules="{ required: true }"
        class="inputitem mg"
      />
    </div>
  </div>
</template>

<script>
import { RgInputDate, RgSelectUnithealth } from "~tokio/primitive";
const moment = require("moment");

export default {
  name: "Emergency",
  components: {
    RgInputDate,
    RgSelectUnithealth,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Período de Emergências",
      form: {
        uns_id: null,
        uns_nome: "",
        initialDate: moment().add(-31, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "EmergencyReport";
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(31, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },
  watch: {
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(31, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "PACIENTE" },
      { name: "Idade", key: "IDADE" },
      { name: "Telefones", key: "TELEFONES" },
      { name: "Endereço", key: "ENDERECO" },
      { name: "Bairro", key: "BAIRRO", align: "left" },
      { name: "Cidade", key: "CIDADE", align: "left" },
      { name: "Nome da Mãe", key: "NOME_DA_MAE" },
      { name: "Unidade de Saúde", key: "UNIDADE_DE_SAUDE" },
      { name: "Data de Atendimento", key: "DATA_ATENDIMENTO" },
      { name: "Hora de Atendimento", key: "HORA_ATENDIMENTO" },
      { name: "Setor", key: "SETOR" },
      { name: "Usuário", key: "USUARIO" },
    ];
  },
  mounted() {},
  methods: {
    buildFilter() {
      return {
        uns_id: this.form.uns_id,
        uns_nome: this.form.uns_nome,
        initialDate: String(this.form.initialDate),
        finalDate: String(this.form.finalDate),
        columnsToPrint: this.columnsToPrint,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_EMERGENCY_REPORTS",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    selectingUnitHealth(pValue) {
      const hasSuggestion = pValue && Object.keys(pValue).length > 0;
      if (hasSuggestion) {
        this.form.uns_nome = pValue.label;
      } else {
        this.form.uns_nome = "";
      }
    },
    clearFilter() {
      this.form = {
        uns_id: null,
        uns_nome: "",
        initialDate: moment().add(-31, "days").format("DD/MM/YYYY"),
        finalDate: moment().format("DD/MM/YYYY"),
      };
    },
  },
};
</script>
