import AuthRequest from "~common/request/AuthRequest";
export default async (pCurrentUnitHealthId) => {
  try {
    return AuthRequest.post("/access-control/change-current-unithealth", {
      pCurrentUnitHealthId,
    });
  } catch (err) {
    throw new Error(
      "Falha ao atualizar a Unidade de Saúde corrente " + pCurrentUnitHealthId,
    );
  }
};
