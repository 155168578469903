import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation CreateIndividualPatientRegistration(
    $basicAttentionIntegrations: AbIntegrationsInputType!
  ) {
    CreateIndividualPatientRegistration(
      basicAttentionIntegrations: $basicAttentionIntegrations
    ) {
      success
      message
    }
  }
`;

export default async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });

    return data.CreateIndividualPatientRegistration;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
