import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SaveConsolidatedBilling($consolidatedBilling: Int) {
    RemoveConsolidatedBilling(fco_id: $consolidatedBilling)
  }
`;

export default async ({ commit }, { variables = {} }) => {
  const { data } = await GraphQLClient.mutate({ mutation, variables }).catch(
    (pErr) => {
      console.log(pErr);
      throw pErr;
    },
  );

  commit("REMOVE_CONSOLIDATED_ITEM", variables.consolidatedBilling);
  return data;
};
