/**
 *
 * Dependents:
 */
import moment from "moment";
import { isFunction } from "lodash";
import { removeExtraSpaces } from "@/common/utils/sanitize";

export class RgValidatorRules {
  constructor(rules = {}) {
    this.rules = rules;
    this.error = [];
    moment.locale("pt-BR");
  }

  defineRules(newRules) {
    this.rules = newRules;
  }

  async isValid(pValue, bindThis = {}) {
    const rulesResult = [];
    this.error = [];
    for (const rule in this.rules) {
      const isValidValidation =
        isFunction(this[rule]) || isFunction(this.rules[rule]);

      // Check if the rule passed is a valid rule
      if (!isValidValidation) {
        rulesResult.push(Promise.resolve({ valid: false, errors: this.error }));
      } else if (isFunction(this[rule])) {
        // não é uma validação global
        const valid = await this[rule](pValue, this.rules[rule]);
        rulesResult.push(Promise.resolve({ valid, errors: this.error }));
      } else {
        // provavel que seja uma validação local ( anotherRules )
        const valid = await this.fn(pValue, this.rules[rule].bind(bindThis));
        rulesResult.push(Promise.resolve({ valid, errors: this.error }));
      }
    }

    return new Promise((resolve, reject) => {
      Promise.all(rulesResult).then((pResults) => {
        let valid = true;
        const errors = [];
        for (const ruleResult in pResults) {
          if (pResults[ruleResult].valid === false) {
            errors.push(pResults[ruleResult].errors);
            valid = false;
          }
        }
        return resolve({ valid, errors });
      });
    });
  }

  required(pValue, pParams) {
    if ((!pValue || pValue === "false") && pParams === true) {
      this.error.push("Campo obrigatório");
      return false;
    }
    return true;
  }

  listRequired(pValue, pParams) {
    const isList =
      Array.isArray(pValue) && pValue.length > 0 && pParams === true;

    if (isList) {
      return true;
    }

    this.error.push("Campo obrigatório");
    return false;
  }

  min(pValue, minSize) {
    if (typeof pValue === "string" && pValue.length < minSize) {
      this.error.push(`O campo deve possuir pelo menos ${minSize} caracteres`);
      return false;
    }
    return true;
  }

  max(pValue, maxSize) {
    if (typeof pValue === "string" && pValue.length > maxSize) {
      this.error.push(`O campo deve possuir no máximo ${maxSize} caracteres`);
      return false;
    } else if (pValue.toString().length > maxSize) {
      this.error.push(`O campo deve possuir no máximo ${maxSize} caracteres`);
      return false;
    }
    return true;
  }

  number(pValue, pParams) {
    if (isNaN(pValue)) {
      this.error.push("O campo deve ser numérico");
      return false;
    }
    return true;
  }

  email(pValue, pParams) {
    let regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
    const valid = regex.test(pValue);

    if (!valid) {
      this.error.push("O campo deve ser um e-mail válido");
    }

    return valid;
  }

  equal(pValue, pParams) {
    if (pValue !== pParams.valor) {
      this.error.push(`Campo diferente de ${pParams.campo}`);
      return false;
    }
    return true;
  }

  date(pValue, pParams) {
    if (!moment(pValue, pParams).isValid()) {
      this.error.push(`A data deve estar no formato ${pParams}`);
      return false;
    }
    return true;
  }

  name(pValue, pParams) {
    if (!pValue || pValue === "") return;
    const nickname = pValue.split(" ");

    if (nickname[0].length < 2 || nickname[nickname.length - 1].length < 2) {
      this.error.push(
        `O campo não deve possuir primeiro e ultimos nomes abreviados`,
      );
      return false;
    }
    return true;
  }

  fullName(pValue, pParams) {
    if (!pValue || pValue === "") return;
    const fullName = pValue.split(" ");
    if (fullName.length < 2) {
      this.error.push("O campo deve possuir nome e sobrenome");
      return false;
    }
    const name = fullName[0];
    const surName = fullName[1];
    if (name.length === 1 || surName.length === 1) {
      this.error.push("Informe ao menos 2 caracteres para nome e sobrenome");
      return false;
    }
    return true;
  }

  validateEspecialCharacter(pValue, pParams) {
    let hasSpecial = "";

    if (pParams === "name") {
      hasSpecial = /[-!$%^&*()@_^+#¨|´~=`{}[\]:";<>?,\\./]/.test(pValue);
    } else {
      hasSpecial = /[-!$%^&*()@_^+#¨|´~=`{}[\]:";'~<>?,\\./]/.test(pValue);
    }

    if (hasSpecial) {
      this.error.push("O campo não deve possuir caracteres especiais");
      return false;
    }

    return true;
  }

  compositeValue(pValue, pParams) {
    const { required } = pParams;
    const isRequired = !!required; // default false

    if (!pValue && isRequired) {
      this.error.push("Campo obrigatório");
      return false;
    }

    const value = pValue.split(" ");

    if (
      value[0].length > 0 &&
      (value[0].length < 2 || value[value.length - 1].length < 2)
    ) {
      this.error.push(`O primeiro e último nome não podem ser abreviados`);
      return false;
    }

    if (value[0].length > 0 && value[1] === undefined) {
      this.error.push("O campo deve possuir nome e sobrenome");
      return false;
    }

    return true;
  }

  validateIndividualPatientRegistration(pValue) {
    if (!pValue) {
      return true;
    }

    const noApostrophesValue = pValue.replaceAll("'", "");
    const value = removeExtraSpaces(noApostrophesValue).split(" ");

    if (value[0].length > 0 && value[1] === undefined) {
      this.error.push("O campo deve possuir nome e sobrenome");
      return false;
    }

    if (value[0].length < 3) {
      this.error.push("O primeiro nome deve conter pelo menos 3 caracteres");
      return false;
    }

    if ((value.length > 1 && value[0].length === 1) || value[1].length === 1) {
      this.error.push(`O segundo nome não pode ser abreviado`);
      return false;
    }

    for (const element of value) {
      if (this.allEqualCharacter(element)) {
        this.error.push(
          `O campo não permite que um dos termos seja composto apenas por caracteres iguais e consecutivos`,
        );
        return false;
      }
    }

    for (const element of value) {
      const validCharacters = "III";
      if (element.toUpperCase() === validCharacters) {
        continue;
      }
      const amountCharacters = [];
      element.toUpperCase().replace(/(.)\1*/g, (m) => {
        return amountCharacters.push(m.length);
      });

      const moreThanThree = (element) => element >= 3;
      if (amountCharacters.some(moreThanThree)) {
        this.error.push(
          `O campo não permite que um dos termos contenha 3 ou mais consoantes ou vogais iguais em sequência`,
        );
        return false;
      }
    }
  }

  allEqualCharacter(pValue) {
    const validCharacters = "III";

    if (!pValue || pValue.length === 1) {
      return false;
    }

    if (pValue.toUpperCase() === validCharacters) {
      return false;
    }

    const characters = pValue.split("");
    const hasEqualCharacters = characters.every((char) => {
      return char.toUpperCase() === pValue[0].toUpperCase();
    });

    return hasEqualCharacters;
  }

  addressNumber(pValor, pParams) {
    const regexNumber = /^[0-9]+$/;
    const regexChar1 = /\b(s|S)\b[/]\b(n|N)\b/;
    const regexChar2 = /\b(s|S)(n|N)\b/;
    const isValidNumber = regexNumber.test(pValor);
    const isValidChar1 = regexChar1.test(pValor);
    const isValidChar2 = regexChar2.test(pValor);
    if (!isValidNumber && !isValidChar1 && !isValidChar2) {
      this.error.push("O campo deve conter apenas números, sn ou s/n");
      return false;
    }
    return true;
  }

  fn(pValue, pFunction) {
    return pFunction(pValue, this.error);
  }

  getError() {
    return this.error;
  }

  validatorIfExistNumber(pValor, pParams) {
    const regex = /\d+/;

    const containsNumber = regex.test(pValor);
    if (containsNumber) {
      this.error.push("Este campo deve conter apenas letras.");
      return false;
    }
    return true;
  }

  maxTodayDate(pData) {
    const data = moment(pData, "DD/MM/YYYY");
    if (!data.isValid()) {
      return true;
    }
    const hoje = moment();
    if (data.isAfter(hoje)) {
      this.error.push(
        `A data é maior que hoje, e não deve ser usada neste caso.`,
      );
      return false;
    }
  }

  cannotContainNumbers(pValue, pParams) {
    const format = /[0-9]+/;

    if (format.test(pValue)) {
      this.error.push("O campo não pode conter números");
      return false;
    }

    return true;
  }
}
