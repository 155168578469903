<template lang="html">
  <section class="hospitalization-billing-neonatal">
    <div>
      <form class="form">
        <RgValidatorForm ref="validator">
          <FormBase title="Neonatal">
            <div class="hospitalization-billing-neonatal-info">
              <RgInputMasked
                v-model="neonatal.inn_numero_meses_gestacao"
                :rules="{ required: true }"
                label="Mês Gestacional"
                placeholder="Digite o Mês Gestacional"
                mask="#"
                class="inputitem"
              />
              <RgInputMasked
                v-model="neonatal.inn_peso_nascer"
                :rules="{ required: true }"
                label="Peso ao Nascer (g)"
                placeholder="Digite o Peso ao Nascer"
                mask="####"
                class="inputitem"
              />
              <RgComboboxNeonatalExits
                v-model="neonatal.inn_id_internacoes_saidas_neonatal"
                :rules="{ required: true }"
                label="Motivo de Saída"
                placeholder="Digite o Motivo de Saída"
                class="inputitem"
              />
            </div>
          </FormBase>
          <div class="buttons">
            <MediumButton
              title="Confirmar"
              label="Confirmar"
              backgroundColor="#0bb98c"
              @click="confirm"
            />

            <MediumButton
              title="Fechar"
              label="Fechar"
              @click.stop.prevent="close"
            />
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </section>
</template>

<script>
import { RgValidatorForm, RgInputMasked, MediumButton } from "~tokio/primitive";
import RgComboboxNeonatalExits from "$billing/submodules/aih/hospitalization-billing/common/component/combobox/rg-combobox-neonatal-exits/RgComboboxNeonatalExits";
import FormBase from "~tokio/foundation/form-base/FormBase";
import mask from "vue-the-mask";

export default {
  name: "RgContentHospitalizationBillingNeonatal",
  components: {
    FormBase,
    RgValidatorForm,
    RgInputMasked,
    RgComboboxNeonatalExits,
    MediumButton,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      default: "",
    },
    requiredDocuments: {},
  },
  data() {
    return {
      neonatal: {
        type: "neonatal",
        inn_id_internacoes_saidas_neonatal: "",
        inn_numero_meses_gestacao: "",
        inn_peso_nascer: "",
      },
    };
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.neonatal = this.procedure.additionalData[0];
    }
  },
  methods: {
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.neonatal);
      }
    },

    close() {
      this.$emit("close");
    },

    async validateForm() {
      const valid = await this.$refs.validator.validate();
      return valid;
    },
  },
};
</script>
