<template>
  <Modulebox
    title="Agendar Externamente"
    class="appointment-regulation-form-external-schedule"
  >
    <QuickAppointmentExternalScheduleRegulationForm
      ref="form"
      class="appointment-regulation-form-external-schedule-form"
    />

    <div class="footer">
      <RgCancelButton ref="cancelButton" medium class="save" @click="cancel" />

      <RgSaveButton
        ref="saveButton"
        medium
        class="save"
        type="button"
        @click="saveForm"
      />
    </div>
  </Modulebox>
</template>

<script>
import QuickAppointmentExternalScheduleRegulationForm from "$appointment/common/component/quick-appointment-external-schedule-regulation-form/QuickAppointmentExternalScheduleRegulationForm";
import { RgSaveButton, RgCancelButton } from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";

export default {
  name: "AppointmentRegulationFormExternalSchedule",
  components: {
    RgSaveButton,
    Modulebox,
    QuickAppointmentExternalScheduleRegulationForm,
    RgCancelButton,
  },

  data() {
    return {};
  },

  destroyed() {
    const existThisRouteSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-periods",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteSchedulePerPeriods) {
      this.$store.commit(
        "Appointment/Schedule/RESET_FILTER_APPOINTMENT_PER_PERIODS",
      );
    }
  },

  methods: {
    async saveForm() {
      try {
        this.$refs.saveButton.actionSubmit();
        await this.$refs.form.saveForm();
        this.$refs.saveButton.actionDone();
      } catch (Err) {
        this.$refs.saveButton.fail();
      }
    },

    cancel() {
      this.$router.push({ name: "appointment.schedule.queue" });
    },
  },
};
</script>
