<style src="./RgSuggestPatient.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient">
    <RgSuggest
      ref="innerSuggest"
      v-model="inputValue"
      :disabled="disabled"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataItem"
        :idx="idx"
        :item="item"
        class="patient-item"
      >
        <div class="patient-row-top">
          <span class="pes-name">
            <IconUser class="icon-patient" />
            <span class="mg-l-5"> {{ item.pes_nome }} </span>
          </span>
          <span class="pes-mother">
            <IconGenderFemale class="icon-mother" />
            <span class="mg-l-5">
              {{ item.pes_mae }}
            </span>
          </span>
        </div>
        <div class="patient-info">
          <div class="birthday">
            <IconCalendar class="icon-birthday" />
            <span class="mg-l-5 pes-birthday">
              {{ item.pes_nascimento | dateBR }}
            </span>
          </div>
          <div class="cns">
            <IconCard class="icon-cns" />
            <span class="mg-l-5 pes-cns">
              {{ item.cns.crs_numero | Cns }}
            </span>
          </div>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import {
  IconCalendar,
  IconGenderFemale,
  IconUser,
  IconCard,
} from "~tokio/primitive/icon";
import { SearchPatient } from "./action";
import moment from "moment";

export default {
  name: "RgSuggestPatient",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconCalendar,
    IconGenderFemale,
    IconCard,
    IconUser,
  },
  filters: {
    Cns(pValue) {
      if (!pValue) return "CNS NÃO CADASTRADO";
      return pValue.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
    dateBR(pValue) {
      const isValid = moment(pValue).isValid();
      return isValid
        ? moment(pValue).format("DD/MM/YYYY")
        : "Data não informada";
    },
  },
  mixins: [RgSuggestMixin],
  props: {
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enabledPatient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "pes_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      const variables = { peopleName: pSearchTerm };

      if (this.enabledPatient) variables.isSearchable = this.enabledPatient;

      return SearchPatient({ variables });
    },
    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.pes_nome) && pValue.patient.pac_id > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            pes_nome: pValue.pes_nome,
            patient: {
              pac_id: pValue.patient.pac_id,
            },
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    validate() {
      this.$refs.innerSuggest.validate();
    },
  },
};
</script>
