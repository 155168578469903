import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path:
    "/appointment/schedule/queue/schedule/edit-patient/patient-care-history",
  name: "appointment.schedule.queue.schedule.edit-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Regulação", link: OpenSubModule("appointment") },
      { label: "Fila de Regulação", link: "/appointment/schedule/queue" },
      { label: "Agendar", link: "/appointment/schedule/queue/schedule" },
      {
        label: "Cadastro do Paciente",
        link: "/appointment/schedule/queue/schedule/edit-patient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
