<template>
  <button
    :id="id"
    :data-id="dataId"
    :disabled="permission ? disabled : true"
    :class="{
      disable: permission ? disabled : true,
      disablebtn: permission ? disabled : true,
      nopermission: !permission,
    }"
    :style="{
      background: backgroundColor,
    }"
    class="rg-button"
    :title="permission ? title : 'Usuário sem permissão'"
    @click.stop.prevent="clicked"
  >
    <slot />
  </button>
</template>

<script>
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";
export default {
  name: "RgButton",
  components: {},
  mixins: [RgButtonMixin],
  methods: {
    clicked() {
      if (this.disabled || !this.permission) {
        return;
      }

      this.$emit("click");
    },
  },
};
</script>
