import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchCiapTypeClassification(
    $code: String
    $description: String
    $limit: Int
    $offset: Int
  ) {
    SearchCiapTypeClassification(
      cia_codigo: $code
      cia_descricao: $description
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        cia_id
        cia_codigo
        cia_descricao
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.SearchCiapTypeClassification;
};
