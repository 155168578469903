import SEARCH_INTEGRATIONS from "./SearchIntegrations";
import CREATE_INTEGRATIONS from "./CreateIntegrations";
import SOFT_DELETETE_INTEGRATIONS from "./SoftDeleteteIntegrations";
import SEARCH_INTEGRATION_SERVICES from "./SearchIntegrationServices";
import RESEND_PEP_EXAM_INTEGRATION_TO_PEP from "./ResendEffectivedExamToPep";
import SAVE_PREFERENCES from "./SavePreferences";

export default {
  SEARCH_INTEGRATIONS,
  CREATE_INTEGRATIONS,
  SOFT_DELETETE_INTEGRATIONS,
  SEARCH_INTEGRATION_SERVICES,
  RESEND_PEP_EXAM_INTEGRATION_TO_PEP,
  SAVE_PREFERENCES,
};
