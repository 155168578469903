<style src="./RgSuggestEmployee.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    ref="suggest"
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :tabIndex="tabIndex"
    :disabled="disabled"
    :rules="rules"
    min="2"
    class="rg-suggest-employee"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="employee-item"
    >
      <div class="employee-row-top">
        <span class="employee-name">
          <IconUser class="icon-employee" />
          <span class="mg-l-5"> {{ item.pes_nome }} </span></span
        >
      </div>
      <div class="employee-row-info">
        <div v-if="item.ocp_nome" class="ocp-name">
          <IconHospital class="icon-ocp" />
          <span>{{ item.ocp_nome }}</span>
        </div>
        <div v-if="item.crs_numero" class="cns">
          <IconCard class="icon-cns" />
          <span>{{ item.crs_numero }}</span>
        </div>
        <div v-if="item.equ_codigo_ine" class="cns">
          <IconCard class="icon-cns" />
          <span>{{ item.equ_codigo_ine }}</span>
        </div>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import { IconUser, IconCard, IconHospital } from "~tokio/primitive/icon";
import { SearchEmployee } from "./action";
import Store from "@/store";

export default {
  name: "RgSuggestEmployee",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconUser,
    IconCard,
    IconHospital,
  },
  mixins: [RgSuggestMixin],
  props: {
    /**
     * Define field will show in the caption
     * @type String
     */
    captionRaw: {
      type: String,
      default: "pes_nome",
    },
    placeholder: {
      type: String,
      default: "Digite o nome do profissional",
    },
  },
  methods: {
    doSearch(pSearchTerm) {
      return SearchEmployee({
        searchQuery: pSearchTerm,
        uns_id: Store.getters["Login/GET_UNIT_HEALTH_ID"],
      });
    },
    validate() {
      this.$refs.suggest.validate();
    },
    cleanValidate() {
      this.$refs.suggest.cleanValidate();
    },
  },
};
</script>
