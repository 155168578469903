import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    intIdMunicipioProduto: variables.mpd_id,
    intEstado: variables.mpd_bloqueado,
    strObservacoes: variables.mpd_obs,
  };
  return AuthLegacyRequest.post(
    "farmacia/controller-municipio-produto/alterar-estado",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
