import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, { intIdConsulta }) => {
  intIdConsulta = Array.isArray(intIdConsulta)
    ? intIdConsulta
    : [intIdConsulta];
  return AuthLegacyRequest.post(
    "consultas/controller-agendamento-consulta/obter-comprovante-agendamento-em-lote",
    { arrayIdsConsulta: intIdConsulta },
  )
    .then(({ data }) => {
      data = HandleResponse(data);
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
