<template>
  <div v-show="show" class="modal-see-complexity">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-see-complexity-header">
        <span class="title">
          Visualizar Detalhes do Procedimento Selecionado
        </span>
      </div>
      <div slot="body">
        <FormBase title="Dados do Procedimento">
          <div class="container-info">
            <span class="content">
              Complexidade:
              <strong>
                {{ dataComplexity.complexity || "-" }}
              </strong>
            </span>
            <span class="content">
              Procedimento:
              <strong>
                {{ dataComplexity.procedureCode || "-" }} -
                {{ dataComplexity.procedureName || "-" }}
              </strong>
            </span>
          </div>
          <div class="container-data">
            <span class="content">
              Instrumento:
              <strong> {{ dataComplexity.instrument || "-" }} </strong>
            </span>
            <span class="content">
              Quantidade:
              <strong> {{ dataComplexity.quantity || "-" }} </strong>
            </span>
          </div>
          <div class="container-info no-space">
            <span class="content">
              CID-10:
              <strong>
                {{ dataComplexity.cid || "-" }}
              </strong>
            </span>
            <span class="content">
              Ocupação:
              <strong> {{ dataComplexity.occupation || "-" }} </strong>
            </span>
          </div>
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal } from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";

const FORM = {
  complexity: null,
  procedureCode: null,
  procedureName: null,
  cid: null,
  instrument: null,
  quantity: null,
  occupation: null,
};
export default {
  name: "ModalSeeComplexity",
  components: {
    RgBaseModal,
    FormBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedLine: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataComplexity: this.$utils.obj.DeepCopy(FORM),
    };
  },
  computed: {},
  watch: {
    show: {
      handler(pValue) {
        if (pValue) {
          this.fillData();
        }
        if (!pValue) {
          this.close();
        }
      },
    },
  },
  mounted() {},
  created() {},
  methods: {
    fillData() {
      if (this.selectedLine.length > 0) {
        this.dataComplexity = {
          complexity: this.selectedLine[0].complexityName,
          procedureCode: this.selectedLine[0].procedureCode,
          procedureName: this.selectedLine[0].procedureName,
          cid: this.selectedLine[0].cid,
          instrument: this.selectedLine[0].instrument,
          quantity: this.selectedLine[0].quantity,
          occupation: this.selectedLine[0].occupation,
        };
      } else {
        this.dataComplexity = this.$utils.obj.DeepCopy(FORM);
      }
    },
    close() {
      this.$emit("close");
      this.dataComplexity = this.$utils.obj.DeepCopy(FORM);
    },
  },
};
</script>
