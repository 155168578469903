<template lang="html">
  <Modulebox
    :title="itsEditing ? 'Editar Exame' : 'Novo Exame'"
    class="exam-details-effectivation"
  >
    <div class="exam-details-effectivation-body">
      <form class="form-container">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente">
            <RgSuggestPatientInformation
              id="patient"
              ref="patient"
              v-model="suggestPatient"
              :with-patient-module="['exam2']"
              :enabled-patient="false"
              :rules="{ forceSelection: true, required: true }"
              :extra-data="['withoutAddress']"
              :withPatientModule="[
                'emergency',
                'emergency_data',
                'hospitalization',
                'hospitalization_data',
              ]"
              class="inputpatient"
              filterBulletinHospitalization
              @patientInfo="selectingPatient"
            />
          </FormBase>

          <FormBase title="Exame" class="formbase">
            <div class="grid grid-row-2">
              <RgSelectSector
                id="sector"
                ref="sector"
                v-model="form.exe_id_setores"
                :unit-health="form.exe_id_unidades_saude"
                :permission="sectorPermission"
                :rules="{ required: true }"
                data-id="setor"
                label="Setor"
                class="row-field"
              />

              <RgSuggestProcedureEffectivation
                id="procedure"
                ref="procedure"
                v-model="suggestProcedure"
                :rules="{ required: true }"
                label="Procedimento"
                field-caption="NO_PROCEDIMENTO"
                @selected="selectingProcedure"
              />

              <RgInputDate
                id="request-date"
                ref="requestDate"
                v-model="form.exe_data_solicitacao"
                :max-date="new Date()"
                :rules="{ required: true }"
                label="Data da Solicitação"
              />

              <RgInputHour
                id="request-hour"
                ref="requestHour"
                v-model="requestHour"
                :rules="{ required: true, fn: validateRequestHour }"
                label="Hora da Solicitação"
              />

              <RgSuggestEmployee
                id="request-employee"
                ref="requester"
                v-model="suggestRequestEmployee"
                :rules="{ required: true, forceSelection: true }"
                label="Profissional Solicitante"
                @selected="selectingRequestEmployee"
              />
            </div>

            <div class="grid grid-row-3">
              <RgSuggestCid
                id="cid"
                ref="mainCid"
                v-model="mainCid"
                @selected="selectingCidCode"
              />

              <RgInputDate
                id="effected-date"
                ref="effectedDate"
                v-model="form.exe_data_efetivacao"
                :max-date="new Date()"
                :rules="{ fn: validateEffectedDate }"
                label="Data da Efetivação"
              />

              <RgInputHour
                id="effected-hour"
                ref="effectedHour"
                v-model="effectedHour"
                :rules="{
                  required: hasEffectiveDate === true,
                  fn: validateEffectedHour,
                }"
                label="Hora da Efetivação"
              />

              <RgSuggestEmployee
                id="performer-employee"
                ref="employee"
                v-model="suggestEmployee"
                :sector="form.exe_id_setores"
                :sector-required="true"
                :compatible-with-cod-procedure="form.exe_codigo_procedimento"
                :compatible-with-cod-procedure-required="true"
                :disabled="
                  !form.exe_data_efetivacao ||
                  !form.exe_id_setores ||
                  !form.exe_codigo_procedimento
                "
                :class="{
                  disable:
                    !form.exe_data_efetivacao ||
                    !form.exe_id_setores ||
                    !form.exe_codigo_procedimento,
                }"
                :rules="{
                  required: hasEffectiveDate === true,
                  forceSelection: true,
                }"
                label="Profissional Executante"
                @selected="selectingEmployee"
              />

              <div class="exam-situation">
                <span>Situação:</span>
                <div v-if="hasEffectiveDate" class="situation effective">
                  Efetivado
                </div>
                <div v-else class="situation opened">Em Aberto</div>
              </div>
            </div>

            <div class="grid grid-row-4">
              <RgTextArea
                id="performer-observation"
                v-model="form.exe_observacao"
                :maxlength="500"
                class="textarea"
                label="Observação"
              />
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="exam-details-effectivation-footer">
      <RgCleanButton id="clear-btn" small @click="confirmCleanForm" />
      <RgCancelButton id="cancel-btn" medium @click="goBack" />
      <RgSaveButton id="save-btn" medium @click="saveEffectivation" />
    </div>

    <ModalConfirmFieldClearing
      id="confirm-field-clearing"
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="getCleanFormMethod"
      @getOut="closeModalConfirmFieldClearing"
      @close="closeModalConfirmFieldClearing"
    />
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import { isEmpty } from "lodash";
import {
  RgValidatorForm,
  RgSelectSector,
  RgInputDate,
  RgInputHour,
  RgSuggestPatientInformation,
  RgSuggestEmployee,
  RgTextArea,
  RgSuggestCid,
  RgCleanButton,
  RgCancelButton,
  RgSaveButton,
  ModalConfirmFieldClearing,
} from "~tokio/primitive";
import RgSuggestProcedureEffectivation from "$exam/submodules/view/individual-effectivation-details/component/rg-suggest-procedure/RgSuggestProcedureEffectivation";
import { SaveIndividualEffectivationDetails } from "./action";

import PrepareExamToSave from "$exam/store/actions/PrepareExamToSave";
import FormBase from "~tokio/foundation/form-base/FormBase";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "IndividualEffectivationDetails",
  components: {
    Modulebox,
    RgSuggestProcedureEffectivation,
    RgSuggestPatientInformation,
    RgSuggestEmployee,
    RgSelectSector,
    RgInputDate,
    RgInputHour,
    RgValidatorForm,
    RgSuggestCid,
    RgTextArea,
    FormBase,
    RgCleanButton,
    RgCancelButton,
    RgSaveButton,
    ModalConfirmFieldClearing,
  },
  data() {
    return {
      form: {
        exe_id: null,
        exe_id_unidades_saude: null,
        exe_id_pacientes: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
        pes_id: null,
        exe_id_boletins: null,
        exe_id_internacoes: null,
        exe_codigo_procedimento: null,
        exe_id_sinonimos_procedimentos: null,
        exe_codigo_cid: null,
        exe_id_setores: null,
        exe_data_solicitacao: moment().format("DD/MM/YYYY"),
        exe_data_efetivacao: null,
        exe_observacao: null,
      },
      itsEditing: false,
      modalConfirmFieldClearing: false,
      suggestPatient: "",
      suggestEmployee: "",
      suggestRequestEmployee: "",
      suggestProcedure: "",
      sectorPermission: 247,
      sectorLink: null,
      mainCid: null,
      requestHour: null,
      effectedHour: null,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      loggedUserId: "Login/GET_USER_ID",
    }),
    hasEffectiveDate() {
      return this.form.exe_data_efetivacao?.length > 0;
    },
    canNotEffectRetroactive() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.naoEfetivarRetroativo",
        this.unitHealthId,
      );
    },
  },

  watch: {
    "form.exe_data_efetivacao"(pValue, pPrev) {
      if (!pValue && pPrev && pPrev.length > 0) {
        this.form.exe_id_setores = null;
        this.suggestEmployee = "";
        this.form.exe_id_vinculos = null;
        this.sectorLink = null;
        this.$refs.sector.cleanValidate();
        this.$refs.employee.cleanValidate();
      }
    },
    "form.exe_id_setores"(pValue, pPrev) {
      if (!pValue || (pPrev && pPrev.length > 0)) {
        this.suggestEmployee = "";
        this.form.exe_id_vinculos = null;
        this.sectorLink = null;
        this.$refs.employee.cleanValidate();
      }
    },
  },

  mounted() {
    this.$loader.start();
    this.form.exe_id_unidades_saude = this.$store.getters[
      "Login/GET_UNIT_HEALTH_ID"
    ];

    const pData = this.$store.getters[
      "Exam/IndividualEffectivation/GET_SELECTED_DATA"
    ];

    if (pData) {
      this.itsEditing = true;
      this.mountExam(pData);
    }
    this.$loader.finish();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/effectivation/edit",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/IndividualEffectivation/CLEAN_SELECTED_DATA");
    }
  },

  methods: {
    mountExam(pData) {
      if (pData.patient) {
        this.$refs.patient.fillPatientById(Number(pData.patient.person.pes_id));
      }

      if (pData.requestEmployeeRelationship) {
        const suggestRequestEmployee =
          pData.requestEmployeeRelationship.employee.person.pes_nome;
        this.$refs.requester.forceSelection({
          pes_nome: suggestRequestEmployee,
          vin_id: pData.exe_id_vinculos_solicitante,
        });
      }

      if (pData.procedure) {
        this.$refs.procedure.forceSelection({
          NO_PROCEDIMENTO: pData.procedimento,
          CO_PROCEDIMENTO: pData.cod_procedimento,
          ID_SINONIMOS: pData.exe_id_sinonimos_procedimentos,
        });
      }

      this.$nextTick(() => {
        if (pData.cid10 && this.$refs.mainCid) {
          this.$refs.mainCid.forceSelection({
            cid_nome: pData.cid10.cid_nome,
            cid_codigo: pData.cid10.cid_codigo,
          });
        }
      });

      const requestDateData = pData.exe_data_solicitacao
        ? pData.exe_data_solicitacao.split(" ")
        : null;

      const requestDate = requestDateData[0];
      const requestHour = requestDateData[1];

      this.requestHour = requestHour;

      this.form = {
        exe_id: pData.exe_id,
        pes_id: pData.patient.person.pes_id,
        exe_id_pacientes: pData.exe_id_pacientes,
        exe_id_vinculos: pData.exe_id_vinculos,
        exe_data_efetivacao: pData.exe_data_efetivacao,
        exe_id_vinculos_solicitante: pData.exe_id_vinculos_solicitante,
        exe_id_boletins: pData.exe_id_boletins,
        exe_id_internacoes: pData.exe_id_internacoes,
        exe_id_setores: pData.exe_id_setores,
        exe_data_exame: this.$utils.date.BrazilianDateFormat(
          pData.exe_data_exame,
        ),
        exe_data_solicitacao: this.$utils.date.BrazilianDateFormat(requestDate),
        exe_codigo_cid: !isEmpty(pData.exe_codigo_cid)
          ? pData.exe_codigo_cid
          : null,
        exe_id_unidades_saude:
          pData.exe_id_unidades_saude > 0
            ? pData.exe_id_unidades_saude
            : this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        exe_codigo_procedimento: !isEmpty(pData.cod_procedimento)
          ? pData.cod_procedimento
          : pData.procedure
          ? pData.procedure.CO_PROCEDIMENTO
          : null,
        exe_id_sinonimos_procedimentos: this.form
          .exe_id_sinonimos_procedimentos,
        exe_observacao: !isEmpty(pData.exe_observacao)
          ? pData.exe_observacao
          : null,
      };
    },

    async saveEffectivation() {
      const validation = this.$refs.validator
        ? await this.$refs.validator.validate()
        : false;

      if (!validation) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      const status = this.hasEffectiveDate ? 2 : 1;
      const exam = this.$utils.obj.DeepCopy(this.form);

      const formatRequestDate = this.form.exe_data_solicitacao
        ? `${this.$utils.date.BrazilianDateToDatabase(
            this.form.exe_data_solicitacao,
          )} ${this.requestHour}:00`
        : null;

      const formatEffectiveDate = this.form.exe_data_efetivacao
        ? `${this.$utils.date.BrazilianDateToDatabase(
            this.form.exe_data_efetivacao,
          )} ${this.effectedHour}:00`
        : null;

      exam.exe_data_solicitacao = formatRequestDate;
      exam.exe_data_efetivacao = formatEffectiveDate;

      const preparedExamInfo = PrepareExamToSave(
        exam,
        status,
        this.form.exe_id_vinculos,
        null,
        false,
        this.loggedUserId,
      );

      try {
        this.$loader.start();
        await SaveIndividualEffectivationDetails(preparedExamInfo);
        this.$toaster.success("Salvo com sucesso");
        this.cleanForm();
        this.$router.go(-1);
      } catch (e) {
        this.$toaster.error(e, "Não foi possível salvar");
      } finally {
        this.$loader.finish();
      }
    },

    selectingPatient(pPatient) {
      this.form.exe_id_pacientes =
        pPatient && pPatient.patient ? pPatient.patient.pac_id : null;
      this.form.pes_id = pPatient ? pPatient.pes_id : null;
    },

    selectingEmployee(pEmployee) {
      this.form.exe_id_vinculos = pEmployee.source.vin_id || null;
      this.sectorLink = pEmployee.source.vus_id || null;
    },

    selectingRequestEmployee(pRequestEmployee) {
      this.form.exe_id_vinculos_solicitante = pRequestEmployee.source.vin_id;
    },

    selectingProcedure(pRequestEmployee) {
      this.sectorLink = null;
      this.form.exe_id_vinculos = null;
      this.suggestEmployee = "";

      const hasData = pRequestEmployee.source;
      if (hasData) {
        this.form.exe_codigo_procedimento = hasData.CO_PROCEDIMENTO;
        this.form.exe_id_sinonimos_procedimentos = hasData.ID_SINONIMOS;
      } else {
        this.form.exe_codigo_procedimento = "";
        this.form.exe_id_sinonimos_procedimentos = "";
      }
    },

    selectingCidCode(pCidCode) {
      const { cid_codigo, cid_nome } = pCidCode.source;
      this.form.exe_codigo_cid = cid_codigo || null;
      this.mainCid = cid_nome || null;
    },

    validateRequestHour(pValue, pErrors) {
      const currentDateTime = moment(new Date(), "DD/MM/YYYY HH:mm");
      const dateHourRequest = this.form.exe_data_solicitacao + " " + pValue;
      const formatDateRequest = moment(dateHourRequest, "DD/MM/YYYY HH:mm");
      const validateFutureTime = currentDateTime.isAfter(formatDateRequest);

      if (!validateFutureTime) {
        pErrors.push("A hora da solicitação não pode ser futura");
        return false;
      }
    },

    validateEffectedHour(pValue, pErrors) {
      if (this.hasEffectiveDate) {
        const currentDateTime = moment(new Date(), "DD/MM/YYYY HH:mm");
        const dateHourEffective = this.form.exe_data_efetivacao + " " + pValue;
        const formatDateEffective = moment(
          dateHourEffective,
          "DD/MM/YYYY HH:mm",
        );
        const validateFutureTime = currentDateTime.isAfter(formatDateEffective);

        if (!validateFutureTime) {
          pErrors.push("A hora da efetivação não pode ser futura");
          return false;
        }
      }
      return true;
    },

    validateEffectedDate(pValue, pErrors) {
      if (!pValue) {
        return true;
      }

      const validDate = this.$utils.date.IsSameOrBefore(
        this.form.exe_data_solicitacao,
        "DD/MM/YYYY",
        pValue,
        "DD/MM/YYYY",
      );

      if (this.canNotEffectRetroactive && !validDate) {
        pErrors.push(
          "A data da efetivação não pode ser anterior à data da solicitação",
        );
        return false;
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    getCleanFormMethod() {
      this.cleanForm();
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "patient",
        "requestDate",
        "requestHour",
        "requester",
        "sector",
        "procedure",
        "mainCid",
        "effectedDate",
        "effectedHour",
        "sectorEffective",
        "employee",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cleanForm() {
      this.form = {
        exe_id_pacientes: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
        exe_id_boletins: null,
        exe_id_internacoes: null,
        exe_codigo_procedimento: null,
        exe_id_sinonimos_procedimentos: null,
        exe_codigo_cid: null,
        exe_id_setores: null,
        exe_observacao: null,
        exe_data_solicitacao: moment().format("DD/MM/YYYY"),
        exe_data_efetivacao: null,
        exe_id_unidades_saude: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      };
      this.suggestPatient = "";
      this.suggestEmployee = "";
      this.suggestRequestEmployee = "";
      this.suggestProcedure = "";
      this.recordNumber = "";
      this.bulletinNumber = "";
      this.hospitalizationNumber = "";
      this.sectorLink = null;
      this.mainCid = null;
      this.requestHour = null;
      this.effectedHour = null;
      this.$refs.patient.cleanPatient();
      this.cleanFieldsValidation();
    },
  },
};
</script>
