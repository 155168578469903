import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacyProviderRegister from "$pharmacy/submodules/register/view/provider-register/PharmacyProviderRegister";

export default {
  path: "/pharmacy/register/provider/edit",
  name: "pharmacy.register.provider.edit",
  components: {
    default: PharmacyProviderRegister,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Cadastro",
        link: OpenSubModule("pharmacy"),
      },
      {
        label: "Fornecedores",
        link: "/pharmacy/register/provider",
      },
      {
        label: "Editar Fornecedores",
      },
    ],
  },
};
