<style src="./RgInputSmartPersonName.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model="inputValue"
            type="text"
            :placeholder="placeholder"
            :tabindex="tabIndex"
            :disabled="disabled"
            autocomplete="off"
            @blur="validateBlur"
          />

          <div class="options">
            <label class="radio-type">
              <input
                v-model="nameType"
                type="radio"
                value="0"
                checked
                class="radio-input"
              />
              <span class="radion-span">Nome</span>
            </label>

            <label class="radio-type">
              <input
                v-model="nameType"
                type="radio"
                value="1"
                class="radio-input"
              />
              <span class="radion-span">Nome Social</span>
            </label>
          </div>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";
export default {
  name: "RgInputSmartPersonName",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      default: "Paciente",
    },
    placeholder: {
      default: "Digite o nome do paciente",
    },
    maxlength: {
      default: 0,
    },
  },
  data() {
    return {
      nameType: 0,
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    inputValue(pValue) {
      this.$emit("value", pValue);
    },
    nameType(pValue, pPrev) {
      if (pValue !== pPrev) {
        this.reset();
      }

      const type = pValue === "0" ? "name" : "socialName";
      this.$emit("typeName", type);
      this.$emit("value", this.inputValue);
    },
  },
  mounted() {
    this.$emit("typeName", "name");
  },
  methods: {
    reset() {
      this.inputValue = "";
    },
    validateBlur() {
      this.validate();
      this.$emit("blur");
    },
  },
};
</script>
