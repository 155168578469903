import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path:
    "/hospitalization/hospitalizations/edit-details/edit-patient/patient-care-history",
  name:
    "hospitalization.hospitalizations.edit-details.edit-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Internações", link: OpenSubModule("hospitalization") },
      {
        label: "Lista de Internações",
        link: "/hospitalization/hospitalizations/search",
      },
      {
        label: "Editar Internação",
        link: "/hospitalization/hospitalizations/details",
      },
      {
        label: "Editar Paciente",
        link: "/hospitalization/hospitalizations/edit-details/edit-patient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
