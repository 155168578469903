<template>
  <Modulebox
    ref="modulebox"
    :title="isEdition ? 'Editar Tipo de Produto' : 'Cadastrar Tipo de Produto'"
    class="pharmacy-product-type"
  >
    <FormBase
      :title="
        isEdition ? 'Descrição do Tipo de Produto' : 'Cadastro e Busca de Tipos'
      "
      class="register-form"
    >
      <div class="pharmacy-product-type-body">
        <RgValidatorForm ref="validator" @submit.stop.prevent="newProductType">
          <section class="pharmacy-product-type-form">
            <RgSuggestCity
              ref="city"
              v-model="form.city"
              :rules="{ required: true }"
              :class="{ disable: true }"
              disabled
              label="Município"
              class="city"
              @selected="selectingMunicipality"
            />
            <RgInput
              ref="name"
              v-model="form.name"
              label="Nome"
              placeholder="Digite o tipo do produto. Ex.: 'Analgésico'"
              class="name"
              :disabled="disabledByModal"
              :rules="{ required: true, fn: validateEspecialCharacter }"
              :needRemoveSpecialStrings="false"
              :maxlength="45"
            />
            <div class="actions">
              <RgCancelButton
                v-if="isEdition"
                medium
                :disabled="disabledByModal"
                @click="cancelProductTypeEditing"
              />
              <RgCleanButton
                v-else
                :disabled="disabledByModal"
                large
                @click="cleanInfoProductType"
              />
              <RgSaveButton
                v-if="isEdition"
                medium
                :disabled="disabledByModal"
                @click="saveProductTypeEditing"
              />
              <RgAddButton
                v-else
                :permission="canRegister"
                large
                :disabled="disabledByModal"
                @click="newProductType"
              />
            </div>
          </section>
        </RgValidatorForm>
        <hr class="hr" />
        <section class="pharmacy-product-type-search">
          <div class="search">
            <RgInput
              v-model="search.productType"
              :disabled="isEdition || disabledByModal"
              :class="{ disable: isEdition || disabledByModal }"
              label=""
              placeholder="Buscar por nome"
            />
            <RgSearchButton
              ref="searchBtn"
              :disabled="isEdition || disabledByModal"
              :class="{ disable: isEdition || disabledByModal }"
              @submit="searchProductType(true)"
            />
          </div>
          <div class="actions">
            <RgEditButton
              :permission="canEdit"
              :disabled="disableActionsTable || disabledByModal"
              title="Editar Tipo de Produto"
              @click="editProductType"
            />
            <RgLessButton
              :permission="canRemove"
              :disabled="disableActionsTable || disabledByModal"
              title="Excluir Tipo de Produto"
              @click="openModalRemoveProductType"
            />
          </div>
        </section>
        <section
          class="pharmacy-product-type-table"
          :disabled="isEdition || disabledByModal"
          :class="{ disable: isEdition || disabledByModal }"
        >
          <SmartTable
            ref="smartTable"
            name="PharmacyProductType"
            :columns="COLUMNS"
            :body="mutableProductTypeList"
            :initial-columns="2"
            :total="total"
            :disabled="isEdition || disabledByModal"
            :max-register="25"
            :item-per-page="20"
            :dynamicHeight="getSpaceTable"
            index-column="mtp_id"
            toggleSelected
            removeBtnColumns
            @getLine="selectLine"
            @pagination="setPagination"
          />
        </section>
      </div>
    </FormBase>
    <div slot="footer" class="pharmacy-product-type-footer">
      <MediumButton
        medium
        label="Voltar"
        :disabled="disabledByModal"
        @click="goBack"
      />
    </div>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalRemoveProductType"
      @reSearch="searchProductType"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgValidatorForm,
  RgInput,
  RgCleanButton,
  RgSearchButton,
  RgAddButton,
  RgEditButton,
  RgLessButton,
  RgCancelButton,
  RgSaveButton,
  IconDanger,
  MediumButton,
} from "~tokio/primitive";
import { Modulebox } from "~tokio/foundation";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";

import ModalConfirmDeletion from "~tokio/primitive/modal/modal-confirm-deletion/ModalConfirmDeletion";

const FORM = {
  city: null,
  cityId: null,
  acronym: null,
  name: null,
};

export default {
  name: "PharmacyProductType",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgInput,
    RgSuggestCity,
    RgCleanButton,
    RgSearchButton,
    RgAddButton,
    RgEditButton,
    RgLessButton,
    RgCancelButton,
    RgSaveButton,
    SmartTable,
    ModalConfirmDeletion,
    IconDanger,
    MediumButton,
  },

  data() {
    return {
      hasSelectedProductType: false,
      mutableProductTypeList: [],
      productTypeData: {},
      form: this.$utils.obj.DeepCopy(FORM),
      total: 0,
      search: {
        productType: null,
      },
      isEdition: false,
      pagination: {
        limit: 20,
        offset: 0,
        current: 1,
      },
      tableHeight: 0,
      modalRemoveProductType: false,
      firstId: null,
    };
  },

  computed: {
    ...mapGetters({
      clientId: "Login/GET_USER_ID_CLIENT",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
    }),
    disableActionsTable() {
      return this.isEdition || !this.hasSelectedProductType;
    },
    getSpaceTable() {
      return this.tableHeight;
    },
    permission() {
      return {
        register: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoProduto.incluir",
          this.unitHealthId,
        ),
        edit: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoProduto.alterar",
          this.unitHealthId,
        ),
        remove: !!this.$Permissions.global.has(
          "farmacia.cadastroTipoProduto.excluir",
          this.unitHealthId,
        ),
      };
    },
    canRegister() {
      return this.permission.register;
    },
    canEdit() {
      return this.permission.edit;
    },
    canRemove() {
      return this.permission.remove;
    },
    propsModalConfirmDeletion() {
      const show = this.modalRemoveProductType;
      const confirm = () => this.removeProductType();
      const noReason = true;
      const title = "Excluir o Tipo de Produto Selecionado";
      const message = "A operação não poderá ser desfeita";
      const btnRemoveTitle = "Excluir";
      const bodyText = this.productTypeData.mtp_nome;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
      };
    },
    disabledByModal() {
      return this.modalRemoveProductType;
    },
  },

  watch: {},

  async mounted() {
    this.mountedInfo();
    await this.searchProductType();
  },

  created() {
    this.COLUMNS = [
      {
        name: "Tipo",
        key: "mtp_nome",
        align: "left",
      },
    ];
  },

  methods: {
    mountedInfo() {
      this.$loader.start();

      const hasCity = this.currentUnitHealth?.endereco?.neighborhood?.city;

      if (hasCity && this.$refs.city) {
        const city = this.currentUnitHealth.endereco.neighborhood.city;

        this.$refs.city.forceSelection({
          mun_id: city ? city.mun_id : null,
          mun_nome: city ? city.mun_nome : null,
        });
      }

      this.$loader.finish();
    },
    async searchProductType(pButtonSearch = false, toFirst = false) {
      try {
        this.$loader.start();

        if (pButtonSearch) {
          this.$refs.smartTable.$refs.paginator.resetLimitOffset();
        }

        const variables = {
          arrFormData: {
            mtp_nome: this.search.productType,
            limiteInicio: this.pagination.offset,
            limiteFim: this.pagination.limit,
          },
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Register/SEARCH_PRODUCT_TYPE",
          variables,
        );

        this.mutableProductTypeList = result.recordSet;
        this.total = Number(result.total);
        if (toFirst) {
          // LÓGICA CRIADA PARA ADICIONAR REGISTRO RECEM
          // CRIADO/EDITADO EM PRIMEIRO LUGAR DA GRID
          variables.arrFormData.limiteFim = result.total;
          variables.arrFormData.limiteInicio = 0;
          const resultAll = await this.$store.dispatch(
            "Pharmacy/Register/SEARCH_PRODUCT_TYPE",
            variables,
          );
          let aux = null;
          for (let i = 0; i < resultAll.recordSet.length; i++) {
            if (
              resultAll.recordSet[i] &&
              (resultAll.recordSet[i].mtp_id === this.firstId ||
                Number(resultAll.recordSet[i].mtp_id) === this.firstId)
            ) {
              aux = resultAll.recordSet[i];
            }
          }
          this.mutableProductTypeList.forEach((element, index) => {
            if (
              element.mtp_id === this.firstId ||
              Number(element.mtp_id) === this.firstId
            ) {
              this.mutableProductTypeList.splice(index, 1);
            }
          });
          this.mutableProductTypeList.unshift(aux);
          this.firstId = null;
        }
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar tipos de produto");
      } finally {
        this.cleanSelectRow();
        if (!this.isEdition) this.getDynamicHeight();
        this.$refs.searchBtn.actionDone();
        this.$loader.finish();
      }
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async saveProductTypeEditing() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            mtp_id: this.productTypeData.mtp_id,
            mtp_id_municipios: this.form.cityId,
            mtp_id_clientes: this.clientId,
            mtp_nome: this.form.name.toUpperCase(),
          },
        };

        await this.$store.dispatch(
          "Pharmacy/Register/EDIT_PRODUCT_TYPE",
          variables,
        );

        this.$toaster.success("Tipo de Produto alterado com sucesso");
        this.firstId = variables.arrFormData.mtp_id;
        await this.searchProductType(false, true);
      } catch (pErr) {
        const error = pErr.toString();
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isDuplicityError =
            esus_response.exception ===
            "DuplicidadeMunicipioTipoProdutoException";

          if (isDuplicityError) {
            this.$toaster.error(
              `Já existe registro com esse nome:  ${this.form.name}.`,
              " Não foi possível alterar o Tipo de Produto",
            );
          }
          const isBondError =
            esus_response.exception ===
            "MunicipioTipoProdutoPossuiProdutoVinculadoException";

          if (isBondError) {
            this.$toaster.error(
              `Tipos de Produto vinculados a outros registros não podem ser alterados.`,
              "Não foi possível alterar o Tipo de Produto",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao alterar tipo de produto",
          );
        }
      } finally {
        this.cancelProductTypeEditing();
        this.$loader.finish();
      }
    },
    async newProductType() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos.");
        return;
      }

      try {
        this.$loader.start();

        const variables = {
          arrFormData: {
            mtp_id_clientes: this.clientId,
            mtp_id_municipios: this.form.cityId,
            mtp_nome: this.form.name.toUpperCase(),
          },
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Register/REGISTER_PRODUCT_TYPE",
          variables,
        );

        this.$toaster.success("Tipo de Produto cadastrado com sucesso");
        this.firstId = result.dados;
        await this.searchProductType(false, true);
      } catch (pErr) {
        const error = pErr.toString();
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isDuplicityProductError =
            esus_response.exception ===
            "DuplicidadeMunicipioTipoProdutoException";

          if (isDuplicityProductError) {
            this.$toaster.error(
              `Já existe registro com esse nome:  ${this.form.name}.`,
              " Não foi possível cadastrar o Tipo de Produto",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao cadastrar tipo de produto",
          );
        }
      } finally {
        this.cleanInfoProductType();
        this.$loader.finish();
      }
    },
    editProductType() {
      this.isEdition = true;
      this.form.name = this.productTypeData.mtp_nome;
    },
    async removeProductType() {
      try {
        this.$loader.start();

        const variables = {
          intIdMunicipioTipoProduto: this.productTypeData.mtp_id,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/DELETE_PRODUCT_TYPE",
          variables,
        );

        this.$toaster.success("Tipo de Produto excluído com sucesso");

        await this.searchProductType();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o Tipo de Produto",
        );
      } finally {
        this.cleanInfoProductType();
        this.$loader.finish();
      }
    },
    validateEspecialCharacter(pValue, pErrors) {
      const hasSpecial = /[!$%^&*()@_^+#¨|´~=`{}[\]:";'~<>?,\\.]/.test(pValue);

      if (hasSpecial) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: barra e traço",
        );
        return false;
      }

      return true;
    },
    cancelProductTypeEditing() {
      this.cleanInfoProductType();
    },
    cleanSelectRow() {
      this.productTypeData = {};
      this.hasSelectedProductType = false;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    cleanInfoProductType() {
      this.form.name = null;
      this.productTypeData = {};
      this.hasSelectedProductType = false;
      this.isEdition = false;

      if (this.$refs.name) this.$refs.name.cleanValidate();
      this.cleanSelectRow();
    },
    async setPagination(pPagination) {
      this.pagination = pPagination;
      await this.searchProductType();
    },
    selectLine(pValue) {
      if (pValue) {
        this.hasSelectedProductType = true;
        this.productTypeData = pValue;
      } else {
        this.hasSelectedProductType = false;
        this.productTypeData = {};
      }
    },
    selectingMunicipality(pValue) {
      const source = pValue.source;
      if (source) {
        this.form.cityId = source && source.mun_id;
      } else {
        this.form.cityId = null;
      }
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.modulebox) {
          const modulebox = this.$refs.modulebox.$refs.myself.offsetHeight;

          this.tableHeight = modulebox - 290;
        }
      });
    },
    openModalRemoveProductType() {
      this.modalRemoveProductType = true;
    },
    closeModalRemoveProductType() {
      this.modalRemoveProductType = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
