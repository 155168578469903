import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post("farmacia/controller-pedido/buscar", variables)
    .then(({ data }) => {
      data = HandleResponse(data);
      data.dados.data = data.dados.recordSet;
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
