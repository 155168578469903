<template lang="html">
  <svg
    width="5.6478mm"
    height="5.1619mm"
    version="1.1"
    viewBox="0 0 5.6478 5.1619"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-61.041 -143.71)">
      <g transform="matrix(.011031 0 0 .011031 61.041 143.47)" fill="#fff">
        <g fill="#fff">
          <g fill="#fff">
            <path
              d="m483.84 50.561c-18.766-18.975-41.381-28.844-65.483-28.528-22.549 0.292-44.674 9.874-62.298 26.981l-0.166 0.161-228.78 232.6c-22.891 23.131-22.897 56.713-0.014 79.85 11.096 11.22 25.251 17.398 39.86 17.398h7e-3c14.786-2e-3 29.034-6.18 40.189-17.465l158.12-161.54-28.585-27.979-158.05 161.47c-3.516 3.557-7.663 5.516-11.68 5.516-3.952 1e-3 -7.796-1.858-11.423-5.525-10.528-10.646-3.503-20.043 0.049-23.631l228.48-232.3c21.836-21.064 49.811-20.632 71.327 1.123 25.53 25.813 18.405 56.117 0.017 74.698l-260.07 268.6c-17.871 18.046-40.482 27.985-63.677 27.988h-0.012c-23.187 0-45.185-9.849-63.613-28.481-43.137-43.615-31.089-94.84-6e-3 -126.27l197.1-197.05-28.28-28.286-197.14 197.1-0.075 0.076c-24.721 24.981-38.785 56.483-39.601 88.7-0.871 34.355 12.811 66.814 39.568 93.869 25.738 26.023 58.427 40.354 92.054 40.353 4e-3 0 0.013 0 0.017-1e-3 33.995-4e-3 66.734-14.188 92.186-39.937l260.07-268.6c37.449-37.926 37.416-92.948-0.094-130.88z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Attachment",
};
</script>
