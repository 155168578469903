<style src="./RgSuggestCboProcedure.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    :id="id"
    ref="innerSuggest"
    v-model="inputValue"
    :data-id="dataId"
    :label="label"
    :title="title"
    :placeholder="placeholder"
    :search-on-focus="searchOnFocus"
    :disabled="disabled"
    :rules="rules"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :data-item="dataItem"
      :idx="idx"
      :item="item"
      class="cbo-item"
    >
      <div class="cbo-row-info">
        <div class="ocp-code">
          <span>{{ item.ocp_codigo || item.CO_OCUPACAO }}</span>
        </div>
        <div class="ocp-name">
          <span>{{ item.ocp_nome || item.NO_OCUPACAO }}</span>
        </div>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>
<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchCboProcedure from "./action/SearchCboProcedure";
import StartWithLetter from "./helper/StartWithLetter";
import Store from "@/store";

export default {
  name: "RgSuggestCboProcedure",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    label: {
      type: String,
      default: "Ocupação",
    },
    title: {
      type: String,
      default: "Ocupação",
    },
    placeholder: {
      type: String,
      default: "Digite a ocupação",
    },
    dataItem: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sigtap: {
      type: Boolean,
      default: false,
    },
    competence: {
      type: String,
      default: null,
    },
    procedureCode: {
      type: String,
      default: null,
    },
    lastCompetence: {
      type: Boolean,
      default: false,
    },
    isBilling: Boolean,
  },
  data() {
    return {
      captionRaw: "ocp_codigo" + " - " + "ocp_nome",
    };
  },
  computed: {
    noItemSelected() {
      return !isEmpty(this.inputValue) && !this.$refs.innerSuggest.itemSelected;
    },
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    validate() {
      this.$refs.innerSuggest.validate();
    },

    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },

    doSearch(pSearchTerm, pLimit = null) {
      if (this.isBilling && !pSearchTerm && !this.procedureCode) {
        return;
      }

      let valueCode = null;
      let valueName = null;

      if (pSearchTerm) {
        if (pSearchTerm.includes("-")) {
          const term = pSearchTerm.split(" - ");
          valueCode = term[0];
        } else {
          StartWithLetter(pSearchTerm)
            ? (valueName = pSearchTerm)
            : (valueCode = pSearchTerm);
        }
      }

      let procedureCodeSearch = this.procedureCode;
      if (this.procedureCode !== null && this.procedureCode.includes("-")) {
        const term = this.procedureCode.split(" - ");
        procedureCodeSearch = term[0];
      }

      return SearchCboProcedure({
        variables: {
          ocp_nome: valueName,
          ocp_codigo: valueCode,
          ocp_sigtap: this.sigtap,
          ocp_competence: this.lastCompetence
            ? null
            : this.competence || Store.getters["Billing/BDSia/GET_PERIOD_DATE"],
          co_procedimento: procedureCodeSearch,
          limit: pLimit,
        },
      });
    },

    forceSelection(pValue) {
      if (
        pValue &&
        !isEmpty(pValue.ocp_nome) &&
        (pValue.ocp_codigo > 0 ||
          pValue.ocp_codigo !== null ||
          !isEmpty(pValue.ocp_codigo))
      ) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            ocp_nome: pValue.ocp_nome,
            ocp_codigo: pValue.ocp_codigo,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
