import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleProofInsertionQueue = (pModule, pData, size = 1) => {
  const data = pData.dados;

  const isConsultation = pModule === 2;
  const isExam = pModule === 33;
  const isHospitalization = pModule === 14;

  const sizePaper = size === 1 || size === 3 ? "normal" : "termico";

  let consultation = "";
  let exam = "";
  let hospitalization = "";

  if (isConsultation) {
    consultation = `
    <div class="{div_lbl_consulta}">
      <div><label>Ocupação:</label>	<span>${data.flc_nome_ocupacao}</span></div>
    </div>`;
  }

  if (isExam) {
    exam = `
    <div class="{div_lbl_exame}">
      <div><label>Procedimento:</label>			<span>${data.fle_nome_procedimento}</span></div>
      <div><label>Sub Procedimento:</label>		<span>${data.fes_nome_sub_procedimentos}</span></div>
      <div><label>Quantidade:</label>				<span>${data.fle_quantidade}</span></div>
      <div><label>CID:</label>					<span>${data.cid_nome}</span></div>
    </div>`;
  }

  if (isHospitalization) {
    hospitalization = `
    <div class="{div_lbl_internacao}">
      <div><label>Tipo de Leito:</label>			<span>${data.itl_nome}</span></div>
      <div><label>Local de Espera:</label>		<span>${data.ile_nome}</span></div>
      <div><label>Unidade Solicitante:</label>	<span>${data.ius_nome}</span></div>
      <div><label>CID:</label>					<span>${data.cid_nome}</span></div>
      <div><label>Procedimento:</label>			<span>${data.procedimento_nome}</span></div>
    </div>
    `;
  }

  const logo = LocalStorage.getObject("client-logo");

  let html = `
  <!DOCTYPE html>
  <html>

  <head>
      <meta charset="utf-8">
      <title>Esus</title>
      <style>
			body {
				font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
				/*background: rgb(204,204,204);*/
			}

			h1 {
				font-size: 12pt;
				text-transform: uppercase;
				text-align: center;
			}

			h2 {
				font-size: 11pt;
				font-style: italic;
				text-transform: uppercase;
			}

			h3 {
				font-size: 10pt;
				text-align: center;
				text-transform: uppercase;
			}

			hr {
				margin: 30px 0;
			}

			label,
			span,
			p {
				font-size: 10pt;
			}

			label {
				font-weight: 700;
			}

			page {
				background: white;
				display: block;
				margin: 0 auto;
				margin-bottom: 0.5cm;
				/*box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);*/
			}

			page[size="A4"] {
				width: 21cm;
				height: 29.7cm;
			}

			page[size="termico"] {
				width: 7cm;
			}

			.logo-cliente {
				width: 70px;
				display: block;
				margin: 0 auto;
				padding-top: 0.5cm;
			}

			.endereco {
				font-style: italic;
			}

			.info-fila div {
				margin-bottom: 6px;
			}

			.label-dados p {
				font-style: italic;
				color: #424141;
			}

			.ocultar {
				display: none;
      }

			.mostrar {
				display: block;
			}

			/* acertar a impressão térmica quando o usuário escolher a mesma */
			{css_impressao_termico}
		</style>
  </head>

  <body>
    <page size="${sizePaper}">

      <div>
        <img class="logo-cliente" src="${logo}">
        <h1>${data.cli_nome}</h1>

        <h2 class="limit">${data.uns_nome}</h2>
        <p class="limit">${data.tlg_nome} ${data.end_logradouro} ${data.end_numero} ${data.end_complemento} ${data.bai_nome} - ${data.mun_nome}</p>
      </div>

      <div class="info-fila">
        <hr><h3>Comprovante de paciente inserido na fila</h3><hr>

        <!-- dados do paciente -->
        <div class="label-dados"><p>Dados do paciente</p></div>
        <div ><label>Paciente:</label> 					<span class="limit">${data.paciente}</span></div>
        <div ><label>Nome Social:</label> 				<span class="limit">${data.paciente_nome_social}</span></div>
        <div ><label>Prontuário:</label> 				<span>${data.ppr_numero}</span></div>
        <div ><label>Prontuário Único:</label> 	<span>${data.pac_prontuario_unico}</span></div>
        <div ><label>Cartão SUS:</label> 				<span>${data.crs_numero}</span></div>
        <div ><label>Telefone:</label> 				<span>${data.telefone}</span></div>
        <!-- fim dados do paciente -->


        <!-- dados da fila -->
        <div class="label-dados"><p>Dados da fila</p></div>
        <div><label>Inserido na Fila:</label>			<span>${data.fil_data} às ${data.fil_hora}</span></div>
        <div><label>Prioridade:</label>					<span class="limit">${data.pfi_nome}</span></div>
        <div><label>Pedido:</label>						<span>${data.fil_data_pedido}</span></div>

        <!-- dados para impressão em consulta -->
        ${consultation}
        <!-- fim dados para impressão em consulta -->


        <!-- dados para impressão em exames -->
        ${exam}
        <!-- fim dados para impressão em exames -->


        <!-- dados para impressão em internação -->
        ${hospitalization}
        <!-- fim dados para impressão em internação -->

        <div><label>Observações:</label></div>	 <span class="limit">${data.fio_observacao}</span></div>
        <!-- fim dados da fila -->


        <!-- dados da profissional -->
        <div class="label-dados"><p>Dados do profissional</p></div>
        <div><label>Solicitante:</label>		<span class="limit">${data.solicitante}</span></div>
        <!-- fim dados da profissional -->
      </div>
    </page>
  </body>
  <hr />

  </html>
    `;

  if (size > 2) html += '<div style="page-break-after: always"></div>';

  return html;
};

export default ScheduleProofInsertionQueue;
