<template lang="html">
  <div v-if="show" class="modal-see-movement-transfer-request">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <!-- TODO: Verificar depois as telas que utilizam essa modal -->
      <div slot="header" class="modal-see-movement-transfer-request-header">
        <span class="title">
          {{ titleMovement }}
        </span>
      </div>
      <div
        v-if="
          typeMovement === PHARMACY_CENTRAL_REQUEST_TRANSFER ||
          typeMovement === PHARMACY_CENTRAL_ATTENDED_TRANSFER
        "
        class="modal-see-movement-transfer-request-type"
      >
        <div slot="body" class="modal-see-solicitation-transfer-body">
          <FormBase title="Dados de Origem e Destino" class="form-base">
            <section
              v-if="typeMovement === PHARMACY_CENTRAL_REQUEST_TRANSFER"
              class="grid"
            >
              <span class="text-info">
                Unidade de Saúde Origem:
                <strong :title="unitHealthName">
                  {{ this.selectedRow.unidade_origem || "-" }}
                </strong>
              </span>
              <span class="text-info">
                Farmácia Origem:
                <strong :title="pharmacyName">
                  {{ this.selectedRow.farmacia_origem || "-" }}
                </strong>
              </span>
              <br />
              <span class="text-info">
                Unidade de Saúde Destino:
                <strong :title="unitHealthName">
                  {{ this.selectedRow.unidade_destino || "-" }}
                </strong>
              </span>
              <span class="text-info">
                Farmácia Destino:
                <strong :title="pharmacyName">
                  {{ selectedRow.farmacia_destino || "-" }}</strong
                >
              </span>
            </section>
            <section
              v-if="typeMovement === PHARMACY_CENTRAL_ATTENDED_TRANSFER"
              class="grid"
            >
              <span class="text-info">
                Unidade de Saúde Origem:
                <strong :title="unitHealthName">
                  {{ this.selectedRow.unidade_origem || "-" }}
                </strong>
              </span>
              <span class="text-info">
                Farmácia Origem:
                <strong :title="pharmacyName">
                  {{ this.selectedRow.farmacia_origem || "-" }}
                </strong>
              </span>
              <br />
              <span class="text-info">
                Unidade de Saúde Destino:
                <strong :title="unitHealthName">
                  {{ this.selectedRow.unidade_destino || "-" }}
                </strong>
              </span>
              <span class="text-info">
                Farmácia Destino:
                <strong :title="pharmacyName">
                  {{ selectedRow.farmacia_destino || "-" }}</strong
                >
              </span>
            </section>
            <hr class="hr" />
            <section class="grid">
              <span class="text-info">
                Data:
                <strong :title="product.dsc_data">
                  {{ selectedRow.str_data_pedido || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Número:
                <strong :title="product.dsc_data">
                  {{ selectedRow.str_numero || "-" }}</strong
                >
              </span>
            </section>
          </FormBase>
          <FormBase
            title="Lista de Atendimentos"
            class="modal-see-solicitation-transfer-body"
          >
            <div
              v-for="(item, idx) in productList"
              ref="itensRequest"
              :key="idx"
              class="grid-product"
            >
              <span class="text-info">
                Código:
                <strong :title="item.code"> {{ item.code || "-" }}</strong>
              </span>
              <span class="text-info">
                Nome:
                <strong :title="item.product">
                  {{ item.product || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Apresentação:
                <strong :title="item.typePresentation">
                  {{ item.typePresentation || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Tipo:
                <strong :title="item.rec_data"> {{ item.type || "-" }}</strong>
              </span>
              <span class="text-info">
                Quant.Solicitada:
                <strong :title="item.request || '-'">
                  {{ item.request || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Quant.Entregue:
                <strong :title="item.delivered || '-'">
                  {{ item.delivered || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Situação:
                <strong :title="item.situation || '-'">
                  {{ item.situation || "-" }}</strong
                >
              </span>
            </div>
          </FormBase>
        </div>
      </div>
      <div
        v-if="
          this.situation.length > 0 &&
          typeMovement === PHARMACY_CENTRAL_REQUEST_TRANSFER
        "
        slot="footer"
        class="modal-footer-movement-header"
      >
        <RgTagCustom id="situation" label="Situação:" v-bind="propsTagCustom" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgTagCustom } from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";

export default {
  name: "ModalSeeMovementTransferRequest",
  components: {
    RgBaseModal,
    FormBase,
    RgTagCustom,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
    idUnithHealth: {
      type: Number,
      default: 0,
    },
    idPharmacy: {
      type: String,
      default: "0",
    },
    unitHealthName: {
      type: String,
      default: "",
    },
    pharmacyName: {
      type: String,
      default: "",
    },
    filters: {
      type: Number,
      default: 0,
    },
    typeMovement: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      productList: null,
      product: {},
      patient: {},
      historyPatient: {},
      transfer: {},
      situation: [],
    };
  },
  computed: {
    titleMovement() {
      let title = "";
      switch (this.typeMovement) {
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          title = "Solicitação de Transferência de Produtos e Medicamentos";
          break;
        case this.PHARMACY_CENTRAL_ATTENDED_TRANSFER:
          title = "Solicitação de Transferência de Produtos e Medicamentos";
          break;
      }
      return title;
    },
    propsTagCustom() {
      const list = [
        { title: "Aguardando", id: 1 },
        { title: "Atendido", id: 2 },
        { title: "Parcialmente Atendido", id: 3 },
        { title: "Indisponível", id: 4 },
      ];

      const values = this.situation;

      return { list, values };
    },
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
      if ((this.typeMovement === 5 || this.typeMovement === 4) && this.show) {
        this.mountedFormRequestTransfer();
      }
    },
  },
  created() {
    this.PHARMACY_CENTRAL_DISPOSAL = 1;
    this.PHARMACY_CENTRAL_REQUEST = 2;
    this.PHARMACY_CENTRAL_TRANSFER = 3;
    this.PHARMACY_CENTRAL_ATTENDED_TRANSFER = 4;
    this.PHARMACY_CENTRAL_REQUEST_TRANSFER = 5;
    this.PHARMACY_SATELLITE_REQUEST = 6;
    this.PHARMACY_SATELLITE_REVENUE = 7;
  },
  mounted() {},

  methods: {
    async searchItemsByRequest(pIdRequest) {
      try {
        this.$loader.start();

        let variables = {};
        if (this.typeMovement === this.PHARMACY_CENTRAL_ATTENDED_TRANSFER) {
          variables = {
            intIdSolicitacao: pIdRequest,
            blnSomenteDisponiveis: false,
            blnDispensacoes: true,
          };
        } else {
          variables = {
            intIdSolicitacao: pIdRequest,
            // blnSomenteDisponiveis: available,
            // blnDispensacoes: false,
          };
        }
        const result = await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_ITENS_BY_REQUEST",
          variables,
        );

        const hasRequestNumber = Object.keys(result).length > 0;

        if (hasRequestNumber) {
          this.insertProductRequest(result.dados);
          this.insertProductStatus(result.dados);
        }
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao buscar quantidade de pedidos",
        );
      } finally {
        this.$loader.finish();
      }
    },
    insertProductStatus(pData) {
      if (pData) {
        pData.forEach((item) => {
          switch (item.std_nome) {
            case "AGUARDANDO":
              this.situation.push(1);
              break;
            case "ATENDIDO":
              this.situation.push(2);
              break;
            case "PARCIALMENTE ATENDIDO":
              this.situation.push(3);
              break;
            case "INDISPONIVEL":
              this.situation.push(4);
              break;
          }
        });
      }
    },
    insertProductRequest(pData) {
      this.productList = [];
      if (pData) {
        pData.map((item) => {
          const data = {
            str_id: item.str_id,
            code: item.mpd_codigo,
            product: item.mpd_novo_principio_ativo,
            typePresentation: item.mtu_novo_nome,
            type: item.mtp_nome,
            request: item.ist_quantidade_solicitada,
            delivered: item.trs_quantidade,
            situation: item.std_nome,
            ist_id: item.ist_id,
          };
          this.productList.push(data);
        });
      }
    },
    async mountedFormRequestTransfer() {
      try {
        const request = await this.$store.dispatch(
          "Pharmacy/Central/GET_TRANSFER_REQUEST",
          { intSolicitacaoTransferencia: this.selectedRow.str_id },
        );
        this.selectedRow.unidade_origem = request.uns_origem_nome;
        this.selectedRow.unidade_destino = request.uns_destino_nome;
        this.searchItemsByRequest(this.selectedRow.str_id);
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao carregar solicitação");
      } finally {
        this.$loader.finish();
      }
    },
    close() {
      this.$emit("close");
      this.productList = [];
      switch (this.typeMovement) {
        case this.PHARMACY_CENTRAL_DISPOSAL:
          this.product = {};
          break;
        case this.PHARMACY_CENTRAL_TRANSFER:
          this.transfer = {};
          break;
        case this.PHARMACY_SATELLITE_REVENUE:
          this.historyPatient = {};
          this.patient = {};
          break;
        case this.PHARMACY_CENTRAL_REQUEST_TRANSFER:
          this.situation = [];
          break;
      }
    },
  },
};
</script>
