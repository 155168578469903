<template lang="html">
  <div v-if="show" class="modal-hospitalization-exit">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">Cadastrar Saída</span>
      </div>

      <div ref="body" slot="body" class="body">
        <form @submit.stop.prevent="changeFlow">
          <div class="primary-information">
            <RgTypePatient
              id="patient"
              :pac-id="isPatient"
              :sex="isPatient ? patientSex : indigentSex"
              class="patient-type"
            />

            <div class="area">
              <span class="data">
                Unidade de Saúde:
                <strong>
                  {{
                    hospitalizationInfo
                      ? hospitalizationInfo.uns_nome
                      : "Não informado"
                  }}
                </strong>
              </span>

              <span class="data">
                Setor:
                <strong>
                  {{
                    hospitalizationInfo
                      ? hospitalizationInfo.set_nome
                      : "Não informado"
                  }}
                </strong>
              </span>
            </div>

            <div class="area">
              <span class="data">
                Local de Atendimento:
                <strong>
                  {{
                    hospitalizationInfo
                      ? hospitalizationInfo.lca_nome
                      : "Não informado"
                  }}
                </strong>
              </span>

              <span class="data">
                Leito:
                <strong>
                  {{
                    hospitalizationInfo
                      ? hospitalizationInfo.inl_nome
                      : "Não informado"
                  }}
                </strong>
              </span>
            </div>

            <div class="area">
              <span class="data">
                Número de Internação:
                <strong>
                  {{
                    hospitalizationInfo.int_numero
                      ? hospitalizationInfo.int_numero
                      : "Não informado"
                  }}
                </strong>
              </span>

              <span class="data ellipsis">
                Paciente:
                <strong>
                  {{
                    namePatient(
                      hospitalizationInfo.pes_nome ||
                        hospitalizationInfo.paciente,
                    )
                  }}
                </strong>
              </span>
            </div>
          </div>

          <div slot="body" class="body">
            <RgValidatorForm ref="validator" class="validator-form">
              <FormBase class="form-base" title="Saída">
                <div class="destination-content">
                  <div class="line">
                    <RgInputDate
                      id="date"
                      ref="exitDate"
                      v-model="form.isa_data"
                      :rules="{ required: true, fn: validateExitDate }"
                      :disabled="disableDate"
                      :class="{
                        disable: disableDate,
                      }"
                      :max-date="new Date()"
                      :min-date="getMinDate"
                      label="Data"
                      class="input data"
                    />

                    <RgInputHour
                      id="hour"
                      ref="exitHour"
                      v-model="form.isa_hora"
                      :rules="{ required: true, fn: validateExitHour }"
                      :disabled="
                        sentToPep &&
                        hospitalizationExtra.int_data_alta_pep !== null
                      "
                      :class="{
                        disable:
                          sentToPep &&
                          hospitalizationExtra.int_data_alta_pep !== null,
                      }"
                      label="Hora"
                      placeholder="hh:mm"
                      class="input hour"
                    />

                    <RgSuggestHospitalizationExitGroups
                      id="exit-groups"
                      ref="exitGroups"
                      v-model="exitGroups"
                      :rules="{ required: true }"
                      label="Motivo da Saída"
                      @selected="selectingExitGroups"
                    />

                    <RgSuggestHospitalizationDestinationPlace
                      id="destination"
                      ref="destination"
                      v-model="destinationPlace"
                      @selected="selectingDestinationPlace"
                    />
                  </div>

                  <div class="line">
                    <RgSuggestEmployee
                      id="employee"
                      ref="employee"
                      v-model="employee"
                      :occupation-id="form.isa_id_ocupacoes_cbo"
                      :rules="{ required: true }"
                      data-id="profissional"
                      data-item="profissional"
                      placeholder="Digite o nome do profissional responsável"
                      label="Profissional Responsável pela Saída"
                      @selected="selectingEmployee"
                    />

                    <RgSuggestCbo
                      id="cbo"
                      ref="cbo"
                      v-model="cbo"
                      :disabled="!canFillCbo"
                      :class="{ disable: !canFillCbo }"
                      :rules="{ required: true }"
                      :employeeId="form.isa_id_funcionarios"
                      @selected="selectingCbo"
                    />
                  </div>

                  <div class="input-field">
                    <RgTextArea
                      id="observations"
                      ref="textAreaObs"
                      v-model="form.ihs_observacao"
                      :maxlength="500"
                      label="Observação"
                    />
                  </div>
                  <div
                    :class="{ 'disable unselect': disableDeathCause }"
                    class="input-field"
                  >
                    <RgTextArea
                      id="death-cause"
                      ref="textArea"
                      v-model="form.isa_causa_obito"
                      :rules="{ required: !disableDeathCause }"
                      :maxlength="500"
                      :disabled="disableDeathCause"
                      label="Causa do Óbito"
                    />
                  </div>
                </div>
              </FormBase>
            </RgValidatorForm>
          </div>
        </form>
      </div>

      <div slot="footer" class="footer">
        <RgCleanButton id="clean-btn" small @click="cleanData" />
        <RgCancelButton id="cancel-btn" medium @click="close" />
        <RgSaveButton id="save-btn" medium @click="changeFlow" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import DeathCausesEnum from "./DeathCausesEnum";
import RgTypePatient from "$hospitalization/common/component/status/rg-type-patient/RgTypePatient.vue";

import RgSuggestHospitalizationDestinationPlace from "$hospitalization/common/component/suggest/rg-suggest-hospitalization-destination-place/RgSuggestHospitalizationDestinationPlace";
import RgSuggestHospitalizationExitGroups from "$hospitalization/common/component/suggest/rg-suggest-hospitalization-exit-groups/RgSuggestHospitalizationExitGroups";

import { GetGQLEnum } from "$hospitalization/common/component/util/GetTypeBedValues";
import { AlertError, toast } from "~tokio/primitive/notification";
import TypeBedOperationEnum from "$hospitalization/common/component/util/TypeBedOperationEnum";

import {
  RgCleanButton,
  RgSaveButton,
  RgBaseModal,
  RgSuggestCbo,
  RgInputDate,
  RgInputHour,
  RgSuggestEmployee,
  RgValidatorForm,
  RgTextArea,
  RgCancelButton,
} from "~tokio/primitive";

export default {
  name: "ModalHospitalizationExit",
  components: {
    RgValidatorForm,
    RgBaseModal,
    RgCleanButton,
    RgInputDate,
    RgInputHour,
    RgSuggestEmployee,
    RgSuggestHospitalizationDestinationPlace,
    RgSuggestHospitalizationExitGroups,
    RgSaveButton,
    FormBase,
    RgSuggestCbo,
    RgTextArea,
    RgTypePatient,
    RgCancelButton,
  },
  props: {
    hospitalizationExtra: {
      type: Object,
      default: () => {},
    },
    operation: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 0,
    },
    bedId: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    situationDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        int_id: null,
        inl_id: null,
        isl_id: null,
        ihs_observacao: null,
        pac_id: null,
        int_numero: null,
        pes_nome: null,
        isa_data: null,
        isa_hora: null,
        isa_id_ocupacoes_cbo: null,
        isa_id_funcionarios: null,
        isa_id_internacoes_locais_destino: null,
        isa_id_internacoes_grupo_saida: null,
        isa_causa_obito: null,
        minDate: null,
      },
      hospitalizationInfo: {},
      employee: null,
      cbo: null,
      destinationPlace: null,
      suggestPatient: null,
      exitGroups: null,
      patient: null,
    };
  },
  computed: {
    disableDeathCause() {
      return !DeathCausesEnum.includes(
        this.form.isa_id_internacoes_grupo_saida,
      );
    },
    getMinDate() {
      let date = null;
      if (
        this.hospitalizationExtra &&
        this.hospitalizationExtra.int_data &&
        this.hospitalizationExtra.int_data.length > 0
      ) {
        const hospitalizationDate = this.hospitalizationExtra.int_data;
        const validFormat = this.$utils.date.IsValid(
          hospitalizationDate,
          "DD/MM/YYYY",
          true,
        );

        if (validFormat) {
          date = new Date(
            moment(this.hospitalizationExtra.int_data, "DD/MM/YYYY").format(
              "MM-DD-YYYY",
            ),
          );
        } else {
          date = new Date(
            moment(this.hospitalizationExtra.int_data).format("MM-DD-YYYY"),
          );
        }
      }
      return date;
    },

    isIndigente() {
      return this.hospitalizationExtra && !this.hospitalizationExtra.pac_id;
    },

    canFillCbo() {
      return this.form.isa_id_funcionarios;
    },

    isPatient() {
      return this.hospitalizationExtra && this.hospitalizationExtra.pac_id
        ? this.hospitalizationExtra.pac_id
        : 0;
    },

    indigentSex() {
      return this.hospitalizationExtra && this.hospitalizationExtra.ini_id_sexos
        ? this.hospitalizationExtra.ini_id_sexos
        : null;
    },

    patientSex() {
      return this.hospitalizationExtra && this.hospitalizationExtra.pes_id_sexos
        ? this.hospitalizationExtra.pes_id_sexos
        : null;
    },

    sentToPep() {
      return (
        this.hospitalizationExtra &&
        !!this.hospitalizationExtra.int_id_enderecamento_pep
      );
    },

    disableDate() {
      return (
        this.sentToPep &&
        this.hospitalizationExtra.int_data_alta_pep &&
        this.hospitalizationExtra.int_data_alta_pep.lenght > 0
      );
    },
  },
  watch: {
    hospitalizationExtra(pValue) {
      if (pValue) {
        this.mountValues(pValue);
        this.hospitalizationInfo = pValue;
      } else {
        this.close();
      }
    },
  },
  created() {
    this.BED_OCUPADO = 3;
    this.BED_DESINFECCAO = 4;
  },
  methods: {
    isValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    mountValues(pHospitalization) {
      if (pHospitalization && this.show) {
        this.$nextTick(async () => {
          if (
            !this.sentToPep ||
            (this.sentToPep && pHospitalization.int_data_alta_pep === null)
          )
            this.minDate = this.hospitalizationExtra.int_data;
          else this.minDate = null;
          this.form.inl_id = this.bedId;
          this.form.int_id = pHospitalization.int_id;
          this.form.int_numero = pHospitalization.int_numero;
          this.form.int_data = pHospitalization.int_data;
          this.form.int_hora = pHospitalization.int_hora;
          this.form.ihs_observacao = pHospitalization.int_obs_alta_pep
            ? pHospitalization.int_obs_alta_pep
            : null;
          if (pHospitalization.int_id_internacoes_grupos_saidas_pep !== null) {
            this.form.isa_id_internacoes_grupo_saida =
              pHospitalization.int_id_internacoes_grupos_saidas_pep;
            await this.$refs.exitGroups.forceSelection({
              igs_ativo: pHospitalization.igs_ativo,
              igs_nome: pHospitalization.igs_nome,
            });
          }
          if (pHospitalization.int_id_internacoes_locais_destino_pep !== null) {
            this.form.isa_id_internacoes_locais_destino =
              pHospitalization.int_id_internacoes_locais_destino_pep;
            await this.$refs.destination.forceSelection({
              ild_nome: pHospitalization.ild_nome,
            });
          }
          if (pHospitalization.int_id_funcionarios_pep !== null) {
            await this.$refs.employee.forceSelection({
              pes_nome: pHospitalization.fun_nome,
              vin_id: pHospitalization.vin_id,
            });
            this.form.isa_id_funcionarios =
              pHospitalization.int_id_funcionarios_pep;
            await this.$refs.cbo.forceSelection({
              ocp_nome: pHospitalization.ocp_nome,
              ocp_codigo: pHospitalization.ocp_codigo,
            });
          }
          this.form.isa_data = !this.sentToPep
            ? moment().format("DD/MM/YYYY")
            : pHospitalization.int_data_alta_pep &&
              pHospitalization.int_data_alta_pep.length > 0
            ? moment(pHospitalization.int_data_alta_pep).format("DD/MM/YYYY")
            : moment().format("DD/MM/YYYY");
          this.form.isa_hora = !this.sentToPep
            ? moment().format("HH:mm")
            : pHospitalization.int_hora_alta_pep &&
              pHospitalization.int_hora_alta_pep.length > 0
            ? pHospitalization.int_hora_alta_pep
            : moment().format("HH:mm");
          this.form.pac_id = pHospitalization.pac_id;
          this.form.isl_id = this.BED_DESINFECCAO;
        });
      }
    },

    prepareData() {
      const data = this.$utils.obj.DeepCopy(this.form);
      delete data.pes_nome;
      delete data.int_numero;
      delete data.minDate;

      data.isa_data = this.$utils.date.BrazilianDateToDatabase(data.isa_data);
      data.situation = GetGQLEnum(this.status);
      data.new_situation = GetGQLEnum(this.form.isl_id);
      data.ihs_tipo_operacao = TypeBedOperationEnum.SAIDA;

      return data;
    },

    async changeFlow(pError) {
      try {
        const isInvalid = !(await this.isValid());
        const validateDeathCause =
          !this.disableDeathCause && !this.form.isa_causa_obito;

        if (isInvalid || validateDeathCause) {
          return AlertError("Verifique os campos.");
        }

        const data = this.prepareData();
        await this.$store.dispatch(
          "Hospitalization/Bed/FLOW_BUSY_TO_EMPTY_WITH_EXIT",
          data,
        );
        toast.success("Salvo com sucesso!");
        this.$emit("reSearch", true);
        this.close();
      } catch (error) {
        return AlertError(error.message, [], "error");
      }
    },

    selectingExitGroups(pValue) {
      const hasSuggestion = pValue.source && pValue.source.igs_id > 0;

      if (hasSuggestion) {
        this.form.isa_id_internacoes_grupo_saida = pValue.source.igs_id
          ? pValue.source.igs_id
          : null;

        this.exitGroups = pValue.source.igs_nome;

        this.form.ihs_observacao = null;
        this.form.isa_causa_obito = null;
      }
    },

    selectingDestinationPlace(pValue) {
      const hasSuggestion = pValue.source && pValue.source.ild_id > 0;
      if (hasSuggestion) {
        this.form.isa_id_internacoes_locais_destino = pValue.source.ild_id
          ? pValue.source.ild_id
          : null;
      }
    },

    selectingEmployee(pValue) {
      const hasSuggestion =
        pValue && pValue.source && Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        const data = pValue.source.employeeRelationship;

        this.form.isa_id_funcionarios =
          data && data.vin_id_funcionarios ? data.vin_id_funcionarios : null;
      } else {
        this.form.isa_id_funcionarios = null;
        this.form.isa_id_ocupacoes_cbo = null;
        this.cbo = "";
      }
    },

    selectingCbo(pValue) {
      if (pValue.source) {
        this.form.isa_id_ocupacoes_cbo = pValue.source.ocp_id;
        this.cbo = pValue.source.ocp_nome;
      } else {
        this.form.isa_id_ocupacoes_cbo = null;
        this.cbo = null;
      }
    },

    namePatient(pValue) {
      return pValue || "PACIENTE NÃO IDENTIFICADO";
    },

    validateExitDate(pValue, pErrors) {
      const exitDate = this.form.isa_data;
      const exitHour = this.form.isa_hora;
      const dateConverted = moment(
        exitDate + " " + exitHour,
        "DD/MM/YYYY HH:mm",
      );

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push(
          "Data/Hora de saída não pode ser maior que a data/hora atual",
        );
        return false;
      }
      this.$refs.exitHour.cleanValidate();
      return true;
    },

    validateExitHour(pValue, pErrors) {
      const exitDate = this.form.isa_data;
      const exitHour = this.form.isa_hora;
      const dateConverted = moment(
        exitDate + " " + exitHour,
        "DD/MM/YYYY HH:mm",
      );

      if (!dateConverted.isValid()) {
        return false;
      }

      const dateFormat = dateConverted.format("YYYY-MM-DD HH:mm");

      if (dateFormat > moment().format("YYYY-MM-DD HH:mm")) {
        pErrors.push("Hora de saída não pode ser maior que a hora atual");
        return false;
      }
      this.$refs.exitDate.cleanValidate();
      return true;
    },

    cleanData() {
      this.form.ihs_observacao = null;
      this.form.isa_id_ocupacoes_cbo = null;
      this.form.isa_id_funcionarios = null;
      this.form.isa_id_internacoes_locais_destino = null;
      this.form.isa_id_internacoes_grupo_saida = null;
      this.form.isa_causa_obito = null;
      this.form.isa_data = moment().format("DD/MM/YYYY");
      this.form.isa_hora = moment().format("HH:mm");

      this.exitGroups = "";
      this.destinationPlace = "";
      this.employee = "";
      this.cbo = "";

      if (this.$refs.textArea) this.$refs.textArea.resetCharacter();
      if (this.$refs.textAreaObs) this.$refs.textAreaObs.resetCharacter();

      if (this.$refs.cbo) this.$refs.cbo.cleanValidate();
      if (this.$refs.exitGroups) this.$refs.exitGroups.cleanValidate();
      if (this.$refs.destination) this.$refs.destination.cleanValidate();
      if (this.$refs.employee) this.$refs.employee.cleanValidate();
      if (this.$refs.textArea) this.$refs.textArea.cleanValidate();
    },

    setScrollTopZero() {
      if (this.$refs.body) {
        const elementContainer = this.$refs.body;
        elementContainer.scrollTop = 0;
      }
    },

    close() {
      this.form.int_id = null;
      this.form.pes_nome = null;
      this.form.pac_id = null;
      this.form.isa_data = null;
      this.form.isa_hora = null;
      this.form.int_numero = null;
      this.form.isl_id = null;
      this.patient = null;

      this.cleanData();
      this.setScrollTopZero();

      this.$emit("close");
    },
  },
};
</script>
