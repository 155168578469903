import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import ExamRegisterScheduleQueue from "$exam/submodules/schedule/views/queue-schedule/ExamRegisterScheduleQueue";

export default {
  path: "/exam/schedule/queue/schedule",
  name: "exam.schedule.queue.schedule",
  components: {
    default: ExamRegisterScheduleQueue,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: OpenSubModule("exam") },
      { label: "Fila de Regulação", link: "/exam/schedule/queue" },
      { label: "Agendar" },
    ],
  },
};
