<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchProceduresProfessionalAgreements from "./action/SearchProceduresProfessionalAgreements";

export default {
  name: "RgSelectProceduresProfessionalAgreements",
  extends: RgSelect,
  props: {
    sectorId: {
      type: Number,
    },
    bondId: {
      type: Number,
    },
    procedureName: {
      type: String,
    },
    label: {
      type: String,
      default: "Cônvenios",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    bondId() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      this.valuesData = [];

      if (this.sectorId && this.bondId && this.procedureName) {
        const data = await SearchProceduresProfessionalAgreements({
          intIdSetor: this.sectorId,
          intVusId: this.bondId,
          strNomeProcedimento: this.procedureName,
        });

        data.forEach((item) => {
          if (item.enabled === "1") {
            this.valuesData.push({
              value: item.data,
              label: item.label,
            });
          }
        });
      }
    },
  },
};
</script>
