import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation ProcedureComplexity($procedureComplexity: ComplexityInputType) {
    ProcedureComplexity(procedureComplexity: $procedureComplexity) {
      com_id
      com_co_procedimento
      com_tp_complexidade
    }
  }
`;

export default async ({ commit }, { variables }) => {
  const { data } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data;
};
