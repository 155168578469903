<template>
  <div v-if="show" class="modal-change-status-patient">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">{{ title }}</div>
      </div>

      <div slot="body" class="body">
        <div class="sub-title">{{ subtitle }}</div>

        <form class="form" @submit.prevent="save">
          <RgValidatorForm ref="validator" class="validator">
            <RgComboboxReasonsDisqualification
              v-if="registerActive"
              ref="reason"
              v-model="form.reason"
              :rules="{ required: true }"
              class="reason"
              label="Motivo"
              @change="nameReason"
            />

            <RgTextArea
              v-if="registerActive"
              ref="disqualificationObservation"
              v-model="form.disqualificationObservation"
              :maxlength="225"
              label="Observações"
              class="text-area"
            />

            <RgTextArea
              v-else
              ref="reasonQualification"
              v-model="form.reasonQualification"
              :maxlength="225"
              :rules="{ required: true }"
              label="Motivo"
              class="text-area"
            />
          </RgValidatorForm>
        </form>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton medium @click="close" />
        <RgConfirmButton
          v-shortkey="['enter']"
          medium
          @shortkey.self="save"
          @click="save"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCancelButton,
  RgConfirmButton,
  RgTextArea,
  RgValidatorForm,
} from "~tokio/primitive";

import RgComboboxReasonsDisqualification from "$person/patient/component/combobox/rg-combobox-reasons-disqualification/RgComboboxReasonsDisqualification";
import moment from "moment";

export default {
  name: "ModalPatientCareHistory",
  components: {
    RgBaseModal,
    RgCancelButton,
    RgConfirmButton,
    RgTextArea,
    RgComboboxReasonsDisqualification,
    RgValidatorForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      registerActive: false,
      reasonText: null,
      form: {
        reason: null,
        description: null,
        disqualificationObservation: null,
        reasonQualification: null,
      },
    };
  },
  computed: {
    title() {
      return this.registerActive === true
        ? "Inativar o Cadastro do Paciente"
        : "Ativar o Cadastro do Paciente";
    },
    subtitle() {
      return this.registerActive === true
        ? "Escolha um motivo para inativar o cadastro selecionado"
        : "Descreva a causa da reativação do cadastro selecionado";
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }

      this.patientInfo?.patient?.pac_bloqueado === false
        ? (this.registerActive = true)
        : (this.registerActive = false);
    },
  },

  methods: {
    async isFormValid() {
      return this.$refs.validator.validate();
    },

    nameReason(pValue) {
      this.reasonText = pValue[0].text;
    },

    async save() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        this.$emit("reasonDisqualification", this.reasonText);

        this.$loader.start();

        const variables = {
          patient: {
            pac_id: this.patientInfo.patient.pac_id,
            pac_id_motivo_inabilitacao: this.form.reason,
            pac_observacao_inabilitacao: this.form.disqualificationObservation,
            pac_motivo_habilitacao: this.form.reasonQualification,
            pac_ativo: Number(!this.registerActive),
            pac_bloqueado: Number(this.registerActive),
            pac_data_inabilitacao: this.registerActive
              ? moment().format("YYYY-MM-DD HH:mm")
              : null,
          },
        };

        await this.$store.dispatch(
          "Person/Patient/CHANGE_STATUS_PATIENT",
          variables,
        );

        this.$toaster.success("O status do paciente foi alterado com sucesso");
        this.close();
        this.$emit("reSearch");
      } catch (pErr) {
        this.$toaster.error(pErr.message);
      } finally {
        this.$loader.finish();
      }

      this.$emit("save");
    },

    close() {
      this.$emit("close");

      this.form.reason = null;
      this.form.description = null;
      this.form.disqualificationObservation = null;
      this.form.reasonQualification = null;

      if (this.$refs.reason) {
        this.$refs.reason.cleanValidate();
      }

      if (this.$refs.disqualificationObservation) {
        this.$refs.disqualificationObservation.resetCharacter();
        this.$refs.disqualificationObservation.cleanValidate();
      }

      if (this.$refs.reasonQualification) {
        this.$refs.reasonQualification.resetCharacter();
        this.$refs.reasonQualification.cleanValidate();
      }
    },
  },
};
</script>
