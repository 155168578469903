<template>
  <FoundationExternallySchedule :moduleType="3" :printHtml="printHtml" />
</template>

<script>
import { FoundationExternallySchedule } from "~tokio/foundation/pages";
import PatientListHTML from "$hospitalization/hospitalizations/queue/html/exam-externally-schedule/PatientListHTML";

export default {
  name: "HospitalizationExternallySchedule",
  components: {
    FoundationExternallySchedule,
  },
  computed: {
    printHtml() {
      return {
        patientsList: (patientList, info) =>
          this.actPatientListHTML(patientList, info),
      };
    },
  },

  methods: {
    async actPatientListHTML(payload, info) {
      const snapPrint = await this.$store.dispatch(
        "Hospitalization/Queue/SEARCH_EXTERNALLY_SCHEDULE",
        payload,
      );

      const obj = {
        ...snapPrint,
        info,
      };
      return PatientListHTML(obj);
    },
  },
};
</script>
