<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchPharmacyProductCountyType from "./action/SearchPharmacyProductCountyType";

export default {
  name: "RgSelectPharmacyProductCountyType",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Tipo de Produto",
    },
    clientId: {
      type: Number,
      require: true,
    },
    productTypeId: {
      type: Number,
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
    productTypeId() {
      this.doSearch();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },

    async doSearch() {
      const data = await SearchPharmacyProductCountyType({
        mtp_id: this.productTypeId,
        mtp_id_clientes: this.clientId,
      });
      this.valuesData = data.map((item) => {
        return { value: item.mtp_id, label: item.mtp_nome };
      });
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
