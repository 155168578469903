<template>
  <div v-shortkey="['esc']" class="rg-confirm-modal" @shortkey="close">
    <RgBaseModal :show="show" class="rg-base-modal" @close="close">
      <div slot="header" class="confirm-header">
        <span class="title">{{ title }}</span>
      </div>

      <slot slot="body" name="body" class="confirm-body">
        <span class="message" v-html="message" />
      </slot>

      <div slot="footer" class="confirm-footer">
        <MediumButton
          id="cancel-btn"
          class="cancel"
          :label="noLabel"
          :backgroundColor="'#fb7e36'"
          @click="noClicked"
        />
        <MediumButton
          id="confirm-btn"
          class="confirm"
          :label="yesLabel"
          :backgroundColor="'#53b1d8'"
          @click="yesClicked"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import RgBaseModal from "./RgBaseModal";
import MediumButton from "~tokio/primitive/button/medium-button/MediumButton";

export default {
  name: "RgConfirmModal",
  components: {
    RgBaseModal,
    MediumButton,
  },
  props: {
    yesLabel: {
      type: String,
      default: "Sim. Tenho certeza",
    },
    noLabel: {
      type: String,
      default: "Cancelar",
    },
    title: {
      type: String,
      default: "Confirmar",
    },
    message: {
      type: String,
      default: "Deseja confirmar?",
    },
    show: {
      type: Boolean,
      default: undefined,
    },
  },
  methods: {
    yesClicked: function () {
      this.close();
      this.$emit("confirmed");
    },
    noClicked: function () {
      this.close();
      this.$emit("denied");
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
