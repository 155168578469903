<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnitHealthPep from "./action/SearchUnitHealthPep";
export default {
  name: "RgSelectUnitHealthPep",
  extends: RgSelect,

  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData() {
      this.updateOptions();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async fillData() {
      try {
        const data = await SearchUnitHealthPep();

        this.valuesData = data.map((item) => {
          return {
            value: item.data,
            label: item.label,
          };
        });

        if (this.valuesData.length === 1) {
          this.$emit("selectOnlyItem", this.valuesData[0].value);
        }
      } catch (error) {
        this.$toaster.error(error.message);
      }
    },

    blur() {
      this.$emit("blur");
    },
  },
};
</script>
