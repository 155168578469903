export default {
  SELECT_REGISTER_BILLING_BPAI: (state, data) => {
    state.selected_register_billing_bpai = data;
  },
  CLEAN_REGISTER_BILLING_BPAI: (state) => {
    state.selected_register_billing_bpai = null;
  },
  SET_FILTER_BILLING_BPAI: (state, data) => {
    state.filter_billing_bpai = data;
  },
  RESET_FILTER_BILLING_BPAI: (state) => {
    state.filter_billing_bpai = null;
  },
  SET_EMPLOYEE_REGISTER_BPAI: (state, data) => {
    state.selected_employee_register_bpai = data;
  },
  RESET_EMPLOYEE_REGISTER_BPAI: (state) => {
    state.selected_employee_register_bpai = null;
  },
};
