import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation ManageCnsSchedulingPatient($pes_id: Int, $cns: CnsInputType) {
    ManageCnsSchedulingPatient(pes_id: $pes_id, cns: $cns) {
      success
      message
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.mutate({ mutation, variables }).catch(
    (err) => {
      throw err;
    },
  );
  return data;
};
