import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/faturamento/controller-faturamento-servico-classificacao-unidade/buscar",
    {
      arrFormData: variables,
    },
  )
    .then(({ data }) => {
      data.dados.data = data.dados.recordSet;
      const result = data.dados;
      return result;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
