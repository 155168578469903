<template>
  <div v-if="show" class="modal-link-solicitation">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-link-solicitation-header">
        <h1 class="title-modal">
          Deseja vincular o agendamento a uma solicitação?
        </h1>

        <div class="text-modal">
          Existe(m) solicitação(ões) compatíveis com o agendamento atual
        </div>
      </div>

      <div slot="body" class="modal-link-solicitation-body">
        <FormBase title="Solicitação">
          <Tooltip
            message="
              Selecione uma solicitação compatível para cada procedimento a ser agendado"
            startOpen
            class="tooltip"
          >
            <IconInfoHelper slot="icon" hollow />
          </Tooltip>

          <SmartTable
            ref="smarttable"
            name="ModalLinkSolicitation"
            :columns="COLUMN_TABLE"
            :body="mutableLinkSolicitation.rows"
            :total="mutableLinkSolicitation.count"
            index-column="id"
            :show-pagination="false"
            toggle-selected
            remove-btn-columns
            mult-select
            hasCheck
            class="table-modal"
            @getMultLines="selectLines"
          />
        </FormBase>
      </div>

      <div slot="footer" class="modal-link-solicitation-footer">
        <MediumButton
          id="not-linked"
          title="Não vincular"
          label="Não Vincular"
          backgroundColor="#ff7f2a"
          class="mg-r"
          @click="notLinked"
        />
        <MediumButton
          id="linked"
          title="Vincular"
          label="Vincular"
          @click="linked"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, MediumButton, IconInfoHelper } from "~tokio/primitive";
import Tooltip from "~tokio/primitive/tooltip/tooltip/Tooltip";
import { SmartTable, FormBase } from "~tokio/foundation";
export default {
  name: "ModalLinkSolicitation",
  components: {
    RgBaseModal,
    Tooltip,
    FormBase,
    MediumButton,
    SmartTable,
    IconInfoHelper,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestedData: {
      type: Object,
      default: () => {},
    },
    showPatientName: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mutableLinkSolicitation: {
        rows: [],
        count: 0,
      },
      selectedLines: [],
    };
  },

  watch: {
    show(pValue) {
      if (pValue) {
        this.mutableLinkSolicitation = this.requestedData;
        this.mutableLinkSolicitation.rows.forEach((item) => {
          item.data_solicitacao = this.$utils.date.BrazilianDateFormat(
            item.data_solicitacao,
          );
        });
      } else {
        this.mutableLinkSolicitation = {
          rows: [],
          count: 0,
        };
        this.selectedLines = [];
      }
    },
  },

  created() {
    this.COLUMN_TABLE = [
      { name: "Procedimento", key: "procedimento" },
      { name: "Data", key: "data_solicitacao" },
      { name: "Profissional", key: "solicitante" },
    ];
    if (this.showPatientName) {
      this.COLUMN_TABLE.push({ name: "Paciente", key: "paciente" });
    }
    this.NOT_LINK_REQUEST = 1;
    this.LINK_REQUEST = 2;
  },

  methods: {
    selectLines(list) {
      let hasRepeated = false;
      list.forEach((item) => {
        const filterArray = list.filter(
          (key) =>
            key.procedimento === item.procedimento &&
            key.exe_id_pacientes === item.exe_id_pacientes,
        );

        hasRepeated = filterArray;
      });

      if (!(hasRepeated && hasRepeated.length > 1)) {
        this.selectedLines = list;
      } else {
        this.$toaster.warning(
          `Já existe uma solicitação selecionada com o procedimento - ${hasRepeated[0].procedimento}.`,
        );
      }
      this.$refs.smarttable.updateMultSelectRow(this.selectedLines);
    },

    linked() {
      if (this.selectedLines && this.selectedLines.length === 0) {
        this.$toaster.warning("Selecione no mínimo uma solicitação");
        return;
      }
      this.$emit("linked", this.selectedLines, this.LINK_REQUEST);
      this.close();
    },

    notLinked() {
      this.$emit("not-linked", null, this.NOT_LINK_REQUEST);
      this.close();
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
