import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFormData: variables,
  };
  return AuthLegacyRequest.post(
    "farmacia/controller-transporte-satelite/consumir",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
