<template>
  <aside v-if="show" class="modal-person-detail">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      class="rg-alert-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">
          {{
            hasLinkNewborn
              ? "Cadastro de Recém-Nascido"
              : "Cadastro do Paciente"
          }}
        </span>
      </div>

      <div slot="body" class="body">
        <div class="content-detail">
          <QuickPatientDetails
            ref="patientDetails"
            hasLinkNewborn
            removeAnvil
            @fail="statusFail"
            @success="statusSuccess"
          />
        </div>
      </div>

      <div slot="footer" class="footer">
        <div class="action">
          <RgSaveButton
            ref="saveButton"
            large
            data-id="salvar"
            type="button"
            title="Salvar recém-nascido"
            @click="savePatient"
          />
        </div>
      </div>
    </RgBaseModal>
  </aside>
</template>

<script>
import { RgBaseModal, RgSaveButton } from "~tokio/primitive";
import { QuickPatientDetails } from "$person/patient/component";

export default {
  name: "ModalPersonDetail",
  components: {
    RgBaseModal,
    RgSaveButton,
    QuickPatientDetails,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hasLinkNewborn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusSuccess() {
      this.$refs.saveButton.actionDone();
    },

    statusFail() {
      this.$refs.saveButton.fail();
    },

    savePatient() {
      this.$refs.patientDetails.savePatient();
    },
    close() {
      this.$refs.patientDetails.clean();
      this.$emit("close");
    },
  },
};
</script>
