<template lang="html">
  <div v-if="show" class="modal-active-desactive">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title"> {{ title }} </span>
      </div>

      <div slot="body" class="body">
        <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
        <div
          v-if="bodyText"
          class="body-text"
          :class="{ 'align-center': alignCenter }"
          :style="`justify-content: ${justifyContent ? 'center' : 'left'}`"
          v-html="bodyText"
        ></div>
        <slot name="more-info"></slot>

        <div v-if="isSchedule && isActive">
          <RgComboboxReason
            id="reason"
            v-model="reason"
            :isAppointment="isAppointment"
            :rules="{ required: true }"
            label="Motivo"
            filterHollidays
          />
        </div>
        <div v-if="!isSchedule" class="textarea">
          <RgTextArea
            id="reason-mcd"
            ref="textArea"
            v-model="reasonTextArea"
            label="Motivo"
            class="rg-text-area"
            :class="{ disable: isActive }"
            :disabled="isActive"
            :maxlength="maxlength"
            :rules="{ required: true }"
            :viewCharacters="false"
          />
        </div>
      </div>

      <div slot="footer" class="footer">
        <div class="footer-buttons">
          <RgCancelButton id="cancelar-btn" medium @click="close" />
          <MediumButton
            id="confirm-btn"
            ref="confirmButton"
            :label="labelBtn"
            medium
            :disabled="disabledButton"
            @click="affirm"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgCancelButton,
  RgTextArea,
  MediumButton,
} from "~tokio/primitive";

import RgComboboxReason from "~tokio/primitive/combobox/rg-combobox-reason/RgComboboxReason";

export default {
  name: "ModalActiveDesactive",
  components: {
    RgBaseModal,
    RgCancelButton,
    RgTextArea,
    MediumButton,
    RgComboboxReason,
  },
  props: {
    show: Boolean,
    isActive: Boolean,
    isSchedule: Boolean,
    isAppointment: Boolean,

    confirm: Function,
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    bodyText: {
      type: String,
      default: "",
    },
    msgSuccess: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 500,
    },
    labelBtn: {
      type: String,
      default: "",
    },
    textReason: {
      type: String,
      default: "",
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    justifyContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reason: null,
      reasonTextArea: "",
      disableConfirmButton: true,
    };
  },

  computed: {
    disabledButton() {
      return this.isSchedule
        ? this.isActive && !this.reason
        : !this.isActive && this.reasonTextArea.length < 5;
    },
  },

  watch: {
    reason(pValue) {
      this.actionReason(pValue);
    },
    textReason(pValue) {
      this.reason = this.textReason;
    },
  },
  methods: {
    actionReason(pValue) {
      this.isSchedule
        ? this.emitReason()
        : !pValue.trim() || pValue.length < 5
        ? (this.disableConfirmButton = true)
        : this.emitReason();
    },

    emitReason() {
      this.disableConfirmButton = false;
      this.isSchedule
        ? this.$emit("reason", this.reason)
        : this.$emit("reason", this.reasonTextArea);
    },

    close() {
      if (!this.isActive || this.isSchedule) {
        this.reason = null;
        this.reasonTextArea = "";
      }
      this.$emit("close");
    },

    async affirm() {
      this.$loader.start();

      await this.confirm(this.reason);
      this.close();

      this.$emit("reSearch");
      this.$loader.finish();
    },
  },
};
</script>
