<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListSectorsMappedPep from "./action/ListSectorsMappedPep";

export default {
  name: "RgSelectSectorMappedPep",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Setor",
    },
    unitHealthId: {
      default: null,
    },
    placeId: {
      type: Number,
      default: null,
    },
    defaultText: {
      type: String,
      default: "Digite o nome do setor",
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },

  watch: {
    unitHealthId(pValue) {
      this.mValue = null;
      this.doSearch();
    },
    valuesData(val) {
      this.updateOptions();
    },
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (this.unitHealthId === null) return Promise.resolve([]);

      const data = await ListSectorsMappedPep({
        unitHealthId: this.unitHealthId,
        placeId: this.placeId,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.set_id,
          label: `${item.set_nome}`,
        };
      });
    },
  },
};
</script>
