<template>
  <div class="appointment-schedule-per-periods">
    <RgSearch
      ref="rgsearch"
      v-model="mutableEmployeeAgenda"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :build-filter="generateFilter"
      :show-footer="false"
      :show-empty-message="false"
      :showTitle="false"
      :disabledShorkey="modalConfirmSchedulingDeletion"
      @afterPerformSearch="afterSearchFilter"
      @count="getCountValue"
    >
      <div slot="filters" class="appointment-schedule-per-periods-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="select-unity"
              v-model="form.selectUnity"
              :rules="{ required: true }"
              permission="consulta.agendamentoCompleto"
              class="inputitem"
              disabled
            />
          </div>
          <div class="selectinput">
            <RgSelectSectorBondAndPermission
              id="sector"
              ref="sector"
              v-model="form.sector"
              :rules="{ required: true }"
              :unit-health-id="form.selectUnity"
              :submodule-id="subModuleId"
              class="inputitem"
              @change="clearDependenciesSector"
            />
          </div>

          <div class="selectinput">
            <RgSuggestCboSector
              id="cbo"
              ref="Cbo"
              v-model="form.cbo"
              :disabled="!hasSector"
              :class="{ disable: !hasSector }"
              :sectorId="Number(form.sector)"
              label="Ocupação"
              class="inputitem"
              activeProfessional
              @selected="selectedCbo"
            />
          </div>

          <div class="selectinput">
            <RgSelectProfessionalSectorOccupationScaleActive
              id="employee"
              v-model="form.employee"
              :sectorId="Number(form.sector)"
              :ocuppation-id="form.ocp_id"
              default-text="Todos"
              class="inputitem"
              label="Profissional"
              :disabled="!hasSector"
              :class="{ disable: !hasSector }"
            />
          </div>

          <div class="periods">
            Hoje +
            <RgInput
              id="initial-day"
              v-model="form.initialDay"
              :disabled="true"
              label=""
              class="days mg"
              type="number"
            />
            {{ form.lbl_tipo_periodo_inicial }}
          </div>

          <div class="periods">
            <RgInput
              id="final-day"
              v-model="form.finalDay"
              :disabled="true"
              label=""
              class="days"
              type="number"
            />
            {{ form.lbl_tipo_periodo_final }}. A partir de
            <RgInput
              id="day-early-schedule"
              v-model="form.dayEarlySchedule"
              label=""
              class="days mg"
              type="number"
            />
            dias.
          </div>
        </div>
      </div>

      <div
        v-show="mutableEmployeeAgenda.length > 0"
        class="appointment-schedule-per-periods-result"
      >
        <div class="content-grid">
          <legend class="legend">Profissionais</legend>

          <div class="table-one">
            <SmartTable
              ref="smartTableAgenda"
              name="AppointmentSchedulePerPeriodsEmployee"
              :columns="COLUMN_TABLE_ONE"
              :body="mutableEmployeeAgenda"
              :total="mutableEmployeeAgenda.length"
              :show-pagination="false"
              :initialColumns="INITIAL_COLUMNS"
              :colorLine="actionColorLine"
              index-column="eag_id"
              second-index-column="DataConsulta"
              toggleSelected
              @getLine="selectemployeeAgendaData"
            >
              <div slot="legends" class="legends">
                <div class="circle --grey" />
                <span class="legend">Sem vagas disponíveis</span>
                <div class="circle --red" />
                <span class="legend">Inabilitado no dia</span>
              </div>
            </SmartTable>
          </div>

          <div class="painel-button">
            <RgNewScheduleButton
              id="new-schedule-patient"
              :disabled="!scheduleSelected || scaleActiveInactive"
              class="buttons-bottom"
              title="Agendar paciente"
              @click="scheduleAction"
            />

            <RgEditButton
              id="edit-scheduled-patient"
              :disabled="!scheduleSelected || scaleActiveInactive"
              class="buttons-bottom"
              title="Editar escala de atendimento"
              @click="openModalChangeProfessionalScale"
            />
            <SmallButton
              id="inactive"
              title="Inabilitar"
              class="buttons-top"
              :backgroundColor="'#1e88a9'"
              :permission="hasActiveInactiveSchedulePermission"
              :disabled="!rulesInactive"
              @click="openModalActiveInactive(true)"
            >
              <IconInactiveSchedule
                slot="icon"
                :disabled="
                  !rulesInactive || hasActiveInactiveSchedulePermission
                "
              />
            </SmallButton>
            <SmallButton
              id="active"
              title="Habilitar"
              class="buttons-top"
              :permission="hasActiveInactiveSchedulePermission"
              :backgroundColor="'#1e88a9'"
              :disabled="!rulesActive"
              @click="openModalActiveInactive(false)"
            >
              <IconActiveSchedule
                slot="icon"
                :disabled="!rulesActive || hasActiveInactiveSchedulePermission"
              />
            </SmallButton>

            <RgShowButton
              id="show-reason"
              class="buttons-top"
              title="Detalhes Escala Inativada"
              :disabled="
                !(
                  scheduleSelected &&
                  hasReasonActiveInactive &&
                  scaleActiveInactive
                )
              "
              @click="openModalReasonActiveInactive"
            />
            <RgDropdown
              id="options-printer"
              :disabled="
                !scheduleSelected || disablePrint || scaleActiveInactive
              "
              :action-options="itemActionOptionsConsultationControl()"
              small
              show-up
              show-left
            >
              <IconPrinter slot="icon" />
            </RgDropdown>
          </div>

          <div class="separator" />

          <legend class="legend">Pacientes Agendados para o Dia</legend>

          <div class="table-two">
            <SmartTable
              ref="smartTableEmployee"
              name="AppointmentSchedulePerPeriodsAppointment"
              :columns="COLUMN_TABLE_TWO"
              :body="mutableAppointmentSchedule.data"
              :total="Number(mutableAppointmentSchedule.total)"
              :show-pagination="false"
              @getLine="getAppointmentsRow"
            />
          </div>

          <div class="painel-button">
            <RgEditButton
              id="edit-scheduling"
              :disabled="appointmentsSelected"
              title="Editar Agendamento"
              class="buttons-bottom"
              @click="editScheduledAppointment"
            />

            <RgDeleteScheduleButton
              id="delete-scheduling"
              :disabled="appointmentsSelected"
              title="Excluir Agendamento"
              class="buttons-bottom"
              @click="openModalConfirmSchedulingDeletion"
            />

            <RgShowButton
              id="details-scheduling"
              :disabled="appointmentsSelected"
              class="buttons-bottom"
              title="Detalhes"
              @click="openModalAppointmentDetails"
            />

            <RgHistoryButton
              id="history-attendance"
              :disabled="appointmentsSelected"
              class="buttons-top"
              title="Histórico de Atendimentos"
              @click="patientCareHistory"
            />

            <RgPersonCardButton
              id="edit-patient"
              :disabled="appointmentsSelected"
              class="buttons-bottom"
              @click="patientRecord"
            />

            <RgDropdown
              id="options-person"
              :item="appointmentsData || {}"
              :disabled="appointmentsSelected"
              :action-options="itemActionOptionsPerson(appointmentsData)"
              small
              show-up
              show-left
            >
              <IconPatientBioslab slot="icon" />
            </RgDropdown>

            <RgDropdown
              id="options-printer"
              :disabled="appointmentsSelected"
              :item="appointmentsData || {}"
              :action-options="itemActionOptionsPrinter(appointmentsData)"
              small
              show-up
              show-left
            >
              <IconPrinter slot="icon" />
            </RgDropdown>

            <RgDropdown
              id="pep"
              ref="dropDownPep"
              :disabled="appointmentsSelected"
              :item="appointmentsData || {}"
              :action-options="itemActionOptionsPep(appointmentsData)"
              small
              show-up
              show-left
              notBackgroundWhenDisabled
              :backgroundColor="'#ffffff'"
            >
              <IconPep slot="icon" />
            </RgDropdown>
          </div>
        </div>
      </div>

      <div v-show="mutableEmployeeAgenda.length < 1" class="no-result">
        <IconEmpty />
        <span class="message">
          Não foram encontrados resultados para essa busca
        </span>
      </div>
    </RgSearch>

    <ModalChangeProfessionalScale
      v-if="modalChangeProfessionalScale"
      id="modal-change-professional-scale"
      ref="modalChangeProfessionalScale"
      :data="employeeAgendaData || {}"
      :show="modalChangeProfessionalScale"
      :technical-scale-permission="technicalScalePermission"
      :normal-scale-permission="normalScalePermission"
      @reSearch="researchFirstTable"
      @close="closeModalChangeProfessionalScale"
      @error="handleErrorChangeProfessionalScale"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmSchedulingDeletion"
      @reSearch="researchFirstTable"
    />

    <ModalPatientImageInRow
      id="modal-patient-image"
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :modId="appointmentModuleId"
      :fill-id="filId"
      hideButtons
      view-only
      @close="closeModalPatientImageInRow"
    />

    <ModalTypesPrint
      id="modal-types-print"
      ref="modalTypePrint"
      :show="modalTypesPrint"
      :appointment-id="appointmentIdToPrint"
      @close="closeModalTypesPrint"
    />

    <ModalAppointmentDetails
      id="modal-appointment-details"
      :show="modalAppointmentDetails"
      :pcc-id="appointmentsData ? appointmentsData.pcc_id : 0"
      :appointment-id="appointmentsData ? appointmentsData.con_id : 0"
      :unity-health="
        appointmentsData ? appointmentsData.tcu_id_unidades_saude : 0
      "
      @close="closeModalAppointmentDetails"
    />

    <ModalScaleConflict
      id="modal-scale-conflict"
      ref="modalScaleConflict"
      :show="showModalScaleConflict"
      :conflicts="scaleConflicts"
      :history="scaleHistory"
      @close="actionCloseModalScaleConflict"
    />

    <ModalObservationQueue
      id="modal-observation-queue"
      :show="modalObservationQueue"
      :patientData="appointmentsData"
      :modId="appointmentModuleId"
      @close="closeModalObservationQueue"
    />

    <ModalActiveDesactive
      id="modal-active-desactive"
      v-bind="propsModalActiveDesactive"
      @close="closeModalActiveDesactive"
    />

    <ModalReasonActiveInactive
      id="modal-active-desactive"
      :show="showModalReasonActiveInactive"
      :isActive="isActive"
      :scheduleInfo="employeeAgendaData"
      @close="closeModalReasonActiveInactive"
    />
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSectorBondAndPermission,
  RgSuggestCboSector,
  RgEditButton,
  RgNewScheduleButton,
  RgDeleteScheduleButton,
  RgPersonCardButton,
  RgInput,
  IconPatientBioslab,
  RgShowButton,
  IconEmpty,
  RgHistoryButton,
  RgDropdown,
  IconPep,
  IconPrinter,
  IconInactiveSchedule,
  IconActiveSchedule,
  SmallButton,
  ModalActiveDesactive,
  ModalReasonActiveInactive,
} from "~tokio/primitive";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";

import {
  ModalPatientImageInRow,
  ModalConfirmDeletion,
  ModalObservationQueue,
} from "~tokio/primitive/modal";
import moment from "moment";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import {
  ModalChangeProfessionalScale,
  ModalTypesPrint,
  ModalAppointmentDetails,
  ModalScaleConflict,
} from "$appointment/submodules/schedule/component/";

import ScheduleOutpatientConsultationControlPortraitHTML from "$appointment/submodules/schedule/html/ScheduleOutpatientConsultationControlPortraitHTML";
import ScheduleOutpatientConsultationControlLandscapeHTML from "$appointment/submodules/schedule/html/ScheduleOutpatientConsultationControlLandscapeHTML";
import ScheduleHealthcareBulletinHTML from "~tokio/primitive/html/ScheduleHealthcareBulletinHTML.js";
import SchedulePatientFollowUpSheetHTML from "$appointment/submodules/schedule/html/SchedulePatientFollowUpSheetHTML";
import {
  printThermalOneWay,
  printThermalTwoWay,
  printScheduleVoucher,
} from "$appointment/common/utils/printsScheduleVoucherAppointment";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";
import RgSelectProfessionalSectorOccupationScaleActive from "$appointment/common/component/rg-select-professional-sector-occupation-scale-active/RgSelectProfessionalSectorOccupationScaleActive";

export default {
  name: "AppointmentSchedulePerPeriods",
  components: {
    RgSearch,
    RgSelectUnithealth,
    RgSelectSectorBondAndPermission,
    RgSuggestCboSector,
    SmartTable,
    RgEditButton,
    RgNewScheduleButton,
    RgDeleteScheduleButton,
    RgPersonCardButton,
    IconPatientBioslab,
    RgDropdown,
    IconPep,
    IconPrinter,
    ModalChangeProfessionalScale,
    ModalConfirmDeletion,
    RgInput,
    ModalPatientImageInRow,
    ModalTypesPrint,
    RgShowButton,
    ModalAppointmentDetails,
    RgSelectProfessionalSectorOccupationScaleActive,
    ModalScaleConflict,
    IconEmpty,
    ModalObservationQueue,
    RgHistoryButton,
    IconInactiveSchedule,
    IconActiveSchedule,
    SmallButton,
    ModalActiveDesactive,
    ModalReasonActiveInactive,
  },

  data() {
    return {
      form: {
        sector: null,
        ocp_id: null,
        cbo: null,
        employee: null,
        pes_id: null,
        initialDay: null,
        finalDay: null,
        dayEarlySchedule: null,
        lbl_tipo_periodo_final: "",
        lbl_tipo_periodo_inicial: "",
        vin_id: null,
        fun_id: 0,
        tipoPeriodoInicial: null,
        tipoPeriodoFinal: null,
        selectUnity: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
      },
      mutableAppointmentSchedule: {
        data: [],
        total: 0,
      },
      totalAgenda: 0,
      sectorInVuex: null,
      items: [],
      mutableEmployeeAgenda: [],
      modalChangeProfessionalScale: false,
      modalConfirmSchedulingDeletion: false,
      modalPatientImageInRow: false,
      modalTypesPrint: false,
      modalAppointmentDetails: false,
      modalObservationQueue: false,
      controllerClean: true,
      payloadAppointmentSchedule: {},
      employeeAgendaData: null,
      appointmentsData: null,
      filId: null,
      disablePrint: false,
      technicalScalePermission: 1,
      normalScalePermission: 1,
      disablePrinting: false,
      appointmentId: [],
      showModalScaleConflict: false,
      scaleConflicts: [],
      scaleHistory: [],
      showModalActiveInactive: false,
      showModalReasonActiveInactive: false,
      isActive: false,
    };
  },

  computed: {
    rulesInactive() {
      return (
        this.scheduleSelected &&
        this.scaleCurrentAndFutureDates &&
        this.scaleWithoutScheduledPatient &&
        !this.scaleActiveInactive &&
        !this.isHolidayOrBreak
      );
    },
    rulesActive() {
      return this.scheduleSelected && this.scaleActiveInactive;
    },
    hasReasonActiveInactive() {
      return this.employeeAgendaData?.motivo_inabilitar !== "";
    },

    isHolidayOrBreak() {
      return this.employeeAgendaData?.motivo_inabilitar.match(
        /feriado|recesso/i,
      );
    },
    scaleActiveInactive() {
      return (
        parseInt(this.employeeAgendaData?.funcionario_inabilitado) === 1 ||
        parseInt(this.employeeAgendaData?.hag_atendimento) === 0
      );
    },
    propsModalConfirmDeletion() {
      const maxlength = 250;
      const subtitle = "A operação não poderá ser desfeita";
      const show = this.modalConfirmSchedulingDeletion;
      const confirm = (reason) => this.deleteScheduling(reason);

      return { show, confirm, maxlength, subtitle };
    },

    subModuleId() {
      return 42;
    },

    actionColorLine() {
      return {
        get: (item) => {
          if (
            parseInt(item.funcionario_inabilitado) === 1 ||
            parseInt(item.hag_atendimento) === 0
          ) {
            return GetColorRowSmartTable("red");
          }
          if (parseInt(item.eag_quantidade_restantes) === 0) {
            return GetColorRowSmartTable("grey");
          }
        },
      };
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    scheduleSelected() {
      return this.employeeAgendaData !== null;
    },

    appointmentsSelected() {
      return !this.employeeAgendaData || !this.appointmentsData;
    },

    canSchedulePatient() {
      return this.scheduleSelected;
    },

    hasFreeSchedule() {
      return (
        this.employeeAgendaData &&
        Number(this.employeeAgendaData.eag_quantidade_restantes) > 0
      );
    },

    hasSector() {
      return this.form.sector;
    },

    selectedEmployee() {
      return this.$store.getters["Appointment/Schedule/GET_SELECTED_EMPLOYEE"];
    },

    existFilterData() {
      return this.$store.getters[
        "Appointment/Schedule/GET_FILTER_APPOINTMENT_PER_PERIODS"
      ];
    },

    propsModalActiveDesactive() {
      const show = this.showModalActiveInactive;
      let title = null;
      let subtitle = null;
      let msgSuccess = null;
      if (this.isActive) {
        title = "Inabilitar Escala";
        subtitle = "Selecione o motivo para inabilitar a escala selecionada";
        msgSuccess = "Escala inativada com sucesso";
      } else {
        title = "Habilitar Escala";
        msgSuccess = "Escala habilitada com sucesso";
      }

      const confirm = (reason) => this.actionActiveInactiveSchedule(reason);
      const textReason = this.employeeAgendaData?.motivo_inabilitar;
      const bodyText = `Tipo de escala: ${this.employeeAgendaData?.type} <br/>
                      Profissional: ${this.employeeAgendaData?.fun_nome} <br/>
                      Início: ${this.employeeAgendaData?.inicio}`;
      const isActive = this.isActive;

      const labelBtn = "Salvar";
      const justifyContent = false;
      const isSchedule = true;
      const isAppointment = true;

      return {
        show,
        title,
        subtitle,
        msgSuccess,
        confirm,
        textReason,
        bodyText,
        isActive,
        labelBtn,
        justifyContent,
        isSchedule,
        isAppointment,
      };
    },
    hasPermissionSendAppointmentPep() {
      return !!this.$Permissions.sector.has(
        "consulta.integracaoPep.enviarAtendimento",
        this.employeeAgendaData.set_id,
      );
    },

    hasPermissionCancelAppointmentPep() {
      return !!this.$Permissions.sector.has(
        "consulta.integracaoPep.enviarCancelamentoAtendimento",
        this.employeeAgendaData.set_id,
      );
    },

    appointmentIdToPrint: {
      get: function () {
        return this.appointmentId;
      },
      set: function (newValue) {
        this.appointmentId = [];
        this.appointmentId.push(Number(newValue));
      },
    },

    preferences() {
      const preferences = {
        blockedRetroactiveSendingToPep: !!Number(
          this.$store.getters["Login/GET_PREFERENCES"][
            "tViewMarcacaoConsulta.con_bloquear_envio_retroativo_pep"
          ],
        ),
        printOptionsPreference: this.$store.getters["Login/GET_PREFERENCES"][
          "tViewBuscaAgendamentoConsultaDia.opcoesImpressao"
        ],
      };

      return preferences;
    },

    hasActiveInactiveSchedulePermission() {
      return !!this.$Permissions.sector.has(
        "consulta.agendamentoCompleto.multiplosAgendamentos",
        this.employeeAgendaData?.set_id,
      );
    },

    scaleWithoutScheduledPatient() {
      return Number(this.employeeAgendaData?.eag_quantidade_marcadas) === 0;
    },
    scaleCurrentAndFutureDates() {
      return moment(
        this.employeeAgendaData?.DataConsulta,
        "DD/MM/YYYY",
      ).isSameOrAfter(moment(), "day");
    },
  },

  watch: {
    async "form.sector"(pValue) {
      const sectorId = Number(pValue);
      const isValidSector = Number.isInteger(sectorId);
      const invalidValue = pValue === false;

      if (isValidSector) {
        const isDifferentSector = this.sectorInVuex !== pValue;

        if (isDifferentSector) {
          this.sectorInVuex = null;
          const periodsAndDaysInfo = await this.$store.dispatch(
            "Appointment/Schedule/GET_DAYS_AND_PERIODS_INFO_FROM_SECTOR",
            { intIdSetores: pValue },
          );

          this.mountFilter(periodsAndDaysInfo);
        }
      } else if (invalidValue) {
        this.defaultFilter();
        this.form.ocp_id = null;
        this.form.cbo = null;
        this.form.employee = null;
      }
    },

    mutableEmployeeAgenda(pValue) {
      if (pValue && pValue.length > 0) {
        this.selectemployeeAgendaData(this.selectedEmployee, true);
      }
    },
  },

  mounted() {
    const isAppontmentSchedule = this.$route.params.isAppontmentSchedule;
    if (isAppontmentSchedule) {
      this.appointmentId = this.$route.params.appointmentId;
      this.viewAppointmentScheduleVoucher(this.appointmentId);
    }
    this.fillFilter();
  },

  beforeCreate() {
    this.COLUMN_TABLE_ONE = [
      { name: "Profissional", key: "fun_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Data", key: "DataConsulta" },
      { name: "Dia da Semana", key: "dsm_nome" },
      { name: "Início", key: "inicio" },
      { name: "Qtd. de Horários", key: "eag_quantidade_consultas" },
      { name: "Qtd. Ocupada", key: "eag_quantidade_marcadas" },
      { name: "Qtd. Livre", key: "eag_quantidade_restantes" },
      { name: "Tipo de Escala", key: "type" },
    ];

    this.COLUMN_TABLE_TWO = [
      { name: "Hora", key: "con_hora" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Data de Nascimento", key: "pes_nascimento" },
      { name: "Objetivo", key: "tcu_novo_tipo_consulta" },
      { name: "Enviado PEP", key: "enviado" },
      { name: "Status Meu Esus", key: "sme_nome" },
    ];
  },

  created() {
    this.INITIAL_COLUMNS = 8;
    this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.PERMISSION_APPOINTMENT_SCHEDULE_PER_PERIOD_SHOWS = 116;
  },

  destroyed() {
    const existThisRouteSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-periods",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteSchedulePerPeriods) {
      this.$store.commit(
        "Appointment/Schedule/RESET_FILTER_APPOINTMENT_PER_PERIODS",
      );
      this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
    }
  },

  methods: {
    openModalActiveInactive(isActive) {
      if (this.isHolidayOrBreak) {
        const motivo = this.employeeAgendaData?.motivo_inabilitar;
        this.$toaster.info(
          `Motivo: ${motivo}`,
          "Profissional inabilitado neste dia",
        );
        return;
      }
      this.showModalActiveInactive = true;
      this.isActive = isActive;
    },
    closeModalActiveDesactive() {
      this.showModalActiveInactive = false;
      this.isActive = false;
    },
    openModalReasonActiveInactive() {
      this.showModalReasonActiveInactive = true;
    },
    closeModalReasonActiveInactive() {
      this.showModalReasonActiveInactive = false;
    },
    clearDependenciesSector() {
      if (this.controllerClean) {
        this.form.cbo = null;
        this.form.ocp_id = null;
        this.form.employee = null;
      }
    },

    hasPermissionsToEditSchedule() {
      const isReservaTecnica = this.employeeAgendaData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.alterarAgendamentoPeriodoReservaTecnica",
          this.employeeAgendaData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.alterar",
          this.employeeAgendaData.set_id,
        );
      }
    },

    hasPermissionsToSchedule() {
      const isReservaTecnica = this.employeeAgendaData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.agendarReservaTecnica",
          this.employeeAgendaData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.incluir",
          this.employeeAgendaData.set_id,
        );
      }
    },

    hasPermissionsToDeleteSchedule() {
      const isReservaTecnica = this.employeeAgendaData.cte_id === "2";
      if (isReservaTecnica) {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.exclusaoAgendamentoPeriodoReservaTecnica",
          this.employeeAgendaData.set_id,
        );
      } else {
        return this.$Permissions.sector.has(
          "consulta.agendamentoCompleto.excluir",
          this.employeeAgendaData.set_id,
        );
      }
    },

    mountFilter(pValue) {
      this.form.finalDay = pValue.marcacao.vsi_quantidade;

      if (this.form.sector) {
        this.form.initialDay = pValue.carencia.vsi_quantidade;
      } else {
        const DEFAULT_DIAS_INICIAL = "3";
        this.form.initialDay = DEFAULT_DIAS_INICIAL;
      }

      if (Number(pValue.marcacao.vsi_id_intervalo_periodo) === 1) {
        this.form.lbl_tipo_periodo_final = "dias";
      } else {
        this.form.lbl_tipo_periodo_final = "semanas";
      }

      if (Number(pValue.carencia.vsi_id_intervalo_periodo) === 1) {
        this.form.lbl_tipo_periodo_inicial = "dias até hoje + Período de";
      } else {
        this.form.lbl_tipo_periodo_inicial = "semanas até hoje + Período de";
      }

      this.form.tipoPeriodoInicial = pValue.carencia.vsi_id_intervalo_periodo;
      this.form.tipoPeriodoFinal = pValue.marcacao.vsi_id_intervalo_periodo;
    },

    defaultFilter() {
      this.form.lbl_tipo_periodo_inicial = "dias até hoje + Período de";
      this.form.lbl_tipo_periodo_final = "dias";
      this.form.initialDay = "0";
      this.form.finalDay = "30";
    },

    async searchFilter(pData) {
      try {
        const hasCboSelected =
          this.form.cbo && this.form.cbo.length > 0 && !this.form.ocp_id;

        const hasEmployeeSelected =
          this.form.employee &&
          this.form.employee.length > 0 &&
          !this.form.employee;

        if (hasCboSelected) {
          this.$toaster.warning("A seleção de uma ocupação é obrigatória");
          return false;
        }

        if (hasEmployeeSelected) {
          this.$toaster.warning("A seleção de um profissional é obrigatória");
          return false;
        }
        this.$loader.start("Carregando dados da busca de consulta");
        const data = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_EMPLOYEE_AGENDA_PER_PERIODS",
          pData,
        );

        if (!data) this.mutableEmployeeAgenda = [];

        return data;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    scheduleAction() {
      if (!this.hasPermissionsToSchedule()) {
        this.$toaster.error(
          "Você não possui permissão para cadastrar um agendamento.",
          "Usuário Sem Permissão",
        );
        return;
      }
      const hasValue = this.mutableEmployeeAgenda.find(
        (item) =>
          item.eag_id === this.employeeAgendaData.eag_id &&
          item.DataConsulta === this.employeeAgendaData.DataConsulta,
      );

      if (hasValue) {
        this.employeeAgendaData = hasValue;

        this.$store.commit(
          "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
          this.employeeAgendaData,
        );
      }

      if (!this.hasFreeSchedule) {
        return this.$toaster.warning(
          "Profissional não tem mais vagas disponíveis!",
          "Sem vagas",
        );
      }
      this.$store.commit("Appointment/Schedule/DESTROY_TYPE_SCHEDULE");

      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");

      this.$router.push({ name: "appointment.schedule.per-periods.form" });
    },

    editScheduledAppointment() {
      if (!this.hasPermissionsToEditSchedule()) {
        this.$toaster.error(
          "Usuário sem permissão para a edição de Consulta.",
          "Usuário Sem Permissão",
        );
        return;
      }
      this.$store.commit("Appointment/Schedule/DESTROY_TYPE_SCHEDULE");

      this.$router.push({ name: "appointment.schedule.per-periods.edit.form" });
    },

    async deleteScheduling(reason) {
      try {
        const { con_id, set_id } = this.appointmentsData;

        const {
          normalPermission,
          technicalPermission,
        } = this.getPermissionOperation(set_id);

        const variables = {
          intIdConsulta: con_id,
          strMotivo: reason,
          blnPermissaoNormal: normalPermission,
          blnPermissaoReserva: technicalPermission,
        };

        await this.$store.dispatch(
          "Appointment/Schedule/REMOVE_APPOINTMENT",
          variables,
        );

        this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");

        this.clearSelectionSchedule();

        this.$toaster.success("Agendamento removido com sucesso");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao tentar remover o agendamento");
      } finally {
        this.$loader.finish();
      }
    },

    getPermissionOperation(pSectorId) {
      const subModuleOperationNormal = "consulta.agendamentoCompleto.excluir";
      const subModuleOperationTechnical =
        "consulta.agendamentoCompleto.exclusaoAgendamentoPeriodoReservaTecnica";

      const normalPermission = this.$Permissions.sector.has(
        subModuleOperationNormal,
        pSectorId,
      );

      const technicalPermission = this.$Permissions.sector.has(
        subModuleOperationTechnical,
        pSectorId,
      );

      return { normalPermission, technicalPermission };
    },

    patientRecord() {
      const patientId = this.appointmentsData.pes_id;
      this.$store.commit("Person/Patient/SET_PERSON_ID", Number(patientId));

      this.$router.push({
        name: "appointment.schedule.per-period.edit-patient",
      });
    },

    getCountValue(pValue) {
      this.totalAgenda = Number(pValue) || 0;
    },

    selectemployeeAgendaData(pData, reSearch = false) {
      if (pData) {
        if (!reSearch) {
          this.$store.commit(
            "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
            pData,
          );
          this.employeeAgendaData = pData;
        } else {
          const index = this.mutableEmployeeAgenda.findIndex((row) => {
            const compareId = row.eag_id === pData.eag_id;
            const compareDate = row.DataConsulta === pData.DataConsulta;

            return compareId && compareDate;
          });

          if (index !== -1) {
            this.$store.commit(
              "Appointment/Schedule/SELECT_EMPLOYEE_DATA",
              this.mutableEmployeeAgenda[index],
            );

            this.employeeAgendaData = this.mutableEmployeeAgenda[index];
          }
        }

        this.cleanAppointmentList();

        if (
          this.employeeAgendaData &&
          Number(this.employeeAgendaData?.eag_quantidade_marcadas) > 0
        ) {
          this.payloadAppointmentSchedule = this.generateFilterAppointmentSchedule(
            this.employeeAgendaData.hag_id,
            this.employeeAgendaData.DataConsulta,
          );

          this.searchFilterAppointmentSchedule();
        }
        this.disablePrint = Number(pData.eag_quantidade_marcadas) < 1;
      } else {
        this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
        this.employeeAgendaData = null;
        this.cleanAppointmentList();
      }
    },

    getAppointmentsRow(pData) {
      this.$store.commit("Appointment/Schedule/SELECT_APPOINTMENT_DATA", pData);
      this.appointmentsData = pData;
      this.filId = Number(pData?.fil_id) || null;
    },

    generateFilterAppointmentSchedule(idHistorySchedule, dataConsulta) {
      const variables = {
        arrFiltro: {
          intIdAgendamento: 0,
          intIdHistoricoAgendamento: Number(idHistorySchedule),
          intIdUnidade: this.form.selectUnity,
          strDataConsulta: dataConsulta,
          limiteFim: 1000000,
          limiteInicio: 0,
        },
      };

      return variables;
    },

    async searchFilterAppointmentSchedule() {
      try {
        this.$loader.start();
        this.mutableAppointmentSchedule = await this.$store.dispatch(
          "Appointment/Schedule/SEARCH_APPOINTMENT_SCHEDULE",
          this.payloadAppointmentSchedule,
        );
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    async researchFirstTable() {
      try {
        await this.$refs.rgsearch.performSearch(false, false);
        if (this.selectedEmployee) {
          this.$refs.smartTableEmployee.selectRow(this.selectedEmployee);
          this.selectemployeeAgendaData(this.selectedEmployee, true);
        }
      } catch (pErr) {
        console.log(pErr);
      }
    },

    generateFilter() {
      const variables = {
        intSet_id: this.form.sector || 0,
        intOcp_id: this.form.ocp_id || 0,
        intPes_id: this.form.employee || 0,
        intUns_id: this.form.selectUnity,
        strHag_data_consulta: moment().format("DD/MM/YYYY"),
        intDiasInicial: this.form.initialDay,
        intDiasFinal: this.form.finalDay,
        intTipoPeriodoInicial: this.form.tipoPeriodoInicial || 1,
        intTipoPeriodoFinal: this.form.tipoPeriodoFinal || 1,
        intDiasProximoAgendamento: Number(this.form.dayEarlySchedule),
      };

      this.$store.commit(
        "Appointment/Schedule/SET_FILTER_APPOINTMENT_PER_PERIODS",
        this.form,
      );

      return variables;
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.$loader.finish();
      } else {
        this.form.ocp_id = 0;
        this.form.employee = null;
      }
    },

    openModalPatientImageInRow() {
      if (this.filId) {
        this.modalPatientImageInRow = true;
      } else {
        this.$toaster.warning(
          "Esse paciente não foi cadastrado pela fila de regulação",
        );
      }
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    observationPatient() {
      if (this.filId) {
        this.openModalObservationQueue();
      } else {
        this.$toaster.warning(
          "Este paciente não foi cadastrado pela fila de regulação",
        );
      }
    },

    openModalObservationQueue() {
      this.modalObservationQueue = true;
    },

    closeModalObservationQueue() {
      this.modalObservationQueue = false;
    },

    itemActionOptionsConsultationControl(pItem) {
      return [
        {
          label: "Ficha de controle amb. (Retrato)",
          action: () => {
            this.printOutpatientConsultationControlPortrait();
          },
        },
        {
          label: "Ficha de controle amb. (Paisagem)",
          action: () => {
            this.printOutpatientConsultationControlLandscape();
          },
        },
      ];
    },

    itemActionOptionsPerson(pItem) {
      return [
        {
          label: "Arquivos do paciente na fila",
          action: this.openModalPatientImageInRow,
        },
        {
          label: "Observações do paciente na fila",
          action: this.observationPatient,
        },
      ];
    },

    itemActionOptionsPrinter(appointmentsData) {
      return [
        {
          label: "Imprimir comprovante de agendamento",
          action: () => {
            this.appointmentIdToPrint = appointmentsData.con_id;
            this.openModalTypesPrint();
          },
          disable: false,
        },
        {
          label: "Imprimir ficha de acompanhamento do paciente",
          action: this.printPatientFollowUpSheet,
          disable: false,
        },
        {
          label: "Imprimir boletim de atendimento médico",
          action: this.printHealthcareBulletin,
          disable: false,
        },
      ];
    },

    itemActionOptionsPep(pItem) {
      const alreadyWasInPep = pItem && pItem.enviado === "SIM";
      return [
        {
          label: "Enviar atendimento para o PEP",
          action: this.sendAppointmentPep,
          disable: alreadyWasInPep,
        },
        {
          label: "Cancelar atendimento no PEP",
          action: this.cancelAppointmentonPep,
          disable: !alreadyWasInPep,
        },
      ];
    },

    sendAppointmentPep() {
      if (!this.validBlockedRetroactiveSendingToPep()) {
        this.$toaster.error(
          "Não é possível enviar um agendamento retroativo para o Pep",
        );
        return;
      }
      if (!this.hasPermissionSendAppointmentPep) {
        this.$toaster.error("Usuário sem permissão para enviar atendimento");
        return;
      }

      this.$loader.start();

      this.$store
        .dispatch(
          "Appointment/Schedule/SEND_APPOINTMENT_PEP",
          this.appointmentsData.con_id,
        )
        .then((data) => {
          this.$toaster.success("Atendimento enviado para o PEP");
          this.clearSelectionSchedule();
          this.searchFilterAppointmentSchedule();
          this.$refs.dropDownPep.clickOutside();
        })
        .catch((err) => {
          const { esus_response } = err;
          if (esus_response?.hasError) {
            if (esus_response.dados === "DiferencaMaiorPermitidaException") {
              this.$toaster.info(
                "Realize o agendamento do paciente e envie os dados para o PEP um dia antes da data marcada.",
                "O envio para o PEP é realizado 1 dia antes do agendamento",
              );
              this.clearSelectionSchedule();
              this.$refs.dropDownPep.clickOutside();
              return false;
            } else {
              this.$toaster.error(
                err.message,
                "Falha ao enviar atendimento para o PEP",
              );
            }
          }
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    cancelAppointmentonPep() {
      if (!this.hasPermissionCancelAppointmentPep) {
        this.$toaster.error("Usuário sem permissão para cancelar atendimento");
        return;
      }

      this.$loader.start();

      this.$store
        .dispatch(
          "Appointment/Schedule/CANCEL_APPOINTMENT_PEP",
          this.appointmentsData.con_id,
        )
        .then((data) => {
          this.$toaster.success(
            "Cancelamento do Atendimento enviado para o PEP",
          );

          this.clearSelectionSchedule();
          this.searchFilterAppointmentSchedule();
          this.$refs.dropDownPep.clickOutside();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao enviar cancelamento do atendimento para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    async printPatientFollowUpSheet() {
      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_PATIENT_FOLLOW_UP_SHEET",
        {
          intIdPacienteConsulta: this.appointmentsData.pcc_id,
          intIdUnidadeSaude: this.form.selectUnity,
        },
      );

      const html = SchedulePatientFollowUpSheetHTML(data);

      this.$utils.print.printHtml(html);
    },

    async searchOutpatientConsultationControl() {
      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_OUT_PATIENT_CONSULTATION_CONTROL",
        { intIdHistoricoAgendamento: this.employeeAgendaData.hag_id },
      );

      return data;
    },
    async printOutpatientConsultationControlLandscape() {
      try {
        this.$loader.start();
        const client = this.$store.getters["Login/GET_CLIENT_CODE"];
        const unithealth = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

        const employeeAgendaData = {
          client,
          unithealth,
          ...this.employeeAgendaData,
        };

        const data = await this.searchOutpatientConsultationControl();

        const html = ScheduleOutpatientConsultationControlLandscapeHTML(
          employeeAgendaData,
          data,
        );

        this.$utils.print.printHtml(html);
      } catch (pErr) {
        this.$toaster.error(
          "Erro ao gerar impressão",
          this.$utils.sanitize.formatError(pErr),
        );
      } finally {
        this.$loader.finish();
      }
    },
    async printOutpatientConsultationControlPortrait() {
      try {
        this.$loader.start();
        const client = this.$store.getters["Login/GET_CLIENT_CODE"];
        const unithealth = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];

        const employeeAgendaData = {
          client,
          unithealth,
          ...this.employeeAgendaData,
        };

        const data = await this.searchOutpatientConsultationControl();

        const html = ScheduleOutpatientConsultationControlPortraitHTML(
          employeeAgendaData,
          data,
        );

        this.$utils.print.printHtml(html);
      } catch (pErr) {
        this.$toaster.error(
          "Erro ao gerar impressão",
          this.$utils.sanitize.formatError(pErr),
        );
      } finally {
        this.$loader.finish();
      }
    },

    async printHealthcareBulletin() {
      const data = await this.$store.dispatch(
        "Appointment/Schedule/GET_HEALTHCARE_BULLETIN",
        { intIdConsulta: this.appointmentsData.con_id },
      );
      const html = ScheduleHealthcareBulletinHTML(data);

      this.$utils.print.printHtml(html);
    },

    openModalTypesPrint() {
      this.modalTypesPrint = true;
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    openModalChangeProfessionalScale() {
      this.technicalScalePermission = this.$Permissions.sector.has(
        "consulta.agendamentoCompleto.alterarDadosDiaAgendamentoPeriodoReservaTecnica",
        this.employeeAgendaData.set_id,
      )
        ? 1
        : 0;

      this.normalScalePermission = this.$Permissions.sector.has(
        "consulta.agendamentoCompleto.alterarDadosDia",
        this.employeeAgendaData.set_id,
      )
        ? 1
        : 0;

      if (
        !this.normalScalePermission &&
        this.employeeAgendaData.type === "Normal"
      ) {
        this.$toaster.warning(
          "Sem permissão para alterar a escala normal.",
          "Usuário sem Permissão",
        );
        return;
      }
      if (
        !this.technicalScalePermission &&
        this.employeeAgendaData.type === "Reserva Técnica"
      ) {
        this.$toaster.warning(
          "Sem permissão para alterar a escala de reserva técnica.",
          "Usuário sem Permissão",
        );
        return;
      }

      this.modalChangeProfessionalScale = true;
    },

    closeModalChangeProfessionalScale() {
      this.modalChangeProfessionalScale = false;
    },

    openModalConfirmSchedulingDeletion() {
      if (!this.hasPermissionsToDeleteSchedule()) {
        this.$toaster.error(
          "Sem permissão para excluir um agendamento.",
          "Usuário sem Permissão",
        );
        return;
      }
      const { enviado } = this.appointmentsData;
      if (enviado === "SIM") {
        this.$toaster.error(
          "A consulta foi enviada para o Pep e não pode ser excluída. Cancele o atendimento para excluir a Consulta.",
        );
        return;
      }
      this.modalConfirmSchedulingDeletion = true;
    },

    closeModalConfirmSchedulingDeletion() {
      this.modalConfirmSchedulingDeletion = false;
    },

    openModalAppointmentDetails() {
      if (!this.hasPermissionsToEditSchedule()) {
        this.$toaster.error(
          "Usuário sem permissão para ver os detalhes da consulta.",
          "Usuário Sem Permissão",
        );
        return;
      }
      this.modalAppointmentDetails = true;
    },

    closeModalAppointmentDetails() {
      this.modalAppointmentDetails = false;
    },

    patientCareHistory() {
      const patientInfo = this.appointmentsData;
      this.$router.push({
        name: "appointment.schedule.per-periods.patient-care-history",
        params: patientInfo,
      });
    },

    cleanForm() {
      this.form.selectUnity = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.sector = null;
      this.form.ocp_id = null;
      this.form.cbo = null;
      this.form.employee = null;
      this.form.pes_id = null;
      this.form.vin_id = null;
      this.form.fun_id = null;
      this.sectorInVuex = null;
      this.cbo = null;
      this.filId = null;
      this.appointmentsData = null;
      this.employeeAgendaData = null;
      this.form.tipoPeriodoInicial = null;
      this.form.tipoPeriodoFinal = null;
      this.modalPatientImageInRow = false;
      this.modalTypesPrint = false;
      this.totalAgenda = 0;
      this.mutableEmployeeAgenda = [];
      this.form.sector = null;
      this.form.dayEarlySchedule = null;
      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };
      this.$store.commit(
        "Appointment/Schedule/RESET_FILTER_APPOINTMENT_PER_PERIODS",
      );
      this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
      this.defaultFilter();
    },

    async viewAppointmentScheduleVoucher(appointmentID) {
      this.appointmentIdToPrint = appointmentID;
      switch (this.preferences.printOptionsPreference) {
        case "2": // Opções de Impressão
          this.openModalTypesPrint();
          break;
        case "6": // Impressão Normal
          await printScheduleVoucher(this.appointmentIdToPrint);
          break;
        case "7": // Impressora Térmica 1 via
          await printThermalOneWay(this.appointmentIdToPrint);
          break;
        case "8": // Impressora Térmica 2 vias
          await printThermalTwoWay(this.appointmentIdToPrint);
          break;
        default:
          break;
      }
    },

    afterSearchFilter(submitFromButton) {
      if (this.$refs.smartTableAgenda) {
        this.$refs.smartTableAgenda.cleanSelectRow();
      }
      if (this.$refs.smartTableEmployee) {
        this.$refs.smartTableEmployee.cleanSelectRow();
      }
      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };
      this.employeeAgendaData = null;

      if (submitFromButton && this.$refs.smartTableAgenda) {
        this.$refs.smartTableAgenda.setScrollTopZero();
      }
    },

    async fillFilter() {
      try {
        const storedData = this.existFilterData;
        if (storedData) {
          this.controllerClean = false;
          this.form.selectUnity = storedData.selectUnity;
          this.form.sector = storedData.sector;

          this.$nextTick(() => {
            if (storedData.cbo) {
              const cboCodeAndName = storedData.cbo.split(" - ");

              this.$refs.Cbo.forceSelection({
                ocp_codigo: cboCodeAndName[0],
                ocp_nome: cboCodeAndName[1],
                ocp_id: storedData.ocp_id,
              });
            }

            this.form.employee = storedData.employee;
            this.form.pes_id = storedData.pes_id;
            this.form.vin_id = storedData.vin_id;
          });

          this.form.tipoPeriodoInicial = storedData.tipoPeriodoInicial;
          this.form.tipoPeriodoFinal = storedData.tipoPeriodoFinal;

          this.form.lbl_tipo_periodo_final = storedData.lbl_tipo_periodo_final;
          this.form.lbl_tipo_periodo_inicial =
            storedData.lbl_tipo_periodo_inicial;

          this.form.initialDay = storedData.initialDay;
          this.form.finalDay = storedData.finalDay;
          this.form.dayEarlySchedule = storedData.dayEarlySchedule;

          this.sectorInVuex = storedData.sector;

          this.$nextTick(async () => {
            await this.$refs.rgsearch.performSearch();
          });

          if (this.selectedEmployee) {
            this.$refs.smartTableAgenda.selectRow(this.selectedEmployee);
          }

          setTimeout(() => {
            this.controllerClean = true;
          }, 1000);
        } else {
          this.defaultFilter();
        }
      } catch (err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação");
      }
    },

    clearSelectionSchedule() {
      this.appointmentsData = null;
      this.$refs.smartTableEmployee.cleanSelectRow();
    },

    cleanAppointmentList() {
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
      this.appointmentsData = null;
      this.$refs.smartTableEmployee.cleanSelectRow();
      this.mutableAppointmentSchedule = {
        data: [],
        total: 0,
      };
    },

    validBlockedRetroactiveSendingToPep() {
      const today = moment({ h: 0, m: 0, s: 0 });
      const scheduleDate = moment(
        this.employeeAgendaData.DataConsulta,
        "DD/MM/YYYY",
      );

      if (this.preferences.blockedRetroactiveSendingToPep) {
        return !(scheduleDate < today);
      }

      return true;
    },

    handleErrorChangeProfessionalScale(pData) {
      const CONFLICT_SCALE_EXCEPTION =
        "ConflitoAlteracaoDePeriodoHorarioException";

      const isConflictScaleError = pData.exception === CONFLICT_SCALE_EXCEPTION;

      if (isConflictScaleError) {
        const exceptionDetails = Object.assign({}, pData.detalhes_exececao);
        const hasConflictsData = exceptionDetails.escalas?.length > 0;
        const hasHistoryData = exceptionDetails.historicos?.length > 0;

        this.scaleConflicts = hasConflictsData ? exceptionDetails.escalas : [];

        this.scaleHistory = hasHistoryData ? exceptionDetails.historicos : [];

        this.actionOpenModalScaleConflict();
      } else {
        this.$toaster.error(pData);
      }
    },

    actionCloseModalScaleConflict() {
      this.showModalScaleConflict = false;
    },

    actionOpenModalScaleConflict() {
      this.showModalScaleConflict = true;
    },

    async actionActiveInactiveSchedule(reason) {
      try {
        this.$loader.start();
        const path = `Appointment/Schedule/ENABLE_DISABLE_PERIODS`;
        const idsHollidays = [16, 11, 10, 24, 18];
        const isHolliday =
          idsHollidays.includes(Number(reason)) ||
          reason.match(/feriado|recesso/i);

        const auxArr = [];
        if (isHolliday) {
          this.mutableEmployeeAgenda.forEach((item) => {
            if (
              moment(item.DataConsulta, "DD/MM/YYYY").isSame(
                moment(this.employeeAgendaData.DataConsulta, "DD/MM/YYYY"),
              )
            ) {
              const exists = auxArr.some((elem) => elem.vus_id === item.vus_id);

              if (!exists) {
                auxArr.push({ set_id: item.set_id, vus_id: item.vus_id });
              }
            }
          });
        } else {
          auxArr.push({
            set_id: this.employeeAgendaData.set_id,
            vus_id: this.employeeAgendaData.vus_id,
          });
        }

        const variables = {
          arrFormData: {
            arrVinculosSetores: auxArr,
            intIdUnidadeSaude: this.employeeData?.uns_id,
            strDataInicial: this.employeeAgendaData?.DataConsulta,
            strDataFinal: this.employeeAgendaData?.DataConsulta,
            strOperacao: this.isActive ? "INABILITAR" : "HABILITAR",
            intObservacao: this.isActive ? reason : 0,
            strHoraInicio: isHolliday
              ? null
              : this.employeeAgendaData?.eag_inicio_atendimentos,
            strHoraFinal: isHolliday
              ? null
              : this.employeeAgendaData?.eag_final_atendimentos,
            blnPermissaoEscalaNormal: this.hasActiveInactiveSchedulePermission,
            blnPermissaoEscalaReservaTecnica: this
              .hasActiveInactiveSchedulePermission,
          },
        };

        await this.$store.dispatch(path, variables);

        this.$toaster.success(
          `Período ${this.isActive ? "inabilitado" : "habilitado"} com sucesso`,
        );
        await this.$refs.rgsearch.performSearch(false, false);
        this.employeeAgendaData = null;
        if (this.$refs.smartTableAgenda) {
          this.$refs.smartTableAgenda.cleanSelectRow();
        }
        this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
      } catch (err) {
        this.$toaster.error(
          `Erro ao ${this.isActive ? "inabilitar" : "habilitar"} período`,
          this.$utils.sanitize.formatError(err),
        );
      } finally {
        this.$loader.finish();
      }
    },
  },
};
</script>
