import moment from "moment";
import { GraphQLClient, gql } from "@/common/request/GraphQLClient";
import {
  removeGraphQLTypeName,
  prepareDateToBR,
} from "./HospitalizationBillingAihHelper";
import {
  HospitalizationBillingsFields,
  HospitalizationAihReleaseRequestCodeFields,
} from "../../common/service/fields";
import ProcedureChangeValue from "../../common/service/ProcedureChangeValue";

const query = gql`
query GetHospitalizationInfo($hospitalizationBillingAihIdPassed: Boolean!, $hospitalizationId: Int, $hospitalizationBillingAihId: Int)
{
  hospitalizations(int_id: $hospitalizationId) @skip(if: $hospitalizationBillingAihIdPassed) {
		rows {
      int_id
      int_id_internacoes_caracteres
      int_data
      cid10 {
        cid_codigo
			}
      hospitalizationProcedures {
        inp_novo_codigo_procedimento
      }
      modality {
        imo_codigo
      }
      bedSpecialty {
        ils_codigo
      }
      responsibleProfessional {
        person {
          pes_nome
          cns {
            crs_numero
          }
        }
      }
      requestedProfessional {
        person {
          pes_nome
          cns {
            crs_numero
          }
        }
      }
      hospitalizationExits {
        isa_data
        groups {
          igs_codigo
        }
      }
    }
  }
  hospitalizationBillingAih(iaf_id: $hospitalizationBillingAihId) @include(if: $hospitalizationBillingAihIdPassed) {
    rows {
      hospitalizationBilling {
        ${HospitalizationBillingsFields}
        hospitalizationAihReleaseRequestCode {
          ${HospitalizationAihReleaseRequestCodeFields}
        }
      }
    }
  }
}`;

export default async (
  { commit },
  { hospitalizationId, hospitalizationBillingAihId },
) => {
  const hospitalizationBillingAihIdPassed = !!hospitalizationBillingAihId;

  const variables = {
    hospitalizationId,
    hospitalizationBillingAihId,
    hospitalizationBillingAihIdPassed,
  };
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  });

  if (
    (!data.hospitalizations || data.hospitalizations.rows.length < 1) &&
    (!data.hospitalizationBillingAih ||
      data.hospitalizationBillingAih.rows.length < 1)
  )
    return false;

  const fieldsPrepared = prepareHospitalizationBillingInfo(data);

  if (fieldsPrepared.hospitalizationAihReleaseRequestCode) {
    commit(
      "SET_HOSPITALIZATION_AIH_RELEASE_REQUEST_CODE",
      removeGraphQLTypeName(
        fieldsPrepared.hospitalizationAihReleaseRequestCode,
      ),
    );
  }

  if (fieldsPrepared.inf_data_internacao) {
    commit(
      "SET_HOSPITALIZATION_AIH_HOSPITALIZATION_DATE",
      prepareDateToBR(fieldsPrepared.inf_data_internacao),
    );
  }

  if (fieldsPrepared.inf_data_saida) {
    commit(
      "SET_HOSPITALIZATION_AIH_EXITS_DATE",
      prepareDateToBR(fieldsPrepared.inf_data_saida),
    );
  }

  delete fieldsPrepared.hospitalizationAihReleaseRequestCode;
  delete fieldsPrepared.inf_data_internacao;
  delete fieldsPrepared.inf_data_saida;

  commit(
    "SET_HOSPITALIZATION_BILLING_INFO_TAB",
    removeGraphQLTypeName(fieldsPrepared),
  );
  return true;
};

const prepareHospitalizationBillingInfo = (pFields) => {
  let fieldsPrepared = {};

  if (
    pFields.hospitalizationBillingAih &&
    pFields.hospitalizationBillingAih.rows[0].hospitalizationBilling
  ) {
    const hospitalizationBilling =
      pFields.hospitalizationBillingAih.rows[0].hospitalizationBilling;
    const inf_data_autorizacao = hospitalizationBilling.inf_data_autorizacao
      ? moment(hospitalizationBilling.inf_data_autorizacao).format("DD/MM/YYYY")
      : null;
    const hospitalizationAihReleaseRequestCode = {
      ...hospitalizationBilling.hospitalizationAihReleaseRequestCode,
    };

    fieldsPrepared = {
      ...removeGraphQLTypeName(hospitalizationBilling),
      inf_data_autorizacao,
    };

    if (hospitalizationAihReleaseRequestCode) {
      fieldsPrepared.hospitalizationAihReleaseRequestCode = {
        ...removeGraphQLTypeName(hospitalizationAihReleaseRequestCode),
      };
    }
  } else if (pFields.hospitalizations) {
    const hospitalizations = pFields.hospitalizations.rows[0];

    fieldsPrepared.inf_data_internacao = hospitalizations.int_data;

    const hospitalizationCharCode =
      hospitalizations?.int_id_internacoes_caracteres;

    fieldsPrepared.inf_codigo_carater_internacao =
      hospitalizationCharCode.length > 0 ? hospitalizationCharCode : null;

    if (hospitalizations.hospitalizationExits) {
      fieldsPrepared.inf_data_saida =
        hospitalizations.hospitalizationExits.isa_data;

      const finishReason =
        hospitalizations?.hospitalizationExits?.groups?.igs_codigo;

      fieldsPrepared.inf_motivo_encerramento =
        finishReason.length > 0 ? finishReason : null;
    }

    if (hospitalizations.cid10) {
      fieldsPrepared.inf_cid_principal = hospitalizations.cid10.cid_codigo;
    }

    if (hospitalizations.hospitalizationProcedures) {
      fieldsPrepared.inf_procedimento_principal =
        hospitalizations.hospitalizationProcedures.inp_novo_codigo_procedimento;
      fieldsPrepared.inf_procedimento_solicitado =
        fieldsPrepared.inf_procedimento_principal;
      fieldsPrepared.inf_mudanca_procedimento = ProcedureChangeValue(
        fieldsPrepared.inf_procedimento_principal,
        fieldsPrepared.inf_procedimento_solicitado,
      );
    }

    if (hospitalizations.modality) {
      fieldsPrepared.inf_codigo_modalidade =
        hospitalizations.modality.imo_codigo;
    }

    if (hospitalizations.bedSpecialty) {
      fieldsPrepared.inf_codigo_especialidade_leito =
        hospitalizations.bedSpecialty.ils_codigo;
    }

    if (
      hospitalizations.responsibleProfessional &&
      hospitalizations.responsibleProfessional.person.cns
    ) {
      fieldsPrepared.inf_nome_responsavel =
        hospitalizations.responsibleProfessional.person.pes_nome;
      fieldsPrepared.inf_documento_responsavel =
        hospitalizations.responsibleProfessional.person.cns.crs_numero;
    }

    if (
      hospitalizations.requestedProfessional &&
      hospitalizations.requestedProfessional.person.cns
    ) {
      fieldsPrepared.inf_nome_solicitante =
        hospitalizations.requestedProfessional.person.pes_nome;
      fieldsPrepared.inf_documento_solicitante = `${hospitalizations?.requestedProfessional?.person?.cns?.crs_numero}`;
    }
  }

  return fieldsPrepared;
};
