import moment from "moment";
import isEmpty from "lodash/isEmpty";

export const FORMATS = {
  BRAZILIAN_DATE_FORMAT: "DD/MM/YYYY",
};

export const BrazilianDateToDatabase = (pDateString) => {
  return !isEmpty(pDateString)
    ? moment(pDateString, "DD/MM/YYYY").format("YYYY-MM-DD")
    : "";
};

export const BrazilianDateTimeToDatabase = (pDateTimeString, pFormat) => {
  const format = pFormat || "DD/MM/YYYY HH:mm";
  return !isEmpty(pDateTimeString)
    ? moment(pDateTimeString, format).format("YYYY-MM-DD HH:mm")
    : "";
};

export const BrazilianDateFormat = (pDate, pDay = "DD") => {
  return moment(pDate || moment()).format(`${pDay}/MM/YYYY`);
};

export const BrazilianTimeFormat = (pTime, pHour = "HH") => {
  return moment(pTime || moment()).format(`${pHour}:mm`);
};

export const BrazilianDateTimeFormat = (pDateTime, pDay = "DD") => {
  return moment(pDateTime || moment()).format(`${pDay}/MM/YYYY HH:mm:ss`);
};

export const BrazilianDateShortTimeFormat = (pDateTime, pDay = "DD") => {
  return moment(pDateTime || moment()).format(`${pDay}/MM/YYYY HH:mm`);
};

export const BrazilianDateNow = (pDay = "DD") => {
  return BrazilianDateFormat(null, pDay);
};

export const BrazilianTimeNow = (pHour = "HH") => {
  return BrazilianTimeFormat(null, pHour);
};

export const DateNow = () => {
  return moment().format();
};

export const DateNowToDatabase = () => {
  return moment().format("YYYY-MM-DD");
};

export const DateTimeNowToDatabase = () => {
  return moment().format("YYYY-MM-DD HH:mm:ss");
};

export const BrazilianFistDayCurrentMonth = () => {
  return BrazilianDateFormat(null, "01");
};

export const BrazilianDateToInternational = (pDateString) => {
  return pDateString.split("/").reverse().join("-");
};

export const CompetenceFirstDayToDatabase = (pCompetence) => {
  return moment(pCompetence, "YYYYMM").format("YYYY-MM-[01]");
};

export const CompetenceLastDayToDatabase = (pCompetence) => {
  const aux = moment(pCompetence, "YYYYMM").format("YYYY-MM-[01]");
  return moment(aux, "YYYY-MM-[01]").add(1, "months").subtract(1, "days");
};

/**
 *
 * @param {String} pDateString A data
 * @param {String} pFormat O Formato da Data
 * @returns {Boolean} retorna true se a data for valida
 */
export const IsValid = (pDateString, pFormat, pStrictParsing = false) => {
  const date = moment(pDateString, pFormat, pStrictParsing);
  return date.isValid();
};

/**
 *
 * @param {String} pDateString A Data
 * @param {String} pFormat O Formato da data
 * @param {String} pTargetDate A data Alvo
 * @param {String} pTargetFormat O Formato da data alvo
 * @returns {Boolean} retorna true caso a data seja posterior que a data alvo
 */
export const IsAfter = (pDateString, pFormat, pTargetDate, pTargetFormat) => {
  const date = moment(pDateString, pFormat);
  const target = moment(pTargetDate, pTargetFormat);

  return date.isAfter(target);
};

/**
 *
 * @param {String} pDateString A Data
 * @param {String} pFormat O Formato da data
 * @param {String} pTargetDate A data Alvo
 * @param {String} pTargetFormat O Formato da data alvo
 * @returns {Boolean} retorna true caso a data seja anterior que a data alvo
 */
export const IsBefore = (pDateString, pFormat, pTargetDate, pTargetFormat) => {
  const date = moment(pDateString, pFormat);
  const target = moment(pTargetDate, pTargetFormat);

  return date.isBefore(target);
};

/**
 *
 * @param {String} pDateString Data a ser verificada
 * @param {String} pFormat o formato da data
 * @returns {Boolean} retorna true caso a data seja posterior a data de hoje
 */
export const IsAfterToday = (pDateString, pFormat) => {
  const date = moment(pDateString, pFormat);
  const hoje = moment();

  return date.isAfter(hoje);
};

/**
 *
 * @param {String} pDateString Data a ser verificada
 * @param {String} pFormat o formato da data
 * @returns {Boolean} retorna true caso a data seja anterior a data de hoje
 */
export const IsBeforeToday = (pDateString, pFormat) => {
  const date = moment(pDateString, pFormat);
  const hoje = moment();

  return date.isBefore(hoje);
};

/**
 *
 * @param {String} pDateString A Data
 * @param {String} pFormat O Formato da data
 * @param {String} pTargetDate A data Alvo
 * @param {String} pTargetFormat O Formato da data alvo
 * @returns {Boolean} retorna true caso a data alvo seja anterior a data, é verdadeiro caso as duas datas sejam iguais
 */
export const IsSameOrBefore = (
  pDateString,
  pFormat,
  pTargetDate,
  pTargetFormat,
) => {
  const date = moment(pDateString, pFormat);
  const target = moment(pTargetDate, pTargetFormat);

  return date.isSameOrBefore(target);
};

/**
 * @param {String} pDate A data a ser subtraída ou atual caso não informada
 * @param {String} pQuantity A quantidade a ser subtraída
 * @param {String} pMeasurements A medida (months, days, hours,...)
 * @param {String} pFormat O formato de retorno
 * @returns {Boolean} Retorna a subtração da medida escolhida a partir dos dados
 * da data atual ou da data informada
 */
export const Subtract = (pQuantity, pMeasurements, pFormat, pDate = null) => {
  const currentDate = pDate ? moment(pDate) : moment();
  const format = currentDate.subtract(pQuantity, pMeasurements).format(pFormat);

  return format;
};

/**
 * @param {String} pDate A data a ser somada ou atual caso não informada
 * @param {String} pQuantity A quantidade a ser adicionada
 * @param {String} pMeasurements A medida (months, days, hours,...)
 * @param {String} pFormat O formato de retorno
 * @returns {Boolean} Retorna a soma da medida escolhida a partir dos dados
 * da data atual ou da data informada
 */
export const Add = (pDate, pQuantity, pMeasurements, pFormat) => {
  const currentDate = pDate ? moment(pDate) : moment();
  const format = currentDate.add(pQuantity, pMeasurements).format(pFormat);

  return format;
};
