<style src="./SecondaryProcedure.scss" lang="scss" scoped></style>
<template>
  <div class="grid">
    <div class="selectinput">
      <RgSelectUnithealth
        id="uns-id-sp"
        v-model="form.uns_id"
        :rules="{ required: true }"
        permission="faturamento.relatoriosESUSng.Exibir"
        class="inputitem"
      />
    </div>

    <div class="selectinput">
      <RgInputMasked
        id="presentation-sp"
        v-model="form.presentation"
        :mask="['BM/DDDD']"
        :tokens="tokens"
        :rules="{ fn: validatePeriod }"
        label="Apresentação"
        placeholder="mm/aaaa"
        class="inputitem"
      />
    </div>

    <div class="selectinput">
      <RgTypeaheadProcedure
        id="secondary-procedure-sp"
        v-model="form.secondaryProcedure"
        :rules="{
          /*fn: validateRequestedProcedure*/
        }"
        :type="'AIH_ESPECIAL_SECUNDARIO'"
        label="Procedimento Solicitado"
        placeholder="Digite o procedimento"
        class="inputitem"
      />
    </div>

    <div class="selectinput">
      <RgRadioCustom
        id="status-sp"
        v-bind="propsRadioCustomStatus"
        :canUnselect="false"
        class="col-12"
        label="Status da AIH"
        @input="onInputRadioCustomStatus"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgInputMasked,
  RgSelectUnithealth,
  RgRadioCustom,
} from "~tokio/primitive";
import RgTypeaheadProcedure from "$billing/common/component/rg-typeahead-procedure/RgTypeaheadProcedure";
import {
  ValidatePeriodBilling,
  MountPresentation,
} from "../../../common/service/";

export default {
  name: "SecondaryProcedure",
  components: {
    RgInputMasked,
    RgSelectUnithealth,
    RgTypeaheadProcedure,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "AIH por Procedimento Especial e Secundário",
      form: {
        uns_id: 0,
        secondaryProcedure: "",
        presentation: "",
        status: "0",
      },
      tokens: {
        B: { pattern: /^([0-1])$/ },
        M: { pattern: /^([0-9])$/ },
        D: { pattern: /\d/ },
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "SecondaryProcedure";
    },
    propsRadioCustomStatus() {
      const list = [
        { title: " Aberto", id: "0" },
        { title: "Faturado", id: "1" },
        { title: "Exportado", id: "2" },
      ];
      const value = this.form.status;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },
  watch: {
    "form.secondaryProcedure"(pValue, pPrev) {
      if (isEmpty(pValue)) this.form.secondaryProcedure = null;
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "icf_nome" },
      { name: "Prontuário", key: "iaf_prontuario" },
      { name: "Nº Aih", key: "iaf_numero_aih" },
      { name: "Procedimento", key: "inp_novo_nome_procedimento" },
    ];
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.updatePresentationDate();
  },
  methods: {
    onInputRadioCustomStatus(item) {
      if (item) {
        this.form.status = item.id;
      }
    },
    updatePresentationDate() {
      const cmp = this.$store.getters[
        "Billing/PeriodHospitalizationBilling/GET_PERIOD_HOSPITALIZATION_BILLING"
      ];
      if (!cmp) return;
      this.form.presentation = MountPresentation(cmp);
    },
    buildFilter() {
      return {
        uns_id: this.form.uns_id,
        specialSecondaryProcedure: !this.form.secondaryProcedure
          ? null
          : this.form.secondaryProcedure.replace(/\D+/g, "").trim(),
        presentation: this.form.presentation,
        status: this.form.status,
        columnsToPrint: this.columnsToPrint,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_SECONDARY_PROCEDURE_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    validatePeriod(pAih, pErrors) {
      return ValidatePeriodBilling(this.form.presentation, pErrors);
    },
    clearFilter() {
      this.form = {
        uns_id: 0,
        secondaryProcedure: "",
        presentation: "",
        status: "0",
      };
    },
  },
};
</script>
