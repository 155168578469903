<style src="./RgTagCustom.scss" lang="scss" scoped></style>

<template lang="html">
  <div class="rg-radio-custom">
    <section class="tag-custom" :style="{ 'flex-direction': labelDirection }">
      <span class="label">{{ label }}</span>
      <div
        v-for="(el, idx) in options"
        :id="replaceString(el.title)"
        :key="`rc-${idx}`"
        class="tag-container"
        :class="{ clicker: hasClicker }"
        :style="`background-color: ${el.color ? el.color : ''}; border-color: ${
          el.colorBorder ? el.colorBorder : ''
        }; color: ${el.textColor ? el.textColor : ''}`"
        @click="openModal"
      >
        <CentralizerIcons :iconId="el.icon" :color="'#fff'" />
        {{ el.title }}
      </div>
    </section>
  </div>
</template>

<script>
import { replaceSpecial } from "@/common/utils/sanitize.js";
import CentralizerIcons from "~tokio/primitive/icon/font-awesome/icons/CentralizerIcons";

export default {
  name: "RgTagCustom",
  components: {
    CentralizerIcons,
  },

  props: {
    list: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    hasClicker: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
    },
    labelDirection: {
      type: String,
      default: "row",
    },
  },

  data() {
    return {
      options: [],
    };
  },

  computed: {},

  watch: {
    list(pValue) {
      if (pValue && pValue.length > 0) {
        this.loadOptions();
      }
    },
  },

  mounted() {
    this.loadOptions();
  },

  methods: {
    loadOptions() {
      return (this.options = this.list.filter(
        (element) => this.values.indexOf(element.id) !== -1,
      ));
    },

    replaceString(params) {
      return replaceSpecial(params).replaceAll(" ", "-").toLowerCase();
    },

    openModal() {
      if (this.hasClicker) this.$emit("click");
    },
  },
};
</script>
