<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchDestinyConsumption from "./action/SearchDestinyConsumption";

export default {
  name: "RgSelectDestinyConsumption",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Destino",
    },
  },
  data: () => {
    return {
      destiny: [],
    };
  },
  watch: {
    destiny: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.destiny;
    },
    async doSearch() {
      this.destiny = [];

      const data = await SearchDestinyConsumption();
      this.destiny = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
        };
      });
    },
  },
};
</script>
