import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query consolidatedBillings(
    $procedureCode: String
    $cboCode: String
    $period: String
    $fco_id_unidades_saude: Int
    $sheet: String
    $amount: String
    $age: String
  ) {
    consolidatedBillings(
      fco_codigo_procedimento: $procedureCode
      fco_id_unidades_saude: $fco_id_unidades_saude
      fco_cbo: $cboCode
      fco_competencia: $period
      fco_folha: $sheet
      fco_quantidade: $amount
      fco_idade: $age
    ) {
      rows {
        fco_id
        fco_competencia
        fco_folha
        fco_codigo_procedimento
        fco_cbo
        fco_quantidade
        fco_idade
        fco_folha
        fco_idade_requerida
        procedure {
          CO_PROCEDIMENTO
          NO_PROCEDIMENTO
          QT_MAXIMA_EXECUCAO
          VL_IDADE_MAXIMA
          VL_IDADE_MINIMA
        }
        occupation {
          CO_OCUPACAO
          NO_OCUPACAO
        }
      }
    }
  }
`;

export default ({ commit, rootGetters }, variables) => {
  variables = variables || {};

  variables.period = variables.period
    ? variables.period
    : rootGetters["Billing/BDSia/GET_PERIOD_DATE"];
  variables.fco_id_unidades_saude = variables.fco_id_unidades_saude
    ? variables.fco_id_unidades_saude
    : rootGetters["Login/GET_UNIT_HEALTH_ID"];
  return new Promise((resolve, reject) => {
    GraphQLClient.query({ query, variables, fetchPolicy: "no-cache" })
      .then((pRes) => {
        const data = pRes.data?.consolidatedBillings
          ? pRes.data?.consolidatedBillings?.rows
          : [];
        commit("STORE_CONSOLIDATED_LIST", { consolidatedList: data });
        resolve(pRes.data);
      })
      .catch((pErr) => {
        reject(pErr.graphQLErrors);
      });
  });
};
