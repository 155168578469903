<style src="./RgSuggestPatientBulletin.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-patient">
    <RgSuggest
      ref="innerSuggest"
      v-model="inputValue"
      :disabled="disabled"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      min="2"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataItem"
        :idx="idx"
        :item="item"
        class="patient-item"
      >
        <div class="patient">
          <div class="name">
            <IconUser class="icon" />
            <span>
              Nome: <strong> {{ item.bof_nome }} </strong>
            </span>
          </div>
          <div class="birthday">
            <IconCalendar class="icon" />
            <span>
              Data de Nascimento:
              {{ item.bof_data_nascimento || "Não informado" }}
            </span>
          </div>
          <div class="cns">
            <IconCard class="icon" />
            <span> CNS: {{ formatCns(item.bof_cns) || "Não informado" }} </span>
          </div>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { IconUser, IconCalendar, IconCard } from "~tokio/primitive/icon";
import { SearchPatient } from "./action";

export default {
  name: "RgSuggestPatient",
  components: {
    RgSuggest,
    RgSuggestItem,
    IconUser,
    IconCalendar,
    IconCard,
  },
  mixins: [RgSuggestMixin],
  props: {
    dataItem: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    competence: {
      type: String,
      default: "",
    },

    cnes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      captionRaw: "bof_nome",
    };
  },
  mounted() {
    if (this.filterByCnsOrName) {
      this.captionRaw = "bof_cns - bof_nome";
    }
  },
  methods: {
    async doSearch(pSearchTerm, pLimit = null) {
      if (pSearchTerm !== "") {
        const variables = { peopleName: pSearchTerm };

        variables.notUnified = true;

        variables.competence = this.competence;

        variables.crs_numero = this.cns;

        variables.cnes = this.cnes;

        variables.limit = pLimit;

        const result = await SearchPatient({ variables });
        this.hasSuggestPatient(result);
        return result;
      }
    },
    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.bof_nome) && pValue.bof_id > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          const data = [];
          data.push({
            bof_id: pValue.bof_id,
            bof_nome: pValue.bof_nome,

            bof_cns: pValue.bof_cns || "000000000000000",
            ...pValue,
          });
          this.suggestionList = data;
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    hasSuggestPatient(pValue) {
      return pValue && pValue.rows && pValue.rows.length > 0;
    },
    formatDate(pValue) {
      if (!pValue) return "Não informado";
      return this.$utils.date.BrazilianDateFormat(pValue);
    },
    formatCns(pValue) {
      return pValue?.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/gi, "$1 $2 $3 $4");
    },
    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },
    currentCompetence() {
      const competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      const formattedCompetence = this.$utils.date.CompetenceFirstDayToDatabase(
        competence,
      );

      return competence ? formattedCompetence : null;
    },
  },
};
</script>
