<style src="./QuantitativeExamReport.scss" lang="scss" scoped></style>
<template>
  <div class="container">
    <div slot="filters">
      <div class="row filter-fields">
        <RgComboboxUnithealth
          v-model="form.unsId"
          permission="exame2.relatoriosESUSng.Exibir"
        />
      </div>

      <RgRadioCustom
        id="exam-type"
        v-bind="propsRadioCustom"
        class="custom-radio"
        multSelect
        @input="onInputRadioCustom"
      />
      <RgRadioCustom
        id="exam-status"
        v-bind="propsRadioCustomStatus"
        class="custom-radio"
        multSelect
        @input="onInputRadioCustomStatus"
      />

      <div :class="{ disabled: !canSelectSector }" class="row filter-fields">
        <RgSelectSector
          v-model="form.sector"
          :unit-health="form.unsId"
          :permission="sectorPermission"
          :disabled="!canSelectSector"
          label="Setor"
          class="row-field"
        />
      </div>

      <div class="row filter-fields">
        <RgSuggestProcedure v-model="form.procedureCode" label="Procedimento" />
      </div>

      <div class="row filter-fields">
        <RgSuggestEmployee
          ref="requestedEmployee"
          v-model="suggestRequestedEmployee"
          :rules="{ forceSelection: startToTypeSuggestRequestedEmployee }"
          class="row-field"
          label="Profissional Solicitante"
          @selected="selectingRequestedEmployee"
        />
      </div>

      <div class="row filter-fields">
        <RgSuggestEmployee
          ref="employee"
          v-model="suggestEmployee"
          :rules="{ forceSelection: startToTypeSuggestEmployee }"
          :sector="form.sector"
          class="row-field"
          label="Profissional Executante"
          @selected="selectingEmployee"
        />
      </div>

      <!-- Period -->

      <div class="row filter-fields">
        <span class="filter-subtitle period-subtitle"
          >Período de Solicitação</span
        >
        <div class="row filter-fields">
          <div class="col-6">
            <RgInputDate
              v-model="form.requestInitialDate"
              label="Data início"
            />
          </div>

          <div class="col-6">
            <RgInputDate v-model="form.requestFinalDate" label="Data final" />
          </div>
        </div>
      </div>

      <div
        :class="{ disabled: !canSelectEffectivatedPeriod }"
        class="row filter-fields effectivation-period-row"
      >
        <span class="filter-subtitle period-subtitle"
          >Período de Efetivação</span
        >
        <div class="row filter-fields">
          <div class="col-6">
            <RgInputDate
              v-model="form.effectivenessInitialDate"
              :disabled="!canSelectEffectivatedPeriod"
              label="Data início"
            />
          </div>
          <div class="col-6">
            <RgInputDate
              v-model="form.effectivenessFinalDate"
              :disabled="!canSelectEffectivatedPeriod"
              label="Data final"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgInputDate,
  RgSuggestProcedure,
  RgSuggestEmployee,
  RgSelectSector,
  RgRadioCustom,
} from "~tokio/primitive";
import RgComboboxUnithealth from "$billing/common/component/rg-combobox-unithealth/RgComboboxUnithealth";
import EXAM_STATUS from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status/GetExamStatus";
import EXAM_TYPES from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-type/GetExamTypes";

export default {
  name: "QuantitativeExamReport",
  components: {
    RgInputDate,
    RgComboboxUnithealth,
    RgSuggestProcedure,
    RgSuggestEmployee,
    RgSelectSector,
    RgRadioCustom,
  },
  data() {
    return {
      resultTitle: "Relatório Quantitativo de Exame",
      sectorPermission: 247, // permissão para efetivar exames
      canSelectSector: true,
      suggestRequestedEmployee: null,
      suggestEmployee: null,
      sectorLink: null,
      form: {
        unsId: null,
        examType: Object.values(EXAM_TYPES),
        examStatus: [EXAM_STATUS.ABERTO],
        procedureCode: null,
        requestInitialDate: null,
        requestFinalDate: null,
        effectivenessInitialDate: null,
        effectivenessFinalDate: null,
        sector: null,
        exe_id_vinculos: null,
        exe_id_vinculos_solicitante: null,
      },
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Internação", id: 1, color: "#20B1A5" },
        { title: "Emergência", id: 2, color: "#F95354" },
        { title: "Agendado", id: 3, color: "#30AF58" },
      ];
      const value = this.form.examType;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    propsRadioCustomStatus() {
      const list = [
        { title: "Em Aberto", id: 1, color: "#ababab" },
        { title: "Efetivado", id: 2, color: "#56b0c9" },
        { title: "Não Efetivado", id: 3, color: "#ffa56a" },
      ];
      const value = this.form.examStatus;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    canSelectEffectivatedPeriod() {
      return !this.form.examStatus
        ? false
        : this.form.examStatus.includes(EXAM_STATUS.EFETIVADO);
    },
    startToTypeSuggestEmployee() {
      return !isEmpty(this.suggestEmployee);
    },
    startToTypeSuggestRequestedEmployee() {
      return !isEmpty(this.suggestRequestedEmployee);
    },
    printReportCss() {
      const css =
        ".report-column-1-print { width: 200px; }" +
        ".report-column-2-print { width: 100px; }" +
        ".report-column-3-print { width: 200px; }" +
        ".report-column-4-print { width: 130px; }" +
        ".report-column-5-print { width: 130px; }" +
        ".report-column-6-print { width: 130px; }" +
        "tbody tr:nth-of-type(odd) { background-color: rgba(0,0,0,.05); }";
      return css;
    },

    getNameSmartTable() {
      return "QuantitativeExamReport";
    },
  },
  mounted() {
    this.form.unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.examType = item;
      }
    },
    onInputRadioCustomStatus(item) {
      if (item) {
        this.form.examStatus = item;
      }
    },
    buildFilter() {
      return { ...this.form };
    },
    selectingEmployee(pEmployee) {
      this.form.exe_id_vinculos = pEmployee.source.vin_id || null;
      this.sectorLink = pEmployee.source.vus_id || null;
    },
    selectingRequestedEmployee(pEmployee) {
      this.form.exe_id_vinculos_solicitante = pEmployee.source.vin_id || null;
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Exam/IndividualEffectivation/GET_QUANTITATIVE_EXAM_REPORT",
          pData,
        );
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
