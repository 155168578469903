import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path:
    "/appointment/schedule/per-periods/edit/form/edit-patient/patient-care-history",
  name:
    "appointment.schedule.per-periods.edit.form.edit-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Agendamento", link: OpenSubModule("appointment") },
      {
        label: "Agendamentos por Período",
        link: "/appointment/schedule/per-periods",
      },
      {
        label: "Editar Consulta",
        link: "/appointment/schedule/per-periods/edit/form",
      },
      {
        label: "Cadastro do Paciente",
        link: "/appointment/schedule/per-periods/edit/form/edit-patient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
