<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchClassification from "./action/SearchClassification";
import SearchAllClassification from "./action/SearchAllClassification";

import ValidateClassificationRequired from "./action/ValidateClassificationRequired";

export default {
  name: "RgSelectClassificationByService",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Classificação",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    serviceCode: {
      type: String,
      default: "",
    },
    procedureCode: {
      type: String,
      default: "",
    },
    allClassifications: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    unitHealthId(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.fillData();
      }
    },
    serviceCode(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.fillData();
      }
    },
    procedureCode(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.fillData();
      }
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.$loader.start();
    this.fillData();
    this.$loader.finish();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async fillData() {
      this.valuesData = [];
      this.$loader.start();

      let data;

      if (this.allClassifications) {
        data = await SearchAllClassification([
          this.unitHealthId || this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
          this.serviceCode,
        ]);
        this.valuesData = data.map((item) => {
          return { value: item.id, label: item.nome.toUpperCase() };
        });
      } else if (this.unitHealthId && this.serviceCode && this.procedureCode) {
        data = await SearchClassification({
          intUnidade: this.unitHealthId,
          co_servico: this.serviceCode,
          co_procedimento: this.procedureCode,
        });
        this.valuesData = data.map((item) => {
          return { value: item.id, label: item.nome.toUpperCase() };
        });
      }

      let validateClassification = null;

      if (data && data.length > 0) {
        if (this.procedureCode && this.procedureCode.length > 0) {
          validateClassification = await ValidateClassificationRequired({
            strCodigoProcedimento: this.procedureCode,
          });
        }
      }

      if (validateClassification) {
        this.$emit("required", true);
      } else {
        this.$emit("required", false);
      }

      if (this.valuesData && this.valuesData.length > 0) {
        this.$emit("hasValue", true);
        this.$emit("required", true);
      } else {
        this.$emit("hasValue", false);
        this.$emit("required", false);
      }
      this.$loader.finish();
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
