<style src="./RgSubmitButton.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-submit">
    <div class="rg-submit--container">
      <RgButton
        :id="id"
        :data-id="dataId"
        :disabled="disabled"
        class="button rg-submit--button -medium"
        @click="doAction"
      >
        <span v-if="!showWaiting && !showDone" class="text">{{ label }}</span>

        <div v-if="showWaiting" class="rg-submit--button-like-container">
          <div class="spinner" />
        </div>

        <div v-if="showDone" class="rg-submit--button-like-container">
          <IconCheck />
        </div>
      </RgButton>
    </div>
  </fieldset>
</template>

<script>
import { IconCheck } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";

let timoutDone = null;
const timeoutAction = null;

export default {
  name: "RgSubmitButton",
  components: {
    IconCheck,
    RgButton,
  },
  props: {
    label: {
      type: String,
      default: "Enviar",
    },
    dataId: {
      type: String,
      default: "",
    },
    waitLabel: {
      type: String,
      default: "Salvando",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      requestDone: false,
      timeDone: false,
      showNormalCaption: false,
      showWaiting: false,
      showDone: false,
    };
  },
  methods: {
    doAction() {
      this.actionSubmit();
      this.$emit("submit", true);
    },
    actionSubmit() {
      this.showNormalCaption = false;
      this.showWaiting = true;
      this.showDone = false;

      return new Promise((resolve, reject) => {
        timoutDone = setTimeout(() => {
          this.timeDone = true;
          this.finish();
          resolve();
        }, 500);
      });
    },
    actionDone() {
      this.showNormalCaption = false;
      this.showWaiting = false;
      this.showDone = true;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.actionNormalState();
          resolve();
        }, 500);
      });
    },
    actionNormalState() {
      this.showNormalCaption = true;
      this.showWaiting = false;
      this.showDone = false;
      clearTimeout(timoutDone);
      clearTimeout(timeoutAction);
    },
    done() {
      this.requestDone = true;
    },
    fail() {
      this.actionNormalState();
    },
    finish() {
      if (this.requestDone && this.timeDone) {
        this.requestDone = false;
        this.timeDone = false;
        this.$emit("complete");
        this.actionDone();
      }
    },
  },
};
</script>
