<template>
  <div class="modal-individual-patient-registration-form">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div
        slot="header"
        class="modal-individual-patient-registration-form-header"
      >
        <div class="title">Criar Ficha de Cadastro Individual</div>
      </div>

      <div slot="body" class="modal-individual-patient-registration-form-body">
        <span class="subtitle">
          O paciente selecionado não possui ficha de Cadastro Individual na
          Atenção Básica. O agendamento exige a inclusão das informações abaixo.
        </span>

        <div class="contents">
          <RgValidatorForm ref="validator">
            <FormBase title="Cabeçalho" class="form-base">
              <div class="header-area">
                <RgInput
                  v-model="form.unitHealth"
                  class="unit-health"
                  label="Unidade de Saúde"
                  disabled
                  :rules="{ required: true }"
                  :class="{ disable: true }"
                />
                <RgSelectBasicAttentionAreaTeam
                  id="select-attention-area-team"
                  ref="area"
                  v-model="form.area"
                  :disabled="hasLastIndividualRegistration"
                  :class="{ disable: hasLastIndividualRegistration }"
                  :unit-health="this.form.unitHealthId"
                  :rules="{ required: true }"
                  openOnlyBottom
                  @change="selectingArea"
                />
                <RgSelectBasicAttentionEmployee
                  id="select-attention-employee"
                  ref="employee"
                  v-model="form.employee"
                  :disabled="hasLastIndividualRegistration"
                  :class="{ disable: hasLastIndividualRegistration }"
                  :team="this.form.teamId"
                  :unitHealth="this.form.unitHealthId"
                  :rules="{ required: true }"
                  openOnlyBottom
                  @change="selectingEmployee"
                />
                <RgSelectBasicAttentionMicroArea
                  id="select-attention-microarea"
                  ref="microArea"
                  v-model="form.microArea"
                  :area="this.form.area"
                  :rules="{ required: requiredMicroarea }"
                  :disabled="
                    !requiredMicroarea || hasLastIndividualRegistration
                  "
                  :class="{
                    disable:
                      !requiredMicroarea || hasLastIndividualRegistration,
                  }"
                  :placeholder="!requiredMicroarea ? '' : 'Selecione'"
                  :defaultText="!requiredMicroarea ? '' : 'Selecione'"
                  openOnlyBottom
                />
                <div class="out-of-area">
                  <input
                    v-model="form.outOfArea"
                    :disabled="this.disableMicroArea"
                    :class="{ disable: this.disableMicroArea }"
                    type="checkbox"
                  />
                  <span> Está fora de área </span>
                </div>
              </div>
            </FormBase>

            <FormBase title="Paciente" class="form-base">
              <div class="patient-area">
                <div class="row-1">
                  <RgInput
                    ref="patientName"
                    v-model="form.patientName"
                    label="Nome do Paciente"
                    :needRemoveSpecialStrings="false"
                    :rules="{
                      required: true,
                      validateIndividualPatientRegistration: true,
                      validateEspecialCharacter: 'name',
                      cannotContainNumbers: true,
                      max: 70,
                    }"
                    :maxlength="70"
                  />

                  <RgRadioCustom
                    id="gender"
                    ref="gender"
                    v-bind="propsRadioCustom"
                    class="gender"
                    :canUnselect="false"
                    label="Sexo"
                    :rules="{ required: true }"
                    @input="onInputRadioCustom"
                  />

                  <RgInput
                    ref="motherName"
                    v-model="form.motherName"
                    label="Nome da Mãe"
                    :needRemoveSpecialStrings="false"
                    :rules="{
                      required: true,
                      validateIndividualPatientRegistration: true,
                      validateEspecialCharacter: 'name',
                      cannotContainNumbers: true,
                      max: 70,
                    }"
                    :maxlength="70"
                  />
                </div>

                <div class="row-2">
                  <RgInput
                    ref="fatherName"
                    v-model="form.fatherName"
                    label="Nome do Pai"
                    :needRemoveSpecialStrings="false"
                    :rules="{
                      required: requiredFatherName,
                      validateIndividualPatientRegistration: true,
                      validateEspecialCharacter: 'name',
                      cannotContainNumbers: true,
                      max: 70,
                    }"
                    :disabled="!requiredFatherName"
                    :class="{ disable: !requiredFatherName }"
                    :maxlength="70"
                  />
                  <label class="checkbox">
                    <input v-model="form.unknowFatherName" type="checkbox" />
                    <span> Desconhecido </span>
                  </label>

                  <RgComboboxEthnicity
                    ref="race"
                    v-model="form.race"
                    label="Raça/Cor"
                    class="race"
                    :rules="{ required: true }"
                  />

                  <RgComboboxEthnicityAb
                    ref="ethnicityAb"
                    v-model="form.ethnicityAb"
                    :disabled="!isIndigenousRace"
                    :class="{ disable: !isIndigenousRace }"
                    label="Etnia"
                    class="ethnicity-ab"
                    :rules="{ required: isIndigenousRace }"
                  />
                </div>

                <div :class="[brazilianPatient ? 'row-5' : 'row-3']">
                  <RgInputDate
                    ref="birthdayDate"
                    v-model="form.birthdayDate"
                    label="Data de Nascimento"
                    :rules="{ required: true, fn: validateBirthDate }"
                    :max-date="new Date()"
                  />

                  <RgSuggestNationality
                    ref="nationality"
                    v-model="form.nationality"
                    label="Nacionalidade"
                    placeholder="Digite o nome do país"
                    class="nationality"
                    :rules="{ required: true, forceSelection: true }"
                    :hasCodeBasicAttention="true"
                    @selected="selectingNationality"
                  />
                  <RgSuggestCity
                    v-show="brazilianPatient"
                    id="select-attention-city"
                    ref="city"
                    v-model="form.city"
                    label="Naturalidade"
                    placeholder="Digite o nome do município"
                    class="city"
                    :rules="{
                      required: brazilianPatient,
                      forceSelection: true,
                    }"
                    :active="true"
                    @selected="selectingCity"
                  />
                  <label v-if="!brazilianPatient" class="checkbox">
                    <input v-model="form.naturalized" type="checkbox" />
                    <span> Naturalizado </span>
                  </label>
                  <RgInputDate
                    v-if="foreignPatient"
                    ref="arrivalDate"
                    v-model="form.arrivalDate"
                    label="Data de Chegada ao Brasil"
                    :rules="{
                      required: foreignPatient,
                      fn: validateArrivalDate,
                    }"
                    :max-date="new Date()"
                  />
                  <RgInputDate
                    v-if="naturalizedPatient"
                    id="select-attention-city"
                    ref="dateNaturalization"
                    v-model="form.dateNaturalization"
                    label="Data de Naturalização"
                    :rules="{
                      required: naturalizedPatient,
                      fn: validateDateNaturalization,
                    }"
                    :max-date="new Date()"
                  />
                  <RgInput
                    v-if="naturalizedPatient"
                    ref="ordinance"
                    v-model="form.ordinance"
                    label="Portaria"
                    :rules="{ required: naturalizedPatient }"
                    :maxlength="16"
                  />
                </div>

                <div class="row-4">
                  <RgInputCpf
                    ref="cpf"
                    v-model="form.cpf"
                    :rules="{ required: requiredCpf }"
                    :class="{ disable: true }"
                    disabled
                  />
                  <RgInputCns
                    ref="cns"
                    v-model="form.cns"
                    :required-value="requiredCns"
                    :class="{ disable: true }"
                    disabled
                    label="CNS"
                  />
                  <RgSelectDddInputPhone
                    id="select-attention-phone"
                    ref="phone"
                    v-model="form.telephone"
                    :rules="{ required: true, fn: validatePhone }"
                    @getDdd="getDdd"
                  />
                </div>
              </div>
            </FormBase>
          </RgValidatorForm>
        </div>
      </div>

      <div
        slot="footer"
        class="modal-individual-patient-registration-form-footer"
      >
        <RgCancelButton id="select-attention-cancel" medium @click="cancel" />
        <MediumButton
          id="select-attention-save"
          label="Salvar e Agendar"
          @click="saveForm"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgValidatorForm,
  MediumButton,
  RgInput,
  RgInputDate,
  RgCancelButton,
  RgSelectBasicAttentionAreaTeam,
  RgSelectBasicAttentionEmployee,
  RgSelectBasicAttentionMicroArea,
  RgComboboxEthnicityAb,
  RgRadioCustom,
} from "~tokio/primitive";

import FormBase from "~tokio/foundation/form-base/FormBase";

import RgComboboxEthnicity from "$person/common/components/combobox/rg-combobox-ethnicity/RgComboboxEthnicity";
import RgSuggestNationality from "$person/common/components/suggest/rg-suggest-nationality/RgSuggestNationality";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgInputCns from "$person/common/components/input/rg-input-cns/RgInputCns";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import RgSelectDddInputPhone from "$person/common/components/input/rg-select-ddd-input-phone/RgSelectDddInputPhone";
import {
  BrazilianDateToDatabase,
  BrazilianDateFormat,
  DateNowToDatabase,
} from "~utils/date";
import moment from "moment";

const FORM = {
  unitHealthId: null,
  unitHealth: null,
  area: null,
  teamId: null,
  equ_codigo_ine: null,
  unitHealthCnes: null,
  employee: null,
  employeeBasicAttentionId: null,
  cnsEmployee: null,
  occupation: null,
  microArea: null,
  outOfArea: null,
  patientId: null,
  patientName: null,
  gender: null,
  motherName: null,
  fatherName: null,
  unknowFatherName: null,
  race: null,
  ethnicityAb: null,
  birthdayDate: null,
  nationality: null,
  nationalityId: null,
  city: null,
  cityId: null,
  naturalized: false,
  ordinance: null,
  arrivalDate: null,
  dateNaturalization: null,
  cpf: null,
  cpfId: null,
  cns: null,
  cnsId: null,
  ddd: null,
  telephone: null,
};

export default {
  name: "ModalIndividualPatientRegistrationForm",
  components: {
    RgBaseModal,
    RgValidatorForm,
    FormBase,
    MediumButton,
    RgInput,
    RgComboboxEthnicity,
    RgInputDate,
    RgSuggestNationality,
    RgSuggestCity,
    RgInputCns,
    RgInputCpf,
    RgSelectDddInputPhone,
    RgCancelButton,
    RgSelectBasicAttentionAreaTeam,
    RgSelectBasicAttentionEmployee,
    RgSelectBasicAttentionMicroArea,
    RgComboboxEthnicityAb,
    RgRadioCustom,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    personId: {
      type: Number,
    },
    scheduleData: {
      type: Object,
      default: () => {},
    },
    patientBasicAttentionId: {
      type: Number,
    },
    lastIndividualRegistration: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      patientData: {},
      hasCpf: false,
      hasCns: false,
      disableArea: false,
      disableEmployee: false,
      disableMicroArea: false,
    };
  },

  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Masculino", id: "M" },
        { title: "Feminino", id: "F" },
      ];

      const value = this.form.gender;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },

    requiredMicroarea() {
      return !this.form.outOfArea;
    },
    requiredCpf() {
      return !this.form.cns;
    },
    requiredCns() {
      return !this.form.cpf;
    },
    requiredFatherName() {
      return !this.form.unknowFatherName;
    },
    brazilianPatient() {
      return this.form.nationalityId === this.FROM_BRAZIL;
    },
    foreignPatient() {
      return (
        !this.form.naturalized &&
        this.form.nationality &&
        this.form.nationalityId !== this.FROM_BRAZIL
      );
    },
    naturalizedPatient() {
      return this.form.naturalized;
    },
    isIndigenousRace() {
      const INDIGENOUS_ID = 5;
      return this.form.race === INDIGENOUS_ID;
    },
    hasLastIndividualRegistration() {
      return !!this.lastIndividualRegistration;
    },
  },

  watch: {
    show(pValue) {
      if (pValue) {
        this.loadPatientData();
      } else {
        this.clearForm();
      }
    },

    "form.outOfArea"(pValue) {
      if (pValue) {
        this.$refs.microArea.cleanValidate();
        this.form.microArea = null;
      }
    },

    "form.unknowFatherName"(pValue) {
      if (pValue) {
        this.$refs.fatherName.cleanValidate();
        this.form.fatherName = null;
      }
    },

    "form.naturalized"(pValue) {
      if (pValue) {
        this.form.arrivalDate = null;
        this.form.city = null;
        this.form.cityId = null;
      } else {
        this.form.dateNaturalization = null;
        this.form.ordinance = null;
      }
    },

    "form.race"() {
      if (!this.isIndigenousRace) {
        this.form.ethnicityAb = null;
      }
    },
  },

  created() {
    this.FROM_BRAZIL = 1;
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.gender = item.id;
      }
    },
    async loadPatientData() {
      try {
        this.$loader.start();

        this.patientData = await this.$store.dispatch(
          "Appointment/Schedule/GET_PATIENT_BY_ID",
          {
            pesId: this.personId,
          },
        );

        if (this.patientData && Object.keys(this.patientData).length > 0) {
          await this.mountedPatientInfo(this.patientData, this.scheduleData);
        }

        this.mountBasicAttentionInfo();
      } catch (pErr) {
        this.$toaster.error("Erro ao carregar dados do paciente", pErr);
      } finally {
        this.$loader.finish();
      }
    },

    mountBasicAttentionInfo() {
      if (!this.lastIndividualRegistration) return;
      this.form.area = Number(this.lastIndividualRegistration.area_id);
      this.form.employee = Number(
        this.lastIndividualRegistration.funcionario_id,
      );
      this.form.outOfArea = !this.lastIndividualRegistration.microarea_id;
      this.form.microArea = Number(
        this.lastIndividualRegistration.microarea_id,
      );

      this.disableArea = true;
      this.disableEmployee = true;
      this.disableMicroArea = true;
    },

    mountedPatientInfo(pPatient, pSchedule) {
      const form = { ...this.form };

      this.form = Object.assign(form, {
        unitHealthId: this.lastIndividualRegistration
          ? Number(this.lastIndividualRegistration.unidade_id)
          : pSchedule.uns_id,
        unitHealth: this.lastIndividualRegistration
          ? this.lastIndividualRegistration.unidade_nome
          : pSchedule.uns_nome,
        patientId: pPatient.patient.pac_id,
        patientName: pPatient.pes_nome,
        gender: pPatient.gender.sex_id === 3 ? null : pPatient.gender.sex_sigla,
        motherName: pPatient.pes_mae,
        fatherName: pPatient.pes_pai,
        race: pPatient.ethnicity.etn_id,
        birthdayDate: BrazilianDateFormat(pPatient.pes_nascimento),
        ordinance: pPatient.foreign.est_portaria,
        arrivalDate: BrazilianDateFormat(pPatient.foreign.est_chegada_brasil),
        naturalized: pPatient.foreign?.est_naturalizacao?.length > 0,
        dateNaturalization: BrazilianDateFormat(
          pPatient.foreign.est_naturalizacao,
        ),
        cpfId: pPatient.cpf.cpf_id,
        cpf: pPatient.cpf.cpf_numero,
        cnsId: pPatient.cns.crs_id,
        cns: pPatient.cns.crs_numero,
      });

      if (this.$refs.nationality && pPatient.nationality.nac_ativo) {
        this.$refs.nationality.forceSelection({
          nac_id: pPatient.nationality ? pPatient.nationality.nac_id : null,
          nac_nome: pPatient.nationality ? pPatient.nationality.nac_nome : null,
        });
      }

      if (this.$refs.city && pPatient.city.mun_ativo) {
        this.$refs.city.forceSelection({
          mun_id: pPatient.city ? pPatient.city.mun_id : null,
          mun_nome: pPatient.city ? pPatient.city.mun_nome : null,
        });
      }

      const hasCellPhone = this.getLastCellPhone(pPatient.telephones);

      if (hasCellPhone) {
        this.$refs.phone.fillValue(hasCellPhone.tel_numero, hasCellPhone.ddd);
      } else {
        if (this.$refs.phone) {
          this.$refs.phone.cleanPhone();
        }
      }

      pPatient?.cpf?.cpf_numero && this.form.cpf && this.form.cpf.length > 0
        ? (this.hasCpf = true)
        : (this.hasCpf = false);

      pPatient?.cns?.crs_numero && this.form.cns && this.form.cns.length > 0
        ? (this.hasCns = true)
        : (this.hasCns = false);
    },

    async saveForm() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      let isPersonExistsException;

      try {
        this.$loader.start();

        const variables = this.formatData();

        const response = await this.$store.dispatch(
          "Appointment/Schedule/CREATE_INDIVIDUAL_PATIENT_REGISTRATION",
          {
            basicAttentionIntegrations: variables,
          },
        );

        const hasSuccess = response && response.success;

        const personExistsMessage = "A pessoa informada já existe no sistema";
        isPersonExistsException = response.message === personExistsMessage;

        if (!hasSuccess) {
          if (isPersonExistsException) {
            this.$toaster.info(
              "Verfique se os dados inseridos no agendamento são compatíveis com o cadastro do paciente no sistema.",
              "O paciente possui dados duplicados no sistema.",
            );
          } else {
            this.$toaster.error(response.message);
          }
        }
      } catch (pErr) {
        this.$toaster.error(
          "Erro ao criar a ficha de cadastro individual: " + pErr.message ||
            pErr,
        );
      } finally {
        if (!isPersonExistsException) {
          this.$emit("save");
        } else {
          this.$loader.finish();
        }
      }
    },

    formatData() {
      const brazilianType = this.brazilianPatient ? 1 : null;
      const naturalizedType = this.naturalizedPatient ? 2 : null;
      const foreignType = this.foreignPatient ? 3 : null;
      const cityId = brazilianType
        ? this.form.cityId
        : naturalizedType
        ? this.$utils.app.CustomCities.NATURALIZADO
        : this.$utils.app.CustomCities.ESTRANGEIRO;

      const patientName = this.removeAllApostrophes(this.form.patientName);
      const motherName = this.removeAllApostrophes(this.form.motherName);
      const fatherName = this.removeAllApostrophes(this.form.fatherName);

      const variables = {
        pae_id: this.patientBasicAttentionId,
        ina_cartao_sus: this.removeDashesAndDots(this.form.cnsEmployee),
        ina_cnes: this.form.unitHealthCnes,
        ina_codigo_cbo: this.form.occupation,
        ina_codigo_ine: this.form.equ_codigo_ine,
        ina_id_ab_equipes: this.form.teamId,
        ina_id_ab_funcionarios_atencao_basica: this.form
          .employeeBasicAttentionId,
        ina_data_atendimento: DateNowToDatabase(),
        ina_id_ab_microareas: !this.form.outOfArea ? this.form.microArea : null,
        ina_documento: this.form.cns
          ? this.removeDashesAndDots(this.form.cns)
          : this.removeDashesAndDots(this.form.cpf),
        ina_id_pessoas: this.personId,
        ina_nome: patientName,
        ina_nascimento: BrazilianDateToDatabase(this.form.birthdayDate),
        ina_mae: motherName,
        ina_pai: fatherName,
        ina_mae_desconhecida: false,
        ina_pai_desconhecido: this.form.unknowFatherName
          ? this.form.unknowFatherName
          : false,
        ina_id_sexos: this.form.gender === "M" ? 1 : 2,
        ina_id_raca: this.form.race,
        ina_id_etnias: this.form.ethnicityAb,
        ina_celular:
          this.form.ddd.ddd_codigo + this.form.telephone.replace("-", ""),
        ina_id_ab_tipos_nacionalidades:
          brazilianType || naturalizedType || foreignType,
        ina_id_nacionalidades: this.form.nationalityId,
        ina_id_municipios: cityId,
        ina_data_naturalizacao: this.naturalizedPatient
          ? BrazilianDateToDatabase(this.form.dateNaturalization)
          : null,
        ina_portaria_naturalizacao: this.form.ordinance,
        ina_data_entrada_brasil: this.foreignPatient
          ? BrazilianDateToDatabase(this.form.arrivalDate)
          : null,
        cpf: {
          cpf_id: this.form.cpfId,
          cpf_numero: this.removeDashesAndDots(this.form.cpf),
        },
        cns: {
          crs_id: this.form.cnsId,
          crs_numero: this.removeDashesAndDots(this.form.cns),
        },
        foreigner: {
          est_id: this.patientData.foreign.est_id,
          est_portaria: this.naturalizedPatient ? this.form.ordinance : null,
          est_chegada_brasil: this.foreignPatient
            ? BrazilianDateToDatabase(this.form.arrivalDate)
            : null,
          est_naturalizacao: this.naturalizedPatient
            ? BrazilianDateToDatabase(this.form.dateNaturalization)
            : null,
          est_passaporte: this.foreignPatient
            ? this.patientData.foreign.est_passaporte
            : null,
        },
      };

      return variables;
    },

    selectingArea(pValue) {
      this.form.teamId = pValue ? pValue.teamId : null;
      this.form.equ_codigo_ine = pValue ? pValue.equ_codigo_ine : null;
      this.form.unitHealthCnes = pValue ? pValue.unitHealthCnes : null;
    },

    selectingEmployee(pValue) {
      if (pValue) {
        this.form.occupation = pValue.occupation;
        this.form.cnsEmployee = pValue.cns;
        this.form.employeeBasicAttentionId = pValue.fab_id;
      }
    },

    selectingNationality(pData) {
      const source = pData.source;
      const hasValue = source && source.nac_id && source.nac_id > 0;
      if (hasValue) {
        this.form.nationalityId = source.nac_id;
      } else {
        this.form.nationalityId = null;
        this.form.city = null;
        this.form.cityId = null;
        this.form.naturalized = false;
        this.form.dateNaturalization = null;
        this.form.ordinance = null;
        this.form.arrivalDate = null;
        if (this.$refs.city) {
          this.$refs.city.clearData();
        }
      }
    },

    selectingCity(pValue) {
      const source = pValue.source;
      if (source) {
        this.form.cityId = source && source.mun_id;
      } else {
        this.form.cityId = null;
      }
    },

    getDdd(pValue) {
      this.form.ddd = pValue;
    },

    getLastCellPhone(telephones) {
      if (telephones?.rows && telephones?.rows?.length > 0) {
        const celPhones = telephones.rows.filter(
          (phone) =>
            phone.tel_numero.startsWith("9") && phone.tel_numero.length === 9,
        );

        if (celPhones.length > 0) {
          celPhones.sort((a, b) => (a.tel_id < b.tel_id ? 1 : -1));
          return celPhones[0];
        }
      }

      return null;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    validatePhone(pValue, pErrors) {
      if (!pValue) {
        if (this.$refs.phone) {
          this.$refs.phone.cleanPhone();
        }
        return true;
      }
      if (pValue && pValue.length < 9) {
        pErrors.push("Digite todos os números do telefone");
        return false;
      } else {
        if (!this.form.ddd) {
          pErrors.push("Selecione um DDD");
          return false;
        }
      }
      return true;
    },

    validateEthnicityAb(pValue, pErrors) {
      if (this.isIndigenousRace) {
        pErrors.push("Etnia obrigatória para a raça indígena");
        return false;
      }

      return true;
    },

    validateBirthDate(pValue, pErrors) {
      const birthdate = moment(pValue, "DD/MM/YYYY");
      const currentDate = moment().startOf("day");

      if (moment.duration(currentDate.diff(birthdate)).asYears() > 130) {
        pErrors.push(
          "Data de Nascimento não pode ter 130 anos da data de atendimento",
        );
        return false;
      }
    },

    validateDateNaturalization(pValue, pErrors) {
      const dateNaturalization = moment(pValue, "DD/MM/YYYY");
      const birthDaydate = moment(this.form.birthdayDate, "DD/MM/YYYY");

      if (dateNaturalization.isBefore(birthDaydate)) {
        pErrors.push(
          "Data de naturalização não pode ser menor que a data de nascimento",
        );
        return false;
      }
    },

    validateArrivalDate(pValue, pErrors) {
      const arrivalDate = moment(pValue, "DD/MM/YYYY");
      const birthDaydate = moment(this.form.birthdayDate, "DD/MM/YYYY");

      if (arrivalDate.isBefore(birthDaydate)) {
        pErrors.push(
          "Data de chegada ao Brasil não pode ser menor que a data de nascimento",
        );
        return false;
      }
    },

    clearMicroArea() {
      this.form.outOfArea = null;
    },

    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },

    removeAllApostrophes(pValue) {
      return pValue ? pValue.replaceAll("'", "") : null;
    },

    cleanFieldsValidation() {
      const fieldsRefs = [
        "area",
        "employee",
        "microArea",
        "patientName",
        "gender",
        "motherName",
        "fatherName",
        "race",
        "birthdayDate",
        "nationality",
        "city",
        "arrivalDate",
        "dateNaturalization",
        "ordinance",
        "cpf",
        "cns",
        "phone",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cancel() {
      this.clearForm();
      this.close();
    },

    close() {
      this.clearForm();
      this.$emit("close");
    },

    clearForm() {
      this.patientData = {};
      this.form = this.$utils.obj.DeepCopy(FORM);
      if (this.$refs.phone) {
        this.$refs.phone.cleanPhone();
      }
      this.hasCpf = false;
      this.hasCns = false;
      this.disableArea = false;
      this.disableEmployee = false;
      this.disableMicroArea = false;
      this.cleanFieldsValidation();
    },
  },
};
</script>
