<template lang="html">
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.74998 0.687507V3.43758C2.74998 3.81754 3.05744 4.12504 3.43758 4.125C3.81754 4.125 4.125 3.81754 4.125 3.43758V0.687507C4.125 0.307458 3.81754 0 3.43758 0C3.05744 0 2.74998 0.307458 2.74998 0.687507ZM15.1252 3.43763C15.1252 3.81758 14.8175 4.12504 14.4376 4.12504C14.0576 4.12504 13.7499 3.81754 13.7499 3.43758V0.687507C13.7499 0.307458 14.0576 0 14.4376 0C14.8175 0 15.1252 0.307458 15.1252 0.687507V3.43763ZM16.5 22.0001C19.5374 22.0001 22 19.5376 22 16.4999C22 13.4626 19.5374 11 16.5 11C13.4627 11 11 13.4627 11 16.4999C11 19.5376 13.4628 22.0001 16.5 22.0001ZM2.74998 8.25004H5.50006V11H2.74998V8.25004ZM5.50006 15.1251H2.74998V12.3749H5.50006V15.1251ZM9.6251 11H6.87498V8.25004H9.6251V11ZM9.6251 15.1251H6.87498V12.3749H9.6251V15.1251ZM1.37506 6.87498V16.3632C1.37506 16.4356 1.42752 16.4999 1.48982 16.4999H9.6251V17.8752H1.48982C0.666786 17.8752 0 17.1957 0 16.3632V4.26206C0 3.42684 0.666786 2.74998 1.48982 2.74998H2.06257V3.43763C2.06257 4.19487 2.67893 4.81259 3.43763 4.81259C4.19614 4.81259 4.81246 4.19487 4.81246 3.43763V2.74998H13.0627V3.43763C13.0627 4.19487 13.6791 4.81259 14.4377 4.81259C15.1961 4.81259 15.8125 4.19487 15.8125 3.43763V2.74998H16.386C17.2089 2.74998 17.8751 3.42684 17.8751 4.26206V9.62505H16.5V6.87498H1.37506ZM13.7499 8.25004V11H11.0001V8.25004H13.7499Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0257 14.0251C13.7978 14.2529 13.7978 14.6223 14.0257 14.8501L15.6756 16.5L14.0257 18.1499C13.7978 18.3777 13.7978 18.7471 14.0257 18.9749C14.2535 19.2027 14.6228 19.2027 14.8506 18.9749L16.5005 17.325L18.1504 18.9749C18.3783 19.2027 18.7476 19.2027 18.9754 18.9749C19.2032 18.7471 19.2032 18.3777 18.9754 18.1499L17.3255 16.5L18.9754 14.8501C19.2032 14.6223 19.2032 14.2529 18.9754 14.0251C18.7476 13.7973 18.3783 13.7973 18.1504 14.0251L16.5005 15.675L14.8506 14.0251C14.6228 13.7973 14.2535 13.7973 14.0257 14.0251Z"
      :fill="disabled ? '#CCCCCC' : color"
    />
  </svg>
</template>

<script>
export default {
  name: "NotEffectiveSchedule",
  props: {
    disabled: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "#F96B70",
    },
  },
};
</script>
