<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
import SearchHospitalizationServiceCharacter from "./action/SearchHospitalizationServiceCharacter";

export default {
  name: "RgComboboxHospitalizationServiceCharacter",
  mixins: [RgComboboxMixin],
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];
      const data = await SearchHospitalizationServiceCharacter({});
      data.forEach((item) => {
        this.mData.push({
          value: `${item.ica_id}`,
          text: `${item.ica_id} - ${item.ica_nome}`,
        });
      });
    },
    blur() {
      this.$emit("blur");
      this.validate();
    },
  },
};
</script>
