const OutPatientControlSheet = (
  pPrinter,
  pOutPatientControlSheet,
  pAddress,
  pClient,
) => {
  const nomeUnidade = pAddress.uns_nome;
  const cidadeUnidade = pAddress.endereco.neighborhood.city.mun_nome;
  const estadoUnidade = pAddress.endereco.neighborhood.city.state.est_sigla;
  const enderecoUnidade = pAddress.endereco.end_logradouro;
  const numeroUnidade = pAddress.endereco.end_numero;

  const nomeCliente = pClient;
  const unidade = nomeUnidade + " / " + cidadeUnidade + " - " + estadoUnidade;
  const endereco = enderecoUnidade + " - N°: " + numeroUnidade;
  const cnes = pAddress.uns_cnes;

  let dadosTabela = "";

  pOutPatientControlSheet.forEach((item) => {
    dadosTabela += "<tr>";
    dadosTabela += `<td>${item.nomeFuncionario || "NÃO INFORMADO"}</td>`;
    dadosTabela += `<td>${item.ocp_nome || "NÃO INFORMADA"}</td>`;
    dadosTabela += `<td>${item.con_hora || "NÃO INFORMADA"}</td>`;
    dadosTabela += `<td>${item.pac_prontuario_unico || "NÃO INFORMADO"}</td>`;
    dadosTabela += `<td>${item.pes_nome || "NÃO INFORMADO"}</td>`;
    dadosTabela += `<td>${item.idade || "NÃO INFORMADA"}</td>`;
    dadosTabela += `<td>${item.sex_sigla || "NÃO INFORMADO"}</td>`;
    dadosTabela += `<td>${item.tcu_novo_tipo_consulta || "NÃO INFORMADO"}</td>`;
    dadosTabela += `<td>${
      item.ppd_codigo_procedimento || "NÃO INFORMADO"
    }</td>`;
    dadosTabela += `<td>${item.sus_pa_dc || "NÃO INFORMADO"}</td>`;
    dadosTabela += `<td>${item.ppd_cid10 || "NÃO INFORMADO"}</td>`;
    dadosTabela += "</tr>";
  });

  const html = `
    <html lang="pt-br">
      <head>
          <meta charset="utf-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <title>Ficha de Controle Ambulatorial</title>

          <style type="text/css">

          @media print{@page {size: landscape}}

          page[size="landscape"] {
            width: 28.7cm;
            height: 21cm;
            margin: 20px;
          }

          @media print {
              body {
                  margin: 0;
                  padding: 0;
                  line-height: 1.4em;
                  font: 12pt Georgia, "Times New Roman", Times, serif;
                  color: #000;
              }

              atendimento {
                  page-break-inside: auto;
              }

              tabela {
                  page-break-inside: avoid;
                  page-break-after: auto;
                  color: #8C8C8C;
              }
          }

          body {
              font-family: Verdana, Geneva, sans-serif;
          }

          .header {
              display: flex;
              flex-direction: row;
              align-items: center;
          }

          header span {
              font-size: 7pt;
          }

          header div:first-child {
              margin: 0 0 5px 0;
          }

          .subtitle {
              display: flex;
              font-size: 10.5pt;
              font-weight: bold;
              color: #000;
              margin: 30px 0 5px 0;
          }

          .separator {
              margin: 4px 0;
              border: 1px solid #CFCFCF;
          }

          .logoCliente {
              float: left;
              max-width: 100px;
              max-height: 100px;
              width: 100px;
              height: 100px;
          }

          .info {
              display: flex;
              flex-direction: column;
              font-size: 8.3pt;
              color: #000000;
              line-height: 13px;
              font-weight: 400;
              justify-content: center;
              margin-left: 16px;
          }

          .info-atendimento {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              gap: 16px;
              font-size: 9pt;
              margin: 25px 0 25px 0;
          }

          .info-filtro span:last-child {
              font-weight: bold;
              word-break: break-all;
          }

          .atendimento {
              margin: 16px 0 32px 0;
          }

         .tabela {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #C4C4C4;
          }

          tbody {
            border: 1px solid #C4C4C4;
          }

          th {
            padding: 5px 3px;
            background-color: #C4C4C4;
            font-size: 8.5pt;
            text-align: left;
            font-weight: 700;
          }

          td {
            border-bottom: 1px solid #C4C4C4;
            font-size: 8.5pt;
            padding: 5px 3px;
            text-align: left;
            color: #8C8C8C;
          }

          .img-header {
              display: flex;
              width: 2.5cm;
              border: 0;
          }

          .esus {
              height: 35px;
              width: 35px;
              margin-top: 6px;
              align-items: center;
          }

          .sus {
              height: 50px;
              width: 140px;
              margin-left: auto;
          }
          </style>

      </head>
      <body>

          <header>
            <div class="header">
              <div class="img-header esus">
                <img src="/static/images/mini-logo-esus.png" class="esus"/>
              </div>

              <div class="info">
                  <span>${nomeCliente}</span>
                  <span>${unidade}</span>
                  <span>${endereco}</span>
                  <span>CNES da Unidade: ${cnes}</span>
              </div>

              <div class="img-header sus">
                <img src="/static/images/novo-sus-logo.jpg" class="sus"/>
              </div>
            </div>
          </header>

          <hr>

          <span style="font-weight: bold; font-size: 14px;">Ficha de Controle Ambulatorial</span>

          <hr>

          <div class="info-atendimento">
            <div class="info-filtro">
              <span>Unidade de Saúde:</span>
              <span>${pPrinter.unitHealthName}</span>
            </div>

            <div class="info-filtro">
              <span>Setor:</span>
              <span>${pPrinter.sectorName}</span>
            </div>

            <div class="info-filtro">
              <span>Data:</span>
              <span>${pPrinter.serviceDate}</span>
            </div>

            <div class="info-filtro">
              <span>Ocupação do Agendamento:</span>
              <span>${pPrinter.occupationName}</span>
            </div>

            <div class="info-filtro">
              <span>Profissional do Agendamento:</span>
              <span>${pPrinter.employeeName}</span>
            </div>
          </div>

          <table class="tabela">
            <thead>
              <tr class="titulo-tabela">
                  <th>Profissional</th>
                  <th>Ocupação</th>
                  <th>Horário</th>
                  <th>Prontuário</th>
                  <th>Paciente</th>
                  <th>Idade</th>
                  <th>Sexo</th>
                  <th>Tipo</th>
                  <th>Código</th>
                  <th>Procedimento</th>
                  <th>CID-10</th>
              </tr>
            </thead>
            <tbody>
                ${dadosTabela}
            </tbody>
          </table>
      </body>
    </html>
  `;

  return html;
};

export default OutPatientControlSheet;
