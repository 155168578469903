import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/transferir",
    variables,
  )
    .then(({ data }) => {
      const isTransferError =
        data.dados === "Erro ao transferir os agendamentos";

      const professionalNotPerformProcedure =
        data.dados === "ProfissionalNaoRealizaProcedimentoException";

      const appointmentsGreaterThanVacancies =
        data.dados === "ExameTransferenciaAgendamentosExistenteException";

      if (isTransferError) {
        return {
          status: false,
          msg: "Erro ao transferir os agendamentos",
        };
      }

      if (professionalNotPerformProcedure) {
        return {
          status: false,
          msg:
            "Não foi possível realizar a(s) transferêcia(s). Motivo: Procedimento(s) não realizado(s) pelo profissional de destino",
        };
      }

      if (appointmentsGreaterThanVacancies) {
        return {
          status: false,
          msg:
            "Quantidade de agendamentos maior que a quantidade de vagas disponíveis",
        };
      }

      return data.dados;
    })
    .catch((pErr) => {
      const NETWORK_ERROR = "Network Error";

      const errorMessage = pErr.message || pErr;

      const message = errorMessage.includes(NETWORK_ERROR)
        ? "Erro ao transferir"
        : "Problemas ao realizar transferência";

      throw new Error(message);
    });
};
