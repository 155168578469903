import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchAihPerPatientReport(
    $unsId: Int
    $name: String
    $medicalRecord: String
    $presentation: String
    $limit: Int
    $offset: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchAihPerPatientReport(
      unsId: $unsId
      name: $name
      medicalRecord: $medicalRecord
      presentation: $presentation
      limit: $limit
      offset: $offset
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        iaf_id
        icf_nome
        iaf_prontuario
        iaf_numero_aih
        valor_servico_hospitalar
        valor_servico_profissional
        total
      }
    }
  }
`;
export default async ({ commit }, pParameters) => {
  try {
    const variables = {
      ...pParameters,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    const html =
      !data.SearchAihPerPatientReport || !data.SearchAihPerPatientReport.html
        ? ""
        : data.SearchAihPerPatientReport.html.replace('\\"', '"');
    const count = !data.SearchAihPerPatientReport
      ? 0
      : data.SearchAihPerPatientReport.count;
    const csv = !data.SearchAihPerPatientReport
      ? 0
      : data.SearchAihPerPatientReport.csv;
    const excel = !data.SearchAihPerPatientReport
      ? 0
      : data.SearchAihPerPatientReport.excel;
    const fileName = !data.SearchAihPerPatientReport
      ? 0
      : data.SearchAihPerPatientReport.fileName;
    return {
      html,
      count,
      csv,
      excel,
      fileName,
      rows: data.SearchAihPerPatientReport.rows,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
