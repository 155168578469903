<template lang="html">
  <svg
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6015 18.0207L15.0458 1.46982C14.5145 0.54951 13.5628 0 12.5 0C11.4372 0 10.4855 0.54951 9.95413 1.46982L0.398546 18.0206C-0.132849 18.941 -0.132849 20.0399 0.398546 20.9603C0.929941 21.8807 1.8816 22.4301 2.94439 22.4301H22.0556C23.1183 22.4301 24.07 21.8806 24.6015 20.9603C25.1328 20.0399 25.1328 18.941 24.6015 18.0207Z"
      fill="#F96B70"
    />
    <path d="M13.2332 7.30969H11.7686V14.633H13.2332V7.30969Z" fill="white" />
    <path
      d="M12.4882 16.0966C11.9498 16.0966 11.5117 16.5346 11.5117 17.073C11.5117 17.6114 11.9498 18.0495 12.4882 18.0495C13.0266 18.0495 13.4647 17.6114 13.4647 17.073C13.4647 16.5346 13.0266 16.0966 12.4882 16.0966Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "DangerHelper",
};
</script>
