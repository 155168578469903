import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const sendData = {
    arrFiltro: {
      ...variables,
    },
  };

  return AuthLegacyRequest.post(
    "faturamento/controller-atendimento/buscar-procedimentos",
    sendData,
  )
    .then(({ data }) => {
      const result = data.dados.recordSet.map((item) => {
        return {
          sus_pa_id_dv: item.sus_pa_id_dv,
          fcs_codigo_simplificado: item.fcs_codigo_simplificado,
          sus_pa_dc: item.sus_pa_dc.toUpperCase(),
        };
      });

      return result;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
