import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query People($pesId: Int) {
    people(pes_id: $pesId) {
      rows {
        pes_id
        pes_nome
        pes_mae
        pes_pai
        pes_email
        pes_nascimento
        gender {
          sex_id
          sex_sigla
        }
        patient {
          pac_id
        }
        cns {
          crs_id
          crs_numero
        }
        cpf {
          cpf_id
          cpf_numero
        }
        address {
          end_id
          end_logradouro
          end_cep
          end_numero
          end_complemento
          neighborhood {
            bai_id
            bai_nome
            city {
              mun_id
              mun_codigo
              mun_nome
              state {
                est_id
                est_sigla
                est_codigo
              }
            }
          }
          publicPlaceType {
            tlg_id
            tlg_nome
            tlg_codigo
          }
        }
        telephones {
          rows {
            tel_id
            tel_id_pessoas
            tel_id_ddds
            tel_numero
            ddd {
              ddd_id
              ddd_codigo
            }
          }
        }
        ethnicity {
          etn_id
          etn_nome
          etn_codigo_sisaih
        }
        ethnicityAb {
          ens_id
          ens_descricao
          ens_codigo_ab
        }
        city {
          mun_id
          mun_nome
          state {
            est_id
            est_sigla
            est_nome
          }
        }
        nationality {
          nac_id
          nac_nome
          nac_codigo
        }
      }
    }
  }
`;

export default async ({ pesId }) => {
  if (!pesId) return Promise.reject(Error("pesId não informado"));
  const variables = {
    pesId,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.people.rows[0];
  } catch (err) {
    return Promise.reject(err.graphQLErrors);
  }
};
