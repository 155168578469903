<template slot="subFilters">
  <div class="pharmacy-central-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.productTypeId"
          default-text="Todos"
          class="inputitem"
          @change="selectProductType"
        />
        <RgSelectPharmacy
          v-model="form.pharmacyOrigin"
          :unitHealthId="Number(globalFilters.unitHealth)"
          :typePharmacy="1"
          default-text="Selecione"
          openOnlyBottom
          label="Farmácia de Origem"
          class="inputitem"
          @change="selectOrigin"
        />

        <RgSelectPharmacy
          v-model="form.far_id_destino"
          :unitHealthId="Number(globalFilters.unitHealth)"
          :typePharmacy="2"
          default-text="Selecione"
          openOnlyBottom
          label="Farmácia de Destino"
          class="inputitem"
          @change="selectDestiny"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import { mapGetters } from "vuex";

const FORM_FILTER = {
  productTypeId: null,
  lotAndExpiration: null,
};

export default {
  name: "PharmacyCentralTotalConsumptionReport",
  components: {
    RgSelectProductType,
    RgSelectPharmacy,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      document: null,
      disabledDocument: false,
      pharmacyId: null,
      productId: null,
      pharmacyOriginName: null,
      pharmacyDestinyName: null,
      productTypeName: null,
    };
  },

  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
    }),
  },

  watch: {
    globalFilters: {
      handler(pValue) {
        this.productId = pValue.product;
      },
      deep: true,
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "NOME", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Quantidade", key: "QUANTIDADE", align: "right" },
      { name: "Valor Total", key: "VALOR_TOTAL", align: "right" },
    ]);
  },

  methods: {
    async doSearchSubReport(pValue) {
      try {
        const filters = {
          ...this.globalFilters,
          ...this.form,
          ...pValue,
        };

        filters.pharmacyOrigin = Number(filters.pharmacyOrigin);
        filters.product = Number(filters.product);
        filters.mtp_id = Number(filters.productTypeId);
        filters.far_id_destino = Number(filters.far_id_destino);
        filters.far_nome_destino = this.pharmacyDestinyName;
        filters.far_nome_origem = this.pharmacyOriginName;
        filters.mtp_nome = this.productTypeName;
        filters.loggedUserId = this.userId;

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CENTRAL_TOTAL_CONSUMPTION",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    selectOrigin(pValue) {
      this.pharmacyOriginName = pValue.far_nome;
    },
    selectDestiny(pValue) {
      this.pharmacyDestinyName = pValue.far_nome;
    },
    selectProductType(pValue) {
      this.productTypeName = pValue.mtp_nome;
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.document = null;
      this.disabledDocument = false;
    },
  },
};
</script>
