import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import TypeScale from "$exam/submodules/register/view/type-scale/TypeScale";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/register/type-scale",
  name: "exam.register.typescale",
  components: {
    default: TypeScale,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Cadastro", link: OpenSubModule("exam") },
      {
        label: "Tipos de Escala",
        link: "/exam/register/type-scale",
      },
    ],
  },
};
