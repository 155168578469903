import moment from "moment";
import LocalStorage from "~common/local-storage/LocalStorage";

const PatientListHTML = (pInfo, pData) => {
  const emissao = moment().format("DD/MM/YYYY");
  const logo = LocalStorage.getObject("client-logo");
  let body = "";

  pData.corpo.forEach((item) => {
    "<tr>";
    body += "<td>" + item.nomePaciente + "</td>";
    body += "<td>" + item.unidadeFila + "</td>";
    body += "<td>" + item.uns_solicitante + "</td>";
    body += "<td>" + item.nomeFuncionarioSolicitante + "</td>";
    body += "<td>" + item.usuarioFila + "</td>";
    body += "<td>" + item.flg_data + "</td>";
    body += "<td>" + item.flg_obs + "</td>";
    body += "<td>" + item.nomeSinonimosOcupacoes + "</td>";
    body += "<td>" + item.usuarioExclusao + "</td></tr>";
  });

  const html = `
  <!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <title>Esus</title>
      <style type="text/css">

    @media print {
        body {
            margin: 0;
            padding: 0;
            line-height: 1.4em;
            font: 12pt Georgia, "Times New Roman", Times, serif;
            color: #000;
        }

        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid; page-break-after: auto;
        }

        footer {
            page-break-inside: avoid; page-break-after: auto;
        }
    }

    body {
        font-family: Verdana, Geneva, sans-serif;
    }

    .w33 {
        width: 33%;
        float: left;
    }

    header{

    }

     header h1 {
        margin: 45px 0 0 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 12pt;
        font-family: Verdana, Geneva, sans-serif;
        font-weight: bold;
    }

    header span {
        font-size: 7pt;
        font-weight: bold;
    }

    header div:first-child {
        margin: 0 0 5px 0;
    }

    .logoCliente {
        float: left;
        max-width: 150px;
        max-height: 100px;
        width: 150px;
        height: 100px;
         margin-bottom: 40px;
    }

    .logoEsus{
        float: right;
        max-width:  150px;
        max-height: 100px;
        width:  150px;
        height: 100px;
        margin-bottom: 40px;
    }

    table {
      margin: 30px 0px 10px 0px;
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
    }

    th:nth-child(1),
    tr td:nth-child(1) {
        width: 20%;
        font-size: 7.5pt;
    }

    th:nth-child(2),
    tr td:nth-child(2) {
        width: 20%;
    }

    th:nth-child(3),
    tr td:nth-child(3) {
        width: 20%;
    }

    th:nth-child(4),
    tr td:nth-child(4) {
      width: 30%;
      font-size: 7.5pt;
    }

    th:nth-child(5),
    tr td:nth-child(5) {
        width: 20%;
    }

    th:nth-child(6),
    tr td:nth-child(6) {
        width: 20%;
    }

    th:nth-child(7),
    tr td:nth-child(7) {
      width: 30%;
      word-break: break-word;
      font-size: 8pt;
    }

    th:nth-child(8),
    tr td:nth-child(8) {
      width: 30%;
      word-break: break-word;
      font-size: 8pt;
    }

    th:nth-child(9),
    tr td:nth-child(9) {
      width: 20%;
      font-size: 7.5pt;
    }

    td, th {
        padding: 0 8px;
        border: 1px solid #ddd;
        text-align: center;
        font-size: 8.5pt;
    }

    .footer {
        width: 100%;
        margin: 10px 0px;
        font-size: 8pt;
    }
    </style>
  </head>
  <body>
  <page size="A4">
      <header>
          <div><span>${pInfo.cli_nome || ""}</span></div>
          <div class="w33"><img src="${logo}" class="logoCliente"></div>
          <div class="w33"><h1>Pacientes Excluidos Pela Fila</h1></div>
          <div class="w33"><img src="/static/images/logo-esus.png" class="logoEsus"></div>
      </header>
      <br>

      <table class="table">
        <thead>
          <tr>
            <th>Paciente</th>
            <th>Unidade de Saúde</th>
            <th>Unidade Solicitante</th>
            <th>Médico Solicitante</th>
            <th>Usuário Fila</th>
            <th>Data Exclusão</th>
            <th>Motivo Exclusão</th>
            <th>Ocupação</th>
            <th>Usuário Exclusão</th>
          </tr>
        </thead>
        <tbody>
            ${body}
        </tbody>
      </table>

      <div class="footer">
        <p>Emitido em ${emissao}</p>
      </div>
    </page>
  </body>
</html>
  `;

  return html;
};

export default PatientListHTML;
