<template>
  <Modulebox
    :title="getTitle"
    class="appointment-schedule-form-per-day"
    :granted="hasPermission"
  >
    <RgRadioCustom
      id="gender"
      ref="gender"
      v-bind="propsRadioCustom"
      :canUnselect="false"
      label="Tipo de agendamento:"
      class="radio-type-schedule"
      @input="onInputRadioCustom"
    />

    <QuickAppointmentScheduleForm
      ref="form"
      class="appointment-schedule-form-per-day-form"
      :typeSchedule="typeSchedule"
      :employeeData="employeeData"
      :appointmentData="appointmentData"
      @saveWithAvailableHour="saveForm"
      @canSave="onCanSave"
      @saveSchedule="onSaveSchedule"
    />

    <div class="footer">
      <RgCleanButton
        v-show="!appointmentData.con_id"
        id="clean-btn"
        small
        type="button"
        title="Limpar"
        @click="confirmCleanForm"
      />

      <RgCancelButton
        id="cancel-btn"
        ref="cancelButton"
        medium
        class="cancel"
        @click="cancel"
      />

      <RgSaveButton
        id="save-btn"
        ref="saveButton"
        medium
        class="save"
        type="button"
        :disabled="!canSave || disableBySave"
        @click="saveForm"
      />
    </div>

    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="getCleanFormMethod"
      @getOut="closeModalConfirmFieldClearing"
      @close="closeModalConfirmFieldClearing"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  ModalConfirmFieldClearing,
  RgCancelButton,
  RgRadioCustom,
} from "~tokio/primitive";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import QuickAppointmentScheduleForm from "$appointment/common/component/quick-appointment-schedule-form/QuickAppointmentScheduleForm";
import { mapGetters } from "vuex";

export default {
  name: "AppointmentFormSchedule",
  components: {
    Modulebox,
    QuickAppointmentScheduleForm,
    RgSaveButton,
    RgCleanButton,
    RgCancelButton,
    ModalConfirmFieldClearing,
    RgRadioCustom,
  },
  data: () => {
    return {
      appointment_id: 2,
      scheduleLoadInfo: null,
      typeSchedule: false,
      modalConfirmFieldClearing: false,
      canSave: false,
      disableBySave: false,
      typeRadioCustom: 1,
    };
  },
  computed: {
    ...mapGetters({
      hasTypeSelected: "Appointment/Schedule/GET_TYPE_SCHEDULE",
    }),

    propsRadioCustom() {
      let list = [];
      const existRoutePerPeriods =
        this.$route.name === "appointment.schedule.per-periods.form";
      if (existRoutePerPeriods) {
        list = [
          { title: "Único", id: 1 },
          { title: "Múltiplo", id: 2 },
        ];
      } else {
        list = [{ title: "Único", id: 1 }];
      }

      const value = this.typeRadioCustom;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },

    isEditing() {
      return this.appointmentData != null && this.appointmentData.con_id > 0;
    },
    employeeData() {
      return this.$store.state.Appointment.Schedule.selected_employee_data;
    },
    appointmentData() {
      return Object.assign(
        {},
        this.selectedAppointmentData,
        this.scheduleLoadInfo,
      );
    },
    selectedAppointmentData() {
      return this.$store.state.Appointment.Schedule.selected_appointment_data;
    },
    hasPermission() {
      return this.$Permissions.global.has("consulta.agendamento");
    },
    // existRoutePerPeriods() {
    //   return this.$route.name === "appointment.schedule.per-periods.form";
    // },
    getTitle() {
      if (this.isEditing) {
        return "Editar Consulta";
      } else {
        return "Agendar Consulta";
      }
    },
    hasPermissionMultipleSchedule() {
      return this.$Permissions.sector.has(
        "consulta.agendamentoCompleto.multiplosAgendamentos",
        this.employeeData.set_id,
      );
    },
  },

  beforeDestroyed() {
    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-day/form",
      this.$route.meta.breadcrumb,
    );

    const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-periods/form",
      this.$route.meta.breadcrumb,
    );

    if (
      !existThisRouteFromSchedulePerDay &&
      !existThisRouteFromSchedulePerPeriods
    ) {
      this.removeSelectedData();
      this.$refs.form.cleanForm();
      this.typeSchedule = false;
      this.$store.commit("Appointment/Schedule/CLEAN_FORM_APPOINTMENT");
    }
  },

  mounted() {
    if (!(this.employeeData && this.appointmentData)) {
      this.cancel();
      this.$toaster.info(
        "Será necessário realizar uma nova pesquisa.",
        "Você foi redirecionado para a busca",
      );
    }

    const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/per-day/form",
      this.$route.meta.breadcrumb,
    );

    if (!existThisRouteFromSchedulePerDay && this.hasTypeSelected !== null) {
      this.typeSchedule = this.hasTypeSelected;
      this.setTypeSchedule(this.typeSchedule);
    }

    if (this.selectedAppointmentData) {
      // Complementa os dados do agendamento carregando mais informaçoes necessarias que serão concatenadas apenas
      // nesta view no computed: appointmentData
      this.$store
        .dispatch("Appointment/Schedule/SCHEDULE_LOAD", {
          intIdConsulta: this.appointmentData.con_id,
        })
        .then((data) => {
          if (data.con_hora && data.con_hora.length > 5) {
            data.con_hora = data.con_hora.substring(0, 5);
          }
          this.scheduleLoadInfo = data;
        })
        .catch((pErr) => {
          console.log(pErr);
        });
    }
  },
  methods: {
    setTypeSchedule(pValue) {
      const SINGLE_SCHEDULE = 1;
      const MULTIPLE_SCHEDULE = 2;

      if (!pValue || pValue === false) {
        this.$refs.radioType.toggleStatus(SINGLE_SCHEDULE);
      } else {
        this.$refs.radioType.toggleStatus(MULTIPLE_SCHEDULE);
      }
    },

    onCanSave(val) {
      this.canSave = val;
    },

    cancel() {
      const existThisRouteFromSchedulePerDay = ValidateIfRouteExistInBreadscrumb(
        "/appointment/schedule/per-day",
        this.$route.meta.breadcrumb,
      );

      const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
        "/appointment/schedule/per-periods",
        this.$route.meta.breadcrumb,
      );

      if (existThisRouteFromSchedulePerDay) {
        this.$router.push({ name: "appointment.schedule.per-day" });
      }

      if (existThisRouteFromSchedulePerPeriods) {
        this.typeSchedule = false;

        this.$router.push({ name: "appointment.schedule.per-periods" });
      }
    },

    getCleanFormMethod() {
      this.$refs.form.cleanForm();
      this.modalConfirmFieldClearing = false;
    },

    confirmCleanForm() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    onInputRadioCustom(pItem) {
      this.typeRadioCustom = pItem.id;
      const isMultiple = this.typeRadioCustom === 2;

      if (!this.hasPermissionMultipleSchedule) {
        this.$toaster.error("Usuário sem permissão para Múltiplo Agendamento");
        return;
      }

      if (isMultiple) {
        this.typeSchedule = true;
      } else {
        this.typeSchedule = false;
      }

      this.$store.commit(
        "Appointment/Schedule/SELECT_TYPE_SCHEDULE",
        this.typeSchedule,
      );
    },
    removeSelectedData() {
      // this.$store.commit("Appointment/Schedule/UNSELECT_EMPLOYEE_DATA");
      this.$store.commit("Appointment/Schedule/UNSELECT_APPOINTMENT_DATA");
    },

    onSaveSchedule(result) {
      this.pushRouter(result);
    },

    async saveForm() {
      try {
        this.$loader.start();
        this.disableBySave = true;
        const result = await this.$refs.form.saveForm();
        this.pushRouter(result);
      } catch (err) {
        console.log("err", err);
      } finally {
        this.disableBySave = false;
        this.$loader.finish();
      }
    },

    pushRouter(result) {
      this.$loader.start();

      const existThisRouteFromSchedulePerPeriods = ValidateIfRouteExistInBreadscrumb(
        "/appointment/schedule/per-periods",
        this.$route.meta.breadcrumb,
      );

      if (result && result !== false) {
        this.removeSelectedData();

        if (typeof result === "number") {
          const params = {
            isAppontmentSchedule: true,
            appointmentId: result,
          };

          existThisRouteFromSchedulePerPeriods
            ? this.$router.push({
                name: "appointment.schedule.per-periods",
                params,
              })
            : this.$router.push({
                name: "appointment.schedule.per-day",
                params,
              });
        } else {
          existThisRouteFromSchedulePerPeriods
            ? this.$router.push({
                name: "appointment.schedule.per-periods",
              })
            : this.$router.push({
                name: "appointment.schedule.per-day",
              });
        }
      }
      this.$loader.finish();
    },
  },
};
</script>
