import { render, staticRenderFns } from "./RgContentHospitalizationTeam.vue?vue&type=template&id=5c3f0945&scoped=true&lang=html&"
import script from "./RgContentHospitalizationTeam.vue?vue&type=script&lang=js&"
export * from "./RgContentHospitalizationTeam.vue?vue&type=script&lang=js&"
import style0 from "./RgContentHospitalizationTeam.scss?vue&type=style&index=0&id=5c3f0945&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3f0945",
  null
  
)

export default component.exports