<template>
  <div v-if="show">
    <RgBaseModal
      ref="Modal"
      aria-modal="true"
      :show="show"
      class="modal-choose-way-photo"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-choose-way-photo-header">
        <span class="title">{{ title }}</span>
      </div>

      <div slot="body" class="modal-choose-way-photo-body">
        <FormBase title="Obter Foto">
          <div class="painel">
            <RgRadioCustom
              :list="RADIO"
              :value="status"
              :can-unselect="false"
              uniqueKey="id"
              @input="onInputRadioCustom"
            />
          </div>

          <div v-show="status === UPLOAD" class="img-container">
            <div class="img-area">
              <img :src="img" class="img" />
              <canvas ref="canvas" class="webcam" />
            </div>
            <p class="max-text">É permitido arquivos de até 3MB</p>
            <div class="btns-area">
              <input
                ref="file"
                class="input-file"
                type="file"
                :accept="acceptTypes"
                @change="uploadFile"
              />
              <label>
                <RgAddButton :disabled="!alreadyHasImage" @click="openUpload" />
              </label>

              <RgLessButton
                :disabled="alreadyHasImage"
                @click="confirmRemovePhoto"
              />
            </div>
          </div>

          <div v-show="status === WEBCAM" class="webcam-container">
            <label class="input">
              Câmera:
              <select v-model="selectedCamera" class="select">
                <option
                  v-for="camera in cameras"
                  :key="camera.deviceId"
                  :value="camera.deviceId"
                >
                  {{ camera.label }}
                </option>
              </select>
            </label>

            <video
              v-show="!existFile && !img"
              ref="webcam"
              class="webcam"
              autoplay
              playsinline
            />

            <canvas v-show="existFile && !img" ref="canvas" class="webcam" />

            <img v-if="img" :src="img" class="img" />

            <div class="actions">
              <SmallButton
                class="-photo"
                label="Tirar foto"
                backgroundColor="#1E88A9"
                :class="{ disable: existFile || !!img }"
                :disabled="existFile || !!img"
                @click="snap"
              >
                <IconPhoto slot="icon" class="svg" />
              </SmallButton>

              <RgLessButton
                title="Remover foto"
                :class="{ disable: !existFile && !img }"
                :disabled="!existFile && !img"
                @click="confirmRemovePhoto"
              />
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="modal-choose-way-photo-footer">
        <RgCancelButton medium class="btn-margim" @click="close" />
        <RgConfirmButton medium type="button" @click="confirm" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  // LargeButton,
  // IconWebcam,
  // IconArrowDownload,
  RgAddButton,
  RgLessButton,
  RgConfirmButton,
  RgCancelButton,
  SmallButton,
  IconPhoto,
  RgRadioCustom,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { AlertError } from "~tokio/primitive/notification";

import Webcam from "webcam-easy";
import SendFile from "./actions/SendFile";
import { SendFile as File } from "~tokio/primitive/modal/modal-upload/actions/SendFile";
let webcam = null;

export default {
  name: "ModalChooseWayPhoto",

  components: {
    RgBaseModal,
    // LargeButton,
    // IconWebcam,
    // IconArrowDownload,
    FormBase,
    RgAddButton,
    RgLessButton,
    RgConfirmButton,
    RgCancelButton,
    SmallButton,
    IconPhoto,
    RgRadioCustom,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Foto do Paciente",
    },
    hasImage: {
      type: Object,
      default: () => {},
    },
    accept: {
      type: Array,
      default: () => {
        return ["image/png", "image/jpeg", "image/jpg"];
      },
    },
    maxSizeInKB: {
      type: Number,
      default: 3145728,
    },
  },

  data() {
    return {
      cameras: [],
      selectedCamera: null,
      modalUpload: false,
      status: 1,
      img: "/static/person-image/photo-default3.png",
      img_data: null,
      file: null,
    };
  },

  computed: {
    alreadyHasImage() {
      return this.img === "/static/person-image/photo-default3.png";
    },
    existFile() {
      return this.img_data !== null;
    },
    acceptTypes() {
      return this.accept.join(", ");
    },
  },

  watch: {
    show(pValue) {
      if (pValue && this.hasImage && this.hasImage.url) {
        this.img = this.hasImage.url;
        this.img_data = this.hasImage;
      }
    },

    selectedCamera(pValue) {
      if (this.show && this.status === this.WEBCAM) {
        webcam.stop();
        webcam._selectedDeviceId = pValue;
        webcam.start();
      }
    },

    async status(pValue) {
      if (pValue === this.WEBCAM) {
        // this.confirmRemovePhoto();
        if (this.alreadyHasImage) {
          this.img = "";
        }
        webcam = new Webcam(this.$refs.webcam, "user", this.$refs.canvas);

        webcam
          .start()
          .then((result) => {
            if (webcam.webcamList.length <= 0) {
              webcam.stop();
              this.$emit("close");
              this.$toaster.error(
                "Verifique as Configuraçoes do seu Computador.",
                "Nenhuma webcam encontrada",
              );
            }
            this.cameras = webcam.webcamList;
            this.selectedCamera = this.cameras[0].deviceId;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (webcam) {
          webcam.stop();
        }

        if (!this.img) {
          this.img = "/static/person-image/photo-default3.png";
        }
      }
    },
  },

  created() {
    this.UPLOAD = 1;
    this.WEBCAM = 2;

    this.RADIO = [
      { title: "Arquivo de Imagem", id: 1 },
      { title: "Câmera", id: 2 },
    ];
  },

  methods: {
    openUpload() {
      this.$refs.file.click();
      this.status = this.UPLOAD;
    },

    uploadFile(pFile) {
      const fileInUpload = this.$refs.file.files[0];
      const isValid = this.validateFile(fileInUpload);

      if (isValid) {
        this.file = fileInUpload;
        this.confirmUpload();
      } else {
        this.$refs.file.value = null;
      }
    },

    validateFile(pFile) {
      const hasInvalidSize = pFile.size > this.maxSizeInKB;

      if (hasInvalidSize) {
        AlertError("Arquivo com tamanho maior que o esperado");
        return false;
      }

      const fileType = pFile.type;
      const hasInvalidTypes = !this.accept.includes(fileType);

      if (hasInvalidTypes) {
        AlertError(`Formato inválido. Permitidos: ${this.acceptTypes}`);
        return false;
      }

      return true;
    },

    confirmUpload() {
      this.$loader.start("Enviando Arquivo...");

      File(this.file)
        .then((result) => {
          this.$loader.finish();
          this.getUploadedFileInfo(result.data.fileInfo);
        })
        .catch((err) => {
          this.file = null;
          this.$loader.finish();
          this.$toaster.error("Falha ao enviar o arquivo:" + err.message);
        });

      this.file = null;
    },

    onInputRadioCustom(item) {
      this.status = item.id;
    },

    confirmRemovePhoto() {
      this.img_data = null;
      if (this.status === this.WEBCAM) {
        this.img = "";
      } else if (this.status === this.UPLOAD) {
        this.img = "/static/person-image/photo-default3.png";
      }
      this.$refs.file.value = null;
    },

    snap() {
      const data = webcam.snap();
      this.img_data = data;
    },

    getUploadedFileInfo(pValue) {
      this.img = pValue.url;
      this.img_data = pValue;
    },

    openModalWebcam() {
      this.status = this.WEBCAM;
    },

    confirm() {
      if (!this.img_data) {
        this.$parent.confirmRemovePhoto();
      }

      if (this.status === this.UPLOAD) {
        this.$emit("upload", this.img_data);
        this.close();
      } else if (this.status === this.WEBCAM) {
        if (!this.img_data) {
          this.$emit("upload", null);
          this.close();
        } else {
          // send to backend uploads and emit filename
          this.$loader.start("Enviando Arquivo...");
          SendFile(this.img_data)
            .then((result) => {
              this.$loader.finish();
              this.$emit("upload", result.data.fileInfo);
              this.$emit("close");
              this.img_data = null;
              this.$refs.canvas
                .getContext("2d")
                .clearRect(
                  0,
                  0,
                  this.$refs.canvas.width,
                  this.$refs.canvas.height,
                );
            })
            .catch((err) => {
              this.confirmRemovePhoto();
              this.$loader.finish();
              this.$toaster.error("Falha ao enviar o arquivo:" + err.message);
            });
        }
      }
    },

    cancelClose() {
      this.close();
    },

    close() {
      this.status = 1;
      this.$emit("close");
    },
  },
};
</script>
