import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacyCentralAttendRequest from "$pharmacy/submodules/central/view/attend-request/PharmacyCentralAttendRequest";

export default {
  path: "/pharmacy/central/attendRequest",
  name: "pharmacy.central.attend.request",
  components: {
    default: PharmacyCentralAttendRequest,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Central",
        link: "/pharmacy/central",
      },
      {
        label: "Atender Solicitações",
      },
    ],
  },
};
