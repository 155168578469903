import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  PacienteConsultadoHojeAtendenteException: `Este paciente já possui consulta agendada`,
  HorarioIndisponivelException: `A hora informada já possui consulta agendada`,
  PacienteAgendadoHojeException: `Este paciente já possui procedimento agendado`,
  RegistroInativadoException: `Registro já foi retirado da fila por outro usuário`,
};

export default async (context, variables) => {
  const sendData = {
    collectionFormData: variables,
    tipoAgendamento: "AGENDAMENTOPORREGULACAO",
    blnExibirAvisos: variables.blnExibirAvisos,
  };
  return AuthLegacyRequest.post(
    "exames2/controller-exame-agendamento-procedimento/agendar-pela-fila-lote",
    sendData,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
