import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExamLaboratoryDetails from "$exam/submodules/view/laboratory-details/ExamLaboratoryDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/laboratory/details",
  name: "exam.laboratory.details",
  components: {
    default: ExamLaboratoryDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      { label: "Exames Laboratoriais", link: "/exam/laboratory" },
      { label: "Cadastrar Pedido" },
    ],
  },
};
