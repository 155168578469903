import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query($limit: Int) {
    genres(limit: $limit) {
      rows {
        gen_id
        gen_descricao
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.genres.rows;
};
