<template>
  <div class="pharmacy-inventory">
    <RgSearch
      ref="rgsearch"
      v-model="mutablePharmacy"
      :result-title="'Estoque Máximo e Mínimo'"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="34.5"
      :max-register="40"
      :show-footer="hasPharmacyInventory"
      @count="getCountValue"
      @submitFromButton="cleanSelectRow"
      @afterPerformSearch="afterSearchFilter"
      @viewClickPagination="cleanSelectRow"
    >
      <div slot="filters" class="pharmacy-inventory-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealthPharmacy
              id="unit-health"
              ref="unitHealth"
              v-model="form.unitHealth"
              class="inputitem"
              label="Unidade de Saúde"
              :rules="{ required: true }"
            />
          </div>
          <div class="selectinput">
            <RgSelectPharmacy
              id="pharmacy"
              ref="pharmacy"
              v-model="form.pharmacy"
              class="inputitem"
              :unitHealthId="Number(form.unitHealth)"
              :disabled="!form.unitHealth"
              :class="{ disable: !form.unitHealth }"
              :rules="{ required: true }"
            />
          </div>
          <div class="selectinput">
            <RgSelectProduct
              id="product"
              ref="product"
              v-model="form.product"
              class="inputitem"
              :disabled="!form.pharmacy"
              :class="{ disable: !form.pharmacy }"
              perClient
              label="Produto / Medicamento"
              @change="selectedProduct"
            />
          </div>

          <div class="selectinput">
            <RgSelectTypePresentation
              id="presentation"
              ref="presentation"
              v-model="form.presentation"
              class="inputitem"
              :disabled="true"
              :class="{ disable: true }"
              label="Apresentação"
            />
          </div>
        </div>
      </div>

      <div v-if="!hasPharmacyInventory" slot="menu-top" class="top-button">
        <RgAddButton
          id="nova-maximo-minimo"
          large
          label="Novo"
          title="Cadastrar Estoques Mínimo e Máximo"
          :permission="hasPermissionCreateProvider"
          @click="newPharmacyInventory"
        />
      </div>

      <div class="pharmacy-inventory-result">
        <SmartTable
          ref="smartTable"
          name="PharmacyInventorySearch"
          :columns="COLUMNS"
          :body="mutablePharmacy"
          :total="Number(total)"
          :initial-columns="7"
          index-column="mam_id"
          :show-pagination="false"
          toggle-selected
          class="smart-table"
          :dynamic-height="getSpaceTable"
          @getLine="getValueRow"
        >
          <div slot="top-buttons" class="actions">
            <div class="right-buttons">
              <RgAddButton
                id="cadastrar-maximo-minimo"
                :permission="hasPermissionCreateProvider"
                title="Cadastrar Estoques Mínimo e Máximo"
                @click="newPharmacyInventory"
              />
              <RgEditButton
                id="editar-maximo-minimo"
                :permission="hasPermissionEditProvider"
                title="Editar Estoques Mínimo e Máximo"
                :disabled="!hasInventorySelect"
                @click="editPharmacyInventory"
              />
              <RgLessButton
                id="excluir-maximo-minimo"
                :permission="hasPermissionDeleteProvider"
                title="Excluir Estoques Mínimo e Máximo"
                :disabled="!hasInventorySelect"
                @click="openModalRemovePharmacyInventory"
              />
            </div>
          </div>
        </SmartTable>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      v-bind="propsModalDeletion"
      @close="closeModalRemovePharmacyInventory"
      @reSearch="reSearchTable"
    />
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";

import {
  RgAddButton,
  RgEditButton,
  RgLessButton,
  ModalConfirmDeletion,
} from "~tokio/primitive";

const FORM_INVENTORY = {
  unitHealth: null,
  pharmacy: null,
  product: null,
  presentation: null,
};

export default {
  name: "PharmacyInventory",
  components: {
    RgSearch,
    SmartTable,
    RgSelectUnithealthPharmacy,
    RgAddButton,
    RgEditButton,
    RgSelectPharmacy,
    RgLessButton,
    ModalConfirmDeletion,
    RgSelectProduct,
    RgSelectTypePresentation,
  },
  directives: {
    mask,
  },

  data() {
    return {
      mutablePharmacy: [],
      selectedRow: null,
      modalRemovePharmacyInventory: false,

      form: this.$utils.obj.DeepCopy(FORM_INVENTORY),
      pagination: {
        limit: 20,
        offset: 0,
        current: 1,
      },
      total: 0,
      tableHeight: 0,
    };
  },

  computed: {
    hasPharmacyInventory() {
      return this.mutablePharmacy?.length > 0;
    },

    hasInventorySelect() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },

    hasPermissionCreateProvider() {
      return !!this.$Permissions.global.has(
        "farmacia.estoqueMaximoMinimo.incluir",
      );
    },
    hasPermissionEditProvider() {
      return !!this.$Permissions.global.has(
        "farmacia.estoqueMaximoMinimo.alterar",
      );
    },
    hasPermissionDeleteProvider() {
      return !!this.$Permissions.global.has(
        "farmacia.estoqueMaximoMinimo.excluir",
      );
    },
    propsModalDeletion() {
      const show = this.modalRemovePharmacyInventory;
      const noReason = true;
      const title = "Excluir Estoque Mínimo e Máximo";
      const message =
        "As quantidades de estoque serão zeradas para essa farmácia ";
      const bodyText = this.selectedRow
        ? this.selectedRow.far_nome +
          " - " +
          this.selectedRow.mpd_novo_principio_ativo +
          " - " +
          this.selectedRow.mtu_novo_nome
        : null;
      const msgSuccess = "Estoque excluída com sucesso!";
      const btnRemoveTitle = "Excluir";
      const confirm = () => this.removePharmacyInventory();

      return {
        show,
        noReason,
        confirm,
        title,
        message,
        bodyText,
        msgSuccess,
        btnRemoveTitle,
      };
    },
    getSpaceTable() {
      return this.tableHeight;
    },
  },

  watch: {
    "form.product"(pValue) {
      if (!pValue && pValue === null) {
        this.form.presentation = null;
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
      { name: "Farmácia", key: "far_nome", align: "left" },
      { name: "Nome", key: "mpd_novo_principio_ativo", align: "left" },
      { name: "Apresentação", key: "mtu_novo_nome", align: "left" },
      { name: "Tipo", key: "mtp_nome", align: "left" },
      { name: "Quantidade Mínima", key: "mam_minimo", align: "left" },
      { name: "Quantidade Máxima", key: "mam_maximo", align: "left" },
    ];
  },

  mounted() {
    this.fillFilter();
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/pharmacy/register/inventory",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Pharmacy/Register/RESET_FILTER_PHARMACY_INVENTORY");
    } else {
      this.reSearchTable();
    }
  },

  methods: {
    selectedProduct(pProduct) {
      if (pProduct) {
        this.form.presentation = pProduct.mtu_id;
      } else {
        this.form.presentation = null;
      }
    },
    afterSearchFilter() {
      this.getDynamicHeight();
    },

    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.rgsearch?.$refs.listContent) {
          const headerHeight = 40;
          this.tableHeight =
            this.$refs.rgsearch.$refs.listContent.offsetHeight - headerHeight;
        }
        this.cleanSelectRow();
      });
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          uns_id: Number(this.form.unitHealth),
          far_id: Number(this.form.pharmacy),
          mpd_id: Number(this.form.product),
          mtu_id: Number(this.form.presentation),
        },
      };

      this.$store.commit(
        "Pharmacy/Register/SET_FILTER_INVENTORY",
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Pharmacy/Register/GET_FILTER_INVENTORY"
        ];

        if (existFilterData) {
          this.$loader.start();

          this.form.unitHealth = existFilterData.unitHealth;
          this.form.pharmacy = existFilterData.pharmacy;
          this.form.product = existFilterData.product;
          this.form.presentation = existFilterData.presentation;

          await this.$refs.rgsearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning(Err, "Erro ao carregar os dados da navegação");
        this.$loader.finish();
      }
    },

    async searchFilter(pData) {
      const result = await this.$store.dispatch(
        "Pharmacy/Register/SEARCH_INVENTORY",
        pData,
      );

      this.$loader.finish();
      return result;
    },

    async removePharmacyInventory() {
      try {
        this.$loader.start();
        const variables = {
          intIdMaximoMinimo: this.selectedRow.mam_id,
        };
        await this.$store.dispatch(
          "Pharmacy/Register/DELETE_PHARMACY_INVENTORY",
          variables,
        );
        this.modalRemovePharmacyInventory = false;
        this.$toaster.success("Estoque excluído com sucesso");
      } catch (error) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o Estoque",
        );
      } finally {
        this.$loader.finish();
      }
    },

    async reSearchTable() {
      try {
        this.cleanSelectRow();
        await this.$refs.rgsearch.performSearch();
      } catch (pErr) {
        console.log(pErr);
      }
    },

    cleanSelectRow() {
      this.selectedRow = null;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },

    newPharmacyInventory() {
      this.$router.push({
        name: "pharmacy.register.inventory.insert",
      });
    },

    editPharmacyInventory() {
      this.$router.push({
        name: "pharmacy.register.inventory.edit",
      });
    },

    openModalRemovePharmacyInventory() {
      this.modalRemovePharmacyInventory = true;
    },

    closeModalRemovePharmacyInventory() {
      this.modalRemovePharmacyInventory = false;
    },

    getValueRow(pValue) {
      if (pValue) {
        this.selectedRow = pValue;
        this.$store.commit(
          "Pharmacy/Register/SELECT_PHARMACY_INVENTORY",
          pValue,
        );
      } else {
        this.selectedRow = null;
      }
    },

    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    cleanForm() {
      this.mutablePharmacy = [];
      this.selectedRow = null;
      this.form = this.$utils.obj.DeepCopy(FORM_INVENTORY);
      this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    },
  },
};
</script>
