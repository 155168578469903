<template>
  <section class="foundation-schedules">
    <RgSearch
      ref="RgSearch"
      v-model="mutableList"
      :buildFilter="generateFilter"
      :searchFunction="actionSearchFilter"
      :clearFunction="actionCleanForm"
      showEmptyMessage
      :itemHeight="29.5"
      :maxRegister="30"
      resultTitle="Lista de Pacientes Agendados pela Fila"
      @afterPerformSearch="actionAfterSearchFilter"
      @count="getCountValue"
    >
      <section slot="filters" class="foundation-schedules-filter">
        <section class="grid">
          <div class="filter-title">
            <span class="title"> Dados de Agendamento (Destino) </span>
          </div>
          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-destiny"
              v-model="form.unitDestiny"
              label="Unidade de Saúde"
              class="inputitem"
              default-text="Todas"
              :permission="permissionScreen"
            />

            <RgSelectSector
              id="sector-destiny"
              v-model="form.sectorDestiny"
              class="inputitem"
              label="Setor"
              default-text="Todos"
              :disabled="!form.unitDestiny"
              :unit-health="form.unitDestiny"
            />

            <RgSelectPlaces
              id="place-destiny"
              v-model="form.placeDestiny"
              class="inputitem"
              default-text="Todos"
              :disabled="!form.sectorDestiny"
              :sector="form.sectorDestiny"
            />

            <RgSuggestCboSector
              v-if="moduleType === TYPE_MODULE.APPOINTMENT"
              id="occupation-destiny"
              ref="Cbo"
              v-model="form.ocp_nome"
              :disabled="!form.sectorDestiny"
              :class="{ disable: !form.sectorDestiny }"
              :sectorId="Number(form.sectorDestiny)"
              label="Ocupação"
              placeholder="Digite o nome da ocupação"
              class="inputitem"
              @selected="selectedCbo"
            />

            <RgSelectProceduresFilter
              v-if="moduleType === TYPE_MODULE.EXAM"
              id="procedures-destiny"
              ref="procedure"
              v-model="form.stp_id"
              :disabled="!form.sectorDestiny"
              :class="{ disable: !form.sectorDestiny }"
              :sector-id="Number(form.sectorDestiny)"
              label="Procedimento"
              placeholder="Digite o nome do procedimento"
              class="inputitem"
            />

            <RgSuggestTypeBed
              v-if="moduleType === this.TYPE_MODULE.HOSPITALIZATION"
              id="bed-type"
              v-model="form.bedType"
              :rules="{ forceSelection: true }"
              class="inputitem"
              placeholder="Todos"
              @selected="selectingBedType"
            />

            <RgSuggestEmployee
              id="employee-destiny"
              ref="employee"
              v-model="form.pes_nome"
              :maxlength="100"
              :ocuppation-id="form.ocp_id"
              :sector="form.sectorDestiny"
              :vus-responsavel="false"
              :disabled="
                !form.sectorDestiny &&
                moduleType !== this.TYPE_MODULE.HOSPITALIZATION
              "
              :class="{
                disable:
                  !form.sectorDestiny &&
                  moduleType !== this.TYPE_MODULE.HOSPITALIZATION,
              }"
              :sectorRequired="moduleType !== this.TYPE_MODULE.HOSPITALIZATION"
              :filterWithoutBond="
                moduleType === this.TYPE_MODULE.HOSPITALIZATION
              "
              label="Profissional"
              placeholder="Digite o nome do profissional"
              class="inputitem"
              @selected="selectingEmployee"
            />

            <RgSuggestUser
              id="user-destiny"
              ref="userDestiny"
              v-model="form.userDestiny"
              label="Usuário"
              @selected="selectingUser"
            />

            <div class="two-columns">
              <RgInputDate
                id="initial-date-destiny"
                v-model="form.scheduleDateStart"
                :rules="{ fn: verifyScheduleDate }"
                label="Data Inicial"
                class="inputitem"
              />
              <RgInputDate
                id="final-date-destiny"
                v-model="form.scheduleDateEnd"
                label="Data Final"
                class="inputitem"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title"> Dados do Paciente </span>
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              id="smart-person"
              ref="patient"
              v-model="form.personName"
              label="Paciente"
              :enabled-patient="false"
              :rules="{ forceSelection: true }"
              @selected="selectingPerson"
            />
          </div>

          <div class="filter-title">
            <span class="title"> Dados da Regulação (Origem) </span>
          </div>

          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-origin"
              v-model="form.unitOrigem"
              label="Unidade de Saúde"
              class="inputitem"
              :default-text="'Todas'"
              :permission="'consulta.filaConsulta.exibir'"
            />

            <RgSuggestUser
              id="user-origin"
              ref="userOrigin"
              v-model="form.userOrigin"
              label="Usuário"
              @selected="selectingUserOrigin"
            />

            <div class="two-columns">
              <RgInputDate
                id="initial-date-origin"
                v-model="form.insertDateStart"
                :rules="{ fn: verifyRegulationDate }"
                label="Data Inicial"
                class="inputitem"
              />
              <RgInputDate
                id="final-date-origin"
                v-model="form.insertDateEnd"
                label="Data Final"
                class="inputitem"
              />
            </div>
          </div>

          <div class="filter-title">
            <span class="title"> Dados da Solicitação </span>
          </div>

          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-requesting"
              v-model="form.unitRequesting"
              class="inputitem"
              :default-text="'Todas'"
              :permission="'consulta.filaConsulta.exibir'"
            />

            <RgSuggestEmployee
              id="employee-request"
              ref="employeeRequest"
              v-model="form.pes_nome_solicitante"
              :maxlength="100"
              :vus-responsavel="false"
              label="Profissional Solicitante"
              placeholder="Digite o nome do profissional"
              class="inputitem"
              @selected="selectingRequestEmployee"
            />

            <RgSelectPriorityQueue
              id="priority"
              v-model="form.priority"
              :module-id="moduleId"
              label="Prioridade"
              default-text="Todas"
              class="inputitem"
            />

            <RgRadioCustom
              v-if="moduleType !== TYPE_MODULE.HOSPITALIZATION"
              id="with-print"
              ref="RgRadioCustom"
              :list="RADIO_DATA"
              :value="form.withPrint"
              uniqueKey="id"
              @input="onInputRadioCustom"
            />
          </div>
        </section>
      </section>

      <section class="foundation-schedules-search">
        <SmartTable
          ref="smartTable"
          :name="getModuleType().smartTableName"
          :columns="getModuleType().columnSmartTable"
          :body="mutableList"
          :dynamic-height="getSpaceTable"
          :total="Number(totalList)"
          :initial-columns="5"
          :show-pagination="false"
          :colorLine="actionColorLine"
          :showColorInFirstColumn="false"
          toggle-selected
          circle-indicator
          class="smart-table"
          @getLine="selectRowData"
        >
          <div slot="top-buttons" class="top-buttons">
            <RgLessButton
              id="delete-schedule"
              :disabled="!selectedRow"
              class="_mg-r-5"
              title="Excluir Agendamento"
              @click="openModalDeleteSchedule"
            />

            <RgDropdown
              id="options-print"
              :item="selectedRow || {}"
              class="_mg-r-5"
              :action-options="itensActionOptionsPrint(selectedRow)"
              show-down
              show-left
              small
            >
              <IconPrinter slot="icon" />
            </RgDropdown>

            <RgDropdown
              id="options-person"
              :disabled="!selectedRow"
              :item="selectedRow || {}"
              :action-options="itensActionOptionsPerson(selectedRow)"
              small
              show-down
              show-left
            >
              <IconCard slot="icon" />
            </RgDropdown>
          </div>
        </SmartTable>
      </section>
    </RgSearch>

    <ModalObservationQueue
      id="modal-observation-queue"
      :show="modalObservationQueue"
      :patientData="selectedRow"
      :modId="moduleId"
      @close="closeModalObservationQueue"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalDeleteSchedule"
      @close="closeModalDeleteSchedule"
    >
      <div slot="more-info" class="delete-schedule">
        <hr class="divisor" />
        <div>
          <span class="info-title">Tipo de exclusão do agendamento</span>
        </div>
        <div class="delete-options">
          <RgRadioCustom
            id="delete-schedule"
            ref="RgRadioCustomDelete"
            :list="RADIO_DATA_DELETE"
            :value="deleteSchedule"
            uniqueKey="id"
            canUnselect
            @input="onInputRadioCustomDelete"
          />
        </div>
      </div>
    </ModalConfirmDeletion>

    <ModalInformationPrint
      id="modal-information-print"
      :show="showModalInformationPrint"
      :list-size="totalList"
      @close="closeModalInformationPrint"
    />

    <ModalPatientImageInRow
      v-if="selectedRow"
      id="modal-patient-image"
      hide-buttons
      :show="modalPatientImageInRow"
      :fill-id="Number(selectedRow.fil_id)"
      :modId="moduleId"
      view-only
      @close="closeModalPatientImageInRow"
    />

    <ModalPatientDuplicationQueue
      id="modal-patient-duplication-queue"
      :show="modalPatientDuplicationQueue"
      :module-id="moduleId"
      :patient-name="patientDuplicationName"
      :queue-info="queueDuplicationInfo"
      :block-duplication="blockPatientDuplication"
      @save="saveDuplication"
      @close="closeModalPatientDuplicationQueue"
      @cancel="closeModalPatientDuplicationQueue"
    />
  </section>
</template>

<script>
import moment from "moment";

import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson.vue";

import ModalObservationQueue from "~tokio/primitive/modal/modal-observation-queue/ModalObservationQueue";
import ModalConfirmDeletion from "~tokio/primitive/modal/modal-confirm-deletion/ModalConfirmDeletion";
import {
  ModalPatientImageInRow,
  ModalPatientDuplicationQueue,
} from "~tokio/primitive/modal";

import {
  RgInputDate,
  RgSelectUnithealth,
  RgSelectSector,
  RgSelectPlaces,
  RgSelectPriorityQueue,
  RgLessButton,
  RgDropdown,
  IconPrinter,
  IconCard,
} from "~tokio/primitive";

import RgSuggestEmployee from "~tokio/primitive/suggest/rg-suggest-employee/RgSuggestEmployee.vue";
import RgSuggestUser from "~tokio/primitive/suggest/rg-suggest-user/RgSuggestUser.vue";
import RgSuggestCboSector from "~tokio/primitive/suggest/rg-suggest-cbo-sector/RgSuggestCboSector.vue";
import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";

import { RgRadioCustom } from "~tokio/primitive/radio";

import ModalInformationPrint from "~tokio/primitive/modal/modal-information-print/ModalInformationPrint";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import RgSearch from "~tokio/foundation/rg-search/RgSearch";

import { RgSelectProceduresFilter } from "$exam/submodules/schedule/component/";

const FORM_BASE = {
  unitOrigem: null,
  unitDestiny: null,
  sectorDestiny: null,
  placeDestiny: null,
  ocp_nome: "",
  ocp_codigo: "",
  ocp_id: null,
  stp_id: null,
  fun_id_solicitante: 0,
  pes_nome_solicitante: "",
  vin_id_solicitante: 0,
  fun_id: 0,
  pes_nome: "",
  pes_id: null,
  personName: "",
  vin_id: 0,
  usu_id_destiny: 0,
  userDestiny: "",
  unitRequesting: null,
  scheduleDateStart: null,
  scheduleDateEnd: null,
  insertDateStart: null,
  insertDateEnd: null,
  requestingDoctor: null,
  priority: null,
  patient: null,
  usu_id_origin: 0,
  userOrigin: "",
  withPrint: 2,
  bedTypeId: null,
  bedType: null,
};

export default {
  name: "FoundationSearchSchedules",
  components: {
    RgSearch,
    SmartTable,
    RgSelectUnithealth,
    RgSelectSector,
    RgInputDate,
    RgSuggestSmartPerson,
    RgSelectPriorityQueue,
    RgDropdown,
    IconPrinter,
    RgSuggestUser,
    ModalPatientImageInRow,
    RgRadioCustom,
    RgSelectPlaces,
    RgSuggestCboSector,
    RgSuggestEmployee,
    RgSelectProceduresFilter,
    ModalInformationPrint,
    RgLessButton,
    ModalConfirmDeletion,
    ModalObservationQueue,
    ModalPatientDuplicationQueue,
    IconCard,
    RgSuggestTypeBed,
  },

  props: {
    moduleType: {
      type: Number,
      default: 2,
    },
    printHtml: Object,
  },

  data() {
    return {
      mutableList: [],
      totalList: null,
      form: this.$utils.obj.DeepCopy(FORM_BASE),
      selectedRow: null,
      activeRow: null,
      tableHeight: null,
      modalPatientImageInRow: false,
      modalObservationQueue: false,
      showModalInformationPrint: false,
      modalDeleteSchedule: false,
      deleteSchedule: 1,
      modalPatientDuplicationQueue: false,
      patientDuplicationName: "",
      queueDuplicationInfo: "",
      blockPatientDuplication: false,
      reasonReturn: "",
      validateDuplication: true,
    };
  },

  computed: {
    actionColorLine() {
      return {
        get: (item) => {
          return item.pfi_cor;
        },
      };
    },

    permissionScreen() {
      const permission = this.getModuleType().permissionScreen;
      return permission;
    },

    moduleId() {
      return this.getModuleType().moduleId;
    },

    hasMutableList() {
      if (!this.mutableList) {
        return false;
      }
      return this.mutableList.length > 0;
    },

    getSpaceTable() {
      return this.tableHeight;
    },

    permissionSuggest() {
      return this.getModuleType().permissionSuggest;
    },

    propsModalDeleteSchedule() {
      const title = "Excluir o agendamento";
      const subtitle =
        "Escolha entre as opções “Excluir somente o agendamento” ou “Excluir o agendamento e <br> retornar o paciente para a fila”, selecionando um dos filtros abaixo do nome do paciente:";
      const bodyText = this.selectedRow && this.selectedRow.nomePaciente;
      const show = this.modalDeleteSchedule;
      const maxlength = 255;
      const confirm = (reason) => this.deleteScheduledPatientFromQueue(reason);
      return {
        show,
        confirm,
        title,
        subtitle,
        bodyText,
        maxlength,
      };
    },
  },

  watch: {
    "form.unitDestiny"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.sectorDestiny = 0;
        this.form.placeDestiny = 0;
      }
    },

    "form.sectorDestiny"(pValue) {
      if (!pValue || !isNaN(pValue)) {
        this.form.placeDestiny = 0;
        this.form.ocp_nome = "";
        this.form.ocp_codigo = "";
        this.form.ocp_id = 0;
      }
    },

    "form.ocp_nome"() {
      this.form.pes_nome = "";
      this.form.vin_id = 0;
      this.form.fun_id = 0;
    },
  },

  created() {
    this.RADIO_DATA = [
      { title: "Impresso", blue: true, id: 1 },
      { title: "Não Impresso", blue: true, id: 0 },
    ];
    this.RADIO_DATA_DELETE = [
      { title: "Somente excluir", blue: true, id: 1 },
      { title: "Excluir e retornar para a fila", blue: true, id: 2 },
    ];

    this.COLUMN_APPOINTMENT = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Agendamento", key: "data_agendada" },
      { name: "Unidade de Saúde Destino", key: "unidadeAgendamento" },
      { name: "Setor Destino", key: "set_nome" },
      { name: "Local de Atendimento Destino", key: "lca_nome" },
      { name: "Ocupação", key: "ocp_nome" },
      { name: "Profissional", key: "nomeFuncionarioAgendamento" },
      { name: "Usuário Destino", key: "usuarioAgendamento" },
      { name: "Data de Entrada", key: "fil_data" },
      { name: "Unidade de Saúde Origem", key: "unidadeFila" },
      { name: "Prioridade", key: "pfi_nome", statusColor: true },
      { name: "Usuário Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
      { name: "Impresso", key: "comprovanteImpresso" },
    ];

    this.COLUMN_EXAM = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Agendamento", key: "data_agendada" },
      { name: "Unidade de Saúde Destino", key: "unidadeAgendamento" },
      { name: "Setor Destino", key: "set_nome" },
      { name: "Local de Atendimento Destino", key: "lca_nome" },
      {
        name: "Procedimento",
        key: "stp_novo_nome_procedimento",
        align: "left",
      },
      { name: "Profissional", key: "nomeFuncionarioAgendamento" },
      { name: "Usuário Destino", key: "usuarioAgendamento" },
      { name: "Data de Entrada", key: "fil_data" },
      { name: "Unidade de Saúde Origem", key: "unidadeFila" },
      { name: "Prioridade", key: "pfi_nome", statusColor: true },
      { name: "Usuário Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
      { name: "Impresso", key: "comprovanteImpresso" },
    ];

    this.COLUMN_HOSPITALIZATION = [
      { name: "Paciente", key: "nomePaciente" },
      { name: "Data de Agendamento", key: "data_agendada" },
      { name: "Unidade de Saúde Destino", key: "unidadeAgendamento" },
      { name: "Setor Destino", key: "set_nome" },
      { name: "Local de Atendimento Destino", key: "lca_nome" },
      {
        name: "Tipo de Leito",
        key: "itl_nome",
        align: "left",
      },
      { name: "Profissional", key: "nomeFuncionarioAgendamento" },
      { name: "Usuário Destino", key: "usuarioAgendamento" },
      { name: "Data de Entrada", key: "fil_data" },
      { name: "Unidade de Saúde Origem", key: "unidadeFila" },
      { name: "Prioridade", key: "pfi_nome", statusColor: true },
      { name: "Usuário Origem", key: "usuarioFila" },
      { name: "Unidade de Saúde Solicitante", key: "uns_solicitante" },
      { name: "Profissional Solicitante", key: "nomeFuncionarioSolicitante" },
    ];

    this.TYPE_MODULE = {
      APPOINTMENT: 1,
      EXAM: 2,
      HOSPITALIZATION: 3,
    };
  },

  beforeDestroy() {
    const pathName = this.getModuleType().beforeDestroyPathName;
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      `/${pathName}/schedule/patient-schedule`,
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      const moduleName = this.getModuleType().beforeDestroyRoute;
      this.$store.commit(moduleName);
    }
  },

  mounted() {
    this.fillFilter();
    this.handleAfterSearchFilter();
  },

  methods: {
    closeModalPatientDuplicationQueue() {
      this.modalPatientDuplicationQueue = false;
    },
    saveDuplication() {
      this.modalPatientDuplicationQueue = false;
      this.deleteScheduledPatientFromQueue(this.reasonReturn, false);
    },
    async fillFilter() {
      try {
        const moduleName = this.getModuleType().fillFilterRoute;

        const existFilterData = this.$store.getters[moduleName];

        if (existFilterData) {
          this.$loader.start("Carregando dados da última busca...");

          this.form = {
            ...this.form,
            ...existFilterData,
          };

          this.form.unitDestiny = existFilterData.unitDestiny;
          this.form.sectorDestiny = existFilterData.sectorDestiny;
          this.$nextTick(() => {
            this.form.placeDestiny = existFilterData.placeDestiny;
          });

          if (this.moduleType === this.TYPE_MODULE.APPOINTMENT) {
            if (this.$refs.Cbo) {
              this.$refs.Cbo.forceSelection({
                ocp_nome: existFilterData.ocp_nome,
                ocp_codigo: existFilterData.ocp_codigo,
                ocp_id: existFilterData.ocp_id,
              });
            }
          }

          if (this.moduleType === this.TYPE_MODULE.EXAM) {
            this.form.stp_id = existFilterData.procedure;
          }

          if (this.$refs.employee) {
            this.$refs.employee.forceSelection({
              pes_nome: existFilterData.pes_nome,
              vin_id: existFilterData.vin_id,
              employeeRelationship: {
                employee: {
                  fun_id: existFilterData.fun_id,
                },
              },
            });
          }

          if (this.$refs.employeeRequest) {
            this.$refs.employeeRequest.forceSelection({
              pes_nome: existFilterData.pes_nome_solicitante,
              vin_id: existFilterData.vin_id_solicitante,
              employeeRelationship: {
                employee: {
                  fun_id: existFilterData.fun_id_solicitante,
                },
              },
            });
          }

          if (this.$refs.userDestiny) {
            this.$refs.userDestiny.forceSelection({
              usu_nome: existFilterData.userDestiny,
              usu_id: existFilterData.usu_id_destiny,
            });
          }

          if (this.$refs.userOrigin) {
            this.$refs.userOrigin.forceSelection({
              usu_nome: existFilterData.userOrigin,
              usu_id: existFilterData.usu_id_origin,
            });
          }

          if (this.$refs.patient && existFilterData.pes_id) {
            this.$refs.patient.fillPatientById(existFilterData.pes_id);
          }

          if (this.$refs.SmartTable) {
            this.$refs.SmartTable.cleanSelectRow();
          }
          await this.$refs.RgSearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    selectingPerson(pValue) {
      const hasPerson = pValue?.source?.pes_id > 0;

      if (hasPerson) {
        const { pes_id } = pValue.source;
        this.form.pes_id = pes_id;
      } else {
        this.form.pes_id = 0;
        this.form.personName = "";
      }
    },

    onInputRadioCustom(item) {
      if (item) {
        this.form.withPrint = item.id;
      } else {
        this.form.withPrint = 2;
      }
    },
    onInputRadioCustomDelete(item) {
      if (item) {
        this.deleteSchedule = item.id;
      } else {
        this.deleteSchedule = 1;
      }
    },

    selectingUser(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id_destiny = usu_id;
        this.form.userDestiny = usu_nome;
      } else {
        this.form.usu_id_destiny = 0;
        this.form.userDestiny = "";
      }
    },

    selectingUserOrigin(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id_origin = usu_id;
        this.form.userOrigin = usu_nome;
      } else {
        this.form.usu_id_origin = 0;
        this.form.userOrigin = "";
      }
    },

    selectingEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome, vin_id, pes_id, fun_id } = source;

        this.form.pes_id_employee = pes_id || 0;
        this.form.fun_id = fun_id || 0;

        this.form.pes_nome = pes_nome;
        this.form.vin_id = vin_id;
      } else {
        this.form.fun_id = 0;
        this.form.vin_id = 0;
        this.form.pes_nome = "";
      }
    },

    selectingRequestEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_nome, vin_id } = source;
        const existEmployeeData =
          source.employeeRelationship && source.employeeRelationship.employee;

        this.form.fun_id_solicitante =
          !!existEmployeeData && existEmployeeData.fun_id
            ? existEmployeeData.fun_id
            : 0;
        this.form.pes_nome_solicitante = pes_nome;
        this.form.vin_id_solicitante = vin_id;
      } else {
        this.form.fun_id_solicitante = 0;
        this.form.vin_id_solicitante = 0;
        this.form.pes_nome_solicitante = "";
      }
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      try {
        if (hasSuggestion) {
          this.$loader.start();
          this.form.ocp_id = pValue.source.ocp_id;
          this.form.ocp_nome = pValue.source.ocp_nome;
          this.form.ocp_codigo = pValue.source.ocp_codigo;
        } else {
          this.form.ocp_id = 0;
          this.form.ocp_nome = "";
          this.form.ocp_codigo = "";
        }
      } finally {
        this.$loader.finish();
      }
    },

    async selectRowData(pItem, pIndex) {
      this.selectedRow = pItem;
      this.activeRow = pIndex;
    },

    validateColor(pItem) {
      return pItem && pItem.comprovanteImpresso === "NÃO"
        ? "#CCCCCC"
        : "#1E88A9";
    },

    itensActionOptionsPrint(row) {
      const list = [
        {
          label: "Lista de Pacientes Agendados",
          action: () => this.printPatientsList(),
        },
        {
          label: "Comprovante de Agendamento",
          disable: !this.selectedRow,
          action: () => {
            if (!this.selectedRow) {
              this.$toaster.warning("Por favor, selecione um paciente");
              return;
            }
            this.printScheduleVoucher();
          },
        },
      ];

      if (this.moduleType === this.TYPE_MODULE.APPOINTMENT) {
        list.push({
          label: "Ficha de Autorização de Consulta",
          disable: !this.selectedRow,
          action: () => {
            if (!this.selectedRow) {
              this.$toaster.warning("Por favor, selecione um paciente");
              return;
            }
            this.printConsultationAuthorization();
          },
        });
      }
      if (this.moduleType === this.TYPE_MODULE.HOSPITALIZATION) {
        list.push({
          label: "Ficha de Autorização de Internação",
          disable: !this.selectedRow,
          action: () => {
            if (!this.selectedRow) {
              this.$toaster.warning("Por favor, selecione um paciente");
              return;
            }
            this.printHospitalizationAuthorization();
          },
        });
      }

      return list;
    },

    itensActionOptionsPerson(row) {
      return [
        {
          label: "Arquivos do paciente na fila",
          action: () => {
            this.modalPatientImageInRow = true;
          },
        },
        {
          label: "Observações do paciente na fila",
          action: this.openModalObservationQueue,
        },
      ];
    },

    openModalObservationQueue() {
      this.modalObservationQueue = true;
    },

    closeModalObservationQueue() {
      this.modalObservationQueue = false;
    },

    actionsModalDuplicity(data) {
      const { exception } = data;

      const EXCEPTION_BLOCK_EXAME =
        "BloquearDuplicidadeAoInserirFilaExameException";
      const EXCEPTION_INFORM_EXAME =
        "InformarDuplicidadeAoInserirFilaExameException";
      const EXCEPTION_BLOCK_CONSULTA =
        "BloquearDuplicidadeAoInserirFilaConsultaException";
      const EXCEPTION_INFORM_CONSULTA =
        "InformarDuplicidadeAoInserirFilaConsultaException";
      const EXCEPTION_BLOCK_HOSPITALIZATION =
        "BloquearDuplicidadeAoInserirFilaInternacaoException";
      const EXCEPTION_INFORM_HOSPITALIZATION =
        "InformarDuplicidadeAoInserirFilaInternacaoException";

      const isExceptionDuplicityExam =
        exception === EXCEPTION_BLOCK_EXAME ||
        exception === EXCEPTION_INFORM_EXAME;
      const isExceptionDuplicityAppointment =
        exception === EXCEPTION_BLOCK_CONSULTA ||
        exception === EXCEPTION_INFORM_CONSULTA;
      const isExceptionDuplicityHospitalization =
        exception === EXCEPTION_BLOCK_HOSPITALIZATION ||
        exception === EXCEPTION_INFORM_HOSPITALIZATION;

      if (isExceptionDuplicityExam) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.nomePaciente;
        this.queueDuplicationInfo = this.selectedRow.stp_novo_nome_procedimento;

        if (exception === EXCEPTION_INFORM_EXAME) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK_EXAME) {
          this.blockPatientDuplication = true;
        }
      }
      if (isExceptionDuplicityAppointment) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.nomePaciente;
        this.queueDuplicationInfo = this.selectedRow.ocp_nome;

        if (exception === EXCEPTION_INFORM_CONSULTA) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK_CONSULTA) {
          this.blockPatientDuplication = true;
        }
      }
      if (isExceptionDuplicityHospitalization) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.selectedRow.nomePaciente;
        this.queueDuplicationInfo = this.selectedRow.itl_nome;

        if (exception === EXCEPTION_INFORM_HOSPITALIZATION) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK_HOSPITALIZATION) {
          this.blockPatientDuplication = true;
        }
      }
    },

    async deleteScheduledPatientFromQueue(pReason, validarDuplicidade = true) {
      try {
        this.$loader.start();
        this.reasonReturn = pReason;
        this.validateDuplication = validarDuplicidade;
        const atualDate = moment(new Date(), "DD/MM/YYYY HH:mm");
        const dateSchedule = moment(
          this.selectedRow.data_agendada,
          "DD/MM/YYYY HH:mm",
        );
        const typeDate = moment(atualDate).isAfter(dateSchedule)
          ? "retroativa"
          : "futura";

        const path = this.getModuleType().deleteScheduledPatientFromQueue;

        let variables = {
          strMotivo: pReason,
          voltarPraFila: this.deleteSchedule !== 1,
          validarDuplicidade: validarDuplicidade,
        };

        if (this.moduleType === this.TYPE_MODULE.APPOINTMENT) {
          variables = {
            intIdConsulta: Number(this.selectedRow.con_id),
            blnPermissaoNormal: true,
            blnPermissaoReserva: true,
            strTipoData: typeDate,
            ...variables,
          };
        } else if (this.moduleType === this.TYPE_MODULE.EXAM) {
          variables = {
            intIdExameAgendamentoProcedimento: Number(this.selectedRow.eap_id),
            ...variables,
          };
        } else if (this.moduleType === this.TYPE_MODULE.HOSPITALIZATION) {
          variables = {
            intIdInternacao: Number(this.selectedRow.int_id),
            ...variables,
          };
        }
        await this.$store.dispatch(path, variables);
        await this.$refs.RgSearch.performSearch();
        this.$toaster.success("Operação realizada com sucesso");
        this.reasonReturn = "";
      } catch (pErr) {
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          if (this.validateDuplication) {
            this.actionsModalDuplicity(esus_response);
            if (this.modalPatientDuplicationQueue) return;
          }

          this.$toaster.error(
            esus_response.message,
            "Não foi possível realizar a operação",
          );

          return;
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Não foi possível realizar operação",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },

    getPermissionOperation(pSectorId) {
      const subModuleOperationNormal = "consulta.agendamento.AgendamentoFuturo";

      const subModuleOperationTechnical =
        "consulta.agendamento.agendamentoFuturoReservaTecnica";

      const normalPermission = this.$Permissions.sector.has(
        subModuleOperationNormal,
        pSectorId,
      );

      const technicalPermission = this.$Permissions.sector.has(
        subModuleOperationTechnical,
        pSectorId,
      );

      return { normalPermission, technicalPermission };
    },

    openModalDeleteSchedule() {
      this.modalDeleteSchedule = true;
    },

    closeModalDeleteSchedule() {
      if (!this.modalPatientDuplicationQueue) {
        this.deleteSchedule = 1;
      }
      this.modalDeleteSchedule = false;
    },

    closeModalInformationPrint() {
      this.showModalInformationPrint = false;
    },

    async printPatientsList() {
      if (this.totalList > 1000) {
        this.showModalInformationPrint = true;
        return;
      }

      const payload = {
        arrFormData: {
          ...this.getPayloadFilter(this.form),
          fil_comprovante_impresso: 2,
          cli_id: this.$store.getters["Login/GET_USER_ID_CLIENT"],
        },
        modulo: this.getModuleType().moduleId,
      };

      this.$loader.start("Preparando impressão...");
      const html = await this.printHtml.patientsList(payload, this.selectedRow);
      this.$utils.print.printHtml(html);
      this.$loader.finish();
    },

    async printScheduleVoucher() {
      let payload = {};
      if (this.moduleType === this.TYPE_MODULE.APPOINTMENT) {
        payload = {
          intIdConsulta: this.selectedRow.con_id,
        };
      } else if (this.moduleType === this.TYPE_MODULE.EXAM) {
        payload = { ids: [this.selectedRow.eap_id] };
      } else if (this.moduleType === this.TYPE_MODULE.HOSPITALIZATION) {
        payload = {
          intIdInternacao: this.selectedRow.int_id,
        };
      }

      try {
        this.$loader.start("Preparando impressão...");
        const html = await this.printHtml.scheduleVoucher(
          payload,
          this.selectedRow,
        );
        this.$utils.print.printHtml(html);
        this.$loader.finish();
        this.$loader.start("Carregando listagem...");
        await this.$refs.RgSearch.performSearch();
        this.$loader.finish();
      } catch (err) {
        this.$toaster.warning(err);
      } finally {
        this.$loader.finish();
      }
    },

    async printConsultationAuthorization() {
      try {
        const payload = {
          intIdConsulta: this.selectedRow.con_id,
        };

        this.$loader.start("Preparando impressão...");
        const html = await this.printHtml.consultationAuthorization(
          payload,
          this.selectedRow,
        );
        this.$utils.print.printHtml(html);
      } catch (err) {
        this.$toaster.warning(err);
      } finally {
        this.$loader.finish();
      }
    },
    async printHospitalizationAuthorization() {
      try {
        const payload = {
          intIdInternacao: Number(this.selectedRow.int_id),
        };

        this.$loader.start("Preparando impressão...");
        const html = await this.printHtml.hospitalizationAuthorization(
          payload,
          this.selectedRow,
        );
        this.$utils.print.printHtml(html);
      } catch (err) {
        this.$toaster.warning(err);
      } finally {
        this.$loader.finish();
      }
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    actionAfterSearchFilter() {
      this.handleAfterSearchFilter();
    },

    handleAfterSearchFilter() {
      if (this.$refs.RgSearch?.$refs.listContent) {
        const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;
        this.tableHeight = searchArea - 40;

        if (this.$refs.smartTable) {
          this.$refs.smartTable.cleanSelectRow();
          this.$refs.smartTable.setScrollTopZero();
        }

        this.selectedLine = null;
        this.selectedRow = null;
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          ...this.getPayloadFilter(this.form),
        },
      };

      const moduleName = this.getModuleType().generateFilterRoute;

      this.$store.commit(moduleName, this.$utils.obj.DeepCopy(this.form));

      return variables;
    },

    actionSearchFilter(pData) {
      try {
        const path = this.getModuleType().actionSearchFilterRoute;

        return this.$store.dispatch(path, pData);
      } catch (pErr) {
        this.$toaster.error(pErr);
      }
    },

    getCountValue(pValue) {
      this.totalList = Number(pValue) || 0;
    },

    actionCleanForm() {
      this.mutableList = [];
      this.selectedRow = null;
      this.activeRow = null;
      this.form = this.$utils.obj.DeepCopy(FORM_BASE);

      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }

      const moduleName = this.getModuleType().actionCleanFormRoute;
      this.$store.commit(moduleName);

      if (this.$refs.RgRadioCustom) {
        this.$refs.RgRadioCustom.forceUpdate();
      }
    },

    getPayloadFilter(filter) {
      return {
        pfi_id: this.form.priority,
        set_id: this.form.sectorDestiny,
        lca_id: this.form.placeDestiny,
        ocp_id: this.form.ocp_id,
        stp_id: this.form.stp_id,
        usu_id_agendamento: this.form.usu_id_destiny,
        pes_id: this.form.pes_id,

        periodoFinal: this.form.scheduleDateEnd,
        periodoInicial: this.form.scheduleDateStart,
        periodoFilaFinal: this.form.insertDateEnd,
        periodoFilaInicial: this.form.insertDateStart,

        fun_id_solicitante: this.form.fun_id_solicitante,
        fun_id:
          this.moduleType === this.TYPE_MODULE.HOSPITALIZATION
            ? this.form.pes_id_employee
            : this.form.fun_id,

        uns_id: this.form.unitOrigem,
        uns_id_destino: this.form.unitDestiny,
        uns_solicitante: this.form.unitRequesting,

        fil_id_agendado_por: this.form.usu_id_origin,
        fil_comprovante_impresso: this.form.withPrint,
        smd_id: this.getModuleType().smd_id,

        ilt_id: this.form.bedTypeId,
      };
    },

    verifyScheduleDate(pValue, pErrors) {
      const initialDate = moment(this.form.scheduleDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.scheduleDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    verifyRegulationDate(pValue, pErrors) {
      const initialDate = moment(this.form.insertDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.insertDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    selectingBedType(pBedType) {
      const source = pBedType && pBedType.source;
      if (source) {
        this.form.bedTypeId = source.itl_id;
      } else {
        this.form.bedTypeId = null;
      }
    },

    getModuleType() {
      switch (this.moduleType) {
        case this.TYPE_MODULE.APPOINTMENT:
          return {
            smartTableName: "AppointmentFoundationSearchSchedules",
            columnSmartTable: this.COLUMN_APPOINTMENT,
            permissionScreen:
              "consulta.buscaPacientesAgendadosFilaConsulta.Exibir",
            permissionSuggest: ["appointment"],
            moduleId: this.$utils.app.ModulesId.APPOINTMENT,
            beforeDestroyPathName: "appointment",
            beforeDestroyRoute:
              "Appointment/Schedule/RESET_FILTER_SCHEDULE_PATIENT_LIST",
            fillFilterRoute:
              "Appointment/Schedule/GET_FILTER_SCHEDULE_PATIENT_LIST",
            generateFilterRoute:
              "Appointment/Schedule/SET_FILTER_SCHEDULE_PATIENT_LIST",
            actionSearchFilterRoute:
              "Appointment/Schedule/SCHEDULE_PATIENT_APPOINTMENT",
            deleteScheduledPatientFromQueue:
              "Appointment/Schedule/DELETE_SCHEDULE_QUEUE",
            actionCleanFormRoute:
              "Appointment/Schedule/RESET_FILTER_SCHEDULE_PATIENT_LIST",
            smd_id: 160,
          };
        case this.TYPE_MODULE.EXAM:
          return {
            smartTableName: "ExamFoundationSearchSchedules",
            columnSmartTable: this.COLUMN_EXAM,
            permissionScreen: "exame2.buscaPacientesAgendadosFilaExames.exibir",
            permissionSuggest: ["exame2"],
            moduleId: this.$utils.app.ModulesId.EXAM,
            beforeDestroyPathName: "exam",
            beforeDestroyRoute:
              "Exam/Schedule/RESET_FILTER_SCHEDULE_PATIENT_LIST",
            fillFilterRoute: "Exam/Schedule/GET_FILTER_SCHEDULE_PATIENT_LIST",
            generateFilterRoute:
              "Exam/Schedule/SET_FILTER_SCHEDULE_PATIENT_LIST",
            actionSearchFilterRoute: "Exam/Schedule/SCHEDULE_PATIENT_EXAM",
            deleteScheduledPatientFromQueue:
              "Exam/Schedule/DELETE_SCHEDULE_QUEUE",
            actionCleanFormRoute:
              "Exam/Schedule/RESET_FILTER_SCHEDULE_PATIENT_LIST",
            smd_id: 164,
          };
        case this.TYPE_MODULE.HOSPITALIZATION:
          return {
            smartTableName: "HospitalizationFoundationSearchSchedules",
            columnSmartTable: this.COLUMN_HOSPITALIZATION,
            permissionSuggest: ["hospitalization"],
            moduleId: this.$utils.app.ModulesId.HOSPITALIZATION,
            beforeDestroyPathName: "hospitalization",
            beforeDestroyRoute:
              "Hospitalization/Queue/RESET_FILTER_SCHEDULE_PATIENT_LIST",
            fillFilterRoute:
              "Hospitalization/Queue/GET_FILTER_SCHEDULE_PATIENT_LIST",
            generateFilterRoute:
              "Hospitalization/Queue/SET_FILTER_SCHEDULE_PATIENT_LIST",
            actionSearchFilterRoute:
              "Hospitalization/Queue/SCHEDULE_PATIENT_HOSPITALIZATION",
            deleteScheduledPatientFromQueue:
              "Hospitalization/Queue/DELETE_SCHEDULE_QUEUE",
            actionCleanFormRoute:
              "Hospitalization/Queue/RESET_FILTER_SCHEDULE_PATIENT_LIST",
            smd_id: 161,
          };
      }
    },
  },
};
</script>
