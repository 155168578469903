<template>
  <Modulebox
    :title="isEdition ? `Editar Fila` : `Inserir na Fila`"
    class="hospitalization-registration-queue"
  >
    <div class="hospitalization-registration-queue-body">
      <form class="form-container" @submit.stop.prevent="registerQueue">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente" class="module-patient">
            <div class="content-inputs">
              <RgSuggestPatientInformation
                id="patient"
                ref="patientInfoSuggest"
                v-model="suggestPatient"
                :rules="{ forceSelection: true, required: true }"
                :disabledOnlySuggest="isEdition"
                :with-patient-module="['hospitalization']"
                :pac_prontuario_unico="
                  patientInfo && patientInfo.prontuario_unico
                "
                :enabled-patient="false"
                :is-patient="false"
                :extra-data="['withoutAddress']"
                :disabled="disabledByModal"
                :withPatientHospitalization="true"
                class="patient-input"
                @disabledByModalComponents="disabledByModalComponents"
                @patientInfo="getPatientData"
              />
            </div>
          </FormBase>

          <FormBase title="Dados da Solicitação" class="module-solicitation">
            <div class="content-grid">
              <div class="content-grid row-1">
                <RgInputDate
                  id="entry-date"
                  v-model="form.entryDate"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Data da Entrada"
                />

                <RgInputHour
                  id="entry-hour"
                  v-model="form.entryHour"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Hora da Entrada"
                />

                <RgSuggestEmployee
                  id="employee"
                  ref="employee"
                  v-model="requestingProfessional"
                  label="Profissional Solicitante"
                  :disabled="
                    !hasIdPatient ||
                    isEdition ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{ disable: !hasIdPatient || isEdition }"
                  :rules="{
                    required: true,
                    forceSelection: enableFreeRequesterEmployee,
                  }"
                  :maxlength="100"
                  :autoSelectIfHasOne="enableFreeRequesterEmployee"
                  placeholder="Digite o profissional solicitante"
                  notNecessaryBond
                  filterWithoutBond
                  @selected="selectingProfessional"
                  @clear="form.classCouncil = null"
                />

                <RgInput
                  id="council"
                  v-model="form.classCouncil"
                  label="Conselho de Classe"
                  :rules="{ required: !form.requestingProfessionalId }"
                  :disabled="
                    disableClassCouncil ||
                    isEdition ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable:
                      disableClassCouncil ||
                      isEdition ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :maxlength="20"
                  placeholder="Digite o conselho de classe"
                />
              </div>

              <div class="content-grid row-2">
                <RgSelectUnithealthLegacy
                  id="unit-solicitation"
                  ref="unitSolicitation"
                  v-model="form.originUnit"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{ disable: !hasIdPatient }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  :rules="{ required: true }"
                  :subModuleId="subModuleId"
                  label="Unidade de Saúde Solicitante"
                />

                <RgSelectPriority
                  id="priority"
                  ref="priority"
                  v-model="form.priority"
                  :rules="{ required: true }"
                  :module-id="hospitalizationModuleId"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  :class="{ disable: !hasIdPatient }"
                  label="Prioridade"
                  class="inputitem"
                />

                <RgInputDate
                  id="request-date"
                  ref="date"
                  v-model="form.requestDate"
                  :rules="{ maxTodayDate: true, required: true }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  :disabled="
                    !hasIdPatient ||
                    isEdition ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable:
                      !hasIdPatient ||
                      isEdition ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :max-date="new Date()"
                  label="Data do Pedido"
                />
              </div>
            </div>

            <div class="area" :class="{ disable: !hasIdPatient }">
              <div class="cbo-area">
                <p class="label">Tipos de Leito</p>
                <div v-if="queuesPerPerson.length > 0" class="table-area">
                  <div
                    v-for="(queuePerPerson, id) in queuesPerPerson"
                    :key="id"
                    class="item"
                  >
                    {{ queuePerPerson.nome }}
                  </div>
                </div>
                <div v-else class="empty-result">
                  <IconEmpty />
                  <span>Nenhum tipo de leito encontrado</span>
                </div>
              </div>

              <div class="text-area">
                <RgTextArea
                  v-if="!isEdition"
                  id="observations"
                  ref="observations"
                  v-model="form.observations"
                  :maxlength="500"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{ disable: !hasIdPatient }"
                  label="Observações"
                  class="textarea"
                />

                <RgTextArea
                  v-if="!isEdition"
                  id="reasons"
                  ref="reason"
                  v-model="form.reasons"
                  :maxlength="500"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{ disable: !hasIdPatient }"
                  label="Motivo"
                  class="textarea"
                />
              </div>
            </div>

            <RgCleanButton
              v-show="!isEdition"
              id="clear-btn"
              large
              class="clean-btn"
              title="Limpar"
              :disabled="disabledByModal || disabledByModalComponent"
              @click="showConfirmFieldClearing('solicitation')"
            />
          </FormBase>

          <FormBase title="Internação" class="module-regulation">
            <div class="content-grid">
              <div class="content-grid row-1">
                <RgSuggestTypeBed
                  id="type-bed"
                  ref="typeBed"
                  v-model="form.bedType"
                  :rules="{ required: true, forceSelection: true }"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  @selected="selectingBedType"
                />

                <RgSelectWaitingPlace
                  id="waiting-place"
                  v-model="form.waitingPlace"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{ disable: !hasIdPatient }"
                  label="Local de Espera"
                />

                <RgSelectUnithealthExternal
                  id="unit-insertion"
                  ref="unitInsertion"
                  v-model="form.requesterUnit"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  label="Unidade Externa Solicitante"
                />
              </div>

              <div class="row-2 content-grid">
                <RgSuggestHospitalizationProcedureCid
                  id="mainProcedure"
                  ref="mainProcedure"
                  v-model="form.mainProcedure"
                  :unit-id="unitHealthId"
                  :cid-code="form.mainCidCode"
                  :patient-gender="patientInfo.sex_sigla"
                  :patientBirthDate="patientInfo.pes_nascimento"
                  :rules="{ forceSelection: true, fn: procedureAlreadyExists }"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{
                    disable:
                      !hasIdPatient ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Digite o procedimento"
                  label="Procedimento Principal"
                  @selected="selectingMainProcedure"
                />

                <RgSuggestCid
                  id="mainCid"
                  ref="mainCid"
                  v-model="form.mainCid"
                  :rules="{ forceSelection: true, fn: cidAlreadyExists }"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{
                    disable:
                      !hasIdPatient ||
                      disabledByModal ||
                      disabledByModalComponent,
                  }"
                  :patient-gender="patientInfo.sex_sigla"
                  :procedureCode="form.mainProcedureCode"
                  data-id="CID"
                  label="CID Principal"
                  @selected="selectingMainCid"
                />
              </div>

              <div class="row-3 content-grid">
                <RgSuggestHospitalizationProcedureCid
                  id="secondProcedure"
                  ref="secondProcedure"
                  v-model="form.secondProcedure"
                  :unit-id="unitHealthId"
                  :cid-code="form.secondCidCode"
                  :patient-gender="patientInfo.sex_sigla"
                  :patientBirthDate="patientInfo.pes_nascimento"
                  :rules="{ forceSelection: true, fn: procedureAlreadyExists }"
                  :disabled="
                    !hasIdPatient ||
                    disabledByModal ||
                    disabledByModalComponent ||
                    !form.mainProcedure
                  "
                  :class="{
                    disable:
                      !hasIdPatient ||
                      disabledByModal ||
                      disabledByModalComponent ||
                      !form.mainProcedure,
                  }"
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Digite o procedimento"
                  label="Procedimento Secundário"
                  @selected="selectingSecondProcedure"
                />

                <RgSuggestCid
                  id="secondCid"
                  ref="secondCid"
                  v-model="form.secondCid"
                  :rules="{ forceSelection: true, fn: cidAlreadyExists }"
                  :maxlength="500"
                  :disabled="
                    !hasIdPatient ||
                    !hasMainCid ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: !hasIdPatient || !hasMainCid,
                  }"
                  :patient-gender="patientInfo.sex_sigla"
                  :procedureCode="form.secondProcedureCode"
                  data-id="CID"
                  label="CID Secundário"
                  @selected="selectingSecondCid"
                />
              </div>

              <div class="row-4 content-grid">
                <RgSuggestHospitalizationProcedureCid
                  id="thirdProcedure"
                  ref="thirdProcedure"
                  v-model="form.thirdProcedure"
                  :unit-id="unitHealthId"
                  :cid-code="form.thirdCidCode"
                  :patient-gender="patientInfo.sex_sigla"
                  :patientBirthDate="patientInfo.pes_nascimento"
                  :rules="{ forceSelection: true, fn: procedureAlreadyExists }"
                  :disabled="
                    !hasIdPatient ||
                    disabledByModal ||
                    disabledByModalComponent ||
                    !form.secondProcedure
                  "
                  :class="{
                    disable:
                      !hasIdPatient ||
                      disabledByModal ||
                      disabledByModalComponent ||
                      !form.secondProcedure,
                  }"
                  data-id="procedimento"
                  data-item="procedimento-item"
                  placeholder="Digite o procedimento"
                  label="Procedimento Terciário"
                  @selected="selectingThirdProcedure"
                />

                <RgSuggestCid
                  id="thirdCid"
                  ref="thirdCid"
                  v-model="form.thirdCid"
                  :rules="{ forceSelection: true, fn: cidAlreadyExists }"
                  :disabled="
                    !hasIdPatient ||
                    !hasSecondCid ||
                    disabledByModal ||
                    disabledByModalComponent
                  "
                  :class="{
                    disable: !hasIdPatient || !hasSecondCid,
                  }"
                  :patient-gender="patientInfo.sex_sigla"
                  :procedureCode="form.thirdProcedureCode"
                  data-id="CID"
                  label="CID Terciário"
                  @selected="selectingThirdCid"
                />
              </div>
            </div>
            <RgCleanButton
              v-show="!isEdition"
              id="clear-btn"
              large
              class="clean-btn"
              title="Limpar"
              :disabled="disabledByModal || disabledByModalComponent"
              @click="showConfirmFieldClearing('hospitalization')"
            />
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="footer">
      <RgCancelButton
        v-if="showBackButton"
        id="cancel-btn"
        ref="cancelButton"
        medium
        class="cancel"
        :disabled="disabledByModal || disabledByModalComponent"
        @click="cancel"
      />

      <RgSaveButton
        id="save-btn"
        medium
        class="save"
        :disabled="
          !hasIdPatient ||
          disabledSave ||
          disabledByModal ||
          disabledByModalComponent
        "
        @click="saveForm(false)"
      />

      <MediumButton
        v-if="!isEdition"
        id="save-archive-btn"
        title="Salvar e inserir arquivo"
        medium
        class="save"
        label="Salvar e inserir arquivo"
        :disabled="
          !hasIdPatient ||
          disabledSave ||
          disabledByModal ||
          disabledByModalComponent
        "
        @click="saveForm(true)"
      />
    </div>

    <ModalConfirmFieldClearing
      id="modal-confirm-clearing"
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />

    <ModalTypePrintInsertQueue
      id="modal-type-print"
      :show="modalPrint"
      :filId="fil_id"
      @close="closeModalPrint"
    />

    <ModalPatientImageInRow
      id="modal-patient-image-row"
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="Number(fil_id)"
      :modId="hospitalizationModuleId"
      @close="closeModalPatientImageInRow"
    />

    <ModalPatientDuplicationQueue
      id="modal-patient-duplication-queue"
      :show="modalPatientDuplicationQueue"
      :module-id="hospitalizationModuleId"
      :patient-name="patientDuplicationName"
      :queue-info="queueDuplicationInfo"
      :block-duplication="blockPatientDuplication"
      @save="saveDuplication"
      @close="closeModalPatientDuplicationQueue"
      @cancel="closeModalPatientDuplicationQueue"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  RgSuggestPatientInformation,
  RgInputDate,
  RgInput,
  RgValidatorForm,
  RgSelectUnithealthLegacy,
  RgSuggestEmployee,
  RgInputHour,
  RgTextArea,
  RgCancelButton,
  RgSelectPriority,
  MediumButton,
  RgSuggestCid,
  IconEmpty,
} from "~tokio/primitive";
import {
  ModalPatientImageInRow,
  ModalPatientDuplicationQueue,
} from "~tokio/primitive/modal";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import ModalTypePrintInsertQueue from "$hospitalization//hospitalizations/queue/component/modal/modal-type-print-insert-queue/ModalTypePrintInsertQueue";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import RgSuggestTypeBed from "$hospitalization/common/component/suggest/rg-suggest-type-bed/RgSuggestTypeBed";
import RgSelectWaitingPlace from "$hospitalization/hospitalizations/queue/component/select/wating-place/RgSelectWaitingPlace";
import RgSelectUnithealthExternal from "$hospitalization/hospitalizations/queue/component/select/unithealth-external/RgSelectUnithealthExternal";
import { mapGetters } from "vuex";
import RgSuggestHospitalizationProcedureCid from "$hospitalization/common/component/suggest/rg-suggest-hospitalization-procedure-cid/RgSuggestHospitalizationProcedureCid";
export default {
  name: "HospitalizationInsertQueue",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    ModalConfirmFieldClearing,
    RgSuggestPatientInformation,
    RgInputDate,
    RgInput,
    RgSelectUnithealthLegacy,
    RgSuggestEmployee,
    RgSelectPriority,
    RgInputHour,
    RgSaveButton,
    RgCleanButton,
    RgTextArea,
    RgCancelButton,
    ModalTypePrintInsertQueue,
    RgSuggestTypeBed,
    RgSelectWaitingPlace,
    RgSelectUnithealthExternal,
    RgSuggestCid,
    ModalPatientDuplicationQueue,
    MediumButton,
    ModalPatientImageInRow,
    IconEmpty,
    RgSuggestHospitalizationProcedureCid,
  },
  data: () => {
    return {
      dataPatientQueue: null,
      patientInfo: {},
      suggestPatient: null,
      requestingProfessional: null,
      modalConfirmFieldClearing: false,
      modalPrint: false,
      modalPatientImageInRow: false,
      showBackButton: true,
      dataLoadAlter: null,
      fil_id: 0,
      form: {
        patientId: null,
        entryDate: moment().format("DD/MM/YYYY"),
        entryHour: moment().format("HH:mm:ss"),
        bedType: null,
        waitingPlace: null,
        originUnit: null,
        mainProcedureId: null,
        secondProcedureId: null,
        thirdProcedureId: null,
        mainProcedureCode: null,
        secondProcedureCode: null,
        thirdProcedureCode: null,
        mainProcedure: null,
        secondProcedure: null,
        thirdProcedure: null,
        mainCidId: null,
        secondCidId: null,
        thirdCidId: null,
        mainCidCode: null,
        secondCidCode: null,
        thirdCidCode: null,
        mainCid: null,
        secondCid: null,
        thirdCid: null,
        requestingProfessionalId: null,
        classCouncil: null,
        requesterUnit: null,
        priority: null,
        requestDate: "",
        observations: null,
        reasons: null,
      },
      disableClassCouncil: true,
      disabledByModalComponent: false,
      bedTypeName: null,
      modalPatientDuplicationQueue: false,
      patientDuplicationName: "",
      queueDuplicationInfo: "",
      itl_id: null,
      queuesPerPerson: [],
      personData: null,
      selectedTypeBed: null,
      validateDuplication: true,
      inp_novo_codigo_procedimento: null,
      isClearSolicitation: false,
      blockPatientDuplication: false,
      patientGender: false,
      patientBirthDate: false,
      disabledSave: false,
    };
  },

  computed: {
    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    disabledByModal() {
      return (
        this.modalConfirmFieldClearing ||
        this.modalPrint ||
        this.modalPatientImageInRow ||
        this.modalPatientDuplicationQueue
      );
    },

    hasIdPatient() {
      return Boolean(
        Number.isInteger(this.patientInfo.pes_id) && this.suggestPatient,
      );
    },

    isEdition() {
      return this.personData !== null;
    },

    hospitalizationModuleId() {
      return this.$store.state.Login.route_module_map.hospitalization;
    },

    hasMainProcedure() {
      return this.form.mainProcedure;
    },

    hasSecondProcedure() {
      return this.form.secondProcedure;
    },

    mainProcedureId() {
      return this.form.mainProcedureId;
    },

    secondProcedureId() {
      return this.form.secondProcedureId;
    },

    thirdProcedureId() {
      return this.form.thirdProcedureId;
    },

    hasMainCid() {
      return this.form.mainCid;
    },

    hasSecondCid() {
      return this.form.secondCid;
    },

    mainCidId() {
      return this.form.mainCidId;
    },

    secondCidId() {
      return this.form.secondCidId;
    },

    thirdCidId() {
      return this.form.thirdCidId;
    },

    subModuleId() {
      return 32;
    },

    enableFreeRequesterEmployee() {
      const preference = !!Number(
        this.$store.getters["Login/GET_PREFERENCES"][
          "tViewInternacao.int_habilita_campo_livre_prof_solicitante"
        ],
      );
      return !preference;
    },

    isRequiredCns() {
      const required = this.$store.getters["Login/GET_PREFERENCES"][
        "tViewInternacao.rbt_cns_fila_internacao"
      ];
      return required === "1";
    },
  },

  watch: {
    mainProcedureId(pValue, pPrev) {
      if (!pValue && pPrev && pPrev > 0) {
        const cleanProcedureAndCid = 1;
        this.cleanProcedures(cleanProcedureAndCid);
        this.cleanCids(cleanProcedureAndCid);
      }
    },

    secondProcedureId(pValue, pPrev) {
      if (!pValue && pPrev && pPrev > 0) {
        const cleanProcedureAndCid = 2;
        this.cleanProcedures(cleanProcedureAndCid);
        this.cleanCids(cleanProcedureAndCid);
      }
    },

    thirdProcedureId(pValue, pPrev) {
      if (!pValue && pPrev && pPrev > 0) {
        const cleanProcedureAndCid = 3;
        this.cleanProcedures(cleanProcedureAndCid);
        this.cleanCids(cleanProcedureAndCid);
      }
    },

    mainCidId(pValue, pPrev) {
      if (!pValue && pPrev && pPrev > 0) {
        const cleanProcedureAndCid = 1;
        this.cleanCids(cleanProcedureAndCid);
      }
    },

    secondCidId(pValue, pPrev) {
      if (!pValue && pPrev && pPrev > 0) {
        const cleanProcedureAndCid = 2;
        this.cleanCids(cleanProcedureAndCid);
      }
    },

    thirdCidId(pValue, pPrev) {
      if (!pValue && pPrev && pPrev > 0) {
        const cleanProcedureAndCid = 3;
        this.cleanCids(cleanProcedureAndCid);
      }
    },
  },

  async mounted() {
    if (!this.isEdition) {
      this.form.originUnit = String(this.unitHealthId);
    }

    if (this.lastPersonIdSaved) {
      this.$refs.patientInfoSuggest.fillPatientById(this.lastPersonIdSaved);
    }

    const fromRegulation =
      this.$route.name === "hospitalization.queue.queue-insert";
    if (fromRegulation) {
      this.showBackButton = false;
    }

    const lineSelected = await this.$store.getters[
      "Hospitalization/Queue/GET_SELECTED_EXTERNAL_HOSPITALIZATION_RESULT"
    ];

    if (lineSelected) {
      this.dataLoadAlter = lineSelected[0];
    }

    if (this.dataLoadAlter) {
      await this.loadDataForEditing();
    }
  },

  beforeDestroy() {
    const validateIfExistInsert = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue/insert",
      this.$route.meta.breadcrumb,
    );

    const validateIfExistEdit = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/queue/edit-insert",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExistEdit && !validateIfExistInsert) {
      this.$store.commit(
        "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
      );
    }
  },

  methods: {
    closeModalPatientDuplicationQueue() {
      this.modalPatientDuplicationQueue = false;
    },

    saveDuplication() {
      this.modalPatientDuplicationQueue = false;
      this.validateDuplication = false;
      this.registerQueue(this.insertImageInRow);
    },

    async actionsModalDuplicity(data) {
      const { exception } = data;

      const EXCEPTION_BLOCK =
        "BloquearDuplicidadeAoInserirFilaInternacaoException";
      const EXCEPTION_INFORM =
        "InformarDuplicidadeAoInserirFilaInternacaoException";

      const isExceptionDuplicityHospitalization =
        exception === EXCEPTION_BLOCK || exception === EXCEPTION_INFORM;

      if (isExceptionDuplicityHospitalization) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.suggestPatient;
        this.queueDuplicationInfo = this.bedTypeName;

        if (exception === EXCEPTION_INFORM) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK) {
          this.blockPatientDuplication = true;
        }
      }
    },

    disabledByModalComponents(disabledByModal) {
      this.disabledByModalComponent = disabledByModal;
      return this.disabledByModalComponent;
    },

    cleanProcedures(pProcedureClean) {
      const isProcedureOne = pProcedureClean === 1;
      const isProcedureTwo = pProcedureClean === 2;
      const isProcedureThree = pProcedureClean === 3;

      if (isProcedureOne) {
        this.form.mainProcedure = null;
        this.form.mainProcedureId = null;
        this.form.mainProcedureCode = null;

        this.form.secondProcedure = null;
        this.form.secondProcedureId = null;
        this.form.secondProcedureCode = null;

        this.form.thirdProcedure = null;
        this.form.thirdProcedureId = null;
        this.form.thirdProcedureCode = null;

        this.$refs.mainProcedure.cleanValidate();
        this.$refs.secondProcedure.cleanValidate();
        this.$refs.thirdProcedure.cleanValidate();
      }

      if (isProcedureTwo) {
        this.form.secondProcedure = null;
        this.form.secondProcedureId = null;
        this.form.secondProcedureCode = null;

        this.form.thirdProcedure = null;
        this.form.thirdProcedureId = null;
        this.form.thirdProcedureCode = null;

        this.$refs.secondProcedure.cleanValidate();
        this.$refs.thirdProcedure.cleanValidate();
      }

      if (isProcedureThree) {
        this.form.thirdProcedure = null;
        this.form.thirdProcedureId = null;
        this.form.thirdProcedureCode = null;

        this.$refs.thirdProcedure.cleanValidate();
      }
    },

    cleanCids(pCidClean) {
      const isCidOne = pCidClean === 1;
      const isCidTwo = pCidClean === 2;
      const isCidThree = pCidClean === 3;

      if (isCidOne) {
        this.form.mainCid = null;
        this.form.mainCidId = null;
        this.form.mainCidCode = null;

        this.form.secondCid = null;
        this.form.secondCidId = null;
        this.form.secondCidCode = null;

        this.form.thirdCid = null;
        this.form.thirdCidId = null;
        this.form.thirdCidCode = null;

        this.$refs.mainCid.cleanValidate();
        this.$refs.secondCid.cleanValidate();
        this.$refs.thirdCid.cleanValidate();
      }

      if (isCidTwo) {
        this.form.secondCid = null;
        this.form.secondCidId = null;
        this.form.secondCidCode = null;

        this.form.thirdCid = null;
        this.form.thirdCidId = null;
        this.form.thirdCidCode = null;

        this.$refs.secondCid.cleanValidate();
        this.$refs.thirdCid.cleanValidate();
      }

      if (isCidThree) {
        this.form.thirdCid = null;
        this.form.thirdCidId = null;
        this.form.thirdCidCode = null;

        this.$refs.thirdCid.cleanValidate();
      }
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    async saveForm(insertImageInRow) {
      this.insertImageInRow = insertImageInRow;
      const validadeForm = await this.isFormValid();

      if (!validadeForm) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      if (this.isRequiredCns && !this.patientInfo.crs_numero) {
        this.$toaster.error(
          "O paciente informado não possui CNS, atualize o Cadastro do paciente.",
          "O CNS é obrigatório para inserir na fila de regulação",
        );
        return;
      }

      this.registerQueue(insertImageInRow);

      return true;
    },

    async registerQueue(insertImageInRow) {
      try {
        this.disabledSave = true;
        this.$loader.start();
        this.fil_id = 0;

        const variables = {
          itl_nome: this.bedTypeName,
          flt_id_internacoes_tipos_leito: this.itl_id,
          flt_id_internacoes_locais_espera: this.form.waitingPlace
            ? Number(this.form.waitingPlace)
            : null,
          flt_id_internacoes_unidades_solicitantes:
            this.form.requesterUnit || null,
          flt_id_internacoes_procedimentos_1: this.form.mainProcedureId || null,
          flt_id_internacoes_procedimentos_2:
            this.form.secondProcedureId || null,
          flt_id_internacoes_procedimentos_3:
            this.form.thirdProcedureId || null,
          flt_id_cid10_1: this.form.mainCidId || null,
          flt_id_cid10_2: this.form.secondCidId || null,
          flt_id_cid10_3: this.form.thirdCidId || null,
          fil_data_pedido: this.form.requestDate,
          fil_id_funcionarios: this.form.requestingProfessionalId,
          fil_conselho_classe: this.form.classCouncil,
          fil_id_unidades_saude: this.unitHealthId,
          fil_id_unidades_saude_solicitante: Number(this.form.originUnit),
          fil_id_pessoas: this.patientInfo.pes_id,
          fil_id_prioridades_fila: Number(this.form.priority),
          fil_id_agendado_por: this.userLogin.user.usu_id,
          fio_observacao: this.form.observations,
          fio_motivo: this.form.reasons,
        };

        if (!this.form.requestingProfessionalId) {
          delete variables.fil_id_funcionarios;
          variables.fil_funcionario_externo = this.requestingProfessional;
        }

        if (this.isEdition) {
          variables.fil_ordem_regulada = this.dataLoadAlter.fil_ordem_regulada;
          variables.flt_id = Number(this.dataLoadAlter.flt_id);

          await this.$store.dispatch(
            "Hospitalization/Queue/EDIT_HOSPITALIZATION_QUEUE",
            {
              variables,
              validarDuplicidade: this.validateDuplication,
            },
          );

          this.$router.go(-1);
        } else {
          const data = await this.$store.dispatch(
            "Hospitalization/Queue/ADD_HOSPITALIZATION_QUEUE",
            {
              variables,
              validarDuplicidade: this.validateDuplication,
            },
          );

          this.fil_id = Number(data.dados);
          this.modalPrint = true;

          if (insertImageInRow) {
            this.modalPatientImageInRow = true;
          }
        }

        this.$toaster.success("Operação realizada com sucesso");

        this.$store.commit(
          "Hospitalization/Queue/UNSELECT_QUEUE_EXTERNAL_HOSPITALIZATION_DATA",
        );

        this.clearForm(false);
      } catch (error) {
        const { esus_response } = error;

        if (esus_response?.hasError) {
          if (this.validateDuplication) {
            this.actionsModalDuplicity(esus_response);
            if (this.modalPatientDuplicationQueue) return;
          }

          this.$toaster.error(
            "Não foi possível realizar operação: ",
            esus_response.message,
          );

          return;
        } else {
          this.$toaster.error(
            "Não foi possível realizar operação: ",
            this.$utils.sanitize.formatError(error),
          );
        }
      } finally {
        await this.searchQueueHospitalizationPerPerson();
        this.$loader.finish();
        this.disabledSave = false;
      }
    },

    async searchQueueHospitalizationPerPerson() {
      try {
        this.$loader.start();
        this.queuesPerPerson = await this.$store.dispatch(
          "Hospitalization/Queue/SEARCH_HOSPITALIZATION_QUEUES_PER_PERSON",
          { intIdPessoa: this.patientInfo.pes_id },
        );
      } catch (pErr) {
        this.$toaster.error(
          "Não foi possível buscar o paciente ",
          this.$utils.sanitize.formatError(pErr),
        );
      } finally {
        this.$loader.finish();
      }
    },

    async loadDataForEditing() {
      try {
        this.$loader.start();

        const { flt_id } = this.dataLoadAlter;

        this.dataPatientQueue = await this.$store.dispatch(
          "Hospitalization/Queue/GET_DATA_PATIENT_HOSPITALIZATION_QUEUE",
          { flt_id },
        );

        this.personData = await this.$store.dispatch(
          "Hospitalization/Queue/GET_PATIENT_BASIC_INFO",
          { pesId: this.dataPatientQueue.fil_id_pessoas },
        );

        await this.fillPatientInfo(this.personData);

        await this.$refs.patientInfoSuggest.fillPatientById(
          this.personData.pes_id,
        );

        const queueDate = this.dataPatientQueue.fil_data;
        const splittedQueueDate = queueDate.split(" ");
        const hasQueueDate = splittedQueueDate && splittedQueueDate.length > 0;

        this.form.entryDate = hasQueueDate ? splittedQueueDate[0] : null;
        this.form.entryHour = hasQueueDate ? splittedQueueDate[1] : null;

        const hasExternalProfessional =
          this.dataPatientQueue.fil_funcionario_externo &&
          !this.dataPatientQueue.objFuncionario.fun_id;

        if (hasExternalProfessional) {
          this.requestingProfessional = this.dataPatientQueue.fil_funcionario_externo;
        } else {
          const employeeData = this.dataPatientQueue.objFuncionario;
          const employeeRelationships = employeeData.objVinculo.vin_vinculos;

          const hasEmployeeRelationships =
            Array.isArray(employeeRelationships) &&
            employeeRelationships.length > 0;

          if (hasEmployeeRelationships) {
            const vinId = employeeRelationships[0].vin_id;

            await this.$refs.employee.forceSelection({
              pes_nome: employeeData.pes_nome,
              vin_id: Number(vinId),
            });
          }

          this.requestingProfessional = this.dataPatientQueue.objFuncionario.pes_nome;
        }

        if (this.dataPatientQueue?.fil_id_funcionarios) {
          this.form.requestingProfessionalId = this.dataPatientQueue?.fil_id_funcionarios;
        }

        this.disableClassCouncil = !!this.dataPatientQueue.fil_conselho_classe;
        this.form.classCouncil = this.dataPatientQueue.fil_conselho_classe;

        this.form.originUnit = this.dataPatientQueue.fil_id_unidades_saude_solicitante;
        this.form.priority = this.dataPatientQueue.fil_id_prioridades_fila;
        this.form.requestDate = this.dataPatientQueue.fil_data_pedido;

        const bedType = this.dataPatientQueue.objTipoLeito;
        if (bedType) {
          this.form.bedType = bedType.itl_nome;
          this.form.bedTypeName = bedType.itl_nome;
          await this.$refs.typeBed.forceSelection(bedType);
        }

        const waitingPlace = this.dataPatientQueue.objLocalEspera;
        if (waitingPlace) {
          this.form.waitingPlace = waitingPlace.ile_id;
        }

        this.form.requesterUnit = this.dataPatientQueue.flt_id_internacoes_unidades_solicitantes;

        const mainProcedure = this.dataPatientQueue.objProcedimento1;
        if (mainProcedure) {
          this.form.mainProcedureId = mainProcedure.inp_id;
          this.form.mainProcedureCode =
            mainProcedure.inp_novo_codigo_procedimento;
          const procedure = {
            inp_id: mainProcedure.inp_id,
            inp_novo_codigo_procedimento:
              mainProcedure.inp_novo_codigo_procedimento,
            inp_novo_nome_procedimento:
              mainProcedure.inp_novo_nome_procedimento,
          };
          await this.$refs.mainProcedure.forceSelection(procedure);
        }

        const secondProcedure = this.dataPatientQueue.objProcedimento2;
        if (secondProcedure) {
          this.form.secondProcedureId = secondProcedure.inp_id;
          this.form.secondProcedureCode =
            secondProcedure.inp_novo_codigo_procedimento;
          const procedure = {
            inp_id: secondProcedure.inp_id,
            inp_novo_codigo_procedimento:
              secondProcedure.inp_novo_codigo_procedimento,
            inp_novo_nome_procedimento:
              secondProcedure.inp_novo_nome_procedimento,
          };
          await this.$refs.secondProcedure.forceSelection(procedure);
        }

        const thirdProcedure = this.dataPatientQueue.objProcedimento3;
        if (thirdProcedure) {
          this.form.thirdProcedureId = thirdProcedure.inp_id;
          this.form.thirdProcedureCode =
            thirdProcedure.inp_novo_codigo_procedimento;
          const procedure = {
            inp_id: thirdProcedure.inp_id,
            inp_novo_codigo_procedimento:
              thirdProcedure.inp_novo_codigo_procedimento,
            inp_novo_nome_procedimento:
              thirdProcedure.inp_novo_nome_procedimento,
          };
          await this.$refs.thirdProcedure.forceSelection(procedure);
        }

        const mainCid = this.dataPatientQueue.objCid10_1;
        if (mainCid) {
          this.form.mainCidId = mainCid.cid_id;
          this.form.mainCidCode = mainCid.cid_codigo;
          const cid = {
            cid_id: mainCid.cid_id,
            cid_codigo: mainCid.cid_codigo,
            cid_nome: mainCid.cid_nome,
          };
          await this.$refs.mainCid.forceSelection(cid);
        }

        const secondCid = this.dataPatientQueue.objCid10_2;
        if (secondCid) {
          this.form.secondCidId = secondCid.cid_id;
          this.form.secondCidCode = secondCid.cid_codigo;
          const cid = {
            cid_id: secondCid.cid_id,
            cid_codigo: secondCid.cid_codigo,
            cid_nome: secondCid.cid_nome,
          };
          await this.$refs.secondCid.forceSelection(cid);
        }

        const thirdCid = this.dataPatientQueue.objCid10_3;
        if (thirdCid) {
          this.form.thirdCidId = thirdCid.cid_id;
          this.form.thirdCidCode = thirdCid.cid_codigo;
          const cid = {
            cid_id: thirdCid.cid_id,
            cid_codigo: thirdCid.cid_codigo,
            cid_nome: thirdCid.cid_nome,
          };
          await this.$refs.thirdCid.forceSelection(cid);
        }

        this.form.observations = this.dataPatientQueue.objFilaObservacao.fio_observacao;
        this.form.reasons = this.dataPatientQueue.objFilaObservacao.fio_motivo;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    fillProcedures() {
      const maxProcedure = 3;

      for (let i = 1; i <= maxProcedure; i++) {
        const hasProcedure =
          this.dataPatientQueue[`flt_id_internacoes_procedimentos_${i}`]
            .length > 0;

        if (hasProcedure) {
          const {
            inp_id,
            inp_novo_nome_procedimento,
            inp_novo_codigo_procedimento,
          } = this.dataPatientQueue[`objProcedimento${i}`];

          this.$refs[`procedure${i}`].forceSelection({
            inp_id,
            inp_novo_nome_procedimento,
            inp_novo_codigo_procedimento,
          });
        }
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async getPatientData(pPerson) {
      try {
        const hasPerson = pPerson && pPerson.pes_id > 0;

        if (hasPerson) {
          const patient = pPerson && pPerson.patient;
          const patientHospitalization = patient.patient_hospitalization;
          const gender =
            pPerson && pPerson.gender
              ? pPerson.gender.sex_sigla
              : pPerson.sex_sigla
              ? pPerson.sex_sigla
              : null;

          this.patientInfo.pac_id = patient.pac_id ? patient.pac_id : null;
          this.patientInfo.pci_id =
            this.patientInfo.pac_id && patientHospitalization
              ? patientHospitalization.pci_id
              : null;
          this.patientInfo.sex_sigla = gender;
          this.patientInfo.pes_nascimento = pPerson.pes_nascimento;

          this.fillPatientInfo(pPerson);

          if (this.isRequiredCns && !this.patientInfo.crs_numero) {
            this.$toaster.error(
              "O paciente informado não possui CNS, atualize o Cadastro do paciente.",
              "O CNS é obrigatório para inserir na fila de regulação",
            );
            return false;
          }
        } else {
          this.patientInfo = {
            pac_id: "",
            pci_id: "",
            sex_sigla: "",
            pes_nascimento: "",
          };
        }

        if (this.patientInfo.pes_id) {
          await this.searchQueueHospitalizationPerPerson();
        }
      } catch (error) {
        console.error(error);
      }
    },

    selectingBedType(pBedType) {
      const source = pBedType && pBedType.source;
      if (source) {
        this.bedTypeName = source.itl_nome;
        this.itl_id = source.itl_id;
      } else {
        this.bedTypeName = null;
        this.itl_id = null;
      }
    },

    cancel() {
      this.$router.push({ name: "hospitalization.queue" });
    },

    selectingMainProcedure(pProcedure) {
      const source = pProcedure.source;
      this.form.mainProcedureId = source ? source.inp_id : null;
      this.form.mainProcedureCode = source
        ? source.inp_novo_codigo_procedimento
        : null;
    },

    selectingSecondProcedure(pProcedure) {
      const source = pProcedure.source;
      this.form.secondProcedureId = source ? source.inp_id : null;
      this.form.secondProcedureCode = source
        ? source.inp_novo_codigo_procedimento
        : null;
    },

    selectingThirdProcedure(pProcedure) {
      const source = pProcedure.source;
      this.form.thirdProcedureId = source ? source.inp_id : null;
      this.form.thirdProcedureCode = source
        ? source.inp_novo_codigo_procedimento
        : null;
    },

    procedureAlreadyExists(pValue, pErrors) {
      if (pValue) {
        const allSelectedProcedures = [];
        const selectedProcedure = pValue.split(" - ")[1];

        if (this.form.mainProcedure)
          allSelectedProcedures.push(this.form.mainProcedure.split(" - ")[1]);

        if (this.form.secondProcedure)
          allSelectedProcedures.push(this.form.secondProcedure.split(" - ")[1]);

        if (this.form.thirdProcedure)
          allSelectedProcedures.push(this.form.thirdProcedure.split(" - ")[1]);

        if (allSelectedProcedures.length > 1) {
          const total = allSelectedProcedures.filter(
            (item) => item === selectedProcedure,
          ).length;

          if (total > 1) {
            pErrors.push("Procedimento já selecionado");
            return false;
          }
        }

        return true;
      }
    },

    selectingMainCid(pCid) {
      const source = pCid.source;
      this.form.mainCidId = source ? source.cid_id : null;
      this.form.mainCidCode = source ? source.cid_codigo : null;
    },

    selectingSecondCid(pCid) {
      const source = pCid.source;
      this.form.secondCidId = source ? source.cid_id : null;
      this.form.secondCidCode = source ? source.cid_codigo : null;
    },

    selectingThirdCid(pCid) {
      const source = pCid.source;
      this.form.thirdCidId = source ? source.cid_id : null;
      this.form.thirdCidCode = source ? source.cid_codigo : null;
    },

    cidAlreadyExists(pValue, pErrors) {
      if (pValue) {
        const allSelectedCids = [];
        const selectedCid = pValue.split(" - ")[1];
        if (this.form.mainCid)
          allSelectedCids.push(this.form.mainCid.split(" - ")[1]);
        if (this.form.secondCid)
          allSelectedCids.push(this.form.secondCid.split(" - ")[1]);
        if (this.form.thirdCid)
          allSelectedCids.push(this.form.thirdCid.split(" - ")[1]);

        if (allSelectedCids.length > 1) {
          const total = allSelectedCids.filter((item) => item === selectedCid)
            .length;

          if (total > 1) {
            pErrors.push("CID10 já selecionado");
            return false;
          }
        }
        return true;
      }
    },

    selectingProfessional(pProfessional) {
      const source = pProfessional && pProfessional.source;
      if (source) {
        if (
          source.employeeRelationship &&
          source.employeeRelationship.vin_id_funcionarios
        ) {
          this.form.requestingProfessionalId =
            source.employeeRelationship.vin_id_funcionarios;
        }
        this.form.classCouncil = source.fun_conselho_classe;
        this.disableClassCouncil = !!source.fun_conselho_classe;
      } else {
        this.form.requestingProfessionalId = null;
      }
    },

    showConfirmFieldClearing(pForm) {
      this.isClearSolicitation = pForm === "solicitation";
      this.modalConfirmFieldClearing = true;
    },

    confirmCleanForm() {
      this.isClearSolicitation
        ? this.clearSolicitation()
        : this.clearHospitalization();

      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    closeModalPrint() {
      this.modalPrint = false;
      this.fil_id = 0;
    },

    fillPatientInfo(pData) {
      this.patientInfo = {
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pac_nome: pData.pes_nome,
        sex_sigla: pData && pData.gender ? pData.gender.sex_sigla : null,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        pes_nascimento:
          pData && pData.pes_nascimento ? pData.pes_nascimento : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };
    },

    clearSolicitation() {
      this.form.entryDate = moment().format("DD/MM/YYYY");
      this.form.entryHour = moment().format("HH:mm:ss");
      this.form.requestingProfessionalId = null;
      this.form.classCouncil = null;
      this.form.originUnit = String(this.unitHealthId);
      this.form.priority = null;
      this.form.requestDate = null;
      this.form.observations = null;
      this.form.reasons = null;
      this.requestingProfessional = null;

      if (this.$refs.observations) this.$refs.observations.resetCharacter();
      if (this.$refs.reasons) this.$refs.reasons.resetCharacter();

      this.$refs.employee.cleanValidate();
      this.$refs.date.cleanValidate();
      this.$refs.priority.cleanValidate();
      this.$refs.unitSolicitation.cleanValidate();
    },

    clearHospitalization() {
      this.form.bedType = null;
      this.form.waitingPlace = null;
      this.form.requesterUnit = null;
      this.form.mainProcedure = null;
      this.form.secondProcedure = null;
      this.form.thirdProcedure = null;
      this.form.mainProcedureId = null;
      this.form.secondProcedureId = null;
      this.form.thirdProcedureId = null;
      this.form.mainProcedureCode = null;
      this.form.secondProcedureCode = null;
      this.form.thirdProcedureCode = null;
      this.form.mainCid = null;
      this.form.secondCid = null;
      this.form.thirdCid = null;
      this.form.mainCidId = null;
      this.form.secondCidId = null;
      this.form.thirdCidId = null;
      this.form.mainCidCode = null;
      this.form.secondCidCode = null;
      this.form.thirdCidCode = null;

      this.bedTypeName = null;

      this.$refs.typeBed.cleanValidate();
      this.$refs.unitInsertion.cleanValidate();
    },

    clearForm(pCleanPatient = true) {
      this.clearSolicitation();
      this.clearHospitalization();

      if (pCleanPatient) {
        this.$refs.patientInfoSuggest.cleanPatient();
        this.$refs.patientInfoSuggest.cleanValidate();
      }

      this.queuesPerPerson = [];
      this.modalConfirmFieldClearing = false;

      this.$refs.patientInfoSuggest.cleanValidate();
    },
  },
};
</script>
