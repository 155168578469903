<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListEmployeeBySectorOccupation from "./action/ListEmployeeBySectorOccupation";
export default {
  name: "RgSelectEmployeeBySectorOccupationLegacy",
  extends: RgSelect,
  props: {
    sectorId: {
      required: true,
      type: Number,
      defualt: 0,
    },
    occupationId: {
      required: true,
      type: Number,
      defualt: 0,
    },
    concatSectorAndEmployee: {
      type: Boolean,
      defualt: true,
    },
    label: {
      type: String,
      default: "Profissional",
    },
    filtering: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employees: [],
    };
  },
  computed: {
    hasSectorId() {
      return this.sectorId && this.sectorId > 0;
    },

    hasOccupationId() {
      return this.occupationId && this.occupationId > 0;
    },

    areRequiredFieldsFilled() {
      return this.hasSectorId && this.hasOccupationId;
    },
  },
  watch: {
    employees: function (val) {
      this.updateOptions();
    },
    areRequiredFieldsFilled: async function (pValue) {
      this.mValue = null;
      if (pValue) {
        await this.searchSectors();
      } else {
        this.employees = [];
      }
    },
  },
  methods: {
    getData() {
      return this.employees;
    },
    async searchSectors() {
      const hasRequiredFields = this.sectorId && this.occupationId;
      if (hasRequiredFields) {
        const filter = {
          intIdSetor: this.sectorId,
          intIdOcupacao: this.occupationId,
          blnConcatSetorFuncionario: this.concatSectorAndEmployee ? 1 : 0,
        };

        const data = await ListEmployeeBySectorOccupation(filter);

        this.employees = data.map((item) => {
          return {
            value: item.data,
            label: item.pes_nome,
            vus_id: item.vus_id,
          };
        });
      }
    },
  },
};
</script>
