<template>
  <div class="appointment-patients-externally-scheduled-report">
    <section class="grid">
      <div class="filter-title">
        <span class="title">Dados do Paciente</span>
      </div>

      <div class="selectinput">
        <RgInput
          id="patient-name"
          v-model="form.patientName"
          :disabled="!!form.pac_id"
          :class="{ disable: !!form.pac_id }"
          class="inputitem"
          placeholder="Digite o nome do paciente"
          label="Paciente"
        />
      </div>

      <div class="selectinput">
        <RgSuggestSmartPerson
          id="specific-patient"
          ref="patient"
          v-model="form.specificPatient"
          :disabled="hasPatientName"
          :with-patient-module="permissionSuggest"
          :enabled-patient="false"
          filterOnlyDocument
          class="inputitem"
          @selected="selectingPatient"
        />
      </div>

      <div class="filter-title">
        <span class="title">Dados da Regulação</span>
      </div>

      <div class="selectinput">
        <RgSelectUnithealth
          id="requesting-unit"
          v-model="form.requestingUnit"
          :module-id="moduleId"
          :externally="true"
          label="Unidade de Saúde Solicitante"
          class="inputitem"
          :default-text="'Todas'"
          @change="selectingUnitHealth"
        />
      </div>

      <div class="selectinput">
        <RgSelectOccupationScheduleExternally
          id="occupation-externally"
          v-model="form.occupation"
          default-text="Todas"
          @change="selectingOccupation"
        />
      </div>

      <div class="filter-title">
        <span class="title">Dados do Agendamento</span>
      </div>

      <div class="selectinput">
        <div class="period-date">
          <RgInputDate
            id="initial-date"
            v-model="form.schedulePeriodStart"
            label="Data Inicial"
            class="date"
            :rules="{ fn: verifyScheduleDate }"
          />

          <RgInputDate
            id="final-date"
            v-model="form.schedulePeriodEnd"
            label="Data Final"
            class="date"
            :rules="{ fn: verifyScheduleDate }"
          />
        </div>

        <div class="selectinput">
          <RgSelectExternalLocation
            id="destiny"
            v-model="form.aex_id"
            label="Destino"
            class="inputitem"
            default-text="Todos"
            :id-modulo="moduleId"
            @change="selectingDestiny"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";

import {
  RgInput,
  RgInputDate,
  RgSelectExternalLocation,
  RgSelectUnithealth,
} from "~tokio/primitive";

import RgSuggestSmartPerson from "~tokio/primitive/suggest/rg-suggest-smart-person/RgSuggestSmartPerson";
import RgSelectOccupationScheduleExternally from "$appointment/common/component/rg-select-occupation-schedule-externally/RgSelectOccupationScheduleExternally";

const FORM_FILTER = {
  patient: null,
  patientName: null,
  unitHealth: null,
  unitHealthName: null,
  occupation: null,
  occupationName: null,
  destiny: null,
  destinyName: null,
  schedulePeriodStart: null,
  schedulePeriodEnd: null,
  columnsToPrint: null,
};

export default {
  name: "AppointmentPatientExternallyScheduledReport",
  components: {
    RgInput,
    RgInputDate,
    RgSelectUnithealth,
    RgSuggestSmartPerson,
    RgSelectExternalLocation,
    RgSelectOccupationScheduleExternally,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Pacientes Agendados Externamente",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }"
      );
    },
    getNameSmartTable() {
      return "AppointmentPatientsExternallyScheduledReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    hasPatientName() {
      return this.form.patientName && this.form.patientName.length > 0;
    },
    moduleId() {
      return this.$utils.app.ModulesId.APPOINTMENT;
    },
    permissionSuggest() {
      return ["appointment"];
    },
  },
  watch: {
    "form.patientName"(pValue) {
      if (pValue && pValue.length > 0) {
        this.disabledDocument = true;
      } else {
        this.disabledDocument = false;
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Telefone", key: "TELEFONE", align: "left" },
      {
        name: "Data",
        key: "DATA_AGENDAMENTO",
      },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      {
        name: "Destino",
        key: "DESTINO",
        align: "left",
      },
      {
        name: "Unidade de Saúde Solicitante",
        key: "UNIDADE_SOLICITANTE",
        align: "left",
      },
      {
        name: "Usuário",
        key: "USUARIO",
        align: "left",
      },
    ];
  },
  methods: {
    buildFilter() {
      const payload = {
        patient: this.form.patient,
        patientName: this.form.patientName,
        unitHealth: this.form.unitHealth,
        unitHealthName: this.form.unitHealthName,
        occupation: Number(this.form.occupation),
        occupationName: this.form.occupationName,
        destiny: this.form.destiny,
        destinyName: this.form.destinyName,
        schedulePeriodStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.schedulePeriodStart,
        ),
        schedulePeriodEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.schedulePeriodEnd,
        ),
        columnsToPrint: this.columnsToPrint,
      };

      return payload;
    },

    async doSearch(pData) {
      try {
        this.$loader.start("Carregando...");

        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_PATIENTS_EXTERNALLY_SCHEDULED_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish();
      }
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source && pValue.source.patient) {
        const { pac_id } = pValue.source.patient;
        this.form.patient = pac_id;
      } else {
        this.form.patient = null;
      }
    },

    selectingUnitHealth(pValue) {
      if (pValue) {
        this.form.unitHealthName = pValue.label;
      } else {
        this.form.unitHealthName = null;
      }
    },

    selectingOccupation(pValue) {
      if (pValue) {
        this.form.occupationName = pValue.label;
      } else {
        this.form.occupationName = null;
      }
    },

    selectingDestiny(pValue) {
      if (pValue) {
        this.form.destinyName = pValue.label;
      } else {
        this.form.destinyName = null;
      }
    },

    verifyScheduleDate(pValue, pErrors) {
      const initialDate = moment(this.form.schedulePeriodStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.schedulePeriodEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
  },
};
</script>
