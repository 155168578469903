<template>
  <div v-show="show" class="modal-info-import-sigtap-bdsia">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-history-movement-header">
        <span class="title">
          Siga as orientações de Importação das Tabelas SIGTAP e BDSIA no
          Sistema ESUSNG
        </span>
      </div>
      <div slot="body">
        <div v-if="step === 1">
          <div class="subtitle">Bem-vindo {{ userName }}.</div>
          <div class="content">
            Antes de iniciar a importação das tabelas disponíveis no sistema
            DataSUS para o sistema ESUSNG, é necessário tomar conhecimento das
            condições para realizar o processo.
          </div>
        </div>
        <div v-if="step === 2">
          <div class="subtitle">
            O sistema da Unidade de Saúde será enviado para duas Filas de
            Atendimento:
          </div>
          <div class="content">
            <ul>
              <li>A primeira fila será para o download dos arquivos SIGTAP;</li>
              <li>A segunda fila será para o download dos arquivos BDSIA;</li>
              <li>Aguarde em cada fila a vez da sua Unidade ser atendida;</li>
              <li>
                Cada atendimento possui etapas de implementação da importação;
              </li>
            </ul>
          </div>
          <div class="content-info">
            <strong>
              <i>
                Os dados são disponibilizados pelo DataSUS, podendo apresentar
                falha ou indisponibilidade do serviço.
              </i>
            </strong>
          </div>
        </div>
        <div v-if="step === 3">
          <div class="subtitle">
            Cada Atendimento (SIGTAP e BDSIA) possui as seguintes etapas:
          </div>
          <div class="content">
            <ul>
              <li>Download do arquivo no sistema DataSUS;</li>
              <li>Extração das tabelas do arquivo;</li>
              <li>Leitura das tabelas extraídas do arquivo baixado;</li>
              <li>Carregamento das tabelas extraídas no banco de dados;</li>
              <li>Implementação das tabelas SIGTAP no sistema ESUSNG.</li>
            </ul>
          </div>
          <div class="content-info">
            <strong>
              <i>
                Não é possível cancelar ou interromper o atendimento,
                certifique-se da disponibilidade para iniciar o processo.
              </i>
            </strong>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="container-step">
          <span :class="`step ${step === 1 ? 'active' : 'inactive'}`"></span>
          <span :class="`step ${step === 2 ? 'active' : 'inactive'}`"></span>
          <span :class="`step ${step === 3 ? 'active' : 'inactive'}`"></span>
        </div>
        <div class="modal-info-import-sigtap-bdsia-footer">
          <RgCancelButton
            id="cancel-btn"
            label="Cancelar"
            medium
            @click="close"
          />
          <MediumButton
            id="save-btn"
            :label="step < 3 ? 'Próxima Orientação' : 'Iniciar Atendimento'"
            medium
            @click="nextStep"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, MediumButton, RgCancelButton } from "~tokio/primitive";

export default {
  name: "ModalInfoSigtapBdsia",
  components: {
    RgBaseModal,
    MediumButton,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showOld: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      step: 1,
      userName: this.$store.getters["Login/GET_USER_NAME"],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    nextStep() {
      this.step < 3 ? (this.step += 1) : this.save();
    },
    save() {
      this.step = 1;
      this.showOld(2);
    },
    close() {
      this.step = 1;
      this.showOld(0);
    },
  },
};
</script>
