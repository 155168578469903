import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyCentralRequestsTransferReport(
    $unitHealth: Int
    $unitHealthName: String
    $productId: Int
    $productName: String
    $initialDate: String
    $finalDate: String
    $requestingPharmacyId: Int
    $requestingPharmacyName: String
    $pharmacyAttendantId: Int
    $pharmacyAttendantName: String
    $userId: Int
    $userName: String
    $productTypeId: Int
    $productTypeName: String
    $situationOrderId: Int
    $situationOrderName: String
    $situationItem: [Int]
    $serviceInitialDate: String
    $serviceFinalDate: String
    $loggedUserId: Int!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyCentralRequestsTransferReport(
      unitHealthId: $unitHealth
      unitHealthName: $unitHealthName
      productId: $productId
      productName: $productName
      initialDate: $initialDate
      finalDate: $finalDate
      requestingPharmacyId: $requestingPharmacyId
      requestingPharmacyName: $requestingPharmacyName
      pharmacyAttendantId: $pharmacyAttendantId
      pharmacyAttendantName: $pharmacyAttendantName
      userId: $userId
      userName: $userName
      productTypeId: $productTypeId
      productTypeName: $productTypeName
      situationOrderId: $situationOrderId
      situationOrderName: $situationOrderName
      situationItem: $situationItem
      serviceInitialDate: $serviceInitialDate
      serviceFinalDate: $serviceFinalDate
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        NUMERO_SOLICITACAO
        FARMACIA_SOLICITANTE
        FARMACIA_ATENDENTE
        NOME
        NOME_COMERCIAL
        CODIGO
        UNIDADE_SAUDE
        DATA_SOLICITACAO
        USUARIO_SOLICITANTE
        TIPO
        DATA_ATENDIMENTO
        GUIA_REMESSA
        APRESENTACAO
        LOTE_VALIDADE
        SITUACAO_PEDIDO
        SITUACAO_ITEM
        QUANTIDADE_SOLICITADA
        QUANTIDADE_ATENDIDA
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyCentralRequestsTransferReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
