<template lang="html">
  <div v-if="show" class="modal-types-print-insert-queue">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Emissão de Comprovante</div>
      </div>

      <div slot="body" class="body">
        <FormBase class="form-base" title="Emitir Comprovante ?">
          <div class="grid informations">
            <div class="line">
              <RgButton
                id="print-schedule-thermal"
                class="btn-generate"
                title="Clique para imprimir na Impressora Térmica"
                @click="printScheduleNormal(THERMAL)"
              >
                <span class="span">Impressora Térmica</span>
              </RgButton>
            </div>

            <div class="line">
              <RgButton
                id="print-schedule-normal"
                class="btn-generate"
                title="Impressora Normal"
                @click="printScheduleNormal(NORMAL)"
              >
                <span class="span">Impressora Normal</span>
              </RgButton>
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <div class="footer-buttons">
          <RgCancelButton
            id="cancel-btn"
            class="cancel"
            medium
            @click="close"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import ScheduleProofInsertionQueueHTML from "$hospitalization/hospitalizations/queue/html/ScheduleProofInsertionQueueHTML";
import { RgBaseModal, RgCancelButton, RgButton } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalTypePrintInsertQueue",
  components: {
    RgBaseModal,
    FormBase,
    RgCancelButton,
    RgButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    filId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },
  created() {
    this.NORMAL = 1;
    this.THERMAL = 2;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async printScheduleNormal(size) {
      if (this.filId) {
        const idModulo = this.$store.state.Login.route_module_map
          .hospitalization;

        const data = await this.$store.dispatch(
          "Hospitalization/Queue/GET_SCHEDULE_PROOF_INSERTION_QUEUE",
          {
            idModulo: idModulo,
            idFila: this.filId,
          },
        );

        const html = ScheduleProofInsertionQueueHTML(idModulo, data, size);
        this.$utils.print.printHtml(html);
        this.close();
      }
    },
  },
};
</script>
