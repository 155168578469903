import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "farmacia/controller-tipo-transporte/inativar",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data);
    })
    .catch((pErr) => {
      throw pErr;
    });
};
