<template>
  <Modulebox
    :title="
      isEdition
        ? 'Editar Serviços e Classificações da Unidade de Saúde'
        : 'Adicionar Serviços e Classificações para a Unidade de Saúde'
    "
    :disabled="disabledByModal"
    class="classifications"
  >
    <Tooltip
      :message="`Configurações atuais do módulo Faturamento: CNES: ${
        form.cnes || '-'
      } Competência: ${
        competence ? competenceMonth + '/' + competenceYear : 'Não definida'
      }`"
      class="tooltip"
      startOpen
    >
      <IconInfoHelper slot="icon" />
    </Tooltip>
    <main class="classifications-body">
      <FormBase
        title="Dados da Unidade de Saúde"
        class="form-base"
        :class="{ disable: disabledByModal }"
      >
        <div class="first-collumn">
          <RgSelectUnithealth
            id="select-unity"
            v-model="form.unitHealth"
            addCnesLabel
            :label="'CNES'"
            class="inputitem"
            placeholder="0000000"
            permission="faturamento.faturar"
          />
          <RgSelectUnithealth
            id="unit-health"
            ref="unitHealth"
            v-model="form.unitHealth"
            :rules="{ required: true }"
            class="inputitem"
            label="Unidade de Saúde"
            placeholder="Digite o Nome da Unidade"
            permission="faturamento.faturar"
          />

          <RgSelectService
            v-model="form.service"
            :rules="{ required: true }"
            label="Serviço"
            placeholder="Digite o Número ou Nome do Serviço"
            @change="chooseService"
          />
        </div>
      </FormBase>

      <FormBase
        title="Buscar e Importar Classificações"
        class="form-base"
        :class="{ disable: disabledByModal }"
      >
        <RgValidatorForm ref="validator">
          <section class="products-data">
            <RgSelectClassificationByService
              v-model="form.classification"
              label="Busca"
              default-text="Digite o Nome da Classificação"
              :serviceCode="form.service"
              :disabled="!form.service"
              allClassifications
            />
            <div class="actions">
              <RgSearchButton
                id="search"
                ref="searchBtn"
                :disabled="!form.service || disabledByModal"
                :class="{ disable: !form.service }"
                @submit="searchClassification()"
              />
              <RgCleanButton
                id="clean-btn"
                :disabled="!form.service || disabledByModal"
                @click="cleanForm"
              />
              <RgAddButton
                id="add-btn"
                title="Adicionar Classificação"
                :disabled="listClassifications.length === 0 || disabledByModal"
                @click="saveClassification"
              />
            </div>
            <div class="actions">
              <RgLessButton
                id="delete"
                title="Remover Classificação"
                :disabled="
                  listAddClassifications.length === 0 || disabledByModal
                "
                @click="openModalDelete"
              />
            </div>
          </section>
        </RgValidatorForm>

        <section class="product-tables">
          <div>
            <span class="table-title"> Classificações </span>
            <div v-if="mutableClassification.length > 0">
              <SmartTable
                ref="smartTableOne"
                name="smartTableOne"
                :columns="COLUMNS"
                :body="mutableClassification"
                :total="mutableClassification.length"
                :showPagination="false"
                :removeBtnColumns="true"
                :initial-columns="5"
                toggle-selected
                hasCheck
                multSelect
                :disabled="disabledByModal"
                :class="{
                  disable: disabledByModal,
                }"
                class="smart-table-procedure"
                @getMultLines="selectMultiClassifications"
              />
            </div>
            <div v-else class="no-results-empty">
              <IconEmpty />
              <span class="message">{{ msgEmptyClassification }}</span>
            </div>
          </div>
          <div>
            <span class="table-title space">
              Classificações adicionadas para o Serviço selecionado
            </span>
            <div v-if="mutableAddClassification.length > 0">
              <SmartTable
                ref="smartTable"
                name="smartTable"
                :columns="COLUMN_CLASSIFICATION"
                :body="mutableAddClassification"
                :total="mutableAddClassification.length"
                :showPagination="false"
                :removeBtnColumns="true"
                :initial-columns="5"
                toggle-selected
                hasCheck
                multSelect
                :disabled="disabledByModal"
                :class="{
                  disable: disabledByModal,
                }"
                class="smart-table-classification"
                @getMultLines="selectMultiClassificationsTwo"
              />
            </div>
            <div v-else class="no-results-helper">
              <IconInfoHelper class="icon" :background="'#6f8894'" />
              <span class="message">
                {{ msgEmptyAddClassification }}
              </span>
            </div>
          </div>
        </section>
      </FormBase>
    </main>

    <footer class="procedures-footer">
      <div class="actions">
        <MediumButton
          label="Voltar"
          :disabled="disabledByModal"
          @click="goBack"
        />
      </div>
    </footer>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      hasSearch
      @close="closeModalDelete"
    />
  </Modulebox>
</template>

<script>
import { mapGetters } from "vuex";

import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgSelectService from "$billing/submodules/bpa/services-ratings/components/select/rg-select-service/RgSelectService";
import RgSelectClassificationByService from "$billing/submodules/bpa/pep-pending-procedures/components/select/rg-select-classification-by-service/RgSelectClassificationByService";

import {
  RgValidatorForm,
  RgCleanButton,
  RgSelectUnithealth,
  RgSearchButton,
  RgAddButton,
  IconInfoHelper,
  IconEmpty,
  Tooltip,
  MediumButton,
  RgLessButton,
  ModalConfirmDeletion,
} from "~tokio/primitive";

const FORM = {
  cnes: null,
  unitHealth: null,
  service: null,
  classification: null,
};

export default {
  name: "RegisterServicesRatings",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    SmartTable,
    Tooltip,
    RgCleanButton,
    RgSearchButton,
    RgSelectUnithealth,
    RgSelectService,
    IconEmpty,
    IconInfoHelper,
    RgAddButton,
    RgLessButton,
    MediumButton,
    RgSelectClassificationByService,
    ModalConfirmDeletion,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableClassification: [],
      mutableAddClassification: [],
      search: null,
      listClassifications: [],
      listAddClassifications: [],
      competenceMonth: null,
      competenceYear: null,
      serviceName: null,
      indexProcedure: null,
      competence: this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"],
      isEdition: false,
      modalDeleteService: false,
      msgEmptyClassification:
        'PARA EXIBIR RESULTADOS, O "SERVIÇO" DEVE SER PREENCHIDO',
      msgEmptyAddClassification:
        'PARA EXIBIR AS CLASSIFICAÇÕES ADICIONADAS, SELECIONE O "CNES", "UNIDADE DE SAÚDE" E O "SERVIÇO"',
    };
  },
  computed: {
    ...mapGetters({
      userId: "Login/GET_USER_ID",
      currentUnitHealth: "Login/GET_UNIT_HEALTH",
      clientId: "Login/GET_USER_ID_CLIENT",
    }),

    disabledByModal() {
      return this.modalDeleteService;
    },

    propsModalConfirmDeletion() {
      const show = this.modalDeleteService;
      const confirm = () => this.removeClassification();
      const title = `Excluir Classificação do Serviço`;
      const message = `As classificações selecionadas serão removidas para o Serviço:`;
      const msgSuccess = "Classificações removidas com sucesso";
      const noReason = true;

      const bodyText = `${this.serviceName}`;
      const hasSearch = true;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
        hasSearch,
      };
    },
  },

  watch: {
    "form.service"(pValue) {
      if (pValue) {
        this.searchClassification(true);
      } else {
        if (this.$refs.smartTableOne) {
          this.$refs.smartTableOne.cleanMultSelectedRow();
        }
        this.mutableClassification = [];
        if (this.$refs.smartTable) {
          this.$refs.smartTable.cleanMultSelectedRow();
        }
        this.mutableAddClassification = [];
      }
    },
  },
  created() {
    this.COLUMN_CLASSIFICATION = [
      { name: "Código", key: "id", align: "left", active: true },
      {
        name: "Classificação",
        key: "nome",
        align: "left",
        active: true,
      },
      { name: "Número", key: "servico", align: "left", active: true },
      { name: "Serviço", key: "nomeServico", align: "left", active: true },
    ];

    this.COLUMNS = [
      { name: "Código", key: "id", active: true, align: "left" },
      {
        name: "Classificação",
        key: "nome",
        active: true,
        align: "left",
      },
    ];
  },
  mounted() {
    this.competenceMonth = this.competence.substring(4);
    this.competenceYear = this.competence.substring(0, 4);
    this.form.service = this.$route.params.srv;
    this.isEdition = this.$route.params.isEdit;
    this.form.cnes = this.currentUnitHealth.uns_cnes;
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    closeModalDelete() {
      this.modalDeleteService = false;
    },
    openModalDelete() {
      this.modalDeleteService = true;
    },
    async searchClassification(hasLoading = false) {
      try {
        if (this.form) {
          if (hasLoading) this.$loader.start();
          const variables = [
            this.$store.getters["Login/GET_UNIT_HEALTH_ID"].toString(),
            this.form.service,
          ];
          const aux = await this.$store.dispatch(
            "Billing/ServicesRatings/OBTAIN_CLASSIFICATION_BY_SERVICE",
            variables,
          );
          const aux2 = Array.from(aux);
          aux.forEach((element) => {
            element.nomeServico = this.serviceName;
          });
          this.mutableAddClassification = aux2.filter(
            (item) => item.utilizado === "1",
          );
          this.mutableClassification = aux.filter(
            (item) => item.utilizado === "0",
          );
          if (
            this.mutableClassification.length === 0 &&
            this.mutableAddClassification.length > 0
          ) {
            this.msgEmptyClassification =
              "TODAS AS CLASSIFICAÇÕES JÁ FORAM ADICIONADAS";
          }
          if (
            this.mutableClassification.length > 0 &&
            this.mutableAddClassification.length === 0
          ) {
            this.msgEmptyAddClassification = "ADICIONE NOVAS CLASSIFICAÇÕES";
          }
          if (this.form.classification) {
            this.mutableClassification = this.mutableClassification.filter(
              (item) => Number(item.id) === Number(this.form.classification),
            );
          }
        }
      } catch (err) {
        this.$toaster.error("Erro ao buscar as classificações");
      } finally {
        if (hasLoading) this.$loader.finish();
        this.$refs.searchBtn.actionDone();
      }
    },
    chooseService(pValue) {
      this.serviceName = pValue?.label.split("-", 2)[1] || null;
    },

    fillData(existFilterData) {
      this.form.vin_id = existFilterData.vin_id;
      this.form.cbo = existFilterData.cbo;

      this.$refs.employee.forceSelection({
        pes_nome: existFilterData.employee.professionalName,
        vin_id: existFilterData.employee.vin_id,
        fun_id: existFilterData.employee.professionalId,
      });
    },
    selectedEmployee(pValue) {
      if (pValue.source) {
        this.form.employee.professionalName = pValue.source.pes_nome;
        this.form.employee.professionalId = pValue.source.fun_id;
        this.form.employee.vin_id = pValue.source.vin_id;
      } else {
        this.form.employee.professionalName = null;
        this.form.employee.professionalId = null;
        this.form.employee.vin_id = null;
      }
    },

    selectedSector(pValue) {
      if (pValue) {
        this.form.set_nome = pValue.label;
      } else {
        this.form.set_nome = null;
      }
    },
    selectedOccupation(pValue) {
      if (pValue) {
        this.form.cbo_nome = pValue.label;
      } else {
        this.form.cbo_nome = null;
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    selectMultiClassifications(pItem) {
      this.listClassifications = pItem;
    },
    selectMultiClassificationsTwo(pItem) {
      this.listAddClassifications = pItem;
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    verifyDuplicate() {
      const filterDuplicate = this.mutableAddClassification.filter((pData) => {
        this.listClassifications.forEach((item) => {
          if (pData.sus_pa_id_dv === item.sus_pa_id_dv) {
            return true;
          }
        });
      });
      return filterDuplicate;
    },

    async saveClassification() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }
      try {
        this.$loader.start();
        let classificationCode = [];
        this.listClassifications.forEach((item) => {
          classificationCode.push({
            fcu_co_classificacao: item.id,
          });
        });
        this.mutableAddClassification.forEach((item) => {
          classificationCode.push({
            fcu_co_classificacao: item.id,
          });
        });
        classificationCode = classificationCode.filter(
          (este, i) => classificationCode.indexOf(este) === i,
        );
        const variables = {
          arrClassificacao: classificationCode,
          fcu_co_classificacao: 0,
          fcu_co_servico: this.form.service,
          fcu_id: 0,
          fcu_id_unidades_saude: this.form.unitHealth,
        };
        await this.$store.dispatch(
          "Billing/ServicesRatings/REGISTER_CLASSIFICATION",
          variables,
        );

        if (this.$refs.smartTableOne)
          this.$refs.smartTableOne.cleanMultSelectedRow();
        this.listAddClassifications = [];
        this.listClassifications = [];
        this.searchClassification();
        this.$toaster.success("Classificações adicionadas com sucesso");
      } catch (e) {
        this.$toaster.error(
          "Erro na comunicação com o servidor. Tente novamente ou contate o suporte.",
          "Falha ao adicionar as Classificações",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async removeClassification() {
      try {
        this.$loader.start();

        const classificationCode = [];
        this.mutableAddClassification.forEach((item) => {
          let insert = true;
          this.listAddClassifications.forEach((element) => {
            if (item.id === element.id) {
              insert = false;
            }
          });
          if (insert) {
            classificationCode.push({
              fcu_co_classificacao: item.id,
            });
          }
        });

        const variables = {
          arrClassificacao: classificationCode,
          fcu_co_classificacao: 0,
          fcu_co_servico: this.form.service,
          fcu_id: 0,
          fcu_id_unidades_saude: this.form.unitHealth,
        };
        await this.$store.dispatch(
          "Billing/ServicesRatings/REGISTER_CLASSIFICATION",
          variables,
        );
        this.listClassifications = [];
        this.listAddClassifications = [];

        if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
        this.searchClassification();
        this.$toaster.success("Classificações removidas com sucesso");
      } catch (e) {
        this.$toaster.error(
          "Erro na comunicação com o servidor. Tente novamente ou contate o suporte.",
          "Falha ao remover as Classificações",
        );
      } finally {
        this.$loader.finish();
      }
    },

    cleanForm() {
      this.search = null;
      this.form.service = null;
      this.form.classification = null;
      this.mutableClassification = [];
      this.mutableAddClassification = [];
      this.listClassifications = [];
      this.listAddClassifications = [];
      this.msgEmptyClassification =
        'PARA EXIBIR RESULTADOS, O CAMPO "SERVIÇO" DEVE SER PREENCHIDO';
      this.msgEmptyAddClassification =
        'PARA EXIBIR AS CLASSIFICAÇÕES ADICIONADAS, SELECIONE O "CNES", "UNIDADE DE SAÚDE" E O "SERVIÇO"';
    },
  },
};
</script>
