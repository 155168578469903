<template slot="subFilters">
  <div class="pharmacy-satellite-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.mtp_id"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />
        <RgSelectLotExpiration
          ref="lotExpiration"
          v-model="form.rec_lote"
          :pharmacyId="Number(globalFilters.pharmacy)"
          :productId="Number(globalFilters.product)"
          :isEntry="true"
          :disabled="!globalFilters.product || !globalFilters.pharmacy"
          :class="{
            disable: !globalFilters.product || !globalFilters.pharmacy,
          }"
          label="Lote"
          default-text="Todos"
          class="inputitem"
          @change="selectedLotExpiration"
        />
        <RgSuggestPatient
          ref="patient"
          v-model="form.pcf_nome"
          :withInfo="false"
          placeholder="Digite o nome do paciente"
          label="Paciente"
          @selected="getPatientInfo"
        />
        <RgSuggestState
          id="estado"
          ref="state"
          v-model="form.est_nome"
          label="Estado"
          placeholder="Selecione"
          @selected="getStateInfo"
        />

        <RgSuggestCity
          ref="city"
          v-model="form.mun_nome"
          :disabled="!form.est_id"
          :state="form.est_id"
          :class="{ disable: !form.est_id }"
          label="Cidade"
          filterByState
          @selected="getCityInfo"
        />

        <RgSelectPharmacyEmployee
          ref="employee"
          v-model="form.rec_id_funcionarios"
          class="inputitem"
          onlyActive
          @change="selectedEmployee"
        />

        <RgSuggestUser
          id="user"
          v-model="form.usu_nome"
          label="Usuário"
          placeholder="Todos"
          :clientId="clientLoginId"
          @selected="getUserInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration";
import { RgSuggestUser } from "~tokio/primitive";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgSelectPharmacyEmployee from "$pharmacy/submodules/satellite/components/select/rg-select-pharmacy-employee/RgSelectPharmacyEmployee";
import RgSuggestPatient from "$pharmacy/common/suggest/rg-suggest-patient/RgSuggestPatient.vue";
const FORM_FILTER = {
  pcf_id: null,
  pcf_nome: null,
  rec_id_funcionarios: null,
  rec_nome_funcionarios: null,
  rec_lote: null,
  rec_lote_validade_lote: null,
  mtp_id: null,
  mtp_nome: null,
  mun_id: null,
  mun_nome: null,
  est_nome: null,
  fap_id_usuarios: null,
  usu_id: null,
  usu_nome: null,
};

export default {
  name: "PharmacySatelliteOutputReport",
  components: {
    RgSelectProductType,
    RgSelectLotExpiration,
    RgSuggestPatient,
    RgSuggestCity,
    RgSuggestState,
    RgSelectPharmacyEmployee,
    RgSuggestUser,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      userId: this.$store.getters["Login/GET_USER_ID"],
    };
  },
  computed: {
    ...mapGetters({
      clientLoginId: "Login/GET_USER_ID_CLIENT",
      userId: "Login/GET_USER_ID",
    }),
  },
  watch: {
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
    "globalFilters.pharmacy"(pValue) {
      if (!pValue && pValue === null) {
        this.form.rec_lote = null;
      }
    },
    "globalFilters.product"(pValue) {
      if (!pValue && pValue === null) {
        this.form.rec_lote = null;
      }
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE", align: "left" },
      { name: "Farmácia", key: "FARMACIA", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Saída", key: "SAIDA", align: "right" },
      { name: "Dispensado", key: "DISPENSADO", align: "right" },
      { name: "Solicitado", key: "SOLICITADO", align: "right" },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Endereço", key: "ENDERECO", align: "left" },
      { name: "Usuário", key: "USUARIO", align: "left" },
      { name: "Médico Solicitante", key: "MEDICO_SOLICITANTE", align: "left" },
    ]);
  },

  methods: {
    selectedProductType(pValue) {
      if (pValue) {
        this.form.mtp_nome = pValue.mtp_nome;
      } else {
        this.form.mtp_nome = null;
      }
    },
    selectedLotExpiration(pValue) {
      if (pValue) {
        this.form.rec_lote_validade_lote = pValue.label;
      } else {
        this.form.rec_lote_validade_lote = null;
      }
    },
    getPatientInfo(pValue) {
      const source = pValue?.source;

      if (source) {
        this.form.pcf_id = source.pcf_id;
      } else {
        this.form.pcf_id = null;
      }
    },
    getStateInfo(pValue) {
      const source = pValue?.source;
      if (source) {
        this.form.est_id = source.est_id;
      } else {
        this.form.est_id = null;
        this.form.mun_nome = null;
      }
    },
    getCityInfo(pValue) {
      const source = pValue?.source;
      if (source) {
        this.form.mun_id = source.mun_id;
      } else {
        this.form.mun_id = null;
      }
    },
    selectedEmployee(pValue) {
      if (pValue) {
        this.form.rec_nome_funcionarios = pValue.label;
      } else {
        this.form.rec_nome_funcionarios = null;
      }
    },
    getUserInfo(pValue) {
      const source = pValue?.source;
      if (source) {
        this.form.usu_id = source.usu_id;
      } else {
        this.form.usu_id = null;
      }
    },

    async doSearchSubReport(pValue) {
      try {
        const variables = {
          ...this.form,
          mtp_id: Number(this.form.mtp_id),
          pcf_id: Number(this.form.pcf_id),
          rec_id_funcionarios: Number(this.form.rec_id_funcionarios),
          mun_id: Number(this.form.mun_id),
          usu_id: Number(this.form.usu_id),
          fap_id_usuarios: this.userId,
        };
        const globalFilters = {
          ...this.globalFilters,
          initialDate: this.$utils.date.BrazilianDateToDatabase(
            this.globalFilters.initialDate,
          ),
          finalDate: this.$utils.date.BrazilianDateToDatabase(
            this.globalFilters.finalDate,
          ),
          loggedUserId: this.userId,
          pharmacy: Number(this.globalFilters.pharmacy),
          product: Number(this.globalFilters.product),
        };
        const filters = {
          ...globalFilters,
          ...variables,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_SATELLITE_OUTPUT",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
    },
  },
};
</script>
