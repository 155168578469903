<style src="./RgInputCnpj.scss" lang="scss"></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--alert" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            :id="id"
            v-model="inputValue"
            v-mask="'##.###.###/####-##'"
            :tabindex="disabled ? -1 : tabIndex"
            :disabled="disabled"
            placeholder="00.000.000/0001-00"
            type="text"
            :class="{ 'on-error': error.length > 0 }"
            @blur="validateBlur"
          />
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import RgValidationAlert from "~tokio/primitive/validation/rg-validation-alert/RgValidationAlert";
import Cnpj from "./RgInputCnpjHelper";
import { mask } from "vue-the-mask";
import RgInputMixin from "~tokio/primitive/input/mixin/RgInputMixin";
import RgValidatorMixin from "~tokio/primitive/validation/RgValidatorMixin";

export default {
  name: "RgInputCnpj",
  components: {
    RgValidationAlert,
    RgFormBase,
  },
  directives: {
    mask,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    label: {
      type: String,
      default: "CNPJ",
    },
    title: {
      type: String,
      default: "Cadastro Nacional da Pessoa Jurídica",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anotherRules: {
        checkCnpj(pCnpj, pError) {
          return Cnpj.checkDigit(pCnpj, pError);
        },
      },
    };
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  methods: {
    validateBlur() {
      this.validate();
    },
  },
};
</script>
