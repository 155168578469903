import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPersonDuplicateRegistrationReport(
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $filters: [String]
  ) {
    SearchPersonDuplicateRegistrationReport(
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      html
      csv
      excel
      fileName
      count
      rows {
        pes_nome
        pes_mae
        pes_nascimento
        possiveis_duplicidades
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.SearchPersonDuplicateRegistrationReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
