import GetExamQueueListReport from "./GetExamQueueListReport";
import GetExamByDayReport from "./GetExamByDayReport";
import GetExamDayReport from "./GetExamDayReport";
import GetExamUserProductivityReport from "./GetExamUserProductivityReport";
import GetExamSearchScaleExcludedReport from "./GetExamSearchScaleExcludedReport";
import GetExamScheduleExcludedReport from "./GetExamScheduleExcludedReport";
import GetExamDisabledPeriodsReport from "./GetExamDisabledPeriodsReport";
import GetExamScheduleHistoryReport from "./GetExamScheduleHistoryReport";
import GetExamSectorReport from "./GetExamSectorReport";
import GetExamProceduresPerformedReport from "./GetExamProceduresPerformedReport";
import GetExamSubProceduresPerformedReport from "./GetExamSubProceduresPerformedReport";
import GetExamProfessionalProductivityReport from "./GetExamProfessionalProductivityReport";
import GetExamPatientsQueueReport from "./GetExamPatientsQueueReport";
import GetExamPatientsScheduledQueueReport from "./GetExamPatientsScheduledQueueReport";
import GetExamPatientsExcludedQueueReport from "./GetExamPatientsExcludedQueueReport";
import GetExamPatientsExternallyScheduledReport from "./GetExamPatientsExternallyScheduledReport";
import GetExamPatientsPendencyQueueReport from "./GetExamPatientsPendencyQueueReport";
import GetCeilingReport from "./GetCeilingReport";

export default {
  GET_EXAM_QUEUE_LIST_REPORT: GetExamQueueListReport,
  GET_EXAM_DAY_REPORT: GetExamDayReport,
  GET_EXAM_USER_PRODUCTIVITY_REPORT: GetExamUserProductivityReport,
  GET_EXAM_SEARCH_SCALE_EXCLUDED_REPORT: GetExamSearchScaleExcludedReport,
  GET_EXAM_SCHEDULE_EXCLUDED_REPORT: GetExamScheduleExcludedReport,
  GET_EXAM_DISABLED_PERIODS_REPORT: GetExamDisabledPeriodsReport,
  GET_EXAM_SCHEDULE_HISTORY_REPORT: GetExamScheduleHistoryReport,
  GET_EXAM_SECTOR_REPORT: GetExamSectorReport,
  GET_EXAM_PROCEDURES_PERFORMED_REPORT: GetExamProceduresPerformedReport,
  GET_EXAM_SUB_PROCEDURES_PERFORMED_REPORT: GetExamSubProceduresPerformedReport,
  GET_EXAM_PROFESSIONAL_PRODUCTIVITY_REPORT: GetExamProfessionalProductivityReport,
  GET_EXAM_PATIENTS_QUEUE_REPORT: GetExamPatientsQueueReport,
  GET_EXAM_BY_DAY_REPORT: GetExamByDayReport,
  GET_EXAM_PATIENTS_SCHEDULED_QUEUE_REPORT: GetExamPatientsScheduledQueueReport,
  GET_EXAM_PATIENTS_EXCLUDED_QUEUE_REPORT: GetExamPatientsExcludedQueueReport,
  GET_EXAM_PATIENTS_EXTERNALLY_SCHEDULED_REPORT: GetExamPatientsExternallyScheduledReport,
  GET_CEILING_REPORT: GetCeilingReport,
  GET_EXAM_PATIENTS_PENDENCY_QUEUE_REPORT: GetExamPatientsPendencyQueueReport,
};
