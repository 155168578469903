import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchTransferEmployee(
    $pes_nome: String
    $ocp_code: String
    $uns_id: Int
    $searchQuery: String
    $limit: Int
  ) {
    SearchTransferEmployee(
      pes_nome: $pes_nome
      ocp_code: $ocp_code
      uns_id: $uns_id
      searchQuery: $searchQuery
      limit: $limit
    ) {
      count
      rows {
        pes_nome
        ocp_codigo
        cns_profissional
        equ_codigo_ine
      }
    }
  }
`;

export default async ({ variables }) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      fetchPolicy: "no-cache",
      variables,
    }).catch((err) => {
      console.error(err);
    });
    return data.SearchTransferEmployee;
  } catch (err) {
    return err.graphQLErrors;
  }
};
