<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="3.75"
      width="5.3033"
      height="22.981"
      rx="2.65165"
      transform="rotate(-45 0 3.75)"
      :fill="color"
    />
    <rect
      x="16.25"
      width="5.3033"
      height="22.981"
      rx="2.65165"
      transform="rotate(45 16.25 0)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Close",
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
