<template>
  <Modulebox
    :title="isEdition ? `Editar Fila` : `Inserir na Fila`"
    class="appointment-registration-queue"
  >
    <div class="appointment-registration-queue-body">
      <form class="form-container" @submit.stop.prevent="saveForm">
        <RgValidatorForm ref="validator">
          <FormBase title="Paciente" class="module-patient">
            <div class="content-inputs">
              <RgSuggestPatientInformation
                id="patient"
                ref="patientInfoSuggest"
                v-model="suggestPatient"
                :rules="{ forceSelection: true, required: true }"
                :disabledOnlySuggest="isEdition"
                :with-patient-module="['appointment']"
                :pac_prontuario_unico="
                  patientInfo && patientInfo.prontuario_unico
                "
                :enabled-patient="false"
                :is-patient="false"
                :extra-data="['withoutAddress']"
                :disabled="disabledByModal"
                :withPatientAppointment="true"
                class="patient-input"
                @disabledByModalComponents="disabledByModalComponents"
                @patientInfo="getPatientData"
              />
            </div>
          </FormBase>

          <FormBase title="Dados da Regulação" class="module-regulation">
            <div class="content-grid">
              <div class="row-1 content-grid">
                <RgInputDate
                  id="entry-date"
                  v-model="form.entryDate"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Data de Entrada"
                />

                <RgInputHour
                  id="entry-hour"
                  v-model="form.entryHour"
                  :disabled="true"
                  tabIndex="-1"
                  :class="{ disable: true }"
                  label="Hora de Entrada"
                />

                <RgSelectUnitHealthLinkedCbo
                  id="unit-insertion"
                  ref="unitInsertion"
                  v-model="form.originUnit"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  :rules="{ required: true }"
                  label="Unidade de Inserção"
                  @change="selectingOriginUnit"
                />

                <RgSelectOccupationSynonymsUnit
                  id="occupation"
                  ref="cbo"
                  v-model="occupation"
                  :healthUnitId="Number(form.originUnit)"
                  :rules="{ required: true }"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{ disable: !hasIdPatient }"
                  :tabIndex="!hasIdPatient ? '-1' : '0'"
                  with-bond
                  placeholder="Informe a ocupação"
                  label="Ocupação"
                  @change="selectingCbo"
                />
              </div>
            </div>
          </FormBase>

          <FormBase title="Dados da Solicitação" class="module-solicitation">
            <div class="content-grid">
              <RgSuggestEmployee
                id="employee"
                ref="employee"
                v-model="requestingProfessional"
                label="Profissional Solicitante"
                :disabled="
                  !hasIdPatient ||
                  isEdition ||
                  disabledByModal ||
                  disabledByModalComponent
                "
                :class="{ disable: !hasIdPatient || isEdition }"
                :rules="{
                  required: true,
                  forceSelection: enableFreeRequesterEmployee,
                }"
                :maxlength="100"
                :autoSelectIfHasOne="enableFreeRequesterEmployee"
                placeholder="Digite o profissional solicitante"
                notNecessaryBond
                filterWithoutBond
                @selected="selectingProfessional"
                @clear="form.classCouncil = null"
              />

              <RgInput
                id="class-council"
                v-model="form.classCouncil"
                label="Conselho de Classe"
                :rules="{ required: !form.requestingProfessionalId }"
                :disabled="
                  disableClassCouncil ||
                  isEdition ||
                  disabledByModal ||
                  disabledByModalComponent
                "
                :class="{
                  disable:
                    disableClassCouncil ||
                    isEdition ||
                    disabledByModal ||
                    disabledByModalComponent,
                }"
                :maxlength="20"
                placeholder="Digite o conselho de classe"
              />

              <RgSelectUnithealthLegacy
                id="unit-solicitation"
                ref="unitSolicitation"
                v-model="form.requesterUnit"
                :disabled="
                  !hasIdPatient || disabledByModal || disabledByModalComponent
                "
                :class="{ disable: !hasIdPatient }"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :rules="{ required: true }"
                label="Unidade de Saúde Solicitante"
              />

              <RgSelectPriority
                id="priority"
                ref="priority"
                v-model="form.priority"
                :rules="{ required: true }"
                :module-id="2"
                :disabled="
                  !hasIdPatient || disabledByModal || disabledByModalComponent
                "
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :class="{ disable: !hasIdPatient }"
                label="Prioridade"
                class="inputitem"
              />

              <RgInputDate
                id="request-date"
                ref="date"
                v-model="form.requestDate"
                :rules="{ maxTodayDate: true, required: true }"
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :disabled="
                  !hasIdPatient ||
                  isEdition ||
                  disabledByModal ||
                  disabledByModalComponent
                "
                :class="{
                  disable:
                    !hasIdPatient ||
                    isEdition ||
                    disabledByModal ||
                    disabledByModalComponent,
                }"
                :max-date="new Date()"
                label="Data da Solicitação"
              />

              <RgInput
                v-if="isEdition"
                id="name-regulator"
                v-model="dataLoadAlter.nomeRegulador"
                label="Autorizado por"
                :disabled="
                  isEdition || disabledByModal || disabledByModalComponent
                "
                :tabIndex="!hasIdPatient ? '-1' : '0'"
                :class="{ disable: !hasIdPatient || isEdition }"
              />
            </div>

            <div class="area" :class="{ disable: !hasIdPatient }">
              <div class="cbo-area">
                <p class="label">Ocupações</p>
                <div class="table-area">
                  <div
                    v-for="(queuePerPerson, id) in queuesPerPerson"
                    :key="id"
                    class="item"
                  >
                    {{ queuePerPerson.nome }}
                  </div>
                </div>
              </div>

              <div class="text-area">
                <RgTextArea
                  v-if="!isEdition"
                  id="observations"
                  ref="textArea"
                  v-model="form.observations"
                  :maxlength="500"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{ disable: !hasIdPatient }"
                  label="Observações"
                  class="inputbox"
                  is-optional
                />
                <RgTextArea
                  v-if="!isEdition"
                  id="motivations"
                  ref="textArea"
                  v-model="form.motivations"
                  :maxlength="500"
                  :disabled="
                    !hasIdPatient || disabledByModal || disabledByModalComponent
                  "
                  :class="{
                    disable: !hasIdPatient,
                  }"
                  label="Motivo"
                  class="inputbox"
                  is-optional
                />
              </div>
            </div>
          </FormBase>
        </RgValidatorForm>
      </form>
    </div>

    <div class="footer">
      <RgCleanButton
        v-show="!isEdition"
        id="clean-btn"
        small
        class="clean"
        title="Limpar"
        :disabled="disabledByModal || disabledByModalComponent"
        @click="showConfirmFieldClearing"
      />

      <RgCancelButton
        v-if="showBackButton"
        id="cancel-btn"
        ref="cancelButton"
        medium
        class="cancel"
        :disabled="disabledByModal || disabledByModalComponent"
        @click="cancel"
      />

      <RgSaveButton
        id="save-btn"
        medium
        class="save"
        :disabled="
          !hasIdPatient ||
          disabledSave ||
          disabledByModal ||
          disabledByModalComponent
        "
        @click="saveForm(false)"
      />

      <MediumButton
        v-if="!isEdition"
        id="save-insert-archive-btn"
        medium
        class="save"
        label="Salvar e inserir arquivo"
        title="Salvar e inserir arquivo"
        :disabled="
          !hasIdPatient ||
          disabledSave ||
          disabledByModal ||
          disabledByModalComponent
        "
        @click="saveForm(true)"
      />
    </div>

    <ModalConfirmFieldClearing
      id="modal-confirm-clearing"
      ref="confirmClearing"
      :show="modalConfirmFieldClearing"
      @getYes="confirmCleanForm"
      @getOut="closeConfirmFieldClearing"
      @close="closeConfirmFieldClearing"
    />

    <ModalTypePrintInsertQueue
      id="modal-type-print"
      :show="modalPrint"
      :filId="fil_id"
      @close="closeModalPrint"
    />

    <ModalPatientImageInRow
      id="modal-patient-image"
      ref="modalPatientImageInRow"
      :show="modalPatientImageInRow"
      :fill-id="fil_id"
      :modId="appointmentModuleId"
      @close="closeModalPatientImageInRow"
    />

    <RgConfirmModal
      id="modal-confirm"
      :show="modal.showConfirmModal"
      title="Atenção"
      :message="modal.msgModalConfirm"
      @close="handleModal(0)"
      @confirmed="handleModal(1)"
      @denied="handleModal(2)"
    />

    <ModalPatientDuplicationQueue
      id="modal-patient-duplication-queue"
      :show="modalPatientDuplicationQueue"
      :module-id="appointmentModuleId"
      :patient-name="patientDuplicationName"
      :queue-info="queueDuplicationInfo"
      :block-duplication="blockPatientDuplication"
      @save="saveDuplication"
      @close="closeModalPatientDuplicationQueue"
      @cancel="closeModalPatientDuplicationQueue"
    />
  </Modulebox>
</template>
<script>
import {
  RgCleanButton,
  RgSaveButton,
  RgSuggestPatientInformation,
  RgInputDate,
  RgInput,
  RgValidatorForm,
  RgSelectUnithealthLegacy,
  RgSuggestEmployee,
  RgInputHour,
  RgTextArea,
  RgCancelButton,
  RgSelectPriority,
  RgConfirmModal,
  MediumButton,
} from "~tokio/primitive";
import {
  ModalPatientImageInRow,
  ModalPatientDuplicationQueue,
} from "~tokio/primitive/modal";

import moment from "moment";
import FormBase from "~tokio/foundation/form-base/FormBase";
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

import ModalTypePrintInsertQueue from "$appointment/submodules/schedule/component/modal/modal-type-print-insert-queue/ModalTypePrintInsertQueue";
import RgSelectUnitHealthLinkedCbo from "$appointment/submodules/schedule/component/suggest/rg-select-unithealth-linked-cbo/RgSelectUnitHealthLinkedCbo";
import RgSelectOccupationSynonymsUnit from "$appointment/common/component/rg-select-occupation-synonyms-unit/RgSelectOccupationSynonymsUnit";
import ModalConfirmFieldClearing from "~tokio/primitive/modal/modal-confirm-field-clearing/ModalConfirmFieldClearing";
import { mapGetters } from "vuex";
export default {
  name: "AppointmentInsertQueue",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    ModalConfirmFieldClearing,
    RgSuggestPatientInformation,
    RgInputDate,
    RgInput,
    RgSelectUnitHealthLinkedCbo,
    RgSelectUnithealthLegacy,
    RgSuggestEmployee,
    RgSelectPriority,
    RgInputHour,
    RgSaveButton,
    RgCleanButton,
    RgTextArea,
    RgSelectOccupationSynonymsUnit,
    RgCancelButton,
    ModalTypePrintInsertQueue,
    RgConfirmModal,
    MediumButton,
    ModalPatientImageInRow,
    ModalPatientDuplicationQueue,
  },
  data: () => {
    return {
      dataPatientQueue: null,
      appointmentQueueId: null,
      patientInfo: {},
      suggestPatient: null,
      occupation: null,
      requesterUnit: null,
      requestingProfessional: null,
      period: null,
      modalConfirmFieldClearing: false,
      modalPatientImageInRow: false,
      modalPrint: false,
      showBackButton: true,
      dataLoadAlter: null,
      fil_id: 0,
      form: {
        patientId: null,
        entryDate: moment().format("DD/MM/YYYY"),
        entryHour: moment().format("HH:mm:ss"),
        originUnit: null,
        requestingProfessionalId: null,
        requesterUnit: null,
        priority: null,
        requestDate: "",
        observations: "",
        motivations: "",

        occupationName: null,
        classCouncil: null,
        ocpId: null,
      },
      queuesPerPerson: [],
      personData: null,
      modal: {
        showConfirmModal: false,
        msgModalConfirm: null,
      },
      disableClassCouncil: true,
      disabledByModalComponent: false,
      disabledSave: false,
      modalPatientDuplicationQueue: false,
      patientDuplicationName: "",
      queueDuplicationInfo: "",
      blockPatientDuplication: true,
      validateDuplication: true,
      insertImageInRow: false,
    };
  },

  computed: {
    disabledByModal() {
      return (
        this.modal.showConfirmModal ||
        this.modalConfirmFieldClearing ||
        this.modalPatientImageInRow ||
        this.modalPrint
      );
    },
    ...mapGetters({
      userLogin: "Login/GET_COMPLETE_LOGIN_INFO",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
    }),

    isRequiredCns() {
      const required = this.$store.getters["Login/GET_PREFERENCES"][
        "tViewAgendamento.rbt_cns_fila_consulta"
      ];
      return required === "1";
    },

    hasIdPatient() {
      return Boolean(
        Number.isInteger(this.patientInfo.pes_id) && this.suggestPatient,
      );
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    isEdition() {
      return this.personData !== null;
    },

    disableEmployeeFields() {
      return !this.isEdition && !this.form.ocpId;
    },

    isBlockPatientDuplication() {
      return !!Number(
        this.$store.getters["Login/GET_PREFERENCES"][
          "tViewMarcacaoConsulta.flc_bloqueio_inserir_fila"
        ],
      );
    },

    enableFreeRequesterEmployee() {
      const preference = !!Number(
        this.$store.getters["Login/GET_PREFERENCES"][
          "tViewMarcacaoConsulta.con_habilita_campo_livre_prof_solicitante"
        ],
      );
      return !preference;
    },
  },

  watch: {
    suggestPatient(pValue) {
      if (!pValue) {
        this.patientInfo = {};
        this.disableClassCouncil = true;
      }
    },
  },

  async mounted() {
    if (this.lastPersonIdSaved) {
      this.$refs.patientInfoSuggest.fillPatientById(this.lastPersonIdSaved);
    }

    const fromRegulation =
      this.$route.name === "appointment.schedule.queue-insert";
    if (fromRegulation) {
      this.showBackButton = false;
    }

    this.setInitialData();
    const lineSelected = await this.$store.getters[
      "Appointment/Schedule/GET_SELECTED_EXTERNAL_APPOINTMENT_RESULT"
    ];

    if (lineSelected) {
      this.dataLoadAlter = lineSelected[0];
    }

    if (this.dataLoadAlter) {
      await this.loadDataForEditing();
    }
  },

  beforeDestroy() {
    const validateIfExistInsert = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/queue/insert",
      this.$route.meta.breadcrumb,
    );

    const validateIfExistEdit = ValidateIfRouteExistInBreadscrumb(
      "/appointment/schedule/queue/edit-insert",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExistEdit && !validateIfExistInsert) {
      this.$store.commit(
        "Appointment/Schedule/UNSELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
      );
    }
  },

  methods: {
    closeModalPatientDuplicationQueue() {
      this.modalPatientDuplicationQueue = false;
    },

    saveDuplication() {
      this.modalPatientDuplicationQueue = false;
      this.validateDuplication = false;
      this.saveForm(this.insertImageInRow);
    },

    disabledByModalComponents(disabledByModal) {
      this.disabledByModalComponent = disabledByModal;
      return this.disabledByModalComponent;
    },

    closeModalPatientImageInRow() {
      this.modalPatientImageInRow = false;
    },

    async saveForm(insertImageInRow) {
      this.insertImageInRow = insertImageInRow;
      const validadeForm = await this.isFormValid();
      const alertCnsRequired = this.isRequiredCns;

      if (!validadeForm) {
        this.$toaster.warning("Verifique os campos");
        return;
      }

      if (alertCnsRequired && !this.patientInfo.crs_numero) {
        this.$toaster.warning(
          "É obrigatório que o paciente tenha CNS para que seja inserido na fila",
        );
        return;
      }

      this.registerQueue(insertImageInRow);

      return true;
    },

    async actionsModalDuplicity(data) {
      const { exception } = data;

      const EXCEPTION_BLOCK =
        "BloquearDuplicidadeAoInserirFilaConsultaException";
      const EXCEPTION_INFORM =
        "InformarDuplicidadeAoInserirFilaConsultaException";

      const isExceptionDuplicityAppointment =
        exception === EXCEPTION_BLOCK || exception === EXCEPTION_INFORM;

      if (isExceptionDuplicityAppointment) {
        this.modalPatientDuplicationQueue = true;
        this.patientDuplicationName = this.suggestPatient;
        this.queueDuplicationInfo = this.form.occupationName;

        if (exception === EXCEPTION_INFORM) {
          this.blockPatientDuplication = false;
        } else if (exception === EXCEPTION_BLOCK) {
          this.blockPatientDuplication = true;
        }
      }
    },

    async registerQueue(insertImageInRow) {
      try {
        this.disabledSave = true;
        this.$loader.start();
        this.fil_id = 0;

        const variables = {
          flc_id_sinonimos_ocupacoes_cbo: this.occupation,
          fil_id_pessoas: this.patientInfo.pes_id,
          fil_id_prioridades_fila: Number(this.form.priority),
          fil_id_unidades_saude: this.form.originUnit,
          fil_data_pedido: this.form.requestDate,
          fil_id_funcionarios: this.form.requestingProfessionalId,
          fil_id_unidades_saude_solicitante: this.form.requesterUnit,
          fio_observacao: this.form.observations,
          fio_motivo: this.form.motivations,
          fil_id_agendado_por: this.userLogin.user.usu_id,
          flc_nome_sinonimos_ocupacoes: this.form.occupationName,
          fil_conselho_classe: this.form.classCouncil,
        };

        if (!this.form.requestingProfessionalId) {
          delete variables.fil_id_funcionarios;
          variables.fil_funcionario_externo = this.requestingProfessional;
        }

        if (this.isEdition) {
          variables.fil_ordem_regulada = this.dataLoadAlter.fil_ordem_regulada;
          variables.flc_id = this.dataLoadAlter.flc_id;

          await this.$store.dispatch(
            "Appointment/Schedule/EDIT_APPOINTMENT_QUEUE",
            {
              variables,
              validarDuplicidade: this.validateDuplication,
            },
          );

          this.$router.go(-1);
        } else {
          const data = await this.$store.dispatch(
            "Appointment/Schedule/ADD_APPOINTMENT_QUEUE",
            {
              variables,
              validarDuplicidade: this.validateDuplication,
            },
          );

          this.fil_id = Number(data.dados);
          this.modalPrint = true;

          if (insertImageInRow) {
            this.modalPatientImageInRow = true;
          }
        }

        this.$toaster.success("Operação realizada com sucesso");

        this.$store.commit(
          "Appointment/Schedule/UNSELECT_QUEUE_EXTERNAL_APPOINTMENT_DATA",
        );

        this.clearForm(false);
      } catch (error) {
        const { esus_response } = error;

        if (esus_response?.hasError) {
          if (this.validateDuplication) {
            this.actionsModalDuplicity(esus_response);
            if (this.modalPatientDuplicationQueue) return;
          }

          this.$toaster.error(
            "Não foi possível realizar operação: ",
            esus_response.message,
          );

          return;
        } else {
          this.$toaster.error(
            "Não foi possível realizar operação: ",
            this.$utils.sanitize.formatError(error),
          );
        }
      } finally {
        await this.searchQueueAppointmentPerPerson();
        this.$loader.finish();
        this.disabledSave = false;
      }
    },

    formatDataBR(pValue) {
      if (!pValue) {
        return "";
      }

      if (pValue.includes("-")) {
        return this.$utils.date.BrazilianDateFormat(pValue);
      } else {
        return pValue;
      }
    },

    async loadDataForEditing() {
      try {
        this.$loader.start();

        const { flc_id } = this.dataLoadAlter;

        this.dataPatientQueue = await this.$store.dispatch(
          "Appointment/Schedule/GET_DATA_PATIENT_APPOINTMENT_QUEUE",
          { flc_id },
        );

        this.personData = await this.$store.dispatch(
          "Appointment/Schedule/GET_PATIENT_BASIC_INFO",
          { pesId: this.dataPatientQueue.fil_id_pessoas },
        );

        await this.fillPatientInfo(this.personData);

        const patient = this.dataPatientQueue.objPaciente;

        this.$refs.patientInfoSuggest.fillPatientById(Number(patient.pes_id));

        const queueDate = this.dataPatientQueue.fil_data;
        const splittedQueueDate = queueDate.split(" ");
        const hasQueueDate = splittedQueueDate && splittedQueueDate.length > 0;

        this.form.entryDate = hasQueueDate ? splittedQueueDate[0] : null;

        this.form.entryHour = hasQueueDate ? splittedQueueDate[1] : null;

        this.form.originUnit = this.dataPatientQueue.fil_id_unidades_saude;

        this.form.occupationName = this.dataPatientQueue.flc_nome_sinonimos_ocupacoes;
        this.occupation = this.dataPatientQueue.flc_id_sinonimos_ocupacoes_cbo;

        const hasExternalProfessional =
          this.dataPatientQueue.fil_funcionario_externo &&
          !this.dataPatientQueue.objFuncionario.fun_id;

        if (hasExternalProfessional) {
          this.requestingProfessional = this.dataPatientQueue.fil_funcionario_externo;
        } else {
          const employeeData = this.dataPatientQueue.objFuncionario;
          const employeeRelationships = employeeData.objVinculo.vin_vinculos;

          const hasEmployeeRelationships =
            Array.isArray(employeeRelationships) &&
            employeeRelationships.length > 0;

          if (hasEmployeeRelationships) {
            const vinId = employeeRelationships[0].vin_id;

            this.$refs.employee.forceSelection({
              pes_nome: employeeData.pes_nome,
              vin_id: Number(vinId),
            });
          }

          this.requestingProfessional = this.dataPatientQueue.objFuncionario.pes_nome;
        }

        if (this.dataPatientQueue?.fil_id_funcionarios) {
          this.form.requestingProfessionalId = this.dataPatientQueue?.fil_id_funcionarios;
        }

        this.form.classCouncil = this.dataPatientQueue.fil_conselho_classe;
        this.disableClassCouncil = !!this.dataPatientQueue.fil_conselho_classe;

        this.form.requesterUnit = this.dataPatientQueue.fil_id_unidades_saude_solicitante;

        this.form.priority = this.dataPatientQueue.fil_id_prioridades_fila;

        this.form.requestDate = this.dataPatientQueue.fil_data_pedido;

        this.form.observations = this.dataPatientQueue.objFilaObservacao.fio_observacao;
      } catch (Err) {
        this.$toaster.error(Err);
      } finally {
        this.$loader.finish();
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async getPatientData(pPatient) {
      try {
        const hasPerson = pPatient && pPatient.pes_id > 0;

        if (pPatient.patient && hasPerson) {
          this.patientInfo.pac_id =
            pPatient && pPatient.patient ? pPatient.patient.pac_id : null;
          this.patientInfo.pcc_id =
            this.patientInfo.pac_id && pPatient.patient.patient_appointment
              ? pPatient.patient.patient_appointment.pcc_id
              : null;
          this.fillPatientInfo(pPatient);
        } else {
          this.patientInfo = {
            pac_id: "",
            pcc_id: "",
          };
        }
        if (this.patientInfo.pes_id) {
          await this.searchQueueAppointmentPerPerson();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async searchQueueAppointmentPerPerson() {
      this.queuesPerPerson = await this.$store.dispatch(
        "Appointment/Schedule/SEARCH_APPOINTMENT_QUEUES_PER_PERSON",
        { intIdPessoa: this.patientInfo.pes_id },
      );
    },

    cancel() {
      this.$router.push({ name: "appointment.schedule.queue" });
    },

    selectingOriginUnit(pOriginUnit) {
      if (!pOriginUnit) {
        this.occupation = null;
      }
    },

    selectingCbo(pCbo) {
      if (pCbo) {
        this.$loader.start();
        this.form.occupationName = pCbo.label;
        this.form.ocpId = pCbo.ocp_id;
        this.$loader.finish(1000);
      } else {
        this.form.occupationName = "";
        this.form.ocpId = null;
      }
    },

    selectingProfessional(pProfessional) {
      const source = pProfessional && pProfessional.source;
      if (source) {
        if (
          source.employeeRelationship &&
          source.employeeRelationship.vin_id_funcionarios
        ) {
          this.form.requestingProfessionalId =
            source.employeeRelationship.vin_id_funcionarios;
        }
        this.form.classCouncil = source.fun_conselho_classe;
        this.disableClassCouncil = !!source.fun_conselho_classe;
      } else {
        this.form.requestingProfessionalId = null;
      }
    },

    showConfirmFieldClearing() {
      this.modalConfirmFieldClearing = true;
    },

    closeModalPrint() {
      this.modalPrint = false;
      this.fil_id = 0;
    },

    confirmCleanForm() {
      this.clearForm();
      this.modalConfirmFieldClearing = false;
    },

    closeConfirmFieldClearing() {
      this.modalConfirmFieldClearing = false;
    },

    fillPatientInfo(pData) {
      this.patientInfo = {
        pac_id: pData.patient.pac_id,
        pes_id: pData.pes_id,
        pac_nome: pData.pes_nome,
        crs_numero: pData && pData.cns ? pData.cns.crs_numero : null,
        pes_mae: pData && pData.pes_mae ? pData.pes_mae : null,
        cpf: pData && pData.cpf ? pData.cpf.cpf_numero : null,
        prontuario_unico:
          pData && pData.patient ? pData.patient.pac_prontuario_unico : null,
      };
    },

    setInitialData() {
      this.form.originUnit = this.unitHealthId;
      this.form.requesterUnit = this.unitHealthId;
    },

    clearForm(pCleanPatient = true) {
      if (this.isEdition) {
        this.form.originUnit = null;
        this.occupation = null;
        this.form.occupationName = null;
        this.form.requesterUnit = null;
        this.form.priority = null;
      } else {
        if (pCleanPatient) {
          this.$refs.patientInfoSuggest.cleanPatient();
          this.$refs.patientInfoSuggest.cleanValidate();
        }

        this.occupation = null;
        this.requesterUnit = null;
        this.requestingProfessional = null;
        this.classCouncil = null;
        this.period = null;
        this.queuesPerPerson = [];
        this.modalConfirmFieldClearing = false;
        this.form = {
          patientId: null,
          entryDate: moment().format("DD/MM/YYYY"),
          entryHour: moment().format("HH:mm:ss"),
          originUnit: null,
          requestingProfessionalId: null,
          requesterUnit: null,
          priority: null,
          requestDate: "",
          observations: "",
          motivations: "",
          classCouncil: null,
        };
        this.setInitialData();
        this.$refs.cbo.cleanValidate();
        this.$refs.employee.cleanValidate();
        this.$refs.date.cleanValidate();
        this.$refs.priority.cleanValidate();
        this.$refs.unitInsertion.cleanValidate();
        this.$refs.unitSolicitation.cleanValidate();
        this.$refs.textArea.resetCharacter();
      }
    },

    async handleModal(option) {
      const isOptionCloseOrDenied = option === 0 || option === 2;
      const isOptionConfirmed = option === 1;

      if (isOptionCloseOrDenied) {
        this.modal.showConfirmModal = false;
      }

      if (isOptionConfirmed) {
        this.modal.showConfirmModal = false;
        await this.registerQueue();
      }
    },
  },
};
</script>
