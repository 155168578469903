import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const requestAttend = await AuthLegacyRequest.post(
    "farmacia/controller-solicitacao-transferencia/dispensar",
    variables,
  );

  return HandleResponse(requestAttend).data;
};
