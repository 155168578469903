import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExamExternallySchedule from "$exam/submodules/schedule/views/exam-externally-schedule/ExamExternallySchedule.vue";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/externally-schedule",
  name: "exam.schedule.externally-schedule",
  components: {
    default: ExamExternallySchedule,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Regulação", link: OpenSubModule("exam") },
      {
        label: "Agendados Externamente",
        link: "/exam/schedule/externally-schedule",
      },
    ],
  },
};
