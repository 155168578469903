<template>
  <div class="appointment-patients-pendency-queue-report">
    <section class="grid">
      <div class="filter-title">
        <span class="title"> Dados do Relatório Selecionado </span>
      </div>
      <RgSuggestCboSynonyms
        id="occupation"
        ref="cboSynonyms"
        v-model="form.cbo"
        :rules="{ required: true }"
        @selected="selectingOccupation"
      />
      <RgSelectUnithealthQueue
        id="unit"
        v-model="form.unitHealthRequest"
        label="Unidade de Saúde Solicitante"
        :module-id="appointmentModuleId"
        :default-text="'Todas'"
        hasRegularPermission
        openOnlyBottom
        @change="selectingUnitHealthRequest"
      />
      <div class="filter-title">
        <span class="title"> Período de Inclusão na Fila </span>
      </div>
      <div class="period-date">
        <RgInputDate
          id="initial-date-queue"
          ref="queuePeriodDateStart"
          v-model="form.queuePeriodDateStart"
          :rules="{ fn: verifyQueuePeriodDate }"
          label="Data Inicial"
          :max-date="new Date()"
        />
        <RgInputDate
          id="final-date-queue"
          ref="queuePeriodDateEnd"
          v-model="form.queuePeriodDateEnd"
          label="Data Final"
          :max-date="new Date()"
        />
      </div>
      <div class="filter-title">
        <span class="title"> Período do Pedido </span>
      </div>
      <div class="period-date">
        <RgInputDate
          id="initial-request-date"
          ref="requestPeriodDateStart"
          v-model="form.requestPeriodDateStart"
          :rules="{ fn: verifyRequestPeriodDate }"
          :max-date="new Date()"
          label="Data Inicial"
          popUp="top"
        />
        <RgInputDate
          id="final-request-date"
          ref="requestPeriodDateEnd"
          v-model="form.requestPeriodDateEnd"
          label="Data Final"
          :max-date="new Date()"
          popUp="top"
        />
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";

import {
  RgSuggestCboSynonyms,
  RgSelectUnithealthQueue,
  RgInputDate,
} from "~tokio/primitive";

const FORM_FILTER = {
  cbo: null,
  occupation: null,
  occupationName: null,
  unitHealthRequest: null,
  unitHealthRequestName: null,
  requestPeriodDateStart: null,
  requestPeriodDateEnd: null,
  queuePeriodDateStart: null,
  queuePeriodDateEnd: null,
  columnsToPrint: null,
};

export default {
  name: "AppointmentPatientsPendencyQueueReport",
  components: {
    RgSuggestCboSynonyms,
    RgSelectUnithealthQueue,
    RgInputDate,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Pacientes na Fila com Pendência",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },
  computed: {
    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; }" +
        ".report-column-2 { white-space: normal !important; text-align: left; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; }" +
        ".report-column-5 { white-space: normal !important; }" +
        ".report-column-6 { white-space: normal !important; }" +
        ".report-column-7 { white-space: normal !important; text-align: left; }"
      );
    },
    getNameSmartTable() {
      return "AppointmentPatientsPendencyQueueReport";
    },
    initialColumns() {
      return 7;
    },
    columnTable() {
      return this.COLUMN;
    },
  },
  created() {
    this.COLUMN = [
      { name: "Ocupação", key: "OCUPACAO" },
      { name: "Data de Inclusão na Fila", key: "DATA_INCLUSAO_FILA" },
      {
        name: "Unidade de Saúde Solicitante",
        key: "UNIDADE_SAUDE_SOLICITANTE",
        align: "left",
      },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "Mãe", key: "MAE", align: "left" },
      { name: "Nascimento", key: "NASCIMENTO" },
      { name: "CNS", key: "CNS" },
      { name: "Pendência", key: "PENDENCIA", align: "left" },
    ];
  },
  methods: {
    async doSearch(pData) {
      try {
        this.$loader.start();

        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_PATIENTS_PENDENCY_QUEUE_REPORT",
          pData,
        );
      } catch (error) {
        this.$toaster.error("Erro ao gerar o relatório");
      } finally {
        this.$loader.finish();
      }
    },
    buildFilter() {
      const payload = {
        occupation: this.form.occupation,
        occupationName: this.form.occupationName,
        unitHealthRequest: this.form.unitHealthRequest,
        unitHealthRequestName: this.form.unitHealthRequestName,
        requestPeriodDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.requestPeriodDateStart,
        ),
        requestPeriodDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.requestPeriodDateEnd,
        ),
        queuePeriodDateStart: this.$utils.date.BrazilianDateToDatabase(
          this.form.queuePeriodDateStart,
        ),
        queuePeriodDateEnd: this.$utils.date.BrazilianDateToDatabase(
          this.form.queuePeriodDateEnd,
        ),
        columnsToPrint: this.columnsToPrint,
      };
      return payload;
    },
    selectingOccupation(pValue) {
      if (pValue?.source) {
        this.form.occupationName = pValue.source.soc_nome;
        this.form.occupation = pValue.source.soc_id;
      } else {
        this.form.occupationName = null;
        this.form.occupation = null;
      }
    },
    selectingUnitHealthRequest(pValue) {
      if (pValue) {
        this.form.unitHealthRequestName = pValue.label;
      } else {
        this.form.unitHealthRequestName = null;
      }
    },
    verifyQueuePeriodDate(pValue, pErrors) {
      const initialDate = moment(this.form.queuePeriodDateStart, "DD/MM/YYYY");
      const finalDate = moment(this.form.queuePeriodDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    verifyRequestPeriodDate(pValue, pErrors) {
      const initialDate = moment(
        this.form.requestPeriodDateStart,
        "DD/MM/YYYY",
      );
      const finalDate = moment(this.form.requestPeriodDateEnd, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "cboSynonyms",
        "requestPeriodDateStart",
        "requestPeriodDateEnd",
        "queuePeriodDateStart",
        "queuePeriodDateEnd",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
  },
};
</script>
