<template>
  <div class="list-item-carousel" :class="{ back: rotate, front: !rotate }">
    <div v-if="!rotate" class="content-item">
      <div class="icon-area">
        <slot name="icon" class="svg" />
      </div>

      <div class="title-area">
        <h3 class="title-content">{{ title }}</h3>
      </div>

      <p class="subtitle-text" v-html="subtitle"></p>
    </div>

    <div v-else class="list-area back">
      <ul class="ul">
        <li
          v-for="(li, index) in listItem"
          :key="index"
          class="li"
          @click="go(li.route)"
        >
          {{ li.label }}
          <IconArrowCircle class="svg" />
        </li>
      </ul>
    </div>

    <button class="base-button" @click="list">
      <div v-if="rotate" class="title-button">{{ title }}</div>
      <IconArrowCircle class="svg" />
    </button>
  </div>
</template>

<script>
import { IconArrowCircle } from "~tokio/primitive";
export default {
  name: "ListItemCarousel",
  components: { IconArrowCircle },
  props: {
    subtitle: {
      type: String,
      default: "Subtitulo",
    },
    title: {
      type: String,
      default: "Titulo",
    },
    redirect: {
      type: Function,
      default: () => {
        return "";
      },
    },
    listItem: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rotate: false,
    };
  },
  methods: {
    async list() {
      const url = await this.redirect();

      if (url) {
        this.go(url);
        return;
      }

      this.rotate = !this.rotate;
    },

    go(pRoute) {
      const newSite = /^http/.test(pRoute);
      if (newSite) {
        window.open(pRoute);
      } else {
        this.$router.push(pRoute);
      }
      localStorage.setItem(
        "exec-refresh-page-refreshLogin",
        JSON.stringify({ exec: "active", refresh: "active" }),
      );
    },
  },
};
</script>
