<template lang="html">
  <div v-if="show" class="modal-see-revenue">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-see-revenue-header">
        <span class="title"> Visualizar Saídas por Pacientes e Farmácias </span>
      </div>

      <div class="modal-see-revenue-type">
        <div slot="body" class="modal-see-revenue-body">
          <FormBase title="Dados do Paciente" class="form-base">
            <section class="grid">
              <span class="text-info">
                Paciente:
                <strong :title="patient.pes_nome">
                  {{ patient.pes_nome || "-" }}</strong
                >
              </span>
              <span class="text-info">
                CNS:
                <strong :title="patient.crs_numero">
                  {{ patient.crs_numero || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Idade:
                <strong :title="patient.pes_idade">
                  {{ patient.pes_idade || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Data de Nascimento:
                <strong :title="patient.pes_nascimento">
                  {{ patient.pes_nascimento || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Prontuário Único:
                <strong :title="patient.pac_prontuario_unico">
                  {{ patient.pac_prontuario_unico || "-" }}</strong
                >
              </span>
            </section>
          </FormBase>
          <FormBase title="Dados da Saída" class="form-base">
            <div class="filters">
              <RgSelectUnithealthPharmacy
                ref="unitHealth"
                v-model="form.unitHealth"
                :rules="{ required: true }"
                :filter="this.historyPatientFilter"
              />
              <RgSelectPharmacy
                ref="pharmacy"
                v-model="form.pharmacy"
                byUser
                :unitHealthId="form.unitHealth"
                :userId="USER_LOGIN_ID"
                :typePharmacy="2"
                :disabled="!form.unitHealth"
                :class="{
                  disable: !form.unitHealth,
                }"
                :filter="this.historyPatientFilter"
                :rules="{ required: true }"
                @change="selectedPharmacy"
              />
            </div>
            <hr class="hr" />
            <section v-if="historyPatient.length > 0" class="data-revenue">
              <div class="quantity-product">
                <span class="text-info">
                  Quantidade de produtos solicitados:
                  <strong :title="historyPatient.length">
                    {{ historyPatient.length || "-" }}</strong
                  >
                </span>
              </div>
              <div class="list">
                <div
                  v-for="(item, idx) in historyPatient"
                  ref="historyPatient"
                  :key="idx"
                  class="grid-product"
                >
                  <span class="text-info">
                    Cód.:
                    <strong :title="item.mpd_codigo">
                      {{ item.mpd_codigo || "-" }}</strong
                    >
                  </span>
                  <span class="text-info">
                    Prod.:
                    <strong :title="item.mpd_novo_principio_ativo">
                      {{ item.mpd_novo_principio_ativo || "-" }}</strong
                    >
                  </span>
                  <span class="text-info">
                    Quant.:
                    <strong :title="item.rec_quantidade">
                      {{ item.rec_quantidade || "-" }}</strong
                    >
                  </span>
                  <span class="text-info">
                    Entrega:
                    <strong :title="item.rec_data">
                      {{ item.rec_data || "-" }}</strong
                    >
                  </span>
                  <span class="text-info">
                    Médico:
                    <strong :title="item.solicitante || '-'">
                      {{ item.solicitante || "-" }}</strong
                    >
                  </span>
                  <span class="text-info">
                    Retorno:
                    <strong :title="item.rec_retorno || '-'">
                      {{ item.rec_retorno || "-" }}</strong
                    >
                  </span>
                  <span class="text-info">
                    Usuário:
                    <strong :title="item.usu_nome || '-'">
                      {{ item.usu_nome || "-" }}</strong
                    >
                  </span>
                </div>
              </div>
            </section>
            <section v-else class="no-result">
              <IconInfoHelper class="icon" :background="'#6f8894'" />
              <span class="message"> SELECIONE UMA FARMÁCIA </span>
            </section>
          </FormBase>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, IconInfoHelper } from "~tokio/primitive";
import { FormBase } from "~tokio/foundation";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";

const FORM_REVENUE = {
  unitHealth: null,
  pharmacy: null,
};

export default {
  name: "ModalSeeRevenue",
  components: {
    RgBaseModal,
    RgSelectUnithealthPharmacy,
    FormBase,
    RgSelectPharmacy,
    IconInfoHelper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Number,
      default: 0,
    },
    unitHealthId: {
      type: Number,
      default: 0,
    },
    pharmacyId: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      patient: {},
      historyPatient: [],
      form: this.$utils.obj.DeepCopy(FORM_REVENUE),
      historyPatientFilter: [],
    };
  },
  computed: {},
  watch: {
    async show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        await this.historyRevenue(this.filters);
      }
    },
    "form.unitHealth"(pValue, pPrev) {
      if (!pValue) {
        this.form.pharmacy = null;
      }
      if (pValue !== pPrev) {
        this.form.pharmacy = null;
      }
    },
    "form.pharmacy"(pValue) {
      if (!pValue) {
        this.historyPatient = [];
      }
    },
  },
  created() {
    this.USER_LOGIN_ID = this.$store.getters["Login/GET_USER_ID"];
  },
  mounted() {},

  methods: {
    async historyRevenue(pValue) {
      try {
        this.$loader.start();

        const variables = {
          intIdPacienteFarmacia: pValue,
        };
        const infoPatient = await this.$store.dispatch(
          "Pharmacy/Satellite/GET_SUMMARY_PATIENT",
          variables,
        );

        this.historyPatientFilter = await this.$store.dispatch(
          "Pharmacy/Satellite/SEARCH_HISTORY_PATIENT",
          variables,
        );

        this.patient = {
          crs_numero: infoPatient.crs_numero,
          pac_prontuario_unico: infoPatient.pac_prontuario_unico,
          pes_idade: infoPatient.pes_idade.years,
          pes_nascimento: infoPatient.pes_nascimento,
          pes_nome: infoPatient.pes_nome,
        };

        this.form.unitHealth = this.unitHealthId;
        this.form.pharmacy = this.pharmacyId;
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao encontrar o histórico",
        );
      } finally {
        this.$loader.finish();
      }
    },

    selectedPharmacy(pValue) {
      if (pValue) {
        let aux = [...this.historyPatientFilter];
        aux = aux.filter(
          (pData) => Number(pData.far_id) === Number(pValue.value),
        );
        this.historyPatient = aux;
      }
    },
    close() {
      this.$emit("close");
      this.historyPatient = [];
      // this.historyPatientFilter = [];
      // this.patient = {};
      this.form = this.$utils.obj.DeepCopy(FORM_REVENUE);
    },
  },
};
</script>
