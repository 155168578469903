import AuthRequest from "~common/request/AuthRequest";

const SendFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return AuthRequest.post("/globals/files/upload", formData);
};

export default SendFile;
export { SendFile };
