<template lang="html">
  <section class="patient-type">
    <div v-if="sex" class="patient">
      <IconPatientMan v-if="sex === 1" class="svg" />
      <IconPatientWoman v-else-if="sex === 2" class="svg" />
      <IconPersonUndefined v-else class="svg" />
    </div>

    <div v-else>
      <IconPersonUndefined class="svg" />
    </div>
  </section>
</template>

<script>
import {
  IconPatientMan,
  IconPatientWoman,
  IconPersonUndefined,
} from "~tokio/primitive/icon/symbols";

export default {
  name: "PatientType",
  components: {
    IconPatientMan,
    IconPatientWoman,
    IconPersonUndefined,
  },
  props: {
    sex: {
      type: Number,
      default: null,
    },
  },
};
</script>
