import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query OccupationProfessionalBond(
    $ocp_nome: String
    $ocp_codigo: String
    $fun_id: Int
    $fun_crs_numero_ab: String
    $vin_ativo: Boolean
    $set_id: Int
    $limit: Int
  ) {
    occupationProfessionalBond(
      ocp_nome: $ocp_nome
      ocp_codigo: $ocp_codigo
      fun_id: $fun_id
      fun_crs_numero_ab: $fun_crs_numero_ab
      vin_ativo: $vin_ativo
      set_id: $set_id
      limit: $limit
    ) {
      count
      rows {
        ocp_id
        ocp_nome
        ocp_codigo
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.occupationProfessionalBond;
};
