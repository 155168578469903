import { render, staticRenderFns } from "./RgInputTextArea.vue?vue&type=template&id=13570885&scoped=true&"
import script from "./RgInputTextArea.vue?vue&type=script&lang=js&"
export * from "./RgInputTextArea.vue?vue&type=script&lang=js&"
import style0 from "./RgInputTextArea.scss?vue&type=style&index=0&id=13570885&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13570885",
  null
  
)

export default component.exports