import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "faturamento/controller-tabela-unificada-municipio/buscar",
    variables,
  )
    .then(({ data }) => {
      const total = data.dados.total;
      data = HandleResponse(data);
      data.dados = data.dados.recordSet;

      return {
        total,
        data: data.dados,
      };
    })
    .catch((pErr) => {
      throw pErr;
    });
};
