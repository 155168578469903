<template>
  <div v-if="show" class="modal-confirm-exchange">
    <RgBaseModal
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="modal-title">
          <h1 class="title">Confirmação da Permuta</h1>
        </div>
      </div>

      <div slot="body" class="body">
        <span class="question"> Deseja realmente realizar a permuta ? </span>

        <div class="box clear">
          <span class="title"
            >INTERNAÇÃO
            <strong>{{ getValue("origin", "int_numero") }}</strong>
          </span>

          <span class="label">
            Paciente:
            <strong>{{
              getValue("origin", "paciente", "nome_paciente")
            }}</strong>
          </span>
          <span class="label">
            Unidade de Saúde:
            <strong> {{ getValue("origin", "uns_nome") }} </strong>
          </span>
          <span class="label">
            Setor:
            <strong> {{ getValue("origin", "set_nome") }} </strong>
          </span>
          <span class="label">
            Local de Atendimento:
            <strong> {{ getValue("origin", "lca_nome") }} </strong>
          </span>
          <span class="label">
            Leito:
            <strong>{{ getValue("origin", "inl_nome") }}</strong>
          </span>
        </div>

        <div class="divider unselect">
          <IconArrowExchange class="exchange-svg" />
        </div>

        <div class="box dark">
          <span class="title"
            >INTERNAÇÃO
            <strong>{{ getValue("destiny", "int_numero") }}</strong>
          </span>
          <span class="label">
            Paciente:
            <strong> {{ getValue("destiny", "paciente") }} </strong>
          </span>
          <span class="label">
            Unidade de Saúde:
            <strong> {{ getValue("destiny", "uns_nome") }} </strong>
          </span>
          <span class="label">
            Setor:
            <strong> {{ getValue("destiny", "set_nome") }} </strong>
          </span>
          <span class="label">
            Local de Atendimento:
            <strong> {{ getValue("destiny", "lca_nome") }} </strong>
          </span>
          <span class="label">
            Leito:
            <strong> {{ getValue("destiny", "inl_nome") }} </strong>
          </span>
        </div>
      </div>

      <div slot="footer" class="footer">
        <div class="action unselect">
          <RgSaveButton
            id="save-btn"
            ref="save"
            :class="{ disable: isSaving }"
            :disabled="isSaving"
            title="Salvar"
            medium
            type="button"
            class="save"
            @click="saveForm"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>
<script>
import { RgBaseModal, RgSaveButton, IconArrowExchange } from "~tokio/primitive";

export default {
  name: "ModalConfirmExchage",
  components: {
    RgBaseModal,
    RgSaveButton,
    IconArrowExchange,
  },
  props: {
    title: {
      type: String,
      default: "Confirmação de Permuta",
    },
    show: {
      type: Boolean,
      default: false,
    },
    information: {
      type: Object,
      default: () => {},
    },
    exchange: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    prepareExchange() {
      const hasOriginData =
        this.information.origin &&
        Object.keys(this.information.origin).length > 0;

      const hasDestinyData =
        this.information.destiny &&
        Object.keys(this.information.destiny).length > 0;

      if (!hasOriginData || !hasDestinyData) {
        throw new Error("Não foi possível realizar a permuta");
      }

      const originBedId =
        this.information.origin.inl_id || this.information.origin.id;

      const data = {
        origin_int_id: this.information.origin.int_id,
        origin_bed_id: originBedId,
        origin_situation_id: this.information.origin
          .inl_id_internacoes_situacoes_leito,
        origin_bed_type_id: this.information.origin.itl_id,
        origin_unit_health_id: this.information.origin.uns_id,
        origin_observation: this.information.origin.ihs_observacao,

        destiny_int_id: this.information.destiny.int_id,
        destiny_bed_id: this.information.destiny.inl_id,
        destiny_situation_id: this.information.destiny
          .inl_id_internacoes_situacoes_leito,
        destiny_bed_type_id: this.information.destiny.itl_id,
        destiny_unit_health_id: Number(this.information.destiny.uns_id),
        destiny_observation: this.information.destiny.ihs_observacao,
      };

      return data;
    },

    async saveForm() {
      try {
        this.isSaving = true;

        await this.$refs.save.actionSubmit();

        const isExchange = this.show && this.exchange;

        if (isExchange) {
          const exchange = this.prepareExchange();

          const response = await this.$store.dispatch(
            "Hospitalization/PATIENT_EXCHANGE",
            {
              exchange,
            },
          );
          const hasSuccess = response && response.success;

          hasSuccess
            ? this.$toaster.success(response.message)
            : this.$toaster.error(response.message);
        }

        await this.$refs.save.actionDone();
        this.$emit("reSearch", true);
        this.close();
      } catch (pErr) {
        this.$refs.save.fail();
        this.$toaster.error(pErr);
      }

      this.isSaving = false;
    },

    getValue(pType, pKey = null, pAlternativeKey = null) {
      const hasValue = this.information[pType];

      const value =
        this.information[pType][pKey] ||
        this.information[pType][pAlternativeKey];

      return hasValue ? value : "Não Informado";
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
