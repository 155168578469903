<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListUnitHealthLegacy from "./action/ListUnitHealthLegacy";

export default {
  name: "RgSelectUnithealthLegacy",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Unidade de Saúde",
    },
    defaultText: {
      type: String,
      default: "Selecione",
    },
    emptyDefaultText: {
      type: String,
      default: "Nenhum Item para Selecionar",
    },
    subModuleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const data = await ListUnitHealthLegacy({
        intIdSubModulo: this.subModuleId,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: `${item.uns_nome}`,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
