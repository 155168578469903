<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchOccupation from "./action/SearchOccupation";

export default {
  name: "RgSelectOccupation",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Ocupação",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    subModuleId: {
      type: Number,
      default: null,
    },
  },
  data: () => {
    return {
      occupation: [],
    };
  },
  watch: {
    unitHealthId(pValue) {
      if (pValue) {
        this.doSearch();
      }
    },
    occupation: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.occupation;
    },
    async doSearch() {
      this.occupation = [];

      if (this.unitHealthId) {
        const data = await SearchOccupation({
          intIdUnidade: this.unitHealthId,
          intIdSubModulo: this.subModuleId,
        });

        this.occupation = data.map((item) => {
          return {
            value: item.data,
            label: item.label,
            codigo: item.ocp_codigo,
          };
        });
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
