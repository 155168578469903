<style lang="scss" src="./RgDatePicker.scss" scoped></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" class="rg-input--icon" />
      </div>
      <div class="container">
        <DatePicker
          v-model="inputValue"
          :type="type"
          :format="format"
          :placeholder="placeholder"
          :picker-options="pickerOptions"
        />
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidationAlert, RgValidatorMixin } from "../../validation";

export default {
  name: "RgDatePicker",
  components: {
    RgFormBase,
    RgValidationAlert,
  },
  mixins: [RgValidatorMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
    placeholder: {
      default: "Selecione",
    },
    type: {
      type: String,
    },
    format: {
      type: String,
    },
    rules: {},
    pickerOptions: {},
    label: {
      type: String,
      default: "Data",
    },
  },
  data() {
    return {
      inputValue: "",
      error: [],
    };
  },
  computed: {
    validValue() {
      if (this.useDatepicker) {
        return this.date;
      }
      return this.inputValue;
    },
    hasError() {
      return this.error.length > 0;
    },
    isRequired() {
      return this.rules && this.rules.required;
    },
  },
  watch: {
    value(pValue) {
      this.inputValue = pValue;
    },

    date(pDate) {
      this.inputValue = pDate;
      this.validate();
    },

    inputValue(pValue, pPrev) {
      this.validate();
      this.$emit("input", pValue);
    },
  },
  mounted() {},

  methods: {
    validateBlur() {
      if (this.date || this.inputValue || this.isRequired) {
        this.validate();
        this.$emit("blur");
      }
    },

    cleanDatePicker() {
      this.date = null;
    },

    cleanValidate() {
      this.error = [];
      this.isValid = true;
      if (this.dirt) {
        this.dirt = false;
      }
    },
  },
};
</script>
