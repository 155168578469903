<style lang="scss" src="./GenericTypeScale.scss" scoped></style>
<template>
  <div v-if="show" class="rg-alert-modal">
    <RgBaseModal
      withCloseButton
      removeSubTitle
      :show="show"
      :size="{ minWidth: 700, minHeight: 260, unit: 'px' }"
      class="rg-alert-base-modal"
      @close="actionClose"
    >
      <div slot="header" class="generic-title">
        <span>{{ title }}</span>
      </div>

      <div slot="body" class="generic-content">
        <span class="generic-subtitle">{{ subtitle }}</span>
        <slot name="message"></slot>
      </div>

      <div slot="footer" class="generic-footer">
        <slot name="footer"></slot>
      </div>
    </RgBaseModal>
  </div>
</template>

<script type="text/javascript">
import RgBaseModal from "~tokio/primitive/notification/modal/RgBaseModal.vue";

export default {
  name: "GenericTypeScale",

  components: {
    RgBaseModal,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },

  computed: {},

  methods: {
    actionClose() {
      this.$emit("close");
    },
  },
};
</script>
