import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchMainDiagnosticReport(
    $unsId: Int
    $mainProcedure: String
    $cid: String
    $presentation: String
    $status: String
    $limit: Int
    $offset: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchMainDiagnosticReport(
      uns_id: $unsId
      inf_procedimento_principal: $mainProcedure
      inf_cid_principal: $cid
      iaf_apresentacao: $presentation
      iaf_faturado: $status
      limit: $limit
      offset: $offset
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        icf_nome
        iaf_prontuario
        iaf_numero_aih
        cid
      }
    }
  }
`;
export default async (
  { commit },
  {
    uns_id,
    toPrint,
    toCsv,
    toExcel,
    initialDate,
    finalDate,
    mainProcedure,
    cid,
    presentation,
    status,
    limit,
    offset,
    columnsToPrint,
  },
) => {
  presentation = !presentation.indexOf("/")
    ? presentation
    : presentation.replace("/", "");
  try {
    const variables = {
      unsId: uns_id,
      toPrint,
      toCsv,
      toExcel,
      initialDate,
      finalDate,
      mainProcedure,
      cid,
      presentation,
      status,
      limit,
      offset,
      columnsToPrint,
    };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    const html =
      !data.SearchMainDiagnosticReport || !data.SearchMainDiagnosticReport.html
        ? ""
        : data.SearchMainDiagnosticReport.html.replace('\\"', '"');
    const count = !data.SearchMainDiagnosticReport
      ? 0
      : data.SearchMainDiagnosticReport.count;
    const csv = !data.SearchMainDiagnosticReport
      ? 0
      : data.SearchMainDiagnosticReport.csv;
    const excel = !data.SearchMainDiagnosticReport
      ? 0
      : data.SearchMainDiagnosticReport.excel;
    const fileName = !data.SearchMainDiagnosticReport
      ? 0
      : data.SearchMainDiagnosticReport.fileName;
    return {
      html,
      count,
      csv,
      excel,
      fileName,
      rows: data.SearchMainDiagnosticReport.rows,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
