import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
export default {
  path: "/appointment/schedule/queue-insert/patient-care-history",
  name: "appointment.schedule.queue-insert.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Regulação", link: OpenSubModule("appointment") },
      { label: "Inserir na Fila", link: "/appointment/schedule/queue-insert" },
      {
        label: "Histórico de Atendimentos",
      },
    ],
  },
};
