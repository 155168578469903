import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/faturamento/controller-faturamento-servico-classificacao-unidade/obter-classificacoes-por-servico",
    {
      arrFormData: variables,
    },
  )
    .then(({ data }) => {
      const result = data.dados;
      return result;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
