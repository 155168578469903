<style src="./RgConfirmButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-confirm-button">
    <RgButton
      :id="id"
      :title="title"
      :permission="permission"
      :data-id="dataId"
      :disabled="disabled"
      :class="{
        'rg-confirm-button -small': small,
        'rg-confirm-button -medium': medium,
        'rg-confirm-button -large': large,
      }"
      class="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>Confirmar</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-if="showWaiting && !medium" class="spinner">
        <div class="icon-spinner" />
      </div>
      <div v-if="(showNormalCaption || showDone) && !medium" class="icon-check">
        <IconCheck />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconCheck } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

let timoutDone = null;
const timeoutAction = null;

export default {
  name: "RgConfirmButton",
  components: {
    IconCheck,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    title: {
      type: String,
      default: "Confirmar",
    },
  },
  data() {
    return {
      requestDone: false,
      timeDone: false,
      showNormalCaption: true,
      showWaiting: false,
      showDone: false,
    };
  },
  methods: {
    clicked() {
      this.actionSubmit();
      this.$emit("click", true);
    },
    actionSubmit() {
      this.showNormalCaption = false;
      this.showWaiting = true;
      this.showDone = false;
      return new Promise((resolve, reject) => {
        timoutDone = setTimeout(() => {
          this.timeDone = true;
          this.finish();
          resolve();
        }, 800);
      });
    },
    actionDone() {
      this.showNormalCaption = false;
      this.showWaiting = false;
      this.showDone = true;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.actionNormalState();
          resolve();
        }, 800);
      });
    },
    actionNormalState() {
      this.showNormalCaption = true;
      this.showWaiting = false;
      this.showDone = false;
      clearTimeout(timoutDone);
      clearTimeout(timeoutAction);
    },
    done() {
      this.requestDone = true;
    },
    fail() {
      this.actionNormalState();
    },
    finish() {
      if (this.requestDone && this.timeDone) {
        this.requestDone = false;
        this.timeDone = false;
        this.$emit("complete");
        this.actionDone();
      }
    },
  },
};
</script>
