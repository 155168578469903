<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import SearchPriority from "./action/SearchPriority";
export default {
  name: "RgSelectPriority",
  extends: RgSelect,
  props: {
    moduleId: {
      required: true,
      type: Number,
    },
    label: {
      type: String,
      default: "Prioridade",
    },
    defaultText: {
      type: String,
      default: "Todas",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const data = await SearchPriority({ intIdModulo: this.moduleId });
      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: item.pfi_nome,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
