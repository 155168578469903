<style src="./RgImportButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-import-button">
    <RgButton
      :id="id"
      :data-id="dataId"
      :title="title"
      :permission="permission"
      :disabled="disabled"
      :class="{
        'rg-import-button -small': small,
        'rg-import-button -medium': medium,
        'rg-import-button -large': large,
      }"
      type="button"
      class="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>{{ label }}</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-show="!medium" class="icon">
        <IconImport />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconImport } from "~tokio/primitive/icon/";
import RgButton from "~tokio/primitive/button/rg-button/RgButton";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin";

export default {
  name: "RgImportButton",
  components: {
    IconImport,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    title: {
      type: String,
      default: "Importar",
    },
    label: {
      type: String,
      default: "Importar",
    },
  },
};
</script>
