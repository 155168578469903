<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchUnitHealthLinkedCbo from "./action/SearchUnitHealthLinkedCbo";

export default {
  name: "RgSelectUnitHealthLinkedCbo",
  extends: RgSelect,
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const data = await SearchUnitHealthLinkedCbo();

      this.valuesData = data.map((item) => {
        return { value: item.data, label: item.label };
      });
    },
  },
};
</script>
