var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('RgBaseModal',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['esc']),expression:"['esc']"}],ref:"Modal",staticClass:"modal-patient-image-in-row",attrs:{"withCloseButton":"","show":_vm.show && _vm.isReady},on:{"close":_vm.close},nativeOn:{"shortkey":function($event){return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"modal-patient-image-in-row-header",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"modal-patient-image-in-row-body",attrs:{"slot":"body"},slot:"body"},[_c('FormBase',{attrs:{"title":"Arquivos"}},[_c('div',{staticClass:"full-area"},[_c('div',{staticClass:"half-area"},[_c('div',[_c('input',{ref:"file",staticClass:"input-file",attrs:{"type":"file","accept":_vm.acceptTypes,"disabled":_vm.viewOnly},on:{"change":_vm.uploadFile}}),_c('div',{on:{"click":_vm.openFileDialog}},[_c('label',{staticClass:"input-label"},[_c('RgInput',{attrs:{"id":"input-file","readonly":"","label":"Enviar Novo Arquivo","placeholder":_vm.drawInformationMessage(),"legend":'É permitido arquivos de até 3MB',"cursosMouseHover":""}})],1)])]),_c('RgAddButton',{staticClass:"new",attrs:{"id":"new-button","type":"button","disabled":!_vm.file || _vm.viewOnly},on:{"click":_vm.confirmUpload}})],1),_c('div',{staticClass:"file-list"},[(_vm.files && _vm.files.length > 0)?_c('span',[_vm._v("Arquivos Enviados")]):_vm._e(),(_vm.files && _vm.files.length > 0)?_c('div',{staticClass:"top-btns"},[_c('RgLessButton',{attrs:{"id":"less-button","disabled":!_vm.hasSelectedDocument || _vm.viewOnly,"title":"Remover arquivo"},on:{"click":function($event){return _vm.removeFile(_vm.selectedData)}}}),_c('a',{staticClass:"download",class:{
                  disable: !_vm.hasSelectedDocument,
                  disablebtn: !_vm.hasSelectedDocument,
                },attrs:{"id":"icon-arrow-download","disabled":!_vm.hasSelectedDocument,"title":"Baixar"},on:{"click":_vm.actionDownload}},[_c('IconArrowDownload',{staticClass:"svg"})],1),_c('a',{staticClass:"see",class:{
                  disable: !_vm.hasSelectedDocument,
                  disablebtn: !_vm.hasSelectedDocument,
                },attrs:{"id":"icon-show","href":_vm.selectedData !== null ? _vm.selectedData.url : null,"disabled":!_vm.hasSelectedDocument,"target":"_blank","title":"Visualizar"}},[_c('IconShow')],1)],1):_vm._e(),(_vm.files && _vm.files.length > 0)?_c('div',[_c('RgTable',{staticClass:"rg-table",attrs:{"columns":_vm.COLUMNS}},_vm._l((_vm.files),function(item,index){return _c('tr',{key:index,staticClass:"tr",class:{ selected: index === _vm.activeRow },attrs:{"slot":"rows"},on:{"click":function($event){return _vm.selectLine(item, index)}},slot:"rows"},[_c('td',[_vm._v(" "+_vm._s(item.filename || item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.dateHour || item.hourDate)+" ")])])}),0)],1):_c('div',{staticClass:"empty-result"},[_c('IconEmpty'),_c('span',[_vm._v("Não foram encontrados arquivos")])],1)])])])],1),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('RgCancelButton',{attrs:{"id":"cancel-button","medium":"","type":"button"},on:{"click":_vm.close}}),_c('RgConfirmButton',{attrs:{"id":"confirm-button","disabled":_vm.viewOnly,"medium":"","type":"button"},on:{"click":_vm.confirm}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }