import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientDetails from "$person/patient/view/patient-details/PatientDetails";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";
export default {
  path: "/exam/schedule/per-period/patient",
  name: "exam.schedule.per-period.patient",
  components: {
    default: PatientDetails,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      { label: "Agendamentos por Dia", link: "/exam/schedule/per-period" },
      { label: "Editar Paciente" },
    ],
  },
};
