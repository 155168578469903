import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

export default {
  path:
    "/hospitalization/hospitalization-solicitation/register/patient-care-history",
  name:
    "hospitalization.hospitalization-solicitation.register.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Internações", link: OpenSubModule("hospitalization") },
      {
        label: "Solicitações de Internação",
        link: "/hospitalization/hospitalization-solicitation/search",
      },
      {
        label: "Cadastrar Internação",
        link: "/hospitalization/hospitalization-solicitation/register",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
