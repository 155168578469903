<template>
  <div class="emergency-bulletin-search">
    <RgSearch
      ref="rgsearch"
      v-model="mutableEmergencyBulletin"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="42"
      :max-register="30"
      :result-title="'Boletins'"
      :show-empty-message="true"
      :showFooter="hasEmergency"
      @submitFromButton="actionCleanSelection"
      @viewClickPagination="actionCleanSelection"
      @count="getCountValue"
    >
      <div slot="filters" class="emergency-bulletin-search-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectUnithealth
              id="unit-ebs"
              v-model="form.unit"
              :rules="{ required: true }"
              label="Unidade de Saúde"
              class="inputitem"
              disabled
            />
          </div>

          <div class="selectinput">
            <RgSelectSectorLegacy
              id="sector-ebs"
              v-model="form.sector"
              :class="{ 'disable unselect': !hideSector }"
              :disabled="!hideSector"
              :unit-health-id="Number(form.unit)"
              :empty-default-text="'Selecione uma Unidade'"
              :submodule-id="submoduleId"
              class="inputitem"
              label="Setor"
              default-text="Todos"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <div class="selectinput">
            <RgInput
              id="patient-name-ebs"
              ref="patient"
              v-model="form.patientName"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput">
            <RgSuggestSmartPerson
              id="specific-patient-name-ebs"
              ref="patient"
              v-model="form.specificPatientName"
              :disabled="disableDocumentation"
              :with-patient-module="['emergency']"
              :enabled-patient="false"
              filterOnlyDocument
              @selected="selectingPatient"
            />
          </div>

          <div class="selectinput toggle">
            <span :class="{ active: form.unidentifiedPatient }" class="text"
              >Somente pacientes não identificados</span
            >
            <RgToggleButton
              id="unidentified-patient-ebs"
              class="input-toggle"
              :valueSync="true"
              :value="form.unidentifiedPatient"
              @change="handleToggleUnidentifiedPatient"
            />
          </div>

          <div class="selectinput">
            <RgInput
              id="bulletin-ebs"
              ref="bulletin"
              v-model="form.bulletin"
              class="inputitem"
              placeholder="Digite o número do boletim"
              label="Boletim"
            />
          </div>

          <div class="filter-subtitle filter-title">
            <span class="period-subtitle">Período</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-order-period-ebs"
                v-model="form.initialOrderPeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                id="final-order-period-ebs"
                v-model="form.finalOrderPeriod"
                label="Data Final"
                class="date"
              />
            </div>
          </div>
        </div>
      </div>

      <div slot="search-top" class="top-button">
        <div class="tooltip-info">
          <Tooltip
            message="Pacientes não atendidos podem ser reenviados para o PEP"
            startOpen
            class="tooltip"
          >
            <IconInfoHelper slot="icon" hollow />
          </Tooltip>
        </div>

        <div class="actions">
          <div class="status-radio">
            <RadioStatusBulletin
              id="exit-options-ebs"
              v-model="form.exitOptions"
              @input="getExamStatus"
            />
          </div>
          <div class="top-buttons">
            <RgAddButton
              id="new-bulletin-ebs"
              small
              title="Cadastrar Boletim"
              class="item"
              :permission="canRegister"
              @click="registrationBulletin"
            />

            <RgEditButton
              id="edit-bulletin-ebs"
              small
              title="Editar Boletim"
              class="item"
              :permission="canEdit"
              :disabled="
                disabledButtonWhenExit || disabledButtonWhenPatientSentToPep
              "
              @click="editRegistrationBulletin"
            />

            <RgLessButton
              id="remove-bulletin-ebs"
              :permission="canRemove"
              :disabled="disabledButtonWhenExit || disabledButtonWhenSentToPep"
              title="Excluir Boletim"
              class="item"
              @click="openModalDeletion"
            />

            <RgShowButton
              id="show-bulletin-ebs"
              :disabled="hasOneSelected"
              class="item"
              title="Detalhes do Boletim"
              @click="openModalBulletinDetails"
            />

            <RgPersonCardButton
              id="edit-patient-ebs"
              :disabled="hasOneSelected || disabledPatientCareHistory"
              class="item"
              @click="editPatient"
            />

            <RgHistoryButton
              id="history-ebs"
              :disabled="hasOneSelected || disabledPatientCareHistory"
              class="item"
              title="Histórico de Atendimentos"
              @click="patientCareHistory"
            />

            <RgDropdown
              id="printer-ebs"
              :disabled="hasOneSelected"
              :item="bulletinData"
              :action-options="itemActionOptionsPrinter(bulletinData)"
              class="item"
              small
            >
              <IconPrinter slot="icon" />
            </RgDropdown>
          </div>
        </div>
      </div>

      <div class="emergency-bulletin-search-result">
        <div v-if="hasEmergency" class="content-grid">
          <div class="table">
            <RgTable ref="rgTable" :columns="columnsHeader" class="rg-table">
              <tr
                v-for="(item, index) in mutableEmergencyBulletin"
                slot="rows"
                :key="index"
                :class="{
                  selected: item.bol_id === bulletinData.bol_id,
                }"
                class="tr"
                @click="selectRow(item)"
              >
                <td
                  v-if="
                    item.enviado === 'SIM' &&
                    item.bol_motivo_nao_atendimento.length > 0
                  "
                  class="tooltip-result"
                >
                  <Tooltip class="tooltip">
                    <IconDangerHelper slot="icon" />
                    <div slot="content" class="content">
                      <span class="title">
                        Paciente não atendido. Motivo:
                      </span>
                      <ul class="list">
                        <li class="item">
                          {{ item.bol_motivo_nao_atendimento }}
                        </li>
                      </ul>
                    </div>
                  </Tooltip>
                </td>
                <td v-else class="circle-indicator">
                  <ColorIndication
                    size="13px"
                    :value="actionColorLine.get(item)"
                    class="circle"
                  />
                </td>
                <td>{{ item.bol_numero }}</td>
                <td class="patient-name">
                  <span :title="item.pes_nome" class="ellipsis">
                    {{ item.pes_nome }}
                  </span>
                </td>
                <td v-if="isWithOutput">
                  {{ item.dsg_nome }}
                </td>
                <td>{{ item.entrada }}</td>
                <td>{{ item.set_nome }}</td>
                <td>{{ item.enviado }}</td>
                <td class="user-name">
                  <span :title="item.usu_nome" class="ellipsis">
                    {{ item.usu_nome }}
                  </span>
                </td>
              </tr>
            </RgTable>
          </div>
        </div>
      </div>

      <div
        v-show="mutableEmergencyBulletin.length >= 1"
        slot="menu-bottom"
        class="bottom-button"
      >
        <div class="actions">
          <SmallButton
            id="register-exit-ebs"
            :permission="canRegisterExit"
            :disabled="disableRegisterExit"
            :backgroundColor="'#37c871'"
            :title="'Cadastrar Saída'"
            @click="registerExit"
          >
            <IconExit slot="icon" class="icon" />
          </SmallButton>

          <SmallButton
            id="delete-register-ebs"
            :permission="canDeleteRegisterExit"
            :disabled="disableDeleteRegisterExit"
            :backgroundColor="'#f96b70'"
            :title="'Excluir Saída'"
            @click="openModalDeleteRegisterExit"
          >
            <IconCancelExit slot="icon" class="icon" />
          </SmallButton>

          <RgDropdown
            id="pep-ebs"
            ref="dropDownPep"
            :disabled="disabledButtonWhenExit"
            :item="bulletinData"
            :action-options="itemActionOptionsPep(bulletinData)"
            small
            show-up
            show-left
            notBackgroundWhenDisabled
            :backgroundColor="'#ffffff'"
          >
            <IconPep slot="icon" />
          </RgDropdown>
        </div>
      </div>
    </RgSearch>

    <ModalDeleteBulletin
      id="modal-delete-ebs"
      :show="showModalDeletion"
      :maxlength="250"
      :bulletinData="bulletinData"
      @close="closeModalDeletion"
      @reSearch="reSearchTable"
    />

    <ModalBulletinDetails
      id="modal-details-ebs"
      :show="showModalDetails"
      :bulletinData="bulletinData"
      @close="actionCloseModalDetails"
    />

    <ModalDeleteRegisterExit
      id="modal-delete-exit-ebs"
      :show="showModalDeleteRegisterExit"
      :bulletinData="bulletinData"
      @close="closeModalDeleteRegisterExit"
      @reSearch="reSearchTable"
    />

    <ModalPrintEmitBulletin
      id="modal-print-ebs"
      :show="showModalEmitBulletin"
      :bulletinData="bulletinData"
      @close="closeModalEmitBulletin"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  RgInputDate,
  RgSelectUnithealth,
  RgAddButton,
  RgEditButton,
  RgShowButton,
  IconExit,
  IconCancelExit,
  RgInput,
  RgLessButton,
  RgToggleButton,
  SmallButton,
  RgSuggestSmartPerson,
  RgSelectSectorLegacy,
  ColorIndication,
  RgHistoryButton,
  RgPersonCardButton,
  Tooltip,
  IconDangerHelper,
  IconInfoHelper,
  RgDropdown,
  IconPep,
  IconPrinter,
} from "~tokio/primitive";

import {
  ModalDeleteBulletin,
  ModalBulletinDetails,
  ModalDeleteRegisterExit,
  ModalPrintEmitBulletin,
  RadioStatusBulletin,
} from "$emergency/bulletin/component";

import RgTable from "~tokio/foundation/rg-table/RgTable";
import PatientIdentificationHTML from "~tokio/primitive/html/PatientIdentificationHTML";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import moment from "moment";

export default {
  name: "EmergencyBulletinSearch",
  components: {
    RgSearch,
    RgInputDate,
    RgSelectUnithealth,
    RgSelectSectorLegacy,
    RgHistoryButton,
    RgAddButton,
    RgEditButton,
    RgInput,
    RgTable,
    RgShowButton,
    RgLessButton,
    ModalDeleteBulletin,
    ModalBulletinDetails,
    ModalDeleteRegisterExit,
    IconPrinter,
    RgToggleButton,
    RgDropdown,
    IconPep,
    IconExit,
    IconCancelExit,
    SmallButton,
    RgSuggestSmartPerson,
    ModalPrintEmitBulletin,
    ColorIndication,
    RgPersonCardButton,
    RadioStatusBulletin,
    Tooltip,
    IconDangerHelper,
    IconInfoHelper,
  },

  data() {
    return {
      mutableEmergencyBulletin: [],
      bulletinData: {},
      header: this.COLUMNS,
      form: {
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        patientName: null,
        bulletin: null,
        initialOrderPeriod: moment().subtract(1, "days").format("DD/MM/YYYY"),
        finalOrderPeriod: moment().format("DD/MM/YYYY"),
        unidentifiedPatient: false,
        exitOptions: [0, 1, 2],
        specificPatientName: "",
        pesId: null,
      },
      totalItems: 0,
      pagination: {
        limit: 30,
        offset: 0,
        current: 1,
      },
      bolId: null,
      reason: null,
      unidentifiedPatient: null,
      disableRegisterExit: true,
      disableDeleteRegisterExit: true,
      disableDocumentation: false,
      showModalDeletion: false,
      showModalDetails: false,
      showModalDeleteRegisterExit: false,
      showModalEmitBulletin: false,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    sectorId() {
      return Number(this.selectedBulletin?.set_id);
    },

    submoduleId() {
      return 34;
    },

    hasOneSelected() {
      return !(this.bulletinData && this.bulletinData.bol_id > 0);
    },

    hasEmergency() {
      return this.mutableEmergencyBulletin.length > 0;
    },

    hideSector() {
      return this.form.unit > 0;
    },

    disabledButtonWhenExit() {
      const bulletinWithExit = this.bulletinData?.saida !== "0";
      return this.hasOneSelected || bulletinWithExit;
    },

    disabledButtonWhenPatientSentToPep() {
      const bulletinPatientSentToPep =
        this.bulletinData?.enviado === "SIM" &&
        this.bulletinData?.pes_id.length > 0;

      return this.hasOneSelected || bulletinPatientSentToPep;
    },

    disabledButtonWhenSentToPep() {
      const bulletinSentToPep = this.bulletinData?.enviado === "SIM";
      return this.hasOneSelected || bulletinSentToPep;
    },

    disabledPatientCareHistory() {
      return (
        this.bulletinData &&
        !this.bulletinData.pes_id &&
        !this.bulletinData.pci_id
      );
    },

    selectedBulletin() {
      return this.$store.getters["Emergency/Bulletin/GET_SELECTED_BULLETIN"];
    },

    permission() {
      return {
        hasPermissionRegisterBulletin: !!this.$Permissions.global.has(
          "emergencia.boletim.incluirEntrada",
          this.unitHealthId,
        ),
        hasPermissionRetroactiveBulletin: !!this.$Permissions.global.has(
          "emergencia.boletim.incluirEntradaRetroativa",
          this.unitHealthId,
        ),
        hasPermissionEditBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.alterarEntrada",
          this.sectorId,
        ),
        hasPermissionRemoveBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.excluirEntrada",
          this.sectorId,
        ),
        hasPermissionRegisterExitBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.incluirSaida",
          this.sectorId,
        ),
        hasPermissionDeleteRegisterExitBulletin: !!this.$Permissions.sector.has(
          "emergencia.boletim.excluirSaida",
          this.sectorId,
        ),
        hasPermissionEmergencyPEP: !!this.$Permissions.sector.has(
          "emergencia.emergenciaPEP.utilizarEmergenciaPep",
          this.sectorId,
        ),
      };
    },

    canRegister() {
      return (
        this.permission.hasPermissionRegisterBulletin ||
        this.permission.hasPermissionRetroactiveBulletin
      );
    },

    canEdit() {
      return this.permission.hasPermissionEditBulletin;
    },

    canRemove() {
      return this.permission.hasPermissionRemoveBulletin;
    },

    canRegisterExit() {
      return this.permission.hasPermissionRegisterExitBulletin;
    },

    canDeleteRegisterExit() {
      return this.permission.hasPermissionDeleteRegisterExitBulletin;
    },

    canEmergencyPep() {
      return this.permission.hasPermissionEmergencyPEP;
    },

    preferences() {
      return {};
    },

    actionColorLine() {
      return {
        get: (item) => {
          if (item.saida === "0") {
            return "#ffd42a";
          }
          if (item.saida !== "0") {
            return "#37C871";
          }
        },
      };
    },

    isWithOutput() {
      return (
        this.form.exitOptions.includes(1) || this.form.exitOptions.length === 0
      );
    },

    columnsHeader() {
      return this.form.exitOptions.includes(1) ||
        this.form.exitOptions.length === 0
        ? this.COLUMNS
        : [
            { name: "", key: "saida" },
            { name: "Boletim", key: "bol_numero" },
            { name: "Paciente", key: "pes_nome" },
            { name: "Entrada", key: "entrada" },
            { name: "Setor", key: "set_nome" },
            { name: "Enviado PEP", key: "enviado" },
            { name: "Usuário", key: "usu_nome" },
          ];
    },
  },

  watch: {
    bulletinData(pValue) {
      const hasExit = pValue?.saida ? pValue.saida !== "0" : false;
      const hasSentPep = pValue?.enviado ? pValue.enviado === "SIM" : false;

      this.disableRegisterExit = pValue.bol_id ? hasExit || hasSentPep : true;
      this.disableDeleteRegisterExit = pValue.bol_id
        ? !(!hasSentPep && hasExit)
        : true;
    },

    "form.patientName"(pValue) {
      pValue?.length > 0
        ? (this.disableDocumentation = true)
        : (this.disableDocumentation = false);
    },
  },

  created() {
    this.COLUMNS = [
      { name: "", key: "saida" },
      { name: "Boletim", key: "bol_numero" },
      { name: "Paciente", key: "pes_nome" },
      { name: "Tipo de Saída", key: "dsg_nome" },
      { name: "Entrada", key: "entrada" },
      { name: "Setor", key: "set_nome" },
      { name: "Enviado PEP", key: "enviado" },
      { name: "Usuário", key: "usu_nome" },
    ];

    this.unidentifiedPatient = "PACIENTE NÃO IDENTIFICADO";
  },

  mounted() {
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/emergency/bulletin",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Emergency/Bulletin/RESET_FILTER_EMERGENCY_BULLETIN");
    }
  },

  methods: {
    async searchFilter(pData) {
      try {
        if (this.$refs.rgTable) {
          this.$refs.rgTable.setScrollDefaultTop();
        }
        return await this.$store.dispatch(
          "Emergency/Bulletin/SEARCH_BULLETINS",
          pData,
        );
      } catch (pErr) {
        this.$toaster.error(pErr);
      }
    },

    generateFilter() {
      const variables = {
        arrFormData: {
          bol_id_setores: this.form.sector,
          bol_numero: this.form.bulletin,
          periodoInicial: this.form.initialOrderPeriod,
          periodoFinal: this.form.finalOrderPeriod,
          pes_nome: this.form.patientName,
          pes_id: this.form.pesId,
          smd_id: this.submoduleId,
          uns_id: this.form.unit,
          somenteNaoIdentificados: this.form.unidentifiedPatient,
        },
      };

      const selectedExitOptions = this.form.exitOptions;

      const allOrNoFilter =
        selectedExitOptions.length === 0 || selectedExitOptions.length === 3;

      variables.arrFormData.saida = allOrNoFilter
        ? -1
        : selectedExitOptions.length === 1
        ? selectedExitOptions[0]
        : selectedExitOptions.sort().join();

      this.$store.commit(
        "Emergency/Bulletin/SET_FILTER_EMERGENCY_BULLETIN",
        this.form,
      );

      return variables;
    },

    getExamStatus() {
      this.$loader.start("Carregando...");
      this.$refs.rgsearch.submitForm(true);
      this.$loader.finish();
    },

    async reSearchTable() {
      try {
        await this.$refs.rgsearch.performSearch();
        this.bulletinData = {};
      } catch (pErr) {
        console.log(pErr);
      }
    },

    async selectRow(pItem) {
      if (this.bulletinData.bol_id === pItem.bol_id) {
        this.bulletinData = {};
        this.bolId = null;
      } else {
        this.bolId = Number(pItem.bol_id);
        this.bulletinData = pItem;
      }

      this.$forceUpdate();

      this.$store.commit(
        "Emergency/Bulletin/SELECT_BULLETIN",
        this.bulletinData,
      );
    },

    registrationBulletin() {
      this.$store.commit("Emergency/Bulletin/UNSELECT_BULLETIN");
      this.$router.push({
        name: "emergency.bulletin.registration",
      });
    },

    editRegistrationBulletin() {
      this.$store.commit(
        "Emergency/Bulletin/SELECT_BULLETIN",
        this.bulletinData,
      );

      this.$router.push({
        name: "emergency.bulletin.edit-registration",
      });
    },

    editPatient() {
      this.$store.commit(
        "Person/Patient/SET_PERSON_ID",
        Number(this.bulletinData.pes_id),
      );
      this.$router.push({
        name: "emergency.bulletin.edit-patient",
      });
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Emergency/Bulletin/GET_FILTER_EMERGENCY_BULLETIN"
        ];

        if (existFilterData) {
          this.$loader.start("Carregando boletins...");

          this.form.unit = existFilterData.unit;
          this.form.sector = existFilterData.sector;
          this.form.patientName = existFilterData.patientName;
          this.form.bulletin = existFilterData.bulletin;
          this.form.initialOrderPeriod = existFilterData.initialOrderPeriod;
          this.form.finalOrderPeriod = existFilterData.finalOrderPeriod;
          this.form.unidentifiedPatient = existFilterData.unidentifiedPatient;
          this.form.exitOptions = existFilterData.exitOptions;

          if (this.$refs.patient && existFilterData.pesId) {
            this.$refs.patient.fillPatientById(existFilterData.pesId);
          }

          this.actionCleanSelection();

          this.reSearchTable();

          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
        this.$loader.finish();
      }
    },

    handleToggleUnidentifiedPatient(pValue) {
      this.form.unidentifiedPatient = pValue;
    },

    actionCleanSelection() {
      this.bulletinData = {};
    },

    selectingPatient(pValue) {
      if (pValue && pValue.source) {
        const { pes_id } = pValue.source;

        this.form.pesId = pes_id;
      } else {
        this.form.pesId = 0;
        this.form.specificPatientName = "";
      }
    },

    cleanForm() {
      this.bulletinData = {};
      this.mutableEmergencyBulletin = [];
      this.form = {
        unit: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        patientName: null,
        bulletin: null,
        initialOrderPeriod: moment().subtract(1, "days").format("DD/MM/YYYY"),
        finalOrderPeriod: moment().format("DD/MM/YYYY"),
        unidentifiedPatient: false,
        exitOptions: [0, 1, 2],
        specificPatientName: "",
        pesId: null,
      };
    },

    getCountValue(pValue) {
      this.totalItems = Number(pValue) || 0;
    },

    openModalBulletinDetails() {
      this.showModalDetails = true;
    },

    actionCloseModalDetails() {
      this.showModalDetails = false;
    },

    patientCareHistory() {
      const patientInfo = this.bulletinData;
      this.$router.push({
        name: "emergency.bulletin.patient-care-history",
        params: patientInfo,
      });
    },

    registerExit() {
      this.$store.commit(
        "Emergency/Bulletin/SELECT_BULLETIN",
        this.bulletinData,
      );

      this.$router.push({
        name: "emergency.bulletin.register-exit",
      });
    },

    openModalDeleteRegisterExit() {
      this.showModalDeleteRegisterExit = true;
    },

    closeModalDeleteRegisterExit() {
      this.showModalDeleteRegisterExit = false;
    },

    openModalDeletion() {
      this.showModalDeletion = true;
    },

    closeModalDeletion() {
      this.showModalDeletion = false;
    },

    async patientIdentification() {
      const personData = await this.$store.dispatch(
        "Emergency/Bulletin/BASIC_PATIENT_INFO",
        {
          pesId: this.bulletinData.pes_id,
        },
      );

      const recordNumber = personData.patient.record_numbers;
      const validate =
        recordNumber && recordNumber.rows.length > 0
          ? recordNumber.rows[0].ppr_numero
          : "";

      const html = PatientIdentificationHTML({
        paciente: personData.pes_nome,
        pac_prontuario_unico: personData.patient.pac_prontuario_unico,
        pes_nascimento: this.$utils.date.BrazilianDateFormat(
          personData.pes_nascimento,
        ),
        ppr_numero: validate,
      });

      this.$utils.print.printHtml(html);
    },

    modalEmitBulletin() {
      this.showModalEmitBulletin = true;
    },

    closeModalEmitBulletin() {
      this.showModalEmitBulletin = false;
    },

    sendBulletinPep() {
      this.$loader.start();

      this.$store
        .dispatch(
          "Emergency/Bulletin/SEND_BULLETIN_PEP",
          this.bulletinData.bol_id,
        )
        .then((data) => {
          this.$toaster.success("Boletim enviado para o PEP");
          this.reSearchTable();
        })
        .catch((err) => {
          const hasIndigent =
            this.bulletinData.pes_nome === "PACIENTE NÃO IDENTIFICADO";

          const emptyValue = /É preciso atualizar os seguintes dados do Paciente/.test(
            err.message,
          );
          if (emptyValue && hasIndigent) {
            const indexMessage = err.message.indexOf("<br><br>É");

            err.message = err.message.slice(0, indexMessage);
          }

          const fillLocal = /Local de atendimento não mapeado:/.test(
            err.message,
          );
          if (fillLocal) {
            const local = `${hasIndigent ? "<br><br>" : ""}${
              this.bulletinData.lca_nome
            } <br/>`;
            err.message = err.message + local;
          }

          this.$toaster.error(
            err.message,
            "Falha ao enviar boletim para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    cancelSendBulletinPep() {
      this.$loader.start();
      this.$store
        .dispatch(
          "Emergency/Bulletin/CANCEL_SEND_BULLETIN_PEP",
          this.bulletinData.bol_id,
        )
        .then((data) => {
          this.$toaster.success("Cancelamento do boletim enviado para o PEP");
          this.reSearchTable();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao enviar cancelamento do boletim para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    resendBulletinPep() {
      this.$loader.start();
      this.$store
        .dispatch(
          "Emergency/Bulletin/RESEND_BULLETIN_PEP",
          this.bulletinData.bol_id,
        )
        .then((data) => {
          this.$toaster.success("Boletim reenviado para o PEP");
          this.reSearchTable();
        })
        .catch((err) => {
          this.$toaster.error(
            err.message,
            "Falha ao reenviar boletim para o PEP",
          );
        })
        .finally(() => {
          this.$loader.finish();
        });
    },

    itemActionOptionsPep(pItem) {
      const alreadyWasInPep = pItem && pItem.enviado === "SIM";

      return [
        {
          label: "Enviar boletim para o PEP",
          action: this.sendBulletinPep,
          disable:
            alreadyWasInPep ||
            !this.canEmergencyPep ||
            pItem.bol_motivo_nao_atendimento?.length > 0,
        },
        {
          label: "Cancelar boletim no PEP",
          action: this.cancelSendBulletinPep,
          disable:
            !alreadyWasInPep ||
            !this.canEmergencyPep ||
            pItem.bol_motivo_nao_atendimento?.length > 0 ||
            pItem.saida !== "0",
        },
        {
          label: "Reenviar boletim no PEP",
          action: this.resendBulletinPep,
          disable:
            !this.canEmergencyPep ||
            !alreadyWasInPep ||
            (alreadyWasInPep && pItem.bol_motivo_nao_atendimento?.length < 1),
        },
      ];
    },

    itemActionOptionsPrinter() {
      return [
        {
          label: "Imprimir identificação do paciente",
          action: this.patientIdentification,
          disable: !(this.bulletinData && this.bulletinData.pes_id),
        },
        {
          label: "Emitir boletim de atendimento médico",
          action: this.modalEmitBulletin,
          disable: !this.bulletinData,
        },
      ];
    },

    setReason(value) {
      this.reason = value;
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialOrderPeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalOrderPeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
  },
};
</script>
