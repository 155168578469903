import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SavePreferences($preferences: [SavePreferencesInputType]) {
    SavePreferences(preferences: $preferences) {
      success
      message
    }
  }
`;

export default async (store, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.SavePreferences;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0]);
  }
};
