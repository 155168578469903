<style src="./RgDocumentInput.scss" lang="scss"></style>
<template>
  <div class="document-input">
    <form @submit.stop.prevent="sendValues">
      <RgValidatorForm ref="validator">
        <RgInputCpf
          v-if="documentTypeId === 31"
          id="cpf"
          v-model="value"
          placeholder="000.000.000-00"
          :disabled="disabled"
        />
        <RgInputSuscard
          v-else-if="documentTypeId === 41"
          id="suscard"
          v-model="value"
          :disabled="disabled"
        />
        <RgInputRg
          v-else-if="documentTypeId === 28"
          id="rg"
          v-model="value"
          :disabled="disabled"
        />
        <RgInputCnh
          v-else-if="documentTypeId === 42"
          id="cnh"
          v-model="value"
          :disabled="disabled"
        />
        <RgInput v-else id="document" label="Documento" :disabled="disabled" />
      </RgValidatorForm>
    </form>
  </div>
</template>

<script>
import {
  RgInput,
  RgInputCnh,
  RgInputCpf,
  RgInputSuscard,
  RgInputRg,
  RgValidatorForm,
} from "~tokio/primitive";

export default {
  name: "RgDocumentInput",

  components: {
    RgInput,
    RgInputCpf,
    RgInputSuscard,
    RgInputRg,
    RgInputCnh,
    RgValidatorForm,
  },

  props: {
    documentTypeId: {
      default: 0,
    },
    valueEdit: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value(pValue) {
      this.set(pValue);
      this.sendValues();
    },
    valueEdit(pValue) {
      this.value = pValue;
    },
  },

  mounted() {},

  methods: {
    set(val) {
      this.$emit("input", val);
    },

    clearInput() {
      this.value = "";
    },

    validate() {
      return this.$refs.validator.validate();
    },

    async sendValues() {
      const validation = await this.$refs.validator.validate();
      this.$emit("enable", validation);
    },
  },
};
</script>
