import IMPORT_SIGTAP from "./ImportSigtap";
import IMPORT_BDSIA from "./ImportBdsia";
import HISTORY_IMPORT from "./HistoryImport";

import SEARCH_EXTERNAL_LIST_SIGTAP from "./SearchExternalListSigtap";
import SEARCH_EXTERNAL_LIST_BDSIA from "./SearchExternalListBdsia";
import STATUS_PROCEDURES_SIGTAP from "./StatusProceduresSigtap";
import STATUS_PROCEDURES_BDSIA from "./StatusProceduresBdsia";
import LIST_QUEUE_SIGTAP from "./ListQueueSigtap";
import LIST_QUEUE_BDSIA from "./ListQueueBdsia";
import TIME_PROCEDURE_SIGTAP from "./TimeProcedureSigtap";
import TIME_PROCEDURE_BDSIA from "./TimeProcedureBdsia";
import SEARCH_HISTORY_IMPORT from "./SearchHistoryImport";
import SEARCH_ALL_COMPETENCE from "./SearchAllCompetence";
import SEARCH_LOGGER_ERROR from "./SearchLoggerError";
import UPLOAD_LINKS_SIGTAP_BDSIA from "./upload-links-sigtap-bdsia/UploadLinksSigtapBdsia";
import SEARCH_AVAILABLE_COMPETENCE from "./SearchAvailableCompetence";

export default {
  IMPORT_SIGTAP,
  IMPORT_BDSIA,
  HISTORY_IMPORT,
  SEARCH_EXTERNAL_LIST_SIGTAP,
  SEARCH_EXTERNAL_LIST_BDSIA,
  STATUS_PROCEDURES_SIGTAP,
  STATUS_PROCEDURES_BDSIA,
  LIST_QUEUE_SIGTAP,
  LIST_QUEUE_BDSIA,
  TIME_PROCEDURE_SIGTAP,
  TIME_PROCEDURE_BDSIA,
  SEARCH_HISTORY_IMPORT,
  SEARCH_ALL_COMPETENCE,
  SEARCH_LOGGER_ERROR,
  UPLOAD_LINKS_SIGTAP_BDSIA,
  SEARCH_AVAILABLE_COMPETENCE,
};
