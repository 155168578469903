<template>
  <div v-if="show" class="modal-view-observation">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <span class="title">
          {{ "Visualizar Observação" }}
        </span>
      </div>

      <div slot="body" class="body">
        <div class="textarea">
          <RgTextArea
            ref="reason"
            v-model="form.reason"
            :disabled="viewObservations"
          />
        </div>

        <div class="textarea">
          <RgTextArea
            ref="observation"
            v-model="form.observation"
            :disabled="viewObservations"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgTextArea } from "~tokio/primitive";

export default {
  name: "ModalViewObservation",
  components: {
    RgBaseModal,
    RgTextArea,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    viewObservations: {
      type: Boolean,
      default: false,
    },
    viewData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        reason: "",
        observation: "",
      },
    };
  },
  watch: {
    viewData(pView) {
      if (pView) {
        this.form.reason = this.$utils.sanitize.removeHtmlTag(
          this.viewData.fio_motivo,
        );

        this.form.observation = this.$utils.sanitize.removeHtmlTag(
          this.viewData.fio_observacao,
        );
      }
    },
  },
  methods: {
    close(pActionTaken = false) {
      this.$emit("close", pActionTaken === true);
    },
  },
};
</script>
