import Register from "$pharmacy/submodules/register/store";
import Satellite from "$pharmacy/submodules/satellite/store";
import Central from "$pharmacy/submodules/central/store";
import Report from "$pharmacy/submodules/report/store";

import actions from "./actions";
// import state from "./state";
// import getters from "./getters";
// import mutations from "./mutations/";

export default {
  namespaced: true,
  // state,
  actions,
  // getters,
  // mutations,
  modules: {
    Register,
    Satellite,
    Central,
    Report,
  },
};
