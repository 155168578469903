<style src="./AppointmentDisabledPeriodsReport.scss" lang="scss" scoped></style>

<template>
  <div class="disabled-periods-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-adpr"
          v-model="form.uns_id"
          permission="consulta.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-adpr"
          v-model="form.set_id"
          :unitHealth="form.uns_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          default-text="Todos"
          empty-default-text="Todos"
          class="inputitem"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          id="ocp-nome-adpr"
          v-model="form.ocp_nome"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          :employeeId="professionalBond"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          label="Ocupação"
          class="inputitem"
          @selected="selectedCbo"
        />
      </div>

      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-adpr"
          v-model="form.pes_nome"
          :maxlength="100"
          :ocuppationId="form.ocp_id"
          :unitHealthId="form.uns_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          label="Profissional"
          class="inputitem"
          @selected="selectedEmployee"
        />
      </div>

      <div class="selectinput">
        <RgComboboxReason
          id="reason-id-adpr"
          v-model="form.reason_id"
          :isAppointment="true"
          label="Motivo Inabilitação"
          default-text="Todos"
          class="inputitem"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período Inabilitação</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-adpr"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="final-date-adpr"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          label="Data Final"
          class="inputitem mg"
        />
      </div>

      <div class="filter-title">
        <span class="title">Ordenação</span>
      </div>

      <div class="selectinput">
        <RgComboboxOrderBy
          id="orderby-adpr"
          v-model="form.orderby"
          :data="ORDERS"
          :rules="{ required: true }"
          label="Ordenar por"
          class="inputitem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgSuggestCbo,
  RgSuggestEmployee,
  RgInputDate,
  RgComboboxReason,
  RgComboboxOrderBy,
} from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: null,
  ocp_nome: null,
  ocp_id: null,
  pes_nome: null,
  pes_id: null,
  reason_id: null,
  reason: null,
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  orderby: 1,
  orderByLabel: "Data e Hora",
};

export default {
  name: "AppointmentDisabledPeriodsReport",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgSuggestCbo,
    RgSuggestEmployee,
    RgInputDate,
    RgComboboxReason,
    RgComboboxOrderBy,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Períodos Inabilitados",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      professionalBond: null,
    };
  },
  computed: {
    printReportCss() {
      return "@page {size: landscape;}";
    },
    columnTable() {
      return this.COLUMN;
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id > 0);
    },
    getNameSmartTable() {
      return "AppointmentDisabledPeriodsReport";
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },
  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
      }
    },
    "form.ocp_id"(pValue) {
      if (!pValue && !this.form.pes_id) {
        this.professionalBond = null;
      }
    },
    "form.pes_id"(pValue) {
      if (!pValue && !this.form.ocp_id) {
        this.professionalBond = null;
      }
    },
    "form.reason_id"(pValue) {
      if (!pValue) {
        this.form.reason = "";
      }

      if (pValue && typeof pValue === "object") {
        this.form.reason = pValue[0].text;
      }
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.COLUMN = [
      { name: "Consulta", key: "DATA_CONSULTA" },
      { name: "Profissional", key: "PROFISSIONAL", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
      {
        name: "Motivo Inabilitação",
        key: "MOTIVO_INABILITACAO",
        align: "left",
      },
      { name: "Data da Inabilitação", key: "DATA_HORA_INABILITACAO" },
      { name: "Usuário", key: "USUARIO", align: "left" },
    ];

    this.ORDERS = [
      { value: 1, text: "Data e Hora" },
      { value: 2, text: "Setor" },
      { value: 3, text: "Profissional" },
    ];
  },
  methods: {
    buildFilter() {
      const payLoad = {
        uns_id: this.form.uns_id || null,
        set_id: this.form.set_id || null,
        ocp_id: this.form.ocp_id || null,
        pes_id: this.form.pes_id || null,
        reason_id: Number(this.form.reason_id) || null,
        set_nome: this.form.set_nome || "",
        ocp_nome: this.form.ocp_nome || "",
        pes_nome: this.form.pes_nome || "",
        usu_nome: this.form.usu_nome || "",
        reason: this.form.reason || "",
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        orderby: this.form.orderby,
        orderByLabel: this.form.orderByLabel,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_DISABLED_PERIODS_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
      this.form.ocp_nome = null;
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.form.ocp_nome = pValue.source.ocp_nome;
        this.$loader.finish();
      } else {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
      }
    },

    selectedEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_id, pes_nome } = source;
        this.form.pes_id = pes_id;
        this.form.pes_nome = pes_nome;
        this.professionalBond =
          pValue.source?.employeeRelationship?.vin_id_funcionarios;
      } else {
        this.form.pes_id = null;
        this.form.pes_nome = null;
        this.professionalBond = null;
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.professionalBond = null;

      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
