import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHospitalizationDischargedReport(
    $initialDate: String
    $finalDate: String
    $uns_id: Int
    $orderby: Int
    $orderByLabel: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchHospitalizationDischargedReport(
      initialDate: $initialDate
      finalDate: $finalDate
      uns_id: $uns_id
      orderby: $orderby
      orderByLabel: $orderByLabel
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        NASCIMENTO
        DATA_SAIDA
        TIPO_ALTA
        ENDERECO
        BAIRRO
        MUNICIPIO
        TELEFONE
        SETOR
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchHospitalizationDischargedReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
