<template>
  <Modulebox
    ref="modulebox"
    title="Saída por Receita de Produtos e Medicamentos"
    class="pharmacy-satellite-output"
  >
    <div class="pharmacy-satellite-output-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Paciente" class="module-patient">
          <div class="tooltips">
            <Tooltip class="tooltip-print">
              <IconInfoHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Última entrega de produto:
                    {{ delivery.far_nome || "-" }} - Saída:
                    {{ delivery.rec_data || "-" }} - Retorno:
                    {{ delivery.rec_retorno || "-" }}
                  </span>
                </div>
              </div>
            </Tooltip>
          </div>
          <div class="content-inputs">
            <RgSuggestPatientInformation
              id="patient"
              ref="patientInfoSuggest"
              v-model="suggestPatient"
              :disableEditPatient="disableEditPatient || disabledByModal"
              :rules="{ forceSelection: true, required: true }"
              :disabledOnlySuggest="mutableProductList.length > 0"
              :enabled-patient="false"
              :is-patient="false"
              class="patient-input"
              @patientInfo="getPatientData"
            />
          </div>
        </FormBase>

        <FormBase title="Profissional" class="register-form">
          <section class="pharmacy-satellite-output-purchase-data-form">
            <RgSelectPharmacyEmployee
              ref="employee"
              v-model="form.employee"
              onlyActive
              :disabled="!form.pcf_id || disabledByModal"
              :class="{ disable: !form.pcf_id || disabledByModal }"
              @change="selectedEmployee"
            />
            <RgSelectUnithealthPharmacy
              ref="unitHealth"
              v-model="form.unitHealth"
              :disabled="
                mutableProductList.length > 0 ||
                !this.form.pes_id ||
                disabledByModal
              "
              :class="{
                disable: mutableProductList.length > 0 || !this.form.pes_id,
              }"
              :rules="{ required: true }"
            />
            <RgSelectPharmacy
              ref="pharmacy"
              v-model="form.pharmacy"
              byUser
              :unitHealthId="form.unitHealth"
              :userId="USER_LOGIN_ID"
              :typePharmacy="2"
              :disabled="
                !form.unitHealth ||
                mutableProductList.length > 0 ||
                !this.form.pes_id ||
                disabledByModal
              "
              :class="{
                disable:
                  !form.unitHealth ||
                  mutableProductList.length > 0 ||
                  !this.form.pes_id,
              }"
              :rules="{ required: true }"
              @change="selectedPharmacy"
            />
            <RgInputDate
              ref="purchaseData"
              v-model="form.purchaseData"
              label="Data da Saída"
              :disabled="blockedDateRevenue || !form.pcf_id || disabledByModal"
              :class="{
                disable: blockedDateRevenue || !form.pcf_id,
              }"
              :max-date="new Date()"
              :rules="{ required: true }"
            />
            <RgInputDate
              ref="returnDate"
              v-model="form.returnDate"
              label="Data do Retorno"
              :disabled="!form.pcf_id || disabledByModal"
              :class="{ disable: !form.pcf_id }"
              :min-date="getMinDateInitial"
              :rules="{ fn: minDate }"
            />
          </section>
        </FormBase>

        <FormBase
          title="Medicamentos e ou Produtos"
          class="pharmacy-satellite-output-destination-pharmacy-form"
        >
          <div class="tooltips">
            <Tooltip class="tooltip-print">
              <IconExclamationHelper slot="icon" />
              <div slot="content" class="content">
                <div class="title">
                  <span>
                    Ao Imprimir o comprovante de saída do Produto ou
                    Medicamento, o registro é finalizado e salvo. E pode ser
                    visualizado na tela de busca de movimentações
                  </span>
                </div>
              </div>
            </Tooltip>
          </div>
          <section class="pharmacy-satellite-output-products-form">
            <RgSelectProduct
              ref="product"
              v-model="form.product"
              :pharmacyId="Number(form.pharmacy)"
              :rules="{ required: true }"
              :disabled="!form.pharmacy || !form.pcf_id || disabledByModal"
              :class="{
                disable: !form.pharmacy || !form.pcf_id,
              }"
              :typeMovimentation="1"
              @change="selectedProduct"
            />

            <RgSelectTypePresentation
              id="presentation"
              ref="presentation"
              v-model="form.presentation"
              label="Apresentação"
              :disabled="true"
              :class="{ disable: true }"
              :rules="{ required: true }"
            />
            <label
              class="checkbox"
              :disabled="!this.form.product || !form.pcf_id || disabledByModal"
              :class="{
                disable: !this.form.product || !form.pcf_id,
              }"
            >
              <input v-model="form.continueUse" type="checkbox" />
              <span> Uso Contínuo </span>
            </label>
            <div></div>
            <RgInputNumber
              v-if="!stockLotExpiration"
              ref="inventory"
              v-model="form.inventory"
              :rules="{ required: true }"
              label="Estoque"
              placeholder="000000000"
              readonly
              :class="{ disable: true }"
              :maxlength="10"
            />
          </section>
          <section class="pharmacy-satellite-output-products-info-form">
            <RgSelectLotExpiration
              v-if="stockLotExpiration"
              id="batch"
              ref="batch"
              v-model="form.batch"
              :disabled="!form.product || !form.pcf_id || disabledByModal"
              :class="{
                disable: !form.product || !form.pcf_id,
              }"
              :pharmacyId="Number(form.pharmacy)"
              :productId="Number(form.product)"
              :rules="{ required: true }"
              @change="selectedBatch"
            />
            <div v-else></div>
            <RgInput
              v-if="stockLotExpiration"
              ref="inventory"
              v-model="form.inventory"
              :rules="{ required: true }"
              label="Estoque"
              placeholder="000000000"
              readonly
              disabled
              :class="{ disable: true }"
              :maxlength="10"
            />
            <div v-else></div>
            <RgInputNumber
              ref="amountPrescribed"
              v-model="form.amountPrescribed"
              :rules="{ required: true, fn: minValue }"
              :class="{
                disable: !form.product || !form.pcf_id,
              }"
              :readonly="!form.product || disabledByModal"
              :disabled="!form.product || disabledByModal"
              label="Prescrição doses"
              placeholder="000000000"
              :maxlength="10"
            />
            <RgInputNumber
              ref="amountSolicited"
              v-model="form.amountSolicited"
              :rules="{ required: true, fn: validateAmountSolicited }"
              :class="{
                disable: !form.product || !form.pcf_id,
              }"
              :readonly="!form.product || disabledByModal"
              :disabled="!form.product || disabledByModal"
              label="Quant. Solicitada"
              placeholder="000000000"
              :maxlength="10"
            />

            <RgInputNumber
              ref="amountDismissed"
              v-model="form.amountDismissed"
              :rules="{ required: true, fn: validateAmountDismissed }"
              :class="{
                disable: !form.product || !form.pcf_id,
              }"
              :readonly="!form.product || disabledByModal"
              :disabled="!form.product || disabledByModal"
              label="Quant. Dispensada"
              placeholder="000000000"
              :maxlength="10"
            />

            <div class="actions">
              <RgCleanButton
                :disabled="!form.pcf_id"
                @click="openModalConfirmField"
              />

              <RgAddButton
                id="add-btn"
                title="Adicionar Produto/Medicamento"
                :disabled="!form.pcf_id"
                @click="saveRevenue"
              />
            </div>
          </section>

          <div class="pharmacy-satellite-output-table">
            <hr class="hr" />
            <SmartTable
              ref="smartTable"
              name="PharmacyProductType"
              :columns="setColumn"
              :body="mutableProductList"
              :total="mutableProductList.length"
              :showPagination="false"
              :initial-columns="9"
              toggleSelected
              removeBtnColumns
              @getLine="selectLine"
            >
              <div slot="top-buttons" class="top-buttons">
                <RgLessButton
                  id="delete-btn"
                  title="Excluir Produto/Medicamento selecionado"
                  :disabled="!hasSelectedProductTable"
                  @click="openModalDeleteProduct"
                />

                <RgPrinterButton
                  title="Comprovante de Recebimento"
                  :disabled="mutableProductList.length === 0"
                  @click="printerRevenue"
                />
              </div>
            </SmartTable>
          </div>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="pharmacy-satellite-output-footer">
      <MediumButton id="cancel-btn" label="Voltar" @click="cancel" />
    </div>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteProduct"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
    <ModalConfirmDefault
      id="modal-confirm-default"
      v-bind="propsModalConfirmDefault"
      class="modal-confirm-return"
      buttonsRight
      @getYes="saveMovement"
      @getOut="closeModalConfirmRevenue"
      @close="closeModalConfirmRevenue"
    />
    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmField"
      @getYes="cleanForm"
      @getOut="closeModalConfirmField"
      @close="closeModalConfirmField"
    />

    <ModalTypesPrint
      id="modal-types-print"
      :show="modalTypesPrint"
      :infosPrint="infosPrint"
      :dataPrint="mutableProductList"
      :selectedRow="dataRequestPrint"
      @close="closeModalTypesPrint"
    />
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInputDate,
  RgCleanButton,
  RgAddButton,
  MediumButton,
  RgInputNumber,
  RgInput,
  Tooltip,
  IconExclamationHelper,
  RgPrinterButton,
  ModalConfirmFieldClearing,
  ModalConfirmDefault,
  RgSuggestPatientInformation,
  RgLessButton,
  IconInfoHelper,
  ModalConfirmDeletion,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";
import moment from "moment";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import { mapGetters } from "vuex";
import ModalTypesPrint from "$pharmacy/submodules/satellite/components/modal/modal-types-print/ModalTypesPrint";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgSelectPharmacyEmployee from "$pharmacy/submodules/satellite/components/select/rg-select-pharmacy-employee/RgSelectPharmacyEmployee";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration.vue";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";

const FORM = {
  employee: null,
  unitHealth: null,
  pharmacy: null,
  purchaseData: moment().format("DD/MM/YYYY"),
  returnDate: null,
  continueUse: false,
  amountPrescribed: null,
  product: null,
  presentation: null,
  batch: null,
  lot: null,
  expirationDate: null,
  inventory: null,
  amountDismissed: null,
  amountSolicited: null,
  lotMinLimitDateSelect: null,
  pcf_id: null,
};

export default {
  name: "PharmacySatelliteRevenue",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectTypePresentation,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectPharmacyEmployee,
    RgSelectUnithealthPharmacy,
    RgInputDate,
    RgInputNumber,
    RgInput,
    RgCleanButton,
    RgAddButton,
    MediumButton,
    SmartTable,
    Tooltip,
    IconExclamationHelper,
    RgSelectLotExpiration,
    RgPrinterButton,
    ModalConfirmFieldClearing,
    ModalConfirmDefault,
    RgSuggestPatientInformation,
    RgLessButton,
    IconInfoHelper,
    ModalConfirmDeletion,
    ModalTypesPrint,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      mutableProductList: [],
      selectedProductRevenue: null,
      selectedProductRevenueIndex: null,
      outputType: null,
      code: null,
      product: null,
      presentationType: null,
      employeeName: null,
      expirationBatch: null,
      hasSelectedProductTable: false,

      modalConfirmField: false,
      modalDeleteMovement: false,
      suggestPatient: null,
      pharmacyName: null,
      modalConfirmReturn: false,
      disableEditPatient: false,
      delivery: {},
      infosPrint: {},
      dataRequestPrint: {},
      modalTypesPrint: false,
    };
  },

  computed: {
    ...mapGetters({
      lastPersonIdSaved: "Person/Patient/GET_LAST_PERSON_ID_SAVE",
      USER_LOGIN_ID: "Login/GET_USER_ID",
      clientName: "Login/GET_CLIENT_NAME",
      userName: "Login/GET_USER_NAME",
      lastFormSaved: "Pharmacy/Satellite/GET_FORM_SATELLITE",
      mutableLastProductList: "Pharmacy/Satellite/GET_TABLE_SATELLITE",
      unitHealth: "Login/GET_UNIT_HEALTH",
    }),

    propsModalConfirmDefault() {
      const show = this.modalConfirmReturn;

      const title = `A Data de Saída da medicação é menor que a Data do Retorno da Última Entrega`;
      const subtitle = `Deseja dispensar o medicamento?`;
      const message = `Última entrega de Produto: ${this.delivery.far_nome}  <br> Saída: ${this.delivery.rec_data} &nbsp;&nbsp; Retorno: ${this.delivery.rec_retorno}`;
      const yesLabel = "Sim";
      const noLabel = "Cancelar";
      const buttonsRight = true;
      const alignCenter = true;

      return {
        show,
        confirm,
        subtitle,
        title,
        message,
        yesLabel,
        noLabel,
        buttonsRight,
        alignCenter,
      };
    },

    propsModalConfirmDeletion() {
      const confirm = () => this.inactivateRevenue();

      const show = this.modalDeleteMovement;
      const noReason = true;

      const title = `Excluir Produto do Registro de Saída`;
      const message = `Deseja remover o produto/medicamento?`;
      const msgSuccess = "Saída excluída com sucesso";
      const bodyText = `${this.selectedProductRevenue?.name} - ${this.selectedProductRevenue?.batch} - ${this.selectedProductRevenue?.amountDismissed}`;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
      };
    },
    getMinDateInitial() {
      return this.form.purchaseData
        ? this.form.continueUse
          ? new Date(moment(this.form.purchaseData, "DD/MM/YYYY").format())
          : new Date()
        : null;
    },

    disableActionsTable() {
      return !this.hasSelectedProductTable;
    },
    preferenceStockType() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.frp_tipo_estoque"
      ];
    },
    stockLotExpiration() {
      return this.preferenceStockType === "2";
    },
    preferenceDateRevenue() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.trs_bloquear_data_dispensacao"
      ];
    },
    blockedDateRevenue() {
      return this.preferenceDateRevenue === "1";
    },
    preferenceReturn() {
      return this.$store.getters["Login/GET_PREFERENCES"][
        "tViewFarmacia.rbt_dispensacao_retorno"
      ];
    },
    optionReturn() {
      return this.preferenceReturn === "1";
    },
    setColumn() {
      return this.stockLotExpiration
        ? this.COLUMNS
        : this.COLUMNS_WITHOUT_BATCH;
    },
    calculatedDays() {
      const addedDays = Math.floor(
        Number(this.form.amountSolicited / this.form.amountPrescribed),
      );

      return moment(this.form.purchaseData, "DD/MM/YYYY")
        .add(addedDays, "days")
        .format("DD/MM/YYYY");
    },
    revenueIsBeforeReturn() {
      return moment(this.form.purchaseData, "DD-MM-YYYY").isBefore(
        moment(this.delivery.rec_retorno, "DD-MM-YYYY"),
      );
    },
    disabledByModal() {
      return (
        this.modalConfirmReturn ||
        this.modalConfirmField ||
        this.modalDeleteMovement
      );
    },
  },

  watch: {
    suggestPatient(pValue) {
      if (!pValue && pValue === "") {
        this.form.pac_id = null;
        this.form.pes_id = null;
        this.form.pcf_id = null;
        this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
        this.$store.commit("Pharmacy/Satellite/UNSET_FORM_SATELLITE");
        this.$store.commit("Pharmacy/Satellite/UNSET_TABLE_SATELLITE");
        this.$store.commit("Person/Patient/SET_PERSON_ID", this.form.pes_id);
      }
    },
    "form.unitHealth"(pValue) {
      if (!pValue && pValue === null) {
        this.form.pharmacy = null;
        this.form.product = null;
      }
    },
    "form.pharmacy"(pValue, pPrev) {
      if ((!pValue && pValue === null) || pValue !== pPrev) {
        this.form.product = null;
      }
    },

    "form.product"(pValue) {
      if (!pValue && pValue === null) {
        this.form.amountDismissed = null;
        this.form.batch = null;
        this.delivery = {};
      }
      if (pValue !== null && this.form.pcf_id !== null) {
        this.getLastProductDelivery();
      }
    },
    "form.batch"(pValue) {
      if (!pValue && (pValue === null || pValue === undefined)) {
        this.form.inventory = null;
      } else if (this.form.product) {
        this.getInventory();
      }
    },

    "form.continueUse"(pValue) {
      if (
        pValue &&
        Number(this.form.amountSolicited) !== 0 &&
        Number(this.form.amountPrescribed) !== 0
      ) {
        this.form.returnDate = this.calculatedDays;
      } else {
        this.form.returnDate = null;
      }
    },
    "form.amountSolicited"(pValue) {
      if (Number(pValue) !== 0 && this.form.continueUse) {
        this.form.returnDate = this.calculatedDays;
      }
    },
    "form.purchaseData"(pValue, pPrev) {
      if (pValue && pValue !== pPrev && this.form.continueUse) {
        this.form.returnDate = this.calculatedDays;
      } else {
        this.form.returnDate = null;
      }
    },
    "form.amountPrescribed"(pValue) {
      if (Number(pValue) !== 0 && this.form.continueUse) {
        this.form.returnDate = this.calculatedDays;
      }
    },
    "form.pcf_id"(pValue) {
      if (pValue !== null && this.form.product !== null) {
        this.getLastProductDelivery();
      }
    },
  },

  async mounted() {
    if (this.$route.params) {
      this.disableEditPatient = this.$route.params.disableEditPatient
        ? this.$route.params.disableEditPatient
        : false;
    }
    if (this.lastPersonIdSaved) {
      this.mountFormWithPersonIsSaved();
    }
  },

  created() {
    this.COLUMNS = [
      { name: "Código", key: "code", align: "left" },
      { name: "Nome", key: "name", align: "left" },
      { name: "Apresentação", key: "presentation", align: "left" },
      { name: "Dispensado", key: "amountDismissed", align: "left" },
      { name: "Solicitado", key: "amountSolicited", align: "left" },
      { name: "Prescrição", key: "amountPrescribed", align: "left" },
      { name: "Lote-Validade", key: "batch", active: "true", align: "left" },
      { name: "Médico", key: "employee", align: "left" },
    ];
    this.COLUMNS_WITHOUT_BATCH = this.COLUMNS.filter((column) => {
      return column.key !== "batch";
    });
  },

  beforeDestroy() {
    const existRoute = ValidateIfRouteExistInBreadscrumb(
      "/pharmacy/satellite/revenue",
      this.$route.meta.breadcrumb,
    );

    if (!existRoute) {
      this.form.pes_id = null;
      this.$store.commit("Pharmacy/Satellite/UNSET_FORM_SATELLITE");
      this.$store.commit("Pharmacy/Satellite/UNSET_TABLE_SATELLITE");
      this.$store.commit("Person/Patient/DESTROY_LAST_PERSON_ID_SAVE");
      this.$store.commit("Person/Patient/SET_PERSON_ID", this.form.pes_id);
    }
  },

  methods: {
    closeModalDeleteProduct() {
      this.modalDeleteMovement = false;
    },
    openModalDeleteProduct() {
      this.modalDeleteMovement = true;
    },
    async getLastProductDelivery() {
      const variables = {
        intIdPacienteFarmacia: this.form.pcf_id,
        intIdMunicipioProduto: this.form.product,
      };
      const data = await this.$store.dispatch(
        "Pharmacy/Satellite/GET_LAST_PRODUCT_DELIVERY",
        variables,
      );
      this.delivery = {
        far_nome: data.far_nome,
        rec_data: data.rec_data,
        rec_retorno: data.rec_retorno,
      };
    },
    selectedPharmacy(pValue) {
      if (pValue) {
        this.pharmacyName = pValue.far_nome;
      } else {
        this.pharmacyName = null;
      }
    },
    mountFormWithPersonIsSaved() {
      this.form = {
        pcf_id: this.lastFormSaved?.pcf_id,
        pac_id: this.lastFormSaved?.pac_id,
        pes_id: this.lastFormSaved?.pes_id,
        employee: this.lastFormSaved?.employee,
        unitHealth: this.lastFormSaved?.unitHealth,
        pharmacy: this.lastFormSaved?.pharmacy,
        continueUse: this.lastFormSaved?.continueUse,
        purchaseData: this.lastFormSaved?.purchaseData,
        returnDate: this.lastFormSaved?.returnDate,
        product: this.lastFormSaved?.product,
        presentation: this.lastFormSaved?.presentation,
        batch: this.lastFormSaved?.batch,
        expirationDate: this.lastFormSaved?.expirationDate,
        lot: this.lastFormSaved?.lot,
        inventory: this.lastFormSaved?.inventory,
        amountPrescribed: this.lastFormSaved?.amountPrescribed,
        amountSolicited: this.lastFormSaved?.amountSolicited,
        amountDismissed: this.lastFormSaved?.amountDismissed,
      };
      this.mutableProductList = this.mutableLastProductList;
      this.$refs.patientInfoSuggest.fillPatientById(this.lastPersonIdSaved);
    },
    async getPatientData(pValue) {
      try {
        if (!pValue) {
          this.delivery = {};
        }
        if (pValue && Object.keys(pValue).length > 0) {
          this.$loader.start();
          this.suggestPatient = null;
          this.suggestPatient = pValue.pes_nome;
          this.form.pes_id = null;
          this.form.pes_id = pValue.pes_id;
          this.$store.commit("Person/Patient/SET_PERSON_ID", this.form.pes_id);
          this.$store.commit(
            "Person/Patient/SET_LAST_PERSON_ID_SAVE",
            this.form.pes_id,
          );
          this.$store.commit(
            "Pharmacy/Satellite/SET_FORM_SATELLITE",
            this.form,
          );
          this.form.pac_id = pValue.patient?.pac_id;

          const variables = {
            intIdPessoa: this.form.pes_id,
          };

          const data = await this.$store.dispatch(
            "Pharmacy/Satellite/SEARCH_PHARMACY_PATIENT",
            variables,
          );

          this.form.pcf_id = data.pcf_id;
        }
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e),
          "Erro ao buscar o paciente",
        );
      } finally {
        this.$loader.finish();
      }
    },

    closeModalConfirmRevenue() {
      if (this.optionReturn && this.revenueIsBeforeReturn) {
        this.modalConfirmReturn = false;
      }
    },
    openModalConfirmField() {
      this.modalConfirmField = true;
    },
    closeModalConfirmField() {
      this.modalConfirmField = false;
    },

    selectedProductEntryType(pValue) {
      if (pValue) {
        this.outputType = pValue.label;
      } else {
        this.outputType = null;
      }
    },
    selectedBatch(pValue) {
      if (pValue) {
        this.expirationBatch = pValue.value + " | " + pValue.fel_validade_lote;
        this.lotMinLimitDateSelect = pValue.trs_data;
      } else {
        this.expirationBatch = null;
        this.lotMinLimitDateSelect = null;
      }
    },
    async getInventory() {
      try {
        this.$loader.start();
        const variables = {
          intIdProduto: this.form?.product,
          intIdFarmacia: this.form?.pharmacy,
          ...(this.stockLotExpiration && { strLote: this.form?.batch }),
        };

        const data = await this.$store.dispatch(
          "Pharmacy/Central/GET_QUANTITY_INVENTORY",
          variables,
        );
        this.form.inventory = data[0].estocado;
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e),
          "Erro ao carregar o estoque",
        );
      } finally {
        this.$loader.finish();
      }
    },

    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    parseDataSave() {
      const variables = {
        pac_id: this.form.pac_id,
        pcf_id: this.form.pcf_id,
        pes_id: this.form.pes_id,
        rec_data: this.form.purchaseData,
        rec_dose_diaria: this.form.amountPrescribed.replace(/[.]/g, ""),
        rec_gravar_retorno: this.form.continueUse ? "1" : "0",
        rec_id_farmacias: this.form.pharmacy,
        rec_id_funcionarios: this.form.employee,
        rec_id_municipios_produtos: this.form.product,
        rec_id_pacientes_farmacia: this.form.pcf_id,
        rec_id_usuarios: this.USER_LOGIN_ID,
        rec_lote: this.form.batch,
        rec_quantidade: this.form.amountDismissed.replace(/[.]/g, ""),
        rec_retorno: this.form.returnDate,
        rec_solicitada: this.form.amountSolicited.replace(/[.]/g, ""),
      };
      return variables;
    },
    verifyDuplicate() {
      const filterDuplicate = this.mutableProductList.filter((pData) => {
        if (pData.code === this.code && pData.batch === this.expirationBatch)
          return true;
      });

      return filterDuplicate;
    },
    async saveRevenue() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }
      const initialDate = moment(this.lotMinLimitDateSelect, "DD/MM/YYYY");
      const finalDate = moment(this.form.purchaseData, "DD/MM/YYYY");
      const initialDateIsAfter = initialDate.isAfter(finalDate);

      if (initialDateIsAfter) {
        this.$toaster.info(
          `Escolha uma data de saída após o dia ${this.lotMinLimitDateSelect}`,
          "A data de saída é anterior à data de entrada do produto",
        );
        return null;
      }

      const existsDuplicate = this.verifyDuplicate();

      if (existsDuplicate.length > 0) {
        this.$toaster.info(
          "Escolha o mesmo produto com outro Lote - Validade para registrar a saída.",
          "O produto já faz parte do registro de Saída",
        );
        return false;
      }
      if (this.optionReturn && this.revenueIsBeforeReturn) {
        this.modalConfirmReturn = true;
      } else {
        this.saveMovement();
      }
    },
    async saveMovement() {
      try {
        this.$loader.start();
        const variables = this.parseDataSave();

        const result = await this.$store.dispatch(
          "Pharmacy/Satellite/REGISTER_REVENUE",
          variables,
        );

        this.$toaster.success(
          "Saída por prescrição/receita realizada com sucesso",
        );

        const info = {
          intIdMunicipioProduto: this.form.product,
          intIdFarmacia: this.form.pharmacy,
        };
        const data = await this.$store.dispatch(
          "Pharmacy/MIN_LIMIT_EXCEEDED",
          info,
        );

        if (data !== 0) {
          this.$toaster.info(
            "Faça uma solicitação para repor o estoque do produto.",
            "O estoque mínimo foi alcançado",
          );
        }

        this.insertProductTable(variables, result.dados);
        this.cleanFormWithRevenue();
        this.closeModalConfirmRevenue();
        this.selectedProductRevenue = null;
        this.selectedProductRevenueIndex = null;
        this.hasSelectedProductTable = false;
        if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao registrar saída",
        );
      } finally {
        this.$loader.finish();
      }
    },
    insertProductTable(pData, pId) {
      const data = {
        id: pId,
        code: this.code,
        name: this.product,
        presentation: this.presentationType,
        amountDismissed: pData.rec_quantidade,
        amountSolicited: pData.rec_solicitada,
        amountPrescribed: pData.rec_dose_diaria,
        ...(this.stockLotExpiration && {
          batch: this.expirationBatch,
        }),
        employee: this.employeeName,
      };

      this.mutableProductList.push(data);

      this.$store.commit(
        "Pharmacy/Satellite/SET_TABLE_SATELLITE",
        this.mutableProductList,
      );
    },

    async inactivateRevenue() {
      try {
        this.$loader.start();

        await this.$store.dispatch(
          "Pharmacy/Satellite/INACTIVATE_REVENUE",
          Number(this.selectedProductRevenue.id),
        );

        this.$toaster.success(
          "Saída por prescrição/receita excluída com sucesso",
        );
        this.mutableProductList.splice(this.selectedProductRevenueIndex, 1);
        this.$store.commit("Pharmacy/Satellite/UNSET_TABLE_SATELLITE");
        this.$store.commit(
          "Pharmacy/Satellite/SET_TABLE_SATELLITE",
          this.mutableProductList,
        );
        this.cleanFormWithRevenue();
        this.selectedProductRevenue = null;
        this.selectedProductRevenueIndex = null;
        this.hasSelectedProductTable = false;
        if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
      } catch (pErr) {
        const error = pErr.toString();
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao excluir saída",
        );
      } finally {
        this.$loader.finish();
      }
    },

    async printerRevenue() {
      try {
        // const variable = {
        //   strDataRecebimento: this.form.purchaseData,
        //   strFarmacia: this.form.pharmacy,
        //   intPaciente: this.form.pcf_id,
        //   quantidadeDeVias: 1,
        //   idUnidade: this.form.unitHealth,
        // };

        // const result = await this.$store.dispatch(
        //   "Pharmacy/Satellite/GET_PRINT_REVENUE",
        //   variable,
        // );
        this.$loader.start();
        this.disableEditPatient = true;

        this.dataRequestPrint.far_nome = this.pharmacyName;
        this.dataRequestPrint.pes_nome = this.suggestPatient;
        this.dataRequestPrint.rec_data = this.form.purchaseData;
        this.dataRequestPrint.usu_nome = this.userName;

        this.infosPrint.clientName = this.clientName;
        this.infosPrint.userName = this.userName;
        this.infosPrint.uns_nome = this.unitHealth.uns_nome;
        this.infosPrint.mun_nome = this.unitHealth.endereco.neighborhood.city.mun_nome;

        this.$loader.finish();
        this.modalTypesPrint = true;
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir comprovante de recebimento",
        );
        this.$loader.finish();
      }
    },

    closeModalTypesPrint() {
      this.modalTypesPrint = false;
    },

    selectLine(pValue, pIndex) {
      if (pValue) {
        this.selectedProductRevenue = pValue;
        this.selectedProductRevenueIndex = pIndex;
        this.hasSelectedProductTable = true;
      } else {
        this.selectedProductRevenue = null;
        this.selectedProductRevenueIndex = null;
        this.hasSelectedProductTable = false;
        if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
      }
    },

    selectedEmployee(pValue) {
      if (pValue) {
        this.employeeName = pValue.label;
      } else {
        this.employeeName = null;
      }
    },
    selectedProduct(pProduct) {
      if (pProduct) {
        this.code = pProduct.mpd_codigo;
        this.product = pProduct.mpd_novo_principio_ativo;
        this.presentationType = pProduct.mtu_novo_nome;
        this.form.presentation = pProduct.mtu_id;
      } else {
        this.code = null;
        this.product = null;
        this.presentationType = null;
        this.form.presentation = null;
      }
    },

    minDate(pValue, pErrors) {
      const purchaseData = moment(this.form.purchaseData, "DD/MM/YYYY");
      const returnDate = moment(this.form.returnDate, "DD/MM/YYYY");

      const initialDateIsNotValid = purchaseData.isAfter(returnDate);

      if (initialDateIsNotValid && this.form.returnDate !== null) {
        pErrors.push(
          "A data de retorno não pode ser menor do que a data de saída",
        );
        return false;
      }

      return true;
    },

    minValue(pValue, pErrors) {
      if (Number(pValue) < 1) {
        pErrors.push("O valor não pode ser menor do que 1");
        return false;
      }
    },
    validateAmountDismissed(pValue, pErrors) {
      const amount = Number(pValue);

      if (Number(pValue) < 1) {
        pErrors.push("O valor não pode ser menor do que 1");
        return false;
      }

      const inventory = Number(this.form.inventory.replace(/[.]/g, ""));

      const quantitySolicited = Number(
        this.form.amountSolicited.replace(/[.]/g, ""),
      );

      if (amount > quantitySolicited) {
        pErrors.push(
          "A Quantidade Dispensada não pode ser maior do que a Solicitada",
        );
        return false;
      }
      if (amount > inventory) {
        pErrors.push(`O valor não pode ser maior do que ${inventory}.`);
        return false;
      }

      return true;
    },
    validateAmountSolicited(pValue, pErrors) {
      if (Number(pValue) < 1) {
        pErrors.push("O valor não pode ser menor do que 1");
        return false;
      }

      return true;
    },

    cleanFormWithRevenue() {
      this.form.returnDate = null;
      this.form.continueUse = false;
      this.form.amountPrescribed = null;
      this.form.product = null;
      this.form.presentation = null;
      this.form.batch = null;
      this.form.lot = null;
      this.form.expirationDate = null;
      this.form.inventory = null;
      this.form.amountDismissed = null;
      this.form.amountSolicited = null;
      this.cleanFieldsValidation();
    },

    cleanForm() {
      if (this.mutableProductList.length === 0) {
        this.form = this.$utils.obj.DeepCopy(FORM);
      } else {
        this.form.employee = null;
        this.form.continueUse = false;
        this.form.amountPrescribed = null;
        this.form.product = null;
        this.form.presentation = null;
        this.form.batch = null;
        this.form.lot = null;
        this.form.expirationDate = null;
        this.form.inventory = null;
        this.form.amountDismissed = null;
        this.form.amountSolicited = null;
      }
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "patientInfoSuggest",
        "employee",
        "unitHealth",
        "pharmacy",
        "purchaseData",
        "returnDate",
        "product",
        "inventory",
        "presentation",
        "batch",
        "amountPrescribed",
        "amountDismissed",
        "amountSolicited",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    cancel() {
      this.$router.push({ name: "pharmacy.satellite" });
    },
  },
};
</script>
