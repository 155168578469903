import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchUnitHealthTypeSus(
    $tss_nome: String!
    $tss_codigo: String
    $limit: Int
  ) {
    unitHealthTypeSus(
      tss_nome: $tss_nome
      tss_codigo: $tss_codigo
      limit: $limit
    ) {
      rows {
        tss_id
        tss_codigo
        tss_nome
      }
    }
  }
`;
export default async ({ tss_nome = "", tss_codigo = "", limit = 1000 }) => {
  const variables = {
    tss_nome,
    tss_codigo,
    limit,
  };

  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.unitHealthTypeSus.rows;
  } catch (err) {
    return err.graphQLErrors;
  }
};
