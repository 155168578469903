import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-setor/buscar",
    variables,
  )
    .then(({ data }) => {
      if (data && data.dados && data.dados.recordSet) {
        data.dados.recordSet.map((item) => {
          if (item.set_ddd_telefone && item.set_telefone) {
            item.telefone =
              "(" +
              item.set_ddd_telefone +
              ") " +
              item.set_telefone.replace(/^(\d{4,5})(\d{4}$)/gi, "$1-$2");
          } else {
            item.telefone = "";
          }
        });
      }
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
