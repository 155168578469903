import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation TransferBulletinBilling(
    $transferBulletinBilling: TransferBulletinBillingInputType
  ) {
    TransferBulletinBilling(transferBulletinBilling: $transferBulletinBilling) {
      result_transfer
    }
  }
`;

export default async ({ commit }, { variables }) => {
  const { data } = await GraphQLClient.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data;
};
