<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import moment from "moment";

export default {
  name: "RgSelectCompetence",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Competência",
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
    isFutureCompetence: {
      type: Boolean,
      default: true,
    },
    hideWarning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      competences: [],
    };
  },
  mounted() {
    this.getCompetences();
  },
  methods: {
    getData() {
      return this.competences;
    },
    async getCompetences() {
      const currentCompetence = this.$store.getters[
        "Billing/BDSia/GET_PERIOD_DATE"
      ];

      const listCompetences = await this.$store.dispatch(
        "Billing/ImportBdsiaSigtap/SEARCH_AVAILABLE_COMPETENCE",
        {
          isRetroactive: this.isRegister,
          isFutureCompetence: this.isFutureCompetence,
        },
      );

      if (currentCompetence) {
        for (const iterator of listCompetences.bdsiaRows) {
          const competence = moment(iterator.competence, "YYYYMM").format(
            "YYYY-MM",
          );

          const periodMonth = competence.substring(7, 5);
          const periodYear = competence.substring(0, 4);

          const formatData = (
            moment(periodMonth).locale("pt-BR").format("MMM") +
            " - " +
            periodYear
          ).toUpperCase();

          this.competences.push({
            label: formatData,
            value: competence.replace("-", ""),
          });
        }

        this.competences = [...new Set(this.competences)];
      } else {
        if (!this.hideWarning) {
          this.$toaster.info(
            `Selecione uma competência na tela de "Competência BPA".`,
            "Competência não definida",
          );
        }
      }
    },
  },
};
</script>
