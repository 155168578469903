import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPersonUnifyReport(
    $usu_nome: String
    $pes_nome: String
    $psl_nome: String
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPersonUnifyReport(
      usu_nome: $usu_nome
      pes_nome: $pes_nome
      psl_nome: $psl_nome
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      csv
      excel
      fileName
      count
      rows {
        PACIENTE
        NASCIMENTO
        MAE
        PACIENTE_BASE
        UNIFICACAO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.SearchPersonUnifyReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
