<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchEmployeeByOccupation from "./action/SearchEmployeeByOccupation";

export default {
  name: "RgSelectEmployeeByOccupation",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Profissional",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    occupationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      valuesData: [],
    };
  },
  watch: {
    unitHealthId(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.fillData();
      }
    },
    occupationId(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        this.fillData();
      }
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async fillData() {
      if (this.unitHealthId && this.occupationId) {
        const result = await SearchEmployeeByOccupation({
          intIdUnidadeSaude: this.unitHealthId,
          intIdOcupacao: this.occupationId,
        });

        this.valuesData = result.map((item) => {
          return {
            value: item.data,
            label: item.label,
            fun_id: item.fun_id,
          };
        });
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
