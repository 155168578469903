<template>
  <div v-if="show" class="modal-observation-queue">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-observation-queue-header">
        <span class="title"> Observações do Paciente na Fila </span>
      </div>

      <div slot="body" class="modal-observation-queue-body">
        <form class="form-container" @submit.stop.prevent="registerObservation">
          <RgValidatorForm ref="validator">
            <FormBase title="Paciente" class="content-form">
              <span class="patient-name">
                Paciente:
                <strong>
                  {{
                    patientData.pes_nome ||
                    patientData.nomePaciente ||
                    patientData[0].pes_nome
                  }}
                </strong></span
              >

              <div class="fields">
                <div class="textarea">
                  <RgTextArea
                    id="observation"
                    ref="observation"
                    v-model="form.observation"
                    :rules="{ required: !viewModeObservation }"
                    :disabled="viewModeObservation"
                    :maxlength="500"
                    :viewCharacters="!viewModeObservation"
                    label="Observação"
                    class="text-area"
                  />
                </div>
                <div class="textarea">
                  <RgTextArea
                    id="reason"
                    ref="reason"
                    v-model="form.reason"
                    :rules="{ required: !viewModeObservation }"
                    :disabled="viewModeObservation"
                    :maxlength="500"
                    :viewCharacters="!viewModeObservation"
                    label="Motivo"
                    class="text-area"
                  />
                </div>
              </div>

              <div v-if="viewModeObservation" class="actions">
                <MediumButton
                  id="cancel-btn"
                  label="Fechar Visualização"
                  @click="cancelViewObservation"
                />
              </div>

              <div v-else class="actions">
                <RgCleanButton
                  id="clean-btn"
                  title="Limpar"
                  class="clean"
                  @click="cleanForm"
                />

                <RgAddButton
                  id="add-btn"
                  :disabled="disableAdd"
                  :permission="canInclude"
                  large
                  title="Adicionar observação"
                  class="add"
                  @click="registerObservation"
                />
              </div>
            </FormBase>

            <FormBase title="Observações" class="content-form">
              <div v-if="hasObservations && canShow" class="table">
                <SmartTable
                  ref="smartTable"
                  name="ModalObservationQueue"
                  :columns="COLUMN"
                  :body="mutableListObservationsQueue"
                  :initial-columns="5"
                  :total="listTotal"
                  :show-pagination="false"
                  remove-btn-columns
                  toggle-selected
                  mult-select
                  class="smart-table"
                  @getMultLines="getValueRow"
                >
                  <div slot="top-buttons" class="top-buttons">
                    <RgLessButton
                      id="view-observation-btn"
                      small
                      :disabled="disableView"
                      title="Excluir observação"
                      class="buttons"
                      @click="deleteObservation"
                    />
                    <RgShowButton
                      id="view-observation-btn"
                      :disabled="disableView"
                      small
                      title="Visualizar observação"
                      class="buttons"
                      @click="viewObservation"
                    />

                    <RgPrinterButton
                      id="print-btn"
                      :disabled="disablePrint"
                      small
                      title="Imprimir observação"
                      class="buttons"
                      @click="printObservation"
                    />
                  </div>
                </SmartTable>
              </div>

              <div v-else class="no-result">
                <IconEmpty class="icon" />
                <span class="message"> Não foram encontradas observações </span>
              </div>
            </FormBase>
          </RgValidatorForm>
        </form>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  RgBaseModal,
  RgValidatorForm,
  RgTextArea,
  RgAddButton,
  RgPrinterButton,
  RgShowButton,
  RgCleanButton,
  IconEmpty,
  MediumButton,
  RgLessButton,
} from "~tokio/primitive";

import { SmartTable } from "~tokio/foundation";
import { AlertError } from "~tokio/primitive/notification";

import FormBase from "~tokio/foundation/form-base/FormBase";
import ScheduleObservationPatientQueueHTML from "$appointment/submodules/schedule/html/ScheduleObservationPatientQueueHTML";

export default {
  name: "ObservationQueue",
  components: {
    FormBase,
    SmartTable,
    RgBaseModal,
    IconEmpty,
    RgValidatorForm,
    RgTextArea,
    RgAddButton,
    RgPrinterButton,
    RgShowButton,
    RgCleanButton,
    MediumButton,
    RgLessButton,
  },
  props: {
    show: Boolean,
    patientData: Object,
    modId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        reason: "",
        observation: "",
      },
      mutableListObservationsQueue: {},
      listSelected: [],
      viewModeObservation: false,
    };
  },
  computed: {
    hasObservations() {
      return this.mutableListObservationsQueue?.length > 0;
    },

    disableAdd() {
      return (
        this.form.reason?.trim().length < 5 ||
        this.form.observation?.trim().length < 5
      );
    },

    disableView() {
      return this.listSelected?.length !== 1;
    },

    disablePrint() {
      const validate = this.listSelected.every((item) => {
        return item.fio_id;
      });

      return !(validate && this.listSelected?.length > 0);
    },

    listTotal() {
      const total = this.mutableListObservationsQueue?.length || 0;
      return total;
    },

    permission() {
      return {
        addObservation:
          (this.modId === this.appointmentModuleId &&
            !!this.$Permissions.global.has(
              "consulta.filaConsulta.incluirObservacao",
            )) ||
          (this.modId === this.examModuleId &&
            !!this.$Permissions.global.has(
              "exame2.filaExame.incluirObservacao",
            )) ||
          (this.modId === this.hospitalizationModuleId &&
            !!this.$Permissions.global.has(
              "internacao.filaInternacao.incluirObservacao",
            )),
        deleteObservation:
          (this.modId === this.appointmentModuleId &&
            !!this.$Permissions.global.has(
              "consulta.filaConsulta.excluirObservacao",
            )) ||
          (this.modId === this.examModuleId &&
            !!this.$Permissions.global.has(
              "exame2.filaExame.excluirObservacao",
            )) ||
          (this.modId === this.hospitalizationModuleId &&
            !!this.$Permissions.global.has(
              "internacao.filaInternacao.excluirObservacao",
            )),
        showObservation:
          (this.modId === this.appointmentModuleId &&
            !!this.$Permissions.global.has(
              "consulta.filaConsulta.exibirObservacao",
            )) ||
          (this.modId === this.examModuleId &&
            !!this.$Permissions.global.has(
              "exame2.filaExame.exibirObservacao",
            )) ||
          (this.modId === this.hospitalizationModuleId &&
            !!this.$Permissions.global.has(
              "internacao.filaInternacao.exibirObservacao",
            )),
      };
    },

    canInclude() {
      return !!this.permission.addObservation;
    },

    canShow() {
      return !!this.permission.showObservation;
    },

    appointmentModuleId() {
      return this.$store.state.Login.route_module_map.appointment;
    },

    examModuleId() {
      return this.$store.state.Login.route_module_map.exam;
    },

    hospitalizationModuleId() {
      return this.$store.state.Login.route_module_map.hospitalization;
    },
  },
  watch: {
    show(pValue) {
      if (pValue) {
        this.searchFilter(this.patientData);
      }
    },
  },
  created() {
    this.COLUMN = [
      {
        name: "Observação",
        key: "fio_observacao_exibicao_dataGrid",
        align: "left",
      },
      { name: "Motivo", key: "fio_motivo_exibicao_dataGrid", align: "left" },
      { name: "Usuário", key: "usu_nome" },
      { name: "Data", key: "data_observacao" },
      { name: "Hora", key: "hora_observacao" },
    ];
  },
  methods: {
    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async searchFilter(pData, hasLoader = true) {
      try {
        if (hasLoader) this.$loader.start();
        this.mutableListObservationsQueue = await this.$store.dispatch(
          "Exam/Schedule/SEARCH_OBSERVATION_QUEUE",
          {
            arrFiltro: {
              fil_id: pData.fil_id,
              pes_id: pData.pes_id,
              pes_nome: pData.pes_nome,
              tipos_leito: "",
              limiteInicio: 0,
              limiteFim: 1000000,
            },
          },
        );
        this.mutableListObservationsQueue = this.mutableListObservationsQueue.data;
      } catch (e) {
        this.$toaster.error(e);
      } finally {
        if (hasLoader) this.$loader.finish();
      }
    },

    async registerObservation() {
      try {
        if (!(await this.isFormValid())) {
          AlertError("Verifique os campos");
          return false;
        }

        if (this.form.reason.trim()?.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo motivo",
          );
          return;
        }

        if (this.form.observation.trim()?.length < 5) {
          this.$toaster.warning(
            "Preencha no mínimo 5 caracteres no campo observação",
          );
          return;
        }

        const variables = {
          fio_id_filas: this.patientData.fil_id,
          fio_motivo: this.form.reason,
          fio_observacao: this.form.observation,
        };
        this.$loader.start();

        await this.$store.dispatch(
          "Appointment/Schedule/SAVE_OBSERVATION_QUEUE",
          variables,
        );

        await this.searchFilter(this.patientData, false);

        if (this.listSelected?.length > 0) {
          this.cleanSelectedRow();
        }

        this.$toaster.success("Observação salva com sucesso");
        this.cleanForm();
      } catch (error) {
        this.$toaster.error(error);
        this.cleanForm();
      } finally {
        this.$loader.finish();
      }
    },

    viewObservation() {
      this.form.reason = this.listSelected[0].fio_motivo_exibicao_texto;
      this.form.observation = this.listSelected[0].fio_observacao_exibicao_texto;

      this.viewModeObservation = true;
    },

    cancelViewObservation() {
      this.viewModeObservation = false;
      this.cleanForm();
      this.cleanSelectedRow();
    },

    async printObservation() {
      const client = this.$store.getters["Login/GET_CLIENT_CODE"];
      const patientData = { client, ...this.patientData };

      this.listSelected.map((item) => {
        item.fio_motivo = this.$utils.sanitize.removeHtmlTag(item.fio_motivo);

        item.fio_observacao = this.$utils.sanitize.removeHtmlTag(
          item.fio_observacao,
        );
      });

      const html = ScheduleObservationPatientQueueHTML(
        patientData,
        this.listSelected,
      );
      this.$utils.print.printHtml(html);
    },

    async deleteObservation() {
      try {
        this.$loader.start();
        if (!this.permission.deleteObservation) {
          return this.$toaster.info(
            "O seu usuário precisa ter permissão para excluir observações. Solicite ajuda de um administrador",
            "A observação selecionada não pode ser excluída",
          );
        }

        const variables = {
          intIdObservacao: this.listSelected[0].fio_id,
        };

        await this.$store.dispatch(
          "Exam/Schedule/DELETE_OBSERVATION_QUEUE",
          variables,
        );

        await this.searchFilter(this.patientData, false);

        if (this.listSelected?.length > 0) {
          this.cleanSelectedRow();
        }
        this.cleanForm();

        this.$toaster.success("A observação foi excluída com sucesso");
      } catch (Err) {
        this.$toaster.error(Err.message);
      } finally {
        this.$loader.finish();
      }
    },

    getValueRow(pValue) {
      this.listSelected = pValue;
    },

    cleanSelectedRow() {
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanMultSelectedRow();
      }
      this.listSelected = [];
    },

    cleanForm() {
      this.form = {
        reason: "",
        observation: "",
      };
      this.$refs.reason.cleanValidate();
      this.$refs.reason.resetCharacter();
      this.$refs.observation.cleanValidate();
      this.$refs.observation.resetCharacter();
    },

    close() {
      this.cancelViewObservation();
      this.cleanForm();

      this.$emit("close");
    },
  },
};
</script>
