<template lang="html">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 8H7L1 8L1 12H7H8V13V19H12V13V12H13H19V8L13 8H12V7V1L8 1V7V8ZM7 6V1C7 0.447715 7.44772 0 8 0H12C12.5523 0 13 0.447715 13 1V6V7H14L19 7C19.5523 7 20 7.44772 20 8V12C20 12.5523 19.5523 13 19 13H14H13V14V19C13 19.5523 12.5523 20 12 20H8C7.44772 20 7 19.5523 7 19V14V13H6H1C0.447715 13 0 12.5523 0 12V8C0 7.44771 0.447715 7 1 7L6 7H7V6Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "Cross",
};
</script>
