<style src="./RgPleniIntegrationButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-pleni-integration-button">
    <RgButton
      :disabled="disabled"
      :permission="permission"
      :title="title"
      :data-id="dataId"
      class="button"
      :class="borderColor"
      @click="clicked"
    >
      <div class="icon">
        <IconPleni />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconPleni } from "~tokio/primitive/icon/institutional";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "RgPleniIntegrationButton",
  components: {
    IconPleni,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    integrationStatus: {
      default: null,
    },
  },
  computed: {
    borderColor() {
      let borderColor = "-without-integration";
      const hasTryIntegration = this.integrationStatus !== null;

      if (hasTryIntegration) {
        borderColor = this.integrationStatus ? "-success" : "-warning";
      }

      return borderColor;
    },
  },
};
</script>
