import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const duplicationErrors = {
  BloquearDuplicidadeAoInserirFilaInternacaoException: `Paciente já consta na fila para esse procedimento.`,
  InformarDuplicidadeAoInserirFilaInternacaoException: `Aviso: paciente já consta na fila para esse procedimento`,
};
export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "internacoes/controller-fila-internacao/excluir",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: duplicationErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
