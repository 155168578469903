import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyMedicationsDispensedPatientsReport(
    $patientId: Int
    $patientName: String
    $initialDate: String
    $finalDate: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyMedicationsDispensedPatientsReport(
      patientId: $patientId
      patientName: $patientName
      initialDate: $initialDate
      finalDate: $finalDate
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        DATA
        PRODUTO
        PACIENTE
        IDADE
        TELEFONE
        ENDERECO
        MAE
        UNIDADE_SAUDE
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyMedicationsDispensedPatientsReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
