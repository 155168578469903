var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-hollow-button"},[_c('RgButton',{staticClass:"button",style:({
      'border-color': _vm.borderColor,
    }),attrs:{"id":_vm.id,"data-id":_vm.dataId,"disabled":_vm.disabled,"title":_vm.title,"permission":_vm.permission},on:{"click":_vm.clicked}},[_c('div',{staticClass:"label",style:({
        color: _vm.disabled ? '#fff !important' : _vm.fontColor,
        'border-color': _vm.disabled ? '#ccc' : _vm.borderColor,
      })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.separator),expression:"separator"}],staticClass:"separator"}),_c('div',{staticClass:"iconarea",style:({
        'border-color': _vm.disabled ? '#ccc' : _vm.borderColor,
      })},[_vm._t("icon")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }