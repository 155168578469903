import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";

export default {
  path: "/exam/schedule/per-day/edit/form/edit-patient/patient-care-history",
  name: "exam.schedule.per-day.edit.form.edit-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      { label: "Agendamentos por Dia", link: "/exam/schedule/per-day" },
      { label: "Editar Exame", link: "/exam/schedule/per-day/edit/form" },
      {
        label: "Editar Paciente",
        link: "/exam/schedule/per-day/edit/form/edit-patient",
      },
      { label: "Histórico de Atendimentos" },
    ],
  },
};
