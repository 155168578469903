<template>
  <div class="billing-bpa-pep-pending-procedures-search">
    <RgSearch
      ref="rgSearch"
      v-model="mutableProcedures"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="clearForm"
      :item-height="25"
      :max-register="50"
      :result-title="'Procedimentos Pendentes PEP'"
      :disabled="disabledByModal"
      :class="{ disable: disabledByModal }"
      @count="getCountValue"
      @afterPerformSearch="afterSearchFilter"
    >
      <div
        slot="filters"
        class="billing-bpa-pep-pending-procedures-search-filter"
      >
        <div class="grid">
          <RgSelectUnithealthLegacy
            id="unit-health"
            v-model="form.unitHealthId"
            class="input"
            :subModuleId="subModuleId"
          />

          <RgSelectOccupationByUnit
            id="occupation-by-unit"
            v-model="form.occupationId"
            class="input"
            :unitHealthId="Number(form.unitHealthId)"
            :disabled="!hasUnitHealthId"
          />

          <RgSelectEmployeeByOccupation
            id="employee-by-occupation"
            v-model="form.profissionalId"
            class="input"
            :unitHealthId="Number(form.unitHealthId)"
            :occupationId="Number(form.occupationId)"
            :disabled="!hasUnitHealthId || !hasOccupationId"
            @change="selectedEmployee"
          />

          <RgSelectProcedureByEmployee
            id="procedure-by-employee"
            v-model="form.procedureId"
            class="input"
            :unitHealthId="Number(form.unitHealthId)"
            :occupationId="Number(form.occupationId)"
            :employeeId="Number(form.profissionalId)"
            :disabled="!hasUnitHealthId || !hasOccupationId || !hasEmployeeId"
          />

          <div class="filter-title">
            <span class="title">Período de Envio</span>
          </div>

          <div class="_flex">
            <RgInputDate
              id="initial-date"
              ref="initialDate"
              v-model="form.initialDate"
              label="Data Inicial"
              class="input"
              :rules="{ required: requireFinalDate, fn: validateInitialDate }"
            />
            <RgInputDate
              id="final-date"
              ref="finalDate"
              v-model="form.finalDate"
              label="Data Final"
              class="input"
              :rules="{ required: requireInitialDate, fn: validateFinalDate }"
            />
          </div>
        </div>
      </div>

      <div class="billing-bpa-pep-pending-procedures-search-list">
        <div class="tooltip-info">
          <Tooltip class="tooltip">
            <IconLightBulbHelper slot="icon" />
            <div slot="content">
              {{ messageTooltip }}
            </div>
          </Tooltip>
        </div>

        <div class="table">
          <SmartTable
            ref="smartTable"
            name="PendingProceduresPEP"
            :body="mutableProcedures"
            :columns="COLLUMNS"
            :total="Number(total)"
            :initial-columns="6"
            :show-pagination="false"
            toggle-selected
            multSelect
            class="smart-table"
            @getMultLines="getListSelected"
          >
            <div slot="top-buttons" class="top-buttons">
              <SmallButton
                :disabled="!hasOnlyOneProcedureSelected || disabledByModal"
                :backgroundColor="'#0BB98C'"
                title="Atender e Reenviar"
                @click="openModalResolvePendingPEPProcedures(false)"
              >
                <IconCheck slot="icon" />
              </SmallButton>
              <RgEditButton
                :disabled="!hasOnlyOneProcedureSelected || disabledByModal"
                @click="openModalResolvePendingPEPProcedures(true)"
              />
              <RgLessButton
                :disabled="!hasMutableProceduresSelected || disabledByModal"
                @click="openModalConfirmDeletion"
              />
            </div>
          </SmartTable>
        </div>
      </div>

      <div
        v-show="hasProcedures"
        slot="menu-bottom"
        class="billing-bpa-pep-pending-procedures-search-footer"
      >
        <LargeButton
          title="Atualiza a lista de Procedimentos de Atendimento"
          label="Reenviar"
          :disabled="!hasMutableProceduresSelected || disabledByModal"
          :backgroundColor="'#1E88A9'"
          @click="resendPending"
        >
          <IconResend slot="icon" />
        </LargeButton>
      </div>
    </RgSearch>

    <ModalResolvePendingPEPProcedures
      :show="modalResolvePendingPEPProcedures"
      :procedureInfo="mutableProceduresSelected"
      :isEditing="isEditingPendingPEP"
      @close="closeModalResolvePendingPEPProcedures"
      @reSearch="reSearch"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      hasSearch
      @close="closeModalConfirmDeletion"
      @reSearch="reSearch"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
  </div>
</template>

<script>
import moment from "moment";
import { RgSearch, SmartTable } from "~tokio/foundation";

import {
  RgSelectUnithealthLegacy,
  RgEditButton,
  RgLessButton,
  ModalConfirmDeletion,
  IconDanger,
  IconResend,
  RgInputDate,
  LargeButton,
  SmallButton,
  IconCheck,
  IconLightBulbHelper,
  Tooltip,
} from "~tokio/primitive";

import RgSelectEmployeeByOccupation from "$billing/submodules/bpa/pep-pending-procedures/components/select/rg-select-employee-by-occupation/RgSelectEmployeeByOccupation";
import RgSelectOccupationByUnit from "$billing/submodules/bpa/pep-pending-procedures/components/select/rg-select-occupation-by-unit/RgSelectOccupationByUnit";
import RgSelectProcedureByEmployee from "$billing/submodules/bpa/pep-pending-procedures/components/select/rg-select-procedure-by-employee/RgSelectProcedureByEmployee";

import ModalResolvePendingPEPProcedures from "$billing/submodules/bpa/pep-pending-procedures/components/modal/modal-resolve-pending-PEP-procedures/ModalResolvePendingPEPProcedures";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "BillingBpaPepPendingProceduresSearch",
  components: {
    RgSearch,
    SmartTable,
    RgSelectUnithealthLegacy,
    RgSelectEmployeeByOccupation,
    RgSelectOccupationByUnit,
    RgSelectProcedureByEmployee,
    ModalResolvePendingPEPProcedures,
    ModalConfirmDeletion,
    RgEditButton,
    RgLessButton,
    IconDanger,
    IconResend,
    RgInputDate,
    LargeButton,
    SmallButton,
    IconCheck,
    IconLightBulbHelper,
    Tooltip,
  },
  data() {
    return {
      form: {
        unitHealthId: null,
        occupationId: null,
        profissionalId: null,
        procedureId: null,
        initialDate: null,
        finalDate: null,
      },
      employeeId: null,
      mutableProcedures: [],
      mutableProceduresSelected: [],
      pagination: {
        limit: 20,
        offset: 0,
        current: 1,
      },
      total: 0,
      modalConfirmDeletion: false,
      modalResolvePendingPEPProcedures: false,
      requireFinalDate: false,
      requireInitialDate: false,
      isEditingPendingPEP: false,
    };
  },
  computed: {
    messageTooltip() {
      return "Realize operações em lote como “Excluir” e “Reenviar”, quando mais de um registro possuir mesmo procedimento e motivo.";
    },
    hasUnitHealthId() {
      return this.form.unitHealthId && this.form.unitHealthId > 0;
    },
    hasOccupationId() {
      return this.form.occupationId && this.form.occupationId > 0;
    },
    hasEmployeeId() {
      return this.form.profissionalId && this.form.profissionalId > 0;
    },
    hasOnlyOneProcedureSelected() {
      return (
        this.mutableProceduresSelected &&
        this.mutableProceduresSelected.length === 1
      );
    },
    hasMutableProceduresSelected() {
      return (
        this.mutableProceduresSelected &&
        this.mutableProceduresSelected.length > 0
      );
    },
    hasProcedures() {
      return this.mutableProcedures && this.mutableProcedures.length > 0;
    },
    disabledByModal() {
      return this.modalConfirmDeletion || this.modalResolvePendingPEPProcedures;
    },
    propsModalConfirmDeletion() {
      const procedureCode =
        this.mutableProceduresSelected &&
        this.mutableProceduresSelected[0]?.ppe_codigo_procedimento
          ? this.mutableProceduresSelected[0].ppe_codigo_procedimento
          : null;

      const procedureName =
        this.mutableProceduresSelected &&
        this.mutableProceduresSelected[0]?.sus_pa_dc
          ? this.mutableProceduresSelected[0].sus_pa_dc
          : null;

      const listPatientsProceduresSentPep = this.mutableProceduresSelected.map(
        (item) => {
          return item.ppe_id;
        },
      );

      const show = this.modalConfirmDeletion;
      const noReason = true;
      const title = "Excluir Procedimento Pendente PEP";
      const message =
        "O registro selecionado será removido da relação de procedimentos importados";
      const bodyText = `${procedureCode} - ${procedureName}`;
      const btnRemoveTitle = "Excluir";

      const confirm = () => this.removeProcedure(listPatientsProceduresSentPep);

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        btnRemoveTitle,
        bodyText,
      };
    },
    subModuleId() {
      const SUB_MODULE_APPOINTMENT_QUEUE_ID = 233;
      return SUB_MODULE_APPOINTMENT_QUEUE_ID;
    },
  },
  watch: {
    "form.initialDate"(pValue) {
      if (pValue && pValue.length > 0) {
        this.requireInitialDate = true;
      } else {
        this.requireInitialDate = false;
      }
    },
    "form.finalDate"(pValue) {
      if (pValue && pValue.length > 0) {
        this.requireFinalDate = true;
      } else {
        this.requireFinalDate = false;
      }
    },
    "form.unitHealthId"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.occupationId = null;
        this.form.profissionalId = null;
        this.form.procedureId = null;
      }
    },
    "form.occupationId"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.profissionalId = null;
        this.form.procedureId = null;
      }
    },
    "form.profissionalId"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.procedureId = null;
      }
    },
  },
  created() {
    this.COLLUMNS = [
      { name: "Data do Envio", key: "dataEnvio", align: "left" },
      { name: "Motivo", key: "ppe_motivo_nao_enviado", align: "left" },
      { name: "Profissional", key: "pes_profissional", align: "left" },
      { name: "Ocupação", key: "ocp_nome", align: "left" },
      { name: "CID - 10", key: "ppe_cid10", align: "left" },
      { name: "Classificação", key: "ppe_classificacao", align: "left" },
      { name: "Serviço", key: "ppe_servico", align: "left" },
      { name: "Quant.", key: "ppe_quantidade", align: "left" },
      { name: "Código", key: "ppe_codigo_procedimento", align: "left" },
      { name: "Procedimento", key: "sus_pa_dc", align: "left" },
      { name: "Paciente", key: "pes_paciente", align: "left" },
      { name: "Data da Consulta", key: "dataAtendimento", align: "left" },
      { name: "Unidade de Saúde", key: "uns_nome", align: "left" },
    ];
  },
  mounted() {
    this.loadFilterPepPendingProcedures();
  },
  beforeDestroy() {
    const validateRegisterRoute = ValidateIfRouteExistInBreadscrumb(
      "/billing/bpa/pep-pending-procedures/search",
      this.$route.meta.breadcrumb,
    );

    if (!validateRegisterRoute) {
      this.$store.commit(
        "Billing/PEPPendingProcedures/RESET_FILTER_PEP_PENDING_PROCEDURES",
      );
    }
  },
  methods: {
    loadFilterPepPendingProcedures() {
      const data = this.$store.getters[
        "Billing/PEPPendingProcedures/GET_FILTER_PEP_PENDING_PROCEDURES"
      ];
      if (data && Object.keys(data).length > 0) {
        this.form.unitHealthId = data.uns_id;
        this.form.occupationId = data.ocp_id;
        this.form.profissionalId = data.professionalId;
        this.form.procedureId = data.sus_pa_id;
        this.form.initialDate = data.periodoInicial;
        this.form.finalDate = data.periodoFinal;
      } else {
        this.form.unitHealthId = this.$store.getters[
          "Login/GET_UNIT_HEALTH_ID"
        ];
        this.form.unitHealthId = this.form.unitHealthId?.toString();
      }
    },
    generateFilter() {
      const variables = {
        arrFiltros: {
          uns_id: this.form.unitHealthId || 0,
          ocp_id: this.form.occupationId || 0,
          fun_id: this.employeeId || 0,
          sus_pa_id: this.form.procedureId || 0,
          periodoInicial: this.form.initialDate || "",
          periodoFinal: this.form.finalDate || "",
          smd_id: 233,
          professionalId: this.form.profissionalId || undefined,
        },
      };

      this.$store.commit(
        "Billing/PEPPendingProcedures/SET_FILTER_PEP_PENDING_PROCEDURES",
        variables.arrFiltros,
      );

      return variables;
    },
    async searchFilter(pData) {
      this.$loader.start();
      const data = await this.$store.dispatch(
        "Billing/PEPPendingProcedures/GET_PROCEDURE_PEP",
        pData,
      );
      this.cleanSelectedRow();
      this.$loader.finish();
      return data;
    },
    async reSearch() {
      this.cleanSelectedRow();
      if (this.$refs.rgSearch)
        await this.$refs.rgSearch.submitForm(false, true);
    },
    async resendPending() {
      try {
        this.$loader.start();

        const validateEqualsPending = await this.validateEqualsPendency();

        if (!validateEqualsPending) {
          return false;
        }

        const variables = [];

        this.mutableProceduresSelected.forEach((item) => {
          variables.push(item.ppe_id);
        });

        await this.$store.dispatch(
          "Billing/PEPPendingProcedures/RESEND_PROCEDURE_PEP",
          { arrProcedimento: variables },
        );

        this.$toaster.success("Procedimento reenviado com sucesso");
      } catch (pError) {
        const error = pError.toString();
        const formatError = error.replace("Error: ", "");

        switch (formatError) {
          case "CID10 obrigatório":
            this.$toaster.info(
              `“Cid10” é um campo obrigatório para adicionar o procedimento.`,
              "O Procedimento precisa ser Classificado",
            );
            break;
          case "Sexo não permitido para o procedimento":
            this.$toaster.info(
              `No campo “Sexo” o gênero escolhido não é permitido para o procedimento.`,
              "O Procedimento é incompatível com o sexo",
            );
            break;
          case "Idade não permitida para o procedimento":
            this.$toaster.info(
              `No campo “Idade” o valor inserido não é permitido para o procedimento.`,
              "O Procedimento é incompatível com a idade",
            );
            break;
          case "Procedimento não importado para o profissional":
            this.$toaster.info(
              `Verifique os campos “Ocupação” e “Nome do Profissonal”.`,
              "O Procedimento não é permitido para o profissonal",
            );
            break;
          case "Procedimento já adicionado":
            this.$toaster.info(
              `Confira a relação de procedimentos importados.`,
              "O Atendimento já possui esse procedimento",
            );
            break;
          case "Profissional sem CNS":
            this.$toaster.info(
              `Insira um número válido no campo “CNS” para adicionar um procedimento.`,
              "O Profissional deve ter vínculo com um CNS",
            );
            break;
          case "Procedimento não pode ser faturado na competência atual":
            this.$toaster.info(
              `Fature o procedimento na próxima competência válida.`,
              "O Procedimento não pode ser faturado",
            );
            break;
          case "Procedimento exige serviço e classificação":
            this.$toaster.info(
              `Insira informações válidas nos campos “Serviço” e “Classificação”.`,
              "O Procedimento precisa ser classificado",
            );
            break;
          case "Procedimento incompatível com a quantidade informada":
            this.$toaster.info(
              `Insira no campo “Quantidade” um valor compatível com o procedimento.`,
              "O Procedimento é incompatível com a quantidade",
            );
            break;
          default:
            this.$toaster.error(error);
            break;
        }
      } finally {
        this.$loader.finish();
        this.reSearch();
      }
    },
    async removeProcedure(pListPatientsProceduresSentPep) {
      try {
        this.$loader.start();

        const validateEqualsPending = await this.validateEqualsPendency();

        if (!validateEqualsPending) {
          return false;
        }

        await this.$store.dispatch(
          "Billing/PEPPendingProcedures/REMOVE_PROCEDURE_PEP",
          {
            arrPacienteProcedimentoEnviadoPep: pListPatientsProceduresSentPep,
          },
        );

        this.$toaster.success("Procedimento removido com sucesso");
      } catch (pErr) {
        this.$toaster.error("Erro ao remover procedimento");
      } finally {
        this.$loader.finish();
      }
    },
    validateEqualsPendency() {
      const equalsPendency = this.mutableProceduresSelected.every((item) => {
        return (
          this.mutableProceduresSelected[0].uns_id === item.uns_id &&
          this.mutableProceduresSelected[0].ppe_id_funcionarios ===
            item.ppe_id_funcionarios &&
          this.mutableProceduresSelected[0].ppe_id_ocupacoes_cbo ===
            item.ppe_id_ocupacoes_cbo &&
          this.mutableProceduresSelected[0].ppe_codigo_procedimento ===
            item.ppe_codigo_procedimento &&
          this.mutableProceduresSelected[0].ppe_motivo_nao_enviado ===
            item.ppe_motivo_nao_enviado
        );
      });

      if (!equalsPendency) {
        this.$toaster.info(
          "A pendência dos procedimentos selecionados precisam ser iguais",
        );
      }

      return equalsPendency;
    },
    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(pValue, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },
    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(pValue, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },
    selectedEmployee(pEmployee) {
      if (pEmployee) {
        this.employeeId = pEmployee.fun_id;
      } else {
        this.employeeId = null;
      }
    },
    getListSelected(pProcedure) {
      if (pProcedure) {
        this.mutableProceduresSelected = pProcedure;
      } else {
        this.mutableProceduresSelected = [];
      }
    },
    afterSearchFilter() {
      this.cleanSelectedRow();
    },
    openModalConfirmDeletion() {
      this.modalConfirmDeletion = true;
    },
    closeModalConfirmDeletion() {
      this.modalConfirmDeletion = false;
    },
    async openModalResolvePendingPEPProcedures(pIsEditing) {
      const validateEqualsPending = await this.validateEqualsPendency();

      if (!validateEqualsPending) {
        return false;
      }

      this.modalResolvePendingPEPProcedures = true;

      if (pIsEditing === true) {
        this.isEditingPendingPEP = true;
      } else {
        this.isEditingPendingPEP = false;
      }
    },
    closeModalResolvePendingPEPProcedures() {
      this.modalResolvePendingPEPProcedures = false;
      this.cleanSelectedRow();
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },
    cleanSelectedRow() {
      this.mutableProceduresSelected = [];
      if (this.$refs.smartTable) this.$refs.smartTable.cleanMultSelectedRow();
    },
    clearForm() {
      this.form = {
        unitHealthId: null,
        occupationId: null,
        profissionalId: null,
        procedureId: null,
        initialDate: null,
        finalDate: null,
      };
      this.form.unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.form.unitHealthId = this.form.unitHealthId?.toString();

      this.employeeId = null;
      this.mutableProcedures = [];
      this.mutableProceduresSelected = [];
      this.pagination = {
        limit: 20,
        offset: 0,
        current: 1,
      };
      this.total = 0;
      this.modalConfirmDeletion = false;
      this.modalResolvePendingPEPProcedures = false;
      this.requireFinalDate = false;
      this.requireInitialDate = false;
      this.isEditingPendingPEP = false;

      this.$store.commit(
        "Billing/PEPPendingProcedures/RESET_FILTER_PEP_PENDING_PROCEDURES",
      );
    },
  },
};
</script>
