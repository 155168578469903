<template lang="html">
  <li class="confirm-add" tabindex="0">
    <div class="confirm-add--adding-container">
      <button class="confirm-add--btn-add">
        <IconCircleAdd class="confirm-add--btn-add--icon" />
      </button>
    </div>

    <div class="confirm-add--confirm">
      <button
        type="button"
        class="confirm-add--btn-add-confirm"
        @click.stop.prevent="addConfirm"
      >
        Gerar
      </button>

      <button
        type="button"
        class="confirm-add--btn-add-cancel"
        @click.stop.prevent
      >
        Cancelar
      </button>
    </div>
  </li>
</template>
<script>
import { IconCircleAdd } from "~tokio/primitive/icon";

export default {
  name: "RgConfirmItem",
  components: {
    IconCircleAdd,
  },
  methods: {
    addConfirm() {
      this.$emit("add-action");
    },
  },
};
</script>
