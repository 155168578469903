var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPermission)?_c('div',{staticClass:"rg-dropdown-menu-hospitalization",attrs:{"tabindex":"0","title":_vm.title},on:{"focusout":_vm.toggleFocusOut}},[_c('div',{staticClass:"button",class:{
      '-empty': _vm.generateSituationStatus === 1,
      '-waiting': _vm.generateSituationStatus === 2,
      '-full': _vm.generateSituationStatus === 3,
      '-desinfecting': _vm.generateSituationStatus === 4,
      '-maintenance': _vm.generateSituationStatus === 5,
    },attrs:{"id":"status-bed"},on:{"click":_vm.toggle}},[_c('IconArrow',{staticClass:"svg"})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],staticClass:"dropdown",class:{ 'show-up': _vm.showUp }},_vm._l((_vm.itens),function(element,index){return _c('span',{key:index,staticClass:"item",on:{"click":function($event){return _vm.openBedFlow(element)}}},[_vm._v(" "+_vm._s(element.name)+" ")])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }