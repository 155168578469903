<style src="./RgCancelButton.scss" lang="scss" scoped></style>
<template>
  <div class="rg-cancel-button">
    <RgButton
      :id="id"
      :title="title"
      :data-id="dataId"
      :disabled="disabled"
      :permission="permission"
      :class="{
        'rg-cancel-button -small': small,
        'rg-cancel-button -medium -hollow': medium,
        'rg-cancel-button -large': large,
        'disabled-button': disabledButton,
      }"
      class="button"
      @click="clicked"
    >
      <div v-show="medium || large" class="text">
        <span>Cancelar</span>
      </div>

      <div v-show="large" class="separator" />

      <div v-show="!medium" class="icon">
        <IconAdd />
      </div>
    </RgButton>
  </div>
</template>
<script>
import { IconAdd } from "~tokio/primitive/icon/symbols";
import RgButton from "~tokio/primitive/button/rg-button/RgButton.vue";
import RgButtonMixin from "~tokio/primitive/button/rg-button/RgButtonMixin.js";

export default {
  name: "RgCancelButton",
  components: {
    IconAdd,
    RgButton,
  },
  mixins: [RgButtonMixin],
  props: {
    title: {
      type: String,
      default: "Cancelar",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
