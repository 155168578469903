<template>
  <section class="status-export-list">
    <FormBase class="formbase" title="Exportações">
      <SmartTable
        ref="smartTableList"
        name="StatusExportList"
        class="integration-table"
        :columns="COLUMNS"
        :body="mutableList"
        :total="totalList"
        :show-pagination="false"
        has-check
        @getLine="getValueRow"
      />
      <div v-show="hasList" class="painel-button">
        <SmallButton
          title="Baixar"
          label="Baixar"
          :class="{
            'disable unselect': disableInProgress,
          }"
          :disabled="disableInProgress"
          class="button"
          :background-color="'#1E88A9'"
          @click="downloadFile"
        >
          <div slot="icon" class="icon">
            <IconArrowDownload />
          </div>
        </SmallButton>

        <MediumButton
          title="Atualizar"
          label="Atualizar"
          class="button"
          @click="searchList"
        />
      </div>
    </FormBase>
  </section>
</template>

<script>
import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import { MediumButton, SmallButton } from "~tokio/primitive";

import { IconArrowDownload } from "~tokio/primitive/icon/symbols";

export default {
  name: "ExportQueueInfoForm",
  components: {
    FormBase,
    SmartTable,
    MediumButton,
    SmallButton,
    IconArrowDownload,
  },

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mutableList: [],
      selectedItem: null,
    };
  },
  computed: {
    totalList() {
      return this.mutableList.length || 0;
    },

    hasList() {
      return this.mutableList.length > 0;
    },

    disableInProgress() {
      const isFinished = this.selectedItem?.ett_situacao === "CONCLUIDO";
      return !isFinished;
    },
  },

  watch: {
    list(pValue) {
      this.mutableList = pValue;
    },
  },

  created() {
    this.COLUMNS = [
      { name: "Nome do Arquivo", key: "ett_nome_arquivo" },
      { name: "Status", key: "ett_situacao" },
      { name: "Início do processamento", key: "ett_criado_em" },
      { name: "Última atualização", key: "ett_alterado_em" },
      { name: "Mensagem de Erro", key: "ett_mensagem_erro" },
    ];
  },
  methods: {
    async downloadFile() {
      const fileURL = this.selectedItem.url;

      window.open(fileURL);
    },
    searchList() {
      this.$emit("update-status-export-list");
    },

    getValueRow(pItem) {
      const data = this.$utils.obj.DeepCopy(pItem);
      const hasValue = data && data.ett_id > 0;

      this.selectedItem = hasValue ? data : null;
    },
  },
};
</script>
