<style src="./RgContentHospitalizationTeam.scss" lang="scss" scoped></style>
<template lang="html">
  <section class="rg-content-hospitalization-team">
    <form class="form">
      <RgValidatorForm ref="validator">
        <FormBase title="Procedimento">
          <div class="procedure">
            <RgTypeaheadProcedure
              v-model="procedure.iap_codigo_procedimentos"
              :disabled="true"
              class="col-12 inputitem"
              label="Procedimento"
              placeholder="Digite o Procedimento"
            />
          </div>
        </FormBase>
        <FormBase title="Profissional da Equipe">
          <div class="rg-content-hospitalization-team-professional">
            <RgComboboxSurgeryFunction
              v-model="
                professionalTeam.ieq_id_internacoes_funcoes_profissionais
              "
              :rules="{ required: true }"
              label="Função"
              class="inputitem"
            />

            <RgSuggestEmployee
              v-model="professionalTeam.ieq_nome_profissional"
              :disabled="!requiredCPF"
              :rules="{ required: requiredCPF }"
              :caption-raw="professionalCaptionRaw"
              placeholder="Nome do profissional"
              class="inputitem"
              label="Profissional"
              @selected="professionalSelected"
            />
          </div>
          <div class="rg-content-hospitalization-team-professional">
            <RgInputMasked
              v-model="professionalTeam.ieq_cns"
              :disabled="!requiredCPF"
              :rules="{ required: requiredCPF, fn: validateCns }"
              class="inputitem"
              :class="{ disabled: !requiredCPF }"
              label="CNS"
              placeholder="Digite o código do CNS"
              mask="###############"
            />

            <RgTypeaheadCbo
              v-model="professionalTeam.ieq_cbo"
              :disabled="!requiredCBO"
              :rules="{ fn: validateCbo }"
              class="inputitem"
              label="CBO"
              placeholder="Digite o código do CBO"
            />

            <RgInputMasked
              v-model="professionalTeam.ieq_cnes"
              :rules="{ required: requiredCNES }"
              class="inputitem"
              label="CNES"
              placeholder="Digite o código do CNES"
              mask="#######"
            />
          </div>
        </FormBase>
        <FormBase title="Apurar Valor Para">
          <div class="rg-content-hospitalization-team-documents">
            <RgComboboxAihDocumentType
              v-model="professionalTeam.ieq_id_internacoes_documentos_aih"
              :rules="{ required: true }"
              label="Tipo de Documento"
              class="inputitem"
              @input="documentSelected"
            />

            <RgInputMasked
              v-model="professionalTeam.ieq_numero_documento"
              :rules="{ required: true }"
              label="Número do Documento"
              placeholder="Informe o número do documento"
              class="inputitem"
              mask="###############"
            />

            <div class="buttons">
              <RgCleanButton
                v-if="!editMode"
                small
                title="Limpar"
                label="Limpar"
                @click="clear"
              />
              <MediumButton
                v-if="editMode"
                title="Cancelar"
                label="Cancelar"
                @click="clear"
              />

              <RgAddButton
                v-if="!editMode"
                small
                title="Adicionar"
                label="Adicionar"
                @click="add"
              />
              <MediumButton
                v-if="editMode"
                title="Alterar"
                label="Alterar"
                backgroundColor="#0bb98c"
                @click="edit"
              />
            </div>
          </div>
        </FormBase>
        <FormBase title="Lista de Profissionais">
          <div v-if="professionalTeamList.length === 0" class="empty">
            <IconEmpty class="svg" />
            <span>Não há procedimentos cadastrados</span>
          </div>

          <div v-else class="row">
            <table>
              <tr>
                <th>Função</th>
                <th>Profissional</th>
                <th>Ocupação</th>
                <th>CNS</th>
                <th>CNES</th>
                <th>Apurar Valor Para</th>
              </tr>
              <tr
                v-for="(professional, professionalIdx) in professionalTeamList"
                :key="professionalIdx"
              >
                <td>
                  {{
                    getSurgeryFunctionName(
                      professional.ieq_id_internacoes_funcoes_profissionais,
                    )
                  }}
                </td>
                <td>{{ professional.ieq_nome_profissional }}</td>
                <td>{{ professional.ieq_cbo }}</td>
                <td>{{ professional.ieq_cns }}</td>
                <td>{{ professional.ieq_cnes }}</td>
                <td>{{ professionalDocNameAndNumber(professional) }}</td>
                <td>
                  <RgEditButton
                    title="Editar"
                    medium
                    class="btn"
                    @click="editLine(professionalIdx)"
                  />
                </td>
                <td>
                  <RgLessButton
                    title="Remover"
                    medium
                    class="btn"
                    @click="handleRemoveLine(professionalIdx, professional)"
                  />
                </td>
              </tr>
            </table>
          </div>
        </FormBase>
      </RgValidatorForm>
    </form>
    <RgConfirmModal
      :message="deleteModalMsg"
      :show="confirmDelete"
      title="Você tem certeza que deseja remover o profissional?"
      style="background-color: red"
      class="rg-confirm-modal"
      @close="closeConfirmModal"
      @confirmed="removeLine"
      @denied="closeConfirmModal"
    />
  </section>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgValidatorForm,
  RgInputMasked,
  RgCleanButton,
  RgAddButton,
  RgLessButton,
  RgEditButton,
  MediumButton,
  IconEmpty,
} from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";
import RgComboboxAihDocumentType from "../../combobox/rg-combobox-aih-document-type/RgComboboxAihDocumentType";
import RgComboboxSurgeryFunction from "../../combobox/rg-combobox-surgery-function/RgComboboxSurgeryFunction";
import RgTypeaheadProcedure from "$billing/common/component/rg-typeahead-procedure/RgTypeaheadProcedure";
import RgSuggestEmployee from "$billing/common/component/rg-suggest-employee/RgSuggestEmployee";
import RgTypeaheadCbo from "$billing/common/component/rg-typeahead-cbo/RgTypeaheadCbo";
import RgConfirmModal from "~tokio/primitive/notification/modal/RgConfirmModal";
import SearchHospitalizationProfessionalFunction from "$billing/submodules/aih/hospitalization-billing/common/component/combobox/rg-combobox-surgery-function/action/SearchHospitalizationProfessionalFunction";
import SearchDocumentType from "$billing/submodules/aih/hospitalization-billing/common/component/combobox/rg-combobox-aih-document-type/action/SearchDocumentAihType";
import { AlertError, ValidateCbo } from "../../../service";
import RgInputSuscardHelper from "~tokio/primitive/input/rg-input-suscard/RgInputSuscardHelper";

export default {
  name: "RgContentHospitalizationBillingTincture",
  components: {
    RgInputMasked,
    FormBase,

    IconEmpty,
    RgValidatorForm,
    RgComboboxAihDocumentType,
    RgComboboxSurgeryFunction,
    RgSuggestEmployee,
    RgTypeaheadCbo,
    RgTypeaheadProcedure,
    RgConfirmModal,
    RgCleanButton,
    RgAddButton,
    RgLessButton,
    RgEditButton,
    MediumButton,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      professionalTeamList: [],
      professionalTeam: {},
      professionalCaptionRaw: "pes_nome",
      deleteIdx: "",
      deleteProf: "",
      deleteModalMsg: "",
      confirmDelete: false,
      surgeryFunctionTypes: [],
      documentsTypes: [],
      editMode: false,
      editIdx: "",
    };
  },
  computed: {
    requiredCPF() {
      if (!this.requiredDocuments.CPF) return false;
      return this.requiredDocuments.CPF.required;
    },
    requiredCBO() {
      if (!this.requiredDocuments.CBO) return false;
      return this.requiredDocuments.CBO.required;
    },
    requiredCNES() {
      if (!this.requiredDocuments.CNES) return false;
      return this.requiredDocuments.CNES.required;
    },
  },
  watch: {
    "professionalTeam.ieq_nome_profissional"(pValue) {
      if (!pValue) return;
      this.professionalTeam.ieq_nome_profissional = pValue.toUpperCase();
    },
  },
  async mounted() {
    if (this.procedure.additionalData[0]) {
      this.professionalTeamList = this.procedure.additionalData[0].professionalTeamList;
    }
    this.surgeryFunctionTypes = await SearchHospitalizationProfessionalFunction();
    this.documentsTypes = await SearchDocumentType();
  },
  methods: {
    professionalSelected(pData) {
      if (pData && pData.source) {
        this.professionalTeam.ieq_id_ocupacoes_cbo = pData.source.ocp_id;
        this.professionalTeam.ieq_cbo = pData.source.ocp_codigo;
        this.professionalTeam.ieq_id_funcionarios = pData.source.fun_id;
        this.professionalTeam.ieq_cns = pData.source.crs_numero;
      }
    },
    async documentSelected(pData) {
      if (pData[0]) {
        // Cnes Prop.
        if (pData[0].value === 4) {
          this.professionalTeam.ieq_numero_documento = await this.$store
            .getters["Login/GET_UNIT_HEALTH_CNES"];
        } else {
          this.professionalTeam.ieq_numero_documento = "";
        }
      }
    },
    async add(pEvent) {
      const professional = Object.assign({}, this.professionalTeam);
      if (await this.validateForm(professional)) {
        this.professionalTeamList.push(professional);
        this.$emit("setProfessionalList", this.professionalTeamList);
        this.clear(pEvent);
      }
    },
    async edit(pEvent) {
      this.professionalTeamList[this.editIdx] = JSON.parse(
        JSON.stringify(this.professionalTeam),
      );
      this.editMode = false;
      this.$emit("setProfessionalList", this.professionalTeamList);
      this.clear(pEvent);
    },
    clear(pEvent) {
      this.editMode = false;
      this.professionalTeam = {};
    },
    editLine(pIdx) {
      this.editMode = true;
      this.editIdx = pIdx;
      this.professionalTeam = JSON.parse(
        JSON.stringify(this.professionalTeamList[pIdx]),
      );
    },
    professionalDocNameAndNumber(pProfessional) {
      return (
        this.getDocumentName(pProfessional.ieq_id_internacoes_documentos_aih) +
        " - " +
        pProfessional.ieq_numero_documento
      );
    },
    async validateForm(pProfessional) {
      const valid = await this.$refs.validator.validate();
      if (!valid) return false;
      if (!this.validateTeamLength()) return false;
      if (!this.validateProfessional(pProfessional)) return false;

      return true;
    },
    validateTeamLength() {
      if (this.professionalTeamList.length > 5) {
        return AlertError("Limite de profissionais excedido.");
      }
      return true;
    },
    validateProfessional(pProfessional) {
      const hasFunction = this.professionalTeamList.filter(
        (prof) =>
          prof.ieq_id_internacoes_funcoes_profissionais ===
          pProfessional.ieq_id_internacoes_funcoes_profissionais,
      );
      if (hasFunction.length) {
        AlertError("Já existe um profissional com essa função na equipe.");
        return false;
      }
      return true;
    },
    validateCns(pValue, pErrors) {
      if (!this.requiredCPF) return true;
      if (pValue) {
        if (pValue.length < 15) {
          AlertError("CNS deve conter 15 caracteres.");
          return false;
        }
        const valid = RgInputSuscardHelper.checkDigit(pValue, pErrors);
        if (!valid) {
          AlertError("CNS inválido.");
          return false;
        }
        return true;
      }
      return false;
    },
    validateCbo(pCbo, pErrors) {
      if (!this.requiredCBO) return true;

      return isEmpty(pCbo)
        ? AlertError("Campo obrigatório", pErrors)
        : ValidateCbo(
            this.procedure.iap_codigo_procedimentos,
            pCbo,
            this.procedure.iap_competencia,
            pErrors,
          );
    },
    getDocumentName(pId) {
      const data = this.documentsTypes.filter((item) => item.ind_id === pId);
      if (data[0]) {
        return data[0].ind_descricao;
      }
      return "";
    },
    getSurgeryFunctionName(pId) {
      const data = this.surgeryFunctionTypes.filter(
        (item) => item.ifp_id === pId,
      );
      if (data[0]) {
        return data[0].ifp_descricao;
      }
      return "";
    },
    async removeLine() {
      this.confirmDelete = false;
      if (this.deleteProf && this.deleteProf.ieq_id) {
        await this.$store.dispatch(
          "Billing/HospitalizationBilling/DELETE_HOSPITALIZATION_TEAM",
          this.deleteProf.ieq_id,
        );
      }
      this.professionalTeamList.splice(this.deleteIdx, 1);
    },
    handleRemoveLine(pIdx, pDeleteProf) {
      if (this.professionalTeamList.length === 1) {
        AlertError("Equipe precisa ao menos de um profissional.");
        return false;
      }

      this.confirmDelete = true;
      if (this.confirmDelete) {
        this.deleteIdx = pIdx;
        this.deleteProf = pDeleteProf;
      } else {
        this.deleteIdx = "";
        this.deleteProf = "";
      }
    },
    closeConfirmModal() {
      this.confirmDelete = false;
    },
  },
};
</script>
