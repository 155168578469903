<style src="./RgCarousel.scss" lang="scss" scoped></style>

<template>
  <div class="rg-carousel-container">
    <button @click="back">
      <IconArrowLeftPoint class="icon" />
    </button>
    <div
      ref="scrollCarousel"
      :class="`container-options-carousel ${
        selfCentering ? 'carousel-align' : ''
      }`"
    >
      <div ref="optionsCarousel" :class="`${styleSlide}`">
        <slot name="carousel"></slot>
      </div>
    </div>
    <button @click="next">
      <IconArrowLeftPoint class="icon icon-back" />
    </button>
  </div>
</template>
<script>
import { IconArrowLeftPoint } from "~tokio/primitive/icon/symbols";

export default {
  name: "RgCarousel",
  components: {
    IconArrowLeftPoint,
  },
  props: {
    mutationItems: {
      typeof: String,
    },
  },
  data() {
    return {
      index: 0,
      limitPixel: 0,
      styleSlide: "",
      selfCentering: false,
    };
  },
  watch: {
    mutationItems: {
      handler() {
        this.selfCentering = true;
        setTimeout(() => {
          this.selfCentering = false;
        }, 100);
      },
    },
  },
  mounted() {
    this.generationSizerPage();
  },
  methods: {
    next() {
      this.initScroll(this.limitPixel);
      this.fadeSlide();
    },
    back() {
      this.initScroll(-this.limitPixel);
      this.fadeSlide();
    },
    initScroll(pValue) {
      this.generationSizerPage();
      const scroll = this.$refs.scrollCarousel;
      scroll.scrollLeft += pValue;
    },
    generationSizerPage() {
      const el = this.$refs.optionsCarousel;
      const size = el.getBoundingClientRect();
      this.limitPixel = size.width / 2;
    },
    fadeSlide() {
      this.styleSlide =
        this.styleSlide === "slide-carousel-1"
          ? "slide-carousel-2"
          : "slide-carousel-1";
    },
  },
};
</script>
