import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "/consultas/controller-marcacao-consulta/buscar-dias-inabilitados-atendente",
    variables,
  )
    .then(({ data }) => {
      const hasResult = Array.isArray(data?.dados) && data.dados?.length > 0;

      if (hasResult) {
        data = HandleResponse(data);

        data.dados.forEach((element) => {
          element.dia_completo = `${element.hag_data_consulta} (${element.dsm_nome})`;
        });

        return data.dados;
      }

      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
