import GetModulePermissionsByUnitHealthId from "../../service/GetModulePermissionsByUnitHealthId";
import GetSubModulesPermissionsReport from "~tokio/foundation/main-header/main-menu/menu-map/GetSubModulesPermissionsReport";
import { setPermissionsMap } from "@/app/access-control/model/PermissionManager";

export default async ({ commit, state }, to) => {
  const unitHealthId = state.unitHealth.uns_id;
  const splittedPath = to.fullPath.split("/");
  const moduleName = splittedPath[1];

  const reportModule = moduleName === "report";

  if (reportModule) {
    const namespaces = await GetSubModulesPermissionsReport();

    try {
      const { data } = await GetModulePermissionsByUnitHealthId(namespaces);

      if (!data) {
        throw new Error("Falha ao Carregar Permissões do Usuário");
      }

      commit("UPDATE_PERMISSIONS", data);
      setPermissionsMap(state.permissions);
    } catch (err) {
      return err;
    }
  }

  const hasNotModuleName = !moduleName || !state.route_module_map[moduleName];

  if (hasNotModuleName) {
    return true;
  }

  const unitHealthModuleLoaded = state.module_permissions_loaded[unitHealthId];

  const notLoaded =
    unitHealthModuleLoaded === undefined ||
    unitHealthModuleLoaded[moduleName] !== true;

  if (notLoaded) {
    try {
      const moduleId = state.route_module_map[moduleName];
      const { data } = await GetModulePermissionsByUnitHealthId(moduleId);

      if (!data) {
        throw new Error("Falha ao Carregar Permissões do Usuário");
      }

      commit("UPDATE_PERMISSIONS", data);
      commit("INCLUDE_LOADED_PERMISSIONS", { moduleName, unitHealthId });
      setPermissionsMap(state.permissions);
    } catch (err) {
      commit("REMOVE_LOADED_PERMISSIONS", { moduleName, unitHealthId });
      return err;
    }
  }
};
