<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import {
  SearchServices,
  ValidateService,
} from "../../select/rg-select-service/action";

export default {
  name: "RgSelectService",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Serviço",
    },
    defaultText: {
      type: String,
      default: "Digite o nome do serviço",
    },
    unitHealthId: {
      type: Number,
      default: null,
    },
    procedureCode: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    procedureCode(pValue, pPrev) {
      if (pValue && pValue !== pPrev) {
        if (pValue.length > 0) this.fillData();
      }
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async validateService(pData) {
      if (
        this.unitHealthId &&
        this.unitHealthId > 0 &&
        this.procedureCode !== null &&
        this.procedureCode.length > 0
      ) {
        let validate;
        let hasData;

        try {
          validate = await ValidateService({
            strCodigoProcedimento: this.procedureCode,
          });

          hasData = pData && pData.length > 0;

          if (!this.disable && !hasData && validate) {
            this.$toaster.info(
              'Verifique em "Faturamento/BPA/Serviços e Classificações" as opções disponíveis.',
              "O Serviço não foi importado para a Unidade",
            );
          }
        } catch (pErr) {
          this.$toaster.error("Erro ao buscar validação para o serviço", pErr);
        } finally {
          this.$loader.finish();

          if (hasData || validate) {
            this.$emit("required", true);
          } else {
            this.$emit("required", false);
          }

          if (!hasData || hasData.length < 1) {
            this.$emit("disable", true);
          } else {
            this.$emit("disable", false);
          }
        }
      }
    },
    async fillData() {
      let data;

      try {
        this.$loader.start();
        this.valuesData = [];

        if (this.unitHealthId > 0 && this.procedureCode?.length > 0) {
          data = await SearchServices({
            unitHealthId: this.unitHealthId,
            procedureCode: this.procedureCode,
          });

          this.valuesData = data.map((item) => {
            return {
              value: item.CO_SERVICO,
              label: item.CO_SERVICO + " - " + item.NO_SERVICO,
            };
          });
        }
      } catch (pErr) {
        this.$toaster.error("Erro ao buscar serviço", pErr);
      } finally {
        this.validateService(data);
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
