<template lang="html">
  <section class="patient-status">
    <div v-if="active" @click.stop.prevent="clicked">
      <IconIndicationActive class="svg" />
    </div>
    <div v-else @click.stop.prevent="clicked">
      <IconIndicationInactive class="svg" />
    </div>
  </section>
</template>

<script>
import {
  IconIndicationActive,
  IconIndicationInactive,
} from "~tokio/primitive/icon/symbols";

export default {
  name: "PatientStatus",
  components: {
    IconIndicationActive,
    IconIndicationInactive,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>
