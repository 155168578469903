import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query searchHospitalizationHealthPlan(
    $pls_ativo: Boolean
    $mod_id: Int!
    $uns_id: Int!
  ) {
    hospitalizationHealthPlan(
      pls_ativo: $pls_ativo
      mod_id: $mod_id
      uns_id: $uns_id
    ) {
      rows {
        pls_id
        pls_id_clientes
        pls_nome
        pls_obs
        pls_ativo
      }
    }
  }
`;

export default async (variables) => {
  try {
    const active = variables.pls_ativo || true;
    variables = { ...variables, pls_ativo: active };
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.hospitalizationHealthPlan.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
