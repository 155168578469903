<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import SearchExternalLocation from "./action/SearchExternalLocation.js";

export default {
  name: "RgSelectExternalLocation",
  extends: RgSelect,
  props: {
    IdModulo: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: "Destino",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      try {
        const data = await SearchExternalLocation({
          intIdModulo: this.IdModulo,
        });
        this.valuesData = data.map((item) => {
          return { value: item.data, label: item.label };
        });
      } catch (error) {
        this.$toaster.error(error);
      }
    },
  },
};
</script>
