import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const mutation = gql`
  mutation SendAdministrativeCheckOutToPep(
    $administrativeCheckout: AdministrativeCheckoutInputType!
  ) {
    SendAdministrativeCheckOutToPep(
      administrativeCheckout: $administrativeCheckout
    ) {
      success
      message
    }
  }
`;
export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.mutate({ mutation, variables });
    return data.SendAdministrativeCheckOutToPep;
  } catch (pErr) {
    return Promise.reject(pErr.graphQLErrors[0].message);
  }
};
