<template lang="html">
  <div v-if="show" class="modal-history-professional-scales">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Histórico de Operações das Escalas</div>
      </div>

      <div slot="body" class="body">
        <FormBase without-title class="info-area">
          <div class="info">
            <span class="unit item">
              <div class="label">Unidade de Saúde:</div>
              <strong>{{ getValue(professionalInfo, "uns_nome") }}</strong>
            </span>

            <span class="sector item">
              <div class="label">Setor:</div>
              <strong>{{ getValue(professionalInfo, "set_nome") }}</strong>
            </span>

            <span class="occupation item">
              <div class="label">Ocupação:</div>
              <strong>{{ getValue(professionalInfo, "ocp_nome") }}</strong>
            </span>

            <span class="professional item">
              <div class="label">Profissional:</div>
              <strong>{{ getValue(professionalInfo, "pes_nome") }}</strong>
            </span>
          </div>
        </FormBase>

        <FormBase title="Alterações" class="list-area">
          <SmartTable
            ref="smartTable"
            name="ModalHistoryProfessionalScales"
            :columns="COLUMNS"
            :body="historyList"
            :total="historyListTotal"
            :initial-columns="9"
            :show-pagination="false"
            remove-btn-columns
            class="history-list"
          />
        </FormBase>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { SmartTable, FormBase } from "~tokio/foundation";
import { RgBaseModal } from "~tokio/primitive";

export default {
  name: "ModalHistoryProfessionalScales",
  components: {
    RgBaseModal,
    SmartTable,
    FormBase,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sectorLinkId: {
      type: Number,
      default: 0,
      required: true,
    },
    professionalInfo: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      historyList: [],
    };
  },

  computed: {
    historyListTotal() {
      const total = this.historyList.length || 0;
      return total;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.searchHystoryList();
      }
    },
  },

  created() {
    this.COLUMNS = [
      { name: "", key: "eel_data" },
      { name: "Tipo de Operação", key: "tlg_nome" },
      { name: "Tipo", key: "ees_nome" },
      { name: "Dia", key: "dsm_sigla" },
      { name: "Horário de Início", key: "eel_inicio_atendimentos" },
      { name: "Horário de Término", key: "eel_final_atendimentos" },
      { name: "Intervalo", key: "eel_intervalo_exames" },
      { name: "Vagas", key: "eel_quantidade_exames" },
      { name: "Validade", key: "validade" },
      { name: "Usuário", key: "usu_nome", active: true },
    ];
  },

  methods: {
    getValue(pItem, pKey) {
      return pItem[pKey] || "Não informado";
    },

    async searchHystoryList() {
      try {
        const hasSectorLinkId = this.sectorLinkId > 0;

        if (hasSectorLinkId) {
          this.$loader.start("Carregando histórico de escalas...");

          const data = await this.$store.dispatch(
            "Exam/Register/SEARCH_HISTORY_SCALE_LIST",
            { intIdVinculoSetor: Number(this.sectorLinkId) },
          );

          this.historyList = data;

          this.$loader.finish();
        }
      } finally {
        this.$loader.finish();
      }
    },
    close() {
      this.historyList = [];
      this.$refs.smartTable.setScrollTopZero();
      this.$emit("close");
    },
  },
};
</script>
