<template>
  <div class="smart-table" :class="{ disable: disabled }">
    <div v-if="showTableMenu" ref="tableMenu" class="table-menu">
      <div
        v-if="showSelectAllPage && multSelect && body.length > 0"
        class="mult-selectors"
      >
        <RgButton
          v-if="!selectAll"
          id="check-btn"
          class="check"
          type="button"
          :disabled="disabled"
          @click="selectAllList"
        >
          <IconSquare />
        </RgButton>
        <RgButton
          v-if="selectAll"
          id="uncheck-btn"
          class="check"
          type="button"
          :disabled="disabled"
          @click="unselectAllList"
        >
          <IconSquareChecked />
        </RgButton>
        <RgButton
          id="reverse-list"
          class="check"
          type="button"
          :disabled="body.length === 0 || disabled"
          @click="reverseListSelection"
        >
          <IconArrowSquare />
        </RgButton>
        <span class="text">
          Linhas selecionadas: {{ multSelectedRow.length }}
        </span>
      </div>
      <slot name="legends" class="legends" />
      <slot name="top-buttons" class="top-buttons" />
      <div
        v-if="!removeBtnColumns"
        v-clickOutside="clickOutside"
        :class="{ 'dropdown-button-fixed': fixedDropdown }"
        class="dropdown-button box-scroll"
      >
        <div class="button" @click="toggle">
          <span class="text unselect">Colunas</span>
          <IconArrow class="svg" />
        </div>

        <div
          v-if="active === true"
          :class="{
            'dropdown-fixed': fixedDropdown,
            'dropdown-absolute': !fixedDropdown,
          }"
          class="dropdown"
        >
          <span class="label">Colunas Exibidas</span>
          <label
            v-for="(col, index) in activeHeader"
            :key="col.key"
            :class="{ 'disable unselect': hasOnlyOneActiveHeader }"
            :disabled="hasOnlyOneActiveHeader"
            class="item actives unselect"
          >
            {{ col.name }}
            <input
              :id="index"
              :checked="col.active"
              class="checkbox"
              type="checkbox"
              @change="toggleStatus(col, col.key)"
            />
          </label>

          <span class="label">Colunas Não Exibidas</span>

          <label
            v-for="(col, index) in inactiveHeader"
            :key="col.key"
            class="item inactives unselect"
          >
            {{ col.name }}
            <input
              :id="index"
              :checked="col.active"
              class="checkbox"
              type="checkbox"
              @change="toggleStatus(col, col.key)"
            />
          </label>
        </div>
      </div>
    </div>
    <div ref="scrollArea" class="table-dropdown" :style="actDynamicHeight">
      <div ref="itemHeight" class="table">
        <thead class="theader">
          <tr class="title">
            <th
              v-for="(col, idx) in columnTable"
              v-show="col.active"
              :key="idx"
              :style="{ textAlign: col.align || col.alignTitle }"
              class="text"
            >
              {{ col.name }}
            </th>
          </tr>
        </thead>

        <tbody ref="tbody" class="tbody">
          <tr
            v-for="(item, index) in dataTable"
            :key="getRowKey(item, index)"
            :disabled="isDisabledRow(item)"
            :class="{
              active: isSelectedRow(item, index),
              disable: isDisabledRow(item),
              opaque: isOpaque ? isOpaque.check(item) : false,
              color: colorLine && !circleIndicator ? colorLine.get(item) : null,
            }"
            :style="{
              'background-color':
                colorLine && !circleIndicator ? colorLine.get(item) : null,
              color: item.color,
            }"
            class="body"
            @click="getItem(item, index)"
          >
            <td
              v-for="(col, idx) in columnTable"
              v-show="col.active"
              :key="idx"
              :style="{ textAlign: col.align }"
              class="data"
              :class="{ datalimit: minHeight }"
            >
              <div
                :class="{
                  'has-check':
                    ((circleIndicator || hasCheck) &&
                      (isSelectedAndCheckRow(index, idx) ||
                        (idx === firstIndexSelected && showCircleColor))) ||
                    col.statusColor,
                }"
              >
                <span v-if="hasLines" class="marks">
                  <span v-if="isSelectedAndCheckRow(index, idx)" class="check">
                    <IconCheck class="svg" />
                  </span>
                  <ColorIndication
                    v-if="
                      ((idx === firstIndexSelected && showColorInFirstColumn) ||
                        col.statusColor) &&
                      showCircleColor
                    "
                    :value="colorLine.get(item)"
                    class="circle-color"
                  />
                </span>
                <span v-if="col.seeMore">
                  {{ seeMoreItem(item[col.key]) }}
                </span>
                <span v-if="col.html" v-html="item[col.key]"> </span>
                <span
                  v-if="!col.concat && !col.seeMore && !col.html"
                  class="info"
                >
                  {{
                    circleIndicator ? item[col.key] || "" : item[col.key] || "-"
                  }}
                </span>
                <div v-if="col.concat">
                  <div
                    v-for="(item, index) in separateItens(
                      item[col.key],
                      col.concat,
                    )"
                    :key="index"
                  >
                    <div class="info">
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </div>
    </div>
    <div>
      <slot name="bottom-buttons" />
    </div>

    <RgPagination
      v-if="showPagination"
      ref="paginator"
      :data-length="total"
      :item-per-page="itemPerPage"
      :max-register="maxRegister"
      :disabled="disabled"
      :from-modal="fromModal"
      isFromSmartTable
      @pagination="setPagination"
    />
    <slot v-if="!showPagination" />
  </div>
</template>

<script>
import { IconArrow } from "~tokio/primitive/icon/symbols";
import RgPagination from "~tokio/foundation/pagination/RgPagination";
import ColorIndication from "~tokio/primitive/indication/color-indication/ColorIndication";
import {
  IconCheck,
  IconSquare,
  IconSquareChecked,
  IconArrowSquare,
  RgButton,
} from "~tokio/primitive";
import { mapGetters } from "vuex";
export default {
  name: "SmartTable",

  components: {
    IconArrow,
    RgPagination,
    IconCheck,
    IconSquare,
    IconSquareChecked,
    IconArrowSquare,
    ColorIndication,
    RgButton,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    body: {
      type: Array,
      default: () => {
        return [
          {
            without_result: "Não Há Resultado(s)",
            disabled: true,
            align: "center",
          },
        ];
      },
    },
    indexColumn: {
      type: String,
      default: null,
    },

    secondIndexColumn: {
      type: String,
      default: null,
    },

    minHeight: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      default: () => {
        return [
          {
            name: "Resultado",
            key: "without_result",
            align: "center",
            active: true,
          },
        ];
      },
    },

    initialColumns: {
      type: Number,
      default: 5,
    },

    itemPerPage: {
      type: Number,
      default: 0,
    },

    maxRegister: {
      type: Number,
      default: 15,
    },

    total: {
      type: Number,
      default: 0,
    },

    dynamicHeight: {
      type: Number,
      default: 0,
    },

    showPagination: {
      type: Boolean,
      default: true,
    },

    isOpaque: {
      type: Object,
      default: null,
    },

    colorLine: {
      type: Object,
      default: null,
    },

    toggleSelected: {
      type: Boolean,
      default: false,
    },

    multSelect: Boolean,
    removeBtnColumns: Boolean,
    hasCheck: Boolean,
    dontSelect: Boolean,
    showTableMenu: {
      type: Boolean,
      default: true,
    },

    showSelectAllPage: {
      type: Boolean,
      default: true,
    },

    circleIndicator: {
      type: Boolean,
      default: false,
    },

    showColorInFirstColumn: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    saveColumnsOnStore: {
      type: Boolean,
      default: true,
    },

    useIndexAsKey: {
      type: Boolean,
      default: false,
    },

    fromModal: Boolean,
    separatorSeeMore: {
      type: String,
      default: ",",
    },
    withoutResultText: {
      type: String,
      default: "Não Há Resultado(s)",
    },
  },

  data() {
    return {
      isWaitingResult: false,
      modalSeeMore: false,
      fixedDropdown: false,
      header: [],
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
      timeout: null,
      selectedData: null,
      selectedRow: {
        type: Object,
        default: () => {},
      },
      rowToSelect: null,
      multSelectedRow: [],
      active: false,
      columnToSeeMore: null,
      loadPreferenceColumn: false,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      getColumns: "User/GET_SMART_TABLE_COLUMNS",
    }),

    firstIndexSelected() {
      return this.columnTable.findIndex((item) => item.active);
    },

    actDynamicHeight() {
      return this.dynamicHeight ? `height: ${this.dynamicHeight}px` : "";
    },

    changePosition() {
      return this.pagination.limit < 6;
    },

    dataTable() {
      const validateData =
        this.total !== 0
          ? this.body
          : [
              {
                without_result: this.withoutResultText,
                disabled: true,
                align: "center",
                color: "#707079",
              },
            ];
      return validateData;
    },

    columnTable() {
      const validateColumns =
        this.total !== 0
          ? this.columns
          : [
              {
                name: "Resultado",
                key: "without_result",
                align: "center",
                active: true,
              },
            ];

      return this.mountTableHeader(validateColumns, this.initialColumns);
    },
    allowedShowButton() {
      if (
        this.dataTable[this.selectedRow] !== null &&
        this.dataTable[this.selectedRow] !== undefined
      ) {
        return Object.keys(this.dataTable[this.selectedRow]).length > 0;
      }

      return false;
    },
    activeHeader() {
      const isHeaderFilled = this.header.length > 0;

      if (isHeaderFilled) {
        const actives = this.header.filter((item) => item.active);

        this.$emit("columnsToPrint", this.getActiveColumnsKeysAndName(actives));

        return actives;
      }

      return false;
    },

    inactiveHeader() {
      const isHeaderFilled = this.header.length > 0;

      if (isHeaderFilled) {
        return this.header.filter((item) => !item.active);
      }

      return false;
    },

    hasOnlyOneActiveHeader() {
      return this.activeHeader.length === 1;
    },

    selectAll() {
      return this.multSelectedRow.length === this.body.length;
    },

    showCircleColor() {
      return !this.hasCheck && this.circleIndicator;
    },

    hasLines() {
      return this.body.length > 0;
    },
  },

  watch: {
    itemPerPage(pValue, pPrev) {
      if (this.$refs.paginator) {
        this.$refs.paginator.pagination.limit = pValue;
      }
    },
    selectedRow() {
      this.selectedData = this.dataTable[this.selectedRow];
    },
    body(value) {
      this.performRowSelection();
    },
    rowToSelect() {
      this.performRowSelection();
    },

    activeHeader(pValue, pPrev) {
      if (this.saveColumnsOnStore) {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          if (pPrev && pValue[0]?.key !== "without_result") {
            const link = this.$route.path;
            const getStoreColumns = this.getColumns(
              link,
              this.userLoginId,
              this.unitHealthId,
              this.name,
            );

            this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
              link,
              usu_id: this.userLoginId,
              uns_id: this.unitHealthId,
              columns: pValue,
              name: this.name,
            });

            if (
              this.loadPreferenceColumn &&
              JSON.stringify(
                pValue?.map((e) => {
                  return { key: e.name, active: e.active };
                }),
              ) !==
                JSON.stringify(
                  getStoreColumns?.columns?.map((e) => {
                    return { key: e.name, active: e.active };
                  }),
                )
            ) {
              this.loadPreferences();
            }

            if (
              this.$store.getters["Login/GET_REFRESH_PREFERENCE_COLUMN_GRID"]
            ) {
              this.loadPreferenceColumn = true;
              this.loadPreferences();
              this.$store.commit(
                "Login/SET_REFRESH_PREFERENCE_COLUMN_GRID",
                false,
              );
            }
          }
        }, 400);
      }
    },
  },

  mounted() {
    this.loadPreferenceColumn = true;
    this.loadPreferences();
  },

  created() {
    this.MAX_COLUMNS = 35;
    this.getColumnToSeeMore();
  },

  methods: {
    toggle() {
      if (this.active) {
        this.savePreferences();
      }
      this.loadPreferenceColumn = false;
      this.active = !this.active;
    },

    clickOutside() {
      if (this.active) {
        this.savePreferences();
      }
      this.loadPreferenceColumn = false;
      this.active = false;
    },

    isFilterFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    cleanMultSelectedRow() {
      this.multSelectedRow = [];
    },

    mountTableHeader(ArrColumns, initialColumns = 1) {
      let header;
      const link = this.$route.path;
      const savedHeader = this.getColumns(
        link,
        this.userLoginId,
        this.unitHealthId,
        this.name,
      );

      if (savedHeader && this.saveColumnsOnStore && !this.removeBtnColumns) {
        const activeKey = [];
        savedHeader.columns.forEach((item) => {
          activeKey.push(item.key);
        });

        header = Array.from(
          ArrColumns.map((item) => {
            if (activeKey.includes(item.key) || item.key === "without_result") {
              return {
                ...item,
                name: item.name,
                key: item.key,
                align: item.align,
                active: true,
              };
            } else {
              return {
                ...item,
                name: item.name,
                key: item.key,
                align: item.align,
                active: false,
              };
            }
          }),
        );
      } else {
        header = Array.from(
          ArrColumns.map((item, idx) => {
            const active = idx < this.initialColumns;
            return {
              ...item,
              name: item.name,
              key: item.key,
              align: item.align,
              active,
            };
          }),
        );
      }

      if (this.total === 0) {
        header = [
          {
            name: "Resultado",
            key: "without_result",
            active: true,
            align: "center",
          },
        ];
      }

      this.header = header;
      return header;
    },

    selectRow(item) {
      this.rowToSelect = item;
    },

    performRowSelection() {
      const withoutRowToSelect = this.rowToSelect !== null;

      if (withoutRowToSelect) {
        const index = this.body.findIndex((row) => {
          const compareIndex =
            this.rowToSelect[this.indexColumn] === row[this.indexColumn];

          const isToSelectSecondIndex =
            this.secondIndexColumn && this.rowToSelect[this.indexColumn];

          if (isToSelectSecondIndex) {
            const compareTwo =
              this.rowToSelect[this.secondIndexColumn] ===
              row[this.secondIndexColumn];

            return compareIndex && compareTwo;
          } else {
            return compareIndex;
          }
        });

        const validIndex = index > -1;

        if (validIndex) {
          this.rowToSelect = null;
          this.selectedRow = index;
          this.setScrollOnSelectedItem();
        }
      }
    },

    getHeaderIndex(pKey) {
      return this.header.findIndex((item) => item.key === pKey);
    },

    getRowKey(item, index) {
      if (this.useIndexAsKey) return index;
      return item[this.indexColumn]
        ? item[this.secondIndexColumn]
          ? `${item[this.indexColumn]}${item[this.secondIndexColumn]}`
          : item[this.indexColumn]
        : index;
    },

    getActiveColumnsIndex() {
      const actives = [];
      this.header.forEach((item, index) => {
        if (item.active) actives.push(index);
      });

      return actives;
    },

    getActiveColumnsKeysAndName(columns) {
      const result = [];

      columns.forEach((item) => {
        if (item.active) result.push({ key: item.key, name: item.name });
      });

      return result;
    },

    getFirstActiveColumnIndex() {
      return this.getActiveColumnsIndex()[0];
    },

    getLastActiveColumnIndex() {
      const activeColumns = this.getActiveColumnsIndex();
      return activeColumns[activeColumns.length - 1];
    },

    getActiveColumnsLength() {
      return this.getActiveColumnsIndex().length;
    },

    toggleStatus(pColumn, pKey) {
      pColumn.active = !pColumn.active;

      const index = this.getHeaderIndex(pKey);
      const activesLength = this.getActiveColumnsLength();
      const maxReached = activesLength > this.MAX_COLUMNS;
      const middle = activesLength / 2;
      const currentIndex = index + 1;

      if (maxReached) {
        const idx =
          currentIndex > middle
            ? this.getFirstActiveColumnIndex()
            : this.getLastActiveColumnIndex();

        this.header[idx].active = false;
      }
    },

    setPagination(pPagination) {
      this.cleanSelectRow();

      this.$emit("pagination", pPagination);
    },

    cleanSelectRow() {
      this.selectedRow = null;
    },

    isSelectedAndCheckRow(pIndex, pIndexCol) {
      if (this.total <= 0) {
        return;
      }
      if (!this.hasCheck || pIndexCol !== this.firstIndexSelected) {
        return;
      }

      if (this.multSelect) {
        return this.multSelectedRow.find((el) => {
          return el.index === pIndex;
        });
      }
      return this.selectedRow === pIndex;
    },

    isSelectedRow(pItem, pIndex) {
      if (this.total <= 0) {
        return false;
      }
      if (this.multSelect) {
        if (!this.indexColumn || !pItem[this.indexColumn]) {
          return this.multSelectedRow.find((el) => el.index === pIndex);
        } else {
          return this.multSelectedRow.find(
            (el) => el[this.indexColumn] === pItem[this.indexColumn],
          );
        }
      }
      return this.selectedRow === pIndex;
    },

    isDisabledRow(pItem) {
      return pItem.disabled === true;
    },

    getItem(pItem, pIndex) {
      if (this.dontSelect) {
        return;
      }

      if (this.multSelect) {
        this.getMultItems(pItem, pIndex);
        this.$emit("getMultLines", this.multSelectedRow);
      } else {
        const isSameLine = this.selectedRow === pIndex;
        if (this.toggleSelected && isSameLine) {
          this.selectedRow = null;
          this.$emit("getLine", null);
        } else {
          this.selectedRow = pIndex;

          if (pItem && !pItem.without_result) {
            this.$emit("getLine", pItem, pIndex);
          }
        }
      }
    },

    getMultItems(pItem, pIndex) {
      if (!this.indexColumn || !pItem[this.indexColumn]) {
        const alreadySelected = this.multSelectedRow.find(
          (el) => el.index === pIndex,
        );
        if (alreadySelected) {
          pItem.index = false;
          this.multSelectedRow.map((el, index) => {
            if (el.index === false) {
              this.multSelectedRow.splice(index, 1);
            }
          });
        } else {
          pItem.index = pIndex;
          this.multSelectedRow.push(pItem);
        }
      } else {
        const alreadySelected = this.multSelectedRow.find((el) => {
          return el[this.indexColumn] === pItem[this.indexColumn];
        });

        const index = this.multSelectedRow.findIndex((el) => {
          return el[this.indexColumn] === pItem[this.indexColumn];
        });

        if (alreadySelected) {
          pItem.isSelected = false;
          this.multSelectedRow.splice(index, 1);
        } else {
          pItem.isSelected = true;
          pItem.index = pIndex;
          this.multSelectedRow.push(pItem);
        }
      }
    },

    resetLimitOffset() {
      this.$refs.paginator.resetLimitOffset();
      this.$refs.paginator.loadPagination();
    },

    setScrollTopZero() {
      const elementContainer = this.$refs.scrollArea;
      if (this.$refs.scrollArea) {
        elementContainer.scrollTop = 0;
      }
    },

    setScrollOnSelectedItem() {
      this.$nextTick(() => {
        const classElement = "active";
        const element = this.$el.getElementsByClassName(classElement)[0];

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      });
    },

    updateMultSelectRow(pList) {
      this.cleanMultSelectedRow();
      this.multSelectedRow = pList.map((item) => item);
    },

    selectAllList() {
      if (this.body.length > 0) {
        this.cleanMultSelectedRow();
        this.body.forEach((ele, index) => {
          ele.index = index;
          this.multSelectedRow.push(ele);
        });
      }

      this.$emit("getMultLines", this.multSelectedRow);
    },

    unselectAllList() {
      if (this.body.length > 0) {
        this.body.forEach((ele, index) => {
          ele.index = false;
        });
        this.cleanMultSelectedRow();
      }

      this.$emit("getMultLines", this.multSelectedRow);
    },

    reverseListSelection() {
      // Antes de alterar essa lógica. Verifica a saída no multSelectedRow.
      this.body.map((ele, i) => {
        if (ele?.index === undefined || ele.index === false) {
          ele.index = i;
        } else {
          ele.index = false;
        }
      });
      this.cleanMultSelectedRow();
      this.multSelectedRow = this.body.filter((e) => {
        return e.index !== false;
      });
      this.$emit("getMultLines", this.multSelectedRow);
    },

    separateItens(item, separator) {
      return item && separator ? item.split(separator) : "-";
    },
    getColumnToSeeMore() {
      for (const data of this.columnTable) {
        if (data.seeMore === true) {
          this.columnToSeeMore = data.key;
        }
      }

      this.$emit("columnToSeeMore", this.columnToSeeMore);
    },
    seeMoreItem(item) {
      if (item && item !== null) {
        const data = item.split(this.separatorSeeMore);
        if (data.length > 1) {
          item = data[0] + " ...";
        }
      }

      return item;
    },

    async savePreferences() {
      try {
        const modulesListId = this.$store.getters["Login/GET_ROUTE_MODULE_MAP"];
        const locationModule = window.location.pathname;
        let moduleId = null;

        for (const iterator of Object.keys(modulesListId)) {
          if (locationModule.includes(iterator)) {
            moduleId =
              modulesListId[iterator] === null ? 0 : modulesListId[iterator];
          }
        }

        const variables = {
          preferenceColumnTable: {
            moduleId,
            userId: this.userLoginId,
            name: this.name,
            unitHealthId: this.unitHealthId,
            link: this.$route.path,
            columns: this.header
              .filter(
                (element) =>
                  element.active === true && element.key !== "without_result",
              )
              .map((element) => {
                return {
                  active: element.active,
                  align: element.align,
                  key: element.key,
                  name: element.name,
                };
              }),
          },
        };
        if (variables.preferenceColumnTable?.columns?.length > 0) {
          await this.$store.dispatch("User/SAVE_PREFERENCE_COLUMNS", variables);
        }
      } catch (error) {
        this.$toaster.error("Erro ao salvar preferência de colunas");
      }
    },

    async loadPreferences() {
      try {
        if (
          !this.getColumns(
            this.$route.path,
            this.userLoginId,
            this.unitHealthId,
            this.name,
          ) ||
          this.loadPreferenceColumn
        ) {
          const modulesListId = this.$store.getters[
            "Login/GET_ROUTE_MODULE_MAP"
          ];
          const locationModule = window.location.pathname;
          const moduleId = [];

          for (const iterator of Object.keys(modulesListId)) {
            if (locationModule.includes(iterator)) {
              moduleId.push(
                modulesListId[iterator] === null ? 0 : modulesListId[iterator],
              );
            }
          }

          const result = await this.$store.dispatch(
            "User/SEARCH_PREFERENCE_COLUMNS",
            {
              moduleId:
                moduleId.length > 1
                  ? moduleId[moduleId.length - 1].toString()
                  : moduleId.toString(),
              userId: this.userLoginId,
              unitHealthId: this.unitHealthId,
            },
          );

          for (const columnsTable of result) {
            if (
              !this.getColumns(
                columnsTable.link,
                columnsTable.usu_id,
                columnsTable.uns_id,
                columnsTable.name,
              ) ||
              this.loadPreferenceColumn
            ) {
              this.$store.commit("User/SET_SMART_TABLE_COLUMNS", {
                ...columnsTable,
              });
              if (
                this.userLoginId === columnsTable.usu_id &&
                this.name === columnsTable.name &&
                this.unitHealthId === columnsTable.uns_id &&
                this.$route.path === columnsTable.link
              ) {
                if (columnsTable.columns.length > 0) {
                  for (const col of this.header) {
                    if (col.key !== "without_result") col.active = false;
                  }
                  for (const option of columnsTable.columns) {
                    for (const col of this.header) {
                      if (col.key === option.key) {
                        col.active = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        this.$toaster.error("Erro ao buscar preferência de colunas");
      }
    },
  },
};
</script>
