import GET_EMPLOYEE_SCALE_EXAM from "./GetEmployeeScaleExam";
import SEARCH_EMPLOYEE_AGENDA from "./SearchEmployeeAgenda";
import SEARCH_APPOINTMENT_SCHEDULE from "./SearchAppointmentSchedule";
import REMOVE_EXAM from "./RemoveExam";
import GET_SUBPROCEDURE from "./GetSubprocedure";
import SCHEDULE_PATIENT from "./SchedulePatient";
import EDIT_SCHEDULE_PATIENT from "./EditSchedulePatient";
import SAVE_PROFESSIONAL_SCALE from "./SaveProfessionalScale";
import GET_PROFESSIONAL_SCALE from "./GetProfessionalScale";
import GET_PRINT_CONSULTATION_CONTROL from "./GetPrintConsultationControl";
import GET_PRINT_PROCEDURE_RECOMMENDATIONS_SHEET from "./GetPrintProcedureRecommendationsSheet";
import GET_PRINT_SUB_PROCEDURE_RECOMMENDATIONS_SHEET from "./GetPrintSubProcedureRecommendationsSheet";
import GET_SCHEDULE_PROOF_INSERTION_QUEUE from "./GetScheduleProofInsertionQueue";
import SEARCH_OBSERVATION_QUEUE from "./SearchObservationQueue";
import SAVE_OBSERVATION_QUEUE from "./SaveObservationQueue";
import SCHEDULE_LOAD from "./ScheduleLoad";
import GET_PRINT_SCHEDULE_THERMAL from "./GetPrintScheduleThermal";
import GET_PRINT_SCHEDULE_NORMAL from "./GetPrintScheduleNormal";
import GET_PRINT_SCHEDULE_BATCH from "./GetPrintScheduleBatch";
import GET_PRINT_SCHEDULE_BATCH_WITH_PREPARATION_SUB from "./GetPrintScheduleBatchWithPreparationSub";
import SEARCH_EMPLOYEE_SCHEDULE_PERIOD from "./SearchEmployeeSchedulePeriod";
import GET_SUBPROCEDURE_BY_PROCEDURE from "./GetSubprocedureByProcedure";
import GET_DAYS_AND_PERIODS_INFO_FROM_SECTOR from "./GetDaysAndPeriodsInfoFromSector";
import GET_EXAM_QUEUES_PER_PERSON from "./GetExamQueuesPerPerson";
import ADD_EXAM_QUEUE from "./AddExamQueue";
import GET_EXAM_DETAILS from "./GetExamDetails";
import SEARCH_EXAM_QUEUE from "./SearchExamQueue";
import DELETE_OBSERVATION_QUEUE from "./DeleteObservationQueue";

import SEARCH_PENDENCY_QUEUE from "./SearchPendencyQueue";
import SAVE_PENDENCY_QUEUE from "./SavePendencyQueue";
import GET_PRINT_PENDENCY_QUEUE from "./GetPrintPendencyQueue";
import REMOVE_PENDENCY_QUEUE from "./RemovePendencyQueue";
import SAVE_RESOLUTION_QUEUE from "./SaveResolutionQueue";
import REMOVE_RESOLUTION_QUEUE from "./RemoveResolutionQueue";

import GET_DATA_PATIENT_EXAM_QUEUE from "./GetDataPatientExamQueue";
import GET_PATIENT_BASIC_INFO from "./GetPatientBasicInfo";
import EDIT_EXAM_QUEUE from "./EditExamQueue";
import REMOVE_EXAM_QUEUE from "./RemoveExamQueue";

import ADVANCE_PATIENT_QUEUE from "./AdvancePatientQueue";
import BACK_PATIENT_QUEUE from "./BackPatientQueue";
import MOVE_PATIENT_QUEUE from "./MovePatientQueue";

import REGULATE_EXAM_QUEUE from "./RegulateExamQueue";
import UNREGULATE_EXAM_QUEUE from "./UnregulateExamQueue";

import SUBPROCEDURES_REGULATION_EXTERNAL from "./SubprocesuresRegulationExternal";
import SAVE_EXAM_EXTERNAL_SCHEDULE from "./SaveExamExternalSchedule";

import GET_AVAILABLE_VACANCIES from "./transfer/GetAvailableVacancies";
import GET_DESTINY from "./transfer/GetDestiny";
import GET_ORIGIN from "./transfer/GetOrigin";
import TRANSFER_PRINT from "./transfer/Print";
import TRANSFER from "./transfer/Transfer";
import SEARCH_HISTORY_SCHEDULE from "./SearchHistorySchedule";

import GET_UNITS_PERFORM_SUB_PROCEDURE from "./exam-schedule-queue/GetUnitsPerformSubProcedure";
import GET_DAYS_ON_WEEK_OF_PERIODS from "./exam-schedule-queue/GetDaysOnWeekOfPeriods";
import SAVE_EXAM_SCHEDULE_QUEUE from "./exam-schedule-queue/SaveExamScheduleQueue";
import SAVE_EXAM_SCHEDULE_QUEUE_REASON from "./exam-schedule-queue/SaveExamScheduleQueueReason";
import REGISTER_SCHEDULE_VOUCHER_BATCH from "./exam-schedule-queue/RegisterScheduleVoucherBatch";

import SEARCH_PATIENT_EXCLUDE_IN_QUEUE from "./SearchPatientExcludeInQueue";
import BACK_PATIENT_FROM_QUEUE from "./BackPatientFromQueue";
import GET_PRINT_PATIENT_EXCLUDE from "./search-patient-exclude/GetPrintPatientExclude";

import SEARCH_DISABLED_PERIODS from "./disabled-periods/SearchDisabledPeriods";
import VALIDATE_DISABLE_PERIODS from "./disabled-periods/ValidateDisablePeriods";
import ENABLE_DISABLE_PERIODS from "./disabled-periods/EnableDisablePeriods";
import SEARCH_DISABLED_DAYS from "./disabled-periods/SearchDisabledDays";
import EXCLUDE_DISABLED_PERIODS from "./disabled-periods/ExcludeDisabledPeriods";

import PRINT_PATIENT_SCHEDULE_VOUCHER from "./print/PrintPatientScheduleVoucher";
import PRINT_PATIENT_SCHEDULE_CONSULTATION_AUTHORIZATION from "./print/PrintPatientScheduleConsultationAuthorization";
import PRINT_PATIENT_SCHEDULE_LIST from "./print/PrintPatientScheduleList";
import SCHEDULE_PATIENT_EXAM from "./search-patient-schedule/SchedulePatientExam";
import SEARCH_EXTERNALLY_SCHEDULE from "./externally-schedule/SearchExamExternallySchedule";
import REMOVE_EXTERNALLY_SCHEDULE from "./externally-schedule/RemoveExamExternallySchedule";
import EDIT_EXTERNALLY_SCHEDULE from "./externally-schedule/EditExternallySchedule";

import DELETE_SCHEDULE_QUEUE from "./exam-schedule-queue/DeleteScheduleQueue";

import SEARCH_REQUESTED_QUEUE_EXAMS from "./SearchRequestedQueueExams";

import SEARCH_EXAM_PERMISSION from "./SearchExamePermission";

export default {
  SCHEDULE_PATIENT_EXAM,
  PRINT_PATIENT_SCHEDULE_VOUCHER,
  PRINT_PATIENT_SCHEDULE_CONSULTATION_AUTHORIZATION,
  PRINT_PATIENT_SCHEDULE_LIST,
  GET_EMPLOYEE_SCALE_EXAM,
  SEARCH_EMPLOYEE_AGENDA,
  SEARCH_APPOINTMENT_SCHEDULE,
  REMOVE_EXAM,
  GET_SUBPROCEDURE,
  SCHEDULE_PATIENT,
  SAVE_PROFESSIONAL_SCALE,
  GET_PROFESSIONAL_SCALE,
  GET_PRINT_CONSULTATION_CONTROL,
  SCHEDULE_LOAD,
  EDIT_SCHEDULE_PATIENT,
  GET_PRINT_PROCEDURE_RECOMMENDATIONS_SHEET,
  GET_PRINT_SUB_PROCEDURE_RECOMMENDATIONS_SHEET,
  GET_PRINT_SCHEDULE_THERMAL,
  GET_PRINT_SCHEDULE_NORMAL,
  GET_PRINT_SCHEDULE_BATCH,
  GET_PRINT_SCHEDULE_BATCH_WITH_PREPARATION_SUB,
  SEARCH_OBSERVATION_QUEUE,
  SAVE_OBSERVATION_QUEUE,
  DELETE_OBSERVATION_QUEUE,
  SEARCH_EMPLOYEE_SCHEDULE_PERIOD,
  GET_SUBPROCEDURE_BY_PROCEDURE,
  GET_DAYS_AND_PERIODS_INFO_FROM_SECTOR,
  GET_EXAM_QUEUES_PER_PERSON,
  ADD_EXAM_QUEUE,
  GET_EXAM_DETAILS,
  GET_SCHEDULE_PROOF_INSERTION_QUEUE,
  SEARCH_EXAM_QUEUE,
  SEARCH_PENDENCY_QUEUE,
  REGISTER_SCHEDULE_VOUCHER_BATCH,
  SAVE_PENDENCY_QUEUE,
  GET_PRINT_PENDENCY_QUEUE,
  REMOVE_PENDENCY_QUEUE,
  SAVE_RESOLUTION_QUEUE,
  REMOVE_RESOLUTION_QUEUE,
  GET_DATA_PATIENT_EXAM_QUEUE,
  GET_PATIENT_BASIC_INFO,
  EDIT_EXAM_QUEUE,
  REGULATE_EXAM_QUEUE,
  UNREGULATE_EXAM_QUEUE,
  REMOVE_EXAM_QUEUE,
  ADVANCE_PATIENT_QUEUE,
  BACK_PATIENT_QUEUE,
  MOVE_PATIENT_QUEUE,
  SUBPROCEDURES_REGULATION_EXTERNAL,
  SAVE_EXAM_EXTERNAL_SCHEDULE,
  GET_AVAILABLE_VACANCIES,
  GET_DESTINY,
  GET_ORIGIN,
  TRANSFER_PRINT,
  TRANSFER,
  SEARCH_HISTORY_SCHEDULE,
  GET_UNITS_PERFORM_SUB_PROCEDURE,
  GET_DAYS_ON_WEEK_OF_PERIODS,
  SAVE_EXAM_SCHEDULE_QUEUE,
  SAVE_EXAM_SCHEDULE_QUEUE_REASON,
  SEARCH_PATIENT_EXCLUDE_IN_QUEUE,
  GET_PRINT_PATIENT_EXCLUDE,
  SEARCH_DISABLED_PERIODS,
  VALIDATE_DISABLE_PERIODS,
  ENABLE_DISABLE_PERIODS,
  SEARCH_DISABLED_DAYS,
  EXCLUDE_DISABLED_PERIODS,
  SEARCH_EXTERNALLY_SCHEDULE,
  REMOVE_EXTERNALLY_SCHEDULE,
  EDIT_EXTERNALLY_SCHEDULE,
  DELETE_SCHEDULE_QUEUE,
  BACK_PATIENT_FROM_QUEUE,
  SEARCH_REQUESTED_QUEUE_EXAMS,
  SEARCH_EXAM_PERMISSION,
};
