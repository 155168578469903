import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchBulletinBilling(
    $cnes: String
    $competence: String
    $professionalCns: String
    $patientName: String
    $patientCns: String
    $cboCode: String
    $sheet: String
    $bof_id: Int
    $pdf_id: Int
    $bulletin: String
    $origin: String
    $dateInitial: String
    $dateFinal: String
    $professionalName: String
    $limit: Int
    $offset: Int
  ) {
    SearchBulletinBilling(
      cnes: $cnes
      competence: $competence
      professionalCns: $professionalCns
      patientName: $patientName
      patientCns: $patientCns
      cboCode: $cboCode
      sheet: $sheet
      bof_id: $bof_id
      pdf_id: $pdf_id
      bulletin: $bulletin
      origin: $origin
      dateInitial: $dateInitial
      dateFinal: $dateFinal
      professionalName: $professionalName
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        bof_id
        bof_nome
        bof_id_boletins
        bof_id_unidades_saude
        bof_id_pessoas
        bof_registro_geral
        bof_cns
        bof_sexo
        bof_data_nascimento
        bof_cod_nacionalidade
        bof_cod_raca
        bof_cod_etnia
        bof_cep
        bof_cod_municipio
        bof_cod_tipo_logradouro
        bof_endereco
        bof_endereco_numero
        bof_endereco_complemento
        bof_bairro
        bof_tel
        bof_tel_ddd
        bof_email
        bof_cnes
        bof_competencia
        bof_faturado
        bol_numero
        pdf_id
        pdf_id_boletins_faturamento
        pdf_cod_procedimento
        pdf_quantidade
        pdf_cnpj
        pdf_servico
        pdf_classificacao
        pdf_cid
        pdf_id_internacoes_caracteres
        pdf_num_autorizacao
        pdf_cns
        pdf_nome_profissional
        pdf_cod_cbo
        pdf_data_atendimento
        pdf_cod_ine
        pdf_folha
        pdf_origem_pep
        ica_nome
        data_nascimento
        data_atendimento
        raca
        etnia
        nacionalidade
        logradouro
        municipio
        procedimento
        servico
        classificacao
        cid
        est_id
        est_codigo
        est_sigla
        ens_id
        ens_codigo_ab
        ens_descricao
        ocp_id
        ocp_nome
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    data.SearchBulletinBilling.rows.map((element, index) => {
      element.sequence = String(((index + 1) / 10).toFixed(1)).replace(".", "");
      element.origem_atendimento =
        element?.pdf_origem_pep === 1 ? "PEP" : "ESUS";
      return element;
    });

    return data.SearchBulletinBilling;
  } catch (err) {
    return Promise.reject(err);
  }
};
