<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchSectorProcedureProfessional from "./action/SearchSectorProcedureProfessional";

export default {
  name: "RgSelectSectorProcedureProfessional",
  extends: RgSelect,
  props: {
    unithHealthId: {
      type: Number,
    },
    procedureName: {
      type: String,
    },
    submoduleId: {
      type: Number,
    },
    label: {
      type: String,
      default: "Setor",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    unithHealthId() {
      this.mValue = null;
      this.doSearch();
    },
    procedureName() {
      this.mValue = null;
      this.doSearch();
    },
    valuesData: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      if (this.unithHealthId && this.procedureName && this.submoduleId) {
        this.valuesData = [];

        const data = await SearchSectorProcedureProfessional(
          this.unithHealthId,
          this.procedureName,
          this.submoduleId,
        );

        data.forEach((item) => {
          if (item.enabled === "1") {
            this.valuesData.push({ value: item.data, label: item.label });
          }
        });

        if (data.length === 1) {
          this.$emit("selectItem", data.value);
        }
      }
    },
  },
};
</script>
