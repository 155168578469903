import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query StatusProceduresSigtap($userId: String) {
    StatusProceduresSigtap(userId: $userId) {
      status {
        userId
        procedures {
          code
          title
          message
        }
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.StatusProceduresSigtap;
  } catch (err) {
    return Promise.reject(err);
  }
};
