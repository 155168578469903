import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchUserProductivityReport(
    $uns_id: Int
    $set_id: Int
    $ocp_id: Int
    $pes_id: Int
    $usu_id: Int
    $healthInsurance: Int
    $con_id_tipos_consultas_unidade: Int
    $con_id_locais_atendimento: Int
    $employeeRequest: Int
    $groupById: Int
    $set_nome: String
    $ocp_nome: String
    $usu_nome: String
    $healthInsuranceName: String
    $con_id_tipos_consultas_unidade_name: String
    $con_id_locais_atendimento_name: String
    $employeeRequestName: String
    $initialDate: String
    $finalDate: String
    $initialDateAppointment: String
    $finalDateAppointment: String
    $groupByName: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchUserProductivityReport(
      uns_id: $uns_id
      set_id: $set_id
      ocp_id: $ocp_id
      pes_id: $pes_id
      usu_id: $usu_id
      healthInsurance: $healthInsurance
      con_id_tipos_consultas_unidade: $con_id_tipos_consultas_unidade
      con_id_locais_atendimento: $con_id_locais_atendimento
      employeeRequest: $employeeRequest
      groupById: $groupById
      set_nome: $set_nome
      ocp_nome: $ocp_nome
      usu_nome: $usu_nome
      healthInsuranceName: $healthInsuranceName
      con_id_tipos_consultas_unidade_name: $con_id_tipos_consultas_unidade_name
      con_id_locais_atendimento_name: $con_id_locais_atendimento_name
      employeeRequestName: $employeeRequestName
      initialDate: $initialDate
      finalDate: $finalDate
      initialDateAppointment: $initialDateAppointment
      finalDateAppointment: $finalDateAppointment
      groupByName: $groupByName
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        QUANTIDADE
        OCUPACAO
        PROFISSIONAL
        SETOR
        OBJETIVO
        PERCENT
        USUARIO
        UNS_NOME
        LOCAL_ATENDIMENTO
        CONVENIO
        DATA_CONSULTA
        DATA_CADASTRO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchUserProductivityReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
