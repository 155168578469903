<template slot="subFilters">
  <div class="pharmacy-satellite-submodule">
    <div class="grid">
      <div class="selectinput">
        <RgSelectProductType
          id="productType"
          v-model="form.mtp_id"
          default-text="Todos"
          class="inputitem"
          @change="selectedProductType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RgSelectProductType from "$pharmacy/common/select/rg-select-product-type/RgSelectProductType";

const FORM_FILTER = {
  productTypeId: null,
  lotAndExpiration: null,
};

export default {
  name: "PharmacySatelliteEntryVsOutputReport",
  components: {
    RgSelectProductType,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
    globalFilters: Object,
    columnsTable: Function,
  },
  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      userId: this.$store.getters["Login/GET_USER_ID"],
    };
  },

  watch: {
    columnsToPrint(columnsToPrint) {
      if (columnsToPrint) {
        this.form.columnsToPrint = columnsToPrint;
      }
    },
  },

  mounted() {
    this.columnsTable([
      { name: "Código", key: "CODIGO", align: "right" },
      { name: "Nome", key: "NOME", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Unidade de Saúde", key: "UNIDADE_SAUDE" },
      { name: "Farmácia", key: "FARMACIA" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Tipo", key: "TIPO", align: "left" },
      { name: "Lote-Validade", key: "LOTE_VALIDADE", align: "left" },
      { name: "Entradas", key: "ENTRADAS", align: "right" },
      { name: "Saidas", key: "SAIDAS", align: "right" },
    ]);
  },

  methods: {
    selectedProductType(pValue) {
      if (pValue) {
        this.form.mtp_nome = pValue.mtp_nome;
      } else {
        this.form.mtp_nome = null;
      }
    },
    async doSearchSubReport(pValue) {
      try {
        const variables = {
          ...this.form,
          mtp_id: Number(this.form.mtp_id),
        };
        const globalFilters = {
          ...this.globalFilters,
          initialDate: this.$utils.date.BrazilianDateToDatabase(
            this.globalFilters.initialDate,
          ),
          finalDate: this.$utils.date.BrazilianDateToDatabase(
            this.globalFilters.finalDate,
          ),
          loggedUserId: this.userId,
          pharmacy: Number(this.globalFilters.pharmacy),
          product: Number(this.globalFilters.product),
        };
        const filters = {
          ...globalFilters,
          ...variables,
          ...pValue,
        };

        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_SATELLITE_ENTRY_OUTPUT",
          filters,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.document = null;
      this.disabledDocument = false;
    },
  },
};
</script>
