<script>
import { RgComboboxMixin } from "~tokio/primitive";
import SearchGenre from "./action/SearchGenre";

export default {
  name: "RgComboboxGender",
  mixins: [RgComboboxMixin],
  props: {
    label: {
      default: "Gênero",
    },
    emptyDefaultText: {
      type: String,
      default: "Selecione",
    },
  },
  data: () => {
    return {};
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.mData = [];

      const data = await SearchGenre();

      data.forEach((item) => {
        this.mData.push({ value: item.gen_id, text: item.gen_descricao });
      });
    },
  },
};
</script>
