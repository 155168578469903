<style
  src="./AppointmentProfessionalProductivityReport.scss"
  lang="scss"
  scoped
></style>
<template>
  <div class="professional-productivity-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-appr"
          v-model="form.uns_id"
          permission="consulta.relatoriosESUSng.Exibir"
          :rules="{ required: true }"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-appr"
          v-model="form.set_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unit-health="form.uns_id"
          default-text="Todos"
          empty-default-text="Todos"
          class="inputitem"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          id="ocp-nome-appr"
          ref="occupation"
          v-model="form.ocp_nome"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          :employeeId="professionalBond"
          label="Ocupação"
          class="inputitem"
          @selected="selectedCbo"
        />
      </div>

      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-appr"
          v-model="form.pes_nome"
          :maxlength="100"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :ocuppationId="form.ocp_id"
          label="Profissional"
          class="inputitem"
          @selected="selectedEmployee"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-appr"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
          popUp="top"
        />
        <RgInputDate
          id="final-date-appr"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateFinal"
          label="Data Final"
          class="inputitem mg"
          popUp="top"
        />
      </div>

      <div class="selectinput">
        <RgRadioCustom
          id="scale"
          ref="scale"
          v-bind="propsRadioCustom"
          label="Agrupar por:"
          @input="onInputRadioCustom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgSelectSector,
  RgSuggestCbo,
  RgSuggestEmployee,
  RgInputDate,
  RgRadioCustom,
} from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  set_id: null,
  set_nome: "",
  ocp_nome: "",
  ocp_id: null,
  pes_nome: "",
  pes_id: null,
  scale: null,
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
};

export default {
  name: "AppointmentProfessionalProductivityReport",
  components: {
    RgSelectUnithealth,
    RgSelectSector,
    RgSuggestCbo,
    RgSuggestEmployee,
    RgInputDate,
    RgRadioCustom,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Produtividade Profissional de Saúde",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      professionalBond: null,
    };
  },
  computed: {
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; ; text-align: left;}" +
        ".report-column-2 { white-space: normal !important; ; text-align: left;}"
      );
    },
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "AppointmentProfessionalProductivityReport";
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },
    getMaxDateFinal() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },

    propsRadioCustom() {
      const list = [{ title: "Por Escala", id: 1 }];

      const value = this.form.scale;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },
  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
      }
    },

    "form.ocp_id"(pValue) {
      if (!pValue && !this.form.pes_id) {
        this.professionalBond = null;
      }
    },
    "form.pes_id"(pValue) {
      if (!pValue && !this.form.ocp_id) {
        this.professionalBond = null;
      }
    },
    "form.initialDate"(pValue, pPrev) {
      if (pValue && !this.form.finalDate) {
        if (this.$refs.initialDate) {
          this.$refs.initialDate.cleanValidate();
        }
      }
    },
  },
  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  created() {
    this.MAX_DAYS_DATE_INTERVAL = 31;
    this.COLUMN = [
      { name: "Profissional de Saúde", key: "PROFISSIONAL", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Tipo de Escala", key: "ESCALA", align: "left" },
      { name: "Disponibilizadas", key: "DISPONIBILIZADAS" },
      { name: "Agendadas", key: "AGENDADAS" },
      { name: "Efetivadas", key: "EFETIVADAS" },
      { name: "Não Efetivadas", key: "NAO_EFETIVADAS" },
      { name: "Aguardando", key: "AGUARDANDO" },
      { name: "Vagas Restantes", key: "VAGAS_RESTANTES" },
      { name: "Produtividade", key: "PRODUTIVIDADE" },
    ];
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.scale = item.id;
      } else {
        this.form.scale = null;
      }
    },
    buildFilter() {
      const payLoad = {
        uns_id: this.form.uns_id || null,
        set_id: this.form.set_id || null,
        ocp_id: this.form.ocp_id || null,
        pes_id: this.form.pes_id || null,
        set_nome: this.form.set_nome || null,
        ocp_nome: this.form.ocp_nome || null,
        pes_nome: this.form.pes_nome || null,
        scale: this.form.scale || null,
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Appointment/Report/GET_APPOINTMENT_PROFESSIONAL_PRODUCTIVITY_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
      this.form.ocp_nome = null;
    },

    selectedCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.form.ocp_nome = pValue.source.ocp_nome;
        this.$loader.finish();
      } else {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
      }
    },

    selectedEmployee(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_id, pes_nome } = source;
        this.form.pes_id = pes_id;
        this.form.pes_nome = pes_nome;
        this.professionalBond = source.employeeRelationship.vin_id_funcionarios;
      } else {
        this.form.pes_id = null;
        this.form.pes_nome = null;
        this.professionalBond = null;
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }

      const daysDateInterval = finalDate.diff(initialDate, "days");
      if (daysDateInterval > this.MAX_DAYS_DATE_INTERVAL) {
        pErrors.push(
          "O periodo entre a data inicial e final não pode ser superior a 1 mês",
        );
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
      const daysDateInterval = finalDate.diff(initialDate, "days");

      if (daysDateInterval > this.MAX_DAYS_DATE_INTERVAL) {
        pErrors.push(
          "O periodo entre a data inicial e final não pode ser superior a 1 mês",
        );
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.professionalBond = null;

      if (this.$refs.occupation) {
        this.$refs.occupation.cleanValidate();
      }

      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }
    },
  },
};
</script>
