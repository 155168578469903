<style src="./RelationshipRemoveReasonModal.scss" lang="scss" scoped></style>

<template>
  <div v-if="show" class="modal-test">
    <RgBaseModal :show="show">
      <div slot="header" class="modal-test">
        <h3>Inativamento de Vínculo</h3>
      </div>
      <div slot="body" class="modal-test">
        <div class="row">
          <RgComboboxRelationshipRemoveReason
            v-model="form.dlv_id_motivos_desligamento"
            class="col-12"
            label="Motivo"
          />
        </div>
        <div class="row between-all">
          <RgInputNumber
            v-model="form.dlv_codigo"
            v-mask="'##'"
            :rules="{ required: true }"
            class="col-4"
            label="Código"
          />
          <RgInputDate
            v-model="form.dlv_desligamento"
            :rules="{ required: true }"
            class="col-5"
            label="Data"
          />
        </div>
      </div>
      <div slot="footer" class="modal-test">
        <div class="row modal-btn">
          <button
            class="btn btn-default btn-danger col-3"
            @click.stop="closeModal"
          >
            Cancelar
          </button>
          <button
            class="btn btn-default btn-success col-3"
            @click.stop="deactivateRelationship"
          >
            Confirmar
          </button>
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgInputNumber, RgInputDate } from "~tokio/primitive";
import { mask } from "vue-the-mask";
import RgComboboxRelationshipRemoveReason from "@/app/employee/common/components/container/combobox/rg-combobox-relationship-remove-reason/RgComboboxRelationshipRemoveReason";
export default {
  components: {
    RgBaseModal,
    RgInputNumber,
    RgInputDate,
    RgComboboxRelationshipRemoveReason,
  },
  directives: {
    mask,
  },
  props: {
    show: {
      default: false,
    },
  },
  data() {
    return {
      form: {
        dlv_id_motivos_desligamento: null,
        dlv_codigo: null,
        dlv_desligamento: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    deactivateRelationship() {
      this.$emit("deactivate-relationship", this.form);
    },
  },
};
</script>
