<template lang="html">
  <div v-if="show" class="modal-types-print">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <div class="title">Emissão de Comprovante</div>
      </div>

      <div slot="body" class="body">
        <FormBase class="form-base" title="Emitir Comprovante?">
          <div class="grid informations">
            <div class="line">
              <RgButton
                id="print-thermal-one-way"
                class="btn-generate"
                title="Clique para imprimir na Impressora Térmica 1"
                @click="printThermalOneWay"
              >
                <span class="span">Impressora Térmica 1 via</span>
              </RgButton>
            </div>

            <div class="line">
              <RgButton
                id="print-thermal-two-way"
                class="btn-generate"
                title="Clique para imprimir na Impressora Térmica 2"
                @click="printThermalTwoWay"
              >
                <span class="span">Impressora Térmica 2 vias</span>
              </RgButton>
            </div>

            <div class="line">
              <RgButton
                id="print-schedule-voucher"
                class="btn-generate"
                title="Impressora Normal"
                @click="printScheduleVoucher"
              >
                <span class="span">Impressora Normal</span>
              </RgButton>
            </div>
          </div>
        </FormBase>
      </div>

      <div slot="footer" class="footer">
        <div class="footer-buttons">
          <RgCancelButton
            id="cancel-btn"
            class="cancel"
            medium
            @click="close"
          />
        </div>
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import {
  ScheduleVoucherHTML,
  ScheduleThermalOneWayHTML,
} from "$exam/submodules/schedule/html";

import { RgBaseModal, RgCancelButton, RgButton } from "~tokio/primitive";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "ModalTypesPrint",
  components: {
    RgBaseModal,
    FormBase,
    RgCancelButton,
    RgButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    examIds: {
      type: Array,
      default: () => [],
    },
    isInBatch: {
      type: Boolean,
      default: false,
    },
    preparationSub: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },
  beforeDestroy() {
    this.preparationSub = false;
  },

  methods: {
    close() {
      this.$emit("close");
    },
    printed() {
      this.$emit("printed", this.examIds);
    },

    async printThermalOneWay() {
      const data = await this.getDataToPrint();
      const html = ScheduleThermalOneWayHTML(data, this.preparationSub);
      this.$utils.print.printHtml(html);
      this.printed();
      this.close();
    },

    async printThermalTwoWay() {
      const data = await this.getDataToPrint();
      let html = ScheduleThermalOneWayHTML(data, this.preparationSub);

      html += html;

      this.$utils.print.printHtml(html);
      this.printed();
      this.close();
    },

    async printScheduleVoucher() {
      const data = await this.getDataToPrint();
      const html = ScheduleVoucherHTML(data, this.preparationSub);
      this.$utils.print.printHtml(html);
      this.printed();
      this.close();
    },

    async getDataToPrint() {
      const payload = { ids: this.examIds };
      const path = this.preparationSub
        ? "GET_PRINT_SCHEDULE_BATCH_WITH_PREPARATION_SUB"
        : "GET_PRINT_SCHEDULE_BATCH";

      return await this.$store.dispatch(`Exam/Schedule/${path}`, payload);
    },
  },
};
</script>
