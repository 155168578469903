import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExportQueueInfo from "$queue/view/export-queue-info/ExportQueueInfo";
import UserGroupAuth from "~common/utils/UserGroupAuth";
import { UserGroup } from "~common/utils/app/";

export default {
  path: "/register/configuration/export-queue-info",
  name: "register.configuration.exportQueueInfo",
  components: {
    default: ExportQueueInfo,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Configuração", link: "/home" },
      { label: "Exportar Informações da Fila" },
    ],
    restrictTo: [UserGroup.ADM, UserGroup.SUPPORT, UserGroup.DEVELOP],
  },

  beforeEnter: UserGroupAuth,
};
