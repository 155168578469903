<template>
  <section class="individual-effectivation">
    <RgSearch
      ref="RgSearch"
      v-model="mutableExamList"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="36"
      :max-register="30"
      result-title="Efetivação"
      @afterPerformSearch="afterSearchFilter"
      @submitFromButton="actionCleanSelection"
      @count="getCountValue"
    >
      <section slot="filters" class="individual-search-filter">
        <div class="filter-grid">
          <RgSelectUnithealth id="unit-health" v-model="form.uns_id" disabled />

          <RgSelectSector
            id="sector"
            v-model="form.sector"
            :unit-health="form.uns_id"
            :permission="sectorPermission"
            label="Setor"
            @change="selectingSector"
          />

          <RgSuggestEmployee
            id="employee-request"
            ref="requestedEmployee"
            v-model="requestedEmployee"
            label="Profissional Solicitante"
            placeholder="Digite o nome do profissional"
            sectorRequired
            :sector="form.sector"
            :class="{ disable: !form.sector }"
            :disabled="!form.sector"
            :vus-responsavel="false"
            :filterByActiveOrInactive="false"
            @selected="selectingRequestedEmployee"
          />

          <div class="filter-title">
            <span class="title">Status do Exame</span>
          </div>
          <RgRadioCustom
            id="exam-status"
            ref="status"
            v-bind="propsRadioCustom"
            class="custom-radio"
            multSelect
            @input="onInputRadioCustom"
          />

          <div class="filter-title">
            <span class="title">Dados do Paciente</span>
          </div>

          <RgInput
            id="patient"
            v-model="form.patient"
            placeholder="Digite o nome do paciente"
            label="Paciente"
          />

          <RgSuggestSmartPerson
            id="document"
            v-model="form.document"
            filterOnlyDocument
            @selected="selectingDocument"
          />

          <div class="date-periods">
            <RgInput
              id="hospitalization-number"
              v-model="form.hospitalizationNumber"
              placeholder="Digite a internação"
              label="Internação"
            />
            <RgInput
              id="bulletin-number"
              v-model="form.bulletinNumber"
              placeholder="Digite o boletim"
              label="Boletim"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados da Solicitação</span>
          </div>

          <RgSelectExamOrigin
            id="exam-type"
            v-model="form.examType"
            class="origin"
            label="Origem"
            default-text="Todos"
          />

          <RgSuggestProcedureEffectivation
            id="procedure"
            ref="Procedure"
            v-model="form.procedureCode"
            label="Procedimento"
            @selected="selectingProcedure"
          />

          <RgSelectScaleType
            id="scale-type"
            v-model="form.typeScale"
            :unit-health-id="form.uns_id"
            class="inputitem"
            label="Tipos de Escala"
            :empty-default-text="'Selecione uma Unidade'"
            default-text="Todas"
          />

          <div class="filter-title">
            <span class="title">Período da Solicitação</span>
          </div>

          <div class="date-periods">
            <RgInputDate
              id="request-initial-date"
              v-model="form.requestInitialDate"
              label="Data Inicial"
            />

            <RgInputDate
              id="request-final-date"
              v-model="form.requestFinalDate"
              label="Data Final"
            />
          </div>

          <div class="filter-title">
            <span class="title">Dados da Efetivação</span>
          </div>

          <div class="filter-subtitle filter-title">
            <span class="period-subtitle">Período da Efetivação</span>
          </div>

          <div class="date-periods">
            <RgInputDate
              id="effectiveness-initial-date"
              v-model="form.effectivenessInitialDate"
              label="Data Inicial"
            />
            <RgInputDate
              id="effectiveness-final-date"
              v-model="form.effectivenessFinalDate"
              label="Data Final"
            />
          </div>
        </div>
      </section>

      <section slot="search-top" class="individual-search-list-top">
        <Tooltip
          message="Faça a efetivação em lote selecionando mais de um registro que tenha o mesmo paciente ou o mesmo procedimento."
          startOpen
          class="tooltip"
        >
          <IconInfoHelper slot="icon" hollow />
        </Tooltip>
      </section>
      <div v-if="!hasExamList" slot="menu-top" class="top-button">
        <RgAddButton
          id="new-exam"
          large
          label="Novo"
          title="Novo Exame"
          :permission="canRegister"
          @click="registerExam"
        />
      </div>
      <main class="individual-effectivation-list">
        <section v-if="hasExamList" class="table">
          <SmartTable
            ref="smartTable"
            name="individualEffectivationList"
            :columns="COLUMN_TABLE"
            :body="mutableExamList"
            :total="Number(totalItems)"
            :initial-columns="5"
            :show-pagination="false"
            :color-line="actionColorLine"
            :dynamic-height="getSpaceTable"
            index-column="exe_id"
            circle-indicator
            toggle-selected
            mult-select
            class="smart-table"
            @getMultLines="selectLines"
          >
            <div slot="top-buttons" class="top-buttons">
              <RgAddButton
                id="register-exam"
                :permission="canRegister"
                title="Novo Exame"
                @click="registerExam"
              />
              <RgEditButton
                id="edit-exam"
                :permission="canEdit"
                :disabled="disabledEdit"
                title="Editar Exame"
                @click="editExam"
              />
              <RgLessButton
                id="delete-exam"
                :permission="canRemove"
                :disabled="disableRemove"
                title="Excluir Exame"
                @click="openModalConfirmDeletion"
              />
              <RgShowButton
                id="show-exam"
                title="Visualizar Exame"
                :disabled="disableView"
                @click="viewExam"
              />
            </div>
          </SmartTable>
        </section>
        <section v-else class="empty"></section>
      </main>

      <footer
        v-if="hasExamList"
        slot="menu-bottom"
        class="individual-effectivation-buttons-footer"
      >
        <SmallButton
          id="effectuate"
          title="Efetivar"
          :permission="canEffectiveness"
          :backgroundColor="'#1e88a9'"
          :disabled="!hasExamSelected || examEffectivated"
          @click="effectuate"
        >
          <IconEffectiveSchedule
            slot="icon"
            :disabled="!hasExamSelected || examEffectivated"
            :color="'#1e88a9'"
          />
        </SmallButton>

        <SmallButton
          id="not-effectuate"
          title="Não Efetivar"
          :permission="canNotEffectiveness"
          :backgroundColor="'#1e88a9'"
          :disabled="!hasExamSelected || examNotEffectivated"
          @click="notEffectuate"
        >
          <IconNotEffectiveSchedule
            slot="icon"
            :disabled="!hasExamSelected || examNotEffectivated"
            :color="'#1e88a9'"
          />
        </SmallButton>

        <SmallButton
          id="is-open"
          title="Em Aberto"
          :permission="canOpened"
          :backgroundColor="'#1e88a9'"
          :disabled="!hasExamSelected || examOpened"
          @click="effectuateOpen"
        >
          <IconOpenSchedule
            slot="icon"
            :disabled="!hasExamSelected || examOpened"
            :color="'#1e88a9'"
          />
        </SmallButton>
      </footer>
    </RgSearch>

    <ModalViewIndividualEffectivation
      id="view-effectivation-details"
      :show="showModalViewEffectivationDetails"
      @close="closeModalViewEffectivationDetails"
    />

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmDeletion"
      @reSearch="reSearch"
    />

    <ModalEffectiveExam
      id="modal-effective-exam"
      :show="showModalEffectiveExam"
      :exam-info="listSelected"
      @reSearch="reSearch"
      @close="closeModalEffectivateExam"
    />

    <ModalNotEffectiveExam
      id="modal-not-effective-exam"
      :show="showModalNotEffectiveExam"
      :exam-info="listSelected"
      @reSearch="reSearch"
      @close="closeModalNotEffectivateExam"
    />
  </section>
</template>

<script>
import moment from "moment";

import RgSelectExamOrigin from "$exam/submodules/view/individual-effectivation/component/select/rg-select-exam-origin/RgSelectExamOrigin";
import PrepareExamToSave from "$exam/store/actions/PrepareExamToSave";
import ModalViewIndividualEffectivation from "$exam/submodules/view/individual-effectivation/component/modal/modal-view-individual-effectivation/ModalViewIndividualEffectivation";

import EXAM_STATUS from "$exam/submodules/view/individual-effectivation/component/radio/rg-radio-exam-status/GetExamStatus";

import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";
import GetColorRowSmartTable from "~common/utils/GetColorRowSmartTable";

import { mapGetters } from "vuex";
import { RgSearch, SmartTable } from "~tokio/foundation";

import {
  Tooltip,
  RgSelectUnithealth,
  RgInputDate,
  RgInput,
  RgSelectSector,
  RgShowButton,
  RgSuggestSmartPerson,
  IconInfoHelper,
  RgRadioCustom,
  RgSuggestEmployee,
} from "~tokio/primitive";

import { RgSelectScaleType } from "$exam/submodules/schedule/component/";

import RgSuggestProcedureEffectivation from "$exam/submodules/view/individual-effectivation-details/component/rg-suggest-procedure/RgSuggestProcedureEffectivation";

import { ModalConfirmDeletion } from "~tokio/primitive/modal";

import {
  SmallButton,
  RgAddButton,
  RgLessButton,
  RgEditButton,
} from "~tokio/primitive/button";

import {
  IconEffectiveSchedule,
  IconNotEffectiveSchedule,
  IconOpenSchedule,
} from "~tokio/primitive/icon";

import {
  ModalEffectiveExam,
  ModalNotEffectiveExam,
} from "$exam/submodules/view/individual-effectivation/component/modal";

export default {
  name: "IndividualEffectivation",
  components: {
    RgSearch,
    SmallButton,
    RgAddButton,
    RgLessButton,
    RgEditButton,
    SmartTable,
    Tooltip,
    ModalConfirmDeletion,
    ModalEffectiveExam,
    ModalNotEffectiveExam,
    ModalViewIndividualEffectivation,
    RgSelectExamOrigin,
    RgSuggestProcedureEffectivation,
    RgInputDate,
    RgSelectUnithealth,
    RgSelectSector,
    RgSuggestSmartPerson,
    RgInput,
    RgShowButton,
    IconEffectiveSchedule,
    IconNotEffectiveSchedule,
    IconOpenSchedule,
    IconInfoHelper,
    RgRadioCustom,
    RgSelectScaleType,
    RgSuggestEmployee,
  },

  data() {
    return {
      sectorPermission: 247,
      mutableExamList: [],
      listSelected: [],
      totalItems: 0,
      form: {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        patient: null,
        pes_id: null,
        hospitalizationNumber: null,
        bulletinNumber: null,
        examType: null,
        typeScale: null,
        exe_id_vinculos_solicitante: null,
        requestName: null,
        procedureCode: null,
        requestInitialDate: moment().format("DD/MM/YYYY"),
        requestFinalDate: moment().format("DD/MM/YYYY"),
        exe_id_vinculos: null,
        employeeName: null,
        effectivenessInitialDate: null,
        effectivenessFinalDate: null,
        procedureName: null,
        examStatus: [EXAM_STATUS.ABERTO],
      },
      showModalConfirmDeletion: false,
      showModalEffectiveExam: false,
      showModalNotEffectiveExam: false,
      showModalViewEffectivationDetails: false,
      standByTime: [],
      tableHeight: null,
      standByTimeInterval: null,
      requestedEmployee: null,
      userId: null,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
      examsList: "Exam/IndividualEffectivation/GET_LIST",
      examsCount: "Exam/IndividualEffectivation/GET_COUNT",
      loggedUserId: "Login/GET_USER_ID",
    }),

    propsRadioCustom() {
      const list = [
        { title: "Em Aberto", id: 1, color: "#ababab" },
        { title: "Efetivado", id: 2, color: "#56b0c9" },
        { title: "Não Efetivado", id: 3, color: "#ffa56a" },
      ];
      const value = this.form.examStatus;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    canRegister() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.cadastrarExame",
        this.unitHealthId,
      );
    },

    canEdit() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.editarExame",
        this.unitHealthId,
      );
    },

    canRemove() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.excluirExame",
        this.unitHealthId,
      );
    },

    canEffectiveness() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.efetivar",
        this.unitHealthId,
      );
    },

    canEffectivenessInBatch() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.efetivarEmLote",
        this.unitHealthId,
      );
    },

    canNotEffectiveness() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.naoEfetivar",
        this.unitHealthId,
      );
    },

    canOpened() {
      return this.$Permissions.global.has(
        "exame2.efetivacao.emAberto",
        this.unitHealthId,
      );
    },

    hasExamList() {
      return this.mutableExamList && this.mutableExamList.length > 0;
    },

    hasExamSelected() {
      return this.listSelected.length > 0;
    },

    examOpened() {
      const opened = this.listSelected.every((item) => {
        return item.exe_id_exames_efetivacao !== 1;
      });

      return !opened;
    },

    examEffectivated() {
      const effectivated = this.listSelected.every((item) => {
        return item.exe_id_exames_efetivacao !== 2;
      });

      return !effectivated;
    },

    examNotEffectivated() {
      const notEffectivated = this.listSelected.every((item) => {
        return item.exe_id_exames_efetivacao !== 3;
      });

      return !notEffectivated;
    },

    disabledEdit() {
      return this.listSelected.length !== 1;
    },

    disableRemove() {
      return this.listSelected.length !== 1;
    },

    disableView() {
      return this.listSelected.length !== 1;
    },

    getSpaceTable() {
      return this.tableHeight;
    },

    actionColorLine() {
      return {
        get: (item) => {
          if (item.exe_id_exames_efetivacao === 1) {
            return GetColorRowSmartTable("grey");
          }
          if (item.exe_id_exames_efetivacao === 2) {
            return GetColorRowSmartTable("blue");
          }
          if (item.exe_id_exames_efetivacao === 3) {
            return GetColorRowSmartTable("orange");
          }
        },
      };
    },

    propsModalConfirmDeletion() {
      const show = this.showModalConfirmDeletion;
      const subtitle = "A operação não poderá ser desfeita";
      const bodyText = `${this.listSelected[0]?.patientName}`;
      const title = "Excluir Exame";
      const maxlength = 250;
      const confirm = (reason) => this.deleteExam(reason);
      return { show, subtitle, bodyText, title, maxlength, confirm };
    },
  },

  created() {
    this.COLUMN_TABLE = [
      { name: "Solicitação", key: "requestDate" },
      { name: "Prontuário", key: "pac_prontuario_unico" },
      { name: "Paciente", key: "patientName", align: "left" },
      { name: "Procedimento", key: "procedimento", align: "left" },
      {
        name: "Profissional Solicitante",
        key: "request_employee",
        align: "left",
      },
      { name: "Tipo de Escala", key: "tipo_escala", align: "left" },
      { name: "Setor", key: "setor", align: "left" },
    ];
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/exam/effectivation",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Exam/IndividualEffectivation/CLEAN_FILTER");
    }

    this.$store.commit("Exam/IndividualEffectivation/RESET_STORE");
  },

  mounted() {
    const pFilter = this.$store.getters[
      "Exam/IndividualEffectivation/GET_FILTER"
    ];

    if (pFilter) {
      this.mountFilter(pFilter);
      this.reSearch();
    }

    this.$store.commit("Exam/IndividualEffectivation/CLEAN_SELECTED_DATA");
    this.$store.commit("Exam/IndividualEffectivation/CLEAN_LIST");
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.form.examStatus = item;
      }
    },
    afterSearchFilter() {
      this.getDynamicHeight();

      if (this.$refs.smartTable) {
        this.$refs.smartTable.setScrollTopZero();
        this.$refs.smartTable.cleanMultSelectedRow();
      }
      this.listSelected = [];
    },

    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.RgSearch?.$refs.listContent) {
          const footerSlot = 45;
          const searchArea = this.$refs.RgSearch.$refs.listContent.offsetHeight;

          this.tableHeight = searchArea - footerSlot - 10;
        }
      });
    },

    searchFilter(pData) {
      return this.$store.dispatch(
        "Exam/IndividualEffectivation/LIST_EXAMS_BY_FILTER",
        { variables: { ...pData } },
      );
    },

    generateFilter() {
      const variables = {
        unitHealth: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: this.form.sector || null,
        patient: this.form.patient || null,
        patientId: this.form.pes_id || null,
        hospitalizationNumber: Number(this.form.hospitalizationNumber) || null,
        bulletinNumber: Number(this.form.bulletinNumber) || null,
        examType: this.form.examType || null,
        typeScale: this.form.typeScale || null,
        exe_id_vinculos: this.form.exe_id_vinculos || null,
        exe_id_vinculos_solicitante:
          this.form.exe_id_vinculos_solicitante || null,
        procedureCode: this.form.procedureCode || null,
        requestInitialDate:
          this.$utils.date.BrazilianDateToDatabase(
            this.form.requestInitialDate,
          ) || null,
        requestFinalDate:
          this.$utils.date.BrazilianDateToDatabase(
            this.form.requestFinalDate,
          ) || null,
        effectivenessInitialDate:
          this.$utils.date.BrazilianDateToDatabase(
            this.form.effectivenessInitialDate,
          ) || null,
        effectivenessFinalDate:
          this.$utils.date.BrazilianDateToDatabase(
            this.form.effectivenessFinalDate,
          ) || null,
        examStatus:
          this.form.examStatus.length > 0 ? this.form.examStatus : null,
      };

      this.$store.commit("Exam/IndividualEffectivation/SET_FILTER", this.form);

      return variables;
    },

    mountFilter(pFilter) {
      this.form = {
        ...pFilter,
      };

      if (this.$refs.Procedure && pFilter.procedureCode) {
        this.$refs.Procedure.forceSelection({
          NO_PROCEDIMENTO: pFilter.procedureName,
          CO_PROCEDIMENTO: pFilter.procedureCode,
        });
      }

      if (this.$refs.requestedEmployee && pFilter.exe_id_vinculos_solicitante) {
        this.$refs.requestedEmployee.forceSelection({
          vin_id: pFilter.exe_id_vinculos_solicitante,
          pes_nome: pFilter.requestName,
        });
      }

      this.$emit("reSearch");
    },

    async reSearch() {
      this.cleanSelectedRow();
      await this.$refs.RgSearch.submitForm(true);
    },

    registerExam() {
      this.$router.push({
        name: "exam.effectivation.details",
      });
    },

    editExam() {
      if (this.examEffectivated) {
        this.$toaster.warning("Não é possível editar um exame efetivado");
        return;
      } else if (this.listSelected[0].editavel === false) {
        this.$toaster.warning("Este exame não pode ser editado");
        return;
      }

      const exam = this.listSelected[0];

      this.$store.commit(
        "Exam/IndividualEffectivation/SET_SELECTED_DATA",
        exam,
      );

      this.$router.push({
        name: "exam.effectivation.edit",
      });
    },

    deleteExam(pReason) {
      try {
        this.$store.dispatch(
          "Exam/IndividualEffectivation/REMOVE_EXAMS_BY_ID",
          {
            examId: this.listSelected[0].exe_id,
            reason: pReason,
          },
        );

        this.reSearch();
        this.cleanSelectedRow();
      } catch (pErr) {
        this.$toaster.error(`Erro ao tentar excluir exame. ${pErr}`);
      }
    },

    viewExam() {
      const exam = this.listSelected[0];

      this.$store.commit(
        "Exam/IndividualEffectivation/SET_SELECTED_DATA",
        exam,
      );

      this.showModalViewEffectivationDetails = true;
    },

    effectuate() {
      const effectivated = this.listSelected.every((item) => {
        return item.exe_id_exames_efetivacao !== 2;
      });

      const equalsProcedure = this.listSelected.every((item) => {
        return this.listSelected[0].cod_procedimento === item.cod_procedimento;
      });

      const equalsPatient = this.listSelected.every((item) => {
        return this.listSelected[0]?.patient?.pac_id === item?.patient?.pac_id;
      });

      if (this.listSelected.length > 1 && !this.canEffectivenessInBatch) {
        this.$toaster.warning("Usuário sem a permissão efetivar em lote");
      } else if (!effectivated) {
        this.$toaster.warning("Um dos exames selecionados já foi efetivado");
      } else if (!equalsProcedure && !equalsPatient) {
        this.$toaster.warning(
          "Não é possível efetivar em lote exames e pacientes diferentes",
        );
      } else {
        this.openModalEffectivateExam();
      }
    },

    notEffectuate() {
      const notEffectivated = this.listSelected.every((item) => {
        return item.exe_id_exames_efetivacao !== 3;
      });

      const equalsProcedure = this.listSelected.every((item) => {
        return this.listSelected[0].cod_procedimento === item.cod_procedimento;
      });

      const equalsPatient = this.listSelected.every((item) => {
        return this.listSelected[0]?.patient?.pac_id === item?.patient?.pac_id;
      });

      if (!notEffectivated) {
        this.$toaster.warning(
          "Um dos exames selecionados já está como não efetivado",
        );
      } else if (!equalsProcedure && !equalsPatient) {
        this.$toaster.warning(
          "Não é possível não efetivar exames e pacientes diferentes",
        );
      } else {
        this.openModalNotEffectivateExam();
      }
    },

    async effectuateOpen() {
      try {
        this.$loader.start();

        const OPENED = 1;
        const data = [];
        const exams = this.listSelected;

        const preparedExamInfo = PrepareExamToSave(
          exams,
          OPENED,
          null,
          null,
          true,
          this.loggedUserId,
        );

        preparedExamInfo.forEach((item) => {
          if (item.examId) {
            data.push(item);
          }
        });

        await this.$store.dispatch(
          "Exam/IndividualEffectivation/EFFECTTIVATE_EXAMS_BY_ID",
          data,
        );

        this.$toaster.success("Exame em aberto com sucesso");
        this.reSearch();
      } catch (pError) {
        this.$toaster.error(pError, "Erro ao deixar exame em aberto");
      } finally {
        this.$loader.finish();
      }
    },

    selectLines(pValue) {
      this.listSelected = pValue;
    },

    getCountValue(pValue) {
      this.totalItems = Number(pValue) || 0;
    },

    getExamStatus() {
      this.$refs.RgSearch.submitForm(true);
    },

    selectingDocument(pDocument) {
      if (pDocument.source) {
        this.form.pes_id = pDocument.source.pes_id;
      }
    },

    selectingProcedure(pProcedure) {
      if (pProcedure && pProcedure.source) {
        const { CO_PROCEDIMENTO, NO_PROCEDIMENTO } = pProcedure.source;
        this.form.procedureCode = CO_PROCEDIMENTO;
        this.form.procedureName = NO_PROCEDIMENTO;
      } else {
        this.form.procedureCode = null;
        this.form.procedureName = null;
      }
    },

    selectingRequestedEmployee(pEmployee) {
      if (pEmployee && pEmployee.source) {
        const { vin_id, pes_nome } = pEmployee.source;
        this.form.exe_id_vinculos_solicitante = vin_id;
        this.form.requestName = pes_nome;
      } else {
        this.form.exe_id_vinculos_solicitante = null;
        this.form.requestName = null;
      }
    },

    selectingSector() {
      this.form.exe_id_vinculos_solicitante = null;
      this.form.requestName = null;
      this.requestedEmployee = null;
    },

    openModalConfirmDeletion() {
      if (this.examEffectivated) {
        this.$toaster.warning("Não é possível excluir um exame efetivado");
        return;
      } else if (this.listSelected[0].editavel === false) {
        this.$toaster.warning("Este exame não pode ser excluído");
        return;
      }

      this.showModalConfirmDeletion = true;
    },

    closeModalConfirmDeletion() {
      this.showModalConfirmDeletion = false;
    },

    closeModalViewEffectivationDetails() {
      this.showModalViewEffectivationDetails = false;
    },

    openModalEffectivateExam() {
      this.showModalEffectiveExam = true;
    },

    closeModalEffectivateExam() {
      this.showModalEffectiveExam = false;
    },

    openModalNotEffectivateExam() {
      this.showModalNotEffectiveExam = true;
    },

    closeModalNotEffectivateExam() {
      this.showModalNotEffectiveExam = false;
    },

    actionCleanSelection() {
      this.listSelected = [];
    },

    cleanSelectedRow() {
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanMultSelectedRow();
      }
      this.listSelected = [];
    },

    cleanForm() {
      this.totalItems = 0;
      this.mutableExamList = [];
      this.requestedEmployee = null;
      this.form = {
        uns_id: this.$store.getters["Login/GET_UNIT_HEALTH_ID"],
        sector: null,
        patient: null,
        pes_id: null,
        hospitalizationNumber: null,
        bulletinNumber: null,
        examType: null,
        exe_id_vinculos_solicitante: null,
        requestName: null,
        procedureCode: null,
        requestInitialDate: moment().format("DD/MM/YYYY"),
        requestFinalDate: moment().format("DD/MM/YYYY"),
        exe_id_vinculos: null,
        employeeName: null,
        effectivenessInitialDate: null,
        effectivenessFinalDate: null,
        procedureName: null,
        examStatus: [EXAM_STATUS.ABERTO],
      };
      if (this.$refs.status) {
        this.$refs.status.clearRadio();
      }

      this.$store.commit("Exam/IndividualEffectivation/RESET_STORE");
      this.$store.commit("Exam/IndividualEffectivation/CLEAN_FILTER");
    },
  },
};
</script>
