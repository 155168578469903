<template lang="html">
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 7.4804C13 7.11349 12.9893 7.1001 12.6277 7.1001C10.6084 7.1001 8.589 7.1001 6.56696 7.1001C4.48599 7.1001 2.40503 7.1001 0.321384 7.1001C0.0294602 7.1001 0 7.12956 0 7.41077C0 10.3996 0 13.3885 0 16.3747C0 16.8488 0.182118 17.0818 0.624021 17.1916H12.376C12.8125 17.0844 12.9946 16.8488 13 16.3908C13 16.364 13 16.3345 13 16.3078C13 13.3644 13 10.4211 13 7.47772V7.4804ZM8.6506 12.7806C8.6506 12.877 8.62114 12.9011 8.53008 12.8984C8.32921 12.8904 8.12835 12.8984 7.9248 12.8984C7.74001 12.8984 7.55521 12.9091 7.37042 12.8957C7.22847 12.885 7.20705 12.9413 7.20972 13.0645C7.21776 13.4394 7.20972 13.8144 7.21508 14.1893C7.21508 14.3098 7.18026 14.3393 7.0651 14.3366C6.6848 14.3286 6.30449 14.3312 5.92419 14.3366C5.81438 14.3366 5.78224 14.3098 5.78224 14.1973C5.79028 13.817 5.78224 13.4367 5.7876 13.0564C5.7876 12.9359 5.76617 12.8931 5.63494 12.8957C5.24928 12.9064 4.86362 12.8957 4.47796 12.9011C4.37618 12.9011 4.34672 12.877 4.3494 12.7725C4.35744 12.3815 4.35744 11.9905 4.3494 11.5968C4.3494 11.4816 4.38422 11.4575 4.49135 11.4602C4.87701 11.4656 5.26267 11.4602 5.64833 11.4656C5.75814 11.4656 5.79028 11.4361 5.79028 11.3263C5.78224 10.946 5.79563 10.5657 5.78492 10.1854C5.78224 10.0515 5.82509 10.0301 5.94561 10.0327C6.32056 10.0408 6.69551 10.0408 7.07046 10.0327C7.18562 10.0327 7.22311 10.0542 7.22044 10.1774C7.2124 10.5577 7.22044 10.938 7.21508 11.3183C7.21508 11.4388 7.2499 11.4682 7.36506 11.4656C7.75072 11.4575 8.13638 11.4656 8.52204 11.4602C8.62382 11.4602 8.65595 11.4843 8.65328 11.5888C8.64792 11.9851 8.64792 12.3842 8.65328 12.7806H8.6506Z"
      fill="white"
    />
    <path
      d="M6.48393 6.4493C4.39761 6.4493 2.31129 6.4493 0.22497 6.45198C0.0776691 6.45198 0.0401742 6.42252 0.0830255 6.2779C0.125877 6.13328 0.198188 6.01276 0.302638 5.90831C0.811497 5.39677 1.32303 4.88791 1.83189 4.37637C1.88813 4.32013 1.94438 4.29871 2.02204 4.29871C5.00824 4.29871 7.99712 4.29871 10.9833 4.29871C11.061 4.29871 11.1172 4.32013 11.1735 4.37637C11.6823 4.88791 12.1912 5.39945 12.7027 5.90831C12.8286 6.03418 12.9089 6.18416 12.9357 6.36092C12.9464 6.4252 12.9304 6.45466 12.8607 6.4493C12.8206 6.44663 12.7831 6.4493 12.7429 6.4493C10.6566 6.4493 8.57025 6.4493 6.48393 6.4493V6.4493Z"
      fill="white"
    />
    <path
      d="M6.5157 0.00280762C7.1799 0.00280762 7.84677 0.00280762 8.51096 0.00280762C8.61274 0.00280762 8.65291 0.0188768 8.65291 0.136718C8.64755 1.24282 8.64755 2.35159 8.65291 3.45769C8.65291 3.55411 8.63684 3.5916 8.52703 3.5916C7.1799 3.58624 5.83276 3.58892 4.48295 3.5916C4.38118 3.5916 4.34904 3.57017 4.35172 3.46305C4.35439 2.35159 4.35707 1.23746 4.35172 0.126005C4.35172 0.0108422 4.39724 0.00548582 4.4883 0.00548582C5.16589 0.00816402 5.8408 0.00548582 6.51838 0.00548582L6.5157 0.00280762Z"
      fill="white"
    />
    <path
      d="M3.63888 1.79987C3.63888 2.34355 3.6362 2.88455 3.63888 3.42822C3.63888 3.54606 3.62013 3.59427 3.48622 3.59159C3.08449 3.58088 2.68008 3.59159 2.27835 3.58624C1.77485 3.58356 1.49096 3.29699 1.48828 2.79349C1.48828 2.12929 1.48828 1.46242 1.48828 0.798227C1.49364 0.284012 1.78021 0.00547848 2.29442 0.00280027C2.69615 0.00280027 3.10056 0.00547848 3.50229 0.00012207C3.6121 0.00012207 3.64156 0.0295823 3.64156 0.139389C3.6362 0.693777 3.64156 1.24549 3.64156 1.79987H3.63888Z"
      fill="white"
    />
    <path
      d="M9.36205 1.79186C9.36205 1.24283 9.36473 0.696475 9.35938 0.147443C9.35938 0.03228 9.38616 -0.00253662 9.50668 0.000141581C9.91912 0.00817619 10.3342 0.000141581 10.7494 0.00281978C11.2127 0.00549799 11.5073 0.292066 11.51 0.752717C11.5153 1.44637 11.5153 2.14003 11.51 2.83368C11.5073 3.28094 11.2288 3.57019 10.7815 3.58358C10.345 3.59697 9.90841 3.58358 9.47186 3.58893C9.35937 3.58893 9.36205 3.53269 9.36205 3.45235C9.36205 2.89796 9.36205 2.34625 9.36205 1.79186V1.79186Z"
      fill="white"
    />
    <circle
      cx="14"
      cy="16"
      r="5.5"
      :fill="disabled ? '#ccc' : '#1E88A9'"
      :stroke="disabled ? '#ccc' : '#1E88A9'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9999 21C16.7612 21 18.9999 18.7614 18.9999 15.9999C18.9999 13.2387 16.7612 11 13.9999 11C11.2388 11 9 13.2388 9 15.9999C9 18.7614 11.2388 21 13.9999 21Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0022 12.8181C13.7093 12.8181 13.4719 13.0555 13.4719 13.3484V15.4696H11.3506C11.0577 15.4696 10.8203 15.707 10.8203 15.9999C10.8203 16.2928 11.0577 16.5302 11.3506 16.5302H13.4719V18.6514C13.4719 18.9443 13.7093 19.1817 14.0022 19.1817C14.2951 19.1817 14.5325 18.9443 14.5325 18.6514V16.5302H16.6536C16.9465 16.5302 17.1839 16.2928 17.1839 15.9999C17.1839 15.707 16.9465 15.4696 16.6536 15.4696H14.5325V13.3484C14.5325 13.0555 14.2951 12.8181 14.0022 12.8181Z"
      :fill="disabled ? '#ccc' : '#1E88A9'"
    />
  </svg>
</template>

<script>
export default {
  name: "ProductType",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
