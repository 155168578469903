import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    SearchExternalListBdsia {
      list
      type
      available
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });
  return data.SearchExternalListBdsia;
};
