<template>
  <div class="exam-schedule-excluded-report">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-eser"
          v-model="form.uns_id"
          :rules="{ required: true }"
          permission="exame2.relatoriosESUSng.Exibir"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectSector
          id="set-id-eser"
          v-model="form.set_id"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unit-health="form.uns_id"
          label="Setor"
          class="inputitem"
          @change="getSector"
        />
      </div>

      <div class="selectinput">
        <RgSuggestCbo
          id="ocp-nome-eser"
          v-model="form.ocp_nome"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :sectorId="Number(form.set_id)"
          :employeeId="professionalBond"
          label="Ocupação"
          class="inputitem"
          @selected="selectingCbo"
        />
      </div>

      <div class="selectinput">
        <RgSuggestEmployee
          id="pes-nome-eser"
          v-model="form.pes_nome"
          :disabled="!hasUnitHealth"
          :class="{ disable: !hasUnitHealth }"
          :unitHealthId="form.uns_id"
          :ocuppationId="form.ocp_id"
          label="Profissional"
          class="inputitem"
          @selected="selectingProfessional"
        />
      </div>

      <div class="selectinput">
        <RgSelectProceduresFilter
          id="stp-id-eser"
          ref="comboProcedures"
          v-model="form.stp_id"
          class="inputitem"
          label="Procedimento"
          :sector-id="Number(form.set_id)"
          :disabled="!form.set_id"
          default-text="Todos"
          @change="getSubprocedureValue"
        />
      </div>

      <div class="selectinput">
        <RgSuggestUser
          id="usu-nome-eser"
          ref="user"
          v-model="form.usu_nome"
          class="inputitem"
          label="Usuário"
          @selected="selectingUser"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período</span>
      </div>

      <div class="selectinput period">
        <RgInputDate
          id="initial-date-eser"
          ref="initialDate"
          v-model="form.initialDate"
          label="Data Inicial"
          :rules="{ required: true, fn: validateInitialDate }"
          class="inputitem"
        />

        <RgInputDate
          id="final-date-eser"
          ref="finalDate"
          v-model="form.finalDate"
          label="Data Final"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          class="inputitem"
        />
      </div>

      <div class="filter-title">
        <span class="title">Ordenação</span>
      </div>

      <div class="selectinput">
        <RgComboboxOrderBy
          id="orderby-eser"
          ref="order"
          v-model="form.orderby"
          :data="ORDERS"
          :rules="{ required: true }"
          label="Ordenar por"
          class="inputitem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgInputDate,
  RgSelectSector,
  RgSuggestCbo,
  RgSuggestEmployee,
  RgSuggestUser,
  RgComboboxOrderBy,
} from "~tokio/primitive";

import RgSelectProceduresFilter from "$exam/submodules/schedule/component/select/rg-select-procedures-filter/RgSelectProceduresFilter";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  uns_nome: null,
  set_id: null,
  set_nome: null,
  ocp_id: null,
  ocp_nome: null,
  usu_id: null,
  usu_nome: null,
  stp_id: null,
  stp_novo_nome_procedimento: null,
  pes_id: null,
  pes_nome: null,
  initialDate: moment().add(-90, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  orderby: 1,
  orderByLabel: "Data e Hora",
};

export default {
  name: "ExamScheduleExcludedReport",
  components: {
    RgSelectUnithealth,
    RgInputDate,
    RgSelectSector,
    RgSelectProceduresFilter,
    RgSuggestCbo,
    RgSuggestEmployee,
    RgSuggestUser,
    RgComboboxOrderBy,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Agendamentos Excluídos",
      professionalBond: null,
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },

  computed: {
    getNameSmartTable() {
      return "ExamScheduleExcludedReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-2 { white-space: normal !important; }" +
        ".report-column-3 { white-space: normal !important; }" +
        ".report-column-4 { white-space: normal !important; }" +
        ".report-column-5 { white-space: normal !important; }" +
        ".report-column-6 { white-space: normal !important; }" +
        ".report-column-8 { white-space: normal !important; }" +
        ".report-column-9 { white-space: normal !important; }"
      );
    },
    hasUnitHealth() {
      return this.form.uns_id && Number(this.form.uns_id) > 0;
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.set_id = null;
        this.form.set_nome = null;
      }
    },
    "form.set_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
        this.form.pes_id = null;
        this.form.pes_nome = null;
      }
    },
    "form.ocp_id"(pValue) {
      if (!pValue && !this.form.pes_id) {
        this.professionalBond = null;
      }
    },
    "form.pes_id"(pValue) {
      if (!pValue && !this.form.ocp_id) {
        this.professionalBond = null;
      }
    },
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Data/Hora Exame", key: "DATA_HORA_EXAME" },
      { name: "Paciente", key: "PACIENTE", align: "left" },
      { name: "CNS", key: "CNS" },
      { name: "Prontuário da Unidade", key: "PRONTUARIO_UNIDADE" },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      { name: "Profissional de Saúde", key: "PROFISSIONAL", align: "left" },
      { name: "Ocupação", key: "OCUPACAO", align: "left" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Agendado por", key: "AGENDADO_POR", align: "left" },
      { name: "Data/Hora Exclusão", key: "DATA_HORA_EXCLUSAO" },
      { name: "Excluído por", key: "EXCLUIDO_POR", align: "left" },
      { name: "Motivo", key: "MOTIVO", align: "left" },
    ];

    this.ORDERS = [
      { value: 1, text: "Data e Hora" },
      { value: 2, text: "Setor" },
      { value: 3, text: "Profissional" },
      { value: 4, text: "Paciente" },
    ];
  },

  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.form.uns_nome = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
  },

  methods: {
    buildFilter() {
      return { ...this.form, columnsToPrint: this.columnsToPrint };
    },

    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Exam/Report/GET_EXAM_SCHEDULE_EXCLUDED_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    getSector(pValue) {
      this.form.set_nome = pValue ? pValue.label : null;
    },

    getSubprocedureValue(pValue) {
      if (pValue) {
        this.form.stp_novo_nome_procedimento = pValue.label;
      } else {
        this.form.stp_novo_nome_procedimento = null;
      }
    },

    selectingProfessional(pValue) {
      if (Object.keys(pValue.source).length > 0) {
        const source = pValue.source;
        const { pes_id, pes_nome } = source;
        this.form.pes_id = pes_id;
        this.form.pes_nome = pes_nome;
        this.professionalBond = source.employeeRelationship.vin_id_funcionarios;
      } else {
        this.form.pes_id = null;
        this.form.pes_nome = null;
        this.professionalBond = null;
      }
    },

    selectingCbo(pValue) {
      const hasSuggestion = Object.keys(pValue.source).length > 0;
      if (hasSuggestion) {
        this.$loader.start();
        this.form.ocp_id = pValue.source.ocp_id;
        this.form.ocp_nome = pValue.source.ocp_nome;
        this.$loader.finish();
      } else {
        this.form.ocp_id = null;
        this.form.ocp_nome = null;
      }
    },

    selectingUser(pValue) {
      if (pValue && pValue.source) {
        const { usu_id, usu_nome } = pValue.source;
        this.form.usu_id = usu_id;
        this.form.usu_nome = usu_nome;
      } else {
        this.form.usu_id = null;
        this.form.usu_nome = null;
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      this.professionalBond = null;

      if (this.$refs.order) {
        this.$refs.order.cleanValidate();
      }
    },
  },
};
</script>
