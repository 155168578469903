<template lang="html">
  <svg
    v-if="!disabled"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9434 6.14038C8.16504 6.14038 6.89648 8.3024 6.89648 10.7353H7.39665H11.2875H14.4522H14.992C14.9911 8.26189 13.7217 6.14038 10.9434 6.14038Z"
      fill="url(#paint0_linear_17050_174)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.51659 13.5823H6.89513V15.2388V15.2506C6.89513 16.4996 6.37641 17.3738 5.33643 17.8743C5.9319 18.0397 6.55775 18.1291 7.20299 18.1325C7.2148 18.1325 7.22661 18.1325 7.23842 18.1325H11.2853C13.388 18.1477 15.4722 18.1485 17.575 18.1325C15.8788 18.1418 14.9906 16.6869 14.9906 15.1333V13.5823H13.9093H11.2853H9.51659Z"
      fill="url(#paint1_linear_17050_174)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.32263 10.8895C4.83883 0.597547 17.0402 1.93088 17.3532 9.90807C17.4249 11.7384 16.5949 16.4009 18.9372 16.5367C19.342 16.5604 20.1458 16.4304 20.2935 15.984C26.2938 -2.13747 1.61093 -6.16362 0.0286048 10.7427C-0.0363413 13.6271 1.71298 16.6203 4.30155 17.9224C4.30155 15.5773 4.30323 13.2346 4.32263 10.8895Z"
      fill="url(#paint2_linear_17050_174)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2856 21.9925C11.2856 22.0094 11.3194 21.9925 11.3363 21.9925C13.9729 21.9748 17.485 20.4718 18.7654 18.0507L11.2848 18.0744H7.23875C6.58086 18.0744 5.9432 17.9849 5.33676 17.8161C5.06264 17.7402 4.79526 17.6474 4.53463 17.541C4.36932 17.4727 4.20822 17.3993 4.04965 17.3191C1.66604 16.1199 0.0263672 13.6187 0.0263672 10.7275C0.0263672 16.9495 5.06685 21.9925 11.2856 21.9925Z"
      fill="url(#paint3_linear_17050_174)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17050_174"
        x1="10.9442"
        y1="6.14038"
        x2="0.945329"
        y2="35.2537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0571D3" />
        <stop offset="1" stop-color="#014AB5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_17050_174"
        x1="10.8702"
        y1="19.0569"
        x2="13.5843"
        y2="13.1004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0571D3" />
        <stop offset="1" stop-color="#004AB5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_17050_174"
        x1="12.6779"
        y1="-2.02523"
        x2="1.36577"
        y2="26.494"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.0001" stop-color="#0978DD" stop-opacity="0.79" />
        <stop offset="0.369792" stop-color="#0466CB" />
        <stop offset="1" stop-color="#014AB5" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_17050_174"
        x1="15.5607"
        y1="21.783"
        x2="2.74762"
        y2="11.7407"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.119792" stop-color="#004AB5" />
        <stop offset="0.708333" stop-color="#0571D3" />
        <stop offset="0.994792" stop-color="#57AFFF" />
      </linearGradient>
    </defs>
  </svg>
  <svg
    v-else-if="disabled"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.917 6.14038C8.13868 6.14038 6.87012 8.30241 6.87012 10.7353H7.37029H11.2612H14.4258H14.9656C14.9648 8.2619 13.6954 6.14038 10.917 6.14038Z"
      fill="url(#paint0_linear_17124_11)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.49023 13.5825H6.86877V15.2391V15.2509C6.86877 16.4998 6.35004 17.3741 5.31006 17.8745C5.90554 18.0399 6.53139 18.1294 7.17663 18.1327C7.18844 18.1327 7.20025 18.1327 7.21206 18.1327H11.259C13.3617 18.1479 15.4459 18.1488 17.5486 18.1327C15.8524 18.142 14.9643 16.6872 14.9643 15.1336V13.5825H13.883H11.259H9.49023Z"
      fill="url(#paint1_linear_17124_11)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.29628 10.8895C4.81248 0.597549 17.0139 1.93088 17.3269 9.90811C17.3986 11.7385 16.5686 16.4009 18.9109 16.5368C19.3157 16.5604 20.1195 16.4305 20.2672 15.9841C26.2675 -2.13747 1.58456 -6.16364 0.00223759 10.7427C-0.0627087 13.6271 1.68662 16.6203 4.27519 17.9225C4.27519 15.5773 4.27688 13.2347 4.29628 10.8895Z"
      fill="url(#paint2_linear_17124_11)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2593 21.9925C11.2593 22.0094 11.2931 21.9925 11.3099 21.9925C13.9466 21.9748 17.4587 20.4718 18.7391 18.0508L11.2585 18.0744H7.21241C6.55451 18.0744 5.91686 17.9849 5.31041 17.8162C5.03629 17.7402 4.76891 17.6474 4.50828 17.541C4.34296 17.4727 4.18186 17.3993 4.02329 17.3191C1.63968 16.1199 0 13.6187 0 10.7275C0 16.9495 5.0405 21.9925 11.2593 21.9925Z"
      fill="url(#paint3_linear_17124_11)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17124_11"
        x1="10.9179"
        y1="6.14038"
        x2="0.918943"
        y2="35.2538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_17124_11"
        x1="10.8439"
        y1="19.0572"
        x2="13.5579"
        y2="13.1007"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_17124_11"
        x1="12.6516"
        y1="-2.02524"
        x2="1.3394"
        y2="26.4941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.0001" stop-color="white" />
        <stop offset="0.369792" stop-color="white" />
        <stop offset="1" stop-color="#CACACA" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_17124_11"
        x1="15.5344"
        y1="21.7831"
        x2="2.72126"
        y2="11.7407"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.119792" stop-color="#E2E2E2" />
        <stop offset="0.708333" stop-color="#F2F3F3" />
        <stop offset="0.994792" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "BasicAttention",
  props: {
    disabled: Boolean,
  },
};
</script>
