import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
import { OpenMenuModule } from "~common/utils/app/menu";

export default {
  path:
    "/appointment/schedule/queue/external-schedule-form/new-patient/patient-care-history",
  name:
    "appointment.schedule.queue.external-schedule-form.new-patient.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Consulta", link: OpenMenuModule("appointment") },
      { label: "Regulação", link: OpenMenuModule("appointment") },
      { label: "Fila de Regulação", link: "/appointment/schedule/queue" },
      {
        label: "Agendar Externamente",
        link: "/appointment/schedule/queue/external-schedule-form",
      },
      {
        label: "Editar Paciente",
        link: "/appointment/schedule/queue/external-schedule-form/new-patient",
      },
      {
        label: "Histórico de Atendimentos",
      },
    ],
  },
};
