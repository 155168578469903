<script>
import { RgComboboxMixin } from "~tokio/primitive/selection/mixin";
export default {
  name: "RgComboboxYear",
  mixins: [RgComboboxMixin],
  data: () => {
    return {
      mData: [
        { value: "2015", text: "2015" },
        { value: "2016", text: "2016" },
        { value: "2017", text: "2017" },
        { value: "2018", text: "2018" },
        { value: "2019", text: "2019" },
        { value: "2020", text: "2020" },
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
      ],
      // mData: [],
    };
  },
  // CÓDIGO PARA CRIAÇÃO DO ARRAY DE ANOS DINÂMICAMENTE
  // COMENTADO POR HORAM PARA CONFIRMAÇÃO COM REGRA
  async mounted() {
    // let actualYear = new Date().getFullYear();
    // while (actualYear >= 2015) {
    //   this.mData.push({
    //     value: actualYear.toString(),
    //     text: actualYear.toString(),
    //   });
    //   actualYear = actualYear - 1;
    // }
  },
};
</script>
