import mutations from "./mutations/index";
import Report from "$basic-attention/submodules/report/store";

export default {
  namespaced: true,
  mutations,
  modules: {
    Report,
  },
};
