<template>
  <Modulebox
    title="Atender Transferência de Produtos e Medicamentos"
    class="pharmacy-central-attend-transfer"
  >
    <main class="pharmacy-central-attend-transfer-body">
      <form ref="element">
        <FormBase title="Dados do Atendimento" class="form-base">
          <div class="group-select-attend-transfer">
            <RgSelectPharmacy
              v-model="oldData.str_id_farmacia_destino"
              label="Farmácia de Destino (Atendente)"
              :class="{ disable: true }"
              :unitHealthId="Number(oldData.uns_destino)"
              :typePharmacy="1"
              :disabled="true"
              :rules="{ required: true }"
            />
            <RgSelectPharmacy
              v-model="oldData.str_id_farmacia_origem"
              label="Farmácia de Origem (Solicitante)"
              :class="{ disable: true }"
              :unitHealthId="Number(oldData.uns_origem)"
              :typePharmacy="1"
              :disabled="true"
              :rules="{ required: true }"
            />
            <RgInput
              v-model="guideShipping"
              label="Guia de Remessa"
              placeholder="Número da Guia"
              :class="{ disable: true }"
              :disabled="true"
              :rules="{ required: true }"
            />
            <RgInput
              v-model="oldData.str_numero"
              label="Número"
              placeholder="Número da Solicitação"
              :class="{ disable: true }"
              :disabled="true"
              :rules="{ required: true }"
            />
            <!-- <div class="select-date-attend-transfer"> -->
            <RgInputDate
              id="request-date"
              ref="requestDate"
              v-model="oldData.str_data_pedido"
              :class="{ disable: true }"
              label="Data da Solicitação"
              popUp="bottom"
              :rules="{ required: true }"
              :disabled="true"
            />
            <!-- </div> -->
          </div>
        </FormBase>
        <FormBase title="Produtos e Medicamentos" class="register-form">
          <RgValidatorForm ref="validator">
            <div class="tooltips">
              <Tooltip class="tooltip-print">
                <IconInfoHelper slot="icon" />
                <div slot="content" class="content">
                  <div class="title">
                    <span>
                      Lote com estoque insuficiente para atendimento: para
                      atender a quantidade restante, selecione o pedido e
                      escolha outro lote.
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
            <div class="products-data">
              <RgSelectProduct
                ref="product"
                v-model="formProduct.productNumber"
                :typeMovimentation="1"
                :perClient="true"
                :disabled="true"
                :class="{ disable: true }"
                :rules="{ required: true }"
                @change="selectedProduct"
              />
              <RgSelectTypePresentation
                ref="typePresentation"
                v-model="formProduct.productPresentation"
                label="Apresentação"
                :rules="{ required: true }"
                :disabled="true"
                :class="{ disable: true }"
              />
              <RgSelectLotExpiration
                ref="typePresentation"
                v-model="formProduct.lotAndExpiration"
                :pharmacyId="Number(pharmacyId)"
                :productId="Number(formProduct.productNumber)"
                :rules="{ required: true }"
                :execCallback="formProduct.lotAndExpiration"
                :callback="getValidation"
                :class="{ disable: !this.productSelect }"
                :disabled="disabledByModal || !this.productSelect"
              />
              <RgInputDate
                id="request-date"
                ref="requestDate"
                v-model="formProduct.dateAttend"
                :class="{ disable: true }"
                label="Data de Atendimento"
                :rules="{ required: true }"
                :disabled="true"
              />
              <!-- <div class="group-qtd-submit-attend-transfer"> -->
              <RgInput
                v-model="formProduct.stock"
                label="Estoque"
                placeholder="0000000"
                :class="{ disable: true }"
                :disabled="true"
                :rules="{ required: true }"
              />
              <RgInputNumber
                v-model="formProduct.quantity"
                label="Quantidade"
                placeholder="000000000"
                :rules="{ required: true, fn: validateAmount }"
                :class="{ disable: !this.productSelect }"
                :disabled="disabledByModal || !this.productSelect"
              />
              <div class="actions">
                <SmallButton
                  id="disable-product"
                  backgroundColor="#1E88A9"
                  title="Indisponível"
                  :disabled="
                    !this.productSelect ||
                    disabledByModal ||
                    asPartiallyServiced
                  "
                  @click="modalConfirmReturn = !modalConfirmReturn"
                >
                  <div slot="icon" class="icon icon-unavailable">
                    <IconAdd />
                  </div>
                </SmallButton>
                <RgCleanButton
                  id="edit-npr"
                  ref="editButton"
                  small
                  class="edit"
                  :disabled="!formProduct.lotAndExpiration || disabledByModal"
                  @click="cleanFields"
                />
                <RgAddButton
                  id="edit-npr"
                  ref="editButton"
                  small
                  class="edit"
                  title="Adicionar Produto/Medicamento"
                  :disabled="!formProduct.lotAndExpiration || disabledByModal"
                  @click="itemAttend"
                />
                <!-- </div> -->
              </div>
            </div>
          </RgValidatorForm>
          <hr class="separator" />
          <div class="group-table-attend-transfer">
            <div>
              <RgTitle
                title="Produtos e Medicamentos solicitados"
                backgroundColor="transparent"
                fontColor="#707070"
                fontSize="14px"
                class="title-table-attend-transfer"
              />
              <div class="scroll-table">
                <SmartTable
                  ref="smartTable"
                  name="PharmacyCentral"
                  :columns="collumnsRequestProduct"
                  :body="mutableList.listRequest"
                  :total="Number(mutableList.listRequest.length)"
                  :showPagination="false"
                  :removeBtnColumns="true"
                  :dontSelect="!statusOrder.bol"
                  toggle-selected
                  :initial-columns="5"
                  class="smart-table-attend-transfer"
                  @getLine="selectLine"
                />
              </div>
            </div>
            <div>
              <RgTitle
                title="Produtos e Medicamentos para envio"
                backgroundColor="transparent"
                fontColor="#707070"
                fontSize="14px"
                class="title-table-attend-transfer"
              />
              <ul class="list-submit-attend-transfer scroll-table">
                <li
                  v-for="(el, idx) of mutableList.listSubmit"
                  :key="'sub' + idx"
                  class="item-list"
                >
                  <span class="ellipsis">{{ el.mpd_codigo }}</span>
                  <span class="ellipsis">{{
                    el.mpd_novo_principio_ativo
                  }}</span>
                  <span class="ellipsis">{{ el.mtu_novo_nome }}</span>
                  <span v-if="el.trs_quantidade" class="ellipsis">{{
                    el.trs_quantidade
                  }}</span>
                  <span v-else class="ellipsis">0</span>
                  <span class="ellipsis">{{ el.std_nome }}</span>
                </li>
                <li
                  v-if="mutableList.listSubmit.length === 0"
                  class="default-item-list"
                >
                  Nenhum item para envio
                </li>
              </ul>
            </div>
          </div>
        </FormBase>
      </form>
    </main>
    <footer class="group-bottom-footer-attend-transfer">
      <RgRadioCustom
        id="status"
        label="Situação:"
        v-bind="propsRadio"
        :value="statusOrder.int"
        class="situation"
        disabled
      />

      <div class="actions">
        <LargeButton
          label="Observações"
          backgroundColor="#1e88a9"
          :disabled="disabledByModal"
          @click="
            () => {
              showModalObservation = true;
            }
          "
        >
          <IconObservation slot="icon" />
        </LargeButton>
        <LargeButton
          label="Fechar Pedido"
          backgroundColor="#ff7f2a"
          :disabled="!statusOrder.bol || isWaiting || disabledByModal"
          @click="closeRequest"
        >
          <IconCheck slot="icon" />
        </LargeButton>
        <RgSaveButton
          id="save-npr"
          ref="btnInsertPreRegistration"
          label="Voltar"
          medium
          :disabled="disabledByModal"
          class="save"
          @click="exitTransfer"
        />
      </div>
    </footer>
    <ModalConfirmDefault
      id="modal-confirm-default"
      :show="modalConfirmReturn"
      subtitle="Deseja não atender a solicitação por motivo de Estoque Indisponível?"
      title="Indisponibilidade de Estoque"
      :message="productSelected.message"
      yes-label="Salvar"
      no-label="Cancelar"
      class="modal-confirm-return"
      buttonsRight
      @getYes="unavailableItem"
      @getOut="closeModalUnavailableOrder"
      @close="closeModalUnavailableOrder"
    />
    <ModalObservationTransferRequest
      title="Incluir observações sobre o Atendimento de Solicitação"
      :show="showModalObservation"
      :request-data="listObservation"
      @close="
        () => {
          showModalObservation = false;
        }
      "
    />
  </Modulebox>
</template>

<script>
import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  RgAddButton,
  RgCleanButton,
  RgInput,
  RgInputDate,
  SmallButton,
  RgRadioCustom,
  RgSaveButton,
  LargeButton,
  ModalConfirmDefault,
  RgValidatorForm,
  RgInputNumber,
  IconInfoHelper,
  Tooltip,
} from "~tokio/primitive";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import RgSelectLotExpiration from "$pharmacy/common/select/rg-select-lot-expiration/RgSelectLotExpiration";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgTitle from "~tokio/primitive/title/rg-title/RgTitle";
import {
  IconCheck,
  IconAdd,
  IconObservation,
} from "~tokio/primitive/icon/symbols";
import ModalObservationTransferRequest from "$pharmacy/submodules/central/components/modal/modal-observation-transfer-request/ModalObservationTransferRequest";
import moment from "moment";

const FORM_PRODUCT = {
  productNumber: null,
  productPresentation: null,
  lotAndExpiration: null,
  dateAttend: moment().format("DD/MM/YYYY"),
  stock: 0,
  quantity: "0",
};

const ID_STATUS_ITEM_REQUEST = {
  waiting: "1",
  answered: "2",
  partiallyServiced: "3",
  unavailableItem: "4",
};

const STATUS_ORDER = {
  open: 0,
  close: 1,
};

export default {
  name: "PharmacyCentralAttendTransfer",
  components: {
    Modulebox,
    FormBase,
    RgSelectPharmacy,
    RgInputDate,
    RgInput,
    RgAddButton,
    RgCleanButton,
    RgSelectProduct,
    RgSelectTypePresentation,
    RgSelectLotExpiration,
    SmallButton,
    SmartTable,
    RgTitle,
    RgRadioCustom,
    RgSaveButton,
    LargeButton,
    IconCheck,
    IconAdd,
    IconObservation,
    ModalObservationTransferRequest,
    ModalConfirmDefault,
    RgValidatorForm,
    RgInputNumber,
    IconInfoHelper,
    Tooltip,
  },

  data() {
    return {
      oldData: {},
      productSelect: null,
      guideShipping: "",
      formProduct: this.$utils.obj.DeepCopy(FORM_PRODUCT),
      mutableListProduct: [],
      modalConfirmReturn: false,
      productSelected: {},
      dateExpiration: null,
      listObservation: [],
      showModalObservation: false,
    };
  },

  computed: {
    asPartiallyServiced() {
      return (
        this.productSelected &&
        this.productSelected.ist_id_status_itens_pedido === "3"
      );
    },
    disabledByModal() {
      return this.showModalObservation || this.modalConfirmReturn;
    },
    pharmacyId() {
      if (this.oldData.str_id_farmacia_destino) {
        return this.oldData.str_id_farmacia_destino; // str_id_farmacia_destino;
      } else {
        return 0;
      }
    },
    collumnsRequestProduct() {
      return this.COLLUMSREQUEST;
    },
    collumnsSubmitProduct() {
      return this.COLLUMSSUBMIT;
    },
    mutableList() {
      const listRequest = [];
      const listSubmit = [];
      this.mutableListProduct.map((element) => {
        const waiting =
          element.ist_id_status_itens_pedido === ID_STATUS_ITEM_REQUEST.waiting;
        const partiallyServiced =
          element.ist_id_status_itens_pedido ===
          ID_STATUS_ITEM_REQUEST.partiallyServiced;
        if (
          (waiting || partiallyServiced) &&
          Number(element.ist_quantidade_solicitada) -
            Number(element.trs_quantidade) !==
            0
        ) {
          const result = listRequest.find(
            (item) => item.mpd_codigo === element.mpd_codigo,
          );

          if (!result) listRequest.push(element);
          if (!waiting) listSubmit.push(element);
        } else {
          listSubmit.push(element);
        }
      });
      return { listRequest, listSubmit };
    },
    isWaiting() {
      let waiting = false;
      const statusWaiting = "1";
      if (this.mutableList.listRequest.length === 0) {
        waiting = false;
      } else {
        this.mutableList.listRequest.forEach((item) => {
          if (item.ist_id_status_itens_pedido === statusWaiting) {
            waiting = true;
          }
        });
      }

      return waiting;
    },
    propsRadio() {
      const list = [
        { title: "Aberto", blue: true, id: 0, active: true },
        {
          title: "Fechado",
          blue: true,
          id: 1,
          active: false,
        },
      ];
      const uniqueKey = "id";

      return { list, uniqueKey };
    },
    statusOrder() {
      return Number(this.oldData.str_status) === STATUS_ORDER.open
        ? { int: STATUS_ORDER.open, bol: true }
        : { int: STATUS_ORDER.close, bol: false };
    },
  },
  watch: {
    "formProduct.lotAndExpiration": {
      handler(pValue) {
        if (pValue != null) {
          this.searchStock();
        } else {
          this.formProduct.stock = null;
          this.formProduct.quantity = null;
        }
      },
      deep: true,
    },
    "formProduct.productNumber": {
      handler(pValue) {
        if (pValue != null) {
          this.validationSelectLine(pValue);
        } else {
          this.formProduct.lotAndExpiration = null;
          this.formProduct.stock = null;
          this.formProduct.quantity = null;
        }
      },
    },
  },
  created() {
    this.COLLUMSREQUEST = [
      { name: "Código", key: "mpd_codigo", align: "left", active: "true" },
      {
        name: "Nome",
        key: "mpd_novo_principio_ativo",
        align: "left",
        active: "true",
      },
      {
        name: "Apresentação",
        key: "mtu_novo_nome",
        align: "left",
        active: "true",
      },
      {
        name: "Solicitado",
        key: "ist_quantidade_solicitada",
        align: "left",
        active: "true",
      },
      { name: "Restante", key: "rest", align: "left" },
    ];
    this.COLLUMSSUBMIT = [
      { name: "Código", key: "mpd_codigo", align: "left", active: "true" },
      {
        name: "Nome",
        key: "mpd_novo_principio_ativo",
        align: "left",
        active: "true",
      },
      {
        name: "Apresentação",
        key: "mtu_novo_nome",
        align: "left",
        active: "true",
      },
      { name: "Tipo", key: "mtp_nome", align: "left", active: "true" },
      { name: "Lote-Validade", key: "lote", align: "left" },
      { name: "Solicitado", key: "request", align: "left" },
    ];
  },
  mounted() {
    this.searchDataOrder();
  },
  destroyed() {
    this.$store.commit("Pharmacy/Central/UNSELECT_PHARMACY_CENTRAL");
  },
  methods: {
    validateAmount(pValue, pErrors) {
      const amount = Number(pValue.replace(/[.]/g, "")(/[^0-9]/g, ""));

      const stock = Number(this.formProduct.stock.replace(/[.]/g, ""));

      const requestAmount = Number(
        this.productSelected.ist_quantidade_solicitada.replace(/[.]/g, ""),
      );

      const rest = Number(this.productSelect.rest.replace(/[.]/g, ""));

      if (amount < 1) {
        pErrors.push("Informe um valor maior que zero");
        return false;
      }

      if (stock < amount) {
        pErrors.push("A quantidade não pode ser maior que o estoque");
        return false;
      }

      if (requestAmount < amount) {
        pErrors.push("A quantidade não pode ser maior que a solicitada");
        return false;
      }
      if (rest < amount) {
        pErrors.push("A quantidade não pode ser maior que o restante");
        return false;
      }

      return true;
    },

    selectedProduct(pProduct) {
      if (pProduct) {
        this.formProduct.productPresentation = pProduct.mtu_id;
      } else {
        this.formProduct.productPresentation = null;
      }
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async searchDataOrder() {
      try {
        this.$loader.start();

        const idOrder = {
          ...this.$store.getters["Pharmacy/Central/GET_SELECTED_CENTRAL"],
        };
        const result = await this.$store.dispatch(
          "Pharmacy/Central/GET_TRANSFER_ATTEND",
          {
            intSolicitacaoTransferencia: idOrder.str_id,
          },
        );
        const guide = await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_GUIDE_SHIPPING",
          {
            str_id: result.str_id,
          },
        );

        this.oldData = { ...result };
        this.guideShipping = guide.dados.toUpperCase();

        this.listObservation = [
          {
            far_id_origem: this.oldData.str_id_farmacia_origem,
            str_id: this.oldData.str_id,
            str_numero: this.oldData.str_numero,
          },
        ];

        this.SearchItens(result.str_id);
      } catch (err) {
        this.$toaster.error(err, "Erro ao carregar os dados da solicitação");
      } finally {
        this.$loader.finish();
      }
    },
    closeModalUnavailableOrder() {
      this.modalConfirmReturn = false;
    },
    async SearchItens(idRequest) {
      try {
        this.$loader.start();

        const itens = await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_ITENS_TRANSFER_ATTEND",
          {
            intIdSolicitacao: idRequest,
            blnSomenteDisponiveis: false,
            blnDispensacoes: true,
          },
        );

        this.mutableListProduct = itens.itens_request;

        let total = 0;
        if (this.mutableList.listRequest.length > 0) {
          for (const pValue of this.mutableList.listRequest) {
            this.mutableList.listSubmit.forEach((item) => {
              if (Number(item.mpd_codigo) === Number(pValue.mpd_codigo)) {
                total += Number(item.trs_quantidade);

                pValue.rest =
                  Number(pValue.ist_quantidade_solicitada) - Number(total);
              }
            });

            if (total === 0)
              pValue.rest = Number(pValue.ist_quantidade_solicitada);
            total = 0;
          }
        } else {
          this.mutableList.listRequest.forEach((item) => {
            item.rest = Number(item.ist_quantidade_solicitada);
          });
        }
      } catch (err) {
        this.$toaster.error(err, "Erro ao buscar itens");
      } finally {
        this.$loader.finish();
      }
    },
    async searchStock() {
      try {
        this.$loader.start();
        const stockResult = await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_STOCK_ITEM",
          {
            intIdLote: this.formProduct.lotAndExpiration,
            ist_id_municipios_produtos: Number(this.formProduct.productNumber),
            str_id_farmacia_destino: Number(
              this.oldData.str_id_farmacia_destino,
            ),
          },
        );
        this.formProduct.stock = stockResult;
      } catch (err) {
        this.$toaster.error(err, "Erro ao carregar estoque");
      } finally {
        this.$loader.finish();
      }
    },
    async itemAttend() {
      try {
        if (!(await this.isFormValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
        if (!this.productSelect) {
          this.$toaster.warning("Selecione um item da tabela solicitados");
          return null;
        }
        this.$loader.start();
        const result = await this.$store.dispatch(
          "Pharmacy/Central/ATTEND_ITEM",
          {
            arrFormData: {
              intEstocado: Number(
                this.formProduct.quantity.replace(/[.]/g, ""),
              ),
              intIdLote: this.formProduct.lotAndExpiration,
              intSolicitado: Number(
                this.oldData.ist_quantidade_solicitada.replace(/[.]/g, ""),
              ),
              ist_id: this.productSelect.ist_id,
              ist_id_municipios_produtos: this.formProduct.productNumber,
              ist_id_status_itens_pedido: this.generationStatusItem(),
              str_id: this.oldData.str_id,
              str_id_farmacia_destino: this.oldData.str_id_farmacia_destino,
              str_id_farmacia_origem: this.oldData.str_id_farmacia_origem,
              strGuiaRemessa: this.guideShipping,
              strValidadeLote: this.dateExpiration,
              totalDispensado: Number(
                this.formProduct.quantity.replace(/[.]/g, ""),
              ),
            },
          },
        );
        if (!result.status) throw new Error(result.dados);
        this.$toaster.success("Transferência atendida com sucesso");
        this.productSelected = {};
        this.cleanData();
        this.SearchItens(this.oldData.str_id);
      } catch (err) {
        this.$toaster.error(err, "Erro ao dispensar item");
      } finally {
        this.$loader.finish();
      }
    },
    async unavailableItem() {
      if (!this.productSelect) {
        this.$toaster.warning("Selecione um item da tabela solicitados");
        return null;
      }
      try {
        this.$loader.start();
        await this.$store.dispatch("Pharmacy/Central/UNAVAILABLE_ITEM", {
          intIdPedido: this.oldData.str_id,
          intIdItemPedido: this.productSelect.ist_id,
        });
        this.modalConfirmReturn = false;
        this.$toaster.success("Indisponibilização atendida com sucesso");
        this.productSelected = {};
        this.cleanData();
        this.SearchItens(this.oldData.str_id);
      } catch (err) {
        this.modalConfirmReturn = false;
        this.$toaster.error(err, "Erro ao indisponibilizar item");
      } finally {
        this.$loader.finish();
      }
    },
    async closeRequest() {
      try {
        this.$loader.start();
        await this.$store.dispatch("Pharmacy/Central/CLOSE_REQUEST_TRANSFER", {
          intIdSolicitacao: this.oldData.str_id,
        });
        this.$toaster.success("Pedido fechado com sucesso");
        this.cleanData();
        this.searchDataOrder();
      } catch (err) {
        this.$toaster.error(err, "Não foi possível fechar o pedido");
      } finally {
        this.$loader.finish();
      }
    },
    generationStatusItem() {
      const quantity = Number(this.formProduct.quantity);

      const rest = Number(this.productSelected.rest) - Number(quantity);

      if (quantity === 0) return ID_STATUS_ITEM_REQUEST.waiting;
      if (rest > 0) return ID_STATUS_ITEM_REQUEST.partiallyServiced;
      if (rest === 0) return ID_STATUS_ITEM_REQUEST.answered;
    },
    getValidation(pValue) {
      this.dateExpiration = pValue.fel_validade_lote;
    },
    selectLine(pValue) {
      if (pValue) {
        this.productSelected = pValue;
        this.productSelected.message =
          this.productSelected.mpd_novo_principio_ativo +
          " - " +
          this.productSelected.mtu_novo_nome;
        if (
          pValue.ist_id_municipios_produtos !== this.formProduct.productNumber
        ) {
          this.cleanData();
        }
        this.formProduct.productNumber = pValue.ist_id_municipios_produtos.toString();
        if (pValue.lote2) this.lotAndExpiration = pValue.lote2;
      } else {
        this.productSelected = {};
        this.cleanData();
      }
    },
    validationSelectLine(pValue) {
      let notItemList = true;
      this.mutableListProduct.map((element) => {
        if (
          element.ist_id_status_itens_pedido ===
            ID_STATUS_ITEM_REQUEST.waiting ||
          element.ist_id_status_itens_pedido ===
            ID_STATUS_ITEM_REQUEST.partiallyServiced
        ) {
          if (element.ist_id_municipios_produtos === pValue) {
            this.productSelect = element;
            notItemList = false;
          }
        }
      });
      if (notItemList) {
        this.cleanData();
        this.$toaster.warning("Este item não foi solicitado");
      }
    },
    cleanFields() {
      this.formProduct.lotAndExpiration = null;
      this.formProduct.stock = 0;
      this.formProduct.quantity = "0";
    },

    cleanData() {
      this.productSelect = null;
      this.dateExpiration = null;
      this.formProduct = this.$utils.obj.DeepCopy(FORM_PRODUCT);
    },
    exitTransfer() {
      this.cleanData();
      this.$router.push({ name: "pharmacy.central" });
    },
  },
};
</script>
