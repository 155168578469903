<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";

import SearchService from "./action/SearchService";

export default {
  name: "RgSelectService",
  extends: RgSelect,
  props: {
    label: {
      type: String,
      default: "Serviço",
    },
    defaultText: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      service: [],
    };
  },
  watch: {
    service: function (val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.service;
    },
    async doSearch() {
      this.service = [];

      const data = await SearchService();

      this.service = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
        };
      });
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
