<template lang="html">
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.69645 19.967C8.95807 19.7126 8.95812 19.3001 8.69645 19.0457L6.28791 16.7041L13.4354 16.704C13.8055 16.704 14.1055 16.4123 14.1055 16.0526C14.1055 15.6928 13.8055 15.4011 13.4354 15.4011L6.28778 15.4012L8.69636 13.0593C8.95803 12.805 8.95803 12.3925 8.69636 12.1381C8.43469 11.8837 8.01047 11.8837 7.7488 12.1381L4.19648 15.592C4.07078 15.7141 4.0002 15.8798 4.0002 16.0526C4.0002 16.2253 4.07083 16.3911 4.19648 16.5132L7.74889 19.967C8.01056 20.2214 8.43478 20.2214 8.69645 19.967Z"
      fill="white"
    />
    <path
      d="M11.3036 5.19074C11.0419 5.4451 11.0419 5.8576 11.3036 6.11201L13.7121 8.45366L6.56456 8.4537C6.19453 8.4537 5.89453 8.74537 5.89453 9.10515C5.89453 9.46492 6.19453 9.75659 6.56456 9.75659L13.7122 9.75655L11.3036 12.0984C11.042 12.3527 11.042 12.7652 11.3036 13.0196C11.5653 13.2741 11.9895 13.2741 12.2512 13.0196L15.8035 9.56576C15.9292 9.44359 15.9998 9.27791 15.9998 9.10515C15.9998 8.93238 15.9292 8.76665 15.8035 8.64449L12.2511 5.19069C11.9894 4.93633 11.5652 4.93632 11.3036 5.19074Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 25C22.7614 25 25 22.7614 25 20C25 17.2386 22.7614 15 20 15C17.2386 15 15 17.2386 15 20C15 22.7614 17.2386 25 20 25ZM19.6516 17.5303C19.6516 17.2374 19.889 17 20.1819 17C20.4748 17 20.7122 17.2374 20.7122 17.5303V19.6515H22.8333C23.1262 19.6515 23.3636 19.8889 23.3636 20.1818C23.3636 20.4747 23.1262 20.7121 22.8333 20.7121H20.7122V22.8333C20.7122 23.1262 20.4748 23.3636 20.1819 23.3636C19.889 23.3636 19.6516 23.1262 19.6516 22.8333V20.7121H17.5303C17.2374 20.7121 17 20.4747 17 20.1818C17 19.8889 17.2374 19.6515 17.5303 19.6515H19.6516V17.5303Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "RequestTransfer",
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>
