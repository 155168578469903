import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacyCentralTotalConsumptionReport(
    $unitHealth: Int
    $pharmacyOrigin: Int
    $product: Int
    $mtp_id: Int
    $mtp_nome: String
    $productName: String
    $far_id_destino: Int
    $far_nome_destino: String
    $far_nome_origem: String
    $initialDate: String
    $finalDate: String
    $loggedUserId: Int!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacyCentralTotalConsumptionReport(
      uns_id: $unitHealth
      far_id: $pharmacyOrigin
      mpd_id: $product
      mtp_id: $mtp_id
      mtp_nome: $mtp_nome
      productName: $productName
      far_id_destino: $far_id_destino
      far_nome_destino: $far_nome_destino
      far_nome_origem: $far_nome_origem
      initialDate: $initialDate
      finalDate: $finalDate
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        NOME
        NOME_COMERCIAL
        APRESENTACAO
        TIPO
        LOTE_VALIDADE
        QUANTIDADE
        VALOR_TOTAL
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacyCentralTotalConsumptionReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
