import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamPatientsPendencyQueueReport(
    $procedureName: String!
    $subProcedureName: String
    $unitHealthRequest: Int
    $unitHealthRequestName: String
    $queuePeriodDateStart: String
    $queuePeriodDateEnd: String
    $requestPeriodDateStart: String
    $requestPeriodDateEnd: String
    $columnsToPrint: [ColumnsToPrintInputType]
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchExamPatientsPendencyQueueReport(
      procedureName: $procedureName
      subProcedureName: $subProcedureName
      unitHealthRequest: $unitHealthRequest
      unitHealthRequestName: $unitHealthRequestName
      queuePeriodDateStart: $queuePeriodDateStart
      queuePeriodDateEnd: $queuePeriodDateEnd
      requestPeriodDateStart: $requestPeriodDateStart
      requestPeriodDateEnd: $requestPeriodDateEnd
      columnsToPrint: $columnsToPrint
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        UNIDADE_SAUDE_SOLICITANTE
        DATA_INCLUSAO_FILA
        PACIENTE
        MAE
        NASCIMENTO
        CNS
        PROCEDIMENTO
        SUBPROCEDIMENTO
        PENDENCIA
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamPatientsPendencyQueueReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
