<style src="./PharmacyConsolidatedReport.scss" lang="scss" scoped></style>

<template>
  <div class="pharmacy-consolidated-filter">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="unit-health-pcr"
          v-model="form.uns_id"
          :rules="{ required: true }"
          permission="farmacia.relatorioCentral.relatorioCentral"
          class="inputitem"
        />
      </div>

      <div class="selectinput">
        <RgSelectPharmacyUnit
          id="pharmacy-unity-pcr"
          ref="pharmacyUnit"
          v-model="form.far_id"
          :unitHealth="form.uns_id"
          :rules="{ required: true }"
          default-text="Todas"
          class="inputitem"
          @change="getPharmachyUnit"
        />
      </div>

      <div class="selectinput">
        <RgSuggestPharmacyProductCounty
          id="pharmacy-product-pcr"
          ref="product"
          :clientId="clientId"
          default-text="Todos"
          class="inputitem"
          :rules="{ forceSelection: true }"
          @selected="getProductCounty"
        />
      </div>

      <div class="selectinput">
        <RgSelectPharmacyProductCountyType
          id="pharmacy-product-type-pcr"
          v-model="form.mtp_id"
          :clientId="clientId"
          default-text="Todos"
          class="inputitem"
          @change="getProductCountyType"
        />
      </div>

      <div class="filter-title">
        <span class="title">Período</span>
      </div>

      <div class="selectinput _flex">
        <RgInputDate
          id="initial-date-pcr"
          ref="initialDate"
          v-model="form.initialDate"
          :rules="{ required: true, fn: validateInitialDate }"
          label="Data Inicial"
          class="inputitem"
        />
        <RgInputDate
          id="final-date-pcr"
          ref="finalDate"
          v-model="form.finalDate"
          :rules="{ required: true, fn: validateFinalDate }"
          label="Data Final"
          class="inputitem mg"
          :min-date="getMinDateInitial"
          :max-date="getMaxDateInitial"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RgSelectUnithealth, RgInputDate } from "~tokio/primitive";

import {
  RgSelectPharmacyUnit,
  RgSuggestPharmacyProductCounty,
  RgSelectPharmacyProductCountyType,
} from "$pharmacy/common/components";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  far_id: null,
  far_nome: null,
  mpd_id: null,
  mpd_nome: null,
  mtp_id: null,
  mtp_nome: null,
  initialDate: moment().add(-90, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  orderByLabel: "Produto",
};

export default {
  name: "PharmacyConsolidatedReport",
  components: {
    RgSelectUnithealth,
    RgInputDate,
    RgSelectPharmacyUnit,
    RgSuggestPharmacyProductCounty,
    RgSelectPharmacyProductCountyType,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Consolidado",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
    };
  },

  computed: {
    getNameSmartTable() {
      return "PharmacyConsolidatedReport";
    },

    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-1 { white-space: normal !important; text-align: left; }" +
        ".report-column-2 { white-space: normal !important; text-align: left; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; }" +
        ".report-column-5 { white-space: normal !important; text-align: left; }"
      );
    },

    columnTable() {
      return this.COLUMN;
    },

    clientId() {
      return this.$store.getters["Login/GET_USER_ID_CLIENT"];
    },

    getMinDateInitial() {
      const date = this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;

      return date;
    },

    getMaxDateInitial() {
      const date = this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(90, "days")
              .format(),
          )
        : null;

      return date;
    },
  },

  watch: {
    "form.uns_id"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.far_id = null;
        this.form.far_nome = null;
      }
    },

    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(90, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  mounted() {
    this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },

  created() {
    this.COLUMN = [
      { name: "Cód. do Produto", key: "CODIGO_PRODUTO", align: "left" },
      { name: "Produto", key: "PRODUTO", align: "left" },
      { name: "Nome Comercial", key: "NOME_COMERCIAL", align: "left" },
      { name: "Tipo de Produto", key: "TIPO_PRODUTO", align: "left" },
      { name: "Apresentação", key: "APRESENTACAO", align: "left" },
      { name: "Entrada", key: "ENTRADA" },
      { name: "Saída", key: "SAIDA" },
    ];
  },

  methods: {
    buildFilter() {
      const productCountId = this.form.mpd_id > 0 ? this.form.mpd_id : null;

      const payLoad = {
        uns_id: this.form.uns_id,
        far_id: this.form.far_id,
        far_nome: this.form.far_nome,
        mpd_id: productCountId,
        mpd_nome: this.form.mpd_nome,
        mtp_id: this.form.mtp_id,
        mtp_nome: this.form.mtp_nome,
        initialDate: this.form.initialDate,
        finalDate: this.form.finalDate,
        orderByLabel: this.form.orderByLabel,
        columnsToPrint: this.columnsToPrint,
      };
      return payLoad;
    },

    async doSearch(pData) {
      try {
        return await this.$store.dispatch(
          "Pharmacy/Report/GET_PHARMACY_CONSOLIDATED_REPORT",
          pData,
        );
      } catch (Err) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    getPharmachyUnit(pValue) {
      this.form.far_nome = pValue ? pValue.label : null;
    },

    getProductCounty(pValue) {
      this.form.mpd_id = pValue ? pValue.source.mpd_id : null;
      this.form.mpd_nome = pValue
        ? pValue.source.mpd_novo_principio_ativo
        : null;
    },

    getProductCountyType(pValue) {
      this.form.mtp_nome = pValue ? pValue.label : null;
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.form.uns_id = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
      if (this.$refs.initialDate) {
        this.$refs.initialDate.cleanValidate();
      }
      if (this.$refs.finalDate) {
        this.$refs.finalDate.cleanValidate();
      }

      if (this.$refs.pharmacyUnit) {
        this.$refs.pharmacyUnit.cleanValidate();
      }
    },
  },
};
</script>
