<template lang="html">
  <div v-if="show" class="modal-confirm-hospitalization">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">Deseja efetivar a internação do paciente?</h1>
      </div>

      <div slot="body" class="body">
        <div class="information">
          <div class="status">
            <IconBed :color="'#5295E2'" :shadow="'#5295E2'" class="icon-bed" />

            <IconArrowRight class="exchange-svg" />

            <IconBed :color="'#ff5555'" :shadow="'#ff5555'" class="icon-bed" />
          </div>

          <div class="grid">
            <span class="data">
              Unidade de Saúde:
              <strong>
                {{
                  hospitalization ? hospitalization.uns_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Local de Atendimento:
              <strong>
                {{
                  hospitalization ? hospitalization.lca_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Número da Internação:
              <strong>
                {{
                  hospitalization ? hospitalization.int_numero : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Setor:
              <strong>
                {{
                  hospitalization ? hospitalization.set_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Leito:
              <strong>
                {{
                  hospitalization ? hospitalization.inl_nome : "Não informado"
                }}
              </strong>
            </span>

            <span class="data">
              Paciente:
              <strong>
                {{ namePatient(hospitalization) }}
              </strong>
            </span>
          </div>
        </div>

        <div class="message">
          <span>
            Ao efetivar a internação do paciente a situação do leito muda de
            reserva para ocupado.
          </span>
        </div>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel-btn" medium class="clean" @click="close" />

        <RgSaveButton id="save-btn" medium class="save" @click="save" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { AlertError, toast } from "~tokio/primitive/notification";

import {
  RgBaseModal,
  RgSaveButton,
  RgCancelButton,
  IconBed,
  IconArrowRight,
} from "~tokio/primitive";

export default {
  name: "ModalHospitalizationExit",
  components: {
    RgBaseModal,
    RgSaveButton,
    RgCancelButton,
    IconBed,
    IconArrowRight,
  },
  props: {
    hospitalization: {
      type: Object,
      default: () => {},
    },
    hospitalizationId: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 0,
    },
    operation: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    hospitalization(pValue) {
      if (pValue) {
        this.hospitalizationInfo = pValue;
      }
    },
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
  },
  methods: {
    namePatient(pValue) {
      if (!pValue) {
        return "NÃO INFORMADO";
      }

      if (pValue.paciente) {
        // Modal na busca de Internação
        if (pValue.paciente !== "INDIGENTE") {
          return pValue.paciente;
        }
        if (pValue.nome_paciente === "INDIGENTE") {
          return "PACIENTE NÃO IDENTIFICADO";
        }
      }

      if (pValue.nome_paciente) {
        // Modal na busca de Leitos
        if (pValue.nome_paciente !== "INDIGENTE") {
          return pValue.nome_paciente;
        }
        if (pValue.nome_paciente === "INDIGENTE") {
          return "PACIENTE NÃO IDENTIFICADO";
        }
      }
    },

    async save() {
      const data = {
        bed_id: this.hospitalizationInfo.id,
        bed_type_id: this.hospitalizationInfo.itl_id,
        int_id: this.hospitalizationInfo.int_id,
      };

      try {
        await this.$store.dispatch(
          "Hospitalization/Bed/FLOW_RESERVATION_TO_BUSY",
          data,
        );
        toast.success("Internação salva com sucesso!");
        this.close();
      } catch (pErr) {
        console.log("pErr", pErr);
        return AlertError("Falha ao salvar internação.");
      }
    },

    close() {
      this.$emit("close");
    },

    setColorSituation() {
      return "#5295E2";
    },

    setColorOperation() {
      return "#ff5555";
    },
  },
};
</script>
