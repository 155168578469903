import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchHistoryScheduleReport(
    $initialDate: String
    $finalDate: String
    $uns_id: Int
    $pes_id: Int
    $pac_prontuario_unico: Int
    $pes_nome: String
    $cns_or_cpf: String
    $age: Int
    $telephones: String
    $sex: String
    $orderby: Int
    $orderByLabel: String
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchHistoryScheduleReport(
      initialDate: $initialDate
      finalDate: $finalDate
      uns_id: $uns_id
      pes_id: $pes_id
      pac_prontuario_unico: $pac_prontuario_unico
      pes_nome: $pes_nome
      cns_or_cpf: $cns_or_cpf
      age: $age
      telephones: $telephones
      sex: $sex
      orderby: $orderby
      orderByLabel: $orderByLabel
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        DATA_HORA_CONSULTA
        UNIDADE_DE_SAUDE
        SETOR
        OCUPACAO
        PROFISSIONAL_DE_SAUDE
        OBJETIVO
        SITUACAO
        MARCACAO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchHistoryScheduleReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
