import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
} from "@apollo/client/core";

import ApolloLinkTimeout from "apollo-link-timeout";

import "cross-fetch/polyfill";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";

import {
  HandleUnauthorized,
  HandleForbidden,
  HandleNetworkTimeout,
} from "./error-handlers";

const TIMEOUT = 120000;
const URI = `${process.env.VUE_APP_BASE_URL}graphql`;

const httpLink = createHttpLink({
  uri: URI,
});

const getLoginState = () => {
  return require("@/store").default.state.Login;
};

const authLink = setContext((_, { headers }) => {
  const localStorageLogin = getLoginState();
  return {
    headers: {
      ...headers,
      authorization: localStorageLogin.token,
      unithealth: localStorageLogin.unitHealth.uns_id,
    },
  };
});

const logLink = onError(({ networkError }) => {
  const httpStatus = networkError?.statusCode;

  switch (httpStatus) {
    case 401: {
      HandleUnauthorized();
      break;
    }
    case 403: {
      HandleForbidden();
      break;
    }
    case 504: {
      HandleNetworkTimeout();
      break;
    }
  }
});

const timeoutLink = new ApolloLinkTimeout(TIMEOUT);

const link = timeoutLink.concat(logLink.concat(authLink.concat(httpLink)));

const GraphQLClient = new ApolloClient({
  link,
  addTypename: false,
  cache: new InMemoryCache(),
});

const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  } else if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

/**
 * @descrption Gera a string de variavel e parametro da query do graphql
 * @param {Object} pParams
 */

const GenerateParams = (pParams) => {
  const entries = Object.entries(pParams);

  let variables = "";
  let parameters = "";

  for (const [key, value] of entries) {
    variables += `$${key}: ${value},`;
    parameters += `${key}: $${key},`;
  }

  return {
    variables: variables.slice(0, -1),
    parameters: parameters.slice(0, -1),
  };
};

export { GraphQLClient, removeTypename, GenerateParams, gql };
