<template lang="html">
  <div v-if="show" class="modal-print-movement">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <!-- TODO: Verificar depois as telas que utilizam essa modal -->
      <div slot="header" class="modal-print-movement-header">
        <span class="title"> Imprimir Guia de Remessa </span>
      </div>
      <div slot="body" class="modal-print-movement-body">
        <FormBase title="Dados do Envio" class="form-base">
          <section class="grid">
            <span class="text-info">
              Origem:
              <strong :title="product.origem">
                {{ product.origem || "-" }}</strong
              >
            </span>
            <span class="text-info">
              Destino:
              <strong :title="product.destino">
                {{ product.destino || "-" }}</strong
              >
            </span>
            <span class="text-info">
              Data do Envio:
              <strong :title="product.trs_data">
                {{ product.trs_data || "-" }}</strong
              >
            </span>
            <span class="text-info">
              Número da Guia:
              <strong :title="product.trs_guia_remessa">
                {{ product.trs_guia_remessa || "-" }}</strong
              >
            </span>
            <span class="text-info">
              Emissor:
              <strong :title="product.usu_nome">
                {{ product.usu_nome || "-" }}</strong
              >
            </span>
            <span class="text-info">
              Usuário:
              <strong :title="product.usuName">{{
                product.usuName || "-"
              }}</strong>
            </span>
          </section>
          <span class="subtitle">Dados do Produto</span>
          <hr class="hr" />
          <section class="data-product">
            <div class="grid-product">
              <span class="text-info">
                Código:
                <strong :title="product.mpd_codigo">
                  {{ product.mpd_codigo || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Produto:
                <strong :title="product.mpd_novo_principio_ativo">
                  {{ product.mpd_novo_principio_ativo || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Apresentação:
                <strong :title="product.mtu_novo_nome">
                  {{ product.mtu_novo_nome || "-" }}</strong
                >
              </span>
              <span class="text-info">
                Quantidade:
                <strong :title="product.trs_quantidade">
                  {{ product.trs_quantidade || "-" }}</strong
                >
              </span>
            </div>
          </section>
        </FormBase>
      </div>
      <div slot="footer" class="modal-print-movement-footer">
        <RgCancelButton medium @click="close" />
        <RgPrinterButton label="Imprimir" medium @click="printerDeliveryNote" />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgPrinterButton, RgCancelButton } from "~tokio/primitive";
import DeliveryNoteHTML from "$pharmacy/submodules/central/html/DeliveryNoteHTML";
import { FormBase } from "~tokio/foundation";
import { mapGetters } from "vuex";

export default {
  name: "ModalPrintMovement",
  components: {
    RgBaseModal,
    RgPrinterButton,
    FormBase,
    RgCancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      product: {},
      result: {},
    };
  },
  computed: {
    ...mapGetters({
      clientName: "Login/GET_CLIENT_NAME",
      userName: "Login/GET_USER_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
    }),
  },
  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      }
    },
    filters(pValue) {
      if (pValue && pValue !== null) {
        this.printerOutput(pValue);
      }
    },
  },
  created() {},
  mounted() {},

  methods: {
    close() {
      this.$emit("close");
    },
    async printerOutput(pValue) {
      try {
        const variables = {
          strGuiaRemessa: pValue,
        };
        this.$loader.start();
        this.result = await this.$store.dispatch(
          "Pharmacy/Central/PRINTER_OUTPUT",
          variables,
        );

        this.product = {
          origem: this.result[0].atendente,
          destino: this.result[0].solicitante,
          trs_data: this.result[0].trs_data,
          trs_guia_remessa: this.result[0].trs_guia_remessa,
          usu_nome: this.result[0].usu_nome,
          usuName: this.userName,
          mpd_codigo: this.result[0].mpd_codigo,
          mpd_novo_principio_ativo: this.result[0].mpd_novo_principio_ativo,
          mtu_novo_nome: this.result[0].mtu_novo_nome,
          trs_quantidade: this.result[0].trs_quantidade,
        };
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir guia de remessa",
        );
      } finally {
        this.$loader.finish();
      }
    },
    printerDeliveryNote() {
      const html = DeliveryNoteHTML(
        this.product,
        this.result,
        this.clientName,
        this.userName,
        this.unitHealth,
      );
      this.$utils.print.printHtml(html);
      this.close();
    },
  },
};
</script>
