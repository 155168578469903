import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamPriority($priority: String) {
    examPriority(priority: $priority) {
      rows {
        fep_id_prioridades_fila
        fep_prioridades_fila
      }
    }
  }
`;

export default async (variables) => {
  try {
    const { data } = await GraphQLClient.query({ query, variables });
    return data.examPriority.rows;
  } catch (err) {
    return Promise.reject(err.graphQLErrors[0].message);
  }
};
