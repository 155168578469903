<style src="./SurgeryMaterial.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="surgery-material">
    <section class="teams-form">
      <form class="teams-list-form row" @submit.prevent="insertItem">
        <div class="material-amount col-2">
          <RgInput v-model="lItem.amount" label="Quantidade" />
        </div>

        <div class="material-combo col-9">
          <RgTypeaheadSurgeryMaterial
            v-model="lItem.value"
            label="Material"
            placeholder="Digite o material"
            @select="seletedItem"
          />
        </div>
        <div class="form-submit col-1">
          <button class="button">
            <IconCircleAdd class="button--icon" />
          </button>
        </div>
      </form>
    </section>
    <section class="form-list">
      <div class="requesting-list">
        <ul>
          <li>
            <ul class="form-list--container-header row">
              <li class="form-list--employee col-4">Quantidade</li>
              <li class="form-list--employee col-7">Material Cirúrgico</li>
              <li class="form-list--remove col-1" />
            </ul>
          </li>
          <li
            v-for="(item, i) in templateListItems"
            :key="i"
            class="form-list--container-row"
          >
            <ul class="form-list--container-content row">
              <li class="form-list--employee col-4">{{ item.amount }}</li>
              <li class="form-list--employee col-7">{{ item.text }}</li>
              <li class="form-list--remove col-1">
                <RgRemoveSmall @confirm-remove="removeItem(i)" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { IconCircleAdd, RgInput, RgRemoveSmall } from "~tokio/primitive";
import RgTypeaheadSurgeryMaterial from "$surgery-center/surgery/component/combobox/rg-typeahead-surgery-material/RgTypeaheadSurgeryMaterial";
import SurgeryMaterialModel from "$surgery-center/surgery/model/SurgeryMaterialModel";
import SurgeryAggregateBaseMixin from "$surgery-center/surgery/component/mixin/SurgeryAggregateBaseMixin";
export default {
  name: "SurgeryMaterial",
  components: {
    RgInput,
    IconCircleAdd,
    RgTypeaheadSurgeryMaterial,
    RgRemoveSmall,
  },
  extends: SurgeryAggregateBaseMixin,
  props: {
    value: {
      default: null,
    },
    surgeryId: {
      default: null,
    },
  },
  data() {
    return {
      lItem: {
        amount: "",
      },
    };
  },
  methods: {
    fillList() {
      SurgeryMaterialModel.getMaterialsById(this.surgeryId).then((pList) => {
        const mapList = pList.map((pListItem) => {
          return {
            text: pListItem.tmc_nome,
            amount: pListItem.cmc_qtd,
            value: pListItem.cmc_id_tipos_materiais_cirurgicos,
          };
        });
        this.templateListItems = mapList;
        this.updateModel(mapList);
      });
    },
    isItensInvalid() {
      const itemValue = this.lItem && this.lItem.value;
      const itemAmount = this.lItem.amount;
      const validItem = !itemValue || !itemAmount;
      return validItem;
    },
    updateModel(pItem) {
      if (!pItem) return [];
      const arrFiltered = pItem.map((item) => {
        return {
          cmc_id_tipos_materiais_cirurgicos: item.value,
          cmc_qtd: item.amount,
        };
      });
      this.$emit("input", arrFiltered);
      return arrFiltered;
    },
  },
};
</script>
