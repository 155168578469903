import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    ListQueueBdsia {
      list {
        userId
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.ListQueueBdsia.list || [];
};
