import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchProcedureBulletinBilling(
    $procedureName: String
    $procedureCode: String
    $competence: String
    $occupation: String
    $sex: String
    $birth: String
    $type: String
    $limit: Int
    $offset: Int
  ) {
    SearchProcedureBulletinBilling(
      NO_PROCEDIMENTO: $procedureName
      CO_PROCEDIMENTO: $procedureCode
      DT_COMPETENCIA: $competence
      CO_OCUPACAO: $occupation
      sex: $sex
      birth: $birth
      type: $type
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        CO_PROCEDIMENTO
        NO_PROCEDIMENTO
        VL_IDADE_MAXIMA
        VL_IDADE_MINIMA
        TP_SEXO
        QT_MAXIMA_EXECUCAO
        CO_OCUPACAO
        possuiCid
        possuiServico
        cnsObrigatorio
        cidObrigatorio
        caraterAtendimentoObrigatorio
      }
    }
  }
`;

export default async ({ variables }) => {
  const result = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });

  return result ? result.data.SearchProcedureBulletinBilling : [];
};
