<template lang="html">
  <div class="rg-content-hospitalization-billing-tincture">
    <div class="team-info">
      <RgContentHospitalizationTeam
        :procedure="procedure"
        :required-documents="requiredDocuments"
        @setProfessionalList="setProfissionalList"
      />
    </div>

    <div class="tincture-info">
      <form class="form">
        <RgValidatorForm ref="validator">
          <FormBase title="Laqueadura">
            <div class="rg-content-hospitalization-billing-tincture-info">
              <RgInputMasked
                v-model="tincture.ilv_qtd_filhos"
                :rules="{ required: true }"
                label="Quantidade de Filhos: "
                placeholder="0"
                mask="##"
                class="inputitem"
              />

              <RgRadioCustom
                id="rg-radio"
                v-bind="propsRadioCustom"
                class="rg-radio"
                label="Gestação Alto Risco"
                :canUnselect="false"
                @input="onInputRadioCustom"
              />

              <RgComboboxInstructionLevel
                v-model="tincture.ilv_id_internacoes_grau_instrucoes"
                :rules="{ required: true }"
                label="Grau de Instrução"
                class="inputitem"
              />
            </div>

            <div class="rg-content-hospitalization-billing-tincture-info">
              <RgComboboxCid
                v-model="tincture.ilv_codigo_cid10"
                :procedure="procedure.iap_codigo_procedimentos"
                :rules="{ required: true }"
                label="CID Principal"
                class="inputitem"
                @input="cidSelected"
              />

              <RgComboboxContraceptiveMethod
                v-model="tincture.ilv_id_internacoes_metodos_contraceptivos1"
                :rules="{ required: true }"
                label="Método Contraceptivo 1"
                class="inputitem"
              />
              <RgComboboxContraceptiveMethod
                v-model="tincture.ilv_id_internacoes_metodos_contraceptivos2"
                :rules="{ required: true }"
                label="Método Contraceptivo 2"
                class="inputitem"
              />
            </div>
          </FormBase>
          <div class="buttons">
            <MediumButton
              :disabled="!tincture.professionalTeamList.length > 0"
              label="Confirmar"
              backgroundColor="#0bb98c"
              @click="confirm"
            />

            <MediumButton
              title="Fechar"
              label="Fechar"
              @click.stop.prevent="close"
            />
          </div>
        </RgValidatorForm>
      </form>
    </div>
  </div>
</template>

<script>
import mask from "vue-the-mask";
import {
  RgValidatorForm,
  RgInputMasked,
  MediumButton,
  RgRadioCustom,
} from "~tokio/primitive";
import RgComboboxInstructionLevel from "../../../combobox/rg-combobox-instruction-level/RgComboboxInstructionLevel";
import RgComboboxContraceptiveMethod from "../../../combobox/rg-combobox-contraceptive-method/RgComboboxContraceptiveMethod";
import RgContentHospitalizationTeam from "$billing/submodules/aih/hospitalization-billing/common/component/container/content-hospitalization-team/RgContentHospitalizationTeam";
import RgComboboxCid from "$billing/common/component/rg-combobox-cid/RgComboboxCid";
import { ValidateTeamMembers } from "../../../../service";
import FormBase from "~tokio/foundation/form-base/FormBase";

export default {
  name: "RgContentHospitalizationBillingTincture",
  components: {
    FormBase,
    RgValidatorForm,
    RgInputMasked,
    RgComboboxInstructionLevel,
    RgComboboxContraceptiveMethod,
    RgContentHospitalizationTeam,
    RgComboboxCid,
    MediumButton,
    RgRadioCustom,
  },
  directives: {
    mask,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {},
    },
    requiredDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleRequiredTrue: true,
      tincture: {
        type: "tincture",
        ilv_qtd_filhos: 0,
        ilv_gestacao_alto_risco: false,
        ilv_id_internacoes_grau_instrucoes: "",
        ilv_id_internacoes_metodos_contraceptivos1: "",
        ilv_id_internacoes_metodos_contraceptivos2: "",
        professionalTeamList: [],
      },
      professionalCaptionRaw: "pes_nome",
    };
  },
  computed: {
    propsRadioCustom() {
      const list = [
        { title: "Sim", id: true },
        { title: "Não", id: false },
      ];

      const value = false;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },
  mounted() {
    if (this.procedure.additionalData[0]) {
      this.tincture = this.procedure.additionalData[0];
    }
  },
  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.tincture.ilv_gestacao_alto_risco = item.id;
      }
    },
    async confirm(event) {
      if (await this.validateForm()) {
        this.$emit("onFinish", this.tincture);
      }
    },
    close() {
      this.$emit("close");
    },
    setProfissionalList(event) {
      this.tincture.professionalTeamList = event;
    },
    async validateForm() {
      const valid = await this.$refs.validator.validate();
      // if (!this.validateTeamMembers()) valid = false
      return valid;
    },
    cidSelected(pData) {
      if (pData && pData[0].text) {
        this.tincture.ilv_codigo_cid10 = pData[0].text;
      }
    },
    validateTeamMembers() {
      return ValidateTeamMembers(this.tincture.professionalTeamList);
    },
  },
};
</script>
