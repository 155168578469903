<script>
import RgSelect from "~tokio/primitive/selection/rg-select/RgSelect";
import SearchScale from "./action/SearchScale";

export default {
  name: "RgSelectScaleExam",
  extends: RgSelect,
  props: {
    hoursSelected: Array,
    hours: {
      type: Object,
    },
    interval: {
      type: Number,
    },
    dateExam: {
      type: String,
    },
    sectorId: {
      type: Number,
    },
    attendance: {
      type: Boolean,
    },
    attendanceScheduledExams: {
      type: Boolean,
    },
    sectorLink: {
      type: Number,
    },
    label: {
      type: String,
      default: "Escala",
    },
  },
  data: () => {
    return {
      valuesData: [],
    };
  },
  watch: {
    valuesData(val) {
      this.updateOptions();
    },
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    getData() {
      return this.valuesData;
    },
    async doSearch() {
      const data = await SearchScale({
        strData: this.dateExam,
        intIdSetor: this.sectorId,
        blnEhaAtendimento: this.attendance,
        blnAtendComExamesMarcados: this.attendanceScheduledExams,
        intIdVinculoSetor: this.sectorLink,
      });

      this.valuesData = data.map((item) => {
        return {
          value: item.data,
          label: item.label,
          eea_inicio_atendimentos: item.eea_inicio_atendimentos,
          item: item,
        };
      });

      if (this.valuesData.length === 1) {
        this.$emit("selectOnlyItem", this.valuesData[0].value);
      }
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
