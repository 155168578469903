<template>
  <Modulebox
    :title="isEdition ? 'Editar Unidade' : 'Cadastrar Unidade'"
    class="unit-health-register"
  >
    <div class="body">
      <form class="form-container" @submit.stop.prevent="saveUnitHealth">
        <FormBase title="Unidade de Saúde" class="form-base">
          <RgValidatorForm ref="validatorUnit">
            <div class="grid-unit">
              <Tooltip class="tooltip">
                <IconExclamationHelper slot="icon" />
                <div slot="content" class="content">
                  <span class="title">
                    Para guardar as alterações da seção é necessário:
                  </span>

                  <ul class="list">
                    <li class="item">
                      Apertar o botão salvar no final da página;
                    </li>
                    <li class="item">
                      Ao cancelar a edição/cadastro, o sistema volta para a
                      lista de unidades.
                    </li>
                  </ul>
                </div>
              </Tooltip>
              <div class="second-line grid-unit">
                <RgInput
                  id="nome-unidade"
                  ref="unitName"
                  v-model="unit.name"
                  :rules="{ required: true }"
                  :maxlength="100"
                  :disabled="disabledByModal"
                  label="Nome"
                  placeholder="Digite o nome da unidade"
                />
                <RgInput
                  id="cnes"
                  ref="cnes"
                  v-model="unit.cnes"
                  v-mask="'#######'"
                  :disabled="disabledByModal"
                  :rules="{ required: true, min: 7 }"
                  :maxlength="7"
                  label="CNES"
                  placeholder="0000000"
                />

                <RgInput
                  id="orgao-emissor"
                  v-model="unit.issuingBody"
                  :disabled="disabledByModal"
                  :maxlength="15"
                  label="Órgão Emissor"
                  placeholder="Digite o nome do órgão emissor"
                />

                <RgSelectUnitHealthTypeSus
                  id="tipo-unidade"
                  ref="unitTypeSus"
                  v-model="unit.type"
                  :disabled="disabledByModal"
                  :rules="{ required: true }"
                  label="Tipo de Unidade"
                  defaultText="Digite o tipo"
                />

                <RgSelectUnitHealthSubTypeSus
                  id="sub-tipo-unidade"
                  ref="unitSubTypeSus"
                  v-model="unit.subType"
                  :unit-type-sus-id="unit.type"
                  :rules="{ required: hasSubTypeOptions }"
                  :disabled="!hasSubTypeOptions"
                  :class="{ disable: !hasSubTypeOptions }"
                  label="Subtipo de Unidade"
                  defaultText="Digite o subtipo"
                  @options="handleoptions"
                />

                <RgInputCheckbox
                  id="terceirizada"
                  v-model="unit.category"
                  class="checkbox"
                  label="Terceirizada"
                  :disabled="disabledByModal"
                />
              </div>

              <div class="third-line grid-unit">
                <RgInput
                  id="razao-social"
                  v-model="unit.corporateName"
                  :disabled="disabledByModal"
                  :maxlength="75"
                  label="Razão Social"
                  placeholder="Digite o nome de registro da unidade"
                />
                <RgInputCnpj
                  id="cnpj"
                  v-model="unit.cnpj"
                  label="CNPJ"
                  placeholder="00.000.000/0001-00"
                  :disabled="disabledByModal"
                />
                <RgInput
                  id="nome-notificacoes"
                  v-model="unit.notificationName"
                  :maxlength="45"
                  label="Nome para Notificações"
                  placeholder="Digite o nome para notificações SMS"
                  :disabled="disabledByModal"
                />
                <RgSuggestEmployee
                  id="diretor-clinico"
                  ref="clinicalDirector"
                  v-model="unit.clinicalDirector"
                  label="Diretor Clínico"
                  placeholder="Digite e selecione o nome do profissional"
                  notNecessaryBond
                  :filterByActiveOrInactive="false"
                  :disabled="disabledByModal"
                  @selected="selectingClinicalDirector"
                />
              </div>

              <div class="form-separator" />
              <div class="sub-title">Endereço</div>

              <div class="fourth-line grid-unit">
                <RgInputCep
                  id="cep"
                  ref="cep"
                  v-model="address.cep"
                  :rules="{ required: true, fn: validateCep }"
                  label="CEP"
                  placeholder="00.000-000"
                  :disabled="disabledByModal"
                  @blur="fillAddress"
                />
                <RgSuggestPlace
                  id="logradouro"
                  ref="place"
                  v-model="address.publicPlace"
                  :rules="{ required: isThereCEP }"
                  label="Logradouro"
                  placeholder="Digite e selecione o tipo"
                  :disabled="disablePublicPlace || disabledByModal"
                  :class="{ disable: disablePublicPlace }"
                  @selected="selectingPlace"
                />
                <RgInput
                  id="endereco"
                  ref="address"
                  v-model="address.name"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableAddress || disabledByModal"
                  :class="{ disable: disableAddress }"
                  :maxlength="100"
                  label="Endereço"
                  placeholder="Digite o nome do logradouro"
                />
                <RgInput
                  id="bairro"
                  ref="neighborhood"
                  v-model="address.neighborhood"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableNeighborhood || disabledByModal"
                  :class="{ disable: disableNeighborhood }"
                  :maxlength="100"
                  label="Bairro"
                  placeholder="Digite o nome do bairro"
                />
              </div>

              <div class="fifth-line grid-unit">
                <RgSuggestCity
                  id="cidade"
                  ref="city"
                  v-model="address.city"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableCity || isEdition || disabledByModal"
                  :class="{ disable: disableCity || isEdition }"
                  label="Cidade"
                  placeholder="Digite e selecione o nome da cidade"
                  @selected="selectingCity"
                />
                <RgSuggestState
                  id="uf"
                  ref="state"
                  v-model="address.state"
                  :rules="{ required: isThereCEP }"
                  :disabled="disableState || isEdition || disabledByModal"
                  :class="{ disable: disableState || isEdition }"
                  label="UF"
                  placeholder="Selecione"
                  @selected="selectingUf"
                />
                <RgInput
                  id="numero"
                  ref="number"
                  v-model="address.number"
                  :rules="{ required: true, addressNumber: true }"
                  :disabled="!isThereCEP || disabledByModal"
                  :class="{ disable: !isThereCEP }"
                  :maxlength="10"
                  label="Número"
                  placeholder="000000"
                />
                <RgInput
                  id="complemento"
                  v-model="address.complement"
                  :disabled="!isThereCEP || disabledByModal"
                  :class="{ disable: !isThereCEP }"
                  :maxlength="30"
                  label="Complemento"
                  placeholder="Ex.: casa/apto./ 000"
                />
                <RgInput
                  id="referencia"
                  v-model="address.reference"
                  :disabled="!isThereCEP || disabledByModal"
                  :class="{ disable: !isThereCEP }"
                  :maxlength="75"
                  label="Referência"
                  placeholder="Ex.: Comércio ou praça próximos ao domicílio"
                />
              </div>
            </div>
          </RgValidatorForm>
        </FormBase>

        <FormBase title="Setor" class="form-base form-sector">
          <RgValidatorForm ref="validatorSector">
            <div class="grid-sector">
              <Tooltip class="tooltip">
                <IconLightBulbHelper slot="icon" />
                <div slot="content" class="content">
                  <span class="title">
                    Os campos das seções são independentes:
                  </span>

                  <ul class="list">
                    <li class="item">
                      As alterações salvam automaticamente ao adicionar/editar;
                    </li>
                  </ul>
                </div>
              </Tooltip>
              <div class="second-line-sector grid-sector">
                <RgInput
                  id="setor-nome"
                  ref="sectorName"
                  v-model="sector.name"
                  :rules="{ required: true }"
                  :disabled="!hasUnitHealthId || disabledByModal"
                  :class="{ disable: !hasUnitHealthId }"
                  :maxlength="75"
                  label="Nome"
                  placeholder="Digite o nome do setor"
                />
                <RgInput
                  id="responsavel"
                  v-model="sector.responsible"
                  :disabled="!hasUnitHealthId || disabledByModal"
                  :class="{ disable: !hasUnitHealthId }"
                  :maxlength="70"
                  label="Responsável"
                  placeholder="Digite o nome do responsável"
                />
                <RgSelectDddInputPhone
                  id="numero-telefone"
                  ref="phone"
                  v-model="sector.telephone"
                  :disabled="!hasUnitHealthId || disabledByModal"
                  :class="{ disable: !hasUnitHealthId }"
                  @getDdd="getDddData"
                />
                <RgRadioCustom
                  id="sms"
                  ref="sms"
                  label="Envio de sms para notificar pacientes sobre agendamento"
                  v-bind="propsRadioCustom"
                  multSelect
                  :refreshValue="sector.sms"
                  :disableByComponent="disableByComponent"
                  @input="onInputRadioCustom"
                />

                <div class="actions">
                  <RgCleanButton
                    v-if="!isEditionSector"
                    id="limpar-setor"
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="cleanSector"
                  />
                  <RgAddButton
                    v-if="!isEditionSector"
                    id="adicionar-setor"
                    large
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="saveSector"
                  />

                  <RgCancelButton
                    v-if="isEditionSector"
                    id="cancela-edicao-setor"
                    medium
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="cancelEditSector"
                  />

                  <RgSaveButton
                    v-if="isEditionSector"
                    id="salvar-edicao-setor"
                    medium
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="saveEditSector"
                  />
                </div>
              </div>

              <div class="form-separator" />
              <div class="sub-title">Setores Cadastrados</div>

              <div
                :disabled="isEditionSector || disabledByModal"
                :class="{ disable: isEditionSector }"
                class="sector-list"
              >
                <div v-show="mutableSectorList.length === 0" class="no-result">
                  <IconEmpty class="empty-icon" />
                  <span class="info">
                    Não foram encontrados resultados para essa busca
                  </span>
                </div>

                <RgList
                  v-show="mutableSectorList.length > 0"
                  ref="rgList"
                  v-model="mutableSectorList"
                  class="list"
                  :show-total="false"
                >
                  <div class="table-menu">
                    <div class="legends">
                      <Tooltip
                        message="Para ativar ou inavitar os locais cadastrados
                      basta clicar nos botões “A” e “I” da coluna situação"
                        startOpen
                        class="icon-tooltip"
                      >
                        <IconLightBulbHelper slot="icon" />
                      </Tooltip>
                    </div>
                    <div class="top-buttons">
                      <RgEditButton
                        id="editar-setor-cadastrado"
                        :disabled="
                          !selectedSector || isEditionSector || isInactiveSector
                        "
                        @click="editSector"
                      />
                      <RgLessButton
                        id="remover-setor-cadastrado"
                        :disabled="
                          !selectedSector || isEditionSector || isInactiveSector
                        "
                        @click="openModalRemoveSector"
                      />
                    </div>
                  </div>

                  <RgTable ref="rgTable" :columns="headerSector" class="table">
                    <tr
                      v-for="(item, index) in mutableSectorList"
                      :key="index"
                      slot="rows"
                      class="tr row-content"
                      :class="{
                        selected: isSelectedRowSector(item, index),
                      }"
                      @click="getValueRowSector(item, index)"
                    >
                      <td
                        v-for="(col, id) in headerSector"
                        v-show="col.active"
                        :key="id"
                        class="result"
                        :style="{ 'text-align': col.align }"
                      >
                        <span v-if="col.key !== 'set_ativo'">
                          {{ item[col.key] || "-" }}
                        </span>
                        <div v-else class="status">
                          <Tooltip
                            :class="{ 'show-up': toShowUp(index, 'Sector') }"
                            :show-up="toShowUp(index, 'Sector')"
                            show-left
                            class="tooltip"
                          >
                            <SectorPlaceStatus
                              slot="icon"
                              :active="getStatusSector(item)"
                              @click="desativateActivateSector(item)"
                            />
                            <div slot="content" class="content">
                              <strong
                                v-if="getStatusSector(item)"
                                class="title"
                              >
                                Setor Ativo
                              </strong>
                              <strong v-else class="title">
                                Setor Inativo
                              </strong>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  </RgTable>
                </RgList>
              </div>
            </div>
          </RgValidatorForm>
        </FormBase>

        <FormBase title="Local de Atendimento" class="form-base form-place">
          <RgValidatorForm ref="validatorPlace">
            <div class="grid-place">
              <Tooltip class="tooltip">
                <IconLightBulbHelper slot="icon" />
                <div slot="content" class="content">
                  <span class="title">
                    Os campos das seções são independentes:
                  </span>

                  <ul class="list">
                    <li class="item">
                      As alterações salvam automaticamente ao adicionar/editar;
                    </li>
                  </ul>
                </div>
              </Tooltip>
              <div class="second-line-place grid-place">
                <RgSelectSector
                  id="setor"
                  ref="placeSector"
                  v-model="place.sector"
                  :unit-health="Number(unitHealthId)"
                  :rules="{ required: true }"
                  :disabled="
                    !hasUnitHealthId || isEditionPlace || disabledByModal
                  "
                  :class="{ disable: !hasUnitHealthId || isEditionPlace }"
                  label="Setor"
                  isUnitHealthRegister
                  placeholder="Selecione"
                />

                <RgInput
                  id="nome-local-atendimento"
                  ref="placeName"
                  v-model="place.name"
                  :rules="{ required: true }"
                  :disabled="!hasUnitHealthId || disabledByModal"
                  :class="{ disable: !hasUnitHealthId }"
                  :maxlength="45"
                  label="Nome"
                  placeholder="Digite o nome do local de atendimento"
                />
              </div>

              <div class="third-line grid-place">
                <RgSelectUnitHealthPep
                  id="unidade-pep"
                  ref="placeUnitHealthPEP"
                  v-model="place.unitHealthPEP"
                  :disabled="!hasUnitHealthId || disabledByModal"
                  :class="{ disable: !hasUnitHealthId }"
                  :rules="{ required: obligatorinessPep }"
                  label="Unidade de Saúde PEP"
                  default-text="Selecione a Unidade de Saúde PEP"
                  @change="changeUnitHealthPep"
                />
                <RgSelectSectorPep
                  id="setor-pep"
                  ref="placeSectorPEP"
                  v-model="place.sectorPEP"
                  :unit-health-code="unitHealthCodePEP"
                  :disabled="!place.unitHealthPEP || disabledByModal"
                  :class="{ disable: !place.unitHealthPEP }"
                  :rules="{ required: obligatorinessPep }"
                  label="Setor PEP"
                  default-text="Selecione o Setor PEP"
                  @change="changeSectorPep"
                />

                <RgSelectPlacePep
                  id="atendimento-pep"
                  ref="placePlacePEP"
                  v-model="place.placePEP"
                  :sector-code="sectorCodePep"
                  :disabled="!place.sectorPEP || disabledByModal"
                  :class="{ disable: !place.sectorPEP }"
                  :rules="{ required: obligatorinessPep }"
                  label="Local de Atendimento PEP"
                  default-text="Selecione o Local de Atendimento PEP"
                />

                <div class="actions">
                  <RgCleanButton
                    v-if="!isEditionPlace"
                    id="limpar-atendimento "
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="cleanPlace"
                  />

                  <RgAddButton
                    v-if="!isEditionPlace"
                    id="salvar-atendimento"
                    large
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="savePlace"
                  />

                  <RgCancelButton
                    v-if="isEditionPlace"
                    id="cancelar-edicao-atendimento"
                    medium
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="cancelEditPlace"
                  />

                  <RgSaveButton
                    v-if="isEditionPlace"
                    id="salvar-edicao-atendimento"
                    medium
                    :disabled="!hasUnitHealthId || disabledByModal"
                    @click="saveEditPlace"
                  />
                </div>
              </div>

              <div class="form-separator" />
              <div class="sub-title">Locais Cadastrados</div>

              <div
                :disabled="isEditionPlace || disabledByModal"
                :class="{ disable: isEditionPlace }"
                class="place-list"
              >
                <div v-show="mutablePlaceList.length === 0" class="no-result">
                  <IconEmpty class="empty-icon" />
                  <span class="info">
                    Não foram encontrados resultados para essa busca
                  </span>
                </div>

                <RgList
                  v-show="mutablePlaceList.length > 0"
                  ref="rgList"
                  v-model="mutablePlaceList"
                  class="list"
                  :show-total="false"
                >
                  <div class="table-menu">
                    <div class="legends">
                      <Tooltip
                        message="Para ativar ou inavitar os locais cadastrados
                      basta clicar nos botões “A” e “I” da coluna situação"
                        startOpen
                        class="icon-tooltip"
                      >
                        <IconLightBulbHelper slot="icon" />
                      </Tooltip>
                    </div>
                    <div class="top-buttons">
                      <RgEditButton
                        id="editar-setor-cadastrado"
                        :disabled="
                          !selectedPlace || isEditionPlace || isInactivePlace
                        "
                        @click="editPlace"
                      />
                      <RgLessButton
                        id="remover-setor-cadastrado"
                        :disabled="
                          !selectedPlace || isEditionPlace || isInactivePlace
                        "
                        @click="openModalRemovePlace"
                      />
                    </div>
                  </div>

                  <RgTable ref="rgTable" :columns="headerPlace" class="table">
                    <tr
                      v-for="(item, index) in mutablePlaceList"
                      :key="index"
                      slot="rows"
                      class="tr row-content"
                      :class="{
                        selected: isSelectedRowPlace(item, index),
                      }"
                      @click="getValueRowPlace(item, index)"
                    >
                      <td
                        v-for="(col, id) in headerPlace"
                        v-show="col.active"
                        :key="id"
                        class="result"
                        :style="{ 'text-align': col.align }"
                      >
                        <span v-if="col.key !== 'lca_ativo'">
                          {{ item[col.key] || "-" }}
                        </span>
                        <div v-else class="status">
                          <Tooltip
                            :class="{ 'show-up': toShowUp(index, 'Place') }"
                            :show-up="toShowUp(index, 'Place')"
                            show-left
                            class="tooltip"
                          >
                            <SectorPlaceStatus
                              slot="icon"
                              :active="getStatusPlace(item)"
                              @click="desativateActivatePlace(item)"
                            />
                            <div slot="content" class="content">
                              <strong v-if="getStatusPlace(item)" class="title">
                                Local Ativo
                              </strong>
                              <strong v-else class="title">
                                Local Inativo
                              </strong>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  </RgTable>
                </RgList>
              </div>
            </div>
          </RgValidatorForm>
        </FormBase>
      </form>
    </div>

    <div slot="footer" class="footer">
      <RgCleanButton
        v-if="!hideCleanButton"
        id="limpar-cadastro-unidade"
        :disabled="disabledByModal"
        @click="openModalCleanUnit"
      />

      <RgCancelButton
        id="cancelar-cadastro-unidade"
        medium
        :disabled="disabledByModal"
        @click="cancel"
      />

      <RgSaveButton
        id="salvar-cadastro-unidade"
        medium
        :disabled="disabledByModal"
        @click="editOrSaveUnitHealth"
      />
    </div>

    <ModalConfirmDeletion
      v-bind="propsModalDeletionSector"
      @close="closeModalRemoveSector"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>

    <ModalConfirmDeletion
      v-bind="propsModalDeletionPlace"
      @close="closeModalRemovePlace"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
    <ModalDeletingLink
      :show="showModalDeletingLink"
      :type="titleDeletingLink"
      :inactivationInfo="inactivationInfo"
      :sectorInfo="sectorInfo"
      :sectorInactivateLink="sectorInactivateLink"
      @save="inactivateActivateSectorPlace"
      @activate="activatePlaceSector"
      @close="closeModalDeletingLink"
    />
    <ModalConfirmInactivateActivate
      :show="showModalConfirmInactivateActivate"
      :type="titleDeletingLink"
      :activateInactivate="activateInactivate"
      :inactivationActivationInfo="inactivationInfo"
      @save="inactivateActivateSectorPlace"
      @close="closeModalConfirmInactivateActivate"
    />
    <ModalConfirmFieldClearing
      :show="showModalCleanUnit"
      @getYes="cleanUnit"
      @getOut="closeModalCleanUnit"
      @close="closeModalCleanUnit"
    />
  </Modulebox>

  <!-- </section> -->
</template>

<script>
import { mask } from "vue-the-mask";
import { isEmpty } from "lodash";
import {
  Modulebox,
  // SmartTable,
  FormBase,
  RgList,
  RgTable,
} from "~tokio/foundation";
import {
  RgSelectSector,
  RgValidatorForm,
  RgInput,
  RgInputCheckbox,
  RgInputCnpj,
  // RgInputPhone,
  RgSuggestEmployee,
  RgCancelButton,
  RgSaveButton,
  RgCleanButton,
  RgAddButton,
  RgEditButton,
  RgLessButton,
  Tooltip,
  IconLightBulbHelper,
  IconExclamationHelper,
  IconDanger,
  IconEmpty,
  ModalConfirmFieldClearing,
  ModalConfirmDeletion,
  RgRadioCustom,
} from "~tokio/primitive";
import CheckCEP from "$billing/billing/action/CheckCEP";
import SectorPlaceStatus from "./../components/sector-place-status/SectorPlaceStatus";
import RgInputCep from "$person/common/components/input/rg-input-cep/RgInputCep";
import RgSuggestPlace from "$person/common/components/suggest/rg-suggest-place/RgSuggestPlace";
import RgSuggestCity from "$person/common/components/suggest/rg-suggest-city/RgSuggestCity";
import RgSuggestState from "$person/common/components/suggest/rg-suggest-state/RgSuggestState";
import RgSelectDddInputPhone from "$person/common/components/input/rg-select-ddd-input-phone/RgSelectDddInputPhone";
import RgSelectUnitHealthSubTypeSus from "$unit-health/common/components/select/rg-select-unit-health-sub-type-sus/RgSelectUnitHealthSubTypeSus.vue";
import ModalDeletingLink from "./../components/modal/modal-deleting-link/ModalDeletingLink.vue";
import ModalConfirmInactivateActivate from "./../components/modal/modal-confirm-inactivate-activate/ModalConfirmInactivateActivate.vue";
import RgSelectUnitHealthTypeSus from "$unit-health/common/components/select/rg-select-unit-health-type-sus/RgSelectUnitHealthTypeSus.vue";
import RgSelectUnitHealthPep from "$unit-health/common/rg-select/rg-select-unit-health-pep/RgSelectUnitHealthPep";
import RgSelectSectorPep from "$unit-health/common/rg-select/rg-select-sector-pep/RgSelectSectorPep";
import RgSelectPlacePep from "$unit-health/common/rg-select/rg-select-place-pep/RgSelectPlacePep";

const NOT_TO_SEND = ["0"];
const APPOINTMENT_TYPE = ["1"];
const EXAM_TYPE = ["2"];
const BOTH_TYPE = ["1", "2"];

const FORM_UNIT = {
  name: "",
  cnes: "",
  issuingBody: "",
  type: null,
  subType: null,
  corporateName: "",
  cnpj: "",
  notificationName: "",
  category: false,
  clinicalDirector: "",
  clinicalDirectorId: null,
};

const FORM_ADDRESS = {
  cep: "",
  publicPlaceId: "",
  publicPlace: "",
  name: "",
  neighborhood: "",
  cityId: null,
  city: "",
  stateId: null,
  state: "",
  number: "",
  complement: "",
  reference: "",
};

const FORM_SECTOR = {
  name: "",
  responsible: "",
  set_setores_pe: "",
  set_tipos_atendimento_pep: "",
  telephone: "",
  ddd: "",
  sms: ["0"],
};

const FORM_PLACE = {
  sector: null,
  name: "",
  unitHealthPEP: null,
  sectorPEP: null,
  placePEP: null,
};

export default {
  name: "UnitHealthRegister",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    // SmartTable,
    RgList,
    RgTable,
    Tooltip,
    RgInput,
    RgInputCheckbox,
    RgInputCnpj,
    RgInputCep,
    IconEmpty,
    // RgInputPhone,
    RgSelectSector,
    RgSelectUnitHealthPep,
    RgSelectSectorPep,
    RgSelectPlacePep,
    RgSuggestEmployee,
    RgSuggestPlace,
    RgSuggestCity,
    RgSuggestState,
    RgCancelButton,
    RgSaveButton,
    RgCleanButton,
    RgAddButton,
    RgEditButton,
    RgLessButton,
    IconLightBulbHelper,
    IconExclamationHelper,
    IconDanger,
    ModalConfirmFieldClearing,
    ModalConfirmDeletion,
    RgSelectUnitHealthTypeSus,
    RgSelectDddInputPhone,
    RgSelectUnitHealthSubTypeSus,
    SectorPlaceStatus,
    ModalDeletingLink,
    ModalConfirmInactivateActivate,
    RgRadioCustom,
  },
  directives: {
    mask,
  },

  props: {},

  data() {
    return {
      unit: this.$utils.obj.DeepCopy(FORM_UNIT),
      address: this.$utils.obj.DeepCopy(FORM_ADDRESS),
      sector: this.$utils.obj.DeepCopy(FORM_SECTOR),
      place: this.$utils.obj.DeepCopy(FORM_PLACE),
      mutableSectorList: [],
      mutablePlaceList: [],
      selectedSector: null,
      selectedPlace: null,
      unitHealthId: null,
      sectorId: null,
      unitHealthData: {},
      sectorCodePep: null,
      unitHealthCodePEP: null,
      typeAttendance: null,
      hasAddress: false,
      hasPublicPlace: false,
      hasCity: false,
      hasState: false,
      hasNeighborhood: false,
      addressId: null,
      isEditionUnitHealth: false,
      isEditionSector: false,
      isEditionPlace: false,
      showModalCleanUnit: false,
      showModalRemoveSector: false,
      showModalRemovePlace: false,
      showModalDeletingLink: false,
      showModalConfirmInactivateActivate: false,
      hasSubTypeOptions: false,
      obligatorinessPep: false,
      errorMapPlacePep: false,
      activateInactivate: false,
      titleDeletingLink: "",
      inactivationInfo: "",
      sectorInfo: "",
      sectorInactivateLink: false,
      headerSector: [],
      headerPlace: [],
      selectedRowPlace: [],
      selectedRowSector: [],
      disableByComponent: BOTH_TYPE,
    };
  },

  computed: {
    receiveNotification() {
      return !!Number(
        this.$store.getters["Login/GET_PREFERENCES"][
          "tViewNotificacoes.rbt_enviar_sms"
        ],
      );
    },
    isInactivePlace() {
      return this.selectedPlace.lca_ativo === "0";
    },
    isInactiveSector() {
      return this.selectedSector.set_ativo === "0";
    },
    disabledByModal() {
      return (
        this.showModalCleanUnit ||
        this.showModalRemoveSector ||
        this.showModalRemovePlace
      );
    },
    isEdition() {
      return this.$route.name === "unit-health.register.edit";
    },

    hasUnitHealthId() {
      return this.unitHealthId && this.unitHealthId > 0;
    },

    isThereCEP() {
      return this.address?.cep?.length >= 9;
    },

    disablePublicPlace() {
      if (!this.isThereCEP || this.hasPublicPlace) {
        return true;
      }
      return false;
    },

    disableAddress() {
      if (!this.isThereCEP || this.hasAddress) {
        return true;
      }
      return false;
    },

    disableNeighborhood() {
      if (!this.isThereCEP || this.hasNeighborhood) {
        return true;
      }
      return false;
    },

    disableCity() {
      if (!this.isThereCEP || this.hasCity) {
        return true;
      }
      return false;
    },

    disableState() {
      if (!this.isThereCEP || this.hasState) {
        return true;
      }
      return false;
    },

    hideCleanButton() {
      return this.isEdition || this.isEditionUnitHealth;
    },

    propsModalDeletionSector() {
      const show = this.showModalRemoveSector;
      const noReason = true;
      const title = "Excluir Setor";
      const message = "A operação não poderá ser desfeita.";
      const msgSuccess = "Setor excluído com sucesso";
      const btnRemoveTitle = "Excluir";
      const confirm = () => this.removeSector();

      return {
        show,
        noReason,
        confirm,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },

    propsModalDeletionPlace() {
      const show = this.showModalRemovePlace;
      const noReason = true;
      const title = "Excluir Local de Atendimento";
      const message = "A operação não poderá ser desfeita.";
      const msgSuccess = "Local de Atendimento excluído com sucesso";
      const btnRemoveTitle = "Excluir";
      const confirm = () => this.removePlace();

      return {
        show,
        noReason,
        confirm,
        title,
        message,
        msgSuccess,
        btnRemoveTitle,
      };
    },
    propsRadioCustom() {
      const list = [
        { title: "Consultas", id: "1" },
        { title: "Exames", id: "2" },
        { title: "Não enviar", id: "0" },
      ];

      const value = this.sector.sms;

      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
  },

  watch: {
    "place.sector"(pValue, pPrev) {
      if (pValue || pValue !== pPrev) {
        this.sectorId = pValue;
      }
    },

    "unit.type"(pValue, pPrev) {
      const isToCleanSubType = !pValue || (pValue && pPrev !== null);

      if (isToCleanSubType) {
        this.unit.subType = null;
      }
    },

    "place.unitHealthPEP"(pValue, pPrev) {
      pValue && pValue.length > 0
        ? (this.obligatorinessPep = true)
        : (this.obligatorinessPep = false);

      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.typeAttendance = null;
        this.sectorCodePep = null;
        this.place.sectorPEP = null;
        this.place.placePEP = null;
      }
    },

    "address.cep"(pValue) {
      if (pValue) {
        const isEmptyCep = pValue.length === 0 || pValue === "";

        if (isEmptyCep) {
          this.cleanAddress();
        }
      } else {
        this.cleanAddress();
      }
    },
  },

  destroyed() {},

  created() {
    this.COLUMN_SECTOR = [
      { name: "Setor", key: "set_nome", align: "left", active: true },
      {
        name: "Profissional Responsável",
        key: "set_responsavel",
        align: "left",
        active: true,
      },
      {
        name: "Telefone",
        key: "telefone",
        align: "center",
        active: true,
      },
      { name: "Envio de SMS", key: "sms", align: "left", active: true },
      { name: "Situação", key: "set_ativo", active: true },
    ];

    this.COLUMN_PLACE = [
      { name: "Setor", key: "set_nome", align: "left", active: true },
      {
        name: "Local de Atendimento",
        key: "lca_nome",
        align: "left",
        active: true,
      },
      { name: "Mapeado com PEP", key: "mapeado_pep", active: true },
      { name: "Situação", key: "lca_ativo", active: true },
    ];
  },

  async mounted() {
    this.unitHealthData = this.$store.getters[
      "UnitHealth/GET_SELECTED_UNIT_HEALTH"
    ];

    if (this.isEdition && this.unitHealthData) {
      this.unitHealthId = this.unitHealthData.uns_id;

      this.fillUnitHealth(this.unitHealthData);
      this.searchSector(this.unitHealthId);
      this.searchPlace();
    }
    this.headerSector = this.COLUMN_SECTOR;
    this.headerPlace = this.COLUMN_PLACE;
  },

  methods: {
    onInputRadioCustom(item) {
      if (item) {
        this.sector.sms = item;
        this.disableByComponent = [];

        if (item.includes("0")) {
          this.disableByComponent = BOTH_TYPE;
        } else if (item.includes("1") || item.includes("2")) {
          this.disableByComponent = NOT_TO_SEND;
        } else {
          this.disableByComponent = [];
        }
      }
    },
    toShowUp(pItemIndex, type) {
      let mutableList = null;
      type === "Sector"
        ? (mutableList = this.mutableSectorList)
        : (mutableList = this.mutablePlaceList);
      if (mutableList.length > 1) {
        const listSize = mutableList.length;
        const rest = listSize - 1;
        return mutableList.length >= 2 && pItemIndex >= rest;
      }
      return true;
    },
    getStatusSector(pItem) {
      return pItem?.set_ativo === "1";
    },
    desativateActivateSector(pItem) {
      const permissionSector = this.$Permissions.global.has(
        "cadastro.setor.ativarInativar",
      );
      pItem?.set_ativo === "1"
        ? (this.activateInactivate = true)
        : (this.activateInactivate = false);
      if (!permissionSector) {
        this.$toaster.warning(
          `Você não possui permissão para ${
            this.activateInactivate ? "inativar" : "ativar"
          } o setor.`,
          "Usuário Sem Permissão!",
        );
        return;
      }

      this.selectedSector = pItem;
      this.inactivationInfo = this.selectedSector.set_nome;
      this.showModalConfirmInactivateActivate = true;
      this.titleDeletingLink = "Setor";
    },
    getStatusPlace(pItem) {
      return pItem?.lca_ativo === "1";
    },
    desativateActivatePlace(pItem) {
      const permissionPlace = this.$Permissions.global.has(
        "cadastro.localAtendimento.ativarInativar",
      );
      pItem?.lca_ativo === "1"
        ? (this.activateInactivate = true)
        : (this.activateInactivate = false);

      if (!permissionPlace) {
        this.$toaster.warning(
          `Você não possui permissão para ${
            this.activateInactivate ? "inativar" : "ativar"
          } o local de atendimento.`,
          "Usuário Sem Permissão!",
        );
        return;
      }
      this.selectedPlace = pItem;
      this.inactivationInfo = this.selectedPlace.lca_nome;
      this.showModalConfirmInactivateActivate = true;
      this.titleDeletingLink = "Local de Atendimento";
    },
    isSelectedRowSector(pItem, pIndex) {
      return this.selectedRowSector === pIndex;
    },
    isSelectedRowPlace(pItem, pIndex) {
      return this.selectedRowPlace === pIndex;
    },

    getDddData(pValue) {
      this.sector.ddd = pValue;
    },
    handleoptions(pValue) {
      const hasOptions = Array.isArray(pValue) && pValue?.length > 0;
      this.hasSubTypeOptions = hasOptions;
    },

    async formUnitHealthValid() {
      return this.$refs.validatorUnit
        ? this.$refs.validatorUnit.validate()
        : false;
    },

    async formSectorValid() {
      return this.$refs.validatorSector
        ? this.$refs.validatorSector.validate()
        : false;
    },

    async formPlaceValid() {
      return this.$refs.validatorPlace
        ? this.$refs.validatorPlace.validate()
        : false;
    },

    parseUnitHealthData() {
      const variables = {
        arrFormData: {
          uns_id_funcionarios: Number(this.unit.clinicalDirectorId),
          uns_cnes: this.unit.cnes,
          uns_cnpj: this.unit.cnpj.replace(/[^0-9]/gi, ""),
          uns_id_tipos_unidades_saude_sus: Number(this.unit.type),
          uns_id_sub_tipos_unidades_saude_sus: Number(this.unit.subType),
          uns_nome: this.unit.name,
          uns_orgao_emissor: this.unit.issuingBody,
          uns_razao_social: this.unit.corporateName,
          bai_id_municipios: Number(this.address.cityId),
          bai_nome: this.address.neighborhood,
          end_cep: this.address.cep.replace(/[^0-9]/gi, ""),
          end_complemento: this.address.complement,
          end_id_tipos_logradouros: Number(this.address.publicPlaceId),
          end_logradouro: this.address.name,
          end_numero: this.address.number,
          ddd: this.address.ddd,
          end_referencia: this.address.reference,
          est_id: Number(this.address.stateId),
          sub_nome: "",
          uns_id_categoria_unidades_saude: Number(this.unit.category),
          uns_nome_curto: this.unit.notificationName,
          exc_id: 0,
          uns_id_enderecos:
            this.isEdition || this.isEditionUnitHealth
              ? Number(this.addressId)
              : 0,
          end_id:
            this.isEdition || this.isEditionUnitHealth
              ? Number(this.addressId)
              : 0,
          uns_id:
            this.isEdition || this.isEditionUnitHealth
              ? Number(this.unitHealthId)
              : 0,
        },
      };

      return variables;
    },

    async saveUnitHealth() {
      try {
        if (!(await this.formUnitHealthValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }

        const variables = this.parseUnitHealthData();

        this.$loader.start("Salvando dados da unidade de saúde...");

        const response = await this.$store.dispatch(
          "UnitHealth/REGISTER_UNIT_HEALTH",
          {
            ...variables,
          },
        );

        if (response.dados) {
          this.isEditionUnitHealth = true;
          this.unitHealthId = response.dados;
          this.fillUnitHealth(this.unitHealthId);
          this.searchSector(this.unitHealthId);
          this.searchPlace();
        }
        this.$toaster.success("Unidade de saúde cadastrada com sucesso");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao cadastrar unidade de saúde");
      } finally {
        this.$loader.finish();
      }
    },

    async editUnitHealth() {
      try {
        const isValid = await this.formUnitHealthValid();

        if (!isValid) {
          this.$toaster.warning("Verifique os campos");

          return false;
        }

        const variables = this.parseUnitHealthData();

        this.$loader.start("Alterando dados da unidade de saúde...");

        await this.$store.dispatch("UnitHealth/EDIT_UNIT_HEALTH", {
          ...variables,
        });

        this.$toaster.success("Unidade de saúde alterada com sucesso");
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao alterar unidade de saúde");
      } finally {
        this.$loader.finish();
      }
    },

    async loadUnitHealth() {
      return await this.$store.dispatch("UnitHealth/LOAD_UNIT_HEALTH", {
        intIdUnidade: Number(this.unitHealthId),
      });
    },

    async fillUnitHealth(pUnit) {
      try {
        this.$loader.start("Carregando informações da unidade de saúde...");

        const data = await this.loadUnitHealth();

        if (pUnit) {
          this.unitHealthId = Number(data.uns_id);
          this.unit.name = data.uns_nome;
          this.unit.cnes = data.uns_cnes;
          this.unit.issuingBody = data.uns_orgao_emissor;
          this.unit.type = Number(data.uns_id_tipos_unidades_saude);
          this.unit.subType = Number(data.uns_id_sub_tipos_unidades_saude_sus);
          this.unit.category = Boolean(data.uns_id_categoria_unidades_saude);
          this.unit.corporateName = data.uns_razao_social;
          this.unit.cnpj = data.uns_cnpj;
          this.unit.notificationName = data.uns_nome_curto;

          this.addressId = Number(data.end_id);
          this.address.cep = data.end_cep;

          this.fillAddress(this.address.cep, data);

          if (this.$refs.clinicalDirector) {
            this.$refs.clinicalDirector.forceSelection({
              pes_nome: data.pes_nome,
              pes_id: data.pes_id,
            });
          }

          if (this.$refs.place) {
            this.$refs.place.forceSelection({
              tlg_id: Number(data.end_id_tipos_logradouros),
              tlg_nome: data.tlg_nome,
            });
          }

          if (this.$refs.city) {
            this.$refs.city.forceSelection({
              mun_id: data.mun_id,
              mun_nome: data.mun_nome,
            });
          }

          if (this.$refs.state) {
            this.$refs.state.forceSelection({
              est_id: data.est_id,
              est_sigla: data.est_sigla,
            });
          }

          this.address.name = data.end_logradouro;
          this.address.neighborhood = data.bai_nome;
          this.address.number = data.end_numero;
          this.address.complement = data.end_complemento;
          this.address.reference = data.end_referencia;
        }
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish(1000);
      }
    },

    async searchSector(pUnitId) {
      try {
        const variables = {
          arrFiltros: {
            uns_id: pUnitId,
            smd_id: 158,
          },
        };

        const result = await this.$store.dispatch("UnitHealth/SEARCH_SECTOR", {
          ...variables,
        });

        this.mutableSectorList = result.recordSet;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar setor");
      }
    },

    parseSectorData() {
      let formatNumber = "";
      let listaTelefones = {};

      if (
        this.sector.ddd?.ddd_codigo?.length > 0 &&
        this.sector.telephone?.length > 0
      ) {
        formatNumber = `${this.$refs.phone.dddItem.ddd_codigo}${this.$refs.phone.telephone}`;
      }

      if (formatNumber.length > 0) {
        const telephone = this.$refs.phone.telephone.replace("-", "");
        listaTelefones = [
          {
            ddd: this.sector.ddd.ddd_codigo,
            numero: telephone,
            ramal: "",
            recado: "",
          },
        ];
      } else {
        listaTelefones = {};
      }

      const sendSms =
        JSON.stringify(this.sector.sms) === JSON.stringify(NOT_TO_SEND) ? 0 : 1;

      let smsType = null;
      if (sendSms) {
        smsType =
          JSON.stringify(this.sector.sms) === JSON.stringify(APPOINTMENT_TYPE)
            ? "CONSULTAS"
            : JSON.stringify(this.sector.sms) === JSON.stringify(EXAM_TYPE)
            ? "EXAMES"
            : "AMBOS";
      }

      const variables = {
        arrFormData: {
          set_id: this.isEditionSector ? Number(this.selectedSector.set_id) : 0,
          set_id_unidades_saude: Number(this.unitHealthId),
          set_ativo: true,
          set_nome: this.sector.name.toUpperCase(),
          set_responsavel: this.sector.responsible,
          set_setores_pe: this.sector.set_setores_pe,
          set_tipos_atendimento_pep: this.sector.set_tipos_atendimento_pep,
          set_enviar_sms: sendSms,
          set_tipo_sms: smsType,
          listaTelefones,
        },
      };

      return variables;
    },

    async saveSector() {
      try {
        if (!(await this.formSectorValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
        if (this.sector.sms.length === 0) {
          this.$toaster.info("Escolha um tipo de envio de sms");
          return false;
        }

        const variables = this.parseSectorData();

        this.$loader.start("Salvando dados do setor...");

        await this.$store.dispatch("UnitHealth/REGISTER_SECTOR", {
          ...variables,
        });

        this.$toaster.success("Setor cadastrado com sucesso");

        this.cleanSector();
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.searchPlace();
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao cadastrar setor");
      } finally {
        this.$loader.finish();
      }
    },

    async editSector() {
      const data = await this.loadSector();

      const appointmentType = ["1"];
      const examType = ["2"];
      const bothType = ["1", "2"];
      const notToSend = ["0"];

      if (data.listaTelefones.length > 0) {
        const numeroTel = data.listaTelefones[0].numero;
        const numeroDdd = data.listaTelefones[0].ddd;

        this.$refs.phone.fillValue(numeroTel, numeroDdd);

        this.isEditionSector = true;

        let smsType = [];
        switch (data.set_tipo_sms) {
          case "CONSULTAS":
            smsType = appointmentType;
            this.disableByComponent = notToSend;
            break;
          case "EXAMES":
            smsType = examType;
            this.disableByComponent = notToSend;
            break;
          case "AMBOS":
            smsType = bothType;
            this.disableByComponent = notToSend;
            break;
          default:
            smsType = notToSend;
            this.disableByComponent = bothType;
            break;
        }

        this.sector = {
          name: data.set_nome.toUpperCase(),
          responsible: data.set_responsavel,
          set_setores_pe: data.set_setores_pe,
          set_tipos_atendimento_pep: data.set_tipos_atendimento_pep,
          ddd: this.$refs.phone.dddItem.ddd_codigo,
          telephone: this.$refs.phone.telephone,
          sms: smsType,
        };
      } else {
        this.isEditionSector = true;

        let smsType = [];
        switch (data.set_tipo_sms) {
          case "CONSULTAS":
            smsType = appointmentType;
            this.disableByComponent = notToSend;
            break;
          case "EXAMES":
            smsType = examType;
            this.disableByComponent = notToSend;
            break;
          case "AMBOS":
            smsType = bothType;
            this.disableByComponent = notToSend;
            break;
          default:
            smsType = notToSend;
            this.disableByComponent = bothType;
            break;
        }

        this.sector = {
          name: data.set_nome.toUpperCase(),
          responsible: data.set_responsavel,
          set_setores_pe: data.set_setores_pe,
          set_tipos_atendimento_pep: data.set_tipos_atendimento_pep,
          ddd: "",
          telephone: "",
          sms: smsType,
        };
      }
    },

    async saveEditSector() {
      try {
        if (!(await this.formSectorValid())) {
          this.$toaster.warning("Verifique os campos");
          return false;
        }
        if (this.sector.sms.length === 0) {
          this.$toaster.info("Escolha um tipo de envio de sms");
          return false;
        }

        const variables = this.parseSectorData();

        this.$loader.start("Alterando dados do setor...");

        await this.$store.dispatch("UnitHealth/EDIT_SECTOR", {
          ...variables,
        });

        this.$toaster.success("Setor alterado com sucesso");

        this.cleanSector();
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.searchPlace();
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao alterar setor");
      } finally {
        this.$loader.finish();
      }
    },

    async removeSector() {
      try {
        this.$loader.start();

        await this.$store.dispatch("UnitHealth/REMOVE_SECTOR", {
          intIdSetor: Number(this.selectedSector.set_id),
        });

        this.$toaster.success("Setor excluído com sucesso");

        this.cleanSector();
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.searchPlace();
      } catch (pErr) {
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          this.$toaster.error(esus_response.message, "Erro ao excluir setor");
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Erro ao excluir setor",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },

    async loadSector() {
      try {
        this.$loader.start();
        const hasSector = this.sectorId > 0;

        if (hasSector) {
          const result = await this.$store.dispatch("UnitHealth/LOAD_SECTOR", {
            intIdSetor: Number(this.sectorId),
          });

          return result;
        }
      } catch (e) {
        this.$toaster.error("Erro ao carregar os dados do setor");
      } finally {
        this.$loader.finish();
      }
    },

    async getValueRowSector(pSector, pIndex) {
      if (this.selectedRowSector === pIndex) {
        this.selectedSector = null;
        this.sectorId = null;
        this.selectedRowSector = null;
      } else {
        this.selectedSector = pSector;
        this.sectorId = pSector?.set_id;
        this.selectedRowSector = pIndex;
      }
    },
    async activateSector(pSector) {
      try {
        this.$loader.start();

        const sectorId = pSector || this.selectedSector.set_id;

        await this.$store.dispatch("UnitHealth/ACTIVATE_SECTOR", {
          intIdSetor: Number(sectorId),
        });
        this.$toaster.success("Setor ativado com sucesso");
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.selectedRowSector = null;
        this.selectedSector = null;
        this.searchPlace();
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr),
          "Erro ao ativar o setor",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async desactivateSector() {
      try {
        this.$loader.start();
        await this.$store.dispatch("UnitHealth/INACTIVATE_SECTOR", {
          intIdSetor: Number(this.selectedSector.set_id),
        });
        this.$toaster.success("Setor desativado com sucesso");
        this.searchSector(this.unitHealthId);
        this.$refs.placeSector.reSearchSector();
        this.selectedRowSector = null;
        this.selectedSector = null;
        this.searchPlace();
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr),
          "Erro ao desativar o setor",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async activatePlaceSector() {
      await this.activateSector(this.selectedPlace.set_id);
      await this.activatePlace();
      this.showModalDeletingLink = false;
    },

    async activatePlace() {
      try {
        this.$loader.start();
        await this.$store.dispatch("UnitHealth/ACTIVATE_PLACE", {
          intIdLocalAtendimento: Number(this.selectedPlace.lca_id),
        });

        this.$toaster.success("Local de atendimento ativado com sucesso");
        this.selectedRowPlace = null;
        this.selectedPlace = null;
        this.searchPlace();
      } catch (pErr) {
        const { esus_response } = pErr;
        this.showModalDeletingLink = true;
        this.titleDeletingLink = "Local de Atendimento";
        this.inactivationInfo = this.selectedPlace.lca_nome;
        this.sectorInactivateLink = true;
        this.sectorInfo = this.selectedPlace.set_nome;
        if (esus_response?.hasError) {
          this.$toaster.error(
            esus_response.message,
            "Erro ao ativar local de atendimento",
          );
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Erro ao ativar local de atendimento",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },
    async desactivatePlace() {
      try {
        this.$loader.start();
        await this.$store.dispatch("UnitHealth/INACTIVATE_PLACE", {
          intIdLocalAtendimento: Number(this.selectedPlace.lca_id),
        });
        if (this.selectedPlace.mapeado_pep === "SIM") {
          await this.$store.dispatch(
            "UnitHealth/REMOVE_MAP_PLACE_PEP",
            Number(this.selectedPlace.lca_id),
          );
        }
        this.selectedRowPlace = null;
        this.selectedPlace = null;
        this.$toaster.success("Local de atendimento desativado com sucesso");
        this.searchPlace();
      } catch (pErr) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(pErr),
          "Erro ao desativar o local de atendimento",
        );
      } finally {
        this.$loader.finish();
      }
    },

    inactivateActivateSectorPlace(type) {
      if (type === "Setor") {
        if (this.selectedSector.set_ativo === "1") {
          this.desactivateSector();
        } else {
          this.activateSector();
        }
      } else {
        if (this.selectedPlace.lca_ativo === "1") {
          this.desactivatePlace();
        } else {
          this.activatePlace();
        }
      }
      this.closeModalDeletingLink();
      this.closeModalConfirmInactivateActivate();
    },

    closeModalDeletingLink() {
      this.showModalDeletingLink = false;
    },
    closeModalConfirmInactivateActivate() {
      this.showModalConfirmInactivateActivate = false;
    },
    async searchPlace() {
      try {
        const variables = {
          arrFiltros: {
            smd_id: 157,
            uns_id: Number(this.unitHealthId),
            set_id: null,
          },
        };

        const result = await this.$store.dispatch("UnitHealth/SEARCH_PLACE", {
          ...variables,
        });

        const hasResult = result?.recordSet.length > 0;

        if (hasResult) {
          this.mutablePlaceList = result.recordSet;
        } else {
          this.mutablePlaceList = [];
        }
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao buscar local de atendimento");
      }
    },

    parsePlaceData() {
      const variables = {
        arrFormData: {
          lca_ativo: 1,
          lca_id: this.isEditionPlace ? this.selectedPlace.lca_id : 0,
          lca_id_setores: this.place.sector,
          lca_nome: this.place.name.toUpperCase(),
        },
      };

      return variables;
    },

    async savePlace() {
      try {
        const isValid = await this.formPlaceValid();

        if (!isValid) {
          this.$toaster.warning("Verifique os campos");

          return false;
        }

        const variables = this.parsePlaceData();

        this.$loader.start("Salvando dados do local de atendimento...");

        const response = await this.$store.dispatch(
          "UnitHealth/REGISTER_PLACE",
          {
            ...variables,
          },
        );

        const placeId = response.dados;

        const hasInformations =
          this.place.unitHealthPEP !== null &&
          this.place.sectorPEP !== null &&
          this.place.placePEP !== null;

        if (placeId && hasInformations) {
          await this.mapPlacePep(placeId);
        }

        this.$toaster.success("Local de atendimento cadastrado com sucesso");
        this.cleanPlace();
        this.searchPlace();
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao cadastrar local de atendimento");
      } finally {
        this.$loader.finish();
      }
    },

    async editPlace() {
      const data = await this.loadPlace();

      this.isEditionPlace = true;

      this.place = {
        sector: Number(data.set_id),
        name: data.lca_nome.toUpperCase(),
        unitHealthPEP: data.pel_codigo_unidades_saude || null,
        sectorPEP: data.pel_codigo_setores || null,
        placePEP: data.pel_codigo_locais_atendimento || null,
      };
    },

    async saveEditPlace() {
      try {
        const isValid = await this.formPlaceValid();

        if (!isValid) {
          this.$toaster.warning("Verifique os campos ");

          return false;
        }

        this.$loader.start("Alterando dados do local de atendimento...");

        const variables = this.parsePlaceData();

        const response = await this.$store.dispatch("UnitHealth/EDIT_PLACE", {
          ...variables,
        });

        const placeId = response.dados;

        const hasInformations =
          this.place.unitHealthPEP &&
          this.place.sectorPEP &&
          this.place.placePEP;

        const isMapped = this.selectedPlace.mapeado_pep === "SIM";

        if (placeId && !hasInformations && isMapped) {
          await this.removeMapPlacePep(this.selectedPlace.lca_id);
        } else if (placeId && hasInformations) {
          await this.mapPlacePep(this.selectedPlace.lca_id);
        }

        if (!this.errorMapPlacePep) {
          this.$toaster.success("Local de atendimento alterado com sucesso");
          this.cleanPlace();
          this.searchPlace();
        }
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao alterar local de atendimento");
      } finally {
        this.$loader.finish();
      }
    },

    async removePlace() {
      try {
        this.$loader.start();

        await this.$store.dispatch("UnitHealth/REMOVE_PLACE", {
          intIdLocalAtendimento: Number(this.selectedPlace.lca_id),
        });

        if (this.selectedPlace.mapeado_pep === "SIM") {
          await this.$store.dispatch(
            "UnitHealth/REMOVE_MAP_PLACE_PEP",
            Number(this.selectedPlace.lca_id),
          );
        }

        this.$toaster.success("Local de atendimento excluído com sucesso");
        this.cleanPlace();
        this.searchPlace();
      } catch (pErr) {
        const { esus_response } = pErr;

        this.showModalDeletingLink = true;
        this.titleDeletingLink = "Local de Atendimento";
        this.inactivationInfo = this.selectedPlace.lca_nome;
        if (esus_response?.hasError) {
          this.$toaster.error(
            esus_response.message,
            "Erro ao excluir local de atendimento",
          );
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(pErr),
            "Erro ao excluir local de atendimento",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },

    async loadPlace() {
      try {
        this.$loader.start();
        const result = await this.$store.dispatch("UnitHealth/LOAD_PLACE", {
          intIdLocalAtendimento: Number(this.selectedPlace.lca_id),
        });

        const {
          set_tipos_atendimento_pep,
          pel_codigo_unidades_saude,
          pel_codigo_setores,
        } = result;

        const hasTypeAttendance = set_tipos_atendimento_pep?.length > 0;

        this.typeAttendance = hasTypeAttendance
          ? set_tipos_atendimento_pep.toUpperCase()
          : null;

        this.unitHealthCodePEP = pel_codigo_unidades_saude;
        this.sectorCodePep = pel_codigo_setores;

        return result;
      } catch (e) {
        this.$toaster.error(
          "Erro ao carregar os dados do lodal de atendimento",
        );
      } finally {
        this.$loader.finish();
      }
    },

    async mapPlacePep(pPlaceId) {
      try {
        const variables = {
          uns_id: this.unitHealthId,
          set_id: this.sectorId,
          lca_id: Number(pPlaceId),
          uns_codigo_pep: this.place.unitHealthPEP,
          set_codigo_pep: this.place.sectorPEP,
          lca_codigo_pep: this.place.placePEP,
          set_tipo_setores_pep: this.typeAttendance.toUpperCase(),
        };

        await this.$store.dispatch("UnitHealth/MAP_PLACE_PEP", {
          ...variables,
        });

        this.errorMapPlacePep = false;
      } catch (pErr) {
        this.errorMapPlacePep = true;
        this.$toaster.error(pErr, "Erro ao mapear local de atendimento");
      }
    },

    async removeMapPlacePep(pPlaceId) {
      try {
        await this.$store.dispatch("UnitHealth/REMOVE_MAP_PLACE_PEP", pPlaceId);
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao remover mapeamento");
      }
    },

    getValueRowPlace(pPlace, pIndex) {
      if (this.selectedRowPlace === pIndex) {
        this.selectedPlace = null;

        this.selectedRowPlace = null;
      } else {
        this.selectedPlace = pPlace;

        this.selectedRowPlace = pIndex;
      }
    },

    editOrSaveUnitHealth() {
      this.isEdition || this.isEditionUnitHealth
        ? this.editUnitHealth()
        : this.saveUnitHealth();
    },

    changeUnitHealthPep(pUnitHealth) {
      if (pUnitHealth) {
        this.unitHealthCodePEP = pUnitHealth ? pUnitHealth.value : null;
      }
    },

    changeSectorPep(pSector) {
      if (pSector) {
        this.typeAttendance = pSector.type;
        this.sectorCodePep = pSector.value;
      } else {
        this.typeAttendance = null;
        this.sectorCodePep = null;
        this.place.placePEP = null;
      }
    },

    selectingClinicalDirector(pValue) {
      if (pValue) {
        const source = pValue && pValue.source;
        this.unit.clinicalDirectorId =
          source?.employeeRelationship?.vin_id_funcionarios;
      }
    },

    selectingPlace(pValue) {
      if (pValue) {
        const source = pValue && pValue.source;
        this.address.publicPlaceId = source?.tlg_id;
      }
    },

    selectingCity(pValue) {
      if (pValue) {
        const source = pValue && pValue.source;
        this.address.cityId = source?.mun_id;
        this.address.city = source?.mun_nome;
      }
    },

    selectingUf(pValue) {
      if (pValue) {
        const source = pValue;
        this.address.stateId = source?.est_id ? source.est_id : 0;
        this.address.state = source?.est_id ? source.est_id : 0;
      }
    },

    async fillAddress(pCep, pAddressData) {
      const currentCep = pCep ? pCep.replace(/[^0-9]/gi, "") : null;
      const isInvalidCep = currentCep ? currentCep.length !== 8 : true;

      if (isInvalidCep) return;

      try {
        this.$loader.start();
        const searchCep = await CheckCEP(currentCep);
        const hasCep = Object.keys(searchCep).length !== 0;

        if (hasCep) {
          this.address.cityId = searchCep.mun_id;
          this.address.city = searchCep.mun_nome;
          this.hasCity = !!searchCep.mun_nome;

          this.address.stateId = searchCep.est_id;
          this.address.state = searchCep.est_sigla;
          this.hasState = !!searchCep.est_sigla;

          if (pAddressData?.tlg_id > 0) {
            this.address.publicPlaceId = pAddressData.tlg_id;
            this.address.publicPlace = pAddressData.tlg_nome;
          } else {
            this.address.publicPlaceId = null;
            this.address.publicPlace = null;
            if (!this.address.publicPlaceId || searchCep.tlg_id !== null) {
              this.address.publicPlaceId = searchCep.tlg_id;
              this.address.publicPlace = searchCep.tlg_nome;
            }
          }
          this.$refs.place.forceSelection({
            tlg_id: searchCep.tlg_id ? searchCep.tlg_id : null,
            tlg_nome: searchCep.tlg_nome ? searchCep.tlg_nome : null,
          });
          this.hasPublicPlace = !!searchCep.tlg_nome;

          if (pAddressData?.end_logradouro?.length > 0) {
            this.address.name = pAddressData.end_logradouro;
          } else {
            this.address.name = null;
            if (!this.address.name || searchCep.logradouro !== null) {
              this.address.name = searchCep.logradouro
                ? searchCep.logradouro.toUpperCase()
                : "";
            }
          }
          this.hasAddress = !!searchCep.logradouro;

          if (pAddressData?.bai_nome?.length > 0) {
            this.address.neighborhood = pAddressData.bai_nome;
          } else {
            this.address.neighborhood = null;
            if (!this.address.neighborhood || searchCep.bairro !== null) {
              this.address.neighborhood = searchCep.bairro
                ? searchCep.bairro.toUpperCase()
                : "";
            }
          }
          this.hasNeighborhood = !!searchCep.bairro;

          return true;
        } else {
          this.cleanAddress();
          this.$toaster.warning("CEP não encontrado");
          return false;
        }
      } catch (pErr) {
        this.cleanAddress();
        this.$toaster.warning("CEP não encontrado");
      } finally {
        this.$loader.finish();
      }
    },

    validateCep(pCep, pErrors) {
      if (isEmpty(pCep)) {
        return true;
      }
      if (pCep.replace(/[^0-9]/gi, "").length !== 8) {
        pErrors.push("CEP inválido");
        return false;
      }

      return CheckCEP(pCep)
        .then((pCepData) => {
          return true;
        })
        .catch((pError) => {
          pErrors.push(`O CEP ${pCep} não existe`);
          return false;
        });
    },

    cancelEditSector() {
      this.cleanSector();
    },

    cancelEditPlace() {
      this.cleanPlace();
    },

    openModalRemoveSector() {
      this.showModalRemoveSector = true;
    },

    closeModalRemoveSector() {
      this.showModalRemoveSector = false;
    },

    openModalRemovePlace() {
      this.showModalRemovePlace = true;
    },

    closeModalRemovePlace() {
      this.showModalRemovePlace = false;
    },

    openModalCleanUnit() {
      this.showModalCleanUnit = true;
    },

    closeModalCleanUnit() {
      this.showModalCleanUnit = false;
    },

    cleanAddress() {
      this.address = this.$utils.obj.DeepCopy(FORM_ADDRESS);
    },

    cleanFieldsValidationUnit() {
      const fieldsRefs = ["unitName", "cnes", "unitTypeSus", "unitSubTypeSus"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cleanFieldsValidationAddressUnit() {
      const fieldsRefs = [
        "cep",
        "place",
        "address",
        "neighborhood",
        "city",
        "state",
        "number",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cleanFieldsValidationSector() {
      const fieldsRefs = ["sectorName"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cleanFieldsValidationPlace() {
      const fieldsRefs = ["placeName", "placeSector"];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cleanFieldsValidationPEP() {
      const fieldsRefs = [
        "placeUnitHealthPEP",
        "placeSectorPEP",
        "placePlacePEP",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },

    cleanUnit() {
      this.unit = this.$utils.obj.DeepCopy(FORM_UNIT);
      this.address = this.$utils.obj.DeepCopy(FORM_ADDRESS);

      this.mutableSectorList = [];
      this.mutablePlaceList = [];
      this.selectedSector = null;
      this.selectedPlace = null;
      this.unitHealthData = {};
      this.addressId = null;
      this.unitHealthId = null;
      this.isEditionUnitHealth = false;

      this.cleanFieldsValidationUnit();
      this.cleanFieldsValidationAddressUnit();

      this.cleanSector();
      this.cleanPlace();

      this.closeModalCleanUnit();
    },

    cleanSector() {
      if (this.$refs.sms) {
        this.$refs.sms.clearRadio();
        this.disableByComponent = BOTH_TYPE;
      }
      this.sector = this.$utils.obj.DeepCopy(FORM_SECTOR);
      this.$refs.phone.telephone = null;
      this.$refs.phone.dddItem = null;
      this.$refs.sectorName.cleanValidate();
      this.cleanFieldsValidationSector();
      this.isEditionSector = false;
      this.selectedRowSector = null;
      this.selectedSector = null;
    },

    cleanPlace() {
      this.place = this.$utils.obj.DeepCopy(FORM_PLACE);
      this.cleanFieldsValidationPlace();
      this.cleanFieldsValidationPEP();
      this.isEditionPlace = false;
      this.selectedRowPlace = null;
      this.selectedPlace = null;
      this.unitHealthCodePEP = null;
      this.sectorCodePep = null;
      this.typeAttendance = null;
    },

    cancel() {
      this.$router.push({ name: "unit-health.search" });
    },
  },
};
</script>
