import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import ExamDisabledPeriods from "$exam/submodules/schedule/views/exam-disabled-periods/ExamDisabledPeriods.vue";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/exam/schedule/disabled-periods",
  name: "exam.schedule.disabled-periods",
  components: {
    default: ExamDisabledPeriods,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Exame", link: OpenMenuModule("exam") },
      { label: "Agendamento", link: OpenSubModule("exam") },
      {
        label: "Períodos Inabilitados",
        link: "/exam/schedule/disabled-periods",
      },
    ],
  },
};
