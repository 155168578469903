<template lang="html">
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4238 0C6.68571 0 2.85714 3.83808 2.85714 8.57143H0L3.70951 12.2809L3.77616 12.419L7.61906 8.57143H4.76192C4.76192 4.89049 7.74763 1.90478 11.4286 1.90478C15.1095 1.90478 18.0952 4.89049 18.0952 8.57143C18.0952 12.2524 15.1095 15.2381 11.4286 15.2381C9.58571 15.2381 7.9238 14.4857 6.71906 13.2809L5.37143 14.6286C6.91906 16.1809 9.05714 17.1429 11.4238 17.1429C16.1619 17.1429 20 13.3048 20 8.57143C20 3.83808 16.1619 0 11.4238 0Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Reload",
};
</script>
