<template>
  <div class="rg-toggle-button" :class="{ center: center }">
    <span class="description">
      {{ description }}
    </span>

    <span v-if="externalLabel" class="external-label">
      {{ toogle ? truthyText : falsyText }}
    </span>

    <toggle-button
      :id="id"
      v-model="toogle"
      class="button"
      :class="{ 'margin-left-auto': marginAutoLeft }"
      :name="name"
      :value="value"
      :sync="valueSync"
      :speed="transitionSpeed"
      :disabled="disabled"
      :width="width"
      :height="height"
      :margin="margin"
      :font-size="fontSize"
      :css-colors="externalCss"
      :color="setBackgroundColor"
      :switch-color="disabled ? switchColorDisabled : setSwitchColor"
      :labels="setLabels"
      @change="handleChange"
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: "RgToggleButton",
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: [Boolean, Number],
      default: false,
    },

    valueSync: {
      type: Boolean,
      default: false,
    },

    marginAutoLeft: {
      type: Boolean,
      default: false,
    },

    transitionSpeed: {
      type: Number,
      default: 300,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    falsyText: {
      type: String,
      default: "",
    },

    truthyText: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    width: {
      type: Number,
      default: 32,
    },

    height: {
      type: Number,
      default: 12,
    },

    margin: {
      type: Number,
      default: -3,
    },

    fontSize: {
      type: Number,
      default: 15,
    },

    externalCss: {
      type: Boolean,
      default: false,
    },

    backgroundColorChecked: {
      type: String,
      default: "#80B3F0",
    },

    backgroundColorUnchecked: {
      type: String,
      default: "#C2C2C2",
    },

    backgroundColorDisabled: {
      type: String,
      default: "#CCCCCC",
    },

    switchColorChecked: {
      type: String,
      default: "#016EF1",
    },

    switchColorUnchecked: {
      type: String,
      default: "#A1A1A1",
    },

    switchColorDisabled: {
      type: String,
      default: "#CCCCCC",
    },

    name: {
      type: String,
      default: undefined,
    },

    center: Boolean,
    externalLabel: Boolean,
  },
  data() {
    return {
      toogle: this.value,
    };
  },

  computed: {
    setLabels() {
      const hasLabelsValues = this.truthyText && this.falsyText;
      const labels = { checked: this.truthyText, unchecked: this.falsyText };

      if (this.externalLabel) {
        return false;
      }

      return hasLabelsValues ? labels : false;
    },

    setBackgroundColor() {
      return {
        checked: this.backgroundColorChecked,
        unchecked: this.backgroundColorUnchecked,
        disabled: this.backgroundColorDisabled,
      };
    },

    setSwitchColor() {
      return {
        checked: this.switchColorChecked,
        unchecked: this.switchColorUnchecked,
      };
    },
  },

  watch: {
    value(pValue) {
      this.toogle = pValue;
    },
  },
  created() {
    this.toggle = this.value;
  },

  methods: {
    handleChange() {
      this.$emit("change", this.toogle);
    },
    handleInput() {
      this.$emit("input", this.toogle);
    },
  },
};
</script>
