<template lang="html">
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="14"
      cy="14"
      r="13"
      fill="white"
      stroke="#26789F"
      stroke-width="2"
    />
    <path d="M14.3125 18.9531H13V9H14.3125V18.9531Z" fill="#26789F" />
  </svg>
</template>

<script>
export default {
  name: "IndicationInactive",
};
</script>
