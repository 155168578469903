import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchPharmacySatelliteProductForPatientsReport(
    $unitHealth: Int
    $pharmacy: Int
    $product: Int
    $unitHealthName: String
    $pharmacyName: String
    $productName: String
    $mtp_id: Int
    $mtp_nome: String
    $initialDate: String
    $finalDate: String
    $loggedUserId: Int
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
    $columnsToPrint: [ColumnsToPrintInputType]
  ) {
    SearchPharmacySatelliteProductForPatientsReport(
      uns_id: $unitHealth
      far_id: $pharmacy
      mpd_id: $product
      uns_nome: $unitHealthName
      far_nome: $pharmacyName
      mpd_nome: $productName
      mtp_id: $mtp_id
      mtp_nome: $mtp_nome
      initialDate: $initialDate
      finalDate: $finalDate
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
      columnsToPrint: $columnsToPrint
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        CODIGO
        PRODUTO
        NOME_COMERCIAL
        UNIDADE_SAUDE
        FARMACIA
        CONSUMIDO
        APRESENTACAO
        TIPO
        PACIENTES
        MEDIA
        CONSUMO_DIARIO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchPharmacySatelliteProductForPatientsReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
