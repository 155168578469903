<style src="./RgInputPassword.scss" lang="scss" scoped></style>
<template lang="html">
  <fieldset class="rg-input--component">
    <RgFormBase :label="label" :required="isRequired">
      <div slot="right-label">
        <RgValidationAlert :alert="error" />
      </div>
      <div class="rg-input--base">
        <div class="rg-input--textbox-container">
          <input
            v-if="togglePassword"
            :id="id"
            v-model="inputValue"
            :autocomplete="autoComplete"
            :tabindex="tabIndex"
            type="text"
            class="rg-input--input"
            @blur="validateBlur"
          />
          <input
            v-else
            :id="id"
            v-model="inputValue"
            :autocomplete="autoComplete"
            :tabindex="tabIndex"
            type="password"
            class="rg-input--input"
            @blur="validateBlur"
          />
          <div
            v-if="showPassword"
            :style="eyeStyle"
            class="rg-input--show-button"
            @click="show"
          >
            <IconRgView v-if="togglePassword" class="icon-eye" />
            <IconNotView v-else class="icon-eye" />
          </div>
        </div>
      </div>
    </RgFormBase>
  </fieldset>
</template>

<script>
import { RgFormBase } from "~tokio/foundation/container";
import { RgValidatorMixin, RgValidationAlert } from "../../validation";
import RgInputMixin from "../mixin/RgInputMixin";
import { IconRgView, IconNotView } from "~tokio/primitive/icon";

export default {
  name: "RgInputPassword",
  components: {
    RgValidationAlert,
    RgFormBase,
    IconRgView,
    IconNotView,
  },
  mixins: [RgValidatorMixin, RgInputMixin],
  props: {
    showPassword: {
      type: Boolean,
      default: true,
    },
    label: {
      default: "Senha",
    },
    isAutoComplete: {
      type: Boolean,
      default: false,
    },
    eyeStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      togglePassword: false,
    };
  },
  computed: {
    autoComplete() {
      return this.isAutoComplete ? "new-password" : "off";
    },
    isRequired() {
      if (
        (this.rules && this.rules.required) ||
        (this.rules &&
          this.rules.compositeValue &&
          this.rules.compositeValue.required)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    show() {
      this.togglePassword = !this.togglePassword;
    },
    validateBlur() {
      this.validate();
    },
  },
};
</script>
