<template>
  <div v-if="show" class="modal-conflict-scale">
    <RgBaseModal
      ref="modal"
      v-shortkey="['esc']"
      :show="show"
      class="rg-base-modal"
      with-close-button
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="modal-conflict-scale-header">
        <div class="title">
          {{ title }}
        </div>
      </div>

      <div slot="body" class="modal-conflict-scale-body">
        <span class="information">{{ information }}</span>

        <FormBase title="Lista de Pacientes" class="service-list">
          <section class="attendances">
            <Tooltip
              class="tooltip"
              message="Selecione um dos pacientes para exibir os
              agendamentos que eles possuem"
              startOpen
            >
              <IconLightBulbHelper slot="icon" hollow />
            </Tooltip>

            <SmartTable
              ref="smartTable"
              name="ModalPatientCareHistory"
              :columns="COLUMN"
              :body="mutableAttendanceList"
              :initial-columns="4"
              :total="mutableAttendanceList.length"
              toggle-selected
              class="smart-table-patient-history"
              :show-pagination="false"
              @getLine="selectLine"
            />

            <div v-if="!hasSelectedLine" class="service-details">
              <IconInfoHelper class="icon" :background="'#6f8894'" />
              <span class="message"> DETALHES DOS AGENDAMENTOS </span>
            </div>
          </section>

          <section class="details">
            <section v-if="hasSelectedLine" class="appointment">
              <div
                v-for="item in selectedLine.conflicts"
                :key="item.pcc_id"
                class="schedule"
              >
                <span class="details-title"
                  >Agendamento: {{ item.ocp_nome || "NÃO INFORMADO" }}</span
                >
                <hr />
                <div class="details-area">
                  <div class="first-line">
                    <span>
                      Data:
                      <strong>{{
                        item.hag_data_consulta || "NÃO INFORMADO"
                      }}</strong>
                    </span>
                    <span>
                      Horário:
                      <strong>{{ item.con_hora || "NÃO INFORMADO" }}</strong>
                    </span>
                  </div>
                  <div class="second-line">
                    <span>
                      Unidade de Saúde:
                      <strong>{{ item.uns_nome || "NÃO INFORMADO" }}</strong>
                    </span>
                    <span>
                      Profissional:
                      <strong>{{ item.pes_nome || "NÃO INFORMADO" }}</strong>
                    </span>
                    <span>
                      Objetivo:
                      <strong>{{
                        item.tcu_novo_tipo_consulta || "NÃO INFORMADO"
                      }}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </FormBase>
      </div>
      <div slot="footer" class="footer">
        <RgCancelButton
          v-if="showSave"
          ref="cancelButton"
          medium
          class="cancel"
          @click="close"
        />
        <RgSaveButton
          v-if="showSave"
          ref="save"
          medium
          title="Salvar Agendamento"
          class="save"
          @click="save"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { FormBase, SmartTable } from "~tokio/foundation";

import {
  RgBaseModal,
  IconInfoHelper,
  IconLightBulbHelper,
  RgSaveButton,
  RgCancelButton,
} from "~tokio/primitive";

import Tooltip from "~tokio/primitive/tooltip/tooltip/Tooltip";

export default {
  name: "ModalConflictScale",
  components: {
    RgBaseModal,
    FormBase,
    SmartTable,
    RgSaveButton,
    RgCancelButton,
    IconInfoHelper,
    Tooltip,
    IconLightBulbHelper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Array,
      default: () => [],
    },
    scheduleInfo: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default:
        "Alguns pacientes já possuem consulta agendada no dia selecionado",
    },
    information: {
      type: String,
      default:
        "Verifique na lista abaixo os pacientes que possuem conflito de agendamento e os respectivos horários marcados",
    },
  },
  data() {
    return {
      mutableAttendanceList: [],
      attendanceQueueDetail: [],
      selectedLine: {},
    };
  },
  computed: {
    hasSelectedLine() {
      return this.selectedLine && Object.keys(this.selectedLine).length > 0;
    },
  },

  watch: {
    show(pValue) {
      if (!pValue) {
        this.close();
      } else {
        this.searchDetailsConflictsConsultation();
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Paciente", key: "patient", align: "left" },
      { name: "CPF", key: "cpf", align: "left" },
      { name: "Cartão SUS", key: "sus", align: "left" },
      { name: "Prontuário Único", key: "prontuario", align: "left" },
    ];
  },
  methods: {
    async searchDetailsConflictsConsultation() {
      try {
        this.$loader.start();

        const result = await this.$store.dispatch(
          "Appointment/Schedule/GET_CONFLICT_SCALE_INFO",
          this.scheduleInfo,
        );
        result.filter((data) => {
          data.forEach((item) => {
            if (item.consultado !== "0") {
              this.attendanceQueueDetail.push(item);
            }
          });
        });

        this.patientInfo.map((item) => {
          this.mutableAttendanceList.push({
            patient_id: item.pcc_id,
            patient: item.pes_nome,
            cpf: item.pep_cpf_numero,
            sus: item.pep_cartaosus,
            prontuario: item.pac_prontuario_unico,
          });
        });
        const aux = [];
        this.attendanceQueueDetail.forEach((item) => {
          this.mutableAttendanceList.forEach((data) => {
            if (!item.isArray) {
              if (Number(data.patient_id) === Number(item.pcc_id)) {
                const infosConflict = {
                  ...data,
                };
                infosConflict.conflicts = [];
                infosConflict.conflicts.push(item);
                aux.push(infosConflict);
              }
            } else {
              if (Number(data.patient_id) === Number(item[0].pcc_id)) {
                const infosConflict = {
                  ...data,
                  conflicts: item,
                };
                aux.push(infosConflict);
              }
            }
          });
        });
        this.mutableAttendanceList = aux.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      } catch (pErr) {
        this.$toaster.error(pErr);
      } finally {
        this.$loader.finish();
      }
    },

    selectLine(pValue) {
      this.selectedLine = pValue;
    },

    save() {
      this.$emit("save");
    },

    cleanFilter() {
      this.mutableAttendanceList = [];
      this.attendanceQueueDetail = [];
      this.selectedLine = {};
    },

    async close() {
      await this.cleanFilter();
      this.$emit("close");
    },
  },
};
</script>
