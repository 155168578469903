import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query {
    UploadLinkSigtapBdsia {
      status
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    throw err;
  });

  return data;
};
