<template>
  <Modulebox
    ref="modulebox"
    :title="
      isEditing
        ? 'Editar Solicitação de Transferência de Produtos e Medicamentos'
        : 'Solicitar Transferência de Produtos e Medicamentos'
    "
    class="pharmacy-central-transfer-request"
  >
    <div class="pharmacy-central-transfer-request-body">
      <RgValidatorForm ref="validator">
        <FormBase title="Dados da Solicitação">
          <section class="pharmacy-central-transfer-request-origin">
            <RgSelectUnithealthPharmacy
              ref="unitHealthOrigin"
              v-model="form.unitHealthOrigin"
              label="Unidade de Saúde (Origem)"
              :disabled="isEditing || disabledByModal"
              :class="{ disable: isEditing }"
              :rules="{ required: true }"
              @change="selectedUnitHealthOrigin"
            />
            <RgSelectPharmacy
              ref="pharmacyOrigin"
              v-model="form.pharmacyOrigin"
              label="Farmácia (Origem)"
              byUser
              :unitHealthId="form.unitHealthOrigin"
              :userId="userLoginId"
              :typePharmacy="CENTRAL_PHARMACY_TYPE"
              :rules="{ required: true }"
              :disabled="!form.unitHealthOrigin || isEditing || disabledByModal"
              :class="{ disable: !form.unitHealthOrigin || isEditing }"
              @change="selectedPharmacyOrigin"
            />
            <div></div>
            <RgInputDate
              ref="requestDate"
              v-model="form.requestDate"
              label="Data da Solicitação"
              :rules="{ required: true }"
              :class="{ disable: true }"
              :disabled="true"
              class="request-date"
            />
          </section>
          <hr class="separator" />
          <section class="pharmacy-central-transfer-request-destination">
            <RgSelectUnithealthPharmacy
              ref="unitHealthDestiny"
              v-model="form.unitHealthDestiny"
              :disabled="isEditing || disabledByModal"
              :class="{ disable: isEditing }"
              :rules="{ required: true }"
              :withPermission="routeEditing"
              label="Unidade de Saúde (Destino)"
              @change="selectedUnitHealthDestiny"
            />
            <RgSelectPharmacy
              ref="pharmacyDestiny"
              v-model="form.pharmacyDestiny"
              label="Farmácia (Destino)"
              :unitHealthId="form.unitHealthDestiny"
              :typePharmacy="CENTRAL_PHARMACY_TYPE"
              :rules="{ required: true }"
              :disabled="
                !form.unitHealthDestiny || isEditing || disabledByModal
              "
              :class="{ disable: !form.unitHealthDestiny || isEditing }"
              @change="selectedPharmacyDestiny"
            />
            <div></div>
            <RgInput
              ref="requestNumber"
              v-model="form.requestNumber"
              :rules="{ required: true }"
              disabled
              readonly
              :class="{ disable: true }"
              label="Número da Solicitação"
            />
          </section>
        </FormBase>

        <FormBase title="Produtos/Medicamentos">
          <section class="pharmacy-central-transfer-request-products">
            <Tooltip
              message="Só é possível incluir “Observações” no pedido após incluir um produto/medicamento."
              class="tooltip-info"
              startOpen
            >
              <IconLightBulbHelper slot="icon" />
            </Tooltip>
            <div class="pharmacy-central-transfer-request-products-form">
              <div class="form">
                <RgSelectProduct
                  ref="product"
                  v-model="form.product"
                  :disabled="
                    !(form.pharmacyOrigin && form.pharmacyDestiny) ||
                    disabledByModal
                  "
                  :class="{
                    disable: !(form.pharmacyOrigin && form.pharmacyDestiny),
                  }"
                  :pharmacyId="Number(form.pharmacyOrigin)"
                  :typeMovimentation="PRODUCTS_HAVE_ENTRIES"
                  :rules="{ required: true }"
                  withMovement
                  @change="selectedProduct"
                />
                <RgSelectTypePresentation
                  ref="typePresentation"
                  v-model="form.typePresentation"
                  label="Apresentação"
                  :rules="{ required: true }"
                  :disabled="true"
                  :class="{ disable: true }"
                />
                <div></div>
                <RgInput
                  ref="requestedQuantity"
                  v-model="form.requestedQuantity"
                  :rules="{ required: true, fn: validateAmount }"
                  label="Quantidade Solicitada"
                  placeholder="000000000"
                  :maxlength="10"
                  :disabled="disabledByModal"
                />
              </div>
              <div class="actions">
                <RgCleanButton
                  :disabled="disabledByModal"
                  @click="openModalConfirmField"
                />
                <RgAddButton
                  id="add-btn"
                  title="Adicionar Produto/Medicamento"
                  :disabled="disabledByModal"
                  @click="saveRequest"
                />
              </div>
            </div>

            <hr class="separator" />
            <div class="table">
              <SmartTable
                ref="smartTable"
                name="PharmacyProductsRequest"
                :columns="COLUMNS"
                :body="mutableProductList"
                :total="mutableProductList.length"
                :showPagination="false"
                :initial-columns="8"
                toggleSelected
                @getLine="selectLine"
              >
                <div slot="top-buttons" class="top-buttons">
                  <RgLessButton
                    id="excluir-solicitacao"
                    :permission="true"
                    small
                    title="Excluir Produto/Medicamento selecionado"
                    :disabled="!hasSelectedRow || disabledByModal"
                    @click="openModalDeleteProduct"
                  />
                  <RgPrinterButton
                    :disabled="mutableProductList.length < 1 || disabledByModal"
                    title="Imprimir Comprovante de Solicitação"
                    @click="printerRequest"
                  />
                </div>
              </SmartTable>
            </div>
          </section>
        </FormBase>
      </RgValidatorForm>
    </div>

    <div slot="footer" class="pharmacy-central-transfer-request-footer">
      <LargeButton
        backgroundColor="#1E88A9"
        label="Observações"
        separator
        :disabled="mutableProductList.length < 1 || disabledByModal"
        @click="openModalObservationTransferRequest"
      >
        <IconObservation slot="icon" class="icon" />
      </LargeButton>
      <MediumButton
        title="Voltar"
        :disabled="disabledByModal"
        label="Voltar"
        @click="goBack"
      />
    </div>
    <ModalObservationTransferRequest
      :show="showModalObservationTransferRequest"
      :requestData="mutableProductList"
      @close="closeModalObservationTransferRequest"
    />
    <ModalConfirmFieldClearing
      id="modal-confirm"
      ref="confirmClearing"
      :show="modalConfirmField"
      @getYes="cleanForm"
      @getOut="closeModalConfirmField"
      @close="closeModalConfirmField"
    />
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalDeleteProduct"
    >
      <div slot="icon" class="icon">
        <IconDanger />
      </div>
    </ModalConfirmDeletion>
  </Modulebox>
</template>

<script>
import {
  RgValidatorForm,
  RgInputDate,
  RgInput,
  RgAddButton,
  RgCleanButton,
  RgLessButton,
  RgPrinterButton,
  MediumButton,
  IconObservation,
  LargeButton,
  Tooltip,
  IconLightBulbHelper,
  ModalConfirmFieldClearing,
  ModalConfirmDeletion,
} from "~tokio/primitive";

import { Modulebox } from "~tokio/foundation";
import moment from "moment";

import { mapGetters } from "vuex";

import FormBase from "~tokio/foundation/form-base/FormBase";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";

import RgSelectUnithealthPharmacy from "$pharmacy/common/select/rg-select-unithealth-pharmacy/RgSelectUnithealthPharmacy";
import RgSelectPharmacy from "$pharmacy/common/select/rg-select-pharmacy/RgSelectPharmacy";
import RgSelectProduct from "$pharmacy/common/select/rg-select-product/RgSelectProduct";
import RgSelectTypePresentation from "$pharmacy/common/select/rg-select-type-presentation/RgSelectTypePresentation";
import ModalObservationTransferRequest from "$pharmacy/submodules/central/components/modal/modal-observation-transfer-request/ModalObservationTransferRequest";
import TransferRequestHTML from "$pharmacy/submodules/central/html/TransferRequestHTML";

const FORM = {
  product: null,
  requestedQuantity: null,
  requestDate: moment().format("DD/MM/YYYY"),
  requestNumber: "0",
  pharmacyOrigin: null,
  pharmacyDestiny: null,
  unitHealthOrigin: null,
  unitHealthDestiny: null,
};

const TABLE = {
  code: null,
  product: null,
  typePresentation: null,
  type: null,
  lot: null,
  pharmacyOrigin: null,
  pharmacyDestiny: null,
  unitHealthDestiny: null,
  unitHealthOrigin: null,
  stock: null,
  amountReturned: null,
  lotExpiration: null,
};

export default {
  name: "PharmacyCentralTransferRequest",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgSelectPharmacy,
    RgSelectProduct,
    RgSelectUnithealthPharmacy,
    RgSelectTypePresentation,
    RgInputDate,
    RgInput,
    RgCleanButton,
    RgAddButton,
    RgLessButton,
    RgPrinterButton,
    MediumButton,
    SmartTable,
    ModalObservationTransferRequest,
    IconObservation,
    LargeButton,
    Tooltip,
    IconLightBulbHelper,
    ModalConfirmFieldClearing,
    ModalConfirmDeletion,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM),
      table: this.$utils.obj.DeepCopy(TABLE),
      mutableProductList: [],
      selectedRow: null,
      requestData: null,
      situation: null,
      showModalObservationTransferRequest: false,
      isEditing: false,
      modalConfirmField: false,
      modalDeleteMovement: false,
    };
  },

  computed: {
    ...mapGetters({
      userLoginId: "Login/GET_USER_ID",
      clientName: "Login/GET_CLIENT_NAME",
      userName: "Login/GET_USER_NAME",
      unitHealth: "Login/GET_UNIT_HEALTH",
    }),
    disabledByModal() {
      return (
        this.modalConfirmField ||
        this.showModalObservationTransferRequest ||
        this.modalDeleteMovement
      );
    },
    propsModalConfirmDeletion() {
      const confirm = () => this.inactivateItem();

      const show = this.modalDeleteMovement;
      const noReason = true;

      const title = `Excluir Produto/Medicamento de Solicitação de Transferência`;
      const message = `Deseja remover o produto/medicamento?`;
      const msgSuccess = "Solicitação de transferência excluída com sucesso";
      const bodyText = `${this.selectedRow?.product} - ${this.selectedRow?.typePresentation} - ${this.selectedRow?.request}`;

      return {
        show,
        confirm,
        noReason,
        title,
        message,
        bodyText,
        msgSuccess,
      };
    },
    hasSelectedRow() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },
    routeEditing() {
      return this.$route.name === "pharmacy.central.edit-transfer-request";
    },
  },

  watch: {
    "form.unitHealthOrigin"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacyOrigin = null;
        this.form.product = null;
      }
    },
    "form.pharmacyOrigin"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.product = null;
      }
    },

    "form.unitHealthDestiny"(pValue, pPrev) {
      if (!pValue || (pValue && pPrev && pValue !== pPrev)) {
        this.form.pharmacyDestiny = null;
      }
    },

    "form.product"(pValue, pPrev) {
      if (!pValue) {
        this.form.typePresentation = null;
      }
    },
  },

  async mounted() {
    if (this.routeEditing) {
      const requestData = this.$route.params;
      this.situation = requestData.situacao;
      this.isEditing = true;
      this.mountBulletin(requestData);
    } else {
      await this.fillFilter();
    }
  },

  created() {
    this.COLUMNS = [
      { name: "Código", key: "code", align: "left", active: "true" },
      { name: "Nome", key: "product", align: "left", active: "true" },
      {
        name: "Apresentação",
        key: "typePresentation",
        align: "left",
        active: "true",
      },
      { name: "Tipo", key: "type", align: "left", active: "true" },
      { name: "Solicitado", key: "request", align: "left", active: "true" },
      { name: "Entregue", key: "delivered", align: "left", active: "true" },
      { name: "Saída", key: "output", align: "left", active: "true" },
      { name: "Status", key: "situation", align: "left", active: "true" },
      { name: "Unidade Origem", key: "unitHealthOrigin", align: "left" },
      { name: "Farmácia Origem", key: "pharmacyOrigin", align: "left" },
      { name: "Unidade Destino", key: "unitHealthDestiny", align: "left" },
      { name: "Farmácia Destino", key: "pharmacyDestiny", align: "left" },
      { name: "Número da Solicitação", key: "requestNumber", align: "left" },
    ];

    this.CENTRAL_PHARMACY_TYPE = 1;
    this.SATELLITE_PHARMACY_TYPE = 2;

    this.PRODUCTS_HAVE_ENTRIES = 1;
  },

  methods: {
    async fillFilter() {
      const existFilterData = this.$store.getters[
        "Pharmacy/Central/GET_FILTER_CENTRAL"
      ];

      if (existFilterData) {
        this.form.unitHealthOrigin = existFilterData.unitHealth;
        this.form.pharmacyOrigin = existFilterData.pharmacy;
      }
    },
    closeModalDeleteProduct() {
      this.modalDeleteMovement = false;
    },
    openModalDeleteProduct() {
      this.modalDeleteMovement = true;
    },
    closeModalConfirmField() {
      this.modalConfirmField = false;
    },

    openModalConfirmField() {
      this.modalConfirmField = true;
    },
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async searchItemsByRequest(pIdRequest) {
      try {
        this.$loader.start();

        const variables = {
          intIdSolicitacao: pIdRequest,
          // blnSomenteDisponiveis: true,
          // blnDispensacoes: false,
        };

        const result = await this.$store.dispatch(
          "Pharmacy/Central/SEARCH_ITENS_BY_REQUEST",
          variables,
        );

        const hasRequestNumber = Object.keys(result).length > 0;

        if (hasRequestNumber && result.dados.length > 0) {
          this.form.requestNumber = Number(result.dados[0].str_numero);
          result.dados[0].far_id_origem = this.form.pharmacyOrigin;
          this.requestData = result.dados[0];
          this.insertProductTable(result.dados);
        }
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao buscar quantidade de pedidos",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async printerRequest() {
      try {
        this.$loader.start();
        const variable = {
          intIdSolicitacao: this.requestData.str_id,
        };

        const situacao = this.isEditing ? this.situation : "TRANSF. ABERTA";
        const objRequest = {
          farmacia_origem: this.requestData.solicitante,
          farmacia_destino: this.requestData.atendente,
          str_data_pedido: this.requestData.str_data_pedido,
          str_numero: this.requestData.str_numero,
          usu_nome: this.requestData.usu_nome,
          situacao: situacao,
        };
        const orderItems = await this.$store.dispatch(
          "Pharmacy/Central/GET_PRINT_TRANSFER_REQUEST",
          variable,
        );

        const html = TransferRequestHTML(
          objRequest,
          orderItems,
          this.clientName,
          this.userName,
          this.unitHealth,
        );
        this.$utils.print.printHtml(html);
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao imprimir comprovante de solicitação",
        );
      } finally {
        this.$loader.finish();
      }
    },
    async mountBulletin(pRequest) {
      try {
        this.$loader.start();

        const request = await this.$store.dispatch(
          "Pharmacy/Central/GET_TRANSFER_REQUEST",
          { intSolicitacaoTransferencia: pRequest.str_id },
        );

        await this.searchItemsByRequest(request.str_id);

        this.form.unitHealthOrigin = Number(request.uns_origem);
        this.form.pharmacyOrigin = request.str_id_farmacia_origem;
        this.form.requestDate = request.str_data_pedido;

        this.form.unitHealthDestiny = Number(request.uns_destino);
        this.form.pharmacyDestiny = request.str_id_farmacia_destino;
        this.form.requestNumber = request.str_numero;
      } catch (pErr) {
        this.$toaster.error(pErr, "Erro ao carregar solicitação");
      } finally {
        this.$loader.finish();
      }
    },
    selectLine(pValue) {
      if (pValue && pValue !== this.selectedRow) {
        this.selectedRow = pValue;
      } else {
        this.selectedRow = null;
      }
      return this.selectedRow;
    },
    verifyDuplicate() {
      const filterDuplicate = this.mutableProductList.filter((pData) => {
        if (pData.code === this.table.code) return true;
      });

      return filterDuplicate;
    },
    async saveRequest() {
      if (!(await this.isFormValid())) {
        this.$toaster.warning("Verifique os campos.");
        return false;
      }
      if (
        Number(this.form.pharmacyDestiny) === Number(this.form.pharmacyOrigin)
      ) {
        this.$toaster.error(
          "A Farmácia Destino não pode ser igual a Farmácia Origem.",
          "Não foi possível solicitar a transferência",
        );
        return false;
      }
      const existsDuplicate = this.verifyDuplicate();
      if (existsDuplicate.length > 0) {
        this.$toaster.info(
          "É possível excluir o produto e adicioná-lo novamente com outra quantidade.",
          "O produto já faz parte do registro de Saída",
        );
        return false;
      }
      try {
        this.$loader.start();

        const variables = this.parseDataSave();

        const result = await this.$store.dispatch(
          "Pharmacy/Central/REGISTER_TRANSFER_REQUEST",
          variables,
        );

        if (result && result.dados > 0) {
          const requestId = result.dados;
          await this.searchItemsByRequest(requestId);
        }

        this.form.product = null;
        this.form.requestedQuantity = null;

        this.$toaster.success("Transferência solicitada com sucesso");
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Erro ao solicitar transferência",
        );
      } finally {
        this.$loader.finish();
        this.$refs.smartTable.cleanSelectRow();
        this.selectedRow = null;
      }
    },
    async inactivateItem() {
      try {
        this.$loader.start();
        const ist_id = this.selectedRow.ist_id;
        await this.$store.dispatch(
          "Pharmacy/Central/INACTIVATE_TRANSFER_REQUEST_ITEM",
          ist_id,
        );

        this.searchItemsByRequest(this.selectedRow.str_id);
        this.selectedRow = null;
        this.$toaster.success(
          "Solicitação de transferência excluída com sucesso",
        );

        // this.cleanForm();
      } catch (pErr) {
        const error = pErr.toString();

        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o registro",
        );
      } finally {
        this.$loader.finish();
        this.$refs.smartTable.cleanSelectRow();
      }
    },
    parseDataSave() {
      const variables = {
        // ped_id: , // Quando é a primeira solicitação vai como 0, a partir da segunda pega id.
        ist_id_municipios_produtos: this.form.product,
        ist_quantidade_solicitada: this.form.requestedQuantity,
        str_data_pedido: this.form.requestDate,
        str_id_farmacia_destino: this.form.pharmacyDestiny,
        str_id_farmacia_origem: this.form.pharmacyOrigin,
        str_numero: this.form.requestNumber,
      };

      return variables;
    },
    insertProductTable(pData) {
      this.mutableProductList = [];
      pData.map((item) => {
        const data = {
          str_id: item.str_id,
          code: item.mpd_codigo,
          product: item.mpd_novo_principio_ativo,
          far_id_origem: item.str_id_farmacia_origem,
          str_numero: item.str_numero,
          typePresentation: item.mtu_novo_nome,
          type: item.mtp_nome,
          request: item.ist_quantidade_solicitada,
          delivered: item.trs_quantidade,
          situation: item.std_nome,
          ist_id: item.ist_id,
          pharmacyOrigin: this.table.pharmacyOrigin,
          pharmacyDestiny: this.table.pharmacyDestiny,
          unitHealthDestiny: this.table.unitHealthDestiny,
          unitHealthOrigin: this.table.unitHealthOrigin,
          requestNumber: this.form.requestNumber,
        };
        this.mutableProductList.push(data);
      });
    },
    validateAmount(pValue, pErrors) {
      const hasSpecial = /[:]/.test(pValue);

      if (hasSpecial) {
        pErrors.push("O campo deve conter apenas valores numéricos");
        return false;
      }
      if (pValue && (Number(pValue) < 1 || Number(pValue) > 4294967295)) {
        pErrors.push("Informe um valor entre 0 e 4294967295.");
        return false;
      }

      return true;
    },
    selectedUnitHealthDestiny(pValue) {
      if (pValue) {
        this.table.unitHealthDestiny = pValue.label;
      } else {
        this.table.unitHealthDestiny = null;
      }
    },
    selectedUnitHealthOrigin(pValue) {
      if (pValue) {
        this.table.unitHealthOrigin = pValue.label;
      } else {
        this.table.unitHealthOrigin = null;
      }
    },
    selectedPharmacyOrigin(pPharmacy) {
      if (pPharmacy) {
        this.table.pharmacyOrigin = pPharmacy.far_nome;
      } else {
        this.table.pharmacyOrigin = null;
      }
    },
    selectedPharmacyDestiny(pPharmacy) {
      if (pPharmacy) {
        this.table.pharmacyDestiny = pPharmacy.far_nome;
      } else {
        this.table.pharmacyDestiny = null;
      }
    },
    openModalObservationTransferRequest() {
      this.showModalObservationTransferRequest = true;
    },
    closeModalObservationTransferRequest() {
      this.showModalObservationTransferRequest = false;
    },
    selectedProduct(pProduct) {
      if (pProduct) {
        this.form.typePresentation = pProduct.mtu_id;

        this.table.code = pProduct.mpd_codigo;
        this.table.product = pProduct.mpd_novo_principio_ativo;
        this.table.typePresentation = pProduct.mtu_novo_nome;
      } else {
        this.form.typePresentation = null;

        this.table.code = null;
        this.table.product = null;
        this.table.typePresentation = null;
      }
    },
    cleanForm() {
      if (this.isEditing) {
        this.form.product = null;
        this.form.requestedQuantity = null;
      } else {
        this.form = this.$utils.obj.DeepCopy(FORM);
        this.table = this.$utils.obj.DeepCopy(TABLE);
      }
      this.cleanFieldsValidation();
    },
    cleanFieldsValidation() {
      const fieldsRefs = [
        "unitHealthOrigin",
        "pharmacyOrigin",
        "requestDate",
        "unitHealthDestiny",
        "pharmacyDestiny",
        "product",
        "typePresentation",
        "requestedQuantity",
        "requestNumber",
      ];

      fieldsRefs.forEach((field) => {
        if (this.$refs[field]) {
          this.$refs[field].cleanValidate();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
