var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"quick-registration-bulletin"},[_c('form',{staticClass:"form-container",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveForm.apply(null, arguments)}}},[_c('RgValidatorForm',{ref:"validator"},[_c('FormBase',{staticClass:"form-base",attrs:{"title":"Paciente"}},[_c('div',{staticClass:"container-patient"},[(_vm.isPreRegistrationRoute && !_vm.isPatient)?_c('span',{staticClass:"name-pre-registration"},[_c('Tooltip',{staticClass:"tooltip",attrs:{"message":`Nome pré-cadastro: ${
                _vm.preRegistrationData && _vm.preRegistrationData.prc_nome
              }`,"icon-title":"Informações do Paciente","startOpen":""}},[_c('IconInfoHelper',{attrs:{"slot":"icon"},slot:"icon"})],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPatient),expression:"!isPatient"}],staticClass:"patient-not-identified",class:{ margin: _vm.isPreRegistrationRoute }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.patientNotIdentified),expression:"patientNotIdentified"}],class:{ disable: _vm.disablePatientNotIdentified },attrs:{"id":"patient-not-identified-qrb","disabled":_vm.disablePatientNotIdentified ||
                _vm.disabled ||
                _vm.disabledByModalComponent,"type":"checkbox"},domProps:{"value":true,"checked":Array.isArray(_vm.patientNotIdentified)?_vm._i(_vm.patientNotIdentified,true)>-1:(_vm.patientNotIdentified)},on:{"change":function($event){var $$a=_vm.patientNotIdentified,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=true,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.patientNotIdentified=$$a.concat([$$v]))}else{$$i>-1&&(_vm.patientNotIdentified=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.patientNotIdentified=$$c}}}}),_c('label',[_vm._v(" Paciente não identificado ")])]),_c('RgSuggestPatientInformation',{ref:"patientInfoSuggest",attrs:{"id":"patient-qrb","rules":{
              forceSelection: true,
              required: !_vm.patientNotIdentified,
            },"disabledOnlySuggest":!!_vm.hasPatientSelected,"disabled":_vm.disablePatient || _vm.patientNotIdentified || _vm.disabled,"with-patient-module":['emergency', 'hospitalization'],"extra-data":['withoutAddress'],"pac_prontuario_unico":_vm.patientInfo && _vm.patientInfo.prontuario_unico,"withPatientEmergency":true,"withPatientHospitalization":true},on:{"disabledByModalComponents":_vm.disabledByModalComponents,"patientInfo":_vm.getPatientData,"blockedPatient":function($event){return _vm.blockedPatient($event)}},model:{value:(_vm.patient),callback:function ($$v) {_vm.patient=$$v},expression:"patient"}})],1)]),_c('FormBase',{staticClass:"form-base",attrs:{"title":"Responsável"}},[_c('div',{staticClass:"container-responsible"},[_c('RgComboboxResponsibleType',{class:{ disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient },attrs:{"id":"responsible-type-qrb","disabled":_vm.sendNotIdentifiedToPep ||
              _vm.disabledPatient ||
              _vm.disabled ||
              _vm.disabledByModalComponent,"unidentifiedPatient":_vm.patientNotIdentified,"default-text":"Não informado"},on:{"select":_vm.selectedResponsibleType},model:{value:(_vm.form.responsibleType),callback:function ($$v) {_vm.$set(_vm.form, "responsibleType", $$v)},expression:"form.responsibleType"}}),(!_vm.form.responsibleType)?_c('RgInput',{class:{
              disable:
                _vm.sendNotIdentifiedToPep ||
                !_vm.form.responsibleType ||
                _vm.disabledPatient,
            },attrs:{"id":"responsible-name-qrb","disabled":_vm.disableResponsibleName ||
              _vm.sendNotIdentifiedToPep ||
              !_vm.form.responsibleType ||
              _vm.disabledPatient ||
              _vm.disabled ||
              _vm.disabledByModalComponent,"rules":{ required: false },"label":"Nome do Responsável "},model:{value:(_vm.form.responsibleName),callback:function ($$v) {_vm.$set(_vm.form, "responsibleName", $$v)},expression:"form.responsibleName"}}):_c('RgInput',{class:{
              disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient,
            },attrs:{"id":"responsible-name-qrb","disabled":_vm.disableResponsibleName ||
              _vm.sendNotIdentifiedToPep ||
              _vm.disabledPatient ||
              _vm.disabled ||
              _vm.disabledByModalComponent,"rules":{ required: true },"label":"Nome do Responsável "},model:{value:(_vm.form.responsibleName),callback:function ($$v) {_vm.$set(_vm.form, "responsibleName", $$v)},expression:"form.responsibleName"}})],1)]),_c('FormBase',{staticClass:"form-base",attrs:{"title":"Boletim"}},[_c('div',{staticClass:"container-bulletin"},[_c('div',{staticClass:"grid-bulletin"},[_c('div',{staticClass:"row-1 grid-bulletin"},[_c('RgInputDate',{class:{ disable: _vm.disableEdit || _vm.disabledPatient },attrs:{"id":"date-qrb","disabled":_vm.disableEdit ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"rules":{ fn: _vm.verifyRetroactiveDate },"max-date":new Date(),"label":"Data da Entrada"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('RgInputHour',{class:{ disable: _vm.disableEdit || _vm.disabledPatient },attrs:{"id":"hour-qrb","disabled":_vm.disableEdit ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"rules":{ fn: _vm.verifyRetroactiveHour },"label":"Hora da Entrada","placeholder":"hh:mm"},model:{value:(_vm.form.hour),callback:function ($$v) {_vm.$set(_vm.form, "hour", $$v)},expression:"form.hour"}}),_c('RgSelectUnithealth',{staticClass:"inputitem",class:{
                  disable: true,
                },attrs:{"id":"unit-qrb","label":"Unidade de Saúde","disabled":""},model:{value:(_vm.form.unit),callback:function ($$v) {_vm.$set(_vm.form, "unit", $$v)},expression:"form.unit"}}),_c('RgComboboxSectorPerUnitPep',{ref:"sector",class:{
                  disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient,
                },attrs:{"id":"sector-qrb","disabled":_vm.sendNotIdentifiedToPep ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"unitHealth":_vm.UNIT_HEALTH,"sectorType":"EMERGENCIAL","operationId":_vm.operationId,"active":true,"rules":{ required: true }},on:{"change":_vm.selectingSector},model:{value:(_vm.form.sector),callback:function ($$v) {_vm.$set(_vm.form, "sector", $$v)},expression:"form.sector"}}),_c('RgComboboxPlacePerSectorPep',{ref:"place",class:{
                  disable:
                    _vm.sendNotIdentifiedToPep || _vm.disabledPatient || !_vm.form.sector,
                },attrs:{"id":"place-qrb","disabled":_vm.sendNotIdentifiedToPep ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"sector":Number(_vm.form.sector),"active":true,"rules":{ required: true },"empty-default-text":'Selecione o setor'},model:{value:(_vm.form.place),callback:function ($$v) {_vm.$set(_vm.form, "place", $$v)},expression:"form.place"}}),_c('RgComboboxHealthPlan',{ref:"healthPlan",class:{
                  disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient,
                },attrs:{"id":"health-plan-qrb","disabled":_vm.sendNotIdentifiedToPep ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"rules":{ required: true }},model:{value:(_vm.form.healthPlan),callback:function ($$v) {_vm.$set(_vm.form, "healthPlan", $$v)},expression:"form.healthPlan"}})],1),_c('div',{staticClass:"row-2 grid-bulletin"},[_c('RgComboboxSpecialization',{class:{
                  disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient,
                },attrs:{"id":"specialization-qrb","disabled":_vm.sendNotIdentifiedToPep ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"default-text":"Não informado"},model:{value:(_vm.form.specialization),callback:function ($$v) {_vm.$set(_vm.form, "specialization", $$v)},expression:"form.specialization"}}),_c('RgComboboxArrivalType',{class:{
                  disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient,
                },attrs:{"id":"arrival-type-qrb","disabled":_vm.sendNotIdentifiedToPep ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"default-text":"Não informado"},model:{value:(_vm.form.arrivalType),callback:function ($$v) {_vm.$set(_vm.form, "arrivalType", $$v)},expression:"form.arrivalType"}}),_c('RgComboboxOccurrenceType',{class:{
                  disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient,
                },attrs:{"id":"occurrence-type-qrb","disabled":_vm.sendNotIdentifiedToPep ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"default-text":"Não informado"},model:{value:(_vm.form.occurrenceType),callback:function ($$v) {_vm.$set(_vm.form, "occurrenceType", $$v)},expression:"form.occurrenceType"}})],1),_c('div',{staticClass:"row-3 grid-bulletin"},[_c('RgTextArea',{class:{
                  disable: _vm.sendNotIdentifiedToPep || _vm.disabledPatient,
                },attrs:{"id":"observations-qrb","disabled":_vm.sendNotIdentifiedToPep ||
                  _vm.disabledPatient ||
                  _vm.disabled ||
                  _vm.disabledByModalComponent,"maxlength":255,"label":"Observações"},model:{value:(_vm.form.observations),callback:function ($$v) {_vm.$set(_vm.form, "observations", $$v)},expression:"form.observations"}})],1)])])])],1)],1),_c('ModalPatientNoWayOutBulletin',{ref:"modalPatientNoWayOutBulletin",attrs:{"show":_vm.showModalPatientNoWayOutBulletin,"list-no-way-out-bulletin":_vm.noWayOutBulletins},on:{"close":_vm.actionCloseModalNoWayOutBulletin,"save":_vm.actionSaveForm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }