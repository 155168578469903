import { render, staticRenderFns } from "./RgSuggestCboUnifiedTable.vue?vue&type=template&id=859f4564&scoped=true&"
import script from "./RgSuggestCboUnifiedTable.vue?vue&type=script&lang=js&"
export * from "./RgSuggestCboUnifiedTable.vue?vue&type=script&lang=js&"
import style0 from "./RgSuggestCboUnifiedTable.scss?vue&type=style&index=0&id=859f4564&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "859f4564",
  null
  
)

export default component.exports