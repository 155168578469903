<template>
  <div class="hospitalization-solicitation-search">
    <RgSearch
      ref="rgsearch"
      v-model="mutableSolicitationSearch"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :item-height="32"
      :max-register="30"
      :result-title="'Solicitações de Internação'"
      :show-empty-message="true"
      :showFooter="hasSolicitation"
      :disabled="disabledByModal"
      @afterPerformSearch="afterSearchFilter"
      @submitFromButton="actionCleanSelection"
      @count="getCountValue"
    >
      <div slot="filters" class="hospitalization-solicitation-search-filter">
        <div class="grid">
          <div class="selectinput">
            <RgInput
              id="patient"
              ref="patient"
              v-model="form.patientName"
              :disabled="disabledByModal"
              class="inputitem"
              placeholder="Digite o nome do paciente"
              label="Paciente"
            />
          </div>

          <div class="selectinput toggle">
            <span :class="{ active: form.unidentifiedPatient }" class="text"
              >Somente pacientes não identificados</span
            >
            <RgToggleButton
              id="unidentified-patient"
              class="input-toggle"
              :valueSync="true"
              :value="form.unidentifiedPatient"
              :disabled="disabledByModal"
              @change="handleToggleUnidentifiedPatient"
            />
          </div>

          <div class="selectinput">
            <RgInput
              id="bulletin"
              ref="bulletin"
              v-model="form.bulletin"
              :disabled="disabledByModal"
              class="inputitem"
              placeholder="Digite o número do boletim"
              label="Boletim"
            />
          </div>

          <div class="selectinput">
            <RgSuggestEmployee
              id="employee"
              ref="employee"
              v-model="professionalName"
              :disabled="disabledByModal"
              class="inputitem"
              label="Profissional Solicitante"
              placeholder="Digite o nome do profissional"
              @selected="selectingProfessional"
            />
          </div>

          <div class="filter-subtitle filter-title">
            <span class="period-subtitle">Período de Solicitação</span>
          </div>

          <div class="selectinput">
            <div class="period">
              <RgInputDate
                id="initial-date"
                v-model="form.initialDatePeriod"
                :rules="{ fn: verifyOrderInitialDate }"
                :disabled="disabledByModal"
                label="Data Inicial"
                class="date"
              />

              <RgInputDate
                id="final-date"
                v-model="form.finalDatePeriod"
                :rules="{ fn: verifyOrderFinalDate }"
                :disabled="disabledByModal"
                label="Data Final"
                class="date"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="hospitalization-solicitation-search-result">
        <div class="solicitation-list">
          <SmartTable
            ref="smartTable"
            name="HospitalizationSolicitationSearch"
            :columns="COLUMNS"
            :body="mutableSolicitationSearch"
            :total="Number(totalItems)"
            :initial-columns="7"
            :show-pagination="false"
            :disabled="disabledByModal"
            remove-btn-columns
            index-column="ins_id"
            toggle-selected
            class="smart-table"
            @getLine="selectLine"
          >
            <div slot="top-buttons" class="top-buttons">
              <RgLessButton
                id="remove-solicitation"
                :permission="canRemove"
                :disabled="!hasSelectedLine || disabledByModal"
                @click="openModalSolicitationDeletion"
              />
              <RgShowButton
                id="show-solicitation"
                :disabled="!hasSelectedLine || disabledByModal"
                @click="openModalSolicitationDetail"
              />
              <LargeButton
                id="new-hospitalization"
                :disabled="!hasSelectedLine || disabledByModal"
                label="Internar"
                title="Internar"
                :backgroundColor="'#1E88A9'"
                @click="hospitalizePatient"
              >
                <div slot="icon" class="icon">
                  <IconCross />
                </div>
              </LargeButton>
            </div>
          </SmartTable>
        </div>
      </div>
    </RgSearch>

    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      v-bind="propsModalConfirmDeletion"
      @close="closeModalConfirmSolicitationDeletion"
      @reSearch="reSearchTable"
    />

    <ModalSolicitationDetail
      id="modal-solicitation-detail"
      :show="modalSolicitationDetail"
      :solicitationData="hospitalizationSolicitation"
      @close="closeModalSolicitationDetail"
    />
  </div>
</template>

<script>
import {
  RgInput,
  RgInputDate,
  RgSuggestEmployee,
  RgShowButton,
  RgToggleButton,
  LargeButton,
  RgLessButton,
  ModalConfirmDeletion,
} from "~tokio/primitive";

import { mapGetters } from "vuex";
import { RgSearch, SmartTable } from "~tokio/foundation";
import { IconCross } from "~tokio/primitive/icon/symbols";

import moment from "moment";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb.js";
import ModalSolicitationDetail from "$hospitalization/hospitalizations/component/modal/modal-solicitation-detail/ModalSolicitationDetail";

const FORM = {
  patientName: null,
  bulletin: null,
  professionalId: null,
  initialDatePeriod: moment().subtract(30, "days").format("DD/MM/YYYY"),
  finalDatePeriod: moment().format("DD/MM/YYYY"),
  unidentifiedPatient: false,
};

export default {
  name: "HospitalizationSolicitationSearch",
  components: {
    RgSearch,
    SmartTable,
    RgInput,
    RgInputDate,
    RgSuggestEmployee,
    RgShowButton,
    RgLessButton,
    RgToggleButton,
    LargeButton,
    IconCross,
    ModalConfirmDeletion,
    ModalSolicitationDetail,
  },

  data() {
    return {
      mutableSolicitationSearch: [],
      hospitalizationSolicitation: {},
      professionalName: null,
      vin_id: null,
      form: this.$utils.obj.DeepCopy(FORM),
      totalItems: 0,
      modalConfirmSolicitationDeletion: false,
      modalSolicitationDetail: false,
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),

    permission() {
      return {
        hasPermissionRegisterSolicitation: !!this.$Permissions.global.has(
          "internacao.solicitacaoInternacoes.incluir",
          this.unitHealthId,
        ),
        hasPermissionRemoveSolicitation: !!this.$Permissions.global.has(
          "internacao.solicitacaoInternacoes.excluir",
          this.unitHealthId,
        ),
      };
    },

    canRegister() {
      return this.permission.hasPermissionRegisterSolicitation;
    },

    canRemove() {
      return this.permission.hasPermissionRemoveSolicitation;
    },

    hasSolicitation() {
      return this.mutableSolicitationSearch.length > 0;
    },

    hasSelectedLine() {
      return (
        this.hospitalizationSolicitation &&
        Object.keys(this.hospitalizationSolicitation).length > 0
      );
    },

    propsModalConfirmDeletion() {
      const maxlength = 250;
      const title = "Excluir Solicitação de Internação";
      const message = "A operação não poderá ser desfeita";
      const bodyText =
        this.hospitalizationSolicitation &&
        this.hospitalizationSolicitation.paciente;
      const show = this.modalConfirmSolicitationDeletion;
      const noReason = true;

      const confirm = (reason) => this.removeSolicitation(reason);

      return { show, title, message, bodyText, maxlength, confirm, noReason };
    },

    disabledByModal() {
      return (
        this.modalSolicitationDetail || this.modalConfirmSolicitationDeletion
      );
    },
  },

  watch: {},

  created() {
    this.COLUMNS = [
      { name: "Boletim", key: "bol_numero" },
      { name: "Paciente", key: "paciente", align: "left" },
      { name: "Profissional Solicitante", key: "profissional", align: "left" },
      { name: "Solicitação", key: "data_hora" },
    ];
  },

  mounted() {
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/hospitalization/hospitalization-solicitation/search",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit(
        "Hospitalization/Hospitalizations/RESET_FILTER_HOSPITALIZATION_SOLICITATION",
      );
    }
  },

  methods: {
    generateFilter() {
      const professionalName = this.professionalName;
      const vin_id = this.vin_id;

      const initialDate = this.form.initialDatePeriod
        ? moment(this.form.initialDatePeriod, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;

      const finalDate = this.form.finalDatePeriod
        ? moment(this.form.finalDatePeriod, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;

      const variables = {
        bol_numero: Number(this.form.bulletin),
        pes_nome: this.form.patientName,
        unidentifiedPatient: this.form.unidentifiedPatient,
        fun_id: this.form.professionalId,
        initialDateSolicitation: initialDate,
        finalDateSolicitation: finalDate,
      };

      this.$store.commit(
        "Hospitalization/Hospitalizations/SET_FILTER_HOSPITALIZATION_SOLICITATION",
        { professionalName, vin_id, ...this.form },
      );

      return variables;
    },

    afterSearchFilter() {
      if (this.$refs.smartTable) {
        this.$refs.smartTable.setScrollTopZero();
        this.$refs.smartTable.cleanSelectRow();
      }
      this.hospitalizationSolicitation = null;
    },

    async searchFilter(pData) {
      try {
        return this.$store.dispatch(
          "Hospitalization/Hospitalizations/SEARCH_HOSPITALIZATION_SOLICITATION",
          { ...pData },
        );
      } catch (Err) {
        this.$toaster.error(Err);
      }
    },

    async removeSolicitation() {
      try {
        const insId = this.hospitalizationSolicitation;
        await this.$store.dispatch(
          "Hospitalization/Hospitalizations/DELETE_HOSPITALIZATION_SOLICITATION",
          { ...insId },
        );

        this.reSearchTable();

        this.$toaster.success("Solicitação excluída com sucesso");
      } catch (error) {
        this.$toaster.error(error.message, [], "error");
      }
    },

    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Hospitalization/Hospitalizations/GET_FILTER_HOSPITALIZATION_SOLICITATION"
        ];

        if (existFilterData) {
          this.$loader.start("Carregando solicitações...");

          this.form.bulletin = existFilterData.bol_numero;
          this.form.patientName = existFilterData.pes_nome;
          this.form.professionalId = existFilterData.fun_id;
          this.form.initialDatePeriod = existFilterData.initialDatePeriod;
          this.form.finalDatePeriod = existFilterData.finalDatePeriod;
          this.form.unidentifiedPatient = existFilterData.unidentifiedPatient;

          if (this.$refs.employee) {
            this.$refs.employee.forceSelection({
              pes_nome: existFilterData.professionalName,
              vin_id: existFilterData.vin_id,
              employeeRelationship: {
                employee: {
                  fun_id: existFilterData.professionalId,
                },
              },
            });
          }

          this.reSearchTable();
        }
      } catch (Err) {
        this.$toaster.warning("Erro ao carregar os dados da navegação", Err);
      } finally {
        this.$loader.finish();
      }
    },

    async reSearchTable() {
      this.$refs.rgsearch.submitForm(false, true);
      if (this.$refs.smartTable) {
        this.$refs.smartTable.cleanSelectRow();
      }
    },

    async selectLine(pItem) {
      this.hospitalizationSolicitation = pItem;
    },

    handleToggleUnidentifiedPatient(pValue) {
      this.form.unidentifiedPatient = pValue;
    },

    hospitalizePatient() {
      const pData = this.hospitalizationSolicitation;

      this.$store.commit(
        "Hospitalization/Hospitalizations/SET_HOSPITALIZATION_SOLICITATION_INFO",
        { ...pData },
      );

      this.$router.push({
        name: "hospitalization.hospitalization-solicitation.register",
      });
    },

    selectingProfessional(pValue) {
      if (pValue && pValue.source) {
        this.professionalName = pValue.source.pes_nome;
        this.vin_id = pValue.source.vin_id;
        this.form.professionalId =
          pValue.source.employeeRelationship?.vin_id_funcionarios;
      } else {
        this.form.professionalId = null;
        this.professionalName = "";
      }
    },

    verifyOrderInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDatePeriod, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDatePeriod, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }

      if (!pValue && this.form.finalDatePeriod) {
        pErrors.push("A data é obrigatória");
        return false;
      }
    },

    verifyOrderFinalDate(pValue, pErrors) {
      if (!pValue && this.form.initialDatePeriod) {
        pErrors.push("A data é obrigatória");
        return false;
      }
    },

    getCountValue(pValue) {
      this.totalItems = Number(pValue) || 0;
    },

    actionCleanSelection() {
      this.hospitalizationSolicitation = {};
    },

    openModalSolicitationDeletion() {
      this.modalConfirmSolicitationDeletion = true;
    },

    closeModalConfirmSolicitationDeletion() {
      this.modalConfirmSolicitationDeletion = false;
    },

    openModalSolicitationDetail() {
      this.modalSolicitationDetail = true;
    },

    closeModalSolicitationDetail() {
      this.modalSolicitationDetail = false;
    },

    cleanForm() {
      this.mutableSolicitationSearch = [];
      this.hospitalizationSolicitation = {};
      this.professionalName = null;
      this.form = this.$utils.obj.DeepCopy(FORM);
      this.totalItems = 0;
    },
  },
};
</script>
