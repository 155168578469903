<template>
  <Modulebox
    ref="modulebox"
    title="Exportar Faturamento por Competência"
    class="export-billing"
  >
    <RgValidatorForm ref="validator" @submit.stop.prevent="exportBilling">
      <FormBase title="Dados da Remessa" class="form-shipping-export">
        <section class="group-form">
          <RgInput
            ref="cnes"
            v-model="form.cnes"
            label="CNES"
            :rules="{ required: true }"
            :disabled="true"
            :class="{ disable: true }"
          />
          <RgInput
            ref="unitHealth"
            v-model="form.unitHealth"
            label="Unidade de Saúde"
            :rules="{ required: true }"
            :disabled="true"
            :class="{ disable: true }"
          />
          <RgSelectCompetence
            ref="competence"
            v-model="form.competence"
            :rules="{ required: true }"
            :isFutureCompetence="false"
            isRegister
          />
        </section>
        <hr class="separator-1" />
        <span class="subtitle-export">Conteúdo</span>

        <Tooltip
          message="Selecione uma das opções de faturamento (BPA-C, BPA-I ou Todos) para ter acesso aos respectivos registros de procedimento"
          startOpen
          class="tooltip"
        >
          <IconLightBulbHelper slot="icon" />
        </Tooltip>

        <div class="subgroup-form">
          <div class="group-quantity-sheet-export-radio">
            <RgRadioCustom
              id="status"
              v-bind="propsRadio"
              :canUnselect="false"
              @input="onInputRadioCustom"
            />
          </div>
          <div class="group-quantity-sheet-export">
            <RgInputNumber
              ref="initBpai"
              v-model="form.initBpai"
              label="Inicio da contagem BPA-I"
              :rules="{ fn: minValueAmountBpai }"
              :disabled="LIST_GROUP_TYPES.bpac === selectTypeExport"
              :class="{
                disable: LIST_GROUP_TYPES.bpac === selectTypeExport,
              }"
              :maxlength="3"
              placeholder="000"
            />
            <RgInputNumber
              ref="initBpac"
              v-model="form.initBpac"
              label="Inicio da contagem BPA-C"
              :rules="{ fn: minValueAmountBpac }"
              :disabled="LIST_GROUP_TYPES.bpai === selectTypeExport"
              :class="{
                disable: LIST_GROUP_TYPES.bpai === selectTypeExport,
              }"
              :maxlength="3"
              placeholder="000"
            />

            <RgSelectTypeModuleExport
              id="type-module-export"
              v-model="form.moduleExport"
              :rules="{ required: true }"
            />
            <LargeButton
              id="import"
              label="Exportar Arquivo"
              :disabled="inProgress"
              :backgroundColor="'#1E88A9'"
              @click="exportBilling"
            >
              <IconArrowUpload slot="icon" />
            </LargeButton>

            <div
              class="export-loader"
              :class="{
                'export-loader-active': inProgress,
                'export-loader-desactive': !inProgress,
              }"
            >
              <font-awesome-icon
                icon="fa-solid fa-rotate fa-spin"
                :spin="inProgress"
                class="icon-loader"
                :class="{
                  'icon-loader-active': inProgress,
                  'icon-loader-desactive': !inProgress,
                }"
              />
              <span v-if="inProgress" class="text-in-progress">
                Aguarde. Criando o arquivo de remessa...
              </span>
            </div>
            <div v-if="!inProgress"></div>
          </div>
        </div>
      </FormBase>
    </RgValidatorForm>
    <FormBase
      title="Histórico de Arquivos de Remessa"
      class="history-file-export"
    >
      <div class="button-option">
        <LargeButton
          id="import"
          label="Baixar Arquivo Exportado"
          :backgroundColor="'#1E88A9'"
          :disabled="!fileOldDownload"
          @click="oldDownload"
        >
          <IconArrowDownload slot="icon" />
        </LargeButton>
      </div>
      <SmartTable
        ref="smartTable"
        name="BpaI"
        :columns="COLUMNS"
        :body="listExports"
        :initial-columns="10"
        :total="listExports.length"
        :showPagination="false"
        toggleSelected
        removeBtnColumns
        class="smart-table"
        @getLine="selectLine"
      >
      </SmartTable>
    </FormBase>
    <div slot="footer" class="export-billing-footer">
      <RgBackButton v-if="showBackButton" medium @click="goBack" />
    </div>
  </Modulebox>
</template>

<script>
import {
  RgInput,
  RgValidatorForm,
  Tooltip,
  IconLightBulbHelper,
  RgRadioCustom,
  LargeButton,
  RgBackButton,
  RgInputNumber,
  // RgInputDate,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import {
  IconArrowDownload,
  IconArrowUpload,
} from "~tokio/primitive/icon/symbols";
import { Modulebox } from "~tokio/foundation";
import FormBase from "~tokio/foundation/form-base/FormBase";
import {
  RgSelectCompetence,
  RgSelectTypeModuleExport,
} from "$billing/common/components/index";
import moment from "moment";

const FORM_SEARCH = {
  cnes: null,
  unitHealth: null,
  unitHealthId: null,
  competence: null,
  initBpac: 1,
  initBpai: 1,
  initialDate: null,
  finalDate: null,
  moduleExport: 3,
};

const GROUP_TYPES = {
  bpai: 1,
  bpac: 2,
  all: 3,
};

const MODULES_EXPORTS = {
  emergency: 1,
  outpatient: 2,
  all: 3,
};

export default {
  name: "ExportBilling",
  components: {
    Modulebox,
    FormBase,
    RgValidatorForm,
    RgInput,
    RgSelectCompetence,
    Tooltip,
    IconLightBulbHelper,
    RgRadioCustom,
    LargeButton,
    IconArrowDownload,
    IconArrowUpload,
    SmartTable,
    RgBackButton,
    RgInputNumber,
    RgSelectTypeModuleExport,
    // RgInputDate,
  },

  data() {
    return {
      form: this.$utils.obj.DeepCopy(FORM_SEARCH),
      selectTypeExport: 1,
      quantitySheetBpaI: 0,
      quantitySheetBpaC: 0,
      listExports: [],
      fileOldDownload: null,
      showBackButton: false,
      billingExported: null,
      inProgress: false,
      exportError: null,
      userId: this.$store.getters["Login/GET_USER_ID"],
    };
  },

  computed: {
    propsRadio() {
      const list = [
        { title: "Somente BPA-I", blue: true, id: this.LIST_GROUP_TYPES.bpai },
        { title: "Somente BPA-C", blue: true, id: this.LIST_GROUP_TYPES.bpac },
        { title: "Todos", blue: true, id: this.LIST_GROUP_TYPES.all },
      ];
      const value = this.selectTypeExport;
      const uniqueKey = "id";

      return { list, uniqueKey, value };
    },
    quantitySheet() {
      switch (this.selectTypeExport) {
        case this.LIST_GROUP_TYPES.bpai:
          return String((this.quantitySheetBpaI / 100).toFixed(2)).replace(
            ".",
            "",
          );
        case this.LIST_GROUP_TYPES.bpac:
          return String((this.quantitySheetBpaC / 100).toFixed(2)).replace(
            ".",
            "",
          );
        case this.LIST_GROUP_TYPES.all:
          return String(
            ((this.quantitySheetBpaI + this.quantitySheetBpaC) / 100).toFixed(
              2,
            ),
          ).replace(".", "");
        default:
          return "000";
      }
    },
    minValidDate() {
      const competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      const date = this.form.competence
        ? moment(this.form.competence, "YYYYMM").format("YYYY-MM-[02]")
        : moment(competence, "YYYYMM").format("YYYY-MM-[02]");
      return new Date(date);
    },
    maxValidDate() {
      const competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
      const date = this.form.competence
        ? moment(this.form.competence, "YYYYMM")
            .add(1, "months")
            .format("YYYY-MM")
        : moment(competence, "YYYYMM").add(1, "months").format("YYYY-MM");
      return new Date(date);
    },
  },

  watch: {
    "form.competence": {
      handler(pValue) {
        this.form.initBpac = 1;
        this.form.initBpai = 1;
        this.form.initialDate = null;
        this.form.finalDate = null;
      },
      deep: true,
      immediate: true,
    },
    selectTypeExport() {
      this.form.initBpac = 1;
      this.form.initBpai = 1;
      this.form.initialDate = null;
      this.form.finalDate = null;
    },
  },

  mounted() {
    const existRouteAutomatedShippingProduction =
      this.$route.name ===
      "billing.bpa.automated-shipping-production.export-billing";
    if (existRouteAutomatedShippingProduction) {
      this.showBackButton = true;
    }

    this.form.cnes = this.$store.getters["Login/GET_UNIT_HEALTH_CNES"];
    this.form.unitHealth = this.$store.getters["Login/GET_UNIT_HEALTH_NAME"];
    this.form.unitHealthId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
    this.form.competence = this.$store.getters["Billing/BDSia/GET_PERIOD_DATE"];
    this.searchHistoryBilling();
  },

  created() {
    this.COLUMNS = [
      {
        name: "Competência",
        key: "fex_competencia",
        align: "left",
        active: "true",
      },
      { name: "CNES", key: "fex_cnes", align: "left", active: "true" },
      {
        name: "Unidade de Saúde",
        key: "uns_nome",
        align: "left",
        active: "true",
      },
      {
        name: "Quantidade de Folhas",
        key: "fex_quantidade_folha",
        align: "left",
        active: "true",
      },
      {
        name: "Conteúdo",
        key: "fex_tipo_arquivo",
        align: "left",
        active: "true",
      },
      {
        name: "Inicio BPA-I",
        key: "fex_inicio_bpai",
        align: "left",
        active: "true",
      },
      {
        name: "Inicio BPA-C",
        key: "fex_inicio_bpac",
        align: "left",
        active: "true",
      },
      /* {
        name: "Data Inicial",
        key: "fex_data_inicio_exportacao",
        align: "left",
        active: "true",
      },
      {
        name: "Data Final",
        key: "fex_data_final_exportacao",
        align: "left",
        active: "true",
      }, */
      {
        name: "Data da Criação",
        key: "format_date",
        align: "left",
        active: "true",
      },
      {
        name: "Usuário",
        key: "usu_nome",
        align: "left",
        active: "true",
      },
    ];

    this.LIST_GROUP_TYPES = GROUP_TYPES;
    this.LIST_MODULES_EXPORTS = MODULES_EXPORTS;
  },
  methods: {
    async isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },
    async exportBilling() {
      const valid = await this.isFormValid();

      if (!valid) {
        this.$toaster.warning("Verifique os campos");
        return false;
      }

      try {
        this.inProgress = true;

        const actualList = await this.$store.dispatch(
          "Billing/ExportBilling/HISTORY_EXPORT_BILLING",
          {
            unitHealthId: String(this.form.unitHealthId),
            userId: this.userId,
            cmp: this.form.competence,
          },
        );
        this.verifyExport(actualList);

        this.initialDate = this.getInitialDate(this.form.competence);
        this.finalDate = this.getFinalDate(this.form.competence);

        this.billingExported = await this.$store.dispatch(
          "Billing/ExportBilling/EXPORT_BILLING",
          {
            variables: {
              exportBilling: {
                userId: this.userId,
                cmp: this.form.competence,
                idUns: String(this.form.unitHealthId),
                typeExport: this.selectTypeExport,
                moduleExport: this.form.moduleExport,
                cnes: this.form.cnes,
                initialDate: this.initialDate,
                finalDate: this.finalDate,
                initBpac:
                  this.selectTypeExport === this.LIST_GROUP_TYPES.bpai
                    ? 0
                    : Number(this.form.initBpac),
                initBpai:
                  this.selectTypeExport === this.LIST_GROUP_TYPES.bpac
                    ? 0
                    : Number(this.form.initBpai),
              },
            },
          },
        );
      } catch (error) {
        if (!error.message.includes("Timeout")) {
          this.exportError = { ...error };
        }
      }
    },
    getInitialDate(competence) {
      return this.$utils.date.CompetenceFirstDayToDatabase(competence);
    },
    getFinalDate(competence) {
      return (
        this.$utils.date
          .CompetenceLastDayToDatabase(competence)
          .format("YYYY-MM-DD") + " 23:59:59"
      );
    },
    async oldDownload() {
      try {
        this.$loader.start();
        const result = await this.$store.dispatch(
          "Billing/ExportBilling/DOWNLOAD_BILLING",
          {
            variables: {
              downloadBilling: {
                path: this.fileOldDownload,
              },
            },
          },
        );
        this.downloadFile(result.file, result.fileName);
      } catch (error) {
        if (error.message.includes("notExistExportFile")) {
          this.$toaster.info(
            "Faça uma nova exportação para gerar o arquivo da competência selecionada",
            "O arquivo de Faturamento está indisponível",
          );
        } else {
          this.$toaster.error(
            error.message,
            "Erro ao gerar arquivo de faturamento",
          );
        }
      } finally {
        this.$loader.finish();
      }
    },
    async searchHistoryBilling(dontLoad = false) {
      try {
        if (!dontLoad) this.$loader.start();
        const result = await this.$store.dispatch(
          "Billing/ExportBilling/HISTORY_EXPORT_BILLING",
          {
            unitHealthId: String(this.form.unitHealthId),
          },
        );
        this.listExports = this.formatRegisterHistory(result);
      } catch (error) {
        this.$toaster.error(
          error.message,
          "Problemas ao consultar histórico de exportação",
        );
      } finally {
        if (!dontLoad) this.$loader.finish();
      }
    },
    async verifyExport(actualList) {
      let result = actualList;
      while (result.length === actualList.length && !this.exportError) {
        result = await this.$store.dispatch(
          "Billing/ExportBilling/HISTORY_EXPORT_BILLING",
          {
            unitHealthId: String(this.form.unitHealthId),
            userId: this.userId,
            cmp: this.form.competence,
          },
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
      if (this.exportError) {
        if (this.exportError.message.includes("notExistRegister")) {
          this.$toaster.info(
            `É necessário criar uma remessa de procedimentos para exportar a produção.`,
            "O arquivo de Faturamento não foi criado",
          );
        } else if (this.exportError.message.includes("existExportBilling")) {
          this.$toaster.info(
            `Confira no histórico de remessas os detalhes do arquivo que foi gerado.`,
            "O arquivo de Faturamento já foi criado",
          );
        } else {
          this.$toaster.error(
            this.exportError.message,
            "Erro ao gerar arquivo de faturamento",
          );
        }
        this.exportError = null;
        this.inProgress = false;
        return;
      }
      if (this.billingExported) {
        this.downloadFile(
          this.billingExported.file,
          this.billingExported.fileName,
        );
      } else {
        this.downloadFile(result[0].file, result[0].fileName);
      }
      this.searchHistoryBilling(true);
      this.exportError = null;
      this.inProgress = false;
      this.$toaster.success(
        `A remessa de faturamento da competência “${moment(
          this.form.competence,
          "YYYYMM",
        )
          .format("MMM-YYYY")
          .toUpperCase()}” foi exportada.`,
        "Arquivo de Faturamento criado com Sucesso",
      );
    },
    formatRegisterHistory(list) {
      return list.map((element) => {
        element.format_date = moment(element.format_date).format(
          "DD/MM/YYYY - HH:mm",
        );
        element.fex_quantidade_folha = String(
          (element.fex_quantidade_folha / 100).toFixed(2),
        ).replace(".", "");
        element.fex_inicio_bpac =
          element.fex_inicio_bpac === 0
            ? "000"
            : String((element.fex_inicio_bpac / 100).toFixed(2)).replace(
                ".",
                "",
              );
        element.fex_inicio_bpai =
          element.fex_inicio_bpai === 0
            ? "000"
            : String((element.fex_inicio_bpai / 100).toFixed(2)).replace(
                ".",
                "",
              );
        element.fex_competencia = moment(
          element.fex_competencia,
          "YYYYMM",
        ).format("YYYY-MM");
        /* if (element.fex_data_inicio_exportacao) {
          element.fex_data_inicio_exportacao = moment(
            element.fex_data_inicio_exportacao,
          ).format("DD/MM/YYYY");
        }
        if (element.fex_data_final_exportacao) {
          element.fex_data_final_exportacao = moment(
            element.fex_data_final_exportacao,
          ).format("DD/MM/YYYY");
        } */

        return element;
      });
    },
    onInputRadioCustom(item) {
      if (item) {
        this.selectTypeExport = item.id;
      }
    },
    selectLine(pRegister) {
      if (pRegister) {
        this.fileOldDownload = pRegister.fex_arquivo_gerado;
      } else {
        this.fileOldDownload = null;
      }
    },
    minValueAmountBpac(pValue, pErrors) {
      if (Number(this.form.initBpac) <= 0) {
        pErrors.push(`Digite um inicio maior que 0`);
        return false;
      }
      return true;
    },
    minValueAmountBpai(pValue, pErrors) {
      if (Number(this.form.initBpai) <= 0) {
        pErrors.push(`Digite um inicio maior que 0`);
        return false;
      }
      return true;
    },
    downloadFile(value, name) {
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
