import LocalStorage from "~common/local-storage/LocalStorage";

const ScheduleObservationPatientQueue = (pAppointmentsData, pPrinterArray) => {
  const logo = LocalStorage.getObject("client-logo");
  const texto = "OBSERVAÇÕES DO PACIENTE NA FILA";

  const patientName =
    pAppointmentsData.pes_nome ||
    pAppointmentsData.nomePaciente ||
    "Não Informado";

  let dadosObservacoes = "";

  pPrinterArray.forEach((item) => {
    const reason = item?.fio_motivo || "Não informado";

    dadosObservacoes += '<div class="info-fila-observacao"> ';
    dadosObservacoes +=
      "<div><label>Data da inserção:</label> <span>" +
      item.data_observacao +
      "</span></div>";
    dadosObservacoes +=
      "<div><label>Data da hora:</label> <span>" +
      item.hora_observacao +
      "</span></div>";
    dadosObservacoes +=
      "<div><label>Usuário:</label> <span>" + item.usu_nome + "</span></div>";
    dadosObservacoes +=
      "<div><label>Motivo:</label> <span>" + reason + "</span></div>";
    dadosObservacoes +=
      "<div><label>Observação:</label> <span>" +
      item.fio_observacao +
      "</span></div>";
    dadosObservacoes += "</div>";
  });

  const html = `
  <!DOCTYPE html>
  <html>

  <head>
      <meta charset="utf-8">
      <title>Esus</title>
      <style type="text/css">
        body {
          font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
          /*background: rgb(204,204,204);*/
        }

        h1 {
          font-size: 12pt;
          text-transform: uppercase;
          text-align: center;
        }

        h2 {
          font-size: 11pt;
          font-style: italic;
          text-transform: uppercase;
        }

        h3 {
          font-size: 10pt;
          text-align: center;
          text-transform: uppercase;
        }

        hr {
          margin: 30px 0;
        }

        label,
        span,
        div,
        p {
          font-size: 11pt !important;
        }

        label {
          font-weight: 700;
        }

        page {
          background: white;
          display: block;
          margin: 0 auto;
          margin-bottom: 0.5cm;
          /*box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);*/
        }

        page[size="A4"] {
          width: 21cm;
          height: 29.7cm;
        }

        .logo-cliente {
          width: 70px;
          display: block;
          margin: 0 auto;
          padding-top: 0.5cm;
        }

        .info-fila div {
          margin-bottom: 6px;
        }

        .info-fila-observacao {
          border-top: 1px solid #888;
          padding: 5pt 0 0 0;
        }

        .ocultar {
          display: none;
        }

        .mostrar {
          display: block;
        }

        font {
          font-size: 11pt !important;
        }
      </style>
  </head>

  <body>
      <page size="A4">
          <div>
          <img class="logo-cliente" src="${logo}">
          <h1>${pAppointmentsData.client}</h1>

          <h2>${
            pAppointmentsData.uns_nome ||
            pAppointmentsData.unidade_fila ||
            pAppointmentsData.unidadeFila
          }</h2>
        </div>

        <div class="info-fila">
          <hr><h3>${texto}</h3><hr>
          <div><label>Paciente: </label> <span>${patientName}</span> </div>
          <div>${dadosObservacoes}</div>
        </div>
      </page>

  </body>

  </html>
    `;

  return html;
};

export default ScheduleObservationPatientQueue;
