import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query OccupationProcedure(
    $ocp_nome: String
    $ocp_codigo: String
    $co_procedimento: String
    $ocp_sigtap: Boolean
    $ocp_competence: String
    $limit: Int
  ) {
    occupationProcedure(
      ocp_nome: $ocp_nome
      ocp_codigo: $ocp_codigo
      co_procedimento: $co_procedimento
      ocp_sigtap: $ocp_sigtap
      ocp_competence: $ocp_competence
      limit: $limit
    ) {
      count
      rows {
        ocp_id
        ocp_nome
        ocp_codigo
      }
    }
  }
`;

export default async ({ variables }) => {
  const { data } = await GraphQLClient.query({
    query,
    variables,
    fetchPolicy: "no-cache",
  }).catch((err) => {
    console.error(err);
  });
  return data.occupationProcedure;
};
