import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import { OpenMenuModule } from "~common/utils/app/menu";

import UnitHealthSearchRoute from "$unit-health/view/search/UnitHealthSearch";

export default {
  path: "/unit-health/search",
  name: "unit-health.search",
  components: {
    default: UnitHealthSearchRoute,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Cadastro", link: OpenMenuModule("register") },
      { label: "Unidades de Saúde", link: "/unit-health/search" },
    ],
  },
};
