<template>
  <div class="pharmacy-provider">
    <RgSearch
      ref="rgsearch"
      v-model="mutablePharmacyProvider"
      :result-title="'Fornecedores'"
      :build-filter="generateFilter"
      :search-function="searchFilter"
      :clear-function="cleanForm"
      :searchAreaHeight="tableHeight"
      :item-height="32"
      :max-register="30"
      :disabled="disabledByModal"
      @count="getCountValue"
      @submitFromButton="cleanSelectRow"
      @viewClickPagination="cleanSelectRow"
      @afterPerformSearch="afterSearchFilter"
    >
      <div slot="filters" class="pharmacy-provider-filter">
        <div class="grid">
          <div class="selectinput">
            <RgSelectProvider
              id="pharmacy-provider"
              ref="provider"
              v-model="form.frd_nome"
              class="inputitem"
              :class="{ disable: hasCpf || hasCnpj }"
              :disabled="hasCpf || hasCnpj"
              @change="selectingProvider"
            />
          </div>
          <div class="selectinput">
            <RgInputCpf
              id="cpf"
              v-model="form.frd_cpf"
              class="inputitem"
              label="CPF"
              placeholder="000.000.000-00"
              :class="{ disable: hasProvider || hasCnpj }"
              :disabled="hasProvider || hasCnpj"
            />
          </div>
          <div class="selectinput">
            <RgInputCnpj
              id="cnpj"
              v-model="form.frd_cnpj"
              class="inputitem"
              label="CNPJ"
              :class="{ disable: hasProvider || hasCpf }"
              :disabled="hasProvider || hasCpf"
            />
          </div>
          <div class="selectinput">
            <RgComboboxTypePerson
              id="type-person"
              ref="person"
              v-model="form.typePerson"
              class="inputitem"
            />
          </div>
          <div class="selectinput">
            <RgComboboxSituation
              id="situation"
              ref="radio"
              v-model="form.frd_ativo"
              class="inputitem"
            />
          </div>
        </div>
      </div>

      <div slot="menu-top" class="top-button">
        <RgAddButton
          v-if="!hasMutablePharmacyProvider"
          id="new-registration"
          :permission="hasPermissionCreateProvider"
          large
          label="Novo"
          title="Cadastrar Fornecedor"
          :disabled="disabledByModal"
          @click="openNewProvider"
        />
      </div>
      <div class="pharmacy-provider-search-list">
        <SmartTable
          ref="smartTable"
          name="PharmacyProvider"
          :columns="COLUMNS"
          :body="mutablePharmacyProvider"
          :dynamic-height="getSpaceTable"
          :total="Number(total)"
          :initial-columns="7"
          index-column="frd_id"
          toggle-selected
          :showPagination="false"
          class="smart-table"
          @getLine="selectLine"
        >
          <div slot="top-buttons" class="actions">
            <div class="right-buttons">
              <RgAddButton
                id="cadastrar-fornecedor"
                :permission="hasPermissionCreateProvider"
                small
                class="btns"
                title="Cadastrar Fornecedor"
                :disabled="disabledByModal"
                @click="openNewProvider"
              />

              <RgEditButton
                id="editar-fornecedor"
                :permission="hasPermissionEditProvider"
                small
                title="Editar Fornecedor"
                :disabled="
                  !hasSelectedLine || hasSelectedLineInactive || disabledByModal
                "
                @click="editPharmacyProvider"
              />

              <RgLessButton
                id="excluir-fornecedor"
                :permission="hasPermissionDeleteProvider"
                small
                title="Excluir Fornecedor"
                :disabled="
                  !hasSelectedLine || hasSelectedLineInactive || disabledByModal
                "
                @click="openModalProvider"
              />
            </div>
          </div>
        </SmartTable>
      </div>
      <div
        v-if="hasMutablePharmacyProvider"
        slot="menu-bottom"
        class="painel-button"
      >
        <div class="others-btn">
          <SmallButton
            id="inativar"
            :permission="hasPermissionEnableDisableProvider"
            small
            title="Inativar Fornecedor"
            class="bottom"
            :backgroundColor="'#1e88a9'"
            :disabled="
              !(hasSelectedLine && hasSelectedLineActive) || disabledByModal
            "
            @click="openModalDesactiveProvider"
          >
            <IconDesactivate slot="icon" />
          </SmallButton>
          <SmallButton
            id="ativar"
            :permission="hasPermissionEnableDisableProvider"
            small
            title="Ativar Fornecedor"
            class="bottom"
            :backgroundColor="'#1e88a9'"
            :disabled="
              !(hasSelectedLine && hasSelectedLineInactive) || disabledByModal
            "
            @click="openModalActiveProvider"
          >
            <IconActivate slot="icon" />
          </SmallButton>
        </div>
      </div>
    </RgSearch>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      :show="showModalDeleteProvider"
      noReason
      title="Excluir o Fornecedor Selecionado"
      message="Deseja excluir esse fornecedor/fabricante?"
      msgSuccess="Fornecedor excluído com sucesso!"
      :bodyText="bodyText"
      :confirm="deleteProviderItem"
      @close="closeModalProvider"
      @reSearch="reSearch"
    />
    <ModalActiveDesactive
      :show="showModalActiveDesactive"
      :title="titleActiveDesactive"
      :subtitle="messageActiveDesactive"
      :msgSuccess="msgSuccessActiveDesactive"
      :bodyText="bodyText"
      :isActive="isActive"
      :labelBtn="labelBtnActiveDesactive"
      :confirm="activeDesactiveProviderItem"
      :textReason="textReason"
      @reason="reasonActiveDesactiveProvider"
      @close="closeActiveDesactive"
      @reSearch="reSearch"
    />
  </div>
</template>

<script>
import RgSearch from "~tokio/foundation/rg-search/RgSearch";
import RgInputCpf from "$person/common/components/input/rg-input-cpf/RgInputCpf";
import {
  RgInputCnpj,
  RgEditButton,
  RgAddButton,
  RgLessButton,
  SmallButton,
  ModalConfirmDeletion,
  ModalActiveDesactive,
  RgComboboxTypePerson,
  RgComboboxSituation,
  IconActivate,
  IconDesactivate,
} from "~tokio/primitive";
import SmartTable from "~tokio/foundation/smart-table/SmartTable";
import RgSelectProvider from "$pharmacy/common/select/rg-select-provider/RgSelectProvider";
import { mapGetters } from "vuex";
import ValidateIfRouteExistInBreadscrumb from "~common/utils/ValidateIfRouteExistInBreadscrumb";

export default {
  name: "PharmacyProvider",
  components: {
    RgSearch,
    SmartTable,
    RgInputCnpj,
    RgInputCpf,
    RgEditButton,
    RgAddButton,
    RgLessButton,
    RgComboboxTypePerson,
    RgComboboxSituation,
    SmallButton,
    IconActivate,
    IconDesactivate,
    ModalConfirmDeletion,
    ModalActiveDesactive,
    RgSelectProvider,
  },

  data() {
    return {
      form: {
        frd_nome: null,
        frd_id: null,
        frd_cnpj: null,
        frd_cpf: null,
        frd_ativo: null,
        typePerson: null,
      },
      showModalDeleteProvider: false,
      titleActiveDesactive: "",
      messageActiveDesactive: "",
      msgSuccessActiveDesactive: "",
      labelBtnActiveDesactive: "",
      mutablePharmacyProvider: [],
      editPharmacyProviderRow: null,
      isEdit: false,
      isActive: false,
      showModalActiveDesactive: false,
      total: 0,
      tableHeight: null,
      selectedRow: null,
      bodyText: null,
      textReason: "",
    };
  },

  computed: {
    ...mapGetters({
      unitHealthId: "Login/GET_UNIT_HEALTH_ID",
    }),
    getSpaceTable() {
      return this.tableHeight;
    },
    hasMutablePharmacyProvider() {
      return !(
        this.mutablePharmacyProvider &&
        this.mutablePharmacyProvider.length === 0
      );
    },
    hasSelectedLine() {
      return this.selectedRow && Object.keys(this.selectedRow).length > 0;
    },
    hasSelectedLineActive() {
      return this.selectedRow && this.selectedRow.situacao === "ATIVO";
    },
    hasSelectedLineInactive() {
      return this.selectedRow && this.selectedRow.situacao === "INATIVO";
    },
    hasCpf() {
      return this.form.frd_cpf !== null && this.form.frd_cpf !== "";
    },
    hasCnpj() {
      return this.form.frd_cnpj !== null && this.form.frd_cnpj !== "";
    },
    hasProvider() {
      return this.form.frd_nome !== null && this.form.frd_nome !== "";
    },
    hasPermissionCreateProvider() {
      return !!this.$Permissions.global.has(
        "farmacia.cadastroFornecedor.incluir",
      );
    },
    hasPermissionEditProvider() {
      return !!this.$Permissions.global.has(
        "farmacia.cadastroFornecedor.alterar",
      );
    },
    hasPermissionDeleteProvider() {
      return !!this.$Permissions.global.has(
        "farmacia.cadastroFornecedor.excluir",
      );
    },
    hasPermissionEnableDisableProvider() {
      return !!this.$Permissions.global.has(
        "farmacia.cadastroFornecedor.ativarInativar",
      );
    },
    disabledByModal() {
      return this.showModalDeleteProvider || this.showModalActiveDesactive;
    },
  },

  watch: {},

  mounted() {
    this.fillFilter();
  },

  beforeDestroy() {
    const validateIfExist = ValidateIfRouteExistInBreadscrumb(
      "/pharmacy/register/provider",
      this.$route.meta.breadcrumb,
    );

    if (!validateIfExist) {
      this.$store.commit("Pharmacy/Register/RESET_FILTER_PHARMACY_PROVIDER");
    } else {
      this.reSearch();
    }
  },

  created() {
    this.COLUMNS = [
      { name: "Nome", key: "frd_nome", align: "left" },
      { name: "Pessoa", key: "tipoFornecedor", align: "left" },
      { name: "CPF", key: "frd_cpf", align: "left" },
      { name: "CNPJ", key: "frd_cnpj", align: "left" },
      {
        name: "Inscrição Estadual",
        key: "frd_inscricao_estadual",
        align: "left",
      },
      {
        name: "Inscrição Municipal",
        key: "frd_inscricao_municipal",
        align: "left",
      },
      { name: "Situação", key: "situacao", align: "left" },
    ];
  },

  methods: {
    selectingProvider(pValue) {
      if (pValue) {
        this.form.frd_id = pValue.value;
      } else {
        this.form.frd_id = null;
      }
    },
    reasonActiveDesactiveProvider(pValue) {
      this.form.frd_motivo_inabitacao = pValue;
    },
    afterSearchFilter() {
      this.getDynamicHeight();
      this.cleanSelectRow();
    },
    getDynamicHeight() {
      this.$nextTick(() => {
        if (this.$refs.RgSearch?.$refs.listContent) {
          const headerHeight = 36;
          this.tableHeight =
            this.$refs.RgSearch.$refs.listContent.offsetHeight - headerHeight;
        }
      });
    },

    selectLine(pValue) {
      if (pValue) {
        this.selectedRow = pValue;
        this.$store.commit(
          "Pharmacy/Register/SELECT_PHARMACY_PROVIDER",
          pValue,
        );
      } else {
        this.selectedRow = null;
      }
    },

    removeDashesAndDots(pValue) {
      return pValue ? pValue.replace(/[^\d]/g, "") : null;
    },
    generateFilter() {
      const variables = {
        arrFormData: {
          frd_nome: this.form.frd_nome,
          frd_cnpj: this.removeDashesAndDots(this.form.frd_cnpj),
          frd_cpf: this.removeDashesAndDots(this.form.frd_cpf),
          frd_id: this.form.frd_id,
          ehAtivo: this.form.frd_ativo === "1" ? 1 : null,
          ehInativo: this.form.frd_ativo === "2" ? 1 : null,
          ehFisica: this.form.typePerson === "1" ? 1 : null,
          ehJuridica: this.form.typePerson === "2" ? 1 : null,
        },
      };

      this.$store.commit(
        "Pharmacy/Register/SET_FILTER_PROVIDER",
        this.$utils.obj.DeepCopy(this.form),
      );

      return variables;
    },
    async fillFilter() {
      try {
        const existFilterData = this.$store.getters[
          "Pharmacy/Register/GET_FILTER_PROVIDER"
        ];

        if (existFilterData) {
          this.$loader.start();

          this.form.frd_nome = existFilterData.frd_nome;
          this.form.frd_cnpj = existFilterData.frd_cnpj;
          this.form.frd_cpf = existFilterData.frd_cpf;
          this.form.frd_ativo = existFilterData.frd_ativo;
          this.form.typePerson = existFilterData.typePerson;

          await this.$refs.rgsearch.performSearch();
          this.$loader.finish();
        }
      } catch (Err) {
        this.$toaster.warning(Err, "Erro ao carregar os dados da navegação");
        this.$loader.finish();
      }
    },

    async searchFilter(pData) {
      try {
        this.$loader.start();
        return await this.$store.dispatch(
          "Pharmacy/Register/SEARCH_PROVIDER",
          pData,
        );
      } catch (e) {
        this.$toaster.warning(e);
      } finally {
        this.$loader.finish();
      }
    },
    reSearch() {
      this.$refs.rgsearch.performSearch();
    },
    cleanSelectRow() {
      this.selectedRow = null;
      if (this.$refs.smartTable) this.$refs.smartTable.cleanSelectRow();
    },
    cleanForm() {
      this.form = {
        frd_nome: null,
        frd_cnpj: null,
        frd_cpf: null,
        frd_ativo: null,
        typePerson: null,
      };
      this.mutablePharmacyProvider = [];
      this.selectedRow = null;
    },
    getCountValue(pValue) {
      this.total = Number(pValue) || 0;
    },

    async activeProvider() {
      try {
        this.$loader.start();
        const variables = {
          intIdFornecedor: this.selectedRow.frd_id,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/ACTIVATE_PHARMACY_PROVIDER",
          variables,
        );

        this.$toaster.success("Fornecedor habilitado com sucesso");
        this.reSearch();
      } catch (e) {
        this.$toaster.error("Não foi possível habilitar o Fornecedor");
      } finally {
        this.$loader.finish();
        this.isActive = false;
      }
    },
    async desactiveProvider() {
      try {
        this.$loader.start();
        const variables = {
          intIdFornecedor: this.selectedRow.frd_id,
          motivo: this.form.frd_motivo_inabitacao,
        };

        await this.$store.dispatch(
          "Pharmacy/Register/INACTIVATE_PHARMACY_PROVIDER",
          variables,
        );

        this.$toaster.success("Fornecedor inabilitado com sucesso");
        this.reSearch();
      } catch (e) {
        this.$toaster.error("Não foi possível inabilitar o Fornecedor");
      } finally {
        this.$loader.finish();
      }
    },
    activeDesactiveProviderItem() {
      if (this.isActive) {
        this.activeProvider();
      } else {
        this.desactiveProvider();
      }
      this.showModalActiveDesactive = false;
    },
    openModalActiveProvider() {
      this.titleActiveDesactive =
        "Habilitar o Fornecedor/Fabricante Selecionado";
      this.messageActiveDesactive =
        "Deseja habilitar o fornecedor/fabricante selecionado?";
      this.msgSuccessActiveDesactive = "Fornecedor habilitado com sucesso";
      this.labelBtnActiveDesactive = "Habilitar";
      this.textReason = this.selectedRow.frd_motivo_inabilitacao;
      this.bodyText = this.selectedRow.frd_nome;
      this.showModalActiveDesactive = true;
      this.isActive = true;
    },
    openModalDesactiveProvider() {
      this.titleActiveDesactive =
        "Inabilitar o Fornecedor/Fabricante Selecionado";
      this.messageActiveDesactive =
        "O Fornecedor/Fabricante selecionado possui movimentações vinculadas. Por isso só é possível inabilitar o cadastro. Deseja inabilitar o fornecedor/fabricante selecionado?";
      this.msgSuccessActiveDesactive = "Fornecedor inabilitado com sucesso";
      this.labelBtnActiveDesactive = "Inabilitar";
      this.textReason = "";
      this.bodyText = this.selectedRow.frd_nome;
      this.showModalActiveDesactive = true;
    },
    editPharmacyProvider() {
      this.$router.push({ name: "pharmacy.register.provider.edit" });
    },
    openNewProvider() {
      this.$router.push({
        name: "pharmacy.register.provider.insert",
      });
    },
    closeNewProvider() {
      this.editPharmacyProviderRow = null;
    },

    openModalProvider() {
      this.showModalDeleteProvider = true;
      this.bodyText = this.selectedRow.frd_nome;
    },
    async deleteProviderItem() {
      try {
        this.$loader.start();
        const variables = {
          intIdFornecedor: this.selectedRow.frd_id,
        };
        await this.$store.dispatch(
          "Pharmacy/Register/DELETE_PHARMACY_PROVIDER",
          variables,
        );
        this.showModalDeleteProvider = true;
        this.$toaster.success("Fornecedor excluído com sucesso");
      } catch (error) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(error.message || error),
          "Não foi possível excluir o Fornecedor",
        );
      } finally {
        this.$loader.finish();
      }
    },
    closeModalProvider() {
      this.showModalDeleteProvider = false;
    },
    closeActiveDesactive() {
      this.showModalActiveDesactive = false;
      this.isActive = false;
    },
  },
};
</script>
