<style src="./UnbilliedExits.scss" lang="scss" scoped></style>

<template>
  <div class="grid">
    <div class="selectinput">
      <RgSelectUnithealth
        id="uns-id-pv"
        v-model="form.unsId"
        :rules="{ required: true }"
        permission="faturamento.relatoriosESUSng.Exibir"
        class="inputitem"
      />
    </div>
    <div class="selectinput">
      <RgInputMasked
        id="hospitalization-numer-ae"
        v-model="form.hospitalizationNumber"
        :mask="['########']"
        class="inputitem"
        label="Número da Internação"
        placeholder="Informe o número da internação "
      />
    </div>
    <div class="selectinput">
      <RgInputMasked
        id="medical-record-ae"
        v-model="form.medicalRecord"
        :mask="['###############']"
        class="inputitem"
        label="Prontúario"
        placeholder="Informe o prontuário"
      />
    </div>
    <div class="selectinput">
      <RgInput
        id="name-ae"
        v-model="form.name"
        class="inputitem"
        label="Nome do Paciente"
        placeholder="Informe o nome do paciente"
      />
    </div>
    <div class="filter-subtitle filter-title">
      <span class="period-subtitle">Período de Entrada</span>
    </div>
    <div class="selectinput _flex date">
      <RgInputDate
        id="entry-initial-date-ae"
        v-model="form.entryInitialDate"
        class="inputitem"
        label="Data Inicial"
        placeholder="dd/mm/aaaa"
      />
      <RgInputDate
        id="entry-final-date-ae"
        v-model="form.entryFinalDate"
        class="inputitem"
        label="Data Final"
        placeholder="dd/mm/aaaa"
      />
    </div>
    <div class="filter-subtitle filter-title">
      <span class="period-subtitle">Período de Saída</span>
    </div>
    <div class="selectinput _flex date">
      <RgInputDate
        id="exit-initial-date-ae"
        v-model="form.exitInitialDate"
        class="inputitem"
        label="Data Inicial"
        placeholder="dd/mm/aaaa"
      />
      <RgInputDate
        id="exit-final-date-ae"
        v-model="form.exitFinalDate"
        class="inputitem"
        label="Data Final"
        placeholder="dd/mm/aaaa"
      />
    </div>
  </div>
</template>

<script>
import {
  RgInputDate,
  RgInput,
  RgInputMasked,
  RgSelectUnithealth,
} from "~tokio/primitive";

export default {
  name: "UnbilledExits",
  components: {
    RgInput,
    RgInputDate,
    RgInputMasked,
    RgSelectUnithealth,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Relatório Saídas Não Faturadas",
      form: {
        initialDate: "",
        finalDate: "",
        unsId: 0,
      },
    };
  },
  computed: {
    columnTable() {
      return this.COLUMN;
    },
    getNameSmartTable() {
      return "UnbilliedExits";
    },
  },
  watch: {
    "form.name"(pValue, pPrev) {
      if (this.form.name) {
        this.form.name = this.form.name.toUpperCase();
      }
    },
  },
  created() {
    this.COLUMN = [
      { name: "Nome do Paciente", key: "nomePaciente" },
      { name: "Prontuário", key: "prontuario" },
      { name: "Número Internação", key: "int_numero" },
      { name: "Data da Entrada", key: "dataEntrada" },
      { name: "Data da Saída", key: "dataSaida" },
    ];
  },
  mounted() {
    this.form.unsId = this.$store.getters["Login/GET_UNIT_HEALTH_ID"];
  },
  methods: {
    buildFilter() {
      return {
        unsId: this.form.unsId ? this.form.unsId : null,
        hospitalizationNumber: this.form.hospitalizationNumber
          ? this.form.hospitalizationNumber
          : null,
        name: this.form.name ? this.form.name : null,
        medicalRecord: this.form.medicalRecord ? this.form.medicalRecord : null,
        entryInitialDate: this.form.entryInitialDate
          ? this.form.entryInitialDate
          : null,
        entryFinalDate: this.form.entryFinalDate
          ? this.form.entryFinalDate
          : null,
        exitInitialDate: this.form.exitInitialDate
          ? this.form.exitInitialDate
          : null,
        exitFinalDate: this.form.exitFinalDate ? this.form.exitFinalDate : null,
        columnsToPrint: this.columnsToPrint,
      };
    },
    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Billing/HospitalizationBilling/GET_UNBILLED_EXITS_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },
    formatDate(pValue) {
      return pValue ? this.$utils.date.BrazilianDateTimeFormat(pValue) : "-";
    },
    clearFilter() {
      this.form = {
        initialDate: "",
        finalDate: "",
        unsId: 0,
      };
    },
  },
};
</script>
