<template>
  <RgSuggest
    :id="id"
    ref="innerSuggest"
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :search-on-focus="searchOnFocus"
    :title="this.inputValue ? this.inputValue : title"
    :disabled="disabled"
    class="rg-suggest-type-bed"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="rg-suggest-type-bed-item flex-column _fit-content"
    >
      <div class="info flex-column justify-content-center">
        <span class="bed">{{ item.itl_nome }}</span>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import { isEmpty } from "lodash";
import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";
import SearchHospitalizationBedType from "./action/SearchHospitalizationBedType";

export default {
  name: "RgSuggestTypeBed",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    inlId: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "Tipo de Leito",
    },
    placeholder: {
      type: String,
      default: "Digite o tipo de leito",
    },
    title: {
      type: String,
      default: "Tipo de Leito",
    },
    typeBedIds: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captionRaw: "itl_nome",
      filter: () => {
        return {
          searchQuery: this.inputValue,
        };
      },
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  methods: {
    doSearch(searchQuery, pLimit = null) {
      return SearchHospitalizationBedType({
        itl_nome: searchQuery,
        inl_id: this.inlId,
        limit: pLimit,
        ids_tipos_do_leito: this.convertStrArrayToIntArray(this.typeBedIds),
      });
    },
    formatItem(pItem) {
      if (pItem && pItem.itl_nome) {
        return pItem.itl_nome;
      }
    },
    validate() {
      this.$refs.innerSuggest.validate();
    },
    cleanValidate() {
      this.$refs.innerSuggest.cleanValidate();
    },
    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.itl_nome) && pValue.itl_id > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            itl_nome: pValue.itl_nome,
            itl_id: pValue.itl_id,
            inl_id: this.inlId,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    convertStrArrayToIntArray(pItem) {
      let pDataInt = [];

      if (pItem && pItem.length > 0) {
        pDataInt = pItem.split(",").map((item) => parseInt(item, 10));
      }
      return pDataInt;
    },
  },
};
</script>
