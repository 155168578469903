<script>
import RgSelect from "../../selection/rg-select/RgSelect";
import ListSectorsByUnithealthId from "./action/ListSectorsByUnithealthId";

export default {
  name: "RgSelectSector",
  extends: RgSelect,
  props: {
    isBatchScheduleQueue: {
      type: Boolean,
      default: false,
    },
    unitHealth: {
      default: null,
      required: true,
    },
    permission: {
      type: Number,
      default: null,
    },
    isNecessary: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Setor",
    },
    defaultText: {
      type: String,
      default: "Todos",
    },
    limit: {
      type: Number,
      default: 1000,
    },
    isUnitHealthRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sector: [],
    };
  },
  watch: {
    sector: function (val) {
      this.updateOptions();
    },
    unitHealth(pValue) {
      this.doSearch();
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    getData() {
      return this.sector;
    },

    async doSearch() {
      this.sector = [];

      if (this.unitHealth && this.unitHealth > 0) {
        const variables = {
          unitHealth: this.unitHealth,
          permission: this.permission,
          limit: this.limit,
        };

        const data = await ListSectorsByUnithealthId(variables);

        if (this.isBatchScheduleQueue || this.isUnitHealthRegister) {
          data.forEach((item) => {
            const option = {
              value: item.set_id,
              label: `${item.set_nome}`,
              type: item.set_tipos_atendimento_pep,
              active: item.set_ativo,
            };

            if (option.active === true && this.isUnitHealthRegister) {
              this.sector.push(option);
            }
            if (
              option.type !== "ATENÇÃO BÁSICA" &&
              option.type !== "Atenção Básica" &&
              !this.isUnitHealthRegister
            ) {
              this.sector.push(option);
            }
          });
        } else {
          this.sector = data.map((item) => {
            return {
              value: item.set_id,
              label: `${item.set_nome}`,
              type: item.set_tipos_atendimento_pep,
            };
          });
        }
      }
    },

    reSearchSector() {
      this.doSearch();
    },

    blur() {
      if (this.isNecessary) {
        this.$emit("blur");
      }
    },
  },
};
</script>
