<template>
  <div class="exam-scheduling-history-report">
    <div class="grid">
      <div class="selectinput">
        <RgSelectUnithealth
          id="uns-id-eshr"
          v-model="form.uns_id"
          permission="exame2.relatoriosESUSng.Exibir"
          class="inputitem"
          :rules="{ required: true }"
          placeholder="Todas"
        />
      </div>

      <div class="filter-title">
        <span class="title">Paciente</span>
      </div>

      <div class="selectinput">
        <RgSuggestSmartPerson
          id="suggest-patient-eshr"
          ref="patient"
          v-model="suggestPatient"
          :enabled-patient="false"
          :rules="{ forceSelection: true }"
          class="inputpatient"
          :extraData="['telephone']"
          @selected="selectingPatient"
        />
      </div>

      <div class="filter-subtitle filter-title">
        <span class="period-subtitle">Período do Exame</span>
      </div>

      <div class="selectinput period">
        <RgInputDate
          id="initial-date-eshr"
          ref="initialDate"
          v-model="form.initialDate"
          label="Data Inicial"
          :rules="{ required: true, fn: validateInitialDate }"
          class="inputitem"
        />

        <RgInputDate
          id="final-date-eshr"
          ref="finalDate"
          v-model="form.finalDate"
          label="Data Final"
          :rules="{ required: true, fn: validateFinalDate }"
          :max-date="getMaxDateInitial"
          :min-date="getMinDateInitial"
          class="inputitem"
        />
      </div>

      <div class="filter-title">
        <span class="title">Ordenação</span>
      </div>

      <div class="selectinput">
        <RgComboboxOrderBy
          id="orderby-eshr"
          ref="order"
          v-model="form.orderby"
          :data="ORDERS"
          label="Ordenar por"
          class="inputitem"
          :rules="{ required: true }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RgSelectUnithealth,
  RgInputDate,
  RgSuggestSmartPerson,
  RgComboboxOrderBy,
} from "~tokio/primitive";

import moment from "moment";

const FORM_FILTER = {
  uns_id: null,
  pes_nome: null,
  pac_prontuario_unico: null,
  cns_or_cpf: "",
  telephones: "",
  sex: "",
  age: null,
  pes_id: null,
  initialDate: moment().add(-30, "days").format("DD/MM/YYYY"),
  finalDate: moment().format("DD/MM/YYYY"),
  orderby: 1,
  orderByLabel: "Data e Hora",
};

export default {
  name: "ExamScheduleHistoryReport",
  components: {
    RgSelectUnithealth,
    RgInputDate,
    RgSuggestSmartPerson,
    RgComboboxOrderBy,
  },
  props: {
    columnsToPrint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultTitle: "Histórico de Agendamentos do Paciente",
      form: this.$utils.obj.DeepCopy(FORM_FILTER),
      suggestPatient: null,
    };
  },

  computed: {
    getNameSmartTable() {
      return "ExamScheduleHistoryReport";
    },
    columnTable() {
      return this.COLUMN;
    },
    printReportCss() {
      return (
        "@page {size: landscape;}" +
        ".report-column-2 { white-space: normal !important; }" +
        ".report-column-3 { white-space: normal !important; text-align: left; }" +
        ".report-column-4 { white-space: normal !important; text-align: left; }" +
        ".report-column-5 { white-space: normal !important; text-align: left; }" +
        ".report-column-6 { white-space: normal !important; text-align: left; }" +
        ".report-column-7 { white-space: normal !important; }" +
        ".report-column-9 { white-space: normal !important; text-align: left;}"
      );
    },
    getMaxDateInitial() {
      return this.form.initialDate
        ? new Date(
            moment(this.form.initialDate, "DD/MM/YYYY")
              .add(30, "days")
              .format(),
          )
        : null;
    },
    getMinDateInitial() {
      return this.form.initialDate
        ? new Date(moment(this.form.initialDate, "DD/MM/YYYY").format())
        : null;
    },
  },

  watch: {
    "form.initialDate"(pValue, pPrev) {
      if (!pValue || pValue !== pPrev) {
        this.form.finalDate = "";
      }

      if (pValue && !this.form.finalDate) {
        this.form.finalDate = moment(pValue, "DD/MM/YYYY")
          .add(30, "days")
          .format("DD/MM/YYYY");
      }
    },
  },

  created() {
    this.COLUMN = [
      { name: "Data/Hora Exame", key: "DATA_HORA_EXAME" },
      { name: "Unidade de Saúde", key: "UNIDADE" },
      { name: "Setor", key: "SETOR", align: "left" },
      { name: "Profissional de Saúde", key: "PROFISSIONAL", align: "left" },
      { name: "Procedimento", key: "PROCEDIMENTO", align: "left" },
      {
        name: "Subprocedimento",
        key: "SUB_PROCEDIMENTO",
        align: "left",
        concat: ",",
      },
      { name: "Situação", key: "SITUACAO" },
      { name: "Data/Hora Marcação", key: "DATA_HORA_MARCACAO" },
      { name: "Usuário Marcação", key: "USUARIO_MARCACAO", align: "left" },
    ];

    this.ORDERS = [
      { value: 1, text: "Data e Hora" },
      { value: 2, text: "Setor" },
      { value: 3, text: "Profissional" },
    ];
  },

  methods: {
    buildFilter() {
      return { ...this.form, columnsToPrint: this.columnsToPrint };
    },

    async doSearch(pData) {
      try {
        return this.$store.dispatch(
          "Exam/Report/GET_EXAM_SCHEDULE_HISTORY_REPORT",
          pData,
        );
      } catch (error) {
        return this.$toaster.error("Erro ao gerar o relatório");
      }
    },

    selectingPatient(pPatient) {
      const source = pPatient.source ? pPatient.source : null;
      const hasPerson = source && source.pes_id > 0;

      if (hasPerson) {
        this.form.pes_id = source.pes_id;
        this.form.pes_nome = source.pes_nome;

        if (source.telephones.rows.length > 0) {
          let contact = "";

          source.telephones.rows.forEach((item) => {
            contact += `(${item.ddd.ddd_codigo}) ${item.tel_numero} `;
          });

          this.form.telephones = contact;
        }

        if (source.gender.sex_sigla) {
          this.form.sex =
            source.gender.sex_sigla === "F" ? "Feminino" : "Masculino";
        }

        if (source.pes_nascimento) {
          const ageDifference = moment().diff(source.pes_nascimento);
          const diffDuration = moment.duration(ageDifference);
          this.form.age = diffDuration.years();
        }

        if (source.patient && source.patient.pac_prontuario_unico > 0)
          this.form.pac_prontuario_unico = source.patient.pac_prontuario_unico;

        if (source.cns && source.cns.crs_numero)
          this.form.cns_or_cpf = source.cns.crs_numero;
        else if (source.cpf && source.cpf.cpf_numero)
          this.form.cns_or_cpf = source.cpf.cpf_numero;
      } else {
        this.form.pes_id = null;
        this.form.pac_prontuario_unico = null;
        this.form.age = null;
        this.form.pes_nome = "";
        this.form.cns_or_cpf = "";
        this.form.telephones = "";
        this.form.sex = "";
      }
    },

    validateInitialDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const initialDateIsNotValid = initialDate.isAfter(finalDate);

      if (initialDateIsNotValid) {
        pErrors.push("A data inicial não pode ser maior que a data final");
        return false;
      }
    },

    validateFinalDate(pValue, pErrors) {
      const initialDate = moment(this.form.initialDate, "DD/MM/YYYY");
      const finalDate = moment(this.form.finalDate, "DD/MM/YYYY");

      const finalDateIsNotValid = initialDate.isAfter(finalDate);

      if (finalDateIsNotValid) {
        pErrors.push("A data final não pode ser menor que a data inicial");
        return false;
      }
    },

    clearFilter() {
      this.form = this.$utils.obj.DeepCopy(FORM_FILTER);
      this.suggestPatient = null;

      if (this.$refs.order) {
        this.$refs.order.cleanValidate();
      }
    },
  },
};
</script>
