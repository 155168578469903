import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";
import PatientCareHistory from "$person/patient/view/patient-care-history/PatientCareHistory";
import { OpenMenuModule, OpenSubModule } from "~common/utils/app/menu";

export default {
  path: "/hospitalization/bed/search/new-hospitalization/patient-care-history",
  name: "hospitalization.bed.search.new-hospitalization.patient-care-history",
  components: {
    default: PatientCareHistory,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Internação", link: OpenMenuModule("hospitalization") },
      { label: "Leitos", link: OpenSubModule("hospitalization") },
      {
        label: "Lista de Leitos",
        link: "/hospitalization/bed/search",
      },
      {
        label: "Cadastrar Internação",
        link: "/hospitalization/bed/search/new-hospitalization",
      },
      {
        label: "Histórico de Atendimentos",
      },
    ],
  },
};
