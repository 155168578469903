import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query Place($tlgNome: String, $tlg_codigo: String) {
    publicPlaceType(tlg_nome: $tlgNome, tlg_codigo: $tlg_codigo) {
      rows {
        tlg_id
        tlg_nome
        tlg_codigo
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.publicPlaceType.rows;
};
