<style src="./RgSuggestNationality.scss" lang="scss" scoped></style>
<template>
  <div class="rg-suggest-nationality">
    <RgSuggest
      :id="id"
      ref="rgSuggest"
      v-model="inputValue"
      :data-id="dataId"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :rules="rules"
      min="1"
      :search-on-focus="searchOnFocus"
    >
      <RgSuggestItem
        v-for="(item, idx) in suggestionList"
        :key="idx"
        :data-item="dataId"
        :idx="idx"
        :item="item"
        class="nat-item"
      >
        <div class="nat-row-top">
          <span class="nat-name">
            {{ item.nac_codigo }} - {{ item.nac_nome }}
          </span>
        </div>
      </RgSuggestItem>
    </RgSuggest>
  </div>
</template>
<script>
import { RgSuggest, RgSuggestMixin, RgSuggestItem } from "~tokio/primitive";
import { SearchNationality } from "./action";

export default {
  name: "RgSuggestNationality",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
    initValue: {
      type: String,
      default: "",
    },
    hasCodeBasicAttention: Boolean,
  },
  data() {
    return {
      captionRaw: "nac_codigo - nac_nome",
    };
  },
  computed: {
    searchOnFocus() {
      return this.inputValue === "" || this.inputValue === null;
    },
  },
  watch: {
    initValue: {
      handler(pValue) {
        this.forceSelection(pValue);
      },
    },
  },
  methods: {
    async doSearch(pSearchTerm, pLimit = null) {
      try {
        const variables = {
          nacNome: null,
          nac_codigo: null,
          nac_ativo: this.active,
          has_nac_codigo_ab: this.hasCodeBasicAttention,
          limit: pLimit,
        };

        if (!isNaN(pSearchTerm)) {
          variables.nac_codigo = pSearchTerm;
        } else if (!isNaN(pSearchTerm.slice(0, 3))) {
          variables.nac_codigo = pSearchTerm.split(" - ")[0];
          variables.nacNome = pSearchTerm.split(" - ")[1];
        } else {
          variables.nacNome = pSearchTerm;
        }

        return await SearchNationality(variables);
      } catch (e) {
        this.$toaster.error("Não foi possível carregar a nacionalidade");
      }
    },
    validate() {
      this.$refs.rgSuggest.validate();
    },
    cleanValidate() {
      this.$refs.rgSuggest.cleanValidate();
    },
    async forceSelection(pValue) {
      if (pValue && pValue.nac_nome) {
        this.$refs.rgSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            nac_id: pValue.nac_id,
            nac_codigo: pValue.nac_codigo,
            nac_nome: pValue.nac_nome,
            ...pValue,
          });
        }
        this.$refs.rgSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
  },
};
</script>
