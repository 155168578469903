import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamPatientsExcludedQueueReport(
    $unitHealth: Int
    $unitHealthName: String
    $unitHealthRequest: Int
    $unitHealthRequestName: String
    $employeeRequest: Int
    $employeeRequestName: String
    $patient: Int
    $patientName: String
    $entryPeriodDateStart: String
    $entryPeriodDateEnd: String
    $exclusionPeriodDateStart: String
    $exclusionPeriodDateEnd: String
    $userOriginId: Int
    $userOriginName: String
    $userExclusionId: Int
    $userExclusionName: String
    $priority: Int
    $priorityName: String
    $est_sigla: String
    $est_id: Int
    $mun_nome: String
    $mun_id: Int
    $gender: Int
    $maxAge: Int
    $minAge: Int
    $procedureId: Int
    $procedureName: String
    $subprocedureId: String
    $subprocedureName: String
    $cidCode: Int
    $cidName: String
    $smd_id: Int
    $loggedUserId: Int!
    $columnsToPrint: [ColumnsToPrintInputType]
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchExamPatientsExcludedQueueReport(
      unitHealth: $unitHealth
      unitHealthName: $unitHealthName
      unitHealthRequest: $unitHealthRequest
      unitHealthRequestName: $unitHealthRequestName
      employeeRequest: $employeeRequest
      employeeRequestName: $employeeRequestName
      patient: $patient
      patientName: $patientName
      entryPeriodDateStart: $entryPeriodDateStart
      entryPeriodDateEnd: $entryPeriodDateEnd
      exclusionPeriodDateStart: $exclusionPeriodDateStart
      exclusionPeriodDateEnd: $exclusionPeriodDateEnd
      userOriginId: $userOriginId
      userOriginName: $userOriginName
      userExclusionId: $userExclusionId
      userExclusionName: $userExclusionName
      priority: $priority
      priorityName: $priorityName
      est_sigla: $est_sigla
      est_id: $est_id
      mun_nome: $mun_nome
      mun_id: $mun_id
      gender: $gender
      maxAge: $maxAge
      minAge: $minAge
      procedureId: $procedureId
      procedureName: $procedureName
      subprocedureId: $subprocedureId
      subprocedureName: $subprocedureName
      cidCode: $cidCode
      cidName: $cidName
      smd_id: $smd_id
      loggedUserId: $loggedUserId
      columnsToPrint: $columnsToPrint
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        IDADE
        SEXO
        PROCEDIMENTO
        SUBPROCEDIMENTOS
        CID
        PRIORIDADE
        UNIDADE_SAUDE_SOLICITANTE
        PROFISSIONAL_SOLICITANTE
        ESTADO
        MUNICIPIO
        DATA_INSERCAO
        DATA_EXCLUSAO
        MOTIVO_EXCLUSAO
        USUARIO_EXCLUSAO
        UNIDADE_SAUDE_ORIGEM
        USUARIO_ORIGEM
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamPatientsExcludedQueueReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
