import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (variables) => {
  return AuthLegacyRequest.post(
    "exames2/controller-exame-procedimento/combo-box-procedimentos-tu",
    variables,
  )
    .then(({ data }) => {
      return data.dados;
    })
    .catch((pErr) => {
      throw pErr;
    });
};
