import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const arrayExceptions = {
  AtivarLocalAtendimentoException: `Não é possível ativar um Local de Atendimento de um Setor Inativo`,
};

export default async (context, variables) => {
  return AuthLegacyRequest.post(
    "unidadesaude/controller-local-atendimento/ativar",
    variables,
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: arrayExceptions });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
