import AuthLegacyRequest from "~common/request/AuthLegacyRequest";

export default async (context, variables) => {
  const guide = await AuthLegacyRequest.post(
    "farmacia/controller-solicitacao-transferencia/pegar-guia-remessa",
    variables,
  );

  return { ...guide.data };
};
