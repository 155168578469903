<style src="./RgPersonCard.scss" lang="scss" scoped></style>
<template lang="html">
  <div class="rg-person-card">
    <div
      :class="{
        'rg-person-card--status-bar__danger':
          patientContent.pes_ativo === false,
      }"
      class="rg-person-card--status-bar"
    />
    <div class="avatar-container" @click="contentClick()">
      <img
        v-if="!patientContent.pes_foto"
        class="avatar"
        src="/static/person-image/photo-default.png"
        alt=""
      />
      <img v-if="patientContent.pes_foto" :src="avatar" class="avatar" alt="" />
    </div>
    <div class="rg-person-card--container-data">
      <div class="rg-person-card--column">
        <div class="rg-person-card--column-data" @click="contentClick()">
          <div class="rg-person-card--content" @click="titleClick">
            <div class="rg-person-card--title-container">
              <span class="rg-person-card--title">{{
                patientData.pes_nome
              }}</span>
            </div>

            <div class="rg-person-card--nickname-container">
              <span
                v-if="patientContent.pes_nome_social"
                class="rg-person-card--nickname"
                >{{ patientContent.pes_nome_social }}</span
              >
              <span
                v-if="!patientContent.pes_nome_social"
                class="rg-person-card--nickname"
                >{{ patientContent.pes_nome }}</span
              >
            </div>
          </div>

          <div class="rg-person-card--details-container">
            <div class="rg-person-card--detail" @click.stop="openMap">
              <IconPlace class="rg-person-card--icon rg-person-card--place" />
              <span class="rg-person-card--label"
                >{{ patientContent.address.end_logradouro }},
                {{ patientContent.address.neighborhood.bai_nome }} -
                {{
                  patientContent.address.neighborhood.city.state.est_sigla
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="rg-person-card--column-info" @click="contentClick()">
          <div class="rg-person-card--detail">
            <IconCalendar
              class="rg-person-card--icon rg-person-card--svg__calendar"
            />
            <span class="rg-person-card--label">{{
              patientContent.pes_nascimento | DateBR
            }}</span>
          </div>

          <div class="rg-person-card--detail">
            <IconMother
              class="rg-person-card--icon rg-person-card--svg__mother"
            />
            <span class="rg-person-card--label">{{
              patientContent.pes_mae
            }}</span>
          </div>

          <div class="rg-person-card--detail">
            <IconGenderMale
              v-if="patientContent.gender.sex_id === 1"
              class="rg-person-card--icon rg-person-card--svg__male"
            />
            <IconGenderFemale
              v-if="patientContent.gender.sex_id === 2"
              class="rg-person-card--icon rg-person-card--svg__female"
            />
            <span class="rg-person-card--label">{{ gender }}</span>
          </div>
        </div>
      </div>
      <!-- <rg-patient-card-expand-panel @toggle-panel="togglePanel" /> -->
    </div>
    <RgPatientCardCommands
      v-show="panelState"
      :patient-data="patientContent"
      @associate-newborn="newBorn"
    />
    <!-- <rg-list-item-remove @remove-action="togglePersonStatusByPesId()"/> -->
  </div>
</template>

<script>
import {
  IconMother,
  IconCalendar,
  IconGenderMale,
  IconGenderFemale,
  IconPlace,
} from "~tokio/primitive/icon";
import RgPatientCardCommands from "./rg-patient-card-commands/RgPatientCardCommands";
import moment from "moment";
import _ from "lodash";
export default {
  name: "RgPersonCard",
  components: {
    IconMother,
    IconCalendar,
    IconGenderMale,
    IconGenderFemale,
    IconPlace,
    RgPatientCardCommands,
    // RgEnableDisablePatient,
    // RgPatientCardCommands
    // RgPatientCardExpandPanel
  },
  filters: {
    DateBR: (pDate) => {
      return moment(pDate).format("DD/MM/YYYY");
    },
  },
  props: {
    patientData: {
      default() {
        return {};
      },
    },
    listIndex: {
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      panelState: true,
      avatar: null,
      patientContent: {
        pes_ativo: null,
        pes_foto: null,
        pes_nome: null,
        pes_nome_social: null,
        pes_nascimento: null,
        pes_mae: null,
        gender: {
          sex_id: null,
        },
        address: {
          end_logradouro: null,
          neighborhood: {
            bai_nome: null,
            city: {
              state: {
                est_sigla: null,
              },
            },
          },
        },
      },
    };
  },
  computed: {
    gender() {
      switch (this.patientContent.gender.sex_id) {
        case 1:
          return "Masculino";
        case 2:
          return "Feminino";
        case 3:
          return "Gênero Não Informado";
      }
      return false;
    },
  },
  watch: {
    patientData(pValue) {
      this.patientContent = _.clone(pValue, true);
    },
  },
  mounted() {
    this.patientContent = _.clone(this.patientData, true);
  },
  methods: {
    titleClick() {
      this.$emit("title-click", this.patientContent);
    },
    contentClick() {
      this.$emit("content-click", this.patientContent);
    },
    newBorn() {
      this.$emit("new-born", this.patientContent);
    },
    openMap() {
      this.$emit("open-map");
    },
    togglePanel(pData) {
      this.panelState = !pData;
    },
    togglePersonStatusByPesId(pPersonId) {
      const status = !this.patientData.pes_ativo;
      this.$store
        .dispatch("PatientTOGGLE_PERSON_STATUS_BY_PES_ID", {
          personId: this.patientData.pes_id,
          status,
        })
        .then(({ pes_ativo }) => {
          this.patientData.pes_ativo = pes_ativo;
          this.$toaster.success("Status Atualizado");
        })
        .catch((pErr) => {
          this.$toaster.error(pErr[0].message);
        });
    },
  },
};
</script>
