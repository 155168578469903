import store from "@/store";

export default {
  CLEAN_MODULE_BILLING: (state) => {
    state.Billing.lastAddProcedure = null;
    state.Billing.patientBofId = null;
    state.Billing.patientInfo = {};
    state.Billing.procedureStatus = {};
    state.Billing.proceduresList = [];

    store.commit("Billing/Bulletin/DESTROY_BULLETIN");
    store.commit("Billing/BDSia/CLEAN_MODULE_BILLING_BDSIA");
    store.commit("Billing/Review/RESET_FILTER_AUTOMATED_BPAI");
  },
};
