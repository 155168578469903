import AuthLegacyRequest, {
  HandleResponse,
} from "~common/request/AuthLegacyRequest";

const duplicationErrors = {
  BloquearDuplicidadeAoInserirFilaExameException: `Paciente já consta na fila para esse procedimento.`,
  InformarDuplicidadeAoInserirFilaExameException: `Aviso: paciente já consta na fila para esse procedimento`,
};

export default async (
  context,
  { idFilaExame, strMotivo, validarDuplicidade },
) => {
  return AuthLegacyRequest.post(
    "exames2/controller-fila-exame/voltar-paciente-fila",
    {
      idFilaExame,
      strMotivo,
      validarDuplicidade,
    },
  )
    .then(({ data }) => {
      return HandleResponse(data, { extraExceptionsErrors: duplicationErrors });
    })
    .catch((pErr) => {
      throw pErr;
    });
};
