/**
 * @param {*} pProcedure
 */
const PreparedBioslabExtraInfoToSave = (pProcedure) => {
  return {
    lpe_id: pProcedure.lpe_id,
    lpe_codigo_sigtap: pProcedure.codigo,
    lpe_codigo_exame_bioslab_mne: pProcedure.codigoExameMne,
    lpe_objeto_analise: pProcedure.objectAnalisis.lob_nome_objetos,
    lpe_material_biologico: pProcedure.collectMaterial,
    lpe_situacao: "",
    lpe_mensagem_erro: "",
    object_analise_id: pProcedure.objectAnalisis.lob_id,
    lpe_ativo: "1",
    lpe_motivo_inclusao: pProcedure.reasonInclusion,
  };
};

export default PreparedBioslabExtraInfoToSave;
