import SEARCH_PHARMACY from "./pharmacy/SearchPharmacy";
import REGISTER_PHARMACY from "./pharmacy/RegisterPharmacy";
import EDIT_PHARMACY from "./pharmacy/EditPharmacy";
import DELETE_PHARMACY from "./pharmacy/DeletePharmacy";
import GET_PHARMACY from "./pharmacy/GetPharmacy";

import SEARCH_PRODUCT from "./product/SearchProduct";
import REGISTER_PRODUCT from "./product/RegisterProduct";
import EDIT_PRODUCT from "./product/EditProduct";
import DELETE_PRODUCT from "./product/DeleteProduct";
import CHANGE_STATUS_PRODUCT from "./product/ChangeStatusProduct";
import GET_PRODUCT from "./product/GetProduct";
import GET_NEW_CODE_PRODUCT from "./product/GetNewCodeProduct";

import SEARCH_TYPE_PRESENTATION from "./type-presentation/SearchTypePresentation";
import REGISTER_TYPE_PRESENTATION from "./type-presentation/RegisterTypePresentation";
import EDIT_TYPE_PRESENTATION from "./type-presentation/EditTypePresentation";
import DELETE_TYPE_PRESENTATION from "./type-presentation/DeleteTypePresentation";
import IMPORT_TYPE_PRESENTATION from "./type-presentation/ImportTypePresentation";

import SEARCH_PRODUCT_TYPE from "./product-type/SearchProductType";
import REGISTER_PRODUCT_TYPE from "./product-type/RegisterProductType";
import EDIT_PRODUCT_TYPE from "./product-type/EditProductType";
import DELETE_PRODUCT_TYPE from "./product-type/DeleteProductType";

import SEARCH_PROVIDER from "./provider/SearchProvider";
import CREATE_PHARMACY_PROVIDER from "./provider/CreatePharmacyProvider";
import DELETE_PHARMACY_PROVIDER from "./provider/DeletePharmacyProvider";
import INACTIVATE_PHARMACY_PROVIDER from "./provider/InactivatePharmacyPovider";
import ACTIVATE_PHARMACY_PROVIDER from "./provider/ActivatePharmacyPovider";
import EDIT_PHARMACY_PROVIDER from "./provider/EditPharmacyProvider";

import SEARCH_PHARMACY_MOVEMENTS from "./movements/SearchPharmacyMovements";
import CREATE_PHARMACY_MOVEMENT from "./movements/CreatePharmacyMovement";
import DELETE_PHARMACY_MOVEMENT from "./movements/DeletePharmacyMovement";
import INACTIVATE_PHARMACY_MOVEMENT from "./movements/InactivatePharmacyMovement";
import ACTIVATE_PHARMACY_MOVEMENT from "./movements/ActivatePharmacyMovement";
import EDIT_PHARMACY_MOVEMENT from "./movements/EditPharmacyMovement";

import SEARCH_DESTINATION from "./destination/SearchDestination";
import REMOVE_DESTINATION from "./destination/RemoveDestination";
import REGISTER_DESTINATION from "./destination/RegisterDestination";
import EDIT_DESTINATION from "./destination/EditRegisterDestination";

import SEARCH_INVENTORY from "./inventory/SearchInventory";
import CREATE_PHARMACY_INVENTORY from "./inventory/CreatePharmacyInventory";
import DELETE_PHARMACY_INVENTORY from "./inventory/DeletePharmacyInventory";
import EDIT_PHARMACY_INVENTORY from "./inventory/EditPharmacyInventory";
import GET_INVENTORY from "./inventory/GetInventory";

export default {
  SEARCH_PHARMACY,
  REGISTER_PHARMACY,
  EDIT_PHARMACY,
  DELETE_PHARMACY,
  GET_PHARMACY,
  SEARCH_PRODUCT,
  REGISTER_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  CHANGE_STATUS_PRODUCT,
  GET_PRODUCT,
  GET_NEW_CODE_PRODUCT,
  SEARCH_TYPE_PRESENTATION,
  REGISTER_TYPE_PRESENTATION,
  EDIT_TYPE_PRESENTATION,
  DELETE_TYPE_PRESENTATION,
  IMPORT_TYPE_PRESENTATION,
  SEARCH_PRODUCT_TYPE,
  REGISTER_PRODUCT_TYPE,
  EDIT_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
  SEARCH_PROVIDER,
  CREATE_PHARMACY_PROVIDER,
  DELETE_PHARMACY_PROVIDER,
  INACTIVATE_PHARMACY_PROVIDER,
  ACTIVATE_PHARMACY_PROVIDER,
  EDIT_PHARMACY_PROVIDER,
  SEARCH_PHARMACY_MOVEMENTS,
  CREATE_PHARMACY_MOVEMENT,
  DELETE_PHARMACY_MOVEMENT,
  INACTIVATE_PHARMACY_MOVEMENT,
  ACTIVATE_PHARMACY_MOVEMENT,
  EDIT_PHARMACY_MOVEMENT,
  SEARCH_DESTINATION,
  REMOVE_DESTINATION,
  REGISTER_DESTINATION,
  EDIT_DESTINATION,
  SEARCH_INVENTORY,
  CREATE_PHARMACY_INVENTORY,
  DELETE_PHARMACY_INVENTORY,
  EDIT_PHARMACY_INVENTORY,
  GET_INVENTORY,
};
