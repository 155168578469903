<template>
  <section class="rg-table">
    <div class="header">
      <slot name="header" />
    </div>

    <table class="table">
      <thead class="theader">
        <tr class="title">
          <th
            v-for="(col, idx) in columns"
            :key="`idx-th-${idx}`"
            class="text"
            :style="{ 'text-align': col.align }"
          >
            {{ col.name }}
          </th>
        </tr>
      </thead>

      <tbody ref="tbody" class="tbody">
        <slot name="rows" />
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  name: "RgTable",

  props: {
    columns: {
      type: Array,
      default: () => {
        return [{ name: "Resultado", key: "without_result" }];
      },
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {
    setScrollDefaultTop() {
      if (this.$refs.tbody) this.$refs.tbody.scrollTop = 0;
    },
  },
};
</script>
