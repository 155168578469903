import { OpenMenuModule } from "~common/utils/app/menu";

import RootModal from "~tokio/foundation/root-modal/RootModal";
import MainHeader from "~tokio/foundation/main-header/MainHeader";

import PharmacyCentralEntry from "$pharmacy/submodules/central/view/entry/PharmacyCentralEntry";

export default {
  path: "/pharmacy/central/edit-entry",
  name: "pharmacy.central.edit-entry",
  components: {
    default: PharmacyCentralEntry,
    header: MainHeader,
    rootModal: RootModal,
  },
  meta: {
    breadcrumb: [
      { label: "Farmácia", link: OpenMenuModule("pharmacy") },
      {
        label: "Central",
        link: "/pharmacy/central",
      },
      {
        label: "Editar Entrada",
      },
    ],
  },
};
