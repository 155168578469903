var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.startOpen)?_c('section',{staticClass:"open-tooltip"},[_c('div',{staticClass:"container"},[_c('div',[_vm._t("icon")],2),(_vm.showMessage)?_c('div',{staticClass:"message-slot",class:{ clickable: _vm.clickable },on:{"click":_vm.clicked}},[_vm._t("content")],2):_vm._e(),(_vm.showMessage && _vm.hasMessage)?_c('div',{staticClass:"message",class:{ border: _vm.showMessage, clickable: _vm.clickable },on:{"click":_vm.clicked}},[_c('span',[_vm._v(" "+_vm._s(_vm.message)+" ")])]):_vm._e()])]):_c('section',{staticClass:"hover-tooltip"},[_c('div',{staticClass:"container"},[_vm._t("icon"),_c('span',{staticClass:"hover-tooltip box",class:{
        'show-left': _vm.showLeft,
        'show-up': _vm.showUp,
        'border-radius-tooltip': _vm.hasBorderRadiusTooltip,
        'padding-border-radius': _vm.hasBorderRadiusTooltip,
      },style:({
        'margin-top': _vm.hasBorderRadiusTooltip ? '-21px' : _vm.marginTitle,
      }),attrs:{"id":"hover-tooltip"}},[_vm._t("content")],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }