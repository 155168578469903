<style src="./RgSuggestOccupationEsus.scss" lang="scss" scoped></style>
<template>
  <RgSuggest
    ref="innerSuggest"
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :show-icon="showIcon"
    min="2"
  >
    <RgSuggestItem
      v-for="(item, idx) in suggestionList"
      :key="idx"
      :idx="idx"
      :item="item"
      class="cbo-item"
    >
      <div class="cbo-row-info">
        <div class="ocp-code">
          <span>{{ item.ocp_codigo }}</span>
        </div>
        <div class="ocp-name">
          <span>{{ item.ocp_nome }}</span>
        </div>
      </div>
    </RgSuggestItem>
  </RgSuggest>
</template>

<script>
import { isEmpty } from "lodash";

import {
  RgSuggest,
  RgSuggestItem,
  RgSuggestMixin,
} from "~tokio/primitive/selection";

import SearchOccupationEsus from "./action/SearchOccupationEsus";

export default {
  name: "RgSuggestOccupationEsus",
  components: {
    RgSuggest,
    RgSuggestItem,
  },
  mixins: [RgSuggestMixin],
  props: {
    showIcon: {
      default: true,
    },
  },
  data() {
    return {
      captionRaw: "ocp_codigo" + " - " + "ocp_nome",
    };
  },
  methods: {
    doSearch(pSearchTerm) {
      return SearchOccupationEsus({
        variables: { occupationName: pSearchTerm },
      });
    },

    forceSelection(pValue) {
      if (pValue && !isEmpty(pValue.ocp_nome) && pValue.ocp_codigo > 0) {
        this.$refs.innerSuggest.itemSelected = true;
        if (
          !this.suggestionList ||
          (this.suggestionList && this.suggestionList.length === 0)
        ) {
          this.suggestionList.push({
            ocp_nome: pValue.ocp_nome,
            ocp_codigo: pValue.ocp_codigo,
            ...pValue,
          });
        }
        this.$refs.innerSuggest.$parent.selectingItemFromSuggestList(pValue);
      }
    },
    async fillByOccupationId(pOccupationId) {
      if (pOccupationId) {
        const result = await SearchOccupationEsus({
          variables: {
            occupationId: pOccupationId,
          },
        });

        const hasResult =
          Array.isArray(result) && Object.keys(result[0]).length > 0;

        if (hasResult) {
          this.forceSelection(result[0]);
          return result[0];
        }

        return null;
      }
    },
  },
};
</script>
