<template lang="html">
  <svg
    width="41"
    height="54"
    viewBox="0 0 41 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.2128 11.6043L28.9532 0.344681C28.7234 0.114894 28.3787 0 28.1489 0H4.48085C2.06809 0 0 2.06809 0 4.48085V49.5191C0 51.9319 2.06809 54 4.48085 54H35.9617C38.4894 54 40.4426 51.9319 40.4426 49.5191V12.4085C40.4426 12.0638 40.3277 11.834 40.2128 11.6043ZM29.2979 3.79149L36.6511 11.1447H31.4809C30.217 11.1447 29.183 10.1106 29.183 8.84681V3.79149H29.2979ZM38.2596 49.5191C38.2596 50.783 37.2255 51.817 35.9617 51.817H4.48085C3.21702 51.817 2.18298 50.783 2.18298 49.5191V4.48085C2.18298 3.21702 3.21702 2.18298 4.48085 2.18298H27V8.9617C27 11.4894 29.0681 13.4426 31.4809 13.4426H38.2596V49.5191Z"
      fill="white"
    />
    <path
      d="M32.6284 38.2593H7.92628C7.35181 38.2593 6.77734 38.7189 6.77734 39.4082C6.77734 39.9827 7.23692 40.5571 7.92628 40.5571H32.6284C33.2029 40.5571 33.7773 40.0976 33.7773 39.4082C33.7773 38.7189 33.2029 38.2593 32.6284 38.2593Z"
      fill="white"
    />
    <path
      d="M21.3688 45.0386H7.92628C7.35181 45.0386 6.77734 45.4981 6.77734 46.1875C6.77734 46.762 7.23692 47.3364 7.92628 47.3364H21.4837C22.0582 47.3364 22.6327 46.8769 22.6327 46.1875C22.5178 45.4981 21.9433 45.0386 21.3688 45.0386Z"
      fill="white"
    />
    <path
      d="M27.8055 18.8427H24.2438V15.281C24.2438 14.132 23.3246 13.2129 22.1757 13.2129H19.3033C18.1544 13.2129 17.2353 14.132 17.2353 15.281V18.8427H13.6736C12.5246 18.8427 11.6055 19.7618 11.6055 20.9108V23.7831C11.6055 24.932 12.5246 25.8512 13.6736 25.8512H17.2353V29.4129C17.2353 30.5618 18.1544 31.481 19.3033 31.481H22.1757C23.3246 31.481 24.2438 30.5618 24.2438 29.4129V25.8512H27.8055C28.9544 25.8512 29.8736 24.932 29.8736 23.7831V20.9108C29.8736 19.7618 28.9544 18.8427 27.8055 18.8427ZM27.5757 23.4384H23.5544C22.6353 23.4384 21.9459 24.1278 21.9459 25.0469V29.0682H19.648V25.0469C19.648 24.1278 18.9587 23.4384 18.0395 23.4384H13.7884V21.1406H17.8097C18.7289 21.1406 19.4182 20.4512 19.4182 19.532V15.5108H21.7161V19.532C21.7161 20.4512 22.4055 21.1406 23.3246 21.1406H27.5757V23.4384Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "EmergencyBulletin",
};
</script>
