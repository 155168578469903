<template lang="html">
  <div v-if="show" class="modal-patient-duplication-queue">
    <RgBaseModal
      ref="Modal"
      v-shortkey="['esc']"
      :show="show"
      with-close-button
      class="rg-base-modal"
      @close="close"
      @shortkey.native="close"
    >
      <div slot="header" class="header">
        <h1 class="title">
          {{ title }}
        </h1>
      </div>

      <div slot="body" class="body">
        <span class="text">
          Foi identificada uma duplicidade na solicitação que já se encontra na
          fila de Regulação:
        </span>
        <span class="content">
          {{ patientName || "NÃO INFORMADO" }} -
          {{ queueInfo || "NÃO INFORMADO" }}
        </span>
      </div>

      <div slot="footer" class="footer">
        <RgCancelButton id="cancel-btn" medium @click="cancel" />
        <MediumButton
          v-if="!blockDuplication"
          id="save-btn"
          title="Duplicar"
          label="Duplicar"
          @click="save"
        />
      </div>
    </RgBaseModal>
  </div>
</template>

<script>
import { RgBaseModal, RgCancelButton, MediumButton } from "~tokio/primitive";

export default {
  name: "ModalPatientDuplicationQueue",

  components: {
    RgBaseModal,
    RgCancelButton,
    MediumButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    moduleId: {
      type: Number,
      required: true,
    },

    patientName: {
      type: String,
      required: true,
    },

    queueInfo: {
      type: String,
      required: true,
    },

    blockDuplication: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    title() {
      if (!this.blockDuplication) {
        return "Atenção";
      }
      return this.drawBlockDuplicationMessage;
    },

    drawBlockDuplicationMessage() {
      let title = "Não informado";
      switch (this.moduleId) {
        case this.$utils.app.ModulesId.APPOINTMENT:
          title = this.APPOINTMENT_DUPLICATION_MESSAGE;
          break;
        case this.$utils.app.ModulesId.EXAM:
          title = this.EXAM_DUPLICATION_MESSAGE;
          break;
        case this.$utils.app.ModulesId.HOSPITALIZATION:
          title = this.HOSPITALIZATION_DUPLICATION_MESSAGE;
          break;
      }

      return title;
    },
  },

  created() {
    this.APPOINTMENT_DUPLICATION_MESSAGE =
      "Não é permitido duplicar o paciente na mesma ocupação";
    this.EXAM_DUPLICATION_MESSAGE =
      "Não é permitido duplicar o paciente no mesmo procedimento";
    this.HOSPITALIZATION_DUPLICATION_MESSAGE =
      "Não é permitido duplicar o paciente no mesmo tipo de leito";
  },

  methods: {
    save() {
      this.$emit("save");
    },

    cancel() {
      this.$emit("cancel");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
