import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchReasonsDisqualification($limit: Int) {
    disqualificationReasons(limit: $limit) {
      rows {
        mip_id
        mip_nome
        mip_permanente
        mip_ativo
      }
    }
  }
`;

export default async (variables) => {
  const { data } = await GraphQLClient.query({ query, variables }).catch(
    (err) => {
      console.error(err);
    },
  );
  return data.disqualificationReasons.rows;
};
