<template>
  <Modulebox
    :title="
      editDestination
        ? 'Editar Destino de Consumo'
        : 'Cadastrar Destino de Consumo'
    "
    class="pharmacy-destination"
  >
    <form ref="element" class="form-container">
      <FormBase
        :title="
          editDestination
            ? 'Descrição do Destino'
            : 'Cadastro e Busca de Consumo'
        "
        class="register-form"
      >
        <RgValidatorForm ref="validator">
          <div class="hello">
            <section class="content-input">
              <RgInput
                id="input-consumo"
                ref="inputDestination"
                v-model="form.destination"
                placeholder="Digite o nome do Consumo"
                label="Destino de Consumo"
                :rules="{ required: true, fn: validateEspecialCharacter }"
                :needRemoveSpecialStrings="false"
                :maxlength="100"
                :disabled="disabledByModal"
              />
            </section>
            <div class="buttons">
              <RgCancelButton
                v-if="editDestination"
                id="clear-edit"
                medium
                label="Cancelar"
                class="clear"
                :disabled="disabledByModal"
                @click="cancelEdit"
              />

              <RgSaveButton
                v-if="editDestination"
                id="new-destination-edit"
                ref="updateBtn"
                class="new"
                medium
                :disabled="disabledByModal"
                @click="saveDestination"
              />
              <RgCleanButton
                v-if="!editDestination"
                id="clear-destination"
                large
                class="clear"
                :disabled="disabledByModal"
                @click="clearForm"
              />

              <RgAddButton
                v-if="!editDestination"
                id="new-destination"
                large
                class="new"
                :disabled="disabledByModal"
                @click="registerDestination"
              />
            </div>
          </div>
          <hr class="hr" />
          <div class="teste">
            <section class="content-input2">
              <RgInput
                id="input-destination"
                v-model="search.destination"
                :disabled="editDestination || disabledByModal"
                :class="{ disable: editDestination || disabledByModal }"
                label=""
                placeholder="Buscar por destino"
              />
              <RgSearchButton
                id="search-destination"
                ref="searchDestination"
                v-shortkey="['enter']"
                class="search-button"
                :disabled="editDestination || disabledByModal"
                :class="{ disable: editDestination || disabledByModal }"
                @submit="searchDestination(true)"
              />
            </section>
            <div class="buttons">
              <RgEditButton
                id="edit-destination"
                small
                title="Editar Destino de Consumo"
                :disabled="!hasDestinationSelected || disabledByModal"
                @click="editRegisterDestination"
              />
              <RgLessButton
                id="remove-destination"
                title="Excluir Destino de Consumo"
                :disabled="!hasDestinationSelected || disabledByModal"
                @click="showModalDeleteDestination"
              />
            </div>
          </div>
          <div
            class="table"
            :disabled="editDestination || disabledByModal"
            :class="{ disable: editDestination || disabledByModal }"
          >
            <SmartTable
              ref="tableDestination"
              name="ModalDestinationTable"
              :columns="COLUMN_DESTINATION"
              :body="list"
              :total="Number(total)"
              :initial-columns="2"
              :disabled="editDestination || disabledByModal"
              class="smart-table"
              toggle-selected
              removeBtnColumns
              :show-pagination="true"
              :item-per-page="10"
              :max-register="10"
              index-column="dco_nome"
              @pagination="getPagination"
              @getLine="getValueRowDestination"
            />
          </div>
        </RgValidatorForm>
      </FormBase>
    </form>
    <ModalConfirmDeletion
      id="modal-confirm-deletion"
      :show="modalDeleteDestination"
      noReason
      title="Excluir o Destino de Consumo Selecionado"
      message="Deseja excluir esse Destino de Consumo?"
      msgSuccess="Destino de Consumo excluído com sucesso!"
      :bodyText="bodyText"
      :confirm="removeDestination"
      @close="closeModalDeleteDestination"
      @reSearch="searchDestination(true)"
    />
  </Modulebox>
</template>

<script>
import {
  RgInput,
  RgCleanButton,
  RgAddButton,
  RgValidatorForm,
  RgSearchButton,
  RgEditButton,
  RgLessButton,
  RgSaveButton,
  RgCancelButton,
  ModalConfirmDeletion,
} from "~tokio/primitive";

import Modulebox from "~tokio/foundation/modulebox/Modulebox";
import FormBase from "~tokio/foundation/form-base/FormBase";
import { SmartTable } from "~tokio/foundation";

export default {
  name: "PharmacyDestination",
  components: {
    Modulebox,
    FormBase,
    RgInput,
    RgCleanButton,
    RgAddButton,
    RgValidatorForm,
    RgSearchButton,
    RgEditButton,
    RgLessButton,
    SmartTable,
    RgSaveButton,
    RgCancelButton,
    ModalConfirmDeletion,
  },

  data() {
    return {
      list: [],
      total: 0,
      editDestination: false,
      bodyText: "",
      hasSelectedRow: false,
      pharmacyDestinationSelected: null,
      modalDeleteDestination: false,
      form: {
        destination: null,
      },
      search: {
        destination: null,
      },
      pagination: {
        limit: 10,
        offset: 0,
        current: 1,
      },
    };
  },

  computed: {
    hasDestinationSelected() {
      return this.hasSelectedRow;
    },
    disabledByModal() {
      return this.modalDeleteDestination;
    },
  },

  watch: {},

  created() {
    this.COLUMN_DESTINATION = [
      { name: "Nome", key: "dco_nome", align: "left" },
    ];
  },

  mounted() {
    this.searchDestination();
  },

  methods: {
    getValueRowDestination(pValue) {
      if (pValue) {
        this.hasSelectedRow = true;

        this.pharmacyDestinationSelected = pValue;
      } else {
        this.hasSelectedRow = false;
        this.pharmacyDestinationSelected = null;
        this.$refs.tableDestination.cleanSelectRow();
      }
    },
    async searchDestination(submitFromButton = false) {
      if (submitFromButton) {
        this.$refs.tableDestination.resetLimitOffset();
      }
      const variables = {
        arrFiltros: {
          dco_nome: this.search.destination,
          limiteInicio: this.pagination.offset,
          limiteFim: this.pagination.limit,
        },
      };

      const result = await this.$store.dispatch(
        "Pharmacy/Register/SEARCH_DESTINATION",
        variables,
      );

      this.list = result.recordSet;
      this.total = result.total;
      this.$refs.searchDestination.actionDone();
    },
    isFormValid() {
      return this.$refs.validator ? this.$refs.validator.validate() : false;
    },

    async registerDestination() {
      const validate = await this.isFormValid();
      if (!validate) {
        this.$toaster.warning("Verifique os campos");
        return;
      }
      try {
        this.$loader.start();
        const variables = {
          dco_nome: this.form.destination.toUpperCase(),
        };
        const createdDestinationId = await this.$store.dispatch(
          "Pharmacy/Register/REGISTER_DESTINATION",
          { ...variables },
        );
        this.$toaster.success("Destino de Consumo cadastrado com sucesso");
        variables.dco_id = createdDestinationId.dados;
        this.list.unshift(variables);
        this.total++;
        this.clearForm();
        this.hasSelectedRow = false;
        this.$refs.tableDestination.cleanSelectRow();
      } catch (pErr) {
        const error = pErr.toString();
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isDuplicityError =
            esus_response.exception === "DuplicidadeDestinoConsumoException";

          if (isDuplicityError) {
            this.$toaster.error(
              `Já existe registro com esse nome:  ${this.form.destination}.`,
              "Não foi possível cadastrar o Destino de Consumo",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao cadastrar tipo de apresentação",
          );
        }
      } finally {
        this.$loader.finish();
        this.$refs.updateBtn.actionDone();
      }
    },

    async removeDestination() {
      try {
        this.$loader.start();
        const variables = {
          intIdDestinoConsumo: this.pharmacyDestinationSelected.dco_id,
        };
        await this.$store.dispatch(
          "Pharmacy/Register/REMOVE_DESTINATION",
          variables,
        );
        this.cancelEdit();
        this.searchDestination();
        this.hasSelectedRow = false;
        this.$toaster.success("Destino de Consumo excluído com sucesso");
      } catch (e) {
        this.$toaster.error(
          this.$utils.sanitize.formatError(e),
          "Não foi possível excluir o Destino de Consumo",
        );
      } finally {
        this.$loader.finish();
      }
    },

    validateEspecialCharacter(pValue, pErrors) {
      const hasSpecial = /[!$%^&*()@_^+#¨|´~=`{}[\]:";'~<>?\\]/.test(pValue);

      if (hasSpecial) {
        pErrors.push(
          "O campo permite apenas os caracteres especiais: barra, ponto, traço e vírgula",
        );
        return false;
      }

      return true;
    },

    editRegisterDestination() {
      this.editDestination = true;
      this.form.destination = this.pharmacyDestinationSelected.dco_nome;
      this.hasSelectedRow = false;
    },

    showModalDeleteDestination() {
      this.bodyText = this.pharmacyDestinationSelected.dco_nome;
      this.modalDeleteDestination = true;
    },

    closeModalDeleteDestination() {
      this.modalDeleteDestination = false;
    },
    async saveDestination() {
      const validate = await this.isFormValid();
      if (!validate) {
        this.$toaster.warning("Verifique os campos");
        return;
      }
      try {
        this.$loader.start();
        const variables = {
          dco_nome: this.form.destination.toUpperCase(),
          dco_id: this.pharmacyDestinationSelected.dco_id,
        };
        await this.$store.dispatch("Pharmacy/Register/EDIT_DESTINATION", {
          ...variables,
        });
        this.$toaster.success("Destino de Consumo alterado com sucesso");
        this.list.splice(
          this.list.findIndex((e) => e.dco_id === variables.dco_id),
          1,
        );
        this.list.unshift(variables);
        this.pharmacyDestinationSelected = null;
        this.clearForm();
        this.cancelEdit();
      } catch (pErr) {
        const error = pErr.toString();
        const { esus_response } = pErr;
        if (esus_response?.hasError) {
          const isDuplicityError =
            esus_response.exception === "DuplicidadeDestinoConsumoException";

          if (isDuplicityError) {
            this.$toaster.error(
              `Já existe registro com esse nome:  ${this.form.destination}.`,
              "Não foi possível alterar o Destino de Consumo",
            );
          }
        } else {
          this.$toaster.error(
            this.$utils.sanitize.formatError(error.message || error),
            "Erro ao alterar tipo de apresentação",
          );
        }
      } finally {
        this.$loader.finish();
        this.$refs.updateBtn.actionDone();
      }
    },

    cancelEdit() {
      this.editDestination = false;
      this.form.destination = null;
      this.pharmacyDestinationSelected = null;
      this.$refs.tableDestination.cleanSelectRow();
    },
    clearForm() {
      this.form = {
        destination: null,
      };
      this.$refs.inputDestination.cleanValidate();
    },

    async getPagination(pPagination) {
      this.pagination = pPagination;
      await this.searchDestination();
      this.hasSelectedRow = false;
    },
  },
};
</script>
