import { GraphQLClient, gql } from "@/common/request/GraphQLClient";

const query = gql`
  query SearchExamPatientsScheduledQueueReport(
    $unitHealthDestiny: Int
    $unitHealthDestinyName: String
    $sectorDestiny: Int
    $sectorDestinyName: String
    $placeDestiny: Int
    $placeDestinyName: String
    $procedureDestiny: Int
    $procedureDestinyName: String
    $employeeDestiny: Int
    $employeeDestinyName: String
    $userDestiny: Int
    $userDestinyName: String
    $scheduleDateStart: String
    $scheduleDateEnd: String
    $patientId: Int
    $patientName: String
    $unitHealthOrigin: Int
    $unitHealthOriginName: String
    $userOrigin: Int
    $userOriginName: String
    $insertDateStart: String
    $insertDateEnd: String
    $unitHealthRequest: Int
    $unitHealthRequestName: String
    $employeeRequest: Int
    $employeeRequestName: String
    $priority: Int
    $priorityName: String
    $withPrint: Int
    $columnsToPrint: [ColumnsToPrintInputType]
    $smd_id: Int
    $loggedUserId: Int!
    $toPrint: Boolean
    $toCsv: Boolean
    $toExcel: Boolean
    $limit: Int
    $offset: Int
  ) {
    SearchExamPatientsScheduledQueueReport(
      unitHealthDestiny: $unitHealthDestiny
      unitHealthDestinyName: $unitHealthDestinyName
      sectorDestiny: $sectorDestiny
      sectorDestinyName: $sectorDestinyName
      placeDestiny: $placeDestiny
      placeDestinyName: $placeDestinyName
      procedureDestiny: $procedureDestiny
      procedureDestinyName: $procedureDestinyName
      employeeDestiny: $employeeDestiny
      employeeDestinyName: $employeeDestinyName
      userDestiny: $userDestiny
      userDestinyName: $userDestinyName
      scheduleDateStart: $scheduleDateStart
      scheduleDateEnd: $scheduleDateEnd
      patientId: $patientId
      patientName: $patientName
      unitHealthOrigin: $unitHealthOrigin
      unitHealthOriginName: $unitHealthOriginName
      userOrigin: $userOrigin
      userOriginName: $userOriginName
      insertDateStart: $insertDateStart
      insertDateEnd: $insertDateEnd
      unitHealthRequest: $unitHealthRequest
      unitHealthRequestName: $unitHealthRequestName
      employeeRequest: $employeeRequest
      employeeRequestName: $employeeRequestName
      priority: $priority
      priorityName: $priorityName
      withPrint: $withPrint
      columnsToPrint: $columnsToPrint
      smd_id: $smd_id
      loggedUserId: $loggedUserId
      toPrint: $toPrint
      toCsv: $toCsv
      toExcel: $toExcel
      limit: $limit
      offset: $offset
    ) {
      html
      count
      csv
      excel
      fileName
      rows {
        PACIENTE
        DATA_AGENDAMENTO
        UNIDADE_SAUDE_DESTINO
        SETOR_DESTINO
        LOCAL_ATENDIMENTO_DESTINO
        PROCEDIMENTO
        PROFISSIONAL
        USUARIO_DESTINO
        DATA_ENTRADA
        UNIDADE_SAUDE_ORIGEM
        PRIORIDADE
        USUARIO_ORIGEM
        UNIDADE_SAUDE_SOLICITANTE
        PROFISSIONAL_SOLICITANTE
        IMPRESSO
      }
    }
  }
`;

export default async ({ commit }, variables) => {
  try {
    const { data } = await GraphQLClient.query({
      query,
      variables,
      fetchPolicy: "no-cache",
    });
    return data.SearchExamPatientsScheduledQueueReport;
  } catch (err) {
    return Promise.reject(err);
  }
};
